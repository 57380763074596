
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,9,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,8,8,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기자 않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,9,7,`특별있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,10,6,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,11,5,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`위선`,12,4,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,3,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,2,2,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`위선`,3,1,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`위선`,4,12,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,5,11,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`설득`,6,10,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`상업`,`사업 관련사`,`小`,`7`,`경벌송사`,`公私구설`],
    [`색정`,`외男내女 동시 색정사 有`,`大`,`단절기`,`색정음사`,`색정음사`],
    [`절상인구(絶傷人口)`,`가족 중 수재(秀才)가 상하는 상`,`大`,``,`손해도망`,`무례반목`],
    [`손괴재물(損壞財物)`,`가축(牛) 등 재물이 손실된 상`,`小`,``,``,``],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`8`,``,``],
    [`분산`,`동요불안분산사`,`小`,`침체기`,`동요분산`,`손괴재물`],
    [`향후번창(向後繁昌)`,`나중에 번창하게 되니 굳이 움직여 모사하지 말라-는 상`,`小`,``,`주력사凶`,``],
    [`도적불포(盜賊不捕)`,`도둑을 잡지 못한 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`9`,``,``],
    [`남편`,`남편 관련사`,`小`,`소외기`,`佩印권리`,`능욕박해`],
    [`가해산려(可解散慮)`,`내외 흩어지는 걱정이 깊은 상`,`大`,``,``,``],
    [`능욕박해(凌辱迫害)`,`남이 모르는 능욕과 박해를 당하는 상 `,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`10`,``,``],
    [`결속`,`복잡한 일을 결속하여 권리사를 완성하나 病 官災 우려됨`,`小`,`침해기`,`결속단합`,`動止咸宜`],
    [`고위난위(高位難依)`,`고위인에게 도움받기가 어려워진 상`,`大`,``,`후원대득`,`자강불식`],
    [`손괴재물(損壞財物)`,`재물이 손괴된 상`,`小`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련 비용과출사`,`大`,`11`,``,``],
    [`동료`,`동료관련사`,`小`,`쇠퇴기`,`재물암동`,`손해가출`],
    [`부모묘해(父母墓害)`,`부모 묘지에 해충이 들어 가족의 흥왕이 어려운 상-必`,`小`,``,``,`문서유실`],
    [`문서유실(文書遺失)`,`문서를 잃었거나 망실된 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`大`,``,``,``],
    [`출행`,`원행이동사-문서관련-관직자는 영전함`,`大`,`12`,`원행이동`,`원행이동`],
    [`부모`,`부모 관련사`,`小`,`과시기`,`송구경황`,`간음이익`],
    [`화촉허경(火燭虛驚)`,`火難에 놀랐으나 허경(虛驚)의 상`,`小`,``,`私情인`,`정대즉`],
    [`도적입문(盜賊入門)`,`모든 일이 불리하고 도난 당할 수 있는 상`,`小`,``,`나타남`,`되려재앙`],
    [`직녹`,`직위 관련사`,`大`,``,``,``],
    [`화합`,`상하 화합사`,`大`,`1`,`재난해소`,`파재극처`],
    [`부모`,`부모 관련사`,`小`,`절정기`,`상하화합`,`단순반복`],
    [`인구재쇠(人口(災衰)`,`재난으로 가족 가쇠(家衰)의 상`,`小`,``,``,``],
    [`재앙소멸(災殃消滅)`,`재난이 소멸되는 象`,`小`,``,``,``],
    [`자매`,`자매관련사`,`小`,``,``,``],
    [`친구`,`친구사`,`大`,`2`,`외교막힘`,``],
    [`결속`,`복잡한 일을 결속하여 권리사를 완성하나 病 官災 우려됨`,`小`,`면모기`,`봉흉화길`,`다사단합`],
    [`형제불의(兄弟不宜)`,`형제에게 안 좋은 일이 벌어진 상`,`小`,``,`후원대득`,`모망난수`],
    [`모망난수(謀望難隨)`,`바라는 일을 이루기 어려운 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교 진출하면 성공함`,`小`,`3`,`문서유실`,`情僞難測`],
    [`매사 영속성없음`,`직업직위남편남자자식문제는 천부적 기복재해를 겪음`,`大`,`학습기`,`강제급속`,`강제급속`],
    [`고독이별(孤獨離別)`,`출생지를 떠나 고독하게 살고 이별사를 겪는 상`,`小`,``,`손실사有`,`손실사有`],
    [`시리간음(是利奸陰)`,`간음사를 정대(正大)하면 되려 앙화(殃禍)가 되는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교 진출하면 성공함`,`小`,`4`,``,`주력사凶`],
    [`매사영속성없음`,`직업직위남편남자여식문제는 선천적 기복재해를 겪음`,`大`,`개시기`,`간음무도`,`동요분산`],
    [`난의망망(難依茫茫)`,`고위인에게 도움받기가 어려워져 망망한 상`,`小`,``,``,`개변별리`],
    [`중도청알(중도청알)`,`고위인이 길을 지날 때 청원함이 좋은 상`,`大`,``,``,``],
    [`형제`,`형제관련사`,`小`,``,``,``],
    [`친구`,`친구사`,`大`,`5`,`행길청원`,`免屈난해`],
    [`송쟁`,`외사 급속송쟁파재실종상해사 우려의 상`,`小`,`배양기`,`급속쟁송`,`화합사有`],
    [`기리동모(豈利動謀)`,`어찌 굳이 적극 움직여 일을 꾸며야만 하는가-의 상`,`小`,``,`상해우려`,``],
    [`면굴난해(免屈難解)`,`굴욕적 관송사가 연이어지나 면할 길이 없는 상 `,`大`,``,``,``],
    [`금전`,`금전사`,`小`,``,`逢林有蛇`,`고위안좌`],
    [`상업`,`내외결속사-복잡다단함`,`小`,`6`,`莫入당도`,`衆人이익`],
    [`처첩`,`처첩관련사`,`大`,`존재기`,`다사결속`,`방책알림`],
    [`이익경영(利益經營)`,`고위에 안좌하여 중인의 이익을 널리 알리는 상-부족`,`大`,``,`복잡다단`,`복잡다단`],
    [`혼매우심(昏昧尤甚)`,`해소되지 않는 근심사가 매우 깊은 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`금전사업색정조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`자매친구동료조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`관사직업남편문제로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`관사직업남편가정막힘문제로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`형제친구동료조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,`후원문서부모조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,`직녹권리문서사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`자매친구동료조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`세속허사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`형제친구동료조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,`금전사업처첩조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 되어진 상황에 반발하지 말고 유순유화순응적 처신을 해야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞대응을 말고 유순한 언행으로 일을 늦추면 후에는 유리해지는 人`,0,0,`현재 사안에서 물러나는 것이 좋음**일이 결과없이 반복되기만 하나 계속 진행하면 밝아짐**사물이 돌연히 일어남**전화위복이 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후에 행동함이 유리하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`웃음속에 독이 든 격**매사 음적 이면적이 유리함**여자는 재난이 많음**관직자는 영전하나 늦으면 불발함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 위세를 꾸며도 적중하지 못해 불리하니 공격을 중지해야 함 `,0,0,`호가호위적 위세를 과시하며 상대를 공격해도 상대 또는 목표를 적중달성하지 못해 본인이 불리해질 人`,0,0,`여러 일에 연루됨**강적을 만나게 됨**자칫 형상사가 일어남**결과없이 반복되는 일이나 계속 진행하면 밝아짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후에 행동함이 유리하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 알을 하나로 묶음-단 복잡다단함**현재의 계획이나 방침을 속히 변경시켜야만 됨**나쁜 꿈에 시달리는 兆**여자는 후에 남자를 적극 휴혹하여 달아날 생각까지 하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 되어진 상황에 반발하지 말고 유순유화순응적 처신을 해야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞대응을 말고 유순한 언행으로 일을 늦추면 후에는 유리해지는 人`,0,0,`어디 깊은 산속으로 숨어버리고 싶은 심정을 가고 있는 상태임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있어 천지가 뒤집힌 상으로 화가 밖에서 오므로 신중해야 함`,0,0,`부부남녀가 서로 다른 마음으로 동요분산사를 반복하고 색정사로 인해 의지거처가 난처한 人 `,0,0,`남에게 숨기는 은닉사가 있고 여자는 흉을 당하게 됨**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송 조짐이 있는 人`,0,0,`관공직자는 영전함**권리행사를 하게 됨**일반인은 일이 반복되기만 하고 결과가 없음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후에 행동함이 유리하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 하나로 묶음-단 복잡다단함**여건이 불비부족함**어른이 아래인에게 박해를 받음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 되어진 상황에 반발하지 말고 유순유화순응적 처신을 해야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞대응을 말고 유순한 언행으로 일을 늦추면 후에는 유리해지는 人`,0,0,`풍운불측의 상**간사은닉건이 일어남**일이 돌연하게 발생함**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 위세를 꾸며도 적중하지 못해 불리하니 공격을 중지해야 함 `,0,0,`호가호위적 위세를 과시하며 상대를 공격해도 상대 또는 목표를 적중달성하지 못해 불리해질 人`,0,0,`일을 이면적 후응적으로 하는 것이 유리함**윗사람은 유리하고 아랫사람은 불리함**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 되어진 상황에 반발하지 말고 유순유화순응적 처신을 해야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞대응을 말고 유순한 언행으로 일을 늦추면 후에는 유리해지는 人`,0,0,`일이 순서적으로 진행되어 후에 자산이 됨**현재 어디 산속으로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`스스로 유약하다고 여기고 활동할 의지가 없는 격-강제성에 의해 신체가 부자유한 경우도 있음`,0,0,`풍운불측의 상**가정 불안`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`익성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용나감`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성-무력`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`침체인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`좌고우면-무력`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인-후에 피해`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성-무력`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈 人`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-이사확정됨`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-가옥고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥-이전예정`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥-가옥고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-실종사우려`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-가옥고민-가출사우려`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음2월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,1,1,2,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음6월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음7월-연기 또는 파혼우려`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,2,1,3,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5-연기우려`,1,1,2,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,3,3,6,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,1,3,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,3,3,6,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 순리적이지 못하고 일을 거꾸로 하나 일반적 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 있으나 업무는 일반적 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같아도 상대를 잘 조정하여 초반에 자산을 형성하나 중종반에는 손실사 방어에 주력하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 존언을 무시하고 현안을 결속 단합시키지만 그 일이 결국 큰 피해를 초래하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이나 업무는 일반적임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 많아 업무효과가 허사가 심해매우 저조한 평가가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세고 중반에 침체하며 재정을 소모시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높아 관공분야는 권위를 크게 세우는 업적이 크게 되나 일반은 큰 피해를 남기게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 실적이 없게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무가 띄엄띄엄 진행되어 업적이 수준에 못 미침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 상대를 잘 조정하는 능력이 있어 업무를 성공적으로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 업무도 수준 이상으로 진행하여 종반에 자산을 이룸 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`불이행`,`선난후이`,`進向`,`원`,`留`,`무난`,`남남서`,``,`남남서`],
    [`10.11`,``,`본인측근`,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,`제압됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`없음`,`불이행`,`비용과출`,`進向`,`  원`,`  留`,`세입가해`,`남남서`,``,`동남`],
    [`10.11`,`多集`,``,``,`효율없음`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`없음`,`불이행`,`선후압박`,`進向`,`  원`,`가나오나`,`주인후원`,`동북`,``,`동남`],
    [`10.11`,`多集`,``,``,`결국성사`,``,``,`일반`,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`했음`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대전체`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인포함`,`불이행`,`선난후이`,`退背`,`  원`,`가나오나`,`주인후원`,`서북`,`남남동`,``],
    [`10.11`,``,`방법없음`,``,`천천히`,``,``,`일반`,``,``,`세밀심사`,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선난후난`,`退背`,`  원`,`  去`,`화합`,`남남동`,`남남서`,``],
    [`10.11`,``,`지구전要`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`상대무력`,`進向`,`  원`,`  留`,`주인무력`,`정남`,`남남서`,``],
    [`10.11`,``,`무력함`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근제압`,`불이행`,`상대무력`,`進向`,`  근`,`留`,`주인무력`,`남남서`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`모두반대`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`불이행`,`선이후난`,`退背`,`  원`,`  去`,`세입가해`,`동북`,`정동`,``],
    [`10.11`,``,`방법없음`,``,`빠르게`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음`,`불이행`,`선이후이`,`退背`,`  원`,`  去`,`세입설기`,`정동`,`동남`,``],
    [`10.11`,``,`본인제압`,``,`느긋이`,``,``,``,`피로쇠약`,``,``,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,`반대세력`,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`단합강고`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인방어`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`세입설기`,`동남`,``,`동남`],
    [`10.11`,`  集`,`무력외부`,``,``,``,``,``,`피로쇠약`,``,``,``],
    [`3.6\n9.12`,``,`세력제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`세입가해`,`남남동`,``,`남남동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압무력`,`불이행`,`선난후난`,`退背`,`  근`,`  去`,`무난`,`정남`,``,`정남`],
    [`10.11`,`  集`,`최대연기`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`제압됨`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,`원한귀`,`불혼사망`,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`특별있음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`제례부정`,`제례위범`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`자살귀`,`목맴`,``,``,`묘좌`,``,`있음`],
    [``,`자살귀`,`목맴`,``,``,`묘향`,``,``],
    [`생업`,``,`강력강고`,``,``,`전경`,``,``],
    [``,``,`함`,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,`서북`,`없음`],
    [``,``,``,``,``,`묘향`,`동남`,``],
    [`생업`,`음귀`,`묘지탈`,``,``,`전경`,`풀밭`,``],
    [``,``,`당대자녀`,``,``,`묘속`,`함몰`,``],
    [`가정`,``,`재해`,`저주원심`,`원망구설`,`육친`,`자매,5대모`,``],
    [`질병`,`수귀`,`익사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`수귀`,`익사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`수귀`,`익사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,`수귀`,`익사`,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`수귀`,`익사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`물품부정`,`금속기물`,`전경`,``,``],
    [``,``,``,``,`반입`,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,`남남동`,`없음`],
    [``,`강고함`,``,``,``,`묘향`,`북북서`,``],
    [`생업`,``,``,``,``,`전경`,`풀밭`,``],
    [``,``,``,``,``,`묘속`,`뱀굴`,``],
    [`가정`,``,``,``,``,`육친`,`부친`,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`신사부정`,`영정이동`,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,`정동`,`없음`],
    [``,``,``,``,``,`묘향`,`정서`,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,`풀밭`,``],
    [``,``,``,``,``,`묘속`,`목근`,``],
    [`가정`,`가장귀`,`호주사망`,``,``,`육친`,`형제,5대祖`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`빈궁**전도`,`도적관련사-못잡음`,`부부남녀동시색정사**전도`,`酒食受罰`,`빈궁**병재`,`외사동요이전이동사`,`빈궁**관재`,`公私구설사`,`관재`,`관재**고위안좌-이익경영사`,`빈궁`,`굴욕난해-감옥관련사`,``,`내외사 원행이동사**모두 흉함`,``,`자산권리사**미세송쟁**고시득길함`,`숲에서 뱀을 보게된 격`,`빈궁**외사막힘`,`매사허성불성**세속허사자`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`매사영속성 없음`,`종교진출하면 자립함`,`빈궁**관재**실종사우려**은닉사有`,`외사급속송쟁-상해우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`내외단합결속사`,``,``,`손괴재물`,`전화위복`,``,`재액해소`,`재액해소`,`가정막힘`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`강제급속손실사`,`성공함`,`내사 동요분산사`,`간음무도`,`전화위복`,`침체**경구사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`빈궁**관재**실종사우려**은닉사有`,`외사급속송쟁-상해우려`,`빈궁`,`도적관련사-못잡음`,`부부남녀동시색정사`,`酒食受罰`,`빈궁**병재**손괴재물`,`외사동요이전이동사`,`빈궁**관재`,`公私구설사`,``,`관재**이고위안좌-이익경영사`,`빈궁`,`굴욕난해-감옥관련사`,``,`내외사 원행이동사**모두 흉함`,``,`자산권리사**미세송쟁**고시득길-부족`,`숲에서 뱀을 보게된 격`,`빈궁**외사막힘`,`매사영속성없음`,`종교진출하면 자립함`,`매사영속성 없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`전화위복`,``,`내외단합결속사`,``,``,`재액해소`,`전화위복`,``,``,``,`가정막힘`,``,`재액해소`,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,`재액해소`,`강제급속손실사`,``,`내사 동요분산사`,`간음무도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`빈궁**매사영속성없음`,`종교진출하면 자립함`,`빈궁`,`외사급속송쟁-상해우려`,`내외단합결속사`,`필유성취사`,`부부남녀동시색정사`,`빈궁`,``,`외사동요이전이동사`,`빈궁**관재`,`公私구설사`,`빈궁**병재**가족중 절상사 우려`,`이전이동사**모시미수`,``,`관직-영전 일반-불리`,`전도**빈궁`,`내외사 원행이동사**모두 흉함`,`빈궁**전도`,`문서암동-허약부족`,`송구경황-두려운 사람 관련`,`외사막힘`,`매사영속성 없음`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 동요분산사`,``,`재액해소`,`전화위복`,``,``,``,``,`재액해소`,`전화위복`,``,``,``,`가정막힘`,``,`재액해소`,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,`재액해소`,`강제급속손실사`,`송구경황-두려운자관련`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`매사영속성 없음`,`종교진출하면자립함`,`빈궁**매사영속성없음`,`종교진출하면자립함`,`외사급속송쟁-상해우려`,`빈궁**실종사우려**은닉사有`,`내외단합결속사`,`가나있으나 좋음`,`일체근심해소`,`부부남녀동시색정사`,`빈궁`,`외사동요이전이동사`,`빈궁**관재`,`문서지체-구설소제관련사`,``,`사사성취`,`굴욕난해-감옥관련사`,`빈궁**외사막힘`,`내외사 원행이동사`,`불측근심사`,`자산직녹권리사**상대마음난측사`,`빈궁`,`외사막힘`,`빈궁**병재**손괴재물`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`강제급속손실사`,`내사 동요분산사`,`가정동요부부분산사`,`전화위복`,``,``,``,``,``,``,`전화위복`,`재액해소`,``,`재액해소`,`가정막힘`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`관재**모사미수사`,`외사막힘`,`빈궁**병재**매사영속성 없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`빈궁**실종사우려`,`외사급속송쟁-상해우려`,`빈궁**전도`,`흉이 좋아짐`,`전도**관재**일체근심해소`,`부부남녀동시색정사`,`빈궁`,`외사동요이전이동사`,`송구경황사-사통사관련`,`빈궁**관재**외사동요이전이동사`,`가정막힘`,`매사불리`,`관직- 승진-부족 일반- 불리`,`빈궁`,`내외사 원행이동사`,`빈궁**병재`,`자산직녹권리사**상대마음난측사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`강제급속손실사`,`내사 동요분산사`,`가정동요부부분산사`,``,`전화위복`,`내외단합결속사`,``,``,``,`전화위복`,``,`재물暗動`,`재액해소`,``,`재액해소`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`빈궁**외사막힘이전이동사`,`자산직녹권리사**상대마음난측사`,`모사미수사`,`외사막힘`,`매사영속성 없음`,`종교진출하면자립함`,`매사영속성 없음`,`종교진출하면 자립함`,`빈궁**실종사우려`,`외사급속송쟁-상해우려`,`흉이 좋아짐`,`빈궁**전도`,`관재**일체근심해소`,`부부남녀동시색정사`,`전도`,`외사동요이전이동사`,`송구경황사-사통사관련`,`빈궁**외사동요이전이동사`,`가정막힘`,`빈궁`,`관직-승진 일반-불리`,`전도`,`내외사 원행이동사`,`빈궁**병재**손괴재물`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`음4,5월 화재주의`,`동요불안분산사`,``,`동요불안분산사`,`강제급속손실사`,`내사 동요불안분산사`,`가정동요부부분산사`,`전화위복`,`동요불안분산사`,`동요불안분산사`,`내외단합결속사`,`동요불안분산사`,``,`전화위복`,`동요불안분산사`,`재물暗動`,`동요불안분산사`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`내외사 원행이동사`,`빈궁**병재`,`자산직녹권리사**상대마음난측사`,`빈궁`,`모사미수사`,`외사막힘`,`전도**병재**매사영속성없음`,`종교진출하면자립함`,`전도**매사영속성없음`,`종교진출하면자립함`,``,`빈궁**외사급속송쟁-실종사우려**상해우려`,`흉이 좋아짐`,`전도**빈궁`,`일체근심해소`,`부부남녀동시색정사**전도`,`송구경황사-사통사관련`,``,`송구경황-사통사관련`,`빈궁**외사동요이전이동사`,`가정막힘`,``,`관직-승진 일반-불리`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`음4,5월 화재주의`,``,``,``,``,``,`강제급속손실사`,``,`내사 동요분산사`,`전화위복`,``,`재액해소`,`내외단합결속사`,`재액해소`,``,`전화위복`,``,`재물暗動`,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관직-승진 일반-불리`,`빈궁**관재`,`내외사원행이동사`,`빈궁**병재`,`자산직녹권리사**상대마음난측사`,`빈궁`,`모사미수사`,`외사막힘`,`빈궁**병재**매사영속성 없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`빈궁`,`외사급속송쟁-상해우려`,`전도**빈궁**흉이 좋아짐`,`내외단합결속사`,`일체근심해소-부족`,`부부남녀동시색정사`,`송구경황-사통사관련`,``,`빈궁`,`외사동요이전이동사`,`가정막힘`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,``,``,`강제급속손실사`,``,`내사 동요분산사`,`전화위복`,``,``,``,``,``,`전화위복`,``,`재물暗動`,`재액해소`,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`가정막힘`,`전도**빈궁`,`관직-승진 일반-불리`,``,`빈궁**병재**내외사 원행이동사`,`재앙소멸`,`자산직녹권리사**상대마음난측사`,`빈궁`,`모사미수사`,`외사막힘`,`빈궁**병재**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,``,`빈궁**외사급속송쟁**실종사우려**상해우려`,`흉이 좋아짐`,`내외단합결속사`,`일체근심해소-부족`,`부부남녀동시색정사`,`송구경황-사통사관련`,`외사동요이전이동사`,`재물暗動`,`빈궁**전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`음4,5월 화재주의`,`음4,5월 화재주의`,`재액해소`,``,`재액해소`,``,``,``,`강제급속손실사`,``,`내사동요분산사`,``,`전화위복`,``,``,``,``,``,`전화위복`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`빈궁**전도`,`公私구설사`,`관재**가정막힘`,`고위안좌-이익경영사`,`빈궁`,`굴욕난해-감옥관련사`,`전도`,`모두 흉함`,`빈궁**전도`,`자산권리사**미세송쟁**고시득길함`,`숲에서 뱀을 만난 격`,`외사막힘`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성 없음`,`종교진출하면자립함`,`빈궁**실종사우려**은닉사有`,`외사급속송쟁-상해우려`,`빈궁`,`도적관련사-못잡음`,`부부남녀동시색정사`,`酒食受罰`,`빈궁**관재**병재**재물손괴사`,`외사동요이전이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`음4,5월 화재주의`,`재액해소`,`음4,5월 화재주의`,`내외사 원행이동사`,``,``,``,`재액해소`,``,`강제급속손실사`,`내사 동요분산사`,`간음무도`,`재액해소`,`전화위복`,`내외단합결속사`,``,``,``,`재액해소`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`빈궁**병재**재물손괴사`,`외사막힘이전이동사`,`관재`,`公私구설사`,`가정막힘`,`고위안좌-이익경영사`,`빈궁`,`굴욕난해-감옥관련사`,`빈궁`,`모두 흉함`,`빈궁`,`자산권리사**미세송쟁**고시득길함`,`숲에서 뱀을 마난 격`,`외사막힘`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성 없음`,`종교진출하면자립함`,`빈궁**실종사우려**은닉사有`,`외사급속송쟁-상해우려`,`전도**빈궁`,`도적관련사-못잡음`,`전도**부부남녀동시색정사`,`酒食受罰`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`전화위복`,``,`재액해소`,``,`재액해소`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,`내외사 원행이동사`,``,`음4,5월 화재주의`,``,``,``,`강제급속손실사`,`내사 동요분산사`,`간음무도`,``,`전화위복`,`내외단합결속사`,`폐한인`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`부부남녀동시색정사`,`酒食受罰`,`전도**빈궁**병재**재물손괴사`,`외사막힘이전이동사`,`관재`,`公私구설사`,`가정막힘`,`고위안좌-이익경영사`,`음4,5월 화재주의`,`전도**빈궁**굴욕난해-감옥관련사`,``,`모두 흉함`,`빈궁`,`자산권리사**미세송쟁**고시득길함`,`숲에서 뱀을 만난 격`,`외사막힘`,`매사허성불성**세속허사자`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`매사허성불성**세속허사자`,`음3,6,7,8,9,12월 방문자는 종교진출하면 `,`전도**빈궁**실종사우려**은닉사有`,`외사급속송쟁-상해우려`,`빈궁`,`도적관련사-못잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`전화위복`,``,``,``,``,``,``,`음4,5월 화재주의`,`내외사 원행이동사`,``,`음4,5월 화재주의`,`전도**빈궁`,``,`강제그복손실사`,`성공함`,`내사 동요분산사-간음무도`,`성공함`,``,`전화위복`,`내외단합결속사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 첩첩산중에서 신음하는 격인데, 내부에 뭘(죄인) 감춘다고 이로움이 될까?
⊕자살귀, 
⊖가택귀를 없애고 근신해야    재기를 하지!
⊕⊖신변상가부정이 침범
   했네 ?
`,`■ 가정에 ⊕변사귀, 
          ⊖원한귀가 들었는데 이것만 해소시키면 원하는 것 무엇이든지 다 잘되겠어! 
지금은10보후퇴1보전진 격이야!
`,`■ 물구덩이에 빠진격인데,     이사까지 해야하네? 
   색정사도 있고 ! 
女:모친을 미워하지 마세요!
   모친살상을 주의 요함.
모든 일을 사적, 음적으로 해야만 기회를 잡을수있겠어!
⊕가택음사, 
⊖상가부정이 듬
`,`■내게 힘이 없는데 활을 쏜다고 먼데 있는 상대가 맞겠어? 뭘(범인) 감추고 있겠어! 관재에 연루되는 일을 하지 말고 기도치성에 매달려봐! 
편안해 질거야!
⊕신변고모신사부정침범
⊖신변에신화선영부정이들    었어 !
`,`■ 산넘어 산이요, 물건너 물이라 ! 공격받는 일이 한두가지가 아니겠어 !
⊕자살귀, 
⊖전흉사귀 때문이야!
女:⊖남의 남자를 유혹해서     색정사를 벌이고 따라      나서기 까지 하겠네?

⊖신변상가부정이 들었어!
`,`■ 음모와 배신을 받아 도망가는 것이 상책인격일세?

⊕⊖저주원심부정이 듬
⊕음귀, 
⊖원한귀가 몸을 때리고, 
집에는 저주부정이 들어서 그래!
`,`■ 앞길이 막막한데 색정불화까지 심해서 어디 기댈데가 없겠어!

⊕수귀, 
⊖전흉사귀가 문제인데, 
관재를 초래 할 일(죄인)까지 감추고 있겠어!
`,`■ 도장 쥐고 좋은 차 타는 운세네? 그런데

⊕수귀, 
⊖전흉사귀가 방해를 해서 반복되고 돈 여자로 기로에 섰네,가정 내부 문제에의심 질투사가 있어!
`,`■ 저 산을 넘어야 하는데 나는 물구덩이에 빠진 격일세? 여건도 부족하고, 골육간에도 불화하니 ⊕수귀, 
        ⊖전흉사귀를 잡고 화를 면해야지!

⊕신변음사부정이 들었어! !
⊖女:남의 남자를 유혹했네 ?
`,`■ 앞길은 어지럽고 골육은 등돌리고 관재연루사(죄인) 까지 감춰야 하고 집 권리는 넘어가고!  어쩌겠어?
⊕수귀, 
⊖가택귀 장난인데!
⊕신변저주원심부정침범
⊖신변여고음강부정이
  들었어!
`,`■ 상대를 공격한다 해도 적중이 안되겠어 !

⊕수귀, 
⊖가택귀 때문이야! 
총이 있다하나 총알이 없는격인데 상대가 맞겠어? 
기도가 제일이야!
`,`■ 표은남산이란 말은 도망간다는 뜻인데, 지금 그런 상황이겠어! 관재고심이야 !
집에 ⊕⊖가택고모신사부정               때문이야 ! 
사진ㆍ영정․병풍을 옮기거나 붙였을거야! 
기도하면 풀려!`];
const YOGYEOL1 = [`■ 관귀를 만났는데 천장까지 관살이다. 어떤일이 닥쳐 입을 다물지 않으면 재화가 더욱 커지리라.
`,`■ 좋은 것이 많으니 소기의 목적이 다 이루어진다. 움직이는것도 좋고 후퇴하여 지키면 썩지 않으리라.
`,`■ 몸과 집에 역마가 탔으니 가만히 있기 어렵다. 도적과 손해와 망신이 있으나 나중에는 좋게 변하리라.
`,`■ 지켜봐야 그저 그렇고 다시 해봐도 성과가 없다. 중간과 결과가 없으나 흉사나 근심은 잘 풀린다.
`,`■ 상대가 와서 나를 괴롭히니 오래도록 계속된다. 낮정단은 귀인에게 청탁하지 말라. 밤정단도 이와 같다.
`,`■ 생기가 있으나 상하에서 극 받아 돕지 못한다. 신변에 관재가 생기면 부모나 손윗분의 힘이 크다.
`,`■ 관귀가 모두 없어졌으니 흉한일을 보아도 흉하지 않다. 여름철 밤정단은 화재가 있겠으나 실재 재앙은 없다.
`,`■ 사람이나 집이나 귀인이 있어도 밤정단은 혜택이 없고 낮정단은 혜택이 있다. 끝에서 처음을 도와 관살은 두렵지 않다.
`,`■ 재난이 끝나기 어렵다. 밤정단을 백호가 말전에 있다. 스스로 사서 당하니 무슨 이유로 벗어나리요.
`,`■ 서로 사이가 좋더라도 서로 손해는 있게 된다. 말전에 관살이 현무를 만나니 도둑이 구둘 뚫고 오는 것을 막으라.
`,`■ 활은 있지만 쏘는 것을 잊었다. 밤정단은 도적을 막아야 한다. 순전히 음뿐이므로 두 귀인은 아득하기만 하다.
`,`■ 도움이 되지 않는 귀인을 만났다. 집안에는 검은 구름이 끼었다. 
셋중 하나가 빠졌으니 오직 녹은 뜻대로 안된다.`]
const SIBO1 = [`■家內우환이 있고, 가장을
속인다. 가인이 원한을 품고
加害하려 한다. 正月 임신 有
유약심으로 재해가 깊다. 목적 달성이 안되고 있다. 春ㆍ秋ㆍ冬月 정단은 근신예방의 방침으로 하고 夏ㆍ季月 정단은 전진시켜야 한다. 凶은 內에서 발생한다. 감추는 일이 있다. 재해가 빠르다.
`,`■골육상배ㆍ동요사가 있다.
폐지사ㆍ부흥심ㆍ모망무성
하고 家內 우환이 있고
가장을 속인다. 正月 임신有
家人이 원한을 품고 加害하려는 兆이다. 그러나 일이 역조되어 이익이 있게 된다. 활동하라.
凶이 변해 吉이 된다. 
단, 함부로 망동하지 말라. 은근하고 자연스럽게 나아가야 한다.
`,`■사물이 갈림길에 있다.
가정에 병자가 있다.
간난ㆍ험악의 상태이다.
그만 둘 수가 없이 돌아가는 형국이다. 1을 얻으면 10을 잃는 격이다. 그러나 고생 후에 上人의 추천으로 小成이 있다.
중도에 속는 일이 있다.
`,`■속임을 당한다.
季月은 부자이다.
소망사가 실현되지 못한다.
허공의 바람잡는 형국이다. 동요되고 인정이 멀어지고 있다.
아직 때가 아니다.
기도하며 시의심을 진정시켜야 한다.
財가 있어도 부자유하다.
正月에 임신사가 있다.
`,`■자충수적 우환이 기한다.
女ㆍ婦ㆍ상대가 自來 하여
괴롭힌다.
여난을 조심하라.
남녀가 부적절한 관계를 맺고 있다. 일도 반복 불안하다. 전진이 안된다. 자세를 굽히라. 대기만성이 된다. 단, 재수는 있다.
여름ㆍ季月은 吉하다.
다인 다사건이다.
`,`■자충수적 우환이 기한다.
신변이 불안한 입장이다.
은거하여 재기를 도모하는 것이 좋다. 凶이 변해 吉이 된다. 문장구설ㆍ下人의 음모가 있다. 배역을 방지해야 한다. 先祖의 묘에 이상이 있다. 正月 임신사 有
⊕음사사, ⊖허위사의 피해를 입는다.
`,`■가택을 손상한다.
기도ㆍ치성의 효과가 있다.
신심의 의지처가 없다.
매사 불화하고, 반복의 연속이다. 단, 심중놀람은 허성일 뿐 실해는 없다. 움직이려고 해도 이동되지 않는다. 
기도로 안전을 비는 것이 좋다.
`,`■공명사는 허언미담이다.
동료와의 동업사가 의심으로
결정을 못하고 기로에 있다.
가정 내부 금전과 여자문제로 의심과 질투사를 겪고 있다.
규범이 깨지고 규모가 손상되어 있다.
恩中害 반목 한다.
正月에 임신사가 있다.
`,`■男ㆍ夫가 헌신한다.
상대에게 의존하여
굴욕을 당한다.
財와 여자로 인해 재해가 있다. 타인의 권세에 의존한다. 吉은 공허한 것이 되고 만다. 즉 길흉이 완전하지 않다. 
하극상의 의심이 있다.
번잡한 일이 많다.
다인 다사건이다.
`,`■별리ㆍ고독인이다.
가택의 권리를 이양한다.
다툼이 있으나 타협된다.
사건ㆍ물건을 은닉하면 나쁘다. 교섭사가 좋으므로 의논하면 吉로 된다. 
어떤 경우라도 진실을 말하라. 凶이 吉로 될 것이다.
도난ㆍ분실사를 주의하라.
正月에 임신사가 있다.
`,`■공공사는 불리하고 私事는
유익하다.
속는 일이 있다.
財가 박하다.
출비가 많다.
吉凶이 완성되지 못한다.  
도난ㆍ손실을 방지해야 한다.
`,`■관송사가 발생했다.
⊕⊖고모신사 부정이 듬
초난 후 광명이 있다.
신변이 은복해야할 입장임. 그러나 만사 적극 전진하라. 계획이 많게 되면 오히려 졸책이 되고 만다. 이제 옛일에서 벗어나라 당당하게 나서서 행하면 광명이 있다. 他人의 말에 좌우되지 않아야 한다.`]
const SIBO2_1 = [`■ 官･夫, 가택내사이다.
내방조짐 상의 :  
조용히 있는게 이롭다.
상호교섭색정의 상이다.
`,`■형제․손모․신변동요사.
내방조짐 상의 :  
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 재ㆍ여, 신변동요내외사
내방조짐 상의 :  
조용히 있는게 이롭다.
가정에 병재가 기한다.
`,`■ 임신사, 가정재해사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■형제․손모․신변외사
내방조짐 상의 :  
은근하고 伏하라.
병재·관재 예방이 맆요하다.
`,`■형제․손모․가택내사
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
관재 근심이 있다.
`,`■ 관ㆍ부, 가택내사이다.
내방조짐 상의 :  
조용히 있는게 이롭다.
색정불화, 이심원한의 상이다.
`,`■후원․사업․문서사이다.
내방조짐 상의 :  
원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 형제,손모, 신변외사
내방조짐 상의 :  
은근하고 伏하라.
병재·관재 예방이 필요하다.
`,`■ 속고, 자손재해동요사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.속는 일이 있고 자녀 재액이 발생한다
`,`■ 재ㆍ여, 가택내외사.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■형제,손모,분쟁 가정사
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/016/row-1-column-1.png`,
  `/static/images/sagwa/016/row-1-column-2.png`,
  `/static/images/sagwa/016/row-1-column-3.png`,
  `/static/images/sagwa/016/row-1-column-4.png`,
  `/static/images/sagwa/016/row-1-column-5.png`,
  `/static/images/sagwa/016/row-1-column-6.png`,
  `/static/images/sagwa/016/row-1-column-7.png`,
  `/static/images/sagwa/016/row-1-column-8.png`,
  `/static/images/sagwa/016/row-1-column-9.png`,
  `/static/images/sagwa/016/row-1-column-10.png`,
  `/static/images/sagwa/016/row-1-column-11.png`,
  `/static/images/sagwa/016/row-1-column-12.png`,
]

const HEASYEOL = [
  // 1
  `  이 과는 십이신이 본궁에 숨어서 천지2반(天地二盤)이 동하지 않는다. 예를 들면 사람은 음복신음(陰伏呻吟)한다. 이 과는 상하의 극적이 없다. 음일로 되면 지상신을 초전으로 한다. 중․말전은 형충(刑冲)으로써 음일은 자신격(自信格)으로 한다. 스스로 유약하다고 자신하기 때문이다.
  이 과는 먼저 왕쇠(旺衰)를 판별해야 한다. 사토(巳土)는 미(未)를 만나므로 토(土)는 왕성하고 묘(卯)는 묘(卯)를 보아 왕세(旺勢)로 된다. 그러나 묘(卯)에 의해 극을 당하므로 일간은 변화하며 쇠퇴한다. 왕성할 때는 전진하고, 쇠퇴할 때는 옛 것을 지키며 때를 기다리는 것이 좋다. 신은 토(土)로서 상대방은 관귀(官鬼)가 겹친다. 육합․청룡 또는 인묘(寅卯)의 목장(木將)의 귀(鬼)를 도와 상극(相剋) 전쟁상태가 된다. 흉화를 만나면 조심스럽게 예방을 먼저 하고 조용히 하는 것이 좋다. 일지 목(木)에서 미(未)는 묘신(墓神)이 간상(干上)에 복(伏)하여 스스로 혼미한다.
  백법에 말하기를 ｢제3과․제4과가 귀신(鬼神)으로 되는 것은 모든 재해를 만난다｣고 하였다. 비전에 말하기를 ｢삼전의 사중신(四仲神)은 삼교격(三交格)으로 되어 동작과 행지(行止) 모두 목적을 달성하지 못하고, 길흉은 어느 것이나 내부에서 발생한다｣고 하였다. 일귀는 순행하여 삼전이 합(合)․귀(貴)․공(空)이고, 야귀는 청(靑)․상(常)․주(朱)로서 길상이면 근신하고 주의하면 흉을 만나지 않는다. 삼교격(三交格)은 사물의 은닉(隱匿)의 일을 다스린다. 정(靜) 중에 동(動)하려고 하여 동하면 체(滯)가 생기고 굴(屈)하여 전진할 수 없다.
`
  ,
  // 2
  `  이 과는 제4과 축인(丑寅)의 상극을 초전으로 하고 하에서 상을 적하여 중심과이다. 둔간하여 을병정(乙丙丁)이 삼전 중에 있으므로 삼기(三奇格)이며, 축해자(丑亥子)와 역연여(逆連茹)로 된다. 귀신(貴神)이 유(酉)에 임하는 것은 여덕(勵德)이며, 4과를 초전으로 하는 것은 맥월격(驀越格)이다.
  천상삼기(天上三奇)는 일월성(日月星)으로서 삼전을 지키기 때문에 활동하면 길로 되지 않는 것이 없다. 또 중․말전 해자(亥子)는 재성(財星)으로 되고 일간은 왕록(旺祿)이 승하여 일간을 생하므로 구재(求財)하여 얻지 못하는 것이 없다. 흉을 만나도 흉이 없고 길은 더욱 길을 얻는다. 다만 오(午)와 축(丑)은 육해로 되고 자(子)와 오(午)는 충(冲)으로 된다. 그리하여 길중에 부족한 것이 있다.
  백법에 말하기를 ｢삼전 중에 삼기(三奇)를 만나면 존장의 원조가 있고, 흉이 있어도 흉이 변화하여 길조를 얻는다. 왕록이 신(身)에 임하므로 함부로 망동하지 말아야 한다｣고 하였다. 비전에 말하기를 ｢일상신(日上神)과 명상신(命上神)이 상생하고 지합(支合)할 때는 복록(福祿)이 온다. 자축(子丑)이 서로 가해지고 길장이 승하여 사물을 성취하면 기쁨이 후하다｣고 하였다. 삼기(三奇)가 있으면 현임 관리는 승진하고, 시험 및 선거사는 반드시 적중한다. 왕록이 신(身)에 임하면 계획하여도 구하지 못하므로 자연에 맡기면 반드시 행복이 온다. 
  일귀는 순행하여 삼전은 사(蛇)․귀(貴)․후(后)이며, 야귀는 역행하여 삼전이 백(白)․상(常)․현(玄)이다. 관공직에 있는 사람은 공망을 달성하고 상인(常人)에게도 또 기쁨이 있다. 길조는 없을지라도 재해(災害) 역시 없다. 삼전은 해자축(亥子丑)의 수신(水神)으로 비가 올 징조이다. 일간의 화(火)는 극을 당하므로 청천(晴天)을 얻지 못한다.`
  ,
  // 3
  `  이 과는 제3과․제4과의 하에서 상을 적(賊)하여 섭해과(涉害課)로 되고 지반에 맹신(孟神)이 없으므로 중신(仲神)이다. 제3과 축묘(丑卯)를 취하여 초전으로 한다. 이것은 구법이고 여기서는 신법을 취한다.
  삼전의 축해유(丑亥酉)는 극음(極陰)의 격으로서 역간전(逆間傳)으로 되고, 일간상신의 역마가 신(身)에 임한다. 그러므로 초전의 축(丑)은 정신(丁神)으로 되고 동하여 정지(停止)하지 않는다. 말전의 유(酉)에 원무(元武)가 승하므로 일간의 탈기(脫氣)로 되어 공망한다. 극음은 사물을 촌진척퇴(寸進尺退)하고 곤란과 고생을 모두 맛본 후에는 양(陽)으로 전변한다. 일간상신에 길장이 승하여 간(干)을 생하면 존장의 추천과 원조를 얻는다. 백법에 말하기를 ｢말전의 유(酉)는 일지 묘(卯)를 극하므로 가족은 많고 거택(居宅)이 좁다. 청룡이 생기(生氣)에 가할 때는 길이 있더라도 늦다｣고 하였다.
  일귀는 순행하여 삼전이 후(后)․현(玄)․백(白)이고, 야귀는 역행하여 삼전이 현(玄)․후(后)․사(蛇)이다. 신장(神將)이 함께 흉으로 되면 재앙(災殃)은 머지않아 발생한다.
`
  ,
  // 4
  `  이 과는 상하의 상극이 없고 일간에서 지상신(支上神)을 극하여 요극탄사격(遙剋彈射格)으로 된다. 삼전의 사중신(四仲神)은 삼교격(三交格)이며, 귀신(貴神)이 묘(卯)에 임하여 여덕격(勵德格)이다.
  일간상신 진(辰)의 묘신(墓神)이 숨어서 통하기 어렵다. 일지상신 자(子)의 재(財)는 천장(天將) 토신(土神)에 협극(夾剋)되므로 재(財)가 있어도 자유를 얻지 못한다. 중․말전이 공망하여 길사를 정단하여도 바람을 잡는 것처럼 모든 일이 실현되지 못한다. 삼교격(三交格)은 동요(動遙)를 다스리고 인정(人情)이 투합(投合)하지 않으며, 상대방에 이익이 있고 나에게는 이익되지 않는다. 삼전이 공망하여 탄시력(彈矢力)이 없어서 화복(禍福)이 모두 이루어지지 않는다. 백법에 말하기를 ｢교차육해(交車六害)하므로 시의(猜疑)를 일으킨다｣고 하였다. 
  일귀는 순행하여 삼전의 귀(貴)․현(玄)․공(空)이고, 야귀는 삼전이 구(勾)․사(蛇)․음(陰)이다. 천장(天將)에 흉신(凶神)이 많으므로 길조를 형성하지 않는다. 과전(課傳)이 형해(刑害)․지합(支合)․공망(空亡)하여 길흉이 혼합되어 결정하지 못한다. 다행히 중․말전이 공망을 좋아하므로 모두 나타나지 않는다. 공망 중에 천공(天空)이 승하는 것은 어떤 일도 공론(空論), 허화(虛話)이며 공중누각(空中樓閣)과 같다. 여덕격(勵德格)은 현자(賢者)는 때를 기다려야 한다. 또 신불(神佛)에 기도하는 것이 좋다. 
  육임(六壬)은 점시(占時)를 가장 중시하다. 구단자(求斷者)는 오는 사람의 시각에 월장(月將)을 가하여 천반을 만들어 4과를 구성하고 삼전을 성립하여 길흉을 정단한다. 점시(占時)는 선봉문(先鋒門)이라고 하여 길흉을 예측하고 래정(來情)을 안다. 따라서 점시(占時)의 류신(類神)은 점단 사항을 암시하는 자이다. 그리하여 상생․상극을 먼저 보아야 한다.`
  ,
  // 5
  `  이 과는 4과 중에 2상에서 2하를 극하여 섭해과(涉害課)로 되고 사맹상신(四孟上神)의 극인 미해(未亥)를 초전으로 한다. 삼전의 해묘미(亥卯未)가 삼합목국(三合木局)하여 곡직격(曲直格)을 구성한다. 불비격(不備格)으로 되어도 과전이 함께 삼합목국이면 불비(不備)로 되지 않는다. 일지가 간상(干上)에 와서 일간을 극하면 상문란수(上門亂首)라고 부른다. 삼전의 해묘미(亥卯未)는 4과를 떠나지 않는다. 이와 같은 것은 순환을 반복하므로 모든 일이 계속 불안하게 된다.
  섭해과는 고난(苦難)이 머물러 행(行)하려 하여도 행할 수 없다. 처음은 직(直)으로 되고 후에 곡(曲)한다. 어떤 일도 채산(採算)을 해서 하면 유익하지만 산(算)이 없을 때는 패(敗)한다.
  백법에 말하기를 ｢삼전에 천후․육합이 있고 음사(陰私) 불명의 남녀 관계는 정혼(正婚)이 아니다｣고 하였다. 상문란수(上門亂首)는 사심(邪心)이 있어 상(上)을 범한다. 백호가 간상(干上)에 승하여 일간을 극하면 흉해(凶害)가 빨리 발생한다. 비전(秘傳)에 말하기를 ｢비혼(飛魂)․상백(喪魄)의 양자를 띠면 재(災)에 걸려 죽는다｣고 하였다. 삼전 또는 행년상에 백호가 승하여 생기(生氣)를 보지 못하는 것은 흉해가 적절하다.
  일간 상신 관귀(官鬼)는 관공직의 사람에게는 길하고 보통사람에게는 흉하다. 일귀는 순행하여 삼전이 백(白)․합(合)․후(后)이고, 야귀는 삼전이 후(后)․백(白)․합(合)이다. 후(后)로 시작하여 합(合)으로 끝나는 교동격(狡童格)으로서 남녀가 부실(不實)의 관계를 맺는다.
  삼전의 해묘미(亥卯未)는 삼합목국 곡직과(曲直課)이며 삼전 인묘진(寅卯辰)의 동방목합(東方木合))과 같은 이치(理致)이다. 목(木)은 공작(工作)에 의해 범백(凡百)의 용기(用器)를 형성한다. 수목(樹木)은 곧게 되어 굴절(屈折)이 있는 것은 그 체(體)이다. 춘월(春月)에 시령(時令)을 얻어 양기(陽氣)가 흥성하고 또 굴신(屈身)한다. 항상 움직이면 화창(和暢)하고, 정지하는 것은 무성하다. 재(財)를 구하면 3배의 이익이 있다. 정단사는 직(直)을 바라면 먼저 곡(曲)하는 것이 좋다. 질병과 소송사는 마디(節外) 밖에 가지를 생하는 것처럼 만연한다. 명리를 구하는 일을 급속하게 바라면 달성하지 못하며 대기만성(大器晩成)격으로 때를 기다려야 한다.`
  ,
  // 6
  `  이 과는 제1과․제2과․제3과의 상하에 극적(剋賊)이 있고 하에서 상을 극한다. 술묘(戌卯)를 취하여 초전으로 하는 중심과이다. 일지 상신 술(戌) 자(字)를 초전으로 하면 참관격(斬關格)으로 되고, 난(難)을 피하여 도망하는 것이 좋다. 극이 많으므로 고심, 노력하여 목적을 달성할 수 있다.
  일간상신은 관귀효(官鬼爻)로서 내몸을 극하여도 일덕(日德)․복신(福神)이므로 흉이 변하여 길조로 된다. 주작이 일지상신의 택상(宅上)에 있어 초전을 이루면 문장사(文章事)․구설 또는 사용인이 음모를 꾸미는 형세가 있다. 
  백법에 말하기를 ｢사(巳)가 술(戌)에 백호가 묘(墓)에 임하면 선조의 묘(墓)에 이상이 생하는 징조가 있다｣고 하였다. 비전에 말하기를 ｢주작은 남방 문서의 신으로 진술(辰戌)에 승하면 배역(背逆)을 방지해야 한다｣고 하였다. 관성(官星)이 재(財)에 임하므로 의신(儀神)의 발용(發用)으로 명리를 구하여 성공한다. 일귀는 역행하여 삼전이 주(朱)․백(白)․귀(貴)이고, 야귀는 삼전이 주(朱)․현(玄)․구(勾)이다. 말전의 자(子)는 사(巳)에 가해져 질병의 정단은 사(死)라고 알 수 있다.
`
  ,
  // 7
  `  이 과는 천지반이 칠충(七冲)으로 되어 반음과(返吟課)이다. 제4과의 하에서 상을 적(賊)하고 묘유(卯酉)를 초전으로 하며 조상법(照常法)에 따라 삼전을 구성한다. 반음(返吟)은 의지할 곳 없는 격이며, 묘유(卯酉)가 서로 가하여 발용(發用)으로 된다. 어떤 일도 불화하고 친근함이 없으며 만사는 반복된다. 다행히 삼전이 모두 공망을 만나기 때문에 흉을 보아도 흉을 이루지 않는다. 
  사신(蛇神)이 택상(宅上)에 임하여 택을 극한다. 이것이 또 공망하므로 허(虛)의 놀라움이 있을 뿐이다. 삼사(三蛇)․사호(蛇虎)가 전(傳)에 들어가 도난․음사(陰私)․병소(病訴) 등의 류신으로 되어도 공망하므로 허성(虛聲)뿐이고 실해(實害)를 발생하지 않는다.
  백법에 말하기를 ｢천후(天后)에 육합이 승하여 결혼은 중매인이 없이 하게 되며, 거래격(去來格)이 공망하여 이동하지 않는다. 이 과를 얻을 때는 안전을 비는 것이 좋다. 일귀는 역행하여 삼전이 현(玄)․합(合)․현(玄)이고 야귀는 백(白)․사(蛇)․백(白)이다. 어느 것이나 천장(天將)이 악장(惡將)으로 되어도 공망하므로 흉을 보아도 흉해를 만나지 않는다.
`
  ,
  // 8
  `  이 과는 4과 중에 제1과․제2과․제3과에 상하의 극이 있다. 하에서 음일(陰日)로 되면 음비(陰比)의 극인 사자(巳子)를 초전으로 하고 삼전이 사술묘(巳戌卯)로서 주인격(鑄印格)으로 된다. 지일과(知一課)는 사건이 동류에 의해 발생하고 은(恩) 중에 해(害)가 있다.
  일귀는 간상(干上)에 승하여 재(財)로 되고 야귀는 지상(支上)에 승하여 묘(卯)의 택(宅)을 극하므로 가옥을 손상한다. 초전의 사화(巳火)는 일간을 생하고 말전 묘(卯)의 목(木)은 일간의 귀(鬼)로 되지만 귀(鬼)는 초전에서 생화(生化)하므로 오히려 유익하게 된다. 
  백법에 말하기를 ｢말전은 초전을 생하고 돕는 법에는 삼구분(三區分)하여 얻는다｣고 하였다. 백법의 제37법칙에 해당하는 것으로써 그 항목을 참고하기 바란다. 비전에 말하기를 ｢택상(宅上)의 귀신(貴神)이 공망한 것은 신불(神佛)에 불경(不敬)한 것이 된다. 또 주인격(鑄印格)에 현무가 승하면 규범(規範)을 파하고 백호가 승하면 규모(規模)를 손상한다｣고 하였다. 백호가 묘(卯)에 승하면 금(金)이 묘목(卯木)을 손상하므로 수양(隨陽)이라고 부르며, 부정한 일을 획책하게 된다. 호언미담(好言美談)은 교묘하더라도 공명은 이룩할 수 없다. 일귀는 역행하여 삼전이 백(白)․주(朱)․현(玄)이고, 야귀는 순행하여 삼전이 현(玄)․주(朱)․백(白)이다. 질병․소쟁(訴爭) 등은 불길하다.
`
  ,
  // 9
  `  이 과는 4과 중에 제3과까지 하에서 상을 극하여 섭해과(涉害課)로 된다. 맹신(孟神)의 극이 없는 것으로 중신(仲神)의 극인 제3과의 미묘(未卯)를 써서 초전으로 한다. 삼전사과가 진하여 해묘미(亥卯未)의 삼합목국(三合木局)으로 되고 곡직격(曲直格)이다.
  4과 중에 3과밖에 없으므로 불비격(不備格)일지라도 동일한 삼합(三合)뿐인 과식은 불비격이더라도 경미하다. 삼전 목국(木局)은 중귀(衆鬼)로 되고 해(亥)의 재(財)는 귀(鬼)를 부조하므로 재(財) 또는 이성(異性)의 사건으로 행동이 지나쳐 망동하면 후회를 불러온다. 구재(求財)에 대하여는 충분히 주의하여 수득해야 한다. 매매(賣買) 등은 파란 기복이 있어도 결국 이익을 신속히 얻는다.
  백법에 말하기를 ｢후(后)․합(合)이 있어 결혼은 자유연애이다｣라고 하였다. 일야(日夜) 양귀신(兩貴神)이 함께 공망한 것은 기쁜 일이 있어도 공허(空虛)한 징조가 있다. 비전에 말하기를 ｢간지(干支)가 삼합(三合)하고 삼전이 사과(四課)를 떠나지 않으면 회환격(廻環格)이다｣라고 하였다. 흉을 정단하면 흉을 만나고 길을 정단하면 길을 만난다. 그러므로 과거의 상황을 반복하는 것이다. 요컨대 길흉이 함께 완전하지 않은 것을 보통으로 한다.
  삼전은 4과로부터 나와서 타(他)에 가하지 않는다. 예를 들면 이과와 같이 초전의 미(未)는 제4과이고 중전은 제1과, 말전은 제2과로 구성되었다. 일간 기궁(寄宮)의 미(未)는 지상(支上)에서 묘(卯)의 목(木)에 의해 극을 당한다. 간(干)이 지(支)에 가해지면 부(夫)가 처궁(妻宮)에 붙으므로 췌서격(贅婿格)이라고 한다. 또 타인의 권세에 아부하여 따르는 상이다. 일귀는 역행하여 삼전이 청(靑)․사(蛇)․현(玄)이고, 야귀(夜貴)는 순행하여 삼전이 후(后)․합(合)․백(白)이다. 백호는 귀살(鬼殺)에 승하여 질병의 정단은 두렵다.`
  ,
  // 10
  `  이 과는 제4과의 일하가 일상을 극하고, 유오(酉午)를 초전으로 하는 중심과(重審課)로 된다. 삼전의 사중신(四仲神)은 삼교격(三交格)으로 되고, 제4과를 초전으로 하는 것은 맥월격(驀越格)이다. 간상신 술(戌)과 일지 묘(卯)가 지합(支合)하고 지상신 오(午)와 기궁(寄宮) 미(未)가 지합하며 서로 합하면 교차지합격(交車支合格)이다.
  초전의 공망은 고진격(孤辰格)으로 되고 골육친신(骨肉親身)으로 되지 않고 고독하게 이별하거나 타향으로 떠난다. 삼교격(三交格)은 물건(物件)을 은닉(隱匿)하는 일이나 재해를 피하는 것이 좋다. 그러나 청룡․태상이 승하므로 흉중에 진정한 일이 있다. 교차지합하므로 교섭사는 타협하고 상합화호(相合和好)로 되어 길조를 형성한다. 초전이 공망하므로 길이 감소되고 흉을 제거하여 의혹을 해소한다.
  백법에 말하기를 ｢권섭이 바르지 않은 것은 일지 상신이 건록을 형성하기 때문이다｣고 했다. 가택(家宅)에 녹(祿)을 얻는다는 것은 일간의 건록이 일지상신에 해당하는 것을 말한다. 교차상합(交車相合)할 때는 모든 교역․담합(談合)․악수(握手)는 모두 길행(吉幸)이 있다. 말전에 현무가 승하여 일간을 극하기 때문에 도난․분실 등의 흉이 있다. 그러나 일간상신 술(戌)과 묘(卯)가 지합하기 때문에 흉 중에 길로 전변한다. 일귀는 역행하여 삼전이 합(合)․귀(貴)․현(玄)이고, 야귀는 삼전이 후(后)․상(常)․청(靑)의 길장이다.`
  ,
  // 11
  `  이 과 중에는 상하의 극이 없고 제2과 해(亥)를 일간에서 극하여 요극탄사격(遙剋彈射格)으로 된다. 삼전의 해축묘(亥丑卯)는 순간전격(順間傳格)이고 명몽격(溟瀾格)이라고도 부른다. 일출(日出)은 몽(瀾)과 명(明)의 경지(境地)이고 묘(卯)는 일출(日出)의 문(門)이므로 명몽이라고 부르며 양계(陽界)에 오른다.
  처음으로 관리가 되는 일에 착수하는 것은 모두 길하다. 탄사(彈射) 해(亥)의 재(財)는 공망에 앉으므로 재력(財力)은 박약하고 출비가 많으며 길흉이 모두 이루어지지 않는다. 육음격(六陰格)은 공(公)에 불리하고 사사(私事)에 유리하다. 음극(陰極)하여 양(陽)을 생할 때는 기다려서 광명을 접해야 한다.
  백법에 말하기를 ｢육음이 서로 연속되는 것은 혼미가 많고, 양귀(兩貴)가 극을 받는 것은 귀인이 번민하며, 일지(日支)를 벗어나면 인택이 모두 도난 손실을 초래한다｣고 하였다. 비전에 말하기를 ｢명몽격(溟瀾格)은 음모(陰謀)나 사사(私事)에 유리하고 공사(公事)에는 불리하디｣고 하였다. 초전이 공망한 것은 공비출재(空費出財)가 높다. 사(巳)에 백호가 승하면 가족이 질병에 걸리고, 재(財)가 천중살(天中殺)을 만나면 가업(家業)이 쇠퇴한다. 천중살이란 공망을 말한다.
  일귀는 역행하여 삼전이 사(蛇)․후(后)․현(玄)이고, 야귀는 삼전이 현(玄)․백(白)․청(靑)이다. 초전 현(玄)의 재신(財神)에 사(蛇)․현(玄)의 흉장(凶將)이 승하여 공망의 지(地)에 있어서 길흉이 함께 실력이 없다.`
  ,
  // 12
  `  이 과는 재3과의 진묘(辰卯)가 일하에 일상을 적(賊)하여 초전으로 되는 중심과이다. 삼전 진사오(辰巳午)로 진여격(進茹格)이다. 일간상신에 귀신(貴神)이 승하여도 공망하므로 만나지 못하며, 일지상신은 간(干)의 묘신(墓神)의 택상(宅上)에 임한다. 초전에 묘신이 승하고 말전이 건록으로 되어 처음에는 미혹되거나 지체되고 후에는 호전되어 광휘를 발한다.
  진여격은 만사가 활동하면 길하고 진보되지만 늦을 때는 장해가 발생한다. 또한 급진(急進)하면 유리하다. 어떤 일도 말전은 결과로서 좋다. 귀신(貴神)이 해(亥)에 임하면 귀문등용(貴門登龍)이라고 부르며 만사를 갱신(更新)하여 외출에 의한 활동․은피(隱避) 등에 유리하다. 백법에 말하기를 ｢나망(羅網)을 만나거나 함부로 모사(謀事)를 계획하는 것은 졸책(拙策)으로 된다｣고 하였다. 진사오(辰巳午)는 승계(昇階)라고 하여 광명을 나타내고 희망하는 일은 달성한다.
  일귀는 순행하여 삼전이 구(勾)․청(靑)․공(空)이며 귀인이 천문(天門)에 오른다. 야귀는 역행하여 삼전이 구(勾)․합(合)․주(朱)이다. 귀신(貴神)이 일간상신에 승하는 것은 시험이나 선거사는 공망이더라도 모두 적중된다. 지상(支上)의 진(辰)이 초전을 구성하는 것은 참관격(斬關格)이 되는데, 토(土)는 사람의 통행을 저해하므로 참(斬)하여 통하는 것은 길이 되고, 참하여 통하지 않는 것은 흉하다. 중․말전에 자축인묘미신유(子丑寅卯未申酉)의 어느 것인가가 있으면 관소(關所)를 통하여 나온다.
  이상의 칠진(七辰)은 참관(斬關)을 능히 열고 진술(辰戌)은 강괴(罡魁)로서 무진(戊辰)․무술(戊戌)로 되기 때문에 자축(子丑) 중에 계(癸)가 있어서 간합(干合)하고 인묘미(寅卯未)는 목(木)으로써 진술(辰戌)을 제어한다. 신(申)은 진(辰) 중의 을(乙)과 간합하고 유(酉)와 진(辰)은 지합(支合)한다. 술(戌)은 신금(辛金)의 본위(本位)이므로 능히 통한다. 또 삼전 중에 귀(貴)․합(合)․청(靑)․호(虎)․음(陰)․상(常)․후(后)의 칠장(七將) 중 1장만 있어도 역시 참관이 통한다. 참관이 통할 때는 병점(病占)․소송․여행․합약(合藥)․기도(祈禱)․피익(避匿) 등은 어느 것이나 길하다. 만약 통하지 않을 때는 옛 것을 지키는 일이나 도적을 잡는 것 등은 모두 대소를 불문하고 흉이 복재(伏在)한다.`
  ,
]

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
