
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,8,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,8,7,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,9,6,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,10,5,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,4,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,3,`특별있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,1,2,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,2,1,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`신뢰`,3,12,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,4,11,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,5,10,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`불성`,6,9,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`직녹`,`직위녹위권리 관련사-내女 색정사 有`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8.,10,11월 방문자는 종교진출하면 성공함`,`小`,`1`,`부부화합`,`색정사有`],
    [`매사 영속성없음`,`직위녹위권리 관련사는 선천적 기복재해를 겪음`,`大`,`절정기`,`百중十득`,`근신요됨`],
    [`모이유생(耗而有生)`,`비용지출이 있은 후 득이 있게 되는 상`,`大`,`직녹무망`,``,``],
    [`반화위복(反禍爲福)`,`화난이 소제되고 복을 맞는 상`,`小`,`종교자립`,``,``],
    [`관사`,`관사 직업 권리 남편 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`2`,`외사막힘`,`침체공허`],
    [`매사 영속성없음`,`관사직업남편관련사는 선천적 숙명재해를 겪음-독신고독`,`小`,`면모기`,`구연반복`,`간인궤계`],
    [`생의영창(生宜榮昌)`,`활동을 잘하여 영창함을 이우는 상`,`小`,``,``,``],
    [`봉상희경(捧觴喜慶)`,`고위로부터 술잔을 내려받으며 기쁨을 참는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,`애증화합`],
    [`극부`,`남편 갈등 자식 관련사`,`小`,`3`,`직위불안`,`파재극처`],
    [`급속`,`외사 급속사`,`小`,`학습기`,`급속사有`,`실종가출`],
    [`동과귀제(動過貴濟)`,`지나친 활동에 대하여 윗인이 절제를 당부하게 되는 상  `,`大`,``,`소인쟁재`,`면모훼손`],
    [`와병불기(臥病不起)`,`병이 깊어 자리를 털고 일아나지 못하는 상`,`小`,``,``,`소식대기`],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`결속`,`결속사로 財기반을 크게 세우나 명예직위권리남편 훼절함`,`小`,`4`,`외사수난`,`결속단합`],
    [`결속`,`내사 복잡 단합사`,`小`,`개시기`,`事後쟁탈`,`골육빙탄`],
    [`좌익동부(座益動不)`,`소극적이 이익이고 적극적이면 되려 불이익이 되는 상`,`大`,``,``,``],
    [`문화풍아(문화풍아)`,`한가하여 책을 들여다보는 상-실업저 격`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`남편`,`남편 관련사`,`大`,`5`,`파재극처`,`압박받음`],
    [`파재`,`외사 파재극처실종 관련사`,`小`,`배양기`,`도적失勢`,`탄식신음`],
    [`가처재손(家妻財損)`,`家中 금전 처가 손훼되는 상`,`小`,``,``,`병,事미수`],
    [`문서상신(文書上申)`,`자식 발전을 위해 상신한 문서의 답이 지체되는 상`,`大`,``,``,``],
    [`금전`,`금전 처 관련사`,`大`,``,``,`동요불안`],
    [`동요`,`내사 동요불안 분산사`,`大`,`6`,`개신결여`,`분산기로`],
    [`출행`,`내사 출행이동 기로사`,`小`,`존재기`,`투서헌책 `,`출행이동`],
    [`취재상신(取財傷身)`,`지나친 취재활동은 자칫 몸을 상하게 되는 상`,`小`,``,`(나쁨)`,`가중喪事`],
    [`천발도약(薦拔跳躍)`,`추천발탁되어 도약하게 되는 상`,`小`,``,``,``],
    [`금전`,`금전 여인 관련사`,`大`,``,``,`표은남산`],
    [`색정`,`외男 색정사`,`大`,`7`,`색정사有`,`(산속으로`],
    [`도망`,`외사 증오 도망사`,`小`,`단절기`,`증오도망`,`숨고싶음)`],
    [`점소개기(店所皆忌)`,`교역을 위한 점포 등을 애써 개설하지 말라-는 상`,`小`,``,`물사復起`,`비곡수욕`],
    [`문서엄동(文書暗動)`,`문서가 몰래 넘겨지는 상`,`小`,``,``,`(훼절)`],
    [`결속`,`결속사로 음12월 후원사 크게 완성하나 자녀재해 발생함`,`小`,``,``,``],
    [`남편`,`남편 관련사`,`大`,`8`,`동요분산`,`압박받음`],
    [`동요`,`외사 동요불안분산사`,`小`,`침체기`,`침체공허`,`사행불량`],
    [`불가망동(不可妄動)`,`일시적 좋은 때를 맞아 지나치게 행동하지 말라-는 상`,`小`,``,`발탁도약`,``],
    [`관재형책(官災刑責)`,`관재 형책사가 있는 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`급속`,`내사 급속사`,`小`,`9`,`면모훼손`,`급속사有`],
    [`가출`,`내사 손해 가출사`,`大`,`소외기`,`표은남산`,`손해가출`],
    [`상퇴관영(常退官榮)`,`일반인은 물러나야 좋고 관직자는 나아가 영창하는 상`,`小`,``,`(산속깊이`,`捕難尋獲`],
    [`비곡수욕(悲哭羞辱)`,`면모가 훼손된 수치를 당하여 슬피 우는 격의 상-훼절`,`大`,``,`숨고싶음)`,`(못잡음)`],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`개신`,`외사 개신 개혁사`,`小`,`10`,`개신개혁`,`후원수난`],
    [`모친`,`모친 관련사`,`大`,`침해기`,`가족질환`,`불비부족`],
    [`가면재액(可免災厄)`,`방해와 침해가 있으나 모두 면할 수 있게 되는 상`,`小`,``,``,``],
    [`존비상몽(尊卑相蒙)`,`신분과 나이차이가 큰 동침의 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`남편`,`남편 관련사`,`大`,`11`,`송쟁파재`,`침체驚懼`],
    [`송쟁`,`외사 급속송쟁 실종사-상해 우려`,`小`,`쇠퇴기`,`상해우려`,`驚疑불녕`],
    [`수세신중(守勢愼重)`,`잘 되는 것 같더라도 세를 지키고 신중해야만 하는 상`,`小`,``,`우유閑暇`,``],
    [`존비상몽(尊卑相蒙)`,`신분과 나이차이가 큰 동침의 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`출행`,`외사 출행원행이동 기로사`,`小`,`12`,`기로출행`,`가정막힘`],
    [`막힘`,`내사 가정 막힘사`,`大`,`과시기`,`타인의존`,`상喜하僧`],
    [`최의식록(最宜食祿)`,`식녹사가 매우 좋은 상`,`大`,``,``,``],
    [`존비상몽(尊卑相蒙)`,`신분과 나이차이가 큰 동침의 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`세속하사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안여식사남자갈등사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`관사직업남편파재극처사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,`금전사업처조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,`금전사업여인조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`관사직업남자동요불안분산사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`후원문서부친조력가출사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`후원문사개신모친조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사직업송쟁사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,`형제친구동료조력출행사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결해야만 유리함`,0,0,`명분이 있으므로 정정당당하게 속결처리 않고 조고우면하거나 연기지체하면 결과가 되려 불리해지는 人`,0,0,`일이 거구로 진행됨**산속으로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威 勢)세**만약 타인과 다툰다면 상대는 호가호위 세를 부릴 뿐 피해가 없고 있다해도 가벼움`,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란 듯한 심중이나 조용히 있으면 두려운 일이 자연 사라지게 되는 人`,0,0,`일이 거꾸로 띄엄띄엄 진행됨**여건조건이 부족불비함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`동료와의 동업 또는 협동사로 중상모략적 혐의로 불안기로에 서있으나 가장 친하거나 가장 가까이 거주하는 자와 의논하면 해결되는 人 `,0,0,`신규사가 있음**만사 길조의 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인고 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 사안을 뒤로 미루아야 함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 복잡한 일을 하나로 묶어 재정의 기반을 세움-단, 명예권리직위가 훼절됨-女는 남편과 생리사별하게 됨**육친 윗인이 아래인을 핍박함**일이 결과없이 반복되나 역시 어두워짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`권리사가 있음**관직자는 영전함**정대한 엣일이 끓어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화가 밖에서 오니 신중해야 함`,0,0,`반복무상-배신사를 겪는 人`,0,0,`신규사가 있음**가택이사함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고  순응유화적 처신으로 사안을 연기지체시켜야만 결과가 유리함`,0,0,`역상적 사안이므로  억울하더라도 논쟁을 말고 유순하게 대응하여 후결하는 것으로 하면 결과가 반드시 유리해지는 人`,0,0,`산속으로 깊이 숨고싶은 심정임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인고 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 사안을 뒤로 미루아야 함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 하나로 묶는 개혁사 있음-단, 음12월에 완성하게 되나 자녀신변근심이 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결해야만 유리함`,0,0,`명분이 있으므로 정정당당하게 속결처리 않고 조고우면하거나 연기지체하면 결과가 되려 불리해지는 人`,0,0,`토지가옥사-대립 소송건**산속으로 숨고싶은 심정 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인고 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 사안을 뒤로 미루아야 함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 띄엄띄엄 진행되어 퇴함이 좋음**여건조건이 부족불비함**身宅이 불안함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결해야만 유리함`,0,0,`명분이 있으므로 정정당당하게 속결처리 않고 조고우면하거나 연기지체하면 결과가 되려 불리해지는 人`,0,0,`일이 순서적으로 진행되는 듯하나 실효가 없으므로 결국 퇴함이 좋음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 엿보고 움직이지는 않음`,0,0,`스스로 유약하게 여기고 적금적 활동을 하지 않으며 신음하는 人`,0,0,`토지가옥 관련 송쟁사가 있음**일이 결과없이 반복되기만 하다가 비로서 밝아짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`미사`,`신구중간사`,`외사 `,`이면사`,`좌고우면-무력`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신-무력`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`아발동사후회사겹침`,`기사`,`구사`,`외사`,`표면사이면사겹침`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`애증화해`,`애증화해`,`애증화해`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,` 무력`],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월`,2,2,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음1월`,2,3,5,`음7월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월-연기우려`,2,3,5,`음6월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음1월`,2,2,4,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월-파혼우려`,3,1,4,`음6월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월-연기우려`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월-연기우려`,2,3,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,1,1,2,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음1월`,3,3,6,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서고 배경이 없어 업무 초반이 침체하고 열 중 하나의 실적일 뿐임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 조건 여건이 부족한 중 대체로 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내나 업무실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 여러 일을 일관시키는 결속 단합으로 재정의 기반을 세우나 명예권리가 추락됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이나 권리사를 확보함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무 전체가 동요분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무에 집착을 못하고 회피 방기함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯해도 조정능력이 있어 결속단합하여 개신개혁을 음12월에 완성하는 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 전반적인 실패를 초래하고 종반에 반목대립송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무 초반과 중반을 실패하고 종반에 이르러서야 실적을 보임 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무 전반이 실패로 간주됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 대립 송쟁사를 일으키며 업무 전반을 실패하게 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`본인무력`,`退背`,`원`,`去`,`주인가해`,`정남`,``,`정동`],
    [`10.11`,``,`끌면제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`불이행`,`선이후이`,`進向`,`  근`,`  留`,`주인조력`,`남남서`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선이후난`,`退背`,`  원`,`  去`,`주인조력`,`서남`,``,`남남동`],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선난후이`,`進向`,`  원`,`  留`,`주인가해`,`정서`,`정남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선攻후난`,` 進向`,`  원`,`  留`,`세입가해`,`동남`,`남남서`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`본인반대`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`발현안됨`,`불이행`,`선攻후이`,`退背`,`원근왕래`,`  去`,`세입가해`,`북북서`,`서남`,``],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`참고`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`불이행`,`선난후費`,`進向`,`  근`,`  留`,`주인가해`,`정남`,`정동`,``],
    [`10.11`,``,`본인동조`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`內側近制`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인동조`,`불이행`,`선난후이`,`進向`,`  원`,`  留`,`세입조력`,`남남서`,`동남`,``],
    [`10.11`,``,`측근제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`극렬반대`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선난후난`,`退背`,`원근왕래`,`  去`,`세입조력`,`서남`,`남남동`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`사안무력`,` 退背`,`  원`,`  去`,`주인무력`,`정서`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`사안무력`,`退背`,`  근`,`  去`,`주인무력`,`동남`,``,`남남서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`   小`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대극렬`,`불이행`,`본인무력`,`退背`,`  근`,`  去`,` 무난`,`서남`,``,`남남동`],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  小`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,`전흉사귀`,`유혈사망`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`전흉사귀`,`유혈사망`,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,`제례귀`,`제례위법`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`가택귀`,`호주사망`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,`음귀`,`묘지탈`,0,0,`묘좌`,`남남서`,`없음`],
    [``,``,``,0,0,`묘향`,`북북동`,``],
    [`생업`,``,``,0,0,`전경`,`바다포구`,``],
    [``,``,``,0,0,`묘속`,`목근`,``],
    [`가정`,`음귀`,`묘지탈`,0,0,`육친`,`5대조부`,``],
    [`질병`,`전흉사귀`,`유혈사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`특별있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`여귀`,`호주부인`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`여귀`,`호주부인`,0,0,`육친`,``,``],
    [`질병`,`제례귀`,`제례위법`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`여귀`,`변사귀`,0,0,`묘좌`,``,`있음`],
    [``,`가택귀`,``,0,0,`묘향`,``,``],
    [`생업`,`여귀`,`호주부인`,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,` 음귀`,`묘지탈`,0,0,`묘좌`,``,`있음`],
    [``,``,`공묘무기`,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,`  空`,``],
    [`가정`,`음귀`,`공묘무기`,0,0,`육친`,``,``],
    [`질병`,`음귀`,`묘지탈`,0,0,`묘좌`,``,`있음`],
    [``,``,`공혈`,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,`공혈무기`,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`음귀`,`전흉사귀`,0,0,`묘좌`,`북북사`,`있음`],
    [``,`전사귀`,``,0,0,`묘향`,`남남동`,``],
    [`생업`,`음귀`,`묘지틸`,0,0,`전경`,`바다포구`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,``,``,0,0,`육친`,`조부`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사출행원행기로사`,`상희하승사`,`매사 허성불성-세속허사자**도적에 놀람-실재근심사`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음**尊卑동침사`,`종교진출하면자립함`,`외사 급속사**외사 애증화합-파재극처실종가출사`,`우유한가-실업저 상`,``,`관계불통-가택이사-가족질병사`,`매사 위역사`,`재난전소사`,`내사충행이동기로사`,`문서소식사 길사-송사 흉-이동사`,`외남 색정사**외사 증오도망사`,`입실성사사-남녀관계`,`외사 동요분산사`,`왕래사송계류사`,`내사 급속사**내사 가출사`,`상린충산사`,``,`간인궤계사`,`매사 위역사**송쟁파재극처실종사`,`반화위복사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도`,`내녀 색정사`,`전도**빈궁`,`선천 숙명적 독신고독자`,`빈궁`,`재액해소**전화위복`,``,`재액해소`,`관재`,``,`빈궁**관재`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`침체驚懼사`,`관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외사 송쟁파재실종사`,`소인쟁재사`,`외사출행원행기로사`,`牽連반보가`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**선천숙명적 독신고독자`,`종교진출하면자립함`,`외사 급속사**외사애증화합- 파재극처실종가출사`,`왕세즉진향-쇠즉퇴장사`,``,`관계불통-가택이사-가족질병사`,``,`비곡수욕사-훼절`,`내사출행이동기로사`,`臥病불기사`,`외남 색정사**외사 증오도망사`,`도적악의-공격말 것`,`외사 동요분산사`,`미초희경사-고위술잔내려받음`,`내사 급속사**내사 가출사`,`좋은 소식사`,``,`간인궤계사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전화위복`,`전도**빈궁`,`내녀 색정사**부부반목사`,`전도`,`불리고시사-구설사`,`빈궁`,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,``,`관재`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁**관재`,``,`관재`,``,`빈궁**병재`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`간인궤계사`,`외사 송쟁파재실종극처사`,`소인쟁재사`,`외사출행원행기로사`,`牽連반복사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**선천 숙명적 독신고독자`,`종교진출하면자립함`,`외사 급속사**외사 애증화합-파재극처실종가출사`,`왕세즉진향-쇠즉퇴장사`,``,`관계불통-가택이사-가족질병사`,``,`비곡수욕사-훼절`,`내사출행이동기로사`,`臥病불기사`,`외남 색정사**외사 증오도망사`,`도적악의-공격말 것`,`외사 동요분산사`,`미초희경사-고위술잔내려받음`,`내사 급속사**내사 가출사`,`좋은 소식사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`관재`,``,`재액해소**전화위복`,`빈궁`,`내녀 색정사**부부반목사`,``,`불리고시사-구설사`,`빈궁`,`전화위복`,`빈궁`,``,``,`관재`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,`관재`,``,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`도적절족실세-잡음`,`내사 급속사*내사 가출사`,`事後쟁탈사`,``,`반화위복사`,`매사 위역사**외사 송쟁파재극처실종사`,`외사출행원행기로사`,`간인궤계사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**선천 숙명적 독신고독자`,`종교진출하면자립함`,`환성경사`,`외사 급속사**외사 애증화합-파재극처실종가출사`,``,`자식위한문서상신답신지체사-구설사`,`재난전소사`,`매사 위역사`,``,`내사 출행이동기로사`,`외남 색정사**외사 증오도망사`,`탄식신은사-병 또는 주력사 미수`,`외사 동요분산사`,`觀書-문화풍아-실업자 상`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,``,``,`관재**병재`,``,`전화위복`,`전도**빈궁`,`비상지애사**내녀 색정사`,`전도`,`남모르는능욕박해사`,`빈궁`,`재액해소**전화위복`,``,`재액해소**`,`빈궁**관재`,`빈궁**관재`,``,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,``,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`觀書-문화풍아-실업저 상`,`외사 동요분산사`,`도적절족실세-잡음`,`내사 급속사**내사 가출사`,``,`事後쟁탈사`,`반화위복사`,`매사 위역사**외사 송쟁파재극처실종사`,`외사출행원행기로사`,`간인궤계사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**선천숙명적 독신고독자`,`종교진출하면자립함`,`환성경사`,`외사 급속사**외사 애증화합-파재극처실조가출사`,``,`자식위한문서상신답신지체사-구설사`,`재난전소사`,`매사 위역사`,``,`내사출행이동기로사`,`외남 색정사**외사 증오도망사`,`탄식신음사-병 또는 주력사 미수`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**관재`,``,`재액해소`,`빈궁**병재`,`재액해소`,`관재`,``,`전도**관재**병재`,`전화위복`,`빈궁`,`비상지애사**내녀 색정사`,``,`남모르는 능욕박해사`,`전도**빈궁`,`전화위복`,`관재`,`빈궁`,``,`전도**빈궁**관재`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`외남 색정사**외사 증오도망사`,`불변근신사`,`투서헌책불리사-실재사`,`외사 동요분산사`,`혼인만족-단장사`,`내사 급속사**내사 가출사`,`관재형책사`,``,`외사 송쟁파재극처실종사`,`소인쟁재사`,`외사출행원행기로사`,`간인궤계사`,`매사 영속성없음**소식불통사-와도나쁨`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함**영전도약사-부족`,`도적포획난사`,`외사 급속사**외사 애증화합-파재극처실종가출사`,``,`사행불량사`,``,`비곡수욕사-훼절`,``,`내사출행이동기로사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁`,`동요분산사`,`빈궁**관재`,`동요분산사`,`전도**관재`,`동요분산사`,`전도**빈궁`,`동요분산사`,`관재`,`동요분산**재액해소**전화위복`,`빈궁**관재`,`내녀 색정사**동요분산사`,`빈궁**병재`,`동요분산사`,`선천숙명적 독신고독자`,`동요분산사**전화위복`,`빈궁`,`동요분산사`,`빈궁`,`동요분산사`,`관재`,`음4,5월화재주의**동요분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,``,`내사출행이동기로사`,`외남 색정사**외사 증오도망사`,`불변근신사`,`투서헌책불리사-실재사`,`외사 동요분산사`,`혼인만족-단장사`,`내사 급속사**내사 가출사`,`관재형책사`,``,`소인쟁재사`,`외사송쟁파재극처실종사`,`외사출행원행기로사`,`간인궤계사`,`매사 영속성없음**소식불통사-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`도적포획난사`,`외사 급속사**외사 애증화합-파재극처실종가출사`,``,`사행불량사`,``,`비곡수용사-훼절`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁**관재`,``,`전도**관재`,``,`전도**빈궁`,``,`관재`,`재액해소**전화위복`,`빈궁**관재`,`내녀 색정사`,`빈궁**병재`,`선천숙명적 독신고독자`,`영전도약사-부족`,`전화위복`,`빈궁`,``,`빈궁**관재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`비곡수욕사-훼절`,``,``,`내사출행이동기로사`,`외남 색정사**외사 증오도망사`,`불변근신사`,`투서헌책불리사-실재사`,`외사 동요분산사`,`혼인만족-단장사`,`내사 급속사**내사 가출사`,`관재형책사`,``,`소인쟁재사`,`외사 송쟁파재극처실종사`,`외사출행원행기로사`,`간인궤계사`,`매사 영속성없음**소식불통사-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`도적포획난사`,`외사 급속사**외사 애증화합-파재극처실종가출사`,``,`사행불량사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`관재`,`전화위복`,`빈궁`,`내녀 색정사`,`빈궁**병재`,`선천 숙명적 독신고독자`,`영전도약사-부족`,`전화위복`,`전도**빈궁**관재`,`전도**빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,``,`사행불량사`,`비곡수욕사-훼절`,``,``,`내사출행이동기로사`,`외남 색정사**외사 증오도망사`,`불변근신사`,`투서헌책불리사-실재사`,`외사 동요분산사`,`혼인만족-단장사`,`내사 급속사**내사 가출사`,`관재형책사`,``,`소인쟁재사`,`외사 송쟁파재극처실종사`,`외사출행이동원행사`,`간인궤계사`,`매사 영속성없음**소식불통사-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음**숙명적 독신고독자`,`종교진출하면자립함`,`도적포획난사`,`외사 급속사**외사 애증화합-파재극처실종가출사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁`,``,`관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁**관재`,``,``,`빈궁`,``,`관재`,``,`전화위복`,`전도**빈궁`,`내녀 색정사`,`전도**빈궁**병재`,`선천 숙명적 독신고독자`,`영전도약사-부졷`,`재액해소**전화위복`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사 급속사**외사 애증화합-파재극처실종가출사`,`우유한가-실업저 상`,``,`관계불통-가택이사-가족질병사`,`매사 위역사`,`재난전소사`,`문서소식 길사-송사 흉-이동사`,`내사출행이동기로사`,`외남 색정사**외사 증오도망사`,`입실성사사-남녀관계`,`왕래사송계류사`,`외사 동요분산사`,`내사 급속사*내사 가출사`,`상린충산사`,``,`간인궤계사`,`매사 위역사**외사송쟁파재극처실종사`,`반화위복사`,`외사출행원행기로사`,`상희하승사`,`매사 영속성없음**도적에놀람-금전근심사`,`종교진출하면자립함`,`매사 영속성없음**숙명적 독신고독자`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소**전화위복`,``,`재액해소`,`관재`,``,`빈궁**관재`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`전화위복`,`전도`,`내녀 색정사`,`전도**빈궁`,`尊卑동침사`,`빈궁**선천숙명적독신고독자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진충하면성공함`,`외사 급속사**외사 애증화합-파재극처실종가출사`,`우유한가-실업다 상`,``,`관계불통-가택이사-가족질병사`,`매사 위역사`,`재난전소사`,`문서소식 길사-송사 흉-이동사`,`내사출행이동기로사`,`외남 색정사**외사 증오도망사`,`입실성사사-남녀관계`,`왕래사송계류사`,`외사 동요분산사`,`내사 급속사**내사 가출사`,`상린충산사`,``,`간인궤계사`,`매사 위역사**외사 송쟁파재극처실종사`,`반화위복사`,`외사출행원행기로사`,`상희하승사`,`매사 영속성없음**숙명적 독신고독자`,`종교진출하면자립함**도적에 놀람-금전근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`尊卑동침사`,`빈궁**선천숙명적독신고독자`,`재액해소**전화위복`,`관재`,`재액해소`,``,`빈궁**관재`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`전화위복`,`전도`,`내녀 색정사`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자**도적에놀람-금전근심사`,`음7,8,10,11월 방문자는 종교진충하면 성공함`,`매사 허성불성-세속허사자**숙면적 독신고독자`,`음3,4,5,6,9,12월방문자종교진출하면 성공함`,`외사 급속사**외사 애증화합-파재극처실종가출사`,`우유한가-실업자 상`,``,`관계불통-가택이사-가족질병사`,`매사 위역사`,`재난전소사`,`문서소식 길사-송사 흉-이동사`,`내사출행이동기로사`,`외남 색정사**외사 증오도망사`,`입실성사사-남녀관계`,`왕래사송계류사`,`외사동요분산사`,`내사 급속사**내사 가출사`,`상린충산사`,``,`간인궤계사`,`매사 위역사**외사 송쟁파재극처실종사`,`폐한인**반화위복사`,`상희하승사`,`외사출행원행기로사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내녀 색정사`,`전도**빈궁`,`尊卑동침사`,`빈궁**선천숙명적독신고독자`,`재액해소**전화위복`,``,`재액해소`,``,`빈궁**관재`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`전화위복`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 자칫 권리 내놓고 교도소     가게 생겼네? 
  병도 생기겠고! 
  이게 모두 귀신(삼전 1과     전귀)이 가득차서 생기는     일이야!
女:남편의 凶害가 있네!
`,`■ 관재고심이 있고 일은 막     히고 병은 깊고… 
⊕⊖전흉사귀와 가택상가부정을     잡고 물러나야 살길이       열리겠어! 
  열을 잃고 하나를 건지는      운세야!
`,`■ 일이 불완전하겠네? 
  거꾸로 가고 있어! 

⊕음귀, 
⊖신불제사귀 침범 때문이네!     이걸 잡으면 좋아져! 
 마음속 놀램도 해소되고!
`,`■먼데서 돈은 오는데 마음은    갈피가 안서겠네? 
 기로에 섰어! 
⊕ 전흉사귀, 
⊖가택귀ㆍ가택상가부정을      해소해봐! 
  길이 열리게 되겠어!
⊖신변목기부정도 침범했네?
`,`■윗사람의 반대와 속임을
 당하고 있겠네? 
  지푸라기라도 잡고 싶겠어! 
계획을 변경하고


⊕음귀, 
⊖신불제사귀만 해소하면 뜻이    확 펴질꺼야!
`,`■ 내부(여자)가 문제네? 
  의심과 질투로 이러지도     저러지도 못 할 기로에 섰어! 관직자는 소원을 이루겠네!
⊕ 전흉사귀, 
⊖ 가택귀가 문제야!
  모든일이 끊어지겠어!
⊖신변상가부정도 침범했네?
`,`■돈도 오고 비보도 오겠는데…     만사 동요불안, 반복무상이네?     집도 뜨고 일도 뜨고 몸도      떠다니네! 
⊕변사귀, 
⊖신불제사귀부터 잡고 나야 
 일이 풀려 가겠어!
`,`■ 가정에서 여자가 통곡할     일이 벌어졌네? 
⊕⊖여귀와 가택수망노부 
  부정이 가득차서 생기는      일이야! 관재도 걱정이고!

⊕신변음사부정도 침범했네?
`,`■ 확 바꿔야 좋아 지겠는데     육친골육(자손)의 이별수가 
  있겠어! 

⊕신불제사귀, 
⊖음귀 탓이네?
`,`■고생 끝, 행복 시작이네?    그러나 땅 문제가 발목을     잡게 되겠어?
⊕여귀, 
⊖도로귀만 해소 시키면 전화    위복이 되겠네!
⊕신변수망노부부정 침범과
⊖신변음사부정도 들었어!
`,`■ 男：권리를 잃게 생겼네? 
  女：집 나갈 일이 생겼네?

⊕신불제사귀, 
⊖음귀를 잡아야 흉을 면하게    생겼어!
`,`■ 진퇴양난이네? 
  권리도 상실되겠고… 
  가정에 병재․우환도 있고! 

⊕신불제사귀ㆍ상가부정 
⊖음귀부터 해소해야 길이 
 보이겠어!`];
  const YOGYEOL1 = [`■ 한번 편의를 얻었으면 또 다시 기대하지 말라. 사람과 귀신이 같이 성내니 내 몸이 폐인 되겠다.
  `,`■ 다행이 녹이 탔으나 공망이다. 버리고 위태한 범을 찾으니 나중에 좋은 것은 있겠으나 만개를 잃고 백개를 얻는 격이다.
  `,`■ 부서지고 실패하는 것이 집에 이르렀으니 초전은 관살이 되어 두 귀인은 이 관살을 도우니 없앤 돈을 다시 모으겠다.
  `,`■ 관살에 청룡 생기에 백호이니 노는 희로 희는 노가 되겠다. 낮정단은 관직사가 좋은데 귀인이 정마가 되었기 때문이다.
  `,`■ 사람은 주색으로 상하기 쉽고 집은 점점 기울어간다. 밤정단에는 좋은 천장이 있어 관직자나 일반인 모두 좋다.
  `,`■ 장생에 현무와 백호가 있고 두 귀인은 무심하다. 정마와 재성이 모두 약해서 밤정단은 관귀를 믿을 수 없겠다.
  `,`■ 정마가 셋 역마가 셋이다. 가고 그치는 일이 자주 있겠다. 귀인의 마음을 믿기에 부족하고 집은 옮기고 사람은 머뭇거린다.
  `,`■ 사과가 자형지지이니 처음에는 좋다가 나중에는 나쁘다. 두 귀인과 가까워도 내집은 침침하고 답답하겠다.
  `,`■ 정마가 내게 왔고 두 귀인이 도우니 좋다.
  재물은 먼 곳에서 움직이고 나쁜 것도 먼저는 흉하나 나중이 좋다.
  `,`■ 묘와 관살이 나를 공격하고 삼전도 이와 같다. 위태로운 것은 변해서 풀리는데 집안 사람이 구해준다.
  `,`■ 두 귀인이 과전에 많고 재물과 정마가 같이 있어도 싹이 트다가 말랐으니 나쁜 것이 돌고 돌아 고생이겠다.
  `,`■ 서로 교제하여 화목하여도 손해나는 것은 면하기 어렵겠다. 
  낮정단은 견우직녀가 만나니 좋은 연분을 소개받겠다.`]
  const SIBO1 = [`■ 저주사가 있다.
  권리를 상실하고 있다.
  스스로 유약심을 갖고
  타인에 의존한다.
  이로울 때는 거듭 이익을 위해 무리하다가 화를 당할 우려가 있다.
  관직자는 매우 길하고 일반인은 주의가 필요하다.
  밤정단은 어둡다.
  `,`■ 가정에 병자가 있다.
  상가부정이 들었다.
  녹이 공망되어 직녹의
  허모가 많다.
  인정이 멀어져 안정이 안된다. 일도 뜻대로 안된다. 조용히 지키고 있으면 길화한다.
  `,`■ 일의 조건이 불충분하다.
  금전이 원동한다.
  가정이 쇠퇴의 징조가
  있으나 흉은 소산되고 길은 점진적으로 발현된다. 금전도 잃은 후 재차 획득한다.
  일을 사사롭게 해야 좋고 집단 회합을 피하고 늦게 나가야 좋다.
  `,`■ 금전이 먼데서 송금되어
  온다.
  ⊕병이 있고, ⊖금전의
  고민이 있다.
  일에 일희일비의 사건이 있다.
  ⊖여인의 싸움이 발생한다. 불안하고 선택의 기로에 있고 근ㆍ친자로 결정하면 吉해진다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  고생후에 유리하게 되고 유익하게 된다.
  ⊖주석ㆍ연회ㆍ잔치에서 병을 얻거나 음식에 의한 재화를 당한다.
  골육간 장상이 아래를 속이고 반대한다.
  낮 정단은 어둡다.
  `,`■ 심정이 의심되고 질투가
  있다.
  선택의 기로에 있다.
  관직자는 어떤 일도 늦기는 하나 목적은 달성되며 일반인은 관사의 우려가 있다.
  옛 일, 인연은 결절하게 된다.
  七月 임신사가 있다.
  `,`■ 자충수적 우환이 있고
  움직이고 그치는 일이
  빈번하다.
  가정에 상복사가 발생한다.
  ⊖외래의 상복사도 있게 된다. 
  이전ㆍ개축 등의 일이 발생한다.
  밤정단은 어둡다.
  `,`■ 가정에 부정이 들어 스스로
  원한을 만들고 스스로 
  질투한다.
  소인은 관직을 다투고 군자는 관직에서 물러나는 상이다. 폐지사를 부흥시키려 한다. 심신이 부유되어 안정하지 못한다. 혼인사가 있다.
  七月 임신사가 있다.
  `,`■ 심신이 분주한 형편이다.
  금전이나 문장사에
  급속한 활동이 있다.
  옛것을 고치고 새것으로 나아가는 상이다.
  개신ㆍ개혁이 유리하다.
  여러인의 추천이 있으나 진정이 없다. 여자는 이별의 조가 있다.
  `,`■ 자충수적 우환이 있고
  심정이 부유하여 안정
  하지 못하고 있다.
  토지사로 관송사가 기했다. 관직자는 진보하고 일반인은 구태를 지키는 것이 좋다.
  흉을 보고도 길화 되어 행복하게 된다.
  `,`■ 권리를 상실한다. 
  일의 조건이 부족한
  입장이다.
  일의 진행이 불투명하다. 진퇴가 지체되고 고의스럽다. 그러나 재해를 보아도 흉은 면하게 된다. 
  酉日月에 응기가 있다.
  `,`■ 권리를 상실한다.
  ⊕가정에 병자가 있다.
  진퇴가 불명하고 서로의
  교섭사는 성립된다.
  관직자는 현직에 불만이 있고 조건의 결함이 있다. 일반인은 식녹사에 우환이 있다.
  일에 수세를 견지하는 것이 좋고 흉은 면한다.`]
  const SIBO2_1 = [`■ 官･夫,신변외사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■官･夫,신변반목사이다.
  내방조짐 상의 :  
  상복사가 있다.
  움직임이 근본이고 동기가 발동한다.
  `,`■官･夫,신변동요사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  부모상해 또는 불구의 상이다.
  `,`■ 財ㆍ女,가택동요사이다.
  내방조짐 상의 :  
  원행·이동사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■官･夫,신변동요사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  男직위상실하고
  女부부이별한다. 
  `,`■ 자손ㆍ박관내사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 財ㆍ女,가택동요사이다.
  내방조짐 상의 : 
  원행·이동사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■財ㆍ女,신변손해사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  부부간사․악의의상이다.
  `,`■ 사업ㆍ후신변외사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 자손 재액 예방이 필요하다.
  `,`■官･夫,신변파폐사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官･夫,신변외사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■官･夫,신변외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/060/row-1-column-1.png`,
    `/static/images/sagwa/060/row-1-column-2.png`,
    `/static/images/sagwa/060/row-1-column-3.png`,
    `/static/images/sagwa/060/row-1-column-4.png`,
    `/static/images/sagwa/060/row-1-column-5.png`,
    `/static/images/sagwa/060/row-1-column-6.png`,
    `/static/images/sagwa/060/row-1-column-7.png`,
    `/static/images/sagwa/060/row-1-column-8.png`,
    `/static/images/sagwa/060/row-1-column-9.png`,
    `/static/images/sagwa/060/row-1-column-10.png`,
    `/static/images/sagwa/060/row-1-column-11.png`,
    `/static/images/sagwa/060/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 십이궁위(十二宮位) 동일지지가 중첩되어 복음과로 된다. 제1과 계축(癸丑)의 극이 있어 축축(丑丑)을 초전으로 하고 삼전에 토신뿐이면 가색격으로 되며, 음일 복음은 자신격(自信格)이다. 
    음일은 유(柔)하여 스스로 약하다고 믿으니 맹동하지 않고 타인에게 의존하여 재해가 없다. 무슨 일이나 스스로에게 이로울 때는 거듭 이(利)를 탐내니 오히려 화를 자아내고, 신(神)도 사람도 모두 성내는 상이다. 복(伏)하여 움직이지 않으나 사물이 극도에 도달하면 변하며, 곤란 끝에 복력으로 전화하는 것이다. 
    삼전 중 중전이 백호귀묘(白虎貴墓)로 되고 최관부(催官符)로 되면 사관․구직은 양화(良化)한다. 일반인은 자칫하면 병 또는 송사가 발생한다.
    일귀는 역행하고 삼전은 구․백․음이며, 야귀는 순포하고 삼전은 음․백․구이다. 행년(行年)에 천마를 띠고 4과의 축(丑)과 해(亥)는 자(子)의 건록을 끼며, 자(子)에는 청룡이 승한다. 이런 것은 사룡(蛇龍)이 비를 얻고 천마가 공(空)에 오르며, 자월(子月)이 오면 명리가 향상된다.`
    ,
    // 2
    `  이 과는 4과 중 제3과의 술해(戌亥)가 일상에서 일하를 극하고 원수과로 되며, 제2국의 대부분은 퇴여격이다. 
    일간상신은 건록이지만 공망하므로 이것을 버리고, 초전에서 볼때는 술(戌)의 귀묘로 되며, 백호가 승하여 사물은 진행하기 힘들다. 그러나 관귀는 신유(申酉)의 인수에 생화하니 흉은 덕행에 의하여 면할 수 있다. 이리하여 금(金)은 내몸인 일간을 생하여 생기가 발하고, 일귀는 청룡이 승하여 길조이지만, 야귀는 현무가 승하여 소모되니 백중 열을 얻는데 불과하다.
    참관퇴여(斬關退茹)는 인정에 미(美)가 없어 행하려 하나 순조롭지 않다. 다행히도 원수과이니 먼저 흉하고 후에는 길행을 얻을 수 있다. 무슨 일이나 조용히 지켜서 재를 면해야 한다. 
    일귀는 역행하고 백․상․현이며, 야귀는 순행하고 삼전은 백․공․청이다.`
    ,
    // 3
    `  이 과는 상하의 극이 없고 4과의 미(未)에서 비스듬히 극하여 초전으로 되고 요극호시과(遙剋蒿矢課)이다. 
    삼전은 역간적격이고, 4과이지만 3과에서 없기 때문에 불비격이다. 초전의 일귀는 요극해서 힘이 경하다. 중․말전에 귀인이 가해지고 말전에서 체생하여 일지상신을 생하며 이 금은 일간을 생하니 길은 점차적으로 발생하고 따라서 흉은 소산된다. 다만 불비격이니 완전하지 못할 뿐이다. 계해(癸亥)일에 육음이 잇따라 소모가 있으나 사물이 극에 달하면 오히려 양으로 전화하고 후에는 복력이 나타난다. 
    유(酉)는 파쇄로 되고 천공이 승하여 가택에 쇠퇴의 상이 있지만 중․말전에 일야귀신(日夜貴神)이 승하여 실재(失財) 후에 재차 획득한다. 순중(旬中)의 정사(丁巳), 정신․역마는 재의 움직임이 있다.
    일귀는 역포이고 삼전은 음․귀․주이며, 야귀는 삼전이 상․음․귀로 된다. 육음격은 사사(私事)에 유리하고 공사(公事)에는 이롭지 않으니 무슨 일이나 집단 회합하지 말아야 하고 늦게 나가는 것이 좋다. 오래 내리던 비는 개고 길사는 점차 이루어진다.`
    ,
    // 4
    `  이 과는 제1과․제4과에서 상에서 하를 극한다. 그러므로 음일(陰日)이면 음일의 극이니 음일음비(陰日陰比)인 사신(巳申)을 초전으로 하고 지일과(知一課)로 된다. 
    초전은 정신 사(巳)의 재동이 되고, 사맹(四孟)을 삼전으로 하면 원태격으로 된다. 그러나 천반에서 지지는 십이운 병(病)이기 때문에 병태(病胎)라고 한다. 예를 들면 사(巳)는 병(丙)이고 지반 신(申)은 병(病)이 되며, 인(寅)의 갑(甲)에서 사(巳)는 십이운 병이고, 말전의 해(亥)는 임(壬)으로서 인(寅)을 보면 병이 되는 이것이다. 
    술(戌)의 귀(鬼)는 일간상에 있고 야귀의 청룡은 일지 해(亥)에 인종하여 장생으로 된다. 백호가 승하여 일희일노(一喜一怒)의 상이 되고, 일귀는 귀인이 초전에 승하여 정마로 되니 삼전이 체생하여 관직․구직이 다 양호하다. 삼전 지반에서 천반을 보면 장생으로 되기 때문에 진보장생(進步長生)이 되고, 또 일간에는 귀인이 승하여 만사가 길조로 변화한다.
    비전에 이르기를 ｢백호가 신(申)에 승하여 일지상신에 있으면 백호가 가정에 임한다고 하여 질병을 방비해야 한다｣고 하였다. 
    야귀인 태음이 사(巳)에 승하여 해(亥)에서 충극하면 여인(女人)의 싸움이 있다. 일귀는 역포(逆布)하고 삼전은 귀․합․공이며, 야귀는 삼전이 음․사․구이다.`
    ,
    // 5
    `  이 과는 제2과 ․제3과․제4과가 모두 상에서 하를 극하기 때문에 사맹신의 극인 미해(未亥)를 사용하여 초전으로 하고 섭해과로 된다. 삼전곡직격은 계수(癸水)로 설기되지만 금(金)에서 쇠목(衰木)을 제한다. 야귀는 상․귀․구이고 개토(皆土)의 천장은 유금(酉金)을 생하며 관귀는 인(印)으로 생화하므로 만사는 길이 되며 기쁜 일이 있다. 
    원래 섭해과는 모사는 대개 험조(險阻)하게 되고 더구나 삼상이 삼하를 극하므로 타로부터 극되어 고생이 있다. 다행히 기궁 축(丑)과 일지 해(亥)는 자(子)의 건록을 사이에 두고 유리유익하게 동용(動用)한다.
    일귀는 역행하고 삼전은 음․주․공이며, 야귀는 삼전이 상․귀․구이다. 
    귀신이 유(酉)에 임하면 여덕격이 되어 군자가 시(時)를 기다리는 과이다. 초전의 야장(夜將)은 태상으로 일간이 극되어 주식(酒食)․연회(宴會) 등에 의해 병환 또는 음식의 재(災)가 있다. 4과 중 삼상에서 삼하를 극하거나 또는 삼하가 삼상을 극할 때는 도액격(度厄格)이라 하며, 상은 존장․타인이고 하는 배하로 나를 가리킨다. 
    이 과와 같이 삼상이 삼하를 극하면 장상(長上)이 하를 속이기 때문에 액(厄)을 만나니 유도액(幼度厄)이라 명한 것이다.`
    ,
    // 6
    `  이 과는 이하(二下)가 이상(二上)을 극하고 음일이므로 음의 극인 묘신(卯申)이 초전이 되고 지일비용과이다. 초전 묘신(卯申)과 금목(金木)상극은 단륜격(斷輪格)이다. 목(木)은 금(金)의 극에 의하여 기물(器物)을 이룬다는 데서 이 명칭을 갖게 된 것이다. 
    삼전 묘술사(卯戌巳)는 주인격(鑄印格)이다, 술(戌) 가운데 신금(辛金)이 있고 사(巳) 중에 병화(丙火)가 있어 합하면 단련되고 인모(印模)를 이루기 때문에 이렇게 명명한다. 관직은 고천(高遷)의 기쁨이 있고, 어떤 일이라도 늦지만 목적은 달성되며, 구직사는 양호하다. 그러나 보통 일반 안건은 관사가 생겨서 길격이 되지 않는다. 사(巳)의 역마 정재는 병(丙)에서 술(戌)의 지반은 묘에 들어가기 때문에 역량이 약하여 수행하기 힘들다. 염막귀인(簾幕貴人)은 입학 시험․권익사(權益事)는 통과한다.
    비전에 이르기를 ｢오(午)는 해(亥)에 가입하고 태신 절(絶)에 앉아서 극을 받기 때문에 임신을 정단하면 태산이 다 흉조로 된다｣고 하였다. 
    일귀는 역행하고 삼전은 주․백․귀이며, 야귀는 삼전이 귀․청․음이다. 이 과는 병간(病看)․소송사를 싫어한다.`
    ,
    // 7
    `  이 과는 천지충위(天地冲位)에 있으므로 반음과이다, 상하의 극이 있는 가운데 하에서 상을 극하는 사해(巳亥)를 초전으로 한다.
    사과삼전 중 정신 사(巳)의 역마가 삼소에 있으니 동지(動止)가 빈발(頻發)한다. 귀신은 양소에 있어 극을 받는다. 미축(未丑)은 일간의 귀살(鬼殺)로 되고, 사(巳)의 재는 귀살을 생한다. 묘(卯)월에 정단하면 귀(鬼)가 사기(死氣)로 되고 태상이 승하기 때문에 가정 내에 상사(喪事)가 발생한다.
    일지상신 사(巳)을 초전으로 하고 말전의 사(巳)는 재차 일지상신에 귀한다. 이와 같은 경우에는 여행․외출 등은 어느 것이나 중도에서 돌아온다.
    제1과가 계(癸)의 위에 가해지고 태상이 승하면 외래의 상사를 듣는다. 일간상신은 밖에서 오기 때문에 외사(外事)에서 상(傷)한다. 정신 역마는 일지상신이 가택에 응하니 이전(移轉)․개축(改築) 등의 건이 발생한다. 
    일귀는 순포하고 삼전은 귀․공․귀이며, 야귀는 역행하고 삼전은 음․구․음이다.`
    ,
    // 8
    `  이 과는 상하의 극이 세 곳에 있어 하에서 상을 극하는 오계(午癸)를 사용하여 초전으로 하고 중심과로 된다. 사과삼전의 어느 것이나 진오유해(辰午酉亥)로 자형(自刑)의 지지가 병렬하기 때문에 처음에는 스스로 형충을 초래할 위험이 있다. 자형은 타인에서 발하는 것이 아니고 스스로 원한을 만들고 스스로 질투를 한다. 사과삼소에 극이 많고 화기(火氣)가 없으며, 또한 유순하지 못하고 스스로 힘이 강하다고 뽐내는 만용(蠻勇)의 상이다. 삼전은 하에서 중, 중에서 초전을 체극(遞剋)하여 덕을 베풀지 못하며, 소인은 벼슬을 다투고 군자는 벼슬을 물러서는 상이다. 과전․천지반 중 건록․귀인․재마가 공망하여 길조를 이루기 힘들다. 이 국은 사묘신(四墓神)이 인신사해(寅申巳亥)의 상(上)에 가해지니 이런 것은 사묘(四墓)가 생에 복(覆)한다고 하여 물사복기(物事復起)의 징조를 이룬다.
    일귀는 순행하고 삼전은 사․공․후이며, 야귀는 역행하고 삼전을 현․구․후이다. 일간상신 오(午) 옆에 있는 사(巳)는 일귀가 있고, 일지상신 진(辰) 옆에 있는 묘(卯)는 야귀로 된다. 두 귀인이 이웃으로 되면 내 집에 혼매지사(昏昧之事)가 있고, 일지상신의 진(辰)이고 삼전에 진(辰)의 자가 있으면 참관격(斬關格)이다. 천장의 길흉에 의하여 길흉을 결정한다.
    참관격은 병․송사는 흉이고 기도는 길이다. 도망자는 붙잡을 수 없는데, 도피할 경우에는 청․상․음․합의 방위로 도망가야 하고 문을 나설 때는 왼발을 먼저 내디뎌야 한다. 해(亥)는 천문(天門)이라 칭하고 해(亥)의 방향으로 도망하지 않는다. 또 금일의  지지의 지신에 가는 것을 금하며, 이를 범하면 반드시 재화를 당한다. 또 년명상신․고허신(孤虛神)은 범하지 말아야 한다.`
    ,
    // 9
    `  이 과는 4과중 제1과․제2과․제4과의 삼하에서 삼상을 극하는 지반 사맹신의 극이다. 유사(酉巳)를 초전으로 하여 섭해도액과(涉害度厄課)이다. 일간상신과 말전은 정마재동(丁馬財動)이고 사(巳)와 묘(卯)의 양귀는 협귀(夾貴)이며, 일간일지상신은 보구작용(輔救作用)을 한다. 따라서 재사(財事) 또는 문장사(文章事)에서 급속하게 활동한다. 삼전금국은 일간을 생하여 길조가 있다고 본다. 사유축(巳酉丑) 삼합 종혁격은 낡은 것을 고치고 새로운 것에 따르는 것이 좋다. 섭해도액은 골육 불화로 망동은 불량하다.
    중․말전은 공망이 된다. 삼전은 체생하지만 공망하니 진정(眞情)이 아닌 말만의 추천이다. 염막귀인이 일간상에 임하므로 중말이 공망이라 하여도 년명상신이 공망할 때는 공망이 아닌 것으로 판정한다.    일귀는 순행하며 삼전은 구․상․귀이며, 야귀는 삼전이 공․음․주로 한다.
    삼전은 사유축(巳酉丑) 금국이니 종혁이라 하여 금(金)은 사(巳)에 장생이며, 유(酉)에 왕(旺)하고 축(丑)은 묘(墓)이다. 이미 단련되어 상종(相從)하기 때문에 구(舊)를 개혁하여 이런 이름이 붙는다. 변동 변혁하며 낡은 것을 고치니 정신(鼎新)의 상이며, 수일(水日)은 생기로 되고 토일(土日)은 설기하며 병정(丙丁)의 일은 재(財)로 된다. 유(酉)가 사(巳)에 승하면 수과(愁課)라 하며 유(酉)는 추냉(秋冷)의 기후로 만물의 수고(愁苦)를 나타낸다. 도로(道路)․가문(家門)의 개혁은 불안하고, 여인에게는 이별의 상이기 때문에 점혼(占婚)은 좋지 않다. 병은 폐(肺)에 있고 근골동통(筋骨疼痛)이다. 구재는 진기(珍器)를 얻고 원행은피(遠行隱避)하면 가장 길하다. 사물을 개동 갱신하면 좋다.`
    ,
    // 10
    `  이 과는 4과 중 제1과 진계(辰癸)에서 일상이 일하를 극하고 진축(辰丑)을 초전으로 하며 원수과가 된다. 
    삼전가색격으로 진(辰)이 일간상에 올라 중귀로 되어 일간을 극한다. 다행히 일지상신 인목(寅木)은 중귀를 제하여 위험한 중에도 구해(救解)가 있다. 일지상신은 가인(家人)으로 가인에 의뢰하여 구원된다. 
    삼전에 토신(土神)이 많으면 물사가 색체(塞滯)되고, 더욱이 진(辰)의 자(字), 진(辰)의 초전으로 되면 참관격이니 안거(安居)하기 힘들다. 현임 관리는 진보를 볼 수 있지만 보통 일반인은 제자리를 지키는 것이 좋다. 복덕(福德)․의신(儀神)의 인(寅)은 일지상에 가해지고 정마귀덕(丁馬貴德)이 음신에 있으면 흉이라 하여도 화를 바꾸어 행복하게 한다.
    일귀는 순포하고 삼전은 후․주․청이며, 야귀는 삼전이 동․구․백이다. 술(戌)에 백호가 승하여 흉이지만 진(辰)을 충하여 흉을 제하므로 큰 흉재는 없다.`
    ,
    // 11
    `  이 과는 제3과․제4과에 상하의 극이 있으니 사맹신 해(亥) 지반의 극을 취하여 축해(丑亥)를 초전으로 하는 섭해과이다. 4과이지만 3과밖에 없어 불비격이다. 삼전 축묘사(丑卯巳)는 4과를 떠나지 않으니 이런 것을 회환격(回還格)이라 한다. 삼전은 순간전격으로 출호(出戶)라 한다. 묘(卯)는 문호(門戶), 사(巳)는 지호(地戶)라 하는데, 축(丑)에서 사(巳)에 전하고 사람이 문을 나가는 상이기 때문에 이런 이름을 갖게 되었다. 방문하여도 부재(不財)하고, 대인은 온다. 상위나 중위에 계급자는 승양(昇陽)이라 칭하여 길이 되며, 소인은 호의(狐疑)하여 불길하다. 묘(卯)는 야귀 사(巳)가 일귀로 되어 귀인이 모이고, 사(巳)는 일간의 재마(財馬丁神)가 되어 일신(一神)이 일소(一所)에 모이는 섭해견기격(涉害見機格)으로, 간전(間傳)이 되어 나아가면 조체(阻滯)의 상이 있다. 불비격은 결합, 불비하여 돌지 않기 때문에 수세(守勢)가 좋다. 일지상신 귀묘는 일간을 극하여 재해의 징조가 있지만, 묘목(卯木)에서 제하여 흉재를 만나지 않는다. 귀신 공망과 실(實)이 있어 암(暗)에서 명(明)으로 된다. 묘(卯)는 묘귀(墓貴)이니 먼저 암이라 해도 후에는 명쾌하게 되어 시험․선거․입시는 당선이 틀림없다.
    일귀는 순행하고 삼전은 상․음․귀이며, 야귀는 삼전이 음․귀․주이다. 초전의 축(丑)은 공망하고 말전의 사화(巳火)는 축토(丑土)를 생하지만 힘이 없다. 삼전에는 축사(丑巳)는 있고 유(酉)가 없는데, 즉 닭을 안고 싸우지 않는다는 속담과 같다. 따라서 유(酉)의 일신을 기다려서 성사되는데 이를 기일대용(期一待用)이라 한다.`
    ,
    // 12
    `  본국은 720과의 종국(終局)에 해당한다. 제4과 축자(丑子)는 일상이 일하를 극하여 초전이 되며 원수과이다. 일간상신 인(寅)은 일지 해(亥)와 지합하고 일간 기궁 축(丑)은 지상 자(子)와 지합한다. 즉 교차지합하고 교호화해(交互和諧)한다. 그러나 일지상신 건록이 공망하고 초전의 축(丑)은  관귀이지만 중․말전의 인묘(寅卯)가 귀(鬼)를 제어하므로 재환은 없다. 자축(子丑)은 직녀우매(織女牛買)로 태상이 승하니 우녀(牛女)와 상봉한다 하여 양매(良媒)가 있어 공망이라도 혼약은 성립된다. 천반의 앞에 있으면 나망격이라 하여 신중하게 수세를 갖는 것이 좋고 망동은 불리하다. 일록이 지상에 임하여 일귀의 천장 현무․백호가 승하면 식록(食祿)에 의하여 우환손모(憂患損耗)가 발생한다. 일간상신 인(寅)은 탈기(脫氣)로 되고 현무가 승하면 탈도격(脫盜格)으로 된다. 관공직에 있는 사람은 관성 공망을 싫어한다.만약 공망하면 현임에 불만과 결함이 발생한다.
    진여격(進茹格)이 공망이 되면 오히려 퇴여격(退茹格)으로 변화한다. 진여․퇴여는 연주격(連珠格)이라 한다. 삼전 인묘진(寅卯辰)의 방합(方合)이 있거나 또 이 과와 같이 맹신(孟神)․중(仲)․계(季)가 상련(相連)하는 것을 말한다. 길사의 정단은 길사가 연속하여 발생한다.
    흉사는 연여(連茹)를 싫어한다. 퇴연여격(退連茹格)은 실우(失友)하고, 행하고자 하지만 행할 수 없으며, 인정은 아름답지 못하고 길흉이 연발한다. 잉태할 때는 연태(連胎)하고, 갤 때는 계속 개며 비가 오면 계속해서 온다. 진여(進茹)는 나아가는 것이 좋고, 귀인 순행은 신속하게 된다. 공망을 만나면 물러가는 것이 좋고, 퇴여격은 물러가는 것이 합당하다. 귀인 역포는 늦고 저해가 있으며, 공망을 만나면 전진하는 것이 좋고 재화를 제거할 수 있기 때문이다. 삼전 해자축(亥子丑)은 일월성의 삼기연구(三奇連球)로 되어 만사 화합의 기쁨이 있다. 일귀는 순포하고 삼전은 상․현․음이며, 야귀는 삼전이 음․후․귀이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
