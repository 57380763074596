
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,3,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,2,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,10,1,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,12,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,11,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,10,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,2,9,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,8,`특별있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,7,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,5,6,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,6,5,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,4,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`색정`,`외남 색정사 有`,`小`,`12`,`반화위복`,`능욕박해`],
    [`손모`,`외사 비용과출사`,`大`,`과시기`,`색정사有`,`수난번민`],
    [`가면기흉(可免其凶)`,`흉사가 많아도 가히 면할 수 있는 상`,`大`,``,``,``],
    [`비상지애(非常之愛)`,`여건과 조건이 때를 얻어 정을 나누게 되는 상-혼인 관련`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사-공허`,`大`,`11`,`사후쟁탈`,`비상지愛`],
    [`남자`,`남자 관련사`,`小`,`쇠퇴기`,`이전사`,`남자문제`],
    [`재화생화(財化生禍)`,`찔러주는 돈이 화로 변하는 상`,`小`,``,``,``],
    [`반화위복(反禍爲福)`,`화가 반대로 복을 부르는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`도적失勢`,`간인궤계`],
    [`자식`,`자식 관련사`,`小`,`침해기`,`직위불안`,`가정침체`],
    [`타인일락(他人逸樂)`,`남은 편안하고 즐거우나 자기는 왜 힘든가-의 상`,`大`,``,`외사애증`,``],
    [`발탁영잔(拔擢榮轉)`,`추천발탁되어 영전 도약하는 상`,`小`,``,``,``],
    [`결속`,`결속단합으로 大財를 획득하나 부모災害가 발생함`,`大`,``,`술잔내려`,``],
    [`극남`,`남자 갈등사`,`小`,`9`,`받는경사`,`소인쟁재`],
    [`색정`,`내女 색정사 有`,`小`,`소외기`,`직위불안`,`색정사有`],
    [`취재생화(取財生禍)`,`옳지 않은 돈을 받았다가는 화를 당하게 되는 상`,`大`,``,``,``],
    [`소인쟁재(小人爭財)`,`신분이 차이나는 자와 금전을 다투는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`8`,`도적악의`,`간인궤계`],
    [`매사 영속성없음`,`관직직업직위남편남자사는 선천적 기복재해를 겪음`,`小`,`침체기`,`공격말것`,`이사의사`],
    [`비불유기(費不由己)`,`재주로 받는 대가를 왜 자기에게는 안 주는가- 상`,`小`,``,``,``],
    [`도적악의(盜賊惡意)`,`도적이 악의를 품고 있으므로 공격하지 말라-는 상`,`大`,``,``,``],
    [`금전`,`금전상업여인 관련사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`7`,`臥病불기`,`좋은소식`],
    [`매사 영속성없음`,`금전사업처첩사는 선천적 기복재해를 겪음`,`小`,`단절기`,`사업곡절`,`면모훼손`],
    [`불이이득(不而利得)`,`이득을 취해서는 안 되는 상`,`小`,``,`외사동요`,``],
    [`왕진쇠퇴(旺進衰退)`,`세력이 있다치면 나아가고 쇠약하면 그만 퇴함이 좋은 상`,`小`,``,`출행이동`,``],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`6`,`비곡차욕`,`미초희경`],
    [`여인`,`여인 관련사`,`小`,`존재기`,`신규손실`,`술잔내려`],
    [`호악무성(好惡無成)`,`좋고 나쁘건 성립되지 않는 상-처(여인) 관련`,`小`,``,``,`받는경사`],
    [`부처반목(夫妻反目)`,`가정(부부) 불화의 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 직위 관련사`,`大`,`5`,`간사은닉`,``],
    [`결속`,`결속하여 財기반을 크게 세우나 직위권리남편이 훼탈됨`,`小`,`배양기`,`가택이사`,`사사성취`],
    [`가택사기(家宅死氣)`,`가정에 사망사가 예상되는 상`,`小`,``,`관계불통`,``],
    [`사송계류(詞訟稽留)`,`재판이 끝나지 않고 진행중인 상`,`大`,``,``,``],
    [`후원`,`후원문서 조력사`,`小`,``,``,``],
    [`급속`,`외 강제급속손실사-도망사 우려`,`小`,`4`,`失業한가`,`구설사송`],
    [`출행`,`내사 출행이동사`,`大`,`개시기`,`급속사有`,`출행이동`],
    [`시무익야(是無益也)`,`단연코 아무것도 이익이 안 되는 상`,`小`,``,``,`문서길함`],
    [`상린충산(傷鱗冲散)`,`물고기의 비늘이 벗겨지듯하는  동요불안분산의 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`3`,`승진영전`,`비곡차욕`],
    [`모친`,`모친 관련사`,`大`,`학습기`,``,`손해가출`],
    [`이성사야(以成事也)`,`장담하건대 주력사가 이루어진다-는 상`,`小`,``,``,`우려`],
    [`비곡수욕(悲哭差辱)`,`감추고 있던 일의 폭로가 분하고 치욕스러워 슬피우는 상`,`大`,``,``,``],
    [`관사`,`관사- 침체 경구(驚懼)사`,`小`,``,``,``],
    [`직업`,`직업 직위 관련사`,`大`,`2`,`소식기대`,`사행불량`],
    [`남편`,`남편 관련사`,`小`,`면모기`,`와도나쁨`,`가해손실`],
    [`범사삼사(凡事三事)`,`경솔함을 경계하고 모든 일을 세번 생각하라-는 상`,`小`,``,``,``],
    [`비곡수욕(悲哭差辱)`,`감추고 있던 일의 폭로가 분하고 치욕스러워 슬피우는 상`,`大`,``,``,``],
    [`명예`,`수상(受賞) 관련사`,`小`,``,``,``],
    [`직녹`,`직위 녹위 관련사`,`小`,`1`,`간인궤계`,`환성경사`],
    [`권리`,`권리 관련사`,`大`,`절정기`,`외교막힘`,`애증화합`],
    [`선용교합(先用交合)`,`먼저 통하고 후에 합하는 상-남녀 혼인관계 관련`,`大`,``,``,``],
    [`간인궤계(奸人詭計)`,`사통관계 또는 간사한 자가 꾸미는 거짓말-의 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,`형제친구동료색정조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`관사직업직위남자문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위남편갈등사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위색정사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,`금전사업여인조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`관사직업남자사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`후원문서출행부친조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`후원문사모친조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사 직업 남편 사로 불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,`명예직녹권리사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의호질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`일이 거꾸로 진행됨**깊은 산속으로 숨고싶은 심정을 갖게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당하고 명분이 있으므로 먼저 나서서 속결해야만 유리함`,0,0,`여러 사정을 감안하여 좌고우면하거나 지체 연기되면 오히려 불리한 결과가 있게 되는 人`,0,0,`일이 거꾸로 띄엄띄엄 진행됨**일이 갑자기 돌발함**금전 또는 여인에 의한 재난을 조심해야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당하고 명분이 있으므로 먼저 나서서 속결해야만 유리함`,0,0,`여러 사정을 감안하여 좌고우면하거나 지체 연기되면 오히려 불리한 결과가 있게 되는 人`,0,0,`신구사가 있음**1남2녀 삼각관계 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순을유화유순한 처신으로 사안을 뒤로 미루거나 지체시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응하여 후결하는 자세를 유지하면 반드시 결과가 유리해지는 人 `,0,0,`여러 일을 하나로 묶는 결속을 이루어 금전을 크게 확보하게 됨-단 외허내허함**현재 산으로 깊이 숨고싶은 심정임**일이 결과없이 반복되기만 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순을유화유순한 처신으로 사안을 뒤로 미루거나 지체시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응하여 후결하는 자세를 유지하면 반드시 결과가 유리해지는 人 `,0,0,`기이한 전화위복사가 있음**정대한 옛일들이 끓어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`매사 영속성없이 반복되고 정한 일이 없으며 배반사를 겪는 人`,0,0,`신규사가 있음**일이 결과없이 반복되고 점점 어두워짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`색정사에 관련됨**최근의 병은 치유되고 오래된 병은 치유불가함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순을유화유순한 처신으로 사안을 뒤로 미루거나 지체시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응하여 후결하는 자세를 유지하면 반드시 결과가 유리해지는 人 `,0,0,`복잡한 여러 일을 하나로 묶는 결속단합사가 있음-단 큰 손실사가 우려됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순을유화유순한 처신으로 사안을 뒤로 미루거나 지체시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응하여 후결하는 자세를 유지하면 반드시 결과가 유리해지는 人 `,0,0,`신규사가 있음**1녀2남 삼각관계 있음**타인에게 의지하는 입장**무력한 타인이 스스로 와서 손해를 끼침**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순을유화유순한 처신으로 사안을 뒤로 미루거나 지체시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응하여 후결하는 자세를 유지하면 반드시 결과가 유리해지는 人 `,0,0,`현재의 일에서 퇴하려하나 계속하게 됨**부인이 타인의 남편을 적극적으로 유혹하여 함께 도망할 각오를 하고 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순을유화유순한 처신으로 사안을 뒤로 미루거나 지체시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응하여 후결하는 자세를 유지하면 반드시 결과가 유리해지는 人 `,0,0,`독신고독각방주말부부생리사별적 처지임**일이 허무하여 퇴하려하나1928,40,52,64,76,88년 출생자와생-1929,41,53,65,77,89년 출생자는 계속 진행하여 길함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 있어도 움직이지는 않음`,0,0,`자기 스스로 자임하고 지금 것을 지키며 신음하면서 새 기회를 기다리는 人`,0,0,`신구사가 있음**고시준비생에게 많음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성-무력`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`좌고우면-무력`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사-후회사겹침`,`미사`,`신사`,`외사`,`표면사-이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해함`,`화해함`,`화해함`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`북잡화해`,`복잡화해`,`복잡화해`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,1,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월-연기우려`,3,2,5,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음2월`,2,3,5,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월-연기 또는 파혼우려`,3,3,6,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음6월-연기우려`,2,1,3,`음4월-연가우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음8월-연기 또는 파혼우려`,2,2,4,`음2월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5월`,3,2,5,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음8월`,1,1,2,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월-연기우려`,1,1,2,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음2월`,2,1,3,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,3,3,6,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 수준이하로 마침 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 초 중반이 허무하고 종반에 이르러서야 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 여러 사안을 일관시키는 결속단합을 완성하여 재정을 크게 확보하는 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 종반에서야 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무 시종 동요불안분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무전반에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것 같으나 사람을 잘 조정하는 능력이 있어 복잡한 여러 사안을 일관시키는 결속단합을 이루나 재정의 바탕은 되지만 큰 손해가 발생함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 측근과 내부의 무력으로 업무 전반이 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 유순하고 준수 미모이나 업무가 강하지 못해 초 중반을 허무하게 보내고 종반에 이르러 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성품이 유순하고 준수 미모이나 업무가 강하지 못해 시종 실적을 못냄-단 좌측 '적확성' 항목에 적시한 출생연도 자는 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무가 초반의 실적을 보이는 것 외 중반에 저조하며 후반에는 공허하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,`반대 있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`본인반대`,`불이행`,`선난후난`,`退背`,`원`,`去`,`세입가해`,`정북`,``,`정서`],
    [`10.11`,`多集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`초기합의`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선이후난`,`進向`,`  원`,`  留`,`주인조력`,`북서`,``,`북서`],
    [`10.11`,``,``,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`권리양도`,`進向`,`  근`,`  留`,`주인조력`,`동북`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`불이행`,`선난후난`,`向進`,`  원`,`  留`,`세입가해`,`정동`,`정남`,``],
    [`10.11`,``,`본인동조`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,`결국이익`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`불이행`,`선이후난`,` 進向`,`  원`,`  留`,`세입가해`,`북북동`,`남남서`,``],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`의사有`,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`주인가해`,`동북`,`북북동`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,`동요불안`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`발현무력`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`내부반대`,`불이행`,`본인무력`,`退背`,`  근`,`  去`,`세입가해`,`정동`,`정서`,``],
    [`10.11`,` 多集`,`발현안됨`,``,``,``,``,``,``,``,`세밍심사`,``],
    [`3.6\n9.12`,``,`내부단속`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부합세`,`불이행`,`선攻후난`,`退背`,`  원`,`  去`,`세입조력`,`북북서`,`서북`,``],
    [`10.11`,``,`본인제압`,``,``,``,``,``,`설기피로`,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`사안무력`,`退背`,`  원`,`  去`,`주인무력`,`서남`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`불이행`,`사안무력`,`退背`,`  원`,`  去`,`주인무력`,`정남`,``,`정북`],
    [`10.11`,``,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세무력`,`불이행`,`선난후난`,`進向`,`  근`,`  留`,`무난`,`남남서`,``,`1`],
    [`10.11`,``,`본인제압`,``,`비용과출`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`선난후난`,`進向`,`  근`,`  留`,` 무난`,`서남`,``,`정동`],
    [`10.11`,` 多集`,``,``,`비용과출`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`목기부정`,`木斷木入`,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`여귀`,`가장부인`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`제례귀`,`제례위범`,0,0,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`제례귀`,`제례위범`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`여귀`,`가장부인`,0,0,`육친`,0,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사급속사**명예직녹자산사-애증파재극처사`,`간사한 자의 꾸며진 거짓사`,`외남자 색정사`,`비상지애사`,``,`남모르게 당하는 굴욕능욕사`,`외사 애증파재극처사-실종상해우려**가정막힘`,`환성지경사`,`내여자 색정사`,`문서헌책지체사-구설사`,`매사 허성불성-세속허사자**매사위역사`,`음34,5,6,9,12월 방문자는 종교진출하면 성공함`,`매사 영속성없음**발탁영전도약경사`,`종교잔출하면자립함`,``,`탄식신음-병 또는 모사 미수사`,``,`觀書-문화풍아`,`외사 급속사-내사 출행이동사`,`도적실세-잡음`,``,`事후쟁탈사`,`매사위역사`,`전화위복사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외교막힘`,`빈궁`,`전화위복`,``,`재액해소`,`빈궁**관재`,``,``,`빈궁**관재`,``,`재난전소사`,`빈궁`,`외사 출행이동사`,`전도`,`음5월 화재주의**전화위복`,`전도`,`재액해소`,`빈궁**관재`,``,`빈궁**병재`,``,``,`침체경구사**재액해소`,`관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사`,`전화위복사`,`간사한 자의 꾸며진 거짓사`,`내사급속사**명예직녹의자산사-애증파재극처사`,`외남자 색정사`,`비상지애사`,``,`남 모르게 당하는 능욕박해사`,`외사 애증파재극처사-실종상해우려**가정막힘`,`환성지경사`,`내여자 색정사`,`문서헌책지체사-구설사`,`매사 영속성없음**매사위역사`,`종교진출하면자립함`,`매사 영속성없음**발탁영전도약경사`,`종교진출하면자립함`,``,`탄식신음사-병 또는 모사 미수사`,`觀書-문화풍아`,``,`외사 급속사-내사 출행이동사`,`도적실세-잡음`,``,`事후쟁탈사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재**병재`,``,`전도**빈궁`,`외교막힘`,`전화위복`,`전도`,``,`빈궁**관재`,`재액해소`,`관재`,`재액해소`,`빈궁`,`재난전소사`,`빈궁`,`외사 출해이동사`,``,`음5월 화재주의**전화위복`,`관재`,``,`빈궁**관재`,``,`빈궁**병재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`事후쟁탈사`,`매사위역사`,`전화위복사`,`간사한 자의 꾸며진 거짓사`,`내사급속사**명예직녹자산사**외교막힘`,`외남자색정사`,`비상지애사`,``,`남 무르게 당하는 굴욕박해사`,`외사 애증파재극처사-실종상해우려**가정막힘`,`환성지경사`,`내 여자 색정사`,`문서헌책사-구설사`,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**발탁영전도약경사`,`종교진출하면자립함`,``,`탄식신음사-병 또는 모사 미수사`,`觀書-문화풍아`,``,`외사 급속사-내사 출행이동사`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`빈궁**병재**관재`,``,`빈궁**재액해소`,`내사 애증파재극처사`,`전화위복**재액해소`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`재난전소사`,`빈궁`,`외사 출행이동사`,``,`음5월 화재주의**전화위복`,`관재`,``,`빈궁**관재`,``,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`좋은 소식사`,`외사 급속사-내사 출행이동사`,`간사한 자의 꾸며진 거짓사-이동사`,``,`신분이 차이나는 소인쟁재사`,``,`움추러드는견련반복사`,`내사 급속사**명예직녹자산사-애증파재극처사`,`외남자 색정사`,`부부반목사`,`불리고시-없는 구설근심사`,``,`외사 애증파재극처사-실종상해우려**가정막힘`,`旺즉 貪榮不禍-衰즉 퇴함이 쌓는 것임`,`내여자 색정사`,`관계불통-이사-질병사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**병 중태-구설사`,`종교진출하면자립함`,``,`도적악의-공격말 것`,``,`권리사가 부족한 기쁨사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,`빈궁`,``,`관재`,``,`전도**빈궁`,`외교막힘`,`전화위복`,`전도`,``,`빈궁**관재`,`재액해소`,`빈궁`,`재액해소`,`관재`,`비곡차욕사`,``,`외사 출행이동사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`권리사가 부족한 기쁨사`,``,`좋은 소식사`,`외사 급속사-내사 출행이동사`,`간사한 자의 꾸며진 거짓사-이동사`,``,`신분이 차이나는 소인쟁재사`,``,`움추러드는 경련반복사`,`내사 급속사**명예직녹자산권리사**외교막힘`,`외남자색정사`,`부부반목사`,`불리 고시-없는 구설근심사`,``,`외사 애증파재극차사-실종상해우려**가정막힘`,`旺즉 貪榮不禍-衰즉 퇴함이 쌓는 것임`,`내여자 색정사`,`관계불통-이사-질병사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**병 중태-구설사`,`종교진출하면자립함`,``,`도적악의-공격말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**빈궁**병재`,``,`전도**관재`,``,`관재`,``,`빈궁**재액해소`,`내사 애증파재극처사`,`전화위복**재액해소`,`관재`,``,`빈궁**관재`,``,`빈궁**관재`,``,``,`비곡차욕사`,``,`외사 출행이동사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`도적악의-공격말 것`,``,`권리사가 보족한 기쁨사`,``,`좋은 소식사`,`외사 급속사-내사 출행이동사`,`간사한 자의 꾸며진 거짓사-이동사`,``,`신분이 차이나는 소인쟁재사`,``,`움추러드는견련반복사`,`내사 급속사**명예직녹자산권리사**외교막힘`,`외남자색정사`,`부부반목사`,`불리 고시-없는 구설근심사`,``,`외사 애증파재극처사-실종상해우려**가정막힘`,`旺즉 貪榮不禍-衰즉 퇴함이 쌓는 것임`,`내여자색정사`,`관계불통-이사-질병사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음*병 중태-구설사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요분산사`,`전도**빈궁`,`동요분산사**재액해소`,`관재`,`동요분산사`,`관재**병재`,`동요분산사`,`빈궁**관재`,`동요분산사**재액해소`,`관재`,`내사 애증파재사-동요분산사`,`빈궁**관재`,`동요분산사**전화위복`,`관재`,`동요분산사**재액해소`,`빈궁**관재`,`동요분산사`,`빈궁`,``,``,`비곡차욕사**동요분산사`,`관재`,`외사 출행이동사**동요분산사`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음**병 중태-구설사`,`종교진출하면자립함`,`도적악의-공격 말것`,``,`권리사가 부족한 기쁨사`,``,`좋은 소식사`,`외사 급속사-내사 출행이동사`,`간사한 자의 꾸면진 거짓사-이동사`,``,`신분이 차이나는 소인쟁재사`,``,`움주러드는 견련반복사`,`내사 급속사**명예직녹자산사-내사 파재극처사`,`외남자색정사`,`부부반목사`,`불리 고시-없는 구설근심사`,``,`외사 애증파재극처사-실종상해우려**가정막힘`,`旺즉 貪榮不禍-衰즉 퇴함이 쌓는 것임`,`내여자 색정사`,`관계불통-이사-질병사`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 출행이동사`,`전도**빈궁`,`음5월 화재주의**전화위복`,`전도**빈궁`,`재액해소`,`관재`,`관재**병재**빈궁`,``,``,`빈궁`,`재액해소`,`관재`,`빈궁**관재`,`외교막힘`,`전화위복`,``,`재액해소`,`빈궁**관재`,``,`빈궁`,``,``,`비곡차욕사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**구설송쟁사**매사위역사`,`종교진출하면자립함`,``,`사사성취사`,``,`왕래사송계류사`,`상린충산사`,`외사 급속사-내사 출행이동사`,`간사한 자의 꾸면진 거짓사-이동사`,``,`전화위복사`,`매사위역사`,`상위 기쁨-하위 수도자격`,`내사 급속사**명예직녹자산사-내사파재극처사`,`외남자 색정사`,`도적의혹불녕사`,`재물이 남모르게 움직임`,``,`외사 애증파재극처사-실종상해우려**가정막힘`,`신분 차이 음란-존비상몽사`,`내여자 색정사`,`관계불통-이사-질병사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재난전소사`,`빈궁`,`외사 출행이동사`,`빈궁`,`음5월 화재주의**전화위복`,``,`빈궁`,``,``,``,`빈궁`,``,`병재`,``,`전도`,`외교막힘`,`전화위복`,`전도**빈궁`,``,`빈궁`,`재액해소`,`관재`,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`내여자색정사`,`관계불통-이사-질병사`,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**구설송쟁사`,`종교진출하면자립함`,`사사성취사`,`재액해소`,``,`왕래사송계류사`,`상린충산사`,`외사 급속사-내사 출행이동사`,`간사한 자의 꾸면진 거짓사-이동사`,``,`전화위복사`,`매사위역사`,`상위 기쁨-하위 소도자격`,`내사 급속사**명예직녹자산사-내사파재극처사`,`외남자 색정사`,`도적의혹불년ㅇ사`,`재물이 남모르게 움직임`,``,`외사 애증파재극처사-실종상해우려**가정막힘`,`신분 차이 음란-존비상몽사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재난전소사`,`빈궁`,`외사 출행이동사`,`빈궁`,`음5월 화재주의**전화위복`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`전도`,`외교막힘`,`전화위복`,`전도**빈궁`,``,`빈궁**관재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사 에증파재극처사-실종상해우려**가정막힘`,`도적을 추격했으나 못잡음`,`내여자색정사`,`사행불량사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**발탁영전도약경사`,`종교진출하면자립함`,`말 말고 근신이 좋음`,``,`투서헌책-안좋음`,`말 말고 근신이 좋음`,`혼인단장사`,`외사 급속사-내사 출행이동사`,``,`형책사-이동사`,``,`신분이 차이나는 소인쟁재사`,`간사한 자의 꾸며진 거짓사`,`내사 급속사**명예직녹자산사-내사파재극처사`,`외남자 색정사`,`소식 안옴-와도 나쁨`,``,`승진영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁`,`재액해소`,`빈궁`,`탄식신음-병 또는 모사미수사`,``,`외사 출행이동사`,``,`음5월 화재주의**전화위복`,`빈궁`,``,`빈궁**관재`,``,``,`빈궁`,``,`관재`,``,`전도**빈궁`,`외교막힘`,`전화위복`,`전도**빈궁**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`승진영전사`,`외사 애증파재극처사-실종상해우려**가정막힘`,`도적을 추격했으나 못잡음`,`내여자색정사`,`사행불량사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**발탁영전도약경사`,`종교진출하면자립함`,`말 말고 근신이 좋음`,``,`투서헌책-인 좋음`,`말 많고 근신이 좋음`,`혼인단장사`,`외사 급속사-내사 출행이동사`,``,`형책사-이동사`,``,`신분이 차이나는 소인쟁재사`,`간사한 자의 꾸며진 거짓사`,`내사 급속사**명예직녹자산권리사**외교막힘`,`외남자 색정사`,`소식 안옴-와도 나쁨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재액해소`,`빈궁**관재`,`재액해소`,`빈궁`,`탄식신음-병 또는 모사 미수사`,``,`외사 출행이동사`,``,`음5월 화재주의**전화위복`,`빈궁`,``,`빈궁**관재`,``,``,`빈궁`,``,`관재`,``,`전도**빈궁**폐한인`,`내사 애증파재극처사`,`전화위복`,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외남자 색정사`,`비상지애사`,``,`남 무르게 당하는 굴욕박해사`,`외사 애증파재극처사-실종상해우려**가정막힘`,`환성지경사`,`내여자 색정사`,`문서헌책사-구설사`,`매사 허성불성-세속허사자**매사위역사`,`음3,4,5,6,9,12월 출생자는 종교진출하면 `,`매사 허성불성-세속허사자**발탁영전도약경사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,``,`탄식신음-병 또는 모사 미수사`,`觀書-문화풍아`,``,`도적실세-잡음`,`외사 급속사-내사 출행이동사`,``,`事후쟁탈사`,`매사위역사`,`전화위복사`,`간사한 자의 꾸며진 거짓사`,`내사 급속사**명예직녹자산사-내사 파재극처사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁`,`성공함**재난전소사`,`외사 출행이동사`,`전도`,`음5월 화재주의**전화위복`,`전도`,`빈궁**관재`,``,`빈궁**병재`,`재액해소`,``,`재액해소`,`관재**병재`,``,`빈궁`,`외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 두사람이 힘을 합해 일을     벌이겠네? 
  그런데 내쪽의 소모가 더     크겠어! 
  여권상실, 여인 흉재도      겪겠어!
  그래도 하늘이 도와 주네.!
`,`■ 병도 있겠고 가정금전․     여자문제로의심과질투를      하고 있네 ?
⊕전흉사귀․음귀 
⊖전흉사귀․전사귀 침범으로    그러네!

⊕⊖신변상가부정이침범했네?
`,`■⊕금전 
 ⊖병 문제가 심신을 괴롭      히겠어! 
   결국 돈 때문에 가정까지      깨져!
⊕가택귀 
⊖전․흉사귀 해소가 되어야    돈이 들어와!
`,`■한 남자에 여자 둘이서     다투고 집까지 넘어 가게    생겼네? 
 이권을 상실하게 생겼어 !
 장차 부귀할 사람이 그걸      모르고 손해날 일만 하고    있는 셈이야!

⊕신변 상가부정이 침범했네?
`,`■ 자칫머리는 용인데 꼬리는     뱀이되고 말겠어! 
  관재고심도 있고 일도 어     려워 지지만 

⊕목기부정ㆍ가택귀․신불제사귀 ⊖상가부정ㆍ전흉사귀․음귀    부터 잡아야 큰 돈을 벌지!
`,`■ 옛일이 다 끊어지네? 
  인연이 다했어! 
⊕자칫 부도나게 생겼네 ? 
⊖여인탄식사가벌어지겠어! ⊕변사귀 
⊖전사귀 장난이야! 
 해소시키면 희망이 생겨!
`,`■색정불화네 ?
  파탄 곡절로 분주 하겠어!    이미 부부간에 뜻도 안 맞      겠고… 
⊕가택상가부정을 해소 시키고    타인을 앞세우면 소송문제는    해결 될거야.
`,`■돈은 없고 여자는 속썩이고…    의심 질투로 기로에 섰네?
⊕가택귀․여귀 
⊖가택귀․여귀를 해소하고    기도하면 흉도 줄고 통하    것이 있게 돼!

⊕⊖신변에 수망노부부정이      침범 했네 ?
`,`■여러건을 하나로 묶겠는데, 
男:직위가 날라가겠네 ?
女:남편을 잃게 생겼어 ! 
그나마 가정에 임신사도 있겠네!
⊕ 전사귀 
⊖가택음사부정ㆍ변사귀를     없애야 재기를 도모할 수    있겠어!
`,`■ 한 여자에 두 남자가 
  붙었네? 
  상대가 스스로 와서 손해만     나게 하고 일까지 망쳐
  놓겠어
`,`■⊖가정에 여자가 남자를      유혹 하네 ?
⊕가택음사부정, 
⊖가택수망노부 부정이 들었고 일에는 자충수가 벌어져 권리를 잃겠어 !
⊕도로귀ㆍ음귀, 
⊖여귀ㆍ신불제사귀가잔뜩    있어서 자칫 관재에 걸리고    병까지 들겠어!
`,`■ 진퇴양난이겠어? 
  뜻대로 되는게 없네!
⊕도로귀 ,
⊖여귀 침범때문인데, 
 이별사까지 벌어질 조짐이야!

⊖신변 상가부정이 침범했네?`];
  const YOGYEOL1 = [`■ 이미 합했으나 녹은 깨지고 빈것을 지키고 있다. 중간이나 결과도 별것 없으니 낮정단은 도둑을 주의 하라.
  `,`■ 백호가 관살에 탔다.
  내 옆에도 있고 다른 데도 있다. 친구나 동기가 돕는다 하나 그 흉이 또 있다.
  `,`■ 먼저는 좋다가 나중에는 패하니 돈을 욕심내면 나쁘다. 재물이 귀살을 도와 관재와 병을 피할 수 없다.
  `,`■ 내 부모와 은인을 해치는 자가 있으니 구하러 가봐야 소용없다. 이익은 버리고 손해를 취하니 종신토록 곤궁하리라.
  `,`■ 재물과 여자가 귀살로 변하니 칼 끝의 꿀을 빨지 말라. 낮정단은 백호가 살이 되 흉한 재앙이 크다.
  `,`■ 돈을 들여 사업을 시작하면 생계가 방해 받는다. 부모나 윗분의 일은 나쁘고 달콤함도 막히리라.
  `,`■ 돈을 다 써버리는데 왜가나 도둑뿐이다. 면전에서는 잘해도 두 귀인은 도와 줄 생각이 없으리라.
  `,`■ 몸과 집이 모두 막혔다. 재물은 없어지고 처를 잃는다. 낮정단은 칼을 문 범을 만나니 재물과 여자로 두려운 일이 생긴다.
  `,`■ 꼬리에 머리가 있고 머리에 꼬리가 올랐다.
  일반인과 공직자중 공직자는 좋은 일이 없겠다.
  `,`■ 저 사람이 내게 왔는데 나중에 또 찾아 오리라. 비록 합했다고는 하나 이로운 것이 없다고 본다.
  `,`■ 처음에는 안되다가 나중에는 잘 되겠다. 어둡다가 깨어난다. 만약 토끼띠라면 두 귀인이 도와서 일을 이루리라.
  `,`■ 앞에는 비록 썩은것 뿐이요 뒤에는 도적이 기다린다. 피차 무례하니 억압받고 지키기도 어렵다.`]
  const SIBO1 = [`■ 1․2과에 덕․녹이 올라
  내 힘이 강하다.
  신규사가 있으나 경거망동
  하면 불길하고 서서히 전진해야 吉하다. 두 사람의 동업사가 있게 된다. 나의 투자가 많게 된다. 교역․교환사는 좋다. 
  반드시 재산을 증식하게 된다.
  `,`■ 자충수적 우환․질병이 
  있고 가정에도 우환이 있
  으며 가인이 원한을 품고
  가해하는 일이 기한다.
  가정을 속인다. 흉해가 크다. 만사가 곤란하고 놀랍고 도려운 일이 된다. 
  귀신을 해소 시켜야 한다.
  단, 관공직자는 매우 吉하다.
  `,`■ 후원의 재물이 들어 온다.
  행복중에 불행이 오고 불행
  중에 다행이 있게 된다.
  그러나 재앙이 적지 않다.
  돌연히 기한다. 특히 재물을 취하여 생기는 재앙이다. 대개 흉조이고 단, 관직자는 吉하다.
  七月 임신사가 있다.
  `,`■ 이익사가 있고 반드시
  소득한다. 자손에게 가택의
  명의를 이전한다. 의심이
  따른다. 1남2녀의 삼각관계가 있다. 장차 부귀할 운세이다.
  이권상실사, 여인 재해를 겪는다.
  `,`■ 자충수적 우환이 있고
  가정에도 우환이 있으며
  가인이 원한을 품고 가해
  하려 하고 가장을 속이는 일이 있다. 금전은 분수를 넘으면 재액을 초래 한다.여러 일을 묶으나 용두사미로 끝나게 된다. 그러나 재화는 경미하다. 부부가 음탕하다. 
  七月 임신사가 있다.
  `,`■ 임신사가 있다. 낙태한다.
  타인의 천거로 구재․구직
  은 吉하다.
  ⊕금전은 도난 당하나 도둑을 잡는다. 금전이 들어와도 자유롭지 못한 돈이 된다. 가택이 송사가 있고 송금이 오게 되나 막히는 일이 있다.
  `,`■ ⊕소아의 근심이 있고 
  ⊖도난․분실사가 있다
  모든 일이 지체되고 우연
  한 일이 벌어진다. 
  내외에 파란곡절이 많고 동분서주 한다. 
  득실도 계획에 어긋난다. 
  원행․이동․별리사가 있고 소송건도 있다.
  `,`■ 가정에 우환이 있고 가장
  을 속이며 가인이 원한
  을 품고 가해하려 한다.
  시작은 있어도 끝은 없고 금전도 공허하게 된다. 
  가정과 부부 모두에 불행사가 중첩된다. 
  귀신을 제거 하면 흉화는 면한다.
  男:권리상실이 기한다.
  여:남편의 凶兆가 있다.
  `,`■ 가정에 임신사가 있다.
  일이 한가지만이 아니다.
  女는 극부하여 많은 財
  를 얻는다. 
  男은 반드시 직위해탈과 불명예사가 발생하므로 대비해야 한다. 
  또, 봄철 정단은 가택화재를 예방하라. 
  다인 다사건이다.
  `,`■ 1녀2남의 삼각관계가
  있다. 여자나 상대가
  스스로 와서 의탁하고
  손해와 일을 방해하게 된다. 
  그러므로 사기ㆍ부실ㆍ도난을 예방해야 한다.
  특히 자손에게 재해가 일어날 조짐이다.
  신규사도 의심이 따른다.
  `,`■ 저주사가 있다.
  ⊕소아근심, ⊖여인근심이
  있다.
  인․택이 쇠퇴하는 듯 걱정이나 ⊕전도에 원대한 희망을 본다. ⊖여자가 남자를 적극적으로 유혹하여 도망갈 심산에 있다.
  관재 근심이 있으나 해소가 된다. 
  七月 임신사가 있다.
  `,`■ ⊕소아근심, ⊖여인근심사가
  있고 손해와 손모가 多하고
  흉이 크다.
  진퇴양난으로 상업․구재․ 투자 등의 계획도 뜻대로 안된다. 귀신을 제거하고 수양․근신하면 나중에 기회가 오게 된다. 
  七月 임신사가 있다.
  권리와 이권, 처첩을 喪失하거나흉이 기한다`]
  const SIBO2_1 = [`■ 직녹신변외사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  수구대신의 상이다.
  피아 협력을 한다.
  `,`■ 손모가정 질병사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■병․夫, 목전사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다. 부모상해․불구의 상이다.
  `,`■財․女,동요 신변사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 재액이 있다.
  `,`■官․夫가,정질병사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다.부모 재액 예방이 필요하다.
  `,`■財․女,가택내사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  이전 이사를 한다.
  `,`■ 자손․박관 가택내사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.
  색정불화 한다.
  `,`■ 자손․극복가정의 혹사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官․夫,가정화합사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다.
  男직위상실하고
  女부부이별한다.
  `,`■ 이동․별리 이심내사이다.
  내방조짐 상의 :  
  원행·이동사가 있다흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官․夫,가정내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■官･夫,별리사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/039/row-1-column-1.png`,
    `/static/images/sagwa/039/row-1-column-2.png`,
    `/static/images/sagwa/039/row-1-column-3.png`,
    `/static/images/sagwa/039/row-1-column-4.png`,
    `/static/images/sagwa/039/row-1-column-5.png`,
    `/static/images/sagwa/039/row-1-column-6.png`,
    `/static/images/sagwa/039/row-1-column-7.png`,
    `/static/images/sagwa/039/row-1-column-8.png`,
    `/static/images/sagwa/039/row-1-column-9.png`,
    `/static/images/sagwa/039/row-1-column-10.png`,
    `/static/images/sagwa/039/row-1-column-11.png`,
    `/static/images/sagwa/039/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 12신이 본궁에 있으며 엎드려 움직이지 않으므로 복음과(伏吟課)로 된다. 양일에는 일간상신의 해(亥)를 사용하여 원태격(元胎格)을 구성한다. 일간일지가 상하내외로 해인(亥寅)이 상합하고 교호로 상생하며 상하가 상합하므로 초전은 일록(日祿)으로 된다. 
    일간상신 왕록(旺祿)이 신(身)에 임하면 내가 힘을 얻어 양호하다. 그러나 경거망동은 불길하고 조용하게 서서히 전진하면 길하다. 무슨 일에서나 두 사람이 서로 모여서 화합한다. 그러나 내가 상대방에 기여하므로 유정하지만 물심(物心)에 소모가 있다. 3전이 체생(遞生)하고 말전이 재성으로 되어 공망을 벗어나므로 재는 소득한다.
    백법에는 ｢교차상합은 교역(交易)․교환(交換)에서 모두 길하다｣고 하였다. 상하가 다 합으로 되면 서로가 뜻이 상통하여 화합한다. 
    비전에서는 ｢일덕이 해(亥)에 승하면 덕이 천문에 든다고 하여 시험은 반드시 합격한다｣고 하였다. 해(亥)를 천문이라 하여 신조(神助)를 얻은다. 녹왕이 간상에 가해져서 초전을 이루면 모사나 기타 유리한 일에 참여하여 반드시 덕록을 얻는다. 
    일귀는 순행하여 3전은 상(常)․후(后)․주(朱)이고. 야귀는 역행하여 3전이 공(空)․합(合)․귀(貴)이다.`
    ,
    // 2
    `  이 과는 4과 중에서 3과에 상하의 극이 있으며, 양일(陽日)이니 양의 극을 취하여 자축(子丑)을 초전으로 하는 지일비용과(知一比用課)이다. 일간상신의 술(戌)이 말전으로 되므로 참관격(斬關格)이며, 일간상신 술(戌)의 귀(鬼)에 백호가 승하니 흉재가 심하다. 일간 임(壬)은 초․중전으로 왕성하다. 말전 술토(戌土)는 일간을 극하여 흉에 흉을 가한다. 술(戌)이 해(亥)에 가해지면 괴도천문격(魁度天門格)이다. 천장에 흉신이 승하여 참된 흉해가 있다. 그러나 말전이 최관부(催官符)이니 사관․구직은 가장 길조를 얻는다.
    백법에는 ｢일간상신에 묘(墓)․호(虎)가 앉으면 질병이 가장 흉하고 신속하게 발생한다｣고 하였다. 일간일지가 교차상극하여 교섭․타협은 성립되지 않는다. 
    비전에는 ｢천지반 중에 진사(辰巳)가 중첩되고 사신(蛇神)이 승하므로 양사협묘(兩蛇夾墓))가 되어 뱃 속에 적괴․암병이 있다. 만일 년명행년(年命行年)이 진(辰)으로 되면 병이 급발한다. 년명이 해술(亥戌)인 경우에는 사호(蛇虎)를 충하여 파묘(破墓)로 되니 다소 오래간다. 3전이 자해술(子亥戌)인 것은 음경(陰境)뿐으로서 중음(重陰)의 지(地)이니 만사가 곤란하고 놀랍고 두려운 일이 있다｣고 하였다.
    일귀는 순행하여 3전이 현(玄)․상(常)․백(白)이며, 야귀는 역행하여 3전이 청(靑)․공(空)․백(白)이다.`
    ,
    // 3
    `  이 과는 4과에서 1상이 1하를 극하여 술자(戌子)를 초전으로 하니 원수관전젹(元首關傳格)으로 된다. 일간상신의 유(酉)는 일간을 생하고 일지상신의 자(子)는 일지 인(寅)을 생하여 피차가 서로 이익을 얻는다. 제4과를 초전으로 하는 것은 맥월격(驀越格)으로서 사물이 돌연히 일어난다. 
    일간에서 3전을 보면 재관인(財官印)으로 되어 관공(官空)의 임무는 명리가 향상된다. 구재인 경우에는 재가 살(殺)을 생하고 재가 귀(鬼)로 화하니. 재를 구(求)하여 재앙이 생긴다.
    백법에 ｢일야양귀신(日夜兩貴神)이 모두 공망하므로 경사는 공허하며, 백호가 둔귀(遁鬼)에 승하니 재앙이 적지 않다｣고 하였다. 
    비전에는 ｢초전에 청룡이 승하여 일(日)을 극하니 행복한 가운데 불행이 있고, 중전 신(申)에 백호가 승하여 일간을 생하는 것은 불행 중에 다행이다｣라고 하였다. 따라서 일희일우(一喜一憂)의 형상이다.
    일귀는 순행하여 3전은 청(靑)․백(白)․현(玄)이고, 야귀는 3전이 백(白)․현(玄)․후(后)이다. 일야 모두 3전에 현무․백호가 앉으므로 대개는 흉조(凶兆)를 이룬다. 3전이 술신오(戌申午)이며 패려격(悖戾格)으로 된다. 3전은 완전히 음의 경계에 있어서 오(午)에 이르나, 음을 나가지 못하므로 이와 같은 이름을 이루는 것이다.`
    ,
    // 4
    `  이 과는 4과 중에서 제2과에서 1상이 1하를 극한다. 사신(巳申)을 초전으로 하여 원수원태과(元首元胎課)로 된다. 일간 임(壬)에서 상신의 신(申)은 장생에 해당하며, 초전은 재신이지만 공망한다. 그러니 초전의 공망은 과숙격(寡宿格)이 된다. 또 4과이지만 3과뿐이니 불비격(不備格)이다. 
    일간상신의 신(申)의 지(支)는 역마로, 일지상신은 간록(干祿)으로 되어 부귀격(富貴格)이다. 4과의 상하는 육해로 된다.
    백법에서는 ｢녹신이 지상에 임하면 권섭부정(權攝不正)이 되며, 일간일지상신이 역마와 건록을 만나는 것은 부귀할 징조이고, 육해가 서로 따르는 것은 피차간에 의심한다｣고 하였다.
    비전에는 ｢취직은 일간상신이 금속에 관련된 직이다. 화(火)로서 연금(煉金)하므로 금․철의 공업이거나 또는 지상(支上)에 해(亥)의 수(水)가 있으니 수리(水利)에 관계되는 업에 종사한다｣고 하였다. 3전이 체생일지라도 초․중전이 공망되므로 생을 받아도 이익이 없다. 일간의 해(亥)는 일지상신에 가서 일지를 생하니 내몸은 상대방에 의존하여 도리어 그에게 이익을 준다.
    일귀는 역행하여 3전이 음(陰)․사(蛇)․구(勾)이고, 야귀는 순행하여 3전이 귀(貴)․합(合)․공(空)이다. 불비격은 바르지 못하다. 서양자(壻養子)를 맞을 때는 충분하지는 못하나 가정을 위하여 힘을 다하는 사람이다. 현태격(玄胎格)의 공망은 산육(産育)을 정단하면 공망하니 생육하기 어렵다.`
    ,
    // 5
    `  이 과는 4과 중 3과에 상하의 극이 있다. 하에서 상을 극하는 술인(戌寅)을 사용하여 초전으로 하니 중심염상과(重審炎上課)이다. 일지상신이 술(戌)을 초전으로 하는 것은 참관격(斬關格)이다. 
    3합화국(三合火局)은 3전이 전재(全財)로 되고 귀(鬼)로 화하므로, 구재는 분수를 알고 해야 하며, 과분할 때는 재액을 초래한다. 술(戌)은 명암이귀(明暗二鬼)로서 백호가 승하므로 흉해가 중하다. 그러나 인목(寅木)은 살을 제어하므로 해를 이루지 않고, 해를 입어도 경미하게 끝난다. 4과가 교차상극(交車相剋)하고 3전이 상하로 화합되므로 4과는 시작으로 하고 3전은 결과로 본다. 그러므로 처음에는 극하고 후에는 화합한다.
    백법에 ｢둔귀백호(遁鬼白虎)에 승하는 것은 재앙이 적지 않고 무슨 일에서나 경사는 삼합․육합을 이룬다｣고 하였다. 피차간에 투합되지 못하는 것은 지형상(支刑上)에 있다. 
    비전에는 ｢술(戌)이 인(寅)에 가해지고 청룡이 앉아서 귀(鬼)｣로 되면 년명(年命)이 인(寅)인 사람은 일을 도모하면 파동이 있은 뒤에 이루어진다｣고 하였다. 신해일(辛亥日)에 응과(應果)가 있는데, 신(辛)은 일간을 생하고 해(亥)는 말전과 지합한다. 
    일귀는 역행하여 3전은 청(靑)․현(玄)․사(蛇)로서 초전이 청(靑)이고 말전이 사(蛇)인 것은 용두사미로 끝난다. 야귀는 역행하여 3전이 백(白)․후(后)․합(合)이다.`
    ,
    // 6
    `  이 과는 일간일지에 상하의 극이 있으며 하에서 상을 극한다. 제1과 오해(午亥)를 사용하여 초전으로 하니 중심과(重審課)이다. 초전의 오(午)는 순수(旬首)가 승하여 육의(六儀)로 된다.
    일간상신이 재효로 되고, 초전과 말전은 일간의 장생․생기로 되어 구재․구직에는 모두 길하다. 말전에 현(玄)․백(白)이 승하여 일간을 생하면 불행한 가운데 길행을 얻는다. 
    일간일지는 교차상생하고 음신은 각각 묘(墓)에 앉는다. 가정내부에 정체가 있다. 오(午)의 재에 현무가 승하면 재의 도난을 만나는데, 잡을 수 있다. 오(午)는 일간에서 극하고 천후(天后)에 승하는 것은 재를 극하여 겹치면 복태격(腹胎格)이라 하여 신월(申月)에 정단하면 처첩이 잉태한다.
    백법에는 ｢재신이 협극을 만나는 것은 재의 자유를 얻지 못한다｣고 하였다. 피차간에 정의가 투합되지 못하는 것은 지형상(支刑上)에 있으며, 3전이 체생(遞生)하고 초전에 순의(旬儀)가 있어서 타인의 천거를 받는다.
    일귀는 순행하여 3전이 현(玄)․주(朱)․백(白)이며, 야귀는 3전이 후(后)․구(勾)․현(玄)이다. 처재태신(妻財胎神)은 처첩이 임신한다.`
    ,
    // 7
    `  이 과는 천지신이 충위(冲位)에 있어서 반음과로 되며, 4과가 전부 상극한다. 양일(陽日)은 하에서 상을 극하는 인신(寅申)을 사용하여  초전으로 한다. 일간상신 사(巳)는 재효로 되나 공망하므로 재는 허모(虛耗)된다. 초․말전의 寅은 설기로 되고, 현무(玄武)가 승할 때는 도난․분실 사건이 있다. 4과 상하에 사신인해(巳申寅亥)의 지합이 있다. 또 신해(申亥)는 육해로 되어 상하가 화합하는 가운데 장애가 발생한다. 제4과를 초전으로 하는 것은 맥월격(驀越格)으로서 모든 일이 지체되거나 맥연(驀然)하게 발생된다.
    백법에는 ｢상하가 모두 합하면 두 마음이 서로 일치된다｣고 하였다. 일간일지에서 상신(上神)이 절신(絶神)으로 되면 결절(結絶)에 좋다. 백호가 일지상신 신(申)에 앉으면 집안 사람에게 질병이 발생한다. 인신(寅申)이 칠충(七冲)하여 역마로 되니 내외에 파란이 많고, 또 동분서주할 징조가 있다.
    일귀는 역행하여 3전이 사(蛇)․백(白)․사(蛇)이며, 야귀는 순행하여 3전이 현(玄)․합(合)․현(玄)이다. 즉 천장이 모두 불길한 신이다. 반음(返吟)이란 반복이 비상하여 오는 자는 가려고 생각하고 떠날 때는 돌아올 일을 생각한다. 득실이 일정하지 않으므로 물건을 얻으면 먼저 잃고, 사물이 파탄될 때는 도리어 이루어진다. 3전이 인신인(寅申寅)이면 원행(遠行)․이동․소송 사건이 있다. 천장이 흉장이니 손실하며, 부지런하여도 이익되는 것이 없다.`
    ,
    // 8
    `  이 과는 4과 중 3과에 상하의 극이 있으며 양일이니 양비의 극인 자미(子未)를 사용하여 초전으로 하니 지일비용과(知一比用課)를 구성한다.
    일간 임수(壬水)는 진(辰)을 보고 묘신이 일상(日上)에 있어서 신(身)을 복(伏)한다. 일지 인목(寅木)은 미(未)를 보아 묘신에 해당되는데 택상에 엎드렸으며, 중전 사(巳)의 처재는 공망하고 사화(巳火)에 술(戌)이 가해져서 묘(墓)로 된다. 그러므로 재는 공허하게 되고 처(妻)는 묘(墓)하여 사액(死厄)이 있다. 
    요컨대 아(我)․택(宅)․처(妻) 모두가 3묘(三墓)로 되어 가정과 부부 모두에 불행이 중첩되어 일어난다. 초전은 실(實)하나 중․말전은 공망하니 시작은 있어도 끝이 없는 형상이다. 태상은 주연(酒宴)의 신 미(未)에 승하여 일간을 극하니 즐거운 연회 가운데서 재병(災病)에 걸린다. 일간의 진(辰)은 말전의 술(戌)에서 충하여 개묘(開墓)로 되니 혼미한 중에 통하는 곳이 있다.
    백법에는 ｢간지상신(干支上神)에 모두 묘신이 승하여 혼미하다｣고 하였다. 
    비전에는 ｢자(子)가 미(未)에 가해지고 육합이 승하면 사(蛇)가 재앙을 일으키며. 순기(旬奇)가 초전으로 되면 흉화는 면한다｣고 하였다.
    일귀는 역행하여 3전은 합(合)․음(陰)․청(靑)이고, 야귀는 순행하여 3전이 백(白)․귀(鬼)․청(靑)이다. 순기란 해자축(亥子丑)을 채용한 것으로서, 갑신(甲申)과 갑오(甲午)의 순(旬)은 자(子)를 사용하고, 갑자(甲子)와 갑술(甲戌)의 순은 축(丑)을 사용하며, 갑진(甲辰)과 갑인(甲寅)의 순은 해(亥)를 사용한다. 그러나 아직도 불합리한 점이 남아 있어 연구할 여지가 있다.`
    ,
    // 9
    `  이 과는 4과 중의 제2과에서 일하가 일상을 극한 미묘(未卯)를 초전으로 하며 중심곡직과(重審曲直課)로 된다. 일지상신 오(午)는 순수(旬首)이고, 일간상신 묘(卯)는 순미(旬尾)이다. 따라서 수미상견(首尾相見)으로 모두 길하다.
    말전 묘(卯)는 지상(支上) 오(午)의 재신을 생하여 재가 강력하게 되므로 많은 재를 얻는다. 곡직격(曲直格)은 모든 일에서 한 가지 일만이 아니다. 일간일지는 상신에 탈기로 되어 인택 내외 모두에 소모할 일이 있으므로 모든 일에서 조용히 현상 유지를 하는 것이 좋고, 활동하면 모두 불길하게 끝난다. 또 사업을 계획해도 비용 때문에 지탱하지 못하게 된다.
    백법에는 ｢수미상견(首尾相見)함은 끝가지 길하다｣고 하였다.
    비전에는 ｢인(寅)에 오(午)가 가해지고 사(蛇)가 앉으니 춘월에는 자기집 또는 옆집의 화재를 방비해야 한다｣고 하였다. 3전이 해묘미(亥卯未)의 목국(木局)을 이루고 길장이 승하면 때를 기다려 움직이는 것이 길하다. 
    일귀는 순행하여 3전은 구(勾)․상(常)․귀(櫷)이고, 야귀는 3전이 주(朱)․공(空)․음(陰)이다.`
    ,
    // 10
    `  이 과는 4과의 신사(申巳)가 1하애서 1상을 적하여 중심원태과(重審元胎課)로 되고, 4과이나 3과뿐으로서 불비격(不備格)이다. 일지는 일간상신에 가해져서 췌서격(贅壻格)이다.
    그가 와서 내게 의지하니 내몸은 상대방에게 탈기되어 소모의 손해가 있다. 일간상신과 말전에 탈기한다. 초전의 신(申)은 생기, 즉 장생으로 되지만 공망에 앉으니 힘이 없고, 중전 건록이 천공에 승할 때는 공망과 동일하여 모두가 허모손실된다. 
    현무가 인(寅)에 승하면 도난 당하고 분실한다. 역마가 공망하면 움직일 수 없으며, 불비격이므로 바르지 못할 우려가 있다.
    백법에 ｢피차간에 서로 의심을 사는 것은 육해가 있기 때문이다｣고 하였다. 천장내전(天將內戰)이 있으면 도모하는 일에서 위험을 만난다. 초전 청룡이 생기에 앉는 것은 늦은 용건의 정단이다. 인(寅)과 현무가 승하여 탈도격(脫盜格)으로 되니 무슨 일에서나 허사(虛詐)․분실․도난에 주의해야 한다.
    비전에는 ｢신(申)이 사(巳)에 가해지고 육합이 승하는 것은 내전으로 되어 자손에게 재해가 일어날 징조다｣라고 하였다.`
    ,
    // 11
    `  이 과는 4과 중에 상하의 극이 세 자리 있으며 하에서 상을 적하는 진인(辰寅)을 사용하여 초전으로 하니 중심간전과(重審間傳課)가 된다. 초전의 진(辰)은 일지상신을 사용하여 참관격(斬關格)이다. 그리고 진(辰)은 일간의 묘신에 해당하고 말전의 신(申)은 일간의 장생이 되니 처음에는 혼미하나 후에는 깬다.
    참관격의 진(辰)은 공망을 만나서 모든 일에 의지할 곳이 없으니 귀묘(鬼墓)라 해도 겁날 것이 없다. 처음은 어려우나 후에는 쉽다. 3전이 진오신(辰午申)이면 등삼천격(登三天格)으로서 용이 하늘에 올라 구름을 부르고 비를 뿌리며 작은 것을 변하게 하여 크게 하므로 병이나 소송사는 불길하다. 초전의 공망은 과숙격(寡宿格)이다.
    백법에 ｢진묘(辰卯)가 중첩되는 것은 강색귀호(罡塞鬼戶)로 되어 모사에 적합하고 간묘병관(干墓倂關)은 인택(人宅)이 쇠퇴한다｣고 하였다.
    일귀는 순행하여 3전은 사(蛇)․합(合)․청(靑)이고, 사(蛇)가 청(靑)으로 화하니 전도에 원대한 희망이 있다. 야귀는 3전이 후(后)․사(蛇)․합(合)이다. 백호가 진(辰)에 앉아서 일간을 극하면 질병 또는 재환에 걸리면 반드시 죽는다. 건강하다면 병에 걸려서 초전에 사신(蛇神)이 앉으므로 마음이 진정되기 어렵고 괴상한 꿈을 꾼다. 사신(蛇神)은 걱정스럽고 놀라운 일이 있는데 소아의 사건이다.`
    ,
    // 12
    `  이 과는 제2과와 제4과에 상하의 극이 있고 하에서 상을 적하는 진묘(辰卯)를 초전으로 하니 중심진여과(重審進茹課)이다. 초․중․말이 모두 공망하여 전진도 후퇴도 할 수 없고, 길흉은 모두 공허하여 이루어지지 않는다. 일간일지의 상신은 앞의 지(支)에 해당하여 나망격(羅網格)으로되니 망동은 불길하다. 임(壬)은 자(子)에 제왕으로 되고 일지는 묘(卯)의 제왕으로 되어 서로 왕성하므로 피차에 모두 이익이 있다. 중․말전의 재신이 공망하니 상업․구재․투자 등은 뜻대로 진행되지 않는다.
    백법에 ｢진여격(進茹格)의 공망은 진퇴가 모두 뜻대로 되지 않는다｣라고 하였다. 
    비전에는 ｢나망(羅網)은 양인(羊刃)이 일간․일지상신에 승하면 흉이며, 흉장이 승하면 가장 흉하다｣고 하였다. 또 이르기를 ｢진(辰)이 택으로 되고 초전으로 되면 남의 원망이 있어서 성공할 수 없으며, 진여가 공망되면 공곡전성(空谷傳聲)과 같다｣고 하였다.
    일귀는 역행하여 3전은 사(蛇)․주(朱)․합(合)이며, 야귀는 순행하여 3전이 후(后)․귀(貴)․사(蛇)이다. 교차상생(交車相生)하므로 일간일지의 상하는 서로 지합한다. 합은 화순(和順)한 신으로서 음양이 배합되고 기우(奇偶)가 교차되므로 모든 일이 이루어지고 상하가 화합한다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
