
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,3,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,8,2,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,9,1,`특별있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,12,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,11,11,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,12,10,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,1,9,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,2,8,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,3,7,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신뢰`,4,6,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,5,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`설득`,6,4,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관려사`,`小`,`7`,`손해도망`,`파재극처`],
    [`색정`,`외남 색정사 유`,`大`,`단절기`,`미세관訟`,`途中청원`],
    [`가필유혼(可必有婚)`,`반드시 가정에 혼인 경사가 있는 상`,`大`,``,`고시득길`,`고위행길`],
    [`음인암손(陰人暗損)`,`남모르는 연인에 의해 손해를 입은 상`,`小`,``,``,`면담청원`],
    [`자매`,`자매 관련사`,`小`,``,`동요불안`,`遠當莫入`],
    [`동요`,`외사 동요불안분산사`,`大`,`8`,`분산사`,`개신개혁`],
    [`개신`,`내부 가정 개신사`,`小`,`침체기`,`모두흉함`,`봉림유사`],
    [`난수복덕(難受福德)`,`명예권위식복 등에 관련하여 얻어지는 것이 없다-는 상`,`小`,``,``,`(숲에서뱀`],
    [`이익전포(利益展布)`,`고위에 안좌하여 다중이익을 널리 알리는 상-단, 부족`,`大`,``,``,`을만난격)`],
    [`급속`,`내부가정 급속사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,``,`생업침체`,`급속사`],
    [`매사 영속성없음`,`관사직업권리남편남자사는 선천적 기복재해를 겪음`,`小`,`9`,`관직이익`,`손해가출`],
    [`불능유구(不能悠久)`,`변수에 의해 내외를 오랫동안 반듯히 유지하기가 어려운 상`,`大`,`소외기`,`평민불리`,`문서암동`],
    [`문서암동(文書暗動)`,`문서가 남모르게 넘어가는 상 `,`小`,``,``,``],
    [`결속`,`가정동요불안분산부부개변사개혁하여 이혼사가 발생함`,`大`,``,``,`부부대립`],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`10`,`결속단합`,`동요불안`],
    [`매사 영속성없음`,`관사직업권리남편남자사는 선천적 기복재해를 겪음`,`小`,`침해기`,`사사성취`,`분산개변`],
    [`상길관기(常吉官忌)`,`일반인은 좋으나 관직자는 나쁜 상`,`大`,``,`단,부족`,`不測근심`],
    [`불축지우(不測之憂)`,`예측이 안 되는 근심사가 있는 상`,`小`,``,``,``],
    [`형제`,`형제 비용과출사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`11`,`비용과출`,`동기화합`],
    [`화합`,`내부가정 화합사`,`小`,`쇠퇴기`,`재물암동`,`관직이득`],
    [`부모허재(父母虛災)`,`부모의 재난이 근심스러우나 허(虛)한 것이다-의 상`,`小`,``,`(재물이 몰`,`평민불리`],
    [`봉흉화길(逢凶化吉)`,`흉을 만났으나 변하여 길하게 되는 상`,`大`,``,`래이동함)`,``],
    [`후원`,`후원조력사`,`大`,``,``,``],
    [`출행`,`외사 출행이동사`,`大`,`12`,`출행이동`,`개신개혁`],
    [`개혁`,`내부가정 개신개혁사`,`小`,`과시기`,`송구경황`,`역유도난`],
    [`유의폐구(惟宜閉口)`,`오로지 사는 방법은 입을 굳게 다무는 것이다-의 상`,`小`,``,`(통정인나`,`(도둑맞음)`],
    [`역유도난(역유도난)`,`여러가지 불리한 차에 도난 까지 당한 상`,`小`,``,`타남)`,``],
    [`후원`,`후원조력사`,`大`,``,``,``],
    [`문서`,`직업 상하화합 관련사`,`大`,`1`,`상하화합`,`색정사有`],
    [`색정`,`내녀 색정사 有`,`小`,`절정기`,`일체근심`,`재물암동`],
    [`유의좌수(惟宜座守)`,`반드시 적극 나서지 말고 일을 앉아서 해야 좋다-는 상`,`小`,``,`해소됨`,`(재물을 빼`],
    [`모사미수(謀事未遂)`,`계획한 일이 이루어지지 않고 있는 상`,`小`,``,``,`돌림)`],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`막힘`,`외사 막힘사`,`大`,`2`,`생업막힘`,`가정수혜`],
    [`결속`,`결속사를 음2월 완성 권리사를 크게 이루나 病 형재災害`,`小`,`면모기`,`동지함의`,`화소복지`],
    [`재산제중(財散濟衆)`,`돈을 들여 여러 사람을 가지런하게 하는 상 `,`小`,``,``,``],
    [`동지함의(動止咸宜)`,`안방을 잘 지킴으로 가나 있으나 좋은(자유로운) 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`3`,`급속사有`,`면모훼손`],
    [`자식`,`자식 관련사`,`大`,`학습기`,`문서유실`,`일체근심`],
    [`불리전향(不利前向)`,`여기서 그만 앉아서 지키고  앞으로 나아가지 말라-는 상`,`小`,``,``,`해소됨`],
    [`간음시리(姦淫是利)`,`간음사가 이익인데도 정대적을 원하면 재앙이 되는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`막힘`,`내부가정 막힘사`,`小`,`4`,`타인시혜`,`가정막힘`],
    [`여식`,`여식 관련사`,`大`,`개시기`,`간음무도`,`도둑놓침`],
    [`양귀수상(兩貴受傷)`,`도와줄 고위인들이 傷하여 추한 정황에 처한 상`,`小`,``,``,``],
    [`손괴재물(損壞財物)`,`재물이 손해나거나 부서진 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,`소송사有`,``],
    [`소송`,`외사 급속송쟁파재극처사-실종 우려`,`大`,`5`,`도중청알`,`손해가출`],
    [`가출`,`가정 손실 가출사`,`小`,`배양기`,`(고위행길`,`음인암손`],
    [`자기일락(自己逸樂)`,`본인은 좋고 타인은 침해를 받고 있는 상`,`小`,``,`할때청원`,`연인의해`],
    [`도난포획(盜難捕獲)`,`도둑을 찾으나 잡기가 어려운 상`,`大`,``,`하면좋음)`,`손실이됨`],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`6`,`타인압박`,`출행`],
    [`출행`,`내사 출행이동원행사`,`大`,`존재기`,`봉林유蛇`,`간음무도`],
    [`고귀최길(告貴最吉)`,`고위인과 소통하여 매우 좋은 일을 맞는 상`,`大`,``,``,``],
    [`간음무도(姦淫無道)`,`간음사를 절제없이 행하는 상 `,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`금전사업색정조력사로 가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`자매친구동료조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`형재친구동료조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,`후원문서부모조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,`후원문사색정조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`자매친구동료조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,"직업직위불안남편갈등자식사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,"직업직위불안남자갈등여식사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`형재친구동료조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,`금전사업출행여인조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`조격복닉은폐(伏匿阻隔隱蔽)**만약 타인과 다툰다면 조용히 그 자리에서 물러나는 것이 이로움`,0,0,`상대와 맞서다가는 본인의 감춰진 사실들이 드러날 우려가 크므로 다투는 일에서 피해야 하는 人`,0,0,`일이 결과없이 반복되고 점점 어두워지는 상**군자가 때를 기다리는 격이나 포기가 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威세**만약 타인과 다툰다면 상대는 호가호위의 세를 부리나 공격할 뜻은 없음 `,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란 듯하는 심중이나 조용히 있으면 놀램이 자연 사라질 人 `,0,0,`여건조건이 부족불비함**1남2녀 삼각관계有**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결해야만 유리함 `,0,0,`명분이 있는 사안이므로 정정당당하게 속결해야 하고 좌고우면하거나 사안을 미루게 되면 되려 불리해지는 人`,0,0,`관직자 고위에 안좌하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 사안을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 하나로 결속시켜 개신개혁함**재원의 바탕을 크게 세우나 부부이별이 우려됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 사안을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 갑자기 발생함**일을 종료해야 우환을 면함**이럴까 저럴까 기로에 있음 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화가 밖에서 침입하니 신중해야 함`,0,0,`남녀 색정왕래로 가정동요불안사가 있는 人`,0,0,`가정다툼有**실재 피해는 없게 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 사안을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`제사지낼 상속자가 없게 되는 상**금전은 크게 획득하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순을하며 유화적 처신으로 일을 지체시키거나 뒤로 미루어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순한 처신을 보여야 하고 결정을 후로 미루면 반드시 결과가 유리해지는 人 `,0,0,`복잡다단한 여러 일을 하나로 결속시키려 함-음2월이라야 완성됨**여자가 남의 남자를 적극 유혹하여 자기 남자로 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 호가호위의 세를 부리나 공격할 뜻은 없음 `,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란 듯하는 심중이나 조용히 있으면 놀램이 자연 사라질 人 `,0,0,`풍운불측의 상-타의에 연루됨-남자 혈책사-여자 사통사**간사은닉건이 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결해야만 유리함 `,0,0,`명분이 있는 사안이므로 정정당당하게 속결해야 하고 좌고우면하거나 사안을 미루게 되면 되려 불리해지는 人`,0,0,`1남2녀 삼각관계有**입을 닫아야만 이로움**내외 암운함**물러나고 싶으나 계속됨-사통사 포함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순은유화유순후결**만약 타인과 다툰다면 대항하지 말고 순을하며 유화적 처신으로 일을 지체시키거나 뒤로 미루어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순한 처신을 보여야 하고 결정을 후로 미루면 반드시 결과가 유리해지는 人 `,0,0,`일을 앞으로 진행하여 중도에서 토함이 좋음-결과가 침체하게 됨**폐한사가 있으나 전화위복 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`스스로 유약하다고 여기고 적극적 활동을 포기하면서 신음하는 人-신체자유가 억압된 경우도 있음`,0,0,`가정이 편안하지 않은 상**만난 자는 가고 간 자는 오려함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`미사`,`구사`,`돌연사`,`모호사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-출비됨`,`아발동사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-출비됨`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-침체인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용출비`,`타발동급속기로사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사-급속사겹침`,`미사`,`신구중간사`,`내사`,`표면사이면사겹침`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음7월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음6월`,2,3,5,`음2월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월-연기우려`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음7월`,3,2,5,`음1월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음6월`,1,1,2,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5월`,1,1,2,`음11월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음4월`,2,3,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월`,3,1,4,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음10월`,2,2,4,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 타인과 같은 소리를 내며 좋은 조건에도 불구하고 엄무를 책임자가 아닌 직원의 수준으로 점점 기울게 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 수준이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 권한을 양도하는 사안을 발생시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무를 결속시켜 개신개혁을 이루나 반대가 많아 분산사가 발생함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하며 배경이 없으나 초 중반에 대외적 실적은 수준을 이루지만 내부는 침체한 결과를 초래함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무를 전반적으로 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하고 업무 초반에 저조하나 중 종반에는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 초반 이외 중 종반에는 수준 이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무 전반에 걸쳐 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무를 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무 초반에 警恐사를 일으키나 전화위복되고 종반에는 침체하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 초중 종반 모두 수준 이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선損후攻`,`進向`,`원`,`留`,`무난`,`남남서`,``,`정서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`게세무력`,`이행`,`선이후損`,`退背`,`  원`,`  去`,`주인조력`,`서남`,``,`북서`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세없음`,`이행`,`선이후이`,`進向`,`  근`,`  留`,`주인가해`,`정서`,``,`북북동`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`본인무력`,`退背`,`  원`,`  去`,`주인가해`,`북서`,`북북동`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,`기세없음`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인반대`,`이행`,`본인무력`,`退背`,`  원`,`  去`,`주인조력`,`동남`,`북북동`,``],
    [`10.11`,`多集`,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`기세없음`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세무력`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`정북`,`정남`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`정남`,`남남동`,``],
    [`10.11`,``,`기세없음`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,`  集`,`발현안됨`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인합세`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`주인조력`,`남남서`,`동남`,``],
    [`10.11`,` 多集`,`기세무력`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`내부측근`,`겨우제압`,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부합세`,`이행`,`선攻후攻`,`退背`,`원근왕래`,`  去`,`세입조력`,` 남서`,`남남동`,``],
    [`10.11`,``,`끌면제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선攻후費`,`退背`,`원근왕래`,`  去`,`세입조력`,`동남`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선이후이`,`退背`,`원근왕래`,`  去`,`세입조력`,`남남동`,``,`남남서`],
    [`10.11`,`多集`,``,``,``,``,``,``,`이후손실`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대없음`,`이행`,`선費후費`,` 進向`,`  근`,`  留`,`무난`,`정남`,``,`남서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,``,`특별있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,"제례부정","제례위법",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,`남남동`,`있음`],
    [``,``,``,0,0,`묘향`,`북북서`,``],
    [`생업`,`자상귀`,`목맴`,0,0,`전경`,`향대`,``],
    [``,``,``,0,0,`묘속`,`뱀굴`,``],
    [`가정`,``,``,0,0,`육친`,`  父`,``],
    [`질병`,``,``,0,0,`묘좌`,`동남`,`없음`],
    [``,``,``,0,0,`묘향`,`북서`,``],
    [`생업`,`음귀`,`묘지탈`,0,0,`전경`,`향대`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,``,``,0,0,`육친`,`  母`,``],
    [`질병`,`수귀`,`익사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,` 수귀`,`익사`,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,`동북`,`없음`],
    [``,``,``,0,0,`묘향`,`남서`,``],
    [`생업`,``,``,0,0,`전경`,`향대`,``],
    [``,``,``,0,0,`묘속`,`棺전복`,``],
    [`가정`,`음귀`,`묘지탈`,0,0,`육친`,`조모`,``],
    [`질병`,`자살귀`,`목맴`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,` 수귀`,`익사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,"저주부정","하인원심",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사 기로출행이동원행`,`도적못잡음`,`내사 파재극처사`,`酒食수벌사`,`외사동요분산사`,`손괴재물사`,`매사 허성불성-세속허사자**내사 강제급속사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,`매사 위역사**내사 동기화합사`,`면굴난해사-옥송관련`,`외사 기로출행이동사`,`주력사 흉함`,``,`경미송사-고시득길사`,`외교막힘`,`먼데서 막 도착하여 숲에서 뱀을 만난격-이동사`,`외사 손실급속사`,`길가는 고위인에게 청원함이 좋음`,`내부가정막힘`,`간음무도사`,`매사 위역사**남녀직원공모사有-은닉사**음인암손사`,`외사급속송쟁파재실종상해우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁`,``,`전도`,``,`빈궁**병재`,`공공사사구설사`,`빈궁`,`고위안좌 다중이익포고사-부족`,``,`전화위복`,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁`,``,``,``,``,`경구침체사`,`관제**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**남녀직원공모사有-은닉사`,`외사급속송쟁파재실종상해우려`,`내사 기로출행원행사`,`도적 모잡음`,`내사 파재극처사`,`酒食수벌사`,`외사 동요분산사`,`손괴재물사`,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,`매사 위역사**내사동기화합사`,`면굴난해사-옥송관련`,`외사 기로충행이동사`,`주력사 흉함`,``,`경미송사-고시득길사`,`외교막힘`,`먼데서 막 도착하여 숲에서 뱀을 만난 격`,`외사 손실급속사`,`길가는 고위인에게 청원함이 좋음`,`내부가정막힘`,`간음무도사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음인암손사`,`전도**빈궁**관재`,`전화위복`,`빈궁`,``,``,`전도**빈궁**병재`,``,`공공사사구설사`,`빈궁`,`고위안좌다중이익포고사-부족`,``,`전화위복`,`전도**빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**관재`,``,`전도**빈궁`,`재액해소`,``,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내부가정막힘`,`음인(연인) 병질-괴이구설사`,`매사 위역사**외사 급속송쟁파재실종상해우려`,`문서유실사`,`내사 기로출행원행사`,``,`내사 파재극처사`,`남모르는 능욕박해사`,`외사 동요분산사`,`모사 미수사`,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,`매사 위역사**내사동기화합사`,`관직자 이익-평민 불리`,`외사 기로출행이동사`,`주력사 흉함**도적은 못잡고 놀래기만 함`,``,`문서暗動사`,`외교막힘`,`송구경황-통저인이 나타나 두려움-이동사`,`외사 손실급속사`,`길가는 고위인에게 청원함이 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`빈궁`,``,`재액해소**전화위복`,``,`재액해소`,`빈궁`,``,``,`공공사사구설사`,`빈궁`,`손괴재물사`,`빈궁**병재`,`전화위복`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,``,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`승진영전사`,`외사 손실급속사`,`도적악의-공격말 것`,`내부가정막힘`,`음인暗損사`,`매사 위역사**외사 급속송쟁파재실종상해우려`,`자강불식-가나 있으나 좋음`,`내사 기로출행원행사`,`내사 파재극처사`,`일체근심해소`,`외사 동요분산사`,`禍소福지사`,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,`매사위역사**내사동기화합사`,`면굴난해사-옥송관련`,`외사 기로출행이동사`,`불측근심사`,``,`연정사-상대진심-정위난측사`,`손괴재물사-이동사`,`외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`전도**빈궁`,``,`빈궁`,``,`전화위복`,``,`재액해소`,``,`재액해소`,`빈궁`,`문서상신답지체-구설사`,`빈궁`,`사사성취사-부족`,``,`전화위복`,`빈궁`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁`,``,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`모사 미수사-이동사`,`외교막힘`,`싫은 일의 반복사-문 관련`,`외사 손실급속사`,`간음사이익-정대적은 재앙됨`,`내부가정막힘`,`문서유실사`,`매사 위역사**외사 급속송쟁파재실종상해우려`,`봉흉화길사`,`내사 기로출행원행사`,`내사 파재극처사`,`일체근심해소`,`외사 동요분산사`,`송구경황-통정인이 나타나 두려움`,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`,`매사영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,`관직자권리수여-평민 불리`,`내사동기화합사`,`외사 기로출행이동사`,`앙화소멸사`,``,`연정사-상대진심-정위난측사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`관재`,``,`빈궁**병재`,``,`관재`,`재액해소`,`빈궁`,`전화위복`,`빈궁`,``,`관재`,`재액해소`,``,`재물암동사`,`빈궁`,`도난사`,`빈궁`,`재액해소**전화위복`,``,`음4,5월화재주의`,`전도**병재`,`음4,5월화재주의`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`연정사-상대진심-정위난측사`,`앙화소멸사`,`모사 미수사-이동사`,`외교막힘`,`싫은 일의 반복사-문 관련`,`외사 손실급속사`,`간음사이익-정대적은 재앙됨`,`내부가정막힘`,`문서유실사`,`매사위역사**외사 급속송쟁파재실종상해우려`,`봉흉화길사`,`내사 기로출행원행사`,`내사 파재극처사`,`일체근심해소`,`외사 동요분산사`,`송구경황-통정인이 나타나 두려움`,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,`관직자 권리수여-평민 불리`,`내사동기화합사`,`외사 기로출행이동사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁`,`동요불안분산사`,`전도`,`동요불안분산사**재액해소`,`빈궁**관재**병재`,`동요불안분산사**재액해소`,`관재`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사`,`관재`,`동요불안분산사`,`전도`,`재물암동사**동요불안분산사`,`빈궁`,`도난사**동요불안분산사`,`빈궁`,`동요불안분산사**전화위복`,`전도`,`음4,5월화재주의**동요분산사`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,``,`외사 기로출행이동사`,`연정사-상대진심-정위난측사`,`앙화소멸사`,`모사 미수사-이동사`,`외교막힘`,`싫은 일의 반복사-문 관련`,`외사 손실급속사`,`간음사이익-정대적은 재앙됨`,`내부가정막힘`,`문서유실사`,`매사 위역사**외사 급속송쟁파재실종상해우려`,`봉흉화길사`,`내사 기로출행원행사`,`내사 파재극처사`,`일체근심해소`,`외사 동요분산사`,`송구경황-통정인이 나타나 두려움`,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,`관지가 권리수여-평민불리`,`내사동기화합사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`빈궁**병재`,`음4,5월화재주의`,`빈궁`,``,``,`빈궁**관재**병재`,``,``,``,`빈궁`,``,`전화위복`,`전도**빈궁**관재`,``,`전도`,``,``,`재물암동사`,`빈궁`,`도난사`,`빈궁`,`전화위복`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관직자 권리수여-평민불리 `,`내사동가화합사`,``,`외사 기로출행이동사`,`앙화소멸사`,`연정사-상대진심-정위난측사`,`모사 미수사-이동사`,`외교막힘`,`싫은 일의 반복사-문 관련`,`외사 손실급속사`,`간음사이익-정대적은 재앙됨`,`내부가정막힘`,`문서유실사`,`매사 위역사**외사 급속송쟁파재실종상해우려`,`봉흉화길사`,`내사 기로출행원행사`,`내사 파재극처사`,`일체근심해소`,`외사 동요분산사`,`송구경황-통정인이 나타나 두려움`,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소**전화위복`,`관재`,`음4,5월화재주의`,`전도**빈궁**병재`,`음4,5월화재주의`,`전도**빈궁`,`재액해소`,``,``,`빈궁`,``,``,`재액해소`,`빈궁`,`빈궁`,`전화위복`,``,``,`재액해소`,``,`재물암동사`,`빈궁`,`도난사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,``,`내사동기화합사`,``,`외사 기로출행이동사`,`앙화소멸사`,`연정사-상대진심-정위난측사`,`모사 미수사-이동사`,`외교막힘`,`싫은 일의 반복사-문 관련`,`외사 손실급속사`,`간음사이익-정대적은 재앙됨`,`내부가정막힘`,`문서유실사`,`매사 위역사**외사 급속송쟁파재실종상해우려`,`봉흉화길사`,`내사 기로출행원행사`,`내사 파재극처사`,`일체근심해소`,`외사 동요분산사`,`송구경황-통정인이 나타나 두려움`,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`도난사`,`빈궁`,`전화위복`,`전도`,`음4,5월화재주의`,`빈궁**병재`,`음4,5월화재주의`,`빈궁`,``,`전도`,`재액해소`,`빈궁**병재`,`재액해소`,``,`전도**빈궁`,``,`전화위복`,`빈궁`,``,``,`전도`,``,`재물암동사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사 영속성없음**내사 강제급속사`,`종교진출하면자립함`,`매사 영속성없음**내사 부부동요개변사`,`종교진출하면자립함`,`매사 위역사**내사동기화합사`,`면굴난해사-옥송관련`,`외사 기로출행이동사`,`주력사 흉함`,``,`경미송사-고시득길사`,`외교막힘`,`먼데서 막 도착하여 숲에서 뱀을 만난격-이동사`,`길가는 고위인에게 청원함이 좋음`,`외사 손실급속사`,`내부가정막힘`,`간음무도사`,`매사 위역사**남녀직원공모사유-은닉사`,`외사 급속송쟁파재실종상해우려`,`내사 기로출행원행사`,``,`내사 파재극처사`,`酒食수벌사`,`외사 동요분산사`,`손괴재물사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`공공사사구설사`,`빈궁`,`고위안좌다중이익포고사-부족`,``,`전화위복`,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁`,``,``,``,``,`음인암손사`,`빈궁`,`전화위복`,`전도**빈궁`,``,`전도`,``,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외사 동요분산사`,`손괴재물사`,`매사 영속성없음**내사 강제급속사`,`종교진출하면 자립함`,`매사 허성불성-세속허사자**내사부부동요개변사`,`흠1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 위역사**내사동기화합사`,`면굴난해사-옥송관련`,`외사 기로출행이동사`,`주력사 흉함`,``,`경미송사-고시득길사`,`외교막힘`,`먼데서 막도착하여 숲에서 뱀을 만난격-이동사`,`길가는 고위인에게 청원함이 좋음`,`외사 손실급속사`,`내부가정막힘`,`간음무도사`,`매사 위역사**남녀직원공모사有-은닉사`,`외사 급속송쟁파재실종상해우려`,`내사 기로출행원행사`,``,`내사 파재극처사`,`酒食수벌사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**병재`,`공공사사구설사`,`빈궁`,`고위안좌다중이익폭사-부족`,``,`재액해소**전화위복`,`빈궁`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도**빈궁`,`재액해소`,`빈궁**관재`,``,``,``,``,`음인암손사**재액해소`,`빈궁`,`폐한인**전화위복`,`빈궁`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`내사 파재극처사`,`酒食수벌사`,`외사 동요분산사`,`손괴재물사`,`매사 허상불성-세속허사자**내사 강제급속사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자**내사부부동요개변사`,`음1,210,11월 방문자는 종교진출하면 성공함`,`매사 위역사**내사동기화합사`,`면굴난해사-옥송관련`,`외사 기로출행이동사`,`주력사 흉함`,``,`경미송사-고시득길사`,`외교막힘`,`먼데서 막도착하여 숲에서 뱀을 만난격-이동사`,`길가는 고위인에게 청원함이 좋음`,`외사 손실급속사`,`내부가정막힘`,`간음무도사`,`매사 위역사**남녀직원공모사有-은닉사`,`외사 급속송쟁파재실종상해우려`,`내사 기로출행원행사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁**병재`,``,`공공사사구설사`,`전도**빈궁`,`고위안좌다중이익포고사-부족`,`전도`,`전화위복`,`빈궁`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁`,``,``,``,``,`음인암손사`,`빈궁`,`빈궁`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 운세가 막혀 있네 !
  조용히 때를 기다리면 
 가정도 편안해지고 
⊖부인 문제도 해결되겠어!
 함부로 행동하면 놀래고 
 두려운 일이 벌어져!
⊕신변상가부정 침범
⊖신변음사부정이 침범했네?
`,`■ 종업원이나 연인관계로       돈깨나 부서지겠네? 
   진퇴양난 허사부실이야!
   가정을 잘 돌봐야 경사를      맞아 들이지…
   눈가릴 운이 있겠어 !
`,`■ 변동수가 있네? 
  그런데 여건이 부족하겠어!
  삼각관계가 있는데다
⊕가택상가부정ㆍ자살귀, 
⊖가택음사부정ㆍ가택귀까지 
 발동했어! 
⊕男:남의 여자를 뺏었네?
`,`■대단히 존귀한 분이 오셨네?    부귀공명이현달되겠네!     그런데 권리를 잃겠어!
⊕자살귀, 
⊖전흉사귀를 해소시켜 주면    좋겠어!

⊕신변고모신사부정 침범
⊖신변신화선영부정침범했네?
`,`■ 구개신취라… 
  확 뜯어고치는건 좋고 
  옛것을 버려야 하는 운세     인데, 
女:자칫부부이별까지하겠어!
男：직위가 해탈되겠네?

⊖신변상가부정이 침범했네?
`,`■ 큰 돈이 들어오겠네?
  돈이 들어오거든 입을 꽉     다물어 버려야 해! 
  적선적덕을 하고 !
  부정이 들었으니 기도ㆍ     치성을 드리면 더욱 좋겠고!

⊕⊖가택신화선영부정이야!
`,`■색정불화로가정이먹구      름이네? 
  움직일 수도 없겠고!
男：직위손상, 
女：별리ㆍ고독이야! 
⊕수귀, 
⊖전흉사귀가 발동했어! 
 禍는 면해야지!
`,`■父子지간 불화가 심해 이별    수가 보이네? 代가 끊길 걱    정이야! 계획을 바꿔야 겠어!
⊕음귀, 
⊖원한귀 발동이니 
 산소(3면)를 옮기거나 
 화장을 해야 좋겠어!
`,`■ 여러건을 하나로 묶지만     가세는 기울고 가정은 
  불화도 심하고…
⊕女: 근신해야 겠어 흉조야!
⊖男：남의 여자를 뺏었네?
⊕음사부정이 들었어!

⊕신변음사부정이 침범했어 !
`,`■ 지금 놀라고 있겠는데 집안     에 죄인을 숨겼겠어 !
男：직위해탈, 
女：남편과 별리고독이 되게       생겼어!
⊕수귀, 
⊖가택귀 해소가 급선무야!
⊕신변저주원심부정 침범
⊖신변여고음강부정 침범했네?
`,`■색정사가 너무 심하네?
 한두번이 아니야!

⊕가택음사부정ㆍ수귀, 
⊖가택귀 발동으로 내 마음ㆍ몸을 내 마음대로 못하는    격이야!
`,`■천우신조를 얻겠네 ? 

⊕⊖가택여고음강부정만
   해소시키면 좋겠어 !`];
  const YOGYEOL1 = [`■ 앉았다 섰다 일정하지 않고 잃거나 도둑맞는 것을 주의하라. 움직이면 범을 만나고 입을 다물어야 돈이 크다.
  `,`■ 낮 천장은 음이 많고 과전에 원숭이가 둘이고 또 범이 네 마리가 모였으니 내 녹은 공중에 떠 있겠구나.
  `,`■ 파쇄가 내게 탔고 삼전도 이익이 없게 생겼다. 다 버리고 집으로 돌아오니 끝내 고통을 받는다.
  `,`■ 녹이 내 몸을 살려주고 교섭사도 서로 기쁘다. 밤정단은 가히 살수 있어도 낮정단은 순을 넘겨 다시 보라.
  `,`■ 내가 이미 해를 입었는데 또 몸이 상한다.
  손해 나는 일이 계속되니 힘만 들고 경비만 난다. 
  `,`■ 처음은 돈 중간에는 녹이 있다. 그러나 욕심 부려 따르지 말라. 입 다무는 신이 끝에 있으니 돈을 보거든 입을 다물어라.
  `,`■ 관살이 모두 비었으니 힘들게 관살을 제압할 것 없다. 낮정단은 현무와 육합이 있으니 음란한 일이 생기고 입 다물어야 한다.
  `,`■ 불화가 심하거나 소송이 생기고 낮정단은 귀인의 재물이 있겠으나 숨은 귀신이 범 타고 오니 대는 끊어지고 어른에게 재앙이 오리라.
  `,`■ 칼 끝에 꿀을 묻혔으니 빨아보아야 이익이 없다. 다만 정신을 바짝 차려야 흉한 재앙이 스스로 풀린다.
  `,`■ 화살을 맞는다해도 쑥대화살이다. 중간에서 뚝 떨어지고 마니 놀라기는 해도 다치지는 않겠다.
  `,`■ 음란하고 속이는 일이 계속된다. 나는 혼자 입 다물어도 낮정단은 숨은 귀신이 범타고 오며 두 귀인은 다 힘이 되지 않는다.
  `,`■ 나아가고 물러서는 것이 先근심 後기쁨이다. 
  재판은 하지 말고 병은 비장이다. 여름 점이면 재수가 좋다.`]
  const SIBO1 = [`■ ⊕여자종업원에 의한
  도난ㆍ유실사가 있고
  ⊖부인의 간음사가 있다.
  동정ㆍ진퇴가 비상한  일을 만나게 되나 조용히 현상을 유지하고 때를 기다려야 유리하다.
  `,`■ 가정에 타인의 물건을
  받는 일이 있다.
  숨겨야 할 일이 있고, 일의 조격이 있어 막힌다. 허사부실이 있어 내 직위나 재산을 지킬 수 없는 고민이 있다. 가정에서 일어나는 일이며 보통 여자문제ㆍ음사사인 경우에 해당된다.
  `,`■ 가정에 변동수가 있다.
  생업이나 가택을 옮긴다.
  권리를 잃는다.
  부부의 색정관계가 발생하고 삼각관계가 된다. 
  ⊕남자가 타인의 부인을 유혹하여 거래한다. 보통 상대방에게 스스로 의존하여 상대를 이롭게 한다. 여자의 운세는 크고 남자의 운세는 작다.
  `,`■ 길행이 넘치는 운세이다.
  부귀번영한다.
  모든 계획이 달성된다.
  단, 가택의 권리가 넘어간다. 관직자인 경우 공관을 비어주는 경우이다.
  공명이 현달하고 外 財가 들어온다. 승용차의 전복은 各月 喪車신살로 변별하라.
  正月 임신사가 있다.
  `,`■ 옛것을 버리고 새것으로
  개신시키는 운세이다.
  다인 다사건이다.
  여러건 多人의 회맹에 관련된다. 결국 남자는 박관되고 여자는 극락되게 된다. 
  남의 추천과 천거를 받아도 손해가 난다.
  `,`■ 옛일, 지난 일을 끝맺는
  운세이다.
  男:권리를 잃는다.
  어떤 일도 추구하지 말아야 한다.
  ⊕ 小兒, ⊖ 교역ㆍ여자 문제가 신속하게 전개되며 기로에 있다.
  가정의 신화선영 부정을 해소시켜야 된다.
  `,`■ 저주를 받고 있다.
  보통 음사사인 경우이다.
  ⊖가정 상가부정이 들었어!
  가정에 동요ㆍ불안ㆍ다툼이 있고 재해는 밖에서 오고 한가지 일이 아니다.
  이동의 징조가 있으나 목적 달성이 안된다.
  소나기 피하듯 하면 재해를 당하지는 않는다.
  `,`■ 上下ㆍ 상란한다.
  上은 下를 업신여기고
  下는 上을 범한다.
  장상은 죽고 상속자가 없어 고독하게 되는 정황이다. 
  금전은 많이 소득하나 다른 것은 곤란하다.
  이런 경우 대개 산소탈로 본다.
  正月 임신사가 있다.
  `,`■ 불화로 가정이 쇠하는
  운세이다.
  매사 혼미한 상태이다.
  남녀 각각 불순한 애정행각이 있다.
  ⊕凶이 吉로 변화한다. 회견ㆍ선 보는 일은 좋다.
  가정에 ⊕소아, ⊖금전의 고심이 있고, 부부갈등
  으로 개변하려 한다.
  남자가 타인 부인을 유혹하여 색정사를 행한다
  `,`■ 가정에 임신사가 있다.
  남자는 형죄의 불안이 있고
  여자는 사통을 범하고 있다.
  모든 일이 허사ㆍ부실ㆍ공론ㆍ빈말 뿐이므로 쫓아가서는 안된다.
  자꾸 연결되어 스스로 추량할 수 없다.
  결국 모두 모손되나 재화는 경미하게 된다.
  `,`■ 음사사가 있다.
  삼각관계이다.
  권리를 잃는다.
  진지하기 어렵고 길도 험한 형국이다.
  귀인의 도움도 없다. 
  허손을 방비하는 것이 방법이다. 암운이다.
  공사적으로는 불리하고 사사롭게 하는 것이 이롭다. 시험사는 吉하다.
  `,`■ 가정에 부정이 들었다.
  正月 임신사가 있다.
  존숭ㆍ은상ㆍ재물 중 
  어느 것 하나의 기쁨을 얻게 된다.
  계속 전진하여 吉하게 된다. 
  결혼사도 吉로 본다.`]
  const SIBO2_1 = [`■자손ㆍ박관가택내사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  부동신음의 상이고
  질병이 침입한다.
  `,`■신변대립ㆍ반목외사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단을 잡는다.
  `,`■官ㆍ夫 가정개변내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■후원공직ㆍ상관신변사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사불리하다.
  `,`■후원개신개혁내사이다.
  내방조짐 상의 : 
  정사 利 암사불리하다.
  男직위상실하고
  女부부이별한다. 
  `,`■財ㆍ女신변여부사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 官ㆍ夫 가정개변사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■형제ㆍ손모신변외사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 財ㆍ女여부신변사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 병재·관재 예방이 필요하다
  `,`■ 官ㆍ夫 가정개변사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■ 신변동요외사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  여건이 부족 불비하다
  `,`■ 財ㆍ女여부내사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/046/row-1-column-1.png`,
    `/static/images/sagwa/046/row-1-column-2.png`,
    `/static/images/sagwa/046/row-1-column-3.png`,
    `/static/images/sagwa/046/row-1-column-4.png`,
    `/static/images/sagwa/046/row-1-column-5.png`,
    `/static/images/sagwa/046/row-1-column-6.png`,
    `/static/images/sagwa/046/row-1-column-7.png`,
    `/static/images/sagwa/046/row-1-column-8.png`,
    `/static/images/sagwa/046/row-1-column-9.png`,
    `/static/images/sagwa/046/row-1-column-10.png`,
    `/static/images/sagwa/046/row-1-column-11.png`,
    `/static/images/sagwa/046/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `   이 과는 12신이 본궁에 엎드려 복음과(伏吟課)이다. 초전은 설기로 되고 일간상신과 중전에 정신(丁神)이 승하여 동정진퇴(動靜進退)가 모두 비상하다. 이 과는 조용히 현상 유지하고 때를 기다리는 것이 좋다.
    백법에 ｢전후인종격(前後引從格)은 승천(昇遷)의 경사가 있다｣고 하였다.
    비전에는 ｢귀신이 신(申)에 승하여 역행하면 축(丑)에 백호가 승하므로써 귀인의 노여움을 산다｣고 하였다. 축(丑)은 천을귀신(天乙貴神)의 본가로서 백호를 보면 좋지 않으며 축(丑)에 임하는 것도 같다고 본다. 초전에 천후(天后)가 앉으면 사건이 부인으로 인하여 일어난다.
    일간상신은 미(未)이고 일지상신은 유(酉)이다. 신(申)이 천을귀신을 띠면 현임관공직(現任官公職)에 있는 사람은 공명이 달성되며, 영전을 바라면 목적이 달성된다. 보통 일반 사람도 계획하는 일이 이루어진다.
    일귀는 순행하며 3전은 현(玄)․백(白)․사(蛇)이고, 야귀는 역행하여 3전이 후(后)․사(蛇)․백(白)이다. 3전 중에 백호․사신(蛇神)이 승하면 두려운 사건이 있으며 진퇴가 뜻대로 되지 않는다.`
    ,
    // 2
    `   이 과는 4과 중에 상하의 극이 없고 요극도 없으므로 묘성과(昴星課)를 취한다. 음일(陰日)이면 천반 유(酉)의 하신인 술(戌)을 초전으로 하고, 중․말전은 일간일지상신으로 한다. 
    일간 상산이 간록(干祿)이지만 천공이 승하면 허사 부실한 징조가 있으니 녹(祿)을 지킬 수가 없다. 술(戌)이 해(亥)에 임하면 괴도천문(魁度天門)을 건너 정체․경공 등의 일이 있다. 
    일지상신에 태상이 승하면 가정에 경사가 일어난다. 초전의 술(戌)은 남자 사용인이고 태음이 승하면 여자 사용인이다. 일지(日支) 유(酉)가 육해(六害)로 되어 사용인이 집을 해친다.
    백법에 ｢왕록(旺祿)이 신(身)에 임하면 망동은 불길하며, 조용히 때를 기다려 움직여야 한다｣라고 하였다. 3전에 술(戌)과 오(午)가 있어서 허일대용(虛一待用)이라 부르는데 유(酉)를 기다려 길흉의 보응을 안다.
    비전에는 ｢신(申)이 지상(支上)에 임하면 타인이 물건을 보내온다｣고 하였다. 묘성과(昴星課)로서 괴도천문을 건너는 것은 복닉(伏匿) 또는 조격(阻隔)이 있다. 
    일귀는 순행하며 3전은 음(陰)․공(空)․상(常)이고, 야귀는 역행하여 3전이 음(陰)․주(朱)․귀(貴)이다. 초전 술(戌)에 태음이 앉으면 음사(陰私)나 애매한 일이 많다. 광명정대하면 재환(災患)을 면한다.`
    ,
    // 3
    `   이 과는 상하에 극적(剋賊)이 없고 제2과 묘(卯)에서 일간을 요극(遙剋)하므로 묘사(卯巳)가 초전으로 되어 요극호시과(遙剋蒿矢課)이다. 3전에 합(合)․후(后)가 앉는 것은 교동격(狡童格)이며, 남녀의 색정관계가 일어난다. 여기에 불비격은 2녀가 1남을 다투는 3각 관계이다.
    일간상신 사화(巳火)는 일간(日干)을 생하고, 초․중전은 공망되며, 말전의 해(亥)는 재효로서 일간기궁(日干寄宮)․일지상신에 가해져 상대방에게 가서 그에게 이익을 주며, 서양자가 되어 혼가(婚家)에 힘을 보탠다. 상성(相星)은 화토상생(火土相生)으로 길하나 초전의 공망으로 결혼 문제는 중도에 장애가 생긴다. 이연(離緣)은 합의하고 성립된다. 이 과는 모두 복이 옅고 재화도 경하다. 과전이 전부 음지이면 일반 사람에게 는 유익하나 상류 사회의 사람에게는 불리하며, 부부는 서로 다른 마음이 생기기 쉽다.
    백법에 ｢육음격(六陰格)은 혼미가 연속하여 일어난다｣고 하였다. 청룡에 생기가 가해지면 길조가 있으나 늦게 발생한다.
    비전에는 ｢3전이 묘축해(卯丑亥)이면 인묘(寅卯)가 공망으로 되고, 다음 순(旬)은 자축(子丑)이, 그 다음은 술해(戌亥)가 공망으로 된다｣고 하였다. 이와 같은 것은 3순 공망이라 하여 이익을 버리고 손해를 부르는 일이 있다.
    일간기궁(日干寄宮)은 일지상신에 가해지고 일지에 탈기되면 역허(歷虛)라 이름하여 그에게 탈모(脫毛)하는 일이 있다. 일귀는 순행하여 3전이 합(合)․사(蛇)․후(后)이며, 야귀는 역행하여 3전이 청(靑)․백(白)․현(玄)이다. 양(兩)의 불비격(不備格)인 동시에 육음격이므로 음인  여자는 왕성하고 양인 남자는 쇠퇴하는 형상이다.`
    ,
    // 4
    `   이 과는 4과 중의 제3과에서 1상이 1하를 극하여 원수과로 된다. 일간의 왕록(旺祿)은 일지상신에 임하여 일간일지가 교차지합(交車支合)하고 간지가 상생한다. 또 3전이 체생(遞生)하므로 신(身)을 생하여 길행한 상태이다. 다만 오직 중․말전이 공망을 가석하게 여겨 길이 경하다. 고개승헌객(高蓋乘軒格)으로서 부귀 번영의 상징이며, 모든 계획은 이루어진다.
    백법에 ｢일지상신이 왕록에 해당하여 권섭부정(權攝不正)의 격이다｣라고 하였다. 중․말전의 공망은 전(傳)으로 가지 않고 초전의 일신(一神)으로만 길흉을 단정한다. 길장이 승할 때는 공망을 벗어나 길사가 성취된다.
    비전에는 ｢일간상신에 진(辰)이 가해져서 교차지합(交車支合)이 되면 간사(奸邪)한 일, 또는 교섭사가 두세 건 있다｣고 하였다.
    초전에서 말전을 극하면 사물의 성취가 희소하지만, 본과(本課)와 같이 초전에서 말전을 극하는 것은 사물이 성립된다. 3전이 오묘자(午卯子)이면 헌개격(軒蓋格)이다. 공명․구직․입시에서 모두 길조를 얻는다.
    일귀는 순행하여 3전이 공(空)․합(合)․귀(貴)이고, 야귀는 3전이 음(陰)․백(白)․구(勾)이다. 일간상신에 진(辰)이 가해지면 처음에는 다소의 곤란을 면치 못한다.
    고개승헌(高蓋乘軒)에 대해 해설한다. 자(子)는 화개(華蓋)로서 12지(十二支)의 처음이다. 묘(卯)는 천차(天車)라 하며, 오(午)는 천마(天馬)로 하므로 오묘자(午卯子)의 3전은 승헌격(乘軒格)이라 부르며, 공명(功名)이 현달하고 크게 득재한다. 거마(車馬)가 재를 이루어 밖으로부터 오는 재를 얻는다. 묘(卯)에 상차(喪車)가 승할 때는 탈것이 전복되거나 고장날 우려가 있고, 헌개(軒蓋)가 변하여 3교격(三交格)으로 된다.`
    ,
    // 5
    `   이 과는 제1과․제3과가 상에서 하를 극하여 동일한 음의 극이니 섭해과로 되며, 지반맹신(地盤孟神)의 극이 없으므로 중신의 극인 사유(巳酉)를 초전으로 한다. 3합금국(三合金局)하여 종혁격(從革格)이다.
    일간상신은 내몸이 극살(剋殺)을 만나도 3전 금국에서 제어하므로 해가 없다. 일지는 택이다. 택상의 화(火)는 금(金)의 택을 극한다. 즉 인택이 모두 손상을 받는다. 3전이 전탈(全脫)되어 도로(徒勞)․소모가 있으나 일간상신이 공망에 승하니 귀해(鬼害)는 힘이 없다. 모두 3합되는 것은 한 사람의 일이 아니고 대중의 사건이다. 종혁은 옛 것을 버리고 새로 시작하는 것이 좋다. 교역이나 교섭사는 사후에 불화를 초래한다. 만일 앞에서 불화하면 후에는 오히려 화합하는 과이다.
    백법에 ｢일간일지상신에서 함께 극되는 것은 서로가 손상을 받는다｣고 하였다. 백호가 간귀(干鬼)에 임하면 흉이 속하다. 그러나 공망하니 해는 경하다. 
    비전에는 ｢귀살이 공망을 만나거나 명상(命上)에서 귀방(鬼方)에 앉으며 또는 년명상신이 귀살을 생하는 방향으로 앉으며 눈 앞에 재화가 있어도 결국은 재앙이 없어진다. 3전이 체생되면 남의 천거를 받는다. 
    일귀는 순행하며 3전은 청(靑)․사(蛇)․현(玄)이고, 야귀는 3전이 현(玄)․청(靑)․사(蛇)이다.`
    ,
    // 6
    `   이 과는 4과 중 3과에 상하의 극이 있다. 제4과가 해진(亥辰)으로 하에서 상을 극하니 초전으로 하며, 중심과로서 맥월격(驀越格)이다.
    일간상신이 인목(寅木)에서 극되나 공망하여 해는 경하다. 초전의 해(亥)는 재로 되고, 중전은 일간의 녹신이 된다. 말전은 묘신(墓神)에 해당되는데, 일지에 묘신(墓神)이 투입되는 것을 중지하면 우환이 없어지며, 혹은 지난 일을 끝맺는 과이다.
    백법에 ｢일간상신 인(寅)의 공망에 천공(天空)이 승하면 공상공(空上空)을 만나므로 모든 일을 추구하지 말아야 한다｣고 하였다. 
    비전에는 ｢역마가 초전으로 되면 만사가 신속한 징조이다｣라고 하였다. 또 ｢사해(巳亥)가 역마로 되는 것은 기로(岐路)에 있다｣고 한다. 
    일귀는 역행하며 3전은 사(蛇)․공(空)․후(后)이며, 야귀는 순행하여 3전이 합(合)․음(陰)․청(靑)이다.`
    ,
    // 7
    `   이 과는 천지반이 대궁(對宮)에 있으니 반음과이다, 하에서 상을 적하는 묘유(卯酉)를 사용하여 일지중신(日支仲神)이며, 3전 묘유묘(卯酉卯)의 충신(冲神)은 용전격(龍戰格)으로 된다. 그리고 과전 묘(卯)의 귀살은 공망으로 되고 또 제지에 앉으니 두려울 것이 없다. 현무․육합이 가택의 음양신에 앉고 일간상신에 천후를 보면 음사(陰私)한 일로 왕래가 있다.
    묘유(卯酉)는 문호의 신이다. 3전이 상극, 충전하니 가정에 동요․불안․다툼이 있고, 화는 밖에서 오며 한 가지  일이 아니다. 그러나 다행히 공망되므로 흉해 동요는 있으나 실해는 없다.
    백법에 ｢반음은 거래격(去來格)이라 한다｣고 하였다. 공망하니 이동의 징조가 있어도 이동의 목적을 달성하기 어렵다. 
    비전에는 ｢묘(卯)가 유(酉)에 가해져서 관귀(官鬼)로 되고 현무가 앉아서 초전을 이루는 것은 내의(來意)가 모두 실탈․도난의 건이며, 현무․백호가 공망되면 난을 피하여 화를 받지 않는다｣고 하였다. 3전의 공망은 한 사건도 성취되지 않는다. 
    일귀는 역행하며 3전은 현(玄)․합(合)․현(玄)이고 야귀는 순행하여 3전이 백(白)․사(蛇)․백(白)이다.`
    ,
    // 8
    `   이 과는 4과 모두 4하가 4상을 적(賊)하므로 섭해과(涉害課)로 되며, 지반 4맹상신을 취하여 초전으로 한다. 제4과에 미인(未寅)의 상극이 있다.
    조상법(照常法)에 따라 3전이 미자사(未子巳)로 되며, 모두가 하에서 상을 극하여 절사도액격(絶嗣度厄格)에 해당된다. 존상의 일을 정단하면 재앙이 있으며 섭해과는 모두 간난, 정체가 계속된다. 예컨대 풍상고초를 맛본 뒤에 단 것을 만나는 과이다. 4과를 떠나지 않고 3전을 이루는 것은 회환격(廻環格)이다. 덕신(德神)이 택상에 앉고 귀신이 신(身)에 임하면 흉을 해소하니 후에 경사가 있다.
    백법에 ｢둔귀․백호와 합병하는 것은 재앙이 적지 않다｣고 하였고, 비전에는 ｢4하가 상을 적하니 절사(絶嗣)라 부른다. 선인은 망하고 고독하게 되며 상속자가 없다｣고 하였다. 초전이 공망에 앉는 것은 고과격(孤寡格)으로서 사물의 동기가 유명무실하다.
    3전체극(三傳遞剋)은 구재에서 많이 소득하지만, 기타의 사건에서는 곤란한 징조가 있다.
    일귀는 역행하며 3전은 청(靑)․귀(貴)․백(白)이고, 야귀는 순행하여 3전이 후(后)․구(勾)․현(玄)이다. 정신(丁神)이 공망에 앉으니 나를 돕는 것도 힘이 되지 않는다.`
    ,
    // 9
    `   이 과에서는 제1과가 하에서 상을 적하니 해미(亥未)를 사용하여 초전으로 하는 중심곡직과(重審曲直課)이다. 일간상신 해(亥)의 재신이 신(身)에 임한다. 3전이 곡직목국(曲直木局)하여 일간의 중귀로 되어도 중․말전이 공망하니 귀살을 두려워할 것이 못 되며 흉재는 해소된다. 
    곡직격은 모든 일에서 번잡하며, 3전에 합(合)․후(后)가 앉는 것은 교동격(狡童格)으로 남자가 여자를 유혹한다.
    백법에 ｢후(后)․합(合)이 앉으면 점혼(占婚)하면 자유 결혼의 징조이다｣라고 하였다. 과전이 모두 6음(六陰)뿐이면 사물이 혼미하다. 
    비전에는 ｢곡직격은 처음에 곧은 것을 굽히면 후에 착오를 초래하며, 해(亥)의 천문에 묘(卯)의 문호의 신이 승하면 회견이나 선보기 모두에서 화합한다｣고 하였다.
    간지의 묘신(墓神)이 일지상에 엎드리는 것은 가운이 쇠퇴할 징조이다. 일귀는 사(蛇)가 용으로 화하니 흉이 변화하여 길을 초래한다. 야귀는 교동격으로서 남녀가 불순한 사랑을 나눈다. 4과 중 한 자리 아래에서 상을 극하므로 중심과는 별명을 시입격(始入格)이라 한다.`
    ,
    // 10
    `   이 과는 4과 중에 상하의 극이 없고 제3과․제4과의 두 과에 사극(斜剋)이 있다. 천신에서 극하는 묘자(卯子)를 사용하여 초전으로 하여 요극호시삼교과(遙剋蒿矢三交課)이다.
    초전의 묘(卯)는 공망하고 호시(蒿矢)는 이미 공망하여 극해가 없다. 중전 오(午)의 건록도 역시 공망의 지에 앉았고, 유금(酉金)이 있으나 화(火)의 제지(制支)에 있어서 길흉이 극히 경미하다. 교차육해가 있어서 교섭사는 의심이 생긴다. 4중일(四仲日)에 3전(三傳) 4중신(四仲神)은 3교격(三交格)으로 다툼의 징조를 이룬다.
    백법에 ｢공망 중에 천공을 만나는 것은 허사(虛詐)․부실(不實)․공론(空論)․빈말(空話)이므로 쫓지 말라｣고 하였다.  비전에는 ｢일지상에 태신이 승하면 가정에 임신부가 있다. 초․중전이 공망하며 말전의 유(酉)는 실(實)이지만 설기로 되니 모든 일이 종국에는 모손된다｣고 하였다. 일간상신 술(戌)이 천괴(天魁)가 되어 다소의 어려움이 있다. 일귀는 역행하며 3전은 현(玄)․공(空)․합(合)이고, 야귀는 3전이 청(靑)․주(朱)․후(后)이다, 점시(占時)가 4중신인 경우에는 참다운 3교격이 된다.
    3교격에 대한 해설은 이미 논하였으나 여기서 거듭 설명한다. ｢무릇 자오묘유(子午卯酉)는 4패(敗)의 신이다. 4중일(仲日)에 점과하여 지상에 4중신이 있으니 1교(一交)로 되고, 중신이 초전으로 되고 3전이 모두 4중신이면 2교로 되며, 과전 중에 태음․육합이 승하는 것이 3교다. 3자가 교차되어 만나므로 이와같이 명명한 것이다. 모든 일에서 구련(勾連)되어 풍운을 측량하기 어려운 과다. 집안에는 음사한 일이 있고, 도피․모사에서는 명백하지 못하며, 재를 구하나 득이 없고 관사(官事)를 초래하기 쉽다. 만일 흉장이 승한다면 병환은 가장 흉하고, 흉신을 만나면 남자는 형죄를 범할 두려움이 있고, 여자는 사통(私通)을 범한다. 연루되어 암매한 일이 있으며, 진퇴는 모두 곤란하다.`
    ,
    // 11
    `   이 과는 제4과에서 1상이 1하를 극하여 중심불비격으로 된다. 3전은 일자(一字)를 두고 순간전출호격(順間傳出戶格)으로 된다. 묘(卯)는 가정의 신, 사(巳)는 지호(地戶)라 부른다. 축(丑)에서 사(巳)로 전하고 집을 나가는 형상이니 이런 이름을 얻었다.
    양기는 위로 올라가므로 상진하는 형상을 이룬다. 초전이 순미(旬尾)의 축(丑)이니 폐구격(閉口格)이다, 과전이 순음으로 천후․육합․현무가 승하여 음사한 사건이 거듭 일어난다. 불비격은 남녀 3각 관계의 형상이다. 간전격(間傳格)은 급히 전진하기 어려우며 길이 험하다. 5음(五陰)이 서로 이어지면 공사(公事)에 이롭지 못하고 사사에는 이롭다.
    백법에 ｢일야 두 귀신이 지반에서 극되니 귀인의 도움이 없다｣고 하였다. 일간에 유(酉)가 앉아서 탈기되고 천후(天后)의 수신이 승하여 유(酉)에서 탈(脫)로 된다. 이와 같은 것을 탈상탈을 만난다고 하는데, 허손을 방비해야 한다. 
    비전에는 ｢유(酉)는 종괴(從魁)로서 아괴성(亞魁星)이라 부른다｣고 하였다. 일간상신에 유(酉)가 있고 년명상신에 또 유(酉)를 보면 시험에는 반드시 급제된다. 
    일귀는 역행하며 3전은 후(后)․현(玄)․백(白)이고, 야귀는 3전이 백(白)․청(靑)․합(合)이다. 3전 중에 백호를 보는 것을 싫어하는데, 공망에 앉는 것은 별로 두려울 것이 못된다.`
    ,
    // 12
    `   이 과는 제4과에서 1하가 1상을 적하여 중심퇴여격(重審退茹格)이다. 해자축(亥子丑)은 북방수합(北方水合)하여 일간의 재로 되니 구재에 길하다. 말전 축(丑)은 순미(旬尾)로 되어 폐구격(閉口格)이며, 소송사를 싫어한다. 3전의 둔간은 신임계(辛壬癸)의 3기(奇)로 되어 흉을 구(救)하므로 모든 일이 화합하다. 일간상신에 태상․청룡이 승하니 결혼은 대단히 길하다.
    백법에 ｢3전이 3기를 만나는 것은 존숭(尊崇)․은상(恩賞)․재물 중 어느 것인가의 기쁨을 만난다｣고 하였다. 3기는 천상(天上)․지하(地下)․인중(人中)으로 구분한다.  신임계(辛壬癸)는 인중(人中)의  3기(三奇)에 속한다. 
    비전에는 ｢축(丑)이 자(子)에 가해지면 복태격(腹胎格)이라고 하였다. 천반 축(丑)의 음신이 공망을 만나므로 출산을 정단하면 속히 출산한다. 임신을 정단하면 태아가 손상된다.
    일귀는 순행하며 3전은 후(后)․귀(貴)․사(蛇)이고, 야귀는 3전이 현(玄)․상(常)․백이다. 과전이 가량하지는 않으나 재화(災禍)는 면한다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
