import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";

const ContentContainer = styled.div`
    position: relative;
    width: 100vw;
    min-height: 100vh;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (max-width: 400px) {
        /* ... */
        width: 100vw;
    }
    @media only screen and (min-width: 400px) {
        /* ... */
        width: 100vw;
    }
    @media only screen and (min-width: 600px) {
        /* ... */
        width: 100vw;
    }
    @media only screen and (min-width: 768px) {
        /* ... */
        width: 100vw;
    }
    @media only screen and (min-width: 992px) {
        /* ... */
        width: 100vw;
    }
    @media only screen and (min-width: 1200px) {
        /* ... */
        width: 100vw;
    }
`;

const Comp = props => {
    const { children } = props;
    return <ContentContainer {...props}>{children}</ContentContainer>;
};

export default Comp;
