
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,2,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,8,1,`특별있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,9,12,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,10,11,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,11,10,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,9,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,8,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,2,7,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,3,6,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득-불성`,4,5,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집-불성`,5,4,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,6,3,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,``,``],
    [`색정`,`外남 內녀 동시 색정사 有`,`大`,`단절기`,`색정사有`,`색정사有`],
    [`기가취지(豈可取之)`,`재해가 많게 되는데 어찌 취득하려고만 하는 가-의 상`,`大`,``,`손해도망`,`비용과출`],
    [`탄식신음(嘆息呻吟)`,`병 또는 事 미수로 인한 탄식 하는 상`,`小`,``,``,`탄식신음`],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부(남)`,`남편(남자) 갈등사`,`大`,`8`,``,`관계불통`],
    [`분산`,`외사 동요불안분산사`,`小`,`침체기`,`미초희경`,`간사은닉`],
    [`욕명불명(慾明不明`,`밝음을 원하나 밝아지지 못하는 상`,`小`,``,`권한부족`,`가택이사`],
    [`간사은닉(奸私隱匿)`,`관계성불통-가택이사-간사은닉사의 상-淸論難容`,`大`,``,`실소`,``],
    [`후원`,`후원 관련사`,`大`,``,``,`우유한가`],
    [`문서`,`문서 관련사`,`小`,`9`,`위례득죄`,`실업처지`],
    [`부친`,`부친 관련사`,`小`,`소외기`,``,`애증화합`],
    [`필수폐구(必須閉口)`,`반드시 입을 닫아야만 하는 상-맹공격이 방출됨`,`大`,``,``,`파재극처`],
    [`미초희경(微招喜慶)`,`권한 증명 표시가 결여되어 실소 하는 상-부족`,`小`,``,``,`상신우려`],
    [`후원`,`후원 관련사`,`大`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`10`,``,``],
    [`결속`,`결속하여 음6월에 후원사를 크게 세우나 자녀災害 발생함`,`小`,`침해기`,`결속`,`단합`],
    [`결속`,`내외 남녀 상하 결속 단합의 상-부족-자기 것을 지킬 것`,`大`,``,`부족절감`,`부족절감`],
    [`봉상희경(捧觴喜慶)`,`고위가 내린 술잔을 받고 애써 기쁨을 참는 상-부족`,`小`,``,``,``],
    [`박관`,`직업직위불안사`,`小`,``,``,``],
    [`극부`,`남편갈등사`,`大`,`11`,`환성경사`,``],
    [`자식`,`음6월 결속하여 후원사는 완성하는 상- 자녀걱정 발생함`,`小`,`쇠퇴기`,`외사과출`,`앙화소멸`],
    [`수구최길(守舊最吉)`,`옛것(익숙하게 해오던)을 지키는 것이 제일 좋은 상`,`小`,``,``,``],
    [`앙화소멸(殃禍消滅)`,`재난이 해소되는 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`大`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`12`,`존비상몽`,`복통수욕`],
    [`출행`,`내외 출행 이동 원행사`,`小`,`과시기`,`동요불안`,`동요불안`],
    [`복통수용(腹痛受辱)`,`복통사-모사(謀事)는 좋은 상`,`小`,``,``,`분산사有`],
    [`논송난소(論訟難訴)`,`범죄의 결론이 어려움으로 입을 닫는 것이 좋은 상`,`小`,``,``,``],
    [`직녹`,`직위자산권리사`,`大`,``,``,``],
    [`매사허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`1`,`침해불성`,`압박가함`],
    [`매사 영속성없음`,`자산형제친구동지사는 선천적 기복재해를 겪음`,`小`,`절정기`,`상하화합`,`허사불성`],
    [`호가호위(狐假虎威)`,`호가호위위세이므로 좌모(座謀-앉아서 궁리함)가 좋은 상`,`小`,`세속허사`,`허사불성`,`관재형벌`],
    [`모사즉길(謀事卽吉)`,`계획사는 좋은 상`,`小`,`자로무망`,`동지함의`,`有`],
    [`박관`,`결속하여 음6월에 후원사를 크게 세우나 자녀災害 발생함`,`小`,``,``,``],
    [`매사허성불성`,`음3,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`2`,``,``],
    [`매사 영속성없음`,`직업남편남자식사는 선천적 숙명재해를 겪음-독신고독`,`小`,`면모기`,`외교막힘`,`재물유실`],
    [`미면억륵(未免抑勒)`,`억류인의 신세를 면하지 못하는 상`,`小`,``,`재난극복`,``],
    [`사송계류(詞訟稽留)`,`송쟁사가 해결되지 않고 계류되어 있는 상`,`大`,``,`이득진취`,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`3`,``,`도적不捕`],
    [`급속`,`내외사 출행이동사`,`大`,`학습기`,`재앙소멸`,`손해가출`],
    [`매사 가허(每事可許)`,`주력사가 이루어지는 상`,`小`,``,``,``],
    [`신물수손(身物受損)`,`몸, 또는 소지물품을 손실한 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`4`,``,``],
    [`여인`,`여인 관련사`,`大`,`개시기`,`간사은닉`,`승진영전`],
    [`고위편지(高位遍地)`,`도와주는 고위인이 한직으로 물러난 상`,`小`,``,``,``],
    [`간사은닉(奸私隱匿)`,`관계성불통-가택이사-간사은닉사의 상-淸論難容`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,`급속송쟁`,``],
    [`극부`,`남편 갈등사`,`大`,`5`,`파재실종`,`남편갈등`],
    [`자식`,`자식 문제사`,`小`,`배양기`,`상해우려`,`침체驚懼`],
    [`혹인식상(惑因食傷)`,`음식이 원인이 되어 몸이 상한 상`,`小`,``,`총시성재`,`도적실재`],
    [`총시성재(總是成災)`,`모두 재난 뿐인 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,``,`가족반목`],
    [`남편`,`남편 관련사`,`大`,`존재기`,`불의투서`,`가정막힘`],
    [`희서관비(喜庶官非)`,`일반인은 좋고 관직자는 안 좋은 상`,`大`,``,`구설실재`,`도적의혹`],
    [`도적우의(盜賊憂疑)`,`도적 의혹 근심이 있는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`관사직업외내색정사또는병으로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`직위불안男갈등가택이사간사은닉사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`후원문서부친조력사로가능함`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`후원문서모친조력내외결속사로가능함`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`직업직위남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,`형제자매친구조력출행사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`금전사업처관련사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`금전상업여인사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`송쟁부부갈등자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`관사직업남편가정반목사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 신속하게 종결해야함 `,0,0,`사안을 미루거나 좌고우면하면 결과가 되려 불리해지는 人`,0,0,`일이 역조적으로 진행됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인고 다툰다면 피차 전의가 별로 없고 있다해도 가벼움`,0,0,`위세를 가장하여 상대를 공격하나 적중되지 않아 되려 불리한 입장인 人`,0,0,`일이 역조적으로 띄엄띄엄 전개되어 그만두려하나 계속하게 되는 격`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 신속하게 종결해야함 `,0,0,`사안을 미루거나 좌고우면하면 결과가 되려 불리해지는 人`,0,0,`신규사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 후에 행동함이 좋고 방심하면 불리함 `,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 하나로 묶으려하나 여건조건이 불비함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 먼저 나서지 말고 순응유화적 처신으로 일을 미뤄야 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하며 지체시켜야만 결과가 유리하게 되는 人`,0,0,`독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오니 신중해야 함`,0,0,`반복무상한 입장에 처한 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 먼저 나서지 말고 순응유화적 처신으로 일을 미뤄야 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하며 지체시켜야만 결과가 유리하게 되는 人`,0,0,`권리사를 행사함-공직자는 좋고 일반인은 불리함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 먼저 나서지 말고 순응유화적 처신으로 일을 미뤄야 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하며 지체시켜야만 결과가 유리하게 되는 人`,0,0,`여러 일을 하나로 묶는 결속사有-단 복잡하고 어려움**1녀2남 삼각관계有**되돌아가고져 함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)**만약 타인과 다툰다면 감춘 일이 드러나게 되므로 물러서야 함`,0,0,`기구한 사연으로 굴을 못 찾은 겨울 뱀의 처지이고 범인을 감추거나 자신이 혐의자인 人`,0,0,`여러 일을 하나로 묶으려하나 어려움-단 완성을 해도 외화내허함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 먼저 나서지 말고 순응유화적 처신으로 일을 미뤄야 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하며 지체시켜야만 결과가 유리하게 되는 人`,0,0,`관직자는 자리 옮김**색정우려**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 먼저 나서지 말고 순응유화적 처신으로 일을 미뤄야 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하며 지체시켜야만 결과가 유리하게 되는 人`,0,0,`일이 순서적으로 나아가나  그만 두게 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지 않음 `,0,0,`스스로 유약하다고 여기고 움직이려 하지 않는 人`,0,0,`일이 모두 폐하거나 막힘`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`기사`,`신구중간사`,`내외모호사`,`돌발사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사-급속사 겹침`,`미사`,`신사`,`내사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-침체`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`애증화해`,`애증화해`,`애증화해`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음8월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음5월-연기 우려`,3,1,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,3,3,6,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월-연기 또는 파혼우려`,2,1,3,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음4월`,1,1,2,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음10월-연기우려`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월-연기우려`,2,3,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,3,1,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음2월`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음11월`,2,2,4,`음음5월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음10월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음9월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준소 미모이며 업무도 수준 이상으로 끝냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 초반 외에는 업무실적이 공허함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 업무도 성과적임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 종반에서야 겨우 순준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초중반이 허무하고 종반에 이르러 실적을 내나 결과적으로 이심배반사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 시종 동요분산배반사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행에 불성실한 면이 있으나 업무는 성공적이며 권위를 고양시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 상대를 잘 조정하여 여러 사항를 일관시켜 종반에 이르러 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 초반 중반은 공허하고 종반에 이르러 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 업무는 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 중반 외에는 업무가 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무는 시종 수준 이하로 끝냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행함`,`본인무력`,`退背`,` 근`,`去`,`주인가해`,`정동`,``,`정동`],
    [`10.11`,``,``,``,``,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,`했음`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대없음`,`이행함`,`선후압박`,`進向`,`  근`,`  留`,`주인조력`,`북북서`,``,`북북동`],
    [`10.11`,``,``,``,`가해야함`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,`이행함`,`선압박`,`退背`,`  근`,`  去`,`주인조력`,`북북동`,``,`동북`],
    [`7.8월`,``,`본인제압`,``,`후비용`,``,``,``,`결국손해`,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행함`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,` 정동`,`북북동`,``],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`동남`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`선비후이`,` 退背`,`  근`,`  去`,`세입가해`,` 서남`,`북서`,``],
    [`10.11`,``,`본인제압`,``,`천천히`,``,``,``,`동요분산`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선損후不`,`退背`,`  원`,`  去`,`주인가해`,`동남`,`정서`,``],
    [`10.11`,``,`제압난함`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선이후滯`,`退背`,`원근왕래`,`  去`,`세입손실`,`북북동`,`북서`,``],
    [`10.11`,``,`대세부결`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,`됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대없음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,``,`불이행`,`선이후不`,`退背`,`원근왕래`,` 去`,`세입조력`,`동북`,`북북서`,``],
    [`10.11`,``,``,``,`빠르게`,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대있음`,`불이행`,`선損후이`,`退背`,`  근`,`  去`,`세입가해`,`정북`,``,`정북`],
    [`10.11`,``,`본인합세`,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`내부반대`,`불이행`,`선난후損`,`進向`,`  근`,`  留`,`무난`,`동남`,``,`북북동`],
    [`10.11`,``,`연기지체`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`필요`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`내부동조`,`불이행`,`선난후난`,`退背`,`  근`,`  去`,` 무난`,`남남동`,``,`동북`],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,"여고음강",`시모영령`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`여귀`,`가장부인`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`신부정귀`,`신不定`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,``],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,`없음`,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`水귀`,`익사`,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,`북북서`,`없음`],
    [``,``,``,0,0,`묘향`,`남남동`,``],
    [`생업`,``,``,0,0,`전경`,`바다포구`,``],
    [``,``,``,0,0,`묘속`,  `水`,``],
    [`가정`,`신부정귀`,`신不定`,0,0,`육친`,`고조부`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`도적의혹근신사`,`내사가정막힘`,`외내남녀동시색정사`,`염려실재사`,``,`승진영전사`,``,`관직자-생살대권쥠 일반인- 형벌대흉함`,`빈궁**관재`,`폭객능욕-속음`,``,`중중희애-비천지상`,``,`외내사 출행이동원행사`,`매사허성불성**세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`매사영속성없음**외교막힘`,`종교진출하면자립함`,`외내사 급속사`,`모두 재난 뿐임`,``,`관계성불통-가택이사-간사은닉`,`외사급속송쟁-상해-실종사 우려`,`신음탄식사-병 또는 事미수`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재**전도`,``,`전화위복`,`빈궁**관재**전도`,``,``,`재액해소`,`빈궁**병재`,`재액해소`,``,``,``,`빈궁`,`음4월 화재주의`,``,``,`빈궁`,`선천 숙명적 독신고독자`,`빈궁**병재`,`전화위복`,``,``,`침체경구사**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외사급속송쟁-상해-실종사 우려`,`매사위역**빈궁**관재`,`빈궁**관재`,`내사가정막힘`,`외내남녀동시색정사`,`부부남녀반목사`,``,`술잔내려받는미초희경사-부족`,`전도**관재`,`宜상서헌책`,`전도*빈궁`,`폭객능욕-속음`,`관재형벌`,`매사위역`,``,`외내사 출행이동원행사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**외교막힘`,`종교진출하면자립함`,`외내사 급속사`,`화장매무세가 필요없는 한가로움`,``,`관계성불통-가택이사-간사은닉`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`재난전소`,``,``,`전화위복`,`관재`,``,`빈궁`,``,``,``,``,``,`병재`,`재액해소`,`음4월 화재주의`,``,`빈궁`,`선천숙명적 독신고독자`,`빈궁`,`전화위복`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`관계성불통-가택이사-간사은닉`,`외사급속송쟁-상해-실종사우려`,`매사위역**빈궁`,``,`내사가정막힘`,`외내남녀동시색정사`,`부부남녀반목사`,``,`술잔내려받는미초희경사`,``,`宜상서헌책`,`폭객능욕-속음`,``,`관재형벌`,`매사위역`,``,`외내사 출행이동원행사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**외교막힘`,`종교진출하면자립함`,`외내사 급속사`,`화장매무세가필요없는한가로움`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`재난전소`,`재액해소`,`빈궁**관재`,`재액해소`,`관재`,``,`빈궁`,``,``,`빈궁`,``,``,`빈궁**병재`,`관재`,`음4월 화재주의`,`빈궁`,``,`빈궁`,`선천숙명적 독신고독자`,`전도`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`도적절족실세격`,`외내사 급속사`,`빈궁**관재`,`음사폐호안녕`,`신음탄식-병 또는 事미수`,`외사급속송쟁-상해-실종사 우려`,`재난극복-이득진취`,`내사가정막힘`,`외내남녀동시색정사`,``,`눈이 가려진 상`,``,`환성경사`,``,``,`부부불화`,`중중지애-비천지상`,`매사위역`,`외내사 출행이동원행사`,`모사吉`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**술잔내려받는미초희경사`,`종교진출하면자립함**외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`병재`,`전화위복`,`빈궁`,``,``,``,`빈궁**관재`,``,`전화위복`,`관재`,`빈궁`,``,`재액해소`,``,`재액해소`,``,`빈궁**관재`,``,`음4월 화재주의`,`빈궁`,``,`빈궁**병재`,`선천 숙명적 독신고독자`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`매사영속성없음**술잔내려받는미초희경사`,`종교진출하면자립함**외교막힘`,`빈궁**병재**전도`,`도적실세절족격`,``,`음사폐호안녕`,`신음탄식-병 또는 事미수`,`외사급속송쟁-상해-실종사 우려`,`재난극복-이득진취`,`내사가정막힘`,`외내남녀동시색정사`,``,`눈이 가려진 상`,``,`환성경사`,``,``,`부부불화`,`중중지애-비천지상`,``,`외내사 출행이동원행사`,`모사吉`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`선천 숙명적 독신고독자`,`외내사 급속사`,`전화위복`,`빈궁**좐재**전도`,``,``,``,``,``,`전화위복`,`빈궁**관재`,``,`빈궁`,`재액해소`,`관재`,`재액해소`,`빈궁`,``,``,`음4월 화재주의`,`빈궁**병재`,`빈궁**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`매사영속성없음**身물손실`,`종교진출하면자립함`,`매사영속성없음**외교막힘`,`종교진출하면자립함**선천숙명적 독신고독자`,`혼인사**외내사 급속사`,`전화위복`,``,`상하존비상몽`,`외사급속송쟁-상해-실종사 우려`,`매사위역**재난전소`,`재난극복-이득진취`,`내사가정막힘`,`스스로 지켜 가나있으나 좋음`,`외내남녀동시색정사`,`신분이 차이나는 음란-상하존비상몽`,``,``,`도적불포사`,`재물유실사`,``,`매시위역`,`관재형벌`,`외내사 출행이동원행사`,`모사吉**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,``,`동요불안분산사`,`빈궁**송사계류`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`빈궁**관재**전도`,`빈궁`,`동요불안분산사`,`관재`,``,`동요불안분산사`,`관재**전화위복`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`빈궁`,`동요불안분산사`,``,`동요불안분산사`,`빈궁**병재`,`음4월 화재주의`,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외내사 출행이동원행사`,`모사吉`,`매사영속성없음**身물손실`,`종교진출하면자립함`,`매사영속성없음**외교막힘`,`종교진출하면자립함**선천 숙명적 독신고독자`,`혼인사`,`외내사 급속사`,``,`상하존비상몽`,`외사급속송쟁-상해-실종사 우려`,`매사위역**빈궁`,`재난극복-이득진취`,`내사가정막힘`,`스스로지켜 가나있으나 좋음`,`외내남녀동시색정사`,`신분이 차이나는 음란-상하존비상몽`,``,``,`도적불포사`,`재물유실사`,``,`매사위역`,`관재형벌`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4월 화재주의`,`빈궁`,``,``,`빈궁`,`송사계류`,`전도**관재`,`전화위복`,`전도**빈궁`,``,``,`재난전소`,`관재`,``,`관재`,`전화위복`,`재액해소`,`빈궁`,`재액해소`,`빈궁`,``,``,`빈궁**관재**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사위역`,`관재형벌`,`오내사 출행이동원행사`,`모사吉`,`매사영속성없음**身물손실`,`종교진출하면자립함`,`매사영속성없음**외교막힘`,`종교진출하면자립함**선천숙명적 독신고독자`,`혼인사`,`외내사 급속사`,``,`상하존비상몽`,`외사급속송쟁-상해-실종사 우려`,`매사위역**빈궁`,`재난극복이득진취`,`내사가정막힘`,`스스로 지켜 가나있으나 좋음`,`외내남녀동시색정사`,`신분이 차이나는 음란-상하존비상몽`,``,``,`도적불포사`,`재물유실사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재**병재`,``,`음4월 화재주의`,`빈궁`,``,``,`빈궁**관재`,`송사계류`,``,`전화위복`,`빈궁`,``,``,`재난전소`,``,`전도**빈궁`,`전도**관재`,`전화위복`,`빈궁`,``,`재액해소`,`관재`,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재물유실사`,``,``,`관재형벌`,`외내사 출행원행이동사`,`모사吉`,`매사영속성없음**身물손실`,`종교진출하면자립함`,`매사영속성없음**외교막힘`,`종교진출하면자립함**선천 숙명적 독신고독자`,`혼인사`,`외내사 급속사`,``,`상호존비상몽`,`외사급속송쟁-상해-실종사 우려`,`매사위역**빈궁`,`재난극복-이득진취`,`내사가정막힘`,`스스로지켜 가나있으나 좋음`,`외내남녀동시색정사`,`신분이 차이나는 음란-상하존비상몽`,``,``,`도적불포사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁**병재`,``,`음4월 화재주의`,`빈궁`,``,``,`빈궁`,`송사계류`,`전화위복`,`전도`,``,`전도**빈궁`,``,`재난전소`,`재액해소`,`관재`,`재액해소`,`관재`,``,`빈궁`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,``,`관직자-생살대권쥠 일반인 형벌대흉`,``,`폭객능욕-속음`,``,`중중지애-비천지상`,`관직자영전-일반인 반흉`,`외내사 출행이동원행사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**외교막힘`,`종교진출하면자립함**선천숙명적 독신고독자`,`외내사 급속사`,`모두 재난 뿐임`,``,`관계성불통-가택이사-간사은닉`,`외사급속송쟁-상해-실종사 우려`,`신음탄식-병 또는 事미수`,`도적의혹근심사`,`내사가정막힘`,`외내남녀동시색정사`,`염려실재사`,``,`승진영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**병재`,``,`전도**관재`,``,``,``,`빈궁**재액해소`,`음4월 화재주의`,``,``,`빈궁`,``,`빈궁**병재`,`전화위복`,``,``,``,``,`빈궁**관재`,``,`전화위복`,`빈궁**관재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`승진영전사`,``,`관직자-생살대권쥠 일반인 형벌대흉`,``,`폭객능욕-속음`,``,`중중지애-비천지상`,`관직자 영전-일반인 반흉`,`외내사 출행이동원행사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**외교막힘`,`종교진출하면자립함`,`외내사 급속사`,`모두 재난 뿐임`,``,`관계성불통-가택이사-간사은닉`,`외사급속송쟁-상해-실종사 우려`,`신음탄식-병 또는 事미수`,`도적의혹근심사`,`내사가정막힘`,`외내남녀동시색정사`,`염려실재사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전도**관재**병재`,``,`전도**빈궁`,``,``,``,`빈궁**재액해소`,`음4월 화재주의`,``,``,`빈궁`,`선천 숙명적 독신 고독자`,`전화위복`,`병재`,``,``,``,``,`빈궁**관재`,`폐한인`,`전화위복`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외내남녀동시색정사`,`염려실재사`,``,`승진영전사`,``,`관직자-생살대권쥠 일반인-형벌대흉`,``,`폭객능욕-속음`,``,`중중지애-비천지상`,`관직자 영전-일반인 반흉`,`외내사 출행이동원행사`,`매사허성불성**세속허사자`,`음1,2,4,5월방문자는 종교진출하면성공함`,`매사허성불성**세속허사자**외교막힘`,`음3,4,5,6,9,12월방문자는종교진출하면`,`외내사 급속사`,`모두 재난 뿐임`,``,`관계성불통-가택이사-간사은닉`,`외사급속송쟁-상해-실종사 우려`,`신음탄식-병 또는 事미수`,`도적의혹근심사`,`내사가정막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**관재`,``,`재액해소`,`빈궁**병재`,``,`빈궁`,``,``,`재액해소`,`전도**빈궁`,`음4월 화재주의`,`전도`,``,`빈궁`,`성공함**선천숙명적독신고독자`,`빈궁**병재`,`전화위복`,``,``,``,`재액해소`,`관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  

  const CHOSA1 = [`■ 힘없는 사람이 무거운 짐을 졌으니 진퇴양난이겠네?

  ⊕신부정귀, 
  ⊖음귀부터 없애고 업종을 
    바꿔야 살아!
  `,`■ 집에 ⊕⊖가택수망노부 부정이 들었으니, 
  자칫 교도소 가게 생겼네? 
  지금 상황에서 발을 빼든가,부정을 풀던가, 이사를 빨리해야 돼!
  ⊕신변 상가부정이 들었어!
  `,`■ 총은 있는데 총알이 없는 격이야! 
  그러니 상대가 쓰러지겠어? 
  윗사람을 찾아나서 봐, 도움을 얻을거야!
  `,`■ 원행․이동사에 비용이 많이들어가겠어 !
  ⊕신부정귀, 
  ⊖변사귀 장난이야! 
  이것을 해소시키고 가면 
  오히려 큰 이익이 있겠어!
  ⊕신변 목기부정 침범
  ⊖신변 상가부정이 들었어!
  `,`■ 일도 많고 사람도 많은일에     치어 물구덩이에 빠진격
     일세?
  
  ⊕ 신부정귀, 
  ⊖ 변사귀부터 달래고 보면 
     옛일로 부흥할 수 있겠어!
  `,`■옛일, 옛인연 모두 끝났어! 애써봐야 헛거야!
  직․녹손실이 있겠고 !
  
  ⊕가택상가부정 해소하면
    금전 재수는 좋아질거야!
  `,`■ 색정사로 배반당해 만사가     뒤집어졌네? 
  
  ⊕신부정귀, 
  ⊖변사귀를 해소해야 
    신규사로 승부를 걸어보지!
  `,`■ 집안에 ⊕⊖상가부정이 들어 병자가 있겠어!
  부정만 해소하면 산 사람에게는 만사가 풀리고 기쁜 소식도 오게 될거야!
  `,`■ 일도 많고 사람도 많네? 
  하나로 결속시키겠는데 ! 
  그래도 옛것을 지켜야 복이 
  되겠어!
  
  ⊕신부정귀, 
  ⊖음귀를 해소시켜야 일이     뜻대로 풀릴거야!
  `,`■ 빛 좋은 개살구네!
  허비소모가 많아!
  ⊖가택상가부정이 들어 병자가    있겠어!기도ㆍ치성이효과가    있겠으니근신하고그대     로 지켜!
  ⊕신변 음사부정 침범
  ⊖신변 수망노부부정이        들었어 !
  `,`■ 윗사람에게 도움을 얻어보려고 하겠는데 그 전에
  ⊕신부정귀,⊖음귀부터 해소하고 일을 봐야 되는게 있겠어! 
  男：下人女와 인연으로 재앙을      만드네?
  `,`■돈ㆍ여자를 과욕하면 재앙이 되겠어! 
  돈들여 오는 것은 좋으나
  ⊕여귀, 
  ⊖수귀의 방해는 피해가야
   하겠네! 병이 발생하겠어!
  ⊖신변 음사부정이 들었어 !`];
  const YOGYEOL1 = [`■ 사람과 집이 모두 발동하니 어찌 머물 수 있겠는가. 서로 입에 삼키려드니 함께 놀래고 괴이한 일이 생기리라.
  `,`■ 낮정단은 내 녹을 범이 물어가고 초전과 가택에 묘신이 탔다. 머문 곳을 팔아 돈을 버나 몸이 병들까 걱정된다.
  `,`■ 내가 쏜 총이 맞지 않는다. 누가 내게 와서 구한다. 깨지는 것이 있으니 금전이 완전하지 못하다.
  `,`■ 낮정단의 이름은 참관이다. 일간 위에 천강이 있어 기막힌 일이 갑자기 생긴다. 도망하여 크게 이로우리라.
  `,`■ 사람은 도움을 얻으나 가택은 점점 기울어 간다. 내가 스스로 사서 화를 당한다. 반은 위태롭고 반은 좋으리라.
  `,`■ 교섭사는 먼저는 좋고 나중은 나쁘다.
  처나 재물로 실패수가 있다. 녹은 없어졌으니 지킨들 무슨 소용이겠는가
  `,`■ 고쳐봐야 다시 마찬가지이다. 이사나 여행으로 움직이겠다. 소송은 뜻대로 안되고 매사 입 다물고 있는 것이 좋겠다.
  `,`■ 사람과 집이 모두 관귀 귀신을 만났으나 두 귀인이 집을 밀어주니 나쁜 일은 사라진다.
  `,`■ 사람은 잘되나 집은 좁다. 그러나 오래 살면 좋지 않다. 밤정단은 모두 허사가 되고 낮정단은 장돌뱅이와 같도다.
  `,`■ 네 마리 범이 와서 현재의 업을 절대 지켜야 한다. 불이 나무를 만났으니 아무리 써도 줄지 않으리라.
  `,`■ 내가 아쉽거든 예의를 다해 찾아보라. 재물이 깨지니 처나 여자로 몸이 상할 것이다.
  `,`■ 재물이 많은 것은 기쁘나 집안에 사고나 관재가 두렵다. 자세히 살펴보니 병들어 나쁠까 걱정이다.`]
  const SIBO1 = [`■ 가내 우환이 발생하고
  가인이 가장을 속이며 원한
  을 품고 가해하려 한다.
  ⊕경조사의 속임, ⊖문서사의 속임을 당한다.
  凶을 凶으로 제어한다.
  결국 ⊕문서사, ⊖경조사에 힘을 뺏기게 된다.
  매사를 확 바꾸지 않으면 폐지의 위기가 닥친다.
  이사하려 한다.
  `,`■ ⊕⊖가정에 수망노부
  부정이 들어 가정이 침체
  하고 일도 진실과 성의가
  있다.
  관재가 있어 피신해야 편안한 형국이다.
  참관이 통하여 기도ㆍ치성이 吉하다. 특히 巳띠 자는 개고되어 자유를 얻는다.
  `,`■ 스스로의 노력에 의해
  도모사가 호전되고 운명도
  펴지나 지속적으로는
  반감이다. 
  어떤 일도 초장에 승부수를 던져야 하고 끌면 효과가 없게 된다. 
  丑月과 丑日에 금전사가 결정된다. 상대를 공격하는 일은 적중이 안된다.
  속는 일이 야기된다.
  `,`■ 가택 문제가 고민이다.
  활동을 하게 되나 신불에
  기도하는 것이 우선이다.
  신규사에 손모가 있게 된다. 중간에 효과가 있으나 끝에서 귀신과 저주문서사가 기한다. 타인의 추천을 받는 일이 있다.
  `,`■ 사람은 왕성해지나
  가택은 좁다.
  후원이 많고 진실과
  성의가 있다. 
  가급적 옛것을 지켜야 곡절 파동을 극복한다.
  공망으로 부목이 되어 버렸기 때문이다. 
  부부가 모두 음탕하다. 
  신불기도가 필요하다.
  속는 일이 있다.
  `,`■ 진실과 성의를 받으나
  옛일, 옛 인연은 모두
  끝나게 된다.
  금전이나 챙겨서 후일을 도모해야 한다. 
  모사는 위험하다. 
  가택의 권리가 넘어간다. 
  단, 관직 승진, 가택수조사는 吉하다.
  
  ⊕가정에 상가부정이 들었다.
  `,`■ 내 몸에 저주사가 있다.
  배반사로 배반당했다.
  신규사도 만사 미정의
  입장에 있다. 변동 기복이 심하다. 
  말을 조심해야 한다.
  중단에 허기부실을 면하려면 신불기도에 매달려야 한다. 손모는 많고 허공부실한 형국이다.
  `,`■ ⊕⊖집안에 상가부정이
  들어 병자가 있고,
  자충수적 우환이 발생한다.
  관공직자는 매우 吉하다.
  일반인도 혼미한 정체사가 풀리게 된다.
  집안의 병자가 걱정이다. 
  중전戌에 의지해야 한다.
  집밑에 복시가 있다.
  正月 임신사가 있다.
  `,`■ 상대가 스스로 와서
  피해를 준다.
  ⊕신불기도, ⊖여인음사
  주의가 필요하다. 
  장차 후원이 있어 혼인ㆍ잔치 등의 길사가 있게 된다. 옛것을 지키고 책모하지 않는 것이 좋다.
  토지가옥 등에 다툼이 일어난다.
  다인 다사건이다.
  `,`■ ⊖상가부정이 들었다.
  ⊖가정에 병자가 있다.
  가정에 숨기는 일이 있다.
  건록이 공망하여 책모가 불성하고 허비가 계속된다. 과감히 옛것을 버려야만 좋다. 
  가정에 병ㆍ재난이 있어 干上ㆍ年命上에 월장이 아니면 나쁘다.
  `,`■ 이익사가 있고, 소득한다.
  巳月ㆍ日이다.
  음사사가 있어 서리내린
  얼음판을 걷는 격이다.
  사용인과 정이 통했다. 정처로 삼게 된다.
  
  ⊕신불기도가 필요하고,
  ⊖여자 사용인을 주의해야 한다. 자칫 죽게 된다.
  `,`■ 가내 우환이 발생하고
  가인이 가장을 속이며 원한
  을 품고 가해하려 한다.
  여자나 금전에 과욕을 부리면 관재가 일어난다.
  서리내린 다리를 달리는 격에 있다.
  금전 외에는 모두 공허한 형상이다.
  正月 임신사가 있다.`]
  const SIBO2_1 = [`■근친가택내사이다.
  내방조짐 상의 : 이전·이사
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■가정탄식반목사이다.
  내방조짐 상의 : 해산건 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■금전여인사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■가택동요손모사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  은근하고 伏하라.
  관재 질병이 침입한다
  `,`■신변외사속임수이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  속는 일이 있고 자손재액이 발생한다 예방이 필요하다
  `,`■신변외사직녹근심이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  낭비가 많고 재녀상실하고 형제에 재액이 있다.
  `,`■가택동요손모사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.
  은근하고 伏하라.
  색정불화가 있다.
  `,`■가택동요손모사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  은근하고 伏하라.
  모망사는 완수된다.
  `,`■신변외사속임수이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  속는 일이 있고 자손재액이 발생한다 예방이 필요하다
  `,`■신변외사직녹근심이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  낭비가 많고 재녀상실하고 형제에 재액이 있다.
  `,`■금전여인사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■금전여인사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  용두사미의 상이다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/024/row-1-column-1.png`,
    `/static/images/sagwa/024/row-1-column-2.png`,
    `/static/images/sagwa/024/row-1-column-3.png`,
    `/static/images/sagwa/024/row-1-column-4.png`,
    `/static/images/sagwa/024/row-1-column-5.png`,
    `/static/images/sagwa/024/row-1-column-6.png`,
    `/static/images/sagwa/024/row-1-column-7.png`,
    `/static/images/sagwa/024/row-1-column-8.png`,
    `/static/images/sagwa/024/row-1-column-9.png`,
    `/static/images/sagwa/024/row-1-column-10.png`,
    `/static/images/sagwa/024/row-1-column-11.png`,
    `/static/images/sagwa/024/row-1-column-12.png`,
  ]
  const HEASYEOL = [
    // 1
    `  이 과는 천지반이 본궁에 엎드려 복음과(伏吟課)이다. 4과에 상극이 없이 음일이기 때문에 일지상신을 초전으로 한다. 해(亥)는 자형(自刑)이고 중전은 일간상신, 말전은 충신(冲神)인 축(丑)으로써 3전을 구성하여 두전격(杜傳格)으로 되고, 아무 일에서나 폐색되어 통하지 않는다.
    잉태한 아이는 농아일 우려가 있고, 기다리는 사람은 소식이 없다. 복음은 모두 일간일지의 왕쇠에 의하여 길흉을 간점한다. 이 과의 일간은 극을 받고 또 설탈(泄脫)하여 쇠퇴한다. 쇠퇴할 때는 수세의 방침이 좋고 흉한 사건에서는 기다리며 참아야 한다. 그러나 4과3전 중의 해(亥)는 정신(丁神)으로 되어 끊임없이 변동하는 징조가 있다. 중전의 미(未)는 공망되어 요절(腰折) 공망이라고 부르며 사물의 중단이 있거나 혹은 중도에 좌절된다.
    음일 복음은 자신(自信)이라 부르고 자기는 유(柔)하다고 자각하여 조용할 것을 바라지만, 수토(水土)가 상극되고 정신(丁神)이 임립(林立)하므로 조금도 한가하지 않다. 두색격(杜塞格)은 중도에 일을 바로잡아서 행동하면 절반은 달성할 수 있다.
    백법에 말하기를 ｢귀(鬼)와 천을이 같이 있으면 천을신기격(天乙神祗格)으로 된다｣라고 하였다. 재앙은 아니지만 신불에 빌면 흉사가 완화된다. 비전(秘傳)에 이르기를 ｢정해일(丁亥日)의 복음은 자신정마(自信丁馬)로 되어 여행이나 대인(待人)을 정단하면 중도에 장애가 생기고 중전이 공망하여 진퇴양난에 빠진다｣고 하였다. 일귀는 순행하여 삼전이 귀(貴)․상(常)․주(朱)이고, 야귀는 역행하여 삼전이 음(陰)․주(朱)․상(常)이다.`
    ,
    // 2
    `  이 과는 제3과의 술해(戌亥)의 상극뿐이므로 이것을 초전으로 하여 원수퇴여격(元首退茹格)이 된다. 지상신인 술(戌)의 천괴(天魁)로써 초전을 이루는 것은 참관격(斬關格)이라고 한다.
    3전 중에 신유(申酉)의 두 자가 있다. 참관(斬關)이 통하여 길로 된다. 즉 이 격은 자축인묘미신유(子丑寅卯未申酉)의 그 지(支)를 중전․말전에서 볼 때에는 진(辰)의 참관도 같게 논한다. 또한 상기한 7지 이외에 귀(貴)․합(合)․청(靑)․백(白)․상(常)․음(陰)․후(后)의 7장(將)이 있는 것도 참관에 통한다. 참관에 통하는 것은 질병․소송․여행․외출․조제(調劑)․신불기도(神佛祈禱)․신체의 피익(避匿) 등 모두 길조라고 본다. 구태(舊態)를 지키는 일이나 도적을 잡는 일에서는 불리하다. 만일 통하지 않는다면 만사가 흉하다. 
    술유신(戌酉申)은 양의 퇴여격이므로 반가(返駕)라 부른다. 신유(申酉)는 금기숙살(金氣肅殺)의 지(地)가 되고, 유(酉)는 술(戌)에 향하지 않고 신(申)에 향하므로 반가라고 부른다. 술(戌)은 형무소의 신(神)이고 유신(酉申)은 출옥의 형상이기 때문에 형기가 차기 전에 출옥할 수 있다. 일간상신(日干上神)인 오(午)는 녹(祿)으로 되고 음신인 사(巳)는 왕(旺)으로 되나 공망한다. 중전․말전의 금(金)은 일간의 처재(妻財)로 되어 나의 용(用)을 이룬다. 술(戌)이 해(亥)의 지(地)에 임하면 관격(關格)이 되어 모사(謀事)나 출입에서 모두 조격(阻隔)을 초래한다.
    백법에 이르기를 ｢간묘관병(干墓關倂)이 되면 인택이 쇠퇴하는 징조가 있고, 천후(天后)가 합승하며, 점혼(占婚)하면 자유 결혼이다｣고 하였다. 비전에 이르기를 ｢출산을 정단(正斷)할 때는 해(亥)를 머리로 하고 술(戌)은 발로 하는데, 술(戌)이 위에 있고 아래에 해(亥)가 있다. 위에서 아래를 극하는 것은 남아이고, 머리와 발이 거꾸로 있어 도생(倒生)할 두려움이 있는데 이를 속(俗)으로 역산(逆産)이라고 한다.`
    ,
    // 3
    ` 이 과는 4과 중에 상하에 상극이 없고 제3과 상신(上神)인 유금(酉金)을 일간에서 요극(遙剋)하여 탄사(彈射)로 된다. 3전 유미사(酉未巳)는 음의 역간전격(逆間傳格)이다. 중전, 말전이 공망하므로 전(傳)에 돌지 않아 유미사(酉未巳)는 여명격(勵明格)이라 한다.
    초전의 유(酉)는 일몰(日沒)이고 미(未)와 사(巳)는 노력 향상하여 스스로 분발한다고 하여 이 이름이 붙었다. 모사․망사에서 노력에 의하여 효과가 있다. 사업의 운명은 호전된다. 그러나 공망하여 반감(半減)한다. 이 과는 허일대용(虛一待用)으로 된다. 즉 초전이 유(酉)이고 말전은 사(巳)인데 축(丑)이 와서 삼합하기 때문에 축(丑)의 한 신을 기다려 화합․형합․단결한다. 이 이법(理法)에 따라 어느 삼합이나 부족한 일신(一神)의 지지가 와서 목적이 달성된다.
    요극탄사과(遙剋彈射課)는 비스듬히 쏘는 형상이니 길이나 흉이 모두 미약하다. 일지상신(日支上神)인 유(酉)가 초전으로 되고 말전의 사(巳)가 일간 상에 있으면 지(支)에서 전하여 일간 상으로 돌아가서 조원격(朝元格)이라 부른다. 무슨 일에서나 타인이 와서 나에게 청구하는 형상이다. 백법에 이르기를 ｢전(傳)으로 돌지 않는 것은 초시(初時)를 생각하라｣고 하였다. 일지에서 보아 일간상신인 사(巳)가 해일(亥日)의 역마로 되면 사물이 갈림길에 있다. 일귀(日貴)는 순행(順行)하여 3전이 음․상․공이고, 야귀(夜貴)는 3전이 귀․음․상이다. 태음은 가만히 돕는 신이므로 귀인의 암조(暗助)를 받는다. 초전의 유(酉)는 둔간하고 을(乙)의 인수(印綬)는 내몸을 도와 재를 소망하면 반드시 소득한다.`
    ,
    // 4
    `  이 과는 4과 중 제4과의 사신(巳申)이 위에서 아래를 극하여 초전으로 되는 원수원태격(元首元胎格)이다. 원수(元首)는 하늘에 모양을 따서 순정(順正)하고 헝클어지지 않는다.
    점사(占事)는 밖으로부터 발생하고, 남자의 일을 제과(諸課)의 으뜸으로 한다. 하늘을 본뜬 것은 상위(上位)에 관한 동용(動用)이다. 정단할 때는 초전을 상세히 하고 다음에 일간․일지상신의 신장(神將)을 명확하게 하여야 한다. 년명을 살피고 다음에 중전․말전과 4과의 길흉성쇠․생극․묘충합(墓冲合)․형파(刑破)․공망 등을 다시 살피는 것이 관점법의 원칙이다. 기타의 과에서도 시종 이 원칙을 잊지 말아야 한다. 말 그대도 연마하고 숙습해야 한다.
    이 과에서 초전 사(巳)는 역마로 되고 말전 해(亥)는 정신(丁神)이 타므로 시종 활동을 주지(主旨)로 한다. 그 위에 일간상신 진(辰)은 탈기로 되고 청룡이 타서 경사가 있으나 출재(出財)는 면치 못한다. 백법에 이르기를 ｢귀(鬼)와 천을(天乙)이 동병(同倂)하면 신기격(神祗格)이다｣고 하였다. 비전에 이르기를 ｢일간상신인 진(辰)에 청룡이 타서 일간을 생하면 중앙정부에서 일하다가 후에는 외국에 부임한다｣고 하였다. 그러나 행년(行年)이 쇠하는 세년(歲年)에 돌면 휴직․퇴직하는 일이 일어난다. 또는 세(歲)에서 극을 받을 때에도 같다. 3전이 체생으로 되고 초전에 역마가 타면 남의 추천에 의하여 동의(動意)가 생긴다. 일귀는 순행하여 삼전이 공․합․귀이고, 야귀(夜貴)는 삼전이 상․청․주이다. 낮과 밤 모두 천장은 길신이 되므로 공명․구직․입시․선거에서 모두 양호하다.`
    ,
    // 5
    `  4과 중 제3과는 위에서 음의 극뿐이므로 섭해과(涉害課)로 되고 맹신(孟神)의 극이다. 미해(未亥)를 초전으로 하기 때문에 곡직불비격(曲直不備格)을 이룬다. 그러나 4과 동일의 3합은 불비격을 취하지 않는 것이 원칙이다.
    삼합(三合) 목국(木局)은 일간을 생하고 사람은 역량을 얻는다. 일지 해(亥)는 3전에 설기하므로 집이 좁다. 4과 상신만이 3전을 구성하는 것은 회환격(廻環格)이다. 즉 3전 4과를 떠나지 않으므로 길흉이 모두 구태를 반복한다. 그러므로 흉을 정단하면 흉으로 되고 길을 관점하면 역시 길조를 얻는다. 섭해과로서 목기(木氣)가 임립(林立)하여 있으므로 모든 일에서 곡절파동이 있다. 가급적 옛 것을 지킬 때는 길하고 망동은 불길하다.
    백법에 이르기를 ｢3전은 일간을 생하고 일지는 3전에 탈설(脫泄)하기 때문에 가족은 가득차는데 거택은 좁다｣라고 하였다. 비전에 이르기를 ｢3전 묘해(卯亥)는 정양(正陽)이라 하여 전도가 있을 징조가 나타나며 삼합목국(三合木局) 중에 공망이 승하는 것은 부목(腐木)으로 기재(器材)가 되지 못한다｣라고 하였다. 천지반 중 사묘(四墓)가 사맹상신(四孟上神)에 가해지면 구사구태(舊事舊態)가 복기(腹起)하여 유리하다. 일귀는 순행하여 3전이 상(常)․구(勾)․귀(貴)이고, 야귀는 3전이 음(陰)․공(空)․주(朱)이다. 주작이 일귀(日鬼)로 되고 정신(丁神)이 승하면 무슨 일에서나 불평불만․구성시비를 방비해야 한다. 초․중전이 공망되므로 말전의 한 신으로 길흉을 정단한다.`
    ,
    // 6
    `  이 과는 제2과와 제3과에 상하의 극이 있고, 아래에서 위를 극하므로 오해(午亥)를 취하여 초전으로 하는 중심과(重審課)이다.
    일간일지는 인해(寅亥)가 지합하고 미(未)와 오(午)와 지합하며, 교차육합(交車六合) 중에 오미(午未)의 공망이 있으며 일간에서 보아 오(午)는 건록이 공망한다. 일간상신인 인(寅)은 인수로 되고 둔간하여 경(庚)의 재(財)로 되며 다만 구재에 좋다. 백호가 오(午)에 승하여 공망으로 되고 현무도 역시 그러하므로 흉해를 보아도 가볍다. 천지반(天地盤)에 자사(子巳)가 겹치면 사절격(四絶格)으로서 구사(舊事)를 종결짓는데 좋다. 건록이 공망하므로 봉록(俸祿) 수익이 떨어진다. 년명상신 오(午)는 공망이 해소되어 오히려 물재(物財)가 모두 풍부하다. 
    백법에 이르기를 ｢지상(支上) 건록은 권섭부정(權攝不正)이 되고 천장(天將)이 내전(內戰)을 만나는 것은 모사에서 위험하며, 초전이 오(午)이고 기궁(寄宮)이 미(未)이며 말전이 신(申)이면 전후인종격(戰後引從格)인데 두가지 법이 있다｣라고 하였다. 일간의 앞의 지가 초전에서 인종(引從)으로 되고 미(未)가 간(干)의 후지(後支)에 있어서 종(從)으로 된다. 이 격을 만나면 관직이 승진한다. 또 이 격은 가택을 수조하여 옮긴다. 이상의 두 사항은 모두 길하다. 초전․중전이 공망하고 말전에 신(申)이 있으면 재동(財動)하여 실(實)로 되고, 처음에는 공허하여 힘이 없으나 후에 재리를 얻는다. 일귀는 순행하여 3전이 백(白)․주(朱)․현(玄)이고, 야귀는 현(玄)․구(勾)․후(后)이다. 현무가 승하면 실재(失財)하는 일이 있다. 천후(天后)가 승하면 재의 은혜를 입는다. 천장에 따라 정단해야 한다.`
    ,
    // 7
    `  이 과는 천지신이 대충(對冲)되는 자리에 있다. 제3과에 극이 있어 1하(下)가 1상(上)을 적(賊)하는 사해(巳亥)를 초전으로 하고 3전이 사해사(巳亥巳)인 반음과(返吟課)이다.
    대체로 반음과는 반복적으로 변천하므로 만사가 미정이다. 사해사(巳亥巳)가 서로 가해지면 중하게 되는데 구(求)는 하지만 얻는 것은 적다. 사(巳)는 쌍녀(雙女), 해(亥)는 쌍어(雙魚)라 부르며, 무슨 일이나 겹쳐 일어난다. 사(巳)는 역마이고 해(亥)는 정신(丁神)이다. 정마(丁馬)가 타서 변동 기복이 중첩된다. 길흉화복은 단독적인 일이 아니고 여러 갈래에 걸치는 것이 보통이다. 말전은 순미(旬尾)의 사(巳)가 초전으로 되어 폐구격(閉口格)이 되므로 말을 삼가하면 화환을 면한다. 소송시․관사(官事)는 더욱 그러하다. 일간은 축(丑)을 보아 탈기로 되고, 태음이 승하면 축(丑)에서 탈기로 된다. 이와 같은 것은 탈상탈(脫上脫)을 만난다고 하여 허기부실(虛期不實)한 일이 많다.
    백법에 이르기를 ｢귀(鬼)에 천을(天乙)이 승하면 신기격(神祗格)이다｣라고 하였다. 탈상탈격(脫上脫格)은 백법칙(百法則)의 제15법에 해당한다. 이 격으로 사건을 정단하면 소모가 많고 모두 허공부실한 징조를 암시한다고 본다. 정마가 승하여 모이면 무슨 일에서나 왕래가 있고 행동해야 하며 신속하고, 여러 갈래에 걸치게 된다. 일귀는 역행하여 3전이 공(空)․귀(貴)․ 공(空)이고, 야귀는 순행하여 3전이 상(常)․주(朱)․상(常)이다.`
    ,
    // 8
    `  이 과는 제1과․제2과에 상하의 극이 있으며 아래에서 위를 극하는 사자(巳子)를 초전으로 하는 중심과로 된다. 3전 사술묘(巳戌卯)는 주인격(鑄印格)이고, 초전 역마는 주인관작격(鑄印官爵格)으로 된다.
    술(戌) 중에 신금(辛金)이 있고, 사(巳) 중에 병화(丙火)가 있어서 합치며, 금(金)은 화련(火煉)에 의하여 귀기(貴器)를 이루기 때문에 주인(鑄印)이라 부른다. 현임관에게 가장 길하고 태산(胎産)은 대길이며, 병이나 소송사건에서는 흉하다. 일간상신 자(子)의 귀효(鬼爻)로 되지만 중전 술토(戌土)에서 제어하므로 귀(鬼)를 두려워하지 않는다. 일지는 진(辰)에 백호가 승하므로 집안에 병자가 있어 가장 흉하다. 진(辰)의 묘(墓)는 정체의 신이지만 술(戌)에서 충하므로 파묘(破墓)로 되어 삽체가 제거된다.
    일지상신 진(辰)의 전지(前支) 사(巳)를 초전으로 하고 말전 묘(卯)는 진(辰)의 후지(後支)로 되어 전후인종격(前後引從格)으로 되기 때문에 가옥을 수리하면 길조가 있다. 그러나 지상(支上)에 백호가 승하여 일지인 해(亥)를 극하므로 집안사람 또는 집에 손상된 곳이 있으니 경거망동(輕擧妄動)하지 말아야 한다.
    백법(百法)에 이르기를 ｢지(支)가 묘호(墓虎)에 임하면 복시(伏屍)가 있고 일간일지상신에서 극하면 피차에 모두 손실이 있으며 제귀(制鬼)의 위(位)에는 양의(良醫)가 있다｣라고 하였다. 비전(秘傳)에 이르기를 ｢술(戌)에 양사(兩蛇)가 승하고 진(辰)에 양호(兩虎)가 승하여 협묘(夾墓)로 되고, 백호가 택(宅)에 들어가고 지(支)에 묘(墓)가 가해지는 것은 생사를 정단하면 3일 안에 죽는다｣라고 하였다. 주인관작격이면 현임관은 진급하고, 벼슬을 구하면 임관할 수 있다. 일귀는 역행하여 3전이 공(空)․사(蛇)․상(常)이고, 야귀는 순행하여 3전이 상․사․공으로 된다.`
    ,
    // 9
    `  이 과는 제1과․제4과에 상하의 극이 있고 아래에서 위를 극한다. 제4과 미묘(未卯)를 사용하여 초전으로 하고 중심곡직격(重審曲直格)이 된다. 3전4과가 모두 목(木)국이다. 그리고 초전․중전은 공망된다. 야귀는 말전에 천공이 타므로 3전이 모두 공(空)으로 된다. 3전이 4과를 떠나지 않으면 회환격(廻環格)으로 되며, 일지가 간상에 와서 일간을 극하므로 상문난수(上門亂首)라고 부른다. 즉 그가 와서 나를 상하기 때문에 경계해야 한다.
    3합목국(三合木局)은 일간을 생하며 일지의 해(亥)는 수생목(水生木)하고 삼전에 탈기하므로 사람은 많으나 가택이 좁다. 일지상신 묘(卯)가 내몸을 생하고 태상이 승하면 혼인의 기쁨 혹은 주식연회(酒食宴會) 등이 있고, 음식업은 장래성이 있다. 삼전곡직(三傳曲直)은 대재(大材)이지만 공망되면 양재양기(良材良器)를 이루기 어렵고 길흉이 모두 이루어지지 않는다. 만사에서 옛 것을 지키고 책모(策謀), 계획 등은 보류해야 한다.
    백법(百法)에 이르기를 ｢일야귀신(日夜貴神)이 승하는 천반을 지반에서 극하면 두 귀가 극을 받아 윗사람의 원조를 얻기 어렵다｣라고 하였다. 귀(鬼)에 천을(天乙)이 승하면 신기격(神祗格)으로 된다.  비전에 이르기를 ｢미(未)에 구진(勾陳)이 승하면 토지․가옥 등의 다툼이 일어난다. 곡직격(曲直格)은 길격이지만 공망되므로 충분히 때를 기다려 활동에 옮겨야 한다｣고 하였다.
  `
    ,
    // 10
    `  이 과는 4과 중에 상하의 극도 요극(遙剋)도 없으므로 묘성과(昴星課)로 된다. 음일은 천반(天盤) 유(酉)의 하신(下神)을 취하여 초전으로 한다.
    천반 유(酉)의 아래는 오(午)이기 때문에 오묘(午卯)를 초전으로 하고 중전은 일간상신, 말전은 일지상신으로 3전을 구성한다. 즉 음양을 교차로 사용한다. 초전 오(午)가 묘(卯)에 가해지면 명당(明堂)이라 부르며, 을목(乙木)은 오(午)를 보고 장생(長生)으로 되어 흉장이 타도 길로 화한다. 묘성과는 형상이 움직이지 않고 변하며 과체는 음에 속하기 때문에 관사(官事)․교도소․질병 등을 싫어한다.음일은 동사엄목격(冬蛇掩目格)으로 되어 더욱 흉하다.
    초전의 오(午)는 건록(建祿)이지만 공망된다. 청룡, 육합이 승하고 목장(木將)은 화(火)를 생하며 화국(火局)은 간상의 술토(戌土)를 생한다. 일지상신 인(寅)은 경(庚)의 재(財)가 둔간으로 되고 토(土)는 이를 생하여 구재는 순조롭다. 일간일지가 모두 천신(天神)을 생하므로 허비가 연속 나타난다. 
    초전천반이 공망하면 과숙(寡宿)으로 되어 이별의 염려가 있고 인(寅) 위에 백호가 승하면 사호(四虎)가 와서 가축에게 재화를 끼친다. 
    백법(百法)에 이르기를 ｢피난도주생격(避難逃走生格)은 옛 것을 버려야 한다｣라고 하였다. 백법 제9법에 이르기를 ｢피난불능격(避難不能格)은 일간상신에 묘신이 덮여 초전의 길에 의지하지만 공망되며, 말전 인(寅)은 화(火)의 장생이나 백호가 승하여 거택에 재(災)․병의 징조가 있으니 편안하게 살 수 없다. 만일 일간상신 또는 년명상신(延命上神)이 월장(月將)의 신이면 난을 피할 수 있다｣라고 하였다.`
    ,
    // 11
    `  이 과는 4과 중에 상하의 극이 세 곳에 있다. 제1과의 하에서 상을 극하는 유미(酉未)를 사용하여 초전으로 하는 중심간전과(重審間傳課)로써 응음격(凝陰格)이다.
    해축(亥丑)은 동월로서 서리를 밟고 견빙(堅氷)에 이르는 형상이다. 음은 응결되어 견빙에 이르니 옛 것을 떠나서 새로운 것을 따른다는 것이다. 음사(陰私)의 염(念)을 석명(釋明)하고 정대(正大)한 일을 하는 격이다. 초전은 간상에서 시작되고 말전은 지상으로 돌아간다. 내가 그에게 간청한다는 뜻으로서 남보다 한층 떨어진다. 유(酉)는 여자 사용인의 유신이다. 그러므로 사용인과 정이 통하여 처로 삼고, 색정의 재앙이 발생한다. 말전의 토(土)는 유(酉)의 재를 생하여 점혼 및 구재(求財)의 소강(小康)을 얻는다. 간전격(間傳格)에서는 진행 중에 장애가 생기고, 어떤 일이나 명확하지 못하다.
    백법에 이르기를 ｢일야의 양귀(兩貴)가 3전4과 중에 있으므로 양귀인의 도움을 바란다｣라고 하였다. 간(干)에서 지(支)로 전하면 내가 그에게 소원하는 바가 있고 귀(鬼)에 천을(天乙)이 승하면 신기격(神祗格)이다. 비전에서는 ｢유(酉)의 재(財)에 파쇄(破碎)가 승한다｣고 하였다. 말전인 축(丑)에 태음이 승하는 것은 음회(陰晦)의 형상이다. 그러므로 색정 때문에 죽을 우려가 있다.
    일귀는 역행하여 3전이 주(朱)․귀(貴)․음(陰)이며, 야귀는 삼전이 귀(貴)․음(陰)․상(常)이다. 초전이 장생(長生)이라 해도 공지(空地)에 앉으니 이익이 없다. 점인(占人)의 년명상신에 술(戌)이 승하면 양귀(兩貴)에 끼여서 만사가 길조로 변화된다. 즉 귀인의 원조를 받게 된다.`
    ,
    // 12
    `  이 과는 4과 중에 제1과가 화극금(火剋金)으로 아래에서 위를 극하기 때문에 신미(申未)를 초전으로 한다. 3전 신유술(申酉戌)은 서방일기(西方一氣)의 연여격(連茹格)이다. 정(丁)에서 보아 서방금기(西方金氣)는 재동(財動)으로 기뻐할 일이지만 일지는 상하(上下) 자해(子亥)의 수귀(水鬼)를 생하므로 구재에서 과욕하면 도리어 재 때문에 흉해가 일어나므로 신중하여야 안전하다. 즉 말전의 술(戌)과 제4과의 음신(陰神) 축(丑)에서 살(殺)을 제어하므로 해(亥)는 해제된다. 신유술(申酉戌)은 유금격(流金格)이라 하여 서리내린 다리 위를 달리는 격이다. 불의의 재액을 만날 수 있으므로 주의하여 피면하여야 한다.
    일지상신이 귀살로 되면 우환이 집안에서 발생한다. 혹은 집안 사람이 나에게 원한을 품고 해를 끼치려 한다. 백법에 이르기를 ｢일간 일지상신에 후(后)가 합승하여 중매없이 혼약을 맺는 즉 자유결혼의 징조이다. 전재(全財)가 병체(病體)로서 짐을 짊어지기 어렵다｣고 하였다. 비전에 이르기를 ｢묘유(卯酉)의 상신에 진술(辰戌)이 가해지고 사(蛇)․호(虎)가 승하면 가정에 사상(死喪)이 생길 우려가 있다｣고 하였다. 일귀는 역행하여 3전이 합(合)․주(朱)․사(蛇)이고, 야귀는 3전이 사(蛇)․귀(貴)․후(后)이다. 초전의 신(申)은 순수(旬首)이고 육의(六儀)이지만 공망의 지(地)에 앉아서 구재 외에는 공허한 기쁨이 있을 뿐이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
