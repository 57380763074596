
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,7,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,8,6,`특별있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,9,5,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`위선`,10,4,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,11,3,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,2,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,1,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,2,12,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`설득`,3,11,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`위선`,4,10,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,5,9,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,8,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,`사사성취`,`부부화합`],
    [`색정`,`외남 색정사有`,`大`,`단절기`,`손해도망`,`夜起散髮`],
    [`공후재발(恐後再發)`,`두려운 일이 재발하는 상-혼인사는 좋음`,`大`,``,``,``],
    [`산발경의(散髮驚疑)`,`밤에 놀라운 의혹사 또는 재물 잃은 근심사를 당하는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남자 갈등사`,`大`,`8`,`동요분산`,`가정막힘`],
    [`여식`,`여식 문제사`,`小`,`침체기`,`미초희경`,`불의투서`],
    [`불리구재(不利求財)`,`금전 구하는 것은 불리한 상-상위자는 좋은 일 있게 됨`,`小`,``,``,`헌책실재`],
    [`불의헌책(不宜獻策)`,`급한 문사의 투서헌책은 좋지않은 상--失財`,`大`,``,``,``],
    [`후원`,`후원조력사`,`大`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`9`,`침체절감`,`타인압박`],
    [`급속`,`외사 급속사`,`小`,`소외기`,`관재형흉`,`재난전소`],
    [`동좌비녕(動座非寧)`,`가나 있으나 놀랄 일을 당하는 상`,`大`,``,``,``],
    [`부처반목(夫妻反目)`,`부부 피차 무례 대립의 상`,`小`,``,``,``],
    [`후원`,`후원조력사`,`大`,``,`가실불화`,``],
    [`문서`,`문서 관련사`,`小`,`10`,`외사결속`,`타인압박`],
    [`결속`,`음8월 개신사 완성해 大財를 획득하나 부모災害 발생함`,`小`,`침해기`,`복잡다단`,`음사폐호`],
    [`기피원행(忌避遠行)`,`출행원행 不歸 우려의 상`,`大`,``,`음8월`,`가택안녕`],
    [`복통수욕(腹痛受辱)`,`복통설사로 망신스러운 상-모사(謀事)는 좋음`,`小`,``,`大財획득`,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`11`,`비용과출`,`파재극처`],
    [`자식`,`자식 관련사`,`小`,`쇠퇴기`,`환성경사`,`실종우려`],
    [`수재위왕(守財爲旺)`,`금전을 지켜야 하는 상-함부로 움직이면 숨긴 칼을 만남`,`小`,``,``,`도적失勢`],
    [`가실불화(家室不和)`,`부부 불화 분쟁의 상`,`大`,``,``,``],
    [`형제`,`형제자매관련사`,`大`,``,``,``],
    [`손모`,`비용과출사`,`大`,`12`,`수혜사有`,`수혜사有`],
    [`이동`,`외사 출행이동사`,`小`,`과시기`,`존비상몽`,`사송계류`],
    [`모친종상(母親終喪)`,`외종호사(外終戶死)-바깥에서 집안으로 모시는 상`,`小`,``,`(신분나이)`,``],
    [`이어진취(利於進取)`,`모든 재난을 극복하고 이득되는 일에 매진하는 상`,`小`,``,`차이불륜)`,``],
    [`직녹`,`직위승급사`,`大`,``,``,``],
    [`외합`,`외 상하화합사`,`大`,`1`,`상하화합`,`손해가출`],
    [`색정`,`가정색정사-손해가출-인도에 어긋난 취처도발임`,`小`,`절정기`,`동지함의`,`身物受損`],
    [`병송개기(病訟皆忌)`,`병과 송사 모두 주의해야 하는상`,`小`,``,`(가나있으`,``],
    [`복통수욕(腹痛受辱)`,`복통설사로 망신스러운 상-모사(謀事)는 좋음`,`小`,``,`나좋음)`,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`2`,`외교막힘`,`동요분산`],
    [`결속`,`음8월 개신사 완성해 大財를 획득하나 부모災害 발생함`,`小`,`면모기`,`이득진취`,`복통受辱`],
    [`망재귀채(亡財鬼債)`,`돈을 잃고도 채무로 시달리는 상`,`小`,``,`음8월`,``],
    [`질병관재(疾病官災)`,`질병관재구설이 침범하는 상`,`大`,``,`대재획득`,``],
    [`금전`,`금전상업이권처 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`3`,`타인시혜`,`타인시혜`],
    [`매사 영속성없음`,`금전사업이권처첩사는 선천적기복재해를 겪음`,`大`,`학습기`,`재난전소`,`관재형흉`],
    [`통어경공(通語驚恐)`,`서로 말을 주고받는 중 놀랍고 두려움을 갖게되는 상`,`小`,``,``,``],
    [`신물수손(身物受損)`,`몸이나 물건이 없어진 상`,`大`,``,``,``],
    [`금전`,`금전사업이권여처첩 관련사`,`小`,``,``,``],
    [`매사 허성 불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`4`,`타인시혜`,`개신개변`],
    [`매사 영속성없음`,`금전사업이권처첩사는 선천적기복재해를 겪음`,`大`,`개시기`,`간사은닉`,`폭객欺凌`],
    [`역흠리야(亦欠利也)`,`역시 이익은 완전하지 않고 흠이 있게되는 상`,`小`,``,``,``],
    [`간사은닉(奸邪隱匿)`,`간사(奸事)를 숨기는 상-조행번뇌, 가택이사의 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,`관직자`],
    [`극부`,`남편 갈등사`,`大`,`5`,`급속쟁송`,`생살대권`],
    [`자식`,`자식 관련사`,`小`,`배양기`,`파재실종`,`쥠`],
    [`설상보행(雪霜步行)`,`눈 서리 내린 길을 걷는 상`,`小`,``,`상해우려`,`일반`],
    [`총시성재(總是成災)`,`이리보고 저리봐도 모두 재해뿐인 상`,`大`,``,``,`형벌흉`],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,`타인압박`,`타인압박`],
    [`원행`,`내사 원행이동사`,`大`,`존재기`,`투서헌책`,`진직영전`],
    [`기동외수(豈動畏遂)`,`어찌 움직이려 하는가-의 상-관직자는 두려운 일이 끝남`,`大`,``,`구설사`,``],
    [`진직천관(進職遷官)`,`승진영전의 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`관사직업색정사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`직업직위불안부부갈등여식문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`후원문서부친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`후원문사모친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,``,`직업직위불안부부갈등자식문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`형제자매친구동료조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`직위승급색정화합사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,``,`직업직위불안남편남자갈등여식문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`세속허가자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`직업직위부부갈등자식문제로불가`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`관사직업남편원행사로불가`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 반발하지 말고 순응유화적 처신으로 사안을 뒤로 미루면 나중에 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 순응하고 유화적 언행을 취하며 종결을 후로 하면 결과가 유리하게 도는 人`,0,0,`물러나야 하는 사안이고 退하는 것이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 반발하지 말고 순응유화적 처신으로 사안을 뒤로 미루면 나중에 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 순응하고 유화적 언행을 취하며 종결을 후로 하면 결과가 유리하게 도는 人`,0,0,`일이 띄엄띄엄 역조되어 退해야 할 사안이나 되려 계속 진행하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)-속임**만약 타인과 다툰다면 본인이 타인의 눈을 가리는 비밀을 감추고 있으므로 전면전을 피해야 함`,0,0,`현재 진퇴사에 고민하고 있고 거처가 불편하며 죄인을 감추고 있거나 본인이 범법한 처지의 人`,0,0,`속이는 일이 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 서둘러 종결해야만 유리함`,0,0,`정정당당하게 먼저 서둘러 종결해야만 유리하고 늦어지거나 미루면 되려 불리해지는 人`,0,0,`여러가지 사안을 하나로 묶음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 반발하지 말고 순응유화적 처신으로 사안을 뒤로 미루면 나중에 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 순응하고 유화적 언행을 취하며 종결을 후로 하면 결과가 유리하게 도는 人`,0,0,`지난 일은 모두 끓기게 됨**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있어 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`재난이 밖에서 오고 육친관계가 빙탄(氷炭)같은 처지의 人`,0,0,`일을 직접하면 안 되므로 타인을 시켜 우회적으로 해야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 반발하지 말고 순응유화적 처신으로 사안을 뒤로 미루면 나중에 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 순응하고 유화적 언행을 취하며 종결을 후로 하면 결과가 유리하게 도는 人`,0,0,`권리행사를 하게 됨**신분이 높아지는 상 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 반발하지 말고 순응유화적 처신으로 사안을 뒤로 미루면 나중에 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 순응하고 유화적 언행을 취하며 종결을 후로 하면 결과가 유리하게 도는 人`,0,0,`여러 사안을 하나로 묶는 개신개혁을 시도하나 불성함**일이 돌발적으로 나타남**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)-속임**만약 타인과 다툰다면 본인이 타인의 눈을 가리는 비밀을 감추고 있으므로 전면전을 피해야 함`,0,0,`현재 진퇴사에 고민하고 있고 거처가 불편하며 죄인을 감추고 있거나 본인이 범법한 처지의 人`,0,0,`속이는 일이 있음**어디 깊은 산속으로 숨고싶은 심정을 갖게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 반발하지 말고 순응유화적 처신으로 사안을 뒤로 미루면 나중에 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 순응하고 유화적 언행을 취하며 종결을 후로 하면 결과가 유리하게 도는 人`,0,0,`일이 띄엄띄엄 역조되어 退해야 할 사안이나 되려 계속 진행하게 됨-결과 무익함**육친무력함**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 반발하지 말고 순응유화적 처신으로 사안을 뒤로 미루면 나중에 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 순응하고 유화적 언행을 취하며 종결을 후로 하면 결과가 유리하게 도는 人`,0,0,`일이 물러나야 할 상황이나 되려 계속하게 되지만 결과가 매우 나쁨**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**스스로 유약하여 나서지 못하고 신음하는 격**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`강제성에 의해 신체가 부자유한 경우도 많음`,0,0,`토지사에 관련되어 송쟁사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인-단 옳고정중함`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`미사`,`신구중간사`,`돌발사`,`눈을 가리듯숨기는 사안이므로 적시가불가함`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성무력`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면-무력함`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`미사`,`신사`,`돌발사`,`가족사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성-무력함`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`기사`,`신구중간사`,`돌발사`,`눈을 가리듯숨기는 사안이므로 적시가불가함`,`배신`,`보성-무력함`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`신사`,`외시`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`기사`,`구사`,`내사`,`표면사-급속사 겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-반목대립송사有`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-동요불안분산`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-실종사우려`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`패`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`패`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-이동이사함`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,` 무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음4월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음5월-연기 또는 파혼우려`,2,1,3,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월-연기 또는 파혼우려`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음5월`,2,1,3,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음4월`,1,1,2,`음10월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월`,1,2,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음2월`,3,3,`음8월-연기 또는 파혼우려`,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음1월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,2,2,4,`음8월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월-연기 또는 파혼우려`,2,3,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음6월`,2,3,5,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많아 정체성을 과거로 되돌리게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 업무도 허사 공허로 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 도움을 받으나 업무 전반이 일반 이하 수준으로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 개신개혁을 시도하나 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 상대를 잘 조정하는 능력을 갖고 있지만업무가 일바에 미치지 못함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 초반의 명예와 실적이 외부여건으로 인해 수준 이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실 면이 있으나 정체성의 권위를 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 개혁을 명분삼으나 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 초반의 명예와 실적 외 중종반의 실적은수준 이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 업무는 수준 이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하며 초중종반의 실적이 없게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 업무는 토지사에 의해 소송사로 번지고 실적도 수준 이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`반대많음`,`불이행`,`포기요됨`,`退背`,`원`,`留`,`화합`,`남남서`,``,` 정동`],
    [`10.11`,`多集`,`포위지경`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`연기필요`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대있음`,`불이행`,`세입가해`,` 退背`,`  원`,`  留`,`세입가해`,`북북동`,``,`동북`],
    [`10.11`,` 多集`,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`반대있음`,`불이행`,`선반목`,` 退背`,`  원`,`  留`,`반목대립`,`동북`,``,`동북`],
    [`10.11`,`多集`,`측근제압`,``,`후동요`,``,``,``,`송쟁우려`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,` 없음`,`불이행`,`進向`,`사안무력`,`  원`,`  留`,`주인무력`,`정동`,`남남서`,`정남`],
    [`7.8월`,``,``,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`불이행`,`進向`,`사안무력`,`  근`,`  留`,`주인무력`,`동남`,`정남`,`동북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음`,`불이행`,`退背`,`선막힘`,`  근`,`  去`,`상호동요`,`남남동`,`정북`,`남남동`],
    [`10.11`,` 多集`,`다수제압`,``,``,`후동요`,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,`됨`,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`있음`,`불이행`,`退背`,`선권리양`,`  원`,`  去`,`주인후원`,`정남`,`북북서`,``],
    [`10.11`,``,`중 종반`,``,``,`도후배척`,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,`제압됨`,``,``,`포기요됨`,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음`,`불이행`,`退背`,`선이후不`,`  근`,`  留`,`주인후원`,`남남서`,`정동`,``],
    [`10.11`,``,`측근제압`,``,``,`속결요됨`,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`없음`,`불이행`,`退背`,`선費후불`,`원근왕래`,`  去`,`상호파재`,`정동`,`북북서`,``],
    [`7.8월`,``,``,``,``,`안`,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,` 있음`,`불이행`,`退背`,`선이후不`,`  근`,`  去`,`주인가해`,`동남`,``,`정북`],
    [`10.11`,``,`무력`,``,``,`빠르게`,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`없음`,`불이행`,`退背`,`先滯후不`,`  근`,`  去`,`주인가해`,`남남동`,``,`북북동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`불이행`,`退背`,`선후동요`,`  근`,`  去`,`무난`,`정남`,``,`동북`],
    [`10.11`,``,``,``,``,`극복성사`,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,`수귀`,`익사`,0,0,`묘좌`,`정북`,`특별있음`],
    [``,`음귀`,`산소탈`,0,0,`묘향`,`정남`,``],
    [`생업`,``,``,0,0,`전경`,`종교시설`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,`수귀`,`익사`,0,0,`육친`,` 모친`,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`변사귀`,`객사`,0,0,`육친`,``,``],
    [`질병`,`자살귀`,`익사`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"음사부정","제사절못",`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`신부정귀`,`성소침해`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`신부정귀`,`성소침해`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`상가왕래`,`육친`,``,``],
    [`질병`,`음귀`,`묘지탈`,0,0,`묘좌`,`정동`,`없음`],
    [``,``,`당대재산`,0,0,`묘향`,`정북`,``],
    [`생업`,``,`훼손`,0,0,`전경`,`종교시설`,``],
    [``,``,``,0,0,`묘속`,`목근`,``],
    [`가정`,``,``,0,0,`육친`,`형제,5代 祖`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사 출행이동사`,`빈궁**방비부족-도적근심사`,`의혹놀램불녕-금전도난근심사`,`빈궁**男색정음사`,`외 동요분산사`,`승진영전이동사`,`내사 급속사`,`관직-생살대권-일반- 형벌대흉`,``,`빈궁**관재**무뢰폭력기만능욕사`,``,`중중희애사`,`외사 출행이동사`,`전도**관직-승진도약 일반-반흉모사`,``,`자산권리**매사완성**전도`,``,`빈궁**구설**실재*외교막힘`,`매사허성불성세속허사자`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`관계성불통-가택이동사**간사은닉`,`매사영속성없음 종교진출하면 자립함`,``,`탄신신음**내사외사급속송쟁-상해우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`재액해소`,`가정침체**전화위복`,``,``,``,``,``,`재액해소`,`음4,5월 화재조심`,``,`음4,5월 화재조심`,`女색정음사`,``,`재액해소`,`빈궁**병재`,`성공함`,``,``,`재액해소`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`빈궁**관재`,`매사위역사**내사외사급속송쟁-상해우려`,`내사 출행이동사`,`빈궁**투서헌책부작용또는 실재`,`男색정음사`,`부부남녀반목`,`외 동요분산사`,`빈궁**남모르는 능욕박해**가정침체**전화위복`,`내사 급속사`,`고위안좌-이득경영사`,``,`빈궁**무뢰폭력기만능욕사`,`형벌흉함`,`빈궁**매사위역사`,`외사 출행이동사`,`희경사`,`도적악의-공격하지 말 것`,`자산권리사**도적회심불의반공`,``,`빈궁**문화**외교막힘`,``,`매사영속성없음 종교진출하면 자립함`,`관계성불통-가택이동사**간사은닉`,`매사영속성없음 종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`전화위복`,``,``,`외 동요분산사`,``,`재액해소`,`이동이전사`,``,``,``,``,``,`재액해소`,``,`음4,5월화재조심`,``,`女색정음사`,``,`재액해소`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`관계성불통-가택이동사**간사은닉`,`매사영속성없음 종교진출하면 자립함`,`빈궁`,`매사위역사**내사외사급속송쟁-상해우려`,`내사 출행이동사`,`투서헌책부작용 또는 실재`,`男색정음사`,`부부남녀반목`,`외 동요분산사`,`빈궁**남모르는 능욕박해**전화위복`,`내사 급속사`,`고위안좌-이득경영사`,``,`빈궁**무뢰폭력기만능욕사`,`형벌흉함`,`빈궁**병재**매사위역사`,`외사 출행이동사`,`희경사-부족`,`도적악의-공격하지 말 것`,`자산권리사**도적회심불의반공`,``,`빈궁**문화**외교막힘`,``,`매사영속성없음 종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재액해소`,`전화위복`,``,`빈궁`,`외 동요분산사`,``,`재액해소`,`이동이전사`,``,``,``,``,`음4,5월화재조심`,`재액해소`,``,`음4,5월 화재조심`,``,`女색정음사`,``,`재액해소`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`빈궁**병재`,`매사영속성없음 종교진출하면 자립함`,``,`매사영속성없음 종교진출하면 자립함`,`탄식신음사**내사외사급속송쟁-상상해우려`,``,`재해모면 이익진취`,`내사 출행이동사`,`빈궁**상대 눈을가려도 효과없음`,``,`빈궁**없는구설근심**가정침체**전화위복`,`이동이전사`,`내사 급속사`,`환성기쁨사`,``,`빈궁**관재**부부남녀불화사`,`중중희애사-부족`,``,`외사 출행이동사`,`빈궁**모사 좋음**복통주의`,`병재**자산권리사**앙화소멸`,``,`관재`,`희경사**외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`도적실세-잡음`,``,``,`전도**빈궁`,``,`전도**전화위복`,`재액해소`,``,`男색정음사`,`재액해소`,`고시 불리**외 동요분산사`,``,``,``,``,``,``,`음4,5월 화재조심`,``,``,`女색정음사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`희경사**외교막힘`,``,`매사영속성없음 종교진출하면 자립함`,`빈궁**관재`,`매사영속성없음 종교진출하면 자립함`,``,`탄식신음사**내사외사급속송쟁-상해우려`,``,`재해모면 이익진취`,`내사 출행이동사`,`빈궁**관재**상대 눈을 가려도 효과없음`,``,``,`빈궁**없는구설근심**가정침체**전화위복`,`이동이전사`,`환성기쁨사`,``,`부부남녀불화사`,`중중희애사-부족`,``,`외사 출행이동사`,`빈궁**모사 좋음-복통주의`,`자산권리사**앙화소멸`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`도적실세-잡음`,``,``,``,``,``,`전화위복`,``,`男색정음사`,`외 동요분산사`,`외 동요분산사`,`고시불리`,`내사급속사`,``,``,`음4,5월 화재조심`,``,`음4,5월 화재조심`,``,``,`女색정음사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`자산권리사**몸-유실사`,``,`빈궁**송쟁계류사**외교막힘`,``,`매사영속성없음 종교진출하면 자립함`,`혼인사**관재`,`매사영속성없음 종교진출하면 자립함`,`관재**병재`,`매사위역사**내사외사급속송쟁-상해우려`,``,`재해모면 이익진취`,`내사 출행이동사`,`가나 있거나 좋음`,``,`빈궁**상하남녀귀천구분없는 난사有`,`이동이전사**동요불안분산사`,`도적을 추격했으나 못잡음`,`도적포난`,`재물유실`,``,`빈궁**병재**중중희애사-부족`,`음4,5월 화재조심`,`외사 출행이동사`,`빈궁**전도**모사 좋음-복통주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`女색정음사`,`동요불안분산사`,`재액해소`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`재액해소`,`동요불안분산사`,`전화위복`,`동요불안분산사`,`男색정음사`,``,`가정막힘**전화위복`,`재액해소`,`동요불안분산사`,`내사 강제급속손실사`,`동요불안분산사`,`음4,5월 화재조심`,`재액해소`,`동요불안분산사`,``,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`빈궁**모사 좋음-복통주의`,`외사 출행이동사`,`몸-유실사`,``,`빈궁**송쟁계류사**외교막힘`,``,`매사영속성없음 종교진출하면 자립함`,`혼인사**관재**병재`,`매사영속성없음 종교진출하면 자립함`,``,`매사위역사**내사외사급속송쟁-상해우려`,``,`재액모면 이익진취`,`내사 출행이동사`,`가나 있거나 좋음`,`외 동요분산사`,`빈궁**상하남녀귀천구분없는 난사有`,`이동이전사`,`도적을 추격했으나 못잡음`,`빈궁**도적포난`,`재물유실`,`음4,5월 화재조심`,`관재**병재**매사위역사`,`음4,5월 화재조심`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재조심`,``,`女색정음사`,`음4,5월 화재조심`,``,``,``,``,``,``,`빈궁`,``,`관재`,`재액해소`,`男색정음사`,`재액해소`,`가장침체**전화위복`,`외 동요분산사`,``,`내사 강제급속손실사`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`빈궁**병재**매사위역사`,``,`빈궁**모사 좋음-복통주의`,`외사 출행이동사`,`몸-유실사`,``,`빈궁*송쟁계류사**외교막힘`,``,`매사영속성없음 종교진출하면 자립함`,`혼인사`,`매사영속성없음 종교진출하면 자립함 `,`관재**병재`,`매사위역사**내사외사급속송쟁-상해우려`,``,`재액모면 이익진취`,`내사 출행이동사`,`가나 있거나 좋음-부족`,``,`빈궁**상하남녀귀천구분없는 난사有`,`이동이전사`,`빈궁**매사위역사`,`도적을 추격했으나 못잡음`,`재물유실`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`음4,5월 화재조심`,``,`女색정음사`,`음4,5월 화재조심`,``,``,``,``,``,``,`빈궁`,``,`전화위복`,``,`男색정음사`,`외 동요분산사`,`가정침체**전화위복`,`외 동요분산사`,``,`내사 강제급속손실사`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재물유실`,``,`빈궁**병재**매사위역사`,``,`빈궁**모사 좋음-복통주의`,`외사 출행이동사`,`몸-유실사`,``,`빈궁**송쟁계류사-외교막힘`,``,`매사영속성없음 종교진출하면 자립함`,`혼인사`,`매사영속성없음 종교진출하면 자립함`,`관재**병재`,`매사위역사**내사외사그속송쟁-상해우려`,``,`재액모면 이익진취-부족생살대권`,`내사 출행이동사`,``,`가나 있거나 좋음-부족`,`상하남녀귀천구분없는 난사有`,`빈궁**이동이전사`,`도적을 추격했으나 못잡음`,`빈궁**도적난포`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`음4,5월 화재조심`,``,`女색정음사`,`음4,5월 화재조심`,``,``,``,``,``,``,`빈궁`,``,`전화위복`,``,`男색정음사`,`외 동요분산사`,`가정막힘**전화위복`,`외 동요분산사`,``,`내사 강제급속손실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내서급속사`,`관직-생살대권 일반-형벌대흉`,``,`무뢰폭력기만능욕사`,``,`중중희애사`,`외사 출행이동사`,`빈궁**무뢰폭력기만능욕사`,``,`자산권리사**매사완성`,``,`빈궁**고설**실재**외교막힘`,`재해창궐`,`매사영속성없음 종교진출하면 자립함`,`관계성불통-가택이동사**간사은닉`,`매사영속성없음 종교진출하면 자립함`,``,`탄식신음**내사외사급속송쟁-상해우려`,`내사 출행이동사`,`빈궁**방비부족-도적근심사`,`의혹놀램불녕-금전도난근심사`,`빈궁**실재(女)근심사`,`외 동요분산사`,`승진영전이동사**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`빈궁`,``,``,`음4,5월 화재조심`,``,`음4,5월 화재조심`,`女색정음사`,``,``,``,``,``,``,``,``,`전화위복`,``,`외 동요분산사`,`男색정음사`,``,`가정침체**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외 동요분산사`,`승진영전이동사`,`내사 급속사`,`관직-생살대권 일반-형벌대흉`,``,`무로폭력기만능욕사`,``,`중중희애사`,`외사 출행이동사`,`관직-승진도약 일반-반흉`,``,`자산권리사**매사완성`,``,`빈궁**구설**실재**외교막힘`,`재해창궐`,`매사영속성없음 종교진출하면 자립함`,`관계성불통-가택이동사**간사은닉`,`매사엉속성없음 종교진출하면 자립함`,``,`탄식신음**내사외사급속송쟁-상해우려`,`내사 출행이동사`,`방비부족-도적근심사*빈궁`,`의호놀램불녕-금전도난근심사`,`빈궁**실재(女)근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`가정침체**전화위복`,``,``,``,`빈궁`,``,``,`음4,5월 화재조심`,`빈궁`,`음4,5월 화재조심`,`女색정음사`,``,`빈궁`,``,``,``,``,``,``,``,`전화위복`,``,`男색정음사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`의혹놀램불녕-금전도난근심사`,`빈궁**실재(女)근심사`,``,`승진영전이동사`,`내사 급속사`,`관직-생살대권 일반-형벌대흉**빈궁`,``,`빈궁**무뢰폭력기만능욕사`,``,`중중희애사`,`외사 출행이동사`,`관직- 승진도약 일반- 반흉**빈궁`,``,`자산권리사**매사완성`,``,`빈궁**구설**실재**외교막힘`,`매사허성불성 세속허사자`,`음3,6,7,8,9,12월 방문자 종교진출하면`,`매사허성불성 세속허사자`,`음3,6,7,8,9,12월 방문자 종교진출하면`,``,`탄식신음**내사외사급속송쟁-상해우려`,`내사 출행이동사`,`빈궁**전도**방비부족-도적근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`男색정음사`,`외 동요분산사`,`가정침체**전화위복`,``,`재액해소`,``,`재액해소`,``,``,`음4,5월 화재조심`,``,`음4,5월 화재조심`,`女색정음사`,``,``,`재해창궐`,`성공함`,`가택이동사**간사은닉`,`성공함`,``,``,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 땅에 송사가 붙었네? 
꽉 막혔는데 의욕을 잃었겠어! 그래도 하늘이 도와서 나중에 살길이 열려!
`,`■ 아무리 붙잡아 보았댔자 좋은 것이란 없겠어!
⊕도로귀ㆍ신부정귀ㆍ가택    음사부정, 
⊖수귀ㆍ음귀 때문인데 
 그걸 모르는게 불행이야!

⊕신변상가부정이 들었어 !
`,`■ 일이 갑자기 일어나서
   이익을 가져오겠네?

⊕신부정귀, 
⊖변사귀를 달래고 일을 
 사적으로 접근하면 대길하겠어!
`,`■ 굴 하나를 놓고 두 범이 싸우는 격일세? 진퇴양난, 거처불안, 내부재난이 겹쳤어!
⊕도로귀, 
⊖자살귀 침범 때문이네!

⊕신변목기부정침범
⊖신변상가부정이 들었어!
`,`■ 돈이 여기저기, 이일 저일에 널렸으나 八月이라야 완성 되겠어 ! 하나로 개혁을 해야 하겠는데 여자가 웃음 속에 칼을 품고 있겠어!
`,`■옛일,옛인연이 다했어! 申일, 申월에 다시 시도해보면 기회를 잡을 수 있을거야!
`,`■ 상하 부자간의 불화로 심신이 불안하고 마음이 두가지로 갈려저 있네? 
일을 직접하지말고 남을 앞세워 속히 끝내야 돼 !!

⊕신부정귀, 
⊖변사귀를 없애고!
`,`■상급인:도장도 새기고
  바퀴도 달았으니 크게
  한번 나서 봐야지?
  하급인:일이 지체되고 늦    겠어 !
단,⊕여귀, 
   ⊖자살귀를 해소하고 나면       좋겠네 !
`,`■ 옛것을 고쳐서 새것으로 만들겠네? 금전사가 여러 사람, 여러건인데 하나로 묶어야 가을이 오면 성사가 돼!

⊕신부정귀, 
⊖음귀가 내몸을 치겠어!
`,`■ 진퇴양난, 거처곤란, 내부암매, 눈을 가리는 은닉사가 있겠어 ! 굴은 하나인데 뱀은 두 마리격이야! 가정에도⊕가택상가부정, ⊖가택목기부정이 들어서 그래!
⊕신변음사부정침범이 듬
⊖신변수망노부부정이침범
  했네 !
`,`■ 갈길은 먼데 해는 서산에 걸렸네? 
혈육이 흩어지게 생겼어! 부부도 갈라지고!

⊕신부정귀, 
⊖음귀 장난이네?
`,`■ 모든 일이 물거품이요 꿈과 같겠어!
분수를 지키고 있어야 재기의 기운이 되돌아오게 될거야!
⊖가택상가부정이 들었다

⊖신변음사부정이 들었어!`];
const YOGYEOL1 = [`■ 내 몸을 움직이면 일개 무리가 된다. 상대가 내게 조건을 붙이나 서로 묘신을 껴안고 있다. 정중동의의 상이다.
`,`■ 낮정단은 내 녹을 범이 물어가고 장래를 바라보니 귀살과 묘신 뿐이다. 끝까지 잘 안되리라.
`,`■ 사방에 귀인이 가득하니 윗사람을 만나 이익이 없다. 간지가 모두 절신을 만났으니 일을 사적으로 해야 한다.
`,`■ 서로 어두운 것 뿐이다. 단것을 빨다가 후회하리라. 괴강과 정신이 있어 마치 호랑이 등에 탄 것과 같다.
`,`■ 내가 먼저 상대에게 조금 베풀면 큰 이익으로 되돌아 올 것이다. 삼전은 공망이나 천장이 좋으니 거듭거듭 재물과 여자를 얻는다.
`,`■ 도장을 새기는데 칼끝이 부러졌다. 원하는 바는 이루기 어렵다. 일은 비록 망쳤어도 순이 끝나면 다시 도모해보라.
`,`■ 먼저 움직일 뜻이 있어 주객이 서로 만난다. 맺은 의리는 잊기 어렵고 기쁜 것이 오래된 친구 같다.
`,`■ 피차 부족한 것이 많으나 그런 연후에 화목할 것이다. 비록 사람은 공손하여도 마침내 종복의 믿음을 얻는다.
`,`■ 삼전이 관살을 생하게 되나 밤정단은 두렵지 않다. 양 귀인은 비록 상했으나 없어진 재물은 다시 일어난다.
`,`■ 간지가 다 묘신을 태웠으니 낮정단은 뱀과 범이 되어 흉재다. 두 귀인이 모두 공망이니 조그마한 도움도 없으리라.
`,`■ 내 몸에 손해와 죽은 것이 와 있으니 재물을 구하는 것은 좋지 않다.
역마를 많이 만났으니 두 귀인이 떠난다.
`,`■ 적은 재물이나 지켜야 하고 욕심내면 망하게 된다. 묘신이 재물을 도우니 자본을 다 없앤 후에 다시 필 것이다.`]
const SIBO1 = [`■凶을 凶으로 제어한다.
靜中動 兆이다.
어떤일도 他人에 의존한다.
친소를 막론하고 合한후에 다시 갈라진다.
방어능력이 부족하여 신음ㆍ고로한다. 土地, 농경소송사가 있다.
가을에 정단하면 흥기하는 人으로 본다.
현재는 폐색되었다.
`,`■가내 우환이 기하고 가인
중 가장을 속이며 원한을
품고 加害하려 한다.
교섭사는 의혹이 있어 기복이 심하다. 主가 이롭고 客이 불리하다. 
上人의 災厄이 있다. 
길흉이 內에서 야기된다. 
신중치 못하면 착오가 있게 된다. 녹을 지키라.
망동은 금물이다. 
正月에 임신사가 있다.
`,`■가내 우환이 있다.
가인중 가장을 속인다.
원한을 품고 加害하려 한다.
일을 私적으로 진행해야 吉하다. 혼자 직접나서야만 이익이 된다.
他人에게 위탁하는 것은 믿을수 없다. 
갑자기 동하고, 갑자기 퇴하게 된다. 凶이 변해 吉하게 된다.
음사를 주의하라.
해칠 마음이 있다.
`,`■⊕수망노부, ⊖음사부정이
들었다. 해소하라.
관송사가 있다.
계획사는 위험하다. 스스로 자초하여 어둡다.
변동수가 있으나, 움직이면 凶하다. 가정에 자손재액이 발생한다. 진퇴가 불명하고 기박하다.
身傷事를 예방하라!
正月에 임신사가 있다.
`,`■부부가 음사로 충돌한다.
女ㆍ婦가 위역하고
부부 共히 음탕하다.
부부이별의 징조이다. 
二心으로 財를 개혁하려 한다. 시종 부실한 사안이 발생한다. 
小利小金을 얻는 것은 재주일 뿐이다. 헛소리가 많고 실의는 적다. 
손모가 많다. 
가을에 득재한다.
금전사가 여러건이다.
`,`■계획사는 위험하다.
옛일, 옛인연은 끝났다.
모사는 달성이 안된다.
내부에서 화가 생긴다.
실직의 근심이 있고, 새로 계획을 한다. 일을 바꿔야 한다. 때를 기다리라. 옛것을 버려라.
가정에 財공망이 들어 금전의 苦가 있다.
이권을 상실한다.
`,`■他人에게 의존한다.
女ㆍ婦는 男에 헌신하고
男ㆍ夫는 女에 헌신한다.
어떤 일도 他人을 앞세워 도모해야 한다. 직접 나서면 일이 안된다.
옛것을 지켜야 한다.
上人의 화를 풀어야 한다. 빠른 성취는 바라지 말라. 망동은 재액을 초래한다.
동요불안, 의지처가 없다.
`,`■자충수적 우환이 기한다.
가택의 권리가 넘어간다.
서로 다투고 시의하다가
화목ㆍ화해하게 된다.
어떤 일도 늦게 성취된다. 
상류인ㆍ관직자는 영달의 기쁨이 있고, 하급인은 財ㆍ祿을 손상한다. 벌칙사를 당한다.
女의 구해를 받는다.
간사사가 있다.
교제에 능하다.
正月에 임신사가 있다.
`,`■자충수적 우환이 있다.
신ㆍ불기도하면 효과가 있다.
內外, 上下가 투쟁ㆍ불화
하고 이동한다.
가정에 신불제사가 잘못되어 재앙이 있다. 
제례상을 청소하고 고사하면 효과가 있다. 
계획사는 보류 방침이 좋다. 
사물이 갈림길에 있다.
다인 다사건이다.
`,`■관송사가 있다.
⊕상가부정, ⊖목기부정이
들었다.
凶을 凶으로 소산시킨다.
구재ㆍ혼인사ㆍ남매건에 관련된다. 후원자의 역량이 부족하여 도움이 안된다. 두려움이 있으나 주의하여 凶을 제어한다.
진퇴불명ㆍ기박하다.
內에서 기한 일이다.
`,`■가택의 손상이 있다.
이권을 손상한다.
이익사가 있고 소득한다.
금전사는 안된다.
움직일 일이 있으나 신중하게 접근해야 한다.
어떤일도 역효과가 난다. 人宅이 쇠퇴할 조짐이다. 처를 버리고 他女를 취한다. 의외의 財가 들어온다.
이권을 상실한다.
`,`■전진은 함정이 있다.
가택을 손상한다.
서리내린 다리를 말타고 달리는 형상이다.
만사가 물거품이다.
모사는 졸책이 되므로 조용히 때를 기다리라.
가을에는 小成이 있다.
남쪽(巳方)에 기로를 바꿀 인연이 있다.`]
const SIBO2_1 = [`■ 자손, 가택내사이다.
내방조짐 상의 :이사·이전
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 官･夫, 가택내사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■官･夫, 가정내부사이다.
내방조짐 상의 :원행·이동 
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■官･夫, 근친 가택내사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 내부 손모ㆍ여인사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 부모 재해 예방이 필요하다.
`,`■ 후원, 신변외사이다.
내방조짐 상의 :  
조용히 있는게 이롭다.
매사 불성한다.
`,`■官･夫,별리이동사이다.
내방조짐 상의 : 원행
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 손모, 후회심이 있다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 재ㆍ여, 가택내사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
재·녀 재액이 있고 이권상실, 부모재해 예방 이 필요
`,`■ 직녹, 신변외사이다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
내심 동요한다.
`,`■ 재ㆍ여, 가택내사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
신변이 동요한다.
`,`■ 재ㆍ여, 부부이심사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
이권상실하고 재녀의 재액이 있다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/014/row-1-column-1.png`,
  `/static/images/sagwa/014/row-1-column-2.png`,
  `/static/images/sagwa/014/row-1-column-3.png`,
  `/static/images/sagwa/014/row-1-column-4.png`,
  `/static/images/sagwa/014/row-1-column-5.png`,
  `/static/images/sagwa/014/row-1-column-6.png`,
  `/static/images/sagwa/014/row-1-column-7.png`,
  `/static/images/sagwa/014/row-1-column-8.png`,
  `/static/images/sagwa/014/row-1-column-9.png`,
  `/static/images/sagwa/014/row-1-column-10.png`,
  `/static/images/sagwa/014/row-1-column-11.png`,
  `/static/images/sagwa/014/row-1-column-12.png`,
]

const HEASYEOL = [
  // 1
  `  이 과는 천지(天地) 십이신(十二神)이 본궁에 숨어서 동하지 않는다. 음일(陰日)은 지상신(支上神)을 초전으로 하고 중․말전은 지형(支刑)으로 정한다. 사람은 은복신음(隱伏呻吟)하여 동하지 않고 천지반이 동궁(同宮)하여 화태(和泰)의 상이다. 음일은 스스로 유연하다고 믿음으로써 자신격(自信格)으로 된다.
  사과삼전은 축(丑)에 정신(丁神)이 승하여 공묘(拱墓)로 되고, 조용한 가운데 동의(動意)를 생한다. 초전은 지상신의 축(丑)이고 말전은 미(未)로서 일간에 돌아온다. 어떤 일도 그는 나에게 돌아와 의존하려고 한다. 일간일지는 미축(未丑)이 양충(兩冲)하여 유자격(遊子格)으로 된다. 즉 교차충(交車冲)은 친소(親疎)를 불문하고 먼저 합하고 후에 갈라진다.
   백법에 말하기를 ｢탈기(脫氣)가 지나치므로 출비(出費)가 커져 이것을 얻는데 상(償)을 얻지 못하고 부족이 있다. 그가 나에게 일을 구하는 것은 지(支)에 의해 발하고 간(干)에 전(傳)한다｣고 하였다. 비전에 말하기를 ｢삼전사과가 모두 계신(季神)이면 춘월(春月)은 정단하여 월고(越顧)라고 부르며 재(財)를 바른 길에 쓰지 않는다. 하월(夏月)은 전괴(傳魁)라고 부르며 임용(任用)할 인물을 얻지 못한다. 추월(秋月)은 살묘(殺墓)라고 부르며 세력이 왕성하여 홍기한다. 동월(冬月)은 복음(伏陰)이라고 부르며 점차 수장(收藏)하게 된다｣고 하였다. 교차칠충(交車七冲)은 이별․해산․육친(六親)이 불화합한다. 일귀는 순행하여 삼전이 주(朱)․후(后)․상(常)이고, 야귀는 삼전이 상(常)․후(后)․주(朱)이다.`
  ,
  // 2
  `  이 과는 제3과의 자축(子丑)의 극이 있고 이것을 용신(用神)으로 하는 것은 중심과이다. 삼전 자해술(子亥戌)은 역퇴여격(逆退茹格)으로 주(主)에게 이롭고 웃어른에게 액난이 있다. 길흉이 함께 내부에서 발생한다.
  일간상신이 건록을 만나고 육합이 승하여 길로 되어도 백호가 승하면 녹(祿)은 지킬 수 없으며 복분(福分)을 잃기 쉽다. 초전은 귀(鬼)로 되고 흉이 동하여도 말전의 술(戌)이 귀(鬼)를 제어하여 재해는 경미하다. 일간 일지는 오축자미(午丑子未)와 상하가 지합(支合)하지만 교차육해(交車六害)되어 평화 중에 해사(害事)가 발생한다. 교섭사는 의혹이 생겨 타결(妥決)되지 못하며, 화합․투쟁․고장은 교차하여 발생한다. 어떤 일도 신중하게 심사하지 않으면 착오를 일으킨다. 군자(君子)는 항상 연구하여 일을 처리하므로 시종 완벽한 것을 얻는다.
  백법에 말하기를 ｢귀(鬼)에 천을(天乙)이 승하면 신기격(神祗格)이다. 가정내의 신불제사(神佛祭祀)가 바르지 못하여 질병에 걸리고, 또 적(敵) 때문에 방조를 받을 상이다. 천장(天將)의 내전(內戰)은 모사가 위험에 도달한다｣고 하였다. 비전에 말하기를 ｢정일(丁日)은 오(午)가 녹(祿)으로 되고 해(亥)가 일덕(日德)․일마(日馬)로 된다. 자(子)를 자미궁(紫微宮)으로 하고 축(丑)은 북두(北斗)의 신이므로 숭교적(崇敎的)으로 음덕(陰德)이 홍대(洪大)하다｣고 하였다. 일귀는 순행하여 삼전이 사(蛇)․귀(貴)․후(后)이고, 야귀는 역행하여 삼전이 현(玄)․음(陰)․후(后)이다.`
  ,
  // 3
  `  이 과는 제3과에서 일하가 일상을 극하여 중심역간전격(重審逆間傳格)이다. 격명(格名)은 시둔격(時遁格)이라고 부른다. 퇴여격(退茹格)은 홀연히 퇴(退)한다. 그리하여 은둔(隱遁)하고 잠장퇴복(潛藏退伏)하므로 시둔(時遁)이라고 한다.
  초전이 귀살(鬼殺)이더라도 중전 유(酉)의 재성(財星)이 귀신(貴神)으로 되어 모든 일에서 이익이 있다. 타인에게 위탁하고 의존하는 것은 좋지 않다. 일간의 화(火)는 해(亥)에 절(絶)하고, 사과(四課)에 사해(巳亥)의 칠충(七冲)이 있다. 또 초전은 간상신(干上神)과 칠충하고 그 위에 삼전이 순수한 육음(六陰)의 땅이므로 사사(私事)를 계획하는 것은 좋고 공사(公事)는 불리하다.
  중심(重審)으로서 일지상신이 해(亥)를 초전으로 하면 천을귀(天乙鬼)로 되고, 역마가 일덕(日德)으로 되면 흉을 변화시켜 길을 얻는다. 백법에 말하기를 ｢사과삼전 중에 귀신(貴神)이 많은 것은 길조를 전변하므로 오히려 의지할 수 없으며, 중․말전의 공망은 전(傳)에 따르지 않고 초전에 의해 길흉을 고찰하면 초전의 귀(鬼)에 귀신(貴神)이 승하고 또 축(丑)의 제지(制地)에 있으므로 흉을 보아도 흉을 만나지 않는다｣고 하였다.
  일귀는 순행하여 삼전이 귀(貴)․음(陰)․상(常)으로 귀신의 발용(發用)은 길조를 나타내고 흉을 피할 수 있다. 야귀는 주(朱)․귀(貴)․음(陰)이다.`
  ,
  // 4
  `  이 과는 4과의 상하 및 요극(遙剋)도 없으므로 묘성과(昴星課)로 된다. 음일은 천지반에 극 중의 유(酉)의 하신(下神) 자(子)를 초전으로 한다. 자(子)의 하(下)는 묘(卯)이다. 중전은 일간상신(日干上神)을, 말전은 일지상신(日支上神)을 사용한다. 음일은 태양이 지는 상(象)에 의하여 유(酉)의 하신(下神)을 사용하면 초전이 음으로 된다. 중전의 일간상신을 쓰는 것은 양(陽)을 쓰고, 말전의 일지상신을 쓰는 것은 음(陰)의 상(象)을 취한다. 즉 음양접속(陰陽接續)의 길로써 한다.
  묘성(昴星)은 유(酉)의 상(上) 또는 유(酉)의 하(下)를 보고 초전을 결정하기 때문에 부앙격(俯仰格)이라고 부른다. 부(俯)하여 용신(用神)을 취할 때는 가까운 곳에 우환이 있다. 앙(仰) 하에 용신(用神)을 정할 때는 화재(禍災)가 먼 곳에 있다. 원래 유(酉)는 살기(殺氣)가 많으면 흉과이다. 유(酉)의 살기(殺氣)의 신을 표준으로 하여 사과에 극적(剋賊)이 없는 것을 용신(用神)으로 정하는 것은 극적(剋賊)과 같은 이치이다.
  일간․일지 어느 것이나 묘신(墓神)에 임하여 묘(墓)에 복(伏)한다. 스스로 묘에 앉아서 스스로 암매(暗昧)를 자각한다. 진(辰)은 동의(動意)를 생하고 지반 진(辰)의 상에서 축(丑)의 천반은 정신(丁神)으로 되어 변동의 징조가 있으며 동(動)하면 흉으로 동한다. 묘성과(昴星課)는 동사엄목(冬蛇掩目)이라고 하며 호시(虎視)라고도 한다. 음일(陰日)은 유하(酉下)를 취하여 수(首)를 부(俯)하는 뜻이 있고 해가 진 후에 호(虎)를 본다는 뜻이기 때문에 음일은 호시격(虎視格)이라고 부른다. 어떤 일도 은밀(隱密)하게 일을 일으키지 않고 방어를 우선으로 하면 재(災)는 멸할 수 있다.
  백법에 말하기를 ｢간과 지가 함께 묘에 승하면 사람과 가택의 혼미를 다스린다. 호시(虎視)를 만나면 실시하는 일은 모두 역량이 부족하다｣고 하였다. 비전에 말하기를 ｢야(夜)의 천장(天將)은 백호가 진(辰)에 승하여 미(未)에 가해지고, 백호가 신(身)에 임하여 호시격으로 삼호(三虎)를 만나기 때문에 놀라거나 위험을 만날 우려가 있다. 일귀는 순행하여 삼전이 사(蛇)․청(靑)․후(后)로서 길장이고, 야귀는 삼전이 합(合)․백(白)․사(蛇)이다. 천장(天將) 과격(課格)중에 어느 것이나 흉으로 되면 피점자는 신중을 요할 뿐이다.`
  ,
  // 5
  `  이 과는 4과 중에 제4과만 일상이 일하를 극하여 원수과(元首課)로 되고, 삼전 사축유(巳丑酉)는 삼합금국(三合金局) 종혁격(從革格)으로 된다. 일간은 내몸이며 일지는 상대방으로 한다. 일간에서 보아 재(財)로 되고, 삼전은 또 금국(金局)이 되어 나의 재(財)이다. 그러나 초․말전이 모두 유(酉)의 공망이다. 따라서 내몸의 이익은 어느 것이나 반감(半減)하는 상으로 된다. 그러나 원수과로서의 종혁격은 사물의 순리를 상(常)으로 하고 모든 일이 광명정대한 상이다.
  귀신(貴神)이 묘유(卯酉)에 서는 것은 음소(陰小)의 재(災)가 있다. 초전․말전이 공망하고 중전은 설기(泄氣)로 되어 시종 부실(不實)의 사건이 발생하므로 능히 연구(硏究)․채산(採算)․심술(心術)을 실시하여 소리소익(小利小益)을 얻는다. 삼합(三合)은 화미(和美)의 징조가 있지만 사과(四課)의 천반과 지반이 모두 칠충(七冲)으로 되고 상하 내외가 동요 파극(破剋)한다. 즉 기쁨 가운데 불화가 있거나 마음을 다르게 한다.
  비전(秘傳)에 말하기를 ｢천지반 중에 신(申)을 자(子)에 가하여 초전을 사(巳)로 하고, 사화(巳火)는 하의 유금(酉金)을 극한다. 상이 강하고 하가 약한 것은 산(産)을 점(占)하면 남아를 생산한다｣고 하였다. 백법에 말하기를 ｢인택(人宅)의 사중패신(四仲敗神)은 인택이 쇠퇴의 징조이다｣고 하였다. 삼전이 순재(純財)로서 일간상신의 부모효를 극하고 경계하더라도 웃어른이나 존장에게 재해가 있다. 혹은 재(財)로 인하여 혹은 부녀로 인하여 위역(違逆)을 초래한다. 결혼은 악처(惡妻)를 취하거나 처와 존친과는 의견이 다르다.
  일귀는 순행하여 삼전이 공(空)․주(朱)․음(陰)이고, 초전의 공중공(空中空)은 공언(空言)․허화(虛話)이며 실의(實意)가 적다. 야귀는 삼전이 상(常)․구(勾)․귀(貴)로서 어느 것이나 토(土)의 천장(天將)은 금국(金局)을 생한다. 삼전의 사유축(巳酉丑)은 반사(反射)라고 하며, 사물은 살벌(殺伐)을 품고 은혜(恩惠)를 베풀어 보수(報酬)로써 일에 답한다. 초전은 중전을, 중전은 말전을 생하는 것은 유실(遺失)이라고 부르며 오랫동안 소모의 일이 있다. 혹은 실비(失費)의 건이 겹쳐서 발생한다. 일간이 일지를 생할 때는 오히려 타(他)에서 일익을 받지만 이 과는 삼전만을 체생(遞生)한다.`
  ,
  // 6
  `  이 과는 4과 중에 제2과․제4과에 극적이 있고 하에서 상을 적(賊)한다. 제4과를 써서 초전으로 한 중심과이다. 신(申)은 공망하고 그 위의 묘(卯)를 용신(用神)으로 하면 단륜격(斷輪格)으로 되는데 공망하기 때문에 목(木)은 후목(朽木)으로 되어 조각용이 될 수 없다. 그러나 신유(申酉)의 공망을 나오면 다시 조각(彫刻)할 수 있다. 즉 때를 기다려 바꿔서 도모하는 것이 좋다.
  일귀는 묘(卯)에 의해 구진(勾陳)의 토(土)를 극하고 야귀는 천공의 토(土)를 극하여 어느 것이나 내전(內戰)으로 된다. 즉 천신(天神)이 천장(天將)을 극하는 것을 내전(內戰)이라고 하는데, 화(禍)는 내부에 의해 발생한다. 
  백법에 말하기를 ｢교차나망(交車羅網)으로 되면 모사(謀事)는 졸책을 만든다｣고 하였다. 공망의 상에 천공이 승하면 공상공(空上空)을 만나도 공론공화(空論空話)로서 어떤 일도 추구하지 말아야 한다. 비전에 말하기를 ｢묘목(卯木)이 신(申)의 공망에 앉으면 후목(朽木)으로 조각하기 어렵다. 별도로 공작을 구하여 만사는 옛 것을 버리는 것이 좋다｣고 하였다. 삼전 중에 일간의 묘신이 승하는 것은 구륜재단(舊輪再斷)이라고 부르며 실직(失職)의 우려가 있고 다시 계획을 일으키는 징조이다.
  일귀는 삼전이 구(勾)․후(后)․공(空)이고, 야귀는 삼전이 공(空)․사(蛇)․상(常)이다. 모사는 목적을 달성하지 못한다.
`
  ,
  // 7
  `  이 과는 천지(天地)가 상하의 충위(冲位)에 있으므로 반음과(返吟課)로 된다. 4과의 상하에 극이 없고 일간․기궁(寄宮)․삼합의 앞에 해당하는 해(亥)를 초전으로 하여 정란사격(井欄斜射格)으로 된다.
  정단은 일신양용(一身兩用)하며, 옆으로 구하면 쉽고 직접 나서면 어렵다. 어떤 일도 빨리 성취되는 것은 파하기쉽다. 조용히 하는 것이 가장 유리하고 활동하면 재해가 발생한다. 정신(丁神)은 동하기 쉽다. 말전의 축(丑)은 정신(丁神)에 해당되어 일간상신에 가(加)해진다. 그러므로 초전 해(亥)의 장간(藏干) 임(壬)과 간합(干合)하고 순정(旬丁)과 둔정(遁丁)이 서로 교차되어 가해지므로 주객(主客)이 서로 왕래가 친밀하여 잊기 어렵다. 모든 일은 구정(舊情)은 바꾸지 않고 덕신(德神)이 발용(發用)에 승하여 모든 재액(災厄)이 해소된다. 백법에 말하기를 ｢전묘(傳墓)는 묘(墓)에 들어가 애(愛)와 증(憎)을 나눈다. 귀신(貴神)은 일야(日夜)가 함께 임하므로 극(剋)되는 것은 귀인이 노하여 차질(差跌)을 일으킨다｣고 하였다. 반음(返吟)에 극이 없는 것은 정사신축미일(丁巳辛丑未日) 등의 육일(六日)로 극적(剋賊)이 없다. 그러므로 정란사(井欄射)를 초전으로 한다. 중전은 지상신을, 말전은 간상신을 쓴다. 정란이란 축(丑)의 장간(藏干) 계수(癸水)에서 사극(斜剋)하고 사궁(巳宮)의 상신 해(亥)를 채용하여 정하기 때문에 정(井)의 옆에 비스듬히 충(冲)하므로써 우물 밖으로 나오지 않는다는 뜻이다. 해(亥)는 관성(官星)에 해당되고 중․말전이 묘(墓)에 들어가 관(官)으로 하여금 초전을 제어하는 것으로 관리에게는 불리하다. 그러나 상인(常人)은 오히려 귀(鬼)가 묘(墓)에 들어가는 것을 기뻐한다.
  일귀는 역행하여 삼전이 귀(貴)․구(勾)․음(陰)이고, 야귀는 순행하고 삼전이 주(朱)․음(陰)․구(勾)이다. 과체(課體)가 반음무친격(返吟無親格)으로 되면 신심(身心)이 부정(不定) 중에 진퇴(進退)가 결정된다. 그러니 힘써 망동을 조심해야 한다. 정란사격은 전도에 재화를 만나기 쉽고 방향이 잘못되면 우환이 커지기 때문이다. 무친(無親)이란 모든 일이 의존하기 어렵고 반복하여 불안한 징조를 형성하는 것을 말한다.`
  ,
  // 8
  `  이 과는 상하의 극이 4과 중에 세 곳이 있다. 그 중 하에서 상을 극하는 제2과의 사자(巳子)를 취하여 초전으로 하는 중심과(重審課)이다. 삼전 사술묘(巳戌卯)는 주인격(鑄印格)으로 된다.
  제1과의 상하(上下)는 자미(子未)의 육해(六害)로 되고, 제4과의 오축(午丑)은 상하의 육해로 된다. 그러므로 일간상신 자(子)와 일지 축(丑)이 지합(支合)하고, 기궁(寄宮) 미(未)는 일지상신 오(午)와 지합하여 교차지합(交車支合)하기 때문에 상하가 상전(相戰), 육해(六害)한 후에 서로 화목한다. 삼전이 주인승헌격(鑄印乘軒格)으로 군자(君子)에게는 대단히 길하나 상인(常人)에게 별로 길하지 못하다. 어떤 일도 지둔하고 늦게 성취된다.
  2상은 하를 극하고 일하(一下)가 일상(一上)을 극하는 것을 초전으로 하는 것은 모든 일이 음소(陰小)하여 권세가 없다. 주인격(鑄印格)은 현임 관리에게는 가장 길하여 전직(轉職) 영달한다. 관직을 구할 때는 반드시 목적을 달성한다.
  백법에 말하기를 ｢일지상신 건록은 권섭이 바르지 않다｣고 하였다. 비전에 말하기를 ｢일지 음신(陰神)은 사과상신(四課上神)이다. 해수(亥水)가 건록을 극하기 때문에 벌칙을 만나고 상인은 재복(財福)을 손상한다. 즉 털을 불어가면서 남을 헐뜯는 상이다. 일귀는 역행하여 삼전이 공(空)․사(蛇)․상(常)이고, 초전에 천공(天空)이 승하는 것은 웃어른이나 존장의 면회 신청․선거 및 입시 등 어느 것이나 크게 길하다. 야귀는 여행하여 삼전이 상(常)․사(蛇)․공(空)이다.`
  ,
  // 9
  `  이 과는 제1과, 제4과에 상하의 상극이 있고 하에서 상을 적한다. 제4과 유사(酉巳)를 초전으로 하고 삼전은 유축사(酉丑巳)의 종혁격(從革格)이다. 제4과를 초전으로 하면 맥월격(驀越格)으로 된다.
  일간상신이 관귀(官鬼)로 되고 삼전이 금생수(金生水)로 귀(鬼)로 화(化)한다. 이 재(財)를 수득하면 오히려 재해를 초래한다. 그러나 중전의 설기(泄氣)가 구신(救神)으로 되어 큰 해가 없다. 초․중전이 공망하고 말전이 천공(天空)에 승하면 삼전이 모두 공망으로 되어 관점(觀占)이 없다. 일간기궁(日干寄宮)과 일지가 충(冲)하고 간상과 일지상신에 사해(巳亥)의 충(冲)이 있다. 내외상하의 투쟁․이동․불안의 상이다.
  맥월격은 어떤 일도 늦는 것을 상식으로 한다. 그러나 공망하기 때문에 소리만 있고 실의(實意)가 없다. 안정(安靜)보다는 오히려 만사 자연을 존중하출 행동하는 것이 좋다. 백법에 말하기를 ｢삼전의 재(財)가 귀(鬼)로 변하면 어떤 일도 구하는 일을 보류해야 한다. 양귀(兩貴)가 승하는 천반을 지반으로부터 극을 당하면 귀기(貴氣)를 상실한다｣고 하였다. 귀(鬼)에 천을(天乙)이 승하면 신기격(神祗格)으로 된다.   비전에 말하기를 ｢삼전의 재(財)는 간(干)이 귀(鬼)를 생한다. 이와 같은 것은 칼끝의 꿀과 같으니 맛보지 말아야 한다｣고 하였다. 초전 유사(酉巳) 위에 주작이 승하면 재(財)의 음신(音信)이 있다. 그러나 공망하므로 허재(虛財)이다.
  삼합은 화미(和美)의 상으로 되어도 제4과의 상하에 충극(冲剋)이 있다. 4과는 처음으로 삼전의 길흉의 결과를 예측하기 때문에 처음에는 불화하고 후에 화협(和協)한다. 말전에서 오행(五行)이 체생(遞生)하여도 일간상신은 일간을 극하여 타인의 추천을 받아도 결국은 적해(敵害)를 받고 재화를 초래한다. 일귀는 역행하여 삼전이 주(朱)․음(陰)․공(空)이고, 야귀는 삼전이 귀(貴)․상(常)․구(勾)이다. 천장(天將)은 모두 토신(土神)으로 삼합금국(三合金局)을 생하여도 공망하므로 실제 이익이 적다.`
  ,
  // 10
  `  이 과는 4과 중에 상하의 극도 없고 요극(遙剋)도 없는 것으로 음일(陰日)이면 천반 유(酉)의 하신(下神) 오(午)를 초전으로 하고 중전은 양(陽)을 쓰는 것을 원칙으로 하며, 일간상신은 술(戌)로서 말전은 음(陰)으로 된다. 일지상신의 진(辰)을 말전으로 하며, 사과삼전이 모두 설기(泄氣)한다. 일간․일지상신이 묘(墓)에 숨고 일귀(日貴)를 사용할 때는 사(蛇)․호(虎)를 만나서 혼매(昏昧)하다. 일야(日夜) 양 귀신은 공망에 앉고 공망에 승하여 귀인의 힘을 얻지 못한다. 즉 후원자의 역량이 부족하다. 원래 묘성과(昴星課)는 명암을 불문하고 경공화환(驚恐禍患)이 가장 깊은 것이 보통 상식이다.
  이 과는 초전 오(午)의 덕록(德祿)이 청룡에 승하고 진술(辰戌)은 간지상신을 충개(冲開)하여 흉으로써 흉을 소산(消散)하는 상이다. 우환이 있더라도 주의하면 큰 재해를 끼치지 않는다.
  백법에 말하기를 ｢일야 양귀신이 공망하면 기쁜 것은 모두 공허(空虛)로 된다. 호시격(虎視格)으로서 백호를 보면 만사에 힘을 쓰기 어렵다. 오(午)가 묘(卯)의 상에 승하여 초전을 이루는 것은 명당(明堂)이라고 부르며, 희사(喜事)는 더욱 길조로 된다. 즉 오(午)는 이명(離明)이라고 부른다. 묘(卯)는 천마성(天馬星)으로 되기 때문에 흉을 만나서 길을 형성한다.｣ 비전에 말하기를 ｢묘성호시격(昴星虎視格)은 생사(生死)의 문제이거나 부인의 색정(色情)의 건이다. 일귀는 역행하여 삼전이 청(靑)․사(蛇)․백(白)이고, 청신(靑神)의 발용(發用)은 공명․구직에는 길조가 있다. 야귀는 삼전이 합(合)․후(后)․청(靑)이고 구재․결혼 문제․남매 관계의 건이 있다.
  묘성과는 동하지 않고 변하지 않으며 원래부터 음(陰)에 속하므로 형사(刑事) 문제 및 질병의 정단(正斷)은 가장 싫어한다. 이 과는 양일(陽日)은 호시전봉(虎視輾逢)이라고 부르고 음일(陰日)은 동사엄목(冬蛇掩目)이라고 부른다. 또 춘(春)은 호시격이고 하(夏)는 전봉격(輾逢格)이며, 추(秋)는 묘성(昴星) 그대로이고 동월(冬月)은 동사엄목격이라고도 부른다.`
  ,
  // 11
  `  이 과는 제1과, 제3과에 상하의 극이 있고 제1과의 하에서 상을 극하여 유미(酉未)를 초전으로 하는 중심과이다. 삼전은 유(酉)에서 일신(一神)을 사이에 두고 있으며 해(亥)를 중전으로 하고 또 일신을 사이둔 축(丑)을 말전으로 하는 간전격(間傳格)이다. 유(酉)는 석각(夕刻)이고 해축(亥丑)은 야반(夜半)으로서 양기(陽氣)가 더욱 없기 때문에 의음격(疑陰格)이라고 한다. 초전이 공망한 것은 고과격(孤寡格)으로 된다.
  일간상신 유(酉)의 재신(財神)은 초전으로 되어도 공망하는 것으로서 목전의 재(財)는 거울 속의 꽃이고 물 속의 달처럼 손에 들어올 수 없다. 중전 귀효(鬼爻)는 공망에 승하여 해(害)를 이루지 않는다. 말전이 정신(丁神)으로 되어 동의(動意)를 구성하지만 일간일지상신 묘유(卯酉)가 충신(冲神)이 되므로 경거망동하지 말아야 한다. 의음격은 음소(陰小)에 의해 발생하며 웃어른에게 유익하지 않고 어떤 일에 있어서도 역효과를 부른다. 유월(酉月) 또는 년명상신(年命上神)이 유(酉)로 되면 정리이익(整利利益)으로 변화한다.
  백법에 말하기를 ｢일간․일지․상신에 패신(敗神)이 승하면 인택이 모두 쇠퇴할 우려가 있다｣고 하였다. 비전에 말하기를 ｢의음격(疑陰格)은 서리(霜)을 밟아서 견빙(堅氷)의 상으로 되면 어떤 일도 유음(幽陰)으로 되어 명확하지 않다｣고 하였다. 부부 문제는 처를 버리고 다른 사람을 사랑한다. 초․중전이 공망하므로 말전의 일신(一神)에 의해 길흉을 정단한다. 말전이 축(丑)의 탈기(脫氣)로 되면 구직․관공직은 이익되지 않는다.
  일귀는 역행하여 삼전이 주(朱)․귀(貴)․음(陰)이고, 야귀는 삼전이 귀(貴)․음(陰)․상(常)이다. 천장은 토신(土神)이며 유(酉)의 재(財)를 생한다. 공망하여 구재가 불가능하게 되어도 대금(貸金)이나 기타 생각지 않은 재(財)는 오히려 얻는다.`
  ,
  // 12
  `  이 과는 제1과․제3과에 상하의 극이 있고 하에서 상을 적하는 신미(申未)를 초전으로 하는 중심과이다. 삼전 신유술(申酉戌)의 서방합(西方合)은 유금격(流金格)으로 된다. 일간상신의 재(財)는 공망하며 이 재(財)는 지킬 수 없다. 진여격(進茹格)으로서 삼전이 모두 공망되어 길흉이 모두 이루어지지 않는다. 재(財)가 공망한 것이기 때문에 구재는 공망을 벗어나는 신유술일(申酉戌日)을 지나서 얻을 수 있다. 그러므로 때가 돌아오기를 기다리는 것이 좋다.
  삼전 및 일(日)의 음양신(陰陽神)이 모두 공망하면 만사가 물거품 같고 혹은 꿈과 같으니 분수를 알아 때를 기다리는 것만 같지 못하다. 즉 걱정을 만나도 걱정이 없고 기쁨을 들어도 기쁨이 없으니 조용하게 앉아서 오히려 재복(財福)을 얻으며 소재(小財)에 만족해야 한다. 백법에 말하기를 ｢공여(空如)의 상은 어떤 일도 추구하지 말아야 한다. 진여공망(進茹空亡)은 진퇴(進退)가 함께 이루어지기 어렵다. 모사(謀事)를 이루어도 졸책(拙策)으로 되는 것은 나망(羅網)을 만나기 때문이다｣고 하였다. 비전에 말하기를 ｢유금격(流金格)은 삼전이 신유술(申酉戌)인 것을 말한다. 이 격을 만나는 것은 서리 내린 다리 위를 말이 달리는 것과 비슷하다.｣
  일귀는 역행하여 삼전이 합(合)․주(朱)․사(蛇)이며, 귀신(貴神)이 옥(獄)에 들어간다. 현임 관리는 깊이 반성하여 자수(自修)해야 한다. 야귀는 삼전이 사(蛇)․귀(貴)․후(后)이고 귀신(貴神)이 공지(空地)에 있으므로 공명을 구하여도 목적을 달성하지 못한다. 진여격(進茹格)은 전진하는 것이 길이지만 공망하여 전진하면 함정에 빠져 들어간다. 혹은 전진하여도 이익이 없다. 군자는 때를 기다려서 공망을 벗어난 후에 기회를 보고 전진해야 한다. 어떤 일도 바람을 잡고 그림자를 잡는 것과 같으며, 만사는 재(財)의 소모로 끝난다. 다만 추월(秋月)의 정단만은 얼마간 길조로 변화한다.`
  ,
]

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
