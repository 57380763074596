
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,6,7,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,4,6,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`위선`,3,5,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`위선`,2,4,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,3,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,1,2,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,12,1,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,11,12,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`위선`,10,11,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신뢰`,9,10,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,8,9,` `,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,8,`특별있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,`중중희애`,`가정막힘`],
    [`남편(남자)`,`남편(남자) 관련사`,`大`,`존재기`,`(애정이넘`,`재난극복`],
    [`역불의동(亦不宜動)`,`부족함이 두렵더라도 적극적 활동은 되려 좋지않은 상`,`大`,``,`치는격)`,`이익진취`],
    [`탄식신음(嘆息呻吟)`,`병(病) 또는 도모사 미수로 탄식하는 상`,`小`,``,``,``],
    [`박관`,`직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`5`,`타인시혜`,`부부화합`],
    [`여식`,`여식 관련사`,`小`,`배양기`,`가실불화`,`신음탄식`],
    [`구재역득(舊財亦得)`,`오래 된 대여금을 받거나 새로 구하는 돈이 얻어지는 상`,`小`,``,``,``],
    [`불리고시(不利考試)`,`시험은 불리하고 없는 구설을 근심하는 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`大`,``,``,``],
    [`송쟁`,`외사 급속송쟁사-상신 우려`,`小`,`4`,`급속쟁송`,`타인시혜`],
    [`출행`,`내사 출행이동사`,`小`,`개시기`,`상해우려`,`음사폐호`],
    [`불가동용(不可動用)`,`적극적으로 움직이는 것은 피해야 하는 상-心神 관련`,`大`,``,`환성경사`,`가택안녕`],
    [`앙화소멸(殃禍消滅)`,`무서운 재난이 해소된 상`,`小`,``,``,``],
    [`후원`,`후원사`,`大`,``,``,``],
    [`결속`,`결속하여 권위사를 음7월에 크게 완성하나 病관재우려됨`,`小`,`3`,`암조능욕`,`무례반목`],
    [`모친`,`모친 관련사`,`小`,`학습기`,`박해소송`,`우유한가`],
    [`적주현형(賊主現形)`,`도적의 형체를 보게 되는 상`,`大`,``,`음7월`,``],
    [`우유한가(優遊閒暇)`,`용모를 돌볼 필요가 없는 한가로움의 상-실업자 관련`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`2`,`부부반목`,`단합결속`],
    [`자식`,`자식관련사`,`小`,`면모기`,``,`觀書문화`],
    [`처재작악(妻財作惡)`,`부인과 재물이 해악을 끼치는 상`,`小`,``,``,``],
    [`부처 반목(夫妻反目)`,`부부 반목대립사`,`大`,``,``,``],
    [`명예`,`수상(受賞)사`,`大`,``,``,``],
    [`직녹`,`입신 관련사`,`大`,`1`,`외교막힘`,`박해가함`],
    [`권리`,`권리행권사`,`小`,`절정기`,`투서불용`,`도적악의`],
    [`유거반복(閒居반복)`,`한가함을 반복하며 약속한 사람을 기다리는 상-실업자 격`,`小`,``,`금전유실`,``],
    [`불의 반공(不宜反攻)`,`적(賊)이 악심을 품었으므로 공격하지 말아야 할 상`,`小`,``,``,``],
    [`자매`,`형제자매 관련사`,`大`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`12`,`비용과출`,`남편색정`],
    [`색정`,`외男 색정사 有`,`小`,`과시기`,`재난전소`,`쟁투`],
    [`포석투강(包石投江)`,`이름을 싸서 강에 던져버리는 격의 상`,`小`,``,``,`미초희경`],
    [`불변근신(不辨謹愼)`,`장차를 위해 말을 삼가하고 근신해야 하는 상`,`小`,``,``,`도장관련`],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`대재`,`개신사를 음8월 완성 대재를 획득하나 부모 근심이 발생함`,`大`,`11`,`타인시혜`,`손해가출`],
    [`여식`,`여식 관련사`,`小`,`쇠퇴기`,`간사은닉`,`중중희애`],
    [`고위무력(高位無力)`,`윗인의 도움을 받아야 하나 그만 무력하게 되고 만 상`,`小`,``,`대재획득`,`개신개혁`],
    [`간사은닉(奸邪隱匿)`,`관계성 단절-가택이사-간사(奸事)은닉의 상`,`大`,``,`음8월`,`이혼불사`],
    [`금전`,`금전사업여인 관련사`,`小`,``,`애증화합`,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출로 성공함`,`小`,`10`,`급속쟁송`,`단합결속`],
    [`매사 영속성없음`,`금전사업처첩이권사는 선천적 기복재해를 겪음`,`大`,`침해기`,`파재실종`,`폭객欺凌`],
    [`택역흠녕(宅亦欠寧)`,`역시 집안이 편안하지 못한 흠의 상`,`小`,``,`상해우려`,``],
    [`총시성재(總是成災)`,`이리저리 둘러봐도 재난 뿐인 상`,`大`,``,`됨`,``],
    [`금전`,`금전사업이권여인 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`9`,`개신개변`,`색정사有`],
    [`매사 영속성없음`,`금전사업이권여인사는 손천적 기복재해를 겪음`,`大`,`소외기`,`사송계류`,`파재극처`],
    [`호악구무(好惡俱無)`,`좋고 나쁘건 간에 조건이 안 맞아 무효가 되는 상`,`小`,``,``,`실종우려`],
    [`관재형흉(官災刑凶)`,`관재형벌이 무거운 상`,`大`,``,``,``],
    [`박관`,`직업지위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`8`,`침체위역`,`압박받음`],
    [`자식`,`자식 관련사`,`小`,`침체기`,`身物손실`,`존비상몽`],
    [`불리전행(不利前行)`,`앞으로 나아가는 일이 불리한 상`,`小`,``,``,`가택이사`],
    [`난포심획(難捕尋獲)`,`도적을 쪽거나 찾아가봐도 붙잡기어려운 상`,`大`,``,``,`예정`],
    [`관사`,` 관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,`동요분산`,`비용과출`],
    [`남편(남자)`,`병재관재침해등 원수사`,`大`,`단절기`,`원수지경`,`눈이 가려`],
    [`초가안일(稍可安逸)`,`소극적 자세를 가져 편안하게 지내는 상`,`大`,``,``,`진격`],
    [`복통수욕(腹痛受辱)`,`복통으로 망신스러운 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`관사직업남자문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`직업직위여식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"우원문서부친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,"후원문서모친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`직업직위부부갈등자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,"명예직녹권리사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,"형제자매친구동료조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`직업직위불안부부갈등여식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안부부갈등자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`관사직업남자문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`제일 친하거나 제일 가까히 거주하는 자를 선택하면 해결될 人`,0,0,`현재 사안에서 물러날 뜻이 있으나 되려 진행하게 됨**어디 산속으로 숨고싶은 심정**전화위복이 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 서동하지 말고 후응해야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하고 유화적 처신으로 일을 미루면 결과가 유리해지는 人`,0,0,`색정사의 우려가 있음**전화위복이 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하면 이기고 늦으면 불리함`,0,0,`정정당당하게 속결해야만 유리하고 후응하거나 미루면 불리한 人`,0,0,`풍운불측의 상**남자는 관재사가 있고 여자는 독해가 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)**만약 타인과 다툰다면 상대에 대한 공격이 빗나가 유효하지 않음`,0,0,`공격을 포기해야만 할 人`,0,0,`여러 일이 하나로 묶어짐**입을 닫음**여자의 경우 남자를 적극 유혹하여 같이 도망할 속심 有**독신고독각방주말부부별리사적 처지 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기어려우므로 신중해야 하고 후에 행동하는 것이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`1녀2남 삼각관계 有**모든 관계가 끓어짐**아래가 위를 범하는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오니 신중해야 함`,0,0,`반목무상하고 부부남녀상쟁으로 불안하며 색정사가 있는 人 `,0,0,`풍운불측의 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 서동하지 말고 후응해야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하고 유화적 처신으로 일을 미루면 결과가 유리해지는 人`,0,0,`1남2녀 색정사 有**권리행사사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 서동하지 말고 후응해야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하고 유화적 처신으로 일을 미루면 결과가 유리해지는 人`,0,0,`여러 사안을 하나로 묶는 개신개혁 함**재앙이 업드려 있는 상**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 서동하지 말고 후응해야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하고 유화적 처신으로 일을 미루면 결과가 유리해지는 人`,0,0,`고집이 있고 신규사가 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 서동하지 말고 후응해야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응하고 유화적 처신으로 일을 미루면 결과가 유리해지는 人`,0,0,`관직자는 영전하고 서민은 가택이 불비함**일이 띄엄띄엄 진행되어 퇴하려 하나 되려 진행하게 됨**결말없이 반복되는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`제일 친하거나 제일 가까히 거주하는 자를 선택하면 해결될 人`,0,0,`일이 순서있게 진행됨**물사가 더디나 홀연히 일어남`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 동하려 하나 중지됨`,0,0,`고집이 있어 옛것을 지키며 새로운 기회를 기다리는 人`,0,0,`자신을 과신함**신규사가 있음**고등시험이 목적인 자가 많음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`충성무력`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인-비용과출`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성무력`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사-후회심겹침`,`기사`,`신사`,`외사`,`표면이면사가 겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-가옥고민 `],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-가옥고민 `],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-이사예정`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`   승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월`,3,1,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,1,1,2,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음3월-연기되거나 파혼우려`,1,1,2,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,3,3,6,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월`,3,2,5,`음5월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음1월`,3,1,4,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음12월`,3,2,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음11월`,2,2,4,`음7월-연기되거나 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음10월`,2,3,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음9월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 잋처리가 강하지 못해 재정을 비게 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 종반 업무가 공허함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세고 종반업무는 일반에 못미침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일처리가 강하지 못해 업무성과를 못 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받고 종반에 역량을 나타냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 시종 동요불안분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 업무도 중반의 침체를 넘어 합격점임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 초중반이 허무하고 종반에 이르러 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이며 급하여 종반에 이르러서야 업적을 드러 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 종반업무가 허무하게 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많으나 업무는 일반적임 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 반목대립으로 송쟁을 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`있음`,`불이행`,`선난후압`,`進向`,` 근`,`留`,`무난`,`정남`,``,`정북`],
    [`10.11`,``,`불발현함`,``,`박필요`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,` 있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선손후합`,` 進向`,`  원`,`  留`,`주인가해`,`동남`,``,`북북동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`있음`,`불이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`남남동`,``,`동북`],
    [`7.8월`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음`,`불이행`,`사안무력`,` 進向`,`  근`,`  留`,`주인무력`,` 정동`,`정동`,``],
    [`10.11`,``,`본인화합`,``,``,`지구력要`,``,`선택여지`,``,``,``,``],
    [`3.6\n9.12`,``,`제압`,``,``,``,``,`없음`,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`측근제압`,`불이행`,`불가`,` 退背 `,`  원`,`  去`,`주인가해`,`남남서`,`동남`,``],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,` 있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`자중지란`,`불이행`,`선출비後`,` 退背`,`  원`,`  去`,`세입가해`,`선택지難`,`정남`,``],
    [`10.11`,``,`됨`,``,`압박필요`,``,``,``,`동요불안`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 다集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,` 없음`,`불이행`,`선권리양`,`退背`,`원근왕래`,`  去`,`세입가해`,`정북`,`정북`,``],
    [`10.11`,``,``,``,`도後용이`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,`함`,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`없음`,`불이행`,`선손후합`,`退背`,`  근`,`  去`,`상호 단합`,`북북동`,`북서`,``],
    [`10.11`,``,``,``,`본인무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음`,`불이행`,`선이후不`,`退背`,`초근후원`,`  去`,`세입설기`,`북서`,`북북서`,``],
    [`10.11`,``,`무력`,``,`빠르게`,``,``,``,`쇠약`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`불이행`,`선이후난`,` 退背`,`  원`,`  去`,`세입 설기`,`정동`,``,`정북`],
    [`10.11`,``,``,``,`빠르게`,``,``,``,`쇠약`,``,``,`세밀심사`],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,` 없음`,`불이행`,`선손후손`,` 進向`,`  근`,`  留`,`화합`,`동남`,``,`서북`],
    [`10.11`,`  集`,``,``,`포기요됨`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`발현안됨`,`불이행`,`선난후난`,`進向`,`  근`,`  留`,`무난`,`남남동`,``,`북북서`],
    [`10.11`,``,`종반제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,"목기부정","수목절단",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`성인위인`,`성소침해`,0,0,`육친`,``,``],
    [`질병`,`성인위인`,`성소침해`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"여고음강","女姑미련",`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`자살귀`,`목맴`,0,0,`묘좌`,``,`있음`],
    [``,``,`강고함`,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`자살귀`,`목맴`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`자살귀`,`목맴`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`자살귀`,`목맴`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,`남남서`,`있음`],
    [``,``,``,0,0,`묘향`,` 북북동`,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,`강 천`,``],
    [``,``,``,0,0,`묘속`,` 뱀굴`,``],
    [`가정`,``,``,0,0,`육친`,`증조모`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"목기부정","목기반입",`육친`,``,``],
    [`질병`,` 음귀`,`산소탈`,0,0,`묘좌`,`정동`,`없음`],
    [``,``,`현3대자손`,0,0,`묘향`,`정서`,``],
    [`생업`,``,`재물손실`,`음사부정`,`제사잘못`,`전경`,`강 천`,``],
    [``,``,`공허`,0,0,`묘속`,`목근`,``],
    [`가정`,``,``,0,0,`육친`,`고조모`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,`정북`,`특별있음`],
    [``,``,``,0,0,`묘향`,`정남`,``],
    [`생업`,``,``,0,0,`전경`,`강 천`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,`도로귀`,`교통사`,`음사부정`,`제사잘못`,`육친`,`고조부`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`전도**관재`,`可불리모면 이득진취**외사출행이동사`,`눈이 가려진 격으로 사람을 상해할 수 없음`,`빈궁**관재**가정침체**전도`,`불리 고시-없는구설근심사`,`빈궁`,`외사 급속사`,`환성기쁨사**내사 출행이동사`,`부부불화사-이동사`,`관재`,``,`중중지애사`,`복통-도모사 吉`,`명예자산권리사**외교막힘`,`재앙소멸사`,`병재**外남 색정사有`,``,`희경사`,`빈궁**병재**세속허사자**매사 허성불성함`,`음3,6,7,8,9,12월 방문자는종교잔출하면`,`內녀 색정사有**음사폐호-가택안녕`,`빈궁**관재**종교진출하면 자립함`,`정체**경공사有`,`관재**탄식신음사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 동요분산사`,``,`내부가정막힘`,``,``,`전화위복`,``,`재난해소`,``,`재난해소`,``,`음4,5월 화재주의`,`내사급속사`,`음4,5월 화재주의`,``,`내사 부부동요분산사`,`내부가정손해가출사 우려`,``,`도적실세-포회함`,`성공함`,`종업원모사有`,`어떤 일도 영속성 없음`,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,``,`관재**탄식신음사`,`전도**관재`,`可불리모면 이득진취**외사출행이동사`,`눈이 가려진 격으로 사람을 상해할 수 없음`,`전도**빈궁**관재**가정침체`,`불리 고시-없는구설근심사`,`빈궁`,`외사급속사`,`관재**환성기쁨사**내사 출행이동사`,`부부불화사-이동사`,`빈궁`,``,`중중지애사**출행이동사`,`복통-도모사 吉`,`명예자산권리사**외교막힘`,`재앙소멸사`,`관재**병재**外남 색정사有`,`내부가정손해가출사 우려`,`희경사`,`빈궁**병재`,`종교진출하면 자립함`,`빈궁**內녀 색정사有**음사폐호-가택안녕`,`빈궁**관재**종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`전화위복`,`외사 동요분산사`,``,`내부가정막힘`,``,``,`전화위복`,``,`재난해소`,``,`재난해소`,``,`음4,5월 화재주의`,`내사 급속사`,`음4,5월 화재주의`,``,`내사 부부동요분산사`,``,``,`도적실세-포획함`,`어떤 일도 영속성 없음`,``,`어떤 일도 영속성 없음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`전도**빈궁**內녀 색정사有`,`종교진출하면 자립함`,``,`반목대립**탄식신음사`,``,`可불리모면 이득진취**출행이동사`,`빈궁**관재**가정침체`,`눈이 가려진 격으로 사람을 상해할 수 없음`,`불리 고시-없는구설근심사`,`빈궁**관재`,`외사 급속사`,`관재**환성기쁨사**내사 출행이동사`,`부부불화사-이동사`,`빈궁`,``,`중중지애사`,`복통-도모사 吉`,`명예자산권리사**외교막힘`,`재앙소멸사`,`빈궁**관재**병재**外남 색정사有`,`내부가정손해가출사 우려`,`희경사`,`전도**빈궁`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음사폐호-가택안녕`,`어떤 일도 영속성 없음`,``,`전화위복`,`외사동요분산사`,``,`내부가정막힘`,``,``,`전화위복`,``,`재난해소`,``,`재난해소`,``,`음4,5월 화재주의`,`내사급속사`,`음4,5월 화재주의`,`내부가정손해가출사 우려`,`내사 부부동요분산사`,``,``,`도적실세-포획함`,`어떤 일도 영속성 없음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`종교진출하면 자립함`,``,`종교진출하면자립함`,`번민-간사은닉-가택이사**內녀 색정사有`,`빈궁**매사위역`,`재난전소사`,`빈궁**관재**출행이동사`,`급문사 투서헌책불채택-금전실재사`,`부부반목사**관재**가정침체`,``,`남 모르는 능욕박해사**이동사`,`빈궁`,`고위안좌-이득경영사**내사 출행이동사`,``,`빈궁**관재`,`폭객기만능욕사`,`빈궁**병재**매사위역`,`형벌최흉`,`명예자산권리사**외교막힘**희경사-부족`,`내사 급속사`,`빈궁內녀 색정사有`,``,`빈궁**관재`,`觀書-문화풍아`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`어떤 일도 영속성없음`,``,`전화위복`,``,`외사 동요분산사`,``,`내부가정막힘`,``,``,`전화위복`,`외사급속사`,``,``,``,``,`음4,5월 화재주의`,`재난해소`,`음4,5월 화재주의`,`재난해소`,``,`내부가정손해가출사 우려`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`빈궁**관재`,`觀書-문화풍아`,`종교진출하면자립함`,``,`종교진출하면자립함`,`번민-간사은닉-가택이사**內녀 색정사有`,`빈궁**매사위역`,`재난전소사`,`빈궁**관재`,`급문사투서헌책불채택-금전실재사`,`관재**부부반목사**가정침체`,``,`빈궁`,`남 모르는 능욕박해사`,`관재**고위안좌-이득경영사**내사 출행이동사`,``,`빈궁`,`폭객기만능욕사`,`빈궁**병재*매사위역`,`형벌최흉**내사 급속사`,`명예자산권리사**외교막힘**희경사-부족**전도`,`내사 급속사`,`전도**빈궁**관재**外남 색정사有`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재난해소`,`내부가정손해가출사 우려`,`어떤 일도 영속성없음`,``,`어떤 일도 영속성 없음`,``,`재난해소`,`전화위복`,`외사동요분산사`,``,`내부가정막힘`,``,`재난해소`,`전화위복`,`외사 급속사`,``,``,``,`재난해소`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`빈궁**外남 색정사有`,``,`빈궁`,`觀書-문화풍아`,`종교진출하면 자립함`,``,`종교진출하면 자립함`,`번민-간사은닉-가택이사**內녀 색정사有`,`빈궁**매사위역`,`재난전소사`,`빈궁**관재`,`급문사투서헌책불채택-금전실재사`,`부부반목사**관재**가정침체`,`내부가정막힘`,`빈궁`,`남 모르는 능욕박해사`,`고위안좌-이득경영사**내사 출행이동사`,``,`빈궁`,`폭객기만능욕사`,`빈궁**병재**매사위역`,`형벌최흉`,`명예자산권리사**외교막힘**희경사`,`내사급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 부부동요불안분산사`,``,`동요불안분산사`,`내부가정손해가출사 우려`,`어떤 일도 영속성 없음`,`동요불안분산사`,`어떤 일도 영속성 없음`,`동요불안분산사`,`동요불안분산사`,`전화위복`,`외사 동요불안분산사`,`재난해소`,`동요불안분산사`,`재난해소`,`동요불안분산사`,`전화위복`,`동요불안분산사`,`외사 급속사`,`동요불안분산사`,``,`동요불안분산사`,`음4,5월 화재주의`,`동요불안분산사`,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`명예자산권리사**외교막힘**희경사`,``,`빈궁**外남 색정사有`,``,`빈궁`,`觀書-문화풍아`,`종교진출하면 자립함`,`전도**관재**송구경황사`,`종교진출하면 자립함`,`번민-간사은닉-가택이사**內녀 색정사有**전도`,`빈궁**매사위역`,`재난전소사`,`빈궁**관재`,`급문사투서헌책불채택-금전실재사`,`관재**부부반목사**가정침체`,`내부가정막힘`,`빈궁`,`남 모르는 능욕박해사`,`고위안좌-이득경여사**내사 출행이동사`,``,`빈궁**관재`,`폭객기만능욕사`,`빈궁**관재**병재**매사위역`,`형벌최흉**내사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 급속사`,`음4,5월 화재주의`,`내사 부부동요분산사`,``,`내부가정손해가출사 우려`,``,`어떤 일도 영속성 없음`,``,`어떤 일도 영속성 없음`,``,`전화위복`,``,`외사 동요분산사`,`재난해소`,``,`재난해소`,`전화위복`,``,``,`외사 급속사`,``,``,``,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`중중희애사`,`매사위역`,`명예자산권리사**외교막힘`,`관직자 영전도약-일반인 반흉`,`사사성취`,`내사 부부동요분산사`,`빈궁`,`투서헌책  좋지않음-구설-금전실재사 `,`종교진출하면 자립함`,`병재`,`종교진출하면 자립함`,`번민-간사은닉-가택이사`,`매사위역`,`재난전소사`,`탄식신음사`,``,`빈궁**관재**가정침체`,`내부가정막힘`,`승진영전사-부족`,``,`빈궁**병재**내사 출행이동사`,`관직자 대길-생살대권 쥠-일반인 형벌대흉`,`빈궁`,`폭객기만능욕사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`음4,5월화재주의`,`내사 급속사`,`음4,5월화재주의`,``,`내부가정손해가출사 우려`,``,`어떤 일도 영속성 없음`,``,`어떤 일도 영속성 없음`,``,`전화위복`,``,`외사 동요분산사`,``,`도적의혹근심사`,``,`전화위복`,``,``,`외사 급속사`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`빈궁`,`폭객기만능욕사`,`관재**중중희애사`,`매사위역`,`명예자산권리**외교막힘**내사 급속사`,`관직자 영전도약-일반인 반흉**外남 색정사有`,`사사성취`,`내사 부부동요분산사`,`빈궁`,`투서헌책 좋지않음-구설-금전실재사`,`종교진출하면 자립함`,`빈궁**병재**內녀 색정사有`,`번민-간사은닉-가택이사`,`종교진출하면 자립함`,`탄식신음사`,`재난전소사`,`빈궁**관재`,``,`빈궁**관재**가정침체`,`내부가정막힘`,`可승진영전사`,``,`빈궁**병재**내사 출행이동사`,`관직자 대길-생살대권 쥠-일반인 형벌 대흉`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재난해소`,``,`재난해소`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,`내부가정손해가출사 우려`,``,`어떤 일도 영속성 없음`,``,``,`어떤 이도 영속성 없음`,`전화위복`,``,`외사 동요분산사`,``,`도적의혹근심사`,``,`전화위복`,``,`매ㅐ사위역`,`외사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`도적을 추격했으나 못잡은 격`,`빈궁**내사 출행이동사`,``,`관재**재물유실사`,`매사위역**재난해소`,`빈궁**병재`,`복통-도모사 吉`,`명예자산권리**외교막힘**내사 급속사`,`내사 부부동요분산사`,`몸-물품손실**外남 색정사有`,`왕래사송계류사`,`빈궁`,`혼인사-연기 또는 파혼우려`,`종교진출하면 자립함`,`內녀 색정사有`,`종교진출하면 자립함`,`재난전소사`,`빈궁**매사위역`,``,`可불리모면-이득진취-부족`,`내부가정막힘`,`가나 있으나 좋음**가정침체`,`신분 차이 음사- 존비상몽사`,`빈궁**이잔이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 급속사`,`매사위역`,``,``,`형벌최흉`,``,`재난해소`,`음4,5월 화재주의`,``,`음4,5월화재주의`,`내부가정손해가출사 우려`,``,``,`어떤 일도 영속성 없음`,`관재 또는 병재사`,`어떤 일도 영속성 없음`,``,`전화위복`,`외사 동요분산사`,``,``,`매사위역`,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`신분 차이 음사-존비상몽사`,`빈궁**관재**전도`,`도적을 추격했으나 못잡은 격`,`빈궁**내사 출행이동사`,``,`재물유실사`,`형벌최흉`,`빈궁**병재**전도`,`복통-도모사 吉`,`명예자산권리**외교막힘`,`내사 부부동요분산사`,`몸-물품손실**外남 색정사有`,`왕래사송계류사`,`빈궁**전도`,`종교진출하면 자립함`,`혼인사-연기 또는 파혼우려`,`세속허사자**매사 허성불성함**관재또는 병재사`,`음3,6,7,8,9,12월방문자는종교진출하면`,`재난전소사`,`빈궁**전도`,`외사 동요분산사`,`관재**可불리모면-이득진취`,`내부가정막힘`,`관재**가나 있으나 좋음가정침체`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`매사위역`,`외사 급속사`,`재난해소`,``,`재난해소`,``,``,`내사 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,`내부가정손해가출사 우려`,`어떤 일도 영속성없음`,``,`內녀 색정사有`,`성공함`,``,`매사위역`,``,`폐한인`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`눈이가려진 격으로 사람을 상해할 수 없음`,`빈궁**관재**가정침체`,``,`빈궁`,`외사 급속사`,`환성기쁨사**내사 출행이동사`,`부부불화사`,`빈궁`,``,`중중지애사`,`복통-도모사 吉`,`명예자산권리**외교막힘`,`재앙소멸사`,`빈궁**병재**外남 색정사有**내사 부부동요분산사`,`부부불화사`,`희경사`,`세속허사자**매사 허성불성함`,`음3,6,7,8,9,12월방문자는종교진출하면`,`세속허사자**매사 허성불성함**관재 또는 병재사`,`음3,6,7,8,9,12월 방문자는종교잔출하면`,``,`탄식신음*매사위역`,`외사 동요분산사`,`可불리모면-이득진취`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내부가정막힘`,`재난해소`,``,`전화위복`,``,``,``,`매사위역`,``,`매사위역`,`내사 급속사`,`음4,5월 화재주의`,`도작실세-잡음`,`음4,5월 화재주의`,``,`내부가정손해가출사 우려`,``,`성공함`,`內녀 섹정사有**음사폐호-가택안녕`,`성공함`,``,`전화위복`,``,`재난해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 관재야 ! 자신감이 있어도 아직 때가 아니야! 
움직이면 결국 도망가야 될 운세야 !
⊕수귀,⊖가택음사부정듬.도로귀가 가정에 들어 돈ㆍ여자ㆍ재산을 방해하고 있어!
⊕⊖신변목기부정침범했네!
`,`■ 지금 선택의 기로에섰는데 친하고 가까운데 사는 한 사람을 선택하세요.

⊕음귀, 
⊖신부정귀가 가정에 침범    해서 계속 일이 꼬여!
`,`■ 이사를 해야겠네? 
집 밑에 해골이 있어! 
본인이 변을 당하게 생겼어!

⊕변사귀, 
⊖신부정귀도 발동했고! 
또 색정사가 벌어져!
`,`■ 감추는게 있겠네! 
   앞길이 안보이는데 색     정사․도망사가벌어     질 조짐이야 ! 

⊕물에 빠진 자살귀,
⊖도로귀가 막고 있어!
그러니 관재수도 있고 신변이 시끄럽지!
`,`■ 결국 공격 받을 것이 한 두가지가 아니겠어! 
총이 있으나 총알이 없는 격이니 입조심을 잘 해야 해 !
⊕자살귀, 
⊖도로귀를 잡고 봐야지!
女:⊕남의 남자를 신랑으로
     삼았네?
`,`■ 윗골육이 등을 돌린데다 옛일, 옛 인연조차 다 끝났어! 한 여자에 두 남자가 삼각관계까지 이루고 있네 !

⊕자살귀, 
⊖도로귀까지 날뛰니 물구덩이에 빠졌지!

⊖신변음사부정도침범했네?
`,`■ 색정사로 배반당하고 이러지도 저러지도 못하겠고 의지할데는 없고 앞길은 안 보이고 숨기는 것은 있고!

⊕자살귀, 
⊖여귀 발동때문이야!
누구 탓도 못하겠어 !
`,`■ 한 남자에 여자가 둘이니 고생을 사서하는 셈이야! 대 권리가 모두 남한테 있는 셈이야 ! 집 명의도 넘어가게 생겼는데 정작 본인은 힘을 쓸 수가 없겠어?

⊕⊖신변에 음사부정이
    들었네 ?
`,`■ 돈이 한두군데서 열린게 아닌데 가을이라야 보배가 되겠어! 
가정에⊕가택목기부정, 
       ⊖가택상가부정이 들었는데 이것을 해소시켜야 계획하는 개혁을 이루어내지!
`,`■ 새 일을 시작하겠는데
먼저 잃고 후에 얻지만 적은 돈이네 ! 늦게서야 좋아져 !
그런데 ⊕음귀, 
       ⊖신부정귀를 잡고 나서야 등돌린 여러사람이 따라오지!
⊕신변음사부정 침범했어!
`,`■ 일이 끝이 안나고 반복되기만 하네? 내 몸도 집안도 모두 불안하기만 하고!

⊕도와줄 자가 병이 듬
⊖도우면서 도둑심보니 
  괴롭겠어!
`,`■ 집이 움직이고 있는데, 그대로 있는게 좋아요 ! 
마음이 불안하여 기로에 
섰는데 근친자로 결정하여 계속 진행하면 결과가 있겠어!`];
const YOGYEOL1 = [`■ 녹과 재물이 있으나 낮정단은 아무 쓸모가 없다. 끝에 역마가 나를 도우나 밤정단은 범이 흉하게 움직인다.
`,`■ 병사묘절은 피차 기쁘지 않다. 병이나 소송은 재발되고 재물은 귀신이 빼앗아 간다.
`,`■ 몸이 수척해가는 것은 집이 나쁘기 때문이다. 순이 바뀌거나 여름이면 옛일로 재물을 얻는다.
`,`■ 돕는자가 말이 되니 재가 침범을 못한다. 밤정단은 현무요 낮정단은 백호라. 종업원이 음란사를 일으키고 떠난다.
`,`■ 서로 교섭하나 좋지 않다. 낮정단은 재물을 잃는다. 삼합육합하니 관직점은 틀림이 없다.
`,`■ 모두 위에서 아래를 극하니 윗사람이 어지럽고 덕을 잃어 칡대가리가 여러 가닥인 것과 같다. 여자나 재물로 간사사가 생긴다.
`,`■ 덕녹이 있으나 양인을 보니 다 쓸모가 없다. 이곳저곳 가보아도 진퇴양난일 뿐이다..
`,`■ 스스로 화를 사서 당한다. 쇠도장이 있지만 굴하나를 놓고 두뱀이 싸우니 녹을 얻는 것이 가능하겠는가.
`,`■ 삼전이 자취가 없고 두 귀인도 힘없는 곳에 앉았다. 밤정단은 하늘이 도와 잃은 재물을 다시 얻게 된다.
`,`■ 내 재물을 먼저 써야 여러사람의 인심을 얻는다. 그러나 밤정단은 한가지 일조차 되지 않는다.
`,`■ 서로 해가 되고 있으니 각기 장돌뱅이마음이다. 탈기 양인 공망으로 온전히 의지하고 믿을 곳이 없다.
`,`■ 간지가 삼전을 껴안고 있으나 녹이 변했다. 밤정단은 귀인이 공망이고 낮정단은 귀인이 옥을 거친다.`]
const SIBO1 = [`■ 家內 우환이 있다. 가인이
가장을 속인다. 원한을
품고 加害하려 한다.
凶을 凶으로 제어한다.
자만 강폭하여 신음하는 형편이 되었다. 
움직일때는 먼곳을 택하라. 구재는 어렵고, 중도에 좌절하게 되나 끝에는 성공한다.
이권을 상실한다. 

가내 혼인을 거부하는 女 있음.
正月에 임신사가 있다.
`,`■ 관재가 있다. 家內 우환이
기하고 가인 중 가장을
속인다. 원한을 품고 加害
하려 한다.
기로에 있어 불안하다.
이권을 상실한다. 
진퇴양난이다. 
동료의 의사에 보조하면 吉하다. 
이견은 불리하다. 
가정에서 신불에 기도할 일이 있다. 人宅이 쇠퇴하고 상대ㆍ가정이 충돌한다. 폐지사를 부흥시키려 한다.
`,`■ ⊖수망노부 부정이 들었다.
⊕음사부정이 들었다.
관송사가 기한다.
가택下에 복시(伏屍)가 있다. 시작은 있으나 끝이 없다. 
가정에 괴이한 일이 자주 발생한다. 
구설도 있다. 
일이 어둡다.
이권을 상실할 우려가 있다 
기도하면 吉하다.
`,`■ 사물이 양갈래이다.
이익사가 있고, 소득한다.
금전사는 무용이다.
집안에 죄인을 감추고 있다. 은상을 받는 일이 생긴다. 
멀리 출행하게 되면 厄이 생긴다. 
교제사는 끝나게 된다.
正月에 임신사가 있다.
이권을 상실한다.
`,`■ 부부가 共히 음탕하다.
男ㆍ女부부관계가 정리된다.
화ㆍ복 또한 경미하다.
교섭사는 잘된다.
女가 男을 유혹하여 가출하려 하는 상이다.
분실ㆍ도난이 우려된다.
사교성이 지나치다.
二ㆍ三종의 교섭사가 있다.
正月에 임신사가 있다.
`,`■ 자충수적 우환이 기한다.
女ㆍ婦가 加害하려는 兆가 有
他人이 와서 해치려 한다. 경계하라. 여난이 있게 된다. 一女二男의 삼각관계가 있다. 
下의 예의가 문란하다. 
양자가 되고 姓을 바꾼다. 가택이 손상된다.
正月에 임신사가 있다.
`,`■ 자충수적 우환이 기한다.
정치인ㆍ관리는 탄핵을
받는다.
진퇴양난에 있다. 
신불의 재앙이다.
神床을 청소하고 기도하라. 凶이 해소된다.
직위ㆍ직업ㆍ종사업은 끝난다. 
公私간에 동일하다.
正月에 임신사가 있다.
`,`■ 가택의 권리가 넘어간다.
男ㆍ夫가 헌신한다.
一男二女 삼각관계가 있다. 他人에게 가서 구해를 받는다. 
스스로 고생을 사서 하는 격이다. 
관직자는 吉하고, 일반인은 凶이다.
亥띠 행년자는 길하다.
`,`■ 다인 다사 취합사이다
⊕가택목기부정으로
가정 喪事가 난다.
관송사가 기한다.
일의 진행이 모두 공허하게 된다. 금전사가 여러건이 되고 개혁하려 한다. 가을에 효과가 있게 된다. 
가사가 곤란하고 가택북쪽에 복시가 있다.
가택이 손상된다.
`,`■ 별리ㆍ무덕 女이다.
고독인이다.
財를 구하나 소모가 먼저이다. 小財일뿐이다.
他人에게 혜택을 준다. 
도중 후원은 허언일뿐 성사가 없다. 늦게 서서히 이익을 얻게 된다. 가택이 좁다.
`,`■ 자기 뜻을 주장하여 
일이 막힌다.
침소봉대의 위험이 있다.
기도하여 때를 기다리는 것이 좋다. 
기쁨이 있다해도 공허하게 된다. 일이 간격을 두고 진행된다.
반목ㆍ대립ㆍ재해의 사정임.
戌ㆍ酉ㆍ午띠는 吉하다. 
財事는 처음에 訣하라.
이권을 상실한다.
`,`■ 가택이 좁다. 그러나
이사는 금물이다.
모든 일이 초조를 띠고
우연히 성사되는 기쁨이 있다. 옛것을 지켜야 한다. 가정에 부부합연이 있다. 현재 선택의 기로에 있으나 친ㆍ근자로 결정하면 吉하게 된다. 二事이다.`]
const SIBO2_1 = [`■ 직녹, 신변외사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 자손사, 탄식신음한다.
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
여인사가 지체되고 있다
`,`■ 자손, 가족반목사가 있다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 손모, 가택동요가 있다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
財․女事가 길하다.
`,`■ 금전ㆍ여, 가택내외사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
재·녀 재액이 있고 이권상실, 관재·병재 예방이 필요
`,`■ 官･夫,가택내사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 손모, 가택동요가 있다.
내방조짐 상의 : 
움직임이 근본이고 동기가 발동한다.
색정불화사가 있다.
`,`■ 직녹, 신변외사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 재ㆍ여, 신변외사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
재·녀 재액이 있고 이권상실,부모재해 예방이 필요함
`,`■ 재ㆍ여, 신변외사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
재·녀 재액이 있고 이권상실한다.
`,`■ 자손, 가택내사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 직녹반목외사이다.
내방조짐 상의 :  
원행·이동사가 있다.
은둔ㆍ도망ㆍ원행에 이롭다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/013/row-1-column-1.png`,
  `/static/images/sagwa/013/row-1-column-2.png`,
  `/static/images/sagwa/013/row-1-column-3.png`,
  `/static/images/sagwa/013/row-1-column-4.png`,
  `/static/images/sagwa/013/row-1-column-5.png`,
  `/static/images/sagwa/013/row-1-column-6.png`,
  `/static/images/sagwa/013/row-1-column-7.png`,
  `/static/images/sagwa/013/row-1-column-8.png`,
  `/static/images/sagwa/013/row-1-column-9.png`,
  `/static/images/sagwa/013/row-1-column-10.png`,
  `/static/images/sagwa/013/row-1-column-11.png`,
  `/static/images/sagwa/013/row-1-column-12.png`,
]
const HEASYEOL = [
  // 1
  `  12신이 본궁(本宮)에 있는 것은 복음과(伏吟課)로 된다. 양일(陽日)은 일간상신을 초전으로 하고, 중전은 지형(支刑)을 채용하며, 말전은 충신(冲神)을 써서 삼전을 조식(造式)한다. 사신인(巳申寅)은 원태격(元胎格)이고 양일은 자임(自任)으로 된다. 복음이라는 것은 숨어서 동하지 않고 굴(屈)하여 신(伸)하지 않고 지킬 때는 효과가 있다. 저촉되면 폐색되어 사(巳)를 얻지 못하므로 후에 동한다. 모두 상에 이롭고 하에 불리하며, 먼 곳에 이롭고 가까운 곳은 불리하다.
  삼전의 천반에 의해 지반을 보면 병(丙)은 사(巳)의 건록으로 되고 신금(申金)은 신(申)을 보아 건록이며 말전의 인목(寅木)에서 인(寅)을 보면 건록으로 된다. 따라서 원태(元胎)는 녹(祿)에 앉아서 길조로 된다. 백법에 말하기를 ｢주객이 투합하지 않는 것은 지형(支刑)이 상에 있다. 말전의 인(寅)은 일지의 역마로 되고 임신정마(任信丁馬)가 있을 때는 동의(動意)를 형성한다｣고 하였다. 자임(自任)이란 양일(陽日)은 스스로 강하게 되어 그 강(剛)에 맞게 사물(事物)에 지나치게 참견함으로써 불리한 손모를 초래할 우려가 있다.
  일간에서 일지상신은 십이운 태(十二運 胎)로 되어 쌍태의 임신을 정단하면 쌍둥이임을 알 수 있다. 비전에 말하기를 ｢초전은 일덕(日德)이 승하고 말전은 장생으로 되기 때문에 복음과 중의 길과(吉課)이다｣고 하였다. 
일귀는 역행하여 삼전이 구(勾)․사(蛇)․백(白)이고, 야귀는 순행하여 삼전이 공(空)․현(玄)․합(合)이다. 또 야귀(夜貴)는 해(亥)에 천을귀신(天乙貴神)이 승하여 귀(貴)가 천문에 오르는 격이며, 공명을 정단하면 반드시 효과가 있다. 입시․선거 등은 반드시 적중한다. 중전의 신(申)은 공망하여 단교공망(斷橋空亡)으로 된다. 일간에서 보아 재(財)가 공망하므로 구재는 이롭지 못하다. 대인(待人)은 단교공망(斷橋空亡)하여 오지 않는다. 중전의 공망은 요절공망(腰折空亡)이라고도 하는데 사물이 중도에서 좌절을 보게 된다.`
  ,
  // 2
  `  이 과는 4과 중에 제2과, 제4과의 두 곳에 상에서 하를 극하는 것이 있다. 금일이 양일(陽日)로 되면 양비(陽比)의 제4과 양(陽)의 극을 취하여 초전으로 하고 지일과(知一課)로 된다. 삼전은 퇴여격(退茹格)이고 중․말전이 공망하여 술(戌)을 초전으로 하는 참관격(斬關格)이다. 순수(旬首)의 술(戌)이 초전으로 되기 때문에 육의격(六儀格)을 구성한다.
  간상(干上)의 진(辰)은 일지를 극하고 지상(支上)의 해(亥)는 병(丙)을 극하여 교차상극(交車相剋)으로 된다. 퇴여공망(退茹空亡)은 전진할 수 없고 또 퇴(退)하면 액난(厄難)을 일으키기 때문에 지키는 것이 좋다. 모든 일은 해산(解散)하는 것이 좋고 도모하여도 유익하지 않다. 만사는 동류(同類)로 인하여 발생하고 이해(利害)가 서로 보조를 같이 하면 길로 된다. 서로 다른 방향으로 전진하는 것은 흉이다. 백법에 말하기를 ｢괴도천문(魁度天門)은 건너서 관격(關隔)이 있다. 초전에 병(丙)의 묘신(墓神)이 병관(倂關)하는 것은 인택(人宅)이 쇠퇴한다｣고 하였다. 비전에 말하기를 ｢일지상신에 해(亥)가 가해지고 천을(天乙)이 승하면 가정내에 신불(神佛)에 기도할 일이 있다｣고 하였다.
  일귀는 역행하여 삼전이 후(后)․귀(貴)․사(蛇)이고 귀신(貴神)이 옥(獄)에 들어간다. 야귀(夜貴)는 순행하여 삼전이 후(后)․음(陰)․현(玄)이다. 년명상신(年命上神) 또는 행년(行年)이 해자(亥子)에 해당하면 일야 두 귀신(貴神)에 끼이기 때문에 흉해는 해소된다. 초전이 천후(天后)의 수장(水將) 술(戌)에 승하여 내전(內戰)으로 되면 위의(危疑)를 만나고, 또 은(恩)을 베풀어 해(害)를 초래한다.`
  ,
  // 3
  `  이 과는 4과 중에 제2과, 제3과에 극이 있고 하에서 상을 극하며 축묘(丑卯)를 초전으로 하며 중심과(重審課)이다. 삼전은 역간전격(逆間傳格)이다.
  중전은 귀효(鬼爻)로 되어 일간을 극하여도 초전 축(丑)의 자손효(子孫爻)는 구신(救神)으로 되어 흉해를 제거한다. 술(戌)은 묘신(墓神)이 지(支)에 숨어서 일지의 택(宅)을 극한다. 유(酉)는 일몰(日沒)이고 해축(亥丑)은 야반(夜半)의 음기(陰氣)의 신으로 극음격(極陰格)으로 되어 암중(暗中)에 기도하는 상이다. 축(丑)은 순기(旬奇)이며 흉을 제거하여 길조로 변화한다. 일간일지는 수생목(水生木)․화생토(火生土)로 교차상생된다.
  백법에 말하기를 ｢삼전 중에 일귀(日貴)․야귀(夜貴)가 함께 있어서 두 가지 귀(貴)를 구한다. 일지상신이 묘(墓)로 택(宅)을 극하면 택지(宅地) 중에 복시(伏屍)가 있다｣고 하였다. 비전에 말하기를 ｢사신(蛇神)이 승하여 일지를 극하므로 가정내에 괴이한 사건이 자주 발생할 우려가 있다. 초전의 축(丑)은 명(明)에 향하지 않고 오히려 해유(亥酉)의 음지(陰地)에 들어가 사물이 암매(暗昧)하다｣고 하였다.
  일귀는 순행하여 삼전이 구(勾)․주(朱)․귀(貴)이고 구진은 유련(留連)의 일이 있다. 축(丑)의 지반 묘(卯)는 가정의 류신(類神) 주작(朱雀)이 승하여 구설(口舌)이 있다. 그러나 순기(旬奇)를 이루므로 흉은 극히 경미하다. 말전의 공망은 처음이 있고 끝이 없을 징조이다.`
  ,
  // 4
  `  이 과는 제4과만이 화극금(火剋金)으로 일상에서 일하를 극하고 오유(午酉)가 초전으로 되며 원수과이다. 일간상 인(寅)은 장생으로 되어 지(支)의 역마이다.
  일지상신 유(酉)는 일간의 재(財)로 되지만 공망한다. 즉 재(財)는 불급(不及)하여 힘이 없다. 
  유(酉)는 여자 고용인이다. 오화(午火)가 사용인에 임하고 백호․현무가 임하는 것은 색정(色情)에 의해 도주사가 있다. 
  원수과는 공명정대(公明正大)한 광명의 상으로 나가면 모든 일이 순리롭다. 삼전이 중신(仲神)일때 자오묘유(子午卯酉)가 점시(占時)로 되면 삼교격(三交格)으로 된다. 오(午)가 유(酉)에 가하여 사교(死交)라고 부르며 길조로 되지 않는다.
  백법에 말하기를 ｢백호가 둔간하여 귀(鬼)에 승하면 재(災)가 얕지 않다｣고 하였다. 비전(秘傳)에 말하기를 ｢오(午)는 말(馬)이다. 묘(卯)는 차(車), 자(子)는 화개(華蓋)로 된다. 이와 같은 것은 삼전이 허로 되지 않고 은상(恩賞)을 받는 경사가 있다.｣ 삼교격은 집안에 죄인을 은닉(隱匿)한다.`
  ,
  // 5
  `  이 과는 4과 중에 상하의 극이 없고 일간의 제2과, 제3과 천신(天神)에 사극(斜剋)이 있다. 양일(陽日)로 되면 양(陽)의 극이다. 제3과의 신자(申子)를 초전으로 하여 요극탄사과(遙剋彈射課)이다.
  삼전은 신진자(申辰子)의 수국(水局)이며 윤하격(潤下格)으로도다. 탄사란 내몸에서 극하므로써 탄환을 발사하는 것과 같기 때문에 탄사격이라고 한다. 삼전 중에 금(金)과 토(土)가 있으면 탄환이 강한 것으로 길흉도 힘이 있다고 본다. 그러나 이 과(課)는 금토가 함께 공망하여 화복(禍福)은 어느 것이나 경미하다. 일간․일지상신은 신사(申巳)와 자축(子丑)이 지합(支合)하여 교차지합(交車支合)이 된다.
  백법에 말하기를 ｢사과삼전이 모두 삼합회국(三合會局)하며, 삼전이 삽합수국(三揷合水局)하여 중귀(重鬼)로 되어도 4과 중에 토(土)의 제신(制神)이 있으니 중귀(重鬼)가 두려울 것이 없으며 흉해도 없다｣고 하였다. 
  만사가 기쁨이 겹치면 삼합․육합이 겹친 때문이고, 천후(天后)․육합이 있는 것은 결혼을 정단하면 남녀 자유 연애 관계로서 일녀격(泆女格)이다. 일녀(泆女)는 여자가 남자를 유혹한다. 비전에 말하기를 ｢천후(天后)․태음․백호가 삼전사과에 나타나면 남녀 관계나 부부는 어느 것이나 이별의 우려가 있다｣고 하였다. 초전이 공망하고 현무가 승하면 분실 또는 도난의 우려가 발생한다.
  일귀는 순행하여 삼전이 후(后)․백(白)․합(合)이고, 야귀는 삼전이 현(玄)․청(靑)․사(蛇)이다. 교차지합(交車支合)이 되면 교역(交易)․교관(交關)․타협사(妥協事)는 어느 것이나 성공된다. 그러나 해산(解散)․이별사는 불리하다. 제3과․제4과로 초전을 이루는 것은 정단 사건이 내부에서 발생하는 요건(要件)이다. 초전․중전이 공망하면 중․말전이 동하지 않으므로 말전의 일신을 중용하여 길흉을 정단하게 된다.`
  ,
  // 6
  `  이 과는 4과 상신에서 모두 극하는 섭해사절과(涉害四絶課)이다. 사맹신(四孟神)을 채용하여 자사(子巳)를 초전으로 한다. 일지의 자(子)는 일간상신에 와서 일간을 극하고, 타인 또는 상대는 나를 해치려 한다. 그러므로 경계를 요한다. 그러나 미(未)의 음신이 이것을 제어하므로 해는 경미하다. 4과 중에 3과뿐이므로 음의 불비과로서 이양일음(二陽一陰)이 다툰다. 불비는 사물의 부족 또는 결함이 있다. 3상은 하를 극하고 존장이나 어른에게 극을 당하여 침해를 받는다.
  백법에 말하기를 ｢과전의 상하 내외에 서로 극이 많고 중인(衆人)에 의해 미혹을 파급시킨다. 간지가 함께 극상(剋傷)받는 것은 인택과 상대방은 물론 나 모두에게 양손(兩損)이 있다｣고 하였다. 서로 의심하는 것은 육해(六害)때문이다. 이 과의 구법(舊法)은 삼전이 인유진(寅酉辰)으로 되어도 이것은 잘못된 것이다. 초전과 일간상신은 나를 극하고 말전을 생하므로 인(寅)은 장생으로 된다. 미토(未土)는 칠살관귀(七殺官鬼)를 제어하여 구신(救神)의 작용을 한다.
  일귀는 순행하여 삼전이 합(合)․음(陰)․청(靑)이고 청룡이 장생에 승하여 길조로 된다. 야귀는 삼전이 사(蛇)․상(常)․합(合)이다. 일지가 일간상에 와서 일간을 극하여 초전으로 되면 난수격(亂首格)이라고 한다. 가정은 불길하고 병을 간점하면 가장 흉하다. 난수란 일지의 하에 와서 일간의 상을 극하므로 예(禮)를 문란(紊亂)시키기 때문에 이와 같은 이름을 갖게 된 것이다.
  이 격은 인사(人事)가 내부에서 일어나서 외부에 미친다. 전체적으로 타인이 와서 내몸은 저해를 받는다. 이 과는 선조의 성씨(姓氏)를 바꾼다. 존비(尊卑)를 나누지 않고 사용인은 나를 원수(仇)로 한다. 혹은 가족이 나를 해치니 이 경우에 재(災)는 가족에 의해 발생한다. 남자는 서양자로 된다. 남녀가 서로 자기를 버리고 췌서하여 원수로 되기 쉽다. 그러나 제신(制神)이 있으므로 흉은 경미하다.`
  ,
  // 7
  `  이 과는 천지신(天地神)이 상충(相冲)하여 반음과(返吟課)로 된다. 사과삼전이 모두 극적(剋賊)이 있으므로 의지할 곳 없는 격이며, 자오묘유(子午卯酉)의 사중신(四仲神)으로 삼전을 구성하는 것은 삼교격(三交格)이라고 부른다. 와서 극하고 가서 극하기 때문에 진퇴양난이다. 이와 같은 것은 전후핍박(前後逼迫)이라고 한다. 병일(丙日)에 해(亥)가 승하여 결절격(結絶格)으로 되고 과거의 흉해재병(凶害災病) 등은 결절(結絶)한다. 즉 흉이 종국이다. 이것을 칭하여 구사결절(舊事結絶)이라고 한다.
  백법 중의 제100법을 여기에 설명한다. 정단하기 전에 이미 재화(災禍)로 인해 고생한 사람은 일간상신에 해(亥)가 승하여 흉조인것 같아도 과거에 이미 흉재를 만난 후이므로 오히려 구사(舊事)의 흉은 종결되었다. 일귀(日貴)가 승할 때는 완전히 종결(綜結)된다. 
  귀살(鬼殺)이 천을(天乙)에 동병하면 신기격(神祗格)이 된다. 이 격은 귀숭(鬼崇)이 아니더라도 병을 정단하면 신불(神佛)에 의해 해를 초래한다. 신상(神床)을 깨끗하게 하고 기도하면 재난은 해소된다.
  일귀는 순행하여 삼전이 현(玄)․합(合)․현(玄)으로 흉장이다. 야귀는 역행하여 삼전은 청(靑)․후(后)․용(龍)으로서 길장이다. 이와 같이 천장의 길흉을 다르게 하는 것은 일귀(日貴)를 쓰는 것을 흉조로 하고 야귀는 길과(吉課)로 변화한다. 삼전의 중신(仲神)은 삼교격(三交格)으로 된다. 자오(子午)는 도로(道路)의 신인 동시에 음양의 경계를 짓는 곳으로 명암(明暗)이 교차하고, 공사(公私)의 인사(人事)가 발생하여 말전의 천장에 의해 길흉을 결정한다.`
  ,
  // 8
  `  이 과는 제2과․제3과에 상하의 극적이 있고 제3과의 하에서 상을 극적(剋賊)한다. 사자(巳子)를 써서 초전으로 하는 중심과(重審課)이다. 삼전 사술묘(巳戌卯)는 주인격(鑄印格)이고 4과지만 3과뿐이다. 양일(陽日)은 양(陽)을 남기고 음(陰)을 제거하므로 일양이음(一陽二陰)으로서 일남(一男)은 이녀(二女)를 놓고 싸우는 삼각관계이다. 일간기궁(日干寄宮)의 사(巳)는 일지상신에 가하여 지지(地支)에 의해 극을 받는다. 서양자(壻養子)로 되고 가인(家人)은 또 처에 의해 극상을 받는다.
  주인승헌격(鑄印乘軒格)은 현임 관리라면 영달한다. 주인(鑄印)이란 술(戌)은 인수이고 사(巳) 중에 병화(丙火)가 있으며 술(戌)의 신금(辛金)과 합하여 단련하고 극조하기 때문에 이런 이름이 붙었다. 묘(卯)는 차(車)이므로 승헌이라고 이름을 붙였으며 기재를 만든다. 백법 제8법에 말하기를 ｢일지상신이 건록에 만나는 것은 권섭부정격(權攝不正格)으로 된다. 일간상신이 묘신에 숨어서 일야의 사신(蛇神)이 승하면 52법의 양사협묘격(兩蛇夾墓格)으로 된다. 병을 정단하면 복(腹) 중에 적괴(積塊)가 있거나 또는 위암으로 치료하기 어렵고 년명 또는 행년상신(行年上神)에 사신(死神)이 승하면 사망한다. 쟁사(爭事)는 유혈의 사건이 발생하고 소송은 최악의 상황을 초래한다｣고 하였다. 진(辰)의 충신을 즐겨 파묘(破墓)라고도 한다. 육처 중 어딘가에 있으면 흉을 제거한다.
  일귀는 순행하여 삼전이 상(常)․사(蛇)․공(空)이며 야귀는 역행하여 공(空)․사(蛇)․상(常)이다. 일간 또는 일지상신이 묘(墓)를 싫어하더라도 삼전 중에서 묘(墓)를 충(冲)할 때는 파묘(破墓)라고 하며 복묘(伏墓)의 작용을 하지 않고 흉조로도 되지 않는다.`
  ,
  // 9
  `  이 과는 제1과․제3과에 상하의 극적이 있고, 제1과는 하에서 상을 극한다. 유사(酉巳)는 종혁격(從革格)이다. 초전과 중전은 공망하고, 말전에 천공이 승하면 삼전이 모두 공(空)으로 된다.
  길흉이 모두 공허하고 형체를 이루지 못한다. 금국(金局)은 일간의 재(財)가 되지만 공망하므로 재(財)를 망실(亡失)한다. 중심은 후에 동하는 것이 유익하고 어떤 일도 개혁된다. 추월(秋月)에 정단하면 금(金)은 기력이 증가되고 만사는 유익하게 된다.
  일지상신 진(辰)에 백호가 승하여 일지를 극한다. 즉 귀묘(鬼墓)가 승하여 복시(伏屍)가 있다. 장소는 백호의 입지(立地)인 자(子), 즉 북의 방위임을 알 수 있다. 또 가정에 상사(喪事)가 발생한다. 일지상신 진(辰)이 승하면 가사(家事)는 곤란하고 어떤 일도 마음에 드는 것이 없다.
  일귀는 역행하여 삼전이 귀(貴)․상(常)․구(勾)이며, 야귀는 삼전이 주(朱)․음(陰)․공(空)으로 삼전이 모두 공허의 상태로 된다.`
  ,
  // 10
  `  이 과는 제1과의 하에서 상을 극하고, 신사(申巳)를 써서 발용(發用)으로 하는 중심원태과(重審元胎課)이다. 일지상신의 신금(申金)을 극하여 재(財)로 되고, 초전을 구성하여도 공망하기 때문에 먼저 재(財)를 소모하고 후에 얻지만 소재(小財)일 뿐이다.
  초전 신(申)은 중전을 생하고 중전을 말전을 생하여 체생(遞生)으로 된다. 내몸의 재(財)를 설(泄)하여 타인에게 혜택을 주는 상이다. 육합․귀신(貴神)이 있으나 공망하므로 원조가 있어도 공허(空虛)한 성원뿐이다. 초전․중전․말전은 어느 것이나 장생에 앉고 또 말전이 일간의 장생으로 되어 생기에 앉는다 할지라도 공망하여 퇴보장생이 되고, 무슨 일이나 기쁨이 있어도 지체되어 발생한다.
  백법에 말하기를 ｢이귀(二貴)는 어느 것이나 공망하면 기쁨도 공허로 되어 미약하다｣고 하였다. 비전(秘傳)에 말하기를 ｢삼전이 체생이면 중인의 힘을 얻어 시종 목적을 달성하지만 이 과는 공망한 것으로 성취의 뜻은 있어도 한화허언(閑話虛言)으로서 성의가 적다. 말전의 장생은 늦게 서서히 이익을 받는다. 일귀는 역행하여 사(蛇)․음(陰)․백(白)이고 일사(一事)도 이룩하지 못한다. 야귀는 삼전이 합(合)․귀(貴)․현(玄)이며 일귀에 비해 조금 낫다.`
  ,
  // 11
  `  이 과는 제4과의 진인(辰寅)이 하(下)에서 상(上)을 극하여 초전으로 하는 중심과(重審課)이다. 삼전의 진오신(辰午申)과 일신(一神)을 사이에 둔 간전격(間傳格)이며 등삼천(登三天)이라고 부른다. 일간일지는 서로 교차육해(交車六害)로 된다. 교섭․타협․상담 등 어느 것이나 서로 제 뜻을 주장하여 저해를 초래한다. 자존심을 신중히 하고 덕(德)을 수(修)하면 길조로 된다.
  순진간전과(順進間傳課)로서 등삼천격(登三天格)은 진(辰)은 청(靑)에 속하는데 구름이 가고 비를 만드는 정세이며, 세(細)가 변하여 대(大)로 된다. 그러므로 병점이나 소송 사건은 불길하다. 일신(一神)을 사이에 두고 삼전을 구성하는 것은 간격이 있어 즉시 이루어지지 않는다. 그러나 순(順)은 순조(順調)로 된다. 사오미신(巳午未申)은 상에 있어 천(天)을 나타내고, 해자축인(亥子丑寅)은 하에 위치하여 지(地)를 상징한다. 간전(間傳)은 12격이 있는데 그 안에 음양이 각각 육격(六格)으로 된다. 귀신(貴神)이 유(酉)의 지(支)에 서는 것은 여덕격(勵德格)이다. 몸을 삼가고 신앙(神仰)을 즐기며, 군자(君子)는 때를 기다린다.
  백법에 말하기를 ｢양귀(兩貴)가 모두 공망하면 기쁨이 있어도 허(虛)로 끝난다. 강색귀호(罡塞鬼戶)는 음모를 이룬다｣고 하였다. 비전에 말하기를 ｢백호가 인(印)을 띠고 택(宅)에 들어가면 부모의 신변은 재병(災病)에 걸린다｣고 하였다. 등삼천격(登三天格)은 공명 사관을 정단하면 달성할 수 있지만 이 과는 신(申)이 공망에 해당하므로 년명상신(年命上神)의 신(申)이 전실공망(塡實空亡)하면 길하지만 만약 신(申)이 없을 때는 처음은 있으나 결과가 없다. 일귀는 역행하여 삼전이 청(靑)․합(合)․사(蛇)이고 청(靑)은 사(蛇)로 변화하여 흉상(凶象)으로 된다. 야귀는 백(白)․청(靑)․합(合)이고 일귀에 의해 길하다.`
  ,
  // 12
  `  이 과는 제3과, 제4과의 2과가 함께 상에서 하를 극하기 때문에 양일(陽日)이 되면 양비(陽比)의 극인 인축(寅丑)을 초전으로 하는 지일진여격(知一進茹格)으로 된다. 제4과를 초전으로 하는 것은 맥월격(驀越格)이다.
  3과가 어느 것이나 극이 없고 제4과를 발용(發用)으로 할 때는 이 격으로서 점사(占事)의 건은 우연히 맥성(驀成)한다. 삼전 인묘진(寅卯辰)은 동방의 목기(木氣)로서 연여격(連茹格)으로 된다. 어떤 일도 서로 연속되어 발생하기 때문에 길사를 정단하면 길사가 겹치고, 흉사를 정단하면 화(禍)가 일사(一事)로 끊이지 않는다. 순연여(順連茹)는 모든 일이 전진하고 빠른 것을 상례로 한다.
  지일(知一)은 양과(兩課)의 극이 있는 것으로 동기(動機)는 양기(兩岐)에 걸치며 승부는 주(主)가 이긴다. 사람과 실물(失物)은 가까운 곳에서 찾아야 한다. 상에서 극하고 벗에 의해 외부를 다스린다. 삼전의 목기(木氣)가 일간을 생하여 모든 일은 호조(好調)이다. 일간은 왕성하고 일지는 극을 당하므로 사람은 왕성하고 가택이 좁다. 만사는 옛 것을 지키는 것이 좋고 망동은 불길하며 동하면 재(災)를 초래한다.
  백법에 말하기를 ｢사람이 많아서 거택(居宅)이 좁으며 나망(羅網)을 만난다. 즉 사과삼전이 지지(地支)의 전일지(前一支)를 구성하고 어떤 일이나 모사를 성립하면 졸작(拙作)을 이룬다｣고 하였다. 
  비전에 말하기를 ｢제3과에 축자(丑子)가 겹치는데 축(丑) 중에 우숙(牛宿)이 있고 자(子) 중에 여숙(女宿)이 있다. 자(子)가 축(丑)에 가해져 태상이 승하면 우녀상회격(牛女相會格)이라고 한다｣고 하였다. 부처합혼(夫妻合婚)이 성립되고 화합한다. 사람이 많아서 택이 비좁아도 넓은 가옥으로 이동하지 말아야 한다. 인묘진(寅卯辰)의 연여(連茹)는 정화(正和)라고 하여 모든 일이 온화하고 은탁(恩濁)의 격이다. 일귀는 역행하여 삼전이 백(白)․공(空)․청(靑)이고 야귀는 삼전이 현(玄)․상(常)․백(白)이다.`
  ,
]




  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
