
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,11,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,8,10,`특별있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,9,9,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,8,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,7,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,6,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,1,5,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,2,4,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,3,3,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,4,2,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,1,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,6,12,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`색정`,`외남 색정사 有`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면성공함`,`小`,`7`,`색정사有`,`매사결절`],
    [`매사 영속성없음`,`관사직업지위권리남편사는 선천적 기복재해를 겪음`,`大`,`단절기`,`증오도망`,`중중희愛`],
    [`미중흠리(美中欠利)`,`비용을 들여 일은 이루어지나 좋은 중에 흠이 있게 되는 상`,`大`,``,`일실성취`,``],
    [`폭객기능(暴客欺凌)`,`폭력배로부터 속임과 능멸을 받는 상`,`小`,``,`(관계설립)`,``],
    [`침체`,`외사 동요불안분산사-직위불안사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면성공함`,`大`,`8`,`침체소외`,`개신번민`],
    [`매사 영속성없음`,`직업직위불안남편남자사는 선천적 기복재해를 겪음`,`小`,`침체기`,`직위불안`,`폭객欺凌`],
    [`사불공현(私不公現)`,`私的은 무익하고 공적이라야 만 되는 것이 있는 상`,`小`,``,`미초희경`,``],
    [`능욕박해(凌辱迫害)`,`밤마다 아무도 모르는 능욕과 박해를 받는 상`,`大`,``,``,``],
    [`후원`,`후원조력 관련사`,`大`,``,``,``],
    [`급속`,`내사 가정 급속사`,`小`,`9`,`면모훼손`,`송쟁대립`],
    [`송쟁`,`내사 가정 송쟁대립사`,`小`,`소외기`,`관재형벌`,`급속사有`],
    [`출길가흉(出吉家凶)`,`밖으로 나가 활동하면 길하고 집에만 있으면 흉한 상`,`大`,``,``,`고위입각`],
    [`투서불용(投書不用)`,`투서헌책이 채택되지않고 급한 무서사도 불능되는 상`,`小`,``,``,`(대중이익)`],
    [`결속`,`결속단합으로 후원사를 크게 이루나 자녀災害 발생함`,`大`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`10`,`복잡단합`,`재난수난`],
    [`모친`,`모친 병 관련사`,`小`,`침해기`,`폭객기능`,`능욕박해`],
    [`범사불순(凡事不順)`,`매사 순조롭지 못한 상`,`大`,``,``,``],
    [`제살피제(諸侵被制)`,`여러 침해를 극복하고 이익을 취하기 위해 나서려는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`11`,`비용과출`,`남편갈등`],
    [`자식`,`자식 관련사`,`小`,`쇠퇴기`,`환성경사`,`엄목지상`],
    [`범모흠순(凡謀欠順)`,`모든 모망사가 순조롭지 못하고 흠이 있게되는 상`,`小`,``,``,`(눈이 기려`],
    [`음폐안녕(陰閉安寧)`,`음사를 폐하여 가정이 편안한 상`,`大`,``,``,`진상)`],
    [`형제`,`형제 관련사`,`大`,``,``,``],
    [`출행`,`외사 동요불안분산 岐路 출행이동사`,`大`,`12`,`기로출행`,`가정막힘`],
    [`막힘`,`내부 가정 막힘사`,`小`,`과시기`,`존비상몽`,`제난피제`],
    [`관길상부(官吉常不)`,`관직자는 길하나 일반인은 불리한 상`,`小`,``,``,``],
    [`이동수욕(移動受辱)`,`출행이동 때 당하는 망신사가 있는 상-복통 관련`,`小`,``,``,``],
    [`직녹`,`직위녹위 관련사`,`大`,``,``,``],
    [`화합`,`외사 상하 화합사`,`大`,`1`,`상하화합`,`색정사有`],
    [`색정`,`내녀 색정사 有`,`小`,`절정기`,`守閏咸宜`,`비용과출`],
    [`시비종성(始費終成)`,`시작할 때는 비용이 들더라도 결국 이루게 되는 상`,`小`,``,``,`재난전소`],
    [`복통수욕(腹痛受辱)`,`복통으로 인하야 면모가 훼손되는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`결속`,`개혁사를 음12월 완성하여 大財획득 후 부모災害 발생함`,`大`,`2`,`직위불안`,`침체공허`],
    [`막힘`,`외사 외교막힘사-음12월 개신사 완성으로 大財획득함`,`小`,`면모기`,`외교막힘`,`관재질병`],
    [`구재주화(求財主禍)`,`금전(女포함)을 구하건대 되려 화가 있게 되는 상`,`小`,``,`침해피제`,``],
    [`곤재질병(官災疾病)`,`관재나 질병사에 시달리는 상`,`大`,``,``,``],
    [`금전`,`금전 처 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`3`,`급속사有`,`면모훼손`],
    [`급속`,`외사 급속사`,`大`,`학습기`,`재난전소`,`혼인美滿`],
    [`이재취귀(利財取鬼)`,`금전을 구하건대 고위인에게 얻게 되는 상`,`小`,``,``,``],
    [`혼인미만(婚姻美滿)`,`혼인이 만족하고 머리를 어여쁘게 단장하는 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업관련사`,`小`,`4`,`개시수난`,`개신개혁`],
    [`개신`,`내부 가정 개신개혁사`,`大`,`개시기`,`간사은닉`,`투서헌책`],
    [`사주유암(事主幽暗)`,`일이 심히 어두운 상`,`小`,``,``,`(나쁨)`],
    [`간사은닉(奸邪隱匿)`,`관계불통-가택이사-간사은닉상태에 있는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`5`,`송쟁파재`,`남편갈등`],
    [`쟁송`,`외사 급속송쟁파재극처실종사-상해 우려`,`小`,`배양기`,`총시성재`,`身物손실`],
    [`이옥휴근(以獄休近)`,`고위인이 감옥에서 쉬는 격에 이르러 도움이 안 되는 상`,`小`,``,``,``],
    [`총시성재(總是成災)`,`모두 역시 재난 뿐인 상-형검(칼) 관련`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`남편`,`남편 관련사`,`小`,`6`,`관사`,`기로출행`],
    [`출행`,`내사 岐路 출행원행이동사`,`大`,`존재기`,`관직영전`,`일반흉함`],
    [`식녹공외(食祿空畏)`,`집안살림과 지위가 비게 되어 두려운 상`,`大`,``,``,`이사확정`],
    [`관천상흉(官遷常反)`,`관직자는 영전도약하고 일반인은 반대로 흉한 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`후원문서부친조력급속사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`후원문서모친조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,``,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`형제친구동료조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`자매친구동료조력원행사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,``,`직업직위불안남자갈등색정여식사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`금전사업처조력급속사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`금전사업여인조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,``,`관사직업남편충행사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정단속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 정정당당하고 신속하게 속결처리하는 것이 율함`,0,0,`당면 사안을 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 人`,0,0,`일이 거꾸로 진행되는 듯하여 퇴하려하나 진행하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체 연기시켜야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 당장 시시비비를 가리려 말고 이후로 연기해야만 결과가 반드시 율해지는 人`,0,0,`일을 공적을 피하고 사사적으로 진행함이 좋음**조건과 여건이 부족불비함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 세**만약 타인과 다툰다면 상대는 호가호위적 세를 과시할 뿐으로 피해가 없고 있다해도 가벼움`,0,0,`옳지 못한 일이 밝혀져 심중에 마치 천둥에 놀란 듯한 두려움을 갖고 있으나 조용히 있으면 두려운 사안이 자연 해소되는 人`,0,0,`신규사가 있음**입을 닫아야 함**직업이나 직위가 변경됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 세**만약 타인과 다툰다면 상대는 호가호위적 세를 과시할 뿐으로 피해가 없고 있다해도 가벼움`,0,0,`옳지 못한 일이 밝혀져 심중에 마치 천둥에 놀란 듯한 두려움을 갖고 있으나 조용히 있으면 두려운 사안이 자연 해소되는 人`,0,0,`여러 복잡한 일을 하나로 묶음**결과없이 반복되기만 하던 일이 비로소 밝아짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 사안을 뒤로 미는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`정대한 일들이 끓김**가정 존상이 아래 혈육을 핍박함**의심-가정쇠퇴적`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오니 신중해야 함`,0,0,`동요불안하고 반복무상한 人`,0,0,`신규사가 있음**이동사가 있으나 결정이 안 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체 연기시켜야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 당장 시시비비를 가리려 말고 이후로 연기해야만 결과가 반드시 율해지는 人`,0,0,`권리인정권한사-음11월이라야 가능**산속으로 깊이 숨고싶은 심정임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체 연기시켜야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 당장 시시비비를 가리려 말고 이후로 연기해야만 결과가 반드시 율해지는 人`,0,0,`개신개혁사가 있음-음12월이라야 완성됨-금전은 대득하나  부모신변에 재해가 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체 연기시켜야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 당장 시시비비를 가리려 말고 이후로 연기해야만 결과가 반드시 율해지는 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체 연기시켜야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 당장 시시비비를 가리려 말고 이후로 연기해야만 결과가 반드시 율해지는 人`,0,0,`여건 조건이 부족불비함**금전 또는 여성으로 인한 재난이 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체 연기시켜야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 당장 시시비비를 가리려 말고 이후로 연기해야만 결과가 반드시 율해지는 人`,0,0,`일이 순서적으로 진행됨**금전여인사 호조세 임-단, 적극적이면 되려 화됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 엿보고 있으나 공격하지는 않음`,0,0,`스스로 유약하다고 여기고 적극적 활동을 포기하면서 신음하는 人-신체가 강제되고 있는 경우도 있음`,0,0,`일이 순서있게 진행되고 금전(女포함) 획득기회가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동타발동동급속사`,`미사`,`신구중간사`,`내사`,`아표면사타이면사 겹침`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`표면사이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`합의됨`,`함의됨`,`합의됨`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`애증화해`,`애증화해`,`애증화해`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음8월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음5월`,2,1,3,`음11월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월`,3,3,6,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,1,1,2,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음4월`,1,1,2,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`정1월`,3,2,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월-연기 또는 파혼우려`,2,3,5,`음10월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월-연기 또는 파혼우려`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음10월`,3,2,5,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,2,3,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음10월`,3,2,5,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,1,3,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무를 거꾸로 하는 듯하나 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무를 사적으로 진행하고 종반에서야 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무에 부정을 꾸밈`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하고 복잡한 여러 일을 일관시켜 큰 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무가 의심을 받고 전체를 쇠퇴시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 업무도 동요분산배신사로 일관함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 업무에 대한 회피심을 가져 권리사를 결여시켜 음11월에 재 완성 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 결속단합하여 개신개혁으로 재정을 확보하려하나 음12월이라야 완성함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻고 업무도 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 종반이 허무함-단 1949,61,73,85,97,2009년 출생자는 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성품이 둔하고 조정능력이 있으나 업무 내내 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`多集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선費후費`,`進向`,`근`,`留`,`세입조력`,`남남서`,``,`정동`],
    [`10.11`,`集`,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선이후이`,`進向`,`  근`,`  留`,`주인조력`,`서남`,``,`동남`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인제압`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`상호대립`,`정서`,``,`남남동`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`다수제압`,`이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`북서`,`정남`,``],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`동남`,`남남서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압불가`,`이행`,`본인무력`,` 退背`,`  원`,`  去`,`주인가해`,`남남동`,`서남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`본인무력`,`退背`,`원근왕래`,`  去`,`세입조력`,`정남`,`정동`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인반대`,`이행`,`선攻후이`,`進向`,`원근왕래`,`  留`,`세입가해`,`동남`,`동남`,``],
    [`10.11`,``,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`이행`,`선攻후費`,` 退背`,`  원`,`  去`,`애증화합`,`정동`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대있음`,`이행`,`손損후이`,`進向`,`  근`,`  留`,`세입조력`,`동남`,``,`정남`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대없음`,`이행`,`권리양도`,`進向`,`  근`,` 留`,`무난`,`남남동`,``,`남남서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후이`,`退背`,`  근`,`  去`,` 무난`,`정남`,``,`동북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,"목기부정","木斷器入",`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,``],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,"없음",``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`신부정귀`,`신不定`,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`여귀`,`호주부인`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`신부정귀`,`신不定`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`음귀`,`금속器入`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사 원행이동기로사-내사 동요이별사`,`도적의혹근심사`,`매사 허성불성-세속허사자**의심놀램失재근심사`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음**승진영전사-부족`,`종교진출하면자립함`,`내사 급속사`,`과작자 생살대권 쥠-일반인 형벌 대흉`,``,`폭력배 의 속임수와 능멸사`,``,`중중희애사`,`외사 출행이동사`,`관직자 영전도약사-일반인 흉사`,`직녹권리상하화합사**내녀 색정사`,`주력사 모두 잘됨`,`외사 외겨막힘`,`투서헌책 나쁨-구설-실재사`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`모두 재난 뿐임`,``,`가택이사-간사은닉사`,`외사 송쟁파재실종사`,`탄식신음사-병,모사미수 원인`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁**관재`,`외남 색정사**외사 손해도망사`,`전도**빈궁`,`외사 동요분산사`,``,`재액해소**전화위복`,`빈궁**병재`,`재액해소`,`빈궁**관재`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁`,``,`빈궁**병재`,``,``,``,`침체驚懼사`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**외사 송쟁파재실종사`,`재난전소사`,`내사 원행이동기로사-내사 동요이별사`,`투서헌책-급문서 불득용-실재사`,`매사 영속성없음**부부반목사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`내사 급속사`,`고위입각-대중이익경영사`,``,`폭력배의 속임수와 능멸사`,`매사 위역사`,`관재 매우 심함`,`외사 출행이동사`,`미초희경사-권리부여받음`,`직녹권리상하화합사**내녀 색정사`,`도적악의-공격말 것`,`외사 외교막힘`,`觀書-실업처지-한가함`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`우유한가-실업-한가함`,``,`가택이사-간사은닉사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`전화위복`,`전도**빈궁**관재`,`외남 색정사**외사 손해도망사`,`전도**빈궁`,`외사 동요분산사`,`빈궁`,`재액해소**전화위복`,`관재`,`재액해소`,`빈궁`,``,`빈궁**병재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**관재`,``,`빈궁`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`가택이사-간사은닉사`,`매사 위역사**내사 원행이동기로사`,`재난전소사`,`내사 원행이동기로사-내사 동요이별사`,`투서헌책-급문서 불득용-실재사`,`매사 영속성없음**부부반목사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`내사 급속사`,`고위입각-대중이익경영사`,``,`폭력배의 속임수와 능멸사`,`매사 위역사`,`관재 매우 심함`,`외사 출행이동사`,`미초희경사-권리부여받음`,`직녹권리상하화합사**내녀 색정사`,`도적악의-공격말 것`,`외사 외교막힘`,`觀書-실업처지-한가함`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`우유한가-실업-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`외사송쟁파재실종사-재액해소`,`빈궁`,`전화위복`,`비눙**관재`,`외남 색정사**외사 손해도망사`,``,`외사 동요분산사**재액해소`,`빈궁`,`전화위복`,``,``,`빈궁`,`재액해소`,`빈궁**병재`,`음4,5월화재주의`,`전도**관재`,`음4,5월화재주의`,`전도**빈궁**관재`,`재액해소`,`빈궁`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`도적失세-잡음`,``,`음사폐호-가택안녕사`,`외사 송쟁파재실종사`,`탄식신음사-병, 모사미수 원인`,`내사 원행이동기로사-내사 동요이별사`,`모든재난극복-이익진취사`,`매사 영속성없음**눈이 가려진 상`,`종교진출하면자립함**불리고시-구설근심사`,`매사 영속성없음`,`종교진출하면자립함`,`내사 급속사`,`환성경사`,``,`가정부부불화사`,``,`중중희애사`,`외사 출행이동사`,`복통수욕사-이동사`,`직녹권리상하화합사**내녀 색정사`,`앙화소멸사`,`외사 외교막힘`,`미초희경사-하사받음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`도적失세-잡음`,`빈궁**병재`,``,`빈궁`,`재액해소`,``,`전화위복`,`관재`,`외남 색정사**외사 손해도망사`,`빈궁`,`외사 동요분산사**재액해소`,`빈궁`,`전화위복`,``,`빈궁**관재`,``,`재액해소`,``,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**빈궁**병재`,`재액해소`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`외사 외교막힘`,`미초희경사-하사받음-부족`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`도적失세-잡음`,``,`음사폐호-가택안녕사`,`외사 송쟁파재실종사`,`탄식신음사-병 모사미수 원인`,`내사 원행이동기로사-내사 동요이별사`,`모든 재난극복-이익진취사`,`매사 영속성없음**눈이 가려진 상`,`종교진출하면자립함**불리고시-구설근심사`,`매사 영속성없음`,`종교진출하면자립함`,`내사 급속사`,`한성경사`,``,`가정부부불화사`,``,`중중희애사`,`외사 출행이동사`,`복통수욕사-이동사`,`직녹권리상하화합사**내녀 색정사`,`앙화소멸사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**병재`,``,`빈궁**관재`,``,``,``,`전화위복`,`관재`,`외남 색정사**외사 손해도망사`,`빈궁`,`외사 동요분산사`,`빈궁`,`전화위복`,`전도**관재`,``,``,`전도**빈궁`,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`신물손실사`,`직녹권리상하화합사**내녀 색정사`,`외사 외교막힘`,`왕래사송계류사`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`혼인단장사`,`관재질병사`,``,`외사 송쟁파재실종사`,`재난전소사`,`내사 원행이동기로사-내사 동요이별사`,`모든 재난극복-이익진취사`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함**외사 손해도망사`,`매사 영속성없음**尊卑동침사`,`종교진출하면자립함`,`내사 급속사`,`도적잡기 어려움`,`재물유손실사`,``,``,`관재 매우 흉함`,`외사 출행이동사`,`복통수욕사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,``,`동요분산사`,`빈궁`,`동요분산사`,`전도**관재`,`동요분산사`,`전도**관재**병재`,`동요분산사`,`빈궁`,`동요분산사-재액해소**전화위복`,`관재`,`동요분산사**재액해소`,``,`동요분산사`,`빈궁`,`동요분산사**전화위복`,`빈궁`,`동요분산사`,``,`동요분산사`,`빈궁**병재`,`음4,5월화재주의**동요분산사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외사출행이동사`,`복통수욕사-이동사`,`직녹권리상하화합사**내녀 색정사`,`身물손실사`,`외사 외교막힘`,`왕래사송계류사`,`외사 급속사**외사 애증화합-송쟁파재극처가출사`,`혼인단장사`,`관재질병사`,``,`외사 송쟁파재실종사`,`재난전소사`,`내사 원행이동기로사-내사 동요이별사`,`모든 재난극복-이익진취사`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함`,`매사 영속성없음**尊卑동침사`,`종교진출하면자립함`,`내사 급속사`,`도적잡기 어려움`,`재물유손실사`,``,``,`관재 매우 흉함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁`,``,`전도**관재`,``,`전도**빈궁`,``,`빈궁`,``,`재액해소**전화위복`,`관재`,`외사 손해도망사**재액해소`,``,`외사 동요분산사`,`빈궁`,`전화위복`,`빈궁`,``,`관재`,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관재 매우 흉함`,``,`외사 출행이동사`,`복통수욕사-이동사`,`직녹권리상하화합사**내녀 색정사`,`身물손실사`,`외사 외교막힘`,`왕래사송계류사`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`혼인단장사`,`관재질병사`,``,`외사 송쟁파재실종사`,`재난전소사`,`내사 원행이동기로사-내사 동요이별사`,`모든 재난극복-이익진취사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**존비동침사`,`종교진출하면자립함`,`내사 급속사`,`도적잡기 어려움`,`재물유손실사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재**병재`,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,`빈궁`,``,``,``,`빈궁`,``,`빈궁`,``,`전화위복`,`관재`,`외남 색정사**외사 손해도망사`,``,`외사 동요분산사`,`빈궁`,`전화위복`,`전도**빈궁**관재`,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재물유손실사`,``,`관재 매우 흉함`,``,`외사 출행이동사`,`복통수욕사-이동사`,`직녹권리상하화합사**내녀 색정사`,`身물손실사`,`외사 외교막힘`,`왕래사송계류사`,`외사 급속사**외사 애증화합-송쟁파재극처가출사`,`혼인단장사`,`관재질병사`,``,`외사 소쟁파재실종사`,`재난전소사`,`내사 원행이동기로사-내사 동요이별사`,`모든 재난극복-이익진취사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**존비동침사`,`종교진출하면자립함`,`내사 급속사`,`도적잡기 어려움`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`빈궁**병재`,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,`빈궁`,``,`내녀 색정사`,``,`빈궁`,``,`빈궁`,``,`전화위복`,`관재`,`외남 색정사**외사 손해도망사`,``,`외사 동요분산사`,`빈궁`,`전화위복`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내사 급속사`,`관직자 생살대권 쥠-일반인 형벌 대흉-부족`,``,`폭력배의 속임수와 능멸사`,``,`중중희애사`,`외사 출행이동사`,`관직자 영전도약사-일반인 흉사`,`직녹권리상하화합사**내녀 색정사`,`주력사 모두 잘 됨`,`외사 외교막힘`,`투서헌책 나쁨-구설-실재사`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`모두 재난 뿐임`,``,`가택이사-간사은닉사`,`외사 송쟁파재실종사`,`탄식신음사-병,모사미수 원인`,`내사 원행이동기로사-내사 동요이별사`,`도적의혹근심사`,`매사 영속성없음**의혹놀램-失재근심사`,`종교진출하면자립함`,`매사 영속성없음**승진영전사-부족`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`존화위복`,`전도**빈궁**병재`,``,`전도**빈궁**관재`,``,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,`빈궁`,``,`빈궁**병재`,``,``,``,``,``,`전화위복`,`빈궁**관재`,`외남 색정사**외사 손해도망사`,`빈궁`,`외사 동요분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자**승진영전사-부족`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`내사 급속사`,`관직자 생살대권 쥠-일반인 형벌 대흉-부족`,``,`폭력배의 속임수와 멸사`,`중중희애사`,`외사 출행이동사`,``,`관직자 영전도약사-일반인 흉사`,`직녹권리상하화합사**내녀 색정사`,`주력사 모두 잘됨`,`외사 외교막힘`,`투서헌책 나쁨-구설-실재사`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`모두 재난 뿐임`,``,`가택이사-간사은닉사`,`외사 송쟁파재실종사`,`탄식신음사-병, 모사미수 원인`,`내사 원행이동기로사-내사 동요이별사`,`도적의혹근심사`,`매사 영속성없음**의혹놀램-失재근심사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 동요분산사`,``,`전화위복`,`전도**관재**병재`,``,`전도**빈궁`,``,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,`빈궁`,``,`빈궁**병재`,``,``,``,``,``,`빈궁**관재`,`폐한인**전화위복`,`외남 색정사**외사 손해도망사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자**의혹놀램-失재근심사`,`음7,8,10,11월방문자는 종교진출하면성공함`,`매사 허성불성-세속허사자**승진영전사-부족`,`음3,4,5,6,9,12월 방문자 종교진출하면 성공함`,`내사 급속사`,`관직자 생살대권 쥠-일반인 혈벌 대흉`,``,`폭력배의 속임수와 능멸사`,`외사 출행이동사`,`중중희애사`,``,`관직자 영전도약사-일반인 흉사`,`직녹권리상하화합사**내녀 색정사`,`주력사 모두 잘 됨`,`외사 외교막힘`,`투서헌책 나쁨-구설-실재사`,`외사 급속사**내사 애증화합-송쟁파재극처가출사`,`모두 재난 뿐임`,``,`가택이사-간사은닉사`,`외사 송쟁파재실종사`,`탄식신음사-병, 모사미수 원인`,`내사 원행이동기로사-내사 동요이별사`,`도적의혹근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외남 색정사**외사 손해도망사`,`빈궁`,`외사 동요분산사`,``,`전화위복`,`빈궁**병재`,``,`빈궁`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁`,``,`전도**빈궁**병재`,``,`전도`,``,``,``,`빈궁**관재`,`재액해소**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 변동수가 있네? 
  그대로 있기가 힘들겠어!    그런데 관재도 있겠네?     기도ㆍ치성하면 돈복이
  더 커지겠어!
`,`■  일이 꽉 막혔네?
    가택에 침범한 부정을       해소시켜야 속지 않고       내 것을 지킬 수 있겠네!

⊕⊖가택 목기부정이야!

⊕신변상가부정도 침범했네? 
`,`■ 색정사 때문에 가정이 
   무너질 징조네?

⊕신부정귀, 
⊖변사귀를 그대로 두고서는    망신살 뻗치겠어!
`,`■ 호가호위에 놀래겠어!
   그래도 가만있지는 못하     겠고…
근심해소가 될려면
⊕신부정귀, 
⊖변사귀 해소가 더 급하네!

⊕신변상가부정 침범 
⊖신변목기부정도 침범했어! 
`,`■ 마음속에 놀래는 일이 
   전화위복이 되겠고…
   나는 좋겠는데 자식 걱정이     생기겠어!

⊕신부정귀, 
⊖변사귀 발동이야!
`,`■ 골육상하가 등돌렸네?
   어른이 아래를 박대하고     있어!
  지금까지 일이 끝나겠고 !
⊕신부정귀ㆍ도로귀ㆍ음사부정 ⊖변사귀ㆍ자살귀를 해소해야 
  정리정돈이 되겠어!
`,`■ 만사 결정은 안되고 바쁘     기만 하겠어!
   가정도 움직여야 겠고 !
   자식문제에 속임수도 
   있겠고색정불화도 있고!
⊕신부정귀, 
⊖변사귀 해꼬지야! 
  해소시키면 밝겠어 !
`,`■ 목전에서 발목 잡힌 
   형국이네? 
   뜻대로 되는게 없겠어!
관직자는 子年月에 소원이루겠네!

⊕여귀ㆍ가택음사부정, 
⊖자살귀ㆍ가택음사부정
  때문이네!
`,`■火月(巳午),土月(辰戌丑未)에는 
   돈 벼락 맞겠네? 
   여러건을 하나로 확 바꿔서     좋기는 하겠는데 자칫
⊕신부정귀, 
⊖음귀가 관재가 방비되겠어!
`,`■근심걱정 끝! 기쁨시작이네?    하고자 하는 일을 마음껏    해 나가세요!  그런데 
⊕신부정귀, 
⊖음귀와 가택음사부정을 잡아    줘야 더 좋겠어!

⊕신변수망노부 부정 침범과 
⊖신변음사부정도 침범했네 ?
`,`■ 조건이 부족한데 간다고     가지겠어? 
   맨날 그 짝이 되고 말지!      모두 중단하고

⊕신부정귀, 
⊖음귀부터 달래놓고 뭘해도    해야지!
`,`■ 男：여자가 둘이네?
   女：돈은 좋은데 부모 
       신변에 재해가 있게         생겼어! 
       일이 위험해! 
그냥 그대로가 안전해!
 
⊖신변음사부정이 침범했네?`];
  const YOGYEOL1 = [`■ 간지가 녹을 껴안았다. 재물 노리는 도적을 쫓으라. 밤정단은 불행중 다행이 있고 정신이 많아 움직임이 빠르다.
  `,`■ 녹과 장생이 있어도 낮밤 모두 범이 있어 불안하다. 몸이나 마음에 손해만 나고 조금도 성사 되는게 없겠다.
  `,`■ 역마와 정마가 모두 보이니 사람과 집과 잘 어울린다. 낮정단은 귀인을 믿을 수 없고 밤정단은 귀인이 귀인을 만나 좋겠다.
  `,`■ 낮정단은 동정이 엇갈리고 움직인다. 도망자는 오지 않는다. 말타고 화살을 쏘니 살촉에 맞아 상처나겠다.
  `,`■ 천장은 뺏어가고 삼전은 생하니 득실이 반반이고 쑥대 화살에 놀란다. 속는 일을 계속 벌여 구설 관재의 해가 생긴다.
  `,`■ 밤정단은 돈과 여자로 부모가 나쁘고 낮정단은 귀인의 힘이 매우 약하다.
  가을철 낮정단은 화액을 조심하고 불화도 방지하라.
  `,`■ 손해 나는 것은 버려야 좋다. 이사ㆍ여행ㆍ변동이 있겠다. 밤정단은 문서 발동이 있고 낮정단은 귀인사로 움직이겠다.
  `,`■ 힘 없는 귀신이 사람에게 붙고 답답한 일은 집에 오니 움직이고자해도 뜻대로 안되고 지키고 있어도 헛놀램이 있겠다.
  `,`■ 두 귀인이 나와 집에 있고 밤정단은 천장이 금전을 돕는다. 
  너무 욕심내서 만족을 모르면 귀신이 되어 재앙을 준다.
  `,`■ 의심과 근심을 다 버리고 돈 벌 일을 시작하라. 이로 인해 살 길이 열리고 문관ㆍ무관도 다 좋다.
  `,`■ 밤정단은 귀인이 성을 내고 재물이 없어지거나 파혼사가 있겠다.
  낮정단은 귀인이 입을 다물고 힘도 약하고 승낙도 없겠다.
  `,`■ 서로 교섭하여 사이가 좋다. 구재는 빨리 서둘러라. 병이나 소송은 욕심 때문이고 낮정단은 귀인의 힘이 약하다.`]
  const SIBO1 = [`■가정에 식록이 풍부하다.
  구재사는 대득하나 다른 건은 남에게 속임을 당한다. 
  동요하고 가내에 불안사가 있다. 
  소송사가 있다.
  `,`■가정에 부정이 들었다.
  처음은 길하지만 결과는
  공허하게 된다.
  물러서면 흉하고 나아가면 좋다. 그러나 망동은 재난을 초래하므로 7일후에 움직여야 한다. 
  대개 실의가 없다.
  `,`■자손사에 속임수를 당하고
  있다.
  공사는 불리하고 음사에
  이롭다.
  구재는 풍파 후에 얻는다. 6개월 후 여자 사용인과의 연정사로 가정이 퇴락하는 일이 있게 된다. 
  女는 별리무덕ㆍ고독인이다.
  `,`■신변 동정이 엇갈린다.
  감추는 비밀로 놀래나
  비밀은 누설되지 않는다.
  조용히 집에 있게 되면 흉이 있게 되므로 외출ㆍ여행이 좋다.
  일은 신속히 진행되고 흉을 봐도 흉을 만나지 않는다.
  `,`■여러 사람의 원조ㆍ추천
  으로 복을 이루나 자식에게
  재해가 일어나게 된다.
  관직자는 좋고 일반인은 타인에게 속는 일이 있다. 
  기로에 서게 된다.
  ⊕일이 반복 될뿐 결과가 없다.
  春月 정단은 吉하다.
  `,`■가내 우환이 있고 가장을
  속이며 가인이 원한을 품고
  가해하려 한다.
  그러나 힘이 없다.
  겸양해야 한다. 존친의 반대와 기만을 받는다. 
  사점은 왕해도 가택은 쇠퇴한다. 
  집안에 여인의 사망사가 있게 된다.
  正月 임신사가 있다.
  `,`■저주사가 있다.
  활동이 비상하지만
  통하는 것이 없다.
  소모도 많고 안정이 안되며 헛되고 부실하다.
  만사 기복이 있고 ⊖반복될 뿐 결과가 없다. 
  배반당한 입장이다.
  `,`■가정에 부정이 들었다.
  자충수적 우환이 있다.
  권리를 상실한다.
  움직이고자 하나 중도에 정체되어 움직일 수 없게 된다. 조건이 규모를 이루지 못한다.
  冬月에 기회가 온다.
  그 이전에 어떤 부탁도 부실하다.
  `,`■⊕신변에 신불의 진노를
  받고, ⊖신변에 여인음사의
  구해를 받는다.
  개신ㆍ개혁으로 여자ㆍ금전의 이익을 얻게 되나 부모의 재해가 있게 된다.
  土月에 財가 있고 그 이전은 빈소리일 뿐이다.
  `,`■신변이 어둡고 침체하고
  곤란하다.
  신구사가 바뀌는데 있어
  의혹이 있다.
  타인이 와서 쟁단을 일으키고 가인이나 자식의 재해가 일어난다.
  단, 구재만큼은 기대할 수 있다.
  `,`■금전과 여자로 인해 
  재해를 초래한다.
  그러나 경미하게 된다.
  시작은 있어도 끝이 없다. 
  여건과 조건이 불비부족하여 중단하는 것이 좋다.
  구재는 여의치 않고, 맨손자는 얻게 된다.
  `,`■가택의 권리가 넘어간다.
  처첩이 동거한다.
  금전사는 속히 끝내야 좋다. 미루게 되면 어둡다. 
  어른의 재해가 있게 된다. 
  서리내린 다리 위를 달리는 말과 같아 자칫 관재가 야기 된다.`]
  const SIBO2_1 = [`■ 손모가정내사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 사업ㆍ후원內外근심사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 자손ㆍ박관동요신변사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다
  속는 일이 있고 자손 재액이 있다.
  `,`■官･夫,가택동요사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  은근하고 伏하라.
  가정 반복의 상이다.
  `,`■官･夫,가택동요사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.
  은근하고 伏하라.자손재해 예방이 필요하다
  `,`■財ㆍ女,가택개신사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 손모가택내사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 손모가택내사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■財ㆍ女,가정개변사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女,가정내사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 부모 재해 예방이 필요하다.
  `,`■財ㆍ女,가택개신사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女,신변내외사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/054/row-1-column-1.png`,
    `/static/images/sagwa/054/row-1-column-2.png`,
    `/static/images/sagwa/054/row-1-column-3.png`,
    `/static/images/sagwa/054/row-1-column-4.png`,
    `/static/images/sagwa/054/row-1-column-5.png`,
    `/static/images/sagwa/054/row-1-column-6.png`,
    `/static/images/sagwa/054/row-1-column-7.png`,
    `/static/images/sagwa/054/row-1-column-8.png`,
    `/static/images/sagwa/054/row-1-column-9.png`,
    `/static/images/sagwa/054/row-1-column-10.png`,
    `/static/images/sagwa/054/row-1-column-11.png`,
    `/static/images/sagwa/054/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 4과 십이천지반(十二天地盤)이 상하에 복(伏)하여 움직이지 않고 상극도 없다. 음일(陰日)은 일지상신을 초전으로 하고 일간에 왕하다. 4과는 사(巳)와 미(未)의 양과이고  오(午)의 건록을 사이에 두어 암록격(暗綠格)으로 된다. 가중에 식록(食祿)이 풍부하다.
    삼전 4맹신(四孟神)은 원태격이고, 사(巳)는 정신으로 된다. 중전 신금(申金)은 재동하며 말전 인(寅)은 일간을 생한다. 삼전의 천장 흉신은 흉조(凶兆)를 이루니 길이라 하더라도 경하다. 과전에 정신이 있으니 무슨 일이나 동조(動兆)는 신속하다. 
    원래 복음과(伏吟課)는 제반사가 복(伏)하여 통상 조용하지만 정신이 승하면 동요하는 일이 있고, 정신이 택옥(宅屋)에 있어 공(空)․구(勾)가 승하면 가내(家內)에 불안한 징조를 이룬다. 
    비전에 이르기를 ｢초․중․말전이 체극하고 말전이 재성이 되거나 인수가 되면 구재(求財)는 많이 얻게 되지만 다른 사건에서는 남에게 속임을 당한다｣고 하였다.
    일귀는 순행하여 삼전은 공․현․합이고, 야귀는 역행하여 구․사․백이다. 이 과는 병점하면 불량하다.`
    ,
    // 2
    `   이 과는 4과 중 제4과의 묘진(卯辰)에서 1상이 1하를 극하여 초전으로 되며 원수과이다. 삼전 묘인축(卯寅丑)은 퇴여격(退茹格)이다. 일산상신인 오(午)와 사(巳)는 왕록이 신(身)에 임하고, 초․중전은 일간을 생하여 왕성하게 된다. 
    말전의 축(丑)은 공망하므로 처음에는 길하고 마지막에는 허(虛)하게 되는데. 다만 퇴여(退茹) 공망이므로 오히려 진여(進茹)로 변화한다. 따라서  물러서면 흉이고 나아가면 좋다. 신(身)은 녹왕으로 되어야 길하지만, 망동은 재(災)를 초래하니 공망을 탈리한 뒤에 움직여야 한다.
    비전에 이르기를 ｢인(寅)에 백호가 승하고 인(印)에 임하면 고양이의 요건이다｣고 하였다. 혹은 가정 내에 근심거리가 생긴다. 천공이 초전에 승하면 무슨 일이나 실의(實意)가 결핍된다.
    일귀는 순행하며 삼전은 구․합․주이고, 야귀는 역행하며 삼전은 공․백․상이다. 귀신이 유(酉)에 앉으면 배덕격(背德格)이 되어 군자(君子)가 시(時)를 기다리는 상이다.`
    ,
    // 3
    `  이 과는 제4과 축묘(丑卯)에서 1하가 1상을 극하여 초전을 이루며, 중심간전격(重審間傳格)이다. 
    일간산신에 정신이 타고 중전은 역마로 되어 제반사(諸般事)는 동(動)을 의미한다. 일지가 간상에 가담하여 왕기로 되어 인택은 서로 친해진다. 
    초전의 공망은 과숙(寡宿)이고 중전 또한 공망에 들어간다. 따라서 처음과 중도(中途)는 모두 기력이 없다.
    삼전 축해유(丑亥酉)는 음기가 겹치기 때문에 공사(公事)에는 이롭지 않고 음사(陰私)에는 사리(私利)가 있다. 일은 혼미하여 나아가기 어렵다. 말전만이 실(實)하고 재동이 되기 때문에 구재는 풍파가 있은 후에 얻게 된다.
    비전에 이르기를 ｢말전의 유(酉)는 택상 묘(卯)와 충극한다. 유(酉)나 묘(卯)는 6수이기 때문에 6개월 후에 가정이 퇴락(退落)한다｣고 하였다. 금일의 사신이 유(酉)에 타는데, 유(酉)는 여자 사용인의 유신이다. 
    일귀는 순행하며 삼전은 주․귀․음이고, 야귀는 삼전이 구․주․귀이다. 귀신이 극지(剋地)에 임할 때는 공명․구직에 이롭지 않다.`
    ,
    // 4
    `   이 과는 4과 중 상하에 극이 없고 제4과 해(亥)에서 일간을 극하여 요극원태과(遙剋元胎課)이다. 초전은 역마로 되어 도망자는 멀리 간다. 갑인(甲寅)의 순(旬)은 해(亥)가 순미(旬尾)로 되어 초전을 이루는 폐구격이다. 물(物)에 의해서 파되지 않는 상으로 비밀을 누설하지 않는다. 병자에게는 불량하다. 
    초전․말전의 정신․역마는 동서남북의 정처가 없으니 외출하는 것이 좋으며, 조용하게 집에 있으면 흉을 초래한다.
    사과삼전(四課三傳)으로 산수(山水)의 상을 볼 수 있다. 예를 들면 인(寅)은 산(山)이고 해(亥)는 수(水)이기 때문에 산에 둘러싸인 수변(水邊)의 지(地)라고 본다. 초․중전은 역마가 재관에 있어 일은 신속하게 발생한다.
    일귀는 순행하며 삼전은 귀․현․공이고, 야귀는 삼전이 주․후․상이다. 이과는 해(亥)세서 계를 둔간(遁干)하기 때문에 명(明)과 암(暗)의 2귀로 된다. 그러나 일간상 진토(辰土)에서 이것을 제하므로 흉을 보아도 흉을 만나지 않는다.`
    ,
    // 5
    `   이 과는 4과 중에 상하의 극이 없고 일간을 비스듬히 극하는 해(亥)와 유(酉)가 있어 그로부터 극을 받는다. 해(亥)를 사용하여 초전으로 하기 때문에 제3과 해묘(亥卯)를 초전으로 하는 삼합목국 곡직격이다. 곡직(曲直)이란 먼저 직(直)하고 후에 곡(曲)한다.
    과전이 모두 음지이면 사사(私事)에는 좋지만 공사(公事)는 꺼린다. 귀신 주작이 절지(絶地)에 임하면 무슨 일이나 끝을 맺는 것이 좋다. 목(木)국이므로 춘월에 정단하는 것을 좋아한다.
    비전에 이르기를 ｢해사(亥巳)에 귀신이 승하면 물사반복(物事反覆)을 본다｣고 하였다. 말전에서 체극하면 사관공무(仕官公務)에 있는 사람은 길조를 이룬다. 상인(常人)이라면 타인에게 속는다. 역마 사(巳) 혹은 해(亥)를 보면 기로(岐路)에 서는 사건이다. 유(酉)의 재(財)가 공망의 지(地)에 앉으니 구재는 희망이 적다.
    일귀는 순행하며 삼전은 귀․상․구이고, 야귀는 삼전 주․음․공이다. 이 과는 삼전목국(三傳木局)하여 일간을 생하기 때문에 많은 사람들의 조력과 원조를 얻어 복을 초래할 수 있다.`
    ,
    // 6
    `   이 과는 3상에서 하를 극하여 섭해과로 되며, 지반 사맹의 극이 있어 유인(酉寅)을 사용하여 초전으로 한다. 4상이 모두 하를 극하면 사절액격(四絶厄格)이다. 일간상신은 내몸을 생하는 인수이고, 초전 유(酉)는 재동하며, 말전과 일지상신은 관귀(官鬼)이다. 그러나 진(辰)과 미(未)에서 제어하므로 흉을 보아도 흉을 푼다. 삼전 자형(自刑)의 지(支)뿐이면 자신의 부주의로 인해 해사(害事)를 초래할 위험이 있으므로 예방하여야 한다. 상에서 4극하면 어른(父)의 반대를 받아 평안하지 못하고, 교차육해(交車六害)가 되면 의심을 품으니 인정이 조화롭지 못하다. 
    일간은 생을 받고 일지는 극을 당하기 때문에 사람은 왕(旺)하지만 가택은 쇠퇴하는 상이다.
    자(子)는 부인의 신(神)인 사화(巳火)를 극하여 자사(子巳)에 가담하면 사(死)자가 되어 집안에 여인의 사망 사건이 있다. 삼전이 양귀를 싸고 천덕을 보면 상류인(上流人)에게는 기쁨이 있다.
    일귀는 순행하며 삼전은 음․청․귀이고, 야귀는 귀․백․주이다. 
    비전에 이르기를 ｢삼전 자형은 스스로 억제하고 모든 일에서 겸양(謙讓)하면 흉해를 초래하지 않는다｣고 하였다.`
    ,
    // 7
    `   이 과는 천지상충하여 반음과이다. 제4과의 하에서 상을 극하는 사(巳)를 초전으로 하고, 자축(子丑) 공망 순중(旬中)은 사(巳)가 정신으로 된다. 삼전은 사해사(巳亥巳)이고 사맹신은 원태격이다.
    일간상신은 일간에서 탈기되어 순공망(旬空亡)에 해당한다. 일간기궁 미(未)에서 사(巳)는 역마이고, 사(巳)의 지(支)에서 해(亥)의 역마가 중전에 있다. 반음(返吟)은 움직이는 상이고 정마도 역시 움직여 왕래가 분주하므로 안정되지 않는다. 일간 정(丁)에서 축(丑)은 묘신에 해당한다. 
    일간상신이 묘에 임하면 일이 암매하여 명랑하게 처사히기 어렵고, 폐색되어 통하기 어렵다. 따라서 충을 만나는 것을 좋아한다. 즉 음신 미(未)에서 충하여 구한다.
    원래 진(辰)과 미(未)는 일묘(日墓)가 되고, 술(戌)과 축야(丑夜)가 묘에 해당하면 야묘(夜墓)라고 한다. 일묘는 길흉이 신속하고, 야묘는 유연하고 늦다. 이 과와 같이 야묘가 일간상에 승하면 암에서 명으로 되어 제반사를 구제하는 작용을 한다. 만약 일묘가 승하면 이와 반대로 명에서 암으로 들어간다고 한다. 일간상신 축(丑)에 태음이 승하면 탈상탈(脫上脫)을 만난다. 정단사는 소모가 있거나 혹은 허작부실(虛作不實)의 상이다. 
    비전에 이르기를 ｢공묘(空墓)가 일간상신에 복(覆)하여 병단은 위독하다｣고 하였다. 
    일귀는 역행하여 삼전은 공․귀․공이고, 중전은 귀신귀효(貴神鬼爻)하여 일간상신에 임하면 욕심 때문에 과도한 재(災)를 초래한다. 야귀는 순행하고 삼전은 상․주․상이다. 이 과는 만사에 반복과 기복이 있다.`
    ,
    // 8
    `   이 과는 제1과․제2과․제4과에 상하의 극이 있고, 하에서 상을 극하는 사자(巳子)를 초전으로 하여 중심과가 된다. 
    일간상신 및 초전의 지반이 공망하고, 초전에 정신이 승하지만 지저(地底)가 공망하니 동의(動意)는 있어도 움직이지 못한다. 중전 술(戌)은 묘신에 해당되는데, 중도에 정체가 발생하여 움직여도 더 움직일 수 없다. 삼전 사술묘(巳戌卯)는 주인격(鑄印格)이지만 초전이 공망이므로 주인규모(鑄印規模)를 이루지 못하니 처음에는 흉이고 후에는 잘 성사된다.
    일간상신이 관귀라 하지만 술토(戌土)에서 제하므로 해가 없다. 제4과의 술(戌)이 중전에 승하면 참관격이고 4과의 사술묘(巳戌卯)자가 삼전이 되면 회환격(廻環格)이다. 무슨 일이나 하려고 하는 일마다 길이고, 해산사(解散事)는 어렵다. 관귀는 제가 있으니 재화는 피면할 수 있고, 병점은 다른 사람의 도움을 받아 양약을 얻는다. 년명상신에서 관귀를 제하면 이 역시 화환(禍患)이 해소된다.
    비전에 말하기를 ｢천공이 자(子)에 승하고 순공(旬空)에 해당하므로 다른 데에 기탁(寄託)하면 부실(不實)한 일을 당한다｣고 하였다.`
    ,
    // 9
    `   이 과는 제1과․제3과에 극적(剋賊)이 있고, 제3과의 하에서 상을 극하는 유사(酉巳)를 취하여 초전으로 하는 중심과이다. 삼전 유축사(酉丑巳)는 금(金)국이기에 종혁격이라 한다.
    중․말전은 공망하고 초전의 일신(一神)만 실(實)이다. 해(亥)와 유(酉)는 일귀와 야귀에 해당한다.. 즉 양귀가 일진(日辰)에 임하고 일간은 일간상신의 해(亥)를 만나 극을 당한다. 그러나 축(丑)의 토(土)신에서 제를 받으므로 흉을 만나도 경하다.
    일지상신의 유(酉)는 재(財)로서 재는 금전이요 물질이다. 재가 초전으로 되니 구재는 반드시 후하고, 양귀가 일지에 임하면 입학시험의 건은 매우 길이다.
    이 과는 말전 체생이 되지만 공망하면 진정(眞情)이 없으니 다만 입에 바른 말에 불과하다. 
    일귀는 역행하고 삼전은 주․음․공이고, 야귀는 삼전이 귀․상․구로 되며 구재는 유망하다.`
    ,
    // 10
    `   이 과의 재4과 신사(申巳)는 1하가 1상을 극하니 중심과이며, 삼전 신해인(申亥寅)은 사맹신(四孟神)으로 원태격이다. 일간의 묘신 술(戌)은 일간에 복(覆)하여 의혹이 있고, 초전 신(申)은 일간의 재성이 택상에 임하여 구재는 유망하다.
    삼전은 초전에서 체생하니 손윗 사람의 추천이 있다. 원태격은 노유(老幼) 모두 병단을 꺼린다. 원래 낡은 것을 버리고 새 것을 생한다. 즉 신구(新舊)가 바뀌어지는 건이다. 초전 신(申)에 육합이 승하면 금극목(金克木)으로 천신(天神)에서 천장(天將)을 극하여 내전이 된다. 타인이 와서 쟁단을 일으키고 자식이나 가인에게 재(災)가 있다. 혹은 중매인이 와서 결혼을 의논한다.
    일간상신 술(戌)의 천괴(天魁)가 신(身)에 임하면 무슨 일이나 곤란하다.
    일귀는 역행하고 삼전은 합․귀․현이고, 야귀는 삼전이 동․음․백이다. 중전 계해(癸亥)는 명신(明神)과 암신(暗神) 양귀(兩鬼)가 되고, 백호가 승하면 병환은 가장 두렵다.`
    ,
    // 11
    `   이 과는 4과중 제1과 유정(酉丁)의 극이 있을 뿐이니 이것으로 초전을 이루어 중심과로 된다. 4과 중 극이 적을 때는 정단사가 복잡하지 않고 한 사건이다. 만약 이와 반대로 극이 많을 때는 복잡하다. 4과이지만 3과 밖에 없으면 불비격에 해당한다. 양신이 완전하고 음신이 일위이면 2양1음으로 되어 음의 불비격이다. 
    사과삼전 중 오음(五陰)이 있으면 음기를 주관한다. 초전의 재가 해(亥)의 귀효(鬼爻)를 생하면 전재(傳財)가 귀(鬼)로 화한다고 하며, 재(財) 또는 여성의 일로 재화를 초래한다. 그러나 축미(丑未)의 제귀(制鬼)의 신이 있으니 재화는 경하다. 말전 축(丑)이 공망이면 축(丑)의 년월(年月)이나 년명상신이 축(丑)인 경우는 전실(塡實)하여 공망으로 하지 않는다.
    초․중전이 실하고 말전이 공망이면 머리만 있고 꼬리는 없다고 하여 무슨 일이나 소사(小事)에 그치니 흉조(凶兆)로 되며 군자․귀인에게는 길조이다. 과전 중에 귀신이 많으면 귀인․윗사람과의 면접은 방해가 없으며, 초전 재성에 파쇄(破碎)가 승하면 구재하여도 이루지 못한다. 그러나 맨손으로 구재하는 자는 수득(收得)하게 된다.
    삼전 유해축(酉亥丑)은 야간의 지(支)로서 음이 성하고 양이 쇠하는 상이다. 말전이 공망하니 시작은 있으나 끝이 없다. 
    일귀는 역행하며 삼전은 주․귀․음이고, 야귀는 삼전이 귀․음․상이다. 여덕격(勵德格)은 군자가 시(時)를 기다리는 격이다.`
    ,
    // 12
    `  이 과는 제1과 신정(申丁)의 극뿐이므로 신미(申未)를 초전으로 한다. 삼전은 신유술(申酉戌)의 진여격(進茹格)이고 신(申)과 사(巳), 오(午)와 미(未)는 교차지합(交車支合)이 되니 무슨 일이나 화순하다.
    일간상신 및 삼전 신유술(申酉戌)은 서방합(西方合)이 되고 재동(財動)이 되니 급하게 구재하는 것이 좋다. 재가 많으면 인수(印綬)의 존장을 극한다.
    일귀는 역행하여 삼전은 합․주․사이고, 야귀는 삼전이 동․귀․후이다. 
    삼전 신유술(申酉戌)은 유금격(流金格)이라 명명하고 서리 내린 다리 위를 달리는 말에 비유하여 어떤 일이나 위험한 징조를 이룬다. 또 간지가 각각 왕기, 즉 지반1위 전지에 앉으면 나망격(羅網格)이 되니 길이라고 자기를 지키는 것이 좋으며, 움직이고 모사하면 불리하다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
