import React, { useState, useEffect, Component } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import { toast } from "react-toastify";

// import Login from './Login';
import fireDb from "../firebase";
import Firebase from 'firebase';

const user = Firebase.auth().currentUser;

const Home = () => {
  const [data, setData] = useState({});
  const [sortedData, setSortedData] = useState([]);
  const [sort, setSort] = useState(false);
  const [auth, setAuth] = useState(false)

  const [textEmail, setTextEmail] = useState("")
  const [textPassword, setTextPassword] = useState("")
  
  Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // var uid = user.uid;

      // setAuth(true)
      // ...
    } else {
      // User is signed out
      setAuth(false);
      // ...
    }
  });

  useEffect(() => {
    setAuth(false);
    Firebase.database().ref().child("contacts").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, []);


  const onDelete = (id) => {
    if (
      window.confirm("정말로 삭제하시겠습니까 복구할수 없습니다. ?")
    ) {
      Firebase.database().ref().child(`contacts/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("처리완료되었습니다.");
        }
      });
    }
  };

  const handleChange = (e) => {
    setSort(true);
    Firebase.database().ref()
      .child("contacts")
      .orderByChild(`${e.target.value}`)
      .on("value", (snapshot) => {
        let sortedData = [];
        snapshot.forEach((snap) => {
          sortedData.push(snap.val());
        });
        setSortedData(sortedData);
      });
  };
  const handleReset = () => {
    setSort(false);
    Firebase.database().ref().child("contacts").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
  };

  const filterData = (value) => {
    Firebase.database().ref()
      .child("contacts")
      .orderByChild("status")
      .equalTo(value)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          setData(data);
        }
      });
  };


  function login(e) {
    e.preventDefault();

    console.log("Firebase.auth().currentUser", )
    Firebase.auth().signInWithEmailAndPassword(textEmail, textPassword)
    .then(()=> {
      setAuth(true);
    })
    .catch((error) => {
      setAuth(null);
      alert("입력한 이메일/비밀번호 입력란의 로그인 정보가 다릅니다. FIREBASE 인증시스템에 의해 이전 로그인 정보가 있다면 로그인 시도합니다.")
    });


    Firebase.auth().onAuthStateChanged((user) => {
      if(user){
        setAuth(user)
      }else{
        setAuth(null);
      }
    });

}


  if (auth == false ) {
      return(
          <div className="form_block">
              <div id="title">Login</div>
              <div className="body">
                  <form>
                      <input type="text" 
                      value={textEmail} 
                      onChange={e=> {setTextEmail(e.target.value)}} 
                      name="email" />
                      <input type="password" 
                      value={textPassword} 
                      onChange={e=> {setTextPassword(e.target.value)}} 
                      name="password" />
                      <input className="loginBtn" type="submit" onClick={login} value="Enter" />
                  </form>
              </div>

    <style>{`
    body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
    .form_block{
      width: 400px;
      display: table;
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
      background-color: rgba(0,0,0,.1);
      padding: 5px;
      border-radius: 5px;
    }
    .form_block > #title{
      background: #3d8577;
      padding: 10px;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      text-shadow: -1px -1px 0 rgba(0,0,0,.2);
    }
    .form_block > .body{
      background: #FFFFFF;
      padding: 10px;
      display: table;
    }
    
    input[type='text'],input[type='password'] {
      height: 35px;
      width: 100%;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 4px 5px;
      background: #fff;
      border: 1px solid #d0d0d0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
    .loginBtn{
      height: 35px;
      box-sizing: border-box;
      padding: 4px 10px;
      background: #f5f4ea;
      border: 1px solid #d0d0d0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      cursor: pointer;
    }
    .loginBtn:hover{
      background: #c3c3ba;
    }
    .registerBtn{
      float: right;
      background: #3d8577;
      color: #fff;
      height: 35px;
      box-sizing: border-box;
      padding: 4px 10px;
      border: 1px solid #d0d0d0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      cursor: pointer;
      margin-top: -35px;
    }
    .registerBtn:hover{
      background: #2d6358;
    }
    .Error{
      padding: 10px;
      background-color: #feeaea;
      border: 1px solid #fadadb;
      margin-bottom: 10px;
      border-radius: 3px;
    }
    `}
      </style>
          </div>
          )
  }


  return (
    <div style={{ marginTop: "100px" }}>
      <table className="styled-table">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>No.</th>
            <th style={{ textAlign: "center" }}>Name</th>
            <th style={{ textAlign: "center" }}>Email</th>
            <th style={{ textAlign: "center" }}>Contact</th>
            <th style={{ textAlign: "center" }}>Address</th>
            <th style={{ textAlign: "center" }}>Status(이전버전)</th>
            <th style={{ textAlign: "center" }}>Status2(확장버전)</th>
            <th style={{ textAlign: "center" }}>status3(태블릿)</th>
            <th style={{ textAlign: "center" }}>Status4(기독교)</th>
            <th style={{ textAlign: "center" }}>Status5(확장버전2)</th>
            <th style={{ textAlign: "center" }}>Status6(종사업등)</th>
            <th style={{ textAlign: "center" }}>Status7(PC/노트북)</th>
            <th style={{ textAlign: "center" }}>접근횟수</th>
            <th style={{ textAlign: "center" }}>최근일자</th>
            {!sort && <th style={{ textAlign: "center" }}>Action</th>}
          </tr>
        </thead>
        {!sort && (
          <tbody>
            {Object.keys(data)
            
            .map((id, index) => {
              // console.log("data[id]", data[id]);
              return (
                <tr key={id}>
                  <th scope="row">{index + 1}</th>
                  <td>{data[id].name}</td>
                  <td>{data[id].email}</td>
                  <td>{data[id].contact}</td>
                  <td>{data[id].address}</td>
                  <td>{data[id].status}</td>
                  <td>{data[id].status2}</td>
                  <td>{data[id].status3}</td>
                  <td>{data[id].status4}</td>
                  <td>{data[id].status5}</td>
                  <td>{data[id].status6}</td>
                  <td>{data[id].status7}<br />{data[id].contact_ip}</td>
                  <td>{data[id].date && Object.keys(data[id].date) && Object.keys(data[id].date).length}</td>
                  <td>{data[id].recent_date}</td>
                  <td>
                    <Link to={`/admin/update/${id}`}>
                      <button className="btn btn-edit">수정</button>
                    </Link>
                    <button
                      className="btn btn-delete"
                      onClick={() => onDelete(id)}
                    >
                      삭제
                    </button>
                    <Link to={`/admin/view/${id}`}>
                      <button className="btn btn-view">보기</button>
                    </Link>
                  </td>
                </tr>
              );
            })
            
        // .sort(function(a, b)  {
        //   return b.id - a.id;
        // })
        .reverse()
        }
        
          </tbody>
        )
        }
        {sort && (
          <tbody>
            {sortedData

            .map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.contact}</td>
                  <td>{item.address}</td>
                  <td>{item.status}</td>
                  <td>{item.status2}</td>
                  <td>{item.status3}</td>
                  <td>{item.status4}</td>
                  <td>{item.status5}</td>
                  <td>{item.status6}</td>
                  <td>{item.status7}</td>
                  <td>{item.date && Object.keys(item.date) && Object.keys(item.date).length}</td>
                  <td>{item.recent_date}</td>
                </tr>
              );
            })
            
// .sort(function(a, b)  {
//   return a.id - b.id;
// })

.reverse()
            }
          </tbody>
        )}
      </table>
      {/* <label>Sort By:</label>
      <select className="dropdown" name="colValue" onChange={handleChange}>
        <option>Please Select</option>
        <option value="name">Name</option>
        <option value="email">Email</option>
        <option value="contact">Contact</option>
        <option value="status">Status</option>
        <option value="status2">Status2</option>
        <option value="status3">Status3</option>
        <option value="status4">Status4</option>
      </select>
      <button className="btn btn-reset" onClick={handleReset}>
        검색필터 초기화
      </button>
      <br />
      <label>Status:</label>
      <button className="btn btn-active" onClick={() => filterData("Active")}>
        Active
      </button>
      <button
        className="btn btn-inactive"
        onClick={() => filterData("Inactive")}
      >
        Inactive
      </button> */}

    </div>
  );
};

export default Home;

