
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,9,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,8,`절반`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`위선`,10,7,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`위선`,11,6,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,5,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,4,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,2,3,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,3,2,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,1,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신뢰`,5,12,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,11,`절반`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,10,`절반`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`권리`,`권리사`,`小`,`6`,`重重喜愛`,`구설근심`],
    [`남편`,`남편 관련사`,`大`,`존재기`,`명예 침해`,`조력수혜`],
    [`엄목지상(掩目之象)`,`눈이 가려져 있는 격의 상태에 놓여있는 상`,`大`,``,``,`불리考試`],
    [`포도진퇴(捕盜進退)`,`도둑 잡는 여부-진퇴구별사`,`小`,``,``,``],
    [`박관`,`직장직위 불안사`,`小`,``,``,``],
    [`남자`,`남자 갈등사`,`大`,`5`,`가정불화`,`掩目之象`],
    [`여식`,`딸 관련사`,`小`,`배양기`,`타인施惠`,`(눈이가려)`],
    [`불리고시(不利考試)`,`시험사는 불리하고-없는 구설을 근심하는 상`,`小`,``,``,`진) 상`],
    [`암조독해(暗遭毒害)`,`숨겨진 독약침해를 당할 우려가 있는 상 `,`大`,``,``,``],
    [`후원`,`후원조력사(나쁜 일은 좋고 좋은 일은 허사)의 상`,`大`,``,``,``],
    [`문서송쟁`,`外급속강제손실 송쟁사`,`小`,`4`,`환성경사`,`이익진취`],
    [`부모`,`부친 관련사`,`小`,`개시기`,`급속관재`,`시종불성`],
    [`질병구설(疾病口舌)`,`질병 구설에 시달리는 상`,`大`,``,`傷身우려`,``],
    [`이어진취(利於進取)`,`이익을 위해 나아가는 상-초 불성함`,`小`,``,``,``],
    [`후원`,`후원조력사`,`大`,``,``,``],
    [`색정`,`內女 색정사`,`小`,`3`,``,``],
    [`결속`,`결속하여 음9월에 동지사를 완성하고 금전 처女에 훼절됨`,`小`,`학습기`,`능욕박해`,`색정출비`],
    [`몽침요사(蒙寢妖邪)`,`꿈자리가 괴이요기(妖奇)한 상`,`大`,``,``,``],
    [`재난전소(재난전소)`,`재난이 끝났는가-의 상`,`小`,``,``,``],
    [`직위`,`직위불안사`,`小`,``,``,``],
    [`극부(剋夫)`,`부부 반목대립불화사`,`大`,`2`,`부부불화`,`무례반목`],
    [`자식`,`자식관련사`,`小`,`면모기`,`명예훼손`,`간사은닉`],
    [`부부불화(夫婦不和)`,`부부 불화사`,`小`,``,``,`別室이전`],
    [`무례반목(無禮反目)`,`무례 반목의 상`,`大`,``,``,``],
    [`명예`,`수상(受賞)사`,`大`,``,``,``],
    [`자산`,`증녹(增祿)사`,`大`,`1`,`헌책불용`,``],
    [`막힘`,`외사 외교가 막힘`,`小`,`절정기`,`외교막힘`,`대립반목`],
    [`충난진퇴(冲難進退)`,`동요분산사로 진퇴가 어려운 상`,`小`,``,``,`송쟁사`],
    [`이어경영(利於經營)`,`고위에 안좌하여 널리 이익이 되는 방법을 강구하는 상`,`小`,``,``,``],
    [`색정`,`外男색정사`,`大`,``,``,``],
    [`형제`,`형재소모사`,`大`,`12`,`재난전소`,``],
    [`친구`,`친구관련사`,`小`,`과시기`,`색정사有`,`외화내허`],
    [`작사난성(作事難成)`,`주력사를 이루기가 어려운 상`,`小`,``,``,`觀書문화`],
    [`부처반목(夫妻反目)`,`부부 불화의 상`,`小`,``,``,``],
    [`박관`,`직위 불안사`,`小`,``,`가택이사`,``],
    [`극부(剋夫)`,`남편 갈등사`,`大`,`11`,`직위불안`,``],
    [`대재(大財)`,`개신 결속으로 음4,5월에  대재를 획득 후 부모 근심 상`,`小`,`쇠퇴기`,`관계불통`,`事事성취`],
    [`소득불상(所得不詳)`,`소득 관련 不보상사`,`小`,``,`개신개혁`,``],
    [`간사은닉(奸私隱匿)`,`개신개혁-가정색정별리사`,`大`,``,`부모근심`,``],
    [`금전`,`금전이익 관련사`,`小`,``,``,``],
    [`출행`,`출행원행이동 사업사`,`小`,`10`,`반목송쟁`,`부부상대`],
    [`색정`,`1남2녀 삼각관계사`,`大`,`침해기`,`애증화합`,`이심동요`],
    [`도망관기(逃亡官忌)`,`관의 눈을 피해 도망하는 상`,`小`,``,`파재극처`,`분산사`],
    [`총시성재(總是成災)`,`이리저리 봐도 재해 뿐인 상`,`大`,``,``,``],
    [`금전`,`금전사`,`小`,``,``,``],
    [`사업`,`사업사`,`小`,`9`,``,``],
    [`여인`,`여인관련사`,`大`,`소외기`,`사송계류`,`관재형벌`],
    [`명폐구봉(名閉口封)`,`이름이 지워지고 입이 막히는 상-사망 또는 죄수에 통함`,`小`,``,`개신개혁`,`손해가출`],
    [`면굴난해(免屈難解)`,`굴욕적인 처지가 해소되지 못하는 상-감옥 관련`,`大`,``,``,``],
    [`직위`,`직위불안사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`8`,``,``],
    [`매사 영속성없음`,`자식직업직위남편자식 잘 속는 일은 선천적 기복재해를 겪음`,`小`,`침체기`,`침체허사`,`남편갈등`],
    [`선흉후길(先凶後吉)`,`먼저는 흉하고 나중에는 좋아지는 상`,`小`,``,``,``],
    [`관재형흉(官災刑凶)`,`관재형벌이 매우 흉한 상`,`大`,``,``,``],
    [`관사`,`관공사`,`小`,``,`허사허성`,`愛憎화합`],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`7`,`동요불안`,`파재극처`],
    [`매사 영속성없음`,`관직직업권리남편남자사는 선천적 기복재해를 겪음`,`大`,`단절기`,`출행이동`,`손해가출`],
    [`병권만리(兵權萬里)`,`병권(兵權))의 큰 책임을 맡아 널리 알려지는 상`,`大`,``,`분산우려`,`우려`],
    [`수욕복통(受辱腹痛)`,`부끄러운 복통증상의 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`관사침해로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`직위불안 부부갈등으로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`부친후원문서조력으로 가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`모친후원문서조력으로 가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,``,`직위불안 남편갈등 자식문제로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`명예 자산직녹으로 가능 `,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`형제자매친구동료의뢰로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,``,`지위불안남편갈등가정침체여식문재로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`금전사업정처활용으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`금전상업연인활용으로 가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툼이 있다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주 소송조짐이 있는 人`,0,0,`물러나는 여건이나 되려 전진하게 됨**기도하면 효과 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 순을유화적 처신으로 이후로 미루는 것이 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응 유화적인 면모를 보이고 일을 지연시켜야 만이 결과가 반드시 유리해지는 人`,0,0,`이면적 판단**낮은자세**용호상박 격의 관계가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)**만약 상대와 다툰다면 피아 간 전의가 별로 없고 손상도 없으며 있다해도 가벼우며 유리함`,0,0,`상대의 공격으로 심중 몹시 근심하나 언행만 조심하면 자연 해소되는 人`,0,0,`신규사 있음**용호상방 격임**여건이 부족함**1녀2남 삼각관계 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 먼저 나서지 말고유순한 처신으로 사안을 뒤로 미뤄야만유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응 유화적인 면모를 보이고 일을 지연시켜야 만이 결과가 반드시 유리해지는 人`,0,0,`다사단합**외화내빈**종적없이 숨고싶은 심정**고독 각방 별리 주말부부 격 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툼이 있다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`가장 친하고 가까이 거주하는 자와 의논하여 선택하면 해소되는 人`,0,0,`모든 관계성이 단절됨**근친 윗사람이 아랫사람을 박해하는 사안이 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`분산동요불안배신**만약 상대와 다툼이 있다면 천지가 바뀐 격으로 간신이 있고 재난이 밖에서 오니신중해야 함`,0,0,`본인의 의지처가 없어 불안하고 일이 반복되는 人`,0,0,`신규사 있음**부부이심색정사 있음**애증관계 人`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툼이 있다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주 소송조짐이 있는 人`,0,0,`고위승진의 기회이나 지연되면 무망함**종적없이 숨고싶은 심정 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응 유화적 처신으로 일을 지연시켜야만 결과가 유리해지는 人 `,0,0,`다사를 하나로 묶는 개신개혁사 있음**가정색정사**이별 별리문제 기함**용이 구름을 얻어 비상하는 격 당도`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 맞서자 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응유화적 처신으로 일을 지체 연기시켜야만 결과가 유리해지는 人`,0,0,`여건 조건 환경 미완부족**1남2녀 삼각관계 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 유순한 자세로 사안을 뒤로 미뤄야만 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응유화적 처신으로 일을 지연시켜야만 결과가 유리해지는 人`,0,0,`고위발탁기회**사안이 띄엄띄엄 진행**색정사 문제 돌출**종적없이 숨고싶은 심정**일이 결과없이 반복되기만 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 자세로 사안을 이후로 미뤄야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 시시비비를 삼가하고 순응유화적 처신으로 일을 지체 연기시켜야만 결과가 유리해지는 人`,0,0,`일을 전진시키는 것이 유리함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 상대와 다툰다면 상대는 뜻이 동하나 중지됨`,0,0,`고등시험 또는 고위 병권에 취임하게 될 人`,0,0,`스스로 강하다고 자임함**신규사 있음**사안 반복을 겪음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`신구중간사`,`미사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`구사`,`기사`,`외사`,`이면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아타동시사`,`신사`,`미사`,`내외 동일사 `,`아 이면후회 사 타표면사`,`배신`,`보성`,`진실이나 무력함`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`구사`,`미사`,`내사`,`표면사`,`충성`,`악성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사`,`신구중간사`,`미사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동급속사`,`신사`,`기사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`신구중간사`,`미사`,`내사`,`이면사`,`충성하나 무력함`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`신구중간사`,`미사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아 권리 가져갈 인`,`아타동시발동사`,`신사`,`미사`,`아 외사 타내 동시사`,`아표면사 타 이면사`,`좌고우면`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`구사`,`기사`,`내사`,`표면사`,`충성`,`악성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동급속사`,`구사`,`기사`,`내사`,`이면사`,`좌고우면`,`보성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사`,`신사`,`기사`,`외사`,`표면 이면 동시사`,`배신`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠  `,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥-이전의사`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥 `,`쇠-공허이사필요`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허가택고민 이사필요`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`가택 싫증`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`반목대립 가택 동요불안`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-회화내빈`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-반목대립`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥-이사의사`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥 `],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`패`,`패`,`패`],
    [`화해`,`화해`,`화해`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  무력`,`무력`,`  무력`],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [`                 `,`                                  `,`                           `],
    [``,``,``],
    [``,``,``],
    [`  `,``,`  `],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  패`,`패`,`  패`],
    [`단합화해`,`단합화해`,`단합화해`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  패`,`패`,`  패`],
    [`애증화해       `,`애증화해`,`애증 화해`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음1월`,2,1,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음1월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음1월`,3,1,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음3월`,1,1,2,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음2월`,3,2,5,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음1월-연기또는 파혼우려`,3,2,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음12월`,2,2,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음11월`,3,1,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,4,`음8월`,2,3,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음1월`,1,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,3,2,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음1월`,3,3,6,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]

const columnCE = [
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 업무능력이 갈수록 공하하게되며 계자리가 속적이지 못함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`언어가 교묘하고 속아며 업무도 공허하고 자리가 계속적이지 못함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높으나 초중반에 실적이 없게 되고 임기 말년에 명얘를 세움`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 중반에 업무능력이 공하하다가 임기 말년에야 실적을 나타냄`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`동료의 원조를 받으며 초중반에는 피해와 손해를 입히게 되나 종반에 실적이 있게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높고 실적을 나타내게되나 분산동요불안사를 일으킴`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`동료의 원조를 받고 중반에 실적이 있다가 종반에 침체 허사로 마침`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높고 다사를 하나로 묶는 개신개혁의 효과를 내며 단합을 과시하는 실적을 나타냄`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 조급하며 초반에는 실적을 보이나 중 종반에는 허사 공허하게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`고집이 세고 초반에는 실적이 없으나 중 종반에 두각을 나타내서 치적을 이룸`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`고집이 세고 초반에는 실적이 없으나 중 종반에 두각을 나타내서 치적을 이룸`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`언어가 불성실하고 신뢰받지 못하나 이외로 실적을 이룸`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0]
]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`음월 적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`초반반대`,`불이행`,`후이`,`進   向`,`근`,`去`,`가택협소`,`정동`,``,`정동`],
    [`10.11`,`다집多集`,`內비주류`,``,`세밀함`,`초반승부`,``,``,`주인피해`,``,``,``],
    [`3.6\n9.12`,`집集`,``,``,`천천히`,`절대필요`,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`집集`,`내부반대`,`불이행`,`선난후이`,`進   向`,`원`,`留`,`주인후원`,`동남`,``,`동남`],
    [`7.8월`,``,`본인제압`,``,`신속처리`,`초반승부`,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,`절대필요`,``,``,``,``,``,``],
    [`3.6\n9.12`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`內반대 `,`불이행`,`선난후이`,`進   向`,`  근`,`留`,`주인무력`,`남남동`,`남`,`남남동`],
    [`7.8월`,``,`측근반대`,``,`상대무력`,`지구전`,``,``,``,``,``,``],
    [`10.11`,`음10월집`,`본인제압`,``,``,`종반승부`,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,`필요`,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`불이행`,`상대무력`,`進   向`,``,`留`,`주인무력`,`정북`,`정서`,``],
    [`7.8월`,``,``,``,`침체암매`,`지구전`,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,`종반승부`,``,``,``,``,``,``],
    [`3.6\n9.12`,`음9월집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`측근반대`,`불이행`,`先攻후이`,`退 \n背`,`원근왕래`,`去`,`주인박해`,`북북동`,`북서`,``],
    [`7.8월`,``,`내부조력`,``,``,``,``,`출세자손`,``,``,``,``],
    [`10.11`,``,`지연제압`,``,``,``,``,`출현`,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`본인반대`,`불이행`,`先攻후의`,`退 \n背`,`  원`,`去`,`주인박해`,`동북`,`남남동`],
    [`7.8월`,`집集`,`기세없음`,``,``,`동요불안`,``,``,``,``,``,``],
    [`10.11`,`다집多集`,`무시진행`,``,``,`분산됨`,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`요망`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`초반반대`,`불이행`,`아여건부`,`  退진 背`,`원근왕래`,`去`,`세입피해`,`정동`,`정남`,``],
    [`7.8월`,``,`내부제압`,``,`족`,`하극상受`,``,``,``,``,``,``],
    [`10.11`,`집集`,``,``,`상대침체`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`불이행`,`선이후난`,`退 \n背`,`  근`,`留`,`세입피로`,`북북동`,`서남`,``],
    [`7.8월`,`다집多集`,``,``,`신속처리`,`음4,5월 大`,``,``,`설기쇠약`,``,``,``],
    [`10.11`,``,``,``,``,`財 得함`,``,``,``,``,``,``],
    [`3.6\n9.12`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`본인반대`,`불이행`,`선이후난`,`進   向`,`  원`,`留`,`세입피로`,`정동`,`동북`,``],
    [`7.8월`,`다집多集`,`기세없음`,``,`신속처리`,`초반승부`,``,`이사이전`,`설기쇠약`,``,``,``],
    [`10.11`,``,``,``,``,`절대필요`,``,`권리양도`,``,``,``,``],
    [`3.6\n9.12`,`집集`,``,``,``,``,``,`됨`,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`집集`,`반대없음`,`불이행`,`선난후이`,`進   向`,`  원`,`留`,`주인피해`,`정동`,``,`정동`],
    [`7.8월`,``,``,``,`천천히`,``,``,``,`세입가해`,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`집集`,`반대없음`,`불이행`,`선이후이`,`進   向`,`  원`,`留`,`이전이사`,``,``,``],
    [`7.8월`,``,``,``,`느긋이`,``,``,``,`再이사心`,`남남서`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,`발생함`,``,``,``],
    [`3.6\n9.12`,`다집多集`,``,``,``,`3월 원`,``,``,``,``,``,``],
    [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,`반대없음`,`불이행`,`선이후이`,`進   向`,`  근`,`留`,`무난`,`동북`,``,`남남동`],
    [`7.8월`,``,``,``,`느긋이`,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`도로귀`,`교통사고`,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`성소참범`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`신부정귀`,`성소침범`,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`자살귀`,`水투신`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`자살귀`,`水투신`,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`자살귀`,`水투신`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`음귀`,`산소탈`,0,0,`묘좌`,`남남동`,`없음`],
    [``,``,``,0,0,`묘향`,`북북서`,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,`화원`,``],
    [``,``,``,0,0,`묘속`,`뱀굴`,``],
    [`가정`,``,``,0,0,`육친`,"고조모",``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`상가조문`,`상태`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`상태`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`상태`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`재해해소-이득진취사-부족`,`관재**애증가출사**내사급속사`,`눈이 가려진 격- 상해불능사`,`빈궁**관재**전화위복`,`불리 고시-없는 구설근심사`,`빈궁**전화위복`,``,`화합환성희사**가정막힘**외사급속강제손실사`,`가정불화사`,`빈궁**관재**내 여자 색정음사`,``,`음3,4,,5,6,9,12월 방문 부자**중중희애사`,`복통-도모사는 길함`,`명예자산권리**외교막힘**內반목대립송쟁`,`앙화소멸사`,`빈궁**병재**전도**외 남자 색정음사`,`희경사**내외쇠미침체`,``,`도적실세-잡음`,`빈궁**병재**가정동요분산사`,`음사폐호-가택안녕`,`빈궁**종업원 모사 공모`,`탄식신음사-병 또는 도모미수`,`폐한 경구 위역**매사불성**종교진출성공함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 출행이동원행사`,`매사불성**활인진출자립함`,`전화위복`,``,`존화위복`,``,``,``,``,``,``,``,``,`음4,5월 화재조심`,``,`음4,5월 화재조심`,``,``,`내사 출행이동사`,`애증**파재실종상해사 우려`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`탄식신음사-병 또는 도모사 미수`,`관재`,`재해해소-이득진취-부족**전화위복`,`관재**전도**애증가출사**내사급속사`,`눈이 가려진 격- 상해불능사`,`빈궁**전화위복`,`불리 고시-없는 구설근심사`,`빈궁**전화위복`,``,`화합환성희사**가정막힘**외사급속강제손실사`,`가정불화사`,`빈궁**내여자 색정음사`,``,`음3,4,5,6,9,12월 방문 부자**중중희애사`,`복통**도모사는 길함**실종사 우려`,`명예자산권리**외교막힘**內반목대립송쟁`,`앙화소멸사`,`빈궁**병재**외 남자 색정음사`,``,`희경사**내외쇠미침체`,`도적실세-잡음`,`빈곤**병재**가정동요분산사`,`음사폐호-가택안녕`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`매사불성**종교진출자립함`,`외사 출행이동원행사`,`매사불성**활인진출자립함`,``,`전화위복`,``,`전화위복`,``,``,``,``,``,``,``,`음4,5월 화재조심`,``,`음4,5월 화재조심`,``,``,`내사 출행이동사`,`애증**파재실종상해사 우려`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`음사폐호-가택안녕`,`빈궁`,`탄식신음-병 또는 도모사 미수`,`매사불성**종교진출자립함`,`재해해소-이득진취사-부족**내사급속사`,`관재**매사불성**활인진출자립함`,`눈이 가려진 격- 상해불능사`,`빈궁**전화위복`,`불리 고시-없는 구설근심사`,`빈궁**전화위복`,``,`환성희사**가정막힘**외사급속강제손실사`,`가정불화사`,`빈궁**내 여자 색정음사`,``,`음3,4,5,6,9,12월 방문 부자**중중희애사`,`급속송쟁**傷身우려-도모사는 길함`,`명예자산권리**외교막힘**內반목대립송쟁`,`앙화소멸사`,`빈궁**관재**병재**외 남자 색정음사`,``,`희경사**내외쇠미침체`,`도적실세-잡음`,`빈궁**가장동요분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`외사 출행이동원행사`,`애증가출사**전화위복`,``,`전화위복`,``,`전화위복`,``,``,``,``,``,`음4,5월 화재조심`,`복통**실종사 우려`,`음4,5월 화재조심`,``,`음4,5월 화재조심`,``,``,`내사 출행이동사`,`애증**파재실종상해사 우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`가정동요분산사`,`내사 출행이동사`,`음3,6,7,8,9,12월 방문 고위부자**가택이사`,``,`매사불성**종교진출자립함`,`재난전소사`,`빈궁**관재**활인진출자립함`,`급문사 투서헌책불용-금전실재사**내사급속사`,`부부반목사**조행(操行)문제**전화위복`,``,`빈궁**전화위복`,`남 모르게 당하는 능욕박해사`,`고위안좌-대중이익경영사`,`외사 급속사`,`빈궁**관재**내 여자 색정음사`,``,`빈궁**병재**위역사`,`관재형벌 흉함`,`희경사**內반목대립송쟁`,``,`빈궁**외 남자 색정음사**`,`도적악의-공격 하지 말 것`,`빈궁**관재`,`觀書-문화풍아`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`애증**파재실종상해사 우려`,``,`간사은닉`,``,``,``,`애증가출사**전화위복`,`외사 출행이동원행사`,``,`전화위복`,`전화위복`,``,``,``,``,``,``,`실종사 겪음`,`음4,5월 화재조심`,``,`음4,5월 화재조심`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`관재`,`觀書-문화풍아`,`애증**가정동요분산사**파재실종상해사 우려`,`내사 출행이동사`,`3,6,7,8,9,12월 방문 고위부자**가택이사`,``,`빈궁**매사불성**종교진출자립함`,`재난전소사`,`빈궁**관재**매사불성**활인진출자립함`,`급문사 투서헌책불용-금전실재사**내사급속사`,`부부반목사**관재**전화위복`,``,`빈궁`,`남 모르게 당하는 능욕박해사`,`고위안좌-대중이익경영사`,`외사 급속사`,`빈궁**내 여자 색정음사`,``,`빈궁**병재**위역사`,`관재형벌 흉함`,`희경사**內반목대립송쟁**전도(顚倒)`,``,`빈궁**외 남자 색정음사`,`도적악의-공격하지 말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`간사은닉`,``,``,``,`애증가출사`,`외사 출행이동원행사`,`전화위복`,``,`전화위복`,``,``,``,``,``,``,``,`음4,5월 화재조심`,`실종사 겪음`,`음4,5월 화재조심`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`빈궁**외 남자 색정음사**동요불안분산`,`도적악의-공격하지 말 것`,`빈궁**전도**동요불안분산`,`觀書-문화풍아`,`가정동요분산사`,`내사 출행이동사`,`음3,6,7,8,9,12월 방문 고위부자`,`동요불안분산**가택이사**간사은닉`,`빈궁**전도**매사불성**종교진출자립함`,`재난전소사`,`빈궁**관재**매사불성**활인진출자립함`,`급문사 투서헌책불용-금전실재**외사 출행이동사`,`부부쟁투사**관재**전화위복`,``,`빈궁**전도**전화위복`,`남 모르게 당하는 능욕박해사`,`고위안좌-대중이익경영사`,`외사급속사`,`빈궁**내 여자 색정음사`,``,`병재**전도**위역사`,`관재형벌 흉함`,`희경사**동요불안분산**內반목대립송쟁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재조심`,``,``,``,`애증**파재실종상해사 우려`,``,`재액해소`,``,`동요불안분산**재액해소`,``,`내사급속사동요불안분산사`,`애증가출사`,`동요불안분산`,``,`동요불안분산`,``,`동요불안분산`,``,`동요불안분산`,``,`동요불안분산`,``,`음4,5월 화재조심`,`실종사 겪음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`희경사**內반목대립송쟁**전도(顚倒)`,``,`빈궁**전도**외 남자 색정음사`,`도적악의-공격하지 말 것`,`빈궁`,`觀書-문화풍아`,`애증**가정동요분산사**파재실종상해사 우려`,`내사 츨행이동사`,`음3,6,7,8,9,12월 방문 고위부자**가택이사`,``,`빈궁**매사불성**종교진출자립함`,`재난전소사`,`빈궁**관재**매사불성**활인진출자립함`,`급문사 투서헌책불용-금전실재사`,`부부증오사**관재*전화위복`,``,`빈궁**전화위복`,`남 모르게 당하는 능욕박해사`,`고위안좌-대중이익경영사`,`외사급속사`,`빈궁**관재**내 여자 색정음사`,``,`빈궁**병재**관재**위역사`,`관재형벌 흉함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재조심`,`실종사 겪음`,`음4,5월 화재조심`,``,``,``,``,``,`간사은닉`,``,``,``,`내사급속사*애증가출사*전화위복`,`외사 출행이동원행사`,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`음3,4,5,6,9,12월 방문 부자**중중희애사`,``,`빈궁**관공직 승진**일반인 나쁨`,``,`목적성사**가정희사**외 남자 색정음사**재액해소`,``,`빈궁`,`觀書-문화풍아`,`빈궁**병재**가정동요분산사**출행사`,`모두 재난 뿐임**내사 출행이동사`,`가택이사`,``,`매사불성**종교진출자립함`,``,`빈궁**관재**매사불성**활인진출로자립함`,`급문사 투서헌책불용-금전실재사`,`빈궁**관재**전화위복`,`도적으로 놀래고 의심되는 실재사`,`승진영전사**전화위복`,``,`관재**병재**전도`,`관직자-생살대권 쥠- 일반인-형벌매우 흉함`,`빈궁**전도**내 여자 색정음사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`음4,5월 화재조심**재액해소`,`실종사 겪음`,`음4,5월 화재조심`,``,``,``,`애증**파재실종상해사 우려`,``,`간사은닉사`,``,``,``,`내시급속사**애증가출사`,`외사 출행이동원행사`,``,``,``,``,``,`외사 급속사`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`빈궁**전도**내 여자 색정음사`,``,`음3,4,5,6,9,12월 방문 부자**중중희애사`,``,`빈궁**관공직 승진**일반인 나쁨**재액해소`,``,`목적성사**가정희사**외 남자 색정음사**재액해소`,``,`빈궁`,`觀書-문화풍아`,`빈궁**병재**가정동요분산사**출행사`,`모두 재난 뿐임**내사 출행이동사`,`가택이사`,`간사은닉사`,`매사불성**종교진출자립함`,``,`빈궁**전도**매사불성**활인진출자립함`,`도적의혹근심사`,`빈궁**관재**전화위복`,`도적으로 놀래고 의심되는 실재사`,`승진영전사-기반부족`,``,`빈궁**병재`,`관직자-생살대권 쥠-일반인 형벌 매우 흉함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`음4,5월 화재조심`,`실종사 겪음`,`음4,5월 화재조심`,``,``,``,`애증**파재실종상해사 우려`,``,`전화위복사`,``,`전화위복`,``,`내사급속사**애증가출사`,`외사 출행이동원행사`,``,``,`전화위복`,``,``,`외사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`도적 추격-못잡은 격`,`빈궁**외사 급속강제손실사**재액해소`,``,`재물유실사**내 여자 색정음사**재액해소`,``,`빈궁**병재`,`복통**위역사`,`명예자산권리**기이빈궁**급속송쟁**傷神우려`,``,`身물손실사**외 남자 색정음사`,`왕래사송계류사`,`빈궁**내외쇠미침체`,`내사 출행이동사`,`혼인사**가택고민**가정동요분산사`,`관제질병사`,`빈궁`,`재난전소사`,`빈궁**매사불성**종교진출자립함`,`재해해소-이득진취사-부족`,`관재**전도**매사불성**활인진출자립함`,``,`가나 있으나 좋음 단, 부족**전화위복`,`신분이 다른 존비상몽`,`빈궁**관재**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,`실종사 겪음`,`음4,5월 화재조심`,``,`음4,5월 화재조심`,``,``,``,`애증**파재실종상해사 우려`,``,``,``,``,`외사 출행이동원행사`,`내사급속사**애증가출사`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`신분이 다른 존비상몽`,`빈궁**관재**전화위복`,`도적 추격-못잡은 격`,`빈궁**관재**외사 급속강제손실사**재액해소`,``,`재물유실사**내 여자 색정음사`,``,`빈궁**병재**`,`복통**위역사**도모사는 길함`,`급속송쟁**상해우려`,`외 남자 색정음사`,`身물손실사`,`왕래사송계류사`,`문관출세**색정음사`,`혼인사**내사 출행이동사`,`애증**파재실종상해사 우려**가정동요분산사`,`관재질병사`,`빈궁*전화위복`,`재난전소사`,`빈궁**매사불성**종교진출자립함`,`재해해소-이득진취사-부족`,`관재**전도**매사불성**활인진출자립함`,``,`가나 있으나 좋음**전화위복 단, 부족`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,`재액해소`,``,``,`실종사 겪음`,`음4,5월 화재조심`,``,`음4,5월 화재조심`,``,``,``,`전화위복`,``,``,``,``,`외사 출행이동원행사`,`내사급속사**애증가출사`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`관재**빈궁**전화위복**재액해소`,``,`빈궁전화위복`,`외사 급속사`,`화합환성희사**비용과출`,`가정불화사`,`빈궁**내 여자 색정음사`,``,`음3,4,5,6,9,12월 부자**중중희애사`,`복통**도모사는 길함`,`명예자산권리**기이빈궁**급속송쟁**傷身우려`,`앙화소멸사`,`빈궁**병재**외 남자 색정음사`,``,`희경사**내외쇠미침체`,`내사 출행이동사`,`빈궁**병재**전도**가정동요분산사`,`음사폐호-가정안녕`,`빈궁`,`재난전소사`,`매사불성**음3,4,5,6,9,12월은 종교진출성공자`,`관재**재해해소-이득진취-부족`,`매사불성**음7,8,10,11월은 활인진출성공해당자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,`실종사 겪음`,`음4,5월 화재조심`,``,`음4,5월 화재조심`,``,``,`도적실세 -잡음`,`애증**파재실종상해사 우려`,``,``,``,`외사 출행이동원행사`,`내사급속사**애증가출사`,`재액해소**기도성과`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  

  const CHOSA1 = [
    `■ 재산에 송사가 붙었으나 신음이나 할 뿐 움직일수가 없네? 관직자는 길하네 !
신불기도로 
⊕가택상가부정ㆍ도로귀를 잡고 
⊖수귀를 해소해야 재판도, 신규사도 풀리겠어!
`,`■ 남의 장담이나 믿고 벌이는 일이 안속고 가질까? 영달수단이 잘못되어가네?
⊕ 수귀가 방해하고⊖ 도로귀가 침범해서 돈만허비 되겠어!
⊕⊖신변 목기부정이
   들었네 ?
`,`■ 남한테 속는 운이네?
 上下 예의도 없고 먹구름속의 용이니 일이 되나?
권리를 잃네 !

⊕자살귀, ⊖도로귀ㆍ가택음사부정의 방해야.
`,`■ 불륜이네? 
  一女二男이야! 실속없이 화살을 맞게 생겼으니, 

⊕ 변사귀 해소가 필요하고, ⊖ 신부정귀를 풀어야해!
`,`■ 일단 도망이나 피신을 해야 후일을 도모할 것 아닌가? 그 형편에 女:⊖연상의 남에 남자와 연애하네?
 
⊕가택음사부정, 
⊖가택수망노부부정이 들었어!
`,`■ 골육간 등을 보이니 옛일이 끊어지지! 지금 선택의 기로인데 과욕만 없으면 흉화위길 하겠는데.
⊕요는 물에 빠져죽은 자살귀, ⊖도로귀의 장난이 큰거라!⊖신변 음사부정이 들었어!
`,`■ 부부가 색정불화에 딴마음이니 두가지 일이 발목을 잡지!

⊕수귀 
⊖자살귀를 잡아주고 예의를 차리면 겨우 일이 되겠네!
`,`■ 집 밑에 사골이 있어서 사람이고 집이고 어둡기만 하겠네! 의심․질투사도 있고 !
⊕ 자살귀, ⊖ 여귀까지 침범했으니 신명의 가호에 의지해야 펴지겠어!
⊕⊖신변음사부정이들었어 !
`,`■ 금전사가 한두가지가 아닌데 가을에 빛이 나겠어! 옛것을 고쳐 새것으로 하는건 좋으나 과욕하면 범법자로 몰릴거야!
`,`■ 부귀쌍전일세? 만사형통이야! 그런데 하나로 일관해야 해요! (밤정단은 계획이 위험하다)
⊕음귀, ⊖신부정귀를 해소하세요. 一男二女 삼각관계도 정리하고!
⊕신변음사부정이 들었어!
`,`■ 관재가 보이네? 
돈에 귀신이 붙어서 소득하고 흉이 오겠어! 

⊕가택목기부정, ⊖가택 상가부정을 해소해야 도망안가고 해결할 수 있어!
`,`■ 과거일이 우연히 큰복이 되네? 단순하게 따지지  않고 가도 일이 잘 되겠어!`,

  ];
  const YOGYEOL1 = [
    `■ 나는 상대에게 의존하고 집나간 이는 멀리서 돌아온다. 재녹이 저절로 모이니 묻는 것이 다 좋다.
`,`■ 두려운 귀살이 비었으나 병ㆍ송은 재발한다. 
밤정단은 규방융합하니 부부 가연이로다.
`,`■ 도와준다는 말을 믿지 말라. 內外 모두 무례하다. 돕는것이 되려 훼방이니 윗사람을 기대하지 말라.
`,`■  활이 있되 화살이 없다. 일도 소리뿐 실속이 없다. 위험하고 의심되는 일을 버리라. 오는 이가 있어 도우리라. 
`,`■ 먼저 죽고 나중에 살아난다. 먼저 미혹되고 나중에 깨닫는다.  화평함이 전혀 없으니 상하 모두 형벌 당한다.
`,`■ 피아가 모두 재앙이고 진행도 차질이 있다. 인택이 어두우니 일의 길흉은 말하기 어렵다.
`,`■  서로 반드시 은근하고 화합으로 이익을 거둔다. 삶의 토대에 귀살이 붙었으니 두루 실적이 없다.
`,`■ 뱀 두 마리가 굴 하나로 싸운다. 부리는 자가 저주를 한다. 진행사를 익히 살펴보니 좋은것이 아무것도 없다.
`,`■ 죽기로 지켜도 돈이 나간다. 밤정단은 더 그렇다. 하나를 얻으면 열이 나가고 있다.
`,`■ 타인의 천거가 있으나 밤정단은 믿기 어렵다. 윗사람이 아랫사람에게 굽혀야 소기의 목적을 이루게 된다.
`,`■ 재물에 암귀가 붙었으나 욕심으로 버리지 못한다. 만약 돈을 소득하면 집고치는데나 기부로  써버려라.
`,`■ 제4과가 초전이 되고 말전이 일간상신이다. 
이것을 조원격이라하는데 백발백중한다 하였다.`,

  ]
  const SIBO1 = [
    `■관송사가 있다. ⊕상가부정
  가택권리가 넘어간다.
  女는 시집가기를 원한다. 은복하여 動할수 없다. 신규사를 대기한다. 때가 아니다. 수덕해야 한다. 맹동하면 목적달성이 안된다. 신불에 기도하면 안태하다. 凶을 凶으로 제어한다. 신규사에 뜻이 있다.
  `,`■관송사가 있다.
  ⊖ 계획사는 위험하다.
  의심이 있고 경쟁자가 있다.
  이미 끝난일이 재발될 兆. 
  모든 일은 가내에서 비롯되고 內에서 생긴다. 
  가택은 넉넉하나 人은 쇠퇴하는 조짐이다. 희비의 말은 모두 허위가 된다. 
  他人의 호언장담을 믿지 말라. 진퇴양난이므로 그대로 지키고 가야 吉하다.
  正月에 임신사가 있다.
  `,`■家內 우환발생함. 가장속임
  家人이 원한품고 加害의 兆
  가운이 쇠퇴한다. 
  他人의 허위에 속는다. 
  上下의 예의를 잃었다. 
  貴氣를 잃었다. 
  상대는 이심이 있어 타협이 안된다. 가정이 편안치 못하여 갈등이 깊다. 단, 寅띠자는 上人의 후원을 얻는다. 
  ⊖ 음사부정듬. 
  모든 일이 연루된다.
  正月에 임신사가 있다.
  `,`■家內 우환발생함. 가장속임
  家人이 원한품고 加害의 兆
  이익사 有. 소득한다.
  심중놀람이 있으나 무해하다. 조용히만 있으라. 모든 교섭사와 인간관계는 화합 중에 적의를 품는다. 단, 기도ㆍ치성하면 凶이 吉로 化한다. 女ㆍ婦는 헌신한다. 
  一女二男의 삼각관계가 있다.
  `,`■家內 속임을 당한다.
  ⊕음사부정, ⊖수망노부부정듬
  폐지사를 부흥시키려고 한다
  저주사가 있다. 
  때를 기다려야 한다. 
  도망ㆍ피신이 좋다. 
  매사가 반허ㆍ반실적이다. 계획ㆍ모사는 고생이 많고 완성이 안된다. 
  人宅이 폐한되는 조짐에 있다. 夫婦가 음탕하다. 
  부정을 해소해야 한다. 
  늦게 기회가 오게 된다.
  `,`■자충수적 우환 있다.
  가택을 손상한다.
  ⊖행상으로 이익이 있다.
  ⊖계획사는 위험하다. 
  현재는 모두 불화의 상이나 결국 시종이 있고, 서로 손해가 있으나 과욕만 없으면 경사가 있게 된다. 단, 골육상배가 있고 上人이 기만 하고 下人의 災가 있다. 흉화위길하는 조건에 있다.
  正月에 임신사가 있다.
  `,`■자충수적 우환有, 허모多
  ⊖ 신불기도필요. 효과有
  ⊕ 정치인ㆍ관리탄핵사유조심
  모두 二事가 발목을 잡는다. 움직여지지 않는다. 계획도 생각뿐이다. 
  옛것은 끝난다. 그러나 예의로 처신하면 길행이 있게 된다. 
  夫婦가 二心으로 색정 동요한다. 가택을 손상한다. 
  왕래 반대의 兆이다.
  `,`■宅下에 死骨이 있다.
  병정단은 사망이다.
  人宅이 혼미하다.
  의심ㆍ경쟁사가 있다. 모든일이 어둡다. 선악이 혼합되어 변화가 심하다. 인내ㆍ평안심으로 적덕하면 신명가호를 받아 길이 된다. 현재를 이겨내야 한다. 부뚜막 동티를 해소하라. 家長이 신음ㆍ고로한다.
  正月에 임신사가 있다.
  `,`■⊕ 上人과 財를 따진다.
  ⊖ 文書ㆍ소송사의 財이다.
  신ㆍ택이 불리하다. 
  官災 兆. 財를 묻고, 財가 왕성하다. 
  財의 일이 여러건이다. 
  구개신취ㆍ개혁한다. 
  재기ㆍ재거를 위탁한다. 그러나 과욕을 부리면 범법하게 된다. 
  꿀속의 독약을 경계해야 한다. 용이 득운하여 비상하는 상이다.
  `,`■사물이 갈림길에 있다
  男ㆍ夫는 가정에 헌신한다.
  권리를 상실한다.
  ⊖ 계획사는 위험하다. 
  만사 계획사는 추천과 협조가 된다. 부귀쌍전하는 상이다. 어떤일이든 처음 조건과 一事로 결정해야 한다. 끌면 나쁘다. 다 좋으나 약간 기대치에 부족하다. 
  一男二女 삼각관계가 있다. 화합중 반복을 경계할 것
  `,`■관재가 있다.
  ⊕목기부정, ⊖상가부정듬
  財得하고 凶이 생긴다
  또는 가사건에 쓰게 된다. 上流人은 불리하다. 일반인은 주거의 고민이 있다. 
  무슨일이든 돈이 들어가야 일이 된다. 私事는 신불기도후 진행하면 효과가 있다. 현임관리 퇴직이 좋다.
  `,`■女의 임신사가 있다.
  모든 일이 이루어진다.
  과거의 일에 속한다.
  우연한 기회를 놓치지 말것. 전진하여 시종의 결과가 유익하다. 계획ㆍ모사는 적중한다. 단 활동을 하지 말고 앉아서 진행하라. 계획을 여러 가지 할 필요가 없다. 중도에 막혀도 계속가면 일이 성사 된다.`]
  const SIBO2_1 = [
    `■ 직녹사ㆍ신변외사이다.
내방조짐 상의 : 
조용히 있는게 이롭다.
은혜를 원수로 갚는 소송사가 있다.
`,`■ 가정내외 화합사이다.
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
가정에 우환이 있다.
`,`■ 자손사, 가정화합사이다.
내방조짐 상의 : 
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 부부이별, 가택내사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
직위·권리상실·남편 망조한다
`,`■ 속음, 가정부정이 들었다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
속는일이 있고 자손재액발생한다 재녀손실 예방 필요
`,`■ 가정 파재사이다. 
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
상해사에 주의하라.
`,`■ 후원사, 가택이심사이다.
내방조짐 상의 : 
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■官･夫, 가정갈등사이다.
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
가정에 우환이 있다.
`,`■ 금전ㆍ여 신변외사이다.
내방조짐 상의 : 
은근하고 伏하라.
부모 재액 예방이 필요하다
`,`■ 금전ㆍ여 신변외사이다.
내방조짐 상의 :  
원행 이동사가 있다.
움직임이 근본이고 동기가 발동한다.
`,`■ 자손ㆍ박관 가정부정이 듬.
내방조짐 상의 : 
은둔ㆍ도망ㆍ원행에 이롭다. 만사 피면에
의한다.
`,`■ 자손ㆍ박관 손해실주사이다.
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.이전․이사를 한다.
`
  ]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/003/003_001.png`,
    `/static/images/sagwa/003/003_002.png`,
    `/static/images/sagwa/003/003_003.png`,
    `/static/images/sagwa/003/003_004.png`,
    `/static/images/sagwa/003/003_005.png`,
    `/static/images/sagwa/003/003_006.png`,
    `/static/images/sagwa/003/003_007.png`,
    `/static/images/sagwa/003/003_008.png`,
    `/static/images/sagwa/003/003_009.png`,
    `/static/images/sagwa/003/003_010.png`,
    `/static/images/sagwa/003/003_011.png`,
    `/static/images/sagwa/003/003_012.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 십이신(十二神)이 각각 본궁에 있고 사과가 극이 없으면 일간상신의 사(巳)를 초전으로 하고, 형충(刑冲)을 취하여 중․말전으로 한다. 즉 일간일지의 음양신이 함께 같이 복(伏)하여 동하지 않는다. 정단사는 정(靜) 중에 동(動)이 있고 구태(舊態)를 지키면서 새것을 기다리는 과(課)이다. 초전은 일간(日干)의 사(巳)로부터 시작하고 말전은 지상(支上)의 인(寅)에 돌아온다. 이와 같은 것은 만사 내 몸을 타인에 기탁한다. 중전은 일지의 역마에 해당하고 말전은 지상(支上)에 돌아온다. 이와 같은 경우에는 기다리는 사람은 먼 곳에서도 반드시 온다.
    일간에서 바라본 초전은 건록으로 되어 중전의 재(財)를 극하고 중전은 말전을 극하므로 순극(順剋)하며 재(財)로 된다. 일간의 장생에 해당하므로 구재(求財)는 반드시 목적을 달성한다. 양일복음과(陽日伏吟課)는 자임격(自任格)이라고 하는데, 스스로 강하다고 자임할 때는 길로 되지 않으므로 조용하게 하는 것이 유리하다. 망동하면 정체되거나 장닉(藏匿)의 상태가 되어 구부러지면 펴기 어렵다. 바른 것을 지켜 덕(德)을 수(修)하면 길조로 되며, 귀신(貴神)이 유(酉)에 승하면 여덕격(勵德格)이 된다. 신불에 기도하여 군은(君恩)을 생각하면 자연히 안태하게 되어 흉해가 없다. 춘하(春夏)의 정단은 길하고, 추(秋)는 평(平)이며 동(冬)은 불길하다.
    백법에 말하기를 ｢일간기궁은 초전으로 되고 말전은 지상(支上)에 돌아온다. 이와 같은 것은 내가 그 피(彼)에게 구(求)한다고 한다. 육병일(六丙日)은 어느 것이나 길로서 초전이 덕록(德祿), 중전이 재성(財星), 말전은 장생으로 되기 때문에 공망을 기(忌)한다.
    비전에서 말하기를 ｢복음(伏吟)은 맹동(猛動)하면 목적을 이루지 못하고, 양일(陽日)은 대인(待人)을 정단하면 반드시 집에 오게 된다｣고 하였다. 일귀는 역행하고 삼전은 구(勾)․사(蛇)․백(白)이며, 야귀는 순행하여 삼전이 공(空)․현(玄)․합(合)으로 된다. 야귀는 해(亥)에 천을(天乙)이 승하여 귀인등천문(貴人登天門)으로 된다. 삼전 사맹(四孟)의 신은 현태격(玄胎格)이다. 정단사는 어느 것이나 새로운 뜻의 일로 된다. 출산․구직․구재․구혼은 어느 것이나 장생(長生)으로 대단히 길하고, 질병과 소송은 정체된다.`
    ,
    // 2
    `  이 과는 제2과, 3과, 4과에 극이 있고 양일(陽日)을 채용한다. 제4과 축(丑)에서 자(子)의 수(水)를 극하여 초전으로 된다. 즉 양일양비(陽日陽比)를 채용하므로 지일과(知一課)라고 부른다. 초전의 자(子)는 귀효(鬼爻)로 되고 해(亥)가 또 관효(官爻)로 되어도 공망된다. 일귀․야귀는 어느 것이나 공망한다. 질병의 정단은 관귀(官鬼)를 주로 하여 공망을 두려워하고 재발을 방지해야 한다. 무릇 이미 끝난 일도 다시 사단(事端)을 일으킬 징조가 있다.
    이 과는 관성(官星)․귀신(貴神)이 공망하기 때문에 관(官)을 구하여도 고생하며 효과를 거두지 못한다. 초전의 순수(旬首)가 갑자(甲子)로 되면 간기지의(干奇支儀)로 되고 순의(旬儀)가 발용(發用)으로 되는 것으로 흉을 만나도 능히 길로 변화한다.
    양일양비(陽日陽比)의 지일과(知一課)는 어떤 일도 동류의 일에 의해 발생하거나 외부로부터 오게 된다. 모든 계획과 작전은 다시 세우는 것이 좋다. 백법에 말하기를 ｢지(支)의 음신(陰神)을 초전으로 하는 것은 가귀(家鬼)가 되어 일지(日支)의 인(寅)을 생하고 일간의 병화(丙火)를 극하므로 가택이 관대(寬大)하게 되고 사람은 쇠퇴한다. 그러므로 가옥을 팔아 오히려 장래를 위해 축재(蓄財)하는 것이 좋다｣고 하였다. 이귀(二貴)가 모두 공망한 경우에는 기쁜 얘기도 허위의 빈말이므로 타인의 호언(好言)을 믿지 말아야 한다. 허희(虛喜)로 인한 출재(出財)․소비 등의 일이 발생하고, 퇴여공망(退茹空亡)의 경우에는 후퇴하면 재액(災厄)을 만나고 전진하여도 무익하므로 현실을 그대로 지키는 것이 가장 현명한 방침이다.
    술(戌)이 해상(亥上)에 승하는 것은 하괴(河魁)가 해(亥)를 건드리는 것과 같으며 모든 일은 조격(阻格)이 따른다. 일귀는 역행하고 삼전은 현(玄)․음(陰)․후(后)이며, 야귀는 순행하여 삼전은 사(蛇)․귀(貴)․후(后)로 된다. 중전․말전이 공망한데 홀로 사귀(蛇鬼)만이 존재하기 때문에 아름답지 않고 모든 점단의 대부분이 길조로 되지 않는다. 만약 점시(占時)가 수귀(水鬼)로 되면 천망격(天網格)으로, 점시는 현재로 가고 초전은 일의 시작으로 한다. 두 곳의 일귀(一鬼)로 되어 동하여 재(災)를 초래하고 또 조체(阻滯)되어 활동되지 못한다. 소송은 불리하고 병은 중하게 된다. 수귀(水鬼)는 부녀자의 병재(病災)이다.`
    ,
    // 3
    `  이 과는 제2과와 제4과의 상하에 극이 있고 하에서 상을 극한다. 제2과를 써서 초전으로 한다. 삼전중 전 유해축(酉亥丑)은 한자씩 앞의 지(支)가 없다. 이것을 역간전(逆間傳)이라고 부른다. 제1과와 제3과의 상신은 자묘(子卯)로 예(禮)가 없는 형(刑)이 어 상하의 예(禮)를 결하고, 일귀야귀(日貴夜貴)가 함께 공망하므로 귀(貴)를 구하여도 얻지 못한다. 간지상신(干支上神)이 패기(敗氣)로 되면 소송에 불리하다. 즉 병(丙)에서 보아 묘(卯)는 목욕(沐浴)이 되며, 일지 인(寅)의 목(木)에서 자(子)를 보면 목욕으로 된다. 이와 같은 것을 패기(敗氣)라고 한다. 일간을 생하고 일지를 상에서 생하여도 점차 가운(家運)에 패퇴(敗退)의 두려움이 있으며, 일간상신에 천공(天空)을 보면 타인으로부터 사위(詐僞)를 당한다. 일귀(日貴)는 귀신(鬼神) 해(亥)의 지(地)에 임하기 때문에 귀인은 천문(天門)에 오른다고 하며 흉화(凶化)하여 오히려 조체(阻滯)가 없다. 년명상신 자(子)를 보면 귀신(鬼神)을 섬기기 때문에 어른이나 존장으로부터 성원을 받는다.
    백법에 말하기를 ｢간지상신이 상기(上記)한 패기를 만나면 사람은 혈기(血氣)가 쇠퇴하고 가택을 정단하면 가업이 패퇴(敗退)하거나 모든 일이 연루된다｣고 하였다. 주객(主客)이 화기가 없는 것은 형(刑)이 있기 때문이다. 예를 들면 간상(干上)이 묘(卯)이고 지상(支上)이 자(子)이며, 간지상신이 각각 예(禮)가 없는 형(刑)으로 되어 주객이 서로 교섭하는 경우에는 이심(異心)을 품고, 소송의 건은 서로 패소한다.
    일귀(日貴)는 순행(順行)하여 삼전이 구(勾)․주(朱)․귀(貴)로 되고, 야귀(夜貴)로 순행(順行)하여 삼전(三傳)은 주(朱)․귀(貴)․음(陰)으로 된다. 길신이 승하여도 공망하여 길의 힘이 부족하다. 초전이 단독으로 실(實)하고 주(朱)․구(勾)에 의해 이것을 정단(正斷)해야 한다. 병을 정단하면 공망하여 위험하게 되고 구조(救助)의 손길이 없다.`
    ,
    // 4
    `  이 과는 4과 중에 상하의 극이 없고 천반에서 일간을 극하여 초전으로 된다. 요극과(遙剋課), 호시격(蒿矢格)이며 길흉이 모두 경미(輕微)하다. 초전의 해(亥)가 공망하므로 활을 취하고 화살을 잊어버린 형상이다. 소리는 크지만 관귀(官鬼)는 두려울 것이 없다. 중전의 재(財)는 공망의 위에 있어서 별로 세력이 없으며, 일지의 인(寅)은 일간상신에 와서 일간을 생한다. 그리하여 조용히 지키면 길조를 얻는다.
    초전 귀살의 공망은 동요하여 인정(人情)이 역(逆)한다. 방문․내객(來客)․대담(對談)․교섭 및 기타는 화합 중에 나에게 적의(敵意)를 품는다. 그러나 공망하여 실해(實害)를 받지 않는다. 구설․관사(官事) 등은 서남(西南)에서 발생하게 된다. 노유(老幼)를 정단하면 대단히 길하다. 해(亥)가 공망함으로써 해(亥)의 전실(塡實)로 되고 해(亥)의 년월시(年月時)에 정단하는 것은 목적을 이루게 된다.
    백법에 말하기를 ｢그 피(彼)는 나를 구한다｣고 하였다. 즉 일지가 와서 간상(干上)에 오르는 것을 말한다. 일간이 인(寅)을 보아 장생(長生)으로 되고, 일지의 인(寅) 목(木)은 해(亥)를 보아 장생으로 되는데, 이것을 호생구생(互生俱生)이라고 하며 모든 일은 길리(吉利)하다. 귀신(鬼神)이 관귀(官鬼)에 승하는 것은 천을신기격(天乙神祇格)으로 된다. 청룡이 장생에 가하여 길(吉)은 점차 이룩되고 발전한다.
    비전에 말하기를 ｢간상신은 간(干)을 생하고 지상신은 지(支)를 생하여 장생을 만나며, 피아화순(彼我和順)으로 타협하고 공동 경영은 어느 것이나 길행(吉幸)하다｣고 하였다.
    일귀는 순행하여 삼전은 주(朱)․후(后)․상(常)으로 되고, 야귀는 삼전이 귀(貴)․현(玄)․공(空)으로 된다. 일간상신 인(寅)에 청룡이 승하여 즉 일귀는 야귀를 이긴다. 초전의 관(官)은 공망하고 중전 신(申)의 재(財)는 지저공망(地底空亡)에 승하기 때문에 타(他)에서 재관(財官)을 구하는 것은 가능하지 못하다.`
    ,
    // 5
    `  이 과는 제3과 인(寅)의 지신(地神)에서 술(戌)의 천신(天神)을 극한다. 이것을 초전으로 하여 중심과(重審課)이다. 삼전 술오인(戌午寅)은 삼합화국(三合火局) 염상격(炎上格)이다. 화기(火氣)가 성한 것은 세력이 크게 되나 초․중전이 공망이면 명(明)이 오히려 암(暗)으로 된다. 따라서 계획과 모사(謀事)는 고생이 많고 이루지 못한다.
    초전은 일간의 묘(墓)로 되고 말전은 일간의 장생으로 된다. 그러므로 처음에는 미혹되지만 후에 길조로 된다. 장생에 묘신(墓神)이 승하는 것은 모두 재거(再擧)․재발(再發)․재기(再起)의 뜻이 있어 병환은 다시 발병한다. 일간상신과 일지상신이 상형(相刑)하는 것은 상대방이 있으면 상호 화기(和氣)가 없다.
    갑자(甲子)와 갑술(甲戌)의 순(旬)은 순기(旬奇)로 된다. 제1과에 순기로 되는 것은 사람의 위에 군림하는 직위에 오르며, 일반의 정단은 흉해를 퇴산(退散)한다. 만약 묘(卯)의 년명(年命)인 사람이라면 명상(命上)에 해(亥)가 있으니 귀신(貴神)이 해(亥)에 승하면 귀(貴)는 천문(天門)에 오른다고 하여 권리를 얻어 요직(要職)에 오르게 된다. 화기(火氣)가 성한 것은 모든 일에 관대(寬大)하면 피해가 없고 정단사는 반허반실(半虛半實)의 징조가 있다.
    백법에 말하기를 ｢존숭(存崇)하는 것은 삼전 내에 삼기(三奇)를 만나고, 처음은 어려우나 후에 쉬우며, 일간의 묘신(墓神)이 병관(竝關)｣하면 인택(人宅)이 함께 폐(閉)하는 일이 있다｣고 하였다. 비전에서 말하기를 ｢술(戌)의 괴(魁)는 지(支)에 임하고 중전이 백호를 승하여 지괴(地魁)가 초전으로 되어 참관격(斬關格)을 구성한다. 도망은신(逃亡隱身)하면 이익이 있다｣고 하였다.
    일귀는 순행하여 삼전은 사(蛇)․현(玄)․청(靑)으로 된다. 사신(蛇神)이 화(化)하여 청(靑)을 얻는 것은 공명(功名)을 정단하면 경사가 많으며 시험은 반드시 적중한다. 또한 비상한 기쁨을 만난다. 야귀는 삼전이 후(后)․백(白)․합(合)으로 된다. 귀신(鬼神)이 묘유(卯酉)에 승하는 것은 여덕격(勵德格)으로서 군자(君子)는 초려(焦慮)하지 않고 때를 기다려 활동해야 한다.`
    ,
    // 6
    `  이 과는 제1과․제2과․제3과는 상에서 하를 극한다. 오늘은 양일(陽日)이므로 양비(陽比)를 취하여 제1과의 자사(子巳)를 초전으로 하여 지일비용과(知一比用課)로 된다.
    일간은 자(子)의 수(水)에 극되며 일지 인(寅)은 유금(酉金)에 의해 극되어 피아(彼我)가 함께 재(災)로 되어도 제2과에서 자(子)의 귀(鬼)를 제어한다. 유금(酉金)은 일간에서 제극(制剋)하여 재성(財星)으로 되고, 삼전은 천반에서 전부 하(下)를 극하고 외전(外傳)하여 불화(不和)의 상이다.
    또 일지상신은 말전을 극하고, 말전은 중전을 극하며 중전은 초전을 극하고 초전은 일간을 극한다. 이와 같이 체극(遞剋)으로 되기 때문에 타인이 와서 나를 능치(凌恥)하려고 한다. 간상의 자(子)는 일순(一旬)의 수(首)이며, 지상신 유(酉)는 순미(旬尾)로 된다. 즉 백법의 일순주편격(一旬周偏格)으로서 처음이 있고 끝이 있어 시종 서로 화합한다. 간지(干支)가 함께 상에서 하를 극하여 간지전상(干支全傷)하므로 서로 손해가 있으며, 초전은 말전을 생하고 중전은 일간을 생한다. 지상신(支上神)의 유금(酉金)은 간상신(干上神)을 생하여 초전으로 된다. 즉 귀(鬼)를 이끌어 생화(生化)하므로 모든 일을 나무랄 것이 없다. 과욕(過慾)으로 구할 때는 후회하게 된다.
    사과 삼상(三上)에서 삼하(三下)를 극하여 유도액격(幼度厄格)이라고 하며 아랫사람의 액난(厄難)을 만난다. 가정을 정단하면 가정이 불안하고 골육 이배(離背)한다. 유도액격은 적은 이(利)에 구애되지 않는다. 그러나 신장(神將)의 길흉과 구신(救神)의 유무를 짐작하여 단정해야 한다. 간기(干奇)와 지의(支儀)는 지(支)의 음신(陰神)에 임하고 자(子)는 순의(旬儀)로서 간상에 임하여 능히 화를 복으로 전변시키며 곤란을 변화시켜 은혜로 되게 한다. 비전에 말하기를 ｢초전이 의신(儀神)으로 되고 말전이 길장을 만나면 시종 경사가 있다｣고 하였다.
    일귀는 순행하여 삼전이 합(合)․음(陰)․청(靑)으로 되고, 야귀는 삼전이 사(蛇)․상(常)․합(合)으로 된다.`
    ,
    // 7
    `  아 과는 천지십이신(天地十二神)이 각각 충위(冲位)에 있어 陽日이면 양극(陽剋)이다. 제4과의 인신(寅申)을 써서 초전으로 한다. 삼전이 인신인(寅申寅)으로 되고 일간과 일지상신이 역마로 되기 때문에 원행(遠行)․이동(移動)․쟁소사(爭訴事) 등은 천장(天將)의 길흉에 따라 선악(善惡)을 정단한다.
    반음과(返吟課)는 제신(諸神)이 그의 위(位)에 반대되고 일진(日辰)의 음양이 왕래극적(往來剋賊)하여 반복하기 때문에 반음(返吟)이라고 이름을 붙인 것이다. 오는 자는 가는 것을 생각하고 떠나가는 자는 돌아올 것을 생각한다. 반음과는 일명 무의격(無依格)이라고도 말한다. 움직이려 하여도 움직일 수 없으며, 어떤 일도 두 길에 있다. 간상(干上)․지상(支上)이 합하고 간상은 일지와 합하여 교차지합(交車支合)으로 되고 덕록장생(德祿長生)으로 되어 목적을 이루어 유익하게 된다.
    일간상신이 관귀효(官鬼爻)로 되어도 공망하여 해(害)를 이루지 않는다. 간상의 양신과 음신이 함께 공망하여 움직이면 무익하고, 정지하여 예(禮)로써 일을 행한다면 길조를 얻는다.
    백법에 말하기를 ｢병(丙)에서 해(亥)는 십이운(十二運) 절(絶)로 되고, 일지 인(寅) 목(木)은 신(申)을 만나서 절신(絶神)이 된다｣고 하였다. 간지가 절(絶)을 만나는 것은 계획하여 결정하지 못하고 또 옛것을 결절(結絶)한다. 간지가 전부 상하므로 두 개의 손상을 방지해야 한다. 비전에서 말하기를 ｢반음(返吟)의 의지할 곳이 없는 격은 도망․가출 등은 먼 곳을 찾아야 한다｣고 하였다. 합하는 것은 분산되고, 사물을 바꾸고 이동하며 친우(親友)와는 옷깃을 나눈다. 형충파해(刑冲破害)는 모두 사물의 두 길에 걸쳐 있어 두 개의 일이더라도 용신(用神)이 왕상(旺相)이면 비로소 길로 된다. 어떤 일도 반복하여 일정하지 않으며 병은 양증(兩症)이 있다. 백법의 흉사결절(凶事結絶)을 운용해야 한다. 일귀는 순행하여 삼전은 청(靑)․후(后)․청(靑)이고 야귀는 역행하여 삼전은 현(玄)․합(合)․현(玄)으로 한다.`
    ,
    // 8
    `  이 과는 제2과, 제3과, 제4과의 세 곳에 극이 있다. 양일양비(陽日陽比)의 극인 제4과의 자미(子未)를 채용하여 초전으로 하고 지일비용과(知一比用課)로 된다. 일간상신에 술(戌)의 묘신(墓神)이 복(復)하여 일야(日夜)가 함께 사신(蛇神)이 승한다. 이와 같은 것은 양사(兩蛇)가 묘신을 도와 모든 일은 암매(暗昧)하고 흉조가 있다. 천공(天空)에 사(蛇)가 승할 때는 가장(家長)이 신음(呻吟)을 받거나 부뚜막에 동티가 있다. 술(戌)은 사용인이고 사용인은 주인을 배반한다.
    이 과(課)는 선악(善惡)이 혼합되어 변화가 무쌍하다. 삼전이 공망하여 공허한 놀라움이 있거나괴기(怪氣)를 만나도 괴이(怪異)한 해(害)는 없다. 인내하여 마음을 평안하게 갖고 적덕(積德)하면 신명(神明)의 도움이 있어 오래도록 흉은 해산되고 길조를 초래한다.
    백법에 말하기를 ｢양사협묘(兩蛇夾墓)는 흉을 면하기 어렵다｣고 하였다. 간지가 각각 묘(墓)에 승할 때는 혼미삽체(昏迷澁滯)된다고 하였다. 비전에서 말하기를 ｢일간일지상신이 묘신(墓神)에 복(伏)하는 것은 인택(人宅)이 함께 혼미하여 운무(雲霧) 속을 걸어가는 것과 같다. 정단사는 형쾌(亨快)하지 않다｣고 하였다. 또 말하기를 ｢미월(未月) 병인일(丙寅日)에 이 과를 얻어 신상(身上)을 정단할 때는, 예를 들어 미(未)는 인(寅)에 가하여 지상이 묘(墓)가 되고, 초전은 귀효(鬼爻)로 되고, 말전의 술(戌)은 일상(日上)에 돌아온다. 미월(未月)은 술(戌)이 사신(死神)으로 되며 양사(兩蛇)는 이것을 끼고 복시(伏屍)가 있어 재앙(災殃)을 이루므로 술년(戌年) 술월(戌月)의 미일(未日)에 사망한다. 택(宅) 중 땅 속에 반드시 고분(古墳) 또는 사골(死骨)이 있다｣고 하였다.
    일귀는 순행하여 삼전은 합(合)․상(常)․사(蛇)이고 양귀는 역행하여 삼전은 후(后)․공(空)․사(蛇)로 되며, 양사협묘(兩蛇夾墓)는 병을 정단하면 반드시 사망한다.`
    ,
    // 9
    `이 과는 제1과 유사(酉巳)의 일하일상(一下一上)을 적(賊)하여 중심과(重審課)로 되며, 일간상신(日干上神) 유(酉)는 병(丙)의 재(財)가 된다. 또 삼전 사유축(巳酉丑)이 회합하여 종혁격(從革格)이며 재국(財局)으로 된다. 종혁격은 먼저 종(從)하고 후에 혁(革)하여 어떤 일이라도 재기재거(再起再擧)한다. 병은 재발하고, 모사는 성취되고 변혁하는 일이 있으며, 낡은 것을 버리고 새 것을 만드는 문화(文化)의 상이다.
    백법에 말하기를 ｢양귀(兩貴)가 극을 받는 것은 귀(貴)를 결(缺)하고, 삼전의 재(財)가 태과(太過)한 것은 반대로 재를 얻어도 결하기 쉽고 갑자(甲子)의 일순(一旬)은 유(酉)가 순미(旬尾)이다. 순미가 초전으로 되는 경우는 폐구격(閉口格)이라고 부르며 어떤 일도 비밀을 지켜 그 기(機)를 측정하기 어렵다｣고 하였다. 비전에 말하기를 ｢일하(一下)는 그 상을 적극(賊極)한다. 귀신(貴神)의 순행(順行)은 우환이 있어도 얕다. 역행(逆行)은 우환이 깊고 묘(墓)에 들어가는 것은 해(害)가 있으나 삼전에서 생할 때는 해를 피할 수 있게 된다｣고 하였다. 말전은 중전을 생하고 중전은 초전을 생하여 일간상(日干上)에 돌아오므로 재성(財星)은 매우 왕성한다.
    일귀는 역행하여 삼전은 귀(貴)․상(常)․구(勾)이며, 천장(天將)은 어느 것이나 토신(土神)으로 삼합금국(三合金局)을 생한다. 이와 같은 것은 재원(財源)이 진하지 않아 수입이 있다. 그렇지만 과욕을 탐할 때는 오히려 재(財)로 인하여 재(災)를 초래한다. 야귀는 삼전이 주(朱)․음(陰)․공(空)이 승하여 구설의 징조가 있다.
    간지가 함께 사기(死氣)를 승하기 때문에 병점(病占)은 가장 불길하다. 일간에서 보아 초전의 유(酉)는 십이운(十二運)이 사(死)로 되며 말전이 건록으로 되면 모든 관점(觀占)은 나중에는 길이라고 보게 된다. 백법에 말하기를 ｢전재(傳財)의 태왕은 반대로 재(財)가 결여될 우려가 있고, 사과(四課)가 삼합금국으로 되고 삼합화국(三合火局)에 극되는 것은 합중(合中)에 살(殺)을 띠는 것으로 꿀 속에 독약이 있는 과로 된다｣고 하였다.`
    ,
    // 10
    `  이 과는 제1과와 제4과가 하에서 상을 극하여 중심불비과(重審不備課)로 된다. 초전은 중전을 생하고, 중전은 말전을 생하며, 말전의 인(寅)은 일간을 생하여 체생(遞生)으로 되고 만사는 추거(推擧)의 기쁨이 있다. 
    그러나 중․말전이 공망하여 기쁨이 있어도 경미하다. 일간기궁(日干寄宮)의 사(巳)는 일지상신에 돌아와서 인(寅) 목(木)에 의해 생(生)을 받으므로 겸양(謙讓)하면 길하다. 계획하는 사업과 바라는 일은 구하면 성공한다.
      일지(日支) 인(寅)에서 보아 일간상신이 역마가 되고 일지상신에 건록이 승하면 부(富)를 빨리 얻게 된다. 초전이 신(申)의 재(財)가 되고 중․말전이 공망이면 초전의 신(申)에 의해서 길흉을 정단(正斷)한다. 즉 재마(財馬)가 승하므로 활동에 상응한 재(財)를 구하면 얻게 된다.
      귀신(貴神)이 일간음신(日干陰神) 즉 제2과에 승하므로 만사가 길조이며 이복(利福)의 과(課)이다. 봉급생활은 승진 또는 승급의 기쁨이 있다. 중․말전의 공망이므로 탐욕스러운 일은 고려하여야 한다. 그렇지 않으면 화를 초래한다. 백법에 말하기를 ｢일지상건록(日支上建祿)은 권섭부정(權攝不正)으로 되고 삼전 체생(遞生)은 타인의 추천을 받으며, 지상(支上)의 녹마재관(祿馬財官)은 부귀의 혜택이 있다｣고 하였다. 비전에서 말하기를 ｢신(申)이 사(巳)에 가하는 것은 주사(主事)가 새롭게 마음에는 걱정이 있어도 신변에는 기쁨이 있다｣고 하였다. 삼전은 체생으로 되고 일간일지상신(日干日支上神)은 생왕(生旺)으로 되기 때문에 만사는 길조를 초래하나 중말전(中末傳)이 공망하여 경감(輕減)은 면하지 못한다.
      일귀는 역행하여 삼전은 사(蛇)․음(陰)․백(白)으로 된다. 야귀는 삼전이 합(合)․귀(貴)․현(玄)이다. 사과불비격(四課不備格)으로 되면 정단사는 좋은 중에 약간 부족하거나 불비(不備)가 있다.`
    ,
    // 11
    `  이 과는 제3과에 진인(辰寅)의 극이 있어 이것을 초전으로 하여 중심과(重審課)로 된다. 삼전에 일자씩 간격(間隔)이 있어 이것을 진간전(進間傳)이라고 부른다. 진(辰)의 일지상신이 초전으로 되는 것은 참관격(斬關格)이다. 귀인이 유(酉)에 승할 때는 여덕격(勵德格)으로 된다.
    말전 신(申)은 임(壬)의 둔귀(遁鬼)가 승하므로 재(財)를 얻어 흉을 초래한다. 그렇지 않으면 가사(家事)의 건에 출비(出費)하게 된다. 삼전 진오신(辰午申)은 등삼천격(登三天格)이라고 하며, 상급자에게 유익하고 하급자에게는 무익하다. 일반 사람은 거주(居住) 불안의 징조가 있고 만사는 진전 중에 있다. 어떤 일도 출비출재(出費出財)에 의해서 성사된다. 사사(私事)로운 계획은 산불의 가호(加護)를 받아 계획하는 것이 좋다.
    백법에 말하기를 ｢일야귀인(日夜貴人)이 유(酉)의 천지반중(天地盤中)에 승하면 양귀(兩貴)를 구하여 얻는다. 청룡이 인(寅)의 지(地)에 임하면 생기(生氣)를 가하여 길이 되어도 늦다｣고 하였다. 비전에 말하기를 ｢진(辰)의 천강(天罡)이 지상(支上)에 임하여 초전으로 되고 백호가 승하는 것은 참관(斬關)으로 되며, 혈지(血支)나 양인(羊刃)을 띠게 되면 인축(人蓄)의 상해사건이 발생한다｣고 하였다.
    일귀는 역행하여 삼전은 청(靑)․합(合)․사(蛇)에서 청(靑)은 사(蛇)로 화(化)한다. 그러므로 공명사(功名事)를 정단하면 불길하다. 현임관리는 퇴직 또는 휴직(休職)이 걱정이다. 야귀는 삼전이 백(白)․청(靑)․합(合)이며 청룡은 일간과 비화(比和)하여 일귀(日貴)보다 우수하다. 현무는 천지반(天地盤) 자(子)의 순수(旬首)에 승하므로 도난 건은 도적을 붙잡을 수 있고 피해물품은 후에 돌아온다.
    등삼천(登三天)은 관직에 크게 길조로 승진․영전(榮轉)의 기쁨이 있으며, 소송사건은 확대된다. 질병을 정단하면 중하게 되고, 진술(辰戌)은 괴강(魁罡)이라고 부른다. 일진에 가하여 초전으로 되면 참관과(斬關課)로 된다. 사람은 토(土)의 흉신을 만나고 토(土)가 겹쳐서 폐색(閉塞)의 상으로 되어 반드시 개문(開門)을 요구한다. 목(木)이 와서 토(土)를 극하면 개문(開門)하게 된다.`
    ,
    // 12
    `  이 과는 제4과 진묘(辰卯)와 일하(一下)에서 상을 극하여 초전으로 되고 중심과(重審課)이다. 일지의 음신(陰神)을 초전으로 하고 말전의 오(午)는 일간상(日干上)에 돌아온다. 이것을 조원격(朝元格)이라고도 한다. 
    시종이 있고, 말하는 것마다 적중(適中)하지 않는 것이 없다.
    간지가 함께 왕신(旺神)에 승하는 것은 앉아서 일을 계획하는 것이 좋다. 제4과를 초전으로 하는 것은 맥월격(驀越格)이라고 하여 일은 우연하게 이루어진다. 대개는 과거의 일에 속한다. 초전에 청룡이 있어, 일은 성사된다. 모든 일을 순조롭게 구하여 얻게 되며, 삼전 진사오(辰巳午)의 진여격(進茹格)이라도 귀신(鬼神)이 역행할 때는 신속한 진행 중에 삽체(澁滯)의 징조가 있다.
    천반 지지(地支)가 지반의 전지(前支)로 되는 것은 나망격(羅網格)으로 된다. 백법에 말하기를 ｢모사(謀事)가 졸(拙)이 많은 것은 나망격으로 되기 때문이고, 호왕격(互旺格)은 모사(謀事)를 앉아서 달성하는 것이 좋다｣고 하였다. 
    일간은 내몸이며 일지상신(日支上神)은 객(客)으로 된다. 주객(主客)이 함께 극왕(極旺)하면 출동하여 이(利)가 없기 때문에 앉아서 계획하는 일이 좋은 소이이다. 나망격은 원동(遠動)을 밖에서 구할 때는 신택(身宅)에 재화(災禍)를 초래한다. 진연주격(進連珠格)은 사건이 순(順)하다.
    일귀는 역행하여 삼전은 청(靑)․구(勾)․합(合)이며, 야귀는 삼전이 백(白)․공(空)․청(靑)으로 된다. 정단의 사건에 따라 길흉을 구하여 알 수 있다.`
    ,
  ]

export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
