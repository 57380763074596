
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,12,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,9,11,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,10,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,11,9,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,12,8,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,7,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,2,6,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,3,5,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,4,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,5,3,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,2,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,1,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직위불안 남편갈등 자식 관련사`,`小`,``,``,``],
    [`매사허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`9`,`직위불안`,`남편갈등`],
    [`매사 영속성없음`,`직업지위권리남편남자자식사는 선천적 기복재해를 겪음`,`大`,`소외기`,`고위연회`,`가택有井`],
    [`음인유산(陰人有産)`,`사정인을 들여 유산을 얻는 상-가택 古井 怪事 발생함`,`大`,``,`초대열석`,`괴이질병`],
    [`음폐안녕(陰閉安寧)`,`색정사를 끓고 정숙하니 가택이 편안한 상`,`小`,``,`(고위직자)`,``],
    [`후원`,`후원조력 문서 모친 관련 침체사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`8`,`소외침체`,`가정침체`],
    [`매사 영속성없음`,`후원조력문서모친사는 선천적 기복재해를 겪음`,`小`,`침체기`,`大人도래`,`임신이산`],
    [`간도연루(奸盜連累)`,`간사한 도둑에 연루되어 서로 피해를 입게되는 상`,`小`,``,`모망길조`,``],
    [`불변근신(不辨謹愼)`,`장차 큰 뜻을 이루기 위해 말을 삼가하고 근신하라-는 상`,`大`,``,``,``],
    [`단절`,`금전 사업 처 관련 단절사`,`大`,``,``,``],
    [`동요`,`생업외사 내부가정 모두 부부이심 동요불안분산이별사`,`小`,`7`,`동요분산`,`이심단절`],
    [`출행`,`외사 내사 출행이동사`,`小`,`단절기`,`출행이동`,`출행이별`],
    [`관고상흉(官高常凶)`,`관직자는 고위에 영전되나 일반인은 病訟으로 흉한 상`,`大`,``,`관공吉兆`,`이전이사`],
    [`차최유기(此最有氣)`,`지금 때가 차서 반가운 문서 또는 소식을 받게 되는 상`,`小`,``,``,``],
    [`금전`,`금전 여인 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`6`,`손실증오`,`손실증오`],
    [`결속`,`결속사 음11월 완성 재원기반 세우나 직위남편을 훼절함`,`小`,`존재기`,`선損후利`,`도적소실`],
    [`쾌락무궁(快樂無窮)`,`쾌락이 무궁하나 일을 크게 움직이면 불리하게 되는 상`,`大`,``,``,``],
    [`엄목지상(掩目之象)`,`눈이 가려진 격으로 엎드려 쉴 수밖에 없는 상 `,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`5`,`舟車파괴`,`재물유실`],
    [`부친`,`부친 관련사`,`小`,`배양기`,`인명손상`,``],
    [`임취무해(任取無害)`,`업무에 관련한 取財는 무해한 상-단 관직자는 불리함`,`小`,``,`우려禁,休`,``],
    [`자검불약(自檢不約)`,`약속한 비밀을 누설하여 죄에 이르는 상`,`大`,``,``,``],
    [`결속`,`결속사 음11월 완성 재원기반 세우나 직위남편을 훼절함`,`大`,``,``,``],
    [`애증`,`외사 내사 애증화합 급속송쟁파재 극처사`,`大`,`4`,`관사급속`,`남편愛憎`],
    [`급속`,`외사 내사 급속강제손실사`,`小`,`개시기`,`전화위복`,`舊事종결`],
    [`불의취처(不宜取妻)`,`모든 일이 결절되므로 금전, 妻, 자산이 좋지않게 되는 상`,`小`,``,``,``],
    [`우유한가(優遊閑暇)`,`용모에 무관심해도 무방한 상-실업자 처지`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`3`,`관사직업`,`교제수난`],
    [`남자`,`남자 관련사`,`小`,`학습기`,`대중회맹`,`상하존비`],
    [`사음역례(邪淫逆禮)`,`색정사의 무례가 심하여 역작용이 두렵게 되는 상`,`小`,``,``,`동실동침`],
    [`사행불량(私行不良)`,`근친상간사를 벌이고 있는 상`,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`2`,`모망불리`,`허성무실`],
    [`모친`,`모친 관련사`,`小`,`면모기`,`활동정지`,`근신苦輕`],
    [`좌수초가(座守稍可)`,`소극적으로 앉아지켜야 좋고 적극 나서면 흉이되는 상`,`小`,``,`요됨`,``],
    [`도적소실(盜賊消失)`,`도적이 길을 잃은 격으로 도난의 피해가 없게 되는 상 `,`大`,``,``,``],
    [`명예`,`수상(受賞)사`,`小`,``,``,``],
    [`직녹`,`직위녹위 관련사`,`小`,`1`,`외교막힘`,`가정침체`],
    [`막힘`,`생업 가정 외교 막힘사`,`大`,`절정기`,`退座피면`,`허모경계`],
    [`상가관기(常可官忌)`,`일반인에게는 좋고 관직자에게는 나쁜 상`,`小`,``,`요됨`,`구설불평`],
    [`투서헌책(投書獻策)`,`투서헌책으로 고위인을 접인하게 되는 상-불성`,`大`,``,``,`거듭발생`],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`색정`,`외남 내녀 동시 색정사 有`,`小`,`12`,`색정사有`,`색정사有`],
    [`과출`,`외사 내사 비용과출사`,`大`,`과시기`,`선흉후길`,`타협타결`],
    [`약능좌대(若能座待)`,`능력을 발휘할 역량이 갖췄으되 때를 기다리는 상`,`小`,``,`병송治和`,``],
    [`음신조격(音信阻隔)`,`소식을 오지 않아기다리는 상-와도 일단 나쁨 `,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`11`,`침체공허`,`침체공허`],
    [`부친`,`부친 관련사`,`小`,`쇠퇴기`,`守己영창`,`맹잔傷害`],
    [`상신훼택(傷身毁宅)`,`몸을 크게 다치거나 가택이 크게 훼손되는 두려움의 상`,`小`,``,`근친상간`,`근친상간`],
    [`상희하승(上喜下僧)`,`상위자는 기쁜 의관행사가 있고 하위자는 수도자 격의 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`면모훼손`,`면모훼손`],
    [`자식`,`자식 관련사`,`大`,`침해기`,`수舊대新`,`두풍病苦`],
    [`불리구재(不利求財)`,`금전(女포함)이 鬼가 되므로 애써 구재(取)하지 말라-는 상`,`大`,``,``,``],
    [`실업한가(失業閑暇)`,`면모를 갖출 필요가 없는 한가한 상-실업 처지-이후 大吉`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`금전사업처조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업여인조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`후원문서부친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,"관사부부애증강제급속손실사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,"건사직업남자사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`후원문서모친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`명예직녹자산권리사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`자매친구동료조력비용색정조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`후원문서부친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,``,"직업직위불안남편갈등자식사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력근친상간(近親相姦)**만약 타인과 다툰다면 상대는 동심으로 협력하고자 하므로 협상이 타결됨`,0,0,`수고하지 않아도 효과를 이루는 人`,0,0,`근친상간사 有**가택내 마른 우물이 있고 괴이사가 발생하며 가장이 병듬**협상사는 타결됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 당당하게 속결해야만 유리함`,0,0,`명분이 있는 사안을 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 인`,0,0,`일이 역순으로 띄엄띄엄 진행됨**일이 용두사미격이 되므로 속히 타결지어야 함**군자가 결과없이 때를 기다리는 격이나 역시 반복됨 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 당당하게 속결해야만 유리함`,0,0,`명분이 있는 사안을 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 인`,0,0,`신규사가 있음**사업장 이전 가택 이사함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 유순한 처신으로 사안을 지체 연기시켜야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 순응해야만 하고 사안을 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`여러 일을 묶는 결속사가 있음-단 음11월에 완성함-되려 명예직위 남편이 훼탈됨**산으로 깊이 숨고싶은 심정임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`간 자는 오고 온 자는 가는 상**가고 싶어도 못가고 오고 싶어도 못오는 격**정대한 일들이 모두 단절됨**行 舟車 파괴됨**사망사 우려**출행을 쉬어야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상으로 화가 밖에서 오므로 신중해야 함`,0,0,`부부이심반목색정이별사가 있는 人`,0,0,`전화위복사가 있음**신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력근친상간(近親相姦)**만약 타인과 다툰다면 상대는 동심으로 협력하고자 하므로 협상이 타결됨`,0,0,`수고하지 않아도 효과를 이루는 人`,0,0,`근친상간사 有**일이 겹쳐발생함**남자에게 유리함-단, 적극 활동에 효과가 없으므로 수신이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 당당하게 속결해야만 유리함`,0,0,`명분이 있는 사안을 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 인`,0,0,`여러 일을 묶는 결속사가 있음-단 음11월에 완성함-되려 명예직위 남편이 훼탈됨**군자가 결과없이 때를 기다리나 역시 반복되고 어두움**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력**만약 타인과 다툰다면 상대는 동심으로 협력하고자 하므로 협상이 타결됨`,0,0,`입을 닫고 침묵으로 물러나야만 피면되는 人`,0,0,`허모사가 많음**구설불평사 有**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 유순한 처신으로 사안을 지체 연기시켜야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 순응해야만 하고 사안을 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`일이 역순으로 띄엄띄엄 진행됨**여자가 남자를 적극 유혹하여 함께 도망할 각오까지 품고 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력**만약 타인과 다툰다면 상대는 동심으로 협력하고자 하므로 협상이 타결됨`,0,0,`유혈사 방지가 필요한 人`,0,0,`전화위복사 있음**기존의 익숙한 일을 지키면 영참함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)신음고로**만약 타인과 다툰다면 상대는 뜻은 있으나 공격하지는 않음`,0,0,`스스로 자임하고 옛것을 지키며 새 기회를 기다리는 人-이후 대성함`,0,0,`신규사가 있음**송쟁사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`미사`,`신구중간사`,`모호사`,`은닉사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사타발동사겹침`,`기사`,`신구중간사`,`아외사타내사겹침`,`아표면사타표면사겹침`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아타발동동시사`,`기사`,`신사`,`아외사타내사동시가`,`아타표면동시사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아타동시발동사`,`미사`,`신구중간사`,`아외후회사타내급속동시사`,`아이면타이면동시사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아타동시발동사`,`미사`,`구사`,`아외사타내사동시사`,`아표면타표면동시사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아타동시발동사`,`기사`,`신사`,`아외사타내사동시사`,`아표면타표면동시사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`이외사`,`기사`,`신구중간사`,`돌발사`,`모호사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아타발동동시사`,`기사`,`구사`,`아외타내동시사`,`아후회사타급속사겹침`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동타발동동시사`,`기사`,`구사`,`아외사타내사동시사`,`아표면타표면동시사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사타발동급속사겹침`,`미사`,`신구중간사`,`아외사타내사동시사`,`아이면타이면동시사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-출비됨`,`아발동타발동동시사`,`미사`,`신사`,`아외사타내사겹침`,`아표면타표면사겹침`,`좌고우면`,`충성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동타발동동시사`,`미사`,`신사`,`아후회사타급속사겹침`,`이표면타표면겹침`,`배신`,`좌고우면`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`숭`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [`애증화해`,`애증화해`,`애증화해`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해함`,`화해함`,`화해함`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,2,3,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월`,2,1,3,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음8월`,3,3,6,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,3,2,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,2,1,3,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,2,3,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월`,2,1,3,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`움8월`,3,3,6,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월`,3,3,6,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내나 업무는 협상타결능력으로 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 초반에는 저조하고 중 종반에 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이고 반목대립으로 엄부가 불안정함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여음11월 결속단합 완성으로 되려 명예지위를 훼절함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻고 화합하나 기존 업무를 모두 단절 시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성품이 온순하고 준수 미모이나 업무가 강하지 못해 시종 각론으로 동요 분산사를 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 중 종반의 업무가 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 음11월에 여러 일을 결속시키나 결국 명예와 지위를 훼절 시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무 전반이 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무의 초 중반을 실패하고 종반에 이르러 동요우여곡절로 실적을 보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무도 전화위복적으로 수준을 유지하나 유혈사가 발생할 우려가 있음`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 온순하고 준수 미모이나 업무가 강하지 못해 시종 반목대립 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대없음`,`이행`,`선이후費`,`進向`,`근`,`留`,`주인조력`,`정서`,``,`남남서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인내부`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`주인가해`,`북서`,``,`남서`],
    [`10.11`,``,`반대`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,`끌면不制`,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인내부`,`이행`,`선난후費`,`退背`,`  원`,`  去`,`주인가해`,`남남동`,``,`동북`],
    [`10.11`,``,`반대`,``,``,``,``,``,`애증화합`,``,``,``],
    [`3.6\n9.12`,``,`끌면제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`주인조력`,`정남`,`정동`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선攻후費`,`進向`,`  원`,`  留`,`세입가해`,`남남서`,`동남`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선攻후費`,`退背`,`  원`,`  去`,`세입가해`,`동북`,`남남동`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인사안`,`退背`,`  근`,`  去`,`주인무력`,`정동`,`정남`,``],
    [`10.11`,`  集`,``,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,`본인사안`,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`모두무력`,`退背`,`원근왕래`,`  去`,`주인무력`,`동남`,`남남서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선損후費`,`退背`,`원근왕래`,`  去`,`세입조력`,`남남동`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후費`,`進向`,`원근왕래`,`  留`,`주인조력`,`정남`,``,`남남서`],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`반대없음`,`이행`,`선費후費`,`進向`,`  근`,`  留`,`무난`,`남남서`,``,`서남`],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`애증화합`,`이행`,`권리양도`,`進向`,`원근왕래`,` 留`,`무난`,`서남`,``,`북북서`],
    [`10.11`,``,`됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`도로귀`,`교툥사망`,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,`남남동`,`없음`],
    [``,``,``,0,0,`묘향`,`북북서`,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,`큰도로`,``],
    [``,``,``,0,0,`묘속`,`뱀굴`,``],
    [`가정`,`변사귀`,`객사`,0,0,`육친`,`부친`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,"두성부정","기도위배",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"두성부정","기도위배",`육친`,``,``],
    [`질병`,`제례귀`,`제례위법`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,`정북`,`없음`],
    [``,``,``,0,0,`묘향`,`정남`,``],
    [`생업`,``,``,0,0,`전경`,`큰도로`,``],
    [``,``,``,0,0,`묘속`,`水 입출`,``],
    [`가정`,``,``,0,0,`육친`,`4대모`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,"두성부정","기도위배",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"두성부정","기도위배",`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외내 증오 도망가출사`,`상위 희사-하위 수도자 격`,`매사 허성불성-세속허사자`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`입조입각-대중이익강구사`,``,`가정부부불화사`,`위례득죄사`,``,`외사가정급속송쟁실종상해사`,`사송사흉-문서소식 길-이동사`,``,`장차 위한 불변근신사`,``,`고위연회초대열석길사`,`명예직녹자산사**내외사 막힘`,`실업자격-한가함`,`외남내녀 동시색정사**남녀직원공모사`,`음사폐호-가택안녕사`,`매사 위역사`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`소식불통사-와도 나쁨`,`빈궁**병재`,`관직자 영전도약사-부족`,`빈궁`,`전화위복`,`전도`,``,`전도**빈궁**관재`,``,``,`음4,5월화재주의**재액해소`,`빈궁**관재`,`음4,5월화재주의**재액해소`,`빈궁**관재`,``,``,``,``,`빈궁`,``,`침체驚懼사`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사`,`도적실세-잡음`,`외내 증오 도망가출사`,`상위희사-하위 수도자 격`,`매사 영속성없음**소식불통사-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음**관직자 영전도약사-부족`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`입조입각-대중이익강구사`,``,`가정부부불화사`,`위례득죄사`,``,`외사가정급속송재실종상해사`,`사송사흉-문서소식 길-이동사`,``,`장차 위한 불변근신사`,``,`고위연회초대열석길사`,`명예직녹자산사**내외사 막힘`,`실업자 겨-한가함`,`외남내녀동시색정사`,`음사폐호-가택안녕사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`재액해소**전화위복`,``,`재액해소`,`빈궁**병재`,`전화위복`,`빈궁`,`전화위복`,`관재`,``,`빈궁`,``,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,``,`전도`,``,``,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`외남내녀동시색정사`,`음사폐호-가택안녕사`,`매사 위역사`,`도적실세-잡음`,`오내 증오 도망가출사`,`상위 희사-하위 수도자 격`,`매사 영속성없음**소식불통사-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음**관직자 영전도약사-부족`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`입조입각-대중이익강구사`,``,`가정부부불화사`,`위례득죄사`,``,`외사가정급속송쟁실종상해사`,`사송사 흉-문서소식 길-이동사`,``,`장차 위한 불변근신사`,``,`고위연회초대열석길사`,`명예직녹자산사**내외사 막힘`,`실업저 격-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`빈궁`,``,`재액해소**전화위복`,``,`재액해소`,`빈궁**병재`,``,`빈궁`,`전화위복`,``,``,`빈궁`,``,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,``,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`명예직녹자산사**내외사 막힘`,`상린충산사`,`외남내녀동시색정사`,`관재형책사`,`약속비밀누설-자초 죄사`,``,`외내 증오도망가출사`,`불의투서헌책-급문서사-불용`,`매사 영속성없음**눈이 가려진 격-휴伏상태`,`종교진출하면자립함`,`매사 영속성없음**투서헌책-고위면접사-부족`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`실업자격-한가함`,`사행불량사`,``,`도적소실사`,`매사 위역사`,`외사가정급속송쟁실종상해사`,`권한수여희경사-이동사`,``,`앙화소멸사`,`사기득재사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁`,``,`재액해소`,``,`전화위복`,`빈궁`,``,`빈궁`,`재액해소`,``,`전화위복`,``,`빈궁**관재`,``,`재액해소`,`빈궁`,`음4,5월화재주의`,`전도**관재`,`음4,5월화재주의`,`전도**관재**병재`,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`사기득재사`,``,`명예직녹자산사**내외사 막힘`,`상린충산사`,`외남내녀동시색정사`,`관재형책사`,`약속비밀누설-자초 죄사`,``,`외내 증오 도망가출사`,`붕의투서헌책-급문서사-불용`,`매사 영속성없음**눈이 가려진 격-휴伏상태`,`종교진출하면자립함`,`매사 영속성없음**투서헌책-고위면접사-부족`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`실업자 격- 한가함`,`사행불량사`,``,`도적소실사`,`매사 위역사`,`외사가정급속송쟁실종상해사`,`권한수여희경사-이동사`,``,`안화소멸사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,``,`빈궁**관재`,``,``,``,`전화위복`,`전도**빈궁`,``,`전도**관재**빈궁`,``,``,`재액해소**전화위복`,`관재`,`재액해소`,`빈궁`,``,`빈궁`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`앙화소멸사`,``,`사기득재사`,``,`명예직녹자산사**내외사 막힘`,`상린충산사`,`외남내녀동시색정사`,`관재형책사`,`약속비밀누설-자초 죄사`,``,`오내 증오 도만가출사`,`불의투서헌책-급문서사-불용`,`매사 영속성없음**눈이 가려진 상-휴伏상태`,`종교진출하면자립함`,`매사 영속성없음**투서헌책-고위면접사-부족`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`실업자 격-한가함`,`사행불량사`,``,`도적소실사`,`매사 위역사`,`외사가정급속송쟁실종상해사`,`권한수여희경사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁**관재**병재`,`동요분산사`,`전도**빈궁`,`동요분산사**재액해소`,`관재`,`동요분산사**재액해소`,`빈궁**관재`,`동요분산사`,`전도`,`동요분산사**전화위복`,`빈궁**관재`,`동요분산사`,`빈궁`,`동요분산사`,`전도`,`동요분산사**전화위복`,``,`동요분산사`,`빈궁`,`동요분산사`,`전도**빈궁`,`음4,5월화재주의**동요분산사`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외사가정급속송쟁실종상해사`,`권한수여희경사-이동사`,`앙화소멸사`,``,`사기득재사`,``,`명예직녹자산사**내외사 막힘`,`상린충산사`,`외남내녀동시색정사`,`관재형책사`,`약속비밀누설-자초 죄사`,``,`외내 증오 도망가출사`,`불릐투서헌책-급문서사-불용`,`매사 영속성없음**눈이 가려진 격-휴伏상태`,`종교진출하면자립함`,`매사 영속성없음**투서헌책-고위면접사-부족`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`실업자격-한가함`,`사행불량사`,``,`도적소실사`,`매사 위역사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**병재**관재`,``,`전도**빈궁`,`재액해소`,`관재`,`재액해소`,`빈궁`,``,`전도`,`전화위복`,`빈궁**관재`,``,`빈궁`,``,`전도`,`전화위복`,``,`빈궁**관재`,``,``,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`도적소실사`,`매사 위역사`,`외사가정급속송쟁실종상해사`,`권한수여희경사-이동사`,`앙화소멸사`,``,`사가득재사`,``,`명예직녹자산사**내외사 막힘`,`상린충산사`,`외남내녀동시색정사`,`관재형책사`,`약속비밀누설-자초 죄사`,``,`외내 증오 도망가출사`,`불의투서헌책-급문서사-불용`,`매사 영속성없음**눈이 가려진 격-휴伏상태`,`종교진출하면자립함`,`매사 영속성없음**투서헌책-고위면접사-부족`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`실업자격-한가함`,`사행불량사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**관재**병재`,``,`빈궁`,``,``,`빈궁`,``,``,``,`전화위복`,``,``,`전도**빈궁`,``,`전도**빈궁`,`재액해소**전화위복`,``,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`사행불량사`,``,`도적소실사`,`매사 위역사`,`외사가정급속송쟁실종상해사`,`권한수여희경사-이동사`,`앙화소멸사`,``,`사가득재사`,``,`명예직녹자산사**내외사 막힘`,`상린충산사`,`외남내녀동시색정사`,`관재형책사`,`약속비밀 누설-자초 죄사`,``,`외내 증오 도망가출사`,`불의투서헌책-급문서사-불용`,`매사 영속성없음**눈이 가려진 격-휴伏상태`,`종교진출하면자립함`,`매사 영속성없음**투서헌책-고위면접사-부족`,`종교진출하면자립함`,`외사가정출행이동사**내외동요분산사`,`실업저 격-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁`,``,`빈궁`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁관재**병재`,``,`빈궁`,``,``,`빈궁`,``,``,``,`전화위복`,`전도**빈궁`,``,`전도**빈궁`,``,``,`재액해소**전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사가정출행이동사**내외동요분산사`,`입조입각-대중이익강구사`,``,`가정부부불화사`,`위례득죄사`,``,`외사가정급속송쟁실종상해사`,`사송사 흉-문서소식 길-이동사`,``,`장차 위한 불변근신사`,``,`고위연회초대열석길사`,`명예직녹자산사**내외사 막힘`,`실업저 격-한가함`,`외남내녀동시색정사`,`음사폐호-가택안녕사`,`매사 위역사`,`도적실세-잡음`,`외내 증오 도망가출사`,`상위 희사-하위 수도자 격`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**관직자 영전도약사-부족`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,``,`빈궁**관재`,`재액해소`,``,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도*빈궁**관재`,`재액해소`,``,``,``,`빈궁`,``,`재액해소`,`빈궁`,`전화위복`,``,`소식불통사-와도 나쁨`,`빈궁**병재`,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진출하면 성공함`,`외사가정출행이동사**내외동요분산사`,`입조입각-대중이익강구사`,``,`가정부부불화사`,`위례득죄사`,``,`외사가정급속송쟁실종상해사`,`사송사 흉-문서소식 길-이동사`,``,`장차 위한 불변근신사`,``,`고위연회초대열석길사`,`명예직녹자산사**내외사막힘`,`실업저 격-한가함`,`외남내녀동시색정사`,`음사폐호-가택안녕사`,`매사 위역사`,`도적실세-잡음`,`외내 증오 도망가출사`,`상위 희사-하위 수도자 격`,`매사 영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관직자 영전도약사-부족`,`빈궁`,`전화위복`,`관재`,``,`빈궁`,`재액해소`,``,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**빈궁**관재`,`재액해소`,``,``,``,`빈궁`,``,`재액해소`,`빈궁**관재`,``,`폐한인**전화위복`,`빈궁**병재`,`소식불통사-와도 나쁨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자**소식불통사-와도 나쁨`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진출하면 성공함`,`외사가정출행이동사**외내동요분산사`,`입조입각-대중이익강구사`,``,`가정부부불화사`,`위례득죄사`,``,`외사가정급속송쟁실종상해사`,`사송사 흉-문서소식 길-이동사`,``,`장차 위한 불변근신사`,``,`고위연회초대열석길사`,`명예직녹자산사**내외사 막힘`,`실업저 격-한가함`,`외남내녀동시색정사`,`음사폐호-가택안녕사`,`매사 위역사`,`도적실세-잡음`,`외내 증오 도망가출사`,`상위 희사-하위 수도자 격`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**병재`,`관직자 영전도약사-부족`,`빈궁`,`전화위복`,`전도`,``,`전도**빈궁`,``,``,`음4,5월화재주의**재액해소`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,``,``,``,`빈궁`,``,`빈궁`,``,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 소송은 벌어지고, 부부       뜻은 다르고 이러지도 
  저러지도 못할 일이 
  닥치네 ? 
⊕전흉사귀, 가택상가부정이 듬 ⊖여귀 발동으로 신음하는 
형편이 됐어 !
⊕신변상가부정이 침범했네?
`,`■ 두 사람이 마음만 합한다고     일이 되나? 
 돈깨나 날리고 있겠네? 
 자칫 음모로 재산까지 
 뺏기겠네!
⊖가정에서 불의밀통ㆍ근친    상간사가 벌어졌네? 
`,`■ 관재조짐이 있고? 
 재물과 여자 때문이네!     옛 것으로 되돌아가고 근친     상간사가 벌어졌네 !
⊕가택귀, 
⊖도로귀ㆍ가택음사부정 해소가    더 급하네!
⊖신변음사부정도 침범했네?
`,`■ 관재가 벌어졌네 ?
 심신도, 집도, 생업도 
 모두 떴어! 
⊕전사귀, 
⊖변사귀 발동으로 그러네!    해소시키면 북쪽 인연이     도울거야!
`,`■자식걱정이네 속는 일이 있고?
男:퇴직ㆍ불명예사가 벌어졌네?
女:이별하게 생겼네?

⊕⊖가택에 두성불안 부정이      잔뜩 끼어서 그래!
`,`■ 마음에 의심ㆍ질투가 있네?     이럴까 저럴까 기로에
 섰어!

⊕신불제사귀, 
⊖음귀를 없애야 가정이 
편안해지겠어!
`,`■ 색정사로 가정은 요란해도 
 돈복은 있네? 
 일도 두 개고 사람도 
 둘이네?
돈은 4일,14일후에 될거야!
자칫 크게 후회하겠어 !

⊖가택상가부정은잡아야 해!
`,`■ 二人동사로 밖에서 신속하게     움직이다 일까지 잘 된다고     할 수는 없지!    
 도와줘야 일을 해먹지!

⊕가정에서불의밀통근친상간     사까지 있고…
`,`■속고 속아 실패수만 연이어    지고 있네?
男：직위해탈ㆍ불명예사가 있겠어! 女：이별하게 생겼어!덕을 쌓고       때를 기다려야 해!
⊕ 가택에 상가부정이 들었다.
⊕신변음사부정도 침범했네?
`,`■ 손해만 나고 도와줄 사람은      능력을 잃어버렸고…
 비용만 나가겠어 !
男：새 일을 찾아야 하겠네?
女：고독이 겹치게 생겼네?
`,`■속고있네! 자손 걱정도 있고 !
二人협력사가비용만나가     겠다! 신변과 가정에 양쪽     다 부정(기도잘못)이 침범      했어! 
女:⊕남자를적극적으로        유혹해 색정사를 벌이고     있네! 
⊕⊖신변두성불안부정침범했네?
`,`■가정에 불의밀통의 근친상간     추행사가 벌어지고 있네?    자칫 피보는 재앙이 닥치    겠어!
기도ㆍ치성이 급하네!
그대로 가다가 후회를 남    기겠어 !`];
  const YOGYEOL1 = [`■ 낮정단은 백호가 많아서 움직인들 좋을 것이 없다. 정마가 삼전에 들었으니 장사가 여행을 떠나겠다.
  `,`■ 초전에 양인과 겁재를 만났으니 막혀서 잘 되기 어렵겠다. 가을겨울은 좋아서 여러 사람의 도움이 많겠다.
  `,`■ 만약 재물을 욕심내어 취하면 재앙을 부르는 원인이 된다. 재관인이 모두 있어 군자의 점이면 아주 좋다.
  `,`■ 밤정단은 주작은 극하고 구진은 생한다. 정마 역마를 모두 만나니 말전 해수로 인해 능히 이루기도 하고 패하기도 하겠다.
  `,`■ 도적을 많이 만났으나 초중전이 공망이어서 다행이다. 오직 말전에 인수만 남았으니 나중에는 생기가 무궁하겠다.
  `,`■ 정신 관살을 중전에서 만났으나 초전 말전에서 제거한다. 재앙은 잘 피하게 되고 돈은 내가 쥐게 된다.
  `,`■ 재물은 넷이나 되고 녹도 있으나 밤정단은 백호가 있어 나쁘다. 
  네 마리 역마가 함께 뛰니 분주하겠으나 반복일 뿐이다.
  `,`■ 낮정단은 귀인이 넷이나 되어도 전혀 힘써줄 수 없다. 뜬 재물이 있기는 하나 밤정단은 가히 두렵겠다.
  `,`■ 손해와 실패가 앞에 가득 놓였으니 삼전이 모두 자손이다. 낮밤 천장은 모두 물이니 손해와 도적이 심할 수밖에 없다.
  `,`■ 모든 일에서 입을 다물어야만 재앙이나 손해를 벗어나겠다. 
  귀인과 관련한 일의 희망은 다음으로 미뤄야하겠다.
  `,`■ 두성불안 부정이 사람과 집에 들었고 역마가 있으나 허공에 떴다. 
  움직이면 자주 손해를 보고 결국에는 숨은 귀살이 두렵다.
  `,`■ 지키면 잘되고 움직이면 상하게 된다.
  두 칼이 날을 세웠으니 재앙과 해로움이 생기겠다.`]
  const SIBO1 = [`■ 정중 동의가 있다.
  스스로 강하다고 믿어
  과오가 있게 된다. 
  일이 연속되어 보류된다.
  조용히 구태를 지키면 화환은 해소된다. 
  가정에 두풍병고 여인이 있다. 여행ㆍ장사와 관련이 있다.
  ⊕가정에 상가부정이 들었다.
  `,`■ 매사 지나친 행동은
  장애를 초래한다.
  분주ㆍ수고하지 않아도
  효과가 있다.
  윗사람의 후원으로 유리한 운세를 맞게 된다.
  二人 동심협력사가 있고
  ⊖불의밀통ㆍ근친상간사가 있다. 우물로 인한 괴이사가 있거나 가장이 질병에 걸린다.
  `,`■ 자충수적 우환이 있고
  가장을 속이고 가인이
  원한을 품고 가해하려
  한다. 경계하면 이로 인한 화환은 없게 된다.
  퇴직후 복직한다. 원대한 것을 집망하라. 모망사는 길하고 단, 가정에 임부가 있거나 아니면 여인이 상하는 일이 있다. 문서사에 세심하라!
  `,`■ 자충수적 우환이 있고
  심신ㆍ가택 모두 변동수가
  있다. 인ㆍ택이 손상될
  조짐이나 흉사가 있어도 흉을 보지 않게 된다.
  단, 申띠자는 관재를 입는다. 관공직자는 길하다.
  
  ⊕가정에 불의밀통ㆍ근친상간사가 있다.
  `,`■ 속는 일이 있고 손모가 있다.
  어떤 일도 성사가 없고
  유손무익한 형편이다.
  동정이 엇갈려 심신이 동요하고 멀리가고 싶다.
  부정을 해소시키면 후원을 받아 유리유익해진다.
  공동사도 순리해지고 방해도 사라진다.
  `,`■ 명예나 녹위가 끝난다.
  현직자는 물러나는 운세이다.
  어떤 일도 휴식이 좋다.
  재해가 있어도 가볍게 끝난다. 가정에 근친상간사가 있어 편안치 못하다.
  선택의 기로에 섰다. 
  자동차 사고를 경계해야 한다. 자손의 흉해가 있다.
  四月 임신사가 있다.
  `,`■ 二人 동심협력사가 있으나
  二사 二심이다. 
  금전으로 고생한다.
  옛일, 인연은 끝내는 것이 좋다. 자칫 놀라는 사건이 된다.
  
  ⊕불의밀통ㆍ근친상간사가 있다. 금전은 4일후 탈공 일에 들어온다.
  `,`■ 저주사가 있다.
  ⊕귀인의 후원이 없고
  ⊖후원사에 허위가 있다.
  외무활동에는 이익이 없고 집을 수리하고 수신해야 좋다.
  ⊕上下ㆍ존비 간에 불의밀통ㆍ근친상간사가 있다.
  부부간 각기 외정도 있다.
  부모의 凶害가 있다.
  四月 임신사가 있다.
  `,`■ 자손사에 속는 일이 있다.
  남자는 직위해탈의 
  불명예사가 기하고 여자는
  극부하고 이별하게 된다.
  남자는 별리 고독인이다.
  모든 활동을 정지하는게 좋다. 조용하면 소모의 고통은 덜하다. 타인의 추천이 있어도 허언일 뿐이다.
  ⊕ 가정에 상가부정이 들었다.
  `,`■ 부모의 凶害가 있다.
  손모가 있다.
  만사에 입을 다물고 침묵
  해야만 재해를 피면하게 된다.
  女는 별리ㆍ고독인이다.
  존비간 불의밀통이 있다.
  무슨 일이든 구설과 불평을 만난다.
  `,`■ 사업ㆍ후원사에 속임수를
  당한다.
  ⊕女는 남의 남자를 적극
  유혹하여 색정에 따르고 있다.
  가정에 부정이 들었다.
  해소시키면 정단사의 길행이 있게 된다.
  타협하고 같은 목소리를 내면 성립한다.
  `,`■ 맹동하지 않고 자신을
  지키면 영창한다.
  함부로 경동하면 신체에
  상해를 입고, 또는 주색으로 망신한다.
  모사는 졸책이 되므로 계획하지 않는 것이 좋다. 기도ㆍ치성은 천우신조가 있게 된다.`]
  const SIBO2_1 = [`■ 직ㆍ녹,내외사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 손모이외사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官･夫,신변가택사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다.
  부부간사 악의의 상이다
  `,`■官･夫,신변가택사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 자손ㆍ극부내외사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다
  속는 일이 있고 자손 재액이 있다.男직위상실하고女부부이별한다.
  `,`■ 사업ㆍ후원기로사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  부부이별 반목의 상이다.
  `,`■財ㆍ女,이심사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  `,`■財ㆍ女,협력동심사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 사업ㆍ후원이면사이다.
  내방조짐 상의 : 
  해산건이 있다. 정사 利 암사 불리하다.男직위상실하고女부부이별한다.
  `,`■ 사업ㆍ후원여부사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액 예방이 필요하다
  `,`■ 자손ㆍ박관이면사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다
  속는 일이 있고 자손 재액이 있다.
  `,`■ 자손ㆍ박관이외사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  형제상해 불구의 상이다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/057/row-1-column-1.png`,
    `/static/images/sagwa/057/row-1-column-2.png`,
    `/static/images/sagwa/057/row-1-column-3.png`,
    `/static/images/sagwa/057/row-1-column-4.png`,
    `/static/images/sagwa/057/row-1-column-5.png`,
    `/static/images/sagwa/057/row-1-column-6.png`,
    `/static/images/sagwa/057/row-1-column-7.png`,
    `/static/images/sagwa/057/row-1-column-8.png`,
    `/static/images/sagwa/057/row-1-column-9.png`,
    `/static/images/sagwa/057/row-1-column-10.png`,
    `/static/images/sagwa/057/row-1-column-11.png`,
    `/static/images/sagwa/057/row-1-column-12.png`,
  ]

const HEASYEOL = [
  // 1
  `  이 과는 천지 12신이 상하에 겹치고 복하며 움직이지 않기 때문에 복음과로 된다. 4과에 극이 없으니 양일은 일간상신을 초전으로 하고, 중․말전은 형충(刑沖)을 취용한다. 일진(日辰)의 음양이 복하여 움직이지 않고 신음하기 때문에 복음이라 한다. 정단건은 정중동(靜中動)을 이룬다. 구태를 지키면서 신사(新事)를 기다리는 과이다.
  일반적으로 복음과로서 극이 없는 양일은 스스로 일간상신을 사용하여 초전으로 한다. 양은 강(剛)으로 스스로 강하다고 자처하므로 자임격(自任格이라고 한다. 스스로 자기를 강하다고 자처하면 반드시 과오를 범한다. 이 과는 대인이 가까이 온다. 도망한 자도 가까이에 있다. 모든 화환(禍患)은 유연(留連)한다. 방문해도 나오지 않는다. 복음이란 구부리고는 펴지 않고 정중(靜中)에 있다. 생각에 따라 움직이고 무슨 일이나 머리를 내어서 폐색(閉塞)한다. 유순하게 자기를 지키면서 길하고 조용하면 길이 된다. 움직일 때는 정체가 있다.
  신(申)은 일간의 건록이자 또한 신(申)은 백호의 본체(本體)로서 과전 중에 모이고, 일귀에는 또 백호가 승한다. 말전의 사(巳)는 정신이 승하고 중전 인(寅)은 재마(財馬)로 된다. 신금(申金)은 모여서 재마를 극상하니 움직이면 재화가 있다. 백호가 묘(廟)에 들어가고, 사(蛇)는 각(角)을 생하며, 구진은 봉인(捧印)으로 된다. 시일(時日)이 경과한 후에 구직․입학시험․공관(公官) 등은 모두 대길조(大吉兆)된다.
  야귀의 초전에 천후가 승하면 가중에 두풍(頭風)의 병고(病苦)에 시달리는 부인이 있다. 말전이 초전을 극하니 초의 흉도 후에는 길행(吉行)이 된다. 일귀는 순행하며 삼전은 백․사․구이고 야귀는 역포하며 삼전은 후․청․주이다. 사․호가 삼전 중에 들면 괴이한 일이 있고, 정마가 삼전 중에 있으면 여상인(旅商人)의 징조가 있다. 말전 巳와 일(日)이 지합하고 사(巳)에 주․구․가 승하면 관의 소송사가 있다.`
  ,
  // 2
  `  이 과는 4과 중에 극이 없고, 양일은 제1과에서 삼위(三位)의 유(酉)를 초전으로 한다. 일간기궁 신(申)에서 유(酉)는 나망이 되므로 맹동(猛動)은 장해를 초래한다. 
  밤에 정단하면 미(未)에 귀신이 승하고 일간일지에 가(加)하기 때문에 수고하지 않아도 효과를 본다.
  팔전과(八專課)는 사물이 중첩된다. 오미(午未)는 서로 상합상생하기 때문에 모든 것이 유리하게 된다. 낮에 정단하면 염막귀신이 일(日)에 임한다. 시험은 고위에서 통과한다. 
  귀신이 택상에 있어 지(支)를 생하므로 손윗사람의 후원이 있다. 가택상에 천공이 승하면 마른 우물이 있거나 우물로 인한 괴사(怪事)가 발생한다.  또는 택주(宅主)가 질병에 걸린다. 일간일지상신이 지합하고 초전 경(庚)의 왕세로 되면 팔상동정(八象同井)의 상이고, 교섭사는 협력타결(協力妥結)한다. 
  일귀는 순행하며 삼전은 상․공․공이고, 야귀는 역행하며 삼전은 음․귀․귀로 되나. 천공이 승하면 신청한 건은 모두 승낙을 받는다.`
  ,
  // 3
  `  이 과는 4과 중 오경(午庚)에서 1상이 1하를 극하니 원수과로 한다. 이 과는 비룡천(飛龍天)에 있어 운룡(雲龍)․풍호(風虎)가 상종(相從)하는 대인(大人)의 상이다. 구종(九宗)의 원(元)이자 64과의 으뜸이기 때문에 원수과라고 명명한다.
  일간일지의 오(午)는 일간인 나를 극하여 초전으로 된다. 삼귀(三鬼)는 재(災)가 적지 않는데 다행히도 진토(辰土)가 인수(印綬)로 되니 살은 인(印)에 생화(生化)하여 화환(禍患)이 없다. 역간전(逆間傳)의 오진인(午辰寅)은 고조격(顧祖格)이라 한다. 오(午)는 말전의 인(寅)에서 설기하니 인(寅)의 자(子)로 된다. 인(寅)은 오화(午火)에서 보아 장생의 지이며, 오(午)에서 인(寅)으로 전하고 자(子)는 돌아서 인(寅)의 모(母)를 돌본다. 그리하여 이렇게 명명한 것이다.
  구재는 모사․망사(望事) 모두 길이다. 또 대인이 오는 격이다. 납세(納稅)․진물(進物)․관공직(官公職)을 구하는 것도 길조이다. 야귀는 오(午)에 사신이 승하고 가정에 임부(姙婦)가 있다. 만약 그렇지 않으면 여인이 이산하는 일이 있다. 
  일귀는 순행하며 삼전은 청․합․사이니, 용두사미(龍頭蛇尾)로 되며, 무슨 일이나 빨리 하는 것이 좋다. 야귀는 역포하고 삼전은 사․합․청이며, 사(蛇)는 청으로 화하니 퇴직했다가 복직한다. 지망은 원대한 것이 좋다. 진(辰)에 육합이 승하면 문서사(文書事)에 주의하여 전일하게 검사하여야 한다.`
  ,
  // 4
  `  이 과는 1상이 1하를 극하는 원수과이며, 삼전사맹신은 원태격(元胎格)이다. 신(申)의 일지에서 인(寅)을 보면 역마이고, 사(巳)는 정신이다. 사람과 가택 및 여러 인사에서 동의의 징조가 있다. 
  금일(金日)이 정신을 만나면 흉사로서 움직임이 있으며, 일간일지상신에 관귀(官鬼)가 승하면 서로 손상한다. 그런데 미(未)의 해(亥)는 기(己)의 관귀를 제하니 흉사를 보아도 흉해가 없다. 그러나 년명상신에 정신이 승하면 반드시 관재를 본다. 또 월건상에 사기가 승하면 도망사건을 만난다. 행년에서 정신을 제할 것을 요한다.
  말전으로부터 중전을 생하고 중전은 초전을 생하여 일간으로 돌아오면, 관직에 있는 사람이나 대회사에 근무하는 사람에게는 길조가 있다. 일간은 지상신(支上神)에 정신이 있으면 몸에 임하여 택에 들어간다고 하여 재(災)가 있어야 비로소 움직인다. 
  일귀는 순행하고 삼전은 구․사․음이며, 야귀는 역포하고 주․청․상이다.`
  ,
  // 5
  `  이 과는 자진(子辰)이 상(上)에서 하(下)를 극하여 중심과로 되나. 신자진(申子辰)의 삼합뿐이고 과전이 삼합수국이므로 윤하격(潤下格)이다. 초․중전은 자(子)가 공망하고, 일간일지상신은 진토(辰土)에서 생조(生助)하니 생기는 한이 없다.
  수국(水局)은 일간 경(庚)에서 상생하여 설기 태과로 되며, 초전 공망은 고신(孤神)의 발용(發用)으로 되어 소모손(消耗損)이 있다. 따라서 무슨 일이나 성사되지 않고, 유손무익(有損無益)하다.
  진(辰)에 현무가 타니 실탈(失脫)을 면할 수 없으니 무슨 일이나 조용히 지키는 것이 좋고, 맹동하면 비용을 감당하지 못한다. 
  그러나 일간일지상신에서 같이 생하면 다른데서 후원을 받아서 유리유익하게 된다. 즉 장사는 순리롭고 공동 경영하는 일도 잘 된다. 갑일(甲日)․무일(戊日)․경일(庚日)에 따라 귀신이 해(亥)에 임하면 귀신이 천문에 올라가서 신장살몰(神藏殺沒)이 되므로 모사는 방해와 지장이 없다. 
  일귀는 역행하고 삼전은 사․청․현이며, 야귀는 순행하고 삼전은 청․사․현이다.`
  ,
  // 6
  `  이 과는 4과이지만 2과밖에 없고 하에서 극할 뿐이므로 중심과를 취할 수 없다. 양일은 양비(陽比)라고 하여 술묘(戌卯)의 양의 극을 사용하며 초전으로 하고 비용과(比用課)가 된다. 중전은 정신의 귀로 되고 명암의 귀효는 재해가 중하다. 다행히도 자(子)의 수신에서 제하여 공망이 되니 힘이 약하고 재화도 경하다. 시일(時日)을 경과하면 사(巳)가 말전에 들어가서 공망이 되므로 악신은 기력이 없으며 재화를 제거한다.
  지일과(知一課)는 극이 양과(兩課)에 있기 때문에 사물은 두 갈래로 갈라지는데, 하나를 찾아서 초전으로 하므로 일사(一事)에 귀한다.
  과전에 술(戌)의 묘가 세 곳에 있으면 일간이 묘를 만난다고 하여 무슨 일이나 휴식하는 것이 좋다. 또 사람과 택의 음신이 묘에 앉고 귀신이 공망하면 기쁜 일이 없다.
  이 과의 한 실례를 든다. 신(申)의 월장이 축시(丑時)에 처자와 가족이 동반하여 여행하는 것을 정단한 결과 먼 타국에서 의외의 사고를 만나 사망하게 된다고 보았다. 신(申)은 천지반의 공망의 지에 앉는다. 간지의 음신은 묘가 되고 묘 중 겁살이 되며, 말전 자사(子巳)는 사(死) 자에 해당한다. 간지상신 묘(卯)는 관개(棺蓋)로 되기 때문에 주차파괴(舟車破壞)를 암시한다. 그러므로 남녀 5인은 모두 변사(變死)할 우려가 있었다.
  4하가 상을 극하니 존장(尊長)에 이롭지 않으며 또한 상을 극하면 명록(名祿)을 손상하기 때문에 무록(無祿)이라 부른다. 일귀는 역행하며 삼전은 합․상․사이고, 야귀는 순포로 되며 삼전은 합․음․청이다.`
  ,
  // 7
  `  사과삼전에서 천지상충국이 되고, 하에서 상을 극하는 인신(寅申)을 초전으로 한다. 인(寅)의 재는 역마를 합병하여 중전 건록은 사재일록(四財一祿)이 된다. 야귀는 인(寅)에 백호가 타서 협극(夾剋)이 되어 재를 위해서 동분서주하고 반복 동요하며 극지(剋地)에 앉아 재 때문에 고생을 한다. 반음과는 덕(德)과 녹(祿)이 없어지기 때문에 구사는 결속짓는 것이 좋다. 구재는 복(福)을 맞아 들이는 것은 좋지 않다. 다행히도 인(寅)의 초전은 순의(旬儀)가 되어 흉을 구할 수 있으므로 다소의 자비여택(慈悲餘澤)이 있다.
  비전에 이르기를 ｢초전육의(初傳六儀)는 병이 치유되고, 복역 중인 사람은 불원지간에 석방된다｣고 하였다. 일간일지상신 인(寅)의 재(財)는 문에 임하여 택에 들어간다고 하며 또한 일귀의 시는 천후의 수(水)가 인목(寅木)을 생하기 때문에 일순(一旬)을 지나면 집안에 재(財)의 기쁨이 있다. 
  일귀는 역행하고 삼전은 후․청․후이며, 야귀는 순행하고 삼전은 백․사․백이다. 야귀는 사호전투(蛇虎戰鬪)하여 놀라운 사건을 만날 위험이 있다.`
  ,
  // 8
  `  이 과는 4과중 상하의 극이 없고, 일간의 양신․음신․간지가 동일하여 극이 없으니 양순의 삼신 묘(卯)를 초전으로 하는데, 이것을 초진순포(超進順布)라고 한다. 중․말전은 일간일지상신과 팔가동병(八家同倂)하여 전일(專一)로 되기 때문에 팔전과라고 한다. 밖에서활동하면 이익이 없으므로 전적으로 집을 정돈하고 수신(修身)하는 것이 좋다. 이인동심(二人同心)․피아협력(彼我協力)․대중회맹(大衆會盟)이라고도 한다.
  양은 전진하면 남자에게 좋고 분실한 것은 안에서 찾아야 한다. 양일은 전진하여 결기(決起)를 취급하고, 이 과는 청․상․귀신의 길장 및 천월이덕(天月二德)을 지니면 길조로 본다. 팔전과는 상하존비(上下尊卑)가 동실에 있고 또 상하의 간격이 없으므로 동권(同權)의 상이다. 극이 있어 초전을 이루면 제극(制剋)이 도리는 있지만 극이 없는 양순음역삼위(陽順陰逆三位)를 요신으로 하면 신축(伸縮)해서 음실(淫失)의 일이 있기 때문에 유박불수격(帷薄不修格)이라고 명명한다. 2과밖에 없기 때문에 일이 겹치고 우희(憂喜)는 재차 발생한다. 
  후․합․현․음 등이 삼전에 들어가면 음란색정(淫亂色情)의 건이 있고, 남녀 부부의 건을 정단하면 부정(不貞)의 징조가 있다. 묘(卯)의 재성(財星)을 초전으로 하면 처 이외의 이성(異性)의 건이 있는데, 유박불수는 음사(淫邪)를 방지한다.
  이 과는 무슨 일이나 근외(勤外)에 가지가 생하는 상이 있고, 천공과 공망은 서로 부실(不實)로 끝난다. 일귀는 역행하고 삼전은 음․귀․귀이며, 야귀는 순행하고 삼전은 상․공․공이다.`
  ,
  // 9
  `  이 과는 진자(辰子)가 상에서 하를 극하여 원수과이고 삼전 진신자(辰申子)는 삼합수국하여 윤하격(潤下格)이다. 4과는 모두 공망하고, 삼전도 초․말이 공전하며 중전만이 실(實)이다. 이리하여 경(庚)에서 자(子)는 십이운이 사(死)가 되기 때문에 활동을 정지하여야 하며, 활약․계획․모사 등은 모두 이롭지 않다.
  삼합수국은 물이 흘러서 유통성이 있지만, 초․말전이 공망하고 수원이 끊어져 도리어 설기태과를 해소한다. 조용하면 소모의 고통은 경하다. 삼전은 체생하지만 공망하면 타인의 추천은 있어도 입에 발린 말에 불과하고 진정(眞情)은 없다. 
  일귀는 역포하고 삼전은 현․청․사이며, 야귀는 순행하고 삼전은 현․사․청이다.`
  ,
  // 10
  `    이 과는 4과에 상하의 극이 없기 때문에 양일은 양신의 해(亥)에서 순행삼위에 해당하는 축(丑)을 초전으로 한다.
  해(亥)는 순미(旬尾)의 지지로서 일간일지상신에 가담하고, 또 중․말전에서 본다. 만사에서 입을 다물고 침묵을 지켜야 하며, 물러나 앉아 재화를 피면하여야 한다. 
  유박불수격은 존비가 공실하여 남녀를 가리지 않는데 우희(憂喜)가 모두 있다. 따라서 만사에 조심하면 후회가가 없다. 사과(四果)가 모두 하에서 4상을 생하는 것을 원소근단(源消根斷)이라 칭하여 허모(虛耗)가 많으므로 경계하여야 한다.
  일귀는 역해이며 삼전은 귀․주․주이고, 야귀는 순포하며 삼전은 공․구․구이다. 중전․말전의 주작․구진이 내외극전(內外剋戰)하면 무슨 일이나 구설과 불평이 거듭 발생한다.`
  ,
  // 11
  `  이 과는 자술(子戌)이 하에서 상을 극하여 중심과로 되고, 삼전은 일지(一支)를 건너 삼전을 이루므로 순간전격(順間傳格)이다. 순역(順逆)은 각각 12격으로 초․중․말전이 일지를 건너 삼전을 이루기 때문에 이렇게 부르게 된 것이다. 순간전격은 일이 순조롭고, 자인진(子寅辰)은 향양격(向陽格)이라 한다.
  자(子)는 북방 유암(幽暗)의 지이고 인진(寅辰)은 일출하여 이동하며 양에 향하기 때문에 향양(向陽)이라 이름지었다. 정단사는 모두 처음에는 흉하지만 후에는 길이다. 병은 치유되며 송사는 화해하고 인정은 아름답다.
  술(戌)을 하괴(河魁)라 하여  일간일지상신 술(戌)이 신(身) 혹은 택상에 복(覆)한다. 초․중전이 공망하고 중전의 인(寅)은 역마 재동이 되지만 공망하므로 동조(動兆)는 뜻대로 되지 않는다. 향양격에서귀신이 해(亥)에 임하고 귀신이 천문에 올라서 흉을 풀면 이롭다. 일간일지는 상하가 서로 생하니 교차상생하여 무슨 일이나 타협․공명사(共鳴事)가 성립한다.
  일귀는 순행하고 삼전은 후․사․합이며, 야귀는 역포하고 삼전은 백․청․합이다.
  술(戌)에 현무가 승하면 보행 중 도로의 방해가 있다.`
  ,
  // 12
  `  이 과는 상하에 상극이 없기 때문에 양신 제3위의 해(亥)를 사용하여 초전으로 한다. 일간상 유(酉)는 양인(羊刃)으로 되며 팔전유박삼기격이다. 일간상신 유금(酉金)은 일간의 왕기(旺氣)로 되니 자신을 지키고 있으면 모두 영창(榮昌)한다. 일간상신이 녹왕이면 맹동은 좋지 않다. 양인이 네 곳에 있으니 피를 보는 재(災)를 반드시 방지하여야 한다. 유박불수격은 내외 간격이 없이 무슨 일이나 우희(憂喜)가 겹쳐 발생한다. 가만히 지키고 있으면 재화를 피면한다. 맹진하거나 경솔하면 신체의 상해와 고장을 일으킨다. 또 음색(淫色)에 의하여 몸을 망칠 위험이 있다.
  신유(申酉)․유술(酉戌)과 같이 지지순(地支順)으로 배열하면 나망이라 하여 모사는 졸책을 만난다.
  삼기격(三奇格)에 대해 설명한다. 갑인(甲寅)의 순 중에 해(亥)를 초전으로 하면 순삼기(旬三奇)라 칭한다. 해(亥)는 성(星)의 정(精)이고 축(丑)은 왕당(王堂)이며 자(子)는 명당(明堂)이라 하는데, 이상 삼신은 일월성(日月星)의 정기(精氣)로 하기 때문에 순중의 기(奇)를 사용하는 것을 삼기(三奇)라 칭한다. 이 신은 백재(百災)를 소산하니 흉장을 만나도 흉을 해제한다. 삼기란 갑자(甲子)와 갑술(甲戌)의 순(旬)은 축(丑), 갑신(甲申)과 갑오(甲午)의 순은 자(子), 갑진(甲辰)과 갑인(甲寅)의 순은 해(亥)로서 이들 삼신(三神)이 초전이 되면 삼기격(三奇格)이다. 만약 삼전에 해자축(亥子丑) 삼기와 완전할 경우는 더욱 길조의 과격이다. 삼기는 순기(旬奇)라고도 한다. 일귀는 순포하며 삼전은 음․상․상이고, 야귀는 역행해서 삼전은 상․음․음이다. 귀신이 공망이면 시험이나 공명은 좋지 않다.`
  ,
]


  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
