
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]

const columnD = [
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`고집`,7,3,`없음`,`01.21\n~
02.19`,`보통크기 \n고환큼

음모
적음 
분위기파`,`조용\n담담

성교후곧잠`],
  [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
  [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
  [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,8,2,`없음`,`02.20\n~
03.20`,`굵음\n적극적 매너

강력한 성교 함`,`문을 열면 적극적이 됨\n
감격을 숨기지않음`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,9,1,`특별있음`,`03.21\n~
04.20`,`단순 서툼\n시간 짧음

전,후 없이 본격적 임`,`주도적\n적극적

완벽
주의자 임`],
  [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
  [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
  [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
  [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,10,12,`있음`,`04.21\n~
05.20`,`때와 장소 안 가림\n
발정
타입
 곧 
시듬`,`정숙\n하고 
억제심 강함

분비물 많음`],
  [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
  [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
  [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,11,11,`있음`,`05.21\n~
06.20`,`기교파 반응 감상 타입\n
크고, 즐김`,`위쪽\n 절정감에 집요함

주도권을 빼앗기도`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,12,10,`없음`,`06.21\n~
07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
질이 큼

소극적 못견딤
절정 빠름`],
  [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
  [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
  [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
  [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`신뢰`,1,9,`없음`,`07.21\n~
08.20`,`정력파 女만족시키려 함\n
쉽게 지침`,`적극적 타입 수축 형\n
중년 쇠퇴 후 회복함`],
  [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
  [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
  [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,2,8,`없음`,`08.21\n~
09.20`,`헌신 리드 파 \n더러 포경

성지식 풍부함`,`부끄러움 많이 탐\n
성기는 명물임`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`설득`,3,7,`없음`,`09.21\n~
10.20`,`조루증 많음\n
음경은 작은 편`,`음모 많음\n
수축 형이고 능란함`],
  [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
  [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
  [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
  [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,4,6,`없음`,`10.21\n~
11.20`,`보통이상 크기\n
극적. 본능에 그침`,`은밀함\n절정을 끝없이 함

전신
경련. 수축 형 강력
 명물`],
  [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
  [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
  [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,5,`없음`,`11.21\n~
12.20`,`통크기. 강직도 있음\n
오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
  [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`설득`,6,4,`없음`,`12.21\n~
01.20`,`보통 크기. 강직도 있음\n
여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
  [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
  [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
  [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,``,``],
    [`색정`,`외男 색정사有`,`大`,`단절기`,`색정사有`,`단절수난`],
    [`좌모완수(座謀完遂)`,`앉아서 지키며 도모하여야만 이룰 수 있는 상`,`大`,``,`모두성사`,`總是成災`],
    [`총시성재(總是成災)`,`모두 재난 뿐인 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,`미초희경`,``],
    [`극남`,`타 남자와의 갈등사`,`大`,`8`,`연루견련`,`觀書문화`],
    [`여식`,`여식 관련사`,`小`,`침체기`,`예정변경`,`남자갈등`],
    [`예하구재(禮下求財)`,`예의를 갖춰 금전을 부탁해야 하는 상`,`小`,``,`침체공허`,``],
    [`능욕박해(凌辱迫害)`,`남이 모르는 능욕박해를 당하는 상`,`大`,``,`여건부족`,``],
    [`후원`,`후원 관련사`,`大`,``,``,``],
    [`급속`,`내사 급속사 有`,`小`,`9`,``,``],
    [`부친`,`부친 관련사`,`小`,`소외기`,`침체절감`,`손해가출`],
    [`교지불미(交之不美)`,`상대와의 교제가 아름답지 못한 상`,`大`,``,`관재형흉`,`도적악의`],
    [`도적악의(盜賊惡意)`,`도적이 악의를 갖고 있으므로 공격하지 말아야 할 상`,`小`,``,`(허실)`,`공격말것`],
    [`후원`,`外 후원 단합 관련사`,`大`,``,``,``],
    [`문서`,`문서 관련 동요사`,`小`,`10`,`가실불화`,`복통수욕`],
    [`결속`,`개혁사를 음4월 완성 大財를 획득하나 부모災害발생함`,`小`,`침해기`,`침해수난`,`가택동요`],
    [`미초희경(微招喜慶)`,`고위로부터 술잔을 내려받는 기쁨을 애써 참는 상`,`大`,``,``,``],
    [`존친재해(尊親災害)`,`존친의 재해가 우려되는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`11`,``,`중중지愛`],
    [`매사 영속성없음`,`직업지위권리남편자식사는 선천적 기복재해를 겪음`,`小`,`쇠퇴기`,`직위불안`,`단합화합`],
    [`우의실탈(憂疑失脫)`,`가출도망사를 의혹 근심하는 상`,`小`,``,`환성경사`,`허성허사`],
    [`이어진취(利於進取)`,`여러 난관을 극복하고 이득을  위해 나아가는 상`,`大`,``,``,``],
    [`형제`,`형제 자매 관련사`,`大`,``,`尊卑상몽`,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`12`,`(차이음란)`,`재물유실`],
    [`매사 영속성없음`,`형제자매친구동료동업사는 선천적 기복재해를 겪음`,`小`,`과시기`,`출행이동`,`파재극처`],
    [`행인귀회(行人歸回)`,`출행하여 되돌아 귀가하는 상`,`小`,``,`손해도망`,`가출실종`],
    [`재물유실(財物遺失)`,`재물을 잃은 상`,`小`,``,`우려`,`우려`],
    [`직녹`,`직업녹위 관련사`,`大`,``,``,``],
    [`자산`,`자산사`,`大`,`1`,`動止咸宜`,`도둑難捕`],
    [`색정`,`내女 색정사 有`,`小`,`절정기`,`상하화합`,`색정사有`],
    [`수기동모(須忌動謀)`,`도모사를 적극성을 피하고 반드시 앉아서 진행해야 할  상`,`小`,``,``,``],
    [`왕래사송(往來詞訟)`,`재판을 받으러 역에서 역으로 오가는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,`재난극복`,``],
    [`극남`,`남자 갈등사`,`大`,`2`,`이익진취`,`존비상몽`],
    [`결속`,`결속단합하여 후원사를 크게 이루나 단,자녀 災害 발생함`,`小`,`면모기`,`외사막힘`,`(신분나이`],
    [`진사유암(進事幽暗)`,`진행사 또는 자손사가 어두운 상`,`小`,``,``,`차이음란)`],
    [`이어진취(利於進取)`,`재난을 극복하고 이득을 취해 나아가는 상`,`大`,``,``,`남자갈등`],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`급속`,`상업 관련 급속사`,`小`,`3`,`재난전소`,`動止함의`],
    [`처`,`처 관련사`,`大`,`학습기`,`급속사有`,`비용과출`],
    [`음사소재(陰私小災)`,`불륜관계로 인한 작은 재해를 겪을 상-신분의 상하관계`,`小`,``,``,``],
    [`동지함의(動止咸宜)`,`안방을 지키므로 가나 있으나 좋음 `,`大`,``,``,``],
    [`송쟁`,` 직업직위 불안사-외사 급속 대립 송쟁사-상신우려`,`小`,``,``,``],
    [`극부`,`남편갈등사-손해 가출사 우려`,`小`,`4`,`직위불안`,`자식배양`],
    [`자식`,` 자식 관련사`,`大`,`개시기`,`가택이전`,`남편갈등`],
    [`불의동모(不宜動謀)`,`적극적으로 움직여 하는 도모사는 안 좋은 상`,`小`,``,`불륜숨김`,`도적근심`],
    [`간사은닉(奸私隱匿)`,`간사은닉-가택이사-관계상이 막힌 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,`탄식신음`],
    [`출행`,`내사 출행이동원행사`,`大`,`5`,`총시성재`,`(病事미수)`],
    [`남편`,`남편 관련사`,`小`,`배양기`,`의혹근심`,`출행이동`],
    [`직원구해(職員救解)`,`당할 뻔한 害를 구해주는 상-종업원 관련`,`小`,``,``,``],
    [`도적우의(盜賊憂疑)`,`도적 관련 의혹근심하는 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`남편`,`남편 관련사`,`小`,`6`,`불의투서`,`간사은닉`],
    [`출행`,`내사 출행이동원행사`,`大`,`존재기`,`구설失財`,`출행이동`],
    [`음인유재(陰人有災)`,`불륜여인의 재해가 있는 상`,`大`,``,``,``],
    [`간사은닉(奸私隱匿)`,`가택이사-간사은닉의 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`관사직업직위색정사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`직업직위불안여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"우원문서부친조력사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,"후원문사모친사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,"직녹자산권리형제색정사조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`직업직위불안여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,"금전상업처조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"금전상업여인조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`관사직업직위출행이동사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 전의가 별로 없고 있다해도 피해가 가벼움`,0,0,`호가호위로 상대를 공격하나 적중되지 않으므로 되려 불리해지는 人`,0,0,`일이 거꾸로 진행되므로 물러나야 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`타세의존(他勢依存)연루**만약 타인과 다툰다면 여건이 부족하므로 피하는 것이 좋음`,0,0,`타인 타사에 연루되어 의혹을 받고 전후 좌우 여건이 부족하여 타인에게 의뢰하는 입장의 人`,0,0,`연건 조건이 갖춰지지 않아 불비부족함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 당당하고 신속하게 종결하는 것이 유리함 `,0,0,`신속하게 속결하지 않고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,0,0,`풍운불측의 상**가택권리를 양도함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 당당하고 신속하게 종결하는 것이 유리함 `,0,0,`신속하게 속결하지 않고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,0,0,`여러 일을 하나로 일관시키는 개혁사가 있음-음4월에 완성되어 큰 돈을 다루게 됨-부부이별사도 많음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 순응하며 유화적 처신으로 일을 지체시키거나 연기해냐만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순한 언행으로 결정을 뒤로 미루면 반드시 결과가 유리하게 되는 人 `,0,0,`독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 격이므로 신중해야 함`,0,0,`반복부정하고 심신의 의지처가 없으며 가정불녕하고 일이 반복되며 배신사를 겪는 人`,0,0,`온 사람은 가려하고 간 사람은 오려고 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 사안을 후로 미루는 것이 좋음`,0,0,`풍상고초 후 성공할 人`,0,0,`기존 방침을 시급히 바꿔야 함**육친 간 아래 가족이 위 존친을 박해함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 당당하고 신속하게 종결하는 것이 유리함 `,0,0,`신속하게 속결하지 않고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,0,0,`복잡한 여러 일을 하나로 결속시켜 후원사를 완성함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 전의가 별로 없고 있다해도 피해가 가벼움`,0,0,`호가호위의 세에 놀라 심중 두려움이 크나 언행을 조심하며 조용히 있으면 놀란 일이 자연히 사라지게 되는 人 `,0,0,`처음은 불리하고 나중이 유리함**관직자는 영전함**관재 우려가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 순응하며 유화적 처신으로 일을 지체시키거나 연기해냐만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순한 언행으로 결정을 뒤로 미루면 반드시 결과가 유리하게 되는 人 `,0,0,`1남2녀 삼각관계 있음**타인에 의존하는 상-타인이 스스로 와서 금전을 도움**일이 역으로 띄엄띄엄 진행됨**생업장을 이전함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`일이 순서적으로 진행됨**기이한 도움으로 전화위복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동(不動)신음**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`스스로 유약하다고 여기고 적극적인 활동을 주저하고 신음하는 人 `,0,0,`어떤 일도 중도에 폐기됨**결과없이 반복됨**이사를 하려함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`충성-무력`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`기사`,`구사`,`돌발사`,`모호사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면-무력`,`선성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사-급속사포함`,`미사`,`신구중간사`,`내사`,`표리사`,`배신-무력`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [` 쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,1,4,`음6월`,2,2,4,`음2월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월`,2,1,3,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음8월`,2,2,4,`음10월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음5월`,1,1,2,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음8월`,2,1,3,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,3,2,5,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월-연기 또는 파혼우려`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음2월`,3,3,6,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음11월`,2,2,4,`음5월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음10월`,3,2,5,`음3월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,3,3,6,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 중반 실적이 부진해도 초 종반에는 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 아둔하고 배경이 없어 업무 전반에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 인정미가 있으나 사심이 있어 초반에 권리를 양도하고 종반에도 피해를 끼치는 행태를 보이게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하나 단합결속단합을 단행하여 음4월에 완성 후 자금을 크게 이루어 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 초반이 허무하고 종반이 저조하며 오직 중반의 실적 뿐이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무 내내 내부적 동요불안사를 겪지만 표면적 실적은 돋보이게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 아둔하여 업무 전반이 수준이하가 되고 특히 종반이 공허함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 복잡한 여러 사안을 일관시키는 결속단합을 꾀하여 탁월한 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이며 업무가 강하지 못한 듯하나 수준이상의 실적으로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 초반의 이전사 외 수준 이하의 실적을 보임 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사저을 갖고 상대와 같은 소리를 내지만 업무 전반에 기이한 조력으로  실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대없음`,`이행`,`선攻후攻`,`進向`,`원`,`留`,`무난`,`남남서`,``,`정서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인무력`,` 退背`,`  근`,`  去`,`주인조력`,`서남`,``,`서북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`본인무력`,` 退背`,`  근`,`  去`,`주인가해`,`정서`,``,`북북서`],
    [`10.11`,``,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근반대`,`이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`북서`,`정북`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`사안무력`,`退背`,`  근`,`  去`,`주인무력`,`북북서`,` 정북`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후不`,` 退背`,`  원`,`  去`,`세입가해`,`정북`,`북북동`,``],
    [`10.11`,`  集`,``,``,`빠르게`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선이후費`,`退背`,`  원`,`  去`,`세입가해`,`북북동`,`정남`,``],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인반대`,`이행`,`선난후난`,`進向`,`원근왕래`,`  留`,`주인조력`,`동북`,`남남서`,``],
    [`10.11`,` 多集`,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인제압`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`세입조력`,`정동`,`남서`,``],
    [`10.11`,` 多集`,``,``,``,``,``,``,`파재`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대있음`,`이행`,`선난후난`,`進向`,`  원`,`  留`,`세입조력`,`정서`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인동조`,`이행`,`선난후이`,`向進`,`  근`,`  留`,`주인조력`,`북서`,``,`북서`],
    [`10.11`,` 多集`,`내부조합`,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대없음`,``,`선攻후費`,`退背`,`  근`,`  去`,`무난`,`정남`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`자살귀`,` 水투신`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`신부정귀`,`신不定`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`여귀`,`가장부인`,0,0,`육친`,0,``],
    [`질병`,`음귀`,`水반입`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`음귀`,`水반입`,0,0,`육친`,0,``],
    [`질병`,`음귀`,`산소흉함`,0,0,`묘좌`,`북서`,`없음`],
    [``,`수귀`,`水 익사`,0,0,`묘향`,`남동`,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,`향대`,``],
    [``,``,``,0,0,`묘속`,`봉분흉상`,``],
    [`가정`,``,``,"여고음강","사망시모",`육친`,`조부모`,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사 출행이동사`,`도적근심사`,`외 남자 색정사-도망사 -내 파재시종사우려`,`놀램-의혹-불녕사`,`외사동요분산사`,`승진영전사`,`내사 급속사`,`관직자 대길-생살대권 쥠-일반인 형벌 대흉`,`내사 동요분산사`,`폭력배의 기만능욕사`,`매사 허성불성**세속허사자**중중희애사`,`음3,4,5,6,912월 방문자는 종교진출하면 성공`,`매사 영속성없음**외사 출행이동사`,`종교진출하면 자립함`,`내 여자 색정사**직녹자산권리사**재액해소`,`사사성취사`,`내사 막힘`,`투서헌책 안좋음-구설사-금전실재사`,`외사 급속사`,`모두 재해 뿐임`,``,`관계불통-가택이사-간사은닉사`,`내사 급속송쟁사-상신우려`,`탄식신음-병 또는 事 미수사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`전화위복`,`빈궁`,``,``,`전도**병재`,``,`전도**관재**병재`,``,``,``,`빈궁`,``,`음5월 화재주의**전화위복`,``,`빈궁`,``,`빈궁**병재`,``,``,``,`경구침체사`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사**내사 급속송쟁사-상신우려`,`재난해소사**급문사 투서헌책불용-금전실재사`,`내사 출행이동사`,``,`외 남자 색정사-손해도망사-내 파재색정사 우려`,`부부반목사`,`외사동요분산사`,``,`내사 급속사`,`고위안좌-이익경영사`,`내사 동요분산사`,`폭력배의 기만능욕사`,`매사 영속성없음**매사위역사`,`종교진출하면 자립함`,`매사 영속성없음**외사 출행이동사`,`종교진출하면 자립함`,`내 여자 색정사**직녹자산권리사`,`도적악의-공격 말 것`,`내사 막힘`,`觀書-문화풍아`,`외사 급속사`,`용모단장이 필요없는 한가함`,``,`관계불통-가택이사-간사은닉사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`전도**관재**빈궁`,``,`전화위복`,`전도**관재`,``,`빈궁`,`재액해소`,`관재`,`재액해소`,`빈궁`,`관재형벌 매우 흉함`,`빈궁**병재`,``,`권리사 부족사`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,`빈궁`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`관계불통-가택이사-간사은닉사`,`매사위역사**내사  급속송쟁사-상신우려`,`재난해소사**급문사 투서헌책불용-금전실재사`,`내사 출행이동사`,``,`외 남자 색정사-손해도망사-내 파재실종사 우려`,`부부반목사`,`외사동요분산사`,``,`내사 급속사`,`고위안좌-이익경영사`,`내사 동요분산사`,`폭력배의 기만능욕사`,`매사 영속성없음**매사위역사`,`종교진출하면자립함`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함`,`내 여자 색정사**직녹자산권리사`,`도적악의-공격 말 것`,`내사막힘`,`觀書-문화풍아`,`외사 급속사`,`용모단장이 필요없는 한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`빈궁`,``,`재액해소`,`빈궁**관재`,`전화위복**재액해소`,`관재`,``,`빈궁`,``,``,``,`빈궁`,`관재형벌 매우 흉함`,`빈궁**병재`,``,`권리사부족사`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,`빈궁`,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`도적실세-잡음`,`외사 급속사`,`음사폐호-가택안녕사`,``,`신음탄식-병 또는 事 미수사`,`내사 급속송쟁사-상신우려`,`재해제압-이득진취사`,`내사 출행이동사`,`외 남자 색정사-손해도망사-내 파재색정사 우려`,`눈이 가려진 격-상해불가`,`외사동요분산사`,`불리 고시-없는 구설근심사`,`사사성취사`,`내사 급속사`,`내사 동요분산사`,``,`매사 영속성없음**중중희애사`,`종교진출하면 자립함`,`매사 영속성없음**외사 출행이동사`,`종교진출하면 자립함**펵력배의 기만능욕사`,`내 여자 색정사**직녹자산권리사`,`앙화소멸사`,`내사막힘`,`권리사 부족사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**병재**빈궁`,``,`전도**빈궁`,``,``,``,`재액해소`,`관재`,`전화위복**재액해소`,`빈궁**관재`,``,`빈궁`,``,``,``,`빈궁`,``,``,``,`빈궁`,`음5월 화재주의**전화위복`,`빈궁**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`내사막힘`,`권리사부족사`,`도적실세-잡음`,`외사 급속사`,`음사폐호-가택안녕`,``,`탄식신음-병 또는 事 미수사`,`내사 급속송쟁사-상신우려`,`재해제압-이득진취사`,`내사 출행이동사`,`외 남자 색정사-손해도망사-내 파재실종사 우려`,`눈이 가려진 격-상해불가`,`외사동요분산사`,`불리 고시-없는 구설 근심사`,`환성경사`,`내사 급속사`,`내사 동요분산사`,``,`매사 영속성없음**중중희애사`,`종교진출하면자립함`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함**퍽력배의 기만능욕사`,`내 여자 색정사**직녹자산권리사`,`앙화소멸사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`관재`,``,`빈궁**병재`,``,`빈궁**관재`,`재액해소`,``,``,`관재`,`전화위복`,`빈궁**관재`,`재액해소`,`빈궁`,``,`관재`,``,`빈궁`,``,``,`관재형벌 매우 흉함`,`전도**빈궁`,`음5월 화재주의**전화위복`,`전도**병재**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`身물상손사`,`내 여자 색정사**직녹자산권리사`,`내사막힘`,``,`혼인단장사`,`외사 급속사`,``,`관재 병질사`,`재난해소사`,`매사위역사**내사 급속송쟁사-상신우려`,`재해제압-이득진취사`,`내사 출행이동사`,`외 남자 색정사-손해도망사-내 파재실종사우려`,`안방을 지켜 가나 있으나 좋음`,`외사동요분산사`,`신분이 차이나는 음란-존비상몽사`,`도적 못잡음`,`내사 급속사`,`내사 동요분산사`,`재물유실사`,`매사 영속성없음**매사위역사`,`종교진출하면자립함`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함**폭력배의 기만능욕사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요분산사`,`음5월 화재주의**전화위복`,`동요분산사`,`전도*빈궁`,`동요분산사**재액해소`,`관재`,`동요분산사**재액해소`,`빈궁**관재`,`동요분산사`,`전도**빈궁`,`동요분산사`,`관재`,`동요분산사**전화위복`,`관재`,`동요분산사`,`전도**빈궁`,`동요분산사`,`빈궁`,`동요분산사`,``,`동요분산사**관재형벌 대흉`,`전도**빈궁**병재`,`동요분산사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음**외사 출행이동사`,`종교진출하면자립함**폭력배의 기만능욕사`,`身물상실사`,`내 여자 색정사**직녹자산권리사`,`내사막힘`,``,`혼인단장사`,`외사 급속사`,`관재 병질사`,`재난해소사`,``,`매사위역사**내사 급속송쟁사-상해우려`,`재해제압-이득진취사 `,`내사 출행이동사`,`외 남자 색정사-손해도망사-내 파재색정사우려`,`안방을 지켜 가나 있으나 좋음`,`외사동요분산사`,`신분이 차이나는 음란-존비상몽사`,`도적 못잡잡음`,`내사 급속사`,`내사동요분산사`,`재물유실사`,`매사 영속성없음**매사위역사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,``,`음5월 화재주의**전화위복`,`빈궁`,``,`관재`,``,`빈궁`,``,`빈궁`,``,`전도**관재`,``,`전화위복`,`전도**관재`,``,`빈궁`,`재액해소`,`빈궁`,`재액해소`,`관재`,`관재형벌 매우 흉함`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**외사 출행이동사`,`종교진출하면자립함**폭력배의 기민능욕사`,`身물상실사`,`내 여자 색정사**직녹자산권리사`,`내사막힘`,``,`혼인단장사`,`외사 급속사`,`재난해소사`,`관재 병질사`,``,`매사위역사**내사 급속송쟁사-상신우료`,`재해제압-이득진취사`,`내사 출행이동사`,`외 남자 색정사-손해도망사-내 파재색정사 우려`,`안방을 지켜 가나 있으나 좋음`,`신분이 차이나는 음란-존비상몽사`,`외사동요분산사`,`도적 못잡음`,`내사 급속사`,`내사동요분산사`,`재물유실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`건재형벌 매우 흉함`,`빈궁**병재`,``,`전도**빈궁`,`전도`,`음5월 화재주의**전화위복`,`빈궁`,`재액해소`,``,``,`빈궁`,``,`재액해소`,`빈궁`,``,`관재`,`전화위복`,`관재`,`재액해소`,`빈궁`,``,`빈궁**관재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재물유실사`,`내사도요분산사`,`매사 영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**외사 출행이동사`,`종교진출하면자립함**폭력배의 기만능욕사`,`身물상실사`,`내 여자 색정사**직녹자산권리사`,`내사막힘`,``,`혼인단장사`,`외사 급속사`,`재난해소사`,`관재 병질사`,``,`내사 급속송쟁사-상신우려`,`매사위역사**내사 출행이동사`,`재해진압-이득진취사`,`외 남자 색정사손해도망사-내 파재색정사 우려`,`안방을 지켜 가나 있으나 좋음`,`신분이 차이나는 음란-존비상몽사`,`외사동요분산사`,`도적 못잡음`,`내사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`관재형벌 매우 흉함`,`빈궁**병재`,``,`전도**빈궁`,`전도`,`음5월 화재주의**전화위복`,`빈궁`,`재액해소`,``,``,`빈궁`,``,`빈궁`,`재액해소`,`관재`,``,`전화위복`,`관재`,`빈궁`,`재액해소`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내사 급속사`,`관직자 대길-생살대권 쥠-일반인 형벌 대흉`,`폭력배의 기만능욕사`,`내사동요분산사`,`매사영속성없음**중중희애사`,`종교진출하면자립함`,`매사영속성없음**외사 출행이동사`,`종교진출하면자립함`,`내 여자 색정사**직녹자산권리사**재액해소`,`사사성취사`,`내사막힘`,`투서헌책 안좋음-구설사-금전실재사`,`외사 급속사`,`모두 재난 뿐임`,``,`관계불통-가택이사-간사안녕`,`탄식신음-병 또는 事 미수사`,`내사 급속송쟁사-상해우려`,`내사 출행이동사`,`도적의혹근심사`,`외 남자 색정사-손해도망사-내 파재 색정사 우려`,`놀램-의혹-불년사`,`승진영전사`,`외사동요분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**병재`,``,`전도**관재**빈궁`,``,``,``,`빈궁`,``,``,`음5월 화재주의**전화위복`,``,`빈궁`,``,`빈궁**병재`,``,``,``,``,``,`빈궁**관재`,`전화위복`,`빈궁**관재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`승진영전사`,`외사동요분산사`,`내사 급속사`,`관직자 대길-생상대권 쥠-일반인 형벌 대흉`,`폭력배의 기만능욕사`,`내사동요분산사`,`매사영속성없음**중중희애사`,`종교진출하면자립함`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함`,`내 여자 색정사**직녹자산권리사`,`사사성취사`,`내사막힘`,`투서헌책 안좋음-구설사-금전실재사`,`외사 급속사`,`모두 재난 뿐임`,``,`관계불통-가택이사-간사은닉`,`탄식신음-병 또는 事 미수사`,`내사 급속송쟁사-상해우려`,`내사 출행이동사`,`도적의혹근심사`,`외 남자 색정사-손해도망사-내 파재실종사 우려`,`놀램-의혹-불녕사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,`재액해소`,`관재**빈궁**병재`,``,`빈궁`,``,``,``,`전도**빈궁`,``,`전도`,`음5월 화재주의**전화위복`,`빈궁`,`재액해소`,`빈궁**병재`,``,``,``,``,`재액해소`,`빈궁**병재`,``,`전화위복`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외 남자 색정사-손해도망사-내 파재색정사 우려`,`놀램-의혹-불녕사`,`외사동요분산사`,`승진영전사`,`내사 급속사`,`관직자 대길-생살대권 쥠-일반인 형벌 대흉`,`내사동요분산사`,`폭력배의 기만능욕사`,`매사 허성불성**세속허사자**중중희애사`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,`매사 허성불성**세속허사자**외사 출행이동사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`내 여자 색정사**직녹자산권리사**재액해소`,`사사성취사`,`내사막힘`,`투서헌책 안좋음-구설사-금전실재사`,`외사 급속사`,`모두 재난 뿐임`,``,`관계불통**가택이사-간사은닉`,`탄식신음-병 또는 事 미수사`,`폐한인**내사급속송쟁사-상해우려`,`내사 출행이동사`,`도적의혹근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**관재`,``,``,`전도**병재**빈궁`,``,`전도**빈궁`,``,``,`성공함`,`빈궁`,``,``,`음5월 화재주의**전화위복`,`빈궁`,``,`병재`,``,``,``,``,``,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ ⊕여인, 
  ⊖上人이 도와서 이사도      하고 일도 시작하겠네?      그러나 자칫 중도폐기       되던가 
  ⊖반복되기만 하고 결과가     없겠어!
   기도해야 평탄해!
`,`■ 아무리 총을 쏴도 맞아야     속이 풀리지! 
   내 생각과 다른 운세니! 
   후퇴하는 처신을 가져야만     큰 재물이 들어올꺼야!

⊖가택음사부정 없애고!

⊕신변 상가부정이 침범했네? 
`,`■ 내것이 부족하고 여건도 안     되니 타인에게 의존하는     운세야!변경되고 연기되는    일도 있겠고 !
  계획이나 방침도 변경
   될 운세야 !
  준비부터 다시 해야겠어 !
`,`■ 집이 넘어가네? 집에 죄     인을 감추고 있는 격이야 !
⊕도로귀, 가택상가부정 
  해소요망
⊖자살귀 때문에 집에 病者    까지 생기겠어! 
  정도에 벗어난 일이 많아!  
⊕신변목기부정 침범 
⊖신변상가부정이 침범했네? 
`,`■ 여러 건의 옛것여러개를     고쳐 새것으로 바꾸려      하는데 
   여의치가 않네? 
   巳月에효과가있을거야! 

치성을 드리면 더욱 좋겠고!
`,`■ 집에 
 ⊕가택목기부정ㆍ신부정귀,   ⊖가택상가부정ㆍ변사귀가     발동해서 침침해! 
   이것들을없애거나
  이사를 하면 소원이 이루     어지겠어! 
`,`■ 색정불화로 가정에 팔자     바꿀 사연이보이네 ? 
   서로 다른 마음을 갖고 있      으니원망할데도없고       의지할데도 없겠어! 
   저주도 받겠고…
   기도가 살길이야!
`,`■ 육친골육이 등을 돌렸네?     하극상이야!

⊕여귀, 
⊖자살귀 장난이네? 
  그대로 두면 여러사람에게    사기까지 당하게 생겼어!
`,`■ 다인 다사건을 취합하겠     는데사람은 신불의 노여     움을받고 있고 집은 어     두우니 여러 사람의 도움을 
   얻으려면

⊕신부정귀, 
⊖음귀ㆍ산소탈을 해소해야만    소원사가 형통해질거야!
`,`■지금 마음속에 놀라고 있는    것은 자연히 없어질 일인데 정작⊕여귀, 
   ⊖수귀 침범문제야! 
  이것만 해소하면 편안해
  질거야!
⊕신변음사부정 침범 
⊖신변여고음강부정침범했네? 
`,`■ 男：돈과 여자가 스스로 오는         운세네?
   女：미혼=시집을 가겠네 ?     기혼=스스로 타인(남자)     의재물이되어주고있네?

⊕신부정귀, 
⊖음귀ㆍ산소탈은 예방하고
 구설도 막아야지!
`,`■집안에 금전 여자가 문제네! ⊕신부정귀ㆍ가택음사부정, ⊖음귀ㆍ산소탈ㆍ가택수망노부    부정들이 잔뜩 들어와 이러    지도 저러지도 못할 사정이    되었어! 
  해소하면 길이 열려!
 
⊖신변 음사부정이 침범했네? `];
  const YOGYEOL1 = [`■ 귀인이 재물을 먼데서 보내준다. 또 중ㆍ말에서도 도와주나 지안에 약간의 허물이 생기리라.
  `,`■ 타인이 내게 와서 구하니 규범에 벗어나지는 않으리라. 낮정단은 내녹에 범이 탔으니 지키기가 눈물겹다.
  `,`■ 내가 타인에게 갔으나 일은 깨지고 빈 절차뿐이다. 이같은 일이 세 번이나 놀라지 말라. 헛것이다.
  `,`■ 서로의 교제가 화목하나 직녹 문제는 좋지 않다. 낮에는 범이 밤에는 도적이 내녹을 가져가니 쉬 는 것이 좋다.
  `,`■ 빈것들이 깨지기까지 하니 이런 일을 가정에서 많이 본다. 집안 재산은 이미 패했으나 천장이 도와 재기하리라.
  `,`■ 이 과체는 참관 격이니 도망자는 돌아오지 않는다. 각자가 생기를 만났으나 낮정단은 귀인의 도움이 없다.
  `,`■ 귀인도 많고 재물도 많으니 왕래가 빈번하구나. 가정에 이사나 여행건이 있고 기다리는 자는 곧 온다.
  `,`■ 아래에서 위를 모두 극하니 일은 반드시 상하가 시끄럽겠다.
  빈것들이 또 깨졌으니 재물과 재산이 없어진다.
  `,`■ 사람은 극 받고 집은 묘이고 두 귀인도 극을 받았다. 낮정단은 손해는 있어도 일이 잘 풀여 탈은 없겠다.
  `,`■ 사람은 침체되고 집은 손해가 난다. 인택 모두 허하고 위기가 온다. 밤정단은 화살이 날아오니 입을 다물어야 흉을 면한다.
  `,`■ 인택 모두 귀인이 올랐으나 일이 이어질뿐 도움이 없다. 재물은 스스로 내게 왔고 또 남모르는 도움이 있다.
  `,`■ 앞길이 어두우니 절대 사고 파는 일을 하지 말라. 아랫사람의 덕이 있어 큰 고생은 면하리라.`]
  const SIBO1 = [`■ 금전ㆍ여인사로 신음한다.
  다행히 윗사람에게서 
  송금을 받고 일을 다시
  시작하게 된다. 단 가정에 여인구설사가 있다.
  옛일은 폐기해야 한다. 
  조급은 금물이다. 느긋하게 때를 기다려야 한다.
  `,`■ 녹이 몸에 임하여 큰
  재물을 얻는 운세이다.
  인택이 모두 유익하다.
  크게 움직이지 말고 앉아서 계획하고 불러들여 일을 도모하는 것이 좋다.
  쉽다고 생각한 것은 위험하고 위험한 것은 쉽다.
  친근자는 멀어지고 소원자는 친근해진다.
  `,`■ 매사 결함이 있어 
  타인에게 의존하는
  운세에 있다.
  모든 일에 처음 생각과 계획대로 해야 한다.
  변경하면 허사가 된다. 가정에 계모 건이 있고, 형제의재해가 있게 된다.
  `,`■ 속는 일이 있다.
  가택의 권리가 넘어간다.
  그러나 교섭상담은 성취
  된다. 일을 길게 끌고 가면 녹이 부서지am로 속전속결로 결정해야 좋다.
  모든 凶이 집안에서 벌어지므로 단속이 필요하다.
  正月 임신사가 있다.
  ⊕상가부정 해소도 급하다.
  `,`■ 부부 똑같이 음탕하여
  이별의 징조가 있다.
  금전ㆍ여인사는 가을에
  이르러 효과가 있다.
  단, 웃어른의 재화가 있게 되고, 만약 병일 때는 구사일생하게 된다.
  外에서 內로 번진 일이고 남자에 의한 일이다.
  다인 다사건이다.
  `,`■ 가정에 속는 일이 있다.
  男은 별리고독인이다.
  관사ㆍ관재는 관살이 인화되어 凶을 해소하므로 귀신만 해소시키면 서로 형통해진다. 가정에
  
  ⊕가택목기, ⊖상가신살이 들어 병자가 있다.
  `,`■ 저주를 받고 있다.
  부부외심이 있어 문호개변의
  동요불안사가 가정에
  야기되어 있다.
  일이 두가지로 번졌다. 반복무상하다. 배반당한 입장에서 의지거처가 없고 원망할 수도 없다.
  `,`■ 자충수적 우환이 있고
  가정에 하극상의 불화가
  있어 上人은 불리하다.
  귀신을 제거하과 모든 일에 방침을 급히 바꿔야 이롭게 된다. 윗사람과 친하고 아랫사람을 피하는 것이 좋다.
  인내로 소성하게 된다. 
  正月 임신사가 있다.
  형제의재해가 있게 된다
  `,`■ 신불제사가 잘못되어
  신불의 앙화를 받고 있다.
  자충수적 우환이 발생한다.
  병발생도 있게 된다. 정돈ㆍ수리하고 정중히 고사ㆍ치성하면 흉이 해소되고 길해진다.
  윗사람의 도움으로 일이 잘 될 운세이다. 
  아랫사람의 일에 관여하면 해롭다.
  `,`■ 인택 모두 혼미하고
  허모사가 많다.
  가내 우환이 있고, 가장을
  속이며 가인이 원한을 품고 가해하려 한다.
  
  ⊕여인, ⊖도난사로 심중놀람이 크나 귀신을 해소하면 가정 근심사는 자연해소 된다.
  正月 임신사가 있다.
  `,`■ 가내 우환이 있고, 가장을
  속이며 가인이 원한을 품고
  가해하려 하나 덕신이 되어
  凶은 가볍다.
  여인이 스스로 나를 돕는다. 처ㆍ재는 남자에게 헌신한다.
  ⊕문서, ⊖上人의 금전이 내게 온다. 귀신을 해소시키면 복이 된다.
  `,`■ 귀신과 부정을 해소시키는
  것이 급선무이다.
  일도 다시 하지 말아야
  하고 외출ㆍ왕래의 사업은 불길하다.
  종업원이나 아랫사람이 내 흉을 구하게 된다.
  일이 지연되더라도 태연하라. 우연히 도모되어 좋게 된다. 금전 여자로 심중 의심질투가 있다
  正月 임신사가 있다.`]
  const SIBO2_1 = [`■금전ㆍ여인가택내사이다.
  내방조짐 상의 : 
  이사·이전사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■가정금전여인사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■구설ㆍ다툼신변동요사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■가정직녹사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  가택권리가 양도된다.
  `,`■가택동기사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  손재·녀하고 형제 재액이 있다 병·관재 예방이 필요하다
  `,`■官ㆍ夫, 신변사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  조용히 있는게 이롭다.
  가택 財․女손실주의 
  `,`■가정동요사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  색정불화․이심원한의 상이다.
  `,`■신변근친외사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官ㆍ夫, 신변사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.
  조용히 있는게 이롭다.
  자손재해 예방이 필요
  `,`■가정경구,관ㆍ부사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■妻ㆍ財,가택내사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■官ㆍ夫, 신변사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  조용히 있는게 이롭다.
  가택상사가 겹친다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/034/row-1-column-1.png`,
    `/static/images/sagwa/034/row-1-column-2.png`,
    `/static/images/sagwa/034/row-1-column-3.png`,
    `/static/images/sagwa/034/row-1-column-4.png`,
    `/static/images/sagwa/034/row-1-column-5.png`,
    `/static/images/sagwa/034/row-1-column-6.png`,
    `/static/images/sagwa/034/row-1-column-7.png`,
    `/static/images/sagwa/034/row-1-column-8.png`,
    `/static/images/sagwa/034/row-1-column-9.png`,
    `/static/images/sagwa/034/row-1-column-10.png`,
    `/static/images/sagwa/034/row-1-column-11.png`,
    `/static/images/sagwa/034/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  12지(支)가 각각 본 궁에 엎드려 상극이 없으므로 일지상신을 초전으로 한다. 중전은 일간상신, 말전은 충신(冲神)을 사용하니 복음두전격(伏吟杜傳格)이다.
    일간의 재(財)는 일지의 음양에 있고 귀신이 앉아서 윗사람․존장의 재를 얻는다. 정신(丁神)이 승하므로 먼 곳에서 송달되는 재도 있다. 태음이 승하면 정신은 유(酉)를 극하여 가정에 구설이 있다. 복음두전(伏吟杜傳)은 모든 일을 중지하고 다시 시작하는 것이 좋다. 일지가 초전으로 되고 중전은 미(未)이다. 이와 같은 것은 지에서 간에 전하다고 하여 그가 내게 구하는 것이다. 년명상신이 유(酉)일 때는 입시․선거에서 높게 당선된다.
    백법에는 ｢복음과로서 정마가 앉는 것은 동의가 생긴다｣고 했다. 그가 내게 이익을 구하는 것은 지(支)에서 간(干)으로 전하기 때문이다. 
    비전에는 ｢일(日)이 재지(財支)에 드는 것은 외재(外財)가 안으로 들어오는 기쁨이 있으며, 복음과는 움직여도 완성되는 일이 적다｣고 하였다.
    일귀는 순행하여 3전이 음․상․주이고, 야귀는 역행하여 3전이 귀․주․상이다. 귀신이 유(酉)에 임하는 것은 여덕격(勵德格)이니 군자는 때를 기다려야 하고 조급히 움직이지 말아야 한다. 귀신이 승하여 윗사람을 면접하고 재를 얻는 데 길하다.`
    ,
    // 2
    `  이 과는 4과 중에 상극이 없고 제3과는 일간에서 사극(斜剋)하여 요극탄사격(遙剋彈射格)으로 된다. 지상신(支上神)이 초전으로 되고 말전은 간(干)으로 전한다. 정일(丁日)의 녹신이 오(午)에 해당되므로 왕록(旺祿)이 신(身)에 임한다.
    탄사(彈射)의 재(財)는 체생(遞生)하여 상생하고 상조(相助)한다.  따라서 큰 재물을 얻는 기쁨이 있다. 인택이 다 왕세하여 모두가 유익하다. 
    백법에 ｢서로 왕성한 것은 앉아서 도모하여 반드시 유리 유익을 얻는다｣고 했다. 그가 나에게 사물을 구하는 것은 지(支)에서 간(干)으로 전한다. 
    비전에는 ｢3전이 신미오(申未午)이면 능음격(凌陰格)이라 부른다｣고 했다. 위험을 무릅쓰고 요행을 얻기 쉬운 것은 위험하고, 위험한 것은 도리어 쉬울 수 있다. 
    능음(凌陰)이란 신(申)은 음으로 미오(未午)가 이것을 능욕하여 음양이 교전하고 안위(安危)의 기(機)를 이루며 범경(凡境)을 벗어나 고명(高明)에로 들어간다는 뜻이다. 친근한 사이는 소원하게 되고 소원한 것은 도리어 친근하게 된다.
    일귀는 역행하여 3전이 현(玄)․상(象)․백(白)이고, 야귀는 역행하여 3전이 사(蛇)․주(朱)․합(合)이다. 무릇 조용하면 이롭고 망동하면 불리하다. 괴도천문(魁度天門)으로 되어 초전으로 되지 않아도 방해의 유무를 물으면 조격(阻隔)을 초래한다.`
    ,
    // 3
    `  이 과는 4과에 상하의 극이나 요극이 없고 불비격이니 별책과(別責課)로 된다. 이 과는 불비하므로 합하는 바를 사용하여 그 부족을 보충한다.
    양일에는 간합(干合)하는 승신(乘神)을 취하는데 이 날은 음일이므로 일지3합의 앞을 초용(初用)으로 한다. 
    예를 들면 일지 유(酉)의 3합은 사유축(巳酉丑)이다. 유(酉)의 앞에 축(丑)에 해당하므로 축묘(丑卯)를 초전으로 한다.  중․말전은 일간상신을 취한다.
    일간기궁(日干寄宮)의 미(未)는 일지상에 붙는다. 초전은 일간의 설기묘신(泄氣墓神)으로 된다. 중․말전은 파쇄살(破碎殺)과 순공 그리고 천공이 앉아 길흉을 모두 이루지 못한다. 무릇 별책과는 모든 일에서 타인에 의뢰하는 행동을 한다. 
    불비는 결함이 있어서 완전하지 못하다.
    백법에는 ｢중․말전이 공망하여 전으로 가지 않는다｣고 했다. 초시(初時)의 생각대로 서로 생하고 함께 생하면 만사에서 유익하다. 
    일귀는 순행하여 3전이  주(朱)․공(空)․공(空)이며 야귀는 3전이 구(勾)․상(常)․상(常)이다.`
    ,
    // 4
    `  이 과는 4과 중에서 제3과의 오유(午酉)가 1상에서 1하를 극하여 초전으로 되니 원수과(元首課)로 된다. 일간일지가 서로 교차지합(交車支合)하니 화합(和合)․교관(交關)․상담(相談)은 모두 성취된다. 
    3전은 체생하나 충형이 중첩되고 오(午)의 건록을 충한다. 그러므로 구직과 병의 관점은 흉하다.
    백법에 ｢일지상신에 건록이 앉는 것은 권섭부정격(權攝不正格)으로 된다｣고 했다. 비전에는 ｢3전이 중신(仲神)이고 중장(仲將)이 앉아서 삼교격(三交格)으로 되면 집안에 음폐(陰蔽) 내지는 간사(奸私)한 일이 있다｣고 하였다. 또 삼교격의 길흉은 모두 안에서 발생한다.
    초전의 오(午)는 육의(六儀)에 고개승헌격(高蓋乘軒格)으로 되어 흉을 제거하니 현임관에서는 천관 전직한다.
    일귀는 순행하여 3전이 백(白)․구(勾)․사(蛇)이고, 야귀는 3전이 현(玄)․공(空)․합(合)이다. 초전 천장이 백호이고 말전이 동신(蛇神)이면 호두사미(虎頭蛇尾)라 하여 가까운 장래에 실직하고 관리는 퇴직한다.`
    ,
    // 5
    `  이 과는 제3과의 사유(巳酉)가 1상에서 1하를 극하여 초전으로 되고 원수종혁격(元首從革格)으로 된다.
    3전이 3합금국으로 되어 일간의 재로 되고 가산의 분산을 도와 무사하게 된다. 종혁은 옛것을 고쳐 새롭게 하는 형상이다. 말전은 일간상신의 인수(印綬)를 충하여 존친의 신상이나 문서에 재화의 우려가 있다. 백법에 ｢3전이 전재(全財)이면 손윗사람의 재화(災禍)가 있다｣고 했다.
      사유축(巳酉丑)과 신유술(申酉戌)은 추방금국(秋方金局)으로서 오행의 금(金)은 의(義)에 속하고 형(刑)에 속하여 사물이 이루어진다는 뜻이다. 그러므로 종혁이라 한다. 이기(利器)도 이루어지고 질병은 반드시 구사 일생으로 낫는다. 소송 사건은 처음에는 흉하나 후에 길하다.  초․중전은 공망하고 말전의 유금(酉金)만이 실(實)하다. 유(酉)는 순내(旬內)의 정신(丁神)이 앉아 재와 기타의 동조(動兆)는 신속하다.
      일귀는 순행하여 3전이 공․주․음이고, 야귀는 상․구․귀이다.
      무릇 원수과는 1상이 하를 극하고 그밖에는 극적(剋賊)이 없는 것을 취한다. 하늘을 모방하여 순정(順正)하고 어지럽지 않으며, 사건은 밖으로부터 온다. 그리고 남자에 일어난다. 제과(諸課)의 으뜸이므로 원수(元首)라 부른다. 간점(看占)할 때는 초전을 빨리하고 일간일지의 천장(天將) 및 년명을 살피며, 4과3전의 길흉․왕쇠․상생․묘(墓)․충합(冲合)을 변별한 연후에 격(格)에 미친다. 원수는 115과가 있으며 주요한 곳은 일간일지․년명상신에 길흉을 구한다. 다른 과격(課格)도 이 법칙에 준한다.`
    ,
    // 6
    `  이 과는 제2과와 제4과에 상하의 상극이 있고 하에서 상을 극한다. 해진(亥辰)을 사용하여 초전으로 한다.
    초전의 해(亥)의 관귀효(官鬼爻)이나 제지(制地)에 있으며 또 일간상신의 인수(印綬)와 지합하므로 관살인화(官殺印化)로 되어 흉해는 변화하여 길조로 된다. 
    일간일지상신은 천신에게서 생기를 받아 주객(主客) 모두가 풍요롭다. 중심과는 모든 사건이 안에서 일어난다. 4과 상신은 서로 지합한다.
    백법에 ｢함께 생하고 서로 살아가는 것은 모든 일에서 유익하다｣고 했다. 
    일귀는 순행하여 3전이 귀(貴)․백(白)․주(朱)이고, 야귀는 3전이 주(朱)․현(玄)․구(勾)이다.`
    ,
    // 7
    `  이 과는 12신이 충위(冲位)에 있어서 반음과로 되며, 하에서 충하는 묘유(卯酉)를 사용하여 초전으로한다. 과전은 일간의 재(財)와 인(印)으로 되고 유(酉)는 정신(丁神)으로 된다. 3전 묘(卯)와 유(酉)는 가정 문호(門戶)의 류신(類神)이므로 사건은 가정을 벗어나지 않는다. 반음과는 정단 사건이 모두 반복하여 외출․여행․실종․도망자는 정신(丁神)이 있고 절지(絶地)에 임하니 반드시 돌아온다. 일간일지는 교차상생(交車相生)으로 되어 교관(交關)․상담사는 이루어진다.
    백법에 ｢호생(互生)하는 것은 만사에 유익하다｣고 했다. 탈상탈(脫上脫)을 만나면 허사(虛詐)를 만나게 되니 예방해야 한다. 초전의 묘가 경신신유(庚辛申酉)에 가해지는 것은 단륜과(斷輪課)로 되는 데 차륜(車輪)인 묘(卯)를 금(金)인 금구(金具)로서 극하여 용(用)을 이루므로 기구(器具)를 이룩한다. 이 격은 낡은 것을 고쳐 혁신하는 형상이다. 묘유일(卯酉日)에 점과하여 초전이 묘유(卯酉)이고 년명도 묘유(卯酉)이면 용전격(龍戰格)으로 된다. 무릇 양은 천도(天道)로서 남편으,로 하며, 음은 지도(地道)로서 아내이다. 양은 움직이고 음은 유순하여 상화(相和)하고 적대하지 않는다. 
    묘(卯)는 봄의 신이며 양이다. 유(酉)는 가을의 신이고 음이다. 그러므로 음양이 서로 교차되는 것이다. 양이 음을 의심할 때는 싸움으로 되어 분리․쟁투․불안하며, 진퇴 소장 길흉이 서로 반복한다.
    일간이 왕하여 길장이 승하면 쇠퇴한다. 길 중에 흉이 있고 흉 중에 길이 있는 격이다.
    일귀는 역하여 3전이 상․주․상이고, 야귀는 3전이 공․귀․공이다. 태상(太常)이 묘(卯)에 승하여 내전(內戰)으로 되면 주색 또는 금전의 일로서 다툼이 일어난다. 귀신이 묘(卯)에 임하여 내전으로 되니 사건은 이루어지고 후에 반복한다.`
    ,
    // 8
    `  이 과는 4과에 모두 극이 있어서 섭해과(涉害課)로 되고 4맹상신 미인(未寅)의 극을 취하여 초전으로 한다. 4과가 모두 극하는 것은 도액격(度厄格)으로 되어 가정이 불길하다. 골육이 서로 배반하고 일간상은 명귀(明鬼)로 되며, 일지상신은 둔간귀로 되어 중첩된다. 일간이 왕성하고 길장이 앉으면 흉을 완화한다. 또는 다른데서 원조를 얻는다. 교차상생하면서도 과전이 육해교차(六害交叉)한다.
    백법에는 양사협묘(兩蛇夾墓)로 되면 흉해를 면키 어렵다｣고 하였다. 3전이 체극(遞剋)하므로 뭇사람들에게 사기당한다. 육해가 서로 가해지면 의심하는 일이 일어난다. 섭해과에서 맹상신(孟上神)을 취하여 초전으로 하는 것은 견기격(見機格)이라 한다. 일을 할 때는 지나치게 되고, 앉아서 삼갈 때는 기회를 잃는다. 섭해는 풍상을 겪은 다음에 고진감래한다. 점과하는 일에서는 삽체되기 쉬우며, 병은 빨리 치료되기 어렵다. 잉태하면 태를 상하며 모든 일에서 처음에는 어렵고 후에 쉽다. 즉 먼저 흉하고 후에 길하다. 윗사람에게는 친근해야 좋고 아랫 사람은 피하는 것이 좋다.
    비전에는 ｢천지반에서 술(戌)이 사(巳)에 가해져서 일야천장(日夜天將)에 사신(蛇神)이 앉으므로 양사협묘(兩蛇夾墓)라 부르며, 병의 점단은 뱃속에 적괴 즉 암(癌)에 범하여 구할 수 없다｣고 했다. 일간일지상신이 관귀효(官鬼爻)로 되니 공명․사관․구직은 반드시 이루어진다.
    일귀는 역행하여 3전이 구(勾)․후(后)․공(空)이고, 야귀는 순행하여 3전이 음(陰)․합(合)․상(常)이다. 말전이 공망하니 시작은 있고 끝이 없다.`
    ,
    // 9
    `  이 과는 4과 중 제1과에서 1상이 1하를 극하므로 해미(亥未)를 채용하여 초전으로 하는 원수과(元首課)이다. 
    일간상신의 해(亥)가 살(殺)로 되어도 일지상신 축(丑)의 묘신이 살을 제어하므로 귀(鬼)는 두려울 것 없다. 해(亥)에 귀신(貴神)이 승하면 신기격(神祗格)으로 된다. 3전 해묘미(亥卯未)는 3합곡직격(三合曲直格)이다. 3합의 목(木)은 일간을 생하고 또 귀덕(貴德)이 신(神)에 임하여 흉을 해제하니 길조를 이룬다. 
    제1과․제2과는 내몸으로 하며 목국(木局)이다. 제3과․제4과는 상대자로 하여 금(金)국이 되므로 피차간에 뜻이 합치되지 않는다. 피차가 서로 고집하여 동조되지 못한다. 귀(鬼)가 천을과 동병(同倂)되면 신기격이다.
    이 격은 백법의 제48법에 해당되는데 인용하면 아래와 같다. ｢이 격은 일간의 귀살이 신(身)에 임하여 천을귀신과 동승(同乘)한다. 이는 귀숭(鬼崇)이 아니고 신불로 인하여 일어나는 해이다. 가정내 신불의 제사가 바르지 못하여 병환에 걸린다. 바르게 수리하고 택신에게 제사드려야 한다｣ 
    비전에는 ｢일덕(日德)․일귀(日貴)가 간상에 승하므로 윗사람의 원조에 의하여 일이 이루어진다｣고 했다. 
    일귀는 역행하여 3전이 귀(貴)․상(常)․구(勾)이고, 야귀는 3전이 음(陰)․공(空)․주(朱)이다. 3전 전인(全印)은 윗사람의 일에 이롭고 아랫 사람의 일에는 이롭지 못하다. 일간의 묘신이 일지에 가해지면 사물이 안정된 중에 불명한 일이 있다.`
    ,
    // 10
    `  이 과는 4과 중에 상하의 극이 없고 제3과 자수(子水)가 일간을 극하여 요극호시과(遙剋蒿矢課)로 되나. 이 과는 길흉이 모두 경미하다. 일간 음양신이 묘(墓)에 엎드려 설탈(泄脫)로 되고 일지는 또 자(子)에 泄氣하여 인택이 모두 공탈혼미(空脫昏迷)하다. 일지상신이 수극(水剋)을 받으나 일간상신 술(戌)에서 제어하므로 흉이 없는 것은 불행 중 다행이다.
    백법에 이르기를 ｢간묘병관(干墓倂關)은 인택(人宅)이 퇴폐한다｣고 하였다. 요극과는 사물이 가까우면서 허(虛)에 놀라는 형상으로서 일이 이루어져도 허명허리하다. 3전 중에 태음․현무․천공 중 어느 것인가가 승하면 사기에 걸릴 염려가 있다. 그러나 요극은 힘이 없으므로 화가 경하다. 
    3전은 체생(遞生)하여 순기(旬奇)가 초전으로 된다. 양귀(兩貴)가 일간을 공정(拱定)하니 공명․입시․선거․구관․구직은 모두 달성된다.
    일귀는 역행하여 3전이 후(后)․상(常)․청(靑)이고, 야귀는 3전이 현(玄)․공(空)․합(合)이다. 순기(旬奇)는 갑신(甲申)과 갑오(甲午)의 순(旬)은 자(子)로써 하고, 갑술(甲戌)의 순은 축(丑)이 순기로 되며, 갑진(甲辰)과 갑인(甲寅)의 순은 해(亥)를 순기로 한다. 그러므로 정유(丁酉)는 갑오(甲午)의 순이니 초전의 자(子)는 순기에  해당된다. 
    비전에 이르기를 ｢유일(酉日)에 자(子)가 가해지고 천후가 승하면 가정에 승니(僧尼)의 출입이 있다｣고 하였다.`
    ,
    // 11
    `  이 과는 제1과와 제4과에 상하의 극이 있고 하에서 상을 극하는 제1과를 사용하여 초전으로 하니 중심과(重審課)이다. 일간일지상신에 일야(日夜)의 귀신이 승한다.
    일지는 일간상에 와서 재로 되어 췌서격(贅壻格)이며 처재(妻財)는 내몸에 붙는다. 3전은 4과를 떠나지 않아 회환격(廻環格)으로 순환이 끝없이 이루어진다. 3전의 유해축(酉亥丑)의 간전은 응음격(凝陰格)으로서 음으로 도모하면 유리하게 되고, 공사(公事)는 불리하다. 일지의 역마는 중전에 해당되므로 현임관 및 구관․구직은 호조로 진행된다.
    백법에  ｢일야귀신이 간지상에 승하면 두 윗 사람의 원조를 받는다｣고 했다. 췌서격에는 둘이 있다. 하나는 일간기궁이 일지상신에 임하는 것이고, 하나는 이 과와 같이 일지가 일간상에 임하여 초전으로 되는 것이다. 일간을 남편으로 하고 지(支)를 아내로 한다. 일간에서 극하는 것은 처재(妻財)이다. 간(干)이 지(支)에 임하면 움직여서 조용하게 되며, 남자는 처가의 서양자(壻養子)로 된다. 이 과와 같이 일지가 간상에 임하면 조용함에서 움직임으로 되며, 여자는 남자를 따라서 시집가게 된다. 이것은 자기를 버리고 남을 따르는 몸으로써 사위[壻]로 되기 때문에 췌서격(贅壻格)이라 부른다.
    관점하는 사건은 몸을 맡기어 자유롭지 못하다. 내몸이 객(客)이 되어 재를 구하는 격이다. 즉 뜻을 굽혀서 남을 따르며, 대개는 견제(牽制)하는 일이다. 잉태는 지연되고 기다리는 사람은 늦어진다. 재명(財名)은 성취되고, 모든 일을 먼저 움직이면 이긴다. 이 과에서는 일지가 일간에 가해져서 간의 극을 받아 초전으로 된다. 여자는 남자를 따르고 그가 와서 그를 극하며 내몸을 이긴다. 주작이 앉으면 구설이 있다. 귀신이 앉으면 윗사람으로부터 원조의 재물을 얻는다. 일귀는 역행하여 3전이 주(朱)․귀(貴)․음(陰)이고, 야귀는 3전이 귀(貴)․음(陰)․상(常)이다.`
    ,
    // 12
    `  이 과는 제1과와 제4과에 음양의 극이 있고, 음일이니 음비의 극인 해술(亥戌)을 사용하여 초전으로 하는 지일비일과(知一比用課)이다.
    3전은 해자축(亥子丑)으로서 진여격(進茹)格)이며 정일(丁日)에서 해(亥)를 보면 절(絶)로 되어 묘(墓) 상에 있으니 무슨 일에서나 다시 하는 것은 좋지 않다. 일간의 재는 해의 역마가 귀(鬼)를 생하므로 외출하여 사업하는 것은 불길하다. 해자축(亥子丑)이 방합(方合)하여 중귀(衆鬼)로 되지만 일지상신의 술(戌)은 사용인이나 종업원이 능히 적을 격퇴시켜 흉내를 방지한다.
    이 과는 삼기연여격(三奇連茹格)으로서 귀신(貴神)이 초전이 있고 재(財)는 내 몸에 있어서 기쁨이 있으며, 관(官)이 왕성하니 사관․구직에서는 가장 길조이다. 일지의 음양 술해(戌亥)는 가정에 친근함이 없는데, 유(酉)는 가정이다. 술(戌)이 승하여 묘문(墓門)이라 이름하며, 점병은 가장 흉하다.
    백법에 존숭(尊崇)의 형상은 3전3기(三傳三奇)를 만난다｣고 했다. 나망격(羅網格)은 도모하면 졸책이 된다. 그러나 중귀를 제어하므로 두려울 것이 없다. 
    일귀는 역행하여 3전이 귀(貴)․후(后)․음(陰)이며, 야귀는 3전이 음(陰)․현(玄)․상(常)이다. 
    비전에 ｢태상․귀신이 관(官)과 합병하면 관직에 있는 사람은 지위가 향상한다｣고 하였다. 4과를 초전으로 하는 것은 맥월격(驀越格)으로서 모든 일이 지연된다. 그러나 사물이 우연히 성취되는 일이 있다고 한다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
