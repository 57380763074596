
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,2,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,9,1,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,12,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,11,11,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,12,10,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,1,9,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,2,8,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,7,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,6,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,5,5,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,4,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,3,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`9`,`외사단합`,`타인압박`],
    [`자식`,`자식 관련사`,`大`,`소외기`,`고위연회`,`음사폐호`],
    [`만당금옥(滿堂金玉)`,`집안에 돈과 보물이 가득하고 이름을 크게 덜치는 상`,`大`,``,`초대열석`,`가택안녕`],
    [`불변근신(不辨謹愼)`,`장차의 임무를 위해 말을 말고 근신해야 하는 상`,`小`,``,`희사`,``],
    [`후원`,`후원조력 관련사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`8`,`침체불통`,`반목송쟁`],
    [`쟁송`,`내사 송쟁파재사-가출사우려`,`小`,`침체기`,`불변근신`,`실업한가`],
    [`부취어인(附就於人)`,`여태 안 되던 관직(취직)이 성취되는 상 `,`小`,``,``,``],
    [`불변근신(不辨謹愼)`,`장차의 임무를 위해 말을 말고 근신해야 하는 상`,`大`,``,``,``],
    [`출행`,`외사 이심동요불안 출행이동사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`7`,`이심동요`,`매사단절`],
    [`매사 영속성없음`,`금전사업이권처첩 관련사는 선천적 기복재해를 겪음`,`小`,`단절기`,`출행이동`,`고위연회`],
    [`산아불육(産兒不育)`,`모든 타협사와 관구직은 이루어지나 胎産사는 불육되는 상`,`大`,``,`송흉문길`,`초대열석`],
    [`송흉문길(訟凶文吉)`,`구설과 소송사는 나쁘고 문서소식사는 길한 상`,`小`,``,``,`희사`],
    [`색정`,`내녀 색정시 有-이혼 우려`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`6`,`외사손실`,`색정사有`],
    [`매사 영속성없음`,`금전사업이권처첩 관련사는 선천적 기복재해를 겪음`,`小`,`존재기`,`도적소실`,`앙화소멸`],
    [`경방재흥(更防再興)`,`변경하여 방비하면 다시 흥해지는 상`,`大`,``,``,``],
    [`결속`,`결속사를 완성하여 財기반을 크게 세우나 직위남편 훼절됨`,`小`,``,``,``],
    [`후원`,`후원조력 관련사`,`小`,``,``,``],
    [`동요`,`내부가정 동요불안분산사`,`大`,`5`,`外후원사`,`동요불안`],
    [`부친`,`부친 관련사`,`小`,`배양기`,`사행불량`,`침체공허`],
    [`구재부득(求財不得)`,`병은 흉하고 구재사 역시 불가한 상`,`小`,``,``,`미초희경`],
    [`불검초죄(不檢招罪)`,`약속한 비밀을 누설하여 죄에 이르는 상`,`大`,``,``,`(권한受여)`],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`급속`,`외사 강제급속사`,`大`,`4`,`강제급속`,`면모훼손`],
    [`송쟁`,`외사 애증화합-급속송쟁파재사-실종상해 우려`,`小`,`개시기`,`송쟁파재`,`도적소실`],
    [`택역흠녕(宅亦欠寧)`,`역시 가정의 편안함에 흠이 있는 상`,`小`,``,`실업한가`,``],
    [`우유한가(優遊閑暇)`,`실업자 격으로 한가한 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`3`,`압박수난`,`가정단합`],
    [`단합`,`내부가정 단합사`,`小`,`학습기`,`충산분산`,`외화내허`],
    [`음소무녕(陰小無寧)`,`가정에 작은 불안이 발생하는 상-도난 관련`,`小`,``,``,`사행불량`],
    [`충산분산(冲散傷鱗)`,`서로 맞부딛쳐 비늘이 벗겨지듯하는 분산사`,`小`,``,``,``],
    [`결속`,`결속단합하여  재원의 기틀을 크게 세우나 직위남편이 훼절`,`小`,``,``,``],
    [`쟁송`,`내부가정 반목송쟁파재사-가출 이혼 우려`,`大`,`2`,`엄목지상`,`반목쟁송`],
    [`모친`,`모친 관련사`,`小`,`면모기`,`(눈이가려`,`실업한가`],
    [`이공사불(利公私不)`,`공적사는 이익이 되고 사적으로 하면 이익이 안 되는 상`,`小`,``,`진격)`,``],
    [`도적소실(盜賊消失)`,`도적에 대한 근심이 사라진 상`,`大`,``,``,``],
    [`명예`,`수상(受賞) 관련사`,`小`,``,``,``],
    [`직녹`,`직위녹위 관련사`,`小`,`1`,`외교막힘`,`타인시혜`],
    [`출행`,`내사 출행이동사`,`大`,`절정기`,`불의투서`,`출행이동`],
    [`호악무익(好惡無益)`,`좋고 나쁜 일이고 간에 이익이 없는 상`,`小`,``,`체택불용`,`투서헌책`],
    [`우유한가(優遊閑暇)`,`실업자 격으로 한가한 상`,`大`,``,`금전失財`,`고위면접`],
    [`자매`,`자매 출비 관련사`,`小`,``,``,``],
    [`색정`,`외남 색정사 有`,`小`,`12`,`색정사有`,`가정증오`],
    [`가출`,`가정 손해 증오 가출사`,`大`,`과시기`,`도적실세`,`가출사`],
    [`범모무익(凡謀無益)`,`모든 모사가 무익하게 되는 상`,`小`,``,`잡음`,`소식대기`],
    [`소식지체(消息遲滯)`,`와야 할 소식이 지체되어 기다리는 상-와도 나쁨`,`大`,``,``,`와도나쁨`],
    [`후원`,`후원조력 관련사`,`小`,``,``,``],
    [`내외 침체`,`외사 침체사- 내부가정 막힘사`,`大`,`11`,`침체공허`,`가정막힘`],
    [`부친`,`부친 관련사`,`小`,`쇠퇴기`,`음사폐호`,`衣冠행사`],
    [`기리전행(豈利前行)`,`형벌이 기다리고 이익도 없는데 어찌 나아가려는가-의 상`,`小`,``,`가택안녕`,``],
    [`도적우의(盜賊憂疑)`,`도적에 대한 근심과 의혹이 있는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`면모훼손`,`급속사有`],
    [`급속`,`가정 급속사`,`大`,`침해기`,`실업한가`,`도적失勢`],
    [`고귀난언(告貴難言)`,`고위인이 입을 닫아 소통이 어려운 상`,`大`,``,``,`잡음`],
    [`우유한가(優遊閑暇)`,`실업자 격으로 한가한 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,`후원조력문서모친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`후원조력부친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,``,`관사급속송쟁사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,``,`관사 직업 남편 사로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`후원송쟁모친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`명예직녹자산권리사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`자매색정조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`후원문서부친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,``,`직업직위불안남편갈등여식사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威 )세**만약 타인과 다툰다면 상대는 호가호위적 세일 뿐 공격의 뜻이 없음`,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란 듯한 심중이나 말을 삼가고 조용히 있으면 두려움이 자연히 사라지는 人`,0,0,`일이 거꾸로 진행됨**몇 사람의 방해자가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함 `,0,0,`명분이 있는 사안이므로 굳센 처신으로 정정당당하게 속결해야만 하고 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 人`,0,0,`가택명의가 양도됨**여건조건이 불비부족함**큰 뜻을 품고 때를 기다리며 반복사를 겪으나 아직 시기가 아님-단, 1950,62,74,86,98,2010년 출생자는 뜻을 이룸`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함 `,0,0,`명분이 있는 사안이므로 굳센 처신으로 정정당당하게 속결해야만 하고 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 人`,0,0,`신규사가 있음**가택이사 의사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체시키거나 후로 미루어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 시시비비를 가리려 말고 유순한 자세로 일을 미루거나 지체시키면 결과가 유리해지는 人`,0,0,`결속단합하여 재정의 바탕을 세우나 직위남편의 불안사가 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`부부남녀 동시 색정사 有**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화는 밖에서 침범하니 신중해야 함`,0,0,`매사 반복무상하고 있던 자는 떠나려하고 갔던 자는 오려고 하는 입장의 人`,0,0,`신규사가 있으나 영구성없고 불성함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복허나 화해의 뜻을 갖고 있음`,0,0,`동료와의 동업협동사로 중상모략을 받아 동요불안하고 선택의 기로에 서 있으며 가장 친하고 가까이 거주하는 자와 의논하면 중상모략이 풀리게 되는 人`,0,0,`저주사가 일어남**침체 소외된 입장임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인고 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 사안을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 결속하여 재정의 바탕을 세우나 직위명예가 추락되고 자칫 부부이별이 우려됨**양운경쟁의 조짐에 있으므로 적선적덕으로 인심을 확보해야 함**때를 기다리나 어두워 짐**전화위복 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 본인의 호가호위적 세의 공격이 허사가 됨`,0,0,`호가호위 세로 공격을 해도 상대는 피해를 입지 않아 되려 본인은 불리하고 상대가 유리하게 되는 人`,0,0,`신규사가 있음**처 재물의 손상이 우려됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체시키거나 후로 미루어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 시시비비를 가리려 말고 유순한 자세로 일을 미루거나 지체시키면 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄 진행되어퇴하려하나 계속하게 됨-퇴가 좋음**여건조건이 부족함**상대가 스스로 와서 조력함**가택을 매입하면 자산이 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 사안을 지체시키거나 후로 미루어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 시시비비를 가리려 말고 유순한 자세로 일을 미루거나 지체시키면 결과가 유리해지는 人`,0,0,`일이 순서적으로 진행되나 종반에 침체함으로 중반의 호조건으로 퇴가 좋음**전화위복사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 있으나 공격하지 않음`,0,0,`스스로를 자임하고 기존을 지키며 새로운 기회를 기다리는 人-고시 준비생에 많음`,0,0,`신규사가 있음**소송사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회시`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사타발동사겹침`,`미사`,`신구중간사`,`외사내사겹침`,`아이면타표면사겹침`,`충성-무력`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면-무력`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사후회사겹침`,`미사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`합의됨`,`합의됨`,`합의됨`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음6월`,2,3,5,`음12월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,3,2,5,`음11월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월-연기 또는 파혼우려`,2,1,3,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월-연기우려`,2,2,4,`음2월-연기 또는파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음3월`,2,3,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월-연기우려`,2,1,3,`음12월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음1월-연기우려`,2,1,3,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,3,2,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,2,1,3,`음3월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월`,3,2,5,`음4월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 업무는 수준이상을 유지함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 업무는 수준을 유지하고 1950,62,74,86,98,2010년 출생자이면 뜻을 크게 이룸 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이면 업무도 수준 이하로 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하고 결속답합사를 일으켜 결과적으로 전체 면모를 크게 상실하게 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받으나 업무는 수준이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 시종 동요불안분산사로 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성품이 훌륭하나 업무 전바이 침체함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있고 업무를 결속하여 재정의 바탕을 세우나 전체적 명예가 실추되고 전체적 지위를 하락시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 초반 중반이 허무한 후 종반에 겨우 표면적 실적을 이루내부적 부실이 있게 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반을 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하여 업무를 순서적으로 진행하여 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 초반의 실적 외 소송사로 이어짐`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`선이후난`,`進向`,`원`,`留`,`세입조력`,`정서`,``,`정남`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`권리양도`,`退背`,`원근왕래`,`  去`,`세입조력`,`북서`,``,`남남서`],
    [`10.11`,``,`측근제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선이후난`,`退背`,`원근왕래`,`  去`,`반목대립`,`북북서`,``,`남서`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`내부반대`,`이행`,`선난후이`,`進向`,`  원`,`  留`,`주인조력`,`정북`,`정서`,``],
    [`10.11`,` 多集`,`발형안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`끌면제압`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`주인조력`,`북북동`,`북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인무력`,`退背`,`원근왕래`,`  去`,`상호동요`,`남남서`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인제압`,`불이행`,`사안무력`,`退背`,`  근`,`  去`,`주인무력`,`남서`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`사안무력`,`退背`,`  원`,`  去`,`주인무력`,`동남`,`북북동`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선이후난`,`退背`,`  원`,`  去`,`상호반목`,`남남동`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`선費후攻`,`進向`,`  근`,`  留`,`세입가해`,`정남`,``,`남남서`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`선난후난`,`進向`,`  근`,`  留`,`세입가해`,`남남서`,``,`동남`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`불이행`,`선이후이`,`進向`,`  근`,`  留`,`무난`,`남서`,``,`남서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,``,`없음`],
    [``,`변사귀`,`객사`,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`도로귀`,`교툥사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`전흉사귀`,`혈광사망`,0,0,`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,`남남동`,``],
    [``,``,``,0,0,`묘향`,`북북서`,``],
    [`생업`,``,``,0,0,`전경`,`사찰제각`,``],
    [``,``,``,0,0,`묘속`,` 뱀굴`,``],
    [`가정`,`제례귀`,`제례위범`,0,0,`육친`,`5조부,형제`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"두성부정","기도위배",`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,"두성부정","기도위배",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,`북북서`,`없음`],
    [``,``,``,0,0,`묘향`,`남남동`,``],
    [`생업`,``,``,0,0,`전경`,`사찰제각`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,``,``,0,0,`육친`,`처,증조부`,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"두성부정","기도위배",`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사 급속사`,``,`의관 희사`,`소식 기대사-와도 불리함`,`내사 쟁송파재사`,`관직영전사`,`매사 허성불성-세속허사자**외사 출행이동사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음**내녀 색정사`,`종교진출하면 자립함`,`내사 동요분산사`,`위례득죄사`,`내사 급속사**외사 애증화합-쟁송파재실종-상해우려`,`쟁송사 흉-문서소식 길`,``,`불변근신사`,`내사 대립소송사`,`고위연회 초대열석 희사-출행사`,`명예직녹자산사**외교막힘**내사 출행이동사`,`실업자상-한가함`,`외남 색정사**남녀직원공모은닉사有`,`음사폐호-가택안녕`,`매사 위역사**내부가정막힘`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소**전화위복`,``,`재액해소`,`빈궁**병재`,``,`빈궁`,`고위안좌대중이익시행사-부족`,``,`부부불화사`,`빈궁`,`전화위복`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,``,`전도`,``,`전도**빈궁`,``,`침체경구사`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**내부가정막힘`,`도적실세-잡음`,`외사 급속사`,``,`의관 희사`,`소식기대사-와도 불리함`,`내사 송쟁파재사`,`관직영전사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면 자립함`,`매사 영속성없음**내녀 색정사`,`종교진출하면자립함`,`내사 동요분산사`,`위례득죄사`,`내사 급속사**외사 애증화합쟁송파재실종-상해우려`,`쟁송사 흉-문서소식사 길`,``,`불변근신사`,`내사 대립소송사`,`고위연회 초대열석 희사-출행사`,`명예직녹자산사**외교막힘**내사 출행이동사`,`실업자 상-한가함`,`외남 색정사`,`음사폐호-가택안녕`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`재액해소**전화위복`,``,`재액해소`,`빈궁**병재`,``,`빈궁`,`고위안좌대중이익시행사-부족`,``,`부부불화사`,`관재`,`전화위복`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,``,`전도`,``,`전도**빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`외남 색정사`,`음사폐호-가택안녕`,`매사 위역사**내부가정막힘`,`도적실세-잡음`,`외사 급속사`,``,`의관 희사`,`소식기대사-와도 불리함`,`내사 송쟁파재사`,`관직영전사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사`,`종교진출하면자립함`,`내사 동요분산사`,`위례득죄사`,`내사 급속사**외사 애증화합쟁송파재실종-상해우려`,`쟁송사 흉-문서소식사 길`,``,`불변근신사`,`내사 대립소송사`,`고위연회 초대열석 의사-출행사`,`명예직녹자산사**외교막힘**내사 출행이동사`,`실업자상-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`빈궁`,``,`재액해소**전화위복`,``,`재액해소`,`빈궁**병재`,`빈궁`,``,`고위안좌대중이익시행사-부족`,``,`부부불화사`,`빈궁`,`전화위복`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,``,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`충산상린사`,`명예직녹자산사**외교막힘**내사 출행이동사`,`관재형책사`,`외남 색정사`,`약속비밀누설자초득죄사`,`내부가정막힘`,`투서헌책불채택사-금전실재사`,`외사 급속사`,`눈이 가려진 격`,``,`내사 송쟁파재사`,`투서헌책-고위면접사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함-실업자 상-한가함`,`매사 영속성없음**내녀 색정사`,`종교진출하면자립함`,`도적소실사`,`매사 위역사**내사 동요분산사`,`제한권한수여희사`,`내사 급속사**외사 애증화합송쟁파재실종-상해우려`,`앙화소멸사`,`내사 대립소송사`,``,`사기득재사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁`,``,`재액해소`,``,`전화위복`,`빈궁`,``,`빈궁`,`재액해소`,``,`외사 동요분산사`,``,`사행불량사`,`빈궁`,`재액해소**전화위복`,`빈궁`,`음4,5월화재주의`,`전도**관재`,`음4,5월화재주의`,`전도**관재**병재`,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`사기득재사-이동사`,`내사 대립소송사`,`충산상린사`,`명예자산직녹사**외교막힘**내사 출행이동사`,`관재형챇사`,`외남 색정사`,`약속비밀누설 자초득죄사`,`내부가정막힘`,`투서헌책불채택사-금전실재사`,`외사 급속사`,`눈이 가려진 격`,``,`내사 송쟁파재사`,`투서헌책-고위면접사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함**실업자 상-한가함`,`매사 영속성없음**내녀 색정사`,`종교진출하면자립함`,`도적소실사`,`매사 위역사**내사 동요분산사`,`제한권한수여희사`,`내사 급속사**외사 애증화합송쟁파재실종-상해우려`,`매사 위역사**내사 대립소송사`,`앙화소멸사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,``,`빈궁**관재`,``,``,``,`전화위복`,`전도**빈궁`,``,`전도**빈궁**관재`,``,``,`외사 동요분산사`,`빈궁`,`사행불량사`,`빈궁`,`전화위복`,`빈궁`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`앙화소멸사`,``,`사기득재사-이동사`,`내사 대립소송사`,`충산상린사`,`명예자산직녹사**외교막힘**내사 출행이동사`,`관재형책사`,`외남 색정사`,`약속비밀누설 지초득죄사`,`내부가정막힘`,`투서헌책불채택사-금전실재사`,`외사 급속사`,`눈이 가려진 격`,``,`내사 송쟁파재사`,`투서헌책-고위면접사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함**실업저 상-한가함`,`매사 영속성없음**내녀 색정사**사행불량사`,`종교진출하면자립함`,`도적소실사`,`매사 위역사**내사 동요분산사`,`제한권한수여희사`,`내사 급속사**외사 애증화합파재실종-상해우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁**관재**병재`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사**재액해소`,`관재`,`동요불안분산사**재액해소`,`빈궁**관재`,`동요불안분산사`,`전도`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`전도`,`외사 동요불안분산사`,``,`동요불안분산사`,`빈궁`,`동요불안분산사**전화위복`,`전도**빈궁`,`음4,5월화재주의**동요분산사`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`제한권한수여희사`,`내사 급속사**외사 애증화합송쟁파재실종-상해우려`,`앙화소멸사`,``,`사기득재사-이동사`,`내사 대립소송사`,`충산상린사`,`명예자산직녹사**외교막힘**내사 출행이동사`,`관재형책사`,`외남 색정사`,`약속비밀누설 자초득죄사`,`내부가정막힘`,`투서헌책불채택사-금전실재사`,`외사 급속사`,`눈이 가려진 격`,``,`내사 송쟁파재사`,`투서헌책-고위면접사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함**실업저 상-한가함`,`매사 영속성없음**내녀 색정사`,`종교진출하면자립함`,`도적소실사`,`매사 위역사**내사 동요분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**재액해소`,`관재`,`음4,5월화재주의**재액해소`,`빈궁**관재**병재`,``,`빈궁`,``,`관재`,``,`빈궁`,``,``,`전화위복`,`빈궁**관재`,``,`빈궁`,``,``,`외사 동요분산사`,`전도`,`사행불량사`,`전도**빈궁`,`전화위복`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`도적소실사`,`매사 위역사**내사 동요분산사`,`제한권한수여희사`,`내사 급속사**외사 애증화합송쟁파재실종-상해우려`,`앙화소멸사`,``,`사기득재사-이동사`,`내사 대립소송사`,`충산상린사`,`명예직녹자산사**외교막힘**내사 출행이동사`,`관재형책사`,`외남 색정사`,`약속비밀누설 자초득죄사`,`내부가정막힘`,`투서헌책불채택사-금전실재사`,`외사 급속사`,`눈이 가려진 격`,``,`내사 송쟁파재사`,`투서헌책-고위면접사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면 자립함**실업저 상-한가함`,`매사 영속성없음**내녀 색정사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**관재`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**관재**병재`,``,`빈궁`,``,``,`빈궁`,``,``,``,`전화위복`,`전도**빈궁`,``,`전도**빈궁`,``,``,`외사 동요분산사`,``,`사행불량사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`매사 영속성없음**내녀 색정사`,`종교진출하면자립함`,`도적소실사`,`매사 위역사**내사 동요분산사`,`제한권한수여희사`,`내사 급속사**외사 애증화합파재실종-상해우려`,`앙화소멸사`,``,`사기득재사-이동사`,`내사 대립소송사`,`충산상린사`,`명예직녹자산사**외교막힘**내사 출행이동사`,`관재형책사`,`외남 색정사`,`약속비밀누설 자초득죄사`,`내부가정막힘`,`투서헌책불채택사-금전실재사`,`외사 급속사`,`눈이 가려진 격`,``,`내사 송쟁파재사`,`투서헌책-고위면접사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함**실업저 상-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`사행불량사`,`빈궁`,`전화위복`,`전도**빈궁`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**관재**병재`,``,`전도**빈궁`,`재액해소`,``,`재액해소`,`빈궁`,``,`전도`,`전화위복`,`빈궁`,``,`빈궁`,``,`빈궁**병재`,`외사 동요분산사`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사`,`종교진출하면자립함`,`내사 동요분산사`,`위례득죄사`,`내사 급속사**외사 애증화합송쟁파재실종-상해우려`,`쟁송사 흉-문서소식사 길`,``,`불변근신사`,`고위연회 초대열석 희사-출행사`,`내사 대립소송사**불변근신사`,`실업자 상-한가함`,`명예직녹자산사**외교막힘**내사 출행이동사`,`음사폐호-가택안녕`,`외남 색정사`,`매사 위역사**내부가정막힘`,`도적실세-잡음`,`의관희사`,`외사 급속사`,``,`소식기대사-와도 불리함`,`내사 송쟁파재사`,`관직영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`고위안좌대중이익시행사-부족`,``,`부부불화사`,`빈궁`,`재액해소**전화위복`,``,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**빈궁**관재`,`재액해소`,``,``,``,`빈궁`,``,`재액해소`,`빈궁`,`전화위복`,``,`빈궁**병재`,``,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`내사 송쟁파재사`,`관직영전사`,`매사 영속성없음**외사 출행이동사`,`종교진출하면자립함`,`매사 허성불성-세속허사자**내녀 색정사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`내사 동요분산사`,`위례득죄사`,`내사 급속사**외사 애증화합송쟁파재실종-상해우려`,`쟁송사 흉-문서소식사 길`,``,`불변근신사`,`고위연회 초대열석희사-출행사`,`내사 대립소송사**불변근신사`,`실업저 상-한가함`,`명예직녹자산사**외교막힘**내사 출행이동사`,`음사폐호-가택안녕`,`외남 색정사`,`매사 위역사**내부가정막힘`,`도적실세-잡음`,`의관 희사`,`외사 급속사`,``,`소식기대사-와도 불리함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**관재`,`고위안좌대중이익시행사-부족`,``,`부부불화사`,`빈궁`,`재액해소**전화위복`,``,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**빈궁**관재`,`재액해소`,``,``,``,`빈궁`,``,`재액해소`,`빈궁`,`폐한인**전화위복`,``,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`소식기대사-와도 불리함`,`내사 송쟁파재사`,`관직영전사`,`매사 허성불성-세속허사자**외사출행이동사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자**내녀 색정사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`내사 동요분산사`,`위례득죄사`,`내사급속사*외사애증화합송쟁파재실종-상해우려`,`쟁송사 흉-문서소식사 길`,``,`불변근신사`,`고위연회 초대열석의사-출행사`,`내사 대립소송사**불변근신사`,`실업저 상-한가함`,`명예직녹자산사**외교막힘**내사 출행이동사`,`음사폐호-가택안녕`,`외남 색정사`,`매사 위역사**내부가정막힘`,`도적실세-잡음`,`의관 희사`,`외사급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,`빈궁`,``,`고위안좌대중이익시행사-부족`,`전도`,`부부불화사`,`전도**빈궁`,`전화위복`,``,`음4,5월화재주의**재액해소`,`빈궁**관재`,`음4,5월화재주의**재액해소`,`빈궁**관재`,``,``,``,``,`빈궁`,``,`빈궁`,``,`전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 배은망덕의 관재가 벌어     지는데, 이권을 상실하겠어!
  男:처첩의 재해를 걱정해야     되겠어 ! 가택에 침입한 
  ⊕⊖가택두성불안 부정과 
  ⊕전사귀 
  ⊖변사귀가 문제야!
  
  ⊕신변상가부정도 침범했네?
  `,`■금전․자손․병재․관재로    많이 놀라고 있네? 
    한 건도 아니고 두세건이야!관직자:권리를 연이어 얻겠네!
  ⊕전사귀, 
  ⊖변사귀를 해소시켜야 좋아    지겠어!
  `,`■ 삼각관계가 있네? 
     이권을 잃고 자충수로 관재     고심이 있겠고 옛 인연으로     다시 돌아가게 생겼어!
  ⊕가택상가부정ㆍ가택신, 
  ⊖도로귀를 없애야 관재 
    방지가 될거야!
  
  ⊖신변음사부정이 침범했네?
  `,`■ 변동수가 있네? 
     그런데 자충수로 관재 다툼     도 있겠어!
    이권을 상실 하겠는데 !
  
  
  ⊕전사귀, 
  ⊖변사귀를 해소시켜야 원하는    것들이 다 이루어지겠어!
  `,`■ 다인 다사건으로 
  男:직위가 손상 되겠고, 
  女:부부이별을 하게 생겼네!
  
  ⊕상가부정ㆍ전흉사귀, 
  ⊖여귀 해소가 급하겠어?
  
  ⊕⊖신변에 두성불안부정이      침범 했네 ?
  `,`■ 부부가 서로 바람났네?
     가정 단속을 해라마라 할     수가없어!금전․여자로         의심 질투도 있네 !
     이권상실사도 있겠어 !
  ⊕신불제사귀, 
  ⊖음귀 발동때문인데… 
    자칫 관재가 벌어지겠어!
  `,`■ 색정불화로 부부 마음이     각기달라서일안되겠어!
     일이 양갈래로 벌어져      있겠고… 색정불화야!
  
  ⊕⊖가택에 두성불안 부정
      까지 들었어!
  
  ⊖신변상가부정이 침범했네?
  `,`■ 동료가 문제네? 
     이럴까 저럴까 기로에 섰어! 
     두 가지는 안되니 하나로만     선택해야 하겠어!
  ⊕전흉사귀, 
  ⊖여귀를 해소시키고 입조심    하면 잘 될거야!
  `,`■ 지푸라기라도 잡아야 할     운세네? 
  男：직위가 꺾이겠어!
  女：부부이별이 있게 생겼어! 
  
  ⊖가택상가부정부터 없애야    일이 풀려!
  
  ⊕신변음사부정이 침범했네?
  `,`■ 배은망덕한 소송붙었네?
  男：이권을 상실하게 생겼네?
  女：남자가 떠나게 생겼네?
  
  ⊕신불제사귀, 
  ⊖전흉사귀를 잡아야만 기우는    가세를 되돌려 세우지!
  `,`■ 여건과 상황이 불비 부족     하겠어 ! 
  
  ⊖가택상가부정을 해소시켜야     계획ㆍ소망사가 자연히 
    이루어지겠어!
  
  ⊕⊖신변에두성불안부정이        침범 했네 ?
  `,`■ 욕심만 크게 내지 않으면     모든 일이 잘 되게 생겼네!
  
     돌아가세요!`];
  const YOGYEOL1 = [`■ 낮정단은 범이 내 녹을 물어간다. 귀인에게 의지하기 어렵다. 움직이면 귀신 집에 떨어지고 아무리 재주를 부려도 졸작일 뿐이다.
  `,`■ 삼전의 관귀가 뭉쳐 나를 극하니 일반인은 심히 두렵고 관직자는 기쁘겠다.
  `,`■ 말전이 초전의 관살을 도우니 괜히 고통스럽고 신경쓰인다. 上人이 下人을 예의로 대하면 어려움을 피하고 살길을 만나겠다.
  `,`■ 깎았다가 높였다가 하니 모두 타인의 장난이다. 낮정단은 장생이 있어 좋으나 밤정단은 관살이 되어 해롭겠다.
  `,`■ 낮정단은 관귀가 살이 되었으나 다행히 잘 막아 기특하다.  
  병이나 고통의 재발을 막고 나쁜 것들을 끊어야 마음 놓게 된다.
  `,`■ 동업이나 교섭은 재앙이 생긴다. 도와줄 사람은 돕지 못한다. 
  썩은 나무로 바퀴를 만드는 격이다. 
  낮정단은 나쁜 일이 많겠다.
  `,`■ 밤정단은 범을 7마리나 만났으나 재앙은 벗어나겠다. 내게 공망과 절신이 탔으니 내 몸이 허공에 뜬 격이다.
  `,`■ 내 병이나 형제의 병은 반드시 죽을 두려움이 있다.
  숨은 귀신이 범을 타고오니 관속에 들어가는 일이 있으리라.
  `,`■ 자식으로 손해가 많겠다. 성격이 게을러 그렇다. 안되는 것을 버리고 살곳을 찾아가라. 담보 잡히고돈 얻는것은 되겠다.
  `,`■ 먼저는 손해나고 나중에 얻게 된다. 비용과 노력을 많이 들여야 한다. 두 귀인이 항상 노하고 있으니 길흉선악이 종적 없겠다.
  `,`■ 상대가 와서 나를 도우니 가만히 지키면 형통하겠다.
  두려운 것은 도적이니 어디가지 말고 지키고 있으라.
  `,`■ 내가 손해날 운세이니 모든 일에 허비만 나겠다. 병이나 소송은 위태롭다. 자식, 부하, 종업원이 속썩이겠다.`]
  const SIBO1 = [`■ 내 녹을 지키기가 어렵다.
  득재도 어렵고 이권을
  상실하게 된다.
  어떤 일도 앞장서면 불리하다. 중도에 좌절ㆍ중지ㆍ장애가 발생한다. 금전 여인의 凶을 기한다. 방향ㆍ방침을 바꿔야만 길하다.
  `,`■ 가정에 ⊕주식사, ⊖음사로
  손모ㆍ재앙이 우려되므로
  예방해야 한다.
  2ㆍ3인의 가해나 방해를 받게 된다.
  신중하면 공명사ㆍ교섭사는 吉하다.
  후원사에는 독이 숨어 있다.
  `,`■ 타인으로부터 사주를
  받는다. 
  자충수적 우환이 된다.
  내가 상대에게 가서 권리를 상실한다. 가택의 권리도 넘어간다. 모든 일을 공공적으로 해나가야 한다.
  ⊕용두사미가 되고
  ⊖뱀이 용으로 변하게 된다.
  `,`■ 자충수적 우환이 있다.
  후원사에는 숨겨진
  독이 들었다.
  이권상실이 있게 된다.
  ⊕관사다툼, ⊖공직자는 탄핵 받는다.
  구직사는 좋고 교섭사도 좋다. 
  巳띠 巳행년자는 목적이 달성된다.
  `,`■ ⊕가정에 병자가 있고
  ⊖여인의 구해를 받는다.
  자충수적 우환이 있다.
  내 일이 타인의 방해를 받는다. 또는 재물이 암손된다. 타인의 감언이설에 속는 일이 발생한다. 관계정립을 잘해야 좋다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을 품고
  가해하려 한다.
  남자는 별리ㆍ고독인이다.
  사물이 두 갈래로 번졌다.
  부부가 서로 사통을 벌여 기로에 섰다.
  소중도의 작용으로 단교의 사건이 있게 된다.
  四月 임신사가 있다.
  `,`■ 가정에 부정이 들어 
  득실이 일정하지 않고
  반복부정하며 성패가 없다.
  부부이심의 분쟁이 있고 이권을 상실한다.
  여자는 별리ㆍ고독인이다. 
  사건이 두갈래로 번졌다.
  일을 고치고 변경시켜야 좋다.
  금전 여인의 凶이 기한다.
  `,`■ 저주사가 있다. 
  남자는 별리ㆍ고독인이다.
  동료에 의해 불안하여 기로에
  섰다. 두 가지 중 하나로 전일시켜야 한다. 근ㆍ친자로 결정하면 이롭게 된다. 
  관공직사는 길하고, 단 귀인의 기색ㆍ언어를 살펴 원조를 부탁할 일이 있게 된다.
  四月 임신사가 있다.
  `,`■ ⊕여인의 이권상실 있다.
  ⊖병자가 있다.
  모든 일이 어렵고 지체
  되고 곡절 끝에 일이 이루어진다.
  계획적인 모해를 받게 되는 것은 예방해야 한다. 공적으로 행동함이 좋고 적덕을 쌓아야 한다.
  `,`■ 이권상실이 있다.
  나의 주장이나 공격이 
  적중되지 않는다.
  모략을 써도 실적이 없게 된다. 세력과 능력의 부족이다.
  上이 유리하고 下가 불리하므로 귀인이 성을 낸다. 신불기원이 좋다.
  `,`■ 가정에 부정이 들어
  ⊖병자가 있다.
  금전 여인의 凶이 기한다.
  분수를 지키면 자연히 형통하게 된다.
  단, 여건이 부족한 상태이다.
  `,`■ 신불기도에 의해 만사가
  길조로 변한다.
  피차 의시하는 일이나 음사사도 해소된다.
  단, 기쁨중에 소모건이 있게 된다.
  
  ⊖병의 재발방지가 필요하다.`]
  const SIBO2_1 = [`■직녹, 신변외사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.은둔ㆍ도망ㆍ원행에 이롭다.
  `,`■관ㆍ부,흉해여부사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■관ㆍ부 ,물손해사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■관ㆍ부,반목대립사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■자손ㆍ박관가정상쟁사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.男직위상실하고女부부이별한다. 
  `,`■사업ㆍ후원, 가택내사이다.
  내방조짐 상의 :  
  금전 여인의 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女,신변이별사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 재액이 있다.
  `,`■직녹, 신변외사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.은둔ㆍ도망ㆍ원행에 이롭다.
  `,`■후원가택동요불안사이다.
  내방조짐 상의 : 
  해산건이있다.개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.男직위상실女부부이별한다.
  `,`■財･女,이심동요사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 재액이 있다.
  `,`■자손ㆍ박관신변외사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.
  이권을 상실한다.
  `,`■자손ㆍ박관가정사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/047/row-1-column-1.png`,
    `/static/images/sagwa/047/row-1-column-2.png`,
    `/static/images/sagwa/047/row-1-column-3.png`,
    `/static/images/sagwa/047/row-1-column-4.png`,
    `/static/images/sagwa/047/row-1-column-5.png`,
    `/static/images/sagwa/047/row-1-column-6.png`,
    `/static/images/sagwa/047/row-1-column-7.png`,
    `/static/images/sagwa/047/row-1-column-8.png`,
    `/static/images/sagwa/047/row-1-column-9.png`,
    `/static/images/sagwa/047/row-1-column-10.png`,
    `/static/images/sagwa/047/row-1-column-11.png`,
    `/static/images/sagwa/047/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `   이 과에서는 4과 중에 극이 없고 12신이 본궁에 엎드렸으므로 복음과이다. 양일에는 일간상신인 신(申)을 초전으로 하여 원태격(元胎格)으로 된다. 일간상신은 건록이지만 백호가 승하면 그 녹을 지키기 어렵다. 중전 인(寅)은 재동이 공망하니 득재하지 못한다. 귀신이 미(未)에 승하면 승당(昇堂)이라 하여 태산같이 편안하다. 
    양일복음(陽日伏吟)은 자임격(自任格)이라 하여 스스로 굳세다고 자처하므로 무슨 일에나 앞에 나서면 불길한 결과를 초래한다. 고요한 가운데 움직임이 있으며, 말전의 관성(官星)은 일간의 장생으로 공망은 단교(斷橋)공망이라 하여 무슨 일에서나 중도에 좌절되거나 중지되며, 장해가 생기게 된다. 방향이나 방침을 바꾸면 효과가 있다.
    백법에 ｢지형(支刑)이 3전에 있는 것은 피차가 투합하지 못한다｣고 하였다. 비전에는 ｢역마가 초전이고 중전이 공망하면 병점(病占)에서 가장 꺼린다｣고 하였다. 이것은 신령이 떠나가는 형상을 이루기 때문이다. 
    일귀는 순행하며 3전은 백․사․구이고, 야귀는 역행하여 3전이 후․청․주이다. 일귀의 천장은 흉신이며, 야귀는 길장이 승하여 길조를 이루므로 흉을 보아도 경하다.`
    ,
    // 2
    `   이 과는 4과에 상하의 극이 없고, 제2과의 천신에서 일간을 사극(斜剋)하니 오미(午未)를 초전으로 하여 요극호시과(遙剋蒿矢課)이며 삼전퇴여격(三傳退茹格)이다. 초․중전은 일간을 극하고 또 일간상신 미(未)는 정신(丁神)이 되어 극이 중하다. 따라서 두 세사람으로부터 적해를 받으며 흉한 일은 연이어 발생한다. 병점(病占)은 가장 흉하고, 사관․임관․천관은 모두 길조를 얻는다. 호시과로서 금철의 탄환이 없고 오히려 지합 소통하니 시종 상호화합하며, 비록 걱정스러운 일이 있어도 흉은 흩어진다. 일지는 상신 유(酉)에 탈하고 일간의 양인(羊刃)에 해당하니 음사․불평 등의 재액이 있다.
    백법에 ｢금일이 정신(丁神)을 만나면 흉화의 일로 움직이므로 신중해야 하며, 초전의 오(午)는 미(未)에 전하지 않고 역전(逆轉)하여 사(巳)에 들고 진(辰)으로 전하는 것은 등용격(登庸格)이라 부른다. 무슨 일에서나 머리를 써서 향상하려는 뜻이 있다｣고 하였다. 특히 간(干)과 지(支)는 서로 왕하여 피차간에 서로 화합한다. 공명 및 교섭사는 모두 길하다.
    일귀는 순행하며 3전은 청(靑)․구(勾)․합(合)이고, 야귀는 역행하여 3전이 사(蛇)․주(朱)․합(合)이다, 초전 오(午)와 일지상신 오미(午未)의 합이 있다. 말전 진(辰)과 일지상신 유(酉)가 지합하는 것은 시종 화합하고 성취된다.`
    ,
    // 3
    `   이 과는 1상이 1하를 극하여 초전으로 원수과이고, 4과이지만 3과뿐이다. 양일이니 양신을 머무르게 하고 제4과의 음신을 제거하니 2양1음의 불비격이 된다. 
    일간상신의 오(午)는 관귀로 된다. 초전에서 일간을 극하면 남의 사주를 받는다. 말전의 인(寅)은 귀(鬼)를 돕지만 공망하여 서로 돕는 힘이 없다. 원수과는 존장이 아래를 제어하므로 대순(大順)하는 형상이다. 상에서 극하는 것은 길흉이 밖으로부터 오고 또 남자에게 일어난다. 일간의 기궁(寄宮) 신(申)은 일지상신에 와서 일지에서 생하니 유정하고 또 유리하다. 불비격은 사물이 결여되어 전혀 돌아가지 않는다. 과전이 양지뿐이면 공(公)에 이롭고 음사한 일은 불리하다.
    백법에 ｢일지상신 건록은 권섭부정(權攝不正)이다｣고 하였다. 과전이 6양(六陽)이면 낡은 것을 버려야 한다. 비전에는 ｢말전은 초전을 도우나 공망하다｣고 하였다. 따라서 남이 동주(使嗾)하더라도 스스로 발각된다. 3전에 오(午)와 인(寅)이 있고 술(戌)의 한신을 기다리고 있으므로 술월(戌月) 또는 술일(戌日)에 응과가 있다. 청룡이 오(午)에 승하여 간상에 임하는 것은 오자미(午子未)의 달에 정단하면 애인이 임신한다. 또는 가정에 임신부가 있다. 3전이 오진인(午辰寅)이면 고조격(顧祖格)이다. 말전이 공망이면 처음에는 분명하다 해도 종국에 가서는 암매하게 된다. 관직을 관점하면 오래 사관하기 어렵다.
    일귀는 순행하며 3전은 청(靑)․합(合)․사(蛇)이고 용두사미로 된다. 야귀는 역행하여 3전이 사(蛇)․합(合)․청(靑)으로 되어 사(巳)가 화하여 용으로 된다. 행년 또는 년명이 인(寅)이면 전실공망(塡實空亡)하여 해공(解空)으로 되니 원대한 뜻은 목적을 달성한다.`
    ,
    // 4
    `   이 과는 제1과의 1상이 1하를 극하여 초전으로 되니 원수과(元首課)로 된다. 3전4맹신(三傳四孟神)은 원태격으로서 개선하는 일이 아니라면 자녀의 사건이다.
    제1과의 사(巳)는 경(庚)을 극하여 초전으로 되며, 귀효이지만 말전의 해(亥)가 귀를 제어하므로 구신(救神)의 작용을 한다. 체생되지만 공망하므로 유정이 아닌 원조이다. 원태공망은 산아(産兒)를 정단하면 기르기 어렵다. 중․말전이 공망이면 초전으로 정단한다. 사(巳)는 경(庚)의 관성장생(官星長生)에 해당하여 일간상신에 임한다. 구직․관직은 대단히 길하다. 일간일지가 교차상생으로 되니 교섭․타협사는 이루어진다.
    중전 인(寅)은 재동 공망하여 인(寅)의 년월 또는 년명상신이나 행년에 해당하면 해공으로 되어 목적이 달성된다.
    백법에 ｢3전 체생은 남의 천거가 있고, 중․말전의 공망은 전(傳)으로 돌지 않으므로 초전의 한 신(神)에만 의거하여 길흉을 단정한다｣고 하였다. 
    비전에는 일귀(日鬼)가 주작에 앉아서 일간에 더해지면 공직에 있는 사람은 탄핵을 받고, 보통 사람은 문장으로 인해 질책을 받으며, 간지(干支)가 서로 상생되면 피차가 서로 화합하고 성취한다｣고 하였다.
    일귀는 순행하며 3전은 구(勾)․사(蛇)․음(陰)이고, 야귀는 역행하여 3전이 주(朱)․청(靑)․상(常)이다.`
    ,
    // 5
    `   이 과는 4과 중의 제2과의 자진(子辰)에서 1하가 1상을 극하여 자진(子辰)을 초전으로 하여 중심과로 된다. 3전 자신진(子申辰)의 3합수국은 윤하격(潤下格)이다. 제3과 오화(午火)가 일간을 극하여 상대방이 나를 극해 하는데, 제2과 자수(子水)에서 이를 제어하므로 해하지 못한다. 
    경금(庚金)에서 3전의 수국은 금생수(金生水)로 자손효로 된다. 윤하는 수(水)의 유동으로 되어 일간일지상신에서 각각 생을 받는다. 인택은 모두 남의 원조를 받는다. 일지 오(午)는 관성에 백호가 앉아 최관(催官)이라 이름하며 임관․구직이 속하다. 그러나 수국은 이것을 제어하니 중망(衆望)을 얻지 못한다.
    백법에 ｢백호가 귀살하면 재환(災患)을 본다면 재해가 적지 않다.
    일간일지가 상하로 각각 함께 생하면 만사에서 유익하다｣고 하였다. 3전체생과(三傳遞生課)는 남의 천거를 받는다. 과전이 모두 양지이면 6양(六陽)의 수가 족하여 공용(公用)은 가장 길하다. 합하는 가운데 지파(支破)가 있고 나의 일은 화합하는 가운데 타인으로 인하여 저지된다. 또는 몰래 재물이 소모되거나 타인의 감언에 속기 쉽다.
    일귀는 역행하며 3전은 사(蛇)․청(靑)․현(玄)이고, 야귀는 순행하여 3전이 청(靑)․사(蛇)․현(玄)이다. 비전에 ｢진(辰)의 묘신(墓神)이 일(日)에 엎드려 심신(心身)에 모두 정체가 있고 다행히 일지 술(戌)에서 충하여 개묘로 되니 후에 통한다｣고 하였다.`
    ,
    // 6
    `  이 과는 지일비용과(知一比用課)이다. 원칙에 이르기를 ｢이 과는 제2과, 제3과에 상하에 음양의 극이 있다｣고 하였다. 양일은 양의 극을 사용하여 술묘(戌卯)를 초전으로 하고, 중․말전은 조상법(照常法)을 적용한다. 지일은 제2과․제3과의 극으로써 2효(二爻)가 모두 움직이므로 사물에 두 갈래의 사건이 있다. 선악이 혼합되는 비화일선(比和一線)을 골라서 이것을 사용한다. 그러므로 일사 일건을 채용한다. 점단하는 일은 모두 가까이에 있으며 현자(賢者)에게 위임하는 것이 좋다.
    화복은 안의 일로 인해 일어난다. 일간상신이 묘(卯)를 극하여 재동이 되며, 일지상신은 일간을 극하여 관효(官爻)로 되나. 일간일지는 묘술사신(卯戌巳申)이 상극이라 해도 교차지합한다. 그러나 묘(卯)는 공망으로 되고 목기(木氣) 공망이면 썩은 나무로 된다. 재물․교섭․타협이 모두 절반 밖에 이루어지지 않는다. 일간상신은 일지를 극하고 일지상신은 일간을 극하면 무음격(蕪淫格)으로, 부부의 정단은 서로 사통하며 배반하는 일이 있다. 가정은 바르지 못하여 화합되지 못한다. 교차합 중에서 이 격을 이루는 것은 은혜 가운데 해를 생한다.
    백법에 ｢썩은 나무로는 조각할 수 없다｣고 하였다. 책략을 바꾸는 것이 좋다. 태음이 승하면 협극(夾剋)으로 되어 만사에 자기의 자유를 얻지 못한다. 비전에는 ｢묘(卯)에 경(庚)이 더해지고 해(亥)와 지합하며 일지상신이 신(申)과 지합한다｣고 하였다. 즉 웃음 속에 칼을 품고 원한을 감추어 서로 어울리나 후에 단교(斷交)하게 된다. 술(戌)에 육합이 앉으면 사용인이 도망하는 일이 일어난다. 말전에 자사(子巳)가 겹치면 사(死)자로 되어 병자가 사망될 우려가 있다. 초전이 공망이면 무슨 일이나 어렵다.
    일귀는 역행하며 3전은 합(合)․상(常)․사(蛇)이고, 야귀는 순행하여 3전이 합(合)․음(陰)․청(靑)이다. 청신(靑神)이 3전에 들면 길로 화한다. 4과가 3전을 떠나지 않으면 회환격(廻環格)이다.`
    ,
    // 7
    `  이 과는 12궁의 천지반이 대충(對沖)하므로 반음과(返吟課)라고 부른다. 연식원칙(演式原則)에 이르기를 ｢반음과는 제신(諸神)이  그 위(位)에 반(反)화여 왕래극적(往來剋賊)하니 신음(呻吟)이 반복(返復)되므로 이렇게 부른다｣고 하였다. 오는 자는 가려고 생각하고 떠나는 자는 돌아오려고 생각한다. 득실이 일정하지 않으며, 물건을 얻을 때는 먼저 잃고 사물이 패할 때는 후에 이루어진다. 3전이 인신인(寅申寅)이면 원행 이동이나, 분쟁 사건의 정단이다. 천장의 길흉에 의하여 길흉의 경중을 단정해야 한다. 만일 반음과(返吟課)로써 극이 없을 때는 일지의 역마를 초전으로 하고, 중전은 일지상신, 말전은 일간상신으로써 한다. 기축일(己丑日)․정축일(丁丑日)․신축일(辛丑日)․신미일(辛未日) 등의 4일은 극이 없다. 정란사격(井欄射格)이라 하여 일신(一身兩用)의 체(體)이다.  이 과 중의 인(寅)은 호(虎)이고 신(申)은 백호로서, 과전 중에 5호(虎)가 거래한다. 그러나 공망하니 흉은 경하다. 모든 것이 반복되어 성패가 있다.
    백법에 ｢거래(去來)가 공망하면 동의가 있으나 움직일 수 없다｣고 하였다. 일야천을귀신(日夜天乙貴神)이 가해지니 두 귀인에 의존한다.
    비전에는 ｢반음과는 의뢰할 곳이 없다고 하는데, 몰래 집을 떠나서 도망한 자는 먼 곳에서 찾아야 하며, 만나는 자는 분산된다｣고 하였다. 일은 고쳐 하면 안전할 수 있다. 반음과에서는 사물이 두 갈래도 된다. 인(寅)은 일간의 재동이지만 공망하므로 구할 수가 없다.
    일귀는 역행하며 3전은 후(后)․청(靑)․후(后)이고, 야귀는 순행하여 3전이 백(白)․사(蛇)․백(白)이다. 과전 중에 7호(虎)가 왕래하니 흉사를 정단하면 오히려 두려울 것이 없다.`
    ,
    // 8
    `   이 과는 4과에서 음과 양의 2상이 2하를 극한다. 양이니 양비라 부르며 양의 극인 신묘(申卯)를 사용하여 초전으로 하는 지일과(知一課)이다.
    이 과는 일명 비린(比隣)이라 부르는데, 일은 동류에서 일어나고 화는 밖으로부터 온다. 모든 일이 근린에 있고 호의(狐疑)하여 결정하기 어렵다. 그러나 천장과 천신이 비(比)일 때는 가까우나, 비(比)가 아니면 멀다는 것을 알아야 한다. 길흉우희(吉凶憂喜)는 천장의 길흉에 의하여 알 수 있다. 대체로 먼 것을 버리고 가까운 것에 붙으며, 소원을 버리고 친근함에 붙어야 한다. 은혜 가운데 해로움에 생기는 형상이다.
    일간상신에 순미(旬尾)의 축(丑)이 앉아 폐구격(閉口格)으로 되니 모든 것을 내부에서 찾아야 한다. 일간에 귀신이 승하여 신(身)을 생하고 3전체생(三傳遞生)에 덕마(德馬)가 초전으로 되니 명리․공명․구직은 모두 길하다. 축(丑)의 귀신은 순미에 해당하고 폐구격이므로 귀인의 언어를 살피고 색을 보아가면서 원조를 청해야 한다.
    백법에 ｢백호가 귀살에 승하는 것은 재해를 만난다면 해가 적지 않고, 3전 체생은 남의 천거를 받는다｣고 하였다. 화개(華蓋)가 일(日)에 엎드려 사람은 혼미하다.
    비전에는 ｢일간상에 축(丑)이 임하면 저주하는 일이 일어난다｣고 하였다. 또는 묘신(墓神)이 일(日)에 더해져서 신(身)에 재해와 조체가 생긴다. 초전이 공망이므로 고신(孤神)으로 된다. 녹마가 공망하니 모든 일에서 이롭지 못하다. 일귀는 역행하며 3전은 청(靑)․귀(鬼)․백(白)이고,야귀는 순행하여 3전이 사(蛇)․공(空)․후(后)이다. 말전의 명암2귀(明暗二鬼)는 질병․소송사를 간점하면 대단히 흉하다.`
    ,
    // 9
    `   이 과는 제2과․제3과에서 상이 극하니 비용과를 취할 수 없으므로 섭해과(涉害課)로 된다. 연식(演式)의 원칙(原則)에는 ｢2상이 하를 극하고 혹은 2하가 상을 극하니 모두가 동일하여 비용과(比用課)를 취할 수 없을 때는, 섭해과로 되는 지반에 건너가 본궁에 들어가서 극이 깊은 것을 취하여 초전으로 하는 원칙이다｣라고 하였으나, 이것은 구법에 속한다. 또 한 법은 첫째를 지반의 4맹신(四孟神)을 취하고 4맹신이 없을 때는 4중신(四仲神)의 극을 사용하여 초전으로 한다. 이 과는 4맹신이 없으므로 4중신 자(子)의 극인 진자(辰子)를 초전으로 하였다.
    섭해과는 극이 깊어 해를 입으므로 이 같은 이름이 붙었다. 이 과는 모든 일이 어렵고 지체되며, 풍파와 곡절을 겪은 후에 만사가 이루어진다. 모든 일에서 출비가 많고 결혼에는 방해가 있으며, 질병은 안전하지 못하고 잉태는 지체된다. 기다리는 사람은 오지 않고, 4과에 극이 많을 때는 재액이 깊어 풀기 어렵다. 극이 적은 것은 흉이 가볍고 풀기 쉽다. 
    인신사해(寅申巳亥)의 지반(地盤)에 맹신을 취하여 초전으로 하는 것은 견기격(見機格)이라 부른다. 자오묘유(子午卯酉)의 지반 4중신을 사용할 때는 찰미격(察微格)이라 부른다. 이 과는 중신(仲神)을 취하였으므로 찰미격이다. 재해는 점차로 경미하게 된다. 사람의 불인(不仁)을 두려워하며 혹은 계획적인 모해를 받기 쉬우므로 예방을 먼저 하면 재환을 면하다. 3합수국(三合水局)은 일간의 탈기(脫氣)로 되고 일지상신의 재효는 공망한다. 순수(旬首)가 초전으로 되어 순의(旬儀)는 길하다 해도 길은 가볍다. 귀인 2, 8 즉 묘유(卯酉)에 임하여 여덕격(勵德格)이다, 중상류 계급에 이롭고 하층 계급에는 불리하다.
    백법에 ｢과전이 6양(六陽)이면 공(空)에 이롭고 사사(私事)에는 불리하다｣고 하였다. 일귀는 역행하며 3전이 현(玄)․청(靑)․사(蛇)이며, 야귀는 순행하여 3전이 현(玄)․사(蛇)․청(靑)이다.`
    ,
    // 10
    `   이 과는 4과 중에 상하의 극이 없으니 사극(斜剋)인 인해(寅亥)가 초전으로 된다. 요극과(遙剋課)의 연식원칙(演式原則)을 이해해야 한다. ｢4과중에 상하의 상극이 없으니 4과상신과 일간의 사극(斜剋)을 사용하여 초전으로 한다｣ 만일 극이 두 개일 때는 상신(上神)에서 극하는 것을 채용한다. 즉 멀리 극하므로 이와 같은 이름이 붙었다.
    상신에서 극하여 초전으로 되는 것은 호시격(蒿矢格)이라 부르며, 일간에서 상신을 극하여 용(用)을 이루는 것은 탄사격(彈射格)이다. 즉 내가 가서 남을 극하기에 원극(遠剋)하니 먼 곳의 것을 쏘는 것과 같으며, 일은 멀어서 붙기 어려우니 허명 허리이다. 재해는 안에서 일어나고 모든 일이 후에 가서 성취되며, 사람을 방문해도 면회할 수 없고 기다리는 사람은 오지 않는다. 공망이 초전으로 되어 동작은 모두 공허한 것이다. 
    흉장․충형해(冲刑害) 또는 귀신 역행은 불순하여 원수(寃讐)․도난 등의 흉해가 있다. 만일 덕합(德合)․길장(吉將) 등이라면 친근함이 있어서 길조를 이룬다. 남을 모략하여 스스로 자기쪽의 불리를 초래한다. 위에는 이롭고 아래에는 불리하다. 귀신이 진술(辰戌)에 임하면 웃사람의 노여움을 산다. 초전이 공망하니 길흉을 이루지 않는다. 현무가 택신의 음신(陰神) 천강에 승하므로 도난․분실 등의 흉해가 있다.
    백법에 ｢피차가 화합되지 않는 것은 지형(支刑)이 3전에 있기 때문이다｣라고 하였다. 비전에는 ｢제2과를 초전으로 하는 것은 자기가 책전(策戰)해도 힘이 부족하여 실적이 적다｣고 하였다. 
    일귀는 역행하며 3전은 후(后)․상(常)․청(靑)이고, 길조(吉兆)가 있다. 야귀는 순행하여 3전이 백(白)․음(陰)․사(蛇)의 흉장이다.`
    ,
    // 11
    `   이 과는 4과이나 3과뿐이니 불비격으로 되며, 자진(子辰)의 1하가 1상의 적극(賊剋)을 사용하여 초전으로 하는 중심과(重審課)이다.
    3전은 밤중의 자(子)에서 인(寅)으로 옮겨 진(辰)에 돌아서 밤으로부터 광양(光陽)으로 된다. 이와 같은 것은 3양격(三陽格)이라 하여 어둠에서 밝음을 맞으니 처음에는 흉하나 후에 길조가 된다. 일지의 술(戌)이 일간상에 임하여 일간을 생하는 것은 자재격(自在格)이라 하여 즐거운 일이 자연히 온다. 그러나 현무가 승하므로 망동은 삼가야 한다. 중전 인(寅)의 재(財)는 공망하고 말전은 지저(地低) 공망으로 된다. 일지가 일간상신에 임하는 것은 췌서격(贅壻格)으로서 신(身)이 자유를 얻지 못하므로 그를 맞아들여 내몸을 유익하게 한다.
    백법에 ｢중․말전의 공망은 전(傳)으로 가지 않으므로 초전만으로 간점한다｣고 하였다. 또 진인(辰寅)이 중첩되는 것은 강색귀호격(罡塞鬼戶格)이다. 
    백법의 52법에 이르기를 ｢진(辰)이 인(寅)에 가해지거나 3전이 한지(閑地)의 어느 것에나 진인(辰寅)이 중첩되면 이 격으로 되어 재해를 피하기에 좋고 귀살이 엿보는 일이 없다｣고 하였다. 갑(甲)․무(戊)․경(庚)의 날에 귀신이 해(亥)에 임하면 귀신이 천문에 오른다고 하여 간점(看占)은 길로 화한다. 강색귀호(罡塞鬼戶)는 만사에서 귀(鬼)는 잠기고 악한 짐승은 엎드려 모든 일이 뜻대로 되니 지장이 없다. 
    비전에는 ｢인오술월(寅午戌月)을 정단하여 이 과를 이루게 되면 도망․가출(家出) 등의 정단은 반드시 돌아온다｣고 하였다. 일귀는 순포(順布)하며 3전은 후(后)․사(蛇)․합(合)이고 일녀격으로 된다. 야귀는 역포하여 3전이 백(白)․청(靑)․합(合)이다.`
    ,
    // 12
    `   이 과는 제3과에서 1하가 1상을 적하여 초전으로 되어 줌심격이며, 3전은 해자축(亥子丑)으로 진여격(進茹格)이다. 즉 연주3기(連珠三奇)로 된다. 일간은 왕상하여 모든 일이 길조로 화한다. 모사․계획사는 모두 유리하다. 유술(酉戌)과 신해(申亥)는 교차육해로 되어 피차간에 화합되지 못한다. 초전의 해(亥)는 겁살연여(劫殺連茹)로 되어 길흉이 모두 신속하게 진행된다. 말전의 축(丑)은 순미(旬尾)이니 언어를 삼가는 것이 좋다. 일가에서 3전은 북방수합(北方水合)하여 탈기(脫氣)로 된다. 모든 일에서 출비와 소모가 많고 질병은 위험하다.
    백법에 ｢모사는 졸책으로 된다. 즉 나망격(羅網格)이기 때문이다｣라고 하였다. 비전에는 ｢3기격(三奇格)은 원래 길과이지만 일간에서 탈기되는 것은 기쁨 중에 소모하는 일이 있다｣고 하였다. 
    말전 일간의 묘신 축(丑)에 천공(天空)이 승하는 것은 염막귀신(簾幕貴神)에 해당하여 시험은 급제한다. 일귀는 순행하며 3전은 음(陰)․후(后)․귀(貴)이고, 야귀는 역행하여 3전이 상(常)․백(白)․공(功)이다.
    이 과는 3전이 해자축(亥子丑)으로 북방1기(北方一氣)에 서로 이어지니 연주격(連珠格)으로 되고 해자축(亥子丑)은 일월성의 3기연주(三奇聯珠)로서 만사에서 화합한다. 길장이 승하면 길조로서 재해를 해제(解除)하고 화환을 피할 수 있다. 또 길사를 정단하면 연주(連珠)를 즐기며, 흉사를 정단하면 연여를 싫어한다. 병을 점단하면 대개는 위험하고 치료해도 재발한다. 또 병의 재발은 3합회국(三合會局) 및 연여격(連茹格) 중의 백호 또는 백호의 음신(陰神)은 병신(病神)이라고 말한다. 3전 중에 보일 때는 재발을 방지해야 한다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
