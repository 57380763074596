
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,8,7,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,9,6,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`위선`,10,5,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,4,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,3,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,1,2,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`신뢰`,2,1,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,12,`특별있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`위선`,4,11,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`위선`,5,10,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,9,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,8,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`小`,`12`,`색정사有`,`동요분산`],
    [`색정`,`외男 색정사-가정동요분산`,`大`,`과시기`,`반화위복`,`발탁도약`],
    [`최기취재(最忌取財)`,`제공하는 금전(여자포함)을 받으면 절대 안 되는 상 `,`大`,``,``,``],
    [`반화위복(反禍爲福)`,`화가 복으로 바뀌는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`11`,`事후쟁탈`,`의혹가출`],
    [`남자`,`남자 관련사`,`小`,`쇠퇴기`,`공허`,`災禍해소`],
    [`불통복음(不通伏吟)`,`활동을 못하고 속으로 신음하는 상`,`小`,``,``,``],
    [`환성지경(歡聲之慶)`,`기쁨에 넘쳐 소리지르는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`애증화합`,`단합결속`],
    [`자식`,`자식 관련사`,`小`,`침해기`,`도적失勢`,`외화내허`],
    [`불의상인(不宜上人)`,`윗인 조력이 좋지 못한 상`,`大`,``,``,`獻策지체`],
    [`발탁지희(拔擢之喜)`,`추천발탁되는 기쁨을 맞는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,`旺진衰퇴`],
    [`결속`,`여러 일을 결속하여 대재를 획득하나 부모 근심사 발생함`,`小`,`9`,`단합결속`,`색정사有`],
    [`색정`,`내女 색정사 有-파재 극처사`,`小`,`소외기`,`복잡다단`,`파재극처`],
    [`판상취귀(販商取鬼)`,`판매금 및 상품을 취하면 관송사가 벌어지는 상`,`大`,``,`미초희경`,`실종우려`],
    [`소인쟁재(小人爭財)`,`신분이 대등하지 못한 상대와 재물을 다투는 象`,`小`,``,``,``],
    [`관사`,`관재 질병 관련사`,`小`,``,``,``],
    [`직업`,`직업직위 관련사`,`大`,`8`,`도적악의`,`고시불리`],
    [`남편`,`남편 관련사`,`小`,`침체기`,`공격말것`,`구설근심`],
    [`반길위흉(半吉爲凶`,`절반의 좋은 일이 결국 나쁘게 되는 상`,`小`,``,``,``],
    [`적회악의(賊懷惡意)`,`도적(같은)이 해칠 마음을 돌렸으니 공격하지 말라는 상 `,`大`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`7`,`동요분산`,`비용과출`],
    [`출행`,`외사 출행이동사`,`小`,`단절기`,`출행이동`,`부부불화`],
    [`좌우부득(左右不得)`,`금전 등 원하는 것을 얻지 못함`,`小`,``,`와병불기`,``],
    [`적회악의(賊懷惡意)`,`도적(같은)이 해칠 마음을 돌렸으니 공격하지 말라는 象 `,`小`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`6`,`비곡차욕`,`가정막힘`],
    [`처첩`,`처첩 관련사`,`小`,`존재기`,``,`굴욕반복`],
    [`피난도생(避難逃生)`,`피난 도망하여 기회를 맞는 상`,`小`,``,``,``],
    [`비곡수욕(悲哭差辱)`,`본인 용모가 어떻든 상관없이 슬피 우는 상-훼절`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`5`,`가족질병`,`상하화합`],
    [`결속`,`결속하여 재원의 기반을 세우나  명예권리남편을 훼절함`,`小`,`배약기`,``,`백사俱憂`],
    [`무의도피(無疑逃避)`,`혐의가 없는데도 도피해야만 할 사연의 상`,`小`,``,``,``],
    [`사송계류(詞訟稽留)`,`사건이 종결되지 않고 대기 중에 있는 상`,`大`,``,``,``],
    [`후원`,`후원문서부친 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출로 성공함`,`小`,`4`,`의혹도망`,`타인압박`],
    [`매사 영속성없음`,`후원문서부모사는 선천적 기복재해를 겪음`,`大`,`개시기`,`급속사有`,`출행이동`],
    [`필유음사(必有陰私)`,`반드시 음사를 벌이고 있는 상`,`小`,``,`우유한가`,`간인궤계`],
    [`간인궤계(奸人詭計)`,`간사한 인이 꾸민 거짓 일을 벌이는 상`,`大`,``,`실업상태`,``],
    [`후원`,`후원문서모친 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`3`,`승진영전`,`혼인단장`],
    [`매사 영속성없음`,`후원문서모친사는 선천적 기복재해를 겪음`,`大`,`학습기`,``,`파혼우려`],
    [`하사허사(何事虛事)`,`좋고 나쁜 어떤 일도 허사가 되고마는 상`,`小`,``,``,``],
    [`혼인미만(婚姻美滿)`,`혼인이 만족하여 단장하는 상-파혼 우려됨`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`2`,`소식대기`,`불의투서`],
    [`남편`,`남편 관련사`,`小`,`면모기`,`와도나쁨`,`외화내허`],
    [`동변상상(動變相傷)`,`무리한 활동으로 상하고 서로 원수맺게 되는 상`,`小`,``,``,`구설실재`],
    [`진직천관(進職遷官)`,`승진 영전하는 상`,`大`,``,``,``],
    [`명예`,`수상(受賞)사`,`小`,``,``,``],
    [`직녹`,`직위녹위 관련사`,`小`,`1`,`외교막힘`,`탄식신음`],
    [`자산`,`재산권리 관련사`,`大`,`절정기`,`간인궤계`,`병 事미수`],
    [`불화반목(不和反睦)`,`음적 모해가 불화로 벌어지는 것에 대해 의논하는 상`,`大`,``,``,``],
    [`간인궤계(奸人詭計)`,`간교한 사람이 꾸민 거짓 계획을 벌이는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,`형제친구동료색정사조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`공허관사직업남자여식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위남편자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위부부갈등가정색정여식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`관사직업직위남편문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,"금전사업처조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,"금전사업처첩조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`관사직업직위사송계류남편문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사직업남편문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,`명예직녹자산권리사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속전속결로 종결해야만 유리하고 늦거나 뒤로 밀면 불리함`,0,0,`정당한 사안이므로 미적거리지 말고 강건하고 정정당당하게 속전속결해야만 유리한 人`,0,0,`현재에서 물러나야 하는 상황이나 되려 계속하게 됨**어디 산속으로 깊이 숨고싶은 심정**전화위복이 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속전속결로 종결해야만 유리하고 늦거나 뒤로 밀면 불리함`,0,0,`정당한 사안이므로 미적거리지 말고 강건하고 정정당당하게 속전속결해야만 유리한 人`,0,0,`일이 순리로 되지않고 역조로 띄엄띄엄진행됨**관직자는 자리를 옮기게 됨**여자는 남자가 둘인 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속전속결로 종결해야만 유리하고 늦거나 뒤로 밀면 불리함`,0,0,`정당한 사안이므로 미적거리지 말고 강건하고 정정당당하게 속전속결해야만 유리한 人`,0,0,`신규사가 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 순응유화유순하게 처신하여 사안을 뒤로 미루면 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순유화적 언행으로 후응하면 결과가 유리해지는 人`,0,0,`여러 일을 하나로 묶는 결속사가 있음**일이 반복되기만 하고 결과가 없음**전화위복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 순응유화유순하게 처신하여 사안을 뒤로 미루면 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순유화적 언행으로 후응하면 결과가 유리해지는 人`,0,0,`1녀2남 삼각관계 有**상대가 스스로 와서 금전(여자)을 도움`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오게 되므로 신중해야 함`,0,0,`부부남녀상쟁으로 동요불안분산사를 반복적으로 겪는 처지의 人 `,0,0,`풍운불측의 상**결론이 없는 반복부정사 에 처한 입장임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`제일 친한 친구나 가장 가까이 거주하는 자를 찾아 의논하고 선택하면 불안이 해소될 人`,0,0,`1남2녀 삼각관계 有**어디 깊은 산속으로 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 순응유화유순하게 처신하여 사안을 뒤로 미루면 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순유화적 언행으로 후응하면 결과가 유리해지는 人`,0,0,`여러 일을 하나로 묶는 결속 단합사가 있음- 단 복잡다단함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 순응유화유순하게 처신하여 사안을 뒤로 미루면 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순유화적 언행으로 후응하면 결과가 유리해지는 人`,0,0,`풍운불측의 상으로 연루사가 있고 간사(奸事)를 숨기고 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 순응유화유순하게 처신하여 사안을 뒤로 미루면 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순유화적 언행으로 후응하면 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄진행됨**서리 내린 다리를 건너는 격**일이 결과없이 반복되기만 하나 계속진행하면 밝아짐**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속전속결로 종결해야만 유리하고 늦거나 뒤로 밀면 불리함`,0,0,`정당한 사안이므로 미적거리지 말고 강건하고 정정당당하게 속전속결해야만 유리한 人`,0,0,`일을 전개시킴**전화위복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 동해도 중지됨`,0,0,`자기자신을 과대평가하여 지나친 처신으로 신음하는 격`,0,0,`과욕하거나 지나친 행위는 나쁜 일이 꼬리를 물고 일어날 우려를 초래함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성-무력`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`좌고우면-무력`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인-비용나감`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이권리가져갈인`,`아타동시발동사`,`기사`,`구사`,`외내동시사`,`표면이면동시사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성-무력`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사-후회사 겹침`,`미사`,`신사`,`외사`,`표면이면동시사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-이사하게 됨`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-쟁투분산`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [` 화해  됨`,`화해됨`,` 화해됨`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음2월`,3,2,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음3월`,2,2,4,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음2월`,2,3,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월-연기우려`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월-연기우려`,2,1,3,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음9월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음4월`,1,2,3,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,2,4,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음4월`,1,1,2,`음10월-연기또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,1,3,`음11월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,2,1,3,`음10월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,3,3,6,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초중말 모두 업무를 망침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 업무는 수준 이하임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 업무는 종반에 가서야 자산을 형성하는 실정이 있게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 결속단합하여 재정을 확충함순`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많아 업무가 수준 이하이고 종반에 후원이 끓기게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많으나 업무는 수준 이상의 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준소 미모이나 업무는 시종 공허허사무력 함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 상대를 잘 조정하여 결속단합하나 결국 큰 불명예와 큰 손실을 끼침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무전반이 저조공허허사무력으로 일관됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 업무는 수준 이하임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 업무는 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하나 업무는 성공적으로 평가됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부동조`,`이행`,`후이`,`退背`,`근`,`去`,`무난`,`정북`,``,`동남`],
    [`10.11`,``,`제압불가`,``,`천천이`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,`연기폐지`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부맞불`,`이행`,`본인무력`,`退背`,`  근`,`  去`,`세입설기`,`북북동`,``,`남남동`],
    [`10.11`,`  集`,`제압함`,``,``,``,``,``,`피로쇠약`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인무력`,`退背`,`  근`,`  去`,`주인후원`,`동북`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대다수`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`세력막강`,`이행`,`선난후不`,`退背`,`  원`,`  去`,`주인후원`,`정동`,`정남`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,`연기폐지`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`번대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부가세`,`이행`,`선不후不`,`退背`,`  원`,`   去`,`세입설기`,`동북`,`남남소`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,`피로쇠약`,``,``,``],
    [`3.6\n9.12`,``,`연기폐지`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후不`,` 退背`,`  원`,`   去`,`주인가해`,`남남동`,`북북동`,``],
    [`10.11`,``,``,``,`빠르게`,``,``,``,`동요불안`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압불가`,`이행`,`권리양도`,`退背`,`  근`,`  去`,`주인가해`,`정남`,`정북`,``],
    [`10.11`,``,`연기폐지`,``,`후난`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`자중지란`,`이행`,`선난후이`,`退背`,`  원`,`   去`,`세입설기`,`남남서`,`북서`,``],
    [`10.11`,``,`제압됨`,``,`천천히`,``,``,``,`피로쇠약`,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`상대사안`,`進向`,`  근`,`  留`,`주인무력`,`정북`,`북북서`,``],
    [`10.11`,``,``,``,`무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`본인가담`,`이행`,`상대사안`,`向進`,`원근왕래`,`  去`,`주인무력`,`정동`,``,`정북`],
    [`10.11`,``,`측근제압`,``,`무력`,`지구력要`,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근가세`,`이행`,`선不후不`,`進向`,`  근`,`  留`,`세입설기`,`동남`,``,`북북동`],
    [`10.11`,``,`종반제압`,``,``,``,``,``,`피로쇠약`,``,``,``],
    [`3.6\n9.12`,`多集`,`됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선不후不`,`進向`,`  근`,`  留`,`무난`,`남남동`,``,`정동`],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,`전흉사귀`,`혈광사`,0,0,`묘좌`,`남남동`,`없음`],
    [``,``,``,0,0,`묘향`,`북북서`,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,`등라수`,``],
    [``,``,``,0,0,`묘속`,`뱀굴`,``],
    [`가정`,``,``,0,0,`육친`,`처`,`증조부`,``],
    [`질병`,`전흉사귀`,`혈광사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`부인사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`老婦부정`,`水亡부정`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,0,`특별효과`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`제례귀`,`제례위범`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사  급속사`,`명예자산권리사**외교막힘`,`가정부부남녀상쟁사**외男색정사`,`非常之愛사`,`전화위복`,`소송할 수 없는 능욕박해사`,``,`환성적 경사`,`내女색정사`,`내부가정파재극처사`,``,`매사위역사`,`외사 출행이동사`,`발탁영전도약희사`,``,`내부가정막힘`,``,`신음탄식-병 또는 목적사 불성`,`매사허성불성**세속허사 자**내사 출행이동사`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`事후쟁탈사**매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`매사위역사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 급속사`,``,``,``,``,``,``,`재난해소`,``,`재난해소`,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외사 급속사`,`성공함`,``,``,``,`침체경구사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`전화위복`,`매사위역사`,`내사 급속사`,`명예자산권리사**외교막힘`,`가정부부남녀상쟁사**외男색정사`,`非常之愛사`,`전화위복`,`소송할 수 없는 능욕박해`,``,`환성적 경사`,`내女색정사`,`내부가정파재극처사`,``,`매사위역사`,`외사 출행이동사`,`발탁영전도약희사`,``,`내부가정막힘`,``,`신음탄식-병 또는 목적사 불성`,`매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`事후쟁탈사**매사 영속성 없음`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`내사 급속사`,``,``,``,``,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외사 급속사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`事후쟁탈사**매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`매사위역사`,`내사 급속사`,`명예자산권리사**외교막힘`,`외男색정사`,`가정부부남녀상쟁사`,`전화위복`,`非常之愛사`,``,`환성적 경사`,`내女색정사`,`내부가정파재극처사`,``,`매사위역사`,`외사 출행이동사`,`발탁영전도약희사`,``,`내부가정막힘`,``,`신음탄식-병 또는 목적사 불성`,`매사영속성없음**내사 출행이동사`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,`재난해소`,``,`재난해소`,``,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외사 급속사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`소인쟁재사`,`명예자산권리사**외교막힘`,`내사 급속사`,`가정부부남녀반목대립사`,`외男색정사`,`불리 고시**없는구설근심사**이진이동사`,`전화위복`,`진취쇠약-감춰 쌓고 토함이 좋음`,``,`내부가정파재극처사**내女색정사`,`관계불통가택이사 가족질병사`,`비곡차욕`,``,`외사 출행이동사`,`구설 놀램**병 중태사`,`내부가정막힘`,``,`희경사`,`내부가정막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 급속사`,``,``,``,``,``,``,``,``,``,``,``,``,``,``,`재난해소`,`재난해소`,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`희경사`,`내부가정막힘`,`매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`소인쟁재사`,`명예자산권리사**외교막힘`,`내사 급속사`,`외男색정사`,`가정부부남녀반목대립사`,`불리 고시**없는 구설사근심**이전이동사`,`전화위복`,`진취쇠약-감춰쌓고 퇴함이 좋음`,``,`내부가정파재극처사**내女색정사`,`관계불통가택이사가족질병사`,``,`비곡차욕`,`구설 놀램**병 중태사`,`외사 출행이동사`,``,`내부가정막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`외사 급속사`,``,``,``,``,``,`재난해소`,``,`재난해소`,``,``,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,``,`내부가정막힘`,`희경사`,``,`매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`소인쟁재사`,`명예자산권리사**외교막힘`,`내사 급속사`,`외男색정사`,`가정부부남녀반목대립사`,`불리 고시**없는 구설사 근심**이전이동사`,`전화위복`,`진취쇠약-감춰쌓고 퇴함이 좋음`,``,`내부가정파재극처사**내女색정사`,`관계불통가택이사가족질병사`,``,`비곡차욕`,`구설 놀램**병 중태사`,`외사 출행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재주의`,`동요불안분산사`,``,`동요불안분산사`,`외사 급속사`,`동요불안분산사`,``,``,`동요불안분산사`,``,`재난해소`,`동요불안분산사`,`동요불안분산사`,`재난해소`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`구설 놀램**병 중태사`,`외사 충행이동사`,``,`내부가정막힘`,`희경사`,``,`매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`소인쟁재사`,`명예자산권리사**외교막힘`,``,`외男색정사`,`가정부부남녀반목대립사`,`불리 고시**없는 구설사 근심**이전이동사`,`전화위복`,`진취쇠약-감춰쌓고 퇴함이 좋음`,``,`내부가정파재극처사**내女색정사`,`관계불통가택이사가족질병사`,``,`비곡차욕`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외사 급속사`,``,``,``,``,``,``,`내사 급속사`,``,``,``,``,`재난해소`,``,`재난해소`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,``,`비곡차욕`,`구설 놀램**병 중태사`,`외사 출행이동사`,``,`내부가정막힘`,`사송계류사`,``,`매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`소인쟁재사`,`상위자 는 좋으나  하위자는 수도자 격`,`명예자산권리사**외교막힘`,`외男색정사`,`가정부부남녀반목대립사`,`불리 고시**없는 구설사 근심**이전이동사`,`전화위복`,`느긋 한가`,`내부가정파재극처사`,`내부가정파재극처사**내女색정사`,`관계불통가택이사가족질병사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외사 급속사`,``,``,``,``,``,`내사 급속사`,``,``,``,``,``,``,`재난해소`,``,`재난해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`내부가정파재극처사**내女색정사`,`관계불통가택이사가족질병사`,``,`비곡차욕`,`구설 놀램**병 중태사`,`외사 출행이동사`,`내부가정막힘`,``,`희경사`,``,`매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`소인쟁재사`,`상위자 좋으나 하위자 는 수도자 격`,`명예자산권리사**외교막힘`,`외男색정사`,`가정부부남녀반목대립사`,`불리 고시**없는 구설사 근심*이전이동사`,`전화위복`,`느긋 한가`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`재난해소`,`음4,5월 화재주의`,`재난해소`,`음4,5월 화재주의`,``,``,`외사 급속사`,``,``,``,``,``,`내사 급속사`,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,``,`도둑 못잡음`,`내女색정사`,`내부가정파재극처사`,``,`비곡차욕`,`외사 출행이동사`,`발탁영전도약희사`,``,`내부가정막힘`,`투서헌책 나쁨**구설구체성없음**금전분실사`,``,`혼인사**매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`형책**매사 영속성 없음`,`종교진출하면 자립함`,`전화위복`,`소인쟁재사`,``,`명예자산권리사**외교막힘`,`외男색정사`,`가정부부남녀상쟁사`,`전화위복`,`승진영전이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,`재난해소`,`음4,5월 화재주의`,`음4,5월 화재주의`,`재난해소`,``,``,`외사 급속사`,``,``,``,``,``,`내사 급속사`,``,``,`폐한인`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`승진영전이동사`,``,`도득 못잡음`,`내女색정사`,`내부가정파재극처사`,``,`비곡차욕`,`외사 출행이동사`,`발탁영전도약희사`,``,`내부가정막힘`,``,`투서헌책나쁨**구설구체성없음**금전분실사`,`혼인사**매사 영속성 없음**내사 출행이동사`,`종교진출하면 자립함`,`형책**매사 허성불성**세속허사 자`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`전화위복`,`소인쟁재사`,``,`명예자산권리사**외교막힘`,`외男색정사`,`가정부부남녀상쟁사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`전화위복`,``,`재난해소`,``,`재난해소`,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외사 급속사`,``,``,`성공함`,``,``,`내사 급속사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`가정부부남녀상쟁사*외男색정사`,`非常之愛사`,`재난해소`,`소송할 수 없는 능욕박해사`,``,`환성적 경사`,`내女색정사`,`내부가정파재극처사`,`재난해소`,`매사위역사`,`외사 출행이동사`,`발탁영전도약희사`,`내부가정막힘`,`탄식신음-병 또는 목적사 불성`,`재난해소`,`문화풍아`,`매사 허성불성**세속허사 자**내사 츨행이동사`,`음3,6,7,8,9,12월 방문자는 종교진출하면 `,`형책**事후쟁탈사**매사 허성불성**세속허사 자`,`음3,6,7,8,9,12월 방문자는 종교진하면`,`전화위복**재난해소`,`소인쟁재사`,``,`명예자산권리사**외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`전화위복`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외사 급속사`,`성공함`,``,`성공함`,``,``,`내사 급속사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 막혔네? 자신만 믿고 선두에 나설것이 아니라 급히 계획을 변경해야만 돈이 더 안깨지지!
(申月 점은 처 임신, 寅월 점은 유산함)
`,`■ 진퇴양난이네? 
병도 봄ㆍ가을이라야 낫겠고!

⊕⊖전흉사귀 침범 때문인데 해소시키고 기도할 수밖에 없겠어!  효과가 올꺼야!

⊕⊖신변상가부정이 침범을
    했네 ?
`,`■ 어지러운 세상에 귀인이 난을 피하는 형국일세?지금은움직여 봐야 자충수일 뿐인 운세야 !
⊕가택귀ㆍ여귀ㆍ가택수망    노부부정, 
⊖흉사귀ㆍ도로귀ㆍ가택음사부정이 들어 발뻗고 쉴수도 없는거야!
`,`■ 새 일 시작한다고 돈  께나 나가겠어! 
돕겠다는 사람도 힘은 안되겠고!
그래도 힘을 내야지! 
나중에는 좋아져!

⊕신변상가부정이 들었네 ?
`,`■ 여러 사람의 재물이 결국 재앙이 되겠어!
겉은 화려한데 속은 빈격이고 귀신이 내몸을 쳐!

⊕신불제사귀ㆍ가택귀,
⊖음귀ㆍ전흉사귀가 급해!
`,`■ 돈이 저절로 오긴 오는데 공짜가 있겠어? 
一女二男 삼각관계가 관재를 몰고 올 염려가 크네?

⊕변사귀, 
⊖전사귀가 가정과 일 중간에    버티고 있어!
`,`■ 마음은 부부이심이고         앞길은 캄캄,  
     일은 반복만 계속! 

놀램은 크고 불안도 크고! 
기로에 섰어 !
`,`■ 이것이냐, 저것이냐, 
선택을 해야 하는 기로에 섰는데 一男二女 색정사가 끼어 피신할 지경까지 되었네?
⊕수귀, ⊖여귀때문이야!
집권리까지 넘어가겠어!
⊕⊖신변수망노부부정이
    침범을 했네 ?
`,`■ 男 : 사표써야겠네?
  女 : 부부인연이 다했어!
일은 여러갈래로 번잡하고 비용은 크게 나가고!

⊕전사귀ㆍ흉사귀ㆍ가택상가부정!
⊖변사귀ㆍ가택귀ㆍ가택목기부정!
`,`■ 부모의 재해가 걱정이네! 후원․문서사등은기대가 안되겠고 ! 
은닉(죄인)사도 있겠어 !

⊖도난․분실사 조심, 살상과 다툼, 喪事도 조심해야 하겠어 !
`,`■ 마음이 떠있네? 일이 끝이 없고 반복되기만 하겠어! 관재ㆍ색정사 주의가 필요해!

⊕전사귀ㆍ흉사귀 ⊖가택귀ㆍ신불제사귀 때문    이야
`,`■부부가 똑같이 바람이 났어! 그러니 일이 되겠어? 
끝까지 가봐야 손해에다가 
이별사만 보이겠어!
⊕전사귀, 
⊖변사귀 침범으로 그래!

⊖신변상가부정이침범했네?`];
const YOGYEOL1 = [`■ 내 녹을 천장이 해치니 재물과 녹이 상하리라. 만약 다른 것을 한다해도 또다시 손해보니 주의해야 한다.
`,`■ 막히는 일이 자주 있다. 낮정단은 귀살이 범을 타고 온다. 후퇴하여 살 길을 찾고자 해도 공망으로 진퇴양난이다.
`,`■ 집 좌우에 귀인이 산다. 주색을 너무 즐기면 이로 인해 몸을 상하게 되리라.
`,`■ 윗사람의 도움이 없다. 어디가서 무엇을 해도 손해난다. 천장을잘 살피라. 낮정단은 귀인에게 고변하지 말라.
`,`■ 서로간에 좋기는 하나 나중에 손해가 난다.
재물로 재앙을 만드니 나쁜 일이 끝나지 않는다.
`,`■ 상대가 내게 재물을 가져오나 밤정단은 좋다만다. 자세히 살피자면 부모의 점은 불길하다.
`,`■ 세군데서 재물을 도적당하니 두 귀인은 극을 받아 청탁하기 어렵고 낮정단은 피흘릴까 걱정이고 재물이 양쪽에 있으나 내것이 안된다.
`,`■ 피차 모두 다치나 아랫사람이 더 나쁘다. 한번이라도 움직이게 되면 손해나는 것이 여러 가지가 된다.
`,`■ 사람은 적고 집은 넓다. 비록 비용은 나가도 기쁨이 있게 된다. 너무 손해가 나거든 피신하여 편안을 얻으라.
`,`■ 서로 도와 좋긴해도 끝내는 좋지 못하다. 낮정단은 나쁜 일이 많고 밤정단은 손해가 있다.
`,`■ 내가 관직과 합을 했으니 높은 이를 만나는 것이 좋다. 일반인은 관재가 발동하나 이미 재앙을 당한자는 괜찮다.
`,`■ 나와 상대가 서로 학대하니 손해만 나고 좋지않다. 도적이 칼을 들었으니 손해나고 몸까지 상하리라.`]
const SIBO1 = [`■ 가정ㆍ여인 탄식 신음
他家에 거주한다.
자신을 자만하여 신음고로
일도 막혔다. 급히 계획을 변경시켜 정중한 최선을 다해야만 한다.
가을, 겨울은 진행하고, 봄, 여름은 자중하라. 정단의 시기이다.
凶은 해제된다.
七月에 임신사가 있다.
`,`■ 자충수적 우환이 기한다.
病은 흉하다.
무슨 일이든 안 풀리고 있다. 병․관 진퇴양난이다.
길게 끌면 안된다.
초전, 첫 일에 결정해야 한다. 다행히 천우신조가 있어 풀리게 된다.
우선은 도망이 좋다.
부모의 재해가 있게 된다.
`,`■ 가내 우환이 있고 가장을
속인다. 가인이 원한을 
품고 加害하려 한다.
부정을 해소하라.
탄핵ㆍ해임사가 있다. 스스로 자초했다. 
동하면 오히려 손모가크다.부모의 재해가 있게 된다.
⊕수망노부부정, ⊖음사부정이 들었다.
`,`■ 사물이 양갈래이다.
계획은 위험하다.
부모재해가 있게 된다.
후원이나 협력을 기대할 수 없다.
여인에 의해 재를 잃게 된다. 
구재도 목적대로 완전하지 못하다.
출비가 많아 고민한다.
후에 녹이 있다.
`,`■ 자충수적 우환이 기한다.
신불기도 요망
음사부정이 들었다.
多事多人의 금전사이다. 
실보다 허가 많아 주의를 요한다. 문화방면에 기쁨이 있다. 서로 융화협조가 크다.
용두사미를 경계할 것.
七月에 임신사가 있다.
`,`■ 가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
女ㆍ婦는 헌신한다.
一女二男의 삼각관계이고 女가 분주하다.
財또는 여자 문제가 자유롭지 못하다. 
일도 준비가 부족하다.
구태의연한 형편이다.
여자가 파재시킨다.
七月에 임신사가 있다.
`,`■ 가택을 손상한다.
七月 처ㆍ첩의
임신사가 있다.
친구나 형제에 의해 재가 분탈된다.
上人의 도움을 기대할 수 없게 되었다.
반복부정하고 下人에 능욕 받는다. 
연루되어 기복이 있다.
凶이 吉로 된다.
`,`■ 자충수적 우환이 기한다.
가택을 손상한다.
가택의 권리가 넘어갔다.
男ㆍ夫는 가해한다.
관송사가 있다. 
피차 손상된다. 
일에 바른 것이 없고 부족 혼미하고 통하는 것이 없다.
一男二女의 삼각관계도 있다. 계획은 위험하다.
부모재해가 있게 된다.
`,`■ 가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
관사는 탄핵ㆍ해임 兆이다. 
먼저 굽혀야 한다.
직위ㆍ명예가 훼손된다.
일반 정단은 좋다.
매사 화합단결한다.
단 허비가 많게 된다.
⊕상가부정, ⊖목기부정이 들었다.
`,`■ 간사 은닉건이 있다.
內外 시의심이 있다.
지나치게 친밀하면 의혹을 받는다. 
왕성하여 분수를 넘으면 불길하게 되므로 절제가 중요하다.
여인에 의해 허모사가 있다. 도난 분실도 경계하라. 상사가 기한다.
`,`■ 자충수적 우환이 기한다.
계획은 위험하다.
大가 小로 변화한다.
자손사로 재의 손실이 있다. 재물이 뜻대로 진척되지 못한다.
실재ㆍ도난의 걱정이 있게 된다. 
관사의 財가 들어온다. 
신택이 불안하다.
`,`■ 가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
천우신조로 向明이다.
부부가 공히 이심으로 사통한다.
서로 시의심이 있다.
도난사가 있게 되고 불상사가 기한다.
함부로 계획하면 효과 없는 졸책이 된다.
약속은 위배된다.`]
const SIBO2_1 = [`■ 직녹, 신변외사이다.
내방조짐 상의 : 
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 병, 가택내사이다.
내방조짐 상의 : 
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
`,`■ 자손, 신변외사이다.
내방조짐 상의 : 
은둔ㆍ도망ㆍ원행에 이롭다.
財․女로 놀랜다.
`,`■ 재ㆍ여, 신변동요사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 官･夫, 가택내사이다.
내방조짐 상의 : 
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
부모재액 예방이 필요
`,`■ 임신, 가택내사이다.
내방조짐 상의 : 
움직임이 근본이고 동기가 발동한다.
관의 財가 들어 온다.
`,`■ 임신, 가택동요사이다.
내방조짐 상의 : 
움직임이 근본이고 동기가 발동한다.
색정 불화가 있다.
`,`■官･夫, 여인구해사이다.
내방조짐 상의 : 
해산건이 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 관ㆍ부, 가택내사이다.
내방조짐 상의 : 
은근하고 伏하라.
男직위상실하고
女부부이별한다.
`,`■ 후원, 가택시비사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
후원사가 난망하고 부모재액이 발생한다.
`,`■ 후원, 신변불성사이다.
내방조짐 상의 :원행·이동
어떤 일이든 처음 불성한다.
후원사가 난망하고 부모재액이 발생한다.
`,`■官･夫,가정동요사다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/019/row-1-column-1.png`,
  `/static/images/sagwa/019/row-1-column-2.png`,
  `/static/images/sagwa/019/row-1-column-3.png`,
  `/static/images/sagwa/019/row-1-column-4.png`,
  `/static/images/sagwa/019/row-1-column-5.png`,
  `/static/images/sagwa/019/row-1-column-6.png`,
  `/static/images/sagwa/019/row-1-column-7.png`,
  `/static/images/sagwa/019/row-1-column-8.png`,
  `/static/images/sagwa/019/row-1-column-9.png`,
  `/static/images/sagwa/019/row-1-column-10.png`,
  `/static/images/sagwa/019/row-1-column-11.png`,
  `/static/images/sagwa/019/row-1-column-12.png`,
]

const HEASYEOL = [
  // 1
  `  이 과는 12신이 본궁에 숨어 있어 복음과(伏吟課)이다. 양일(陽日)은 일간상신 해(亥)를 초전으로 하고 중전은 지상신의 오(午)를, 말전은 충신(冲神)으로서 구성하는 것이 원칙이다. 양일 복음과(伏吟課)는 자임격(自任格)이라고 하며 음일(陰日)은 자신격(自信格)이다. 이것을 합쳐서 임신(任信)이라고 하는데 정(靜)하는 것이 안전하다. 양(陽)은 강기(剛氣)이므로 스스로의 강함을 믿고 선두에서 본의 아닌 실수를 하게 된다.
  복음(伏吟)은 도망․분실․도난 등 어느 것이나 가까운 곳에서 찾아야 한다. 귀신(貴神)의 순행은 일지의 전 일위(前一位)이고 귀신이 역행은 일지의 후 1위의 지점을 찾아야 한다. 중전은 오(午)의 재성(財星)에 해당되지만 간상(干上) 및 초․말전이 어느 것이나 비겁(比劫)으로 된다. 따라서 군겁(群劫)은 재(財)를 빼앗으므로 분쟁을 일으킨다. 자임격은 스스로의 강함에 맡겨 행(行)이 지나쳐 재(災)를 초래한다. 해(亥)는 해(亥)를 보아 자형(自刑)으로 되고 두전격(杜傳格)으로 되고 사물에 막혀 통하지 않는다.
  백법에 말하기를 ｢중전의 오(午)는 임(壬)에서 보아서 태재(胎財)로 된다. 신월(申月)은 생기(生氣)로 되고 처는 임신한다. 인월(寅月)은 사기(死氣)로 되어 태아를 생육하지 못한다｣고 하였다. 비전에 말하기를 ｢임(壬)에서 해(亥)는 덕록(德祿)으로 되고 해(亥)에 앉는 것은 천문(天門)에 들어간다고 말하며, 흉을 해제하는 덕(德)이 있다｣고 하였다.
  자형두색격(自刑杜塞格)은 어떤 일을 급하게 개변 계획하는 것이 좋으며, 정중동(靜中動)으로 동하는 것이 좋고 정좌하는 것을 싫어한다. 일귀는 순행하여 삼전이 상(常)․합(合)․현(玄)이고, 야귀는 역행하여 삼전이 공(空)․후(后)․청(靑)이다. 또 을일(乙日)․계일(癸日)의 양일(兩日)은 극적(剋賊)이 있으므로 극적을 취하여 초전으로 하고 중․말전은 형(刑)을 취하여 쓴다. 이 과는 먼저 일진(日辰)의 왕쇠(旺衰)를 판별한다. 왕(旺)할 때는 전진하고 쇠(衰)할 때는 지킨다. 초용(初用) 길은 활동해야 하고 용신(用神) 흉은 때를 기다리는 것이 좋다.`
  ,
  // 2
  `  이 과는 제1과만 토극수(土剋水)로 1상에서 1하를 극하여 초전으로 되는 원수과(元首課)이고, 제2국은 어느 것이나 퇴여격(退茹格)이다. 술(戌)을 해(亥)에 가하여 초전으로 되는 것은 괴(魁)가 천문(天門)을 건넌다고 말하며, 정단사는 어느 것이나 저격(咀隔)을 생한다.
  일간상신의 술(戌)에 백호가 승하여 초전을 구성하면 흉재가 급속히 발생한다. 신유(申酉)의 인생(印生)은 공망으로 되어 진퇴양난(進退兩難)이다. 제4과의 상충(相冲)으로 흉이 흉을 제어하여 타개(打開)의 길을 얻을 뿐만 아니라 흉을 흩어지게 한다. 또 육의참관(六儀斬關)은 동하여 길조를 나타낸다.
  백법에 말하기를 간상의 묘호(墓虎)가 신(身)을 극하고, 병(病)을 정단하면 가장 흉이라고 알 수 있다｣고 하였다. 중․말전의 공망은 초전의 1신(一神)에서 길흉을 알아야 한다. 일귀는 순행하여 삼전이 백(白)․공(空)․청(靑)이고, 야귀는 역행하여 삼전이 백(白)․상(常)․현(玄)이다.



`
  ,
  // 3
  `  이 과는 4과 중에 제4과의 인(寅)에서 진(辰)을 극하여 원수과(元首課)로 된다. 삼전 인자술(寅子戌)은 역간전(逆間傳)으로서 명음격(冥陰格)이라고 부른다. 인(寅)은 일출(日出)의 밝은 방향으로 자(子)에 전하고, 술(戌)에 이르면 양(陽)이 퇴하여 음으로 돌아간다. 즉 명(明)을 버리고 암(暗)을 투(投)하는 상이므로 동하면 손모(損耗)를 초래한다.
  초전 인진(寅辰)은 천양격(天梁格)에 이롭고 관(官)의 정단은 가장 흉하다. 일간상신 유(酉)는 인(印)이더라도 공망으로 된다. 만약 천공(天空)이 승하면 허사부실(虛詐不實)로 변화한다. 제1과․제3과의 상하는 진오유해(辰午酉亥)와 자형(自刑)의 자(字) 뿐이다. 흉해는 내외에서 발생하는 것이 아니고 자기가 스스로 일으킨다. 백법에 말하기를 ｢공상(空上)에 천공(天空)을 만나는 것은 사물을 추구(追求)하지 말아야 한다｣고 하였다.
  일귀는 역행하여 삼전이 사(蛇)․합(合)․청(靑)이며, 사(蛇)는 용으로 변화하고 흉은 길로 변화한다. 현임관은 극히 길조로 된다. 공명은 원대한 것을 기다려야 한다. 야귀는 역행하여 삼전이 합(合)․청(靑)․백(白)으로 되어 어느 것이나 삼전 중에 청신(靑神)의 길장(吉將)이 있는 것을 즐긴다.`
  ,
  // 4
  `  이 과는 4과 중에 제2과만 사(巳)와 신(申)의 상극이 있고 1상에서 1하를 극하여 원수과로 된다. 사과 사맹신은 원태과이다. 일간상신 신(申)은 임(壬)의 장생(長生)으로 되어 좋지만 신(申)이 순공(旬空)하기 때문에 생조(生助)에 의존할 수 없다.
  초전의 사(巳)는 공망에 앉아서 고진살(孤辰殺)로 된다. 중전은 설기(泄氣)로 되고 말전은 건록에 해당하므로, 처음에는 허(虛)하고 후에 실(實)하게 된다. 초전은 처재(妻財)인데 공망에 앉아 있어 처에 의해 재(財)를 잃거나 혹은 구재의 목적이 반감(半減)한다.
  백법에 말하기를 ｢일지상신은 탈기(脫氣)로 되고 중전이 또 탈설(脫泄)하여 출비가 많아 힘이 부족하며, 즐거운 가운데 고민이 있다｣고 하였다. 비전에 말하기를 ｢원태격(元胎格)은 노인 및 소아의 병단(病斷)은 좋지 않다｣고 하였다. 예를 들면 초전이 사화(巳火), 말전이 해수(亥水)로 되면 상은 열(熱)하고 하는 냉(冷)하다. 중전 인목(寅木)은 화열(火熱)을 왕(旺)하게 하고 사화(巳火)는 금(金)을 극하여 아래로 통하지 않으며 금(金)의 폐부(肺部)를 상하게 한다. 임수(壬水)에서 신(申)은 장생이며 생(生)을 보고 생하지 않는다. 신(申)은 7, 해(亥)는 4이므로 사칠(四七)은 이십팔일(二十八日)을 넘기지 못하고 사망한다.
  이 과(課)와 같이 일간에서 일지상신 및 삼전 중에 탈기(脫氣)가 두 곳에 있으면 원소근단(源消根斷)이라고 하여 생명이 위험하다. 
  일귀는 역행하여 삼전이 음(陰)․사(蛇)․구(勾)이며, 야귀는 역행하여 삼전이 귀(貴)․합(合)․공(空)이다.`
  ,
  // 5
  `  이 과는 4과 중에 제1과, 제4과에 상하의 극적(剋賊)이 있고 제4과의 하에서 상을 적하는 것을 취하여 초전으로 하는 중심과이다. 삼전 술오인(戌午寅)은 삼합염상격(三合炎上格)이며, 일지상신(日支上神) 인(寅)과 기궁(寄宮)의 해(亥)가 지합(支合)하고, 오(午)와 일간상신 미(未)가 합하며, 술(戌)과 묘(卯)가 지합하여 삼전이 삼합화국(三合火局)한다. 일간과 오(午)의 정(丁)이 간합(干合)하고, 교차(交車) 상합(相合) 등 교호(交互)로 과전이 합하여 상하내외가 융화(融和)하는 상을 이룬다. 그러므로 이것을 화미격(和美格)이라고 한다. 삼합화국은 일간의 재효(財爻)로서 대중의 재(財)이다.
  구재(求財)는 분수를 알아야 하며 과욕은 재해를 초래한다. 또 교섭교관사(交涉交關事)는 교차합(交車合) 중에 탈합(脫合)되는 것은 서로 기만이 있다. 즉 웃음 속에 칼을 품는 격이므로 주의와 경계를 요한다. 순수(旬首)가 초전으로 되면 육의(六儀)로서 특히 삼전이 삼합하는 것은 기쁨이 미간(眉間)에서 넘치고 흉신(凶神)도 해(害)를 끼치지 못한다.
  삼합염상(三合炎上)은 화체(火體)로서 생(生)하기 쉽고 멸(滅)하기도 쉬우며, 양일(陽日)은 일광(日光)이고 음일은 등화(燈火)이다. 화(火)는 염(炎)으로 상을 충(冲)하고 허(虛)가 많고 실의(實意)가 적다. 성질은 조급하고 어떤 일도 급속을 나타낸다. 문명․문화․문서․음신(音信)․도야(陶冶)의 일은 모두 양호하다. 백법에 말하기를 ｢과전에 삼육합(三六合)이 겹치면 만사에 기쁨이 겹치고, 교차지합(交車支合)은 교섭․교역․교관(交關) 등에 유리하다.
  일귀는 역행하여 삼전이 청(靑)․현(玄)․사(蛇)로 변화하여 길도 흉으로 변화하므로 급히 신불(神佛)에 기도해야 한다. 야귀는 삼전이 백(白)․후(后)․합(合)이다. 백호가 귀(鬼)에 승하는 것은 최관사자(催官使者)라고 하여 관리는 부임․선임(選任)이 극히 빠르다. 귀신(貴神)이 묘유(卯酉)에 임하는 것은 여덕격(勵德格)이다. 묘(卯)에 임하면 출문(出門)이라 하고, 유(酉)에 임하면 입문(入門)이라고 한다. 군자는 천동(遷動)이 있고 보통 사람은 인신(人身)․주택에 모두 불안이 겹쳐 발생한다. 가신(家神)에 기도하여 흉해를 제거해야 한다.`
  ,
  // 6
  `  이 과는 제1과에 오화(午火)와 수(水)의 상극이 있어 오해(午亥)를 초전으로 하여 중심과(重審課)를 구성한다. 삼전이 오축신(午丑申)이며 말전이 공망한다. 일지의 오(午)는 일간상에 가하여 췌서격(贅壻格)을로 된다. 4과 중에 3과 밖에 없으므로 불비격(不備格)이다. 어떤 일도 바른 것이 결여되어 완전하지 못하다. 초전 오(午)의 재(財)는 해(亥)의 지(地)에 극되고 천후․현무의 수신(水神)에 극되어 협극(夾剋)으로 된다. 따라서 이 재(財)는 자유를 얻지 못하는 재이다.
  오축신(午丑申)은 4과상신을 떠나지 않는다. 즉 삼전이 4과를 나오지 않으므로 회환격(廻環格)이라고 부른다. 사물은 구태(舊態) 의연한 상이다. 길을 정단하여 길이 완전하지 않고 흉이더라도 완전한 흉이 아니다. 결혼은 서양자가 되거나 유소년(幼少年)이라면 내 집에 와서 양육된다. 이 과격은 토지 가옥의 사건이 연속적으로 발생한다.
  백법에 말하기를 ｢초전의 협극(夾剋)은 모든 일에서 자기의 자유를 얻지 못한다｣고 하였다. 오축(午丑)에 육해(六害)가 있어 피아에게 모두 재해가 생한다. 비전에 말하기를 ｢제4과의 음택(陰宅)에 신(申)의 부모효(父母爻)에 백호가 승하여 묘(墓)에 앉는다. 묘(墓) 중에 충류(蟲類)의 해가 있고 현무가 승하면 묘 중에 수기(水氣)가 차 있다. 말전의 이런 상태는 집안의 존장 또는 부모가 불기(不起)의 병에 걸린다. 초전에서 중전, 중전은 말전, 말전은 일간을 체생(遞生)하여도 말전이 공망하고, 일간의 장생이더라도 기력이 없다. 금왕(金旺)의 월(月) 또는 일(日)을 기다려 사업을 계획하면 성취할 수 있다.
  일귀는 역행하여 삼전이 현(玄)․주(朱)․백(白)이고, 야귀는 삼전이 후(后)․구(勾)․현(玄)이다. 천지반(天地盤) 중에 자(子)가 사(巳)에 가해지면 병점(病占)에는 불길하다.`
  ,
  // 7
  `  이 과는 천지남북의 위치에 있어 상극하고 반음과(返吟課)로 된다. 양일은 양의 극을 취하여 오자오(午子午)로서 삼전을 구성하고 반음비용과(返吟比用課)로 된다. 일간상신 사화(巳火)가 재(財)이고 삼전의 초․말전도 재(財)이지만 해자(亥子)의 비견(比肩)․겁재(劫財)가 있어 재(財)를 빼앗는다. 묘(卯)의 귀신(貴神), 사(巳)의 귀신은 모두 극지(剋地)에 앉고, 임일(壬日)은 오(午)를 보면 십이운태(十二運胎)인 동시에 처재(妻財)이므로 처첩이 임신한다.
  반음과는 모든 일이 반복 왕래하는 과이므로 흉을 만나면 오히려 길조로 된다. 기쁨이 우려로 되고 새옹(塞翁)의 말(馬)이 되기 때문이다. 즉 득실(得失)의 상이다. 백법에 말하기를 ｢양귀신(兩貴神)이 극을 받으면 귀인의 원조를 얻지 못한다｣고 하였다. 비전에 말하기를 ｢병단(病斷)은 일간의 녹신(祿神)을 보는 것을 즐기고, 녹신 및 식신(食神)은 질병의 의약자양(醫藥滋養)의 신이므로 극을 받거나 공망이 되는 것을 두려워한다｣고 하였다. 예를 들면 오랜 병일 때는 반드시 식(食)을 절(絶)하여 죽음에 도달한다. 현무가 오(午)에 승하면 전직(轉職)하게 된다. 일귀는 역행하여 삼전이 현(玄)․합(合)․현(玄)이고, 야귀는 순행하여 삼전이 사(蛇)․백(白)․사(蛇)이다. 천장(天將)은 모두 흉장(凶將)이다. 이 과와 같이 상하내외(上下內外)가 모두 상극하게 되면 가택 인근이 모두 불화하다.
`
  ,
  // 8
  `  이 과는 제1과․제3과에 극이 있고 양일로 되면 양의 극 진해(辰亥)를 취하여 초전으로 한다. 간상(干上) 진(辰)에서 진(辰)을 초전으로 하는 것은 참관격(斬關格)이다. 4과일지라도 진해(辰亥)의 2위(二位)에 있어 3과 뿐일 때는 불비격으로 된다.
  일간상신은 일간을 극하고, 일지상신은 일지를 극하여 피아가 함께 손상되거나 인택이 모두 상한다. 일간(日干)의 기궁(寄宮) 해(亥)는 일지상신에 임하여 일지를 극한다. 즉 내가 그의 집에 가서 그를 극한다. 초전 진토(辰土)는 일간을 극하고 귀묘(鬼墓)로 되고, 중전․말전이 공망하므로 초전의 일신(一神)으로 길흉을 결정한다. 그 위에 불비격으로 되면 모든 일에 유통생화(流通生化)의 정(情)이 없다. 그러나 길흉은 어느 것이나 경미하다.
  백법에 말하기를 ｢일지 상신 건록은 권섭부정(權攝不正)으로 된다｣고 하였다. 중․말전이 공망하면 전(傳)에 행(行)하지 않는다고 하며 시작할 때를 고려해야 한다. 일간상신이 진(辰)의 묘신(墓神)에 숨어서 초전으로 되는 술(戌)의 충(冲)을 즐기지만 충개(冲開)가 없으므로 어떤 일도 혼미하며 간난의 징조가 있다. 일간 기궁(寄宮)이 일지상신에 가해지고 오(午)를 보면 재(財)로 된다. 이와 같은 것은 취재(取材)하에 난(難)을 피하므로 득재격(得材格)이라고 부른다. 일귀는 역행하여 삼전이 후(后)․공(空)․현(玄)이다. 진(辰)에 천후(天后)가 승하는 것은 부인의 질병 및 태산은 흉하다.`
  ,
  // 9
  `  이 과는 제2과․제4과의 상하에 극이 있고 하에서 상을 극한다. 제2과를 취하여 초전으로 한다. 삼전이 묘해미(卯亥未)의 목국(木局)이면 곡직격(曲直格)이고, 과명(課名)은 중심(重審)이다.
  일간의 임수(壬水)에서 삼전은 설탈(泄脫)로 되고 삼합목국(三合木局)의 목(木)은 일지의 오(午)를 생하므로 사람은 적어도 가택은 관대(寬大)하다. 초전은 일간을 극하여도 일간상신의 목(木)은 이것을 제어하여 해가 없고 피난도생(避難逃生)으로 되며, 흉이 없고 편안을 얻는다. 사과삼전이 모두 삼합하면 무슨 일이든 화합한다. 춘월의 정단은 왕하여 가장 길조를 이룬다.
  백법에 말하기를 ｢만사가 기쁨이 많은 것은 과전이 삼합․육합하기 때문이다. 가택이 광대하여도 사람은 쇠퇴한다｣고 하였다. 비전에 말하기를 ｢삼합(三合)․육합(六合)이 겹치면 흉악(凶惡)을 띠어도 대흉(大凶)으로는 되지 않는다. 예를 들면 금일(金日)은 인오술(寅午戌)의 화국(火局)을 얻으며, 토일(土日)은 해묘미(亥卯未)의 목국(木局)을, 목일(木日)은 사유축(巳酉丑)을 보고 화일(火日)은 신자진(申子辰)의 수국(水局) 등은 어느 것이나 중귀(衆鬼)이지만 화(禍)는 경미하고 과전에서 돕는 것이 있으면 흉을 변화시키게 된다. 그러나 삼전이 설기(泄氣)되고 다소의 허비(虛費)는 면하기 어렵다.
  일귀는 순행하여 삼전이 구(勾)․상(常)․귀(貴)이고, 야귀는 삼전이 주(朱)․공(空)․음(陰)이다. 곡직격은 먼저 곡(曲)하고, 후에 직(直)으로 된다. 혹은 먼저 직진(直進)하면 후에는 곡절에 이른다. 삼합은 길상이지만 질병의 간점(看占)은 오래 지속되므로 싫어한다. 간법원칙(看法原則)에서 말하기를 ｢일간에서 삼전을 생하는 것은 재원(財源)이 소모된다. 초전이 말전을 극하면 사물이 성사되기는 어렵지만 이 과(課)와 같이 말전이 초전을 극하면 목적한 일은 성취된다.`
  ,
  // 10
  `  이 과는 제3과의 유오(酉午)가 1하에서 1상을 적하여 초전으로 되는 중심과이다. 삼전 유자묘(酉子卯)의 사충신(四冲神)은 삼교격(三交格)이다. 일간일지는 교호상생(交互相生)하는데 이와 같은 것을 교차상생(交車相生)이라고 한다. 모든 사건의 담합(談合)은 원만하고 화합한다.
  삼교격(三交格)은 투쟁의 과로서 구진․백호가 승하면 살상(殺傷) 사건을 초래하거나 상사(喪事)가 발생한다. 초전 유오(酉午)는 패(敗)로 되고 사(死)로 되기 때문에 교섭하는 일은 성사되지 않는다. 하물며 공망이 파(破)로 되고 말전이 설기(泄氣)하므로 시종 뜻대로 진척되지 않는다. 춘월(春月)에 유(酉)의 월장(月將)일 때는 이번격(二煩格)으로 된다. 일간일지가 교차상생(交車相生)하는 것은 탈골(脫骨)이라고 하여 상담(商談)․교섭(交涉)은 실의(實意)와 유정(有情)으로써 이루어진다. 즉 상대방을 믿는 성의가 있다. 그러나 일간상신은 인목(寅木)에 의해, 일지상신은 유(酉)의 금(金)에 의해 극설되므로 내쪽의 유정이 의혹감을 일으켜 일시 반항한다. 
  백법에 말하기를 ｢서로 생하고 모두 생하는 것은 모든 일이 유익하다｣고 하였다. 일간상신이 인(寅)의 탈기(脫氣)로 되고 천장에 현무가 승하는 것은 탈도격(脫盜格)이라고 부르며 도난이나 분실사건이 발생한다. 점시(占時)가 자오묘유(子午卯酉)의 사중신(四仲神)이면 삼교격(三交格)으로 되고, 사물의 길흉을 연루하여 절(絶)한다. 일귀는 순행하여 삼전이 공(空)․현(玄)․귀(貴)이고, 야귀는 삼전이 구(勾)․백(白)․음(陰)으로 되며, 길 중에 손모가 발생한다.
  삼교(三交) 사중(四仲)의 점시(占時)는 일교(一交), 극하여 사중신만의 삼전은 이교(二交)라고 부른다. 과전 중에 태음(太陰) 또는 육합을 보는 것은 어느 것이나 중장(仲將)으로 되면 진정한 삼교격으로서 모든 일이 왕성하여 분수를 넘으니 불길(不吉)하다. 
  초전이 공망한 것은 과숙(寡宿)이라고 한다. 그러므로 초전의 공망은 참수(斬首)라고 부르며 중전의 공망은 요절(腰折)이라고 부른다. 공망은 공허의 신으로 허(虛)로 될 때는 능히 변화를 일으킨다. 그러므로 공망할 때는 상세하게 추구(推究)해야 한다. 이 과(課)와 같이 초․중전에 흉신이 모일 때는 도리어 공망을 즐긴다는 사실을 잊지 말아야 한다. 공망하면 삼교는 교(交)로 되지 않고 흉이 해소되는 것과 같다.`
  ,
  // 11
  `  이 과는 4과 중에 제1과․제2과․제3과에 모두 상하의 극이 있다. 제3과의 신오(申午)의 상극을 취하여 초전으로 하고 중심과로 된다. 제11국은 어느 것이나 순간전격(順間傳格)이 많다. 즉 1지(一支)를 사이에 두고 순(順)으로 신술자(申戌子)와 삼전을 구성하므로 초․중전은 공망으로 된다.
  일간상신에 정신(丁神)이 승하여 관귀(官鬼)가 재동(財動)으로 되므로 관사(官事)는 길하다. 초전 신(申)의 장생으로 되어도 공망하므로 생(生)하여 생을 이루지 못하며, 중전이 귀살(鬼殺)로 되어도 공망하므로 흉을 보아도 흉하지 않다. 이상과 같이 초․중전이 공망하면 흉사도 이루어지지 않고, 큰 것도 작은 것으로 변화한다. 말전의 자(子)는 일간의 양인(羊刃)․겁재(劫財)로 되며, 현무․백호가 승하여 실재(失財)․손모(損耗)․도난의 우려가 있다. 청룡이 길신을 보아도 공망하므로 재물(財物)이 모두 뜻대로 진척되지 않는다.
  백법에 말하기를 ｢수일(水日)에 정재(丁財)가 동하는 것은 희(喜)․기(忌)가 동시에 발생한다｣고 하였다. 비전에 말하기를 ｢임일(壬日)에 축(丑)이 가해지고 유월(酉月)에 정단하면 일귀(日鬼)가 사기(死氣)로 되고, 태상(太常)이 승하면 내외(內外)에 상복(喪服)을 입을 일이 발생한다. 신(申)에 청룡이 승하는 것은 자손의 일에서 재(財)의 손실을 초래할 수 있다.
  일귀는 순행하여 삼전이 청(靑)․백(白)․현(玄)이고, 둔간하여 자(子)에 병(丙)의 재신(財神)이 승한다. 숨은 재(財)는 얻어도 현무가 승하면 재(財)의 쟁탈, 또는 실재(失財)․도난이 있다. 야귀는 삼전이 합(合)․청(靑)․백(白)이고, 삼전 신술자(申戌子)는 섭삼연격(涉三淵格)으로 되어 병점(病占) 및 소송사건은 흉하다.`
  ,
  // 12
  `  이 과는 제2과의 축자(丑子)가 1상에서 1하를 극하여 원수과(元首課)로 된다. 12국은 어느 날이나 진여격(進茹格)이다. 초전의 축(丑)과 간상의 자(子)는 지합(支合)한다.
  자(子)는 양인(羊刃)․비겁(比劫), 또 백호․현무가 승하여 도난 및 기타 불상사(不祥事)가 발생한다. 초전에 정신(丁神)이 승하고 중전의 자손효(子孫爻)는 재(財)를 도와 암재(暗財)를 얻지만 길 중에 얻어 실재(失財)가 있다. 초전 및 제3과가 관효(官爻)일지라도 삼전에 의해 이것을 제어하므로 흉을 보아 흉에 범하지 않는다. 자축(子丑)이 지합(支合)하고 일지를 충(冲)하므로 지상(支上)과 육해로 되고 화합 중에 피아 상해(相害)를 초래한다.
  백법에 말하기를 ｢나망(羅網)을 만나면 함부로 계획할 때는 졸책이 되므로 조용히 때를 기다리는 것이 좋다｣고 하였다. 피아가 서로 시의(猜疑)를 일으키는 것은 형충해(刑冲害)가 섞이기 때문이다 비전에 말하기를 ｢임자일(壬子日)에 일간상에 자(子)를 보면 처음은 허락하고 후에 돌보지 않는 무정한 행동을 한다｣고 하였다. 원수과(元首課)로서 순기(旬奇)가 용신(用神)을 지키고 정신(丁神)을 만나면 명리를 구하여 모두 성과가 있다.
  일귀는 순행하여 삼전이 음(陰)․후(后)․귀(貴)이고, 야귀는 삼전이 상(常)․현(玄)․음(陰)이다. 간상신(干上神)은 일지(日支)를 극하고 지상신(支上神)은 일간을 극하는 것은 무음격(無淫格)으로, 부부가 서로 이심(異心)을 품고 애매한 행위를 초래하기 쉽다. 이 과(課)와 같이 길흉이 혼합되면 동일한 과전(課傳)이더라도 관점(觀占)할 사건에 따라서 길흉이 다르게 나타난다는 것을 잊지 말아야 한다. 이 과뿐만 아니라 모든 것은 이 이법(理法)에 준하는 것이다.
  순기(旬奇)란 구성구궁(九星九宮)에 해당되지 않는 축자해(丑子亥)이다. 축(丑)은 자(子)에 접하기 때문에 갑자(甲子), 갑술(甲戌)의 순(旬)은 축(丑)을 순기로 하고, 축(丑)은 자(子)에 접하므로 갑신(甲申), 갑오(甲午)의 순(旬)은 자(子)를 순기로 한다. 또 해(亥)는 자(子)에 접하므로 갑진(甲辰)의 순과 갑인(甲寅)의 순은 해(亥)를 순기로 한다. 기(奇)란 귀기(貴氣)로서 흉을 구제하는 작용을 한다.`
  ,
]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
