
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,7,1,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,8,12,`특별있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지 않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,9,11,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,10,10,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,11,9,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,8,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,7,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,2,6,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,3,5,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,4,4,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,3,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,2,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,`색정사有`,`색정사有`],
    [`색정`,`외男내女 동시 색정사 有`,`大`,`단절기`,`증오도망`,`증오가출`],
    [`공처공부(共妻共夫)`,`근친상간의 상`,`大`,``,`皆事성수`,`皆事성수`],
    [`폭객기능(暴客欺凌)`,`폭력배의 기만과 능욕을 당하는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,`동요분산`,`동요분산`],
    [`극남`,`남자 갈등사`,`大`,`8`,`근친상간`,`남편배반`],
    [`여식`,`여식 관련사`,`小`,`침체기`,`침체공허`,`침체공허`],
    [`근친상간(近親相姦)`,`근친상간의 상-共妻共夫사有`,`小`,``,`미초희경`,`미초희경`],
    [`암조능욕(暗遭凌辱)`,`아무도 모르는 능욕박해를 당하는 상`,`大`,``,`(권한受여)`,`(권한受여)`],
    [`후원`,`후원조력문서부친 관련사`,`大`,``,`침체절감`,`침체절감`],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`9`,`면모훼손`,`면모훼손`],
    [`매사 영속성없음`,`후원조력문서부친사는 선천적 기복재해를 겪음`,`小`,`소외기`,`손해도망`,`손해도망`],
    [`구설질병(口舌疾病)`,`구설과 질병에 시달리는 상`,`大`,``,`관재甚흉`,`관재甚흉`],
    [`투서불용(投書不用)`,`투서헌책이 불용되고 금전실재사가 있는 상`,`小`,``,``,``],
    [`결속`,`결속사를 음2월 완성 후원사를 크게 이루나 자녀災害발생`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`10`,`외사단합`,`내사단합`],
    [`매사 영속성없음`,`후원조력문서모친사는 선천적 기복재해를 겪음`,`小`,`침해기`,`부부불화`,`부부불화`],
    [`지체난탈(遲滯難脫)`,`일이 지체되어 어려움을 탈피하기가 힘든 상`,`大`,``,``,``],
    [`가실불화(家室不和)`,`부부 불화의 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`11`,`비용과출`,`비용과출`],
    [`자식`,`자식 관련사`,`小`,`쇠퇴기`,`환성경사`,`환성경사`],
    [`수구생의(守久生意)`,`지금을 오래 지키고 있어야만 좋은 기회를 맞는 상 `,`小`,``,`(허사)`,`(허사)`],
    [`음폐안녕(陰閉安寧)`,`음사를 폐하여 가택가정이 편안한 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`大`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`12`,`외사출행`,`네사출행`],
    [`동료`,`동료 관련사`,`小`,`과시기`,`존비상몽`,`존비상몽`],
    [`유의폐구(惟宜閉口)`,`오로지 입을 굳게 닫는 것이 좋은 상-근친상간사 有`,`小`,``,`(신분나이`,`(신분나이`],
    [`복통수욕(腹痛受辱)`,`복통으로 면모가 훼손되는 상`,`小`,``,`차이음란)`,`차이음란)`],
    [`직녹`,`직위녹위사`,`大`,``,``,``],
    [`자산`,`자산 관련사`,`大`,`1`,`상하화합`,`상하화합`],
    [`권리`,`권리 관련사`,`小`,`절정기`,`동지함의`,`동지함의`],
    [`범사무성(凡事無成)`,`원하는 모든 일이 안 되는 상`,`小`,``,`(안방지켜`,`(안방지켜`],
    [`복통수욕(腹痛受辱)`,`복통으로 면모가 훼손되는 상`,`小`,``,`동작편함)`,`동작편함)`],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`결속`,`결속사를 음2월 완성 후원사를 크게 이루나 자녀災害발생`,`大`,`2`,`외교막힘`,`가정막힘`],
    [`여식`,`여식 관련사`,`小`,`면모기`,`이익진취`,`이익진취`],
    [`불생무익(不生無益)`,`도와줄 듯 싶었으나 불성하여 이익이 없는 상`,`小`,``,``,``],
    [`이익진취(利益進取)`,`여러 방해를 극복하고 이익을 추구하게 된 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`3`,`외사급속`,`내사급속`],
    [`처`,`처 관련사`,`大`,`학습기`,`總是成災`,`總是成災`],
    [`행중불행(幸中不幸)`,`좋은 일 중에 나쁜 일이 발생하는 상`,`小`,``,``,``],
    [`이익진취(利益進取)`,`여러 방해를 극복하고 이익을 추구하게 된 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`4`,`외사수난`,`가정수난`],
    [`여인`,`여인 관련사`,`大`,`개시기`,`간사은닉`,`간사은닉`],
    [`서면재구(庶免災咎)`,`일반인은 재해를 면하게 되는 상`,`小`,``,`(가택이사`,`(가택이사`],
    [`간사은닉(奸邪隱匿)`,`사통하는 연인을 감춰두고 있는 상`,`大`,``,`관계불통)`,`관계불통)`],
    [`박관`,`직업직위 불안사`,`小`,``,`급속송쟁`,``],
    [`극부`,`남편 갈등사`,`大`,`5`,`파재실종`,`반목송쟁`],
    [`자식`,`자식 관련사`,`小`,`배양기`,`상해우려`,`파재극처`],
    [`필송복래(必訟復來)`,`반드시 송쟁사가 다시 발생하게 되는 상`,`小`,``,`總是성재`,`總是성재`],
    [`총시성재(總是成災)`,`전체적인 재난의 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,`단합결여`,`단합결여`],
    [`남편`,`남편 관련사`,`大`,`존재기`,`불의투서`,`불의투서`],
    [`행인래근(행인래근)`,`길 떠난 이가 곧 돌아오는 상`,`大`,``,`금전失재`,`금전失재`],
    [`불의투서(不宜投書)`,`투서헌책이 좋지않은 상-금전실재사 포함`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`관사직업색정사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`직업직위불안남자갈등여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,`형재친구동료조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,`직녹자산권리사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`직업직위불안여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`금전사업처조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`금전사업여인조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`관사 직업 남편 사로 불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인동심협력(近親相姦)**만약 타인과 다툰다면 상대는 같은 처지와 동심을 가졌으므로 조정능력으로 협력이 가능함`,0,0,`근친상간(近親相姦)을 하고 있는 人`,0,0,`가정에 추행이 있음**모든 일이 뜻대로 되지 않음**윗인이 아래인을 속임**독신고독각방주말붑생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인동심협력**만약 타인과 다툰다면 상대는 같은 처지와 동심을 가졌으므로 조정능력으로 협력이 가능함`,0,0,`협력동심의 人`,0,0,`어떤 일도 비용만 과출되고 성사되지 못함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인동심협력**만약 타인과 다툰다면 상대는 같은 처지와 동심을 가졌으므로 조정능력으로 협력이 가능함`,0,0,`협력동심의 人`,0,0,`어떤 일도 비용만 과출되고 성사되지 못함**산으로 깊이 숨고싶은 심정임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속전속결하는 것이 유리함`,0,0,`명분이 있고 정당하므로 기선을 제압하여 결론에 이르러야만 유리하고 좌고우면하거나 미결처리하면 결과가 불리해지는 人`,0,0,`복잡다단한 여러 일을 하나로 결속시키려 함-음2월에 완성하게 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안동요선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`동료와의 동업관계로 중상모략적 의혹을 받아 동요불안한 상태이나 가장 친하고 가까이 거주하는 자와 의논하면 해결될 人`,0,0,`윗인이 아래인을 핍박하나 권위가 없음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인동심협력(近親相姦)**만약 타인과 다툰다면 상대는 같은 처지와 동심을 가졌으므로 조정능력으로 협력이 가능함`,0,0,`근친상간(近親相姦)을 하고 있는 人`,0,0,`동요불안분산사有**일이 결과없이 반복되기만 함**처가 남편을 배신하는 상**외다리로 걷는 격으로 일이 모두 불성함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`관직자는 승진영전함-단, 음2월`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유순한 처신으로 사안을 지체연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유화적인 언행으로 결정을 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`복잡다단한 여러 일을 하나로 결속시키려 함-음2월에 완성하게 됨**일이 마침내 이루어지는 상-단 음2월이라야 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인동심협력**만약 타인과 다툰다면 상대는 같은 처지와 동심을 가졌으므로 조정능력으로 협력이 가능함`,0,0,` 근친상간사가 있는人`,0,0,`산으로 깊숙히 숨어버리고 싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유순한 처신으로 사안을 지체연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유화적인 언행으로 결정을 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**구설사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유순한 처신으로 사안을 지체연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유화적인 언행으로 결정을 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`일이 순서적으로 진행됨**남자이면 따로 여자를 두고 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 엿보고 행동하지는 않음`,0,0,`스스로 유약하다고 여기고 적극 활동을 못하며 신음하는 人-신체아 억압된 경우도 있음`,0,0,`토지가옥사의 송쟁사가 있음**가택이사함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`이외사`,`기사`,`신구중간사`,`돌연사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`기사`,`구사`,`돌연사`,`표면사`,`좌고우면`,`선성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`미사`,`신사`,`돌연사`,`표면사`,`배신`,`보성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아사타사동시사`,`기사`,`신구중간사`,`아외사타내사겹침`,`아표면타표면겹침`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사타발동급속사겹침`,`미사`,`신구중간사`,`아후회사타급속사겹침`,`아이면사타이면사겹침`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`기사`,`신사`,`돌연사`,`모호사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사타발동급속사겹침`,`기사`,`신사`,`아외사타내사겹침`,`아이면후회사타이면급속사겹침`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사타발동사겹침`,`미사`,`신사`,`아외사타내사겹침`,`아표면사타표면사겹침`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`미사`,`신사`,`돌연사`,`모호사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동타발동사겹침`,`미사`,`신구중간사`,`아외사타내사겹침`,`아타표면사`,`충성-무력`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아타발동동시사`,`미사`,`신사`,`아외사타내사동시가`,`이표면타표면동시사`,`좌고우면-무력`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아타발동동시사`,`미사`,`구사`,`아타외내사동시사`,`아후회사타급속사동시사`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [` 쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음9월`,3,3,6,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음5월`,3,1,4,`음11월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음4월`,2,2,4,`음10월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월-연기 또는 파혼우려`,1,1,2,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월-연기우려`,2,1,3,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음4월`,3,2,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음3월`,3,1,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음10월`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음11월`,3,2,5,`음5월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음10월`,2,3,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하나 초반의 실패를 회복하여 중 종반에 큰 업적을 보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯하나 조정능력이 좋아 초반을 부진을 벗어나서 중 종반의 실적이 돋보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무도 수준이하로 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것 같으나 조정능력이 좋아 복잡한 여러 사안을 음2월에 결속 완료하여 큰 성과를 이룸 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무도 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 업무도 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하나 음2월에 권리사를 고양시켜 권위를 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 복잡다단한 여러 사안을 일관시키는 결속을 통해 실적을 크게 올림-단 음2월이라야 완성됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 초반의 실적 외 충 종반에는 수준을 이루지 못함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 업무 초 중 종반 모두 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무 내내 토지건물송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후이`,`進向`,`원`,`留`,`주인조력`,`남남서`,``,`북북동`],
    [`10.11`,``,``,``,``,``,``,``,`화합`,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후이`,`進向`,`  원`,`  留`,`주인조력`,`남서`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인제압`,`이행`,`선난후이`,`退背`,`  근`,`  去`,`무난`,`정서`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세없음`,`이행`,`본인사안`,`退背`,`   원`,`  去`,`주인무력`,`북서`,`정북`,``],
    [`10.11`,``,`다중제압`,``,`무력함`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`끌면제압`,`이행`,`본인사안`,`退背`,`원근왕래`,`  去`,`주인무력`,`동남`,`북북서`,``],
    [`10.11`,``,`불가중반`,``,`무력함`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`제압필요`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선난후이`,`退背`,`  원`,`  去`,`상호동요`,`남남동`,`정북`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선난후이`,`退背`,`  근`,` 去`,`세입가해`,`북북동`,`북북서`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`본인내부`,`이행`,`선난후이`,`退背`,`  근`,`  去`,`세입가해`,`남남서`,`정서`,``],
    [`10.11`,` 多集`,`반대`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`다중제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`다수제압`,`이행`,`선난후이`,`退背`,`  근`,`  去`,`상호반목`,`남서`,`북북서`,``],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인동조`,`이행`,`선攻후이`,`進向`,`  근`,`  留`,`세입조력`,`동남`,``,`북서`],
    [`10.11`,``,`끌면제압`,``,``,``,``,``,`설기피로`,``,``,`세밀심사`],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대없음`,`이행`,`선攻후이`,`進向`,`  원`,`  留`,`세입조력`,`남남동`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후이`,`退背`,`  근`,`  去`,`무난`,`정남`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,`함`,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`신부정귀`,``,"신不定",0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`여귀`,`가장부인`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`여귀`,`가장부인`,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`신부정귀`,`신不定`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`신부정귀`,`신不定`,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,` 음귀`,`妾婦사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,``,`도적의혹근심사`,`외남내녀 동시색정사`,`경의불녕-금전실재근심사`,`외내동요불안분사사`,`승진영전사`,`매사 허성불성-세속허사자**관직자 생살대권 쥠-부족`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음`,`종교진출하면자립함`,``,`중중희애사`,``,`관직자영전도약-일반인 흉`,`외내직녹권리상하화합사`,`매사 성취사`,`외내생업가정막힘`,`불의투서-구설사-이동사`,`총시성재사`,`내와 급속사`,``,`간사은닉-가택이사-관계불통`,`외내급속송쟁사-상해우려`,`탄식신음사-병 또는 모사미수 원인`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**관재`,``,`빈궁**관재`,``,`전도`,`일반인 관재 대흉`,`빈궁**병재`,`폭객기능사`,`빈궁`,`전화위복`,`전도`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,``,`전도**빈궁`,`빈궁**병재`,`재액해소`,``,`재액해소`,`침체驚懼사`,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**외내급속송쟁사-상해우려`,`재난전소사`,``,`투서헌책불용-금전실재사`,`외남내녀동시색정사`,`부부반목사`,`외내동요불안분산사`,`남 모르는 능욕박해사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`매사 위역사`,`관재형벌 대흉`,``,`권한수여-미초희경사`,`외내직녹권리상하화합사`,`개배지봉-군자경사`,`외내생업가정막힘`,`觀書-문화풍아-이동사`,`우유한가-실업자 상`,`내외 급속사`,``,`간사은닉-가택이사-관계불통`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`전화위복`,`전도**빈궁**관재`,``,`전도**관재`,``,`빈궁`,`고위안좌-부족`,``,`폭객기능사`,`빈궁`,`전화위복`,`병재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**관재`,``,`빈궁`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`간사은닉-가택이사-관계불통`,`매사 위역사**외내급속송쟁사-상해우려`,`재난전소사`,``,`투서헌책불용-금전실재사`,`외남내녀동시색정사`,`부부반목사`,`외내동요불안분산사`,`남 모르는 능욕박해사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`매사 위역사`,`관재형벌 대흉`,``,`권한수여-미추희경사`,`외내직녹권리상하화합사`,`개배지봉-군자경사`,`외내생업가정막힘`,`觀書-문화풍아-이동사`,`우유한가-실업자 상`,`내외 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재액해소`,`빈궁`,`전화위복`,`빈궁**관재`,``,`관재`,`재액해소`,`빈궁`,`고위안좌-부족`,``,`폭객기능사`,`빈궁`,`재액해소**전화위복`,`빈궁**병재`,`음4,5월화재주의`,`전도**관재`,`음4,5월화재주의`,`전도**빈궁**관재`,`재액해소`,`빈궁`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`도적실세-잡음`,`내외 급속사`,``,`음사폐호-가택안녕`,`탄식신음-병 또는 모사미수사 원인`,`외내급속송쟁사-상해우려`,``,`재난극복-이익진취사`,`외남내녀동시색정사`,`눈이 가려진상`,`외내동요불안분산사`,`불리 고시사-구설사`,`매사영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`중중희애사`,`복통수욕사`,``,`외내직녹권리상화화합사`,`앙화소멸사`,`외내생업가정막힘`,`술잔내려받는 미초희경사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,`재액해소`,`빈궁`,`재액해소`,`전도`,``,`전화위복`,`관재`,``,`빈궁**관재`,``,`전도**빈궁`,`환성경사-부족`,``,`부부불화사`,`빈궁`,`전화위복`,`전도관ㅈ`,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**병재`,``,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`외내생업가정막힘`,`술잔내려받는 미초희경사`,`도적실세-잡음`,`내외 급속사`,`음사폐호-가택안녕`,``,`탄식신음-병 또는 모사미수사 원인`,`외내급속송쟁사-상해우려`,``,`재난극복-이익진취사`,`외남내녀동시색정사`,`눈이 가려진 상`,`외내동요불안분산사`,`불리 고시사-구설사`,`매사 영속성없음**외내동요불안분산사`,`종교진출하면자립함`,`매시 영속성없음`,`종교진출하면자립함`,``,`중중희애사`,`복통수욕사`,``,`외내직녹권리상하화합사`,`앙화소멸사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**병재`,``,`빈궁**관재`,``,``,``,`전화위복`,`관재`,``,`빈궁**관재`,``,`빈궁`,`환성경사-부족`,`전도`,`부부불화사`,`전도**빈궁`,`전화위복`,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`身물수손사`,`외내직녹권리상하화합사`,`외내외내생업가정막힘`,`왕래사송사-이동사`,``,`내외 급속사`,`관재 병질사`,`혼인단장사`,``,`매사 위역사**외내급속송쟁사-상해우려`,``,`재난극복-이익진취사`,`외남내녀동시색정사`,`동지함의-안방지켜 자유로움`,``,`존비상몽사-신분나이차이가 큰 음란`,`매사 영속성없음**도적못잡음`,`종교진출하면자립함`,`매사 영속성없음**재물유실사`,`종교진출하면자립함`,`관재형책 대흉`,`매사 위역사`,`복통수욕사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`음4,5월화재주의**재액해소`,``,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`전화위복**재액해소**동요분산`,``,`동요불안분산사**재액해소`,``,``,`외내-동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`동요불안분산사**전화위복`,``,`음4,5월화재주의**재액해소`,`빈궁**동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`복통수욕사`,``,`身물수손사`,`외내상하화합사`,`외내직녹권리외내생업가정막힘`,`왕래사송사-이동사`,``,`내외 급속사`,`관재 병질사`,`혼인단장사`,``,`매사 위역사**외내급속송쟁사-상해우려`,``,`재난극복-이익진취사`,`외남내녀동시색정사`,`동지함의-안방지켜 자유로움`,`존비상몽사-신분나이가 큰 음란`,`외내동요불안분산사`,`매사 영속성없음**도적못잡음`,`종교진출하면자립함`,`매사 영속성없음**재물유실사`,`종교진출하면자립함`,`관재형책 대흉`,`매사 위역사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`전도**관재`,``,`전도**빈궁`,``,`빈궁`,``,`관재 `,`전화위복**재액해소`,`관재`,`재액해소`,`빈궁`,``,`빈궁`,``,``,``,`전화위복`,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관재형책 대흉`,`매사 위역사`,`복통수욕사`,``,`身물수손사`,`외내상하화합사`,`외내직녹권리생업가정막힘`,`왕래사송사-이동사`,``,`내외 급속사`,`관재 병질사`,`혼인단장사`,`재난전소사`,`매사 위역사**외내급속송쟁사-상해우려`,``,`재난극복-이익진취사`,`외남내녀동시색정사`,`동지함의-안방지켜 자류로움`,`존비상몽사-신분나이 차이가 큰 음란`,`외내동요불안분산사`,`매사 영속성없음**도적못잡음`,`종교진출하면자립함`,`매사 영속성없음**재물유실사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복**재액해소`,`빈궁**관재**병재`,`전도**빈궁`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`빈궁`,`재액해소`,``,``,`빈궁`,``,`빈궁`,`재액해소`,`관재`,`전화위복`,`관재`,``,`빈궁`,`재액해소`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`매사 영속성없음`,`종교진출하면자립함`,`관재형책 대흉`,`매사 위역사`,`복통수욕사`,`身물수손사`,``,`외내직녹권리상하화합사`,`외내생업가정막힘`,`왕래사송사-이동사`,``,`내외 급속사`,`관재 병질사`,`혼인단장사`,`재난전소사`,`매사 위역사**외내급속송쟁사-상해우려`,``,`재난극복-이익진취사`,`외남내녀동시색정사`,`동지함의-안방지켜 자유로움`,`존비상몽사-신분나이차이가 큰 음란`,`외내동요불안분산사`,`매사 영속성없음**관직자 생살대권 쥠-부족`,`종교진출하면자립함**도적못잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재물유실사`,``,`전화위복**재액해소`,`빈궁**병재`,`전도**빈궁`,`음4,5월화재주의`,`전도`,``,`빈궁`,`재액해소`,``,``,`빈궁`,``,`빈궁`,`재액해소`,`관재`,`전화위복`,`관재`,``,`빈궁`,`재액해소`,`일반인 관재형책 대흉`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**관직자 생살대권 쥠-부족`,`종교진출하면자립함`,``,``,``,`관직자 영전도약-일반인 흉`,`매사성취사`,`외내직녹권리상하화합사`,`외내생업가정막힘`,`불의투서-구설사-이동사`,`총시성재사`,`내외 급속사`,`외사급속송쟁사-상신우려`,`간사은닉-가택이사-관계불통`,``,`탄식신음-병 또는 모사미수사 원인`,``,`도적의혹-근심사`,`외남내녀동시색정사`,`경의불녕-금전실재근심사`,`외내동요불안분산사`,`승진영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**병재`,``,`폭객기능사**일반인 관재 대흉`,`전도**빈궁`,``,`음4,5월화재주의**전화위복`,`빈궁`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,``,`빈궁`,``,`빈궁**병재`,``,``,``,``,`빈궁**관재`,`전화위복`,``,`빈궁**관재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외내동요불안분산사`,`승진영전사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 허성불성-세속허사자**관직자 생살대권 쥠`,`음1,2,10,11월 방문자는 종교진출하면성공함`,``,``,``,`관직자 영전도약-일반인 흉`,`매사성취사`,`외내직녹권리상하화합사`,`외내생업가정막힘`,`불의투서-구설사-이동사`,`총시성재사`,`내외 급속사`,`외사급속송쟁사-상해우려`,`간사은닉-가택이사-관계불통`,``,`탄식신음-병 또는 모사미수사 원인`,``,`도적의혹-근심사`,`외남내녀동시색정사`,`경의불녕-금전실재근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**빈궁**병재`,``,`폭객기능사**일반인 형벌 대흉`,`전도**빈궁`,`전화위복`,``,`빈궁`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁**병재`,``,``,``,``,``,`폐한인**전화위벅`,`빈궁**관재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외남내녀동시색정사`,`경의불녕-금전실재근심사`,`외내동요불안분산사`,`승진영전사`,`매사 허성불성-세속허사자**관직자 생살대권 쥠`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,``,``,``,`관직자 영전도약-일반인 흉`,`매사성취사`,`외내직녹권리상하화합사`,`외내생업가정막힘`,`불의투서-구설사-이동사`,`총시성재사`,`내외 급속사`,`외내급속송쟁사-상해우려`,`간사은닉-가택이사-관계불통`,``,`탄식신음사-병 또는 모사미수사 원인`,`전도**관재**빈궁`,`도적의혹-근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**관재**빈궁`,``,``,``,`일반인 관재 대흉`,`빈궁**병재`,`폭객기능사`,`빈궁`,`전화위복`,``,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁**병재`,``,``,``,``,``,`전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■토지 때문에 소송 붙었네?     자식 혼인사도 앞두고 
  있겠고… 
  돈이 많이 없어지겠어!     그래도 돈 복이 있어서     가만히 있어도 돈이 생기네!
`,`■ 두 사람의 동심․동업사가      있겠는네? 비용만 나가고      일은안되겠어! 집도 넘어 가      겠고…

⊖가정에上下근친상간사가    벌어져 있네?

⊕신변상가부정이 침범했네?
`,`■ 일이 재발되고 있네?

⊕구설 
⊖박해건이고 부부가 배반되    겠어! 
  두 사람이 하는 변동이나    계획사도 달성이 안되겠고!
`,`■ 옛일이 재발되어 관재가     붙겠네? 
   부부배반 될 조짐도 보이고     일도 기대가 안되네! 
   비용 줄이는게 상책이야!
⊕⊖신변 목기부정 침범이        들었네?
`,`■ 다인 다사건을 하나로 묶     지만 지금은 도움 받을      일이 전혀 기대가 안되네? 
   번잡하기만 하겠어!
⊕신부정귀 
⊖변사귀를 해소해야 벗어날    수 있겠어!
`,`■ 마음은 불안하고 일은 
   선택의 기로에 섰겠어?

⊕신부정귀 
⊖변사귀를 없애고 때를 기다려야 겠어!
`,`■마음이 두가지로 갈려      있겠네? 
  일도 반복되기만 하겠고…     육친 상하가 불안하네! 
  직접 나서지 말고 남을 앞    세워야 일이 되겠어!

⊕가정에 근친상간사도 있고 !
`,`■ 가정내부 금전․여자가
   문제네?
   의심․질투건이 발생 했     겠어! 두사람이 마음을      합해도 비용만 나가겠어!
⊕여귀 
⊖자살귀 때문이야! 
  방법은 귀신이야!
`,`■여러개를 하나로 묶네 !
  가지 많은 나무에 바람 잘날    없다더니 그 지경이겠어?
⊕ 신부정귀 
⊖음귀를 달래야만 입신이든   자손사든 구슬을 꿰게 
 생겼어!
`,`■관재․극부사가재발했네? 
   부부 배반사도 발생하네!
⊖가정에서 上下밀통, 근친상간    사가 있게 생겼고… 
⊕신부정귀 ⊖음귀를 잡아쥐야    난을 면하지!
⊖신변수망노부부정 침범
⊕신변음사부정이 침범했네?
`,`■ 일은 막히고 돈도 날아가고     구설만 남았겠네?
   부부가 똑같이 바람까지     나겠어! 

⊕신부정귀 
⊖음귀 해소가 급선무겠어!
`,`■ 여자둘이 한 남자를 모시고     사는 격일세? 그러니 한
   여자(妾)는 애낳고  가겠네! 
   9월에 임신 하겠고! 
   일도 처음에는 분명해도       나중에는 흐려지겠어! 

⊖신변음사부정이 침범했네?`];
  const YOGYEOL1 = [`■ 조용하게 있다가 움직이니 남을 너무 믿지 말라. 밤정단은 혼인비용을 많이 쓰게되고 기다리는 사람은 곧 오리라.
  `,`■ 사람과 집에 범이 가득하니 놀람과 두려운 일이 있다. 손해나거나 고민사가 많이 생기고 이와 같은 일을 면하기 어렵겠다.
  `,`■ 깨지는 줄만 알았더니 해치는 것이 또 숨어 있다. 네 마리 역마가 일어나도 낮정단은  이와 같고 모두 비웠으니 허망하다.
  `,`■ 혼자 벌어 네 사람을 먹인다. 밤정단은 이런 가운데 놀래는 일을 겪는다. 낮정단을 귀인의 도움을 받기 어려우니 집은 어둡고 사람은 고통스럽다.
  `,`■ 삼전은 돕고 천장은 뺏어가니 낮정단은 좋고 나쁨이 반반이다. 
  삼전의 돕는 것을 너무 믿지 마라. 옳지 않은 것은 바꿔야 길하다.
  `,`■ 낮정단은 귀인의 덕을 보기 어렵다. 밤정단도 마찬가지로 비었음을 알아야 한다. 나를 돕는자가 있어도 자세히 보니 별 것 아니다.
  `,`■ 내가 손해날 일만 생기니 모든 점이 그렇다. 숨은 귀살이 모르게 해치니 입을 다물어야 면한다.
  `,`■ 나를 상대가 구해준다. 여우가 범 가죽을 쓰고 행세하니 한번 움직였다하면 재앙의 틀을 밟게 되리라.
  `,`■ 낮정단은 천장이 내 힘을 빼앗으나 삼전이 도와 길흉이 반반이다.
  귀ㆍ덕이 삼전에 있으나 밤정단은 무슨 이익이 있을까보냐.
  `,`■ 낮정단은 묘가 충파되어 불행중 다행한 일이 있겠다. 밤정단은 도리어 귀인이 상하니 다행중 불행이 있을 것이다.
  `,`■ 丁火는 酉에서 죽고(※) 파쇄살은 癸丑이 된다. 두 귀인이 서로 같이 있으니 위태로운 일을 만나거든 입을 다물어라.
  `,`■ 금전을 얻었다가 다시 잃는다. 음9월 점은 처의 임신이 있고 중풍과 천식ㆍ폐렴을 조심하라. 소송은 끝났다가 재발한다.`]
  const SIBO1 = [`■ 여러건의 토지사로 
  소송이 일어나 고심한다.
  그러나 조용히 현상을 
  유지하여야 한다.
  비용도 과다하게 지출되나 다시 득재할 수 있게 된다. 丑月 정단은 불행사가 있고 이 일로 득재한다. 결혼ㆍ경사 비용이 든다. 입조심해야 한다.
  `,`■ 동기ㆍ동심은 줄을 섰으나
  구재는 불가하고 동업사도
  완성되지 못한다.
  신중해야 복록이 풍부해진다. 별리사가 있고 부인이 배반하게 된다.
  ⊖上下의 밀통사건ㆍ근친상간사가 있다. 공처공부(共妻ㆍ共夫)이다. 냉정해야 한다. 
  부모 재해가 기한다.
  `,`■ 인ㆍ택이 모두 변동수가 있다.
  ⊕구설, ⊖전토분쟁사가 있다.
  두 사람이 同心으로 일을 꾀하나 비용만 나고 성공하지 못한다. 별리사가 있고 부인이 배신한다.
  
  
  `,`■ 가정에 ⊕목기부정, 
  ⊖상가신살 부정이 들어
  ⊕금전, ⊖질병으로 소모가
  많고 놀라게 된다. 기도ㆍ치성이 좋다. 부인과 피차 권리가 같아 上下구분이 없고 무례한 배반사가 기한다. 득재하여도 다시 출비된다. 먼곳으로 떠나고 싶어하고 그것이 吉하다.
  `,`■ 여러건의 후원사가 있으나
  기대가 안된다.
  원조도 없다.
  남자가 떠나고 만사가 한산한다. 유명무실하다.
  몸을 낮추고 음적으로 처신해야 한다. 
  귀신을 해소해야만 재기를 도모할 수 있다. 
  부부가 모두 음탕하다.
  다인 다사건이다.
  `,`■ 스스로 충기자만하여
  고생의 씨를 뿌리고 
  있는 상이다. 불안하다.
  어떤 일도 교묘한 듯하나 졸책이 된다. 
  운수에 맡기고 때를 기다려야 한다. 여자가 떠나게 된다. 
  선택의 기로에 섰다.
  친ㆍ근자로 결정하라.
  부모의 재해가 있다.
  `,`■ 저주를 받고 있다.
  소모와 허사ㆍ허탈을
  방지해야 한다.
  큰것을 원하나 작은 것을 얻게 되고 마음이 두갈래이고 옮겨지고 바뀌는 운세에 있다. 불안하다.
  몰래 계획하고 지름길로 전진해야 한다. 
  의심ㆍ우려사가 있다.
  ⊕근친상간사가 있다.
  `,`■ 자충수적 우환이 있다.
  가내 우환이 있고 가장을
  속이며 가인이 원한을 품고
  가해하려 한다. 그러므로 망동해서는 안된다. 여자가 문제이다. 일도 정체되는 운세이므로 근신해야 한다.
  ⊕주방의 훼손이 있다.
  ⊖우물이나 물가에서 재난이 일어난다.
  正月 임신사가 있다.
  `,`■ ⊕신불의 진노를 받고 있다.
  ⊖여인의 구해를 받고 있다.
  다인 다사건이다.
  치성ㆍ고사하고 집을 고치거나 이전하거나 전직하면 吉이 된다. 그대로 두면 시작은 있으나 끝이 없게 된다. 구태의연할 뿐이다. 또, 임신ㆍ자손사 문제가 있으나 운세의 길흉은 신불에 있다.
  `,`■ 가정에 ⊕음사부정, 
  ⊖수망노부 부정이 들어
  두 사람이 합동하여
  일을 꾀해도 되는 일이 없고 비용만 축난다. 관재도 기한다.
  ⊖여인이 배반하고 특히 근친상간사를 벌이며 난잡하고 수치를 모르게 된다. 이 부정을 해소시키면 길조를 얻게 된다.
  `,`■ ⊕금전ㆍ여인ㆍ구설
  ⊖간사은닉사가 있다.
  부부가 함께 음사를 벌이고 있다. 군자는 흉하고 저급인에게는 좋다.
  부주의로 실착을 초래하고 피차 의심하게 된다.
  단, 관직ㆍ공명ㆍ구직ㆍ입시건은 최고의 길조이다.
  `,`■ 집안에 어른의 재화가 
  있게 된다.
  ⊖음사부정이 들었다.
  처ㆍ첩이 함께 산다.
  금전사는 전진해야 좋고 연속되며 반드시 이루어진다. 여인사도 그렇다.
  그러나 처음은 분명해도 나중에는 불분명하다.`]
  const SIBO2_1 = [`■자손ㆍ박관內外손모사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■사업ㆍ후원,內外파탄사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  가택권리가 양동된다.
  `,`■內外골육상배동요사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  순역이 혼란하다.
  `,`■官ㆍ夫.이별ㆍ협력사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■사업ㆍ후원內外단합사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  후원사 난망하고 부모재액 이 있다.자손재해 예방필요
  `,`■財ㆍ女.선택ㆍ기로사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  부부간사 악의의 상이다.
  `,`■손모반복二心사이다.
  내방조짐 상의 : 
  원행·이동사가 있다흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다
  `,`■손모ㆍ후회사이다.
  내방조짐 상의 :  
  원행·이동사가 있다흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다
  `,`■官･夫,방침사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 자손 재해에방이 필요하다.
  官･夫, 여하사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女 內外사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  求財․女 신속하고 
  실기하면역재난 된다.
  `,`■財ㆍ女,전진여부이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  처첩 동거의 상이다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/044/row-1-column-1.png`,
    `/static/images/sagwa/044/row-1-column-2.png`,
    `/static/images/sagwa/044/row-1-column-3.png`,
    `/static/images/sagwa/044/row-1-column-4.png`,
    `/static/images/sagwa/044/row-1-column-5.png`,
    `/static/images/sagwa/044/row-1-column-6.png`,
    `/static/images/sagwa/044/row-1-column-7.png`,
    `/static/images/sagwa/044/row-1-column-8.png`,
    `/static/images/sagwa/044/row-1-column-9.png`,
    `/static/images/sagwa/044/row-1-column-10.png`,
    `/static/images/sagwa/044/row-1-column-11.png`,
    `/static/images/sagwa/044/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 천지신이 본궁에 엎드렸으므로 복음과이며, 극이 없으니 음일에는 일지상신을 초전으로 하고, 중 ․말전은 충형(冲刑)을 사용한다. 3전계신(季神)은 가색격(稼檣格)이다. 정신(丁神)이 일간일지 초전에 승한다. 고요함이 극하여 동의가 일어난다. 음일복음(陰日伏吟)은 자신격(自信格)이다. 스스로 유(柔)하다고 믿으므로 사물에 간여하지 않는다.
    일간에서 과전이 전부 설탈되니 출비 출재가 많다. 축미(丑未)의 충이 있어 개묘로 되니 정체는 자연히 열릴 수 있다. 축월(丑月)은 태상이 앉아서 월파(月破)로 되니 불행사가 있고, 불행한 가운데 재를 획득한다.
    백법에 ｢임신(任信) 정마(丁馬)는 언행을 삼가야 한다｣고 하였다.
    비전에는 ｢3전이 순(純) 자손효이면 재를 구하지 않고도 자연히 득재할 기회를 얻는다｣고 하였다. 기다리는 사람은 3전에 묘신이 병립한다. 말전이 천후에 탈기되면 결혼․경사 등의 비용이 막대하다. 일간이 전탈(全脫)되는 것은 조용히 현상을 유지하는 것이 좋고, 함부로 움직이면 물심양면으로 소모를 면할 수 없다.
    일귀는 순행하며 3전은 상(常)․주(朱)․후(后)이고, 야귀는 역행하여 3전이 주(朱)․상(常)․후(后)이다. 이 과는 공명․관직에 가장 불리하다.`
    ,
    // 2
    `  이 과는 4과 중에 상하의 요극이 없고 음일이니 일지 음신의 역수(逆數) 3위지 묘(卯)를 사용하여 초전으로 한다. 중․말전은 일간일지상신으로써 팔전유박과(八專帷薄課)이다.
    초전이 공망으로 과숙격이며, 과전이 모두 겁재(劫財)․비견(比肩)으로 줄을 섰으나 구재는 얻지 못하고 재가 완전할 수도 없다. 팔전과(八專課)는 모든 일의 사정이 오로지 하나로 돌아간다. 
    일간이 녹왕으로 되는 것은 맹동(猛動)을 두려워하며, 신중히 행동한다면 도리어 후에 복록이 풍요롭게 된다. 과전은 전부 화기(火氣)가 지나치고 흉장이 줄을 서 있다. 따라서 불의 재액을 예방해야 한다. 비겁(比劫)이 중첩된 것은 처재(妻財)를 극하고, 흉장이 승하여 형제의 신상에 재해를 초래한다. 초전 공망에 천공이 앉으면 빈소리․공론으로 끝난다.
    백법에 ｢녹이 일지에 임하는 것은 권섭부정격(權攝不正格)이다｣고 하였다. 신(身)에 녹왕(祿旺)이 앉은 것은 함부로 망동하는 것을 꺼린다. 팔전불수유박격((八專不修帷薄格)에서 부부 및 결혼을 정단하면 부정(不貞)한 사건이 일어나고, 시부모가 있다면 공처(共妻)․공부(共夫)의 난행이 있다. 일간일지상신의 녹왕은 스스로 강권(强權)으로 되어 나서려는 의도가 있으니 냉정하게 움직이는 것이 좋다. 
    일귀는 순행하며 3전은 구(勾)․백(白)․백(白)이고, 야귀는 역행하여 3전이 공(空)․합(合)․합(合)이다.`
    ,
    // 3
    `  이 과는 4과에 극이 없고 음일이니 4과의 역수(逆數) 3신(三神)의 축(丑)을 초전으로 하며, 4과의 간지가 모두 사묘(巳卯)로 되니 2과 밖에 없으므로 팔전과(八專課)로 된다. 초전의 축(丑)은 묘(卯)의 공망에 앉아 고과격(孤寡格)이다.
    일간일지상신의 사(巳)는 역마로 되어 인택이 모두 동의(動意)가 생겨 밤낮으로 분주히 활동한다. 일간에서 축(丑)은 설기로 되고 주작이 승하니 구설이 일어나며, 구진이 승하니 전토(田土)로 인하니 쟁의가 일어난다.
    백법에 ｢초전이 공망하고 4과의 음신(陰神)이 또 공망을 만나므로 사물을 추구하지 마라｣고 하였다. 
    일귀는 순행하며 3전은 주(朱)․공(空)․공(空)이며, 야귀는 3전이 구(勾)․상(常)․상(常)이다,
    앞의 국에 이어 팔전과(八專課)에 대해 해설한다. 팔전과이면서 천후(天后)․육합(六合)․현무(玄武)․태음(太陰) 중의 1장(一將)이 3전 중에 있으면 유박불수격(帷薄不修格)으로 된다. 팔전(八專)은 음양신이 함께 있으면 남녀가 혼잡되고 후(后)․합(合)을 만나면 음사(陰私)의 신이므로 음풍(淫風)이 가장 심하다. 4과에 2과가 결여되니 2과뿐이며 두 사람이 동심하는 형상이다. 양일(陽日)이면 신속하게 전진하고 음일이면 남편을 배반한다. 사물이 퇴축되고 지연되며 길흉이 중첩된다. 존비(尊卑)가 한 방에 있어서 상하를 구분하지 않으며, 만일 4과 상하에 극이 있다면 스스로 제박자성(制縛自省)하므로 음행(淫行)이라고 논하지 말아야 한다. 극이 없는 것은 상하내외를 가리지 않고 간사음욕(奸邪淫浴)의 난행이 있다. 부처 점혼의 정단은 부정(不貞)이 있다. 남자는 수치(羞恥)를 모르고 여자는 지켜야 할 일을 알지 못한다. 부자동처(父子同妻)하고, 고부(姑婦)가 공부(共夫)하는 형상이다.`
    ,
    // 4
    `  이 과에는 상하의 극이 없고 4과이지만 2과뿐이다. 그러므로 팔전과(八專課)로 되고, 음일은 제4과 역행 3위의 지(支)인 해(亥)를 사용하여 초전으로 한다.
    4과에 탈기가 많고 백호가 승할 때는 소모되는 일과 놀라운 일이 겹쳐 일어난다. 초전은 귀효(貴爻)에 귀신이 승하나 공망에 앉으니 귀(貴)에 의지하기 어렵다. 4과에 극이 없는 것은 유박불수격(帷薄不修格)이라 부르는데, 피차의 권리가 같아서 상하의 구분이 없고 예의가 바르지 못하다.
    백법에 ｢귀살이 천을에 승하는 것은 신기격(神祗格)이며, 참관격(斬關格)은 먼 곳으로 가면 이익을 본다｣라고 하였다. 중․말전이 설기로 되니 재를 구하여 출재한다. 
    일귀는 순행하며 3전은 귀(貴)․청(靑)․청(靑)이고, 야귀는 3전이 주(朱)․백(白)․백(白)으로 괴이한 일이 거듭 일어난다.`
    ,
    // 5
    `  이 과는 4과 중에서 제3과가 1상이 1하를 극하여 묘미(卯未)를 초전으로 하는 원수과(元首課)이다. 4과가 다 공망하고 초․중전이 또 공망으로 되어 말전이 홀로 실(實)이다. 묘는 일간을 생하나 공망하여 생을 보고 생하지 않으며, 3전이 4과를 떠나지 않으니 회환격(廻環格)이다. 3합이 전부 3합목국(三合木局)이 되면 모든 일이 허한 사건이지만 생기․귀덕이 모두 공망하므로 원조를 얻지 못한다. 격국은 길흉을 이루지 않아 만사가 한산(閑散)한 형상이다.
    백법에 ｢공상공(空上空)을 만나는 것은 만사를 추구할 것이 못된다｣고 하였다. 사람은 많으나 거택은 상극하므로 좁다는 것을 알아야 한다.
    비전에는 ｢일간일지상신이 함께 공망하니 낡은 일은 정체되고 새로운 것은 폐한다｣고 하였다. 모든 일이 유명무실하게 끝난다.
    일귀는 순행하며 3전은 구(勾)․귀(貴)․상(常)이고, 야귀는 3전이 공(空)․주(朱)․음(陰)이다.`
    ,
    // 6
    `  이 과에서는 제2과․제3과․제4과에서 모두 하를 극하고 음일이니 음비의 극을 사용하여 유인을 초전으로 하니 지일비용과(知一比用課)이다. 초전 공망은 고진격(孤辰格)이다.
    일간상신은 생기일지라고 공망하므로 생하여도 생하지 않아 이익을 이루지 않는다. 초전이 재신으로 되고 생기가 일간을 생하나 공망하기에 공망을 벗어난 뒤에야 재를 소득할 수 있다.
    3전 유진해(酉辰亥)는 모두 자형(自刑)의 지(支)로서 스스로 고생할 씨를 뿌린다. 무슨 일에서나 교묘하게 하려하나 졸책으로 되니 운수에 맡기고 때를 기다리는 것이 좋다.
    백법에 ｢청신(靑神)이 생기를 이루는 것은 길하지만 얼마간 지연된다｣고 하였다. 
    비전에는 ｢축사(丑巳)가 서로 가해지는 것은 합하여 사(死)자로 된다｣고 하였다. 특히 일간의 녹신은 절지(絶地)에 임하고 역마는 묘지에 떨어진다. 3전이 동묘절(死墓絶)이면 구할 길이 없다. 질병은 손발의 질환이다. 
    일귀는 순행하며 3전은 음(陰)․청(靑)․귀(貴)이고, 야귀는 3전이 귀(貴)․백(白)․주(朱)이다.`
    ,
    // 7
    `  이 과는 4과 중에 극이 없고 음일이니 4과 음신의 역3지(逆三支)에 해당하는 사(巳)를 초전으로 한다. 정미일(丁未日)과 기미(己未)의 7국은 반음인 것 같으면서 팔전정란과(八專井欄課)이다. 일간의 정(丁)에서 과전 축미(丑未)에 설기가 중첩되니 소모와 허탈을 방비해야 한다. 초전의 사(巳)가 역마로 되고 년명 또는 행년이 인묘(寅卯)이면 흉모모신(凶耗耗神)을 제어하므로 흉을 해제하고 길을 초래한다.
    백법에 ｢탈상탈(脫上脫)을 만나는 것은 허사(虛詐)를 예방해야 한다｣고 하였다. 
    비전에는 ｢축미(丑未)이거나 또는 축미(丑未)가 가해지면 시험에 관한 일은 극히 길하다. 년명이 해(亥)에 해당하면 혈리(血痢)가 있다｣고 하였다. 사(巳)이면서 천공에 승할 때는 눈병․인후 종통이 있다. 
    일간이 정(丁)이며 과전 상지가 탈묘(脫墓)로 되어 간상에 임하면 의심스럽고 우려되는 사건이다. 초전이 역마로 되면 옮겨지고 바뀌어지는 사건이 있다.
    일귀는 순행하며 3전은 공(空)․음(陰)․음(陰)이고, 야귀는 순행하여 3전이 상(常)․구(勾)․구(勾)이다. 정란사격(井欄射格)은 몰래 계획하거나 지름길로 전진해야 한다. 바르게 전진하면 수고가 많고 효과가 적다. 만사가 관점 사항에서 대개가 즐기지 않는 것이 보통이다.`
    ,
    // 8
    `  이 과는 4과에 모두 상하의 극이 있으며 음일음비(陰日陰比)의 극이다. 사자(巳子)를 초전으로 하니 비용팔전과(比用八專課)이다. 3전이 동술묘(巳戌卯)이면 주인격(鑄印格)이다. 
    말전이 공망하고, 일간의 자(子)는 둔간하여 임수(壬水)로 되고 명암귀살로 된다. 따라서 망동을 꺼리고 조용한 것을 바란다. 3전이 묘(卯)에 들어 정체된다.
    백법에 ｢양사협묘(兩蛇夾墓)로 되니 흉을 면키 어렵다｣고 하였다.
    비전에는 ｢사(巳)에 천공이 승하고 자(子)에 가해지는 것은 주방(廚房)에 파손되는 일이 있고, 자(子)에 육합이 승하여 일지상신에 임하면 재앙이 있으며, 또 미(未)에 가해지는 것은 우물이나 물가에서 재난이 일어난다｣고 하였다. 
    팔전이나, 4과에 상하극이 없고 삼전에 후ㆍ음ㆍ합ㆍ현이 없어 유박불수로 논하지 않는다. 이인(二人) 회맹으로만 본다.
    주인격(鑄印格)은 현임 관공직자에게 기쁨이 있다. 그러나 묘(卯)가 공망하니 길함은 경하다.
    일귀는 역행하며 3전은 공(空)․사(蛇)․상(常)이며, 야귀는 순행하여 3전이 상(常)․사(蛇)․공(空)이다.`
    ,
    // 9
    `  이 과는 제1과․제3과에 극이 있고 1하가 1상을 적한다. 해미(亥未)를 초전으로 하니 중심팔전곡직과(重審八專曲直課)로 된다.
    일간일지상신의 해(亥)에서 극을 받지만 귀덕(貴德)이 신(身)에 임하고, 또 미(未)에서 이를 제어하니 흉을 이루지 않는다. 길장이 승하면 길조가 있다. 곡직(曲直)은 먼저 굽고 후에  직진(直進)한다. 
    과전 3합은 화미격(和美格)이지만 중․말전이 공망하여 길은 가볍다. 년명이 묘(卯)의 공망에 승하면 해공(解空)으로 되어 길하게 되니 전실(塡實)의 묘(妙)라고 말한다.
    백법에 ｢귀(鬼)에 천을귀신(天乙貴神)이 승하면 신기격(神祗格)이다｣라고 하였다. 두 귀신이 극을 받는 것은 귀인의 원조를 바라기 어렵다.
    비전에는 ｢간지의 상신에 해(亥)가 승하여 서로 절(絶)이 되기에 이전(移轉)․전직 등의 일이 전환법(轉換法)으로 되어 길하게 변화한다｣고 하였다. 중․말전의 공망은 전(傳)으로 돌지 않으므로 초전으로써 간점해야 한다. 시작은 있으나 끝이 이루어지기 어렵다. 질병․소송사는 흉하다. 일간일지상신이 태신에 해당하니 내정(來情)은 태산․자녀의 사건이다. 
    일귀는 역행하며 3전은 귀(貴)․상(常)․구(勾)이고, 야귀는 3전이 음(陰)․공(空)․주(鑄)이다.`
    ,
    // 10
    `  이 과는 4과 중에 상하의 극이나 요극이 없으므로 팔전과(八專課)로 한다. 음일은 제4과 음신의 역3지(逆三支)인 亥를 사용하여 초전으로 하고, 일간일지에 술(戌)이 앉아서 3전에 술(戌)을 보니 참관격(斬關格)이다.
    일간에서 보아 과전이 전부 탈설되고 묘신(墓神)이 승하니 폐색되어 통하기 어렵다. 초전은 일간을 극하지만 토(土)가 구신으로 되니 불행중 다행이다.   
    간지동위(干支同位)인 4과는 팔전과로서 대중의 회합을 상징한다. 출비․소모가 있으나 일귀(日貴)․야귀(夜貴)가 천반 유(酉)와 해(亥)가 되고 간상의 술(戌)을 모시므로 묘신(墓神)이 덮여도 흉은 변화되어 길조를 얻는다. 모든 일에서 즐거움과 걱정이 반복해서 일어난다.
    백법에 ｢화개(華蓋)가 일(日)을 덮으니 사람이 혼미하다｣고 하였다.
    비전에는 ｢팔전과(八專課)에서는 3전 중에 천후(天后)․육합․현무 중 어느 것인가가 승할 때는 유박불수격(帷薄不修格)이라 하여 남녀가 난잡하고 음사(陰私)나 색정사건이 발생한다｣고 하였다.
    일귀는 역행하며 3전은 귀(貴)․사(蛇)․사(蛇)이고, 야귀는 3전이 음(陰)․후(后)․후(后)이다.`
    ,
    // 11
    `  이 과는 제1과에서 1하가 1상을 적하여 중심과로 되며, 3전은 순간전격(順間傳格)으로서 모두가 음경(陰境)의 신이니 응음(凝陰)이라고 부른다.
    3전에 귀신이 승하고 4과에 또 귀신이 겹치므로 귀신이 많아서 윗사람․귀인에게 의존하기 어렵다. 3전이 음극(陰極)이면 소인(小人)에게 이롭고 사사(私事)에 좋으며 공사는 흉하다. 즉 저급한 자에게 좋고 군자에게는 흉하다. 4과에 자형(自刑)이 겹치면 자기의 부주의로 인하여 실착을 초래하는 일이 있거나 피차간에 서로 의심을 일으킨다.
    백법에 ｢과전에 귀신이 겹치면 윗사람에게 의뢰하기 어렵다｣고 하였다.
    비전에는 ｢귀신이 과전에 함께 나타나고 일덕(日德)이 움직이니 관공직․공명․구직․입시의 건은 모두 최고의 길조인 과｣라고 하였다.
    일귀는 역행하며 3전은 주(朱)․귀(貴)․음(陰)이고, 야귀는 3전이 귀(貴)․음(陰)․상(常)이다. 일야천장(日夜天將)이 어느 것이나 길장일 때는 길사가 더욱 길하고 흉사라 해도 가볍다.․`
    ,
    // 12
    `  이 과는 제1과에서 신정(申丁)이 1하가 1상을 극하여 중심진여과(重審進茹課)이다 4과3전이 모두가 전재(全財)이다. 재(財)가 과하게 많은 것은 얻어도 잃기 쉽다. 분수를 알고 구재(求財)하면 길하다. 전재는 인수(印綬)인 존장을 극한다. 진여격은 전진에 좋으나 사물이 연속될 징조가 있다. 술월(戌月)의 정단은 처첩이 잉태한다.
    백법에 ｢나망격(羅網格)은 도모하여 졸책으로 되기 쉽고 천지반중에 축(丑)은 자(子)에 가해진다. 복태격(腹胎格)이니 내의는 태산이나 자녀의 일이다｣라고 하였다. 
    비전에는 ｢자축(子丑)이 서로 가해지면 일이 반드시 이루어진다｣고 하였다. 술(戌)에 천후(天后)가 앉는 것은 여인의 병점(病占)으로 처음에는 경하나 후에는 중해진다. 신유술(申酉戌)은 3전이 밤중으로 들어가므로 유금(流金)이라 부르는데 처음에는 분명하고 끝이 분명하지 못하다. 공명구직은 불리하다. 
    일귀는 역행하며 3전은 합(合)․주(朱)․사(蛇)이며 내전 외전이 중첩된다. 야귀는 3전이 사(蛇)․귀(貴)․후(后)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
