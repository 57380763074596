import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./View.css";
import fireDb from "../firebase";
import Firebase from 'firebase';

const View = () => {
  const [user, setUser] = useState({});

  const { id } = useParams();
  const [auth, setAuth] = useState(false)

  const [textEmail, setTextEmail] = useState("")
  const [textPassword, setTextPassword] = useState("")
  

  Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // var uid = user.uid;

      setAuth(true)
      // ...
    } else {
      // User is signed out
      setAuth(false);
      // ...
    }
  });

  useEffect(() => {
    Firebase.database().ref()
      .child(`contacts/${id}`)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setUser({ ...snapshot.val() });
        } else {
          setUser({});
        }
      });
  }, [id]);


  function login(e) {
    e.preventDefault();

    console.log("Firebase.auth().currentUser", )
    Firebase.auth().signInWithEmailAndPassword(textEmail, textPassword)
    .then(()=> {
      setAuth(true);
    })
    .catch((error) => {
      setAuth(null);
      alert("입력한 이메일/비밀번호 입력란의 로그인 정보가 다릅니다. FIREBASE 인증시스템에 의해 이전 로그인 정보가 있다면 로그인 시도합니다.")
    });


    Firebase.auth().onAuthStateChanged((user) => {
      if(user){
        setAuth(user)
      }else{
        setAuth(null);
      }
    });

}


  if (auth == false ) {
      return(
          <div className="form_block">
              <div id="title">Login</div>
              <div className="body">
                  <form>
                      <input type="text" 
                      value={textEmail} 
                      onChange={e=> {setTextEmail(e.target.value)}} 
                      name="email" />
                      <input type="password" 
                      value={textPassword} 
                      onChange={e=> {setTextPassword(e.target.value)}} 
                      name="password" />
                      <input className="loginBtn" type="submit" onClick={login} value="Enter" />
                  </form>
              </div>

    <style>{`
    body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
    .form_block{
      width: 400px;
      display: table;
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
      background-color: rgba(0,0,0,.1);
      padding: 5px;
      border-radius: 5px;
    }
    .form_block > #title{
      background: #3d8577;
      padding: 10px;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      text-shadow: -1px -1px 0 rgba(0,0,0,.2);
    }
    .form_block > .body{
      background: #FFFFFF;
      padding: 10px;
      display: table;
    }
    
    input[type='text'],input[type='password'] {
      height: 35px;
      width: 100%;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 4px 5px;
      background: #fff;
      border: 1px solid #d0d0d0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
    .loginBtn{
      height: 35px;
      box-sizing: border-box;
      padding: 4px 10px;
      background: #f5f4ea;
      border: 1px solid #d0d0d0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      cursor: pointer;
    }
    .loginBtn:hover{
      background: #c3c3ba;
    }
    .registerBtn{
      float: right;
      background: #3d8577;
      color: #fff;
      height: 35px;
      box-sizing: border-box;
      padding: 4px 10px;
      border: 1px solid #d0d0d0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      cursor: pointer;
      margin-top: -35px;
    }
    .registerBtn:hover{
      background: #2d6358;
    }
    .Error{
      padding: 10px;
      background-color: #feeaea;
      border: 1px solid #fadadb;
      margin-bottom: 10px;
      border-radius: 3px;
    }
    `}
      </style>
          </div>
          )
  }


  console.log("user", user);
  return (
    <div style={{ marginTop: "150px" }}>
      <div className="card">
        <div className="card-header">
          <p>User Contact Detail</p>
        </div>
        <div className="container">
          <strong>ID: </strong>
          <span>{id}</span>
          <br />
          <br />
          <strong>Name: </strong>
          <span>{user.name}</span>
          <br />
          <br />
          <strong>Email: </strong>
          <span>{user.email}</span>
          <br />
          <br />
          <strong>Address: </strong>
          <span>{user.address}</span>
          <br />
          <br />
          <strong>Contact: </strong>
          <span>{user.contact}</span>
          <br />
          <br />
          <Link to="/">
            <button className="btn btn-edit">Go Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default View;
