import React from "react";

const About = () => {
  return (
    <div style={{ marginTop: "150px" }}>
      <h2>
        {" "}
        This is React Contact Management System Application with Routing using
        Firebase
      </h2>
    </div>
  );
};

export default About;
