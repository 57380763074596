const columnA = [
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`01`, `좌`],
  [`23:32`, `04 `],
  [`01:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`02`, `좌`],
  [`01:32`, `09`],
  [`03:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`03`, `좌`],
  [`03:32`, `06`],
  [`05:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`04`, `좌`],
  [`05:32`, `11`],
  [`07:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`05`, `좌`],
  [`07:32`, `08`],
  [`09:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`06`, `좌`],
  [`09:32`, `01`],
  [`11:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`07`, `좌`],
  [`11:32`, `10`],
  [`13:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`08`, `좌`],
  [`13:32`, `03`],
  [`15:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`09`, `좌`],
  [`15:32`, `12`],
  [`17:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`10`, `좌`],
  [`17:32`, `05`],
  [`19:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`11`, `좌`],
  [`19:32`, `02`],
  [`21:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`12`, `좌`],
  [`21:32`, `07`],
  [`23:31`, `내용 해당`],
  [``, ``],
];
const columnD = [
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [
    `1.2월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `궤후`,
    `천부적좌고우면신중성자`,
    `신뢰`,
    7,
    9,
    `없음`,
    `01.21\n~
  02.19`,
    `보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,
    `조용\n담담
  
  성교후곧잠`,
  ],
  [
    `4.5월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `단절자`,
    `단斷`,
    `고립적`,
    `1 4\n7 10`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 4\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `신뢰`,
    8,
    8,
    `절반`,
    `02.20\n~
  03.20`,
    `굵음\n적극적 매너
  
  강력한 성교 함`,
    `문을 열면 적극적이 됨\n
  감격을 숨기지않음`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `인자`,
    `천부적진취창의성자`,
    `설득`,
    9,
    7,
    `있음`,
    `03.21\n~
  04.20`,
    `단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,
    `주도적\n적극적
  
  완벽
  주의자 임`,
  ],
  [
    `4.5월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `인자`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 3\n7 10`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `인자`,
    `천부적좌고우면신중성자`,
    `위선`,
    10,
    6,
    `있음`,
    `04.21\n~
  05.20`,
    `때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,
    `정숙\n하고 
  억제심 강함
  
  분비물 많음`,
  ],
  [
    `4.5월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `인자`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 3\n7 10`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 14\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `위선`,
    11,
    5,
    `있음`,
    `05.21\n~
  06.20`,
    `기교파 반응 감상 타입\n
  크고, 즐김`,
    `위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴褪`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `예의`,
    `천부적진취창의성자`,
    `설득`,
    12,
    4,
    `없음`,
    `06.21\n~
  07.20`,
    `기교가 세밀함`,
    `분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`,
  ],
  [
    `4.5월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `예의`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `10후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `14\n710`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `예의`,
    `천부적좌고우면신중성자`,
    `고집`,
    1,
    3,
    `없음`,
    `07.21\n~
  08.20`,
    `정력파 女만족시키려 함\n
  쉽게 지침`,
    `적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`,
  ],
  [
    `4.5월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `예의`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `10후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `퇴세자`,
    `퇴褪`,
    `퇴휴적`,
    `14\n710`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 14\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `신뢰`,
    2,
    2,
    `없음`,
    `08.21\n~
  09.20`,
    `헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,
    `부끄러움 많이 탐\n
  성기는 명물임`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `포살`,
    `천부적진취창의성자`,
    `설득`,
    3,
    1,
    `없음`,
    `09.21\n~
  10.20`,
    `조루증 많음\n
  음경은 작은 편`,
    `음모 많음\n
  수축 형이고 능란함`,
  ],
  [
    `4.5월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `3 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `포살`,
    `천부적좌고우면신중성자`,
    `설득`,
    4,
    12,
    `없음`,
    `10.21\n~
  11.20`,
    `보통이상 크기\n
  극적. 본능에 그침`,
    `은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`,
  ],
  [
    `4.5월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `3 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `종교재난`,
    `1 2\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `신뢰`,
    5,
    11,
    `없음`,
    `11.21\n~
  12.20`,
    `통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,
    `적극적 \n상위 취함`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `종교발전`,
    `2 4\n5 7`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `종교탄핵`,
    `1 2\n4 5`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `종교한산`,
    `2 4\n5 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `종교대성`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `퇴세자`,
    `퇴退`,
    `활인탄핵`,
    `10후\n11후`,
    `궤후`,
    `천부적진취창의성자`,
    `설득`,
    6,
    10,
    `없음`,
    `12.21\n~
  01.20`,
    `보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,
    `적극적 \n상위 취함`,
  ],
  [
    `4.5월`,
    `소외자`,
    `소疏`,
    `활인한산`,
    `5 6\n7`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `진세자`,
    `진進`,
    `활인발전`,
    `2 후\n3 후`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `왕세자`,
    `성盛`,
    `활인대성`,
    `현재`,
    `지헤`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `단절자`,
    `단斷`,
    `활인재난`,
    `1 4\n7 10`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
];
const columnS = [
  [``, ``, ``, ``, ``, ``],
  [`관련`, `속사정`, ``, ``, `外業신변 男上`, `內事 가정 女下`],
  [``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``],
  [`금전`, `금전사`, `小`, ``, ``, ``],
  [`사업`, `사업사`, `小`, `6`, `外압박함`, `가정불화`],
  [`처첩`, `가정분쟁불화사`, `大`, `존재기`, `넘친욕심`, `가정단합`],
  [
    `원신지체(遠信遲滯)`,
    `먼 곳에서 올  소식이 언제 당도할 것인지 여부 상`,
    `大`,
    ``,
    `득죄됨`,
    ``,
  ],
  [`가실불화(家室不和)`, `가정부부가 불화하여 분쟁하는 상`, `小`, ``, ``, ``],
  [`자매`, `자매사`, `小`, ``, ``, `官者`],
  [`친구`, `파재사`, `大`, `5`, ``, `고위안좌`],
  [`동료`, `동료관련사`, `小`, `배양기`, `가실불화`, `이익경영`],
  [
    `투서길경(投書吉慶)`,
    `투서길경(吉慶)사-시험선거상위합격당선함-존장 기쁨`,
    `小`,
    ``,
    ``,
    `일반`,
  ],
  [
    `영전도약(榮轉跳躍)`,
    `관공직자 상인측근발탁 영전-단, 속임수 주의`,
    `大`,
    ``,
    ``,
    `파재극처`,
  ],
  [`관사`, `고위 발탁 안좌 급속사`, `大`, ``, ``, ``],
  [`직업`, `지위사`, `小`, `4`, ``, ``],
  [`출행`, `남편 관련 출행이동사`, `小`, `개시기`, `고위안좌`, `관직도약`],
  [
    `택사음완(宅舍欠完)`,
    `가택가옥이 완전하지 않고 흠결이 있는 상`,
    `大`,
    ``,
    ``,
    `女-혼인心`,
  ],
  [
    `고위경영(高位經營)`,
    `고위에 발탁되어 모두 이익이 되는 방법을 강구하는 상`,
    `小`,
    ``,
    ``,
    `음1월可`,
  ],
  [`관사`, `관사 관련사`, `大`, ``, ``, ``],
  [`직업`, `직업관련사`, `小`, `3`, `투서헌책`, `손해가출`],
  [
    `大財`,
    `여러 일을 결속하여 큰 돈을 획득하는 상-단 부모근심사`,
    `小`,
    `학습기`,
    `고위면접`,
    `눈이 가려`,
  ],
  [`재희형통(財喜亨通)`, `금전 형통사가 있는 상`, `大`, ``, `조력사有`, `진격`],
  [
    `엄목지상(掩目之象)`,
    `눈이 가려진 격으로 타인 상해 등 침해를 할 수 없는 상`,
    `小`,
    ``,
    ``,
    ``,
  ],
  [`형제`, `혈육 소원사`, `小`, ``, ``, ``],
  [`친구`, `불신사`, `大`, `2`, `시혜사有`, `투서헌책`],
  [`동료`, `속임사`, `小`, `면모기`, `눈이 가려`, `急文불용`],
  [
    `의근군자(宜近君子)`,
    `소인배를 멀리하고 실력자를 가까이 해야만 좋은 상`,
    `小`,
    ``,
    `진격`,
    `가정막힘`,
  ],
  [
    `엄목지상(掩目之象)`,
    `눈이 가려진 격으로 타인 상해 등 침해를 할 수 없는 상`,
    `大`,
    ``,
    ``,
    ``,
  ],
  [`명예`, `수상(受賞) 관련사`, `大`, ``, ``, ``],
  [`직녹`, `직위 녹위사`, `大`, `1`, `투서헌책`, ``],
  [`급속`, `내사 급속사`, `小`, `절정기`, `急文불용`, `약속비밀`],
  [
    `미초희경(微招喜慶)`,
    `부여 받은 권한 표시가 결여하여 실소하는 상`,
    `小`,
    ``,
    `외교막힘`,
    `누설추궁`,
  ],
  [`권한행사(權限行事)`, `권한을 행사하게 되는 상`, `小`, ``, ``, ``],
  [`색정`, `외男 색정사 有`, `大`, ``, ``, ``],
  [`후원`, `후원 관련사`, `大`, `12`, `비용과출`, ``],
  [`문서`, `문서 관련사`, `小`, `과시기`, `비밀약속`, `색정사有`],
  [
    `이해상수(利害相遂)`,
    `이익과 손해가 맞먹는 상`,
    `小`,
    ``,
    `누설추궁`,
    `刑責有`,
  ],
  [
    `우유한가(優遊閑暇)`,
    `머리를 가다듬을 수 있는 한가한 상-실업자 격`,
    `小`,
    ``,
    ``,
    ``,
  ],
  [`자매`, `자매 관련사`, `小`, ``, ``, ``],
  [`친구`, `친구관련사`, `大`, `11`, ``, ``],
  [`동료`, `동료관련사`, `小`, `쇠퇴기`, `형책사有`, `동요분산`],
  [
    `병필상식(病必傷食)`,
    `음3,4,5,6,9,12월 대재(大財)획득하는 상-부모傷食사 발생`,
    `小`,
    ``,
    ``,
    ``,
  ],
  [
    `엄목지상(掩目之象)`,
    `눈이 가려진 격으로 타인 상해 등 침해를 할 수 없는 상`,
    `大`,
    ``,
    ``,
    ``,
  ],
  [`박관`, `직위 불안 급속사`, `小`, ``, ``, ``],
  [`극부`, `남편 갈등사`, `小`, `10`, `급속송쟁`, `강제급속`],
  [`자식`, ` 자식 관련사`, `大`, `침해기`, `애증화합`, `남편갈등`],
  [
    `수의구사(守宜舊事)`,
    `옛 것을 지키는 것이 마땅하고 좋은 상`,
    `小`,
    ``,
    `실종상해`,
    ``,
  ],
  [
    `헌책불용(獻策不用)`,
    `투서헌책이 급히 쓴 문사로 불용되고 금전을 잃은 상`,
    `大`,
    ``,
    `우려`,
    ``,
  ],
  [`박관`, `직위 불안사`, `小`, ``, ``, ``],
  [`극부`, `남자갈등사`, `小`, `9`, `고위연회`, ``],
  [`색정`, `내女 색정사 有`, `大`, `소외기`, `초대열석`, `색정 사有`],
  [
    `욕견송사(欲見訟事)`,
    `길 가다 욕심을 내서 관재송사를 당하게 되는 상`,
    `小`,
    ``,
    `희사`,
    `不辨근신`,
  ],
  [
    `우유한가(優遊閑暇)`,
    `면모에 신경쓸 것 없는 한가한 상-실업자 격`,
    `大`,
    ``,
    ``,
    `요됨`,
  ],
  [`형제`, ``, `小`, ``, ``, ``],
  [
    `매사 허성불성`,
    `음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,
    `大`,
    `8`,
    ``,
    `동요불안`,
  ],
  [
    `매사 영속성없음`,
    `형제친구동료동업패재사는 선천적 기복재해를 겪음`,
    `小`,
    `침체기`,
    `소외침체`,
    `구설사송`,
  ],
  [
    `종난전탈(終難展脫)`,
    `결국 어렵게 되어 이탈하는 모습을 보이게 되는 상`,
    `小`,
    ``,
    `不辨근신`,
    ``,
  ],
  [
    `이어경영(利於經營)`,
    `고위에 발탁되어 모두 이익이 되는 방법을 강구하는 상`,
    `大`,
    ``,
    ``,
    `女婚음1월`,
  ],
  [`금전`, `금전사업여인 관련사`, `小`, ``, ``, ``],
  [
    `매사 허성불성`,
    `음7,8,10,11월 방문자는 종교진출하면 성공함`,
    `小`,
    `7`,
    `구설송사`,
    `무례득죄`,
  ],
  [
    `매사영속성없음`,
    `금전여자사업처첩이권사는 선천적 숙명재해를 겪음`,
    `大`,
    `단절기`,
    `동요분산`,
    `타인압박`,
  ],
  [`병권무전(兵權武戰)`, `병권을 쥐고 전재에 나아가는 상`, `大`, ``, ``, ``],
  [`소식즉길(消息卽吉)`, `문서 소식이 길한 상`, `小`, ``, ``, ``],
];
const columnZ = [
  [``, ``, 0, ``, ``],
  [`단계`, `단계`, 0, `가능`, `불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明시작`, ``, 0, ``, `색정사로 불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明종료`, ``, 0, ``, `직위부부갈등자녀사로 불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明진행`, ``, 0, `부친문서후원으로 가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, `모친문서후원으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明하향`, ``, 0, ``, `직위부부갈등자녀문제로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗절정`, 0, `자매조력으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗시작`, 0, `직위자산형재조력으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗종료`, 0, ``, `직위불안부부갈등자녀문제로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, `금전부인사업활용으로빠르게가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明진행`, ``, 0, `금전여인사업활용으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, ``, `세속허사자로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明절정`, ``, 0, ``, `세속하사자로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
];
const columnAF = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
];
const columnAS = [
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [2],
  [0],
  [0],
  [0],
  [0],
  [3],
  [0],
  [0],
  [0],
  [0],
  [4],
  [0],
  [0],
  [0],
  [0],
  [5],
  [0],
  [0],
  [0],
  [0],
  [6],
  [0],
  [0],
  [0],
  [0],
  [7],
  [0],
  [0],
  [0],
  [0],
  [8],
  [0],
  [0],
  [0],
  [0],
  [9],
  [0],
  [0],
  [0],
  [0],
  [10],
  [0],
  [0],
  [0],
  [0],
  [11],
  [0],
  [0],
  [0],
  [0],
  [12],
  [0],
  [0],
  [0],
  [0],
  [1],
];
const columnAU = [
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유화적 처신으로 후로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 시시비비를 가리려 말고 순응 유화적 자세로 사안을 뒤로 연기시켜아만 결과가 반드시 유리해지는 人`,
    0,
    0,
    `물러날 상황이나 되려 잔진하게 됨**전화위복됨`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `간난(艱難)험악고초**만약 상대와 다툰다면  근심걱정을 풀기어려우니 신중해야만 하고 후에 응해야 현명하며 방심하면 불리함`,
    0,
    0,
    `풍상 고초 후에 성공할 人`,
    0,
    0,
    `타인의 호의를 쉽게 받아들이지 말고 전후좌우를 세심하게 관찰해야함**현재 어둡고 기력이 없으므로 매사를 이면적으로 처신해야 함 `,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `호가호위 세(狐假虎威 勢**만약 타인과 다툰다면 피아간에 전의가 별로 없고 손상도 없으며 있다 해도 가벼움`,
    0,
    0,
    `옳지못한 일이 밝혀져 심중 놀람이 크나 말과 처신만 절제하면 자연적으로 해소될 人`,
    0,
    0,
    `집에서 쉬는 것이 좋고 타지에 나가면 불리함**일이 모두 연루되어 있으나 멈추면 조용해짐** `,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `강건정당속결**만약 타인과 다툰다면  먼저 나서서 속결처리해야만 유리함`,
    0,
    0,
    `명분이 있으므로 정정당당하게 선수적 종결이 유리하고 좌고우면하여 지체시키면 되려 불리해지는 人`,
    0,
    0,
    `여러 일이 하나로 묶어질 듯하나 결국 단절되고 번잡함**독신 고독 별리 각방 주말부부격 `,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 먼저 나서지 말고 순응적 처신으로 사안을 후로 미루ㅓ야만 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 순응 유화적 자세로사안을 지체 연기시켜야만 결과가 반드시 유리해지는 人`,
    0,
    0,
    `사람이나 가정이 모두 불리함**관재 발생 우려됨**고독 독신 별리 각방 주말부부 격`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `동요분산불안배신**만약 상대와 다툰다면 간신이 있고 천지가 뒤집힌 격으로 재난이 밖에서 오니 신중해야 함 `,
    0,
    0,
    `일이 반복되어 불안하고 색정사로 진퇴양난격에 처한 人`,
    0,
    0,
    `숨기고 감추는 일이 있음**위지처가 없음**처지 번민함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 시시비비를 가리려 말고 순응유화적 처신으로 후로 미뤄야만 반드시 유리해지는 人`,
    0,
    0,
    `권리행사는 초기외 공허함**승진도 지체하면 허사됨**매사 음적 이면적으로 접근해야 함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 순응 유화 처신해야만 결과가 유리해지는 人`,
    0,
    0,
    `번잡한 여러 일을 하나로 결속시키려 하나 결국 동일권 시도가 불발함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 순응 유화적 처신으로 사안을 뒤로 미뤄야만 결과가 반드시 유리해지는 人`,
    0,
    0,
    `숨기고 감추는 일이 있음**위지처가 없음**관송사 번민함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 순응 유화적 자세로사안을 지체 연기해야만 결과가 반드시 유리해지는 人`,
    0,
    0,
    `관공직 옮김**신택불안**매사반복**풍운불측 격`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `간난(艱難)험악고초**만약 상대와 다툰다면  근심걱정을 풀기어려우니 신중해야만 하고 후에 응해야 현명하며 방심하면 불리함`,
    0,
    0,
    `풍운고초 후 성공할 人`,
    0,
    0,
    `타인의 호의를 쉽게 받아들이지 말고 전후좌우를 세심하게 관찰해야함**현재 어둡고 기력이 없으므로 매사를 이면적으로 처신해야 함**앞으로 나아가야 함**풍운불측**종적없이 어디로 숨고싶은 심정임 `,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `부동신음(不動呻吟)**타인과 다툰다면 상대는 기회를 노리고 있으나 움직이지는 않음`,
    0,
    0,
    `움직이고 싶은 뜻고 달리 움직일 수 없는 처지의 人`,
    0,
    0,
    `스스로 유약하다고 자포자가 함**험난한 입장이고 숨기는 일이 있음**풍운불축의 격`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
];
const columnBE = [
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `타발동급속사`,
    `기사`,
    `구사`,
    `내사`,
    `표면이면동시사`,
    `충성`,
    `악성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동사`,
    `기사`,
    `구사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `피해인`,
    `타발동사`,
    `미사`,
    `신구중간사`,
    `내사`,
    `표면사`,
    `배신`,
    `보성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `타발동급속사`,
    `미사`,
    `구사`,
    `내사`,
    `이면사`,
    `충성`,
    `악성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `타발동사`,
    `미사`,
    `구사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `타발동사`,
    `기사`,
    `신구중간사`,
    `내사`,
    `이면사`,
    `배신 무역`,
    `보성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `아발동후회사`,
    `기사`,
    `신사`,
    `외사`,
    `이면사`,
    `충성무력`,
    `악성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동사`,
    `미사`,
    `구사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `아권리가져갈인`,
    `타발동급속사`,
    `미사`,
    `신구중간사`,
    `내사`,
    `이면사`,
    `배신`,
    `보성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `아발동사`,
    `미사`,
    `신구중간사`,
    `외사`,
    `표면사`,
    `충성`,
    `악성무력`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동사`,
    `기사`,
    `구사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성무력`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `타발동사`,
    `기사`,
    `신구중간사`,
    `내사`,
    `표면이면동시사`,
    `배신`,
    `보성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
];
const columnBO = [
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `흥`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠-가택고민`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `흥 `],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥-이전함`, `쇠-공허 이사필요`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠-공허 이사필요`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `가정동요부부쟁투`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-피해압박`, `가족이가택싫어함`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-공허`, `가정복잡단합`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `쇠 `],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `손해 가출가택고민`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `흥-이사함`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
];
const columnBS = [
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`승`, `승`, `승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, `승`, ``],
  [``, ``, ``],
  [``, `패`, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [` 무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [``, ``, ``],
  [` 무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [` 비김`, `비김`, ` 비김`],
  [``, ``, ``],
  [``, ``, ``],
];
const columnBV = [
  // [0,0,0,``,0,0,0,``],
  // [용모,가문,合6,``,용모,가문,合6,``],
  // [0,0,0,``,0,0,0,``],
  [3, 3, 6, `음4월`, 2, 2, 4, `음2월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 3, 6, `음5월`, 1, 1, 2, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음4월`, 2, 2, 4, `음8월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음5월-연기 또는 파혼우려`, 1, 1, 2, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 2, 5, `음8월`, 1, 1, 2, `음8월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음5월`, 1, 1, 2, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음4월`, 3, 2, 5, `음12월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음3월`, 1, 1, 3, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음2월`, 3, 2, 5, `음8월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 1, 4, `음7월`, 1, 1, 2, `음5월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 3, 5, `음6월`, 3, 2, 5, `음4월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음5월`, 2, 2, 4, `음3월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
];
const columnCE = [
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인격이 높으나 일을 진행하여 결과는 허무하되 외부의 상황에 따른 전화위복적 기우를 받아 공적을 냄`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `둔한 듯하나 공적으로는 별무효과로 드러나지만 개인적 조력에 의지함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `동료의 원조를 받고 공공의 피해를 내는 듯하나 결과를 성공적으로 마침`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인품이 높으나 중간의 일시적 실적으로는 초창기와 결과의 실적을 만회하지 못함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `유순하고 준수 미모이나일처리가 강하지 못해 결국 실적에는 실패함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`인품이 높으나 시종일관 명분과 편가르기 남녀다툼으로 마침`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `언어가 불성실하고 권리행사에 필요한 도장을 새길 칼을 구하지 못하는 격으로 진행함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`둔한 것 같고 역시 둔하게 마침`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`언어가 교묘하며 속이며 권리를 빼앗기는 결과를 초래함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`인격이 높으나 자기 이익만 취하고 공공적 일은 실패함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인격이 높고 공공사도 순서있게 진행하여 초창기의 부진을 극복하며 중간과 결과의 자산 실적을 크게 남김`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `언어가 불성실하나 중반의 침해를 빼고 초창기와 종반기의 실적이 큼`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
];
const columnCL = [
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, `낮`, `밤`],
  [
    `1.2월`,
    `음月적용`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n선택적`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
  ],
  [
    `4.5월`,
    `집集`,
    `반대`,
    `불이행`,
    `선이후이`,
    `進 \n向`,
    `  근`,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    ``,
    `내부제압`,
    ``,
    `느긋이`,
    ``,
    ``,
    `留`,
    `무난`,
    `남남서`,
    ``,
    `정동`,
  ],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `집集`,
    `반대`,
    `불이행`,
    `선실후실`,
    `逆進 逆向`,
    `  원`,
    `留`,
    `주인피해`,
    `정동`,
    ``,
    `동남`,
  ],
  [`7.8월`, ``, `발현안됨`, ``, `이면접근`, ``, ``, ``, `세입가해`, ``, ``, ``],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `선난후난`,
    `退 \n背`,
    `  원`,
    `留`,
    `주인세입`,
    `정동`,
    ``,
    `남남동`,
  ],
  [
    `7.8월`,
    `집集`,
    `내부발현`,
    ``,
    `포기요망`,
    `인내감수`,
    ``,
    ``,
    `상호무례`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, `다집多集`, ``, ``, ``, `종반자산`, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, `進向`, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `음10월`,
    `進 \n向`,
    `  근`,
    `留`,
    `주인무력`,
    `정북`,
    `정남`,
    ``,
  ],
  [
    `7.8월`,
    ``,
    `내부발현`,
    ``,
    `선난후의`,
    `인내持久`,
    ``,
    `이사이전`,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `집集`,
    `기세없음`,
    ``,
    `기다려야`,
    ``,
    ``,
    `하게됨`,
    ``,
    ``,
    ``,
    ``,
  ],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `상대무력`,
    `退 \n背`,
    `  근`,
    `留`,
    `주인무력`,
    `동북`,
    `남남서`,
    ``,
  ],
  [`7.8월`, ``, `이외타인`, ``, `포기요망`, ``, ``, ``, ``, `세밀심사`, ``, ``],
  [`10.11`, ``, `측근제압`, ``, ``, ``, ``, ``, ``, `함`, ``, ``],
  [`3.6\n9.12`, `음9월집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대없음`,
    `불이행`,
    `선이후손`,
    `退 \n背`,
    `원근왕래`,
    `留`,
    `주인압박`,
    `서남`,
    `정동`,
    ``,
  ],
  [
    `7.8월`,
    ``,
    ``,
    ``,
    `압박필요`,
    `동요분산`,
    ``,
    ``,
    `상호대립`,
    `세밀심사`,
    ``,
    ``,
  ],
  [`10.11`, `집集`, ``, ``, ``, `불안`, ``, ``, `동요불안`, `함`, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `다집多集`,
    `반대`,
    `불이행`,
    `선이후난`,
    `退 \n背`,
    `  원`,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    ``,
    `본인발현`,
    ``,
    `신속압박`,
    `권리행사`,
    ``,
    `去`,
    `주인압박`,
    `동북`,
    `정서`,
    ``,
  ],
  [
    `10.11`,
    ``,
    `결말제압`,
    ``,
    `필요`,
    `부실`,
    ``,
    ``,
    `상호미움 `,
    ``,
    ``,
    ``,
  ],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `集`,
    `반대`,
    `불이행`,
    `아 무력`,
    `退 \n背`,
    `  원`,
    ``,
    `세입압박`,
    `동북`,
    `남남동`,
  ],
  [
    `7.8월`,
    ``,
    `측근발현`,
    ``,
    `포기요망`,
    `단합저해`,
    `기력상실`,
    `去`,
    `세입가해`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, `기세없음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, `무시진행`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, `去`, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `아 무력`,
    `退 \n背`,
    `원`,
    `이사이전`,
    `세입피로`,
    `정동`,
    `정남`,
    ``,
  ],
  [
    `7.8월`,
    `다集`,
    `이외타인`,
    ``,
    `권리양도`,
    `권리양도`,
    ``,
    `권리양도`,
    `설기쇠약`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, `종반제압`, ``, `됨`, `됨`, ``, `좀더나음`, ``, ``, ``, ``],
  [`3.6\n9.12`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `선이후이`,
    `進 \n向`,
    `  원`,
    `去`,
    `세입피로`,
    `동남`,
    ``,
    `정북`,
  ],
  [
    `7.8월`,
    `다집多集`,
    `이면측근`,
    ``,
    `동지적`,
    `초반윗인`,
    ``,
    ``,
    `설기쇠약`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, `기세없음`, ``, `진행필요`, `조력재득`, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `집集`, `무시진행`, ``, ``, `중말허무`, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `집集`,
    `반대없음`,
    `불이행`,
    `선손후이`,
    `進 \n向`,
    `  원`,
    `留`,
    `주인피해`,
    `남남동`,
    `북북동`,
  ],
  [`7.8월`, ``, ``, ``, `미뤄야`, ``, ``, ``, `세입가해`, ``, ``, ``],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `선이후난`,
    `進 \n向`,
    `원근왕래`,
    `去`,
    `무난`,
    `정남`,
    ``,
    `동복`,
  ],
  [`7.8월`, ``, `측근내부`, ``, `신속히`, ``, ``, ``, `주인세입`, ``, ``, ``],
  [`10.11`, `집集`, `완전제압`, ``, ``, ``, ``, ``, `동시이사`, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, `하게됨`, ``, ``, ``],
];
const columnCY = [
  [``, ``, ``, 0, 0, ``, 0, ``],
  [``, ``, ``, 0, 0, ``, 0, ``],
  [``, ``, ``, 0, 0, ``, 0, ``],
  [`질병`, `  水`, `익사`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, `상가부정`, `상가조문`, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `특별있음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, `자살귀`, ` 水투신`, 0, 0, `묘좌`, 0, `있음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, `상가부정`, `상가조문`, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `있음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, `자살귀`, ` 水투신`, 0, 0, `묘좌`, 0, `있음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, `도로귀`, `교통사고`, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ` 여귀`, ` 부인`, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, `신부정귀`, `신성침범`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, `신부정귀`, `신성침범`, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ` 여귀`, ` 부인`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, `음사부정`, `제사잘못`, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, `신부정귀`, `신성침범`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, `음사부정`, `제사잘못`, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ` 수귀`, `水익사`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
];
const columnDH = [
  [
    `출생연도`,
    `24`,
    `96`,
    `25`,
    `97`,
    `26`,
    `98`,
    `27`,
    `99`,
    `28`,
    `00`,
    `29`,
    ``,
    `30`,
    `102`,
    `19`,
    `91`,
    `20`,
    `92`,
    `21`,
    `93`,
    `22`,
    `94`,
    `23`,
    `95`,
  ],
  [
    0,
    `36`,
    ``,
    `37`,
    ``,
    `38`,
    ``,
    `39`,
    ``,
    `40`,
    ``,
    `41`,
    ``,
    `42`,
    ``,
    `31`,
    ``,
    `32`,
    ``,
    `33`,
    ``,
    `34`,
    ``,
    `35`,
    ``,
  ],
  [
    0,
    `48`,
    ``,
    `49`,
    ``,
    `50`,
    ``,
    `51`,
    ``,
    `52`,
    ``,
    `53`,
    ``,
    `54`,
    ``,
    `43`,
    ``,
    `44`,
    ``,
    `45`,
    ``,
    `46`,
    ``,
    `47`,
    ``,
  ],
  [
    0,
    `60`,
    ``,
    `61`,
    ``,
    `62`,
    ``,
    `63`,
    ``,
    `64`,
    ``,
    `65`,
    `01`,
    `66`,
    `02`,
    `55`,
    `03`,
    `56`,
    `04`,
    `57`,
    `05`,
    `58`,
    ``,
    `59`,
    ``,
  ],
  [
    0,
    `72`,
    `08`,
    `73`,
    `09`,
    `74`,
    `10`,
    `75`,
    `11`,
    `76`,
    `12`,
    `77`,
    `13`,
    `78`,
    `14`,
    `67`,
    `15`,
    `68`,
    `16`,
    `69`,
    `17`,
    `70`,
    `06`,
    `71`,
    `07`,
  ],
  [
    0,
    `84`,
    `20`,
    `85`,
    `21`,
    `86`,
    `22`,
    `87`,
    `23`,
    `88`,
    `24`,
    `89`,
    `25`,
    `90`,
    `26`,
    `79`,
    `27`,
    `80`,
    `28`,
    `81`,
    `29`,
    `82`,
    `18`,
    `83`,
    `19`,
  ],
  [
    2,
    `내외 결속단합사`,
    `빈궁**관재**전도(顚倒)**도적의혹근심사`,
    `의혹불안사-실재사`,
    `빈궁**관재**전도**전화위복`,
    ``,
    `승진영전사**외사동요분산사**전화위복`,
    `빈궁**병재`,
    `관직자 대길-생살대권 쥠**재액해소`,
    `폭객기만능욕사-이전사`,
    `빈궁**관재`,
    ``,
    `음3,4,5,6,9,12월 부자**고위안좌-이익경영사`,
    `관직자-영전도약-일반인-반흉`,
    `빈궁**음4,5월 화재주의`,
    `사사성취사`,
    ``,
    `투서헌책불량함-구설-실재사`,
    `빈궁`,
    `모두 재난 뿐임`,
    `빈궁**병재**내외급속사`,
    `관계성불통-가택이사-간사은닉`,
    `가정동요분산개변사`,
    ``,
    `탄식신음**폐한(閉寒)인`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    `손해도망사우려`,
    `음4,5월 화재주의`,
    `내외사 출행이동원행사`,
    ``,
    `외사 상하화합사`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    3,
    `재난전소사`,
    `빈궁**관재`,
    `내외결속단합사`,
    `빈궁**관재**전도`,
    ``,
    `부부반목**전도**전화위복`,
    `남 모르게 당하는 능욕박해사`,
    `빈궁**외사동요분산사**전화위복`,
    ``,
    `음10,11,1,2월 부자**고위안좌-이익경영사`,
    `폭객기만능욕사-이전사`,
    `빈궁**형통길사발현**재액해소`,
    `관재형벌사-매우 나쁨`,
    `빈궁**병재**매사위역`,
    `내외사 출행이동원행사`,
    `희경사`,
    `도적악의-공격 말 것`,
    `빈궁**관재`,
    `觀書-문화풍아`,
    `빈궁`,
    `내외 급속사`,
    `급속사**머리단장적 한가`,
    `관계성불통-가택이사-간사은닉`,
    `가정도요분산개변사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `매사불성**종교귀의자립함`,
    `급문사투서헌책-불용됨`,
    `매사불성**종교진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    `출행사`,
    `재액해소`,
    ``,
    `이동사`,
    `손해도망사 우려`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    `가정동요분산개변사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    4,
    `관계성불통-가택이사-간사은닉`,
    ``,
    `재난전소사`,
    `빈궁`,
    `내외 결속단합사`,
    `빈궁**관재`,
    ``,
    `부부반목**전화위복`,
    `남 모르게 당하는 능욕박해사`,
    `빈궁**외사동요분산사**재액해소**전화위복`,
    ``,
    `음10,11,1,2월 부자**고위안좌-이익경영사`,
    `폭객기만능욕사-이전사`,
    `빈궁**형통길사발현`,
    `관재형벌사-매우 나쁨`,
    `빈궁**병재**매사위역**재액해소`,
    `내외사 원행이동출행사`,
    `희경사**전도`,
    `도적악의-공격 말 것`,
    `빈궁**관재**전도`,
    `觀書-문화풍아`,
    `빈궁`,
    `내외 급속사`,
    `급속사**머리단장적 한가`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `가정동요분산개변사`,
    ``,
    `매사불성**종교진출자립함`,
    `급문사투서헌책-불용됨`,
    `매사불성**종교진출자립함`,
    ``,
    `내외 남자여자 동사 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    `손해도망사 우려`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    5,
    `빈궁**병재**내외 급속사`,
    `도적의혹근심사-실재사`,
    `빈궁**가정동요분산개변사`,
    ``,
    `탄식신음사`,
    ``,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `빈궁**관재**전도**전화위복`,
    `눈이 가려져 상해를 할 수 없음`,
    `빈궁**외사동요분산사**전화위복`,
    ``,
    `재액해소`,
    `불리 고시-없는 구설근심사`,
    `반궁**재액해소`,
    `가정불화사-이동사`,
    `음3,4,5,6,9,12월 부자**중중희애사`,
    ``,
    `빈궁**내외사 출행이동원행사`,
    `복통-도모사 吉`,
    `병재**직녹길사발현**부족`,
    `재난소멸사`,
    `희경사-단, 부족`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    ``,
    `음4,5월 화재주의`,
    `손해도망사 우려`,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    6,
    `희경사**관재`,
    ``,
    `빈궁**병재**내외 급속사`,
    `도적실세-잡음`,
    `빈궁**관재**가정동요분산개변사`,
    ``,
    `관재**전도**탄식신음`,
    ``,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `빈궁**전화위복`,
    `눈이 가려져 상해를 할 수 없음`,
    `관재**외사동요분산사**전화위복`,
    ``,
    ``,
    `불리 고시-없는 구설근심사`,
    ``,
    `가정불화사-이동사`,
    `음3,4,5,6,9,12월 부자**중중희애사`,
    `손해도망사 우려`,
    `빈궁**내외사 출행이동원행사`,
    `복통-도모사 吉`,
    `빈궁**병재**직녹길사발현**부족`,
    `재난소멸사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `파재실종상해사 우려`,
    `종교진출하면자립함`,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    ``,
    `음3,4,5,6,9,12월 부자`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    7,
    ``,
    `身물손실사`,
    `빈궁**전도`,
    `왕래 사송계류사`,
    `관재**혼인사**내외 급속사`,
    ``,
    `관재**가정동요분산개변사**재액해소`,
    `관재-병질사`,
    `빈궁**전도**재액해소`,
    `파재실종상해사 우려`,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `관재**탄식신음**전화위복`,
    `내외 남자여자 동시 색정사`,
    `빈궁**외사동요분산사**전도**전화위복`,
    `신분 차이나는 음란-존비상몽사`,
    `빈궁**출행사`,
    `도적을 추격했으나 못잡음`,
    `재물분실사`,
    `이동사`,
    `병재**전도**매사위역**충산동요이별`,
    `관재형벌-매우 나쁨`,
    `빈궁-복통`,
    `내외사 원행이동출행사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    `재난소실사`,
    `충산동요이별`,
    `종교진출하면 자립함`,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별**이동사`,
    ``,
    `음4,5월 화재주의`,
    `손해도망사 우려`,
    `음4,5월 화재주의`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    8,
    `빈궁**내외사 출행이동원행사`,
    `복통-도모사 吉`,
    `身물손실사`,
    ``,
    `빈궁`,
    `왕래 사송계류사`,
    `관재**혼인사**내외 급속사**전도`,
    ``,
    `빈궁**전도**가정동요분산개변사`,
    `관재-병질사`,
    `빈궁**파재실종상해사 우려`,
    `재난소실사`,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `관재**재액해소**전화위복**가나 있으나 좋음`,
    ``,
    `빈궁**외사동요분산사**전화위복`,
    `신분 차이나는 음란-존비상몽사`,
    `빈궁**출행사`,
    `도적을 추격했으나 못잡음`,
    `재물분실사`,
    `이동사`,
    `관재**전도**매사위역`,
    `관재형벌사-매우 나쁨`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `손해도망사우려`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    9,
    `병재**매사위역`,
    `관재형벌사-매우 나쁨`,
    `빈궁**내외사 출행이동원행사`,
    `복통-도모사 吉`,
    `身물손실사`,
    ``,
    `빈궁`,
    `왕래 사송계류사`,
    `화합사**혼인사`,
    `내외 급속사`,
    `빈궁**가정동요분산개변사`,
    `관재-병질사`,
    `빈궁**파재실종상해사 우려`,
    `재난소실사`,
    `재난극복 이득진취사-전도`,
    `내외단합결속사`,
    `관재**전도**전화위복**가나 있으나 좋음`,
    ``,
    `빈궁**외사동요분산사**전화위복`,
    `신분 차이나는 음란-존비상몽사`,
    `관재**형통길사발현`,
    `도적을 추격했으나 못잡음`,
    `재물분실사`,
    `이동사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `손해도망사 우려`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    `급속사`,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    10,
    `재물분실사`,
    `이동사`,
    `병재**위역사`,
    `관재형벌사-매우 나쁨`,
    `빈궁**재액해소`,
    `복통-도모사吉`,
    `身물손실사**재액해소`,
    ``,
    `왕래 사송계류사`,
    `빈궁`,
    `화합사**혼인사`,
    `내외 급속사`,
    `빈궁**가정동요분산개변사`,
    `관재-병질사`,
    `빈궁**파재실종상해사 우려`,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `위해제압-진취이익-용속성없음`,
    `관재**전화위복**가나 있으나 좋음`,
    ``,
    `빈궁**외사동요분산사**전화위복`,
    `신분 차이나는 음란-존비상몽사`,
    `빈궁`,
    `도적을 추격했으나 못잡음`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `이동사`,
    ``,
    `손해도망사 우려`,
    ``,
    `음4,5월 화재주의`,
    `내외사 출행이동원행사`,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    `급속사`,
    ``,
    ``,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    11,
    `관직자 대길-생살대권 쥠-일반인 형벌 대흉함`,
    `병재**전도`,
    `폭객기만능욕사-이전사`,
    `빈궁**관재**전도`,
    `중중희애사有`,
    `음3,4,5,6,9,12월 부자**재액해소`,
    `관직자-영전도약 일반인-반흉`,
    `빈궁**재액해소`,
    ``,
    `화합사**매사완성**직녹길사발현`,
    `급문사 투서헌책불량-구설-실재사`,
    `빈궁`,
    `모두 재난 뿐임`,
    `병재**내외 급속사`,
    `가정동요분산개변사`,
    `관계성불통-가택이사-간사은닉`,
    `병 또는 도모사 미수`,
    `파재실종상해사우려**탄식신음`,
    `도적의혹근심사`,
    `빈궁**내외단합결속사`,
    ``,
    `빈궁**외사동요분산사**전화위복`,
    ``,
    `승진영전사**전화위복**`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `출행사`,
    ``,
    `이동사`,
    `중중희애사有`,
    `손해도망우려`,
    `내외사 출행이동원행사`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자 동시 색정사有`,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    12,
    ``,
    `승진영전사**외사동요분산사**전화위복`,
    `관재형벌사- 매우나쁨`,
    `빈궁**관재**병재**형통길사발현**재액해소`,
    `폭객기만능욕사-이전사`,
    `재액해소`,
    `중중희애사有`,
    `음3,4,5,6,9,12월 부자**재액해소`,
    `관직자-영전도약- 일반인-반흉`,
    `빈궁**재액해소`,
    ``,
    `화합사**매사완성**직녹길사발현`,
    `급문사 투서헌책불량-구설-실재사`,
    `빈궁`,
    `모두 재난 뿐임`,
    `빈궁**병재**내외 급속사`,
    `가정동요분산개변사`,
    `관계성불통-가택이사-간사은닉`,
    `탄식신음-병 또는 도모사 미수`,
    `빈궁**전도**파재실종상해사 우려`,
    `도적의혹근심-실재사`,
    `빈궁**전도**활인진출해당자-성공함`,
    ``,
    `빈궁**전도**전화위복`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    ``,
    ``,
    `손해도망사우려`,
    `내외사 출행이동원행사`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    `내외단합결속사`,
    ``,
    ``,
    `내외 남자여자 동시 색정사 有`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    1,
    ``,
    `빈궁**관재**전화위복`,
    ``,
    `승진영전사**전도**외사동요분산사**전화위복`,
    `관직자 대길-생살대권 쥠-일반인 형벌 대흉함`,
    `빈궁**관재**형통길사발현`,
    `폭객기만능뇩사-이전사`,
    `빈궁**형통길사발현`,
    ``,
    `음3,4,5,6,9,12월 부자**중중희애사有**전도`,
    `관직자-영전도약 일반인-반흉`,
    `빈궁`,
    ``,
    `화합사**매사완성**직녹길사발현`,
    `급문사 투서헌책불량-구설-실재사`,
    `빈궁**전도`,
    `모두 재난 뿐임`,
    `병재**내외 급속사**재액해소`,
    `관계성불통-가택이사-간사은닉`,
    `가정동요분산개변사`,
    `탄식신음-병 또는 도모사 미수`,
    `매사불성**전도**종교진출해당자**성공함`,
    `도적의혹근심-실재사`,
    `빈궁**관재**매사불성**활인진출자립함`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    ``,
    ``,
    `손해도망사우려`,
    `내외사 출행이동원행사`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    `재액해소`,
    ``,
    `파재실종상해사 우려`,
    `내외단합결속사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
];

const CHOSA1 = [
  `■ 계속 갈수도 없고 멈출수도 없고, 자업자득이네? 배은 망덕의관재야! 男:재산이고 신규사고간에 남의 손에 달렸네?
  女:간통을 하고 있네 !
  ⊕ 도로귀, 
  ⊖ 가택귀의 발동 때문이야!
  `,
  `■이사하시게? 그런데 양자 택일의 기로에 섰네? 
  一女二男 삼각관계도 있고! 문서 분쟁으로 번졌어! 
  ⊕ 도로귀, 
  ⊖ 가택귀가 발동했어!
  
  ⊕⊖신변가당신부정이들          었어 !
  `,
  `■ 마음이 떴네? 
  색정사가 번민이고 문서 분쟁이 벌어지겠어! 
  ⊕변사귀는 몸에, 도로귀는 집에, 
  ⊖가택음사부정듬. 전사귀는 몸에, 가택귀는 집에 박혔으니 이를 해소하면 전화위복이 커!
  `,
  `■ 새로 일을 꾸미거나 바꾸거나 자손일이거나 모두 
  
  ⊕ 도로귀, 
  ⊖ 가택귀 발동을 막고 나서 잘되기를 바래야지!
  이권이 상실 되겠어 !
  ⊕신변음사색정부정이들었    어 !
  `,
  `■ 봄ㆍ여름 : 여러개의 금전사를 하나로 묶네 ? 
  돈?  그냥 돌아가시오! 
  돈 많이 들어와! 
  가을ㆍ겨울 : 돈 잔뜩 실은 배가 파선해 물속으로 가라앉는 격일세?
  `,
  `■ 물구덩이에 빠진격일세? 경쟁에서 이길려면 모두 정리하고 인심을 얻어야 해! 
  
  ⊕자살귀, 
  ⊖전흉사귀가버티고있으니 혈육간에 원수가 되지!
  
  ⊕신변음사색정부정이들었    어 !
  `,
  `■색정불화야 ! 만사가 실속없이 소리만 요란하네? 
  옛것은 모조리 버리고
  
  ⊕⊖ 가택 가당신사 부정
  부터 해소해야 재기를 하던가 하지!
  `,
  `■ 가정이 내외 불화만 있고 사람 출입이 없네? 관재도 보이고!
  
  ⊕ 여귀, 
  ⊖ 전흉사귀가 극성이네?
  
  ⊕⊖신변가당신사부정이
      들었어!
  `,
  `■ 총은 좋은데 총알이 없는 격일세? 꼬리가 꼬리를 물고 돌 뿐 결과가 없겠어! 
  ⊖음사부정이 침범했다.
  丑寅卯辰子酉띠 : 성공함
  巳午未申戌亥띠 : 火土신에 치성요망 됨.
  `,
  `■ 빈총만 쏘고 있는 격일세? 새로 짜는 계획이 적중되지 않고 있어 ! 
  돈은 안되고 되려 이권만 잃어! 
  ⊕여귀, 
  ⊖전흉사귀가 길목을 막고 있네!
  
  ⊖신변음사부정이들었어!
  `,
  `■ 살얼음판을 밟고 있는        격이네? 
  자칫 사기 당하게 생겼어!  
  치성을 지성으로 하면 효과는 있겠어!
  
  ⊖가택음사부정이 들었다.
  `,
  `■ 한 남자를 두 여자가 다투고 있네? 이로운 것을 버리고 손해날짓만 하고 있어! 
  또, 부부가 서로 바람을 피우니 한 그물에 들었어! 
  ⊕ 도로귀, 
  ⊖ 가택귀 발동으로 그렇네! 
  ⊕子년⊖午년은 장관직에 오른다.
  ⊖신변음사부정 침범이야!`,
];
const YOGYEOL1 = [
  `■ 낮정단은 장생에 백호가 타고 밤정단은 귀살에 청룡이 탔다. 성패를 정하기 어렵다. 패하고 이루는 것은 본인의 능력에 달렸다.
`,
  `■ 귀신이 가택에 들었으며 이사 가는 것이 좋다. 겨울 낮정단은 화재가 걱정이고 병이나 송쟁이 있게 된다.
`,
  `■ 피차 극을 당하고 인택 모두 움직인다. 밤정단은 길흉이 없고 조용하거나 움직이거나 이익이 없다.
`,
  `■ 옛것을 지켜도 해롭고 새로 시작해도 실재가 없다. 낮정단은 재앙이 있어도 가볍고 밤정단은 공직자는 좋으나 일반인은 나쁘다.
`,
  `■ 상하간에 화목하고 재물ㆍ여자가 지나치게 번성하여 봄여름 점에는 가히 취할 수 있으나 가을 겨울 점에는 얻기 어렵다.
`,
  `■ 밤정단은 귀인이 해가 되어 병이나 송사가 겹친다. 정월점은 처가 임신을 하고 칠월점은 유산하거나 중절이 있다.
`,
  `■ 사과삼전이 모두 공망이니 일은 오나가나 종적이 없다. 내가 가서 상대로부터 해를 입고 오래된 병은 반드시 흉이 된다.
`,
  `■ 귀신이 잠복하여 밤정단은 대단히 나쁘다. 집안이 요란하고 이해를 서로 따진다.
`,
  `■ 서로 타협되고 화합하더라도 피차 서로 손해를 초래한다. 이미 해온 일에는 누군가가 도와야 좋게 된다.
`,
  `■ 재물이나 벼슬이나 부귀가 모두 해당되지 않는다. 활을 들었으나 쏘는 것을 잊었으니 옛것이나 지키면 좋다.
`,
  `■ 초전에서 말전 재를 도우나 두가지 일이 겹쳤다. 재물과 여자가 살을 품으니 그것을 취하면 불을 품는 두려움이 온다.
`,
  `■ 초전이 관살이고 중말전이 양인이니 이로움을 버리고 손해날짓만 한다.  
높은 자가 아랫사람에게 굽히니 떳떳치 못하고 창피하다.`,
];
const SIBO1 = [
  `■ ⊕⊖가당신사부정이 들었다.
  凶을 凶으로 제어하는
  계책이 필요하다. 
  자만하여 多人의 기만이 있고, 극해를 받는다. 겸손하면 움직임이 가능하고 전도가 생발하게 된다. 현재는 上下불손하고 도주관사가 있다. 신규ㆍ개혁사가 있고, 치성하면 吉하다. 
  관사가 기한다. 
  관직자는 영전한다.
  `,
  `■자충수적 우환이 기한다.
  女ㆍ婦가 헌신한다.
  관사가 있다. 일에 의혹이 있다. 
  신중하면 복이 된다. 
  관재의 징후가 있다. 
  一女二男의 색정사가 있다. 일에 불비점과 부족함이 있다. 관공직자는 吉하다. 스스로 온자가 결국 손해를 끼친다.
  `,
  `■가내우환 有. 가장속임
  家人이 원한을 품고 加害
  하려 한다. 자충수적 우환임
  관리ㆍ정치인은 탄핵이 있다. 매사실속이 없고 해롭다. 자기분수를 지키고 私的으로 접근해야만 일이 된다. 주색ㆍ간음사가 있다. 움직이면 불리하고, 근신ㆍ자중하여 광명이 있게 된다. 어떤 일도 교섭하지 말라.  丑年ㆍ丑月將 貴合 吉함
  `,
  `■가택문제를 의논한다.
  ⊖사주에 의해 공격받는
  일이 있다.
  처음에는 기복이 있으나 결국은 吉이 된다. 신규ㆍ신건에 승부가 있다. 上人은 貴人이다. 和平한 사람이다. 추천ㆍ천거에 의해 영리가 있다.
  季月이면 부자이다.
  `,
  `■⊕음사부정이 침범했다
  ⊕上人과의 유대를 강화요
  ⊖허위사에 재물손해 조심
  夫婦가 共히 음탕하다.
  만사형통하다. 특히 금전ㆍ사업ㆍ여인사는 목적 달성된다. 천우신조가 있다. 단, 제사가 잘못되어 있다. 장소를 옮겨야 한다.
  父母의 災害가 있다.
  
  男 : 처가 임신했다. 산고 주의.
  `,
  `■금전을 획득하여 소송이
  붙는다. 財ㆍ妻를 조심.
  사주를 받는 일이다.
  소쟁ㆍ불화가 있다.
  吉凶간 모두허성이다. 
  두가지 일로 상대와 경쟁한다. 전후좌우를 자세히 관찰해야한다. 自他가 서로 속이고 속아 진퇴가 어렵다. 겸손ㆍ적덕하면 吉하다. 舊事는 끝나게 된다.
  男 : 처가 임신했다. 낙태 주의.
  `,
  `■근ㆍ친신변 외사이다.
  직접 나서면 안되고 他人
  을 앞세워 측면으로 일
  해야 성사가 된다. 
  현재의 진행사는 믿을 수 없다. 방침을 바꿔 신규사에 매진해야 한다. 
  소리만 있고, 소득은 없다. 多人이 뭉쳐 공모해도 해산된다. 조행을 바로하고 성찰해야 한다. 
  
  ⊕⊖ 가당신사 부정이 듬
  `,
  `■폐지된 일을 부흥시키려 한다
  下人이 上人을 배반했다
  ⊕가택현관문이 훼손됨
  수입보다 지출이 더 많다. 人宅 모두 황량하다. 상대와 불화하고, 투쟁ㆍ재해가 있다. 어떤일도 완전하지 못하다. 근신하고 원행을 삼가라. 大凶의 징조가 있다. 
  
  ⊕여인과의 인연
  ⊖주석 心動
  `,
  `■근ㆍ친가택내사이다.
  旺財가 인수를 극상하여
  父母가 厄을 입는다.
  서로 친근하여 허모ㆍ지출을 꺼리지 않는다. 
  이로 인해 구재사에 大得하고 입신양명하는 격이다. 모든 교섭사가 성취된다. 만사 기쁨이 있다. 단, 上人의 재화는 면할 수가 없다.
  男 : 처가 임신했다. 출산하면 집이 흥한다.
  `,
  `■⊖季月은 부자이다.
  금전사는 불리하다.
  관직건은 불리하다.
  辰띠는 吉하다.
  모든 일에 수세방침이 좋다. 말로 생업하는자(구류술업자)는 吉하다. 
  집안내부사를 발설하지 말라. 해가 된다. 신규건이 기하게 된다.
  上人의 질책이 있다.
  `,
  `■근ㆍ친신변외사이다.
  목전의 지체사를 묻는다.
  人이고 사물이고 배반하
  지 않아야 한다. 
  초전의 왕쇠로 訣하라. 旺相은 전도가 양양하고 休囚악장은 凶이 된다. 마치 살얼음장을 밟고 있는 형편이다. 
  正月에 임신사가 있다.
  `,
  `■가택의 권리를 이양한다.
  夫婦가 共히 외정간사를
  범하고 있다.
  一男二女 삼각관계이다.
  모망ㆍ계획사가 모두 달성되고 성공한다. 名利도 현달한다. 상대는 항복하게 된다. 군인은 승진ㆍ장군이 된다. 사건은 정단사 外 더 있다.
  他人에 의뢰하여 성취된다.`,
];
const SIBO2_1 = [
  `■ 직녹, 신변외사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  여러사람의 기만․중상을 입는다.
  `,
  `■官･夫, 가택을 이전한다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,
  `■ 손모, 가택동요가 있다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,
  `■官･夫, 사물이 갈림길임.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,
  `■ 금전ㆍ여, 가택내사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  부모 재액예방이 필요함
  `,
  `■ 금전ㆍ여, 가택내사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  금전 여자가 화근이다
  `,
  `■ 직녹, 신변외사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  색정불화가 있다.
  이심 원한이다.
  `,
  `■官･夫, 신변반목사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,
  `■ 금전ㆍ여, 가택내사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  부모 재해예방이 필요함
  `,
  `■ 금전ㆍ여, 신변 동요사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.재·여 재액이있고 이권상실한다.
  `,
  `■ 자손, 신변외사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.
  유리하고 유익하다.
  `,
  `■ 官･夫,신변반목사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사불리하다.`,
];
const SIBO2_2 = [];

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/005/005_001.png`,
  `/static/images/sagwa/005/005_002.png`,
  `/static/images/sagwa/005/005_003.png`,
  `/static/images/sagwa/005/005_004.png`,
  `/static/images/sagwa/005/005_005.png`,
  `/static/images/sagwa/005/005_006.png`,
  `/static/images/sagwa/005/005_007.png`,
  `/static/images/sagwa/005/005_008.png`,
  `/static/images/sagwa/005/005_009.png`,
  `/static/images/sagwa/005/005_010.png`,
  `/static/images/sagwa/005/005_011.png`,
  `/static/images/sagwa/005/005_012.png`,
];

const HEASYEOL = [
  // 1
  `  이 과는 십이신(十二神)이 천지본궁(天地本宮)에 복(伏)하기 때문에 복음과(伏吟課)로 된다. 양일(陽日)은 일간상신을 채용하여 초전으로 하고, 중전은 형(刑)을 쓰며 말전은 충(冲)을 쓴다.
    삼전이 사신인(巳申寅)으로 되고 일귀(日貴)는 중전에 백호가 승하여 장생(長生)에 임하기 때문에 불행중 행이 있다. 야귀는 말전에 청룡이 승하여 일귀(日鬼)로 되어 행 중에 불행이 있다.
    이 과는 체형체극(遞刑遞剋)으로 된다. 예를 들면 인(寅)은 사(巳)를 생하고 사(巳)는 일간의 무토(戊土)를 생한다. 신(申)은 인(寅)을 극하고 인(寅)은 일간을 극하기 때문에 쓴 것을 맛본 다음에 단 것을 만나는 형상으로 된다.
    양일(陽日) 복음(伏吟)은 스스로 강하다고 자부하므로 자임격(自任格)이라고 부른다. 먼저 사람의 극해(剋害)를 만난다. 그리하여 중인(衆人)이 단결하여 비방하거나 개인일 때는 소행이 좋지 않다. 임관(任官)된 사람은 오히려 승천영전(昇遷榮轉)의 기쁨이 있으며, 체극(遞剋)은 화협(和協)의 기(氣)가 없어도 상생(相生)은 일간(日干)을 생하여 호전된다. 피점자가 만용(蠻勇)을 삼가하여 유순(柔順)하게 되면 모든 흉사는 감록이 된다.
    백법에 말하기를 ｢삼전이 호극(互剋)이면 중인(衆人)에게 기만당하고, 형(刑)이 상(上)에 있으면 주객(主客)이 의지가 투합(投合)되지 않는다.｣ 자임격(自任格)으로서 역마가 승하면 반드시 동조(動兆)가 있다. 삼전의 사맹신(四孟神)이 원태격(元胎格)으로 되어 길장(吉將)이 승하면 전도생발(前途生發)의 기(氣)가 있고, 사맹(四孟)은 사장생(四長生)의 신이 되면 임신을 정단함을 알 수 있다. 그렇지 않으면 사물을 개혁하는 일이 있다.
    일귀는 순행하고 삼전은 구(勾)․백(白)․사(蛇)이며 야귀는 역행하고 삼전이 주(朱)․후(后)․청(靑)으로 된다.`,
  // 2
  `  이 과는 묘진(卯辰)의 제2과와 제3과에 극이 있어 불비비용과(不備比用課)로 된다. 
    묘진(卯辰)은 육해(六害)로서 허성(虛聲)이 많고, 묘인축(卯寅丑)은 역연여로 불비격이며 사물은 견련(牽連)되어 의혹이 있다. 모사(謀事)나 희망사는 성공하기 어렵다. 묘(墓)는 간상에 임하고 귀살(鬼殺)은 지(支)에 임하며 사람은 혼매(昏昧)하고 가택은 안녕하지 못하다. 매사에 신중하고 동작이 경거(輕擧)하지 않으면 자연히 재(災)를 제거하고 복을 맞게 된다.
    간상 및 말전에 묘신이 승하기 때문에 병점은 사액(死厄)을 만난다. 육해(六害)와 주작이 승하면 문서의 일로 구설 분쟁이 발생한다. 백법에 말하기를 ｢순내(旬內)의 삼기(三奇)는 전내(傳內)에 있어서 존장의 일이있고, 피차 의심하는 것은 육해때문이다. 귀(鬼)를 제3과․제4과에서 보게 되면 관재(官災)의 징조가 있다｣고 하였다. 일귀(日貴)는 순행하고 삼전은 주(朱)․사(蛇)․귀(貴)이며, 야귀는 역행하고 삼전은 구(勾)․청(靑)․공(空)으로 된다.
    이 과는 삼기(三奇)가 승하여 어떤 일을 정단하여도 길조가 됨은 물론 입시(入試) 및 관공(官公)․회사 현직․구직(求職)의 여하를 막론하고 길로 되어 재화는 없어진다. 진자(辰字) 즉 천강(天罡)이 간(干)에 가해지기 때문에 사물이 다소 간난하여도 만약 년명상신에 술(戌)이 있어 진(辰)을 충(冲)하면 소흉소재(小凶小災)가 있을 뿐이다.
    불비격은 어떤 일도 불비 부족하며 결함이 있다. 이 날(日)이 양일(陽日)이면 양(陽)의 불비를 그대로 두고 음(陰)을 버린다. 따라서 제2과의 음을 버리는 것은 시(時)가 이양일음(二陽一陰)으로 되어 이남일녀(二男一女)의 상이다. 이것을 구별하는 법칙은 십이신(十二神)의 음양에 있는 것이 아니고 양신(陽神)․음신(陰神)의 일이다. 즉 사과(四課)가 완전하지 않고 하나를 결하게 된다.`,
  // 3
  `  이 과는 이상(二上)이 일하(一下)를 극하고 일하(一下)가 일상(一上)을 극하는 축묘(丑卯)를 초전으로 한다. 중심과로 되어도 극이 많다. 그러므로 일간상신과 지상신은 모두 하를 극한다. 즉 피아(彼我)가 함께 상한다. 야귀는 초전에 천공(天空)이 승하고, 중․말전은 전공(全空)으로 되어 삼전이 전공(全空)과 같다.
    관점 사항은 길흉이 함께 실(實)이 없고 정동(靜動)은 어느 것이나 무익하다. 그러므로 자기를 지켜 망동(妄動)을 삼가는 것이 좋다. 천후(天后)는 초전에 천공이 승하여 음운(陰雲)도 홀연히 풍동(風動)하므로 운무(雲霧)를 흩트려 청천(晴天)을 보게 된다. 삼전 축해유(丑亥酉)는 역간전(逆間傳)으로 되며, 삼전 유(酉)는 석각(夕刻), 해(亥)와 축(丑)은 야반(夜半)이다. 그리하여 극음(極陰)의 격으로 되어 동하면 지체된다.
    백법에 말하기를 ｢제1과․제3과의 간지(干支)가 상에 의해 하를 극하여 양소(兩所)가 상하면 서로 양손(兩損)으로 된다. 묘진(卯辰)과 사인(巳寅)은 교차육해(交車六害)로 되고 교섭사(交涉事)는 피아(彼我) 모두 해사(害事)가 발생한다. 일야양귀신(日夜兩貴神)은 지반 묘유(卯酉)에 임하여 가정․교역(交易) 어느 것이나 불리하게 된다. 병소(病所)도 역시 불길하다. 중전․말전에 태상(太常)의 길장(吉將)이 승하면 공망하므로 길이 경하다.
    일귀는 순행하고 삼전은 귀(貴)․음(陰)․상(常)이고, 야귀는 역행하여 삼전이 공(空)․상(常)․음(陰)으로 된다. 삼전의 축해유(丑亥酉)는 극음격(極陰格)이다. 음은 퇴(退)를 주재한다. 축(丑)에서 유(酉)에 전하여 음이 있어서 입음(入陰)의 형상이다. 공사(公事)는 흉하고 사사(私事)는 길하다. 해(亥)는 음란(淫亂)의 신이고 유(酉)는 주색(酒色)의 신이기 때문에 주색․간음(姦淫)의 일이 있다. 병을 정단하면 죽는다. 그러나 해유(亥酉)가 공망하여 병점 외에는 흉사(凶事)가 공허하여 해를 이루지 않는다. 만약 축(丑)의 태세(太歲) 또는 축(丑)의 월장으로 되어 귀신(貴神)이 승하면 용덕격(龍德格)으로 된다. 이 격에 들어가면 복상(福祥)을 내려 고생․빈곤․재화를 없애고 복신(福神)이 상조(相助)하여 운용제회과(雲龍際會課)로 된다.`,
  // 4
  `  이 과는 제1과에 인(寅)의 목(木)은 무토(戊土)를 극하여 원수과(元首課)로 된다. 삼전 원태격(元胎格)이고 일간상신의 관귀(官鬼)를 삼전 중에서 제(制)하여 흉을 구하기 때문에 처음에는 우려가 있어도 후에는 흉을 해소하여 길로 변화한다. 중․말전이 공망하기 때문에 길흉화복은 함께 기복(起伏)이 있다. 
    야귀(夜貴)로서 정단할 때는 삼전에 길장(吉將)이 승하여 양기개태(陽氣)開泰)라고 부르며 길조로 화한다. 인(寅)은 일간의 장생(長生)으로 일지에서 보면 역마로 되며, 귀(鬼)가 발용(發用)으로 된다. 따라서 관직 또는 상급(上級)의 회사원에게는 유익하며, 보통 사람에게는 이해(利害)가 모두 무관하다. 처음에는 실(實)이지만 끝에 가서 허(虛)로 끝난다. 초전이 관성(官星) 역마로 되고 야귀는 청룡이 승하여 명리․관직․구직․시험․선거 등은 가장 길조이다. 백법에 말하기를 ｢삼전이 체생(遞生)하면 사람의 추천을 받는다. 청룡이 장생(長生)에 가는 것은 길이지만 늦다｣고 하였다.
    삼전 인해신(寅亥申)은 사맹(四孟)이며 원태격(元胎格)으로 되고 오행을 생하는 곳은 소아(小兒)의 류신(類神)으로 된다. 정단사는 어떤 일도 신의(新意)이고 회태(懷胎)를 정단하면 반드시 임신한다. 일귀는 순행하여 삼전이 사(蛇)․음(陰)․백(白)의 흉장(凶將)이고, 야귀는 역행하여 삼전이 청(靑)․상(常)․후(后)의 길장으로 된다.
    월장(月將) 또는 년명(年命)이 신해(申亥)일 때는 전실공망(塡實空亡)으로 되어 해공(解空)하여 정단한다. 일상에서 일하를 극하고 나머지 과에 극이 없으면 원수과(元首課)라고 한다. 순극(順剋)하여 동란반상(動亂反常)이 이(理)가 없고 구종(九宗)의 원(元)으로 되며 64과의 수(首)로 되기 때문에 이런 이름을 갖게 됐다. 천지위(天地位)를 얻어 물품은 귀(貴)하고 새롭게 되며, 구종(九從)은 화합하고 부자(父子)는 친근하고 자애롭다. 결혼은 해로(偕老)의 약속을 맺는다. 특히 길장(吉將)이 함께 할 때는 명리가 영달한다.`,
  // 5
  `  이 과는 제3과 자진(子辰)의 극을 채용하여 초전으로 하여 시입중심과(始入重審課)이다. 사과(四課)는 사유축(巳酉丑)과 신자진(申子辰)의 삼합(三合)이며, 삼전 신자진(申子辰)은 삼합하여 재(財)가 왕성하게 된다.
    춘월(春月)과 하월(夏月)의 정단은 재(財)를 많이 얻으며, 동월(冬月)은 전재태과(傳財太過)로 된다. 추월(秋月)에는 금(金)이 또 수재(水財)를 생하여 재(財)가 너무 지나치게 되므로 오히려 소재(小財)밖에 얻지 못한다. 사과삼전이 삼합지합(三合支合)하며 상하가 평화롭고 원만하여 무일(戊日) 진(辰)에 현무가 승하면 수혼신(收魂神)이라고 부르며 병단(病斷)에서 가장 기(忌)한다. 축(丑)에 귀신(貴神)이 임하면 신성강임(神聖降臨)이라고 부르며 천우신조(天佑神助)가 있다. 삼전이 삼합하여 윤하(潤下)의 수재(水財)로서 청룡을 보는 것은 재상(財上)에 재(財)가 있다. 그리하여 재(財)는 뜻대로 목적을 달성한다. 간지상신의 자(子)와 축(丑)이 지합(支合)하면 어떤 일이라도 내조(內助)하고 진력(盡力)하여 사물은 성취된다.
    백법에 말하기를 ｢삼전사과(三傳四課)가 삼합(三合)․지합(支合)하면 만사는 기쁨이 있다｣고 하였다. 비전에서 말하기를 ｢삼합이 서로 가하여 일상(日上)에 임하는 것은 경사(慶事)․결혼․바라는 일․음신(音信) 등 어느 것이나 길경(吉慶)이 있다｣고 하였다.
    일지상신 자(子)는 둔간(遁干)하여 갑(甲)의 귀(鬼)로 되고 일간에서 처재(妻財)로 되므로 처에 의하여 재해(災害) 또는 소송사가 발생하기 쉽다. 이 과를 얻으면 내의(來意)는 재(財)와 여성․결혼․상재(商財)의 건이다. 일귀는 순행하고 삼전은 사(蛇)․청(靑)․현(玄)이며, 야귀는 순행하고 삼전은 청(靑)․사(蛇)․현(玄)으로 된다. 삼전이 재왕(財旺)하므로 존장․존친의 병점(病占)은 대단히 흉조로 된다.
    순수(旬首) 자(子)를 초전으로 하여 육의(六儀)로 된다. 순수(旬首)는 육양(六陽)이고 지신(支神)의 장(長)이며 의례(儀禮)의 존(尊)으로 되기 때문에 육의라고 부른다. 관점사(觀占事)는 어느 것이나 길경(吉慶)이며 가운(家運)은 기쁨이 흘러넘치는 격이다. 왕상(旺相)할 때는 범죄(犯罪)는 사면(赦免)되고 병은 양의(良醫)를 얻어 만사는 수미(愁眉)를 열게 된다. 년명상(年命上)에 육의를 승하면 흉이 변화하여 길로 된다.`,
  // 6
  `  이 과는 4과가 전부 상극뿐인 섭해과(涉害課)로 된다. 사맹신(四孟神)의 상(上)을 취하여 자(子)를 초전으로 한다. 그러나 사과(四課)도 맹신의 극이므로 양일(陽日)이면 일간상신을 용신(用神)으로 하는 것이다.
  이 과는 철하(綴瑕)라고 이름을 붙였는데 복등(復等)이라고도 부른다. 즉 사과(四課)의 극에 맹중계신(孟仲季神)이 완전한 것으로 상등(相等)하기 때문에 복등이라고 부른다. 간(干)의 양과(兩課)와 지(支)의 양과(兩課)에 극이 있다. 즉 이물(二物)이 상병(相竝)하고 양웅(兩雄)이 교쟁(交爭)하며, 월일(月日)을 연철(延綴)하여 견련하므로 철하(綴縀)라고 부른다. 중인(衆人)에게 덕행(德行)하면 길로 변화한다.
  이 과는 길흉․화복이 어느 것이나 허성(虛聲)으로 끝난다. 그러나 계속되는 흉사의 정단은 낡은 일을 결절(結絶)하고, 만사가 겸손하면 재(災)를 해소하고 복을 맞아들일 수 있다. 백법에 말하기를 ｢무(戊)의 상(上)에 자(子)가 승하는 것은 자(子)의 장간(藏干) 계(癸)와 상하가 간합(干合)하여 길장이 승하면 길상(吉祥)이 있고 사(蛇)․백(白)이 승하면 복이 없다｣고 하였다. 섭해과는 먼저 싸움을 일으키면 우리편이 불리하게 된다. 상하 모두 극이 있는 것은 도액격(度厄格)으로, 자타(自他)가 함께 속이고 속아서 진퇴(進退)도 결정하기 어렵다.
  일귀(日貴)는 역행(逆行)하여 삼전(三傳)은 사(蛇)․공(空)․후(后)이고, 야귀는 순행하여 삼전은 청(靑)․귀(貴)․백(白)으로 된다. 제1과와 제4과는 자(子)와 오(午)가 충(冲)하고 일지상신의 해(亥)는 기궁(寄宮)의 사(巳)를 충하여 가정에 소쟁불화동요(小爭不和動搖)가 있다. 자(子)가 사(巳)에 가해지는 것은 병점(病占)에는 불리하게 된다.`,
  // 7
  `  이 과는 이하(二下)가 이상(二上)을 극하여 반음과(返吟課)로 된다. 섭해과(涉害課)를 겸하는 사맹신(四孟神) 해(亥)의 지반을 취하여 초전으로 하고 견기격(見機格)으로 된다. 맹신(孟神)은 시령(時令) 즉 기후를 주재하는 일의 시작으로 화복을 수장하며 기회를 보아야 한다는 뜻에서 견기격이라고 한다.
    이 과는 사과삼전이 모두 공망하지 않는 것이 없다. 반음(返吟)은 왕래(往來)하여도 공망하여 거래의 종적도 없다. 삼전의 사해(巳亥)는 어느 것이나 절지(絶地)에 좌(坐)하므로 구병(久病)은 극히 흉하다.
    반음과는 천지충위(天地冲位)에 있으므로 믿을 수 없는 격이라고 부른다. 과전(課傳)이 모두 공망하면 길흉은 다같이 이루어지지 않으며 사물은 양도(兩途)를 띠고 원근(遠近)이 함께 마음에 걸려 왕래가 무상하며 의심은 결정하기 어렵다. 몸가짐을 삼가하고 반성하며 옛것을 개혁하여 신규(新規)에 진출해야 한다.
    반음과는 거래 이동의 과이므로 전공(全空)하여 이동이 있다고 관점하지 않는다. 또 일체의 목적의 결과는 당연히 맞지 않는다. 일귀는 역행하고 삼전은 상(常)․주(朱)․상(常)이고 야귀는 순행하여 삼전은 음(陰)․구(勾)․음(陰)으로 된다. 무(戊)는 사(巳)를 덕록(德祿)으로 하고 해(亥)의 천문(天門)에 들어가더라도 공망하여 길조로 보지 않는다.
    백법에 말하기를 ｢74법에 삼전의 전부 공망은 길흉이 함께 공허로 되어 실적(實績)이 없고, 일을 하여도 목적을 달성하지 못하고 해산, 파괴하게 된다. 사과가 완전히 공망되는 것은 모두 공론(空論)․공화(空話)로 되어 소리만 있고 실의(實意)가 없다. 또 94법 재(財)를 보고 공망하여 재를 얻지 못한다. 비전에 말하기를 ｢원태격(元胎格)은 4인(四人)이 공모(共謀)하는 징조이다｣고 하였다.`,
  // 8
  `  이 과는 제2과, 제4과에 극이 있고 일하(一下)가 일상(一上)을 극하는 제4과의 인유(寅酉)를 초전으로 하여 중심과로 된다. 초전의 인(寅)은 일귀(日鬼)더라도 유(酉)의 제지(制地)에 있어서 화를 이룰 수 없으며 삼전이 체극(遞剋)한다. 즉 인(寅)의 초전은 중전의 미(未)를 극하고 중전은 말전의 자(子)를 극하여 재(財)로 되어 재(財)를 얻으나 반면에 출비가 크다.
    원래 삼전에 상극이 많은 것은 어떤 일도 화기(和氣)가 없다. 일간상신에 묘신(墓神)이 복(伏)하고 일간은 일지상신에 설기(泄氣)하며, 일지의 진(辰)은 또 토생금(土生金)으로 설기하기 때문에 인택(人宅)이 모두 황량(荒凉)하거나 사람의 출입이 적다. 다시 말해 소모가 많은 징조라고 알 수 있다. 또 자미묘진유술(子未卯辰酉戌) 및 과전이 어느 것이나 육해(六害)가 많기 때문에 집의 세력이 서로 기울어지고 아랫 사람이 존장을 배반한다. 혹은 귀물(鬼物)이 있어 사람을 손상할 두려움이 있다.
    백법에 말하기를 ｢76법은 피아시의격(彼我猜疑格)으로 되고 저해․투쟁․암암리에 재해가 있다. 또한 사물은 효과를 이루지 못하고 서로 불화하여 어떤 일도 끝까지 완전하지 못하다｣고 하였다. 비전에 말하기를 ｢유(酉)의 상신에 인(寅)이 가하여 초전으로 되고, 중․말전의 미(未)와 자(子)가 와서 일실(一失)을 이룬다. 여행․출행․가출 등은 원방에 간다. 비혼상백(飛魂喪魄)을 띠고 천을(天乙)이 역행하여 삼전 중에 백호를 보고 생기를 이루지 않는 것은 대흉(大凶)의 징조이다.｣라고 하였다.
    일귀는 역행하고 삼전은 후(后)․공(空)․청(靑)이다. 야귀는 순행하고 삼전은 백(白)․귀(貴)․사(蛇)로 된다.`,
  // 9
  `  이 과는 4과 중에 상하(上下)의 극이 없고 일간에 의해 4과상신을 극하여 요극과(遙剋課) 탄사격(彈射格)이 된다. 천을귀신(天乙鬼神)이 지반 묘유(卯酉)의 지(地)에 임하므로써 여덕격(勵德格)이 된다. 제1과와 제3과를 진(辰)과 유(酉)가 지합하고 사(巳)의 기궁과 신(申)이 지합으로 되어 교차지합(交車支合)한다. 그러나 일간은 유(酉)에 설(泄)하여 탈기(脫氣)되며, 일지(日支) 진(辰)은 신(申)에 설(泄)하여 탈기로 된다. 즉 피차 서로 탈설(脫泄)하고 비로소 교차지합하므로 상합(相合)하여 친근하다가, 결국에는 상탈(相脫)하여 손모(損耗)를 초래한다.
    제4과의 자(子)는 초전으로 되어 일간의 재성(財星)이 된다. 말전의 신금(申金)이 초전의 재(財)를 생하므로 구재는 크게 얻는다. 말전이 현무 진(辰)의 묘신에 승하여 병관(病觀)은 대흉(大凶)이며, 말전은 초전의 처재(妻財)를 생하므로 결혼은 가장 길조이다. 제4과를 초전으로 하고 요극탄사(遙剋彈射)는 멀어서 힘이 없으며 길흉이 함께 경미하다.
    삼전이 윤하격(潤下格)이고 간상신과 육합(六合)하는 것은 어느것이나 화순(和順)의 상이다. 귀인이 묘유(卯酉)의 문호(門戶)에 서는 것은 군자(君子)는 승진하고 소인은 퇴보한다. 백법에 말하기를 교차상합(交車相合)하는 것은 교역(交易)․교섭(交涉)․타협(妥協)에서 성공한다. 만사가 기쁨을 만나는 것은 4과 삼전이 육합․삼합을 만난 때문이다. 일간에서 삼합하여 재국(財局)으로 되고 수국(水局)은 능히 유통생화(流通生化)한다｣고 하였다｣고 하였다. 초전이 둔간(遁干)하여 갑(甲)의 귀효(鬼爻)로 되기 때문에 과욕한 구재(求財)는 좋지 않다. 군자는 의(義)를 생각하여 해(害)를 멀리 하기 때문에 화해(禍害)가 없다.
    일귀는 역행하고 삼전은 사(蛇)․현(玄)․청(靑)으로 된다. 사신(蛇神)이 먼저 있고 청룡이 말전에 있으면 사(蛇)가 변화하여 청신(靑神)으로 되며 공명은 원대하게 이룩한다. 야귀는 순행하고 삼전은 청(靑)․현(玄)․사(蛇)로 된다. 이와 같은 전기(前記)와 반대로 용은 사(蛇)로 변화한다고 하여 구관구직(求官求職)은 불길하고 현임 관리는 퇴직의 화가 발생한다.`,
  // 10
  `  이 과는 제9국과 같이 요극과(遙剋課)이고 탄사격(彈射格)이다. 초전의 해(亥)는 공망하고, 중전은 일지의 역마가 공망의 지(地)에 좌(坐)하며, 일간의 재국(財局)은 어느 것이나 공망하여 길흉이 함께 힘이 없다.
    초전에서 중전을, 중전에서 말전을, 말전에서 일간을 생하여 체생(遞生)한다. 그러나 역마가 공망한 것은 활동하면 소모되고 지킬 때는 기쁨이 있다. 물론 원태격(元胎格)이 공망으로 되는 것은 수세(守勢)가 좋다. 그러나 구류술업(九流術業)은 오히려 길조를 형성하여 소인은 요행의 복을 얻는다. 다만 결과가 완전하지 않다.
    체생은 추천(推薦)의 일이 있어도 공망하여 유명무실로 돌아가게 된다. 백법에 말하기를 ｢삼전의 체생은 사람의 추천을 받아도 공망하게 되면 이는 류신에 따라 정단해야 한다｣고 하였다. 비전에 말하기를 ｢제2과를 초전으로 하고 집안일에 관하여 남에게 함부로 말하는 것은 좋지 않다. 이 사(巳)에 가하는 것은 주사(主事)는 어느 것이나 신규(新規)의 건이 발생한다｣고 하였다.
    일귀는 역행하고 삼전은 주(朱)․후(后)․상(常)이며 어느 것이나 길신에 해당된다. 야귀는 순행하고 삼전은 구(勾)․백(白)․음(陰)의 흉장이 승한다. 양귀신(兩貴神)이 지반 진술(辰戌)의 지(地)에 임하면 귀인입옥(貴人入獄)이라고 하여 관리의 관점은 불리하다. 만약 년명상신에 귀인이 승하면 흉은 변화하여 길조로 된다.
    요극과(遙剋課)라는 것은 4과중에 상하의 극이 없고 일간과 4과 상신과의 극이 있으면 그 극을 취하여 초전으로 한다. 만약 양극(兩剋)이 있으면 양일(陽日)은 양의 극을, 음일은 음의 극을 쓴다. 요(遙)에 사(斜)의 극을 취하기 때문에 요극과(遙剋課)로 된다.
    이 과는 호시격(蒿矢格)과 탄사격(彈射格)을 구별한다. 4과상신(四課上神)에서 일간을 극하는 것을 호시격으로 하고, 일간에서 사과상신을 극하여 초전으로 되는 것은 탄사격이라고 한다. 따라서 이 과는 탄사격이 된다.`,
  // 11
  `    이 과는 제4과 신오(申午)의 일하(一下)가 일상(一上)을 극하여 이것을 용신(用神)으로 하고 중심시입과(重審始入課)로 된다. 11국의 대부분은 순간전격(順間傳格)이다. 초전과 중전, 중전과 말전의 사이에 지지(地支)가 일자(一字)씩 결여되어 있는 것을 말한다.
    간전격(間傳格)의 신술자(申戌子)는 섭삼연격(涉三淵格)이라고 부르며, 청(靑)은 삼연을 건너서 우(雨)로 되지 않는다. 적(賊)은 삼연을 건너서 병증(病證)이 오지 않고 목전에 저격(沮隔)이 있다. 관사(官事)는 흉하고 모사(謀事)는 이루어지지 않는다. 초전의 신(申)은 수토(水土)의 일간에서 보아 장생(長生)으로 된다. 그러나 오(午)의 화(火)에 제어된다.
    말전의 자(子)는 수재(水財)로 되고 둔간하여 갑(甲)의 칠살(七殺)이 있기 때문에 사물을 배반하여 유익하지 못하다. 그러나 어느 것이나 공망하여 길흉을 이루지 못한다. 일귀는 지반 해(亥)의 지(地)에 임하여 귀인은 천문(天門)에 올라 신장살몰(神藏殺沒)로 된다. 백법에 말하기를 ｢강색귀호(罡塞鬼戶)는 모사(謀事)를 형성하는데 임(任)한다. 백호가 둔귀(遁鬼)에 승하는 것은 재앙(災殃)이 얕지 않다｣고 하였다. 비전에 말하기를 ｢초전 신(申)의 자손효(子孫爻) 장생은 지반 오(午)의 양인(羊刃)에 승하여 극을 받기 때문에 자(子)를 얻기 어렵다｣고 하였다. 말전의 처재(妻財)가 공망하고 천후(天后)는, 술(戌)의 예신(穢神)의 극을 당하기 때문에 처는 출산(出産)에 임하여 산사(産死)한다. 일간, 일지상신이 오미(午未)의 지합에 있어서 피차 화합한다.
    일귀는 순행하고 삼전은 백(白)․현(玄)․후(后)이며, 야귀는 역행하고 삼전은 후(后)․현(玄)․백(白)이다. 야귀는 일간상신 미(未)의 묘신(墓神)에 임하기 때문에 시험 문제는 적중한다. 간전(間傳)은 일위(一位)를 사이에 두고 삼전을 구성하는데 순간전은 사물의 순(順)이다. 내의(來意)는 저격(阻隔)의 사유를 묻고, 초전의 왕상(旺相)은 신이 내려와 길이 되고 휴수(休囚)하고 천장(天將)이 흉으로 되면 흉사라고 정단한다.`,
  // 12
  `    이 과는 불비격(不備格)이며 3과 중에 상하의 극이 없고 요극(遙剋)도 되지 않으며 따로 일신(一神)을 얻어 초전으로 되기 때문에 별책과(別責課)라고 부른다. 양간(陽干)은 항상 동(動)을 주재하므로 양일(陽日)은 간합(干合)의 상신을 취하여 초전으로 한다.
    무일(戊日)은 계(癸)에 간합하고 계(癸)의 기궁(寄宮)은 축(丑)이 된다. 지반 축(丑)의 상신 인(寅)을 써서 초전으로 하는 것은, 즉 따로 쓰기 때문에 별책과라고 이름을 붙였다. 또 다른 이름으로는 무음(蕪淫)이라고도 한다. 그러므로 양음(陽陰)이 불비격으로서 극제(剋制) 없이 사물의 변경을 다스리며, 함부로 동할 때는 문책이 있다.
    천장과 천신이 상생하는 것은 좋게보아 광명정대하게 되고 계획하는 사업은 모두 성공한다. 타인의 원조와 후원을 받는 천장(天將)에서 일간을 생할 때는 명리(名利)가 현달한다. 일간기궁의 내몸은 제3과 상신에 가서 지지(地支) 진(辰)에 설기(泄氣)함으로써 상대방에 항복한다. 양자(養子)로 가서 가문(家門)을 일으키는 형상으로 된다. 초전 인(寅)의 귀(鬼)는 오(午)의 화(火)를 생하고 살(殺)은 인(印)에 생화하여 흉을 이루지 않는다.
    백법에 말하기를 ｢일간의 녹신(祿神)이 바르지 않다. 서로 생하여 함께 생하는 것은 만사가 유익하게 된다. 계획이 졸책(拙責)을 이루는 것은 나망격(羅網格)때문이다｣라고 하였다. 비전에 말하기를 ｢불비격은 정확성이 결여되어 모든 일을 타인에 의뢰하여 성취시킨다. 역마와 사신(蛇神)이 초전에 승하여 오(午)가 월장(月將)일 때는 청룡은 일간을 생하고 사(蛇)는 청(靑)으로 화(化)하여 태세(太歲)가 귀인(貴人)을 승할 때는 대신(大臣)의 직에 취임한다. 천강(天罡)의 진(辰)이 묘(卯)에 가해지면 조용히 동기(動機)를 만든다. 간지상(干支上)에 양인(羊刃)과 구진이 승할 때는 군직(軍職)에 있는 사람은 장교(將校)로 된다. 별책과(別策課)는 정단사건 이외에 별도로 하나의 사건이 있음을 알 수 있다.｣
    일귀는 순행하고 삼전은 사(蛇)․청(靑)․청(靑)은 청으로 화하여 만사가 길조로 화한다. 야귀는 역행하고 삼전은 청(靑)․사(蛇)․사(蛇)로 되며 청은 사(蛇)로 화하여 실직이나 면직 등의 일이 일어난다.`,
];

export default {
  columnA,
  columnD,
  columnS,
  columnZ,
  columnAF,
  columnAS,
  columnAU,
  columnBE,
  columnBO,
  columnBS,
  columnBV,
  columnCE,
  columnCL,
  columnCY,
  columnDH,
  //
  CHOSA1,
  YOGYEOL1,
  SIBO1,
  SIBO2_1,
  SIBO2_2,
  SAGWA_IMAGE1,
  HEASYEOL,
};
