
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,7,12,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,8,11,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,9,10,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,10,9,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,8,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,12,7,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,6,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,2,5,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`설득`,3,4,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,4,3,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,5,2,`특별있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,1,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`색정`,`내부가정 녀 색정사 有`,`小`,``,``,``],
    [`매사허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`10`,`외사단합`,`색정사有`],
    [`매사 영속성없음`,`후원조력문서부모사는 선천적 기복재해를 겪음`,`大`,`침해기`,`入室성취`,`무례반목`],
    [`공함지지(空陷之地)`,`나아가는데 빈 것과 구덩이가 파인(함정) 길일 뿐이다-의 상`,`大`,``,`(관계성립)`,`문서상신`],
    [`화소복지(禍消福至)`,`화가 물러가고 복이 도래하는 상-자식출세 부진 근심 有`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`11`,`파재극처`,`침체공허`],
    [`파재`,`외사 파재극처실종사`,`小`,`쇠퇴기`,`자식출세`,`화소복지`],
    [`수가쇠녕(守可稍寧)`,`지금 것을 지키며 소극적이라야만 편안할 상`,`小`,``,`근심문서`,`부족`],
    [`자강동지(自强動止)`,`자강불식하여 가정이 바름으로 가나 있으나 좋은 상-부족`,`大`,``,`상신答不`,``],
    [`형제`,`형제 관련사`,`大`,``,``,``],
    [`출행`,`외사 출행이동원행사`,`小`,`12`,`출행이동`,`비용과출`],
    [`과출`,`내부가정 과출사`,`小`,`과시기`,`토지송사`,`면모훼손`],
    [`내해무익(內害無益)`,`내부가정의 손해가 걱정이고 무익한 상  `,`大`,``,``,`근심해산`],
    [`목적부달(目的不達)`,`목적사가 이루어지 어려운 상`,`小`,``,``,``],
    [`직녹`,`직위녹위 관련사`,`大`,``,``,``],
    [`막힘`,`내부 가정 막힘사`,`小`,`1`,`손해도망`,`손해막힘`],
    [`결속`,`결속사로 동지사를 크게 이루나 금전처첩이권을 훼절함`,`小`,`절정기`,`근심해소`,`治家여의`],
    [`변위패신(變爲敗信)`,`일이 변하여 패하게 되는 상`,`大`,``,``,``],
    [`송구경황(悚懼驚惶)`,`통정인이 나타나 두려워 쩔쩔매며 당황하는 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`가출`,`내부 가정 손해가출사`,`大`,`2`,`외교막힘`,`손해가출`],
    [`막힘`,`외사 외교막힘사`,`小`,`면모기`,`필유성취`,`문서遺亡`],
    [`은장구보(恩將仇報)`,`은혜를 크게 받고 원수로 갚는 상`,`小`,``,`특명대기`,``],
    [`문서암동(文書暗動)`,`문서가 남모르게 넘어가는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`급속`,`외사 급속사`,`大`,`3`,`급속사有`,`출행이동`],
    [`출행`,`내부가정 출행이동사`,`小`,`학습기`,`직위불안`,`陰人재질`],
    [`용사음일(用事淫佚)`,`일에 음란사가 함께 끼어있는 상`,`小`,``,`문서유망`,`(숨긴연인`],
    [`절상인구(折傷人口)`,`가족 중 절상을 당하는 놀랄 일이 있게 되는 상`,`小`,``,``,`관련)`],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`파재`,`내부가정 파재극처사`,`大`,`4`,`직위불안`,`파재극처`],
    [`자녀`,`자녀 관련사`,`小`,`개시기`,`陰人災疾`,`도중청원`],
    [`화중재획(禍中財獲)`,`흉한 중에 재를 획득하게 되는 상`,`小`,``,``,`(고위行道`],
    [`공사구설(公私口舌)`,`公공私사 간 구설사가 있는 상`,`小`,``,``,`청원좋음)`],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`과출`,`외사 비용과출사`,`大`,`5`,`금전과출`,`결속단합`],
    [`결속`,`결속하여 동지사를 크게 이루나 금전처첩이권을 훼절함`,`小`,`배양기`,`도중청원`,`송구경황`],
    [`경기구알(更忌求謁)`,`가족의 화를 구하려고 다시 청원하는 것은 피하라-는 상`,`小`,``,`(길가는고`,``],
    [`송구경황(悚懼驚惶)`,`통정인이 나타나 두려워 쩔쩔매며 당황하는 상`,`大`,``,`위에 청원)`,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`남편`,`남편 관련사`,`小`,`6`,`명예유덕`,`급속사有`],
    [`급속`,`내부 가정 급속사`,`大`,`존재기`,`봉림유사`,`괴이구설`],
    [`수곤기모(守困忌謀)`,`비록 지금이 곤고해도 크게 움직여 도모하지 말라-는 상 `,`小`,``,`(숲속에서`,`微細송사`],
    [`간음무도(姦淫無道)`,`간음사가 꺼리낌이 없는 상`,`大`,``,`뱀을만난-`,`격`],
    [`관사`,``,`小`,``,``,``],
    [`동요`,`내부 가정 동요불안분산사-부부상쟁 문호개변 우려`,`小`,`7`,`색정사有`,`동요분산`],
    [`색정`,`외남 색정사 有`,`大`,`단절기`,`외사화합`,`남편개변`],
    [`기가전행(豈可前行)`,`도모사가 어려운게 뻔한데 어찌 전진하려는가-의 상`,`小`,``,`정위난측`,`殃禍소멸`],
    [`구연반복(句連反復)`,`단순한 일을 반복하는 상-門`,`大`,``,`(愛心궁금)`,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`동요`,`외사 동요분산사`,`大`,`8`,`동요분산`,`가정화합`],
    [`침체`,`외사 침체 공허사`,`小`,`침체기`,`침체공허`,`免屈난해`],
    [`굴존구행(屈尊求倖)`,`윗인에게 굽히면서 구하는 것이 다행을 얻는 상`,`小`,``,`불측지憂`,``],
    [`면굴난해(免屈難解)`,`굴욕사를 면하기 어려운 상-감옥 관련`,`大`,``,``,``],
    [`후원`,`후원 조력 관련사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`9`,`면모훼손`,`결속단합`],
    [`부모`,`부모 관련사`,`大`,`소외기`,`교섭난관`,`入室성취`],
    [`구상득체(俱相得體)`,`정체성을 구비하여 상호 인정받게 되는 상`,`大`,``,``,`관계화합`],
    [`골육저해(骨肉沮害)`,`골육 간에 소통이 안 되고 미워하는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`형제조력출행과출사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`지녹결속사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`금전사업처조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,``,`직업직위불안급속출행사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,``,`직업직위불안파재자녀사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`금전사업여인조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사남편간음급속사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사도요색정사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`금전사업처조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`후원문서부모조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순유화적 처신으로 사안을 지체시키거나 뒤로 밀면 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 대항해서는 안 되며 순응하는 태도로 후결하는 방침으로 하면 반드시 결과가 유리해지는 人 `,0,0,`일이 거꾸로 진행되어 퇴하려하나 되려 진행하게 되고 효과가 없음**기이적 전화위복사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고난**만약 타인과 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 직면사안을 뒤로 미루는 건이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`계획과 방침을 급히 변경해야 함**일이 거꾸로 띄엄띄엄 진행됨**병은-사망징조임**색정사 유의**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순유화적 처신으로 사안을 지체시키거나 뒤로 밀면 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 대항해서는 안 되며 순응하는 태도로 후결하는 방침으로 하면 반드시 결과가 유리해지는 人 `,0,0,`토지소송사 있음**일이 결과없이 반복되나 비로소 밝아짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 강건하고 정당하게 속결하는 것이 유리함`,0,0,`명분이 있고 정당하므로 속결하지 않고 사안에 대해 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,0,0,`복잡다단한 여러 일을 하나로 묶으려 함-단, 금전과 처첩이 훼절됨**여자가 남자를 적극 유혹하여 함께 도망칠 속심이 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고난**만약 타인과 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 직면사안을 뒤로 미루는 건이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일에 대해 세밀한 심사가 필요함**타인의 호의를 쉽게 받아들이지 말아야 함-笑중刀, 蜜속 毒이 있음**지금까지의 정대한 일들이 끓어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화가 밖에서 침범하므로 신중해야 함`,0,0,`색정음사로 이별하게 되고 심신을 어디에 의지하기 어려운 人`,0,0,`간사은닉건이 있음**풍운불측의 상**온자는 가려하고 간자는 오려 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순유화적 처신으로 사안을 지체시키거나 뒤로 밀면 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 대항해서는 안 되며 순응하는 태도로 후결하는 방침으로 하면 반드시 결과가 유리해지는 人 `,0,0,`일이 결과없이 반복되기만 하다가 비로소 밝아짐**기이한 전화위복사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 직면사안을 뒤로 미루는 건이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 복잡한 일을 하나로 결속시켜 동지사를 완성하나 금전과 처가 훼절됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 직면사안을 뒤로 미루는 건이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`만사 연루됨**간사은닉사 있음**일이 결과없이 반복되고 역시 어두워 짐**전후좌우 심사를 세밀하게 해야함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순유화적 처신으로 사안을 지체시키거나 뒤로 밀면 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 대항해서는 안 되며 순응하는 태도로 후결하는 방침으로 하면 반드시 결과가 유리해지는 人 `,0,0,`서리 내린 다리를 걷는 상**일이 띄엄띄엄 진행되어 퇴함이 좋음**목전에 방해사가 있음**이사 의사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순유화적 처신으로 사안을 지체시키거나 뒤로 밀면 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 대항해서는 안 되며 순응하는 태도로 후결하는 방침으로 하면 반드시 결과가 유리해지는 人 `,0,0,`일이 순서적으로 진행됨**산속으로 숨고싶은 심정임**가정에 위아래가 없이 무례함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 엿보고 공격하지는 않음`,0,0,`스스로 유약하다고 여기고 적극적 활동을 하지 않으며 신음하는 人-신체의 자유가 없는 경우도 있음`,0,0,`사방이 막힘**산으로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`좌고우면-무력`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`배신-무력`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`좌고우면`,`충성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동사후회사겹침`,`기사`,`구사`,`외사`,`표면사이면사겹침`,`배신`,`좌고우면`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,` 승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음1월`,2,1,3,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음8월-연기우려`,1,1,2,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음7월`,2,1,3,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음2월`,3,2,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월`,3,1,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음10월`,3,3,6,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음10월`,2,2,4,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,1,2,3,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음10월`,3,3,6,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 압무 전반에 실패함-단 1960,61,72,73,84,85,96,97,2008,09년 출생자는 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 전반적 침체상태를 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 압무 전반 중반이 허무하고 종반에 기회를 맞으나 효과가 미미함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준소 미모이며 업무가 강하지 못해도 여러 일을 일관시켜 동지사를 단합함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무 전반이 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무를 시종 동요분산사를 일으키고 풍기가 문란하게 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무수준이 떨어지나 요행적 행운이 다라 겨우 현상을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 온순하고 준수 미모이나 업무가 강하지 못한 듯해도 복잡한 여러 일을 일관시키는 결속 단합을 완성함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 오숭하고 준수 미모이나 압무가 강하지 못해 시종이 저조하여 실패로 간주됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯해도 조정능력이 있어 불리한 조건을 타파하나 종반이 허무하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯해도 조정능력이 있어 불리한 조건을 타파하고 실적을 이루나 내부 위계질서에 실패함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯해고 조정능력이 있어 초 중반에는 실적을 이루나 종반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선이후이`,`進向`,`근`,`留`,`무난`,`동남`,``,`정동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압불가`,`불이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`남남동`,``,`동남`],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인사안`,`退背`,`  근`,`  去`,`주인무력`,`정남`,``,`남남동`],
    [`10.11`,``,``,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`주인조력`,`남남서`,`정남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선攻후난`,`進向`,`  원`,`  留`,`상호화합`,`북서`,`동남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선난후損`,` 進向`,`  원`,`  留`,`주인가해`,`남남동`,`남남동`,``],
    [`10.11`,`  集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선난후費`,`退背`,`원근왕래`,`   去`,`주인가해`,`정서`,`정남`,``],
    [`10.11`,`  集`,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선攻후이`,`退背`,`  원`,`  去`,`세입가해`,`북북서`,`남남서`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인협력`,`불이행`,`선이후이`,`進向`,`원근왕래`,`  留`,`세입조력`,`동남`,`북북서`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`불이행`,`선損후費`,`進向`,`원근왕래`,`  留`,`세입조력`,`정동`,``,`남남서`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선攻후난`,`進向`,`  원`,`  留`,`세입가해`,`동북`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`권리양도`,`進向`,`  근`,`  留`,`무난`,`정동`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`여귀`,`가장부인`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`저주부정`,`怨心저주`,`육친`,0,``],
    [`질병`,`도로귀`,`교툥사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`저주부정`,`怨心저주`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`도로귀`,`교통사망`,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`신부정귀`,`신不定`,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`가장귀`,`호주사망`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`특별있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`神師부정`,`聖容훼손`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`神師부정`,`聖容훼손`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`자강불식치가-동지함의`,``,`매사 허성불성-세속허사자**근심해소사`,`음7,8,10,11월 방문자는 종교진출하면성공함`,`매사 영속성없음**화소복지사`,`종교진출하면자립함`,`외사출행이동사`,`자식위한문서상신답신지체사`,``,`입실성사-관계성립사`,`매사 위역사**외교막힘-반목사`,`굴욕난해사-감옥관련`,`외사급속사-내사출행이동사`,`불측근심사-이동사`,`내부가정파재극처사`,`상대애정난측사`,`내부가정결속사`,`손괴재물사`,`내사 급속사`,`승진영전사`,`외남 색정사**가정동요별리개변사`,`도적악의-공격말 것`,`매사 위역사**남녀직원공모사有**가정화합사`,`남모르는 재물손해사-정인관련`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도`,`내녀 색정사-무례대립사`,`전도`,`외사파재극처실종사`,`빈궁`,`재액해소**전화위복`,`빈궁`,`재액해소`,`관재`,`가정가출사`,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁**병재`,``,`관재`,`외사화합사`,`빈궁**관재`,`빈궁**관재`,`침체驚懼사**외사동요분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**남녀직원공모사有**가정화합사`,`남모르는 재물손해사-정인관련`,`자강불식치가-동지함의`,``,`매사 영속성없음**근심해소사`,`종교진출하면자립함`,`매사 영속성없음**화소복지사`,`종교진출하면자립함`,`외사출행이동사`,`자식위한문서상신답신지체사`,``,`입실성사-관계성립사`,`매사 위역사**외교막힘-반목사`,`굴욕난해사-감옥관련`,`외서급속사-내사출행이동사`,`불측근심사-이동사`,`내부가정파재극처사`,`상대애정난측사`,`내부가정결속사`,`손괴재물사`,`내사 급속사`,`승진영전사`,`외남 색정사**가장동요별리개변사`,`도적악의-공격말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사동요분산사`,`빈궁**관재`,`전화위복`,`전도`,`내녀 색정사-무례대립사`,`전도`,`외사파재극처실종사`,`빈궁`,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,``,`가정가출사`,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**관재`,`빈궁**병재`,``,`관재`,``,`외사화합사`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`외남 색정사**가정동요별리개변사`,`도적악의-공격말 것`,`매사 위역사**남녀직원공모사有**가정화합사`,`남모르는 재물손해사`,`자강불식치가-동지함의`,``,`매사 영속성없음**근심해소사`,`종교진출하면자립함`,`매사 영속성없음**화소복지사`,`종교진출하면자립함`,`외사출행이동사`,`자식위한문서상신답신지체사`,``,`입실성사-관계성립사간음사이익-정대적은 되려 재앙`,`매사 위역사**외교막힘-반목사`,`굴욕난해사-감옥관련`,`외사급속사-내사출행이동사`,`불측근심사-이동사`,`내부가정파재극처사`,`상대애정난측사`,`내부가정결속사`,`손괴재물사`,`내사 급솏`,`승진영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사화합사`,`빈궁**관재`,`외사동요분산사`,`빈궁`,`전화위복`,`전도`,`내녀 색정사-무례대립사`,`전도`,`외사파재극처실종사`,`빈궁`,`재액해소**전화위복`,`빈궁`,`재액해소`,``,`가정가출사`,`빈궁`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**관재`,``,`빈궁**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`고위인행길중청원좋음`,`내사 급속사`,`외남 색정사**가정동요벼리개변사`,`정인재난병질사`,`문서망실사`,`매사 위역사**가정화합사`,`필유성취사`,``,`매사 영속성없음**내녀 색정사**남모르는 능욕박해사`,`종교진출하면자립함`,`매사 영속성없음**모사미수사`,`종교진출하면자립함`,`외사출행이동사`,`공공사사구설사`,`가족절상경구불년사`,``,`외교막힘-반목사`,`관직이익-평민불리함`,`외사급속사-내사출행이동사`,`도적 못잡고 놀램`,`내부가정파재극처사`,`문서가 몰래 옮겨짐`,`내부가정결속사`,`송구경황사-통정인 나타나 두려움`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`외사화합사`,`빈궁**관재`,`외사동요분산사`,`빈궁`,`전화위복`,`전도`,`가정무례대립사`,`전도**빈궁`,`외사파재극처실종사`,``,`재액해소**전화위복`,`빈궁`,`재액해소`,`빈궁**관재**병재`,`가정가출사`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`송구경황사-통정인 나타나 두려움`,`내부가정결속사`,`고위인행길중청원좋음`,`내사 급속사`,`외남색정사**가정동요별리개변사**외사화합사`,`정인재난병질사`,`매사 위역사**가정화합사`,`문서망실사`,`필유성취사`,``,`매사 영속성없음**내녀 색정사-무례대립사`,`종교진출하면자립함**남모르는 능욕박해사`,`매사 영속성없음**모사미수사`,`종교진출하면자립함`,`외사출행이동사`,`공공사사구설사`,`가족절상경구불녕사`,``,`외교막힘-반목사`,`관직이익-평민 불리함`,`외사-급속사-내사출행이동사`,`도적못잡고 놀램`,`내부가정파재극처사`,`문서가 몰래 옮겨짐`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**빈궁**관재`,``,`전도**빈궁**관재`,``,`외사동요분산사`,`빈궁`,`재액해소**전화위복`,``,`재액해소`,`빈궁**관재`,`외사파재극처실종사`,``,`전화위복`,`빈궁**관재`,``,`빈궁**병재`,`가정가출사`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`내부가정파재극처사`,`문서가 몰래 옮겨짐`,`송구경황사-통정인 나타나 두려움`,`내부가정결속사`,`고위인행길중청원좋음`,`내사 급속사`,`외남 색정사**가정동요별리개변사**외사화합사`,`정인재난병질사`,`매사 위역사**가정화합사`,`문서망실사`,`필유성취사`,``,`매사영속성없음내녀 색정사-무례대립사`,`종교진출하면자립함**남모르는 능욕박해사`,`매사 영속성없음**외사파재극처실종사`,`종교진출하면자립함**모사미수사`,`외사출행이동사`,`공공사사구설사`,`가족절상경구불녕사`,``,`외교막힘-반목사**가정가출사`,`관직 이익-평민 불리함`,`외사급속사-내사출행이동사`,`도적 못잡고 놀램`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁`,`동요불안분산사`,`전도`,`동요불안분산사**재액해소`,`관재`,`동요불안분산사*재액해소`,`빈궁**관재`,`외사동요불안분산사`,`전도**빈궁`,`전화위복**동요분산사`,`관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도`,`빈궁`,`동요불안분산사**전화위복`,`빈궁*병재`,`동요불안분산사`,`전도`,`동요불안분산사`,`음4,5월화재주의**동요분산사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외사급속사-내사출행이동사`,`도적 못잡고 놀램`,`내부가정파재극처사`,`문서가 몰래 옮겨짐`,`송구경황사-통정인 나낱 두려움`,`내부가정결속사`,`고위인행길중청원좋음`,`내사 급속사`,`외남 색정사**가정동요별리개변사**외사화합사`,`정인재난병질사`,`매사 위역사**가정화합사`,`문서망실사`,`필유성취사`,``,`매사 영속성없음**남모르는 능욕박해사`,`종교진출하면자립함`,`매사 영속성없음**모사미수사`,`종교진출하면자립함`,`외사출행이동사`,`공공사사구설사`,`가족절상경구불녕사`,``,`외교막힘-반목사**가정가출사`,`관직 이익-평민 불리함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,`전도`,`재액해소`,`관재`,`재액해소`,`빈궁**관재`,`외사동요분산사`,`전도**빈궁`,`전화위복`,`관재`,`내녀 색정사-무례대립사`,`빈궁`,`외사파재극처실종사`,`전도`,`전화위복`,`빈궁`,`빈궁**관재**병재`,``,``,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`외교막힘-반목사**가정가출사`,`관직 이익-평민 불리함`,`외사급솏-내사출행이동사`,`도작 못잡고 놀램`,`내부가정파재극처사`,`문서가 몰래 옮겨짐`,`송구경황사-통정인 나타나 두려움`,`내부가정결속사`,`고위인행길중청원좋음`,`내사 급속사`,`외남 색정사**가정동요별리개변사`,`정인재난병질사`,`매사 위역사**가정화합사`,`문서망실사`,`필유성취사`,``,`매사 영속성없음**남모르는 능욕박해사`,`종교진출하면자립함`,`매사 영속성없음**모사미수사`,`종교진출하면자립함`,`외사출행이종사`,`공공사사구설사`,`가족절상경구불녕사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,``,`관재`,``,`외사화합사`,`빈궁**관재`,`외사동요분산사`,`빈궁`,`전화위복`,`전도`,`내녀 색정사-무례대립사`,`전도**빈궁`,`외사파재극처실종사`,``,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`고위안좌-이익전포사`,``,`외교막힘-반목사**가정가출사`,`굴욕난해사-감옥관련`,`외사급속사-내사출행이동사`,`주력사 모두 흉함`,`내부가정파재극처사`,`경범사송사-고시득길사`,`봉림유사-숲속에서 뱀을 보는 격`,`내부가정결속사`,`고위인행길중청원좋음`,`내사 급속사`,`외남 색정사**가정동요별리개변사`,`간음무도사`,`매사 위역사**가정화합사`,`남모르는재물손해사-정인관련`,`도적난포사`,``,`매사 영속성없음**酒食受罰사`,`종교진출하면자립함`,`매사 영속성없음**손괴재물사`,`종교진출하면자립함`,`외사출행이동사`,`공공사사구설사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁`,``,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁`,``,`관재`,`외사화합사`,`관재`,`외사동요분산사**전화위복`,`빈궁`,`전화위복`,`빈궁`,`내녀 색정사-무례대립사`,``,`외사파재극처실종사`,`빈궁**병재`,`전화위복`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사출행이동사`,`남모르게재물이 옮겨짐`,`불리-도난사`,``,`외교막힘-반목사**가정가출사`,`관직이익-평님 불리함`,`외사급속사-내사출행이동사`,`재앙소멸사-이동사`,`내부가정파재극처사`,`상대애정난측사`,`모망난수사`,`내부가정결속사`,`견련반복사-단순 사 관련`,`내사 급속사`,`외남 색정사**가정동요별리개변사`,`간음이익사-정대적은 되려 재앙 됨`,`매사 위역사**가정화합사`,`문서망실사`,`정인의 재난병질사`,``,`매사 영속성없음**외사파재극처실종사`,`종교진출하면자립함**근심해소사`,`매사 영속성없음**송구경황사-사통자 출현`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁`,``,`전도**빈궁**관재`,``,``,`음4,5월화재주의`,`빈궁**병재`,`음4,5월화재주의`,`빈궁`,``,``,`빈궁**관재**병재`,``,`외사화합사`,`관재`,`외사동요분산사**전화위복`,`빈궁`,`전화위복`,`빈궁`,`내녀 색정사-무례대립사`,``,`외사파재극처실종사`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자**화소복지사`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`외사출행이동사`,`자식위한문서상신답신지체사`,``,`입실성취사-관계성립`,`매사 위역사**외교막힘-반목사**가정가출사`,`굴욕난해사-감옥 관련`,`외사급속사-내사출행이동사`,`불측근심사-이동사`,`내부가정파재극처사`,`상대애정난측사`,`손괴재물사`,`내부가정결속사`,`승진영전사`,`내사 급속사`,`외남 색정사**가정동요별리개변사`,`도적악의-공격말 것`,`매사 위역사**남녀직원공모사有**가정화합사`,`남모르는 재물손해사-정인관련`,``,`자강불식치가-동지함의`,`매사 영속성없음**근심해소사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사파재극처실종사`,`빈궁`,`빈궁**관재`,`전화위복`,``,``,`빈궁`,``,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁**병재`,``,`관재`,`외사화합사`,`빈궁**관재`,`외사동요분산사**전화위복`,`빈궁`,`전화위복`,`폐한인**전도`,`내녀 색정사-무례대립사`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자**근심해소사**화소복지사`,`음7,8,10,11월방문자는종교진출하면성공함`,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`외사출행이동사`,`자식위한문서상신답신지체사`,``,`입실성취사-관계성립`,`매사 위역사**외교막힘-반목사`,`굴욕난해사-감옥관련`,`외사급속사-내사출행이동사`,`불측근심사-이동사`,`내부가정파재극처사`,`상대애정난측사`,`손괴재물사`,`내부가정결속사`,`내사 급속사`,`승진영전사`,`외남 색정사**가정동요별리개변사`,`도적악의-공격말 것`,`매사 위역사**남녀직원공모사有**가정화합사`,`남모르는 재물손해사-정인관련`,``,`자강불식치가-동지함의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내녀 색정사**무례대립사`,`전도`,`외사파재극처실종사`,`빈궁`,`빈궁`,`재액해소**전화위복`,``,`재액해소`,`가정가출사`,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁**병재`,``,`관재`,`외사화합사`,`빈궁**관재`,`외사동요분산사`,`빈궁`,`전도`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 토지 관송사로 굴욕을 
  받고 있겠네? 
  집도 넘어가고… 
  가세가 기울고 있어! 
  당분간 은둔하면서 관재     고심을 기도생활로 대신
   하는게 제일 좋겠어!
⊕⊖신변고모신사부정 침범이      들었네 ? 
`,`■ 언덕이 있어야 비빌 텐데     의지할 데가 없네?
  진퇴양난이더라도 지키고    있어야 좋겠어 !
`,`■ 풍파가 거세네? 
  그런데다가 색정사까지 
  끼었네? 
  그러니 되는 일이 없지! 
⊕여귀ㆍ가택상가부정, 
⊖수귀ㆍ가택음사부정이 발동     했으니 이걸 잡아야 고진    감래이겠어!
`,`■ 땅에 송사가 붙었네? 
  권리를 상실하게 생겼어!
  윗 어른의 재해도 발생
  하겠고…


⊖신변음사부정이 침범했네 ? 
`,`■ 여러개를 한 개로 결속시키     겠는데 가지 많은 나무에     바람잘 날 없겠네? 
  돈도 잔뜩 깨지겠고…
女：⊕남의 남자를 적극 유혹       하여 따르고 있네?
`,`■ 자손일, 직위사, 박관사의     계획방침을 급히 변경해야     하겠어! 
  옛 일과 인연도 다했고… 
⊕신부정귀ㆍ가택저주원심부정, ⊖전사귀ㆍ가택저주원심부정의    침범 때문이야!

⊖신변음사부정도 침범했어 ! 
`,`■ 이별수가 보이네? 
  배반사가 있는데 부부각기     바람이 났어! 
  그러니 가정이 색정불화지!
  그리고 집에 죄인을 감추고     있겠어 ! 
⊕도로귀 
⊖자살귀 발동 때문이야!
⊕⊖신변저주원심부정도침범      했네 ?
`,`■ 몸이고 집이고 자충수 
 우환이 벌어졌겠어?
  힘들겠네? 
⊕도로귀 
⊖자살귀가 침범했네! 
 해소시켜야 천우신조를 
 얻겠어!

⊕신변음사부정도 침범했네? 
`,`■ 지푸라기라도 잡고 
  싶겠는데… 
  돈께나 깨지겠고… 
男:처첩이 손상 되겠어 !

⊕신부정귀ㆍ가택상가부정, ⊖전사귀 발동이야! 
해소하면 좋은날이 와!
`,`■ 망신살이 뻗혔네? 
  집안이 어수선하겠어! 
  죄인을 감추고 있겠어 !
  가정불화가 크네!

⊕여귀 
⊖자살귀가 침범했어!
⊕신변음사부정도 침범했네? 
`,`■ 변동수가 있네? 
  집도 옮기고 생업도 변화가     되겠는데 전전긍긍하는      심정이겠어 !

⊕신부정귀 
⊖전사귀를 없애야 장애를     받지 않겠어!
`,`■식구들이 등 돌리고 살겠네?    자존심을 버려야 큰 돈      벌겠어! 
 그런데 마음이 떠있네?
 기도하면 좋겠고,
⊕⊖가택고모신사부정을 
   잡으면 더 좋겠어!`];
  const YOGYEOL1 = [`■ 너와 내가 서로 시기하다. 점차 무례하기 까지 한다. 낮정단은 쓸모없는 귀인을 따르지 말고 집안에 있는 녹이나 지키라. 
  `,`■ 앉아서 지키는 게 좋고 추진한들 이익이 없다. 뒷길은 낭떠러지고 전진해도 좋지 않다.
  `,`■ 재물과 명예가 모두 비었고 병과 소송을 주의 하라. 나쁜 것만 눈에 비치니 욕심 내봐야 좋은 것은 없겠다.
  `,`■ 헛것으로 놀래고 재 물건은 복잡하게 된다. 
  겉으로는 합이 된 것 같으나 아니니 내부에서 해로운 일이 생긴다.
  `,`■ 범이 녹과 묘에 탔으니 낮정단은 질병, 밤정단은 실록이 두렵다. 낮정단은 귀인의 질책이 있고 매사 먼저 죽고 나중에 살아난다.
  `,`■ 낮정단은 모두 허망하고 밤정단은 귀인의 도움을 받지 못한다. 관직점은 불리하고 재앙이 생겨도 걱정은 없겠다.
  `,`■ 상대와의 교섭사는 배반되고 신음한다. 합과 이반이 심하다. 여름철 밤정단이면 불로 놀래는 일이 생기겠다.
  `,`■ 내가 힘들여 재물을 만드니 재수점은 좋다.
  나쁜 일로 재물이 생기고 부부나 상대하고 다툼이 있겠다.
  `,`■ 밤정단은 귀인이 나를 해치고 낮정단은 귀인이 있으나 마나다. 직장이나 직업으로 신경 쓰고 집안일은 후회나 막힘이 있다.
  `,`■ 얼굴은 착한 듯하나  독을 품었다. 근신하지 않으면 망신당한다. 재앙이 생기나 크게 되지 않고 복이 생겨도 별것 아니다.
  `,`■ 귀인이 있으나 무력하고 구하는 것도 얻기 어렵다. 집에 놀래는 일이 생기고 변동수에 시끄럽겠다. 
  `,`■ 역마와 정마가 함께 여럿이다. 움직였다 하면 큰돈이 없어진다. 숨어서 돕는자가 있으니 자존심 굽혀야 이롭게 된다.`]
  const SIBO1 = [`■ 가정에 육친골육의 
  무례사가 있다. 
  시기건이 생긴다. 
  금전사의 쟁탈문제로 심신이 부유한다. 교섭사는 막힌다. 부모 재해가 있게된다.심신의 여유를 갖고 기도ㆍ치성하면 吉하다.
  `,`■ 이권 상실사가 있다. 
  그러나 맹동하면 오히려
  해롭다. 
  진퇴를 중지하고 현상을 지켜야 이롭다. 1남 2녀의 삼각관계가 있다. 단, 범띠는 財를 획득한다.
  `,`■ 가정에 부정이 들어 
  이권을 상실한다.  
  제사도 공허하다. 
  길흉이 모두 이루어지지 못한다. 조용히 현재를 지키고 있는 것이 유리하다. 적극적인 처신은 해롭다. 덕행을 행하면 좋다.
  十月 임신사가 있다.
  `,`■ 저주사가 있다. 
  이권을 상실한다. 
  후원사도 빈 말 뿐이다. 
  윗어른에게 재화가 일어난다. 무슨 일이든 목적달성이 어렵다. 
  기회를 보고 현상에 만족해야 한다.
  十月 임신사가 있다.
  `,`■ 금전사등의 사물이 복잡
  하고 처음에는 굽히고
  나중에 펴진다. 
  ⊕女人은 타인의 남자를 적극적으로 유혹하여 따르고 있다. 男은 여자와의 이별사가 기한다.
  `,`■ 타인의 추천사가 있으나
  부족하다. 
  옛일, 인연을 결속하며 
  끝맺게 된다. 흉은 해산된다. 어떤 일이든 첫 조건에 결정해야 한다. 
  계속 기회는 없게 된다. 가정에 부정이 들었다.
  금전 여인의 재해가 있게된다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  사람과 물건 등을 감추고 있다. 남녀각각 외정을 갖고 부부이별의 운세이다. 가정 내 공포와 재액이 있다. 
  十月 임신사가 있다.
  `,`■ 자충수적 우환이 있고 
  가정에서 가장을 속인다.
  재수는 좋아 구재사에
  大財를 얻는다. 
  다른 목적은 후원 받을 수 없다. ⊕일이 반복되기만 하고 결정 되지 않고 있다.
  十月 임신사가 있다.
  `,`■ 자충수적 우환이 있고
  ⊕신불의 진노를 받고
  있다. 
  신불에 기도ㆍ치성하면 효과가 있다. 금전의 쟁탈이 있다. 남자는 급전이 크게 손모 되고 여자는 부부이별의 징조가 있다.
  `,`■ 신택 내외에 개변의 동
  요가 있다. 특히 내부ㆍ
  가정에서 흉이 발생한다.
  가정에 범인을 숨기는 일이 있다. 
  무슨 일이든 순리적인 진행이 유리하다.
  十月 임신사가 있다.
  ⊖일이 반복되고 결과가 없다.
  `,`■ 가택ㆍ생업 등의 이동ㆍ
  변동이 있다.
  신택 모두손모ㆍ손실이 
  있게 되므로 신중해야 한다. 吉凶은 辰日ㆍ辰月에 응이 있다. 움직이면 간난ㆍ저해가 있게 된다. 부모의 재해가 있게된다. 신불기원이 좋다.
  `,`■ 신변의 변동수가 있다.
  원행ㆍ이동사 계획한다.
  그것이 吉하다. 
  금전 쟁탈사가 있다. 서로 이해하고 넘어가는 것이 해를 면하는 길이다. 스스로 가서 상대 下人으로부터 능욕을 받는다.`]
  const SIBO2_1 = [`■財ㆍ女·분쟁·신변외사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■財ㆍ女,이권상실사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 부모의 재액이 있다.
  `,`■財ㆍ女,이권상실사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 재액이 있다.
  `,`■財ㆍ女,파재ㆍ극처사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 부모의 재액이 있다.
  `,`■ 財ㆍ女,손모사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  재·녀 부모 재해 예방이 필요하다.
  `,`■ 자손ㆍ박관가택내사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  가정손모사가 있다.
  `,`■ 직녹가택동요내사이다
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 손모가정이심사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  매사가 반복된다.
  `,`■財ㆍ女,가정내사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  재·녀 상실예방이 필요하다.
  `,`■官･夫,가택동요사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官･夫,이동후회사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女·분쟁·신변외사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/052/row-1-column-1.png`,
    `/static/images/sagwa/052/row-1-column-2.png`,
    `/static/images/sagwa/052/row-1-column-3.png`,
    `/static/images/sagwa/052/row-1-column-4.png`,
    `/static/images/sagwa/052/row-1-column-5.png`,
    `/static/images/sagwa/052/row-1-column-6.png`,
    `/static/images/sagwa/052/row-1-column-7.png`,
    `/static/images/sagwa/052/row-1-column-8.png`,
    `/static/images/sagwa/052/row-1-column-9.png`,
    `/static/images/sagwa/052/row-1-column-10.png`,
    `/static/images/sagwa/052/row-1-column-11.png`,
    `/static/images/sagwa/052/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `   이 과는 천지일여본궁(天地一如本宮)에 들고 복음과(伏吟課)이다. 진(辰)의 일간․초전에서 진(辰) 자는 참관격(斬關格)이다. 초전의 진(辰)은 재(財)가 되고, 중전의 묘(卯)는 간상의 진(辰)과 육해(六害)로 되나. 말전은 묘(卯)와 자(子)가 호형(互刑)이 되니 예(禮)가 없는 형이다. 
    복음과로서 상하 묘진(卯辰)의 육해가 되기 때문에 육친 골육의 저해(沮害)가 있거나 혹은 시기의 건이 생긴다. 이 때는 조용하게 지키고 있는 것이 좋다. 말전 자수(子水)는 일간을 생하는 유정(有情)의 신이지만 공망하므로 진정(眞情)이 결핍된다. 
    년명상신에 자(子) 자(字)가 있으면 공망을 풀고, 삼전에 같은 글자가 겹쳐서 세 개가 있으면 무슨 일이나 거듭 발생한다. 교차육해(交車六害)이면 교관교섭사(交關交涉事)는 좋지 않다.
    일귀는 역행하며 삼전은 구․청․상이고, 야귀는 순행하고 삼전은 구․합․귀이다.`
    ,
    // 2
    `   이 과는 제4과 축인(丑寅)에서 1하가 1상을 극하여 중심시입과(重審始入課)이며, 삼전은 축자해(丑子亥)로 퇴여격이다. 삼전은 모두 공망이다. 일간상신은 묘(卯)의 건록이 되고, 일간의 음신은 제왕(帝旺)이다. 즉 일간이 녹왕(祿旺)을 만나면 나의 왕(旺)에 의뢰하게 되는데, 이 때는 맹동(猛動)하면 좋지 않다. 앉아서 지키는 것이 이익이다.
    삼전이 모두 공망격이면 길흉이 모두 없으니 진퇴를 중지하고 현상을 지켜야 한다.
    비전에 이르기를 ｢병단(病斷)은 사격(死格)이 된다｣고 하였다. 일귀는 역행하고 삼전은 백․상․현(玄)이며, 야귀는 순행하고 삼전은 사․귀․후이다. 4과는 불비격(不備格)이고, 삼전은 밤중에 음기가 성하여 양을 보지 못하는 상이다.
    삼기(三奇)이나 효과가 작다.`
    ,
    // 3
    `   이 과는 제3과․제4과에서 2하(下)가 2상(上)을 극하니 섭해과(涉害課)이다. 사맹신(四孟神)의 극이 없고 사중(四仲)과 사계신이 극이 되면 사중의 극인 축묘(丑卯)를 사용하여 초전으로 한다. 
    초전은 일간에서 극하여 재동(財動)이 되지만, 공망하니 재력은 공허하다. 그러나 사(蛇)․호(虎)가 공망하면 타사(他事)는 흉을 보아도 흉을 만나지 않으며, 말전 유(酉)는 귀살이지만 중전의 인(印)에 의해 생화하니 흉은 덕행에 의하여 선화(善化)된다. 사주학의 살인생화(殺人生化)와 같은 이치이다. 
    삼전 축해유(丑亥酉)는 음경(陰境)의 지지뿐이니 극음(極陰)의 격이다. 무슨 일이나 조용하게 지키는 것이 좋고, 매사에 적극적으로 나서는 것은 좋지 않다. 초전․중전의 공망은 길흉이 모두 이루어지지 않는다. 공망을 탈하며 천장의 길흉에 따라서 발응(發應)한다.
    일귀는 역행하고 삼전은 현․후․사이며 야귀는 후․현․백이다.`
    ,
    // 4
    `   이 과는 제1과 축을(丑乙)에서 1하(下)가 1상(上)을 극하니 중심과이고, 삼계신(三季神)만은 가색격(稼穡格)이다. 귀신이 묘(卯)에 임하면 여덕격(勵德格)이다. 일간상신 축(丑)은 공망에 해당하고 초전이 되며, 일간일지상신 자(子)와 축(丑)이 지합하지만 어느 것이나 순공망(旬空亡)이어서 힘이 없다. 
    삼전이 모두 재성이면 인수(印綬)를 파하여 손윗 사람이나 존친에게는 이롭지 않다. 초․중전이 공망하고 전(傳)에 돌지 않으므로 무슨 일이나 목적을 달성하기 어렵다. 기회를 보며 현황에 만족하여야 한다.
    비전에 이르기를 ｢천재(天災)의 유무 또는 병점(兵占)할 때 축미(丑未)의 아래에서 충신(冲神)을 가리키면 3년 혹은 3개월을 넘지 않아 일이 발생한다｣고 하였다. 즉 축(丑)․미(未)천반이 상하 충(冲)이 되는 경우이다. 일지중신(日支仲神)의 상에 축(丑)이 승하여 초전이 되고 백호가 승하면 사망사가 있다. 일귀는 순행하고 삼전은 청․주․후이며, 야귀는 삼전이 동․음․백이다.`
    ,
    // 5
    `   이 과는 제4과 미해(未亥)에서 1상(上)이 1하(下)를 극하여 초전이 되며 원수과이다. 초전의 미(未)는 일간의 재로 되고 묘신에 해당하며, 중전은 일간의 건록이다.
    일간상신은 수생목(水生木)이 되어 생기(生氣)가 승하지만 공망이 되므로서 인수에 의뢰하기 어렵다. 삼전삼합은 곡직격(曲直格)이고 미(未)의 묘신에서 말전은 목(木)의 장생으로 되니 처음에는 망설이다가 후에는 각성한다. 원수과는 일이 순리롭다. 하지만 곡직(曲直)은 사물이 복잡하다. 삼전 후․합은 일녀격(泆女格)이니 부정(不正)을 방지해야 한다.
    비전에 이르기를 ｢삼전목국이 묘에서 녹이 되고 녹에서 말전이 생기로 되면 반드시 고생 끝에 영광을 보게 된다. 그리고 장수의 명이다｣라고 하였다. 
    일귀는 순행하며 삼전은 후․백․합으로 일녀격이며, 야귀는 삼전이 백․합․후이다.`
    ,
    // 6
    `   이 과는 제2과․제3과의 하에서 상을 극하는 맹신의 극이다. 오해(午亥)를 사용하여 초전으로 하고 섭해과(涉害課)이다. 
    일간상신은 일을 생하고 삼전의 체생도 힘이 없다. 중전․말전의 재관(財官)은 모두 공망한다. 초전은 실(實)로 처음에는 길이지만 중․말에 이르면 길흉을 이루기 어렵다. 오(午)는 해(亥)에 가담하여 사절격(四絶格)으로 된다. 구사는 결속짓기 때문에 우환을 정단하면 흉이 오히려 해산된다.
    일귀는 순행하고 삼전은 음․청․귀이며, 야귀는 역행하고 삼전은 공․후․구이다.`
    ,
    // 7
    `   이 과는 천지반 칠충하여 반음과(返吟課)이다. 천지 상극하여 무의격(無儀格)이며, 묘유묘(卯酉卯)는 삼교용전격(三交龍戰格)이다. 사상신(四上神) 진(辰)과 유(酉)가 지합하고 술(戌)과 묘(卯)가 지합한다. 
    지반은 진술(辰戌)과 묘유(卯酉)가 충극하여 합 중에 충이 있고 충 중에 합이 있다. 따라서 평화와 불화가 교호(交互)한다. 또 교차지합(交車支合)하고 성패가 있다. 
    비전에 이르기를 ｢삼전 묘유(卯酉)에 육합이 승하면 사람과 이별하고 재가 흩어질 징조가 있다. 일간 혹은 일지상에 묘유(卯酉)가 가해지면 삼전 묘유(卯酉)는 삼교격(三交格)이 되고, 사람과 물질의 음닉(陰匿)을 주관하다. 중전 신(辛)의 암귀(暗鬼), 유(酉)의 명귀(明鬼)가 택에 들어가면 가정 내의 공포 및 재액을 피면하기 어렵다.｣고 했다.
    일귀는 순행하고 삼전은 백․사․백이며, 야귀는 역행하며 삼전 현(玄)․합(合)․현(玄)이다. 사중일(四仲日)에 사중(四仲)의 시(時)는 삼교격(三交格)이 되어 간섭사(干涉事)가 연루되므로 망동하면 좋지 않다. 본분을 지키고 있으면 좋다.`
    ,
    // 8
    `   이 과는 상하의 극이 3과 중에 있고 하에서 상을 극하는 인유(寅酉)를 사용하여 초전으로 하는 중심과이다. 순수(旬首)의 인(寅)이 초전이 되면 순의(旬儀)로 되고, 천을귀신이 묘(卯)에 임하면 여덕격(勵德格)이다. 일간상신의 유(酉)가 초전을 극하고, 초전은 중전을 목극토(木剋土)로 극하며, 중전은 토극수(土克水)로 말전을 극한다.
    이런 것은 체극격(遞剋格)이라 하는데, 극하여 재동(財動)이 된다. 구재하면 재수(財收)를 많이 얻을 수 있다. 천장은 일귀․야귀가 모두 내전(內戰)․외전(外戰)․이 되고, 외전은 흉이라도 경하나 내전은 중하다.
    말전은 일간을 생하는 인수(印綬)이지만 공망하니 힘이 약하다. 
    일귀는 순행하며 삼전은 공(空)․후(后)․구(勾)이고, 야귀는 역행하며 삼전은 음(陰)․청(靑)․귀(貴)이다.`
    ,
    // 9
    `   이 과는 4과 중 3과에 상하의 극이 있고 섭해과(涉害課)이다. 맹신의 극이 없기 때문에 중상(仲相)의 극이고, 미묘(未卯)를 취하여 초전으로 한다. 
    일간상신에 관귀(官鬼)가 타지만 음신(陰神)으로 생하고, 살은 묘(卯)로 생화(生化)하여 해를 이루지 않는다. 삼전의 삼합목국은 곡직격이다. 일지의 택상에 묘신이 복(伏)하여 가인에게 회체(晦滯)가 있다. 
    섭해과의 곡직격은 처음에는 어렵고 후에는 쉽다. 또 먼저 굴(屈)하고 후에 편다. 초전에 재신이 승하여 비국(比局)에 있기 때문에 재에 대한 쟁탈이 생긴다. 목(木)은 봄에 왕하므로 춘월에 정단하면 왕해서 발달한다.
    자(子)와 미(未)는 육해이고 또 신(申)과 해(亥)가 육해로 되어 합 중에 해(害)가 있으며, 암암리에 타인의 해를 초래한다. 초․중전에 육합이 승하면 일녀격이 되어 여인의 유혹을 받는다.
    비전에 이르기를 ｢해(亥)가 미(未)에 가담하고 사신(蛇神)이 승하면 내전이 되고 실재(失財)하는 일이 있다｣고 하였다. 또 말하기를 ｢미(未)가 묘(卯)에 가해지고 천후가 승하면 여인의 재병(災病), 또는 이별의 징조가 있다｣고 하였다. 
    일귀는 순행하며 삼전은 후․합․백이고, 야귀는 역행하고 삼전은 청․사․현이다. 일간상신에 천을이 승하여 정관이 되면 관직을 정단하면 길하다.`
    ,
    // 10
    `   이 과는 제1과․제4과의 하에서 상을 극하고 중심(重審)을 취할 수 없기 때문에 섭해과로 된다. 맹신(孟神)의 극이 없으므로 중신(仲神)의 극이다. 유오(酉午)를 사용하여 초전으로 하고 중일(仲日)에 삼전 유자묘(酉子卯)는 삼교격(三交格)이다. 삼교격은 길 중에 흉이 있고 흉 중에 길이 있다. 무슨 일이나 순리(順理)로 서행하면 이루어진다.
    초전은 귀효(鬼爻)로서 제지(制地)에 있으면 사관․구직은 저해(沮害)가 생기고, 다른 정단은 재(災)가 있으나 파면할 수 있다. 진월(辰月)의 정단은 천번격(天煩格)이 되기 때문에 제반사(諸般事)에 신중을 요한다. 중․말전이 공망하여 전(傳)에 돌지 않는다.
    비전에 이르기를 ｢삼교격은 길흉이 모두 안에 의한 것이다｣고 하였다. 또 이르기를 ｢후합현음(后合玄陰)과 유묘가림(酉卯加臨)은 도난을 만난다｣고 하였다. 
    초전이 일간을 극하고 귀효이지만, 오화(午火)에서 제를 받으니 집안 사람의 흉을 예방한다.
    일귀는 역행하며 삼전은  후․상․청이고, 야귀는 삼전이 합․귀․현이다.`
    ,
    // 11
    `   이 과는 4과 중에 제2과에서 1하가 1상을 손상하니 신오(申午)가 초전이 되는 중심과이다. 일신(一神)을 건너 삼전을 이루니 순간전격(順間傳格)이다. 삼전 신술자(申戌子)는 섭삼연격(涉三淵格)이고, 일지상신은 정신(丁神)이자 역마로 되어 가택 동요의 징조가 있다. 초전은 관귀효이지만 일간일지상신 사오(巳午)의 화(火)기는 귀를 제하므로 흉은 이루어지기 어렵다. 섭삼연은 함부로 움직이면 간난하고 저해가 있다.
    일간일지는 탈기가 되고 또 교차탈설(交車脫泄)하니 신택(身宅)이 모두 소모와 손실이 있으므로 경거망동하지 말아야 한다.
    비전에 말하기를 ｢일간의 탈기는 오(午)에 있다. 또 천공이 승하면 탈공격(脫空格)으로 되어 무슨 일이나 부실(不實)하고 실의(實意)가 없다｣고 하였다. 초․말전에 있고 삼합국은 진(辰)의 일신(一神)이 없기 때문에 길흉이 모두 진일(辰日) 또는 진월(辰月)에 성사된다. 이와 같은 것을 기일대용(期一待用)이라 한다. 기타 삼합에서도 일신이 부족할 때는 이 법칙을 사용하여야 한다. 말전이 공망이니 길흉이 모두 정단사는 결과가 없다고 보아야 한다. 
    일귀는 역행하고 삼전은 귀․음․상이며, 야귀는 삼전이 구․주․귀이다.`
    ,
    // 12
    `   이 과는 제3과 진묘(辰卯)에서 1하가 1상을 극하는 중심진여과(重審進茹課)이며, 제3과 진(辰)의 천강이 초전으로 되어 참관격(斬關格)이다.
    초전은 일간의 재성에 해당하고, 중전 사(巳)는 정신으로 역마가 되어 일간상신에 돌아가고 신심(身心)의 동의(動意)․동조(動兆)를 이룬다. 초전의 재는 중․말전에서 생하므로 재의 바탕이 튼튼하여 구재수익(求財收益)을 크게 얻을 수 있다.
    참관격은 안거에 불리하고, 이동․개변 등에서는 가조(佳兆)로 된다. 구진(勾陳)이 진(辰)에 승하면 재물 쟁탈의 상이 있으니 서로 너그러이 용서하여 해를 면해야 한다. 일간기궁이 일지에 임하고 지(支)에서 극을 받으면 난수격(亂首格)이다. 
    일간을 존(尊)으로 하고 지(支)는 부하로 한다. 상이 하에 임하여 극하기 때문에 이런 이름을 가진다. 또한 하는 상을 극하여 가문을 배역하고 예의에 벗어난다. 난수격은 무슨 일이나 안에서 발생하는데, 밖에서 공격하는 것은 좋지 않다.
    일귀는 역행하며 삼전은 구․합․주이고, 야귀는 순행하고 삼전은 구․청․공이다. 이 과는 중․말전 사오(巳午)의 광명을 나타내기 때문에 사물은 전진하고 장래 광명진발(光明進發)한다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
