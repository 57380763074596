import React, { useState } from "react";
import firebase from "../firebase";
// import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCheckCircle, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function Todo({ todo }) {
  const [textInput1, setTextInput1] = useState("");
  const [textInput2, setTextInput2] = useState("");
  const [textInput3, setTextInput3] = useState("");
  const [textInput4, setTextInput4] = useState("V");
  const [textInput5, setTextInput5] = useState("");

  const deleteTodo = () => {
    const todoRef = firebase.database().ref("Todo").child(todo.id);
    todoRef.remove();
  };
  const completeTodo = () => {
    const todoRef = firebase.database().ref("Todo").child(todo.id);
    todoRef.update({
      complete: !todo.complete,
    });
  };

  const editTodo = () => {
    const todoRef = firebase.database().ref("Todo").child(todo.id);
    todoRef.update({
      title: textInput1,
    });
  };
  return (
    <li className={todo.complete ? "complete" : "list-item"}>
    <div className="flex-column ">
    <label for="imageType">Title/ 제목</label>
    <input
      type="text"
      value={todo.title === "" ? textInput1 : todo.title}
      className="list"
      onChange={e=> {
        setTextInput1(e.target.value);
      }}
    />
      </div>
    <div className="flex-column ">
    <label for="imageType">Image </label>
    <input
      type="text"
      value={todo.videoUrl === "" ? textInput3 : todo.videoUrl}
      className="list"
      onChange={e=> {
        setTextInput3(e.target.value);
      }}
    />
    <img className="table-td-img" src={ todo.imageUrl}/>
    <div className="">
    </div>
      </div>
    <div className="flex-column ">
    <label for="imageType">Order/ 순서</label>
    <input
      type="text"
      value={todo.order === "" ? textInput2 : todo.order}
      className="list"
      onChange={e=> {
        setTextInput2(e.target.value);
      }}
    />
      </div>
    <div className="flex-column ">

    <label for="imageType">Type of Image</label>

    <select name="imageType" id="imageType" 
      value={todo.type === "" ? textInput4 : todo.type} 
      onChange={e=> {
        setTextInput4(e.target.value);
      }}
      >
      <option value="V">V (영상/비디오)</option>
      <option value="Y">Y (연혁)</option>
      <option value="Z">Z (조직도)</option>
      <option value="A">A (인사말씀)</option>
      <option value="B">B (저술목록)</option>
      <option value="C">C (강의안내)</option>
      <option value="D">D (앱/도서/프로그램 효과 활용 설명회)</option>
      <option value="E">E (컬럼 마당)</option>
      <option value="F">F (회원 동정)</option>
      <option value="G">G (공지)</option>
    </select>


    </div>
      {/* <h1 className={todo.complete ? "complete" : ""}>{todo.title}</h1> */}
      <div>
        {/* <button onClick={deleteTodo}>Delete</button>
        <button onClick={completeTodo}>Complete</button> */}

        <button className="button-complete task-button" onClick={completeTodo}>
          {/* <i className="fa fa-check-circle"></i> */}
          <FontAwesomeIcon icon={faCheckCircle} className="fa fa-check-circle" />
        </button>
        <button className="button-edit task-button" onClick={editTodo}>
          {/* <i className="fa fa-edit"></i> */}
          <FontAwesomeIcon icon={faEdit} className="fa fa-edit" />
        </button>
        <button className="button-delete task-button" onClick={deleteTodo}>
          {/* <i className="fa fa-trash"></i> */}
          <FontAwesomeIcon icon={faTrash} className="fa fa-trash" />
        </button>
      </div>
      <style>{`
      .flex-column {
        display: flex;
        flex-direction : column;
      }
      `}</style>
    </li>
  );
}
