const test0 = `HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA `
const testEn0 = `HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA HYOSA   `;

const default0 = `HYOSA `;
const defaultEn0 = `HYOSA  HYOSA `;
const default1 = `HYOSA  `;
const default2 = `HYOSA  `;
// ·
const before1 = `
<div style="font-size: 15px;">
<span style="font-weight: 700; font-size: 18px;">
'장중비결' '육임 AI' 보기요령
</span>
<br/>
<br/>
<br/>
<span style="font-weight: 700; font-size : 17px; ">一. 메뉴</span><br/>
<br/>
* '지역 설정'<br/>
  육임은 시간을 주요시하는 '방문(전화) 시간' 체계이다.<br/>
  판단하는 장소가 어느 지역 인가?를 알아 해당 지역에 클릭해두면 <br/>
  프로그램 내부에서 자동으로 그 지역 시간 변동에 따라 작동한다.<br/>
  지역을 옮기는 경우 다시 '지역설정' 메뉴에 들어가 옮긴 지역에 클릭한다.<br/>
  우리나라 동부, 중부, 서부 지역에 따라 시간의 경계가 다르기 때문이다. 해외 지역 역시 일자와 시간이 다르기에 하루전과 하루 후로 구동 되는 경우가 있게 된다<br/>


<br/>
* '일자 변경'<br/>
  이 일자변경을 통해 '내일 올 사람들'의 사정을 오늘 미리 알 수 있다.<br/>
  또 한 달 전, 석 달 후 어느 날 왔었거나 올 사람들의 속사정을 되돌아보거나, 또는 미리 알게 된다. <br/>
  그리하여 내일 올 사람들의 핵심사항을 오늘 정리해 놓으면 편리하고 방문자 앞에서 PC를 보고 <br/>
  말하지 않아도 되는데 메모 한 장이면 하루 다수인의 상담이 충분하게 된다.<br/>
  이 부분은 우리 개개인의 성향에 따라 매우 중요하고 요긴한 사항이다.<br/>
  맨 아래에 양식을 첨부했으므로 각자 필요한 사항을 첨삭하면 될 것이다.<br/>
<br/>
*'관리'<br/>
 상담한 주요 내용를 메모해 저장해 둔다.<br/>
 이것은 이외로 중요하다. 많은 방문자, 전화자를 상대하다보면 전에 해줬던 상담내용을 잊는 경우가 많다. <br/>
 우리의 말이 일관성을 잃게 되는 것을 방지하기 위해서는 가능한 대로 이 메뉴를 적절히 활용하는 것이 좋다.<br/><br/>
<br/>
*'같은 시간 안에 @@@@@다음사람'<br/>
  매우 중요하다.<br/>
  이 부분만 잘하면 육임은 틀림이 없다.<br/>
  본 프로그램은 매일 매 시간마다 방문(전화)자의 온갖 사정(비밀)들이 모두 떠있게 만들어져 있다.<br/>
  각 지역별 '지역 설정'에 의해 실시간이 바뀌면 첫 화면에 “시간을 변경하시겠습니까?”하는 문구가 뜨는데 <br/>
 “예”를 누르면 다음 실시간으로 변경되고 “아니요”를 누르면 현재 상담내용이 그대로 유지된다.<br/>
  같은 사간 안에 여러 사람이 방문(전화)하여 상담을 원할 때는 순서대로 '다음사람'만 클릭하면 <br/>
  그 해당인 에게 맞는 내용이 바로 뜬다. 이점, 의심하지 말고 바로 말하라. 맞는다.<br/>
  만약 시간 구분과 순서를 혼동했을 때는 주저 말고 숫자를 1-12 중에서 하나를 고르라고 해 방문(전화)자가 <br/>
  그중 하나를 고르면 화면에 나타나 있는 해당 숫자를 누르라. 즉시 해당인 에게 맞는 내용이 뜬다. <br/>
  이때 숫자를 고르게 하는 것이 불편하다면 아예 그릇 등에 숫자나 12지지를 써 넣어 두었다가 그것을 뽑게 해도 된다. <br/>
  또는 대기실에 두고 이 숫자를 뽑아 들어오게 해도 된다.<br/>
  참고로 12지지의 '자연수'가 해당 숫자이다. 즉, 子는 1수, 卯는 4수, 亥는 12수 인 것이다. <br/>
   또, 12방위를 설정해 놓은 것은 어떤 모임의 상대가 앉은 방위를 시간으로 잡아 버튼을 누르면 상대의 사정이 드러나도록 했다.



  숫자 밑에 해당지지를 달아놓았다. 다만 이 방법은 @@@@@'다음사람'을 쓰는 방법보다 속도성은 좀 낮다고 할 수 있겠다. <br/>
  육임은 속도성 제1의, 기선제압에 매우 적절한 오행술 인 이유이다. 즉 방문(전화)자로부터 한마디의 말을 듣지 않고도 <br/>
  문(전화)에 들어선 자의 모든 정황을 미리 알아 먼저 지적해낼 수 있기 때문이다.<br/><br/>
  그렇기에 가장 좋은 방법은 상담실과 대기실을 구분하여 몇 사람이 와서 대기하든 일단 상담실 문을 열고 들어서는 <br/>
  사람만을 대상으로 '다음사람'을 누르는 방법을 쓰는 것이다. <br/>
  이것은 우리의 환경적 요인을 육임에 맞게 개선시켜 우리의 심중 번잡을 피할 수 있으면서 정확도와 효과성을 높이는 <br/>
  방법이라고 할 수 있겠다.<br/>
<br/>
* '정단시간 표시'<br/>
  첫 화면 상단에 낮 시간 것은 적색 지지로, 밤 시간 것은 청색 지지로 나타난다.<br/>
  또 낮시간은 첫창 상단에 적색 선을, 밤 시간에는 바다색 선을 표시해 놓았다<br/>
  육임은 차객법(次客法)이라는 것이 있어 동일한 시간에 여러 사람이 와도 각각 나누어 판단하게끔 되어 있으므로 <br/>
  순서에 따라 '다음사람'을 누르면, 낮에 왔더라도 밤 시간에 해당되는 사람이 있고 밤에 왔더라도 <br/>
  낮 시간에 해당되는 사람이 있게 된다. <br/>
  그렇다면 본문 내용도 낮 시간 것과 밤 시간 것을 구별해 볼 필요가 있게 된다. 그래서 낮 시간 것 내용에는 <br/>
  '플러스' 표시로, 밤 시간 것 내용에는 '마이너스' 표시를 반드시 해놓았다. 이 표시를 잘 보아 그 해당 내용을 말하면 맞는다. <br/>
  특히 '초사' 칸 침범귀신, 부정빌미 구별에 이점 유의하기 바란다.<br/>
<br/>
* '방문(전화)자의 우리에 대한 신뢰도 여부 알기'<br/>
  방문(전화)자가 우리의 말과 판단에 대해 신뢰를 하면 '신뢰'라고 적시해 놨고, 자기 고집이 있을 때는 '고집'이라고 <br/>
  적시해 놨고, 좌고우면 하는 자에 대해서는 '설득'이라고 써 놨고, 우리와의 관계가 이루어질 수 없는 자에게는 <br/>
  '불성'이라고 써 놨다.<br/>
  이 두 자씩의 짧은 단어에는 우리의 방문(전화)자에 대한 처신의 묘리가 담겨있다. <br/>
  신뢰자에 대해서는 자연스럽게, 고집자에 대해서는 확신적으로, 설득자에 대해서는 논리적으로, <br/>
  불성자에 대해서는 다음 기회로 미는 것이 보다 현명한 처신이 될 것이다.<br/>
<br/>
* '수법(부적/ 천도/ 시식/ 기도 등) 효과 미리 알기'<br/>
  우리가 상담(친견)을 청해온 방문(전화)자를 상대로 각종 수법을 행한다면 그 수법이 '효과가 있을 것인가?' '없을 것인가?'<br/>
  를 미리 알고 작정하도록 해 놨다.<br/>
  첫 화면 상단 '수법효과 여부'에 표시해 놓은 “수법효과 있다”, “수법효과 없다. 0일 후  재 정단 하라”를 잘 봐야 한다. <br/>
  낮 시간 것에는 적색, 밤 시간 것에는 청색으로 써 놨다.<br/>
  “수법효과 있다”고 되어 있으면 당장 택일에 들어가고, “효과 없다. 0일 후 재 정단 하라”고 되어 있으면 <br/>
  판단일이 들어있는 그 순(旬)이 끝나고 다음 순이 오는 어느 날 다시 오라고 하여 그때 방문(전화)한 시간에 의한 <br/>
  해당 국수 첫 화면 상단에 낮 시간, 또는 밤 시간으로  “효과 있다”고 되어 있을 때 택일을 해야 옳다는 것이다. <br/>
  그런데 3회까지 “효과 없다”가 되면 포기하라. 천기가 해당 인을 위배하고 있는 것이다.<br/>
  가능한 이 원칙을 지켜 가는 것이 장차 발전의 바탕이 된다. 무턱대고 수법만 한다고 다 효과가 있게 되는 것이 아니기 때문이다.<br/>
  그런데 이 비율이 6대 4로 “효과 있다”가 부족하다. 그러나 이 부족성 4는 “효과 없다”의 6을 '커버'하고 남는다는 것을 <br/>
  빨리 알아챌 필요가 있다. 그러니까 우리가 하는 수법은 반드시 효과가 있어야 하고, 효과를 본 사람들만 우리 곁에 있어야 하며, <br/>
  또 그랬을 때 우리의 발전이 장차 적으로 약속된다는 것이다.<br/>
<br/>
<br/>
<span style="font-weight: 700; ">二. 당면사- 미리 알아 먼저 지적 하기</span>
<br/>
<br/>
1. '초사(初辭)'<br/>
  밤문(전화)자에게 제일 먼저 던질 말이다.<br/>
  자신있게 말하라. 맞는다.<br/>
  여기에는 칸 하단에 침범 '귀신'과 침범 '부정빌미'를 밝혀 놓았는데 그 적중률은 10여년에 걸쳐 전국에서 검증되어 있다. <br/>
  반드시 그 가족력이 드러나는 것이다. “아니다, 가족 중 그렇게 죽은 사람이 없다”고 하는 경우가 더러 있지만 돌아가서 <br/>
  연락을 해와 “어른에게 물어보니 맞더라”하는 사례들이 많다.<br/>
  단, 플러스 표시는 낮 시간 것, 마이너스 표시는 밤 시간 것임을 기억해 해당 것을 지적하면 틀릴 걱정이 없다. <br/>
  간혹 플러스, 마이너스 표시가 함께 된 것이 있으면 낮 밤 시간 모두 해당 인에게 적용된다는 것으로 알면 되겠다.<br/>
  참고로 이 귀신과 부정을 말할 때는 믿어 의심치 말고 마치 '보이는 것처럼' 말하는 것이 효과적이라는 점이다. <br/>
  이 의미를 상고하기 바란다.
  <br/><br/>
2. '왜 왔는가?'<br/>
 육임의 시보(時報)이다.<br/>
 방문(전화)자가 무슨 일로 왔는지가 여기에 나타난다.<br/>
 이 칸 맨 상단 부분이 핵심이다. 그 밑줄 부분은 부연이다.<br/>
 신외 남자(身外 男子)는 남자에 해당하고 바깥일이고, 가내 여자(家內 女子)는 내부 가정사이고 여자에 해당한다.<br/>
<br/>
3. '정황(情況)'<br/>
 육임 내정 부분이다.<br/>
 여기에는 방문(전화)자의 속사정, 자기만 아는 비밀들이 드러난다.<br/>
 믿고 말하라.<br/>
그리고 그 효과에 대해 상고해 보기 바란다.<br/>
<br/>
4. '전체적 사정'<br/>
 육임 과체(課體)로 아는 것이다.<br/>
 방문(전화)자의 총체적 근황 관계, 또는 처신 방침이 나타나 있다. 절대 그 범위를 벗어나지 않는다. <br/>
 같은 칸 흑색 삼각표시를 누르면 그 근거가 나타난다. 읽어두면 숙지가 될 것이다.<br/>
 (이 칸에 '귀인이 순행하면'+, '귀인이 역행하면'- 하는 표시가 나타날 것이다. 이것을 바로 알려면 첫 화면 상단의 <br/>
 '수법 효과 있다' '수법 효과 없다'로 안다. 효과 있다는 것은 귀인 순행이고, 효과 없다는 것은 귀인 역행에 해당되는 것이다)<br/>
<br/>
5. '특별한 사정'<br/>
 육임 격(格)으로 안다.<br/>
 방문(전화)자의 특별한 일을 지적하는 것으로서 아주 잘 맞는다. 과감하게 말하라. <br/>
 이때 흑색 삼각단추를 눌러 숙지해 두면 천금으로 사용된다.<br/>
<br/>
6. '배경 사항'<br/>
 가. 전개사안 : 되어 질 일이다.<br/>
 나. 내외표리 : 바깥일인가? 내부 집안일인가? 드러내도 되는가? 숨기고 싶은 일인가?<br/>
 다. 미기아타 : 일이 아직 일어나지 않고 잠정적인가? 이미 일어났는가?<br/>
               자기가 저지른 일인가? 타인에 의해 발생 했는가?를 이 표시로 안다.<br/>
 그러므로 상담(친견)은 절대 단독으로 해야 한다. 일행도 마찬가지다. 타인에게 숨기고 싶은 일이 많기 때문이다. <br/>
 여러 사람, 옆에 누가 있으면 속사정이나 비밀을 지적하는 우리의 말은 부인되기 십상이다. 중요한 부분이다.<br/>
<br/>
7. '내외 변화'<br/>
 육임 내외 변수로서 일간 기궁과 방문(전화) 시간 등 육임 사과삼전 각 부위개소와의 관계에서 파생된다. <br/>
 합 충 형 해 파 공망으로 부터 표현되어 있다. 그러므로 설사 당장은 맞지 않더라고 반드시 그렇게 전개된다.<br/>
<br/>
8. '공망 재해'<br/>
 중요하다.<br/>
 각 공망 지지와 공망된 통변과 공망 천반지지에 승한 천장을 표시했다.<br/>
 공망 통변은 그 통변성의 불성(不成) 및 기복성(起覆性)으로 판단하고 그 통변성을 육친(肉親)으로 바꿔 해당 <br/>
 육친의 재해(災害)로 판단하는 것이다. 갑술순과 갑진순은 같은 오행 2개가 공망된다. 이는 숙명이 된다.<br/>
 전문가들은 이 공망 통변과 공망 육친만으로 불성 및 재해를 부족 없이 판단한다. <br/>
 익숙해질수록 우리의 판단과 말은 간단해지고 '적중한' 지적에 국한하며 더 이상 확장시키지 않게 된다. <br/>
 이 부분에 대해 상고할 필요가 있을 것이다.<br/>
<br/>
9. '대적(對敵)처신 방침'<br/>
 육임 전쟁정단 중에서 발췌한 것으로서 이것으로 상대와의 대적 관계(소송, 다툼, 이해관계, 시비 등)에서의 <br/>
 처신 방침을 교시해 줄 수 있다.<br/>
 이 부분은 이외로 뚜렷한 교시성이 제한되어 우리를 곤혹스럽게 하기 때문에 이를 굳이 실어 놓았다. <br/>
 많은 도움이 될 것이다.<br/>
<br/>
10, '양, 음택 좌 향 길흉 관계'<br/>
 생자나 사자나 각 그 띠 별로 맞는 좌 향이 있는데  표시된 띠에 사는 집이나 묘지의 좌향이 맞으면 흥가 하고 <br/>
 맞지 않으면 패가 패손 한다.<br/>
 황색은 길, 길사 발현 년 월이고 백색은 흉, 흉사 발현 년 월이다.<br/>
 여기에서는 좌 만 표시되어 있는데 이 좌는 집 뒤쪽을 말하고 사자의 머리 쪽을 말한다. <br/>
 그렇다면 향은 대충 방위, 즉 반대쪽이 된다.<br/>
<br/>
11. '월별 신수'<br/>
 판단하는 해당 년의 해당 월 신수가 이 칸에 있다. 이 칸 맨 위 칸은 종합, 백색 칸은 낮 시간 것, <br/>
 흑색 칸은 밤 시간 것이므로 낮 시간에 해당되면 종합과 백색 것을, 밤 시간에 해당되면 종합과 <br/>
 흑색 것 두 개를 말하면 된다.<br/>
<br/>
12. '선, 악인 판단'<br/>
 방문(전화)자의 선천성이 선한가? 보통인가? 악 한가?를 흑색 바탕에 표시되는 이것으로  안다. <br/>
 매월(중기- 중기 사이)마다 월장이 바뀌고 매 해당 시간마다 선,보,악 지지가 바뀌므로 해당인 <br/>
 모두에게 여착 없이 적용된다.<br/>
 우리는 이 세상의 선생이다. 이 선보악 구별에 의해 상담, 수법, 금전 교부 등에 참고할 필요가 있다.<br/>
 또 방문(전화)자가 묻는 이해관계의 상대에게도 여기에 나타난 천성을 말해주면 된다.<br/>
 (월장가시 후 천반 자하 지지가 사맹지는 선인, 사중지는 보통인, 사계지는 악인이다)<br/>
 이점 반드시 점검하기 바란다. 프로그레머의 일정관계로 9차 업데이트에서 수정하지 못했다.<br/>
<br/>
13. '대사(大事) 호신(護身) 택인 택일 택문 택색 선택 해주기'<br/>
  '축비'의 도교술수서 '육임대점'에서 발췌한 것이다.<br/>
  옛날 육임을 아는 황제의 책사들이 전쟁에 나갈 때 반드시 이 법을 쓰도록 했다.<br/>
  현대에 맞춰 적시했다.<br/>
<br/>
   가. 장수(책임자)를 무슨 띠로 고르게 할 것인가? 정단일의 식상 띠.<br/>
   나. 출진 날(택일)은 어떤 날로 하게 할 것인가? 정단일의 식상 일.<br/>
   다. 출진 시간(택시)은 몇 시로 하게 할 것인가? 정단일의 식상 시.<br/>
   라. 출진 문(택문)은 어느 방향으로 나가게 할 것인가? 정단일의 식상 문.<br/>
   마. 복색(깃발)은 무슨 색으로 하게 할 것인가? 정단일의 재성 색.<br/>
<br/>
  참고로 판단일의 식상 띠, 날, 시, 문 이고 다만 복색(깃발)만 재성 색이 되고 있다.<br/>
  관살(병, 살상)을 극하는 식상을 써 적으로부터 피해를 줄이자는 육임법의 탁월한 지혜이다. <br/>
  찬지 만물이 이 기운에 의해 생장 소멸을 거듭한다는 주의가 우리의 연구 논리일진대 이 법에서 <br/>
  큰 지혜를 발견할 수 있을 것이다.<br/>
<br/>
14. '비방(秘方)'<br/>
<br/>
 *귀신치병고법 : 병난 날을 알면 그 이유를 알 수 있다. 수법을 달았다. 불가에서 써온 전래 수법이다.<br/>
<br/>
 *가택부정해소 : 매월 달라지는 북두칠성의 사기제거 기운을 차용하는 것이다. <br/>
 명시해 놓은 방위에서 모래를 퍼와 집 안팎에 뿌린다. 사기가 제거된다. <br/>
 특히 수법 전에 이 방법을 쓴 후 하게 되면 더 좋은 효과를 거둔다. <br/>
 이외 정신 산란자, 악몽자, 우환 등에 효과가 있다.<br/>
<br/>
 *옥추비법 : 현대에 와서 우리가 가장 간편하게 쓸 수 있는 제례의식이요, 부적법에도 효용이 있다. <br/>
 해당 사안이 구체적으로 구분되어 있어 각 직업군의 호신부로 활용하기 좋다.<br/>
<br/>
 *귀신 침법 : 정신혼미 자, 귀신들린 자 에게 쓰면 효과가 있다. 단, 우리가 잘 아는바와 같이 <br/>
 침은 제한이 있어 유의할 필요가 있을 것이다. 참고로 실었다. 눕게 하여 침을 꼿으면 <br/>
 땀을 흘리거나 잠이 들거나 발광을 하거나 욕설을 하는데 우리에게 덤비지는 못한다. <br/>
 발광자에게는 우리도 같이 강력하게 대응하면 빙의된 귀신이 물러가는 사례가 많다.<br/>
<br/>
 *가택 부정 진압법 : 가정에 부정드는 경우가 많다. 그 부정을 밝혀 놓았는데 여기에서 해소방법을 <br/>
 적시해 두었다. 쉽고 효과도 있다. 많이 활용 될 것이다.<br/>
<br/>
 *천기법 : 중독성이 있을 정도로 효과가 있다. 과학성이 담보된 현대 부적법이 될 것이다. <br/>
 육임의 정수이기도 하다. 해당 칸 맨 위 기(氣)는 쓰는 지지 글자이고 좌측 색(色)은 쓰는 종이이고 <br/>
 우축 수(數)는 쓰는 종이 장수와 매장에 쓰는 지지 글자의 수고 아래 칸 방(方)은 부적을 붙이는 방위 <br/>
 태울 방위 쓰는 지지 글자의 색을 말한다.<br/>
<br/>
 *신명법 : 무속에서 주로 쓴다. 많은 활용이 될 것이다. “꿩 잡는 게 매다”는 격언을 알면 방법에 <br/>
 고하가 없다는 것을 이해할 것이다.<br/>
<br/>
15, '응기(應期)'<br/>
  현재의 흉이 해소되는 일, 또는 월 알기 이다. <br/>
  방문(전화)한 시간 지지가 왕상 하면 일로 치고 휴수사 이면 월로 친다.<br/>
  기도일 수, 또는 부적 장수를 이 일의 수나 월의 수로 환산하여 맞춰하면 이사기적을 일으킬 수 있을 것이다.<br/>
  또 일간상신 천반지지로 부터 일간의 12운 절 지지가 되는 날이 흉 해소일로 되기도 한다.<br/>
 <br/>
16. '소망사가 이루어지는 날, 또는 월 알기' <br/>
  방문(전화)한 시간 지지가 왕상 하면 일로 치고 휴수사 이면 월로 친다. 요령은 위 15항과 같다.<br/>
<br/>
17. '이사 길방 찾아 주기'<br/>
  해당 국수에서 가장 좋은 방위를 골라놓았다.<br/>
  이 방위와 육합 일에 적시한 방위로 이사를 가게 되면 그 이사 방위 월에 반드시 길사가 발현된다는 것을 <br/>
  말해주라.<br/>
  단, 꼭 이사 가는 날이 이사 가는 방위와 육합일이어야 한다.<br/>
<br/>
18. '택일 정해 주기'<br/>
  고등한 육임 법칙에 의해 산출되어 있다.<br/>
  일반 택일법과 배치되더라도 걱정할 것 없다.<br/>
  이것을 많이 활용할 것을 권한다.<br/>
 <br/>
19. '일일 행상, 영업 판매실적 효과 방위 알려주기'<br/>
  하루하루 판매실적이 필요한 방문(전화)자에게 요긴한 육임 법칙이다.<br/>
  이 칸에 적시된 방위를 말해주라. 큰 효과가 있게 된다.<br/>
  단, 낮 시간에 해당된 자는 낮 것을, 밤 시간에 해당된 자는 밤 것을 말해줘야 맞는다.<br/>
  낮 시간에 와도(전화) 온 순서에 따라 밤 시간이 될 수 있고 밤 시간에 와도(전화) <br/>
  낮 시간이 될 수 있다는 것은 앞서 설명 했다.<br/>
<br/>
20. '묻는 사안별 성부단정 판단 해 주기'<br/>
  지금까지 위 1-19항 까지는 방문(전화)자가 우리에게 단 한마디 말을 하지 않아도 우리가 미리 알아 먼저 <br/>
  지적하는 것들이었는데 반해 이 항목은 방문(전화)자가 막상 자기에게 필요한 사안을 물었을 때 <br/>
  그 '되고 안 되는 것'을 판단해 주는 대목이다.<br/>
  묻는 사안을 24항목으로 분류하고 12항목을 더 추리하여 총 36항목에 답을 달았는데 방문(전화)자의 띠에 따라 <br/>
  '된다'는 답이 안 될 수 있고 '안'된다'는 답이 될 수 있는 육임 법칙을 철저히 적용하여 방문(전화)자의 <br/>
  띠 마다에 표시해 놓았다.<br/>
  각 띠에 표시된 별표 자는 확실히 된다고 알고, 삼각표 자는 노력이 필요하고, 동그라미 표시 자는 <br/>
  공망 이므로 절대 불가능하다고 안다.<br/>
  사안 별로 된다는 답이 있더라도 동그라미 표시 자는 안 되고 사안 별로 안 된다는 답이 있더라도 <br/>
  별표 자는 되는 것이다.<br/>
  이것을 육임에서 '변화문'이라고 하고 마지막 결정인 것이다.<br/>
  2011년 5월 이전에는 이 부분이 증보되지 못해 방문(전화)자의 띠에 의해 더러 민망한 일이 있었으나 <br/>
  3년에 걸쳐 이 부분을 증보 시켜 우리들의 판단은 이제 '굳히기'가 된 것이다.<br/>
<br/>
21. '소육임'<br/>
  제 9차 업데이트에서 장착했다.<br/>
  대안, 유연, 속희, 적구, 소길, 공망 사상이 즉시 월 일 시로 구분된다. 특히 월일시 중 시간 것의 해당사를 말하면 <br/>
  당면사 효과가 매우 클 것이다. 월 일 시 3개가 좋게 모이면 형통하고 나쁘게 모이면 지장이 크다.<br/>
  좋은 달, 좋은 날, 좋은 시간을 활용할 수 있고 나쁜 것은 반대다.<br/>
  22. 방문자(전화 차객 포함)의 신분, 즉 극귀자, 차귀자, 고위자 실력자, 종교, 활인업진출로 성공할 자, 무고인을 첫창에 명시했다.
<br/>
  차객도 적용되어 있으므로 좋은 달 좋은 날, 좋은 시간을 고를 수 있을 것이다.<br/>
<br/>
  지난 1921년부터 2100년까지의 색인할 수 있도록 해 놓았다. <br/>
<br/>
<br/>
<br/>
<span style="font-weight: 700; ">三. 일생사 판단</span><br/>
<br/>
1. 화면 우측은 일생사 이다. <br/>
<br/>
   남녀 생년월일시를 입력한 후 '결과보기'를 누른다. 이때 화면 좌측 당면사 <br/>
   하단 남녀 성력 칸 우측에 '소육임'이 자동으로 작동되어 출생 월 일 시에 해당하는 소육임 해답이 <br/>
   채출 된다.<br/>
   일생을 간단명료하게 파악할 수 있을 것이다.<br/>
   특히 생년월일시 어느 것을 모르는 사람을 위해 당면사와 같이 작동되도록 했고 차객도 적용시켰다.<br/>
   이때 생년월일을 기입하면 그 해당 것만 적시된다.<br/>
<br/>
2. 생년월일시 중 어느 것을 모르면 방문(전화)한 시간, 일, 월을 쓴다. <br/>
<br/>
   출생 띠는 사과삼전표를 눌러 남자는 <br/>
   靑 아래 지반지지를 띠로 쓰고, 여자는 后 아래 지반지지를 띠로 쓴다.<br/>
   이때 낮 시간 출생자는 왼쪽에서 찾고 밤 시간 출생자는 우측에서 찾으라.<br/>
<br/>
3. '남녀 성품'<br/>
   잘 맞을 것이다.<br/>
<br/>
4. '평생 정체성'<br/>
   어떻게 살 사람인가?가 설명되어 있다. 단추 안에 해당 법칙이 들어 있다. 체출 근거이다.<br/>
<br/>
5. '일생특이성'<br/>
   어떤 특별한 일을 겪을 것 인가?가 드러나 있다. 역시 단추 안에 체출 근거가 들었다.<br/>
<br/>
6. '일생 숙명' <br/>
   중요하다. 공망 통변과 공망 육친의 불성, 재해, 기복, 곡절을 대비해 보라. 놀라울 것이다. <br/>
   단추 안의 간단명료한 해설을 확대 해석하라.<br/>
<br/>
7. '12궁 복분' <br/>
   해당인의 육친, 또는 환경 요인이다. 당사자의 띠를 클릭해 보고, 상단 적색은 좋은 것, 하단 <br/>
   흑색은 나쁜 것, 좌측 칸은 낮 출생자 것, 우측 칸은 밤 시간 출생자 것이다.<br/>
<br/>
8. '선천 복분' <br/>
   해당인의 직업 변화, 종사 기복 관계를 적시해 놓았다. 띠를 클릭한다. <br/>
<br/>
9. '현재 신상' <br/>
   해당인의 당면 운세이다, 여기에는 침범 귀신, 침범 부정, 침범 산소 탈 등이 적시되어 있다. <br/>
   발견했으면 이를 해소해 주는 것이 우리의 할 일이다.<br/>
<br/>
10. '나이별 운세'<br/>
   평생 운을 예언해 줄 수 있을 것이다. 참고 할 것은 12년 단위로 운이 동일하다는 것이다. <br/>
   60세가 넘으면 다시 1세부터 진행한다. 72세는 12수를 누르라.<br/>
   한 가지, 병이 들어 사망이 우려되는 나이에는 월지 신살 사기(死氣)년이나 월이고, <br/>
   이때 상문 조객이 겹치면 어김이 없다.<br/>
<br/>
11. '일생 변수' <br/>
   해당인이 내외적으로 겪어야 하는 변화 변수를 달아 놓았다. 역시 띠를 클릭한다.<br/>
<br/>
12.'여명(女命)대개' <br/>
   여명의 단적인 운명 구조를 적시한 것이다. 이외로 간단하면서 적중한다.<br/>
<br/>
13. '신분 직업' <br/>
   출생 월, 또는 출생 년에 따라 정해지는 직업 신분을 명시했다. 특히 청소년의 경우 이 방향으로 <br/>
   진출시키면 효과적이지 않겠는가? 특히 지방공직자 일 경우 중견 회사원이 많다.<br/>
<br/>
14. '평생 운세' <br/>
   초년, 중년, 말년으로 구분했다. 각 운의 나이도 적시했고 길흉관계도 0표와 X표로 명시해 놓았다. <br/>
   각각 통변성에 둔간(천반지지에 붙는 육임에서의 천간 명칭)의 숨은 작용력, 즉 진행 결과를 <br/>
   확정 시켰기 때문에 일반 통변성과 다른 내용일 수 있으나 의심치 말고 말하면 맞다.<br/>
   특히 초년에 '大시험 길'이라고 명시되어 있는 자는 반드시 사법고시 등 국가 고등 시험에 <br/>
   응시하면 합격할 자 이다. 참고하라.<br/>
<br/>
15. '수명(壽命)'<br/>
   수명 관계의 대체만 거론했다. 수명에 대해서는 언급하지 말라는 옛 현인들의 충고를 따른다. <br/>
   다만 위 10항의 사망 시기를 참조한다.<br/>
<br/>
16. '질병'<br/>
   무병자가 있다. 이는 '병 지나 감'으로, 침병 될 자는 질환을 표시했다. 병이 지나간다는 것은 <br/>
   병에 걸리더라도 낫는다는 의미다. 이런데서 우리는 활인의 지혜를 유추해야 한다.<br/>
<br/>
17. '배우자의 성질 용모 및 선, 후사(先 後死)' 여부<br/>
   배우자의 성질과 용모, 그리고 부부 중 누가 먼저 죽느냐? 는 것을 표시했다. <br/>
   다만 이때도 확실한 것만 말하라는 고인들의 조언을 따랐다. <br/>
<br/>
18. '형이상 법' <br/>
   귀신 침범, 부정빌미 침범, 산소 탈 침범을 따져 놓았다.<br/>
   해당인의 일이 꼬이고 병이 발생하고 자식의 우환, 부모의 재해 등이 이 부분에서 절대적인 무게를 <br/>
   갖는다는 것은 이미 고인들의 증험과 이 시대 임상을 통해 검증되어 있다.<br/>
   그 해소 방법은 단추를 누르면 설명되어 있다.<br/>
<br/>
19. '장애 육친'<br/>
   가족 중 누가 장애를 갖고 있고, 또 갖게 되며, 어느 연 월에 조심해야 하는가? 에 대해 명시했다.<br/>
<br/>
20.원수 '일생  적 타인'<br/>
   해당인 에게 평생에 걸쳐 위해를 가할 사람, 즉 직업, 사는 곳, 남녀를 명시했다. 가능한 멀리하라고 <br/>
   교시해 줘야 한다.<br/>
<br/>
21. '일생 은혜적 타인'<br/>
   해당인 에게 평생에 걸쳐 도움을 베풀어 줄 사람, 즉 직업, 사는 곳, 남녀를 명시했다. 이 사람들과 <br/>
   관계성을 좋게하는 것이 좋다는 것을 교시하라.<br/>
<br/>
22. '원수적 자녀와 친화적 자녀' 알기<br/>
   일생 자식 때문에 속을 끓이는 부모가 있다. 버림을 받고 심지어 목숨까지 빼앗긴다. <br/>
   낳지 말아야 할 해(年- 띠)에 낳으면 이렇게 된다. 이 예측을 명시한 것이다.<br/>
   반면 평생 부모를 영화롭게 하는 자식이 있다. '친화' '안정' '출세'로 표시해 놨다.<br/>
   그러므로 임신과 출산을 여기에 기준해야 하게끔 예언할 필요가 있다.<br/>
<br/>
23. '남녀 성력(性力)<br/>
   남녀의 성력을 명시했다. 잘 맞을 것이다. 참고도 될 것이다.<br/>
<br/>
24. '소육임' 일생사<br/>
<br/>
   첫 화면 좌측 당면사 맨 아래 세 칸으로 나뉜 위 23항 '남녀 성력' 우측칸에 일생사 소육임이 들어있다. 방문시간에 따라 <br/>
<br/>
   자동으로 구동되다가 우측 일생사에 출생년월일시를 적기하면 이곳의 월일시가 해당 것으로 바뀐다. <br/>
<br/>
   인생의 줄기가 단번에 잡힐 것이다.<br/><br/>

   다음은 명암법을 적용하여 명과 암으로 <br/>상대가 선택한 명암의 각 정도를 적시했다. <br/>


<br/>
25. 'CD프로그램 내용 옮겨 적기<br/>
   내일 방문(전화)자의 오는 시간에 의한 각 국수의 해당 본문 핵심사항을 옮겨 적는다면 <br/>
   그 메모 한 장만 갖고 10명이든 20명이든 모두 상담(친견)이 가능하다.<br/>
   26. 본 앱은 착목도를 위주로 편성했기 때문에 부연설명을 절약해도 되겠다. <br/>
   자주 열어보면 익숙히 변별할 수 있을것이다.<br/>
<br/>
   방문(전화)자의 궁금한 것은 사실상 다음 몇 가지에 불과하다.<br/>
<br/>
   돠는가, 안되는가?<br/>
   좋은가, 안좋은가?<br/>
   할까, 말까? <br/>
<br/>
   나머지는 부연이고 군더더기이다. 깊고 넓을수록 우리의 말은 짧아진다. 필요한 사항만 <br/>
   단호히 말하고 상담(친견) 시간을 길게 하지 않는 것이 보다 효과적이다.<br/>
<br/>
   뒤쪽 양식에서 특별히 볼 것은 각 방문(전화) 12지지 시간마다의 밑에 작은 글씨로 된 <br/>
   지지인데 이 지지는 그 시간의 다음사람에 해당되는 시간 표시이다.<br/>
<br/>
   예를 든다.<br/>
   巳시에 첫 방문(전화)자가 와서 보고 갔다. 그런데 같은 巳시에 사람이 또 왔다. 巳시 밑에 子가 있다. <br/>
   子시 칸의 옮긴 말을 하면 맞게 된다. 다음사람이 또 와서 먼저 본 子시를 보니 卯가 써져 있다. <br/>
   묘시 칸 것을 말한다.<br/>
   이렇게 같은 시간에 12사람 것 까지 각기 다르게 판단할 수 있고 13반째 사람 부터는 동일한 <br/>
   내용으로 이어지는데 그래도 그 내용이 맞게 된다. 우주운동법칙의 순열 현상인 것이다.<br/>
   그러다가 시간이 午시로 넘어갔다. 그때는 아직 몇 사람이 대기하든 새로 오든 午시부터 다시 <br/>
   위 巳시에서의 방법대로 다시 봐 나가면 된다.<br/>
   다음 양식대로의 종이 한 장이면 되는 것이다.<br/>
   이때 순서를 잊었을 때는 숫자를 고르게 하여 그 고른 숫자가 표시된 좌측을 보고 그 숫자 칸의 <br/>
   내용을 말하면 맞는다.<br/>
   숫자를 사용한 시간은 그것으로 끝나고 차객에 포함하지 않는다. 이때는 다음시간으로 넘어갈 때 까지 <br/>
   계속 숫자를 선택하게 해서 정단하라.<br/>
   다만 주의할 것은 각 해당된 시간이 낮 시간인가? 밤 시간인가?를 알아 옮겨 적을 때 표시해둔 내용 <br/>
   앞의 낮 것 적시 내용과 밤 것 표시 내용을 잘 보라는 것이다.<br/>
   해당 국수 역시 옮겨 적을 때 이미 써두었겠기로 착오가 없다.<br/>
<br/>
<br/>
<br/>
     <br/>
<br/>
이상으로 본 '육임AI' 보기요령을 설명했다.<br/>
<br/>
차 후 기회가 되면 항목 추가는 이 화면에 더 이상 여백이 없어 불가능하고 용어를 더 예리하게 다듬어 <br/>
여러분이 요구할 수 있는 부족성을 보완해 나아가기로 한다.<br/><br/>

<span style="font-weight : 700;">주지사항 </span><br/><br/>

1.본 앱과 도서 프로그램은 학습서가 아닌 상담활용서이므로 구입 장착 실행하였을 경우 정확도 효과성 착목도 속도성을 갖춘 저작권의 개요 간취가 가능함으로 반품, 환불요구가 거절됩니다.<br/><br/>
2.본 육임 AI의 품질 향상과 명성을 위해 구입자의 사용권은 실행파일을 구입한 날로 부터 2년으로 제한합니다. <br/><br/>
단, 이 2년동안 연 2회, 2년 4회의 업데이트에 응한 경우 매회 업데이트 서버적용일로부터 2년이 연장됩니다.<br/><br/>
3. 이때, 매회의 업데이트 작업비는 회당 10만원을 상회하지 않으며 최소한 연 1회 또는 2년 2회의 업데이트에 응하지 않는 경우 2년의 경과로 본 AI의 실행은 자동으로 중단됩니다. 그러나 2년과 2년 4년에 걸쳐 4회의 업데이트에 응한 때는 
영구적 실행을 보장합니다. 
<br/><br/>
귀하의 건안을 기원합니다.

<br/>
<br/>
<br/>
<br/>




                              병신년 섣달에<br/>
                              艮山에서 坤水를 바라보며     고복자  효   사<br/><br/><br/><br/>

                              </div>
`;




const church_before1 = `
<div style="font-size: 15px;">
<span style="font-weight: 700; font-size: 18px;">
 'MY+ (마이플러스)' 보기요령
</span>
<br/>
<br/>
<br/>
<span style="font-weight: 700; font-size : 17px; ">一. 메뉴</span><br/>
<br/>
* '지역 설정'<br/>
  육임은 시간을 주요시하는 '방문(전화) 시간' 체계이다.<br/>
  판단하는 장소가 어느 지역 인가?를 알아 해당 지역에 클릭해두면 <br/>
  프로그램 내부에서 자동으로 그 지역 시간 변동에 따라 작동한다.<br/>
  지역을 옮기는 경우 다시 '지역설정' 메뉴에 들어가 옮긴 지역에 클릭한다.<br/>
  우리나라 동부, 중부, 서부 지역에 따라 시간의 경계가 다르기 때문이다. 해외 지역 역시 일자와 시간이 다르기에 하루전과 하루 후로 구동 되는 경우가 있게 된다<br/>


<br/>
* '일자 변경'<br/>
  이 일자변경을 통해 '내일 올 사람들'의 사정을 오늘 미리 알 수 있다.<br/>
  또 한 달 전, 석 달 후 어느 날 왔었거나 올 사람들의 속사정을 되돌아보거나, 또는 미리 알게 된다. <br/>
  그리하여 내일 올 사람들의 핵심사항을 오늘 정리해 놓으면 편리하고 방문자 앞에서 PC를 보고 <br/>
  말하지 않아도 되는데 메모 한 장이면 하루 다수인의 상담이 충분하게 된다.<br/>
  이 부분은 우리 개개인의 성향에 따라 매우 중요하고 요긴한 사항이다.<br/>
<br/>
*'저장'<br/>
 상담한 주요 내용를 메모해 저장해 둔다.<br/>
 이것은 이외로 중요하다. 많은 방문자, 전화자를 상대하다보면 전에 해줬던 상담내용을 잊는 경우가 많다. <br/>
 우리의 말이 일관성을 잃게 되는 것을 방지하기 위해서는 가능한 대로 이 메뉴를 적절히 활용하는 것이 좋다.<br/><br/>
<br/>
*'같은 시간 안의 다음사람'<br/>
  매우 중요하다.<br/>
  이 부분만 잘하면 육임은 틀림이 없다.<br/>
  본 프로그램은 매일 매 시간마다 방문(전화)자의 온갖 사정(비밀)들이 모두 떠있게 만들어져 있다.<br/>
  각 지역별 '지역 설정'에 의해 실시간이 바뀌면 첫 화면에 “시간을 변경하시겠습니까?”하는 문구가 뜨는데 <br/>
 “예”를 누르면 다음 실시간으로 변경되고 “아니요”를 누르면 현재 상담내용이 그대로 유지된다.<br/>
  같은 사간 안에 여러 사람이 방문(전화)하여 상담을 원할 때는 순서대로 '다음사람'만 클릭하면 <br/>
  그 해당인 에게 맞는 내용이 바로 뜬다. 이점, 의심하지 말고 바로 말하라. 맞는다.<br/>
  만약 시간 구분과 순서를 혼동했을 때는 주저 말고 숫자를 1-12 중에서 하나를 고르라고 해 방문(전화)자가 <br/>
  그중 하나를 고르면 화면에 나타나 있는 해당 숫자를 누르라. 즉시 해당인 에게 맞는 내용이 뜬다. <br/>
  이때 숫자를 고르게 하는 것이 불편하다면 아예 그릇 등에 숫자나 12지지를 써 넣어 두었다가 그것을 뽑게 해도 된다. <br/>
  또는 대기실에 두고 이 숫자를 뽑아 들어오게 해도 된다.<br/><br/>
  이 방법은 '다음사람'을 쓰는 방법보다 속도성은 좀 낮다고 할 수 있겠다. <br/>
  육임은 속도성 제1의, 기선제압에 매우 적절한 오행술 인 이유이다. 즉 방문(전화)자로부터 한마디의 말을 듣지 않고도 <br/>
  문(전화)에 들어선 자의 모든 정황을 미리 알아 먼저 지적해낼 수 있기 때문이다.<br/><br/>
  그렇기에 가장 좋은 방법은 상담실과 대기실을 구분하여 몇 사람이 와서 대기하든 일단 상담실 문을 열고 들어서는 <br/>
  사람만을 대상으로 '다음사람'을 누르는 방법을 쓰는 것이다. <br/>
  이것은 우리의 환경적 요인을 육임에 맞게 개선시켜 우리의 심중 번잡을 피할 수 있으면서 정확도와 효과성을 높이는 <br/>
  방법이라고 할 수 있겠다.<br/>
<br/>
* '정단시간 표시'<br/>
  첫 화면 상단에 낮 시간 것은 적색으로, 밤 시간 것은 청색으로 나타난다.<br/>
  또 낮시간은 첫창 상단에 적색 선을, 밤 시간에는 바다색 선을 표시해 놓았다<br/>
  육임은 차객법(次客法)이라는 것이 있어 동일한 시간에 여러 사람이 와도 각각 나누어 판단하게끔 되어 있으므로 <br/>
  순서에 따라 '다음사람'을 누르면, 낮에 왔더라도 밤 시간에 해당되는 사람이 있고 밤에 왔더라도 <br/>
  낮 시간에 해당되는 사람이 있게 된다. <br/>
  그렇다면 본문 내용도 낮 시간 것과 밤 시간 것을 구별해 볼 필요가 있게 된다. 그래서 낮 시간 것 내용에는 <br/>
  '플러스' 표시로, 밤 시간 것 내용에는 '마이너스' 표시를 반드시 해놓았다. 이 표시를 잘 보아 그 해당 내용을 말하면 맞는다. <br/>
  특히 '초사' 칸 침범귀신, 부정빌미 구별에 이점 유의하기 바란다.<br/>
<br/>
* '방문(전화)자의 우리에 대한 신뢰도 여부 알기'<br/>
  방문(전화)자가 우리의 말과 판단에 대해 신뢰를 하면 '신뢰'라고 적시해 놨고, 자기 고집이 있을 때는 '고집'이라고 <br/>
  적시해 놨고, 좌고우면 하는 자에 대해서는 '설득'이라고 써 놨고, 우리와의 관계가 이루어질 수 없는 자에게는 <br/>
  '불성'이라고 써 놨다.<br/>
  이 두 자씩의 짧은 단어에는 우리의 방문(전화)자에 대한 처신의 묘리가 담겨있다. <br/>
  신뢰자에 대해서는 자연스럽게, 고집자에 대해서는 확신적으로, 설득자에 대해서는 논리적으로, <br/>
  불성자에 대해서는 다음 기회로 미는 것이 보다 현명한 처신이 될 것이다.<br/>
<br/>
* '기도 효과 미리 알기'<br/>
  우리가 상담(친견)을 청해온 방문(전화)자를 상대로 각종 기도를 행한다면 그 기도이 '효과가 있을 것인가?' '없을 것인가?'<br/>
  를 미리 알고 작정하도록 해 놨다.<br/>
  첫 화면 상단 '기도효과 여부'에 표시해 놓은 “기도효과 있다”, “기도효과 없다. 0일 후  재 정단 하라”를 잘 봐야 한다. <br/>
  낮 시간 것에는 적색, 밤 시간 것에는 청색으로 써 놨다.<br/>
  “기도효과 있다”고 되어 있으면 당장 택일에 들어가고, “효과 없다. 0일 후 재 정단 하라”고 되어 있으면 <br/>
  판단일이 들어있는 그 순(旬)이 끝나고 다음 순이 오는 어느 날 다시 오라고 하여 그때 방문(전화)한 시간에 의한 <br/>
  해당 국수 첫 화면 상단에 낮 시간, 또는 밤 시간으로  “효과 있다”고 되어 있을 때 택일을 해야 옳다는 것이다. <br/>
  그런데 3회까지 “효과 없다”가 되면 포기하라. 천기가 해당 인을 위배하고 있는 것이다.<br/>
  가능한 이 원칙을 지켜 가는 것이 장차 발전의 바탕이 된다. 무턱대고 기도만 한다고 다 효과가 있게 되는 것이 아니기 때문이다.<br/>
  그런데 이 비율이 6대 4로 “효과 있다”가 부족하다. 그러나 이 부족성 4는 “효과 없다”의 6을 '커버'하고 남는다는 것을 <br/>
  빨리 알아챌 필요가 있다. 그러니까 우리가 하는 기도은 반드시 효과가 있어야 하고, 효과를 본 사람들만 우리 곁에 있어야 하며, <br/>
  또 그랬을 때 우리의 발전이 장차 적으로 약속된다는 것이다.<br/>
<br/>
<br/>
<span style="font-weight: 700; ">二. 당면사- 미리 알아 먼저 지적 하기</span>
<br/>
<br/>
1. '초사(初辭)'<br/>
  밤문(전화)자에게 제일 먼저 던질 말이다.<br/>
  자신있게 말하라. 맞는다.<br/>
  여기에는 칸 하단에 침범 '귀신'과 침범 '부정빌미'를 밝혀 놓았는데 그 적중률은 10여년에 걸쳐 전국에서 검증되어 있다. <br/>
  반드시 그 가족력이 드러나는 것이다. “아니다, 가족 중 그렇게 죽은 사람이 없다”고 하는 경우가 더러 있지만 돌아가서 <br/>
  연락을 해와 “어른에게 물어보니 맞더라”하는 사례들이 많다.<br/>
  단, 플러스 표시는 낮 시간 것, 마이너스 표시는 밤 시간 것임을 기억해 해당 것을 지적하면 틀릴 걱정이 없다. <br/>
  간혹 플러스, 마이너스 표시가 함께 된 것이 있으면 낮 밤 시간 모두 해당 인에게 적용된다는 것으로 알면 되겠다.<br/>
  참고로 이 귀신과 부정을 말할 때는 믿어 의심치 말고 마치 '보이는 것처럼' 말하는 것이 효과적이라는 점이다. <br/>
  이 의미를 상고하기 바란다.
  <br/><br/>
2. '왜 왔는가?'<br/>
 육임의 시보(時報)이다.<br/>
 방문(전화)자가 무슨 일로 왔는지가 여기에 나타난다.<br/>
 이 칸 맨 상단 부분이 핵심이다. 그 밑줄 부분은 부연이다.<br/>
 신외 남자(身外 男子)는 남자에 해당하고 바깥일이고, 가내 여자(家內 女子)는 내부 가정사이고 여자에 해당한다.<br/>
<br/>
3. '정황(情況)'<br/>
 육임 내정 부분이다.<br/>
 여기에는 방문(전화)자의 속사정, 자기만 아는 비밀들이 드러난다.<br/>
 믿고 말하라.<br/>
그리고 그 효과에 대해 상고해 보기 바란다.<br/>
<br/>
4. '전체적 사정'<br/>
 육임 과체(課體)로 아는 것이다.<br/>
 방문(전화)자의 총체적 근황 관계, 또는 처신 방침이 나타나 있다. 절대 그 범위를 벗어나지 않는다. <br/>
 같은 칸 흑색 삼각표시를 누르면 그 근거가 나타난다. 읽어두면 숙지가 될 것이다.<br/>
 (이 칸에 '귀인이 순행하면'+, '귀인이 역행하면'- 하는 표시가 나타날 것이다. 이것을 바로 알려면 첫 화면 상단의 <br/>
 '기도 효과 있다' '기도 효과 없다'로 안다. 효과 있다는 것은 귀인 순행이고, 효과 없다는 것은 귀인 역행에 해당되는 것이다)<br/>
<br/>
5. '특별한 사정'<br/>
 육임 격(格)으로 안다.<br/>
 방문(전화)자의 특별한 일을 지적하는 것으로서 아주 잘 맞는다. 과감하게 말하라. <br/>
 이때 흑색 삼각단추를 눌러 숙지해 두면 천금으로 사용된다.<br/>
<br/>
6. '배경 사항'<br/>
 가. 전개사안 : 되어 질 일이다.<br/>
 나. 내외표리 : 바깥일인가? 내부 집안일인가? 드러내도 되는가? 숨기고 싶은 일인가?<br/>
 다. 미기아타 : 일이 아직 일어나지 않고 잠정적인가? 이미 일어났는가?<br/>
               자기가 저지른 일인가? 타인에 의해 발생 했는가?를 이 표시로 안다.<br/>
 그러므로 상담(친견)은 절대 단독으로 해야 한다. 일행도 마찬가지다. 타인에게 숨기고 싶은 일이 많기 때문이다. <br/>
 여러 사람, 옆에 누가 있으면 속사정이나 비밀을 지적하는 우리의 말은 부인되기 십상이다. 중요한 부분이다.<br/>
<br/>
7. '내외 변화'<br/>
 육임 내외 변수로서 일간 기궁과 방문(전화) 시간 등 육임 사과삼전 각 부위개소와의 관계에서 파생된다. <br/>
 합 충 형 해 파 공망으로 부터 표현되어 있다. 그러므로 설사 당장은 맞지 않더라고 반드시 그렇게 전개된다.<br/>
<br/>
8. '공망 재해'<br/>
 중요하다.<br/>
 각 공망 지지와 공망된 통변과 공망 천반지지에 승한 천장을 표시했다.<br/>
 공망 통변은 그 통변성의 불성(不成) 및 기복성(起覆性)으로 판단하고 그 통변성을 육친(肉親)으로 바꿔 해당 <br/>
 육친의 재해(災害)로 판단하는 것이다. 갑술순과 갑진순은 같은 오행 2개가 공망된다. 이는 숙명이 된다.<br/>
 전문가들은 이 공망 통변과 공망 육친만으로 불성 및 재해를 부족 없이 판단한다. <br/>
 익숙해질수록 우리의 판단과 말은 간단해지고 '적중한' 지적에 국한하며 더 이상 확장시키지 않게 된다. <br/>
 이 부분에 대해 상고할 필요가 있을 것이다.<br/>
<br/>
9. '대적(對敵)처신 방침'<br/>
 육임 전쟁정단 중에서 발췌한 것으로서 이것으로 상대와의 대적 관계(소송, 다툼, 이해관계, 시비 등)에서의 <br/>
 처신 방침을 교시해 줄 수 있다.<br/>
 이 부분은 이외로 뚜렷한 교시성이 제한되어 우리를 곤혹스럽게 하기 때문에 이를 굳이 실어 놓았다. <br/>
 많은 도움이 될 것이다.<br/>
<br/>
10, '양, 음택 좌 향 길흉 관계'<br/>
 생자나 사자나 각 그 띠 별로 맞는 좌 향이 있는데  표시된 띠에 사는 집이나 묘지의 좌향이 맞으면 흥가 하고 <br/>
 맞지 않으면 패가 패손 한다.<br/>
 황색은 길, 길사 발현 년 월이고 백색은 흉, 흉사 발현 년 월이다.<br/>
 여기에서는 좌 만 표시되어 있는데 이 좌는 집 뒤쪽을 말하고 사자의 머리 쪽을 말한다. <br/>
 그렇다면 향은 대충 방위, 즉 반대쪽이 된다.<br/>
<br/>
11. '월별 신수'<br/>
 판단하는 해당 년의 해당 월 신수가 이 칸에 있다. 이 칸 맨 위 칸은 종합, 백색 칸은 낮 시간 것, <br/>
 흑색 칸은 밤 시간 것이므로 낮 시간에 해당되면 종합과 백색 것을, 밤 시간에 해당되면 종합과 <br/>
 흑색 것 두 개를 말하면 된다.<br/>
<br/>
12. '선, 악인 판단'<br/>
 방문(전화)자의 선천성이 선한가? 보통인가? 악 한가?를 흑색 바탕에 표시되는 이것으로  안다. <br/>
 매월(중기- 중기 사이)마다 월장이 바뀌고 매 해당 시간마다 선,보,악 지지가 바뀌므로 해당인 <br/>
 모두에게 여착 없이 적용된다.<br/>
 우리는 이 세상의 선생이다. 이 선보악 구별에 의해 상담, 기도, 금전 교부 등에 참고할 필요가 있다.<br/>
 또 방문(전화)자가 묻는 이해관계의 상대에게도 여기에 나타난 천성을 말해주면 된다.<br/>
 (월장가시 후 천반 자하 지지가 사맹지는 선인, 사중지는 보통인, 사계지는 악인이다)<br/>
 이점 반드시 점검하기 바란다. 프로그레머의 일정관계로 9차 업데이트에서 수정하지 못했다.<br/>
<br/>
13. '대사(大事) 호신(護身) 택인 택일 택문 택색 선택 해주기'<br/>
  '축비'의 도교술수서 '육임대점'에서 발췌한 것이다.<br/>
  옛날 육임을 아는 황제의 책사들이 전쟁에 나갈 때 반드시 이 법을 쓰도록 했다.<br/>
  현대에 맞춰 적시했다.<br/>
<br/>
   가. 장수(책임자)를 무슨 띠로 고르게 할 것인가? 정단일의 식상 띠.<br/>
   나. 출진 날(택일)은 어떤 날로 하게 할 것인가? 정단일의 식상 일.<br/>
   다. 출진 시간(택시)은 몇 시로 하게 할 것인가? 정단일의 식상 시.<br/>
   라. 출진 문(택문)은 어느 방향으로 나가게 할 것인가? 정단일의 식상 문.<br/>
   마. 복색(깃발)은 무슨 색으로 하게 할 것인가? 정단일의 재성 색.<br/>
<br/>
  참고로 판단일의 식상 띠, 날, 시, 문 이고 다만 복색(깃발)만 재성 색이 되고 있다.<br/>
  관살(병, 살상)을 극하는 식상을 써 적으로부터 피해를 줄이자는 육임법의 탁월한 지혜이다. <br/>
  찬지 만물이 이 기운에 의해 생장 소멸을 거듭한다는 주의가 우리의 연구 논리일진대 이 법에서 <br/>
  큰 지혜를 발견할 수 있을 것이다.<br/>
<br/>
14. '비방(秘方)'<br/>
<br/>
 *귀신치병고법 : 병난 날을 알면 그 이유를 알 수 있다. 기도을 달았다. 불가에서 써온 전래 기도이다.<br/>
<br/>
 *가택부정해소 : 매월 달라지는 북두칠성의 사기제거 기운을 차용하는 것이다. <br/>
 명시해 놓은 방위에서 모래를 퍼와 집 안팎에 뿌린다. 사기가 제거된다. <br/>
 특히 기도 전에 이 방법을 쓴 후 하게 되면 더 좋은 효과를 거둔다. <br/>
 이외 정신 산란자, 악몽자, 우환 등에 효과가 있다.<br/>
<br/>
 *옥추비법 : 현대에 와서 우리가 가장 간편하게 쓸 수 있는 제례의식이요, 부적법에도 효용이 있다. <br/>
 해당 사안이 구체적으로 구분되어 있어 각 직업군의 호신부로 활용하기 좋다.<br/>
<br/>
 *귀신 침법 : 정신혼미 자, 귀신들린 자 에게 쓰면 효과가 있다. 단, 우리가 잘 아는바와 같이 <br/>
 침은 제한이 있어 유의할 필요가 있을 것이다. 참고로 실었다. 눕게 하여 침을 꼿으면 <br/>
 땀을 흘리거나 잠이 들거나 발광을 하거나 욕설을 하는데 우리에게 덤비지는 못한다. <br/>
 발광자에게는 우리도 같이 강력하게 대응하면 빙의된 귀신이 물러가는 사례가 많다.<br/>
<br/>
 *가택 부정 진압법 : 가정에 부정드는 경우가 많다. 그 부정을 밝혀 놓았는데 여기에서 해소방법을 <br/>
 적시해 두었다. 쉽고 효과도 있다. 많이 활용 될 것이다.<br/>
<br/>
 *천기법 : 중독성이 있을 정도로 효과가 있다. 과학성이 담보된 현대 부적법이 될 것이다. <br/>
 육임의 정수이기도 하다. 해당 칸 맨 위 기(氣)는 쓰는 지지 글자이고 좌측 색(色)은 쓰는 종이이고 <br/>
 우축 수(數)는 쓰는 종이 장수와 매장에 쓰는 지지 글자의 수고 아래 칸 방(方)은 부적을 붙이는 방위 <br/>
 태울 방위 쓰는 지지 글자의 색을 말한다.<br/>
<br/>
 *신명법 : 무속에서 주로 쓴다. 많은 활용이 될 것이다. “꿩 잡는 게 매다”는 격언을 알면 방법에 <br/>
 고하가 없다는 것을 이해할 것이다.<br/>
<br/>
15, '응기(應期)'<br/>
  현재의 흉이 해소되는 일, 또는 월 알기 이다. <br/>
  방문(전화)한 시간 지지가 왕상 하면 일로 치고 휴수사 이면 월로 친다.<br/>
  기도일 수, 또는 부적 장수를 이 일의 수나 월의 수로 환산하여 맞춰하면 이사기적을 일으킬 수 있을 것이다.<br/>
  또 일간상신 천반지지로 부터 일간의 12운 절 지지가 되는 날이 흉 해소일로 되기도 한다.<br/>
 <br/>
16. '소망사가 이루어지는 날, 또는 월 알기' <br/>
  방문(전화)한 시간 지지가 왕상 하면 일로 치고 휴수사 이면 월로 친다. 요령은 위 15항과 같다.<br/>
<br/>
17. '이사 길방 찾아 주기'<br/>
  해당 국수에서 가장 좋은 방위를 골라놓았다.<br/>
  이 방위와 육합 일에 적시한 방위로 이사를 가게 되면 그 이사 방위 월에 반드시 길사가 발현된다는 것을 <br/>
  말해주라.<br/>
  단, 꼭 이사 가는 날이 이사 가는 방위와 육합일이어야 한다.<br/>
<br/>
18. '택일 정해 주기'<br/>
  고등한 육임 법칙에 의해 산출되어 있다.<br/>
  일반 택일법과 배치되더라도 걱정할 것 없다.<br/>
  이것을 많이 활용할 것을 권한다.<br/>
 <br/>
19. '일일 행상, 영업 판매실적 효과 방위 알려주기'<br/>
  하루하루 판매실적이 필요한 방문(전화)자에게 요긴한 육임 법칙이다.<br/>
  이 칸에 적시된 방위를 말해주라. 큰 효과가 있게 된다.<br/>
  단, 낮 시간에 해당된 자는 낮 것을, 밤 시간에 해당된 자는 밤 것을 말해줘야 맞는다.<br/>
  낮 시간에 와도(전화) 온 순서에 따라 밤 시간이 될 수 있고 밤 시간에 와도(전화) <br/>
  낮 시간이 될 수 있다는 것은 앞서 설명 했다.<br/>
<br/>
20. '묻는 사안별 성부단정 판단 해 주기'<br/>
  지금까지 위 1-19항 까지는 방문(전화)자가 우리에게 단 한마디 말을 하지 않아도 우리가 미리 알아 먼저 <br/>
  지적하는 것들이었는데 반해 이 항목은 방문(전화)자가 막상 자기에게 필요한 사안을 물었을 때 <br/>
  그 '되고 안 되는 것'을 판단해 주는 대목이다.<br/>
  묻는 사안을 24항목으로 분류하고 12항목을 더 추리하여 총 36항목에 답을 달았는데 방문(전화)자의 띠에 따라 <br/>
  '된다'는 답이 안 될 수 있고 '안'된다'는 답이 될 수 있는 육임 법칙을 철저히 적용하여 방문(전화)자의 <br/>
  띠 마다에 표시해 놓았다.<br/>
  각 띠에 표시된 별표 자는 확실히 된다고 알고, 삼각표 자는 노력이 필요하고, 동그라미 표시 자는 <br/>
  공망 이므로 절대 불가능하다고 안다.<br/>
  사안 별로 된다는 답이 있더라도 동그라미 표시 자는 안 되고 사안 별로 안 된다는 답이 있더라도 <br/>
  별표 자는 되는 것이다.<br/>
  이것을 육임에서 '변화문'이라고 하고 마지막 결정인 것이다.<br/>
  2011년 5월 이전에는 이 부분이 증보되지 못해 방문(전화)자의 띠에 의해 더러 민망한 일이 있었으나 <br/>
  3년에 걸쳐 이 부분을 증보 시켜 우리들의 판단은 이제 '굳히기'가 된 것이다.<br/>
<br/>
21. '소육임'<br/>
  제 9차 업데이트에서 장착했다.<br/>
  대안, 유연, 속희, 적구, 소길, 공망 사상이 즉시 월 일 시로 구분된다. 특히 월일시 중 시간 것의 해당사를 말하면 <br/>
  당면사 효과가 매우 클 것이다. 월 일 시 3개가 좋게 모이면 형통하고 나쁘게 모이면 지장이 크다.<br/>
  좋은 달, 좋은 날, 좋은 시간을 활용할 수 있고 나쁜 것은 반대다.<br/>

  22. 방문자(전화 차객 포함)의 신분, 즉 극귀자, 차귀자, 고위자 실력자, 종교, 활인업진출로 성공할 자, 무고인을 첫창에 명시했다.
<br/>
  차객도 적용되어 있으므로 좋은 달 좋은 날, 좋은 시간을 고를 수 있을 것이다.<br/>
<br/>
  지난 1921년부터 2100년까지의 색인할 수 있도록 해 놓았다. <br/>
<br/>
<br/>
<br/>
<span style="font-weight: 700; ">三. 일생사 판단</span><br/>
<br/>
1. 화면 우측은 일생사 이다. <br/>
<br/>
   남녀 생년월일시를 입력한 후 '결과보기'를 누른다. 이때 화면 좌측 당면사 <br/>
   하단 남녀 성력 칸 우측에 '소육임'이 자동으로 작동되어 출생 월 일 시에 해당하는 소육임 해답이 <br/>
   채출 된다.<br/>
   일생을 간단명료하게 파악할 수 있을 것이다.<br/>
   특히 생년월일시 어느 것을 모르는 사람을 위해 당면사와 같이 작동되도록 했고 차객도 적용시켰다.<br/>
   이때 생년월일을 기입하면 그 해당 것만 적시된다.<br/>
<br/>
2. 생년월일시 중 어느 것을 모르면 방문(전화)한 시간, 일, 월을 쓴다. <br/>
<br/>
   출생 띠는 사과삼전표를 눌러 남자는 <br/>
   靑 아래 지반지지를 띠로 쓰고, 여자는 后 아래 지반지지를 띠로 쓴다.<br/>
   이때 낮 시간 출생자는 왼쪽에서 찾고 밤 시간 출생자는 우측에서 찾으라.<br/>
<br/>
3. '남녀 성품'<br/>
   잘 맞을 것이다.<br/>
<br/>
4. '평생 정체성'<br/>
   어떻게 살 사람인가?가 설명되어 있다. 단추 안에 해당 법칙이 들어 있다. 체출 근거이다.<br/>
<br/>
5. '일생특이성'<br/>
   어떤 특별한 일을 겪을 것 인가?가 드러나 있다. 역시 단추 안에 체출 근거가 들었다.<br/>
<br/>
6. '일생 숙명' <br/>
   중요하다. 공망 통변과 공망 육친의 불성, 재해, 기복, 곡절을 대비해 보라. 놀라울 것이다. <br/>
   단추 안의 간단명료한 해설을 확대 해석하라.<br/>
<br/>
7. '12궁 복분' <br/>
   해당인의 육친, 또는 환경 요인이다. 당사자의 띠를 클릭해 보고, 상단 적색은 좋은 것, 하단 <br/>
   흑색은 나쁜 것, 좌측 칸은 낮 출생자 것, 우측 칸은 밤 시간 출생자 것이다.<br/>
<br/>
8. '선천 복분' <br/>
   해당인의 직업 변화, 종사 기복 관계를 적시해 놓았다. 띠를 클릭한다. <br/>
<br/>
9. '현재 신상' <br/>
   해당인의 당면 운세이다, 여기에는 침범 귀신, 침범 부정, 침범 산소 탈 등이 적시되어 있다. <br/>
   발견했으면 이를 해소해 주는 것이 우리의 할 일이다.<br/>
<br/>
10. '나이별 운세'<br/>
   평생 운을 예언해 줄 수 있을 것이다. 참고 할 것은 12년 단위로 운이 동일하다는 것이다. <br/>
   60세가 넘으면 다시 1세부터 진행한다. 72세는 12수를 누르라.<br/>
   한 가지, 병이 들어 사망이 우려되는 나이에는 월지 신살 사기(死氣)년이나 월이고, <br/>
   이때 상문 조객이 겹치면 어김이 없다.<br/>
<br/>
11. '일생 변수' <br/>
   해당인이 내외적으로 겪어야 하는 변화 변수를 달아 놓았다. 역시 띠를 클릭한다.<br/>
<br/>
12.'여명(女命)대개' <br/>
   여명의 단적인 운명 구조를 적시한 것이다. 이외로 간단하면서 적중한다.<br/>
<br/>
13. '신분 직업' <br/>
   출생 월, 또는 출생 년에 따라 정해지는 직업 신분을 명시했다. 특히 청소년의 경우 이 방향으로 <br/>
   진출시키면 효과적이지 않겠는가? 특히 지방공직자 일 경우 중견 회사원이 많다.<br/>
<br/>
14. '평생 운세' <br/>
   초년, 중년, 말년으로 구분했다. 각 운의 나이도 적시했고 길흉관계도 0표와 X표로 명시해 놓았다. <br/>
   각각 통변성에 둔간(천반지지에 붙는 육임에서의 천간 명칭)의 숨은 작용력, 즉 진행 결과를 <br/>
   확정 시켰기 때문에 일반 통변성과 다른 내용일 수 있으나 의심치 말고 말하면 맞다.<br/>
   특히 초년에 '大시험 길'이라고 명시되어 있는 자는 반드시 사법고시 등 국가 고등 시험에 <br/>
   응시하면 합격할 자 이다. 참고하라.<br/>
<br/>
15. '수명(壽命)'<br/>
   수명 관계의 대체만 거론했다. 수명에 대해서는 언급하지 말라는 옛 현인들의 충고를 따른다. <br/>
   다만 위 10항의 사망 시기를 참조한다.<br/>
<br/>
16. '질병'<br/>
   무병자가 있다. 이는 '병 지나 감'으로, 침병 될 자는 질환을 표시했다. 병이 지나간다는 것은 <br/>
   병에 걸리더라도 낫는다는 의미다. 이런데서 우리는 활인의 지혜를 유추해야 한다.<br/>
<br/>
17. '배우자의 성질 용모 및 선, 후사(先 後死)' 여부<br/>
   배우자의 성질과 용모, 그리고 부부 중 누가 먼저 죽느냐? 는 것을 표시했다. <br/>
   다만 이때도 확실한 것만 말하라는 고인들의 조언을 따랐다. <br/>
<br/>
18. '형이상 법' <br/>
   귀신 침범, 부정빌미 침범, 산소 탈 침범을 따져 놓았다.<br/>
   해당인의 일이 꼬이고 병이 발생하고 자식의 우환, 부모의 재해 등이 이 부분에서 절대적인 무게를 <br/>
   갖는다는 것은 이미 고인들의 증험과 이 시대 임상을 통해 검증되어 있다.<br/>
   그 해소 방법은 단추를 누르면 설명되어 있다.<br/>
<br/>
19. '장애 육친'<br/>
   가족 중 누가 장애를 갖고 있고, 또 갖게 되며, 어느 연 월에 조심해야 하는가? 에 대해 명시했다.<br/>
<br/>
20.원수 '일생  적 타인'<br/>
   해당인 에게 평생에 걸쳐 위해를 가할 사람, 즉 직업, 사는 곳, 남녀를 명시했다. 가능한 멀리하라고 <br/>
   교시해 줘야 한다.<br/>
<br/>
21. '일생 은혜적 타인'<br/>
   해당인 에게 평생에 걸쳐 도움을 베풀어 줄 사람, 즉 직업, 사는 곳, 남녀를 명시했다. 이 사람들과 <br/>
   관계성을 좋게하는 것이 좋다는 것을 교시하라.<br/>
<br/>
22. '원수적 자녀와 친화적 자녀' 알기<br/>
   일생 자식 때문에 속을 끓이는 부모가 있다. 버림을 받고 심지어 목숨까지 빼앗긴다. <br/>
   낳지 말아야 할 해(年- 띠)에 낳으면 이렇게 된다. 이 예측을 명시한 것이다.<br/>
   반면 평생 부모를 영화롭게 하는 자식이 있다. '친화' '안정' '출세'로 표시해 놨다.<br/>
   그러므로 임신과 출산을 여기에 기준해야 하게끔 예언할 필요가 있다.<br/>
<br/>
23. '남녀 성력(性力)<br/>
   남녀의 성력을 명시했다. 잘 맞을 것이다. 참고도 될 것이다.<br/>
<br/>
24. '소육임' 일생사<br/>
<br/>
   첫 화면 좌측 당면사 맨 아래 세 칸으로 나뉜 위 23항 '남녀 성력' 우측칸에 일생사 소육임이 들어있다. 방문시간에 따라 <br/>
<br/>
   자동으로 구동되다가 우측 일생사에 출생년월일시를 적기하면 이곳의 월일시가 해당 것으로 바뀐다. <br/>
<br/>
   인생의 줄기가 단번에 잡힐 것이다.<br/><br/>

   다음은 명암법을 적용하여 명과 암으로 <br/>상대가 선택한 명암의 각 정도를 적시했다. <br/>


<br/>
25. 'CD프로그램 내용 옮겨 적기<br/>
   내일 방문(전화)자의 오는 시간에 의한 각 국수의 해당 본문 핵심사항을 옮겨 적는다면 <br/>
   그 메모 한 장만 갖고 10명이든 20명이든 모두 상담(친견)이 가능하다.<br/>
<br/>
26. 본 앱은 착목도를 위주로 편성했기 때문에 부연설명을 절약해도 되겠다. <br/>
자주 열어보면 익숙히 변별할 수 있을것이다.<br/>
<br/>
   방문(전화)자의 궁금한 것은 사실상 다음 몇 가지에 불과하다.<br/>
<br/>
   돠는가, 안되는가?<br/>
   좋은가, 안좋은가?<br/>
   할까, 말까? <br/>
<br/>
   나머지는 부연이고 군더더기이다. 깊고 넓을수록 우리의 말은 짧아진다. 필요한 사항만 <br/>
   단호히 말하고 상담(친견) 시간을 길게 하지 않는 것이 보다 효과적이다.<br/>
<br/>
   뒤쪽 양식에서 특별히 볼 것은 각 방문(전화) 12지지 시간마다의 밑에 작은 글씨로 된 <br/>
   지지인데 이 지지는 그 시간의 다음사람에 해당되는 시간 표시이다.<br/>
<br/>
   예를 든다.<br/>
   巳시에 첫 방문(전화)자가 와서 보고 갔다. 그런데 같은 巳시에 사람이 또 왔다. 巳시 밑에 子가 있다. <br/>
   子시 칸의 옮긴 말을 하면 맞게 된다. 다음사람이 또 와서 먼저 본 子시를 보니 卯가 써져 있다. <br/>
   묘시 칸 것을 말한다.<br/>
   이렇게 같은 시간에 12사람 것 까지 각기 다르게 판단할 수 있고 13반째 사람 부터는 동일한 <br/>
   내용으로 이어지는데 그래도 그 내용이 맞게 된다. 우주운동법칙의 순열 현상인 것이다.<br/>
   그러다가 시간이 午시로 넘어갔다. 그때는 아직 몇 사람이 대기하든 새로 오든 午시부터 다시 <br/>
   위 巳시에서의 방법대로 다시 봐 나가면 된다.<br/>
   다음 양식대로의 종이 한 장이면 되는 것이다.<br/>
   이때 순서를 잊었을 때는 숫자를 고르게 하여 그 고른 숫자가 표시된 좌측을 보고 그 숫자 칸의 <br/>
   내용을 말하면 맞는다.<br/>
   숫자를 사용한 시간은 그것으로 끝나고 차객에 포함하지 않는다. 이때는 다음시간으로 넘어갈 때 까지 <br/>
   계속 숫자를 선택하게 해서 정단하라.<br/>
   다만 주의할 것은 각 해당된 시간이 낮 시간인가? 밤 시간인가?를 알아 옮겨 적을 때 표시해둔 내용 <br/>
   앞의 낮 것 적시 내용과 밤 것 표시 내용을 잘 보라는 것이다.<br/>
   해당 국수 역시 옮겨 적을 때 이미 써두었겠기로 착오가 없다.<br/>
<br/>
<br/>
<br/>
     <br/>
<br/>
이상으로 본 'MY플러스' 보기요령을 설명했다.<br/>
<br/>
차 후 기회가 되면 항목 추가는 이 화면에 더 이상 여백이 없어 불가능하고 용어를 더 예리하게 다듬어 <br/>
여러분이 요구할 수 있는 부족성을 보완해 나아가기로 한다.<br/><br/>
<span style="font-weight : 700;">주지사항 </span><br/><br/>

1.본 앱과 도서 프로그램은 학습서가 아닌 상담활용서이므로 구입 장착 실행하였을 경우 정확도 효과성 착목도 속도성을 갖춘 저작권의 개요 간취가 가능함으로 반품, 환불요구가 거절됩니다.<br/><br/>
2.본 육임 AI의 품질 향상과 명성을 위해 구입자의 사용권은 실행파일을 구입한 날로 부터 2년으로 제한합니다. <br/><br/>
단, 이 2년동안 연 2회, 2년 4회의 업데이트에 응한 경우 매회 업데이트 서버적용일로부터 2년이 연장됩니다.<br/><br/>
3. 이때, 매회의 업데이트 작업비는 회당 10만원을 상회하지 않으며 최소한 연 1회 또는 2년 2회의 업데이트에 응하지 않는 경우 2년의 경과로 본 AI의 실행은 자동으로 중단됩니다. 그러나 2년과 2년 4년에 걸쳐 4회의 업데이트에 응한 때는 
영구적 실행을 보장합니다. 
<br/><br/>
귀하의 건안을 기원합니다.


<br/>
<br/>




<br/>
<br/>




                              2022년 9월 22일<br/>
                              동북산에서 남서강을 바라보며     고복자  효   사<br/><br/><br/><br/>

                              </div>
`;



const footer0 = `
<br/><br/>
COPYRIGHTⒸ 2022 HYOSA. ALL RIGHT RESERVED.`;

const footer1 = [
  {label : 'HELP'},
  {label : 'About us', href : '/help/about-us'},
  {label : 'FAQ', href : '/help/faq'},
  {label : 'Notice', href : '/help/notice'},
  {label : 'Agreement', href : '/help/agreement'},
  {label : 'Privacy', href : '/help/privacy'},
  {label : 'guide', href : '/help/guide'},
];

const footer2 = [
  {label : 'SITE MAP'},
  {label : 'LOG-IN', href : '/auth/signin'},
  {label : 'JOIN', href : '/auth/signup'},
  {label : 'ORDER', href : '/order'},
  {label : 'CART', href : '/cart'},
  {label : 'MYPAGE', href : '/auth/myinfo'},
  {label : 'MAGAZINE', href : '/magazine'},
  {label : 'Metaverse', href : '/metaverse'},
  {label : 'C-auction', href : ''},
];

export default {
  default0,
  defaultEn0,
  
  default1,
  default2,
  footer0,
  footer1,
  footer2,

  test0,
  testEn0,
  before1,
  church_before1,
};
