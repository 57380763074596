
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,8,5,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,9,4,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,3,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,11,2,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,12,1,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,12,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,2,11,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,3,10,`특별있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`신뢰`,4,9,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,5,8,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,7,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,6,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`형제`,`형제 단합 관련사`,`小`,``,``,``],
    [`친구`,`친구관련 費用사`,`小`,`12`,``,``],
    [`색정`,`외男 색정사 有`,`大`,`과시기`,`색정사有`,`결속단합`],
    [`수방화귀(須防火鬼)`,`화재방지가 꼭 필요한 상`,`大`,``,`반화위복`,`문서상신`],
    [`반화위복(反禍爲福)`,`재난이 되려 복으로 변하는 상`,`小`,``,``,`답신지체`],
    [`관사`,`관사`,`小`,``,``,``],
    [`직업`,`집업직위사`,`大`,`11`,`事後쟁탈`,`환성경사`],
    [`남자`,`남자관련파재공허사`,`小`,`쇠퇴기`,`직업관사`,`파재극처`],
    [`범위위곡(凡爲委曲)`,`일정 범위에서 굽은 것을 내버려둬도 괜찮은 상`,`小`,``,``,`동요통관`],
    [`환성지경(歡聲之慶)`,`소리를 지르며 기뻐하는 상`,`大`,``,``,``],
    [`박관`,`직업직위관련사`,`大`,``,``,``],
    [`극부`,`남편갈등사-출행이동원행사`,`小`,`10`,`도적失勢`,`능욕박해`],
    [`자식`,`자식관련사`,`小`,`침해기`,`직위불안`,`출행이동`],
    [`선체후통(先滯後通)`,`처음에는 지체되나 후에 뚫리는 격-병송(病訟)은 주의`,`大`,``,`애증화합`,``],
    [`천발천약(薦拔遷躍)`,`발탁 영전 도약의 상`,`小`,``,``,``],
    [`박관`,`직업직위불안사-복잡단합사`,`大`,``,``,``],
    [`결속`,`결속단합으로 동지사를 크게 이루나 금전 처첩을 상실함`,`小`,`9`,`미초희경`,`부처반목`],
    [`여식`,`여식 문제사`,`小`,`소외기`,`재처손실`,`대립가출`],
    [`처재손의(妻財損矣)`,`금전과 처의 손실이 걱정되는 상`,`大`,``,``,``],
    [`부처반목(夫妻反目)`,`부부불화대립의 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`8`,``,``],
    [`남편`,`남편 관련사`,`小`,`침체기`,`관사피해`,`가정막힘`],
    [`피제무용被制無用)`,`피해를 가해와도 무용되는 상`,`小`,``,`도적악의`,`굴욕반복`],
    [`도적악의盜賊惡意)`,`도적이 악의를 품었으니 공격하지 말아야 할 상`,`大`,``,`자연해소`,``],
    [`자금`,`자금 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`7`,`와병불기`,`존비상몽`],
    [`동요`,`외 동요분산사`,`小`,`단절기`,`동요분산`,`신분나이`],
    [`대리도망(大利逃亡)`,`국경을 넘어 도망치는 것이 큰 이익이 되는 상`,`小`,``,``,`차이음란`],
    [`와병불기(臥病不起)`,`병은 일어나지 못하고 구설은 크게 놀라게 됨`,`小`,``,``,``],
    [`금전`,`금전여인상업사`,`大`,``,``,``],
    [`매사허성불성`,`음1,2,4,5월 방문자는 종교진출로 성공함`,`大`,`6`,`비곡차욕`,`간인궤계`],
    [`매사영속성없음`,`금전사업처여인사는 선천적 기복재해를 겪음`,`小`,`존재기`,`출행이동`,`급속허사`],
    [`주색패괴(酒色敗壞)`,`주색에 의해 망한 듯한 상`,`小`,``,``,``],
    [`의어퇴장(宜於退藏)`,`시기를 잃은 듯하면 退하는 것이 축나지 않고 좋음`,`小`,``,``,``],
    [`결속`,`결속사로 음6월 재원바탕을 세우나명예직위남편 훼탈됨`,`小`,``,`관계불통`,``],
    [`매사허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출로 성공함`,`大`,`5`,`가택이사`,`충산분산`],
    [`매사영속성없음`,`직업직위권리남편남자사는 선천적 기복재해를 겪음`,`小`,`배양기`,`간사은닉`,`비용과출`],
    [`상인희봉(常人喜逢)`,`일반인은 기쁨을 맞고 관직자는 제한되는 상`,`小`,``,`사송계류`,``],
    [`송사계류(訟事稽留)`,`소송사가 계류되어 있는 상`,`大`,``,``,``],
    [`후원`,`후원 조력사`,`小`,``,``,``],
    [`급속`,`문서관련사-급속사`,`小`,`4`,`우유한가`,``],
    [`부친`,`부친 관련사`,`大`,`개시기`,`실업처지`,`왕래사송`],
    [`부가쟁혼(婦嫁爭婚)`,`재가(再嫁)하는 혼사로 다투는 상-병(病)에 통함`,`小`,``,`손해도망`,`계류`],
    [`반화위복(反禍爲福)`,`재난이 되려 복으로 변하는 상-단 부족함`,`大`,``,``,``],
    [`후원`,`후원 조력사`,`小`,``,``,``],
    [`모친`,`모친 관련사`,`小`,`3`,`미초희경`,``],
    [`색정`,`내女 색정사 有`,`大`,`학습기`,`권한부족`,`색정음사`],
    [`기이동용(豈利動用)`,`움직여 이익이 되는 상`,`小`,``,`희미경사`,`불변근신`],
    [`혼인결발(婚姻結髮)`,`혼인이 정해져 머리를 단장하는 격의 상-단 부족`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업직위사`,`大`,`2`,``,``],
    [`남편`,`남편권리사-내부가정 동요불안분산사`,`小`,`면모기`,`관사직업`,`가정분산`],
    [`우녀상회(牛女相會)`,`혼인을 위해 남녀가 만나는 상`,`小`,``,`음신안옴`,`발탁도약`],
    [`존비상몽`,`신분과 나이 차이가 큰 동침(同寢)관련의 상`,`大`,``,`와도나쁨`,``],
    [`명예`,`수상(受賞)사`,`小`,``,``,``],
    [`자산`,`직위 녹위사`,`小`,`1`,`간인궤계`,`재난전소`],
    [`막힘`,`외사 외교 막힘사`,`大`,`절정기`,`외교막힘`,`상대압박`],
    [`가택안녕(家宅安寧)`,`가택이 반드시 편안할 상`,`大`,``,`이전사有`,``],
    [`간인궤계(奸人詭計)`,`간사(간통)한 자의 계획은 꾸민 말임`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,"형재친구동료조력비용지출색정조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`관사직업지위남자관계사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위불안남편갈등자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안남자갈등자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`직업직위남편가정막힘사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,"금전상업부인조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,"후원문서부친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"후원문서모친색정조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사직업직위내부가정막힘사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,"명예자산권리사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`동료 또는 동업에 의해 발생한 일로 제일 친하거나 가까이 거주하는 자를 찾아 의논해 결정하면 현재의 혐의나 불안이 해소될 人`,0,0,`산속 깊이 숨고싶은 심정임**음해가 이어지는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 전격적으로 속결해야만 유리함`,0,0,`정당한 일이므로 속결하지 않고 좌고우면하거나 연기 지체시키면 결과가 불리해지는 人`,0,0,`일이 역순으로 띄엄띄엄 진행됨**일은 타협되고 조정양보됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 전격적으로 속결해야만 유리함`,0,0,`정당한 일이므로 속결하지 않고 좌고우면하거나 연기 지체시키면 결과가 불리해지는 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순음유화유순후결**만약 타인과 다툰다면 맞서 대응하지 말고 순응하며 유화적 처신으로 지체 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 순응유화로 처신하면 결과가 유리해지는 人`,0,0,`일이 결과없이 반복되기만 함**남자가 타인의 부인을 유혹하여 자기 여자처럼 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의심질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`1남2녀 삼가관계 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`반복불안 배신사로 내외암울한 人`,0,0,`일이 결과없이 반복되기만 함**입을 닫아야 할 입장이거나 입을 닫고 말하지 않음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순음유화유순후결**만약 타인과 다툰다면 맞서 대응하지 말고 순응하며 유화적 처신으로 지체 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 순응유화로 처신하면 결과가 유리해지는 人`,0,0,`1녀2남 삼각관계 있음**꼬리가 머리가 되어 순서 계통이 어지러운 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순음유화유순후결**만약 타인과 다툰다면 맞서 대응하지 말고 순응하며 유화적 처신으로 지체 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 순응유화로 처신하면 결과가 유리해지는 人`,0,0,`복잡다단한 일을 하나로 일관시키려 함-음3,4,5,6,9,12월 중 결실이 있게되나 되려 큰 손실이 초래됨-단 금전사는 단단한 기반이 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면  피아간 전의가 별로 없고 있어도 가벼움`,0,0,`옳지못한 일이 밝혀져 천둥에 놀란 듯한 두려움을 갖고 있으나 상대는 호가호위의 세를 부리는 것이므로 말을 삼가고 근신하면 자연히 해소되는 人`,0,0,`토지가옥사**내가 이기고 상대가 불리함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순음유화유순후결**만약 타인과 다툰다면 맞서 대응하지 말고 순응하며 유화적 처신으로 지체 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 순응유화로 처신하면 결과가 유리해지는 人`,0,0,`서리 내린 다리를 건너는 격**전화위복이 됨-단 부족함**독신고독각방주말부부샐리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 전격적으로 속결해야만 유리함`,0,0,`정당한 일이므로 속결하지 않고 좌고우면하거나 연기 지체시키면 결과가 불리해지는 人`,0,0,`일이 앞으로 전개되어 재력을 이루나 분산됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 동해도 중지가 됨`,0,0,`자기 스스로 자임하며 새로운 질 때를  기다리는 人`,0,0,`전후좌후가 막힘**깊은 산속으로 숨고싶은 심정이 될 때가 다가오고 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`죄고우면-무력`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아타발동동시가`,`기사`,`신구중간사`,`외내동시사`,`아표면타이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급소가`,`미사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사-후회사겹침`,`미사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`합의됨`,`합의됨`,`합의됨`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해됨`,`화해됨`,`화해됨`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음11월`,3,2,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음10월`,1,2,3,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음11월`,3,3,6,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음10월`,1,1,2,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음9월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,`음5월-연기 또는 파혼우려`,``,3,3,6,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음4월`,1,1,2,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월`,1,1,2,`음음9월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,3,1,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음1월`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 도움을 받고 업무를 성공적으로 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무는 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하고 업무도  중반의 자충수 외 초반 종반에 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻어 여러 일을 하나로 일관시키는 결속단합을 완성하여 세력을 굳건히 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 초 중반을 허무하게 보내고 종반에 실적을 내는 듯해도 역시 손해를 끼침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 재정을 분산시키는 동요사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 업무 전반이 공허하게 됨  `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무전반을  실패로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무전반을  실패로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무 전반이 수준 이하로 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무를 순조롭게 집행하나 저조한 실적으로 마치게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 초반의 실적 외 중 종반이 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선損후合`,`退背`,` 근`,` 去`,`주인가해`,`정북`,``,`정북`],
    [`10.11`,``,`내부합세`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,`끌면불가`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선損후難`,`進向`,`  원`,`  留`,`주인가해`,`북북서`,``,`북북동`],
    [`10.11`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`이행`,`선난후이`,`進向`,`  근`,`  留`,`상호파재`,`동북`,``,`동북`],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인무력`,`이행`,`본인무력`,` 退背`,`  원`,` 去`,`세입가해`,`정동`,`정북`,``],
    [`10.11`,` 多集`,`내부제압`,``,``,``,``,``,`이후합세`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`무력`,`이행`,`본인무력`,`退背`,`원근왕래`,`  去`,`세입가해`,`동남`,`북북동`,``],
    [`10.11`,``,`지체제압`,``,``,``,``,``,`주인이사`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`할뜻有`,``,`함`,``],
    [`1.2월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`내부반대`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선난후損`,`進向`,`  원`,`  留`,`상호동요`,`북북서`,`서님`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선이후난`,`退背`,`원근왕래`,`  去`,`세입조력`,`정북`,`정서`,``],
    [`10.11`,`  集`,`무력`,``,`빠르게`,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`무력`,`이행`,`선이후난`,`退背`,`  근`,`  去`,`세입조력`,`북북동`,`북서`,``],
    [`10.11`,``,`본인제압`,``,`빠르게`,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`전부반대`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근합세`,`이행`,`사안무력`,`退背`,`  근`,`  去`,`주인무력`,`서남`,`북북서`,``],
    [`10.11`,``,`본인동조`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인반대`,`이행`,`사안무력`,`退背`,`  원`,`  去`,`주인무력`,`정서`,``,`정북`],
    [`10.11`,``,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근반대`,`이행`,`선攻후攻`,`進向`,`  근`,`  留`,`주인조력`,`동남`,``,`북북동`],
    [`10.11`,``,`내부합세`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,`지연제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`내부반대`,`이행`,`선난후난`,`進向`,`  근`,`  留`,`상호무난`,`북북서`,``,`남남동`],
    [`10.11`,` 多集`,`지연동요`,``,``,``,``,``,`이후반목`,``,``,``],
    [`3.6\n9.12`,``,`유일제압`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`전흉사귀`,`혈광사망`,`상가부정`,`조문왕래`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`전사귀`,`전사`,0,0,`육친`,``,``],
    [`질병`,` 여귀`,`호주부인`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`가장귀`,`호주사망`,"목기부정","목물반입",`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,"여고음강","사망시모",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,`서남`,`특별있음`],
    [``,``,``,0,0,`묘향`,`북서`,``],
    [`생업`,``,``,0,0,`전경`,`큰도로`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,``,``,0,0,`육친`,`부친`,``],
    [`질병`,`전흉사`,`혈광사망`,0,0,`묘좌`,``,`있음`],
    [``,`도로귀`,`교통사망`,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`전사귀`,`전사`,0,0,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`제례귀`,`제례위범`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,`남남동`,`있음`],
    [``,``,``,0,0,`묘향`,`북북서`,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,`등라윤천`,``],
    [``,``,``,0,0,`묘속`,` 뱀굴`,``],
    [`가정`,``,``,0,0,`육친`,`증조모,처`,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,`동남`,`없음`],
    [``,`전흉사귀`,`혈광사`,0,0,`묘향`,`북서`,``],
    [`생업`,``,``,0,0,`전경`,`습지`,``],
    [``,``,``,0,0,`묘속`,`교룡`,``],
    [`가정`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`육친`,`조부`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`사통관계인의 거짓말 관련사-이동사`,`명예자산권리사**외교막힘`,`비상지애사`,`외男색정사`,`밤에 당하는 능욕박해사`,`내사파재극처사`,`환성경사`,`내사 애증-출행이동원행사`,``,`기회노리는헌책회답지체-구설언제없어질지의문사`,`매사위역사**내부가장막힘`,`재난일체해소`,`발탁영전도약사`,`외사 동요분산-출행이동원행사`,`매사허성불성**세속허사자**탄식신음-병 또는 事 미수`,`음1,2,4,5월방문자는종교진출로성공함`,`매사영속성없음**문화풍아-觀書`,`종교진출하면자립함`,`외사 급속사`,`도적실세-포획함`,`事후쟁탈사`,`내女색정사`,`매사위역사**재난이 복이 됨`,`내사 동요불안분산사-개통됨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`전도`,`전화위복`,`빈궁**관재`,``,`재액해소`,`빈궁**관재`,`재액해소`,``,`빈궁**관재`,``,`음4월 화재주의`,`빈궁**관재`,``,``,``,``,`전화위복`,`빈궁`,`빈궁**병재`,``,`침체경구사`,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사**재난이 복이 됨`,`내사동요불안분산사-개통됨`,`사통관계인의 거짓말 관련-이동사`,`명예자산권리사**외교막힘`,`비상지애사`,`외男색정사`,`밤에 당하는 능욕박해사`,`내사파재극처사`,`환성경사`,`내사 애증-출행이동원행사`,``,`기회노리는헌책회답지체-구설언제없어질지의문`,`매사위역사**내부가장막힘`,`재난일체해소`,`발탁영전도약사`,`외사 동요분산-출행이동원행사`,`매사영속성없음**탄식신음-병 또는 事 미수`,`종교진출하면자립함`,`매사영속성없음**문화풍아-觀書`,`종교진출하면자립함`,`외사 급속사`,`도적실세-포획함`,`내女색정사`,`事후쟁탈사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`전도**관재**병재`,``,`빈궁`,`전화위복`,``,`전고**빈궁**관재`,``,`관재`,``,`빈궁`,``,`전도**관재**빈궁`,``,`음4월 화재주의`,``,``,``,`전도**빈궁`,``,`전화위복`,`빈궁**병재`,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내女색정사`,`事후쟁탈사`,`매사위역사**재난이 복이 됨`,`내사동요불안분산사-개통됨`,`사통관계인의 거짓말 관련사-이동사`,`명예자산권리사**외교막힘`,`비상지애사`,`외男색정사`,`밤에 달하는 능욕박해사`,`내사파재극처사`,`환성경사`,`내사 애증-출행이동원행사`,``,`기회노리는헌책회답지체-구설언제없어질지의문`,`재난일체해소`,`매사위역사**내부가정막힘`,`발탁영전도약사`,`외사 동요분산-출행이동원행사`,`매사영속성없음**탄식신음-병 또는 事 미수`,`종교진출하면자립함`,`매사영속성없음**문화풍아-觀書`,`종교진출하면자립함`,`외사 급속사`,`도적실세-포획함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`빈궁**병재**관재`,``,`재액해소`,`빈궁`,`전화위복`,`재액해소`,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재`,``,`음4월 화재주의`,``,``,``,``,``,`전화위복`,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`기쁜소식사`,`외사 급속사`,`사통관계인의 교언 관련사`,`내女색정사`,`소인쟁재사`,`내사동요불안분산사-개통됨`,`명예자산권리사**외교막힘`,`허리굽히는 반복사`,`외男색정사`,`부부반목사`,`불리 고시-없는구설근심사`,`내사파재극처사**불리 고시-없는 구설근심사`,`내사 애증-츨행이동원행사`,`실기 즉 물러나야 만 아끼게 되는 상`,`관계불통-가택이사-가족질병사`,``,`화장이지워질정도의 悲哭차욕`,`내부가정막힘`,`외사 동요분산-출행이동원행사`,`와병불기-구설놀램`,`매시영속성없음**도적악의-공격말 것`,`종교진출하면자립함`,`절제희경사**매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**병재`,``,`빈궁`,`재액해소`,`관재`,``,`빈궁`,`전화위복`,``,`재액해소`,`관재`,``,`빈궁`,``,``,`재액해소`,`관재`,`음4월 화재주의`,`전도**빈궁`,``,`전도**빈궁`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`절제희경사**매사영속성없음`,`종교진출하면자립함`,`외사 급속사`,`기쁜소식사`,`사통관계인의 교언 관련사`,`내女색정사`,`소인쟁재사`,`내사동요불안분산사-개통됨`,`명예자산권리사**외교막힘`,`허리급히는 반복사`,`외男색정사`,`부부반목사`,``,`내사파재극처사**불리 고시-없는 구설근심사`,`내사 애증-출행이동원행사`,`실기 즉 물러나야 만 아끼게 되는 상`,`관계불통-가택이사-가족질병사`,``,`내부가정막힘`,`화장이지워질정도의悲哭차욕`,`외사 동요분산-출행이동원행사`,`와병불기-구설놀램`,`매사영속성없음**도적악의-공격말 것`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,`전도**빈궁**병재`,``,`전도**빈궁**관재`,``,``,`재액해소`,`빈궁`,`관재**전화위복`,`재액해소`,`빈궁*8관재`,``,`빈궁****관재`,``,``,``,`관재`,``,`음4월 화재주의`,`빈궁`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`매사영속성없음**도적악의-공격말 것`,`종교진출하면자립함`,`절제희경사**매사영속성없음`,`종교진출하면자립함`,`전도**빈궁**병재`,`기쁜소식사**외사 급속사`,`사통관계인의 교언 관련사`,`내女색정사`,`소인쟁재사`,`내사동요불안분산사-개통됨`,`명예자산권리사**외교막힘`,`허리굽히는 반복사`,`외男색정사`,`부부반목사`,`불리 고시-없는 구설근심사`,`내사파재극처사`,`내사 애증-출행이동원행사`,`실기 즉 물러나야만 아끼게 되는 상`,`관계불통-가택이사-가족질병사`,``,`내부가정막힘`,`화장이지워질정도의 悲哭차욕`,`외사 동요분산-출행이동원행사`,`와병불기-구설놀램`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`빈궁`,`동요불안분산사`,``,`동요불안분산사`,`전화위복`,`동요불안분산사`,`전도**빈궁**관재`,`동요불안분산사`,`관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`관재**재액해소**전화위복`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,``,`동요불안분산사`,`관재`,`빈궁**빈궁**동요불안분산사`,`음4월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외사 동요분산출행이동원행사`,`와병불기-구설놀램`,`매사영속성없음**도적악의-공격말 것`,`종교진출하면자립함`,`절제희경사**매사영속성없음`,`종교진출하면자립함`,`빈궁**관재**병재`,`기쁜소식사**외사 급속사`,`내女색정사`,`사통관계인의 교언 관련사`,`내사동요불안분산사-개통됨`,`소인쟁재사`,`명예자산권리사**외교막힘`,`허리굽히는 반복사`,`외男색정사`,`부부반목사`,`불리 고시-없는 구설근심사`,`내사파재극처사`,`내사 애증-출행이동원해사`,`실기 즉 물러나야만 아끼게 되는 상`,`관계불통-가택이사-가족질병사`,``,`내부가정막힘`,`화장이지워질정도의 悲哭차욕`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4월 화재주의`,`빈궁`,``,`빈궁`,``,``,`전화위복`,`재액해소`,`빈궁`,`재액해소`,`전도**관재`,``,`빈궁**관재`,``,`전화위복`,``,`전도**빈궁**관재`,``,`빈궁`,``,`관재`,``,``,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`내부가정막힘**재난일체해소`,`화장이지워질정도의 悲哭차욕`,`외사 동요분산-출행이동원행사`,`매사위역사**구설송사흉-문서소식吉`,`매사영속성없음`,`종교진출하면자립함`,`절제희경사**매사영속성없음**사송계류사`,`종교진출하면자립함`,`상린븐산사`,`기쁜소식사**외사 급속시`,`외女색정사`,`사통관계인의 교언 관련사`,`내사동요불안분산사-개통됨`,`매사위역사**소인쟁재사**재난이 복이됨`,`명예자산권리사**외교막힘`,``,`부부반목사**놀래고의심되어가정불녕-실재근심`,`외男색정사`,`내사파재극처사`,`존비상침사`,`내사 애증-출행이동원행사`,``,`관계불통-가택이사-가족질병사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`음4월 화재주의`,``,`재액해소`,``,`재액해소`,``,`전화위복`,`빈궁`,``,`빈궁**병재**관재`,``,`전도`,``,`전도**빈궁`,`전화위복`,`빈궁**관재`,``,`빈궁**관재`,`관재`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`관계불통-가택이사-가족질병사`,``,`내부가정막힘**재난일체해소`,`화장이 지워질정도의 悲哭차욕`,`외사 동요분산출행이동원행사`,`매사위역사**구설송사흉-문서소식吉`,`매사영속성없음`,`종교진출하면자립함`,`절제희경사**매사영속성없음**사송계류사`,`종교진출하면자립함`,`상린분산사`,`기쁜소식사`,`내女색정사`,`사통관계인의 교언 관련사`,`내사동요불안분산사-개통됨`,`매사위역사**소인쟁재사**재난이복이됨`,`명예자산권리사**외교막힘`,``,`부부반목사**놀래고의심되어가정불년-실재근심`,`외男색정사`,`내사파재극처사`,`존비상침사`,`내사 애증-출행이동원행사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재액해소`,`빈궁**관재`,`음4월 화재주의`,`전도**빈궁`,``,`전도`,``,`빈궁`,`전화위복`,`외사 급속사`,`빈궁`,``,`재액해소`,`관재**병재**빈궁`,``,``,`전화위복`,`빈궁`,`재액해소`,`빈궁**관재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내사 애증-출행이동원행사`,`도적을 찾아봐도 못잡음`,``,`사행불량`,`내부가정막힘`,`화장이지워질정도의 悲哭차욕`,`외사 동요분산-출행이동원행사`,`발탁영전도약사`,`매사영속성없음**말 말고 근신할 것`,`종교진출하면자립함`,`매사영속성없음**투서헌책 은좋지않음-구성실재사`,`종교진출하면자립함`,`외사 급속사`,`혼인사`,`내女색정사`,`형벌사`,`내사동요불안분산사-개통됨`,`소인쟁재사`,`사통관계인의 거짓 말 관련사-이동사`,`명예자산권리사**외교막힘`,`기쁜소식사**소식 안옴-와도 나쁨`,`외男색정사`,`내시피재극처사`,`승진영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`전도**관재**빈궁`,``,`관재`,``,`음4월 화재주의`,``,``,`빈궁`,``,`빈궁`,`전화위복`,``,``,`빈궁`,``,`관재`,``,`빈궁`,`전화위복`,`빈궁**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`승진영전사`,`내사파재극처사`,`내사 애증-출행이동원행사`,`도적을 찾아봐도 못잡음`,`사행불량`,`내부가정막힘`,`화장이 훼손될정도의 悲哭차욕`,``,`외사 동요분산-출행이동원행사`,`발탁영전도약사`,`매사영속성없음**말 말고 근신할 것`,`종교진출하면자립함`,`매사허성불성**세속허사자**불의투서헌책-구설실재`,`음3,4,5,6,9,12월방문자는종교진출로성공함`,`외사 급속사`,`혼인사`,`내女색정사`,`형벌사`,`소인쟁재사`,`내사동요불안분산사-개통됨`,`명예자산권리사**외교막힘`,`사통관계인의 거짓말 관련사-이동사`,`기쁜소식사**서식 안옴-와도 나쁨`,`외男색정사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재액해소`,`빈궁**관재`,`재액해소`,`빈궁`,``,`관재`,`음4월 화재주의`,``,`빈궁`,``,`빈궁`,``,`전화위복`,``,`빈궁`,``,`관재`,``,`폐한인`,`전도**빈궁`,`전화위복`,`전더**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외男색정사`,`비상지애사`,`밤에 당하는 능욕박해사`,`내사파재극처사`,`내사 애증-출행이동원행사`,`환성경사`,`기회노리는헌책회답지체-구설언제없어질지여부`,`내부가정막힘`,`매사위역사`,``,`외사 동요분산-출행이동원행사`,`발탁영전도약사`,`탄식신음-병 또는 事 미수**말 말고 근신할 것`,`음1,2,4,,5월 방문자는 졸교진출하면 성공함`,`매사허성불성**세속허사자**문화풍아-觀書`,`음3,4,5,6,9,12월방문자는종교진출로성공함`,`외사 급속사`,`도적실세-포획함`,`내女색정사`,`事후쟁탈사`,`매사위역사**소인쟁재사**재난이 복이됨`,`내사공요불안분산사-개통됨**화가 복이됨`,`명예자산권리사**외교막힘`,`사통관계인의 거짓말 관련사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`재액해소`,`빈궁**관재`,``,``,`빈궁`,``,`재액해소`,`빈궁**관재`,`음4월 화재주의`,`전도`,``,`전도`,``,`빈궁`,`전화위복`,`빈궁**병재`,``,``,`재액해소`,`빈궁**병재**관재`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ ⊕돈을 기다리고 
  ⊖간사한 꼬임에 빠져 마음이      떠있네? 
⊕⊖도로귀ㆍ전흉사귀를 해소      하지 않으면 관재로 이어      지겠어! 병도 침범하겠고!
   가택에 음사부정이 듬.
`,`■⊕⊖전흉사귀침범으로           병이 왔어! 
가족 중에 병을 낫게 해줄 사람이 있겠는데, 
송사가 걱정이야!

 ⊕⊖신변 상가부정이 들었         네 ?
`,`■가정에 자손사 흉이 있겠어!

⊕가택귀ㆍ가택음사부정, 
⊖전흉사귀 발동이네! 
  서로 속이고 있어! 
  기도하는 수밖에 없어!
`,`■ 신규사 계획도 좋지만

⊕변사귀, 
⊖전사귀와 산소를 우선 
  다스려야해! 
  그러면 목적달성이야!

⊕신변상가부정이 들었어 !
`,`■ 금전과 여자가 다 부서져     버리는 운세야!

⊕여귀, 
⊖도로귀 침범 때문이야! 
  남의 부인을 가로챘네! 
  바람났어!
`,`■금전 여자로 기로에 섰네?    가택권리가 남한테 넘어갔어!    이사도하겠네?

⊕변사귀, 
⊖전사귀 장난이네? 
  一男二女 삼각관계, 임신낙태,    별리사도 있겠어 !
`,`■ 가정에 귀신과 부정이 가득     찼으니 일이 뒤집어지지!     색정배반사로 일이 매듭이     없겠어!

⊕⊖가택부정이 듬.
`,`■ 一女二男 삼각관계도 있고,     上下순서도 없고!
⊕여귀ㆍ신불제사귀, 
⊖여귀ㆍ변사귀훼방이네!     즉시 막지 않으면 한 달안에    가족중 대흉을 당하겠어!
⊕⊖신변에 수망노부부정이      침범했네 ?
`,`■일많고 사람많고 그걸 하나     로 결속하겠는데 그 일로     직위도 달아나고 부부이별     까지 하게 생겼어!

⊕전사귀, 
⊖변사귀 발동이야!
`,`■마음에 겁을 잔뜩 먹고     있는데 땅 때문에 소송이    붙었겠어! 

이게 모두 귀신(삼전 전귀)이 득시글거려 생긴 일인데 
해소시키면 좋아지겠어!
`,`■ 가정이 비었는데 사업이고     뭐고 되겠어? 
거기다 
⊕음귀ㆍ전흉사귀, 
⊖신불제사귀ㆍ자살귀까지     발동하고 있으니 살얼음판을    걷기지!
`,`■ 다 좋네? 
   그냥 돌아가세요. 
단,
⊕ 음귀, 
⊖ 신불제사귀는 꼭 해소시켜야     순조롭겠어!

⊖신변 상가부정 침범했어 !`];
  const YOGYEOL1 = [`■ 덕녹에 정마가 타서 재물을 탐하면 편하지 않을 것이다. 병과 관재가 발생하게 된다.
  `,`■ 저나 나나 곤궁하니 나는 더하고 저는 가볍다.
  중간에 나를 돕는자가 있어 고생 끝에 좋은 일이 있을 것이다.
  `,`■ 비록 화목하나 손해가 있다. 간전 격이나 손에 쥐고 있으니 생각 밖을 벗어나지 않는다. 일은 잘 안되고 바르지도 않다.
  `,`■ 장생이 있으나 믿기 어렵다. 백호와 현무가 있기 때문이다. 재물을 보거든 입을 다물어야 다른 사람이 안 가져 간다.
  `,`■ 관귀는 공망이니 두렵지 않다. 묘신이 집과 말전에 있다. 병은 고생이요 송사는 나쁘나 기다리는 사람은 즉시 온다.
  `,`■ 상하가 순서가 없으니 아랫사람에게 속아 후회하게 된다. 괴이한 일은 집에 발생하게 된다.
  `,`■ 사과삼전에 재물이 가득하다. 귀인이 극을 당하니 도망가는 것이 좋으리라.
  `,`■ 아랫사람이 윗사람을 해친다. 피하려해도 어렵다. 비록 나서주는 자식이 있어도 힘이 부족하여 성과가 없다.
  `,`■ 밤정단에 어려운 일이 생기면 돈은 들어가나 흉은 면한다. 일반인은 매우 좋고 관공직자는 아주 나쁘다.
  `,`■ 서로 집어 삼키려하니 밤정단은 더욱 나쁘다. 다행히 막아줄 사람이 있고 여러 사람이 예우해 주리라.
  `,`■ 좋은 장생이 있으나 시기하는 것이 많다.
  친구나 형제를 믿지 말라. 낮정단은 더욱 나쁘다.`
  ,`■ 낮정단은 숨은 귀신이 범을 탔으니 이를 어찌 막을꼬. 구해줄 사람을 믿지 말라. 자기도 고통스러운데 누구를 구할까.`]
  const SIBO1 = [`■ 원방의 송금이 있다.
  ⊕⊖가정에 음사부정으로
  우환이 있고 가장을 속이며
  가인이 원한을 품고 가해하려 한다. 이사하는 것이 좋다. 관재와 병들 것이 걱정이다. 만사를 개혁ㆍ개신 시켜야만 한다.
  신음고로하면서도 움직이려 한다.
  `,`■ 병 문제로 선택의 기로에
  있다. 동쪽에 약이 있다.
  만약 관재를 당하면
  도피부터 하고 봐야한다.
  은신하여 기도하면 상황이 호전되게 된다.
  그러나 멀리가면 재앙을 당한다. 자충수적 우환을 주의하라.
  `,`■ 이익사가 있고, 소득하게
  된다. 일이 점점 어두워
  지므로 움직이면 손해가
  있게 된다. 또 가택 손상사가 발생한다.
  단, 교섭사는 달성되므로 좋으나 서로 속이려는 뜻이 있음을 경계해야 한다.
  `,`■ 이익사가 있고, 소득한다.
  가내 우환이 있고, 가장을
  속이며, 가인이 원한을 품고
  가해하려 한다.
  금전ㆍ여인사에 구설이 있다. 자칫 소송건으로 번진다. 사전 예방하고 해소시켜야 좋다. 財의 운세는 매우 좋다.
  `,`■ ⊕가정부처반목, ⊖가정
  비상지애사가 발현됐다.
  금전의 손모가 있다.
  상호 의심하고 있다.
  웃음속의 칼, 꿈속의 독이 있음을 경계해야 한다.
  결국 이별사에 이른다. 
  부부가 음탕하다.⊕男은 타인의 부인을 유혹하여 거래된다.
  `,`■ 임신사가 있고 중절한다.
  가내 재녀사로 선택의 
  기로에 섰다.
  一男二女의 삼각관계가 있다. 女는 고독하다. 가택의 권리가 넘어간다. 반객은 나쁘다. 
  어떤 일도 처음과 중간에 기대가 없다. 참고 견디면 30일 후 되는게 있다.
  `,`■ 가정에 ⊕⊖가택부정이
  들어 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  구재사는 유리하고 큰 돈을 빠르게 얻게 된다.
  財事ㆍ관직ㆍ시험사 외에는 기대할게 많다.
  `,`■ 자충수적 우환이 있다.
  一女二男 삼각관계가 있다.
  타인이 스스로 와서 피해를 주고 능욕을 끼친다.
  모든 일이 집안 내부에서 일어나고 육친 중 신변 재앙이 30일내에 기하므로 주의해야 한다.
  `,`■ 남자는 권리 상실의 일이
  있고, 여자는 남편의凶,
  별리ㆍ고독사가 있다.
  人宅 모두 기운다.
  직위손상사가 기한다.
  부부간 이별의 兆이다.
  매사가 의지와 등을 돌리고 있는 형국이다.
  서로 의심한다.
  `,`■ 남자는 권리상실의 일이
  있고, 여자는 별리ㆍ
  고독녀이다.
  ⊕ 병, ⊖ 금전의 피해가 있다.
  가정에 간사사가 있어 재판에 이른다.
  토지사가 현안문제로 된다. 심중놀램이 있다.
  근신하고 기다려야 한다.
  `,`■ 신변에 저주사가 있다.
  남자는 별리ㆍ고독인이다.
  후원사 불성으로 전전긍긍
  하고 있다. 서로 의심이 있다. 일도 기대가 안된다. 단, 육의가 있어 종교인이라면 천우신조가 있게 된다.
  七月 임신사가 있다.
  ⊖음사부정도 침범했다.
  `,`■ 가정에 ⊕금전ㆍ여인
  구설사가 있고 
  ⊖어른과 금전ㆍ여자문제를
  따지는 일이 있다.
  점진적으로 白明하여 吉하게 된다.
  ⊕사용인과 주인의 생각이 어리석고, ⊖진직ㆍ천관의 기쁨이 있으나 처음 결정에 응해야 한다.`]
  const SIBO2_1 = [`■직ㆍ녹,신변이동외사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■가택상복·동요내사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■자손ㆍ박관신변외사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■금전ㆍ여인신변동요사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  직․녹허위다툼이 있다.
  `,`■근ㆍ친,가택내사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다. 재·녀 손실 예방이 필요하다.
  `,`■임신ㆍ여인사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.이권상실하고 재·녀에 재액이 발생한다.
  `,`■금전ㆍ여인신변동요사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  색정불화․이심 원한의 상이다.
  `,`■자손ㆍ박관신변외사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■후회하는 관사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.男직위상실하고 女부부이별한다.
  `,`■가택내동요사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■후원ㆍ사업목전사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  순역이 혼란하다.
  `,`■가택내동요사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  소재(小財)의 상이다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/029/row-1-column-1.png`,
    `/static/images/sagwa/029/row-1-column-2.png`,
    `/static/images/sagwa/029/row-1-column-3.png`,
    `/static/images/sagwa/029/row-1-column-4.png`,
    `/static/images/sagwa/029/row-1-column-5.png`,
    `/static/images/sagwa/029/row-1-column-6.png`,
    `/static/images/sagwa/029/row-1-column-7.png`,
    `/static/images/sagwa/029/row-1-column-8.png`,
    `/static/images/sagwa/029/row-1-column-9.png`,
    `/static/images/sagwa/029/row-1-column-10.png`,
    `/static/images/sagwa/029/row-1-column-11.png`,
    `/static/images/sagwa/029/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 복음과(伏吟課)로서 양일은 일간상신의 해(亥)를 사용하여 초전으로 하고, 초전이 자형(自刑)되면 충신(冲神)을 사용하여 말전으로 한다. 양일은 자임두전격(自任杜傳格)이다.
    임(壬)의 일간이 해(亥)를 보고 덕록(德祿)으로 되며 정신이 앉고, 일지상신은 묘귀(墓鬼)로 되며 사신이 승하여 몸을 상한다. 3전에 공(空)․사(蛇)․백(白)의 흉장(凶將)이 승하므로 모든 일에서 흉화가 발생될 두려움이 있다. 그러나 현임 관리는 최관부(催官符)로 되어 길경이 있다. 복음은 엎드려 움직이지 않으나, 정신(丁神)이 승하면 움직임을 주관하므로 조용하게 지킬 수가 없다. 그러나 두전격(杜傳格)은 사물이 중지되므로 방침과 책략을 고쳐 진행하면 성취된다. 다만 분수를 알고 실행하여야 길복을 얻는다.
    백법에 이르기를 ｢복음과로서 정신(丁神) 역마가 있는 것은 움직이게 된다｣고 하였다. 수일(水日) 정재(丁財)는 재에 의하여 움직인다. 비전에서는 ｢정신이 재동으로 되면 움직임의 여하를 불문하고 반드시 먼곳으로부터 송금을 받는다｣고 했다. 또는 친족 가운데서 처가 없는 자는 처를 맞는다.
    일귀는 순행하여 3전이 상․사․백이고, 야귀는 역행하여 3전이 공․사․백이다. 일야(日夜)가 모두 사(蛇) ․호(虎)를 보면 질병이나 소송사의 정단에서는 가장 흉하다. 공명․구직도 흉하다. 무릇 천지2반이 같은 궁에 엎드려서 신음하며 움직이지 않는 형상이다.
    양일은 자임격(自任格)이고 음일은 자신격(自信格)이다. 조용함이 극하면 생각은 움직이려 한다. 두전격은 폐색되어 통하지 않으니 임신을 정단하면 불구자를 잉태한다. 대인은 소식이 없다. 자신이란 스스로 강한 것이다. 이 과는 일간일지의 왕쇠를 본다. 왕하면 전진해야 좋고, 쇠하면 수세를 취한다. 초전이 흉하면 시기를 기다려야 한다.`
    ,
    // 2
    `  이 과는 제1과․제3과에 음양의 극이 있고 양일이니 양비(陽比)의 극이다. 제1과 술해(戌亥)를 초전으로 하는데, 술(戌)이 일간상신에 있어서 초전으로 되면 참관격(斬關格)을 구성한다.
    중․말전에 신유(申酉)가 있으면 참관이 통하고 일간상신의 술(戌)은 백호가 승하여 일간을 극하므로 손상이 극심하다. 그러나 다행히도 일지상신의 목(木)이 귀살(鬼神)을 제어하므로 해는 경미하다. 그리고 중․말전의 금(金)은 일간을 생조하고 초전의 칠살은 중전․말전의 인수(印綬)로 생화하므로 흉을 풀어 스스로 안전하게 된다. 지일과(知一課)는 사물이 두 갈래에 걸쳐 있다. 해(亥) 위에 술(戌)이 초전으로 되는 것은 괴도천문(魁度天門格)으로 되어 모든 일에서 장애가 있으니 주(株)를 지키는 것은 좋지 않다. 속히 도피하는 것이 좋다. 3전 중에서 초전은 흉하나 중․말전이 길조이면 고생을 맛본 뒤에 길행을 만나는 형상이다.
    백법에 이르기를 ｢괴도천문격은 관격(關隔)이 있다. 일간에 묘(墓)와 호(虎)가 승하면 병을 정단하지 말며, 제귀(制鬼)의 자리에는 양의(良醫)가 있다｣고 하였다. 비전에는 ｢잃어버린 물건을 정단하면 귀(鬼)가 백호에 승하여 초전이 되는 것은 술(戌)이면 남자 사용인이고, 신(申)에 태상(太常)이 승하면 여자의 옷을 도적 맞으며, 유(酉)의 서쪽 이웃에 인연이 있는 물건이다. 가을은 신유(申酉)의 왕기(旺氣)이므로 잡을 수 있다｣고 하였다. 백호와 사신(死神)․사기(死氣)가 간상 또는 지상에 임하거나 초전이 백호 또는 일묘(日墓)․귀묘(鬼墓)로 되는 것은 백화격(魄化格)이라고 한다. 굶은 범을 만나서 손상을 받는 형상이다. 
    일귀는 순행하여 3전이 백․공․청이고, 야귀는 역행하여 3전이 백․상․현이다. 년명상신(年命上神)은 입명(立命)이라 불러 점과의 제극(制剋)․생화(生化)․길흉의 변화를 안다. 행년은 남녀의 인신(寅申)을 표준으로 하여 자기의 나이까지 센 천반을 행년으로 한다. 별법으로는 그 나이에 앉는 천반을 행년으로 보는 비전(秘傳)이 있다. 어느 것을 채용해도 된다.`
    ,
    // 3
    `  이 과는 4과 중에서 제3과만 상에서 하를 극하여 인진(寅辰)을 초전으로 하는 원수간전격(元首間傳格)이다.
    일간상신 유(酉)와 일지 진(辰)이 지합(支合)하고 기궁(寄宮) 해(亥)와 지상신(支上神) 인(寅)이 지합하여 교차지합(交車支合)하므로 서로 화순하다. 일간에서 지상 인(寅)에 탈기(脫氣)되고 지지는 간상 유(酉)에 탈기하므로 피차 모두 속이려는 뜻이 있다. 3전 인자술(寅子戌)은 명음격(冥陰格)으로 된다. 즉 인(寅)은 태양이 장차 솟으려는 새벽으로 자(子)에 전하고 술(戌)에 이르니 명(明)을 버리고 암(暗)에 머무르는 형상이다. 흉암(凶暗)이 앞에 있으므로 움직이면 반드시 손해를 초래한다. 백법에는 ｢교차상합되는 것은 교관(交關)에서 이익이 있고, 인택이 교차탈기하니 피차가 모두 도난의 재해를 만난다｣고 하였다. 비전에서는 ｢유(酉)와 인(寅)이 일간일지상신(日干日支上神)에 있으면 가정 내에 불행사가 있다｣고 하였다.
    일귀는 역행하여 3전이 동․합․청이며, 蛇가 화하여 용으로 되니 공명은 장래에 이르러 원대해질 희망이 있다. 야귀는 3전이 합․청․백이다. 일본 ｢아베｣의 월장법은 비장법(飛將法)이라 부르는 구식 법칙에 의한 것이고, 중기과궁(中氣過宮)에서 월장을 정하는 것은 천리(天理)에 합치되므로 구식을 버리고 본 중기법으로 쓰기 바란다. 태양의 전도(纏度)는 모두 중기(中氣)에서 전도(轉度)하는 것이라는데 생각을 돌려야 한다. ｢아베｣도 만년에 이 법칙이 맞다고 하고 있다.`
    ,
    // 4
    `  이 과는 4과 중에서 제2과의 사신(巳申)의 극을 사용하여 초전으로 하는 원수원태격(元首元胎格)으로 된다.
    일간상신 신(申)은 장생(長生)이나 현무(玄武)․백호의 흉장이 승하여 길한 가운데 놀라운 사건을 만난다. 순미(旬尾)의 사(巳)가 초전으로 되므로 폐구격(閉口格)으로서 재성으로 되며, 중전에 역마, 말전에 정신이 재효(財爻)로 되니 활동에 의하여 재를 구할 수 있다. 원태격은 먼저 정체되고 후에는 통하며, 4과의 3상신이 3형(三刑)으로 되어 피차가 모두 의지가 투합되지 않는다. 3전이 체생(遞生)하여 재동(財動)으로 되니 봄철에는 재가 왕하여 수익이 크다. 폐구격은 병의 정단과 소송에서 가장 무섭다.
    백법에서는 ｢제3과․제4과에서 일간을 극하고 귀삼사(鬼三四)는 재액이 발생한다｣고 하였다. 수일(水日) 정재(丁財)를 만나서 움직임을 지배하고, 폐구격은 모두가 두 가지의 사건이다. 일귀는 역행하여 3전이 음․사․구이고, 야귀는 3전이 귀․합․공이다. 폐구격(閉口格)은 병을 점단하면 말을 하지 못하고 절식하여 죽는다.
  
  `
    ,
    // 5
    `  이 과는 4과 중 제1과․제2과․제3과에 상하의 극이 있고 하에서 상을 적하는 제3과를 사용하여 초전으로 하는 중심윤하격(重審潤下格)이다. 일간상신 미(未)는 일귀(日鬼)이나 공망하므로 두려울 것 없다. 말전은 일간의 귀묘(鬼墓)로 되므로 병을 점단(占斷)하면 어렵고, 소송에서는 깊은 걱정이 있다. 말전이 일지로 되는 것은 기다리는 사람이 속히 온다. 중심과는 무슨 일에서나 순조롭지 못하다. 4과3전이 전부 3합하고 자(子)와 미(未)는 육해(六害)로 되어 합하는 가운데 살을 띠므로 길한 가운데 흉이 있는 형상이다.
    백법에 이르기를 ｢육해로 보는 것은 의심이 서로 따르는 징조이다｣라고 하였다. 합지 중에 관살지가 있는 것은 꿀 속에 비소(砒素)가 있다. 즉 웃음 가운데 칼을 품는 것이다. 비전에서는 ｢일간상신에 미(未)의 명귀(明鬼)가 있고 자(子)는 둔간하여 무(戊)의 암귀(暗鬼)로 되니 명암의 두 귀가 있다. 명귀는 피하기 쉬우나 암귀는 방비하기 어렵다｣고 하였다. 묘월(卯月)에 정단하여 일귀(日鬼)에 사기(死氣)와 태상이 앉는 것은 밖에서 사상(死喪) 사건이 일어난다. 유(酉)에 귀신(貴神)이 임하면 여덕격(勵德格)으로, 군자(君子)에게는 영전하는 기쁨이 있고 하급 사람에게는 기쁨이 없다. 합(合)․후(后)가 있으므로 혼인은 자유 연애이다.
    일귀는 역행하여 3전이 합(合)․백(白)․후(后)이고 교동격(狡童格)이며, 야귀는 3전이 청(靑)․현(玄)․사(蛇)이다. 청(靑)은 변화하여 사(蛇)로 되니 공명과 구직은 흉하다.`
    ,
    // 6
    `  이 과는 4과 중 제3과의 하에서 상을 극하는 음양의 극이 있으며 양일이니 양비(陽比)의 극인 오해(午亥)를 사용하여 초전으로 하는 지일비용과(知一比用課)로서, 4과이나 3과밖에 없으므로 음의 불비격(不備格)이다.
    4과3전에 오(午)의 공망이 많고 말전만이 참된 것으로 된다. 일간기궁(日干寄宮)의 해(亥)는 일지상신에 가해져서 아래로부터 극된다. 즉 간은 존귀하고 지(支)는 비천하므로 지에 더하여져 극을 받는 것은 존귀하다고 자임할 수 없다. 따라서 부하로부터 모욕을 받는다. 오(午)는 일간의 재성이지만 공망하므로 구재(求財)는 얻기 어려우며, 3전은 체생(遞生)하나 공망하고 묘신이 승하여 남의 천거나 원조를 얻지 못한다.
    백법에서는 ｢일지상신이 건록으로 되면 권섭이 부정하고, 오(午)는 일간에서 극하는데 천장의 수신에서 극되는 것은 협극(夾剋)으로 된다. 그러므로 재는 자기의 자유를 얻지 못한다｣고 하였다. 비전에는 ｢천후가 해(亥)에 임하면 처가 음(淫)으로 흐른다｣고 하였다. 임(壬)에서 오(午)는 태신(胎神)으로 되고 또 협극을 받으므로 임신을 정단하면 태를 손상한다.
    일귀는 역전하여 3전이 현․주․백이고, 야귀는 3전이 후․구․현이다. 천장이 어느 것이나 흉장이 앉으니 정단(正斷)은 만사에서 신중을 기하여야 한다. 지일과(知一課)는 사물이 두 갈래에 걸치는데 오로지 하나로 귀결되어야 한다. 소송은 화해하는 것이 좋고, 만사의 모사(謀事)는 주모자에게 이익이 있다. 찾는 사람이나 분실물은 가까이에서 찾아야 하며, 먼 것을 버리고 가까운 것에 붙으며, 소원한 것을 버리고 친근한 것에 붙어야 한다. 하에서 상을 극하면 질투하는 사건이 있고, 상이 하를 극하면 혐의(嫌疑)받는 일이 일어난다. 불비격은 바른 일이 결여되기 때문이다.`
    ,
    // 7
    `  이 과는 천지칠충(天地七冲)하여 천지반(天地盤)을 이룬다. 수극화(水剋火)로 제1과의 극을 취하여 초전으로 하는 반음과로서, 초전이 순미(旬尾)의 사(巳)이므로 폐구격(閉口格)이며, 귀신(貴神)이 유(酉)에 임하면 여덕격(勵德格)이다.
    초․말전은 일간의 재로 되고 제2과․제3과의 술해(戌亥)는 둔간(遁干)하여 병정(丙丁)의 재성에 해당한다. 구재(求財)는 시종 풍요한 재를 얻는다. 과전이 전부 충극(冲剋)이므로 손상을 방비해야 한다. 귀신이 해(亥)에 임하는 것은 귀신이 천문에 올라 움직이면 좋고, 공사(公事) 신청 및 시험이나 선거에서는 일지상신에 관귀(官鬼)․청룡이 승하여 당선된다.
    반음과(返吟課)는 남을 이용하려다가 도리어 자기 스스로가 해를 입는다. 3전 사해사(巳亥巳)는 무슨 일에서나 개동(改動)을 주관한다. 구재 또는 문화 및 문장사는 가량하다. 백법에서는 ｢두 귀(兩貴)가 극을 받으면 귀를 얻기 어려우며 간지가 모두 손상되면 모두 손상된다｣고 하였다. 비전에는 ｢임(壬)이 사(巳)를 만나서 태재(胎財)로 되는데, 야간에 임신을 정단하면 사생아를 잉태한다｣라고 하였다. 태상(太常)이 미(未)에 앉으면 상복을 입을 사건이 일어난다. 3전이 호환하여 역마가 앉으면 출행 중에 길에서 헤매는 일이 있다. 정신․역마 재동은 먼 곳으로부터 송금이 있다. 일귀는 역행하여 3전이 음․구․음이고, 야귀는 순행하여 3전이 귀․공․귀이다.`
    ,
    // 8
    `  이 과는 제1과․제4과에 상하의 극이 있고 제4과의 하에서 상을 극하는 인유(寅酉)를 초전으로 하여 중심맥월격(重審驀越格)으로 된다. 4과이나 3과밖에 없으며, 2양1음의 불비격(不備格)으로 된다.
    일지(日支)는 일간상신에 가해져서 일간을 극한다. 따라서 남이 와서 나를 해하고 능욕을 받기 쉽다. 초전의 인(寅)은 진(辰)의 귀(鬼)를 제하지만 인(寅)은 유(酉)에서 극되어 힘이 약하고 또 제(制)도 경하다. 초전은 중전을 극하고 중전은 말전을 극한다. 즉 체극(遞剋)으로 된다. 그러나 말전이 공망하니 흉해는 해소되지 않는다.
    백법에서는 ｢중․말전의 공망은 전(傳)으로 돌지 않으므로 처음의 생각에 따라 초전의 일신(一神)만으로 길흉을 결정해야 한다｣고 하였다. 비전에 이르기를 ｢지(支)가 일간 상에 더해져서 일간을 극하는 것은 상문란수(上門亂首)라 부르며, 대흉의 암시다｣라고 하였다. 등산이나 해수욕 및 기타의 유희 운동은 재액을 초래한다. 가정․부부․어버이․자식 중 누군가의 신변에 한달을 지나지 않고 재액을 만나며, 또는 적난(賊難)을 만날 염려가 있다. 불비격은 무슨 일이라 할 것 없이 결함이 있고, 중․말전의 공망은 시작이 있고 끝이 없다.
    일귀는 역행하여 3전이 동․상․합이며, 야귀는 순행하여 3전이 현․주․백이다. 불비격은 2남이 1녀를 다투는 형상인데, 불비의 음양 결정은 제1과․제3과는 양과(陽課)이고 제2과․제4과는 음으로 한다. 같은 과가 있는 경우에는 양일에는 양을 두고 음을 취소하며, 음일에는 음을 두고 양을 제거한다. 이 과는 양일이니 제3과의 양을 두고 제2과의 음을 취소하므로 2양1음으로 되는 것이다. 불비격인 경우에는 이 원칙을 적용한다.`
    ,
    // 9
    `  이 과는 4과 중에서 제2과의 미진(未辰)이 하에서 상을 극하므로 중심곡직격(重審曲直格)으로 되며, 초전이 공망하여 과숙격(寡宿格)이다. 초전은 일간을 극해도 공망하므로 극의 힘이 없으며, 3전이 목(木)국이라도 공망하므로 썩은 나무는 그릇으로 되지 못한다. 일간일지가 모두 상신(上神)에 탈기하면 사람도 택도 소모가 있고, 일간일지가 교차육해(交車六害)로 되어 길사는 이루어지지 않는다. 탈기공망(脫氣空亡)하므로 우려는 풀기 쉬우며, 소송쟁의를 정단하면 처음은 곧지 못하나 후에 곧게 되는 것은 목국(木局)을 이루기 때문이다.
    백법에 이르기를 ｢수일(水日) 정재(丁財)는 재에 따라 움직이고, 인택이 모두 탈(脫)하는 것은 도난․분실의 염려가 있다｣고 하였다. 비전에 이르기를 ｢미(未)가 묘(卯)에 가해져서 초전을 이루면 가정에 동요가 생긴다｣고 하였다. 주작․구진이 승하니 소송 사건이 일어나고, 해(亥)가 천공에 승하면 반드시 사기 사건이다. 갑신일(甲申日)에 오미(午未)가 고허(孤虛)로 되니 도망을 점치면 잡을 수 없다. 태음(太陰)이 묘(卯)에 승하면 멀리 달아난다. 미(未)는 관성(官星), 해(亥)는 건록에 해당되지만 관록이 공망이므로 공명구직(功名求職)에서는 가장 불길하다. 일귀는 순행하여 3전이 구․상․귀이고, 야귀는 역행하여 3전이 주․공․음이다.
  `
    ,
    // 10
    `  이 과는 4과 중에 극이 없고 제2과․제4과에 사극(斜剋)이 있다. 천신(天神)에서 극하는 술미(戌未)를 초전으로 하여 호시요극과(蒿矢遙剋課)로 된다.
    일간일지는 교차상극하며 내면적으로는 불화 교쟁(交爭)이 있다. 3전이 모두 토(土)인 것은 가장격(稼檣格)으로서 일간을 극하므로 중귀(衆鬼)로 되어 흉은 중하다. 그러나 다행히 술진미(戌辰未)가 모두 공망하여 모해를 받아도 화환은 없다. 이 과는 조용히 때를 기다리는 것이 유익하고 망동(妄動)하면 이롭지 못하다. 백법에서는 ｢중귀가 완전하나 공망되고 또 인목(寅木)에서 제극하니 귀(鬼)도 두려울 것 없다｣고 하였다. 부부는 무음(蕪淫)에 홀러서 간사(奸私)를 행하고, 귀(鬼)가 제3과․제4과에 있으니 소송․관재가 서로 따른다. 비전에 이르기를 ｢호시격으로 일간상신에서 귀(鬼)를 제어하는 것은 흉이 변하여 기쁨을 만나고, 귀살이 공망되면 사람이 있어서 모해한다 해도 화를 받지 않는다｣고 하였다.
    일귀는 순포하여 3전이 백․음․사이고, 야귀는 3전이 청․상․후이다. 일간상신 인목(寅木)은 귀살을 제하는 구신이니 탈기로 되지 않는다. 신구(新舊)의 구별법은 양의 일간에 초전이 양인 것은 새것이고, 음이면 오래된 것이다. 이 과는 임(壬)이 양간으로 초전 술(戌)이 양지이므로 새로운 것이다. 또 음간이면서 초전이 양이면 옛 것이고 음이면 새것이다. 요극과(遙剋課)에서 두 곳에 극이 있는 것은 둘이면서 하나로 합친다. 3전 사계신(四季神)은 유자격(遊子格)으로 된다. 만일 천마가 승하면 먼 앞날을 바란다.`
    ,
    // 11
    `  이 과는 4과 중에서 세 곳이 상극하는데 신오(申午)를 사용하여 초전으로 하니 중심과(重審課)로 된다. 순간전격(順間傳格)으로서, 신술자(申戌子)로 음기에로 나아가니 섭삼연격(涉三淵格)으로 된다.
    중심과에서는 모두 후에 움직이는 것이 이롭다. 축오(丑午) 육해는 의심이 생긴다. 일지상신이 재(財)로 되지만 공재(空財)로서 기력이 없으며, 또 초전이 신(申)의 장생이나 공지에 앉으니 무슨 일이나 기대하기 어렵다. 신(申)은 순수(旬首)이니 육의(六儀)로 되어 흉도 변화되어 길조로 된다. 일간상신 축(丑)에 태상(太常)이 승하여 일간을 극하면 신변에 상사(喪事)가 발생한다.
    백법에는 ｢백호․둔귀가 앉는 것은 재앙을 만나면 재화가 중하다｣고 하였다. 비전에는 ｢천지반에서 인(寅) 위에 진(辰)이 앉으면 강색귀호(罡塞鬼戶)라 하여 도피 또는 음사(陰私)의 일에서 좋고, 초전에 육의(六儀)를 보면 병이 치료되며, 교도소에서 복역하는 자는 출옥의 기쁨을 만난다｣고 하였다. 신(申)에 육합이 승하면 의(醫)의 류신(類神)이다. 그러나 공망이되면 종교인으로 된다. 일귀는 순행하여 3전이 청(靑)․백(白)․현(玄)이고, 야귀는 3전이 합(合)․청(靑)․백(白)이다.`
    ,
    // 12
    `  이 과는 4과 중에서 제2과가 축자(丑子)로 상이 하를 극하여 원수과(元首課)로 되며 3전은 축인묘(丑寅卯)로 진여격(進茹格)이다.
    일간상신의 자(子)는 일간에 왕하다. 그리하여 초전이 관귀라 할지라도 중․말전의 인묘(寅卯)의 목(木)이 토(土)를 제어하므로 화해(禍害)를 받지 않는다. 그러나 4과3전에서 앞의 지(支)가 모두 천신으로 되면 나망격(羅網格)이 되므로 길하다 할지라도 모사(謀事)에서는 망동을 삼가야 한다. 축(丑)과 미(未)는 전택신으로서 구진이 승하면 토지나 가옥 등의 분쟁의 징조가 있다. 축인묘(丑寅卯)의 진여격(進茹格)은 장태(將泰)라 하여 명성이 있으나 실현을 보지 못하며, 공적을 올리는 사업도 또한 성취되지 못한다. 무릇 4과에 극이 많으면 번잡하게 되지만 이와 같이 극이 한 자리이면 주요한 사건이 하나로서 번잡하지는 않다. 그러나 교차상극(交車相剋)이므로 교섭사 상담에서 다소 파란이 생긴다.
    육임(六壬)의 원칙에 따르면 점시(占時)로써 래정(來情)을 점치는데 응험되지 않는 것이 없다. 시(時)의 공망은 침해․사취(詐取)하는 사건이 있고, 시(時)의 역마는 반드시 움직임을 주관하여 천이(遷移)하는 일이 있다. 시(時)에서 일(日)을 충하고 지(支)를 충하면 피차에 모두 유리전패(流離顚沛)의 괴로움을 초래하며, 시(時)와 일(日)이 같거나 또는 일지(日支)와 같으면 사물이 지체된다. 시(時)와 일(日)의 상생은 은혜를 받고, 시(時)와 일(日)의 극해는 서로 원수가 된다. 일(日)에서 시(時)를 극하면 재물에 관한 사건이며, 시(時)에서 일(日)을 극하면 귀살(鬼殺)로 본다. 시(時)에서 보아 일(日)이 파(破)로 됨은 실종 사건이고, 시(時)에서 지(支)를 형(刑)하면 관재(官災)가 있다. 시지(時支)와 일지(日支)의 상합은 결혼의 기쁨이 있으며, 일간일지가 모두 시(時)와 합하면 내외에 화합하는 기쁨이 있다. 시(時)에서 월장(月將)은 충형(冲刑)하는 것은 재앙이 오니 예방해야 한다.
    백법에 이르기를 ｢모소(謀所)에서 졸책(拙策)을 이루는 것은 나망(羅網)을 만난다｣라고 하였다. 비전에 이르기를 ｢자축(子丑)이 서로 가해지는 것은 사물이 반드시 성취되며, 길장을 띠면 기쁨으로 전화된다｣라고 하였다. 일귀는 순행하여 3전이 음․후․귀이고, 야귀는 3전이 상․현․음이다. 축(丑)에 태상이 승한 것은 어버이가 있다면 상사(喪事)가 일어난다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
