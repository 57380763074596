const columnA = [
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`01`, `좌`],
  [`23:32`, `04 `],
  [`01:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`02`, `좌`],
  [`01:32`, `09`],
  [`03:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`03`, `좌`],
  [`03:32`, `06`],
  [`05:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`04`, `좌`],
  [`05:32`, `11`],
  [`07:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`05`, `좌`],
  [`07:32`, `08`],
  [`09:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`06`, `좌`],
  [`09:32`, `01`],
  [`11:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`07`, `좌`],
  [`11:32`, `10`],
  [`13:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`08`, `좌`],
  [`13:32`, `03`],
  [`15:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`09`, `좌`],
  [`15:32`, `12`],
  [`17:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`10`, `좌`],
  [`17:32`, `05`],
  [`19:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`11`, `좌`],
  [`19:32`, `02`],
  [`21:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`12`, `좌`],
  [`21:32`, `07`],
  [`23:31`, `내용 해당`],
  [``, ``],
];
const columnD = [
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [
    `1.2월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `궤후`,
    `천부적좌고우면신중성자`,
    `설득`,
    7,
    11,
    `없음`,
    `01.21\n~
  02.19`,
    `보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,
    `조용\n담담
  
  성교후곧잠`,
  ],
  [
    `4.5월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `단절자`,
    `단斷`,
    `고립적`,
    `1 4\n7 10`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 4\n7 10`,
    `아둔`,
    `천부적중정신암심자`,
    `신뢰`,
    8,
    10,
    `없음`,
    `02.20\n~
  03.20`,
    `굵음\n적극적 매너
  
  강력한 성교 함`,
    `문을 열면 적극적이 됨\n
  감격을 숨기지않음`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `인자`,
    `천부적진취창의성자`,
    `고집`,
    9,
    9,
    `절반`,
    `03.21\n~
  04.20`,
    `단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,
    `주도적\n적극적
  
  완벽
  주의자 임`,
  ],
  [
    `4.5월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `인자`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 3\n7 10`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `인자`,
    `천부적좌고우면신중성자`,
    `설득`,
    10,
    8,
    `있음`,
    `04.21\n~
  05.20`,
    `때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,
    `정숙\n하고 
  억제심 강함
  
  분비물 많음`,
  ],
  [
    `4.5월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `인자`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 3\n7 10`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 14\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `설득`,
    11,
    7,
    `없음`,
    `05.21\n~
  06.20`,
    `기교파 반응 감상 타입\n
  크고, 즐김`,
    `위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴褪`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `예의`,
    `천부적진취창의성자`,
    `위선`,
    12,
    6,
    `없음`,
    `06.21\n~
  07.20`,
    `기교가 세밀함`,
    `분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`,
  ],
  [
    `4.5월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `예의`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `10후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `14\n710`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `예의`,
    `천부적좌고우면신중성자`,
    `고집`,
    1,
    5,
    `없음`,
    `07.21\n~
  08.20`,
    `정력파 女만족시키려 함\n
  쉽게 지침`,
    `적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`,
  ],
  [
    `4.5월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `예의`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `10후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `퇴세자`,
    `퇴褪`,
    `퇴휴적`,
    `14\n710`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 14\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `설득`,
    2,
    4,
    `없음`,
    `08.21\n~
  09.20`,
    `헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,
    `부끄러움 많이 탐\n
  성기는 명물임`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `포살`,
    `천부적진취창의성자`,
    `고집`,
    3,
    3,
    `없음`,
    `09.21\n~
  10.20`,
    `조루증 많음\n
  음경은 작은 편`,
    `음모 많음\n
  수축 형이고 능란함`,
  ],
  [
    `4.5월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `3 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `포살`,
    `천부적좌고우면신중성자`,
    `위선`,
    4,
    2,
    `없음`,
    `10.21\n~
  11.20`,
    `보통이상 크기\n
  극적. 본능에 그침`,
    `은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`,
  ],
  [
    `4.5월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `3 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `종교재난`,
    `1 2\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `고집`,
    5,
    1,
    `없음`,
    `11.21\n~
  12.20`,
    `통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,
    `적극적 \n상위 취함`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `종교발전`,
    `2 4\n5 7`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `종교탄핵`,
    `1 2\n4 5`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `종교한산`,
    `2 4\n5 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `종교대성`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `퇴세자`,
    `퇴退`,
    `활인탄핵`,
    `10후\n11후`,
    `궤후`,
    `천부적진취창의성자`,
    `고집`,
    6,
    12,
    `없음`,
    `12.21\n~
  01.20`,
    `보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,
    `적극적 \n상위 취함`,
  ],
  [
    `4.5월`,
    `소외자`,
    `소疏`,
    `활인한산`,
    `5 6\n7`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `진세자`,
    `진進`,
    `활인발전`,
    `2 후\n3 후`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `왕세자`,
    `성盛`,
    `활인대성`,
    `현재`,
    `지헤`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `단절자`,
    `단斷`,
    `활인재난`,
    `1 4\n7 10`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
];

const columnS = [
  [``, ``, ``, ``, ``, ``],
  [`관련`, `속사정`, ``, ``, `外業신변男上`, `內事가정女下`],
  [``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``],
  [`금전`, `금전사`, `小`, ``, ``, ``],
  [`사업`, `사업관련사`, `小`, `7`, `색정음사`, `門訟連連`],
  [`색정`, `외남 색정사 有`, `大`, `단절기`, `손해도주`, `감옥관련`],
  [`중중재희(重重財喜)`, `금전사가 크고 많은 기쁨의 상`, `大`, ``, ``, ``],
  [
    `이어경영(利於經營)`,
    `고위에 올라 널리 이익되게 하는 방침을 포고하는 상`,
    `小`,
    ``,
    ``,
    ``,
  ],
  [`동요`, `外 충산동요불안사`, `小`, ``, ``, ``],
  [`자매`, `자매 관련사`, `大`, `8`, ``, ``],
  [`친구`, `친구 관련사`, `小`, `침체기`, `동요분산`, `개신의향`],
  [
    `귀앙피망(鬼殃破亡)`,
    `침해(鬼煞)를 받아 일이 깨지고 망해가는 상`,
    `小`,
    ``,
    `말 말고`,
    `이익경영`,
  ],
  [`사장성취(事張成就)`, `일 마다 이루어지는 상`, `大`, ``, `근신필요`, ``],
  [`관사`, `관사 관련사`, `大`, ``, ``, ``],
  [`급속`, `가정 대립반목급속송쟁사`, `小`, `9`, `사관牌印`, `반목대립`],
  [`남편`, `남편 관련사`, `小`, `소외기`, `생업침체`, `가정송쟁`],
  [
    `교장양공(巧匠良工)`,
    `교묘한 실력의 장인(匠人) 관련의 상`,
    `大`,
    ``,
    `평민 불리`,
    `급속사有`,
  ],
  [`공사구설(公私口舌)`, `공공사사 구설의 상`, `小`, ``, ``, ``],
  [`관사`, `관사 관련사`, `大`, ``, ``, ``],
  [`직업`, `직업 침해사`, `小`, `10`, ``, ``],
  [
    `결속`,
    `음12월 결속해 권리사 완성함-단, 본인病 형제災害우려`,
    `小`,
    `침해기`,
    `다사단합`,
    `타인조력`,
  ],
  [
    `상처불혼(傷妻不婚)`,
    `女, 財物 상실-혼인 불성사`,
    `大`,
    ``,
    `사사성취`,
    `禍消福至`,
  ],
  [
    `사사성취(事事成就)`,
    `일 마다 이루어지는 상-남녀 合室관계`,
    `小`,
    ``,
    ``,
    ``,
  ],
  [`형제`, `형제관련손모사`, `小`, ``, ``, ``],
  [`친구`, `친구관련사`, `大`, `11`, `재앙소멸`, `근심해소`],
  [`동료`, `동료관련사`, `小`, `쇠퇴기`, `출비소모`, `타인시혜`],
  [
    `호함해산(虎陷解散)`,
    `위기를 초래할 함정이 있으나  다행이 해소되는 상`,
    `小`,
    ``,
    ``,
    ``,
  ],
  [
    `음사시리(陰事是利)`,
    `간음사가 이익인데 정대적이면 되려 재앙이 되는 상`,
    `大`,
    ``,
    ``,
    ``,
  ],
  [`후원`, `후원사`, `大`, ``, `출행이동`, ``],
  [`문서`, `문서사`, `大`, `12`, `송구경황`, ``],
  [`출행`, `외사 출행이동사`, `小`, `과시기`, `사정상대`, `가정막힘`],
  [`앙화소멸(殃禍消滅)`, `재앙이 소멸된 상`, `小`, ``, `두려운人`, `逢凶化吉`],
  [
    `재화반창(災禍反昌)`,
    `재난이 해소되고 번창하게 되는 상`,
    `小`,
    ``,
    `임을앎`,
    ``,
  ],
  [`후원`, `후원관련사`, `大`, ``, ``, ``],
  [`색정`, `內女색정사 有`, `大`, `1`, `근심해산`, `문서유실`],
  [`문서`, `문서관련사`, `小`, `절정기`, `상하화합`, `가정손비`],
  [
    `파모주노(破謀走墟)`,
    `계획사가 파하고 언덕길을 오르는 것같이 힘든 상`,
    `小`,
    ``,
    ``,
    ``,
  ],
  [`재앙소멸(災殃消滅)`, `재앙이 소멸된 상`, `小`, ``, ``, ``],
  [`자매`, `자매 관련사`, `小`, ``, ``, ``],
  [`친구`, `친구소모사`, `大`, `2`, `침체공허`, ``],
  [
    `결속`,
    `개신개혁으로 同志사를 완성함-단 명예직위남편훼절됨`,
    `小`,
    `면모기`,
    `외교막힘`,
    `간음사有`,
  ],
  [
    `간음시리(姦淫是利)`,
    `간음사가 이익인데 정대적이면 되려 재앙이 되는 상`,
    `小`,
    ``,
    `봉흉화길`,
    ``,
  ],
  [
    `고위무력(高位無力)`,
    `고위인이 힘을 못 써주는 상`,
    `大`,
    ``,
    `양도불성`,
    ``,
  ],
  [`박관`, `직업직위불안사- 급속사`, `小`, ``, ``, ``],
  [`극부`, `남편갈등사`, `小`, `3`, `외사급속`, `애중화합`],
  [`자식`, `자식문제사`, `大`, `학습기`, `타인시혜`, `반목송쟁`],
  [
    `구연반복(句連反覆)`,
    `문 열고 닫는 일 등 단순한 일의 반복사`,
    `小`,
    ``,
    `구연반복`,
    `파재극처`,
  ],
  [
    `차위차질(此謂差跌)`,
    `이것에 대하여 차질이라고 할 수 있는 상`,
    `大`,
    ``,
    ``,
    ``,
  ],
  [`박관`, `직업직위 불안사`, `小`, ``, ``, ``],
  [`극부`, `남편 갈등사`, `小`, `4`, ``, `개신의향`],
  [`여식`, `혼인관련사`, `大`, `개시기`, `간음무도`, `莫入당도`],
  [
    `비읍입문(悲泣臨門)`,
    `슬피 울 일 당도한 상`,
    `小`,
    ``,
    `간음무도`,
    `逢林有蛇`,
  ],
  [`작난포획(적난포획)`, `도적을 잡지 못한 상`, `大`, ``, ``, ``],
  [`송쟁`, `외사 급속송쟁사-실종상해사 우려`, `小`, ``, ``, ``],
  [
    `매사 허성불성`,
    `음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,
    `大`,
    `5`,
    ``,
    ``,
  ],
  [
    `매사 영속성없음`,
    `형제자매친구동료동업금전손실사는 선천적 기복재해를 겪음`,
    `小`,
    `배양기`,
    `반목대립`,
    `허망허사`,
  ],
  [
    `도망실주(逃亡失走)`,
    `자리를 옮기겠으나 도망사에 주의해야 할 상`,
    `小`,
    ``,
    `途中청원`,
    `경벌송사`,
  ],
  [
    `도중청알(途中請謁)`,
    `길 가는 고위인에게 청원함이 좋은 상`,
    `大`,
    ``,
    ``,
    ``,
  ],
  [`금전`, `금전상업여인관련 출행이동사 `, `小`, ``, ``, ``],
  [
    `매사 허성불성`,
    `음7,8,10,11월 방문자는 종교진출하면 성공함`,
    `小`,
    `6`,
    `逢林有蛇`,
    `主事皆凶`,
  ],
  [
    `매사 영속성없음`,
    `금전상업처첩이권사는 선천적 기복재해를 겪음`,
    `大`,
    `존재기`,
    `타인압박`,
    `동요분산`,
  ],
  [
    `험중득의(險中得意)`,
    `험준한 가운데 뜻을 얻게 되는 상`,
    `大`,
    ``,
    `허성허사`,
    `불안`,
  ],
  [
    `주사계흉(主事皆凶)`,
    `주력사에 관련하여 모두 나쁜 것일 뿐인 상`,
    `小`,
    ``,
    ``,
    ``,
  ],
];
const columnZ = [
  [``, ``, 0, ``, ``],
  [`단계`, `단계`, 0, `가능`, `불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明시작`, ``, 0, `금전사업여인관계로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明종료`, ``, 0, `자매친구동료의뢰로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明진행`, ``, 0, ` `, `관사직업남편문제로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, ``, `관사직업남자문제로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明하향`, ``, 0, `형제친구동료의뢰로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗절정`, 0, `부모문서후원으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗시작`, 0, `직위녹위문서후원으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗종료`, 0, `자매친구동료의뢰로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, ``, `직업지위불안부부갈등자식문제로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明진행`, ``, 0, ``, `직업직위불안남자갈등여식문제로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, ``, `세속허사자로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明절정`, ``, 0, ``, `세속허사자로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
];
const columnAF = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
];
const columnAS = [
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [2],
  [0],
  [0],
  [0],
  [0],
  [3],
  [0],
  [0],
  [0],
  [0],
  [4],
  [0],
  [0],
  [0],
  [0],
  [5],
  [0],
  [0],
  [0],
  [0],
  [6],
  [0],
  [0],
  [0],
  [0],
  [7],
  [0],
  [0],
  [0],
  [0],
  [8],
  [0],
  [0],
  [0],
  [0],
  [9],
  [0],
  [0],
  [0],
  [0],
  [10],
  [0],
  [0],
  [0],
  [0],
  [11],
  [0],
  [0],
  [0],
  [0],
  [12],
  [0],
  [0],
  [0],
  [0],
  [1],
];
const columnAU = [
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `강건정당속결**만약 타인과 다툰다면  먼저 나서서 서둘러 종결해야만 유리함`,
    0,
    0,
    `명분이 있으므로 정당하게 속결해야만 유리하고 좌고우면하거나 뒤로 미루면 결과가 되려  불리해지는 人`,
    0,
    0,
    `전화위복적인 기이한 해결이 됨`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 휴화적 처신으로 연기 지체시켜야만 결과가 유리함`,
    0,
    0,
    `역상적 사안이므로 억울하더라도 절대 반발하지말고 순응하여 사안을 지체 연기시키면 결과가 유리해지는 人`,
    0,
    0,
    `일이 띄엄띄엄 진행됨`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `호가호위세**만약 타인과 다툰다면 피아간 전의가 별로 없고 손상도 없으며 있더라도 가벼움`,
    0,
    0,
    `올지못한 일이 밝혀져 천둥에 놀란 듯하나 근신하면 근심걱정이 자연 해소되는 人`,
    0,
    0,
    `신규사가 있고 고집이 있음**결과없이 반복되더라도 계속 진행하면 결말이 있게 됨`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `강건정당속결**만약 타인과 다툰다면  먼저 나서서 정당하게 서둘러 종결해야만 유리함`,
    0,
    0,
    `명분이 있으므로 급속 선수적 종결이 유리하고 좌고우면하거나 사안을 보류시키면 되려 불리해지는 人`,
    0,
    0,
    `복잡다단의 사안을 하나로 묶는 격**남자가 남의 부인을 유혹하여 자기 여자처럼 사용함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `간난험악고초**만약 타인과 다툰다면 당장은 근심걱정을 풀기어려우니 신중해야 하고 후에 행동에 나서는 것이 현명하며 방심하지 말아야 함`,
    0,
    0,
    `풍상 고초 후 성공할 人`,
    0,
    0,
    `현재의 계획고 방침을 급히 변경시킬 것**의지거처가 곤란함**재앙이 업드려 있음`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `동요분산불안배신**만약 타인과 다툼이 있다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오니 신중해야 함`,
    0,
    0,
    `매사 반복무상하여 번민하는 人`,
    0,
    0,
    `신규사가 있고 고집이 있음**일을 직접적으로 하지 말고 타인을 앞세워 도모해야 함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,
    0,
    0,
    `저주 소송의 조짐이 있는 人`,
    0,
    0,
    `종적없이 어디로 숨고싶은 심정**일이 반복되기만 하고 결과가 없음**권리행사에 도장을 새길 칼이 없는 격`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `간난험악고초**만약 타인과 다툰다면 당장은 근심걱정을 풀기어려우니 신중해야 하고 후에 행동에 나서는 것이 현명하며 방심하지 말아야 함`,
    0,
    0,
    `풍상 고초 후 성공할 人`,
    0,
    0,
    `현재의 계획과 방침을 급히 병경시켜야 함**남자가 타인의 부인을 유혹하여 본인의 여자로 사용함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유화적 처신으로 지체 연기시켜야만 결과가 유리함`,
    0,
    0,
    `역상적 사안이므로 억울하더라도 절대 반발하지말고 순응하며 사안을 지체 연기시키면 결과가 반드시 유리해지는 人`,
    0,
    0,
    `신규사가 있고 고집이 있음**일이 결과없이 반복되기만 함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `호가호위세**만약 타인과 다툰다면 피아간 전의가 별로 없고 손상도 없으며 있더라도 가벼움`,
    0,
    0,
    `위세를 부려 상대를 공격해도 적중되지 못하고 허사무용하므로 중지하여야 할 人`,
    0,
    0,
    `일이 띄엄띄엄 진행됨**후퇴할 상황이나 되려 진행하게 됨**독신 고독 갇방 별리 사별 주말부부 격`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `진퇴양난은닉**만약 타인과 다툰다면 타인은 지금 속고 있거나 모르고 있으므로 급히 마무리를 해야 함`,
    0,
    0,
    `범인이거나 죄인을 감추고 있는 人`,
    0,
    0,
    `겨울에 굴을 못찾은 뱀 같은 처지**숙명적 신세`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `부동신음**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지 않음`,
    0,
    0,
    `자기를 유약하게 여기며 나아가지 못하고 있는 人**신체가 자유롭지 못한 경우가 있음`,
    0,
    0,
    `관재송사가 있음**신규사가 있고 고집이 있음`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
];
const columnBE = [
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이익소모인`,
    `타발동급속사`,
    `기사`,
    `구사`,
    `내사`,
    `이면사`,
    `배신`,
    `악성`,
    `허위`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `피해인`,
    `타발동아발동후회사`,
    `기사`,
    `구사`,
    `아 외타 내동시사`,
    `아 이면 타표면사`,
    `좌고우면`,
    `선성`,
    `허위`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `피해인`,
    `타발동사`,
    `기사`,
    `신사`,
    `내사`,
    `표면사`,
    `배신`,
    `보성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이익손모인`,
    `아발동사`,
    `기사`,
    `신구중간사`,
    `외사`,
    `표면사`,
    `충성`,
    `악성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `아발동후회사`,
    `미사`,
    `신구중간사`,
    `외사`,
    `이면사`,
    `좌고우면`,
    `선성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `타발동급속사`,
    `기사`,
    `신사`,
    `내사`,
    `이면사`,
    `배신`,
    `보성`,
    `허위`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `아발동후회사`,
    `기사`,
    `신사`,
    `외사`,
    `이면사`,
    `충성`,
    `악성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `타발동사`,
    `미사`,
    `신구중간사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선겅`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동사`,
    `미사`,
    `신사`,
    `내사`,
    `표면사`,
    `배신`,
    `보성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이익손모인`,
    `아발동후화사`,
    `미사`,
    `신사`,
    `외사`,
    `이면사`,
    `충성`,
    `악성`,
    `허위`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `아 권리가져갈 인`,
    `타발동사`,
    `미사`,
    `신사`,
    `외사`,
    `표면사`,
    `좌고우면`,
    `선성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이익인`,
    `타발동급속사`,
    `기사`,
    `신사`,
    `내사`,
    `표면 이면 동시사`,
    `배신`,
    `보성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
];
const columnBO = [
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `흥`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-이전이동有`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-급속강제손실사有`, `쇠-색정사有`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠-공허`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠-공허`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-공허`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-공허`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `흥-색정사有`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `흥`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
];
const columnBS = [
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`승  `, `승`, ` 승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [`승`, `승`, `승`],
  [` 화해됨`, `화해 됨`, `화해됨`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [` 무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`화해됨`, `화해 됨`, `화해됨`],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [` 무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [` 비김`, `비김`, ` 비김`],
  [``, ``, ``],
  [``, ``, ``],
];
const columnBV = [
  // [0,0,0,``,0,0,0,``],
  // [용모,가문,合6기준,``,용모,가문,合6기준,``],
  // [0,0,0,``,0,0,0,``],
  [3, 3, 6, `음2월`, 1, 2, 3, `음1월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 2, 5, `음2월`, 3, 2, 5, `음력12월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 3, 6, `음3월-연기우려`, 1, 1, 2, `음9월-연기 또는 파혼 우려`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 1, 4, `음2월-연기되거나 파혼 우려`, 2, 1, 3, `음8월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음1월`, 3, 3, 6, `음7월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 3, 6, `음12월`, 1, 1, 2, `음6월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 3, 6, `음11월`, 1, 1, 2, `음5월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음10월-연기되거나 파혼 우려`, 2, 3, 5, `음4월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 2, 3, `음3월`, 1, 1, 2, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음6월`, 2, 2, 5, `음4월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 2, 4, `음5월`, 2, 2, 4, `음3월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 3, 5, `음8월`, 3, 3, 6, `음2월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
];
const columnCE = [
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인품이 높아 관공직 분야는 업무에 행운이 따르고 권위를 세우고 실적도 쌓게 되나 일반 단체의 경우는 피해와 증오를 남김 `,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`고집이 세고 업무에 실패함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `언어에 불성실함이 있고 행위도 같으며 타력을 끓어들여 초반에 피해를 초래하고 이후 업무실적이 공허하게 됨`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`사교성으로 동료의 원조를 받으나 실적을 기대할 수 없음`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `사교성으로 초반에는 문제를 해결하고 중반에는 재정을 확충하나 종반에는 공허하게 됨 `,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`고집이 세고 임기 내내 무실적이 됨`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`고집이 세고 초반에는 두각을 나타내지만 이후 기대가 안 됨`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `사교성이 좋아 동료의 원조를 받아 개혁을 하지만 결국 피해만 끼치게 됨`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `둔한 것 같지만 상대를 잘 조정하는 능력이 있으나 배경이 없어 업무에 실패하게 됨`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인정미는 있어도 사심이 있어 초반부터 종반까지 실적없이 피해만 끼침.`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인격이 높으나 업무로 망신을 초래하며 권리를 넘기는 등 손실을 끼침`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `사교성이 있고 동료의 지원을 받으나  결국 내부대립반목을 초래함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
];
const columnCL = [
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, `낮`, `밤`],
  [
    `1.2월`,
    `다집多集`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n선택적`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
  ],
  [
    `4.5월`,
    ``,
    `반대有`,
    `약속위배`,
    `선費후助`,
    `退    背`,
    `  원`,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    ``,
    `본인제압`,
    ``,
    `포기요망`,
    `매사역조`,
    ``,
    `留`,
    `세입피로`,
    `남남서`,
    ``,
    `정동`,
  ],
  [`10.11`, `집集`, ``, ``, ``, ``, ``, ``, `설기쇠약`, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `집集`,
    `반대有`,
    `이행`,
    `선난후이`,
    `逆進逆向`,
    `  원`,
    `留`,
    `주인후원`,
    `서남`,
    ``,
    `동남`,
  ],
  [
    `7.8월`,
    ``,
    `발현안됨`,
    ``,
    `천천히`,
    `초반주력`,
    ``,
    ``,
    `주인이사`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, ``, ``, ``, `이후퇴배`, ``, ``, `할 뜻有`, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대有`,
    `이행`,
    `선난후난`,
    `退    背`,
    `  근`,
    `留`,
    `주인후원`,
    `정서`,
    ``,
    `남남동`,
  ],
  [`7.8월`, ``, `내부발현`, ``, `포기요망`, ``, ``, ``, `주인세입`, ``, ``, ``],
  [`10.11`, `집集`, ``, ``, ``, ``, ``, ``, `반목대립`, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대극성`,
    `이행`,
    `선費후押`,
    `退    背`,
    `  원`,
    `이전이사\n`,
    `세입피로`,
    `동남`,
    `정남`,
    ``,
  ],
  [
    `7.8월`,
    ``,
    `본인발현`,
    ``,
    `후압박好`,
    `침해극성`,
    ``,
    `이미결정`,
    `설기쇠약`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, `집集`, `기력없음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, `반대有`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `발현안됨`,
    `이행`,
    `선押후이`,
    `退    背`,
    `  원`,
    `去`,
    `주인압박`,
    `남남동`,
    `동북`,
    ``,
  ],
  [
    `7.8월`,
    `다집多集`,
    `측근모사`,
    ``,
    `천천히`,
    `하극상`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, `이면측근`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `집集`, `제압`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `다집多集`,
    `반대없음`,
    `이행`,
    `안건상대`,
    `退    背`,
    `원근왕래`,
    `留`,
    `주인무력`,
    `정남`,
    `정남`,
    ``,
  ],
  [`7.8월`, ``, ``, ``, `무력허사`, `허사동요`, ``, ``, ``, ``, ``, ``],
  [`10.11`, ``, ``, ``, `포기요망`, `분산불안`, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `다집多集`,
    `반대有`,
    `이행`,
    `상대안건`,
    `退    背`,
    `  근`,
    `留`,
    `주인무력`,
    `북북동`,
    `정서`,
    ``,
  ],
  [`7.8월`, ``, `상대측근`, ``, `무력허사`, `권리행사`, ``, ``, ``, ``, ``, ``],
  [`10.11`, ``, `기력없음`, ``, `포기요망`, `결함`, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대없음`,
    `이행`,
    `본인무력`,
    `退    背`,
    `  원`,
    `去`,
    `주인세입`,
    `서남`,
    `남남동`,
  ],
  [
    `7.8월`,
    `다집多集`,
    `반대기미`,
    ``,
    `포기요망`,
    `역량무력`,
    ``,
    ``,
    `결속`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, `측근에서`, ``, ``, `기력손실`, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `집集`, `발현안됨`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대有`,
    `이행`,
    `본인무력`,
    `退    背`,
    `  근`,
    `去`,
    `주인세입`,
    `정서`,
    `정남`,
    ``,
  ],
  [
    `7.8월`,
    `다집多集`,
    `기력없음`,
    ``,
    `포기요망`,
    `기력손실`,
    ``,
    ``,
    `애증화합`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대有`,
    `이행`,
    `선費후이`,
    `退    背`,
    `원근왕래`,
    `去`,
    `세입피로`,
    `동남`,
    ``,
    `동남`,
  ],
  [
    `7.8월`,
    ``,
    `기력없음`,
    ``,
    `천천히`,
    `결국침해`,
    ``,
    ``,
    `설기쇠약`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, `음10월集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대없음`,
    `약속위배`,
    `선이후난`,
    `退    背`,
    `  근`,
    `去`,
    `세입피로`,
    `남남동`,
    `남`,
    `남남동`,
  ],
  [
    `7.8월`,
    `다집多集`,
    ``,
    ``,
    `빠르게`,
    `권리양도`,
    ``,
    ``,
    `권리양도`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, ``, ``, ``, `됨`, ``, ``, `주의요함`, ``, ``, ``],
  [`3.6\n9.12`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `다집多集`,
    `반대有`,
    `약속위배`,
    `선이후이`,
    `進    向`,
    `원근왕래`,
    `去`,
    `무난`,
    `정남`,
    ``,
    `동북`,
  ],
  [`7.8월`, ``, `내부제압`, ``, `느긋히`, `송쟁감수`, ``, ``, ``, ``, ``, ``],
  [`10.11`, ``, `소송발현`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, `결국패함`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
];
const columnCY = [
  [``, ``, ``, ``, ``, ``, 0, ``],
  [``, ``, ``, ``, ``, ``, 0, ``],
  [``, ``, ``, ``, ``, ``, 0, ``],
  [`질병`, `가장귀`, `  夫 `, ``, ``, `묘좌`, "동남", `없음`],
  [``, `원한귀`, `불혼사망`, ``, ``, `묘향`, "북서", ``],
  [`생업`, ``, ``, ``, ``, `전경`, "습지", ``],
  [``, ``, ``, ``, ``, `묘속`, "전복", ``],
  [`가정`, ``, ``, `신사`, `聖貌훼손`, `육친`, "모친", ``],
  [`질병`, ``, ``, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, ``, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, `가장귀`, `  夫`, ``, ``, `육친`, 0, ``],
  [`질병`, `원한귀`, `불혼사망`, ``, ``, `묘좌`, 0, `특별있음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, `天神귀`, `의식불경`, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, `원한귀`, `불혼사망`, ``, ``, `육친`, 0, ``],
  [`질병`, `자상귀`, `목맨자살`, ``, ``, `묘좌`, 0, `있음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, `상가부정`, `상가조문`, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, ``, `육친`, 0, ``],
  [`질병`, ``, ``, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, `음귀`, `산소탈`, ``, ``, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, ``, `육친`, 0, ``],
  [`질병`, ``, ``, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, ``, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, `음사부정`, `제사잘못`, `육친`, 0, ``],
  [`질병`, ` 수귀`, `익사사망`, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, ``, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, `저주부정`, `저주구설`, `육친`, 0, ``],
  [`질병`, ``, ``, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, `음사부정`, `제사잘못`, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, ``, `육친`, 0, ``],
  [`질병`, `음귀`, `산소탈`, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, `원한저주`, `저주구설`, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, ``, `육친`, 0, ``],
  [`질병`, `가장귀`, `  夫`, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, ``, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, `음사부정`, `제사잘못`, `육친`, 0, ``],
  [`질병`, ``, ``, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, ``, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, ``, `육친`, 0, ``],
  [`질병`, `원한귀`, `불혼사망`, ``, ``, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, ``, `묘향`, 0, ``],
  [`생업`, ``, ``, `음사부정`, `제사잘못`, `전경`, 0, ``],
  [``, ``, ``, ``, ``, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, ``, `육친`, 0, ``],
];
const columnDH = [
  [
    `출생연도`,
    `24`,
    `96`,
    `25`,
    `97`,
    `26`,
    `98`,
    `27`,
    `99`,
    `28`,
    `00`,
    `29`,
    ``,
    `30`,
    `102`,
    `19`,
    `91`,
    `20`,
    `92`,
    `21`,
    `93`,
    `22`,
    `94`,
    `23`,
    `95`,
  ],
  [
    0,
    `36`,
    ``,
    `37`,
    ``,
    `38`,
    ``,
    `39`,
    ``,
    `40`,
    ``,
    `41`,
    ``,
    `42`,
    ``,
    `31`,
    ``,
    `32`,
    ``,
    `33`,
    ``,
    `34`,
    ``,
    `35`,
    ``,
  ],
  [
    0,
    `48`,
    ``,
    `49`,
    ``,
    `50`,
    ``,
    `51`,
    ``,
    `52`,
    ``,
    `53`,
    ``,
    `54`,
    ``,
    `43`,
    ``,
    `44`,
    ``,
    `45`,
    ``,
    `46`,
    ``,
    `47`,
    ``,
  ],
  [
    0,
    `60`,
    ``,
    `61`,
    ``,
    `62`,
    ``,
    `63`,
    ``,
    `64`,
    ``,
    `65`,
    `01`,
    `66`,
    `02`,
    `55`,
    `03`,
    `56`,
    `04`,
    `57`,
    `05`,
    `58`,
    ``,
    `59`,
    ``,
  ],
  [
    0,
    `72`,
    `08`,
    `73`,
    `09`,
    `74`,
    `10`,
    `75`,
    `11`,
    `76`,
    `12`,
    `77`,
    `13`,
    `78`,
    `14`,
    `67`,
    `15`,
    `68`,
    `16`,
    `69`,
    `17`,
    `70`,
    `06`,
    `71`,
    `07`,
  ],
  [
    0,
    `84`,
    `20`,
    `85`,
    `21`,
    `86`,
    `22`,
    `87`,
    `23`,
    `88`,
    `24`,
    `89`,
    `25`,
    `90`,
    `26`,
    `79`,
    `27`,
    `80`,
    `28`,
    `81`,
    `29`,
    `82`,
    `18`,
    `83`,
    `19`,
  ],
  [
    2,
    `도적불포획사`,
    `빈궁**활인진출자립함`,
    `외 남자 색정사`,
    `주석사 罰`,
    `손괴재물사`,
    `빈궁**병재**외사동요분산**전도`,
    `공공사사구설사`,
    `빈궁**관재`,
    ``,
    `고위안좌-이익경영사** 관재`,
    ``,
    `빈궁**전도**매사위역`,
    `외사 출행이동사`,
    `화합사-안 좋음`,
    `내 여자 색정사`,
    `직녹자산권리문서사`,
    ``,
    `빈궁**전도**가정막힘`,
    `외사 급속사`,
    `도로면담**윗인길사`,
    ``,
    `간음무도**가정개신사`,
    `침체경구사`,
    `음3,4,5,6,9,12월은 종교진출로 성공함`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `내사 출행이동사`,
    `가정동요분산사`,
    ``,
    `가출사**전화위복`,
    ``,
    `전화위복`,
    `내사 급속사`,
    ``,
    ``,
    `이동사`,
    ``,
    ``,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `외교막힘`,
    ``,
    `가정애증**파재도주사**재액해소`,
    ``,
    `재액해소`,
    `실종상해사 우려`,
    `빈궁**관재**전도**정체**경공사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    3,
    ``,
    `빈궁**관재**종교진출자립함`,
    `도적불포획사`,
    `빈궁**전도**활인진출자립함`,
    `외 남자 색정사`,
    `주석사罰**전도`,
    `손괴재물사`,
    `빈궁**병재**외사동요분산`,
    `공공사사구설사`,
    `빈궁**관재**재액해소`,
    ``,
    `고위안좌-이익경영사**재액해소`,
    ``,
    `빈궁**매사위역`,
    `외사  출행이동사`,
    `화합사-안 좋음`,
    `내 여자 색정사`,
    `직녹자산권리문서사`,
    ``,
    `빈궁**가정막힘`,
    `외사 급속사`,
    `도로면담**윗인길사**부족`,
    ``,
    `간음무도**가정개신사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `실종상해사 우려`,
    `내사 출행이동사`,
    `가정동요분산사`,
    ``,
    `가출사**전화위복`,
    ``,
    `전화위복`,
    `내사 급속사`,
    ``,
    ``,
    `이동사`,
    ``,
    ``,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월화재주의`,
    ``,
    `외교막힘`,
    ``,
    `가정애증**파재도주사`,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    4,
    ``,
    `빈궁**가정개신사`,
    ``,
    `빈궁**전도**종교진출자립함`,
    `도적불포획사`,
    `활인진출자립함`,
    `밤에 당하는 능욕박해사`,
    `빈궁**전화위복`,
    ``,
    `모사미수**전도**외사동요분산사`,
    `공공사사구설사`,
    `빈궁**관재`,
    `가정경구불녕사`,
    `빈궁**관재**병재`,
    ``,
    `주석사罰**전도`,
    `외사 출행이동사`,
    `빈궁**관재`,
    `내 여자 색정사`,
    `직녹자산권리문서사**관재`,
    ``,
    `송구경황**전도**가정막힘`,
    `외사 급속사`,
    `도로면담**부족**윗인길사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    `실종상해사 우려`,
    `내사 출행이동사`,
    `가정동요분산사`,
    ``,
    `외 남자 색정사有**가출사`,
    ``,
    `전화위복`,
    `내사 급속사`,
    ``,
    ``,
    `이동사`,
    ``,
    ``,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `외교막힘`,
    ``,
    `가정애증**파재도주사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    5,
    `승진영전사**가정애증**파재도주사`,
    `외사 급속사`,
    `빈궁`,
    `도적악의-공격불의`,
    `빈궁**전도**종교진출자립함`,
    ``,
    `활인진출자립함`,
    `내사 출행이동사`,
    `근심해소**가출사`,
    `외 남자 색정사`,
    `빈궁**외사동요분산**전도`,
    ``,
    `빈궁**관재`,
    `문서헌책지체-구설사`,
    `화합사**사사성취`,
    ``,
    `빈궁**전도**매사위역`,
    ``,
    `불측근심사**관재`,
    `외사 출행이동사`,
    `직녹자산권리**부족**교육관련사`,
    `내 여자 색정사`,
    `병재**관재**전도`,
    `손괴재물사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `재액해소`,
    ``,
    `재액해소`,
    ``,
    `실종상해사 우려`,
    ``,
    `가정동요분산사`,
    ``,
    `전화위복`,
    ``,
    `전화위복`,
    ``,
    `내사 급속사`,
    ``,
    `이동사`,
    ``,
    ``,
    ``,
    `음4,5월 화재주의`,
    ``,
    `색정사有`,
    ``,
    `외교막힘**가정막힘`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    6,
    `분실사**관재`,
    ``,
    `빈궁**병재`,
    `외사 급속사`,
    `화합사**관재**간음사 이익-정대적은재앙됨`,
    ``,
    `빈궁**종교진출자립함`,
    ``,
    `빈궁**활인진출자립함`,
    `내사 출행이동사`,
    `근심해소**가출사`,
    `외 남자 색정사`,
    `송구경황사**가정개신사**외사동요분산`,
    ``,
    `빈궁**관재**전도`,
    `재물암動사`,
    `빈궁**관재**전도`,
    `도적입문격-도난-이동사-제사불리`,
    `전근제외사`,
    ``,
    `빈궁**병재`,
    `앙화소멸사`,
    `직녹자산권리문서**교육관련사**부족`,
    `내 여자 색정사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `외교막힘**가정막힘`,
    ``,
    `가정애증**파재도주사`,
    ``,
    ``,
    ``,
    `실종상해사 우려`,
    ``,
    `가정동요분산사`,
    ``,
    `전화위복`,
    ``,
    `전화위복`,
    ``,
    `내사 급속사`,
    ``,
    `이동사`,
    ``,
    ``,
    ``,
    `음4,5월 화재주의`,
    `외사 출행이동사`,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    7,
    `직녹자산권리문서**교육관련사**부족`,
    `내 여자 색정사`,
    `분실사**가정막힘`,
    ``,
    `빈궁**병재**전도**가정애증**파재도주사`,
    `외사 급속사`,
    `간음사이익-정대적은 재앙됨**관재**전도`,
    ``,
    `빈궁**종교진출자립함`,
    ``,
    `빈궁**관재**활인진출자립함`,
    `내사 출행이동사`,
    `가출사**전화위복`,
    `외 남자 색정사`,
    `송구경황사**외사동요분산**전화위복`,
    ``,
    `빈궁**관재`,
    `재물암動사`,
    `빈궁**관재`,
    `도적입문격-도난-이동사-제사불리`,
    `전근제외사-이익**음4,5월 화재주의`,
    ``,
    `병재**음4,5월 화재주의`,
    `앙화소멸사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `동요분산사`,
    ``,
    `동요분산사`,
    ``,
    `동요분산사`,
    ``,
    `동요분산사`,
    ``,
    `실종상해사**동요분산사`,
    ``,
    `가정동요분산사`,
    ``,
    `색정사有**동요분산사`,
    ``,
    `동요분산사`,
    ``,
    `동요분산사`,
    `내사 급속사`,
    `이동사**동요분산사`,
    ``,
    `동요분산사`,
    ``,
    `동요분산사`,
    `외사 출행이동사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    8,
    `빈궁**병재`,
    `앙화소멸사`,
    `직녹자산권리문서**교육관련사`,
    `내 여자 색정사`,
    `분실사`,
    ``,
    `관재**병재**가정애증**파재도주사`,
    `외사 급속사`,
    `화합사**간음사이익-정대적은 재앙됨`,
    ``,
    `빈궁**종교진출자립함`,
    ``,
    `관재**활인진출자립함`,
    `내사 출행이동사`,
    `근심해소사`,
    `외 남자 색정사`,
    `송구경황사**외사동요분산**가정개신사`,
    ``,
    `빈궁**관재`,
    `재물암動사`,
    `빈궁**관재`,
    `도적입문격-도난-이동사-제사불리`,
    `전근제외사-이익`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `음4,5월 화재주의`,
    `외사 출행이동사`,
    `음4,5월 화재주의`,
    ``,
    `외교막힘**가정막힘`,
    ``,
    ``,
    ``,
    ``,
    ``,
    `실종상해사`,
    ``,
    `가정동요분산사`,
    ``,
    `가출사**전화위복`,
    ``,
    `전화위복`,
    ``,
    `내사 급속사`,
    ``,
    `이동사`,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    9,
    `전근제외사-이익`,
    ``,
    `빈궁**병재`,
    `앙화소멸사`,
    `직녹자산권리**교육관련사`,
    `내 여자 색정사`,
    `분실사`,
    ``,
    `병재`,
    `외사 급속사`,
    `화합사**간음사 이익-정대적은 재앙됨`,
    ``,
    `빈궁**종교진출자립함`,
    ``,
    `빈궁**활인진출자립함`,
    `내사 출행이동사`,
    `근심해소사`,
    `외 남자 색정사`,
    `송구경황사**외사동요분산**기정개신사`,
    ``,
    `빈궁**관재**전도`,
    `재물암動사`,
    `빈궁**관재**전도`,
    `도작입문격-도난-이동사-제사불리`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    `음4,5월 화재주의`,
    `외사 출행이동사`,
    `음4,5월 화재주의`,
    ``,
    `외교막힘**가장막힘`,
    ``,
    `가정애증**파재도주사`,
    ``,
    ``,
    ``,
    `실종상해사 우려`,
    ``,
    `가정동요분산사`,
    ``,
    `가출사**전화위복`,
    ``,
    `전화위복`,
    ``,
    `내사 급속사`,
    ``,
    `이동사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    10,
    `빈궁**관재**전도`,
    `도적입문격-도난-이동사-제사불리`,
    `전근제외사-이익`,
    ``,
    `병재`,
    `앙화소멸사`,
    `직녹**자산**권리**교육관련사`,
    `내 여자 색정사`,
    `분실사`,
    ``,
    `병재`,
    `외사 급속사`,
    `화합사**간음사이익-정대적은 재앙됨`,
    ``,
    `빈궁**종교진출자립함`,
    ``,
    `빈궁**활인진출자립함`,
    `내사 출행사`,
    `근심해소사`,
    `외 남자 색정사`,
    `송구경황사**외사동요분산**가정개신사`,
    ``,
    `빈궁**관재**전도`,
    `재물암動사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `이동사`,
    ``,
    ``,
    ``,
    `음4,5월 화재주의`,
    `외사 출행이동사`,
    ``,
    ``,
    `외교막힘**가정막힘`,
    ``,
    `가정애증**파재도주사`,
    ``,
    ``,
    ``,
    `실종상해사 우려`,
    ``,
    `가정동요분산사`,
    ``,
    `가출사**전화위복`,
    ``,
    `전화위복`,
    ``,
    `내사 급속사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    11,
    `공공사사구설사`,
    `빈궁**관재`,
    ``,
    `고위안좌-이익경영사**관재`,
    `문서유실사`,
    `빈궁**매사위역**음4,5월 화재주의`,
    `외사 출행이동사`,
    `화합사**안 좋음**전도`,
    `내 여자 색정사`,
    `직녹자산권리문서사`,
    ``,
    `빈궁**외교막힘**가정막힘`,
    `외사 급속사`,
    `도로면담**윗인길사`,
    ``,
    `간음무도**기정개신사`,
    ``,
    `빈궁**종교진출자립함`,
    `도적불포획사`,
    `빈궁**활인진출자립함`,
    `외 남자 색정사`,
    `주석사罰**가출사`,
    ``,
    `관재**병재**외사동요분산**전화위복`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `내사 급속사`,
    ``,
    ``,
    `이동사`,
    ``,
    `재액해소`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `색정사有`,
    ``,
    `재액해소`,
    ``,
    `가정애증**파재도주사`,
    ``,
    ``,
    ``,
    `실종상해사 우려`,
    `내사 출행이동사`,
    `가정동요분산사`,
    ``,
    `전화위복`,
    ``,
    `재액해소`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    12,
    ``,
    `빈궁**관재**병재**외사동요분산`,
    `공공사사구설사`,
    `빈궁**관재**전도`,
    ``,
    `고위안좌-이익경영사**전도`,
    `문서유실사`,
    `빈궁**매사위역`,
    `외사 출행이동사`,
    `화합사**안 좋음**음4,5월 화재주의`,
    `내 여자 색정사`,
    `직녹자산권리문서사**재액해소`,
    ``,
    `빈궁**가정막힘`,
    `외사 급속사`,
    `도로면담**윗인길사`,
    ``,
    `간음무도**가정개신사`,
    ``,
    `빈궁**활인진출자립함`,
    `가정동요분산사**도적불포획사`,
    `빈궁**활인진출자립함`,
    `외 남자 색정사`,
    `주석사罰`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `전화위복`,
    `내사 급속사`,
    ``,
    ``,
    `이동사`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `재액해소`,
    ``,
    `색정사有**음4,5월 화재주의`,
    ``,
    `외교막힘`,
    ``,
    `가정애증**파재도주사`,
    ``,
    ``,
    ``,
    `실종상해사 우려`,
    `폐한인**내사 출행이동사`,
    `종업원공모위해`,
    ``,
    `색정사有**전화위복`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    1,
    `외 남자 색정사`,
    `주석사罰`,
    ``,
    `병재**외사동요분산**전화위복`,
    `공공사사구설사`,
    `관재`,
    ``,
    `고위안좌-이익경영사**관재`,
    `문사유실사`,
    `빈궁**매사위역`,
    `외사 출행이동사`,
    `화합사**안 좋음`,
    `내 여자 색정사`,
    `직녹자산권리문서사**음4,5월 화재주의`,
    ``,
    `빈궁**가정막힘`,
    `외사 급속사`,
    `도로면담**윗인길사**전도`,
    ``,
    `간음무도**전도**가장개신사`,
    ``,
    `매사불성**음3,4,5,6,9,12월 종교진출성공함`,
    `내사 출행이동사**가정동요분산사`,
    `매사불성**음7,8,10,11월활인진출성공함`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `전화위복`,
    ``,
    ``,
    `내사 급속사`,
    ``,
    ``,
    `이동사`,
    ``,
    ``,
    ``,
    `음4,5월 화재주의`,
    ``,
    `색정사有`,
    ``,
    `외교막힘`,
    ``,
    `가정애증**파재도주사`,
    ``,
    ``,
    `실종상해사 우려`,
    `빈궁**종교진출해당자`,
    `도적불포획사`,
    `빈궁**활인진출해당자`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
];

const CHOSA1 = [
  `■ 새로 일을 꾸미시네? 
그런데 관재가 걱정이겠어! 
도움줄 사람은 의심하고 있고!
⊕ 변사귀, 
⊖ 원한귀가 침범했구만!

⊕신변상가부정침범
⊖신변음사부정이들었어 !
`,
  `■ 기도ㆍ치성을 잘 드려야     할 분이네? 
반드시 일이 풀려 ! 

⊕목맨 자살귀ㆍ변사귀, 
⊖가택귀ㆍ원한귀 침범이야!

⊕⊖가택고모신사부정이듬
`,
  `■ 여건 부족으로 귀천의 구분이 없게 되어버렸네? 
부부도각각딴마음이고! 그래도 일은 되네?

⊕ 집에 목맨 자살귀,
⊖ 가택귀가 침범했어!
`,
  `■ 마음에 천둥치고 있겠는데 놀랠건 없고!  단 일이 자꾸 반복만 되고 있는데
⊕ 변사귀, 
⊖ 원한귀가 침범해서 그래!

⊕신변고모신사부정침범
⊖신변신화선영부정이
  들었어 !
`,
  `■ 男女共히 : 수치를 모르고 굴속으로만 빠져들고 있네? 
남의 여자를 자기것으로 쓰고 있어! 
⊕목맨 자살귀, 음사부정이    침범했다.
⊖ 전흉사귀가 몸에 들었어!
⊖신변상가부정침범했네!
`,
  `■ 물구덩이에 빠진격이야! 지푸라기라도 잡고 싶겠어! 그래도 참고 가면 고진감래야!

⊕ 음귀, 
⊖ 원한귀가 내 몸을 바로 치고 있네?
`,
  `■ 도대체 어디 의지할데가     없네? 
男: 여자가 비었네! 女:색정불화가 있네!
새로 하려는 일도 배반되고 말겠고! 
당분간 근신자중이 제일이야!
⊕가택에 음사부정이 듬
`,
  `■재․녀 두가지 일로 기로에 섰네? 
마음에 의심질투가  가득하고!
관직자는 9월이 되어야 소원성취하네 !
⊕⊖가택저주원심 부정이 듬
⊕수귀, 

⊖전흉사귀부터 해소시켜야지 !
`,
  `■ 확 바꾸고 싶으나 충돌이 심하겠어! 부부이별이야! 
물구덩이에 빠진격인데, 돈여자는 빈수레겠고 소리만 커!
⊕男:남의 여자를 유혹했네 ?

⊖가택음사부정이침범했다
`,
  `■일이 자꾸 반복되기만 하겠어! 새로 꾸미는 일도 결과가 신통치 않아! 안에서 일어나는 일이니 누구를 탓할수도 없겠어! ⊕음귀 
⊖원한귀가 자꾸결과를 방해해!
⊕신변저주원심부정침범
⊖신변여고음강부정이          들었어 !
`,
  `■ 총은 좋은데 총알이 없겠어! 여건도 부족하고! 
당분간 형편을 따라가는 수밖에 없어! 
운세가 그래!
⊕ 물귀신, 
⊖음사부정이 들었다.
가택귀를 떼야 되겠어!
`,
  `■ 호랑이와 뱀이 싸우는 격이라. 재앙만 있고 집도 넘어가고 눈가리는 일도 있겠고 ! 뭐하나 좋은게 없네! 
관직자 : 한자리 하겠어!`,
];
const YOGYEOL1 = [
  `■ 덕신 문서 장생이 있어 군자는 이익되고 형통하나 삼전이 체극하니 관사재앙의 싹이 된다.
  `,
  `■ 녹신이 내게 임했으니 지키라. 섣불리 시작하면 소송걸린다. 
  군자는 좋게 보거니와 일반인도 나쁜일은 풀린다.
  `,
  `■ 상대가 와서 나를 돕게 되니 다른일을 꾸밀 이유가 없다. 밤정단은 백호가 살에 숨었으니 일깨지고 돈 없어지는 것을 조심하라.
  `,
  `■ 밤정단은 삼전 모두 공망되어 만사가 종적이 없게 된다. 호시과라고 가벼히 보지 말라. 화살촉이 申띠에 적중한다.
  `,
  `■ 관귀에 육합 백호가 승하여 적이 대열을 지었다. 밤정단에는 비록 공무원은 좋으나 일반인은 고통을 입는다.
  `,
  `■ 이름이 무록격이니 나와 상대는 불화할 것이다. 패신과 살을 만났으니 재물은 비고 쫓겨날 것이다.
  `,
  `■ 돕는자가 있다해도 자주 손재한다. 소기의 목적이 불성하니 이는 삼전 모두 공망 때문이다.
  `,
  `■ 계속 연결되어 끝나지 않고 귀살이 인수를 돕고 나를 돕게 한다. 주인ㆍ승헌격이나 일반인은 나쁜일 생길까 두렵다. 
  `,
  `■ 삼전이 합하여 공망 재성을 도우니 담보주고 빚내는건 좋을 것이다. 낮정단은 귀인이 둘이어서 좋고 밤정단은 귀인의 옥문에 든다.
  `,
  `■ 장성ㆍ재물ㆍ덕신이 있으나 모두 무력하여 쓸모없다. 낮정단, 밤정단 모두 귀인사는 차질이 있다.
  `,
  `■ 총에 실탄이 없으니 귀인사를 바라지 말라. 항복심으로 살길을 찾고 밤정단은 형제가 사고난다.
  `,
  `■ 제4과에 백호가 있고 과체의 이름은 호시이다. 범 3개가 과전에 있으니 벼슬에는 기쁨이요, 일반은 재앙이다.`,
];
const SIBO1 = [
  `■女는 시집가기를 원한다
 
  상류인은 좋고 하류인은 나쁘다. 多人의 기만을 당한다. 매사불통한다. 본인의 처신ㆍ행사가 上人의 불신을 당한다. 
  관재ㆍ송사가 있다.
  `,
  `■관재가 있다.
   
  처음 계획대로 밀고나가면 성공이 있다. 방침을 함부로 바꾸지 말라.
  문장으로 복록이 온다.
  곤란이 있어도 견디라.
  吉하게 된다.
  원행사가 있다.
  `,
  `■家內우환이 있다. 가인 中
  가장을 속인다. 가인이 
  원한을 품고 加害하려
  한다. 화순하여 共同 경영사가 吉하게 된다. 서서히 발복한다. 기도ㆍ치성하면 더욱 좋다. 단, 중용을 지키라. 신변색정사가 있고, 삼가지 않으면 발병하여 사망에 이른다.
  `,
  `■家內우환이 기한다.
  가인이 가장을 속인다.
  가인이 원한을 품고 加害
  하려 한다. 일에 凶이 많다. 外에 관재가 있다. 만사에 실적이 없다. 그래도 먼저 나서지 말고 유순한 처신이 필요하다. 他人은 불리하고 본인은 유리하게 된다. 公明正大한 처신이 필요하다. 관재가 있다.
  `,
  `■自充手적 우환이 있다.
   이권을 상실한다.
  금전 여인 재해가 있다
  男이 他婦를 유혹하여 거래한다. 일이 다난하다. 그러나 일시경과하면 해소가 된다. 매사에 예의를 다하라. 일반인은 관재의 징후가 있다. 일은 公이 아닌 私로 진행시켜야 吉하게 된다. 夫婦가 共히 음탕하다.
  `,
  `■自充手적 우환이 기한다.
  은인자중함이 吉하다.
  正月에 임신사가 있다.
  가정에서 妻가 타개책이다. 도모사를 중단하고, 급히 방침을 변경해야 한다. 그대로 진행하면 실패한다. 高下를 막론하고 현재의 직위가 해제된다. 上에서 압박한다. 풍파간난한 입장에 있다.
  `,
  `■心身의 의지처가 없다.
  별리무덕 고독자이다.
  이권상실이 기한다.
  모든일이 효과가 없다.
  반복부정하여 신음한다. 일은 격렬하고 갈피가 서지 않는다. 
  만사를 송별하고 때를 기다리는 것이 좋다. 
  사물은 개혁하려고 하나 시기를 놓쳤다. 
  동凶 정利 하다.
  `,
  `■폐지사를 부흥시키려 한다.
  正月에 임신사가 있다.
  의심하여 결정을 할 수 없는 선택의 기로에 있다.
  친ㆍ근으로 결정하라. 
  성공한다. 현재는 매사 연결되어 매듭이 안풀린다. 기도ㆍ치성하면 풀리게 된다.
  財ㆍ妻를 경계하라. 
  財ㆍ妻에 의해 소송이 기한다.
  `,
  `■사물이 양갈래이다.
  笑中刃를 주의하라.
  다인 다사건이다.
  옛것을 버리고 새것으로 개신ㆍ개혁하고자 한다. 
  어려움을 겪은 후 나중에 좋아진다. 
  단, 관직자나 회사원은 반드시 직위가 해제된다. 申띠는 제외이다. 
  ⊕女는 夫를 버리게 된다. 
  은복하게 된다.
  `,
  `■신ㆍ택 모두 불안하다.
  財 공망이다.
  이권을 상실 한다.
  도모사는 포기하고 물러나야 한다. 계속 진행하면 上人의 질책이 있게 된다. 먼저 충실과 효양을 보여야 한다. 과욕을 부리면 파란이 야기 된다.
  `,
  `■재ㆍ妻를 쫓으나 공망이다.
  별리무덕한 고독人이다
  이권을 상실한다.
  일에 의심이 생겨 불안한 형편이다. 상대ㆍ上人이 유리한 국면이다. 앞장서서 말하지 말라.
  男女 상쟁사도 있다.
  도난건도 있다. 
  불비 부족심이 있다.
  `,
  `■가택의 권리명의를 이양한다.
  혼인사가 있다.
  上人의 손해가 있다.
  진퇴거처가 불명하고 심중에 놀램과 실의심이 겹쳐 있다. 모두 가정 내부에서 생긴 일이다. 일을 꾸미지 말라.
  들킨다. 호랑이 꼬리를 밟고 있는 격이다.
  女는 시집가기를 원한다.`,
];
const SIBO2_1 = [
  `■ 후원, 가택내사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  부동신음의 상이다.
  송쟁사가 있다.
  `,
  `■ 官･夫,신변외사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,
  `■ 손모, 가택이동사가 있다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,
  `■官･夫,家內우환이 기한다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,
  `■ 官･夫,신변외사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재에방이 필요하다..
  `,
  `■ 자손, 가택내사이다.
  내방조짐 상의 :  
  은근하고 伏하다.
  상하분리한다.
  권리를 상실한다.
  `,
  `■ 후원, 가택내사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  색정불화한다.
  이심원한의 상이다.
  `,
  `■ 후원, 가택내사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  가정 우환이 있다.
  형제의 흉이 있다.
  `,
  `■ 자손, 가택내사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  男직위상실 女부부이별한다.
  `,
  `■ 자손, 가택내사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  은혜가 원수되었다.
  `,
  `■ 금전, 가택동요사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  어떤 일이든 처음 불성한다.
  재·녀재액이 있고 이권상실
  `,
  `■ 자손, 가택내사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  가택권리가 양도된다.`,
];
const SIBO2_2 = [];

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/006/006_001.png`,
  `/static/images/sagwa/006/006_002.png`,
  `/static/images/sagwa/006/006_003.png`,
  `/static/images/sagwa/006/006_004.png`,
  `/static/images/sagwa/006/006_005.png`,
  `/static/images/sagwa/006/006_006.png`,
  `/static/images/sagwa/006/006_007.png`,
  `/static/images/sagwa/006/006_008.png`,
  `/static/images/sagwa/006/006_009.png`,
  `/static/images/sagwa/006/006_010.png`,
  `/static/images/sagwa/006/006_011.png`,
  `/static/images/sagwa/006/006_012.png`,
];

const HEASYEOL = [
  // 1
  `  이 과는 천지반이 동궁(同宮)에 복(伏)하여 복음과(伏吟課)로 된다. 4과의 상하 및 요극(遙剋)도 없을 때는 음일(陰日)은 지상신의 사(巳)를 사용하여 초전으로 한다.
    중전은 형(刑)을 채용하고 말전은 전(傳)의 충신(冲神)을 취한다. 그리하여 삼전은 사신인(巳申寅)으로 된다. 초전은 일간의 제왕(帝旺)으로 되고 말전은 일덕(日德)이고 관효(官爻)로 된다. 관리는 승진하고 만사는 이익이 되지 않는 것이 없다.
    초전은 중전을 극하고 중전은 말전을 극하며 말전은 일간을 극하기 때문에 체극(遞剋)하여 화의 싹이 튼다. 상인(常人)은 사람 때문에 침해를 당하고, 또 상인(常人)은 과악(過惡)의 징조가 있다. 현임 관리는 탄핵(彈劾)을 받으니 조심하는 것이 좋다.
    일(日)의 복음(伏吟)은 간상(干上)의 미(未)이고 지상이 사(巳)이며 오(午)의 건록을 끼우기 때문에 식록(食祿)의 일을 정단하는데 좋다. 말전의 인(寅)의 목(木)은 초전 사(巳)의 화(火)를 목생화(木生火)로 생하는데, 이와 같은 것은 사람이 와서 암암리에 원조를 받는다. 야귀는 말전의 인(寅)에 천공(天空)이 승하여 어떤 일도 한담(閑談)이 많고 실의(實意)가 적다. 삼전의 사맹신(四孟神)은 원태격(元胎格)이고 영아를 은복(隱伏)하는 상이다. 모든 일은 정체(停滯)되어 통하지 않으며 정(靜)은 좋고 동(動)은 불길하다. 상류에 유리하고 하급에 불리하다. 군자(君子)는 수신(修身)하여 천명(天命)을 받으므로 기쁨을 받지 않는 일이 없다.
    백법에 말하기를 ｢삼전의 체극(遞剋)은 중인(衆人)에 의해 기만당한다｣고 하였다. 말전은 초전을 돕는다. 백법 37법을 참조하기 바란다. 일귀는 순행하고 삼전은 청(靑)․상(常)․주(朱)로 되며, 초전에 청룡이 승하여 일간을 생하는 인수(印綬)는 더욱 길조로 된다. 야귀는 역행하고 삼전은 합(合)․귀(貴)․공(空)으로 된다. 관직(官職)을 구하면 이익이 있다. 현직에 있는 사람은 불리하고 체극하여 병은 위독하다. 관사(官事)는 재(災)를 초래한다. 대인(待人)은 오지 않는다.
    비전에 말하기를 ｢초전과 말전에 육해(六害)가 있고 중전에 태상(太常)을 끼우는 것은 행동(行動)과 기거(起居)가 모두 존친으로부터 의혹은 받는다｣고 하였다. 일덕(日德)과 주작이 동궁(同宮)하면 관리에게는 길조가 있다.`,
  // 2
  `  이 과는 4과 중에 제4과 묘진(卯辰)과 일상(一上)이 일하(一下)를 극하여 원수과(元首課)로 된다. 삼전(三傳)이 둔간(遁干)하여 을병정(乙丙丁)의 삼기격(三奇格)이 된다. 군자(君子)는 명망이 현달하고 상인(常人)도 일체 화환(禍患)이 없으며 일간상신 오(午)는 건록(建祿)으로 되니 이것을 지키는 것이 좋다.
    초전․중전의 인묘(寅卯)는 일간의 관귀(官鬼)로 되고 말전에 사(蛇)․호(虎)가 승하여 동작은 좋지 않다. 원수과는 정단사가 모두 순리롭다. 범백(凡百)의 일은 옛 것을 지키는 것이 좋고 망동(妄動)은 좋지 않다. 또 처음은 곤란하더라도 후에는 호전된다.
    연여격(蓮茹格)은 삼전이 맹신(孟神)․중신(仲神)․계신(季神)이 상련(相連)하면 관주여래격(貫珠茹來格)이라고 하는데, 길사를 정단하면 경사가 겹친다. 흉사를 정단하는 것을 싫어한다. 즉, 흉사가 겹쳐 일어나기 때문이다. 삼전에 관성(官星)이 겹쳐 보이기 때문에 구직 및 입시․선거 등 어느 것이나 호조(好調)로 된다.
    일간(日干) 상신(上神)에 왕록(旺祿)이 임하는 것은 공명(功名)이 자연히 온다. 함부로 방침을 변경하고 따로 책모(策謀)하는 것은 좋지 않으며 종래의 방침대로 유지해야 한다. 백법에 말하기를 ｢삼전내에 삼기(三奇)를 만나는 것은 가장 존귀를 얻는다. 왕록이 신(身)에 임하는 것은 함부로 망동하지 말아야 한다. 백호와 둔귀(遁鬼)가 병임하는 것은 재화(災禍)가 얕지 않다. 중귀(衆鬼)가 모이더라도 전혀 두려울 것이 없다｣고 하였다. 비전에서 말하기를 ｢주작이 오(午)에 승하는 것은 경쟁․투쟁 등 어느 것이나 반드시 승리한다｣고 하였다.
    주작은 문장(文章)을 다스리며, 일간상에 임하여 일간을 생하는 것은 문장사에서는 숙달한다. 혹은 문장으로 인하여 이익을 얻는다.
    일귀는 순행하고 삼전은 합(合)․주(朱)․사(蛇)이며, 야귀는 역행하고 삼전은 청(靑)․공(空)․백(白)이다. 이 과(課)는 모두 이외의 제우(際遇)를 만난다.`,
  // 3
  `  이 과는 제4과 일하(一下)가 일상(一上)을 극하여 중심과로 된다. 일지의 사(巳)는 일간상에 와서 일간을 생하여 자재격(自在格)이라고 한다. 모사(謀事)를 기도하여 타인의 도움이 있고 어떤 일도 선화(善化)한다.
    초전은 을축(乙丑)에 사호(蛇虎)가 승하고, 중전은 재성(財星)이 공마(空馬)로 되며 말전이 탈기(脫氣)하므로 망동을 조심해야 한다. 축해유(丑亥酉)는 삼전이 역간전(逆間傳)에서 극음격(極陰格)으로 된다. 정단사는 불길하고 음기(陰氣)가 암매(暗昧)하면 병과 소송은 어느 것이나 흉하다. 만약 상조(喪弔)를 만나면 사망의 우려가 있다. 어떤 일도 신중하면 화환(禍患)을 만나지 않는다. 4과는 불비(不備)로 되면 어떤 일도 불비 불완전하고 정확함이 결여된다.
    백법에 말하기를 ｢백호․둔귀(遁鬼)가 동병하는 것은 재앙(災殃)이 얕지 않다. 청룡이 생기(生氣)에 승하는 것은 길하지만 늦다｣고 하였다. 비전에서 말하기를 ｢청룡이 사(巳)에 승하여 간(干)을 생하고 월건 申酉월의 생기를 이룰 때는 길조가 급히 실현되지는 않아도 후에는 서서히 발복(發福)한다｣고 하였다. 사과삼전이 어느 것이나 음신(陰神)으로 귀호책귀(貴戶責鬼)라고 부르며, 이식(利殖)을 점(占)할 때는 음모 궤사(詭詐)로 된다.
    일귀는 순행하고 삼전은 사(蛇)․후(后)․현(玄)이다. 야귀는 역행하여 삼전이 백(白)․현(玄)․후(后)로 된다. 이 과는 백법의 77법이 이에 해당한다. 일간상신은 일간을 생하고 일지상신은 일지를 생한다. 구생격(俱生格)이라고 부르며 피차 모두 화순(和順)하고 생의(生意)가 있으며, 공동 경영 또는 함께 계획하고 모사(謀事)하는 것은 어느 것이나 길조로 된다.
    또 중․말전이 공망한 것은 삼전에 행하지 않는 격으로 되고 초전의 일신(一神)으로 길흉을 정단해야 한다. 역간전(逆間傳)은 일지(一支)를 사이에 두고 삼전을 구성하여 축해유(丑亥酉)는 극음격(極陰格)이다. 축(丑)에 의해 유(酉)에 전하고 음은 음경(陰境)에 들어간다. 특히 불비격으로 되면 음일(淫佚)․주색(酒色)․간사(姦詐) 등의 일이 있고, 색정이 원인이 되어 발병하여 사망에 이르게 된다.`,
  // 4
  `  이 과는 4과 중에 상하의 극이 없고 제3과와 제4과 상신에 요극(遙剋)이 있다. 상신에서 일간을 극하는 인(寅)을 써서 초전으로 하고, 천신(天神)에서 극할 때는 호시격(蒿矢格)이라고 부른다. 제3과를 용신(用神)으로 하는 것은 스스로 양양상전(兩陽相戰)으로 되므로 일을 이루는데 흉이 중하다. 따라서 먼저 동(動)하지 말아야 한다.
    원래 호시는 자기에게 이롭고 상대방에는 불리하며, 소사(小事)에는 이롭고 대사(大事)에는 불리하다. 주작 또는 구진을 보면 관재(官災)는 외(外)로부터 온다. 중전․말전이 공망하고 야귀는 초전에 천공이 승하기 때문에 삼전이 전부 공허하여 만사가 실적(實績)이 없다. 일간(日干) 상신(上神)이 묘(墓)에 복(伏)하여 사물에 저해가 있다. 그러나 초전의 인목(寅木)에서 토(土)를 극제하여 저해를 해제하므로 공(功)을 이룩하고 뜻을 얻는다.
    폐구(閉口)는 양기(兩岐)에 걸쳐 추구(追究)하고 중․말전이 공망하여 전(傳)에 따르지 않고 초전의 길흉을 류신에 따라 정단해야 한다. 비전에서 말하기를 ｢이 과를 얻어 천후(天候)를 정단할 때는 진(辰)의 천강(天罡)은 미(未)의 풍신(風神)을 초전으로 한다. 야귀는 귀인이 해(亥)에 임하고 귀(貴)는 천문(天門)에 올라 청신비천(靑神飛天)으로 된다. 광풍(狂風)이 불고 후에 비로 되어도 수원(水源)이 공망하여 실현되지 못하며 공망을 벗어난 후에야 비로소 대우(大雨)가 있다｣고 하였다.
     일귀는 순행하고 삼전은 주(朱)․후(后)․상(常)으로 되며, 해(亥)의 지(地)에 임하여 귀(貴)는 천문(天文)에 오른다. 일지의 역마는 해(亥)의 공망에 승하기 때문에 대인(待人)은 중도에 불안한 일이 있다.`,
  // 5
  `  이 과는 4과 중에 제1과의 묘(卯)와 미(未)가 일상(一上)이 일하(一下)를 극하여 원수과(元首課)로 된다. 삼전이 삼합목국(三合木局)이 되어 일간을 극하고 중귀(衆鬼)라고 이름을 붙였으며 조합(組合)의 적(賊)이 되어도 중․말전이 공망하여 오래지 않아 스스로 박멸되어 해(害)가 해소된다.
    관공직에 대한 정단은 백호와 귀(鬼)가 승하여 최관부(催官符)로 되고 길하지만, 상인(常人)은 병이나 재화(災禍)가 겹친다. 그러나 원수과는 순극(順剋)하고 특히 일(日)․야(夜)의 천을귀신(天乙鬼神)은 천후(天后)․육합이 서로 임한다. 또 묘(卯)는 사문(私門)이라고 부르고 해(亥)는 음매(陰昧)의 신(神)이므로 자중(自重)하여 예(禮)로써 대할 때는 흉해는 면한다.
    백법에 말하기를 ｢가택(家宅)은 넓고 사람은 쇠퇴한다. 백호가 간귀(干鬼)에 임하면 흉은 빠르다｣고 하였다. 천후(天后)․육합이 승하는 것은 점혼(占婚)하면 자유 연애 결혼이고, 중․말전이 공망하여 삼전에 행하지 않는 것은 초전으로 길흉을 정한다.
    이 과는 묘해미(卯亥未)가 삼합목국(三合木局)을 결성하여 官星이 되므로 공명 정단에 이로울 것 같아도 해미(亥未)가 공망하기 때문에 목전에는 성취하기 어렵다. 일귀는 순행하고 삼전이 합(合)․후(后)․백(白)이다. 야귀는 삼전이 백(白)․합(合)․후(后)로 된다.
    백법에 있는 것처럼 육합․천후는 자유 연애 결혼이다. 그러므로 일귀는 앞에 육합이 있고 후에 천후(天后)를 보는 것은 양(陽)이 가고 음을 구하여 교동격(狡童格)으로 되고 남자가 여자를 유혹한다. 만약 여기에 천후가 먼저 있고 육합이 후에 있을 경우에는 일녀격(泆女格)으로 되어 음은 병(病)을 가게 하고 양(陽)을 구한다. 즉 여자가 남자를 유혹한다. 남녀 색정의 격이다.
    초전 순정(旬丁)이 백호에 승하여 일귀(日鬼)로 되면 흉화(凶禍)가 빨라서 정단하면 사(死)의 우려가 있다. 형법(刑法)에 걸리면 형(刑)을 만난다. 원수과로 되어 흉조를 나타내는 것은 흉이 경하고 길조를 나타낼 때는 길조가 후하다고 정단한다. 과격에 의해 길흉의 후박을 구분할 수 있다.`,
  // 6
  `  이 과는 사과(四課)가 전부 하(下)를 극하기 때문에 섭해무록과(涉害無祿課)로 되고 극중에 지반 사맹(四孟)의 상신을 취하여 초전으로 한다. 제1과․제3과는 인사(寅巳)와 자미(子未)의 교차육해(交車六害)로 되고 중전은 진(辰)의 묘신(墓神)으로 된다. 삼전 과체(課體) 어느 것이나 흉이다. 사상(四上)이 하를 극하는 것은 대인(待人)은 빨리 돌아오고, 초전의 하신(下神)인 인일(寅日)에 돌아온다. 출산도 역시 동론(同論)이다.
    섭해과는 만사에 간난하고 정체되며 풍파험악(風波險惡)하여 건너기 곤란하며, 결혼은 저해가 있다. 질병은 병이 중하다. 사맹(四孟)의 상신을 초전으로 하는 것은 견기격(見機格)이며, 일을 구하는데 처음은 곤란하고 후에는 성공한다. 실물(失物)은 집안에 있고, 도망․가출은 친근한 집에 숨는다. 견기격은 기회를 보는데 좋고 모든 일은 반복한다. 급히 개혁하는 것이 좋으며 구태(舊態)를 지킬 때는 더욱 삽체(澁涕)되어 해제하기 어려우며 모두 채산(採算)으로 한다.
    백법에 말하기를 ｢간지(干支)가 함께 상극(傷剋)을 받는 것은 인택(人宅)이 함께 혹은 피차 양손(兩損)한다｣고 하였다. 육해상수(六害相隨)는 의심이 생긴다. 사(巳)에 의해 자(子)는 심이운 태(胎)로 되고 태신(胎神)은 처재(妻財)로 되기 때문에 처는 회태(懷胎)의 징조가 있다. 신월(申月)은 태재(胎財)가 사기(死氣)를 승하여 태를 손상한다.
    비전에 말하기를 ｢초전이 유(酉)이며 말전이 해(亥)로 된다. 중전의 술(戌)이 암공(暗拱)한다. 만약 년명(年命)이 술(戌)로 되면 시험은 합격한다. 또 년명상에 주작이 승하여 막귀신(幕貴神)을 생할 때는 중위(中位)에 합격한다. 무록격은 직(職)이 높은 자는 작위가 내려가고 낮은 자는 벌봉(罰俸)의 우려가 있다. 보통인은 상에서 압박을 받아 굴(屈)하여 신(伸)하기 어렵고 타개(打開)의 길이 없다｣고 하였다. 일귀는 역행(逆行)하고 삼전은 합(合)․상(常)․사(蛇)이며 사신(蛇神)은 공망하여 재화를 크게 이루지 않는다. 야귀는 순행(順行)하고 삼전은 사(蛇)․상(常)․합(合)이며 자(子)는 사(巳)에 가하여 병단(病斷)은 암중에 걸리고 절식(絶食)하여 사망한다.`,
  // 7
  `  이 과는 천지가 상극하고 수화(水火)가 상전(相戰)하여 초전을 구성한다. 사화(巳火)는 인수(印綬)의 부모효(父母爻)로 되고, 순행하여 말전이 일간을 생하여도 삼전이 전부 공망하여 길흉이 함께 허(虛)로 되므로 실현하지 못한다. 모사 계획은 모두 공허로 되면 억지로 노력하여도 효과가 없고 만사를 송별하는 것이 현명하다.
    원태격(元胎格)이 공망하여 시종 일정하지 않고 원근(遠近)이 함께 역조(逆潮)를 나타낸다. 어떤 일도 가정으로부터 발생한다. 따라서 화환이 겹쳐서 발생한다. 신유월(申酉月)의 정단은 해(亥)의 등명(登明)에 사신사기(死神死氣)가 승하기 때문에 집안에 액사자(縊死者)가 나온다. 가업은 소모되어 흩어지고 반복 신음(呻吟)의 상이다. 
    백법에 말하기를 ｢거래(去來) 공망은 이동이 없고 기쁨도 우려도 공망하여 실현하지 못한다. 요컨대 어떤 일도 때를 기다리는 것이 좋다｣고 하였다. 이 과는 반음중심과(返吟重審課)로 된다. 삼전이 모두 공망하여 일실(一實)도 없으며 동(動)하려 하여도 동할 수 없다. 또 일체 문서(文書)의 일, 시험의 일은 적중하는 것 같지만 적중하지 못한다.
    일귀는 삼전에 역행하여 백(白)․사(蛇)․백(白)으로 어느 것이나 흉장(凶將)이 되어도 오히려 흉이 공망하여 흉이 없다. 야귀는 순행하여 삼전이 현(玄)․합(合)․현(玄)이고 이 역시 모든 일이 공허로 된다. 함부로 동하는 것은 손모(損耗)의 결과를 가져온다.
    원래 반음과는 산은 높고 골짜기가 깊은 상으로, 모든 일이 처음에 얻지만 후에는 패한다. 처음에 실패하면 후에 성공한다. 타인을 해치려 하면 오히려 자기가 받는다. 사해사(巳亥巳)의 삼전은 사물을 개혁할 징조이다. 대개는 재(財)를 구하게 된다. 주사(主事)는 양도(兩跳)를 주로하고 동의(動意)가 격렬하여 정지할 수 없다. 그러나 이 과는 삼전이 모두 공망하기 때문에 동하려 하여도 동하지 못한다.`,
  // 8
  `  이 과는 사과 중의 3과의 상하에 극이 있고 하에서 상을 극하며, 사자(巳子)를 초전으로 하고 지일과(知一課)이다. 삼전 사술묘(巳戌卯)는 주인격(鑄印格)으로서 사과중(四課中)은 제2과․제3과․제4과 상신이 삼전을 구성한다. 즉, 삼전이 사과에 붙기 때문에 회환격(廻環格)으로 된다. 따라서 어떤 일도 연결되어 우환(憂患)이 있으면 해소되지 않는다.
    말전에 의해 초전을 생하고 초전의 화(火)는 일간의 인수(印綬)로 되어 존장의 도움을 받는다. 일간상신은 재(財)로 되어도 말전의 목귀(木鬼)를 돕는다. 그러므로 주인승헌격(鑄印乘軒格)은 사관(仕官) 구직에는 좋다.
    현임 관직은 승진 천관의 기쁨이 있고 상인(常人)은 별로 좋은 일이 없다. 지일과(知一課)는 먼 것을 버리고 가까운 것을 취하므로 모두 화합하고 모든 일은 후에 성취된다. 시험 선거는 합격과 당선의 경사가 있다. 일간상신은 처재(妻財)에 해당하며 인월(寅月)에 임신을 정단하면 반드시 회임한다. 신월(申月)에 정단하면 태를 손상한다.
    주인승헌격(鑄印乘軒格)이란 술(戌) 중에 신금(辛金)이 있고 사(巳)의 화(火) 중에 병(丙)이 있어 병신(丙辛)이 간합하며, 금(金)은 화(火)로 극되어 인모(印摸)를 형성한다. 말전 묘(卯)는 차륜(車輪)으로 승헌의 상을 이룬다. 백법에 말하기를 ｢염막(簾幕)에 귀신(貴神)이 있는 것은 야장(夜將)에 있으므로 입시․구직은 가장 길하다. 삼전이 묘(墓)로써 내몸을 생하는 사(巳)의 화(火)는 묘에 들어가 애증(愛憎)을 나누고, 주인격(鑄印格)의 공망을 애석(哀惜)하게 여겨 이것이 파인(破印)으로 된다. 즉 상길(上吉)의 과이긴 하지만 운명은 아직 때가 오지 않은 상이다.
    일귀는 역행하고 삼전은 백(白)․주(朱)․현(玄)이며 불길(不吉)의 천장(天將)이다. 야귀는 순행하고 삼전이 현(玄)․주(朱)․백(白)으로 말전의 관(官)과 백호는 최관사자(催官使者)라고 하는데, 공망하는 것으로서 피점자의 년명(年命)에 묘술(卯戌)이 있으면 공망을 전실(塡實)하여 해공(解空) 되어 구직은 어느 것이나 빠르다. 삼전이 공망하고 년명상신이 공망하면 어느 것이나 해공으로 되어야 한다는 원칙을 잊지 말아야 한다.`,
  // 9
  `  이 과는 제1과와 제3과는 하에서 상을 극한다. 어느 것이나 음의 극으로 되면 사맹신(四孟神)이 사(巳)의 상신을 채용하여 섭해격(涉害格)으로 된다. 초전이 자오묘유(子午卯酉) 또는 진술축미(辰戌丑未)를 쓸 때는 찰미격(察微格)으로 된다. 섭해(涉害)는 흉과(凶課)이지만 오행천장(五行天將)의 길흉에 따른다. 찰미격(察微格)은 극해가 얕고 경미하기 때문에 찰미라고 이름을 붙였다.
    일간은 일지상신 유(酉)를 보아 탈설(脫泄)로 되고, 지(支)의 음양신에서 나와서 삼전을 구성하여 금국(金局)이 된다. 금(金)은 일간상신 해(亥)의 수(水)를 생하여도 공망의 재(財)이고 삼전의 금국(金局)은 재(財)를 생하기 때문에 실재(實財)로 되며 타(他)에서 원조의 재(財)로 된다. 삼전 종혁(從革)은 사물을 변혁하며, 처음에는 어렵고 후에는 쉽다.
    자손효(子孫爻)를 보면 관직이나 회사 근무자에는 불길하다. 만약 년명상신(年命上神)이 재성(財星)을 보면 관직이나 기타의 구직은 목적을 달성한다. 병점 및 송쟁사(訟爭事)는 불리하다. 백법에 말하기를 ｢천후와 육합이 승하는 것은 점혼하면 중매인이 없이 혼약한다. 즉 자유 결혼이다. 전묘(傳墓)로서 묘에 들어가는 것은 애증(愛憎)을 나눈다. 삼합중(三合中)에 귀살을 범하는 것은 꿀 속에 비소가 있다｣고 하였다.  비전에 말하기를 ｢기사일(己巳日)에 천지반중의 자(子)가 신(申)에 승하는 것은 태신이 장생에 앉기 때문에 임신을 정단하면 가장 길하다. 출산은 삼합회국하여 흉하다｣고 하였다. 즉 자(子)는 모복(母腹)에 연연하여 출산이 지체된다.
    일귀는 역행하고 삼전은 합(合)․후(后)․백(白)이다. 야귀는 순행하고 삼전이 사(蛇)․청(靑)․현(玄)으로 된다. 삼전은 말전에서 둔생(遁生)으로 되어 타인의 원조를 받는다. 삼전 사유축금국(巳酉丑金局)은 종혁격(從革格)으로 되어 옛 것을 파괴하고 혁신한다. 류신은 금철(金鐵)의 일이며, 또 어떤 일도 쉬는 일이 없다. 즉 하나의 일이 가고 또 하나의 일이 와서 왕래가 그침이 없다. 길장이 초전에 있으면 사람의 도인진출(導因進出)의 징조를 이룬다. 업도는 갈라지고 늑골․폐계의 질병이 있다. 여자와의 이별은 이연(離緣)의 목적에 도달한다. 이리하여 금국을 이격신(離隔神)이라 한다.`,
  // 10
  `  이 과는 제3과의 일하(一下)가 일상(一上)을 극하여 중심시입과(重審試入課)로 된다. 제3과 상신은 일간의 장생으로 지반의 극을 받아도 신사(申巳)가 지합하여 이것을 초전으로 하여 싸우는 가운데 화합이 있다. 중전 해(亥)의 재(財)는 공망하고 말전의 인(寅)도 공망한다. 백법에 말하기를 ｢전(傳)에 행하지 않는 것은 초전의 길흉에 따라야 한다｣고 하였다. 일귀의 자(子)는 유(酉)에 가하여 야지(夜地)에 임하고 신(申)의 야귀는 사(巳)에 가하여 주지(晝地)에 임한다. 귀사(貴事)를 정단하면 일에 돌아오지 않는다. 이와 같은 것은 양두탈선(兩頭脫線)의 상으로 된다. 귀신의 순행은 저해가 없고 진행(進行)하는데 좋으며, 만약 역행할 때는 후퇴하는 것이 좋다. 귀신이 일간기궁(日干寄宮)인 미(未) 앞의 신(申)에 해당하기 때문에 추구(追求)를 좋아하지 않는다. 추구하면 귀인이나 존장의 노(怒)를 만나며, 중심과는 충실(忠實)과 효양(孝養)을 앞서 해야 한다.
    삼전의 사맹(四孟)은 원태격(元胎格)으로 되며, 생(生)을 받는 것은 순리로운 정세로 되어 지장이 없다. 소인은 과욕을 일으키면 패란(悖亂)을 만들게 된다. 일간은 일지상신 신(申)에 탈설(脫泄)하고, 일지 사(巳)의 화(火)는 일간상신 술(戌)에 탈설하여 교차설기격(交車泄氣格)으로 되며, 일간이 초전에 탈설하여 삼소설기(三所泄氣)로 되는데, 이것은 허사부실(虛詐不實)의 건이 발생하므로 세심한 주의를 기울여 탈설의 환을 면하는 일에 뜻을 두어야 한다.
    백법에 말하기를 ｢귀신이 차질(蹉跌)을 일으키는 것은 정단의 주사(主事)는 착오를 생하며 말전이 천공(天空)을 승하여 공망하고 공상공(空上空)에 만나기 때문이다. 이런 것은 사물을 추구하지 말아야 한다. 비전에 말하기를 ｢초전의 신(申)은 중전 해(亥)의 수(水)를 생하고 말전의 인(寅)을 생한다. 그러나 인목(寅木)은 일간을 극한다. 이와 같은 것은 은(恩)이 변하여 구(仇)로 되므로 구원격(仇怨格)이라고 이름을 붙였다. 일귀는 역행하고 삼전은 구(勾)․사(蛇)․음(陰)이다. 귀인은 유(酉)의 지(地)에 서서 여덕격(勵德格)으로 되고, 군자는 때를 기다려 진(進)한다. 야귀는 삼전이 귀(貴)․현(玄)․공(空)으로 된다. 이 과는 순수(旬首) 자(子)의 지반(地盤)에 현무가 승하므로 내정(來情)은 도적(盜賊)의 건이다.`,
  // 11
  `  이 과는 사과 중에 상하의 극이 없고, 제2과 해(亥)의 수(水)를 일간에서 요극하여 요극탄사격(遙剋彈射格)으로 된다. 사과가 완전하지 못하여 불비격이 된다.
    초전의 해(亥)는 공망으로 되고 탄사(彈射)하여도 탄환(彈丸)이 없다. 즉 재를 구하여도 얻을 길이 없다. 중전 축(丑)은 을(乙)의 둔귀(遁鬼)로 되어도 공망의 지(地)에 있고, 또 말전의 묘는 일간의 귀살(鬼殺)로 되어도 일간의 유(酉)가 양귀(兩鬼)를 제어하여 재(災)가 없다. 즉 도생피란(逃生避難)으로 된다.
    요극과는 화복이 모두 경미(輕微)하고 어떤 일로 의심이 생겨 소극적으로 된다. 야귀(夜貴)는 현무가 초전으로 되어 실탈의 건이 일어나고, 일귀로 가정의 일을 정단하면 불효한 자식으로 인해 고심하거나 사용인으로 인한 실패 등의 일이 있다.
    백법에 말하기를 ｢양귀신(兩貴神)이 극을 받아서 어떤 일도 귀(貴)가 결여된다. 백호와 둔귀(遁鬼)가 동병(同倂)하면 재화가 적지 않으며 이 과는 피난도생(避難逃生)의 격이다｣고 하였다. 비전에 말하기를 ｢지일격(知一格)은 가까운 것을 논하고 먼 것을 찾는 징조이다. 무릇 사과(四課) 중에 상하(上下)의 극이 없고, 일간과 사과상신의 상극으로 초전이 되고 일간에서 사과상신을 극하는 것은 탄사격(彈射格)으로 된다｣고 하였다. 일간에서 극하여 탄환(彈丸)이 되고 화살이 되므로 탄사격이라고 부른다.
    내가 사람을 도모하여 상대방에 이롭고 나에게는 불리하게 된다. 상(上)에 이롭고 하(下)에 불리하며, 귀신이 순행(順行)하면 친근하고 화합하며 역행(逆行)은 화목하지 않다. 방문하여도 부재(不在)중 이거나 회견할 수 없다. 대인(待人)은 오지 않는다. 제2과를 초전으로 하기 때문에 근사(近射)라고 칭하며 사건은 밖에서 일어난다. 앞장서서 떠들면 불리하다.
    일귀는 역행하고 삼전은 사(蛇)․후(后)․현(玄)이고, 귀신(貴神)이 술(戌)의 옥(獄)을 밟으므로 불길하다. 야귀는 삼전이 현(玄)․백(白)․청(靑)이고, 청신(靑神)과 관성(官星)이 동궁(同宮)에 있어 공명․사관․구직 등은 어느 것이나 유리하다. 이 불비격은 합(合)․후(后)가 일야(日夜)로 동병하여 일남일녀(一男一女)의 투쟁(妬爭)이 있다.`,
  // 12
  `  이 과는 사과 중에 상하의 극이 없고 요극(遙剋)도 없기 때문에 유(酉)를 표준으로 하고 음일(陰日)은 천지반 중의 유(酉)의 하(下)를 채용하여 초전으로 한다. 유(酉) 중에 앙숙(昻宿)이 있고 유(酉)는 서방의 백호가 금성(金星)으로 되어 형살(刑殺)을 다스린다. 극이 없기 때문에 형살(刑殺)을 목표로 하는 것이다.
    중전은 일간상신의 신(申)을 채용하고 말전은 일지상신의 오(午)를 취한다. 정단사건의 대부분은 암매(暗昧)하고 사정(事情)은 실의(失意) 상태이다. 진퇴는 의뢰할 곳이 없고 내부에 우려와 놀라운 사건이 발생한다. 일을 만들어 곤란하게 되며 도망자(逃亡者)는 몸을 숨긴다. 과격(課格)은 동사엄목(冬蛇掩目)으로 되어 동하지 않고 변하지 않으며 복익(伏匿)의 상이다.
    사과는 천라지망(天羅)地網)이므로 재환(財患)을 만나면 피할 수 없다. 그러나 전(傳)에 귀인이 이위(二位)이고 말전이 건록(建祿)을 만난다. 또 일귀는 해(亥)에 임하고 귀(貴)는 천문(天門)에 올라서 공명․관록․구직․사관 등은 길조로 된다. 백법에 말하기를 ｢일지상신이 건록이 되는 것은 권섭부정격(權攝不正格)으로 되며, 나망(羅網)을 만나는 것은 계획하여 졸책(拙策)을 이룬다｣고 하였다. 비전에 말하기를 ｢사일(巳日), 신(申)이 미(未)에 가하고 일귀는 태상이 일간에 임하여 래정(來情)은 결혼문제이다. 도망․가출을 정단하면 현자(賢者)는 동북에 있고 소인은 서남으로 도망한다｣고 하였다.
    일귀는 순행하여 삼전이 상(常)․상(常)․공(空)이고, 야귀는 역행하여 삼전은 귀(貴)․귀(貴)․주(朱)로 된다. 주작이 사(巳)에 임하여 문서(文書) 및 입시․선거 등은 주작이 천(天)에 오른다고 하여 일등으로 당선되고 급제한다. 만약 주작에서 태세(太歲)를 생하거나 년명상신이 해(亥)일 때는 귀신이 천문에 올라 길 중에 길을 더욱 첨가한다. 대개 육임점과(六壬占課)는 흉상(凶象)일지라도 정단사건은 길로도 되고 흉으로도 되는데, 이는 그 사건에 따르기 때문임을 잊지 말아야 한다. 즉 일사(一事) 일점(一占)의 원칙을 굳게 지켜 과격은 동일한 것이라도 길흉은 다르게 해야 한다.`,
];

export default {
  columnA,
  columnD,
  columnS,
  columnZ,
  columnAF,
  columnAS,
  columnAU,
  columnBE,
  columnBO,
  columnBS,
  columnBV,
  columnCE,
  columnCL,
  columnCY,
  columnDH,
  //
  CHOSA1,
  YOGYEOL1,
  SIBO1,
  SIBO2_1,
  SIBO2_2,
  SAGWA_IMAGE1,
  HEASYEOL,
};
