
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,1,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,8,12,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,9,11,`특별있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,10,10,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,9,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,8,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,1,7,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,2,6,`불성`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,3,5,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,4,4,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,5,3,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,2,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`색정`,`외남내녀 부부 똑같이 색정사 有`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,1011월 방문자는 종교진출하면 성공함`,`小`,`7`,`색정사有`,`색정사有`],
    [`매사 영속성없음`,`금전사업이권처첩사는 선천적 재해를 겪음`,`大`,`단절기`,`증오도망`,`손해가출`],
    [`응당좌수(應當座守)`,`당연히 앉아 지켜야만 하는 것인 상`,`大`,``,`미세송사`,`가내추행`],
    [`시위전포(施爲展布)`,`용이 구름을 타고 다중의 이익을 널리 알리는 격의 상`,`小`,``,`고시득길`,``],
    [`동요`,`외사 생업 내부가정 동요불안분산사 `,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`8`,`침체공허`,`침체공허`],
    [`매사 영속성없음`,`형제친구금전소모여인갈등사는 선천적기복재해를 겪음`,`小`,`침체기`,`동요분산`,`동요분산`],
    [`서서발복(徐徐發福)`,`서서히 발복하는 상-단, 근친상간 우려-주거환경정리 요됨`,`小`,``,`모두흉사`,`이전이사`],
    [`손괴재물(손괴재물)`,`재물(牛관련)이 손괴된 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업직위 관련사`,`小`,`9`,`면모훼손`,`면모훼손`],
    [`남편`,`남편 관련사-근친상간사 有`,`小`,`소외기`,`표은남산`,`근친상간`],
    [`사종난탈(事終難脫)`,`일의 끝에 기다리는 어려움을 탈피하기가 힘든 상 `,`大`,``,``,``],
    [`필유성취(必有成就)`,`주력사를 반드시 성취하게 되는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업직위 관련사`,`小`,`10`,`생업단합`,`가정단합`],
    [`결속`,`결속하여 권리사를 크게 이루나 病 형재災害 발생함 `,`小`,`침해기`,`他婦유혹`,`인택쇠퇴`],
    [`동용재손(動用災損)`,`적극적 활동은 재난과 손실만 초래하게 되는 상`,`大`,``,``,``],
    [`절상인구(절상인구)`,`가족 중 절상사를 당하는 비극이 발생하는 상`,`小`,``,``,``],
    [`형제`,`형재 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`11`,`손비과출`,`손비과출`],
    [`동료`,`동료 관련사`,`小`,`쇠퇴기`,`직위훼탈`,`재물암동`],
    [`부모재구(父母災咎)`,`부모의 신상이 크게 걱정되는 상`,`小`,``,``,``],
    [`음사시리(淫事是利)`,`음사를 벌여 이익이 되고 정대적은 되려 재앙이 되는 상`,`大`,``,``,``],
    [`후원`,`후원조력 관련사`,`大`,``,``,``],
    [`부모`,`부모 관련사`,`大`,`12`,`출행이동`,`출행이동`],
    [`출행`,`외사 내부 가정사 기로 출행이동사`,`小`,`과시기`,`속성속패`,`송구경황`],
    [`점위빈자(漸爲貧者)`,`점점 형편이 어렵게 되는 일만 일삼는 상`,`小`,``,``,`(통정인나`],
    [`앙화소멸(殃禍消滅)`,`두려운 재난이 모두 해소되었는가-의 상`,`小`,``,``,`타남)`],
    [`직녹`,`작위녹위 관련사`,`大`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`1`,`상하화합`,`상하화합`],
    [`화합`,`외사 내사 상하화합사`,`小`,`절정기`,`紛憂해산`,`흉화해소`],
    [`병송외봉(病訟畏逢)`,`두려운 병과 소송사에 부딛치게 되는 것을 조심해야 할 상 `,`小`,``,``,``],
    [`신택불안(身宅不安)`,`몸이고 집이고 모두 불안정한 상`,`小`,``,``,``],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`2`,`생업막힘`,`가정막힘`],
    [`결속`,`결속하여 권리사를 크게 이루나 病 형재災害 발생함 `,`小`,`면모기`,`將次공명`,`取財절제`],
    [`안전부득(眼前不得)`,`급히 주는 선물을 놓치니 애석하다-는 상-잉태 관직은 吉`,`小`,``,`(사업관련)`,``],
    [`봉흉화길(逢凶化吉)`,`흉을 만났으되 변화여 길이 되는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사-근친상간사 有`,`小`,`3`,`직위불안`,`남편갈등`],
    [`급속`,`생업 외사 가정 내사 급속사 有`,`大`,`학습기`,`급속사有`,`급속사有`],
    [`처재가외(妻財可畏)`,`처와 재물에 두려운 일이 잇게 되는 상-퇴하면 면함`,`小`,``,`반복不定`,`진퇴동요`],
    [`표은남산(標隱南山)`,`산으로 깊숙히 숨고싶은 심정의 상`,`大`,``,``,`근친상간`],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극남`,`남자 갈등사**근친상간사 有`,`小`,`4`,`직위수난`,`남자수난`],
    [`여식`,`여식 관련사`,`大`,`개시기`,`행진불편`,`음사애매`],
    [`음인비용(陰人費用)`,`자식의 집을 팔아 통정인의 비용으로 쓰는 격인 상`,`小`,``,``,`근친상간`],
    [`근친상간(近親相姦)`,`가족 근친간 통간을 하고 있는 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`5`,`외사송쟁`,`내사파재`],
    [`송쟁`,`외사 내사 급속송쟁파재실종사-상해 우려`,`小`,`배양기`,`도중청원`,`가정추행`],
    [`생계유장(生計悠長)`,`살림살이가 넉넉해지는 상`,`小`,``,`(고위행길`,`(거주환경`],
    [`봉림유사(逢林有蛇)`,`숲에서 뱀을 만난 격으로 놀라운 사건에 유의해야하는 상`,`大`,``,`때청원)`,`방비)`],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`6`,`토지송쟁`,`방해좌절`],
    [`처`,`처 관련사`,`大`,`존재기`,`봉림유蛇`,`진퇴곤란`],
    [`응촉지의(應燭之意)`,`혼인사(私交)가 만족하여 응하는 마음이 있다-는 상`,`大`,``,`(깜짝놀람)`,``],
    [`이인동심(二人同心)`,`두 사람이 힘을 합하여 동사하는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사 직업 남편 사로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사직업남자사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`형제친구동료조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`후원문사출행부모조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`직위녹위상하화합사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`자매친구동료조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`직업직위불안남편갈등사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`직업직위불안남자갈등여식사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`형제친구동료조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`금전사업처조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력**만약 타인과 다툰다면 서로 같은 심정이므로 곧 화해가 됨 `,0,0,`여자이면 이로운 人 이고 남자이면 불리人`,0,0,`부동산이나 도로 관련사로 성공할 수 있음**방해가 있음**두 갈래 기로에 처함**여자는 색정사가 발생 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력**만약 타인과 다툰다면 서로 같은 심정이므로 곧 화해가 됨 `,0,0,`여자이면 이로운 人 이고 남자이면 불리人`,0,0,`근친상간사 발생 우려**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력**만약 타인과 다툰다면 서로 같은 심정이므로 곧 화해가 됨 `,0,0,`여자이면 이로운 人 이고 남자이면 불리人`,0,0,`근친상간사 有**산속으로 깊이 숨고싶은 심정임**배신사가 있음**입을 닫아야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결하는 것이 유리함`,0,0,`명분이 있어 속결처리하자 않고 좌고우면하거나 사안을 뒤로 미루면 되려 불리한 결과가 초래되는 人`,0,0,`여러 복잡한 일을 결속하여 하나로 일관 시킴-단 관공직사가 아니면 본인에게 병 또는 관재가 발생함**남자가 타인의 부인을 유혹하여 자기 여자로 사용함 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜨을 갖고 있음`,0,0,`동료와의 동업 또는 혐력사로 의혹을 받고 불안하여 선택의 기로에 서있으나 가장 친하고 가가이 거주하는 자와 의논하면 플리게 되는 人`,0,0,`직위훼탈됨**정대한 일들이 단절됨**병자는 흉함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력**만약 타인과 다툰다면 서로 같은 심정이므로 곧 화해가 됨 `,0,0,`여자이면 이로운 人 이고 남자이면 불리人`,0,0,`모사는 본인이 직접하면 불성하므로 타인을 앞세워야 함**친속불화함**어떤 일도 오래가지 못함**배신사 있음**기로이동사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인가 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`권리 권한사가 있음-단 음11월에 가능함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서 대항하지 말고 유순유화적 처신으로 사안을 뒤러 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 사안을 지체시키거나 후로 미뤄야만 결과가 반드시 유리해지는 人`,0,0,`여러 복잡한 일을 결속하여 하나로 일관시킴-단 관공직사가 아니면 되려 본인에게 병 또는 관재가 발생함**기이한 전화위복사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력근친상간(近親相姦)**만약 타인과 다툰다면 서로 같은 심정이므로 곧 화해가 됨 `,0,0,`여자이면 이로운 人 이고 남자이면 불리人`,0,0,`근친상간사 有**산속으로 깊이 숨고싶은 심정임**서로 속임**일이 결과없이 반복되나 역시 어두워짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력근친상간(近親相姦)**만약 타인과 다툰다면 서로 같은 심정이므로 곧 화해가 됨 `,0,0,`여자이면 이로운 人 이고 남자이면 불리人`,0,0,`근친상간사 有**보행(외 다리)이 불편한 격`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`이인(二人)동심협력근친상간(近親相姦)**만약 타인과 다툰다면 서로 같은 심정이므로 곧 화해가 됨 `,0,0,`여자이면 이로운 人 이고 남자이면 불리人`,0,0,`근친상간사 有**아내가 남편을 배신하는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 엿보고 공격하지는 않음`,0,0,`스스로 유약심을 갖고 적극적 활동을 하지 않으며 신음고로하는 人-신체 억압상태의 경우도 있음`,0,0,`토지가옥사의 송쟁사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`이외사`,`기사`,`신구중간사`,`돌발사`,`모호사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`기사`,`구사`,`돌발사`,`모호사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-출비됨`,`이외사`,`기사`,`신사`,`돌발사`,`모호사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신구중간사`,`외사내사겹침`,`아표면사타표면사겹침`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후화사 타발동급속사겹침`,`미사`,`신구중간사`,`외사내사겹침`,`아이면사타이면사겹침`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`이외사`,`기사`,`신사`,`모호사`,`모호사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사타발동급속사겹침`,`기사`,`신사`,`아외사타내사겹침`,`아후회사타급속사겹침`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동타발동동시사`,`미사`,`신사`,`외사내사겹침`,`아표면사타표면사겹침`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`미사`,`신사`,`돌발사`,`모호사`,`배신-무력`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`미사`,`신구중간사`,`돌발사`,`모호사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`미사`,`구사`,`돌발사`,`모호사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-출비됨`,`아타동시발동사`,`미사`,`구사`,`외내동시사`,`아표면후회이면사타표면급속이면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,` 무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해함`,`화해하`,`화해함`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [`용모`,`가문`,`合6`,``,`용모`,`가문`,`合6`,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음7월`,2,3,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음3월`,3,1,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월-연기 또는 파혼우려`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음2월`,2,1,3,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음1월`,2,1,3,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월-파혼우려`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음3월-연기우려`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음10월`,2,2,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무도 협력 동심을 이루어 성공함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세나 초반의 실패를 넘기고 중반 종반에 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받으나 업무가 대체로 침체함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세고 여러 복잡한 일을 결속시켜 하나로 묶으나 관공사가 아니면 되려 큰 피해를 초래함  `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻어 여러 불리한 사안을  해결함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초반에는 실적을 보이나 중반 종반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 권리권한사에 치중하여 음11월에 완성함  `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 여러 일을 결속단합시키나 관공사가 아니면 되려 큰 피해가 초래됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하며 업무 전반이 침체함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무 전반을 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무전반에 걸쳐 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 토지건물사로 소송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`多集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`권리양도`,`進向`,`원`,`留`,`주인후원`,`남남서`,``,`정동`],
    [`10.11`,`集`,`다중제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후費`,`進向`,`  원`,`  留`,`주인조력`,`서남`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선費후費`,`進向`,`  원`,`  留`,`무난`,`정서`,``,`남남동`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`주인가해`,`북서`,`정남`,``],
    [`10.11`,`  集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인내부`,`이행`,`선난후費`,`退背`,`  원`,`  去`,`주인가해`,`북북서`,`남남서`,``],
    [`10.11`,``,`반대`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`끌면불가`,`이행`,`본인사안`,`退背`,`  근`,`  去`,`주인무력`,`정남`,`동남`,``],
    [`10.11`,``,``,``,`모두무력`,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,`본인사안`,``,``,``,``,``,``,``],
    [`7.8월`,``,`끌면제압`,`이행`,`모두무력`,`退背`,`  근`,`  去`,`주인무력`,`동북`,`동남`,``],
    [`10.11`,``,`불가`,``,``,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`극렬반대`,`이행`,`선攻후費`,` 退背`,`  원`,`  去`,`세입가해`,`동북`,`남남서`,``],
    [`10.11`,` 多集`,`본인합세`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선費후費`,` 退背`,`  원`,`  去`,`상호반목`,`정동`,`북북서`,``],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대없음`,`이행`,`선損후損`,` 退背`,`  근`,` 去`,`세입조력`,`동남`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,`세밀심사`],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선損후損`,` 退背`,`  원`,`  去`,`세입조력`,`남남동`,``,`남남동`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선費후費`,`進向`,`  근`,`  留`,`무난`,`정남`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,`자살귀`,`목맴`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`자살귀`,`목맴`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`자살귀`,`목맴`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`자살귀`,`목맴`,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,"동북",`없음`],
    [``,``,``,0,0,`묘향`,"서남",``],
    [`생업`,`음귀`,`묘지탈`,0,0,`전경`,"제각",``],
    [``,``,``,0,0,`묘속`,"목근",``],
    [`가정`,`음귀`,`묘지탈`,0,0,`육친`,"모친",``],
    [`질병`,` 수귀`,`익사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`수귀`,`익사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`수귀`,`익사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,"저주부정","저주怨心",`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,"저주부정","저주怨心",`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`도적집기 어려움`,``,`매사 허성불성-세속허사자**증오가출사 우려`,`음7.8.10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음`,`종교진출하면자립함`,``,`공공사사구설사`,``,`고위안좌-다중이익전포사`,`매사 위역사**굴욕난해사`,``,`내외 기로출행이동사`,`주력사 흉-이동사`,`내외 상하 화합사`,`고시 득길사-미세 송사`,`내외 막힘`,`숲속에서 뱀을 만나 놀라는 격`,`내외 급속사`,`고위 행길할 때 청원함이 좋음`,``,`간음무도사`,`매사 위역사**남녀직원공모은닉사有**내외송쟁파재`,`음인암손사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁`,`부부동시색정사**酒食수벌사`,``,`손괴재물사**내외 동요분산사`,`전도**빈궁**병재`,`전화위복`,`빈궁**관재`,``,`관재`,``,`전도**빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`전도**빈궁`,`재액해소`,``,`재액해소`,``,`침체驚懼사**실종상해사 우려`,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**남녀직원공모은닉사有`,`음인암손사`,`도적잡기 어려움`,``,`매사 영속성없음**증오가출사 우려`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`공공사사구설사`,`고위안좌-다중이익전포사`,``,`매사 위역사**굴욕난해사`,``,`내외 기로출행이동사`,`주력사 흉-이동사`,`내외 상하 화합사`,`고시득길사-미세 송사`,`내외 막힘`,`숲에서 뱀을 마나 놀라는 격`,`내외 급속사`,`고위 행길할 때 청원함이 좋음`,``,`간음무도사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내외급속송쟁파재실종상해사 우려`,`빈궁`,`전화위복`,`전도**빈궁`,`부부동시색정사**酒食수벌사`,`전도`,`손괴재물사**내외 동요분산사`,`빈궁**병재`,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,`관재`,``,`빈궁`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`관재`,``,`빈궁`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`통정인 병질구설사`,``,`매사 위역사**내외급속송쟁파재실종상해사 우려`,`문서유망실사`,``,`특명-필유성취사`,`매사 영속성없음**증오가출사 우려-능욕박해사`,`종교진출하면자립함`,`매사 영속성없음**모사미수사`,`종교진출하면자립함`,``,`공공사사구설사`,``,`절상인구사-가족중불행사`,``,`관직 기쁨-상인 흉`,`내외 기로출행이동사`,`허획경구사-이동사`,`내외 상하 화합사`,`문서암동사`,`내외 막힘`,`송구경황사-통정인출현-두려움`,`내외 급속사`,`고위 행길할 때 청원함이 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`재액해소`,`빈궁`,`전화위복`,``,`부부동시색정사`,`빈궁`,`동요분산사**재액해소`,``,`전화위복`,`빈궁**관재`,``,`빈궁**관재**병재`,`재액해소`,``,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**빈궁**관재`,`재액해소`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`승진영전사-부족`,`내외 급속사`,`도적악의-공격말 것`,``,`매사 위역사**내외급속송쟁파재실종상해사 우려`,`음인암손사`,``,`자강불식-가나있으나 좋은 격`,`매사 영속성없음**증오가출사 우려`,`종교진출하면자립함**재난해산사`,`매사 영속성없음`,`종교진출하면자립함`,`자식위한 문서상신-답신지체사`,``,`입실성취사-관계성`,``,`매사 위역사`,`굴욕난해사`,`불측근심사`,`내외 기로출행이동사`,`내외 상하 화합사`,`상대진심가늠안됨-情`,`내외 막힘`,`재물손괴사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`재액해소`,`빈궁`,``,`전도**빈궁`,`전화위복`,`빈궁`,`부부동시색정사`,``,`동요분산사**화소복지사`,`전도**빈궁`,`전화위복`,`빈궁**관재`,``,`관재`,``,`전도*빈궁`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁`,``,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`재물손괴사`,`내외 막힘`,`싫은 일 반복사`,`내외 급속사`,`간음사이익-정대적 재앙됨`,``,`매사 위역사**내외급속송쟁파재실종상해사 우려`,``,`봉흉화길사`,``,`매사 영속성없음**증오가출사 우려`,`종교진출하면자립함**재난해산사`,`매사 영속성없음**송구경황사-통정인나타남`,`종교진출하면자립함`,``,`재물암동사`,``,`도적실세-잡음`,``,`관직 기쁨-상인 흉`,`불측근심사`,`내외 기로출행이동사`,`내외 상하 화합사`,`상대진심가는안됨-情`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**병재`,``,`관재`,``,`빈궁`,``,`전화위복`,`빈궁`,`부부동시색정사`,``,`동요분산사`,``,`전화위복`,`전도**관재**빈궁`,``,`전도**관재**빈궁`,``,``,`음4,5월화재주의**재액해소`,`빈궁**병재`,`음4,5월화재주의**재액해소`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`내외 상하 화합사`,`상대진심가늠안됨-情`,`재물손괴사`,`내외 막힘`,`싫은 일 반복사`,`내외 급속사`,`간음사이익-정대적은 재앙됨`,``,`매사 위역사**내외급속송쟁파재실종상해사 우려`,``,`봉흉화길사`,``,`매사 영속성없음**부부동시색정사**증오가출사 `,`종교진출하면자립함**재난해산사`,`매사 영속성없음**송구경황사-통정인나타남`,`종교진출하면자립함`,``,`재물암동사`,``,`도적실세-잡음`,``,`관직기쁨-상인 흉`,`불측근심사`,`내외 기로출행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁`,`동요불안분산사`,``,`동요불안분산사`,`전도**관재**병재`,`동요불안분산사`,`전도**과재`,`동요불안분산사`,`빈궁`,`빈궁**관재`,`동요분산사**재액해소**전화위복`,``,`동요불안분산사`,``,`동요불안분산사`,`빈궁**관재`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사`,``,`동요불안분산사`,`음4,5월화재주의**동요분산사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`불측근심사`,`내외 기로출행이동사`,`내외 상하 화합사`,`상대진심가늠안됨-情`,`재물손괴사`,`내외 막힘`,`싫은 일 반복사`,`내외 급속사`,`간음사 이익-정대적은 재앙됨`,``,`매사 위역사**내외급속송쟁파재실종상해사 우려`,``,`봉흉화길사`,``,`매사 영속성없음**증오가출사 우려`,`종교진출하면자립함**재난해산사`,`매사 영속성없음**송구경황사-통정인나타남`,`종교진출하면자립함`,``,`재물암동사`,``,`도적실세-잡음`,`관직기쁨-상인 흉`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`빈궁**병재`,`음4,5월화재주의`,`빈궁`,``,``,`전도**관재**병재`,``,`전도`,``,`빈궁`,``,`빈궁**관재`,`재액해소**전화위복`,`부부동시색정사`,``,`동요분산사`,``,`빈궁**관재`,`전화위복`,``,`빈궁**관재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관직기쁨-상인 흉`,``,`불측근심사`,`내외 기로출행이동사`,`내외 상하 화합사`,`상대진심가늠안됨-情`,`재물손괴사`,`내외 막힘`,`싫은 일 반복사`,`내외 급속사`,`간음사 이익-정대적은 재앙됨`,``,`매사 위역사**내외급속송쟁파재실종상해사 우려`,``,`봉흉화길사`,``,`매사 영속성없음**증오가출사 우려`,`종교진출하면자립함**재난해산사`,`매사 영속성없음**송구경황사-통정아나타남`,`종교진출하면자립함`,``,`재물암동사`,`더적실세-잡음`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`관재`,`음4,5월화재주의`,`전도**빈궁**병재`,`음4,5월화재주의`,`전도**빈궁`,`재액해소`,``,`빈궁**병재`,``,``,``,`재액해소`,`빈궁`,`빈궁`,`전화위복`,`부부동시색정사`,``,`동요분산사**재액해소`,``,`빈궁**관재`,`전화위복`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`고위안좌-다중이익전포사-부족`,``,`매사 위역사**굴욕난해사`,``,`주력사 흉-이동사`,`내외 기로출행이동사`,`내외 상하 화합사`,`고시 득길사-미세송사`,`통정인 병질 구설사`,`내외 막힘`,`고위 행길할 때 청원함이 좋음`,`내외 급속사`,``,`간음무도사`,`매사 위역사**내외급속송쟁파재실종상해사 우려`,`재물암동사**남녀직원공모사有`,``,``,`매사 영속성없음**증오가출사 우려`,`종교진출하면자립함`,`매사 영속성없음**절상인구사-가족중불행사`,`종교진출하면자립함`,``,`공공사사구설사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`재액해소`,`빈궁`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도**빈궁`,`재액해소`,`빈궁`,``,``,``,``,`남녀직원공모사**재액해소`,`빈궁`,`빈궁`,`전화위복`,`부부동시색정사**酒食수벌사`,``,`동요분산사**재액해소`,`빈궁**병재`,`빈궁**관재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`공공사사구설사`,``,`고위안좌-다중이익전포사`,``,`매사 위역사`,`굴욕난해사`,`주력사 흉-이동사`,`내외 기로출행이동사`,`내외 상하 화합사`,`고시득길사-미세 송사`,`통정인 병질 구설사`,`내외 막힘`,`고위 행길할 때 청원함이 좋음`,`내외 급속사`,``,`간음무도사`,`**매사 위역사**남녀직원공모은닉사有`,`음인암손사`,``,``,`매사 영속성없음**증오가출사 우려`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁**관재`,``,`전도**관재`,``,`빈궁`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁`,``,``,``,``,`내외급속송쟁파재실종상해사 우려`,`빈궁`,`빈궁`,`전화위복`,`부부동시색정사**酒食수벌사`,``,`재물손괴사-동요분산사`,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자`,`음3,4,6,9,12월방문자종교진출하면성공함`,`공공사사구설사`,``,`고위안좌-다중이익전포사`,``,`매사 위역사`,`굴욕난해사`,`주력사 흉-이동사`,`내외 기로출행이동사`,`내외 상하 화합사`,`고시 득길사-미세 송사`,`통정인 병질 구설사`,`내외 막힘`,`고위 행길할 때 청원함이 좋음`,`내외 급속사`,``,`간음무도사`,`매사 위역사**남녀직원공모은닉사有`,`음인암손사`,``,``,`매사 영속성없음**증오가출사 우려`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재물손괴사-동요분산사`,`빈궁**병재`,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,`관재`,`빈궁`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁`,``,``,``,``,`내외급속송쟁파재실종상해사 우려`,`빈궁`,`전도**빈궁`,`폐한인**전화위복`,`부부동시색정사**酒食수벌사`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자**증오가출사 우려`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`공공사사구설사`,``,`고위안좌-다중이익전포사`,``,`매사 위역사`,`굴욕난해사`,`주력사 흉-이동사`,`내외 기로출행이동사`,`내외 상하 화합사`,`고시 득길사-미세 송사`,`통정인 병질 구설사`,`내외 막힘`,`고위 행길할 때 청원함이 좋음`,`내외 급속사`,``,`간음무도사`,`매사 위역사**남녀직원공모은닉사有`,`음인암손사`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`부부동시색정사**酒食수벌사`,`전도`,`재물손괴사**동요분산사`,`빈궁**병재`,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,`관재`,``,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁`,``,``,``,``,`내외급속송쟁파재실종상해사 우려`,`빈궁`,`전화위복`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  const CHOSA1 = [`■ 땅으로 재판 붙겠네?
  돈도 철저히 깨져버렸어!
  형제의 재액도 생기겠고!
   어떤 일도 방해만 받겠고…    막혔어!

⊕신변상가부정 침범과
⊖신변음사부정도침범했네 ?
`,`■두 사람이 힘을 합해도비용    만 나가지 되는 일이 없겠네?


⊕자살귀, 
⊖가택귀 발동 때문이야!
`,`■ 변동수가 있네? 
   마음이 분주해! 
  그러나 지체되고 있겠어!

⊖가정에 근친상간밀통사가    벌어졌어!
`,`■뜻을 합해도일이 지체되고     관재고심이 생기겠어!, 
⊕⊖가정에 근친상간 밀통사가      벌어 졌겠어? 
    신변과가정에침범,침입한      부정을 잠아야 돼!
⊕신변고모신사부정,침범과 
⊖신변신화선영부정도 침범했어!
`,`■ 二人 동심사로 돈깨나 
   쓰겠는데 결과가 없겠어! ⊕⊖가정에 근친상간 불의
    밀통사만 벌어졌겠어 
⊕자살귀 
⊖흉사귀ㆍ가택상가부정 탓이야!

⊖신변상가부정도 침범했어 ! 
`,`■ 외부 동료사로 불안하고     선택의 기로에 섰는데다     놔야할 운세야!
   인연이 다했어!
⊕음귀, 
⊖원한귀를 해소시키고 때를    기다려봐!
`,`■ 가정 부자간에 등을 돌렸네!     그리고 일도 두 사람의 동심     사는 기대가     안돼! 
   비용 아끼는게 상수야!     일을 직접하지 말고 타인을     앞세워야 되겠어!
⊕가정에 근친상간 불의밀통    사가 벌어져 있네!
`,`■이권을 상실하게 생겼네?    금전 여자 때문에 기로에    섰어!
관직자:子년월에 소원 이루겟어!
⊕수귀, 
⊖전흉사귀 때문인데 해소
  해야 좋아져!
`,`■ 여러 사람한테 공격받게     생겼네? 二人협력도 효     과가 없겠어 !

⊖男:타인 부인과의불의밀통       사로 망신당하게 생겼어!
⊕수귀ㆍ가택음사부정 
⊖전흉사귀가 급해!
`,`■집안에 
⊕저주원심, 
⊖가택여고음강 부정이 
  잔뜩 끼었으니 되는게 있겠어?
  비용만 나가고 말지!
  기도ㆍ치성을 해야 길이     보이네! 
⊖집안에 근친상간사가 걱정이야 !
`,`■ 외쪽 다리로 달리는 격일세?     두 사람이 아무리 머리를     짜도 어려워!
   가정에 근친상간 불의밀통     사가 벌어져 있고…
`,`■ 일한다고 두 사람이 힘을     모았겠는데 비용만 나가     고 일은 안되고. 
   지체되고만 있겠어 !`];
  const YOGYEOL1 = [`■ 나와 상대가 서로 만났으나 반목하다 끝내 싸운다. 혼자 서 있는 것과 다름없으니 낮정단은 범이 가득하여 병 걱정이다.
  `,`■ 군인이나 군대 관련된 일은 그런대로 좋겠다. 
  낮정단은 녹에 천공이 타서 병점에는 사망이 두렵다.
  `,`■ 깨지고 비어있으니 돈 없애고 곤궁해진다.
  정마와 역마가 많아 한 세상 풍운아처럼 살겠다.
  `,`■ 재물신이 입을 다물었으니 돈이나 여자는들어 오지 않는다.
  묘가 네 개이고 구진이 타서 일은 더디고 답답하겠다.
  `,`■ 밤정단은 매우 놀래는 일이 생기겠고 삼전이 모두 관살로 변하니 관직자나 일반인이나 모두 안좋고 나쁜 일이 있겠다.
  `,`■ 귀신이 귀문에서 묵고 있으니 병점은 혼이 몸을 떠나겠다.
  의사나 약을 믿지 말라.
  오히려 재앙의 뿌리이다.
  `,`■ 역마 정마가 초전에 있어 움직일 뜻을 버리기 어렵다. 빈 것을 지키거나 끌어당기니 점점 가난할 것을 주의하라.
  `,`■ 재물은 이미 둘다 비었다. 돈 벌러 나서야 허사만 만난다. 말전에서 관살이 돕는척해도 흉을 용납하기 어렵다.
  `,`■ 삼전이 삼합하여 관살로 뭉쳤다. 분수 밖의 재물이나 여자는 아깝더라도 취하지 말라.
  `,`■ 한 여자를 두고 남자 넷이 경쟁한다.
  싸움은 끝나지 않고 관재가 되고 형벌이 생기겠다.
  `,`■ 외발로 걷게 되니 사기ㆍ손해ㆍ실패가 여러 번이다.
  배ㆍ차ㆍ항공기는 좋고 도망간 여자나 종업원은 돌아오겠다.
  `,`■ 잔나비는 자손이고 또 장생(水土同根)도 된다. 까마귀처럼 효도하는 자손이 있고 밤정단은 귀인의 영화가 있다.`]
  const SIBO1 = [`■ 진퇴가 곤란하고 매사
  방해가 있다.
  백가지의 꾀도 졸책이
  되고 만다. 신음한다.
  놀라거나 실패의 좌절이 있게 된다.
  구태를 지키고 망동하지 않으면 재해를 방지할 수 있다.
  기도ㆍ치성하라!
  형제에 凶害가 기함.
  `,`■ 피아동심으로 합력하나
  여의치 않고 여자는
  색정사가 있고 부동산
  이나 교통 노상사에서 효과를 본다.
  부부간에 등지고 종업원은 주인과 통정한다.
  ⊕근친상간, 불의 밀통사가 있다.
  형제에 凶害가 기함.
  `,`■ 변동수로 분주하다.
  구태를 중시하고 신규사를
  폐지하고 사용하지 않는다.
  ⊖불의밀통ㆍ근친상간사가 있다.
  재물을 소모하고 빈궁에 이를 우려가 있다.
  형제에 凶害가 기함.
  청숙과 수신해야 해가없다. 도로사로 서서히 발복한다.
  `,`■ 동정이 엇갈려 신변이
  부유하고 있다.
  피신하여 신불에 기도
  하면 좋고 자리를 지키면 재해가 있게 된다.
  금전 수입의 기대가 안되고 쟁재사가 일어난다. 
  변명할 사유가 아니다. 
  근친상간불의밀통이 있다.
  `,`■ 자충수적 우환이 있고
  가장을 속이며 가인이
  원한을 품고 가해하려
  한다. 관ㆍ병이 흉하다.
  二人 동심사가 있고, 비용만 축나고 일은 안된다. 
  부부가 음탕하다.
  불의밀통ㆍ근친상간사가 있다. 인택이 쇠퇴한다. 
  돈과 여자를 취하지 말아야 하고 후일 후회한다.
  `,`■ 자충수적 우환이 있고
  가장을 속이며 가인이 
  원한을 품고 가해하려
  한다. 모망사는 순조롭지 못하고 부부는 음사에 빠져있다. 복록과 직위와 권리가 정지되는 운세이다. 자손에게 해가 있게 된다.
  `,`■ 저주사가 있다.
  내외에 괴이사가 기하고
  상하는 불안하다.
  일은 직접 나서지 말고 남을 앞세워 은밀히 진행해야 이롭다.
  대사는 작게 되고 소사는 불발된다. 
  ⊕불의밀통사가 있다.
  어떤 일도 변덕스럽다.
  형제에 凶害가 기함.
  `,`■ 가정과 신변에 이권상실사가
  있다.
  만사 공허하고 규모를 
  이룰 수 없다.
  길사는 불성하고 흉사는 불현한다.
  금전․女人에 의한 기로에 있고 의심ㆍ질투가 있다.
  기도해야 한다. 
  正月 임신사가 있다.
  `,`■ 재물과 여자가 눈앞에
  있으나 이것을 취하게
  되면 후일 관재가 일어나
  후회한다. 관직사는 좋다.
  인택 모두 재화가 있다.
  ⊖남자가 타인의 부인을 유혹한 불의밀통사가 있다.
  신불에 기도하면 후일 공명이 있게 된다.
  
  `,`■ 인ㆍ택 모두 어둡다.
  재사가 있으나 쟁탈된다.
  이 재를 취하면 관재의
  원인이 된다.
  진퇴에 고민이 있고,
  ⊖인도에 어긋난 근친상간 불의밀통사가 있다. 계속되면 화환이 초래된다.
  기도가 좋다.
  `,`■ 인택에 손모가 많고
  쇠미하다.
  만사가 이롭지 않다.
  외다리로 달리는 형국이므로 행동이 부자유하고 조용히 자리를 지켜야 한다.
  불의밀통ㆍ근친상간사가 있다.
  `,`■ 
  가정에 혼인경사 축하연이 있다. 또는 식료점포를 경영한다.
  현직자는 직위해탈사가 기한다. `]
  const SIBO2_1 = [`■ 손모ㆍ질병내외사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  상호 허를 찌르는 토지 관송사가 있다.
  `,`■ 官ㆍ夫내외퇴폐사이다.
  官･夫,내방조짐 상의 :  
  조용히 있는게 이롭다.
  가택권리가 양도되고 내심 변동한다.
  `,`■ 손모내외동요사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  손재·녀하고 형제 재액이 있다
  `,`■財ㆍ女,쟁투사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  관재 고심이 있다.
  `,`■官･夫,번잡내외사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다. 병재·관재 예방이 필요하다.
  `,`■ 자손ㆍ박관후원사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 사업ㆍ후원변동사이다.
  내방조짐 상의 : 
  원행·이동사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 사업ㆍ후원변동사이다.
  내방조짐 상의 :  
  원행·이동사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 財ㆍ女,내외사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  병재·관재 예방이 필요하다.
  `,`■財ㆍ女,쟁탈손모사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  불화하고 무례의 상이다.
  `,`■ 자손ㆍ박관손모사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 손모내외사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  身宅이 쇠폐하는 상이다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/056/row-1-column-1.png`,
    `/static/images/sagwa/056/row-1-column-2.png`,
    `/static/images/sagwa/056/row-1-column-3.png`,
    `/static/images/sagwa/056/row-1-column-4.png`,
    `/static/images/sagwa/056/row-1-column-5.png`,
    `/static/images/sagwa/056/row-1-column-6.png`,
    `/static/images/sagwa/056/row-1-column-7.png`,
    `/static/images/sagwa/056/row-1-column-8.png`,
    `/static/images/sagwa/056/row-1-column-9.png`,
    `/static/images/sagwa/056/row-1-column-10.png`,
    `/static/images/sagwa/056/row-1-column-11.png`,
    `/static/images/sagwa/056/row-1-column-12.png`,
  ]
  

  const HEASYEOL = [
    // 1
    `  이 과는 일간기궁이 미(未)이고 일지가 미(未)이며, 간지(干支)가 서로 만나서 4과 모두 미(未)의 1신뿐으로 다른 신(神)은 없다. 삼전은 미축술(未丑戌)의 토신(土神)이니 가색격이 되며, 중전의 축(丑)은 공망이 되어 단교공망(斷橋空亡) 또는 요절공망(腰折空亡)이라고도 한다. 무슨 일이나 중도에 방해나 좌절 있다. 사과에 1신뿐이면 독족과(獨足課)와 비슷하여 삼전 내는 전전상형(轉輾相刑)해서 무슨 일이나 화기가 없고 순조롭지 못하다. 모두 전후, 진퇴 곤란의 과로서, 먼저 얻지만 후에는 잃는다. 독족격과 유사하여 움직이지 못하는데, 전진하지 못하다가도 만약 년명 사(巳)의 시(時)가 정마로 되면 움직임이 있다.
    비전에 이르기를 ｢일간상신에 백(白)․사(蛇)가 타면 흉신이 택에 들어간다고 하여 놀랄 일 혹은 실패의 건이 생긴다｣고 하였다. 간지(干支) 토(土)의 일자는 백모술책(百謀術策)을 한 가지도 성공하지 못한다. 일귀는 삼전이 순행하여 백․사․음이고, 야귀는 역행하며 삼전은 사․백․음의 흉장이다. 삼전에 사․호(虎)가 승하는 일이 있으며 놀라움을 면할 수 없다. 일귀는 백호가 세 곳에 있고 질병 혹은 소송사 모두 흉해가 있다.
    일반적으로 월장(月將)은 점시(占時)에 가하여 십이지신(十二支神)이 모두 본궁에 있고, 일간지의 음양은 모두 복(伏)하여 움직이지 않으니 홀로 신음한다. 정단사는 정중동(靜中動)이니 구태(舊態)를 지키면서 새것을 기다리는 과이다. 무슨 일이나 길을 구하여 병환 재액이 되고, 사계는모두 흉이다. 따라서 몸을 삼가고 동작이 경거망동하지 않으면 재화를 방지할 수 있다. 만약 자기를 돌보지 않고 강폭할 때는 스스로 재앙을 부른다.`
    ,
    // 2
    `  이 과는 4과이지만 2과밖에 없고 또한 극적법칙(剋賊法則)에 해당하지 않기 때문에 팔전과유박격(八專課帷薄格)이 된다. 제1과․제2과와 제3과․제4과가 같고 극이 없을 때는 양일은 순(順), 음일은 제4과의 역(逆)의 3신을 취하여초전으로 한다. 이 과는 사(巳)의 2과이기 때문에 역의 3신을 취하여 초전으로 한다. 팔전(八專)이란 일간일지의 음양이 모두 동일하여 팔가동병(八家同倂)이라 하며, 피아동심(彼我同心)이라고도 한다.
    양일은 남자에게 이롭다. 음일은 여자에게 색정의 일이 생기나. 물건은 안에서 찾으며, 부동산이나 길에서 공을 이룬다. 무슨 일이나 양기(兩岐)에서 결정한다. 양일은 존장이 하인(下人)을 기만하고 무슨 일이나 전진하는 것은 신속하다. 이 과와 같이 음일은 부처지간에 서로 등지고 고용인은 주인과 통한다. 음은 유(柔)가 되고 사물이 퇴축(退縮)하여 주사(主事)가 지체된다. 점혼(占婚)은 구설이 있고 분리된다. 길신․길장이 타면 길조로 된다.
    제2국은 천지반에서 술(戌)이 해(亥)에 가담하여 괴(魁)가 천문에 건넌다고 칭하며, 정단사는 방해가 생긴다. 오(午)에 주작이 승하는 것은 진(眞)을 얻는다고 하여 관에 상신사(上申事)는 모두 길조이다.팔전은 피차 동일한 권리이므로 존비동실(尊卑同室)하고 예(禮)를 잃는다. 일귀는 삼전이 순포(順布)하여 합․공․공으로 되고, 야귀는 역행하여 삼전은 청․주․주이다. 문서사(文書事)나 입학 시험 등은 길조를 얻는다. 혼인은 모두 불길하고, 병단 역시 좋지 않다.`
    ,
    // 3
    `  이 과는 팔전과의 유박격(帷薄格)에 해당한다. 초전이 공망이면 과숙(寡宿)이라고 한다. 정신․역마가 과전에 모여 이동이 분주하다. 초전의 축(丑)은 파쇄살(破碎殺)이 공망이 되고, 일귀는 사(蛇)이며 야귀는 백호의 흉신을 보면 재물을 소모하여 곤궁(困窮)에 빠진다. 
    운명을 보면 일평생 이동할 일이 생긴다. 
    유박불수격(帷薄不修格)은 총체적으로 정확하게 자신을 지키며 가정은 청숙(淸肅)하여 해가 없다. 청룡이 일간일지를 생하니 서서히 발복하게 된다. 군자는 시사(施舍)하기를 좋아하고 사람들을 구제한다.
    비전에 이르기를 ｢중․말전의 자신이 일간일지상신이 되면 구태(舊態)를 중시하므로 신규(新規)를 폐하고 사용하지 않는다｣고 하였다. 사(巳) 혹은 해(亥)가 역마로 되면 도로상에서 분주한 일이 있고, 정신이 일간기궁 일지 미(未)를 생하면 처음에는 곤란이 있지만 후에는 쉽게 된다. 
    일귀는 순행하고 삼전은 사․청․청이며, 사가 청으로 화하니 관공직은 모두 취직이 가능하게 되며 현직은 승진(昇進)의 기쁨이 있고, 입학시험은 합격이 가능하다. 야귀는 역포(逆布)하고 삼전은 백․합․합이다.`
    ,
    // 4
    `  이 과는 4과에 상극이 없고 2과밖에 없기 때문에 팔전과로 된다. 극이 없기 때문에 제4과의 역행삼신(逆行三神)에 해당하는 해(亥)를 초전으로 되고 일간일지상신의 진(辰)을 중․말전으로 한다. 간상에 진(辰)자가 있고 삼전 중에 진(辰)자가 있으니 참관격이다. 이 격은 진술(辰戌) 중의 어느 하나가 일간일지상신에 가담하여 삼전에 드는 것을 말하다.
    진(辰)은 천강이라 부르고 술(戌)은 천괴(天魁)라 부른다. 일간일지는 그 사람이다. 괴강(魁罡)은 천관(天關)이라 한다. 일간 일지상에 가담되고 사람은 흉신을 만나니 토(土)가 무거워 폐색(閉塞)하기 때문에 통행하려면 반드시 관문(關門)을 열어야 한다. 그러므로 참관격이라 명명한다.
    인(寅) 혹은 묘(卯)의 목(木)이 있어서 토(土)를 극할 때는 삼천(三天)이 모두 움직인다고 한다. 도망․출행은 좋다. 도둑은 붙잡기 힘들고 병환과 송사는 흉하므로 신불(神佛)에 기도하는 것이 좋고, 도피(逃避)하는 것이 좋다. 만약 집에 있으면 가중에 재화가 발생한다. 삼전 중 백호(白虎)․태음(太陰)이 신유(申酉)에 있고 혈지(血支)․혈기(血忌)․양인신음(羊刃呻吟)등의 삼살을 띨 때는 반드시 사람을 손상하고 도주한다.
    초전 해(亥)는 갑인(甲寅)의 순중(旬中)의 순미(旬尾)로 되고 폐구격(閉口格)이 된다. 해(亥)는 재성(財星)이다. 재(財)가 폐구(閉口)하니 입수수재(入手收財)가 불가능하다. 과전 진(辰)자가 사중(四重)으로 있으므로 무슨 일이나 혼미상(混迷狀)을 이루고 명랑성이 없다.
    비전에 이르기를 ｢일간일지상신에 묘신이 복(伏)할 때는 마치 구름과 안개 속에서 걷는 것과 같다｣고 하였다. 또 이르기를 ｢진(辰)의 천강이 오(午) 혹은 미(未) 위에 승하면 사람을 부르면 반드시 온다｣고 하였다. 팔전과로서 사과삼전(四果三傳)에 비견․겁재가 군집하여 초전의 재를 빼앗으니 쟁재(爭財)가 일어난다. 일귀는 순포하고 삼전은 후․구․구이다. 야귀는 삼전이 합․상․상이다. 일체의 소송은 하지 말아야 하며 원죄(寃罪)를 만날 수 있다. 폐구격은 입을 다물고 혀를 움직이지 못하는 상이다.`
    ,
    // 5
    `  이 과는 묘미(卯未)가 1상이 1하를 극하여 원수과로 된다. 삼전 묘해미(卯亥未)는 삼합목국하여 곡직격이다. 먼저 굽고 후에 곧게 된다. 일간일지상신이 귀살이 되어 내몸이 극을 받기 때문에 움직이는 것이나 모사(謀事)는 불리하다. 
    중전의 해(亥)는 재(財)이지만 편관(偏官)을 도우니 귀력(鬼力)이 강하게 되어 재앙의 원인이 된다. 그러므로 이 재(財)는 취하지 말아야 한다.
    일간은 사람, 일지는 택이다. 인택은 모두 병(病)이 되고 귀(鬼)로 되기 때문에 인택은 모두 쇠퇴한다. 삼전목국이므로 공명을 정단하면 길일 것 같지만, 삼전사과가 순음(純陰)이므로 사물이 비밀스러운 것을 꺼리고 정대한 행동에 유리하다. 
    일귀는 순행하고 삼전은 합․후․백이며, 야귀는 삼전이 백․합․후이다. 
    묘(卯)는 을(乙)의 둔간(遁干)으로 되기 때문에 명암(明暗)의 이귀(二鬼)로 되니 병간(病看)은 가장 좋지 않다.`
    ,
    // 6
    `  이 과는 목극토(木剋土)와 금극목(金剋木)으로 2상이 2하를 극하지만 어느 것을 사용하는가는 분명치 않다. 이와 같은 경우는 기토(己土)가 음일이기 때문에 음의 극이고, 유인(酉寅)의 극, 즉 금일(金日)은 음일이므로 음의 극이다. 유인(酉寅)을 사용하여 초전으로 하고 지일과(知一課)라 한다. 
    인(寅)은 귀문(鬼門), 미(未)는 귀숙(鬼宿)이라 하여 인미(寅未)가 서로 가담하면 이혼격(離婚格)이라 하는 데 병간(病看)은 가장 흉하다. 4상에서 4하를 극하면 사절격(四絶格) 혹은 무록격(無祿格)이라 하여 모든 모사는 순조롭지 못하다.
    백법에 이르기를 ｢간지가 완전히 극상(剋傷)이 되면 양쪽 다 소모하며, 부부는 서로 음사(陰邪)에 흐르기 쉽다｣고 하였다. 4상이 4하를 극하여 무록격이 된다. 음양이 모두 상극하니 방법이 없다. 정단의 건은 고독한 것이 많고 복록(福祿)이 없으며, 관공직에 있는 사람은 감봉(減俸)․정직(停職) 등이 있다.    상이 하를 극하니 자녀에게 재해가 되며, 일은 구부러져 펴기 어렵다 또 대수(對數)는 상대방이 이기며, 상에서 하를 극하여 무록격이 되고, 4하에서 상을 극하여 절사(絶嗣)로 된다. 어느 것이나 상하 불화, 주종 분리(主從分離)하여 모사를 이루지 못하고 동작에는 의혹이 많다.
    천지반중 오(午)에 천공이 승하면 천공의 유신은 모두 길조가 된다. 예를 들면 입학 시험이나 손윗 사람의 면접 및 선거에서의 당선 등이다.
    일귀는 역포하며 삼전은 합․상․사이고, 야귀는 순행하며 삼전은 사․상․합이다. 일귀는 육합이 유(酉)에 타서 다투게 되며, 야귀는 유(酉)에 사신(蛇神)이 타서 괴이(怪異)한 일이 생긴다.`
    ,
    // 7
    `  천지상충(天地相冲)하면 반음과(返吟課)이다. 4과의 상하에 극이 없기 때문에 음일은 음신 미(未)이고 역(逆)의 사(巳)를 초전으로 한다. 해(亥)의 상신 사(巳)를 취하여 초전이 되면 정란사격(井欄射格)이라 한다. 우물(井) 곁에서 그를 충하여 맞추므로 이렇게 부르는 것이다.
    거래(去來)가 없고 진(辰) 상신에 의지하므로 일신양용(一身兩用)의 체이다. 대인(待人)은 저해가 있다. 내외에 괴이한 일이 많고 상하가 불안하다. 일은 측면에서 구하여야 하며 바로 구하면 괴로움을 받는다. 즉 무슨 일이나 암암리에 거래를 하는 상이다. 길흉은 신장(神將)에 따라서 한다. 이 과는 동(動)을 주관하지만 공망이 되기 때문에 동론(動論)을 응용하지 못한다.
    정란사격(井欄射格)은 무친(無親)이라고도 한다. 극(剋)이 없어 지신(支神)으로 비스듬히 쏘기 때문에 정란사(井欄射)라 한다. 무슨 일이나 빨리 성사되고 파탄되기 쉬운 과격이다. 초전은 사(巳)의 역마가 순정(旬丁)이 되어 초전에만 동의(動意))가 있고, 중․말전에 공망이 된다. 사과삼전에 공망이 많기 때문에 무슨 일이나 지키는 것이 좋고 활동해도 소용이 없다. 정란(井欄)은 우물 위에 나무로 만든 것이기 에 오래 가지 못한다. 일이 많으면 종첩되어 우려와 기쁨이 모두 온다. 대사(大事)를 계획하면 작게 변화하고, 소사건은 공허하게 끝이 난다.
    백법에 이르기를 ｢초전의 일신이 실(實)이면 초전으로 길흉을 볼 수 있다｣고 하였다. 비전에 이르기를 ｢축(丑)에 미(未)가 가해지고 미(未)에 축(丑)이 가해져 년명(年命) 혹은 일간에 있을 때는 시험사(試驗事)는 필중(必中)한다｣고 하였다. 해(亥)의 재신을 사토미축(巳土未丑)에서 지재(地財)로 하면 길 중에 재(財)가 흩어지기 때문에 무친(無親)이라 한다. 반음(返吟)은 무슨 일이나 변덕스러운 상으로 우울(憂鬱)한 상이다. 일귀는 역행하며 삼전은 백․후․후이고, 야귀는 순행하고 삼전은 현․청․청이다. 용신이 겹쳐 있어도 공망하니 기쁨을 누리지 못한다.`
    ,
    // 8
    `  이 과는 4하(下)가 4상(上)을 극하고 음일이기 때문에 음의 극인 사자(巳子)를 초전으로 하고 지일과(知一課)로 된다. 4과가 모두 공망하고 초전 정마 역시 공허(空虛)를 만난다. 자(子)의 수재(水財)는 신택(身宅)의 위에 있지만 공망하여 용을 이루지 못한다. 초전에 백호․현무가 승하지만 이 역시 공망되어 흉화가 자연히 해소된다. 
    술묘(戌卯)는 주인격(鑄印格)이지만 초전이 공망되어 규모(規模)가 되지 않는다.
    관점(官占)은 길흉이 모두 없다. 염막귀신(簾幕貴紳)이 일간에 임하면 입학 시험은 필중(必中)한다. 자(子)의 공망이 많지만 년명상신이 자(子)이기 때문에 전실공망(塡實空亡)이 된다. 전실공망은 공망이라 하지 않으니 입학 시험은 필중한다. 
    일귀는 역행하며 삼전은 백․주․현이고, 야귀는 순행하며 삼전은 현․주․백이다. 이 과는 공명의 건을 제외하고는 일반적으로 길조로 보지 않는다.`
    ,
    // 9
    `  이 과는 토목(土木)에서 해(亥)를 극하여 중심과이며, 삼전목국은 곡직격이다. 4과 중에 묘(卯)의 귀살(鬼殺)이 두 곳에 있고 삼전중에 또 묘(卯)의 목곡(木曲)이 있다. 그러나 삼목(三木)이 나란히 서고 삼전이 회합하여 목귀(木鬼)로 되니 일간을 손상한다.
    순미(旬尾)의 해(亥)가 초전이니 폐구격(閉口格)으로 되어 눈앞의 재물을 버릴 수는 없으나 이 재는 귀(鬼)로 화하므로 이 재를 얻으면 재(災)의 원인이 된다. 묘(卯)에 을목(乙木)이 둔간(遁干)으로 되어 명귀(明鬼)와 암귀(暗鬼)가 간(干)을 극한다. 야귀는 백호가 승하여 인택이 모두 재화를 만난다. 관직은 관직의 유신이 강하여 춘월에 정단하면 매우 양호하다.
    일귀는 역포하여 삼전은 사․현․청이고, 초전의 사(蛇)가 말전의 청(靑)으로 변화하므로 현임현직(現任現職)은 반드시 승진하며, 입학 시험은 반드시 합격된다. 장래의 사업에서도 자연히 새롭게 공명을 날린다. 
    야귀는 순포하고 삼전은 합․백․후이며, 교동격(狡童格)으로 백법의 자유 연애 결혼에 해당한다.`
    ,
    // 10
    `  이 과는 4과 중에 상하의 극이 없고 음일(陰日)이기 때문에 제4과 지신(地神)의 역삼위(逆三位)인 해(亥)를 발용하고 일간상신이 삼전 중에 있어 참관격이 된다. 초전의 해(亥)는 일간의 수재(水財)이고 그 밖에는 비견․겁재뿐이니 군집(群集)한 비겁이 재를 쟁탈하는 모양이다. 
    참관격은 도망에는 이롭지만 복닉(伏匿)에는 이롭지 않다. 귀신이 묘유(卯酉)에 임하면 여덕격(勵德格)이다. 군자(君子)에게 이롭고 하급인에게는 유익하지 않다.
    비전에 이르기를 ｢초전 해(亥)의 재(財)는 일귀에 사신이 승하고 야귀에 현무가 승하니 재를 취득하지 말아야 한다｣고 하였다. 후일에 일어나는 재해의 원인이 되기 때문이다. 일귀는 역행하며 삼전은 사․주․주이고, 야귀는 삼전이 현․음․음이다. 삼전 술(戌)과 해(亥)는 야간의 지(支)에 해당하니 공명을 정단하면 좋지 않다.
    여덕격은 천을귀신이 묘(卯) 혹은 유(酉)에 임하는 것을 말한다. 묘유(卯酉)는 태양 교체의 시에 해당한다. 가정이나 가문에 해당하는 귀신문호(貴神門戶)에 임할 경우에는 진퇴에서 동요한다. 귀인의 앞에 서면 소인은 세(勢)를 믿고 삼가지 않는다. 
    사람이 인도(人道)를 지킬 때는 복선(福善)이 되고, 도(道)가 문란해지면 화환을 초래한다. 그러므로 덕행을 장려한다는 뜻에서 여덕격이라 명명한 것이다. 소인이 이 격을 얻으면 사대(四大)가 평안하지 못하다. 직장에 있는 사람은 퇴직할 우려가 있고, 귀천존비(貴賤尊卑)의 구별을 교란시키니 조상신에게 감사를 드리면 평안하게 된다.`
    ,
    // 11
    `   이 과는 팔전과이다. 상하에 상극이 없기 때문에 음일은 음신 해(亥)의 역삼위(逆三位) 유(酉)를 초전으로 하고, 삼전이 모두 유(酉)자이므로 독족격이라 한다. 720과 중 본과 1국이 있을 뿐이다. 
    일간에서는 유(酉)는 토생금(土生金)으로 탈기(脫氣)되어 기(氣)가 몹시 무겁다. 따라서 만사가 이롭지 않다. 독족(獨足)이기 때문에 행동이 자유롭지 못하니 조용히 자리를 지키는 것이 현명하다. 
    일귀는 역행하며 삼존은 합․합․합이고, 야귀는 삼전이 후․후․후이다. 
    천장은 모두 음사애매(陰私曖昧)의 신뿐이고 유박불수격(帷薄不修格)과 비슷한 색정의 과이다. 일귀의 육합은 주차(舟車)의 유신이고 독족은 보행이 자유롭지 못하기 때문에 주차(舟車)로 여행하면 좋다.`
    ,
    // 12
    `  이 과는 4과에 극이 없고 음일이므로 음신지지(陰神地支) 유(酉)에서 역삼위(逆三位)인 미(未)를 사용하여 초전으로 하는 팔전유박격이 된다. 임학(壬學)의 수토(水土) 장생의 법칙에 따라 기토(己土)에서 신(申)을 보면 장생의 설기로 되나. 자손은 그 부모를 잘 부양하고 부모의 은덕에 보답하나. 야귀는 신(申)에 귀인(貴人)이 타서 일간을 생하니 더욱 영창(榮昌)한다.
    일간에서 보아 초전은 비견이다. 사․호가 타서 놀라운 사건이 생긴다. 
    야귀는 태상(太常)의 길장이 신(申)에 타서 장생(長生)이 되며, 가정에 결혼 축연의 기쁨이 있다. 혹은 식료(食料) 점포(店鋪)를 경영한다. 미신(未申)․신유(申酉)와 지반의 지지의 다음이 천반으로 되면 나망격(羅網格)으로 모사는 졸책(拙策)이 되며 방해가 생긴다.
    염막귀신(簾幕貴神)이 승하면 시험사(試驗事)는 필중한다. 과전에 탈설(脫洩)이 모이면 큰 돈이라도 소모 또는 산실될 위험이 있다.
    일귀는 순포하며 삼전은 백․상․상이고, 야귀는 역행하여 삼전은 사․귀․귀이다. 식신상관(食神像官)이 모여 관직을 극하니 구직은 불가능하고, 현직에 있는 사람에게는 고난사(苦難事)가 있다. 또는 실직(失職) 전직(轉職)할 일이 있다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
