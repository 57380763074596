
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,5,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,4,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,3,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,11,2,`특별있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,1,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,1,12,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,2,11,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,3,10,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,9,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,5,8,`특별있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,6,7,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`불성`,7,6,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`3`,`고위접인`,`구설소제`],
    [`단합`,`내부가정 단합사`,`大`,`학습기`,`외사수난`,`가정단합`],
    [`수귀구재(守貴求財)`,`금전을 구하건대 체면(자존)를 잃지 않아야만 가능한 상`,`大`,``,``,``],
    [`동지함의(動止咸宜)`,`안방을 굳게 지킴으로서 행동이 자유로운 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`2`,`도난관련`,`왕진쇠퇴`],
    [`여인`,`여인 관련사`,`小`,`면모기`,`금전사업`,`파재극처`],
    [`이공불사(利公不私)`,`공적인 일은 이익이 되고 사적인 일은 이익이 안되는 격`,`小`,``,``,``],
    [`도난 유의(盜難有意)`,`공적 일로 금전의 원조를 받으나 도난이 우려되는 상`,`大`,``,``,``],
    [`명예`,`명예직녹자산권리사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`1`,`사통有`,`사통有`],
    [`매사 영속성없음`,`명예직녹자산형제사는 선천적 숙명재해를 겪음-독신고독`,`小`,`절정기`,`허성불성`,`출행이동`],
    [`가필볼완(家必不完)`,`반드시 가택 가옥에 불완전한 것 또는 사통사가 있는 상`,`大`,`세속허사`,`외교막힘`,`고위접인`],
    [`주유가음(主有佳音)`,`반가운 소식을 듣는 격-부부가 서로 사정을 갖는 상`,`小`,`자로무망`,`도둑의혹`,``],
    [`결속`,`결속으로 음1월 財의 기반을 크게 세우나 직위남편 훼탈됨 `,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`12`,`약속누설`,`비상지애`],
    [`매사 영속성없음`,`형제자매친구동료공동사는 선천적 기복재해를 겪음`,`小`,`과시기`,`색정사有`,`손해가출`],
    [`합중범살(合中犯殺)`,`서로 좋은 중에 해로움을 당하게 되는 상`,`大`,``,`관재질병`,``],
    [`자불검약(自不檢藥)`,`비밀 약속을 어겨 추궁을 받는 상-신분 낮음`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업관련 침체공허사`,`大`,`11`,`전체재난`,`굴욕반복`],
    [`처`,`처 관련사`,`小`,`쇠퇴기`,`쇠퇴침체`,`가정막힘`],
    [`피난도생(避難逃生)`,`재난을 피하여 기회를 맞게되는 상-죽다 살아나는 격`,`小`,``,``,``],
    [`탄식신음(嘆息呻吟)`,`병으로 오래 누워있거나 도모사 미완으로 탄식하는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`10`,`급속송쟁`,`약속누설`],
    [`자녀`,`자녀 관련사`,`小`,`침해기`,`면모훼손`,`강제급속`],
    [`치간불비(治肝不脾)`,`간장은 치료가 되나 비장은 치료가 안 되는 상`,`小`,``,`전체재난`,`손실사有`],
    [`생살대권(生殺大權)`,`官者-생살대권-평민 형벌대흉-단50,62,74,86,98,2010출생`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편(남자) 갈등사`,`大`,`9`,`고위연회`,`관재질병`],
    [`자녀`,`자녀 관련사`,`小`,`소외기`,`결속단합`,`소외수난`],
    [`자심재화(自甚災禍)`,`본인이 쇠잔하여 화가 심한 격`,`小`,``,`외화내허`,`타인受惠`],
    [`연회실사(宴會吉事)`,` 고위인의 잔치에 초대되어 열석하게 되는 상-단 부족`,`小`,``,``,``],
    [`후원`,`결속사로 후원사를 크게 이루나 자녀災害가 발생함`,`小`,``,``,``],
    [`사업`,`사업 침체사`,`大`,`8`,`탄식신음`,`전체재난`],
    [`여인`,`여인 관련사`,`小`,`침체기`,`소외침체`,`비용과출`],
    [`상가손불(上可孫不)`,`윗사람은 후원조력사로 좋으나 자손사는 좋지 않은 상 `,`小`,``,``,``],
    [`총시성재(總是成災)`,`여기저기 모두 재난 뿐인 상`,`大`,``,``,``],
    [`관사`,`관사 침해사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,`출행이동`,``],
    [`동요`,`부부이심 동요불안분산사`,`大`,`단절기`,`부부이심`,`타인施慧`],
    [`우희상의(憂喜尙疑)`,`좋고 기쁜 일이 있으나 의혹이 드는 격`,`小`,``,`동요분산`,`고위연회`],
    [`총시성재(總是成災)`,`이리저리 모두 재난이 우려되는 상`,`大`,``,`구설놀램`,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업직위사`,`小`,`6`,`위례득죄`,``],
    [`남자`,`남자-색정사`,`大`,`존재기`,`손해도망`,`색정화합`],
    [`가택불녕(家宅不寧)`,`가택에 괴이한 일들이 발생하여 편안하지 못한 상`,`小`,``,`의혹우려`,`신물受損`],
    [`위례득죄(違禮得罪)`,`반목대립으로 예의를 크게 잃어 죄에 이르는 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`5`,`금전관련`,`공허침체`],
    [`처`,`처 관련사`,`小`,`배양기`,`타인압박`,`동요불안`],
    [`졸책재난(拙策災難)`,`모사가 졸책이되어 금전이 자유롭지 못한 상`,`小`,``,`구설소제`,`분산사有`],
    [`위례득죄(違禮得罪)`,`반목대립으로 예의를 크게 잃어 죄에 이르는 상`,`大`,``,``,`관길민흉`],
    [`후원`,`후원조력 개시사`,`小`,``,``,``],
    [`급속`,`외사 급속사`,`小`,`4`,`왕진쇠퇴`,`위례득죄`],
    [`부모`,`부모 관련사`,`大`,`개시기`,`후원조력`,`면모훼손`],
    [`부취영신(俯就榮身)`,`관공직에 취임하여 영화를 누리게 되는 상`,`大`,``,`愛憎화합`,``],
    [`왕진쇠퇴(旺進衰退)`,`세가 있으면 나아가고 약하면 물러나는 것이 유리한 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,`후원문서부모조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,`금전사업여인조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`금전사업처조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,``,`직업직위남편가등송쟁사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,``,`직업직위남편갈등이심사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`금전사업여인조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사직업지위부부이심분산사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`직업직위가정색정사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`금전사업처조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`후원조력문서부모조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`일이 거꾸로 진행되어 지체됨**소극적 방침이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 거꾸로 띄엄띄엄 진행됨**내외 암울함**입을 닫음**일이 결과없이 반복되기만하나 차차 밝아짐**경쟁사가 있어 적선적덕으로 인심을 얻어야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 세**만약 타인과 다툰다면 상대는 호가호위 세를 보이는 것이므로 일시 경과하면 자연 사라짐`,0,0,`옳지 않은 일이 들통나 천둥에 놀라듯하나 언어를 조심하고 근신하면 두려움이 자연 사라지게 되는 人`,0,0,`신규사가 있음**큰 일은 삼가해야함**불화가쇠의 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人-직업직위권리 상실 우려`,0,0,`여러 일을 하나로 결속시키는 단합사가 있음-단 외화내허적임**타인의 부인을 유혹하여 자기 여자로 사용함**독신고독각방주말부부생리사별적 처지임**산속으로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 세**만약 타인과 다툰다면 상대는 전의가 별로 없고 피해도 없으며 있다해도 가벼움`,0,0,`옳지 않은 일이 들통나 천둥에 놀라듯하나 언어를 조심하고 근신하면 두려움이 자연 사라지게 되는 人`,0,0,`정대한 일들이 굻어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 격이므로 화가 밖에서 오니 신중해야 함`,0,0,`배신사로 옛 인연이 단절되고 바라과 그림자를 쫒는 것 같은 처지의 人`,0,0,`육친 무력함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 방심하면 불리함`,0,0,`풍상고추 후 성공할 人-`,0,0,`가정 윗인(부모)이 아래 인(자녀)으로부터 박해를 받음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 전의가 없고 피해도 별로 없으며 있다해도 가벼움 `,0,0,`옳지 않은 일이 들통나 천둥에 놀라듯하나 언어를 조심하고 근신하면 두려움이 자연 사라지게 되는 人-자녀 근심사 발생 우려`,0,0,`여러 일을 하나로 결속시키는 단합사가 있음-단 좋은 일이 영속되고 또는 나쁜 일이 연속됨**일이 결과없이 반복되나 밝아짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응 유화적 자세로 일을 지체시키거나 뒤로 밀면 결과가 유리해짐`,0,0,`역상적 사안이므로 억을하더라도 순응 유화적 처신으로 후결하면 결과가 반드시 유리하게 되는 人`,0,0,`신규사가 있음**가택을 옮기려 하는 의사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 띄엄띄엄 진행되어 퇴하려하나 계속하게 됨**타세에 의종하는 상**깊은 산속으로 숨고싶은 심정임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응 유화적 자세로 일을 지체시키거나 뒤로 밀면 결과가 유리해짐`,0,0,`역상적 사안이므로 억을하더라도 순응 유화적 처신으로 후결하면 결과가 반드시 유리하게 되는 人`,0,0,`일이 순서적으로 나아가는 듯하나 되려 퇴보하게 됨**만사 길조가 있으나 부복함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인고 다툰다면 상대는 뜻이 동해도 움직이지는 않음`,0,0,`스스로 자임하고 기존 것을 지키고 새 기회를 기다리며 신음하는 人-고시 준비생에 많음`,0,0,`신규사가 있음**소송사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`충성-무력`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`좌고우면-무력`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사-후회사겹침`,`기사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`  흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해함`,`화해함`,`화해함`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`상호무력`,`상호무력`,`상호무력`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월`,1,1,2,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월-연기우려`,1,1,2,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월-연기우려`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5월-연기 우려`,3,3,6,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,1,2,3,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음11월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음10월`,2,1,3,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월-연기우려`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월-연기 또는 파혼우려`,2,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음12월`,2,2,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음11월`,3,3,6,`음음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 온순하고 준수 미모이며 일 처리가 강하지 못한 듯하나 업무는 수준이상 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 많아 초반의 저하된 수준 이후 실적이 없게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반 후반 종반을 송쟁사로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력으로 여러 사안을 일관시키는 결속을 이루나 회화내허함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 온순하고 준수 미모이며 일 처리가 강하지 못한 듯하나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전체를 바목대립동요분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 여러 사안을 일관시키는 결속단합을 완성하여 기반을 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 업무는 초 중반은 수준을 유지하고 종반에 이르러 자산을 공허하게 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 업무의 진척이 느리고 결과가 수준이하로 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 업무는 수준이하가 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 촉력적이고 조급하여  업무에 실패하고 반목대립의 소송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`반대없음`,`이행`,`사안무력`,`進向`,`원`,`留`,`주인무력`,`정북`,``,`서북`],
    [`10.11`,`多集`,``,``,``,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`동남`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`본인제압`,`이행`,`선이후난`,`進向`,`원근왕래`,`  留`,`상호파재`,`북북서`,``,`정북`],
    [`10.11`,``,`내부방해`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후난`,`進向`,`  원`,`  留`,`세입조력`,`정북`,`정서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`세입가해`,`북서`,`북서`,``],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선攻후이`,`退背`,`  원`,`  去`,`상호동요`,`북북동`,`정남`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,`세밀심사`,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`상호화합`,`남남동`,`정북`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,`세입조력`,`세밀심사`,`세밀심사`,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,`함`,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`세입조력`,`동남`,`서남`,``],
    [`10.11`,``,``,``,``,``,``,``,`탈기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`내부지원`,`이행`,`선攻후난`,`退背`,`원근왕래`,`  去`,`무난`,`동남`,`정남`,``],
    [`10.11`,``,`본인제압`,``,``,``,``,``,`주인이사`,``,`세밀심사`,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,`의사참고`,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선난후이`,`進向`,`  원`,`  留`,`주인조력`,`북북서`,``,`남남서`],
    [`10.11`,``,`제압불가`,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`주인조력`,`정북`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`종반반대`,`이행`,`선난후난`,`退背`,`  근`,`  去`,`무난`,`북북동`,``,`남남동`],
    [`10.11`,``,`중반제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`이후불가`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`음서부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`전흉사`,`혈광사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`특별있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`반대없음`,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`변사귀`,`객사`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`변사귀`,`객사`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,0,`특별있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`가당부정`,`성소훼손`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,`남남동`,`있음`],
    [``,``,``,``,``,`묘향`,`북북서`,``],
    [`생업`,``,``,``,``,`전경`,`습지윤천`,``],
    [``,``,``,``,``,`묘속`,  `뱀`,``],
    [`가정`,``,``,``,``,`육친`, `모친`,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사급속사**외사 애증화합-도망실종사우려`,`도적의혹근심사`,``,`안방을 지킴으로  행동이 자유로움`,`내부가정파재극처사`,`투서헌책-고위면접사-부족`,`매사 허성불성-세속허사자**내사출행이동사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사영속성없음**외남 색정사**문서헌책지체-구설사`,`종교진출하면자립함`,`내부가정막힘-반목대립사`,`違禮득죄사`,`내사급속사**외사급속송쟁사-상해우려`,`관직사 영전도약-일반인 흉사`,``,`身물손실사`,``,`사기득재사-이동사`,`외사출행이동사**외사동요불안분산사`,`좋은 소식사`,`내녀 색정사`,`事후쟁탈사**직원남녀공모사`,`매사위역사**내부가정동요분산사`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁`,``,``,`재액해소`,``,`외교막힘**전화위복`,`빈궁**선천 숙명적독신고독자`,`가정손해가출사우려`,``,`재액해소`,`전도**빈궁`,`음4,5월화재주의`,`전도**빈궁`,`음4,5월화재주의`,`전도`,`재액해소`,`빈궁`,`관제**빈궁**병재`,``,`관재`,``,`침체경구사**재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사**내부가정동요분산사`,`도적실세-잡음`,`외사급속사**외사 애증화합-도망실종사우려`,`도적의혹근심사`,``,`안방을 지킴으로 행동이 자유로움`,`내부가정파재극처사`,`투서헌책-고위면접사-부족`,`매사영속성없음**내사출행이동사`,`종교진출하면자립함`,`매사영속성없음**외남 색정사**문서헌책지체-구설사`,`종교진출하면자립함`,`내부가정막힘-반목대립사`,`違禮득죄사`,`내사급속사**외사급속송쟁사-상해우려`,`관직자 영전도약-일반인 흉사`,``,`身물손실사`,``,`사기득재사-이동사`,`외사출행이동사**외사동요불안분산사`,`좋은 소식사`,`내녀 색정사`,`事후쟁탈사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`전화위복`,`전도**빈궁`,``,`전도`,``,``,`외교막힘**전화위복`,`빈궁**선천숙명적독신고독자`,`가정손해가출사우려`,`빈궁`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`관재`,``,`빈궁`,``,`빈궁**관재**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내녀 색정사`,`事후쟁탈사`,`매사위역사**내부가정동요분산사`,`도적실세-잡음`,`외사급속사**외사 애증화합-도망실종사우려`,`도적의혹근심사`,``,`안방을 지킴으로 행동이 자유로움`,`내부가정파재극처사`,`투서헌책-고위면접사`,`매사영속성없음**내사출행이동사`,`종교진출하면자립함**선천숙명적독신고독자`,`매사영속성없음**외남 색정사**문서헌책지체-구설사`,`종교진출하면자립함`,`내부가정막힘-반목대립사`,`違禮득죄사`,`내사급속사**외사급속송쟁사-상해우려`,`관직자 영전도약-일반인 흉사`,``,`身물손실사`,``,`사기득재사-이동사`,`외사출행이동사**외사동요불안분산사`,`좋은 소식사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁`,``,`전화위복`,`빈궁`,``,``,``,``,`외교막힘**전화위복`,`전도**빈궁`,`가정손해가출사우려`,`전도**빈궁`,``,``,`음4,5월화재주의**재액해소`,`빈궁**관재`,`음4,5월화재주의**재액해소`,`관재`,``,`빈궁`,``,`빈궁관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,``,`외사출행이동사**외사동요불안분산사`,`관재 질병사`,`내녀 색정사`,`비밀약속누설-추궁사`,`내부가정동요분산사`,`외사급속사**외사 애증화합-도망실종사우려`,`굴욕단순반복사`,`비상지애사`,``,`내부가정파재극처사`,`관공직자-영전도약사`,`매사영속성없음**내사출행이동사`,`종교진출하면자립함**관직생살대권-일반 관재대흉`,`매사영속성없음**외남 색정사**재물손실사`,`종교진출하면자립함`,`내부가정막힘-반목대립사`,`매사위역사**도적실로-도적소실사`,`병 중태-구설사`,`내사급속사**외사급속송쟁사-상해우려`,`탄식신음-병 또는 도모사 미수`,``,`고위인 연회초대길사-출행사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재**병재`,``,`빈궁**관재`,``,``,``,`전화위복`,`전도**빈궁`,``,`전도`,``,`빈궁`,`외교막힘**전화위복`,`빈궁**병재**선천숙명적독신고독자`,`가정손해가출사우려`,``,`빈궁`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,``,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`고위인연회초대길사-출행사`,``,``,`외사출행이동사**외사동요불안분산사`,`관재 질병사`,`내녀 색정사`,`비밀약속누설-추궁사`,`내부가정동요분산사`,`외사급속사**외사 애증화합-도만실종사우려`,`굴욕단순반복사`,`비상지愛사`,``,`내부가정파재극처사`,`관공직자 -영전도약사`,`매사영속성없음**내사출행이동사`,`종교진출하면자립함**관직생살대권-일반관재대흉`,`매사영속성없음**외남 색정사**재물손실사`,`종교진출하면자립함`,`내부가정막힘-반목대립사`,`매사위역사**도적실로-도적소실사`,`병 중태-구설사`,`내사급속사**외사급속송쟁사-상해우려`,`탄식신음-병 또는 도모사 미수`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**관재**병재`,``,`전도**빈궁**관재`,``,``,``,`전화위복**재액해소`,`빈궁`,`관재`,`재액해소`,`병재`,``,`외교막힘**전화위복`,`선천숙명적독신고독자`,`가정손해가출사우려`,``,``,`빈궁`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`탄식신음-병 또는 도모사 미수`,``,`고위인연회초대길사-출행사`,``,``,`외사출행이동사**외사동요불안분산사`,`관재 질병사`,`내녀 색정사`,`비밀약속누설-추궁사`,`내부가정동요분산사`,`굴욕단순반복사`,`외사급속사**외사 애증화합-도망실종사우려`,`비상지愛사`,``,`내부가정파재극처사`,`관공직자-영전도약사`,`매사영속성없음**내사출행이동사**외교막힘`,`종교진출하면자립함**관직생살대권-일반관재대흉`,`매사영속성없음**외남 색정사**가정손해가출사우려`,`종교진출하면자립함**재물손실사`,`내부가정막힘-반목대립사`,`매사위역사**도적실로-도적소실사`,`병 중태-구설사`,`내사급속사**외사급속송쟁사-상해우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`동요분산사**음4,5월화재주의`,``,`동요불안분산사`,`빈궁**관재**병재`,`동요불안분산사**재액해소`,`빈궁**관재`,`동요불안분산사**재액해소`,``,`동요불안분산사`,`전도**빈궁*관재`,`동요불안분산사**전화위복`,`전도**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`병재**선천숙명적독신고독자`,`동요불안분산사**전화위복`,``,`동요불안분산사`,`빈궁`,`동요불안분산사`,`동요분산사**음4,5월화재주의`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`병 중태-구설사`,`내사급속사**외사급속송쟁사-상해우려`,`탄식신음-병 또는 도모사 미수`,``,`고위인연회초대길사-출행사`,``,``,`외사출행이동사**외사동요불안분산사`,`관재 질병사`,`내녀 색정사`,`비밀약속누설-추궁사`,`내부가정동요분산사`,`굴욕단순반복사`,`외사급속사**외사 애증화합-도망실종사우려`,`비상지愛사`,``,`내부가정파재극처사`,`관공직자-영전도약사`,`매사영속성없음**내사출행이동사`,`종교진출하면자립함**관직생살대권-일반관재대흉`,`매사영속성없음**외남 색정사**재물손실사`,`종교진출하면자립함`,`도적실로-도적소실사`,`매사위역사**내부가정막힘-반목대립사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,`음4,5월화재주의`,``,``,``,``,`빈궁**관재**병재`,``,`빈궁**관재`,``,``,``,`전도**빈궁**관재`,`전화위복`,`전도`,``,`빈궁`,``,`선천숙명적독신고독자`,`빈궁**병재**외교막힘**전화위복`,`가정손해가출사우려`,``,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`도적실로-도적소실사`,`매사위역사**내부가정막힘-반목대립사`,`병 중태-구설사`,`내사급속사**외사급속송쟁사-상해우려`,`탄식신음-도모사 미수`,``,`고위인영회초대길사-출행사`,``,``,`외사출행이동사**외사동요불안분산사`,`관재 질병사`,`내녀 색정사`,`비밀약속누설-추궁사`,`내부가정동요분산사`,`굴욕단순반복사`,`외사급속사**외사 애증화합-도망실종사우려`,`비상지愛사`,``,`내부가정파재극처사`,`관공직자-영전도약사`,`매사영속성없음**외쇼막힘**내사출행이동사`,`종교진출하면자립함**관자생살대권-일반관재대흉`,`매사영속성없음**외남 색정사**재물손실사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의**재액해소`,``,`재액해소`,``,``,`빈궁**관재**병재`,``,`빈궁**관재`,``,``,``,`빈궁`,`전화위복`,``,``,`빈궁`,``,`선천숙명적독신고독자`,`전도빈궁병재**전화위복`,`가정손해가출사우려`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`매사영속성없음**외남 색정사**재물손실사`,`종교진출하면자립함`,`도적실로-도적소실사`,`매사위역사**내부가정막힘-반목대립사`,`병 중태-구설사`,`내사급속사**외사급속송쟁사-상해우려`,`탄식신음-병 또는 도모사 미수`,``,`고위인연회초대길사-출행사`,``,``,`외사출행이동사**외사동요불안분산사`,`관재 질병사`,`내녀 색정사`,`비밀약속누설-추궁사`,`내부가정동요분산사`,`굴욕단순반복사`,`외사급속사**외사 애증화합-도망실종사우려`,`비상지愛사`,``,`내부가정파재극처사`,`관공직자-영전도약사`,`매사영속성없음**내사출행이동사`,`종교진출하면자립함**관직생살대권-일반관재대흉`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`가정손해가출사우려`,`전도`,`빈궁`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의**재액해소`,``,`재액해소`,``,``,`빈궁*8관제**병재`,``,`빈궁**관재`,``,``,``,`빈궁`,`전화위복`,``,``,`빈궁`,``,`전도**선천숙명적독신고독자`,`외교막힘**병재빈궁**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사영속성없음**내사출행이동사`,`종교진출하면자립함`,`매사영속성없음**외남 색정사**문서헌책지체-구설사`,`종교진출하면자립함`,`내부가정막힘-반목대립사`,`違禮득죄사`,`관직자 영전도약-일반인 흉사`,`내사급속사**외사급속송쟁사-상해우려`,``,`身물손실사`,``,`사기득재사-이동사`,`외사출행이동사**외사동요불안분산사`,`좋은 소식사`,`事후쟁탈사`,`내녀 색정사`,`매사위역사**내부가정동요분산사`,`조적실세-잡음`,`도적의혹근심사`,`외사급속사**외사 애증화합-도망실종사우려`,``,`안방을 지킴으로 행동이 자유로움`,`내부거정파재극처사`,`투섷ㄴ책-고위면접사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외교막힘**전화위복`,`빈궁**선천숙명적독신고독자`,`가정손해가출사우려`,`빈궁`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`관재`,``,`빈궁`,``,`전도**빈궁`,`전화위복`,`전도`,``,`관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`내부가정파재극처사`,`투서헌책-고위면접사`,`매사영속성없음**내사출행이동사`,`종교진출하면 자립함`,`매사 허성불성-세속허사자**외남 색정사**문서헌책지체`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`내부가정막힘-반목대립사`,`違禮득죄사`,`관직자 영전도약-일반인 흉사`,`내사급속사**외사급속송쟁사-상해우려`,``,`身물손실사`,``,`사기득재사-이동사`,`외사출행이동사**외사동요불안분산사`,`좋은 소식사`,`事후쟁탈사`,`내녀 색정사`,`매사위역사**내부가정동요분산사`,`도적실세-잡음`,`외사급속사**외사 애증화합-도망실종사우려`,`도적의혹근심사`,``,`안방을 지킴으로 행동이 자유로움`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`외교막힘**전화위복`,`빈궁**선천숙명적독신고독자`,`체-구설사**가정손해가출사우려`,`빈궁`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`관재`,``,`빈궁`,``,`폐한인**전화위복`,`전도**빈궁`,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`안방을 지킴으로 행동이 자유로움`,`내부가정파재극처사`,`투서헌책-고위면접사`,`매사 허성불성-세속허사자**내사출행이동사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자**외남 색정사**문서헌책지체-`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`내부가정막힘-반목대립사`,`違禮득죄사`,`관직자 영전도약-일반인 흉사`,`내사급속사**외사급속송쟁사-상해우려`,``,`身물손실시`,``,`사기득재사-이동사`,`외사출행이동사**외사동요불안분산사`,`좋은 소식사`,`事후쟁탈사`,`내녀 색정사`,`매사위역사**내부가정동요분산사`,`도적실세-잡음`,`외사급속사**외사 애증화합-도망실종사우려`,`도적의혹근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전도`,``,`외교막힘**전화위복`,`빈궁**선천숙명적독신고독자`,`체-구설사**가정손해가출사우려`,`빈궁`,``,`전도`,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`전도**빈궁`,``,`재액해소`,`빈궁**관재**병재`,`재액해소`,`관재`,``,`전도**빈궁`,`전화위복`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 가진 것은 다 잃고 관재       송사만 남았겠네?
  송사에는 귀신이, 가정에는     부정이 잔뜩 들어서 그러네!

⊕여귀, ⊖전흉사귀야!
⊕⊖가택가당신사 부정
⊖신변음사부정이침범했네?
`,`■가정에서 금전과여자때문에   의심질투가 심하겠네?      지금 갈림길에 섰겠어!
 그나마 더 잃지 않으려면    후퇴ㆍ양보하는처신을      해야겠어!
`,`■ 안이고 밖이고 캄캄한 지경     이겠어! 풍파가 심해!
⊕여귀, 
⊖전흉사귀를 해소시키고 정정    당당하게 나서면 되는 일이    있겠어!
⊖가택음사부정이 있다.
⊕신변상가부정이 침범했네?
`,`■ 마음속에 두려움이 크네?     부부가 똑같이 바람이 났어!     배은망덕의 재판까지 붙     겠네? 입조심 해야 겠어!
⊕여귀, 
⊖전흉사귀 장난이야! 
 없애야 해!
`,`■ 여러사람 일을 혼자 매고       가고 있네? 
  겉만 화려하지 실속이 없겠어! 男:직위가 상실 되겠고 남의 여      자를 뺐어 관재도 보이고....
女:부부이별수가 보여!
⊖가택음사부정이 있다.

⊕⊖신변가당신사부정침범했어!
`,`■옛 일, 옛인연이 다 끊어 지네?
⊕여자가 탄식하고, 
⊖재앙소멸이야! 
과감히 청산 하고 
⊕도로귀, 
⊖가택귀만 없애면 만사형통이    되겠어!
`,`■색정사로부부인연은 끝났어!     애써봤자 바람과 구름일뿐     이야!재물을 얻어재앙 덩    어리겠고! 
⊕도로귀, 
⊖가택귀를 해소시켜야 
 살길이 열려!

⊕신변음사부정이 침범했어!
`,`■ 골육이 등을 돌렸네? 
  하극상이야! 
  만사가 어렵게 가겠어!
⊕변사귀, 
⊖전사귀를 없애야만 고진
 감래가 되겠어!
`,`■ 마음이 천둥치듯 놀래고 
  타겠어 ! 재물을 잃는 운세야 !근신하고,
⊕가택음사부정ㆍ도로귀, ⊖가택귀를 잡고 중절시킨 
 아이나 죽은 형제를 천도해    주면 길이 훤히 뚫리겠어!
⊖신변상가부정이 침범했어!
`,`■ 가택이나 생업이나 직장     변동수가 보이네?
  아주 좋아지겠는데
⊕도로귀, 
⊖가택귀를 해소시켜주면 
 더욱 좋겠어!
`,`■ 지푸라기라도 잡고 싶은     심정이겠는데,계획을 변     경시키고 !
⊕가택음사부정ㆍ여귀, 
⊖전흉사귀부터 해소해야만 
 길이 뚫리겠어!
男：⊕남의 여자를 내것으로 쓰네?
⊕⊖신변가당신사부정침범했네?
`,`■ 지금은 돈ㆍ여자문제로       쪼들려도 다 잘될거야!
   돌아가세요 !`];
  const YOGYEOL1 = [`■ 덕과 녹이 공망되어 낮정단은 귀살에 백호가 탔다. 뱀띠ㆍ말띠는 재앙을 면하겠으나 소띠ㆍ양띠ㆍ개띠는 두려움이 있으리라.
  `,`■ 전진하면 손해요 후퇴하면 이익이다. 재물에 도적이 붙어 필시 잃으리라. 겨울철 낮정단은 화재를 조심하라.
  귀인이 찾아오겠다.
  `,`■ 나를 굽히고 상대를 높여야 돈을 벌 것이다.
  드러나지 않게 도와주리라. 매사를 공적으로 해야 이롭다. 낮정단은 두려운 일이 있게 된다.
  `,`■ 내가 비록 잘 되더라도 간절한 것은 게으름을 피우지 말라.
  병은 재발하고 소송사건은 크게 기한다. 
  그 근심이 있으리라.
  `,`■ 재물과 여자로 쪼들린다. 손해나는 일이 많으니 집사람 말을 들으면 밤정단은 매우 좋으리라.
  `,`■ 살은 없어지고 관은 남았으니 공직자는 기쁨을 맞는다. 그러나 일반인은 재앙과 해로움이 여럿이다.
  `,`■ 좋은 사람은 없어지고 나쁜 사람도 떠난다.
  하고 있는 모든 일에 아무런 그림자가 없다.
  `,`■ 수입이나 직위가 위태롭고 집안도 편하지 않게 된다. 형제 일도 침침해지고 기다리는 사람은 곧 오겠다.
  `,`■ 웃음 뒤에는 독이 숨어있고 귀신과 도적이 가득하다. 드러나지 않게 해를 끼친다.
  자손 문제도 좋지 않다.
  `,`■ 나는 이롭고 상대는 나쁘게 되리라.
  가택이 움직이고 시끄러우나 근심이 변해 기쁨이 되리라.
  `,`■ 자식을 믿으라. 흉사를 막는다. 일은 밖으로 번지지 않을 것이요, 돈이나 여자가 스스로 올 것이다. 낮정단은 놀랠 일이 생긴다.
  `,`■ 가만히 지키면 잘 되고 움직였다 하면 관재가 일어난다.
  낮정단은 실물이나 손해가 나고 밤정단은 도둑떼가 침입하리라.`]
  const SIBO1 = [`■ 가정에 금전과 여자로
  쪼들리고 있다.
  직녹 소송사가 있다.
  ⊕⊖가당신사 부정이 들었다.
  이별사가 있고 일도 미완성이다. 금전을 소득해도 다툼이 야기된다. 활동할 수가 없다. 공직자는 凶하고 종교가는 吉하다.
  `,`■ 저주사가 있다.
  가택의 손실사가 있다.
  여인사로 파란 기복이
  있고 전진이 안된다.
  은혜사 중 위해를 끼치는 일이 일어난다.
  손해를 버리고 이익에 붙는다. 돈을 얻어도 잃는다. 지키면 좋고 활동 하면 잃는다.
  형제의 凶이 기한다.
  `,`■ 가택ㆍ가정의 손해가 있다.
  권리가 넘어간다.
  모든 일을 공용으로 도모 해야 좋고 음모는 불길하다.
  구재사는 반드시 원조를 받으나 얻은 후 잃거나 도난을 당한다.
  ⊖음사부정이 있다. 明에서 暗으로 들어가는 형상이다.
  `,`■ 이익사가 있고 반드시
  소득한다.
  직녹이 손실된다.
  서북쪽에 ⊕여인, ⊖소식사의 걱정이 있다.
  소송사가 기할 조짐으로 놀라고 있다.
  부부간에 각기 외정이 있어 이별의 걱정이 있다.
  正月에 기쁨이 있게 된다.
  
  `,`■ 금전ㆍ여인사가 자유롭지
  못하다.
  외화내빈의 형국이다.
  매사 처음에는 이루어지는 일이 없고 나중에 길하다. 고진감래이다.
  
  男은 타인의 부인을 유혹하여 거래하고, 女는 박관사의 근심이 있게 된다.
  ⊖음사부정이 들었다.
  직녹의 손실이 있다.
  `,`■ 자충수적 우환이 있고
  ⊕관리ㆍ정치가는
  탄핵사건이 있다.
  구직ㆍ사관사는 길하다.
  영전사도 있다. 옛 일, 인연은 끝나고, 선택의 기로에 섰다. 좌우와 의논이 좋다.
  十月 임신사가 있다.
  심중에 의심․질투가 있다.가정 금전 여인사 
  때문이다.
  `,`■ 자충수적 우환이 있다.
  가정에 ⊕⊖가당신사
  부정이 들어 부부이심으로
  만사가 정체되고 어둡다.
  길흉선악이 모두 이루어지지 않는다.
  특히 금전ㆍ여인사에는 칼과 독이 숨겨져 있으므로 주의를 요한다.
  직녹의 손실사가 있다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을 품고
  가해하려 한다.
  이별사가 기한다. 
  강을 건너는데 배가 없는 격이다. 만사가 곤란후에 소성함이 있게 된다.
  육친간 빙탄이고 아래로부터 능욕을 당한다.
  十月 임신사가 있다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을 품고
  가해하려 한다.
  피차간에 의자가 소통되지 않고 반항ㆍ반대를 받는다. 사건이 연이어 기하고 한 사람의 일이 아니다. 인ㆍ택의 소모가 많고 종업원이나 아랫사람이 도망하게 된다. 
  위험한 財를 소득하거나 금전 소송사가 기한다.
  `,`■ 타인의 추천이 있어도
  진정스럽지 못하다.
  직녹의 손실을 기한다.
  금전사로 가정이나 직장에서 동요ㆍ불안이 일어난다. 사물이 순조롭지 못하다. 
  
  ⊖금전 쟁탈사로 재앙을 만난다. 가정에 꿈자리가 사납다. 귀신을 해소하라!
  집안에 죽을 사람이 있다.
  `,`■ 타인ㆍ財ㆍ女가 나를
  따르고 헌신한다.
  ⊕남자가 타인의 부인을 유혹하여 거래한다.
  ⊕가정에 음사부정이 들었다.
  모든 일을 음적으로 진행하면 이롭고 흉화위길의 신조가 있다. 
  공직사는 吉하다.
  `,`■ 형제의 재화나 손실이
  있다.
  만사 냉정하게 처신해야 길하다.
  처음에는 금전ㆍ여인사가 자유롭지 못하나 모든 일에 분수를 지키면 형통하게 된다. 욕망을 억제하지 못하면 관재 근심이 있게 된다.`]
  const SIBO2_1 = [`■녹ㆍ덕,신변외사이다.
  내방조짐 상의 : 
  원행·이동사가있다.어떤 일이든 처음 불성한다.낭비가 많고 불명예가 있고 손재·녀하고 형제의 재액이 있다.
  `,`■사업ㆍ후원,가택내사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  가택 권리가 양도된다
  `,`■財ㆍ女,가택동요사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官･夫,부부이심동요사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.은혜를 원수로 갚는 송쟁사가 있다.
  `,`■財ㆍ女,가택동요사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고
  男직위상실하고
  女부부이별한다. 
  `,`■자손ㆍ극부신변외사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■직ㆍ녹, 신변외사이다.
  내방조짐 상의 : 
  원행·이동사가있다.어떤 일이든 처음 불성한다.낭비가 많고 불명예가 있고 손재·녀하고 형제의 재액이 있다.
  `,`■財ㆍ女,외사내입사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■官ㆍ夫,이별외사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다. 자손 재해 예방이 필요하다.
  `,`■官ㆍ夫,이별외사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다. 가정에 사망사가 있다.
  `,`■財ㆍ女,가택내사이다.
  내방조짐 상의 : 
  매사 초에는 불성하고 모든 일이 겹쳐 일어난다.
  순역이 혼란하다.
  `,`■財ㆍ女,가택내사이다.
  내방조짐 상의 :  
  매사 초에는 불성하고 모든 일이 겹쳐 일어난다.
  용두사미의 상이다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/041/row-1-column-1.png`,
    `/static/images/sagwa/041/row-1-column-2.png`,
    `/static/images/sagwa/041/row-1-column-3.png`,
    `/static/images/sagwa/041/row-1-column-4.png`,
    `/static/images/sagwa/041/row-1-column-5.png`,
    `/static/images/sagwa/041/row-1-column-6.png`,
    `/static/images/sagwa/041/row-1-column-7.png`,
    `/static/images/sagwa/041/row-1-column-8.png`,
    `/static/images/sagwa/041/row-1-column-9.png`,
    `/static/images/sagwa/041/row-1-column-10.png`,
    `/static/images/sagwa/041/row-1-column-11.png`,
    `/static/images/sagwa/041/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 천지반 중에 천지(天地)가 복(伏)하여 움직이지 않으므로 복음과(伏吟課)로 된다.양일은 간상신을 초전으로 하며 중․말전은 지형으로써 한다. 양일(陽日)은 자신의 강함을 자처하므로 복음자임과(伏吟自任課) 이다. 
    3전4맹신(三傳四孟神)은 원태격(元胎格)이며, 인(寅)은 일덕일록(日德日祿)이지만 공망하여 힘이 공허하니 복(福)을 이루지 못한다. 말전은 일귀(日鬼)이나 중전에서 제어하므로 귀는 두려울 것이 없다. 복음이란 복익호의(伏匿狐疑)한 형상이니 안정하는 것이 좋고, 녹마의 공망은 사관․구직에 이롭지 못하다. 초전이 공망․과숙(寡宿)으로 되어 이별의 걱정이 있고 사물이 이루어지지 않는다. 종교가에게는 도리어 길조이다.
    백법에 ｢지형상에 있는 것은 주객(主客)이 투합되지 못하고 일간상신에 귀(鬼)가 임하는 것은 흉사가 빠르다. 그러나 다행히 공망하여 흉은 극히 경미하다｣고 하였다.
    비전에는 ｢일록․역마가 다 공망하면 재를 소득한 뒤에 다투는 일이 일어난다｣고 하였다. 역마는 활동의 신이라 할지라고 공망되는 것은 움직이려 해도 움직일 수 없기 때문이다.
    일귀는 역행하며 3전은 청(靑)․주(朱)․후(后)이고, 야귀는 순행하여 3전이 사(蛇)․구(勾)․백(白)이다.`
    ,
    // 2
    `  제1과․제2과․제3과에 상하의 극이 있다. 하에서 상을 적하는 것이 양이니 양비(陽比)인 자축(子丑)의 극을 초전으로 하여 지일과(知一課)로 된다. 3전의 자해술(子亥戌)은 퇴여격(退茹格)이다. 3전이 전부 음기(陰氣)의 신으로서 중음격(重陰格)이니 전진하지 못한다. 그러므로 소극적으로 하면, 즉 후퇴하면 유익하다. 격극(格剋)이 많으면 파란기복이 있어서 곤란하다.
    일간상신은 축(丑)의 재이고, 말전에 술(戌)의 재가 있어 구재의 목적은 달성된다. 그러나 술(戌)에 현무가 승하므로 재를 얻고도 실재(失財)․분실․도난당할 염려가 있다. 
    지일과(知一課)의 비용격은 모든 일이 금전ㆍ여인에 의하여 일어나며, 은혜 가운데 위해를 초래하는 일이 있다. 제1과의 귀신이 간상에 있고 제4과의 건록이 공망하니 춘하의 정단은 길하다. 구태를 지키는 것이 좋고, 움직이면 이롭지 못한 과격이다.
    백법에 ｢술(戌)이 해(亥)에 가해지면 괴도천문격(魁度天門格)으로 된다｣고 하였다. 
    비전에는 ｢3전에서 일간을 생하는 것은 손해를 버리고 이익에 붙으며, 일간상신이 재로 되고 축(丑)은 순미(旬尾)의 지(支)로서 폐구격(閉口格)이다. 따라서 병단은 불길하며, 귀인․천공이 간상에 있어서 입시에는 합격한다｣고 하였다. 
    일귀는 역행하여 3전은 백(白)․상(常)․현(玄)이고, 야귀는 순행하여 3전이 후(后)․음(陰)․현(玄)이다.`
    ,
    // 3
    `  이 과는 2상이 2하를 극하는데 어느 것이나 양의 극(剋)이니 지일과(知一課)로 되지 않고 섭해과(涉害課)로 된다. 첫째로는 4맹상신(四孟上神)을 취하고, 4맹의 극이 없을 때는 중신(仲神)의 극을 취하는 것이 법칙이다. 따라서 술자(戌子)를 사용하여 초전으로 한다. 3전이 술신오(戌申午)이면 패려격(悖戾格)이 된다. 귀신이 묘(卯) 또는 유(酉)에 임하면 여덕격(勵德格)이다.
    초전은 술(戌)의 재로서 현무가 앉으며, 중전은 귀살이지만 말전의 오(午)가 귀를 제어하니 흉은 두려울 것이 못되나. 일지의 택상공망은 가택에 손실이 있다. 3전이 역간전(逆間傳)이면 대개 조격이 생긴다. 3전4과가 전부 양지인 것은 공용 사건에서 이익이 있고, 음모사는 불길로 끝난다. 
    패려격(悖戾格)이란 3전이 음의 경계이나 오(午)의 이명(離明)이 있어 고위에 오르는 형상으로서 패려(悖戾)로 된다. 망동하면 재화가 있다.
    초전의 재효는 말전에서 생부(生扶)하여 힘을 얻으므로 구재에서는 반드시 남의 원조를 받는다. 그러나 재에 현무가 승하니 얻은 뒤에 실탈․도난의 두려움이 있다.
    일귀는 역행하여 3전이 현(玄)․후(后)․사(蛇)이고, 야귀는 순행하여 3전은 현(玄)․백(白)․청(靑)이다. 4과이나 3과뿐이므로 불비격을 구성한다.
    백법에 ｢일지에 건록이 앉는 것은 권섭부정(權攝不正)이며 백호가 일간상에 가해지는 것은 흉이 있다면 속하다｣고 하였다.`
    ,
    // 4
    `  이 과는 4과 중에 상하의 극이 없으므로 천신에서 극하는 신(申)을 취하여 초전으로 하니 호시원태격(蒿矢元胎格)이다. 일간상신은 해(亥)의 장생(長生)이 앉으므로 남이 와서 나를 부추기면 이익이 있다. 
    초전은 신(申)의 귀살로 되고 백호가 앉으니 질병․소송 정단은 모두 흉하다. 다행히 일간상신 해(亥)의 인수(印綬)를 생하여 살인(殺印) 생화하고 또 중전에서 제하니 흉을 보아도 흉에 걸리지 않는다. 대개 호시과는 길흉화복이 모두 경하여 사물이 멀어질 때는 하등의 공포가 없다. 신금(申金)이 초전으로 되고 귀살로 되면 걱정은 서북에 있다. 일지상신 축이 귀신에 해당되면 묘고(墓庫)로 보지 말라.
    백법에 ｢교차상극하고 상하가 또 상극되는 것은 부부무음(夫婦蕪淫)의 격으로 서로 사정이 있다｣고 하였다. 
    비전에는 ｢일상장생(日上長生)으로 되고 초전의 금(金)은 해수(亥水)를 생한다｣고 하였다. 즉 살(殺)을 인(印)으로 생화하는 것은 불행 중에 다행이 있다고 한다. 제2과가 초전으로 되고 일상의 두 과가 신해(申亥)로 이루어지는 것은 스스로 책전(策戰)하여 역량이 부족하다.
    일간이 지상을 극하고 일지는 간상을 극하는 것은 해리격이라 부른다.
    일귀는 역행하여 3전은 후(后)․주(朱)․청(靑)이고, 야귀는 순행하여 3전이 백(白)․구(勾)․사(蛇)이다. 즉 호두사미(虎頭蛇尾)로 끝난다.`
    ,
    // 5
    `  이 과는 제1과․제3과가 하에서 상을 극하여 같은 양극(陽剋)이니 섭해과(涉害課)로 되고 4맹상신(四孟上神) 술(戌)이 초전이 된다.
    일간에서 술(戌)을 극하여 재로 되고 육해(六害)가 승하여 극되니 협극(夾剋)으로 된다. 따라서 재를 자기의 마음대로 하지 못한다. 3전이 인오술(寅午戌)로 3합화국(三合火局)이 되고 일간에서 설기가 지나치다. 제3과․제4과는 신자진(申子辰)이 3합수국(三合水局)이 되어 화(火)의 지나침을 제어하고 설기(泄氣)를 멎게 한다. 일지상신의 자(子)가 일간을 생하고 청신(靑神)이 승하면 도리어 길조이다. 
    섭해과에 의한 정단은 처음에는 곤란하고 나중에 길하게 된다. 초전이 공망의 지(地)에 앉으니 도모하는 일은 이루어지는 일이 적다. 3전이 3합하는 것은 한 사람의 일이 아니며, 또 길흉이 모두 연이어 발생한다.
    백법에 ｢초전이 협극(夾剋)을 만나는 것은 자기의 자유를 얻지 못하고, 3전에 후(后)․합(合)이 있는 것은 남녀의 자유 연애 결혼이다｣고 하였다.
    비전에는 ｢일간상신 술(戌)의 재신(財神)은 3전의 화국(火局)에서 생하는 환혼격(還魂格)이라 이름 짓는다｣고 하였다.
    일귀는 역행하여 3전이 합(合)․후(后)․백(白)이며, 야귀는 순행하여 3전이 합(合)․백(白)․후(后)이다.`
    ,
    // 6
    `  이 과는 제1과․제3과․제4과에서 하가 상을 적(賊)하며, 양일이니 양의 천신(天神) 오해(午亥)를 초전으로 하여 지일과이다.
    일간에서 유(酉)를 보면 관성(官星)으로 되고 신(申)의 말전은 칠살로 된다. 그러나 오(午)에서 제어하여 살(殺)을 제거하고 관(官)을 머무르게 하니 사관․구직이 호전된다. 현임관리는 영전하는 기쁨을 만난다. 3전이 체생하니 남의 천거에 의하여 만사가 길하게 된다. 4과가 교차상합하여 모든 사물이 타협되고 화합한다.
    백법에 ｢교차상합은 교관사(交關事)에서 반드시 이익이 있고, 주객(主客)이 투합되지 않는 것은 지형상(支刑上)에 있으며, 4과상신의 유진오해(酉辰午亥)의 사신(四神)은 모두 자형(自刑)의 신에 해당하여 3전 체생으로 된다｣고 하였다.
    일귀는 순행하여 3전은 후(后)․공(空)․사(蛇)이고, 야귀는 역행하며 3전이 백(白)․귀(貴)․청(靑)으로 된다.`
    ,
    // 7
    `  이 과는 제1과․제2과에 상하의 상극이 있고 하에서 상을 극한다. 제2과 인신(寅申)을 초전으로 하니 반음중심과(返吟重審課)이고 3전이 완전히 공격(空格)이다. 초전은 일간의 덕록으로 되고 중전은 일간의 귀(鬼)로 되지만 모두 공망이 되어 길흉 선악은 전부가 이루어지지 않는다. 오직 일간상신 귀살(鬼殺)과 상대자는 재동(財動)으로 될 뿐이다. 
    원래 반음과는 만사가 반복되고 정체되며 암매하여 통하기 어렵다고 하지만, 녹마․관살이 공망하여 바람을 잡고 그림자를 쫓는 것과 같다. 걱정․근심이나 해산 등의 일에는 좋다. 인년(寅年)과 인월(寅月)에는 바라는 바가 달성된다.
    백법에 ｢암귀재동(暗鬼財動)은 웃사람의 면접, 구재는 재를 얻어 재화(災禍)의 근원이 되며, 진술(辰戌)에 육합이 앉는 것은 두괴(斗魁)라  이름짓는다｣하였다. 점시(占時)가 발용(發用)으로 되는 경우에는 움직이는 가운데 움직이지 않고, 반음(返吟)은 먼 곳을 지배하지만 이와 같은 것은 멀리 찾아도 도리어 가까이 있으며, 중․말전의 공망도 또한 그러하다.
    일귀는 순행하여 3전은 백(白)․사(蛇)․백(白)이며, 야귀는 역행하여 3전이 후(后)․청(靑)․후(后)이다.`
    ,
    // 8
    `  이 과는 제1과․제2과․제4과에서 2하가 상을 적(賊)하여 지일중심(知一重審)도 취할 수 없으니 4맹상신(四孟上神)의 미인(未寅)을 초전으로 하여 섭해도액과(涉害度厄課)이다.
    초전의 재는 공망의 지(地)에 있고 3전은 체극한다. 섭해과에서는 만사가 간난 신고를 맛본 뒤에 길하게 된다. 초전의 공망은 고진(孤辰)이라 하여 이별의 형상이니 결혼 문제는 불길하고 이별 문제는 성립된다. 3하가 상을 극하여 장도액격(長度厄格)으로 되니 만사가 곤란하여 건너기 어렵다.
    백법에 ｢3전이 묘에 들면 미움과 사랑을 구분하고 귀신육해(貴神六害)는 소송사건에서 곡단(曲斷)을 만난다｣고 하였다
    비전에는 ｢미(未)에 귀신이 앉아 인(寅)에게 극되어 자미(子未)육해(六害)되는 것은 농교성졸(弄巧成拙)하는 결과를 이룬다｣고 하였다. 장도액격은 바다를 건너려는데 배가 없는 격이며, 아랫사람의 능욕을 받는다.
    일귀는 순행하여 3전이 귀(貴)․청(靑)․음(陰)이며, 야귀는 역행하여 3전은 공(空)․사(蛇)․상(常)이다.`
    ,
    // 9
    `  4과 중에 상하의 극이 없으므로 사극(斜剋)을 사용한다. 그러나 일간에서 극하는 것과 천신에서 극되는 것 등 두 개가 있을 때는 극되는 것을 사용하여 초전으로 한다. 즉 요극호시과(遙剋蒿矢課)이다.
    제1과․제2과는 인오술(寅午戌) 3합의 화(火)이고 제3과․제4과는 신자진(申子辰) 3합수국(三合水局)으로 되는데, 3합 중에 극되는 것은 웃음 속에 칼을 품는다. 즉 피차간에 의지가 소통되지 않고 반항․반대를 받는다. 3합수극하고 일간이 생해지는 것은 유정한 협력을 받는다. 말전은 재에 현무가 승하니 재물의 도난이 있다.
    요극과는 사극(斜剋)이니 길흉이 다 경하며, 무슨 일이나 모사할 경우에는 암암리에 계획해야 한다. 3합은 한 사람의 일이 아니고 단결․회합 등을 의미한다. 그리고 성부(成否)는 달이 바뀐 뒤에 실현되고 또 사건이 연이어 일어난다. 일지상신이 초전으로 되고 귀살이라 해도 일간의 오(午)가 이것을 제어하므로 흉을 보아도 화를 만나지 않는다. 4과가 모두 지반에서 천반으로 탈기되는 것은 원소근단(源消根斷)이라 하여 인택이 모두 소모되고 노비의 손실이 있다.
    백법에 ｢합 중에 살을 범하여 보이는 것은 꿀 속의 비소인 형상이다｣라고 하였다. 인택이 모두 탈(脫)하는 것은 도난․분실의 재앙이 있다. 
    비전에는 ｢진(辰)의 재(財) 상에 신(申)의 일귀를 보는 것은 위험한 가운데 취재(取材)하며, 남의 선동에 의하여 다툼이 생긴다｣고 하였다. 혹은 재물로 인하여 관가에 소송할 일이 일어난다. 
    일귀는 순행하여 3전은 사(蛇)․청(靑)․현(玄)이고 야귀는 역행하여 3전이 청(靑)․사(蛇)․현(玄)이다. 이 과는 3전이 3합하여 일간을 생하므로 재(財) 이외의 사건은 대개가 유익하다.`
    ,
    // 10
    `  이 과는 제2과에서 화극금(火剋金)으로 하가 상을 극한다. 신사(申巳)가 초전으로 되어 중심현태과(重審玄胎課)이다. 초전의 신(申)은 일귀(日鬼)이지만 일간상신은 이것을 제어하며 스스로 제지(制地)에 앉으니 걱정할 것 없다. 3전이 체생하나 말전이 공망하므로 추천을 받아도 진정이 없다. 
    일지의 음양은 정신(丁神)이 재동으로 되어 재의 일로 인해 동요․불안이 생긴다. 중심원태격은 사물이 순조롭지 못하다. 숨기 때문에 나타나지 않고 멀기 때문에 목전에는 통하기 어렵다. 바르게 지키면 장래에 나타날 징조이다. 신장(神將)이 내전(內戰)하게 되면 쟁탈에 의하여 재화를 초래한다.
    백법에 ｢3전이 체생되는 것은 남의 천거를 받는다｣고 하였다. 
    비전에는 ｢일간이 상신(上神)을  생하고 태상이 승하면 탈상탈(脫上脫)을 만난다고 하며, 사월(巳月)에 가정사를 정단하면 집안에서 괴이한 것을 발견하거나 괴몽(怪夢)을 꾼다｣고 하였다. 질병이나 소송사는 가장 흉하다.
    일귀는 순행하여 3전은 사(蛇)․구(勾)․백(白)이며, 야귀는 역행하여 3전이 청(靑)․주(朱)․후(后)이다.`
    ,
    // 11
    `  이 과는 제1과․제4과에서 2하가 2상을 극하여 섭해과이니 4맹상신(四孟上神)의 극을 취하여 진인(辰寅)이 초전으로 된다. 일지가 일간상신에 임하면 췌서격(贅壻格)이고, 3전에 육합과 천후(天后)가 승하는 것은 교동격(狡童格)이며 진오신(辰午申)은 일신(一神)을 격하여 간전격(間傳格)으로 된다. 일간상신 진(辰)이 초전으로 되는 것은 참관격이다. 이상과 같이 다수의 격을 구성한다.
    초전은 재로 되고 공망의 지(地)에 앉으며, 말전은 귀살로 되나 중전의 설기(泄氣)가 구신으로 되어 살을 제어하니 해를 이루지 않는다. 일지 진(辰)은 그인데 일간인 나에게 앉아서 재로 되면 남이 와서 나를 이롭게 한다. 4과불비로서 교동격이니 남자가 여자를 유혹할 삼각관계의 징조이다. 
    참관격에서는 모든 일이 조격으로 되나 후에 통하며, 초전의 공망은 길흉이 모두 이루어지기 어렵다. 3전이 진오신(辰午申)이면 등삼천격(登三天格)이지만 사관(仕官)의 기쁨이 있고 현임관도 또한 길행하다. 귀신이 해(亥)에 임하는 것은 귀인이 천문에 올라 흉이 화하여 길조로 되는 것이다. 따라서 4맹상신에 4계(四季)의 신이 도는 것은 신장살몰(神將殺沒)이라 하여 재해를 벗어나고 난을 피하니 좋고, 사물은 음으로 도모하면 효과가 있다.
    백법에 ｢강색귀호(罡塞鬼戶)는 모사에 적합하고 후(后)․합(合)이 앉아서 결혼은 중매없이 자유 연애 결혼의 징조이다｣고 하였다. 
    일귀는 역행하여 3전은 합(合)․사(蛇)․후(后)이고, 야귀는 순행하여 3전이 합(合)․청(靑)․백(白)이다.`
    ,
    // 12
    `  이 과는 제2과애서 1하가 1상을 극하여 진묘(辰卯)를 초전으로 하니 중심진여격(重審進茹格)이다. 일간일지의 묘진인사(卯辰寅巳)는 교차육해(交車六害)로 된다. 중심과로서 진연여격(進連茹格)은 만사에 냉정하게 행동해야 좋으며, 맹동(猛動)을 즐기지 않는다. 초전 순수(旬首)는 순의(旬儀)로 되어 길조이나, 공망에 승하니 길은 경하다. 그리고 공재(空財)로 되니 구재는 반갑지 않다.
    백법에 ｢나망격(羅網格)에서는 모사의 대부분이 졸책(拙策)을 이루고 초전에 육합이 승하여 진(辰)을 극하여 협극으로 되니 재는 자유롭지 못하다｣고 하였다. 또 처의 건을 보면 늘 병약하다. 진(辰)의 시(時)에서 사오(巳午)에 태양이 오르고 오(午)는 정양(正陽)으로서 개태(開泰)의 형상이다. 따라서 승계(昇階)라 이름한다. 아쉽게도 진(辰)이 묘(卯)의 공망에 승하므로 길조광명의 형상도 경하다. 모든 일에서 분수를 지키면 스스로 형통하게 된다.
    일귀는 역행하여 3전이 합(合)․주(朱)․사(蛇)이고, 야귀는 순행하여 3전이 합(合)․구(勾)․청(靑)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
