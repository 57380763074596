
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,8,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,8,7,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`신뢰`,9,6,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,10,5,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,4,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,3,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`위선`,1,2,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,2,1,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`신뢰`,3,12,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`위선`,4,11,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,5,10,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,6,9,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`부모 문서`,`문서관련사- 병은 사망함`,`小`,`10`,`단합결속`,`색정사有`],
    [`색정`,`내녀 색정사 有`,`大`,`침해기`,`사사성취`,`음인암손`],
    [`관불서길(官不庶吉)`,`일반인은 좋고 관직자는 불리한 상(象)`,`大`,``,``,``],
    [`음인암손(陰人(暗損)`,`숨긴 연정사로 손해 有`,`小`,``,``,``],
    [`금전`,`금전 외사 파재사- 실종사 우려`,`小`,``,``,``],
    [`사업`,`사업 공허사`,`大`,`11`,`파재실종`,`음인암손`],
    [`여인`,`여인관련사-극처사`,`小`,`쇠퇴기`,`문서지체`,`압박받음`],
    [`정립실탈(定立失脫)`,`제자리를 벗어나 잃는 것이 있는 象`,`小`,``,`구설문제`,``],
    [`적회악의(敵懷惡懷)`,`침해자의 악의에 대한 반공은 안 좋으니 대항공격을 말 것`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`大`,``,``,``],
    [`친구`,`친구 관련사`,`小`,`12`,`압박받음`,`애증화합`],
    [`동료`,`동료관련사`,`小`,`과시기`,`화소복지`,`정위난측`],
    [`재반화의(財返禍矣)`,`재물을 얻어 되려 화(禍)를 당할 우려 有`,`大`,``,`전화위복`,`(상대진심)`],
    [`화소복지(禍消福至)`,`어려움이 해소되고 복이 도래하는 象`,`小`,``,``,`모르겠음)`],
    [`직녹`,`직위승급사-결속단합동지사 有-단, 금전 처첩 불리사초래`,`大`,``,``,``],
    [`결속`,`복잡사를 결속하여 동지사를 완성하나 금전이권妻 훼절됨`,`小`,`1`,`손해도망`,`단합결속`],
    [`손해`,`외 손해도망사`,`小`,`절정기`,`남모르는`,`송구경황`],
    [`남택부혼(男宅婦婚)`,`남자는 전택사 여자는 혼인사 有`,`大`,``,`능욕박해`,`私合畏私`],
    [`송구경황(悚懼驚惶)`,`놀랍고 두려워 안절부절하는 象-색정 상대 관련`,`小`,``,``,`관련`],
    [`금전`,`금전사`,`小`,``,``,``],
    [`막힘`,`사업사-외교 막힘`,`大`,`2`,`외교막힘`,`압박받음`],
    [`여인`,`여인관련사`,`小`,`면모기`,`필유성취`,`문서암동`],
    [`문재암동(文財暗動)`,`학당학교에 숨은 적도(賊徒)와 관련한 문장 재물 이동사 `,`小`,``,``,``],
    [`문서 암동(文書暗動)`,`정상적 표면적이 아닌 이면적 문서가 몰래 이동하는 상`,`大`,``,``,``],
    [`급속`,`외사 급속사`,`大`,``,``,``],
    [`출행`,`가정동요불안분산-내 출행이동사`,`大`,`3`,`수해사有`,`동요분산`],
    [`자녀`,`자녀 관련사`,`小`,`학습기`,`문서유실`,`허획경구`],
    [`신수흠녕(身遂欠寧)`,`계획사에 신중하면 화를 면하나 망동하면 비용이 큼`,`小`,``,``,`도적불포`],
    [`도적허경(盜賊虛驚)`,`고개를 돌려 되돌라간 적도(賊徒)에 놀란 象`,`小`,``,``,`놀램`],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편(남자)갈등사`,`大`,`4`,`수혜사有`,`관직이익`],
    [`자녀`,`자녀관련사`,`小`,`개시기`,`음인재질`,`평민불리`],
    [`부취재화(俯取財禍)`,`정규지위자가 서민에게 취한 재물로 화가 우려되는 상`,`小`,``,``,``],
    [`공사구설(公私口舌)`,`정식 비공식 간에 구설이 발현되는 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`상업`,`상업 관련사`,`大`,`5`,`고위천원`,`압박받음`],
    [`결속`,`복잡사를 결속하여 동지사를 완성하나 금전이권妻 훼절됨`,`小`,`배양기`,`불발됨`,`송구경황`],
    [`종수허경(終遂虛驚)`,`놀란 일이 결국 기우에 불과한 것인 상`,`小`,``,``,`색정관련`],
    [`송구경황(悚懼驚惶)`,`색정사 관련자가 나타나 두려워 하는 상 `,`大`,``,``,``],
    [`관사`,`관사직업권리남편 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출로 성공함`,`小`,`6`,`봉림유사`,`손해가출`],
    [`매사 영속성없음`,`관사직업권리남편사는 선천적 기복재해를 겪음`,`大`,`존재기`,`숲에서뱀`,`公私구설`],
    [`경가외야(更可畏也)`,`당면사를 바꾸면 두려움이 닥친다-는 상`,`小`,``,`을만난격`,``],
    [`봉림유사(逢林有蛇)`,`먼 곳에서 방금 당도했으나 숲에서 뱀을 만난 격의 상`,`大`,``,``,``],
    [`관사`,`관사직업권리남편남자사`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 빙문자는 종교진출하면 성공함`,`小`,`7`,`색정사有`,`수혜받음`],
    [`매사 영속성없음`,`관사직업권리남편남자사는 선천적 기복재해를 겪음`,`大`,`단절기`,`불축지憂`,`紛憂해산`],
    [`기가전행(豈可前行)`,`나쁜 것을 모르고 어찌 앞으로 나아가려 하는가-의 상`,`小`,``,`상대진심`,``],
    [`구연반복(句連反復)`,`단순반복적 굴욕사를 계속하게 되는 상`,`大`,``,`몰라걱정`,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`8`,`동요분산`,`침체위역`],
    [`부인`,`처 관련사`,`小`,`침체기`,`눈이가려 `,`송구경황`],
    [`보완득수(補完得遂)`,`보완책을 강구하면 목적을 완수함`,`小`,``,`진격`,`색정상대`],
    [`화소복지(禍消福至)`,`어려움이 해소되고 복이 도래하는 象`,`大`,``,``,`나타남`],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서 `,`문서 관련사`,`小`,`9`,`외사단합`,`침체반목`],
    [`부모 `,`부모 관련사`,`大`,`소외기`,`면굴난해`,`가정막힘`],
    [`당부취구(當俯就求)`,`관공서 정규직에 당당히 취직되는 상`,`大`,``,``,``],
    [`면굴난해(免屈難解)`,`반복되는 굴욕사를 탈피할 수 없는 상-감옥관련`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`윗인조력문서색정사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`금전사업여인사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`명예지위형제조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`직위권리사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`금전사업처조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,"직업직위불안부부갈등자녀사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,"직업직위불안부부갈등자녀사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`금전사업여인조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전상업처조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,`후원문서부모조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 종결지어야만 유리하고 미루거나 따라가면 불리한 결과가 됨`,0,0,`현재 사안을 정정당당하게 속결해야만 유리할 人`,0,0,`어디 깊은 산속으로 숨고싶은 심정**일이 반복되기만 하고 결과가 없으나 계속 진행하면 좋게 됨**입을 닫음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위놀람**만약  타인과 다툰다면 피아간 전의가 별로 없고 손상도 없으며 있더라도 가벼움`,0,0,`조용히 근신하면 놀래고 두려운 일이 자연 해소될 人`,0,0,`독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 먼저 나서지 말고 후응해야 함`,0,0,`역상적 사안으로 억울하더라도 유화적 처신으로 일을 늦추면 결과가 유리해지는 人`,0,0,`토지사에 관련됨**일이 반복되기 만하고 결과가 없으나 계속 진행하면 좋아짐**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 하나로 묶음, 단 복잡다단함**여자의 경우 남자를 적극적으로 유혹하여 같이 도망할 속심을 갖고 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 먼저 나서지 말고 후응해야 함`,0,0,`역상적 사안으로 억울하더라도 유화적 처신으로 일을 늦추면 결과가 유리해지는 人`,0,0,`1남2녀 삼각관계 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간시이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오니신중해야 함`,0,0,`반복무상의 처지로 의지처가 궁색하고 일을 직접하면 안 되고 타인을 앞세워 측면도모를 해야 함`,0,0,`고집이 있고 신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 먼저 나서지 말고 후응해야 함`,0,0,`역상적 사안으로 억울하더라도 유화적 처신으로 일을 늦추면 결과가 유리해지는 人`,0,0,`1녀2남삼각관계 有**일이 결과없이 반복되나 계속 진행하면 밝아 짐**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 먼저 나서지 말고 후응해야 함`,0,0,`역상적 사안으로 억울하더라도 유화적 처신으로 일을 늦추면 결과가 유리해지는 人`,0,0,`여러 일을 하나로 묶음, 단 복잡다단함**여자의 경우 남자를 적극적으로 유혹하여 같이 도망할 속심을 갖고 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 먼저 나서지 말고 후응해야 함`,0,0,`역상적 사안으로 억울하더라도 유화적 처신으로 일을 늦추면 결과가 유리해지는 人`,0,0,`토지사에 관련됨**동분서주함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 먼저 나서지 말고 후응해야 함`,0,0,`역상적 사안으로 억울하더라도 유화적 처신으로 일을 늦추면 결과가 유리해지는 人`,0,0,`독신고독각방주말부부별리사별적 처지임**서리내린 다리를 건너는 격**일이 띄엄띄엄 진행되어 물러날 뜻이 있으나 되려 나아가게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 종결지어야만 유리하고 미루거나 따라가면 불리한 결과가 됨`,0,0,`현재 사안을 정정당당하게 속결해야만 유리할 人`,0,0,`일이 전개되어 좋음**전화위복적 결과 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 뜻이 동해도 중지됨`,0,0,`자기자신을 과소평가하며 자기만 믿는 人`,0,0,`어디 깊은 산속으로 숨고싶은 심정**일이 반복되기만 하고 결과가 없으나 계속 진행하면 좋게 됨**입을 닫음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성무력`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`좌고우면무력`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성무력`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인**비용지출`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`배신`,`보성무력`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사-후회심 有`,`기사`,`구사`,`외사`,`표면이면겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-주택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-주택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`가택권리양도`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-이사예정`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [` 합의됨`,`합의됨`,` 합의됨`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음9월`,2,1,3,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,1,1,2,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월`,2,1,3,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음2월`,3,3,6,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음1월`,2,3,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,2,2,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음11월`,3,1,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음10월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월`,3,3,6,`음10월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음11월`,3,3,6,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음10월`,3,3,6,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내고 초반 외 실적이 없게 되나 대립공격만큼은 무위로 만듬`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 많아 업적이 평균 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 반목대립송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일처리가 강하지 못하더라도 여러 일을 일체성있게 하여 단합시킴 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내고 종반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 많아 업적이 평균 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어와 행위에 불성실한 면이 있고 초반과 종반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일처리가 강하지 못하더라도 여러 일을 일체성있게 하여 단합시킴 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 반목대립송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것 같으나 상대를 잘 조정하지만 초중반에는 허사가 많고 종반에나 실적이 있게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이며 급하나 업무실적은 합격적 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 상대를 잘 조정하여 업무수행은 합격선 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`있음무력`,`불이행`,`전후압박`,`進向`,`근`,`留`,`주인가해`,`정남`,``,`정동`],
    [`10.11`,``,``,``,`해야함`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음무력`,`불이행`,`안건무력`,` 進向`,`  근`,`  留`,`주인무력`,`남남동`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,` 있음`,`불이행`,`안건무력`,`向進`,`  원`,`  留`,`주인무력`,` 정남`,``,` 정남`],
    [`10.11`,``,`불발현`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,` 없음`,`불이행`,`선후  불가`,` 向進`,`  원`,`  去`,`주인가해`,`남남서`,`남남동`,``],
    [`7.8월`,``,`심중반대`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,`함`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`있음무력`,`불이행`,`선비용出`,` 向進`,`  원`,`  留`,`세입가해`,` 정동`,`동남`,``],
    [`10.11`,``,``,``,`후압박`,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없움`,`불이행`,`선후용의`,` 向進`,`  원`,`  留`,`세입가해`,`동남`,`남남서`,``],
    [`10.11`,``,``,``,`느긋이`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`있음무력`,`불이행`,`본인조건`,` 退背`,`  근`,`  去`,`주인가해`,`서서북`,`북북동`,``],
    [`10.11`,`  集`,``,``,`불비공허`,``,``,``,``,``,`세밀심사 `,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`불이행`,`본인불비`,` 退背`,`  원`,` 去`,`세입설기`,` 북서`,`북서`,``],
    [`10.11`,``,``,``,``,``,``,``,`쇠함`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`불이행`,` 불가`,` 進向`,`  원`,`  留`,`세입설기`,` 정북`,` 정동`,``],
    [`10.11`,``,``,``,``,``,``,``,`쇠함`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음무력`,`불이행`,`선후압박`,` 向進`,`  원`,`  留`,`주인가해`,` 정동`,``,`동남`],
    [`10.11`,``,``,``,`해야 함`,``,``,``,`이사예정`,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`불이행`,` 불가`,` 向進`,`  원`,`  留`,` 무난`,` 동북`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,` 없음`,`불이행`,`선압박加`,` 向進`,`  근`,`  留`,` 무난`,` 정동`,``,`동남`],
    [`7.8월`,``,``,``,`後화합`,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,0,0,``,``,``,0,``],
    [``,0,0,``,``,``,0,``],
    [``,0,0,``,``,``,0,``],
    [`질병`,"전흉사","혈광사",``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,`음사부정`,`금속기물`,`육친`,0,``],
    [`질병`, "水귀","익사",``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`, "水귀","익사",``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,"신제례귀","신성침범",``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,"신성가침","성인모독",``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`저주원심`,`타인구설`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`상가부정`,`상가출입`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`없음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,"전흉사귀","혈광사",``,``,`묘좌`,0,`없음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`특별있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,`북북서`,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`고모신사`,`성인위인`,`전경`,"포구바다",``],
    [``,0,0,``,`영정웨손`,`묘속`,  "水",``],
    [`가정`,0,0,``,``,`육친`,`처 증조부`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내부가정막힘`,`가나있으나 좋음**전도`,``,`일체분규근심균형해소**전도`,`빈궁`,`외 이전이동사`,`빈궁**문서헌책답산지체-구설사`,`재난해소`,`직위자산권리사`,`사사성취**관재**재난해소`,`원통함을 면하기 어려움-감옥관련`,`빈궁**외교막힘`,`내사 출행이동사`,`불측근심사`,`상대의 마음을 가늠할 수 없음`,`빈궁**음4,5월 화재주의`,`손괴재물사`,`빈궁**병재`,`매사불성허성**세속허사 자**관재`,`음3,6,7,8,9,12월방문자종교진출하면성공함`,`도적악의-공격 말 것`,`매사불성허성**종교진출하면 자립함`,`매사위역**은닉사有**외사동요불안분산사`,`빈궁**관재**침체**驚恐사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`內녀 색정음사有`,`전화위복`,`화소복지사`,`외사 출행이동사`,``,``,``,``,`매사위역`,`내부가정동요분산사`,`음4,5월 화재주의`,``,``,``,``,`승진영전-허사`,``,`外男색정음사有`,`빈궁**관재`,`사통자로인한 손해사`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역`,`빈궁**관재`,`내부가정막힘`,`가나있으나 좋음`,``,`일체분규근심균형해소`,`외 이전이동사`,`빈궁**가정막힘`,`문서헌책답신지체-구설사`,`빈궁`,`직위자산녹위사`,`사사성취**전도`,`매사위역`,`빈궁**외교막힘`,`불측근심사**재난해소`,`내사 출행이동사`,`상대의 마음을 가늠할 수없음`,`빈궁**관재**재난해소`,`손괴재물사`,`빈궁**병재`,`승진영전-허사`,`매사불성**종교진출하면 자립함`,`도적악의-공격 말 것`,`매사불성**종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`사통자로인한 손해사`,`외사동요불안분산사`,``,``,``,`內녀 색정사有`,`화소복지사`,``,`외사 출행이동사`,``,``,``,`원통함을 면하기어려움-감옥관련`,`직위자산권리사`,`내부가정동요분산사`,`음4,5월 화재주의`,``,``,``,`外남 색정음사有`,``,`관재`,`外남 색정음사有`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`外남 색정음사有**도적악의-공격 말 것`,`매사불성**종교진출하면 자립함`,`사통자로인한 손해사`,`빈궁**매사위역`,`내부가정막힘`,`가나있으나 좋음**전도`,``,`일체분규근심균형해소`,`재난해소`,`빈궁**가정막힘**외 이전이동사`,`재난해소`,`빈궁**문서헌책답신지체-구설사`,`직위자산권리사**재난해소`,`사사성취`,`원통함을 면하기어려움-감옥관련`,`빈궁**외교막힘`,`불측근심사**관재`,`내사 출행이동사`,`상대의 마음을 가늠할 수 없음`,`빈궁**관재`,`손괴재물사`,`빈궁**병재`,`승진영전-허사`,`매사불성**종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**관재`,`외사동요불안분산사`,``,``,``,`內녀 색정음사有`,`전화위복`,`화소복지사`,``,`외사 출행이동사`,``,``,``,`직위자산권리사`,`매사위역`,`내부가정동요분산사`,`음4,5월 화재주의`,``,``,``,``,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`관재`,`매사불성**종교진출하면 자립함`,`빈궁**관재`,`매사불성**종교진출하면 자립함`,`빈궁**매사위역`,`문서분실사`,`사사성취**전도`,`내부가정막힘`,`빈궁**전도`,`밤에 당하는 능욕박해사`,`3,4,5,6,9,12월 방문 부자**계획미수사`,`가정막힘`,`빈궁`,`재난해소`,`관재**병재`,`재난해소`,`관공직자 영전-일반인불리함`,`외교막힘**음4,5월 화재주의`,`관재**내사 출행이동사`,`음4,5월 화재주의`,`빈궁`,`문서가 몰래 이동함`,`관재**송구경황사有`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`外남 색정음사有`,`모두 재난 뿐임`,`외사동요불안분산사`,``,``,``,`內녀 색정음사`,``,``,`외 이전이동사`,`공공사사구설사`,`외사 출행이동사`,`가정놀램불녕-가족부상사`,`직위자산권리사`,``,`매사위역`,`내부가정동요분산사`,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`관재**송구경황사有`,``,`빈궁**관재`,`매사불성**종교진출하면 자립함`,`빈궁`,`매사불성**종교진출하면 자립함`,`빈궁**매사위역`,`문서분실사`,`필유성취`,`내부가정막힘`,`빈궁**관재`,`밤에 당하는 능욕박해사`,`계획미수사`,`3,4,5,6,9,12월 방문 부자**계획미수사`,`빈궁**관재`,`공공사사구설사`,`빈궁**병재`,`가정놀램불녕-가족부상사`,`관공직자 영전-일반인불리함`,`외교막힘**음4,5월 화재주의`,`내사 출행이동사`,`음4,5월 화재주의`,`빈궁`,`문서가 몰래 이동함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,``,`外남 색정음사有`,`모두 재난 뿐임`,`외사동요불안분산사`,``,`재난해소`,``,`재난해소**전화위복`,`內녀 색정음사有`,``,`가정막힘**외 이전이동사`,`외사 출행이동사`,``,`직위자산권리사`,``,``,`매사위역`,`내부가정동요분산사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`빈궁**음4,5월 화재주의`,`문서가 몰래 이동함`,`송구경황사有`,``,`관재**전도`,`매사불성**종교자립하면 자립함`,`빈궁**관재**병재**모두 재난 뿐임`,`매사불성**종교진출하면 자립함`,`빈궁**매사위역`,`문서분실사`,`필유성취`,`내부가정막힘`,`빈궁**관재**밤에 당하는 능욕박해사`,`가정부부화합`,`계획미수사**외 이전이동사`,`3,4,5,6,9,12월 방문 부자**계획미수사`,`빈궁**직위자산권리사`,`공공사사구설사`,`빈궁**병재`,`가정놀램불녕-가족부상사`,`관공직자 영전-일반인불리함`,`직위자산권리사**외교막힘**음4,5얼 화재주의`,`음4,5월 화재주의`,`내사 출행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`外남 색정음사有`,`전화위복`,`외사 동요불안분산사`,`재난해소`,`동요불안분산사`,`재난해소`,`동요불안분산사`,`동요불안분산사**전화위복`,`가정막힘**內녀 색정음사有`,`동요불안분산사`,`외사 출행이동사`,`동요불안분산사`,`직위자산권리사`,`동요불안분산사`,`매사위역`,`내부가정동요불안분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`빈궁**내부가정동요분산사`,`내사 출행이동사`,`빈궁**문서가 몰래 이동함`,`음4,5월 화재주의`,`송구경황사有**전도`,``,`관재`,`매사불성**종교자립하면 자립함`,`빈궁**관재`,`매사불성**종교진출하면 자립함`,`빈궁**전도**매사위역`,`문서분실사`,`필유성취**관재`,`내부가정막힘`,`빈궁**관재`,`밤에 당하는 능욕박해사`,`외 이전이동사**계획미수사`,`3,4,5,6,9,12월 방문 부자**계획미수사`,`빈궁`,`공공사사구설사`,`빈궁**병재`,`가정놀램불녕-가족부상사`,`관공직자 영전-일반인불리함`,`외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재난해소`,`음4,5월 화재주의`,`재난해소`,``,``,``,``,``,`外남 색정음사有`,`모두 재난 뿐임`,`전화위복`,`외사동요불안분산사`,``,``,`內녀 색정사有`,``,`전화위복`,`가정막힘`,`외사 출행이동사`,``,``,`직위자산권리사`,`관재**전도`,`매사위역`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관공직자 영전-일반인불리함`,`매사위역`,`빈궁**원통함을 풀기어려움-감옥관련`,`음4,5월 화재주의`,`빈궁**문서가 몰래 이동함`,`음4,5월 화재주의`,`송구경황사有`,``,`직녹자산권리사**매사영속성없음`,`매사불성**종교진출하면 자립함`,`모두 재난 뿐임`,`매사불성**종교진출하면 자립함`,`매사위역**전화위복`,`문서분실사`,`필유성취**전도`,`내부가정막힘`,`빈궁**전도`,``,`외 이전이동사**계획미수사`,`3,4,5,6,9,12월 방문 부자**계획미수사`,`빈궁**관재`,`공공사사구설사`,`직위자산권리사**빈궁**병재`,`가정놀램불녕-가족부상사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외교막힘`,``,`내부가정동요분산사`,`내사출행이동사`,``,``,``,``,``,`관재`,``,`빈궁**관재**外남 색정음사有`,`빈궁`,`외사 동요불안분산사`,``,``,`內녀 색정사有`,``,`전화위복`,`가정막힘`,`재난해소`,`외사 출행이동사`,`재난해소`,`직위자산권리사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`고위안좌-이익경영사`,`1,2,10,11월 방문 부자**계획미수사`,`빈궁**외교막힘`,`음4,5월 화재주의`,`주력사 흉함`,`음4,5월 화재주의`,`빈궁**문서가 몰래 이동함`,`음4,5월 화재주의`,`빈궁`,`숲속에서 뱀을 만난 격`,`직녹자산권리사**매사영속성없음**관재`,`매사불성**종교진출하면 자립함`,`간음무도`,`매사불성**종교진출하면 자립함`,`빈궁**매사위역`,`문서분실사`,`빈궁**전도`,`내부가정막힘`,`酒食受罰**전도`,``,`빈궁**병재**가장막힘`,`외 이전이동사`,`빈궁**관재`,`공공사사구설사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재난해소`,`직위자산권리사`,`매사위역`,``,`내부가정동요분산사`,`내사출행이동사`,``,``,``,``,``,``,`外남 색정음사有`,`모두 재난 뿐임`,`전화위복`,`외사 동요불안분산사`,``,``,`內녀 색정음사有`,``,`전화위복`,`가정놀램불녕-가족부상사`,`재난해소`,`외사 출행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`빈궁**전도`,`재물을 남모르게 이동함`,`빈궁**관재**전도`,`도적입문격-불리 도난`,`외교막힘`,`관공직자 영전, 일반인 불리함`,`음4,5월 화재주의`,`빈궁**병재**내사 출행이동사**내부가정동요분산사`,`음4,5월 화재주의`,`빈궁`,`음3,4,5,6,9,12월 방문 부자`,`모망난수`,`빈궁**관재**병재`,`매사불성**종교진출하면 자립함`,`관재`,`매사불성**종교진출하면 자립함`,`빈궁**매사위역`,`문서분실사`,`빈궁`,`내부가정막힘`,``,`일체분규근심균형해소`,`관재**송구경황사**외 이전이동사`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`외사 출행이동사`,``,``,`매사위역`,``,`앙화소멸사`,`재난해소`,`상대의 마음을 가늠할 수 없음`,`재난해소`,``,`관재`,`종종걸음을 반복함-門 관련`,``,`外남 색정음사`,``,`전화위복`,`외사 동요불안분산사`,``,`봉흉화길사`,``,`內녀 색정음사有`,`가정막힘`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`빈궁**관재**외 이전이동사`,`화소복지사`,`빈궁**관재`,`외사 출행이동사`,`사사성취`,``,`외교막힘**원통함을 면하기어려움-감옥관련`,`빈궁`,`내사 출행이동사`,`불측근심사`,`음4,5월 화재주의`,`빈궁`,`손괴재물사`,`빈궁**병재`,``,`매사불성**종교진출하면 자립함`,`매사불성허성 자`,`음3,6,7,8,9,12월방문자종교진출하면성공함`,`빈궁**매사위역`,`문서분실사**외사 동요불안분산사`,`내부가정막힘`,`가나있으나 좋음`,`순조진행도래`,`일체분규근심균형해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`재난해소`,``,`재난해소`,``,`매사위역`,``,`음4,5월 화재주의`,`내부가정동요분산사`,`상대의 마음을 가늠할 수 없음`,``,``,``,``,``,`도적악의-공격 말 것`,`外남 색정음사有`,`전화위복`,`사통인으로인한 손해사`,``,`폐한우려`,``,`內녀 색정음사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`일체분규근심균형해소`,`화소복지사`,`빈궁**외 이전이동사`,`외사 출행이동사`,`빈궁`,``,`사사성취`,`원통함을 면하기어려움-감옥관련`,`빈궁**외교막힘`,`음4,5월 화재주의`,`불측근심사**내사 출행이동사`,`음4,5월 화재주의`,`빈궁`,`손괴재물사`,`빈궁**병재`,`매사불성허성**세속허사 자`,`음3,6,7,8,9,12월방문자종교진출하면성공함`,`매사불성허성**세속허사 자`,`음3,6,7,8,9,12월방문자종교진출하면성공함`,`문서분실사**외사동요불안분산사`,`빈궁**매사위역`,`내부가정막힘`,`가나있으나 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`內녀 색정음사`,`전도`,``,`전화위복`,`재난해소`,``,`재난해소`,``,``,`매사위역`,``,`내부가정동요분산사`,`상대의 마음을 가늠할 수 없음`,``,``,``,``,``,`도적악의-공격 말 것`,`外남 색정음사有`,`사통인으로인한 손해사`,`전화위복`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■도망가는게 상책이겠네? 관재고심이 크겠어 ! 꽉 막혀서 확 뜯어 고쳐야 하겠는데 기도ㆍ치성밖에 방법이 없겠어!

⊕⊖신변고모신사부정이       들었네 ?
`,`■ 오나가나 여자가 말썽이군! 모든일이 결국 제자리야!
⊕신부정귀, 
⊖신불제사귀 때문이야! 
집에도 ⊕⊖여고음강부정이 들어있고! 
우선 야심을 버려야겠어!
`,`■ 기대고는 싶은데 비용만 들어가네? 
심중놀라는 일도 
⊕여귀, ⊖수귀 장난이고! 
해소시키면 다 잠잠해지겠어! 여:⊕ 외롭겠지만 남의 남자        를유혹해서색정을         하고 있네 !
`,`■ 토지에 관송사가 벌어졌네? 송사에 길이 안 보이겠어! 부부 똑같이 바람도 나고!
⊕신부정귀, 
⊖신불제사귀 때문이네?

⊖신변음사부정이 들었어!
`,`■ 집 밑에 해골이 묻혀있네?! 그래서 가정이 침침하겠어! 부부불화도 있겠고! 그대로가면 돈도 여자도 다 뺏기게 생겼어!

⊖가택 상가부정이야!
`,`■ 남자 하나에 두 여자가 다투네? 상대나 가옥이 내 은인인데

⊕신부정귀, ⊖전사귀를 해소시켜야만 잘되게 되어 있어!

⊖신변음사부정이들었네 ?
`,`■ 색정사로 배반당하고도 새 일을 시작하려 하네? 남의 탓도 못하겠네 ! 
일은겨울ㆍ봄에나 기회가 오겠는데, 남자가 집에는 안 들어오고 겉돌겠어!

⊖가택상가부정이 들었고!

⊕⊖신변저주원심부정이        들었네 ?
`,`■ 여자 하나를 놓고 두 남자가 시합 붙었네? 가정에 신회선영부정이 들었어! 
그 이유로부모한테재해가 있게 생겼어 ! 
⊕도로귀,⊖자살귀도 들고!⊕⊖가택신화선영부정과   
⊖신변음사부정이들었어 !
`,`■ 집은 넘겨주고 병도 들고 여자도  깨지고…

⊕가택상가부정ㆍ신부정귀, ⊖전사귀가 내 몸을 치고     있어서 그러네?
女 :⊕남의 남자가 내것이        되었겠어!
`,`■ 땅에 송사가 붙겠네? 이겨봐도 결국 내것은 없네! 여기저기 여러일로 분주하게만 생겼어!

⊖신변에음사부정이 침범
  했네 ?
`,`■ 돈도 여자도 내집이 싫다고 떠나게 생겼어!

⊕신부정귀, 
⊖전사귀 발동 때문이야! 얇은 얼음판을 걷고 있는 셈이겠어!
`,`■ 좋네! 
그냥 돌아가세요! 
다 잘되겠는데, 
특히 용띠와 일을 하면 더욱 좋겠어! 
가세요!`];
const YOGYEOL1 = [`■ 밤정단은 도적이 셋이라서 실물이 있고 낮정단은 구진이 셋이라서 관재가 있다. 말전 역마에 정마가 숨었으니 특별한 고통이 있다.
`,`■ 후퇴하면 귀신이 기다리나 다행히 귀신들이 공망이다. 병은 재발을 막으라. 그 살이 혼을 부른다.
`,`■ 교섭이 아름다운 것 같으나 맞게 될 화살이 두렵다. 그러나 초전을 자세히 보면 전혀 그 위세가 없다. 
`,`■ 서로 연이어 공격하니 피차 상대하기를 꺼린다. 재물이 관귀로 변하니 귀하기를 바라거든 서둘러야 한다.
`,`■ 스스로 잠잠하다가 나중에 도움을 입으니 먼저는 헤매다가 뒤에 깨우친 격이다. 낮정단은 가택이 매우 침침하고 좋은 일이 없다. 
`,`■ 처음과 중간에 손해가 나고 끝에는 빈 귀살이 버티고 있다. 다행이 상대가 와서 도와주니 죽는줄 알았다가 살게 된다.
`,`■ 낮정단은 흉하고 간혹하고 밤정단은 실물을 반드시 방비하라. 움직이면 매번 비용만 나가고 되는 일은 없을 것이다.
`,`■ 사람은 형체 없는 귀신을 만나고 가택은 진짜 묘신을 만났다. 돈을 거머쥐려거든 관청의 힘을 움직여라. 계속 재물이 이어질 것이다.
`,`■ 관과 귀인의 도움이 컸으니 밤정단은 내 녹을 범이 물어간다. 야점은 그렇거니와 낮정단 또한 내 녹을 현무가 가져간다.
`,`■ 돈이 여기저기 가득해도 얻는것보다 갚는게 많다. 두 귀인이 모두 힘을 못쓰니 밤정단은 범이 날뛰는게 두렵다.
`,`■ 주작이 범을 도우니 소송은 매우 흉악하다. 공망이라고 하지 말라. 귀인에게 의지하기도 어렵다.
`,`■ 모두 있는데 하나가 빠졌으니 바로 금전의 이익이라. 용띠이거나 용띠를 만나야 모든 것이 뜻대로 되리라.`]
const SIBO1 = [`■ 관송사가 있다.
財로인한 분쟁이 있다.
女子는 혼인을 원한다.
방어 능력의 부족으로 신음탄식이 있다. 
천강이 통하므로 기도 치성의 효과가 있고, 動하여 吉하게 된다. 
옛것을 버리고 새일, 신규사에 매진하라.
`,`■ 外事로 신변 동요ㆍ불안이
있다. 희비불명ㆍ하극상
이다. 十月에 임신사 有
가택을 손상한다.
옛것을 지키는 것이 유리하다. 함부로 움직이면 권리를 잃는다. 
시기를 기다리라. 午未丑子띠는 적극 활동하라. 이외 띠는 귀살의 해가 있어 조격이 있으니 기도해야 한다.
폐지사는 부흥시키려는 兆가 있다.
`,`■ 가내 우환이 있다. 
가족중 가장을 속인다.
가인이 원한을 품고 加害
하려 한다. 十月에 임신사 有
他人의 추천은 효과가 없다. 스스로 도모해야 한다. 이익이 있게 된다. 
출재의 전에 화합ㆍ타협 된다. 현재 산란한 것은 집을 고쳐 병이 생긴 것이다.
분실ㆍ도난사건을 당한다. ⊕女가 男을 적극 유혹하여 거래한다.
`,`■ 外에서 신변의 폐퇴사가
있다.
가내 우환이 있고 가장을
속이고 가인이 원한을 품어 加害하려 한다. 
구재의 목적은 달성되나 존친의 재해가 야기되고, 득재는 후일 관송사의 원인이 된다. 
단, 巳午띠는 무방하다. 
토지 가택에 소송이 있다. 
가정에 소비가 크다.
권리를 상실한다.
`,`■ 가택을 손상한다.
夫婦가 共히 음탕하다.
계획사는 위험하다.
부부불화한다.
가정이 어둡고 불행사가 기한다. 형제는 화합이나 女ㆍ妻는 극상한다. 우정에 의해 소모건이 있다. 공동사에 서로 의혹이 있다.
다인 다사건 이다.
`,`■ 女ㆍ婦가 헌신한다.
상대가 스스로 와서
이익을 준다. 
부부가 화목하지 못하다.
은혜가 원수로 변한다. 
옛것을 지키라. 일도 二事로 나뉘어 있다.
一男二女 삼각관계가 있고 일이 완전하지 못하고 부족하다.
계획사는 위험하다.
권리를 상실한다.
`,`■ 간자는 돌아오고, 온자는
또 가는 반복 왕래의 兆.
二事로 고민한다.
人宅이 이동된다. 
기물이 파괴되거나 선박ㆍ차량이 전복되거나 충돌하게 된다. 
시작한 일은 반드시 중간에 폐지된다.
신심의 의지처가 없다.
他人에 의해 배반당함.
`,`■ 자충수적 우환이 있다.
모망사는 흩어지고 
허비가 많다.
男.ㆍ夫가 헌신한다. 
가택이 손상한다. 관송사가 있다. 상대ㆍ가정의 모든 일이 간난고심이다. 가택 문제가 어둡다. 낡은 것을 버려야 좋다.
一女二男의 삼각관계가 있다.권리를 상실한다.
⊕⊖가택신화선영부정이  들었다임신사가 있다.
`,`■ 자충수적 우환이 있다.
신불기도가 효과 있다.
⊕상가부정이 들었다.
謀散하고 허비가 많다.
가택의 권리가 변경된다. 구재사는 서둘러야 한다. 금전ㆍ여자가 상한다. 형제ㆍ동료에 의해 분탈된다. 일에 의혹이 기하게 된다.

⊕상가부정을 해소하라.
권리를 상실한다.
다인 다사건이다.
`,`■ 재물이 自來 한다.
금전사가 여러 건이다.
他人에 위탁하고 원행을
원한다. 
결과는 없고, 일은 겹쳐 있다. 도중에 분할이 되나 다시 합하게 된다. 토지ㆍ가옥사가 손모되는 소송이 있다. 
고생후에 목적이 달성된다.
`,`■ 가택이 손상한다.
모망사는 흩어지고
허비가 많다.
분주하기만 하고 효과는 없다. 강을 건너려는데 배가 없는 격이다. 
서로 시기하고 의심한다. 그러나 처음에는 어려우나 후에 풀리게 되는 운세이다.
`,`■ 외부에서 신변의 퇴폐
동요가 있다.
사물이 양갈래이다.
季月정단은 ⊖부자이다.
모사는 졸책에 불과하므로 중정으로 처신하라.
구재는 우연히 얻게 된다. 귀인의 조력이 있다.
일의 시작부터 점점 밝아진다. 
공명ㆍ명성이 뜬다.`]
const SIBO2_1 = [`■ 재물, 신변외사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 재물ㆍ여, 가정동요가 있다.
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
여인사는 지체된다.
`,`■ 관․부 신변외사 이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
직위·권리상실하고 남편은 
망조한다.
`,`■ 금전송사, 쇠퇴가 크다. 
내방조짐 상의 :  
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 금전ㆍ여, 가정사가 심란하다.
내방조짐 상의 : 
은근하고 伏하라.
재·녀 손실 예방이 필요하다.
`,`■ 자손, 가정사가 현안이다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
가정우환의 상이다.
`,`■ 자손사, 가택이 동요한다.
내방조짐 상의 : 
원행·이동사가있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 손모, 가택내사이다.
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다. 상해사를 주의요함
`,`■ 금전ㆍ여, 가정사이다. 
내방조짐 상의 : 
은근하고 伏하라.
재·녀 손실 예방이 필요하다.
`,`■토지송사가현안이다.
내방조짐 상의 :  
은근하고 伏하라.
만사위배․위역의 상이다.
`,`■ 직탈ㆍ별리, 후회심이 있다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
직위·권리상실하고 남편은 
망조한다.
`,`■금전,여인 신변파재실종사.
내방조짐 상의 :  
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.`]
const SIBO2_2 = [];
const SAGWA_IMAGE1 = [
  `/static/images/sagwa/012/row-1-column-1.png`,
  `/static/images/sagwa/012/row-1-column-2.png`,
  `/static/images/sagwa/012/row-1-column-3.png`,
  `/static/images/sagwa/012/row-1-column-4.png`,
  `/static/images/sagwa/012/row-1-column-5.png`,
  `/static/images/sagwa/012/row-1-column-6.png`,
  `/static/images/sagwa/012/row-1-column-7.png`,
  `/static/images/sagwa/012/row-1-column-8.png`,
  `/static/images/sagwa/012/row-1-column-9.png`,
  `/static/images/sagwa/012/row-1-column-10.png`,
  `/static/images/sagwa/012/row-1-column-11.png`,
  `/static/images/sagwa/012/row-1-column-12.png`,

];

const HEASYEOL = [
  // 1
  `  이 과는 천지반(天地盤)이 본궁에 숨어 동하지 않는다. 제1과에 진을(辰乙)의 극이 있기 때문에 진(辰)을 초전으로 하고, 중전은 지상신을 말전은 충신(冲神)을 채용한다. 음일(陰日)은 자신격(自信格)으로 되고 간상 진(辰)을 초전으로 하기 때문에 참관격(斬關格)으로 된다. 진(辰)을 두강(斗罡)이라고 부르며 술(戌)을 천괴(天魁)라고 부른다. 일진(日辰)은 사람이고 괴강(魁罡)은 중토(重土)가 가해져 사람은 관소(關所)에 만난다.
  참(斬)할 때는 잘 통하면 길이 되지만 통하지 않으면 흉으로 된다. 참관이 통하는 데는 칠신칠장(七神七將)이 있다. 중․말전에 자축인묘미신유(子丑寅卯未申酉) 중 어느 것인가를 만난다. 귀(貴)․합(合)․청(靑)․백(白)․상(常)․후(后)․음(陰) 중에 일장(一將)을 보면 참관이 나온다고 한다. 예를 들면 진(辰)은 무진술(戊辰戌)의 무술(戊戌)이다. 그러므로 자축(子丑) 중에 계(癸)가 있어 술(戌)과 간합(干合)하고, 신(申)의 장간(藏干)에 경(庚)이 있어 진(辰)의 장간 을(乙)과 간합하며, 유(酉)는 진(辰)과 합하고 미(未)는 을(乙)의 목(木)이다. 능히 진술(辰戌)을 제어하므로 인묘미(寅卯未)는 참관이 모두 통한다. 참관이 통할 때는 병증․여행․약이기도(藥餌祈禱) 등 어느 것이나 길하다. 그러나 낡은 것을 지키는 일과 도둑 잡는 일은 모두 이익이 되지 않는다.
  참관이 통하지 않으면 어느 것이나 흉조로 된다. 복음자임(伏吟自任)격이 조용하면 길일지라도 참관이 통하면 동(動)하여 길하다. 말전은 역마, 사(巳)는 둔귀(遁鬼)로 되어 일간을 손상하므로 동하여 이익이 적다. 말전은 초전의 재(財)를 돕고 또 경(庚)의 둔귀가 구진(勾陳)에 승하여 반드시 분쟁이 일어난다. 혹은 재(財)로 인하여 관쟁(官爭)이 발생하게 된다. 백법에 말하기를 ｢초전 분재(墳財)는 여행 중에 장해가 발생한다. 또 구재(求財)는 함부로 얻지 말아야 한다｣고 하였다.
  일귀는 역행하여 삼전이 구(勾)․현(玄)․합(合)이고, 야귀는 순행하여 삼전이 구(勾)․후(后)․청(靑)이다. 비전에 말하기를 ｢참관(斬關)은 모든 일에 고난과 위험이 있다｣고 하였다. 정(靜)하면 이롭고 동(動)하면 불리하다. 통하면 오히려 길조가 있다.`
  ,
  // 2
  `  이 과는 4과 중에 제3과 상신에서 하를 극하여 제3과를 초전으로 하는 원수과(元首課)로 된다. 삼전은 술유신(戌酉申)의 역연여격(逆連茹格)이며 중․말전이 공망된다. 일지상신의 술(戌)을 초전으로 하는 것은 참관격(斬關格)이다. 칠신칠장(七神七將) 중에 일신일장(一神一將)을 띠면 참관이 통한다. 신유(申酉)는 귀살(鬼殺)로 되어도 공망하여 해(害)를 이루지 않는다. 그러나 공망을 나올 때는 실(實)로 된다. 이와 같은 것은 쇄혼살(鎖魂殺)이라고 하여 귀살(鬼殺)의 해를 초래하므로 주의하고 예방해야 한다.
  일간상신이 녹왕(祿旺)으로 되어 신(身)에 임하면 의식녹수(衣食祿壽)의 정단에는 길하다. 그 밖의 정단은 낡은 것을 지키는 것이 길하다. 그러나 답각(踏脚)이 공망이 되면 오히려 분투 노력하고 활동하여 효과를 거둔다. 백법에 말하기를 ｢왕록(旺祿)이 신(身)에 임하면 함부로 망동하지 말아야 한다｣고 하였다. 귀(鬼)와 천을(天乙)이 동병(同倂)하면 천을신기격(天乙神祗格)이다. 답각(踏脚)의 공망은 전진하면 유익하다｣고 하였다. 비전에 말하기를 ｢술(戌)이 해(亥)에 가해지면 괴도천문(魁度天門)을 건넌다고 부르며, 대부분의 정단사는 저격(阻隔)의 두려움이 있다｣고 하였다. 관살(官殺)이 공망하고 왕록(旺祿)이 신(身)에 임하면 현임 관리는 진급을 생각하여 야심을 일으키는 것보다 옛것을 지키는 것이 유리하며 생각을 멈추는 것이 현명하다.
  일귀는 역행하여 삼전은 음(陰)․후(后)․귀(貴)이고, 귀신(貴神)이 유(酉)에 임하는 것은 여덕격이다. 그리하여 현자(賢者)는 시기를 기다리는 것이 좋다. 야귀는 순행하여 삼전이 음(陰)․현(玄)․상(常)이다. 괴도천문은 문을 닫아서 격(隔)을 이룬다는 뜻이다. 쇄혼살(鎖魂殺)이더라도 년명(年命) 상신(上神)에 화(火)의 제신(制神)과 수(水)의 살인(殺印)을 생화하는 신이 있으면 출순(出旬)후라 하더라도 흉해를 예방할 수 있다. 질병일 때는 재발하여도 예방할 수 있다.`
  ,
  // 3
  `  이 과는 4과 중에 상하의 극이 없다. 요극(遙剋)은 2위가 있어도 천신(天神)에서 극하는 제3과의 유해(酉亥)를 써서 초전으로 하는 요극호시과(遙剋蒿矢課)이다. 일간상신과 일지 인해(寅亥)의 지합(支合)이 있다. 또 일지상신 유(酉)와 기궁(寄宮) 진(辰)이 지합한다. 즉 교차상합(交車相合)으로 되어 어떤 일도 화합협조(和合協調)의 기쁨이 있다. 호시격으로 되어도 유금(酉金)을 띠면 철시(鐵矢)로 되고 약간의 두려움이 있다. 그러나 공망하여 그 힘은 감소된다.
  삼전 중에 초전은 신유(申酉)의 공망이며, 중전은 차순(次旬)의 오미(午未)가 공망한다. 또 말전은 차순의 진사(辰巳)의 공망에 해당한다. 이와 같은 것을 삼순공망(三旬空亡)이라고 하는데, 모든 일은 산란(散亂)의 징조가 있다. 과전에 천후․육합이 승하여 일녀격(泆女格)으로 되고  점혼(占婚)을 싫어한다.  백법에 말하기를 ｢후(后)․합(合)은 점혼하면 자유 결혼이며 중매가 없다｣고 하였다. 비전에 말하기를 ｢요극과(遙剋課)․묘성과(昴星課)․별책과(別責課) 등은 초전이 공망하고 현무가 승할 때는 반드시 도난 또는 분실의 사건이 발생한다｣고 하였다.
  이 과는 일지 수(水)에서 간상 인(寅)에 탈기(脫氣)하고 기궁 진토(辰土)는 일지상 유금(酉金)에 탈기되어 교차지합(交車支合)하고 교차탈기(交車脫氣)로 된다. 그러므로 교섭(交涉)의 건은 피아(彼我)간에 출재(出財)의 건이 있다. 또는 인택이 함께 실탈(失脫)의 건이 발생한다.
  일귀는 역행하여 삼전이 구(勾)․사(蛇)․합(合)이고, 야귀는 순행하여 삼전이 현(玄)․백(白)․청(靑)이다. 질병은 가옥 수조(修造)가 원인이 되어 발병한다. 말전에서 중전을 생하고, 중전에서 초전을 생하며, 초전은 일지를 생하여 체생(遞生)으로 된다. 그러나 초․중전이 공망하여 타인의 추천도 효과가 없고 발전하기도 어렵다. 삼전 유미사(酉未巳)의 역간전(逆間傳)은 여명격(勵明格)이라고 한다. 스스로 사랑하고 스스로 격려하는 상(象)으로서 녹위(祿位)를 도모하는데 유리하며 또는 어떤 일도 운영하여 이익을 얻는다.`
  ,
  // 4
  `  이 과는 제1과․제4과에 상극이 있고 하에서 상을 극하는 축진(丑辰)을 초전으로 하는 중심과이다. 삼전의 사계신(四季神)만은 가색전재격(稼穡全財格)이다. 일간상신의 축(丑)은 일지를 극하고 지상(支上)의 신(申)은 일간을 극한다. 상신은 토금(土金)이 상생하기 때문에 서로 상교(相交)하여 상극전(相剋戰)으로 된다.
  삼전의 재신(財神)은 일지상의 관귀효(官鬼爻)를 생한다. 즉 전재(傳財)는 귀(鬼)로 화하므로 얻기 어렵고, 수득(收得)하면 후에 화(禍)로 변화한다. 관귀(官鬼)는 삼전에서 생하므로 관귀가 공망하더라도 구직(求職)․사관 등은 공망을 벗어나 희망이 있다. 구관구직은 길한데, 보통 흉하지만 공망하여 흉을 보아도 흉을 만나지 않는다. 즉 신(申)이 공망하기 때문이다. 삼전의 전재(全財)는 부모의 신, 인성(印星)을 극하므로 존친을 정단하면 불길하다. 백법에 말하기를 ｢전재(傳財)는 귀(鬼)로 변화하는 재(財)를 추구하지 말라｣고 하였다.
  폐구격(閉口格)은 일이 양도(兩途)를 추구한다. 비전에 말하기를 ｢을(乙)의 상에 축(丑)이 있고 해(亥)의 상에 신(申)이 있으면 처음에는 상허(相許)하고 후에는 그 사람을 돌보지 않는다. 전재(傳財)가 귀(鬼)로 화하는 것은 재화(災禍)가 가택 중에 발생한다. 그러나 년명상신에서 제복하면 재화(災禍)가 극히 경미하고 전재(傳財)가 왕성하다. 진술축미사오월(辰戌丑未巳午月)은 재(財)가 대왕하므로 구재(求財)의 목적을 달성한다.
  일귀는 순행하여 삼전이 귀(貴)․주(朱)․후(后)이며, 야귀는 삼전이 사(蛇)․음(陰)․백(白)으로서 흉장(凶將)이다. 삼기용(三奇用)을 형성하면 질병이나 소송은 어느 것이나 구원이 있다.`
  ,
  // 5
  `  이 과는 제3과와 제4과의 상신에서 하를 극하여 섭해과(涉害課)로 되기 때문에 지반 사맹신(四孟神)의 극을 써서 초전으로 한다. 삼전의 해묘미(亥卯未)가 삼합목국하여 곡직격(曲直格)이다. 천후(天后)․육합(六合)이 있고 음일격(淫泆格)이다.
  초전의 미(未)는 목(木)의 묘신(墓神)이 되고 말전의 해(亥)는 목(木)의 장생이 되기 때문에 처음은 혼매(昏昧)하고 후에 통명(通名)한다. 일지상신에 백호가 승하여 일지의 해(亥)를 극하고 백호가 택(宅)을 극하면 가정 내에 암운(暗雲)이 있거나 환자 또는 불행사가 발생할 징조가 있다고 본다. 일간․일지상신에 자미(子未)의 육해(六害)가 있으면 부부의 불화를 초래한다. 삼합(三合)이 비견이 되므로 형제는 화합하고 처첩은 극상(剋傷)한다. 염막귀신(簾幕鬼神)이 일간에 승하여 인수(印綬)가 되므로 시험․선거․입시(入試)의 건은 반드시 적중한다. 천후(天后)의 음신에 백호가 승하면 부부에게 조격(阻隔)의 두려움이 있다.
  백법에 말하기를 ｢귀신(貴神)이 진(辰)에 임하여 옥(獄)에 앉더라도 간상(干上)에 있는 것은 길조로 변화한다. 일지 상신 및 초전이 묘(墓)중에 백호와 동병하면 사골(死骨)이 숨어 있어 화(禍)를 이룬다. 나와 상대방이 시의(猜疑)를 형성하는 것은 육해(六害)가 상수(相水)한다｣고 하였다. 제1과․제2과는 수국(水局)이고 제3과․제4과는 목국(木局)으로 수생목(水生木)으로 생하면 상대방에 대한 우정(友情)이며 소모 손실을 초래한다. 비전에 말하기를 ｢해년(亥年) 인월(寅月)에 정단하면 해(亥)가 묘(卯)에 가해질 때는 미(未)는 상사(喪事)로 되고, 사기(死氣)를 해(亥)에 가하면 세호(歲虎)로 되어 재액(災厄)이 발생한다｣고 하였다.
  일귀는 순행하여 삼전이 후(后)․백(白)․합(合)이며 일녀격(泆女格)으로 되어 여자가 남자를 유혹한다. 야귀는 삼전이 백(白)․합(合)․후(后)이고 교동격(狡童格)으로 되어 남자가 여자를 유혹한다. 초전 자(子)와 미(未)는 육해(六害)로 된다. 묘(卯)와 자(子)는 지형(支刑)한다. 따라서 삼합(三合)은 화미(和美)하더라도 이와 같은 것은 사물이 교섭중이거나 공동 동작 등으로 상호간에 의혹을 초래하기 쉽다.`
  ,
  // 6
  `  이 과는 4과 중 두 곳에 오해(午亥)의 극이 있으나 동일한 상극으로 되면 하나로 본다. 따라서 오해(午亥)를 써서 초전으로 하는 중심불비과(重審不備課)이다.
  일간은 초전의 오(午)에 탈기(脫氣)하고 초전은 중전을 생하며, 중전은 말전을 생하여 체생(遞生)으로 되어도 신금(申金)은 일간을 극한다. 생하는 것은 친근한 은정(恩情)이며 극은 구(仇)로 된다. 그러므로 처음의 은정이 후에 구(仇)로 변한다. 일지가 간상(干上)에 승하여 일간을 생하는 것을 자재격(自在格)이라고 한다.
상대방이 내게 이익을 가져 온다. 일간일지상신에 수화(水火)의 상극이 있어 내가 그를 해하므로 불화하며 부부가 또 화목하지 않다.
  말전의 공망은 구태(舊態)를 지키는 것이 길하다. 관귀(官鬼)가 공망이면 관직․구직은 불길하다. 그 밖의 일은 귀살(鬼殺)이 공망하고 아울러 오(午)로 제압(制壓)하므로 모든 일은 손해를 받지 않는다. 백법에 말하기를 ｢폐구격(閉口格)은 사물을 양도(兩途)에 추천한다. 초전이 되는 천신(天神)의 지반에 현무자가 승하면 이 격에 든다｣고 하였다. 즉 수미(首尾)에 가하여 물체가 폐색된 것 같이 둥굴고 단서가 없는 상으로 되면 폐구격이라고 부른다.
  그러므로 점인(占人)은 점단을 구할 때 입을 다물고 말하지 않으므로 사적(事跡)이 명확하지 않다. 찾는 사람은 그림자를 감추고 실물(失物)은 잠적하여 논쟁이 격렬하다. 자식을 임신하면 불구자이고 점사(占事)는 나중에 이루어진다. 일귀는 순행하여 삼전이 음(陰)․청(靑)․귀(貴)이고, 야귀는 역행하여 삼전이 공(空)․후(后)․구(勾)이다. 4과가 완전하지 않으므로 불비격이라 하며 정단의 건은 완전하지 않고 모든 일에 결함이 있다. 비전에 말하기를 ｢천지반 중의 자(子)가 사(巳)에 가해질 때는 병은 구할 수가 없다｣고 하였다.`
  ,
  // 7
  `  이 과는 천지 12신이 대충(對冲)의 위치에 있고 극적(剋賊)이 있는 것으로 반음과(返吟課)이다. 제1과, 제3과의 극 중에 음일(陰日)이면 음비(陰比)의 극인 사해(巳亥)를 초전으로 하고 조상법(照常法)에 의해 삼전을 정하면 사해사(巳亥巳)로 된다.
  반음과(返吟課)는 어떤 일도 반복왕래(反復往來)한다. 즉 가는 자는 돌아오고 오는 자는 다시 돌아간다. 승리(勝利)를 얻기 위해 먼저 진다. 완전히 길장에 의해 구원을 구(求)하는 것이다. 
  을일(乙日) 초전과 말전에 탈기(脫氣)되는 백호, 현무의 흉장이 승한다. 사(巳)는 일지의 역마이고 초․말은 양마(兩馬)로 되어 왕래 이동을 정하지 못한다. 동할 때는 두 개가 동하는데, 인택이 모두 이동하는 것을 다스린다. 기물이 파괴 손상되고 선차(船車)가 전복되거나 충돌 등의 재액(災厄)이 있다. 지마(支馬)가 절(絶)에 임하는 것으로 대인(待人)은 빨리 온다. 사화(巳火)에서 해(亥)를 보면 십이운(十二運)이 절(絶)에 해당된다.
`
  ,
  // 8
  `  이 과는 4과 중에 상하의 극이 세 곳에 있다. 하에서 상을 극하는 제2과를 취하여 초전으로 하는 중심과(重審課)이다. 또 4과 중에 1과가 결(結)하여 3과밖에 없는 불비격(不備格)이다.
  일간상신의 유(酉)는 간귀(干鬼)이지만 공망하므로 두려운 일이 없다. 일간의 묘신(墓神)은 해(亥)의 택신(宅神)을 극하므로 가택(家宅)의 혼회(昏晦)를 암시한다. 삼전이 모두 지반에서 천반을 극하고 아울러 체극(遞剋)으로 되며, 귀(鬼)를 용(用)으로 하여 재(財)를 얻는다. 그러나 재(財)를 얻어 도리어 화(禍)를 초래한다. 백법에 말하기를 ｢피난도생(避難逃生)은 낡은 것을 버려야 한다. 간상지상(干上支上)이 함께 지반을 극하여 양손(兩損)을 방지해야 한다. 주객(主客)이 투합(投合)하지 않는 것은 지형(支刑)이 상에 있다｣고 하였다. 
  비전에 말하기를 ｢간상의 유(酉)와 지상의 진(辰)이 지합(支合)하여 일간의 재(財)로 되고, 재(財)는 귀(鬼)를 생한다. 따라서 영창(榮昌)을 구하여 반대로 치욕을 받게 된다｣고 하였다. 일간상신 육합이 유(酉)에 승하면 대신격장(對神隔將)이라고 하여 사람의 이별을 다스린다. 만약 일지상신에 있으면 가택(家宅)이 손상된다. 천강(天罡)이 지상(支上)에 승하여 일지를 극하면 모든 일에 간난(艱難)이 있다.
  일귀는 순행하여 삼전이 공(空)․후(后)․구(勾)이고, 귀신(貴神)은 묘(卯)에 임하여 여덕격(勵德格)으로 된다. 야귀는 역행하여 삼전이 음(陰)․청(靑)․귀(貴)이고 길장이다. 일간상신 유(酉)는 간귀(干鬼)로 되고, 말전 자수(子水)에 의뢰하여 피난도생격(避難逃生格)으로 된다.
`
  ,
  // 9
  `  이 과는 제1과와 제4과에 극이 있고 하에서 상을 적(賊)한다. 제4과 미묘(未卯)를 채용하여 초전으로 하는 중심과(重審課)이다.
  일간상신에 신(申)의 관성(官星)이 승하여도 공망하므로 길흉의 작용이 없다. 일지상신 묘(卯)는 일록(日祿)으로 되고 가택이 왕성하여도 현무․백호의 흉장이 승하여 경포(驚怖) 또는 소모가 있다. 일간상신 신(申)과 해(亥)가 육해(六害)이고 기궁(寄宮) 진(辰)과 지상 묘(卯)도 육해로 되어 교차육해(交車六害)이다. 삼전이 곡직격(曲直格)이며 일간의 형제효(兄弟爻)로 되기 때문에 어떤 일도 동류로 인하여 발생한다.
  백법에 말하기를 ｢일지상신에 건록이 승하면 권섭부정격(權攝不正格)이다. 귀신(貴神)이 진(辰)의 옥(獄)에 임하는 것도 간귀(干貴)로 되어 흉조를 이루지 않는다｣고 하였다. 권섭부정격은 백법 제8법으로 일간의 녹신(祿神)이 일지상신에 기하는 것을 말한다. 이 격은 내가 존대(尊大)받지 못하고 타인에게서 굴욕을 당하는 천직(遷職)이나 전업(轉業)을 정단할 때는 권섭이 바르지 않다. 직록(職祿)을 얻어도 복록(福祿)은 다른 것과 바뀌어 출재(出財)한다. 즉 가택(家宅)에 녹(祿)을 얻게 된다. 또 8법에는 이 외에 지록극탈격(支祿剋脫格)이 있다. 이 격은 지록생조격(支祿生助格)에 속한다. 즉 일지 해(亥)에 의해 수생목(水生木)으로 녹신(祿神)을 생하기 때문에 내 집의 복록(福祿)은 오히려 후한 것을 얻게 된다.
  초전이 재(財)이다. 구재(求財)는 빨리 하는 것이 길하다. 늦을 때는 비견탈재(比肩奪財)로 된다. 교차육해(交車六害)는 교섭사(交涉事) 또는 상담(商談)의 건은 서로 의혹을 일으켜서 어느 것이나 유익하지 못하다. 일귀는 순행하여 삼전이 후(后)․합(合)․백(白)이며 귀신(貴神)이 일간상신에 승하여 관성(官星)으로 된다. 관직 공명을 정단하면 길일지라도 공망을 벗어나야 목적을 달성할 수 있다. 야귀는 역행하여 삼전이 청(靑)․사(蛇)․현(玄)이다.`
  ,
  // 10
  `  이 과는 제1과 미(未)와 을(乙)의 일하에서 일상을 극하고 미진(未辰)을 초전으로 하여 중심과(重審課)로 된다. 삼전이 전부 토(土)로 을(乙)의 목(木)에서 재(財)로 되고 가색격(稼穡格)이다. 말전의 축(丑)으로 둔간(遁干)하여 정신(丁神)으로 되고 동의(動意)를 생하여 유자격(遊子格)이 된다.
  정단사는 고생을 인내하여 후에 목적을 달성한다. 일지의 화개(華蓋)가 일간상신에 복(伏)하면 대인(待人)은 돌아가지도 오지도 않으며, 도주(逃走)한 자는 스스로 돌아온다. 미(未)가 진(辰)에 가해지고 추월(秋月)은 관신(關神)이 상병(裳倂)하기 때문에 가족은 쇠패(衰敗)한다.
  비전에 말하기를 ｢묘신(墓神)이 생기(生氣)를 형성하는 것은 구하지 않아도 스스로 재(財)가 모여든다｣고 하였다. 백법에 말하기를 ｢빈주(賓主)가 투합하지 않는 것은 지형(支刑)이 상에 있으며 귀신(貴神)이 공망에 앉는 것은 기쁜 일이 허(虛)로 끝난다｣고 하였다.
  삼전은 모두 토(土)로써 가색(稼穡)으로 되고 토(土)는 침정(沈靜)하여 중정(中正)으로서의 책임이 광대하다. 토(土)가 충형(冲刑)될 때는 종고(從庫)로 되는 것은 경작(耕作)하여 얻는 상으로서 5를 생하여 10으로 된다. 즉 토(土)의 이치이다. 모든 삼전(三傳)이 염상(炎上)․곡직(曲直)․윤하(潤下)․종혁(從革)과 가색(稼穡)의 5격은 어떤 일도 하나가 아니고 중집된 일이다. 그렇지 않으면 두세개에 걸쳐 모든 일을 다른 사람에게 위탁하게 된다. 길장이 승하면 전진하고 흉장은 분할(分割)하게 되어 불리하다. 어떤 일도 결과가 완결되지 않고 겹쳐서 발생하게 된다. 순정(旬丁) 또는 천마(天馬)가 승하므로 유자격(遊子格)은 동마(動馬)로 되고, 유행(遊行)을 좋아하며 동분서주의 상이다. 삼전이 양신(陽神)을 나오는 것은 원행(源行)을 바란다. 출양(出陽)이라는 것은 초전은 미(未)의 음지(陰支), 중전은 술(戌)의 양지(陽支)로 되는 것을 말한다.
  일귀는 역행하여 삼전이 사(蛇)․음(陰)․백(白)의 흉장(凶將)이고 야귀는 삼전이 청(靑)․주(朱)․후(后)이다. 만약 사계월(四季月) 또는 하월(夏月)에 정단할 때는 토(土)는 왕상(旺相)하고 청신(靑神)을 발용(發用)하여 기세가 있다. 그러므로 일간상신은 묘(墓)에 복(伏)하는 해설을 채용하지 않는다.`
  ,
  // 11
  `  이 과는 4과 중에 삼과에 상하의 상극이 있고 일하가 일상을 극하는 신오(申午)를 초전으로 하는 중심과(重審課)이다. 삼전이 신술자(申戌子)로서 일신(一神)을 사이에 두고 있기 때문에 간전격(間傳格)으로 되어 섭삼연격(涉三淵格)이라고 부른다.
  신(申)은 관귀효(官鬼爻)이고 주작이 공망에 승하여 소쟁사(訴爭事)는 가장 흉하다. 기타의 건은 공망하여 힘이 없고 또 제지(制地)에 있으므로 흉을 보아도 흉을 만나지 않는다. 섭삼연격(涉三淵格)은 분주조격(奔朱阻隔)의 징조가 있다. 문을 나와도 차(車)가 없고 건너려고 하여도 배가 없는 상이나 처음에는 어렵고 후에 고로(苦勞)를 풀 수 있다. 섭삼연이란 신(申)에 의해 술(戌)의 음경(陰境)에 이르고 말전은 더욱 깊은 음경인 야반(夜半)에 이름으로써 더욱 음기(陰氣)가 깊어지는 것이 있다. 모든 음기에 치우칠 위험한 상이다.
  일지상신 축(丑)은 정신(丁神)으로 둔간한다. 백호가 승할 때는 가족이 재병(災病)에 걸린다. 또 축토(丑土)는 해수(亥水)를 극하여 가옥을 손상한다. 백법에 말하기를 ｢희(喜)와 기(忌)는 공망의 묘기(妙機)이며 피아(彼我)가 시의(猜疑)하는 것은 육해(六害)가 서로 따른다｣고 하였다. 공망의 묘기격(妙機格)은 백법(百法)의 제94법이다. 그것은 공망을 요구하는 것과 요구하지 않는 것이 있다. 공망을 요구하는 것은 극(剋)․도(滔)․묘신(墓神)․천신(天神)에서 일간을 극하는 것 등이고, 공망을 요구하지 않는 것은 아신을 생하는 신 및 구신(救神)․천덕(天德)․생기(生氣)․재관(財官)․일간에서 극하는 천신(天神) 등으로 어느 것이나 공망은 불길하다.
  이상과 같이 공망은 일률적으로 흉의 작용만을 하는 것은 아니다. 요컨대 나에게 불리한 것은 공망이  좋고 나에게 유익한 신은 공망을 싫어한다. 이와 같이 공망이 길흉 두 가지의 작용을 형성하는 것이 공망의 묘기(妙機)다. 일귀는 역행하여 삼전이 귀(貴)․음(陰)․상(常)의 길장(吉將)이고, 야귀는 삼전이 구(勾)․주(朱)․귀(貴)이다.`
  ,
  // 12
  `  이 과는 제4과에 축자(丑子)의 상극이 있을 뿐인 원수과(元首課)이다. 4과를 초전으로 하는 것은 맥월격(驀越格)이라고 한다. 삼전은 진연여격(進連茹格)이다.
  일지상신이 자(子)이고 일간상신이 사(巳)이다. 또 을(乙)의 기궁(寄宮)은 진(辰)으로 결국 축인묘진(丑寅卯辰)의 4신은 일간․일지에 끼인(來)다. 그러므로 삼전에 진(辰)의 일신(一神)을 보지 못한다. 그리하여 진(辰)의 일위(一位)가 허(虛)가 되므로 공허격(空虛格)이라고 부른다. 을일(乙日)은 진(辰)을 재신(財神)으로 하고 진(辰)의 재(財)가 끼이면 암재(暗財)로 풍성함을 얻는다.
  년명 육소(年命 六所)에서 청룡을 보면 재리(財利)는 뜻대로 진척된다. 원수과는 상에서 하를 극하여 순극(順剋)이라고 한다. 만사가 순리순정(順利順正)으로 되어 문란하지 않고 모든 과의 수(首)로 되기 때문에 원수과라고 이름을 붙였다. 이 과는 먼저 초전을 비롯한 일진년명(日辰年命) 및 중․말전을 다시 관찰하여 길흉의 쇠왕(衰旺) 등으로 정단을 내린다.
  4과 중에 3과에 극적이 없고 제4과에만 극이 있어 초전을 이루면 맥월격(驀越格)으로 된다. 정단의 사건은 우연히 맥진(驀進)하여 성립된다. 백법에 말하기를 ｢천반(天盤)이 지신(地神)의 앞에 있고 12국의 특성으로서 이와 같은 것은 나망(羅網)이라고 하여 모사(謀事)는 졸작(拙作)을 이룬다｣고 하였다. 
  삼전의 축인묘(丑寅卯)는 진여격이므로 축각(丑刻)․인각(寅刻) 및 묘(卯)가 일출(日出) 상태면 양(陽)으로 전변하고 명랑하게 진행하여 광명을 얻는다. 공명은 달성하고 명성도 함께 향상되니 암(暗)에서 명(明)으로 된다. 다른 과격(課格)도 이 이법(理法)을 채용한다는 것을 잊지 말아야 한다.`
  ,
]


  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
