
const columnA = [
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`01`,`좌`],
  [`23:32`,`04 `],
  [`01:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`02`,`좌`],
  [`01:32`,`09`],
  [`03:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`03`,`좌`],
  [`03:32`,`06`],
  [`05:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`04`,`좌`],
  [`05:32`,`11`],
  [`07:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`05`,`좌`],
  [`07:32`,`08`],
  [`09:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`06`,`좌`],
  [`09:32`,`01`],
  [`11:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`07`,`좌`],
  [`11:32`,`10`],
  [`13:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`08`,`좌`],
  [`13:32`,`03`],
  [`15:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`09`,`좌`],
  [`15:32`,`12`],
  [`17:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`10`,`좌`],
  [`17:32`,`05`],
  [`19:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`11`,`좌`],
  [`19:32`,`02`],
  [`21:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`12`,`좌`],
  [`21:32`,`07`],
  [`23:31`,`내용 해당`],
  [``,``]
]

const columnD = [
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,10,`있음`,`01.21\n~
02.19`,`보통크기 \n고환큼

음모
적음 
분위기파`,`조용\n담담

성교후곧잠`],
  [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
  [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
  [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,8,9,`있음`,`02.20\n~
03.20`,`굵음\n적극적 매너

강력한 성교 함`,`문을 열면 적극적이 됨\n
감격을 숨기지않음`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`신뢰`,9,8,`있음`,`03.21\n~
04.20`,`단순 서툼\n시간 짧음

전,후 없이 본격적 임`,`주도적\n적극적

완벽
주의자 임`],
  [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
  [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
  [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
  [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,7,`있음`,`04.21\n~
05.20`,`때와 장소 안 가림\n
발정
타입
 곧 
시듬`,`정숙\n하고 
억제심 강함

분비물 많음`],
  [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
  [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
  [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,6,`있음`,`05.21\n~
06.20`,`기교파 반응 감상 타입\n
크고, 즐김`,`위쪽\n 절정감에 집요함

주도권을 빼앗기도`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`위선`,12,5,`있음`,`06.21\n~
07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
질이 큼

소극적 못견딤
절정 빠름`],
  [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
  [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
  [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
  [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,4,`있음`,`07.21\n~
08.20`,`정력파 女만족시키려 함\n
쉽게 지침`,`적극적 타입 수축 형\n
중년 쇠퇴 후 회복함`],
  [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
  [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
  [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,2,3,`있음`,`08.21\n~
09.20`,`헌신 리드 파 \n더러 포경

성지식 풍부함`,`부끄러움 많이 탐\n
성기는 명물임`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`신뢰`,3,2,`없음`,`09.21\n~
10.20`,`조루증 많음\n
음경은 작은 편`,`음모 많음\n
수축 형이고 능란함`],
  [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
  [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
  [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
  [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,4,1,`없음`,`10.21\n~
11.20`,`보통이상 크기\n
극적. 본능에 그침`,`은밀함\n절정을 끝없이 함

전신
경련. 수축 형 강력
 명물`],
  [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
  [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
  [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,12,`있음`,`11.21\n~
12.20`,`통크기. 강직도 있음\n
오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
  [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,11,`있음`,`12.21\n~
01.20`,`보통 크기. 강직도 있음\n
여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
  [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
  [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
  [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
]

const columnS = [
  [``,``,``,``,``,``],
  [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
  [``,``,``,``,``,``],
  [``,``,``,``,``,``],
  [``,``,``,``,``,``],
  [`후원`,`후원조력사`,`小`,``,``,``],
  [`문서`,`신규사`,`小`,`10`,``,``],
  [`부모`,`부모 관련사`,`大`,`침해기`,`외사단합`,`가정화합`],
  [`필치결벌(必治決罰)`,`주식(酒食)사로 인해 반드시 책벌이 결정되는 상`,`大`,``,`사사성취`,`엄목지상`],
  [`분우해산(紛憂解散)`,`여러 근심사가 해소되는 상`,`小`,``,`이전사有`,`(눈가림)`],
  [`금전`,`금전사업여인사`,`小`,``,``,``],
  [`상업`,`사업 관련사`,`大`,`11`,``,``],
  [`여인`,`파재극처실종사-가정막힘사`,`小`,`쇠퇴기`,`파재실종`,`가정막힘`],
  [`비금실소(飛禽失巢)`,`날으는 새의 둥지를 잃은 격`,`大`,``,`문서헌책`,``],
  [`자강불식(自强不息)`,`스스로 단속하므로 행동이 자유로운 상`,`小`,``,`지체구설`,``],
  [`급속`,`급속강제손실사`,`大`,``,``,``],
  [`형재`,`소모축재여부사`,`小`,`12`,``,``],
  [`출행`,`풀행이동사`,`小`,`과시기`,`압박감수`,`압박감수`],
  [`모사미수(謀事未遂)`,`모망(謀望)여부사-호조의 상`,`大`,``,`고난소멸`,`음인암손`],
  [`화소복지(禍消福至)`,`화난이 사라지고 복이 이르는상`,`小`,``,``,`(연인손해)`],
  [`직위`,`직위녹위 관련사`,`大`,``,``,``],
  [`자산`,`손실 여하사`,`小`,`1`,`능욕박해`,`음인災疾`],
  [`손해`,`여러 사안을 묶는 개신개혁사 有-관직 길 일반병,관재 흉`,`小`,`절정기`,`손해도망`,`(숨긴연인)`],
  [`화합타협(和合妥協)`,`단합결속이 이루어지는 상`,`大`,``,`관재병침`,`별리이혼`],
  [`허획경구(虛獲驚懼)`,`놀라고 두려워 할 실재가 없는데도 두려워 하는 상`,`小`,``,`우려`,`우려`],
  [`금전`,`파재사`,`小`,``,``,``],
  [`사업`,`외교단절사`,`大`,`2`,`금전과출`,`파재극처`],
  [`여인`,`가출사`,`小`,`면모기`,`외교막힘`,`고위청원`],
  [`춘추주희(春秋主喜)`,`봄 가을에 희사가 도래하는 상`,`小`,``,`필유성취`,``],
  [`절상인구(절상인구)`,`가족 중 몸을 다치거나 사망사가 우려되는 상`,`大`,``,`(음10월)`,``],
  [`박관`,`직위불안사`,`大`,``,``,`송구경황`],
  [`극부`,`부부남녀갈등사`,`大`,`3`,`관재질병`,`연정조우`],
  [`급속`,`외사 급속사`,`小`,`학습기`,`(음9월)`,`畏者認知`],
  [`송옥관비(訟獄官非)`,`옥송사 강제급속손실사-관직자는 아님`,`小`,``,`급속손실`,`음8월개신`],
  [`필유성취(必有成就)`,`반드시 성취하나 초기 불성 종반 성취의 상`,`小`,``,``,``],
  [`박관`,`직위불안사`,`大`,``,``,``],
  [`극부`,`부부남녀갈등사-손해가출사 우려`,`大`,`4`,`숨긴연인`,`색정사有`],
  [`색정`,`내녀 색정사 有`,`小`,`개시기`,`재화병질`,`손해가출`],
  [`공사구설(公私口舌)`,`공사 간 구설사가 있는 상`,`小`,``,`외부수혜`,`문서暗動`],
  [`구재대재(求財大得)`,`구재사는 대재를 득하는 상`,`小`,``,``,``],
  [`동요`,`내사 동요 불안 분산사`,`小`,``,``,``],
  [`상업`,`외사 도주사 우려`,`大`,`5`,`개혁침해`,`구설괴이`],
  [`개신`,`여러 일을 개혁하여 권리사를 크게 완성함-단, 官災 病우려 `,`小`,`배양기`,`비용과출`,`분산불안`],
  [`비읍임문(悲泣臨門)`,`슬피 울 비극사 당도-화복양도사(禍福兩途事)한 상`,`小`,``,`고위청원`,`虛獲驚懼`],
  [`도중청알(途中請謁)`,`고위인을 길에서 만나 청원함이 좋은 상`,`大`,``,``,``],
  [`관사`,`관사-토지송사`,`小`,``,``,``],
  [`직위`,`상인발탁사`,`小`,`6`,``,``],
  [`색정`,`1녀2남 삼각관계사- 시인하지 않고 입을 닫음`,`大`,`존재기`,`시혜조력`,`시혜조력`],
  [`고독`,`독신고독각방주말부부생리사별적 처지의 상 `,`小`,``,`遠當莫入`,`免屈난해`],
  [`봉림유사(逢林有蛇)`,`숲에서 뱀을 만난 격-토지송사 有`,`大`,``,``,``],
  [`관사`,`관사 관련사`,`소`,``,``,``],
  [`남편`,`남편 관련사`,`小`,`7`,`단순반복`,`이사의사`],
  [`색정`,`외남 색정사 有`,`大`,`단절기`,`색정사有`,`가정개혁`],
  [`이도간구(移途懇求)`,`고위인이 이동할 때 간절히 부탁함이 좋은 상`,`小`,``,`상대심정`,`도난`],
  [`구연반복(句連反覆)`,`바지-문고리-뚜겅덮는-등 단순한 동작사가 반복되는 상`,`大`,``,`難測`,``],
  [`금전`,`기이득재 패재사`,`小`,``,``,``],
  [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`8`,`불측근심`,`入室성취`],
  [`매사영속성없음`,`금전사업여자이권사는 선천적 기복재해를 겪음`,`小`,`침체기`,`동요분산`,`반목대립`],
  [`문원신지(文遠信至)`,`먼곳에서 올 문자소식을 기다리는 상`,`小`,``,``,``],
  [`헌책지체(獻策遲滯)`,`문서헌책이 지체되고 있는 상-구설사 겹침`,`大`,``,``,``],
  [`후원`,`후원조력사`,`小`,``,``,``],
  [`매사 허성불성`,`음7.8,10,11월 방문자는 종교진출하면 성공함`,`小`,`9`,`송사연발`,`禍消복지`],
  [`매사 영속성없음`,`후원조력문서부모사는 선천작 기복재해를 겪음`,`大`,`소외기`,`타인압박`,`타인압박`],
  [`불의동용(不宜動用)`,`남의 말을 듣고 함부로 움직이면 나쁜 상-특히 질병 관련 `,`大`,``,``,``],
  [`면굴난해(免屈難解)`,`굴욕을 면할 길이 없는 상-감옥 관련`,`小`,``,``,``]
]

const columnZ = [
  [``,``,0,``,``],
  [`단계`,`단계`,0,`가능`,`불가`],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [`明시작`,``,0,`후원부모문서로가능`,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [`明종료`,``,0,`금전여인사업제시 가능`,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [`明진행`,``,0,`형재친구동료의뢰가능`,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,`暗진행`,0,`자매동요친구가능`,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [`明하향`,``,0,`금전여인사압제시가능`,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,`暗절정`,0,``,`직장언변자식문제불가`],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,`暗시작`,0,``,`직장언변자식문제불가`],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,`暗종료`,0,`금전사업여인제시로가능`,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,`暗진행`,0,``,`관사남편문제불가`],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [`明진행`,``,0,``,`관사직업연인문제불가`],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,`暗진행`,0,``,`세속허사자로불가`],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [`明절정`,``,0,``,`세속허사자로불가`],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``],
  [``,``,0,``,``]
]

const columnAF = [
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [2,3,4,5,6,7,8,9,10,11,12,1],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [3,4,5,6,7,8,9,10,11,12,1,2],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [4,5,6,7,8,9,10,11,12,1,2,3],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [5,6,7,8,9,10,11,12,1,2,3,4],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [6,7,8,9,10,11,12,1,2,3,4,5],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [7,8,9,10,11,12,1,2,3,4,5,6],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [8,9,10,11,12,1,2,3,4,5,6,7],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [9,10,11,12,1,2,3,4,5,6,7,8],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [10,11,12,1,2,3,4,5,6,7,8,9],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [11,12,1,2,3,4,5,6,7,8,9,10],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [12,1,2,3,4,5,6,7,8,9,10,11],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [1,2,3,4,5,6,7,8,9,10,11,12],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0]
]


const columnAS = [
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [2],
  [0],
  [0],
  [0],
  [0],
  [3],
  [0],
  [0],
  [0],
  [0],
  [4],
  [0],
  [0],
  [0],
  [0],
  [5],
  [0],
  [0],
  [0],
  [0],
  [6],
  [0],
  [0],
  [0],
  [0],
  [7],
  [0],
  [0],
  [0],
  [0],
  [8],
  [0],
  [0],
  [0],
  [0],
  [9],
  [0],
  [0],
  [0],
  [0],
  [10],
  [0],
  [0],
  [0],
  [0],
  [11],
  [0],
  [0],
  [0],
  [0],
  [12],
  [0],
  [0],
  [0],
  [0],
  [1]
]
const columnAU = [
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유화유순후결**만약 타인과 다툰다면 먼저 나서지 말고 순응유화적 처신으로후결해야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 순응적 자세로 사안을 지연연기시캬야만 결과가 유리해지는 人`,0,0,`물러서는 상황인 듯하나 진행하게 됨**r가정불안 진퇴양난**일이 반복되어왔더라도 이루어짐`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유화유순후결**만약 타인과 다툰다면 먼저 나서지 말고 순응유화적 처신으로지체 연기하여 후결해야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유화적자세로 사안을 후결로 미뤄야만 결과가 유리해지는 人`,0,0,`일이 역조로 띄엄띄엄 진행**관직은 옮김**송쟁은 확대**병 도난 겹쳐 발생함`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유화유순후결**만약 타인과 다툰다면 선동하지 말고 후응해야 함 `,0,0,`역상적 사안이므로 억을하더라도 절대 순응적 처신으로 사안을 지연시키는 것만이 결과가 유리해지는 人`,0,0,`토지사는 움직이는 것이 좋고 가만있는 것은 나쁨**타인에 의존함**일이 반복되나 이루어짐**서민 거주 불안함`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 속결처리해야만 유리함`,0,0,`명분이 있는 사안이므로 정정당당하게 선수적으로 종결하는 것이 유리한 人`,0,0,`여럿을 하나로 묶어 개신개변**부부이별사도 많음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`내부금전질투의심**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`제일 친하고 가까히 사는 자를 택해 의논해야함호의를 쉽게 받지 말 것`,0,0,`관직승진, 단 서둘러야, 지체하면 불가함**권리결정, 단 칼이 무디어 도장을 새길 수 없는 격의 사정임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`동요불안분산반복**만약 타인과 다툰다면 천지가 뒤집힌 상태로 간신이 있어 재난이 밖에서 침범함으로 신중해야 함`,0,0,`배신사이고 어떤 일도 오래가지 않으므로 1회적으로 서둘러 마쳐야 함`,0,0,`토지사 진행 불성**종적없이 숨고싶은 심정임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유화적 자세로 후결하는 것이 유리해지는 人`,0,0,`일이 반복되지만 결구 성사됨**관공직은 자리 옮김`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응적 처신으로 사안을 지연시키는 것만이 결과가 유리한 人`,0,0,`여럿을 하나로 묶어 개신개변**부부이별사인 경우도 많음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 유화적 처신으로 사안을 지연시키는 것만이 결과가 유리해지는 人`,0,0,`토지사, 단 서둘러야-지체 연기는 불성**불비부족여건**1남2녀삼각관계 있음**상대는 입을 닫음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유화적 자세로 후결하는 것이 유리해지는 人`,0,0,`서리내린 다리를 걷는 격**진퇴 곤란의 처지임**일이 띄엄띄엄 진행되어 퇴가 좋음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 속결처리해야만 유리함`,0,0,`명분이 있는 사안이므로 정정당당하게 선수적으로 종결하는 것이 유리한 人`,0,0,`진행하는 것이 좋음**자칫 법망에 걸림**신택(身宅) 불안`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`부동신음**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`동(動)하려해도 움직일 수 없는 처지 환경 여건 임**구금상태인 경우로 있음`,0,0,`토지사, 단 서둘러야-지체 연기는 불성**종적없이 숨어버리고 싶은 심정 임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0]
]
const columnBE = [
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`악성`,`진위반반`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`무력인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`충성자이나 무력`,`선성`,`진싫이나 허사`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`무력인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`허위`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`피해인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`엄중한이득인뜻이 안 맞으면 피해인`,`타발동급속사이전사정`,`기사`,`신구중간사`,`급속내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`아발동사`,`미사`,`구사불성함`,`외사`,`표면사`,`배신무력`,`보성`,`허위`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`손해인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`아발동사`,`미사`,`구사`,`아표면외사 타급속이면내사`,`아타동시사`,`배신`,`보성`,`허위`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`아권리가져갈인`,`아발동후회사`,`미사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이익인의지할 수있으나 비용듬`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`선성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`아발동사후회심있음`,`기사`,`구사`,`외사`,`표면사와이면사겹침`,`배신`,`보성`,`허위`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``]
]
const columnBO = [
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥 `],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-공허 `],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`공허-이사 필요`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-색정사有 `],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`공허불성`,`쇠 `],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`공허불성`,`흥`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠-압박고민`,`가정색정가출사발생`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥 `],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`가택권리양도 이사 의사`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠`,`비용과출 가택 싫음`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥-파재실종사발생`],
  [``,``],
  [``,``],
  [``,``],
  [``,``]
]
const columnBS = [
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`승 화해`,`승 화해`,`승 화해`],
  [``,``,``],
  [``,``,``],
  [`무룍`,`무력`,`무력`],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 3차 비김`,`3차 비김`,` 3차 비김`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`승 양보`,`승 양보`,`승 양보`],
  [``,``,``],
  [`승 단합`,`승 단합`,`승 단합`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [` 무력`,`무력`,` 무력`],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [`패 화해`,`패 화해`,` 패 화해`],
  [``,``,``],
  [`  승     양보`,`승 양보`,`  승 양보`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 3차 비김`,`3차 비김`,` 3차 비김`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`비김`,`비김`,`비김`],
  [``,``,``],
  [``,``,``]
]
const columnBV = [
  // [0,0,0,``,0,0,0,``],
  // [용모,가문,6기준,``,용모,가문,6기준,``],
  // [0,0,0,``,0,0,0,``],
  [3,3,6,`음1월`,3,1,4,`음1월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,6,`음2월`,2,1,3,`음3월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,1,3,`음11월`,1,1,2,`음1월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,3,5,`음2월-위배우려`,1,2,3,`음8월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,1,3,`음1월`,3,3,6,`음7월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,1,3,`음12월`,3,2,5,`음6월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [1,2,3,`음11월`,2,1,3,`음5월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,6,`음10월-연기 또는 위배됨`,2,2,4,`음4월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,1,3,`음8월`,2,3,5,`음7월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,3,5,`음2월-연기우려`,2,2,4,`음8월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,6,`음3월`,2,3,5,`음6월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,2,4,`음2월`,2,2,4,`음4월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``]
]
const columnCE = [
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높고 업무효과도 중간 정도 있게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`유순하고 준수 미모이나 업무실적이 없게되며 자리 계속이 안됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 조급하며 업무의 송쟁이 있게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인정미는 있어도 사심이 많으며 업무출비가 많게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`고집이 세고 자산에 위배 또는 차질을 빚음`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`언어가 교묘하여 속이고 무능하여 동요불안을 일으키고 업무실정이 없게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`언어가 불성실하고 속이는 부분이 있게 됨**단 교육계는 의지가 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 조급하며 업무의 단합 개신 개혁을 하지만 결국 심한 피해를 초래함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 조급하며 토지문제의 송쟁을 일으킴`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`둔한 것 같아도 상대를 잘 조정하는 능력이 있고공적인 업무에 충실함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`언어가 교묘하여 속이는 점이 있으나 업무는 질서있게 진행함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`둔한 것 같으나 상대를 잘 조정하는 능력이 있으나 마무리가 무력함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0]
]
const columnCL = [
  [``,``,``,``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
  [`1.2월`,`음월적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
  [`4.5월`,``,`반대없음`,`불이행`,`선이후이`,`進  \n向`,`   원`,``,``,``,``,``],
  [`7.8월`,`집集`,``,``,`서둘러야`,`초기완성`,``,`留`,`화합`,`북북동`,``,`정동`],
  [`10.11`,`다집 多集`,``,``,``,`중말허무`,`음10월 원`,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,`바다건넘`,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`중반반대`,`불이행`,`선이후이`,`退  \n背`,``,``,``,``,``,``],
  [`7.8월`,``,`이면측근`,``,`문제허사`,``,``,`留`,`주인무력`,`남남동`,`동남`],
  [`10.11`,``,`제압함`,``,`우려당장`,``,`음10워뤈`,``,``,``,``,``],
  [`3.6\n9.12`,`10월集`,``,``,`완결필요`,``,`바다건넘`,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`집集`,`반대없음`,`불이행`,`사안허당`,`進  \n向`,`  원`,``,``,``,``,``],
  [`7.8월`,``,``,``,`포기요망`,`초기완성`,``,`留`,`주인무력`,`정동`,``,`남남동`],
  [`10.11`,``,``,``,``,`중반종반`,``,``,``,``,``,``],
  [`3.6\n9.12`,`다집 多集`,``,``,``,`허무허사`,``,``,``,``,``,``],
  [`1.2월`,`집集`,`내부담합`,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`다집 多集`,`단합반대`,`불이행`,`선난후이`,`進  \n向`,`음4,5월 원`,``,`세입피로`,``,``,``],
  [`7.8월`,``,`유일측근`,``,`사안개신`,`측근필요`,``,`留`,`설기쇠약`,`남남서`,`북북동`,``],
  [`10.11`,``,`제압함`,``,`피해우려`,`전화극복`,`다른월 근`,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,`포기요망`,`됨`,``,``,``,``,``,``],
  [`1.2월`,`다집 多集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`내부반재`,`불이행`,`선난후이`,`退  \n背`,`  근`,``,`세입피로`,``,``,``],
  [`7.8월`,``,`표출안됨`,``,`아능력부`,`급속종결`,``,`去`,`설기쇠약`,`북북동`,`동북`,``],
  [`10.11`,`집集`,``,``,`족함`,`은 進向`,``,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대없음`,`불이함`,`후이`,`退  \n背`,`  원`,``,`주인세입`,`정남`,``,``],
  [`7.8월`,``,``,`9월외`,`아능력부`,`아 능력 부`,``,`去`,`동시동요`,`세밀심사`,`정동`,``],
  [`10.11`,``,``,`허사우려`,`족함`,`족함`,``,``,``,`결정함`,``,``],
  [`3.6\n9.12`,`9월集`,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,`다집 多集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`측근반대`,`불이행`,`후난`,`退  \n背`,``,``,`주인조력`,``,``,``],
  [`7.8월`,``,`내부제압`,``,`신속종결`,`하극상`,``,`去`,`결국상호`,`정동`,`동남`,``],
  [`10.11`,`집集`,``,``,`요망`,``,`음10월 원`,``,`손해사有`,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,`다른월 근`,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`내부단합`,`불이행`,`후난`,`進  \n向`,``,``,``,``,``,``],
  [`7.8월`,`다집 多集`,`극렬반대`,``,`포기요망`,`지구전`,`음8월 원`,`留`,`주인후원`,`정서`,`남남서`,``],
  [`10.11`,``,`숨은측근`,``,``,`비선측근`,`다른월 근`,``,``,``,``,``],
  [`3.6\n9.12`,`집集`,`겨우제압`,``,``,`필요`,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`집集`,`반대없음`,`불이행`,`포기요망`,`進  \n向`,`  원`,``,`주인세입`,``,``,``],
  [`7.8월`,``,``,``,``,`송쟁감안`,``,`去`,`파재극처`,`정북`,`정동`,``],
  [`10.11`,``,``,``,``,`음1,2월 財`,``,``,`실종우려`,``,``,``],
  [`3.6\n9.12`,`다집 多集`,``,``,``,`得함`,``,``,``,``,``,``],
  [`1.2월`,``,`숨은측근`,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대`,`불이행`,`선이후이`,`退  \n背`,`  원`,`留`,``,``,``,``],
  [`7.8월`,`다집 多集`,`본인`,``,`권리양도`,`권리양도`,``,`이사이전`,`주인이사`,`정남`,``,`남남서`],
  [`10.11`,``,`제압함`,``,`됨`,`됨`,``,`권리양도`,`세입피해`,``,``,``],
  [`3.6\n9.12`,`집集`,``,``,``,``,``,`됨`,``,``,``,``],
  [`1.2월`,`다집 多集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대없음`,`불이행`,`후이`,`進  \n向`,`  원`,`去`,`주인압박`,`동북`,``,`북북동`],
  [`7.8월`,``,``,``,`반목대립`,`초중반종`,``,``,``,``,``,``],
  [`10.11`,`집集`,``,``,`송쟁우려`,`결요망됨`,``,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`집集`,`반대없음`,`불이행`,`초반아니`,`進  \n向`,`  근`,`留`,`무난`,`정동`,``,`동북`],
  [`7.8월`,``,``,``,`면파행`,`초중완결`,``,``,`주인세입`,``,``,``],
  [`10.11`,``,``,``,``,`종반허무`,``,``,`변모시도`,``,``,``],
  [`3.6\n9.12`,`다집 多集`,``,``,``,``,``,``,``,``,``,``]
]
const columnCY = [
  [``,``,``,``,``,``,0,``],
  [``,``,``,``,``,``,0,``],
  [``,``,``,``,``,``,0,``],
  [`질병`,`수귀`,`익사사망`,``,``,`묘좌`,0,`있음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`있음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,`여고음강`,`시할머니`,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`있음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,`여고음강`,`시할머니`,``,``,`육친`,0,``],
  [`질병`,`도로귀`,`교통사고`,``,``,`묘좌`,0,`있음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,`도로귀`,`교툥사고`,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,`저주구설`,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,`도로귀`,`교통사고`,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,`도로귀`,`교통사고`,``,``,`묘좌`,`남남동`,`없음`],
  [``,``,``,``,``,`묘향`,`북북서`,``],
  [`생업`,`신부정귀`,`신성침범`,``,``,`전경`,`화원`,``],
  [``,``,``,``,``,`묘속`,`뱀굴`,``],
  [`가정`,``,``,`상가부정`,`조문출입`,`육친`,`사망자녀`,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,`고모신사`,`성물이동`,`육친`,0,``],
  [`질병`,`전사귀`,`전쟁사망`,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`있음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`있음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,`고모신사`,`성물이동`,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,`상가부정`,`음사부정`,`육친`,0,``]
]
const columnDH = [
  [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
  [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
  [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
  [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
  [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
  [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
  [2,`매사영속성없음`,`매사불성 활인진출하면 자립함`,``,`분쟁근심해소사`,`화소복지사`,`빈궁**가정 막힘**외 파재극처실종사우려`,`내사 급속사**문서상신답지체사`,`명예자산권리사`,`직녹자산권리사`,`입실성취사-남녀관계설립-이동사`,`상해파재극처사-실종사 우려`,`빈궁**외교막힘**위역사`,`외사 급속사`,`불측근심사`,`상대 정 난측사`,`빈궁**여건전도**내女 색정사有`,`손괴재물사`,`병재**빈궁**외사동요불안분산사`,``,` 승진영전사`,`도적악의-공격말 것`,`관재**외男색정사有**남녀종업원 공모`,`매사위역사**음인암손사`,`빈궁**매사불성 종교 귀의 절대적 `],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`내사 출행이동원행사`,`빈궁**가나있으나 좋음`,``,``,``,`재난해소`,`빈궁`,`외사 출행이동사`,`관재`,`이동`,`굴욕난해사`,`재난해소`,`전도**빈궁`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,`재난해소`,``,``,``,``,`내 반목대립송쟁사`,`침체경구사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [3,`매사위역사**음인암손사`,`매사불성 종교진출하면 자립함`,`내사 출행이동원행사`,`매사불성 활인진출하면 자립함`,``,`분쟁근심해소사`,`화소복지사`,`가정 막힘**외 파재극처실종사우려`,`내사 급속사**문서상신답지체사`,`외사 출행이동사**관재`,`직녹자산권리사`,`사사성취사**이동사`,`굴욕난해사`,`빈궁**외교막힘**위역`,`외사 급속사`,`불측근심사**상해파재실종사 우려`,`상대 정 난축사`,`빈궁**관재**음4,5월 화재주의`,`손괴재물사`,`빈궁**병재**외사동요불안분산사`,``,` 승진영전사`,`도적악의-공격말 것`,`관재**외男 색정사有`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`내 반목대립송쟁사`,`빈궁`,``,`가나잇으나 좋음`,``,`전화위복재난해소`,``,`전화위복`,``,`가정막힘`,``,`재난해소`,``,`상해파재극처사-실종사우려`,``,`음4,5월 화재주의`,``,`내女 색정사有**재난해소`,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [4,`도적악의-공격말 것`,`전도**외男 색정사有`,`매사위역사**음인암손사`,`빈궁**매사불성 종교진출하면 자립함`,`내사 출행이동원행사`,`여건전도**매사불성 활인진출하면 자립함`,``,`분쟁근심해소사`,`화소복지사`,`빈궁**외 파재극처실종사우려`,`내사 급속사**문서상신답지체사`,`외사 출행이동사**빈궁**가정 막힘`,`직녹자산권리사`,`사사성취사**이동사`,`상해파재극처사-실종사 우려`,`빈궁**외교막힘**위역`,`외사 급속사`,`불측근심**관재**음1,2,4,5월부자`,`상대 정 난측사`,`빈궁**관재**내女 색정사有`,``,`손괴재물사`,``,` 승진영전사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,`내 반목대립송쟁사`,``,``,`가나있으나좋음`,``,`전화위복`,``,`가장막힘**전화위복`,``,`재난해소`,``,`재난해소`,`굴욕난해사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [5,`고위 행길에서 만나 청원하는 상`,``,`외男 색정사有`,`가택이사-간사은닉사`,`빈궁**매사불성**종교진출자립함`,`내사 출행이동원행사`,`매사불성 종교진출하면 자립함`,`내사 출행이동원행사`,``,`남모르는 능욕박해사`,`모망미수**음3,4,5,6,9,11월 이시간 부자`,``,`명예자산위신출행**기이빈궁`,`외사 출행이동원행사`,`빈궁**관재**병재`,`직녹자산권리사`,`상해파재극처사-실종사 우려`,`관공직 희사**일반인 불리`,`빈궁**관재**상해內파재실종사 우려`,`외사 급속사`,`빈궁*내女 색정사有`,`문서암동사`,`두려운자 당도-송구경황사`,`송구경황사-통정인 나타남`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,`내 반목대립송쟁사`,`문서유실사`,``,`특명성취사`,`전화위복재난해소`,``,`가정막힘**전화위복`,`외 파재극처실종사우려`,`내사 급속시`,`공공사사구설사`,`이동`,`가족절상사-이동사`,`외교막힘`,`매사위역사`,`음4,5월 화재주의`,`도적추격-못잡음`,`음4,5월 화재주의`,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [6,`송구경황(悚懼驚惶)사-통정안나타남`,``,`고위인에게 도로상에서 청원`,``,`빈궁**관재**외男 색정사有`,`가택이사-간사은닉사`,`여건전도**매사불성**종교진출자립함`,`내사 출행이동원행사`,`매사불성 종교진출하면 자립함`,`특명성취사`,`관재**전화위복`,`남모르는 능욕박해서`,`모망미수사`,`외 파재극처실종사우려`,`출행**빈궁**관재`,`외사 출행이동사**공공사사 구설사`,`빈궁**관재**병재`,`직녹자산권리사`,`관공직 희사**일반인 불리**전도`,`매사위역사**외교막힘`,`빈궁**도적추적-못잡음`,`병 중태-구설놀램`,`빈궁**내女 색정사有`,`문서암동사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`가정동요불안사`,``,`재난해소`,``,`재난해소`,``,`문서유실사`,`내 반목대립송쟁사`,``,``,``,``,`가정막힘**전화위복`,``,`가정막힘`,`내사 급속사`,``,`가족절상사-이동사`,`상해파재극처사-실종사 우려`,``,`음4,5월 화재주의`,`외사 급속사`,`음4,5월 화재주의`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [7,`빈궁**내女 색정사有**충산동요이별`,`문서암동사`,`송구경황사-통정인 나타남**가정충산동요이별`,``,`고위인에게 도로상에서 청원**충산동동요이별`,``,`빈궁**관재**외男 색정사有`,`가택이사-간사은닉사`,`관재**매사불성**종교진출자립함`,`내사 출행이동원행사`,`관재**매사불성**활인진출자립함`,`특명성취사`,`관재`,`남모르는 능욕박해서`,`모망미수**음3,4,5,6,9,12월 이시간 부자`,`외 파재극처실종사우려`,`빈궁**가정막힘**내사 급속사**공공사사 구설사`,`외사 출행이동사`,`빈궁**병재**이동사`,`직녹자산권리사`,`충산동요이별**외교막힘`,`관직 희사**일반인 불리**상해파재극처사-실종사 우려`,`도적추적-못잡음`,`병 중태-구설놀램`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`음4,5월 화재주의`,``,``,``,``,``,`충산동요이별`,``,`문서유실사`,`내 반목대립송쟁사`,`충산동요이별`,``,`충산동요이별`,``,`가정막힘**전화위복`,`충산동요이별`,`충산동요이별**재난해소`,`음4,5월 화재주의`,`충산동요이별**재난해소`,`가족절상사-이동사`,`외교막힘`,`매사위역사`,`음4,5월 화재주의**小火`,`외사 급속사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [8,`빈궁**도적추적-못잡음`,`병 중태-구설놀램사`,`빈궁**내女 색정사有`,`문서암동사`,`가정동요불안사여건전도`,`송구경황사-통정인 나타남`,`고위인에게 도로상에서 청원**관재`,``,`빈궁**관재**외男 색정사有`,`가택이사-간사은닉사`,`빈궁**매사불성** 종교진출하면 자립함`,`내사 출행이동원행사`,`관재**매사불성** 활인진출하면 자립함`,`특명성취사`,``,`남모르는 능욕박해사`,`모망미수**음3,4,5,6,9,12월 이시간 부자`,`외 파재극처실종사우려`,`빈궁`,`외사 출행이동사`,`빈궁**관재**병재`,`직녹자산권리사`,`상해파재극처사-실종사 우려`,`관공직 희사**일반인 불리`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`음4,5월화재주의`,`외사급속사`,`음4,5월화재주의`,``,``,``,`재난해소`,``,`재난해소`,``,`문서유실사`,`내 반목대립송쟁사`,``,``,``,``,`가정막힘**전화위복**`,`음4,5월 화재주의`,`내사 급속사`,`공공사사 구설사`,`이동`,`가족절상사-이동사`,`외교막힘`,`매사위역사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [9,`관공직 희사**일반인 불리`,`매사위역사`,`빈궁**외사 급속사**도적추적-못잡음`,`병 중태-구설놀램사`,`빈궁**내女 색정사有`,`문서암동사`,`송구경황사-통정인 나타남`,`가정동요불안사`,`고위인에게 도로상에서 청원`,``,`빈궁**관재**외男 색정사有`,`가택이사-간사은닉사`,`빈궁**매사불성** 종교진출 자립함`,`내 반목대립송쟁사`,`매사불성 활인진출하면 자립함`,`특명성취사`,`酒食수벌사`,`남모르는능욕박해사`,`모망미수**음3,4,5,9,12월 이시간 부자`,`외 파재극처실종사우려`,`관재**빈궁**명예자산**위신 무망`,`외사 출행이동사`,`빈궁**병재**이동`,`가족절상사-이동사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`상해파재극처사-실종사 우려`,``,`재난해소`,`음4,5월 화재주의`,``,`음4,5월 화재주으ㅣ`,``,``,``,``,``,``,`문서유실사`,`내사 출행이동원행사`,``,``,``,``,`가정막힘전화위복`,``,`내사 급속사`,`공공사사 구설사`,`직녹자산별리사`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [10,`고위안좌-대중위한 이익경영강구사`,`직녹자산별리사`,`빈궁**위역**상해파재극처사-실종사 우려`,`굴욕난해사`,`병 중태-구설놀램사`,`모든 일 좋지 않음`,`빈궁**내女 색정사有`,`미세송사-고시득길사`,`빈궁**가정동요불안사`,`봉림유사-숲에서 뱀을 만난 격`,`고위인에게 도로상에서 청원`,``,`외男 색정사有`,`간음무도사`,`빈궁**매사불성** 종교진출 자립함`,`내 반목대립송쟁사`,`빈궁**매사불성**활인진출자립함`,`도적난포사`,`전화위복`,`酒食수벌사`,`빈궁**전도**전화위복 단, 부족`,`외 파재극처실종사우려`,`빈궁**전도**명예자산위신출행무망`,`외사 출행이동사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`재난해소`,``,`외교막힘`,`매사위역사`,`외사 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,``,``,``,`음인암손사`,`내사 출행이동원행사`,``,``,``,``,`가정막힘`,`손괴재물사`,`내사 급속사`,`공공사사구설사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [11,`외사 출행이동사**재물암동사`,`빈궁**전도**전화위복`,`직녹자산권리사`,`제사불리**관재**전도`,`관공직 희사** 일반인 불리 `,`內상해파재극처사-실종사 우려`,`외사 급속사**앙화소멸사`,`빈궁**병재`,`상대 정 난측사`,`내女 색정사有`,`가정동요불안사`,`모망난수사**음3,4,5,6,9,12월 이시간 부자`,`견련반복사`,`빈궁**관재**병재`,`간음사이익-정대적은 재앙임`,`관재**외男 색정사有`,`매사위역사**문서유실사`,`빈궁**매사불성** 종교진출자립함`,`침해소제-이익진취사`,`빈궁**여건전도**매사불성 활인진출 자립함`,``,`분쟁근심해산사`,`송구경황사-색정인 출현`,`가정막힘**외 파재극처실종사우려`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`내사 급속사`,`퇴보감수`,`도난사`,``,`관직자 길-일반인 흉`,`외교막힘`,`음4,5월 화재주의`,`재난해소`,``,`음4,5월 화재주의`,``,`재난해소`,``,``,``,``,`내 반목대립송쟁사`,`내사 출행이동사`,``,``,``,``,``,`전화위복`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [12,`분쟁근심사 해소`,`빈궁**관재**가정막힘**외 파재극처실종사우려`,`외사 출행이동사**문서상신답지체사`,`출행**관재**빈궁`,`직녹자산권리사`,`사사성취**이동`,``,`빈궁**병재**상해파재극처사-실종사 우려`,`외사 급속사**앙화소멸사`,`불측근심사`,`상재 정 난측사`,`내女 색정사有`,``,`가정동요불안사`,`영전도약사`,`빈궁**관재**병재`,`도적악의-공격말 것`,`관재**외男 색정사有`,`음3,6,9,12월 이시간 자는종교진출하면 자립함`,`빈궁**매사불성**생업동요**내 반목대립송쟁사`,`음7,8,10,11월 이시간은 활인진출하면 자립함`,`매사불성** 생업동요 가정반목대립`,``,`분쟁근심해산**순리전진**전화위복**단, 부족`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,`전화위복`,`내사 급속사`,``,``,``,``,`외교막힘`,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`재난해소`,``,`재난해소`,`내사 출행이동사**매사위역사`,`종교진출 절대 적-음인암손사`,`폐한인**가나있으나 좋음`,`종교진출 적의`,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [1,``,`분쟁근심해소사**전화위복`,`분쟁근심사 해소`,`빈궁**가정막힘**외 파재극처실종사우려`,`외사 출행이동원행사**문서상신답지체사`,`빈궁`,`직녹자산권리사`,`사사성취**이동`,``,`빈궁**위역**상해파재극처사-실종사 우려`,`외사 급속사**앙화소멸사`,`불측근심사`,`상대 정 난측사`,`빈궁**내女 색정사有`,``,`빈궁**병재**가정동요불안사`,`영전도약사`,`빈궁**관재**병재`,`도적악의-공격말 것`,`빈궁**관재**외男 색정사有`,`음3,6,9,12월 이 시간은 종교진출성공해당자`,`빈궁**매사불성 **내 반목대립송쟁사`,`음7,8,10,11월은 활인진출성공 해당자`,`내사 출행이동사**가나있으나 좋음`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,`전화위복`,`내사 급속사`,`재난해소`,``,`재난해소`,``,`외교막힘`,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,``,``,``,`매사위역사**음인암손사`,`종교진출 절대적`,`도적근심의심사`,`활인진출 절대적`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
]


const CHOSA1 = [
  `■ 굴욕적인 일이 풀리지 않고 있네? 편히 발 뻗을데가 없으니 도망가고 싶겠어! 고래가 산중물에 잠긴 격이야! 
⊕가택상가부정
⊖가택음사부정이들어     막혔어!

⊕⊖신변고모신사부정이들었어 !`,
`■ 일이 반복되기만 해서 구경거리가 되어버렸네? 그래도 참고 견디면 위기를 구해줄 사람이 나타날거야! 

⊕ 술자리조심 
⊖ 근심해소됨`,
`■ 계속 갈까, 그만둘까 걱정이겠는데 만사가 비었으니 몸(과숙)도 빈 격일세?
他人에게 의탁ㆍ의뢰하고 기다릴 수밖에 없겠어! 

⊖ 수귀에다가 집아래복시가    있어 방해받고 있어! 
⊕ 여귀발동이야.`,
`■ 목적사는 이루어지지 않고 땅ㆍ가택에 소송만 붙었으니 갈수록 태산이야! 
숨어서 노리는 女鬼나 달래보는 수밖에!
⊕가택저주원심부정, 
⊖가택여고음강 부정이 들어    재난이 커!
⊖신변고모신사부정이들었어 !`,
`■ 안팎으로 귀신이 발동하여 개혁한다는 것이 자칫 질병 관재로 될 걱정이네! 관직자는 길(吉) 이 문제로 윗사람을 찾아가면 도움을 받겠어!

⊕ 도로귀 
⊖수귀발동이네?`,
`■가정여자 금전관계로  옛것을 버리는 선택을 해야만 권세와 위력을 얻게 생겼네? 
윗사람을 믿지 말고 자구책을 세워야 하겠어! 

⊕ 신부정귀, 
⊖ 전사귀 달래는게
   자구책이야!
⊖신변음사부정이 들었어!`,
`■ 돈이 주렁주렁 열린 것 같아도 내것이 되기는 글렀네? 색정불화에다
빈손에 시비만 붙어! 
피난해서 위기를 벗어나는게 상책이네! 
⊕⊖집 밑에 복시가 있어서     꼬이는 거야!
⊕⊖신변저주원심부정침범      했어!`,
`■ 참고 또 참아야만 큰 돈도 챙기고 유리한 증인도 얻고 하겠어! 
장님나라에는 애꾸가 흉인격이야!

⊕도로귀가 문제야!
⊖살상자살귀의 침범이야!

⊖신변음사부정침범했어!`,
`■ 자충수를 일으켰네?
가을이면 전진이 좋은데 다른때는 물러서야 돼! 
개혁한다고 하다가 자칫 관재ㆍ병재를 당하겠어 !!

⊕신부정, 도로귀
⊖전사귀ㆍ자살귀ㆍ가택상가부정이 칼을 부러뜨리네?`,
`■ 돈과 여자가 문제네? 한 여자가 두 남자를 차고 있어 ! 
부인이 소송을 일으키겠어!

가정에
⊕가택 고모신사 부정
⊖가택신화선영 부정이 들어서 그래 !
⊕신변음사부정이 들었어!`,
`■ 범 꼬리를 밟고 얼음장을 건너는 격이야! 
진퇴양난이네? 
지금 굴욕을 당하고 있을 운세야!

⊕ 모두 신부정귀 때문이고, 
⊖ 전사귀 때문이야!`,
`■ 금실로 구슬을 꿰고 있네? 일을 단순하게 해나아가면 되겠네 !
관사구설 시비 방어책도 필요하겠고 !
`
];

const YOGYEOL1 = [
  `■ 나무 한그루가 아홉 흙을 상대하자니 자칫 재물로 재앙이 생긴다. 또한 음식으로 위장이 상하게 된다.`,
`■ 피차 무례하므로 내 재산 토대를 지키기 어렵다. 흉이 많은 占이나 혼인점만은 좋다.`,
`■ 범이 묘를 타고 귀신을 부르니 집나간 자는 병들어 오고 서로 돕는다는 명분은 말 뿐이다.`,
`■ 돌고돌아 연결되어 낮정단은 귀신이 범과 숨어있다. 범은 위험한 것인데 스스로 생각해볼 일이다.`,
`■ 내외가 연결되어 고통스러운 일이 있으나 오히려 적이 돕게 되므로 선흉후길 할 것이다.`,
`■ 좋은건 헛것이고 나쁜건 실재이다. 관직점은 좋다. 밤정단은 신명기도가 좋고 직녹변경은 말라.`,
`■ 묘신이 집에 올라있는것은 집 밑에 해골 때문이다. 앞길이 모두 막혔으니 만약 처가 죽으면 재산을 남긴다. `,
`■ 흉 뒤쪽에 이익이 들었으니 이같은 일이 곧 닥친다. 낮정단이면 가정불화가 생기고 형제의 병은 죽는다.`,
`■ 일반인은 나쁜 일을 당하고 병재ㆍ송재의 걱정이다. 공직자는 아주 좋아 승급ㆍ승진ㆍ영전사가 있다.`,
`■ 인택이 모두 그늘이라 운무속에 있는 것 같다. 살곳을 찾아나서면 결손하거나 재산을 모으리라.`,
`■ 낮정단은 사기ㆍ허망이 있고 직장을 잃을까 걱정이다. 웃어른이 성을 내니 승진은 어렵고 길흉사가 불성한다.`,
`■ 인택이 함께 삼전에 들어 봄점은 기쁨이 있으나 흉사를 물으면 흉사가 되고 나중에야 재앙이 없어진다.`,
]
const SIBO1 = [
  `■가정에 ⊕ 상가부정⊖
음사부정이 들었다.
토지 관송사가 있다.
凶을 凶으로 제어한다. 財를 탐하여 財의 화근을 만든다. 거주의 어려움이 있다. 숨어서 재난을 피하라. 寅ㆍ卯ㆍ子띠 행년者는 걱정이 없다. 신심이 고로하나 효과는 적고 성공도 장구하지 않다. 폐색ㆍ재물ㆍ부인ㆍ문제가 순리부절이다.`,
`■어떤일도 첫 제의를 받
아들이라. 경과할수록
불리하다.
상대ㆍ목적사는 예의가 없다. 옛것을 지키고 신규사를 금하라. 光明이 없다. 불길하다. 목적달성이 안된다. 상대는 좋고 나는 나쁘다. 단, 위난중에 타인에 의해 구원을 얻게 된다. 공허ㆍ무력ㆍ상쟁운이다.`,
`■가쇠의 징조가 있다.
일을 천천히 하라. 
시작은 있으나 끝이없다.
財를 구하여 災가 된다. 타인의 천거를 받으나 허영이다. 만사공허ㆍ불통ㆍ불명이다. 몸도 고독하다. 수도자ㆍ종교인은 좋다. 他力에 의지하라. 때를 기다려야 한다. ⊖ 가택에 복시가 있다. 이사가 길하다. 
十月에 임신사가 있다.`,
`■가정에 ⊕ 저주원심
⊖ 여고음강부정이 들었다.
저주소송사가 있다.
財ㆍ女의 불화투쟁이 있다. 一男二女의 삼각관계가 있다. 父母의 재해가 있다. 과욕으로 財ㆍ女를 구하면 災가 생긴다. 조용히 있으라. 음모에 걸릴 염려가 있다. 노력해도 구태를 벗어나지 못한다. 단 辰戌띠ㆍ행년者는 凶을 면한다.`,
`■女는 부부이별을 원한다.
가내 우환발생하고 
가장을 속인다.
가인이 원한을 품고 가해하려 한다. 부부모두 음탕하다. 우연한 기회를 통해 가정 내부의 개혁을 도모하게 된다. 그것이 吉하다. 단 관공ㆍ上人에게 의뢰해야 좋다. 財는 위난중에 얻게 된다. 감언과 소중도도 결국 좋게 된다. 上人과 끝까지 버티라. 
十月에 임신사가 있다.`,
`■가내우환발생, 가장을
속인다. 가인이 원한을
품고 가해하려 한다.
사물이 갈림길에 있다. 옛것을 버려야 害를 받지 않는다. 간난심고후 권세와 위력을 얻게 된다. 무직자도 직위를 얻는다. 官者는 좋고 일반인은 험로이다. 선택의 기로에서 근ㆍ친자로 결정하라. 갑작스런 이동이 있다. 병자는 급히 신불에 기도하라. 늦으면 죽는다.`,
`■하극상 신변 동요사이다.
가택 밑에 복시가 있다.
관리는 좌천된다.
처가 사망하면 유산이 있게 된다. 토지사로 財가 분쟁된다. 어떤일도 안심이 안되고 의혹만 커진다. 거주의 애로가 있다. 도망ㆍ가출ㆍ피난의 상이다. 신심이 동요불안하여 멀리가고 싶어한다. 모든 목적사는 실익이 없고 財는 구설이 따른다.`,
`■자충수적 우환 발생함
계획사가 위험하다.
일과 사건이 하나가 아
니다. 비용 낭비가 많고 경쟁자가 있다. 협력과 우애가 안된다. 그러나 財가 大財이고, 災는 他人의 구조가 있고, 송사는 유리한 증인을 얻는다. 스스로의 능력으로 좋은결과가 있게 된다. 

⊖ 정단은 삼전협극이다. 인내가 필요하다.
十月에 임신사가 있다.`,
`■자충수적 우환 발생함
신ㆍ불 기도가 효과 있음
⊖ 계획사는 위험하다. 가정ㆍ내부의 개혁사이다. 부부이별을 고려한다. 그것이 길하다. 관리는 좋고 , 일반인은 고로한다. 신불에 기도하면 효과가 있다. 가정에서 구한다. 日干의 왕쇠로 정하라.
⊖ 정단은 男이 他婦를 유혹하여 색정거래 한다.
十月에 임신사가 있다.`,
`■⊕ 고모신사 ⊖ 신화선영 부정
관송사가 있다.
男ㆍ夫는 女에 헌신한다.
財ㆍ妻로 인해 분쟁이 된다. 人ㆍ宅이 모두 혼미ㆍ가난하다. 財ㆍ女가 모이나 부인에 의해 소송이 발생한다. 토지사로 인해 財ㆍ女의 소송이 있게 된다. 一女二男의 삼각관계가 있다. 자중은 유리, 動은 불리하다. 

⊖ 정단구직은 당년에 등관함.`,
`■가택의 권리가 이양됨
가택을 손상한다. 
자손ㆍ박관사 분쟁이 있다.
官人은 직무의 권리를 잃고 일반인은 진퇴양난이다. 존귀를 잃고 굴욕을 당한다. 가택 명의를 가족에게 이양한다. 모든 일이 실의가 없고 거짓에 속는다. 그러나 위난을 구제하는 단초가 된다. 살얼음 판을 걷는 격이다. 上人이 怒하여 도움 불가임.`,
`■가택을 손상한다.
계획사는 위험하다.
大人ㆍ君子는 大吉하다
春ㆍ冬 吉, 秋ㆍ夏 凶, 旺衰에 의해 진퇴를 訣하라. 계획을 번잡하게 꾸밀 필요가 없다. 한가지로 시류를 보고 임하면 된다. 매사를 가정을 위주로 결정하면 허물이 없게 된다.
관리는 곤란ㆍ고심한다. 과욕없이 전진하라!`,
]
const SIBO2_1 = [
  `■ 신변.ㆍ금전송쟁사이다.
내방조짐 상의 : 
토지 분쟁사가 있다.
은둔ㆍ도망ㆍ원행에 이롭다.`,
`■ 근친ㆍ신변 가택후원외사이다.
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
가정 가택 우환이 있다.`,
`■ 후원불성, 이사가 吉하다.
내방조짐 상의 : 
원행·이동사는 길하다.
어떤 일이든 처음 불성한다.
후원불성부모재액발생한다`,
`■ 가택금전ㆍ여인내사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.`,
`■ 자손ㆍ박관ㆍ부부이별사이다
내방조짐 상의 : 
조용히 있는게 이롭다.
질병·관재 예방이 필요하다.`,
`■ 직녹사ㆍ신불기도가 필요하다
내방조짐 상의 :  
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.`,
`■ 금전ㆍ여인사 초에는 안된다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
이권상실하고 재·녀 재액이
있게된다.`,
`■ 손모갈등, 남편은 겉돈다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.`,
`■官･夫,,신변외사이다.
내방조짐 상의 : 
은근하고 伏하라.
병재·관재 예방이 필요하다`,
`■ 금전송쟁 가택 동요사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`,
`■ 관사, 가택권리가 넘어간다. 
내방조짐 상의 : 
움직임이 근본이고 동기가 발동한다.
이전 이사한다.`,
`■ 손모ㆍ갈등 가정 반복사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.`,

]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/002/002_001.png`,
  `/static/images/sagwa/002/002_002.png`,
  `/static/images/sagwa/002/002_003.png`,
  `/static/images/sagwa/002/002_004.png`,
  `/static/images/sagwa/002/002_005.png`,
  `/static/images/sagwa/002/002_006.png`,
  `/static/images/sagwa/002/002_007.png`,
  `/static/images/sagwa/002/002_008.png`,
  `/static/images/sagwa/002/002_009.png`,
  `/static/images/sagwa/002/002_010.png`,
  `/static/images/sagwa/002/002_011.png`,
  `/static/images/sagwa/002/002_012.png`,

];


const HEASYEOL = [
  // 1
  `  이 과는 12궁 천지반이 천지일여(天地一如)로 되어 복(伏)하고 아직 발(發)하지 않은 과이다. 복음(伏吟)은 극이 있으면 극을 취하여 초전으로 하기 때문에 일간 을목(乙木)은 간상 진(辰)을 극하여 초전으로 한다.
  사과삼전이 모두 토(土)이므로 일목(一木)에서 구토(九土)를 극하니 전재(全財)로 되어 재(財)가 태과(太過)하다. 일간인 내 몸은 약하고 재가극왕(財家極旺)이므로 재를 탐하여 재에 의해 화근(禍根)을 이룬다. 삼전 진술축미(辰戌丑未)는 가색격(稼穡格)이 되어 어떤 일에서나 간난의 징조가 있다. 핍박하여 스스로 나올 수 없으므로 조용히 하는 것이 좋다. 특히 일간상신 진(辰)을 초전으로 하는 것은 참관격(斬關格)으로, 안거(安居)에 어려움이 따르니 자신은 숨어서 재난을 피하는 것이 좋다.
  재성(財星)이 태과(太課)하여 신(身)이 약하게 되고 따라서 나의 역량(力量)은 재(財)의 운용에 견딜 수 없다. 재 또는 부인에 의해 재화를 초래하기 쉽다. 만약 연명상신(年命上神) 또는 행년(行年)에 일간을 생조(生助)하는 수목(水木) 중 어느 하나라도 있으면 걱정할 것 없다. 오히려 내 몸을 돕는 재(財)가 된다. 이와 같은 과격(課格)은 모든 일이 처음에는 급하고 후에는 완만하게 된다. 고생은 많고 효과가 적으며 성공하여도 장구하지 못하다. 그러나 우환혐의(憂患嫌疑)의 일 및 해산과 파괴의 건은 오히려 좋다.
  비전에서 말하기를 ｢진(辰)의 천강(天罡)이 천지(天地)에 겹치는 것은 내면에 분쟁이 발생하므로 예방해야 하며 분쟁이 없어도 분실손모(紛失損耗)를 초래할 우려가 있다｣고 하였다. 백법에 말하기를 ｢전재격(全財格)은 병의 정단을 가장 싫어하고, 초용(初用)에 신(身)을 파하는 것을 신심(身心)이 함께 돌아갈 곳이 없다｣고 하였다.
  일귀는 역행하여 삼전이 구(勾)․백(白)․음(陰)이며, 야귀는 순행하여 삼전이 구(勾)․사(蛇)․음(陰)으로 된다. 이 과는 대국적(大局的)으로는 길조가 아니지만 눈앞의 정단은 그 사물에 따라 길흉을 정단해야 한다.`
  ,
  // 2
  `  4과 중에 제3과만이 토극수(土剋水)로 일하(一下)가 일상(一上)을 극한다. 이것을 채용하여 초전으로 하는 것이 중심과(重審課)이다. 삼전은 퇴여격(退茹格)이다.
  간상(干上)과 지상(支上)을 보면 묘(卯)와 자(子)가 예(禮)가 없는 형(刑)이다. 일간으로부터 묘(卯)를 보면 건록으로 되어도 정신(丁神)이 승하고, 또 묘진(卯辰)은 상하육해(上下六害)로 된다. 자(子)와 묘(卯)가 형전(刑戰)하여 제3과가 상극하더라도 제3과 자축(子丑)은 상하지합(上下支合)한다. 태상이 승하면 견우직녀(牽牛織女)가 상회(相會)한다. 그러므로 결혼을 정단하면 반드시 성공한다. 또 간상(干上)은 나로 하고 제3과는 피(彼)이며, 상대방은 타인으로 된다. 자기에게는 저해가 있고 타인은 일락(逸樂)의 상이다.
  일간상신(日干上神) 건록(建祿)은 왕록(旺祿)이되 길이라도 맹동(猛動)은 좋지 않다. 따라서 힘써 구태(舊態)를 수호하는 것이 좋다. 복록이 있더라도 망동(妄動)하면 오히려 재화가 따르므로 신중히 행동해야 한다. 요컨대 때를 기다리는 마음가짐이 중요하다. 백법에서 말한 ｢왕록(旺祿)이 몸(身)에 임하면 무익한 망동을 삼가야 한다｣는 말을 새겨야 한다.
  비전에 말하기를 ｢묘월(卯月)에 이 과를 얻어 정단할 때는 간지(干支)의 상우(上右)에 역마현무(驛馬玄武)를 보고 좌(左)에 병살적부(倂殺賊符)를 보기 때문에 도적이 초전 자축(子丑)의 방향에서 와서 피해를 받는다｣고 하였다. 그러나 말전 술(戌)이 해(亥)의 현무를 제(制)하기 때문에 도적은 얼마 안가서 포박(捕縛)된다.
  삼전은 퇴여(退茹)이고 자해술(子亥戌)은 극음(極陰) 밤중이 되어 광명이 없다. 지반 해(亥)의 천문상(天門上)에 술(戌)이 승하면 괴도천문격(魁度天門格)으로 모든 일이 불길하고, 명리(名利)를 구하여도 목적에 도달하기 어렵다. 제4과와 말전에 보인다.
  일귀는 역행하여 삼전이 상(常)․현(玄)․음(陰)이며, 야귀는 순행하여 삼전이 귀(貴)․후(后)․음(陰)으로 된다. 오직 초전이 귀신(貴神)의 도움을 기다릴 뿐이다. 육의(六義)는 위난 중에 타(他)에 의해 구원을 얻는다.`
  ,
  // 3
  `  이 과는 4과 중에 제3과 해(亥)와 축(丑)의 극이 있고 이것을 초전으로 하여 중심과(重審課)로 된다. 삼전은 역간전(疫間傳)이다. 그리하여 말전에 미(未)의 묘(墓)가 있다. 이와 같은 것을 삼전이 묘에 들어간다고 한다. 대인(待人)은 오지만 묘상에 밤정단 백호가 승하면 병에 걸려 돌아온다.
  말전이 중전을 생하고 중전이 초전을 생하며, 초전 해(亥)의 수(水)는 일간을 생하여 삼전이 체생격(遞生格)에 해당한다. 그러므로 초․중전은 해(亥)의 공망에 해당하여 결국은 무력(無力)으로 끝난다. 즉 생하더라도 공망하여 생을 이루지 못하며, 여전히 시작은 있으나 끝이 없다. 말전 미(未)는 일간의 재(財)가 되지만 둔간하여 신(辛)의 칠살과 백호가 함께 있으니 재(財)를 구(求)하여 도리어 화(禍)를 초래하며, 전체적으로 길을 보아도 길을 형성하지 못한다. 야간(夜間)에 정단하면 분실의 건이 있다.
  백법에 말하기를 ｢삼전체생(三傳遞生)은 타인의 천거가 있고, 시작이 있고 끝이 없으니 생중(生中)에 묘(墓)를 만난다. 묘호(墓虎)가 동병하는 것은 집에 복시(伏屍)가 있기 때문이다｣고 하였다. 비전에 말하기를 ｢삼전 해유미(亥酉未)는 시둔(時遯)이라고 하며 즉시 이롭지 않으니 때를 기다리는 것이 유리하다｣고 하였다. 하급인(下級人)은 병에 걸려 위독(危篤)하다.
  초전․중전이 공망이 되면 처음에는 허영(虛榮)이다. 초전 해(亥)는 일지의 역마가 되고 병을 정단하면 불량하다. 이 과는 삼전의 초전은 술해(戌亥)가 공망으로 되며, 말전은 다음의 오미(午未)가 공망의 상으로 된다. 이것을 칭하여 순둔공망(旬遁空亡)이라고 하며 만사가 공허(空虛)하여 힘이 없는 상이다. 폐색되어 통하지 않고 암매(暗昧)하게 되어 명확하지 않으며, 신(身)은 고독의 정세이다. 또한 고향을 떠나 재물은 자연히 소모되고 흩어진다.
  원조가 없는 고립된 상으로서 보통 사람에게는 좋지 않으나 승도(僧道) 또는 종교인에게는 좋다. 백법 중의 백법이며, 흉이 결절(結絶)되는 것은 본 법과 같은 것을 말한다. 사람의 원조를 얻지 않아도 근무하며 타력(他力)에 의존하여 타개책(打開策)을 가구해야 한다.
  일귀는 역행하여 삼전이 현(玄)․후(后)․사(蛇)로 된다. 피점자(被占者)의 연명상신(年命上神)에 술(戌)이 승하면 관재(官災)를 만난다.`
  ,
  // 4
  `  이 과는 일목(一木)이 전토(全土)를 극하여 가색격(稼穡格)으로 되며, 사과삼전이 모두 전재(全財)가 된다. 삼전의 축술미(丑戌未)는 사과중에서 나온다. 이와 같은 것은 순환격(循環格)이라고 부르며, 어떤일도 구태(舊態)를 반복할 뿐 새로운 맛이 없다. 그리하여 과전순재(課傳純財)는 재기(財氣)가 태과(太過)하다. 백법에 말하기를 ｢태왕(太旺)은 반대로 재(財)가 결하게 된다｣고 하였다.
  일지는 일간상신으로 되고, 축(丑)은 말전 신(辛)의 암귀(暗鬼)를 만나 어떤 일도 위우(危憂)로 끝난다. 또 말전은 일간의 묘(墓)로 되어 백호가 승한다. 부모와 어른의 건은 불측(不測)의 재(災)를 초래한다. 즉 재(財)가 많아서 인수(印綬)를 파극(破剋)하기 때문이다. 그러나 여러개의 술(戌)은 공망을 만나기 때문에 피점단자는 조용히 앉아서 이(利)를 지키는 것이 좋다. 즉 과욕으로는 대재대리(大財大利)를 얻지 못한다. 탐하면 반드시 재(財)에 의해 재(災)를 초래하게 된다.
  오행이 전부 묘신(墓神)이 상병(相竝)하고 있다. 묘(墓)는 잠복한 상으로서 사물이 통하기 어렵고 암매(暗昧)하여 명확하지 못하다. 일지가 일간상신에 가하여 신(辛)의 둔간귀(遁干鬼)로 되면 상대방의 음모에 걸리기 쉽다. 년명상신(年命上神)에 충신(冲神)이 승하는 것은 흉해(凶害)를 면하게 된다.
  일귀는 삼전이 청(靑)․주(朱)․후(后)의 길장을 승하고, 야귀는 삼전이 사(蛇)․음(陰)․백(白)으로 된다. 
  백법에 말하기를 ｢인택(人宅) 즉, 일간지상신(日干支上神)이 묘신(墓神)에 복(伏)하면 암매(暗昧)를 초래한다｣고  하였다. 전재(全財)는 병란은 가장 싫어하고, 삼전 형상(刑上)은 주객(主客)이 서로 용납하지 않는다. 비전에서 말하기를 ｢일지상신 술(戌)에 주작이 승하면 가정에 불화쟁투구설(不和爭鬪口舌)이 많다｣고 하였다.
  사과 중에 3과만 있고, 음의 불비(不備)를 버리고 양을 남기기 때문에 이양일음(二陽一陰)으로 된다. 또 사물이 불비부족결함(不備不足缺陷)이 있어 색정에 의한 여인의 싸움의 암시이다.`
  ,
  // 5
  `  이 과는 제4과만 상에서 하를 극하여 원수과(元首課)로 되고, 삼전 사축유(巳丑酉)는 종혁격(從革格)으로 된다. 삼전의 초전은 중전을 생하고, 중전은 말전을 생하며, 말전은 일간상신 자(子)의 수(水)를 생하고 자(子)의 수(水)는 일간(日干)을 생한다. 삼전의 중귀(衆鬼)는 신(身)을 극하나 귀(鬼)는 일간상신을 생하여 처음에는 흉이더라도 후에는 살인(殺印)이 생화(生化)되여 재화(災禍)를 해소한다. 즉 일간상신을 생하여 일간을 생한다.
  자(子) 하나가 전체의 적(敵)을 화(化)하여 신(身)을 생부(生扶)하는 신(神)이 된다. 또 간상(干上)의 자(子)는 순수(旬首)의 신이고, 일지상(一支上)은 순미(旬尾)의 지지(地支)로 되어 일순주편격(一旬周偏格)으로 된다. 시작에 있고 끝에 있다.
  종혁격(從革格)은 처음에는 따르고 후에 바꾸어 저체(沮滯)가 많이 있다. 그러나 기세가 있으면 길(吉)이 증가되고, 기세가 없으면 사물을 바꾸어 실착(失錯)을 만난다. 제4과를 초전으로 하는 것은 맥월격(驀月格)이라고 하며 일이 맥연(驀然)히 발생하거나 뜻밖에 길흉을 일으킨다.
  자(子)와 축(丑)이 지합(支合)하고 기궁(寄宮) 진(辰)과 지상(支上) 유(酉)가 지합한다. 즉 교차지합(交車支合)하여 형상하는 것은 화합타협(和合妥協)한다. 백법에 말하기를 ｢중귀(衆鬼)라는 것도 일간상신에 생화(生化)하여 두려울 것이 없다. 귀신(鬼神)도 진(辰)의 지(地)에 임하는 것은 옥(獄)에 앉아서 흉으로 되지만 일간상신에 임하는 것은 오히려 길로 되어 무서운 것이 없다｣고 하였다.
  일순주편격(一旬周偏格)은 시종 서로 화합한다. 염막귀신(簾膜鬼神)은 시험은 반드시 적중한다. 삼합 중에 살(殺)을 보면 웃음 속에 칼을 품으며 또 감언(甘言)을 농(弄)한다. 비전에 말하기를 ｢삼전이 둔생(遁生)하여 천장(天將)과 함께 생하는 것이나 간지교차생합(干支交車生合)하는 것은 입학 시험 및 국가 시험에 어느 것이나 적중한다｣고 하였다. 참고로, 둔간(遁干)하여 병(丙)과 주작이 함께 있으면 문장(文章)이 화려하고 세상의 찬사를 받아 입시도 반드시 합격된다.
  일귀는 삼전 현(玄)․청(靑)․사(蛇)로 되고, 내의(來意)는 비밀이거나 도난의 건으로 된다. 야귀는 삼전․용(龍)․사(蛇)․ 현(玄)으로 되면 도적의 건이 아니다.`
  ,
  // 6
  `  이 과는 제2과와 제4과가 하에서 상을 극하는 것이 있고, 오늘 이 을(乙)의 음일(陰日)이면 음비(陰比)를 취하여 용신(用神)으로 한다. 즉 제4과 상신 묘(卯)의 음을 취하여 초전으로 하며, 비용격(比用格)이 된다.
  일간상신 해수(亥水)는 나를 생하는 인수(印綬)이지만 공망하고, 일지상신 관귀효(官鬼爻)는 실(實)로 되어 자신의 일간을 극한다. 그러나 금살(金殺)은 수(水)에 생화하고 백법에서 말하는 피난도생(避難逃生)이 되어 구사(舊事)를 버리면 해를 받지 않는다. 삼전 묘술사(卯戌巳)는 단륜격(斷輪格)으로, 을목(乙木)을 차륜(車輪)한다. 사(巳) 중에 경금(庚金)이 있어 을경(乙庚)이 간합(干合)하며 목(木)은 금금(金金)의 조각(彫刻)을 받아서 귀기(貴氣)의 뜻을 이루기 때문에 취직은 가장 길하다. 특히 무직(無職)이라면 더욱 좋다.
  이 과를 얻으면 권세와 위력을 발휘하는데 일간(日干)에 의해 묘(卯)는 건록으로 되며 공명(功名)에 좋다. 그러나 사과삼전 중에 귀살(鬼殺)이 겹치기 때문에 간난고심(艱難苦心)한 후에야 효과가 있다. 관귀(官鬼)가 지상(支上)에 승하는 것은 병단(病斷)은 급히 신불(神佛)에 기원(祈願)하는 것이 좋고, 늦으면 중․말전이 공망하여 죽음의 위험을 만난다.
  일귀는 삼전에 순행하여 백(白)․주(朱)․현(玄)으로 되고, 야귀는 삼전에 역행하여 현(玄)․주(朱)․백(白)으로 된다. 초전에 현무가 승하면 폐구격(閉口格)이다. 백법에 말하기를 ｢폐구격은 기회를 측정하지 않는다｣고 했다. 초전 묘(卯)는 정신(丁神)으로 이동(移動)을 다스리고, 병단을 두려워하며 관(官)을 구하므로 관효(官爻)에 귀신(貴神)이 승하여 경사가 있다. 그러나 힘이 약하므로 직위는 낮다. 밀운(密雲)이 비가 되지 않으면 만사의 정단(正斷)은 기회를 알고 바르게 때(時)를 기다리는 것이 좋다.`
  ,
  // 7
  `  이 과는 천지반이 충신(冲神)으로 되고, 을목일(乙木日)은 술(戌)의 토(土)를 극하며 초전으로 된다. 반음과(返吟課)이며 술(戌)을 초전으로 하기 때문에 참관격(斬關格)이 된다.
  삼전 진술축미(辰戌丑未)의 토신(土神)은 가색격(稼穡格)을 구성하여 일간에서 보아 전부 재(財)가 되므로 전재(全財)라고 부른다. 일지 미(未)는 신금(辛金)이 둔간(遁干)하여 귀살(鬼殺)로 되고, 귀(鬼)가 진묘(眞墓)로 되면 가택(家宅)에 복시(伏屍)가 있다.
  사과삼전 천지반 충신(冲神)은 왕래반복(往來反覆)한다. 그러나 삼전이 모두 술(戌)이 공허로 되어 처를 정단하면 고분(鼓盆 : 장사지냄)의 우려가 있다. 재(財)를 정단하면 재로 인하여 쟁단(爭端)을 발생한다. 처가 사망하면 처의 유산이 있다. 주작은 문장(文章)의 유신으로 되어 술(戌)에 승한다. 즉, 주작이 공망한 것은 낡은 문장을 개작하여 서제(書題)가 명확하지 않다. 춘월과 추월에 정단하면 모든 일은 길조라고 관점한다.
  원래 반음과(返吟課)는 충(冲)하여 고신(孤神)이 되므로 어떤 일도 신뢰할 수 없으며, 물사는 반복하여 모든 것이 의혹이 생긴다. 삼전의 모든 재(財)는 금전의 일로서 쟁단을 일으킨다. 삼전 술(戌)의 고신(孤神)을 보면 고향을 떠날 징조가 있고 참관(斬關) 발용(發用)은 도망(逃亡)․가출(家出)․피난(避難)의 상이다.
  비전에서 말하기를 ｢반음과의 사상(事相)은 휴지(休止)하고 언사(言辭)만은 정해야 한다｣고 하였다. 만사는 왕복, 왕래하고 정단은 모두 신(身)은 동요하여도 인정은 원심(遠心)이 있다. 과전이 공망하여 길흉이 모두 실(實)이 없거나 또는 힘을 감한다.
  일귀는 삼전에 순행하여 주(朱)․상(常)․주(朱)로 되며, 야귀는 역행하여 삼전은 또 주(朱)․상(常)․주(朱)로 된다. 재(財)를 정단하면 재를 얻어 구설이 있다. 관리는 예직(穢職)으로 변을 만난다. 정단사는 길이 경하고 흉이 중한 것이 보통이다.`
  ,
  // 8
  `  이 과는 제1과, 제2과, 제4과에 상하의 극이 세 곳이 있다. 제2과의 하에서 상을 극하므로 인유(寅酉)가 초전으로 된다. 극이 많기 때문에 사건은 다기(多岐)에 걸쳐 파동이 있다. 일간상신 유(酉)의 관귀(官鬼)는 내 몸에 임하여 흉동(凶動)을 본다. 그러나 일지상신 오(午)의 화(火)는 유금(酉金)을 제어하여 흉을 구(求)한다. 설기(泄氣)는 흉이라도 관귀(官鬼)가 있으면 구신(救神)이 되는데 바로 이것을 말한다.
  중전 미(未)의 재(財)는 극지(剋地)에 있고 청룡을 승하여 협극(夾剋)으로 된다. 비용(比用)은 낭비(浪費)가 많고 또는 경쟁자가 있다. 일간에서 초전을 극하고, 초전은 중전을 극하며, 중전은 말전을 극하여 화합 협력하는 것은 완전히 화기(和氣)가 없다.
  구재(求財)의 경우는 재상(財上)에 재(財)를 보아 대재(大財)를 얻는다. 간상(干上) 유(酉)는 관귀(官鬼)로서 재(災) 또는 형으로 일지상신에서 이것을 제어한다. 따라서 병점(病占)은 친근한 양의(良醫)를 얻는다. 재(災)는 타인의 구조를 만나고, 소송은 증인의 유리한 변호를 얻는다. 유(酉)는 명귀(明鬼)로 되고, 오(午)는 경(庚)의 둔간으로 암귀(暗鬼)가 되며, 오(午)는 이것을 제어하여 사승살(四勝殺)로 된다. 즉 자기의 능력에 의해 모든 일의 아름다운 결과를 얻게 된다. 삼전이 전부 협극(夾剋)으로 되어 자유를 얻지 못하는 상일지라도 인내하면 길조로 된다. 
  백법에 말하기를 67법 ｢제귀(制鬼)의 위(位)는 양의(良醫)를 얻는다.｣ 75법 사승살(四勝殺)에 해당하고 ｢스스로 용감하게 되어 공을 세운다｣고 하였다.
  귀인의 순행은 묘(卯)의 지(地)에 귀인이 임한다. 즉 묘유(卯酉)에 귀인이 서는 것은 여덕격(勵德格)으로 되어 형법(刑法)을 범하면 법에 걸린다. 또 병자는 죽는다. 여행이나 조작(造作)은 일체 쓰는 것을 금한다.
  일귀는 순행하여 삼전이 공(空)․후(后)․구(勾)로 되고, 형벌의 건은 불리하여 재(災)를 면하지 못한다. 야귀는 역행하여 음(陰)․청(靑)․귀(貴)로 되어 모든 흉재(凶災)가 해구(解救)된다.`
  ,
  // 9
  `  이 과는 제1과와 제4과의 상하에 극이 있다. 하에서 상의 극하는 것을 채용하여 제4과를 초전으로 하고 중심과(重審課)로 된다. 삼전 사유축(巳酉丑) 금국(金局)은 종혁격(從革格)이며, 낡은 것을 바꾸어 새로운 것에 들어가기 때문에 혁고정신(革故鼎新)이라고 부른다. 내의(內意)는 처의 임신이나 부인과의 이별의 일로서 이격신(離格神)이라고 하며, 만사가 쉬지 않고 한 사건이 지나가면 또 한 사건이 와서 왕래가 빈번하여 쉴 새가 없다. 타인의 추천을 얻는 것은 길조로 된다.
  간지삼전이 어느 것이나 삼합(三合)․육합(六合)․상합(相合)하여 과전을 이루면 화미격(和美格)으로 된다. 모든 모사(謀事)는 성공하고 방해가 없으며, 타인의 원조와 추천을 받는다. 삼전 금국(金局)은 일간의 중귀(衆鬼)로 되고, 일간상신은 관살효(官殺爻)가 된다.
  일반인에게는 질병이나 재해․소송․사건이 발생하고, 일귀는 간상(干上) 신(申)에 귀신(鬼神)이 승한다. 귀신이 진술(辰戌)의 지반에 임하면 귀인이 옥(獄)에 들어가지만 일간상신에 승하는 것은 귀(鬼)라 할지라도 두려울 것이 없다. 관효(官爻)가 겹쳐 최관(催官)이 가택(家宅)에 임하여 사관(仕官)․구관(求官)․현임관(現任官)․회사중역(會社重役) 등은 모두 승진의 기쁨이 있다. 초전의 유사(酉巳)가 겹쳐서 태(胎)는 사(巳)의 장생에 앉고 임신은 양호하게 되지만 출산은 좋지 않다.
  원래 종혁격은 사물이 번잡하여 하나가 아닌 일의 관점(觀點)은 지체되어 진행하기 어려운 것을 보통으로 한다. 백법에 말하기를 ｢귀신(鬼神)이 옥(獄)에 앉아도 일간상에 가하는 것은 나를 지킨다｣고 하였다. 귀(鬼)가 천을(天乙)에 승하는 것은 신기격(神祇格)이고, 야귀는 후(后)․합(合)의 초전․중전에 승하여 자유 결혼으로 된다. 즉 교동격(狡童格)이 된다.
  초전의 귀(鬼)는 말전에서 제어되고 간상(干上)의 귀효(鬼爻)는 일간상신의 가인(家人)에 의해 제어되므로 중귀(衆鬼)라도 전혀 두려울 것이없다. 제귀(制鬼)의 위(位)는 양의(良醫)를 얻는다. 삼전 중 묘신(墓神)을 묘 상(墓上)에서 보는 것은 애증(愛憎)을 구분한다.
  일귀는 순행하여 삼전이 사(蛇)․청(靑)․현(玄)이며, 야귀는 역행하여 삼전이 합(合)ㆍ후(后)․백(白)으로 된다. 이 과는 모두가 길하지만 병환은 불길하다.`
  ,
  // 10
  `  이 과는 제1과 일간에서 상신 미(未)를 극하여 타(他)에 극이 없다. 즉 4과 중에 일위상(一位上)을 적(賊)하므로 시입과(始入課)라고도 부른다. 일간과 일지상신 어느 것이나 묘신(墓神)이 복(覆)하므로 사람이나 택(宅)이나 모두 혼미섭체(昏迷涉滯)한다. 일지상신 진(辰)은 둔간(遁干)하여 무(戊)이고, 축(丑)에는 기간(寄干) 계(癸)가 있어 간합(干合)하여 처재(妻財)가 모인다. 또 일간상신이 재효(財爻)로 되어 미(未)의 둔간(遁干) 신(辛)은 일간을 극하여 암귀(暗鬼)로 된다. 이런 것은 재둔귀격(財遁鬼格)이라고 한다.
  재(財)로 인하여 화(禍)를 초래하고, 식(食)으로 인하여 내몸이 상(傷)하며 아내로 인하여 소송이 발생한다. 춘(春)은 축(丑), 하(夏)는 진(辰), 추(秋)는 미(未), 동(冬)은 술(戌)을, 각각 관신(關神)이라고 한다. 만약 가을에 정단하면 관신(關神)이 초전으로 되어 가인(家人)에게 재화가 발생한다. 삼전이 전부 재성(財星)으로 되어 재(財)와 태과(太課)하면 뜻을 얻어서 중간에 장해가 발생한다. 진술축미(辰戌丑未)의 충(冲)을 즐기며, 충(冲)하면 흉사는 흩어지고 우려도 해소된다. 그리하여 중․말전이 공망하기 때문에 초전의 일신(一神)에 의존하여 후에 도달하고 모두 산만(散漫)하게 된다.
  일귀는 삼전이 사(蛇)․음(陰)․백(白)이며, 야귀는 삼전이 청(靑)․주(朱)․후(后)이고 어느 것이나 역행한다. 청룡이 둔귀(遁鬼)에 승하면 관리(官吏)나 회사원은 때를 기다리면 구직을 하게 된다.
  4과(四課)가 불비격(不備格)으로 되면 미중(美中)에 불비부족결함(不備不足缺陷) 중 어느 것인가가 있다. 백법에 말하기를 ｢간상지상(干上支上) 어느 것에라도 묘신이 승하면 모든 일이 혼미하게 된다｣고 하였다. 전재(全財)인 경우에는 병의 정단은 흉이고, 존친의 병을 정단하면 사망한다. 간묘(干墓)로 되어 관신(關神)을 병합하면 인택(人宅)이 함께 쇠퇴한다.
  비전에 말하기를 ｢간지가 묘신에 승하면 만사형통(亨通)하기 어렵다｣고 하였다. 일간기궁(日干寄宮)이 일지상신에 돌아올 때는 조용하면 유리하고 움직이면 불리하다. 중․말전이 공망한 것은 중도에서 돌아온다. 일간상신이 청룡을 승하여 초전으로 되면 그 해(年)에 구관구직(求官求職)이 성취된다.`
  ,
  // 11
  `  이 과는 제2과와 제3과에 상하의 극이 있고 제2과 하(下)에서 상을 적(賊)한다. 신오(申午)를 초전으로 하여 중심과(重審課)로 한다. 을(乙)의 일간에서 오(午)를 보면 탈기(脫氣)로 되며 물(物)의 중용(中庸)이 결핍되어 실의(實意)가 적다. 또 탈상탈(脫上脫)에 만나 허사(虛詐)에 걸리기 쉽다. 그러나 초전 관(官) 신(申)의 귀효(鬼爻)는 나를 해치면서도 귀(鬼)를 제(制)하는 구신(救神)으로 된다.
  일지상신 묘(卯)는 일간의 건록으로 되고, 정신(丁神)이 있고 현무가 승하면 록(祿)에 의해 동하여 물심(物心) 중 어느 한쪽의 소모가 있다. 야귀는 술(戌)의 지(地)에 임하여 귀신(鬼神)의 옥(獄)에 앉아 기쁨을 만나지 않으며 노(怒)에 접한다. 중․말전이 공망하여 중도와 끝 모두 길흉이 이루어지지 않는다.
  일지상신이 일간의 건록으로 되는 것은 백법에서 말하기를 ｢권섭(權攝)이 바르지 않고 스스로 존귀하지 않으며 사람에게 굴욕을 받기 쉽다｣고 하였다. 택상(宅上)의 녹(祿)은 자신의 녹(祿)이 가족과 자손의 것으로 바뀌는 것이 되고, 천지반의 미(未)는 사(巳)에 가하여 양사(兩蛇)가 협묘(夾墓)로 된다. 질병의 정단은 적괴(積塊)의 질환이며 소송은 형벌에 걸리게 된다. 정단이 많은 것은 흉화(凶禍)의 일로 모든 사물은 격리되어 후퇴할 수 없으며, 이룩하기 어려운 두려움이 있다. 백법에 말하기를 ｢일지상신이 일간의 건록으로 되면 권섭(權攝)이 바르지 않고, 삼전은 진여격(進茹格), 진술자(辰戌子)는 섭삼연격(涉三淵格)으로 되며, 청룡이 삼연을 건널 때는 우(雨)로 되지 않고 적(賊)이 삼연을 건너면 병재(病災)나 관재(官災)를 함께 만나지 않는다｣고 하였다. 관(官)을 정단하면 흉이고 모사(謀事)의 대부분은 목적에 도달하지 못한다.
  일귀는 역행하여 삼전이 귀(貴)․음(陰)․상(常)이며, 야귀는 순행하여 구(勾)․주(朱)․귀(貴)로 된다. 큰 사건은 가능하지만 귀신(鬼神)이 공망하여 초전의 구진은 귀효(鬼爻)가 되며, 재화(災禍)․병환․소송은 어느 것이나 불길하다. 권섭부정격(權攝不正格)은 관인(官人)의 정단은 흉으로 부정(不正)의 누(累)를 초래한다. 비전에서 말하기를 ｢삽삼연격은 동하려 하여도 동할 수 없으며 목전의 진퇴(進退)는 곤란한 징조가 있다｣고 하였다.`
  ,
  // 12
  `  이 과는 제3과만 일상(一上)이 하를 극하여 초전으로 되는 원수과(元首課)이다. 일간상신 사(巳)와 일지 축(丑) 사이에 끼여서 삼전 인묘진(寅卯辰)은 내(內)에 끼우는 진여격(進茹格)이며 협정격(夾定格)이라고도 한다. 인묘진(寅卯辰)은 방합(方合)하여 동방목기(東方木氣)로 된다.
  군자(君子)가 광명하게 정사(政事)를 다스리는 상으로 되어 대인군자(大人君子)에게는 가장 길조이다. 봄에 정단하면 천시(天時)에 왕(旺)하고, 가을에 정단하면 흉으로 되며 왕(旺)할 때는 재(災)가 발생하지 않는다. 사수(死囚)의 시(時)는 재화에 임하고, 또 봄에 정단하면 삼양(三陽)으로 되며 양기(陽氣)가 진(進)하여 만사가 길경(吉慶)하다. 진여격(進茹格)으로 되면 전진하는 것이 좋으며, 시기에 순응하여 움직이면 유익하지 않은 것이 없다. 바야흐로 교태(交泰)의 시(時)는 공명(功名)이 나타난다. 다만 비견(比肩)이 왕성하여 형제의 건은 좋지 않다. 모든 일이 지나칠 때는 화근(禍根)이 되어 타일(他日)에 흉조가 발생한다.
  사과 지반지의 전 1위지가 모두 4과의 천반으로 되면 나망격(羅網格)이다. 백법에 말하기를 ｢계획하는 일이 많으면 졸렬하게 된다｣고 하였다. 비전에서 말하기를 ｢사과삼전이 모두 인묘진사오(寅卯辰巳午)와 四課를 떠나지 않는 것은 어떤 일도 가정을 떠나지 않는다. 내(內)로부터 나와서 외(外)에서 구하는 과이다｣라고 하였다.
  일귀(日貴)는 역행하여 삼전은 공(空)․청(靑)․구(勾)이며, 야귀는 순행하여 삼전이 주(朱)․합(合)․구(勾)로 된다. 어느 것이나 길신을 승하여 흉조가 없고 명(名)을 구하여 달성한다. 이재(利財)를 구하면 비견이 겹치므로 재(財)는 충분하지 않다. 또 나망격(羅網格)은 관리에 대한 정단은 어떤 일이나 곤난고심의 일이 있다.`
  ,
]



  


  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
