
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,4,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,3,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,2,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,1,`특별있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,12,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,11,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,2,10,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,3,9,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,8,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,5,7,`특별있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,6,6,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,5,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,`情위난측`],
    [`문서`,`문서 관련사`,`小`,`10`,`사사성취`,`색정음사`],
    [`색정`,`내부 여자 색정사 有`,`大`,`침해기`,`단합결속`,`가출우려`],
    [`모성흉산(謀成凶散)`,`도모사는 이루어지고 나쁜 일은 해소되는 상`,`大`,``,``,`손해사有`],
    [`동지함의(動止咸宜)`,`가나 있으나 모두 좋은 상`,`小`,``,``,``],
    [`금전`,`금전 상업 여인 관련사`,`小`,``,``,``],
    [`동요`,`외사 파재 극처 실종사우려-내 동요불안분산사`,`大`,`11`,`문서상신`,`불측근심`],
    [`공허`,`공허 쇠퇴사`,`小`,`쇠퇴기`,`답신지체`,`불화파란`],
    [`허심불화(虛甚不和)`,`허망함이 심하고 불화사가 있는 상`,`小`,``,`파재극처`,``],
    [`도적실세(盜賊失勢)`,`도적이 실세하여 잡혀 죄수가 되는 상`,`大`,``,`구설사有`,``],
    [`형제`,`형제 관련사`,`大`,``,``,``],
    [`친구`,`친구 관련사`,`小`,`12`,`화소복지`,`免屈난해`],
    [`출행`,`외사 출행이동원행사`,`小`,`과시기`,`출행이동`,`침체번민`],
    [`탐재화환(貪財禍患)`,`금전을 지나치게 탐하여 화환을 당하는 상`,`大`,``,``,``],
    [`주유가음(主有佳音)`,`안택-소혈(巣穴)관련 반가운 소식을 기다리는 상-`,`小`,``,``,``],
    [`직녹`,`직위녹위사`,`大`,``,``,``],
    [`자산`,`자산권리사`,`小`,`1`,`능욕박해`,`折傷人口`],
    [`결속`,`결속하여 동지사는 크게 단합되나 재물처첩을 상실함`,`小`,`절정기`,`손해도망`,`(가족상해)`],
    [`좌수동불(座守動不)`,`일을 앉아서 도모하면 상위실력자를 만나고 動하면 불리함`,`大`,``,``,``],
    [`이초득죄(以招得罪)`,`비밀을 누설(檢約)하여 죄에 이르는 상`,`小`,``,``,``],
    [`금전`,`금전 사업 여인사`,`小`,``,``,``],
    [`매사허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출로 성공함`,`大`,`2`,`필유성취`,`公私구설`],
    [`매사 영속성없음`,`금전사업처첩사는 선천적 숙명재해를 겪음-독신고독`,`小`,`면모기`,`동요분산`,`비용과출`],
    [`족가해우(足可解憂)`,`근심사가 충분히 해소되는 상`,`小`,``,`허성허사`,`허성허사`],
    [`관재질병(官災疾病)`,`관재를 당하거나 질병을 앓을 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사-출행이동사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출로 성공함`,`大`,`3`,`문서유실`,`謀事미수`],
    [`세속허사자`,`직업지위남편남자자녀식복은 선천적 기복재해를 겪음`,`小`,`학습기`,`직위불안`,`후원불성`],
    [`녹귀질병(祿鬼疾病)`,`직녹자산의 침해를 받고 질병이 두려운 상`,`小`,``,``,``],
    [`생살대권(生殺大權)`,`관직자 대길- 생살대권을 쥐고 일반인은 형벌이 대흉 함`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,`음인災疾`,`능욕박해`],
    [`극부`,`남편(남자) 갈등사`,`大`,`4`,`직위권리`,`남편남자`],
    [`자녀`,`자녀 문제사`,`小`,`개시기`,`불안사`,`갈등사`],
    [`권리불리(권리불리)`,`자산권리사가 매우 나쁘고 관직자의 권리사도 불리한 상`,`小`,``,`신규개시`,``],
    [`균가해산(均可解散)`,`모든 재해가 해소되는 상`,`小`,``,``,``],
    [`금전`,`금전사`,`小`,``,`고위行길`,``],
    [`사업`,`사업 관련사`,`大`,`5`,`청원좋음`,`가정마힘`],
    [`同志`,`결속단합하여 동지사를 완선하나 금전이권처첩 훼절함`,`小`,`학습기`,`면모배양`,`필유성취`],
    [`음령보호(陰靈保護)`,`신의 가호를 얻는 상`,`小`,``,``,``],
    [`생살대권(生殺大權)`,`관직자 대길- 생살대권을 쥐고 일반인은 형벌이 대흉 함`,`大`,``,``,``],
    [`급속`,`내부 가정 강제급속손실사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,`遠當莫入`,`음인암손`],
    [`남편`,`남편 관련사`,`大`,`존재기`,`逢林有蛇`,`급속손실`],
    [`송사난명(訟事難明)`,`소송사가 어찌 될지 불분명한 상`,`小`,``,`소송번민`,``],
    [`총시성재(總是成災)`,`모두 재화 뿐인 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,`간음이익`],
    [`직업`,`직업 관련사`,`小`,`7`,`情위난측`,`정대재앙`],
    [`색정`,`외 男 색정사 有`,`大`,`단절기`,`진실궁금`,`위례수난`],
    [`상인무력(上人無力)`,`도움을 받아야 할 윗인들이 무력한 상`,`小`,``,`색정사有`,``],
    [`위례득죄(違禮得罪)`,`예의가 없어 죄에 이르는 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업침체 관련사`,`大`,`8`,`불측근심`,`승진영전`],
    [`처`,`처첩 관련사`,`小`,`침체기`,`동요분산`,`반목대립`],
    [`재물도난`,`재물을 도둑맞는 상`,`小`,``,``,`소송사有`],
    [`위례득죄(違禮得罪)`,`예의가 없어 죄에 이르는 상`,`大`,``,``,``],
    [`후원`,`후원 조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`9`,`免屈난해`,`손괴재물`],
    [`부모`,`부모 관련사`,`大`,`소외기`,`진퇴번민`,`타인압박`],
    [`급류용퇴(急流勇退)`,`주력사를 재빨리 그만둬야 할 상`,`大`,``,``,``],
    [`왕진쇠퇴(旺進衰退)`,`시절을 잃었다면 퇴하고 제 시절이라면 나아가야 할 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`후원조력문사사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`전화위복적으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`형제친구동료조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`직녹자산사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,`직업직위불안 남편남자갈등 자녀문제사로 불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`금전사업처첩조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`관사직업넘편사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`관사직업외색정사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업처첩조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,`후원문서부모조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)-은닉**만약 타인과 다툰다면 숨기고 있는 일이 밝혀질 우려가 있으므로 서둘러 피해야 함`,0,0,`의지할 사람과 거처가 암매하여 분명하지 않고 타인의 눈을 가리는 상으로 형벌건을 안고 있는 人`,0,0,`눈을 가리는 상**반복부정함**은닉 도망의 격인 人 `,0,0], // [3][0], [3][3], [3][6]
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)-은닉**만약 타인과 다툰다면 숨기고 있는 일이 밝혀질 우려가 있으므로 서둘러 피해야 함`,0,0,`의지할 사람과 거처가 암매하여 분명하지 않고 타인의 눈을 가리는 상으로 형벌건을 안고 있는 人`,0,0,`눈을 가리는 상**반복부정함**은닉 도망의격**신규사가 있는 人 `,0,0], 
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하고 유순한 처신으로 사안을 지체 연기시키면 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 유화적 언행을 지키며 결론을 후로 미루면 반드시 결과가 유리하게 되는 人`,0,0,`토지가옥의 송쟁이 있음**동서 분주함**결과없이 반복되나 계속하여 밝아짐**독신고독각방주말부부생리사별적 처지 人`,0,0], // 
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 명분이 있으므로 먼저 나서서 속결해야 함`,0,0,`먼저 행동하여 속결하지 않고 미루거나 좌고우면적 처신을 하면 결과가 불리한 人`,0,0,`복잡다단한 여러 사안을 하나로 일관시키는 결속 단합사가 있는 人`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하고 유순한 처신으로 사안을 지체 연기시키면 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 유화적 언행을 지키며 결론을 후로 미루면 반드시 결과가 유리하게 되는 人`,0,0,`옛 일이 단절됨**전화위복사가 있는 人`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상으로 화가 밖에서 오니 신중헤야 함`,0,0,`반복무상 하고 의지할 데가 없으며 옛 일이 재발된 人`,0,0,`깊은 산속으로 숨고싶은 심정임**토지가옥사에 관련됨**독신고독각방주말부부생리사별적 처지 人`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`권리권한사가 있으나 성립이 안됨**육친빙탄의 상(자녀가 부모를 압박함)**일이 결과없이 반복되나 계속하면 밝아짐**독신고독각방주말부부생리사별적 처지 人`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하고 유순한 처신으로 사안을 지체 연기시키면 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 유화적 언행을 지키며 결론을 후로 미루면 반드시 결과가 유리하게 되는 人`,0,0,`복잡다단한 여러 사안을 하나로 일관시키는 결속 단합사가 있음**남자가 타인의 부인을 유혹하여 자기 여자처럼 사용하는 人 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하고 유순한 처신으로 사안을 지체 연기시키면 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 유화적 언행을 지키며 결론을 후로 미루면 반드시 결과가 유리하게 되는 人`,0,0,`토지가옥의 송쟁사  있음**1남2녀 삼각관계 있음**타세에 의지하는 상**타인이 스스로 와서 금전의 이익을 조력함**독신고독각방주말부부생리사별적 처지 人`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하고 유순한 처신으로 사안을 지체 연기시키면 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 유화적 언행을 지키며 결론을 후로 미루면 반드시 결과가 유리하게 되는 人`,0,0,`일이 띄엄띄엄 이어짐**서리내린 다리를 건너는 격인 人`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 세**만약 타인과 다툰다면 상대는 싸울 의사가 별로 없고 있다해도 피해가 별로 없음`,0,0,`천둥에 놀란 듯한 심정에 있으나 상대는 호가호위의 세를 부리는 것이므로 언행을 삼가하고 조용이 있으면 놀래는 사안이 자연히 사라지는 人`,0,0,`일이 순서적으로 진행되는 人`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`적극적인 활동을 포기하고 속으로 끙끙 않고 있는 人`,0,0,`깇은 산속으로 도망가고 싶은 심정**토지가옥사 번민**동분서주의 입장**독신고독각방주말부부생리사별적 처치 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`미사`,`구사`,`돌발사`,`모호사`,`충성-무력`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`이외사`,`미사`,`신사`,`돌발사`,`모호사`,`좌고우면-무력`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사 타발동급속사 겹침`,`기사`,`구사`,`아외사 타내사 겹침`,`아표리타이면사겹침`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈 인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`위산`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면서`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사후회사겹침`,`기사`,`구사`,`외사`,`표면사이면사겹침`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [`비김`,`비김`,`비김`],
    [` 무력`,`무력`,`무력`],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음9월`,2,1,3,`음1월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음8월`,1,1,2,`음2월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월`,1,1,2,`음1월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음2월`,3,3,6,`음8월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월`,2,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,3,3,6,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월`,1,1,2,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월`,3,3,6,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음2월`,2,2,4,`음음6월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음11월`,3,2,5,`음5월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음10월`,2,3,5,`음2월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내나 업무는 전반적으로 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 초반 이후에 허무하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 시종 반목 대립하며 소송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 여러 일을 하나로 일관시키는 결속단합을 꾀하여 세력을 이루는 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내나 업무는 전반적으로 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무도 시종 공허하여 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있고 초 중반에는 허무하게 보내고 종반에 내부 협력으로 실적을 내나 본인에 의해 부서지고 마는 시패를 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻어 여러 일을 하나로 묶는 결속단합을 꾀해 성공적인 업적을 이룸 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 시종 반목 대립하며 소송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하고 업무도 수준 이상의 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미와 사심이 겹치고 배경이 없어 초반에는 실패하고 중 종반에 수준을 유지함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초반에는 실패하나 중 종반에는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후費`,`進向`,`원`,`留`,`주인조력`,`븍븍동`,``,`정북`],
    [`10.11`,``,``,``,`빠르게`,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`북동`,``,`정서`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`정동`,``,`북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`권리양도`,`進向`,`  원`,`  留`,`주인가해`,`정북`,`동북`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,`이후단합`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선이후합`,`進向`,`  원`,`  留`,`주인가해`,`북북동`,`동북`,``],
    [`10.11`,``,`본인제압`,``,`애증남음`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`동요반목`,`退背`,`  근`,`  去`,`상호동요`,`동북`,`정동`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`세입가해`,`정동`,`정남`,``],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,`상호손해`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`본인반대`,`이행`,`선이후費`,`進向`,`  원`,`  留`,`세입가해`,`북북서`,`남남서`,``],
    [`10.11`,` 多集`,`측근내부`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`동요반목`,`進向`,`  근`,`  留`,`상호반목`,`정북`,`정남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`정동`,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`이행`,`선난후난`,`向進`,`  원`,`  留`,`세입조력`,`정북`,``,`정서`],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,`의사참고`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`지체연기`,`이행`,`본인무력`,`退背`,`  원`,`  去`,`세입조력`,`북북서`,``,`북서`],
    [`10.11`,``,`제압됨`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인무력`,`退背`,`  근`,`  去`,`무난`,`정동`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,0,0,``,``,``,0,``],
    [``,0,0,``,``,``,0,``],
    [``,0,0,``,``,``,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,`고모신사`,`성물옮김`,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`저주부정`,`원한심`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,`없음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,`신화부정`,`近死혼령`,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,``,``,`묘좌`,0,`없음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,"자살귀","금속자살",``,``,`육친`,0,``],
    [`질병`,`수귀`,`익사`,``,``,`묘좌`,0,`특별있음`],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,``,``,`전경`,0,``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,"제례귀","제레위범",``,``,`육친`,0,``],
    [`질병`,0,0,``,``,`묘좌`,0,``],
    [``,0,0,``,``,`묘향`,0,``],
    [`생업`,0,0,`고모부정`,`神寫이탈`,`전경`,"있음",``],
    [``,0,0,``,``,`묘속`,0,``],
    [`가정`,0,0,`상가부정`,`조문왕래`,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`전도`,`자강불식-가나 있으나 좋음`,`일체근심해소사`,`내녀 색정사`,``,`화가 물러가고 복이 도래함`,`외사 출행이동사`,`문서헌책답신지체-구설사`,`직녹자산권리사`,`사사성취함`,`매사 허성불성**세속허사자**매사 위역사`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공`,`매사영속성없음**외사 급속사`,`종교진출하면자립함**예측불가사`,``,`상대의 마음을 가늠하기 어려움`,``,`손괴재물사`,`내 급속사`,`승진영전사`,`도적악의-공격 말 것`,`외남 자색정-화합이동사`,`매사 위역사**사귀는자로 인한 손해사`,`외 동요분산사-내 반목대립송쟁사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,`전도`,`빈궁`,``,`재액해소`,`빈궁`,`재액해소`,`관재`,`외사 막힘**굴욕난면-감옥관련`,`선천 숙명적 독신고독자`,`내사 출행이동사`,`빈궁`,`음5월 화재주의**전화위복`,``,`내사막힘`,`빈궁**병재`,`관재`,``,`빈궁**관재`,``,`침체경구사**남녀종업원모사有`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`사귀는자로 인한 손해사`,`외 동요분산사-내 반목대립송쟁사`,``,`자강불식-가나 있으나 좋음`,`일체근심해소사`,`내녀 색정사`,``,`화가 물러가고 복이 도래함`,`외사 출행이동사`,`문서헌책답신지체-구설사`,`직녹자산권리사`,`사사성취함`,`매사영속성없음**매사 위역사**굴욕난면사-감옥관련`,`종교진출하면자립함-선천 숙명적 독신고독자`,`매사영속성없음**외사 급속사`,`종교진출하면자립함**예측불가사`,``,`상대의 마음을 가늠하기 어려움`,`빈궁**병재`,`손괴재물사`,`내 급속사`,`승진영전사`,`도적악의-공격 말 것`,`외남 색정사-화합이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**관재`,``,``,`전화위복`,``,`재액해소`,`빈궁`,``,`빈궁**관재`,``,``,`외사 막힘`,`빈궁`,`내사 출행이동사`,`전도`,`음5월 화재주의**전화위복`,`전도**빈궁**관재`,`내사 막힘`,`재액해소`,`관재`,``,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`도적악의-공격 말 것`,`외남 색정사-화합이동사**매사 위역사`,`사귀는자로 인한 손해사`,`외 동요분산사-내 반목대립송쟁사`,``,`자강불식-가나 있으나 좋음`,`일체근심해소사`,`내녀 색정사`,``,`화가 무러가고 복이 도래함`,`외사 출행이동사`,`문서헌책답신지체-구설사`,`직녹자산권리사`,`사사성취함`,`매사영속성없음**매사 위역사**굴욕난면사-감옥관련`,`종교진출하면자립함**선천숙명적 독신고독자`,`매사영속성없음**외사 금속사`,`종교진출하면자립함**예측불가사`,``,`상대의 마음을가늠하기 어려움`,``,`손괴재물사`,`내 급속사`,`승진영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`빈궁`,``,`전도`,``,`전화위복`,`전도`,`빈궁`,``,`재액해소`,`빈궁`,`재액해소`,``,`외사 막힘`,`빈궁`,`내사 출행이동사`,``,`음5월 화재주의**전화위복`,`빈궁**관재`,`내사 막힘`,`빈궁**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`내 급속사`,`고위인을 도로에서 만나면 청원함이 좋음`,`정인질병-괴이구설사`,`외남 색정사-화합이동사`,`문서유실사`,`외 동요분산사-내 반목대립송쟁사**매사 위역사`,``,`반드시 성취함`,`남모르게 당하는 능욕박해사`,`내녀 색정사`,`계획미수사`,``,`외사 출행이동사`,`공공사사 구설사`,`직녹권리자산사`,`가정놀램불녕-가족 중 절상사`,`매사영속성없음관직자 이익-일반인 불리`,`종교진출하면자립함**선천 숙명적 독신고독자`,`매사여옥성없음**외사 급속사`,`종교진출하면자립함**빈궁`,``,`문서를 남몰래 옮김`,`송구경황--사통관련`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`재액해소`,`빈궁**관재`,`전도**빈궁`,``,``,``,`전화위복`,`빈궁`,`전도`,``,`빈궁`,``,`병재`,``,`외사 막힘`,`전도`,`내사 출행이동사`,`도적 못잡고 놀램`,`음5월 화재주의**전화위복`,`빈궁`,`내사 막힘`,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`송구경황-사통관련`,``,`내 급속사`,`고위인을 도로에서 만나면 청원함이 좋음`,`정인질병-괴이구설사`,`외남 색정사-화합이동사`,`문서유실사`,`외 동요분산사-내 반목대립송쟁사**매사 위역사`,``,`반드시 성취함`,`남 모르게 당하는 능욕박해사`,`내녀 색정사`,`계획미수사`,``,`외사 출행이동사`,`공공사사 구설사`,`직녹자산권리사`,`가정놀램불년-가족 중 절상사`,`매사영속성없음**선천숙명적독신고독자`,`종교진출하면자립함**외사 막힘`,`매사영속성없음**외사 급속사`,`종교진출하면자립함`,`문서를 남몰래 옮김`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 막힘`,`관재`,`전도**관재`,``,`전도관재**빈궁`,``,`빈궁`,``,`재액해소`,``,`빈궁**관재**전화위복`,`재액해소`,``,``,`빈궁**관재`,``,`빈궁**병재`,``,`외사 급속사**내사 이동출행사`,`관직자 이익-일반인 불리`,`내사 출행이동사`,`도적 못잡고 놀램**빈궁`,`음5월 화재주의**전화위복`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`문서를 남몰래 옮김`,`빈궁`,`송구경황-사통관련`,``,`내 급속사`,`고위인을 도로에서 만나면 청원함이 좋음`,`정인질병-괴이구설사`,`외남 색정사-화합이동사`,`문서유실사`,`외 동요분산사-내 반목대립송쟁사**매사 위역사`,``,`반드시 성취함`,`남모르게 당하는 능욕박해사`,`내녀 색정사`,`계획미수사`,``,`외사 출행이동사`,`공공사사 구설사`,`직녹자산권리사`,`가정놀램불녕-가족 중 절상사`,`매사영속성없음** 외사 막힘`,`종교진출하면자립함**선천숙명적독신고독자`,`매사영속성없음**외사 급속사**내사 출행이동사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음5월 화재주의**전화위복`,`동요불안분산사`,`내사 막힘`,`동요불안분산사`,`관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`전도**관재**빈궁**전화위복`,`동요불안분산사`,``,`동요불안분산사`,`빈궁**재액해소`,`동요불안분산사`,`빈궁**병재**재액해소`,`동요불안분산사`,`관직자 이익-일반인 불리`,`동요불안분산사`,`도적 못잡고 놀램`,`빈궁**동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음**외사 급속사`,`종교진출하면자립함**빈궁`,`문서를 남몰래 옮김`,``,`송구경황-사통관련`,``,`내 급속사`,`고위인을 도로에서 만나면 청원함이 좋음`,`정인질병-괴이구설사`,`외남 색정사-화합이동사`,`문서유실사`,`외 동요분산사-내 반목대립송쟁사**매사 위역사`,``,`반드시 성취함`,`남 모르게 당하는 능욕박해사`,`내녀 색정사**전도**빈궁`,`계획미수시`,``,`외사 출행이동사`,`공공사사 구설사`,`직녹자산권리사`,`가정놀램불녕-가족 중 절상사`,`매사영속성없음**관직자 이익-일반인 불리`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 출행이동사`,`도적 못잡고 놀램`,`음5월 화재주의**전화위복`,`빈궁`,`내사 막힘`,``,`전도**관재`,``,`전도**빈궁**관재`,``,`빈궁`,``,`관재`,`재액해소`,`전화위복`,`재액해소`,``,``,`빈궁`,``,`빈궁**병재`,``,`외사 막힘`,`선천 숙명적 독신고독자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**관직자 이익-일반인 불리`,`종교진출하면자립함`,`매사영속성없음**외사 급속사`,`종교진출하면자립함**전도**빈궁`,`도적을 추격했으나 못잡고 놀램`,`문서를 남몰래 옮김`,`경벌죄-고시득길`,`송구경황-사통관련`,`내 급속사`,`고위인을 도로에서 만나면 청원함이 좋음`,`정인질병-괴이구설사`,`외남 색정사-화합이동사`,`문서유실사`,`외 동요분산사-내 반목대립송쟁사**매사 위역사`,``,`반드시 성취함`,`남 모르게 당하는 능욕박해사`,`내녀 색정사`,`계획미수사`,``,`외사 출행이동사`,`공공사사 구설사`,`직녹자산권리사`,`가정놀램불녕-가족 중 절상사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 막힘`,`선천 숙명적 독신고독자`,`내사 출행이동사`,`도적 못잡고 놀램`,`음5월 화재주의**전화위복`,`전도**빈궁`,`내사 막힘`,`재액해소`,`관재`,``,`빈궁**관재`,``,`빈궁`,`재액해소`,``,``,`전화위복`,`빈궁`,``,`재액해소`,``,`빈궁**관재`,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`직녹자산권리사`,`고위안좌-이익경영사`,`매사영속성없음**매사 위역사**외사 막힘`,`종교진출하면자립함**굴욕난면사-감옥관련`,`매사영속성없음**외사 급속사`,`종교진출하면자립함`,``,`경벌죄-고시득길`,``,`숲에서 뱀을 만난 격`,`내 급속사**내사 막힘`,`고위인을 도로에서 만나면 청원함이 좋음`,`정인질병-괴이구설사`,`외남 이동사-화합색정사`,`사귀는 자로 인한 손해사`,`외 동요분산사-내 대립반목송쟁사**매사 위역사`,``,`도적 못잡음`,`酒食受罰사`,`내녀 색정사`,`손괴재물사`,``,`외사 출행이동사`,`공공사사 구설사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`선천 숙명적 독신고독자`,`빈궁`,`내사 출행이동사`,`주력사 흉함`,`음5월 화재주의**전화위복`,`빈궁`,`내사 막힘`,`빈궁`,``,`관재`,`관재`,`음사폐호-가택안녕`,``,`빈궁`,`전도**빈궁`,``,`전화위복`,`전도`,`빈궁**병재`,``,`재액해소`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사 출행이동사`,`재물을 남몰래 옮김`,`직녹자산권리사`,`도난 당함`,`매사영속성없음**외사 막힘`,`종교진출하면자립함`,`매사영속성없음**외사 급속사**내사 출행이동사`,`종교진출하면자립함`,`빈궁`,`상대의 마음을 가늠하기 어려움`,``,`계획사 미수`,`내 급속사`,`종종걸음반복사`,`간음사 이익-정대적이면 되려 재앙이 됨`,`외남 색정사-화합이동사`,`문서유실사`,`외 동요분산사-내 반목대립송쟁사**매사 위역사`,``,`흉을 만나 길로 변함`,`일체근심해소사`,`내녀 색정사`,``,`송구경황-사통인관련`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`전도**관재**빈궁`,``,`관직자 이득-일반인 불리`,`선천 숙명적 독신고독자`,`앙화소멸사`,`빈궁**병재`,`음5월 화재주의**전화위복`,`재액해소`,`내사 막힘`,``,`관재**빈궁**병재`,``,``,`관재`,``,`빈궁`,``,`빈궁`,`전화위복`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`화가 물러가고 복이 도래함`,`외사 출행이동사`,`문서헌책답신지체-구설사`,`직녹자산권리사`,`사사성취함`,`매사영속성없음**매사 위역사**굴욕난면-감옥관련`,`종교진출하면자립함**선천 숙명적 독신고독자`,`매사 허성불성**세속허사자**외사 급속사`,`음1,2,4,5월 방문자는 종교진출하면성공함`,`상대의 마음을 가늠하기 어려움`,`빈궁`,``,`손괴재물사`,`내 급속사`,`승진영전사`,`도적악의-공격 말 것`,`외남 색정사-화합이동사`,`사귀는 자로 인한 손해사`,`외 동요분산사-내 반목대립송쟁사**매사 위역사`,``,`자강불식-가나 있으나 좋음`,`내녀 색정사`,`일체근심해소사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`전도**관재**빈궁`,``,`전도`,``,`외사막힘`,`빈궁`,`내사 출행이동사`,`불측근심사`,`음5월 화재주의**전화위복`,`재액해소`,`내사 막힘`,`빈궁**병재`,``,`관재`,``,`빈궁**관재`,``,`빈궁`,`폐한인`,``,`전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`일체근심해소사`,`내녀 색정사`,``,`화가 물러가고 복이 도래함`,`외사 출행이동사`,`문서헌책답신지체-구설사`,`직녹자산권리사`,`사사성취함`,`매사 허성불성**세속허사자**매사 위역사`,`음3,4,5,6,9,12월 방문자는종교진출하면성공`,`매사 허성불성**세속허사자**예측불가사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,``,`상대의 마음을 가늠하기 어려움`,``,`손괴재물사`,`내 급속사`,`승진영전사`,`도적악의-공격 말 것`,`외남 색정사-화합이동사`,`사귀는 자로 인한 손해사`,`외 동요분산사-내 반목대립송쟁사**매사 위역사`,``,`자강불식-가나 있으나 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,`전화위복`,`빈궁`,``,`재액해소`,`빈궁`,`재액해소`,``,`외사 막힘**굴욕난면-감옥관련`,`빈궁**선천숙명적독신고독자`,`외사급속사**내사출행이동사`,``,`음5월 화재주의**전화위복`,`빈궁`,`내사 막힘`,`빈궁**병재`,``,`관재`,``,`빈궁**관재`,``,`빈궁`,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 돈 잃고 여자도 잃고, 
  이권을 상실하는 운이네!
가정에 
⊕가택음사부정, 
⊖가택상가부정이 들어서 토지ㆍ여자ㆍ금전에 재난이붙고    막히는거야!
⊕⊖신변에 고모신사부정이      들었어 !
`,`■ 혼자만 아는 비밀을 감추고    있자니 오죽 괴로울까?      
눈가리는 음사건이 있겠어!
진퇴양난인데기도ㆍ치성을     드려야  파란 기복을 면    하겠어!
`,`■ 매사 유명무실하겠는데,
가정ㆍ여자ㆍ자손문제도 겹쳤겠어!
기도ㆍ치성으로 감추는 것을 해소시켜야 좋아지겠어!
`,`■ 토지송사가 붙었겠는데
⊕가택신화선영, 
⊖가택고모신사 부정이 침입    해서 그래! 
 또, 여자 하나에 남자가     둘이 붙었어!

⊖신변에 고모신사부정이 
 침범했네 ?
`,`■ 집 권리가 넘어가게 생겼네? 

⊕가택상가부정이 들어서 
 그렇기도 하고, 
 남녀가 똑같이 바람나서     큰 후회를 남기게 생겼어!
`,`■ 지금까지 일들이 끝나는     운세네! 

⊕신부정귀, 
⊖전사귀 발동으로 일이 
꼬이는데 해소만 시키면
전화위복이 되겠어!

⊖신변 음사부정이 들었네 ?
`,`■ 색정불화로 부부 마음이     각기다른데다가관재
  고심까지 있겠네 !
  배반하고도 의지할데가     없으니 공허한 것 뿐
  이겠어!

⊕⊖신변에 저주원심부정이      침범 했네 ?
`,`■가정에 금전과ㆍ여자가 문제    겠어!의심․질투로이러지     도 저러지도 못하고,육친간    에도 불화이고 일도 결실이     없겠어 !  
⊕도로귀, 
⊖자살귀 침범때문이야! 
 해소시키고 하나로 결정
 하면 좋아져!
⊕신변 음사부정 침범햿어 !
`,`■여러개의 일, 여러사람이
 관련된 일을 하나로 결속    시키겠는데 ! 
 굽힌다음펴는처신으로     가면 좋겠는데, 
 내돈과 여자는 상실되겠어 ! 
⊕남의 부인을 유혹해서 
 색정거래를 하네 !
`,`■ 땅에 송사가 붙었네? 
  一男二女 삼각관계도 
  보이고! 여자가 약간의      도움을 주긴하지만 일을     뒤집지는 못하겠어!
⊕가택여고음강, 
⊖가택저주원심 부정을 해소    해야 한다.

⊕신변 음사부정 침범했네 ?
`,`■ 살얼음판을 걷고 있는 
  격일세? 

⊕가택신부정귀, 
⊖전사귀 장난을 받고 있어서    그렇네! 

여귀ㆍ자살귀까지겹쳤어!
그게 급해!
`,`■ 지금 놀래고 있는 일에 
⊕여귀, 
⊖수귀, 가정에는 
⊕신부정귀, 
⊖신불제사귀가 버티고 
  있으니 관재가 없을수가     없지! 이를 없애면 일이     호전될꺼야!`];
  const YOGYEOL1 = [`■ 전후좌우 모두 돈이니 봄ㆍ겨울점은 얻을 수 있으나 여름ㆍ가을점이면 돈과 여자로 인해 재앙이 있으리라.
  `,`■ 꼬리에 머리가 달렸고 머리에 꼬리가 달려 서로 물고 빙빙 돈다. 
  분수를 지켜야 두렵지 않을 것이다.
  `,`■ 임신이 되면 집안이 기울게 되니 쇠현태라서 그렇다. 집안에 자손이 비었고 부모의 도움이 없다.
  `,`■ 내가 돈을 구하러 갔으면 빨리 얻어내야 한다. 만약 주저하다가는 나중에 큰 고통이 생기리라.
  `,`■ 내게 귀인이 오고 가정에는 녹이 있으나 자세히 들여다보니 버릇없고 화목하지 못하는구나.
  `,`■ 지키고 있어야 살길이 열리고 근심걱정이 흩어질 것이다. 낮밤모두 귀인의 힘은 얻지 못한다.
  `,`■ 속이 빈 재물만 가득하니 그나마 버리면 오지 않는다. 집에 숨은 살이 있어 처나 여자 재물로 재앙이 온다.
  `,`■ 닭과 뱀이 합하여 관귀가 되고 처음과 두 번째 모두 허사이다.
  결국 약간의 소득은 있겠으나 도둑과 범이 나누어 간다.
  `,`■ 먼저 살고 뒤에 죽고 먼저 깨었다가 나중에 답답하나 밤정단은 귀신의 도움이 있고 낮정단은 귀인의 덕을 보리라!
  `,`■ 귀신이 묘 뒤에 숨었으니 부동산이 문제이다. 송쟁이 생기면 끝이 없으니 재물로 고생이 클 것이다.
  `,`■ 말 탄 귀신이 집으로 왔으니 허비되는 것이 많으리라. 타인과 교섭하고 동업하여도 귀인의 도움이 없어 허사일뿐이다.
  `,`■ 막아줄 사람은 없고 귀신뿐이다. 일반인은 크게 두려우니 관직자는 대단히 좋다. 삼기의 기특함이 오기 때문이다.`]
  const SIBO1 = [`■ 가정에 ⊕음사, ⊖상가
  부정이 들어 토지문제가
  막혀 신음한다.
  음식 때문에 고생하게 된다. 여기저기 모두 재물이 있는 것 같으나 이를 보류하고 옛것을 지켜야 유리하다. 어디로 가버리고 싶은 심정에 있다.
  기도ㆍ치성만이 살길이다.
  남녀 공히 별리ㆍ고독인이다.
  `,`■ 금전ㆍ여인사가 암매하고
  근심과 놀람이 큰 입장
  에 있다.
  다행히 삼전에 蛇ㆍ白이 없어 큰 재앙은 당하지 않는다. 
  눈을 가리는 비밀이 있다.
  그리하여 일이 종결되지 않는다. 모든 일이 가정에서 일어난다.
  ⊖일이 반복될 뿐 결실이 없다.
  `,`■ 시작은 있으나 끝이 없고
  모든 일이 공론공설일
  뿐이다.
  눈을 가리는 비밀이 있다.
  진퇴가 불명하고 의지거처가 난색이다. 8日, 8月, 8年의 세월이 흘러 좋아진다.
  자손의 우환이 생기고 속는 일도 발생한다.
  `,`■ 저주 받는 일이 있다.
  가정에 ⊕신화선영,
  ⊖고모신사 부정이 들어
  토지사 송쟁이 붙어있다.
  보통 3년이내 파산하는 것으로 본다.
  이사를 아래 길방으로 시키거나 기도ㆍ치성으로 부정을 해소해야 한다.
  ⊕일이 반복되기만 하고 결과가 없다
  `,`■ 부부가 모두 음탕하여
  외정을 갖고 있다.
  반드시 이혼ㆍ별리하게 된다.
  또한 ⊕상가부정이 들어 가택의 권리가 넘어간다.
  피차 예의가 없고 화합한다해도 결국 불화하고 만다.
  다인 다사의 취합사이다.
  `,`■ 가정에 ⊕허위사
  ⊖음란사로 허모가 많다.
  직업이나 종사업이 끝난다. 이때 다른 일을 하지 말고 기다리면 전화위복의 길행이 있게 된다. 
  가택을 수리하거나 옮기면 좋다.
  `,`■ 재물이나 여자로 인해 
  가정에서 흉해가 있게 된다.
  문서에 의해 토지사로 돈이 열린듯하나 결국 빈손이 된다.
  배반사로 동요 불안하고 의지처가 없게 된다. 관재를 피하여 은신한다.
  男은 별리ㆍ고독인이다.
  `,`■ 자충수적 우환이 있다.
  하극상의 봉변을 당한다.
  가정ㆍ女로 인해 의심
  질투건이 생긴다.
  육친골육이 상배하여 서로를 용납하지 못한다.
  직취영전사는 주인격 불성으로 이롭지 못하다.
  女는 별리ㆍ고독인이다.
  十月 임신사가 있다.
  자손의 우환이 있다.
  `,`■ 자충수적 우환이 있다.
  형제ㆍ친우가 방해하여
  장애가 있으나 후에 
  이루어진다.
  ⊕男이 他人의 부인을 유혹하여 자기여자로 쓰고 있다. 일은 굽히는 처신으로 도모하면 큰 이익이 온다.
  다인 다사의 취합사이다.
  `,`■ 토지사로 소송이 붙었다.
  여러 가지 사건을 혼자
  떠맡고 있다.
  특히 여인이 헌신적으로 금전의 도움을 주게 된다.
  一男二女의 삼각관계가 있다. 인택이 모두 퇴페될 위험이 있다. 
  가정 ⊕여고음강, ⊖저주원심 부정을 해소해야 吉하다.
  男은 별리ㆍ고독인이다.
  `,`■ 가내 우환이 있고, 가장을
  속이며 가인이 원한을 품고
  가해하려 한다.
  사람이나 집이나 허모가 많다.
  가택이나 생업을 이사ㆍ변경할 뜻이 있다.
  초전의 귀신을 해소해야만 좋아진다.
  十月 임신사가 있다.
  `,`■ 외사에 속는 일이 있고
  자손 우환이 있고, 가장을
  속이며 가인이 원한을 품고
  가해하려 한다. 
  입시ㆍ사관등 관공직사는 길하나 일반인은 해롭다.
  심중 놀람이 크나 근신하면 해소된다. 수법효과가 크다.
  十月 임신사가 있다.`]
  const SIBO2_1 = [`■토지財ㆍ분쟁·女 신변외사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.이권상실하고 재·녀 재액이 있다.
  `,`■財ㆍ女신변동요반목사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  人宅 쇠퇴의 상이다.
  `,`■사업ㆍ후원가정동요사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  자손 허위사가 있다.
  `,`■內外동요ㆍ실종財女사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■가택신변손해사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  재·녀 상실예방이 필요하다.
  `,`■가택내,사자손사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  질병을 예방하라.
  `,`■內外,財ㆍ女동요반목사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다. 기만 모욕의 문장사 형사건이 있다
  `,`■가정內ㆍ女人ㆍ자손사이다.
  내방조짐 상의 :  
  이동·원행사가 있다.어떤 일이든 처음 불성한다속는 일이 있고 자손 재액이 있다.
  `,`■후원ㆍ사업성취사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  재·녀 상실예방이 필요하다.
  `,`■가택내금전사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■가택이사ㆍ변동사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■관재신변외사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/032/row-1-column-1.png`,
    `/static/images/sagwa/032/row-1-column-2.png`,
    `/static/images/sagwa/032/row-1-column-3.png`,
    `/static/images/sagwa/032/row-1-column-4.png`,
    `/static/images/sagwa/032/row-1-column-5.png`,
    `/static/images/sagwa/032/row-1-column-6.png`,
    `/static/images/sagwa/032/row-1-column-7.png`,
    `/static/images/sagwa/032/row-1-column-8.png`,
    `/static/images/sagwa/032/row-1-column-9.png`,
    `/static/images/sagwa/032/row-1-column-10.png`,
    `/static/images/sagwa/032/row-1-column-11.png`,
    `/static/images/sagwa/032/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  제1국은 12신이 각각 본궁에 있으므로 복음과(伏吟課)로 한다. 을일(乙日)과 계일(癸日)은 극이 되어 초전으로 하니 진진(辰辰)이 초전으로 된다. 복음(伏吟)은 은복신음(隱伏呻吟))하며 움직이지 않으므로 이와 같은 이름이 붙었다.
    초전이 자형(自刑)이니 중전은 일지상신, 말전은 충지(冲支)를 취한다. 그리고 음일은 자신격(自信格)이라 부른다. 복음은 정(靜)이 극하여 동(動)을 생각하는 것이다. 자신이란 음일이면 스스로 유익하다는 것을 자각한다. 3전이 순토(純土)이니 4과가 모두 총재(總財)가 된다. 3전 계신(季神)은 유자격(遊子格)이라 이름하는데, 조용하면 복닉(伏匿)하여 움직이지 않고 움직일 때는 불안하다. 말전의 축(丑)은 둔간(遁干)하여 신(辛)의 간귀(干鬼)로 되며, 백호가 승할 때에는 반드시 재(財)로 인한 소송 사건이 발생한다. 또 음식물에 의해 몸을 상하게 된다. 백법에 이르기를 ｢전(傳)의 재가 귀(鬼)로 화하는 것은 구재(求財)를 보류하라｣고 하였다.
    지상(支上)에 묘신이 앉고 백호를 합병하면 복시(伏屍)가 있다. 일간일지상신에 묘신(墓神)이 승하면 모두 혼미하게 된다. 비전에 이르기를 ｢이 과는 구재에는 좋지 않다. 채권․채무가 명백하지 못하다｣고 하였다. 유자(遊子)와 참관격이 합병하는 것은 종적격(終跡格)이라 부른다. 관광․이동이 일정하지 않고 고요한 가운데 움직일 뜻이 생긴다. 전재(全財)는 윗사람이나 존장에게 이롭지 못하며 존친의 질병 관점에서는 사망된다.
    일귀는 역행하여 3전이 구(勾)․사(蛇)․백(白)이고, 야귀는 순행하여 3전이 구(勾)․백(白)․사(蛇)이다. 천장(天將)은 어느 것이나 하나의 길장도 없으니 흉과격(凶課格)이며, 분수를 지키고 모두가 조용히 있으면 흉을 피할 수 있으나 망동하여 활동하면 재화를 초래하게 된다.`
    ,
    // 2
    `  이 과는 4과 중에 상하의 극도 요극도 없으므로 묘성과(昴星課)로 한다. 음일이므로 유(酉) 아래의 술(戌)을 사용하여 초전으로 한다. 또 음일에는 일간상신을 중전으로 하고 일지상신을 말전으로 정한다. 즉 음양음(陰陽陰)이 교대로 나타난다. 태양(太陽) 중에 흑점이 있어 유(酉)의 형상을 이루므로 유(酉)를 목표로 하여 묘성이라 이름한다. 오(午)는 순수(旬首)로서 지상에 임하고 간상의 묘(卯)는 순미(旬尾)의 지(支)로서 폐구로 되어 건록폐구격(建祿閉口格)이 된다.
    초전의 재(財)는 지킬 수가 없다. 이 격은 암매우경(暗昧憂驚)의 형상이다. 백법에 ｢수미(首尾)가 서로 보는 것은 끝까지 좋은 상(相)이라고 하였다. 일간일지상신이 교대하여 사신(死神)으로 되면 인택에 모두 왕쇠 기복이 있다. 
    비전에는 ｢묘성격이라 해도 3전에 사(蛇) 호(虎)를 보지 않으면 무슨 일에서나 조용히 지키는 것이 좋다｣고 했다. 중전의 공망은 단교공망(斷橋空亡)으로 되니 중도에 장애가 있거나 혹은 중도에서 좌절된다. 을일(乙日)로서 말전 오(午)를 보면 명당격(明堂格)으로 되는데, 흉장을 본다 해도 길로 화한다. 
    묘성과는 조용하여 움직이지 않는다. 음일에는 형무(刑務) 사건 및 질병에서 흉으로 되고, 여자나 소인의 악모(惡謀)를 방비해야 한다.
    일귀는 역행하여 3전이 음․청․주이고, 야귀는 순행하여 3전이 음․합․공이다. 일간일지상신에 순미(旬尾)․순수(旬首)가 임하면 사물이 순환되며 처음과 끝이 없고, 떠나도 다시 돌아온다.`
    ,
    // 3
    `  이 과는 앞의 국과 같이 음일 묘성과이며, 3전 해인사(亥寅巳)는 원태격(元胎格)이다. 이 격은 생태(生胎)와 병태(病胎)와 절태(絶胎)로 구분하며 3전이 모두 쇠지(衰地)에 앉는다. 
    이 과는 유(酉)를 목표로 하며 상 또는 하를 취하여 초전으로 하므로 부앙격(俯仰格)이라 부른다. 음일은 태양이 지고 범(虎)을 보고 목을 수그리는 뜻이 있어 동사엄목(冬蛇掩目)이라 한다. 우경(憂驚)을 주관하며 진퇴가 모두 믿기 어렵다.
    백법에 이르기를 ｢중․말전의 공망은 초전으로 길흉을 정한다｣고 하였다. 비전에 ｢음일에 고개 숙여 초전을 취하는 것은 우환이 가까이에 있고 원태격으로 중․말전이 공망하는 것은 원태가 생육되지 않는다｣고 하였다. 초전은 목(木)의 장생이고, 중․말전의 공망은 시작은 있으나 끝이 없다. 일귀는 역행하여 3전이 현(玄)․공(空)․합(合)이고, 야귀는 순행하여 3전이 후(后)․주(朱)․청(靑)이다. 
    일귀는 중전이 공망한데다가 천공이 승하니 무슨 일에서나 공론 공설이며 유명무실의 징조이다.`
    ,
    // 4
    `  이 과는 제1과의 을축(乙丑)이 1하에서 1상으로 극하며, 축진(丑辰)을 초전으로 하여 중심과(重審課)이다. 일간기궁의 진(辰)은 지상에 붙어서 피차에 모두 재(財)이니 속히 재를 취하면 좋으나 늦어지면 정체되어 얻지 못한다. 축(丑)은 신(辛)의 둔귀이니 재에 의하여 화가 발생한다. 3전 축술미(丑戌未)는 가색격(稼檣格)이며 전재(全財)로 되니, 무슨 일에서나 합법적으로 이치에 따라 행하지 않는다면 곤란을 일으킨다. 
    3전4과에 묘신이 들고 또 4과 불비(不備)로 되어 기세를 의지할 곳이 형(刑)을 이루니 경공(驚恐)과 불안을 초래한다. 진술축미(辰戌丑未)의 충신(冲神)이 있으면 흉으로써 흉을 제어하므로 신중하다면 평안할 수 있다. 이 과는 납세(納稅)가 길의 근원으로 된다.
    백법에 이르기를 ｢주객(主客)이 서로 투합되지 않는 것은 형(刑)이 위에 있기 때문이다｣라고 하였다. 전재(傳財)가 귀(鬼)로 화하면 구재하는 것을 보류해야 한다. 비전에서는 ｢모두가 재로 되고 기세를 의지할 곳이 형(刑)을 띠면 힘으로 재를 쟁탈한다｣고 하였다. 또는 재를 보면 의리나 인정을 잊는다.
    일귀는 순행하여 3전이 청(靑)․주(朱)․후(后)이고, 야귀는 3전이 사(蛇)․음(陰)․백(白)이다. 전재성(全財星)은 인수(印綬)인 존장을 극하므로 윗사람에게는 이롭지 못하며, 4과가 재의 불비격이면 재가 완전할 수 없는 결함이 있다.`
    ,
    // 5
    `  이 과는 제3과의 묘미(卯未)가 1상에서 1하를 극하여 원수과로 되며, 3전이 해묘미(亥卯未)인 3합목국은 곡직격(曲直格)으로 된다. 3전에 육합․천후가 앉은 것은 음일격(陰泆格)이고, 일간상신 자(子)에 귀신이 앉고 일지상신이 건록으로 되는 것은 길격이지만 교차육해(交車六害)로 자묘(子卯)․신해(申亥)는 형해(刑害)로 되기에 화미로운 가운데 흉해가 있다. 천후(天后)․육합(六合)은 남녀가 부정하여 회한(悔恨)을 초래한다. 
    왕록(旺祿)이 택상에 임하고 백호가 승하여 목(木)국을 극하니 가택이 왕성하다 해도 자연히 기울어질 염려가 있다.
    원수(元首)는 길과에 더하여 음양일록장생(陰陽日祿長生)이 일간일지상신에 임하여 대개는 길조의 암시이다. 교섭사․화합타협사에서는 길한 가운데 의심이 생기고 또 해로운 일이 생긴다. 
    백법에는 ｢지상에 녹신이 임하면 권섭(權攝)이 바르지 못하며, 주객(主客)이 투합되지 않는 것은 형(刑)이 있기 때문이다｣고 했다. 비전에는 ｢목(木)국을 비견이 돕는 것은 신(神)이다. 비견(比肩)이 모이는 것은 재를 극하므로 구재에는 좋지 않으나 어떤 일이든지 서로 부조하면 이익으로 된다｣고 하였다.
    일간상신의 자(子)와 일지상신의 묘(卯)는 형(刑)으로 되어 피차의 교섭이나 상의할 일에서는 예(禮)가 없는 형(刑)이니 화합한 뒤에 불화를 초래한다. 일귀는 순행하여 3전이 백(白)․합(合)․후(后)이고, 야귀는 합(合)․후(后)․백(白)이다. 일야에서 모두 백호가 3전 중에 드는 것은 흉화를 면치 못한다.`
    ,
    // 6
    `  이 과는 4과 중 제2과․제3과․제4과에 모두 상하의 극이 있다. 법칙에 따라 하에서 상을 극하는 제2과의 오해(午亥)를 초전으로 하니 중심과로 된다.
    일간상신 해(亥)에 생신(生神)이 있어서 내몸을 유익하게 하고 적을 방비한다. 초전의 오(午)는 탈기로 되며 천공(天空)이 승하니 공탈(空脫)의 신으로서 우환을 풀기에 좋다. 중전 축(丑)은 둔간(遁干) 신(辛)의 귀(鬼)로 되며 재(財)이다. 이 재는 재액(災厄)의 재이다. 중심과로서 극적(剋賊)이 많으면 일이 순조롭지 못하다.
    3전은 화토금(火土金)으로 체생한다. 그리고 오(午)는 순의발용(旬儀發用)으로 되어 흉을 만나도 길로 화하므로 뜻밖의 기쁨이 있다. 사절격(四絶格)은 낡은 결절(結絶)에 좋고 신규로 일은 계획하지 말아야 한다.
    백법에는 ｢전후인종(前後引從)은 승진에 길하고 또 가택을 수리하고 옮기는 데에 좋다｣고 하였다.   
    백법의 제1법칙을 인용한다. ｢초전과 말전은 일지의 전후의 지(支)에 해당하여 오미신(午未申)으로 일지를 낀다.｣ 
    비전에는 ｢초․말의 인종은 화를 전화하여 복으로 한다｣고 하였다. 일귀는 순행하여 3전이 음(陰)․청(靑)․귀(貴)이고, 야귀는 역행하여 3전이 공(空)․후(后)․구(勾)이다.`
    ,
    // 7
    ` 이 과는 12신이 대충(對冲)하는 자리에 있어서 반음과로 되며, 을목(乙木)․술토(戌土)를 극하여 초전으로 된다. 중․말전은 충신(冲神)을 취하여 사용한다. 3전은 모두 공망의 재동으로서 쓸모가 없다.
    일지상신이 둔간귀(遁干鬼)로 되니 재물 또는 처(妻)로 인하여 재화를 초래한다. 즉 후(后)․청(靑)이 앉기 때문이다. 반음과(返吟課)는 무슨 일에서나 왕래 반복하며, 공망ㆍ묘신이므로 가기는 해도 오지는 않고, 또한 개공(皆空)이므로 길흉이 모두 유명무실로 끝난다. 재공(財空)은 보물산에 들어가서도 빈손으로 돌아온다. 백법에는 ｢모든 일이 공허하게 끝나고 사물을 추구하지 말라｣고 했다.
    거래격(去來格)의 공망은 이동할 뜻이 있어도 움직이지 못한다. 공망의 묘기격(妙機格)은 백법 중의 제94법에 해당하는 데, 원칙을 말하면 ｢무릇 공망을 요구하는 것과 요구하지 않는 것이 있으며, 천반의 공망은 유행(遊行) 공망이라 부른다. 천반은 순행하여 움직이므로 이와같은 이름을 갖게 되었다｣고 하였다. 길흉은 십중팔구를 주관한다. 지반 공망은 지저(地底) 공망이라 불러 길흉에서는 완전한 작용을 한다. 그리고 공망을 요구하는 것은 귀극(鬼剋)․도기(度氣)․묘신(墓神)․천반에서 일간을 극하는 것들이다. 자기를 생하는 것 및 구신(救神)․천덕(天德)․녹신(祿神)․생기(生氣)․재관(財官)․일간에서 천반을 극하는 것들은 공망을 바라지 않는다. 그런데 본 항목에서는 일곱 조항으로 해설하였다.
    3전이 공묘(空墓)로 되어 병의 점단 및 관공(官空)사건은 매우 좋지 못하다. 일귀는 순행하여 3전이 주․상․주이고, 야귀는 역행하여 3전이 또한 주․상․주이다. 진(辰)은 투망(投網)이라 불러 사물이 봉기(蜂起)하지만 공망하여 빈 것을 가리키고 빈 말로 끝난다.`
    ,
    // 8
    `  이 과는 4과에 상하의 상극이 있고 음일이니 음비(陰比)의 극이다. 사자(巳子)를 사용하여 초전으로 하니 지일비용과(知一比用課)이며 도액격(度厄格)이다. 3전 사술묘(巳戌卯)는 주인격(鑄印格)이지만 공망되어 주인(鑄印)이 규모를 이루지 못한다고 한다.
    초․중전이 공망되고 말전 묘(卯)가 건록이면 일간상신 유(酉)에서 극하여 모든 일에 허사가 많다. 그리고 백호․현무의 흉장이 승하여 접촉하면 놀랍고 두려운 사건이 일어나 재화를 초래한다. 도액격은 3하가 3상을 극하여 장도액격(長度厄格)이라 부르는데, 상도(常道)를 잃고 바르지 못하다. 윗사람․존장을 속이고 즉 재액을 헤아리므로 이런 이름을 붙였다. 가정은 화합되지 못하고 노소(老少)에게 재화가 나타나며, 육친 골육은 얼음과 숱으로서 서로 용납하지 못한다. 병환은 중하며 가정은 불길하고, 골육이 서로 배반한다. 양의 극에서는 남자에게 재앙이 있고, 음의 극은 소유(少幼)의 재앙을 주관한다. 길장이 3전 중에 승하면 후에는 흉을 구하는 작용을 한다.
    백법에 이르기를 ｢귀신이 육해(六害) 중에 승하면 소송 사건에서 정당한 시비라 해도 곡단(曲斷)을 만난다｣고 하였다. 3전이 묘(墓)이면서 묘(墓)에 드는 것은 애(愛)와 증(憎)으로 구분한다. 비전에서는 이르기를 ｢3하(三下)가 상을 극하면 장도액격이라 부르며, 바다를 건너는데 배가 없는 형상이니라｣라고 하였다. 즉 사물을 믿지 못하게 된다. 일귀는 순행하여 3전이 현․주․백이고, 야귀는 역행하여 3전이 백․주․현이다. 일간상신의 유(酉)에서 극을 받고 정신(丁神)이 승하므로 흉은 신속하다.`
    ,
    // 9
    `  이 과는 제1과․제3과에 상하의 극이 있으며, 제3과 해미(亥未)의 극을 사용하여 초전으로 하니 중심곡직격(重審曲直格)이다. 일간상신에 정관(正官) 귀신이 앉고 일간이 왕하여 부귀번영한 과격(課格)이다. 3합 중에서 신(申)과 해(亥), 자(子)와 묘(卯), 자(子)와 미(未)는 해(害)로 된다. 이와 같은 것은 먼저 장애가 있고 후에는 이루어진다. 또 형제․친우가 서로 견제하여 방해가 생긴다.
    백법에 이르기를 ｢후(后)․합(合)이 승하니 혼인은 자유 결혼이다｣라고 하였다. 귀신이 옥(獄)에 앉아 있어도 간상에 귀신이 앉으니 길조를 이룬다. 파차간에 의심하는 것은 육해(六害)가 중첩되기 때문이다. 비전에 이르기를 ｢목곡(木曲)은 번창하고 왕성한 형상으로, 초전 사신(蛇神)이 말전 용신으로 화하는 것은 반드시 탑승물(搭乘物)의 직(職)이며, 후에 공명이 성취된다｣고 하였다.
    일귀는 순행하여 3전이 합․백․후이고 교동격(狡童格)으로 되어 남자가 여자를 유혹한다. 야귀는 역행하여 3전이 동․현․청으로서 사화(蛇化)하여 청(靑)으로 되니 승진․전직 등에서는 승진하고 공명을 기대할 수 있다.
    해묘미(亥卯未)와 인묘진(寅卯辰)의 3전은 모두 목(木)국으로서 곡직이라 이름하는데 사물이 곡직파동한다. 목(木)은 동방의 것이고 제왕은 진(震)으로 나간다. 목공(木工)․목기(木器)로서 굽은 것이 곧게 되는 대는 봄을 맞은 까닭이고, 복양(伏陽)이 참여하여 진(震)을 이루는 것이다. 굴신하여 움직이고, 움직이면 반드시 화창하며, 조용하면 번영한다. 재를 구하면 세 배의 이득이 있고, 무슨 일에서나 먼저 굽(曲)고 후에는 곧((直)은 것이 좋다. 병과 소송을 꺼리고 마디 밖에서 가지가 생겨나는 형상으로서 만연한다. 일간 귀신에 정관이 앉으니 이름을 구하면 좋다. 사물의 빠른 것을 바랄때는 성취하기 어렵다. 대기만성(大器晩成)의 형상이다.`
    ,
    // 10
    `  이 과는 제1과가 하에서 상을 적하여 중심가색격(重審稼穡格)이다. 일간에서 보아 과전이 모두 재산이므로 정단사는 토지․가옥․여성의 사건이다. 사과삼전 모두 지형이므로 토지․재화(財貨)․여성 등의 사건으로 고심한다. 즉 축(丑)의 둔간 신(辛)의 관귀(官鬼)로 되기 때문이다. 분수를 알고 과한 욕심을 내지 않으면 좋고 구재는 속히 하는 것이 좋다.
    일지는 간상에 와서 재(財)가 되므로 반드시 재의 소득이 있다. 그러나 묘에 엎드려 축(丑)에서 충하므로 신속하게 개고하면 목적을 달성한다. 백법에 이르기를 ｢화개일(華蓋日)에 엎드리는 것은 사물이 회매(晦昧)하다｣하였다. 간묘(干墓)에 관(關)을 합병하면 인택이 모두 퇴폐될 두려움이 있다. 백호가 둔귀와 함께 있는 것은 재앙이 깊다. 주객이 투합되지 않는 것은 형(刑)이 위에 있다. 비전에서는 ｢3전의 전재는 재를 맞으나 낡은 것을 지키는 것이 좋다｣고 하였다.
    일지가 일간에 임하고 극을 받은 것은 췌서격(贅壻格)이라 하여 자립할 수 없다. 재신이 왕하므로 분을 넘기니 불비격으로서, 재를 구하여도 부족하거나 반면에 실비(失費)가 증가된다. 
    일귀는 역행하여 3전이 사(蛇)․음(陰)․백(白)이고, 야귀는 3전이 청(靑)․주(朱)․후(后)이다. 3전이 모두 토(土)성이면 가색격(稼穡格)이다. 토(土)는 침체하여 중정(中正)한 것이므로 임무는 중하고 일은 광대하다. 형충을 즐겨 개고(開庫)로 되고 개경(開耕)하는 형상으로서, 사물이 집합하고 공모하는 일, 혹은 두 셋이 모인 사건을 남에세 의탁한다. 천장이 길하면 전진하면 길하다.`
    ,
    // 11
    `  이 과는 제2과의 신오(申午)가 1하에서 1상을 적(賊)하는 것을 초전으로 하니 중심과이며, 3전 신술자(申戌子)는 섭삼연격(涉三淵格)이고 또 순간적격(旬間傳格)이다. 일지상신의 유(酉)는 일귀(日鬼)로 되어 순정(旬丁)이 승한다. 기궁 진(辰)과 유(酉)가 지합하고 오(午)와 미(未)가 지합하니 교차지합격(交車支合格)이다. 타협사는 성취되어도, 일간일지는 탈모(脫毛)하므로 뜻을 이루기 힘들다. 마음은 있으나 힘이 없으며, 길사는 늦게 이루어진다.
    백법에 이르기를 ｢천지반에서 천공이 공망을 만나는 것은 무슨 일이나 추구하지 말라｣고 하였다. 인택이 모두 탈설하는 것은 도난을 만날 염려가 있다. 섭삼연(涉三淵)이란 신술(申戌)이 음의 자리이고 말전의 자(子)는 음극의 지(支)이므로 위험한 형상을 말하는 것이다. 간전격(間傳格)은 한 자리를 건너서 3전을 형성하는데, 순간전(順間傳)은 중간에 조애가 있으나 일은 순(順)으로 주관한다. 일귀는 역행하여 3전은 귀․음․상이고, 야귀는 역행하여 3전이 구․주․귀이다.
    일야 천장(日夜天將)은 모두 길장이 많아 흉해도 구해 주며, 길할 때는 길사가 더욱 길하다. 제1과․제2과 즉 일상(日上)의 두 과 중 어느 것이 초전을 구성하면 외사관(外事官)을 주관한다. 초전의 지반에서 천반을 적하면 윗사람에게 재앙이 있다. 내부에서 일이 일어나면 후에는 이롭다. 초전이 일간의 귀(鬼)로 되면 처음에는 이익으로 되지 않는다. 그러나 오(午)의 제지(制支)에 있으면 귀라도 두려울 것이 없다. 만일 년명상신에 재성이 앉으면 구재에 길하다. 초전에서 행년을 극할 때는 그 해는 사물이 성사되기 어렵다. 3전이 모두 양지이면 일간상신에서 일을 처리한다.
    정신(丁神)의 정(丁)은 옥녀성(玉女星)의 정(精)으로서 능히 변화하고 능히 비약하며 능히 영동(靈動)하므로 도망자는 멀리로 간다. 도적은 몸을 숨기고 혼인은 간음하며 밀사(密事)가 이룩된다. 질병 또는 소송은 유암(幽暗)하며 진전이 어렵다. 대체로 어두움에 이롭고 밝음에 불리하다. 또 정신(丁神)은 움직임을 주관하니 육합과 동병(同倂)되면 친족이 멀리 떠난다. 천후와 동병하면 여인의 도주 사건이 일어난다.`
    ,
    // 12
    `  이 과는 4과 중에 극이 없고 제3과․제4과에서 천신이 일간을 극한다. 음일이니 음의 극인 류신(類神)을 사용하여 초전으로 하는 요극순진여격(遙剋順進茹格)으로서, 길흉이 모두 경하다.
    사건이 연달아 일어나므로 결정하기 어려우며, 계획하는 사업은 이룩되지 않는다. 연주(連珠)․삼기(三奇)․막귀(幕貴)가 택에 들어 입시(入試)․구관(求官)․취직에서 모두 크게 효과가 있다. 집안에 손님이 올 때에는 숙박시키지 말아야 한다. 반드시 구설사가 발생한다.
    백법에 ｢제3과․제4과의 두 과가 관귀(官鬼)로 되면 관재(官災)가 발생한다｣고 하였다. 비전에서는 ｢일간상신에 역마가, 지상에 정신이 앉고 청(靑)․상(常)이 앉으면 복상(腹喪)이 있다｣고 하였다.
    3전 유술해(酉戌亥)는 야간의 지(支)로서 모든 일의 목적이 속히 달성되지는 않는다. 오호(五虎)가 원둔(元遁)하고 3전이 을병정(乙丙丁)이 삼기(三奇)로 되니 적덕(積德)에 의하여 명리와 광명이 발생한다. 만일 년명상신에 청신(靑神)이 앉으면 반드시 기우(奇遇)가 있다. 
    오호둔(五虎遁)이란 을경(乙庚)이 무인(戊寅)에 시작되고 사축(巳丑)에 끝나므로 유(酉)에는 을(乙), 술(戌)은 병(丙), 해(亥)는 정(丁)에 해당되는 것을 말한다.
    백법의 42법에는 ｢3전이 삼기격(三奇格)이니 직위는 승진하고 보통 사람은 길태(吉泰)의 징조가 있으며 재화는 제거된다｣고 하였다. 일귀는 역행하여 3전이 후․음․현이고, 야귀는 순행하여 3전이 현․음․후이다. 태음이 술(戌)에 앉는 것은 암암리에 남의 방조가 있다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
