
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,8,6,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,5,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,10,4,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,11,3,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,12,2,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,1,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,2,12,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,3,11,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,10,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,5,9,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,6,8,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`불성`,7,7,`특별있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,`미초희경`],
    [`직업`,`직업 관련사`,`小`,`8`,``,`(술잔내려`],
    [`남편`,`남편 관련사`,`大`,`침체기`,`중중희애`,`받는경사)`],
    [`관길서외(官吉庶外)`,`관직자는 길하고 일반인은 두려운 상`,`大`,``,``,`부부불화`],
    [`가실불화(家室不和)`,`부부 불화의 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극남`,`남자 갈등사 `,`大`,`9`,``,``],
    [`여식`,`여식 관련사`,`小`,`소외기`,`가실불화`,`앙화소멸`],
    [`주색대기(酒色大忌)`,`주색을 멀리해야만 할 상`,`小`,``,``,``],
    [`불리고시(不利考試)`,`고시는 불리하고 없는 구설을 근심하는 상`,`大`,``,``,``],
    [`급속`,` 내외 급속송쟁사-상해 우려`,`大`,``,``,``],
    [`출행`,` 내사 출행 이동사`,`小`,`10`,``,`복통수욕`],
    [`부친`,` 부친 관련사`,`小`,`침해기`,`환성경사`,`출행이동`],
    [`외수래야(畏須來也)`,`나는 나쁘고 타인은 좋으니 반드시 두려움이 닥치는 상`,`大`,``,``,``],
    [`모사즉길(謀事卽吉)`,`계확사는 좋은 상`,`小`,``,``,``],
    [`후원`,`후원 관련사`,`大`,``,``,``],
    [`官病`,`음3월 관직직업권리사가 완성되나 본인 病 형제災害우려`,`小`,`11`,`남모르는`,`관재형흉`],
    [`모친`,`모친 관련사`,`小`,`쇠퇴기`,`능욕박해`,`부부반목`],
    [`재험위야(災險危也)`,`여럿을 움직이는 일에 금전을 탐하면 재난을 당하는 상`,`大`,``,``,``],
    [`부처반목(夫妻反目)`,`부부 반목 대립의 상`,`小`,``,``,``],
    [`박관`,`직업직위불안사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`12`,``,``],
    [`매사 영속성없음`,`직업직위권리남편남자자식사는 선천적 기복재해를 겪음`,`小`,`과시기`,`부부불화`,`관재형흉`],
    [`귀침제압(鬼侵制壓)`,`나를 해롭게 하는침해사가 없어지는 상`,`小`,``,``,``],
    [`재난전소(災難全燒)`,`재난이 모두 사라지는 상`,`大`,``,``,``],
    [`직녹`,`수상 직녹 자산 권리사`,`大`,``,``,`고위안좌`],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`1`,`외교막힘`,`多衆이익`],
    [`매사 영속성없음`,`명예자산권리사는 선천 숙명적 재해를 겪음-독신고독`,`小`,`절정기`,`불의투서`,`방책강구`],
    [`정사결절(正事結絶)`,`바른 일들이 모두 끝나는 상-마치는 것이 좋음`,`小`,`세속허사`,`불용`,`愛憎화합`],
    [`이어경영(利於經營)`,` 고위에 앉게 되어 모두에게 이익되는 일을 하게 되는 상`,`小`,`자로무망`,`失財`,`급속사有`],
    [`형제`,`형제  관련사`,`大`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`2`,`재난전소`,`남모르는`],
    [`색정`,`외男 색정사 有`,`小`,`면모기`,`색정사有`,`능욕박해`],
    [`입묘와병(入墓臥病)`,`병이 깊어 회생이 어려운 상`,`小`,``,``,``],
    [`폭객기능(暴客欺凌)`,`폭력배의 거짓과 능멸을 당하는 상 `,`小`,``,``,``],
    [`형제`,` 형제 관련사`,`小`,``,`관계불통`,``],
    [`결속`,`개혁사를 음4월 완성해 大財획득하나 부모재해 발생함`,`大`,`3`,`가택이사`,`의혹불녕`],
    [`大財`,`음4월 개신하여 대재를 획득하나 부모 근심이 발생함`,`小`,`학습기`,`간사은닉`,`남자갈등`],
    [`선비후득(先費後得)`,`먼저 손해나고 후에 득하는 상`,`小`,``,``,``],
    [`간사은닉(奸私隱匿)`,`정인과 딴 살림을 차리는 상-가택이사 관계성 불통`,`大`,``,``,``],
    [`금전`,` 금전 관련사`,`小`,``,`總是成災`,``],
    [`상업`,`상업 관련사`,`小`,`4`,`급속소송`,``],
    [`처첩`,`처 관련사`,`大`,`개시기`,`애증화합`,`가정막힘`],
    [`필무정옥(必無正屋)`,`필시 자기 집이 없는 상`,`小`,``,`상해실종`,`도적근심`],
    [`총시성재(總是成災)`,`모두 재난 뿐인 상`,`大`,``,`우려`,``],
    [`금전`,` 금전 관련사`,`小`,``,``,``],
    [`상업`,`상업 관련사`,`小`,`5`,`사송계류`,`재난전소`],
    [`색정`,`내女 색정사 有 `,`大`,`배양기`,`(왕래)`,`색정사有`],
    [`죄수의동(座守宜動)`,`앉아서 도모하나 움직여 진행하나 모두 좋은 상`,`小`,``,``,``],
    [`선난후양(先難後陽)`,` 먼저는 곤란하더라도 후에 밝아지는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`6`,`身物수손`,`관재병질`],
    [`자식`,`자식 관련사`,`小`,`존재기`,`침체불안`,`남편갈등`],
    [`기리이전(豈利前貪)`,`이익이 있기도 전에 욕심부터  내는 상`,`小`,``,``,``],
    [`관재질병(官災疾病)`,`관재 또는 질병의 상`,`大`,``,``,``],
    [`관사`,` 관사 관련사`,`小`,``,``,``],
    [`남편`,` 남편 관련사`,`小`,`7`,`복통수욕`,``],
    [`출행`,`외사 동요불안분산 출행이동원행사`,`大`,`단절기`,`동요출행`,`도적실세`],
    [`수난무실(守難無實)`,`수난을 참으나 되어지는 것이 없는 상`,`大`,``,`이동`,``],
    [`수욕복통(受辱腹痛)`,`복통관련 수욕의 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`관사직업부부불화사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`직업직위남자갈등여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"후원문서부친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,"후원문서모친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,"형제친구색정조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`직업직위여식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,"금전상업처조력사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"금전여인색정조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`관사직업출행원행사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정정당당하게 속결하는 것이 유리함`,0,0,`명분이 있는 사안이므로 정당하게 속결하되 좌고우면하거나 뒤로 밀면 되려 불리한 人`,0,0,`일이 띄엄띄엄 역으로 진행되어 퇴하려 하나 계속하게 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 일을 지체시키거나 뒤로 밀면 결과가 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 유순하게 처신하며 연기하면 반드시 유리한 결과를 맞게되는 人`,0,0,`일이 띄엄띄엄 역으로 진행됨**연정사가 있게되는 조짐 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정정당당하게 속결하는 것이 유리함`,0,0,`명분이 있는 사안이므로 정당하게 속결하되 좌고우면하거나 뒤로 밀면 되려 불리한 人`,0,0,`신규사가 있음**1남2녀 삼각관계 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 일을 지체시키거나 뒤로 밀면 결과가 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 유순하게 처신하며 연기하면 반드시 유리한 결과를 맞게되는 人`,0,0,`여러 일을 하나로 묶는 결속단합을 시도하나 음3월이라야만 가능함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`正事적인 옛 일들이 끓어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화가 밖에서 오니 신중해야 함`,0,0,`모든 일이 반복 무상하고 타인을 해치려해도 되려 자기가 해를 입을 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정정당당하게 속결하는 것이 유리함`,0,0,`명분이 있는 사안이므로 정당하게 속결하되 좌고우면하거나 뒤로 밀면 되려 불리한 人`,0,0,`가정에 長病자 근심이 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 일을 지체시키거나 뒤로 밀면 결과가 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 유순하게 처신하며 연기하면 반드시 유리한 결과를 맞게되는 人`,0,0,`여러 일을 하나로 일관시키는 개신개혁 결속단합을 꾀하나 차질이 있고 음4월이라야만 큰 돈이 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 일을 지체시키거나 뒤로 밀면 결과가 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 유순하게 처신하며 연기하면 반드시 유리한 결과를 맞게되는 人`,0,0,`신규사가 있음**1녀2남 삼각관계 있음**스스로 와서 금전여인사를 조력하는 사람이 있게 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 일을 지체시키거나 뒤로 밀면 결과가 유리하게 됨`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 유순하게 처신하며 연기하면 반드시 유리한 결과를 맞게되는 人`,0,0,`일이 순서적으로 띄엄띄엄 진행되는 듯하나 되려 역순이 됨**가정에 사상사가 우려됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)勢**만약 타인과 다툰다면 호가호위적 세로 상대를 공격해도 적중하지 않음`,0,0,`호가호위적 세로 타인을 공격해도 효과가 없게 되므로 중단하는 것이 좋은 人`,0,0,`일이 순서적으로 진행됨**타인이 이롭고 본인이 손해임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻은 있어도 행동으로 나서지는 않음`,0,0,`자기를 스스로 자임하고 해오던 일을 지키면서 새 기회를 기다림-고시준비생이 많음-비로소 때에 이른 人`,0,0,`소송사가 있음**신규사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성-무력`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`죄고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아벌동사-후회사겹침`,`기사`,`신사`,`외사`,`펴면사이면사겹침`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해됨`,`화해됨`,`화해됨`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`양자무력`,`양자무력`,`양자무력`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음8월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음7월`,2,1,3,`음1월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,1,1,2,`음12월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음3월-연기 또는 파혼우려`,1,2,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음2월`,2,2,4,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음1월`,3,2,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,1,2,3,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,2,3,5,`음6월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음1월`,2,2,4,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,2,3,5,`음6월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음9월`,2,3,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯해도 사람을 잘 조정하여 초반의 실패 외 중 종반에는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무도 수준이하로 종반에 재정의 파란을 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무 전반을 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조로 여러 사안을 하나로 일관시키는 개신을 단행하나 결국 역효과적이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력작이고 조급하여 업무 전반에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하나 외부적 요인으로 업무 전반이 동요불안분산되는 신고가 있게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실한 평가를 받으나 초 중반에 실적을 이루고 종반에 이르러 저조하게 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하고 업무의 개혁을 단행하여 큰 재정을 확보하게 되나 음4월이라야만 완성이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 초반에 재정을 허무하게 하고 종반에 실적을 이루지만 역시 본인에 의해 파란을 일으킴 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 중반 외 초 종반에는 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무도 초반의 실적이 본인에 의해 부서지고 중 종반에는 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무 전반을 소송사로 끝냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,` 集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인무력`,`退背`,`원`,`去`,`주인조력`,`정동`,``,`정서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선이후난`,`進向`,`  원`,`  留`,`주인가해`,`북북서`,``,`서북`],
    [`10.11`,``,``,``,`빠르게`,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`정북`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`했음`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`사안무력`,`進向`,`원근왕래`,`  留`,`주인무력`,`정남`,`정동`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` `,`본인반대`,`이행`,`선이후난`,`退背`,`  근`,`  去`,`세입가해`,`남남서`,`북북서`,``],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`세입가해`,`북서`,`정북`,``],
    [`10.11`,` 集`,`발현안됨`,``,`빠르게`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,`내부제압`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선난후난`,`退背`,`  근`,`  去`,`주인조력`,`정서`,`정동`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대 있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`발현안됨`,`이행`,`선난후난`,`進向`,`  근`,`  留`,`세입조력`,`북서`,`남남서`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인동조`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`세입조력`,`북북서`,`북북서`,``],
    [`10.11`,``,`연기제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,`속결요됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대있음`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`주인조력`,`정북`,``,`정남`],
    [`10.11`,`  多集`,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`내부동조`,`이행`,`선攻후攻`,`進向`,`  원`,`  留`,`무난`,`북북동`,``,`남남서`],
    [`10.11`,``,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인무력`,`退背`,`  근`,`  去`,`무난`,`동북`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`자살귀`,` 水 투신 `,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`자살귀`,`水 투신`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`자살귀`,`水 투신`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`수귀`,`익사`,0,0,`육친`,0,``],
    [`질병`,`음귀`,`쇠부정물`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사 출행이동사**내 손해가출사우려`,`재난해소-이익진취사`,``,`눈이 가려진 상-불가상해사`,``,`불리고시-없는 구설근심사`,`내사 출행이동사**외사 급속사**내외송쟁사`,`환성경사`,``,`가정불화사`,`매사 허성불성**세속허사자**명예자산권리사`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,`매사영속성없음**내사 급속사**중중희애사`,`종교진출하면자립함**복통-수욕사`,`앙화소멸사`,`외남자 색정사`,``,`고위에게  기쁨을 삼가하는 경사`,``,`도적실세-잡음`,`종업원모사有**내여자 색정사`,`음사폐호-가택안녕-이동사`,``,`탄식신음-병 또는 도모사 미수`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`곤궁`,`전화위복`,`전도**곤궁`,``,``,``,`곤궁**관재`,``,`전도`,`성공함**외교막힘`,`곤궁`,`선천 속명적 독신고독자`,`곤궁**병재`,`음5월 화재주의**전화위복`,`전도`,``,`재액해소`,`곤궁**병재`,`재액해소`,`곤궁`,`침체경구사`,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,``,`탄식신음-병 또는 도모사 미수`,`외사 출행이동사**내 손해가출사우려`,`재난해소-이익진취사`,``,`눈이 가려진 상-불가 상해사`,``,`불리 고시-없는 구설근심사`,`내사 출행이동사**외사 급속사**내외송쟁사`,`환성경사`,``,`가정불화사`,`매사영속성없음**명예자산권리사`,`종교진출하면자립함`,`매사영속성없음**내사 급속사**중중희애사`,`종교진출하면자립함**복통-수욕사`,`앙화소멸사`,`외남자 색정가`,``,`고위에게 기쁨을 삼가하는 경사`,``,`도적실세-잡음`,`내여자 색정시`,`음사폐호-가택안녕-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**관재`,``,`전도**빈궁**관재`,`전화위복`,`빈궁`,``,`재액해소`,`관재`,`재액해소`,`빈궁`,`외교막힘`,``,`빈궁`,`선천 숙명적 독신고독자`,`관재**빈궁**병재`,`음5월 화재주의**전화위복`,``,``,`빈궁**병재`,``,`빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내여자  색정사`,`음사폐호-가택안녕-이동사`,``,`탄식신음-병 또는 도모사 미수`,`외사 출행이동사**내 손해가출사우려`,`재난해소-이익진취사`,``,`눈이 가려진 상-불가상해사`,``,`불리 고시-없는 구설근심사`,`내사 출행이동사**외사 급속사**내외송쟁사`,`환성경사`,``,`가정불화사`,`매사영속성없음**명예자산권리사**외교막힘`,`종교진출하면자립함`,`매사영속성없음**내사 급속사**중중희애사`,`종교진출하면자립함**복통-수욕사`,`앙화소멸사`,`외남자 색정사`,``,`고위에게 기쁨을 삼가하는 경사`,``,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,``,``,`재액해소`,``,`관재**빈궁`,`전화위복**재액해소`,`빈궁`,``,``,``,`빈궁`,``,``,``,`빈궁`,`선천 숙명적 독신고독자`,`관재**빈궁**병재`,`음5월 화재주의**전화위복`,``,``,`전도**빈궁**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,``,`용모에 무관심해도 되는 한가함`,`내여자  색정사`,`관계성불통-가택이사-간사은닉사`,`매사위역사`,`재난해소사`,`급문사투서헌책불용-금전실재사`,`외사 출행이동사**내 손해가출사 우려`,``,`부부반목사`,``,`남 모르는 능욕박해사`,`내사 출행이동사**외사 급속사**네외송쟁사`,`고위안좌-이익경영사`,``,`폭력배의 기만능욕사`,`매사영소겅없음**매사 위역사**명예자산권리사`,`종교진출하면자립함**관재형벌 매우 흉함`,`매사영속성없음**내사 급속사`,`종교진출하면자립함**권한을 수여받는 경사有`,`도적악의-공격 말 것`,`외남자 색정사`,``,`觀書-문화풍아`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`빈궁`,`재액해소`,``,`빈궁`,``,`전화위복`,`빈궁`,`재액해소`,``,``,`빈궁`,``,`외교막힘`,`빈궁**병재`,`선천 숙명적 독신고독자`,`전도`,`전도**빈궁`,`음5월 화재주의**전화위복`,`빈궁**관재`,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`觀書-문화풍아`,``,``,`용모에 무관심해도 되는 한가함`,`내여자 색정사`,`관계성불통-가택이사-간사은닉사`,`매사위역사`,`재난해소사`,`급문사투서헌책불용-금전실재사`,`외사 출행이동사**내 손해가출사 우려`,``,`부부반목사`,``,`남 모르는 능욕박해사`,`내사 출행이동사**외사 급속사**내외송쟁사`,`고위안좌-이익경영사`,``,`폭력배의 기만능욕사`,`매사영속성없음**매사 위역사**명예자산권리사`,`종교진출하면자립함**관재형벌 매우 흉함`,`먀사영속성없음**내사 급속사`,`종교진출하면자립함**권한을 수여받는 경사有`,`외남자 색정사`,`도적악의-공격 말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,``,`관재`,``,`빈궁`,``,`전도**빈궁**관재`,``,`전도**관재`,`전화위복`,`빈궁`,``,`관재`,`재액해소`,`빈궁`,`재액해소`,`외교막힘`,`빈궁**병재`,`선천 숙명적 독신고독자`,``,`빈궁`,`음5월 화재주의**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`도적악의-공격 말 것`,`외남자 색정사`,`觀書-문화풍아`,``,``,`용모에 무관심해도 되는 한가함`,`내여자 색정사`,`관계성불통-가택이사-간사은닉사`,`매사위역사`,`재난해소사`,`급문사투서헌책불용-금전실재사`,`외사 출행이동사**내 손해가출사 우려`,`관재`,`부부반목사`,`전도**빈궁`,`남 모르는 능욕박해사`,`네사 출행이동사**외사급속사**내외송쟁사`,`고위안좌-이익경영사`,``,`폭력배의 기만능욕사`,`매사영속성없음**매사 위역사**명예자산권리사`,`종교진출하면자립함**외교막힘**관재형벌 매우 흉함`,`매사영속성없음**내사 급속사`,`종교진출하면자립함**권한을 수여받는 경사有`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사**빈궁`,`음5월 화재주의**전화위복`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사`,`관재`,`동요불안분산사`,`관재`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`전화위복`,`동요불안분산사`,`재액해소`,`동요불안분산사`,`재액해소`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도**빈궁**병재`,`동요불안분산사`,`선천 숙명적 독신고독자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음**내사 급속동요사`,`종교진출하면자립함**권한을 수여받는 경사有`,`도적악의-공격말 것`,`외남자 색정사`,`觀書-문화풍아`,``,``,`용모에 무관심해도 되는 한가함`,`내 여자 색정사`,`관계성불통-가택이사-간사은닉사`,`매사위역사`,`재난해소사`,`금문사투서헌책불용-금전실재사`,`외사 출행이동사**내 손해가출사우려`,``,`부부반목사`,``,`남 모르는 능욕박해사`,`내사 출행이동사**외사 급속사**내외송쟁사`,`고위안좌-이익경영사`,``,`폭력배의 기만능욕사`,`매사영속성없음**매사 위역사**명예자산권리사`,`종교진출하면자립함**외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`선천 숙명적 독신고독자`,``,`빈궁`,`음5월 화재주의**전화위복`,``,`전도**빈궁`,`관재`,`재액해소`,``,`재액해소`,`전도**빈궁`,``,`빈궁**관재`,``,`관재`,`전화위복`,``,`전도**빈궁`,``,``,`빈궁`,``,`전도** 병재`,`관재형벌 매우 흉함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**명예자산권리사**외교막힘`,`종교진출하면자립함`,`매사영속성없음**내사 급속사`,`종교진출하면자립함`,`모두 이루어짐`,`외남자 색정사`,``,`투서헌책 안 좋음-떠도는 구설-금전실재사`,``,`모두 재난 뿐임`,`내 여자 색정사`,`관계성불통-가택이사-간사은닉`,`탄식신음-병질사`,`모든 일이 이루어짐`,`도적 의혹근심사`,`외사 출행이동사**내 손해가출사우려`,``,`놀래고 의혹되어 불녕-금전실재근심사`,``,`승진도약사`,`내사 출행이동사**외사 급속사**내외송쟁사`,`관직자 대길-생살대권 쥠-일반인 형벌 대흉 `,``,`폭력배의 기만능욕사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`중중희애사`,``,`관직자 영전도약-일반인 반흉`,`빈궁**선천 숙명적 독신고독자`,`재액해소`,`음5월 화재주의**전화위복`,``,`빈궁`,``,`빈궁`,``,``,``,``,`빈궁**관재`,``,`빈궁`,`전화위복`,``,``,`전도**병재**관재`,``,`전도**빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,``,`폭력배의 기만 능욕사`,`매사영속성없음**명예자산권리사**외교막힘`,`종교진출하면자립함`,`매사영속성없음**내사 급속사`,`종교진출하면자립함`,`모두 이루어짐`,`외남자 색정사`,``,`투서헌책 안좋음-떠도는 구설-금전실재사`,``,`모두 재난 뿐임`,`내 여자 색정사`,`관계성불통-가택이사-간사은닉사`,`탄식신음-병질사`,`모든 일이 이루어짐`,`도적 의혹근심사`,`외사 출행이동사**내 손해가출사우려`,``,`놀래고 의혹되어  불녕-금전실재근심사`,``,`승진도약사`,`내사 출행이동사**외사 급속사**내외송쟁사`,`관직자 대길-생살대권 쥠-일반인 형벌 대흉`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`중중희애사`,``,`관직자 영전도약-일반인 반흉`,`선천 숙명적 독신고독자**빈궁`,`음5월 화재주의**전화위복`,`재액해소`,`빈궁`,``,`빈궁**병재`,``,``,``,``,``,`빈궁**관재`,``,`빈궁**관재`,`전화위복`,``,``,``,`전고**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내사 출행이동사**외사 급속사**내외송쟁사`,`도적을 추격했으나 못잡음`,``,`재물유실사`,`매사영속성없음**매사위역사**명예자산권리사`,`종교진출하면자립함**관재형벌 매우 흉함`,`매사영속성없음**내사 급속사`,`종교진출하면자립함**복통-수욕사`,`身물상실사`,`외남자 색정사`,``,`왕래사송계류사`,``,`혼인단장사`,`내 여자 색정사`,`관재질병 이동사`,`매사위역사`,`눈이 가려진 상-불가 상해사`,`외사 출행이동사**내 손해가출사우려`,`재난해소-이익진취사`,``,`안방을 지켜 가나 있으나 좋음`,``,`송구경황-색정사관련`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`관재`,``,`외교막힘`,`빈궁**병재`,`선천 숙명적 독신고독자`,`전도**빈궁`,`전도`,`음5월 화재주의**전화위복`,`빈궁`,`재액해소`,``,``,`빈궁`,``,`빈궁`,`재액해소`,``,`관재`,`관재`,`전화위복`,`빈궁**관재`,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`송구경황-색정사 관련`,`내사 출행이동사**외사 급속사**내외송쟁사`,`도적을 추격했으나 못집음`,``,`재물유실사`,`매사영속성없음**매사위역사**명예자산권리사`,`종교진출하면자립함**외교막힘`,`매사허성불성**세속허사자**내사 급속사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`身물상실사`,`외남자 색정사`,``,`왕래사송계류사`,``,`혼인단장사`,`내 여자 색정사`,`관재질병 이동사`,`매사위역사`,`눈이 가려진 상-불가 상해사`,`외사 출행이동사**내 손해가출사우려`,`재난해소-이익진취사`,``,`안방을 지켜 가나 있으나 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`전도**빈궁**관재`,``,`전도`,``,`빈궁**병재`,`관재형벌 매우 흉함`,`빈궁**선천 숙명적 독신고독자`,`복통-수욕사`,``,`음5월 화재주의**전화위복`,`재액해소`,`빈궁`,``,``,`빈궁`,``,`빈궁`,``,``,`관재**폐한인`,`관재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`눈이 가려진 상-불가 상해사`,``,`불리 고시-없는 구설근심사`,`내사 출행이동사**외사 급속사**내외송쟁사`,`환성경사`,``,`가정불화사`,`매사허성불성**세속허사자**내사 급속사**외교막힘`,`음3,4,5,6,9,12월방문자는 종교진출하면`,`매사허성불성**세속허사자**내사 급속사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`앙화소멸사`,`외남자 색정사`,`고위에게 기쁨을 삼가하는 경사`,`사기득재사`,``,`도적실세-잡음`,`내 여자 색정사`,`음사폐호-가택안녕-이동사`,``,`탄식신음-병 또는 도모사 미수`,`외사 출행이동사**내 손해가출사우려`,`재난해소-이익진취사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,`전화위복**재액해소`,``,`빈궁`,``,``,`빈궁`,``,`중중희애사`,`성공함**명예자산권리사`,`빈궁`,`선천 숙명적 독신고독자`,`빈궁**병재`,`음5월 화재주의**전화위복`,``,``,`전도**빈궁**병재`,``,`전도**빈궁`,``,``,``,`관재`,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 내 녹은 소송으로 비어
  버리고 모두 등을 돌렸으니
 새로운 일도 어두워지기     만 하겠어 ! 

⊕가택음사부정을 해소해야만    나중에 기회가 오게 되겠어!
`,`■ 금전과 재물이 비었으니     물러나서 때를 기다리며     기도ㆍ치성을 드리면 
  좋아질거야! 
  나아가면 절벽을 만나게 돼!

⊕⊖신변 목기부정이들었네?
`,`■ 집밑에 해골이 묻혔고,
⊕변사귀, 
⊖가택상가부정,신부정귀      침범으로 가정에 
⊖병자, 
⊕도난상해사가 있겠어! 
 일이 거꾸로 가는 격이야!
`,`■ 집도 넘어가게 생겼고 
  一男二女 색정사도 있고…     재산에 반목시비도 보이고…

⊕변사귀, 
⊖신부정귀 침범 때문에 
 그렇네!
`,`■가정에 속는 일이 벌어졌네?    병자도 있고…
⊕자살귀, 
⊖도로귀가 침범했고 
집에 ⊕가택상가부정, 
   ⊖가택목기부정이 들었        으니 풀어야 형통해!
`,`■가정이나 여자가 문제네!    의심과 질투로 이러지도
 저러지도 못하고있겠어!
 직․녹 손실도 되겠고 !
⊕자살귀, 
⊖도로귀 때문인데, 
재물에 재앙이 오겠어!

⊖신변 음사부정 침범했네 ?
`,`■색정불화로부부간에 마음이    다르니가정이소란하고     불안하겠어!
⊕변사귀, 
⊖시누정귀가 발동했어! 
 신불기도로 안정을 해야    겠네!
`,`■ 시작은 있어도 끝이 없는     운세네! 
  구설ㆍ저주도 받겠고…     기도외에는 방법이 없네!


⊕⊖신변음사부정이들었네 ?
`,`■확 바꾸게 생겼네?
일도 여러개,사람도 여럿   인데 하나로 결속시키겠어
개신ㆍ개혁으로 돈깨나 
들어오겠는데 
⊕수귀, 
⊖여귀를 해소해야 巳月에     큰 돈돼!
`,`■한 여자가 두 남자와 삼각관    계를 맺고 있겠네 !!

⊕음귀, 
⊖신부정귀를 해소해야 도움    받는 일이 크게 열리게 될    거야!

⊕신변 음사부정 침범했네?
`,`■ 남이 도와주는 일을 그대로     믿으면 독이 되겠어! 

⊕수귀ㆍ가택수망노부, 
⊖여귀ㆍ가택음사부정부터     해소하고 이사하면 일이     잘 풀리게 되겠네!
`,`■ 잘가고 있는데 왜 이사하고     변동하려할까?
  급한 것은 이사변동이
  아니고 
⊕음귀, 
⊖신부정귀 물리치는 일이겠어?`];
  const YOGYEOL1 = [`■ 좋은 것은 비어 있고 종전 재물에 낮정단도 적이 붙었다. 밤정단은 부모를 범이 해치고 또 숨은 살이 노리는구나.
  `,`■ 후퇴하면 살 곳을 만나고 전진하면 골짜기를 만난다. 일이 중간에 이르러 말이 범으로 변해 나를 해친다.
  `,`■ 머리에 꼬리 붙고 꼬리에 머리가 붙었다.
  문고리처럼 마주 물고 있으니 귀인에게 부탁하면 이롭다.
  `,`■ 내가 가서 저를 만났으니 쉽게 떨어지기 어렵다. 욕심이 있으나 손에 넣지 못하고 도리어 두려움만 닥친다.
  `,`■ 비록 일이 잘 안되더라도 나중에는 도리어 잘 된다. 오직 시간을 두고 기다리면 좋으니 삼합ㆍ육합이기 때문이다.
  `,`■ 서로 무례하니 입 다무는 것이 최고 상책이다. 각자가 어리석으니 병에는 약이나 음식을 못먹게 된다.
  `,`■ 서로간에 큰 해가 되니 시기와 질투가 가득하다. 밤정단은 악귀의 장난이 있고 낮정단은 신벌이 있다.
  `,`■ 나나 너나 다 답답하다. 밤정단은 귀인의 도움이 없고 낮정단은 음 三月점이면 병이 위태하다. 몸이 관속에 들어간다.
  `,`■ 낮정단은 숨은 귀인이 도우나 재수나 사업은 깨지게 된다. 밤정단은 천신이 도와 돈을 벌게 되고 또 투자하여 그만큼 번다.
  `,`■ 명예와 재산이나 부귀가 있어도 밤정단은 좋지가 않다. 여러 사람이 칭송하니 두고두고 후원을 계속하리라.
  `,`■ 귀신이 묘 위에 앉아 있으니 집안에 병자가 생기겠다. 밤정단은 숨은 귀신이 범을 타고 오니 믿었던 일이 해가 된다.
  `,`■ 사람과 집이 모두 잘되니 달리 변동하면 재앙이 생긴다. 귀인이 많아도 쓸데 없고 건강이나 챙기면서 기다리라.`]
  const SIBO1 = [`■ 남녀 모두 별리 고독인이다.
  과부ㆍ홀아비이거나
  떨어져 사는 경우를 말한다.
  화합하는 중에 형상사의 불씨가 있다.
  움직이면 헛된 사건이 있게 되므로 동기가 없어야 한다. 남의 도움을 받더라도 독이 있다.
  직위 손실사가 있다.
  `,`■ 타인에게 속임을 당하는
  일이 있다.
  무슨 일이든 처음에는
  불성하게 된다.
  타인의 도움에는 반드시 독이 숨겨져 있다. 흉이 신속하므로 주의해야 한다. 외로는 보잘것 없어도 내로는 실익이 있다.
  때를 기다리는 것이 좋다.
  자손의 우환 속는 일이 있다.
  `,`■ 달이 서산에 지는 형국이다.
  사적인 일은 좋으나 공직사는
  불리하다.
  가택지하에 복시가 있어 화환이 있다.
  귀신을 제거하면 계획사가 길해하게 된다. 
  단, 해산ㆍ분리ㆍ의혹사는 나쁘다.
  
  `,`■ 이익사가 있고 획득하게 된다.
  옛것을 버리고 새로운
  것을 취하게 된다.
  가택의 권리가 넘어간다.
  인택의 복리가 있으나 가택 공망으로 가볍다.
  一男二女의 삼각관계가 있다.
  직위 손실사가 있다.
  `,`■ 저주받는 일이 있고,
  가택에 ⊕상가부정
  ⊖목기부정이 들어 이를
  해소하면 목적사가 달성된다. 上人이 下人에게 의탁하여 吉함을 얻게 된다.
  특히 밤 정단에는 蛇가 龍이 되는 격이다.
  正月 임신사가 있다.
  다인 다사건이다.
  속는 일이 있게된다.
  `,`■ 자충수적 우환이 있다.
  正月占에는 연인이 임신했다.
  무슨 일이든 처음의 조건에 응하지 않으면 중ㆍ말의 공망으로 시작은 있고 끝이 없는 결과가 된다.
  병은, 수족부자유ㆍ손상 등이다.
  `,`■ 신불에 의한 해가 있다.
  기도해야 한다.
  정치인이나 관리는 ⊕탄핵을 받는다. 일반인도 상인의 질책이 있다. 
  화평한 속에 의심이 생겨 어떤 일도 반복 무상하며 두가지 일에 관련된다.
  처음은 좋은 듯하나 결과는 그림의 떡이다.
  `,`■ 人宅에 각기 墓가 올라
  암매ㆍ침체하고, 시작은
  있으나 끝이 없다.
  병 정단은 내장의 적괴ㆍ암등으로 죽는 것으로 본다.
  단, 午띠는 凶이 경미하다.
  후원사에는 독이 들었으므로 주의가 필요하다.
  `,`■ 가내 우환이 있고, 가장을
  속이며 가인이 원한을 품고
  가해하려 한다.
  여자는 얼굴에 장애가 있다. 구재는 먼저 쓰고 나중에 얻는 격이다. 
  여러 금전사를 개신ㆍ개혁하려 한다.
  四月이 기회이다. 자칫 공론으로 끝날 우려가 있다.
  正月 임신사가 있다.
  `,`■ 가내 우환이 있고, 가장을
  속이며 가인이 원한을 품고
  가해하려 한다.
  사람들의 천거가 있고 원조 받으나 신중하지 않으면 자칫 말뿐으로 참된 뜻을 결한다.
  ⊕처가의 상복을 입는다.
  부모의 상사가 겹친다. 
  타인이 스스로 와서 금전을 도운다.
  `,`■ 모든 일은 공적으로 
  진행해야 한다.
  점차 진행이 길하다.
  가정에 ⊕수망노부, ⊖음사부정이 들어 발병자가 생긴다. 
  해소시키면 처음은 어려우나 후에 풀리게 된다.
  일이 반복되므로 덕을 쌓고 때를 기다려야 한다.
  광명정대하라!
  正月 임신사가 있다.
  `,`■ 上人이 와서 처첩의 
  임신사를 묻는다.
  맹렬히 활동하지 않아도 앉아서 복을 얻게 된다.
  옛것을 지켜야 좋다.
  함부로 진퇴를 결정하지 말고 시기를 엿보는 처세가 좋다. 또 옛것을 응용하는 일은 좋다.`]
  const SIBO2_1 = [`■직녹신변외사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.낭비가 많고 재·녀 상실하고 형제의 재액이 있다.
  `,`■사업ㆍ후원,가정단합사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  진퇴양난의 상이다.
  임신사가 있다.
  `,`■자손ㆍ박관,가정손해사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■직녹신변외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.낭비가 많고 재·녀 상실하고 형제에 재액이 있다.
  `,`■官･夫,가택내사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다.병재·관재 예방이 필요하다.
  `,`■자손ㆍ박관,가택화합사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  모사에 의심이 있다.
  `,`■부부이심가택동요사이다.
  내방조짐 상의 : 
  원행·이동사가있다.
  움직임이 근본이고 동기가 발동한다.
  `,`■사업ㆍ후원골육화합사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  身․宅이 혼미한 상이다.
  `,`■財·女·신변·이동·외사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 부모 재액 예방이 필요하다.
  `,`■財ㆍ女,가택내사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官･夫,가택내사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女신변외사이다.
  내방조짐 상의 :  
  이사·이전사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/033/row-1-column-1.png`,
    `/static/images/sagwa/033/row-1-column-2.png`,
    `/static/images/sagwa/033/row-1-column-3.png`,
    `/static/images/sagwa/033/row-1-column-4.png`,
    `/static/images/sagwa/033/row-1-column-5.png`,
    `/static/images/sagwa/033/row-1-column-6.png`,
    `/static/images/sagwa/033/row-1-column-7.png`,
    `/static/images/sagwa/033/row-1-column-8.png`,
    `/static/images/sagwa/033/row-1-column-9.png`,
    `/static/images/sagwa/033/row-1-column-10.png`,
    `/static/images/sagwa/033/row-1-column-11.png`,
    `/static/images/sagwa/033/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 천지본궁에 있어서 복음과로 된다. 양일에는 일간상신을 초전으로하고, 중․말전은 형충(刑冲)을 취한다. 원래 육임과식(六壬課式)의 구성법에서는 극을 취하여 초전으로 정하는 것이 법칙이지만, 이 과는 극적(剋賊)․요극 등이 없으므로 충형 등의 싸움하는 지(支)를 사용하여 극을 대신한다. 원래 길흉은 움직임에 의하는 것은 물론이며, 조용하면 길흉의 동기를 이루지 않는 것이다. 따라서 극을 취하고, 충형(冲刑) 등을 취하는 것이다.
    초전은 병(丙)에서 보아 사(巳)가 덕록이지만 공망된다. 중전은 일간의 재로 되나 현무의 흉장이 앉는다. 다행히 말전은 일간의 장생으로 되고 일지의 생기(生氣)로 된다. 4과는 상하에서 모두 사신(巳申)이 지합하여 아름답지만, 동시에 지형(支刑)이기도 하므로 화합 상조하는 가운데 형쟁(刑爭) 불화를 초래한다. 따라서 길이 부족한 과격이다.
    백법에 이르기를 ｢사(巳)의 공망에 천공(天空)이 앉는 것은 헛된 사건이 많으므로 일을 추구치 말라｣고 하였다. 말전 인(寅)은 임(壬)의 둔간귀이므로 흉해를 만나면 해가 작지 않다. 비전에 이르기를 ｢3전은 화금목(火金木)으로서 체극(遞剋)이 되어 서로 형극하므로 화합이 결여된다｣고 하였다. 4과가 모두 합하는 것은 간지상합격(干支相合格)이라 부르는데 교역(交易)사에 길하다. 초전 덕록이 공망하고 년명 사(巳)의 공망이 앉은 것은 도리어 길조이다. 입시는 모두 길하다.
    일귀는 역행하여 3전이 구․사․백이고, 야귀는 순행하여 3전이 공․현․합이다. 재신에․현무․사신(蛇神)이 승하면 재를 얻은 후에 잃는다. `
    ,
    // 2
    `  이 과는 4과 중 제2과에서 1상이 1하를 극하여 묘진(卯辰)을 초전으로 하는 원수과(元首課)이다. 3전 묘인축(卯寅丑)은 퇴여격(退茹格)이지만, 초전 진(辰)이 공망에  앉아 진여(進茹)로 변한 것이다. 그러나 전진할 때는 진의 공망에 든다.
    중전은 일지의 역마로 되고 둔간하여 임수(壬水)의 귀(鬼)로 되기에 마재귀호(馬載鬼虎)라 하여 흉재가 일어날 때는 신속하게 발생한다. 사관․구직은 길조를 이룬다. 제1과․제2과는 공망하고 제3과․제4과는 실(實)인데 지합으로 되며, 순수(旬首)의 지지이므로 외사는 허하고 내사는 모두 실한다.. 일간일지상신은 묘신이 엎드려 정체되거나 사물이 통쾌하게 진행되지 못한다. 
    백법에 이르기를 ｢초전이 공망하는 지(地)에 천공(天空)이 승할 때는 사물이 공허하니 추구하지 말아야 한다｣고 하였다.
     비전에 이르기를 ｢묘인축(卯寅丑)은 연방회린(聯芳悔吝)이라 하여 비관한 뒤에 안태가 온다｣고 하였다. 묘목(卯木)이 공망하고 또 구진(勾陳)이 승하면 가옥에 고장이 생긴다. 술(戌)이 해(亥)에 가하여지면 초전을 이루지 않는다 해도 하괴(河魁)가 천문(天門)을 건넌다고 하여 조격(阻隔)이 생하기 쉽다. 일귀는 순행하여 3전이 공․백․상이고, 야귀는 3전이 구․합․주이다. 모든 일에 때를 기다리는 것이 이롭다. 년명이 술자(戌子)이면 두 귀신이 승하여 흉이 없고, 길을 초래하여 기쁨을 만나고 윗사람의 후원을 받는다. 초전지반공망은 고진격(孤辰格)이다.`
    ,
    // 3
    `  이 과는 제2과․제3과에 상하의 극이 있어서 하에서 상을 적한다. 제2과의 축묘(丑卯)를 사용하여 초전으로 하고 중심역간전격(重審逆間傳格)이다. 일간상신의 묘(卯)는 순미(旬尾)의 지(支)이다. 지상의 오(午)는 순수(旬首)이다. 이와 같은 것은 돌아서 다시 시작된다.
    일순주편격(一旬週遍格)이라 하여 정단사에서는 계획하는 바가 모두 이루어진다. 역간전격은 전진할 때 곤란이 있는 형상이며, 3전에 일야귀신이 승한다. 그러므로 입시․선거․윗사람의 알현(謁見)에서 모두 목적이 달성된다. 해산(解散)․분리․우려되는 의혹의 정단에서는 모두 좋지 못하다. 
    일지상신에 백호․현무가 승하는 것은 가상(家相)을 정단하면 복시(伏屍)의 화환(禍患)이 있다. 3전이 극음으로 되어 사사(私事)에는 이로우나 공사(公事)에는 불리하다. 일귀는 순행하여 3전이 구․주․귀이고, 야귀는 3전이 주․귀․음이다. 공명․구관(求官)은 이익이 없다.
    백법에 이르기를 ｢수미(首尾)가 상견(相見)하면 시종이 길행하다｣고 하였다. 3전에 일야 두 귀신이 승하니 진학이나 입시 등은 양호하다. 
    비전에 이르기를 ｢역간전퇴여격(逆間傳退茹格)은 달이 서산에 지는 형상으로서 사물이 발전하기 어렵다｣라고 하였다. 축(丑) 또는 미(未)에 구진(勾陳)에 승하면 토지․가옥의 다툼이 일어난다.`
    ,
    // 4
    `  이 과는 제3과가 사신(巳申)으로 홀로 1상이 1하를 극하여 원수원태격(元首元胎格)으로 된다. 초전의 공망은 과숙격(寡宿格)으로서 사과불비격(四課不備格)이다. 일간기궁이 일지상신에 가해져서 사람은 상대방에 가서 재(財)로 된다. 즉 내몸이 재에 붙는다. 그러나 공망되므로 힘이 없다. 초전의 덕록 또한 공망된다. 원태격은 옛 것을 버리고 새로운 것을 취하는 형상이다. 3전이 4과를 떠나지 않는 것은 회환격(廻環格)으로서 옛 일을 되풀이 할 뿐이다. 청룡이 인(寅)에 승하여 생기(生氣)로 되어 서서히 발복한다.
    백법에 이르기를 ｢일간상신이 역마로 되고 일지상신은 건록에 해당하여 간지에 녹마가 승하면 명리가 향상된다｣라고 하였다. 건록이 지상(支上)에 임하는 것은 권섭부정격(權攝不正格)이다.
    비전에는 ｢간상의 인(寅)이 역마로 되고 지상의 사(巳)는 일록(日祿)이 되어 초전이 상기(相氣)한다. 말전 해(亥)에 귀신이 승하고 년명에 이상의 길신이 재차 승하면 영화격으로 되어 인택 출입이 모두 복리를 초래하고 명리가 신달(伸達)한다｣라고 했다. 사(巳)의 공망에 의하여 길은 경하다.
    일귀는 순행하여 3전이 상(常)․청(靑)․주(朱)이고, 야귀는 3전이 공(空)․합(合)․귀(貴)이다. 4과가 1양2음으로 되니 음의 불비격이라고 하며, 부정한 일로 1남이 2녀를 다투는 형상이다. 바른 일에 결함이 있다는 것을 알아야 한다. `
    ,
    // 5
    `  이 과는 제4과의 자진(子辰)이 아래에서 위를 적하여 초전으로 되며, 중심과로서 자신진(子申辰)은 3합수국윤하격(三合水局閏下格)이다. 3합 중에 지합을 합병하고, 3합의 수(水)는 일간상신의 축(丑)과 초전 자(子)는 지합하고, 축미(丑未)는 중귀를 제어하여 흉을 해소한다.
    이 과는 도모하는 일마다 모두 목적을 달성한다. 그러나 말전이 공망이니 기회를 잡아야 한다. 주작을 류신으로 할 때는 힘을 얻어 효과가 있다. 
    백법에는 ｢중귀가 모두 나타나도 두려울 것 없다｣했다. 3합․6합이 중복되어 있으면 만사에서 기쁜 일을 만난다. 중전이 일간의 재로 되고 귀(鬼)로 생화한다. 그러므로 분에 넘치는 과욕은 도리어 재물로 인하여 고통을 초래한다. 3합은 맹(孟)․중(仲)․계(季))의 3신으로써 되며 수(水)의 성(性)은 내려가서 자윤(滋潤)한다. 그 형상은 유동하며 내부가 밝고 외부는 어둡다. 위가 아래에 붙으면 길하다.
    소송사는 연달아 일어난다. 음신사(音信事)는 주작이 임하나 발동하기 어렵다. 공명․구직을 정단할 때는 초․말전이 공망하니 년명상신에 진(辰)이 가해지면 길하다. 중귀는 두려우나 도리어 공망을 즐긴다.
    일귀는 순행하여 3전이 합(合)․후(后)․백(白)이고, 야귀는 3전이 사(蛇)․현(玄)․청(靑)이다. 사(蛇)가 화하여 용(龍)으로 되니 장래를 헤아려 원대할 수 있다. 중전의 재는 귀로 되어 차재(借財)나 대금(貸金)은 후난(後難)을 초래하니 꾸거나 보증서지 말아야 한다.`
    ,
    // 6
    `  이 과는 4과에 모두 극이 있다. 하에서 상을 극하는 양비(陽比)인 술묘(戌卯)를 사용하여 초전으로 하며 지일사절과(知一四絶課)이다. 3전 술사자(戌巳子)는 주인격이지만 공망되므로 인모(印模)가 되지 않는다고 하며, 또 술(戌)이 묘(墓)가 되므로 사관(仕官)에는 불리하다.
    일지상신 묘(卯)는 순미(旬尾)의 지지(地支)이다. 지상(支上)에 가해져서 폐구격(閉口格)으로 된다. 병을 앓을 때에는 약이(藥餌) 및 음식을 먹지 못하여 병점에서는 가장 흉하다. 
    백법에 이르기를 ｢피차가 투합되지 않는 것은 지형상(支刑上)에 있다｣고 했다. 중․말전의 공망은 전(傳)으로 돌지 않으므로 초전(初傳)을 생각해야 한다.
    간상신의 자(子)는 병(丙)의 태신(胎神)이다. 인월(寅月)에 정단하면 정처(正妻)가 아닌 이성이 잉태한다. 그러나 공망되므로 귀태(鬼胎)가 아니라면 조산할 우려가 있다. 비전에 이르기를 ｢묘(卯)가 신(申)에 더해지고, 술(戌)이 묘(卯)에 더해지므로 병관(病觀)은 수족의 부자유이거나 손상되는 따위이다. 술(戌)이 묘(卯)에 가해지는 것은 귀과(歸課)라 하여 기다리는 사람은 반드시 돌아온다｣고 하였다. 중․말전의 공망은 무슨 일에서나 시작은 있고 끝이 없다.
    일귀는 순행하여 3전이 사(蛇)․상(常)․합(合)이고, 야귀는 3전이 후(后)․공(空)․사(蛇)이다. 말전에 사귀(蛇鬼)가 가해져도 공망하여 횽해를 받지 않는다. `
    ,
    // 7
    `  이 과는 12천지반이 충위(冲位)에 있어서 반음과(返吟課)이다. 일간일지는 교차육해(交車六害)로 되고 천지가 각각 지합하므로 화평한 가운데 의심이 생긴다. 반음과는 무슨 일이나 반복이 무상하며, 일간상신에 명귀(明鬼)가 있고, 초전(初傳)에는 임(壬)의 둔간귀(遁干鬼)가 있어서 인정을 헤아리기 어렵다. 그러나 일간상신에 귀신이 승하여 내몸을 지키는 구신(救神)으로 되니 모든 옛 일이 끝난다.
    백법에 ｢귀(鬼)에 천을이 승하면 신기격(神祗格)으로 된다｣고 했다. 육해가 서로 따르면 피차간에 의혹이 생긴다. 병(丙)의 일간상신에 해(亥)가 가해지면 덕(德)이 천문에 든다고 하여 시험에서는 좋은 성적으로 입선된다. 반음과는 무슨 일이나 두 사건인데 질병도 두 증세이다. 
    일귀는 순행하여 3전이 용․후․용이고, 야귀는 역행하여 3전이 현․합․현이다.  일귀는 길장이다. 백법의 21법에서는 ｢교차격(交車格)에 는 10격이 있으며, 교차육해격이란 일간일지가 사각(斜角)으로 육해(六害)로 되는 것을 말한다｣고 했다.
    이 격은 처음에는 서로 화평하나 후에는 대개가 그림의 떡으로 돌아간다. 따라서 처음에는 아름다우나 마감이 아름답지 못하다. 또 백법의 천을신기격(天乙神祗格)은 제48법에 해당한다. 이 격은 일간의 귀살이 신(身)에 임하여 천을귀신과 동승(同乘)한다. 귀숭(鬼崇)이므로 간점하지 않는 병을 정단하면 신불(神佛)에 의하여 해를 받는다. 이 과는 적(敵)에 의해 도움을 받는다.`
    ,
    // 8
    `  이 과는 제2과에서 1하가 1상을 극하여 묘술(卯戌)을 초전으로 하니 원수과(元首課)로 된다. 일간일지상신에 각각 묘신이 엎드리고, 일간의 음신은 순미(旬尾)의 묘(卯)이며 일지의 음신은 순수(旬首)의 오(午)이다. 주편격(週遍格)으로 되니 시작은 있어도 끝이 없다. 일간상신의 묘신의 위에 묘가 가하여지고 신(申)에 육합이 승하는 것은 육편격(肉片格)이라 하여 병을 정단하면 사액(死厄)이 있다. 즉 묘(卯)의 관(棺)에 묘신이 임하기 때문이다.
    백법에는 ｢양사(兩蛇)가 묘(墓)를 협(夾)하는 것은 재앙을 면키 어렵다｣고 했다. 일간일지에 각각 묘신이 승하면 혼미정체한다. 양사란 일야(日夜)의 사신(蛇神)이 사(蛇)에 승하며, 지반의 사(巳)는 사신(蛇神)이다. 병을 점단하면 적괴(積塊) 또는 암병으로서 구하기 어렵다.
    년명상신에 해(亥)가 있으면 사(巳)를 충하니 파묘(破墓)라 불러 흉이 경미하다. 
    일귀는 역행하며 3전은 공․후․구이고, 야귀는 3전이 상․합․음이다.`
    ,
    // 9
    `  이 과는 제1과․제4과에 극이 있다. 하에서 상을 극하는 제1과를 초전으로 하고, 2전은 유축사(酉丑巳)로서 중심종혁격(重審從革格)이다.
    일간상신 일귀(日鬼)는 귀신이 승하니, 즉 간귀(干貴)가 신(身)에 임하고 또 재신으로 되어 구재는 먼저 출비하고 후에 소득하며, 대개는 대차(貸借) 금융의 재이다. 유(酉)에는 정신(丁神)이 승하므로 처에 의하여 재동(財動)이 있다. 
    3전이 3합금국인 것은 종혁격인데, 초전이 공망하므로 삼정(三鼎)이 되지 않는다고 부르며 단결을 이루지 못한다. 낡은 것을 고쳐서 새롭게 하여도 역시 완료되지 못한다.
    백법에 이르기를 ｢3합 가운데에 지합이 있으니 만사에서 즐거운 일을 만나게 된다｣고 하였다. 3합 중에 자수(子水)는 귀(鬼)로 되어 상대는 웃음 속에 칼을 품는다. 즉 꿀 속에 비소가 있는 형상이다.
    일귀는 역행하여 3전이 귀(貴)․상(常)․구(勾)이며, 야귀는 3전이 주(朱)․음(陰)․공(空)이다. 말전이 공망 중에 천공(天空)이 앉으니 모든 일에서 공론허사(空論虛詐)뿐이다. 비전(秘傳)에 ｢3전이 유축사(酉丑巳)이면 헌도(獻刀)라 하여 다툼을 정단하면 원근을 불문하고 부상하는 사건이 발생한다｣고 했다.  
    사(巳)에 유(酉)가 가해지면 적사(赤蛇)가 금문(金門)에 든다고 하여 여인은 얼굴에 장애가 있다.`
    ,
    // 10
    `  이 과는 제1과가 신병(申丙)으로 1하가 1상을 극하여 중심원태격(重審元胎格)으로 된다. 말전의 인(寅)은 일간(日干) 병(丙)에서 보아 장생으로 되고, 중전의 해(亥)는귀신이며, 초전의 신(申)은 일간의 재로 된다. 그러나 사신(蛇神)이 승하면 양사협극(兩蛇夾剋)이 된다. 초․중․말이 모두 순생하고 미(未)는 일간을 생한다. 즉 체생하여 사람의 천거를 받고 뭇사람들로부터 원조와 찬사를 받는다.
    일지의 신(申)은 일간상에 더해져서 재(財)가 되니 남이 와서 자기에게 재리를 얻게 한다. 신해(申亥)는 육해(六害)로 불비격(不備格)으로 되니. 재(財)의 일은 물론이고 바른 일에 결함이 있다고 본다. 일지상신이 귀효로 되어 위험한 가운데서 재를 소득한다. 즉 즐거움 중에 근심이 숨어 있다.
   백법에 ｢귀(鬼) 중에 천을이 앉으면 천을신기격(天乙神祗格)으로 된다｣고 했다. 피차에 의심을 사게 되는 것은 육해가 서로 따르기 때문이다. 비전에는 ｢처재(妻財) 중에 사호(蛇虎)를 보면 처가에 들러 상복(喪服)입는 일을 만난다｣고 했다. 인(寅)은 공조(功曹)라 하여 관리인이다. 인(寅)에 사호(蛇虎)가 승하면 가까운 장래에 부모의 신변에 걱정이 초래되거나 상사(喪事)가 거듭하여 발생한다. 초전이 공망하여 체생하니 천거를 받아도 말뿐이며 참된 뜻이 없다.
   일귀는 역행하여 3전이 사(蛇)․음(陰)․백(白)이고, 야귀는 3전이 합(合)․귀(貴)․현(玄)이다. 3전 중에 사호(蛇虎)를 보면 흉사가 많다. 일지가 일간 상에 임하는 것은 췌서격(贅壻格)이라 부르며, 일간을 남편으로 하고 일지는 처로 한다. 자기를 버리고 남의 위세를 따른다. 객(客)이 되어 재를 구하고 내몸을 발재(發財)의 상(象)으로 한다. 전진할 때는 권위가 없고, 물러설 때에는 의지할 곳이 없다. 년명상신에 길장이 앉으면 권세를 얻어 길하게 된다.`
    ,
    // 11
    `  이 과는 제4과의 자술(子戌)이 1하에서 1상을 극하여 초전으로 되니 중심순간전과(重審順間傳課)이다. 초전의 자(子)는 일간의 귀살이지만 미술토(未戌土)가 살을 제어하므로 흉이 없으며, 일간일지상에는 모두 묘신이 엎드렸다. 자인진(子寅辰)은 3양격(三陽格)으로서 공(公)으로 도모하면 이롭다.
    3전이 진간전격(進間傳格)이니 점차로 전진하면 길하다. 말전의 공망은 미 중에 부족함이 있으며 귀(鬼)가 묘(墓)에 앉으면 발병하는 사람이 있다.
    백법에 ｢둔귀에 백호가 승하는 것은 재앙이 있다｣고 했다. 강색귀호(罡塞鬼戶)는 모사에 임한다. 3양격은 바라는 곳에 광명이 있고, 말전의 진(辰)은 공망되어도 술(戌)의 충으로 공망을 풀고 개묘로 되니 처음에는 어렵지만 후에는 쉽다. 일귀는 역행하여 3전이 현(玄)․백(白)․청(靑)이고, 야귀는 3전이 후(后)․현(玄)․백(白)이다.
    귀신이 유(酉)에 임하는 것은 여덕격(茹德格)으로서 군자가 그 덕을 쌓으며, 때를 기다리는 과(課)이다. 3양은 향양격(向陽格)이라 한다. 자(子)는 음이 극하므로 양을 생하여 진(辰)에 이른다. 걸어서 양으로 향하고 사(邪)를 제거하여 바른 길로 향하므로 모든 일에서 광명 길조로 본다.`
    ,
    // 12
    `  이 과는 상하에 극이 없고 일간에서 제3과 상신 유(酉)를 요극하므로 탄사과(彈射課)이다. 3전 유술해(酉戌亥)는 순연여격(順連茹格)이다.
    일간상신의 오(午)는 제왕(帝旺)으로 되고 일지상신 유(酉)는 금(金)에서 보아 제왕으로 된다. 즉 신택(身宅)에 모두 왕기(旺氣)가 더해지고 신택이 모두 왕성하다. 왕(旺)에 있을 때는 맹렬하게 운동하지 않아도 앉아서 복리가 오므로 맹동(猛動)을 삼가야 한다. 지반 앞의 지에 천반지지가 해당하며 나망격(羅網格)으로 옛 것을 지키면 좋다. 초전은 일간의 사(死)에 해당하고 중전의 술(戌)은 묘(墓)로 되며 말전의 해(亥)는 절(絶)로 되니, 병환에서는 가장 꺼린다. 3전이 동묘절(死墓絶)에 해당되면 함부로 진퇴하지 말고 적당히 시기를 노려야 한다. 옛 일에 의하는 것은 어렵지 않다.
    백법에 ｢모사를 이룩한 결과 나망을 만나면 졸책(拙策)으로 된다｣고 하였다. 서로 왕하는 것은 시기를 보아가면서 모사하면 좋은 결과를 얻는다. 비전에는 ｢청룡이 천반에 드는 것은 존장이 와서 간점(看占)을 바란다고 했다. 병정일(丙丁日)에 천반지에서 축(丑)이 자(子)에 가해지는 것은 복태격(腹胎格)이라 이름하며, 온 뜻은 처첩의 임신사이다.
    일귀는 역행하여 3전이 귀․후․음이고, 야귀는 3전이 주․사․귀이다. `
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
