
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,8,1,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,9,12,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지      않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,10,11,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,10,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,9,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,1,8,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`신뢰`,2,7,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,3,6,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,5,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,5,4,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,3,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,2,`특별있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,`손해사有`,`부부다툼`],
    [`남편`,`남편 관련사`,`大`,`존재기`,`중중희愛`,`동요개변`],
    [`녹득영창(祿得榮昌)`,`식녹이 영창하게 되는 상`,`大`,``,``,``],
    [`가실불화(家室不和)`,`비관이 극에다한 후 경사을 맞는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극남`,`남자 갈등사`,`大`,`5`,`불리시험`,`손해가출`],
    [`자녀`,`자녀관련사`,`小`,`배양기`,`구설무방`,`중중희愛`],
    [`이음불공(利陰不公)`,`일을 음적으로 하면 이익이고 공적이면 불이익이 되는 상`,`小`,``,`부부불화`,``],
    [`목명고시(目暝考試)`,`고시(시험)는 불리하고 구설근심은 무방한 상`,`大`,``,``,``],
    [`후원`,`후원 조력 부모 관련사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`4`,`급속사有`,`호가호위`],
    [`매사 영속성없음`,`후원조력문서부친사는 선천적 기복재해를 겪음`,`小`,`개시기`,`송쟁상해`,`狐假虎威`],
    [`여득부족(餘得不足)`,`더 이상의 이익은 불가함으로 바라지 말라-는 상`,`大`,``,`환성경사`,`가실불화`],
    [`엄목지상(掩目之相)`,`눈이 가려진 격의 상`,`小`,``,`(불성)`,``],
    [`결속`,`결속하여 음3월 동지사를 크게 이루나 財妻 재해 발생함`,`大`,``,``,`고위안좌`],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`3`,`능욕박해`,`多衆이익`],
    [`매사 영속성없음`,`후원조력문서모친사는 선천적 기복재해를 겪음`,`小`,`학습기`,``,`강구(불성)`],
    [`탐즉생화(貪卽生禍)`,`이익을 지나치게 탐하면 禍를 부르는 상`,`大`,``,``,`색정사有`],
    [`재난전소(災難全燒)`,`재난재해가 물러간 상`,`小`,``,``,`파재극처`],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`2`,`부부불화`,`공허`],
    [`자식`,`자식 관련사`,`小`,`면모기`,`선택기로`,`舊事결절`],
    [`상유항연(尙有恒戀)`,`남녀 간 화합이 항상 잘 이루어지는 상`,`小`,``,`직위불안`,`능욕박해`],
    [`부처반목(夫妻反目)`,`부부 반목대립불화의 상`,`大`,``,`掩目`,``],
    [`명예`,`수상(受賞)사`,`大`,``,``,``],
    [`직녹`,`직위녹위사`,`大`,`1`,`외교막힘`,`면모훼손`],
    [`자산`,`자산 관련사`,`小`,`절정기`,`투서불용`,`비용과출`],
    [`관의상기(官宜常忌)`,`관직자는 좋고 일반인은 크게 나쁜 상`,`小`,``,`失財사`,`부부반목`],
    [`도적악의(盜賊惡意)`,`도적이 쫒기다가 되돌아 봄으로 공격하지 말라-는 상`,`小`,``,``,``],
    [`형재`,`형제 관련사`,`大`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`12`,`색정사有`,`가정막힘`],
    [`색정`,`외남 색정사 有`,`小`,`과시기`,`비용과출`,`반목대립`],
    [`반도조신(反逃助神)`,`일단 도피하면 神의 도움을 얻게 되는 상`,`小`,``,`재난전소`,`관재형흉`],
    [`관재형흉(官災刑凶)`,`매우 무거운 관재형벌이 우려되는 상`,`小`,``,``,`투서불용`],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극남`,`남자 갈등사`,`大`,`11`,`침체`,`신음탄식`],
    [`결속`,`개신결속하여 대재를 획득하나 부모災害 발생함`,`小`,`쇠퇴기`,`간사은닉`,`(病 事미수)`],
    [`전묘입묘(轉墓入墓)`,`墓가 墓로 이어지니 비읍불행사나 침체사가 우려되는 상`,`小`,``,``,``],
    [`간사은닉(奸邪隱匿)`,`연정 상대를 숨기고 있는 상-관계불통-가택이사에도 통함`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,`애증화합`,``],
    [`사업`,`사업 관련사`,`小`,`10`,`파재극처`,`출행이동`],
    [`처`,`처 관련사`,`大`,`침해기`,`손해실종`,`간사은닉`],
    [`위명암조(爲明暗助)`,`일을 밝게 하면 남모르는 도움을 받게 되는 상`,`小`,``,`모두재난`,`가택이사`],
    [`총시성재(總是成災)`,`이리저리 봐도 재난 뿐인 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`9`,`개신혁신`,`손실사有`],
    [`여인`,`여인 관련사`,`大`,`소외기`,`사송계류`,`혼인단장`],
    [`불가동용(不可動用)`,`적극적으로 움직이는 것은 해롭다-는 상`,`小`,``,``,``],
    [`혼인미만(婚姻美滿)`,`혼인하기 위해 머리와 용모를 다듬는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`8`,`身物受損`,`외화내허`],
    [`자식`,`자식 관련사`,`小`,`침체기`,`상대공격`,`혼인단장`],
    [`동변망도(動變網刀)`,`크게 움직이다가는 큰 형벌을 받게 되는 상`,`小`,``,`되려불리`,`왕래사송`],
    [`혼인미만(婚姻美滿)`,`혼인을 앞두고 몸을 단장하는 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,`동요분산`,`급속사有`],
    [`남편`,`남편 관련사`,`大`,`단절기`,`원행이동`,`앙화소멸`],
    [`이무성취(而無成就)`,`성취되는 것이 아무것도 없다-는 상`,`大`,``,`복통수욕`,``],
    [`복통수욕(腹痛受辱)`,`배가 아파 망신을 당하는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`관사 직업 남편 사로 불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`직업직위불안남편갈등여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,`명예자산직위사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,`형제친구동료조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`직업직위불안남자갈등사여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`금전사업처조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`금전사업여인조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`관사 직업 남편 사로 불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 만저 나서서 정당하게 속전속결하면 유리함`,0,0,`명분이 있으므로 정정당당하게 앞서 일을 속결해야만 유리하고 좌고우면하거나 뒤로 미루면 불리해지는 人`,0,0,`1남2녀 삼각관계 有**일이 거꾸로 진행되나 계속하게 됨**1949,50,51,61,62,63,73,74,75,85,86,87,97,98,99년 출생자는 진행하여 길함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 자세로 일을 지체시키거나 뒤로 미뤄야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순한 처신으로 연기시키면 반드시 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄 진행되어 물러서는 것이 좋음**내방자는 악의가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 공격의 뜻이 없고 피해도 없으며 있다해도 가벼움`,0,0,`옳지않은 일이 밝혀져 천둥에 놀란 듯한 심중이나 조용히 근신하면 두려움이 자연히 사라지는 人`,0,0,`풍운불측의 상**간사은닉건이 발생함**큰일은 불리하고 작은 일은 유리함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 자세로 일을 지체시키거나 뒤로 미뤄야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순한 처신으로 연기시키면 반드시 결과가 유리해지는 人`,0,0,`결속단합하여 동지사를 크게 이루나 외화내허함**재물 처첩 손모 재해 우려됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`중상모략적 사안으로 동요불안하여 선택의 기로에 서 있으나 가장 친한 자나 제일 가가이 사는 자와 의논하면 해결되는 人`,0,0,`정대한 옛일들이 끓어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집한 상으로 화가 밖에서 오니 신중해야 함`,0,0,`모든 일이 동요불안하고 반복무상하며 부부남녀 상쟁으로 분산사가 있는 人`,0,0,`풍운불측의 상**간사은닉건이 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`중상모략적 사안으로 동요불안하여 선택의 기로에 서 있으나 가장 친한 자나 제일 가가이 사는 자와 의논하면 해결되는 人`,0,0,`전화위복사 有**산으로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 자세로 일을 지체시키거나 뒤로 미뤄야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순한 처신으로 연기시키면 반드시 결과가 유리해지는 人`,0,0,`여러 사안을 일관시키는 개신개혁결속사가 있음**부모 재해 발생 우려`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 자세로 일을 지체시키거나 뒤로 미뤄야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 유순한 처신으로 연기시키면 반드시 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**병사자 우려됨**서리내린 다리를 건너는 격임**가택이사 함**관직자는 이동함 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威 勢)세**만약 타인과 다툰다면 공격하는 상대는 적중되지 않으므로 되려 불리함`,0,0,`호가호위세로 상대를 공격을 하나 실효가 없으므로 공격을 중지해야만 되는 人`,0,0,`1녀2남 삼각관계 有**금전 여인사의 진행은 좋음-단 길게 가면 침체됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 있어도 움직이지는 않음`,0,0,`옛것(익숙한) 지키면서 새 기회를 노리며 신음하는 人-고시 준비생에 해당하는 경우가 많음`,0,0,`신구사가 있음**음3월 일반인은 관재소송사가 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`충성-무력`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면-무력`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사-후회사 겹침`,`기사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,1,4,`음8월`,2,2,4,`음2월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음7월`,3,1,4,`음1월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,1,1,2,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음3월`,2,1,3,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,1,3,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월-연기 또는 파혼우려`,2,1,3,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음10월`,3,2,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음9월`,3,2,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,1,3,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음9월`,2,3,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔하며 어무를 크게 실패함-단 1949,50,51,61,62,63,73,74,75,85,86,87,97,98,99년 출생자는 업적을 크게 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무는 초 중반이 허무하고 종반에 이르러서야 내부 재정을 확보함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폴력적이고 조급하나 업무는 가까스로 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 음1월에 결속단합으로 동지사를 크게 이루나 외화내허하여 결국 재정고갈을 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 도움을 받으나 업무는 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무 내내 동요불안분산사로 면모를 크게 추락시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 중반에 이르러 서야 실적을 이루고 종반에는 공허함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하고 여러 일을 일관시키는 개신개혁을 통해 재정의 큰 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 초반의 실적 이외는 순준 저하가 뚜렷함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 도움을 얻으나 초반을 넘기고나서는 실적이 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻어 재정을 확충함-단 방침을 길게 하면 종반이 침체함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무 내내 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`권리양도`,`退背`,`근`,`去`,`무난`,`정서`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`이행`,`본인무력`,`退背`,`  근`,`  去`,`세입조력`,`북서`,``,`남남서`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`기세무력`,`이행`,`본인사안`,`退背`,`원근왕래`,`  去`,`주인무력`,`서남`,``,`서남`],
    [`10.11`,``,`끌면제압`,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`사안무력`,`退背`,`  근`,`  去`,`주인무력`,`북북동`,`정서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인반대`,`이행`,`선난후이`,`退背`,`  근`,`  去`,`세입조력`,`북서`,`북서`,``],
    [`10.11`,` 多集`,`내부제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선난후이`,`退背`,`  원`,`  去`,`주인가해`,`북북서`,`북북서`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`이행`,`선난후난`,`退背`,`  근`,`  去`,`주인가해`,`정북`,`동남`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,`본인제압`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대없음`,`이행`,`선난후이`,`進向`,`  원`,`  留`,`세입조력`,`북북동`,`북북동`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인동조`,`이행`,`선이후이`,`進向`,`  원`,`  留`,`세입가해`,`남남동`,`남남동`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`이행`,`선攻후費`,`退背`,`  원`,`  去`,`세입가해`,`정서`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대없음`,`이행`,`선費후費`,`進向`,`  원`,`  留`,`세입조력`,`동남`,``,`정서`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,`주인이사`,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후이`,`進向`,` 근`,`  留`,`무난`,`남남동`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`목기부정`,`목물반입`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`목기부정`,`목물반입`,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`자살귀`,`水투신`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`자살귀`,`水투신`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`자상귀`,`水투신`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`자살귀`,`水투신`,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`변사귀`,`객사`,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`음귀`,`묘지탈`,0,0,`묘좌`,`정서`,`없음`],
    [``,``,``,0,0,`묘향`,`정동`,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,`화원`,``],
    [``,``,``,0,0,`묘속`,`石`,`水`,``],
    [`가정`,``,``,0,0,`육친`,`3대조모`,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사출행이동사-내사급속사`,`재난극복-이익진취사`,`부부남녀쟁투사`,`눈이 가려진 상`,``,`불리 고시사-구설사`,`매사 허성불성-세속허사자**외사급속사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음**내녀 색정사有**부부불화사`,`종교진출하면자립함`,``,`중중희愛사`,`명예직녹자산사**외교막힘`,`길사 도래함-복통受辱사`,`외남 색정사**내부가정막힘`,`앙禍소멸사`,`상하화합사`,`미초희경사-술잔 내려받는 경사-이동사`,`외사애증화합송쟁파재실종상해사**내사출행이동사`,``,`남녀직원공모사有**음사폐호-가택안녕`,`도적절족失세사`,``,`탄식신음사-병 또는 모사미수사 이유`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`관재`,``,`빈궁**관재`,``,`전도**빈궁`,`환성경사-부족`,``,`내부가정파재극처사`,`빈궁`,`전화위복`,`전도`,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의`,`빈궁**병재`,``,`전도`,`재액해소`,`빈궁**병재`,`재액해소`,`빈궁`,`驚懼침체사`,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,``,`탄식신음사-병 또는 모사미수사 이유`,`외사출행이동사-내사급속사`,`재난극복-이익진취사`,`부부남녀쟁투사`,`눈이 가려진 상`,``,`불리 고시사-구설사`,`매사 영속성없음**외사급속사`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사有**부부불화사`,`종교진출하면자립함`,``,`중중희愛사`,`명예직녹자산사**외교막힘`,`길사 도래함-복통受辱사`,`외남 색정사**내부가정막힘`,`앙禍소멸사`,`상하화합사`,`미초희경사-술잔내려받는 경사-이동사`,`외사애증화합송쟁파재실종상해**내사출행이동사`,``,`음사폐호-가택안녕`,`도적절족失세사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전화위복`,`전도**관재`,``,`전도**빈궁**관재`,``,`빈궁`,`환성경사-부족`,``,`내부가정파재극처사`,`빈궁`,`전화위복`,``,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의`,`관재**병재`,``,``,``,`빈궁**병재`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`음사폐호-가택안녕`,`도적절족失세사`,``,`탄식신음사-병 또는 모시미수사 이유`,`외사출행이동사-내사급속사`,`재난극복-이익진취사`,`부부남녀쟁투사`,`눈이 가려진 상`,``,`불리 고시사-구설사`,`매사 영속성없음**외사급속사`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사有**부부불화사`,`종교진출하면자립함`,``,`중중희愛사`,`명예직녹자산사**외교막힘`,`길사 도래함-복통受辱사`,`외남 색정사**내부가정막힘`,`앙禍소멸사`,`상하화합사`,`미초희경사-술잔내려받는 경사-이동사`,`외사애증화합송쟁파재실종상해**내사출행이동사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`재액해소`,``,`전화위복`,`관재`,``,`관재**빈궁`,`재액해소`,`빈궁`,`환성경사-부족`,``,`내부가정파재극처사`,`빈궁`,`재액해소**전화위복`,``,`음4,5월 화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**관재**병재`,`재액해소`,``,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`실업자 상으로 한가함`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`간사은닉-관계불통-가택이사`,``,`재난전消사`,`매사 위역사`,`투서헌책 불용사-금전실재사`,`외사출행이동사-내사급속사`,`부부반목사`,`부부남녀쟁투사`,`남 모르는 능욕박해사`,``,`매사 영속성없음**외사급속사`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사有**폭객기능사有`,`종교진출하면자립함`,`관재형책 -매우 흉함`,`매사 위역사`,`명예직녹자산사**외교막힘`,`미초희경사-권리受여경사`,`외남 색정사**내부가정막힘`,`도적악의-공격말 것`,`상하화힙사`,`觀書-뭉화풍아-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`빈궁`,``,`전도**빈궁**관재`,`전화위복`,``,`전도**관재`,``,`빈궁`,`고위안좌-부족`,``,`빈궁`,`내부가정파재극처사`,`빈궁**병재`,`전화위복`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`상하화합사`,`觀書-문화풍아-이동사`,`실업자 상으로 한가함`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`간사은닉-관계불통-가택이사`,``,`재난전消사`,`매사 위역사`,`투서헌책 불용사-금전실재사`,`외사출행이동사-내사급속사`,`부부반목사`,`부부남녀쟁투사`,`남 모르는 능욕박해사`,``,`매사 영속성없음**외사급속사`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사有**폭객기능사有`,`종교진출하면자립함`,`관재형책-매우 흉함`,`매사 위역사`,`명예직녹자산사**외교막힘`,`미초희경사-권리受여경사`,`외남 색정사**내부가정막힘`,`도적악의-공격말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,`재액해소`,``,``,`관재`,``,`빈궁`,`재액해소`,`빈궁**관재`,`전화위복`,``,`관재`,`빈궁`,`재액해소`,`고위안조-부족`,``,`빈궁`,`내부가정파재극처사`,`빈궁**병재`,`재액해소**전화위복`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`외남 색정사**내부가정막힘`,`도적악의-공격말 것`,`상하화합사`,``,`觀書-문화풍아-이동사**실업자 상으로 한가함`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`간사은닉-관계불통-가택이사`,``,`재난전消사`,`매사 위역사`,`투서헌책불용사-금전실재사`,`외사출행이동사-내사급속사`,`부부반목사`,`부부남녀쟁투사`,`남 모르는 능욕박해사`,``,`매사 영속성없음**외사급속사`,`종교진출하면자립함**고위안좌-부족`,`매사 영속성없음**내녀 색정사有**폭객기능사有`,`종교진출하면자립함**내부가정파재극처사`,`관재형책-매우 흉함`,`매사 위역사`,`명예직녹자산사**외교막힘`,`미초희경사-권리受여경사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,`동요붕란분산사`,`빈궁`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`빈궁**관재**재액해소`,`동요불안분산사**전화위복`,`관재`,`동요불안분산사**재액해소`,`빈궁`,`동요불안분산사`,``,`동요불안분산사`,`빈궁`,`동요불안분산사`,`빈궁**병재`,`동요불안분산사**전화위복`,`동요분산사**음4,5워화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`명예직녹자산사**외교막힘`,`미초희경사-권리受여경사`,`외남 색정사**내부가정막힘`,`도적악의-공격말 것`,`상하화합사`,``,`觀書-문화풍아-이동사**실업저 상으로 한가함`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`간사은닉-관계불통-가택이사`,``,`재난전消사`,`매사위역사`,`투서헌책불용사-금전실재사`,`외사출행이동사-내사급속사`,`부부반목사`,`부부남녀쟁투사`,`남 모르는 능욕박해사`,``,`매사 영속성없음**외사급속사`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사有**폭객기능사有`,`종교진출하면자립함`,`관재형책-매우 흉함`,`매사 위역사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,`빈궁`,``,`관재`,``,``,`빈궁`,``,`전도**빈궁**관재`,`전화위복`,`전도**관재`,``,`빈궁`,``,`고위안좌-부족`,``,`빈궁**관재`,`내부가정파재극처사`,`관재**빈궁**병재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관직영전도약-상인 災흉`,`중중희愛사`,`명예직녹자산사**외교막힘`,``,`合室성취사`,`외남 색정사**내부가정막힘`,`상하화합사`,`불의투서헌책사-금전실재사`,`모두 재난 뿐임`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`간사은닉-관계불통-가택이사`,``,`탄식신음사-병 또는 모사미수사 이유`,``,`도적의혹근심사`,`외사출행이동사-내사급속사`,`도적의혹-실재근심사`,`부부남녀쟁투사`,`승진영전사`,``,`매사 영속성없음*외사급속사`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사有**폭객기능사有`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,``,`빈궁`,``,`빈궁`,``,``,``,``,`빈궁**관재`,`전화위복`,``,`빈궁**관재`,``,``,`관직자 샐살대권-부족-庶관재`,`전도**빈궁`,`전도**빈궁`,`내부가정파재극처사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`매사 영속성없음**내녀 색정사有**폭객기능사有`,`종교진출하면자립함`,`관직영전도약-상인 災흉`,`중중희愛사`,`명예직녹자산사**외교막힘`,``,`合室성취사**길사 도래함-복통수욕사`,`외남 색정사**내부가정막힘`,`상하화합사`,`불의투서헌책사-금전실재사`,`모두 재난 뿐임`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`간사은닉-관계불통-가택이사`,``,`탄식신음사-병 또는 모사미수사 이유`,``,`도적의혹근심사`,`외사출행이동사-내사급속사`,`도적의혹-실재근심사`,`부부남녀쟁투사`,`승진영전사`,``,`매사 영속성없음**외사급속사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,`내부가정파재극처사**전화위복`,``,``,`빈궁`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁**병재`,``,``,``,``,``,`빈궁**관재`,`전화위복`,``,`빈궁**관재`,``,``,`관직자 샐살대권-부족庶관재`,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사 영속성없음**외사급속사**도적 못잡음`,`종교진출하면자립함`,`매사 영속성없음**내녀 색정사有`,`종교진출하면 자립함`,``,`관재형책 대흉함`,`명예직녹자산사**매사 위역사**외교막힘`,``,`외남 색정사**내부가정막힘**길사 도래함-복통受辱사`,`身物수손사`,`상하화합사`,`왕래사송계류사-이동사`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`혼인단장사`,``,`관재병질사`,``,`재난전소사`,`외사출행이동사-내사급속사`,`재난극복-이익진취사`,`동지함의사-안방을 지킴으로 가나있거나 좋은 상`,`부부남녀쟁투사`,``,`존비상몽-신분나이 차이 음란사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재물유실사`,`전도**빈궁`,`내부가정파재극처사**전화위복`,`전도`,``,`빈궁**병재`,`빈궁`,`음4,5월 화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,``,`빈궁`,``,``,`빈궁`,``,`빈궁`,``,`전화위복`,`관재`,``,`관재`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`존비상몽사-신분나이 차이 음란사`,`매사 영속성없음**외사급속사**도적 못잡음`,`종교진출하면 자립함`,`매사 허성불성-세속허사자**내녀 색정사有`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,``,`관재형책 대흉함`,`명예직녹자산사**매사 위역사**외교막힘`,``,`외남 색정사**내부가정막힘**길사도래함-복통受辱사`,`身物수손사`,`상하화합사`,`왕래사송계류사-이동사`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`혼인단장사`,``,`관재병질사`,``,`재난전소사`,`외사출행이동사-내사급속사`,`재난극복-이익진취사`,`동지함의사--안방을 지킴으로 가나 있거나 좋은 상`,`부부남녀쟁투사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`재물유실사`,`전도**빈궁`,`내부가정파재극처사*전화위복`,`전도`,``,`빈궁**병재`,`빈궁`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,``,``,`빈궁`,``,`빈궁`,``,`폐한인*전화위복`,`관재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`부부남녀쟁투사`,`눈이 가려진 상`,``,`불리 고시사-구설사`,`매사 허성불성-세속허사자**외사급속사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자**내녀 색정사有`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,``,`중중희愛사`,`며예직녹자산사**외교막힘`,``,`외남 색정사**내부가정막힘`,`길사 도래함-복통受辱사**앙禍소멸사`,`상하화합사`,`미초희경사-술잔내려받는 경사-이동사`,`외사애증화합송쟁파재실종상해**내사출행이동사`,`도적절족失세사`,`음사폐호-가택안녕`,`관재병질사`,``,`탄식신음사-병 또는 모사미수사 이유`,`외사출행이동사-내사급속사`,`재난극복-이익진취사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**관재`,``,`빈궁`,`환성경사-부족-부부불화사`,``,`내부가정파재극처사**전화위복`,`빈궁`,``,``,`빈궁`,`음4,5월화재주의`,`빈궁**병재`,`음4,5월화재주의`,``,``,`전도**병재`,``,`전도**빈궁`,``,``,``,`재액해소**전화위복`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 재산에 송사가 붙었네?       갈수록 나빠질 운세야!      가정에침입한부정을         해소시키는게 길을여는      방법이네!

  ⊖가택상가부정이 들었어!
  `,`■ 일은 막혔는데
  ⊕속기만하고, 
  ⊖부부불화만 있으니 가택권    리가 넘어가지! 一男 二女    삼각관계도 벌어져 있고… 
    비관 후에 운이 열리겠어!
  
  ⊕⊖신변목기부정이 침범했어!
  `,`■ 색정사가 있겠어! 
     지금 모두 헛소리 뿐이네!
  
  ⊕변사귀,가택상가부정 
  ⊖음귀 가택목기부정부터      해소시켜야 능욕이나 구설을    면하겠어!
  `,`■집안에 죄인을 숨겼네 !
    ⊕부부불화, 
    ⊖소아근심으로 
      놀라고 있겠어!
    ⊕색정사 때문이네? 
  ⊕자살귀, 
  ⊖도로귀를 없애야만 구설수가    예방 될거야!
  `,`■다인 다사건을 하나로 묶    겠는데 겉은 화려해도 속      은 비었겠어!
  
  ⊖여자가 오히려 남자를 
    유혹해 색정을 버릴고 있네? 
    기도ㆍ치성을 올리면 아주    좋아질거야!
  `,`■ 지금까지의 일과인연이     다 끝나는 운세네?
     동료간의 문제로 불안하     겠어!  기로에 섰어!
     근친자 1인으로 선택하고
  ⊕자살귀, 
  ⊖도로귀를 해소시키면 전화    위복할거야!
  ⊖신변음사부정이 침범했네?
  `,`■ 색정불화네 !부부가 똑같이     바람을 피우니 사람이고     집이고 온통 요동을 치지!
     인연이 끝나게 생겼어! 
    집안에 죄인을 감추고 있겠어!
  ⊕자살․변사귀
  ⊖여귀․신부정귀를 잡아야 해!
  `,`■병도 두렵고 관재도 두려운    운세네? 두가지 일로 불안    하고 선택의 기로에 섰어!
  ⊕변사귀, 
  ⊖신부정귀를 해소시켜야 
    이름 바꿔 끝을 보지!
  
  ⊕⊖신변음사부정이침범했네!
  `,`■돈과 여자가 여럿이 둘러쌌네?
     확 바꿔 하나로 묶겠어 ! 
     그런데 집에 든 부정부터     해소시켜야 만이 큰 돈을
     거머쥐게 생겼어! 
  ⊕가택수망노부, 
  ⊖가택음사부정이네!
  `,`■ 가족이 흩어지게 생겼네?     부부가 딴 마음이야!
     의심ㆍ질투가 끼었어!
  ⊕가택음귀, 
  ⊖가택신부정귀를 달래야만     길이 훤히 열리겠어!
  
  ⊕신변음사부정이 침범했네?
  `,`■ 사람도 집도 이동수네?
     돈ㆍ여자로 전전긍긍이겠어!
  
  ⊕수귀, 가택음사부정이 듬. ⊖여귀를 잡아줘야 늦더라도    좋아지겠네!
  `,`■ 변동수가 있네?
     一女二男의 삼각관계가      있네… 여자 하나에 두       남자가 붙어있어! 
      내 뜻은 적중이 안되고       서리 내린 다리를 조심       조심 걸어가는 격이야!`];
  const YOGYEOL1 = [`■ 녹ㆍ재ㆍ장생이었으나 낮정단은 모두 성사되기 어렵다. 처음에는 꽃과 비단 같아도 뒤에가서는 부평초 같으리라.
  `,`■ 빈 것을 밟고 가니 나를 돕는자가 없다.
  앞으로만 계속 나아가야만 식록이 오랫동안 좋겠다.
  `,`■ 돕던 사람이 망신살로 변하여 서로 해로운 사이가 되고 밤정단은 귀인에게 재물을 받고 낮정단은 귀인의 도움이 별로 없겠다.
  `,`■ 사과가 모두 비었으니 도와줄 것 같아도 도와주지 않는다. 
  멀리서 화살을 쏘았으나 호악간에 허사이다.
  `,`■ 먼저 죽었다가 나중에 살아나니 매우 기특한 일이 있으리라.
  뜻을 얻어 무르익은 곳에 팔을 내밀고 씩씩하게 걸으리라.
  `,`■ 사람이나 가정이나 낮정단은 재앙이 생긴다.
  귀인에게 부탁해봐야 헛수고이고 끼리끼리 사귀는 것이 더욱 좋으리라.
  `,`■ 지키든 움직이든 관살을 만나고 서로 사귀는 일은 좋지 않다.
  살이 중간에 있어 공직자는 좋으리라.
  `,`■ 뱀 두 마리가 굴 하나를 두고 싸우니 질병이나 다툼이 생긴다. 
  관살이 집에 있으니 대들보에 좀이 슨 것 같도다.
  `,`■ 두 귀인은 도우려 하지 않고 밤정단은 약간의 재수는 있겠으나 일은 많이 깨지고 집에 먼지가 일어나리라.
  `,`■ 낮정단은 귀인이 관살이 되고  밤정단은 귀인이 재물을 도와준다.
  재물과 역마가 과전에 있으니 나를 돕는 자가 많게 된다.
  `,`■ 나와 집에 모두 역마가 올랐으니 주인과 손님은 서로 환영하나 귀살이 병자를 불러대고 움직이든 쉬든 일을 이루지 못한다.
  `,`■ 나와 너는 합하여 돈도 딴다. 처첩은 임신을 하고 음식으로 생긴 병을 치료한다. 위장을 수술하는 것은 좋게 된다.`]
  const SIBO1 = [`■직ㆍ녹에 소송이 붙었다.
  財의 문제이다.
  부모의 재해가 기한다.
  처음은 吉한듯 하나 끝에 가서 나빠진다. 일을 처음에 서둘러 마치는 것이 이익이다. 
  움직이면 막히고 조용하면 길이 된다. 복역ㆍ구금자는 석방ㆍ출옥하게 된다.
  `,`■금전의 소모가 크다.
  가택의 권리가 넘어간다.
  부모의재해가 기한다.
  길흉이 모두 이루어지지 않는다. 일에 지장과 차질이 많다. 종래의 사안과 반대되는 동정이 기한다. 
  비관이 극도에 달한 뒤에 경사가 온다.
  `,`■
  부모의 재해가 기한다.
  ⊕병으로 가택을 옮기는 일이 있다. 문장사ㆍ관사는 흉조이고, 財는 후하다. 색정사가 있고, 음모가 좋고 공공사는 불리하다. 
  공론ㆍ공허가 된다. 
  밝지 못한 상이다.
  `,`■사업ㆍ후원사는 모두
  공론ㆍ공허일 뿐이다.
  부모의 재해가 기한다.
  집안에 악인이 숨는다.
  財는 충분하지는 않으나 吉하다. 색정사로 구설을 맞으며 모두 내부에서 일어나게 된다.
  正月 임신사가 있다.
  `,`■저주사가 있다.
  가택의 손상이 있다.
  후원사는 기대가 안되고 금전과 여자가 크게 손모되거나 갈등이 크게 된다.
  
  ⊖여자가 남자를 유혹한다. 
  기도하면 凶이 구해진다.
  다인 다사건이다.
  `,`■자충수적 우환이 있다.
  부모의 재해가 기한다.
  화합중에 서로 다툰다.
  근ㆍ친자로 결정하고 하나로 전일시켜야 길하다. 옛일, 인연들이 단절된다. 화협되지 못한다.
  正月 임신사가 있다.
  `,`■신변사는 ⊕저주구설
  ⊖신불진노를 받고,
  正月 임신사가 있다.
  가내 우환이 있고 가장을 속이며 가인이 원한을 품고 가해하려 한다.
  가정 남녀상쟁으로 반복왕래가 무상하다.
  서로 사정을 품었다.
  배반당한 입장이다.
  `,`■가정에 ⊕구설
  ⊖신불진노가 있어 
  가내 우환이 있고 가장을
  속이며 가인이 원한을 품고 가해하려 한다.
  시작은 있어도 끝이 없다. 안되는 일은 두세차례 계속 교섭하면 된다.부모의재해가 기한다.
  고사ㆍ치성이 좋다.
  `,`■가정에 부정이 들었다.
  회사, 또는 집단의
  財를 얻는다.
  지금까지의 일을 개신ㆍ개혁시키려 한다. 
  구개신취사이다. 
  제귀시키면 上人 신뢰를 얻지 못하고 의심받으나 타인의 천거로 길하게 된다.
  다인 다사건이다.
  `,`■가족 이산사가 발생한다.
  財는 大利하다.
  여러 사람의 후원은 참된 뜻이 없으므로 기대가 안된다. 또 교섭사도 불길하다. 
  가정ㆍ여자로 의심ㆍ질투심이 있다.
  부모의 재해가 기한다.
  `,`■인ㆍ택 모두 이동한다.
  피차가 서로 화합하고 손님을 집안에 초청하는 상이다. 財로 움직이며 전전긍긍한다. 
  모든 일이 자유스럽지 못하다.
  正月 임신사가 있다.
  `,`■正月 임신사가 있다.
  집안 어른에게 병, 또는 재화가 기하게 된다.
  금전과 여자 문제로 맹동하게 되면 관재에 걸린다. 모략을 써도 졸책이 된다. 財ㆍ女로 전전긍긍하는 입장이다.`]
  const SIBO2_1 = [`■녹ㆍ직.신변외소송사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■사업ㆍ후원가택파재사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액 예방이 필요하다
  `,`■자손ㆍ박관가정퇴폐사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  색정음사가 있고 순역이 혼란하다.
  `,`■근친가택상쟁사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다. 동기가 발동한다.
  허언 무실사가 있다.
  `,`■자손ㆍ박관갈등사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다. 불리하다. 재·녀상실 예방이 필요하다.
  `,`■근ㆍ친.가택상쟁사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  가정이 동요한다.
  `,`■근ㆍ친.가정상쟁사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■자손ㆍ박관가정불안사이다.
  내방조짐 상의 :  
  상복·분쟁사가있다.
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  `,`■財ㆍ女.구개신취사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  부모 재액 예방이 필요하다.
  `,`■財ㆍ女.신변변동사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  은둔ㆍ도망ㆍ원행에 이롭다.재․녀 길함
  `,`■財ㆍ女.신변변동사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.
  은둔ㆍ도망ㆍ원행에 이롭다.색정의 상이다
  `,`■財ㆍ女.신변변동사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  은둔ㆍ도망ㆍ원행에 이롭다.이전 이사한다`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/043/row-1-column-1.png`,
    `/static/images/sagwa/043/row-1-column-2.png`,
    `/static/images/sagwa/043/row-1-column-3.png`,
    `/static/images/sagwa/043/row-1-column-4.png`,
    `/static/images/sagwa/043/row-1-column-5.png`,
    `/static/images/sagwa/043/row-1-column-6.png`,
    `/static/images/sagwa/043/row-1-column-7.png`,
    `/static/images/sagwa/043/row-1-column-8.png`,
    `/static/images/sagwa/043/row-1-column-9.png`,
    `/static/images/sagwa/043/row-1-column-10.png`,
    `/static/images/sagwa/043/row-1-column-11.png`,
    `/static/images/sagwa/043/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 12신이 본궁에 엎드려 움직이지 않고 4과에 상하의 극이 없으므로 양일에는 일간상신을 사용하여 초전으로 하니 복음원태과(伏吟元胎課)로 된다.
    일간에서 사(巳)는 건록으로 되고 중전은 신(申)의 재이며, 말전의 인(寅)은 장생공망(長生空亡)한다. 처음에는 길하고 마지막에는 끝을 맺지 못한다. 모든 일은 속히 끝맺는 것이 유리하다. 
    년명상신 인(寅)의 공망은 녹마(祿馬)․인수(印綬)․장생으로 되어 만사는 양호하게 진전된다. 사(巳)는 파쇄살(破碎殺)이고 일지의 음양 신이 모두 양인(羊刃)에 승하여 모두 비겁(比劫)으로 되니 재를 겁탈하여 재로 인한 분쟁이 일어난다.
    복음은 엎드려 아직 발생되지 않은 형상이며 움직일 때는 정체되고 조용할 때는 길조를 얻는다. 
    비전에는 ｢사(巳)에 구진(勾陳)이 승하면 관신(管神)이라 부르는데 복역(服役) 또는 구금된 자는 석방되거나 출옥한다. 덕신이 지키는 곳은 경사가 겹친다｣고 하였다.
    일귀는 역행하며 3전은 구(勾)․사(蛇)․백(白)이며, 야귀는 순행하며 3전이 공(空)․현(玄)․합(合)이다.`
    ,
    // 2
    `  이 과는 4과 중에서 제2과 묘진(卯辰)이 1상에서 1하를 극하여 초전으로 되니 원수퇴여불비과(元首退茹不備課)이다. 3전은 생기라 하나 개공(皆空)으로 되어, 생하면서 생하지 않으니 길흉이 모두 이루어지지 않는다. 
    일지상신은 일록으로 되어 번영한다. 괴호(怪戶)가 천문(天門)을 건너는 격으로서 불비로 되니 사물에 조격이 많다.
    일간상신에 천강이 승하는 것은 종래와 반하는 동정이 있다.
    백법에 ｢일지상에 녹신을 보면 권섭부정(權攝不正)한 일이 있다｣고 하였다. 
    답배(踏背) 공망은 진용(進用)에 길하다. 
    비전에는 ｢인묘축(寅卯丑)은 연방회린(聯芳悔吝)이라 불러 비관(悲觀)이 극한 일이 있고 후에 경사가 온다｣고 하였다.
    일귀는 역행하여 3전이 공(空)․백(白)․상(常)이며, 야귀는 순행하여 3전이 구(勾)․합(合)․주(朱)이다, `
    ,
    // 3
    `  이 과는 제2과․제4과에 상하의 극이 있고 하에서 상을 극하는 축묘(丑卯)를 사용하여 초전으로 하니 ․중심퇴여간전과(重審退茹間傳格)를 구성한다. 
    일간상신은 일간을 생하지만 공망되어 행하지 않는다. 천공이 승하여 모두가 공허 공론으로 끝난다. 3전이 축해유(丑亥酉)이면 극음격(極陰格)으로서 음모(陰謀)에는 이롭고 공공에는 불리하다. 
    초전은 순미(旬尾)의 축(丑)이니 폐구격(閉口格)으로, 주작․구진이 앉는 동시에 또 공망에 앉는 것은 일체의 문장이나 관사는 흉조로 된다. 말전은 일간의 재로 되고 토(土)에서 생을 받으니 재가 두텁다.
    백법에 ｢공상공(空上空)을 만나는 것은 무슨 일이나 추구하지 말라｣고 하였다. 
    일간일지 상신이 육해(六害)로 되니 파차간에 의심할 일이 일어난다. 
    비전에는 ｢묘유(卯酉)는 문호(門戶)라고 하는데, 문 위에 토신(土神)이 승하면 외출에 장애가 생긴다｣고 하였다. 또 이르기를 ｢일지상신 진(辰)에 백호가 승할 때는 거처를 옮기는 일이 있고, 축(丑)은 2양(二陽)이지만 해유(亥酉)는 밤에 속하므로 정단사는 유회(幽晦)하여 밝지 못한 형상을 이룬다｣고 하였다.
    일귀는 역행하며 3전은 구(勾)․주(朱)․귀(貴)이고, 야귀는 3전이 주(朱)․귀(貴)․음(陰)이다.`
    ,
    // 4
    `  이 과는 상하의 극이 없고 사극(斜剋)이 제2과․제4과에 있다. 양일(陽日)이니 양비(陽比)의 자묘(子卯)를 사용하여 초전으로 하는 요극호시과(遙剋蒿矢課)이다. 3전 4중신(仲神)은 삼교격(三交格)으로 되고 4과는 모두 형체도 없다.
    일간상신이 생기장생(生氣長生)이라도 공망하여 생할 수가 없다. 초전이 또 공망에 앉아 과숙격(寡宿格)으로 된다. 호시격(蒿矢格)은 길흉화복이 모두 경하다. 뿐만 아니라 4과가 전부 공망되므로 조금도 진실한 상태가 없다. 
    해산․이별사는 좋고, 망사․모사 및 기타는 순(旬)을 바꿔서 다시 정단해야 한다. 그러나 중전이 재효로 되고 말전 일간의 제왕(帝旺)은 일지로 돌아간다. 그러므로 재에 관한 사건은 충분하지는 않으나 길한 편이다.
    백법에 ｢공망이라면 사물의 추구를 멈춰야 한다｣고 하였다. 청룡이 생기에 앉아 길이 지연되고, 공망 때문에 지구력이 없다. 
    비전에는 ｢삼교격(三交格)은 집안에 악인이 숨는다｣고 하였다. 또 길흉은 모두 내부에서 일어난다. 자(子)가 묘(卯)에 가해지고 육합이 앉을 때는 색정의 일로 구설이 일어난다.
    일귀는 순행하며 3전은 합(合)․귀(貴)․현(玄)이고, 야귀는 3전이 사(蛇)․음(陰)․백(白)이다. `
    ,
    // 5
    `  이 과는 4과 중의 제4과에서 1하가 1상을 극하니 술인(戌寅)을 사용하여 초전으로 하는 중심과이다. 초전의 술(戌)은 병(丙)에서 보아 묘신(墓神)으로 되고, 3전이 인오술(寅午戌)인 것은 3합염상격(三合炎上格)이다. 내몸은 왕(旺)을 만나고 말전의 인(寅)은 장생으로 되지만 공망하니 생하면서 생으로 되지 않는다. 둔간하여 3기(奇)로 되면 흉이 화하여 길하게 된다. 정단사는 활기가 없으나 후에는 반드시 형통하며, 4과의 축(丑)과 오(午), 사(巳)의 교차육해(交車六害)로 된다.
    백법에 ｢3전이 3기를 만나는 것은 전적으로 길하게 화하여 흉을 구한다｣고 하였다. 3전의 염상(炎上)은 비견으로 되어 재와 처(妻)가 재해를 입는다.
    비전에는 ｢술(戌)에 천후(天后)가 승하여 인(寅)에 앉는 것은 사용인 또는 부하가 도주하며, 초․말전이 모두 공망하면 결과가 없다｣고 하였다.
    일귀는 순행하며 3전은 사(蛇)․현(玄)․청(靑)이고, 사(蛇)가 변화되어 청으로 된다. 년명 또는 행년 인(寅)의 전실공망(塡實空亡)은 길조로 된다. 야귀는 3전이 후(后)․백(白)․합(合)으로서 일녀격(泆女格)으로 된다. 남녀는 자유 연애 결혼이고, 여자가 남자를 유혹한다.`
    ,
    // 6
    `  이 과는 제1과․제2과에서 2상이 2하를 극한다. 양일이니 양의 극인 제1과 자사(子巳)를 사용하여 초전으로 하니 지일과(知一課)이다. 3전이 모두 위에서 하를 극하므로 사절격(四絶格)이다.
    일간․일지․상신 자축(子丑)의 지합이 있으며, 자(子)와 오(午)가 충하고 축(丑)과 미(未)가 충하며, 자(子)와 미(未)는 육해로 된다. 그러므로 화합하는 가운데 서로 싸우고 원수로 된다. 
    지일과는 모든 일에서 전일적이므로 하나에 붙는 것이 좋다. 일간상신은 관귀이지만 중전 및 일지상신에서 제복되어 흉재를 만나지 않는다. 말전 인(寅)의 생기는 공망을 꺼리며, 생하여도 생을 얻지 못한다.
    백법에 ｢귀살이 나타나도 두려울 것이 없다｣고 하였다. 제귀(制貴)하는 쪽에 양의(良醫)가 있다. 
    비전에 ｢병일(丙日)은 자(子)를 태신(胎神)으로 하고 축(丑)은 묘(卯)이다｣라고 하였다. 인월(寅月)은 혈지(血支)․혈기(血忌)가 양(養)에 앉아서 태신을 극하므로 출산을 정단하면 빠르다. 
    말전은 중전을, 중전은 초전을, 초전은 일간을 체극(遞剋)하므로 화협(和協)하는 정이 없다. 자(子)에 둔귀(遁鬼)가 앉으므로 명암 두 귀(鬼)는 간(干)을 극하여 병이나 소송 쟁의에서는 가장 흉하다.
    일귀는 순행하며 3전은 합(合)․음(陰)․청(靑)이고, 야귀는 3전이 사(蛇)․상(常)․합(合)이다.`
    ,
    // 7
    `  이 과에서는 12지가 대궁(對宮)으로 되어 극이 있으며, 1하가 1상을 극한다. 양일은 양비(陽比)의 자오(子午)를 사용하여 초전으로 하니 반음삼교과(返吟三交課)이다.
    일간일지상신은 모두가 극되어 공직 임관 및 구직은 길해도 기타 사건에서는 인택이 모두 굽 혀 펴지지 않는다. 수화(水火)는 남북교역(南北交易)의 신이니 이 일은 성립된다. 인신진술월(寅申辰戌月)은 천마(天馬)가 앉아서 길로 변한다. 반음과는 반복 왕래가 무상하다.
    백법에 ｢귀(鬼)에 천을(天乙)이 승하는 것은 신기격(神祗格)이며, 간지가 서로 상극하면 피차간에 인택이 모두 손상된다｣고 하였다. 부처무음(夫妻蕪淫)은 서로 사정을 품는다. 
    비전에는 ｢과거에 흉한 일이 거듭되었다면, 이와 같은 때에 흉과가 연포(演布)되는 것은 낡은 일을 끝맺게 된다｣고 하였다.
    일귀는 순행하며 3전은 현(玄)․합(合)․현(玄)이며, 야귀는 역행하여 3전이 청(靑)․후(后)․청(靑)이다.`
    ,
    // 8
    `  이 과는 제2과․제3과․제4과의 3상(三上)이 3하(三下)를 극하고, 양일은 양비의 극이니 진해(辰亥)를 채용하여 초전으로 하는 지일과(知一課)이다. 진(辰)으로써 3전을 이루는 것은 참관격(斬關格)이며, 일간상의 술(戌)은 묘신(墓神)에 엎드리고, 진(辰)에서 충하여 개묘로 된다. 말전의 인(寅)은 생기장생이지만 공망하니 생하여도 생하지 않는다. 
    간귀(干鬼)는 일지상신에 승한다. 다행히 토(土)는 수귀(水鬼)를 제어한다. 순수발용(旬首發用)으로 되는 것은 순의(旬儀)로서 나의 뜻을 원조한다. 중전이 유(酉)의 재효로 되는데, 귀신은 윗사람의 재, 주작은 문서에 의한 재, 즉 어음․수표․증권 등의 재이다. 늦으면 인(寅)의 공망 중에 들어 시작은 있어도 끝이 없다. 공망을 나가서 실현해야 한다.
    백법에 ｢귀(鬼)가 천을(天乙)에 승하는 것은 신기격(神祗格)이다. 양사협묘격(兩蛇夾墓格)은 재액(災厄)을 예방해야 한다｣고 하였다.
    비전에는 ｢술사(戌巳)에 더해지는 것은 개명(改名)의 징조이다｣라고 하였다. 모든 일은 두세 차례의 교섭 또는 노력으로 이루어진다. 3상(三上))에서 하를 극하면 부하 사람에게는 불리하다.
    일귀는 순행하며 3전은 백(白)․귀(貴)․청(靑)이고, 야귀는 역행하여 3전이 백(白)․주(朱)․현(玄)이다.`
    ,
    // 9
    `  이 과는 제1과․제4과에 상하의 극이 있다. 1하가 1상을 극하는 제1과 유사(酉巳)를 사용하여 초전으로 하니 3합금국중심종혁과(三合金局重審從革課)이다. 3전금국은 일간의 재로 되고 태왕(太旺)하다.
    재가 왕성하면 존친을 극한다. 따라서 질병․재해를 모두를 방비해야 한다. 3합의 재는 단결중인(團結衆人)의 재로서 한 사람의 재가 아니라 회사 집단의 재이다. 종혁(從革)은 정신(鼎新)의 형상이니 낡은 것을 버리고 새롭게 고치는 것을 좋아한다.
    백법에 ｢양(兩) 귀신이 하에서 극되면 웃사람의 신뢰를 얻지 못하고 일간일지상신이 육해로 되면 의심이 일어난다｣고 하였다. 술(戌)이 오(午)의 택상에 있는 것은 묘(墓)신이 덮이고, 만일 술(戌)이 월장이라면 태양이 택을 비춘다고 하여 경사스러운 일이 많다. 말전은 중을 생하고, 중전은 초전을 생하여 체생하며, 재는 몰래 관을 생하므로 타인의 천거에 의하여 기쁨이 있다.
    일귀는 순행하며 3전은 귀(貴)․상(常)․구(勾)이고, 야귀는 역행하여 3전이 주(朱)․음(陰)․공(空)이다.`
    ,
    // 10
    `  이 과는 제1과․제3과에서 2하가 상을 적(賊)하며, 양일이나 양비인 신사(申巳)를 사용하여 지일비용과(知一比用課)이다. 일간상신이 초전으로 되고 신(申)은 재로 된다. 중전은 해(亥)의 관효(官爻)로 되고, 말전의 인(寅)은 공망한다. 신금(申金)은 재상(財上)에 역마가 앉고 또 3전은 체생격(遞生格)으로서 재와 역마는 재리(財利)가 크다. 
    지일과(知一課)는 일의 대부분이 아직 결정되지 않았다. 이 과는 만사가 양호하나 말전 인(寅)이 순공(旬空)을 만난다. 만일 년명 인(寅)의 공망을 본다면 전실(塡實)은 허공의 작용을 하여 아름다운 과격으로 된다.
    백법에 ｢부부무음(夫婦蕪淫)은 서로 사정을 품고, 초전은 재효로 되며, 지반에서 천신을 극한다. 또 일간일지 양신(兩神) 하(下)에서 극하는 것은 거주인이 이산될 징조이다｣라고 하였다. 체새의 뒤에 인(寅)이 공망을 띠어 체생은 말뿐으로서 참된 뜻이 없다.
    일간일지는 서로 교차상극(交車相剋)한다. 교섭사는 불길하다. 
    일귀는 역행하여 3전이 사(蛇)․음(陰)․백(白)이며, 야귀는 3전이 합(合)․귀(貴)․현(玄)이다.`
    ,
    // 11
    `  이 과는 4과중 제3과의 신오(申午)에서 1하가 1상을 적하므로 이것을 초전으로 하여 중심간전과(重審間傳課)로 된다. 3전이 신술자(申戌子)이면 섭삼연격(涉三淵格)이다.
    일지상신의 신(申)은 역마로 되고, 일간상신은 정신둔간(丁神遁干)으로 된다. 과전 중에 정마(丁馬)가 있으면 인택이 모두 이동이 있다. 일간일지의 상하는 사신(巳申)이고 오미(午未)는 교차지합으로 되어, 주객이 서로 화합하고 또 손님을 환영하는 형상이다. 말전은 일간을 극하지만, 미(未)와 술(戌)은 자(子)의 수귀(水鬼)를 제어하므로 흉을 만나도 흉에서 벗어난다. 
    간전격(間傳格)은 순조로운 진행은 불가능하며, 간격적(間隔的)으로 진행하게 된다. 섭삼연격(涉三淵格)은 재에 의하여 움직이며 혼미를 초래하기 쉽다.
    백법에 ｢초전이 일간에서 극하고 사신(蛇神)에서 극되니 협극(夾剋)으로 되어 모든 일에서 자유를 얻지 못하며 재(財)는 적절하다｣고 하였다. 합(合)․후(后)가 승하여 결혼 문제는 중매인이 없는 자유 결혼이다. 태산은 말전 자(子)의 재신이 묘(墓)에 앉고 극되므로 출산은 빠르다. 다만 교차합이 있어 때로는 며칠 늦어지는 일이 있다. 3전이 양지뿐이고 일간상신 미(未)가 음지(陰地)이므로 여아를 잉태한다.
    일귀는 역순행하며 3전은 사(蛇)․후(后)․현(玄)이고, 야귀는 3전이 합(合)․사(蛇)․후(后)이다.`
    ,
    // 12
    `  이 과는 4과 중에 상하의 극이 없으므로 제4과의 신(申)을 일간에서 멀리 극한다. 따라서 신미(申未)를 사용하여 초전으로 하니 요극탄사과(遙剋彈射課)이다. 3전은 진여(進茹), 4과는 불비격이다.
    초․중전의 신유(申酉)는 편정재(偏正財)로 되고, 천지반 중의 축(丑)이 자(子)에 가해져서 복태격(服胎格)이 되므로 처첩이 임신한 것이다. 신유술(申酉戌)은 서방합(西方合)으로서 전재(全財)가 된다. 재가 왕하면 존친을 극하는데, 병(病)을 정단하면 흉하다. 과전 상하의 전지(前支)가 천반으로 되는 것은 나망격(羅網格)으로서 맹동(猛動)을 삼가고 조용하게 전진해야 한다. 초전의 재는 말전에서 생을 받으므로 임신․결혼․재에 관한 건에서는 모두 전진하여 길조로 된다.
    백법에 ｢나망격은 함부로 모략함을 금한다｣고 하였다. 모략하면 졸책을 끌어오기 때문이다. 
    비전에는 ｢3전이 신유술(申酉戌)이면 유금(流金)이라 부른다｣고 하였다. 서리낀 다리 위를 달리는 것과 같아 조용하게 걸어야 재액이 없다. 제2과․제4과는 이웃집이다. 상생하며 또 지합하기에 동네 사람들은 서로 화합된다.
    일귀는 역행하며 3전은 사․귀․후이고, 야귀는 3전이 합․주․사이다. 이 과는 탄사격(彈射格)으로서 길흉화복이 모두 경하다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
