
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,6,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,9,5,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기자 않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,4,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,3,`특별있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,12,2,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,1,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,2,12,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,11,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,10,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신뢰`,5,9,`특별있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,8,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,7,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`3`,`후원받음`,`단합결속`],
    [`부모`,`부모 관련사`,`大`,`학습기`,`투서헌책`,`사기득재`],
    [`동지함의(動止咸宜)`,`스스로 본분을 지킴으로 가나 있으나 좋은 상`,`大`,``,`고위접인`,``],
    [`합중불화(合中不和)`,`화합하는 중 간사 동요사가 있는 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사사`,`大`,`2`,`동지함의`,`신물수손`],
    [`여인`,`여인 관련사`,`小`,`면모기`,``,``],
    [`옥택고우(屋宅告訏)`,`집이 기울었으므로 과장하여 큰 소리치치 말아야 하는 상`,`小`,``,``,``],
    [`신물수손(身物受損)`,`신체관련 또는 물품의 손해가 있는 상 `,`大`,``,``,``],
    [`명예`,`수상사(受賞事)`,`大`,``,``,``],
    [`출행`,`내사 출행이동원행사`,`小`,`1`,`명예자산`,`부부이심`],
    [`권리`,`권리 관련사`,`小`,`절정기`,`외교막힘`,`동요분산`],
    [`폐구유의(閉口惟宜)`,`가장 좋은 것은 입을 닫는 것인 상 `,`大`,``,`도적근심`,`출행이동`],
    [`관천상부(官遷常不)`,`관직자는 영전하고 일반인은 되려 나쁜 상`,`小`,``,``,``],
    [`자매`,`자매 관련사`,`大`,``,``,``],
    [`결속`,`결속하여 음5월 금전 바탕을 세우나 명예직위남편 훼탈됨`,`小`,`12`,`색정음사`,`애증증오`],
    [`색정`,`外男 색정사 有`,`小`,`과시기`,`비용과출`,`도적소실`],
    [`기필도래(期必到來)`,`천리길을 기일에 맞취 반드시 도착해야 하는 상`,`大`,``,`약속누설`,``],
    [`득죄자초(得罪自招)`,`약속한 비밀을 누설하여 죄를스스로 자초한 상`,`小`,``,``,``],
    [`금전 `,`금전 침체사`,`小`,``,``,``],
    [`상업`,`사업 공허사`,`大`,`11`,`관재병질`,`재물유실`],
    [`여인`,`처(妻) 관련사`,`小`,`쇠퇴기`,``,``],
    [`주의수상(主意受傷)`,`몸 다칠 우려의 상`,`小`,``,``,``],
    [`득죄자초(得罪自招)`,`약속한 비밀을 누설하여 죄를스스로 자초한 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`10`,`반목대립`,`애증화합`],
    [`자녀`,`자녀 관련사`,`小`,`침해기`,`급속송쟁`,`급속사有`],
    [`병송구외(病訟俱畏)`,`병과 송사가 두려운 상`,`小`,``,`상신우려`,``],
    [`생살대권(生殺大權)`,`관직자는 생살대권을 쥐고 일반인은 형벌이 대흉 함`,`小`,``,``,``],
    [`박관`,`직업자손사기 관련사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`9`,`고위연회`,`영전도약`],
    [`매사 영속성없음`,`직업지위자녀속는 일은 선전착 기복재해를 겪음`,`小`,`소외기`,`초대열석`,``],
    [`필시불리(必是不利)`,`주력사가 불리함이 확실한 상`,`小`,``,`결국무산`,``],
    [`비상지애(非常之愛)`,`각별하게 아끼는 애정사를 갖고 있는 상`,`小`,``,``,``],
    [`결속`,`결속하여 후원사를 크게 이루나 자녀의 재해가 발생함`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`8`,`탄식신음`,`비상지애`],
    [`매사 영속성없음`,`금전사업이권처첩사는 선천적 기복재해를 겪음`,`小`,`침체기`,`病 事미수`,``],
    [`난영자손(難榮子孫)`,`결속하여 후원사가 완성되는 상- 반드시 자녀 근심 발생`,`小`,``,``,``],
    [`도적소실(盜賊消失)`,`도난 도적근심이 없어진 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,`와병불기`,``],
    [`출행`,`출행이동원행사`,`小`,`7`,`이심동요`,`가정막힘`],
    [`남편`,`남편 관련사`,`大`,`단절기`,`불안분산`,`굴욕반복`],
    [`귀인이옥(貴人履獄)`,`도와주는 두 고위인이 힘을 못쓰는 처지에 놓인 상`,`小`,``,`출행이동`,``],
    [`총시성재(總是成災)`,`모든 일이 재난으로 되는 상`,`大`,``,`구설사`,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,`손해도망`,`색정음사`],
    [`색정`,`內女 색정사 有`,`大`,`존재기`,`위례득죄`,`도적실세`],
    [`이근불원(利近不遠)`,`가까이 도모하면 좋고 멀게 도모하면 불리한 상`,`小`,``,``,`죄수됨`],
    [`위례득죄(違禮得罪)`,`예의없는 짓으로 죄를 받는 상`,`大`,``,``,``],
    [`금전`,`금전 공허사`,`小`,``,``,``],
    [`사업`,`사업 침체사`,`大`,`5`,`문서상신`,`事後쟁탈`],
    [`처첩`,`처 여인사`,`小`,`배양기`,`답신지체`,``],
    [`가정잉태(家庭孕胎)`,`가정에 임신사가 있는 상`,`小`,``,`구설사`,``],
    [`위례득죄(違禮得罪)`,`예의없는 짓으로 죄를 받는 상`,`大`,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,`급속사有`,``],
    [`문서`,`문서 관련사`,`小`,`4`,`애증화합`,`손해가출`],
    [`부모`,`부모 관련사`,`大`,`개시기`,`손해도망`,`좋은소식`],
    [`결절(結絶)`,`내부 가정의 오랜 일이 끓어지는 상`,`大`,``,`파재실종`,`사有`],
    [`이어퇴장(利於退藏)`,`시기를 잃은 진취는 불리하므로 퇴하는 것이 되려 좋은 상`,`小`,``,`우려`,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`후원조력가정단합사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`금전사업여인사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`명예자산권리출행사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`재매친구동료비용지출색정사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`금전사업처사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,`직업직위불안자녀강제급속손실사로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,`허성불성`,`세속허사자로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,``,`허성불성`,`세속허사자로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`관사남편외사송쟁사로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`관사남자가정색정사로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업처관련사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,`후원조력부모사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`현재애서 물러나는 상**친속불화함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어렵고 신중해야 하며 후에 행동함이 좋고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 역으로 띄엄띄엄 진행되어 퇴하려하나 되려 진행하게 됨**일이 반복되기만 하고 결과가 없으나 계속진행하면 좋게 됨**옛것으로 되돌아감**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속결하면 이롭고 나중으로 미루면 불리함`,0,0,`명분이 정당하므로 앞서 빠르게 속결하면 유리하나 뒤로 밀거나 우유부단하면 불리한 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어렵고 신중해야 하며 후에 행동함이 좋고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 사안을 하나로 묶음-단, 외화내허적임**남자가 타인의 부인을 유혹하여 자기 여자로 만듬**어디 깊은 산속으로 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`권리사를 얻음-관직자 영전하고 일반인 은 불량함**관재 우려`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이며재난이 밖에서 오므로 신중해야 함`,0,0,`반복무상한 人`,0,0,`상하가 뒤바뀜**아래가 위가되어 머리행세를 하는 어지러운상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`권리행사를 하려하나 도장을 새길 나무가 썩은 격임**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속결하면 이롭고 나중으로 미루면 불리함`,0,0,`명분이 정당하므로 앞서 빠르게 속결하면 유리하나 뒤로 밀거나 우유부단하면 불리한 人`,0,0,`여러 일을 하나로 묶는 일이 있음**입을 열지 않음**일이 반복되나 계속하면 좋아짐**일이 갑자기 돌발함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 반발하지 말고 일단 순응하고 유화적 처신으로 사안을 뒤로 연기하면 결과가 유리함 `,0,0,`역상적 사안이므로 억울하더라도 절대 항변하지 말고 순응하고 유순한 처신으로 일을 연기하면 결과가 유리할 人 `,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어렵고 신중해야 하며 후에 행동함이 좋고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`어디 깊은 산속으로 숨고싶은 심정 임**관직자 영전함-단 늦으면 불발함**어떤 일도 초기에 결정해야만 함-미루거나 좌고우면 하면 불성함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 반발하지 말고 일단 순응하고 유화적 처신으로 사안을 뒤로 연기하면 결과가 유리함 `,0,0,`역상적 사안이므로 억울하더라도 절대 항변하지 말고 순응하고 유순한 처신으로 일을 연기하면 결과가 유리할 人 `,0,0,`일이 순조로운 듯하나 결과가 비었음**관재 우려`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 동해도 중지됨`,0,0,`스스로 자만하여 신음하고 기회를 기다리는 人`,0,0,`신규사 있음**자기의 우월성을 믿음**고시합격자가 많음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`좌고우면 `,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인 비용지출`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면 `,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동후회사타발동동시사`,`기사`,`신사`,`내외동시사`,`표면이면동시사`,`배신`,`보성-무력`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성-무력`,`거짓위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면 `,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면 `,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동-후회심 有`,`기사`,`신사`,`외사`,`표면이면동시사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥-이전예정`,`쇠-애증`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-이사예정`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 화해된`,`화해됨`,` 화해됨`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해가능`,`화해가능`,`화해가능`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해가능`,`화해가능`,`화해가능`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월`,1,1,2,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,1,1,2,`음1월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,1,3,4,`음12월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`#NAME?`,2,3,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월-연기 또는 파혼 우려`,3,3,6,`음9월-연기 또는 파혼 우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월-연기 도는 파혼 우려`,2,1,3,`음3월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음2월-연기 또는 파혼우려`,2,1,3,`음2월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월-연기 또는 파혼우려`,3,3,6,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월-연기 또는 파혼 우려`,1,3,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음1월`,2,2,4,`음7월-연기 또는 파혼 우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,3,2,5,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음11월`,2,3,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`용모가 준수 미모이고 일처리가 강하지 못한 것 깉으니 업무는 수준 이상으로 긑 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품은 훌룡하나 초 중반은 허무하고 종반에서 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯하나 상대를 잘 조정하는 능력이 있어 수준 이상의 실적을 나타냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 원조를 받으나 초반에 실적을 내다가 중 종반에는 허무하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 원조를 받고 업무실적은 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 시종 동요불안분산사를 일으켜 후미가 어지러운 사태를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 초반의 부진을 벗어나 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많으나 여러 일을 하나로 묶어 일관적 실적을 드러냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하나 업무는 수준이상의 실적을 보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의; 원조를 받으나 초반 이외 중 종반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 원조를 받으나 초반 이외는 수준 이하의 실적이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하며 송쟁사를 일으켜 결국 피해를 입힘유순하고 준구 미모이나 업무`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`없음`,`이행`,`사안무력`,`進向`,`근`,`留`,`주인무력`,`정동`,``,`정북`],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`없음`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`동남`,``,`북북동`],
    [`7.8월`,``,``,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`있음 `,`이행`,`손해동요`,`進向`,`  원`,`  留`,`애증화합`,` 동남`,``,`동남`],
    [`7.8월`,``,`무력불발`,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`없음`,`이행`,`선加압박`,` 退背`,`  원`,`  去`,`주인조력`,`남남동`,`남서`,``],
    [`7.8월`,``,``,``,`후동요`,``,``,``,``,``,``,`정서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`본인반대`,`이행`,`선比후難`,`退背`,`  근`,`   去`,`세입가해`,`북북동`,`정동`,``],
    [`7.8월`,``,`초기승세`,``,``,``,``,``,``,``,``,`북서`],
    [`10.11`,``,`종결필요`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,`후반부결`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`본인반대`,`이행`,`선이후난`,`退背`,`  원`,`   去`,`세입가해`,`동남`,`동북`,``],
    [`7.8월`,``,`동요불안`,``,``,``,``,``,``,`세밀색인`,``,`북북서`],
    [`10.11`,`  集`,`이심분산`,``,``,``,``,``,``,`함`,``,``],
    [`3.6\n9.12`,``,`결과없음`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`이행`,`선이후不`,`退背`,`  근`,`  去`,`주인조력`,`남남동`,`북북동`,`정북`],
    [`10.11`,`  集`,``,``,`본인무력`,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`이행`,`선이후난`,`退背`,`  근`,`   去`,`세입조력`,`서북`,`동남`,`북북동`],
    [`10.11`,``,``,``,`본인무력`,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`있음 `,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`세입조력`,`북북서`,`남남동`,`동남`],
    [`10.11`,``,`애증남음`,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,`예정참고`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`있음 `,`이행`,`선이후나`,`退背`,`  원`,`  去`,`주인조력`,`정북`,``,`동남`],
    [`10.11`,``,`무력`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,`이행`,`선난후난`,` 退背`,`  원`,`  留`,`무난함`,`정북`,``,`정동`],
    [`7.8월`,``,`불발현함`,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음 `,`이행`,`선不후不`,`退背`,`  근`,`  留`,`무난함`,`동북`,``,`북북서정북`],
    [`7.8월`,``,`송쟁비화`,``,``,``,``,``,``,``,``,``],
    [`10.11`,`  集`,`패소우려`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,0,0,``,``,``,``,``],
    [``,0,0,``,``,``,``,``],
    [``,0,0,``,``,``,``,``],
    [`질병`,0,0,``,``,`묘좌`,``,`있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,0,0,``,``,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,``,``,`육친`,``,``],
    [`질병`,0,0,``,``,`묘좌`,``,`있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,0,0,``,``,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,``,``,`육친`,``,``],
    [`질병`,0,0,``,``,`묘좌`,`남남동`,`있음`],
    [``,0,0,``,``,`묘향`,`북북서`,``],
    [`생업`,0,0,``,``,`전경`,`윤천등라`,``],
    [``,0,0,``,``,`묘속`,`뱀굴`,``],
    [`가정`,0,0,``,``,`육친`,`증모,첩`,``],
    [`질병`,0,0,``,``,`묘좌`,`동남`,`특별있음`],
    [``,0,0,``,``,`묘향`,`북서`,``],
    [`생업`,0,0,`가당부정`,`성물훼손`,`전경`,`바위습지`,``],
    [``,0,0,``,``,`묘속`,`뻘흙`,``],
    [`가정`,0,0,`두성부정`,`기도위배`,`육친`,`조부`,``],
    [`질병`,0,0,``,``,`묘좌`,``,`있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,0,0,``,``,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,``,``,`육친`,``,``],
    [`질병`,`도로귀`,`교툥사망`,``,``,`묘좌`,``,`있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,0,0,``,``,`묘좌`,``,`있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,0,0,``,``,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,``,``,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,`정북`,`있음`],
    [``,0,0,``,``,`묘향`,`정남`,``],
    [`생업`,0,0,``,``,`전경`,`강,호수,천`,``],
    [``,0,0,``,``,`묘속`,`벌흙`,``],
    [`가정`,0,0,``,``,`육친`,`조부`,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,``,`있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,0,0,``,``,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,``,``,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,``,`특별있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,0,0,`가당부정`,`성물훼손`,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,`두성부정`,`기도위배`,`육친`,``,``],
    [`질병`,0,0,``,``,`묘좌`,``,`있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,0,0,``,``,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,``,``,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,``,`있음`],
    [``,0,0,``,``,`묘향`,``,``],
    [`생업`,0,0,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,0,0,``,``,`묘속`,``,``],
    [`가정`,0,0,`상가부정`,`조문왕래`,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외 급속사`,`도적근심**이동사`,``,`스스로지켜 가나 있으나 좋음`,``,`투서헌책 고위면접사`,`외교막힘`,`명예자산권리사**내사 원행출행이동사`,``,`외男색정사`,``,`違禮득죄사`,`내 급속사`,`관직자영전도약-일반인 반흉사`,`매사허성불성**세속허사 자**몸, 물품유실사`,`음1,2,4,5월 방문자는 종교진출하면`,`매사영속성없음`,`종교진출하면 자립함`,`외사 출행이동사`,`외사이심동요분산사**가정막힘**좋은소식사`,`내女색정사`,`事後쟁탈사`,`매사위역사**빈궁**관재`,`도적罪囚사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`빈궁`,`전화위복`,``,``,`재난해소`,`빈궁`,``,`관재`,``,``,`재난해소`,`전도**빈궁`,`음4,5월 화재주의`,`전도`,`성공함`,`빈궁`,`재난해소`,`관재**병재**빈궁`,`전화위복`,`관재`,``,`재난해소`,`침체경구사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사`,`도적罪囚사`,`외 급속사`,`도적근심**이동사`,``,`스스로지켜가너있으나좋음`,``,`투서헌책 고위면접사`,`외교막힘`,`명예자산권리사 내사 원행출행이동사`,``,`외男색정사`,``,`違禮득죄사`,`내 급속사`,`관직자 영전도약-일반인 반흉사`,`매사영속성없음**몸,물품유실사`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면자립함`,`외사 출행이동사`,`외사이심동요분산사**가정막힘**좋은소식사`,`내女색정사`,`事後쟁탈사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`재난해소`,`빈궁`,`전화위복`,`재난해소`,``,``,`빈궁**관재`,``,`빈궁`,``,``,``,`빈궁`,`음4,5월 화재주의`,``,``,`관재**빈궁`,``,`전도**병재**관재**빈궁`,`전화위복`,`전도**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내女색정사`,`事後쟁탈사`,`매사위역사`,`도적罪囚사`,`외 급속사`,`도적근심**이동사`,`전도**빈`,`스스로지켜가나있으나좋음`,``,`투서헌책 고위면접사`,`외교막힘`,`명예자산권리사**내사 원행출행이동사`,``,`외男색정사`,``,`違禮득죄사`,`내 급속사`,`관직자 영전도약-일반인 반흉사`,`매사영속성없음**몸,물품유실사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외사 출행이동사`,`외사동요분산사**가정막힘**좋은소식사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**관재`,``,`빈궁`,``,`빈궁`,`재난해소`,`전화위복`,`재난해소`,`빈궁`,``,`빈궁`,``,``,``,`관재`,``,`관재`,`음4,5월 화재주의`,`관재`,``,`빈궁`,``,`전도**병재**관재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`모두 흉함`,`외사 출행이동사`,``,`내女색정사`,`불검약자초罪사`,``,`견련牽連반복사`,`외 급속사`,`非常之愛사`,``,`관직자영전도약사`,``,`명예자산권리사**내사 원행출행이동사`,`관직자 생살대권 쥠-일반인 형벌대흉**외교막힘`,`재물손,유실사`,``,`도적실로소실사`,`매사위역사`,`중병구설사`,`내 급속사`,`탄식신음**매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**관재**병재`,``,`빈궁**관재`,``,``,`전도**빈궁`,``,`전화위복`,`전도`,`빈궁`,``,`빈궁**병재`,`단,미흡함`,`관재`,``,`빈궁`,``,`음4,5월 화재주의`,`관재`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`모두 흉함`,`외사 출행이동사`,``,`내女색정사`,`불검약자초罪사`,``,`외 급속사`,`견련牽連반복사`,`非常之愛사`,``,`관직자영전도약사`,``,`명예자산권리사**내사 출행원행이동사`,`관직자 생살대권 쥠 일반인 형벌대흉**외교막힘`,`재물손,유실사`,``,`도적실로소실사`,`매사위역사`,`중병구설사`,`내 급속사`,`탄식신음**매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,`관재**병재**빈궁`,``,``,`전도**빈궁`,``,`전도`,``,`전화위복`,`빈궁`,`빈궁**병재`,``,`관재`,`단,미흡함`,`재난해소`,``,``,`빈궁`,`음4,5월 화재주의`,`빈궁`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`탄식신음**매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`모두 흉함*외사 출행이동사`,`관재**빈궁**병재`,`빈궁**관재`,`내女색정사`,`불검약자초罪사`,``,`견련牽連반복사`,`빈궁**관재`,`非常之愛사`,`관재`,`관직자영전도약사`,`전도**빈궁`,`명예자산권리사**내사 출행원행이동사`,`관직자 생살대권 쥠 일반인 형벌대흉**외교막힘`,`재물손,유실사`,``,`도적실로소실사`,`매사위역사`,`중병구설사`,`빈궁**내 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`전화위복`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`외 급속사`,`동요불안분산사`,`전화위복`,`동요불안분산사`,``,`빈궁**병재**재난해소`,`동요불안분산사`,`재난해소`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사`,`음4,5월 화재주의`,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`중병구설사`,`내 급속사`,`탄식신음**매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`모두 흉함`,`외사 출행이동사`,``,`내女색정사`,`불검약자초罪사`,``,`견련牽連반복사`,`외 급속사`,`非常之愛사`,``,`관직자영전도약사`,``,`명예자산권리사**내사 출행이동원행사`,`관직자 생살대권 쥠 일반인 형벌대흉**외교막힘`,`재물손,유실사`,``,`도적실로소실사`,`매사위역사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재주의`,`전도**빈궁`,`전도`,``,``,``,`관재**병재**빈궁`,`전화위복`,`빈궁**관재`,``,`재난해소`,``,`빈궁**관재`,``,``,`전화위복`,`재난해소`,`빈궁`,`빈궁**병재`,``,`관재`,``,`재난해소`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`도적실로소실사`,`매사위역사`,`중병구설사`,`내 급속사`,`탄식신음**매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`모두 흉함`,`외사 출행이동사`,``,`내女색정사`,``,`불검약자초罪사`,`견련牽連반복사`,`외 급속사`,`非常之愛사`,``,`관직자영전도약사`,``,`명예자산권리사**내사 출행이동원행사`,`관직자 생살대권 쥠 일반인 형벌대흉**외교막힘`,`재물손,유실사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`음4,5월 화재주의`,`빈궁`,``,``,``,``,`관재**빈궁**병재`,`전화위복`,`빈궁**관재`,``,``,``,`전도**빈궁`,``,`전도`,`전화위복`,`빈궁**병재`,``,`재난해소`,`관재**병재**빈궁`,`재난해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재물손,유실사`,``,`도적실로소실사`,`매사위역사`,`중병구설사`,`빈궁**내 급속사`,`탄식신음**매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`모두 흉함`,`외사 출행이동사`,``,`내女색정사`,``,`불검약자초罪사`,`견련牽連반복사`,`외 급속사`,`非常之愛사`,``,`관직자영전도약사`,``,`명예자산권리사**내사 출행원행이동사`,`관직자 생살대권 쥠 일반인 형벌대흉**외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**관재`,``,`빈궁`,``,`재난해소`,`음4,5월 화재주의`,``,``,`빈궁**관재**병재`,``,`관재**빈궁**병재`,`전화위복`,`관재**빈궁`,``,``,``,`빈궁`,``,``,`전화위복`,`빈궁`,``,`전도**빈궁**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외교막힘`,`명예자산권리사**내사 출행이동원행사`,``,`외男색정사`,``,`違禮득죄사`,`내 급속사`,`관직자 여언도약-일반인 반흉사`,`매사영속성없음**몸,물품유실사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외사 출행이동사`,`외사동요분산사**가정막힘**좋은소식사`,`내女색정사`,`事後쟁탈사`,`매사위역사`,`도적罪囚사`,`외 급속사`,`도적근심**이동사`,``,`스스로지켜가나있으나좋음`,``,`투서헌책 귀인면접사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재난해소`,`빈궁`,`재난해소`,`관재**병재**빈궁`,``,``,`음4,5월 화재주의`,`빈궁`,``,``,``,`빈궁`,`관재**병재**빈궁`,`전화위복`,`관재`,``,`빈궁`,``,`전도**빈궁`,``,``,`전화위복`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`투서헌책 귀인면접사`,`외교막힘`,`명예자산권리사**내사 출행이동원행사`,``,`외男색정사`,``,`違禮득죄사`,`내 급속사`,`관직자 영전도약-일반인 반흉사`,`매사영속성없음**몸,물품유실사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외사 출행이동사`,`외사동요분산사**가정막힘**좋은소식사`,`내女색정사`,`事後쟁탈사`,`매사위역사`,`도적罪囚사`,`외 급속사`,`도작근심**이동사`,``,`스스로지켜가나있으나좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재난해소`,`빈궁**관재`,`재난해소`,`빈궁`,``,``,`음4,5월 화재주의`,`빈궁`,``,``,`빈궁`,``,`관재**병재`,`전화위복`,`관재`,``,`빈궁**병재`,``,`전도**빈궁`,`폐한인`,`전도`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`스스로지켜가나있으나좋음`,``,`투서헌책 귀인면접사`,`외교막힘`,`명예자산권리사**내사 출행이동원행사`,``,`외男색정사`,``,`違禮득죄사`,`내 급속사`,`관직자 영전도약-일반인 반흉사`,`매사허성불성**세속허사 자**몸,물품유실사`,`음1,2,4,5월 방문자는 종교진출하면`,`매사허성불성**세속허사 자`,`음1,2,4,5월방문자는 종교진출하면 `,`관재**빈궁**병재**외사 출행이동사`,`외사동요분산사**가정막힘**좋은소식사`,`내女색정사`,`事後쟁탈사`,`매사위역사`,`도적罪囚사`,`외급속사`,`도적근심**이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`전화위복`,``,`전도`,``,`빈궁`,`빈궁`,``,`전도`,``,`음4,5월 화재주의`,`빈궁`,``,`성공함`,`전도**빈궁`,`성공함`,`재난해소`,`전화위복`,`재난해소`,`관재`,`전도**빈궁`,``,`빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 내 토대가 재판 붙어 꼼짝 못하게 생겼는데 새로 일을 꾸민다고 잘 되겠어? 
자고심을 버리고,
⊕여귀,⊖가택상가부정이 들고. 전ㆍ흉사귀부터 없애야 이득을 얻고 관재와 병재도 예방되겠어 !

⊖신변 음사부정 침범했네?
`,`■하극상을 받고 질투․의심도 크고 돈과 여자로 기로에 섰네?
⊕윗 사람과의 돈 문제는      그림의 떡이고, 
⊖돈에 거짓말만 잔뜩 붙었네?    후퇴 방침이 吉이야!
`,`■ 벼랑 끝에 선 격인데, 
계속 전진하는 수밖에 없어! 옛일이 다시 돌아서고 있겠는데 이판사판으로 덤벼봐!

⊕가택음사부정은 해소하고!

⊕신변 상가부정이 들었어 !
`,`■ 공직자:큰 영화가 기다               리고 있겠고,         서민 : 관재가 벌어지겠             는데,
그 때는 
입을 꼭 닫아야 해 ! 
고사를 잘 지내면 풀리겠어!
`,`■ 빛좋은 개살구격인데
男：새 일을 찾아야 할 운세이고,      남의 여자를 내것만들었네?
女：이별수가 있어!
⊕⊖가택에 두성불안 부정이      들어서 그래!
⊕신변 가당신사부정 침범함
⊖신변가당신사부정 침범했어!
`,`■ 가정내부 금전․여자문제로 자칫 가정이 깨지게 생겼어! 
지금 의심 질투로 기로에 섰는데,

⊕변사귀, 
⊖전사귀 침범 때문에 그래! 이것만 잡아주면 좋아지겠어!
`,`■ 색정불화로 가만히 있을 수가 없는 지경이겠는데 식구도 등을 돌렸어! 배반 당하고도 새 일을 해보려고 하겠지만
⊕도로귀, ⊖가택귀가 가로막고 있고!상가부정도 들었다.

⊕신변음사부정이 들었어!
`,`■ 마음이 두개로 흩어지니 날 저무는 격을 당하지! 
마음에 질투 의심을 풀고 
친하고 가까운 곳에 사는 
사람을 찾으면 복이 될거야!
`,`■ 좋네? 지금 어려운건

⊕도로귀, 
⊖가택에 음사부정과. 가택귀    때문이고, 앞으로 큰 복이    오고 있어 ! 
  돌아가시오 !

⊖신변상가부정이들었네?
`,`■ 좋아! 그런데,
  집에 상사(喪事)가 나겠어!

⊕도로귀, 
⊖가택귀만 잡아주면, 
  새로 꾸미는 일은 잘 되겠어!    돌아가시오 !
`,`■ 지나친 욕심이 화를 불렀어!  편히 발 뻗을 데가 없겠어!
⊕⊖가택두성불안 부정이 들어서      그러네 ? 
⊕여귀,⊖전흉사귀도 방해하고     있고! 이것만 풀면 좋아져!

⊕신변 가당신사부정 침범함
⊖신변가당신사부정침범했네?
`,`■ 분수를 몰라서 관재가 생기겠어! 

⊕음귀, 
⊖신불제사귀 부터 없애고 나면 고생 좀 한 후 좋아지겠어!   급해!`];
const YOGYEOL1 = [`■ 기다리는 사람은 돌아오나 와서는 반드시 나를 속인다. 낮정단은 녹에 살이 붙어 해롭고 밤정단은 매사 끝에 나쁘다.
`,`■ 서로 어리석으니 일을 도모하지 말라. 두 귀인은 도와주지 않는다. 생계는 비록 영화스러우나 자식 문제는 나쁘다.
`,`■ 집안에 자식이 문제가 있으니 정신 못차리거나 돈 없앨까 걱정이다. 그러나 살을 막아주고 있으니 그 자식을 돌보면 나중에 좋다.
`,`■ 상대는 처음이고 나는 결과이니 저 사람이 와서 나를 구한다. 
움직이는 역마가 많으나 입 다물고 있는 것이 상수다.
`,`■ 변동수가 있으나 중간과 결과가 허망스럽다. 만약 正月에 정단하면 범이 말을 타고 와서 재앙을 부를 것이다.
`,`■ 서로 도와 이로우나  각자 묘가 되니 주의하라. 움직였다 하면 그물에 걸리니 면할 수 있다고는 생각지 말라.
`,`■ 자기가 존경받지 못하고 도리어 망신당하는 일이 닥치리라.
만약 밤정단이면 8마리 범이 입 벌리고 있다.
`,`■ 나와 상대가 모두 답답하니 밤정단은 소송을 이기기 어렵다. 세월이 가면 사람은 점점 좋아지고 집안도 점점 흥하리라.
`,`■ 사람은 잘 되나 집은 불편하다. 나는 잘되고 상대는 안된다.
내가 손해가 있더라도 하늘이 도와 면하리라.
`,`■ 부딪쳐 흩어져도 이기고 도우며 붙잡아준다. 귀인이 모두 성을 내니 밤정단은 범이 말을 타고 와서 적잖은 일이 벌어지리라.
`,`■ 사람과 집에 변동의 신이 탔으나 결과가 없어 뜻대로 안된다.
辰年七月정단이면 오래 끌던 일이 해결된다.
`,`■ 사람이고 집이고 모두 잘되니 그물에 걸려들 변동은 말라. 
밤정단은 손해가 심하고 남의 말 듣다가 손해 보게 된다.`]
const SIBO1 = [`■ 사물이 갈림길에 있다.
가내우환이 발생하고,
가장을 속인다. 가인이 원한
을 품고 가해하려 한다.
凶을 凶으로 제어한다.
재산에 소송이 붙었다. 
상대와 거택이나 물건을 교환하는 입장에 있다. 과거의 일이 모두 끝난다.
은복하고 때를 기다려야 한다. 계속 움직일 일이 생긴다.
`,`■ 혼자 모두 해결할 듯
싶으나 동업, 또는 공동
으로 일을 해야 이롭다.
단, 적극적이 아닌 후퇴자세가 좋다. 따라 가는듯하라!
갈등으로 의심하고 좌고우면하나 근친자로 결정하면 좋다. 밤길을 조심하고 여자를 경계하라.
이권을 상실한다.
`,`■ 가정 별리사가 있다.
이로인해 가택이 손상 될
조짐이다. ⊖음사부정이
들었다. 서로 조행이 불량하여 가운이 패퇴되는 운세이다.
물러나면 불리하고 계속 전진할 수 밖에 없다. 
일이 지체되어 진퇴양난이다. 나중에 길조를 얻게 된다.
자손 재해가 있게 된다.
`,`■ 이익사가 있고, 소득한다.
말조심만 하고 적극적인
활약을 하여 희경을 얻게
된다. 서로 반목ㆍ대립사가 일어나겠으나 결국 유리하게 된다. 이때는 凶을 凶으로 제어해야 한다.
`,`■ ⊕⊖가정에 두성불안 부정
이 들어 어른의 재화가
있게 된다.
사물이 늦고 허사가 많고 실익이 없다. 금전도 자유롭지 못하게 된다. 
부부 모두 음탕하다. 
특히 남자는 여자를 조심해야 하는데, 현재 남의 부인을 마누라처럼 부리고 있다. 욕심내면 관재에 걸린다.
`,`■ 자충수적 우환으로 
⊕ 현임관리는 탄핵을 받고
⊖일반인은 다툼이 있다.
일이 양기에 걸쳐 하나를 선택해야 할 입장에 있다. 
근친자로 결정하면 매사 양호하여 모사가 성취된다. 특히 교섭사에 이익이 있다. 단, 관재 발생을 주의하라.
十月에 임신사가 있다.
`,`■ 자충수적 우환이 있다.
가정에 부부이심 이별사가
있고 재산이 충산 될
사정에 있다. 왕래ㆍ동요가 비상하다. 
능욕사는 조용히 대기하면 해구된다. 가택의 권리가 넘어간다. 이것으로 凶도 끝나게 된다.
`,`■ 집안 일을 밖에서 말하면
해롭게 된다.
폐지사를 부흥시키려는
兆가 있으나 어렵다.
10일이 경과하여 吉兆가 다가와 인택이 함께 흥성한다. 
특히 구류술업자나 신앙가에게 좋다.
가내 금전 여자문제로 고심을 한다.
이권을 상실한다.
`,`■ 타인에게 속아 인택
모두 쇠퇴한 운세이다.
일도 반복의 연속으로
매듭이 없다.
그러나 적극적으로 나아가면 吉하게 되어 타인의 추천이나 원조를 얻어 만사 순조롭게 된다.

⊖가정에 음사부정이 들어 소인배나 도적이 걱정된다.

`,`■ 겸직ㆍ승진사가 있다.
上人이나 귀인의 도움을
요청하지 않아도 된다.
처음에는 불평이 있고 길흉이 상반되나 신중한 언행으로 추천ㆍ후원이 이루어진다.
단, 집안일을 밖에서 말하면 해롭게 된다.
집안에 죽을 사람이 있다.
`,`■ 가정에 ⊕⊖두성불안이
들어 관재가 있게 된다.
존친의 재해가 걱정된다.
어떤 일이든 첫 제의나 결정에 따르는 것이 유리하다. 
진퇴간난의 형편을 겪은 후 인내로 小成하게 된다.
이사가 吉하다. 
자손 재해가 있게 된다.
`,`■ 가내 우환이 발생하고
가장을 속이고 원한을
품고 가해하려 한다.
1ㆍ3과가 모두 양인이 되어 조용한 처신이 절대 필요하다. 특히 쟁투에 주의해야 한다. 자칫 관재에 걸리게 된다. 전진하여 吉할 것 같아도 물러서야 吉하다. 
十月에 임신사가 있다.
자손 재해가 있게 된다.`]
const SIBO2_1 = [`■직녹, 신변외사이다.
내방조짐 상의 : 
원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■후원, 가택내사이다.
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.
우매인물이다.
`,`■자손ㆍ속는일, 신변외사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.속는일이 있고
자손재액이 발생한다. 
`,`■자손, 가택내사이다.
내방조짐 상의 :  
은근하고 伏하라.
가정 동요사가 있다.
타개책이 별무하다.
`,`■금전ㆍ여, 가정동요사이다.
내방조짐 상의 : 
男직위상실하고
女부부이별한다.
`,`■금전ㆍ여, 교역사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■직녹, 신변외사이다.
내방조짐 상의 : 
영전·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■후원, 가택내사이다.
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.
만사 공론 공화이다
`,`■근ㆍ친 가택내사이다.
내방조짐 상의 : 
해산건이 있다.움직임이근본이고동기가 발동한다.자손재해 예방필요함
`,`■官･夫,가택내사이다.
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
이전 이사를 한다.
`,`■금전ㆍ여, 교역사이다.
내방조짐 상의 : 
움직임이 근본이고 동기가 발동한다.
백살이 해제된다.
`,`■금전ㆍ여, 교역사이다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
근신 임신사가 있다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/021/row-1-column-1.png`,
  `/static/images/sagwa/021/row-1-column-2.png`,
  `/static/images/sagwa/021/row-1-column-3.png`,
  `/static/images/sagwa/021/row-1-column-4.png`,
  `/static/images/sagwa/021/row-1-column-5.png`,
  `/static/images/sagwa/021/row-1-column-6.png`,
  `/static/images/sagwa/021/row-1-column-7.png`,
  `/static/images/sagwa/021/row-1-column-8.png`,
  `/static/images/sagwa/021/row-1-column-9.png`,
  `/static/images/sagwa/021/row-1-column-10.png`,
  `/static/images/sagwa/021/row-1-column-11.png`,
  `/static/images/sagwa/021/row-1-column-12.png`,
]

const HEASYEOL = [
  // 1
  `  이 과는 12신이 본궁에 복(伏)해 있으므로 복음과(伏吟課)이다. 양일은 일간상신을 취하여 초전으로 하고 중․말전은 형(刑)을 취하여 구성한다. 양일은 자임격(自任格)이며 사맹신(四孟神)은 원태격(元胎格)으로 된다.
  복음과는 일명(一名) 일월동궁(日月同宮)이라고 하며 구력(舊曆)의 합삭(合朔)에 해당된다. 초전은 일지의 역마로 되고, 말전은 일간의 역마에 해당된다. 초전은 일간상신(日干上神)이며, 말전의 신(申)은 일지에 돌아와서 일간을 극한다. 이 과(課)에서는 천장(天將)의 길흉을 중용(重用)하여 청룡이 승하면 길로 되고, 사신(蛇神)이 승하면 흉이라고 본다. 말전은 귀살(鬼殺)에 해당되고 백호가 승하여 재앙(災殃)이 있다.
  원태격은 은복(隱伏)하여 아직 발하지 않는 형체이므로 조용히 때를 기다리면 길조가 있다. 초전은 녹마(祿馬)와 사신(蛇神)이 질투를 생하고 중전의 구진은 봉인(封印)으로 되며 현임 관리는 현달한다.
  백법에 말하기를 ｢내가 그에게서 구하는 것은 일간에서 나오고 전지(傳支)에 돌아가는 것을 말한다. 주객(主客)의 마음은 투합하지 않음은 지(支)가 형상(刑上)에 있기 때문이다｣고 하였다. 비전에 말하기를 ｢원태격에서 녹마․청룡․주작을 삼전 중에서 보면 모든 일에 대리(大利)가 있다. 일반 서민(庶民)으로서 구진․백호가 전(傳) 중에 보일 때는 소송사건 또는 환자의 정단이다｣고 하였다.
  복음과에서 역마를 보므로 정(靜) 중에 동조(動兆)가 있다. 그러나 왕록(旺祿)은 내몸이 일간(日干)에 임하는 경우에는 맹동(猛動)은 불길하다. 또 일간에서 지상(支上)을 보아 절(絶)이 되고, 일지 신금(申金)에서 일간상신 인(寅)을 보아 절(絶)로 되는 것 등은 피아(彼我)가 상대적으로 거주 및 기타 물건을 교환하는 거래이다. 직위로 볼 때 대리(代理) 또는 교체(交替)하는 것이 유리하다. 서로 보고 십이운(十二運)이 절(絶)을 만나는 것은 과거의 일이 결절(決絶)되는 것이다. 또는 백법의 결절법(決絶法)도 적용할 수 있다. 일귀는 역행하여 삼전이 청(靑)․주(朱)․후(后)이고, 야귀는 순행하여 삼전이 사(蛇)구(勾)․백(白)이다.`
  ,
  // 2
  `  이 과는 제1과․제2과의 하에서 상을 적한다. 금일이 양일이면 양비(陽比)의 극(剋)인 제2과의 자축(子丑)을 취하여 초전으로 하는 지일과(知一課)이다. 삼전은 자해술(子亥戌)에 퇴여중음격(退茹重陰格)으로 된다. 자(子)는 일양(一陽)을 생하는 것이지만 음(陰) 중의 것이며 해술(亥戌)은 음경(陰境)에 들어감으로써 음기(陰氣)가 겹치므로 이와 같은 격명(格名)이 붙었다. 
  일간 일지상신이 귀신을 지켜 재(財)로 되어 가계(家計)가 풍부하다. 그러나 신(申)에 의해 미(未)는 묘(墓)로 된다. 일지 금(金)은 간상의 축(丑)을 보면 역시 묘로 되므로 피아(彼我)가 몽매(夢昧)의 징조가 있다. 지일과(知一課)가 동하는 것은 양기(兩岐)로 되거나 사건이 2개 있어서 미혹된다. 그러나 일간에 따라서는 능히 전일(專一)로 되므로 소송은 화해가 좋고, 어떤 일도 함께 모사(謀事)하는 것이 유익하다. 찾는 사람이나 실물(失物)은 가까운 곳을 찾아야 하며, 매매 사업은 어느 것이나 공동 경영하는 것이 유리하다. 어떤 일도 소원(疏遠)을 버리고 친근을 취해야 한다. 동류(同類)와 화합하여 모이는 것은 길하다. 사과상신(四課上神)은 자축(子丑)과 오미(午未)는 지합하고, 축미(丑未)와 자오(子午)는 충(冲)하므로 오(午)와 축(丑), 자(子)와 미(未)는 육해(六害)로 되어 화합 중에 간사(奸私)․불화․동요․의혹 등의 사건을 초래하기 쉬우니 신중히 행동하는 것이 좋다.
  백법에 말하기를 ｢일귀가 간지에 가해져 두 사람에게서 재정상의 원조를 받으므로 모든 일을 협합(協合)․타협(妥協)하면 호조(好調)로 된다. 삼전의 부모효(父母爻)는 인택이 풍부하더라도 자손의 일은 불길하다. 삼전의 중음(重陰)은 야행(夜行)의 상(象)이므로 위험한 장소에 접근하지 말아야 한다｣고 하였다. 일귀는 역행하여 삼전이 백(白)․상(常)․현(玄)이고, 야귀는 순행하여 후(后)․음(陰)․현(玄)이다. 말전 술(戌)은 일야(日夜)와 함께 현무가 승하고 술(戌)의 재신(財神)이 현무를 승하여 재(財)의 분실 및 도난에 주의해야 한다.`
  ,
  // 3
  `  이 과는 제2과․제3과가 상에서 하를 극한다. 맹신(孟神)을 취하여 초전으로 하고 섭해과(涉害課)로 한다. 제3국은 역간전격(逆間傳格)이고, 오진인(午辰寅)일 때는 고조격(顧祖格)이고, 귀신이 卯酉에 임하는 것은 여덕격(勵德格)이다. 일간에서 3과는 탈설(脫泄)하여 초전으로 되고, 탈상공망(脫上空亡)한다. 섭해격(涉害格)은 모든 일이 오래 계속되어 지체되고 진퇴를 결정하기 어렵다. 
  고조격은 복구(復舊)가 있으며, 역간전(逆間傳)의 공망은 퇴(退)하면 불리하고 진(進)하면 유익하다. 즉 공망을 만나서 역간은 진간으로 변화하기 때문이다. 백법에 말하기를 ｢일간 갑(甲)에서 자(子)는 목욕(沐浴)으로 되고 신금(申金)에서 오(午)는 목욕으로 된다｣고 하였다. 이와 같은 패세(敗勢)라고 하여 패퇴(敗頹)를 다시르며, 답각(踏脚)의 공망은 진(進)하는 것이 길하다.
  일귀는 역행하여 삼전이 사(蛇)․합(合)․청(靑)이고, 청으로 화하여 장래는 공명복기(功名復起)한다. 야귀는 순행하여 삼전이 청․합․사이고, 청은 사(蛇)로 화한다. 말전이 일덕(日德)․일록(日錄)으로 되어 후에 길조를 얻는다.`
  ,
  // 4
  `  이 과는 제3과 사신(巳申)의 극을 취하여 원수과(元首課)로 된다. 삼전은 맹신(孟神)만이 원태격(元胎格)을 얻는다. 초전은 일지상신이며 말전은 간상(干上)에 돌아온다. 그가 와서 나에게 구하는 전이다. 그러나 수생목(水生木)으로 나를 생하므로 나중에는 이익을 얻는다. 중전의 인(寅)은 역마이고 일간의 덕록으로서 활동하면 유익하다. 순미(旬尾)가 일지상에 가하여 초전을 구성하는 것은 폐구격(閉口格)으로 언어(言語)를 힘써서 삼가야 한다.
  원수과로서 사과삼전이 모두 사맹신(四孟神)이다. 존장은 아랫사람을 제어하여 모든 일이 순리롭고 만사는 광명정대하며 암매(暗昧)한 일은 없어진다. 관(官)에 취직할 때는 구진봉인(句陣棒印)이 되고 사(蛇)는 뿔을 생하여 희경(喜慶)이 있다. 상인(常人)은 오히려 투쟁의 일 또는 관재(官災)나 화(火)의 비난이 있다. 간(干)과 지(支)는 상하가 지합하고 각자는 장생(長生)으로 되어 기쁨이 된다. 그러나 천반․지반은 충극(冲剋)하고 교차(交車)는 육해로 되고 기쁜 가운데 우려를 낳는다.
  백법에 말하기를 ｢상하가 모두 합하는 것은 피아(彼我)가 서로 마음이 화해하지만 교차육해는 시의(猜疑)를 뱔생한다｣고 하였다. 폐구격은 사물의 이도(二途)를 추리한다. 또 비밀을 지켜 그 기관을 측정하는 일이 불가능하다. 주작이 승하므로 소송사는 이치에 막혀 오히려 전진할 수 없다. 일간은 사(巳)에 탈기하고 일지는 간상에 탈기한다. 상생융화(相生融和) 중에 서로 소모가 있다.
  일귀는 역행하여 삼전이 주(朱)․청(靑)․상(常)이고, 야귀는 구(勾)․사(蛇)․음(陰)이다. 일귀는 말전 태상이 해(亥)의 장생(長生)에 승하여 혼사는 유리한 격이다.`
  ,
  // 5
  `  이 과는 제1과와 제4과가 함께 하에서 상을 극하고 비용(比用)을 취하지 않으므로 섭해과(涉害課)이다. 지반의 사맹신(四孟神)의 극인 제1과를 취하여 초전으로 하고, 인오술(寅午戌)의 삼합염상격(三合炎上格)이다.
  섭해과로서 염상(炎上)의 화(火)는 사물이 늦어서 허(虛)가 많고 실의(實意)가 적다. 먼저 합하고 후에 산(散)한다. 목일화국(木日火局)을 보고 설기(泄氣)하며, 일지상신 진(辰)은 신(申)에 가하여 역마의 상에 승한다. 백법에 말하기를 ｢초전의 술(戌)은 일간의 재(財)로 되고 술(戌)에는 일야(日夜)가 함께 육합(六合)의 목장(木將)이 승하여 협극(夾剋)으로 된다｣고 하였다. 재(財)는 자기의 자유를 얻지 못하는 재(財)이다. 중․말전의 공망은 전(傳)에 행하지 않으므로 초전의 일신(一神)에서 길흉을 구해야 한다. 초전에 육합이 승하고, 말전에 천후(天后)가 승하면 교동격(狡童格)으로서 남자가 여자를 유혹하는 상으로서 정의가 투합한다. 일녀격(泆女格)은 여명에서 남자를 유혹한다. 교동격은 남자가 여자를 유혹하여 자유연애 결혼을 이룬다.
  말전이 둔간(遁干)하여 경(庚)의 암귀(暗鬼)로 되면 화지(火地)에 앉아서 스스로 제어하고 또 공망하므로 흉해는 극히 경미하게 된다. 초․중전이 공망하고 초전 술(戌)의 재신(財神) 일위(一位)로써 길흉을 정단한다. 둔간하여 병(丙)의 식신(食神)이 되며, 재(財)는 남방에 접하여 서방으로부터 올 수 있다. 일귀는 순행하여 삼전이 합(合)․후(后)․백(白)이고, 야귀는 역행하여 삼전이 합(合)․백(白)․후(后)이다.`
  ,
  // 6
  `  이 과는 4과 중에 3과까지 극이 있고 양일이 양비(陽比)의 극을 취하여 제4과 술묘(戌卯)를 초전으로 하는 지일과(知一課)이다. 초․중전에 술사(戌巳)가 있어 주인격(鑄印格)이며, 일간일지가 신유(申酉)․인묘(寅卯)로 되면 나망(羅網)이라고 한다. 그러나 서로 교왕(交旺)을 만나는 것은 왕(旺)이 와서 나에게 맹동하지 않으며 점차 전진하여 길조를 얻는다. 지일과(知一課)는 모든 일에서 동류는 양기(兩岐)에서 하나로 귀일한다.
  백법에 말하기를 ｢호왕격(互旺格)은 앉아서 모사(謀事)하는 것이 길하다｣고 하였다. 천지반은 묘유(卯酉)와 인신(寅申)의 충(冲)이 있다. 음양신은 각각 지합(支合)이 있다. 음양신이 각각 지합(支合)하고 내외(內外)가 분쟁이 있어도 피아가 함께 평화롭다. 특히 호왕(互旺)은 피아가 흥성하여 서로 교섭사(交涉事)는 초전의 재동(財動)에 의해 서로 이익을 얻는다. 일귀는 순행하여 삼전이 합(合)․음(陰)․청(靑)이고, 야귀는 역행하여 삼전이 합(合)․상(常)․사(蛇)이다. 초전은 일야(日夜)가 함께 육합(六合)이 승하면 호왕격 중에서 가장 양호하다.

`
  ,
  // 7
  `  이 과는 천지 십이신이 대충(對冲)에 있으므로 반음과(返吟課)로 된다. 하에서 상을 적하는 인신(寅申)을 초전으로 한다. 일간기궁(日干寄宮)은 지상(支上)에 와서 하에서 극되고 일지는 간상에 가하여 일간의 내몸은 극을 당하므로 왕래(往來)가 모두 동하여 재액(災厄)을 만난다. 사과삼전 인신(寅申)만은 팔호(八虎)라고 칭하며, 왕래하여 충돌하고 파란이 있어 구제하기 어렵다. 조용히 옆에서 보고 대기(待機)의 자세를 취해야 한다. 간지(干支)가 모두 절지(絶地)에 임하여 용신(龍神)이 승하면 해구(解救)할 수 있다.
  백법에 말하기를 ｢건록이 지상에 임하면 권섭부정(權攝不正)의 일이 발생한다｣고 하였다. 제100법칙의 흉재․결절(結絶)․해액(解厄)의 조건에 해당한다. 즉 새로운 병은 치료되고 오랜 병은 치료되지 않는다. 기타의 재액은 이것으로 종결(終結)한다. 이 과는 4과로 되어도 2과밖에 없으며 정단 사건은 절반만 짐작하여 얻을 뿐이다.
  일귀는 순행하여 삼전이 백(白)․사(蛇)․백(白)이고, 야귀는 역행하여 삼전이 후(后)․청(靑)․후(后)이다. 난수격(亂首格)은 아랫사람이 존장을 침능하고 또 위명(僞名) 또는 타종(他宗)․타문(他門)을 모욕하며, 소인(小人)은 관재(官災)를 만난다. 경(庚)의 암귀(暗鬼)는 절지(絶地)에 임하므로 흉사는 결절(結絶)한다.
`
  ,
  // 8
  `  이 과는 제1과․제2과의 하에서 상을 적한다. 양일이면 양비(陽比)의 극인 자미(子未)를 취하여 초전으로 하고 지일비용과(知一比用課)이다. 일간 일지상신에 묘신이 복(伏)하여 혼미의 상이다.
  일간상신의 처재(妻財)는 공망하고 제2과의 자(子)를 초전으로 하는 것은 외부의 요건(要件)이다. 자미(子未)․축오(丑午)가 상해(相害)로 되고 또 자축(子丑)․오미(午未)의 지합(支合)으로 합(合)은 은혜이며 해(害)는 원수이다. 따라서 은혜 가운데 해를 초래한다. 양 귀인이 간상과 지상에 있어 사람은 통달하고 택(宅)은 흥성하고 변화한다. 일간상신의 미(未)는 공망하면 공망을 벗어나 길로 변화한다.
  묘신의 공망은 암(暗)에서 광명을 생하고 후에 복으로 변화한다. 초전의 인수(印綬)는 공망의 지(地)에 앉아 기쁜 일은 공허로 되기 쉽다. 그러나 구류술업(九流術業) 또는 고독한 사람은 길조를 얻는다.
  백법에 말하기를 ｢일간 일지상신에 묘(墓)가 승하면 각각 혼미하고, 귀신이 육해(六害)로 되면 소송은 정직하여도 곡단(曲斷)을 만난다｣고 하였다. 일귀는 순행하여 삼전이 청(靑)․음(陰)․합(合)이며, 야귀는 역행하여 삼전이 사(蛇)․상(常)․합(合)이다. 비전에 말하기를 ｢초전은 공망에 천공이 승하면 모든 일이 공론(空論)․공화(空話)이며 실현(實現)이 없다｣고 하였다.
`
  ,
  // 9
  `  이 과는 제4과의 1상이 1하를 극하여 원수과로 되고 진자(辰子)를 초전으로 한다. 중․말전은 신자(申子)이므로 삼합수국(三合水局) 윤하격(潤下格)으로 된다. 제4과가 초전으로 되는 것은 맥월격(驀越格)이고, 귀신이 묘유(卯酉)에 임하는 것은 여덕격(勵德格)이며, 순미(旬尾)의 진(辰)이 초전으로 되는 것은 폐구격(閉口格)이 된다.
  삼전은 수국(水局)으로 되어 일간을 생하고 일지 신금(申金)은 금생수(金生水)로 탈기(脫氣)하므로 사람은 왕성하여도 가택은 좁다. 내쪽의 일은 성공하여도 상대방은 패한다. 일간에서 상신 오(午)에 목생화(木生火)로 탈기하고, 공망으로 되어 공탈격(空脫格)으로 된다. 나의 기(氣)를 설(泄)하여 허로(虛勞)되면 삼전의 수신(水神)은 오화(午火)를 제어하고 공탈(空脫)의 재(災)는 해소된다. 원수과로서 윤하격은 물이 흘러서 막힘이 없고 만사는 순조롭다. 상이 하를 극하는 것은 순(順)이며 광명정대하다. 처음은 불길하나 후에는 동하여 길하게 된다.
  천강이 제4과를 초전으로 하는 것은 맥월격(驀越格)이고 사물의 변화가 있다. 맥월격은 모든 일이 늦다. 그러나 우발(偶發)적으로 결정하기 쉽다. 삼전 윤하(潤下)의 수(水)는 지자(智者)가 수(水)를 즐기는 상이다. 덕을 베풀고 자혜(慈惠)의 마음으로 대하면 사람도 물체도 협조하여 길행을 얻는다. 백법에 말하기를 ｢신(申)에서 오(午)는 십이운이 사(死)이고, 일지(日支)의 신금(申金)에서 자(子)는 사(死)로 되어 사람도 택도 모두 사신(死神)이 나타나서 만사는 쇠퇴한다｣고 하였다. 삼전이 체생(遞生)하면 타인의 추천을 받는다. 비전에 말하기를 ｢초전에 일야귀(日夜鬼)와 함께 현무가 진(辰)에 승하고 삼합수국(三合水局)하는 것은 집단 도적을 만날 우려가 있다｣고 하였다.
  일귀는 순행하여 삼전이 현(玄)․사(蛇)․청(靑)이고, 야귀는 역행하여 삼전이 현(玄)․청(靑)․사(蛇)이다. 초전의 현무가 재(財)에 승하여 재의 실재(失財) 또는 도난이 있으므로 주의해야 한다.`
  ,
  // 10
  `  이 과는 4과 중에 제2과의 하에서 상을 적하므로 신사(申巳)를 초전으로 하는 중심원태격(重審元胎格)이다.
  천지(天地)는 상충(相冲)하고 일간일지는 교차지합(交車支合)하여 서로 충극(冲剋)되는 동시에 생합(生合)되어 흉 중에 길이 있고 길 중에 흉이 있다고 한다. 귀신이 진술(辰戌)에 임하면 귀신이 옥(獄)에 들어간다고 하며, 웃어른이나 귀인은 노하여 원조하지 않으며, 초전의 신(申)은 귀(鬼)로 되어도 일간상신은 제어하여 구신(救神)으로 된다. 말전은 일지의 역마로 되어 길(吉)이 있고 흉이 있으며 길흉이 서로 절반된다. 어떤 일에 관계하더라도 능히 조사한 후에 실행에 옮겨야 하며 맹동은 신중해야 할 과격(課格)이다.
  백법에 말하기를 ｢인(寅)은 둔간하여 경(庚)의 귀(鬼)로 되고 일귀는 백호에 승하여 재액(災厄)이 얕지 않다｣고 하였다. 피아가 시의(猜疑)하는 것은 육해를 보기 때문이다. 순수(旬首)의 신(申)의 초전은 순의(旬儀)로 되고, 흉해를 구제한다. 비전에 말하기를 ｢관직의 정단은 초전의 신(申)은 사(巳)에 임하고 사(巳)는 쌍환(雙奐)이라고 부르며 겸직(兼職)한다｣고 하였다. 중전 해(亥)의 장생은 택상(宅上)에 임하여 상관(上官)에서 성원을 받으며, 말전의 인(寅)이 기궁(寄宮)과 같이 해(亥)에 임하면 장생 학당(學堂)으로 되어 직위가 승진된다. 일간은 신(申)에서 해(亥)는 장생에 해당되고 신(申)에서 사(巳)를 보면 장생으로 된다. 처음에는 불평과 알력이 있어도 후에는 반드시 피아가 유익하게 된다. 일귀는 순행하여 삼전이 사(蛇)․구(勾)․백(白)이고, 야귀는 역행하여 삼전이 청(靑)․주(朱)․후(后)이고 공명은 정단하여 길조이다.`
  ,
  // 11
  `  이 과는 제1과․제4과에 극이 있고 비용(比用)을 취하지 않으므로 섭해과(涉害課)로 된다. 따라서 지반 맹신의 극을 취하여 제1과의 진인(辰寅)을 초전으로 한다. 삼전 진오신(辰午申)은 등천격(登天格)이라고 한다.
  진(辰)은 용으로 하늘에 오르면 운행시우(運行施雨)하고 작은 것을 변화하여 큰 것으로 하기 때문에 길(吉)하다. 다만 병점(病占) 및 관소(官訴)는 불길하다. 중․말전은 공망하고 먼 곳에서 이동하여 가까운 곳에 옮긴다.
  괴강(魁罡)이 일간 일지상신에 가해져 초전으로 되면 참관격(斬關格)이다. 진(辰)에 육합이 승하는 것은 진정한 참관(斬關)이다. 진(辰)은 동조(動兆)로 되어도 중․말전의 공망으로 변화하여 동(動) 중에 가끔 휴식한다. 먼 곳은 효과가 없고 가까운 것을 취해야 한다. 섭해과는 안전을 얻지 못하며 진퇴(進退)는 간난을 겪은 후에 길행으로 된다. 초전은 일간에서 극하여 재(財)로 되고, 육합의 목장(木將)은 진(辰)을 구한다. 협극(夾剋)으로 되는 것은 재(財)가 자유를 얻지 못한다. 백법에 말하기를 ｢강색귀호(罡塞貴戶)는 모사(謀事)에 맡긴다｣고 하였다. 52법칙에 말하기를 ｢진(辰)은 천강(天罡)이고 인(寅)은 귀호(鬼戶)라고 한다. 진(辰)이 인(寅)에 가해져 어디에 있어도 이 격을 취하고 재해를 피할 수 있으며 중귀(衆鬼)에게 침해당하지 않는다｣고 하였다.
  삼기(三奇)에 천을(天乙)을 병하면 관대(寬大)의 아래에 임의(任意)로 모사(謀事)를 시험하여 효과가 있다. 묘신(墓神)이 재(財)로 되면 여행하여 체(滯)가 생한다. 일귀는 역행하여 삼전이 합(合)․사(蛇)․후(后)이고, 야귀는 순행하여 삼전은 합(合)․청(靑)․백(白)이다. 이 과는 귀신이 해(亥)에 임하고 진술축미(辰戌丑未)는 사맹(四孟)에 가하여 신장살몰(神藏殺沒)로 된다. 삼전에 흉장이 승하여도 더욱 행해가 없다.`
  ,
  // 12
  `  제2과의 1하에서 1상을 극하여 중심진여과(重審進茹課)이다. 진여(進茹)가 공망하여 퇴여(退茹)로 변화하고 망진(妄進)하면 재해를 초래한다. 일간의 갑목(甲木)은 묘(卯)에 왕하고 일지 신(申)은 유(酉)에 왕하여 각각 스스로 양인(羊刃)으로 된다. 왕성한 것은 자기를 지켜 길로 된다. 그러나 일간일지는 교차상극(交車相剋)하고 교역․교섭 등은 불화하여 투쟁으로 된다. 사물이 복구(復舊)한 건은 고생한 후에 길조로 된다.
  백법에 말하기를 ｢함께 왕성한 것은 조용히 계획하면 길하다. 진사오(辰巳午)의 진여(進茹)는 승계(昇階)라고 이름을 붙인다｣고 하였다. 진사(辰巳)에서 오(午)의 정양(正陽)에 도달한다. 그러나 오(午)는 공망하여 공곡전성(空谷傳聲)과 같다. 지킬 때는 전진하는 것은 불길하므로 어떤 일도 분수를 지켜야 한다. 만약 맹동할 때는 왕신(旺神)이 변하여 나망(羅網)이 몸에 감기고 고심(苦心)한다. 일귀는 역행하여 삼전이 합(合)․주(朱)․사(蛇)이고, 야귀는 순행하여 삼전이 합(合)․구(勾)․청(靑)으로 된다. 청룡의 길장은 공망하므로 길이 다소 경하다.`
  ,
]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
