
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,8,11,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,9,10,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,9,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,8,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,7,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,6,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`신뢰`,2,5,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,4,`특병있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,3,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,6,2,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,1,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,12,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`색정`,`외남 색정사 有`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`12`,`색정사有`,`허성불성`],
    [`매사 영속성없음`,`형재친구동료금전과출사는 선천적 기복재해를 겪음`,`大`,`과시기`,`自來능욕`,`과출수난`],
    [`인풍택협(人豊宅狹)`,`식구는 많은데 집이 좁은 상`,`大`,``,`1녀2남`,`事後쟁탈`],
    [`반화위복(反禍爲福)`,`화가 흉을 이룰수 없게 되니 반대로 복이 오게 되는 상 `,`小`,``,`삼각관계`,``],
    [`관사`,`관사 관련사`,`小`,``,``,`송쟁극처`],
    [`직업`,`외 직업 내 남자 관련사`,`大`,`11`,`관사직업`,`도적失勢`],
    [`송쟁`,`내사 대립송쟁파재극처사`,`小`,`쇠퇴기`,`事後쟁탈`,`女가男을`],
    [`대리혼인(大利婚姻)`,`큰 이익이 있는 혼인이 되는 상`,`小`,``,`상업大利`,`적극유혹`],
    [`탄식신음(嘆息呻吟)`,`병 또는 주력사 미수로 신음하며 탄식하는 상`,`大`,``,`함`,``],
    [`박관`,`직업직위 불안사`,`大`,``,`손실도망`,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`면모훼손`,`남편갈등`],
    [`자식`,`자시 관련사`,`小`,`침해기`,`애증화합`,`실업觀書`],
    [`대재획득(大財獲得)`,`큰 돈이 돈이 들어오게 되는 상`,`大`,``,`大財획득`,`한가함`],
    [`천발도약(薦拔跳躍)`,`천거발탁되어 도약하는 상`,`小`,``,`재앙방비`,``],
    [`결속`,`결속사로 財기반을 크게 세우나 명예직위권리남편 훼절됨`,`大`,``,``,``],
    [`극남`,`남자 갈등사`,`小`,`9`,`직위불안`,`색정사有`],
    [`색정`,`내녀 색정사 有`,`小`,`소외기`,`고진감래`,`남편훼탈`],
    [`상길관기(常吉官忌)`,`일반인은 좋고 관직자는 나쁜 상`,`大`,``,``,``],
    [`미초희경(微招喜慶)`,`고위로부터 술잔을 내려받고 기쁨을 애써 감추는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`폐한`,`외사 침체 소외 驚懼사-음1,2,7,8,월 방문자는 묘지사`,`大`,`8`,`폐한침체`,`동요불안`],
    [`동요`,`내사 동요불안분산사`,`小`,`침체기`,`兩事침해`,`이사意思`],
    [`환무생계(環無生計)`,`생계를 돌불 길이 막힌 상-집안 어른의 병이 있음`,`小`,``,``,``],
    [`용두사미(龍頭蛇尾)`,`어떤 일도 시작은 있으나 결과에 실익이 없는 상`,`大`,``,``,``],
    [`금전`,`금전사업이권妻 관련사`,`大`,``,``,``],
    [`출행`,`외사 출행이동 기로사`,`大`,`7`,`출행원행`,`침체절감`],
    [`동요`,`외사 동요불안분산사`,`小`,`단절기`,`동요기로`,`면모훼손`],
    [`구재반화(求財反禍)`,`옳지 못한 돈을 애써 구하면 반대로 화를 부르는 격의 상`,`小`,``,`반복不定`,`비곡수욕`],
    [`와병불기(臥病不起)`,`병이 깊어 자리를 털고 일어날 수 없는 상`,`小`,``,`移轉이동`,`(毁節사)`],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`사업관련사`,`大`,`6`,`금전여인`,`외화내허`],
    [`여인`,`여인 관련사`,`小`,`존재기`,`손실손해`,`단합결속`],
    [`가도광윤(家道廣閏)`,`가세가 두루 윤택하니 주인은늘 이롭고 객은 나쁜 상`,`小`,``,`흉화길조`,`가족질병`],
    [`비곡수욕(悲哭羞辱)`,`화장이 지워져 슬피우는 상-女는 훼절-男은 소외`,`小`,``,`(半減)`,`(가택移要)`],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`결속`,`결속사로 財기반을 크게 세우나 명예직위권리남편 훼절됨`,`大`,`5`,`직위불안`,`송쟁극처`],
    [`송쟁`,`내사대립송쟁파재극처사`,`小`,`배양기`,`관계불통`,`閑暇心思`],
    [`권리상실(權利傷失)`,`결속하여 타협사는 좋으나 명예직위남편사가 훼절되는 상 `,`小`,``,`가족질병`,`교섭사成`],
    [`사송계류(詞訟稽留)`,`왕래 중 사건으로 송사가 벌어져 계류중인 상`,`大`,``,``,``],
    [`후원`,`후원조력문서부친 관련사`,`小`,``,``,``],
    [`급속`,`외사 급속사`,`小`,`4`,`급속사有`,`출행원행`],
    [`출행`,`내사 출행원행사`,`大`,`개시기`,`증오도망`,`尊卑상몽`],
    [`중귀난침(衆鬼難侵)`,`일에 방해 침해사가 많아도 감히 제압시켜 무난한 상`,`小`,``,`원지財來`,`(신분나이`],
    [`진화지상(進化之象)`,`재난이 소실되어 면모가 크게 진화되는 상`,`大`,``,``,`차이동침)`],
    [`후원`,`후원조력 모친 관련사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`3`,``,``],
    [`가출`,`내사 손해가출사`,`大`,`학습기`,`후원수난`,`손해가출`],
    [`허성무실(虛聲無實)`,`허성허사가 많아 실익이 적고 가정내 화환이 발생하는 상 `,`小`,``,`외래喪事`,`비곡수욕`],
    [`음신조격(音信阻隔)`,`소식을 기다리나 오지 않는 상-와도 나쁨`,`大`,``,``,`(훼절)`],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`2`,`폐한驚懼`,`침체막힘`],
    [`막힘`,`외사 폐한침체驚懼사- 내사 막힘사`,`小`,`면모기`,`전화위복`,`1남2녀`],
    [`골육난수(骨肉難首)`,`골육간 위계가 없어 상하가 어지러운 상`,`小`,``,``,`삼각관계`],
    [`간인궤계(奸人詭計)`,`간사한 자가 꾸민 거짓에 수난을 겪는 상`,`大`,``,``,``],
    [`명예`,`수상(受賞)사`,`小`,``,``,``],
    [`직녹`,`직위 녹위 관련사-외교 막힘`,`小`,`1`,`외교막힘`,`급속사有`],
    [`급속`,`내사 급속사`,`大`,`절정기`,`병송발생`,`표은남산`],
    [`병송구외(病訟俱畏)`,`병과 송쟁사가 두려운데 어찌 전진하려고만 하는가-의 상`,`大`,``,`관직길조`,`(산속으로`],
    [`간인궤계(奸人詭計)`,`간사한 자가 꾸민 거짓에 수난을 겪는 상`,`小`,``,``,`숨고싶음)`]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안남자갈등내녀색정여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`관사직업남편침체소외동요불안분산사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,"금전사업처조력출행사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,"금전사업여인조력출행사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`관사직업남자침체가정송쟁대립사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,"후원문서부친조력급속출행사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"후원문서모친조력급속출행사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사직업외내막힘사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,"명예직녹자산급속사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결처리 해야만 유리함`,0,0,`명분이 있는데도 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 人`,0,0,`일이 역으로 진행됨**가정의 위계가 없어 어지러운 상**1녀2남 삼각관계 有**여건조건이 불비부족함**산속으로 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결처리 해야만 유리함`,0,0,`명분이 있는데도 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 人`,0,0,`옛일을 복구하려 함**여자가 남자를 적극 유혹하여 함께 도망칠 각오까지 하고 있음**관직자 대길함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결처리 해야만 유리함`,0,0,`명분이 있는데도 좌고우면하거나 뒤로 밀면 결과가 되려 불리해지는 人`,0,0,`신규사가 있음**생업부속점 이전함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 일을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 복잡다단한 일을 결속시켜 재원의 토대를 세우려함-단, 명예지위권리사는 훼절됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서 시비를 말고 순응유화적 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 유순유화적 자세로 대처하고 지체 연기시켜야만 반드시 결과가 유리해지는 人`,0,0,`정대한 엣일은 모두 끓어짐**원지에서 송금함**부인이 남편을 배반 사통하는 조짐 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 잇고 천지가 뒤집힌 상이므로 화가 밖에서 오니 신중해야 함`,0,0,`만사 동요하고 배반사로 기로에 놓여있고 급속 원행이동사가 있는 人`,0,0,`신규사가 있음**생업이전함**주력사가 결과없이 반복되고 역시 어두움`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 일을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`산속으로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서 시비를 말고 순응유화적 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 유순유화적 자세로 대처하고 지체 연기시켜야만 반드시 결과가 유리해지는 人`,0,0,`여러 복잡다단한 일을 결속시켜 재원의 토대를 세우려함-단, 명예지위권리사는 훼절됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위 세(狐假虎威 勢)**만약 타인과 다툰다면 상대는 호가호위의 세를 부릴 뿐 공격의사 없음`,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란 듯한 심중이나 조용히 있으면 놀라는 사안이 자연 사라지게 되는 人`,0,0,`토지가옥관련 대립사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서 시비를 말고 순응유화적 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 유순유화적 자세로 대처하고 지체 연기시켜야만 반드시 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**일이 결과없이 반복되었으나 비로소 밝아짐**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서 시비를 말고 순응유화적 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 유순유화적 자세로 대처하고 지체 연기시켜야만 반드시 결과가 유리해지는 人`,0,0,`기이한 전화위복사가 있음**가정위계질사가 없어 어지러움**주력사가 순서적인 듯하나 퇴함이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻은 있으나 공격하지는 않음`,0,0,`스스로 자임하며 옛것을 지키고 새 기회를 기다리는 人`,0,0,`산으로 깊이 숨고싶은 심정임**일이 결과없이 반복되나 비로소 밝아짐**전화위복사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`펴면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면-무력`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`배신-무력`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사후회사겹침`,`미사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠공-허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`애증화해`,`애증화해`,`애증화해`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,` 승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,3,3,6,`음10월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월`,2,2,4,`음9월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음2월`,3,2,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월-파혼우려`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음10월`,3,1,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음8월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5월`,3,2,5,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음4월`,2,1,3,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월`,2,1,3,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초반에 침체하고 위계질서를 어지럽히며 불비부족 현상을 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이며 업무가 강하지 못하는 듯하나 별도 부업적 사업으로 큰 이익을 창출함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 신규사를 일으켜 실적을 쌓음`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 타인과 같은 소리를 내며 여러 일을 일관시키는 결속을 이루어 재정의 기반을 구축하나 명예권리를 훼손함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 초반의 실적 외 중 종반이 허무하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 타인과 같은 소리를 내며 업무를 동요불안배신사로 점철함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이며 업무가 강하지 못하여 업무의 고초를 겪고 산으로 깊숙히 은거하고 싶은 심정이 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성품이 둔한 듯해도 조정능력이 있어 여러 복잡한 일을 결속시켜 재정의 토대를 세우나 명예 지위가 훼절됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조금하여  업무 초반이 허무하고 중 종반을 반목대립송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무전반에 걸쳐 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 권리를 양도하고 초반에 권리가 상실된 실적을 보이나 업무전반에 걸쳐 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선이후난`,`退背`,`원`,`去`,`세입조력`,`정서`,``,`정남`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선이후費`,`進向`,`  원`,`  留`,`세입조력`,`남남서`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`불이행`,`선난후이`,`進向`,`원근왕래`,`  留`,`상호반목`,`정남`,``,`남남동`],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선攻후이`,`退背`,`  근`,`  去`,`주인조력`,`정서`,`정남`,``],
    [`10.11`,``,`다중제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선攻후費`,`進向`,`  근`,`  留`,`주인조력`,`북서`,`남남서`,``],
    [`10.11`,``,`내부동조`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`발현안됨`,`불이행`,`선난후난`,`退背`,`  원`,`  去`,`상호동요`,`북북서`,`서남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`주인가해`,`정동`,`정서`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`분인합세`,`불이행`,`선난후費`,`退背`,`  원`,`  去`,`주인가해`,`동남`,`남남동`,``],
    [`10.11`,``,`다중제압`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`사안무력`,`退背`,`  근`,`  去`,`주인무력`,`남남동`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`종반반대`,`불이행`,`사안본인`,`退背`,`  근`,`  去`,`주인무력`,`정동`,``,`정동`],
    [`10.11`,`  集`,`제압불가`,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`세력없음`,`불이행`,`본인무력`,`退背`,`  근`,`  去`,`세입가해`,`남남서`,``,`동남`],
    [`10.11`,` 多集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`제압불가`,`불이행`,`선난후난`,`進向`,`  근`,`  留`,`무난`,`서남`,``,`남남동`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,`전흉사`,`유혈사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,`남남서`,`없음`],
    [``,``,``,0,0,`묘향`,`북북동`,``],
    [`생업`,` 음귀`,`묘지탈`,0,0,`전경`,`공동香臺`,``],
    [``,``,``,0,0,`묘속`,`泥土입출`,``],
    [`가정`,``,``,0,0,`육친`,` 조모`,``],
    [`질병`,`제례귀`,`제례위법`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`제례귀`,`제례위법`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"女姑부정","사망媤母",`육친`,``,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`여귀`,`가장부인`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,``,`특별있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`도로귀`,`전사귀`,0,0,`묘좌`,``,`있음`],
    [``,`혈광사귀`,`유혈사망`,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,`화장穴`,``],
    [`가정`,`음귀`,`화장穴`,0,0,`육친`,``,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,`제례귀`,`제례위법`,0,0,`묘좌`,`북북서`,`있음`],
    [``,``,``,0,0,`묘향`,`남남동`,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,`공동향대`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,``,``,0,0,`육친`,`증조모`,``],
    [`질병`,`전흉사귀`,`유혈사망`,0,0,`묘좌`,`북서`,`없음`],
    [``,`음귀`,`묘지탈`,0,0,`묘향`,`남동`,``],
    [`생업`,``,``,0,0,`전경`,`공동향대`,``],
    [``,``,``,0,0,`묘속`,`泥土출입`,``],
    [`가정`,`전흉사귀`,`유혈사망`,`상가부정`,`조문왕래`,`육친`,`조부`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`명예직녹자산사-외사 급속사-외교막힘`,`간인궤계사`,`매사허성불성-세속허사자`,`음7,8,10,11월방문자는 종교진출하면성공함`,`매사 영속성없음`,`종교진출하면자립함`,`외사 애증화합-실종상해사 우려`,`환성경사`,`내녀 색정사`,`자식위한문서상신답신지체사-구설사`,`매사 위역사**가정동요분산사`,`재난전소사`,`외사 출행원행기로사`,`발탁도약사-이동사`,``,`탄식신음사-병 또는 주력사 미수 `,`가정송쟁파재극처사`,`觀書-한가함`,`외사 급속사-내 사 출행이동사`,`도적실족실세-잡음`,`가정손해가출사`,`事後쟁탈사`,`매사 위역사**내부가정막힘`,`반화위복사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁`,`비상지애사**외남 색정사`,`전도`,`남모르는능욕박해사**가정송쟁사`,`빈궁`,`재액해소**전화위복`,``,`재액해소`,`빈궁**관재`,``,`빈궁**관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,``,`빈궁**관재`,``,`외사 증오 도망사`,`빈궁**병재`,``,``,`침체驚懼사`,`관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**내부가정막힘`,`반화위복사`,`명예직녹자산사-외사 급속사-외교막힘`,`간인궤계사`,`매사 영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외사 애증화합-실종상해사 우려`,`환성경사`,`내녀 색정사`,`자식위한상신답신지체사-구설사`,`매사 위역사**가정동요분산사`,`재난전소사`,`외사 출행원행기로사`,`발탁도약사-이동사`,``,`탄식신음사-병 또는 주력사 미수`,`가정송쟁파재극처사`,`觀書-한가함`,`외사 급속사-내사 출행이동사`,`도적실족실세-잡음`,`가정손해가출사`,`事後쟁탈사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재**병재`,``,`재액해소**전화위복`,`빈궁`,`비상지애사**외남 색정사`,``,`남모르는능욕박해사**가정송쟁사`,`빈궁`,`전화위복`,`관재`,``,`빈궁`,``,`빈궁**관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`관재`,``,`빈궁**관재`,`외사 증오 도망사`,`전도**빈궁**병재`,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`가정손해가출사`,`事後쟁탈사`,`매사 위역사**내부가정막힘`,`반화위복사`,`명예직녹자산사-외사 급속사-외교막힘`,`간인궤계사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`외사 애증화합-실종상해사 우려`,`환성경사`,`내녀 색정사`,`지식위한문서상신답신지체사-구설사`,`매사 위역사**가정동요분산사`,`재난전소사`,`외사 출행원행기로사`,`발탁도약사-이동사`,``,`탄식신음사-병 또는 주력사 미수`,`가정송쟁파재극처사`,`觀書-한가함`,`외사 급속사-내사 출행이동사`,`도적실족실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`빈궁**병재`,``,`재액해소**전화위복`,`빈궁`,`비상지애사**외남 색정사`,``,`남모르는능욕박해사**가정송쟁사`,`빈궁`,`전화위복`,``,``,`빈궁`,``,`빈궁`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`관재`,`빈궁`,``,`외사 증오 도망사`,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`좋은소식사`,`외사 급속사-내사 출행이동사`,`가정손해가출사`,`간인궤계사`,`내부가정막힘`,`소인쟁재사`,`명예직녹자산사-내사급속사-외교막힘`,`견련반복사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**불리 고시사`,`종교진출하면자립함`,`외사 애증화합-실종상해사 우려`,`왕세즉진취-쇠즉퇴사`,`내녀 색정사`,`관계불통-가택이사-가족질병사`,`가정동요분산사`,`비곡수욕사-훼절함`,`외사 출행원행기로사`,`와병불기사-이동사`,``,`도적악의-공격말 것`,`가정송쟁파재극처사`,`고위로부터 술잔내려받는 희경사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁*병재`,`외사 증오 도망사`,``,`빈궁`,``,`관재`,`전화위복`,`전도**빈궁`,`외남 색정사**부부반목사`,`전도`,`가정송쟁사`,`빈궁`,`재액해소**전화위복`,`빈궁`,`재액해소`,`관재`,``,`관재`,`음4,5월화재주의`,`빈궁*관재`,`음4,5월화재주의`,`빈궁`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`가정송쟁파재극처사`,`고위로부터 술잔내려받는 희경사`,`좋은소식사`,`외사 급속사-내사 출행이동사`,`가정손해가출사`,`간인궤계사`,`내부가정막힘`,`소인쟁재사`,`명예직녹자산사-내사급속사-외교막힘`,`견련반복사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**불리 고시사`,`종교진출하면자립함`,`외사 애증화합-실종상해사 우려`,`왕세즉진취-쇠즉퇴사`,`내녀 색정사`,`관계불통-가택이사-가족질병사`,`가정동요분산사`,`비곡수욕사-훼절함`,`외사 출행원행기로사`,`와병불기사-이동사`,``,`도적악의-공격말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**빈궁**병재`,`외사 증오 도망사`,``,`전도**빈궁**관재`,``,`관재`,`재액해소**전화위복`,`빈궁`,`외남 색정사**부부반목사`,``,`가정송쟁사`,`빈궁`,`전화위복`,`빈궁**관재`,``,``,`관재`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`도적악의-공격말 것`,``,`가정송쟁파재극처사`,`고위로부터 술잔내려받는 희경사`,`좋은소식사`,`외사 급속사-내사 출행이동사`,`가정손해가출사`,`간인궤계사`,`내부가정막힘`,`소인쟁재사`,`명예직녹자산사-내사 급속사-외교막힘`,`견련반복사`,`매사 영속성없음**부부반목사`,`종교진출하면자립함`,`매사 영속성없음**불리고시사`,`종교진출하면자립함`,`외사 애증화합-실종상해사 우려`,`왕세즉진취-쇠즉토사`,`내녀 색정사`,`관계불통-가택이사-가족질병사`,`가정동요분산사`,`비곡수욕사-훼절함`,`외사 출행원행기로사`,`와병불기사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁`,`동요분산사`,`관재`,`외사증오도망사**동요분산사`,`전도**빈궁**관재`,`동요분산사`,`관재`,`동요분산사`,`빈궁**관재`,`동요분산사**재액해소-전화위복`,``,`외남 색정사**동요분산사`,`빈궁`,`가정송쟁사**동요분산사`,`빈궁`,`동요분산사**전화위복`,``,`동요분산사`,``,`동요분산사`,`관재`,`음4,5월화재주의**동요분산사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외사 출행원행기로사`,`와병불기사-이동사`,`도적악의-공격말 것`,``,`가정송쟁파재극처사`,`고위로부터 술잔 내려받는 희경사`,`좋은소식사`,`외사 급속사-내사 출행이동사`,`가정손해가출사`,`간인궤계사`,`내부가정막힘`,`소인쟁재사`,`명예직녹자산사-내사 급속사-외교막힘`,`견련반복시`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**불리고시사`,`종교진출하면자립함`,`외사 애증화합-실종상해사 우려`,`왕세즉진취-쇠즉퇴사`,`내녀 색정사`,`관계불통-가택이사-가족질병사`,`가정동요분산사`,`비곡수욕사-훼절함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,`관재`,`외사 증오 도망사`,`빈궁**관재**병재`,``,`빈궁`,``,`관재`,`전화위복`,`전도*빈궁**관재`,`부부반목사**외남 색정사`,`전도`,`가정송쟁사`,`빈궁`,`재액해소**전화위복`,`빈궁`,`재액해소`,``,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`재난전소사`,`매사 위역사**가정동요분산사`,`외사 출행원행기로사`,`사송사 흉-문서소식사 길`,`주력사완수사`,``,`가정송쟁파재극처사`,`왕래사송계류사`,`상린충산사`,`외사 급속사-내사 출행이동사`,`가정손해가출사`,`간인궤계사`,`반화위복사`,`매사 위역사**내부가정막힘`,`명예직녹자산사-내사 급속사-외교막힘`,`상위 기쁨-하위 수도자격`,`매사 영속성없음**도적애놀램-금전근심사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`외사 애증화합-실종살해사 우려`,`실업자 상-한가함`,`내녀 색정사`,`관계불통-가택이사-가족질병사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁**관재`,``,`외사 증오 도망사`,``,`빈궁`,``,`관재**병재`,``,`전화위복`,`전도`,`외남 색정사`,`전도**빈궁`,`尊卑동침사**가정송쟁사`,`빈궁`,`재액해소**전화위복`,`관재`,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`내녀 색정사`,`관계불통-가택이사-가족질병사`,`매사 위역사**가정동요분산사`,`재난전소사`,`외사출행원행기로사`,`사송사 흉-문서소식사 길`,`주력사완수사`,``,`가정송쟁파재극처사`,`왕래사송계류사`,`상린충산사`,`외사 급속사-내사 출행이동사`,`가정손해가출사`,`간인궤계사`,`반화위복사`,`매사 위역사**내부가정막힘`,`명예직녹자산사-내사 급속사-외교막힘`,`상위 기쁨-하위 수도자격`,`매사 영속성없음**도적에 놀램-금전근심사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`외사 애증화합-실종상해사 우려`,`실업저 상-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`빈궁**관재`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁**관재`,``,`외사 증오 도망사`,``,`빈궁`,``,`관재**병재`,``,`전화위복`,`전도`,`외남 색정사`,`전도**빈궁`,`尊卑동침사**가정송쟁사`,`빈궁`,`재액해소**전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사 애증화합-실종상해사 우려`,`도적난포사`,`내녀 색정사`,`사행불량사`,`비곡수용사-훼절함`,`가정동요분산사`,`외사 출행원행기로사`,`발탁도약사-이동사`,``,`장차 위한 불번근신사`,`가정송쟁파재극처사`,`투서헌책사-안좋음-실재사`,`외사 급속사-내사 출행이동사`,`혼인만족-단장사`,`가정손해가출사`,`관재형책사`,`내부가정막힘`,`소인쟁재사`,`명예직녹자산사-내사 급속사-외교막힘`,`간인궤계사`,`매사 영속성없음**소식불통사-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음**승진영전사-부족`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁`,``,`빈궁**관재`,`재액해소`,`관재`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도**빈궁`,`재액해소`,`빈궁**관재`,`외사 증오 도망사`,``,``,`빈궁`,`재액해소`,`관재`,`전화위복`,`빈궁`,`외남 색정사`,`빈궁**병재`,`가정송쟁사**재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자**승진영전사-부족`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`외사 애증화합-실종상해사 우려`,`도적난포사`,`내녀 색정사`,`사행불량사`,`비곡수욕사-훼절함`,`가정동요분산사`,`외사 출행원행기로사`,`발탁도약사-이동사`,``,`장차 위한 불변근신사`,`가정송쟁파재극처사`,`투서헌책사-안좋음-실재사`,`외사 급속사-내사 출행이동사`,`혼인만족-단장사`,`가정손해가출사`,`관재형책사`,`내부가정막힘`,`소인쟁재사`,`명예직녹자산사-내사 급속사-외교막힘`,`간인궤계사`,`매사 영속성없음**서식불통사-와도 나쁨`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`가정송쟁사`,``,`전화위복`,`빈궁**관재`,``,`빈궁`,`재액해소`,`관재`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도**빈궁`,`재액해소`,`빈궁**관재`,`외사 증오 도망사`,``,`빈궁`,``,`재액해소`,`관재`,`빈궁`,`폐한인**전화위복`,`외남 색정사`,`빈궁*병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`외사 애증화합-실종상해사 우려`,`환성경사`,`내녀 색정사`,`자식위한문사상신답신지체사-실재사`,`매사 위역사**가정동요분산사`,`장차 위한 불번근신사`,`외사 출행원행기로사`,`밭탁도약사-이동사`,``,`신음탄식사-병 또는 주력사 미수`,`가정송쟁파재극처사`,`觀書-한가함`,`외사 급속사-내사 출행이동사`,`도작절족실세-잡음`,`가정손해가출사`,`事後쟁탈사`,`매사 위역사**내부가정막힘`,`반화위복사`,`명예직녹자산사-내사 급속사-외교막힘`,`간인궤계사**도작난포사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`비상지애사**외남 색정사`,``,`남모르는능욕박해사**가정송쟁사`,`빈궁`,`전화위복`,``,`빈궁`,``,`재액해소`,`빈궁**관재`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도`,`재액해소`,`빈궁**관재`,`외사 증오 도망사`,`빈궁`,``,``,`재액해소`,`관재**병재`,`빈궁`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 병도 근심이고 소송사도      걱정이겠네? 
  매듭이 없고 끝이 안보이     겠어!
귀신⊕전흉사
  ⊖여귀와 가택상가부정        빌미가 가득차서 그렇네! 
    해소시키면 확 뚫리겠어!
`,`■ 병 때문에 심사가 괴롭겠네?     식구끼리 등돌리고 있겠고…     1女2男 삼각관계까지 있네?
⊕⊖전흉사귀 침범 때문인데,      이걸 잡아야 길이 열리겠어!

⊕신변상가부정 침범과
⊖신변음사부정도 침범했네 ?
`,`■  이미지나버린폐지사를
   복구하게 생겼네?
男：상업으로 큰 이익이있겠어!
女：⊖남의 남자를 내남자로        만들어 살겠네? 
⊕여귀
⊖도로귀와가택상가부정은     잡고 봐야해!
`,`■ 새 일로 돈이 크게 움직     이고 있네?

⊕신불제사귀, 
⊖음귀를 물리쳐야 구슬이     꿰어지고 큰돈을 벌겠어!

⊕신변상가부정도 침범했어 !
`,`■ 지푸라기라도 잡고 싶은     심정이겠네?
男：새 일을 찾아야 할 운세      가 됐네!
女：이별수가 보이네?
⊕신불제사귀, 
⊖음귀를 잡으면 돈복이 
 열리겠어!
`,`■ 옛일은 끊어지고 돈은 
  쪼들리고…
  이전 이사까지 하게 생겼어!

⊕변사귀, 
⊖전사귀 해소가 급하네!
`,`■몸도 불안, 마음도 불안이네?    먼저 나서서 만든 일이니    원망도 못하겠어! 
 돈이 독으로 변했어!

⊕⊖가택에 수망노부 부정이      탔어!
슬피 우는 여자야!
`,`■ 풍파가 심하겠어! 
  마음도 떠 있고… 
  산속에 들어가(은신) 기도      하면 길은 보이는데 
⊕⊖여귀를 해소시켜야 
  그 길이 더 단단해지겠어!

⊕⊖신변 수망노부부정도 
   침범해는데 ?
`,`■ 다인 다사건을 하나로 묶겠     는데 나는 손해야 !
男：자리가 이탈되겠네?
女：이별수가 보이네?

⊕전사귀, 
⊖변사귀 장난이네! 
 이걸 물리쳐야 전화위복이 
 되겠어!
`,`■ 땅과 집이 원수겠어!
  마음도 놀래고 있고… 
  귀신(삼전 전귀)들이 
  잔뜩 침범했으니 권리를     잃게 생겼지! 
  서로 속이기도 하고! 
  그래도 귀신만 없애면 
  해결되겠어!
`,`■ 권리는 날아가고 소비는     많고 빈말 빈소리뿐이네?
모두 
⊕ 도로․음귀, 
⊖신불제사․여귀와 가택상가     부정 장난이야!

이 귀신ㆍ부정들을 해소해야 길이 열리겠어!
`,`■골육이 원수로 변하고 있네?    재산도 권리도 다 날아가게    생겼어!

⊕ 음귀, 
⊖신불제사귀를 해소해야만     길이 나오겠어!

⊖신변상가부정도 침범했네 ?`];
  const YOGYEOL1 = [`■ 간상과 초전이 폐구가 되어 믿기 어렵다.
  관살이 여러개 있고 구진과 백호가 있어 나쁘게 본다.
  `,`■ 일지가 간상에 와서 간살이 되니 상대가 나에게 해를 끼친다. 오로지 허욕을 급히 버려야 나중에 잘 됨이 따라오리라.
  `,`■ 말전에서 초전을 도우니 그것은 처재가 되므로 혼사 문제는 크게 이롭다. 
  `,`■ 낮정단은 주석자리에서 뜻밖의 재앙이 생기겠다. 
  손윗사람의 질병은 귀신의 간특함이 있겠다. 
  `,`■ 낮정단은 손재나 도둑을 맞고 천장이 관살이 되어도 자손이 잘 막으니 탈기로 보지말라. 재앙을 막아주는 근본이 된다.
  `,`■ 처나 종업원이 괴이한 일을 꾸민다. 자식의 빚을 갚아야 하고 여러 사람이 도울것 같으나 돈 문제 때문에 그렇게 안되겠다.
  `,`■ 재물에 정신이 숨었으니 힘만 들고 이익은 없다. 집안 사람이 추악해서 병이나 소송이 자주 생기겠다.
  `,`■ 나나 너나 다같이 좋지 않다. 묘와 패와 손해 뿐이다. 집을 삼전에 인존해보니 집은 넓고 좋겠다.
  `,`■ 서로간에 사이는 좋으나 낮정단은 천장이 사람을 극한다. 삼전이 모두 내 힘을 빼앗으니 관재는 풀리고 손해는 어쩔 수 없다.
  `,`■ 쑥대 화살이라 허경뿐이다. 서로 속이고 업신여긴다. 자손에게 의지하니 여러 가지 어려워도 해는 당하지 않겠다.
  `,`■ 사과와 초전과 중전이 모두 공망되어 허망하다. 관귀가 말전에 있으나 귀신굴이 막혀 해는 없겠다.
  `,`■ 윗사람은 대접 받지 못하니 자기가 사서 당하는 망신이다. 밤정단은 천공이 타서 좋고 나쁨이 일체 없어졌다.`]
  const SIBO1 = [`■ 막혀서 통하지 않는다.
  구태를 지키고 개신하지
  말며 망동하지 않아야 한다.
  때를 기다려야 한다.
  가정에 질병이 있다.
  부정빌미 탓이다. 관직사는 길조이고 수험은 목적 달성한다.
  `,`■ 자충수적 우환이 있다.
  타인이 스스로 와서
  능욕을 입힌다.
  맹동하지 않고 조용히 점진하면 길이 된다.
  현재는 어떤 일도 간난하고 방해가 있다.
  관재의 고심이 있고, 단 관직사는 吉하다.
  `,`■ 옛 일, 옛 것으로 회귀
  하려고 한다.
  폐지사를 부흥시켜 길조를
  얻게 된다. 특히 장사를 하면 큰 이익이 있다.
  관직자는 임기를 마치지 못하고 중도 변경이 된다. ⊖女는 남자를 유혹하여 색정거래한다.
  七月 임신사가 있다.
  `,`■ 이익사가 있고 소득한다.
  신규사가 있고 개선시킨다.
  흉이 변해 길조를 이룬다.
  활동하여 大財를 획득한다. 신속히 움직이고 있다.
  단, 소송이 기할 우려가 있다.
  `,`■ 자충수적 우환이 있다.
  직위해탈사, 또는 별리사로
  財가 왕하게 된다.
  흉해가 흉이 되지 않는다.
  교섭사는 타협된다. 
  도난을 만나기 쉬우나 요행을 얻는다.
  七月 임신사가 있다.
  `,`■ 이동ㆍ선거사가 있다.
  타인의 천거과 추천을
  받으나 실의가 없다.
  시작은 있으나 끝이 없다.
  財가 보이나 자유롭지 못하다. 女는 남자를 적극 유혹하여 따른다.
  권리가 상실된다
  七月 임신사가 있다.
  `,`■ 변동수가 있고 가내 우환이
  있고 가장을 속이며 
  가인이 원한을 품고
  가해하려 한다.
  財의 다툼이 있고 財가 소송의 씨앗이 된다.
  빚을 갚거나 헌납하여 재난을 면한다. 
  옛 일이 끝나게 된다.
  ⊕일이 반복 된다.
  `,`■ 자충수적 우환이 있다.
  인ㆍ택이 모두 해롭다.
  이사하는 것이 좋다.
  凶은 길조로 변하게 된다. 
  女人의 비곡사가 있다.
  관재의 고심이 있다.
  `,`■ 교섭사는 타협된다.
  먼저 굽히고 후에는
  펴게 된다.
  곤란 후에 좋아지는 운세이다. 
  남자는 직위해탈, 여자는 부부이별사고 재복이 있게 된다.
  다인 다사건이다.
  `,`■ 권리를 상실한다.
  처음에는 허성에 놀라고
  능욕받는 상이다.
  힘이 약하고 손상받는 일이 있으나 우희를 이루지 않는다. 財의 동함이 있다. 
  관직자는 길하고 일반인은 수구가 좋다.
  `,`■ 저주사가 있고 허모가
  많고 권리를 상실한다.
  형제의 凶이 있다.
  흉해는 당하지 않으며 처음은 흉이라도 후에는 길하다.
  ⊖외부의 상복사가 있게 된다. 재화는 가정안에서 일어난다.
  `,`■ 파재ㆍ극처사가 있다.
  권리 상실이 있다.
  가택권리가 넘어간다.
  몸을 타인에게 의탁하고 굽히는 운세이다.
  일도 불완전하다.
  분실사건이 생긴다.
  육친이 불화한다. 
  천우신조를 받게 된다.`]
  const SIBO2_1 = [`■ 직ㆍ녹,신변외사다.
  내방조짐 상의 : 
  은근하고 伏하라.
  매사 중도폐기된다.
  `,`■官ㆍ夫ㆍ病,가택내사이다.
  내방조짐 상의 :  
  상복사가 있다.
  움직임이 근본이고 동기가 발동한다.
  `,`■ 財ㆍ女,가택내사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다.매사지장 있고 용두사미 상이다.
  `,`■財ㆍ女,신변동요사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 官･夫, 외사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  男직위상실하고
  女부부이별한다. 
  `,`■ 財ㆍ女,가택내사다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다. 금전화근․ 처 소송사가 있다.
  `,`■財ㆍ女,신변동요사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 官･夫,가택동요사이다.
  내방조짐 상의 :  
  해산사가 있다.흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官･夫,가택반목사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  男직위상실하고
  女부부이별한다. 
  `,`■官･夫,가택동요사이다.
  내방조짐 상의 :  
  매사 초에는 불성한다.정사 利 암사 불리하다.
  `,`■ 손모ㆍ극처가정사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  손재·녀하고 형제 재액이 있다
  `,`■직ㆍ녹,신변외사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  가정배역사가 있고 가택이 넘어간다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/059/row-1-column-1.png`,
    `/static/images/sagwa/059/row-1-column-2.png`,
    `/static/images/sagwa/059/row-1-column-3.png`,
    `/static/images/sagwa/059/row-1-column-4.png`,
    `/static/images/sagwa/059/row-1-column-5.png`,
    `/static/images/sagwa/059/row-1-column-6.png`,
    `/static/images/sagwa/059/row-1-column-7.png`,
    `/static/images/sagwa/059/row-1-column-8.png`,
    `/static/images/sagwa/059/row-1-column-9.png`,
    `/static/images/sagwa/059/row-1-column-10.png`,
    `/static/images/sagwa/059/row-1-column-11.png`,
    `/static/images/sagwa/059/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 천지상하가 복하여 움직이지 않는다. 역사(易辭)에 이르기를 ｢통간(統艮)의 체(體)로서 무슨 일이나 구태를 지키고 개신(改新)하지 않으며 초전․일간상신은 해(亥)의 순미덕록(旬尾德祿)이 되어 흉해가 없다｣고 하였다. 앞으로 나가려고 하여도 자형(自刑)은 두전격(杜傳格)이니 막혀서 통하지 않으며, 일간상신 음양이 모두 왕록으로 신(身)에 임하여도 망동하지 말고 때를 기다려 활동하면 좋다. 술(戌)에 백호가 승하고 일간을 극하면 병과소송의 건이 있다. 
    관직인은 최관관부(催官官符)라 칭하여 길조가 있다. 덕신왕록은 공명․직위의 건에는 길조가 있고, 진술(辰戌)의 택상에 승하여 백호를 보면 가택이 편안치 못하다. 
    일귀는 순행하고 삼전은 상․백․구이며, 야귀는 역행하고 공․백․음이다. 이 과는 수험(受驗)․응시(應試)는 모두 목적을 달성한다.`
    ,
    // 2
    `  이 과는 4과 중 술임(戌壬)이 상에서 하를 극하니 초전이 되어 원수과이다. 초전은 말전에서 신유술(申酉戌)로 역퇴여격(逆退茹格)이다. 초전은 술(戌)에서 일간을 극하지만 말전이 장생이 되어 맹동하지 않고 조용히 점진(漸進)하면 길로 된다. 일지의 술(戌)이 일간인 나의 위에 와서 일간을 극하기 때문에 가택, 또는 상대로부터 능욕을 당한다. 
    원수과는 존장이 배하를 제하여 일은 순리로운 상태이다. 일간상신의 귀살은 신유(申酉)의 인수에 생화하므로 흉은 변화하여 길조로 된다. 백호가 간상에 타면 흉은 신속하게 온다. 술(戌)의 하괴(河魁)가 해(亥)의 위에 타면 무슨 일이나 간난(艱難)하고 방해가 있다.
    일귀는 순행하며 백․공․청이고, 야귀는 역행하며 삼전은 백․상․현이다.
    이 과는 명리를 정단하면 목적을 달성하고 병소(病訴)의 건은 가장 흉하다.`
    ,
    // 3
    `    이 과는 4과 중 오신(午申)에서 1상이 1하를 극하고 초전이 되며 원수과이다. 초전은 일간의 처재(妻財)로 되고, 말전 인(寅)은 화(火)에서 보아 장생 육합이 승하여 중매인이다.  말전은 초전을 생하기 때문에 중매의 힘에 의하여 결혼이 성립되는 기쁨이 있다.
    삼전역간전격(三傳逆間傳格)이고 오(午)에서 인(寅)을 보면 고조격으로 되어 무슨 일이나 길조를 얻는다. 임(壬)에서 인(寅)은 탈기이지만 일간일지상신의 인수가 설루(洩漏)를 구하고 재(財)를 생하여 탈설의 고(苦)는 없다. 
    초․말전이 상생하여 후에 왕하면 장사에 좋으니 반드시 큰 이익이 있고, 공관유직(公官有職)에 있는 사람은 고조격이면 임기를 끝나지 못하고 중도에 변경이 있다. 
    일귀는 순행하고 삼전은 현․후․사이며, 야귀는 역행하고 삼전은 후․사․합이다. 삼전에 후․합이 승하면 자유 결혼의 징조가 있고 일녀격(泆女格)이라 한다.`
    ,
    // 4
    `  이 과는 4과 중 상하의 극이 없고 사신(巳申)에서 1상이 1하를 극하여 원수과로 된다. 삼전사맹신은 원태격(元胎格)이다. 일간상신은 역마 장생이 되고 초전은 일간의 재로 되어 제3과․제4과의 토신(土神)을 생한다. 토(土)는 전화하여 일간상신을 돕는다. 그러므로 흉을 변화시켜 모든 것은 길조를 얻는다. 말전은 덕록이고 역체생이 되며, 초전은 정신일간상의 역마이니 사람은 동조(動兆)가 있다.
    중전의 인(寅)에서 초전의 재를 생하여 재(財)가 많기 때문에 대재(大財)를 획득한다.
    제3과와 제4과는 일간의 귀(鬼)로 되어 즉 3, 4귀살이 되어 재앙․소송이 일어나기 쉽다. 수일(水日) 정재(丁財)는 재물의 움직임이 급속하게 발생한다.
    일귀는 순행하고 삼전은 음․사․구이며, 야귀는 역시 역행하고 삼전은 귀․합․공이다.
  `
    ,
    // 5
    `  이 과는 제1과․제2과에서 2상이 2하를 극하고 원수나 중심비용과(重審比用課)를 취하지 못하기 때문에 지반의 맹신인 제1과 미해(未亥)를 초전으로 하는 섭해과(涉害課)이며, 삼합목국이 곡직격이 된다. 임(壬)에서 삼합목국은 전탈(全脫)이 되고, 일지상신 오(午)는 재성이니 목(木)이 화(火)를 생하여 재는 왕세로 된다. 또 일간상신 관귀는 묘인(卯寅)으로 제하여 구신이 되어 흉해를 보고도 흉을 만나지 않는다.
    과전의 삼합․지합은 기쁜 일이 거듭 발생한다. 
    재(財)에 현무가 승해 도난을 만나기 쉬우나 말전의 해(亥)가 이것을 제하여 요행히 면하게 된다. 삼합목국에서 날씨를 정단하면 바람이 많다. 삼합 중에 지합하면 교섭사는 타협이 성립한다.
    일귀는 역행하고 삼전은 상․귀․구이며, 야귀는 역시 역행하고 삼전은 음․주․공이다.`
    ,
    // 6
    `  이 과는 제1과와 제4과에 상하의 극이 있고 하에서 상을 극하는 제1과 오해(午亥)를 취하여 초전으로 하여 중심과를 구성한다.
    일지상신이 정재(丁財)로 되고 말전 역마는 장생이 된다. 일지는 가택이고 가사이며 이동전거(移動轉居)하고자 하는 마음이 생긴다. 초전이 중전을 생하여 체생격으로 되며 다른 사람의 추천을 받는다. 그러나 축(丑)은 공망하니 실의(實意가 없다.
    초전은 지반 해(亥)로부터 극되고 또 천장으로부터 극되는데, 이와 같은 것을 협극(夾剋)이라 하여 재가 자유를 얻지 못한다.
    일귀는 역행하고 삼전은 현․주․백이며, 야귀는 또 역포로 되고 삼전은 후․구․현이다. 초전은 실(實)이고 중․말전이 공망하기 때문에 무슨 일이나 시작은 있으나 끝이 없다.`
    ,
    // 7
    `  이 과는 천지상충위(天地相冲位)에 있어 1하가 1상을 극하는 사해(巳亥)를 취하여 초전으로 하고, 삼전 사해사(巳亥巳)는 원태격(元胎格)이다. 사(巳)는 둔간(遁干)하여 정재로 되니 재상재(財上財)를 본다. 
    해(亥)의 비겁과 교차하여 재의 다툼이 있고, 일지상신 진(辰)의 천강은 일간을 상해한다. 즉 전재(傳財)는 귀(鬼)로 화하고 이 재를 채용하여 불러들인다. 일지는 가택이니 가정에 추악한 싸움으로 되며 병소(病訴)는 빈발한다. 채무(債務)를 변상하거나 재를 헌납함으로써 재(災)를 면할 수 있다.
    반음은 일이 반복하여 의뢰할 곳이 없고, 우희사(憂喜事)가 의외로 발생한다. 일간상신이 절(絶)이 되고 구사절멸(舊事絶滅)로 되므로 흉의 극은 도리어 후에 흉이 없다.
    일귀는 역행하고 삼전은 음․구․음이며, 양귀는 순포하고 삼전은 귀․공․귀이다. 귀인이 해(亥)에 임하면 귀인이 천문에 올라가는 격이다.`
    ,
    // 8
    `  이 과는 4과 중 3과까지 상에서 하의 극이 많기 때문에 사맹(蛇猛)의 상신 진해(辰亥)를 사용하여 초전으로 한다. 진(辰)은 묘신으로 귀묘(鬼墓)는 일간을 극하고 지상 묘(卯)는 술(戌)의 택을 극하니 인택은 모두 극을 받아 일이 펴지지 않는다. 
    택상에서 일상신을 극할 때는 이 택에 있지 말아야 한다. 그러나 귀신이 택상에 승하면 흉을 구할 수 있다.
    일귀는 역행하며 삼전은 후․공․사이고, 야귀는 순행하며 삼전은 후․구․현이다. 이 과는 일귀는 묘(卯)이고 야귀는 사(巳)인데 양귀가 일간 해(亥)를 싸니 횽은 길조로 변화한다. 해(亥)궁을 보라. 그러나 사(巳)의 귀신은 공망의 지(地)에 앉아 길은 경하며, 사(巳)의 재도 자(子)의 공망의 지에 있으니 구재는 반감(半減)하여 이롭지 않다.`
    ,
    // 9
    `  이 과는 제2과․제3과에 상하의 극이 있고, 하에서 상을 극하는 제2과의 미묘(未卯)를 사용하여 초전이 되는 중심과이다. 삼전목국은 곡직격으로 일간에서 설기된다. 미토(未土)에서 일간을 극하지만 인묘(寅卯)의 목(木)은 이를 제하여 흉을 보아도 흉을 만나지 않는다. 즉 탈기는 구신의 작용을 한다. 중심과는 후에 일어나거나 혹은 착수하는 것이 유익하다. 곡직격은 움직여서 뜻을 기다리는 데 조용한 것을 싫어하여 무슨 일이나 먼저 굽고 후에 곧게 된다. 즉 처음에는 곤란이 있다가 후에 쉬운 상이다. 과전의 삼합․육합은 길사가 거듭된다.
    말전으로부터 초전을 극하니 길장을 만나면 무슨 일이나 흉을 변화시켜 길조로 된다. 인(寅)과 해(亥)가 사합(斜合)한다. 즉 교차지합하여 교섭․교관(交關)․약속 등은 모두 타협되고 기쁜 일을 만난다. 삼합․지합하며 우사(憂事) 및 해산(解散)에 관한 일에는 좋지 않다.
    일귀는 순포하고 삼전은 구․상․귀이며, 야귀도 역시 순행하고 삼전은 주․공․음이다. 이과는 초전이 관성이라 할지라도 지신 묘(卯)로 극되므로 공명․구직은 불리하다.`
    ,
    // 10
    `  이 과는 4과 상하에 극이 없고 사극(斜剋)으로 사화(巳火)와 진토(辰土)의 극이 있으니 천신(天神)에서 극하는 것을 취하여 초전으로 하므로 요극호시과(遙剋蒿矢格)라고 한다. 삼전계신(三傳季神)은 가색격이라 명한다.
    간지는 교차상극하지만 제3과의 축토(丑土)가 공망이고 초전도 공망한다. 따라서 처음에는 허하고 후에는 실이지만 편관이 공망되니 흉해는 없다. 또 일간상신의 설(泄)이 구신(救神)이 되어 흉해를 구하므로 흉은 없다.
    요극과는 처음에는 허성(虛聲)에 놀라고 서로 능치(凌恥)하는 상이다. 그러나 인목(寅木)이 있어 중귀(衆鬼)의 침범을 받지 않으며, 제4과를 초전으로 맥월격(驀越格)이므로 힘이 약하고 손상받는 일이 더디어 우희(憂喜)를 이루지 않는다. 가색격은 사물의 침체를 담당하는데, 제3과와 제4과에서 일간을 극하면 귀(鬼)가 삼사에 임한다고 하여 소재(訴災)를 초래한다. 수일(水日)의 사(巳)는 정재(丁財)로 재(財)의 사건에서 움직임이 있다.
    일귀는 순행하고 삼전은 사․구․백이며 백호․등사(螣蛇)가 삼전으로 들면 가장 두려운 일이 일어난다. 야귀는 순포하고 삼전은 후․주․청이다. 관공직에 있는 사람에게는 길조가 있고, 상인은 지키고 있는 것이 좋다.`
    ,
    // 11
    `  이 과는 제1과․제2과의 상에서 하를 극하고, 제3과의 자술(子戌)은 하에서 상을 극하여 초전으로 되면 중심과이다. 삼전은 순간전격(順間傳格)이고 초․중전이 공망하여 역간전(逆間傳)으로 변화하기 때문에 향삼양(向三陽)이라 한다. 그러나 말전 진(辰)은 귀호(貴戶)로 되어 일간을 극한다. 다만 중전 인(寅)에서 이것을 제하여 흉해는 없다. 4과와 초․중전순이 공망으로 되어 길조는 이루지 못하며, 말전의 진인(辰寅)은 강색귀호(罡塞鬼戶)로 되어 사살사우(四殺四隅)에 있고 잠장(潛藏)하니 저해없이 모사는 이루어진다. 삼양격(三陽格)은 암에서 명으로 들기 때문에 처음에는 흉이라도 후에는 길하다. 
    과전에 공망이 많으면 허문(虛文)․허성(虛聲)이 이기고 실의(實意)는 적다. 일간상신이 쇠절(衰絶)이 되어 태상이 승하면 외래의 효복(孝服:상사)이 있다. 초전의 공망은 과숙(寡宿)이라 하여 결혼 문제는 성립되지 않고 성립된다 해도 얼마 안되어 파경을 맞을 우려가 있다.
    일지상신이 양인이 되면 백호가 택에 든다고 하여 재화는 가정 내에서 발생한다. 
    일귀는 순포하고 삼전은 현․후․사이고, 야귀는 순포하며 삼전은 백․현․후로 된다. 질병 또는 송사는 해구(解救)하지 못한다.`
    ,
    // 12
    `  이 과는 제2과가 토극수(土克水)이고, 제3과 해술(亥戌)은 하에서 상을 극하므로 중심과를 취한다. 일간기궁의 해(亥)는 제3과 지상에 가입하여 하에서 극을 받아 향학심(向學心)이 결핍되고 몸은 다른 사람에게 굴한다. 해자축(亥子丑)은 삼기격(三奇격格)으로 재(災)를 풀고 복을 초래한다. 
    중․말전이 공망하므로 삼기가 공망되어 복력을 감한다. 지반에서 천반까지는 순(順)의 지지로 나망격의 저해가 있어서 통하지 않고, 4과는 음의 불비격으로 되어 결함 불비의 일이 있으니 완미하지 못한다. 일간기궁 일지상신에 임하여 극이 되는 것을 난수(亂首)라고 한다.
    비전에 이르기를 ｢현무가 진술해(辰戌亥)의 어느 곳에 승하여도 현무가 적지(賊地)에 든다 하여 반드시 실탈의 사건이 생긴다｣고 하였다. 일귀는 순포하고 삼전은 상․현․음이며, 야귀는 삼전이 공․백․상이다. 이 과는 삼전사과가 모두 공망이 많다. 해자축(亥子丑)과 삼전은 순연여격(順連茹格)이라 한다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
