import React, { Component, useEffect, useState, useContext, useRef } from "react";

import "./VideoAdmin.css";
import Forms from "../components/Forms";
import TodoList from "../components/TodoList";
import Title from "../components/Title";
import fireDb from "../firebase";
import Firebase from 'firebase';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css';


const modules = {
    toolbar: [
      // [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      // ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'camera'],
      // [
      //   { list: 'ordered' },
      //   { list: 'bullet' },
      //   { indent: '-1' },
      //   { indent: '+1' },
      // ],
      // ['link', 'image', 'video'],
      // ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    'header',
    // 'font',
    // 'size',
    // 'bold',
    // 'italic',
    // 'underline',
    // 'strike',
    // 'blockquote',
    // 'list',
    // 'bullet',
    // 'indent',
    // 'link',
    // 'image',
    // 'camera',
    // 'video',
  ]
  
  // <div className={``}></div>
  // const pageCode1 =  'page000/1/find-id';
function App() {

  const imageRef1 = useRef();

  const [imageUpload1, setImageUpload1] = useState('');
  const [imageLink, setImageLink] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  
  const [quillValue1, setQuillValue1] = useState();
  const [quillValue2, setQuillValue2] = useState();
  const [quillValue3, setQuillValue3] = useState();
  const [quillValue4, setQuillValue4] = useState();

  const upload = ()=>{

    // if (viewScreenMode.mode != pageCode1) {
    //   return;
    // }

    var returnName1 = `${imageUpload1.name}_${moment().format("YYYYMMDD_HHmmss")}`;
    if(imageUpload1 == null)
      return;
      const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(imageUpload1)
      uploadTask
      .on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        fireDb.storage().ref('images').child(returnName1).getDownloadURL()
         .then(fireBaseUrl => {
          //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
          console.log("fireBaseUrl", fireBaseUrl)
          setImageLink(fireBaseUrl);
         })
      }
    );

  }
  useEffect(()=> {
    upload();
  },[imageUpload1])
  function copyFunction() {
    // Get the text field
    var copyText = document.getElementById("copyDiv1");
  
    // Select the text field
    copyText.select(); 
    copyText.setSelectionRange(0, 99999); // For mobile devices
  
     // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
    document.execCommand("copy");
  
    // Alert the copied text
    // alert("Copied the text: " + copyText.value);
  }


  return (
    <div className="container">
      <div className="app-wrapper">
            <div className="circleimage_wrapper cursor_pointer" 
                    style={{ 
                      background: imageUpload1 == '' ? `rgba(33, 37, 41, 0.7)` : `transparent`,
                      border : imageUpload1 == '' ? `none` : `1px solid #aeaeae`,
                    }}
                  >
                    {
                      imageUpload1 == '' &&
                      <>
                      <div className="circle0" onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef1.current.click();

                      }}>
                        {/* <img className="circleimage0" src={`/static/icons/000/icon_camera.png`}/> */}
                        <div className="circledesc0">사진을<br/>등록해 주세요. </div>
                      </div>
                      <input ref={imageRef1} 
                        type="file" 
                        onChange={(e)=>{setImageUpload1(e.target.files[0])}} 
                        // accept="image/*"
                        // accept=".gif, .jpg, .png"
                        accept=".png"
                        style={{display : 'none'}}/>
                      <button onClick={upload} style={{display : 'none'}}>Upload</button>
                      </>
                    }
                    
                    {
                      imageUpload1 != '' &&
                      <>
                      <img className="full_image" src={imageLink}/>
                      <input ref={imageRef1} 
                      type="file" 
                      onChange={(e)=>{setImageUpload1(e.target.files[0])}} 
                      // accept="image/*"
                      // accept=".gif, .jpg, .png"
                      accept=".png"
                      style={{display : 'none'}}/>
                      <button onClick={upload} style={{display : 'none'}}>Upload</button>

                      </>
                    }
                    <div>
                    </div>
                  </div>


                  <div className="text1">방금 올린 사진 링크</div>
                      <div className="text1"
                       onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        copyFunction();
                      }}
                      >{imageLink}</div>

                      <textarea 
                      style={{ display: 'none'}}
                      id="copyDiv1"
                      className="text1"
                      value={imageLink}
                       onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        copyFunction();
                      }}
                      />
                
        <div>
          <Title />
        </div>
        <div>
          <Forms imageUrl={imageLink}/>
        </div>
        <div>
          <TodoList />
        </div>
        <style>
          {`



.circleimage_wrapper {
  width : 140px;
  height : 140px;
  border-radius: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
  margin-bottom : 30px;

  overflow: hidden;
  margin-left : auto;
  margin-right: auto;

  color : #fff;
}
.circle0 {

}
.circleimage0 {
  width : 40px;
  height : 40px;
  object-fit: contain;
  
}
`}
        </style>
      </div>
    </div>
  );
}

export default App;
