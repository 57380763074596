
const columnA = [
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`01`,`좌`],
  [`23:32`,`04 `],
  [`01:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`02`,`좌`],
  [`01:32`,`09`],
  [`03:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`03`,`좌`],
  [`03:32`,`06`],
  [`05:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`04`,`좌`],
  [`05:32`,`11`],
  [`07:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`05`,`좌`],
  [`07:32`,`08`],
  [`09:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`06`,`좌`],
  [`09:32`,`01`],
  [`11:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`07`,`좌`],
  [`11:32`,`10`],
  [`13:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`08`,`좌`],
  [`13:32`,`03`],
  [`15:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`09`,`좌`],
  [`15:32`,`12`],
  [`17:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`10`,`좌`],
  [`17:32`,`05`],
  [`19:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`11`,`좌`],
  [`19:32`,`02`],
  [`21:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`12`,`좌`],
  [`21:32`,`07`],
  [`23:31`,`내용 해당`],
  [``,``]
]
const columnD = [
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,9,`없음`,`01.21\n~
02.19`,`보통크기 \n고환큼

음모
적음 
분위기파`,`조용\n담담

성교후곧잠`],
  [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
  [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
  [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,8,`없음`,`02.20\n~
03.20`,`굵음\n적극적 매너

강력한 성교 함`,`문을 열면 적극적이 됨\n
감격을 숨기지않음`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,7,`없음`,`03.21\n~
04.20`,`단순 서툼\n시간 짧음

전,후 없이 본격적 임`,`주도적\n적극적

완벽
주의자 임`],
  [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
  [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
  [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
  [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,6,`없음`,`04.21\n~
05.20`,`때와 장소 안 가림\n
발정
타입
 곧 
시듬`,`정숙\n하고 
억제심 강함

분비물 많음`],
  [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
  [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
  [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,5,`없음`,`05.21\n~
06.20`,`기교파 반응 감상 타입\n
크고, 즐김`,`위쪽\n 절정감에 집요함

주도권을 빼앗기도`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`위선`,1,4,`없음`,`06.21\n~
07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
질이 큼

소극적 못견딤
절정 빠름`],
  [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
  [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
  [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
  [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,2,3,`없음`,`07.21\n~
08.20`,`정력파 女만족시키려 함\n
쉽게 지침`,`적극적 타입 수축 형\n
중년 쇠퇴 후 회복함`],
  [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
  [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
  [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,3,1,`없음`,`08.21\n~
09.20`,`헌신 리드 파 \n더러 포경

성지식 풍부함`,`부끄러움 많이 탐\n
성기는 명물임`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`위선`,4,1,`없음`,`09.21\n~
10.20`,`조루증 많음\n
음경은 작은 편`,`음모 많음\n
수축 형이고 능란함`],
  [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
  [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
  [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
  [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신뢰`,5,12,`없음`,`10.21\n~
11.20`,`보통이상 크기\n
극적. 본능에 그침`,`은밀함\n절정을 끝없이 함

전신
경련. 수축 형 강력
 명물`],
  [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
  [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
  [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,11,`없음`,`11.21\n~
12.20`,`통크기. 강직도 있음\n
오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
  [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,10,`없음`,`12.21\n~
01.20`,`보통 크기. 강직도 있음\n
여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
  [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
  [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
  [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
]
const columnS = [
  [``,``,``,``,``,``],
  [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
  [``,``,``,``,``,``],
  [``,``,``,``,``,``],
  [``,``,``,``,``,``],
  [`금전`,`금전 관련사`,`小`,``,``,``],
  [`사업`,`사업 관련사`,`小`,`6`,``,``],
  [`처첩`,`처첩 관련사`,`大`,`존재기`,`압박가함`,`타인수혜`],
  [`최이도망(最利逃亡)`,`이리봐도저라봐도 산속으로 도망치는 것이 이익의 상`,`大`,``,`창독득죄`,`직위이동`],
  [`소식조격(消息阻隔)`,`관격 불통으로 소식이 안 오는 격`,`小`,``,``,``],
  [`자매`,`자매 관련사`,`小`,``,``,``],
  [`친구`,`친구 관련사`,`大`,`5`,``,``],
  [`동료`,`동료관련사`,`小`,`배양기`,`타인시혜`,`소식대기`],
  [`종난명백(終難明白)`,`끝내 흑백이 가려지지 못하는 상`,`小`,``,`가실불화`,`와도나쁨`],
  [`사관천역(仕官遷躍)`,`관공직자 승진영전하는 상`,`大`,``,``,``],
  [`관사`,`관사 관련사`,`大`,``,`昇雲출입`,``],
  [`송쟁`,`외사 급속송쟁사-상해우려`,`小`,`4`,`이익경영`,`微招衣冠`],
  [`남편`,`남편 관련사`,`小`,`개시기`,`급속송쟁`,`가정막힘`],
  [`장상무력(長上無力)`,`고위인이 힘을 못쓰게 되어 도움을 얻을 수 없는 상`,`大`,``,`상해우려`,``],
  [`이어경영(利於經營)`,`고위에 안좌하여 모든 이의 이익되는 방법을 강구하는 상`,`小`,``,``,``],
  [`관사`,`관사 관련사`,`大`,``,``,``],
  [`결속`,`여러 일을 결속하여 후원사를 키우나 자식사 근심 발생함`,`小`,`3`,``,``],
  [`색정`,`내女 색정사 有`,`小`,`학습기`,`색정음사`,`비용과출`],
  [`차례시야(此例是也)`,`본인의 말과 같이 되어지는 상`,`大`,``,`투서헌책`,`약속비밀`],
  [`불검초죄(不檢招罪)`,`약속한 비밀을 누설하여 죄인이 되는 상`,`小`,``,`고위면접`,`누설`],
  [`형제`,`형제 관련사`,`小`,``,``,``],
  [`친구`,`친구 관련사`,`大`,`2`,`비용과출`,`압박당함`],
  [`동료`,`동료 관련사`,`小`,`면모기`,`엄목지상`,`관재형책`],
  [`화촉재해(火燭災害)`,`촛불에 의한 화재를 입을 상`,`小`,``,``,``],
  [`엄목지상(掩目之象)`,`눈을 가리는 경우이나 사람이 상하지는 않는 상`,`大`,``,``,``],
  [`명예`,`수상(受賞)사`,`大`,``,``,``],
  [`직녹`,`권리 관련사`,`大`,`1`,``,``],
  [`송쟁`,`내사 반목대립 송쟁사`,`小`,`절정기`,`외교막힘`,`대립송쟁`],
  [`유시무종(有始無終)`,`약속하고 교합지명(交合之名)했으나 후에 결말이 없는 상 `,`小`,``,`불의투서`,`충산분산`],
  [`우유(優遊) 閑暇)`,`머리를 다듬는 한가로운 상-실업자에 통함`,`小`,``,``,``],
  [`후원`,`후원조력사`,`大`,``,``,``],
  [`문서`,`문서 관련사`,`大`,`12`,``,``],
  [`색정`,`外男 색정사`,`小`,`과시기`,`비용과출`,`사기득재`],
  [`생의계외(生意皆畏)`,`벗어나고 싶으나 모두 두려움 뿐인 상`,`小`,``,`약속비밀`,``],
  [`엄목지상(掩目之象)`,`눈이가려진 격이므로 사람이 상하지는 않는 상`,`小`,``,`누설`,``],
  [`자매`,`자매 관련사`,`小`,``,``,``],
  [`친구`,`친구 관련사`,`大`,`11`,`관재형책`,``],
  [`결속`,`음8월 개혁사를 완성해 大財를 회득하나 부모災害발생함`,`小`,`쇠퇴기`,`음8월`,`가정침체`],
  [`부녀노병  (婦女怒病)`,`부녀가 화가나서 생긴 병으로 놀라나 허경(虛驚)의 상`,`小`,``,`대재획득`,`재앙소멸`],
  [`상인접인(上人接人)`,`투서헌책으로 고위인을 면담하게 되는 상`,`大`,``,``,``],
  [`박관`,`직업직위 불안사`,`小`,``,``,``],
  [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`10`,`충산분산`,`부부이심`],
  [`매사 영속성없음`,`직업직위남편자식문제는 선천적 기복재해를 겪음`,`大`,`침해기`,`공허애증`,`동요분산`],
  [`자취지화(自取之禍)`,`스스로 선택한 격의 재난인 상`,`小`,``,``,`미초희경`],
  [`충산분산(冲散分散)`,`부딛쳐 동요하고 흩어지는 상`,`大`,``,``,``],
  [`박관`,`직업직위불안사`,`小`,``,``,``],
  [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`9`,`고위연회`,`손해가출`],
  [`매사 영속성없음`,`직업직위남편여식문제는 선천기복재해를 겪음`,`大`,`소외기`,`초대길사`,`위례득죄`],
  [`연역난동(然亦難動)`,`역시 적극적으로 움직이면 어렵게 되는 상`,`小`,``,``,``],
  [`위례득죄(違禮得罪)`,`무례가 극심하여 죄를 입는 상`,`大`,``,``,``],
  [`형제`,`형제 관련사`,`小`,``,``,``],
  [`친구`,`친구 관련사`,`大`,`8`,``,``],
  [`동료`,`동료 관련사`,`小`,`침체기`,`침체암울`,`압박가함`],
  [`출재입환(出財出入還)`,`나갔던 돈이 다시 돌아오는 상`,`小`,``,`불변근신`,`부부불화`],
  [`창독득죄(昌瀆得罪)`,`주변거식(酒邊居食)이 물란하여 죄에 이르는 상`,`大`,``,``,``],
  [`금전`,`동요불안분산사`,`小`,``,``,`昇雲출입`],
  [`급속`,`내사 급속사`,`小`,`7`,`구설송사`,`고위안좌`],
  [`출행`,`외사 출행이동사`,`大`,`단절기`,`동요불안`,`衆人이익`],
  [`성패여하(成敗如何)`,`좋고나쁜지 되고 안되는지를 분간할 수 없는 상`,`大`,``,`조상불경`,`방법강구`],
  [`불변근신(不辯謹身)`,`장차를 위해 말을 쉬고 몸을 삼가해야 하는 상`,`小`,``,``,``]
]
const columnZ = [
  [``,``,0,``,0],
  [`단계`,`단계`,0,`가능`,"불가"],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明시작`,``,0,`금전사업처첩조력으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明종료`,``,0,`자매친구동료조력으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明진행`,``,0,``,"관사직업남편문제로불가"],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗진행`,0,``,"관사직업색정사로불가"],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明하향`,``,0,`형제친구동료조력으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗절정`,0,`명예직녹권리사로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗시작`,0,`후원문서색정사조력으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗종료`,0,`자매친구동료조력으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗진행`,0,``,`세속허사자로불가`],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明진행`,``,0,``,`세속허사자로불가`],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗진행`,0,`형제친구동료조력으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明절정`,``,0,`금전사업처첩조력으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0]
]
const columnAF =[
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [2,3,4,5,6,7,8,9,10,11,12,1],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [3,4,5,6,7,8,9,10,11,12,1,2],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [4,5,6,7,8,9,10,11,12,1,2,3],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [5,6,7,8,9,10,11,12,1,2,3,4],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [6,7,8,9,10,11,12,1,2,3,4,5],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [7,8,9,10,11,12,1,2,3,4,5,6],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [8,9,10,11,12,1,2,3,4,5,6,7],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [9,10,11,12,1,2,3,4,5,6,7,8],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [10,11,12,1,2,3,4,5,6,7,8,9],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [11,12,1,2,3,4,5,6,7,8,9,10],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [12,1,2,3,4,5,6,7,8,9,10,11],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [1,2,3,4,5,6,7,8,9,10,11,12],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0]
]
const columnAS = [
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [2],
  [0],
  [0],
  [0],
  [0],
  [3],
  [0],
  [0],
  [0],
  [0],
  [4],
  [0],
  [0],
  [0],
  [0],
  [5],
  [0],
  [0],
  [0],
  [0],
  [6],
  [0],
  [0],
  [0],
  [0],
  [7],
  [0],
  [0],
  [0],
  [0],
  [8],
  [0],
  [0],
  [0],
  [0],
  [9],
  [0],
  [0],
  [0],
  [0],
  [10],
  [0],
  [0],
  [0],
  [0],
  [11],
  [0],
  [0],
  [0],
  [0],
  [12],
  [0],
  [0],
  [0],
  [0],
  [1]
]
const columnAU = [
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송조짐이 있는 人`,0,0,`현재 사안에서 물러나는 상**타인의 방해를 받음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유순유화후결**만약 타인 다툰다면 유화적 처신으로 일을 미뤄야 나중에 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응적 태도로 처신을 해야만 결과가 유리한 人`,0,0,`신규사가 있음**1녀2남 삼각관계 있음**여건이 부족함**아랫사람이 윗사람에게 예의를 범함`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유순유화후결**만약 타인과 다툰다면 유화적 처신으로 일을 미뤄야 나중에 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응적 태도로 처신을 해야만 결과가 유리한 人`,0,0,`여러 일을 하나로 묶는 단합사가 있음-단 외화내허적임**남자가 타인의 부인을 유혹하여 자기 여자로 거래함**어디 깊은 산중으로 숨고싶은 심정임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유순유화후결**만약 타인과 다툰다면 유화적 처신으로 일을 미뤄야 나중에 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응적 태도로 처신을 해야만 결과가 유리한 人`,0,0,`이전의 일이 모두 끓김`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`동요불안분산배신**만약 타인과 다툰다면 간신이 있어 천지가 바뀐 상이므로 재난이 밖에서 오니 신중해야 함`,0,0,`반복적 불안사로 상호 배신적 처지에 힘든 人`,0,0,`부부이심색정사가 있음**신규사가 있음**윗사람(부모)이 아랫사람(자녀)으로부터 박해를 당함**독신고독각방주말부부별리사별적 처지임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송조짐이 있는 人`,0,0,`입을 닫음**결국 깊은 산속으로 숨고싶은 심정이 될 상황에 이르게 됨`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`출행불귀(不歸)재앙방비-원행금지**만약 타인과 다툰다면 재앙을 맞게되므로 피해야 함`,0,0,`등뒤에서 재앙이 노리고 있는 격이므로 비행기 배 기차로 멀리가는 출행을 금지해야 할 人 `,0,0,`마치 백호가 노려보는 격**일이 반복되기만 함**독신고독각방주말부부별리사별적 처지임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유순유화후결**만약 타인과 다툰다면 유화적 처신으로 후응해야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응적 태도로 처신을 해야만 결과가 유리한 人`,0,0,`신규사가 있음**1남2남 삼각관계 있음**여건이 부족함**독신고독각방주말부부별리사별적 처지임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유순유화후결**만약 타인과 다툰다면 유화적 처신으로 후응해야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응적 태도로 처신을 해야만 결과가 유리한 人`,0,0,`어디 깊은 산속으로 숨고싶은 심정**1남2녀 삼각관계 있음**일이 띄엄띄엄 진행되어 그만두려하나 되려 진행하게 되나 결과가 비었음**관직자는 진직영전하나 늦으면 허사가 됨`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유순유화후결**만약 타인과 다툰다면 유화적 처신으로 후응해야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응적 태도로 처신을 해야만 결과가 유리한 人`,0,0,`어디 깊은 산속으로 숨고싶은 심정**남자가 타인의 부인을 유혹하여 자기 부인처럼 거래함**일이 띄엄띄엄 진행되어 그만두려하나 되려 진행하게 되나 결과가 비었음**관직자는 진직영전하나 늦으면 허사가 됨`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`순응유순유화후결**만약 타인과 다툰다면 유화적 처신으로 후응해야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 반발하지 말고 순응적 태도로 처신을 해야만 결과가 유리한 人`,0,0,`일이 순조롭게 앞으로 나아감`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`수구대신(守舊待新)**자기 과신으로 옛것을 지키며 기회를 찾는 입장**만약 타인과 다툰다면 상대는 뜻은 동해도 중지가 됨 `,0,0,`고집이 있어 목적을 굽히지 않고 신음하는 처지의 人`,0,0,`자기자신을 믿고 신규사가 있으며 고집이 있음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0]
]
const columnBE = [
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이익인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이익인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`침체인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성-무력`,`악성`,`거짓`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`무력인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면-무력`,`선성`,`진위반반`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`무력인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면-무력`,`선성무력`,`위선`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`아권리가져갈인`,`아타동시발동사`,`미사`,`신사`,`외내사겹침`,`아표면타이면사겹침`,`좌고우면`,`선성-무력`,`위선`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인-비용듬`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`배신`,`보성`,`위선`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`피해인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`피해인`,`아발동사-후회사겹침`,`기사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`악성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``]
]
const columnBO = [
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-가옥고민-이사예정`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠-관직자 흥`,`흥`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-이사하게 됨`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-공허`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-공허`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠`,`쇠-가옥고민`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠-공허`,`쇠`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠-공허`,`쇠`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-가옥고민`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-가옥고민`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥`],
  [``,``],
  [``,``],
  [``,``],
  [``,``]
]
const columnBS = [
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`승`,`승`,`승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 무력`,`무력`,` 무력`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 무력`,`무력`,` 무력`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 비김`,`비김`,` 비김`],
  [``,``,``],
  [``,``,``]
]
const columnBV =[
  // [0,0,0,``,0,0,0,``],
  // [용모,가문,合6,``,용모,가문,合6,``],
  // [0,0,0,``,0,0,0,``],
  [3,2,5,`음2월`,2,2,4,`음12월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,6,`음2월`,3,1,4,`음10월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,1,4,`음9월`,3,3,6,`음5월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,2,4,`음10월`,1,1,2,`음9월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,3,5,`음3월`,1,1,2,`음9월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,2,4,`음9월`,2,2,4,`음3월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [1,1,2,`음5월`,2,2,4,`음1월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,1,3,`음4월`,3,1,4,`음12월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,1,4,`음5월`,3,3,6,`음3월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,1,4,`음5월`,3,2,5,`음3월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,3,5,`음4월`,1,2,3,`음2월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,3,5,`음3월`,3,3,6,`음1월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``]
]
const columnCE = [
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`고집이 있고 업무는 수준급이나 종반에 침체하게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높고 업무도 적절함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`사교성이 좋아 동료의 도움을 얻어 결속 단합하여 성과를 이루나 화화내허적 임`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 조급하나 일은 일반적임`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`유순하고 준수 미모이나 업무가 부조하여 수준 이하 임`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 조급하나 일은 일반적임`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높으나 중반 외 초반 종반에는 빛을 잃음`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높으나 업무가 극히 저조하여 실망하게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`사교성이 좋아 동료의 도움을 얻어 업무의 수준을 이룸`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`사교성이 좋아 동료의 협력을 받아 업무를 수준있게 함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`사교성이 좋아 동료의 협력을 받아 업무를 수준있게 함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`언어가 교묘하고 속이며 반목대립하여 소송사를 일으킴`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0]
]
const columnCL = [
  [``,``,``,``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
  [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
  [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
  [`7.8월`,`集`,`측근반대`,`불이행`,`선費후破`,`退背`,`근`,`去`,`세입가해`,`정남`,``,`정북`],
  [`10.11`,`多集`,`발현안됨`,``,``,``,``,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`  集`,`본인반대`,``,``,``,``,``,``,``,``,``],
  [`7.8월`,``,`발현안됨`,`불이행`,`선압박`,`退背`,`  원`,`  去`,`주인후원`,`남남서`,``,`북북동`],
  [`10.11`,``,``,``,`후易`,``,``,``,``,``,``,``],
  [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`본인반대`,``,``,``,``,``,``,``,``,``],
  [`7.8월`,``,`세력협력`,`불이행`,`후이`,`退背`,`  원`,`  去`,`주인후원`,`서북`,``,`동북`],
  [`10.11`,`  集`,`반대성공`,``,`천천히`,``,``,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
  [`7.8월`,``,`상대측근`,`불이행`,`반목대립`,`退背`,`  원`,`  留`,`세입가해`,`남남동`,`정남`,``],
  [`10.11`,``,`설득하면`,``,`포기要됨`,``,``,``,``,``,`세밀심사`,``],
  [`3.6\n9.12`,`多集`,`제압됨`,``,``,``,``,``,``,``,`함`,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`종반반대`,``,``,``,``,``,``,``,``,``],
  [`7.8월`,`  集`,`세력침체`,`불이행`,`안건무력`,`進向`,`  근`,`  留`,`주인무력`,`정남`,`정남`,``],
  [`10.11`,` 多集`,`제압못함`,``,``,``,``,``,``,``,`세밀심사`,``],
  [`3.6\n9.12`,``,`연기필요`,``,``,``,``,``,``,``,`함`,``],
  [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
  [`7.8월`,``,`반대다수`,`불이행`,`안건무력`,`退背`,`  근`,`  留`,`주인무력`,`남남서`,`남남서`,``],
  [`10.11`,``,`무력`,``,``,``,``,``,``,``,`세밀정단`,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
  [`7.8월`,`  集`,`없음`,`불이행`,`선費후반`,` 退背`,`  근`,`  去`,`세입가해`,`정북`,`서북`,``],
  [`10.11`,`多集`,``,``,`목대립`,``,``,``,``,``,`세밀심사 `,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
  [`7.8월`,``,`없음`,`불이행`,`선이후반`,`退背`,`  근`,`  去`,`세입설기`,`북북동`,`동북`,``],
  [`10.11`,``,``,``,`빠르게`,``,``,``,`피로`,``,`세밀심사`,``],
  [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,`함`,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`종반반대`,``,``,``,``,``,``,``,``,``],
  [`7.8월`,`  集`,`근거확실`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`세입설기`,`동북`,`동남`,``],
  [`10.11`,``,`내부제압`,``,``,``,``,``,`이심반목`,``,`세밀심사`,``],
  [`3.6\n9.12`,``,`됨`,``,``,``,``,``,``,``,`함`,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,` 集`,``,``,``,``,``,``,``,``,``,``],
  [`7.8월`,``,`없음`,`불이행`,`절차대로`,`進向`,`  근`,`  去`,`세입가해`,`정동`,``,`정북`],
  [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
  [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,`함`],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
  [`7.8월`,``,`내부반대`,`불이행`,`선난후이`,`進向`,`  근`,`  留`,` 무난`,`동남`,``,`서북`],
  [`10.11`,``,`발현안됨`,``,`천천히`,``,``,``,``,``,``,``],
  [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`多集`,`내부동조`,`불이행`,`선난후이`,``,``,``,``,``,``,``],
  [`7.8월`,``,`본인측근`,``,`천천히`,`進向`,`   근`,`  留`,`무난`,`남남동`,``,`북북서`],
  [`10.11`,``,`제압함`,``,``,``,``,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
]
const columnCY = [
  [``,``,``,``,``,``,0,``],
  [``,``,``,``,``,``,0,``],
  [``,``,``,``,``,``,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,`가당부정`,`성물훼손`,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,`전사귀`,`전사`,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
  [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,`가장귀`,`호주사망`,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,`여귀`,`부인사망`,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``],
  [`질병`,`여귀`,`부인사망`,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,`여귀`,`부인사망`,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,`가당부정`,`성물훼손`,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
  [`질병`,`여귀`,`부인사망`,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,``,``,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``],
  [`질병`,``,``,``,``,`묘좌`,0,`없음`],
  [``,``,``,``,``,`묘향`,0,``],
  [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
  [``,``,``,``,``,`묘속`,0,``],
  [`가정`,``,``,``,``,`육친`,0,``],
  [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,`동북`,`없음`],
  [``,``,``,``,``,`묘향`,`서남`,``],
  [`생업`,``,``,``,``,`전경`,`덩쿨목`,``],
  [``,``,``,``,``,`묘속`,`목근`,``],
  [`가정`,`가장귀`,`호주사망`,``,``,`육친`,`형재5대조`,``]
]
const columnDH = [
  [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
  [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
  [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
  [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
  [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
  [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
  [2,`상위 기쁨-하위 수도자 격`,`신분상승사`,``,`곤궁**병재**소식 끓김-와도 나쁨`,`관직자 승진도약사`,`곤궁`,`외사송쟁사`,`고위안좌-이익경영사`,`가족불화사`,`곤궁**관재**女색정음사`,``,`전도**위례득죄사`,`음4,5월 화재주의`,`명예자산권리사**구설송사 흉-문서사 길함`,`음4,5월 화재주의`,`男색정음사`,``,`고위연회길사-부족`,`매사허성불성**세속허사자**생업가정대립송쟁사`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`매사영속성 없음**종업원남녀모사조짐有`,`종교진출하면 자립함`,`곤궁**관재**도적죄수관련`,`매사위역사**경구침체**암매불통`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`외사동요분산사`,`외사 출행이동사`,``,``,`이동이전사**전화위복`,`재액해소`,`외사 급속사`,`가정막힘`,``,``,``,`재액해소`,`전도`,`외교막힘`,`전도**곤궁`,`말 말고 근신 필요함`,``,`재액해소`,`면모 무관심적 우유한가함`,`성공함**내사 출행이동사`,`음사폐호-가택안녕`,`곤궁`,`전화위복`,`재액해소`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [3,`곤궁**관재**도적죄수관련`,`암매불통`,`상위 기쁨-하위 수도자 격`,`신분상승사`,`소식끊김-와도 나쁨`,`빈궁**병재`,``,`관직-승진도약사`,`외사 급속사**거정막힘`,`고위안죄-이익경영사`,`곤궁**가족불화사`,`女색정음사`,`위례득죄사`,``,`음4,5월 화재주의`,`명예자산권리사**고설송사 흉-문서사 길함`,`음4,5월 화재주의`,`男색정음사`,``,`고위연회길사-부족`,`매사영속성없음**생업가정대립송쟁사`,`종교진출하면 자립함`,`매사영속성 없음`,`종교진출하면 자립함`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,`전화위복`,`외사동요분산사`,`외사 출행이동사`,``,``,``,`이동이전사**전화위복`,`외사송쟁사`,`재액해소`,``,`재액해소`,``,``,``,`외교막힘`,``,`말 말고 근신필요함`,``,``,`면모 무관심적 우유한가함`,`내사 출행이동사`,``,`음사폐호-가택안녕`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [4,`매사 영속성 없음`,`종교진출하면 자립함`,`곤궁**도적실세-잡음`,`매사위역사**암매불통`,`상위 기쁨-하위 수도자 격`,`신분상승사`,`소식끊김-와도 나쁨`,`곤궁**병재`,`관직자 승진도약사`,`곤궁**병재**소식끓김`,`외사송쟁사`,`고위안좌-이익경영사`,`가족불화사`,`곤궁**女색정음사`,`위례득죄사`,``,`음4,5월 화재주의`,`명예자산권리사**구설송사 흉-문서사길함`,`음4,5월 화재주의`,`곤궁**男색정음사`,``,`고위연회길사`,`매사영속성 없음**생업가정대립송쟁사`,`종교진출하면 자립함`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`음사폐호-가택안녕`,`곤궁`,``,`전화위복`,`외사동요분산사`,`외사 출행이동사`,``,``,``,`이동이전사**전화위복`,`외사 급속사`,`가정막힘`,``,``,``,``,``,`외교막힘`,``,`말 말고 근신필요함`,``,``,`면모 무관심적 우유한가함`,`내사 출행이동사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [5,`매사 영속성 없음**생업가정대립송쟁사`,`종교진출하면 자립함`,`매사영속성 없음`,`종교진출하면 자립함`,`불검약- 자초득죄-비밀누설관련`,`암매불통`,`투서헌책부작용 또는 금전유실사`,`곤궁`,``,`곤궁`,`투서헌책-고위면담사`,``,`머리단장적 우유한가함`,`외사 급속사**외사송쟁사`,`女색정음사`,`빈궁`,`매사위역사`,`빈궁`,`희경사-부족`,`명예자산권리사`,`빈궁((병재**재난소멸사`,`男색정음사`,`빈궁**관재**사기득재사`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`동요분산사`,`내사 출행이동사`,`형벌사`,``,`재액해소`,`전화위복`,`외사동요분산사`,`외사 출행이동사`,``,`눈이 가려진 격-불능傷인`,`이동이전사**전화위복`,``,`가정막힘`,`재액해소`,`사행불량사`,`재액해소`,``,`도적소실사`,`음4,5월 화재주의`,`외교막힘`,`음4,5월 화재주의`,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [6,`사기득재사`,`곤궁**관재`,`매사영속성 없음*생업가정대립송쟁사`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`불검약-자초득죄-비밀누설관련`,`암매불통`,`투서헌책불용 또는 금전실재`,`빈궁`,`빈궁`,`눈이 가려진 격-불능傷인`,`투서헌책-고위면담사`,``,`외사송쟁사`,`머리단장적 우유한가함`,`女색정음사**조행불량`,`빈궁`,`빈궁**매사위역사`,`도적소실사`,`희경사`,`명예자산권리사`,`빈궁**병재**男색정음사`,`재난소멸사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`재액해소`,``,`동요분산사`,`내사 출행이동사`,`형벌사`,``,`전화위복`,`재액해소`,`외사동요분산사`,`외사 출행이동사`,``,``,`재액해소`,`이동이전사**전화위복`,`가정막힘`,`외사 급속사`,`사행불량사`,``,`재액해소`,``,`음4,5월 화재주의`,`외교막힘`,`음4,5월 화재주의`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [7,`빈궁**병재**女색정음사`,`음4,5월 화재주의`,`전도**빈궁`,`사기득재사`,`매사영속성없음*생업가정대립송쟁사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`불검약-자초득죄-비밀누설관련`,`암매불통`,`ㅎ투서헌책불용**금전실재`,``,`빈궁`,`눈이 가려진 격-불능傷인`,`투서헌책-고위면담사`,``,`외사 급속사**외사송쟁사`,`머리단장적 우유한가함`,`사행불량사`,`女색정음사**조행불량`,`매사위역사`,`빈궁**도적소실사`,`희경사**음4,5월 화재주의`,`명예자산권리사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`충산동요분산사`,`재난소멸사`,`동요불안분산사`,``,`동요불안분산사`,`내사 출행이동사`,`동요불안분산사`,`형벌사`,`전화위복`,``,`외사동요불안분산사`,`외사 출행이동사`,`동요불안분산사`,``,`이동이전사**전화위복`,`동요불안분산사`,`가정막힘`,`동요불안분산사`,``,`동요불안분산사`,`도적소실사`,`동요불안분산사`,`동요불안분산사`,`외교막힘`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [8,`희경사`,`명예자산권리사`,`男색정음사`,`빈궁**병재**재난소멸사`,`곤궁**사기득재사`,``,`매사영속성없음**생업가정대립송쟁사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`불검약-자초득죄-비밀누설관련`,`암매불통`,`투서헌책불용 또는 금전실재`,`곤궁`,`빈궁**눈이 가려진 격-불능傷인`,`곤궁`,`투서헌책-고위면담사`,``,`외사 급속사**외사송쟁사`,`머리단장적 우유한가힘`,`女색정음사**조행불량`,`사행불량사`,``,`곤궁**도적소실사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`음4,5월 화재주의`,`외교막힘`,`음4,5월 화재주의`,``,`재액해소`,``,`동요분산사`,`내사 출행이동사`,`매사위역사`,`형벌사`,`재액해소`,`전화위복`,`외사동요분산사`,`외사 출행이동사`,``,``,`재액해소`,`이동이전사**전화위복`,`가정막힘`,``,``,``,`재액해소`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [9,`매사위역사`,`곤궁**도적소실사`,`희경사`,`명예자산권리사`,`男색정음사`,`곤궁**병재**재난소멸사`,`곤궁**사기득재사`,``,`매사영속성없음**생업가정대립송쟁사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`불검약-자초득죄-비밀누설관련`,`암매불통`,`곤궁`,`투서헌책불용**또는 금전실재`,`빈궁`,`눈이 가려진 격-불능傷인`,`투서헌책-고위면담사`,``,`외사 급속사**외사송쟁사`,`머리단장적 우유한가함`,`女색정음사**조행불량`,`사행불량사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,`음4,5월 화재주의`,`외교막힘`,`음4,5월화재주의`,``,``,``,`동요분산사`,`내사 출행이동사`,`형벌사`,``,`전화위복`,``,`외사동요분산사`,`외사 출행이동사`,``,``,`전화위복`,``,`이동이전사**재액해소`,`가정막힘`,`재액해소`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [10,`女색정음사**조행불량`,`사행불량사`,`곤궁**도적소살사**매사위역사`,``,`희경사`,`명예자산권리사`,`南색정음사`,`곤궁**병재**재난소멸사`,`곤궁**사기득재사`,``,`매사영속성없음**생업가정대립송쟁사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`불검약-자초득죄-비밀누설관련`,`암매불통`,`빈궁**투서헌책불용**도는 금전실재`,``,`빈궁`,`눈이 가려진 격-불능傷인`,`투서헌책-고위면담사`,``,`외사 급속사**외사송쟁사`,`머리단장적 우유한가함-가정막힘`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,`음4,5월 화재주의`,`외교막힘`,`음4,5월 화재주의`,``,``,``,`동요분산사`,`내사 출행이동사`,`형벌사`,``,`전화위복`,``,`외사동요분산사`,`외사 출행이동사`,``,``,`이동이전사**전화위복`,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [11,`가정막힘`,`고위안좌-이익경영사`,`관재**가족불화사`,`女색정음사**조행불량`,``,`위례득죄사`,``,`명예자산권리사**구설송사 흉-문서사길함`,`곤궁`,`男색정음사`,``,`고위연회길사`,`매사영속성없음**생업가정대립송쟁사`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면자립함`,`매사위역사**암매불통`,`도적실세-잡음`,`상위 기쁨-하위 수도자 격`,`신분상승사`,``,`전도**병재**소식끓김-와도 나쁨`,`이전이동사`,`관직자 승진도약사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`외사 급속사**외사송쟁사`,`이동이전사**재액해소`,``,`재액해소`,``,``,`음4,5월 화재주의`,`외교막힘`,`말 말고 근신필요함`,`음4,5월 화재주의`,``,``,`면모 무관심적 우유한가함`,`내사 출행이동사`,`음사폐호-가택안녕`,``,`전화위복-부족`,``,`외사동요분산사`,`외시 출행이동사`,``,``,``,`전화위복`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [12,`이전이동사`,`관직자 승진도약사`,`외사 급속사**가정막힘`,`고위안좌-이익경영사`,`가족불화사`,`女색정음사`,``,`위례득죄사`,``,`명예자산권리사**구설송사 흉-문서사길함`,`말 말고 근신필요함`,`男색정음사`,``,`고위연회길사`,`매사영속성없음**생업가정대립송쟁사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`도적실세-잡음`,`매사위역사**암매불통`,`상위 기쁨-하위 수도자 격`,`신분상승사**외사동요분산사`,``,`곤궁**관재**소식끓김-와도 나쁨`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,`전화위복`,`외사송쟁사`,`이동이전사**재액해소`,``,`재액해소`,``,``,`음4,5월 화재주의`,`외교막힘`,``,`음4,5월 화재주의`,``,``,`면모 무관심적 우유한가함`,`내사 출행이동사`,`음사폐호-가택안녕`,``,``,`전화위복-부족`,`외사 출행이동사`,`폐한인`,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [1,``,`곤궁**병재**소식끓김**와도 나쁨`,``,`관직자 승진도약사`,`외사송쟁사`,`고위안좌-이익경영사`,`가족불화사`,`女색정음사**조행불량`,``,`위례득죄사`,``,`명예자산권리사**구설송사 흉-문서사길함`,`유구무언적**근신요됨`,`곤궁**男색정음사`,``,`고위연회길사`,`매사영속성없음**생업가정대립송쟁사`,`종교진출하면자립함`,`매사허성불성 세속허사자`,`음3,6,7,8,9,12월방문자는 종교진출로`,`도적실세-잡음`,`곤궁**매사위역사**암매불통`,`상위 기쁨-하위 수도자 격`,`신분상승사***외사동요분산사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,`재액해소`,``,`이동이전사**전화위복`,`외사 급속사`,`가정막힘`,``,``,``,``,`음4,5월 화재주의`,`외교막힘`,`음4,5월 화재주의`,`말 말고 근신필요함`,``,``,`면모 무관심적 우유한가함`,`내사 출행이동사`,`음사폐호-가택안녕`,`성공함`,``,`전화위복`,`외사 출행이동사`,`재액해소`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
]

const CHOSA1 = [`■ 도망가야 할 관재는 어쩌고 새 일에 열중할까? 
이건 자기 과신이 지나친 경우야!

⊕도로귀,가택음사부정듬. ⊖가택귀해소부터하고일을    해도 해야지!
`,`■ 내부 금전․여자문제로 의심과 질투가 가득하겠어!
진실을기도해봐! 
반드시 하늘의 도움을 얻게 될꺼야 !

⊕⊖신변가당신사부정이침범      했네 ?
`,`■시작은 있는데 끝이 없네? 거기다 색정사까지 끼어 들어 있고!
아랫사람이 병주고 약주고 하겠네?

⊖가택상가부정이 들었다.
`,`■ 새로 하는 일이 있겠는데 아랫사람한테 망신 당하게 생겼네? 
一女二男삼각관계도있고!
⊕도로귀, 
⊖가택귀가 문제야!

⊕신변가당신사부정이
들었네 ?
`,`■ 순풍에 돛단격일세?
그러나 가정에 男:⊕⊖가당신사 부정이 들어 남의 부인을 유혹하여 거래하고 있겠어!

⊕⊖가택가당신사 부정이 들었다.
`,`■옛일, 옛 인연이 다 끝났어! 사람이고 집이고 다 기울겠어!

⊕여귀, 
⊖전흉사귀가 버티고 있어서     그래!

⊕신변음사부정이 들었어!
`,`■ 부부 남녀 마음이 달라 색정사가 벌어졌는데 신규사를 꾸민다고 일이 되겠어?
육친간에 하극상 빙탄이니 의지할데도 없겠고!

⊕여귀,
⊖가택음사부정듬.전흉사귀가    침범해서 그래!
`,`■돈과 여자,두 가지 중 하나로 선택은 해야겠는데 마음에 의심과 질투만 가득해!
기도를 해야 돈ㆍ여자 문제가 풀리겠어 !

⊕⊖신변가당신사부정이침범
  했네 ?
`,`■ 만사가 꽉 막혔네? 
 내부 집안 일에 눈가릴 사정이 있겠네 !
지금은 시작도 없고 끝도 없는 운이야 !

⊕가택상가부정이 들었다.
`,`■ 새로 일은 하고 싶은데 여건은 안되고 집도 넘어가겠네 ? 거기에다 또 一男二女 삼각관계까지 끼어 들어있고 !
⊕여귀, 
⊖전흉사귀 장난이야 !
⊖신변음사부정이 들었어!
`,`■ 돈깨나 부서졌겠는데 
기도부터 해야 길이 열려 ! 집에 
⊕⊖가택가당신사 정이 들어 男:남의 부인을 유혹해 내      여자처럼 쓰고 있네 !
`,`■ 현재는 다리 뻗고 쉴데가 없겠는데 형편에 맡기면 곧 되는게 있어 !
그런데⊕변사귀,⊖전사귀가 가정에 침범했어!
이것을 없애야 되는 일이 있을거야!
⊖신변음사부정이 들었어!`];
const YOGYEOL1 = [`■ 연명으로 고소나 진정을 당하니 공무원은 상사에게 문책당한다.
내가 상대에게 구하고 나중에는 화목하게 된다.
`,`■ 삼전에 좋은 것이 많으니 모든 일을 해볼만 하다. 염려는 삼전 모두 밤중인 것이다. 낮정단은 관문을 돌파하게 된다.
`,`■ 내가 타인을 망신시키려 하면 상대가 나의꾀를 알아차린다. 하늘의 뜻은 돌고 도는 것이니 서로 무례한 것에 눈물 흘린다.
`,`■ 약은 적고 병은 많으니 어찌 병을 이기고 면할까. 지키나 움직이나 재앙이요, 두 귀인은 성질만 낸다.
`,`■ 밤정단은 귀신이 범을 탄 것과 같다. 
낮정단은 흉이 가볍다.
나는 잘되고 상대는 패한다. 집은 없어져도 사람은 좋으리라.
`,`■ 서로 패살을 만나니 여름철 밤정단은 화재가 괴이하다. 말전이 비록 관귀가 되나 자세히 보면 겁날 것 없다.
`,`■ 피차 합하지 말라.
사귀되 담담하기를 물같이 하라. 생하거나 극함이 없으니 이와같이 끝나게 된다.
`,`■ 녹신과 덕신이 극을 당하니 직장이나 존장은 해롭다. 밤정단은 귀인이 입을 다물고 집 또한 그림자가 생긴다.
`,`■ 처음과 끝이 모두 비었고 중간에 나쁜 것이 있다. 두 귀인을 믿지 말라. 끝내 곤궁함을 펴지 못하리라.
`,`■ 말로만 나를 돕고 중간에 재물이 흩어진다.
재앙은 내가 사서 당하고 밤정단은 매우 흉하다.
`,`■ 먼저 죽고 나중에 살아난다. 그러나 끝까지 편하지 않다. 서로 쳐다보아도 두 귀인은 바쁘기만 하다.
`,`■ 사람이나 집이나 그물이 덮치니 현재 그대로 지키라.
분수밖의 짓을 하면 나쁜 일이 허다하리라.`]
const SIBO1 = [`■가내 우환이 있고 가족이 
가장을 속이며 원한을 
품고 加害하려 한다.
凶을 凶으로 제어한다.
선두에 서면 凶이 된다.
일을 중지하라. 좌절된다.
二心을 가지면 재화를 당한다. 多人의 기만에 시달린다. 조용히 때를 기다리라. 凶이 빠르다.
가을점은 관재가 있다.
속임을 당한다.
`,`■관재가 있다. 
사물이 분명하지 못하고
人宅이 어둡다. 구재의
목적은 달성하나 동료ㆍ형제에 의해 손모될것이다.  
가정내부에 금전과 여자문제가 의심과 질투를 유발하고 있다.
正月에 임신사가 있다.
`,`■자충수적 우환이 기한다.
시작은 있으나 끝이 없다.
서로 화합하지 못하고 구설
문서가 분분하다.
모망사ㆍ금전사를 불성한다. 스스로 덕을 쌓아 凶을 제어해야 한다.
특히 여자로 인하여 결과를 감(減)하게 된다.
속임을 당한다.
正月에 임신사가 있다.
`,`■자충수적 우환이 기한다.
女ㆍ妻ㆍ상대는 헌신 가해
하게 된다.
一女二男의 색정사가 있다. 下人으로부터 능욕을 당한다. 꿀속의 독을 경계하라. 재해는 벗어나지만 鬼害가 남는다. 이익사가 있어 財를 소득한다.
여난을 주의하라.
`,`■폐지사를 부흥시키려 한다.
災ㆍ病이 있다.
다인 다사건이다.
人盛하고 가택ㆍ업소는 좁으나 이사하면 오히려 凶이 된다. 웃음속의 칼을 조심하라. 
男이 女를 적극적으로 유혹하여 거래하는 상이다. 사통사만 주의하면 吉兆를 이루게 된다.
`,`■가택손상의 兆가 있다.
계획사는 위험하다.
여러사건이 겹쳤다.
교섭사는 타협이 된다.
금전과 여자로 놀라는 일이 있으나 금방 해소된다. 宅ㆍ人이 쇠퇴하는 상이다. 多人의 비웃음을 사고 있다.
女는 上人의 혜택을 입게 된다.
별리ㆍ고독자이다.
正月에 임신사가 있다.
`,`■가택손상의 兆가 있다.
원행이동사가 있다.
二事가 반복된다.
小災ㆍ大滯이고 목적사가 달성되지 못하고 있다.
불행중 다행한 경우이나 그 행로는 대개 불행하게 된다. 女는 음식으로 몸을 상하게 된다.
財ㆍ妻로 인해 분쟁이 야기된다.
속임을 당한다.

`,`■계획사가 위험하다.
금전․여자로 의심질투를
한다.
단, 금전사는 양호하다. 大財가 入한다.
기도ㆍ치성을 하면 더욱 吉하다.
正月에 임신사가 있다.
`,`■처음에는 일이 지지부진
하더라도 참아야 한다.
일이 광명하므로 끝까지 凶을 피하는데 주력하면 된다. 
허비출재가 많다. 
함부로 움직이고 방만한 것을 경계해야 한다.
他人에 속는 일이 있다.

`,`■가택의 권리가 넘어간다.
사물이 양갈래이다.
男ㆍ夫는 가정에 헌신한다.
목적사는 절반의 성공이 있다. 外에서의 이동사는 중지된다. 어떤 일도 內起出外가 된다. 一男二女의 삼각관계가 있다.
사물을 개혁ㆍ혁신한다.

`,`■男은 他人의 부인을 
유혹하려 한다.
관재가 있다.
일이 서로 연결되어 미혹이 많다. 음모ㆍ기도를 大事ㆍ大人에 접근하는 쪽으로 하면 좋다. 천우신조를 받는다. 일이 적절하게 이루어 진다. 피신이 좋은 입장에 있다.
속임을 당한다.
`,`■가내 우환이 있고, 가장을
속이며 가인이 원한을 품고
加害하려 하는 兆이다.
적선과 덕행으로 세월을 보내라. 장차 광명이 기다린다. 처음에는 지체되나 곧 형통하게 된다.
春ㆍ夏月 정단이면 더욱 吉하다.
관리는 4년 후에 上官이 된다.`]
const SIBO2_1 = [`■ 직녹, 신변외사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 재ㆍ여 임신사가 있다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 손모, 화합사가 있다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 官･夫,가택내사이다.
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.
모사에 이심이 있다.
`,`■ 손모, 가택침체사이다.
내방조짐 상의 : 
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
자손재액 예방이 필요
`,`■ 재ㆍ여, 임신사가 있다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 官･夫,가택내사이다.
내방조짐 상의 : 
은둔ㆍ도망ㆍ원행에 이롭다.
색정불화가 있다.
`,`■ 재ㆍ여 임신사가 있다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 손모, 후회심이 있다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 여행을 삼가라.
`,`■ 자손ㆍ속은, 신변외사이다.
내방조짐 상의 :  
원행·이동사가 있다.어떤 일이든 처음 불성한다. 속는일이 있고 자손재액이 있다.
`,`■ 극처, 가정상쟁사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 극처, 가정상쟁사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
`/static/images/sagwa/015/row-1-column-1.png`,
`/static/images/sagwa/015/row-1-column-2.png`,
`/static/images/sagwa/015/row-1-column-3.png`,
`/static/images/sagwa/015/row-1-column-4.png`,
`/static/images/sagwa/015/row-1-column-5.png`,
`/static/images/sagwa/015/row-1-column-6.png`,
`/static/images/sagwa/015/row-1-column-7.png`,
`/static/images/sagwa/015/row-1-column-8.png`,
`/static/images/sagwa/015/row-1-column-9.png`,
`/static/images/sagwa/015/row-1-column-10.png`,
`/static/images/sagwa/015/row-1-column-11.png`,
`/static/images/sagwa/015/row-1-column-12.png`,
]

const HEASYEOL = [
// 1
`  이 과는 천지(天地) 십이신(十二神)이 본궁(本宮)에 숨기 때문에 복음과(伏吟課)로 된다. 초전은 일간상신을 쓰고 중․말전은 형충(刑冲)으로써 정한다. 양일(陽日) 복음(伏吟)은 스스로 강하다고 자부자임(自負自任)하기 때문에 자임격(自任格)이라고 부른다. 스스로 강력하다고 자임하여 선두에 서면 흉해를 만난다. 삼전이 화극금(火剋金), 금극목(金剋木)으로 말전의 목(木)은 일간을 극하여 체극(遞剋)으로 된다. 관직 또는 현임관․공직원은 호조(好調)로 진전한다. 일간상신이 초전으로 되고 말전은 일지에 돌아온다. 이와 같은 것은 나는 그에게서 구하고 말전은 초전을 목생화(木生火)로 상생하영 결국은 화협(和協)으로 된다.
자임복음(自任伏吟)은 굴(屈)하여 신(伸)하지 않고 숨어서 발(發)하지 않는 상이다. 조용히 바른 것을 지키는 것이 좋고 동할 때는 정체된다. 말전은 초전을 돕기 때문에 음(蔭)의 방조가 있다. 백법에 말하기를 ｢간상(干上)은 지(支)에 전하고 삼전이 체극하여 중인(衆人)에게 기만당한다｣고 하였다. 비전에 말하기를 ｢진자(辰子)가 지의 앞에 있으며 과전이 형극(刑剋)하고 사귀(蛇鬼)가 가택에 들어가면 탄핵을 받아서 해임 또는 실직의 우려가 있다｣고 하였다. 체극(遞剋)의 기세에 두려움을 이루어 모든 일이 좌절될 우려가 있다. 그러나 말전이 초전을 생하는 것은 어떤 일을 중지함으로써 오히려 음조(陰助)가 있다. 왕록(旺祿)이 일간 상신에 임하는 것은 분수를 지키는 것이 좋고, 길이라 하더라도 망동하거나 따로 모사(謀事)하면 화환(禍患)을 초래한다.
일귀는 순행하여 삼전이 구(勾)․백(白)․사(蛇)이며, 야귀는 역행하여 삼전이 주(朱)․후(后)․청(靑)이다. 청신(靑神)이 일귀(日鬼)를 만드는 것은 행(幸) 중에 불행을 초래하는 것이다. 중전의 공망은 단교(斷橋) 또는 요절(腰折)이라고 부르며, 좌절하거나 또는 목적의 피안(彼岸)에 도달하지 못한다.`
,
// 2
`  이 과는 4과 중에 3과가 모두 상하의 극이 있다. 양일(陽日)이 되면 양극(陽剋)이 자축(子丑)을 채용하여 초전으로 하고 지일비용과(知一比用課)가 된다. 삼전 자해술(子亥戌)은 퇴여격(退茹格)으로 둔간하여 을병정(乙丙丁)의 삼기(三奇)를 얻는다.
모사는 모두 활동 노력하면 효과가 있다. 오직 말전이 일간지상신에 숨기 때문에 사물이 명확하지 않다. 삼전이 야지(夜地)에 연결되기 때문에 중음(重陰)이라고 한다. 지일격(知一格)은 동류(同類)에 의해 일이 발생하고 의혹이 생기는 부정(不定)의 징조가 있다. 간지(干支)가 묘(墓)에 복(覆)하여 인택이 혼매(昏昧)하다. 그러나 동월(冬月)의 정단은 순리롭다. 백법에 말하기를 ｢후(后)․합(合)이 승하므로 결혼을 정단하면 자유연애 결혼이다｣고 하였다. 비전(秘傳)에 말하기를 ｢간(干)은 부(夫)로 되고 지(支)는 처(妻)로 되며 상신에 육합이 승하는 것은 사정(私情)의 일이 있다｣고 하였다.
삼전에 일간의 재신(財神)이 겹치는 것은 구재(求財)의 건이다. 재를 구하는 것은 가능하지만 일간일지상신의 비견이 그 재를 빼앗기 때문에 재(財)의 싸움을 초래하고, 남명(男命)은 처재(妻財)를 극한다. 자(子)는 향명(向明)이라고 불러도 퇴전(退傳)하여 해술(亥戌)로 후퇴하는 극음(極陰)의 지(地)이다. 그러므로 공명을 구하여도 길조가 없다.
일귀는 순행하여 삼전이 후(后)․음(陰)․현(玄)이다. 야귀는 역행하여 삼전이 백(白)․상(常)․현(玄)이고 말전 무(戊)에 일야(日夜)가 함께 현무를 승한다. 때문에 실탈(失脫) 도난의 일이 있다. 일간상신(日干上神)에 진(辰)이 있어도 말전의 술(戌)은 충(冲)하여 개묘(開墓)로 된다. 자신의 암매(暗昧)는 열려서 재해를 이루지 않는다.`
,
// 3
`  이 과는 4과 중에 3과에 상하의 극이 있다. 제2과의 하에서 상을 적(賊)하는 축묘(丑卯)를 초전으로 하여 중심과로 된다. 제3국의 대부분은 역간전격(逆間傳格)으로 된다. 삼전의 축해유(丑亥酉)는 극음격(極陰格)이다. 
일간일지상신은 자묘(子卯)의 지형(支刑)이며 예(禮)가 없는 형(刑)으로서 피아(彼我)가 함께 화합하지 못하고 덕(德)을 수(修)하여 흉을 해제한다. 즉 백법에 말하기를 ｢피아(彼我)의 의지가 투합하지 않는 것은 지형(支刑)에 있다｣고 하였다. 비전에 말하기를 ｢주작이 일(日)을 극하면 분분(紛紛)하게 되어 바라는 일이나 구재는 모두 성취하지 못한다. 말전이 공망되고 만사가 시작이 있고 결과가 없다｣고 하였다. 일간상신이 관성(官星)으로 되고 일지상신이 재성(財星)으로 되어 재관쌍미(財官雙美)로 되고 명리(名利)가 함께 달성하여도 패기(敗氣)로 되면 경미하다. 즉 무(戊)에 의해 묘(卯)는 목욕(沐浴)이며 인목(寅木)에서 자(子)를 보면 목욕으로 된다.
일귀는 순행하여 삼전이 귀(貴)․음(陰)․상(常)으로서 길장이며, 야귀는 역행하여 삼전이 공(空)․상(常)음(陰)이다. 야귀가 묘(卯)에 임하면 자기를 수호하여 시기를 기다리는 것이 좋다.`
,
// 4
`  이 과는 4과 중에 제1과 인(寅)에서 무토(戊土)를 극하여 초전으로 되는 원수과(元首課)이다. 삼전 인해신(寅亥申)은 원태격(元胎格)이며, 간상신(干上神)에 임하여 간(干)을 극하므로 귀효(鬼爻)가 되지만 신금(申金)이 구신(救神)이므로 귀살(鬼殺)을 두려워할 것은 없다. 그러나 사과삼전 중에 5위의 인(寅)이 있고 양위(兩位)의 신(申)은 제신(制神)이 부족하여 약간의 귀해(鬼害)가 있다고 본다. 동할 때는 초전의 귀(鬼)를 만나서 화를 받는다. 귀신(貴神)은 일야(日夜)가 모두 진술(辰戌)의 옥(獄)에 들어가기 때문에 귀인의 노여움을 만난다.
백법에 말하기를 ｢인해(寅亥)는 지합(支合)하고 인목(寅木)은 일간의 살(殺)로 된다｣고 하였다. 즉 합(合) 중에 살이 있으면 꿀 속에 비소의 독이 있다. 일귀는 순행하여 삼전이 사(蛇)․음(陰)․백(白)이고, 야귀는 역행하여 삼전이 청(靑)․상(常)․현(玄)이다.
이 과는 일간일지상신은 무(戊)에 의해 인(寅)이 장생으로 되고 인(寅)의 목(木)에 의해 해(亥)가 장생으로 되어 모든 일은 호조(好調)로 변화한다. 일지는 간상에 와서 일간을 극하고 내몸은 가인(家人)에게 극을 당한다. 즉 하(下)로 인해 나는 반대로 능욕당하는 상이다.`
,
// 5
`   이 과는 4과 중에 제3과의 하에서 상을 적하는 술인(戌寅)을 초전으로 하는 중심과(重審課)이다. 두괴(斗魁)의 술(戌)이 지상(支上)에 승하여 초전으로 되면 참관격(斬關格)이다. 말전의 인(寅)은 귀살(鬼殺)로 되고 야귀는 백호가 승하여 재화 또는 병의 징조이다. 삼합화국(三合火局)은 일간을 생하고 일지는 목생화(木生火)로 탈기한다. 따라서 내몸은 왕성하고 상대방은 쇠퇴한다. 사람이 많으므로 가택이 좋다. 그러나 넓은 택으로 이전하지 말아야 한다. 반대로 재(災)를 초래하기 때문이다. 삼합(三合)은 길이지만 말전이 칠살(七殺)이므로 먼저는 길이고 후에 흉하다.
백법에 말하기를 ｢합(合) 중에 살(殺)을 띨 때는 꿀 속에 비소가 있거나 웃음 속에 칼을 품는다｣고 하였다. 합(合)․후(后)가 있어 점혼(占婚)은 자유 연애 결혼이다. 이것을 일녀격(泆女格)이라고 하며, 여자가 남자를 유혹한다. 초전 술(戌)은 의신(儀神)으로서 흉을 해제하여 길조를 얻는다.
일귀는 역행하여 삼전이 합(合)․백(白)․후(后)이며, 귀신(貴神)이 해(亥)에 임하여 신장살몰(神藏殺沒)로 된다. 이를 귀신(貴神)이 천문(天門)에 오른다고 한다. 신장살몰이란 귀신(貴神)이 건궁신위(乾宮神位)에 임하는 것이다. 사(蛇)는 자(子)에 임하여 추수(墜水)로 되고, 주작은 축(丑)의 계(癸)에 임하여 투하(投河)라고 한다. 구진은 묘(卯)의 극제(剋制)의 지(地)에 임한다. 천공(天空)의 사(巳)의 손궁(巽宮)에 임하면 생(生)을 받는다. 백호는 오(午)에 임하여 소신(燒身)으로 되고, 현무가 신(申)에 임하면 절족(折足)이라고 부른다. 이상의 천장(天將)은 힘이 없어서 흉으로 되지 않는다. 그러므로 진술축미(辰戌丑未)의 사살(四殺)은 사우(四隅)에 빠져 들어가 사살이 죽는다고 하여 이것으로 길조를 이루는 것이다.`
,
// 6
`    이 과는 4과 중에 제1과, 제2과, 제3과에 상하의 극이 있다. 제1과 하에서 상을 적(賊)하는 자사(子巳)를 초전으로 하는 중심과이다. 이 과와 같이 극이 많으면 정단의 건은 한사건이 아니며 대개는 어려운 사건들이다.
일간일지는 교차상생(交車相生)하고 교섭․교환사는 융화로 타협한다. 일지상신 유금(酉金)에 주작이 승하여 일지를 극하면 집안의 화난(火難)을 방재해야 한다. 그러나 유(酉)가 공망하므로 재해는 경미하다. 초전 일귀가 사신(蛇神)에 승하므로 자(子)는 수(水)의 제지(制地)에 있어 처음에 놀라운 사건이 있어도 즉시 소산된다.
백법에 말하기를 ｢간지(干支) 중 어느 것이나 패신(敗神)이 승하여 인택이 모두 퇴패(退敗)의 징조가 있다｣고 했다. 자(子)가 사(巳)에 승하면 구사(舊事)를 절결(絶結)한다. 비전에 말하기를 ｢자(子)에 청룡이 승하는 것은 여자를 점(占)하면 웃어른의 혜택을 받는다. 
주작이 유(酉)에 승하면 재(災)가 발생할 우려가 있으며, 삼전이 체극(遞剋)하면 화기타협(和氣妥協)의 뜻이 없다.｣ 일귀는 역행하여 삼전이 사(蛇)․공(空)․후(后)이고, 야귀는 순행하여 삼전이 청(靑)․귀(貴)․백(白)이다.`
,
// 7
`  이 과는 천지가 칠충(七冲)하고 4과가 모두 상하에 극이 있어 하에서 상을 극한다. 양일(陽日)로 되면 제4과의 인신(寅申)을 초전으로 한다. 중전은 충신(冲神)을, 말전은 충(冲)을 취하여 삼전(三傳)을 구성한다. 삼전 인신인(寅申寅)은 원행(遠行)․이동(移動) 혹은 소쟁(訴爭) 등의 사건을 점단(占斷)한다. 흉신이 흉장을 만나면 손실이 있고 활동하더라도 유익한 일이 없다. 반면 선신(善神)에 길장이 승하면 대소를 불문하고 길조가 있다.
천지음양이 극적(剋賊)하고 반복하여 신음(神吟)하기 때문에 반음과(返吟課)라고 하며 정단하는 일은 오는 자는 돌아갈 것을 생각하고 떠나는 자는 돌아올 것을 생각하여 득실(得失)이 일정하지 않다. 재물을 얻으면 후에 잃고 재물을 잃으면 오히려 성공하는 왕래가 반복하는 불안한 과격이다. 모든 일이 양도(兩途)의 사건이고 질병도 양증(兩症)이다.
이 과는 간지(干支)의 상하가 육해로 되고 교차(交車)는 지합(支合)하여 이해(利害)가 함께 있다. 삼전의 신(申)이 모두 공망하기 때문에 길흉이 함께 공허(空虛)하여 화로 되지 않고 복도 되지 않는다. 4과가 전부 상극하는 것은 도액격(度厄格)으로서 작게는 조체(阻滯)되고 크게는 재해를 만난다. 다행히 삼전이 모두 공망하므로 길흉이 실현되지 않는다. 구류승도(九流僧道)에게는 오히려 길조이다.
백법에 말하기를 ｢피아(彼我)가 시의(猜疑)하는 것은 육해에 의한 것이다. 간지(干支)가 절(絶)을 만나면 계획한 목적을 달성하지 못한다. 거래격(去來格)의 공망은 이동하지 못한다｣고 하였다. 비전에 말하기를 ｢반음과로서 사맹(四孟)은 원태(元胎)를 절(絶)한다. 백호가 삼중(三重)하여 장생으로 되면 불행 중 다행이다. 삼중청룡(三重靑龍)이 일귀(日鬼)를 형성하면 행(幸)이 불행으로 변화한다.
일귀는 역행하여 삼전이 후(后)․청(靑)․후(后)이고, 야귀는 순행하여 삼전이 백(白)․사(蛇)․백(白)이다.`
,
// 8
`  이 과는 4과 중에 상하의 극적이 3위가 있다. 양일(陽日)이 되면 양의 극이다. 자미(子未)를 채용하여 초전으로 하는 지일과(知一課)이다. 4과를 써서 초전으로 하면 맥월격(驀越格)이다.
일간 일지상신은 묘(墓)에 복(覆)하고 사람도 가택도 암매하여 명확하지 않다. 초전은 재신(財神)이 승한다. 그러나 일간일지 상신(上神)․비견(比肩)․겁재(劫財)는 재(財)를 빼앗으니 재(財)의 싸움은 동류․동료에 의해 발생한다. 미(未)는 본 순(旬) 중의 순미(旬尾)에 해당하고 지상신에 가하여 폐구(閉口)로 된다. 4과 중에 합(合)․형(刑)․해(害)가 있어 피아에게 싸움과 해사(害事)를 발생한다.
백법에 말하기를 ｢일순주편(一旬周遍)으로 되면 시종 서로 길하다｣고 하였다. 비전에 말하기를 ｢초전에 청룡, 말전에 육합이 승하면 시태과(時泰課)라고 부르며 만사는 기쁨이 있다｣고 하였다. 지일과는 2상의 극이나 2하의 적이 있고, 양일은 양비(陽比)라고 하여 양의 극을 채용한다. 음일(陰日)일 때는 음의 극을 사용한다. 비(比)란 화(和)를 말하며 비화(比和)로서 이것을 사용한다. 그러므로 지일과(知一課)라고 하는 것이다.
사물은 양사(兩事) 중 하나의 방책이 좋다. 소송사는 화해가 좋고, 교섭․교쟁사(交爭事)는 내게 이익이 있다. 즉 주모자가 승(勝)을 제(制)한다. 찾는 사람․실물(失物)은 가까운 곳에서 구해야 한다. 어떤 일도 하나로서 둘로 되는 것을 싫어한다. 즉 전일(專一)이 좋다. 또 원소(遠疎)를 버리고 근친(近親)을 따라야 한다. 
상이 하를 극하여 용신(用神)을 구성할 때는 일은 외부를 다스리고 벗에 의한 것이다. 이 과와 같이 하에서 상을 극하여 초전을 구성하는 것은 화복(禍福)이 내부에서 발생하며 처첩(妻妾)에 의한 것이다. 간상(干上)은 순주(旬主)의 술(戌)이고 지상(支上)은 순미(旬尾)의 미(未)로서 이와 같은 것은 일순주편격(一旬周遍格)이라고 하여 계획하는 사업은 모두 성취된다. 초전의 재신은 둔간(遁干)하여 병(丙)의 인수(印綬)로 되어 일간을 생하기 때문에 구재(求財)는 가장 길하다. 일귀는 역행하여 삼전이 사(蛇)․상(常)․합(合)이고, 야귀는 순행하여 삼전이 청(靑)․음(陰)․합(合)이다. 청신(靑神)이 재(財)를 형성하여 재(財)가 또 재(財)를 만나니 재(財)의 건은 가장 길조이다.`
,
// 9
`  이 과는 4과에 상하의 극도 없고 요극(遙剋)도 없으므로 양일(陽日)은 지반 유(酉)의 상신을 취하여 초전으로 하는 묘성과(昴星課)이다. 양일은 지상신의 오(午)를 중전으로 하고 말전은 일간 상신을 취한다. 그러므로 초전․말전은 어느 것이나 유(酉)의 공망에 앉고 정단사는 시종이 없다. 공망하지 않은 중전의 1신(一神)으로 길흉을 정단한다.
중전이 양인(羊刃)으로 되고 일귀가 백호에 승하여 동하면 반드시 재화를 초래한다. 묘성호시격(昴星虎視格)은 동작이 삽체(澁滯)하여 모든 일이 전진하기 어렵고 후퇴하기 쉽다. 허비출재(虛費出財)가 많으나 끝까지 공망하면 길흉이 실현되지 못한다. 귀신(貴神)이 묘유(卯酉)에 임하여 여덕격(勵德格)으로 되는데 군자(君子)는 앙천부지하여 부끄러움이 없고 어떤 일도 흉을 피하는 일에 힘쓰므로 흉이 변화하여 길조를 얻을 수 있다.
백법에 말하기를 ｢일간일지가 함께 지반에 의해 천반을 탈기(脫氣)하면 도난을 만나기 쉽다. 호시격(虎視格)으로 되어 호(虎)를 만나는 것은 힘을 쓰기 어렵다｣고 하였다. 비전에 말하기를 ｢축(丑)은 천을귀신(天乙貴神)이 임하고 사(巳)는 술(戌)의 기궁(寄宮)의 신이며, 일지(日支) 인(寅)은 삼전 오(午)에 있어 일진(日辰)에 기(氣)가 있다｣고 하였다. 이와 같은 것은 나도 빛나고 주택도 빛나며 동작도 빛난다. 즉 삼광격(三光格)이라고 한다. 그러므로 묘성과(昴星課)일지라도 만사는 길창(吉昌)을 얻는다.
삼광격이란 일간(日干)을 사람으로 하고 일지를 가택으로 하며 용신(用神)을 동작으로 한다. 이 세 곳이 모두 왕상하여 길장(吉長)이 승하면 삼광(三光)이라고 한다. 만약 말전이 형충극해(刑冲剋害)로 되고 흉장(胸長)이 승하면 삼광(三光)이 그림자를 묻(埋)는다고 칭하여 길도 흉으로 변화한다.
일귀는 역행하여 삼전이 귀(貴)․상(常)․구(勾)이고, 야귀는 순행하여 삼전이 공(空)․음(陰)․주(朱)로 되어 순기(旬奇)로 된다. 일귀(日貴)는 현임 관직에 있으면 길하다. 야귀(夜貴)는 시험․선거․공명사에 유익하다. 순기(旬奇)란 갑신(甲申)과 갑오(甲午)의 순(旬)은 자(子)이고, 갑자(甲子)와 갑술(甲戌)의 순은 축(丑)이며, 갑진(甲辰)과 갑인(甲寅)의 순은 해(亥)이다. 이 과는 초전의 축(丑)이 순기로 된다.`
,
// 10
`  이 과는 제4과에 신사(申巳)의 극적(剋賊)이 있다. 이것을 초전으로 하여 중심과로 된다. 삼전 사맹신(四孟神)은 원태격(元胎格)으로 되며 4과를 초전으로 하는 것은 맥월격(驀越格)이다. 그러므로 4과 중에 1과를 결하여 불비격으로 된다.
수토(水土)는 신(申)에 장생(長生)으로 되므로 일간상신은 장생으로 되고 초전으로 된다. 그러나 신(申)이 공망하므로 초․중전이 공망한다. 중전의 해(亥)는 일간의 재(財)로 되고 초전에 의해 체생(遞生)하여 순리를 얻지만 공지(空地)에 있어서 재(財)는 절반으로 감소된다. 말전의 인(寅)은 일귀(日鬼)로 되고 야귀(夜貴)는 백호가 승하여 흉으로 되는데, 신(申)이 귀(鬼)를 제어하므로 흉이 해소된다. 일간상신에 역마가 승하여도 공망하므로 이동은 외부에 의해 발생하지만 중지된다.
중심과는 하에서 상을 적하므로 대개는 순(順)으로 되지 않는다. 길흉은 내부에서 발생하고, 동하는 것은 후자에 유익하고 전자에게는 불리를 초래한다. 원태과(元胎課)는 사물을 개혁하는 것이다. 초․중전이 공망하여 길흉이 모두 허(虛)하고 녹마(祿馬)가 함께 생한다. 지(地)에 앉는 것은 왕기(旺氣)를 얻어 순공(旬空)이더라도 해(害)는 없다.
백법에 말하기를 ｢권섭이 바르지 않은 것은 녹(祿)이 지상(支上)에 임하기 때문이다. 녹마가 간지상(干支上)에 해당하는 것은 부귀(富貴)가 완전하다｣고 하였다. 비전에 말하기를 ｢말전 귀효(鬼爻)에 백호가 승하면 최관부(催官符)라고 한다. 관리의 부임은 가장 빠르다｣고 하였다. 4과 중 불비(不備)는 어떤 일도 결함이 있다. 남녀 관계때는 삼각관계이다. 일귀는 역행하여 삼전이 청(靑)․주(朱)․후(后)이며 청룡은 공망으로 된다. 야귀는 순행하여 삼전이 사(蛇)․구(勾)․백(白)이고, 말전 귀효(鬼爻)에 백호가 승하여 병단(病斷)은 사액(蛇厄)이 있다. 관사(官事)는 형(刑)을 만난다.`
,
// 11
`  이 과는 제3과 진인(辰寅)이 하에서 상을 극적(剋賊)하여 초전으로 되는 중심과이다. 삼전 진오신(辰午申)은 순간전격(順間傳格)이고 말전이 공망으로 된다. 초전은 묘신(墓神)이 승하여 참관격(斬關格)으로 되고, 말전의 신(申)은 술(戌)에서 보아서 수토(水土)가 장생에 해당되므로 처음은 미혹되고 후에 각성한다.
일간상신은 지묘(支墓)로 되고 지상신은 간(干)의 묘(墓)로 되어 서로 연결되어 미혹이 많다. 천을귀신이 축(丑)에 승하여 해(亥)에 임하면 귀(貴)가 천문(天門)에 올라 모든 흉재를 해제하므로 관직․구직은 가장 길조이다. 중심과는 후에 움직이는 자에게 이롭다. 높게 오르려고 하여도 말전이 공망하여 목적을 달성하기 어렵다. 야귀는 초전이 육합(六合)이고 말전에 천후가 승하여 색정의 건이 일어난다. 대인(大人)․대사(大事)에 유익하고, 상인(常人)․소용(小用)에는 무익하다. 
백법에 말하기를 ｢강색귀호격(罡塞鬼戶格)은 음모(陰謀)나 기도(祈禱)의 건에 유익하다. 피난과 재해(災害)는 피하는 것은 좋다. 간지(干支)가 묘신이되 사람도 가택도 혼미하게 된다｣고 하였다. 후(后)․합(合)은 점혼(占婚)하면 자유 결혼이다. 교동격(狡童格)은 남자가 여자를 유혹한다. 비전에 말하기를 ｢축(丑)이 해(亥)에 가해져 귀신이 천문에 오르고 신장살몰(神藏殺沒)하면 흉해를 피하고 길을 초래하므로 일이 적절하게 이루어진다｣고 하였다. 육합이 진(辰)에 승하는 것은 위례(違禮)라고 부르며, 천후가 신(申)에 승하는 것은 간사(奸私)라고 한다. 
육합에서 시작하여 천후(天后)에 끝나는 것을 교동(狡童)이라고 한다. 남자는 수치를 돌보지 않고 여성을 유혹한다. 일귀는 순행하여  삼전이 합(合)․청(靑)․백(白)이다. 야귀는 역행하여 삼전이 합(合)․사(蛇)․후(后)이다.`
,
// 12
`  이 과는 제4과의 진묘(辰卯)와 하에서 상을 적하여 초전으로 되는 중심과(重審課)이다. 진사오(辰巳午)는 진여격(進茹格)이며, 승계(昇階)라고 하여 관광(觀光)의 기쁨이 있고 만사가 명랑한 광휘(光輝)의 상이다.
일지상신이 모두 왕록(旺祿)으로 일지(一支)의 앞에 있다. 조용하게 지키는 것이 길하고 지나친 망동(妄動)은 불행을 불러온다. 초전 진(辰)은 참관(斬關)으로 되고 사오(巳午)와 진여(進茹)는 참관이 통하여 후에 형통한다. 제4과가 초전으로 되는 맥월격(驀越格)은 다소 지체된다.
이 과를 얻으면 적선(積善)․덕행(德行)을 이루어 타인의 원조가 있고, 처음에는 저해가 있어도 나중에는 희경(喜慶)을 맞이한다. 춘월, 하월은 더욱 크게 유익하다. 백법에 말하기를 ｢나망(羅網)을 만나는 것은 모사가 많으면 졸책을 만난다｣고 하였다. 비전에 말하기를 ｢인묘(寅卯)는 관살(官殺)이다. 관공직은 구직은 선임(選任)하고 초전은 5수(五數) 묘(卯)가 6수에 합하여 11년째에 상관(上官)에 임명된다｣고 하였다. 일귀(日貴)는 순행하며 삼전이 합(合)․구(勾)․청(靑)이다. 이 과는 길조가 많고 흉상(凶象)이 없다. 공명(公明)하는 사건은 유익하고, 음사․음모․간사(奸詐) 등은 이익이 없다.`
,
]


export default {
  columnA,
  columnD,
  columnS,
  columnZ,
  columnAF,
  columnAS,
  columnAU,
  columnBE,
  columnBO,
  columnBS,
  columnBV,
  columnCE,
  columnCL,
  columnCY,
  columnDH,
  // 
  CHOSA1,
  YOGYEOL1,
  SIBO1,
  SIBO2_1,
  SIBO2_2,
  SAGWA_IMAGE1,
  HEASYEOL,
}
