
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,4,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,8,3,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,9,2,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,10,1,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,12,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,12,11,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득 `,1,10,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,2,9,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,3,8,`특별있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,4,7,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,6,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,5,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편갈등사`,`小`,`4`,`도적악의`,`모두근심`],
    [`자녀`,`자녀 관련사`,`大`,`개시기`,`공격말것`,`부부화홥`],
    [`재후혼길(財厚婚吉)`,`상대는 돈이 많아 혼인은 좋은 상`,`大`,``,`불안근심`,``],
    [`백사구우(百事俱憂)`,`이것 저것 모두 근심사 뿐인 상`,`小`,``,``,``],
    [`후원`,`후원조력문서사`,`小`,``,`집정行權`,``],
    [`송쟁`,`외 급속송쟁사-상해우려`,`大`,`5`,`기거適宜`,`소식기대`],
    [`막힘`,`내부 가정 막힘사`,`小`,`배양기`,`외사송쟁`,`와도나쁨`],
    [`수서안일(守庶安逸)`,`지금을 지키면 편안한 상`,`小`,``,``,`가정막힘`],
    [`음신저격(音信阻隔)`,`소식을 기다리림- 와도 나쁨`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,`송구경황`,``],
    [`사업`,`사업 관련사`,`小`,`6`,`(사정상대`,`上喜하승`],
    [`급속`,`내부 가정 급속사`,`小`,`존재기`,`나타남)`,`급속사有`],
    [`총견화지(總見禍至)`,`모두 살펴봐도 화가 닥칠 뿐인 상`,`大`,``,`외사모색`,``],
    [`허회경구(虛獲驚懼)`,`도적을 못잡고 놀라고 두려움을 갖는 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,`군자大慶`,``],
    [`결속`,`음4월 개신하여 동지결속을 크게 이루나 財妻를 훼절함`,`小`,`7`,`改拜封爵`,`불의진취`],
    [`색정`,`외남 색정사有`,`小`,`단절기`,`색정사有`,`압박수난`],
    [`인택계둔(人宅皆迍)`,`모두 헐뜯는 것 뿐이므로 가족이 출입을 삼가하는 상`,`大`,``,``,``],
    [`허회경구(虛獲驚懼)`,`도적을 못잡고 놀라고 두려움을 갖는 상`,`小`,``,``,``],
    [`후원`,`후원 조력 문서 부모 관련사`,`小`,``,``,`정위난측`],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`8`,``,`(상대진심`],
    [`매사 영속성없음`,`후원문서부모사는 선천적 기복재해를 겪음`,`小`,`침체기`,`虛驚의혹`,`가늠안됨)`],
    [`부모관록(父母官祿)`,`부모와 관록이 모두 좋지 않은 상`,`小`,``,``,`색정음사`],
    [`간음무도(姦淫無道)`,`간음색정사가 지난친 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`매사허성불성`,`음1,2,4,5,월 방문자는 종교진출하면 성공함`,`大`,`9`,``,``],
    [`매사 영속성없음`,`관직직업남편남자사는 선천적 기복재해를 겪음`,`小`,`소외기`,`총애다툼`,`손괴재물`],
    [`기리동모(豈利動謀)`,`어찌하여 적극적으로 나서 이익을 좆는가-말려야하는 상`,`小`,``,`허사`,``],
    [`필요성취(必有成就)`,`주력사를 반드시 이루는 상-부족 배신사 有`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,`군자大慶`],
    [`남편`,`남편 관련사`,`大`,`10`,`간음무도`,`改拜爵位`],
    [`색정`,`내녀 색정사有`,`小`,`침해기`,`외사결속`,`색정사有`],
    [`합길산흉(合吉散凶)`,`합하는 것은 길하고 흩아지는 것은 흉한 상`,`小`,``,`화합사有`,`가출우려`],
    [`간음무도(姦淫無道)`,`간음색정사가 지난친 상`,`小`,``,``,``],
    [`결속`,`음4월 개신하여 동지결속을 크게 이루나 財妻를 훼절함`,`小`,``,``,``],
    [`송쟁`,`외사 급속송쟁파재사-실종상해우려`,`大`,`11`,`급속송쟁`,`許獲驚懼`],
    [`동요`,`가정 동요불안분산사`,`小`,`쇠퇴기`,`私門청원`,`동요분산`],
    [`길흉호견(吉凶互見)`,`좋고 나쁜 것을 서로 보게 되는 상-妻의 財 관련`,`小`,``,`좋음`,`도난문제`],
    [`불의진취(不宜進取)`,`퇴진 함이 좋고 전진하는 것은 안 좋은 상`,`大`,``,``,``],
    [`형제`,`형제관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`小`,`12`,`송구경황`,`문서유실`],
    [`출행`,`외사 출행 이동사`,`大`,`과시기`,`(通姦人나`,`면모훼손`],
    [`화환이지(禍患而至)`,`화환이 당도하는 상`,`小`,``,`타남)`,``],
    [`주사개흉(主事皆凶)`,`모두 흉 뿐인 상`,`大`,``,`출행이동`,``],
    [`직위`,`직위 관련사`,`小`,``,``,``],
    [`자산`,`자산 관련사`,`小`,`1`,`모두근심`,`제사불리`],
    [`권리`,`권리사`,`大`,`절정기`,`손실사有`,`도난문제`],
    [`상의관기(常宜官忌)`,`일반인은 좋고 관직자는 나쁜 상`,`小`,``,``,``],
    [`도적입문(盜賊入門)`,`도적이 문에 당도한 상`,`大`,``,``,``],
    [`후원`,`후원 조력 관련사`,`小`,``,``,``],
    [`숙명(宿命)`,`후원조력부친사는 선천적 기복재해를 겪음`,`大`,`2`,`외교막힘`,`고발투서`],
    [`부친`,`부친 관련사`,`小`,`면모기`,`소식기대`,`좋음`],
    [`급취지난(急取遲難)`,`급히 서두르면 취하고 늦으면 못 갖게 되는 상`,`小`,``,`와도나쁨`,`대립송쟁`],
    [`성서헌책(上書獻策)`,`고위에 글과 방책을 올려 길한 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,`투서헌책`],
    [`극부`,`남편 갈등사-출행이동원행사`,`小`,`3`,`尊卑불화`,`고위면접`],
    [`자녀`,`자녀문제사-강제급속손실사`,`大`,`학습기`,`외사급속`,`출행이동`],
    [`자녀극험(子女極險)`,`자녀가 없거나 있어도 극험한 상`,`大`,``,`驚懼사有`,``],
    [`모사미수(謀事未遂)`,`계획사가 완성되지 않은 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,``,"직업직위남편갈등자녀문제사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`후원조력문서송쟁사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`금전사업처조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업색정조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,"관사남편가정색정사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`후원조력문서송쟁사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`형제친구출행사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`직위자산권리사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`후원조력문서부친관련사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,``,"직업직위남편갈등자녀문제사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 지체시키고 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 순응하여 후결로 연기하면 반드시 유리한 결과를 맞는 人`,0,0,`일이 역순으로 진행됨**일이 결과없이 반복되기만 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 지체시키고 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 순응하여 후결로 연기하면 반드시 유리한 결과를 맞는 人`,0,0,`일이 역순으로 띄엄띄엄 진행됨**흉사로 움직임**가정 병질사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`견련위배연기변경불비**만약 타인과 다툰다면 여건이 불비부족하고 결정사안이 변경되므로 믿지말고 별도 대책을 강구해야 함`,0,0,`타인에 의해 한 그물에 걸린 물고기와 같은 처지가 된 人`,0,0,`어떤 일도 위배 변경됨**1녀2남 삼각관계有**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`선택기로불안의혹**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`동료에 의해 중상모략사가 발생하여 불안하고 양갈래 중 선택을 못하고 있으나 가장 친하고 가까이 거주하는 자와 의논하면 해결되는 人`,0,0,`여러 일을 하나로 묶는 개혁개신사가 있음-음4월에 완성함**매사 상배됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 지체시키고 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 순응하여 후결로 연기하면 반드시 유리한 결과를 맞는 人`,0,0,`권리행사사가 있음-음4월에 완성됨**승진영전사도 음4월이라야 됨**가정 색정사有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함 `,0,0,` 매사 배반되고 육친 도 무력하며 동요불안분산사를 겪고 있는 人`,0,0,`심신이 배회하고 있음**독신고독각방주말부부생이사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 지체시키고 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 순응하여 후결로 연기하면 반드시 유리한 결과를 맞는 人`,0,0,`일이 결과없이 반복되고 점점 어두워짐**외남 내녀 부부 동시 색정사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`여러 일을 하나로 묶는 개혁개신사가 있음-음4월에 완성함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`견련위배연기변경불비**만약 타인과 다툰다면 여건이 불비부족하고 결정사안이 변경되므로 믿지말고 별도 대책을 강구해야 함`,0,0,`타인에 의해 한 그물에 걸린 물고기와 같은 처지가 된 人`,0,0,`어떤 일도 위배 변경됨**1남2녀 삼걱관계 有**독신고독각방주말부부생리사별적 처지 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정단속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함`,0,0,`명분있는 사안이므로  정정당당하게 속결처리 않고 좌고우면하거나 뒤로 밀면 되려 불리해지는 人 `,0,0,`가정내사 색정사 有**부부남녀 금슬이 부조함**밝음이 가득했다가 어두워가는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정단속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함`,0,0,`명분있는 사안이므로  정정당당하게 속결처리 않고 좌고우면하거나 뒤로 밀면 되려 불리해지는 人 `,0,0,`퇴하려 하나 계속하게 됨**시작은 있으나 결과가 없음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**상대는 기회를 노리고 움직이지는 않음`,0,0,`스스로 유약하다고 여기고 적극적 활동을 포기하며 신음함`,0,0,`토지가옥사의 소송사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성-무력`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`기사`,`신사`,`돌발사`,`모호사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성-무력`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`미사`,`신사`,`돌발사`,`모호사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`이외사`,`기사`,`신사`,`돌발사`,`모호사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신구중가나`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사-급속사겹침`,`기사`,`구사`,`내사`,`표면사이면사셥침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥-동요`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해함`,`화해함`,`화해함`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`   승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해됨`,`화해됨`,`화해됨`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월-연기 또는 파혼우려`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음11월`,3,2,5,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음10월`,3,1,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음9월`,2,2,4,`음3월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음8월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,2,2,4,`음3월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,1,1,2,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음3월-연기우려`,1,1,2,`음9월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음2월`,2,1,3,`음8월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월`,1,3,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`정서`,3,3,6,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무를 거꾸로 하고 결과없이 반복되며 본인의 자산중식에 분주함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세고 일을 거꾸로 진행하여 선후를 가리기 어렵게 하며 중 종반에 겨우 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무 초반이 허무하고 중 종반에는 자기 세력으로 내부와 반목송쟁을 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 여러 일을 일관시키는 결속당합을 꾀하나 결국 권리를 잃게 되고 비용이 크게 소모됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 유순하고 준수 미모이나 업무가 강력하지 못하고 배경이 없어 권리사가 결여 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 업무는 초반 종반이 허무하고 중반도 반목 대립으로 수준에 못 미침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이며 업무가 강하지 못해도 순준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무를 일관시키는 결속단합을 꾀하며 음4월에 완성하여 세력을 구축함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 업무는 여건이 부족 결여하여 초반은 허무하고 중 종반에 수준을 유지람 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 여러 일을 일관시키는 결속단합을 꾀하여 음4월에 완성하고 큰 재원을 확보하나 본인이 감당하지 못해 허울이 되고 마는 현상을 초래함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯하나 사람을 잘 조정하는 능력이 있고 초 중반에 재정을 확보하나 종반이 허무하여 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 토지 건물사로 내내 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`반대없음`,`불이행`,`선난후난`,`進向`,` 원`,`留`,`세입가해`,`정서`,``,`서북`],
    [`10.11`,`多集`,``,``,``,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`반대없음`,`불이행`,`선損후損`,`進向`,`  원`,`  留`,`세입가해`,`북북서`,``,`북북서`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대잇음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선이후난`,`退背`,`  근`,`  去`,`상호반목`,`정북`,``,`북북서`],
    [`10.11`,``,``,``,`빠르게`,``,``,``,`분쟁발생`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,`세입조력`,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`권리양도`,`退背`,`  원`,`  去`,`설기피로`,`북북동`,`정북`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`의사참고`,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`본인무력`,`退背`,`원근왕래`,`   去`,`세입조력`,`동북`,`정동`,``],
    [`10.11`,`  集`,`기력없음`,``,``,``,``,``,`설기피로`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,`제압불가`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`상호동요`,`정동`,`정남`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`불이행`,`선난후난`,`進向`,`  원`,`  留`,`주인조력`,`정동`,`정동`,``],
    [`10.11`,` 集`,`본인동조`,``,``,``,``,``,`이후손해`,``,``,``],
    [`3.6\n9.12`,``,`끌면제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인동조`,`불이행`,`사안무력`,`進向`,`원근왕래`,`  留`,`주인무력`,`북서`,`남남서`,``],
    [`10.11`,``,`기력없음`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`사안무력`,`進向`,`원근왕래`,`  留`,`주인무력`,`서남`,`남서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선攻후난`,`退背`,`  근`,`  去`,`주인가해`,`남남서`,``,`정남`],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선攻후합`,`退背`,`  원`,` 去`,`주인가해`,`서남`,``,`서남`],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대없음`,`불이행`,`선난후난`,`進向`,`  근`,`  留`,`무난`,`정서`,``,`정서`],
    [`7.8월`,``,``,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,0,0,0,0,``,0,``],
    [``,0,0,0,0,``,0,``],
    [``,0,0,0,0,``,0,``],
    [`질병`,0,0,0,0,`묘좌`,`정북`,`없음`],
    [``,0,0,0,0,`묘향`,`정남`,``],
    [`생업`,0,0,0,0,`전경`,`사찰제각`,``],
    [``,0,0,0,0,`묘속`,  `水`,``],
    [`가정`,0,0,0,0,`육친`,`재매 6代모`,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,`제례부정`,`제례위범`,`육친`,0,``],
    [`질병`, `수귀`,`익사`,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`, `수귀`,`익사`,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,`제례귀`,`제례위범`,0,0,`육친`,0,``],
    [`질병`, `水귀`,`익사`,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,`자살귀`,  `火`,0,0,`묘좌`,0,`특별있음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,`저주부정`,`원한심`,`육친`,0,``],
    [`질병`,`자살귀`,  `火`,0,0,`묘좌`,0,`있음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,`제례부정`,`제례위범`,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사급속사**내사출행이동사사`,`소식이 안옴-와도 불리함`,``,`모든 일이 근심임`,``,`모사 미수사`,``,`상서헌책사 좋음`,`외남 색정사`,`사사성취사`,`매사 허성불성-세속허사자**매사위역사**문서유실사`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`발탁영전도약사`,`외사 송쟁파재사-실종상해우려**내사동요분산사`,`송구경황사-간음사 관련`,`득지행권-기거하기 좋음`,`외사출행이동사**내사급속사`,``,`도적악의-공격말 것-이동사`,`내사 송쟁파재극처사**외교막힘`,`상위 기쁨-하위 수도자 격`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`빈궁**병재`,`전화위복`,`빈궁`,`재액해소`,``,`빈궁`,``,`관재`,``,`빈궁`,`성공함**외사동요분산사`,`말 말고 근신이 좋음`,`전도**빈궁`,`음5월 화재주의**전화위복`,`전도`,`재액해소`,``,`빈궁**병재`,``,`빈궁`,``,`지체경구사**재액해소`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`내사송쟁파재극처사**외교막힘`,`상위 기쁨-하위 수도자 격`,`외사급속사**내사출행이동사`,`소식이 안옴-와도 불리함`,``,`모든 일이 근심임`,``,`모사 미수사`,``,`상서헌책사 좋음`,`외남 색정사`,`사사성취사`,`매사영속성없음**매사위역사**문서유실사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`발탁영전도약사`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`송구경황사-간음사 관련`,`득지행권-기거하기 좋음`,`외사출행이동사**내사급속사`,``,`도적악의-공격말 것-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`관재`,``,`빈궁**병재`,`전화위복`,`빈궁`,`재액해소`,``,`빈궁**관재`,``,``,``,`빈궁`,`외사동요분산사`,`말 말고 근신이 좋음`,`전도빈궁`,`음5월 화재주의**전화위복`,`전도**관재`,`재액해소`,``,`빈궁**병재`,``,`빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`도적악의-공격말 것-이동사`,`내사송쟁파재극처사**외교막힘`,`상위 기쁨-상위 수도자 격`,`소식이 안옴-와도 불리함`,`외사급속사**내사출행이동사`,``,`모든 일이 근심임`,``,`모사 미수사`,``,`상서헌책사 좋음`,`외남 색정사`,`사사성취사`,`매사영속성없음**매사위역사**문서유실사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`발탁영전도약사`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`송구경황사-간음서 관련`,`득지행권-기거하기 좋음`,`외사출행이동사**내사급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,``,``,`재액해소`,`빈궁**병재`,`전화위복**재액해소`,`빈궁`,``,``,`빈궁`,``,``,``,`빈궁`,`외사동요분산사`,`말 말고 근신이 좋음`,`빈궁`,`음5월 화재주의**전화위복`,`관재`,``,``,`건재**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`상대의 심중을 가늠할 수 없음 `,`외사출행이동사**내사급속사`,`진취 흉-퇴 길함`,``,`내사송쟁파재극처사**외교막힘`,`매사위역사**왕래송쟁-퇴-피함이 좋음`,`필유성취사`,`외사급속사**내사출행이동사`,`날개가 꺽인 격-낙제-쟁송무방`,``,`재난이 사라지고 복이 오는가`,``,`고위인 면담-사적방문청원길함`,``,`외남 색정사`,`간음무도사`,`매사영속성없음**매사위역사**필득 총애 다툼사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`절을 고쳐 해야하는  군자 대경사`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`손괴재물사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**병재`,``,`전도`,``,`빈궁`,``,`재액해소`,``,`전화위복**재액해소`,`빈궁`,``,`빈궁`,``,``,`관재`,``,`빈궁`,`외사동요분산사`,`도적은 못잡고 놀라고 두려움`,`빈궁`,`음5월 화재주의**전화위복`,``,``,`관재**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`손괴재물사`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`상대의 심중을 가늠할 수 없음`,`외사출행이동사**내사급속사`,`진취 흉-퇴 길함`,``,`내사송쟁파재극처사**외교막힘`,`매사위역사**왕래사송계류사-퇴, 피함이 좋음`,`필유성취사`,`외사급속사**내사출행이동사`,`날개가 껏인 격-낙제-송쟁무방`,``,`재난이 사라지고 복이 오는가`,``,`고위인면담-사적방문청원길함`,``,`외남 색정사`,`간음무도사`,`매사영속성없음**매사위역사**필득 총애다툼사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`절을 고쳐 해야하는 군자 대경사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**병재`,``,`재액해소`,`빈궁**병재`,`재액해소`,`관재`,``,`전도**빈궁`,``,``,`전화위복`,`빈궁**관재`,``,`전도**빈궁`,``,`관재`,``,``,`전도**빈궁`,`외사동요분산사`,`도적은못잡고 놀라고두려움`,`빈궁`,`음5월 화재주의**전화위복`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`내녀 색정사`,`절을 고쳐 해야하는 군자 대경사`,`손괴재물사`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`상대의 심중을 가늠할 수 없음`,`외사출행이동사**내사급속사`,`진취 흉-퇴 길함`,``,`내사송쟁파재극처사**외교막힘`,`매사위역사**왕래사송사-퇴, 피함이 좋음`,`필유성취사`,`외사급속사*내사출행이동사`,`날개가 걱임-낙제-송쟁무방`,``,`재난이 사라지고 복이 오는가`,``,`고위인면담-사적방문청원길함`,``,`외남 색정사`,`간음무도사`,`매사영속성없음**매사위역사**필득 총애다툼사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사**음5월화재주의`,`전도**관재`,`동요불안분산사**재액해소`,`빈궁*병재`,`동요불안분산사`,`관재**빈궁**병재`,`동요불안분산사`,`관재`,`동요불안분산사**재액해소`,`빈궁`,`동요불안분산사`,`관재`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사**재액해소`,`빈궁`,`동요불안분산사`,``,`동요불안분산사**재액해소`,``,`외사동요불안분산사`,`빈궁`,`전도**빈궁**동요불안분산사`,`도적은 못잡고 놀라고 두려움`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`절을 고쳐 해야하는 군자 대경사`,`손괴재물사`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`상대의 심중을 가늠할 수 없음`,`외사출행사**내사급속사`,`진취 흉-퇴 길함`,``,`내사송쟁파재극처사**외교막힘`,`매사위역사**왕래사송사-토,피함이 좋음`,`필유성취사`,`외사급속사**내사출행이동사`,`날개가 꺽임-낙제-송쟁무방`,``,`재난이 사라지고 복이 오는가`,``,`고위인면담-사적방문청원깋함`,``,`외남 색정사`,`간음무도사`,`매사영속성없음**매사위역사**필득 총애다툼사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`도적은 못잡고놀라고두려움`,`빈궁`,`음5월 화재주의**전화위복`,`관재`,``,`전도**빈궁**병재`,`재액해소`,`빈궁**관재**병재`,`재액해소`,``,`전도**빈궁`,``,``,`관재`,`전화위복`,`빈궁`,``,`전도**빈궁`,``,``,``,``,`외사동요분산사`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**필득 총애 다툼사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`절을 고쳐해야 하는 군자 대경사`,`손괴재물사`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`상대의 심중을 가늠할 수 없음`,`외사출행이동사**내사급속사`,`진취 흉-퇴 길함`,``,`내사송쟁파재극처사**외교막힘`,`매사위역사**왕래사송사-퇴,피함이 좋음`,`필유성취사`,`외사급속사**내사출행이동사`,`날개가 꺽임-낙제-송쟁무방`,``,`재난이 사라지고 복이 오는가`,``,`고위인면담-사적방문청원길함`,``,`외남 색정사`,`간음무도사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사동요분산사`,`빈궁`,`도적은못잡고놀라고두려움`,`빈궁`,`음5월 화재주의**전화위복`,`관재`,`빈궁**병재`,``,`빈궁**병재`,``,``,``,`재액해소`,`빈궁`,``,``,`전화위복`,`빈궁`,``,`빈궁`,``,`전도**관재`,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재난불측사`,`외남 색정사`,`매사영속성없음**매사위역사**문서유실사`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`타인 연루사`,`모망 난수사`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`상대의 심중을 가늠할 수 없음`,`외사출행이동사**내사급속사`,``,`가정경구불녕사-절상가족有`,`내사송쟁파재극처사**외교막힘`,`매사위역사`,`도적 못잡음`,`외사급속사**내사출행이동사`,``,`본부인과 첩의 상호 후회사`,`송구경황사-색정사 관련`,``,`고위인면담-사적방문청원길함`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`빈궁`,`외사동요분산사`,`모두 흉함`,``,`음5월 화재주의**전화위복`,`빈궁**관재`,``,``,`전도**빈궁**병재`,``,`전도**빈궁**병재`,``,`신분차이 음란-존비상몽사`,``,`재액해소`,`빈궁`,`전화위복**재액해소`,`빈궁`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`중상모략이간사`,``,`도적입문 격-도난불리사`,`외남 색정사`,`매사영속성없음**매사위역사**필득총애다툼사`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면자립함`,`내녀 색정사`,`발탁영전도약사`,``,`외사송쟁파재사-상해실종우려**내사동요분산사`,`구설이 심함`,`외사출행이동사**내사급속사`,``,`간음사 유리-정대적이면 되려 재앙이됨-이동사`,`내사송쟁파재극처사**외교막힘`,`왕래송사계류사-퇴,피함이 좋음`,`비상 화합사`,`외사급속사**내사출행이동사`,``,`모든 일이 근심임`,``,`손괴재물사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁**관재`,``,`전도**빈궁`,`외사동요분산사`,`비곡치욕사-폭로관련`,``,`음5월 화재주의**전화위복`,`관재`,`전도**빈궁`,`숲에서 뱀을 마난 격`,`재액해소`,`빈궁**병재`,`재액해소`,``,`전도**빈궁`,``,``,``,`전화위복`,`빈궁`,``,`전도**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`모사 미수사`,``,`상서헌책 좋음`,`사사성취사`,`외남 색정사`,`매사영속성없음**매사위역사**문서유실사`,`종교진출하면자립함`,`매사 허성불성-세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 자립함`,`내녀 색정사`,`발탁영전도약사`,`송구경황사-간음사 관련`,`외사송쟁파재사-실종상해우려**내사동요분산사`,`득지행권-기거하기 좋음`,`외사출행이동사**내사급속사`,``,`도적악의-공격말 것-이동사`,`내사송쟁파재극처사**외교막힘`,`신분차이 음란-존비상몽서`,`소식 안옴-와도 불리함`,`외사급속사**내사출행이동사`,``,`모든 일이 금심임`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`빈궁**관재`,``,``,``,`전도**빈궁`,`외사동요분산사`,`빈궁`,`말 말고 근신이 좋음`,`음5월 화재주의**전화위복`,`관재`,`재액해소`,`전도**빈궁`,`재액해소`,`빈궁**병재`,``,`빈궁`,`존도`,``,`폐한인`,`빈궁**병재`,`전화위복`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`모든 일이 근심임`,``,`모사 미수사`,``,`상서헌책 좋음`,`사사성취사`,`외남 색정사`,`매사 허성불성-세속허사자**매사위역사**문서유실사`,`음3,4,5,6,9,12월방문자는 종교진출하면`,`매사 불성허성-세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`내녀 색정사`,`발탁영전도약사`,`송구경황사-간음사 관련`,`외사송쟁파재사-상해실종우려**내사동요분산사`,`득지행권-기거하기 좋음`,`외사출행이동사**내사급속사`,``,`도적악의-공격말 것-이동사`,`내사송쟁파재극처사**외교막힘`,`신분차이 음란-존비상몽사`,`소식 안옴-와도 불리함`,`외사급속사**내사출행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁`,``,``,`재액해소`,`빈궁`,`재액해소`,``,`빈궁`,`성공함**외사동요분산사`,`빈궁`,`말 말고 근신이 좋음`,`음5월 화재주의**전화위복`,`관재`,``,``,`빈궁**병재`,``,`빈궁**병재`,``,``,``,``,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 관송사가 벌어져 있네?
  땅 사건이겠어! 
  근신하면서 기도ㆍ치성하면     밝아질 수 있겠네!

⊕⊖신변에 신화선영부정이      침범 했네 ?
`,`■자식과 직위 문제가 마음을     괴롭히고 있네? 
 그러나 냉정한 처신이라야     하겠어 !
 한발 물러서는게 오히려 
 좋겠어!

⊕신변 상가부정 침범했어 !
`,`■ 의리와 신의를 잃고 의심만     남았네? 
  일이 거꾸로 가고 있겠어!

⊖가택상가부정이들었는데     해소해야 좋겠어!
`,`■ 일자에 두 남자야!
⊕수귀, 
⊖도로귀가 침범했고 가정에    부정이 가득해서 강을 건너야    하는데 배이 변경되겠네 ! 
  권리상실사가 있겠고 색정    사도 있네? 
 한 여가 없는 격이야!
⊕신변 상가부정 침범했네 ?
`,`■여러일을 묶다가 부부이별을    하게 생겼네?    
 기로에 섰어! 
 집 명의도 넘어가게 생겼고…

⊕수귀, 
⊖도로귀 방해가 너무 심해!
`,`■ 불이 없어서 도장 팔 칼을     못 만들겠어! 
  가정에 지출도 많겠고…
  관직자:巳月에 소원을 이            루겠어!
⊕수귀, 
⊖여귀부터 없애야 도움을     얻지!
`,`■ 어디 기댈데가 없겠네?
  부자․상하가 모두 등을 돌렸어! 
  가정도 불안하고…일은 남을     앞세워 속히 마무리 해야 지     끌면 해롭겠어 !
⊕가택상가부정, 
⊖가택음사부정이 들어서 그래!    자칫 이별사도 벌어지겠어!
⊕⊖신변여고음강부정침범했어!
`,`■ 부부가 똑같이 바람이 났 네?
  일이 반복되기만 하고 어두

  
  워져 가고 있어 !

⊕신불제사귀, 
⊖신부정귀 장난이네! 
 해소시키면 돈은 들어오겠어!
`,`■ 여러건을 하나로 묶는 개혁을     하겠네? 옛것을 버리고 새     것을 얻는 운세야? 
  가정금전ㆍ여자문제로      
  의심질투도 있겠어!
⊕수귀, 
⊖여귀를 해소시켜야 편안해    질거야!
`,`■一男二女 삼각관계가 있네?    가정에는 부모걱정, 바같에는    권리상실사가 있겠어 ! 
 내 뜻이 변경되겠어 ! 
⊕가택저주원심, 
⊖가택여고음강 부정이 가득해서    모든 일이 등을 돌렸어!
⊖신변상가부정이 침범했어!
`,`■부부가 똑같이 바람이 났네?     그러니 금슬이 안좋지!

⊕가택음사부정ㆍ자살귀, 
⊖수귀를 해소시켜야만 공론(空論)ㆍ공화(空話)를 실속있게 할 수     있어!
`,`■ 시작은 있어도 끝이 없네?     윗선에 따져봐도 서둘지      않으면 큰 돈을 놓치겠어!     기도하면 좋아져!
男：여자가 두명이네?

⊖신변상가부정이 침범했네?`];
  const YOGYEOL1 = [`■ 일의 시작이나 집위에 모두 어두운 그늘이다. 나쁜 것을 버리고자 노력하면 여러 사람이 돕게 된다.
  `,`■ 밤정단은 내 녹을 지키기가 어렵다. 투자를 해도 손해난다. 나는 속타고 괴롭고 남은 돈을 많이 벌겠다.
  `,`■ 초전 역마 중전 정신이니 반드시 가만히 있지 못한다. 나쁜일로 움직이게 되고 밤정단은 도적을 주의해야 한다.
  `,`■ 낮정단은 돕는 이를 믿을 수 있는데 왜 딴짓을 할까? 벼슬과 명예가 모두 비었으니 밤정단은 돕는 이를 못 믿는다.
  `,`■ 공망과 묘와 정마가 있어 모두 헛것이 되었다. 낮정단은 귀인의 힘을 입으나 밤정단은 안되는 일이 많다.
  `,`■ 윗사람의 은혜를 받는다해도 그 힘이 매우 약하다. 좋은 것은 없어지고 나쁜것만 많아 좋지가 않다.
  `,`■ 천강이 내게 붙었으니 다리에 바퀴달린 것 같다. 처음도 그렇고 나중에도 헛된 움직임이다.
  `,`■ 입에 꼬리가 붙고 꼬리에 입이 가 있어 서로 물고 물으니 진퇴가 자유스럽지 못하리라.
  `,`■ 피차 싸우게 되니 내 녹을 지키기 어렵다. 하늘의 그물이 보이지는 않으나 서 있는 자리에서 길흉을 당하리라.
  `,`■ 낮정단은 묘위에 범이 앉아 노리고 있고 이같이 셋이나 더 있다. 집은 기울고 나는 답답하니 덕을 쌓고 절도 있게 살아야 한다.
  `,`■ 먼저는 친하다가 나중에는 싸운다. 위아래가 모두 형벌이 되고 웃음속에 독을 숨겼다. 
  겨울 밤정단은 불에 놀랜다.
  `,`■ 손해수가 있는데 또 도적 실물 도망사가 있다.
  병과 재판은 매우 두렵고 돈 문제는 급히 취해야 좋다.`]
  const SIBO1 = [`■ 인택이 모두 퇴폐하는
  징조이다.
  자손사나 직위ㆍ불명예사가 기한다. 
  친구간에 화합ㆍ결합하다가 다투게 된다. 그러나 천장이 吉하여 기도ㆍ치성의 효과로 밝음을 맞게 된다.
  `,`■ 직위나 재산에 음모가
  있으므로 경계해야 한다.
  헛된 움직임을 피하고 냉정을 유지하면 장애가 극복된다.
  
  ⊕고모신사부정, ⊖신화선영부정이다.
  `,`■ 자손ㆍ극복ㆍ도난ㆍ질병
  문제로 凶動한다.
  은둔ㆍ잠복ㆍ퇴복의
  운세이므로 의리를 잃고 의혹을 초래한다.
  병은 치유되고 취직ㆍ영전은 吉하며 단, 신흥종교에 빠져들 기미가 있다.
  `,`■ ⊕고모신사부정, ⊖신화선 
  영 부정이 들어 만사가 
  지체되고 나아갈수가 없는
  형편에 있다.
  타인에게 의지한다.
  내 마음과는 정반대의 결과가 있게 된다.
  경거망동하면 재화가 일어나며 귀신과 부정을 해소하면 凶을 줄일 수 있다.
  
  `,`■ 가택 개동사에 음모가
  들어 권리가 넘어간다.
  다인 다사건이다.
  동료나 친구사이에 벌어진 일이다. 또는 가택을 고치거나 축조하여 그 재산을 잃게 된다.
  기도의 효과로 凶을 줄여야 한다. 
  `,`■ 자충수적 우환이 있다.
  男:권리 상사가 있다.
  女:남편에 凶이 기한다.
  윗사람의 은혜를 받게 되고 명예사는 좋으나 완전하지 못하다.
  관직자는 기대가 안된다.
  귀신을 해소해야 좋다.
  四月 임신사가 있다.
  巳月에 직취하게 된다.
  `,`■ 움직이는 운세이나 공망에
  앉아 여의치 않게 된다.
  헛된 활동일 뿐이다.
  좌절되므로 신덕을 기르는 처신이 유리하다.
  직접 나서지 말고 측면으로 도모해야 이롭게 된다.
  부모의 凶이 기한다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  부부가 서로 외정을 갖고 있어 금슬이 나쁘다. 
  이외 무슨 일이나 다시 시작하는 운세에 있다.
  귀신 해소가 급선무다.
  四月 임신사가 있다.
  `,`■ 가정ㆍ여인사를 개신
  시키려한다.
  다인 다사건이다.
  부부가 각기 외정을 갖고 있다. 의심한다. 
  부부이별하게 된다.
  직위ㆍ재산에 음모가 있다. ⊖남자는 타인의 부인을 유혹하여 거래한다.
  `,`■ 타인이 스스로 와서
  나를 돕고자 한다.
  저주사가 있다.
  가정에 부정이 들어 1남2녀의 삼각관계가 있다.
  마음과는 반대되는 결과가 초래된다. 처음 계획은 적중되지 않는다. 자손사에 凶이 온다.
  `,`■ 재물 도난을 경계해야 한다.
  女人도 뺏긴다.
  부부 모두 외정을 가졌다.
  모든 일에 진실이 없다.
  처음 조건에 결정해야 이롭다. 웃음 뒤에 칼이 숨겨져 있다.
  四月 임신사가 있다.
  男:권리상실가 기한다.
  女:남편의 凶이 기한다.
  `,`■ 실종사건이 있다.
  금전사는 빨리 서둘러
  취해야 한다.
  남자는 두 여자를 취하고 있다. 
  유정함이 지나쳐 손모가 따른다. 그 외 문제는 전진이 안된다. 가정에서 귀인을 만나게 된다.
  만사를 속전속결하라.
  四月 임신사가 있다.`]
  const SIBO2_1 = [`■사업ㆍ후원가택내사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■자손ㆍ박관가택내사이다
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 자손ㆍ극부가정사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.
  조용히 있는게 이롭다.
  자신․동기의 흉이 발생한다.
  `,`■官ㆍ夫신변외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■官･夫,가정내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.재·녀 상실예방필요하다.
  `,`■財ㆍ女,신변외사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  부모상해․불구의 상이다.
  `,`■자손ㆍ극부가정사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.
  조용히 있는게 이롭다.
  모망사는 측면으로 구하라.
  `,`■財ㆍ女,신변외사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  身․宅쇠퇴의 상이다.
  목적사가 지체된다.
  `,`■직ㆍ녹,신변손해사다.
  내방조짐 상의 : 
  병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다. 재·녀
  상실예방이 필요하다.
  `,`■官ㆍ夫,신변외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■財ㆍ女,신변외사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  모사 교섭사에 이심이 있다.
  `,`■財ㆍ女,가정내사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  부부이별의 상이다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/038/row-1-column-1.png`,
    `/static/images/sagwa/038/row-1-column-2.png`,
    `/static/images/sagwa/038/row-1-column-3.png`,
    `/static/images/sagwa/038/row-1-column-4.png`,
    `/static/images/sagwa/038/row-1-column-5.png`,
    `/static/images/sagwa/038/row-1-column-6.png`,
    `/static/images/sagwa/038/row-1-column-7.png`,
    `/static/images/sagwa/038/row-1-column-8.png`,
    `/static/images/sagwa/038/row-1-column-9.png`,
    `/static/images/sagwa/038/row-1-column-10.png`,
    `/static/images/sagwa/038/row-1-column-11.png`,
    `/static/images/sagwa/038/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 천지12신이 본궁에 있어서 복음과이며 상하의 극이 없다. 음일은 일지상신(日支上神)을 사용하여 초전으로 한다. 3전의 축술미(丑戌未)는 가색참관과(稼欌斬關課)이다. 축(丑)은 일간의 묘(墓)로서 일지의 음양신과 초전에도 있으므로 묘(墓)를 사용할 때는 길신은 장애가 있으나 흉사는 발생하지 않으며, 과전이 모두 일간을 생한다. 그러나 과전의 지형(支刑)이므로 화기가 없다. 다만 모든 일에서 정(靜)을 지키면 길하다.
    백법에는 ｢간이 묘(墓)로 되고 참관(斬關)을 합병하는 것은 인택이 모두 퇴폐하는 징조다｣라고 하였다. 지형 상에 있으면 피차간에 투합되지 못하며. 같은 토성(土性)은 교차지형(交車支刑)으로 되어 친구가 결합․화합하는 가운데 다툼을 초래하고 상호간에 예를 잃는다.
    비전에는 ｢초전이 묘(墓)라 해도 말전의 미(未)에서 충하여 파묘(破墓)로 되니 어둠도 밝게 되고 혼미는 깨어난다｣고 하였다. 병환도 충에 의하여 기사회생(起死回生)할 수 있다. 인(印)이 많으므로 자손에 대한 걱정이 있다.
    일귀는 순행하여 3전이 후(后)․상(常)․청(靑)이고, 야귀는 역행하여 3전이 청(靑)․상(常)․후(后)이다. 이 과는 일야천장(日夜天將))이 모두 길신 뿐이므로 흉이 있다면 구(救)할 길이 있다고 본다.`
    ,
    // 2
    `  이 과는 제3과 자축(子丑)이 하에서 상을 극하여 중심퇴여과(重審退茹課)로 된다. 신(辛)의 일간상신 유(酉)의 건록음신(建祿陰神)은 제왕(帝旺)이며, 身은 녹왕(祿旺)에 임한다. 3전 해자축(亥子丑)은 북방수위(北方水位)로서 일간의 설기로 된다. 퇴여격(退茹格)은 망동을 꺼린다. 냉정하면 자연히 복록이 오기 때문이다. 하물며 간상(干上) 유(酉)는 정신(丁神) 금일(金日)로 되고 수(水)는 정귀(丁鬼)를 제어하므로 해(害)가 없다. 오직 움직이면 이롭지 못할 뿐이다.
    백법에는 ｢왕록(旺祿)이 신(身)에 임하는 것은 헛된 동요를 삼가면 길하다｣고 하였다. 백호와 둔간 귀(鬼)가 동병(同竝)하면 재화가 적지 않다. 정극(丁剋)을 만나는 것은 흉화이니 움직임을 주의해야 한다. 말전은 인수(印綬)로 되나 술(戌)과 유(酉)가 육해로 되어 존장과 유정한 가운데 작은 장애가 있다.
    일귀는 순행하여 3전이 음(陰)․현(玄)․상(常)이고, 야귀는 역행하여 3전이 공(空)․백(白)․상(常)이다. 말전에 술해(戌亥)가 겹치면, 진격(眞格)이 아니나 하괴(河魁)가 천문(天門)을 건너므로 다소의 장애가 생긴다.`
    ,
    // 3
    `  이 과는 제2과․제3과의 상하에 극이 있고 하에서 상을 적하는 해축(亥丑)을 사용하여 초전으로 하니 중심과(重審課)이다. 3전의 해유미(亥酉未)는 역간전격(逆間傳格)이며 시둔(時遁)이라 한다.
    초전은 일지의 역마로 되며 중전에 정신이 앉으므로 이동하는 상태이다. 현무․백호가 정신을 만나니 무슨 일이나 흉에 의하여 움직이고 움직이면 손상을 받는다. 일간에서 설기로 되며 현무가 승하는 것도 도난사건을 만나게 되므로 예방해야 한다. 3전의 해유미(亥酉未)는 모두가 음의 경계(境界)이고, 미(未)는 정화(丁火)를 간직한다. 퇴여간전(退茹間傳)은 이미 움직여서 물러서기 때문에 은둔(隱遁)․잠장(潛藏)․퇴복(退伏)의 상태이므로 시둔격(時遁格)이라 이름한다. 일간일지상신 신(申)과 해(亥)는 육해로 되어 상하 서로가 의리(義理)를 잃고 또는 피차간에 의혹을 초래한다.
    백법에는 ｢금일정신(金日丁神)을 만나면 흉화에 움직이며, 육해(六害)를 보면 서로가 상해하고 의심을 생한다｣고 하였다. 건록에 정신이 승하면 사관․부임이 빠르다.
    비전에는 ｢말전이 초전을 극하므로 먼 곳의 여행은 길하며, 병은 치유되고 재화는 해소된다｣고 하였다. 3전에 해(亥)와 미(未)가 있고 묘(卯)가 삼합하니 묘의 일신(一神)을 기다려 3합하는 날 일의 반응이 있다. 
    일귀는 순행하여 3전이 현․백․용이고, 야귀는 역행하여 3전이 백․현․후이다. 해(亥)에 백호․현무가 승하면 신흥 종교를 망신(妄信)할 징조이다.`
    ,
    // 4
    `  이 과는 상하의 상극이 없고 또 요극(遙剋)도 없으며, 4과이지만 두 과는 같으니 3과뿐이다. 이와 같은 것은 별책과(別責課)로 된다. 음일이니 일지 3합의 앞의 지(支)를 사용하여 초전으로 한다. 즉 사유축(巳酉丑)이 3합하고 축(丑)의 앞은 사(巳)이므로 사(巳)를 초전으로 한다. 사과 중에 없는 다른 지(支)를 취하여 사용하므로 별책과라고 이름하는 것이다. 중․말전은 간상(干上神)을 채용하여 합하는 다른 지(支)를 취하는데, 이것은 다른 곳으로 방문하는 과(課)이며 자기를 돌보는 뜻이 있다.
    이 과는 초전이 일귀(日鬼)로 되어 재화(災禍)가 타인에 의하여 일어나는데, 다행하게도 공망되니 두려울 것은 없다. 별책에서는 길흉이 모두 타인의 관계에 의하여 일어난다. 일간일지상신이 묘(墓)에 앉는 것은 육교반졸격(六巧反拙格)이다. 이 과는 불비이니 만사가 바르지 못하며, 재물도 완전히 얻지 못한다. 일에 임하여는 주인을 찾고, 강을 건너려 하며 배를 찾는 격이다. 구혼한 뒤에 따로 취(娶)하고, 임신은 대개 연장된다. 모든 일에서 처음에는 손해를 보고 후에 이익을 얻는다. 길흉은 왕쇠와 천장의 길흉에 의하는데, 만사가 유체(留滯)되어 계속 전진하려해도 나아갈 수가 없다.
    백법에는 인택에 묘신이 엎드리면 회기(晦氣)를 초래한다｣라고 하였다. 
    비전에는 ｢천반이 모두 지반의 묘상(墓上)에 앉는 것은 무슨 일에서나 마음이 뜻과 정(情)을 배반하며, 경거(輕擧)에 의하여 스스로 재화를 초래한다. 또 천지가 지형(支刑)으로 되니 상하에 화기가 없다｣고 하엿다. 
    일귀는 역행하여 3전이 현․백․백이고, 야귀는 순행하여 3전이 동․후․후로서 백호가 겹쳐 보이는 것은 흉조이다.`
    ,
    // 5
    `  이 과는 제1과․제4과의 상에서 하를 극하며 음일이니 음의 극을 사용하여 음일음비(陰日陰比)의 비용과(比用課)로 된다. 3전금국은 종혁(從革)으로 되고, 초전의 공망은 과숙격(寡宿格)이다. 초전의 사(巳)는 일간(日干)의 귀살로 된다. 그러나 공망하고 중전 축(丑)의 묘신도 공망에 떨어져 힘이 없다. 종혁은 일간의 귀살로 되기에 동류동지가 서로 모이고, 간상 오화(午火)와 초전은 명귀로 되고 유(酉)에는 정신이 앉아서 암귀로 되며, 신금(辛金)은 굽혀서 펴지지 않는다.
    백법에는 ｢일지상신의 건록은 권섭부정격(權攝不正格)이다｣라고 하였다. 일간상신 관귀(官鬼)에 천을이 앉는 것은 신기격(神祗格)이다. 
    비전에서는 ｢일간의 녹신이 지상(支上)에 가해지고 일간의 오(午)에 극되어 묘신에 앉으면 가택을 조축하고 재록을 잃는다｣고 하였다. 또 일지 축(丑)에서 3전에 토생금(土生金)으로 설기되는 것은 가택에 의하여 채무를 변제할 사건이 있다.
    일간상신은 관귀이고 일지상신이 암귀(暗鬼)로 되는 데 인택에 모두 동의가 있고 또 관공직에서는 부임이 극히 빠르다. 
    일귀는 역행하여 3전이 현(玄)․사(蛇)․청(靑)이며, 야귀는 3전이 사(蛇)․청(靑)․현(玄)이다.`
    ,
    // 6
    `  이 과는 4과 중 3과에 상하의 극이 있고 하에서 상을 극하는 제4과의 묘신(卯申)을 초전으로 하는 중심단륜주인과(重審斷輪鑄印課)이다. 초전의 묘신(卯申)은 단륜격이고, 묘술사(卯戌巳)는 주인격(鑄印格)이다.
    일간상신에 덕신(德神)이 임하면 웃사람으로부터 은택을 받는다. 단륜격은 이름을 구하면 유익하다. 일간상신 3전은 7살(七殺)이지만 자수(子水)로써 제어하고 또 공망하니 흉의 힘이 없다.
    주인(鑄印)이란 사(巳)는 노화(爐火)이고 술(戌)은 인(寅)으로 하며 묘(卯)가 인모(寅模)로 되어 술(戌) 중에 신금(辛金)이 있다. 사(巳) 안에서 병화(丙火)와 합하여 연주(煉鑄)한다. 이 과는 사(巳)의 공망을 만나 주인이 이루어지지 않으므로 관사(官事)는 중도에서 장애가 있다.
    일귀는 역행하여 3전이 후․구․현이고, 야귀는 순행하여 3전은 현․구․후이다.`
    ,
    // 7
    `  이 과는 12신이 천지충전(天地冲戰)으로 되어 반음과(返吟課)이며, 간상신(干上神) 진(辰)이 3전 중에 있으므로 참관격(斬關格)으로 되며, 초전이 공망이므로 고진격(孤辰格)이다. 진(辰)의 천강(天罡)이 일간에 엎드리고 초전 역마는 동의가 심하다. 그러나 모두 공망되니 의마심원(意馬心猿)으로 헛된 움직임일 뿐이고 참된 뜻을 나타내지 못한다. 
    3전 중에 초․말전이 망하고 중전이 홀로 실하지만 사호(蛇虎)의 흉신이 앉아서 일지를 충하므로 가택과 처첩이 불안하다.
    백법에는 ｢두 귀신이 극을 받으면 귀인이 힘을 얻지 못한다｣고 하였다. 
    비전에는 ｢술(戌) 위에 진(辰)이 가해지면 정란사격(井欄射格)으로 된다｣고 하였다. 무슨 일에서나 오래 움직일 수 없고, 조용하면 걱정이 없다. 길신을 보아도 중도에서 좌절되므로 별도로 구하거나 암암리에 구하는 것이 좋다. 즉 옆길에서 운동하면 효과를 이룩한다.
    일귀는 역행하여 3전이 합․백․음이고, 야귀는 순행하여 3전이 청․사․음이다. 청룡․육합은 길장이지만 공망되므로 즐거운 일이 공허하게 끝나고 만다.`
    ,
    // 8
    `  이 과는 4과 중에 상하의 극이 세 자리 있으며, 하에서 상을 극하는 제1과 묘신(卯辛)을 초전으로 하여 중심과이다. 
    일간상신 묘(卯)는 순수(旬首)의 지(支)로서 각각 일간일지를 극하므로 진퇴가 모두 매우 어렵다. 그러나 주편격(週遍格)이니 시종(始終) 상의(相宜)하다. 일간을 남편으로 하고 일지를 처로 한다. 상신(上神)에서 서로 극하는 것은 무음(蕪淫)이라 하여 부처가 서로 협력하지 않고, 연담의 상성은 불량하다.
    백법에는 ｢수미(首尾)가 상견(相見)되면 주편격이라 불러 시종 완전할 수 있다｣고 하였다. 부부가 사정(私情)을 따로 하는 것은 무요(蕪遙格)에 의한다. 귀살천을(鬼殺天乙)이 일지상신에 승하면 신기격(神祗格)으로서 일간상신 묘(卯)의 재는 사물이 밖으로부터 내게 들어 온다.
    일귀는 역행하여 3전이 후(后)․공(空)․사(蛇)이고, 야귀는 순행하여 3전이 현(玄)․주(朱)․백(白)이다. 주편격(週遍格)은 무슨 일이나 돌아서 다시 시작한다는 뜻이다.`
    ,
    // 9
    `  이 과는 제1과․제4과에 음양의 극이 있다. 오늘은 음일이니 음의 극인 유사(酉巳)를 초전으로 하여 지일비용종혁격(知一比用從革格)이다. 일간상신은 일지를 극하고 일지상신은 일간을 극하여 무음격(蕪淫格)으로 된다. 육합에 천후(天后)가 승하므로 교동격(狡童格)이다. 
    초전의 유(酉)는 일록(日祿)이지만, 공망하고 교호상극(交互相剋)되므로 피차간에 원수로 되는 형상이다. 그리고 무음격이니 자타가 각각 다른 마음을 가진다. 종혁격은 공망하므로 삼정이 되지 않는다.
    백법에는 ｢귀신이 술(戌)에 임하면 옥(獄)에 앉는다 해도 간상(干上)에 앉아서 길조로 본다. 인사(寅巳)․축오(丑午)는 육해로 되어 의심이 서로 따른다｣고 하였다.
    일간상신이 인(寅)의 재로 되어도 3전 금국의 비견이 재를 빼앗는다. 그러므로 재를 얻어도 다시 잃는다.
    일귀는 역행하여 3전이 청(靑)․사(蛇)․현(玄)이고, 야귀는 순행하여 3전이 합(合)․백(白)․후(后)이다.`
    ,
    // 10
    `  이 과는 4과 중에 상하 및 요극(遙剋)이 없고, 4과이나 3과뿐이므로 별책과(別責課)로 한다. 음일에는 일지 3합의 앞에 해당하는 사(巳)를 초전으로 하며, 만일 양일인 경우에는 일간과 간합에 승하는 것을 초용(初用)으로 한다.
    일간상신은 묘(墓)에 엎드리고 백호가 승하며 중․말전에 또 백호를 볼때는 놀라운 사건이 있거나 혹은 병자가 거듭 발생한다. 일간과 일지상신에 묘신(墓神)이 앉으면 인택(人宅)이 모두 음희(淫晦)로 되며, 초전은 귀살일지라도 공망하므로 흉이 없다.
    별책과란 무슨 일에서나 따로 모사를 고쳐 하는 것을 말한다. 대안으로 건너가려 해도 배가 없으며, 갑에게 시집가려하지만 을에게로 시집가게 된다. 갑의 집으로 가려고 하면서도 을의 집으로 가게 된다. 이와같이 모두가 변경되고 고쳐하는 과이다. 
    백법에는 ｢일간에 백호가 묘에 앉는 것은 병을 정단하면 가장 흉하며, 간지(干支)에 모두 묘신이 앉는 것은 피차간에 서로 혼미하다｣고 하였다. 일귀는 순행하여 3전이 합(合)․후(后)․후(后)이며. 야귀는 3전이 후(后)․백(白)․백(白)이다. 병점, 소송 및 타인과의 교섭사는 불리하다.`
    ,
    // 11
    `  이 과는 4과 중의 제3과가 묘축(卯丑)으로 1상이 1하를 극하여 원수과로 된다. 일간일지는 서로 지합(支合)하여 교차상합하나. 4과의 상신은 자묘(子卯)와 인사(寅巳)로 지형하고, 4과의 지반은 술축(戌丑)과 자묘(子卯)로 지형(支刑)이 된다. 상하가 서로 겨루고 내부는 교차지합(交車支合)하여 화합한다. 
    일간은 상신의 자(子)에 탈기되고 천공이 승하면 탈공격(脫空格)으로 된다. 일지상신은 일간의 재(財)가 되는 동시에 초전으로 되는데 현무가 승하면 재물의 도난에 주의해야 한다.
    백법에는 ｢공상탈(空上脫)을 만나면 무슨 일이나 추구하지 말아야 한다. 무음(蕪淫)은 부처 모두 간사(奸私)가 있고, 형상(刑上)에 있는 것은 피차가 투합되지 못하며, 중․말전이 공망하니 초전의 일신(一神)으로 결정한다｣고 하였다. 3전이 체생하면 다른 사람의 천거가 있으나 공망하므로 진정(眞情)이 없이 공론허화(空論虛話)의 우려가 있다.
    비전에는 ｢상하가 상극하고 일간일지가 교차지합되는 것은 먼저 사귀고 후에는 다툰다｣고 하였다. 모든 일이 웃은 얼굴 뒤의 심증에 독이 있다. 
    일귀는 순행하여 3전이 사(蛇)․합(合)․청(靑)이니 사(蛇)가 용으로 화하여 명리가 달성되고, 흉도 후에는 경사로 화한다. 야귀는 3전이 현(玄)․후(后)․사(蛇)이다. 즉 일귀와 야귀는 길흉에 천지(天地)의 차(車)가 있다.`
    ,
    // 12
    `  이 과는 4과 중의 제3과에서 1상이 1하를 극하여 원수진여과(元首進茹課)이다. 일간상신 금생수(金生水)로 탈기(脫氣)하여 현무가 승하면 실종 사건이 있다. 질병과 소송사건의 점단은 흉하며,. 재는 속히 구하면 큰 재를 얻는다. 봄에 재가 왕하는 방위는 동방의 재이고, 일지상신에 구진이 승하여 축(丑)의 택(宅)을 극하면 소송 사건을 방지해야 한다. 
    일간의 음양은 수(水)이고 일지의 음양신은 목기(木氣)로서 수생목(水生木)으로 상대방에 설하여 유정이 지나쳐 내가 손실을 본다. 지반에서 천반의 앞의 지(支)로 되는 것은 나망격(羅網格)이다.
    백법에는 ｢모책(謀策)하여 졸책(拙策)으로 되는 것은 나망격이기 때문이다｣고 하였다. 재(財)가 지나치면 존장에게 불길하다. 진여격(進茹格)은 활동하면 길하지만 말전이 공망하니 전진할 수가 없고 퇴보할 우려가 있다. 또 진여격의 공망은 빈 골짜기에 소리를 전하는 것과 같아 전진해도 이익이 없다. 
    비전에는 ｢귀신이 인(寅)에 앉으면 가정에서 윗사람․귀인(貴人)과 만나는 일이 있으며,. 말전의 공망은 처음이 있고 종국이 원만하지 못하다｣고 하였다. 만사를 조속히 계획하면 이롭다.
    일귀는 순행하여 3전이 귀(貴)․사(蛇)․주(朱)이고, 야귀는 역행하여 3전이 구(勾)․합(合)․주(朱)이다. 말전에 주작이 승하여 문서 및 음신은 년명(年命)에 진(辰)의 공망이 승하면 목적이 달성되고, 전실공망(塡實空亡)하지 않으면 유명무실로 끝난다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
