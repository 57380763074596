
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`고집`,7,6,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,8,5,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`신뢰`,9,4,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,3,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,11,2,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,12,1,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,1,12,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,2,11,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,3,10,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,4,9,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,8,`특별있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,7,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`10`,``,`승진영전`],
    [`부모`,`부모 관련사`,`大`,`침해기`,`단합결속`,`파재극처`],
    [`봉병우외(逢病尤畏)`,`병이 두려운 상-新병치료,구병 (久病)불치`,`大`,``,`사사성취`,`가출우려`],
    [`승진영전(昇進榮轉)`,`승진 전직이 안 되다가 비로소 이루어지는 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`11`,`문서上申`,`개신사有`],
    [`여인`,`여인 관련사`,`小`,`쇠퇴기`,`지체구설`,`손괴재물`],
    [`범개흠리(凡皆欠利)`,`이득사가 모두 흠이 있게 되는 상`,`小`,``,`파재실종`,``],
    [`손괴재물(損壞財物)`,`재물 또는 가축을 잃은 상`,`大`,``,`우려`,``],
    [`형제`,`형제 관련사`,`大`,``,``,`정위난측`],
    [`급속`,`內사 급속사`,`小`,`12`,`출행이동`,`(상대진심`],
    [`이동`,`외사 출행이동사`,`小`,`과시기`,`화소복지`,`가늠안됨)`],
    [`쟁문토전(爭門土田)`,`토지가옥사로 차량이 다투어 번질나게 오가는 상`,`大`,``,``,`손해가출`],
    [`화소복지(禍消福至)`,`화가 물러가고 복이 도래하는 상`,`小`,``,``,`급속우려`],
    [`직녹`,`직녹자산사`,`大`,``,``,``],
    [`수난`,`수난 관련사`,`小`,`1`,`능욕박해`,`부부남녀`],
    [`재해`,`개혁하여 직업권리사는 크게 이루나 病 형제災害가 발생함`,`小`,`절정기`,`손해도망`,`동요불안`],
    [`은다노심(恩多怒深)`,`많은 은혜를 원수로 갚는 사안에 봉착한 상`,`大`,``,`우려`,`분산다툼`],
    [`허획경구(虛獲驚懼)`,`도적은 못잡고 놀라기만 하는 상`,`小`,``,``,`이혼우려`],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`2`,`외교막힘`,`관직유리`],
    [`막힘`,`외사 외교 막힘사`,`小`,`면모기`,`필유성취`,`평민불리`],
    [`사관불의(仕官不宜)`,`관직자는 뜻에 어긋나 불리한 상`,`小`,``,`이전사有`,``],
    [`필유성취(必有成就)`,`주력사가 반드시 성취되는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`3`,``,``],
    [`자녀`,`자녀 문제사`,`小`,`학습기`,`급속사有`,`개신사有`],
    [`체기동모(切忌動謀)`,`근신이 좋으므로 움직여 꾸미는 일은 절대 삼가해야 할 상`,`小`,``,`문서유실`,`절상인구`],
    [`절상인구(折傷人口)`,`몸 다치는 가족이 우려되는 상`,`小`,``,``,``],
    [`색정`,`내女 색정사 有`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 자립함`,`大`,`4`,``,``],
    [`매사 영속성없음`,`직업지위자녀잘속는일은 선천적 기복재해를 겪음`,`小`,`개시기`,`공허허사`,`색정사有`],
    [`화재조심(火災操心)`,`화재를 조심해야 할 상-단, 면피 격`,`小`,``,``,``],
    [`송구경황(悚懼驚惶)`,`두려운 자가 와서 어쩔줄모르는 상-색정사 상대 관련`,`小`,``,``,``],
    [`결속`,`결속하여 후원사를 크게 이루나 자식災害가 발생함`,`小`,``,`결속단합`,``],
    [`매사 허성불성`,`금전사업여인사는 선천적기복재해를 겪음`,`大`,`5`,`후원성사`,``],
    [`매사 영속성없음`,`금전사업여인사는 선천적 기복재해를 겪음`,`小`,`배양기`,`자녀근심`,`가정모사`],
    [`불순병미(不順病迷)`,`병이 불순하여 판단이 어려운 상`,`小`,``,`발생함`,`미수됨`],
    [`이도청알(移途請謁)`,`길 가는 고위인에게 면담을 요청하여 청원하는 것이 좋음`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,``,``],
    [`남편`,`남편 관련사`,`大`,`존재기`,`봉림유사`,`酒食受罰`],
    [`우방조격(尤訪阻隔)`,`찾아 만나는 것이 서로 쇠약을 면하고 새 삶을 얻는 상`,`小`,``,``,``],
    [`봉림유사(逢林有蛇)`,`먼곳에서 막 당도하여 숲에서 뱀을 만나는 격의 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,`정위난측`,`가정막힘`],
    [`색정`,`외男 색정사 有`,`大`,`단절기`,`(상대진심`,`봉흉화길`],
    [`이이형통(移易亨通)`,`거주지를 옮기면 목적사를 이루게 되는 상`,`小`,``,`가늠안됨)`,``],
    [`구연반복(勾連反復)`,`굽히는 일(굴욕)이 반복되는 상`,`大`,``,``,``],
    [`금전`,`금전 침체사`,`小`,``,``,`음인암손`],
    [`사업`,`사업 공허사`,`大`,`8`,`동요분산`,`사통관계`],
    [`처`,`처 관련사`,`小`,`침체기`,`정위난측`,`출비손실`],
    [`불가사차(不可捨此)`,`지금 것을 버리면 좋은 기회를 잃는 상`,`小`,``,`(상대진심`,`손해가출`],
    [`동지함의(動止咸宜)`,`일과 집안이 잘 다듬어져 가거나 있거나 좋은 상`,`大`,``,`가늠안됨)`,`사 우려`],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`9`,``,``],
    [`이동`,`출행 이동 원행사`,`大`,`소외기`,`후원조력`,`은혜베품`],
    [`필수가근(必須加謹)`,`필수적으로 근신에 근신을 더해야 하는 상`,`大`,``,`有`,``],
    [`면굴난해(免屈難解)`,`굴욕사를 면하기 어려운 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`후원조력외사단합사로 가능-단, 가정사는 숨겨야함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`금전사업여인사로가능-단 외파재사는 숨겨야함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`형제친구동료조력으로 가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`직녹사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`금전사업여인사로가능함 `,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,`직업남편갈등자녀사로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,`세속허사자로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,``,`세속허사자로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`관직사는 가능함`,`관사남편문제로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`관사직업외男색정사로불가함`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업처조력사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,`후원조력문서부모사로가능함`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 세**만약 타인과 다툰다면 피아간 전의가 별로 없고 손상도 없으며 있어도 가벼움`,0,0,`옳지 못한 일이 밝혀져 심중 크게 놀라고 있으나 조용히 근신하면 현재의 두려움이 자연 해소되는 人`,0,0,`당면사에서 퇴하려하나 되려 계속하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 피아간 전의가 별로 없고 손상도 없으며 있어도 가벼움`,0,0,`호가호위 위세로 상대를 공격하더라도 적중시키지 못하여 무용하게 되는`,0,0,`일이 역순으로 띄엄띄엄되어 퇴하려하되려 계속하게 됨**현재가 바뀌어 밝은 데로 나아가게 되는 상**독신고독각방주말부부별리사별의 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 먼저 나서지 말고 유순유화적 처신으로 일을 뒤로 미루면 결과가 오히려 좋음`,0,0,`역상적 사안으로 억울하더라도 절대 반발하지 말고 유화적으로 순응하며 지연시키면 결과가 유리해지는 人`,0,0,`토지가옥소송사**조행불량**女는 재난많음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 정당하게 속결해야만 유리함`,0,0,`현재의 주력사안을 속결하지 않고 머뭇거리면 불리해지는 人`,0,0,`여러 일을 일관시키는 개신개혁사有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송사의 조짐이 있는 人`,0,0,`1녀2남의 삼각관계有**엣것이 모두 끓어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**간신이 있고 천지가 뒤집힌 상으로 재난이 밖에서 오므로 신중해야 함`,0,0,`색정사 반복으로 의지거처가 난처한 지경에 이른 배반사를 겪는 人`,0,0,`간사람은 오려하고 있는 자는 가려하는 처지임**옛일이 재발되는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송사의 조짐이 있는 人`,0,0,`1남2녀의 삼각관계有**일이 갑자기 돌발됨**꼬리가 머리가 되어 어지러운 상**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 정당하게 속결해야만 유리함`,0,0,`현재의 주력사안을 속결하지 않고 머뭇거리면 불리해지는 人`,0,0,`좋은 일이 거듭 생기는 상**관재 우려`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 먼저 나서지 말고 유순유화적 처신으로 일을 뒤로 미루면 결과가 오히려 좋음`,0,0,`역상적 사안으로 억울하더라도 절대 반발하지 말고 유화적으로 순응하며 지연시키면 결과가 유리해지는 人`,0,0,`토지가옥사**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 먼저 나서지 말고 유순유화적 처신으로 일을 뒤로 미루면 결과가 오히려 좋음`,0,0,`역상적 사안으로 억울하더라도 절대 반발하지 말고 유화적으로 순응하며 지연시키면 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**서리 내린 다리를 건너는 격`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 상대와 다툰다면 먼저 나서지 말고 유순유화적 처신으로 일을 뒤로 미루면 결과가 오히려 좋음`,0,0,`역상적 사안으로 억울하더라도 절대 반발하지 말고 유화적으로 순응하며 지연시키면 결과가 유리해지는 人`,0,0,`보물을 품고 미로를 헤매는 격**어두운 삼경을 지나 동트는 때를 기다리는 상**급속 이전 이동사有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`자기 스스로 유약하다고 여기고 부동하며 신음함-신체가 억압되어 있는 경우도 있음`,0,0,`어디 산으로 깊이 숨고싶은 심정**매사 폐색되고 반대당하는 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`좌고우면-무력`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아타발동시사`,`미사`,`신사`,`외내동시사`,`표면이면동시사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈 인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`배신-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사-후회사겹침`,`외사`,`구사`,`외사`,`표면이면겹침사`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-부부별리`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-이사예정`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`합의가능`,`합의가능`,`합의가능`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음9월`,3,1,4,`음3월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음8월`,1,1,2,`음2월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,4,`음6월-연기 또는 파혼우려`,2,2,4,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음1월-연기 또는 파혼우려`,3,2,5,`음7월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월-연기우려`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음10월-연기 또는 파혼우려`,2,1,3,`음10월-연기 또는파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월-연기우려`,3,2,5,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음1월-`,3,3,6,`음7월-`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음12월`,2,2,4,`음9월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음11월`,2,2,4,`음3월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음10월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 초반의 덕망을 잃고 업적이 허망하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초 중반을 허무하게 보내고 종반에 이르러 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 시종 반목대립 송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미가 있으나 사심이 많아 우려되지만 여러 일을 일관시켜 그 개혁이 위험하게 보이나 결과는 매우 효과적으로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻어 초반에 이전사를 일으키고 중반이 허무하나 종반에 이르러 실적을 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 시조일관 동요불안분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순 준수 미모이나 일처리가 강하지 못해 초 중반을 허무하게 보내고 종반에도 수준이 못 되는 결과를 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 결속단합의 혁신으로 탁월한 실적을 보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 시종 토지건물사로 대립반목송쟁을 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것 같으나 상대를 잘 조정하는 능력이 있어 수준급의 업무실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순 준수 미모이나 일처리가 강하지 못해도 수준 이상의 업무능력을 보이게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 상대를 잘 조정하여 동료화합을 이루고 종반에 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대있음`,`이행`,`선不후不`,`進向`,`근`,`留`,`무난`,`북북동`,``,`정북`],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`集`,``,``,``,`u `,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`대상무력`,`進向`,`  근`,`  留`,`주인무력`,`동북`,``,`북북동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`대상무력`,`進向`,`  근`,`  留`,`주인무력`,`정동`,``,`동북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,`이행`,`선損후爭`,`進向`,`  원`,`  留`,`주인가해`,`정동`,`정북`,``],
    [`7.8월`,``,`대세공격`,``,``,``,``,``,`결과내편`,``,``,``],
    [`10.11`,``,`본인측근`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`합세제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선攻후不`,`進向`,`  근`,`  留`,`주인조력`,`동남`,`북북동`,``],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압난함`,`이행`,`선권리양`,`進向`,` 원`,`  留`,`세입가해`,`남남동`,`동북`,``],
    [`10.11`,`  集`,`겨울연기`,``,`도후피해`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`필요함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선費후攻`,`退背`,`  근`,`  去`,`세입가해`,`정동`,`동남`,``],
    [`10.11`,``,`끌면부결`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`측근제압`,`이행`,`선攻후易`,`退背`,`  근`,`  去`,`주인조력`,`동남`,`남남동`,``],
    [`10.11`,``,``,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후무`,`退背`,`  근`,`   去`,`세입조력`,`서남`,`서남`,``],
    [`10.11`,``,``,``,`빠르게`,``,``,``,`쇠약피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대있음`,`이행`,`선이후무`,`退背`,`  원`,`  去`,`세입조력`,`정서`,``,`정서`],
    [`10.11`,``,`무력`,``,`빠르게`,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선공후損`,`退背`,`  원`,`  去`,`주인조력`,`북북서`,``,`0`],
    [`10.11`,`多集`,``,``,`빨리압박`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선不후損`,`退背`,`  근`,`   去`,`무난`,`정북`,``,`동남`],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`제례귀`,`제례위범`,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`도로귀`,`겨통사고`,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`신부정귀`,`신不定`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`신화부정`,`근친망령`,`육친`,0,``],
    [`질병`,`水귀`,`익사`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`水귀`,`익사`,`고모신사`,`성물훼손`,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사 출행이동원행사`,`수신제가되어 가나 있으나 좋은 상`,`빈궁`,`일체근심해소`,`파재극처실종사우려`,`재난이 없어지고 복이 옴`,`외사 출행이동원행사`,`문서 보고서 답신지체-흉절시기사`,`직위녹위사`,`사사성취`,`매사위역**외교막힘`,`굴욕을 면하기 어려움`,`외사 급속사`,`예측이 어려운 근심사`,`매사 허성불성**세속허사 자**내女색정사`,`음1,2,4,5,월 방문자는 종교진출하면`,`매사영속성없음`,`종교진출하면자립함`,``,`승진영전`,`외男색정사**내부가정막힘`,`도적악의-반대공격불의`,`손해가출사우려**침체경구사**매사위역`,`불륜암손사**외사동요분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,``,`전도**빈궁`,``,`전도**관재`,`내사 급속사`,`빈궁`,``,`빈궁`,``,``,``,`빈궁`,`성공함`,``,``,`전화위복`,``,`관재`,``,`관재*빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역**손해가출사우려`,`불륜암손사**외사동요분산사`,`내사 출행이동원행사`,`수신제가되어 가나 있으나 좋은 상`,``,`일체근심해소`,`파재극처실종사우려`,`재난이 없어지고 복이 옴`,`외사 출행이동원행사`,`문서 보고서답신지체-흉절시기사`,`직위녹위사`,`사사성취`,`매사위역**외교막힘`,`굴욕을 면하기 어려움`,`외사 급속사`,`예측이 어려운 근심사`,`매사영속성없음**내女색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,``,`승진영전`,`외男색정사**내부가정막힘`,`도적악의-반대공격불의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,``,`전화위복`,``,`빈궁`,``,`관재**재난해소`,`내사 급속사`,`재난해소`,``,`빈궁`,``,``,``,`빈궁`,``,`빈궁`,``,`전화위복`,``,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`외男색정사**내부가정막힘`,`도적악의-반대공격불의`,`매사위역**손해가출사우려`,`불륜암손사**외사동요분산사`,`내사 출행이동원행사`,`수신제가되어 가나 있으나 좋은 상`,`전도`,`일체근심해소`,`파재극처실종사우려`,`재난이 없어지고 복이 옴`,`외사 출행이동원행사`,`문서보고서답신지체-흉절시기사`,`직위녹위사`,`사사성취`,`매사위역**외교막힘`,`굴욕을 면하기 어려움`,`외사 급속사`,`예측이 어려운 근심사`,`매사영속성없음**내女색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,``,`승진영전`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`빈궁`,``,`전도`,``,`전화위복`,``,`빈궁`,``,`재난해소`,`빈궁**내사 급속사`,`재난해소`,``,`빈궁`,``,`음4월 화재주의`,``,`빈궁`,``,`병재`,``,`전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`고위자도로에서 만나청원함이 좋음`,`전도`,`정인의재난질병-괴이구설사`,`외男색정사**내부가정막힘`,`문서유실**매사위역`,`손해가출사우려**외사동요분산사`,`필유성취`,`내사 출행이동원행사`,`능욕박해사-광란관련`,`빈궁`,`모사미수사`,`파재극처실종사우려`,`외사 출행이동원행사`,`필유성취`,`직위녹위사`,`절상인구사-가족몸조심`,`관직자 승진-일반인 불리`,`외교막힘`,`도적을 못잡고 놀라기만 한 상`,`빈궁`,`매사영속성없음**문서암동**내女색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`전도**빈궁**관재`,``,`빈궁`,``,`재난해소`,``,`재난해소`,`전화위복`,``,``,`빈궁`,`내사 급속사`,`병재`,``,``,``,`음4월 화재주의`,`외사 급속사`,``,`빈궁`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`매사영속성없음**송구경황-색정사관련임`,`종교진출하면자립함`,`고위자 도로에서 만나 청원함이 좋음`,``,`정인의 재난질병-괴이구설사`,`외男색정사**내부가정막힘`,`문서유실**매사위역`,`손해가출사우려**외사동요분산사`,`필유성취`,`내사 출행원행이동사`,`능욕박해사-관란관련`,``,`모사미수사`,`파재극처실종사우려`,`필유성취`,`외사 출행이동원행사`,`직위녹위사`,`절상인구사-가족 몸조심`,`관직자 승진-일반인 불리`,`외교막힘`,`도적을 못잡고 놀라기만 한 상`,`전도**외사 급속사`,`매사영속성없음**문서암동**내女색정사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,``,`빈궁**관재`,``,`재난해소`,`빈궁`,``,``,`빈궁`,`전화위복`,`재난해소`,``,`빈궁`,`내사 급속사`,`빈궁**병재`,``,`재난해소`,``,`음4월 화재주의`,`빈궁`,`전도**빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`매사영속성없음**문서암동사**내女색정사`,`종교진출하면자립함`,`매사영속성없음**송구경황-색정사관련임`,`종교진출하면자립함`,`고위자 도로에서 만나 청원함이 좋음`,`전화위복`,`정인의 재난질병-괴이구설사`,`외男색정사**내부가정막힘`,`문서유실*매사위역`,`손해가출사우려**외사동요분산사`,`필유성취`,`내사 출행원행이동사`,`능뇩박해사-광란관련`,`빈궁`,`모사미수사`,`파재극처실종사우려`,`필유성취`,`외사 출행이동원행사`,`직위녹위사`,`절상인구사-가족 몸조심`,`관직자 승진-일반인 불리`,`외교막힘`,`도적을 못잡고 놀라기만 한 상`,`빈궁**외사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도`,`동요불안분산사`,`재난해소`,`동요불안분산사`,`빈궁**관재**재난해소`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사`,``,`동요불안분산사`,`전화위복`,`동요불안분산사`,``,`동요불안분산사`,`빈궁**내사 급속사`,`동요불안분산사`,`병재`,`동요불안분산사`,`전도`,`동요불안분산사`,`음4월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`도적을 못잡고 놀라기만 한 상`,`외사 급속사`,`매사영속성없음**문서암동사**내女색정사`,`종교진출하면자립함`,`매사영속성없음**송구경황-색정사관련임`,`종교진출하면자립함`,`고위자 도로에서 만나 청원함아 좋음`,``,`정인의 재난질병-괴이구설사`,`외男색정사**내부가정막힘`,`문서유실**매사위역`,`손해가출사우려**외사동요분산사`,`필유성취`,`내사 출행이동원행사`,`능욕박해사-광란관련`,``,`모사미수사`,`파재극처실종사우려`,`필유성취`,`외사 출행이동원행사`,`절상인구사-가족 몸조심`,`직위녹위사`,`관직자 승진-일반인 불리`,`외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4월 화재주의`,`빈궁`,``,``,`빈궁**관재`,``,`전화위복`,``,`관재**빈궁`,``,`빈궁`,``,``,``,`전화위복`,`빈궁`,``,``,`재난해소`,`빈궁**내사 급속사`,`재난해소`,`병재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관직자 승진-일반인 불리`,`외교막힘`,`도적을 못잡고 놀라기만 한 상`,`빈궁**외사 급속사`,`매사영속성없음**문서암동사**내女색정사`,`종교진출하면자립함`,`매사영속성없음**송구경황-색정사관련임`,`종교진출하면자립함`,`고위자 도로에서 만나 청원함이 좋음`,``,`정인의 재난질병-괴이구설사`,`외男색정사**내부가정막힘`,`문서유실**매사위역`,`손해가출사우려**외사동요분산사`,`필유성취`,`내사 출행이동원행사`,`능욕박해사-광란관련`,``,`모사미수사`,`파재극처실종사우려`,`필유성취`,`외사 출행이동원행사`,`절사인구사-가족 몸조심`,`직위녹위사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재난해소`,`음4월 화재주의`,``,`빈궁`,``,``,`전화위복`,``,`빈궁**관재`,``,`빈궁`,``,``,``,`전화위복`,`빈궁`,``,``,`전도**빈궁`,`내사 급속사`,`전도**빈궁**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`득위-이익경영사`,`직위녹위사`,`매사위역**외교막힘`,`능욕박해사-광란관련`,`무두 흉함`,`외사 급속사`,`고시득길**구체성없는 구설사*내女색정사`,`종교진출하면자립함`,`매사영속성없음**숲에서 뱀을 만난 격임`,`종교진출하면자립함**예측이 어려운 근심사`,`고위자 도로에서 만나 청원함이 좋음`,``,`숲에서 뱀을 만난격`,`외男색정사**내부가정막힘`,`매사위역`,`불륜암손사**손해가출사우려*외사동요분산사`,`도적못잡음`,`내사 출행이동원행사`,`酒食受罰`,``,`모사미수사`,`파재극처실종사우려`,`재물손괴사`,`외사 출행이동원행사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재난해소`,``,`빈궁`,``,`음4월 화재주의`,``,`빈궁`,``,`빈궁`,``,`전화위복`,``,`관재`,``,`빈궁`,``,`전도**빈궁`,``,`전화위복`,`전도`,`빈궁**병재`,``,`재난해소`,`빈궁**내사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사 출행이동원행사`,`재물암동사`,`직위녹위사`,`모두불리-다시도난사有`,`외교막힘`,`관직자승진-일반인 불리`,`재앙소멸사**외사 급속사`,`빈궁**병재`,`매사영속성없음**내女색정사`,`종교진출하면자립함`,`매사영속성없음**예측이 어려운근심사`,`종교진출하면 자립함`,``,`허리굽히기가 반복되는 상`,`외男색정사**내부가정막힘`,`간음음사이익-정대적이면 되려 재앙이 됨`,`손해가출사우려**외사동요분산사`,`문서유실**매사위역`,`내사 출행원행이동사`,`흉이 길로 변함`,``,`일체근심해소`,`파재극처실종사우려`,`송구경황-색정상대가 두려움`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,`내사 급속사`,`전도**빈궁`,``,``,``,`재난해소`,`음4월 화재주의`,`빈궁`,``,``,``,`전화위복`,`빈궁**관재**병재`,``,`관재`,`빈궁`,``,`빈궁`,``,`전화위복`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`파재극처실종사우려`,`재난이 가고 복이 옴`,`외사 출행이동원행사`,`문서보고서답산지체-흉절시기사`,`직위녹위사`,`사사성취`,`매사위역**외교막힘`,`능욕박해사-광란관련`,`상대마음을 측정하기 어려움`,`외사 급속사`,`매사영속성없음**내女색정사`,`종교진출하면 자립함`,`매사 허성불성**세속허사 자**예측이 어려운 근심사`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,``,`승진영전`,`외男색정사**내부가정막힘`,`도적악의-반대공격불의`,`손해가출사우려*외사동요분산사`,`음인암손사**매사위역`,`폐한인**내사 출행이동원행사`,`수신제가 되어 가나 있으나 좋음`,``,`일체근심해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재난해소`,`빈궁**전도`,``,`내사 급속사`,`빈궁**병재`,``,`재난해소`,`빈궁`,`전도`,`음4월 화재주의`,`전도**빈궁`,``,`빈궁**병재`,`성공함`,`전화위복`,``,`빈궁**병재`,``,`재난해소`,``,`빈궁**관재`,``,`전화위복`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`일체근심해소`,`파재극처실종사우려`,`재난이 가고 복이 옴`,`외사 출행이동원행사`,`문서보고서답신지체-흉절시기사`,`직위녹위사`,`사사성취`,`매사위역**외교막힘`,`능욕박해사-광란관련`,`외사 급속사`,`상대마음을 측정하기 어려움`,`매사 허성불성**세속허사 자*내女색정사`,`음1,2,3,4월 방문자는 종교진출하면`,`매사 허성불성**세속허사 자**예측어려운 근심사`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,``,`승진영전`,`외男색정사**내부가정막힘`,`도적악의-반대공격불의`,`손해가출사우려**외사동요분산사`,`매사위역**음인암손사`,`내사 출행이동원행사`,`수신제가되어 가나 있으나 좋은 상`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도`,`빈궁`,``,`재난해소`,`빈궁*내사 급속사`,`재난해소`,``,`빈궁`,``,`음4월 화재주의`,``,`빈궁`,`성공함`,`빈궁**병재`,`성공함`,`전화위복`,``,`빈궁**관재`,``,`빈궁`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 사람이나 일이나 붙기도 떨어지기도 애매하겠어! 
꽉 막힌 격인데 계획을 확 바꾸고
⊕여귀, 
⊖수귀를 해소하면 되는게 
  있겠어!
⊕신변 고모신사부정 침범함
⊖신변 고모신사 부정이침범
  했네 ?
`,`■ 마음에 천둥치는 격이겠는데 나쁘고 좋고 간에 다 헛소리뿐이니 놀랄 것은 없고

⊕신부정귀, 
⊖신불제사귀를제거하지     않으면 직위나 권리가 변경    되게 생겼어!
`,`■빈총으로 아무리 총을 쏴도 상대는 맞지 않고 피해가니

⊕가택음사부정, 
⊖가택상가부정이 가택에 침범    해서그래! 
  또 부부가 각기 딴 마음    이고,집밑에해골도묻혀    있고!
`,`■ 토지사에 돈이 붙었으나 소송까지 붙었네?
이사를 한번 해보면 풀릴 
것도 같은데?
女:근신해야겠어 ! 凶하네 !
   모친살상을 주의요함.
   혼인․출행․출진도 모두
   보류해야 돼 !
⊖신변 음사부정이 들었어!
`,`■ 변경이나 개혁할 일이 한두가지가 아니겠는데, 그건 좋으나 자칫 부부이별까지 가게 생겼어!

⊕도로귀, 
⊖수귀를 잡아야 탈이 없겠어!
`,`■금전․여자로의심․질투가 심하네 ? 그리고 옛일, 옛인연이 다 했네? 一女二男 삼각관계도 정리될 수밖에 없겠고, 가택에 
⊕⊖가택신화선영부정든      것만해소시키면 전화      위복이되겠어!

⊖신변음사부정이 들었어!
`,`■ 색정불화로 가정은 요동치고 일도 반복무상하니 어디 의지할데가 없겠어!
⊕도로귀, 
⊖상가부정이 들었다. 자살귀를    잡아야 집 뺏기는 수모를     안당하지!

⊕신변 저주원심부정 침범함
⊖신변저주원심부정침범했네?
`,`■ 一男二女 삼각관계가 되어 있고 골육도 등을 돌렸네 ? 금전․여자 문제로 선택의 기로에 서 있겠는데
⊕도로귀, 
⊖자살귀부터 잡고 봐야 살    길이 열리겠어!

⊕신변음사부정 침범했네?
`,`■그냥 돌아가도 되겠어!
어째 사람은 번성한데 집이 좁아! 단,

⊕신부정귀, 
⊖전사귀를 해소시켜야 하늘    에서 던지는 투망에 걸리지    않겠어!
`,`■땅 문제가 시끄럽겠어!
자칫 권리를 뺏기게 생겼네? 그런데 힘이 없어! 
기도ㆍ치성에 매달리면 효과가 크겠어!


⊕신변음사부정이 들었어!
`,`■ 집에 바퀴가 달렸네 ? 
이사하는 것도 좋긴 하네!
그러나

⊕신부정귀,
⊖가택음사부정이 듬, 
  전사귀를 없애야 승진이든    재산이든 탈이 없이 잘 되    갈거야!
`,`■ 경사 날 집에 웬
⊕⊖여고음강 부정이 들었지?
이사하면 좋겠는데 보물을 품고 미로를 헤매는 격이야!`];
const YOGYEOL1 = [`■ 교섭사는 좋으나 집안에 귀신이 들어 고통이다. 화합중 화가 있으니 종업원의 속임수가 있을 것이다.
`,`■ 드러내고 숨어서 나를 해치니 일반인은 그 흉이 두렵다. 몸과 집이 모두 흉하다. 공직군자는 좋으리라.
`,`■ 낮정단은 범과 묘가 나쁘니 금전사나 혼인사는 나쁘다. 여자의 재물을 취하면 허물이 되고 소송은 펴지기 어렵다.
`,`■ 겉으로는 서로 미워해도 속으로는 사모하고 사랑한다. 만약 재물의 교섭을 논하면 상대는 좋고 나는 나쁘다. 
`,`■ 과전에 귀신이 가득하나 구해주는 자가 있어 좋게 된다. 재물은 멀리서 구하면 좋고 근심이 변해 기쁨이 된다.
`,`■ 좋은 것은 버리고 나쁜 것을 찾아가니 이익은 버리고 손해를 택했다. 손해날 일이 거듭되리라.
`,`■ 내 녹에 임한 귀살이 셋이나 되니 도모사마다 골치 아프다. 밤정단은 뱀과 범을 만나니 녹을 지키기 어렵고 배부르기도 어렵다.
`,`■ 도적이 있어도 비었으니 간절히 바라거든 거만하지 말라. 아랫사람이 윗사람을 해치니 이는 덕으로 막아야 한다.
`,`■ 사람은 잘되나 집은 좁다. 귀인이 많아도 도움이 안된다. 천장은 재수이고 삼전은 명예이니 이 두 가지를 잘 지키라.
`,`■ 내 힘이 부족하니 여러 사람을 돌보기 어렵다. 방해와 형벌이 따르나 나중에 점차 좋아진다.
`,`■ 사람은 손해가 있고 집은 옮겨갈 수 있다. 
고위자나 공직자는 좋아 복을 받고 재산이 늘어난다.
`,`■ 면전에 도둑을 맞는 셈이나 다 버리고 앞으로만 가라. 움직여야 좋은 일을 만나니 손해 난 것을 면하게 된다.`]
const SIBO1 = [`■ 가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
그러나 대외적으로 교섭ㆍ교역사는 양호하게 이루어진다. 여자 사용인과 통정이 있고 구해를 일으킨다. 결과는 좋으나 경미하고 토대가 동요하게 되므로 이를 잘 지켜야 한다.
十月 임신사가 있다.
`,`■ 가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
녹과 가택이 충돌하여 해산된다. 후퇴하여 진퇴양난에 빠지므로 점진적인 진전을 해나가야 한다.
천우신조를 얻어 나중에 좋게 된다.
이권 상실과 혹은 일을 주의하라.
`,`■ 집안 계모건이다.
금전사에 관계되고
별리사가 있고 무덕한
입장이다. 가정에 

⊕음사, ⊖상가부정이 든 이유이다. 부부간에도 각자 사정이 있어 고과가 될 징후가 있다. 
⊖정단은 가정에 병자가 있고, 집밑에 해골이 있다.
`,`■ 신변에 저주사가 있다.
가택을 손상할 조짐이 있다.
가정에 속는 일이 있거나
자녀의 손상사가 기한다.
여러건의 토지사로 관송사가 벌어졌다. 어른에게 재화가 있게 된다. 금전도 침체되고 불화ㆍ시의가 있다. 이사가 좋다.
이권상실사가 있게 된다.
女:구추앙화-공망午年․月 상사 주의하라.
`,`■ 부부가 다같이 음탕하다.
사건이 여러개다.
옛것을 새로 개신ㆍ개혁
하여 吉하게 된다.
가정에 夫가 겉돈다. 
여자는 부부이별을 고려한다. 
일은, 후원과 추천이 있어 잘 된다.
十月 임신사가 있다.
`,`■ ⊕⊖가정에 신화선영
부정이 들었다.
이로인해 구사가 끝나고
한 여자에 두 남자의 삼각관계가 있다.
두 가지 마음 때문에 일이 두 갈래가 되어 선택의 기로에 섰다. 
돈 여자로 심중에 질투 의심이 있다. 근ㆍ친자로 결정해야 좋다.속는 일과
자손재해가 발생한다.
`,`■ 색정 불화가 있다.
이로인해 가정에 병자가 있고
가택의 권리가 넘어간다.
점자는 배반당했다. 부부외심이 있고 가정이 불녕하다. 본인의 財를 문서로 묶지 않으면 날아가게 생겼다.
十月 임신사가 있다.
`,`■ 자충수적 우환이 기한다.
一男二女의 삼각관계가
있고 부부별리사가 있다.
선택의 기로에 섰다. 여자가 유리하다. 
집안에 허위ㆍ음사사로 비용이 손모한다. 일이 한가지가 아니며 동료에 의한 문제이다. 정단 목적사를 개변시켜야 좋다.
十月 임신사가 있다.
`,`■ 신불기도ㆍ치성하면
반드시 큰 효과가 있게
된다.
여러 사람의 추천과 후원이 있게 된다. 만사 길행이 따른다. 분수를 넘으면 자충수적 우환이 발생하게 된다.
`,`■ 토지ㆍ가옥사로 소송사가
기한다.
존장의 재해가 있게 된다.
財의손실이 있고 별리ㆍ고독인이다. 옛것을 지키고 조용히 있는 것이 상책이다.
`,`■ 신변에 자손ㆍ문서등의
속는 일이 있다.
명예와 夫의 일은 
헛소리에 불과하게 된다.
가택의 개동이 있다.
자손 재해가 발생한다.

`,`■ 사람은 잘되고 집은 좁다.
사업ㆍ후원사로 
움직인다.
활동하면 이익이 된다.
단, 가정의 부정을 해소하는 것이 좋다.
이사가 좋다.`]
const SIBO2_1 = [`■가택신변토지금전사이다.
내방조짐 상의 : 
분쟁사가 있다.
움직임이 근본이고 동기가 발동한다.
`,`■가정내부출외관사이다.
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
심중 놀램이 해소된다
`,`■가정내부출외금전사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
이권을 상실하고 재·녀의 재화가 있게 된다.
`,`■가택내금전신변사이다.
내방조짐 상의 :  
조용히 있는게 이롭다.
상호 허를 찌르는 토지관사가 있다.
`,`■가택내부개혁사이다.
내방조짐 상의 : 
은근하고 伏하라.
병재·관재예방이 필요하다.
`,`■외사내입후원사이다.
내방조짐 상의 :  
원행·이동사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■가택동요재산사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■금전ㆍ여인사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.이권을 상실하고 재·녀의 재화가 있게 된다.
`,`■외사내입신변명예사이다.
내방조짐 상의 : 
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
자손 흉재예방이 필요
`,`■금전ㆍ여인사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.이권을 상실하고 재·녀의 재화가 있게 된다.
`,`■명예관련후회사이다.
내방조짐 상의 : 
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
이전 이사를 한다.
`,`■내사출외후원사이다.
내방조짐 상의 :  
원행·이동사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/022/row-1-column-1.png`,
  `/static/images/sagwa/022/row-1-column-2.png`,
  `/static/images/sagwa/022/row-1-column-3.png`,
  `/static/images/sagwa/022/row-1-column-4.png`,
  `/static/images/sagwa/022/row-1-column-5.png`,
  `/static/images/sagwa/022/row-1-column-6.png`,
  `/static/images/sagwa/022/row-1-column-7.png`,
  `/static/images/sagwa/022/row-1-column-8.png`,
  `/static/images/sagwa/022/row-1-column-9.png`,
  `/static/images/sagwa/022/row-1-column-10.png`,
  `/static/images/sagwa/022/row-1-column-11.png`,
  `/static/images/sagwa/022/row-1-column-12.png`,
]

const HEASYEOL = [
  // 1
  `  이 과는 천지 십이신이 복(伏)하여 동하지 않고 복음과(伏吟課)로 된다. 제1과에 극이 있어 진(辰)을 초전으로 하고 중전은 지상신을 말전은 충신(冲神)을 취한다. 진(辰)과 유(酉)가 지합하고 일간일진이 교차지합하면 피아의 심정이 같은 마음으로 교관(交關)․교역(交易)․교섭사(交涉事)는 모두 성취된다. 유(酉)는 여자 사용인으로 현무․천후(天后)가 승하여 간사(奸私)의 일이 있다. 주인(主人)에 대해 표면으로는 지합(支合)하여 화합인 것 같아도 일간을 극하므로 주인에게 구해(仇害)를 형성한다.
  복음(伏吟)은 복닉(伏匿)하여 미발(未發)의 일이 있다. 음일의 복음(伏吟)은 자신격(自信格)이고 스스로 유연하다고 믿으며, 함부로 망동하지 않고 정(靜)하는 것으로 재해가 없다. 진(辰)은 재(財)로 되고 일간은 극을 당하여 신체가 약하다. 귀(鬼)는 현무를 생하여 집안에 도적이 있으며 도난․실탈(失脫) 사건이 겹쳐서 발생한다. 복음과(伏吟課)는 일진(日辰)의 왕쇠(旺衰)를 먼저 판별하여 왕(旺)할 때는 전진하고 쇠퇴할 때는 지켜야 한다. 이 과의 일간은 귀극(鬼剋)을 만나서 쇠퇴하므로 지키는 것이 좋다.
  간지가 함께 지합(支合)하면 교섭사(交涉事)는 타협된다. 일귀는 역행하여 삼전이 구(勾)․후(后)․청(靑)이며, 야귀는 순행하여 삼전이 구(勾)․현(玄)․합(合)이다. 말전의 묘(卯)는 건록에 해당하고 유(酉)에서 충(冲)하여 길복(吉福)이 경미하다. 환자가 건록을 충(冲)하면 식(食)을 절(絶)하여 죽음에 이른다.`
  ,
  // 2
  `  이 과는 상하의 극이 없고 제3과의 상신에서 일간을 요극(遙剋)하여 신유(申酉)를 초전으로 한다. 신미오(申未午)는 역퇴여격(逆退茹格)이며 능음격(凌陰格)이라고도 부른다.
  신(申)은 음(陰)의 경지(境地)에서 오미(午未)를 능가하므로 음양이 교전(交戰)하여 안위(安危)의 계기를 형성한다. 그러나 오미(午未)는 점차 양광(陽光)을 나타내므로 범부(凡夫)를 벗어나서 고명한 경지에 들어가고 음험(陰險)에서 요행을 얻는다. 편안한 것은 위험한 것으로 되고 위험한 자는 편안한 것에 도달한다.
  일지의 택상신(宅上神)이 일간을 극하고 음신(陰神)의 미(未)에서 귀(鬼)를 도우므로 거주는 불쾌하다. 군자는 왕록(旺祿)이 신(身)에 임하고 정관(正官)이 왕지(旺地)에 있으며 천장(天將)은 길성(吉星)에 승하여 크게 명리를 발한다. 상인(常人)도 복록(福祿)이 있다. 그러나 질병과 소송 사건은 심각하게 된다. 
중․말전이 공망이므로 초전의 일사(一事)에서 길흉을 구해야 한다. 원래 호시격(蒿矢格)은 동요가 심하여 인정을 등지기 쉽지만 일은 경소(輕小)하다. 퇴여공망(退茹空亡)은 퇴(退)하여 함정에 빠진다.
  백법에 말하기를 ｢왕록(旺祿)이 신(身)에 임하면 함부로 망동하는 것을 금지하고 점차 전진하는 방침이 좋다｣고 하였다. 중․말전의 공망은 전(傳)에 가지 않는다고 하며 초전을 고려해야 한다. 82법칙에 말하기를 ｢전(傳)에 행하지 않는다는 것은 중․말전이 공망하기 때문에 중․말전에 행하지 않아서 초전의 일신(一神)만이 실(實)하면 초전의 사류(事類)에 따라 길흉을 본다｣고 하였다. 이와 같은 격은 1일 중에 반드시 1～2국이 있다.
  비전에 말하기를 ｢일지와 일지상신에서 일간을 중극(重剋)하므로 흉의 힘이 중하다. 그러므로 모든 일은 먼저 동하면 불리하다. 그러나 연여공망(連茹空亡)은 각답공망(脚踏空亡)이라고 하여 전진하는 것은 유리하고 후퇴하는 것은 불리하다. 그러므로 기회를 보아 진행해야 한다. 초전의 신(申)은 의신(儀神)으로 되고 순수(旬首)의 신이 발용(發用)으로 되면 육의격(六儀格)이며, 흉이 변화하여 길조로 된다. 천을귀신(天乙貴神)과 그밖의 길장이 승하는 것은 명리육의격(名利六儀格)이라고 부르며 더욱 길조로 된다. 왕록(旺祿)이 일간상에 가해지면 분수를 지켜 함부로 동작하지 않아야 안전을 얻게 된다. 일귀는 역행하여 삼전이 귀(貴)․사(蛇)․주(朱)이며, 야귀는 순행하여 삼전이 상(常)․백(白)․공(空)이다. 백호가 삼전중에 있어서 공망하면 재화를 이루지 않는다.`
  ,
  // 3
  `  이 과는 4과 중에 상하의 극이 없다. 제3과의 천신(天神) 미(未)를 일간에서 요극하여 이것을 초전으로 하는 요극탄사과(遙剋彈射課)이다. 삼전 미사묘(未巳卯)는 음의 역간전격(逆間傳格)이며, 음을 경유하여 양에 돌아오므로 회명격(回明格)이라고 부른다. 달(月)은 점차 동근 상으로 길사는 점차 겹치고 흉사는 점차 해소된다. 초전의 미(未)는 일간의 묘신(墓神)이며, 백호는 택(宅)에 임하지만 다행히 공망하여 흉을 이루지 않는다. 육합(六合)이 묘(卯)의 건록(建祿)에 임하여 혼담이 있다.
  탄사격(彈射格)은 모든 일이 외부에서 발생하고 가까이 하기 어렵다. 상대방에게는 유리하고 내게는 불리하다. 그러나 후에는 길하고 처음에는 불길하다. 초전이 공망한 것은 동작이 부실(不實)하므로 일은 허(虛)하다. 과숙(寡宿)으로 되면 부처(夫妻)가 분리하고, 남녀는 고과(孤寡)의 징조가 있다. 천반이 공망한 것은 과숙격(寡宿格)이고, 지반의 공망은 고진격(顧辰格)이라고 한다.
  백법에 말하기를 ｢일간의 묘신(墓神)․병관(倂關)은 인택(人宅)이 퇴폐하고, 일지에 묘호(墓虎)가 승하면 집안에 복시(伏屍)가 있다. 또 부부는 각각 사정(私情)을 품고 있다. 일귀는 역행하여 삼전이 사(蛇)․합(合) ․청(靑)이고 사(蛇)는 용으로 변화하여 현임 관리는 가장 길조로 된다. 야귀는 순행하여 삼전이 백(白)․청(靑)․합(合)으로 되어 일간은 일지상신을 극하고 일지는 일간상신을 극하여 교차상극(交車相剋)된다.`
  ,
  // 4
  `  이 과는 제1과․제3과에 극이 있고 하에서 상을 적(賊)한다. 제1과의 축진(丑辰)을 써서 초전으로 하는 중심과(重審課)로 되며, 삼전의 사계신(四季神)은 가색격(稼穡格)이다. 천반 축(丑)과 오(午)는 육해(六害)이며, 간지가 진유(辰酉)와 지합하기 때문에 외부에는 불화․시의(猜疑)가 있고 내부는 상애화합(相愛和合)한다.
  삼전이 모두 재성(財星)으로 토(土)는 침체(沈滯)의 성질이며, 중용(中庸)에 임하는 것은 중하고 광대하다. 형충(刑冲)을 즐기고 개묘(開墓)는 흉을 해하여 길조로 된다. 초전에 정신(丁神)이 승하여 유자격(遊子格)이다. 가색격(稼穡格)은 전재(全財)로 된다. 인수(印綬)는 존친․어른을 극하여 장상(長上)은 이롭지 못하다. 재신(財神)이 너무 지나쳐 오히려 재(財)가 흩어지고 재(財)로 인하여 타인의 노여움을 사게 된다.
  구추일(九醜日)은 을기신임무(乙己辛壬戊) 등의 이상 5간(五干)에 지지(地支)가 자오묘유(子午卯酉)이면 구추(九醜)라고 하는데, 을유일(乙酉日)은 이 날에 해당된다. 양일(陽日)은 남자가 흉하고 음일(陰日)은 여자가 흉하다. 양지(陽支)가 겹치면 부(父)를 해하고 음지(陰支)가 겹치면 모(母)를 해한다. 따라서 모친살상주의가 요망된다(女임), 주일간일지상신(日干日支上神)에 승하면 상사(喪事)가 발생한다. 진술(辰戌)이 일간일지에 가해져 초전으로 되면 정체되는 일이 있다. 일진(日辰)에 사절휴수(死絶休囚)하는 것은 이난(二難)이라고 부르며, 부처가 서로 반목한다.
  백법에 말하기를 ｢분재(憤財)를 병(倂)하는 것은 여행 중에 저해가 생긴다. 주객(主客)이 투합하지 않는 것은 지형상(支刑上)에 있다｣고 하였다. 비전에 말하기를 ｢구추(九醜)로서 일간 또는 일지상신에 축(丑)이 승하여 초전으로 되면 진정한 구추격(九醜格)이라고 한다. 천재(天災)가 사람에게 미치므로 결혼․출행(出行)․출진(出陳)을 기(忌)한다. 일귀는 순행하여 삼전이 청(靑)․주(朱)․후(后)이며, 야귀는 삼전이 사(蛇)․음(陰) ․백(白)이다.  `
  ,
  // 5
  `  이 과는 제3과만 1상에서 1하를 극하므로 사유(巳酉)를 써서 초전으로 하는 원수과이다. 삼전의 사축유(巳丑酉)는 종혁격(從革格)이다. 중귀(衆鬼)는 일간을 극하여도 일간상신 자수(子水)를 생한다. 수(水)는 일간을 생하는 자모(慈母)의 신이 되므로 우려가 변화하여 기쁨으로 된다. 특히 삼전은 체생(遞生)하여 타인의 추천을 받는다. 말전은 일귀(日鬼)일지라도 제(制)와 생화(生化)로 되어 새롭게 흉해로 되지 않는다. 원래 원수과(元首課)로 되어 상하 내외가 삼합(三合)․육합(六合)하면 시종 순조롭다. 사(巳)가 유(酉)에 가해지면 처음은 개변하고 후에 순종한다. 또 처음은 저해가 있고 후에 통하여, 재난이 변하여 길로 된다. 처음의 구(仇)도 후에는 은혜로 변화한다. 추월(秋月)․동월(冬月)은 삼전이 왕상(旺相)하고 백사(百事)가 길하다. 백법에 말하기를 ｢중귀(衆鬼)가 나타나더라도 두려울 것이 없다. 귀신은 진(辰)의 옥(獄)에 앉아도 일간상에 귀신(貴神)이 승하여 기쁨을 이루고 모든 일을 화합․협력․타협하는 것은 삼합(三合)․육합(六合)하기 때문이다｣고 하였다. 
  일귀(日貴)는 순행하여 삼전이 현(玄)․청(靑)․사(蛇)이고, 야귀는 삼전이 청(靑)․사(蛇)․현(玄)이다. 처음에 중귀(衆鬼)가 변하여 인(印)으로 생화(生化)하는 것은 살벌(殺伐)을 품고, 후에는 은혜에 보답하는 상(象)이다. 이와 같은 것을 반사격(反射格)이라고 부른다. 비전에 말하기를 ｢초전에서 말전을 체생하는 것은 유실(遺失)이라고 한다. 타인에게 능멸당해도 일간상신을 생한다｣고 하였다. 간상신(干上神)이 일간을 생하는 것은 일심(一心)으로 간(干)에 모이고, 생생하게 화육(化育)하여 모든 일이 호조(好調)로 운행된다.`
  ,
  // 6
  `  이 과는 4과 중 4과에 극이 있어 하에서 상을 적한다. 음일은 음비(陰比)의 극인 해진(亥辰)을 사용하여 초전으로 하고, 비용불비격(比用不備格)이다. 일간 해(亥)에 정신(丁神)이 승하고 수생목(水生木)으로 일간을 생하므로 사물이 타(他)에서 구하지 않아도 유익하다. 불비(不備)의 해진(亥辰)이 초전으로 되는 것은, 양단(兩端)에 걸쳐 일치하지 않으므로 좋은 방법을 선택해야 한다. 예를 들면 가까운 것을 버리고 먼 것을 취하며, 친근한 것을 버리고 소연(疏緣)에 따르는 것 등은 양도(兩途)에 뜻이 있다.
  백법에 말하기를 ｢중․말전이 공망하면 전(傳)에 순행하지 않는다고 하며 초전의 1신으로 길흉을 정단해야 한다｣고 하였다. 비전에 말하기를 ｢오(午)가 해(亥)에 가해지고 유(酉)는 인(寅)에 가해지며 자(子)는 사(巳)에 가해지고 묘(卯)는 신(申)에 가해진다. 즉, 사중신(四仲神)이 사맹상(四孟上)에 가해지는 것을 사절격(四絶格)이라고 하며, 구사(舊事) 결절(結絶)의 격으로 한다. 지일과(知一課)로서 하에서 상을 적(賊)하고 초전으로 되는 것은 처(妻) 또는 재(財)로 인하여 저주하는 소송을 초래할 수 있다. 역마와 정신(丁神)이 승하여 초전으로 되고 현임 관리는 천관전직(遷官轉職)하며, 일반 사람이더라도 동의(動意)가 발생한다. 일귀는 순행하여 삼전이 합(合)․음(陰)․청(靑)이며, 야귀는 사(蛇)․공(空)․후(后)이다.
  십이천장(十二天將)의 길흉(吉凶)은 이미 잘 알고 있으리라 믿지만 다시 참고로 열거한다. 길신(吉神)은 귀신(貴神)․청룡․태상․천후․육합의 오천장(五天將)이며, 흉신(凶神)은 백호․현무․천공(天空)․구진(句陳)․등사(螣蛇)․주작ㆍ태음의 칠천장(七天將)이다. 십이천장에 따라 각각 인사(人事) 및 기타 길흉의 류신이 나타나는 것이다.`
  ,
  // 7
  `  이 과는 십이신(十二神)이 대충(對冲)에 있고 반음과(返吟課)이며, 삼전 묘유묘(卯酉卯)는 용전격(龍戰格)이다. 묘유일(卯酉日)에 정단하고 묘유(卯酉)가 초전으로 된다. 년명(年命)이 묘유(卯酉)이면 진정한 용전격으로 된다.
  양(陽)은 천(天)의 도(道)로서 부(夫)로 하고, 음(陰)은 지(地)의 도(道)로서 처(妻)로 한다. 양은 동하고 음은 순(順)하며, 양이 창(唱)할 때는 음이 화(和)한다. 창화(唱和)의 도(道)로서 적대(敵對)되지 않아도 묘유(卯酉)는 음양이 서로 섞여 싸운다. 건(乾)은 양으로 청(靑)이라고 이름을 붙이므로 용전(龍戰)이라고 부른다. 분리(分離)․산발(散發)․투쟁․불안으로 진퇴소장(進退消長)하고 길흉이 반복된다. 반음(返吟)에 의하지 않는 과격(課格)은 사물이 양도(兩途)를 형성하고, 왕래가 비상하며 반복이 부정(不定)하다. 동하려 하나 동하지 못하며, 묘유(卯酉)가 서로 가해지면 가정이 불안하고 도망․간사(奸私)․재화(災禍)가 발생할 징조가 있다.
  백법에 말하기를 ｢일지상신의 건록은 권섭부정(權攝不正)하며 사과(四課) 천지반(天地盤)이 각각 지합하면 상하 양심(兩心)과 내외가 화합한다｣고 하였다. 비전에 말하기를 ｢묘유(卯酉)를 용신(用神)으로 하고 용전(龍戰)으로 되어 백호가 승하면 싸움으로 되고 모든 일은 개변되어 우려와 의혹이 부정(不定)하다｣고 하였다. 택상(宅上)에 묘유(卯酉)가 용전(龍戰)하면 가택(家宅)이 크게 파하고, 불평불만이 일어나지 않으면 복록(福祿)을 잃는다. 일귀는 순행하여 삼전이 백(白)․사(蛇)․백(白)이고, 야귀는 역행하여 삼전이 현(玄)․합(合)․현(玄)이다. 현무가 건록에 승하면 현임 관리 또는 봉급 생활자는 감봉(減俸)의 착오를 초래하게 되거나 급료(給料)를 도난․분실할 우려가 있다.`
  ,
  // 8
  `  이 과는 4과에 전부 극이 있고 하에서 상을 적(賊)한다. 음일(陰日)이면 음비(陰比)의 극이다. 미인(未寅)으로써 초전으로 하는 지일과(知一課)로 된다. 순미(旬尾)의 미(未)가 초전으로 되면 난수격(亂首格)이며, 이음일양(二陰一陽)의 불비격(不備格)이다. 제4과를 초전으로 하면 맥월격(驀越格)에 이른다. 초전의 공망은 과숙격(寡宿格)이다.
  초․중전이 공망하므로 말전의 1신(一神)으로 길흉을 점단한다. 사(巳)의 말전에 백호․현무의 흉장이 승하여도 스스로 제지(制地)에 있어 흉해는 경미하다. 일지는 일간상에 가해지고 일간을 극하므로 타인이 와서 나를 해한다. 지일과(知一課)는 어떤 일도 동류에 의해서 발생한다. 상문난수(上門亂首)는 타인에게서 능욕을 받아도 초전으로 되어 공망하므로 그 화해(禍害)를 해소한다. 과숙격(寡宿格)은 고독한 상으로 정단사는 개변하여 좋다. 4과에 극이 많은 것은 사건이 한 가지 사건이 아니며 파란기복이 많다.
  백법에 말하기를 ｢귀신이 자미(子未)의 육해(六害)로 되면 소송사는 곡단(曲斷)을 만나므로 성의(誠意)를 다하여 싸워야 한다｣고 했다. 일귀는 순행하여 삼전이 후(后)․구(勾)․현(玄)이고, 야귀는 역행하여 삼전이 청(靑)․귀(貴)․백(白)이다. 난수격은 ｢만사를 덕행(德行)으로써 해야 한다｣고 하였다.

`
  ,
  // 9
  `  이 과는 4과 중에 제1과만 상에서 하를 극하여 원수윤하격(元首潤下格)이다. 삼전이 삼합수국(三合水局)하여 일간을 생부(生扶)하고 사람은 번성한다. 일지 유금(酉金)은 삼전에 설기(泄氣)하여 집이 좁다. 과전 중에 귀신이 많으면 오히려 웃어른의 원조가 박하다. 일지상신 및 삼전은 처재(妻財)이다.
  원수과(元首課)는 과격(課格)이 존귀(尊貴)하고 만사는 광명정대하여 화미격(和美格)으로 백사(百事)는 협력하고 화합(和合)한다. 원수라는 것은 천(天)의 상을 취하여 순정(順正)하고 문란하지 않으며, 모든 과격의 수(首)를 구성하기 때문에 이와 같은 이름이 붙었다. 초전 및 일간일지상신의 천장의 길흉을 심사하고 왕쇠(旺衰)․생묘(生墓)․충합(冲合) 등을 충분히 관찰한 후에 단정해야 한다. 길과(吉課)라 하더라도 여자나 소인은 난동(亂動)의 징조가 있다.
  끝으로 년명(年命)․행년(行年)․상신(上神)의 상태를 알아 정단을 시험해야 한다. 사과삼전(四課三傳)이 상하가 모두 삼합(三合)․지합(支合)하면 화미격(和美格)이라고 부르며, 생일(生日)이 간지(干支)의 상신에 지합하거나 재동(財動)으로 되면 삼 육(三 六)이 서로 부른다고 하여 만사가 성취된다. 피점자는 기뻐서 오고 귀살(鬼殺)이 승하여도 저해가 없다. 즉 육임신과(六壬神課)는 삼합․지합․상생을 즐기는 소이이다. 인정이 화합(和合)하므로 화미격(和美格)이라고 부른다. 따라서 교역은 잘 되고, 재리(財利)는 끝이 없으며 결혼은 성립된다. 합(合)이 많은 것은 성립되기 쉽고 경사가 후하며, 합이 적은 것은 일이 지완(遲緩)되어 길사도 경미하다. 이 과(課)의 천장은 토신(土神)이 많아서 일간의 재(財)로 되므로 구재(求財)는 좋으나 인수(靭綬)의 존장만은 좋지 않다.
  일귀는 순행하여 삼전이 귀(貴)․구(勾)․상(常)이고, 야귀는 역행하여 삼전이 구(勾)․귀(貴)․상(常)이다.`
  ,
  // 10
  `  이 과는 제1과의 하에서 상을 극하는 시입중심과(始入重審課)이다. 삼전의 계신(季神)만이 가색격(稼穡格)으로 된다.
  일간상신에 묘신이 복(伏)해도 축(丑)의 충(冲)이 있어 개건(開鍵)하고 정체하지 않는다. 일간의 재성(財星)으로 되어도 초․중전이 공망하고 재상(財上)에 청룡이 승하여 소재(小財)를 얻는다. 전재(全財)는 인수(印綬)를 파하여 존장에게 재해를 초래한다. 또 노력은 많고 얻는 것이 적다. 초전의 공망은 이별하여 고독하게 되고 모사(謀事)나 바라는 일은 한 가지 일도 성공하지 못하므로 구태(舊態)를 조용히 지켜야 길하다.
  백법에 말하기를 ｢피아(彼我)의 의지가 합하지 않으면 형(刑)이 상에 있다. 자미(子未)의 상해(相害)는 시의(猜疑)의 일이 있다｣고 하였다. 비전에 말하기를 ｢을일(乙日)의 가색격(稼穡格)은 큰 해가 없어도 일귀는 사호(蛇虎)가 삼전 중에 있으므로 허모(虛耗)를 본다｣고 하였다. 점혼(占婚)은 삼전에 재신(財神)․청룡이 초전에 승하고, 중전에 천희(天喜)가 있으며, 일간이 지상(支上)을 극하므로 처연(妻緣)이 성립된다. 제4과의 현무가 묘자(卯子)에 승하면 여자 집에 상사(喪事)가 발생하여 일시 단절하는 상으로 된다. 원래 결혼 문제는 제3과․제4과에서 형극(刑剋)할 때는 불리를 초래한다. 신(身)은 밖에 있고 장상(長上)은 집안에 있다. 일귀(日貴)는 역행(逆行)하여 삼전이 사(蛇)․음(陰)․백(白)이고, 야귀(夜貴)는 삼전이 청(靑)․주(朱)․후(后)이다.`
  ,
  // 11
  `  이 과는 제2과․제4과에 상하의 극이 있고 하에서 상을 극하는 신오(申午)를 채용하여 초전으로 하는 중심과(重審課)이다. 삼전 신술자(申戌子)는 진간전격(進間傳格)으로 삼연(三淵)을 거닌다고 한다.
  일간상신이 공망하고 또 탈기로 되며 천공에 승하여 신상(身上)의 소모가 많다. 해(亥)는 지마(支馬)로 되고 정신(丁神)에 승하여 가택(家宅)에 변동이 있다. 자(子)는 하의 4위(四位)에 있는데 신술(申戌)은 음(陰)의 경지(境地)로 되어 자(子)는 야반(夜半)의 지(地)이며 음극(陰極)이므로 삼전이 음암(陰暗)하여 보행에 위험이 있다. 그리하여 섭삼연격(涉三淵格)이라고 하며, 사물의 순리를 얻지 못한다.
  음이 작으면 스스로 권세가 강하고 장상(長上)은 위험을 보게 된다. 초전이 공지(空地)에 앉아 모든 일이 허성(虛聲)뿐이며 부실(不實)하지만 흉이 화하므로 길사는 늦으나 후에 발생한다. 모든 일은 분수를 지키고 냉정하게 때를 기다려야 한다. 백법에 말하기를 ｢공상상(空上上)을 만나면 어떤 일도 추구하지 말아야 한다. 사물이 공론공화(空論空話)로 끝난다. 일간일지는 상신을 생하고 인택이 함께 탈(脫)하는 것은 도난․실탈(失脫)의 우려가 있다｣고 하였다. 비전에 말하기를 ｢일간일지가 공탈(空脫)하고 양귀신(兩貴神)이 극을 받으면 웃어른의 노여움을 사서 90일간에 미친다. 사립(四立)의 일(日)에 정단하고 전일(前日)의 지(支)가 일간일지상신으로 될 때는 천화격(天禍格)이라고 부르며 45일 내에 천재(天災)를 만난다｣고 하였다. 사립이란 입춘(立春)․입하(立夏)․입추(立秋)․입동(立冬)을 말한다. 
  초전의 신(申)은 순수(旬首)로서 육의(六儀)라고 부르며 응당 흉을 해제할 수 있지만 공망에 앉아서 그 힘이 미약하다. 섭삼연격(涉三淵格)은 망동(妄動)․모사(謀事)는 불길하다. 또 질병과 소송 사건은 어느 것이나 흉하다. 일귀는 역행하여 삼전이 귀(貴)․음(陰)․상(常)이고, 야귀는 삼전이 구(勾)․주(朱)․귀(貴)이다. 귀신(貴神)이 전(傳)에 들어가 흉은 경하다.`
  ,
  // 12
  `  이 과는 4과 중에 제4과가 해술(亥戌)로서 하에서 상을 적하여 중심진여격(重審進茹格)이다. 일간상신의 사(巳)에 탈기(脫氣)로 되고 초전 해(亥)는 정신(丁神) 역마가 승하여 동의(動意)의 건이다. 중전의 자수(子水)는 일간을 생하고 말전의 축(丑)은 재(財)로 된다. 중심과로서 해자축(亥子丑)과 진여(進茹)하기 때문에 순리일 때는 형통(亨通)하고, 이(理)에 역(逆)할 때는 사물이 정체(停滯)를 생한다. 간상(干上)의 설기(泄氣)는 처음에 손상하고 후에 이(利)로 된다. 초․중전이 일간을 생하여 일지의 금(金)이 수(水)에 설기하면 사람은 풍부하고 가택은 좁다.
  백법에 말하기를 ｢나망(羅網)을 만나서 모사(謀事)를 하면 졸책(拙策)으로 된다｣고 하였다. 비전에 말하기를 ｢순연여격(順連茹格)으로 월장(月將)이 해(亥)에 있어 해자축(亥子丑)의 삼전격(三傳格)은 용잠격(龍潛格)이라고 한다｣고 하였다. 양기(陽氣)는 하에 있고 보배(寶)를 품어 미혹을 생한다. 과전중에 청룡이 있으면 재(財)의 기쁨이 있고 군자는 동의(動意)가 생한다. 태음이 술(戌)에 있으면 아름다운 의복을 입고 결혼하는 기쁨이 있다. 정신(丁神) 역마가 초전에 있다. 진여격은 활동하면 이(利)가 있고 정(情)하여 지키면 불리하다.
  일귀는 역행하여 삼전이 현(玄)․상(常)․백(白)이고, 야귀는 순행하여 삼전이 후(后)․귀(貴)․사(蛇)로서 말전에 사(蛇)․호(虎)가 승하여 재(財)로 되면 분수를 알고 수재(收財)해야 한다.`
  ,
]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
