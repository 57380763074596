
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,11,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,9,10,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,9,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,11,8,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,7,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,6,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,2,5,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,3,4,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,3,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,5,2,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,1,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,12,`특별있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사직업권리남편 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`6`,`허성불성`,`가정단합`],
    [`매사 영속성없음`,`관사직업권리남편사는 선천적 기복재해를 겪음`,`大`,`존재기`,`중중喜愛`,`부부불화`],
    [`유생무해(有生無害)`,`목적사 진행에 아무런 방해가 없는 상`,`大`,``,``,``],
    [`가실불화(家室不和)`,`가정 부부사이가 불화한 상`,`小`,``,``,``],
    [`박관`,`명예직업직위권리남편자녀사는 선천적 기복재해를 겪음`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`5`,`직위불안`,`파재극처`],
    [`매사 영속성없음`,`명예직업직위권리남편자녀사는 선천적 기복재해를 겪음`,`小`,`배양기`,`가정불화`,`환성경사`],
    [`허성무성(虛聲無成)`,`전진사에 모두 헛소리가 되고 성립되는 것이 없는 상`,`小`,``,``,`(색정관계)`],
    [`불리고시(不利考試)`,`시험사는 나쁠 뿐더러 없는 구설까지 근심하는 상`,`大`,``,``,``],
    [`송쟁`,`외사 급속송쟁사-상해 우려`,`大`,``,``,``],
    [`급속`,`외사 급속사`,`小`,`4`,`급속사有`,`출행이동`],
    [`출행`,`내사 가정 출행이동사`,`小`,`개시기`,`상해우려`,`불리고시`],
    [`여견영창(餘見榮昌)`,`찬찬히 둘러봐도 망동하지만 않으면 榮昌이 틀림없는 상`,`大`,``,`환성경사`,`구설근심`],
    [`이어진취(利於進取)`,`나쁜 것들을 다 물리치고 이익을 추구할 수 있는 상`,`小`,``,``,``],
    [`후원`,`후원조력 관련사`,`大`,``,``,``],
    [`결속`,`결속사 음11월 완성 권리사 크게 이루나 病형제災害 발생`,`小`,`3`,`후원조력`,`손해가출`],
    [`가출`,`내사 가정 손해 가출사`,`小`,`학습기`,`남모르는`,`부부반목`],
    [`불리동용(不利動用)`,`적극 나서지 말고 소극적으로 앉아서 도모해야만 좋은 상`,`大`,``,`능욕박해`,``],
    [`부처반목(夫妻反目)`,`부부사이가 반목대립하는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`2`,`직위불안`,`가정막힘`],
    [`막힘`,`내부 가정 막힘사`,`小`,`면모기`,`구사결절`,`금전失財`],
    [`처재대기(妻財大忌)`,`처, 금전이 크게 손실되고 손상하는 상`,`小`,``,`기로의심`,`문서不用`],
    [`도적악의(盜賊惡意)`,`도적이 쫒기다 되돌아서니 공격하지 말라-는 상`,`大`,``,``,``],
    [`명예`,`명예 수상(受賞) 직녹사`,`大`,``,``,``],
    [`급속`,`내부 가정 급속사 有`,`大`,`1`,`외교막힘`,`급속사有`],
    [`막힘`,`외사 외교막힘`,`小`,`절정기`,`투서헌책`,`재화전소`],
    [`욕동불능(欲動不能)`,`침체한 입장에서 적극나서 봐야 災禍만을 구비하는 상`,`小`,``,`不宜불용`,`이사急要`],
    [`미초희경(微招喜慶)`,`권리권한을 행사할 수 있게 되어 애써 기쁨을 감추는 상`,`小`,``,``,``],
    [`형제`,`형제 자매 관련사`,`大`,``,``,``],
    [`색정`,`외남 색정사 有`,`大`,`12`,`색정사有`,`受惠사有`],
    [`과출`,`외사 비용 과출사`,`小`,`과시기`,`비용과출`,`간사은닉`],
    [`승마무용(乘馬無用)`,`말에 올라 달리려해도 방해가 심해 내닫지 못하게 되는 상`,`小`,``,`재난전소`,`관계불통`],
    [`고위안좌(高位安坐)`,`고위에 올라 다중의 이익을 전포하는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극남`,`남자 갈등사`,`大`,`11`,`직위불안`,`남자갈등`],
    [`결속`,`개혁사를 음12월 완성하여 大財획득후 부모災害 발생함`,`小`,`쇠퇴기`,`침체공허`,`비용출비`],
    [`취재반화(取財反禍)`,`금전(女퍼함)을 취해봐야 되려  화를 불러오게 되는 상`,`小`,``,`간사은닉`,`총시成災`],
    [`간사은닉(奸邪隱匿)`,`관계불통,가택이사,간사은닉사에 당면해 있는 상`,`大`,``,``,`(재난뿐)`],
    [`금전`,`금전 사업 처 관련사`,`小`,``,``,``],
    [`송쟁`,`외사 애증화합-급속 송쟁파재극처실종도망사-상해 우려`,`小`,`10`,`급속송쟁`,`타인施惠`],
    [`처`,`처 관련사`,`大`,`침해기`,`총시성재`,`불의투서`],
    [`난이빙준(難易憑準)`,`목표를 달성하는데 효과없는 허비가 많아 어렵게 되는 상`,`小`,``,``,`금전실재`],
    [`총시성재(總是成災)`,`이리저리 둘러봐도 모두 재난 뿐인 상`,`大`,``,``,`구설근심`],
    [`금전`,`금전 사업 여인 관련사`,`小`,``,``,``],
    [`개신`,`외사 개신 결속사`,`小`,`9`,`개신결속`,`가정화합`],
    [`화합`,`내부 가정 화합사`,`大`,`소외기`,`사송계류`,`身物受損`],
    [`서가안일(庶可安逸)`,`일반인은 편안이 보장되고 관직자는 침해를 받게되는 상`,`小`,``,``,``],
    [`속전속결(速戰速決)`,`당면사를 속전속결 해야 하고 늦으면 용두사미격이 되는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`8`,`침체공허`,`동요불안`],
    [`동요`,`내부 가정 동요불안분산사`,`小`,`침체기`,`삼각관계`,`복통수욕`],
    [`종수곤핍(終受困乏)`,`결국 곤고하고 궁핍하게 되므로 적극 나서지 말라-는 상`,`小`,``,`(1남2녀)`,`이사의사`],
    [`이어진취(利於進取)`,`모든 재난을 극복하고 이익을 위해 전진할 수 있는가-의 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`기로`,`외사 岐路적 동요불안분산사`,`小`,`7`,`동요기로`,`면모훼손`],
    [`출행`,`외사 기로 출행이동원행사`,`大`,`단절기`,`출행원행`,`중중喜愛`],
    [`가택흠녕(家宅欠寧)`,`가택,가정의 편안함에 흠이 있는 상-이사 요됨`,`大`,``,`관직양호`,``],
    [`수욕복통(受辱腹痛)`,`복통으로 체면이 손상되는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`직업직위불안가정불화사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`후원문서부친조력출행사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`후원조력문서모친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,``,`직업직위불안남편갈등가정막힘사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`명예직녹자산권리사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`형제자매친구동료여인조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,``,`직업직위불안남자갈등여식사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`금전사업처조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`금전사업여인조력가정화합사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`직업직위불안남편갈등가정동요분산사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,``,`관사기로출행사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 강건하고 정당하게 속결하는 것이 유리함`,0,0,`명분이 있고 정당한 사안이므로 속결해야만 하고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,0,0,`일이 거꾸로 진행되는 듯하여 물러나려하나 계속하게 됨**1녀2남 삼각관계 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하며 일을 지체 연가하여 후결함이 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응유화적 처신으로 사안을 연기하면 반드시 결과가 유리해지는 人`,0,0,`일이 거꾸로 띄엄띄엄 진행되고 퇴함이 좋음**색정사가 발생함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위 세(狐假虎威 勢)**만약 타인과 다툰다면 상대는 호가호위 세를 부릴 뿐 공격하지는 않음`,0,0,`옳지 못한 일이 밝혀져 마치 천둥에 놀란 듯한 심중이나 조용히 근신하면 두려움이 자연 사라지게 되는 人`,0,0,`전화위복 됨**신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하며 일을 지체 연가하여 후결함이 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응유화적 처신으로 사안을 연기하면 반드시 결과가 유리해지는 人`,0,0,`음11월 결속단합사를 이루나 관직자는 좋고 일반인은 병 또는 관재를 당할 우려가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`정대한 옛일들이 끓어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집한 상이므로 화가 밖에서 오니 신중해야 함`,0,0,`반복부정(不定)의 人`,0,0,`신규사가 있음**급속한 이전 이동사가 있음**군자가 때를 기다리나 결과없이 반복되기만 했으나 비로소 밝아짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하며 일을 지체 연가하여 후결함이 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응유화적 처신으로 사안을 연기하면 반드시 결과가 유리해지는 人`,0,0,`기이적 전화위복사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하며 일을 지체 연가하여 후결함이 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응유화적 처신으로 사안을 연기하면 반드시 결과가 유리해지는 人`,0,0,`개신개혁사를 결속함-음12월에 완성하여 大財를 획득함- 단,부모신변이 위태해짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하며 일을 지체 연가하여 후결함이 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응유화적 처신으로 사안을 연기하면 반드시 결과가 유리해지는 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응하며 일을 지체 연가하여 후결함이 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 순응유화적 처신으로 사안을 연기하면 반드시 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**주력사가 결과없이 반복되고 역시 어두워짐**서리내린 다리를 걷는 격**범법은 형벌이 있게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`연루위배지체연기변경별도대책**만약 타인과 다툰다면 한 그물에 든 고기격으로 연루되고 예상되는 결과가 변경되고 지체되므로 별도의 대책을 강구해야 함 `,0,0,`어떤 예정과 약속이 반드시 변경되므로 별도 대책을 세워 놓아야 하는 人`,0,0,`부부 똑같이 색정사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 공격할 의사가 있으나 움직이지는 않음`,0,0,`스스로 자임하면서 옛것을 지키고 새 기회를 기다리며 신음하는 人`,0,0,`신규사가 있음**이전 이동사가 있음**송쟁사가 있음-고시준비생에 해당되는 경우가 많음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사타발동사겹침`,`기사`,`신구중간사`,`아외사타내사겹침`,`아이면사타표면사겹침`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`배신-무력`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-출비됨`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`이외사`,`미사 `,`신사`,`돌발사`,`모호사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동시후회사겹침`,`기사`,`신사`,`외사`,`표면사이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`훙`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`애증합의`,`애증합의`,`애증합의`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월`,1,3,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음7월-연기우려`,3,1,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음10월`,2,1,3,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음3월-연기우려`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음2월-연기우려`,2,2,4,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음1월`,2,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음2월-연기 또는 파혼우려`,3,3,6,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음1월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음9월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월`,2,3,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음9월`,3,3,6,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성품이 둔한 듯하나 조정능력이 있어 업무의 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무 전반이 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고업무도 수준이상이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻어 음11월애 결속단합을 이루고 정체성을 크게 고양함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무를 성공적 방침으로 진행하나 외부적 요인으로 타격을 받음`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 초반 이후 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 음12월에 개신개혁을 완성하여 재정을 크게 확보함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 있어 동료의 협력을 받으나 초반 외 중 종반의 업무가 수준 이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무도 성공적임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하여 업무 내내 송사가 이어짐`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`多集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후난`,`退背`,`원`,`去`,`주인가해`,`정남`,``,`정동`],
    [`10.11`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선이후費`,`進向`,`  근`,`  留`,`주인가해`,`남남서`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인제압`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`서남`,``,`북북서`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세없음`,`이행`,`본인사안`,`退背`,`  근`,`  去`,`주인무력`,`정서`,`정동`,``],
    [`10.11`,`  集`,`제압불가`,``,`무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`본인무력`,`退背`,`  근`,`  去`,`세입가해`,`남남서`,`동남`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선난후난`,` 退背`,`  원`,`  去`,`상호동요`,`동북`,`남남동`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선攻후난`,`退背`,`  원`,`  去`,`세입조력`,`동남`,`정동`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대없음`,` 이행`,`선攻후費`,`進向`,`  근`,`  留`,`세입조력`,`북서`,`남남서`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인합세`,`이행`,`선費후이`,`進向`,`  원`,`  留`,`무난`,`북북서`,`서남`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`기세없음`,`이행`,`선이후이`,`退背`,`  원`,`  去`,`주인조력`,`정동`,``,`정서`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`제압불가`,`이행`,`권리양도`,`進向`,`  근`,`  留`,`주인조력`,`동남`,``,`서남`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선費후費`,` 進向`,`  근`,`  留`,`무난`,`남남동`,``,`동북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,"목기부정","樹斷木物",`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`자살귀`,`水 투신`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`자살귀`,`水 익사`,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`자살귀`,`水 투신`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`변사귀`,`객사`,0,0,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`자살귀`,`水 투신`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`음귀`,`금속怪물`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,`동남`,`특별있음`],
    [``,``,``,0,0,`묘향`,`북서`,``],
    [`생업`,``,``,0,0,`전경`,`草 습지`,``],
    [``,``,``,0,0,`묘속`,`泥土`,``],
    [`가정`,``,``,`목기부정`,`樹斷木物`,`육친`,`고조부`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사동요불안분산가-출행이동기로사`,`여러침해극복-이익진취사`,`매사 허성불성-세속허사자`,`음7,8,10,11월 방문자는 종교진출하면성공함`,`매사 영속성없음**불리 고시사-구설사`,`종교진출하면자립함`,`외사급속사-내사출행이동사`,`환성경사`,`자식위한문서상신지체사`,`가정가출사`,`내부가정막힘-반목사`,`중중희愛사`,`내사급속사**명예직녹사-외사외교막힘`,`복통수욕사-이동사`,`외남 색정사`,`앙화소멸사`,``,`미초희경사-하사 받음`,`외사애증화합송쟁파재실종도망사-상해우려`,`도적실세-잡음`,`남녀직원공모은닉사有**내녀 색정사**가정화합사`,`음사폐호-가택안녕사`,`내사가정동요분산사`,`탄식신음사-병,주력사 미수사 원인`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전호위복`,`관재`,`눈이가려진상`,`빈궁`,`내사가정파재극처실종사`,`전도**빈궁`,`전화위복`,``,`빈궁**관재`,``,`전도`,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁**병재`,``,`전도`,`재액해소`,`빈궁**병재`,`재액해소`,`빈궁`,`침체驚懼사`,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`내부가정동요분산사`,`탄식신음사-병,주력사 미수 원인`,`외사동요불안분산사-출행이동기로사`,`여러침해극복-이익진취사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**불리 고시사-구설사`,`종교진출하면자립함`,`외사급속사-내사출행이동사`,`환성경사`,`자식위한문서상신지체사`,`가정가출사`,`중중희愛사`,`내부가정막힘-반목사`,`매사급속사**명예직녹사-외사외교막힘`,`복통수욕사-이동사`,`외남 색정사`,`앙화소멸사`,``,`미초희경사-하사 받음`,`외사애증화합송쟁파재실종도망사-상해우려`,`도적실세-잡음`,`내녀 색정사**가정화합사`,`음사폐호-가택안녕사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전화위복`,`전도**관재`,`눈이가려진상`,`전도**빈궁`,`내사가정파재극처실종사`,`빈궁`,`재액해소**전화위복`,`관재`,`재액해소`,`빈궁`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`관재**병재`,``,``,`빈궁**병재`,``,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내녀 색정사*가정화합사`,`음사폐호-가택안녕사`,`탄식신음사-병,주력사 미수 원인`,`내부가정동요분산사`,`외사동요불안분산사-출행이동기로사`,`여러침해극복-이익진취사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**불리 고시사-구설사`,`죵교진출하면자립함`,`외사급속사-내사출행이동사`,`환성경사`,`자식위한문사상신지체사`,`가정가출사`,`내부가정막힘-반목사`,`중중희愛사`,`매사급속사**명예직녹사-외사외교막힘`,`복통수욕사-이동사`,`외남 색정사`,`앙화소멸사`,``,`미초희경사-하사 받음`,`외사애증화합송쟁파재실종도망사-상해우려`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`재액해소`,``,`전화위복`,`관재`,`눈이가려진상`,`빈궁`,`내사가정파재실종사**재액해소`,`빈궁`,`전화위복`,``,`빈궁`,``,`재액해소`,``,`음4,5월화재주의`,`전도**관재`,`음4,5월화재주의`,`전도**관재**병재`,`재액해소`,``,`빈궁**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`외사애증화합송쟁파재실종도망사-상해우려`,`실업실정-한가함`,`내녀 색정사**가정화합사`,`간사은닉-가택이사-관계불통사`,`재난정소사`,`매사 위역사**내부가정동요분산사`,`외사동요불안분산사-출행이동기로사`,`투서헌책불용사-금전실재사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**불리 고시-구설근심사`,`종교진출하면자립함`,`외사급속사-내사출행이동사`,`고위입각-다중이익전포사`,`가정가출사`,`폭객기능사`,`내부가정막힘-반목사`,`관재형벌 매우 흉함`,`매사급속사**명예직녹사-외사외교막힘`,`미초희경사-권리수명-이동사`,`외남 색정사`,`도적악의-공격 말 것`,``,`실업실정-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`재액해소`,`빈궁`,`전화위복`,`빈궁**관재`,`부처반목사`,``,`내사가정파재실종사**재액해소`,`빈궁`,`전화위복`,``,`빈궁**관재`,``,`음4,5월화재주의**재액해소`,`빈궁**병재`,`음4,5월화재주의`,`전도**관재`,`음4,5월화재주의`,`전도**빈궁`,`빈궁**관재`,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`실업실정-한가함`,``,`외사애증화합송쟁파재실종도망사-상해우려`,`싱업실정-한가함`,`내녀 색정사**가정화합사`,`간사은닉-가택이사-관계불통사`,`재난전소사`,`매사 위역사**가정동요분산사`,`외사동요불안분산사-출행이동기로사`,`투서헌책불용사-금전실재사`,`매사 영속성없음`,`종교진출하면자리함`,`매사 영속성없음**불리고시-구설근심사`,`종교진출하면자립함`,`외사급속사-내사출행이동사`,`고위입각-다중이익전포사`,`폭객기능사`,`가정가출사`,`매사 위역사**관재형벌 매우 흉함`,`내부가정막힘-반목사`,`매사급속사**명예직녹사-외사외교막힘`,`미초희경사-권리수명-이동사`,`외남 색정사`,`도적악의-공격말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`전도`,``,`전도**관재`,``,`빈궁`,``,`재액해소**전화위복`,`관재`,`부처반목사**재액해소`,``,`빈궁`,`내사가정파재극처실종사`,`전화위복`,`관재`,``,`빈궁`,``,`병재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`외남 색정사`,`도적악의-공격말 것`,`실업실정-한가함`,``,`외사애증화합송쟁파재실종도망사-상해우려`,`실업실정-한가함`,`내녀 색정사**가정화합사`,`간사은닉-가택이사-관계불통사`,`재난전소사`,`매사 위역사**가정동요분산사`,`외사동요불안분산사-출행이동기로사**재액해소`,`투서헌책불용사-금전실재사`,`매사 영속성없음**재액해소`,`종교진출하면자립함`,`매사 영속성없음**내사가정파재극처실종사`,`종교진출하면자립함**불리고시-구설근심사`,`외사급속사-내사출행이동사`,`고위입각-다중이익전포사`,`폭객기능사`,`가정가출사`,`매사 위역사**관재형벌 매우 흉함`,`내부가정막힘-반목사`,`매사급속사**명예직녹사-외사외교막힘`,`권리임명사-권리수명-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5원화재주의**동요분산사`,`빈궁`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사**전화위복`,`관재`,`부처반목사**동요불안분산사`,``,`동요불안분산사`,`빈궁`,`동요불안분산사**전화위복`,``,`동요불안분산사`,`빈궁`,`동요불안분산사`,`병재`,`음4,5월화재주의**동요분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사급속사**명예직녹사-외사외교막힘`,`미초희경사권리수명-이동사`,`외남 색정사`,`도적악의-공격말 것`,`실업실정-한가함`,``,`외사애증화합송쟁파재실종도망사-상해우려`,`실업실정-한가함`,`내녀 색정사**가정화합사`,`간사은닉-가택이사-관계불통사`,`재난전소사`,`매사 위역사**내부가정동요분산사`,`외사동요불안분산사-출행이동기로사`,`투서헌책불용사-금전실재사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**내사파재극처실종사`,`종교진출하면자립함`,`외사급속사-내사출행이동사`,`고위입각-다중이익전포사`,`촉객기능사`,`가정가출사`,`매사 위역사**관재형벌 매우 흉함`,`내부가정막힘-반목사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`전도**빈궁`,`재액해소`,`관재`,`재액해소`,``,`전도**빈궁`,``,`전화위복`,`비눙**관재`,`부처반목사`,``,`불리 고시-구설근심사`,`전도**빈궁`,`전화위복`,``,`빈궁**관재`,``,`음4,5월화재주의`,`전도**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`중중희愛사`,`내부가정막힘-반목사`,`매사급속사**명예직녹사-외사외교막힘`,`구설사송흉-문서소식 길**중중희愛사`,`외남 색정사**도적에 놀램-금전실재근심사`,`입실관계성립사`,`투서헌책 나쁨-구설-금전실재사`,``,`외사애증화합송쟁파재실종도망사-상해우려`,`모두 재난 뿐인상`,`내녀 색정사**가정화합사`,`간사은닉-가택이사-관계불통사`,`탄식신음사-병,주력사 미수 원인`,`내부가정동요분산사`,`외사동요불안분산사-출행기로사-도적근심사`,`투서헌책 나쁨-금전실재사-구설사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**승진영전사-부족`,`종교진출하면자립함`,`외사급속사-내사출행이동사`,`관직자 생살대권 쥠-일반 형벌 대흉함`,`폭객기능사`,`가정가출사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,`빈궁`,``,`빈궁**병재`,``,``,``,``,``,`전화위복`,`빈궁**관재`,``,`빈궁`,`내부가정파재극처실종사`,``,`전화위복`,`전도**관재**병재`,``,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`폭객기능사`,`가정가출사`,`중중희愛사`,`내부가정막힘-반목사`,`매사급속사**명예직녹사-외사외교막힘`,`구설사송 흉-문서소식 길**중중희愛사`,`외남 색정사`,`입실관계성립사`,`투서헌책 나쁨-구설-실재근심사`,``,`외사애증화합송쟁파재실종도망사-상해우려`,`모두 재난 뿐인상`,`내녀 색정사**가정화합사`,`간사은닉-가택이사-관계불통사`,`탄식신음사-병,주력사 미수 원인`,`내부가정동요분산사`,`외사동요불안분산사-출행이동기로사`,`투서헌챡 나쁨-금전실재-구설사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**승진영전사-부족`,`종교진출하면자립함`,`외사급속사-내사출행이동사`,`관직자 생살대권 쥠-일반 형벌 대흉함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,``,`빈궁`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁**병재`,``,``,``,``,`빈궁**관재`,``,`도적근심사**전화위복`,`빈궁**관재`,``,`빈궁`,`내부가정파재극처실종사`,``,`전화위복`,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사급속사-내사출행이동사`,`도적잡기 어려움`,`재물유실사`,`가정가출사`,`매사 위역사**관재형벌 매우 흉함`,`내부가정막힘사`,`매사급속사**명예직녹사-외사외교막힘`,`복통수욕사-이동사`,`외남 색정사`,`身物수손사`,`투서헌책 나쁨-금전실재사`,``,`외사애증화합송쟁파재실종도망사-상해우려`,`혼인만족단장사`,`내녀 색정사**가정화합사`,`관재질병사`,`매사 위역사**내부가정동요분산사`,`재난전소사`,`외사동요불안분산사-출행이동기로사`,`여러침해극복-이익진취사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**존비상몽사-신분나이차이음란`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁`,``,`전도**관재`,``,`빈궁**병재`,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,`빈궁`,``,``,``,`빈궁`,``,`빈궁`,``,`전화위복`,`관재`,`동지함의사`,``,`내부가정파재극처실종사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자**존비상몽사`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`외사급속사-내사출행이동사`,`도적잡기 어려움`,`재물유실사`,`가정가출사`,`매사 위역사**관재형벌 매우 흉함`,`내부가정막힘-반목사`,`매사급속사**명예직녹사-외사외교막힘`,`복통수욕사-이동사`,`외남 색정사`,`身物수손사`,`투서헌책 나쁨-금전실재사`,``,`외사애증화합송쟁파재실종도망사-상해우려`,`혼인만족단장사`,`내녀 색정사**가정화합사`,`관재질병사`,`매사 위역사**가정동요분산사`,`재난전소사`,`외사동요불안분산사-출행이동기로사`,`여러침해극복-이익진취사`,`매사 영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내부가정파재극처실종사`,`빈궁`,`존화위복`,`빈궁**관재`,``,``,`재액해소`,`빈궁**병재`,`음4,5월화재주의`,`전도**빈궁`,`음4,5월화재주의`,`전도`,`재액해소`,`빈궁`,``,``,`빈궁`,``,`재액해소`,`빈궁`,`관재`,`폐한인**전화위복`,`동지함의사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자**불리 고시사-구설사`,`음7,8,10,11월방문자는종교진출하면성공함`,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`외사급속사-내사출행이동사`,`환성경사`,`가정가출사`,`자식위한문사상신지체사`,`내부가정막힘-반목사`,`중중희愛사`,`매사급속사**명예직녹사-외사외교막힘`,`복통수욕사-이동사`,`외남 색정사`,`앙화소멸사`,``,`미초희경사-하사 받음`,`외사애증화합송쟁파재도망사-상해우려`,`도적실세-잡음`,`내녀 색정사**가정화합사`,`음사폐호-가택안녕사`,`가정동요분산사`,`탄식신음사-병, 주력사 미수 원인`,`외사동요불안분산사-출행이동기로사`,`여러침해극복-이익진취사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`눈이가려진상`,`빈궁`,`내부가정파재극처실종사`,`빈궁`,`전화위복`,``,`빈궁`,``,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁**병재`,``,``,`전도**빈궁**병재`,``,`전도**빈궁`,``,``,``,`재액해소**전화위복`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 내 것 좋은 줄 모르고 쓸데
  없이 나섰다가 재판 붙었겠네?     가정에 부정까지 가득 찼네?      이것부터 해소해야 길이 뚤리     겠어!
⊕⊖가택에가택목기부정이      들었다.
`,`■ 물러나고 싶겠는데 후퇴가     안되게 생겼네? 매사 여건이     부족한 실정이겠어!
  삼각관계까지 있고! 
  一女二男이네! 
  상황도 역전되고 있겠어!
⊕⊖신변목기부정도 침범했네? 
`,`■색정사로 덕 보게 생겼네?    그러나 호악이 반반이겠네!    속는일과 자손재해가 생    기겠어!

⊕변사귀 
⊖신부정귀를 잡고 나야 吉이    찾아와 돈을 쥐게 되겠어!
`,`■ 지금 놀라고 있는 것은 
  시간이 지나면 없어지는 일     이겠고, 문제는 귀신이야! 

⊕변사귀, 
⊖신부정귀를 잡아야 보이는    돈이 내 것이 되겠어!
`,`■ 男:속기도 하고 권리를 상실          하게생겼네 !
  女:남편(남자)의 재해가            걱정되네!
모든 일이 등 돌리고 말았겠네? 
공격 받는게 쉴 틈이 없겠어! 
⊕타인의 부인을 유혹 했으니    자살귀
⊖도로귀ㆍ가택음사부정을     해소시켜야 조용해지겠네!
`,`■가정 금전 여자문제로 
 의심질투가 있어 기로에 섰네? 
 옛 일이고 옛 인연이고 
 모두 끝났어! 
⊕변사귀ㆍ자살귀, 
⊖도로귀ㆍ신부정귀부터 
 잡는게 급선무겠어!

⊖신변음사부정도 침범했어 ! 
`,`■ 색정불화지만 새로운 일에     좋은 것이마구 뛰고 있네? 
  이걸 거머쥐려면 
⊕변사귀 
⊖신부정귀와 집에 잔뜩 든 ⊕⊖음사부정을 해소해야만      되겠어!
`,`■ 돈도 보이고 여자도 보이     는데 매사 흉화위길하겠어! 
女:남편의 凶이 생겨! 
그런데
⊕자살귀 
⊖여귀를 해소해야 미움을     면하겠어!
⊕⊖신변음사부정도침범했네? 
`,`■火月(巳午)土月(辰成丑夫)만    되면 돈벼락을 맞긴 맞겠    는데 자칫 관재가 끼어들    겠어! 
가택의 
⊕음사부정을 해소 시켜야
여러건을 취합하는 개혁이   뜻대로 될꺼야!
`,`■여러 사람이 추천을 해 주는    운세이긴 한데… 
⊕음귀 
⊖신부정귀를 물리쳐야 계획    과 뜻이 이루어지겠어! 
 돈도 들어오고…

⊕⊖신변음사부정도침범했어! 
`,`■ 돈 문제, 여자문제는 
  속전속결로 끝내야지 
  우물쭈물 하다가는 끝이     안나게 생겼어! 
  부모의 재해도 생기겠고!
⊕수귀 
⊖여귀도 해소해야 되겠고!
 지금 전전긍긍하고 있어 !
`,`■ 이사 하겠네? 
  집이든 생업이든 옮기고       바꿔지겠는데변경되거나      취소되거나 늦어지게 생겼어! 
그런데 부부모두 색정이 있는데다,1男2女 삼각관계까지있네? 
흉이 보여! 
⊕음귀 
⊖신부정귀 발동이야!`];
  const YOGYEOL1 = [`■ 녹과 재물과 장생이 있으나 공망과 백호가 종횡한다. 조용히 있어야 하는데도 움직이게 되면 하고자 하는 일은 성사가 안된다.
  `,`■ 자식의 빚을 갚아 주게 되고 부모의 일 또한 편치 않다. 그런 연후에 근심이 없어지겠다. 물러서면 무해하다.
  `,`■ 두 사람이 만나 사귀어도 각각 이익과 손해가 있게 된다. 밤 귀인이 낮 귀인에게 갔으나 낮정단은 귀인의 도움을 믿지 못할 것이다.
  `,`■ 파쇄살이 가정에 들었으니 사업과 혼사에는 손해가 많다. 낮정단은 귀인의 도움이 없고 신음할 뿐 말 못할 사정이 생긴다.
  `,`■ 사과삼전이 모두 잠기고 꺼졌으니 나쁜 것은 되려 잘 풀리겠다. 남자문제가 겉과 속으로 연결되어 밤정단은 음란사가 두렵다.
  `,`■ 초전 외 중 말전이 허무하니 오직 시작만 있을 뿐이다. 움직인들 도적만 만나게 되고 낮정단은 귀인의 도움도 없겠다.
  `,`■ 정마가 여기 저기 많으니 활동하여 귀한 것을 이루겠다. 밤정단은 움직이지 말라. 귀살과 묘가 있어 잘 되기 어렵겠다.
  `,`■ 처나 재물이 비록 보기 좋아도 다가가게 되면 나를 해친다. 여러 사람의 입이 하나같이 나를 해치니 구설 관재를 조심하라.
  `,`■ 교제거래가 내게 이로우나 낮정단은 귀인을 믿지 마라. 밤정단은 천장이 도와 재수는 있겠으나 분수 밖의 재물과 여자를 삼가하라.
  `,`■ 금전ㆍ귀인ㆍ후원이 삼전에 모두 모였다. 서로 추천하여 도우나 밤정단은 성사가 잘 안되겠다.
  `,`■ 재물이 관직을 돕는다. 공직자는 매우 기쁘겠다. 숨은 귀신과 드러난 도적이 있으니 손해 보거나 도둑맞겠다.
  `,`■ 귀인과 청룡이 보이니 낮정단은 좋은 일을 만나겠다. 도적과 강도로 변하니 밤정단은 나쁜 일이 많겠다.`]
  const SIBO1 = [`■ 지금까지의 사정이 
  정반대로 바뀐다. 
  기쁨은 불안으로 불안은 
  안정으로 변화한다. 
  ⊕놀라운 일이 생기고 질병ㆍ송사는 위태하다.
  ⊖허위ㆍ도난을 당한다. 관공직은 大吉하다.
  `,`■ 1남2녀 삼각관계가 있다.
  여건도 불리하여 모든
  일을 중지하는 것이 좋다.
  처음에는 번영해도 후에는 폐퇴하게 된다. 
  상대가 스스로 와서 손해를 끼친다. 동ㆍ정이 변화한다.
  자손의 凶이 기하고 속는일이 있게된다.
  `,`■ 女는 별리무덕ㆍ고독인이다.
  음사사가 있다.
  간사음모로 암재ㆍ명재를
  얻게 된다. 
  사람은 왕성하나 가택은 쇠한다. 길흉이 巳日ㆍ月에 발현된다.
  正月 임신사가 있다.
  진퇴양난사가 있다.
  `,`■ 호가호위의 위협에 놀랜다.
  그러나 자연 해소 되는
  일이다. 
  결과가 유리하고 서서히 발복하게 된다. 
  ⊖물품이나 금전을 도난  당하거나 속는 일이 있게 된다. 매사 인내하고 좋은 기회를 기다리면 편안하다.
  자손 재해가 기한다.
  `,`■ 저주사가 있다. 
  여러사람으로부터 침해를 
  받는다. 正月 임신사 有
  일이 연속된다. 그러나 경미하다. 女는 별리ㆍ고독인 이다. 
  ⊕남자가 타인의 부인을 유혹하여 거래한다.
  ⊖부인에게 비읍사가 기한다. 처음은 흉하나 후에는 길하게 된다.
  `,`■ 자충수적 우환이 있고
  가정에는 ⊕저주사
  ⊖신불진노가 있다.
  가장을 속인다. 
  모든 일이 허성ㆍ공론이다. 타인의 추천도 진정이 없다. 
  옛 일, 인연이 끝난다. 
  숨겨지고 드러나고 하는 침해를 예방하라.
  正月 임신사가 있다.
  `,`■ ⊕문서구설 ⊖신불의
  진노를 받고 있다. 
  가정에 음사부정이 들었다.
  노력에 의해 뜻을 이루나 불안이 있다. 
  신규사ㆍ자녀사로 상한내외ㆍ동요반복사가 있다. 관공직은 좋고 병ㆍ송은 불리하다.
  `,`■ 신변이 침체되어 있다.
  그러나 흉은 해소되고 
  길로 변한다. 
  재물의 암재ㆍ명재로 있겠으나 분수가 넘으면 관재의 근심이 있게 된다.
  正月 임신사가 있다.
  권리상실사가 기한다.
  `,`■ 금전사를 개신ㆍ개혁한다.
  구개신취이다. 
  여러 건의 일을 하나로 
  묶고 처음에는 소모ㆍ손실이었으나 土月에 이르러 완성한다. 
  단, 관재조심ㆍ존친재해를 예방해야 한다. 男은 처첩을 두고 있다.
  正月 임신사가 있다.
  `,`■ 신규사로 타인의 추천을
  받게 된다. 재력이 있다.
  모든 일은 길조가 있어
  계획ㆍ모망사가 완성된다. 
  ⊕험악하고 놀라운 일을 겪는다. 
  ⊖금전ㆍ여자문제는 뜻을 이룬다.
  `,`■ 금전사로 전전긍긍 한다.
  일에 방해가 있다. 
  辰日ㆍ月에 흉이 있게 
  되고 죄가 되면 사면이 어렵다. 그러나 본인의 노력에 의해 피면된다. 
  만사 시작은 있으나 끝이 없으므로 속전속결해야 한다.
  正月 임신사가 있다.
  `,`■ 부부가 똑같이 음사사가
  있고 각기 1남2녀 삼각
  관계를 이루고 있다.
  중지하는 것이 좋다.
  ⊕여인구해 ⊖신불진노가 있기 때문이다. 가택의 권리가 넘어간다. 스스로 가서 상대에게 이익을 주고 손해 본다.`]
  const SIBO2_1 = [`■직ㆍ녹,신변외사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 사업ㆍ후원진퇴사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 자손ㆍ박관가택내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다
  속는 일이 있고 자손 재액이 있다.
  `,`■官･夫,신변동요사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  외사손상, 폐구의 상이다.
  `,`■官･夫,가정내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.병·관재예방이 필요하다.
  `,`■ 남녀상쟁동요사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  옛일이 끊어진다.
  `,`■ 직ㆍ녹,신변외사다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 사업ㆍ후원반목대립사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  `,`■ 財ㆍ女가정내사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다.부모․자손 재해 예방이 필요하다.
  `,`■ 財ㆍ女,신변외사다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 財ㆍ女,신변외사다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 官･夫,내외동요사이다.
  내방조짐 상의 :  
  은근하고 伏 하라.
  가택의 권리가 양도되고 이전․이사를 한다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/053/row-1-column-1.png`,
    `/static/images/sagwa/053/row-1-column-2.png`,
    `/static/images/sagwa/053/row-1-column-3.png`,
    `/static/images/sagwa/053/row-1-column-4.png`,
    `/static/images/sagwa/053/row-1-column-5.png`,
    `/static/images/sagwa/053/row-1-column-6.png`,
    `/static/images/sagwa/053/row-1-column-7.png`,
    `/static/images/sagwa/053/row-1-column-8.png`,
    `/static/images/sagwa/053/row-1-column-9.png`,
    `/static/images/sagwa/053/row-1-column-10.png`,
    `/static/images/sagwa/053/row-1-column-11.png`,
    `/static/images/sagwa/053/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `   이 과는 천지반 본궁에 앉아 천지가 움직이지 않기 때문에 복음과가 된다. 양일(陽日)은 간상신(干上神)을 사용하여 초전으로 하는데, 초전의 사(巳)는 덕록에 해당하며 정신이 승한다. 중전의 신(申)은 재효이고, 말전의 인(寅)은 장생 역마가 된다. 
    복음과에서 삼전 중 정신 역마가 모두 있으면 정(靜) 중에 이미 동(動)한다. 말전은 초전의 덕록을 생하여 도우니 관공직을 정단하면 크게 길하다. 또 천강에 청룡이 승하면 기쁨이 있고 후에 불안한 일이 생긴다. 
    삼전 맹신은 원태격(元胎格)이고 생기는 있으나 체극(遞剋)으로 되어 출산은 신속하게 안산한다.
    백법의 번호는 제89에 해당한다. 양일의 복음은 스스로 강하다고 자처하기 때문에 자임격(自任格)으로 되어 쓸데없는 참견을 하면 불리하다. 
    비전에 이르기를 ｢체극이 있다｣고 하였다. 현임 관리의 건을 정단하면 행동상 과도한 점이 있고 또 탄핵(彈劾)를 받는다.
    일귀는 역행하며 삼전은 구․사․백이고, 야귀는 순행하며 삼전은 공․현․합이다. 사호(蛇虎)가 전에 들어가면 놀라운 일이 생기고 질병이나 소송사는 모두 위험하다.`
    ,
    // 2
    `   이 과는 4과이지만 3과밖에 없고, 1상이 1하를 극하는 묘진(卯辰)을 초전으로 하는 원수과이며 불비퇴여격(不備退茹格)이다. 
    일지상신은 일간을 생하여 초전으로 된다. 일지는 일간상에 임하여 생을 받으며, 그가 오면 나는 그에게 덕을 입힌다. 또는 상대가 와서 나의기운을 뺏어가고 나를 돕는 인수와 상해(相害)한다. 말전은 탈설하지만 공망하므로 오히려 근심을 소산하기 때문에 퇴여 공망은 전진하는 것이 좋다. 그러나 처음은 인묘(寅卯)의 생기이고 말전의 공망은 처음은 번영해도 후에는 낙엽과 같다.
    불비격은 무슨 일이나 불비결함(不備缺陷)이 있다.
    일귀는 역행하고 삼전은 공․백․상이며, 야귀는 순행하며 삼전은 구․합․주이다. 이 과는 그리 흉조(凶兆)를 이루지 않으니 만사 자기를 잘 지키면 좋다.`
    ,
    // 3
    `   이 과는 제2과와 제3과에 상하의 극이 있고 하에서 상을 손상하는 축묘(丑卯)를 사용하여 초전으로 하는 중심과이다. 초전의 공망은 과숙격(寡宿格)이고, 삼전 축해유(丑亥酉)는 태양의 광선을 볼 수 없으니 극음격(極陰格)이다. 
    일간의 천을일야귀신 유해(酉亥)가 말전에 겹치면 대소를 불문하고 덕을 본다. 초전의 공망은 동기공망(動機空亡)하여 길흉은 모두 성취되지 않는다. 극음(極陰)은 간사음모(奸私陰謀)에는 이롭지만 공사(公事)에는 불리하다. 일지상신이 지(支)를 극하고 일간을 생하여 사람은 왕성하나 택은 쇠퇴한다. 귀신(貴神)이 해(亥)와 축(丑)에 임하면 년명이 자(子)가 되고, 양귀(陽貴)를 끼우면 정단사는 무슨 일이나 길행이 있다.
    비전에 이르기를 ｢삼전에 축유(丑酉)가 있어 사(巳)를 기다려 3합금국(三合金局)하기 때문에 사일(巳日)에 길흉이 반응하니 이것을 기일대용(期一待用)이라 부른다. 다른 삼합도 이 법칙을 채용하여야 한다｣고 하였다. 또 이르기를 ｢병일(丙日)은 자(子)를 태신(胎神)으로 하니 인월(寅月)에 정단하면 처가 임신한다｣고 하였다. 
    혈기(血忌)․혈지(血支)는 모두 축(丑)에 해당하는데, 출산을 간점(看占)하면 신속하지만 태아에게 손상이 있다. 축월(丑月)을 정단할 때는 혈지․혈기는 자(子)에 있고, 또 출산을 정단하면 신속하다.
    백법의 번호는 제18에 해당하다. 말전 신유(辛酉)는 둔간암재(遁干暗財)가 된다.
    일귀는 순행하며 삼전은 구․주․귀이고, 야귀는 순행하며 삼전은 주․귀․음이다.`
    ,
    // 4
    `   이 과는 사과 중 상하의 극이 없고, 제2과의 천반 해(亥)에서 일간을 비스듬히 극하니 요극과(遙剋課)로 되며 천반에서 극을 받아서 호시격(蒿矢格)이다. 
    삼전 4맹신(四孟神)은 원태격(元胎格)이고, 삼기(三奇)에 봉착하며 일간상신 인(寅)은 장생(長生)이 되어 지합한다. 간상에 청룡이 승하면 서서히 발복(發福)하는 기쁨이 있다. 중전의 신(申)은 일간에서 보아 재동하고 현무가 승하면 재 또는 물품을 도난당한다. 순미(旬尾)의 지지 해(亥)가 초전이 되면 폐구격(閉口格)이므로 입을 다물고 말을 하지 않으며, 무슨 일이나 도리는 있어도 말하지 못한다. 말전에 덕록이 승하므로 결과는 유리하다.
    비전에 이르기를 ｢일간상신 청룡이 장생에 승하면 목적을 이룬다｣고 하였다. 정단사는 모두 인내(忍耐)하고 좋은 기회를 기다리는 것이 편안하고 망동은 좋지 않다.
    백법의 번호는 제38에 해당한다. 호시격은 힘이 없다고 하지만 순기(旬奇)가 초전이 되면 기우(奇遇)를 만난다. 일귀는 순행하고 삼전이 주․후․상이고, 야귀는 삼전이 귀․현․공이다.`
    ,
    // 5
    `  이 과는 4과 중 제3과에서 자진(子辰)이 하에서 상을 극하여 중심과이고, 삼합수극 윤하격(潤下格)이다.
    초․중전이 공망하니 과숙격이다.
    초전은 일간을 극하지만 공망하므로 화(禍)를 이루지 않는다. 자신진(子申辰) 삼합수국의 중귀는 내몸을 극해(剋害)하고, 삼합하니 무슨 일이나 연속 발생한다. 이리하여 한 사람이 아니라 몇 사람으로부터 상침(相侵) 당한다. 그러나 일간상신 축토(丑土)가 수귀(水鬼)를 제하니 흉은 소재(小災)일 뿐이다. 소해(小害)일 때는 화를 피면해야 한다. 
    초전의 자(子)와 축(丑)이 지합하고 삼합 중 지합하면 기쁜 일이 있다. 가령 흉이 있어도 크지 않다.
    비전에 이르기를 ｢일지상신 자(子)에 사신(蛇神)이 승하면 부인에게 비읍(悲泣)의 일이 있다.
    일귀는 순행하고 삼전은 합․후․백이며,  야귀는 순행하고 삼전은 사․현․청이다. 초전은 사(蛇)이고 말전에는 청신이 승하는데, 이와 같은 때는 사가 화하여 용이 되니 처음에는 흉이라 하여도 후에는 길이 있다. 합․후가 승하면 음일격(陰泆格)으로 된다.`
    ,
    // 6
    `   이 과는 4과 중에 모두 상하의 극이 있다. 제3과․제4과의 양과에서 이하(二下)가 이상(二上)을 극하면, 양일(陽日)은 양의 극(剋)인 오해(午亥)를 사용하여 초전으로 하니 지일과(知一課)가 된다. 제1과․제2과의 중․말전은 공망에 해당한다. 초전은 양인(羊刃)이 되니 함부로 맹동하는 것을 삼가야 한다. 
    사절(四絶)의 과이니 오행이 묘(墓)에 들어가면 옛 것을 지키고 신규(新規)를 보류해야 한다. 중․말전이 공망하므로 모든 일이 허성(虛聲)이고 공론(空論)에 그친다. 축(丑)이 오(午)에 가담하는 것은 전택(田宅)이 있으면 그로 인해 분쟁이 생긴다. 말전 신(申)의 재(災)는 공망에 앉으니 재력은 기(氣)가 없고 오히려 소모의 건이 있다.
    비전에 이르기를 ｢축(丑)이 오(午)에 가담하면 저주(詛呪)할 일이 생기며, 중전 축(丑)에 주작․구진이 승하면 소송건을 초래한다｣고 하였다.
    삼전은 체생되어도 공망하여 진실한 뜻이 없다. 관귀는 일간과 일지상신에 있고 또 해(亥)에 암귀(暗鬼)가 있으니 명암의 적귀(敵鬼)를 방비해야 한다.
    일귀는 순행하며 삼전은 현․구․후이고, 야귀는 삼전이 백․주․현이다. 제1과의 자(子)가 사(巳)에 임하면 사(死)가 되니 병단(病斷)은 가장 흉하다. 귀신이 진(辰)에 임하면 귀인이 옥(獄)을 밟는다고 하여 관리에게는 불길하다.`
    ,
    // 7
    `   이 과는 천지가 상반(相反)하여 극위(剋位)에 있고 제2과의 하에서 상을 극하는 사해(巳亥)를 초전으로 하는 반음과(返吟課)이다.
    초전은 사해사(巳亥巳)의 원태격이다. 일간에서 보아 덕록이 정신으로 되고 일간상신 귀인은 명을 지킨다. 정신 덕록은 자기의 노력에 의하여 뜻을 이룬다. 그러나 순정(旬丁)은 어떤 일에 대해서는 불안하다. 중전은 관귀이지만 제3과․제4과에서 제하므로 귀해(鬼害)가 없고, 사관․구직은 목적을 달성한다. 원태격은 신규의 건이나 자녀에 관한 건이다.
    비전에 이르기를 ｢중전이 칠살이지만 일지상신으로부터 제하므로 가인이 이를 방지하여 재해를 제거한다｣고 하였다.
    반음과는 상하․내외․동요․반복이 동시에 정신 또는 동이(動移)의 신이므로 길흉에 모두 움직임이 있다.
    일귀는 순행하며 삼전은 상․주․상이고, 야귀는 역행하고 삼전은 공․귀․공이다. 이 과는 병간(病看) 또는 소송사에 더욱 불리하다.`
    ,
    // 8
    `   이 과는 제2과․제4과에 상하 상극이 있고 하에서 상을 극하는 인유(寅酉)를 초전으로 하는 중심과이다. 순수의 인(寅)이 초전이 되므로 순의격(旬儀格)이다. 일지의 상신 유(酉)는 신(辛)의 암재(暗財)로 된다. 
    초전의 인(寅)은 일간을 생하고, 말전의 자(子)는 칠살이지만 공망하므로 흉을 이루지 못한다. 초전 인(寅)은 일지의 역마가 되어 체극(遞剋)한다. 진(辰)의 일(日)은 지의(支儀)로서 승의와 병렬되어 있다. 형충은 관귀라 할지라고 흉이 해소되어 길로 변화한다. 일간지상신은 모두 육해로 되어 표면화합(表面和合)이 되지 않으며, 묘신이 일(日)에 복하여 제사는 삽체된다.
    비전에 이르기를 ｢일간상의 술(戌)은 일야에 사신이 타고, 지반의 사(巳)가 또 사(蛇)의 본궁(本宮)이다. 따라서 양사협묘(兩蛇夾墓)가 되고 병을 정단하면 병은 구하기 어렵다. 
    체극하는 것은 피차 서로가 불편하고 나를 극해하나 관살공망이 된다. 관직의 건은 좋지 않다.
    일귀는 순행하며 삼전은 청․음․합이며, 야귀는 역행하여 삼전은 현․구․후이다. 의신(儀神)과 양귀(兩貴)가 감싸서 위험한 재액을 만나지 않고, 만나도 구제할 수도 있다.`
    ,
    // 9
    `   이 과는 제1과 유병(酉丙)이 하에서 상을 손상하니 중심과이다. 삼전 유축사(酉丑巳)는 삼합금국으로 종혁격(從革格)을 구성하고, 일간의 재동이 된다. 
    말전의 사(巳)와 일지상신의 신(申)이 지합(支合)하고 일간일지상신도 재동이 되기 때문에 교역에서 재리(財利)가 있다, 삼전 금(金)재는 공명에는 이롭고, 부모의 인수를 파하니 존친에는 이롭지 않다. 제1과․제2과는 금(金)국이고, 제3과․제4과가 수(水)국으로 내가 그에게 설한다. 따라서 처음에는 내게 소모와 손실의 건이 있다. 일간상신 귀신이 재동이 되어 시험은 반드시 합격한다.
    비전에 이르기를 ｢자(子)는 태신이고 수(水)에서 신(申)은 장생에 앉는다｣고 하였다. 임신은 길이고 출산은 좋지 않다. 구재의 건은 삼전 재성이 천장의 토(土)에서 생부(生扶)하므로 크게 길하다. 
    삼전 유축사(酉丑巳)는 헌인(獻刃)이라 하여 원근을 불문하고 상해를 받을 위험성이 있다. 말전에서 체생(遞生)이 되지만 공망하므로 추천사는 진정(眞情)이 없다. 또 건록이 공망하기 때문에 현직 관리에게는 좋지 않다. 
    일귀는 역행하며 삼전은 귀․상․구이고, 야귀는 역행하여 삼전이 주․음․공이다. 천공과 공망이 병합하면 결과는 공론에 그친다.`
    ,
    // 10
    `   이 과는 제1과 신병(申丙)이 하에서 상을 극하여 초전이 되므로 중심과이다. 삼전 사맹(四孟)은 원태격이고, 신(申)은 일간의 재가 되며 해(亥)는 일귀에 해당한다. 인(寅)은 일간에서 보아 장생이 되고 삼전은 완전히 재(財)․관(官)․인(印)으로 3길신이다. 이리하여 초전에서 중전, 중전에서 말전, 말전에서 일간을 생하여 체생격이 된다. 무슨 일이나 타인의 추천에 의뢰하는 유정의 상이다.
    일간에서 제3과․제4과에 탈기(脫氣)한다. 재성은 내몸 기궁(寄宮)과 지합하니 재는 충실하고 몸은 풍만하다. 삼전의 삼길신이 명을 지키고 제사는 길조를 이루니 망사(望事)․모사(謀事)는 모두 이루어진다. 교차장생(交車長生)하고 부부는 화해한다. 상성이 좋으니 결혼은 성립된다.
    백법의 제31법 삼전체생격이다. 일귀는 역행하고 삼전은 사․음․백이며, 야귀는 삼전이 현․귀․합이다.
    일귀의 사(蛇)․호(虎)․가 삼전 중에 들어가면 험악하고 놀라운 일이 생긴다. 일간의 재동이 제1과 및 초전에 있으면 재를 구하면 뜻대로 된다.`
    ,
    // 11
    `   이 과는 제4과의 신오(申午)가 하에서 상을 극하여 중심과로 된다. 삼전 신술자(申戌子)는 간전섭삼연격(間傳涉三淵格)이다. 
    초전의 신(申)은 일간의 재신이고, 말전 자(子)는 칠살이지만 공망하여 사관․구직은 목적을 이루지 못한다. 그러나 관귀가 공망이면 흉을 보아도 흉을 이루지 않는데, 중전의 술(戌)은 암귀(暗鬼)로 되어 중도에 암해를 당한다. 섭삼연격(涉三淵格)은 먼저 전진하는데 따르고 자(子)는 야반(夜半)이 되기 때문에 전진에 방해가 있다. 초전 신(申), 말전 자(子)는 진(辰)의 일신(一神)을 기다려 삼합이 되기 때문에 길흉이 모두 진일(辰日)에 반응한다. 4과는 내부이고 체(體)이다. 4과 중에 극이 일위이고 일간일지상신 오미(午未)가 지합하며 교차는 화토(火土)의 비화(比和)로 된다.
    초전 신오(申午)에 가담하면 노화(爐火)의 본(本)이 있다. 이 과는 일간은 상신에 설하고 일지는 상신에서 생을 받기 때문에 사람은 부족해도 집은 풍부하다고 본다.
    일귀는 역행하며 삼전은 사․후․현(玄)이고, 야귀는 삼전이 합․사․후이다. 말전 공망은 만사가 시작은 있으나 끝이 없는 상이다.`
    ,
    // 12
    `   이 과는 4과 중 상하의 극도 요극(遙剋)도 없으며 4과 불비는 별책과(別責課)가 된다. 일간의 역마를 초전으로 하고, 중․말전은 일간일지상신의 오(午)를 사용한다. 일간 병화(丙火)는 중․말전에서 모두 일(日)에 왕하고, 체용(體用)은 모두 길이다. 
    초전이 귀신에서 극을 받으면 존장(尊長)의 노여움을 자아내고, 태음․귀동(鬼動)이 되어 중․말전이 양급(羊及)이 되면 흉으로 움직여서 흉해가 발생한다. 별책과는 음(淫)의 불비격이라고도 하여 무슨 일이나 중지하는 것이 좋다. 
    일간기궁의 사(巳)는 지상에 가서 지(支)를 생하고 타인에게 이익을 주는 격이다. 백법은 55, 88에 해당한다.
    비전에 이르기를 ｢일간이 지상에 임하면 양성이 동거한다｣고 하였다. 그리하여 일지를 생하면 역허격(歷虛格)이라 칭하여 타인에게 의존해도 힘을 얻지 못한다. 타인은 이익을 보고 나는 불리하다고 본다.
    일귀는 역행하며 삼전은 음․합․합이고, 야귀는 삼전이 귀․청․청이다. 천장은 모두 길로서 현직을 정단하면 승진의 기쁨이 있다. 
    일지상신 건록은 권섭(權攝)이 타인에세 양보하는 상이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
