import firebase from "firebase";
import "firebase/database";

var firebaseConfig = {

  apiKey: "AIzaSyC-BsuXCeKvHA9AaBbbn7APrgBY0o8qsEU",
  authDomain: "hyosa1-902e0.firebaseapp.com",
  projectId: "hyosa1-902e0",
  storageBucket: "hyosa1-902e0.appspot.com",
  messagingSenderId: "730063861125",
  appId: "1:730063861125:web:191c3f28d47041376b80ec",
  databaseURL: "https://hyosa1-902e0-default-rtdb.asia-southeast1.firebasedatabase.app",
  measurementId: "G-V5EFGGV01D"

};

const fireDb = firebase.initializeApp(firebaseConfig);
export default fireDb;