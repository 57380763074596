
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`고집`,8,8,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,9,7,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`위선`,10,6,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,5,`특별있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,4,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`위선`,1,3,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,2,2,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,3,1,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`위선`,4,12,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신뢰`,5,11,`특별있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,10,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,9,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`3`,`투서헌책`,``],
    [`부모`,`부모관련사-상사(喪事)우려`,`大`,`학습기`,`고위면담`,`事後쟁탈`],
    [`우녀상해(牛女相會)`,`남녀 인연이 되는 상- 혼인사 좋음`,`大`,``,``,``],
    [`동지함의(動止咸宜)`,`안방을 지켜 오가는 출입이 자유로운 상`,`小`,``,``,``],
    [`금전`,`금전사`,`小`,``,``,``],
    [`사업`,`관재 우려사`,`大`,`2`,``,``],
    [`처첩`,`가정반목대립쟁송사`,`小`,`면모기`,`우유한가`,`반목송쟁`],
    [`좌수부동(座守부동)`,`앉아서 도모해야하고 적극적으로 나아가면 나쁜 상`,`小`,``,``,``],
    [`신물수손(身物守損)`,`몸 또는 몸에 지니는 물건을 잃은 상-火傷`,`大`,``,``,``],
    [`명예`,`수상사`,`大`,``,``,``],
    [`직녹`,`직위 승급사`,`小`,`1`,``,``],
    [`막힘`,`외교 막힘`,`小`,`절정기`,`외교막힘`,`사기득재`],
    [`일거성명(一擧成名)`,`단번에 이름을 떨치는 상-부족`,`大`,``,`도적근심`,``],
    [`주유가음(主有佳音)`,`좋은 소식을 기다리는 상`,`小`,``,``,``],
    [`결속`,`결속해 재정의 기반을 크게 세우나 명예직위남편이 훼절됨`,`大`,``,``,``],
    [`실직`,`직위불안-남편실직 갈등사`,`小`,`12`,`색정사有`,``],
    [`색정`,`남편 부인 남녀 각각 색정사 有`,`小`,`과시기`,`비밀누설`,`색정사有`],
    [`망재복지(亡財復至)`,`잃은 금전(여인)을 회복하려고 적극적으로 활동하는 상`,`大`,``,`추궁득죄`,`질병구설`],
    [`자검불약(自檢不約)`,`약속한 비밀을 누설하여 추궁을 당하는 상`,`小`,``,``,``],
    [`금전`,`자금관련사-좋음`,`小`,``,``,``],
    [`사업`,`사업 동요사`,`大`,`11`,`간음이익`,`증오`],
    [`처첩`,`부인사-동요불안분산사`,`小`,`쇠퇴기`,`정도적은`,`동요불안`],
    [`이불가탐(利不可貪)`,`이익을 탐해도 불가능한 상`,`小`,``,`되려재앙`,`분산배신`],
    [`비상지애(非常之愛)`,`특별한 애정사를 벌이고  있는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`10`,`급속쟁송`,`도적소실`],
    [`자녀`,`자녀 관련사`,`小`,`침해기`,`상해우려`,`침체절감`],
    [`주유실탈(主有失脫)`,`가정내부 도난실탈이 있는 상`,`小`,``,``,``],
    [`생살대권(生殺大權)`,`관직자-생살대권 쥠-일반인 형벌 대흉의 상`,`小`,``,``,``],
    [`박관`,`직업직위불안사`,`大`,``,``,``],
    [`극부`,`남편(남자)갈등사-내외남녀상하결속-외화내허적인 상`,`大`,`9`,`단합결속`,`단합결속`],
    [`자녀`,`자녀관련사`,`小`,`소외기`,`외화내허`,`외화내허`],
    [`인이군자(印利君子)`,`관직자는 권리결제사의 이익이 있고 일반인은 불리한 상`,`小`,``,`발탁도약`,`재물유실`],
    [`비상지애(非常之愛)`,`각별한 애정사가 있는 상`,`小`,``,``,``],
    [`금전`,`금전 사업 관련사`,`小`,``,``,``],
    [`결속`,`결속해 재정의 기반을 크게 세우나 명예직위남편이 훼절됨`,`大`,`8`,`직위불안`,``],
    [`송쟁`,`여인사-가정반목대립형송사`,`小`,`침체기`,`탄식신음`,`반목송쟁`],
    [`계류사기(稽留詐欺)`,`사기사가 게류 중이고 빈 안방을 지키고 있는 상`,`小`,``,`병 事미수`,`파재극처`],
    [`생살대권(生殺大權)`,`관직자-생살대권 쥠-일반인 형벌 대흉의 상`,`大`,``,``,``],
    [`관사`,`관사 남편 직업 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`7`,`외사동요`,``],
    [`매사 영속성없음`,`직업남편권리명예사는 선천적 기복재해를 겪음`,`大`,`단절기`,`내외출행`,`관직영전`],
    [`고위임택(高位臨宅)`,`고위인이 방문하는 상`,`小`,``,`질병구설`,``],
    [`총시성재(總是成災)`,`여기저기 모두 재난 뿐인 상`,`大`,``,``,``],
    [`관사`,`내외 관사 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,9,12,7,8월 방문자는 종교진출하면 성공함`,`小`,`6`,``,``],
    [`매사 영속성없음`,`직업남편남자권리사는 선천적 기복재해를 겪음`,`大`,`존재기`,`손해도주`,`손해가출`],
    [`범사유성(凡事有成)`,`여러 일이 매듭지아져 완성되는 상`,`小`,``,`위례득죄`,`우유한가`],
    [`위례득죄(違禮得罪)`,`무례함이 지나쳐 죄에 이르는 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`5`,``,``],
    [`처첩`,`부인사`,`小`,`배양기`,`문서지체`,`내부막힘`],
    [`후경장진(後更長進)`,`주류업을 그만두고 오래 지속될 사업을 하고 싶은 상`,`小`,``,`구설문제`,`도적근심`],
    [`위례득죄(違禮得罪)`,`무례함이 지나쳐 죄에 이르는 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서관련사-외 애증사 有`,`小`,`4`,`애증관계`,`침체위역`],
    [`부모`,`부모관련사`,`大`,`개시기`,`탐영불화`,`도적죄수`],
    [`재필유실(財必遺失)`,`반드시 금전도난 또는 유실사가 있는 상`,`大`,``,``,``],
    [`왕진쇠퇴(旺進衰退)`,`세력이 있으면 나아가고 쇠하면 퇴하는 것이 이익이 되는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`후원문서부모조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`금전사업여인사로 가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`명예직녹권리사로 가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`자매친구여인사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`금전사업여인사로 가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,"직업지위불안부부갈등자녀사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,"직업지위불안남녀갈등자녀사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`上人금전사업여인사로 가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,"세속허사인이므로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,"세속허사인이므로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업여인사로 가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,`후원문서부모조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면  상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`현재 상태에서 물러나는 것이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후로 미루어 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고로를 겪은 후에 성공할 人`,0,0,`웃음속에 칼이 들었으므로 상대가 주는 호의를 쉽게 받아들이지 말 것**일이 반복되기만하나 풀리게 됨**진행이 띄엄띄엄 역으로 나아감**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위**만약 타인과 다툰다면 피아간에  전의가 별로 없고 손상도 없으며 있다하더라도 가벼움`,0,0,`호가호위적 위세에 크게 놀라고 있으나 조용히 있으면 저절로 해결될 人`,0,0,`고집이 있고 신규사가 있음**독신고독각방주말부부별리사별적 처지임**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 절대 거역하지 말고 순응유화적 처신을 해야 함`,0,0,`역상적 사안이므로 억울하더라도 순응처신으로 일을 미루면 결국 유리해지는 人`,0,0,`여러 일을 하나로 묶는 단합**타세에 의지함**어디로 깊이 숨고싶은 심정임**여건이 불비부족함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`의혹불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`혐의사로 불안하여 둘중 하나로 선택해야 할 기로에 섰으나 가장 친하거나 가장 가까히 거주하는 자로 선택하면 풀리는 人`,0,0,`일이 모두 끓김**우연히 발생한 목전사임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상으로 재난이 밖에서 오니 신중해야 함`,0,0,`부부이심색정사가 있고 반복무상한 처지의 人`,0,0,`신규사가 있음**불성함**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면  상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`입을 닫음**내외 암울함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정정당당하게 먼저 나서서 속전속결해야 함`,0,0,`선수를 쳐나아가 종결해야만 유리하고 미루거나 늦어지면 불리한 人 `,0,0,`여러 일을 하나로 묶는 단합사**결말없이 반복되나 계속 진행하면 좋음**여인이 남자를 적극적으로 유혹하는 兆`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 절대 거역하지 말고 순응유화적 처신을 해야 함`,0,0,`역상적 사안이므로 억울하더라도 순응처신으로 일을 미루면 결국 유리해지는 人`,0,0,`고집이 있고 신규사가 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후로 미루어 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고로를 겪은 후에 성공할 人`,0,0,`깊은 산으로 숨고싶은 심정**일이 띄엄띄엄진행됨**남자가 남의 부인을 유혹해 본인 부인처럼 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면  상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`일이 순서적으로 진행됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면  상대는 뜻은 움직이나 중지가 됨`,0,0,`자기과신으로 신음함`,0,0,`고집이 있고 신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성무력`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`좌고우면 무력`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성무력`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이발동후회사`,`기사`,`신사`,`외사`,`표면이면동시사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-반목대립쟁송`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-동요불안분산`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-반목대립소송사 이사예정`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-도난우려`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`   승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`   승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월`,2,1,3,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,2,3,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월`,3,3,6,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음12월`,2,3,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음11월`,2,3,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월`,3,1,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,2,3,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월`,3,3,6,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음1월`,2,3,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음11월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고준수 미모이나 일처리가 강하지 못하나 상대를 무력화시키는 등 원만한 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 지지기반이 약해 종반이라야 실적을 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세고 초중반에 무실적으로 가다가 종반에 반목대립을 거쳐 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 원조를 받아 단합하지만 외화내허적이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 무난하여 업적을 남기게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 있고 전반에 걸쳐 무력 무실적 결과가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 무난하여 업적을 남기게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 있고  외화내허적 업무를 집행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 초중반을 무실적으로 보내고 종반에 가서야 실적을 나타냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 원조를 받아 단합하지만 외화내허적이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 원조를 받으나 업적은 없게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 내내 반목대립송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月    적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`있음`,`불이행`,`안건무력`,`進向`,`근`,`留`,`주인무력`,`동북`,``,`정동`],
    [`1011`,`多集`,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`있음무력`,`불이행`,`안건무력`,` 進向`,`  원`,`  留`,`주인무력`,` 동남`,``,` 동남`],
    [`7.8월`,``,``,``,`허사`,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음무력`,`불이행`,`선압박加`,`退背`,`   근`,`   留`,`  반목대립`,`남남동`,``,` 동남`],
    [`10.11`,``,``,``,`후난`,``,``,``,`소송함`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`없음`,`불이행`,`선손후이`,` 退背`,`  원`,`  留`,`  주인       후원`,` 정남`,`정동`,``],
    [`10.11`,``,``,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음무력`,`불이행`,`선손후패`,` 退背`,`  근`,`  去`,`주인후원`,`남남서`,`동남`,``],
    [`1011`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`있음무력`,`불이행`,`본인무력`,` 退背`,`  원`,`  去`,`동요불안`,`북북동`,`북북동`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음무력`,`불이행`,`초비용出`,` 進向`,`  근`,`   留`,`초害후和`,`남남동`,`동북`,``],
    [`1011`,` 多集`,``,``,`후害결속`,``,``,``,``,`세밀심사`,`세멜심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,`함`,``],
    [`1.2월`,`  多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,` 없음`,`불이행`,`권리양도`,` 進向`,`초원후근`,`  去`,` 後易`,` 북서`,`정동`,``],
    [`7.8월`,``,``,``,`됨`,``,``,`권리양도`,`천천히`,``,`세밀심사`,``],
    [`1011`,`  集`,``,``,``,``,``,``,``,``,`함`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`있음무력`,`불이행`,` 손해`,`  退背`,`   근`,`  去`,`초압박`,`북북서`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,`후손해`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`속결요됨`,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`集`,`있음무력`,`불이행`,`선이후합`,` 進向`,`  원`,`  留`,`주인피해`,`  정북`,``,`정북`],
    [`7.8월`,``,``,``,`속결요됨`,``,``,``,`세입가해`,``,``,`세밀심사`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,` 없음`,`불이행`,`선이후이`,` 進向`,`   근`,`  去`,`주인피해`,`북북동`,``,`북서`],
    [`10.11`,``,``,``,`느긋이`,``,``,``,`세입가해`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,` 무력`,`불이행`,`선후압박`,` 進向`,`  근`,`  留`,`무난`,` 동북`,``,`북북서`],
    [`1011`,`  集`,``,``,`으로가능`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,0],
    [``,``,``,``,``,``,0,0],
    [``,``,``,``,``,``,0,0],
    [`질병`,`전흉사귀`,`혈광사`,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,`두성부정`,`기도위배`,`육친`,0,0],
    [`질병`,``,``,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,``,``,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,`전흉사귀`,`혈광사`,`상가부정`,`상가왕래`,`육친`,0,0],
    [`질병`,`전흉사귀`,`혈광사`,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,``,``,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,``,``,`육친`,0,0],
    [`질병`,``,``,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,`가옥   성소`,`성소훼손`,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,``,``,`육친`,0,0],
    [`질병`,``,``,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,`변사귀`,`객사`,``,``,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,``,``,`육친`,0,0],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,`두성부정`,`기도위배`,`육친`,0,0],
    [`질병`,``,``,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,``,``,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,``,``,`육친`,0,0],
    [`질병`,``,``,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,``,``,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,``,``,`육친`,0,0],
    [`질병`,`도로귀`,`교통사`,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,``,``,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,``,``,`육친`,0,0],
    [`질병`,`전흉사귀`,`혈광사`,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,`가당부정`,`성소훼손`,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,``,``,`육친`,0,0],
    [`질병`,``,``,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,``,``,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,``,``,`육친`,0,0],
    [`질병`,`전흉사귀`,`혈광사`,``,``,`묘좌`,0,0],
    [``,``,``,``,``,`묘향`,0,0],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,0],
    [``,``,``,``,``,`묘속`,0,0],
    [`가정`,``,``,`두성불안`,`기도위배`,`육친`,0,0]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사 애증사`,`빈궁**도적의혹근심사`,``,`가나 있으나 좋음`,``,`고위투서헌책면담사**재액해소**전화위복`,`시절을 놓쳤다면 퇴함이 되려 쌓는 것이 됨 `,`명예자산직녹권리**외교막힘**기이빈궁`,`문서헌책답신지체-구설사`,`관재**외내남녀동시 색정사有`,`내부가정동요분산사`,`화합사**득죄원인매매주의**재액해소`,`관직자 영전도약-일반인 반흉`,`전도**빈궁**외사송쟁 상해우려`,`음4,5월 화재주의`,`재물손실**3,6,9,12월 방문 부자**전도(顚倒)`,`사기득재사`,`빈궁**암매불통**재액해소`,`세속허사**음3,6,7,8,9,12월 방문- 종교진출하면성공함`,`빈궁**관재**병재`,`매사불성**종교진출하면 자립함`,`관재**事후쟁탈사`,`도적실세-포획-죄인됨`,`빈궁**매사위역**관재**재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`내외 급속사`,``,``,``,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,``,`身物손실사`,`내부가정반목대립소송사`,``,`내외사 출행이동사`,`좋은 소식기다림`,``,``,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,``,`매사위역**관재**전화위복`,`외사 애증사`,`빈궁**도적의혹근심사**재액해소`,``,`가나 있으나 좋음**재액해소`,``,`고위투서헌책면담사**전화위복`,`시절을 놓쳤다면 퇴하는 것이 되려 쌓는 것이 됨`,`명예자산직녹권리**외교막힘**기이빈궁**관재`,`문서헌책답신지체-구설사`,`빈궁**외내남녀동시 색정사有`,`내부가정동요분산사`,`화합사**득죄원인매매주의`,`관직자 영전도약-일반인 반흉`,`빈궁**외사송쟁 상해우려`,`음4,5월 화재주의`,`재물손실**기반없음**관재`,`사기득재사`,`빈궁**암매불통`,`매사불성**종교진출하면 자립함`,`관재**병재**전도`,`매사불성**종교진출하면 자립함`,`事후쟁탈사**관재**전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`내외 급속사`,``,``,``,``,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,`身물손실사`,`내부가정반목대립소송사`,``,`내외사 출행이동사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`매사불성**종교진출하면 자립함`,`事後쟁탈사**관재`,`매사불성**종교진출하면 자립함`,`빈궁**매사위역**전화위복`,`외사 애증사`,`빈궁**도적의혹근심사`,``,`가나 있으나 좋음`,``,`고위투서헌책면담사**재액해소**전화위복`,`시절을 놓쳤다면 뙤하는 것이 되려 쌓는 것이 됨`,`명예자산직녹권리**외교막힘**기이빈궁**전도`,`문서헌책답신지체-구설사`,`빈궁**외내남녀동시 색정사有`,`내부가정동요분산사`,`화합사**득재원인주의`,`관직자 영전도약-일반인 반흉`,`빈궁**관재**외사송쟁**상해우려`,`身물손실사`,`재물손실**음4,5월 화재주의`,`사기득재사`,`빈궁**암매불통`,`매사불성**종교진출하면 자립함`,`관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,`내외 급속사`,``,``,``,``,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,``,`내부가정반목대립소송사`,``,`내외사 출행이동사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`빈궁**병재`,`매사불성**종교진출하면 자립함`,`빈궁`,`매사불성**종교진출하면 자립함`,``,`화합사**득죄원인매매주의**전화위복`,`빈궁**전도`,`견련牽連반복사`,`비상지애사**전도`,``,`빈궁**전화위복`,`사기득재사`,`명예자산직녹권리**외교막힘**기이빈궁**병재`,`재액해소`,`재물유실**관재**재액해소`,`외내남녀동시 색정사有`,`빈궁**매사위역`,`도적소실사`,`빈궁**병,구설**관재**상해우려`,`음4,5월 화재주의`,`탄식신음**음4,5월 화재주의`,``,`고위연회관련사**침체불통**관재`,`순리순행길상도래`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내외사출행이동사`,`모두 재난뿐임`,``,`관재병재`,``,``,`외사 애증사`,`내외 급속사`,``,``,``,``,``,`좋은소식사-기다림`,``,` `,`내부가정동요분산사`,``,``,``,``,``,`내부가정반목대립소송사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`고위연회관련사**불통침체**재액해소`,`내부가정반목대립소송사`,`빈궁**병재`,`매사불성**종교진출하면 자립함`,`빈궁**관재`,`매사불성**종교진출하면 자립함`,`화합사**득죄원인매매주의**재액해소`,``,`빈궁`,`견련牽連반복사`,`비상지애사**관재`,``,`빈궁**재액해소**전화위복`,`사기득재사`,`명예자산직녹권리**외교막힘**관재**병재`,``,`재물유실**외내남녀동시 색정사有`,``,`빈궁**매사위역**재액해소`,`도적소실사`,`빈궁**외사급속송쟁 상해우려**전도`,`병,구설**음4,5월 화재주의`,`탄식신음**전도**음4,5월 화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`내외사출행이동사`,`모두 재난뿐임`,`관재병재`,``,`전화위복`,``,`외사 애증사`,`내외 급속사`,``,``,``,``,``,`좋은소식사-기다림`,``,``,`내부가정동요분산사`,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`탄식신음**음4,5월 화재주의`,``,`귀인연회관련  동요불안사**전화위복`,`내부가정반목대립소송사`,`빈궁**관재**병재**내외사출행이동사`,`매사불성**종교진출하면 자립함`,`빈궁**관재`,`매사불성**종교진출하면 자립함`,`화합사**전도**득죄원인매매주의**전화위복`,``,`빈궁**관재**내외 급속사`,`견련牽連반복사`,`비상지애사**관재`,``,`빈궁**전도`,`사기득재사`,`명예자산직녹권리**외교막힘**기이빈궁**병재`,`좋은소식사-기다림`,`재물유실**외내남녀동시 색정사有`,``,`매사위역**빈궁**전도`,`도적소실사`,`빈궁**병,구설**외사급속송쟁 상해우려`,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`모두 재난뿐임`,`동요불안분산사`,`관재병재`,`둥요불안분산사`,``,`동요불안분산사`,`외사 애증사`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`내부가정동요불안분산사`,``,`동요불안분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`빈궁**병,구설**외사급속송쟁 상해우려**전도`,`음4,5월 화재주의`,`탄식신음**전도**음4,5월 화재주의`,``,`귀인연회관련사**침체불통**재액해소`,`음4,5월 화재주의`,`빈궁**관재**병재`,`매사불성**종교진출하면 자립함`,`빈궁**관재**전화위복`,`매사불성**종교진출하면 자립함`,`화합사**득죄원인매매주의**재액해소`,``,`빈궁**관재`,`견련牽連반복사`,`비상지애사`,``,`빈궁**재액해소**전화위복`,`사기득재사`,`명예자산직녹권리**외교막힘**기이빈궁**병재`,`좋은소식사-기다림`,`재물유실**관재**외내남녀동시 색정사有`,``,`매사위역**빈궁**관재**재액해소`,`도적소실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,`내부가정반목대립소송사`,`내외사출행이동사`,`모두 재난뿐임`,``,`관재병재`,``,``,`외사 애증사`,`내외 급속사`,``,``,``,``,``,``,``,``,`내부가정동요분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사위역**빈궁**관재**전도`,`도적소실사`,`빈궁**외사급속송쟁 상해우려**전화위복`,`병,구설**음4,5월 화재주의`,`탄식신음**음4,5월 화재주의`,``,`귀인연회관련사**침체불통**전도(顚倒)`,`내부가정반목대립소송사`,`빈궁**관재**병재`,`매사불성**종교자립하면 자립함`,`빈궁**관재`,`매사불성**종교진출하면 자립함`,`화합사**득죄원인매매주의**전도**전화위복`,``,`빈궁`,`견련牽連반복사`,`비상지애사`,``,`빈궁**전도`,`사기득재사`,`빈궁**관재**병재**재액해소`,`명예자산직녹권리**외교막힘`,`재물유실**외내남녀동시 색정사有**재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내부가정동요분산사`,``,``,``,``,``,``,``,`내외사출행이동사`,`모두재난뿐임`,``,`관재병재`,``,``,`외사 애증사`,`내외 급속사`,``,``,``,``,`좋은소식사-기다림`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재물유실**외내남녀동시 색정사有**전도`,``,`매사위역**빈궁`,`도적소실사`,`빈궁**외사급속송쟁 상해우려**`,`병,구설**음4,5월 화재주의`,`탄식신음**음4,5월 화재주의`,``,`귀인연회관련사**침체불통**음4,5월 화재주의`,`내부가정반목대립소송사`,`빈궁**관재**병재`,`매사불성**종교진출하면자립함`,`빈궁**관재`,`매사불성*종교진출하면 자립함`,`화합사**득죄원인매메주의**전화위복`,``,`빈궁`,`견련牽連반복사`,`비상지애사`,``,`빈궁**전화위복`,`사기득재사`,`빈궁**병재**전도`,`명예자산직녹권리**외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`내부가정동요분산사`,``,``,``,``,``,``,``,`내외사출행이동사`,`모두재난뿐임`,``,`관재병재`,``,``,`외사 애증사`,`내외 급속사`,``,``,``,``,`좋은소식사-기다림`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`시절을 놓쳤다면 퇴하는 것이 되려 쌓는 것이 됨`,`명예자산직녹권리**외교막힘**기이빈궁**재액해소`,`문서헌책답신지체-구설사`,`관재**재액해소**외내남녀동시 색정사有`,`내부가정동요분산사`,`화합사**득죄원인매매주의`,`관직자 영전도약-일반인 반흉`,`빈궁**외사급속송쟁 상해우려`,``,`재물유실**음4,5월 화재주의`,`사기득재사`,`암매불통**빈궁`,`매사불성**종교진출하면 자립함`,`빈궁**관재**병재`,`매사불성**종교진출하면 자립함`,`事후쟁탈사**관재`,`도적실세-잡음-죄인됨`,`매사위역**빈궁**전화위복`,`음사폐호-가택안녕`,`빈궁**도적의혹근심사**전도`,``,`가나 있으나 좋음**단, 방심은 전도`,`순리순행길상도래`,`고위투서헌책면담사**관재**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,``,`내부가정반목대립소송사`,``,`내외사 출행이동사`,`좋은소식사`,``,``,``,``,`외사 애증사`,`내외 급속사`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`고위투서헌책면담사**관재**전화위복`,`시절을 놓쳤다면 퇴하는 것이 되려 쌓는 것이 됨`,`명예자산직녹권리**외교막힘**기이빈궁**관재`,`문서헌책답신지체-구설사`,`재액해소**외내남녀동시 색정사有`,`내부가정동요분산사`,`빈궁**화합사**득죄원인매매주의`,`음4,5월 화재주의`,`빈궁**외사급속송쟁 상해우려`,`음4,5월 화재주의`,`재물손실`,`사기득재사`,`빈궁**병재`,`매사불성**종교진출하면 자립함`,`관재`,`세속허사**음3,6,7,8,9,12월 방문-종교진출하면성공함`,`事후쟁탈사**빈궁`,`도적실세-잡음-죄인됨`,`매사위역**빈궁**전도**전화위복`,`음사폐호-가택안녕`,`빈궁**도적의혹근심사**전도**폐한人`,``,`가나 있으나 좋음**단, 방심은 전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`재액해소`,``,``,``,``,``,`관직자 영전도약-일반인 반흉`,``,``,`내부가정반목대립소송사`,``,`내외사 출행이동사`,`좋은소식사`,``,``,``,``,`외사 애증사`,`내외 급속사`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`안방을 지켜 가나 있으나 좋음`,``,`고위투서헌책면담사**전도**전화위복`,`시절을 놓쳤다면 퇴하는 것이 되려 쌓는 것이 됨`,`명예자산직녹권리**외교막힘**기이빈궁`,`문서헌책답신지체-구설사`,`빈궁**외내남녀동시 색정사有`,`내부가정동요분산사`,`화합사**득죄원인매매주의**전도`,`음4,5월 화재주의`,`빈궁**외사급속송쟁 상해우려`,`음4,5월 화재주의`,`재물손실`,`사기득재사`,`암매불통**빈궁**전도`,`세속허사**음3,6,7,8,9,12월 방문-종교진출하면성공함`,`빈궁**병재`,`세속허사**음3,6,7,8,9,12월 방문- 종교진출하면성공함`,`빈궁**事후쟁탈사**관재`,`도적실세-잡음-죄인됨`,`매사위역**빈궁**전도**전화위복`,`음사폐호-가택안녕`,`빈궁**도적의혹근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,`관직자 영전도약-일반인 반흉`,``,``,`내부가정반목대립소송사`,``,`내외사 출행이동사`,`좋은소식사`,``,``,``,``,`외사 애증사`,`내외 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 내몸은 도망가고 싶은 관재요, 집은 여자와 재물을 도적맞으니 새로 시작할 때가 아니라 지킬때인데, 이게 다 ⊕여귀, 
⊖전흉사귀에다 신앙기도가     잘못돼서 일어나는 일이야!
⊖신변음사부정이 들었어!
`,`■ 금전 여자 두가지 일로 기로에 섰는데, 집이 기울어가는 이유를 알아야지! 질투 의심보다 이사부터 하던가, 
⊕음귀, 
⊖신불제사귀를   물리치는 것이 급선무야!
`,`■ 첩첩산중에서 물구덩이를 만난격일세? 급히 방침을 변경해야만 하겠고, 
⊕여귀, 
⊖가택상가부정ㆍ전흉사귀도 물리쳐야만 재기할 수 있겠어!

⊕신변상가부정이 들었어!
`,`■ ⊕여귀, 
   ⊖전흉사귀가 발동해서 관재와 병을 일으키네? 
이것만 해소시키면 부자가 되겠어! 
심중에 놀라는 것은 호가호위일 뿐이고!
`,`■타인의 부인을 유혹했어도 막상 편히 발 뻗을데가 없겠어! 
여건이 부족해서 남의 말에 따라야할 운세야! 
빛 좋은 개살구격이야! 
기도ㆍ치성만이 살길이네!
⊖가택상가부정이 들었어.
⊕⊖신변가당신사부정이 들      었네 ?
`,`■ 이것이냐? 저것이냐? 기로에 섰네? 
중상모략으로 옛 일과 인연이고 모두 끝났어!
⊕변사귀, 
⊖전사귀가 내 몸을 때리니    이것만 없애면 풀려!
`,`■색정사로 배반하고 후회하면서 새일을 꾸민다고 일이 되겠어?
⊕도로귀, 
⊖가택귀를 먼저 잡아야지!
가택에는 ⊖두성불안 부정까지 들었고!

⊕신변음사부정이 들었어!
`,`■ 하극상을 받고 있네? 금전 여자 두가지 일로 질투의심되고기로에 섰어! 
가정에도 자칫 상사가 나게 생겼네? 
아니면 돈이 크게 부서지게 생겼고!
`,`■ 일은 안되고, 집도 뺏기고, 남한테 피해도 끼치게 되고, 어쩔수없이 남한테 기대다가 겨울ㆍ봄에나 나서봐야겠어!
女:남의 남자를 유혹해서      색정사를 벌이고 있겠는     데따라나서게 생겼구만!

⊖신변상가부정이 들었어!
`,`■ 새로 일을 시작하더라도 ⊕도로귀, 
⊖가택귀를 달래놓고 해야지! 노력해 모은 재산이 날아가게 될걸 ? 
집에 돈과 여자가 나갈 조짐이고 喪事가 나겠어 !
`,`■ 돈 때문에 물구덩이에 빠졌어! 도망치고 싶겠는데 기도ㆍ치성이제일이야! ⊕여귀, 
⊖전흉사귀도 잡아주고!
男:⊕남의 여자를 내것처럼     쓰고 있네!
⊕⊖신변가당신사부정이          들었어 !
`,`■가정에 금전 여자로 의심․질투가 크고, 선택의 기로에 섰는데 먼 것을 버리고 친한자를 택해 계속가면 일이 되겠어! 가정과 첩 둘다 보배야!`];
const YOGYEOL1 = [`■ 역마가 범과 귀신을 태워오니 낮정단은 매우 두려우나 다행히 비어서 당하지는 않는다. 밤정단은 재물의 손실이 있을 것이다.
`,`■ 좋은 것이 너무 많아 일반인은 감당하기가 어렵다. 군자는 매우 좋아서 등과급제하리라.
`,`■ 육소가 모두 양이니 일반인은 재앙이 흩어지리라. 역마가 범과 귀신을 실었으니 사람을 재촉하거나 쫓지 말라.
`,`■ 화살이 날아와 상처를 입히나 다행히 공망이다. 집에서 입다물고 있으면 생계는 발달하리라.
`,`■ 안과 밖에서 다툼이 이어지니 집은 좋아도 사람은 안풀린다. 잃은 재물이 후에 오는데 종업원이 간계를 부리고 도망가겠다.
`,`■ 초전과 말전에 귀인이 감싸니 윗사람의 생각함이 각별하다. 빈 귀신이 헛되게 놀라게 하고 상대는 손해나게 할것이다.
`,`■ 밤정단은 녹을 범이 물어가고 낮정단은 용이 귀신이다. 자세히 보면 좋기도 하고 나쁘기도  하나 오고 가는 것이 모두 비었다.
`,`■ 밤정단은 귀인이 묘에 타서 재판관의 분노를 만난다. 다행히 잘 풀리고 당하는 오해가 사라진다.
`,`■ 돈 벌기가 힘드니 집은 넓고 사람은 안된다. 처가살이 하는 것은 좋겠고 손모 도적이 여러번이다.
`,`■ 돕는것과 해치는 것이 모두 비어있다. 밤정단은 범이 내 녹을 물어간다. 집안에 정마가 뛰니 이사 변동이 있다.
`,`■ 낮정단은 범이 비었으니 위태함은 걱정할 것이 없다. 돈 여자가 숨어 노리니 申띠는 온전히 상함을 입으리라.
`,`■ 낮정단은 손실이 많으니 가정이나 첩에게 의지하라. 말전이 초전을 도우니 양가를 왕래하면 좋다.`]
const SIBO1 = [`■ 두성불안부정이 침범함
凶을 凶으로 제어한다.
도주관사ㆍ거지곤란 관재有
옛것을 지키고 움직이지 말라. 움직이면 재화가 기한다. 도적을 조심하라. 상대와 의견일치가 안된다. 강폭자만에 의해 일을 그르치고 신음한다.
가정 부정해소가 요망되고, ⊖은 효과가 있다.
권리상실사가 기한다.
`,`■ 가정에 우환이 있다. 
가족 중 가장을 속인다.
원한을 품고 加害하려는
兆가 있다.
폐지사를 부흥시키려한다.
모든 활동을 중지하고 때를 기다리라. 女人의 별리가 있고 재복이 있게 된다. 가정의 모망사가 흩어지고 허비가 많다.
권리상실사가 기한다.
`,`■ 가내 우환이 기한다.
가인 중 가장을 속인다.
가인이 원한을 품고 加害
하려 한다. 
모든 일을 公적으로 진행해야 길하다. 공직 취임이 吉하고 현직자는 면직ㆍ퇴직하게 된다. 위험중에 財를 득한다. 財ㆍ妻에 의해 소송사의 우려를 경계하라. 금전 수수에 있어 명확하지 않으면 災가 된다.권리상실이 있다.
`,`■ 이익사가 있고 소득한다.
금전사 가해의 兆가 있다.
辰戌丑未 月정단은
⊕ 부자이다. 가정연희가 있다.
언행만 조심하면 해가 없다. 집안에 환자가 있다. 부부가 불화하고 다같이 外情을 통하고 있다. 주종간에 배반한다. 內外화기가 없다.
모든 일은 처음에는 안되고 공망해공 후에 吉하다.
`,`■ 폐지사를 부흥시키려는
兆가 있다. 女는, 또는
상대가 헌신하고 財를
만든다.
상대가 자래하여 금전의 이익을 준다. 他人이 의존해온다. 간사건이 있고 男이 他人의 부인을 유혹하여 거래한다. 상대는 자기의 뜻을 굽히고 따르게 된다.
다인 다사건이다.
`,`■ 자충수적 우환이 있다.
정치인ㆍ관리는 탄핵을
받게 된다. ⊕신불의 
앙화가 있다. 치성하라.
인ㆍ택 內外가 손실이다. 상대로부터 득이 있으나 나중에 변상해야 될 이익이다. 교역ㆍ타협사는 불성한다. 반면 놀람도 공허한 것에 불과하다. 和氣ㆍ협력의사가 없다.
권리를 상실한다.
`,`■ 자충수적 우환이 있다.
⊕⊖두성불안부정이 들었다.
관재가 있다. 사물이 양
갈래이다.
가택동요불안사가 있다.
사물이 반복하여 불안하다.
낡은 일과 사건이 성사된다. 구재사도 송사 문제로 비화된다. 모든 것을 보류하라. 신심이 동요하고 있다.
권리를 상실한다.
`,`■ ⊕上人과 財의 경위를
따진다.
가택이 손실된다.
戌月은 가내외 喪事가 있다.
곤란하고 복잡한 일이 두가지로 야기되었다. 은혜를 구해로 갚는 일이 드러났다. 재해가 당도 하였다. 선악이 같이 발생한다. 금전 여자로 의심 질투가 있다 .
一事를 택하라. 근친자가 좋다. 정당한 일이 곡해된다.
`,`■ 가택권리가 넘어간다.
⊕상가신살부정이 들었다
가택이 손실된다.
男ㆍ夫가 女ㆍ妻에 헌신한다. 사람은 쇠퇴하고 가택은 넓다. 계획사는 성공한다. 他人에게 굴복당한다. 私情통교건이 있다. 女가 男을 유혹하여 적극적이다. 권리ㆍ권한이 상실된다.
기도ㆍ치성 효과가 있다.
다인 다사건이다.
`,`■ 가택송사가 있다.
季月은 ⊖부자이다.
女子에게 의존하는 것이
吉이 된다.
녹을 지키기 힘들다. 
고생후에 되는 일이 있다.
허사를 방지하라. 사용인을 배우자로 맞게 된다. 動意가 있으나 吉凶이 공허하다. 
모든 일은 초에는 안된다. 謀散하고 허비가 많다.
집안에 죽을 사람이 있다.
`,`■ 관사가 있다. 피신이 
좋다. 기도ㆍ치성이 
통한다. 
男이⊕ 타인의 부인을 유혹하여 색정 거래를 한다. 財의 소득에 조심하라. 관재가 된다. 寅ㆍ亥ㆍ申ㆍ巳띠는 季月에 좋다.
기도의 효과가 있게 된다. 
치성하라.
특히 戌ㆍ酉띠는 吉하다. 
⊕상가부정이 들었다.
권리를 상실한다.
`,`■ 가정이익사가 있고
소득한다.
만사 적극적 추진이 吉하다.
만사가 양단이다.
一事로 귀결하면 吉하다. 부부간 불화한다. 나중에 화합한다.
길사는 길이 겹치고 흉사는 흉이 겹친다.
女는 조행이 불량하다.`]
const SIBO2_1 = [`■ 직녹, 신변외사이다.
내방조짐 상의 : 
은둔ㆍ도망ㆍ원행에 이롭다.은혜를 원수로 갚는 소송사이다.
`,`■ 부모․사업 후원사가 있다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사불리하다.
`,`■ 자손ㆍ직위 가택내사이다.
내방조짐 상의 : 
움직임이 근본이고 동기가 발동한다.
순역이 혼란하다.
`,`■ 직탈, 부부이심동요가 있다.
내방조짐 상의 :  
원행·이동사가 있다.어떤 일이든 처음 불성한다. 직위·권리상실하고 남편망조한다
`,`■ 금전ㆍ여, 가택내사이다.
내방조짐 상의 : 
모든 일이 겹쳐 일어난다.
男직위상실 女부부이별함
`,`■ 사업 불안ㆍ기로에 있다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사불리하다.
`,`■ 직녹, 신변외사이다.
내방조짐 상의 : 
은둔ㆍ도망ㆍ원행에 이롭다.
색정불화가 있다.
`,`■ 후원, 가정무례사가 있다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사불리하다.
`,`■ 직녹, 신변외사이다.
내방조짐 상의 : 
은둔ㆍ도망ㆍ원행에이롭다.
男직위상실 女부부이별함
`,`■ 직탈ㆍ별리 허모사가 있다.
내방조짐 상의 :  
원행·이동사가있다.어떤 일이든 처음 불성한다.직위 권리상실하고 남편망조한다
`,`■ 금전ㆍ여, 가택동요가 있다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단을 잡는다.
`,`■ 금전ㆍ여, 가택동요가 있다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단을 잡는다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [


  `/static/images/sagwa/011/011_001.png`,
  `/static/images/sagwa/011/011_002.png`,
  `/static/images/sagwa/011/011_003.png`,
  `/static/images/sagwa/011/011_004.png`,
  `/static/images/sagwa/011/011_005.png`,
  `/static/images/sagwa/011/011_006.png`,
  `/static/images/sagwa/011/011_007.png`,
  `/static/images/sagwa/011/011_008.png`,
  `/static/images/sagwa/011/011_009.png`,
  `/static/images/sagwa/011/011_010.png`,
  `/static/images/sagwa/011/011_011.png`,
  `/static/images/sagwa/011/011_012.png`,

];

const HEASYEOL = [
  // 1
  `  이 과는 모든 신이 본궁에 귀복(歸伏)한다. 지반 인(寅)은 인(寅)에 돌아와 4과의 상하에 극이 없다. 요극(遙剋)을 쓰지 않으므로 양일(陽日)이 되면 일간상신의 인(寅)을 초전으로 한다. 중전․말전은 지형(支刑)하여 중전으로 하고, 사(巳)는 신(申)을 형(刑)하여 말전으로 한다.
  삼전 사맹신(四孟神)은 현태격(玄胎格)이다. 양일(陽日) 복음(伏吟)은 자임격(自任格)이다. 양이 강(剛)으로 되면 스스로 강하게 되므로 이같이 명명한 것이다. 복음은 복(伏)하여 동하지 않는 과(課)로서 옛것을 지키고 동하지 말라. 만약 동할 때는 재화(災禍)를 초래한다. 말전의 신(申)은 금일의 역마이며 일간의 귀(鬼)이다. 백호가 승하여 일간을 극상(剋傷)하여도 다행히 공망하여 두려운 일이 없다. 그러나 일지상신 술(戌)은 일간의 재신(財神)으로 현무가 승하여 도적에게 박탈당할 우려가 있다. 초전 인(寅)의 건록이 일덕(日德)으로 되고 말전 신(申)의 역마는 공망한다. 그러나 년명상신(年命上神)에 신(申)이 승하여 전실공망(塡實空亡)하는 경우는 공망으로 공(空)을 논하지 않는다. 현임 관리는 길조를 이룬다.
  백법에 말하기를 ｢왕록(旺祿)이 간상에 임하는 것은 함부로 망동하지 말아야 한다. 주객(主客)이 투합하지 않는 것은 형(刑)이 상에 있다｣고 하였다. 일귀는 역행하여 삼전이 청(靑)․주(朱)․후(后)이고 야귀는 순행하여 삼전이 사(蛇)․구(勾)․백(白)이다. 어느 것이나 모두 흉장(凶將)이다.
  이 과는 공명(功名)의 정단은 길하고 구재는 소득한다. 병점은 숨은 증상이 있다. 삼형(三刑)이 되면 소송 사건에서 불길하다. 도망․가출․도적․실물 등 어느 것이나 가까운 곳에 있다. 귀신(貴神)의 순행(順行)은 일지의 전 1위를 찾고, 귀신(貴神)이 역행(逆行)은 일지의 후 1위 되는 곳을 찾아야 한다.`
  ,
  // 2
  `  이 과는 제1과․제2과의 이하가 이상을 극한다. 양일로 되면 양비(陽比)라고 부르며, 제2과 일간의 음신(陰神)의 자축(子丑)을 취하여 초전으로 하는 지일과(知一課)이다. 삼전 자해술(子亥戌)은 퇴여격(退茹格)이고 야반(夜半)과 일몰(日沒) 후 정단이면 중음(重陰)이라고 부른다. 어떤 일이나 조용히 때를 기다려 활동을 중지하는 것이 안전하다.
  지일과는 먼 것을 버리고 가까운 것을 따르고, 소원(疏遠)을 버리고 친한 것을 따라야 한다. 은혜를 생각하여 해(害)를 초래하는 것은 동료나 친척에게 발생하고, 어떤 일이나 반성하고 화합을 주의(主意)로 하면 길조를 얻는다. 해자축(亥子丑)을 둔간하여 을병정(乙丙丁)의 천상(天上) 삼기(三奇)가 있으므로 군자는 사관(仕官)이고 현임 고관(高官)인 사람은 대단한 기쁨이 있다. 입시문제는 합격한다. 일반 사람은 재(財)의 덕을 얻는다.
  백법에 말하기를 ｢제3과․제4과에서 극하는 것을 귀(鬼)는 제3과․제4과에 임한다고 하여 관재(官災) 또는 화난(禍難)이 몸에 발생한다. 일귀는 역행하여 삼전이 백(白)․상(常)․현(玄)이고, 야귀는 순행하여 삼전이 후(后)․음(陰)․현(玄)으로서 실탈(失脫)의 해를 초래한다. 일간상신에 귀신(貴神)이 일진의 막귀(幕貴)에 승하므로 시험과 선거는 모두 당선되고 합격된다.`
  ,
  // 3
  `  이 과는 제2과, 제4과에 극이 있다. 지반 맹신의 상을 채용하여 섭해견기과(涉害見機課)가 된다. 섭(涉)이라는 것은 건넌다는 뜻이고 해(害)라는 것은 극으로서 지반(地盤)에 돌아와 건너 극이 깊기 때문에 기회를 보고 안연(安然)한 책전(策戰)을 취한다는 명사(名詞)이다.
  초전의 오(午)는 청(靑)․사(蛇)로서 공망의 지(地)에 있어 길흉을 이루지 못하고 기쁨도 또한 없다. 삼전이 모두 양지(陽支)뿐이면 공용(公用)에 좋다. 일지상신 귀효(鬼爻)에 백호가 병합되면 최관부(催官符)라고 하여 음신 오(午)에서 귀(鬼)를 제어한다. 관직에 있는 사람은 길하고, 상인(常人)은 흉신(凶神)이 공망하고 또 구신(救神)이 있어 해(害)가 없다. 그러나 백호가 택상(宅上)에 임하고, 술년(戌年)은 상조(喪弔)에 만나기 때문에 상사상복(喪事喪服)의 일이 있다.
  백법에 말하기를 ｢육양수(六陽數)가 완전한 것은 공용(公用)에 좋다. 최관사자(催官使者)는 관(官)에 취임한다｣고 하였다. 비전에 말하기를 ｢갑술일(甲戌日)은 일간상에 자(子)가 있고 지상에 신(申)이 있어 위중취재격(危中取財格)이라고 부른다｣고 하였다. 일간은 지(支)를 극하여 재(財)로 되고 지상(支上)은 귀살(鬼殺)을 보기 때문에 경위(驚危) 중에 재(財)를 얻게 된다. 다행히 신(申)이 공망하므로 두려운 일은 없다.
  삼전 오진인(午辰寅)은 고조격(顧祖格)이라고 한다. 오화(午火)는 인(寅)에 장생(長生)으로 된다. 즉 모(母)를 돌보는 뜻으로 되어 편안을 얻기 때문에 희기평화(喜氣平和)의 격이다. 일귀는 역행하여 삼전이 사(蛇)․합(合)․청(靑)이다. 사(蛇)는 청(靑)으로 변화하고 간난한 일이 있어도 후에 공명의 목적을 달성한다. 야귀는 순행하여 삼전이 청(靑)․합(合)․사(蛇)이다. 청(靑)은 변화하여 사(蛇)가 되므로 공명은 완전한 것을 얻지 못하고 실직이나 퇴직을 당하고 관리는 면직(免職)당한다.`
  ,
  // 4
  `  이 과는 4과 중에 상하의 극이 없고 제2과 신(申)에서 일간을 극하여 요극호시과(遙剋蒿矢課)로 된다. 호시라는 것은 활로 쏜다는 뜻이다. 그러나 삼전 중에 금(金) 또는 토(土)가 있는 것을 두려워한다.
  초전의 신(申)은 금철(金鐵)의 시(矢)이지만 다행히 공망하여 힘이 없기 때문에 해(害)가 없다. 순말(旬末)의 미(未)는 택상에 가하는 폐구(閉口)라고 한다. 폐구는 언어를 조심하면 화환(禍患)이 없다. 일간상의 해(亥)는 장생이며, 그 위에 음신에 의해 생부(生扶)하여 생활 상태는 풍부하다. 일귀(日鬼)가 발용으로 되어 역마를 병합하기 때문에 사관 또는 구직은 신속하지만 초․중전이 공망하므로 공망을 탈(脫)하여야 가능하다. 초전 백호가 신(申)에 승하여 해(亥)에 가해지면 호분등명(虎奔登明)이라고 하여 집안에 반드시 환자가 있다. 일간지 상하 및 교차(交車) 어느 것이나 상극한다.
  백법에 말하기를 ｢부부무음(夫婦蕪淫)은 각각 사정(私情)을 품고, 과전(課傳)에 지형(支刑)하는 것은 주객(主客)이 투합(投合)하지 않는다｣고 하였다. 비전에 말하기를 ｢사과삼전이 모두 형충극해(刑冲剋害)로 되면 어떤 일도 화기(和氣)가 없다. 그러므로 자식은 부모를 역(逆)하고 주종(主從)은 반역(反逆)한다｣고 하였다. 
  일귀는 역행하여 삼전이 후(后)․주(朱)․청(靑)의 길장이고, 야귀는 순행하여 삼전이 백(白)․구(勾)․사(蛇)로서 어느 것이나 흉장(凶將)이며, 질병과 소송사건을 정단하면 가장 흉조로 된다.`
  ,
  // 5
  `  이 과는 4과 중에 제1과 술갑(戌甲)이 하에서 상을 극하여 중심과(重審課)로 된다. 4과이지만 3과밖에 없는 불비격(不備格)이다. 사과삼전이 모두 인오술(寅午戌)의 화국(火局)이므로 염상격(炎上格)이라고 한다.
  일간에서 목생화(木生火)로 탈기(脫氣)한다. 일지는 일간상에 와서 재(財)가 되는 췌혼격(贅婚格)이고, 내몸은 처재(妻財)를 얻는다. 일지의 술(戌)은 초전으로 되고 말전의 인(寅)은 일간기궁(日干寄宮)에 돌아온다. 지반 인(寅)의 초전은 일간기궁이며 말전의 지반 오(午)는 일지상신에 돌아와서 내외 연속의 상이다. 삼전의 화기(火氣)는 일지를 생하고 또 일간은 일지상신에 설(泄)하기 때문에 사람에 의뢰하여 택(宅)을 생하므로 택이 왕성하게 된다. 즉, 타인에 의존하여 길행(吉幸)을 초래한다. 술(戌)은 사용인의 류신으로 삼합화국(三合火局)하면 합(合)하여 간사(姦私)를 품는다. 인(寅)은 귀호(鬼戶)로서 삼자(三者)가 상병(相倂)하면 사용인이 간사도주(奸邪逃走)의 징조가 있다. 일지에 와서 일간에 승하여 처재(妻財)로 되면 처가 남편(夫)에게 따른다. 정단사는 내몸의 뜻을 굽히고 남에게 따르는 일이다.
  백법에 말하기를 ｢초전에 육합이 승하여 협극(夾剋)으로 되면 자기의 자유를 얻지 못하는 재(財)이다｣고 하였다. 비전에 말하기를 ｢월장이 해(亥)로서 묘각(卯刻)에 승할 때는 도주의 건으로서 일지는 술(戌)로 되고 술(戌)은 미(未)를 형(刑)한다. 미(未)는 해(亥)에 임하기 때문에 서북 해(亥)의 방위를 찾으면 잡을 수 있다｣고 하였다. 순주(旬主)의 술(戌)을 초전으로 하는 것은 육의과(六儀課)에 해당한다. 일귀는 순행하여 삼전이 합(合)․후(后)․백(白)이고, 야귀는 역행하여 삼전이 합(合)․백(白)․후(后)이다. 초전이 육합이고 말전의 천후(天后)는 교동격(狡童格)으로 된다. 이 격은 남자가 여자를 유혹한다. 갑목(甲木)이 염상(炎上)하여 탈기하고 어떤 일을 구하여도 정신기력 또는 파재(破財)하거나 물체를 손상한다. 전체적으로 내 쪽에 손상과 소모가 많다.`
  ,
  // 6
  `  이 과는 2상이 2하를 극한다. 양일(陽日)이면 양비(陽比)에 해당하고 자사(子巳)를 초전으로 하여 지일비용과(知一比用課)로 된다. 일간 상신 유(酉) 귀효(鬼爻)로 되어도 순공(旬空)하면 오직 공허(空虛)한 놀라움뿐이다. 일간은 일지상신에 탈기되며 일지 술(戌)의 토(土)가 일간상신 유(酉)에 탈기하면 간지(干支)가 교차탈기(交車脫氣)로 되고 인택이 함께 양실(兩失)된다.
  지일과는 일이 양도(兩途)에 있어 그 중에 하나를 선택해야 한다. 4과가 초전으로 되는 것은 맥월격(驀越格)으로 되며 정단사는 갑자기 정지한다. 또 남에게 위탁하는 것도 부실(不實)하다. 교역사(交易事)는 성립되기 어렵다. 교차탈기는 나는 상대방에 의해 얻는 것이 있지만, 상대방은 나에게 구상(求償)하거나 서로 손실되는 상이다.
  백법에 말하기를 ｢전후 인종(引從)하여 승진의 기쁨이 있고 인택이 함께 탈기되는 것은 도난의 우려가 있다｣고 하였다. 비전에 말하기를 ｢말전의 인(寅)이 미(未)에 가하고 백호가 승하면 신불(神佛)의 앙화(殃禍)가 있고, 또 천공이 미(未)에 승하면 괴이한 일이 있으며, 말전은 중전을 극하고 중전은 초전을 극하며 초전은 일지상의 사(巳)를 극한다｣고 하였다. 즉 체극(遞剋)하여 어떤 일도 화기협력(和氣協力)의 뜻이 없다.
  일귀는 순행하여 삼전이 청(靑)․귀(貴)․백(白)이고, 야귀는 역행하여 삼전이 사(蛇)․공(空)․후(后)이다. 공명․구직은 일귀는 목적을 달성하고 야귀는 달성하기 어렵다.`
  ,
  // 7
  `  이 과는 천지(天地) 충위(冲位)에 있고 제1과, 제2과에 상하의 극이 있다. 하에서 상을 극하는 인신(寅申)을 초전으로 한다. 삼전이 모두 공망하고 길흉의 천장(天將)이 어느 것이나 공망으로 되면 오는 자도 가는 자도 모두 공망으로 되는데, 호악(好惡)이 모두 뜬구름 같고 혹은 물속의 달과 같아서 길흉이 함께 이루어지지 못한다.
  원래 반음과(返吟課)는 사물이 반복하여 불안한 상으로서 동정(動靜)을 함께 지키는 일은 어렵다. 이 과는 삼전이 모두 공망으로 되기 때문에 길흉이 모두 형성되기 어려우며, 강고(强固)하면 약하게 된다. 진퇴(進退)가 서로 역(逆)으로 행동하기 때문에 어떤 일도 보류하고 지키는 것만이 현명(賢明)한 일이다. 일지상신은 일간의 재(財)로 되고 일간상신의 귀살(鬼殺)을 생한다. 원칙(原則)에 말하기를 ｢일간상신이 귀(鬼)를 보고 재성(財星)이 있을 때는 구재(求財)는 불길하다. 살(殺)이 왕성하여 내몸인 일간을 공격하기 때문이다｣고 하였다.
  반음과는 낡은 사건은 성취된다. 오랫동안 경과한 것은 다시 발생한다. 백법에 말하기를 ｢반음과는 왕래거래(往來去來)의 과명(課名)이다. 공망하는 것은 왕래 이동의 징조가 있어도 현실을 보지 말고 추구(追求)하지 말아야 한다｣고 하였다. 비전에 말하기를 ｢인신(寅申)에 천후(天后)가 승하면 먼곳으로부터 통신 사건이 발생한다. 반음과는 어떤 일도 왕복의 상태가 되거나 두 가지 사건으로 신심(身心)이 함께 동요한다. 만약 상대방에 호소하여 동하지 않으면 원한을 품게 된다｣고 하였다.
  일귀는 순행하여 삼전이 백(白)․사(蛇)․백(白)이고, 야귀는 역행하여 삼전이 후(后)․청(靑)․후(后)의 길장으로 되면 일귀는 이긴다. 그러나 길흉이 함께 공망하므로 만사는 성립하지 못한다. 질병과 소송사는 가장 싫어한다. 일지상신 재(財)에 현무가 승하기 때문에 실탈․도난은 어느 것이나 반복하여 발생하므로 충분한 예방과 경계를 해야 한다.`
  ,
  // 8
  `  이 과는 4과 모두 상하의 상극이 있다. 양일(陽日)이므로 하에서 극받는 양수극(陽受剋)을 채용하여 자미(子未)를 초전으로 하는 지일과(知一課)로 된다. 지일과는 비린(比隣)이라고도 부르며 선악(善惡)이 나란히 발생한다. 사물은 양기(兩岐)에 걸쳐서 모두 일사(一事)를 집행하는 것이 좋다.
  이 과는 재해가 밖으로부터 온다. 또한 사과(四課)의 극이 많기 때문에 곤란하고 복잡한 징조가 있어 의혹이 겹치고, 은(恩)이 구(仇)로 되는 상(象)이다. 일간상신 십이운의 묘신(墓神)은 일상(日上)에 복(覆)한다. 초전 자수(子水)가 육해로 되고 관사(官事)․소송사는 관인(官人)의 노여움을 만난다. 그러나 말전의 술(戌)이 자(子)의 초전을 극하여 노여움을 해소한다.
  백법에 말하기를 ｢귀신(貴神)이 육해로 되면 귀신상해(貴神相害)라고 부르며 정당한 사건도 곡해(曲解)를 받기 쉽다. 초전의 지신(地神) 미(未)가 순미(旬眉)로 되면 재신(財神)은 폐구격(閉口格)으로 되고 사물이 양기(兩岐)로 분리된다｣고 하였다. 비전에 말하기를 ｢간상폐구(干上閉口)가 재신(財神)을 승하면 병란을 싫어한다. 술월(戌月)은 일지상신이 사기(死氣 : 木)를 승하고 태상은 택상(宅上)에 들어가기 때문에 가정 내외에 상사(喪事)가 발생할 징조가 있다｣고 하였다.
  일귀는 순행하여 삼전이 청(靑)․음(陰)․합(合)이며 길장(吉將)이다. 태상(太常)이 택상(宅上)에 임하여 처재(妻財)로 되면 처의 병단은 좋지 않다. 야귀는 역행하여 삼전이 사(蛇)․상(常)․합(合)이며 맥귀(驀貴)는 일묘(日墓)를 이루므로 시험과 선거는 모두 불리하다. 관사(官事)에 걸리면 형죄를 받아야 한다.`
  ,
  // 9
  `  이 과는 4과 중 제3과에서 일상이 일하를 극한다. 인술(寅戌)을 초전으로 하여 원수과(元首課)로 된다. 삼합화국(三合火局)은 염상격(炎上格)이고 인오술(寅午戌)은 생왕묘(生旺墓)로 되며 순합(順合)한다. 4과가 불비(不備)이지만 사과와 동일한 삼합은 불비더라도 중하게 쓰지 않는다.
  귀신(貴神)이 묘유(卯酉)에 임하면 여덕격(勵德格)이다. 선천후(天后)에 후육합(六合)이 승하면 일녀격(泆女格)으로 된다. 말전 술(戌)은 일간의 재(財)로 되고, 육합이 승하여 협극(夾剋)의 재(財)로 되어 내게 쓰이는 재가 되지 못한다. 
  일간은 삼전으로 설탈(泄脫) 되고 삼전의 화국(火局)은 일지의 택(宅)을 생하여 사람은 쇠퇴하고 가택이 넓다. 일간상신을 부(夫)로 하고 지상신을 처(妻)로 하고 목생화(木生火), 화생토(火生土)로 부부가 융화(融和)한다. 일간기궁(日干寄宮)은 일지상에 가하여 췌혼격(贅婚格)으로 된다. 사과삼전이 모두 삼합화국이면 일기상성(一氣相成)이라고 부른다. 내몸은 중인(衆人)과 함께 같은 기(氣)를 서로 구하여 계획하는 일은 성공한다. 즉 대중회맹(大衆會盟)의 상(象)이다. 협극의 재(財)는 재(財)를 얻기까지 심로(心勞)가 많다.
  백법에 말하기를 ｢일지 상신이 건록으로 되면 권섭부정(權攝不正))이라고 한다｣고 하였다. 권섭부정격을 비전 백법 제8법의 해설에 따라 요약하여 기술한다. 이 격은 나는 존대(尊大)한 일을 얻지 못하고 타인에게 굴복한다. 천직전업(遷職轉業)은 권섭이 정확하지 않고 봉록을 받아도 다른데 바꿔 출재(出財)한다. 즉 가택(家宅)에 녹(祿)을 얻기 때문이다. 천후(天后)에 육합이 승하면 비전(秘傳) 백법(百法)중의 제40법의 자유 결혼격이다. 즉 중매인이 없고 남녀가 정의를 투합하여 이것이 동기(動機)가 되어 결혼으로 진행한다. 이 과는 일간(日干)․기궁(寄宮)․지신(地神)이 일지상신에 가해지고 인술(寅戌)에 천후(天后)․육합(六合)이 겹치기 때문에 완전히 사통정교(私通情交)한다.
  일귀는 순행하여 삼전이 백(白)․후(后)․합(合)이고, 야귀는 역행하여 삼전이 후(后)․백(白)․합(合)으로되어 일녀(泆女)로 된다. 여덕격은 신상(身上)이 불안하면 수호신에게 기도하는 것이 좋다.`
  ,
  // 10
  `  이 과는 4과중 제2과의 신사(申巳)의 극을 취하여 초전으로 하는 중심과(重審課)이다. 삼전(三傳) 사맹신(四孟神)은 원태격(元胎格)으로 된다.
  일지상신 축(丑)에 정신(丁神)이 승하고 인오술(寅午戌)일(日)은 신(申)을 보고 역마로 되어도 신(申)은 공망한다. 초․중전은 공망하고 말전의 인(寅)은 일간의 녹신(祿神)이지만 초전의 신(申)에 의해 극을 당하여 녹을 지키기는 어렵다. 신(申)의 역마가 발용(發用)으로 되어도 공망된다. 일지상신 정신(丁神)이 동의(動意)를 생한다. 초전․중전이 공망하고 말전의 인(寅) 1자(字)에 의해 길흉을 구하여 처음은 고생이나 후에 성취하는 상이다. 일귀(日鬼)가 공망하고 해(亥)의 장생(長生)이 또 공망되면 길흉은 모두 공허(空虛)하다.
  백법에 말하기를 ｢희기(喜氣)가 공망하여 묘기격(妙機格)으로 된다｣고 하였다. 비전에 말하기를 ｢일간상신 사화(巳火)에 탈기(脫氣)되고 태상(太常)이 승하면 화생토(火生土)로 탈상(脫上)은 탈(脫)을 만나므로 허사(虛詐)를 방지해야 한다. 유(酉)가 오(午)에 가해지면 사용인을 사랑하여 나의 처첩으로 한다.｣
  일귀는 역행하여 삼전이 사(蛇)․구(勾)․백(白)이며, 야귀는 역행하여 삼전이 청(靑)․주(朱)․후(后)이고 귀신(貴神)이 진술(辰戌)의 지(地)에 임한다. 년명에 진술(辰戌)이 승하면 길하지만 그렇지 않으면 귀인이 옥에 들어간다고 하여 관재(官災)를 초래하기 쉽다.`
  ,
  // 11
  `  이 과는 제1과와 제3과의 하에서 상을 극적(剋賊)한다. 어느 것이나 양극(陽剋)으로 되면 양일양비(陽日陽比)를 결정하기 어렵기 때문에 섭해과(涉害課)로 되고, 사맹신(四孟神)의 극을 채용하여 제1신(第一神) 진인(辰寅)을 초전으로 한다. 삼전은 1신을 건너 뛰어 순간전격(順間傳格)으로 된다.
  일간상신 진(辰)을 초전으로 하기 때문에 참관격(斬關格)이다. 삼전의 진오신(辰午申)은 등삼천(登三天)이란 이름이 있다. 간지상신에 육합․천후(天后)가 승하면 교동격(狡童格)으로 된다. 말전의 신(申)이 공망하므로 백호가 승하고 일간을 극하여 귀살(鬼殺)이 된다. 그러나 공망하기 때문에 두려울 것은 없으며 위험에 처하여도 구원이 있다. 진(辰)은 일간의 재신(財神)이지만 둔간이 경(庚)의 칠살(七殺)에 해당된다. 이 재(財)의 수득에 주의해야 한다. 그러나 오(午)에 의해 제어되므로 흉해를 면할 수 있다.
  귀신(貴神)이 해(亥)에 임하면 귀신이 천문(天門)에 오른다고 하며, 존장(尊長)에 대한 용건이나 공무(公務)․시험․선거․계획하는 사업이나 바라는 일은 모두 좋은 결과를 초래한다. 만약 년명(年命)이 생비(生比)에 있어 극이 없고 왕상(旺相)이 승할 때는 완전히 길조를 얻는다.
  백법에 말하기를 ｢진(辰)은 일간에서 극하여 재(財)로 되고 육합의 목장(木將)이 승하여 진(辰)을 극한다. 이와 같은 것은 협극(夾剋)이 되므로 재(財)는 자유를 얻지 못한다｣고 하였다. 후(后)․합(合)이 승하여 점혼(占婚)은 자유연애 결혼이다. 일귀는 역행하여 삼전이 합(合)․사(蛇)․후(后)이고 교동(狡童)으로 되며, 야귀는 순행하여 삼전이 합(合)․청(靑)․백(白)이다. 질병과 관사(官事)의 정단은 가장 흉하다.`
  ,
  // 12
  `  이 과는 4과 중에 제2과․제3과에 극이 있고 양일(陽日)이 양비(陽比)이다. 제2과의 진묘(辰卯)를 초전으로 하는 지일비용과(知一比用課)이다. 삼전은 진사오(辰巳午)로 진연여격(進連茹格)이다.
  초전은 진(辰)의 재(財)가 되고, 중․말전은 사오(巳午)의 탈기가 오히려 재(財)를 생하는 근원으로 되어, 구재는 큰 것을 얻는다. 탈기는 일간의 역량이 약하지만 일지상신 해수(亥水)가 나를 생조하여 설기(泄氣)를 제어하므로 손모 소비를 방지할 수 있다. 일지 술(戌)은 나의 집의 재(財)로서 간상 묘(卯)와 지합(支合)하고 기궁(寄宮) 인(寅)과 지상 해(亥)와 지합하여 교차상합(交車相合)하므로 모두 평화 타협하고 협조화미(協調和美)의 징조이다.
  지일(知一)은 극이 2개 있다. 그리하여 사상(事相)은 양도(兩途)를 건너서 하나로 돌아오는 것이 좋다. 진(辰)은 협극(夾剋)으로 되고 묘(卯)는 육해(六害)이다. 부처가 불화의 뜻을 품어도 일지가 합하여 후에 화합한다. 백법에 말하기를 ｢초전의 재신이 협극을 만나면 자기에게 의하지 않는다｣고 하였다. 삼전의 진(辰)은 계신(季神)이고 중전은 맹신(孟神)이며 말전은 중신(仲神)이다. 이와 같이 맹중계신(孟仲季神)이 상련(相連)하는 것은 관주연여(貫珠連茹)라고 부른다. 연주(連珠)는 길사의 정단이 겹치므로 길하다. 그러나 흉사의 정단은 연속적으로 흉사가 발생된다. 그러나 진연여(進連茹)는 사물이 전진 활동하면 길하고 또 순조롭게 진행된다.
  일귀는 역행하여 삼전이 합(合)․주(朱)․사(蛇)이며, 야귀는 순행하여 삼전은 합(合)․구(句)․청(靑)이다. 관직에 있는 사람은 공명영달(功名榮達)하고 바라는 일은 적중한다. 교차상합(交車相合)하는 것은 결혼 상성(相性)이 모두 길하고 성립되며, 또 피아 교섭사는 타협된다.`
  ,
]

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
