
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,1,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,8,12,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`신뢰`,9,11,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,10,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,11,9,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`신뢰`,12,8,`특별있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,1,7,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,2,6,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`신뢰`,3,5,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,4,4,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,5,3,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,2,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`직녹`,`직위녹위 관련사`,`小`,``,``,``],
    [`자산`,`자산 관련사`,`小`,`1`,`외사 화합`,`부부화합`],
    [`화합`,`외사 내사 화합사`,`大`,`절정기`,`경의불녕`,`경의불녕`],
    [`불가망행(不可妄行)`,`대중이 지켜 보므로 언행을 신중하게 해야 할 상`,`大`,``,`실財근심`,`실財근심`],
    [`산발경의(散髮驚疑)`,`밤도둑이 들어 놀라 산발한채 일어나 두려움에 떠는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`2`,`외교막힘`,`가정막힘`],
    [`막힘`,`외사 내사 침체막힘사`,`小`,`면모기`,`협력동심`,`활동득재`],
    [`형제불의(형제불의)`,`본인은 무관하나 형제에게 화가 발생하게 되는 상`,`小`,``,``,``],
    [`견련반복(牽連反復)`,`싫으나 연루되어 반복할 수밖에 없는 입장의 상`,`大`,``,``,``],
    [`급속`,`와사 내사 급속사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,``,`급속사有`,`급속사有`],
    [`매사영속성없음`,`명예직업직위권리남편자식사는 선천적 기복재해를 겪음`,`小`,`3`,`은둔심정`,`득재관재`],
    [`상인병송(常人病訟)`,`관자는 기쁨이 이어지나 일반인은 병, 쟁송이 당도하는 상`,`大`,`학습기`,``,``],
    [`소인쟁재(小人爭財)`,`신분의 차이가 나는 자와 적은 금전으로 다투는 상`,`小`,``,``,``],
    [`결속`,`개신개혁사로 재원바탕을 세우나 명예직위남편이 훼절됨`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`4`,`간인궤계`,`舊改新就`],
    [`매사 영속성없음`,`명예직업직위권리남편자식사는 선천적 기복재해를 겪음`,`小`,`개시기`,``,``],
    [`직위흥륭(職位興隆)`,`관공직자의 직위가 날로 높아지는 상`,`大`,``,``,``],
    [`발탁영전(拔擢榮轉)`,`발탁 영전 도약하는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`파재`,`외사 내사 파재극처실종 관련사`,`大`,`5`,`목표달성`,`도적失勢`],
    [`남편`,`남편 관련사`,`小`,`배양기`,`무효지사`,`잡음`],
    [`약능수구(略能守舊)`,`옛(지금) 것을 지키면 한가히 소요함을 얻게되는 상`,`小`,``,`파재실종`,`파재극처`],
    [`육자탐영(育子貪榮)`,`자식의 영화를 위한 문서사가 지체되고 있는 상`,`大`,``,``,``],
    [`금전`,` 금전 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`6`,`토지가옥`,`만사艱難`],
    [`처`,`처 관련사`,`小`,`존재기`,`음6월개혁`,`음6월개혁`],
    [`미중불미(美中不美)`,`좋은 중 나쁜 것이 있게 되는 상`,`小`,``,`내외실추`,`내외실추`],
    [`투서헌책(投書獻策)`,`투서헌책이 나쁘고 구설도 있으며 失財事가 있는 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`금전 관련사`,`大`,`7`,`협력동심`,`주택다툼`],
    [`색정`,`외남 내녀 동시 색정사 有`,`小`,`단절기`,`금전차용`,`이사요됨`],
    [`병재망난(病災亡難)`,`재물과 처가 말에 실리는 것을 병석에서 보게 되는 상`,`小`,``,`이후봉변`,``],
    [`불변근신(不辨謹愼)`,`장차를 위하여 말을 삼가하고 행위를 절제하라-는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`동요`,`외사 내사 동요불안분산사`,`大`,`8`,`발탁영전`,`신속細心`],
    [`결속`,`개신하여 財源바탕을 세우나 명예직위남편이 훼절됨`,`小`,`침체기`,``,``],
    [`자행공난(自行恐難)`,`고위인을 믿고 만용을 부리다가 두려운 일을 당하는 상`,`小`,``,``,``],
    [`주유형책(主有刑責)`,`확실한 것은 주력사로 관재형책이 있게 되는 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`토지`,`토지 관련사`,`小`,`9`,`면모훼손`,`전화위복`],
    [`부친`,`부친 관련사`,`大`,`소외기`,`구직신속`,``],
    [`역불사용(亦不私用)`,`혼외 通情은 妖氣가 되니 더 이상 취하지 말라-는 상 `,`小`,``,``,``],
    [`비곡수욕(悲哭差辱)`,`훼절을 당해 분해서 슬퍼하는 상`,`大`,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`개신`,`음8월에 내외 개신개혁사가 완성되는 상-이혼우려`,`小`,`10`,`희사허사`,`원방來金`],
    [`모친`,`모친 관련사`,`大`,`침해기`,``,``],
    [`범사손괴(凡事損壞)`,`모든 일이 암암리에 부서져버리는 상`,`小`,``,``,``],
    [`가정질병(家庭疾病)`,`가정에 질병사, 이사, 불통사가 있게 되는 상`,`大`,``,``,``],
    [`관사`,` 관련사`,`小`,``,``,``],
    [`송쟁`,` 관련사`,`大`,`11`,`무형消散`,`자식耗盜`],
    [`남편`,` 관련사`,`小`,`쇠퇴기`,``,``],
    [`견우봉망(牽尤逢網)`,`속히 퇴하지 않으면 망에 숨겨진 칼을 만나게 되는 상`,`小`,``,``,``],
    [`실업한가(失業閑暇)`,`실업자 격으로 한가한 상`,`大`,``,``,``],
    [`형제`,` 관련사`,`小`,``,``,``],
    [`급속`,`외사 내사 기로(岐路)적 출행이동원행사의 상`,`小`,`12`,`송사심흉`,`곤란不展`],
    [`송쟁`,`토지가옥 소송사`,`大`,`과시기`,`不辨救生`,``],
    [`유폐구가(惟閉口可)`,`오로지 입을 닫아야만 재난을 면할 수 있는 상`,`大`,``,``,``],
    [`존비상몽(尊卑相蒙)`,`신분 나이 차이가 많은 고용원과 음란의 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,`직녹자산화합사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`관사직업내외막힘으로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,``,`관사파재남편사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`금전사업여인조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`금전사업색정조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,``,`관사동요불안분산실추사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`후원문서부친조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`후원문서개신모친조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사송쟁남편실업사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`형제급속송쟁사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 시시비비를 당장 가리려 말고 유순한 처신으로 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하고 이후 결정하는 것으로 하면 반드시 유리한 결과를 얻게 되는 人`,0,0,`일이 거꾸로 진행되므로 퇴 하는 것이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 시시비비를 당장 가리려 말고 유순한 처신으로 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하고 이후 결정하는 것으로 하면 반드시 유리한 결과를 얻게 되는 人`,0,0,`전화위복사 맞음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 명분이 있고 정당하므로 먼저나서서 신속히 속결하는 것이 유리함`,0,0,`신속한 결정을 못하고 좌고우면하거나 사안을 뒤로 미루면 되려 결과가 불리해지는 人`,0,0,`토지가옥사가 있음**산으로 깊이 숨어버리고 싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 명분이 있고 정당하므로 먼저나서서 신속히 속결하는 것이 유리함`,0,0,`신속한 결정을 못하고 좌고우면하거나 사안을 뒤로 미루면 되려 결과가 불리해지는 人`,0,0,`개신개혁사 有**부부이별의 조 有**군자가 때를 기다리나 결과가 없고 역시 어두워 짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 시시비비를 당장 가리려 말고 유순한 처신으로 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하고 이후 결정하는 것으로 하면 반드시 유리한 결과를 얻게 되는 人`,0,0,`관공직자는 전근함-단, 음2월**이익을 위해 고위 또는 실력자를 이용하려 함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`매사 반복무상하고 어떤 일도 오래가지 않으며 편안한 가정을 이룰 수 없는 人`,0,0,`토자가옥사가 불안함**가택 이사하고 가족이 이산되며 직장도 옮김`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 시시비비를 당장 가리려 말고 유순한 처신으로 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하고 이후 결정하는 것으로 하면 반드시 유리한 결과를 얻게 되는 人`,0,0,`협력동심사가 있음**외남 내녀 모두 색정사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 사안을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`개신개혁사가 있고 결속하여 재원의 바탕을 이루나 명예직장남편사는 훼절됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 명분이 있고 정당하므로 먼저나서서 신속히 속결하는 것이 유리함`,0,0,`신속한 결정을 못하고 좌고우면하거나 사안을 뒤로 미루면 되려 결과가 불리해지는 人`,0,0,`토지가옥사가 있음**산으로 깊이 숨어버리고 싶은 심정임**전화위복사가 있음**가족이 상할 우려가 크므로 가택을 속히 빠져나와야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 명분이 있고 정당하므로 먼저나서서 신속히 속결하는 것이 유리함`,0,0,`신속한 결정을 못하고 좌고우면하거나 사안을 뒤로 미루면 되려 결과가 불리해지는 人`,0,0,`일이 띄엄띄엄 진행되고 실효가 없으므로 토하는 것이 좋음**군자가 때를 기다리며 결과없이 반복되었으나 비로소 밝아지는 상**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 명분이 있고 정당하므로 먼저나서서 신속히 속결하는 것이 유리함`,0,0,`신속한 결정을 못하고 좌고우면하거나 사안을 뒤로 미루면 되려 결과가 불리해지는 人`,0,0,`일이 순서적으로 진행되나 실효가 없으므로 퇴하는 것이 좋음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`스스로 유약하다고 여기고 적극적 활동을 기피하며 신음하는 人`,0,0,`일이 순서적으로 진행되는 듯하나 실효가 없으므로 퇴하는 것이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아사타사동시사`,`미사`,`신구중간사`,`외사내사겹침`,`아표면사타표면사겹침`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용출비`,`아타동시사`,`미사`,`신사`,`외사내사겹침`,`아표면사타표면사겹침`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동타발동사겹침`,`미사`,`구사`,`아외사타외사겹침`,`이표면사타표면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동타발동사겹침`,`기사`,`신사`,`아외사타내사겹침`,`아표면사타표면사겹침`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동타발동사겹침`,`기사`,`신구중간사`,`아외사타내사겹침`,`아표면타표면사겹침`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동타발동동시사`,`미사`,`구사`,`아외사타내사겹침`,`아표면사타표면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사타발동사겹침`,`기사`,`신구중간사`,`아외사타내사겹침`,`아표면사타표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동타발동동시사`,`미사`,`신구중간사`,`아외사타내사겹침`,`아표면사타표면사겹침`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사타발동사겹침`,`기사`,`구사`,`아외사타내사겹침`,`아표면사타표면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동타발동겹침`,`기사`,`신구중간사`,`아외사타내사겹침`,`아표면타표면사겹침`,`충성-무력`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사타발동사겹침`,`기사`,`신사`,`아외사타내사겹침`,`아표면사타표면사겹침`,`좌고우면-무력`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사타발동사겹침`,`기사`,`구사`,`아외사타내사겹침`,`아표면후회사타표면급속사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음10월`,2,3,5,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음1월-파혼우려`,1,3,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,3,2,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음1월-연기 또는 파혼우려`,3,3,6,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,2,1,3,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,2,2,4,`음음2월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월`,3,3,6,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음7월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,2,1,3,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있고 일을 거꾸로 진행하여 인심을 잃음`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯하나 조정능력이 있어 초반 중반에는 실적을 이루고 종반에는 저조함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품은 훌륭하나 업무는 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 여러 일을 결속시키는 개혁을 이루어 재원의 바탕을 크게 이루나 명예직위사는 훼손되는 결과를 초래함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무도 초 중반을 실패하고 종반에 이르러서야 실적을 이룸 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무도 크게 실패함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여  정체성을 진퇴양난에 처하게 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯하나 조정능력이 있어 여러 일을 결속시켜 재원의 부리를 크게 키우지만 결과적으로 정체성의 명예를 실추시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 엄무는 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무도 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 유순하고 준수 미모이나 업무가 강하지 못해 업무 전반에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무도 내내 송쟁사가 진행 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`반대있음`,`불이행`,`권리양도`,`進向`,`원`,`留`,`화합`,`정북`,``,`정서`],
    [`10.11`,`多集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`후반반대`,`불이행`,`선이후이`,`進向`,`  원`,` 留`,`세입가해`,`북북동`,``,`남남서`],
    [`10.11`,` 多集`,`중반제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`처리유리`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`극렬반대`,`불이행`,`선난후費`,`退背`,`  원`,`  去`,`반목대립`,`정남`,``,`남서`],
    [`10.11`,``,`본인합세`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선이후이`,`進向`,`  근`,`  留`,`세입조력`,`동남`,`정서`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선이후이`,` 進向`,`  근`,`  留`,`세입조력`,`남남동`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대극렬`,`불이행`,`선난후이`,`退背`,`  원`,`  去`,`상호동요`,`정남`,`북북서`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`불이행`,`선攻후費`,`退背`,`  근`,`  去`,`주인조력`,`정남`,`정북`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`측근제압`,`불이행`,`선攻후費`,` 進向`,`  원`,`  留`,`주인조력`,`서남`,`동남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`이행`,`선난후費`,`退背`,`  원`,`  去`,`상호파재`,`서남`,`남남동`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`종반반대`,`불이행`,`본인사안`,`退背`,`  근`,`  去`,`주인무력`,`정서`,``,`정남`],
    [`10.11`,``,`제압불가`,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,`본인사안`,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세무력`,`불이행`,`모두무력`,` 退背`,`  근`,`  去`,`주인무력`,`북북서`,``,`동남`],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`극렬반대`,`불이행`,`선난후이`,`退背`,`  근`,`  去`,` 무난`,`정북`,``,`남남동`],
    [`10.11`,``,`본인동조`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,`제압불가`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,`전흉사귀`,`혈광사`,0,0,`묘좌`,`정북`,`있음`],
    [``,``,``,0,0,`묘향`,`정남`,``],
    [`생업`,``,``,0,0,`전경`,`사찰,제각`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,``,``,0,0,`육친`,`처,증조모`,``],
    [`질병`,`제례귀`,`제례위법`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`가택귀`,`가장사망`,0,0,`묘좌`,``,`없음`],
    [``,`제례귀`,`제례위법`,0,0,`묘향`,``,``],
    [`생업`,`가택귀`,`가장사망`,"목기부정","절단반입",`전경`,``,``],
    [``,``,``,0, "樹木",`묘속`,``,``],
    [`가정`,`가택귀`,`가장사망`,"목기부정","절단반입",`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`특별있음`],
    [``,`제례귀`,`제례위법`,0,0,`묘향`,``,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`변사귀`,`객사`,0,0,`육친`,``,``],
    [`질병`,`여귀`,`호주부인`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`제례귀`,`제례위법`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`여귀`,`호주부인`,0,0,`묘좌`,``,`있음`],
    [``,`변사귀`,`객사`,0,0,`묘향`,``,``],
    [`생업`,`여귀`,`호주부인`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`여귀`,`호주부인`,0,0,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,`북북동`,`있음`],
    [``,`전사귀`,`전사`,0,0,`묘향`,`남남동`,``],
    [`생업`,`음귀`,`묘지틸`,0,0,`전경`,`사찰,제각`,``],
    [``,``,``,0,0,`묘속`,` 泥土`,``],
    [`가정`,`음귀`,`묘지탈`,0,0,`육친`,` 조모`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외내 출행이동원행사`,`의관행사사`,`내외 부부남녀화합사`,`산발驚疑사`,`외내 막힘사`,`尊卑상몽사`,`매사 허성불성-세속허사자**실업 한가함`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음`,`종교진출하면자립함`,`매사 위역사`,`재난전소사`,``,`구설송사 흉-문서소식 길`,`외남내녀 동시 색정사`,`주력심 성사`,`내외 충산동요사`,`왕래 사송계류`,``,`상린충산사`,``,`간인궤계사`,`매사 위역사**외내급속송쟁파재실종상해사우려`,`반화위복사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`빈궁`,``,`재액해소`,`관재`,`외내 급속사`,``,`가족질병사-이사`,``,`전화위복**재액해소`,`빈궁**관재`,`음4,5월화재주의`,`전도**빈궁`,`음4,5월화재주의`,`전도`,`재액해소`,`빈궁**관재`,``,``,`빈궁`,``,`驚懼침체사**재액해소`,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외내급속송쟁파재실종상해사 우려`,`소인쟁재사`,`직녹권리사**외내 출행이동원행사`,`견련반복사`,`내외 부부남녀화합사`,`부부반목사`,`외내 막힘사`,`불리 고시사-구설사`,`매사 영속성없음**왕진쇠퇴사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`비곡차욕사-훼절당하고 슬피우는 상`,``,`와병불기-구설놀램`,`외남내녀 동시 색정사`,`도적악의-공격말 것`,`내외 충산동요사`,`下賜-미초희경사`,``,`좋은 소식 대기사`,``,`간인궤계사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`관재`,`전화위복`,`빈궁`,``,``,`재액해소`,`빈궁**관재`,`외내 급속사`,`빈궁`,`가족질병사-이사`,``,`전화위복**재액해소`,`관재`,`음4,5월화재주의`,`전도**빈궁`,`음4,5월화재주의`,`전도**빈궁**관재`,`재액해소`,`관재`,``,`빈궁**병재`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`간인궤계사`,`외내 급속송쟁파재실종상해사 우려`,`소인쟁재사`,`직녹권리사**외내 출행이동원행사`,`견련반복사`,`내외 부부남녀화합사`,`부부반목사`,`외내 막힘사`,`불리 고시사-구설사`,`매사 영속성없음**왕진쇠퇴사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`비곡차욕사-훼절당하고 슬피 우는 상`,``,`와병불기-구설놀램`,`외남내녀 동시 색정사`,`도적악의-공격말 것`,`내외 충산동요사`,`下賜-미초희경사`,``,`좋은 소식 대기사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`관재`,``,`전화위복`,`전도**빈궁`,``,`전도`,``,`빈궁**관재`,`외내 급속사`,`빈궁`,`가족질병사-이사`,``,`전화위복`,`관재`,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,`관재`,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`도적失勢-잡음`,``,`事後쟁탈사`,``,`매사 위역사**외사급속송쟁파재실종상해사우려`,`반화위복사`,`직녹권리사**외내 출행이동원행사`,`간인궤계사`,`내외 부부남녀화합사`,`비상지애사`,`외내 막힘사`,`남모르는 능욕박해사`,`매사 영속성없음**환성경사-부족`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`매사 위역사`,`재난전소사`,`발탁영전도약사`,``,`외남내녀 동시 색정사`,`탄식신음사-병, 모사미수 원인`,`내외 충산동요사`,`觀書-실업-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**병재`,``,`전도`,``,`빈궁**관재**병재`,``,`전화위복**재액해소`,`빈궁`,`재액해소`,``,`빈궁**관재`,``,`외내 급속사`,``,`자식사 문서상신답신지체-실재사`,`빈궁`,`전화위복`,`빈궁**관재`,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,``,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`내외 충산동요사`,`觀書-실업-한가함`,`도적失勢-잡음`,``,`事後쟁탈사`,``,`매사 위역사**오내 급속송쟁파재실종상해사우려`,`반화위복사`,`직녹권리사**외내 출행이동원행사`,`간인궤계사`,`내외 부부남녀화합사`,`비상지애사`,`외내 막힘사`,`남모르는 능욕박해사`,`매사 영속성없음**환성경사-부족`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`매사 위역사`,`재난전소사`,`발탁영전도약사`,``,`외남내녀 동시 색정사`,`탄식신음사-병, 모사미수 원인`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**관재`,``,`재액해소`,`빈궁**병재`,`재액해소`,`관재`,``,`전도**관재**병재`,`전화위복`,`빈궁`,``,`관재`,``,`전도**빈궁**관재`,`외내 급속사`,``,`자식사문서상신답신지체-실재사`,`빈궁**관재`,`전화위복`,`전도**빈궁**관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`외남내녀 동시 색정사`,`불변근신사`,`내외 충산동요사`,`안 좋은 투서헌책사-이동사`,`혼인 만족 단장사`,``,`관재형책사`,``,`외내 급속송쟁파재실종상해사우려`,`소인쟁재사`,`직녹권리사**외내 출행이동원행사`,`간인궤계사`,`내외 부부남녀화합사`,`소식 대기-와도 나쁨`,`외내 막힘사`,`승진영전사`,`매사 진출하면자립함**도적 못잡음`,`종교진출하면자립함`,`매사 영속성없음**사행불량사`,`종교진출하면자립함`,``,`비곡차욕사-훼절당하고 슬피 우는 상`,`발탁영전도약사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`관재`,`동요분산사**전화위복`,`전도**빈궁**관재`,`동요불안분산사`,`전도관재**병재`,`동요불안분산사`,`관재`,`동요불안분산사**외내 급속사`,`빈궁`,`동요불안분산사`,`빈궁`,`동요분산사**전화위복`,`관재`,`음4,5월화재주의**동요분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`발탁영전도약사`,``,`외남낸녀 동시색정사`,`불변근신사`,`내외 충산동요사`,`안 좋은 투서헌책사-이동사`,`혼인 만족 단장사`,``,`관재형책사`,``,`외내 급속송쟁파재실종상해사우려`,`소인쟁재사`,`직녹권리사**외내 출행이동원행사`,`간인궤계사`,`내외 남녀부부화합사`,`소식 대기-와도 나쁨`,`외내 막힘사`,`승진영전사`,`매사 영속성없음**도적못잡음`,`종교진출하면자립함`,`매사 영속성없음**사행불량사`,`종교진출하면자립함`,``,`비곡차욕사-훼절당하고 슬피우는 상`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁**관재`,``,`전도**관재`,``,`전도**빈궁`,``,`관재`,`전화위복**재액해소`,`빈궁**관재`,`재액해소`,`빈궁**병재`,``,`관재`,`외내 급속사`,`빈궁`,``,`빈궁`,`전화위복`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`비곡차욕사-훼절당하고 슬피 우는 상`,``,`발탁영전도약사`,``,`외남내녀 동시 색정사`,`불변근신사`,`내외 충산동요사`,`안 좋은 투서헌책사-이동사`,`혼인만족 단장사`,``,`관재형책사`,``,`외내 그복송쟁파재실종상해사우려`,`소인쟁재사`,`직녹권리사**외내 출행이동원행사`,`간인궤계사`,`내외 부부남녀화합사`,`소식 대기-와도 나쁨`,`외내 막힘사`,`승진영전사`,`매사 영속성없음**도적 못잡음`,`종교진출하면자립함`,`매사 영속성없음**사행불량사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`관재`,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁`,``,`빈궁**관재`,``,``,`빈궁`,``,`관재`,``,`전화위복`,`빈궁`,``,`빈궁**병재`,``,`관재`,`외내 급속사`,`전도**빈궁`,``,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`매사 영속성없음**사행불량사`,`종교진출하면자립함`,`비곡차욕사-훼절당하고 슬피 우는 상`,``,`발탁영전도약사`,``,`외남내녀 동시색정사`,`불변근신사`,`내외 충산동요사`,`안 좋은 투서헌책사-이동사`,`혼인 만족 단장사`,`관재형책사`,``,``,`외내 급속송쟁파재실종상해사우려`,`소인쟁재사`,`직녹권리사**외내 출행이동원행사`,`간인궤계사`,`내외 남녀부부화합사`,`소식대기-와도 나쁨`,`외내 막힘사`,`승진영전사`,`매사 영속성없음**도적 못잡음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`전화위복`,`관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`빈궁**관재`,``,``,`빈궁`,``,`관재`,``,`전화위복`,`전도**빈궁`,``,`전도**빈궁**병재`,``,`관재`,`외내 급속사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사 영속성없음**실업 한가함`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`매사 위역사`,`재난전소사`,``,`구설송사 흉-문서소식사 길`,`외남내녀 동시 색정사`,`주력심 성사`,`내외 충산동요사`,`왕래 사송계류사-이동사`,``,`소인쟁재사`,``,`간인궤계사`,`매사 위역사**외내 급속송쟁파재실종상해사우려`,`반화위복사`,`직녹권리사**외내 출행이동원행사`,`下賜-미초희경사`,`내외 남녀부부화합사`,`산발驚疑사`,`외내 막힘사`,`尊卑상몽사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외내 급속사`,``,`가족질병사-이사`,``,`전화위복`,`전도**빈궁**관재`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,``,``,`재액해소`,`전도**빈궁**관재`,`재액해소`,``,``,`빈궁`,`전화위복`,``,``,`빈궁`,``,`전도**빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외내 막힘사`,`尊卑상몽사`,`매사 영속성없음**실업한가함`,`종교진출하면자립함`,`매사 허성불성-세속허사자`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 위역사`,`재난전소사`,``,`구설 송사 흉-문서소식사 길`,`외남내녀 동시 색정사`,`주력심 성사`,`내외 충산동요사`,`왕래사송계류사-이동사`,``,`소인쟁재사`,``,`간인궤계사`,`매사 위역사**외내 급속송쟁파재실종상해사우려`,`반화위복사`,`직녹권리사**외내 출행이동원행사`,`下賜-미초희경사`,`내외 부부남녀화합사`,`산발驚疑사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**관재`,``,`외내 급속사`,``,`가족질병사-이사`,``,`전화위복`,`전도**빈궁**관재`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`전도**빈궁**관재`,``,`재액해소`,``,`재액해소`,`빈궁`,``,`전도**관재**병재`,`폐한인**전화위복`,``,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`내뇌부부남녀화합사`,`산발驚疑사`,`외내 막힘사`,`尊卑상몽사`,`매사 영속성없음-세속허사자**실업 한가함`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 위역사`,`재난전소사`,``,`구설 송사 흉-문서소식사 길`,`외남내녀 동시 색정사`,`주력심 성사`,`내외 충산동요사`,`왕래사송계류사-이동사`,``,`소인쟁재사`,``,`간인궤계사`,`매사 위역사**외내 급속송쟁파재실종상해사우려`,`반화위복사`,`직녹권리사**외내 출행이동원행사`,`下賜- 미추희경사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`빈궁**관재`,``,`외내 급속사`,``,`가족질병사-이사`,`빈궁**관재`,`전화위복`,`빈궁`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**관재`,``,``,``,``,``,`빈궁`,``,`빈궁**관재**병재`,`전화위복`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 꽉 막혔네? 
  귀신도 가득차 있고! 
  땅, 가옥으로 소송 붙었고!    돈ㆍ여자에 욕심내다가는    재판이 또 붙겠어! 
  방법은 귀신 해소 뿐이야!
`,`■ 인심은 잃고 있어도 재복은     있어서 복록이 크네?

⊕⊖전사귀를 해소 해야 
    병이나더라도 이겨내지!
`,`■⊕음귀, 
  ⊖신불제사귀만 해소하면      맘먹은대로 다 되겠네? 

단, 男：처로 인해 근심이           생기겠어!
`,`■ 내 몸이나 가정이나 집이나 
   귀신과 부정이 꽉 찼네?
⊕상가부정, 
⊖가택목기부정이 들었다.
  그러니 토지ㆍ가옥사가 막히고     소송붙었지! 
  이것을 해소하고 근신하면 풀려 !
⊕신변상가부정 침범
⊖신변목기부정이 침범했네 ?
`,`■여러 일을 묶고 확 바꿔서    새롭게 결속하겠어! 
  구개신취가 되어 형통은
  하겠는데
⊕전사귀, 
⊖변사귀와 자식이 근심으로    남겠어! 
`,`■ 일과 인연들이 다 끝났네? 
   그래도 확 바꾸지는 말고
   자손의 凶이 있겠어!
⊕전흉사귀, 
⊖가택상가부정ㆍ가택귀를 잡고    윗사람을 찾아봐!
  卯月에 기쁨이 오겠어 !
⊖신변상가부정이 침범했네?
`,`■ 색정불화와 관송사로
  몸도 마음도 둥둥떴네?
   고상하게 살고 싶은데 
   일은 반대야! 
   귀신 방해인데
⊕⊖변사귀․신불제사귀가       신변과 가택에 널렸어! 
    안잡고는 방법이 없겠어!  
`,`■ 집 문제로 다툼이 생기겠네?     진퇴양난이야! 
   이사가는게 좋겠어! 
   그냥 살면 초상나겠어! 
  두사람이 협력해도 효과가      없겠어 !
⊕여귀ㆍ가택음사부정, 
⊖여귀를 없애는게 더 급하고!
⊕신변음사부정이 침범했네?
`,`■ 옛것을 보내고 새것을 
   얻는 격이네? 
   여러 일을 하나로 묶는      개혁은 빠를수록 좋고 
   귀신 해소도 그렇겠어! 
   그런데 자식 걱정이 생기     겠어!
`,`■ 몸이나 가정이나 가택이나 ⊕가택수망노부부정, 
  음사부정이 들었다.
  그대로 두면 기쁜 일도 다    허사로 되고 말겠어!

⊕신변 수망노부부정 침범
⊖신변음사부정이 침범했네?
`,`■ 속고 있네? 
   자손의 재해가 크지만 
   형체는 없는데 그림자만     밟고 가는 형국이야! 
   나중에 남는 것도 구설ㆍ     다툼 뿐이겠어! 
   귀신부터 잡고 봐야 겠네!
`,`■ 자식 걱정이 크네?
   지금 속고 있겠어! 
   다만 내일부터 재물운세가     좋아지겠어 !
   귀신 잡는게 더 급한데   ⊕여귀, 
⊖도로귀 발동이야 !`];
  const YOGYEOL1 = [`■ 재물을 보고 욕심을 내면 나쁜일이 침범한다. 중간에 귀살이 범을 타고 있으나 관직자는 좋고 일반인은 나쁘다. 
  `,`■ 내녹이 좋으니 잘 활용해야 한다.
  규방부부가 서로 합하니 혼인사는 매우 좋다. 
  소송이 생기면 여러 사람이 괴로우리라.
  `,`■ 오는 자나 간 자나 돈을 갖고 돌아오겠다.
  관살에 또 숨은 관살이 있으니 친구 형제의 일은 나쁘다.
  `,`■ 금전사를 애써 꾸미면 재앙을 받는다. 
  낮정단은 그 흉이 더욱 심하다. 관직자는 대단히 좋고 기쁨을 거듭 보리라.
  `,`■ 천장은 극하고 천신은 생한다. 사람과 집에 암귀가 있다. 사람은 잘되나 집은 쇠하고 신사는 잘되나 천한자는 안된다.
  `,`■ 돈과 재물이 땅속으로 들어가고 자손사는 썩은 나무에 도장 판다. 두 귀인이 화나 있으니 부탁말고 옛것을 지키고 소일하라.
  `,`■ 재물이 관살에 임했으니 칼 끝에 묻은 꿀을 빨지 말라.
  귀인의 도움 받는 것은 쉬라. 좋은 중에 나쁜 일이 생긴다.
  `,`■ 처나 재물이나 내 왕마다 손해만 난다.
  처는 병이 생기고 돈은 없어지니 갖기도 어렵고 버리기도 어렵다.
  `,`■ 사람은 잘 되나 집이 좁다. 상대는 손해나고 나는 이롭다.
  여러 입이 나를 도와주니 관직자는 앞길이 훤히 열리겠다.
  `,`■ 귀신과 도적이 가득하니 흉한일이 많겠다.
  범띠와 토끼띠는 모든 凶한 일을 막아낼 수 있으리라.
  `,`■ 귀인이 많으나 일이 생기면 도와줄 수 없을 것이다. 현재의 재물도 감당 못하는데 숨은 재물을 간수하겠는가.
  `,`■ 사람이고 집이고 되어가는 일에 손해수가 가득하다.
  자식이 돈 버리지 않으면 병약하거나 단명하리라.`]
  const SIBO1 = [`■ 저주사가 있다.
  막혀서 통하지 못한다.
  下가 上을 기만하고 재난은
  외사 내입 된다.
  금전ㆍ여자를 경위없이 받아들이면 재난이 발생한다.
  병ㆍ송사는 매우 흉하나 입조심해야 화를 면한다.
  관공직사는 신속하게 이루어진다.
  `,`■ 권리를 잃는 격이다.
  그러나 국가에서 은사가
  있게 된다.
  비로소 때가 도래한 격이다. 단, 공사적이 아닌 사사로히 접근해야 이롭게 된다.
  관공직자는 매우 길하고 신속하며 일반인도 기쁨을 맞는다. 
  병ㆍ송사는 나쁘다.
  `,`■ 하극상의 일은 있으나
  모두 길로 변한다.
  금선사로 움직이고 원방의 송금이 있게 된다.
  二人의 동심ㆍ협력사가 있다.
  공명ㆍ구직사는 불리하게 본다. 건록이 공망에 좌했기 때문이다.
  `,`■ 금전사를 계획하면
  재난ㆍ화근이 된다.
  토지ㆍ가옥의 소송건을 타인에 의뢰하면 비용만 손해나고 반복된다.
  일이 간난하여 피신이 유리하고 신불기원하면 길이 열린다.
  
  `,`■ 사람은 왕성하고 주택은
  쇠퇴하는 격이다.
  여러 가지 일을 하나로 묶어 구개신취ㆍ개신ㆍ개혁하여 이롭게 된다.
  금ㆍ철건이 기한다.
  전직하거나 천전하여 더욱 편하게 된다. 
  단, 자식과 부부이별 걱정이 있다.
  `,`■ 계약ㆍ교섭ㆍ문서사는
  완결이 안된다.
  조건부족 때문이다.
  구사를 종결짓고 구태를 지켜야 한다. 
  속임을 당한다. 
  오직 가정ㆍ가족ㆍ내부의 의견만 쫓는 것이 유리하다. 다른 것은 모두 헛소리이다.
  `,`■ 관리ㆍ공직자는
  ⊕탄핵을 받고, ⊖주석에서
  사건이 생긴다.
  무슨 일에서나 반복부정하고 간난한 입장이다.
  토지ㆍ가옥사로 원방의 송금이 있고, 이 돈은 시비가 있게 되므로 경위를 잘 밝힐 수 있어야 한다.
  `,`■ 금전이 쪼들린다.
  부인이 혈질병에 걸린다.
  돈을 빌리면 재난이 따르게 된다.
  현재의 집에서 급히 이사해야 한다. 
  5년 이내 사망사가 벌어진다. 
  七月 임신사가 있다.
  `,`■ 구개신취 한다.
  여러 일을 하나로 묶은
  개신ㆍ개혁사가 있다.
  신속해야 좋다.
  여러 사람의 추천ㆍ천거를 받는다. 
  금전이 크게 들어온다.
  단, 자식 문제가 큰 걱정으로 된다.
  `,`■ 토지가옥사가 침체하여
  암울한 입장이다.
  ⊕수망노부부정, 음사부정
  이 들었다.
  기쁜 일이 허사로 되기 쉽다. 
  가택에서 부상 당하거나 재해가 발생한다.
  그러나 경미하게 된다. 
  관직사는 신속한 기쁨이 있다.
  `,`■ 처음ㆍ중간이 모두 공망
  되고 4과도 모두 공망하여
  다 헛되다.
  속고 있다.
  말전의 귀살은 丁火를 숨겨 재해를 노리므로 원방의 송금은 나중에 시비 붙을 돈이므로 방비해야 한다.
  `,`■ 자식 문제가 온통 근심이고
  속고 있다.
  공론ㆍ공화일 뿐이다.
  귀신을 해소 하면 우환도 해소된다.
  財를 구하지 않아도 저절로 들어온다.
  단, 冬春절이다.`]
  const SIBO2_1 = [`■관ㆍ부,신변내외사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  토지 관송사가 있다.
  상호 허를 찌른다.
  `,`■직ㆍ녹,신변내외사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  가택권리가 양도된다.
  `,`■ 형제ㆍ손모내외사이다.
  내방조짐 상의 : 
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다. 
  `,`■官･夫,대립반목사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 사업ㆍ후원내외단합사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다. 자손 재해 예방이 필요하다.
  `,`■ 자손ㆍ박관후회사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다
  속는 일이 있고 자손 재액이 있다.
  `,`■官･夫,내외동요사이다.
  내방조짐 상의 : 
  이전·이사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■ 財ㆍ女,여한사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 사업ㆍ후원내외개신사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  자손 재해 예방이 필요하다.
  `,`■官･夫,내외동요사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  병․관재가 침입한다.
  `,`■ 자손ㆍ박관내외사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다
  속는 일이 있고 자손 재액이 있다.
  `,`■ 자손ㆍ박관내외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.자손 허위사가 흉하다. `]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/050/row-1-column-1.png`,
    `/static/images/sagwa/050/row-1-column-2.png`,
    `/static/images/sagwa/050/row-1-column-3.png`,
    `/static/images/sagwa/050/row-1-column-4.png`,
    `/static/images/sagwa/050/row-1-column-5.png`,
    `/static/images/sagwa/050/row-1-column-6.png`,
    `/static/images/sagwa/050/row-1-column-7.png`,
    `/static/images/sagwa/050/row-1-column-8.png`,
    `/static/images/sagwa/050/row-1-column-9.png`,
    `/static/images/sagwa/050/row-1-column-10.png`,
    `/static/images/sagwa/050/row-1-column-11.png`,
    `/static/images/sagwa/050/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `   이 과는 천지신복(天地神伏)하여 움직이지 않는다. 위에서 아래를 극하는 것이 있어 이것을 용신(用神)이라 하고 가색격(稼穡格)이다.
    과전(課傳)이 모두 일간을 극하여 중귀(衆鬼)가 된다. 제반사(諸般事)가 곤란하고 발전하지 않는다. 묘신(墓神)만은 막혀서 통하기 어렵다. 말전의 미(未)는 정재(丁財)가 된다. 재(財)는 칠살(七殺)을 생하고 귀(鬼)로 화하기 때문에 재를 보면 마음이 움직여 흉화(凶禍)를 초래한다. 중전 백호로부터 극되어 병점(病占)․송사(訟事)는 가장 흉하다. 구관(求官)․구직(求職)은 최관부(催官符)로 되고 임관구직(任官求職)은 신속하게 이루어진다.
    계일(癸日)은 기궁(寄宮) 축(丑)이 과전동류(課傳同類)로서 탈난격(脫難格)이라 부르며, 흉이 극에 달하면 오히려 액난(厄難)이 해소(解消)될 징조가 있다. 일간상신에 파쇄살(破碎殺)이 승하여 초전이 되는 것은 상문난수(上門亂首)라 하는데, 하(下)가 상(上)을 극하고 재(災)는 밖에서 생겨 안으로 파급된다. 말전이 정신(丁神)으로 되면 원방에서 재물을 보내준다. 결혼은 이루어지지만 이별의 우려도 있다. 만약 년명상신(年命上神)이나 행년으로부터 정신(丁神)의 화(火)를 극하여 없애면 상술한 일도 제거된다.
    백법에 이르기를 ｢피차 투합(投合)되지 않는 것은 지형(支刑)사에 있기 때문이다｣고 하였다. 복음과(伏吟課)로서 정마(丁馬)가 승하면 동조(動兆)를 이루고, 유자격(遊子格)은 몸의 움직임이 있다. 순미(旬尾)의 축(丑)이 초전이 되면 폐구격(閉口格)이다. 무슨 일이나 구론(口論)을 중지함으로써 화(禍)를 면하여야 한다. 비전(秘傳)에 이르기를 ｢사과(四課)가 모두 같으면 사물이 명확하게 되기 어렵고 해결하기도 어렵다｣고 하였다.`
    ,
    // 2
    `   이 과는 아래에서 위를 극하는 제3과의 자축(子丑)을 초전으로 하고 중심과(重審課)이다. 일간상신은 건록, 제2과는 제왕(帝旺)이 되어 왕록(旺祿)이 신(身)에 임한다. 자축(子丑)이 지합(支合)하므로 우녀(牛女)가 서로 만나 결혼하면 가장 길하다. 말전 술(戌)은 백호가 승하여 일간을 극하므로 병소(病訴)의 정단은 가장 흉하다.
    제2국의 퇴여격(退茹格)은 물러서서 명을 지킨다. 술(戌)이 해(亥)에 임하면 괴도천문격(魁度天門格)이라 하고 일명 실우격(失友格)이라고도 하여 인정을 잃는다. 일지상신이 초전으로 되고 중전에는 역마(驛馬)가 승하며 말전은 최관부(催官符)이다. 공직․임관 및 천전(遷轉)은 신속하다.
    백법에 이르기를 ｢왕록이 신에 임하면 망동하면 좋지 않다｣고 하였다. 제3과 상신이 녹에 임하면 권섭부정격(權攝不正格)이다. 비전(秘傳)에 이르기를 ｢천을귀신이 덕상(德相)에 임하면 나라에는 은사(恩赦)가 있다｣고 하였다. 보통 사람에게는 희사가 있고 청룡은 바다에 들어간다. 즉 자(子)에 승하면 재복(財福)이 장차 오고 복력(福力)이 면면(綿綿)하여 기쁨이 있다. 과전이 모두 술해자(戌亥子)이면 음기가 중하기 때문에 사사(私事)는 유리하고 공사(公事)는 유익하지 않다. 일귀(日貴)는 역행하여 삼전은 청(靑)․공(空)․백(百)이고, 야귀(夜貴)는 삼전 현(玄)․상(相)․백(百)이다. 말전에 백호가 승하면 재(災)를 받기 쉽다.`
    ,
    // 3
    `   이 과는 제3과 해축(亥丑)이 일하(一下)가 일상(日上)을 극하므로 이것을 초전으로 하여 중심과(重審課)가 된다. 일간상신 해(亥)는 역마가 초전이 되고 말전은 정신(丁神)으로 되어 정마(丁馬)가 승하므로 활동하여 재(財)를 얻는다. 중심(重審)은 하(下)에서 상(上)을 극하고 하(下)는 상(上)의 존장(尊長)을 범(犯)한다. 사과(四課)는 해유(亥酉)의 2과밖에 없고 삼전삼기(三傳三奇)는 흉을 구하여 길(吉)로 화한다.
    백법에 이르기를 ｢수일(水日) 정재(丁財)는 재사(財事)에 의하여 움직인다｣고 하였다. 백법의 제26법에 이르기를 ｢임계일(壬癸日)에 삼전일진연명(三傳日辰年命)의 육소(六所)에 순내(旬內)의 정신(丁神)이 승하면 이 격을 구성한다｣고 하였다. 이 격은 반드시 재동지사(財動之事)가 있어 원근을 물론하고 재(財)가 나에게 이른다. 또 미혼자는 구혼(求婚)의 기쁨이 있고, 기혼자에게는 처(妻)의 일로 인해 고심과 걱정이 생긴다. 점자(占者)의 행년에 정신(丁神)이 승하여 신으로 극이 될 때는 재는 움직이지 않는다.
    비전에 이르기를 ｢부부년명상신(夫婦年命上神)에 삼합의 지(支)가 가해져 연월이 왕상이 되면 왕잉격(旺孕格)이라 하여 그 해에 잉태한다. 일간일지의 과체동일(課體同一)은 팔전과(八專課)이지만 계축일(癸丑日) 및 기타 5과는 극이 있다. 그러나 사과의 일진은 2과 밖에 없고 두 사람이 동심(同心)의 상(常)이 있으며, 팔가동병(八家同倂)의 징조가 있다.
    일귀는 역행하며 3전은 공․상․음이며, 야귀는 삼전이 구․공․상이다, 이 과는 천반건록이 공망에 앉아 공명구직(功名求職)은 불리하다.`
    ,
    // 4
    `   이 과는 4과 중 제1과 술(戌)과 계(癸)의 극을 사용하여 초전으로 하고, 일상일하(一上一下)의 극이므로 원수가색격(元首稼穡格)으로 된다. 4과의 술(戌)이 초전이 되면 참관격(斬關格)이다. 그러나 진술(辰戌)이 충하므로 참관(斬關)이 통한다.
    중전의 미(未)에 정신(丁神)이 있어 수일(水日)정재이다. 그러나 과전은 모두 관귀(官鬼)로 되어 살(殺)을 돕고 술(戌)은 일간을 극하여 최관부로 되기 때문에 사관․구직은 가장 적합하다. 원수과(元首課)는 상이 하를 제(制)하여 일이 순조롭고, 가색격은 일이 간난할 징조이다. 참관은 진동(震動)의 형(形)이다. 중귀(衆鬼)에 더하여 충형(冲刑)이 있다. 천장(天將) 사호(蛇虎)가 승할 때는 보통 사람은 놀랄 일을 만난다. 재사(財事)를 계획하면 화근이 된다.
    백법에 이르기를 ｢백호가 일간상신에 승하여 귀(鬼)로 되면 흉해는 신속하게 온다｣고 하였다. 수일(水日)  정재는 재사(財事)의 움직임이 있다. 전재(傳財)는 귀(鬼)를 생하므로 재사는 위험하다. 
    비전에 이르기를 ｢참관유자격(斬關遊子格)은 몸에 변동이 생기고 임계일(壬癸日)의 가색(稼穡)은 탈난살(脫難殺)이라 하여 사물이 극에 이르면 변하고 변화하면 통하기 때문에 위험을 풀어준다｣고 하였다.
    이 과는 대부분은 건축(建築)․증축(增築)․수조(修造)․토지(土地)․가옥(家屋) 등의 건에 걸려 무슨 일이나 지둔(遲鈍)하게 된다. 병증(病症)은 비위(脾胃)에 속한다. 삼전에 흉장(凶將)이 승하면 토지․가옥 등으로 인하여 다툼이 생긴다. 청룡이 승하면 전택(田宅)의 교역매매(交易賣買)의 건이다. 충형이 되면 타인에게 의뢰하여 비용의 손해를 보고, 모사(謀事)는 반복한다. 일귀는 역행하고 삼전은 백(白)․음(陰)․사(蛇)이며 야귀는 청(靑)․상(常)․후(后)이다.`
    ,
    // 5
    `   이 과는 제2과가 사유(巳酉)로 상에서 하를 극하여 초전이 되고 원수과(元首課)로 된다. 삼전 사유축(巳酉丑)은 종혁격으로 삼합금국에서 일간을 생한다. 일간은 상신에서 생(生)을 받고 일지는 간상(干相)의 유(酉)에 설(泄)하기 때문에 사람은 왕성하고 주택은 쇠퇴한다. 삼전은 화금토(火金土)와 수일(水日)이 체생(遞生)이 되어 타인의 천거를 받으므로 일이 순조롭다. 삼전 종혁격은 개구취신(改舊就新)해야 한다. 과전이 삼합하면 화미격(和美格)이라 하여 무슨 일이나 조화되며 부귀의 징조이다. 회환격(廻環格)에서와 같이 길할 때는 길사가 거듭되고 흉할 때는 거듭 흉을 이룬다. 구상(舊狀)은 반복된다.
    백법에 이르기를 ｢간과 지상에 패성(敗星)이 있고 낙매(樂埋) 가운데 비(悲)를 생한다｣라고 하였다. 과전이 삼합하면 전국격(全國格)이라 한다. 금(金)국은 종혁격이라 하는데, 병과(兵戈)․금철(金鐵)에 관한 일이 생긴다. 무슨 일이나 갱생개혁(更生改革)하면 길하다. 삼합하여 회극을 이루면 수기(秀氣)가 모이고 길사는 반드시 이루어진다. 재(財)의 기쁨이 있고 결혼은 혼약(婚約)이 이루어지며 모사하면 매우 좋다. 천장 길신은 더욱 길을 보탠다.
    병(兵)에 관한 일은 흉이고 피를 보는 재액(災厄)을 초래한다. 재(財) 혹은 물질이면 귀중품을 얻는다. 옛 것을 버리고 새로운 것을 찾아 다른 직업에 종사해야 한다. 병은 늑골(肋骨) 혹은 폐계(肺系)에서 발생한다. 왕상(旺相)의 기(己)와 길장(吉將)이 합하면 변혁이 있고 부(富)와 귀(貴)를 쟁취한다. 년(年) 혹은 월상신(月上神)과 충하고 사호(蛇虎)가 승하면 사상(死喪)의 일이 생긴다. 만약 일간에 기가 없을 때는 사물은 더욱 퇴보하고 방해가 생긴다. 초전 사(巳)기 유(酉)에 가담하면 전직(轉職) 또는 천전(遷轉)해서 더 펀안하게 된다. 문호개혁(門戶改革)은 불안을 낳는다. 또는 여인과 이별하는 일이 있다. 이 과는 점혼(占婚)을 기(忌)하는데 왕쇠 및 천장의 길휴에 따라서 정단하여야 한다. 일귀는 역행하고 삼전은 귀(貴)․구(勾)․상(常)이며, 야귀는 삼전이 음(陰)․주(朱)․공(空)이다.`
    ,
    // 6
    `   이 과는 양과음신(兩課陰神)이 하로부터 상을 손상시키니 중심과로 되고, 삼전 묘술사(卯戌巳)는 주인격(鑄印格)이다. 그러나 초전․중전이 공망하여 부목(腐木)으로 되니 조각(彫刻)할 수 없다. 그리하여 주인규모(鑄印規模)가 이루어지지 않는다고 한다.
    말전의 사(巳)는 재동(財動)이지만 지반(地盤) 술(戌)은 일간의 관귀로 되기 때문에 옛 것은 귀묘(貴墓)의 지(地)에 있다. 일간일지상신의 신(申)은 수일(水日)의 장생이기 때문에 구태(舊態)를 지키면 흉재를 피면할 수 있다. 과체 사절격(四絶格)은 구사(舊事)를 종결짓고, 귀신이 묘(卯)에 승하면 내전(內戰)이 일어난다. 손윗 존장(尊長)을 나의 이익을 위하여 이용하려고 한다. 재동(財動)이 묘(墓)를 설하면 길흉은 모두 의지할 곳이 없다.
    백법에 이르기 ｢후목(朽木)은 조각하기 힘들다｣고 하였다. 신(申)이 묘(卯)에 가해지면 금극목(金克木)으로 극하여 용(用)을 이루기 때문에 단륜격(斷輪格)이 되어 공명 구직에는 적합하지만 공망이 되므로 년명 묘(卯)가 가해지면 해공(解空)이 된다. 목적은 이루지만 효과는 얻지 못한다. 일귀는 역행하며 삼전은 주․백․귀이고, 야귀는 삼전이 귀․청․음이다. 주인격(鑄印格)의 원칙은 즉 전(傳)에 술(戌)이 사(巳)에 가해지는 것을 말한다. 술(戌)은 천괴(天魁)라 부르고 인수(印綬)라고도 한다. 사(巳)와 태을(太乙)로서 노(爐)를 이룬다. 술(戌) 중에 신금(神金)이 있어 사(巳) 중에 병화(丙火)와 간합(干合)하고, 금은 화련(火煉)되어 귀중한 기물(器物)로 되기 때문에 부인(符印)이라 하고 주인(鑄印)이라 명명한다. 화(火)의 공로에 의하여 관직은 권세를 쥔다. 산(産)을 정단하면 길(吉)이고, 손윗 존장과의 면접(面接)은 좋고 보통 이하의 사람은 불길하다. 길장이 승하면 원조와 은명(恩命)의 기쁨이 있고 , 공망․월파(月破) 및 간지상신이 기(氣)가 없는 것은 파인손모(破印損耗)라고 하여 헛수고로 끝난다.`
    ,
    // 7
    `   이 과는 천지반 충위(冲位)에 있고 반음가색격(返吟稼穡格)이 된다. 과전 중 4개의 정신(丁神) 미(未)에 정신이 승하여 유자격(遊子格)에 해당한다.
    사과삼전은 모두 귀효(鬼爻)로서 중귀로 되며 팔방적중(八方敵中)에 있고, 년명상신 혹은 행년(行年)에서 목신(木神)으로 제(制)하거나 신유(申酉)가 있어 살(殺)이 인(印)으로 화(化)할 때는 중귀(衆鬼)라 할지라도 두려워할 것이 없다. 반음과(返吟課)는 무슨 일에서나 반복이 거듭되고, 가색격은 만사간난(萬事艱難)의 과이다. 여덕(勵德)은 일들이 동요한다. 정신(丁神)은 과전에 있어 조용한 것을 바라지만 조용할 수 없다.
    천을귀신이 묘(卯) 혹은 유(酉)에 임하면 여덕격이라 부른다. 묘유(卯酉)는 음양교차(陰陽交叉)의 위(位)로서 귀신이 변천하기 쉽다. 상중류(上中流)의 사람에게는 길하고 하급인에게는 이롭지 못하다. 현임관(現任官)중 고급인에게는 천관(遷官)이 있고 하급관은 퇴직한다. 모든 일에서 대(大)는 좋고 소(小)는 불리하다. 가정은 동요 진퇴가 반복되고 일정치 않다. 일반인은 몸도 주택도 불안한 사건이 발생하는데 조상에 기도하면 너그러워진다. 일간일지상신의 음신․양신에 천을(天乙)이 승하면 병화가 생긴다.
    백법에 이르기 ｢수일(水日) 정재는 재사(財事)에 의하여 움직인다｣고 하였다. 비전에 이르기를 ｢태상(太常)으로부터 일간을 극하면 연회석상에서 시비(是非)의 건이 발생한다｣고 하였다. 또 질병이나 쟁소송사에서 흉을 초래한다. 일귀는 순행하고 삼전은 주(朱)․상(常)․주(朱)이며, 야귀는 삼전이 상․주․상이다. 가색에 정신(丁神)이 승하면 유자격 또는 오분격(五墳格)이라고도 하여 병단(病斷)하면 좋지 않다. 원래 맹신(孟神)은 은복(隱伏)이 되고, 중신(仲神)은 진퇴를 취급하며, 계신(季神)은 유동을 이룬다. 무슨 일이나 형충파해(刑冲 破害)에 속한다.`
    ,
    // 8
    `   이 과는 상하의 극이 세 곳에 있다. 하에서 상을 극하는 제1과를 사용하여 초전으로 하는 중심과이다. 일간의 재(財)는 일간에 승하여 초전이 된다. 음신의 수(水)는 재를 극하여 처(妻)와 재(財)가 모두 병든다. 중심과는 모두 후로 움직여서 이롭게 되고, 초전에 현무(玄武)가 승하면 재는 협극(夾剋)으로 된다.
    백법에 이르기 ｢전후핍박격(前後逼迫格)은 진퇴가 모두 곤란하다｣고 하였다. 비전에 이르기를 ｢말전의 천후(天后)가 묘상에 있으면 부인(婦人)은 혈기(血氣)의 질환에 걸린다｣고 하였다. 중전 해(亥)는 일지상신의 오(午)를 극하기 때문에 주택으로 인한 다툼이 생긴다. 기회를 보아 이사하는 것이 좋다. 만약 이동하지 않으면5년이 넘지 않아 사망한다. 오(午)의 유신은 심장(心臟)․안목(眼目)․처재(妻財)․가옥(家屋)으로, 좌우 및 중전으로부터 극을 받기 때문에 이런 일이 있다고 간점(看占)하는 것이다. 과전 중에 재(財)가 많더라도 말전의 귀효(鬼爻)를 생하기 때문에 차재(借財)는 하지 말아야 한다. 그렇지 않으면 후난(後難)을 만나게 된다. 일귀는 순행하며 삼전은 사(蛇)․공(空)․후(后)이며, 야귀는 역행하고 삼전 현(玄)․구(勾)․후(后)이다.
    이 과는 간지가 동위로서 팔전과처럼 극을 사용하여 청신으로 한다. 계축일(癸丑日)은 오조(五條) 가운데 있으므로 팔전과로 하지 않는다. 내정(來情)이란 점단(占斷)받는 사건과 사건의 길흉 등을 예지하는 것으로, 그 대부분은 점시(占時)를 선봉문(先鋒門)이라 하여 관전을 만들지 않고도 길흉을 예측할 수 있다. 점시와 일간일지를 서로 대조하여 생극(生剋)․형해(刑害)․충파(冲破)․비화(比和)․상합(相合)에 의하여 길흉을 정한다. 예를 들면 시(時)에서 일간을 충하면 외교적인 동요이다. 혹은 타인의 고민이나 타인의 일을 위탁받아 구하는 사건이다. 만약 일간의 기궁(寄宮)과 시지(時之)가 같으면 일이 지체되거나 타인의 재물 손실 사건이나 외부로부터 화가 닥쳐올 징조가 있다. 초전의 천장에 의하여 이것을 예지할 수 있다.`
    ,
    // 9
    `   이 과는 4과 중 제3과가 하에서 상을 극하여 지반 맹상(孟上)의 극을 초전으로 하는 섭해과(涉害課)이다. 삼전 사유축(巳酉丑) 삼합금(金)국은 종혁격으로 일간을 생하며, 일지는 토생금(土生金)으로 탈기(脫氣)한다. 즉 사람은 왕성하고, 가색은 설기(泄氣)하여 좁다. 삼전 삼합은 중인(衆人)이 되며, 일간을 생하는 것은 중인의 천거․원조․찬양을 받는다. 사관(仕官)을 정단하면 재(財)․관(官)․인(印)의 삼귀신(三貴神)이 되고, 사관(仕官)하여 진달(進達)한다.
    맹신의 극을 초전으로 하면 견기격(見機格)이 된다. 무슨 일이나 지체하지 말고 기회를 보고 전진해야 한다.
  종혁(從革)은 낡은 것을 버리고 새것을 취하는 것이 좋고, 또 변천하는 일이 있다. 말전에서 중전, 중전에서 초전, 초전에서 일간을 생하면 체생격(遞生格)이 된다. 
    백법에 이르기 ｢삼전에 체생(遞生)하면 다른 사람의 천거를 받는다｣고 하였다. 과전의 재효(財爻)는 삼재로 되어 재는 나의 집이나 내 손에 들어온다. 일귀는 순행하고 삼전은 구(勾)․상(常)․귀(貴)이며, 야귀는 공(空)․음(陰)․주(朱)이다.
    섭해과는 풍파가 험악하고 곤란한 과로서 제반사(諸般事)는 출비(出費)가 높다. 결혼은 방해가 있고 질병은 진퇴한다. 잉태는 모두 지체되고 대인은 아직 오지 않는다. 천장의 길흉에 따라서 논단하여야 한다. 만사는 급하게 개정하면 좋고 주저할 때는 손실을 본다. 모든 것은 채산(採算)하면 이기고, 채산하지 않으면 진다. 사맹(四孟)은 시령(時令)의 처음으로 일계(一季)의 기후가 모두 배태(胚胎)되기 때문에 일이 시작되고 길흉을 간직한다. 옛 것을 지키는 일은 지체되고 모든 일이 잘 풀리지 않으므로 신속하게 개변해야 한다. 경솔하지 않고 신중하게 대처하며 평안을 도모함으로써 위험을 피하여야 한다. 무슨 일이나 기회를 보고 성사(成事)시키는 것이 좋다.`
    ,
    // 10
    `   이 과는 일상(一上)이 일하(一下)를 극하고 진축(辰丑)을 초전으로 하는 원수과로서 삼전계신가색격(三傳季神稼穡格)이다.
    계(癸)의 일간은 과전이 모두 관귀중적(官鬼衆敵)이 되어 년명상신과 인묘(寅卯)의 제신(制神)이 없으면 흉해를 면할 수 없다. 그러나 원수과는 이성순(理性順)으로 흉이라 하지만 경하며, 초전이 육의(六儀)이면 흉을 구(救)하니 길사의 정단은 희경(喜慶)이 있다. 미(未)에 정신(丁神)이 승하면 유자격으로서 움직이는 암시이다. 말전에 백(白)․청(靑)이 승하여 최관부로서 사관․구직은 아주 신속하게 목적을 달성한다. 진술(辰戌)이 충(冲)하면 참관(斬關)에 통하고 또한 개묘(開墓)로 되며, 흉으로 흉을 제하므로 해구(解救)의 징조가 있다. 먼저 흉이라 할지라도 후에는 길로 변화한다.
    백법에 이르기 ｢일야귀신(日夜貴神) 공망의 지(地)에 있다｣고 하였다. 따라서 기뻐하던 일은 허사가 되기 쉽다. 간묘병관(干墓倂關)하면 인택(人宅)이 모두 쇠약한다. 비전에 이르기를 ｢참관․유자격은 몸이 움직이는 상태에 이르고, 지상(支相)에 간(干)의 묘를 보고 일간을 극하면 가택에서 부상․재해를 초래하는 일이 있다｣고 하였다. 행년을 극하면 재(災)가 빨리 온다. 일귀는 순행하며 삼전은 후․주․청이며, 야귀는 삼전이 동․구․백이다.`
    ,
    // 11
    `   이 과는 4과 중 제3과의 묘축(卯丑)의 상극을 초전으로 하는 원수과이다. 삼전은 묘사미(卯巳未)이고 양지(陽支)의 신뿐이므로 영양격(盈陽格)이라 칭한다. 말전에는 정재(丁財)가 승한다. 중전 사(巳)는 재성(財星)에 해당되지만 공망한다. 재(財)는 원방의 송금(送金)을 받는 재(財)이다. 원수과는 모든 일이 순조롭고 방해가 없다.
    백법에 이르기를 ｢양귀신(兩貴神)이 다 공망하면 희사(喜事)는 다 헛되이 돌아가고, 삼전 묘사미(卯巳未)가 일지(一地)를 건너 삼전을 이루면 순간전격(順間傳格)이 된다. 묘사(卯巳)는 2양이고 미(未)는 음의 시작이니 양은 이미 찬다｣고 하였다. 사물이란 매우 반복되기 쉽기 때문에 무슨 일이나 빨리 행하며 길하고, 늦어지는 흉조를 룬다. 일귀는 순행하고 삼전은 음․귀․주이며, 야귀는 귀․주․구이다. 계(癸)에서 묘(卯)는 십이운 장생(十二運長生)이지만 공망에 해당하여 생하고도 생하지 않는다.
    잉태한 태아의 성별 구분법은 일간과 점시지반(占時地盤)이 양일양비(陽日陽比)․음일음비(陰日陰比)이면 남아라고 보고, 음양이 모두 비(比)가 되지 않으면 여아를 잉태한 것이다. 예를 들어 계일(癸日)로 점시를 오(午)로 가정하고, 천지반을 보면 천반 오(午)의 지반(地盤)은 진(辰)의 양(陽)이다. 오늘은 계(癸)로 음이기에 일간과 비(比)를 이루지 못하므로 여아라고 보아야 한다. 또 지두법에 의한 천강(天罡)이 양에 가해지면 남아이고, 음에 가해지면 여아라고 본다. 천강법(天罡法)은 잉태일뿐만 아니라 내정(來情) 및 남녀 여부를 예지하는데도 적용된다. 육임점과(六壬占課)의 원칙은 동일한 간법(看法)이라도 법칙을 달리하는 경우들이 많다. 그러나 어느 것을 사용하든 자기가 믿는 법칙을 사용하는 것이 좋고 일반적으로 병용치 않는다.`
    ,
    // 12
    `   이 과는 4과 중 제3과 인축(寅丑)이 일상에서 일하를 극하여 초전이 되는 원수과이다. 사과삼전이 모두 공망이 되어 길흉을 이루지 않는다. 그러나 탈공(脫空)은 자식모도(子息耗盜)의 일이 있고, 병을 정단하면 필연적으로 허탈(虛脫)의 증상이 되어 생사의 경계를 이루게 된다. 원수과는 남자에게 일어나는데, 거동(擧動)은 먼저 취하는 것이 좋다. 진여격(進茹格) 공망은 스스로 퇴보하는 방침을 채용하는 것이 좋은데, 만사는 형체가 없으며 우환(憂患)은 오히려 소산된다.
    백법에 이르기를 ｢진여 공망은 퇴보하는 것이 좋으며, 탈상탈(脫上脫)을 만나면 허사(虛詐)로서 모든 것은 공론(空論)이요, 빈말 뿐이다｣라고 하였다. 
    비전에 이르기를 삼전이 순자손효(純子孫爻)로 되면 재를 구하지 않아도 재가 스스로 온다. 그러나 공망이 되면 현실(顯實)이 없으니 공망을 탈출해야 공적이 있다｣고 본다.
    일귀는 순행하며 3전은 현(玄)․음(陰)․후(后)이고, 야귀는 삼전이 후(后)․귀(貴)․사(蛇)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
