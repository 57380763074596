
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`고집`,7,7,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,8,6,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지  않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`신뢰`,9,5,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`위선`,10,4,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,11,3,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`신뢰`,12,2,`특별있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,1,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,2,12,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`신뢰`,3,11,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,4,10,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,5,9,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,8,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`직녹`,`직위승급 관련사`,`小`,``,``,``],
    [`권리`,`권리 관련사`,`小`,`1`,`화합사有`,`색정사有`],
    [`색정`,`내女 색정사 有`,`大`,`절정기`,`경의불녕`,`매사성사`],
    [`처위잉희(妻爲孕喜)`,`처가 잉태하여 기쁜 상-단 불일치`,`大`,``,``,``],
    [`송흉문길(訟凶文吉)`,`소송사는 나쁘고 문서 소식사는 좋음`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`막힘`,`외사 외교 막힘사`,`大`,`2`,`외교막힘`,`동요분산`],
    [`남자`,`가정 동요분산사`,`小`,`면모기`,`굴욕반복`,`와병불기`],
    [`만상회춘(萬象回春)`,`여러 일이 회복되는 상-단 윗인의 심정이 일정하지 못함`,`小`,``,``,``],
    [`구설변경(口舌變驚)`,`놀란 구설사가 변하여 회복되는 象- 단 병은 불기(不起)함`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`3`,`금전다툼`,`압박받음`],
    [`자식`,`자식 관련사`,`小`,`학습기`,`소인쟁재`,`비곡차욕`],
    [`출입다노(出入多勞`,`강제급속손실사-나쁜사태를 방비해야 함`,`大`,``,``,``],
    [`소인쟁재(小人爭財)`,`대등하지 못한 자와 금전을 다툼`,`小`,``,``,``],
    [`박관`,`직업직위불안사`,`大`,``,``,``],
    [`극남(剋男)`,`남자갈등사`,`小`,`4`,`발탁영전`,`단합결속`],
    [`결속`,`개신하여 음8월 후원사를 크게 이루나 자식재해 발생함`,`小`,`개시기`,`事후쟁탈`,`복잡다단`],
    [`화촉화귀(火燭火鬼)`,`불조심 해야 하는 상`,`大`,``,``,`문서상신`],
    [`천발천약(薦拔薦躍)`,`추천 발탁 승진 영전의 상`,`小`,``,``,`답신지체`],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업사`,`大`,`5`,`파재극처`,`비용과출`],
    [`파재`,`외 파재극처사-실종사 우려`,`小`,`배양기`,`실종우려`,`환성경사`],
    [`비용부족(費用不足)`,`크게 움직이는 것은 불리하고 비용도 부족한 상`,`小`,``,`도적실세`,``],
    [`문서지체(文書遲滯)`,`제출한 헌책문서가 지체되고있는 상-단 구설은 그침`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`6`,`모망난성`,`출행이동`],
    [`출행`,`내사 출행원행이동사`,`小`,`존재기`,`투서헌책`,`진직영전`],
    [`모망난성(謀望難成)`,`바라는 일은 안 되고 병은 흉하고 조문객은 없는 상`,`小`,``,`절제요됨`,``],
    [`불의투서(不宜投書)`,`투서헌책은 안 좋고 구설은 구체성 없고 재물을 잃을 상`,`小`,``,``,``],
    [`금전`,`금전사`,`大`,``,``,``],
    [`사업`,`사업사`,`大`,`7`,`추천영전`,`상하화합`],
    [`색정`,`외男 색정사 有`,`小`,`단절기`,`색정사有`,`손해피로`],
    [`목손필노(目損必勞)`,`눈앞 손해로 피로하고 처 관련사와 재물사도 안 좋은 상`,`小`,``,`불변근신`,`음신대기`],
    [`추천발탁(推薦拔擢)`,`추천발탁되어 영전하고 자리를 옮기는 상`,`小`,``,``,`와도나쁨`],
    [`관사`,`관사 관련사`,`小`,``,`천발도약`,``],
    [`직업`,`직업 관련사`,`大`,`8`,`관직직책`,`가정막힘`],
    [`개신`,`개혁으로 음8월 후원사를 크게 이루나 자녀재해 발생함`,`小`,`침체기`,`상위변경`,`간인궤계`],
    [`관인변현(官印變顯)`,`관직 도장이 바뀌는 길사 상-일반은 안 좋으니 謀事 금함`,`小`,``,`동요불안`,``],
    [`주유형책(主有刑責)`,`형벌 책망을 입을 상`,`大`,``,``,``],
    [`후원`,`후원조력부친 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`9`,`비곡차욕`,`강제급속`],
    [`매사 영속성 없음`,`후원조력문서부친사는 선천적 기복재해를 겪음`,`大`,`소외기`,``,`손실사有`],
    [`폐구면피(閉口免被)`,`입을 닫아야만 능욕과 학대를 면하는 상`,`小`,``,``,`소인쟁재`],
    [`비곡수욕(悲哭差辱)`,`용모 훼손을 무시하고 수치심으로 슬피우는 상-훼절`,`大`,``,``,``],
    [`후원`,`후원조력문서모친 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`10`,`개신개변`,`간인궤계`],
    [`매사 영속성없음`,`후원조력문서모친사는 선천 적 기복재해를 겪음`,`大`,`침해기`,`가택이전`,`하당허사`],
    [`가족질병(家族疾病)`,`가족 중 질병이 있어 시달리는 상`,`小`,``,`가족질병`,``],
    [`불변근신(不辯勤愼)`,`장차를 위해 말을 삼가하고 행동을 조심해야 할 象`,`大`,``,``,``],
    [`관사`,`관사 관련사사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`11`,`급속송쟁`,`반목대립`],
    [`남편`,`남편 관련사`,`小`,`쇠퇴기`,`파재실종`,`소송사有`],
    [`음일지사(淫佚之事)`,`색정사가 여러 번이나 뚜렷하지(暗昧) 못한 상`,`小`,``,`상해우려`,`충산분산`],
    [`충산분산(冲散分散)`,`부딛혀 비늘이 벗겨지는 격이 되어 흩어지는 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`小`,`12`,``,``],
    [`출행`,`출행이동사`,`大`,`과시기`,`존비상몽`,`손괴재물`],
    [`화환빈출(禍患頻出)`,`화환이 계속되는 상-관직자는 면 함`,`大`,``,``,``],
    [`존비상몽(尊卑相夢)`,`신분과 나이가 차이가 나는 어린 여자와 동침하는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,`직녹자산권리색정조력사로 가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`직업외사공허가정동요사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위불안남편갈등강제급속손실사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직위불안남편갈등여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`관사직업파재극처사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,`금전사업처첩조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,`금전사업원행출행사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`관사직업동요분산가정막힘사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사송쟁사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,`형제자매출행왕래사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 세**만약 타인과 다툰다면  위세를 꾸며 공격해도 상대가 적중하지 않음으로 되려 불리함`,0,0,`상대를 공격하는 일이나 목적계획사가 적중되지 않아 되려 불리해지는 人 `,0,0,`현재의 상황에서 물러나야 함**물사가 돌연히 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면  위세를 꾸며 공격해도 상대가 적중하지 않음으로 되려 불리함`,0,0,`상대를 공격하는 일이나 목적계획사가 적중되지 않아 되려 불리해지는 人 `,0,0,`일이 순리대로 되지않고 역상적으로 진행됨**좋은 일이나 나쁜 일이 모두 해산됨**관공직자는 자리를 옮기게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 속전속결해야만 유리함`,0,0,`현재 상황을 속히 결정하지 못하고 우물쭈물하거나 뒤로 미루면 불리해지는 人 `,0,0,`토지관련사**어디 깊은 산으로 숨고싶은 심정임**꼬리가 머리로 된 상으로 위가 아래로부터 핍박을 당함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정이 풀리지 않으므로 신중해야 하고 방심하면 불리해지며 후에 행동해야 함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 하나로 묶는 개신개혁사 有**일이 결과없이 반복됨**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화속결**만약 타인과 다툰다면 순응하고 유화적 처신으로 일을 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 맞서지 말고 순응유화적 자세를 보이면 유리한 결과가 됨`,0,0,`관직자는 영전함**병과 임신은 나쁨**관재우려**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있어 천지가 뒤집힌 상이므로 재난이 밖에서 오므로 신중해야 함`,0,0,`배신사로 반복되는 동요사에 해당됨`,0,0,`토지관련사**꼬리가 머리로 된 상으로 위가 아래로부터 핍박을 당함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의심질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`관공직자는 영잔함**육친빙탄-육친 어른이 아래 혈육으로부터 핍박을 당함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정이 풀리지 않으므로 신중해야 하고 방심하면 불리해지며 후에 행동해야 함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 하나로 묶는 개신개혁사 有**일이 결과없이 반복됨**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 속전속결해야만 유리함`,0,0,`현재 상황을 속히 결정하지 못하고 우물쭈물하거나 뒤로 미루면 불리해지는 人 `,0,0,`토지사관련 소송사有**어디 깊은 산속으로 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면  위세를 꾸며 공격해도 상대가 적중하지 않음으로 되려 불리함`,0,0,`상대를 공격하는 일이나 목적계획사가 적중되지 않아 되려 불리해지는 人 `,0,0,`일이 토하는 쪽으로 가나 되려 진행하게 됨**결과없이 반복되지만 계속하면 밝아짐**현재 나쁜 일이 가득찬 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)-속임**만약 타인과 다툰다면 본인의 숨기는 일이 탄로날 염려가 있으므로 정면대결을 피해야 함`,0,0,`타인의 눈을 가리는 상으로 기구한 형편에 놓여 있고 진퇴의지거처가 없는 처지의 人`,0,0,`본인의 허물과 약점을 가리는 입장임**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**민약 타인과 다툰다면 상대는 기회를 노리고 움직이지 않음`,0,0,`자기 스스로를 유약하게 여기고 활동을 접고 신음하는 입장임**신체가 강제적으로 부자유한 경우도 있음`,0,0,`토지송쟁사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타바동사`,`기사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성-무력`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`좌고우면-무력`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아타동시발동사`,`미사`,`구사`,`외내동시사`,`표면이면동일사`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈 인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용나감`,`이발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신`,`보성-무력`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사-후회사겹침`,`기사`,`구사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥-쟁송`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,3,3,6,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음1월`,2,2,4,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,3,2,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음1월`,2,3,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음12월`,2,1,3,`음7월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월-연기 또는 파혼우려`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음7월-연기 또는 파혼우려`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음6월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,1,1,2,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음2월`,1,1,2,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음1월`,1,2,3,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 업무를 거꾸로 진행하는 형태를 보여 초반 이외는 수준이하가 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 말을 하며 업무도 일반 이하임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 권위를 세우나 전반적 침체가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 개신개혁으로 결속하여 효과를 크게 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 소이는 경향이 있고 권리행사에 장애가 있게되어 종반에서야 실적을 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무 역시 반목대립하여 시종 쟁송사를 벌임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 권위를 세우며 권리행사로 업무실적이 빛남`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔하한 것같으나 상대를 잘 조정하여 결속단합개혁을 시도하나 미와에 그침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 어무는 반목대립적이며 토지문제로 시종 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무 역시 수준 이하 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 업무는 시종 수준이하 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 말을 하며 업무도 반목대립하여 시종 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선압박후`,`進向`,`원`,`留`,`주인후원`,`북북동`,``,`정동`],
    [`10.11`,``,`본인제합`,``,`후不`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`불가`,`退背`,`  원`,`   去`,`세입가해`,`남남서`,``,`동남`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`전면반대`,`이행`,`불가`,`退背`,`  원`,`   去`,`무난`,`정동`,``,`동남`],
    [`10.11`,``,`제압안됨`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`多集`,`폐기요됨`,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`이행`,`불가`,`退背`,`  원`,`   去`,`주인가해`,`동남`,`남남서`,``],
    [`10.11`,``,`함`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`본인무력`,`退背`,`  원`,`   去`,`주민가해`,`남남동`,`정남`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`전면반대`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압안됨`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`상호동요`,`정남`,`북북서`,``],
    [`10.11`,``,`폐지`,``,`빠르게`,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,`예정`,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`권리양도`,`退背`,`  근`,`   去`,`세입가해`,`정북`,`정북`,``],
    [`10.11`,``,`본인가세`,``,`불가`,``,``,``,`상호증오`,``,``,``],
    [`3.6\n9.12`,``,`설득제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대있음`,`이행`,`불가`,`進向`,`  원`,`  留`,`세입가해`,`북북동`,`북북동`,``],
    [`10.11`,``,`무력`,``,``,`지구전`,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`전면반대`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압안됨`,`이행`,`불가`,`退背`,`  근`,`   去`,`상호반목`,`동북`,`동남`,``],
    [`10.11`,``,`폐지`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`사안상대`,`退背`,`  근`,`   去`,`주인무력`,`정동`,``,`정북`],
    [`10.11`,``,`본인제압`,``,`무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`사안상대`,`退背`,`  원`,`  去`,`주인무력`,`동남`,``,`북북동`],
    [`10.11`,``,``,``,`무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`전면반대`,`이행`,`불가`,`退背`,`  근`,`去`,`무난`,`정북`,``,`동북`],
    [`10.11`,``,`제압못함`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,`폐지`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,`도로귀`,`교통사`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,0,`없음`],
    [``,`제례귀`,`제례위범`,0,0,`묘향`,0,``],
    [`생업`,`여귀`,`부인사망`,"목기부정","목물반입",`전경`,0,``],
    [``,``,``,`수망부정`,`水亡`,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`특별있음`],
    [``,`제례귀`,`제례위범`,0,0,`묘향`,0,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`제례귀`,`제례위범`,0,0,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정` ,`조문왕래`,`육친`,0,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`부인사망`,0,0,`묘좌`,0,`있음`],
    [``,`변사귀`,`객사`,0,0,`묘향`,0,``],
    [`생업`,`가장귀`,`호주사망`,`수망부정`,`水亡`,`전경`,0,``],
    [``,`여귀`,``,0,0,`묘속`,0,``],
    [`가정`,`가장귀`,``,"목기부정","목물반입",`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`음귀`,`묘지탈`,0,0,`묘좌`,`남남서`,`있음`],
    [``,`전흉사귀`,`혈광사`,0,0,`묘향`,`북북동`,``],
    [`생업`,`전사귀`,`전사`,0,0,`전경`,`향대`,``],
    [``,``,``,0,0,`묘속`,`매몰`,``],
    [`가정`,`전사귀`,`전사`,0,0,`육친`, `조모`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사 출행이동원행사`,`상위는 기쁨, 하위는 수도자 격`,`직위녹위사`,`外남녀화합사**내女색정사`,`외사 막힘-이동사`,`상하귀천남녀난사이동사`,`외사 급속사`,`느긋-한가`,``,`관계불통-가택이사-가족병질사`,`파재극처사-실종사 우려`,`매사위역사`,``,`내사 출행이동원행사`,`외男색정사`,`사사성취사`,``,`외 충산사-내 막힘사`,`매사허성불성**세속허사 **충산자`,`음3,6,7,8,9,12월 방문자는 종교진출로`,`매사 영속성 없음`,`종교진출하면 자립함`,`매사위역사**침체경구사`,`외내 급속송쟁사-상해우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`재액해소`,``,`재액해소`,``,`전화위복`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`가정막힘`,`내사 급속사`,`성공함`,``,``,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외내 급속송쟁사-상해우려`,`소인쟁재사`,`외사 출행이동원행사`,``,`직위녹위사`,`필유성취사**外남녀화합사*내女색정사`,`외사 막힘-이동사`,`상하귀천남녀난사이동사`,`외사 급속사`,`진취쇠약-감춰쌓고 퇴함이 좋음`,``,`관계불통-가택이사-가족병진사`,`파재극처사-실종사 우려`,`비곡차욕`,``,`내사 출행이동원행사`,`외男색정사`,``,`외 충산사-내 막힘사`,`희경사-단, 부족`,`매사영속성없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,``,`재액해소`,``,`제엑해소`,``,`전화위복`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,`가정막힘`,``,`내사 급속사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`매사영속성없음`,`종교진출하면자립함`,`외내 급속송쟁사-상해우려`,`소인쟁재사`,``,`외사 출행원행이동사`,`직위녹위사`,`필유성취사**外남녀화합사**내女색정사`,`외사 막힘-이동사`,`상하귀천남녀난사이동사`,`외사 급속사`,`진취쇠약-감춰쌓고 퇴함이 좋음`,``,`관계불통-가택이사-가족병질사`,`파재극처사-실종사 우려`,`비곡차욕`,``,`내사 출행원행이동사`,`외男색정사`,``,`외 충산사-내 막힘사`,`희경사`,`매사영속성없음`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`전화위복`,``,``,``,``,``,`전화위복`,``,`재액해소`,``,`재액해소`,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,`가정막힘`,``,`내사 급속사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**事후쟁탈사`,`종교진출하면자립함`,`외내 급속송쟁사-상해우려`,`매사위역사`,``,`외사 출행이동원행사`,`비상지애사**外남녀화합사**내女색정사`,`직위녹위사`,`남 모르 는 능욕박해사`,`외사 막힘-이동사`,`외사 급속사`,`환성적 경사`,``,``,`매사위역사`,`파재극처사-실종사 우려`,`내사 출행이동원행사`,`발탁영전도약희사`,`탄식신음-병 또는 목적사 미수`,`외男색정사`,`외 충산사-내 막힘사`,`가정막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 급속사`,``,``,``,``,`전화위복`,`재액해소`,``,`재액해소`,``,``,`전화위복`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`외 충산사-내 막힘사`,`가정막힘`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**事후쟁탈사`,`종교진출하면자립함`,`외내 급속송쟁사-상해우려`,`매사위역사`,`외사 출행이동원행사`,``,`비상지애사**外남녀화합사**내女색정사`,`직위녹위사`,`남 모르는 밤의 능욕박해사`,`외사 막힘-이동사`,`외사 급속사`,`환성적경사`,``,``,`매사위역사`,`파재극처사-실종사 우려`,`발탁영전도약희사`,`내사 출행원행이동사`,`탄식신음-병 또는 목적사 미수`,`외男색정사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`내사 급속사`,``,``,``,``,`전화위복`,``,``,``,``,``,`전화위복`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`외男색정사`,``,`외 충산사-내 막힘사`,`가정막힘`,`혼인사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`소인쟁재사`,`외내 급속송쟁사-상해우려`,`외사 출행이동원행사`,``,`外남녀화합사**내女색정사`,`직위녹위사`,`승진영전사`,`외사막힘-이동사`,`도적-알고찾아가도 못잡음`,`외사 급속사`,`조행불량사`,``,`비곡차욕`,`파재극처사-실종사 우려`,`발탁영전도약희사`,`내사 출행원행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`음4,5월 화재주의`,`동요불안분산사`,``,`동요불안분산사`,`내사 급속사`,`동요불안분산사`,``,`동요불안분산사`,`전화위복`,`동요불안분산사`,``,`동요불안분산사`,``,`전화위복`,`동요불안분산사`,`재액해소`,`동요불안분산사`,`재액해소`,`동요불안분산사`,`동요불안분산사`,``,`동요불안분산사`,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`발탁영전도약희사`,`내사 출행이동원행사`,`외男색정사`,``,`외 충산사-내 막힘사`,`가정막힘`,`혼인사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`소인쟁재사`,`외내 급속송쟁사-상해우려`,`외사 출행원행이동사`,``,`外남녀화합사**내女색정사`,`직위녹위사`,`승진영전사-단 부족`,`외사 막힘-이동사`,`도적-알고찾아가도 못잡음`,`외사 급속사`,`조행불량사`,``,`비곡차욕`,`파재극처사- 실종사 우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`내사 급속사`,``,``,``,``,`전화위복`,`재액해소`,``,`재액해소`,``,`전화위복`,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`비곡차욕`,`파재극처사-실종사 우려`,`발탁영전도약희사`,`내사 출행원행이동사`,`외男색정사`,``,`외 충산사-내 막힘사`,`가정막힘`,`혼인사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`소인쟁재사`,`외내 급속송쟁사-상해우려`,`외사 출행원행이동사`,``,`外남녀화합사**내女색정사`,`직위녹위사`,`승진영전사-단, 부족`,`외사 막힘-이동사`,`도적-알고찾아가도 못잡음`,`외사 급속사`,`조행불량사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재액해소`,`음4,5월 화재주의`,`재액해소`,`음4,5월 화재주의`,``,``,`내사 급속사`,``,``,``,``,`전화위복`,``,``,``,``,`전화위복`,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`조행불량사`,``,`비곡차욕`,`파재극처사-실종사 우려`,`발탁영전도약희사`,`내사 출행원행이동사`,`외男색정사`,``,`외 충산사-내 막힘사`,`가정막힘`,`혼인사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`소인쟁재사`,`외내 급속송쟁사-상해우려`,`외사 출행원행이동사`,``,`外남녀화합사**내女색정사`,`직위녹위사`,`승진영전사-단, 부족`,`외사 막힘-이동사`,`도적-알고찾아가도 못잡음`,`외사 급속손실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`내사 급속사`,``,``,``,``,`전화위복`,``,``,``,``,`전화위복`,``,`제엑해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,``,`머리단장적 느긋함`,``,`관계불통-가택이사-가족병질사`,`파재극처사-실종사 우려`,`매사위역사`,``,`내사 출행원행이동사`,`외男색정사`,`사사성취사사사성취사`,`외 충산사-내 막힘사`,`가정막힘`,`매사영속성없음**충산자`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`매사위역사`,`외내 급속송쟁사-상해우려`,`외사 출행이동원행사`,`상위는 기쁨, 하위는 수도자 격`,`外남녀화합사**내女색정사`,`직위녹위사`,`외사 막힘-이동사`,`상하귀천남녀난사이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`내사 급속사`,`가정막힘`,``,``,``,`전화위복`,`재액해소`,``,`재액해소`,``,`전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외사 막힘-이동사`,`상하귀천남녀난사이동사`,``,`머리단장적 느긋함`,``,`관계불통-가택이사-가족병질사`,`파재극처사-실종사 우려`,`매사위역사`,``,`내사 출행이동원행사`,`외男색정사`,`사사성취사`,`외 충산사-내 막힘사`,`가정막힘`,`매사영속성없음**충산자`,`종교진출하면자립함`,`매사허성불성**세속허사 자`,`음3,6,7,8,9,12월 방문자는 종교진출로`,`매사위역사`,`외내 급속송쟁사-상해우려`,`외사 출행원행이동사`,`상위는 기쁨, 하위는 수도자 격`,`外남녀화합사**내女색정사`,`직위녹위사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`외사 급속사`,``,``,``,``,``,`전화위복`,`음4,5월 화재주의`,`재액해소`,`음4,5월 화재주의`,`재액해소`,``,``,`내사 급속사`,`가정막힘`,``,`성공함`,``,``,`전화위복`,`폐한인`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`직위녹위사`,`外남녀화합사**내女색정사`,`외사 막힘-이동사`,`상하귀천남녀난사이동사`,``,`머리단장적 느긋함`,``,`매사위역사`,`파재극처사-실종사 우려`,`관계불통-가택이사-가족병질사`,``,`내사 출행이동원행사`,`외男색정사`,`사사성취사`,`외 충산사-내 막힘사`,`가정 막힘`,`매사허성불성**세속허사** 충산자`,`음3,6,7,8,9,12월 방문자는 종교진출로`,`매사허성불성**세속허사 자`,`음3,6,7,8,9,12월 방문자는 종교진출로`,`매사위역사`,`외내 급속송쟁사-상해우려`,`외사 출행이동원행사`,`상위는 기쁨-하위는 수도자 격`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`전화위복`,`재액해소`,`외사 급속사`,`재액해소`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`내사 급속사`,`성공함`,``,`성공함`,``,`전화위복`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 땅문제가 한 두가지가 아니겠는데 하극상 관재를 피할길이 없네! 
온통 귀신이라 귀신 이름도 못세겠어! 흉은 흉으로 제압해야 살아나게 되지!
혈광흉사와 산소탈이네 ?
⊕⊖전사귀․전흉사귀․음귀침범했어 !
`,`■ 큰 횡재를 하게 생겼네? 돈이 커!
(부부띠 위에 未나 胎가 있으면 처가 반드시 임신한다.)
그러나
⊕⊖도로귀 발동은 막아야지!
`,`■ 부부가 각기 딴 마음이라 이별수가 보여! 
상대를 공격해도 적중이 안되겠어!

⊕전사귀, 
⊖변사귀 침범을 해소하면    돈은 들어오겠어!
`,`■ 땅으로 관재가 붙는데 귀신이 사방에 깔려서 이름조차 셀 수가 없어! 해소해야 길이 열려 ! 돈 고생, 병고생, 관재고생, 고생문뿐이야!
⊕전․흉사귀․음귀․도로귀․장충귀
⊖가택(夫)귀․신불제사․여(부인)귀
⊕가택음사부정이 들었다.
⊕신변 상가부정 침범함
⊖신변 목기부정 침범했네 ?
`,`■ 일이 반복되다가 개혁해서 八月에 길이 뚫리겠어. 가장이 밖에서만 도는데

⊕전사귀, 
⊖변사귀 발동으로 그래!
`,`■ 도장 팔 칼은 있는데 
   나무가 썩은 격이야! 
   무리하면 관재 근심이
   생기겠어 ! 그리고
⊕전사귀, 
⊖가택귀가 문제네?
⊖가택음사부정이 들었다.

⊖신변상가부정이 들었어!
`,`■땅 구설ㆍ관재가 있겠는데 여자가 끼어있어! 
골육간에도 등을 돌렸으니 돈으로 해결하는 수밖에 없겠어! 
하극상 배신인데 사방에 널린 귀신 장난 때문이야 !
⊕⊖변사귀․신불제사귀가      내외에 가득해 !
`,`■가택권리가넘어가네? 가정내부 돈 ․여자 문제로 의심 질투가 있고 두가지 중에서 하나를 선택해야 될 기로에 섰어! 
육친골육이 등을 돌렸고! ⊕⊖가택귀 장난인데 해소      시키면 좋아져!
⊕가택상가부정이 들었다.

⊕신변 음사부정 침범했네?
`,`■불화도 있고 가세가 기울겠는데
방침과 계획을 확 바꿔버려야 八月에 성공이 커 !

⊕신불제사귀, 
⊖음귀를 해소하면 가을에    일거에 뜻을 이루겠어! 


`,`■오나가나 땅이 문제겠네? 관재인데 사방에 귀신
⊕여귀․변사귀․가택(夫)귀,⊖도로․장충․전사귀․전흉     사귀가 넘보고 집에는
⊕가택목기부정, 
⊖가택상가부정까지들어서 입을 다물어야 그나마 빠져나가겠어!
⊕신변 수망노부부정 침범
⊖신변 음사부정 침범했네?
`,`■지금 놀래는건 호가호위야! 빈총으로 공격해 봐야 효과도 없겠고, 
⊕변사귀, 
⊖전사귀를 해소시켜야 
  그나마 조용히 끝나고 살길이 열리겠어!
`,`■굴 하나에 뱀이 두 마리 격이니 진퇴거처가 불안하겠어! 
숨기는 음사도 있겠고! 
두달이나, 2년 더 고생해야 길이 열리겠어!`];
const YOGYEOL1 = [`■ 분수밖의 욕심을 내면 마침내 갇히게 된다. 요행히 재물을 얻어도 또 다시 욕심을 내서는 안된다.
`,`■ 상대나 상하나 모두가 화목하다. 재물이 재수점은 되어 있는 것을 가서 가져오면 된다. 
`,`■ 과전이 5음이니 집에 여자만 사는 것 같다. 귀인은 이랬다 저랬다 한다. 역마가 많으니 움직여 자금을 획득한다.
`,`■ 내가 상대에게 가서 구하니 결정은 상대가 한다. 어려운 일을 당하면 입을 다물어야 화를 면한다.
`,`■ 삼전에 돕는이가 많으니 낮정단은 더욱 좋다. 관직도 얻고 도장도 얻었다. 그러나 일반인은 도리어 어렵다.
`,`■ 돕는 것은 숨고 도적은 나섰다. 귀인은 많아도 의지하지 못한다. 썩은 나무에 도장을 못파고 여자나 재물의 덕 보기도 어렵다.
`,`■ 관살과 정마가 함께 있으니 이것들이 임하여 좋지 않다. 남몰래 돈을 주고 받으면 송사시비가 끝나리라.
`,`■ 피차 헐뜯다가 나중에는 화순하게 된다. 숨은 누가 재물을 도우니 원수끼리 혼인을 맺은 것 같다.
`,`■ 사람은 잘되나 집은 그저그러니 나는 복되고 상대는 화가 있다. 낮정단은 하늘이 도와 내게 모두 좋은 일만 있게 된다.
`,`■ 내 주변이 다 좋지 않으니 매사를 신중하게 해야 한다. 입만 다물고 있으면 창피당하는 것은 면하게 될 것이다.
`,`■ 사과삼전이 모두 아래에서 위에 힘을 뺏기니 뿌리는 잘리고 샘물은 마른다. 모든 계획은 이와 같아 나쁘다.
`,`■ 낮정단은 도적이 두렵고 전부 비어 없으니 지키거나 버리기가 어렵다. 내몸 또한 부평초 신세이다.`]
const SIBO1 = [`■ 土地事 송사ㆍ폐색이다.
자충수적 우환이 있다.
가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
재화(災禍)가 신속하다.
여자나 재를 탐하지 마라. 災가 된다. 
관송사가 있다. 
外事를 움직이지 않을 수 없다.
유약심을 버려야 한다.
`,`■ 시의반목ㆍ암중재해ㆍ
관사구설 있다.
七月에 임신사가 있다.
上人이 있으나 신뢰받지 못한다.
一人으로 모시라.
일의 전진이 안된다.
화합중 불화로 이합집산한다. 
홀연히 일이 생긴다. 
구재 문서는 좋다. 
丑ㆍ未띠 행년은 임신한다.
`,`■ 계획은 위험하다.
가정이 문란하다.
금전ㆍ여자 문제는 양호 大利이나 他事는 후퇴 방침이 좋다.
모든 일이 어둡다.
부부 반목한다. 
財와 女와 충돌한다. 
기다리면 해소된다.
원지에서 송금 소식이 있다.
`,`■ 자충수적 우환이 기한다.
관송사가 있다.
토지사에 관련된다
가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
하극상ㆍ財부족ㆍ대립반목사가 있다.
입조심을 해야 화를 면한다. 심신이 고로하나 풀린다.

`,`■ 부부가 공히 음탕하다.
부부이별의 兆이다.
다인 다사취합건이다.
일을 진행하지 마라.
일이 안되고 말뿐이다.
他人의 추천을 받아도 효과가 없다. 
秋月이 되어야 풀린다.
금전ㆍ여자 문제가 현 문제이다.
가택을 손상한다.
`,`■ 가택을 손상한다.
부모의 재액이 발생
한다.
때도 아니고 기량도 없다.
모든 일을 다시 재정비하여 진행해야 한다.
가을에는 효과가 있다. 
재주를 부려도 졸책에 불과할 것이다.
개변하는 상이다.
`,`■ 자충수적 우환이 기한다.
가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
신을 상하는 하극상 동요 있다. 男ㆍ夫가 헌신하나 골육상배이다.
女ㆍ婦도 헌신하다 여난 주색으로 병이 난다.
병 정단에 부인이 남편을 물으면 반드시 사망한다.
`,`■ 가택권리가 넘어간다
가정내부의 금전여자
문제로 의심 질투가 있다.
비상한 길조가 있다.
上人의 조력이 힘이 된다. 단, 가택의 권리가 넘어간다.
上下불화 반목한다.
상호관계는 좋다.
문서사 희경이다.
`,`■ 사물이 양갈래이다.
다인 다사취합건이다.
매사 헛소리이나 계획과 방침을 즉시 바꾸면 이익의 실현이 있다.
옛것을 버리고 새로 바꾸려 한다. 
가을에 결실된다.
주택이 좁다. 
부부이별의 조이다.
`,`■ 자충수적 우환이 기한다.
관송사가 있다.
관리는 탄핵으로 해임의 兆가 있다.
신변에 파재 극처 등 가문이 기우는 일이다.
심신이 동요한다.
참관이 통하여 일은 좋게 변화된다.
처음의 어둠을 기도로 보내야 한다.
`,`■ 가내 허위사가 있다.
上人과의 이익사ㆍ수익
사가 있으나 허사이다.
전진하면 손해가 있다.
부주의로 실책을 일으킨다. 한담공언이다.
현상 그대로 유지하는 것이 좋다. 
아직 때가 아니다. 
秋月을 대기하라.
쇠진의 시기가 왔다.
부모의 재액이 발생한다
`,`■ 계획은 위험하다.
가내 금전 冲散이 있다.
음사사가 있고, 災가 된다. 크다. 
기쁨이 없게 된다. 
인정이 떠나고 의지처가 없다. 
女는 음사가 있어 원망할 곳도 없다.
불화ㆍ가쇠 징조이다.`]
const SIBO2_1 = [`■官･夫,신변가택동요사이다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 재ㆍ여, 이합집산사이다.
내방조짐 상의 : 
원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 재ㆍ여, 가정사이다.
내방조짐 상의 : 
원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■관ㆍ부, 가택동요사이다.
官･夫,내방조짐 상의 : 
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
관재 병재 주의 요함
`,`■ 재ㆍ여, 다인 다사이다.
내방조짐 상의 : 
원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 
자손 재해 예방이 필요하다
`,`■ 자손, 가택내사이다.
내방조짐 상의 : 
조용히 있는게 이롭다.
고독한 남자이고 별리무덕하다.
`,`■ 官･夫, 가택내사이다.
내방조짐 상의 : 
은근하고 伏하라.
신변에 관재․병재가
침범한다.
`,`■ 재ㆍ여, 가택권리사이다.
내방조짐 상의 : 
원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 사업후원, 취합사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
자손재해 예방 요함
`,`■官･夫, 신변퇴패사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 재ㆍ여, 진퇴결정사이다.
내방조짐 상의 : 
원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 후원, 가정사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
후원사가 난망하고 부모 재액이 발생한다`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/020/row-1-column-1.png`,
  `/static/images/sagwa/020/row-1-column-2.png`,
  `/static/images/sagwa/020/row-1-column-3.png`,
  `/static/images/sagwa/020/row-1-column-4.png`,
  `/static/images/sagwa/020/row-1-column-5.png`,
  `/static/images/sagwa/020/row-1-column-6.png`,
  `/static/images/sagwa/020/row-1-column-7.png`,
  `/static/images/sagwa/020/row-1-column-8.png`,
  `/static/images/sagwa/020/row-1-column-9.png`,
  `/static/images/sagwa/020/row-1-column-10.png`,
  `/static/images/sagwa/020/row-1-column-11.png`,
  `/static/images/sagwa/020/row-1-column-12.png`,
]

const HEASYEOL = [
  // 1
  `  이 과는 12신이 복(伏)하여 동하지 않으므로 복음과(伏吟課)이다. 제1과에 상극이 있다. 축(丑)을 취하여 초전으로 하고, 중․말전은 지형(支刑)으로써 구성한다. 일간상신 축(丑)은 정신(丁神)이 승하여 초전으로 된다. 정재(丁財)가 동하여도 축토(丑土)는 일간을 극하므로 재(財)를 탐내서 취해서는 안된다. 재(財) 또는 여성으로 인하여 재(災)를 초래하기 쉽다. 음일은 스스로 유연하고 자신(自信)하며 조용히 하는 것이 좋다. 그러므로 정신(丁神)은 동조(動兆)의 신이고 정(靜) 중에 동(動)을 형성한다. 사과삼전이 모두 중귀(衆鬼)로 된다.
  백법에 말하기를 ｢피아가 모두 의지가 투합하지 않는 것은 지형(支刑)으로 되기 때문이다｣고 하였다. 비전에 말하기를 ｢정재(丁財)는 관귀(官鬼)의 재(財)에 의해 통하고 진술(辰戌)의 달(月)은 출입(出入)이 함께 불리하다｣고 하였다. 인신(寅申)의 달에는 토지 가옥의 분쟁이 있다. 일간 일지상신에 묘신(墓神)이 복(伏)하는 것도 충형(冲刑)에 의해 삽체(澁滯)를 개통한다. 즉 흉으로써 흉을 제어한다. 복음과는 양일이 자임격(自任格)이고, 음일은 자신격(自信格)이며, 초전에 자형(自刑)을 사용할 때는 두전격(杜傳格)으로 된다. 신임정마(信任丁馬)란 상기(上記)한 것을 말한다. 중전 술(戌)에 백호가 승하고 정신(丁神)이 승하여 관귀(官鬼)로 되어 흉해(凶害)가 심하다. 다행히 축(丑)은 순기(旬奇)로 되면 흉해가 해구되어 경미하다.
  일귀는 역행하여 삼전이 구(勾)․백(白)․음(陰)이며, 야귀는 순행하여 삼전이 음(陰)․백(白)․구(勾)이다. 천장(天將)은 어느 것이나 흉이기 때문에 군자(君子)는 이치에 따라서 행동하는 것이 마땅하다.`
  ,
  // 2
  `  이 과는 상하의 극이 없다. 일간에서 제3과, 제4과의 천신(天神)을 극하고 음일(陰日)이므로 음비(陰比)의 극이며, 제4과 사오(巳午)를 초전으로 하는 요극탄사과(遙剋彈射課)이다.
  천지의 상하는 지합(支合)하고 소통(疏通)하며, 과전은 화창(和暢)하고 일간에서 사오(巳午)를 극하여 양재(兩財)로 된다. 제4과를 초전으로 하면 맥월격(驀越格)이며, 삼전 사진묘(巳辰卯)는 역퇴여격(逆退茹格)이고 제2국의 삼전도 거의 역퇴여격이다. 초전의 처재(妻財)는 왕지(旺地)에 있어 말전에서 생하고, 재기(財氣)가 더욱 왕성하면 대재(大財)를 얻는다. 퇴여격은 어떤 일도 후퇴할 뿐 전진하지 못한다. 상하가 지합(支合)할지라도 일간일지는 자미(子未)와 축오(丑午)의 교차육해(交車六害)가 된다. 또 천반과 지반은 각각 충극하므로 화합 중에 불화가 있어 이합집산(離合集散)한다. 탄사맥월격(彈射(驀越格)으로 중전 진(辰)의 사신(蛇神)을 보아도 길흉이 모두 경미하다.
  백법에 말하기를 ｢과전 중에 귀신(貴神)이 많으면 오히려 귀인(貴人)에게 신뢰받지 못한다. 신월(申月)은 태재(胎財)가 생기(生氣)로서 처첩이 회태하고, 인월(寅月)은 태재가 사기(死氣)로 되어 태를 손상한다｣고 하였다. 비전에 말하기를 ｢계미일(癸未日) 일간상신은 자(子)이고 일지상신 오(午)는 서로 태(胎)로 되기 때문에 호태격(互胎格)이라고 부르며, 부처(夫妻)의 년명상신에 태(胎) 또는 재신(財神)을 만나면 처는 반드시 임신의 기쁨이 있다｣고 하였다.
  길흉은 생기(生氣)․사기(死氣)에 의해 결정해야 한다. 왕록(旺祿)이 신(身)에 임하면 맹동(猛動)을 싫어한다. 점차 따로 계획하여 활동해야 한다. 일귀는 역행하여 삼전이 귀(貴)․사(蛇)․주(朱)이며, 야귀는 순행하여 삼전이 주(朱)․사(蛇)․귀(貴)이다. 삼전 사진묘(巳辰卯)는 오자(五子)가 원둔(元遁)하면 을병정(乙丙丁)의 삼기(三奇)로 되어 흉사를 구해(救解)한다.`
  ,
  // 3
  `  이 과는 4과 중에 상하의 극이 없고 제3과 상신(上神) 사화(巳火)를 일간에서 요극(遙剋)하므로 탄사격(彈射格)으로 된다.
  초전은 처재(妻財)로 되고 말전에 정신(丁神)의 재(財)가 있다. 역마․정신의 재동(財動)은 동(動)하여 자금을 획득한다. 삼전사과중에 귀신(貴神)이 많이 모이지만 마음은 전일(專一)하지 못하다. 중전에 주작의 재(財)가 승하면 먼 곳에서 통신으로 인한 재(財)가 있다. 이와 같은 것은 재를 구하면 풍부하고, 뜻에 맡겨 구재(求財)하여 얻는다. 일간에서 보아서 일진상신의 사(巳)는 태절(胎絶)로 된다. 일지에서 일간 해(亥)를 보면 이것도 역시 태절(胎絶)이다. 특히 역간전(逆間傳)으로 되면 후퇴하는 것이 마땅하다. 함부로 망동할 때는 상호간에 갈등이 끊이지 않을 우려가 있다.
  백법에 말하기를 ｢육음(六陰)이 서로 계속되는 격은 모든 일이 혼미(昏迷)하다｣고 하였다. 비전에 말하기를 ｢일간상신이 지상신을 극하고 지지(地支) 축미(丑未)의 충(冲)이 천반 사해묘유(巳亥卯酉)의 충신(冲神)과 겹치는 것은 해리격(解離格)이다. 부부의 행년도 이와 같이 서로 반목(反目)하여 가정이 문란하다｣고 하였다. 수일(水日) 정재(丁財)는 재동(財動)으로 되어 좋은 것 같아도 축토(丑土)에서 극을 당하므로 재(財)로 인한 해(害)를 초래한다. 그러나 다행히 중전 묘(卯)에서 제어되어 해(害)가 없다. 탄사격(彈射格)은 길흉이 모두 경미하다.
  일귀는 역행하여 삼전이 귀(貴)․주(朱)․구(勾)이며, 야귀는 삼전이 음(陰)․귀(貴)․주(朱)이다. 천장(天將)에 의한 길흉은 서로 절반이다.`
  ,
  // 4
  `  이 과는 제1과 술(戌)이 수(水)를 극하고 그 밖에는 극이 없으므로 원수과(元首課)이다. 과전이 모두 토(土)의 극으로 가색격(稼穡格)을 구성한다.
  초전은 간상(干上)에서 발생하고 말전의 진(辰)은 지상(支上)에서 돌아오며, 내몸은 그에게 구한다. 순미(旬尾)의 술(戌)이 순수(旬首)에 가해지면 폐구격(閉口格)이 되므로 어떤 일도 입을 열지 않고 말하지 않으면 화를 면한다. 또 정단사는 입을 다물고 말하지 않는다. 현무가 승하면 진정한 폐구격(閉口格)이다. 미(尾)에서 노를 저으며 수(首)를 돌보는 상(象)으로 회환상(回環狀)을 나타내고, 어느 것이나 수미(首尾)가 확실하지 않다. 따라서 이런 이름이 붙었으니 길조가 아니다. 4과에 진(辰) 또는 술(戌)이 있어서 초전으로 되면 참관격(斬關格)이다.
  삼전의 모든 토신(土神)은 가색(稼穡)이라고 부르며, 토(土)의 성질은 침체(沈滯) 중정(中正)의 이무가 크다. 형충(刑冲)을 즐기고 묘(墓)가 또 전지(田地) 경작의 상으로 되면 흉이 없고 길조로 변화한다. 참관(斬關)은 토(土)의 중압(重壓)을 받음으로써 참개(斬開)하면 능히 통하여 길(吉)이 되고 단관개발(斷關開發)한다. 통하지 않으면 흉하다. 중․말전에 자축인묘미신유(子丑寅卯未申酉)가 있으면 참관이 통한다. 이 과는 중전에 미자(未子)가 있어 참관이 통한다. 또 일수(一水)로서 오토(五土)로 인하여 극극(極剋)을 받아 극(極)할 때는 변화하고, 변화하면 통하므로 오랫동안 흉해일 때는 도리어 흉은 해산된다. 정단의 대부분은 전택(田宅)․농공(農工)․토건(土建)의 사건이다.
  백법에 말하기를 ｢일간상신 백호가 묘신(墓神)에 승하여 극을 당하므로 병은 가장 흉하다｣고 하였다. 비전에 말하기를 ｢초전의 백호, 말전의 사신(蛇神)은 호두사미(虎頭蛇尾)라고 부르며, 처음은 중하고 후에 경하다｣고 하였다. 그러므로 초․말전은 흉으로써 흉을 제어하므로 반대로 흉이 없다. 길신이 승하면 오히려 개건(開鍵)의 기쁨이 있다. 일귀는 역행하여 삼전이 백(白)․음(陰)․사(蛇)이고, 야귀는 삼전이 청(靑)․상(常)․후(后)의 길장(吉將)이다.`
  ,
  // 5
  `  이 과는 제2과․제3과의 상에서 하를 극하여 섭해과로 된다. 맹상신(孟上神)이 극이 없으므로 중상신(仲上神)의 극을 취하여 사유(巳酉)를 초전으로 한다. 사축유(巳丑酉)의 금국(金局)은 종혁격(從革格)이다.
  초전은 재(財), 중전은 관성(官星), 말전은 인수(印綬)로 되어 재관인(財官印)의 3귀(三貴)로 부귀가 완전하다. 섭해과는 사건의 지의(遲疑)를 이루고, 금국의 종혁격(從革格)은 낡은 것을 개변하여 새로운 것에 따르면 길하다. 일간상신 유(酉)는 순공(旬空)으로 천공(天空)이 승할 때는 공중공(空中空)으로 되어 모든 일이 공허한 말뿐이다. 백법에 말하기를 ｢공상공(空上空)을 만나는 것은 어떤 일도 깊이 추구하지 말아야 한다. 출비가 과대하게 되기 때문이다｣고 하였다.
  만사에 기쁜 일이 있는 것은 삼합․육합이 겹치기 때문이다. 삼전의 합(合) 중에 살(殺)을 보면 꿀 속에 비소의 독약이 있다. 삼전이 체생(遞生)하면 타인의 추천(推薦)을 받는다. 비전에 말하기를 ｢제3과 묘미(卯未)가 서로 가하고, 주작이 승하여 택(宅)을 극하면 화난(火難)을 만난다｣고 하였다. 귀신(貴神)이 묘유(卯酉)에 임하는 것은 여덕격(勵德格)으로, 신불(神佛)의 신앙을 후하게 하고 조왕신에게 기도하며 조상(祖上)을 제사지냄으로써 흉을 피해야 한다.
  일귀는 역행하여 삼전이 귀(貴)․구(勾)․상(常)이며, 모두 토(土)의 천장이다. 야귀는 삼전이 음(陰)․주(朱)․공(空)이며, 점병(占病) 및 관소(官訴)는 심히 흉하다. 공명․구직은 희망이 없다. 구재는 급히 수득해야 한다. `
  ,
  // 6
  `  이 과는 제2과․제3과․제4과의 세 곳에 상하(上下)의 극이 있다. 하에서 상을 극하는 묘신(卯申)을 초전으로 하여 중심이다. 삼전 묘술사(卯戌巳)는 단륜격(斷輪格)이나, 초전의 묘(卯)가 공지(空地)에 앉고 목(木)은 부목(腐木)으로 조각할 수 없다. 따라서 기재(器材)로 되기 어려우니 다시 공작해야 한다.
  일지의 앞의 지(支)는 간상(干上)에 임하고, 기궁(寄宮) 축(丑)의 전지(前支) 인(寅)은 지상에 임한다. 이와 같은 것을 나망(羅網)이라고 부른다. 단륜(斷輪)이라는 것은 묘(卯)가 신(申)에 가해져 초전을 구성하는 격을 말한다. 묘(卯)는 차륜(車輪)이며, 경신신유(庚辛申酉)에 도끼와 같은 금(金)은 목(木)을 재단하여 기물을 만든다. 남은 것을 개변하여 정신(鼎新)하는 상(象)이다.
  임계일(壬癸日)의 목(木)은 주(舟)의 사신(事神)이다. 초․말 2전이 말(馬)을 만나거나 혹은 인종(引從)하면 임(任)은 중하고 요원하다. 시(時)에 목왕(木旺)을 만나고 금장(金將)이 전(傳)에 들어가면 금왕(金旺)하고 목장(木將)은 전(傳)에 들어갈 수 있어 주인격(鑄印格)과 비슷하다. 백법에 말하기를 ｢나망(羅網)을 만나면 졸책으로 된다｣고 하였다. 일귀는 역행하여 삼전이 주(朱)․백(白)․귀(貴)이며, 야귀는 삼전이 귀(貴)․청(靑)․음(陰)으로 신장(神將)이 모두 길로서 군자가 때를 기다리는 상이다. 질병의 정단은 사(死)이며, 소송 사건은 흉하다.
`
  ,
  // 7
  `  이 과는 12신이 충위(冲位)에 있으므로 반음과(返吟課)이다. 제1과의 상에서 하를 극하여 이를 초전으로 한다. 삼전이 토신(土神)뿐일 때는 가색격(稼穡格)이며, 정신(丁神)이 승하여 유자격(遊子格)으로 된다. 그러므로 사과삼전(四課三傳)이 모두 일간을 극하여 중귀(衆鬼)로 된다. 축미(丑未)는 어느 것이나 주작이 승하여 쟁소(爭訴)의 징조가 있다. 정재(丁財)는 암재(暗財)이므로 여성 문제에서 쟁단(爭端)이 발생한다. 가색(稼穡)은 모두 묘신(墓神)이므로 모든 것이 지의조체(遲疑阻滯)하고, 충신(冲神)이 겹치기 때문에 불화․분쟁의 기복이 있는 불안한 과격(課格)이다. 년명상신이 금(金) 또는 목(木)으로 되면 생화제극(生化制剋)하고 흉을 구제하여 길조로 변화한다.
  백법에 말하기를 ｢양귀신(兩貴神)은 극을 받으므로 귀인의 원조를 얻기 어렵다. 수일(水日)에 정재(丁財)를 만나면 재(財)로 인하여 흉의 사건이 발생한다｣고 하였다. 비전에 말하기를 ｢이 과(課)에서 남편의 질병을 처의 명(命)에서 정단해 보면 남편은 사망한다고 판단을 내린다｣고 하였다. 이것은 관살(官殺)이 남편의 신이며, 미(未)는 화개(華蓋)로서 태상이 승하여 사기(死氣) 또는 조객(弔客)으로 되어 반드시 사상(死喪)의 우려가 있다. 일귀는 순행하여 삼전이 주(朱)․상(常)․주(朱)이며, 귀살(鬼殺)과 주작이 겹치면 불평불만과 분쟁이 발생한다. 야귀는 삼전이 상(常)․주(朱)․상(常)이다.`
  ,
  // 8
  `  이 과는 4과 모두 상하에 극이 있고 하에서 상을 적한다. 음일이면 극을 취하여 초전으로 하며 지일비용과라고 한다. 4과에 모두 극이 있으면 도액격(度厄格)이며 삼전 사술묘(巳戌卯)는 주인격(鑄印格)이 된다.
  일간일지의 상하는 육해(六害)로 되고, 오(午)와 미(未)가 지합하고 자(子)와 축(丑)이 지합하여 교차지합(交車支合)으로 되며, 천지반은 상하가 상충으로 된다. 상충은 불화하고 싸우며 상합(相合)은 화순(和順)하고 육해는 소해의혹(疏害疑惑)을 낳는다. 사술묘(巳戌卯)는 주인격(鑄印格)으로 현임관은 진급의 기쁨이 있다. 관리가 야(野)에 있을 때는 불리하다. 이 격은 술(戌)중에 신금(辛金)이 있고 사(巳) 중에 병화(丙火)가 있어 간합(干合)하고, 금(金)은 화련(火煉)하여 귀기(貴器)를 구성하므로 주인(鑄印)이라고 부른다.
  태산은 대길하고 병점(病占)은 흉하다. 어떤 일도 정신 기력을 튼튼히 하고 인내하면 후에는 성공할 수 있다. 백법에 말하기를 ｢일지상신 건록은 권섭부정(權攝不正)으로 된다. 신월(申月)의 태재(胎財) 생기(生氣)는 처첩이 회태한다. 인월(寅月)은 사기(死氣)로 되어 태를 손상한다｣고 하였다. 비전에 말하기를 ｢초․말전에 귀신이 승하고 말전에서 초전을 생하여 어떤 일도 생취되며, 귀인의 원조가 있다｣고 하였다. 교차지합하여 재(財)로 되는 것은 교역에 의하여 수재(收財)는 길하다. 년명(年命)에 해(亥)가 승하면 전후인종(前後引從)이라고 하여 양귀인의 도움이 있고 비상한 기쁨을 만난다. 일귀는 순행하여 삼전이 귀(貴)․청(靑)․음(陰)이고, 야귀는 역행하여 삼전이 음(陰)․청(靑)․귀(貴)이다.
`
  ,
  // 9
  `  이 과는 제1과․제2과․제3과가 모두 하에서 상을 극하여 비용(比用)을 취하지 않기 때문에 섭해과(涉害課)로 된다. 제2과는 지반 사(巳)의 맹신(孟神)의 극이므로 유사(酉巳)를 초전으로 하고, 중․말전은 축사(丑巳)로 되어 삼합금국(三合金局)․종혁격(從革格)을 구성한다.
  초․중전은 공망하고 금(金)은 일간을 생하지 못하며 말전의 1신(一神) 사(巳)의 재신(財神)을 취하여 길흉을 예측한다. 어떤 일도 처음에는 허성(虛聲)이지만 후에 실의(實意)․실현(實現)을 본다. 섭해과로서 맹상신(孟上神)을 취하여 초전으로 하면 견기격(見機格)이라고 부른다. 맹신은 사계(蛇季)의 수(首)로서 일계(一季)의 기후를 배태(胚胎)하여 처음으로 발생하고 화복을 간직하므로 견기(見機)라고 부른다.
  일은 의혹이 있고 급히 개혁하는 것이 길하다. 구태(舊態)를 지키면 더욱 삽체(澁滯)되어 해제되기 어려우니 말사는 계획을 세워서 안전책을 강구해야 한다. 처음에는 어려우나 후에 쉽다. 특히 종혁격은 견기(見機)와 함께 낡은 것을 버리고 선(善)을 택하여 새로운 것을 따르면 길조를 형성한다. 말전에서 중전을, 중전에서 초전을, 초전에서 일간을 생하여 체생(遞生)으로 되고, 삼합은 다수의 상(象)이므로 많은 사람으로부터 추천을 받는다. 초․중전이 공망하면 오래도록 길조를 얻는다.
  백법에 말하기를 ｢삼전이 체생하여 타인의 추천을 얻는다. 수일정재(水日丁財)는 재동(財動)이 있다｣고 하였다. 삼전에서 일간을 생하고 일지의 택(宅)은 삼전에 탈기(脫氣)하므로 가족은 풍성하여도 주택이 좁다. 일귀는 순행하여 삼전이 구(勾)․상(常)․귀(貴)이고, 야귀는 삼전이 공(空)․음(陰)․주(朱)이다. 체생(遞生)의 길흉 후박(厚薄)의 법칙으로서는 하에서 상을 생하는 것은 후하고, 상에서 하를 생하는 것은 박하다. 그러므로 이 격과 같이 말전의 하에서 체생하는 것은 후하다. 그러나 공망한 것은 허실착종(虛實錯綜)의 비칙(秘則)을 적용하여 그 시기를 알 수 있다. 체생(遞生)하는 것은 일간에 모이기 때문이다.`
  ,
  // 10
  `  이 과는 제1과 진토(辰土)가 수(水)를 극하고, 진축(辰丑)을 초전으로 하는 원수과(元首課)로 된다. 사과삼전이 모두 귀살(鬼殺)로서 삼전에 진술(辰戌)의 괴강(魁罡)이 승하여 극히 불량한 과다. 그러나 극에 달하면 변하는 이치에 따라 만사는 심사숙고(深思熟考)의 상기(上機)를 측정하여 동할 때는 화환(禍患)을 면할 수 있다. 경거망동하면 화에 걸리고, 묘신(墓神)이 간지상신에 복(覆)하여 암매조체(暗昧阻滯)가 있다. 흉신을 충하는 것은 대사(大事)는 소사(小事)로 변화하고 또 흉으로 흉을 제어하는 효과가 있다. 
  백법에 말하기를 ｢귀살(鬼殺)이 제3과․제4과에 있으면 소송․재화(災禍)가 발생할 우려가 있다｣고 하였다. 또 이 과는 과거에 계속해서 흉재를 만난 사람은 백법의 백칙(百則) 결절격(結節格)으로 되어 흉이 전변한다. 일귀는 순행하여 삼전이 후(后)․주(朱)․청(靑)이고, 야귀는 삼전이 사(蛇)․백(白)․구(勾)의 흉장이다. 참관격(斬關格)으로 되어도 중전에 미(未)의 자(字)가 있어 참관이 통한다. 다만 처음에는 정체되고 후에 개통된다.
  진미술축(辰未戌丑)의 가색격(稼穡格)은 초전에 의해 사계(四季)의 순(順)을 즐기고 순(順)으로 될 때는 도(道)에 합해야 한다. 예를 들면 이 과는 초전의 진(辰)은 봄, 중전의 미(未)는 여름, 말전의 술(戌)은 가을이므로 순(順)으로 된다. 초전의 진(辰)은 수고(水庫)이고 지반의 축(丑)은 금고(金庫)이다. 축(丑)이 진(辰)에 가해져 금수함양(金水涵養)이라고 하며 만물을 생육한다. 토(土)의 기(氣)가 홀연히 열리니 생기의 뜻이며, 처음으로 동하는 징조를 형성한다. 일간 일지상신에 진술(辰戌)이 승하여 초전으로 되면 참관격(斬關格)이며, 양일은 도망하고 음일은 복닉(伏匿)한다. 즉, 진술(辰戌)은 동신(動神)으로 과거에 활동했을 때는 냉정(冷靜)으로 된다. 종래에 정(靜)했다면 금후는 활동에 들어간다.`
  ,
  // 11
  `  이 과는 4과 중에 상하의 극이 없다. 제2과의 사(巳)는 일간에서 극하여 요극탄사격(遙剋彈射格)으로 된다. 삼전 사미유(巳未酉)는 음의 순간전격(順間傳格)이며, 변영격(變盈格)이라고 한다. 이유는 물질이 왕성의 극에 이르면 반드시 쇠퇴하고, 차면 반드시 기우는 자연의 이치에 따른 것이다. 사(巳)는 낮으로 양의 성극(盛極)이고 양(陽)은 중전의 미(未)에 이르러서 소퇴(消退)한다. 말전은 유(酉)로서 태양이 질 때가 되면 변하여 음상(陰象)이 되므로 변영(變盈)이라고 명명하였다. 그러므로 어떤 일도 전진하면 좋지 않다.
  요극과(遙剋課)는 길흉이 모두 경미하고 부주의한 가운데 실착(失錯)을 만든다. 존장에게 유익하고 비유(卑幼)에게 무익하다. 말전의 공망은 처음에는 실의(實意)가 있어도 후에는 허(虛)로 끝나므로 시작은 있어도 끝이 없는 상(象)이다. 백법에 말하기를 ｢삼전의 체생(遞生)은 타인의 추천을 받는 육음(六陰)이 서로 계속되는 것은 혼미(昏迷)의 징조이다｣고 하였다. 비전에 말하기를 ｢사과삼전이 모두 지반에서 천반을 생하여 탈기(脫氣)로 되는 것은 원소근단격(源消根斷格)이라고 한다. 질병을 정단하면 불섭생으로 인하여 병원(病源)이 된다. 삼전이 체생하고 말전이 공망하면 추천(推薦)할 마음이 있어도 성취하지 못한다. 한담공언(閑談空言)이 많고 성의가 적다｣고 하였다. 
  일귀는 순행하여 삼전이 귀(貴)․주(朱)․구(勾)이며, 야귀는 삼전이 주(朱)․구(勾)․공(空)이다. 삼전(三傳) 사미유(巳未酉)는 일지(一支)의 간격을 두고 삼전을 구성한다. 그러나 말전 유(酉)는 진간전(進間傳)이라 하더라도 전진해야 할 뜻은 있으나 공망하기 때문에 전진하면 함정에 빠진다. 그러므로 모든 일을 현상을 유지하면서 때를 기다리는 것이 마땅하다. 하물며 변화로 되기 때문에 더욱 그러하다.`
  ,
  // 12
  `  이 과는 4과 중에 상하의 극도 요극(遙剋)도 없으므로 묘성과(昴星課)로 된다. 음일은 천지반(天地盤) 중에 유(酉)의 하신(下神)을 취하여 초전으로 하고, 중전은 일간상신을 취하고 말전은 일지상신을 취하여 신인신(申寅申)의 삼전을 구성한다. 유(酉) 중에 묘숙(昴宿)이 있고 음일(陰日)은 유(酉)의 하(下)를 취하여 동사엄목격(冬蛇掩目格)으로 된다. 암매(暗昧)의 우리를 방지해야 한다. 현무․천후(天后)가 승하는 것은 음사(陰私)로 중대한 재화(災禍)를 일으킨다.
  이 과는 간상(干上)은 인(寅)이고 지상(支上)은 신(申)이며, 초전과 말전은 신(申)으로서 백호의 본궁이다. 중전에 인(寅)이 있고 인(寅)은 또 호(虎)이다. 호(虎)를 보아 인(寅)을 만난다. 이 과는 경천동지(驚天動地)의 재화를 초래한다. 다만 초전이 공망하므로 흉이 경감될 뿐이다.
  육임(六壬)의 극을 사용하는 것은 길흉은 모두 동(動)에서 발생하는 것이 원칙이다. 묘성과는 극이 없으므로 유(酉)를 표준으로 하고 유(酉)의 상 또는 하를 채용한다. 원래 유(酉)는 서방 백호의 금위(金位)로서 금기(金氣)가 강렬(剛烈)하므로 형살(刑殺)의 의(義)를 취하여 입전(入傳)하기 때문이다. 유(酉)의 상하를 사용하기 때문에 앙복격(仰伏格)이라고도 부른다.
  음일은 음성(陰性)이며 지(地)에 따른다. 여자는 기(氣)가 가라앉으므로 엎드려서 이것을 본다. 그러므로 엄목격(掩目格)이라고 이름을 붙였다. 이 격은 인정이 실의(失意)되고 진퇴(進退)가 모두 의지할 곳이 없다. 여자의 대부분은 음일(淫沿)하고 내부에 우려와 재해가 있다. 방인(訪人)은 보이지 않고 사업은 성공할 수 없다. 가는 자는 침체(沈滯)되고 도망자는 형체를 감춘다. 일귀는 순행하여 삼전이 합(合)․현(玄)․합(合)이며, 야귀는 삼전이 청(靑)․후(后)․청(靑)이다. 청신(靑神)의 길장을 승하여도 공망되어 기쁨이 적다.`
  ,
]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
