
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,8,1,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,12,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,10,11,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,10,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,9,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,1,8,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,2,7,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,3,6,`특별있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,5,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,5,4,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,6,3,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,2,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`색정`,`외남 색정사 有`,`小`,`12`,`색정사有`,`가정막힘`],
    [`막힘`,`내부가정 막힘사`,`大`,`과시기`,`비용과출`,`간인詭計`],
    [`미면선난(未免先難)`,`어떤 일이든 먼저 어려움을 겪고난 후에 기대되는 상`,`大`,``,`反禍위복`,``],
    [`반화위복(反禍爲福)`,`재난과 화환이 가까스로 사라지고 반대로 복이 오는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`11`,`침체공허`,`부부화합`],
    [`화합`,`내부가정 부부남녀 화합사`,`小`,`쇠퇴기`,`事後쟁탈`,`반화위복`],
    [`생계영창(生計榮昌)`,`생계가 영창하는 상-혼인 상대와의 인연은 반드시 좋음`,`小`,``,``,``],
    [`의지돌파(意志突破)`,`여러 액난을 굳은 의지로 버티면 돕는 이가  있게되는 상 `,`大`,``,``,``],
    [`박관`,`명예직업직위불안남편갈등자식 관련사`,`大`,``,`애증화합`,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`10`,`파재실종`,`출행이동`],
    [`매사 영속성없음`,`직업직위불안남편상갈등자식사는 선천적 기복재해를 겪음`,`小`,`침해기`,`도망우려`,`事後쟁탈`],
    [`기리동모(豈利動謀)`,`이익이 없는 일인데도 왜 적극적인가-의 상`,`大`,``,`도적失勢`,``],
    [`탄식신음(嘆息呻吟)`,`병 또는 주력사의 불완성으로 장탄식을 하는 상`,`小`,``,``,``],
    [`결속`,`단합사 음2월 완성 후 직위남편훼절-선천적 재해를 겪음`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`9`,`복잡결속`,`부부반목`],
    [`매사 영속성없음`,`직업직위불안남편상갈등자식사는 선천적 기복재해를 겪음`,`小`,`소외기`,`미초희경`,`佳音대기`],
    [`고유도밀(故喩刀蜜)`,`이르되 웃음속에 칼이 들었고 꿀에 독이 들었다-의 상`,`大`,``,``,``],
    [`미초희경(微招喜慶)`,`고위로부터 술잔을 내려받고 애써 기쁨을 감추는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`8`,`관사`,`가정단합`],
    [`남편`,`남편 관련사`,`小`,`침체기`,`도적回顧`,`미초희경`],
    [`택역흠안(宅亦欠安)`,`역시 가정의 편안함에 흠이 있는 상`,`小`,``,`공격말것`,``],
    [`불의반공(不宜反攻)`,`도적이 악의를 품고 되돌아보니 공격하지 말라-는 상`,`大`,``,``,``],
    [`금전`,`금전사업처 관련사`,`大`,``,``,``],
    [`출행`,`외사 기로 출행이동사`,`大`,`7`,`기로출행`,`급속사有`],
    [`급속`,`내사 가정 급속사`,`小`,`단절기`,`臥病不起`,`도적악의`],
    [`무이소귀(無以所歸)`,`박해와 손실로 심신이 편히 돌아갈 수 없는 사정인 상`,`小`,``,`구설驚變`,``],
    [`도적악의(盜賊惡意)`,`도적이 악의를 품고 되돌아보니 공격하지 말라-는 상`,`小`,``,``,``],
    [`금전`,`금전사업여인 관련사`,`大`,``,``,``],
    [`동요`,`가정부부 상쟁사`,`大`,`6`,`외사손실`,`부부상쟁`],
    [`손실`,`외사 손실사`,`小`,`존재기`,`비곡차욕`,`臥病불기`],
    [`난중득귀(難中得貴)`,`어려운 중 고위인의 도움을 얻는 상-음8월 희경 기대됨`,`小`,``,`毁節한상`,``],
    [`와병불기(臥病不起)`,`병들어 누었으나 병이 중하여 일어날 수가 없는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`5`,`압박받음`,`증오가출`],
    [`결속`,`결속사를 음2월에 완성하나 명예직위남편 훼절됨 `,`小`,`배양기`,`奸邪은닉`,`禍難전소`],
    [`면전해탈(面前解脫)`,`여럿의 도움으로 면모를 다시 세우게 되는 상`,`小`,``,``,``],
    [`사송계류(詞訟稽留)`,`왕래 중 위법사가 발생하여 재판을 기다리는 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`급속`,`외사 급속사`,`小`,`4`,`손해도망`,`내부가정`],
    [`부친`,`부친 관련사`,`大`,`개시기`,`실업한가`,`단합`],
    [`흉존길산(凶存吉散)`,`흉이 아직 남아 있으므로 길이 흩어지게 되는 상`,`小`,``,``,`간사은닉`],
    [`사개성수(事皆成遂)`,`모든 일이 이루어지는 상-부족`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`색정`,`내녀 색정사 有`,`小`,`3`,`과출수난`,`색정사有`],
    [`모친`,`모친 관련사`,`大`,`학습기`,`승진영전`,`파재극처`],
    [`후유성취(後有成就)`,`나중에라야 이루어지는데 왜 지금 나아가려 하는가-의 상`,`小`,``,``,`포난심획`],
    [`비곡수욕(悲哭差辱)`,`훼절하여 슬피우는 상`,`大`,``,``,`(도둑불포)`],
    [`관사`,`관사 경구(驚懼) 관련사`,`小`,``,``,``],
    [`직업`,`직업  관련사`,`大`,`2`,`압박받음`,`가정침체`],
    [`남편`,`남편 폐한(廢閑)관련사`,`小`,`면모기`,`소식대기`,`승진영전`],
    [`기가전진(豈可前進)`,`속임수인데도 어찌 된다며 앞으로 나아가려 하는가-의 상 `,`小`,``,`(와도나쁨)`,``],
    [`우유한가(優遊閑暇)`,`실업자 처지로 한가함`,`大`,``,``,``],
    [`명예`,`수상(受賞)사`,`小`,``,``,`과출손모`],
    [`직녹`,`직위녹위 관련사`,`小`,`1`,`외교막힘`,`면모훼손`],
    [`막힘`,` 외교막힘 관련사`,`大`,`절정기`,`간인궤계`,`비상지愛`],
    [`도처거래(到處去來)`,`녹덕 위에 앉았으나 바삐 서둘 지 않아도 좋은 상`,`大`,``,``,``],
    [`간인궤계(奸人詭計)`,`간사한 자가 꾸민 거짓에 말려든 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,`형제친구동료색정조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`직업남자침체사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,``,`관사 직업 남편 사로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`금전사업처급속출행사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`금전사업여인조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,``,`관사직업남자가출사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`후원문서급속부친조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`후원문서모친색정조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사직업남편침체사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`명예직녹자산권리외교막힘사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결해야만 유리함`,0,0,`명분과 정당성이 있는 사안이므로 당당하게 속결해야만 유리하고 조고우면하거나 사안을 뒤로 미루면 되려 불리해지는 人`,0,0,`일이 거꾸로 진행됨**1남2녀 삼각관계 有**여건조건이 부족 불비함**산속으로 깊이 숨고 싶은 심정임**침체驚懼事를 겪고있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결해야만 유리함`,0,0,`명분과 정당성이 있는 사안이므로 당당하게 속결해야만 유리하고 조고우면하거나 사안을 뒤로 미루면 되려 불리해지는 人`,0,0,`일이 거꾸로 띄엄띄엄 진행됨**산속으로 깊이 숨고 싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`동요와의 동업, 또는 협동사로 중상모략적 의혹을 받고 있어 이리저리 선택을 못하고 기로에 서있으며 가장 친하고 가장 가까이 거주하는 자와 의논하면 해결되는 人`,0,0,`풍운불측의 상**만사 연루됨**간사 사음 은닉사가 있게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인고 다툰다면 걱정과 근심을 풀기 어려우므로 신중해야 하고 사안을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`재원의 부리가 될 여러 복잡한 일은 일관시키려 함-음2월에 완성하나 명예직업직위남편이 훼절됨**경쟁관계가 있고 적선적덕으로 인심을 얻어야 함**일이 결과가 없고 반복되고 점점 어두워 짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서 쟁론하여 결정하지 말고 유순한 자세로 사안을 지체시키거나 뒤로 밀어야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하며 사안을 후로 미뤄야만 결과가 반드시 유리해지는 人`,0,0,`정대한 옛 일들이 끓어짐**부인이 남편을 배반하는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`반복무상하고 이산되며 부부남녀 상쟁하고 어떤 일도 오래가지 않는 人`,0,0,`주력사가 결과없이 반복됨**점점 어두워 짐**부부 모두 外情을 갖고있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서 쟁론하여 결정하지 말고 유순한 자세로 사안을 지체시키거나 뒤로 밀어야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하며 사안을 후로 미뤄야만 결과가 반드시 유리해지는 人`,0,0,`권한사가 결여됨-음2월이라야 성취됨**승진영사도 같음**심정 갈등이 많음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서 쟁론하여 결정하지 말고 유순한 자세로 사안을 지체시키거나 뒤로 밀어야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하며 사안을 후로 미뤄야만 결과가 반드시 유리해지는 人`,0,0,`재원의 뿌리가 될 여러 일을 하나로 일관시키려는 결속사가 있음-음2월에 완성하나 명예직위남편의 훼절사가 있게 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위세(狐假虎威)세**만약 타인과 다툰다면 호가호위 세로 아무리 공격해도 상대는 피해가 없게 되고 되려 불리함`,0,0,`이해관계가 있는 타인에 대한 공격사가 있으나 허사가 되어 유불리가 거꾸로 되므로 중지해야만 하는 人`,0,0,`풍운불측의 상**만사 연루됨**송쟁은 형사건으로 변함**간사음폐은닉사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서 쟁론하여 결정하지 말고 유순한 자세로 사안을 지체시키거나 뒤로 밀어야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하며 사안을 후로 미뤄야만 결과가 반드시 유리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**관직자는 영전이동함-단 음1월**여자가 남의 남자를 적극 유혹하여 함께 도망까지 할 속심을 갖고 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`동요와의 동업, 또는 협동사로 중상모략적 의혹을 받고 있어 이리저리 선택을 못하고 기로에 서있으며 가장 친하고 가장 가까이 거주하는 자와 의논하면 해결되는 人`,0,0,`1녀2남 삼각관계 有**여건조건이 부족불비함**일이 순서적으로 진행되는 듯하나 퇴하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻은 있어도 움직이지는 않음`,0,0,`스스로 자임하고 옛것을 지키며 새 기회를 기대하고 신음하는 人`,0,0,`매사 막힘**군자가 때를 기다리는 격으로 결과없이 반복되다가 비로소 밝아지는 상**전화위복사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동사타발동사겹침`,`미사`,`구사`,`외사내사겹침`,`아표면타이면사겹침`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성-무력`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`좌고우면-무력`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출됨`,`아발동사후회사겹침`,`미사`,`신사`,`외사`,`표면사이면사겹침`,`배신`,`무력`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-무력`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,` 비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월`,2,3,5,`음9월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음1월-연기우려`,3,2,5,`음8월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음11월`,2,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음10월`,3,1,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음9월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음8월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월-파혼우려`,2,3,5,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월-파혼우려`,2,3,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월-연기우려`,2,1,3,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,3,1,4,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,3,3,6,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초반 업무는 수준이하이고 중 종반에 실적을 이루나 자산의 권리를 양도하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세나 업무는 수준을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 업무를 시종 실패하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무 전반이 실패로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있고 업무는 중반의 내부 동요분산사 외 초 중반의 실적이 돋보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 많아 본인의 이익을 위하며 업무를 동요불안분산기로사로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 타인과 같은 소리를 내나 권리권한사를 음2월에 완성하여 업무수준을 높임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯하나 설득력이 있어 재원의 뿌리가 될 여러 일을 결속시켜 음2월에 완성해도 결국 전체 명예를 실추시게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받으나 의지와 배경이 없어 업무 전반이 저조하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 업무 초반은 저조하고 중 종반에 이르러서야 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무 전반을 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하나 업무는 초 중반에 실적을 내고 종반이 빈약하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`권리양도`,`退背`,`원`,`去`,`무난`,`정남`,``,`북서`],
    [`10.11`,``,`측근제압`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`多集`,`지체제압`,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선난후이`,`進向`,`  원`,`  留`,`주인가해`,`남남서`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선이후費`,`進向`,`  근`,`  留`,`주인조력`,`서남`,``,`정북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선이후費`,`退背`,`  원`,`  去`,`주인조력`,`정서`,`북북동`,``],
    [`10.11`,``,`다중제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`불이행`,`선난후費`,`進向`,`  원`,`  留`,`주인가해`,`북서`,`북북동`,``],
    [`10.11`,``,`끌면제압`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선攻후이`,`進向`,`  원`,`  留`,`세입가해`,`남남동`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선攻후이`,`退背`,`원근왕래`,`  去`,`세입가해`,`정남`,`동남`,``],
    [`10.11`,``,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부동조`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`주인가해`,`북북동`,`북북동`,``],
    [`10.11`,``,`음2월제압`,``,``,``,``,``,`이후단합`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인사안`,`退背`,`원근왕래`,`  去`,`주인무력`,`남남동`,`북북동`,``],
    [`10.11`,``,``,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`불이행`,`사안무력`,`退背`,`  원`,`  去`,`주인무력`,`정서`,``,`정남`],
    [`10.11`,``,`끌면제압`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`선난후이`,`進向`,`  근`,`  留`,`주인가해`,`동남`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`선이후이`,`進向`,`  근`,`  留`,` 무난`,`북북서`,``,`남남동`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`]
  ]
const columnCY = [
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [``,``,``,0,0,``,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`제례귀`,`제례위법`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`제례귀`,`제례위법`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,0,`없음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`여귀`,`호주부인`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,0,`특별있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`호주부인`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,`제례귀`,`제례위법`,0,0,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,`여귀`,`호주부인`,0,0,`묘좌`,0,`있음`],
    [``,``,``,0,0,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,0,0,`묘속`,0,``],
    [`가정`,``,``,0,0,`육친`,0,``],
    [`질병`,``,``,0,0,`묘좌`,"정북",``],
    [``,``,``,0,0,`묘향`,"정남",``],
    [`생업`,``,``,0,0,`전경`,"강", "호수", "천",``],
    [``,``,``,0,0,`묘속`,  "水",``],
    [`가정`,``,``,0,0,`육친`, "조모",``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`명예직녹자산권리사**외교막힘-반목사`,`간인궤계사`,`외남 색정사**내부가정막힘`,`비상지애사`,`남모르는 능욕박해사`,`부부남녀화합사`,`매사 허성불성-세속허사자-외사파재실종도망사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음**문서상신-답신지체-구설사`,`종교진출하면 자립함`,`매사 위역사`,`재난전소사`,`외사 출행이동사-내사  급속사**외사 동요분산사`,`발탁영전도약희경사`,`가정부부남녀쟁투사`,`탄식신음사-병-주력사 미수사`,`내부가정손해가출사`,`觀書-문화풍아-실업처지`,`외사급속사`,`도적실세-잡음`,`내녀 색정사**내부가정파재극처사`,`事後 쟁탈사`,`매사 위역사`,`반화위복사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁`,``,`전도`,``,`빈궁**관재`,`환성경사-부족**내사출행이동사`,``,`내부가정반목사`,`빈궁`,`전화위복`,`빈궁**관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,``,`빈궁**관재`,``,`빈궁**병재`,``,``,``,`침체驚懼사`,`빈궁**관재*병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사`,`반화위복사`,`명예직녹자산권리사**외교막힘-반목사`,`간인궤계사`,`외남 색정사**내부가정막힘`,`비상지애사`,`남모르는 능욕박해사`,`부부남녀화합사`,`매사 영속성없음-외사파재실종도망사`,`종교진출하면자립함`,`매사 영속성없음**문서상신답신지체-구설사`,`종교진출하면자립함`,`매사 위역사`,`재난전소사`,`외사 출행이동사-외사 급속사**외사 동요분산사`,`발탁영전도약희경사`,`가정부부남녀쟁투사`,`탄식신음사-병-주력사 미수사`,`내부가정손해가출사`,`觀書-문화풍아-실업처지`,`외사급속사`,`도적실세-잡음`,`내녀 색정사**내부가정파재극처사`,`事後 쟁탈사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재**병재`,``,`전화위복`,`전도**빈궁`,``,`전도`,``,`빈궁**관재`,`환성경사-부족**내사출행이동사`,``,`내부가정반목사`,`빈궁`,`전화위복`,`빈궁**관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`관재`,``,`빈궁**관재`,``,`빈궁**병재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내녀 색정사**내부가정파재극처사`,`간인궤계사`,`소인쟁재사`,``,`명예직녹자산권리사**외교막힘-반목사`,`남모르는 능욕박해사`,`외남 색정사**내부가정막힘**부부남녀화합사`,``,`부부남녀화합사`,`불리고사사-구설사`,`매사 영속성없음-외사파재실종도망사**부부반목사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`비곡차욕사-훼절 원통`,`외사 출행이동사-내사 급속사**외사 동요분산사`,`臥病불기-구설악화사`,`가정부부남녀쟁투사`,`도적악의-공격말 것`,`내부가정손해가출사`,`미초희경사`,`외사급속사`,`좋은 소식사 기대`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`관재`,``,`전화위복`,`빈궁`,`재액해소`,``,`재액해소`,`빈궁**관재`,`내사출행이동사-실기즉퇴가좋음`,`빈궁`,`간사은닉**내부가정반목사`,``,`전화위복`,`관재`,`음4,5월화재주의`,`빈궁관재`,`음4,5월화재주의`,`빈궁**관재`,``,`관재`,``,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`외사급속사`,`좋은 소식 기대사`,`내녀 색정사**내부가정파재극처사`,`간인궤계사`,`소인쟁재사`,``,`명예직녹자산권리사**외교막힘-반목사`,`견련반복사`,`외남 색정사**내부가정막힘`,`부부반목사`,`불리고시사-구설근심사`,`부부남녀화합사`,`매사 영속성없음-외사파재실종도망사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`비곡차욕사-훼절원통`,`외사 출행이동사-내사 급속사**외사 동요분산사`,``,`가정부부남녀쟁투사`,`도적악의-공격말 것`,`내부가정손해가출사`,`미초희경사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,`빈궁`,``,`관재`,``,`전화위복`,`전도**빈궁`,``,`빈궁**관재`,``,`빈궁`,`내사출행이동사-실기즉퇴가좋음`,``,`간사은닉**내부가정반목사`,`관재`,`전화위복`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`미초희경사-이동사`,`내부가정손해가출사`,`외사급속사`,`좋은 소식 기대사`,`내녀 색정사**내부가정파재극처사`,`간인궤계사`,`소인쟁재사`,``,`명예직녹자산권리사**외교막힘-반목사`,`견련반복사`,`외남 색정사**내부가정막힘`,`부부반목사`,`불리고시사-구설근심사`,`부부남녀화합사`,`매사 영속성없음-외사파재실종도말사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`비곡차욕사-훼절원통`,`외사 출행이동사-내사 급속사**외사 동요분산사`,``,`가정부부남녀쟁투사`,`도적악의-공격말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**빈궁**병재`,``,`전도**빈궁**관재`,``,`관재`,``,`전화위복**재액해소`,`빈궁`,`재액해소`,`관재`,``,`빈궁**관재`,`내사 출행이동사-실기죽퇴가좋음`,`빈궁`,`간사은닉**내부가정반목사`,``,`전화위복`,`관재`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`가정부부남녀쟁투사`,`도적악의-공격말 것`,`미초희경사-이동사`,`내부가정손해가출사`,`외사급속사`,`좋은 소식 기대사`,`내녀 색정사**내부가정파재극처사`,`간인궤계사`,`소인쟁재사`,``,`명예직녹자산권리사**외교막힘-반목사-재액해소`,`견력반복사`,`외남 색정사**내부가정막힘**재액해소`,`부부반목사`,`부부남녀화합사`,`불리고시사-구설근심사`,`매사 영속성없음**내사출행이동사`,`종교진출하면자립함-외사파재실종도망사`,`매사 영속성없음**내부가정반목사`,`종교진출하면자립함`,``,`비곡차욕사-훼절원통`,`외사 출행이동사-내사 급속사**외사 동요분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`빈궁`,`동요불안분산사`,`관재`,`동요불안분산사`,`전도**빈궁**병재`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`관재`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사`,`관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사-실기즉퇴가좋음`,`빈궁`,`간사은닉**동요블안분산사`,``,`동요불안분산사**전화위복`,`관재`,`음4,5월화재주의**동요분산사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외사 출행이동사-내사 급속사**외사 동요분산사`,``,`가정부부남녀쟁투사`,`도적악의-공격말 것`,`미초희경사-이동사`,`내부가정손해가출사`,`외사급속사`,`좋은 소식 기대사`,`내녀 색정사**내부가정파재극처사`,`간인궤계사`,`소인쟁재사`,``,`명예직녹자산권리사**외교막힘-반목사`,`견련반복사`,`외남 색정사**내부가정막힘`,`부부반목사`,`부부남녀화합사`,`불리고시사-구설근심사`,`매사 영속성없음-외사파재실종도망사`,`종교진출하면자립함`,`매사 영속서없음`,`종교진출하면자립함`,``,`비곡차욕사-훼절원통`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,`관재`,``,`전도**빈궁**병재`,``,`전도**빈궁`,``,`관재`,`전화위복**재액해소`,`빈궁**관재`,`재액해소`,``,`빈궁**관재`,``,`내사출행이동사-실기즉퇴가좋음`,`빈궁`,`간사은닉**내부가정반목사`,``,`전화위복`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사 위역사`,`재난전소사`,`구설송사 흉-문서소식 길`,`외사 출행이동사-내사 급속사**외사 동요분산사`,`가정부부남녀쟁투사`,`의사 성취사`,`왕레사송계류사-이동사`,`내부가정손해가출사`,`외사급속사`,`傷린충산사`,`내녀 색정사**내부가정파재극처사`,`간인궤계사`,`매사 위역사`,`반화위복사`,`명예직녹자산권리사**외겨막힘-반목사`,`의관행사사`,`외남 색정사**내부가정막힘`,`경의불녕-실재근심사`,`부부남녀화합사`,``,`매사 영속성없음-외사파재실종도망사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**관재`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`전화위복`,`전도`,``,`전도**빈궁`,``,`빈궁**관재`,`실업처지**내사출행이동사`,``,`간사은닉**내부가정반목사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`매사 영속성없음`,`종교진출하면자립함`,`매사 위역사`,`재난전소사`,`구설송사 흉-문서소식사 길`,`외사 출행이동사-내사 급속사**외사 동요분산사`,`가정부부남녀쟁투사`,`의사 성취사`,`왕래사송계류사-이동사`,`내부가정손해가출사`,`외사급속사`,`傷린충산사`,`내녀 색정사**내부가정파재극처사`,`간인궤계사`,`매사 위역사`,`반화위복사`,`명예직녹자산권리사**외교막힘-반목사`,`의관행사사`,`외남 색정사**내부가정막힘`,`경의불녕-실재근심사`,`부부남녀화합사`,``,`매사 영속성없음**내부가정반목사`,`종교진출하면자립함-외사파재실종도망사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`간사은닉**내부가정반목사`,``,`전화위복`,`빈궁**관재`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁**관재`,``,`전도`,``,``,`전도**빈궁`,``,`빈궁**관재**병재`,`전화위복**재액해소`,``,`재액해소`,`빈궁`,`빈궁**관재`,``,`실업처지**내사출행이동사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사 영속성없음-외사파재실종도망사`,`종교진출하면자립함**도적불포사`,`매사 영속성없음`,`종교진출하면자립함`,``,`비곡차욕사-훼절원통`,`발탁영전도약희경사`,`외사 출행이동사-내사 급속사**외사 동요분산사`,`가정부부남녀쟁투사`,`休-불변근신사`,`안 좋은-투서헌책사-구설-失재사`,`내부가정손해가출사`,`외사급속사`,`혼인만족단장사`,`내녀 색정사**내부가정파재극처사`,`관재형책사`,``,`소인쟁재사`,`명예직녹자산권리사**외교막힘-반목사`,`간인궤계사`,`외남 색정사**내부가정막힘`,`소식 대기-와도 나쁨`,`부부남녀화합사`,`승진영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사출행이동사`,`빈궁`,`사행불량사**내부가정반목사`,`빈궁`,`전화위복`,`관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`관재`,``,``,`빈궁`,``,`관재`,``,`전화위복`,`전도**빈궁`,``,`전도**빈궁**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`부부남녀화합사`,`승진영전사`,`매사 영속성없음**외사파재실종도망사`,`종교진출하면자립함**도적불포사`,`매사 허성불성-세속허사자`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,``,`비곡차욕사-훼절원통`,`발탁영전도약희경사`,`외사 출행이동사-내사 급속사**외사 동요분산사`,`가정부부남녀쟁투사`,`休-불변근신사`,`안 좋은-투서헌책사-구설-失재사`,`내부가정손해가출사`,`외사급속사`,`혼인만족단장사`,`내녀 색정사**내부가정파재극처사`,`관재형책사`,``,`소인쟁재사`,`명예직녹자산권리사**외교막힘-반목사`,`간인궤계사`,`외남 색정사**내부가정막힘`,`소식 대기-와도 나븜`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**관재`,``,`내사출행이동사`,`빈궁`,`사행불량사**내부가정반목사`,`빈궁`,`전화위복`,`전도**관재`,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁`,``,`전도**빈궁**관재`,`재액해소`,``,`재액해소`,`빈궁`,``,`전도**관재`,`빈궁`,`폐한인**전화위복`,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외남 색정사**내부가정막힘`,`비상지애사`,`부부남녀화합사`,`남모르는 능욕박해사`,`매사 허성불성-세속허사자-외사파재실종도망사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자**구설사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사위역사`,`재난전소사`,`발탁영전도약희경사`,`외사 출행이동사-내사 급속사**외사 동요분산사`,`가정부부남녀쟁투사`,`탄식신음사-병-주력사 미수사`,`觀書-문화풍아-실업 처지`,`내부가정손해가출사`,`외사급속사`,`도적실세-잡음`,`내녀 색정사**내부가정파재극처사`,`事後 쟁탈사`,`매사 위역사`,`반화위복사`,`명예직녹자산권리사**외교막힘-반목사`,`간인궤계사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재액해소`,`빈궁**관재`,`환성경사-부족**내사출행이동사`,``,`내부가정반목사-문서답신지체`,`빈궁`,`전화위복**재액해소`,`빈궁**관재`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도`,`재액해소`,`빈궁**관재`,``,`빈궁**병재`,``,``,`재액해소`,`빈궁**관재**병재`,`빈궁`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 강해서 부러진 상이네?
  내 것 좋은 줄 모르고 더     욕심내다가는 망신만 
  당하게 생겼어! 
  있는 것을 개혁시키는 쪽이     더 좋겠어!
`,`■ 병은 깊고 집 권리는 
  넘어가고… 관재 고심과     男女삼각관계도 있네? 
  1男 2女야!
⊕⊖전흉사귀를 잡아야 도망      가는 일이 없어질거야!

⊕⊖신변상가부정이 침범했네?
`,`■ 집에 귀신과 부정이 잔뜩     들었네?

⊕가택귀ㆍ가택목기부정, ⊖전흉사귀ㆍ가택상가부정이야!    그대로 두면 病官災로 
 사람과 집이 다 상하게 생    겼어!
`,`■ 일이 두 가지로 벌어져       이러지도 저러지도 못
   하고 있네? 
   가정도 불안하겠는데 죄인        을 숨겨두고 있는 격이야!
   일을 하나로 묶고 사적      으로 해야 하겠어!

⊕신변상가부정이 침범했네 ?
`,`■ 바람은 세고 파도는 높고… 
  게획을 변경시켜야만 하겠어 !
男：직위보전의 고민이 
  생기겠어!
女：부부이별의 기운이 있네?

⊕신불제사귀, 
⊖음귀 발동이야!
`,`■ 지금까지의 일과 인연이     다 끝나겠네?
男：처가 임신하겠네?

⊕변사․신불제사귀
⊖전사․음귀를 없애야 재기    를 하지!
`,`■ 배반당했네? 
  부부가 똑같이 바람났어!     어디 의지하고 믿고 갈데     가 없겠는데, 
  기도가 살 길이야!
⊖가택음사부정이 들었어!
`,`■ 도장 팔 나무가 썩었으니     일이 되겠어? 
  二月에 기쁨을 맞겠어!

⊕⊖가택귀ㆍ여귀부터 해소      시키고 卯月을 기다려봐 !

⊕⊖신변수망노부부정이           들었네 ?
`,`■ 여러일들을 결속시키겠는데      귀신과 부정이 집안에 
 가득찼네? 그러니
男：직위해탈, 
女：부부 이별수가 생기지!
⊕전사․도로귀 가택음사부정, ⊖변사․여귀 가택수망노부     부정이 들었어.
`,`■감추고(죄인) 속여도 가슴은    놀랍고 답답하겠어! 
 비용만 나가지 되는 일이    없네?

그래도 앞장서지는 말아야지! 기도하세요! 
서서히 풀릴거야 !
`,`■ 男：저주를 받고 있네?
     근신이 필요 해 !
女:⊖남의 남자를 뺏었네?         여기저기 이것저것 모두       안심이 안돼! 
⊕도로․음귀
⊖신불제사․여귀부터잡고      봐야겠어!
`,`■ 선택의 기로에 섰네? 
  불안한 마음으로 전진이     안되고 있어! 
  1女2男 삼각관계도 있고! 
⊕도로․음귀
⊖신불제사․여귀를잡아야     길이 열리겠어!
⊖신변상가부정이 침범했네?`];
  const YOGYEOL1 = [`■ 좋은 덕ㆍ녹이 내게 있는데 욕심을 더 내서 잘되려고 무엇을 또 하면 무례한 일을 당해 흉하리라.
  `,`■ 먼저 의심하다가 나중에는 따르니 거짓인줄 알고 물러나라.
  병이나 소송을 만나면 낮밤정단 모두 나쁘다.
  `,`■ 가택에서 발동되어 추전이 되니 낮정단은 오래 살기 어렵다. 재물이 살을 도와주니 나쁜 일로 고생하리라.
  `,`■ 밤정단은 숨은 귀살이 범까지 타고 있다. 
  재물은 없어지고 처는 고생한다. 중간과 결과가 모두 비어 집안이 망할까 걱정이다.
  `,`■ 정화 귀살이 사람에게 있는데 암귀가 집에도 들었다.
  여자나 재물은 칼 끝의 꿀과 같고 밤정단은 더욱 나쁘다.
  `,`■ 밤정단은 숨은 귀신이 범을 타고 있다. 부모나 남의 덕 보기는 어렵겠다. 혹 재물을 잃거나 혹 처나 종업원이 도망가리라.
  `,`■ 두 귀인은 나를 도울 수가 없다. 노름이나 도박을 하면 크게 딴다. 눈에 보이는 것 모두 돈이기는 하나 몸과 마음이 피곤하기만 하다.
  `,`■ 두 귀인이 나를 돕게 되니 다시 직장을 구하거나 복직되리라.
  오래 지체된 자는 다시뽑혀 잘 되겠다.
  `,`■ 밤정단은 하늘이 나를 해치나 다행히 구해주는 자가 있겠다.
  낮정단은 좋은 것이 없으니 관직자는 좋지 못하다.
  `,`■ 형체가 없으니 그림자도 없다. 활은 쥐었는데 쏘는 것을 잊었다.
  말전이 양인이고 병과 도둑이 있으니 凶은 남고 吉은 없어진다.
  `,`■ 처음은 묘고 나중은 생이니 교섭사는 매우 좋다. 힘없는 원숭이라 기댈 수도 없고 여윈 말도 타기 어렵겠구나.
  `,`■ 상대가 힘을 내서 내게 왔으니 만약 이사하거나 팔려고 하면 사기 맞거나 좋지 않은 일이 있겠다.`]
  const SIBO1 = [`■강고하면 일이 폐색되나
  유순하면 일이 이루어지는
  운세이다.
  가택은 불안하고 몸은 움직일 수 없다. 
  계획과 진행사를 다시 고치거나 쉬는 것이 좋다.
  자손의 凶이 기하고 속는 일이 있게된다.
  기도ㆍ치성하면 천우신조가 있게 된다.
  `,`■처음은 흉조이나 차차
  좋아지는 운세이다.
  인정미가 없고 병에 걸리니
  일에서 물러서는게 좋다.
  가택등의 권리가 넘어가니 존대 받을 수 없는 지경에 있다.
  굴욕받는 일이 있다.
  1남 2녀 삼각관계가 있다.
  `,`■가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  인ㆍ택이 불안하고 후퇴한다. 무리한 금전이나 여자를 취하면 재화를 초래하게 된다.
  타인이 와서 도와 길이 되고 불행중 다행이 된다.
  七月 임신사가 있다.
  `,`■이익건이 있고 소득한다.
  七月 임신사가 있다.
  속는 일, 자손에 凶이 기함.
  財ㆍ女 문제로만 길흉을 판단하고 첫조건에 응해야 좋다. 
  기회는 불리하다.
  일이 두가지이므로 한가지로 묶어야 이롭게 된다.
  주색에 의해 실패사가 있다.
  `,`■자충수적 우환이 있다.
  방침을 급히 변경시켜야
  이롭게 되고 그대로 가면
  막히게 된다.
  움직이면 좋고 조용하면 편할 수 없다.
  금전사는 목적 달성되나 속는 일이 일어나고 자손의 凶이 기한다.
  `,`■변동수가 있다.
  가택ㆍ생업을 옮기거나
  새롭게 한다.
  모든 일이 파란 기복이 심하고 가정에 임신사가 있다.
  금전이 옹색하다.
  子ㆍ未 년월에는 우물의 재앙이 있거나 가산이 기운다.
  七月 임신사가 있다.
  `,`■금전사로 심신이 피로하다.
  의지가 어렵고 반복이
  평상치 않다.
  일이 두가지로 번졌다.
  뜻대로 안된다.
  모두 배반된다.
  단, 구재에는 큰 돈을 획득하고 존친의 근심이 발생한다.
  七月 임신사가 있다.
  `,`■여인의 비곡차욕사가 있다.
  자손사 凶이 기함.
  속는 일도 있게 된다.
  관공직자는 기쁨이 있으나 일반인은 조건의 부족으로 일의 규모를 이루지 못한다.
  노력으로 후일 이루어진다. 
  戌띠자는 吉하다.
  `,`■가정에 부정이 들었다.
  공중누각의 형세로
  모두 빈소리 뿐이다.
  여러 사람의 일이다.
  또는 위탁시킨다.
  남자는 직위해탈의 근심이 있고 여자는 극부ㆍ별리사가 기한다.
  ⊕음사부정, ⊖수망노부부정이 들었다.
  `,`■인ㆍ택이 소란하고
  불화한다.
  七月 임신사가 있다.
  물건을 은폐하거나 속이는 사건이 있다.
  또는 도난ㆍ분실 사건이 일어난다. 그러나 해는 경미하다. 사물이 계속 연결되기만 하고 성공이 안된다.
  `,`■저주사가 있다.
  모두 처음에는 혼미하고 
  후에 강성해지는 운세이다.
  만사가 간난후에 이루어진다.
  ⊕길조가 오고
  ⊖여인이 남의 남자를 유혹하여 따르고 있다.
  七月 임신사가 있다.
  자손이 凶이 기한다.
  `,`■가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  진퇴양난이고 일이 완전히 이루어지지 못한다. 
  모사도 졸책이 되고 만다.
  두가지 중 하나로 선택 결정해야만 불안이 해소된다.`]
  const SIBO2_1 = [`■직ㆍ녹,신변외사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■관ㆍ부·상복·병재사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 관ㆍ부,가정내사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女,가택동요쟁투사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 관ㆍ부,가택시의사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고男직위상실하고女부부이별한다.
  `,`■ 財ㆍ女,가택남녀상쟁사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■財ㆍ女,가택상쟁사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 財ㆍ女,신변동요사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 관ㆍ부,가택시의사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 男직위상실하고女부부이별한다.
  `,`■ 財ㆍ女,가택동요사이다.
  내방조짐 상의 :  
  흉재소산 하사도 초에는 불성함. 利 암사 불리하다.
  `,`■ 관ㆍ부,가택내사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  매사 초에는 불성한다
  `,`■ 자손ㆍ박관신변외사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.어떤 일이든 처음 불성한다속는 일이 있고 자손 재액이 있다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/049/row-1-column-1.png`,
    `/static/images/sagwa/049/row-1-column-2.png`,
    `/static/images/sagwa/049/row-1-column-3.png`,
    `/static/images/sagwa/049/row-1-column-4.png`,
    `/static/images/sagwa/049/row-1-column-5.png`,
    `/static/images/sagwa/049/row-1-column-6.png`,
    `/static/images/sagwa/049/row-1-column-7.png`,
    `/static/images/sagwa/049/row-1-column-8.png`,
    `/static/images/sagwa/049/row-1-column-9.png`,
    `/static/images/sagwa/049/row-1-column-10.png`,
    `/static/images/sagwa/049/row-1-column-11.png`,
    `/static/images/sagwa/049/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `   이 과는 12궁위(宮位)에 엎드려 움직이지 않으므로 복음과(伏吟課)로 된다. 복음과의 연식원칙(演式原則)에 이르기를 ｢12신이 각 궁에 있고 극이 없을 때는 일간상신을 사용하여 초전으로 하니 자임격(自任格)이다｣고 하였다.
    양은 강(剛)하다 하여 스스로 강을 용신(用神)으로 한다. 정단사는 강고(强固)하게 나오면 폐색(閉塞)되지만 유순하면 일이 이루어진다. 이와 반대로 음일은 유(柔)로서 극이 없을때는 유하기 때문에 일지를 초전으로 하며, 음은 스스로 유하다고 자신(自信)한다. 그러므로 이 이름을 격으로 한다. 천지신이 움직이지 않고 극하지 않으므로 모든 일에서 몸을 움직일 수가 없다. 가택은 불안하다.
    초전이 진오유해(辰午酉亥)의 자형(自刑)인 경우에는 두전격(杜傳格)이라 하여 중전은 일지상신, 말전은 형(刑)을 사용한다. 점단(占斷) 사건은 중지하고 고쳐서 사용한다면 이루어진다. 있는 자는 이동하고 만나는 자는 헤어진다. 일을 다시 고쳐서 하는 것이 길하다. 3전에 용․상 귀신이 승할 때는 앉아서 기쁨을 만난다. 백호․육합․이마(二馬)가 승할 때는 고요한 가운데 움직임이 있으며, 남에게 음신이 온다. 구진을 보면 굽혀서 펴지지 않으며, 정동(靜動)이 모두 정체된다. 태음은 음사(陰私)를 명백하게 하기 어렵고, 천공은 허사부실(虛詐不實)하다. 육합을 맹신에 보고 3전이 양이면 남아가 탄생된다. 자오(子午)가 완전하면 외부로부터 신임하는 일이 있다. 묘유(卯酉)는 가정사이고 4계(季)가 완전하면 전산(田産)의 다툼이며, 4맹이 완전한 것은 남의 협력에 의하여 움직인다. 일간 왕상은 길조가 있다. 조용하면 좋고 움직일 때는 정체된다.
    이 과는 3기(三奇)가 명을 지켜 화(禍)를 해소하며 복력을 받는 과전은 모두 녹왕(祿旺)이다. 모든 일이 길조로 화한다. 말전이 공망되면 끝이 완전하지 못하다. 
    백법에 ｢녹왕이 신(身)에 임하면 함부로 망동하지 말라｣고 하였다. 일귀는 순행하며 3전은 상(常)․현(玄)․귀(貴)이고, 야귀는 역행하여 3전이 공(空)․청(靑)․주(朱)이다.`
    ,
    // 2
    `   이 과는 4과 중에서 3과밖에 없으니 불비격(不備格)이며, 1상이 1하를 극하여 원수과(元首課)로 된다. 일간상신 술(戌)이 귀살로 되어 처음에는 흉조이나, 중․말전의 살이 인(印)으로 화하여 내몸을 생하므로 흉은 화하여 길조로 된다. 백호가 간(干)에 임하여 초전으로 되니 인정미가 없고, 내몸은 병에 걸리며, 3전이 퇴여격(退茹格)이니 일에서 물러서는 것이 좋다.
    백법에 ｢일지상신 건록은 권섭부정격(權攝不正格)으로 된다｣고 하였다. 백법 제8법을 인용한다. ｢일간의 녹신이 일지상신에 가해지는 것을 말하는데, 이 격은 내몸이 존대받을 수가 없다. 남에게 굴욕을 받고 직업상에서는 권섭(權攝)이 바르지 못하며, 복록을 받으나 복록은 딴 것으로 바뀌어 출재한다.｣ 즉 가정에서 녹을 얻는 일이다. 이 격은 매일 1과씩 있다.
    다음에 지록극탈격(支祿剋脫格)이 있는데 유일(酉日)의 제7국과 같은 것을 말한다. 비전에서는 ｢일간상신에 묘(墓)가 승하여 백호를 보면 흉은 신속하다. 일간기궁(日干寄宮)의 해(亥)는 일간상신에 임하여 왕록에 붙어 가정에 식록(食祿)이 두텁다｣고 하였다. 양일(陽日)의 불비는 양을 남기고 음을 버린다. 이러할 때는 2양 1음으로 되어 음의 불비격이라는 것을 알아야 한다. 
    일귀는 순행하며 3전은 백(白)․공(空)․청(靑)이며, 야귀는 역행하여 3전이 백(白)․상(常)․현(玄)이다.`
    ,
    // 3
    `   이 과는 제3과에서 일상이 일하를 극하여 원수과이다. 3전은 역간전격이고 3전이 술신오(戌申午)는 패려격(悖戾格)이라고 한다. 오(午)는 1음이 처음으로 생기고, 신술(申戌)은 음의 경계에서 깊은 데로부터 옅은 데로 물러서니 재해를 피할 수가 없다. 부지런히 후퇴하는 형상을 이룬다. 기다리는 사람은 오지 않고, 통신도 오지 않는다. 일지 택상의 술(戌)을 초전으로 하고 백호가 앉아서 일간을 극한다. 말전의 오(午)는 재효와 병(丙)의 암재(暗財)이니 귀(鬼)로 화한다. 이 재를 수득하면 재화를 초래한다.
    일간일지상신은 육해로 되고 일간일지를 극하여 인택이 불안하다. 오직 일간상신의 유(酉)에 태상이 승하여 일간을 생하니, 남이 와서 나에게 기쁨을 주고 불행을 변화하여 길로 된다. 이와 같은 과전에서 귀효(鬼爻)로 되면 년명상신에서 이것을 제어하면 재화를 제거한다.
    백법에 ｢백호가 칠살에 승할 때는 재화가 신속하고 적지 않다｣고 하였다. 패려격(悖戾格)이란 술(戌)과 오(午)가 반회(半會)하여 화(火)로 되고 중전 신금(申金)의 장생을 극하므로 이 같은 명칭을 이룬다. 그러나 중전과 일간상신의 유(酉)는 일간을 생하고, 흉을 떠나 불행 중의 다행으로 된다. 일귀는 역행하며 3전은 청(靑)․백(白)․현(玄)이며, 야귀는 3전이 백(白)․현(玄)․후(后)이다.`
    ,
    // 4
    `   이 과는 제2과․제4과에서 2상이 2하를 극하며 양일양비(陽日陽比)를 취하여 오유(午酉)를 초전으로 하니 지일과(知一課)로 된다. 3전의 중․말전이 공망으로 되어 전(傳)으로 돌지 않으므로 초전으로써 간점한다.
    지일과(知一課)의 원칙에 이르기를 ｢무릇 4과 중에서 2상이 하를 극한다｣고 하였다. 음양의 일(日)에서 오늘과 같은 것, 즉 양일은 양의 극, 음일은 음의 극을 사용하여 초전으로 한다. 두 과의 극에 의하여 움직이므로 일은 두 갈래에 걸친다. 음양이 섞이면 비화(比和)의 극을 선택한다. 비화의 하나를 사용하므로 지일과라고 한다. 무슨 일의 정단이 모두 가까이에 있다. 내가 계책하는 것에 이롭고 해로운 것은 밖에서 일어난다. 찾는 사람이나 분실물은 가까이에서 찾으라. 3극은 도액격(度厄格)이며 4극의 무록격(無祿格)은 동란(動亂)․호의(狐疑)가 있다.
    이 과와 같이 두 극에 그치고 하나를 선택할 때는 길상을 이룬다. 일은 동류에 의하여 일어나고 혼인은 화합되지 않는다. 도망자는 멀리 가지 않는다. 도난은 이웃의 소행이다.
    백법에 ｢둔간귀살(遁干鬼殺)은 재앙이 적지 않다｣고 하였다. 비전에는 ｢자일(子日)에 유(酉)가 가해지고 유(酉)가 오(午)에 가해져서 초전으로 되고, 현(玄)․후(后)가 승하면 3교격(三交格)으로 되어 사사(私事)에는 이로우나 공사에는 불리하다고 하였다.
    유(酉)는 임(壬)의 목욕(沐浴)이다. 태상이 승하면 주색에 의하여 가정을 망친다. 일간상신이 장생에 승하면 왕상으로 되며 흉은 경미하다. 오묘자(午卯子)는 고개승헌격(高蓋乘軒格)이며, 인신월(寅申月)은 천마로 되어 공명과 이윤을 보는 일은 가장 길조가 있다. 중․말전이 공망한다. 년명상신에 묘(卯)가 승하면 전실(塡實)은 해공(解空)으로 되어 길하게 된다. 간지상신이 서로 생하고 함께 생하는 것은 제우(際遇)를 얻는다. 일귀는 역행하며 3전은 현(玄)․귀(貴)․합(合)이고, 야귀는 3전이 후(后)․주(朱)․청(靑)이다.`
    ,
    // 5
    `   이 과는 제1과․제2과에서 2상이 2하를 극하니 비용과(比用課)를 채용할 수 없으므로 섭해과(涉害課)로 되며 지반 4맹신의 극이다. 미해(未亥)를 사용하여 초전으로 하며, 3전은 곡직격(曲直格)이나 중․말전이 공망하여 전(傳)으로 돌지 않으므로 초전에서 길흉을 구해야 한다. 
    초전의 미(未)에 정신(丁神)이 둔간(遁干)하여 일간의 재로 된다. 4맹상신(四孟上神)을 초전으로 하는 것은 견기격(見機格)이니 위험을 피하고 안태함을 구해야 한다. 처음은 어렵고 후에는 쉽다. 옛 것을 버리고 방향을 고쳐 움직여야 한다. 구태를 지킨다면 더욱 삽체된다. 곡직(曲直)은 움직이면 길하고 조용하면 안태치 못하다. 일간일지는 교차육해(交叉六害)로 되니 내부적으로 의심이 생긴다.
    백법에 ｢수일정신(水日丁神)은 재의 일로 인해 움직임이 있다｣고 하였다. 비전에는 ｢임자일(壬子日)에 태상이 미(未)에 승하여 해(亥)에 가해지고 묘월(卯月)이 동기(死氣)로 되고 백호가 택상에 승하면 내외로 사상사(死喪事)가 있다｣고 하였다. 
    3합목국(三合木局)은 임일(壬日)에서 자손효(子孫爻)로 된다. 재를 구할 때에 목적이 달성된다. 정신재동(丁神財動)은 먼 곳에서 오는 송금을 받는다. 
    일귀는 역행하며 3전은 상․귀․구이며, 야귀는 3전이 음․주․공이다.`
    ,
    // 6
    `   이 과는 4과 중 3과에 상하의 극이 있다. 하에서 상을 적하는 오해(午亥)를 초전으로 하여 중심과(重審課)로 된다. 말전은 일간의 장생이나 술(戌)은 둔간귀(遁干鬼)로 된다. 그러나 초전의 오(午) 또는 제4과의 인(寅)에서 제어하므로 귀(鬼)는 두려울 것 없다. 
    초전의 오(午)는 처재(妻財)이며, 지신(地神) 해수(亥水)에서 극되어 구재는 경미하며, 처첩은 재병(災病)에 걸린다. 4과 중에 상하상극이 세 자리나 있으므로 모든 일이 반역으로 되고 파란기복하며, 중전에 축오(丑午)가 중첩되면 복태격(腹胎格)으로 되어 처첩 또는 집안에 임신이 있다.
    백법에 ｢3전이 체생되어 남의 천거를 받는다｣고 하였다. 오(午)의 재에 천후(天后)가 승하면 재가 협극(夾剋)되어 자유를 얻지 못한다. 일지(日支) 자(子) 위에 미(未)가 가해지고 자(子) 또는 미(未)의 해나 달에는 우물의 재앙(災殃)이 있거나 혹은 가산이 기울 우려가 있다. 
    일귀는 역행하며 3전은 현․주․백이고, 야귀는 3전이 후․구․현이다. 병의 정단은 극히 흉하다.`
    ,
    // 7
    `   이 과는 천지가 전부 상극한다. 양일은 양의 극을 사용하여 오자(午子)를 초전으로 하며, 반음비용과(返吟比用課)이다. 일간일지상신 및 삼전이 모두 재효로 되어 세력이 분열되어 심신이 피로하다. 그러나 양인건록(羊刃建祿)이 있어서 내몸이 강하고 재가 왕하므로 구재에서 큰 재를 얻는다. 과전이 모두 상극하므로 의지할 곳이 없는 격이며, 반음과는 반복이 비상하여 길한 가운데 흉이 있다.
    백법에 ｢일야(日夜) 양 귀신(兩 貴神)이 상하상극하면 윗사람이나 귀인의 힘을 얻지 못한다｣고 하였다. 반음과의 원칙에 이르기를 ｢12지지(地支)는 모두 충위(冲位)에 있어서 상극을 취하여 용신(用神)으로 한다｣고 하였다. 
    제신이 그 자리에 돌리는 일간음양(日干陰陽), 일지음양(日支陰陽)이 왕래하며, 극적(剋賊)이 반복(返腹) 신음(呻吟)하므로 반음과라고 이름한다. 오는 사람은 떠나려고 생각하고 떠나는 자는 돌아오려고 생각한다. 득실이 일정치 못하므로 반음과는 일명 무의격(無儀格)이라고도 한다. 정단사는 두 길을 지닌다. 움직이려고 하지만 뜻대로 되지 않으며, 화(禍)는 밖으로부터 일어나고 모든 일이 밑에서 발생한다.
    정의를 정하기 어려우며 병에는 두 증세가 있다. 가는 자는 도로가 통하기 어려우며 부자간에는 화목하지 못하다. 도주한 자는 먼 곳을 찾아야 한다. 만나는 자는 이별하고, 부처는 외정이 있으며, 사물은 증복된다. 사용인은 주인을 반역하고 친우는 신의를 저버린다. 자오(子午)에 사호(蛇虎)가 앉아 관재(官災)․병난(兵難)이 있다.
    일귀는 역행하며 3전은 현(玄)․합(合)․현(玄)이며, 야귀는 순행하여 3전이 사(蛇)․백(白)․사(蛇)이다. 현무․사호가 승하여 공포 사건이 많다. 의리를 지키면 안태하고 탐낼 때는 불량하게 된다.`
    ,
    // 8
    `   이 과는 제1과․제3과에 상하의 극이 있다. 하에서 상을 극하는 사자(巳子)를 사용하여 초전으로 하는중심과이다. 3전의 사술묘(巳戌卯)는 주인격(鑄印格)이지만 말전이 공망하여 파인(破印)으로 되니 규모를 이루지 못한다. 그러나 힘써 노력하면 후에 이루어진다. 일간상신은 일지에서 보아 화개(華蓋)가 묘(墓)에 엎드린다. 그러나 중전의 술(戌)은 진(辰)을 충하여 개묘(開墓)로 되니 삽체가 풀린다.
    백법에 ｢전후인종(前後引從)은 승천(昇遷)하는 기쁨이 있다｣고 하였다. 백단법(百斷法)의 제1법에 해당하며 일간인종법(日干引從法)이다. 즉 기궁(寄宮) 해(亥)의 앞은 자(子)이며 초전의 지반에 있고 말전의 술(戌)은 해(亥)의 뒤에 해당하는데, 말전이 지반에 있으면서 이 격을 만나면 관공직이 승진하는 기쁨이 있다. 년명상신(年命上神)일 때는 묘(卯)의 공망은 전실(塡實)하여 해공으로 되어 더욱 길조를 얻는다.
    일귀는 역행하며 3전은 음(陰)․청(靑)․귀(貴)이며, 야귀는 순행하여 3전이 귀(貴)․청(靑)․음(陰)이다.`
    ,
    // 9
    `   이 과는 제2과․제3과에 상하의 극이 있다. 법칙에 따라 하에서 상을 극하는 제2과의 묘미(卯未)를 취하여 초전으로 한다. 일간에서 보아 3전의 목국은 설기로 되어 공망을 즐긴다. 초전․말전이 공망하고 천공(天空)이 승하면 공중중(空中空)을 만나므로 공중누각과 같이 모든 일이 빈소리뿐이라는 것을 알아야 한다. 곡직공망(曲直空亡)이므로 썩은 나무로 되어 그릇을 이루지 못하고 길흉이 모두 성립되지 않는다. 초전에 정신(丁神)․승하여 수일정재(水日正財)가 움직인다. 토(土)는 일간의 귀효이지만 일간상신이 귀를 제어하므로 두려울 것이 없다. 과전이 3합회국(三合會局)인 것은 한 사람의 일이 아니고 다수의 일이다. 3합회국(三合會局)을 이루는 것은 전국격(全局格)이라 하고, 해묘미(亥卯未)는 곡직격(曲直格)이라 부른다. 3방이 회합되는 것은 사물이 단결하며 무슨 일이나 반드시 성취된다. 결혼에는 이롭고 모사는 유익하다. 예를 들면 정단의 유신이 3전 중에 있는 것은 모두 목적이 달성된다. 곡직격은 구리 건조․구재․식재(植材) 및 기타 무슨 일에서나 통한다. 춘월은 천시를 얻어 대단히 가량하다. 목국(木局)은 수(水)로써 나무의 뿌리로 된다. 먼저 굽히고 후에 편다. 
    초전․말전이 함께 공망하여 시종 완전하지 못한다. 질병은 풍(風)으로 인한 질환이다. 무슨 일에서나 복잡하여 한 사람이 아니며 공동․회합․대중의 건이다. 그렇지 않으면 두세 건의 용건을 남에게 위탁할 징조가 있다. 사물이 가고 사물이 오는 과이다. 공동 집단의 사업은 길하나 해산하는 일은 불리하다. 공망이 되면 용(用)을 이루지 않는 것이다.
    일귀는 순행하며 3전은 구․상․귀이고, 야귀는 3전이 주․공․음이다. 비전에는 ｢묘신(墓神)이 택상에 덮이면 집안에 혼회(昏晦)하고 밝지 못한 일이 있다｣고 하였다.`
    ,
    // 10
    `   이 과는 상하에 상극이 없고 사극(斜剋)이다. 제4과의 오묘(午卯)를 초전으로 하여 요극탄사과(遙剋彈射課)이다. 4중일(四仲日)에 정단하여 3전이 오유자(午酉子)이면 3교격(三交格)이다. 4과 전부가 공망하고 초전이 또 공망하며, 일야천을귀신(日夜天乙貴神)도 공망한다. 따라서 귀(貴)를 구해도 용(用)을 이루지 못한다. 3교격은 물건을 은폐하여 속이는 사건이 일어나고 또 허사소모(虛詐消耗)하는 일이 있다. 4과가 전부 하에서 상을 탈설(脫泄)하니 근단원소(根斷源消)의 형상이 있으나 오히려 공망을 즐겨 모든 해나 화를 면한다.
    백법에 ｢인택이 함께 설탈하는 것은 도난․분실․사건이 일어나나 공망하여 극히 경미하였다｣고 하였다. 병을 정단하면 가정의 출재에 의한 심로가 원인이다. 기를 보하여 치료해야 한다.
    비전에는 ｢현무가 현(玄)에 임하면 현무의 본궁에 든다고 하여 실탈․도난 등의 사건을 만난다｣고 하였다. 남에게 위탁하는 것은 보류해야 한다.
    제4과를 초전으로 하는 맥월격(驀越格)으로 되어 모든 일이 급작스레 일어나거나 종결된다. 초전 오화(午火)는 재동이지만 공망의 지에 앉으니 구재의 목적이 달성되지 못한다. 탄사격이 공망하여 탄알이 없으니 길흉이 모두 경하다. 또 기력이 없는 과격이다.
    일귀는 순행하며 3전은 합(合)․공(空)․현(玄)이고, 야귀는 3전이 사(蛇)․구(勾)․백(百)이다. 3전 중에 중장(仲將)이 앉으면 참다운 3교격(三交格)에 해당하며, 사물이 연결된 사건들이다.`
    ,
    // 11
    `   이 과는 4과 중 제4과에 상하의 극이 있다. 하에서 상을 극하는 진인(辰寅)을 초전으로 하여 중심과(重審課)이다. 격(格)은 여덕(勵德)․간전(間傳)․일녀(泆女)․등삼천(登三天)․육의(六儀) 등으로 앉는다. 말전은 일간의 장생으로 묘(墓)에서 말전을 생한다. 일간일지에 교차지합하여 모두가 처음에는 혼미하고 후에 강성한다. 교관(交關)교섭사는 유리하게 타결된다. 그러나 합 중에 공망하고 또 초전이 공망에 승하니 길흉이 모두 경미하다. 다행히 육의(六儀)가 앉아 흉을 화하므로 길하게 한다. 만사가 간난을 겪은 뒤에 이루어진다.
    백법에 ｢일야 두 귀신이 공망하니 윗사람․존장의 힘을 얻지 못한다｣고 하였다. 진인(辰寅)은 강색귀호격(罡塞鬼戶格)으로 되어 모사에 적합하다. 귀(鬼)를 제어하는 지(地)에서 양의(良醫)를 얻는다. 이 과로써 가운을 정단하면 가택은 동남 방위로 전거하거나 또는 이 방향으로 활동하면 명리나 천수(天壽)가 모두 완전할 수 있다. 그러나 자식은 무력하거나 사망하고, 후에 손자대에 가문을 이어 길조가 있다. 예를 들면 수생목(水生木)을 생하는 인(寅)은 자(子)이다. 초전으로 되어 공망하고 진(辰)은 중전에 옮긴다. 즉 진(辰)은 동(東), 오(午)는 남이다. 오(午)는 재효로 되고 오(午)의 재는 신(申)의 장생에 옮긴다. 장생은 장수할 암시이다. 이상과 같이 활용하여 정단해야 한다.
    진오신(辰午申)은 등삼천격(登三天格)이다. 구직․공명을 관점하면 초전이 공망하고 말전이 장생이라면 초기에는 간난스럽지만 후에 다소 이루어진다. 
    일귀는 순행하며 3전은 사(蛇)․합(合)․청(靑)이므로 사(蛇)가 화하여 청(靑)으로 되니 길조를 이룬다. 야귀는 3전이 후(后)․사(蛇)․합(合)이고 일녀격(泆女格)으로 되니 여자가 남자를 유혹한다.  `
    ,
    // 12
    `   이 과는 세 자리의 음양의 극이 있다. 양일 양비라 하여 양의 극이다. 인축(寅丑)을 사용하여 초전으로 하니 지일격(知一課)이다. 3전이 인묘진(寅卯辰)이면 진여격(進茹格)이나. 공망하므로 퇴여격(退茹格)으로 변화된다. 4과이지만 3과뿐으로서 2양1음의 불비격(不備格)이다., 만사에서 불비하고 불만스러운 형상이며, 일은 완전하게 진행되지 않는다. 진여공망은 물러서서 지키는 것이 좋다. 일간일지상신 자축(子丑)이 지합하며, 지일과(知一課)는 무슨 일이나 동류에 의하여 일어난다.
    백법에 ｢나망격(羅網格)은 모사가 졸책(拙策)을 이룬다｣고 하였다. 지일과는 비린(比隣)이라 하여 근린(近隣)의 형상이다. 일지는 택이다. 근린이 지합하는 것은 피차가 서로 화합된다. 또는 무엇인가 계책하려는 사건이 생긴다. 지일과는 사물이 두 갈래에 걸치는데 하나를 선택한다, 즉 양일에는 양비를 채용한다. 재화(災禍)는 밖으로부터 일어나고, 잃어버린 물건이나 찾는 사람은 모두 가까이에 있다. 만사에 화해하는 것이 좋으나 의혹이 생기면 결단하기 어렵다. 도망한 자나 몰래 가출한 자는 멀리로 가지 않는다. 2상이 하를 극하고 양비를 취하여 초전으로 하니 도난․분실 사건이 있고, 친우의 중상(中傷)을 받는다. 상대방에게 이롭고 나에게는 불리하다. 만일 비용과로서 2하가 상을 적하는 경우라면 처첩의 소송․쟁투 또는 원수맺는 일이나 저해 등의 불안이 있다.
    이 과는 먼 것을 버리고 가까이에 붙어야 한다. 소원한 것을 버리고 친근함에 붙어야 한다. 생각해 주는 가운데 걱정스러운 사건을 일으키고 웃음 가운데 의심을 형성한다. 3전이 전부 공망이면 사물이 성취되지 않는다. 일귀는 순행하며 3전이 후(后)․귀(貴)․사(蛇)이고, 야귀는 3전이 현(玄)․음(陰)․후(后)이다. 이 과는 물러서서 지키는 것이 좋고 전진하면 효과가 없다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
