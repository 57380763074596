
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,6,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,9,5,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,4,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,3,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,12,2,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,1,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,2,12,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,11,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,10,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,5,9,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,8,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,7,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위불안사-외 결속단합관련사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`9`,``,``],
    [`자녀`,`자녀 문제사`,`大`,`소외기`,`고위연회`,`관직이동`],
    [`흉심이혼(凶甚耳婚)`,`흉이 심한데 이로운 혼인사를 듣게되는 상`,`大`,``,`초대열석`,`도약`],
    [`음신조격(音信阻隔)`,`투서헌책의 답신을 기다리는 상`,`小`,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`8`,`불변근신`,`소식안옴`],
    [`모친`,`모친관련-1,2,7,8,10,11월 방문자는 묘지사-이외는 토지사`,`小`,`침체기`,`모친문제`,`와도나쁨`],
    [`인재흉화(人財凶禍)`,`입을 다물어야만 위기를 면하는 상`,`小`,``,`침체불통`,``],
    [`불변근신(不辨勤愼)`,`말을 말고 근신해야 하는 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`외 이심동요불안분산사`,`小`,`7`,`분산출행`,`가정막힘`],
    [`출행`,`외사 출행이동원행사`,`小`,`단절기`,`송흉구설`,`작은행사`],
    [`범위폐구(範爲閉口)`,`입을 다물어야만 위기를 면하는 상`,`大`,``,``,`초대사有`],
    [`송흉문길(訟凶文吉)`,`송쟁사는 나쁘고 문서사는 길한 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,``,`약속비밀`],
    [`권리`,`결속하여 음5월 권리사가 완성되나 病 형제 재해 발생함`,`小`,`6`,``,`누설득죄`],
    [`색정`,`내女 색정사 有`,`小`,`존재기`,`도적소실`,`색정사有`],
    [`불리군자(不利君子)`,`일반인은 좋고 관직자는 나쁜 상`,`大`,``,``,``],
    [`불검초죄(不儉招罪)`,`약속된 비밀을 누설해 당하는 죄의 상`,`小`,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`5`,``,``],
    [`공허`,`공허침체사`,`小`,`배양기`,`사행불량`,`관재형책`],
    [`망도재도(網刀在頭)`,`머리 위에 망에 담긴 칼(刑)이 올려진 격`,`小`,``,``,``],
    [`불검초죄(不儉招罪)`,`약속된 비밀누설로 죄에 이른상`,`大`,``,``,``],
    [`관사`,`관사`,`大`,``,``,``],
    [`송쟁`,`남편사-가정반목대립송쟁사`,`大`,`4`,``,``],
    [`급속`,`외 강제급속손실사`,`小`,`개시기`,`급속사有`,`부부이심`],
    [`편지불가(遍地不佳)`,`두루 넓은 땅은 좋지 않고 만약 취득하면 禍가 되는 상`,`小`,``,`우유한가`,`반목송쟁`],
    [`우유한가(優遊閒假)`,`여유있게 머리를 다듬는 한가로운 상-직업이 없는 격`,`小`,``,``,`충산분산`],
    [`관사`,`관사직업남편사`,`大`,``,``,``],
    [`매사허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`3`,`투서헌책`,``],
    [`매사영속성없음`,`관직직업남편사는 선천적 기복재해를 겪음`,`小`,`학습기`,`고위면접`,`사기득재`],
    [`불의동모(不宜動謀)`,`앉아서 하는 것이 좋으나 행동으로 나서면 나쁜 상`,`小`,``,``,``],
    [`엄목지상(掩目之象)`,`눈이 가려진 격으로 사람이 상하지 않는 상`,`小`,``,``,``],
    [`결속`,`결속하여 재원바탕을 이루나 명예직위남편을 훼절함`,`小`,``,``,``],
    [`매사허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`2`,``,``],
    [`매사영속성없음`,`후원문서모친사는 선천적 기복재해를 겪음`,`小`,`면모기`,`엄목지상`,`탄식신음`],
    [`서희관비(庶喜官非)`,`일반인은 좋고 관직자는 안 좋은 상`,`小`,``,`(눈이 가려`,`병, 事미수`],
    [`도적소실(盜賊消失)`,`도적이 없어진 상`,`大`,``,`진 격)`,``],
    [`명예`,`수상(受賞)사`,`小`,``,``,``],
    [`믹힘`,`외사 외교 막힘사`,`小`,`1`,``,``],
    [`출행`,`내사 이심동요불안분산사-출행이동원행사`,`大`,`절정기`,`상린분산`,`출행이동`],
    [`역불가취(亦不可取)`,`타인의 호의를 취하면 안 되는 상`,`小`,``,`불의투서`,`권한수여`],
    [`상린층산(傷鱗分散)`,`고기비늘이 벗겨지듯 상하여 서로 흩어지는 격`,`大`,``,`채택불용`,`부족실소`],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`색정`,`외男 색정사 有`,`小`,`12`,``,``],
    [`가출`,`가정 손해가출사`,`大`,`과시기`,`색정사有`,`손해가출`],
    [`동행무익(動行無益)`,`안거상태에서 움직여봐야 이로울 것이 없는 상`,`小`,``,`도적죄인`,`우려`],
    [`위례득죄(違禮得罪)`,`무례를 매우 그르쳐 죄에 이른 상`,`大`,``,`됨`,`위례득죄`],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`부친`,`부친 관련사`,`大`,`11`,`상대진실`,``],
    [`공허`,`불육(不育)공허사`,`小`,`쇠퇴기`,`판단혼매`,`위례득죄`],
    [`정회혼매(情懷昏昧)`,`상대의 진심(情)을 분간할 수 없는 상`,`小`,``,`도적죄수`,``],
    [`위례득죄(違禮得罪)`,`무례를 매우 그르쳐 죄에 이른 격`,`大`,``,`됨`,``],
    [`박관`,`직업직위불안 손해도망사`,`小`,``,``,`고위안좌`],
    [`극부`,`부부 愛憎갈등사`,`小`,`10`,``,`衆人이익`],
    [`급속`,`내사 급속사`,`大`,`침해기`,`직위불안`,`방법강구`],
    [`우유한가(優遊閒假)`,`얼굴(화장)에 신경쓰지 않아도 좋은 한가한 상-실업자`,`大`,``,`우유한가`,`전포`],
    [`범모가실(凡謀可失)`,`어떤 모사도 이루어지지 않는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,``,`직업직위불안남편갈등자녀문제사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`후원문서모친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`금전사업처조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업여인조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`후원문거부친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,`관사부부불화송쟁강제급속사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`명예자산출행사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`자매외남조력비용지출사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`후원문서부친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,``,`직업직위불안부부갈등사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의심질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`일이 거꾸로 진행되고 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 현명하며방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 띄엄띄엄 진행되어 톼려하나 되려 계속하게 됨**옛 것을 되돌아 봄**일이 결과없이 반복되기만 함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 사안이 정당하므로 일방적 입장을 취해 속결해야만 유리함`,0,0,`선수를 쳐 속결하지 않고 뒤로 미루거나 좌고우면하게 되면 불리해지는 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 현명하며방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러가지 일을 하나로 묶는 결속단합을 하려하나 결속이 되어도 와화내허적이 됨**산속 깊이 숨어버리고 싶은 심정**남자가 타인의 부인을 유혹하여 자기 여자로 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의심질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`임신사는 매우 나쁨**관재발생우려`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오게되니 신중해야 함`,0,0,`부부이심동요분산사가 있고 반복적이며 타인 타세에 의존함`,0,0,`신규사가 있음**상대가 스스로 와서 본인에게 금전을 도움`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의심질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`일이 갑자기 돌발함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 사안이 정당하므로 일방적 입장을 취해 속결해야만 유리함`,0,0,`선수를 쳐 속결하지 않고 뒤로 미루거나 좌고우면하게 되면 불리해지는 人`,0,0,`여러가지 일을 하나로 묶는 결속단합을 꾀하나 되려 큰 손실을 입음**일이 결과없이 반복됨**입을 다물어야만 하는 상태임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 상대와 맞서지 말고 순응하고 유화유순하게 대하며 사안을 뒤로 미루면 결과가 유리해짐`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 유화적 처신으로 일을 지체시키면 결과는 반드시 유리하게 되는 人`,0,0,`신규사가 있음**전화위복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 현명하며방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 띄엄뜨엄 진행되어 퇴하려하나 계속하게 됨**깊은 산속으로 숨고싶은 심정임**관직자는 영전함**남자가 타인의 부인을 유혹하여 자기 여자로 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 상대와 맞서지 말고 순응하고 유화유순하게 대하며 사안을 뒤로 미루면 결과가 유리해짐`,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 유화적 처신으로 일을 지체시키면 결과는 반드시 유리하게 되는 人`,0,0,`일이 순조로울 것 같으나 도려 되하게 됨**일이 갑자기 돌발함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻은  동해도 움직이려하지 않음`,0,0,`자기스스로 자임하고  하던 일을 신음하면서도 고집하며 새 기회를 기다리는 人-국가고시생이 많음`,0,0,`신규사가 있음**송쟁사가 있음**전화위복함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신-무력`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아타동시발동사`,`기사`,`신사`,`외내동시사`,`아표면타이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사-후회사겹침`,`미사`,`신사`,`외사`,`표면이면겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-이전예정`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [` 승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`애증화해`,`애증화해`,`애증화해`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음2월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월-연기 또는 파혼우려`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,3,2,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월-연기우려`,3,1,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,1,3,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,3,3,6,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음9월-연기우려`,1,1,2,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음음5월-파혼우려`,1,1,2,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,3,3,6,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,3,1,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음8월`,1,2,3,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월`,3,3,6,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반이 저조하고 종반에 실적을 내는 듯하나 내부 반목갈드을 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하며 초 중반을 허무하게 보내고 종반에 이르허 실적을 내는 듯하나 외부와 이심동요분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이나 업무는 평상적임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 도움을 얻으나 업무 전반이 외화내허한 결속단합을 꾀하나 그 것마져 불성하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 도움을 얻으나 업무 전반이 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이고 업무를 적극적으로 진행하여 파란을 일으키며 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하며 초반을 허무하게 보내고 종반에 이르러 실적을 내는 듯하나 결국 전체를 무력하게 만드는 대 실패를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 일을 하나로 묶는 결속단합을 꾀하여 결국 큰 손실을 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 높고 업무도 수준을 유지하며 단 종반에 내부 반목을 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무가 초반 이후 허무하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무 전반이 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이며 업무를 수준 이상으로 이어가나 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`측근반대`,`불이행`,`본인무력`,`退背`,` 근`,`去`,`세입가해`,`정서`,``,`정북`],
    [`10.11`,`多集`,`무력`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`본인반대`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`무력`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`주인조력`,`서북`,``,`북북동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선損후不`,`進向`,`  원`,`  留`,`주인조력`,`동북`,``,`동북`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,`주인이후`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`이사함`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`무력`,`불이행`,`선이후난`,`退背`,`  원`,`  去`,`세입가해`,`정북`,`정서`,``],
    [`10.11`,``,`본인제압`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`불이행`,`선費후不`,`進向`,`  근`,`  留`,`주인가해`,`북북동`,`북서`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`권리양도`,`進向`,`  원`,` 留`,`주인가해`,`동북`,`북북서`,``],
    [`10.11`,` 集`,``,``,`후불가`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`서남`,`정북`,``],
    [`10.11`,`  集`,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부무력`,`불이행`,`사안무력`,`退背`,`  원`,`  去`,`주인무력`,`동남`,`북북동`,``],
    [`10.11`,``,`보인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대있음`,`불이행`,`선난후난`,`退背`,`  원`,`  去`,`세입조력`,`서남`,`동북`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 集`,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`무력`,`불이행`,`선이후난`,`進向`,`  원`,`  留`,`세입가해`,`정서`,``,`정서`],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부동조`,`불이행`,`선攻후難`,`進向`,`  원`,`  留`,`무난`,`서북`,``,`북서`],
    [`10.11`,``,`본인제압`,``,`빠르게攻`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`내부동조`,`불이행`,`선攻후不`,`向進`,`  그`,`  留`,`무난`,`북북서`,``,`북북서`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`애증남음`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,`북북서`,`없음`],
    [``,``,``,``,``,`묘향`,`남남동`,``],
    [`생업`,``,``,``,``,`전경`,`바다포구`,``],
    [``,``,``,``,``,`묘속`,`  水`,``],
    [`가정`,``,``,``,``,`육친`,` 부친`,``],
    [`질병`,`전흉사귀`,`혈광사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`두성부정`,`기도위배`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`가당신사`,`성물훼손`,`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,`전흉사`,`혈광사망`,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,`남남동`,`없음`],
    [``,``,``,``,``,`묘향`,`북북서`,``],
    [`생업`,``,``,``,``,`전경`,`윤천등라`,``],
    [``,``,``,``,``,`묘속`,`뱀굴`,``],
    [`가정`,`제례귀`,`제례위범`,``,``,`육친`,`부친`,``],
    [`질병`,`도로귀`,`교툥사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`두성부정`,`기도위배`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,``,`없음`],
    [``,`도로귀`,`교통사망`,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내부가정복잡애증사`,`상위 기쁨-하위 수자인 격-이동사`,`빈궁**병재`,`소식 안옴-와도 나쁨`,`빈궁`,`관직 영전도약`,`외사 출행이동원행사`,`고위-이익경영사`,`내女색정사有`,`부부불화사`,``,`위례득죄사`,`외사복잡다난애증사`,`전도**빈궁**관재`,`매사허성불성**세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`내사 출행이동원행사`,`명예자산권리사**외교막힘`,`남녀종업원 모사有`,`빈궁`,`매사위역사**빈궁**관재`,`도적실세-잡힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 급속사`,``,``,`전화위복`,``,`재액해소`,`내부가정막힘`,``,`빈궁**관재`,``,``,`재액해소`,`음4월 화재주의`,`외사 급속사`,`전도빈궁`,``,``,``,`유유자족`,`전화위복`,``,`외男색정사有`,`침체경구사`,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사`,`도적실세-잡힘`,`내부가정복잡애증사`,`상위기쁨-하위 수도인 격-이동사`,`빈궁**병재`,`속식 안옴-와도 나쁨`,`빈궁`,`관직 영잔도약`,`외사 출행이동원행사`,`고위-이익경영사`,`내女색정사有`,`부부불화사`,``,`위례득죄사`,`외사복잡다난애증사`,`위례득죄사**외사 급속사`,`매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`내사 출행이동원행사`,`명예자산권리사**외교막힘`,`전도**빈궁`,`음사폐문-가택안녕`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`재액해소`,`내사 급속사`,`재액해소`,`전화위복`,``,``,`내부가정막힘`,`관재`,``,`빈궁`,``,``,`음4월 화재주의`,`빈궁**관재`,``,`빈궁**관재`,``,``,`유유자족`,`전도**전화위복`,``,`외男색정사有`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`전도**빈궁`,`음사폐문-가택안녕`,`매사위역사`,`도적실세-잡힘`,`내부가정복잡애증사`,`상위기쁨-하위 수도인격-이동사`,`빈궁**병재`,`소식 안옴-와도 나쁨`,`빈궁`,`관직 영전도약`,`외사 출행이동원행사`,`고위이익경영사`,`내女색정사有`,`부부불화사`,``,`위례득죄사`,`외사복잡다난애증사`,`위례득죄사**외사 급속사`,`매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`내사 출행이동원행사`,`명예자산권리사**외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외男색정사有`,``,`빈궁**병재`,``,`재액해소`,`내사 급속사`,`재액해소`,`전화위복`,``,``,`내부가정막힘`,``,`빈궁`,``,``,``,`음4월 화재주의`,`빈궁`,``,`빈궁`,``,``,`유유자족`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`명예자산권리사**외교막힘`,`내사 출행이동원행사`,`빈궁`,`형벌받음`,`불검약자초죄사`,``,`내사 급속사`,`내부가정복잡애증사`,`눈 가리는 상`,`전도**빈궁`,`투서헌책-고위접인`,``,`외사 출행이동원행사`,`유유자족`,`내女색정사有`,`사행불량`,`매사위역사`,``,`외사복잡다난애증사`,`증명인준권한희경사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`상린분산사`,`외男색정사有`,``,``,``,`전도**빈궁`,``,`전화위복`,``,``,``,`재액해소`,`내부가정막힘`,`재액해소`,`빈궁`,``,`빈궁`,`음4월 화재주의`,`관재**외사 급속사`,`앙화소멸`,`빈궁**병재`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`매사영속성없음`,`종교진출하면자립함`,`명예자산권리사**외교막힘`,`내사 출행이동원행사`,``,`형벌받음`,``,`불검약자초죄사`,`내사 급속사`,`내부가정복잡애증사`,`눈 가리는 상`,``,`투서헌책-고위접인`,``,`유유자족`,`외사 출행이동원행사`,`내女색정사有`,`사행불량`,`매사위역사`,``,`외사복잡다난애증사`,`증명인준권한희경사`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`전화위복`,`상린분산사`,`외男색정사有`,`빈궁**관재`,``,``,`전도**빈궁`,``,`전화위복`,`전도**빈궁**관재`,``,``,`재액해소`,`관재**내부가정막힘`,`재액해소`,`빈궁`,``,`빈궁`,`음4월 화재주의`,`관재**외사 급속사`,`앙화소멸`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`명예자산권리사**외교막힘`,`상린분산사**내사 출행이동원행사`,`형벌받음**전도`,`외男색정사有`,`빈궁**관재`,`불검약자초죄사`,`빈궁**관재`,`내부가정복잡애증사`,`눈 가라는 상`,`빈궁**관재`,`투서헌책-고위접인`,`전도`,`유유자족`,`외사 출행이동원행사`,`내女색정사有`,`사행불량`,`매사위역사`,`전도**빈궁`,`외사복잡다난애증사**외사 급속사`,`증명인준권한희경사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`빈궁**병재**앙화소멸`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사**전화위복`,`관재**재액해소`,`재액해소`,`동요불안분산사`,``,`동요불안분산사`,`내사 급속사`,`동요불안분산사`,`전화위복`,``,`동요불안분산사`,``,`동요불안분산사`,`내부가정막힘`,`동요불안분산사`,`빈궁빈궁**관재`,`동요불안분산사`,``,`음4월 화재주의`,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외사복잡다난애증사**외사 급속사`,`증명인준권한희경사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`명예자산권리사**외교막힘`,`상린분산사**내사 출행이동원행사`,`형벌받음`,`외男색정사有`,``,`불검약자초죄사`,`내사 급속사`,`내부가정복잡애증사`,`눈 가리는 상`,``,`투서헌책-고위접인`,``,`유유자족`,`외시 출행이동원행사`,`내女색정사有`,`사행불량`,`매사위역사`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4월 화재주의`,`전도**관재`,`전도**병재`,`앙화소멸`,`빈궁`,``,`전화위복`,`관재`,``,`빈궁`,`재액해소`,``,`빈궁**관재`,``,`전화위복`,`빈궁`,`재액해소`,``,``,`내부가정막힘`,``,`빈궁`,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사위역사`,``,`외사복잡다난애증사`,`증명인준권한희경사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`명예자산권리사**외교막힘`,`상린분산사**내사 출행이동원행사`,`형벌받음`,`외男색정사有`,``,`불검약자초죄사`,`내사 급속사`,`내부가정복잡애증사`,`눈 가리는 상`,``,`투서헌책-고위접인`,``,`유유자족`,`외사 출행이동원행사`,`사행불량`,`내女색정사有`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`음4월 화재주의`,`관재**외사 급속사`,`빈궁**병재`,`앙화소멸`,`빈궁`,``,`전화위복`,``,`빈궁**관재`,``,``,``,`전도**빈궁`,``,`전화위복`,`전도**빈궁`,``,``,`재액해소`,`관재**내부가정막힘`,`재액해소`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`사행불량`,`내女색정사有`,`매사위역사`,``,`증명인준권한희경사`,`외사복잡다난애증사**외사 급속사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`명예자산권리사**외교막힘`,`상린분산사**내사 출행이동원행사`,`형벌받음`,`외男색정사有`,``,`불검약자초죄사`,`내사 급속사`,`내부가정복잡애증사`,`눈 가리는 상`,``,`투서헌책-고위접인`,``,`유유자족`,`외사 출행이동원행사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`빈궁`,``,`관재**재액해소`,`음4월 화재주의`,`병재`,`앙화소멸`,`빈궁`,``,`전화위복`,``,`빈궁`,``,``,``,`빈궁`,``,`전화위복`,`빈궁`,``,``,``,`내부가정막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`고위-이익경영사`,`외사 출행이동원행사`,`부부불화사`,`내女색정사有`,``,`위례득죄사`,`빈궁**외사 급속사`,`외사복잡다난애증사`,`매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사영속성없음`,`종교진출하면자립함`,`명예자산권리사**외교막힘`,`내사출행이동원행사`,`음사폐문-가택안녕`,`외男색정사有`,`매사위역사`,`도적실세-잡힘`,`상위 기쁨-하위수도인격-이동사`,`내부가정복잡애증사`,`모든 일이 근심사 분임`,`소식 안옴-와도 나쁨`,``,`관직-영전도약`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`내부가정막힘`,`재액해소`,`빈궁**관재`,``,``,``,`음4월 화재주의`,``,``,``,``,`빈궁**전화위복`,`유유자족`,`빈궁`,``,`전도`,``,`전도**병재`,`내사 급속사`,`전화위복`,`전도**빈궁**병재`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`관직-영전도약`,`고위-이익경영사`,`외사 출행이동원행사`,`부부불화사`,`내女색정사有`,``,`위례득죄사`,`외사 급속사`,`외사복잡다난애증사`,`매사영속성없음`,`종교진출하면자립함`,`고위연회길사**매사허성불성**세속허사자`,`음3,4,5,6,9,12월 방문자는 종교진출하면 `,`명예자산권리사**외교막힘`,`내사 출행이동원행사`,`음사폐문가택안녕`,`외男색정사有`,`매사위역사`,`도적실세-잡힘`,`상위기쁨-하위 수도인격-이동사`,`내부가정복잡애증사**내사 급속사`,`모든 일이 근심사 뿐임`,`소식 안옴-와도 나쁨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`반궁`,``,`관재**재액해소`,`내부가정막힘`,`재액해소`,`빈궁`,``,``,`빈궁**관재`,`음4월 화재주의`,``,`빈궁`,``,`성공함`,`전화위복`,`유유자족`,`빈궁`,``,`빈궁`,``,`전도`,`폐한인`,`전화위복`,`전도**병재**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`소식 안옴-와도 나쁨`,`모든 일이 근심사 뿐임`,`빈궁`,`관직-영전도약`,`고위-이익경영사`,`외사 출행이동원행사`,`부부불화사`,`내女색정사有`,``,`위례득죄사`,`외사복잡다난애증사`,`빈궁**관재**외사 급속사`,`매사허성불성**세속허사자`,`음1,2,4,5월 방문자는 종교진출하면성공함`,`고위연회길사**매사허성불성**세속허사자`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,`명예자산권리사**외교막힘`,`외사 출행이동원행사`,`음사폐문-가택안녕`,`외男색정사有`,`매사위역사`,`도적실세-잡힘`,`상위 기쁨-하위 수도인격-이동사`,`내부가정복집애증사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`병재`,`전화위복`,``,``,`전도`,`내부가정막힘`,`전도**빈궁`,``,``,``,`음4월 화재주의`,`재액해소`,`빈궁**관재`,``,``,`성공함`,`전화위복`,`유유자족`,`빈궁`,``,`빈궁`,``,`내사 급속사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 마음만 바쁘네?
  ⊕전사귀,가택음사부정이     들었다. 
  ⊖변사귀 침범으로 관재가 붙    었어!자만심도보태지고!     부부간에도 마음이 다르니    귀신부터 잡고봐야지!
    그래야 좋아져 !
  
  ⊕신변상가부정 침범을 했네?
  `,`■⊕부인, 
    ⊖병으로 허실이 많겠어!     자식 단속도 잘해야겠고! 
     
      가정에 금전․여자문제로      의심지투가 있는데 !
  
  숨은칼날을피하려면
  고사ㆍ치성이 필요하겠어!
  `,`■ 일이 하나도 묶어지는게 없고     계속 반복만 되고 있네?
     계획을 바꿔야 겠어 !
  ⊕가택귀, 
  ⊖가택상가부정도로귀가      침범해서 그렇네! 
    억지부리지 말고 귀신 쫓고    옛것으로 다시 시작해야지!
  
  ⊖신변음사부정이들었어!
  `,`■ 이사하겠네 ?
  
  ⊕전사귀, 
  ⊖변사귀 침범때문이네! 
    신규사가 방해받겠어!
  `,`■ 이일저일 겉은 화려하나 속은 허전한데 자칫 관재가 되겠네 ! 그래도
  ⊕전흉사귀, ⊖여귀 
  ⊕⊖가택에 든 가당신사 부정만 해소시키면 소송건ㆍ남의부인 유혹건ㆍ도움 받는 건 등이 잘 되겠어!
  ⊕⊖신변두성불안부정침범했네?
  `,`■ 男：⊖애인이 임신했겠네?
     집이 기울고 있어! 
     관재 기운도 있고!
     마음에 의심질투가 있고!
  
  ⊕신불제사귀, 
  ⊖음귀 장난이네! 
  해소시키면 뜻을 이루게 
  될거야!
  `,`■만사가 뒤틀려가고 있네? 부부가 색정사로 마음이 각각 다르니 재산이 온전하겠어? 
  그러니 집 권리도 넘어가지!
  
  ⊖가택음사부정 해소해! 
    인연이 다했어!
  
  ⊖신변상가부정이 들었어!
  `,`■마음에 의심 질투가 가득해서 돈과 여자로 양자택일의 기로에 섰네? 
  
  ⊕신불제사귀, 
  ⊖음귀를 해소하면 복이 되돌아오게 될거야!
  `,`■ 관계된 일과 사람이 많겠는    데,일은 반복되기만 하고 
    내외 모두 암울한 지경이네?
  男：직업도 끝났고,
  女：부부관계도 끝이고, 
      모두 보류시키고 명분을     지키는 수밖에 없겠어!
  ⊕가택상가부정이 듬
  
  ⊕신변 음사부정 침범했어!
  `,`■ 일을 풀려면 
  
  ⊕신불제사귀, 
  ⊖음귀해소가 급선무야! 
  그러고나서 새로 일을 시작하면 잘되게 되어 있어!
  `,`■ 지나친 욕심이 관재와
    피신할 일을 만들었어!
  ⊕가택귀, 
  ⊖도로귀 해소외에 방법이 
    없겠어! 
  집에 ⊕⊖ 가당신사 부정까지 들어서 몸 붙일데가 없어!
  
  ⊕⊖신변에 두성불안부정이      침범했네 ?
  `,`■ 시작은 있는데 끝이 안      보이는 지경이겠어!
  그러나 
  ⊕전사귀ㆍ가택귀, 
  ⊖변사귀ㆍ도로귀만 잡고 
    집에 아이만 태어나면 
    가운이 번창하겠어!`];
  const YOGYEOL1 = [`■ 녹신과 역마가 나를 따르고 두 범이 호위하니 공무원이나 고위인은 크게 좋고 일반서민은 재앙이 깊다.
  `,`■ 귀인이 둘이 되어도 서로 차질이 있게 된다. 만약 분수 밖의 짓을 할진대 반드시 재앙을 당하리라.
  `,`■ 근심은 실재가 아니고 헛것이다. 나를 돕는 것은 힘이 없다.
  낮정단은 돈 벌려다가 재앙이 더 심해진다.
  `,`■ 내가 상대에게 구하니 예를 다하여야 한다.
  누가 재앙을 일시 풀어준다 해도 도리어 나중에 재앙의 씨앗이다.
  `,`■ 무엇을 시작하면 두려우나 가만히 있지는 못하게 생겼다. 중간과 결과가 모두 비었으니 지키고 있는 것이 상책이다.
  `,`■ 과전에 관귀가 많으니 계속하면 좋지 않아 고위자나 관직자는 좋고 일반인은 후회가 있으리라.
  `,`■ 상대가 내집에 오고 나는 상대에게 간다. 
  보이는 게 모두 재물이나 밤정단은 좋지가 않다.
  `,`■ 사람과 집에 그늘이 생긴다. 두 귀인을 만났고 벼슬과 관귀가 많아 군자는 좋고 일반인은 나쁘다.
  `,`■ 사람은 안되는데 집은 좋다. 나는 안되고 상대는 잘 된다. 관재나 우환이 생겨도 온전히 두려울 것은 없다.
  `,`■ 해로운 것이 변해 합이 된다. 이사ㆍ여행ㆍ변동이 있다. 귀인의 도움을 얻지 못하고 밤정단은 재물을 범이 밟고 있다.
  `,`■ 움직이는 신이 가득하지 동작함이 비상하리라. 그러나 별 재미는 없으니 중말전 공망된 이유이다.
  `,`■ 사람이고 집이고 모두 잘되니 변동하면 매우 나쁘다. 서로 어리석은 짓을 권하니 뜻은 좋으나 결과가 나쁘다.`]
  const SIBO1 = [`■ ⊕음사부정이 들었다.
  재산에 재판이 붙었다.
  관직자는 영전ㆍ승진의 
  기쁨이 있으나 일반인은 거지곤란ㆍ부부이심ㆍ도망관사가 벌어질 기운이다. 교만심을 버리고 덕행을 쌓아야 한다. 바탕이 든든하여 근신하면 천우신조를 얻게 된다.
  `,`■ 人ㆍ宅이 함께 암울한
  실정이다.  
  폐지사를 부흥시키려 한다.
  화합중 투쟁이 벌어지고 애정속에 원한이 생긴다.
  근친자로 결정하고 신중히 나아가면 이외의 복을 맞게 된다.
  매사가 가정에서 일어나 출외한다.
  `,`■ 가정에 ⊖상가부정이
  들어 병자가 있다.
  男은 직업ㆍ직위의 훼손이
  있고, 女는 남편이 없다.
  있어도 무용이다. 
  집을 안들어 온다. 
  간난ㆍ험악한 형편에서 억지로 가다가는 재앙을 받는다. 화합적으로 가면 이익이 있게 된다.
  `,`■ 자충수적 우환에 시달린다.
  이사하려 한다.
  자녀의 문제에 기인한다.
  신규사가 있다.
  활발하게 움직이면 된다.
  애매한 일에는 관여하면 凶하다. 
  단, 자식으로 인하여 조상불경의 화를 당하게 된다.
  `,`■ ⊕⊖가정에 가당신사 부정이
  들었다. 이로인한 관재가
  발생한다.
  男은 타인의 부인을 유혹하여 거래한다.
  함부로 음모를 꾸미지 말라. 凶이다. 현 상태를 지키고 가면 吉하다.
  타인의 도움으로 목적사가 이루어진다.이사가 좋다.
  `,`■ 임신사로 가정ㆍ금전의
  다툼이 벌어진다.
  집안일을 밖에서 발설
  하면 해롭게 된다.
  후회심이 있다.돈과 여자 문제로 기로에 서 있다. 의심과 질투가 있다.
  근ㆍ친이 좋다. 직접 나서지 말고 앉아서 모사해야 이롭다. 경쟁자가 있다. 낭비도 많다.
  `,`■ 배반사가 발생했다.
  財와 여자ㆍ녹이 의혹을
  일으키고 대립한다.
  간 사람은 오고 온 사람은 가는 격이다.
  왕래ㆍ반복이 심하다.
  옛 인연과 일이 끝나는 형국이다. 
  정단사에 결함이 있고 어떤 일도 外에서 內로 번졌다.
  `,`■ 저주를 받는 일이 있다.
  財․女의 일로 두 가지
  중 하나로 결정해야 할
  선택의 기로에 섰다.
  질투 의심이 있어 결정이 어렵다. 목전사이다. 
  사람과 집이 어둡다. 
  관직자는 吉하나 일반인은 문책을 받는 일이 있다. 
  아랫사람에게 인심을 쓰면 도움을 얻게 된다.
  `,`■ 가내 ⊕상가부정이 들어
  병자가 있고, ⊖여자의
  우환이 발생한다.
  가장을 속이는 일이 있고 가인이 원한을 품고 가해하려 한다.
  도움 주겠다는 사람을 믿지 말라. 손실을 끼치는 도둑이다. 만사허위ㆍ부실을 면하기 어려운 운세이므로 광명정대해야 한다. 
  `,`■ 가내 우환이 있고, 가장을
  속이며, 가인이 원한을 품고
  가해하려 한다.
  심중 동요사를 갖고 있고 별리ㆍ원행한다.
  만사 신중해야만 한다.
  비용지출이 많다. 교역ㆍ교섭은 이로우나 도중에 의심이 끼어든다.
  천우신조가 있다.
  `,`■ ⊕⊖가정에 가당신사
  부정이 들어 少人과 
  財의 다툼을 벌인다.
  도움을 주겠다는 사람이 있어도 믿지 말아야 한다.
  손실을 끼치는 도둑이다.
  움직여도 이익이 없고 반드시 변경하는 일이 생긴다.
  男이 타인의 부인을 유혹하여 거래한다.
  권리상실을 주의.
  `,`■ 사람이고 집이고 바탕이
  든든한 환경이다.
  모든 일을 변동 시키지 
  말고 그대로 지키면 吉하다. 정대광명의 운세이다. 
  단, 중간에 적당한 양보심이 좋다. 끝까지 고집을 부리면 오히려 吉이 감소된다. 원조가 있다.
  四月 임신사가 있다.
  권리상실을 주의.`]
  const SIBO2_1 = [`■별리ㆍ원행·이동신변외사다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  관송사가 있다.
  `,`■자손ㆍ박관신변외사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다. 부모재해,권리침해사가 기한다.
  `,`■官･夫, 가택내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■官･夫, 신변외사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  매사지장, 용두사미의 상이다.
  `,`■사업ㆍ쟁재사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다.
  병재·관재 예방이 필요하다.
  `,`■사업ㆍ쟁재사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■금전ㆍ여인, 가택내사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■손실신변외사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  사물이 우연발생한다.
  `,`■후원ㆍ해산·손실건이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가발동한다.男직위상실하고 女부부이별한다.
  `,`■후원ㆍ원행·이동손실건이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  만사 합동 성과있다.
  `,`■사업ㆍ쟁재사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다. 가정 신변에 부정이 있다
  `,`■사업ㆍ쟁재사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  형제상해 불구상이다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/027/row-1-column-1.png`,
    `/static/images/sagwa/027/row-1-column-2.png`,
    `/static/images/sagwa/027/row-1-column-3.png`,
    `/static/images/sagwa/027/row-1-column-4.png`,
    `/static/images/sagwa/027/row-1-column-5.png`,
    `/static/images/sagwa/027/row-1-column-6.png`,
    `/static/images/sagwa/027/row-1-column-7.png`,
    `/static/images/sagwa/027/row-1-column-8.png`,
    `/static/images/sagwa/027/row-1-column-9.png`,
    `/static/images/sagwa/027/row-1-column-10.png`,
    `/static/images/sagwa/027/row-1-column-11.png`,
    `/static/images/sagwa/027/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 12신이 본궁에 엎드려 움직이지 않고 천지반을 구성하기 때문에 복음과이다. 상하의 극이 없고 양일이므로 일간상신을 초전으로 하며, 중․말전에 지형(支刑)을 사용한다. 신(申)은 일(日)의 덕록(德祿)이고 역마가 신(身)에 임하여 초전이 된다.
    양호(兩虎)가 나란히 임하면 현임관에게는 가장 길조이다. 신(申)은 백호의 본궁이면서 묘(廟)에 들어가기 때문에 권세가 혁혁하다. 보통사람에게는 흉조이고 관재(官災)를 만난다. 양일복음(陽日伏吟)은 자임(自任)이라 하는데 신(申)은 순수(旬首)이며 육의격(六儀格)이 된다. 자임은 스스로 강하다고 생각하여 자존하거나 자부하면 재앙을 초래한다. 이 격에 들면 덕을 수양하면 반드시 좋은 결과가 있다. 순의(旬儀)가 초전이면 부귀가 영달한다. 그러나 상하를 묻지 않고 부덕한 행위를 하면 재화를 초래한다. 4과3전이 역마를 서로 바꾸는 것은 인택에 모두 동의(動意)가 생기는데, 서두르지 말고 단기간 대기하였다가 이동해야 한다. 일간일지 상하교차(上下交車)가 모두 충전(冲戰)이 되며 동요와 파동을 면치 못한다.
    백법에서는 ｢임신역마(任信驛馬)가 중첩되면 움직임이 생기고, 피차가 서로 투합되지 않으면 지형상(支刑上)에 있기 때문이다｣라고 하였다. 비전에서는 ｢복음과에서 교차충전하면 친근함과 소원함을 막론하고 먼저 합하고 후에 같아진다. 부자․부부․주객․형제간에 모두 그러하다｣라고 하였다. 육의(六儀)는 흉해를 구하는 신으로 신(申)은 인(寅)에 절(絶)하고, 경(庚)은 인(寅)을 보고 절한다. 즉 간지가 서로 절신(絶神)으로 되면 구사(舊事)의 결절(結絶)을 주관한다. 일귀는 순행하여 3전이 백(白)․사(蛇)․구(勾)이고, 야귀는 역행하여 3전이 후(后)․청(靑)․주(朱)이다.`
    ,
    // 2
    `  이 과는 제3과․제4과에서 하가 상을 적하고 양일 양비를 사용하기 때문에 제4과 자축(子丑)을 초전으로 하여 지일퇴여과로 된다. 일간에서 일지상신 축(丑)은 묘신이 되고, 일지 인목(寅木)에서 일지상신 미(未)는 묘신으로 되며, 축미(丑未)는 양(兩) 귀신의 본가로서 묘신이 교체하여 엎드리니 귀신(貴神)은 차질을 부른다.
    중․말전 정병(丁丙)의 둔귀는 일간을 극한다. 일간상신의 미(未)는 공망이 되며 천공이 타면 공상공(空上空)으로 되어 인택은 모두 사위(詐僞)를 만난다. 만일 택상에 귀신이 타서 택을 지키고 사람을 사랑하면 흉해혼미를 구(救)한다. 오미(午未)가 지합하고, 축자(丑子)가 지합하며, 양신과 음신은 서로 충으로 되므로 화합하는 가운데 싸움을 초래하고 애정속에 원한이 발생한다. 2하(二下)가 2상(二上)을 극하면 처재(妻財)의 다툼이나 관사(官事)의 불안 등이 모두 집안에서 일어난다. 자해술(子亥戌)은 모두 밤중이어서 태양의 광명을 보지 못하기 때문에 될 수록 모든 일에 신중하고 때를 기다려 활동해야 한다. 간귀(干貴)는 간상에 임하고 순기(旬奇)를 초전으로 하기 때문에 협화(協和)하는 기쁨이 있고, 재화를 밀어내고 길을 받아들이므로 뜻밖의 복록을 획득한다.
    백법에서는 ｢공상(空上)에 공(空)을 만나면 무슨 일에서나 추구하지 말아야 한다. 양귀(兩貴)가 묘(墓)에 승하면 혼미 정체가 많다. 그러나 귀신이 완전하면 이루어진다｣라고 하였다. 일귀는 순행하여 3전이 후(后)․음(陰)․현(玄)이고, 야귀는 역행하여 3전이 백(白)․상(常)․현(玄)이다. 현무가 같이 말전에 승하여 괴도(魁度)가 천문을 건너가는 격이 되므로 여행․외출에는 가장 불리하다.`
    ,
    // 3
    `  이 과는 제1과와 제4과의 상에서 하를 극하므로 섭해과로 되며, 지반 맹신의 극을 취하여 오신(午申)을 초전으로 한다. 오진인(午辰寅)의 간전고조격(間傳顧祖格)은 화합하며 희기(喜氣)가 있다. 그러나 초․중전이 공망하여 경하다. 오(午)가 간상에 타서 귀(鬼)로 되어 초전을 이루는 것은 크게 우려되나 다행히도 공망하여 흉을 이루지 못한다. 본래 섭해과는 갖은 곤란을 겪고 진퇴양난이지만, 초전이 공망하여 극하는 힘이 약하므로 우려를 보고 흉을 이루지 않는다. 천을귀신(天乙貴神)이 묘유(卯酉)에 임하면 여덕격(勵德格)으로서 현임 상급 관리는 승진하는 기쁨이 있으나 하급 관리는 도리어 퇴직할 염려가 있다.
    백법에는 ｢제귀(制鬼)의 자리(位)는 양의(良醫)를 얻는다｣라고 했다. 간지상(干支上)의 자오(子午)는 가운이 패세(敗勢)의 신으로 되어 안정되지 못하며 정체된다. 비전(秘傳)에서는 ｢고조격(顧祖格)은 임기를 채우지 못하고, 특히 초전이 용신, 말전이 사신(蛇神)인 경우에는 청이 사(蛇)로 변화하니 임용(任用)되지 못한다｣라고 하였다. 사물에서 억지로 전진하면 재앙을 초래한다. 말전은 초전을 도와 공망하여 일(日)을 극할 수 없다. 일귀는 순행하여 3전은 청․합․사이고, 야귀는 3전이 사․합․청으로서 사(蛇)가 청으로 화한다. 교차상생(交車相生)하여 화합하면 교섭이 성사된다.
  `
    ,
    // 4
    `  이 과는 4과 중에서 제1과의 사신(巳申)이 위에서 아래를 극하여 초전으로 되고 원수과(元首課)로 된다. 3전 4맹신은 원태격(元胎格)이다.
    초전은 일간상신에서 시작되고, 말전의 해(亥)는 일지상신에 돌아가므로 내가 그에게 구(求)하는 것이다. 일귀(日鬼) 사화(巳火)는 해(亥)의 수신(水神)으로 제어하기 때문에 재화를 보아도 재앙을 만나지 않는다. 원수과이고 4맹신을 초전으로 하므로 무슨 일에서나 활력이 넘치고 만사에서 광명정대한 형상이다. 그러므로 움직이면 이롭다. 애매한 일은 복체(伏滯)하여 통하기 어렵고 움직이면 재화를 이룬다.
    3전이 체생하고 일귀(日鬼)는 구진이 승하여 봉인(棒印)이라 부르며 사신(蛇神)은 용으로 화하기 때문에 관직에 봉사하거나 큰 회사에 근무하면 좋다. 백법에 말하기를 ｢귀(鬼)를 제하는 자리(位)는 양의(良醫)를 얻는다｣라고 하였다. 일간일지에서 서로 탈기되는 것은 즐거운 가운데 우려를 초래한다. 말전 정귀(丁鬼)는 자손효(子孫爻)로 되기 때문에 자녀에 의하여 흉하게 움직인다.
    비전에서는 ｢경(庚)에서 해(亥)는 자손효이며 자식으로 본다｣라고 하였다. 초전의 사(巳)는 구진(勾陳) 또는 주작이고 관사(官事)를 취급하는 사람으로, 자식에게 극을 받기 때문에 사(巳)의 4, 신(申)의 7이 합하여 11이 된다. 가깝게는 11일, 11개월이고, 멀게는 11년만에 실현된다. 순미(旬尾)의 사(巳)가 초전으로 되는 것은 폐구격(閉口格)으로, 병자는 말을 못하고 태아도 출산 후에 말이 늦어진다. 일귀(日貴)는 순행하여 3전이 구․사․음이고, 야귀는 역행하여 3전이 주․청․상의 길장이다.`
    ,
    // 5
    `  이 과는 제2과와 제3과에서 하에서 상을 극하여 비용(比用)을 취할 수 없으므로 섭해과로 보고 4맹신의 극인 술인(戌寅)을 초전으로 하여 참관격이다. 3전에 합(合)․후(后)가 승하여 교동격(狡童格)이다.
    섭해과로써 3전의 염상(炎上)은 중귀(衆鬼)이지만 일간상의 진(辰)으로 생화하므로 귀(鬼)도 두려울 것이 없다. 술인(戌寅)은 둔귀(遁鬼)로 되므로 음모사(陰謀事)는 매우 신중해야 한다. 다행히도 중․말전이 공망하여 흉은 자연히 소산될 징조가 있다. 모사를 멈추고 현상태를 지키면 길하다.
    말전에서 중전을 생하고 중전은 초전을 생하며 초전의 술(戌)은 일간을 생하는 체생격(遞生格)으로서 타인의 도움을 얻어 일이 이루어진다. 그러나 공망이 되어 경하다. 백법에 ｢3전 체생은 남의 천거를 받고, 후(后) ․합(合)이 승하면 점혼(占婚)에서는 자유 연애 결혼이다｣라고 하였다. 중․말전의 공망은 전(傳)에 돌지 않고 초전만으로 정단한다. 귀신(貴神)이 해(亥)에 임하면 귀신이 천문에 오른다. 4맹(四孟) 위에 진술축미(辰戌丑未)가 가해지면 신장살몰(神藏殺沒)로 되어 흉을 제거하고 길로 변한다. 비전에는 ｢참관격(斬關格)은 움직이는 것을 주재하지만 중․말전이 공망되므로써 도리어 움직일 수 없게 된다｣라고 하였다.
    술(戌)은 형무(刑務)의 신으로 3전이 묘에 들면 관재․형무의 관한 사건 또는 관가의 소송사건이 발생한다. 여름․가을에는 세력이 의지하여 흉이 중하다. 일귀는 역행하여 3전이 합(合)․백(白)․후(后)이고, 야귀는 순행하여 3전이 합(合)․후(后)․백(白)이다.`
    ,
    // 6
    `  이 과는 4과 중 제1과․제2과․제3과에 극이 있고 음양 쌍방에 극이 있다. 양일양비(陽日陽比)인 술묘(戌卯)를 초전으로 하는 지일비용과(知一比用課)로 된다. 술(戌)은 둔간하여 병(丙)의 귀(鬼)로 되지만 말전에서 제어하므로 두려울 것 없다.
    비용(比用)과는 무슨 일에서나 한가지 일뿐이 아니고 두 세 갈래의 일로서, 경(庚)은 유(酉)에 왕하고 인(寅)의 지(支)는 간상 묘에 왕하다. 즉 호왕(互旺)하여 길조이고, 흉은 흉의 반응이 있다. 제1과․제2과와 제3과․제4과는 각각 지합으로 되며 양신과 음신은 각각 충전하므로 흉화와 불화가 있다. 내외는 서로 은혜 가운데 해를 품고 있으며 먼데 것을 버리고 가까운 데 것을 구하여야 한다. 처음에는 곤란이 있으나 후에는 성사된다.
    백법에서는 호왕격(互旺格)은 앉아서 모략하는 것이 좋다｣라고 하였다. 음신이 같이 묘에 앉으면 피차 윗사람에게 고난을 초래한다. 태재(胎財)에 사기(死氣)가 타면 태를 손상한다. 비전에는 ｢술묘(戌卯)는 단륜격(斷輪格)으로서 관직․구직은 모두 성립된다｣라고 하였다. 임신 및 병관(病觀)에서는 모두 흉하다. 재록(財祿)은 길조를 나타낸다. 일간의 묘(卯)는 지전일위(支前一位)이고 지상(支上)의 유(酉)는 기궁(寄宮) 신(申)의 전일위(前一位)이므로 나망격(羅網格)으로 된다. 만사에서 망동하면 좋지 않으니 앉아서 도모해야 한다.
    일귀는 역행하여 3전이 합․상․사이고, 야귀는 순행하여 3전이 합․음․청으로서 용신이 전(傳)에 들면 기쁨이 있다.`
    ,
    // 7
    `  이 과는 천지반의 상하가 칠충위(七冲位)에 있어서 제7국 반음원태과(返吟元胎課)이다. 일지는 일간상에, 일간은 일지상신에 가해져서 췌서격(贅壻格)으로 된다. 즉 사람은 나의 집으로 오고 나는 떠나서 남에게 기탁한다. 3전4과에 경(庚)의 재신(財神)이 네 자리에 모여서 호환(互換) 역마를 구성한다. 신(申)은 일간의 녹신(祿神)이 되어 인신(寅申) 두 글자를 떠나지 않고 모두 재록으로 되므로 미격(美格)이다. 사호(蛇虎)가 승하면 길도 흉으로 변화하는데, 반음과(返吟課)는 왕래하면서 단서가 없으며 반복 부정하여 의혹을 생하고 피차간에 서로 화목하지 않다. 군자는 바른 것을 지키고 하급인은 망동을 삼가면 길행을 얻을 수 있다.
    백법에서는 ｢간지상신이 12운이 절(絶)에 해당하면 여러 가지 모사가 결정된다｣라고 하였다. 권섭부정(權攝不正)은 지상신(支上神)에 녹신이 임하는 것이다. 비전에 이르기를 ｢췌서격은 내가 처가로 간다｣라고 하였다.
    일지가 일간에 임하여 극을 받으면 반드시 이런 일이 있다. 일귀는 역행하여 3전이 후․청․후이고, 야귀는 순행하여 3전이 백․사․백이다. 간지에 모두 절신이 타면 구사결절(舊事結絶)의 과로 된다. 4과이지만 2과 뿐인 것은 정단사에 결함이 있다. 초전이 일간의 음양신으로 구성되는 것은 무슨 일이나 밖에서 일어나는 요건(要件)이다.`
    ,
    // 8
    `  이 과는 제3과․제4과에서 하가 상을 극하고 양일이므로 양비(陽比)의 극이다. 자미(子未)를 취하여 초전으로 하여 맥월격(驀越格)이다. 초전의 지(支)와 일간상신 축(丑)이 지합(支合)한다. 지합하는 것은 모두 자기 쪽으로 오는 데에 길이 있다고 본다. 술(戌)이 사(巳)에 가해져서 주인격(鑄印格)으로 된다. 사관(仕官)․임관(任官)은 좋고 보통 사람에게는 문책이 있다. 일간에 금묘(金墓)가 타고 일지에 목묘(木墓)가 타서 인택이 모두 어두컴컴하다. 그러나 일야귀신(日夜貴神)이 타서 구신(救神)으로 되므로 도리어 복을 불러온다. 다만 제3과․제4과 및 초전이 공망하여 길이 경하다.
    백법에는 ｢일야의 두 귀(貴)가 가해지면 귀인․존장에게 의존하여 길하다｣고 하였다. 비전에서 이르기를 ｢가택의 정단에서 공망에 천공이 승하는 것은 구택을 떠나 남방으로 옮기며, 말전 술(戌)은 사용인의 류신으로서 일간을 생하고 육합이 타므로 사용인의 힘에 의하여 교역사가 달성된다｣라고 하였다. 초전이 순기(旬奇)라 해도 공망에 앉기 때문에 복을 이루지 않는다.
    일귀는 역행하여 3전이 사(蛇)․상(常)․합(合)이고, 야귀는 순행하여 3전이 청(靑)․음(陰)․합(合)이다. 3전 중에 청룡이 타면 기쁨이라고 보지만 공망하므로 기쁨을 이루지 않는다. 이와 같은 것은 공망의 묘기(妙機)라 부른다.`
    ,
    // 9
    `  이 과는 제2과가 진자(辰子)로서 상에서 하를 극하여 원수과(元首課)로 된다. 3전 신자진(申子辰)이 3합하여 윤하격(潤下格)으로 된다. 일간에서 탈설(脫泄)하여 3합수국은 일지 인목(寅木)을 생하므로 사람은 쇠퇴하고 가택은 왕성하다. 피차의 상태는 내몸이 약하고 그가 강하다. 오(午)의 관귀(官鬼)는 일간상신에서 제어하고 또 공망하므로 화환이 없으며, 사관(仕官)은 공망하여 복력을 얻지 못한다. 3합 윤하는 무슨 일에서나 순조롭게 움직여 쉬지 않고 만사에서 본분을 지키고 광명정대하게 행하면 길이 후하다. 일간의 음신 진(辰)에 현무가 승하면 내가 아는 사람이 와서 나에게 손실을 주려는 것이므로 예방과 경계가 필요하다.
    백법에 이르기를 ｢탈상탈(脫上탈)을 만나는 것은 허위부실을 만나기 때문에 주의해야 한다｣라고 하였다. 비전에 이르기를 ｢윤하(潤下)는 부유(浮游)의 형상으로서 불안하다. 천후․현무를 보면 도난을 만나기 쉽고, 3전과 간지 상신이 형충․파해를 이루면 합중살(合中殺)을 범한다｣라고 하였다. 웃음 속에 칼을 품는 것과 같다.
    일귀는 역행하여 3전이 현․청․사이고, 야귀는 순행하여 3전이 현․사․청이다. 일간에서 일지상의 오(午)의 목욕을 보고, 일지 인목(寅木)에서 자(子)를 보아 목욕으로 되는 것은 무엇이나 휴식하고 보류하는 것이 좋다.`
    ,
    // 10
    `  이 과는 제4과의 하에서 상을 적하니 중심원태과(重審元胎課)로 된다. 순수(旬首) 신(申)을 초전으로 하는 육의격(六儀格)이다.
    초전은 역마로 되고, 중전은 정신(丁神)이 타며, 말전은 일간의 마(馬)로 되어 항상 동요 이동이 많다. 해(亥)와 인(寅), 사(巳)와 신(申)이 교차지합하고 사해(巳亥)의 충(冲) 인신(寅申)의 충, 신(申)과 해(亥)의 해(害), 사(巳)와 인(寅)의 육해(六害) 등이 있는데 이와 같이 육해와 칠충지합(七冲支合)이 서로 섞이면 먼저 저해가 있고 후에 화합하며, 평화스러운 가운데 조해(阻害)를 초래하기 때문에 만사에서 신중하게 행동해야 한다. 원태(元胎)는 생발하는 과이며 순의(旬儀)가 발용(發用)으로 되고 녹마(祿馬)가 모여 만사 길조이다. 그러나 일간․일지는 모두 탈기로 되므로 비용의 지출이 많고, 합 중에 충해(冲害)가 있으므로 기회를 잘 보고 움직여야 한다. 백법에 이르기를 ｢교차상합은 교역교섭(交易交涉)에서 모두 이로우며 육해가 수반하면 의심하는 일이 일어난다｣라고 하였다.
    비전에는 ｢병을 정단하면 일간상신 해(亥)의 수(水)에 설(泄)하는 금한수냉(金寒水冷)이 되므로 냉증(冷症)이다｣라고 하였다. 인(寅)에 백호가 승하고 신(申)에 사신(蛇神)이 승하면 한열이 왕래한다. 인신(寅申)의 성수(成數)는 7이고, 사해(巳亥)는 4이므로 4․7은 28의 배수인 5월, 6월 사이에는 방심하지 말아야 한다. 3전이 체생하고 재관이 완전하기 때문에 현임관리 및 상급 회사원은 반드시 영전하는 기쁨이 있다. 일간에서 사(巳)는 장생으로 되며 일지 인(寅)에서 일간상신 해(亥)도 장생으로 되어 서로 생기가 발월(發越)한다.`
    ,
    // 11
    `  이 과는 제2과․제3과에서 2하(二下)가 2상(二上)을 극하여 비용(比用)을 취할 수 없으므로 지반사맹의 극을 취하여 진인(辰寅)을 초전으로 하는 섭해순간전과(涉害順間傳課)이다. 귀신이 해(亥)에 임하고 네 구석에 진술축미(辰戌丑未)가 승하면 귀(貴)가 천문(天門)에 올라 신장살몰격(神藏殺沒格)이다.
    진술(辰戌)이 일간․일지상에 임하고 일지상신이 천강을 초전으로 하는 것은 중․말전 역마가 공망의 지(地)에 임하기 때문에 움직여도 이익이 없고 반드시 변경하는 일이 생긴다. 참관격(斬關格)은 피란에 좋으며, 중․말전의 공망은 길흉이 모두 극히 경미하다. 백법에 이르기를 ｢천후(天后)․육합(六合)이 타면 점혼에서는 매인(媒人)이 없이 자유 결혼하는 것이다. 강색귀호(罡塞鬼戶)는 모사에 적합하다. 일귀는 순행하여 3전이 합․청․백이고, 야귀는 역행하여 3전이 합․사․후이다. 야귀는 교동격(狡童格)으로 되어 남자가 부인을 유인한다.
    3전 진오신(辰午申)의 순간전(順間傳)은 등삼천격(登三天格)이다. 간전(間傳)에는 순역(順逆)이 각각 12국씩 있어서 3전을 만들 때 한 신을 사이에 두고 이루어지므로 이같은 이름이 붙었다. 순간전은 모든 일이 순조로와 문사(問事)는 조애의 건이다. 등삼천격은 용이 하늘로 오르는 형상으로 비가 내리고 구름이 가니 관리는 천전(遷轉)을 지배한다. 그러나 공망하므로 기쁨이 가볍다. 병을 간점하면 상태가 악화하며 기다리는 사람은 온다.`
    ,
    // 12
    `  이 과는 제4과에서 하가 상을 적하여 중심진여격(重審進茹格)으로 된다. 일지상신은 어느 것이나 제왕(帝旺)으로 되어 인택이 같이 왕하고 모두 안태하다. 천반이 지반의 전지(前支)에 앉는 것은 나망격(羅網格)으로 옛 것을 지켜 때를 기다리는 것이 좋다. 망동할 때에는 재화를 초래하고, 앉아서 때를 기다리면 길행이 온다. 음신은 모두 묘(墓)로 되어 내부적으로 혼미한 일이 있으나 감추고 참는 것이 좋다.
    초전 진(辰)의 천강(天罡)은 음신의 술(戌)에서 충하여 개묘(開墓)로 되어 어둠 속에서 광명을 뿌리며, 특히 진사오(辰巳午)의 3전은 승계격(昇階格)이라 부른다. 진사(辰巳)는 태양이 솟아 중천에서 빛나고, 오(午)는 양명(陽命)으로서 군위(君位)로서 군신은 덕(德)으로 합하여 명리가 진달하는 형상이다. 다만 말전이 공망하여 길이 감소될 뿐이다.
    진여격(進茹格)은 전진하는 뜻이 있으나 나망(羅網)에 가하여 말전이 공망되므로 무슨 일에서나 분수에 만족하고 정직하게 행하면 인택이 다 융성하고 편안한 지경에 이르며, 남들의 원조를 얻는다. 백법에 이르기를 간지가 서로 왕성하면 앉아서 도모하는 것이 좋다｣고 하였다. 비전에 이르기를 ｢초전에 진(辰)의 천강은 옛일을 주관하고 육합이 승하는 것은 결혼의 기쁨을 만난다｣고 하였다. 초전이 실하고 말전이 공망하는 것은 사물에 시작이 있고 끝이 없는 격이다. 일귀는 역행하여 3전이 합(合)․구(勾)․청(靑)이고, 야귀는 순행하여 3전이 합(合)․주(朱)․사(蛇)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
