
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,8,7,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,6,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,5,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,11,4,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,3,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,1,2,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,2,1,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,12,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`설득`,4,11,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신뢰`,5,10,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,9,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,8,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`상업`,`상업 관련사`,`小`,`6`,``,``],
    [`처`,`처 관련사`,`大`,`존재기`,`위례득죄`,`가정막힘`],
    [`관달서기(官達庶忌)`,`관직자는흔괘히 달성되고 일반인은 매우 나쁜 상`,`大`,``,``,`冠衣之象`],
    [`도적조수(盜賊遭囚)`,`도적이 잡혀 죄수가 된 것을 보게되는 상`,`小`,``,``,`(소행사召)`],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`5`,``,``],
    [`화합`,`내부 가정 부부 남녀 화합사`,`小`,`배양기`,`개신예정`,`부부화합`],
    [`병송심려(病訟心慮)`,`관재 병 근심이 깊은 상`,`小`,``,`부부불화`,`도적죄수`],
    [`관자천약(官者遷躍)`,`관직자는 영전도약하는 상`,`大`,``,``,``],
    [`관사`,`관사`,`大`,``,``,``],
    [`급속`,`외사 급속사`,`小`,`4`,`외사급속`,`내사출행`],
    [`출행`,`내부 가정 출행 이동 원행사`,`小`,`개시기`,`대립송쟁`,`타인시혜`],
    [`관속상흉(官速常凶)`,`관직자는 급속히 원지 부임하고 일반인은 매우 나쁜 상`,`大`,``,`고위입각`,`음사폐호`],
    [`입조경영(入朝經營)`,`고위에 입각하여 衆人의 이익을 강구하게 되는 상`,`小`,``,``,`가택안녕`],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`남편`,`남편 반목 갈등사`,`小`,`3`,``,``],
    [`결속`,`개혁사로 재정기반을 크게 세우나 명예직위남편이 훼절됨`,`小`,`학습기`,`투서헌책`,`가정반목`],
    [`부모유재(父母有災)`,`부모의 재해가 있는 상`,`大`,``,`귀인면접`,`무례대립`],
    [`미초희경(微招喜慶)`,`고위로부터 술잔을 받고 희미하게 웃으며 기뻐하는 상`,`小`,``,``,`충산분산`],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`2`,``,``],
    [`결속`,`내부 가정 단합결속사`,`小`,`면모기`,`난진난퇴`,`단합결속`],
    [`난진난퇴(難進難退)`,`나아가지도 물러나지도 못하는 상  `,`小`,``,`엄목지상`,`사기득재`],
    [`엄목지상(掩目之象)`,`눈이 가려진 격의 상`,`大`,``,``,`허사됨`],
    [`명예`,`수상(受賞)사`,`大`,``,``,``],
    [`자산`,`직위녹위사`,`大`,`1`,``,``],
    [`급속`,`내부가정 급속사`,`小`,`절정기`,`외교막힘`,`강제급속`],
    [`호악무성(好惡無成)`,`좋은 것이나 나쁜 것이나 모두 이루어지지 않는 상`,`小`,``,`간사은닉`,`앙화소멸`],
    [`재앙소멸(殃禍消滅)`,`재앙이 소멸된 상`,`小`,``,``,`부족`],
    [`색정`,`외男 색정사 有`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 밤문자는 종교진출하면 성공함`,`大`,`12`,``,``],
    [`매사 영속성없음`,`후언조력문서부모사는 선천적 기복재해를 겪음`,`小`,`과시기`,`약속비밀`,`부부상쟁`],
    [`불가작귀(不可作鬼)`,`분명치 않은 침해를 받아 주력사가 안 되는 상`,`小`,``,`누설득죄`,`미초희경`],
    [`미초희경(微招喜慶)`,`고위로부터 술잔을 받으며 기븜을 애써 참는 상`,`小`,``,``,``],
    [`가출`,`내부 가정 손해 가출사`,`小`,``,``,``],
    [`매사허성불성`,`음3,4,5,6,9,12월 밤문자는 종교진출하면 성공함`,`大`,`11`,``,``],
    [`매사영속성없음`,`형제자매친구동지사는 선천적 기복재해를 겪음`,`小`,`쇠퇴기`,`재산풍부`,`공허가출`],
    [`재풍이서(財豊利書)`,`재화가 넉넉하고 문서사도 이로운 상`,`小`,``,`형사처벌`,`도적소실`],
    [`대재획득(大財獲得)`,`단합결속으로 대재를 획득하는 상-단,부모 근심이 발생함`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`충산분산`,``],
    [`결속`,`결속으로 大財를 획득하게 되나 부모 재해가 발생함`,`大`,`침해기`,`가정근심`,`사행불량`],
    [`처망투정(妻亡投井)`,`처가 도망하거나 우물(水)에 몸을 던져 사망하는 상`,`小`,``,``,``],
    [`사행불량(私行不良)`,`음란사를 저지르는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극남`,`내女 색정사 有`,`小`,`9`,`개신개혁`,`파재극처`],
    [`여식`,`여식 관련사`,`大`,`소외기`,`고위연회`,`고위안좌`],
    [`이해운운(利害云云)`,`이익이 분명하지 않은 상태에서 김치국부터 마시는 격`,`小`,``,`초대열석`,`衆人이익`],
    [`위례득죄(違禮得罪)`,`법도를 범하여 득죄하는 상`,`大`,``,``,`방책강구`],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`8`,``,``],
    [`동료`,`동료 관련사`,`小`,`침체기`,`침체공허`,`관직영전`],
    [`인이필상(人而必傷)`,`사람이 반드시 상하게 되는 상`,`小`,``,`불변근신`,``],
    [`입조경영(入朝經營)`,`고위에 입각하여 多衆을 이익되는 방법을 강구하는 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`상업`,`사업 동요불안분산사`,`小`,`7`,``,``],
    [`출행`,`외사 출행 이동 원행사`,`大`,`단절기`,`출행이동`,`비용과출`],
    [`송흉문익(訟凶文益)`,`송쟁사는 매우 나쁘고 문서사는 매우 좋은 상`,`大`,``,``,``],
    [`가면둔조(可免迍遭)`,`어려움을 면할 소식을 늦게 받게되는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,`금전상업처조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,`자매친구부부화합승진사로 가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사급속내부가정출행사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사가정대립무례사부모재해사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`형제친구조력비용지출로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`명예자산권리행사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`직업직위불안남편갈등자식관련사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`직업직위불안부인색정남편갈등여식관련사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`형제친구동료조력비용지출로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`금전사업출행문서사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`제일 친하거나 제일 가까이 거주하는 자를 찾아 의논하면 불안이 해소될 人`,0,0,`일이 역순으로 되고 있음**깊은 산속으로 숨어버리고 싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순응하며 유순하게 처신하면 겨과가 유라함`,0,0,`역상적 사안이므로 억울하더라도 절대 항의를 삼가고 유순유화적 언행으로 대하고 일을 디로 지체시키면 결과가 유리해지는 人`,0,0,`일이 역순으로 띄엄띄엄 진행됨**결과없이 반복되기만하는 상**색정사 우려가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기어려우니 신중해야 하고 후에 행동하는 것이 현명하며 방심하지 말아야 함 `,0,0,`풍상고로 후 성공할 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`출행불귀(出行不歸)**만약 타인과 다툰다면 본인의 신상이 위해되므로 그 자리를 급히 벗어나야만 함`,0,0,`여행 장기외출 쟁론을 애써 피해야 할 人(오늘로부터 최소 10일간)`,0,0,`가정이 편하지 않음**만난자는 가고 떠난자는 오려고 함**있고 싶어도 못있고 가고 싶어도 못가는 입장임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순응하며 유순하게 처신하면 겨과가 유라함`,0,0,`역상적 사안이므로 억울하더라도 절대 항의를 삼가고 유순유화적 언행으로 대하고 일을 디로 지체시키면 결과가 유리해지는 人`,0,0,`2남1녀 삼각관계有**타세 의존 상-타인이 스스로 와서 금전여인사의 이득을 줌`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상으로 재난이 밖에서 오니 신중해야 함 `,0,0,`가정질서가 없고 타가살이이며 반복무상한 人`,0,0,`독신고독각방주말부부생리사별의 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순응하며 유순하게 처신하면 겨과가 유라함`,0,0,`역상적 사안이므로 억울하더라도 절대 항의를 삼가고 유순유화적 언행으로 대하고 일을 디로 지체시키면 결과가 유리해지는 人`,0,0,`권한을 가져 결제하게 되는 상**어디 깊은 산중으로 숨어버리고 싶은 심정을 갖게 될 때가 다가오고 있옴`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결해야만 유리함`,0,0,`정당한 사안이므로 선결처리해야만 하며 좌고우면하거나 뒤로 미루면 되려 불리하게 되는 人`,0,0,`좋은 것은 좋게 계속이어지고 나쁜 것도 계속 이어지는 상**어디 깊은 산중으로 숨고싶은 심정임-내부가정사에 관련됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 호가호위 자이므로 놀랄 것이 없음`,0,0,`마치 천둥에 놀란 듯두려워하고 있는 속마음이나 언행을 삼가고 근신하면 두려움이 자연적으로 없어지는 人`,0,0,`풍운불측 상**남 몰래 꾸민 일이 들통 남`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대항하지 말고 순응하며 유순하게 처신하면 겨과가 유라함`,0,0,`역상적 사안이므로 억울하더라도 절대 항의를 삼가고 유순유화적 언행으로 대하고 일을 디로 지체시키면 결과가 유리해지는 人`,0,0,`남자가 타인의 부인을 유혹하여 자기 여자로 사용함**정인이 2인 임**과공직자는 영전하나 초기에 급히 서둘지 않으면 안 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`제일 친하거나 제일 가까이 거주하는 자를 찾아 의논하면 불안이 해소될 人`,0,0,`일이 순서적으로 착착 진행됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 동하여도 움직이지는 않음`,0,0,`스스로 자임하고 지금까지 해오던 것을 신음을 하며 지키고 새 기회를 기다리는 상-국가고시 준비생에 많음`,0,0,`신구사가 있음**송쟁사도 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-침체-비용지출`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`기사`,`신사`,`돌발사`,`모호사`,`충성`,`악신`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리를 가져갈인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용나감`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`죄고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사-후회사겹침`,`기사`,`신사`,`외사`,`표면사이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥-이사의사`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥-불화`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥-이전의사`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-침체`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-화합`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해`,`화해`,`화해`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음5월-파혼우려`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음4월`,2,1,3,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월-연기우려`,3,2,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음8월`,1,1,2,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음2월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음5월-파혼우려`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음4월`,2,1,3,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음1월`,2,2,4,`음7월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음12월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음7월`,2,3,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무가 시종 수준 이하임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 종반에 파란을 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하나 업무는 중반 이외에 수준이하임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 높고 업무도 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하며 초반 외 중 종반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세고 업무가 시종 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무에 권위를 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미가 있고 사심이 있으나 업무를 단합결속시켜 재정을 크게 확보함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무가 시종 수준이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무가 시종 수준 이하 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 높으나 의지처가 없어 업무가 답보함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 업무 시종이 소송반목사로 이어짐`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선攻후攻`,`退背`,`근`,`去`,`무난`,`정북`,``,`정북`],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,`의사참고`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선費후合`,`退背`,`  원`,`  去`,`주인가해`,`북북동`,``,`북북동`],
    [`10.11`,``,`끌면부결`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`불이행`,`선損후不`,`退背`,`  원`,`  去`,`주인조력`,`동북`,``,`동북`],
    [`10.11`,`  集`,`내부제압`,``,``,``,``,``,`이후파재`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선損후損`,` 進向`,`  근`,`  留`,`주인조력`,`남남동`,`동남`,`정서`],
    [`10.11`,``,``,``,``,``,``,``,`합세`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`반대있음`,`불이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`동남`,`정동`,`북서`],
    [`10.11`,` 多集`,`무력함`,``,``,`속결처리`,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,`지체불성`,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`사안무력`,`進向`,`원근왕래`,`  留`,`주인무력`,`남남동`,`동남`,`북북서3`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`선이후이`,`  去`,`  근`,`  去`,`세입가해`,`정북`,`정북`,``],
    [`10.11`,``,`본인제압`,``,`권리양도`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,`됨`,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`불이행`,`선比후合`,` 退背`,`  원`,` 去`,`주인가해`,`정서`,`북북동`,``],
    [`10.11`,``,`다중제압`,``,``,``,``,``,`이후단합`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부합세`,`불이행`,`선不후攻`,`退背`,`  근`,`  去`,`세입손해`,`동북`,`동북`,``],
    [`10.11`,`  集`,`본인제압`,``,``,``,``,``,`무례반목`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압불가`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`세입손실`,`정동`,``,`정서`],
    [`10.11`,``,`본인무력`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인무력`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`주인가해`,`동남`,``,`북서`],
    [`10.11`,`  集`,`제압불가`,``,``,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부합세`,`불이행`,`선攻후不`,`進向`,`  근`,`  留`,`무난`,`남남동`,``,`북북서`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,``,``,``,``],
    [``,``,``,0,``,``,``,``],
    [``,``,``,0,``,``,``,``],
    [`질병`,``,``,0,``,`묘좌`,``,`없음`],
    [``,``,``,0,``,`묘향`,``,``],
    [`생업`,``,``,`가당신사`,`성물훼손`,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,``,``,0,``,`육친`,``,``],
    [`질병`,``,``,0,``,`묘좌`,``,`없음`],
    [``,``,``,0,``,`묘향`,``,``],
    [`생업`,`전사귀`,`전사`,0,``,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,``,``,`두성부정`,`기도위배`,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,0,``,`묘좌`,``,`없음`],
    [``,``,``,0,``,`묘향`,``,``],
    [`생업`,`가장귀`,`호주사망`,0,``,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,``,``,0,``,`육친`,``,``],
    [`질병`,``,``,0,``,`묘좌`,``,`없음`],
    [``,``,``,0,``,`묘향`,``,``],
    [`생업`,``,``,0,``,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,``,``,0,``,`육친`,``,``],
    [`질병`,`여귀`,`가장부인`,0,``,`묘좌`,``,`없음`],
    [``,``,``,0,``,`묘향`,``,``],
    [`생업`,``,``,"음사부정",`제사잘못`,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,``,``,0,``,`육친`,``,``],
    [`질병`,``,``,0,``,`묘좌`,``,`없음`],
    [``,``,``,0,``,`묘향`,``,``],
    [`생업`,``,``,0,``,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,``,``,`음귀`,`묘지탈`,`묘좌`,`남남동`,`없음`],
    [``,``,``,0,`자산침체`,`묘향`,`북북서`,``],
    [`생업`,``,``,0,``,`전경`,`등라수`,``],
    [``,``,``,0,``,`묘속`,`화렴`,``],
    [`가정`,``,``,0,``,`육친`,`조모`,``],
    [`질병`,``,``,0,``,`묘좌`,``,`없음`],
    [``,``,``,0,``,`묘향`,``,``],
    [`생업`,``,``,0,``,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,``,``,0,``,`육친`,``,``],
    [`질병`,`음귀`,`묘지탈`,0,``,`묘좌`,`정동`,`없음`],
    [``,``,`관공무실`,0,``,`묘향`,`정서`,``],
    [`생업`,``,``,0,``,`전경`,`숲풀`,``],
    [``,``,``,0,``,`묘속`,`목근`,``],
    [`가정`,`음귀`,`묘지탈`,0,``,`육친`,`자매`,``],
    [`질병`,``,``,0,``,`묘좌`,``,`없음`],
    [``,``,``,0,``,`묘향`,``,``],
    [`생업`,``,``,0,``,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,`가장귀`,`호주사망`,0,``,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,``,`묘좌`,``,`없음`],
    [``,`가장귀`,`호주사망`,0,``,`묘향`,``,``],
    [`생업`,``,``,"음사부정",`제사잘못`,`전경`,``,``],
    [``,``,``,0,``,`묘속`,``,``],
    [`가정`,``,``,0,``,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,0,``,`묘좌`,`정북`,``],
    [``,``,``,0,``,`묘향`,`정남`,``],
    [`생업`,``,``,0,``,`전경`,`강호수川`,``],
    [``,``,``,0,``,`묘속`,`  水`,``],
    [`가정`,``,``,0,``,`육친`,` 형제`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사 출행이동원행사**동요분산사`,`상위길사-하위수도인격-이동사`,`내부가정막힘`,`소식 안 옴-와도 불리함`,`부부남녀화합사`,`관직-영전도약`,`내사 출행이동원행사**외사급속송쟁-상해우려`,`고위입각-연회길사`,``,`부부불화사`,``,`違禮득죄사`,`명예자산권리사**외교막힘**강제급속사有`,`송쟁사 흉-문서소식사 길`,`매사허성불성**세속허사자**男색정사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`매사영속성없음**가정가출사우려`,`종교진출하면자립함`,`외 실종사 우려`,`만사 느긋 한가`,`가정女색정사有**가정파재극처사`,`음사폐호-가택안녕`,`빈궁**매사위역`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`전화위복`,`전도**빈궁**병재`,`빈궁`,``,`재액해소`,`관재`,`재액해소`,`관재`,``,``,`음4,월 화재주의`,`빈궁`,``,`빈궁`,``,``,`전화위복`,``,`빈궁`,``,`침체경구사`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역`,`도둑포획-遭囚`,`외사 출행이동원행사**동요분산사`,`상위길사-하위수도인격-이동사`,`내부가정막힘`,`소식 안 옴-와도 불리함`,`부부남녀화합사`,`관직-영전도약`,`내사 출행이동원행사**외사급속송쟁-상해우려`,`고위입각-연회길사`,``,`부부불화사`,``,`違禮득죄사`,`명예자산권리사**외교막힘**강제급속사有`,`송쟁사흉-문서소식사길`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음**가정가출사우려`,`종교진출하면자립함`,`외 실종사 우려`,`만사느긋한가`,`가정女색정사有**가정파재극처사`,`음사폐호-가택안녕`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`빈궁**관재`,`전도`,``,`전화위복`,`전도**빈궁**병재`,``,`빈궁`,`재액해소`,`관재`,`재액해소`,`관재`,``,``,`음4,5월 화재주의`,`빈궁`,``,`관재`,``,``,`전화위복`,``,`빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`가정女색정사有**가정파재극처사`,`음사폐호-가택안녕`,`매사위역`,`도득포획-遭囚`,`외사 출행이동원행사**동요분산사`,`상위길사-하위수도인격-이동사`,`내부가정막힘`,`소식 안 옴-와도 불리함`,`부부남녀화합사`,`관직-영전도약`,`내사 출행이동원행사-외사급속송쟁-상해우려`,`고위입각-연회길사`,``,`부부불화사`,``,`違禮득죄사`,`명예자산권리사**외교막힘**강제급속사有`,`송쟁사흉-문서소식사길`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음**가정가출사우려`,`종교진출하면자립함`,`외 실종사 우려`,`만사 느긋 한가`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁`,``,`전도**빈궁`,``,``,`전화위복`,`빈궁**병재`,``,`전도**빈궁`,``,`관재`,`빈궁**관재`,``,``,`전도`,`음4월 화재조심`,`빈궁**관재`,``,`빈궁**관재`,``,``,`재액해소`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`동요분산사`,`외 실종사 우려`,`刑責사`,`가정女색정사有**가정파재극처사`,`불검약자초罪사`,`가정파재극처사`,`외사 출행이동원행사**동요분산사`,`투서헌책무용-또는 실재사`,`내부가정막힘`,`눈가리는 상`,`투사헌책-고위면담`,`부부남녀화합사`,`느긋 한가`,`내사 출행이동원행사**외사급속송쟁-상해우려`,``,`사행불량`,`도적소실사`,`매사위역`,`미초희경사-증명발급권한관련`,`명예자산권리사**외교막힘**강제급속사有`,`매사영속성없음**앙화소멸**男색정사`,`종교진출하면자립함`,`매사영속성없음**사기득재**가정가출사우려`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,`전화위복`,`전도**빈궁`,``,``,``,`재액해소`,`빈궁`,`빈궁**전화위복`,`재액해소`,``,``,`관재`,``,`빈궁**관재`,``,`빈궁`,``,`관재`,`음4월 화재조심`,`빈궁**관재`,``,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`매사영속성없음**사기득재**가정가출사우려`,`종교진출하면자립함`,`동요분산사`,`외 실종사 우려`,`가정女색정사有**가정파재극처사`,`刑責사`,`불검약자초罪사`,`빈궁`,`외사 출행이동원행사**동요분산사`,`투서헌책무용-또는 실재사`,`내부가정막힘`,`눈가리는 상`,`투서헌책-고위면담`,`부부남녀화합사`,`느긋 한가`,`내사 출행이동원행사**외사급속송쟁-상해우려`,``,`사행불량`,`도적서실사`,`매사위역`,`미초희경사-증명발급권한관련`,`명예자산권리사**외교막힘**강제급속사有`,`매사영속성없음**앙화소멸**男색정사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`빈궁**관재`,`전화위복`,``,``,``,`재액해소`,``,`빈궁**관재`,`전화위복`,``,`재액해소`,`관재`,``,`빈궁**관재`,``,``,`재액해소`,`빈궁`,`전도`,`음4월 화재주의`,``,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`매사영속성없음**앙화소멸**男색정사`,`종교진출하면자립함`,`매사영속성없음**사기득재`,`종교진출하면자립함**가정가출사우려`,`동요분산사`,`외 실종사 우려`,`刑責사`,`가정女색정사有**가정파재극처사`,`불검약자초罪사`,``,`외사 출행이동원행사**동요분산사`,`투서헌책무용-또는 실재사`,`빈궁**관재**내부가정막힘`,`빈궁*관재**눈가리는 상`,`투서헌책-고위면담`,`부부남녀화합사`,`느긋 한가`,`내사 출행이동원행사**외사급속송쟁-상해우려`,`사행불량`,``,`도적소실사`,`매사위역`,`미초희경사-증명발급권한관련`,`명예자산권리사**외교막힘**강제급속사有`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요분산사`,`빈궁**병재`,`동요분산사`,`빈궁`,`동요분산사`,`전도**관재**전화위복`,`동요분산사`,`전도**빈궁**관재`,``,`동요분산사`,`동요분산사`,`빈궁**관재**재액해소`,`전화위복-재액해소`,`동요분산사`,``,`동요분산사`,`관재`,`동요분산사`,`빈궁**관재`,`동요분산사`,`빈궁`,`동요분산사`,`동요분산사`,`음4월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`미초희경사-증명발급권한관련`,`명예자산권리사**외교막힘**강제급속사有`,`매사영속성없음**앙화소멸**男색정사`,`종교진출하면자립함`,`매사영속성없음**사기득재**가정가출사우려`,`종교진출하면자립함`,`동요분산사`,`외 실종사 우려`,`刑責사`,`가정女색정사有**가정파재극처사`,`불검약자초罪사`,``,`외사 출행이동원행사**동요분산사`,`투서헌책무용-또는 실재사`,`내부가정막힘`,`눈가리는 상`,`투서헌책-고위면담`,`부부남녀화합사`,`느긋 한가`,`내사 출행이동원행사**외사급속송쟁-상해우려`,`사행불량`,``,`매사위역`,`도적소실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`음4월 화재주의`,`빈궁**병재`,``,`빈궁`,``,`전도**관재`,`전화위복`,`관재`,``,``,`전도**빈궁`,`재액해소`,``,`빈궁**관재**전화위복`,`재액해소`,`빈궁`,``,`관재`,``,`빈궁**관재`,``,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`도적소실사`,`매사위역`,`미초희경사-증명발급권한관련`,`명예자산권리사**외교막힘**강제급속사有`,`매사영속성없음**앙화소멸**男색정사`,`종교진출하면자립함`,`매사영속성없음**사기득재**가정가출사우려`,`종교진출하면자립함`,`동요분산사`,`외 실종사 우려`,`刑責사`,`가정女색정사有**가정파재극처사`,`불검약자초罪사`,``,`외사 출행이동원행사**동요분산사`,`투서헌책무용-또는 실재사`,`내부가정막힘`,`눈가리는 상`,`투서헌책-고위면담`,`부부남녀화합사`,`느긋 한가`,`내사 출행이동원행사**외사급속송쟁-상해우려`,`사행불량`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,`음4월 화재주의`,`빈궁**병재`,``,`빈궁`,``,``,`전화위복`,`빈궁`,``,``,``,`빈궁**전도`,``,`전화위복`,`전도**빈궁`,``,``,`재액해소`,`관재`,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재물유실실사`,``,`도적소실사실사`,`매사위역`,`병 중태-구설놀램`,`명예자산권리사**외교막힘**강제급속사有`,`매사영속성없음**탄식시음-병 또는 事미수`,`종교진출하면자립함**男색정사`,`매사영속성없음**가정가출사우려`,`종교진출하면자립함`,`외 실종사 우려`,`모두 재난 뿐임`,`가정女색정사有**가정파재극처사`,`관재 또는 병재`,`불검약자초罪사`,``,`제복입고 허리굽히는 일 반복사`,`외사 출행이동원행사**동요분산사`,`비상지애사`,`내부가정막힘`,`부부남녀화합사`,`관직-영전도약`,`관직-생살대권 쥠 일반인-형벌대흉`,`내사 출행이동원행사**외사급속송쟁-상해우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**전도`,``,`빈궁`,`음4월 화재주의`,``,``,`전도`,``,`재액해소`,`빈궁**병재**전화위복`,`재액해소`,`빈궁`,``,`전도`,``,`빈궁**관재`,``,`전화위복`,`빈궁**전도`,``,``,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내사 출행이동원행사**외사급속송쟁-상해우려`,`고위입각-연회길사`,``,`부부불화사`,``,`違禮득죄사`,`명예자산권리사**외교막힘**강제급속사有`,`송쟁사흉-문서소식사길**외사 출행이동원행사`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음**가정가출사우려`,`종교진출하면자립함`,`외 실종사 우려`,`만사 느긋 한가`,`가정女색정사有**가정파재극처사`,`음사폐호-가택안녕`,`매사위역`,`도적실세포획-遭囚`,`외사출행이동원행사**동요분산사`,`상위길사-하위수도인격-이동사`,`내부가정막힘`,`소식 안 옴-와도 불리함`,`부부남녀화합사`,`관직-영전도약`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`관재`,`재액해소`,`빈궁**관재`,``,``,`음4월 화재주의`,`빈궁`,``,`빈궁`,``,``,``,`전화위복`,`빈궁`,``,``,`빈궁**관재`,``,`전도`,`전화위복`,`전도**빈궁**병재`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`부부남녀화합사`,`관직-영전도약`,`내사 출행이동원행사**외사급속송쟁-상해우려`,`고위입각-연회길사`,``,`부부불화사`,``,`違禮득죄사`,`명예자산권리사**외교막힘**강제급속사有`,`송쟁사흉-문서소식사길**외사 출행이동원행사`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사허성불성**세속허사자**가정가출사우려`,`음3,4,5,6,9,12월방문자는종교진출하면`,`외 실종사 우려`,`만사 느긋 한가`,`가정女색정사有**가정파재극처사`,`음사폐호-가택안녕`,`매사위역`,`도적실세포획-遭囚`,`외사동요분산사`,`상위길사-하위수도인격-이동사`,`내부가정막힘`,`소식 안 옴-와도 불리함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**관재`,``,`관재`,``,`빈궁**관재`,``,`전도`,``,`음4월 화재주의`,`빈궁`,``,`빈궁`,`전도`,`성공함`,`재액해소`,`전화위복`,`재액해소`,`빈궁**관재`,``,`전도**빈궁`,`폐한인`,``,`전화위복`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`내부가정막힘`,`소식 안 옴-와도 불리함`,`부부남녀화합사`,`관직-영전도약`,`내사출행이동원행사**외사급속송쟁-상해우려`,`고위입각-연회길사`,``,`부부불화사`,``,`違禮득죄사`,`명예자산권리사**외교막힘**강제급속사有`,`송쟁사흉-문서소식사길**외사출행이동원행사`,`매사허성불성**세속허사자**男색정사`,`음1,2,4,5월방문자는종교진출하면성공함`,`매사허성불성**세속허사자**가정가출사우려`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,`외 실종사 우려`,`만사 느긋 한가`,`가정女색정사有**가정파재극처사`,`음사폐호-가택안녕`,`매사위역`,`도적실세포획-遭囚`,`외사동요분산사`,`상위길사-하위수도인격-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**병재재액해소`,`빈궁`,``,`관재`,``,`빈궁**관재`,``,``,``,`음4월 화재주의`,`빈궁`,``,`빈궁`,``,`성공함`,`전도`,`전화위복`,`전도**빈궁`,``,``,`빈궁`,`대액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 재산에 재판붙었네?
  걱정은 해소되겠는데,

⊕도로귀, 
⊖가택상가부정. 가택귀 
 때문에 신규사가 凶으로 되    겠어!
`,`■ 집을 옮기려고 하는데 
큰 여자 작은 여자가 함께 사는 격이야!
돈 복은 있어 저절로 들어오고 일은 천천히 해가면 잘 될거야!

⊕⊖신변에가당신사부정이      침범 했네 ?
`,`■ 색정사가 벌어졌네?
  정도를 벗어났어!

⊕⊖가택두성불안부정이 들었다.
⊕변사귀, 
⊖전사귀 발동 때문에 그렇네!   그러니 일도 매듭이 없어!
`,`■ 男：기쁜일이 있게 생겼네?
  女：남자가 들어오겠는데         亥월, 亥년이야!

⊕도로귀, 
⊖가택귀를 없애야 뜻이 펴져!

⊕신변음사부정이 들었어!
`,`■ 근신만 하면 만사가 다 좋아지겠네? 근심사는 원조를 받아 해소될 것이고, 그런데 저주를 받고 있네?  돌아가세요!

⊖단,가택음사부정이들었어!
`,`■ 색정사가 있네? 
  한 여자에 남자가 둘이네?
⊕여귀, 
⊖전흉사귀 발동이야! 
 그대로 두면 망신살이 뻗    히겠어!

⊕신변 음사부정이 들었어!
`,`■ 후원도 없고 금전도 없고     여자도 비었고, 하극상으로 육친간 빙탄이고…. 
이사가 제일 좋기는 한데
부모걱정이 크겠어 !

⊕가택 상가부정이 들어 
 매사가 결과가 없어!
`,`■ 이미 끝난 일을 다시 부흥     시키려고 하겠는데
⊕음귀, 
⊖신불제사귀만 해소시키면    좋은 일이 많겠어! 
 그런데 가택권리가 넘어가네?
⊕⊖신변에 가당신사부정이      침범 했네 ?
`,`■⊕⊖가택에 두성불안 부정만 없애면 돈은 계속 불어나겠는데, 다인 다사를 하나로 묶겠어 ! 그러나 연정사만큼은 계속 이어질 수밖에 없겠어!
`,`■ 가정에 
  ⊕음귀, 
  ⊖신불제사귀가 발동해서       가정이 깨질 위험에 있어!
마음속 놀라고 있는 일은 감추기 바쁘겠고! 관재에 연루될 일(죄인)을 감추고 있겠어 !

⊖신변 음사부정 침범했네?
`,`■⊕도로귀가택,음사부정이
  들었다.
⊖가택귀 발동으로 일에 진실       이나 성의가 없겠어! 
男：⊖남의 부인을 유혹해 
   색정사를 일으키고 있네!
女：가장을 속이는 일이 
   있겠어!
`,`■마음도 불안하고 두가지 중 하나로 선택해야 될 기로에 섰어!
⊕ 도로귀ㆍ변사귀, 
⊖가택귀ㆍ전사귀 발동인데,    친한사람쪽으로 선택해야    좋아져!

⊖신변음사부정이 들었어!`];
  const YOGYEOL1 = [`■ 낮정단은 나쁜 것이 좋게 되고 밤정단은 좋은 것에 나쁜것이 있다. 만약 어려운 일에 당하면 입을 다물어야 면한다.
  `,`■ 가택의 묘신이 내게 오르니 나는 답답하고 가정은 이사한다. 현무가 해를 부리니 도적을 조심해야만 한다.
  `,`■ 피차 모두 재앙이 두렵다. 집안이 위아래가 없다. 관직자나 간부사원은 좋으니 조상과 인맥의 덕이다.
  `,`■ 내 몸과 시작이 모두 관귀로 나쁘다. 막아줄 사람은 믿지 말라. 그자 역시 나를 해친다.
  `,`■ 낮정단은 청룡이 숨은 살인데 그러나 스스로 눌려 악하지 못하다. 
  때때로 입을 다물어야 범을 우리에 가두리라.
  `,`■ 계속 연결되어 떨어지지 않는다. 밤정단도 흉이 빨리 닥친다. 재물이나 여자는 저절로 오나 일은 진퇴결정이 어렵게 된다.
  `,`■ 재물이 내게 찾아와도 움직이면 망하게 된다. 오나가나 빈말뿐이고 나쁜일은 좋게 되나 좋은 일은 별로이다.
  `,`■ 모두 연결되어 끊어지지 않으니 재물과 여자가 그렇다. 녹을 구하는 일에는 더욱 좋고 귀신까지 내녹을 돕는다.
  `,`■ 밤정단은 집안에 구설이 생기고 새(가금류)나 소리나는 사업은 좋다. 도박이나 복권도 좋다. 오직 가택점에는 재앙이 일어난다.
  `,`■ 총알없는 총만 쏘게 되니 모든것이 허사다.
  집에 손해수가 있으니 귀인의 덕은 별로 없게 된다.
  `,`■ 처음에는 죽고 나중에 살아난다. 교섭사는 서로 속여 좋지 않다.
  낮정단은 좋고 나쁜 일이 서로 교대로 일어난다.
  `,`■ 나를 돕는 것은 모두 헛것이고 해치는 자만 있다. 낮정단은 귀인이 집을 해치고 밤정단은 나쁘니 주의하라.`]
  const SIBO1 = [`■ 가정에 임신女가 있다.
  ⊖상가부정이 들어
  병자가 있다.
  내 녹에 재판이 붙었다. 
  윗사람에게 멸시를 받는다. 좋은 녹이 몸에 임하여 함부로 행동하지 않고 신중하면 암암리에 타인의 도움을 얻어 재난이 해소된다.
  `,`■ 가정 개동의 兆가 있다.
  가택 문제로 고민한다.
  신변이 불안하다.
  폐지된 일을 부흥시키려 한다. 진퇴여부를 고민한다. 마음이 불안하다. 
  근ㆍ친자로 결정하여 하나를 잡으면 좋아진다.
  `,`■ 자충수적 우환이 기한다.
  가택이 침체되어 있다
  午떼는 제외
  타인과 공동으로 일을 꾀하나 상대의 기만이 우려된다. 
  주색ㆍ간사사가 있다.
  
  ⊕관리는 탄핵받아 퇴직하게 된다.
  `,`■ 자충수적 우환이 기한다.
  귀살이 제거되어 화가
  제거된다.
  사람은 적고 집은 크다.
  승진을 바라면 암암리에 천거된다.
  
  ⊖특히 원대한 뜻이 이룩된다. 별리ㆍ이동ㆍ영전사가 있다.
  `,`■ 만사에 화합한다.
  그러나 부부가 음탕하여
  가정이 편안치 않다.
  교역거래에는 이롭다.
  신장살몰이 되어 만사가 吉하다. 
  단, 저주받는 일이 있다.
  `,`■ 임신했다. 낙태한다.
  처가 남편에게 현신하고
  금전을 생한다.
  (년명상신을 보라. 길장이 승한자는 췌서-처가살이-권귀를 얻는다.)
  여자의 병은 돈 문제에 있고 여성이 색정사를 벌인다.
  一女二男 관계이다.
  형제의 재해가 발생한다
  `,`■ ⊖상가부정이 들었다.
  凶을 凶으로 제어한다.
  財문제로 변동을 생각한다.
  그러나 빈소리 뿐이고 효과도 없다.
  산은 높고 골짜기는 깊은 형상이다. 남녀 다툼으로 배반당했다. 존친에 화가 기한다.
  正月 임신사가 있다.
  `,`■ 一男二女 삼각관계가 있다.
  가택의 권리가 넘어간다.
  현임관리는 승진ㆍ영전한다.
  남자는 여자에 헌신한다.
  폐지된 일을 다시 부흥시키려는 兆이다. 모망사는 반드시 이루어진다. 
  관재의 싹을 자르라.
  `,`■ 가정에 ⊕⊖두성불안
  부정이 들고 도적이 재물을
  훔쳐갔다.
  또는 가옥을 개조시키는데 재물이 나갔다.
  그러나 계속 재물의 기쁨이 있다. 단, 존친의 재해는 피할 수 없다. 
  관재가 보이나 참관이 통해 신불기도가 효과 있다.
  正月 임신사가 있다.
  `,`■ 가내 우환이 있고 가장을
  속인다. 가인이 원한을
  품고 加害하려 한다.
  심중 놀라는 일이 있으나 경미하다. 
  가정이 동요하고 어떤일도 빈소리일 뿐이다. 
  가정에 음사사가 있다.
  길흉의 정도는 ⊕⊖삼전의 천장으로 본다.
  부모의 재해가 기한다.
  `,`■ 가내 우환이 있고
  가장을 속이며 가인이
  원한을 품고 加害하려 한다.
  남자가 타인의 여자를 적극 유혹하여 사통한다.
  모든 일에 진실과 성의가 없다. 
  단, 천우신조가 있어 재해를 피해갈 수 있다.
  `,`■ 모든 도모사에 진실과
  성의가 없다.
  선택의 기로에 섰다.
  가깝고 친한 사람으로 결정해야 한다.
  
  ⊕점은 일을 전진하여 吉이 빠르고 ⊖점은 지체된다.
  부모 형제에 의한 고민이고 밖에서 일어난 일이다.
  혐의ㆍ중상을 받고 있다.`]
  const SIBO2_1 = [`■ 신변후원외사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  초흉, 후성한다.
  소송사가 있다.
  `,`■해산건·금전손모사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 가택내손모사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다. 
  신변이 동요한다.
  `,`■官ㆍ夫,신변반목사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 직녹신변외사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  백살이 해제되어 유리 유익하다.
  `,`■ 금전ㆍ여가택내사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  자가 소유가 곤란하다
  `,`■ 가택내상쟁사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  후원사 난망하고 부모재액이 있다.
  `,`■ 직녹신변외사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  가택권리가 양도된다.
  `,`■ 가택내손모사이다.
  내방조짐 상의 : 
  해산건이 있고 움직임이 근본이며 동기가 발동한다.부모재액예방 필요하다
  `,`■ 가택반목사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 가택금전손모사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.
  財․女事로 놀랜다.
  `,`■官ㆍ夫,신변반목대립사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/025/row-1-column-1.png`,
    `/static/images/sagwa/025/row-1-column-2.png`,
    `/static/images/sagwa/025/row-1-column-3.png`,
    `/static/images/sagwa/025/row-1-column-4.png`,
    `/static/images/sagwa/025/row-1-column-5.png`,
    `/static/images/sagwa/025/row-1-column-6.png`,
    `/static/images/sagwa/025/row-1-column-7.png`,
    `/static/images/sagwa/025/row-1-column-8.png`,
    `/static/images/sagwa/025/row-1-column-9.png`,
    `/static/images/sagwa/025/row-1-column-10.png`,
    `/static/images/sagwa/025/row-1-column-11.png`,
    `/static/images/sagwa/025/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 천지12신이 본궁에 있고 천지가 엎드려 움직이지 않고 신음하는 형상이므로 복음과(伏吟課)라고 한다. 양일은 일간상신을 초전으로 하고, 중․말전은 지형(地刑)을 취하며, 3전이 사신인(巳申寅)의 사맹신(四孟神)뿐이므로 원태격(元胎格)이라 부른다.
    초전은 중전을 극하고 중전은 말전을 극하며 말전의 인(寅)은 일간의 무토(戊土)를 극하여 체극(遞剋)이 된다. 뭇사람들에게서 멸시를 받고 극상(剋傷) 당한다. 음사(陰私)를 방지해야 한다. 순미(旬尾)의 사(巳)가 초전으로 되면 녹폐구격(祿閉口格)으로 되고 말전의 인(寅)은 초전 사화(巳火)를 생한다. 무(戊)에서 보아 덕(德)으로 되기 때문에 암암리에 남의 도움을 받는다. 처음에는 고생을 하지만 고생 끝에 낙을 본다. 복음과(伏吟課)로서 덕록역마(德祿驛馬)가 모두 움직여 구관(求官)․사관(仕官)에 이롭다.
    백법에 이르기를 ｢백호가 둔귀(遁鬼)와 동병하면 재앙이 심하다｣라고 하였다. 비전(秘傳)에서는 ｢백호가 장생으로 되면 불행 중에 행복이 있고 청룡이 일귀(日鬼)로 되면 행복한 가운데 불행을 본다｣라고 하였다. 일귀는 순행하여 3전은 구(勾)․백(白)․사(蛇)이고, 야귀는 역행하여 3전이 주(朱)․후(后)․청(靑)이다. 원태격은 꽃핀 후에 결실하는 형상으로서 잉태를 물으면 잉태가 있고, 명예를 물으면 장래에 발전하며, 이윤을 물으면 근원이 마르지 않는다. 만일 공망된다면 현태(玄胎)가 자라지 않는다고 하여 양육하기가 어렵다. 천후의 공망과 같이 논하고 같이 단정을 내린다.`
    ,
    // 2
    `  이 과는 제2과, 제3과에 음양의 극이 있고 양일이면 양비(陽比)의 극을 써서 술해(戌亥)를 초전으로 하는 지일과(知一課)로서 역연여격(逆連茹格)이다. 술(戌)을 초전으로 하면 참관격(斬關格)에 해당한다.
    신(申)자를 보면 참관이 통하는데, 이것은 술(戌)에는 정(丁)이 있고 신(申)에는 임(壬)이 있어서 암암리에 간합(干合)하기 때문이다. 술유신(戌酉申) 역연여(逆連茹)는 반가(返駕)라고 하여 옥에 갇힌 자는 형기가 차기 전에 출옥하며, 무(戊)에서 보아 3전의 금(金)은 순자손(純子孫)으로 되어 재를 구하지 않아도 자연히 재가 들어온다. 일간상신에 묘신(墓神)이 엎드려 혼미의 형상이나 술(戌)이 와서 진(辰)을 충하므로 개고(開庫)로 되어 재화(災禍)를 해소하고 흉을 간화(干化)하여 후에는 길하게 된다. 3과 해자(亥子)는 일간에서 보아 처재(妻財)가 겹치고 집안에 본처와 첩이 같이 있다. 일기궁(日寄宮)에서 보고 일간에서 본다.
    해(亥)는 역마이고 정신(丁神)이 되기 때문에 가택이 변경될 징조이다. 초전 술(戌)에 현무가 승하면 도적의 표적이 될 우려가 있다. 일간일지가 교차상극(交車相剋)되면 교섭하는 일은 이루어지지 않는다. 육해(六害)가 과전에 있어서 만사에 의혹 혹은 저해가 생긴다. 지일과(知一課)에서는 진퇴를 주저하는데, 먼저는 물러서고 후에 전진하면 일은 늦어지나 해가 없다.
    백법(百法)에 이르기를 ｢괴도천문격(魁度天門格)은 관격(關隔)이 있다｣라고 하였다. 백법의 제51법에 해당하는데 다시 기록한다. 술(戌)을 천괴(天魁)로 하고 해(亥)를 천문으로 하여, 술(戌)이 해(亥)에 가해져서 초전을 이룰 때는 이 격으로 되며 정단은 모두 조격이 생긴다. 현무가 승하면 도적 사건에 조격이 생겨 잡기 어렵다. 일귀는 순행하여 3전이 현(玄)․상(常)․백(白)이며, 야귀는 역행하여 3전이 현(玄)․음(陰)․후(后)이다. 백호가 3전에 들어가면 도로의 신이다. 조용하면 재앙을 면할 수 있지만 활동하면 흉해를 만난다.`
    ,
    // 3
    `  이 과는 4과 중에서 3과에 상하의 극이 있고 하에서 상을 적(賊)하는 묘축(卯丑)을 초전으로 하는 중심간전격(重審間傳格)이다. 3전 축해유(丑亥酉)는 음극격(陰極格)이다.
    축(丑)은 이양(二陽)이지만 양은 미약하고 음기가 차는 때이다. 퇴전(退傳)을 극음(極陰)으로 한다. 음사․음란․주색․간사로 바르지 못하며 흉조를 나타낸다. 일간일지상신은 모두 위에서 극을 받는다. 피차 모두 재해를 만난다. 주작이 간(干)에 가해지고 일간을 극하면 관리나 정치가는 탄핵(彈劾)을 받는다. 진(辰)은 새로운 것을 주관하고 술(戌)은 낡은 것을 지배한다. 묘(卯)와 술(戌)이 지합되면 옛 것을 버리고 새 것을 도모하는 것이 좋다. 중․말전 해유(亥酉)는 주색을 경계해야 한다.
    백법에는 ｢간과 지가 모두 위에서 아래를 극하면 억압되어 서로 손상이 있다｣라고 하였다. 비전에서는 ｢천을귀신이 묘유(卯酉)에 임하면 여덕격(勵德格)이라고 한다｣하였다. 고관은 관직의 변동이 있고 소관리는 퇴직한다. 술(戌)과 묘(卯)가 합하며 묘(卯)는 일간을 극한다. 이와 같은 것은 타인 공동으로 경영하고 남에게 기탁하여 동심 협력하는 것 같으나 상대방이 기만하므로 방심하지 말아야 한다.
    일귀는 순행하여 3전이 귀(貴)․음(陰)․상(常)이고, 야귀는 역행하여 3전이 공(空)․상(常)․음(陰)이다. 일야(日夜)에 모두 태음이 전(傳)에 들어가면 음모․궤사(詭詐)를 주관한다.
  `
    ,
    // 4
    `  이 과는 이상(二上)에서 이하(二下)를 극하는 섭해과(涉害課)로 제1과의 지반 사(巳)를 사용하여 인사(寅巳)가 초전이 되고 원태격(元胎格)으로 된다.
    일간상신의 인(寅)은 일간을 극하고 초전을 이루지만 제3과와 말전 신유(申酉)에서 귀(鬼)를 제(制)하여 화(禍)를 제거한다. 일간에서 말전에 탈기하고 일지는 생을 받으므로 사람은 적고 집은 크다. 말전에서 중전, 중전에서 초전을 생하여 관귀(官鬼)로 되고 장생으로 된다. 따라서 관직을 바라면 암암리에 천거를 받는다. 인(寅)에 청룡이 탔으므로 목적을 품으면 반드시 달성되고 원대한 뜻을 이룩한다. 섭해는 만사에서 다사다단하다. 대개 원태격은 임신의 일이 아니라면 갱신의 징조가 있다.
    백법에 이르기를 ｢백호가 둔귀(遁鬼)와 합병하면 재앙이 심하다｣라고 하였다. 일귀는 순행하여 3전이 사(蛇)․음(陰)․백(白)이고, 야귀는 역행하여 3전이 청(靑)․상(常)․후(后)이다.
  
  
  `
    ,
    // 5
    `  이 과와 같이 4과 중에 극이 없고 또 요극도 없는 것은 지반 유(酉)의 상신을 초전으로 하여 묘성과(昴星課)로 된다.
    중전을 일지상신, 말전을 일간상신으로 하여 3전을 구성한다. 일간일지상신과 축(丑)의 자(子)는 지합하고 사(巳)와 신(申)이 지합하여 교차상합(交車相合)으로 된다. 또 3전 사신(巳申)이 지합하고 말전 축(丑)과 일지 자(子)가 지합하여 4극 중 유(酉)와 진(辰)은 음신끼리의 지합이니 상하에 삼합․육합하므로 만사가 화합한다. 양일의 묘성과(昴星課)는 범의 꼬리를 밟는 형상으로서 사람의 일신에 근심사가 생긴다.
    술(戌)에서 초전의 사(巳)는 녹신에 해당한다. 일간 일지․3전이 합하는 것은 화기(和氣)로 된다. 이 과는 길과는 아니지만 능히 흉을 피면하여 길을 초래한다. 교차지합하면 교역 거래에 이롭다. 
    귀신(貴神)이 해(亥)에 임하면 귀인이 천문(天門)에 오른다. 그리고 맹신(孟神) 상에 진술축미(辰戌丑未)의 네 계절의 신을 보면 신장몰살(神藏沒殺)이라 하여 만사가 길하게 변화되며 윗사람 또는 타인의 원조를 받는다.`
    ,
    // 6
    `  이 과에서는 4과에 모두 상하의 상극이 있고 하에서 상을 극한다. 제1과 자사(子巳)를 사용하여 초전으로 하는 중심과(重審課)이다. 4과라고 하지만 3과밖에 없으므로 불비격(不備格)이다. 3전이 4과를 떠나지 않는 것은 회환격(廻環格)이고 일지가 간상으로 가는 것은 췌서격(贅壻格)이다.
    회환(廻環)은 4과의 신이 돌고 떠나기에 길과 흉이 모두 완전하지 못하다. 일간은 간상신을 극하여 처재(妻財)로 된다. 일간을 남편으로 하고 일지(日支)는 처궁(妻宮)으로 하여 지가 간에 가해지면 처가 남편을 따르고, 만일 일간기궁(日干寄宮)이 지상에 가해지면 남편이 처를 따른다. 이상과 같은 것은 자기를 버리고 타인을 따르는 것이므로 췌서격(贅壻格)이라 부른다. 타인의 위세에 의지하거나 타인을 따르는 일로 돈을 버는 형상이다. 전진할 때에는 권위가 없으며, 추구한다 해도 의지할 곳이 없다.
    년명상신(年命上神)이 길장을 얻으면 췌서권귀(贅壻權貴)를 얻는다. 제1과와 초전을 제외하고는 전부 공망되며 오직 초전의 처재(妻財)에만 돌아간다. 재는 스스로 하늘에서 오고, 공망이 많아서 진퇴를 결정하기 어렵다. 나아가서는 재를 위하여 물러서서는 조용히 있음으로서 자기의 복력을 타인에게 의존하는 과이다.
    백법에서는 ｢피차가 서로 의심하는 것은 육해(六害)를 만나기 때문이다｣고 하였다. 중․말전이 공망하여 전(傳)으로 돌지 않으면 모두 초전만에 의하여 길흉을 결정한다. 비전에는 ｢귀살(鬼殺)이 역마에 승하면 흉화가 가장 신속하게 온다｣라고 하였다. 무자일(戊子日)에 자(子)가 무(戊)에 임하면 여자의 병원(病源)이 재에 있다. 위에서 아래를 극하여 불비격으로 되면 여성이 색정불인(色情不仁)하다는 암시이다.
    이 과에서는 3전이 체극(遞剋)하여 4과에 극이 많고 야장(夜將)의 역마는 호귀(虎鬼)를 싣는다. 육해가 중첩되므로 흉해는 제거하기 어렵다. 일귀는 역행하여 삼전이 사(蛇)․공(空)․후(后)이며, 야귀는 순행하여 삼전이 청․귀․백이다.`
    ,
    // 7
    `  이 과는 천지칠충(天地七冲)의 자리에 있고 4과가 모두 상하로 상극하여 동요가 격렬하다. 양일이므로 양비(陽比)이고 아래에서 위를 극하는 오자(午子)를 취하여 초전으로 한다. 3전은 오자오(午子午)로서 모두 공망한다.
    일간상신 정신(丁神)은 재효로 되어 변동을 주재한다. 3전이 모두 공(空)이면 빈 소리뿐으로 길흉은 모두 취할 바가 못되며, 움직인다 해도 효과가 없다. 3전에서 자오(子午)의 상충(相冲)은 남북으로 뛰어 다닌다 해도 공허를 쫓는 것과 같다. 그래도 충하면 은근히 동의(動意)가 생기는데 이것은 충에 의하여 절반이 해공(解空)되기 때문이다. 반음과(返吟課)는 벼랑이 높고 골짜기가 깊은 형상으로 의지하기 어렵다.
    백법에서 ｢반음은 거래격(去來格)이라 부르고 거래 공망은 이동하여도 효과가 없다｣라고 하였다. 양일의 반음과는 덕을 잃고 녹이 끊어진다. 비전에는 ｢3전 오자오(午子午)를 삼교고개(三交高蓋)라 한다｣고 하였다. 문서일에 부실(不實)한 사건이 있고 존장은 위험하며, 인월(寅月)에 정단하면 오(午)는 사기(死氣)에 해당하고 부모나 존장에게 병이 있다. 사과(四課)에 모두 극이 있고 삼하(三下)는 삼상(三上)을 적한다. 아랫사람은 존장을 업신여기고 가정은 질서가 혼란하다.
    일귀는 역행하여 3전이 백․사․백이고, 야귀는 순행하여 3전 후․청․후이다. 길장이라 해도 길을 이루지 못한다. 점시 또는 년명이 사중(四仲)인 경우에는 참된 삼교격(三交格)으로 된다.`
    ,
    // 8
    `  이 과는 제2과․제3과에 상하의 극이 있으며, 하에서 상를 극한다. 사자(巳子)를 초전으로 하는 중심과이고 3전의 사술묘(巳戌卯)는 주인격(鑄印格)으로 된다. 그리고 3전이 4과를 떠나지 않는 것은 회환격(廻環格)이다. 일간상신 술(戌)의 천괴(天魁)가 3전 중에 들어 참관격이다.
    일간에서 일지상신이 사(巳)는 건록으로 되어 초전이 되며, 말전은 일귀(日鬼)이지만 초전을 생하고, 귀(鬼)는 인(印)으로 생화하여 내몸을 생하는 귀중한 신으로 된다. 초전은 덕록으로 왕하여 현임 관리에게는 가장 큰 기쁨이 있으며, 주인승헌격(鑄印乘軒格)은 덕격(德格)이라 불러 능히 덕행(德行)을 행한다. 덕이란 사물을 이롭게 하고 사람을 도우므로 화가 바뀌어 복이 되게 하는 것이다. 정치가에게는 길하고 백성에게는 불리하다. 괴강격(魁罡格)이라 해도 묘(卯)가 있어서 참관이 통하므로 정체하지 않는다.
    백법에 이르기를 ｢일지상에 녹을 보면 권섭(權攝)이 바르지 못하다｣라고 하였다. 3전 중에 간지의 묘신(墓神)을 보면 사랑과 미움을 구별해야 한다. 그러나 묘신이 사맹(四孟)에 가해지는 것은 이미 폐지한 것을 부흥시키는 형상이다. 비전에 이르기를 ｢4과가 완전하지 않은 불비격은 둘을 구하여 하나를 얻는다｣라고 하였다. 일귀는 역행하여 3전이 상(常)․합(合)․음(陰)이고, 야귀는 순행하여 3전이 음(陰)․합(合)․상(常)이다.
    주인격(鑄印格)은 직업상 가장 길한 과이다. 보통 일반인은 길이 가볍다고 본다. 점과(占課)의 법칙은 앞에서 거듭 말한 바와 같이 일간을 내몸으로 하고 일지는 상대자인 동시에 목적물이다. 모사․망사․방문․구재(求財) 등의 정단은 모두가 상생을 좋아하고 충극을 꺼리며 공망을 바라지 않는다. 이 과는 일간인 내몸이 무토(戊土)이고 상대방은 사화(巳火)로 일간을 생하며 일간상신 술(戌)과 묘(卯)는 지합한다. 그러므로 일은 성사한다고 본다.`
    ,
    // 9
    `  이 과는 4과 중 제3과에만 1상(上)에서 1하(下)를 극하여 진자(辰子)를 초전으로 하는 원수과(元首課)로서, 3전 진신자(辰申子)의 수국(水局)은 윤하격(潤下格)으로 된다.
    진(辰)에 현무가 승하여 수재(水財)로 되고 일지상신에 임하여 도적에게 재물을 도둑 맞는다. 진(辰)은 재범중범(再犯重犯)의 악인이다. 간(干)의 음양신은 금(金)국으로 되고 지의 음양신은 수(水)국으로 되어 사람이 가택을 생하므로 가옥을 개조하는데 재물을 쓴다. 금(金)국은 변경의 격이기 때문이다. 3합은 단결의 형상이며 재는 화기롭고 단결하므로 큰 재물을 얻는다. 진(辰)과 유(酉)는 3합․6합이 있어서 화기 융합하며, 일지상신 진자(辰子)가 초전으로 되면 관묘격(關墓格)이지만 신자(申子)를 보고 참관(斬關)이 통하기 때문에 정체가 없다. 원래 수국은 수류가 왕성하여 사물에 유통성이 있다. 진신자(辰申子)는 역삼합(逆三合)으로서 망두(望斗)라 부르는데, 때로는 흐름을 저지하는 일이 있다. 즉 반역(反逆)하는 일이 있다고 본다.
    백법에 이르기를 ｢간묘병관(干墓倂關)은 인택이 모두 쇠퇴할 우려가 있다｣라고 하였다. 비전에서는 ｢진(辰)에 자(子)가 가해지고 현무가 타면 부부가 일심일체로 될 수 없고 서로 음탕한 행위를 한다｣라고 하였다. 일귀는 역행하여 3전이 현(玄)․청(靑)․사(蛇)이고, 야귀는 삼전이 현(玄)․사(蛇)․청(靑)이다. 재기(財氣)가 왕성하면 존친을 상해한다. 천장(天將)의 작용은 중대하며 눈으로 볼 수 없다. 그리고 인사(人事)의 유신으로써 길흉을 주관하는 것이다.
    길장은 귀신․청룡․태상․육합․천후의 5장이고 흉장은 구진․천공․등사․백호․현무․태음․주작의 7장이다. 5성(五成)이라 하여 사물이 잘 성사하고 좋은 역할을 하는 것은 상생․상합․덕록․생왕․상생이다. 이것을 오성(五成)이라 한다. 7전(七戰)이란 형․충․파해․극․공․묘(墓)․절(絶)을 말한다. 이상의 5장 7장과 5성과 7전은 연구에 편리하니 암기하여 두는 것이 좋다.`
    ,
    // 10
    `  이 과에서는 4과 중에 극이 없고 일지상신에서 일간을 극하여 요극호시과(遙剋蒿矢課)이다. 3전 중신(仲神)만은 삼교격(三交格)이라 부른다.
    비스듬히 극한다고 하여 쑥(蒿)의 화살에 비유하였고, 극하는 힘이 약하기 때문에 길흉이모두 경미하다. 중전․말전은 공망하여 중전에서 말전으로 가지 않기 때문에 백법에서 이르기를 ｢전(傳)으로 가지 않는다｣라고 하며 초전의 일신(一神)으로 길흉을 단정한다. 천을귀신이 진술(辰戌)에 임하면 귀신이 옥에 들어간다고 부른다. 옥이란 교도소를 말하며 옥에 들어갈 때는 윗사람이나 귀인에게 의지할 수가 없다. 호시격이 공망되는 까닭에 무슨 일에서나 빈소리뿐이고 가정이 동요하는 징조가 있다. 일간에서 보아 목욕(沐浴)이 되고 음사불명(陰私不明)의 형상이다.
    자오묘유(子午卯酉)는 패신(敗神)이며, 음(陰)․합(合)․후(后)․주(朱)의 4장(四將)은 사중(四仲)의 천장이다. 삼교격이란 사중의 날에 정단하여 간 또는 지에 가해져서 일교(一交)로 한다. 3전이 모두 사중신(四仲神)이면 2교로 되고, 천장이 모두 사중신에 해당하여 삼(三)에 교차하므로 삼교격이라고 부른다. 만일 천장에 청룡․태상의 길장이 타면 사중의 천장이 아니기 때문에 흉중(凶中)에 길행이 있다. 이 과(課)는 야귀는 태상으로 초전에 있다. 또 년명상신에 길장이 승하거나 일간상신이 왕상하면 고개승헌(高蓋乘軒)이라 하여 길조가 있고 3교(三交)를 논하지 않는다.
    비전에 이르기를 ｢3교격은 길흉이 모두 안에서 발생한다｣라고 하였다. 초전이 실(實)하고 중․말전이 공망이면 시작이 있고 끝이 없다. 일귀(日貴)는 역행하여 3전이 음(陰)․백(白)․구(勾)이며, 야귀(夜貴)는 순행하여 3전이 상(常)․후(后)․주(朱)이다.`
    ,
    // 11
    `  이 과는 제4과 진인(辰寅)과 하에서 상을 극하여 중심맥월과(重審驀越課)로 된다. 초전은 묘신으로 되고 말전은 수토(水土) 장생으로 된다. 즉 묘(墓)에서 장생으로 전하는 것은 처음에 헤매다가 후에는 각성한다.
    일간상신은 일지를 극하고 지상(支上)은 일간을 교차상극(交車相剋)하여 이산되는 일이 있다. 말전 신(申)은 둔간(遁干)하고 신(申)이 귀살(鬼殺)로 되지만 공망하는 오(午)의 지(地)에 있어서 흉이면서도 흉을 이루지 않는다. 중심과에서는 심사숙고하여 일을 진행해야 한다. 일간상신이 공망하므로 진실과 성의를 만나지 못한다.
    백법에서는 공망 중에 천공이 승하면 탁상공론으로서 진실성이 없다. 천강이 인(寅)에 가해지면 강색귀호(罡塞鬼戶)로 되어 모사(謀事)를 맡아한다｣라고 하였다. 백법의 52법에서 이르기를 ｢3전 중에 있고 없는 것을 막론하고 진인(辰寅)이 겹치는 것은 이 격을 구성하며 재해를 피할 때는 중귀(衆鬼)가 노리는 일이 없다｣라고 하였다. 이 격과 같이 귀인이 천문에 오르는 강색귀호(罡塞鬼戶)이면 정단하는 사건은 무엇이나 길로 되어 일체가 지장이 없다고 한다. 즉 귀신이 인(寅)에 타서 귀(鬼)를 가로막아 나가지 못하게 하므로 만사에서 관대하고 마음대로 모사하여 효과가 있다. 비전에서는 ｢강색귀호격은 재해를 피해가기 때문에 재난을 당하지 않는다｣라고 하였다.
    진오신(辰午申)은 등삼천(登三天)의 격이고 공명이 달성되는 격이지만 공망을 만나므로 효과가 적고, 년명(年命)․행년(行年)상에서 오(午)의 전실(전(塡)實) 공망으로 되면 만사는 뜻대로 된다. 일귀는 순행하여 3전이 합․청․백이고, 야귀는 역행하여 3전이 합․사․후이다. 천후․육합이 타는 것은 교동격(狡童格)이므로 남자가 여자를 유혹할 우려가 있다. 결혼은 남몰래 자유결혼을 한다.`
    ,
    // 12
    `  이 과는 제3과․제4과에서 2상(二上)이 2하(二下)를 극하며, 양일이므로 양비(陽比)의 극이다. 인축(寅丑)을 초전으로 하는 지일연여격(知一連茹格)이다.
    일간상신 오화(午火)는 내몸을 생하나 공망으로 되어 실의(實意)가 있는 원조자가 없다. 3전 인묘진(寅卯辰)은 동방목기(東方木氣)로서 일간을 극하여 전귀(全鬼)로 된다. 그러나 순관(純官)은 사관(仕官)․구직을 정단하면 가장 길하지만 보통 간점(看占)은 재화를 면치 못한다. 인묘진(寅卯辰)의 연여격은 인(寅)의 맹(孟), 묘(卯)의 중(仲), 진(辰)의 계신(季神)이 상련하므로 관주연여(貫珠聯茹)라 부르며, 서로 견인하기 때문에 길사의 정단에서는 연주(連珠)를 좋아하고, 흉사일 때에는 흉사가 서로 견인하기 때문에 이것을 꺼린다. 임신하는 경우에는 연이어 임신한다. 천후에서는 개일 때라면 연이어 개이고 비라면 오랫 동안 비가 온다.
    3전은 전진하는 것이 좋다. 귀신의 순행은 신속하고 역행은 지체된다. 백법에서는 ｢나망(羅網)을 만나면 모사가 졸책으로 된다｣라고 하였다. 현임 관리는 고심과 재액을 초래한다. 일귀는 순행하여 3전이 사(蛇)․주(朱)․합(合)이고, 야귀는 역행하여 3전이 청(靑)․구(勾)․합(合)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
