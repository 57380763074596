import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./AddEdit.css";
import fireDb from "../firebase";
import { toast } from "react-toastify";
import Firebase from 'firebase';

const initialState = {
  name: "",
  email: "",
  contact: "",
  status: "",
  status2 : "",
  status3 : "",
  status4 : "",
  status5 : "",
  status6 : "",
};

const AddEdit = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const { name, email, contact, address, status, status2, status3, status4 } = state;
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    fireDb.database().ref().child("contacts").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
        
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !contact || !address) {
      toast.error("입력란을 확인해주세요.");
    } else {
      if (!id) {
        fireDb.database().ref().child("contacts").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Contact Added Successfully");
            alert("접수가 완료되어 최고 관리자 페이지로 넘어갔습니다.곧 연락드리겠습니다.")
            window.location.href = "/"
          }
        });
      } else {
        // fireDb.database().ref().child(`contacts/${id}`).set(state, (err) => {
        //   if (err) {
        //     toast.error(err);
        //   } else {
        //     toast.success("Contact Updated Successfully");
        //   }
        // });
      }

      setTimeout(() => {
        // history.push("/")

        window.location.href = "/"
    }, 500);
    }
  };
  return (
    <div style={{ marginTop: "100px" }}>
    육임 AI 신청<br/><br/><br/>
      <form
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit}
      >
        <label htmlFor="name">이름</label>
        <input
          type="text"
          id="name"
          name="name"
          placeHolder="Your Name..."
          value={name || ""}
          onChange={handleInputChange}
        />
        <label htmlFor="contact">전화번호(010)</label>
        <input
          type="number"
          id="contact"
          name="contact"
          placeHolder="Your Contact No. ..."
          value={contact || ""}
          onChange={handleInputChange}
        />
        <label htmlFor="email">이메일</label>
        <input
          type="email"
          id="email"
          name="email"
          placeHolder="Your Email..."
          value={email || ""}
          onChange={handleInputChange}
        />
        <label htmlFor="contact">주소</label>
        <input
          type="text"
          id="address"
          name="address"
          placeHolder="주소"
          value={address || ""}
          onChange={handleInputChange}
        />
        {/* <label htmlFor="name">Status</label>
        <input
          type="text"
          id="status"
          name="status"
          placeHolder="Your Status..."
          value={status || ""}
          onChange={handleInputChange}
        /> */}

      <label 
        style={{ display: 'none'}}
        >활성화 여부</label><br/>
      <select  
      name="status" 
        value={status || "NO"}
        style={{ display: 'none'}}
      onChange={e=> {
        console.log("e");
        console.log(e.target.value);
        handleInputChange(e, e);
      }}>
        <option value="NO">NO</option>
        <option value="YES">YES</option>
      </select>
        <input type="submit" value={id ? "Update" : "신청"} />
        <br/>
        <br/>
        <br/>
        위 신청접수<br/>
        기재를 완료하시면 <br/>
        최고관리자 페이지로 넘어가며, <br/>
        기재하신 개인정보는 감춰집니다.<br/>
      </form>
    </div>
  );
};

export default AddEdit;
