
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,7,11,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,8,10,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`신뢰`,9,9,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,10,8,`특별있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,7,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,6,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,1,5,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,2,4,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`신뢰`,3,3,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,4,2,`특별있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,5,1,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,12,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`10`,`외사단합`,``],
    [`부모`,`부모 관련사`,`大`,`침해기`,`사사성취`,`免屈난해`],
    [`소모무력(所謀無力)`,`주력사가 무력한 상`,`大`,``,`(허성불성)`,``],
    [`성취허사(成就虛事)`,`성취가 될 될듯하나 허사가 되고마는 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,`파재극처`,``],
    [`사업`,`사업 관련사`,`大`,`11`,`실종우려`,``],
    [`여인`,`처첩 관련사`,`小`,`쇠퇴기`,`문서상신`,`사사성취`],
    [`기리조용(豈利造用)`,`내리막에 퇴한 중 어찌 일을 만들려 하는가-의 상`,`小`,``,`답신지체`,`(허사불성)`],
    [`화소복지(禍消福至)`,`재난이 물러가고 복이 오는 상`,`大`,``,`구설사`,``],
    [`형제`,`형제친구동료 관련사`,`大`,``,``,`급속사`],
    [`매사 허성불성`,`음12,10,11월 방문자는 종교진출하면 성공함`,`小`,`12`,``,`문서상신`],
    [`매사 영속성없음`,`형제친구동료동지협력사는선천적 기복재해를 겪음 `,`小`,`과시기`,`화소복지`,`답신지체`],
    [`택역허액(택역허액)`,`재난이 점차 줄어가는 중 가택액난도 역시 헛 것인 상`,`大`,``,`출행이동`,`구설사`],
    [`화소복지(禍消福至)`,`재난이 물러가고 복이 오는 상`,`小`,``,``,``],
    [`결속`,`개신결속으로 권리사를 크게 이루나 病 형제災害 발생함`,`大`,``,``,``],
    [`매사불성`,`음12,10,11월 방문자는 종교진출하면 성공함`,`小`,`1`,`남모르는`,``],
    [`매사 영속성없음`,`형제친구동료동지직녹사는선천적 기복재해를 겪음 `,`小`,`절정기`,`능욕박해`,`謀事미수`],
    [`부녀노병(婦女怒病)`,`부녀의 병은 분노로 생긴 것으로 虛한 것인 상`,`大`,`직녹무망`,`손해도망`,``],
    [`도적악의(盜賊惡意)`,`도둑이 악의를 가졌으므로 공격하지 말라-는 상`,`小`,`종교자립`,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`2`,`외교막힘`,`남모르는`],
    [`처`,`처 관련사`,`小`,`면모기`,`필유성취`,`능욕박해`],
    [`좌수개의(座守皆宜)`,`앉아서 지키며 도모해야만 모든 일이 좋게 되는 상`,`小`,``,`반목조짐`,``],
    [`문서암동(文書暗動)`,`문서가 남모르게 넘어가는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`3`,`급속사有`,`가정막힘`],
    [`급속`,`외사 급속사`,`小`,`학습기`,`문서유실`,`필유성취`],
    [`이취헌납(利取獻納)`,`상품판매의 이익을 위해 금전을 헌납하는 상 `,`小`,``,``,``],
    [`허획경구(허획경구)`,`도적은 못잡고 괜히 놀래는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`색정`,`내女 색정사 有`,`大`,`4`,`음인災疾`,`색정사有`],
    [`자녀`,`자녀 문제사`,`小`,`개시기`,`(숨긴情人)`,`문서유실`],
    [`득재이회(得財而回)`,`득재를 한다해도 조심스럽게 나아갈때로 되돌아 가는 상`,`小`,``,`구설괴이`,``],
    [`공사구설(公私口舌)`,`공적 사적인 구설이 듣게되는 상`,`小`,``,``,``],
    [`금전 `,`금전 관련사-공허`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`5`,`고위途中`,``],
    [`결속`,`개신하여 권리사를 크게 이루나 병, 형제 災害 발생함`,`小`,`배양기`,`청원이吉`,`음인재질`],
    [`관길상흉(官吉常凶)`,`관직자는 길하고 일반인은 대흉의 상`,`小`,``,``,`(숨긴연인`],
    [`음인재질(陰人災疾)`,`숨겨놓은 情人의 재액과 질환이 있는 상`,`大`,``,``,`災病사)`],
    [`관사`,` 관련사`,`小`,``,``,`애증화합`],
    [`직업`,` 관련사`,`小`,`6`,``,`반목송쟁`],
    [`송사`,`토지 관련 송쟁사가 벌어진 성`,`大`,`존재기`,`봉림유蛇`,`고위在道`],
    [`침해극성(侵害極盛)`,`해치려는 새력이 많은 상`,`小`,``,``,`청원좋음`],
    [`봉림유사(逢林有蛇)`,`먼곳에서 방금 당도하여 숲에서 뱀을 만난 상-무시무방`,`大`,``,``,`파재극처`],
    [`관사`,` 관련사`,`小`,``,``,``],
    [`직업`,` 관련사`,`小`,`7`,`고시득길`,`색정사有`],
    [`색정`,`내女 색정사 有`,`大`,`단절기`,`미세步訟`,`謀望미수`],
    [`속전이사(速遷移也)`,`꿈에 귀신이 괴이하니 속히 이사를 해야 하는 상`,`小`,``,`(경벌)`,``],
    [`도중청알(途中請謁)`,`고위인이 도로에 있을 때 청원함이 좋은 상`,`大`,``,``,``],
    [`금전`,`금전 관련사-공허`,`小`,``,`동요분산`,`침체到來`],
    [`상업`,`사업 관련사`,`大`,`8`,`불측근심`,`정위난측`],
    [`처`,`처 관련사`,`小`,`침체기`,`(측정이안`,`(상대진심`],
    [`주식성합(酒食成合)`,`직위 영전을 하려면 주식자리를 갖는 것이 좋은 상`,`小`,``,`되는근심)`,`가늠안됨)`],
    [`봉림유사(逢林有蛇)`,`먼곳에서 방금 당도하여 숲에서 뱀을 만난 상-무시무방`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`9`,`門訟연연`,`동요불안`],
    [`출행`,` 내사 출행이동원행사`,`大`,`소외기`,``,`불측근심`],
    [`능수지재(能守之財)`,`재를 능히 지킬 수 있으니 어떤 일도 적극 움직이라-는 상`,`大`,``,``,``],
    [`면굴난해(免屈難解)`,`단순 반복적 굴욕을 면하기 어려운 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,`후원문서부모조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,`금전사업여인조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`금전사업여인조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,``,`직업직위불안가정막힘사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,``,`직업직위불안가정색정사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`금전사업여인조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사 직업 남편 사로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사직업남자사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`금전사업처조력사로가늠`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`후원문서부모조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함`,0,0,`당면 사안이 명분이 있으므로 정정당당하게 속결해야 하며 좌고우면하거나 지체시키면 결과가 되려 불리해지는 人 `,0,0,`당면사에서 퇴함이 좋으나 1926,27,38,39,50,51,62,67,74,75,86,87,98,99년 출생자는 진하여 좋음**1녀2남 삼각관계 有**독신고독각방주말부부생리사별적 처지임**사안이 갑자기 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유순하게 처신하여 일을 지체시키거나 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`일이 거꾸로 띄엄띄엄 진행됨**일을 이면적으로 진행함이 좋음**일이 갑자기 발생함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유순하게 처신하여 일을 지체시키거나 뒤로 미루면 반드시 결과가 유리해지는 인`,0,0,`토지송사가 있음**적극 움직이는 것은 좋고 수구적이면 나쁨**입을 닫아야 할 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 호가호위의 세를 보일 뿐 공격할 뜻이 없어 피해가 있더라도 경미함`,0,0,`옳지 못한 일을 꾸미다가 드러난 사안으로 천둥에 놀란 듯한 심중이나 조용히 근신하면 자연 풀리게 되는 人`,0,0,`물사를 개신개혁하여 하나로 일관시킴`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유순하게 처신하여 일을 지체시키거나 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`정대한 일이 단절됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`색정배반사가 있음**심신을 의지할 데가 없으며 풍파 상태의 人`,0,0,`신구사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유순하게 처신하여 일을 지체시키거나 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`일이 반복되고 결과가 없으나 진행하면 밝아짐**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순응유화후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유순하게 처신하여 일을 지체시키거나 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`개신개혁으로 일을 하나로 일관함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`토지가옥사의 소송이 있음**동분서주-動길 靜 흉`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 유순하게 처신하여 일을 지체시키거나 뒤로 미루면 반드시 결과가 유리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**서리 내린 다리를 건너는 격임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威 勢)세**만약 타인과 다툰다면 상대는 호가호위의 세를 보일 뿐 공격할 뜻이 없어 피해가 있더라도 경미함`,0,0,`호가호위 세로 상대를 공격하나 적중되지 못함으로 상대가 이로우니 중지해야 하는 人`,0,0,`일을 전진하게 됨**1남2녀 삼각관계 有**일이 갑자기 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 공격하지는 않음`,0,0,`스스로 유약하다 여기고 적극적 활동을 포기하며 신음하는 人-신체의 자유가 억압된 경우도 있음`,0,0,`전화위복 됨**깊은 산속으로 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사타발동급속동시사`,`기사`,`신구중간사`,`외사내사 겹침`,`아표면사타이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신`,`보성-무력`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`충성-무력`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사후회사겹침`,`기사`,`구사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월`,1,1,2,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음8월`,2,1,3,`음2월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월-연기우려`,1,1,2,`음1월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음6월-연기우려`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음5월`,1,2,3,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,3,2,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음11월-연기우려`,2,1,3,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월-연기우려`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월`,2,2,4,`음11월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음10월`,3,2,5,`음6월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음11월`,2,3,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음10월`,3,2,5,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 업무는 실패함-단,1926,27,38,39,50,51,62,63,74,75,86,87,98,99년 출생자는 업무실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여  중반의 실적 외 초반 종반이 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 시종반목대립으로 송쟁사가 있게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나  결속단합으로 업무를 일관시켜 성과를 크게 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무 내내 동요불안분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언해이 불성실하여 업무를 수준이하로 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나   개신개혁을 이루어 업무실적을 고양시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무 내내 반목대립 송쟁사를 벌임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯하고 조정능력이 있으나 업무진행에 차질이 많아 수준이하의 실적을 보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 합리적이지 못하고 업무를 효과없이 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯해도 조정능력이 있어 업무는 수준으로 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세무력`,`이행`,`본인무력`,`退背`,`근`,` 去`,`세입조력`,`정북`,``,`정남`],
    [`10.11`,``,`제압무관`,``,``,``,``,``,`설기피로`,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,`본인무력`,``,``,``,``,``,``,``],
    [`7.8월`,``,`발현안됨`,`이행`,`사안무력`,`退背`,`  근`,`去`,`주인무력`,`정남`,``,`정남`],
    [`10.11`,``,`기세무력`,``,``,``,``,``,``,`세밀심사`,``,`세밀심사`],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,`함`,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`남남서`,``,`남서`],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,`세밀심사`,``,`세밀심사함`],
    [`3.6\n9.12`,` 多集`,`기세무력`,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`多集`,`반대없음`,`이행`,`선난후이`,`進向`,`  원`,`  留`,`세입조력`,`서북`,`정북`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,`세밀심사`,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선이후이`,`進向`,`  원`,`  留`,`주인가해`,`북북동`,`북북동`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후난`,`進向`,`  원`,`  留`,`주인가해`,`북북동`,`북북동`,``],
    [`10.11`,``,``,``,``,``,``,``,``,`세밀심사`,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,`함`,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세무력`,`이행`,`선난후이`,`退背`,`원근왕래`,`   去`,`세입조력`,`정남`,`동남`,``],
    [`10.11`,``,`본인제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인동조`,`이행`,`선난후이`,`退背`,`  원`,`  去`,`세입가해`,`동남`,`동남`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`세입가해`,`남서`,`남남서`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`이행`,`선攻후攻`,`進向`,`  원`,`  留`,`세입조력`,`정서`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,`의사`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인내부`,`이행`,`선이후이`,` 進向`,`  원`,`  留`,`무난`,`북북서`,``,`남남서`],
    [`10.11`,``,`합세제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`선난후이`,`進向`,`  근`,`  留`,`무난`,`정북`,``,`남서`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,`자살귀`,`목맴`,``,``,`묘좌`,0,`있음`],
    [``,`변사귀`,`객사`,``,``,`묘향`,0,``],
    [`생업`,`자살귀`,`목맴`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`변사귀`,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`자살귀`,`목맴`,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`변사귀`,`객사`,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`저주원심`,`노비저주`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`원한귀`,`불혼사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`저주원심`,`노비저주`,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`신부정귀`,`신不定`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`신부정귀`,`신不定`,``,``,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`제례귀`,`제례위범`,``,``,`묘좌`,0,`틀별있음`],
    [``,` 수귀`,`익사`,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`제례귀`,`제례위범`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`고모부정`,`성물훼손`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사 동요분산-출행이동사`,`자강불식-한가함-실업자 상`,``,`일체근심해산사`,``,`禍소복지사`,`매사 허성불성-세속허사자**내사송쟁급속사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`직녹자산권리침해- 매사영속성없음`,`종교진출하면자립함`,`매사 위역사**외사막힘-반목`,`免屈난해사`,`외사 급속사**내사막힘`,`불측근심사`,`내녀 색정사`,`상대 진심 가늠안됨`,``,`손괴재물사-이동사`,`내사 애증화합-송쟁-파재극처사`,`승진영전사`,`외남 색정사`,`도적악의-공격말 것`,`매사 위역사**외사동요분산사`,`연인에게 당한 남모르는 손해사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,``,``,`전도**빈궁`,``,`외사 출행이동사**곤궁`,`문사상신지체-구설사`,``,`관재`,`전화위복`,`전도**빈궁`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,`빈궁`,``,`전도*빈궁**병재`,`재액해소`,`관재`,`직원남녀공모사**재액해소`,`빈궁**관재`,`침체驚懼사**남녀직원공모은닉사`,`전도**빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**남녀직원공모은닉사`,`연인에게 당한 남모르는 손해사`,`내사 동요분산-출행이동사`,`자강불식-한가함-실업저 상`,``,`일체근심해산사`,``,`禍소복지사`,`매사영속성없음**내사 송쟁급속사`,`종교진출하면자립함**문서상신지체-구설사`,`직녹자산권리침해-매사영속성없음`,`종교진출하면자립함`,`매사위역사**외사막힘-밤목`,`免屈난해사`,`외사 급속사**내사막힘`,`불측근심사`,`내녀 색정사`,`상대 진심 가늠안됨`,``,`손괴재물사-이동사`,`내사 애증화합-송쟁-파재극처사`,`승진영전사`,`외남 색정사`,`도적악의-공격말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사동요분산사`,`빈궁**관재`,`전화위복`,`전도`,``,`전도`,``,`빈궁`,`외사 출행이동사`,`빈궁**관재`,``,``,`전화위복`,`빈궁`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,`빈궁**관재`,``,`빈궁**병재`,``,`관재`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`외남 색정사`,`도적악의-공격말 것`,`매사 위역사**남녀직원공모은닉사`,`연인에게 당한 남모르는 손해사`,`내사 동요분산-출행이동사`,`자강불식-한가함-실업저 상`,``,`일체근심해산사`,``,`禍소복지사`,`매사영속성없음**내사 송쟁급속사`,`종교진출하면자립함**문서상신지체-구설사`,`직녹자산권리침해-매사영속성없음`,`종교진출하면자립함`,`매사 위역사**외사막힘-반목`,`免屈난해사`,`외사 급속사**내사막힘`,`불측근심사`,`내녀 색정사`,`상대 진심 가늠안됨`,``,`손괴재물사-이동사`,`내사 애증화합-송쟁-파재극처사`,`승진영전사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`외사동요분산사`,`빈궁`,`전화위복`,`전도`,``,`전도`,`빈궁`,``,`외사 출행이동사`,`빈궁`,``,``,`전화위복`,`빈궁`,`음4,5월 화재주의`,`관재`,`음4,5월 화재주의`,`빈궁**관재`,``,`빈궁**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`고위행길도중청원사`,`내사 애증화합-송쟁-파재극처사`,`연인재병구설괴이사`,`외남 색정사`,`문서망실사`,`매사 위역사**외사동요분산사`,`내사 동요분산-출행이동사`,`주력사 모두 흉함`,`남 모르는 능욕박해사`,``,`謀事미수사`,``,`매사영속성없음**내사 송쟁급속사**공공사사구설사`,`종교진출하면자립함`,`직녹자산권리침해-매사영속성없음`,`종교진출하면자립함`,`외사막힘-반목`,`관직자 유리 일반 불리`,`외사 급속사**내사막힘`,`도적 못잡고 놀램`,`내녀 색정사`,`남모르게 문서가 넘어감`,``,`송구경황-사통인이 두려운 자임을 앎**이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**관재`,``,`빈궁`,``,`전화위복`,``,`빈궁`,``,``,``,`외사 출행이동사`,`전도**빈궁`,`折傷가족사`,`전도**빈궁**병재`,`전화위복`,``,`음4,5월 화재주의`,`빈궁**관재`,`재액해소`,`빈궁`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`송구경황-사통인이 두려운자인지를 앎**이동사`,``,`고위행길도중청원사`,`내사 애증화합-송쟁-파재극처사`,`연인재병구설괴이사`,`외남 색정사`,`문서망실사`,`매사 위역사**외사동요분산사`,`내사 동요분산-출행이동사`,`주력사 모두 흉함`,`남 모르는 능욕박해사`,``,`謀事미수사`,``,`매사영속성없음**내사 송쟁급속사`,`종교진출하면자립함**공공사사구설사`,`직녹자산권리침해-매사영속성없음`,`종교진출하면자립함`,`외사막힘-반목`,`관직자 유리 일반 불리`,`외사 급속사**내사막힘`,`도적 못잡고 놀램`,`내녀 색정사`,`남모르게 문서가 넘어감`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`관재`,``,`전도**관재`,``,`전도**빈궁**관재`,``,`빈궁`,`전화위복**재액해소`,``,`재액해소`,`빈궁**관재`,``,``,`외사 출행이동사`,`빈궁**관재`,`折傷가족사`,`빈궁**병재`,`전화위복`,``,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`내녀 색정사`,`남모르게 문서가 넘어감`,`송구경황-사통인이 두려운 자인지를 앎-이동사`,``,`고위행길도중청원사`,`내사 애증화합-송쟁-파재극처사`,`연인재병구설괴이사`,`외남 색정사`,`문서망실사`,`매사 위역사**외사동요분산사`,`내사 동요분산-출행이동사**재액해소`,`주력사 모두 흉함`,`남 모르는 능욕박해사`,``,`謀事미수사`,``,`매사영속성없음**내사 송쟁급속사`,`종교진출하면자립함**공공사사구설사`,`직녹자산권리침해-매사영속성없음`,`종교진출하면자립함**折傷가족사`,`외사막힘-반목`,`관직자 유리 일반 불리`,`외사 급속사**내사막힘`,`도적 못잡고 놀램`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,`동요불안분산사`,``,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`전도**관재**빈궁`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`관재`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사**재액해소`,``,`동요불안분산사`,`빈궁`,`동요불안분산사-외사출행이동사`,`빈궁**병재`,`동요불안분산사`,``,`동요불안분산사**전화위복`,`빈궁`,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`도적 못잡고 놀램`,`외사 급속사**내사막힘`,`남모르게 문서가 넘어감`,`내녀 색정사`,`송구경황-사통인이 두려운 자인지를 앎-이동사`,``,`고위행길도중청원사`,`내사 애증화합-송쟁-파재극처사`,`연인재병구설괴이사`,`외남 색정사`,`문서망실사`,`매사 위역사**외사동요분산사`,`내사 동요분산-출행이동사`,`주력사 모주 흉함`,`남 모르는 능욕박해사`,``,`謀事미수사`,``,`매사영속성없음**내사 송쟁급속사`,`종교진출하면자립함**공공사사구설사`,`직녹자산권리침해-매사영속성없음`,`종교진출하면자립함`,`외사막힘-반목`,`관직자 유리 일반 불리`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의`,``,`전도`,`관재`,`재액해소`,`빈궁**관재`,`재액해소`,`전도**빈궁`,``,`관재`,`전화위복`,`빈궁`,``,`전도`,``,`외사 출행이동사`,`빈궁`,`빈궁**관재**병재`,`折傷가족사`,`전도**관재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`외사막힘-반목`,`관직자 유리 일번 불리`,`도적 못잡고 놀림`,`외사 급속사**내사막힘`,`남모르게 문서가 넘어감`,`내녀 색정사`,`송구경황-사통인이 두려운 자인지를 앎-이동사`,``,`고위행길도중청원사`,`내사 애증화합-송쟁-파재극처사`,`연인재병구설괴이사`,`외남 색정사`,`문서망실사`,`매사 위역사**외사동요분산사`,`내사 동요분산-출행이동사`,`주력사 모두 흉함`,`남 모르는 능욕박해사`,``,`謀事미수사`,``,`매사영속성없음**내사 송쟁급속사`,`종교진출하면자립함**공공사사구설사`,`직녹자산권리침해-매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`관재`,`빈궁`,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의**재액해소`,``,``,`관재`,``,`빈궁**관재`,``,`빈궁`,``,`전화위복`,``,`빈궁`,``,``,``,`외사 출행이동사`,`전도**빈궁**관재`,`전도**관재`,`折傷가족사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`직녹자산권리침해-매사영속성없음`,`종교진출하면자립함`,`免屈난해사`,`매사 위역사**외사막힘-반목`,`주력사 모두 흉함`,`외사 급속사**내사막힘`,`고시득길사-미세송사`,`내녀 색정사`,`불辯근신사-이동사`,``,`고위행길도중청원사`,`내사 애증화합-송쟁-파재극처사`,`간음무도사`,`외남 색정사`,`연인에게 당한 남모르는 손해사`,`매사 위역사**외사동요분산사`,`내사 동요분산-출행이동사`,`도적 못잡음`,`酒食受罰사`,``,`손괴재물사(牛)`,``,`매사영속성없음**내사 송쟁급속사`,`종교진출하면자립함**공공사사구설사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,`빈궁`,``,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의`,`빈궁`,``,`관재`,``,`관재`,``,`빈궁`,``,`전화위복`,`전도**빈궁`,`전도`,``,`병재`,``,`외사 출행이동사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사 영속성없음**내사 송쟁급속사`,`종교진출하면 자립함**재물暗動사`,`직녹자산권리침해-매사 영속성없음`,`종교진출하면 자립함`,`관직자 이익-일반 불리`,`외사막힘-반목`,`殃禍소멸사`,`외사 급속사**내사막힘`,`상대 진심 가늠안됨`,`내녀 색정사`,``,`謀事미수사-이동사`,`굴욕반복사`,`내사 애증화합-송쟁-파재극처사`,`간음사 이익-정대적이면 재앙 됨`,`외남 색정사`,`매사 위역사**외사동요분산사`,``,`내사 동요분산-출행이동사**陰人암손사`,`봉흉化길사**문서망실사`,``,`일체근심해산사`,``,`송구경황-간음상대 나타남`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 출행이동사`,`전도**빈궁`,`모두 불리-도난사`,`전도**빈궁**관재`,``,`전화위복`,`빈궁**병재`,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의**재액해소`,``,``,`빈궁**관재**병재`,``,`관재`,``,`빈궁`,``,`전화위복`,`빈궁`,``,``,`관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,``,`매사영속성없음**내사 송쟁급속사`,`종교진출하면 자립함`,`매사 허성불성-세속허사자`,`음1,2,10,11월 방문자는 송교진출하면 성공함`,`매사 위역사**외사막힘-반목`,`免屈난해사`,`불측근심사`,`외사 급속사**내사막힘`,`상대 진심 가늠안됨`,`내녀 색정사`,``,`손괴재물사-이동사`,`승진영전사`,`내사 애증화합-송쟁-파재극처사`,`도적악의-공격말 것`,`외남 색정사`,`매사 위역사**남녀직원공모은닉사`,``,`내사 동요분산-출행이동사**陰人암손사`,`봉흉化길사**한가한 실업자 상`,``,`일체근심해산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`외사 출행이동사`,`빈궁**관재`,`직녹자산권리침해`,``,`전화위복`,`빈궁`,``,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의`,`빈궁**병재`,``,`관재`,``,`빈궁**관재`,``,`외사동요분산사`,`빈궁`,`전화위복`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`일체근심해산사`,``,`禍소북지사`,`매사 허성불성-세속허사자**내사 송쟁급속사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 위역사((외사막힘-반목`,`免屈난해사`,`불측근심사`,`외사 급속사**내사막힘`,`상대 진심 가늠안됨`,`내녀 색정사`,``,`손괴재물사-이동사`,`승진영전사`,`내사 애증화합-송쟁-파재극처사`,`도적악의-공격말 것`,`외남 색정사`,`폐한인**남녀직원공모은닉사`,``,`내사 동요분산-출행이동사`,`한가한 실업자 상`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`빈궁`,``,`외사 출행이동사`,`빈궁`,`직녹자산권리침해`,``,`전화위복`,`빈궁`,``,`음4,5월 화재주의`,`빈궁`,`음4,5월 화재주의`,``,`빈궁**병재`,``,`관재`,``,`빈궁**관재`,`외사동요분산사`,`빈궁`,`전화위복`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■돈 때문에 속께나 끓이고    있겠네?

  ⊕신부정귀 
  ⊖신불제사귀 때문인데, 
    해소 시키면 관재고심도 없어    지고 훤히 뚫리겠네!
  
  ⊕⊖신변에 고모신사부정이      침범했네 ?
  `,`■가정에 형제간 싸움으로     부모가 떠나는 형국이네?
    일을 새롭게 계획해야겠어!
  ⊕⊖가택고모신사 부정 때문이야!      1女2男 삼각관계가 있네?
  `,`■ 수입이 끊어 졌네?
     바람도 났고… 
     진퇴양난이겠어?
  
  ⊕여귀 
  ⊖수귀를 달래야 집이 넘어    가도 살길이 열리지!
  `,`■ 땅도 크고 돈도 큰데 
     능력이 미치지 못하겠어!
     순조롭지가 않아! 
     토지관재송사가 걱정인데     말조심해야겠어!
  
  ⊖신변 음사부정 침범이 들었     네 ?
  `,`■ 여러 일을 하나로 묶어      개혁 시키겠는데 제대로     되려면
  관직자:기쁨이 여러개가 되겠네!
  일반인:관재․병재가 걱정이네!
  ⊕도로귀, 
  ⊖가택 음사부정과 수귀를     해소시켜야 해!
  `,`■ 지금까지 해 온 모든 일과     인연이 다했지만 좋은일이     기다리네! 서둘지않아도     多人의 천거를 받게 되겠어 !
  ⊕신부정귀, 
  ⊖전사귀를 해소하고 차분하게    있으면 오히려 만사가 좋아!
  ⊖신변음사부정이 침범했네 ?
  `,`■색정불화로옛일이다시       재발되고 있네? 풍파가 심     하게 불어 닥쳤어! 그나마     부정을 해소해야 대책을 세     울것 아닌가.
  ⊖가택에 저주원심부정과 음사    부정이 들었어!
  
  
  ⊕⊖신변에 저주원심부정이      침범 했네 ?
  `,`■ 모든 일이 등 돌려버렸네?     부부간에도 각각 딴 정이     있고…   이별수네!
  
  ⊕도로귀, 
  ⊖자살귀 발동으로 그러네!
  ⊕⊖가택저주원심부정이 듬.
  
  ⊕신변음사부정이 침범했네?
  `,`■ 개신시킬 일이 한 두가지가     아니네? 
     확 바꾸려하지만 관직자    외에는 凶이 되겠어!
  관직자:기쁨이 여러개가 되겠네!
  일반인:관재․병재가 걱정이네!
  ⊕가택음사부정ㆍ도로귀, 
  ⊖자살귀를 없애야 효과가     더욱 크겠어!
  `,`■토지사로 송사가 붙었네?    모두 돈인것 같아도 허사야!
  
  ⊕가택신부정귀, 
  ⊖전사귀를 없애고 희사하든가   물러서야 凶을 면하겠어!
  
  ⊕신변음사부정이 침범했네?
  `,`■ 변동수가 보이네? 
     집을 옮기거나 생업을      바꾸겠어! 
     그런데 전전긍긍이네?
  
  ⊕가택음사부정ㆍ신부정귀, 
  ⊖전사귀를해소해야만이     길이 열려!
  `,`■ 한 남자에 두 여자가 
     붙었네? 
     내가 손해야!
  
  ⊕여귀, 
  ⊖수귀를 잡아야 고심사들이    해결될거야!`];
  const YOGYEOL1 = [`■ 천강이 중첩하여 움직였으니 몸이 움직이고 편안치 않다. 밤정단은 부정 타서 좋지 않으나 다행히 상하는 일은 없겠다.
  `,`■ 빈 녹이 초전이 되고 계속해 비어 손해일 뿐이니 꾀하는 일은 아무 효과가 없겠고 밤정단은 서서히 좋으리라.
  `,`■ 나나 너나 모두 비었으니 반사가 종적이 없다. 낮정단 병은 음식을 못 먹고 밤정단은 흉이 많으리라.
  `,`■ 눈에 보이는 것이 모두 재물이나 소비한 돈을 받을 수 없다.
  낮정단은 범이 묘를 타고 있으니 입을 꼭 다물어야 무사하다.
  `,`■ 말전에서 초전의 관살을 도우나 두려울 것은 없다. 아래는 절벽이고 위는 원수이나 잘 풀려 좋게 되겠다.
  `,`■ 공직자는 두려움을 만나고 일반인은 흉을 면한다. 낮정단은 귀인에게 부탁받고 밤정단은 좋은 것이 조금 부족하겠다.
  `,`■ 낮정단은 손해를 막아야 하고 돕는이도 믿을 수 없다. 밤정단은 사기 당하고 간사한 약속에 속으리라.
  `,`■ 몸은 다치고 집은 어둡고 답답하다. 낮정단은 매사 쪼들리고 움직이려 하나 여의치 않으니 나쁜 재물을 얻으리라.
  `,`■ 처음에는 화목해도 나중에는 서로 배신한다. 낮정단은 모든 재앙이 흩어지나 낮정단은 흉이 모두 진치고 있다.
  `,`■ 돈을 지니면 귀신이 따라붙으니 돈을 바치고 명예를 사라. 모든 것이 잘 되기를 바라거든 신불에 정성을 바치라.
  `,`■ 두 귀인이 있어도 모두 믿고 의지할 수 없다. 허비는 백가지로 나고 집은 이사하게 되리라.
  `,`■ 진흙으로 맞았는데 돌덩이로 변했다. 내가 쏜 것도 마찬가지이다. 
  고생스러워도 지키는 것이 좋다. 앞뒤로 핍박뿐이다.`]
  const SIBO1 = [`■ 토지 금전사로 굴욕을 
  당한다.
  관재 기운이 있어 숨고 싶은 심정에 있다.
  안정하는 것이 좋고 때를 기다리는 냉정함이 필요하다. 
  기도ㆍ치성하면 효과가 있다.
  `,`■ 가정에 ⊕고모신사 부정이
  들어 동기간에는 불화
  하고 부모가 떠난다.
  모든 이익이 없어지고 그 일에 매달려도 이룩되는 것이 없다.
  일은 새롭게 계획하여야 좋다. 시기를 기다려 활동해야 한다.
  `,`■ 가택의 권리가 넘어간다.
  十月 임신사가 있다.
  모든 일에 종적이 없으며 凶이 무겁다.
  일이 순조롭지 못하므로 진퇴에 의혹이 생긴다.
  달이 서산에 지는 격이니 어떤 일도 추구하지 않는 것이 좋다.
  `,`■ (점자는 솔직한 말을 
  하지 않는다)
  財가 지나쳐 도리어 財를 잃을 우려가 있다.
  가옥ㆍ토지사가 순조롭지 못해 심신이 동요한다.
  조정해줄 사람도 능력을 잃었다. 부모의 신변에 근심이 기한다.
  `,`■ 옛것을 버리고 새롭게
  고치고져 한다.
  현임 관리나 공직희망자는 아주 吉하다. 
  원대한 뜻은 달성되는 기쁨이 있게 된다.
  十月 임신사가 있다.
  다인 다사건을 묶는 일이다.
  `,`■ 아래를 이롭게 하고
  위를 경시한다.
  지금까지의 일과 직위등의 인연이 끝난다. 그대로 따르라. 
  내게 활동의 기운이 새롭게 도래하여 추천ㆍ천거를 받게 되어 재기ㆍ성공한다.
  `,`■ 금전문서의 손실이 있다.
  재수가 왕성한 운세이다.
  그러나 일의 반복이 있고
  왕래가 평상을 벗어나므로 풍파가 있다.
  
  ⊖내부에 괴이한 일이 있다. ⊕풍파가 밖에서 벌어진다.
  `,`■ 자충수적 우환이 있다.
  가정에 ⊕⊖저주원심
  부정이 들어 많은 사람들
  로부터 업신여김을 받는다. 피차 의심이 있고 이별수도 있다. 
  유명무실, 빈소리 뿐이다. 귀신과 부정해소로 타인의 조력을 받게 된다.
  十月 임신사가 있다.
  `,`■ 신불의 노를 받고 있다.
  치성해야 한다.
  공직吉, 일반인 凶,
  교환ㆍ매매ㆍ무역사는 유익하다.
  지금까지 일을 개신ㆍ개혁시키려 한다. 
  그러나 꿀 속에 독이 든 사례가 가정에서 있게 된다.
  十月 임신사가 있다.
  `,`■ 토지ㆍ가옥사로 소송이
  붙었다.
  구재의 목적은 달성되나 그 돈으로 인해 재해를 당하는 것을 예방하여야 한다. 
  신불에 기도하면 흉을 제거하고 吉이 된다.
  `,`■ 가택 이동이나 생업의
  변동수가 있다.
  소모가 되고 불리하다.
  외교적 판매사는 이익을 얻으나 도중 차질을 빗는다. 
  만사에 중용을 잃고 실적이 없다. 
  위방불입하라!
  `,`■ 一男二女삼각관계가 있고
  모든 일에 결함이 있다.
  상대를 공격해도 적중이 안된다.
  공직자는 좋으나 일반인은 현상유지에 만족해야할 운세이다. 전진하면 재해가 따른다.
  十月 임신사가 있다.`]
  const SIBO2_1 = [`■財ㆍ女,신변외사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  求財․女 신속하다.
  `,`■직ㆍ녹,신변손해사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.낭비가 많고 재·녀 상실하고 형제의 재액이 있다..
  `,`■財ㆍ女,신변파재사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  허언이고 무실한 상이다.
  `,`■財ㆍ女,신변파재사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  상호 허를 찌르는 토지 관송사가 있다.
  `,`■관ㆍ부,內外화합사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  병재·관재 예방이 필요하다.
  `,`■자손ㆍ극부가정상쟁사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■자손ㆍ극부가택내사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■허위ㆍ손모신변외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.손재·녀하고 형제의 재액이 있다.
  `,`■관ㆍ부,內外결속사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  병재·관재 예방이 필요하다.
  `,`■財ㆍ女,모망외사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■官･夫,가택내사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다.
  이전 이사를 한다.
  `,`■財ㆍ女,자손화합사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/042/row-1-column-1.png`,
    `/static/images/sagwa/042/row-1-column-2.png`,
    `/static/images/sagwa/042/row-1-column-3.png`,
    `/static/images/sagwa/042/row-1-column-4.png`,
    `/static/images/sagwa/042/row-1-column-5.png`,
    `/static/images/sagwa/042/row-1-column-6.png`,
    `/static/images/sagwa/042/row-1-column-7.png`,
    `/static/images/sagwa/042/row-1-column-8.png`,
    `/static/images/sagwa/042/row-1-column-9.png`,
    `/static/images/sagwa/042/row-1-column-10.png`,
    `/static/images/sagwa/042/row-1-column-11.png`,
    `/static/images/sagwa/042/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 제1과 진(辰)을 초전으로 하며 천지신(天地神)이 엎드려 움직이지 않으므로 복음과(伏吟課)라 한다. 초전의 진은 순수(旬首)에 해당하므로 육의(六儀)라 이름하며, 일간의 두 과는 진(辰)의 재동으로 된다. 진(辰)의 천강이 일간의 음양신으로 되면 심신(心身)의 동요이다. 진(辰)의 글자가 겹치면 움직여서 안전할 수 있다.
    복음과는 천지에 엎드려 아직 발동하지 않은 형상이나. 안정하는 것이 좋고 함부로 움직이지 않는 것이 안전하다. 그러나 진(辰)의 글자가 초전 및 일간상신에 가해지는 것은 동조(動兆)로 된다. 망동하지 말고 때를 기다려서 점차 냉정한 태도로 움직여야 한다. 말전 신금(申金)은 일간을 극하나 일지 음양신의 사(巳)는 귀(鬼)를 제어하여 재해를 면한다.
    백법에 ｢주객이 정의가 투합되지 않는 것은 지형상에 있기 때문이다｣라고 하였다. 귀(鬼)와 천을(天乙)이 동병(同倂)하는 것은 천을신기격(天乙神祗格)이다.
    비전에는 ｢일간일지가 교차하여 탈기(脫氣)로 되는 것은 피차가 서로 물건을 보내는 뜻이 있다｣고 하였다.
    순수(旬首)가 초전으로 되는 것은 순의(旬儀)라 하여 간난한 가운데 남의 도움을 받거나 제휴(提携)하는 일이 있다.
    일귀는 역행하여 3전이 구(勾)․합(合)․귀(貴)이고, 야귀는 순행하여 3전은 구(勾)․청(靑)․상(常)이다.`
    ,
    // 2
    `  이 과는 4과 중 제4과의 묘진(卯辰)이 일상이 일하를 극하여 원수과(元首課)이며 퇴여불비격(退茹不備格)이다. 제4과가 초전으로 되는 것은 맥월격(驀越格)이다. 4과3전이 모두 인묘(寅卯)의 공망에 해당되고 일간의 녹신에 해당되지만 공망이 되어 이익이 없으며, 손해없이 도모한다 해도 이룩되지 않는다. 그러나 퇴여공망(退茹空亡)은 퇴여로 변화하므로 천천히 호전된다. 모든 일을 새롭게 계획하면 좋고, 따라서 때를 기다려 활동에 옮겨야 한다.
    백법에 ｢개공망(皆空亡)은 길흉이 개휴(皆休)한다｣고 하였다. 
    비전에는 ｢초전의 건록이 공망하고 말전이 동호(蛇虎)를 만나는 것은 전후에서 핍박하여 유퇴(遊退)하기 어렵다｣고 하였다. 또 이르기를 ｢녹신이 공망하고 과전에 비견(比肩)이 많으며 부모의 신이 없이 구진(勾陳)이 택상에 있는 것은 동기(同期)가 상쟁(相爭)하여 부모가 집을 떠난다｣고 하였다.
    일귀는 역행하여 3전은 청(靑)․공(空)․백(白)이고, 야귀는 순행하며 3전은 합(合)․주(朱)․사(蛇)이다.`
    ,
    // 3
    `  이 과는 4과 중 제4과의 축묘(丑卯)가 1하에서 1상을 적하여 중심역간전과(重審逆間傳課)로 된다. 3전의 축해유(丑亥酉)는 극음(極陰)의 격으로서 1․3과의 인묘(寅卯)는 공망에 해당하고 자축(子丑)은 공망에 앉았다. 일간일지가 모두 공망하면 모든 일에서 종적이 없으며 초전은 순미(旬尾)의 축(丑)에 해당하여 폐구격(閉口格)이다. 질병을 정단하면 음식을 먹을 수 없다. 
    일간의 재는 말전의 귀효(鬼爻)를 도와 흉이 무거우며, 역간전(逆間傳)이면서 극음(極陰)으로 되니 모든 일에서 순조롭지 못하다. 의혹이 생겨 전진하거나 후퇴하거나 배반하기 쉬우며, 초전이 공망하여 무슨 일이나 공허한 말뿐이다.
    백법에 ｢일지상신이 녹신으로 되는 것은 권섭부정(權攝不正)한 일이 있다｣고 하였다. 공망이 중한 것은 모든 것을 추구하지 말아야 한다.
    비전에는 ｢축해유(丑亥酉)는 극음으로서 달(月)이 서산에 진다고 말하며, 3전은 야간음기(夜間陰氣)로서 광명을 보지 못한다｣고 하였다. 
    일귀는 역행하여 3전은 백(白)․현(玄)․후(后)이며, 야귀는 순행하여 3전이 사(蛇)․후(后)․현(玄)이다.`
    ,
    // 4
    `  이 과는 제1과의 축을(丑乙)에서 1하가 1상을 적(賊)하여 중심가색격(重審稼穡格)으로 된다. 순미(旬尾)의 축(丑)이 초전으로 되는 것은 폐구격(閉口格)이다. 일간의 음양신을 3전으로 하는 것은 모두가 재효로 되니, 재가 지나치게 왕성하여 도리어 재력을 잃을 우려가 있다. 
    폐구격은 언어를 삼가는 것이 필요하다. 내정자(來情者)는 아무 일에도 입을 다물고 말하지 않는다. 가색 중심과는 순조롭지 못한 형상이고 제4과는 역마로 된다. 말전은 순정(旬丁)으로 되어 유자격(遊子格)이니 심신이 동요된다.
    백법에 ｢3전에 재가 태왕(太旺)한 것은 도리어 재가 없고, 인수(印綬)의 존친을 상하므로 윗사람의 질병 관점은 흉하고 위험하다｣고 하였다. 3전이 모두 토(土)인 것은 가색격(稼欌格)이며, 토(土)의 성미는 침체하고, 중정(中正)으로써 임무는 중하고 일은 광대하다. 형충(刑冲)이 있는 것을 즐기고 개묘(開墓) 개발한다. 폐구과(閉口課)의 病은 말을 할 수 없고 또 식욕이 없다.
    일귀는 순행하여 3전은 청(靑)․주(朱)․후(后)이고, 야귀는 3전이 사(蛇)․음(陰)․백(白)이다. 3전에 백호․사신(蛇神)이 들면 놀랍고 두려운 일이 많다.`
    ,
    // 5
    `  이 과는 4과에 상하의 극이 없다. 요극은 신축유(申丑酉)가 있으나 천신(天神)에서의 극은 신(申) 및 유(酉)이다. 음일이니 유(酉)의 극을 사용하여 초전으로 하며 요극종혁과(遙剋從革課)이다. 
    일간에서 3합금국(三合金局)은 관귀로 되나, 일간상신 자수(子水)가 살(殺)을 인(印)으로 생화하여 흉을 변화시키니 해롭지 않고 도리어 길하게 된다.
    요극은 힘이 없으므로 화복은 모두 경하고, 종혁은 정(鼎)의 격이니 옛것을 버리고 새롭게 고치면 길하다.
    백법에 ｢중귀(衆鬼)라 해도 생화하여 두려울 것이 없으며, 3전이 관국(官局)으로 되어 현임․신임할 것 없이 관직은 모두 길하다｣고 하였다.
    일귀는 순행하여 3전이 사(蛇)․현(玄)․청(靑)으로서 사(蛇)가 용으로 화하니 원대한 뜻은 성공한다. 야귀는 3전이 현(玄)․청(靑)․사(蛇)이다.`
    ,
    // 6
    `  이 과는 4과 중에 상하의 극이 있고 하에서 상을 극한다. 오해(午亥)를 사용하여 초전으로 하니 중심과(重審課)로 된다. 초전은 목생화(木生火)로 탈기되고 말전의 신(申)은 관효(官爻)이나 오(午)에서 제극하므로 흉해를 이루지 않는다.
    중심과는 아래를 이롭게 하고 위를 이롭게 하지 않는다. 만사에서 선수를 쓰는 것은 불리하고 뒤에 나가면 이익이다. 신(申)의 관성이 제어되므로 관직․구직은 모두 좋지 못하다. 일지의 역마가 간상에 승하는 것은 내몸에 움직임이 일어난다.
    일간은 일간상신 및 일지상신으로부터 생을 받으니 정단사는 모두 양호하다.
    백법에 ｢3전이 체생하여 타인의 천거를 받는다｣고 하였다.,
    일귀는 순행하여 3전은 음(陰)․청(靑)․귀(貴)이고, 야귀는 역행하여 3전이 공(空)․후(后)․구(勾)이다. 제3과 자(子)는 사(巳)에 가해져서 사(死)가 되므로 병을 정단하면 죽을 우려가 있다.`
    ,
    // 7
    `  이 과는 천지반 상하가 대충되므로 반음과로 되며, 1하가 1상을 극하는 제4과 사해(巳亥)를 사용하여 초전으로 하니 반음원태격(返吟元胎格)으로 된다. 
    일간상신의 음양이 모두 재효로 된다. 그리고 초․말의 사(巳)의 탈기는 재를 생하므로 재가 왕세로 된다. 반음과는 반복왕래가 평상스럽지 못하여 풍파의 상태이다.
    백법에 ｢천장(天將)이 내전(內戰)하니 모사는 위태롭다｣고 했다.
    비전에는 ｢3전이 동해사(巳亥巳)인 것은 항상 개동(改動)하는 것을 주재한다｣고 했다. 천장이 천신을 극하는 것은 외전(外戰)이라 하여 재해는 밖으로부터 온다. 천신에서 천장을 극하는 것은 내전(內戰)이라 하여 재화가 안에서 생긴다.
    일귀는 순행하여 3전은 현(玄)․합(合)․현(玄)이고, 야귀는 역행하여 3전이 백(白)․사(蛇)․백(白)이다. 3전에 사호(蛇虎)가 다 있으면 괴이한 일이 있다. 질병과 소송(訴訟)의 정단을 가장 꺼린다.`
    ,
    // 8
    `  이 과는 4과 중 3과에 극이 있고 하에서 상을 적한다. 인유(寅酉)를 초전으로 하여 중심과(重審課)이다. 일간상신에 귀살이 승하니 내몸에 재액이 있고, 3전이 모두 협극(夾剋)하여 모두 자유를 얻지 못한다. 초전의 공망은 과숙(寡宿)이며, 중전의 미(未)는 정신(丁神)이 앉아 3전이 체극(遞剋)하니 뭇사람들에게서 업신여김을 받으며, 간지상신이 육해로 되니 피차간에 의심을 산다.
    초전의 공망은 과숙으로 되어 이별을 주관하며, 초전 인(寅)에 천공이 승하면 공중공(空中空)이라 하여 유명무실하거나 공론공화(空論空話)가 많다. 외사(外事)의 정단에서는 도리어 길하게 된다.
    백법에 ｢3전이 체극하면 뭇사람들의 업신여김을 받는다｣고 하였다. 교차상극하여 부부무음(夫婦蕪淫)이라 부르는데 서로 사정을 품는다.
    비전에는 ｢초․중전이 공망하고 말전의 자(子)에 귀신이 승하여 일간을 생하므로 먼저는 암매(暗昧)하고 후에 타인의 조력을 얻어서 이득을 본다｣고 하였다.
    체극은 사물이 순조롭지 못하여 대단히 흉조이지만, 다행히 공망하여 흉을 보고도 흉을 입지 않는다. 일간상신 유(酉)의 귀효(鬼爻)가 말전 자수(子水)에 생화하는 것은 흉하다 할지라도 난을 피하여 재화를 막을 수 있다.
    일귀는 순행하여 3전은 공(空)․후(后)․구(勾)이며, 야귀는 역행하여 3전이 음(陰)․청(靑)․귀(貴)이다.`
    ,
    // 9
    `  이 과는 제1과․제3과에 상하의 극이 있으며, 하에서 상을 적(賊)하는 유사(酉巳)를 사용하여 초전으로 하니 중심종혁과(重審從革課)이다.
    일간일지는 신사(申巳)와 진유(辰酉)의 교차지합(交車支合)이다. 교환이나 무역사업은 유익하고 상담사는 타협된다. 3합금국(三合金局)은 일간 을(乙)의 중귀(衆鬼)로 되나 일(日)의 음양은 신자진(申子辰) 3합수국으로 금국에서 생하니 후에 만사가 호전된다.
    일간상신으로 정관(正官) 귀신이 승하니 즉 귀덕(貴德)이 신(身)에 임하므로 재화를 흩어지게 하여 관공․구직에 길하다. 종혁(從革)은 헌 것을 버리고 새 것을 쫓으니 고쳐하면 길하다. 또 귀신이 일간의 음양에 임하여 흉을 변화시켜 길조를 얻으며 병(病)은 구신(救神)의 작용으로 신불(神佛)에 기도드리면 길하다.
    백법에 ｢교차상합은 교관(交關)에 이롭다｣고 하였다. 합중살(合中殺)을 보는 것은 꿀 속에 비소를 감추고 있으며 간귀천을(干鬼天乙)이 승하는 것은 신기격(神祗格)이다. 후(后)․합(合)이 있으니 점혼은 자유 결혼의 암시이다. 
      일귀는 순행하며 3전은 사(蛇)․청(靑)․현(玄)이고, 야귀는 역행하여 3전이 합(合)․후(后)․백(白)이다. 3전의 3합은 한 사람의 일이 아니다.`
    ,
    // 10
    `  이 과는 제1과․제3과에서 2하가 2상을 극한다. 음일이니 음의 극(剋)인 미진(未辰)을 사용하여 초전으로 하니 지일비용가색격(知一比用稼穡格)을 구성한다. 가색격에 정신이 승하니 유자격(遊子格)으로 된다.
    3전이 모두 재성(財星)으로 되어 지상의 귀(鬼)를 도우므로 택 중의 재에 의하여 화환(禍患)이 발생한다.
    일간상신이 재동으로 되고 3전이 모두 재신이니 구재의 목적은 달성되지만 분수를 지켜야 한다. 과욕할 때는 재를 얻어 화환를 부르게 된다. 지일(知一)은 은혜 가운데 해(害)를 생하고 신용 가운데 의심을 초래한다. 타인과 공명 화합하여 일을 도모하면 길과를 얻는다.
    일간상신에 묘정(墓丁)이 엎드려 흉해가 발생할 징조이다. 또 사물이 정체되지만 말전의 축(丑)에서 충하여 흉을 제거하니 길로 화한다. 즉 개묘(開墓) 또는 개고(開庫)라 불러 묘가 충을 즐기는 까닭이다.
    백법에 ｢간묘병관(干墓倂關)은 인택이 퇴폐되고 전순재(傳純財)가 귀(鬼)로 화하는 것은 구재를 보류해야 한다｣고 하였다. 개재(皆財)는 존친(尊親)의 병관을 가장 싫어한다.
    일귀는 역행하며 3전은 사(蛇)․음(陰)․백(白)이고, 순행하여 3전이 청(靑)․주(朱)․후(后)이다.`
    ,
    // 11
    `  이 과는 4과 중에서 제2과가 신오(申午)로 1하가 1상을 극하여 중심간전과(重審間傳格)으로 된다. 신술자(申戌子)는 섭삼연격(涉三淵格)이므로 초전 신(申)의 관귀효(官鬼爻)이나 오(午)의 제지(制支)가 있어 해(害)를 이루지 못한다. 
    일간과  일간상신은 일지와 일지상신에 탈기되어 피차간의 대담에서 내가 소모되고 불리하다. 일간은 상신에 설기하고 일지는 상신 미(未)에 화생토(火生土)로 설기한다. 따라서 인택이 모두 소모된다. 일지상신이 일간의 재로 되고 정신(丁神)이 앉으므로 외교적 판매에는 재리를 얻는다. 그러나 분재(墳財)라 불러 도중에 고장이 생기는 일이 있다.
    백법에 ｢지(支)가 분재(墳財)로 되어 여행 중에 정체한다｣고 하엿다. 탈상탈(脫上脫)을 만나는 것은 허사(虛詐)를 방비해야 한다. 강색귀호(罡塞鬼戶)는 모사에 적합하다. 
    비전에 ｢일간상신이 오(午)에 탈하고 천반(天盤) 신(申)에 구진이 승하는 것은 오화(午火)에서 토장(土將)에 탈한다｣고 하였다. 이와 같은 것은 탈상탈을 만난다고 하여 만사에서 중용(中庸)을 잃고 또 실적이 없다.
    섭삼연격(涉三淵格)은 위험한 곳에 가까이 하지 말아야 한다. 
    일귀는 역행하며 3전은 귀․음․상이고, 야귀는 3전이 구․주․귀이다. 귀인이 술(戌)에 임하는 것은 옥(獄)에 든다고 하여 지(地)를 얻지 못한다. 년명(年命)에 귀신이 승하면 방해가 없다.`
    ,
    // 12
    `  이 과는 4과 중에 상하의 극이 없고 일간에서 제4과상신을 사극(斜剋)하여 요극탄사과(遙剋彈射課)로 되며 미오(未午)를 초전으로 한다. 3전 미신유(未申酉)는 진여격(進茹格)이며 4과이나 3과뿐으로서 불비격(不備格)으로 되는데, 이것은 맥월격(驀越格)에 해당한다.
    초전의 재는 신유(申酉)의 귀를 생하여 재가 귀로 화하나 사오(巳午)의 화(火)신이 살을 제어하여 해를 보아도 피해를 받지 않는다. 
    요극과(遙剋課)는 우려되고 의심스러운 과이나 모두 경미하다. 불비격은 모든 일에서 결함을 면치 못한다. 신유(申酉)의 관귀가 왕하여 사관이나 현임 관공직에 있는 사람은 대단한 길조이며, 보통사람은 현상 유지에 힘써야 한다. 전진하면 재해를 초래할 위험이 있다.
    백법에 ｢전재(傳財)가 귀(鬼)로 화하면 망녕되게 구재를 바라지 말라｣고 하였다. 앞뒤에서 핍박하는 것은 전진도 퇴각도 모두 어렵다.
    비전에는 ｢이 관의 불비는 음이 왕성하고 양이 쇠하므로 양의 불비 및 불완전이라고 알아야 한다｣고 하였다.
    일귀는 역행하며 3전은 사(蛇)․귀(貴)․후(后)이며, 야귀는 순행하여 3전이 백(白)․상(常)․현(玄)이다. 지반의 전지(前支)가 천반으로 되어 나망격(羅網格)이니 가정에 고심할 일이 있다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
