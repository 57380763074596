import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Header.css";
import Firebase from 'firebase';


const user = Firebase.auth().currentUser;



const Header = () => {
  const [activeTab, setActiveTab] = useState("Home");
  const location = useLocation();
  const [search, setSearch] = useState("");

  const history = useHistory();

  const [auth, setAuth] = useState(false)

  const [textEmail, setTextEmail] = useState("")
  const [textPassword, setTextPassword] = useState("")
  

  Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // var uid = user.uid;

      setAuth(true)
      // ...
    } else {
      // User is signed out
      setAuth(false);
      // ...
    }
  });


  useEffect(() => {
    if (location.pathname === "/admin") {
      setActiveTab("Home");
    } else if (location.pathname === "/admin/add") {
      setActiveTab("AddContact");
    } else if (location.pathname === "/admin/about") {
      setActiveTab("About");
    }
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/search?name=${search}`);
    setSearch("");
  };
  return (
    <div className="header">
      <p className="logo">마이플러스 apk 고객 목록</p>
      <div className="header-right">
        &nbsp;
        {/* <form onSubmit={handleSubmit} style={{ display: "inline" }}>
          <input
            type="text"
            className="inputField"
            placeholder="Search Name ..."
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </form> */}
        <Link to="/admin">
          <p
            className={`${activeTab === "Home" ? "active" : ""}`}
            onClick={() => setActiveTab("Home")}
          >
            리스트 홈
          </p>
        </Link>
        <Link to="/admin/add">
          <p
            className={`${activeTab === "AddContact" ? "active" : ""}`}
            onClick={() => setActiveTab("AddContact")}
          >
            데이터 추가
          </p>
        </Link>

        { auth == true &&
          <p
            className={``}
            onClick={() => {
                Firebase.auth().signOut()
                window.location.href = "/admin";
            }}
          >
            로그아웃
          </p>
        }
      </div>
    </div>
  );
};

export default Header;
