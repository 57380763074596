
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,8,4,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,9,3,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,10,2,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,11,1,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,12,12,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,11,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,2,10,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,3,9,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`설득`,4,8,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,5,7,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,6,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,5,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위불안사`,`小`,``,`고위연회`,``],
    [`극부`,`남편갈등사`,`小`,`9`,`초대열석`,``],
    [`자식`,`자식관련사`,`大`,`소외기`,`외사단합`,`가정막힘`],
    [` 상인외봉(常人畏逢)`,`일반인은 두려운 일을 만나고 관직자는 좋은 일이 있는 상`,`大`,``,``,`上喜하승`],
    [`획적실세(獲賊失勢)`,`도적이 실세하여 포획하는 상`,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`8`,`불변근신`,`도적실세`],
    [`모친`,`모친 관련사`,`小`,`침체기`,`외사침체`,`가정화합`],
    [`불변근신(不辨謹愼)`,`말을 쉬고 근신이 좋은 상`,`小`,``,``,``],
    [`유시무종(有始無終)`,`시작은 있으나 끝이 없는 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,`구설詞訟`,`음사폐호`],
    [`상업`,`상업 관련사`,`小`,`7`,`외사동요`,`가택안녕`],
    [`처 `,`처 관련사`,`小`,`단절기`,`부부이심`,`출행이동`],
    [`취재생화(取財生禍)`,`바른 재물이 아닌 것을 취하면 화가 닥치는 상`,`大`,``,`출행이동`,``],
    [`불변근신(不辨謹愼)`,`장차를 위해 말을 쉬고 근신이 좋은 상`,`小`,``,``,``],
    [`결속`,`음3월 재원 바탕을 크게 이루나 명예직위남편 훼탈됨`,`大`,``,``,``],
    [`상업`,`상업 관련사`,`小`,`6`,`도적소실`,`충산동요`],
    [`여인`,`여인 관련사`,`小`,`존재기`,`외사손실`,`가정반목`],
    [`관귀기기(官貴忌氣)`,`관직자의 승진영전이 불가한 상 `,`大`,``,``,`무례사안`],
    [`엄목지상(掩目之象)`,`눈을 가려진 격의 상-속임수 관련`,`小`,``,``,``],
    [`후원`,` 후원조력 관련사`,`小`,``,``,``],
    [`매사 불성허성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`5`,`내외단합`,`사기득재`],
    [`매사 영속성없음`,`후원조력문서부모사는 선천적 기복재해를 겪음`,`小`,`배양기`,`사행불량`,`면모훼손`],
    [`여이상생(與人相生)`,`서로 도와서 편안해지는 상`,`小`,``,``,``],
    [`불검초죄(不檢招罪)`,`약속한 어기고 비밀을 누설하여 스스로 자초한 죄의 상`,`大`,``,``,``],
    [`송쟁`,`외사 애증화합파재극처급속송쟁사-상해실종사 우려`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`4`,`실업한가`,`앙화소멸`],
    [`매사 영속성없음`,`관직직업직위남편남자사는 선천적 기복재해를 겪음`,`小`,`개시기`,`외사급속`,`내사급속`],
    [`병송구흉(病訟俱凶)`,`병과 송사가 모두 흉한 상-일반인은 재(財)를 득함`,`小`,``,`사有`,`사有`],
    [`우유한가(優遊閑暇)`,`머리를 다듬는 한가로움의 상-직업이 없는 격`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,`투서헌책`,`권한受여`],
    [`직업`,`직업지위 관련사`,`大`,`3`,`고위면접`,`부족실소`],
    [`남편`,` 부부 상쟁사`,`小`,`학습기`,`외사혼매`,`부부상쟁`],
    [`범모혼매(凡謀昏昧)`,`모든 계획 도모사가 어두워 분간 할 수 없는 상`,`小`,``,``,``],
    [`미초희경(微招喜慶)`,`권리사가 기대치에 못미쳐 기쁨이 희미한 상`,`小`,``,``,``],
    [`훼탈`,`음3월 재원 바탕을 크게 이루나 명예직위남편 훼탈함`,`小`,``,``,``],
    [`손실`,`내부가정 손해가출사`,`大`,`2`,`掩目지상`,`도적소실`],
    [`모친`,`모친 관련사`,`小`,`면모기`,`후원조력`,`손해가출`],
    [`상인가이(常人可易)`,`일반인은 희망사가 좋고 관직자는 안 좋은 상`,`小`,``,``,``],
    [`도적소실(盜賊消失)`,`도적이 길을 잃어 침범을 못하는 상`,`大`,``,``,``],
    [`명예`,` 수상(受賞)사`,`小`,``,`불의투서`,``],
    [`막힘`,` 외사 외교막힘사`,`小`,`1`,`불용失財`,`사행불량`],
    [`자산`,`자산 관련사`,`大`,`절정기`,`외교막힘`,`결속단합`],
    [`상인희봉(常人喜逢)`,`일반인은 기쁨을 만나고 관직자는 매우 좋지 않은 상`,`小`,``,``,``],
    [`앙화소멸(殃禍消滅)`,`재앙이 사라진 상`,`大`,``,``,``],
    [`자매`,`자매 관련사`,`小`,``,``,`고위안좌`],
    [`친구`,`친구 관련사`,`小`,`12`,`도적失勢`,`多衆이익`],
    [`색정`,`외남 내녀 동시 색정사 有`,`大`,`과시기`,`색정음사`,`강구시행`],
    [`택사흠녕(宅舍欠寧)`,`가정이 온전히 편안한 상`,`小`,``,`비용과출`,`색정음사`],
    [`위례득죄(違禮得罪)`,`예의가 극도로 문란하여 죄에 이르게 되는 상`,`大`,``,``,`파재극처`],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`11`,`간음무도`,``],
    [`부친`,`부친 관련사`,`小`,`쇠퇴기`,`이전이동`,`관吉평不`],
    [`공조기해(恐遭其害)`,`내려칠 칼이 앞에 놓인 공포와 두려움을 피해야 하는 상`,`小`,``,``,``],
    [`이어경영(利於經營)`,`고위에 안좌하여 두루 이익 되는 경영을 강구하는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,`소식기대`],
    [`극부`,`남편 갈등사 `,`小`,`10`,`실업한가`,`와도나쁨`],
    [`자녀`,`자녀 관련사`,`大`,`침해기`,`직위불안`,`남편갈등`],
    [`사식탈모(詞息脫耗)`,`제사를 이을 자식을 잃게 되는 상`,`大`,``,``,``],
    [`우유한가(優遊閑暇)`,`면모를 추스릴 필요가 없는 한가로운 상-실업 격`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,``,"직업직위불안남편갈등자식사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`후원문서모친사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`금전사업처사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업여인사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,"관사직업부부상쟁사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`후원조력모친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`명예직녹자산권리사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`자매친구색정조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`후원문서부친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,``,"직업직위불안남편갈등자녀사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 명분을 앞세워 먼저 나서서 속결해야만 유리함`,0,0,`정정당당하게 속결하지 않고 좌고우면 하거나 뒤로 미루면 오히려 불리한 결과를 초래하게 될 人`,0,0,`일이 역순으로 진행됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`웃음속에 칼이 들었으므로 타인의 호의를 쉽게 받아들이지 말아야 함**옛일로 되돌아가는 입장**전화위복적 사안을 맞음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`선택기로불안**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`동료 동업관계로 무고한 사안에 선택을 못하고 불안기로에 있으나 제일 친하고 가까히 거주하는 자와 의논하면 해결되는 人`,0,0,`기우적 전화위복사가 있게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 지체시키거나 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하며 일을 지연시키면 반드시 결과가 유리해지는 人`,0,0,`여러 일을 하나로 묶는 결속단합사 있음-음3월에 완성되나 재물은 좋으나 부모 근심이 발생함**여건이 부족함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`모든 일이 중도에 지체되거나 좌절됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화가 밖에서 오니 신중해야 함`,0,0,`모든 일이 반복무상한 人`,0,0,`신규사가 잇음**금전사로 출행하나 심중이 서로 달라 재난이 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 지체시키거나 뒤로 미루면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 순응하며 일을 지연시키면 반드시 결과가 유리해지는 人`,0,0,`승진영전 등 권리사가 초반 중반에 실패함**가정금슬부조함-색정사관련**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 명분을 앞세워 먼저 나서서 속결해야만 유리함`,0,0,`정정당당하게 속결하지 않고 좌고우면 하거나 뒤로 미루면 오히려 불리한 결과를 초래하게 될 人`,0,0,`여러 일을 하나로 묶는 결속단합사 있음-음3월에 완성되나 재물은 좋으나 부모 근심이 발생함**여건이 부족함**깊은 산속으로 숨고싶은 심정임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 전의가 별로 없고 피해도 없으며 있다해도 가벼움`,0,0,`옳지않은 일이 드러나 천둥에 놀란 듯한 심정이나 언행을 삼가하고 근신하면 놀라는 일이 자연히 사라지는 人`,0,0,`풍운불측의 상**기이한 전화위복사가 있게됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 일을 하나로 묶는 결속사가 있음-완성해도 손실임**깊은 산속으로 숨고싶은 심정**일이 반복되고 결과가 없으며 점점 어두워짐**독신고독각방주말부부생이사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`선택기로불안**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`동료 동업관계로 무고한 사안에 선택을 못하고 불안기로에 있으나 제일 친하고 가까히 거주하는 자와 의논하면 해결되는 人`,0,0,`일이 순서적으로 진행됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 공격의 뜻이 있어도 움직이지는 않음`,0,0,`옛것을 지키고 새 기회를 기다리며 신음하는 人`,0,0,`신규사가 있음**송쟁사가 발생함-고시 준비생에게 많음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성-무력`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성-무력`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동타발동사겹침`,`기사`,`구사`,`외내사겹침`,`아이면타표면사겹침`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아타동시발동사`,`기사`,`구사`,`외내동시사`,`아이면타표면사겹침`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이발동사-후회사겹침`,`미사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`소-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월`,2,2,4,`음11월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음4월-연기 또는 파혼우려`,2,2,4,`으10월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음1월`,3,1,4,`음7월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,2,1,3,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음9월`,2,2,4,`음10월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,3,1,4,`음3월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월-연기우려`,2,3,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무를 순서적으로 하지 않고 퇴행시켜 마지막에는 손실을 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여  업무 전반에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하며배경이 없어 중반에 자금문제로 내부 외부와 충돌을 일으키고 업무 전반에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여  여러 일을 일관시키는 결속단합을 꾀하나 결과적으로 큰 손실을 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무는 중반 종반에 걸쳐 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무 내내 동요사가 발생하고 특히 자금 문제가 위기를 겪게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성품이 훌륭하나 권리사의 결함으로 초 중반을 허무하게 보내고 종반 음4월에 이르러 권리사를 완성시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있고 여러 사안을 하나로 뭈는 결속단합을 꾀하나 결과적으로 큰 손실을 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무의 효과가 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 얻으나 초반 중반이 허무하고 종반에 이르러 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 중도에 금전의 충돌이 발생하고 종반이 허무하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 이심동요사가 발생하여 송쟁하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선난후난`,`進向`,`원`,`留`,`무난`,`서남`,``,`정북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,`불이행`,`선이후난`,`退背`,`  원`,`  去`,`주인가해`,` 정서`,``,`동북`],
    [`7.8월`,``,`본인반대`,``,`빠르게`,``,``,``,``,``,``,`세밀심사`],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,`함`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,`주인조력`,``,``,``],
    [`7.8월`,``,`끌면제압`,`불이행`,`선이후난`,`퇴배`,`  근`,`  去`,`이후파재`,`북서`,``,`정서`],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`의사有`,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인무력`,`退背`,`  근`,`  去`,`주인조력`,`정북`,`북서`,``],
    [`10.11`,` 集`,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선이 후합`,`進向`,`원근왕래`,`  留`,`주인가해`,`북북서`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`불이행`,`선난후합`,`進向`,`  원`,`  留`,`세입가해`,`남남서`,`정북`,``],
    [`10.11`,`  集`,`발현안됨`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`서남`,`정남`,``],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`사안무력`,`退背`,`  근`,`  去`,`주인무력`,`정서`,`남남서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선攻후合`,`退背`,`  원`,`  去`,`세입조력`,`서북`,`서남`,``],
    [`10.11`,``,``,``,``,``,``,``,`이후반목`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`불이행`,`선공후난`,`退背`,`  원`,`  去`,`세입조력`,`정남`,`정서`,``],
    [`10.11`,``,`측근제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선이후이`,`進向`,`  근`,`  留`,`세입가해`,`남남서`,`서북`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대있음`,`불이행`,`선난후합`,`進向`,`  근`,`  留`,`무난`,`서남`,`북북서`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`두성부정`,`기도위배`,`육친`,0,``],
    [`질병`,`도로귀`,`교툥사망`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`두성부정`,`기도위배`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`전흉사귀`,`혈광사`,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`제례귀`,`제례위범`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`제례귀`,`제례위범`,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`두성부정`,`기도위배`,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`두성부정`,`기도위배`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,`정북`,`없음`],
    [``,``,``,``,``,`묘향`,`정남`,``],
    [`생업`,``,``,``,``,`전경`,`강천`,``],
    [``,``,``,``,``,`묘속`,  `水`,``],
    [`가정`,``,``,``,``,`육친`,  `父`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,``,`상위 기쁨-하위 수도자 격`,``,`소식기다림-와도 나쁨`,``,`관직 영전도약사`,`외사 동요분산사**내외사출행이동사`,`고위안조-두루 좋은 이익경영사`,`가정무례반목사`,`내부가정불화사`,`매사 허성불성-세속허사자`,`음1,2,4,5월방문자는 종교진출하면 성공함`,`매사 영속성없음**우유한가사`,`종교진출하면자립함`,``,`말 말고 근신이 좋음`,`내부가정손해가출사`,`고위연회길사`,`명예직녹자산권리사**외사외교막힘`,``,`남녀종업원공모사有**외남내녀동시색정사`,`음사폐호 가택안녕-이동사`,`매사위역사`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`내부가정막힘**전화위복`,`전도**병재**빈궁`,``,`빈궁`,`재액해소`,``,`재액해소`,`빈궁**관재`,`위례득재사`,``,`외사송쟁파재실종종상해사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁`,``,``,``,``,`가정파재극처이동사`,`빈궁`,`침체경구사`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사`,`도적실세-잡음`,``,`상위 기쁨-하위 수도자 격`,``,`소식기다림-와도 나쁨`,``,`관직 영전도약사`,`외사 동요분산사**내외사출행이동사`,`고위안좌-두루 이익경영사`,`가정무례반목사`,`내부가정불화사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**우유한가사`,`종교진출하면자립함`,``,`말 말고 근신이 좋음`,`내부가정손해가출사`,`고위연회길사`,`명예직녹자산권리사**외교막힘`,``,`여종업원모사有**외남내녀동시색정사`,`음사폐호 가택안녕-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`재액해소`,``,`가정막힘**재액해소**전화위복`,`빈궁**병재`,``,`빈궁`,``,``,``,`빈궁`,`위례득재사`,``,`외사송쟁파재실종상해사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,``,`전도`,``,`가정파재극처이동사`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`외남내녀동시색정사`,`음사폐호 가택안녕-이동사`,`매사위역사`,`도적실세-잡음`,``,`상위 기쁨-하위 수도자 격`,``,`소식 기다림-와도 나쁨`,``,`관직 영전도약사`,`외사 동요분산사**내외사출행이동사`,`고위안좌-두루 이익경영사`,`가정무례반목사`,`내부가정불화사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**우유한가사`,`종교진출하면자립함`,``,`말 말고 근신이 좋음`,`내부가정손해가출사`,`고위연회길사`,`명예직녹자산권리사**외사막힘`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`가정파재극처이동사`,`전도**빈궁`,``,`빈궁`,`재액해소`,``,`가정막힘**재액해소**전화위복`,`빈궁**병재`,``,`빈궁`,``,``,`빈궁`,``,`위례득재사`,``,`외사송쟁파재실종상해사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,``,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`명예직녹자산권리사**외사막힘`,`동요충산사`,`외남내녀동시색정사`,`형사책벌사`,`눈이 가려진 상-상해불가함`,`약속비밀누설-득죄사`,``,`급문사 투서불용사-금전실재사`,``,`눈이 가려진 상-상해 불가`,``,`투서헌책-고위인 면담`,`외사 동요분산사**내외사출행이동사`,`우유한가사`,`가정무례반목사`,``,`매사 영속성없음**매사위역사`,`종교진출하면자립함`,`매사 영속성없음**권한 부족 희경사`,`종교진출하면자립함`,``,`재앙소멸사`,`내부가정손해가출사`,`사기득재사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`가정파재극처이동사`,`빈궁`,`재액해소`,``,`반궁`,``,`내부가정막힘**전화위복`,`빈궁`,`재액해소`,``,``,``,`빈궁**관재`,``,`도적실로-소실사`,`빈궁`,`외사송쟁파재실종상해사`,`전도**관재`,`음5월 화재주의**전화위복`,`전도**병재**관재`,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`사기득재사`,`내부가정손해가출사`,`명예직녹자산권리사**외사막힘`,`동요충산사`,`외남내녀동시색정사`,`형사책벌사`,`눈이 가려진 상-상해불가함`,`약속비밀누설-득죄사`,``,`급문사 투서헌책불용사-금전실재사`,``,`눈이 가려진 상-상해 불가`,``,`투서헌책-고위인 면담`,`외사 동요분산사**내외사출행이동사`,`우유한가사`,`가정무례반목사`,``,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사 여속성없음**권한 보족 희경사`,`종교진출하면자립함`,``,`재앙소멸사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,``,`가정파재극처이동사`,`빈궁**관재`,``,``,`전도**빈궁`,``,`내부가정막힘**전화위복`,`전도**빈궁**관재`,``,``,`재액해소`,`관재`,`재액해소`,`빈궁`,`도적실로-소실사`,`빈궁`,`외사송쟁파재실종상해사`,``,`음5월 화재주의**전화위복`,`빈궁**병재**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`재앙소멸사`,``,`사기득재사`,`내부가정손해가출사`,`동요충산사`,`명예직녹자산권리사**외사막힘`,`외남내녀동시색정사**가정파재극처이동사`,`형사책벌사`,`눈이 가려진 상-상해불가함`,`약속비밀누설-득죄사`,``,`급문사투서헌책불용사-금전실재사`,`내부가정막힘`,`눈이 가려진 상-상해 불가`,``,`투서헌책-고위인 면담`,`외사 동요분산사**내외사출행이동사`,`우유한가사`,`가정무례반목사`,``,`매사 영속성없음**매사위역사`,`종교진출하면자립함**도적실로-소실사`,`매사 영속성없음**외사송쟁파재실종상해사`,`종교진출하면자립함**권한 부족 희경사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`전도**병재**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사**재액해소`,`관재`,`동요불안분산사**재액해소`,`빈궁**관재`,`동요불안분산사`,``,`동요불안분산사`,`빈궁**관재`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`빈궁`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사 영속성없음**권한 부족-희경사`,`종교진출하면자립함`,`재앙소멸사`,``,`사기득재사`,`내부가정손해가출사`,`동요충산사`,`명예직녹자산권리사**외사막힘`,`외남내녀동시색정사**가정파재극처이동사`,`형사책벌사`,`눈이 가려진 상-상해불가`,`약속비밀누설-득죄사`,``,`급문사 투서헌책사-금전실재사`,``,`눈이 가려진 상-상해 불가`,``,`투서헌책-고위인 면담`,`외사 동요분산사**내외사출행이동사`,`우유한가사`,`가정무례반목사`,``,`매사 영속성없음**매사위역사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`매사송쟁파재실종상해사`,``,`음5월 화재주의**전화위복`,`관재**병재`,``,`빈궁`,`재액해소`,`전도**관재`,`재액해소`,`전도**빈궁`,``,``,`빈궁**관재`,``,`내부가정막힘**전화위복`,`빈궁`,``,``,``,``,`빈궁`,``,`도적실로-소실사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**권한 부족 희경사`,`종교진출하면자립함`,`재앙소멸사`,``,`사기득재사`,`내부가정손해가출사`,`동요충산사`,`명예직녹자산권리사**외사막힘`,`외남내녀동시색정사**가정파재극처이동사`,`형사책벌사`,`눈이 가려진 상-상해불가`,`약속비밀누설-득죄사`,``,`급문사 투서헌책사-금전실재사`,``,`눈이 가려진 상-상해 불가`,``,`투서헌책-고위인 면담`,`외사 동요분산사**내외사출행이동사`,`우유한가사`,`가정무례반목사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`도적실로-소식사`,`빈궁`,`외사송쟁파재실종상해사`,``,`음5월 화재주의**전화위복`,`빈궁**병재**관재`,``,`빈궁`,``,``,``,`빈궁`,``,``,`정도**빈궁`,``,`내부가정막힘**전화위복`,`전도**빈궁`,``,``,`재액해소`,`관재`,`재액해소`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,``,`가정무례반목사`,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음*권한 부족 희경사`,`종교진출하면자립함`,`재앙소멸사`,``,`사기득재사`,`내부가정손해가출사`,`동요충산사`,`명예직녹자산권리사**외사막힘`,`외남내녀동시색정사**가정파재극처이동사`,`형사책벌사`,`눈이 가려진 상-상해불가`,`약속비빌누설-득죄사`,``,`급문사 투서헌책불용사-금전실재사`,``,`눈이 가려진 상-상해 불가`,``,`투서헌책-고위인 면담`,`외사 동요분산사**내외사출행이동사`,`우우ㅠ한가사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`도적실로-소실사`,`빈궁`,`외사송쟁파재실종상해사`,``,`음5월 화재주의**전화위복`,`빈궁**관재*병재`,``,`빈궁`,``,`전도`,``,`전도**빈궁`,``,``,`재액해소`,`빈궁`,`내부가정막힘**재액해소**전화위복`,`빈궁`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사 동요분산사**내외사출행이동사`,`고위안좌-두루 이익경영사`,`내부가정불화사`,`가정무례반목사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,``,`말 말고 근신이 좋음`,`귀인연회길사`,`내부가정손해가출사`,`우유한가사`,`명예직녹자산권리사**외사막힘`,`외남내녀동시색정사**가정파재극처이동사`,`음사폐호 가택안녕-이동사`,`매사위역사`,`도적실세-잡음`,``,`상위 기쁨-하위 수도자 격`,``,`소식기다림-와도 나쁨`,``,`관직 영전도약사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`재액해소`,`빈궁**관재`,`위례득죄사`,``,`외사실종파재실종상해사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,``,``,``,`빈궁`,``,`빈궁`,``,`전도`,``,`내부가정막힘**전화위복`,`전도**병재`,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`관직 영전도약사`,`외사 동요분산사**내외사출행이동사`,`고위안좌-두루 이익경영사`,`내부가정불화사`,`가정무례반목사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 허성불성-세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,``,`말 말고 근신이 좋음`,`귀인연회길사`,`내부가정손해가출사`,`우유한가사`,`명예직녹자산권리사**외사막힘`,`외남내녀동시색정사**가정파재극처이동사`,`음사폐호 가택안녕-이동사`,`매사위역사`,`도적실세-잡음`,``,`상위 기쁨-하위 수도자 격`,``,`소식기다림-와도 나쁨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도*빈궁`,``,`관재`,``,`빈궁`,``,`위례득죄사`,`전도`,`외사송쟁파재실종상해사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,`전도`,`재액해소`,``,`재액해소`,`빈궁`,``,`전도*빈궁`,`폐한인`,``,`내부가정막힘**전화위복`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`소식기다림-와도 나쁨`,``,`관직 영전도약사`,`외사 동요분산사**내외사출행이동사`,`고위안좌-두루 이익경영사`,`내부가정불화사`,`가정무례반목사`,`매사 허성불성-세속허사자**위례득죄사`,`음3,4,5,6,9,12월방문자는 종교진출하면`,`매사 허성불성-세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`빈궁**관재`,`말 말고 근신이 좋음`,`내부가정손해가출사`,`귀인연회길사`,`우누한가사`,`명예직녹자산권리사**외사막힘`,`외남내녀동시색정사**가정극처파재이동사`,`음사폐호 가택안녕-이동사`,`매사위역사`,`도적실세-잡음`,``,`상위 기쁨-하위 수도자 격`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내부가정막힘**전화위복`,`빈궁**병재`,``,`빈궁`,``,`전도`,``,`전도**빈궁`,``,`성공함`,`외사송쟁파재실종상해사`,`빈궁`,`음5월 화재주의**전화위복`,`재액해소`,``,``,``,``,`빈궁`,``,`빈궁`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■직녹에 재판이 붙네!
  자칫 권리를 잃고,
女:남편의 凶속이 발생하네!
   속전속결해야 할텐데 
  돈과 여자가 발목을 걸겠어!
⊕전사귀, 
⊖변사귀ㆍ가택상가부정을 해소    해야 길이 뚫리네?
⊕신변 상가부정이 침범했네?
`,`■ 일이 더딘데 의심때문이야!     한 발 물러서면 잃더라도      다시 얻게 될거야!

`,`■ 시작은 있는데 끝이 안
   보이네?

⊕가택귀, 
⊖도로귀와 가택 두성불안     부정을 해소해야만 소원을    이루겠어!

⊖신변음사부정이 침범했어!
`,`■ 마음이 불안하고 선택의     기로에 섰네 ?
   친․근자 1인으로 결정해     야되고,
⊕전사귀ㆍ신불제사귀, 
⊖도로귀ㆍ변사귀를 잡으면    잘 되겠어!
`,`■ 여러일을 하나로 결속시키       겠는데, 집 명의가 변경       되겠네!
男：직위가 위태해 지네?
女：부부이별이 걱정돼!
⊖ 음사부정을 해소해야 
   하겠어!
⊕⊖신변두성불안부정 침범했네?
`,`■금전․여자문제로 마음에    의심질투가 가득하니 일이    잘되겠어?

⊕신불제사귀ㆍ가택상가부정, ⊖음귀를 잡아야 凶을 줄 일    수 있겠어!
`,`■ 색정불화로 부부가 각기     딴 마음이고 색정사로 
   인연이 다했어!
⊕전흉사귀, 
⊖여귀를 풀어야 가을에 돈이    들어오지!

⊖신변 상가부정이 침범했어!
`,`■ 금슬이 좋지 않네? 
   부부가 다같이 딴 정을      갖고 있겠어!
女:남자 인연이 먼 운세야 !
공직자:巳月에 소원이루겠어!
⊕신불제사귀, 
⊖음귀부터 없애야 
  4월에 소원이 이루어져!
`,`■손해나는게 끝이 없겠어!    男:직위도 흔들리고 
  女:부부가 이별할 운세야! 

⊕⊖가택의 두성불안 부정
    해소가 급선무야!
`,`■ 마음에 울분도 크고 놀라고     있기도 하겠네? 
   가정에 불안한 일(죄인)을     감추고 있겠고…

⊕전흉사귀, 
⊖여귀만 해소하면 천우신조를    얻겠어!
`,`■진퇴양난이겠어! 
   물구덩이에 빠졌어!
  관재고심도 크겠지만,
⊕ 가택귀ㆍ가택음사부정, ⊖도로귀를 해소하고 기도      하면 되는 일이 크겠어!
⊕⊖신변에 두성불안부정이      침범했네?
`,`■ 마음이 불안하겠어! 
   둘 중 친한자로 선택하면     길이 열려! 여자 둘을 잘     건사해야 되겠네!`];
  const YOGYEOL1 = [`■ 낮정단은 범이 내 녹을 노리고 또 재물과 여자로 놀라게 된다. 말전이 비고 파쇄가 되니 밤정단은 자손의 재앙이 있겠다.
  `,`■ 낮정단은 내 녹에 범이 붙었으니 직장문제는 좋지 않다. 밤정단은 고귀를 지키라. 처음은 나빠도 나중에는 좋으리라.
  `,`■ 너도 나도 피차 재앙이니 분수밖의 재물을 좋아하지 말라. 재물과 여자가 살을 도우니 군자는 좋고 일반인은 나쁘다.
  `,`■ 좋은 것은 다 비고 나쁜것만 많다. 여러 재앙이 닥치니 뜬 재물로 어찌 피할까보냐.
  `,`■ 사람이고 집이고 진행이 낮밤 모두 손해와 도적만 있다. 버리기도 어렵겠고 나아가기도 어렵다.
  `,`■ 관귀가 힘이 없으니 남편과 공직은 좋지 않다. 돈을 쓰면 잘될 것 같으나 도리어 방해가 되리라.
  `,`■ 금전이나 여자가 독을 숨겼다. 오고가는 것이 일정치가 않으니 돈 내고 벼슬구하는 것과 공적인 일은 좋겠다.
  `,`■ 합했다 싸웠다하니 길흉이 상반이다. 원수와 화합은 하나 친한자와는 재앙이 생긴다.
  `,`■ 상대가 내게 와서 손해를 끼치고 무례하다. 도적의 무리는 그 부근을 벗어나지 못했다.
  `,`■ 쑥대 화살일지라도 낮정단은 못 피하겠다. 그러나 막아주는 자가 있어 끓는 물 삶게 되지는 않겠다.
  `,`■ 사과삼전에 모두 양일 뿐이다. 낮정단은 숨은 귀신이 백호를 탓으니 공직자는 좋고 일반인은 나쁘다.
  `,`■ 역마가 돈을 실었으니 움직여서 돈이 된다.
  너무 급히 서둘면 집은 어둡고 사람은 재앙을 본다.`]
  const SIBO1 = [`■ 가정에 질병과 재앙이 있다.
  지나친 자부심으로 수모를
  겪는다.
  干上에 녹이 있어 조용히 현상태를 지키고 있어야 이롭다.
  역마가 있어 동의를 일으킨다. 내 직위나 재산을 노리는 음모가 있다.
  `,`■ 도난ㆍ사기ㆍ괴이건을
  만난다.
  피차 의심이 있어 일에 
  장애가 있다.
  스스로 후퇴처신으로 교섭ㆍ타협에 나서야 이롭다.
  해산ㆍ근심사 등은 쉽게 풀리지 않는다. 형제ㆍ붕우사나 직녹사에 독이 들었다.
  `,`■ 자충수적 우환이 있다.
  사람이나 가택이나
  발전하지 못한다.
  단, 공직인은 영전 승진이 있다. 시작은 있으나 결과가 없다. 
  모든 결정은 처음 조건대로 응해야 이롭다.
  겸손해야 한다.
  부모의 凶이 기한다.
  `,`■ 가택이동사가 있다.
  관사는 사건이 두갈래로 되어
  결국 하나로 묶어진다.
  불화는 화해가 좋고, 가깝고 친한 사람으로 결정하여 진행하면 吉하다.
  모든 일이 끝에 가서 이롭게 된다.
  四月 임신사가 있다.
  `,`■ 가택의 권리가 넘어간다.
  참됨이 없고 허모가 많다.
  부모의 凶이 기한다.
  안은 밝고 밖은 어둡다. 
  여건과 조건이 부족ㆍ불비된 사정이다. 
  男은 직위손상ㆍ女는 극부하게 된다. 별리사가 야기된다.
  다인 다사건이다.
  `,`■ 가정 내부 금전ㆍ여자
  로 인해 기로에 섰다.
  어떤 일도 처음 조건에
  결정해야 좋다. 경과하면 성립이 안된다.
  일이 중도에서 좌절되거나 정체된다.
  의심과 질투가 있다.
  四月 임신사가 있다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  부부이심이나 재물로 인해 재화가 발생한다. 두개의 사건으로 움직인다.
  공적으로 처신해야 이롭고 가을에는 재산이 증식되나 음모가 가게 된다.
  `,`■ 저주사가 있다.
  女는 夫, 男은 직위가
  위태하다. 
  처음은 다투나 후에는 좋게 된다. 부부가 각기 외정을 갖고 있다. 
  九月에는 상복사가 발생한다. 별리사가 기한다.
  四月 임신사가 있다.
  `,`■ 가정에 부정이 들어 
  어떤 후원사도 빈 말
  뿐이다.
  가정에 도적이 있다. 공망으로 활동을 못한다.
  관재로 피신할 일이 있다.
  일도 반복될 뿐 결과가 없다.
  다인 다사건이다.
  `,`■ 모든 일에 의심이 있어
  허사가 된다. 
  임신사가 있다.
  사람이나 가정이나 손모가 크다. 
  타인에게 의존해도 의리가 없어 효과를 기대할 수 없다. 
  가정에 편안치 못한 사정에 있다. 심중 놀라는 일은 해소된다.
  `,`■ 가택이 불안하고 이사등의
  움직임이 있다.
  직위ㆍ재산에 음모가 있게 된다. 관재의 기미가 있으나 풀리게 된다. 공적으로 처신해야만 吉이 된다. 아랫사람이나 종업원의 도망사가 기한다.
  `,`■ 형제ㆍ붕우사로 손모건이
  있게 된다.
  집안 어른의 재화를 예방해야 한다.
  활동하여 財를 소득한다.
  지나친 모사는 졸책이 되고 퇴보하게 된다. 둘 중 기로에서 하나로 선택하면 吉하다.
  임신사가 있다.`]
  const SIBO2_1 = [`■직녹신변외사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■신변·해산·반목외사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  매사 지장이 있다.
  `,`■가택남녀상쟁사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  옛일로 회귀한다.
  `,`■가택남녀상쟁사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  이전 이사를 한다.
  `,`■가택자손허모사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다.男직위상실하고女부부이별한다. 
  `,`■가정반목대립사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  선택의 기로에 섰다.
  `,`■신변부부이심사이다.
  내방조짐 상의 : 
  원행·이동사가 있다. 움직임이 근본이고 동기가 발동한다.색정불화한다
  `,`■신변반목외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 男便흉이 발생한다.
  `,`■內外단합·해산관련사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액이 있고 男직위상실하고女부부이별한다.
  `,`■가택남녀상쟁사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  부부불화, 충돌한다.
  `,`■가택후원내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액이 있고 男직위상실하고女부부이별한다.
  `,`■財ㆍ女,신변동요사다.
  내방조짐 상의 :  
  원행·이동사가있다.
  움직임이 근본이고 동기가 발동한다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/037/row-1-column-1.png`,
    `/static/images/sagwa/037/row-1-column-2.png`,
    `/static/images/sagwa/037/row-1-column-3.png`,
    `/static/images/sagwa/037/row-1-column-4.png`,
    `/static/images/sagwa/037/row-1-column-5.png`,
    `/static/images/sagwa/037/row-1-column-6.png`,
    `/static/images/sagwa/037/row-1-column-7.png`,
    `/static/images/sagwa/037/row-1-column-8.png`,
    `/static/images/sagwa/037/row-1-column-9.png`,
    `/static/images/sagwa/037/row-1-column-10.png`,
    `/static/images/sagwa/037/row-1-column-11.png`,
    `/static/images/sagwa/037/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  제1국은 모두가 12신이 천지(天地)에 엎드렸으므로 복음과로 되고, 양일은 자임격(自任格)이다. 3전 4맹신(三傳四孟神)은 원태격(元胎格)이다.
    초전은 간에서 발하여 녹신이 된다. 중전 인(寅)은 오늘의 재이고 말전의 사(巳)는 관으로 되어 장생을 만나는데 공망한다. 녹신이 신(身)에 임하면 망동을 즐기지 않으며, 만사에서 현상을 유지하는 것이 좋다. 중전에 역마가 승하니 고요한 가운데 동의가 생긴다. 양일복음(陽日伏吟)은 스스로 강하다고 자처하므로 자격(自格)이 되며, 사물에 대해 지나치게 자부하기 때문에 수모를 겪는다.
    3전에 녹마가 승하면 활동가 맟 임관․사관은 길하다. 그러나 말전이 공망되므로 종국에는 힘이 없어 빨리 처리하는 것이 길하다. 
    백법에는 ｢백호와 둔귀가 함께 승하는 것은 재화를 만나면 화환(禍患)이 가볍지 않다｣고 하였다. 왕록이 신(身)에 임하면 망동을 삼가야 한다.
    비전에는 ｢경일(庚日) 복음과는 두 귀신이 협묘(夾墓)로 된다｣고 하였다.
    일귀는 순행하여 3전은 백(白)․사(蛇)․구(勾)이며, 야귀는 역행하여 3전이 후(后)․용․주․이다. 일야의 백호가 4과 중에 병립하여 가정의 상하 내외에 재앙과 질병이 겹쳐 일어난다.`
    ,
    // 2
    `  이 과는 4과 중 제4과의 술해(戌亥)에서 1상이 1하를 극하여 원수과(元首課)로 된다. 3전 술유신(戌酉申)은 역연여격(逆連茹格)이다. 일간상신 미(未)는 내몸을 생하는 생기인수(生氣印綬)에 해당하고, 초전은 술(戌)의 묘신, 중전은 양인(羊刃), 말전은 일록으로 되어 모두가 일간에 왕한다. 일간일지는 교차육해(交車六害)로 되고 술(戌)은 해(亥)에 임하여 괴도천문격(魁度天門格)으로서 점사는 모두 조격하므로 통하기 어렵다.
    백법에 ｢괴도천문격은 관격조해(關格阻害)가 있고, 교차육해는 피차에 의심이 생기며, 백호가 둔귀와 합병하면 재앙이 있을 경우에는 깊다｣고 하였다. 
    비전에는 ｢일간일지상신에 미해(未亥) 사이의 지지가 3전을 이루면 협정격(夾定格)이라 부른다. 무슨 일에서나 진퇴는 남에게 의거하지 않고, 해산(解散)․우사(憂事)는 이롭지 못하며, 교섭․타협 동업은 극히 미격(美格)으로 만사가 일정하여 범위를 벗어나지 않는다｣고 하였다.
    일귀는 순행하여 3전이 현(玄)․상(常)․백(白)이며, 야귀는 역행하며 3전이 현(玄)․음(陰)․후(后)이다. 일야천장(日夜天將)의 현무는 초전에 앉아 도난․사기․괴의(怪疑)한 사건을 주관한다.`
    ,
    // 3
    `  이 과는 4과 중에서 2상이 하를 극하여 원수비용과(元首比用課)를 취할 수 없기에 섭해과로 된다. 지반맹신(地盤孟神)의 신(申)의 극을 취하여 초전으로 하니 3전이 오진인(午辰寅)으로서 역간전격(逆間傳格)이다.
    초전의 오(午)는 순수(旬首)의 지신(地神)이므로 순의(旬儀)로 된다. 일간일지가 모두 상신에서 극되므로 인택이 모두 발전하지 못한다. 초전이 관귀효(官鬼爻)로서 청룡이 승하면 공직인(公職人)에게는 진급의 기쁨이 있다. 발용육의(發用六儀)는 흉을 해소하고 길상을 초래한다. 중․말전이 공망하니 시작은 있어도 끝이 없다.
    3전이 오진인(午辰寅)이면 고조격(顧祖格)이라 한다. 초전의 오화(午火)에서 인(寅)을 보면 장생(長生)으로 된다. 양극(陽極)인 중말전의 진인(辰寅)은 양계(陽界)에 있어서 스스로 자부하고 겸손하므로 정단사의 대부분은 길조다. 그러나 중말전이 공망하여 길함이 경미하다. 
    백법에 ｢일간일지상신이 다 같이 극되는 것은 피차 양손(兩損)이라 하며, 중․말전의 공망은 전(傳)으로 돌지 않으니 초전을 고려해야 한다｣라고 하였다. 길흉은 초전의 신으로 구(求)해야 한다. 
    비전에는 ｢초전이 미월(未月)이면 천마가 승하므로 기다리는 사람을 점치는 사건이다｣라고 하였다. 중말전이 공망이면 달 안(月內)으로 돌아오지 않는다. 말전의 역마는 일지상신을 극한다. 일간의 경(庚)을 극하여 일지상신 술(戌)과 충하는 병진일(丙辰日)에 반드시 돌아온다. 
    일귀(日貴)는 순행하며 3전이 청(靑)․합(合)․사(蛇)로 화하므로 급히 퇴보해야 한다. 야귀는 역행하여 3전이 사(蛇)․합(合)․청(靑)으로 사가 청으로 화하니 공명이 성취된다. 그러나 공망하기에 원대하게 계획해야 한다.`
    ,
    // 4
    `  이 과는 제1과․제4과의 2상에서 2하를 극하며, 양일이니 양비의 극이다. 오유(午酉)를 취하여 초전으로 하니 지일과(知一課)로 된다. 일간상신과 초전의 사화(巳火)․오화(午火)는 일귀(日鬼)이나 간상신이 공망하고 초전은 말전의 자(子)에서 제어되므로 귀살은 두려울 것이 없다. 
    지일과는 극이 두 자리가 있으므로 사건은 두 갈래에 걸치면서 결국은 하나로 돌아간다. 따라서 다투는 일에서는 화해하는 것이 좋고 사업은 계획하고 활동하여야 한다. 찾는 사람과 분실물은 가까이에서 찾아야 한다.
    백법에 ｢폐구격(閉口格)은 사물이 두 길로 추이(推移)된다｣고 하였다. 3전이 오묘자(午卯子)로서 인월(寅月) 및 신월(申月)은 오(午)가 천마(天馬)가 되면 고개승헌격(高蓋乘軒格)으로 된다. 공명(功名)은 상길로서 출세․개운․영달하는 기쁨이 있고, 모든 시험에서 합격이 가능하다. 
    일귀는 순행하여 3전이 청(靑)․주(朱)․후(后)이며, 야귀는 역행하여 3전이 사(蛇)․구(勾)․백(白)이다.`
    ,
    // 5
    `  이 과는 제2과의 1하가 1상을 적(賊)하여 자진(子辰)을 초전으로 하니 중심과로 된다. 3전은 갑자진(甲子辰)으로서 삼합수국윤하격(三合水局潤下格)이며, 일간의 설기로 된다. 3전은 4과를 떠나지 않고 과전이 모두 3합수극으로 돌아간다.
    3합은 생왕묘(生旺墓)로서 맹중계(孟仲季)의 3신이다. 수(水)의 성(性)은 하(下)를 자윤(滋潤)하므로 윤하(潤下)라고 한다. 3전은 생하여 왕하고 왕하여 묘(墓)로 되며, 순환하여 끝이 없는 수(水)의 형상은 유약하지만 강강(剛强)보다 나으며 안은 밝고 밖이 어둡다. 실(實)하면 썩지 않고 상에서 하에 붙는 것은 길하다. 소송 쟁의는 하인과 연결되니 사물이 진전되기 어렵고, 음신(音信)과 문장(文章) 사건은 만사가 발동하기 어렵다.
    백법에 ｢일지상신에 녹신이 승하면 권섭이 바르지 못하다｣고 하였다. 
    비전에는 신(申)이 월장일 때의 도망인을 밤정단한 일례를 들고 있다. ｢전(傳)은 참관격(斬關格)이고 자손효가 초전으로 되며, 청룡이 승하고 말전 현무가 공망한 것으로 자손유신(子孫類神)인 자가 먼곳으로 도망가고 소식이 없다. 오(午)의 년명에 인(寅)의 재신이 승하니 7년 후에 재물을 얻어 돌아온다｣고 하였다. 즉 년명상(年命上) 인(寅)에는 역마가 승하고 인(寅)은 7수(七數)이므로 7년 후라고 간점한 것이다. 4과이지만 1과가 결여되어 3과뿐이니 불비격이다. 참됨이 없고 허모(虛耗)한다. 그러나 과전이 모두 3합하므로 불비격은 경하다. 
    일귀는 역행하여 3전은 사(蛇)․청(靑)․현(玄)이고, 야귀는 순행하여 3전이 청(靑)․사(蛇)․현(玄)이다.`
    ,
    // 6
    `  이 과는 4과에 모두 상하로 극이 있다. 하에서 상을 극하는 것을 취하며, 양일이니 양의 극이다. 술묘(戌卯)를 사용하여 초전으로 한다. 즉 지일비용과(知一比用課)이다. 비용이란 양일에는 양의 극을 사용하고, 음일인 경우에는 음의 극을 사용하는 것이다. 즉 양일양비. 음일음지를 채용하기에 이같은 이름이 붙었다. 중․말전이 공망하여 모든 일이 중도에서 정체 또는 좌절된다.
    백법에는 ｢중․말전의 공망은 전으로 돌지 않는다. 초전으로써 일을 결정해야 한다｣고 하였다. 
    비전에는 ｢묘(卯)가 신(申)에 가해지는 것은 태신(胎神)이 일간상신에 가해지고 절(絶)에 임하여 극을 받으므로 임신을 정단하면 태아는 위태롭고 출산은 신속하다｣고 하였다. 이 과는 상하의 상극이 많아 모든 일에 파동과 곡절이 많은 것이 보통이다.
    일귀는 역행하여 3전은 합․상․사이고, 야귀는 순행하여 3전이 합․음․청이다. 말전 자(子)는 사(巳)에 가해져서 사(死) 자(字)로 되니 점병하면 구할 수가 없다.`
    ,
    // 7
    `  이 과는 12신이 대궁(對宮)에 임하고 천지7충되니 반음과(返吟課)로 된다. 일간상신 인(寅)은 재성이고 일지상신 오(午)는 관성(官星)이며 과전이 모두 양지로 되어, 공용(公用)에는 가장 길조이다. 
    반음과는 왕래가 반복하는데, 백호가 승하여 일간을 극하면 재물로 인해 화환(禍患)이 발생한다. 
    신(申)의 건록은 절지(絶地)에 있으니 추월에는 녹이 왕하여 길이지만, 다른 달에는 건록도 힘이 약하여 쓸모가 없다. 따라서 작은 이득 뿐이다. 구직구관은 가장 길하다.
    백법에는 ｢일간일지상신에 녹마가 앉고 귀인이 더해지면 부귀한 격이다｣고 하였다.
    비전에는 ｢일간이 경신(庚辛)이고 인(寅)이 가해지면 병에 걸린다｣고 하였다. 역마가 중첩되어 보이는 것은 두 개의 사건으로 움직임이 있다.
    일귀는 역행하여 3전이 후(后)․청(靑)․후(后)이고, 청신(靑神)․덕록(德祿)․천후(天后)가 앉으면 은택의 기쁨이 있다. 야귀는 순행하여 3전이 백(白)․사․백(白)이다.`
    ,
    // 8
    `  이 과에서는 제3과만이 하에서 상을 적(賊)하여 사자(巳子)가 초전으로 되므로 중심과이다. 일간일지 축(丑)과 자(子)는 지합하고 신(申)과 사(巳)가 지합하여 교차육합으로 된다. 그러나 서로 상극하므로 처음에는 쟁해(爭害)하고 후에 기쁨을 만난다.
    사술묘(巳戌卯)는 주인격(鑄印格)이지만 초․중이 공망되면 인모(印模)가 이루어지지 않는다고 하여 사관이나 현임 관리는 모두 흉으로 화한다. 만일 년명상신에 사(巳)가 있다면 전실공망(塡實空亡)을 풀어서 길조를 이룬다. 주인(鑄印)이란 사(巳) 안에 병화(病火)가 있고 술(戌) 안에 신금(辛金)이 있어서 간합한다. 금(金)은 화(火)에 의하여 화련(火煉)되어 귀기(貴器)를 이루므로 이와같은 이름이 생겼다. 태산은 길하고 질병은 흉하며, 현임 관공직은 길하고 재야(在野)에 있은 사람이면 불리하다.
    백법에 ｢무음(蕪淫)은 부처가 서로 사정(私情)이 있다｣고 하였다.
    비전에는 ｢일지상신이 태상(太常)에 승하여 택을 극하고 술월(戌月)은 사기(死氣)가 승하므로 상사(喪事)가 발생한다｣고 하였다. 
    일귀는 역행하여 3전이 상(常)․합(合)․음(陰)이고, 야귀는 순행하여 3전이 음(陰)․합(合)․상(常)이다.`
    ,
    // 9
    `  이 과는 4과이나 3과뿐이다. 즉 제2과와 제3과가 같아서 상에서 하를 극하여 원수불비과를 구성한다. 3전 진신자(辰申子)는 수국윤하(水局潤下)로 되며 일간 경(庚)에서 탈루(脫漏)한다. 일지 자(子)는 내몸에 의지하니 나의 물심을 소모한다. 일지상의 진(辰)이 초전으로 되면 참관격(斬關格)이며, 초전에 현무가 승하는 것은 도적의 무리로 본다.
    여덕격(勵德格)은 한마을  한거리의 사람이다. 일간을 존장으로 하고, 일지는 부하가 일상(日上)에 가해지는 것이므로 나에게 의존하여 손모(損耗)하며, 3전의 3합은 4과를 떠나지 못한다.
    백법에 ｢탈상탈(脫上脫)을 만나면 허사소모(虛詐消耗)를 방비해야 한다｣고 하였다. 난(亂)을 피하여 도망하려면 구태를 버려야 한다. 피난도생격(避難逃生格)이란 경금(庚金)은 진토(辰土)에서 생을 받고, 자수(子水)에서 신(申)은 장생에 해당되므로 흉모(凶耗)의 극에서 한 걸음 피할 길이 있는 것으로, 이와 같은 것을 말한다. 
    비전에는 ｢자(子)에 진(辰)이 가해지고 현무가 승하여 초전을 이루면 참된 도적이다｣라고 하였다. 
    일귀는 순행하여 3전이 현(玄)․청(靑)․사(蛇)이며, 야귀는 순행하여 3전이 현(玄)․사(蛇)․청(靑)이다.`
    ,
    // 10
    `  이 과는 4과 상하에 상극이 없고 사각(斜角)의 극은 세 자리가 있다. 천신에서 극하는 오묘(午卯)를 사용하여 초전으로 하니 요극호시과(遙剋蒿矢課)이다. 초전의 오(午)는 순의 순의(旬儀)로 된다. 자일(子日)에 정단하여 3전4중신(三傳四仲神)은 삼교용전격(三交龍戰格)이다.
    초전은 귀효로 되어 일간을 극하지만 간상의 해(亥) 및 말전의 자(子)가 귀(鬼)를 제어하므로 해를 방지할 수 있다. 4과의  지신(地神)에서 천반에 모두 탈기되는 것은 근단원소(根斷源消)라 하여 소모가 대단하다. 남에게 의존해도 절의(節儀)를 잃어 효과를 보지 못한다. 
    백법에는 ｢일간일지가 탈기로 되고 인택(人宅)을 함께 탈하면 도난․모손을 초래한다｣고 했다. 
    비전에는 ｢초전의 노도살(魯都殺)은 관귀로 되고 말전의 유도장성(遊都將星)은 초전을 충하여 병과(兵戈)가 있으나, 흉을 제어하므로 재앙을 입지 않는다｣고 했다.
    삼교격(三交格)은 모든 일이 연결되어 자유를 얻지 못한다. 유(酉)는 정신(丁神)이 승하여 피차간에 서로 허사의 의심이 있다. 그러나 순의(旬儀)는 모든 것의 구신(仇神)이 되어 흉을 해소한다.
    일귀(日貴)는 역행하여 3전이 백(白)․구(勾)․사(蛇)이고 야귀는 순행하여 3전이 후(后)․주(朱)․청(靑)이다.`
    ,
    // 11
    `  이 과는 제2과와 제4과의 하에서 상을 극하여 섭해과(涉害課)다. 지반맹신(地盤孟神)의 극인 진인(辰寅)을 초전으로 한다. 진(辰)을 초전으로 하니 참관격(斬關格)이나 술(戌)에서 충하므로 개묘(開墓)되어 참관이 통하니 정체가 없다. 진오신(辰午申)은 등삼천격(登三天格)으로서 순간적(旬間的)으로 된다. 과전이 전부 양지(陽支)뿐이면 공용(公用)에 길하다. 귀신이 해(亥)에 임하므로 귀신이 천문에 올라 진인(辰寅)이 초전이면 강색귀호격(罡塞鬼戶格)으로서 모두가 유리 유익하고 흉을 해소한다. 그러나 초․중전이 공망하므로 길을 감한다. 또 일지의 인(寅)은 일간에서 극하고 또 말전에서 충극(冲剋)을 받으며 역마가 승하니 가택이 불안하고 동의(動意)가 일어난다.
    백법에는 ｢강색귀호는 모사에 합당하다｣고 하였다. 과전에 육양지(六陽支)가 완전하면 공용에 길하다. 
    비전에는 ｢진술(辰戌)에 현무가 승하고 공망이 교차되면 사용인의 도망 사건이 일어난다｣고 하였다.
    일귀는 순행하여 3전이 합(合)․청(靑)․백(白)이고, 야귀는 역행하여 3전이 합(合)․사(蛇)․후(后)이다. 일록(日錄)에 백호가 승하면 놀라운 일을 만나는데, 오(午)의 제지(制支)에 앉아 흉이 해소되므로 재해를 만나도 경미하다.`
    ,
    // 12
    `  이 과는 제3․제4과에 음양의 극이 있고 양일(陽日)이므로 양비(陽比)의 극이다. 인축(寅丑)을 사용하여 초전으로 하니 지일격(知一格)이다. 3전 인묘진(寅卯辰)은 진여격(進茹格)이며 말전이 공망한다. 그러나 제2과의 술(戌)과 충하여 개묘로 되니 정체가 없다. 초전의 인(寅)은 역마가 재동(財動)하므로 활동에 의한 재 혹은 먼 곳의 재를 얻는다. 또 방위(方位)를 구(求)하면 더욱 길하다. 
    일간일지는 금생수(金生水), 토생금(土生金)으로교차지합하고, 지신(地神) 앞의 지(支)가 천반으로 되므로 나망격(羅網格)이다. 3전이 일간의 재로 되고 일지(日支)에서 탈설(脫泄)하므로 재가 많이 모이나 재를 낭비할 우려가 있다.
    백법에는 ｢나망격은 모사에서 졸책(拙策)을 이루고, 진여(進茹) 공망되는 것은 도리어 퇴보한다｣고 하였다. 
    비전에는 ｢일간상신이 지(支)를 생하고 지상신이 일간을 생하는 것은 호생격(互生格)이라 하여 피차간에 서로 유익하며 생의(生意)가 있다｣고 하였다.
    3전이 순재(純財)이면 인수(印綬)인 존장을 해친다. 일귀는 순행하여 3전이 사(蛇)․주(朱)․합(合)이고, 야귀는 역행하여 3전이 청(靑)․구(勾)․합(合)이다. 청룡은 재(財)가 좋아하는 신이다. 구재는 지족(知足)이면 전재(全財)라 해도 두려울 것이 없다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
