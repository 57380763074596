import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";

const CompOuter = styled.div`
    width: 100vw;
    height: auto;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
`;

const CompInner = styled.div`
    width: 100%;
    height: 100%;
`;

const Comp = props => {
    const { children } = props;
    return (
        <CompOuter {...props}>
            <CompInner>
                {children}
                <div className="margin_top_auto"></div>
            </CompInner>
            <div className="margin_top_auto"></div>
        </CompOuter>
    );
};

export default Comp;
