
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,8,8,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,7,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,10,6,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,11,5,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,12,4,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,1,3,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,2,2,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,3,1,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,12,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,5,11,`특별있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,10,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`불성`,7,9,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`상업`,`상업 관련사`,`小`,`6`,`위례득죄`,`음사폐호`],
    [`처`,`처 관련사`,`大`,`존재기`,`외사손실`,`가택안녕`],
    [`가정불화(家庭不和)`,`가정(부부)불화의 상`,`大`,``,``,`이동사`],
    [`문장패회(門牆敗懷)`,`감싸주던 대문과 담장이 무너진 격-심신의 의지 훼손 관련 `,`小`,``,``,``],
    [`자매`,` 관련사`,`小`,``,``,``],
    [`친구`,` 관련사`,`大`,`5`,``,``],
    [`송쟁`,` 내부 가정 송쟁사`,`小`,`배양기`,`영전도약`,`반목송쟁`],
    [`유의폐구(惟宜閉口)`,`재난을 면하려면 입을 굳게 닫는 수밖에 없는 상`,`小`,``,`가실불화`,`우유한가`],
    [`영전도약(榮轉跳躍)`,`영전되어 포부를 펼치게 되는상`,`大`,``,``,`(실업처지)`],
    [`관사`,`외 급속송쟁사-상해 우려`,`大`,``,`고위안좌`,``],
    [`직업`,`직업 관련사`,`小`,`4`,`多衆`,`고위연회`],
    [`남편`,`남편 관련사`,`小`,`개시기`,`이익강구`,`초대열석`],
    [`이어경영(利於經營)`,`고위에 올라 여러 사람들의 이익을 위해 일하게 되는 상`,`大`,``,`급속송쟁`,`신규단합`],
    [`사관반희(仕官反喜)`,`일반인은 흉하나 관직자는 반대로 기쁨을 맞게 되는 상`,`小`,``,``,``],
    [`관사`,` 관사 관련사`,`大`,``,``,``],
    [`결속`,`결속단합으로 후원사를 크게 이루나 자녀災害가 발생함`,`小`,`3`,`투서헌책`,`앙화소멸`],
    [`남자`,`내부가정 女 색정 화합사 有`,`小`,`학습기`,`고위면접`,`색정사有`],
    [`우유한가(優遊閑暇)`,`머리를 다듬는 한가로움의 상-보통 직업 직장이 없는 人`,`大`,``,``,``],
    [`관사문자(官事文字)`,`관사 관련 문서사 `,`小`,``,``,``],
    [`동요`,`내부가정 동요분산사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`2`,`掩目지상`,`권리受여`],
    [`매사 영속성없음`,`형제친구동료동업남편남자사는 선천적 기복재해를 겪음`,`小`,`면모기`,`(눈이 가려`,`동요불안`],
    [`귀용사길(貴用事吉)`,`상위자와의 관계사가 吉하게 작용되는 격`,`小`,``,`진격)`,``],
    [`엄목지상(掩目之象)`,`눈이 가려진 격의 상`,`大`,``,``,``],
    [`직녹`,`명예직녹자산권리사-외교막힘`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`1`,`불의투서`,``],
    [`매사 영속성없음`,`자매친구동료남편사는 선천적 숙명재해를 겪음-독신고독`,`小`,`절정기`,`불용`,`도적소실`],
    [`왕래조함(往來遭陷)`,`오고 가는 중 봉변을 당하게 되는 상`,`小`,`세속허사`,`외교막힘`,``],
    [`미초희경(微招喜慶)`,`권한행사에 기쁘나 완전하지 못헤 실소하는 상`,`小`,`자로무망`,``,``],
    [`후원`,`후원 관련사`,`大`,``,``,``],
    [`색정`,`외 男색정사 有`,`大`,`12`,`비밀누설`,`사행불량`],
    [`부모`,`부모 관련사`,`小`,`과시기`,`색정사有`,`동요분산`],
    [`고귀용사(告貴用事)`,`상위인으로부터 할 일을 고지받는 상`,`小`,``,``,``],
    [`상린충산(傷鱗冲散)`,`비늘이 벗겨지는 상처를 입고 서로 흩어지는 상`,`小`,``,``,``],
    [`자매`,`후원 관련사`,`小`,``,``,``],
    [`후원`,`결속단합하여 후원사를 크게 이루나 자녀災害가 발생함`,`大`,`11`,`관재형책`,`失業한가`],
    [`송쟁`,`내부 가정 송쟁사`,`小`,`쇠퇴기`,`침체공허`,`반목송쟁`],
    [`관길상기(官吉常忌)`,`관직자는 촣으나 일반인은 나쁨으로 회피해야 하는 상`,`小`,``,``,``],
    [`우유한가(優遊閑暇)`,`머리를 다듬는 한가로움의 상-실업자 격`,`大`,``,``,``],
    [`박관`,` 직업직위 불안사-외 애증파재실종傷身사 우려`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`충산동요`,`투서헌책`],
    [`출행`,` 내부 가정 출행이동사`,`大`,`침해기`,`직위불안`,`고위면접`],
    [`구재소구(求財小口)`,`구재사는 뜻대로 되나 작은 구설을 방비해야 하는 상`,`小`,``,``,`출행이동`],
    [`불의헌책(不宜獻策)`,`급히 쓴 문사로 투서헌책함이 좋지 않은 상-失財사 겹침`,`大`,``,``,``],
    [`박관`,` 직업직위 불안사`,`小`,``,``,``],
    [`남자`,`남자 갈등사- 내부가정 손해가출사 우려`,`小`,`9`,`고위연회`,`소식개대`],
    [`여식`,`여식 관련사`,`大`,`소외기`,`초대열석`,`와도나쁨`],
    [`병필상식(病必傷食)`,`병자는 반드시 음식을 못먹게 되는 상-부모 관련`,`小`,``,``,`손해가출`],
    [`음신조격(音信阻隔)`,`소식을 기다리나 소식을 받아도 나쁜 상`,`大`,``,``,``],
    [`형제`,` 형제 관련사`,`小`,``,``,``],
    [`친구`,` 친구 관련사`,`大`,`8`,`不辨근신`,`上喜하승`],
    [`침체`,`내부가정 막힘사`,`小`,`침체기`,`지체의혹`,`가정침체`],
    [`향후소익(向後少益)`,`도모사가 지체 의혹스러우나 후에 적은 이익을 얻는 상 `,`小`,``,``,``],
    [`상희하승(上喜下僧)`,`상위자는 기쁘나 하위자는 수도자 격인 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`출행`,`상업 관련사-외사 동요불안분산사-출행이동사`,`小`,`7`,`구설詞訟`,`도적失勢`],
    [`처첩`,`처첩 관련사-내 급속사`,`大`,`단절기`,`출행원행`,`급속사有`],
    [`구설사송(口舌詞訟)`,`구설과 소송사는 흉하고 문서와 소식사는 좋은 상`,`大`,``,``,``],
    [`불가동모(不可動謀)`,`도모사를 적극적으로 움직여 하지 말아야 하는 상 `,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,`금전사업여인사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,`자매친구비용지출영전사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사 직업 남편 사로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사직업가정색정사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`후원문서부모조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`재매친구비용지출로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`직업직위불안남편갈등자식문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`직업직위불안남자갈등여식문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`형재친구동료조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`금전사업처첩조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0], // 0
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1], // 5
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2], // 10
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3], // 15 
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4], // 20
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5], // 25
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6], // 30
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7], // 35
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8], // 40
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9], // 45
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10], // 50
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11], // 55
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12], // 60
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 앞세워 정당하게 속결해야만 유리함`,0,0,`사안을 뒤로 미루거나 좌고우면하여 지체되거나 뒤로 미루면 결과가 되려 불리하게 되는 人`,0,0,`일이 역순으로 진행됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 맞서지 말고 순응 유화적 처신으로 일을 뒤로 미루면 결과가 유리하게 됨`,0,0,`역상적 사안으로 억울하더라도 맞대응을 말고 유순한 처신으로 일을 지체시키거나 후로 밀면 반드시 결과가 유리한 人`,0,0,`일이 결과없이 반복되기만 함**관직자는 자리를 옮기고 일반인은 신택이 불안함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 앞세워 정당하게 속결해야만 유리함`,0,0,`사안을 뒤로 미루거나 좌고우면하여 지체되거나 뒤로 미루면 결과가 되려 불리하게 되는 人`,0,0,`신규사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)**만약 타인과 다툰다면 상대는 전의가 별로 없고 손상도 없으며 있다해도 가벼움`,0,0,`심중이 천둥에 놀란 듯하나 언행을 삼가하고 조용히 있으면 그 두려움이 자연히 사라지게 되는 人 `,0,0,`여러 일을 하나로 일관시키는 결속단합을 꾀함-단, 외화내허 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 맞서지 말고 순응 유화적 처신으로 일을 뒤로 미루면 결과가 유리하게 됨`,0,0,`역상적 사안으로 억울하더라도 맞대응을 말고 유순한 처신으로 일을 지체시키거나 후로 밀면 반드시 결과가 유리한 人`,0,0,`1남2녀 삼각관계有**독신고독각방주말부부생이사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`동요불안분산사가 있고 반복 무상하며 심신의 의지처가 없는 人`,0,0,`신규사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 앞세워 정당하게 속결해야만 유리함`,0,0,`사안을 뒤로 미루거나 좌고우면하여 지체되거나 뒤로 미루면 결과가 되려 불리하게 되는 人`,0,0,`1녀2남 삼각관계가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 앞세워 정당하게 속결해야만 유리함`,0,0,`사안을 뒤로 미루거나 좌고우면하여 지체되거나 뒤로 미루면 결과가 되려 불리하게 되는 人`,0,0,`일이 결과가 없고 반복되기만 함**여자(유부녀)가 남자(유뷰남)를 적극적으로 유혹하여 함께 도망칠 속셈까지 갖고 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)**만약 타인과 다툰다면 상대는 전의가 별로 없고 손상도 없으며 있다해도 가벼움`,0,0,`호가호위의 세로 상대를 공격해도 적중시키지 못하고 되려 불리하게 될 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 맞서지 말고 순응 유화적 처신으로 일을 뒤로 미루면 결과가 유리하게 됨`,0,0,`역상적 사안으로 억울하더라도 맞대응을 말고 유순한 처신으로 일을 지체시키거나 후로 밀면 반드시 결과가 유리한 人`,0,0,`일이 띄엄띄엄 진행되나 퇴하게 됨**모든 일이 어둠을 뚫고밝은 데로 나아감`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 맞서지 말고 순응 유화적 처신으로 일을 뒤로 미루면 결과가 유리하게 됨`,0,0,`역상적 사안으로 억울하더라도 맞대응을 말고 유순한 처신으로 일을 지체시키거나 후로 밀면 반드시 결과가 유리한 人`,0,0,`일이 순조롭게 진행됨**고이고 차질되는 일들이 기이하게 전화위복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 공격할 뜻이 있어도 중지됨`,0,0,`자기 스스로를 자임하고 신음하며 엣 것을 지킴면서 새 기회를 기다리는 人`,0,0,`신규사가 있음**소송사가 있음**독신고독각방주말부부생리사별적 처지임**고시준비생에게 많음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성-무력`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무능인`,`아발동-타발동겹침`,`미사`,`신구중간사`,`아외사타내사 겹침`,`아표면타이면사겹침`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`아발동사-후회사겹침`,`기사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`상호무력`,`상호무력`,`상호무력`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월`,2,2,4,`음11월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월-연기 또는 파혼우려`,3,1,4,`음10월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`정남`,2,1,3,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음7월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음5월`,2,3,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,3,1,4,`음10월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,2,2,4,`음3월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,3,3,6,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음7월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯한나 사람을 잘 조정하지만 업무는 초 중반이 저조하고 종반에 저조한 실적을 보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 배경이 없으나 업무는 수준을 유지하고 종반에 이전이동문제로 심각한 동요분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하나 업무전반이  저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이며 업무가 강하지 못해도 여러 일을 일관시키는 결속단합을 이루어 탁월한 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무 전반이 저조하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업부 저반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`이격이 높고 업무도 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이며 업무가 강하지 못해도 여러 일을 일관시키는 결속단합을 이루어 탁월한 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무도 수준이하로 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무가 수준이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 행운이 따르며 업무를 질서있게 진행시켜 업적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무를 소송사로 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`선난후난`,`退背`,`원`,`去`,`세입조력`,`서남`,``,`정북`],
    [`10.11`,``,`내부제압`,``,`본인무력`,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선난후난`,` 退背`,`  원`,`  去`,`세입조력`,`정서`,``,`북서`],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선이후난`,` 退背`,`  원`,`  去`,`반목송쟁`,`동북`,``,`북북서`],
    [`10.11`,``,`끌면제압`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`급속현안`,`불이행`,`선이후이`,`進向`,`  원`,`  留`,`주인조력`,`정동`,`정북`,``],
    [`10.11`,`  集`,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`불이행`,`사안무력`,`退背`,`  원`,`  去`,`주인무력`,`정남`,`북북서`,``],
    [`10.11`,`  集`,`제압불가`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`사안무력`,` 退背`,`원근왕래`,`  去`,`주인무력`,`남남서`,`정북`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`내부반대`,`불이행`,`선난후합`,`進向`,`  원`,`  留`,`상호화합`,`남남서`,`정동`,``],
    [`10.11`,``,`발형안됨`,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`불이행`,`선난후攻`,`退背`,`  원`,`  去`,`주인가해`,`정서`,`정동`,``],
    [`10.11`,`  集`,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인제압`,`불이행`,`선이후이`,`退背`,`  원`,`  去`,`상호송쟁`,`서남`,`동북`,``],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대있음`,`불이행`,`선攻후攻`,`進向`,`  원`,` 留`,`세입가해`,`정서`,``,`정서`],
    [`10.11`,` 多集`,`측근제압`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`선攻후난`,`進向`,`  근`,`  留`,`세입가해`,`정남`,``,`정서`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`중간제압`,`불이행`,`본인무력`,`退背`,`  근`,`  去`,`무난`,`북북서`,``,`북북서`],
    [`10.11`,``,`끌면불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,`전사귀`,`전사`,``,``,`묘좌`,0,`없음`],
    [``,`가장귀`,`호주사망`,``,``,`묘향`,0,``],
    [`생업`,``,``,`가당부정`,`성소훼손`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`전사귀`,`전사`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`가장귀`,`호주사망`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`가당부정`,`성물훼손`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`음귀`,`철물반입`,``,``,`육친`,0,``],
    [`질병`,` 여귀`,`호주부인`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`여귀`,`호주부인`,``,``,`육친`,0,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,`북북서`,``],
    [``,``,``,``,``,`묘향`,`남남동`,``],
    [`생업`,``,``,``,``,`전경`,`향대`,``],
    [``,``,``,``,``,`묘속`,  `水`,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,`고조부`,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,0,``],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`두성부정`,`기도위배`,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사 급속사**도적실세-잡음`,`윗인 희사-아래인 수도자격`,``,`소식기다림-와도 나쁨`,`관직자 영전도약사`,``,`고위안좌-이득경영사`,`외사 송쟁급속출행이동원행사`,`가정불화사`,`내 여자 색정사`,`매사 허성불성-세속허사자**내사동요분산사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`매사영속성없음**외사막힘`,`종교진출하면자립함**선천 숙명적 독신고독자`,`말 말고 근신이 좋음`,`외 남자 색정사`,``,`고위연회길사`,`내사 출행이동사**외사 애증파재실종사우려`,`면모 단장이 필요없는 한가로움`,`종업원 모사有-이동사**내부가정손해가출사우려`,`음사폐호-가택안녕-이동사`,`매사위역사**내부가저막힘`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,`빈궁**병재`,``,`전도**곤궁`,``,`관재`,``,`빈궁**관재`,`위례득죄사`,`전도`,`빈궁`,`명예직위자산권리사`,`음5월 화재주의`,`곤궁**전화위복`,``,`전도`,`재액해소`,``,`재액해소`,`곤궁`,`침체경구사`,`전도**관재**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**내부가정막힘`,``,`내사 급속사**도적실세-잡음`,`윗인 희사-아래인 수도자격`,``,`소식기다림-와도 나쁨`,`관직자 영전도약사`,``,`고위안좌-이득경영사`,`외사 송쟁급속출행이동원행사`,`가정불화사`,`내 여자 색정사`,`매사영속성없음**내사동요분산사`,`종교진출하면자립함`,`매사영속성없음**외사막힘`,`종교진출하면자립함**선천 숙명적 독신고독자`,`외 남자 색정사`,`말 말고 근신이 좋음`,``,`고위연회길사`,`내사 출행이동사**외사 애증파재실종사우려`,`면모 단장이 필요없는 한가로움`,`내부가정손해가출사우려`,`음사폐호-가택안녕-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`전도`,``,`전화위복`,`전도**빈궁**병재`,``,`빈궁`,`재액해소`,`관재`,`재액해소`,`빈궁**관재`,`위례득죄사`,``,`빈궁`,`명예직위자산권리사`,`음5월 화재주의`,`빈궁**관재**전화위복`,``,``,``,``,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내부가정손해가출사우려`,`음사폐호-가택안녕-이동사`,`매사 위역사**내부가정막힘`,``,`내사 급속사**도적실세-잡음`,`윗인 희사-아래인 수도자격`,``,`소식기다림-와도 나쁨`,`관직자 영전도약사`,``,`고위안좌-이득경영사`,`외사 송쟁금속출행이동원행사`,`가정불화사`,`내 여자 색정사`,`매사영속성없음**내사동요분산사`,`종교진출하면자립함`,`매사 영속성없음**외사막힘`,`종교진출하면자립함**선천 숙명적 독신고독자`,`외 남자 색정사`,`말 말고 근신이 좋음`,``,`고위연회길사`,`내사 출행이동사**외사애증파재실종사우려`,`면모 단장이 필요없는 한가로움`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁`,``,`전도**빈궁`,``,``,`전화위복`,`빈궁**병재`,``,`전도**빈궁`,``,`관재`,``,`빈궁**관재`,`위례득죄사`,`전도**빈궁`,`명예직위자산권리사`,`관재`,`음5월 화재주의`,`빈궁**관재`,`전도`,``,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`동요충산사`,`내사 출행이동사**애증파재실종사우려`,`형책사-이동사`,`내부가정손해가출사우려`,`약속된 비밀누설-죄 초래사`,`내부가정막힘`,`급문사투서헌책-득불용-실재사`,`내사 급속사`,`눈이 가려진 상-불능상해사`,``,`투서헌책-고위면접사`,``,`머리를 가다듬는 한가로움`,`외사 송쟁급속출행이동원행사`,`사행불량사`,`내 여자 색정사`,`매사영속성없음**매사위역사**내사동요분산사`,`종교진출하면자립함`,`매사영속성없음**외사막힘`,`종교진출하면자립함**미초희경사`,`외 남자 색정사`,`앙화소멸사`,`사기득재사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`재액해소`,`빈궁`,``,`전도`,``,`빈궁`,`전화위복`,`빈궁`,`전도`,``,`관재`,``,`빈궁**관재`,``,`도적소실사`,`전도**빈궁`,`명예직위자산권리사`,`관재**선천숙명적독신고독자`,`음5월 화재주의`,`빈궁**병재전화위복`,``,`전도**관재**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`사기득재사`,``,`동요충산사`,`내사 출행이동사**외사 애증파재실종사우려`,`형책사-이동사`,`내부가정손해가출사우려`,`약속된 비밀누설-죄 초래사`,`내부가정막힘`,`급문사투서헌책-불득용-실재사`,`내사 급속사`,`눈이 가려진 상-불능상해사`,``,`투서헌책-고위면접사`,``,` 가다듬는 한가로움`,`외사 송쟁금속출행이동원행사`,`사행불량사`,`내 여자 색정사`,`매사영속성없음**매사 위역사**내사동요분산사`,`종교진출하면자립함**외사막힘`,`매사영속성없음**선천숙명적독신고독자`,`종교진출하면자립함**미초희경사`,`앙화소멸사`,`외 남자 색정사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**관재`,``,``,`빈궁**관재`,``,`재액해소`,``,`빈궁`,``,`전화위복`,`빈궁**관재`,`재액해소`,``,`관재`,``,`빈궁**관재`,``,`도적소실사`,`빈궁`,`전도`,`명예직위자산권리사`,`음5월 화재주의`,`전도**빈궁**빈궁**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`앙화소멸사`,`외 남자 색정사`,`사기득재사`,``,`동요충산사`,`내사 출행이동사**외사애증파재실종사우려`,`형책사-이동사`,`내부가정손해가출사우려`,`약속된 비밀누설-죄 초래사`,`내부가정막힘`,`급문사투서헌책-불득용-실재사`,`내사 급속사`,`눈이 가려진 상-불능상해사`,``,`투서헌책-고위면접사`,``,`머리를 다듬는 한가로움`,`외사 송쟁급속출행이동원행사`,`사행불량사`,`내 여자 색정사`,`매사영속성없음**매사 위역사**내사동요분산사`,`종교진출하면자립함**외사막힘**도적소실사`,`매사 영속성없음**명예직위자산권리사`,`종교진출하면자립함**미초희경사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재**전화위복`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`전도**관재**빈궁`,`동요불안분산사`,``,`동요불안분산사`,`빈궁**관재`,`동요불안분산사**재액해소`,`빈궁**관재전화위복`,`동요불안분산사**재액해소`,``,`동요불안분산사`,`관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`**동요불안분산사**음5월 화재주의`,`선천 숙명적 독신고독자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음**명예자산직위권리사`,`종교진출하면자립함**미초희경사`,`앙화소멸사`,`외 남자 색정사**빈궁**관재`,`사기득재사`,``,`동요충산사`,`내사 출행이동사**외사 애증파재실종사우려`,`형책사-이동사`,`내부가정손해가출사우려`,`약속된 비밀누설-죄 초래사`,`내부가정막힘`,`급문사투서헌책-불득용-실재사`,`내사 급속사`,`눈이 가려진 상-불능상해사`,``,`투서헌책-귀인면접사`,``,`머리를 다듬는 한가로움`,`외사 송쟁급속출행이동원행사`,`사행불량사`,`내 여자 색정사`,`매사영속성없음**매사 위역사**내사동요분산사`,`종교진출하면자립함**외사막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`선천 숙명적 독신고독자`,``,`음5월 화재주의`,`재액해소**전화위복`,`빈궁`,``,`관재`,``,`빈궁`,``,``,``,`빈궁*관재`,``,`전화위복`,`빈궁`,``,``,``,`전도**관재`,``,`전도**빈궁`,`도적소실사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**매사 위역사**내사동요분산사`,`종교진출하면자립함`,`매사영속성없음**외사막힘`,`종교진출하면자립함**미초희경사`,`앙화소멸사`,`외 남자 색정사`,`사기득재사`,``,`동요충산사`,`내사 출행이동사**외사애증파재실종사우려`,`형책사-이동사`,`내부가정손해가출사우려`,`약속된 비밀누설-죄 초래사`,`내부가정막힘`,`급문사투서헌책-불득용-실재사`,`내사 급속사`,`눈이 가려진 살-불능상해사`,``,`투서헌책-귀인면접사`,``,`머리를 다듬는 한가로움`,`외사 송쟁급속출행이동원행사`,`매사영속성없음**내 여자 색정사**새행불량사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`도적소실사`,`전도**빈궁`,`선천 숙명적 독신고독자`,`명예직위자산권리사`,`음5월 화재주의`,`빈궁**병재**전화위복`,``,`전도**빈궁`,``,`재액해소`,`빈궁`,`재액해소`,``,`전도`,``,`빈궁`,`전화위복`,`빈궁`,``,`전도`,``,`관재`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`사행불량사`,`내 여자 색정사`,`매사영속성없음*매사위역사**내사동요분산사`,`종교진출하면자립함`,`매사영속성없음**선천숙명적독신고독자`,`종교진출하면자립함**미초희경사**외사막힘`,`외 남자 색정사`,`앙화소멸사`,`사기득재사`,``,`동요충산사`,`내사 출행이동사**외사애증파재실종사우려`,`형책사-이동사`,`내부가정손해가출사우려`,`약속된 비밀누설-죄 초래사`,`내부가정막힘`,`급문사투서헌책-불득용-실재사`,`내사 급속사`,`눈이 가려진 상-불능상해사`,``,`투서헌책-고위면접사`,``,`머리를 다듬는 한가로움`,`외사 송쟁급속출행이동원행사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`도적소실사`,`빈궁`,`전도**관재`,`명예직위자산권리사`,`음5월 화재주의`,`전도**빈궁**병재**전화위복`,`빈궁`,`재액해소`,``,`재액해소`,`빈궁`,``,``,``,`빈궁`,``,`전화위복`,`빈궁관재`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사 송쟁급속출행이동원행사`,`고위안좌-이득경영사`,`가정불화사`,`내 여자 색정사`,`매사영속성없음**내사동요분산사`,`종교진출하면자립함`,`매사영속성없음**외사막힘`,`종교진출하면자립함**선천 숙명적 독신고독자`,`외 남자 색정사`,`말 말고 근신이 좋음`,``,`고위연회길사`,`면모 단장이 필요없는 한가로움`,`내사 출행이동사**외사애증파재실종사우려`,`음사폐호-가택안녕-이동사`,`내부가정손해가출사우려`,`매사 위역사**내부가정막힘`,`도적실세-잡음`,`내사 급속사`,`윗인 희사-아래인 수도자격`,``,`소식기다림-와도 나쁨`,``,`관직자 영전도약사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**관재`,``,`위례득죄사`,``,`전도**빈궁`,`명예직위자산권리사`,`음5월 화재주의`,`전도*빈궁**전화위복`,``,`재액해소`,``,``,`빈궁`,``,`빈궁`,`재액해소`,``,``,`전화위복`,`빈궁**병재`,`빈궁**관재`,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`관직자 영전도약사`,``,`외사 송쟁급속출행이동원행사`,`고위안좌-이득경영사`,`가정불화사`,`내 여자 색정사`,`매사영속성없음**내사동요분산사`,`종교진출하면자립함`,`매사 허성불성-세속허사자**선천숙명적독신고독자`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`외 남자 색정사`,`말 말고 근신이 좋음`,``,`고위연회길사`,`면모 단장이 필요없는 한가로움`,`내사 출행이동사**외사애증파재실종사우려`,`음사폐호-가택안녕-이동사`,`내부가정손해가출사우려`,`매사 위역사**내부가정막힘`,`도적실세-잡음`,`내사 급속사`,`윗인 희사-아래인 수도자격`,``,`소식기다림-와도 나쁨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**관재`,``,`관재`,``,`빈궁**관재`,`위례득죄사`,``,`전도**빈궁**외사막힘`,`명예직위자산권리사`,`음5월 화재주의`,`전도**빈궁**전화위복`,``,`재액해소`,``,``,`빈궁`,``,`빈궁`,`재액해소`,`폐한인`,`빈궁**병재`,`전화위복`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`소식기다림-와도 나쁨`,`관직자 영전도약사`,``,`외사 송쟁급속출행이동원행사`,`고위안좌-이득경영사`,`가정불화사`,`내 여자색정사`,`매사 허성불성-세속허사자**내사동요분산사`,`음3,4,5,6,9,12월 방문자는 종교진출하면 `,`매사 허성불성-세속허사자*외사막힘`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`외 남자 색정사`,`말 말고 근신이 좋음`,``,`고위연회길사`,`면모 단장이 필요없는 한가로움`,`내사 출행이동사**외사애증파재실종사우려`,`음사폐호-가택안녕-이동사`,`내부가정손해가출사우려`,`매사 위역사**내부가정막힘`,`도적실세-잡음`,`내사 급속사`,`윗인 희사-아래인 수도자격`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소**전화위복`,`빈궁**병재`,``,`빈궁`,``,`관재`,``,`빈궁**관재`,`위례득죄사`,`성공함`,`명예직위자산권리사`,`빈궁**선천 숙명적 독신고독자`,`음5월 화재주의`,`빈궁**전화위복`,``,``,`전도`,``,`전도**빈궁`,``,``,`빈궁`,``,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 재산에 소송이 붙어 신음     하고 있겠네?
  ⊕ 도로귀ㆍ가택두성불안, 
  ⊖ 가택귀ㆍ가택두성불안 
     때문인데, 해소하고 근신     해야 풀리겠어!
  `,`■ 가정이 시끄럽네?
  ⊕ 변사귀ㆍ도로귀, ⊖ 전사귀ㆍ가택귀 발동으로     진퇴양난이야! 
     이사도 해야겠고…
  
  ⊕⊖신변에가당신사부정이      들었네 ? 
  `,`■주색잡기가 너무 심해서
    일까지뒤죽박죽이겠어! 
  
  ⊕가택상가부정을 해소해야만    그나마 외교가 성립할거야!
  `,`■공직관련사:좋네,소원을이                 루겠어!
    일반인:내일에 후원이 말              뿐이네! 
  ⊕도로귀, 
  ⊖가택귀부터 해소하고 덤벼야    일이되지!
  
  ⊕신변 음사부정이 침범했네? 
  `,`■지금 마음에 놀라는 일은    자연히 해소되는 것이지만 
    여러일을 묶는 일은 
  ⊕여귀ㆍ가택상가부정, 
  ⊖ 전흉사귀 침범을 잡고 
     가야만이 실속이 있게 
     되겠어!
  `,`■一男二女삼각관계가         있네? 가택권리도 넘어     가게 생겼고!
  ⊕여귀, 
  ⊖전흉사귀 침범때문인데    흉조야!
  
  ⊕신변음사부정이 들었네 ? 
  `,`■ 색정사로 불화하고 후회하는     격이네? 
     모두 등을 돌렸어! 
     일이 두가지로 번져 재발
     되겠고…
  직․녹이 손실되겠어!
  
  ⊕⊖가택두성불안부정탓이네?
  `,`■一女二男삼각관계가      있네? 
    구설ㆍ저주가 심하겠어!
  ⊕음귀ㆍ산소탈, 
  ⊖신불제사가잘못돼서     자칫몸이 상하게 생겼어!
  ⊕⊖신변가당신사부정이침범      했어! 
  `,`■여러일을 하나로 묶겠는데
  ⊕일이 어둡게 가네 !
  ⊖점점 나아져 가긴 할텐데
  
  ⊕여귀, 
  ⊖전흉사귀ㆍ가택상가부정을      잡아야 뜻을 이루지!
  女:내가 나서서 남자를 적극       유혹했네?
  `,`■ 총은 좋은데 적중이 안되고     있겠네? 잘되는 것을 버     리고 욕심을 내다가는      재앙을 만나게 생겼어!
  직․녹이 손실돼 !
  ⊕여귀, 
  ⊖전흉사귀를 해소해야 겠어!
  
  ⊖신변 음사부정도 침범했네? 
  `,`■⊕도로귀, 
    ⊖가택귀ㆍ가택상가부정을      해소하면 일이잘 되겠네!
  
  女:남자를 적극 유혹해서
     내남자로 만들었네?
  `,`■금전ㆍ여인사가 있겠는데,    분수만 지키고 가면 모든    일이 잘 되겠네!
  그런데 입신사나 병문제가 발생하겠어!
  
  ⊖신변음사부정이 들었어 ! `];
  const YOGYEOL1 = [`■ 처음이나 나중이나 의도적인 사기나 모함에 걸린다. 낮정단이나 밤정단이나 재앙이 창궐하나 밤정단은 면한다.
  `,`■ 후퇴하면 귀신이고 나아가면 낭떠러지다.
  사람은 침침하고 문과 담장은 점점 허물어져 간다.
  `,`■ 서로 합하여 너는 좋고 나는 나쁘다. 처음부터 깨지는 것을 만나니 입을 다물어야 면한다.
  `,`■ 밤정단은 좋은 중에 불행이 있고 이같은 일이 두 번이 더 있게 된다. 낮정단은 제압되어 가볍고 불행중 다행함이 있을 것이다.
  `,`■ 여럿이 나를 돕지만 밤정단은 귀살로 나쁘다. 일이나 집이나 끝에 가서 흉한 손해가 있게 된다.
  `,`■ 내가 상대에게 찾아가서 서로 약조하고 투합한다. 밤정단은 귀신이 범을 타서 흉하나 낮정단은 두려울 것이 없다.
  `,`■ 내가 상대를 찾아가야 별 것이 없고 모든 일에 결과가 없다. 병과 소송사는 나쁘고 기다리는 사람도 더디다.
  `,`■ 낮정단은 좋은 듯하나 나쁘게 되고 자손과 생계문제도 나쁘다. 밤정단은 귀인이 권세가 있어 공직자는 좋으리라.
  `,`■ 보이는 귀신과 숨은 귀신이 있으니 낮밤이 다 좋지 않다. 자손이 와서 구해 주는데 여러 사람이 훼방하게 된다.
  `,`■ 잘 되는 것을 버리고 재물을 더 가지려다가는 재앙을 만난다. 낮에는 귀인이 집에 오고 밤정단은 모든 것이 깨지게 생겼다.
  `,`■ 귀인이 옆에 있으나 해를 당해 소송이나 도움받는 일은 나쁘다. 낮정단은 신축ㆍ개축ㆍ묘지등의 일은 해가 없으니 거행하라.
  `,`■ 하늘의 그물이 인ㆍ택에 내리니 어떤 변동도 액을 만난다. 집에 재물은 많으나 욕심을 부리면 병을 앓는다.`]
  const SIBO1 = [`■ 여러사람, 여러건의
  사기사건이 소송으로 비화
  되어 신음한다.
  냉정을 지켜야 하고 움직이면 해롭게 된다.
  여름과 辰ㆍ戌ㆍ丑ㆍ未月에는 적극 활약해야 좋고 기타는 소극적이 좋다.
  개혁의 뜻이 있다. 단 공직자는 현상 유지가 좋다.
  직녹의 손실이 있다.
  `,`■ 형제에게 흉동이 있다.
  가정의 구해나 사용인의
  음모가 있다. 그러나 오히려
  가족이나 아랫사람이 凶을 구한다. 가정에 이동사가 있다. 안거할 수 없는 사정이고, 남녀 개변사가 있다. 귀신 제어가 필요하다.
  `,`■ 자충수적 우환이 있다.
  문서ㆍ다툼의 건이다.
  음사ㆍ음란ㆍ주색건이 있고 바르지 못하다.
  정대하지 못하면 凶이 된다. 재앙이 있게 되면 암으로 모사해야 이롭다.
  귀신과 부정을 제거해야 이롭다.
  `,`■ 자충수적 우환이 있다.
  그러나 말전의 식상이
  제어하므로 재화는 해소
  된다. 염막귀인이 있어 시험ㆍ선거는 고위 당선된다.
  일반인은 上人의 질책을 받게 된다. 
  신규사의 뜻이 있다.
  `,`■ 저주사가 있다.
  가정에 병자가 있고
  산소탈이 있다.
  심중 놀램이 있으나 희경이고 목적사도 허실이 많다.
  피차 의심하게 된다.
  다만 밤정단은 신장살몰이 되어 유익하게 된다.
  외화 내허적 다인 다사건이다.
  `,`■ 임신사가 있다.
  가택의 권리가 넘어간다.
  一男二女의 삼각관계가 있다. 나는 상대에게 의탁한다. 귀인이 육해가 되어 예상치 못한 凶이 있게 된다.
  귀신을 제거하는 것이 급선무이다.
  `,`■ 가정에 ⊕⊖두성불안의
  부정이 들고 도적이 산다.
  女는 과부이다.
  모망사는 정반대의 양상이 된다. 이기려면 지게 되고 펴려면 굽게 된다.
  옛것을 고쳐 새것을 취하고져 하나 길조가 없다. 후회한다.
  이 과는 천문이 巳에 올라 허리에 돈이 있으나 공망으로 없다고 본다.
  `,`■ 가내 우환이 발생하고
  가장을 속이며 가인이
  원한을 품고 가해하려 한다.
  一女二男의 삼각관계가 있다. 모망사에도 부족함이 있다.
  
  ⊕병ㆍ혈광, ⊖여인의 재앙이 야기될 조짐이 있으므로 주의해야 한다. 
  밤정단 亥月占을 본다. 가정 상복이 있다.
  `,`■ 이별사가 기한다.
  가내 우환이 발생하고
  가장을 속이며 가인이
  원한을 품고 가해하려 한다. 그러나 이는 방비할 수 있게 된다. 
  서로 의혹을 품으나 나중에는 이익이 된다.
  여자가 남의 남자를 적극 유혹하여 거래한다. 귀신을 거제하면 공명을 이룬다.
  `,`■ 財ㆍ女의 구설과 다툼이
  있다. 가정에 ⊖사기부실한
  사건이 있다.
  단, 입시ㆍ선거사는 ⊕고위 당선ㆍ합격한다.
  ⊕은 부부 같이 손상입는 일을 당한다. 상대를 공격하지 말아야 한다. 적중이 안된다.
  부모 형제의 재해가 생긴다.
  `,`■ 女人ㆍ부인의 음사가 있다.
  여자가 적극적으로 남자를
  유혹하여 거래한다.
  입신사가 발현되어 고민이 된다. 어떤 일도 계획에 부응한다. 단, 피차의 의심이 있으니 이를 의식하여 처신하라.
  正月 임신사가 있다.
  `,`■ 부모의 신변에 재화가
  걱정된다. 
  금전ㆍ여인사에 의하여 이익이 크게 된다.
  上人의 원조도 있게 된다.
  분수를 알고 처신하면 복이 되고 망각하면 형책에 걸리게 된다.
  正月 임신사가 있다.`]
  const SIBO2_1 = [`■직녹, 신변외사이다.
  내방조짐 상의 : 
   어떤 일이든 처음 불성한다.낭비가 많고 불명예가 있고 손재·녀하고 부모의 재액이 있다.
  `,`■官ㆍ夫, 근친가택내사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  가정 손모가 있고 
  진퇴양난의 상이다.
  `,`■자손ㆍ극부가택반목사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官ㆍ夫,신변반목사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  신규사가 있다.
  `,`■官ㆍ夫,신변대립사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가발동한다.자손 재해 예방이 필요하다
  `,`■ 財ㆍ女,임신사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■직녹, 신변외사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.낭비가 많고 불명예가 있고 손재·녀하고 부모 형제에 재액이 있다.
  `,`■자손ㆍ극부,신변외사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官ㆍ夫,가정내사출외됨
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.자손 재해예방이 필요하다.
  `,`■財ㆍ女,신변이별사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  호가호위가 통하지 않는다.
  `,`■財ㆍ女,가정임신사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女,신변이별사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  애매한 관재에 연루되기 쉽다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/035/row-1-column-1.png`,
    `/static/images/sagwa/035/row-1-column-2.png`,
    `/static/images/sagwa/035/row-1-column-3.png`,
    `/static/images/sagwa/035/row-1-column-4.png`,
    `/static/images/sagwa/035/row-1-column-5.png`,
    `/static/images/sagwa/035/row-1-column-6.png`,
    `/static/images/sagwa/035/row-1-column-7.png`,
    `/static/images/sagwa/035/row-1-column-8.png`,
    `/static/images/sagwa/035/row-1-column-9.png`,
    `/static/images/sagwa/035/row-1-column-10.png`,
    `/static/images/sagwa/035/row-1-column-11.png`,
    `/static/images/sagwa/035/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 12궁신이 엎드려 움직이지 않으므로 복음과(伏吟課)이다. 양일은 일간상신을 사용하여 초전으로 한다. 중․말전은 형충을 취하고, 3전 사신인(巳申寅)은 원태격(元胎格)이다. 초전에서 말전에 이르며 체극하므로 여러 사람으로부터 사기당한다. 복음과로서 초전이 공망하니 냉정해야 하며, 움직이면 불길하다. 재3과․제4과에 현무가 승하면 양면도(兩面刀)라 하여 실물을 면키 어렵다.
    백법에는 ｢임신(任信)․정마(丁馬) 어느 것이나 동의(動意)를 생한다｣고 했다. 3전이 서로 극(剋)하면 남에게 사기 당한다. 즉 뭇사람들의 악마(惡魔)에 뇌동(雷同)하여 공격하는 형상이다. 비전에는 ｢중전의 신(申)에 백호가 승하면 외출하여 재앙을 만난다｣고 했다. 현태격(玄胎格)은 출산하는 일이 아니면 사물을 개혁하는 용건이다. 왕록이 신(身)에 임하면 현임(現任)의 사람은 따로 책동하지 말아야 한다. 체호형극(遞互刑剋)은 전혀 화협(和協)하려는 뜻이 없다. 움직이면 일에 차질이 생긴다.
    일귀는 순행하여 3전이 구(勾)․백(白)․사(蛇)이고, 야귀는 역행하여 3전이 주(朱)․후(后)․귀(貴)이다. 복음과의 양일은 자임격(自任格)으로서, 스스로 강하다고 자임하므로 무리하게 활동하여 스스로 재화를 초래한다. 
    이 과에서는 왕성할 때는 전진해도 좋고, 쇠할 때는 소극적으로 수세를 취하는 것이 길하다. 초전의 길장은 움직이고 흉장은 기다리는 것이 좋다. 원태격(元胎格)은 3전이 4맹신이며, 오행장생의 위(位)로서 처음으로 개화 결실하는 뜻이며, 임신하여 태를 이루는 것이다. 초전 사(巳)의 건록인수(建祿印綬)는 존장의 용건이며, 원태가 공망되면 태아가 생육되지 못한다.`
    ,
    // 2
    `  이 과는 4과 중 제2과의 묘진(卯辰)을 초전으로 하므로 원수퇴여과(元首退茹課)이다. 일간상신에 묘신이 엎드리나 진(辰)은 과거와 반대되는 동정을 나타낸다. 퇴여격은 사물에 소극적인 방침이 좋다. 진유(辰酉)는 합하고 진묘(辰卯)와 유술(酉戌)은 육해로 되어 화합하는 가운데에 장애가 발생한다. 즉 가정 내의 구해(仇害) 또는 사용인의 음모가 있다.
    초․중전이 귀살이라 해도 일지상신 음양이 모두 이것을 제어한다. 즉 사용인 또는 가족에 의하여 외적의 해를 방지한다. 백법에는 ｢화개일(華蓋日)을 복(伏)하는 것은 사람을 혼미하게 한다｣고 했다. 중귀(衆鬼)를 이루어도 제어하므로 두려울 것이 없다. 비전에는 ｢제1과․제2과는 형제효이며, 3전에 관귀가 왕성하므로 형제에 재화가 미친다｣고 했다. 또 일간 일지상신이 지합하여 위험한 가운데 구신으로 된다.
    일귀는 순행하여 3전이 주․사․귀이고, 야귀는 역행하여 3전이 구․청․공이다. 일지상신 유(酉)는 정신(丁神)이다. 정신은 순중에 둔간하여 정(丁)을 만나며, 동요가 가장 신속하다. 순정(旬丁)은 오(午)의 장간(藏干) 정(丁)을 취한다. 자(子)는 12지의 시작으로 일곱 번째 자리의 오(午)를 충(冲)하는데 충은 동요하여 안거할 수 없다. 3합의 장생을 충하면 역마로 된다. 월살(月殺)의 천난(天難)이 또한 충신(冲神)을 채용하는 것과 같은 뜻이다. 경신일(庚辛日)은 흉으로 움직이고 임계일(壬癸日)은 재동으로 된다. 일지상신에 정신이 앉아 화귀(火鬼)를 띠면 택에 화재(火災)가 일어난다. 정신과 백호의 동병(同倂)은 흉동(凶同)이 급하게 일어난다. 3전이 일귀(日鬼)를 만나고 정신이 앉으면 칼 위에 오르는 것과 같다.`
    ,
    // 3
    `  이 과는 4과 중 제1과․제2과․제4과 상하의 극이 있어 하에서 상을 적(賊)한다. 제2과 축묘(丑卯)를 사용하여 초전으로 하니 중심역간전과(重審逆間傳課)로 된다. 축해유(丑亥酉) 모두가 야간의 지(支)에 해당되므로 극음격(極陰格)이라 부른다.
    일간일지가 교차지합하니 교관(交關)․교섭․교역에서 모두 길하다. 일간상신 묘(卯)의 관귀가 앉아서 주작․구진으로 흉해가 신(身)에 임해도 다행히 일지상신의 신(申)이 있어 귀(鬼)를 제어하고, 집안 사람은 내몸을 지키므로 귀(鬼)를 두려워하지 않는다. 극음격은 모두가 유암(幽暗)의 지(地)에 있어서 모든 일이 억색(抑塞)되므로 통하지 않는다. 백법에는 ｢제귀(제鬼)의 위(位)는 양의(良醫)가 있다｣고 했다.
    축해유(丑亥酉)의 극음격이란, 축(丑)은 2양이라 하지만 양이 미약하고 아직도 음기가 넘치는 퇴여(退茹) 해유(亥酉)로 전하니 양은 더욱 줄어들고 음은 더욱 자란다. 양이 절(絶)하므로 극음이라 이름하여 음사․음란․주색․간도(奸盜)의 사건이 있다. 사(邪)하여 바르지 못하면 흉을 초래한다.
    비전에는 ｢이 과에 의하여 유(酉)의 연명의 종신감정(終身鑑定)을 시험한다｣고 하였다. 3전이 축해유(丑亥酉)이면 극음격으로서 일간상신의 묘(卯)는 목욕(沐浴)으로 되고, 말전의 유(酉)는 본인의 년명에 해당하며, 12운은 사(死)로 된다. 초전 축(丑)은 8의 수이고 묘(卯)는 6의 수이니, 육팔은 48로 48세에 수명이 다한다. 초전귀신은 화(火)의 덕을 받고 중전은 재이니 집에 자산이 있다. 중전 해수(亥水)와 말전의 유(酉)는 주(酒)라는 글자로 된다. 극음은 주색의 재앙이 있다. 따라서 주색으로 인하여 생명이 끝난다.
    또 이르기를 ｢태음이 3전 중에 있으니 음사․모사에 이롭다고｣라고 하였다. 교차지합하니 피차에 공동으로 모사하여 이루어진다. 일귀는 순행하여 3전이 귀․음․상이고, 야귀는 역행하여 공․상․음이다.`
    ,
    // 4
    `  이 과는 4과 중 제1과의 인술(寅戌)의 1상이 1하를 극하여 용신(用神)으로 되니 원수원태과(元首元胎課)이다.
    일간상신 인(寅)은 관귀효로 되어 초전을 이루나, 말전의 신(申)이 귀살을 제어하므로 흉해를 이루지 않는다. 인(寅)에 청룡이 앉으면 구관․현임관직․입시․선거에서 모두 당선되는 기쁨이 있다. 
    원태격은 모든 일에서 처음으로 싹이 트는 형상으로서 천장에 의하여 길흉을 구분해야 한다. 일귀와 야귀에서 귀신이 모두 진술(辰戌)의 지(地)에 임하는 것은 귀인이 옥(獄)에 들어간다고 하여 존장․윗사람이 번민한다.
    백법에는 ｢염막(簾幕) 귀신은 좋은 성적으로 입시에 당선하다｣고 했다. 3전은 체생하나 초전이 공망하므로 남의 천거를 받는다 해도 구실뿐이고 진정이 없다. 
    일귀는 순행하여 3전은 사․음․백이고, 야귀는 역행하여 청․상․후이다.`
    ,
    // 5
    `  이 과는 4과 중에 상하의 극이 없고 제4과 인(寅)의 천신에서 일간을 극하므로 요극호시과(遙剋蒿矢課)로 되며 인오(寅午)를 초전으로 하여 3합화국염상격(三合火局炎上格)이다.
    화(火)는 일간 무토(戊土)를 생한다. 즉 신(身)을 생하여 길조를 이룬다. 호시격은 극(剋)이 멀며, 사극(斜剋)하는 것은 허한 것에 놀랄 뿐이다. 그리고 일의 성패도 또한 허명허리(虛名虛利)로 된다. 이 과는 힘이 없기 때문에 화와 복이 모두 경하다. 일간일지상신의 육해는 의심하는 사건이 있다.
    염상격은 화체(火體)가 어두워 명(明)으로써 생하기도 쉽고 또한 꺼지기도 쉽다. 양일은 태양으로 하고 음일은 등화(燈火)로 본다. 모두가 염상(炎上)하여 위를 충하니 허(虛)가 많고 실(實)이 적다. 성(性)은 급하고 모든 일이 신속하며, 문화가 밝은 일에는 이익이 있다. 문서․통신은 유리하다. 질병은 음허혈증(陰虛血症)이다. 소송사에서는 옥석(玉石)이 판정되지 않으며 만사가 혹열(酷熱)하다.
    백법에는 ｢백호와 둔귀가 합병하면 재앙이 얕지 않으며 3합 중에 귀살이 있으면 꿀 속에 비소가 있는 것과 같다｣고 하였다. 
    비전에는 ｢오(午)가 술(戌)에 가해지고 부모효 위에 백호가 묘(墓)에 승하는 것은 조상의 묘에 흰개미가 생긴다. 혹 부모가 현존한다면 부모의 병환으로 걱정하며, 사신사기(死神死氣)가 앉으면 가장 흉하다｣고 하였다.
    귀신이 해(亥)에 임하면 귀신이 천문에 오른다. 인신사해(寅申巳亥) 위에 계신(季神)이 앉으면 신장살몰(神將殺沒)로 되어 유리유익하지 않은 것이 없다. 일귀는 역행하여3전이 후(后)․합(合)․백(白)이고, 야귀는 순행하여 3전이 백(白)․합(合)․후(后)이다.`
    ,
    // 6
    `  이 과는 4과 중에서 세 곳에는 상하의 극이 없고 제1과의 하에서 상을 적(賊)하는 자사(子巳)를 초전으로 하여 중심불비과로 된다.
    일간인 나는 일지상신에 가해지고 나는 그에게로 투합한다. 말전의 인(寅)은 일귀로 되어 백호가 승하면 두렵지만 귀살이 일지상신의 인수(印綬)로 생화하므로 흉을 이루지 않고 나의 덕(德)에 의하여 적을 자기편으로 한다. 사과불비격(四課不備格)은 바르지 못하고 사물에 불비 부족이 있다. 백호와 칠살이 합병하는 것은 끝맺는 (結絶) 데에 좋다.
    백법에는 ｢녹(祿)이 지상(支上)에 임하는 것은 권섭부정격(權攝不正格)이다｣라고 하였다. 자(子)가 사(巳)에 가해지니 인월(寅月)은 임신의 기쁨이 있다. 그러나 종국은 불길하게 된다. 즉 태신이 절(絶)에 임하여 극을 받기 때문이다. 초전에서 천을승신(天乙乘神)이 육해로 되면 사폐(四閉)하 한다. 불측한 흉해가 발생할 두려움이 있다.
    3전이 체극하는 것은 모든 일에서 협화하는 형상을 이루지 않는다. 자(子)의 재성이 공망하므로 구재의 목적은 달성하기 어려우며, 얻는다 해도 적은 재물뿐이다. 
    일귀는 순행하여 3전이 사(蛇)․공(空)․후(后)이고, 야귀는 순행하여 3전이 청(靑)․귀(貴)․백(白)이다.`
    ,
    // 7
    `  이 과는 12신이 천지상충(天地相冲)하고 극적(剋賊)이 이 있으니 반음과(返吟課)로 된다.
    주사(主事)는 반복되며, 떠나는 것은 또 오고 얻은 것은 잃는다. 남을 이기려 하면 상대방이 이기며, 오직 길장이 승하여 이것을 구할 뿐이다. 12신의 충이 극심하여 둘 다 지체하므로 펴지지 않는다. 점사(占事)는 이루어지기 어렵고 패하기 쉬운 것이 보통인데, 길장을 만나서 절반이 완수된다. 3전 제1과․제2과가 모두 공망하여 생기덕록(生氣德祿)이라 해도 길조를 이루지 않는다. 질병이나 소송 사건에서는 흉하고 출입 왕래에 장애가 있다. 4대 공망은 종교가에게 좋다. 
    백법에는 ｢공중공(空中空)이 겹치면 힘이 없고 복(伏)하므로 추구할 것이 못 된다. 개공(皆空)은 헛수고로서 효과가 없다｣고 했다. 
    일귀는 역행하여 3전이 상․주․상이고, 야귀는 순행하여 3전이 음․구․음이다. 일지상신 진(辰)에 현무(玄武)가 승하면 도적이 자기집에 있다는 징조이다. 
    비전에서는 ｢해(亥)의 수신(水神)이 진사(辰巳)에 임하면 요재(腰財)가 있다. 또 집안 사람이 실종되는 일을 방비해야 한다｣고 하였다.`
    ,
    // 8
    `  이 과는 4과 중 제4과의 신묘(申卯)가 1상에서 1하를 극하여 원수불비격(元首不備格)으로 된다. 일지는 간상에 앉아서 그는 내몸에 의존하여 비화(比和)이나, 묘가 되고 묘목(卯木)으로 극하니 개묘(開墓)로 되며 지합(支合)한다. 불비격은 모든 일에서 결함이 있다. 말전 갑(甲)은 둔귀로 되나 화(火)는 일간의 생기로 되어 큰 해를 이루지 않는다.
    백법에는 ｢둔귀에 백호가 승하면 재앙이 적지 않다｣고 하였다. 전묘(傳墓)는 묘에 드는 것인데 애(愛)와 증(憎)으로 구분해야 한다.
    비전에서는 ｢묘(卯)가 술(戌)에 가해지고 간지가 상회하는 것은 공동으로 사업하면 성취된다｣라고 하였다. 태상이 일귀(日鬼)에 가해지고 택에 들어 해월(亥月)에 가정을 정단하면 집안에 상복(喪服) 사건이 있다. 
    일귀(日鬼)가 일지상신으로 되니 관리가 가정으로 온다.
    일귀는 역행하여 3전이 용․귀․백이고, 야귀는 순행하여 3전이 동․공․후이다. 태상이 지(支)에 가해지고 일지를 극하는 것은 처의 병을 정단하면 가장 흉하다.`
    ,
    // 9
    `  이 과는 4과 중 제3과에서 1사이 1하를 극하여 초전으로 하고 원수과로 된다. 초전의 인(寅)은 명귀(明鬼)이고, 오(午)의 둔간 갑(甲)의 암귀가 있으나 일간상신의 유(酉)는 스스로 귀를 제어하므로 적을 방비하고 재해를 예방한다. 4과3전이 전부 3합되는 것은 화미격(和美格)이며. 일간의 음양은 사유축(巳酉丑)의 금(金)국, 일지의 음양은 인오술(寅午戌)의 화(和)이므로 화극금(火剋金)으로 극되는데, 이것은 상대가 나의 의지를 배반하므로 제각각의 심정이다. 그리고 교차육해(交車六害)는 서로가 의혹을 품는다. 다만 3전에 이르러 인오술(寅午戌)로 3합화국을 이루어 일간을 생하는 생기로 되므로 나중에는 나에게 이익을 주는 형세로 된다.
    원수과에서는 모든 일이 순리이다. 그 위에 3전이 내몸을 생하여 흉해를 구하니 스스로 안전하다. ｢백호와 귀살과 둔귀와 백호가 나란이 임하므로 재앙이 있을 때는 적지 않다｣라고 하였다. 살(殺)은 제(制)가 있고 또 인(印)으로 생화하니 위험을 만난다 해도 패해를 받지 않는다. 
    비전에 ｢신상(身上)을 간점할 때 3전이 인오술(寅午戌)인 염상(炎上)은 내몸을 생하여 발복하며 이름을 이룬다｣라고 하였다.
    일간음양이 금(金)국을 이루고 일지음양이 화(火)국으로 되며 3합이 극을 받으면 그 해에 여인과 이별할 일이 일어난다. 초전이 관귀효이고 체생으로 되면 남의 천거에 의하여 공명을 이루고 취직하는 일이 있다.
    일귀는 역행하여 3전이 후(后)․백(白)․합(合)이고, 일녀격(泆女格)은 자유 결혼의 징조이다. 야귀는 순행하여 3전이 백(白)․후(后)․합(合)이다.`
    ,
    // 10
    `  이 과는 4과 중에 상하의 극이 없고, 제2과 해(亥)는 일간에서 극하여 요극탄사과(遙剋彈射課)로 되며, 해신(亥申)을 사용하여 초전으로 한다. 3전이 해인사(亥寅巳)이면 원태과(元胎課)로서 체생격으로 되며, 말전의 사(巳)는 공망한다.
    초전은 일간의 재로 되고 중전은 관귀이나, 일간상신의 신(申)이 살(殺)을 제어하니 귀살도 두려울 것이 없다. 일지상신의 축(丑)은 귀신과 천공이다. 귀신이 택을 지키면 집안의 모든 재앙을 구(救)한다. 천공이 승하면 사기부실(詐欺不實)한 사건으로 재해를 입는다. 그러나 입시․구관 등은 반드시 당선된다. 초전은 일간의 역마이니 먼 곳의 재동이 있다고 본다.
    백법에서는 ｢탈상탈(脫上脫)을 만나는 것은 허사(虛詐)를 방비해야 한다｣고 하였다. 청룡이 수토(水土)장생에 해당되면 완만하게 온다. 비전에는 ｢해(亥)에 주작이 승하여 수화교쟁으로 되면 부부가 함께 손상을 입는다｣고 하였다. 
    일귀는 역행하여 3전이 주(朱)․후(后)․상(常)이며, 야귀는 순행하여 3전이 구(勾)․백(白)․음(陰)이다. 요극과는 3전 중에 금(金) 또는 토(土)가 있으면 탄환(彈丸)이 되나, 이 과에서는 탄환을 보지 못하므로 점단사는 모두 힘이 미약하고 길흉도 가볍다.`
    ,
    // 11
    `  이 과는 4과 중 제3과의 자술(子戌)에 극이 있고 이것이 초전으로 되어 중심간전과(重審間傳課)이다. 초전의 자(子)와 일간상신 미(未)는 육해로 된다. 재(財) 또는 처첩으로 인하여 해로운 일이 일어난다. 말전의 진(辰)은 천강(天罡)이므로 인(寅)을 귀호(鬼戶)로 하며 강색귀호격(罡塞鬼戶格)이다.
    축(丑)은 귀신으로서 해(亥)에 임하니 귀신이 천문에 오른다. 진술축미(辰戌丑未)가 4맹에 가해져서 신장살몰(神將殺沒)로 되어 흉을 해제하므로 모사는 이루어지지 않는 것이 없다. 후(后)․합(合)이 승하는 것은 색욕․음사의 사건이 일어나는데, 일녀격(泆女格)이기 때문이다. 일간의 재신에 백호가 승하고 중전 인(寅)에 살이 있으니 재에 의하여 재앙을 초래한다.
    백법에는 ｢육해를 보면 피차간에 서로 의심이 따른다｣고 하였다. 강색귀호(罡塞鬼戶格)는 모사에 임(任)하는데, 백법의 52법에 해당하니 인용한다. ｢이 격은 진(辰)의 천강이 인(寅)의 귀호(鬼戶)에 가해지는 것을 말한다. 중귀(衆鬼)가 노려 보는 일이 없고, 경무갑일(庚戊甲日)에 귀신이 천문에 올라 강색귀호격으로 되니 정단사는 모두 길리하다. 또 이 격은 만사가 관대하니 임의로 모사하면 성사된다.｣
    비전에는 ｢3전이 지(支)｣가 인진(寅辰)이면 3양에 향하여 바라는 바에 광명이 있다｣고 하였다. 또 자(子)에 천후(天后)가 앉고 술(戌)에 임하여 초전으로 되면 여인의 음사 사건이 일어난다. 부녀의 일을 정단하면 사통(私通)한 일이 있다고 한다.
    일귀는 역행하여 3전이 후(后)․사(蛇)․합(合)이고 일녀격(泆女格)이다. 야귀는 역행하여 3전이 백(白)․청(靑)․합(合)이다.`
    ,
    // 12
    `  이 과는 4과 중 제3과에서 해술(亥戌)이 1하가 1상을 적(賊)하여 중심과(重審課)이다. 3전 해자축(亥子丑)은 진여격이며 삼기격(三奇格)으로도 된다. 지반의 전지(前支)가 천반으로 되는 것은 나망격(羅網格)으로, 인택이 모두 향락을 누리지 못한다. 다만 조용히 지키면 자연히 길조을 초래하고, 망녕되게 움직이면 양인(羊刃)으로 되어 제3과․제4과 3전 모두에 일간의 재신이 모인다. 재가 많을 때는 과욕을 두려워하니 분수를 지켜서 재를 구한다면 자연히 재복이 온다. 백법에는 ｢모사는 망동하면 졸책으로 된다｣고 하였다. 즉 나망격이기 때문이다.
    3전이 3기인 것은 존장의 원조가 있다. 비전에서는 ｢3전 해자축(亥子丑)은 3양(三陽)으로 되어 용잠양관(龍潛陽光)이라 하여 보배를 품고 재기(財氣)가 왕성하니 이익이 대단하다｣고 하였다. 또 이르기를 ｢3전이 순재(純財)이면 부모를 극해(剋害)한다｣고 하였다. 또 자축(子丑)이 서로 가해지니 사물은 반드시 이루어진다. 다시 길장이 승하면 기쁨은 겹쳐서 발생한다. 일귀는 순행하여 3전이 음(陰)․후(后)․귀(貴)이고, 야귀는 역행하여 3전이 상(常)․백(白)․공(空)이다. 태상은 해(亥)에 승하여 내전(內戰)으로 되고 백호․천공은 흉장이다. 이 과는 길과라해도 흉장이 앉으니 길이 경미하다. 길장이 앉으면 길사가 많아진다.
    내전(內戰) 외전(外戰)에 대하여 설명한다. 천장오행에서 천반신을 극하는 것은 외전(外戰)이라 하고, 천반신에서 천장을 극하는 것은 내전이라 한다. 천장을 외(外)로 하고 천반을 내로 한다. 외전은 재앙이 밖으로부터 온다. 천신오행에서 천장오행을 극하는 것은 내전이라 불러 재앙이 안에서 일어난다. 재앙이 밖으로부터 오면 그(彼)로부터 극을 받는다. 화(禍)가 안에서 일어나면 극이 자기에게 가해지기 때문이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
