
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,4,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,3,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,2,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,1,`특별있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,12,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,11,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,2,10,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,3,9,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,8,`특별있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,5,7,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,6,6,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,5,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원조력문서 관련사`,`小`,``,``,``],
    [`부모`,`부모 관련사`,`小`,`3`,``,``],
    [`불화`,`내부가정 부부쟁투사`,`大`,`학습기`,`고위연회`,`부부상쟁`],
    [`자손불리(子孫不利)`,`가정 자손이 불리함-단, 혼인은 좋음`,`大`,``,`초대열석`,`관직이동`],
    [`동지함의(動止咸宜)`,`안방을 법도에 맞게 지키므로 가나 있으나 좋은 상`,`小`,``,``,`일반반흉`],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`2`,``,`위례득죄`],
    [`가출`,`내부 가정 손해 가출사`,`小`,`면모기`,`동지함의`,`손해가출`],
    [`공위신취(公爲新取)`,`명분있는 일을 새롭게하여 이익을 쥘 수 있는 상`,`小`,``,``,`우려`],
    [`정가금획(定可遭囚)`,`적(賊-본인에게 해악자)이 失勢하여 죄수가 되는 상 `,`大`,``,``,``],
    [`명예`,`수상(受賞)사-외화내허적`,`大`,``,``,`구설사`],
    [`직녹`,`직녹 관련사`,`小`,`1`,`도적의혹`,`문서상신`],
    [`막힘`,`외사 외교 막힘사-내부가정 화합결속사`,`小`,`절정기`,`외교막힘`,`답신지체`],
    [`최불리동(최불리동)`,`적극적으로 움직여 하는 것은 최고 불리하게 되는 상`,`大`,``,``,`단합결속`],
    [`주유가음(主有佳音)`,`좋은 소식이 오게되는 상`,`小`,``,``,``],
    [`결속`,`결속단합으로 재원 기반을 완성하나 명예직위남편 훼절 됨`,`大`,``,``,`관직생살`],
    [`색정`,`외男 내女 동시 색정사 有`,`小`,`12`,`비밀누설`,`대권쥠`],
    [`파재`,`내부 가정 파재극처사`,`小`,`과시기`,`색정사有`,`일반형벌`],
    [`후필난생(後必難生)`,`반드시 어려움이 닥치게 되는 상-관직자는 승진이 누락됨`,`大`,``,`비용과출`,`색정사有`],
    [`불검초죄(不檢招罪)`,`약속된 비밀누설로 죄에 이르는 상`,`小`,``,``,`파재극처`],
    [`금전`,`금전 상업 여인 관련사`,`小`,``,``,``],
    [`매사허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`11`,`관재질병`,`관직이동`],
    [`매사 영속성없음`,`금전사업처여인이권사는 선천적 기복재해를 겪게 됨`,`小`,`쇠퇴기`,`이전사有`,``],
    [`졸난칭의(卒難稱意)`,`하찮고 어려운 상황에 이르는 격`,`小`,``,``,``],
    [`성란관재(性亂官災)`,`호성(好性)문란으로 관재구설 또는 질병사에 이르는 상`,`大`,``,``,``],
    [`송쟁`,`외사송쟁 부부갈등 자녀문제사`,`大`,``,``,``],
    [`매사허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`10`,``,``],
    [`세속허사자`,`금전사업처여인이권사는 선천적 기복재해를 겪게 됨`,`小`,`침해기`,`총시성災`,`비상지애`],
    [`화재주의`,`가을철에 화재를 조심해야 하는 상-큰 불로는 번지지 않음 `,`小`,``,``,``],
    [`생살대권(生殺大權)`,`관직자-생살대권을 쥠. 일반인-형벌이 대흉함`,`小`,``,``,``],
    [`박관`,`직업 직위 불안사 `,`大`,``,`고위연회`,``],
    [`극부`,`남편갈등사`,`大`,`9`,`초대영석`,`굴욕반복`],
    [`막힘`,`내부 가정 막힘사`,`小`,`소외기`,`외사단합`,`가정막힘`],
    [`의혹질투(疑惑嫉妬)`,`의심과 질투심의 상-금전여자에 관련됨`,`小`,``,`외화내허`,`부부대립`],
    [`비상지애(非常之愛)`,`일반적이 아닌 특별한 애정의 상`,`小`,``,`적임`,``],
    [`결속`,`결속단합으로 재원 기반을 완성하나 명예직위남편 훼절 됨`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`8`,``,``],
    [`여인`,`여인 관련사`,`小`,`침체기`,`탄식신음`,`상하화합`],
    [`칭가취재(稱可取財)`,`재(財)를 획득하게 되는 상`,`小`,``,`병, 事미수`,`약속비밀`],
    [`생살대권(生殺大權)`,`관직자-생살대권을 쥠. 일반인-형벌이 최고로 흉함`,`大`,``,``,`누설득죄`],
    [`관사`,`관사 관련 동요불안분산사`,`小`,``,``,``],
    [`직업`,`직업 관련사-외사생업 출행이동원행사有`,`小`,`7`,`외사동요`,``],
    [`출행`,`내부 가정 출행 이동 원행사有`,`大`,`단절기`,`외사출행`,`내사출행`],
    [`불가동야(不可動也)`,`절대 적극적으로 움직이지 말아야 할 상`,`小`,``,`臥病불기`,`관재질병`],
    [`총시성재(總是成災)`,`이것 저것 모두 나쁜 것만 모이는 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`6`,`위례득죄`,`좋응소식`],
    [`남자`,`남자 관련사`,`大`,`존재기`,`외사손해`,`가해손실`],
    [`기가전진(豈可前進)`,`어찌 멈추지 않고 앞으로 나아가려만 하는가-의 상`,`小`,``,`도망우려`,``],
    [`위례득죄(違禮得罪)`,`무례함이 극성하여 죄에 이르는 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,`문서상신`,``],
    [`사업`,`사업 관련사`,`大`,`5`,`답신지체`,`사기득재`],
    [`처`,`처 관련사-내외 결속단합사-외화내허 함`,`小`,`배양기`,`결속단합`,`결속단합`],
    [`당퇴취재(當退取財)`,`현재에서 퇴보함이 큰 돈을 얻게 되는 상`,`小`,``,`외화내허`,`외화내허`],
    [`위례득죄(違禮得罪)`,`무례함이 극성하여 죄에 이르는 상`,`大`,``,`구설사有`,``],
    [`후원`,`후원조력 관련사`,`小`,``,``,``],
    [`급속`,`외사 내사 생업 가정 동시 강제급속사 有`,`小`,`4`,`왕진쇠퇴`,`身物受損`],
    [`부모`,`부모 관련사`,`大`,`개시기`,`후원조력`,`압박당함`],
    [`불리동모`,`적극적으로 움직여 도모하는 것은 불리한 상`,`大`,``,`애증화합`,``],
    [`이어퇴장(利於退藏)`,`시절을 놓친 듯하면 退해야만쌓아둔 것을 아끼게 되는 상 `,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`후원문사부모조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`금전사업여인조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`명예자산권리사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`자매색정인조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,`직업직위불안남편갈등사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`금전사업여인조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`외사생업동요불안분산사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`관사직업남자외사생업손해사로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업여인조력내외결속단합사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,`후원문서부모조력급속사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의심질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`일이 역순으로 진행됨**전화위복 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 유리하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 띄엄띄엄 진행됨**결과없이 반복되기만하는 일이나 계속하면 좋게 됨**관직자는 좌천되고 서민은 신택이 불안함 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면  피차 전의가 별로 없고 있다해도 가벼움`,0,0,`옳지 못한 일이 밝혀져 상대의 호가호위세에 천둥에 놀란 듯하나 말을 삼가하고 근신하면 현재의 두려움이 자연히 사라질 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 후로 지체시키거나 미루면 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 반발 말고 순응유화적 자세로 후응하면 반드시 결과가 유리해지는 人`,0,0,`여러 일을 하나로 묶는 단합결속사가 있음**깊은 산속으로 숨어버리고 싶은 심정임**남자가 타인의 아내를 유혹해 자기 여자처럼 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인괴 다툰다면 먼저 나서서 속결하면 유리함`,0,0,`정당하고 명분이 있는 일이므로 정당하게 속결하면 유리하고 좌고우면하거나 후로 미루면 되려 불리해지는 人 `,0,0,`옛 일은 모두 끓어짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이며 재난이 밖에서 오므로 신준해야 함`,0,0,`변동 불안하고 의지할 곳이 없으며 일이 반복되고 옛일이 재발 한 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의심질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`전화위복**상호교합함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인괴 다툰다면 먼저 나서서 속결하면 유리함`,0,0,`정당하고 명분이 있는 일이므로 정당하게 속결하면 유리하고 좌고우면하거나 후로 미루면 되려 불리해지는 人 `,0,0,`여러 일을 하나로 묶는 단합결속사가 있음-단 외화내허적임**일이 결과없이 반복되나 밝아 짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의심질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`신규사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 유리하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`관직자는 영전하나 초 중에는 실패하고 3번째에 가능함**산속으로 깊이 숨고 싶으나 여의하지 않음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화후결**만약 타인과 다툰다면 맞서지 말고 순응유화적 처신으로 사안을 후로 지체시키거나 미루면 결과가 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 반발 말고 순응유화적 자세로 후응하면 반드시 결과가 유리해지는 人`,0,0,`앞으로 전개하고 싶으나 퇴하는 것이 좋음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 동해도 중지가 됨`,0,0,`스스로 자임하고 고집이 있어 옛것을 지키고 새 기회를 기다림-국가고시자에 많음`,0,0,`신규사가 있음**소송사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인 `,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성-무력`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인 `,`아발동불안사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면-무력`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인-비용나감`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`죄고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-침체`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사-후회사 겹침`,`기사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥-침체`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월`,1,1,2,`음4월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,2,3,5,`음3월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월`,2,2,4,`음음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월`,3,2,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,2,2,4,`음6월-연기 우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월-연기우려`,1,1,2,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,3,2,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음11월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음1월`,2,2,4,`음7월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,2,2,4,`음음6월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음11월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이며 업무가 강하지 못하더리도 시종 수준이상의 성괴를 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미가 있으나 사심이 있어 업무가 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조로 업무를 결속단합시켜 재원의 기반을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 업무도 시종 실패하여 공허하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 신규사를 일으켜 동요불안하게 하나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하나 업무는 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 여러 일을 결속시켜 단합하나 외화내허하여 전체적으로 큰 손실을 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 초반에는 허무하고 중 종반에 실적을 이루나 관계 상대 쪽만 이로운 결과를 초래함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 받으나 업무 전반이 허무하고 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무 전반이 실패로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 시종 소송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`반대없음`,`이행`,`안건무력`,`進向`,`근`,`留`,`주인무력`,`정동`,``,` 정북`],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`안건무력`,`進向`,`  원`,`  留`,`주인무력`,` 정동`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`측근반대`,`이행`,`선費후合`,` 進向`,`원근왕래`,`  留`,`주인조력`,`북북서`,``,`정서`],
    [`10.11`,``,`본인제압`,``,`이권양보`,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`권리양도`,` 退背`,`  원`,`  去`,`주인조력`,`북북서`,`서북`,``],
    [`10.11`,``,``,``,`됨`,``,``,``,``,``,`세밀정단`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인반대`,`이행`,`선攻후이`,`退背`,`  원`,`  去`,`세입조력`,`정북`,`북북서`,``],
    [`10.11`,``,`제압불가`,``,`천천히`,``,``,``,`설기피로`,``,`세밀심사`,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선이후합`,` 退背`,`  원`,`  去`,`세입가해`,`북북동`,`정북`,``],
    [`10.11`,`  集`,`내부제압`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선이후攻`,`退背`,`  원`,`  去`,`세입가해`,`정동`,`북서`,``],
    [`10.11`,` 多集`,``,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선攻후合`,` 退背`,`  원`,`  去`,`세입조력`,`정동`,`북북서`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,`이후합세`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`측근반대`,`이행`,`본인무력`,`退背`,`원근왕래`,`  去`,`세입가해`,`북북서`,`정북`,``],
    [`10.11`,``,`무력함`,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`의사참고`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`이행`,`본인무력`,`退背`,`  원`,`  去`,`세입가해`,`정북`,``,`정서`],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선攻후合`,`退背`,`원근왕래`,`  去`,`세입조력`,`정북`,``,`남남서`],
    [`10.11`,``,``,``,``,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`이행`,`선損후損`,`進向`,`원근왕래`,`  留`,`무난`,`동북`,``,`북북서`],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광귀`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`특별있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`가당부정`,`성물훼손`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`도로귀`,`교툥사망`,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`변사귀`,`객사`,``,``,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,0,`틀별있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`가당부정`,`성물훼손`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`전흉사귀`,`혈광사망`,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내외급속사**도망실종사 우려`,`도적의혹근심사`,``,`안방을 지켜 가나 있으나 좋음`,``,`투서헌책-고위면담사`,`명예직녹권리사**외교막힘`,`시절을 잃었으면 퇴함이 되려 쌓는 것이 됨`,`내외부부남녀동시색정사`,`문서헌책답신지체사-구설사`,`매사 허성불성**세속허사자`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공`,`관직자 영전도약-일반인 반흉-매사영속성없음`,`종교진출하면자립함`,`내부가정막힘`,`身물손실사`,`내부가정상하화합사`,`사기득재사`,`내외출행이동사`,`좋은 소식사`,``,`事후쟁탈사-이동사`,`내외결속단합사`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,``,`전화위복`,`재액해소`,``,`빈궁`,``,`관재`,``,`무례득죄사`,``,``,`전도**빈궁`,`전화위복`,`전도`,`재액해소`,`빈궁`,`빈궁**관재**병재`,``,``,``,`매사위역사**침체경구사`,`빈궁**관재**재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`내외결속단합사**가출실종사 우려`,`도적실세-잡음`,`내외급속사**도망실종사 우려`,`도적의혹근심사`,``,`안방을 지캬 가나 있으나 좋음`,``,`투서헌책-고위면담사`,`명예직녹권리사**외교막힘`,`시절을 잃었으면 퇴함이 되려 쌓는 것이 됨`,`내외부부남녀동시색정사`,`문서헌책답신지체사-구설사`,`매사영속성없음`,`종교진출하면자립함`,`관직자 영전도약-일반인 반흉-매사영속성없음`,`종교진출하면자립함`,`내부가장막힘`,`身물손신사`,`내부가정상하화합사`,`사기득재사`,`내외출행이동사`,`좋은 소식사`,``,`事후쟁탈사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`매사위역사`,`관재`,``,`전도**빈궁`,`전화위복`,``,`전도`,``,`재액해소`,`빈궁**관재`,`재액해소`,`빈궁`,`무례득죄사`,``,`빈궁`,``,`전화위복`,`관재`,`빈궁`,``,`빈궁**관재**병재`,``,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`事후쟁탈사-이동사`,`내외결속단합사**가출실종사 우려`,`도적실세-잡음`,`내외급속사**도망실종사 우려`,`도적의혹근심사`,``,`안방을 지켜 가나 있으나 좋음`,``,`투서헌책-고위면담사`,`명예직녹권리사**외교막힘`,`시절을 잃었으면 퇴함이 되려 쌓는 것이 됨`,`내외부부남녀동시색정사`,`문서헌책답신지체사-구설사`,`매사영속성없음`,`종교진출하면자립함`,`관직자 영전도약-일반인 반흉-매사영속성없음`,`종교진출하면자립함`,`내부가정막힘`,`身물손실사`,`내부가정상하화합사`,`사기득재사`,`내외출행이동사`,`좋은 소식사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`매사위역사`,`빈궁`,``,`빈궁`,`전화위복`,``,``,``,`전도**빈궁`,``,`전도**빈궁`,``,`무례득죄사`,``,`빈궁`,``,`관재**전화위복`,`재액해소`,``,`빈궁`,``,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`내외출행이동사`,`모두 재난 뿐임`,`관재질병사-이동사`,``,`내외결속단합사`,`비밀누설관련득죄사`,`내외급속사**도망실종사 우려`,`종종걸음 반복사`,`비상지애사`,``,`사관 영전도약사`,``,`명예직녹권리사**외교막힘`,`사관 대길 생살대권쥠-일반인 형벌 대흉`,`내외부부남녀동시색정사`,`재물손실사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내부가정막힘`,`탄식신음사-병 또는 事미수사`,`내부가정상하화합사`,`고위연회길사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,`빈궁**관재`,``,`매사위역사`,``,`전도**빈궁`,``,`전화위복`,`전도`,`빈궁`,``,`재액해소`,`빈궁**병재`,`재액해소`,`관재`,`매사위역사`,`빈궁`,`병 중태-구설사`,`빈궁`,`전화위복`,``,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`고위연회길사`,`내부가정상하화합사`,`내외출행이동사`,`모두 재난 뿐임`,`관재질병사-이동사`,``,`내외결속단합사`,`비밀누설관련득죄사`,`내외급속사**도망실종사 우려`,`종종걸음 반복사`,`비상지애사`,``,`사관 영전도약사`,``,`명예직녹권리사**외교막힘`,`사관 대길 생살대권 쥠-일반인 형벙 대흉`,`내외부부남녀동시색정사`,`재물손실사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내부가정막힘`,`탄식신음사-병 또는 事 미수사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**관재**병재`,``,`관재**빈궁`,``,``,``,`빈궁`,``,`전화위복`,`관재`,``,`빈궁`,`전도**빈궁**병재`,``,`전도`,``,`매사위역사`,`빈궁`,`병 중태-구설사`,`빈궁`,`전화위복`,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`내부가정막힘`,`탄식신음사-병 또는 事 미수사`,`고위연회길사`,`내부가정상하화합사`,`내외출행이동사`,`모두 재난 뿐임`,`관재질병사-이동사`,``,`내외결속단합사`,`비밀누설관련득죄사`,`내외급속사**도망실종사 우려`,`종종걸음 반복사`,`비상지애사`,``,`사관 영전도약사`,``,`명예직녹권리사**외교막힘`,`사관 대길-생살대권 쥠-일반인 형벌 대흉`,`내외부부남녀동시색정사`,`재물손실사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,``,`전도`,`재액해소`,`빈궁**관재**병재`,`재액해소`,`빈궁**관재`,``,`전도`,`빈궁**관재`,``,`전화위복`,`관재`,``,`전도**빈궁`,``,`빈궁**병재`,``,``,`매사위역사`,`전도**빈궁`,`병 중태-구설사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음`,`종교진출하면자립함`,`내부가정막힘`,`탄식신음사-병 또는 事 미수사`,`고위연회길사`,`내부가정상하화합사`,`내외출행이동사`,`모두 재난 뿐임`,`관재질병사-이동사`,``,`내외결속단합사`,`비밀누설관련득죄사`,`내외급속사**도망실종사 우려`,`종종걸음 반복사`,`비상지애사`,``,`사관 영전도약사`,``,`명예직업권리사**외교막힘`,`사관 대길-생살대권 쥠-일반인 형벌 대흉`,`내외부부남녀동시색정사`,`재물손실사`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`병 중태-구설사`,`전도**빈궁`,`전화위복`,`전도`,``,`재액해소`,`빈궁**관재**병재`,``,`빈궁**관재`,``,`재액해소`,``,`빈궁**관재`,``,`전화위복`,``,`재액해소`,`빈궁`,``,`빈궁**병재`,``,`관재`,`매사위역사`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내부가정막힘`,`신음탄식사-병 또는 事 미수사`,`고위연회길사`,`내부가정상화화합사`,`내외출행이동사`,`모두 재난 뿐임`,`관재질병사-이동사`,``,`내외결속단합사`,`비밀누설관련득죄사`,`내외급속사**도망실종사 우려`,`종종걸음 반복사`,`비상지애사`,``,`사관 영전도약사`,``,`명예직녹권리사**외교막힘`,`사관 대길 생살대권 쥠-일반인 형벌 대흉`,`내외부부남녀동시색정사`,`재물손실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`매사위역사`,`전도**빈궁`,`병 중태 구설사`,`빈궁`,`전화위복`,``,``,``,`재액해소`,`빈궁**관재**병재`,`재액해소`,`빈궁**관재`,``,``,``,`빈궁`,`전화위복`,``,`전도**빈궁`,``,`빈궁**관재**병재`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`내외부부남녀동시색정사`,`재물손실사`,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`내부가정막힘`,`탄식신음사-병 또는 事 미수사`,`고위연회길사`,`내부가정상하화합사`,`내외출행이동사`,`모두 재난 뿐임`,`관재질병사-이동사`,``,`내외결속단합사`,`비밀누설관련득죄사`,`내외급속사**도망실종사 우려`,`종종걸음 반복사`,`비상지애사`,``,`사관 영전도약사`,``,`명예직녹권리사**외교막힘`,`사관 대길 생살대권 쥠-일반인 형벌 대흉`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`매사위역사`,`빈궁`,`병 중태-구설사`,`빈궁`,``,``,`재액해소`,`전화위복`,`빈궁**병재**병재`,``,`빈궁**관재`,``,``,``,`빈궁`,``,``,`전화위복`,`빈궁`,``,`전도**빈궁**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`명예직녹권리사**외교막힘`,`시절을 잃었으면 퇴함이 되려 쌓는 것이 됨`,`내외부부남녀동시색정사`,`문서헌책답신지체사-구설사`,`무례득죄사`,``,`관직자 여전도약-일반인 반흉-매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**내부가정막힘**身물손실사`,`종교진출하면자립함`,`사기득재사`,`내부가정상하화합사`,`내외출행이동사`,`좋은 소식사`,``,`事후쟁탈사-이동사`,`내외결속단합사`,`도적실세-잡음`,`내외급속사**도망실종사 우려`,`도적의혹근심사`,``,`안방을 지켜 가나 있으나 좋음`,``,`투서헌책-고위면담사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁`,`재액해소`,`관재`,``,``,`빈궁`,``,`전화위복`,``,`빈궁`,``,``,`빈궁**병재`,``,`관재`,`매사위역사`,`빈궁`,``,`전도**빈궁`,`전도`,`전화위복`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`투서헌책-고위면담사`,`명예직녹권리사**외교막힘`,`시절을 앓었으면 퇴함이 되려 쌓는 것이 됨`,`내외부부남녀동시색정사`,`문서헌책답신지체사-구설사`,`무례득죄사`,``,`관직자 영전도약-일반인 반흉-매사영속성없음`,`종교진출하면 자립함`,`매사 허성불성**세속허사자*내부가정막힘`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`사기득재사`,`내부가정상하화합사`,`내외출행이동사`,`좋은 소식사`,``,`事후쟁탈사-이동사`,`내외결속단합사`,`도적실세-잡음`,`내외급속사**도망실종사 우려`,`도적의혹근심사`,``,`안방을 지켜 가나 있으나 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`재액해소`,`빈궁**관재`,`재액해소`,`빈궁`,``,``,`빈궁`,``,`身물손실사`,``,``,`빈궁`,``,`관재**빈궁**병재`,``,`관재`,`매사위역사`,`빈궁`,`폐한인`,`전도**빈궁`,`전화위복`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`전도`,`안방을 지켜 가나 있으나 좋음`,``,`투서헌책-고위면담사`,`명예직녹권리사**외교막힘`,`시절을 잃었으면 퇴함이 되려 쌓는 것이 됨`,`내외부부남녀동시색정사`,`문서헌책답신지체사-구설사`,``,`무례득죄사`,`매사 허성불성**세속허사자`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공`,`매사 허성불성**세속허사자**내부가정막힘`,`음1,2,4,5월 방문자는 종교진출로 성공함`,`사기득재사`,`내부가정상하화합사`,`내외출행이동사`,`좋은 소식사`,``,`事후쟁탈사-이동사`,`내외결속단합사`,`도적실세-잡음`,`내외급속사**도망실종사 우려`,`도적의혹근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,``,``,`빈궁`,``,`빈궁`,``,`전도`,``,`관직자영전도약-일반인 반흉`,`빈궁`,`身물손실사`,`전화위복`,`전도**빈궁**병재`,``,`전화위복`,`빈궁**관재**병재`,`전화위복`,`관재`,`매사위역사`,`전도**빈궁`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 내것 큰 줄 모르고 움직이      다가는 재앙을 당할거야!
  송사가 벌어졌어 !
⊕ 여귀ㆍ가택음사부정, 
⊖전ㆍ흉사귀를 해소하고 
  지키고 있으면 복이 되겠어!

⊖신변음사부정이 들었어!
`,`■가정에 구설ㆍ다툼이 많겠어! 의심․질투건도 있겠고!
일도 두갈래로 결정이 안되고 있네 ! 
후퇴하는 처세를 하면 걱정이 다 풀리게 되네!
`,`■ 일이 거꾸로 가네?
⊕여귀ㆍ가당신사부정,
⊖전흉사귀ㆍ가택가당신사     부정을 해소시키든가 이사    하든가 해야 관재도 풀리고    집안이 밝아져 !

⊕신변상가부정이 들었어 !
`,`■법적 문제가 한두 건이     아닌데 
⊕여귀ㆍ대문수리, 
⊖전흉사귀ㆍ문서구설을 
  해소해야만 새로 꾸미는     일이 제대로 되겠어!
`,`■⊕가택상가부정, 
  ⊖여자로 인해 가택의 
    권리가 넘어가네? 
 돈ㆍ여자의 절제가 없으면
 형벌이 따르겠어!
男：남의 여자를 유혹해 색       정사를 벌이고 있네?
女：부부이별(상실)하게생겼네?      기도를 잘해봐!
⊕⊖신변가당신사부정침범했어!
`,`■ 부부이별의 징조가 보이     는데 
⊕변사귀, 
⊖전사귀를 몰아내야해! 
  모든 옛 일ㆍ인연이 끝나고    있어!
`,`■모든 일이 반복ㆍ무상이야!    부부이심으로 색정불화하고    있어!
⊕도로귀, 
⊖가택귀 해소를 해야 의지처가    생기지!

⊕신변음사부정이 들었네 ?
`,`■ 가정돈․여자가 문제네? 
   의심․질투로이럴까저럴까     마음을 못 정하고있네? 
   
  가까운 사람, 친한 사람     으로 결정하면 풀려!
`,`■  집안에 
⊕⊖가택가당신사부정이 들어      외화내빈이겠어! 
男：일만 반복되고 결실이
    없고 직위도 상실되겠어!
女：남의 남자를 유혹했네!

⊖신변상가부정이 들었어!
`,`■ ⊕도로귀, 
  ⊖가택귀를 잡지 않으면       의심ㆍ질투건이 소송사로       번지겠어!
두가닥의 일이 결정이 안
되고 있네?
가정에 상사(喪事)가 나게
생겼네 !
`,`■ ⊕여귀, 
   ⊖전흉사귀ㆍ가택상가부       정이 들었네? 
     그러니 관재가 보이고 
男：⊕남의 부인을 유혹해 거래하지!
女：남자 인연이 짧게 생겼네!

⊕⊖신변에 가당신사부정이      침범 했네 ?
`,`■男：이권과 직위를 생겼네?
  女：별리ㆍ고독한상이야!
      일이고 사람이고 모두   등을 돌렸어!
속임을 당하고 자손이 凶이 
있겠어 !`];
  const YOGYEOL1 = [`■ 지금 가진 것을 지키지 않고 버리게 되면 빈 골짜기가 되리라. 귀신이 범을 타고오니 끝에 가서 재앙이 있겠다.
  `,`■ 후퇴하면 살곳을 만나고 전진하면 빈것 뿐이라. 밤정단은 악살에 범이 붙고 좋은 중에 재앙이 생긴다.
  `,`■ 밤정단도 불행이 있고 낮정단도 역시 흉하다.
  모두 양이니 공적이라야 좋고 새롭게 혁신하면 더욱 좋다.
  `,`■ 화살을 쏘아도 적중이 안된다. 귀신이 말을 타고 범과 함께 하니 낮정단은 재병송사를 주의하라.
  `,`■ 사람은 기우는데 집만 좋다. 타인이 거슬리나 따를 수밖에 없다. 
  소송은 좋으나 손해가 나고 병점은 허약을 벗어나야 한다.
  `,`■ 도적이 재물을 노리니 어찌 손해와 실물이 없겠는가. 여행하면 재앙이 있겠으니 나쁜 일을 거듭 만나리라.
  `,`■ 사람과 집이 상처를 입게 생겼으니 오나가나 마찬가지이다. 버리기도 가지기도 어렵고 너와나 모두 좋지 않다.
  `,`■ 서로 동업을 하면 둘다 이롭지 않다. 밤정단은 귀인이 해가되니 소송은 이기는데 어려움이 많다.
  `,`■ 손해보는 것이 계속 되니 가을ㆍ겨울 점은 더 나쁘다. 사람은 번번히 손해를 보고 집은 연이어 도둑맞는다.
  `,`■ 손해나고 일도 꺠지니 집안이 소란하고 편치가 않으나 처음 시작은 어려워도 나중에는 매우 좋으리라.
  `,`■ 돈 벌려고 하다가 손해와 재앙이 따른다.
  귀인이 천문에 앉았으니 집 짓거나 묘지사 등은 뜻대로 하라.
  `,`■ 나와 네가 깍지끼듯 손해와 허망한 것을 끼고 있다. 나아가고자 하나 가지 못하고 도난과 손해가 여러번이다.`]
  const SIBO1 = [`■ 내게 좋은 직녹을 지켜야
  복이 된다. 자칫 움직이면
  재앙이 된다.
  재산건 송사가 있다. 새로운 일을 할 뜻이 있으나 포기가 좋다.
  관공직자는 영전이 신속하나 일반인은 재해가 있다. 근신하면 화환은 면하게 된다.
  속는일이 있고 자손에 凶이 기한다.
  `,`■ 여자ㆍ집안 내부의
  재화가 있다.
  의심과 질투건이다.
  일이 두갈래로 나뉘어 결정이 어렵다. 서로에게 재해가 된다. 저주를 받 고있다. 그러나 두가지는 안되고 어느 하나로 선택해야만 길행을 맞는다.
  속는일이 있고 자손에 凶이 기한다.
  `,`■ 가정에 가당신사 부정
  이 들었다. 이권과
  금전ㆍ여자의 손실이
  야기 된다.
  일이 반복되기만 하고 결실이 없다.
  이사를 하든가, 기도ㆍ치성을 하면 효과가 있게 된다. 공공적으로 나가면 길하고 사적은 나쁘다.
  `,`■ 가정에 손모가 많고
  다툼ㆍ시비구설이 잦다.
  2~3개의 일이 번져 심중
  놀램이 크다. 소송사이다.
  그러나 근신하면 풀린다.
  후원사의 이익건이 있고 반드시 소득하게 된다.
  병을 주의해야 한다.
  속는일이 있고 자손에 凶이 기한다.
  `,`■ 남자는 여자ㆍ가정에 헌신한다.
  여자와 남자의 교합건이 있다.
  부부가 음란하다.
  특히 남자는 타인의 부인을 유혹하여 자기 부인처럼 쓴다. 무슨 일이든 호흡을 길게 하는 자세가 필요하다.
  `,`■ 정치인이나 관리는
  탄핵받는다.
  점자의 몸이 상한다.
  지금까지의 종사업이나 인연은 모두 끝나게 된다.
  교섭사는 피차 속임수가 있다. 처음ㆍ기선제압ㆍ남자가 나서야 좋다.
  十月 임신사가 있다.
  이권상실․금전․여자의 凶이 기한다.
  `,`■ 자충수적 우환이 있다.
  만사 동요되고 불안하며
  부부가 똑같이 외정을 갖고 있다.
  둘이 다 의지처가 없다.
  새로운 일과 인연을 생각하나 유익함이 없게 된다.
  심중 후회심이 있다.
  `,`■ 가정 내부ㆍ여자 때문에
  의심ㆍ질투사가 일어
  결정을 못하고 있다.
  소송사건으로 비화될 수 있다. 일이 두가지로 번졌다. 한가지로 가면 가정은 번창하고 사람도 흥한다.
  속는일이 있고 자손에 凶이 기한다.
  `,`■ 여자는 二夫가 있어
  사통분주하다. 일이 
  모양새는 좋으나 실속은 없다.
  반드시 퇴직, 또는 부부이별하게 된다. 
  일도 반복무상하다. 여자가 남자에게 헌신적이나 손해이다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  ⊕연인, ⊖주석에서 속는 일이 있게 된다. 가정ㆍ생업의 이동이 있다. 가정 내부ㆍ여자에 의해 피로가 크다. 선택의 기로이다.
  집안에 죽을 사람이 있다.
  十月 임신사가 있다.
  속임을 당한다.
  `,`■ 이권을 상실한다.
  가내 우환이 있고 가장을
  속이며 가인이 원한을 품고
  가해하려 한다.
  가정ㆍ생업의 이동ㆍ별리ㆍ원행사가 있다.
  ⊖병자가 있다. 女는 과부이다. 男은 타인의 부인을 유혹하여 거래한다.
  `,`■ 진퇴양난이다.
  이권을 상실한다.
  財ㆍ女子문제가 여의치 않다.
  손모된다. 女는 과부이다.
  앞으로 나아가는 형세이나 물러나야만 좋다. 
  모든 계획을 졸책이 되고 만다.`]
  const SIBO2_1 = [`■직녹, 신변외사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.
  송쟁사가 있다.
  `,`■가정남녀상쟁건이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■금전ㆍ여인·해산·손실건이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다.
  人宅 쇠퇴의 상이다.
  `,`■신변동요이심외사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■가정단합사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다.
  男직위상실하고 
  女부부이별한다.
  `,`■官･夫,이동·다툼건이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■직녹, 신변외사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.
  색정불화 한다.
  `,`■가택남녀동요건이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■직녹외사건이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가발동한다.男직위상실하고女부부이별한다.
  `,`■부부이심동요건이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■재ㆍ처 상실건이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 재액이 있다.
  `,`■재ㆍ처 상실후회건이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 재액이 있다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/031/row-1-column-1.png`,
    `/static/images/sagwa/031/row-1-column-2.png`,
    `/static/images/sagwa/031/row-1-column-3.png`,
    `/static/images/sagwa/031/row-1-column-4.png`,
    `/static/images/sagwa/031/row-1-column-5.png`,
    `/static/images/sagwa/031/row-1-column-6.png`,
    `/static/images/sagwa/031/row-1-column-7.png`,
    `/static/images/sagwa/031/row-1-column-8.png`,
    `/static/images/sagwa/031/row-1-column-9.png`,
    `/static/images/sagwa/031/row-1-column-10.png`,
    `/static/images/sagwa/031/row-1-column-11.png`,
    `/static/images/sagwa/031/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 천지신(天地神)이 모두 본궁에 엎드려 움직이지 않으며 4과중 극이 없다. 양일이므로 일간상신을 취하여 초전(初傳)으로 하고, 중․말전은 지형으로써 복음자임과(伏吟自任課)이고 원태격(元胎格)이다.
    갑(甲)의 녹(祿)은 인(寅)이고, 건록(建祿)이 신(身)에 임하는 것은 스스로 지켜서 복을 받는다. 중전이 공망하니 단교(斷橋) 공망이라 하여 중도에 좌절될 염려가 있다. 말전 신(申)은 일귀(日鬼)의 역마로 되어 움직이면 재앙을 초래하고, 덕록이 신(身)을 부조하므로 자중(自重)하면 도리어 길이 된다. 복음(伏吟)은 엎드려 펴지지 못하는 형상이다. 그러나 고요한 가운데 움직임을 나타내는 모사(謀事)나 그 밖의 일에서는 요절공망(腰折空亡)하여 중도에 방해가 일어난다. 말전 일귀(日鬼)에 백호가 승하면 최관부(催官府)라 하여 사관 또는 영전은 신속하지만 보통사람은 재해를 받는다. 그러나 일지음양의 오(午)에서 제어하여 구신으로 되니 화환은 면하게 된다. 백법에 이르기를 ｢신임정마(信任丁馬)는 동의(動意)를 생한다. 왕록이 신(身)에 임하면 무리해서 망동하지 말아야 하며, 앉아서 길조를 불러온다. 백호가 간귀(干鬼)에 임하면 흉이 빠르다｣라고 하였다. 비전에 이르기를 ｢갑일(甲日) 복음과는 녹(祿)이 있고 마(馬)가 있으며 3전이 파(破) 중에 지합하므로 흉한 가운데 길함이 있다. 화복(禍福)이 교차되어 나타나니 천장(天將)에 주의해야 한다｣라고 하였다.
    일귀는 역행하여 3전이 청(靑)․주(朱)․후(后)이고, 야귀는 순행하여 3전이 사(蛇)․구(勾)․백(白)이다. 백호의 귀효(鬼爻)는 가장 두려워해야 할 것이나 다행히 지상(支上) 오화(午火)는 이것을 제어하므로 위험한 가운데 구제된다. 병을 정단하면 양의를 얻는다.`
    ,
    // 2
    `  이 과는 제1과․제2과에 음양의 극이 있고 오늘은 양일이니 양의 극인 자축(子丑)을 초전으로 하여 지일비용과(知一比用課)로 된다. 3전은 말전에서부터 술해자(戌亥子)로 되어 퇴여격(退茹格)이다. 초전은 일간을 생하고 중전은 장생이며, 말전은 일간의 재신으로 되어 길행(吉行)을 암시한다.
    일간일지가 교차육해(交差六害)로 되어 서로에게 재해(災害)가 일어난다. 퇴여격은 전진하는 것은 좋지 않고 현상을 유지하는 것이 좋다. 초전과 일간상신의 자축(子丑)은 지합되므로 사관(士官)․결혼은 모두 좋은 징조이다. 비용과(比用課)의 도액(度厄)․무록(無祿)․절사(絶嗣)격은 일간의 비(比)를 사용한다. 즉 양일은 양과(陽課)의 1에 멈추고, 음일은 음과의 1에 멈추므로 지일(知一)이라 이름한 것이다.
    사물은 두 갈래에 걸쳐도 오로지 하나로 돌아간다. 소송 쟁의는 화해하는 것이 좋고 모사(謀事)하면 주인이 이득을 본다. 찾는 사람이 나 잃은 물건은 가까이에서 찾아야 한다. 장사 및 거래는 동업자와 같이 하며, 하나를 취하고 둘을 취하지 않으므로 소원(疎遠)한 것을 버리고 근친하게 해야 한다. 위에서 극하여 용신(用神)으로 되는 것은 벗에 의한 것이고 외사(外事)에 속하며. 하에서 극하여  전으로 되는 것은 처첩(妻妾)으로부터 재화(災禍)를 초래하며 내사(內事)를 주관한다.
    백법에 이르기를 ｢둔귀(遁鬼)와 백호가 승하면 재화가 적지 않다.｣고 하였다. 비전에 이르기를 ｢초전은 일간을 생하고 일지 오(午)를 극한다. 이와 같은 사람은 왕해도 바른 택(宅)이 없다｣고 하였다. 일귀가 간상에 임하면 시험에 성공한다. 자해술(子亥戌)은 중음(重陰)이라 하여 무슨 일에서나 수장(收藏)하는 것이 좋다. 또 상담하는 일에는 나서지 말아야 한다. 자(子)는 순기발용(旬奇發用)으로 되어 간상 축(丑)과 지합하므로 기우(奇遇)를 만난다.
    일귀는 역행하여 3전이 백(白)․상(常)․현(玄)이고, 야귀는 순행하여 3전이 후(后)․음(陰)․현(玄)이다. 3전 술(戌)의 재에 현무가 승하여 재물의 도난이나 실탈이 있다.`
    ,
    // 3
    `  이 과는 제2과․제4과에 같은 양(陽)의 극이 있고, 지일비용(知一比用)을 취할 수 없으니 섭해과(涉害課)로 된다. 맹상(孟上)을 취하는 것이 원칙이나 맹신(孟神)이 없으므로 중신(仲神)의 극인 술자(戌子)를 초전으로 하여 역간전격(逆間傳格)으로 된다.
    2과에 술(戌)이 있고 술(戌)을 초전으로 하면 참관격(斬關格)이다. 간상 자(子)는 경(庚)의 둔귀가 있고, 중전 신(申)은 귀살(鬼殺)로 되나, 오(午)의 탈기가 있어 구신의 작용을 하므로 흉을 만나지 않는다. 4과3전이 육양(六陽)의 자리이니 공용(公用)에 길하고 사모(私謀)하는 일에서는 이익으로 되지 않는다. 술신(戌申)과 오(午)의 양이 되는 것은 암(暗)에서 명(明)으로 되는 것이므로 옛 것을 고쳐 새롭게 하는 것이 좋다.
    섭해과는 풍상을 만나 고생을 맛본 뒤에 길행이 온다. 맹상승신(孟上乘神)이 초전으로 되는 것은 견기격(見機格)이고, 이 과와 같이 중상승신(仲上乘神)이 초전으로 되는 것으로 하는 것은 찰미격(察微格)이라 부른다. 은미한 곳에서 밝음을 살피고 움직여야 하며, 조급하게 움직이고 나아갈 때는 화환(禍患)이 발생된다.
    이 과는 지일과(知一課)와 비슷하면서 섭해(涉害)의 형상이다. 모든 일은 정체되고, 병은 속히 치료되기 어려우며, 태산에서는 태를 상한다. 무슨 일에서나 먼저는 곤란하고 후에 길하다. 간기(干奇)가 일(日)에 앉으므로 흉이 길로 전변한다. 초전의 재신을 말전에서 생부(生扶)하는 것은 남의 협력을 얻어서 재물을 얻는다. 귀신이 묘유(卯酉)에 임하면 기쁨 가운데 음소(陰小)한 재화가 있으니 신(神)에게 기도하면 좋다. 백법에는 ｢육양(六陽)의 수가 많으면 공용(公用)에 길하고 지(支)의 분재(墳財)는 여행 중에 체류하는 일이 있다｣고 했다. 비전에 이르기를 ｢술신오(戌申午)는 패려격(悖戾格)이라 부른다｣고 하였다. 즉 술(戌)과 오(午)의 중간에 귀살이 있고 생이 극으로 되므로 이런 이름이 붙었는데 후회의 정의가 생긴다. 술(戌)의 재에 현무가 앉아 재의 분실이나 도난사건이 있다. 사물은 작게 이루어지고 흩어지는 것이 크다. 일귀는 역행하여 3전이 현(玄)․후(后)․사(蛇)이고, 야귀는 순행하여 3전이 현(玄)․백(白)․청(靑)이다.`
    ,
    // 4
    `  이 과는 4과 중에 상하의 극이 없다. 제2과의 천신이 신(申)에서 일간을 사극(斜剋)하므로 요극호시과(遙剋蒿矢課)로 된다. 요극은 길흉이 모두 경하지만 3전에 금(金) 또는 토(土)가 있으면 흉한 힘이 증가된다. 초전의 신금(申金)은 화살이 되어 쏘므로 길을 이루지 못하고, 일귀(日鬼)로 되어 역마를 겸하니 화환(禍患)은 깊고 빠르게 움직인다. 백호가 승하면 더욱 흉하니 흉을 피하기에 힘써야 한다. 흉하지만 다행히 중․말전이 공망으로 되어 요절공망(腰折)空亡)은 절반이 해소되며, 사관(士官)은 불길하다.
    백법에 ｢주객이 뜻이 합쳐지지 않는 것은 지형상(支刑上)에 있다｣고 하였다. 
    일지상신(日支上神) 묘(卯)는 순미(旬尾)의 지(支)이므로 폐구격(閉口格)에 해당하여 무슨 일에서나 두 가닥의 사건이다. 비전에는 ｢인(寅)의 위에 해(亥)가 승하여 태상과 나란하면 일간의 장생으로 되어 기다리는 사람은 반드시 온다｣라고 했다. 또 결혼은 이루어진다. 
    일귀는 역행하여 3전이 후(后)․주(朱)․청(靑)이고, 야귀는 순행하여 3전이 백(白)․구(勾)․사(蛇)이다.`
    ,
    // 5
    `  이 과는 4과이나 3과뿐이며 술인(戌寅)의 극을 취하여 초전으로 하니 중심염상격(重審炎上格)이다. 술(戌)의 간상신이 초전으로 되는 것은 참관격(斬關格)이다.
    3전이 화국(火局)이면서 일간에서 탈기(脫氣)되면 사람이 쇠퇴되나. 일지가 3전과 비화(比和)하여 왕성하면 가택이 왕성하다. 4과3전이 전부 3합하면 불비(不備)라 할지라도 경하다. 3전은 4과 모두가 인오술(寅午戌)의 3방회환(三方廻環)으로 되면 사물에 왕래기복이 있다고 본다. 백법에는 ｢일지상신이 일간의 건록이면 권섭부정(權攝不正)으로 된다｣고  했다. 육합에는 천후가 앉으니 이 점혼은 중매인이 없이 결합된다. 일간기궁(日干寄宮) 寅이 일지상신에 가해지고 일지를 생하면 내 몸이 그에게로 가서 상대방을 유익하게 한다. 서양자(壻養子)를 맞을 때는 좋은 남편을 얻는다.
    일귀는 순행하여 3전이 합(合)․후(后)․백(白)이고, 야귀는 역행하여 3전이 합(合)․백(白)․후(后)이다. 일간에서 술(戌)을 극하여 재(財)로 되고 육합의 목장(木將)이 또 술(戌)을 극하여 협극(夾剋)하므로, 이 재물은 자유롭게 얻지 못한다. 말전에서 초전을 극하는 것은 일간설탈이라도 화가 경하다. 말전이 덕록(德祿)의 신을 띠면 이 역시 흉이 경하다. 일간상신이 초전으로 되고 말전 인(寅)은 일지로 돌아가니 시작과 결과가 여일하다.`
    ,
    // 6
    `  이 과는 4과 중 제1과 유인(酉寅)이 1상에서 1하를 극하여 원수과(元首課)로 된다. 제2과 진(辰)은 일간의 재로 되고 현무에 승하며 중전으로 된다.
    재(財)는 소모․분실․도난의 징조가 있으나 공망하므로 흉을 보고도 흉을 만나지 않는다. 말전의 해(亥)는 일간의 장생이지만 공묘(空墓)로 되어 이익이 없다. 일간상신 관귀효(官鬼爻)에 정신(丁神)이 승하여 움직임을 주관한다. 원수과는 남자에 의하여 먼저 거행하면 이익이 된다.
    3전이 유진해(酉辰亥)인 것은 모두가 자형(自刑)의 支이다. 진유(辰酉)가 지합하여 화호(和好)한 가운데 스스로 형쟁(刑爭)하는 원인을 만든다. 중․말전의 공망은 전(傳)에 돌지 않으므로 초시(初時)를 생각하고 초전의 일신(一神)으로 길흉을 결정한다. 일간일지가 교차상극하는 것은 해리격(解離格)이다.
    공망은 모산(謀散)의 신이다. 초전의 공망은 참수(斬首)라 부르며, 중전의 공망은 요절(腰折), 말전의 공망은 단족(斷足)이라 한다. 그리고 공망은 천중살(天中殺)이라고도 하는데, 공허하며 변화의 기(機)를 이룬다. 첫째로는 공망을 꺼리고 둘째로는 흉장을 두려워한다. 공망은 순후(旬後)의 두 지(支)에 해당되며 공망되는 곳에서 일간을 극하면 스스로 모산(謀散)한다. 일지를 극하면 가택 중에 암손(暗損)이 있다. 흉장은 공망을 즐기고 길장은 공망을 무서워하여 길을 이루지 않는다. 녹(祿)의 공망은 모사(謀事)를 이루지 못한다. 역마의 공망은 옛 것을 버리고 새로운 것을 써야 한다. 해리격(解離格)은 부처가 불화하거나 또는 이별할 염려가 있다. 교섭하는 일은 피차간에 서로 속인다.
    일귀는 순행하여 3전이 주(朱)․현(玄)․구(勾)이고, 야귀는 역행하여 3전이 구(勾)․현(玄)․주(朱)이다. 일간 또는 일지상신에 묘유(卯酉)가 가해지면 떠난 자는 만나고 만난 자는 떠난다.`
    ,
    // 7
    `  이 과는 천지반이 대궁(對宮)에 있으므로 반음과(返吟課)이다. 하에서 극하는 인신(寅申)을 취하여 초전으로 하니 원태무의격(元胎無依格)이다.
    일간일지가 모두 극상(剋傷)되므로 피차간에 모두 손상된다. 그리고 극의 지(地)에 앉아서 안태할 수가 없다. 무의격은 반복되며 불안하고 인정이 화합되지 못한다. 3전은 녹마가 모두 움직여 유위(有爲)한 것 같으나 절지(絶地)에 앉아 있다. 간지가 모두 다 극으로 되면 문을 닫고 조용히 스스로 지킬 때에는 재액을 면한다.
    백법에 이르기를 ｢간지가 전부 상하는 것은 양쪽이 손상된다｣고 하였다. 비전에 이르기를 ｢양일반일과(陽日返吟課)는 덕을 상(喪)하고 녹을 끊는다｣고 하였다. 반음(返吟)의 4맹은 사절원태격(死絶元胎格)으로 된다.
    신(申)에 청신(靑神)이 앉는 것은 자손에게 재물의 손실이 있다고 한다. 이 과는 질병을 정단하면 소송 사건의 흉사가 겹쳐 설상가상격이다.
    일귀는 순행하여 3전이 백(白)․사(蛇)․백(白)이고, 야귀는 역행하여 3전이 후(后)․청(靑)․후(后)이다. 사호(蛇虎)가 3전 중에 드는 것은 흉화가 신속하게 발생한다. 청룡이 관귀로 되니 시험에서는 당선된다. 육임(六壬)의 간법(看法)에서는 흉신이 일간일지를 형해(刑害)할 때에는 재환(災患)이 계속된다고 본다. 길장이 과전에 교차되어 앉으면 기쁜 일이 중첩되며, 흉신도 화합하면 재화라 하더라도 깊지 않다. 길장이 형극이 되면 길조라 해도 전미(全美)하지 못하다.
    일간과 일지상신은 그와 나를 구분한다. 신장(神將)은 존비(尊卑)의 차별을 보인다. 일간이 극되면 재화가 자기에게 미치고, 지(支)가 극되면 가택에 재화가 있다. 초전과 비화(比和)․상합하는 것은 근친의 사건이다. 합쳐지지 않는 것은 소원하고, 왕하는 것은 날마다 새롭고 달마다 번영한다. 쇠퇴도 역시 점차로 퇴폐해 간다.`
    ,
    // 8
    `  이 과는 4과가 모두 상하로 극하며 하에서 상을 극한다. 제2과 자미(子未)의 양비(陽比)를 취하여 초전으로 하는 지일비용격(知一比用格)이며 일간일지가 상하로 지합하니 교차격(交車格)이다. 
    일간상신 미(未)는 묘(墓)에 엎드리고 초전과 자(子)와 미(未)는 육해(六害)로 되며 지합하니 은혜로 되고 자(子)와 미(未)는 해(害)로 된다. 3전 묘사술(卯巳戌)은 주인격(鑄印格)이지만 공망을 만나므로 주인(鑄印)을 이루지 못한다. 지일격에서는 일이 두 갈래로 겁쳐 주모(主謀)에 이롭고, 찾는 사람이나 잃은 물건은 모두 가까운 곳에서 찾아야 한다. 장사와 거래는 모두 동업해야 한다. 하에서 상을 극하는 것은 질투에 의한 사건이 생기거나 의혹되는 일이 생기며, 모두가 여성에 의한 것이거나 혹은 내부에서 일어난다.
    백법에 이르기를 ｢귀신이 육해(六害)로 되면 바른 일이라도 소송사건에서 곡단(曲斷)을 만난다｣고 하였다. 전후인종격(前後引從格)은 의혹되는 사건을 해소하고 기꺼운 기운이 발생한다. 이 과는 일지의 전지(前支) 미(未)는 초전 지반으로 되고 말전 지반의 사(巳)는 일지의 후에 해당한다. 가정은 번창하고 사람도 성한다. 전후인종격(前後引從格)은 백법의 제1법에 해당된다. 3기(奇)가 3전으로 되어 흉해의 구신(救神)으로 되므로 이 과는 길흉이 혼합하는 상태이다. 일귀는 순행하여 3전이 청(靑)․음(陰)․합(合)이고, 야귀는 역행하여 3전이 사(蛇)․상(常)․합(合)이다.`
    ,
    // 9
    `  이 과는 제4과에서 1상이 1하를 극하여 원수과(元首課)로 되며 과전은 온통 인오술(寅午戌)의 삼합화국(三合火局) 염상격(炎上格)을 구성한다.
    일간에서는 모두가 탈기하여 소모가 과대하다. 3전에서 일야귀신에는 모두 백호가 앉으니 가을과 겨울의 정단에서는 아귀(餓鬼) 아호(餓虎)라 불러 가장 흉하다. 망동하면 흉재를 초래한다. 일지상신 지에 묘신이 앉아 재해가 빈번히 발생한다.
    원수과이면서 염상격(炎上格)이면 광명하고 이순(利順)한 형상이기는 하지만 탈상탈(脫上脫)을 만나 허사(許詐)가 많다. 일간의 술(戌)의 재신이 일지상신이 되면 채권수득(債權收得)의 재를 얻는다. 혹은 채무의 재를 소득한다. 귀신이 묘(卯)에 임하는 것은 여덕격(餘德格)으로서 군자는 덕을 쌓는다. 보통사람이라면 가신(家神)을 받들어야 한다. 과전에 후(后)․합(合)이 가지런히 나타나는 것은 음일간사(陰泆奸私)한 사건이 일어난다.
    백법에 이르기를 ｢탈상탈을 만나는 것은 허사모산(許詐謀散)을 방비할지어다｣라고 하였다. 점혼에서는 중매인이 없이 먼저 정을 통하고 후에 결혼하다. 비전에는 ｢일지가 일간상신에 가해지고 택이 와서 사람에게 붙으며 가옥의 구입에는 출비가 많다｣고 했다. 3합은 모두가 연이어 일어나므로 소모는 거듭 발생한다.
    일귀는 순행하여 3전이 백(白)․후(后)․합(合)이고, 야귀는 역행하여 3전이 후(后)․백(白)․합(合)이다. 염상격은 화체(火體)가 어둡고 용(用)은 밝기 때문이고 생하기 쉽고 멸(滅)하기도 쉽다. 양일은 혁혁염상(赫赫炎上)하여 위를 충하니 허(虛)가 많고 실(實)이 적다. 무슨 일이나 신속하고 분명한 형상을 이룬다. 문서나 음신(音信) 등의 사건에서는 가장 길하다. 병을 정단할 때는 음허 혈질(血疾)의 증세이다. 소송을 정단하면 흑백과 옥석을 판명하기 어렵다.`
    ,
    // 10
    `  이 과는 4과 중에 상하의 극이 없고 사각(斜角)으로 극이 있다. 양일(陽日)은 양비라 부르며 양의 극인 신사(申巳)가 초전으로 되어 지일원태과(知一(元胎課)이다.
    일간상의 사(巳)는 공망하고, 일지상신 유(酉)는 일귀(日鬼)로 되며 정신은 유(酉)에 해당된다. 초전의 신(申)은 역마가 관귀효이며 공망에 앉아 큰 해를 끼치지 않는다. 중전에 나아가서 해(亥)는 장생(長生)으로, 말전에 나아가 인(寅)은 덕록으로 되므로 먼저 전진하면 길조이다. 무릇 원태격은 처음에는 어렵고 후에 쉬운 것이 보통이다., 3전이 체생하니 사람의 천거를 받는다.
   백법에 이르기를 ｢귀신(貴神)에 차질이 생기면 무슨 일에서나 태상이 앉는 것은 탈기(脫氣)가 천장을 생탈(生脫)하여 허사부실(許詐不實)한 일이 많다. 또 백호가 승하여 상차(喪車)와 동과(同課)하고 초전이 신사(申巳)로 되는 것은 병을 정단하면 가장 두렵다. 갑일(甲日) 신(申)에 용신(龍神)이 앉으며 도중에 재를 얻거나 먼 곳으로부터 소식이 와서 물품의 송달을 받는다｣고 하였다. 지일과(知一課)로서 천신, 즉 위에서 일간을 극하는 것은 혐의가 일어난다. 사람을 멀리 하고 일은 친우․친족․동업자․장사 거래의 사건이다. 양극(兩剋)도 일간에 비용(比用)되기 때문에 일은 두 갈래에 걸친 것이지만 후에는 한 길로 돌아온다.
   일귀는 순행하여 3전은 사(蛇)․구(勾)․백(白)이고, 야귀는 역행하여 3전은 청(靑)․주(朱)․후(后)이다. 일지 오(午)에서 유(酉)를 보년 12운이 동(死)로 되어 일간을 극하니 질병․소송에서 다 흉하다.`
    ,
    // 11
    `  이 과는 2하가 2상을 극하여 지일(知一) 및 요극(遙剋)을 취할 수 없으므로 섭해과이다. 맹상신(孟上神)의 극을 취하여 진인(辰寅)이 초전으로 되어 진간전격(進間傳格)이다. 
    그러나 초․중전의 공망은 전진하여 우물에 빠지는 격이니 조용히 지키는 것이 좋다. 초전의 재는 육합이 승하여 협극(夾剋)이 되므로 재는 자유를 얻지 못하고, 특히 공망되므로 활동하여 재를 구해도 소득하지 못한다. 말전의 신(申)은 역마로 되어 움직임이 신속하다. 귀신(貴神)이 해(亥)에 임하고 귀(貴)는 천문으로 올라서 인신사해(寅申巳亥)의 위에 진술축미(辰戌丑未)가 더해지므로 신장살몰(神將殺沒)로 되어 흉을 구한다. 또 사관․구직에서는 가장 길하다. 진(辰)이 일(日)에 더해지고 초전으로 되는데, 진인은 강색귀호(罡塞鬼戶)라 불러 현임관․공직자에게는 크게 이롭다. 涉害課는 간난신고를 겪은 뒤에 광명을 보게 되는 것이다. 초전의 공망은 고진격(孤辰格)으로 되어 승려에게 이롭다.
  일간상신은 초전으로 되고 말전 신(申)은 일지상신에 돌아간다. 이와 같은 것은 간에서 지(支)로 전한다 하여 자기가 남에게 붙는다. 나는 허세이기 때문에 소리만 있고 실력이 없다.
    백법에 이르기를 ｢간에서 지(支)에 전하는 것은 자신은 모든 일을 그에게 의존한다｣고 하였다. 백호가 일간의 귀(鬼)에 임하면 흉이 빠르다. 강색귀호(罡塞鬼戶)는 謀事에 맡긴다. 이 격은 백법 가운데의 52법칙에 해당한다 1, 2, 3의 항목이 있다. 진(辰)은 천강(天罡)이라 부르며, 인(寅)은 귀호하고 한다. 3전 중의 유무에 불구하고 진인(辰寅)이 중첩되는 것은 이 격을 구성한다. 재해를 피하며 중귀(衆鬼)가 엿보는 일이 없다. 귀(貴)가 천문에 오를 때는 점사는 모두 길리하다. 이 격은 만사에서 관대하여 임의로 모사하여 효과가 있다.
    비전(秘傳)에 이르기를 ｢육합과 천후가 3전 중에 드는 것은 교동격(狡童格)으로 사람이 바르지 못하고 예에 어긋나는 일을 한다｣고 하였다. 결혼 문제에서는 색정 사건이 일어난다. 3전이 진오신(辰午申)이면 공명 이윤문제는 길조이다. 일귀는 역행하여 3전이 합(合)․사(蛇)․후(后)이고, 야귀는 순행하여 3전이 합(合)․청(靑)․백(白)이다. 이 과는 신장살몰(神將殺沒)이라고 하며 육신(六神)이 숨으므로 여행에 안전하다.`
    ,
    // 12
    `  이 과는 제2과에서 1하가 1상을 적(賊)하여 중심과이다. 진사오(辰巳午)는 진여격(進茹格)이지만 3전이 전부 공망하여 전진하려 해도 전진할 수 없으며, 전진하면 허탈을 초래하므로 수세가 좋다. 
    일간상신과 지상신이 중간지지(中間地支))를 써서 3전으로 되는 것은 공협격(拱夾格)이라 부른다. 안으로 연주합격(聯珠合壁)되므로 무슨 일에서나 목적이 달성된다. 
    일간상신은 양인(羊刃)에 앉아 일간의 재(財)를 극한다. 특히 재가 공망하므로 일지의 묘재(墓財)를 얻을 뿐이다. 요행스러운 재물을 얻는다 해도 무의 중에 실재한다.
    백법에 이르기를 ｢진여공망(進茹空亡)은 퇴보하는 것이 좋다｣고 하였다. 일간일지는 인묘오미(寅卯午未)로 나망(羅網)에 해당하므로 모사에서는 졸책을 이룬다. 비전에 이르기를 ｢목(木)은 3고(孤)｣라고 하였다. 즉 공망을 만나는 것은 과부의 형상이다. 일귀는 역행하여 3전이 합(合)․주(朱)․사(蛇)이고, 야귀는 순행하여 3전이 합(合)․구(勾)․청(靑)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
