
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,8,12,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,9,11,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,10,10,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,9,`특별있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,8,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,7,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,2,6,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,3,5,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,4,`잇음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신뢰`,5,3,`특별있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,2,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,1,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`후원`,`후원조력문서부모 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`3`,`투서헌책`,`독신고독`],
    [`매사 영속성없음`,`후원조력문서부모사는 선천적 기복재해를 겪음`,`大`,`학습기`,`고위면접`,`생리사별`],
    [`원조상함(元助傷陷)`,`처음부터 도움받는 일은 함정이 있는 상`,`大`,``,``,``],
    [`동지함의(動止咸宜)`,`안방을 지키므로 가나 있거나 자유로운 상-`,`小`,``,``,``],
    [`금전`,`금전사업처첩 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음4,5,3,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`2`,`허성불성`,`정대결핍`],
    [`매사 영속성없음`,`금전사업이권처첩사는 선천적 기복재해를 겪음`,`小`,`면모기`,``,`도난우려`],
    [`재상가취(財尙可取)`,`금전이 항상 넉넉하여 언제든 취할 수 있는 상`,`小`,``,``,``],
    [`허성불성(虛聲不成)`,`매사 헛소리가 되고 실효가 없으며 되는 일이 없는 상`,`大`,``,``,``],
    [`명예`,`수상(受賞)사`,`大`,``,``,``],
    [`음풍`,`가정 근친상간사 有`,`小`,`1`,`도적근심`,`협력동심`],
    [`막힘`,`외사 생업 외교막힘-내부 가정 막힘 관련사`,`小`,`절정기`,`상위위계`,`근친상간`],
    [`생계영창(生計榮昌)`,`여러 곳에 거래하여 생계가 영창한 상`,`大`,``,``,``],
    [`고위접인(高位接人)`,`투서헌책으로 고위인이 찾아오는 상-허사 우려`,`小`,``,``,``],
    [`결속`,`결속하여 재원기반을 크게 세우나 명예직위남편이 훼절됨`,`大`,``,``,`標隱南山`],
    [`과출`,`내외 비용과출 관련사`,`小`,`12`,`결속단합`,`산속으로`],
    [`색정`,`외남내녀 共색정사 有-男은 타婦를 유혹해 적극 사용함`,`小`,`과시기`,`외화내허`,`숨고싶은`],
    [`기신의고(祈神宜告)`,`믿는 신에게 사정을 告(기도)하면 효과를 얻는 상`,`大`,``,``,`심정`],
    [`불검초죄(不檢招罪)`,`굳게 지키기로 약속한 비밀을 누설하여 죄에 이르른 상`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`11`,`쇠퇴공허`,`인택손상`],
    [`처`,`처 관련사`,`小`,`쇠퇴기`,`절후回生`,`禁新舊繼`],
    [`병송구흉(病訟俱凶)`,`병과 송사가 흉한 것들로 갖춰진 상`,`小`,``,``,``],
    [`주유관병(主有官病)`,`주력사로 병이나 관재를 불러오게 되어 있는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`10`,`신규到來`,`전화위복`],
    [`쟁송`,`와사내사 급속송쟁파재실종가출사-상해우려`,`小`,`침해기`,`불가진행`,`면모훼손`],
    [`재화다단(災禍多端)`,`관직자는 부임이 빠르고 일반인은 災禍가 다단한 상`,`小`,``,``,``],
    [`생살대권(生殺大權)`,`관직자는 생살대권을 쥐고 일반인은 형벌이 최흉한 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극남`,`남자 갈등사`,`大`,`9`,`선택기로`,`골육배반`],
    [`자녀`,`자녀 관련사`,`小`,`소외기`,``,`下剋上사`],
    [`수동불리(守動不利)`,`옛것을 지켜도 昏迷하고 새로 움직여도 불리한 상`,`小`,``,``,``],
    [`비상지애(非常之愛)`,`비상한 애정사가 있으나 결국 누설되어 죄에 이르는 상`,`小`,``,``,``],
    [`금전`,`금전 침체 관련사`,`小`,``,``,``],
    [`사업`,`사업 침체 관련사`,`大`,`8`,`災變길화`,`근친상간`],
    [`음풍`,`가정내 근친상간사 有`,`小`,`침체기`,``,`임신사有`],
    [`가좌부동(可座不動)`,`소극적으로 도모해야 좋고 적극적으로 움직이면 불리한 상`,`小`,``,``,``],
    [`총시성재(總是成災)`,`모두가 災禍뿐인 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`남편`,`남편관련사`,`小`,`7`,`이전의사`,`이사의사`],
    [`출행`,`외사 내사 출행이동원행사 `,`大`,`단절기`,`도난조심`,`도난조심`],
    [`자명입암(自明入暗)`,`스스로 밝은데서 어두운 데로 들어가는 상`,`小`,``,`동요분산`,`부부이심`],
    [`흉변길리(凶變吉利)`,`흉이 변하여 길하게 되는 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업직위 관련사-획득됨`,`小`,`6`,`이전필요`,`이사필요`],
    [`손실`,`외사 내부가정 손실 도망 가출사`,`大`,`존재기`,`급속히`,`급속히`],
    [`병송심외(病訟甚畏)`,`병과 송사가 매우 두려운 상-출행難返 우려됨`,`小`,``,``,``],
    [`표은남산(標隱南山)`,`산속으로 깊숙히 숨고싶은 심정인 상`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`大`,`5`,`구재대득`,`활동이동`],
    [`처`,`처 관련사`,`小`,`배양기`,`전도광명`,``],
    [`상희관휴(常喜官休)`,`일반인은 희사를 맞고 관직자는 임무를 쉬게되는 상`,`小`,``,``,``],
    [`위례득죄(違禮得罪)`,`활동 중 무례를 크게 범하여 죄에 이르는 상`,`大`,``,``,``],
    [`후원`,` 후원조력 관련사`,`小`,``,``,``],
    [`애증`,`외사 가정 애증화합-파재 극처실종-가출사 우려`,`小`,`4`,`상인관송`,`자임강폭`],
    [`급속`,`외사 내사 급속사`,`大`,`개시기`,`관인입각`,`덕행災消`],
    [`인구흠녕(人口欠寧)`,`가족의 안녕이 온전하지 못하고 흠이 있는 상`,`大`,``,``,``],
    [`입조경영(入朝經營)`,`고위에 입각하여 대중의 이익을 경영하게 되는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`명예수상사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`결속과출색정조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`금전사업처조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`관공직자는가능`,`직업직위불안남편갈등쟁송사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,``,`직업직위불안남자갈등사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`금전사업처조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사직업남편출행사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사직업손실사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`금전사업처조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`후원문서급속사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`현재 사안에서 물러나는 것이 좋음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함`,0,0,`명분이 있으므로 당당히 속결하지 못하고 좌고우면하거나 사안을 뒤로 미루면 되려 불리해지는 人`,0,0,`일이 거꾸로 띄엄띄엄 진행되나 계속 진행하는 것이 좋음**사안에 대한 것은 입을 닫아야 함**일이 결과없이 반복되고 역시 어두어짐**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`협력동심 근친상간(近親相姦)**만약 타인과 다툰다면 자칫 대중이 알게 되는 사안이므로 가능한 숨겨야 하며 시일이 흘러야만 해결됨`,0,0,`협력동심사로 진행사가 남자에게는 유리하나 단, 근친상간사가 있는 人 `,0,0,`근친상간사 有**입을 다물어야만 할 사정임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순한 처신으로 사안을 지체시키거나 후로 미뤄야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응해야만 하고 유화적 자세를 보이며 일을 뒤로 밀면 반드시 결과가 유리해지는 人   `,0,0,`결속하여 단합을 이루나 외화내허적이고  실효가 허무하며 명예직위권리남편이 훼절됨**산속으로 숨고싶은 심정임**남자가 타인의 부인을 적극 유혹하여 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함`,0,0,`명분이 있으므로 당당히 속결하지 못하고 좌고우면하거나 사안을 뒤로 미루면 되려 불리해지는 人`,0,0,`가족과 가택의 손상사 우려-이후 예일을 결속하여 길함**근친상간사가 있으나 결절됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화가 밖에서 오니 신중해야 함`,0,0,`부부이심으로 이별재신사가 있고 동요불안하며 반복무상한 人 `,0,0,`신구사가 있음**전화위복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`육친 가족의 윗인이 아래 인으로부터 핍박 받음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`협력동심 근친상간(近親相姦)**만약 타인과 다툰다면 자칫 대중이 알게 되는 사안이므로 가능한 숨겨야 하며 시일이 흘러야만 해결됨`,0,0,`협력동심사로 진행사가 남자에게는 유리하나 단, 근친상간사가 있는 人 `,0,0,`근친상간사 有**임신사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순한 처신으로 사안을 지체시키거나 후로 미뤄야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응해야만 하고 유화적 자세를 보이며 일을 뒤로 밀면 반드시 결과가 유리해지는 人   `,0,0,`신규사 있음**생업 이전 가택이사 의사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순한 처신으로 사안을 지체시키거나 후로 미뤄야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응해야만 하고 유화적 자세를 보이며 일을 뒤로 밀면 반드시 결과가 유리해지는 人   `,0,0,`일이 띄엄띄엄 진행됨** 산속으로 숨고싶은 심정임**관공직자는 영전함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순한 처신으로 사안을 지체시키거나 후로 미뤄야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 순응해야만 하고 유화적 자세를 보이며 일을 뒤로 밀면 반드시 결과가 유리해지는 人   `,0,0,`일이 순서적으로 진행됨**奇異 전화위복사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 공격의 뜻이 있어도 움직이지는 않음`,0,0,`스스로 자임하며 옛것을 지키고 새 기회를 기다리는 人-고시 준비생에 많음`,0,0,`신규사가 있음**송쟁사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`기사`,`구사`,`돌발사`,`모호사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동타발동동시사`,`미사`,`구사`,`외사내사동시사`,`아표면타표면동시사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사타발동사겹침`,`미사`,`신구중간사`,`외사내사겹침`,`아표면사타표면사겹침`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동타발동사겹침`,`기사`,`신사`,`외사내사겹침`,`아이면후회사타이면급속사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-침체`,`아타동시발동사`,`미사`,`구사`,`아외사타내사겹침`,`아후회사타급속사겹침`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이외사`,`미사`,`신사`,`돌발사`,`모호사`,`좌고우면-무력`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동타발동동시사`,`미사`,`신사`,`아외사타내사겹침`,`아후회사 타급속사겹침`,`배신-무력`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동타발동동시사`,`기사`,`구사`,`아외사타내사겹침`,`아표면사타표면사겹침`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-출비`,`아발동타발동동시사`,`기사`,`구사`,`아외사타내사겹침`,`아이면후회사타이면급속사겹침`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동타발동사겹침`,`기사`,`신사`,`아외사타내사겹침`,`아이면후회사타급속이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`상호무력`,`상호무력`,`상호무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,2,1,3,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월`,2,1,3,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음4월`,3,2,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음6월`,3,1,3,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5월`,3,2,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음9월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,2,2,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음3월`,2,3,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무는 종반의 실적이 있겠으나 초 중반에는 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무가 수준이하로 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무는 초반의 부진을 극복하고 중 종반에 큰 실적을 이룸-단, 근친상간자 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`서교성이 좋아 동료의 협조를 받으며 결속단합을 이루지만 외화내허하여 명예권리사의 실추를 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이나 업무는 곡절을 겪은 후 종반에 실적을 보임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전체가 동요되고 배신되며 분산되나 기우적으로 전화위복됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무 초반과 중반이 허무하고 종반에 이르러서도 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여  업무 전반이 수준 이하가 됨-근친상간자 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 업무 전반의 수준을 유지하며 이전 이사를 계획함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻어 업무의 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻어 업무의 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여  업무 전반이 소송사로 이어짐 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인사안`,`退背`,`근`,`去`,`주인무력`,`정동`,``,`정남`],
    [`10.11`,`集`,``,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`끌면제압`,`불이행`,`본인사안`,`退背`,`원근왕래`,`  去`,`주인무력`,`동남`,``,`남남서`],
    [`10.11`,``,``,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선이후이`,`進向`,`  근`,`  留`,`주인조력`,`남남동`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선攻후이`,`進向`,`  원`,`  留`,`세입가해`,`정남`,`정남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인반대`,`이행`,`선난후費`,`退背`,`  원`,` 去`,`주인가해`,`정남`,`남남서`,``],
    [`10.11`,``,`반대극렬`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,`  集`,`끌면제압`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선난후費`,`退背`,`  원`,`  去`,`주인가해`,`남남서`,`북북서`,``],
    [`10.11`,`  集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선攻후費`,`退背`,`  원`,`  去`,`주인무력`,`서남`,`정서`,``],
    [`10.11`,` 集`,``,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`불이행`,`선損후費`,` 退背`,`  근`,`  去`,`세입조력`,`북서`,`남남동`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`불이행`,`선난후費`,`退背`,`  원`,`  去`,`반목대립`,`북북서`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,`의사`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근제압`,`이행`,`선攻후난`,`進向`,`  원`,`  留`,`세입가해`,`북북서`,``,`정동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선費후費`,`進向`,`  원`,`  留`,`무난`,`남남서`,``,`동남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`종반반대`,`불이행`,`권리양도`,`進向`,`  근`,`  留`,`무난`,`동북`,``,`남남동`],
    [`10.11`,`  集`,`중간제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`끌면불가`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,`없음`,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,`북북서`,`없음`],
    [``,``,``,``,``,`묘향`,`남남동`,``],
    [`생업`,``,``,``,``,`전경`,`등라수,木`,``],
    [``,``,``,``,``,`묘속`,`  水`,``],
    [`가정`,``,``,``,``,`육친`,`첩, 증조모`,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`가당부정`,`성소훼손`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`가당부정`,`성소훼손`,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,`변사귀`,`객사`,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,`변사귀`,`객사`,``,``,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`도로귀`,`교툥사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,`남남동`,`없음`],
    [``,``,``,``,``,`묘향`,`북북서`,``],
    [`생업`,``,``,``,``,`전경`,`등라수,木`,``],
    [``,``,``,``,``,`묘속`,`뱀굴`,``],
    [`가정`,``,``,``,``,`육친`,`첩, 증조모`,``],
    [`질병`,`전흉사귀`,`혈광사망`,``,``,`묘좌`,``,`특별있음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`가당부정`,`성소훼손`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`가당부정`,`성소훼손`,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`있음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`전흉사`,`혈광사망`,``,``,`묘좌`,``,`있음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`움사부정`,`제사잘못`,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내외 급속사-애중화합-파재극처실종사`,`도적근심의혹사`,`매사 허성불성-세속허사자`,`음7,8,1011월방문자는종교진출하면성공함`,`매사 영속성없음`,`종교진출하면자립함`,`명예직녹자산권리사**내외막힘`,`왕진영화-쇠즉퇴장사`,`내남외녀동시 색정사`,`자식출세부진사-투서답신지체-구설사`,``,`위례득죄사`,`외내 급속송쟁사-상해우려`,`관직자 영전도약 이동사-일반 흉사`,``,`身물수손사`,``,`사기득재사`,`내외 동요분산사`,`좋은 소식 기대사`,`내외 손해가출사`,`事후쟁탈사`,`매사 위역사`,`도적失세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁`,`動止함의사`,``,`투서헌책-고위면접사`,``,`전화위복`,`빈궁`,``,`관재`,`재액해소`,``,`음4,5월화재주의`,`전도**빈궁`,`음4,5월화재주의`,`전도`,`재액해소`,`빈궁`,`빈궁**관재**병재`,``,`관재`,``,`驚懼침체사**재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사`,`도적失세-잡음`,`내외 애증화합-파재극처실종가출사`,`도적근심의혹사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`명예직녹자산권리사**내외막힘`,`왕진영화-쇠즉퇴장사`,`외남내녀 동시색정사`,`자식출세부진사-투서답신지체-구설사`,``,`위례득죄사`,`외내 급속송쟁사-상해우려`,`관직자 영전도약 이동사-일반 흉사`,``,`身물수손사`,``,`사기득재사`,`내외 동요분산사`,`좋은 소식기대사`,`내외손해가출사`,`事후쟁탈사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`내외 급속사**전화위복`,`전도**빈궁`,`動止함의사`,`전도`,`투서헌책-고위헌책사`,``,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,`빈궁`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`관재`,``,`빈궁`,``,`빈궁**관재**병재`,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내외 손해가출사`,`事후쟁탈사`,`매사 위역사`,`도적失세-잡음`,`내외 애증화합-파재극처실종가출사`,`도적근심의혹사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`명예직녹자산권리사**내외막힘`,`왕진영화-쇠즉퇴장사`,`외남내녀 동시색정사`,`자식출세부진사-투서답신지체-구설사`,``,`위례득죄사`,`외내 급속송쟁사-상해우려`,`관직자 영전도약-이동사-일반 흉사`,``,`身물수손사`,``,`사기득재사`,`내외 동요분산사`,`좋은 소식기대사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁`,``,`내외 급속사**전화위복`,`전도**빈궁`,`動止함의사`,`전도`,`투서헌책-고위면접사`,``,`재액해소**전화위복`,`빈궁`,`재액해소`,`빈궁`,``,``,`음4,5얼화재주의`,`빈궁**관재`,`음4,5월화재주의`,`관재`,``,`빈궁`,``,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`모두 재난 뿐`,`내외 동요분산사`,`관재질병사`,`내외 손해가출사`,`약속비밀누설사-처벌지경`,``,`내외 애증화합-파재극처실종가출사`,`누추 견련반복사`,`매사 영속성없음`,`죵교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`명예직녹자산권리사**내외막힘`,`관직자 생살대권 쥠-일반 혈벌 대흉`,`외남내녀 동시색정사`,`재물손,유실사`,`도적소실사`,`매사 위역사`,`외내 급속송쟁사-상해우려`,`와병불기사-구설놀램`,`탄식신음사-병-모사미수 원인`,``,`고위연회초대열석희사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재**병재`,``,`빈궁**관재`,``,``,``,`내외 급속사**전화위복`,`전도**빈궁`,`비상재愛사`,`전도`,`관직 영전도약사-부족`,`빈궁`,`전화위복**재액해소`,`빈궁**병재`,`재액해소`,`관재`,``,`빈궁`,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,``,``,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`고위연회초대열석희사`,``,`모두 재난 뿐`,`내외 동요부난사`,`관재질병사`,`내외 손해가출사`,`약속비밀누설사-처벌지경`,``,`내외 애증화합-파재극처실종가출사`,`누추 견련반복사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`명예직녹자산권리사**내외막힘`,`관직자 생살대권 쥠-일반 형벌 대흉 `,`외남내녀 동시색정사`,`재물손,유실사`,`도적소실사`,`매사 위역사`,`외내 급속송쟁사-상해우려`,`와병불기사-구설놀램`,`탄식신음사-병-모사미수 원인`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**관재**병재`,``,`빈궁**관재`,``,``,``,`내외 급속사**전화위복`,`빈궁`,`비상지愛사`,``,`관직영전도약사-부족`,`빈궁`,`전화위복`,`전도**관재**병재`,``,`전도`,`빈궁`,``,`음4,5월화재주의*재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`탄식신음사-병-모사미수 원인`,``,`고위연회초대열석희사`,``,`모두 재난 뿐`,`내외동요분산사`,`관재질병사`,`내외 손해가출사`,`약속비밀누설사-처벌지경`,``,`내외 애증화합-파재극처실종가출사`,`누추 견련반복사`,`매사 영속성없음**비상지愛사`,`종교진출하면자립함`,`매사 영속성없음**관직 영전도약사-부족`,`종교진출하면자립함`,`명예직녹자산권리사**내외막힘`,`관직자 생살대권 쥠-일반 형벌 대 흉`,`외남내녀 동시색정사`,`재물손,유실사`,`도적소실사`,`매사 위역사`,`외내 급속송쟁사-상해우려`,`와병불기사-구설놀램`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,``,`동요불안분산사`,`전도`,`동요분산사**재액해소`,`빈궁**관재**병재`,`동요분산사**재액해소`,`빈궁**관재`,`동요불안분산사`,`빈궁**관재`,`내외급속사-동요분산사-전화위복`,`전도`,`동요분산사`,`빈궁**관재`,`동요불안분산사`,``,`동요분산사**전화위복`,`전도**빈궁`,`동요분산사`,`빈궁**병재`,`동요분산사`,`전도**빈궁`,`음4,5월화재주의**동요분산사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외내 급속송쟁사-상해우려`,`와병불기사-구설놀램`,`탄식신음사-병-모사미수 원인`,``,`고위연회초대열석희사`,``,`모두 재난 뿐`,`내외 동요분산사`,`관재질병사`,`내외 손해분산사`,`약속비밀누설사-처벌지경`,``,`내외 애증화합-파재극처실종가출사`,`누추 견련반복사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음**관직영전도약사-부족`,`종교진출하면자립함`,`명예직녹자산권리사**내외막힘`,`관직자 생살대권 쥠-일반 형벌 대흉`,`외남내녀 동시색정사`,`재물손,유실사`,`도적소실사`,`매사 위역사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`전도**빈궁`,`음4,5월화재주의`,`전도`,`재액해소`,``,`빈궁**관재**병재`,``,`빈궁**관재`,``,`재액해소`,``,`내외 급속사**전화위복`,`빈궁**관재`,`비상지愛사`,``,`재액해소`,`빈궁`,`전화위복`,`빈궁**병재`,`관재`,``,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`도적소실사`,`매사 위역사`,`외내 급속송쟁사-상해우려`,`와병불가사-구설놀램`,`탄식신음사-병-모사미구 원인`,``,`고위연회초대열석희사`,``,`모두 재난 뿐`,`내외 동요분산사`,`관재질병사`,`내외 손해가출사`,`약속비밀누설사-처벌지경`,``,`내외 애증화합-파재극처실종가출사`,`누추 견련반복사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`명예직녹자산권리사**내와막힘`,`관직자 생살대권 쥠-일반 형벌 대흉`,`외남내녀 동시색정사`,`재물손,유실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,``,``,`빈궁**관재**병재`,``,`빈궁**관재`,``,``,``,`내외 급속사**전화위복`,`빈궁`,`비상지愛사`,``,`관직 영전도약사-부족`,`빈궁`,`전화위복`,`전도**관재**병재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`외남내녀 동시색정사`,`재물손,유실사`,`도적소실사`,`매사 위역사`,`외내 급복송쟁사-상해우려`,`와병불기사-구설놀램`,`탄식신음사-병-모사미수 원인`,``,`고위연회초대열석희사`,``,`모두 재난 뿐`,`내외 동요분산사`,`관재질병사`,`내외 손해가출사`,`약속비밀누설사-처벌지경`,``,`내외 애증화합-파재극처실종가출사`,`누추 견련반복사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`명예직녹자산권리사**내외막힘`,`관직자 생살대권 쥠-일반 형벌 대흉`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,``,`빈궁`,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,``,``,``,`빈궁**관재**병재`,``,`빈궁**관재`,``,``,``,`내외 급속사**전화위복`,`빈궁`,`비상지愛사`,``,`관직 영전도약사`,`빈궁`,`전화위복`,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`명예직녹자산권리사**내외막힘`,`완진영화-쇠즉퇴장사`,`외남내녀 동시색정사`,`자식출세부진사-투서답신지체-구설사`,``,`위례득죄사`,`외내 급속송쟁사-상해우려`,`관직자 영전도약 이동사-일반 흉사`,``,`身물수손사`,``,`사기득재사`,`좋은 소식대기사`,`내외 동요분산사`,`事후쟁탈사`,`내외 손해가출사`,`매사 위역사`,`도적失세-잡음`,`내외 애증화합-파재극처실종가출사`,`도적근심의혹사`,`매사 영속성없음`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복**재액해소`,`빈궁`,`재액해소`,`빈궁**관재`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁`,``,`빈궁**병재**관재`,``,``,`관재`,`빈궁`,``,`내외 급속사**전화위복`,`전도**빈궁`,`動止함의사`,`전도`,`투서헌책-고위면접사`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 영속성없음`,`종교진출하면자립함`,`왕진영화-쇠즉퇴장사`,`명예직녹자산권리사**내외막힘`,`외남내녀 동시색정사`,`자식출세부진사-투서답신지체-구설사`,``,`위례득죄사`,`외내 급속송쟁사-상해우려`,`관직자 영전도약 이동사-일번 흉사`,``,`身물수손사`,``,`사기득재사`,`좋은 소식대기사`,`내외 동요분산사`,`事후쟁탈사`,`내외 손해가출사`,`매사 위역사`,`도적失세-잡음`,`내외 애증화합-파재극처실종가출사`,`도적근심의혹사`,`매사 영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`투서헌책-고위면접사`,``,`빈궁**관재`,`전화위복**재액해소`,`빈궁`,`재액해소`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`빈궁`,``,``,`빈궁**관재**병재`,``,`관재`,``,`빈궁`,`내외 급속사**전도**빈궁`,`폐한인**전화위복`,`動止함의사`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자`,`음7,8,10,11월 방문자는 종교진출하면자립함`,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`명예직녹자산권리사**내외막힘`,`왕진영화-쇠즉퇴장사`,`외남내녀 동시색정사`,`자식출세부진사-투서답신치체-구설사`,``,`위례득죄사`,`외내 급속송쟁사-상해우려`,`관직자 영전도약 이동사-일반 흉사`,``,`身물수손사`,``,`사기득재사`,`좋은 소식대기사`,`내외 동요분산사`,`事후쟁탈사`,`내외 손해가출사`,`매사 위역사`,`도적失세-잡음`,`내외 애증화합-파재극처실종가출사`,`도적근심의혹사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`動止함의사`,``,`투서헌책-고위면접사`,`전도`,`전화위복`,`빈궁`,``,`빈궁`,``,`전도`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,``,`전도**빈궁`,``,`재액해소`,`빈궁**관재**병재`,`재액해소`,`관재`,`전도**빈궁`,``,`빈궁`,`내외 급속사**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 강한 것은 부러진다고 했     는데 바로 그 격일세?
  재산에 송사가 붙었어! 
⊕여귀 
⊖전흉사귀․가택음사부정을    해소 시키면 복이 되겠어!

⊖신변음사부정도 침범했어 ! 
`,`■ 내부금전․여자문제로 의심     질투가 많은데 이것저것     다 안될 때는 기도․치성이     제일이야!모두 끊어지는     운세에 들었어! 
  모략도 당하겠고…
  금전 여자 부모 모두 
  凶이야!
`,`■ 들리는 건 빈소리뿐이고     얻는게 없겠어! 
  진퇴양난인데밀고나가      다는 화만 당해!
⊕여귀,가택상가부정 
⊖전흉사귀를 잡아줘야 살길    이 열리겠어!
⊕신변상가부정이 침범했네? 
`,`■⊕두 사람이 아무리 마음을       합해도 비용만 나가겠어! 

 ⊖집안에서 불의밀통을 벌이      고 있겠는데, 
   근친상간이네?
`,`■ 빛 좋은 개살구네? 
  관재기운이 있는데다 
男：남의 여자를 내여자 만들었네!
女：부부이별 기운이 보여! 
   가정에 부정이 잔뜩들어      그래! 
⊕⊖신변에 가당신사 부정이
   들었네 ? 
`,`■ 사람도 집도 손상 받는      운세네? 
   일도 인연도 모두 끝났어! 
⊕변사귀 
⊖전사귀 침범 때문인데, 
 이것 해소가 더 급하겠어!
 그래야 吉해져 !
`,`■ 너도 나도 색정사가 있어     불화하니 누구 탓도 할      수 없는 배반사네? 
 자충수를 두고 후회하고    있겠어!
⊕도로귀ㆍ가택음사부정 
⊖가택귀 해소부터하고 
 새 일을 해야 길이 열리지!
⊕신변음사부정이 침범했어! 
`,`■ 육친간 불화에다가 하극상      능욕까지 받고 있겠네?     가정 금전 여자로 선택의     기로이고 의심과 질투심     도 크겠어! 
  기도 하세요!
`,`■ 두 사람이 힘을 합해도      되는건 없겠네?
⊕가정에 근친상간사가 벌어졌네?
⊕도로귀 
⊖가택귀ㆍ가택상가부정을 해소    시켜야만 병을 이길 수 있    겠어!
⊖신변상가부정이 침범했어 ! 
`,`■ 변동수가 있네? 
  일도 두가지로 벌어져 
  있겠고!
  가정에 초상까지 나겠네?

⊕도로귀 
⊖가택귀를 없애야만 편안해    지겠어!
`,`■ 돈은 돈인데 내 돈이 못     되겠어! 
  가정에 가당신사부정이      잔뜩 들었고 
⊕여귀, 
⊖전흉사귀까지 침범해 돈으로    관재가 붙겠고… 급해!
⊕⊖신변가당신사부정침범했네?
`,`■ 돈 들어오는 건 좋은데       서둘다가는 관재가 생기      겠어! 
   신중하게 진행하면 이익      이 커!  좋네!`];
  const YOGYEOL1 = [`■ 덕과 녹이 내게 있으니 밤정단은 관직ㆍ구직사에 좋으리라. 
  낮정단은 역마․귀신ㆍ범이 뭉쳤으니 움직여봐야 다툼과 형벌이겠다.
  `,`■ 나를 돕는 것은 비었고 금전과 여자가 해를 끼친다. 모든 계획이 불리하고 병이다.
  관송사를 감당하기 어렵겠다.
  `,`■ 부모 어른의 도움이 비고 말라 버렸으니 낮정단은 놀래고 흉한 것이  되고 水字든 이름이나 동네에 사는 사람의 말을 믿어서는 안 되겠다.
  `,`■ 다행이 돕는자가 있다 해도 귀인과 금전이 비었다. 여러 번의 곡절과 고생을 겪고 나서 결국 옛날 하던 일로 돌아온다.
  `,`■ 삼전에 도둑이 가득하다. 금전이 허비 되거나 손해가 있으리라. 밤정단은 귀인이 하늘에 오르니 신에게 빌거나 치성이 좋겠다.
  `,`■ 일은 깨지고 사람도 다치고 가택과 가정도 이와 같다. 여름 밤정단은 불조심해야 하고 병과 관재가 두렵도다.
  `,`■ 나 너 피차 상처 입는다. 공무원은 좋고 일반인은 나쁘겠다. 덕․녹에 범이 올라 있으니 재앙이 여러 가지로 생기겠다.
  `,`■ 나와 집이 모두 침침하다. 빈 것과 패하는 것에 의지가 어렵겠다. 자식의 일은 재앙이 있겠고 가정 소송사가 임하리라.
  `,`■ 낮정단은 범이 네 마리가 노리나 재앙은 없어지고 금전이 생기겠다. 관직은 구해지겠으나 뜻대로 쉽지는 않다.
  `,`■ 말들이 교대로 뛰니 어찌 조용하겠는가. 도적과 실물을 방비해야겠고 병은 유명을 달리 하겠다. 
  `,`■ 낮정단은 두려운 일이 있겠다. 귀신이 범과 말을 타고 오나 귀인이 하늘에 올랐으니 천강이 귀신 굴을 막아 재앙은 없겠다. 
  `,`■ 진퇴가 양난이다. 지키는 것만이 제일 좋다. 남의 하루치 식량을 탐하다가는 자기 반년치 식량을 잃으리라.`]
  const SIBO1 = [`■ 소송사만 피하면 모든 일
  에 복이 온다. 관공직은 
  목적달성이 신속하다.
  단, 덕행을 쌓는데 노력해야 한다. 망동을 삼가고 조용한 처신이 이롭다. 
  구재사는 쟁탈의 소지가 있다. 매사 유순하라.
  `,`■ 저주사가 있고 진퇴가 
  양난이다. 
  전진하는 수밖에 없다.
  음서사에 모략이 있다. 두 가지 일로 기로에 섰다. 이권을 상실한다. 남녀간, 부부간 별리의 사정이 있다. 매사 기다려야 안전하다.
  `,`■ 어디에 도움의 손길을 벌
  릴 수 없는 입장이다. 
  윗어른의 재난이 있고
  소모․도난사가 기한다.
  허성일 뿐이고 실의가 없다. 정당한 의사가 결핍되어 배반사가 일어난다. 귀신해소가 방법이 된다. 금전․여인․부모의 凶이 기한다.
  `,`■ 이익사가 있고 소득 한다.
  모두 가정에서 정리정돈
  하는 것이 좋고 밖에서
  분주해도 불리하다. 二人 동심․협력사가 있다. 남자는 유리하나 여자는 불리하다. 
  ⊖근친상간의 음사가 있다. 이권을 상실한다.
  여인의 凶害가 있다.
  `,`■ 外는 화려하나 內는 부실
  한 상이다. 명확한 용건
  은 어둡게 된다. 
  본체가 변화 되고 그 정체성을 잃게 된다. 가정에 부정이 들어 구설이 있고 거주가 불편하다. 
  먼저 합하고 후에 헤어진다. 남자가 남의 여자를 유혹한다.
  다인 다사건이다.
  `,`■ 자충수적 우환이 있고
  가장을 속이며 가인이
  원한을 품고 가해하려
  한다. 인택이 손상 되는 사건이 발생한다. 신규사는 이롭지 못하다. 
  기쁨도 허사가 되고 만다. 
  귀신을 제어하면 흉화위길이 된다.
  十月 임신사가 있다.
  `,`■ 자충수적 우환이 있고
  가장을 속이며 가인이
  원한을 품고 가해하려
  한다. 배반ㆍ부부이심사가 있다. 옛것을 개신시키려 하나 여의치 않다. 
  별리사로 녹이 없어지는 형국이다. 무슨 일이든 속전속결해야 이롭다.
  `,`■ ⊕上人과 財의 경위를 
  밝히는 일이 있고 ⊖금
  전사의 허위건이 발생
  한다. 골육이 배반한다. 
  下人이 어른을 업신여겨 上人은 능욕을 받는다. 
  만사 불화하여 의지할 곳이 없다. 조용히 근신하는 것이 좋고 움직이면 재해를 입는다.
  `,`■ ⊕남녀부정의 상으로 
  근친상간사가 있다. 
  ⊖자손의 병이 있다.
  ⊖가정에 상가 부정이 들었다. 임신사가 있다. 중절하게 된다. 병은 낫는다.
  현재 반복부정하고 결과가 없으나 밝아진다.
  `,`■ 변동수가 있다. 가택․
  직위․생업 등이다.
  추천을 받는다. 
  소모건과 동요건이 계속 이어지고 있다. 분실․도난의 위험이 있다. 가정 초상의 우려가 있고 일이 두 가지로 갈라진다. 관공직사․시험․구직사는 길하다. 
  집안에 죽을 사람이 있다.
  `,`■ 가정에 부정이 들었다.
  금전을 구하나 자유롭지
  못하게 된다.
  관재로 마음이 불안하고 멀리 가고 싶어 한다. 그러나 기도로서 해소 된다. 관공직자는 吉하다. 
  男이 타인의 부인을 유혹하여 거래한다.
  `,`■ 금전과 여자로 인한
  구설과 다툼이 많다.
  구재는 크게 얻는다.
  지금껏 소강상태에서 활발의 전기를 맞는다. 이동의 징조가 있다. 만사 전진하여 이익이 된다. 
  관공직사는 吉하다.`]
  const SIBO2_1 = [`■ 직․녹,신변내외사다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  배은망덕 관송사가 있다.
  `,`■ 사업․후원남녀관계사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액 예방이 필요하다
  `,`■ 財․女,손모후원사다.
  내방조짐 상의 : 
  해산건이 있다.
  움직임이 근본이고 동기가 발동한다.
  `,`■ 협력동심금전사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 재액이 있다.
  `,`■ 財․女,내외사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다. 병재·관재 예방이 필요하다.
  `,`■官･夫,소송다툼사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■직․녹,내외변동사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  색정불화의 상이다.
  `,`■ 사업․후원불성사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액 예방이 필요하다
  `,`■ 官･夫,내외별리사다.
  내방조짐 상의 : 
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다. 
  `,`■官･夫,내외이심동요사다.
  내방조짐 상의 :  
  원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다. 
  `,`■ 財․女,매매사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■財․女,위배사·상매건이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/051/row-1-column-1.png`,
    `/static/images/sagwa/051/row-1-column-2.png`,
    `/static/images/sagwa/051/row-1-column-3.png`,
    `/static/images/sagwa/051/row-1-column-4.png`,
    `/static/images/sagwa/051/row-1-column-5.png`,
    `/static/images/sagwa/051/row-1-column-6.png`,
    `/static/images/sagwa/051/row-1-column-7.png`,
    `/static/images/sagwa/051/row-1-column-8.png`,
    `/static/images/sagwa/051/row-1-column-9.png`,
    `/static/images/sagwa/051/row-1-column-10.png`,
    `/static/images/sagwa/051/row-1-column-11.png`,
    `/static/images/sagwa/051/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `   이 과는 천지신이 복(伏)하여 움직이지 않고 복음과(伏吟課)로 된다. 양일(陽日)은 스스로 강하다고 자처히기에 자임격(自任格)이라 한다. 삼전 인사신(寅巳申)은 원태격(元胎格)이다. 일간에서 인(寅)은 덕록(德祿身)에 임하여 초전이 되고, 청룡이 승하면 백사가 소원대로 되고 목적도 달성된다. 복음(伏吟)은 정(靜)을 지키고 맹동(猛動)을 꺼린다. 말전 신(申)에 백호가 승하면 최관부로 되어 회사․관공직은 신속하게 목적을 달성한다. 관귀(官鬼)가 승하여 두려워하지만, 중전에서 이것을 제(制)하므로 해(害)가 없다. 또한 일간의 왕록(旺祿)․덕신(德神)이 병렬되기 때문에 덕행(德行)에 의하여 흉을 풀어준다. 원태복음(元胎伏吟)은 음복(陰伏)의 상(象)이므로 조용히 하여 다치지 말아야 한다 조심하지 않으면 흉으로 변화한다.
    비전에 이르기를 ｢비견(比肩)이 임립하므로 구재(求財)에서는 쟁탈할 위험이 있다｣고 하였다. 중전에 정신, 말전에 역마(驛馬)가 승하여 정 중에 동할 징조가 있다. 일귀는 순행하며 삼전은 청․주․후이고, 야귀는 순행하며 삼전은 사․구․백이다.
   이 과는 무슨 일을 정단하든지 유순(柔順)한 것이 좋다. 그 다음은 종래의 상궤정단(常軌正斷)이다.`
    ,
    // 2
    `   이 과는 4과가 모두 하에서 상을 상해(傷害)하고 양일이므로 양비(陽比)의 극을 취하여 자축(子丑)을 초전으로 하는 지일퇴여격(知一退茹格)이다. 그러나 초․중전이 공망하므로 퇴여(退茹)는 진여(進茹)로 변격한다. 자축(子丑)이 공망하니 사과 중 이전(二傳)이 공망 되어 말전만이 실(實)로 된다. 일간일지상신은 축(丑)의 공망에 천공이 승하여 공중공(空中空)에 앉으니 길흉이 모두 힘이 없고 부실(不實)의 상을 나타낼 뿐이다.
    지일비용과(知一比用課)는 먼 데 것을 버리고 가까운 것을 구하여 텅 빈 것이 되며, 행하려고 하여도 행하지 못하고 그만 두는 징조를 이룬다. 사하(四下)가 상을 극하여 절사격(絶嗣格)이 되고, 음사(陰私) 또는 암 중에 모략이 있으니 미연(未然)에 방지하여야 한다.
    초전의 공망은 고진(孤辰)이라 하여 고독(孤獨) 원리(遠離)를 주관한다. 이것은 백법(百法) 중 제94법에 해당한다.
    비전에 이르기를 ｢자해술(子亥戌)은 태양의 빛을 보지 못하기 때문에 중음(重陰)이라 하고 백사는 좀 기다려보는 것이 안전하다｣고 하였다. 일귀는 역행하고 삼전은 백․상․현(玄)이며, 야귀는 순행하고 삼전은 후․음․현이다.`
    ,
    // 3
    `   이 과는 4과이지만 2과밖에 없고 상에서 하를 극하는 술자(戌子)가 초전으로 되니 원수간전격(元首間傳格)이다. 일간일지는 모두 상에서 하를 생하지만 공망하므로 자육(慈育)할 힘이 없다. 4과가 모두 공망하고, 초전은 재효(財爻)라 할지라고 공지에 앉고 현무(玄武)가 타니 소모․도난(盜難)의 일이 생긴다. 
    원수의 길과(吉課)이지만 역간전(逆間傳)은 정대(正大)한 감정이 결핍되고 배반할 징조가 보인다. 또 허성(虛聲)이고 실의(實意)가 없다.
    일귀는 역행하며 삼전은 현(玄)․후(后)․사(蛇)이며, 야귀는 순행하고 삼전은 현(玄)․백(白)․청(靑)이다.`
    ,
    // 4
    `   이 과는 4과 중에 상하의 극이 없고 일간일지상신은 동일(同一)하고 4과이지만 2과밖에 없으니 팔전과라고 한다. 초전이 공망하면 과숙격(寡宿格)이고, 순미(旬尾)의 해(亥)가 일간상에 승하면 폐구격(閉口格)이 된다. 팔전과식의 원칙에 이르기를 ｢일간일지가 같고 극이 없는 양(陽)은 순음(純陰)이 역(逆)의 삼신(三神)을 취하여 초전으로 한다｣고 하였다. 즉 해자축(亥子丑)과 삼위(三位)에 해당하는 축(丑)을 초전으로 한다.
    양일(陽日)은 전진하고 음일은 퇴숙한다는 뜻이다. 중전․말전은 일상신(日上神)의 해(亥)를 사용하고, 4과의 팔자간(八字干)과 지신(支神)이 동일하고 팔가동병(八家同倂)하므로 일이 전일(專一)로 되기 때문에 팔전과(八專課)라 한다. 제반사(諸般事)는 집에서 정리하는 것이 좋고 외부에서 분주하게 활동하는 것은 좋지 않다. 이는 또 대중회합(大衆會合)의 과이다. 이인동심(二人同心)이고, 양일이면 진행하여 남자에게 유리하며, 여자는 진행하면 불리하게 된다. 분실한 것은 내부에서 찾는다. 양일은 존장이 부하를 속이고 진행하는 것을 주의(主意)로 하고, 매사는 신속하게 밖으로 나가는 것을 원한다. 음일은 부처간에 의심을 품고 사용인이 주인을 배반한다. 늘 퇴숙하여 일이 늦어지고, 과전 중에 청(靑)․상(常)․귀신(貴神) 및 천월이덕(天月二德) 등의 길성을 지닐 때는 길과이다.
    이 과에서 천후(天后)․육합(六合)․태음(太陰) 중의 어느 한 장성(將星)이 삼전 중에 들어가면 불수격(不修格)이라 한다. 음양이 거처를 같이 하므로 남녀는 혼합되고, 후(后)․합(合)은 음사의 신이기 때문에 색정(色情)․음풍(淫風)이 나타나며, 예절(禮節)은 결핍된다.
    일귀는 역행하며 삼전은 공(空)․상(常)․상이고, 야귀는 순행하여 삼전은 귀․음․음이다. 순미(旬尾)의 해(亥)는 순수(旬首)에 가담하여 폐구격(閉口格)이다. 병(病)은 식사를 하지 못하고 혹은 공망하여 말을 하지 못한다. 소송사에서는 도리를 진술할 수가 없다. 사람은 입을 다물고 말하지 않는다.`
    ,
    // 5
    `   이 과는 4과이지만 2과밖에 없고 하에서 상을 극하는 술인(戌寅)을 사용하여 초전으로 하는 중심과(重審課)이며, 삼합염상격(三合炎上格)이다. 삼전에 합․후가 있으면 교동격(狡童格)이 된다.
    삼전 화국(火局)은 일간에서 설기(泄氣)가 되고, 일간에서 술(戌)은 재효(財爻)가 되어 일간에 임한다. 삼전 인오술(寅午戌)은 삼합하므로 염상격(炎上格)이니 화염의 상이고. 화(火)는 허가 많고 실의(實意)가 적다. 명확한 용건을 정단하면 오히려 암매(暗昧)하게 되어 버린다. 내정(來情)은 화(火)에 관한 건이다. 술(戌)이 인(寅)에 가담하면 묘(墓)가 생(生)에 임한다고 한다. 허한 일은 명(明)하게 되고 명한 일은 암을 생한다. 모두 본체(本體)를 변화시켜 그 체를 잃어버리는 것이다.
    정단의 건은 골육을 파(破)하고, 모사(謀事)는 이루어지지 못한다. 화(火)는 문장을 주관하는데, 매사가 빨리 이루어지고 빨리 쇠퇴한다. 상인(常人)에게는 구설이 있거나 주거(住居)가 편안하지 못하다. 삼전이 합 ․후를 보면 부인(婦人)의 혈병(血病)이다. 하늘은 맑고 사람은 성급하다. 화(火)의 성질을 움직이는 것이므로 대인(待人)․내객(內客)은 오게 된다. 무슨 일이나 처음에는 기뻐하고 후에는 성을 낸다.  또 먼저 합하고 후에 헤어진다. 술(戌)이 인(寅)에 승하며 묘는 생에 임하고, 명(明)은 도리어 암(暗)이 된다. 년명상에 화신이 승하면 병자는 사망하고 소송사는 흉하다.
    일귀는 순행하며 3전은 합․후․백이고, 야귀는 역행하고 삼전은 합(合)․백(白)․후(后)이다. 삼전의 합에 후가 승하므로 점혼(占婚)은 자유 결혼으로 본다.`
    ,
    // 6
    `  이 과는 4과이지만 2과밖에 없다. 팔전(八專)의 상으로 상(上)에서 하(下)를 극하여 원수과이다. 
    유(酉)와 진(辰)의 지합하니 만사는 화해하고 순조롭다. 삼전은 모두 자형(自刑)의 신이고 유(酉)는 파쇄살(破碎殺)로, 일간일지가 초전에 승하여 인택이 모두 손상받는 사건이 있다. 과체 사절격(四絶格)은 옛 일을 결속하는 것이니 새롭게 사업을 계획하는 것은 이롭지 못하고, 천지반 중 천을귀신이 공망하므로 기뻐하던 일은 허사로 돌아간다.  초전은 십이운 절이 되고, 말전 장생은 끝이 나고 길사는 모인다.
    이 과는 처음에는 흉하고 길로 변화한다. 일귀는 순행하며 3전은 주(朱)․현(玄)․구(勾)이며, 야귀는 역행하고 삼전은 구(勾)․현(玄)․주(朱)이다.`
    ,
    // 7
    `   이 과는 천지반이 충위(冲位)에 있으므로 반음과(返吟課)가 된다. 반음(返吟)이란 반복적으로 왕래하여 쉴줄 모르며, 일명 무의(無依)라고도 하여 길흉이 반복되니 진정되지 않는다. 조용하면 움직이려고 하고, 움직이면 조용한 것을 바란다. 덕록이 절(絶)의 지에 앉아 역마가 임립한다. 
    구사(舊事)에서 벗어나 새로운 것을 기도한다. 사관구직(仕官求職)은 매우 신속하다. 보통사람의 길흉을 정단하면 길흉은 모두 한 가지 일이 가면 또 한 가지 일이 온다. 그러므로 거래격(去來格)이라 명명한다. 신(申)에 역마가 있고 일귀로 되면 일귀마재격(日鬼馬載格)이라 부른다. 
    일간상에 둔간귀(遁干貴)로 되고 일간일지는 모두 절지(絶地)에 임하기 때문에 관소(官訴)는 해소되고 흉사는 속히 맺는 것이 좋다. 새로운 계획에 대한 점단은 그만 두어야 한다. 인(寅)이 공조(功曹) 신(申)에 가담하여 풍우를 정단하면 대풍(大風)이 온다.
    일귀는 순행이고 삼전은 백․사․백이다. 삼전 주에 사호(蛇虎)가 승하면 놀라운 사건이나 위험한 일이 생긴다. 야귀는 역행하고 삼전은 후․청․후이다. 
    이 과는 의신(儀神) 즉 순수(旬首)가 초전이 된다. 그러나 덕(德)을 상실하고 녹(祿)은 없어지므로 구조(救助)의 힘은 매우 미약하다.`
    ,
    // 8
    `   이 과는 4과가 모두 하에서 상극(相剋)한다. 양일이므로 양비(陽比)의 극인 자미(子未)를 초전으로 하는 지일과(知一課)이다. 3상은 3하를 극하고 3상을 손상하여 도액격(度厄格)이라고 한다. 도액(度厄)이란 망규부정(網規不正)의 상으로서 어린 것이 장자를 업신여기니 이를 장도액(長度厄)이라 한다. 반면 윗사람이 아랫 사람을 깔보는 것을 유도액(幼度厄)이라고 한다. 이 격은 무슨 일이나 노유(老幼)에게 우려가 있어 병환은 중하고 가정은 불길하며, 골육이 서로 배반한다. 양은 남자의 액(厄)이고, 음은 어린 아이나 여자의 액(厄)이다. 양장(陽將)은 존장과 윗사람에게 災가 있고, 음장(陰將)은 유소재(幼少災)이다. 왕상으로 길장이 승하면 장유는 힘을 이룬다.
    이 과는 3하가 3상을 극하여 장도액격이다. 일간일지상신 묘신(墓神)을 뒤덮으니 혼회(昏晦)하며 불안하다. 초전․중전이 공망하고 길흉이 모두 의지할 곳이 없다. 조용하게 있는 것이 좋고 움직이면 재해가 발생한다. 삼전 주인격(鑄印格)이어서 길조로 되지만, 4하가 4상을 극하여 존장에게 불리하다. 초전의 공망은 고과공망(孤寡空亡)이라 하여 만사가 피차 서로 화목하지 못하고 쾌활하지 않다.
    일귀는 순행하며 3전은 청․음․합이고, 야귀는 역행하고 삼전은 사․상․합이다.`
    ,
    // 9
    `   이 과는 4과 중에 극이 없고 음양이 2과 밖에 없기 때문에 팔전과로 된다. 양일은 일간상신 오(午)에서 세 번째의 신(申)을 초전으로 하고 중․말전은 일간상신을 사용한다. 팔전과에 4과중 상하 극제가 없기 때문에 남녀부정(男女不正)의 상이다. 초전 신(申)은 관귀효(官鬼爻)이지만 오(午)에서 제하므로 길로 전화한다. 
    일귀는 순행하며 삼전은 사․후․후이며, 야귀는 역행하여 삼전은 청․백․백이다. 비전에 이르기를 ｢축(丑)이 유(酉)에 가담하면 복태격(腹胎格)으로 되고 내의(來意)는 임신의 정단이다｣라고 하였다. 또 축(丑)은 공망하여 출산을 정단하면 신속하다. 태(胎)를 관점(觀占)하면 태를 손상한다. 백호가 오(午)에 승하면 백호가 몸을 태운다고 하며, 재(災)는 변하여 길(吉)로 된다.`
    ,
    // 10
    `   이 과는 신사(申巳)에서 1하(下)가 1상(上)을 극하여 중심과이고, 삼전 사맹신은 원태격(元胎格)이다. 일간일지상신 사(巳)는 정신(丁神)이 된다. 정신이 신택(身宅)에 임하고 역마가 신(申)에 해당하므로 동요가 끊이지 않는다. 이리하여 일간은 사(巳)에 탈기(脫氣)하고 인택(人宅)이 같이 움직이니 소모가 있으며, 병관(病觀)은 더욱 위험하다. 4과이지만 2과밖에 없어 사물은 두 방면으로 갈라진다. 
    삼전 체생은 다른 사람의 추천을 받는다. 말전은 덕록이 되어 갑자기 번창할 징조가 있다. 백법의 수는 제95에 해당한다.
    비전에 이르기를 ｢인택이 탈설(脫泄)하면 분실 혹은 도둑을 만날 위험이 있다｣고 하였다. 
    초전이 관귀효이고 체생(遞生)이면 관공직(官公職)․구직․입학 시험 등은 모두 장생․건록이 되어 중위  이상으로 입학 시험에 합격하거나 취직할 수 있다.
    일귀는 순행하고 삼전은 사․구․백이며, 야귀는 역행해서 삼전이 청․주․후이다. 이 과는 어느 사람이나 흉을 제하고 길리를 얻을 수 있다.`
    ,
    // 11
    `   이 과는 진인(辰寅)에서 1하(下)가 1상(上)을 극하니 중심과로 된다. 초전의 진(辰)은 일간에서 보아 재동(財動)이 되고 육합이 타서 협극(夾剋)하므로 재(財)는 자유를 얻지 못한다. 말전 신(申)은 귀효(鬼爻)이지만 중전에서 제하기 때문에 귀살은 두려워할 것이 없다. 진(辰)의 괴강(魁罡)이 일지에 가담되어 초전을 이루며 천을귀신이 해(亥)에 임하면 귀인이 천문에 오른다. 
    지반 사맹(四孟)에 사계(四季)가 오르면 신장살몰(神將殺沒)이라 하여 임관은 반드시 얻는다. 보통 사람이라도 흉을 해소하고 길조를 초래한다.
    일귀는 역행하며 삼전은 합․사․후이고, 교동격(狡童格)이 된다. 야귀는 역행하고 삼전은 합․청․백이다.`
    ,
    // 12
    `   이 과는 4과이지만 2과밖에 없다. 제2과․제4과가 동일하며 1하(下)가 1을 극하는 중심과이고, 삼전 진사오(辰巳午)는 진여격(進茹格)이다. 초전은 진(辰)의 재효(財爻)이고 과전의 삼소(三所)에 있다. 사오(巳午)의 화(火)가 재를 생하여 재기(財氣)가 왕성하며 따라서 구재는 크게 얻는다. 
    일간일지상신이 왕(旺)을 만나고 진여(進茹)이니 나아가는 것이 좋으며, 진사오(辰巳午)와 양(陽)으로 나아가고 만사는 전진하여야 전도가 광명하다. 초전에 천강이 승하면 지금까지 조용하던 것이 동하게 된다. 또 사(巳)는 정신이 승하여 활동이나 이동의 징조가 있다.
    백법에 이르기 ｢서로 왕하면 모사는 모두 잘 된다｣라고 하였다. 일간일지상신의 비견․양인은 재(財)를 소득하지만 재가 분산될 징조가 있다. 진사오(辰巳午)는 승계격(昇階格)으로 임관․구직은 모두 다 양호하다. 
    일귀는 역행하며 삼전은 합․주․사이고, 야귀는 순행하고 삼전이 합․구․청이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
