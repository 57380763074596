import React, { Component, useState, useEffect, useContext } from "react";
import styled, { ThemeProvider } from "styled-components";
import parse from "html-react-parser";
import appStrings from "../../assets/appStrings";

const Header = styled.div`
    min-height: 30px;
    display: flex;
    flex-wrap: nowrap;
    z-index: 20;
    position: fixed;
    background-color: #fff;
`;

const Comp = props => {
    const { children } = props;
    const { refArr } = props;
    // DESKTOP HEADER : 270 + 75 + calc(100% - 345px)
    return (
        <>
            {props.componentCode && props.componentCode == "test/top" && (
                <>
                    <marquee className="marquee1 footer" scrollamount="7">
                        {Array.from([
                            {},
                            {},
                            {},
                            {},
                            {},
                        ]).map((headerItem, headerIndex) => {
                            return (
                                <>
                                {parse(appStrings.testEn0)}
                                {parse(appStrings.test0)}
                                {parse(appStrings.testEn0)}
                                {parse(appStrings.test0)}
                                {parse(appStrings.testEn0)}
                                {parse(appStrings.test0)}
                                {parse(appStrings.testEn0)}
                                {parse(appStrings.test0)}
                                </>
                            );
                        })}
                    </marquee>
                </>
            )}

            <Header
                id="app_header"
                style={{
                    marginTop:
                        props.componentCode && props.componentCode == "test/top"
                            ? `21px`
                            : 0,
                }}
                {...props}
            >


                {true &&
                    (children == null ||
                        children == undefined ||
                        typeof children == "undefined") && (
                        <>
                            {true && (
                                <div className="header-row0 header-row1 mediumSet menuBar border-b">
                                    
                                    {
                                                props.page &&
                                                <>
                                                <div className="" style={{ width: `100%`, height: `50px`, display: 'flex', alignItems: 'center'}}>

                                                    <span className="menubar" style={{ marginLeft: 10}}>
                                                        {props.page && "가북"}
                                                    </span>
                                                    <div  style={{ marginLeft: 'auto'}}></div>
                                                    <span className="menubar"  style={{ marginRight: 10}} 
                                                        onClick={()=> {
                                                            window.location.href = "https://react-firebase-crud-master.vercel.app/add"
                                                        }}
                                                    >
                                                        접수하기
                                                    </span>
                                                </div>
                                                
                                                </>
                                            }
                                </div>
                            )}
                            
                        </>
                    )}

                <style jsx="true" global="true" suppressHydrationWarning>{`
                    .marquee1 {
                        width: 100vw;
                        height: 20px;
                        position: fixed;
                        top: 0px;
                        left: 0px;
                        z-index: 99;
                        background: #8D8DAA;
                        color: #fff;

                        border-bottom: 1px solid #000;
                        display: flex;
                        align-items: center;
                    }
                    .marquee2 {
                        width: 100vw;
                        height: 24px;
                        position: fixed;
                        top: 20px;
                        left: 0px;
                        z-index: 99;
                        background: #fff;
                        color: #000;

                        border-bottom: 1px solid #000;
                        display: flex;
                        align-items: center;
                    }
                `}</style>

                <style jsx="true" global="true" suppressHydrationWarning>{`
                    .header-row0 {
                        width: 100vw;
                        display: flex;
                        flex-direction: row;
                        font-family: 'NanumBarunGothic';
                    }
                    .menubar  {
                        font-family: 'NanumBarunGothic' !important;
                    }

                    .header-row0 .div0 {
                        cursor: pointer;
                    }

                    .header-row1 .div1 {
                        width: 270px;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                    .header-row1 .div2 {
                        width: 75px;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }
                    .header-row1 .div3 {
                        width: calc(100% - 340px);
                        height: 40px;
                    }
                    .header-row1 .div3 .col2 {
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .header-row2 .div1 {
                        width: 61px !important;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }
                    .header-row2 .div2 {
                        width: calc(100vw - 270px - 61px);
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                    .header-row2 .div3 {
                        width: 270px;
                        min-width: 270px;
                        max-width: 270px;
                        height: 40px;
                    }
                    .header-row2 .div3 {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .header-row2 .div3 .font1 {
                        margin-left: 15px;
                    }

                    .menu0 {
                        margin-right: 10px;
                    }

                    .menu0:hover,
                    .menu0:active {
                        background-color: #8D8DAA;
                    }

                    .header-row3 {
                        overflow: hidden;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
                    .header-row3 .div1 {
                        width: 100px;
                        height: 96px;

                        display: flex;
                        justify-content: center;
                        flex-direction: row;
                    }
                    .header-row3 .div2 {
                        width: calc(100vw - 380px);
                        display: flex;
                        flex-direction: column;
                    }
                    .header-row3 .col0 {
                        min-height: 46px;
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                    }
                    .header-row3 .div2 .col1 {
                        width: calc(100% - 4px);
                    }
                    .header-row3 .div2 .col2 {
                        width: calc(100% - 4px);
                    }

                    .header-row3 .div1 .font1 {
                        margin-top: 15px;
                    }

                    .header-row3 .div3 {
                        width: 270px;
                        min-height: 40px;
                    }

                    .header-row3 .div3 .font1 {
                        margin-top: 15px;
                        margin-left: 15px;
                    }

                    .optionitem_wrapper {
                        min-width: 140px;
                        display : flex;
                        flex-direction : column;

                        border : 1px solid #000;
                        margin-top: 3px;
                    }
                    .optionitem_wrapper .optionitem0 {
                        min-width: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                    }
                    .valueitem0 {
                        background-color : transparent;
                        color : #000;
                    }

                    html, body {
                        font-size: 8px;
                    }
                `}</style>
            </Header>
        </>
    );
};

export default Comp;
