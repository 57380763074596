
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`위선`,8,12,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,9,11,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,10,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,11,9,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,12,8,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,7,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`위선`,2,6,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,5,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,4,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`위선`,5,3,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,2,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`위선`,7,1,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外생업男上`,`內가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`형제`,`형제관련손모사`,`小`,``,``,``],
    [`친구`,`친구관련사`,`小`,`12`,`반화위복`,`觀書문화`],
    [`색정`,`외남 색정사 有`,`大`,`과시기`,`색정사有`,`다사단합`],
    [`반화위복(反禍爲福)`,`재해가 끝나고 복이 오는 상`,`大`,``,``,`음7월임신`],
    [`방재태질(防災胎疾)`,`출행낙태질역방비를 해야 할 상-반화위복(反禍爲福)관련`,`小`,``,``,`질액방비`],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업직위사`,`大`,`11`,`불리독모`,``],
    [`간음`,`女가 有婦男을 적극 유혹하여 같이 도망할 의사 有`,`小`,`쇠퇴기`,`事後쟁탈`,`탄식신음`],
    [`탄식신음(嘆息呻吟)`,`병 또는 도모사 미수로 탄식신음하는 상`,`小`,``,``,`간음사有`],
    [`불리독모(不利獨謨)`,`단연(斷然) 독단 도모사는 불리한 상`,`大`,``,``,``],
    [`영전`,`천발약진사-발탁불안심 관련사`,`大`,``,``,`추천수상`],
    [`극부`,`남편 갈등사`,`小`,`10`,`영전도약`,`출행이동`],
    [`출행`,`내사 출행이동사`,`小`,`침해기`,`애증화합`,`동요불안`],
    [`천발천약(薦拔遷躍)`,`추천발탁되여 영전도약하는 상`,`大`,``,`근심무방`,`부부이심`],
    [`종무대구(終無大咎)`,`결국 큰 허물이나 재앙이 없는 상`,`小`,``,`도적실세`,``],
    [`박관`,`직업직위불안사`,`大`,``,``,``],
    [`극남`,`男이 有夫女 유혹-자기 부인처럼 여김- 부부반목 갈등 함`,`小`,`9`,`미초희경`,`비곡치욕`],
    [`결속`,`결속해 음12월 동지사를 완성하나 금전이권 처를 훼절함 `,`小`,`소외기`,`복잡단합`,`증오사有`],
    [`부처반목(夫妻反目)`,`가정부부 반목대립사`,`大`,``,`금전처첩`,`부부불화`],
    [`동지결속(同志結束)`,`다수의 동지들이 결속 단합되는 상-금전 처의 재해 有`,`小`,``,`근심발생`,``],
    [`임신`,`임신사-질액낙태 주의`,`小`,``,``,``],
    [`직업`,`직업사`,`大`,`8`,``,`가택이사`],
    [`남편`,`남편 관련사`,`小`,`침체기`,`압박당함`,`수혜사有`],
    [`도적악의(盜賊惡意)`,`도적이 악의를 품고 있으므로공격하지 말아야 하는 상`,`小`,``,`도적악의`,`임신복태`],
    [`침귀음몽(枕鬼淫夢)`,`꿈속에서 기괴정사(情事)를 하는 상-배개 교체할 것`,`大`,``,`공격말것`,`가족질병`],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`사업 동요불안사`,`大`,`7`,`臥病불기`,`급속사有`],
    [`급속-출행`,`내사 급속사-외사 출행이동사`,`小`,`단절기`,`동요불안`,`애증화합`],
    [`왕진쇠퇴(旺進衰退)`,`세력이 왕성하면 있고 세력이 침체하면 퇴해야 이익인 상`,`小`,``,`출행이동`,``],
    [`재난불소(災難不隨)`,`재난이 아직 끝나지 않음- 혼인중매인이 왕래하는 상`,`小`,``,``,``],
    [`금전`,`금전관련사`,`大`,``,``,``],
    [`사업`,`사업사`,`大`,`6`,`비곡차욕`,`구설근심`],
    [`임신`,`이면 임신 관련사`,`小`,`존재기`,`압박가함`,`압박당함`],
    [`비곡수욕(悲哭差辱)`,`용모를 돌볼 수 없는 비극사를 맞는 상-사망사에 통함`,`小`,``,`출행위험`,`임신복태`],
    [`출행불귀(出行不歸)`,`원행하면 돌아올 수 없는 사고가 우려되는 상-前7일 근신`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업사`,`大`,`5`,`관직畏事`,`도둑관련`],
    [`결속`,`결속해 음10월 財기반 완성하나 명예직위남편을 훼절함 `,`小`,`배양기`,`압박당함`,`散髮의혹`],
    [`사송계류(詞訟稽留)`,`재판이 끝나지 않고 대기되어 있는 상`,`小`,``,`사송계류`,``],
    [`관공외봉(官公畏逢)`,`관공직자는 두려움을 만나고 일반인은 흔쾌한 일을 만남 `,`大`,``,``,``],
    [`후원`,`가정 막힘사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`4`,`급속사有`,``],
    [`부친`,`부친관련사`,`大`,`개시기`,`손해도망`,`내부막힘`],
    [`문서신길(文書信吉)`,`문서 소식이 길한 상`,`小`,``,`조력무망`,``],
    [`상인무력(上人無力`,` 윗사람 둘이 모두 무력하여 도움이 안 되는 상  `,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`3`,``,``],
    [`모친`,`모친 관련사`,`大`,`학습기`,`소식대기`,`색정사有`],
    [`색정음사(色情陰私)`,`내女 색정사 有`,`小`,``,`영전도약`,`비용과출`],
    [`음서부지(音書不至)`,`소식을 기다리는 상-와도 나쁨`,`大`,``,``,``],
    [`관사`,`관사직업남편관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`2`,`허성허사`,`형책사有`],
    [`매사 영속성없음`,`관직직업남편남자사는 선천적 기복재해를 겪음`,`小`,`면모기`,`침체경공`,`수혜허사`],
    [`직천래저(職遷阻來)`,`직장 자리는 올겨지고- 간 사람은 오지 않는 상`,`小`,``,`驚恐`,``],
    [`승진영전(昇進榮轉)`,`승진영적도약의 상`,`大`,``,``,``],
    [`명예`,`명예직녹자산권리사`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함 `,`小`,`1`,`간인궤계`,`도적실세`],
    [`매사 영속성없음`,`직녹자산권리사는 선천적 숙명재해를 겪음-독신고독`,`大`,`절정기`,`외교막힘`,`손해가출`],
    [`수동불용(守動不用)`,`현재를 지켜야 하고 적극 움직이는 것은 나븐 상`,`大`,`세속허사`,``,``],
    [`간인궤계(奸人詭計)`,`간사한 자가 꾸민 거짓말-의 상`,`小`,`자로무망`,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,"형제자매친구동료색정비용으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`관사직업남자문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위남자갈등여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`관사직업남편문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,"금전사업처첩조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,"금전사업처첩조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`관사직업남자문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`부친후원문서사로 가능 `,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"모친조력문서색정사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 세워 속결하면 유리함 `,0,0,`정정당당하고 신속히 종결처리해야만 유리하고 좌고우면하거나 지체시키면 되려 불리해지는 人`,0,0,`현재 물러나야 할 사안이나 되려 진행하게 됨**종적없이 어디로 숨고싶은 심정**독신 고독 각방 주말부부 별리 사별의 처지 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 세워 속결해야만 유리함`,0,0,`정정당당하고 신속히 종결처리해야만 유리하고 좌고우면하거나 지체시키면 되려 불리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**옛 일로 되돌아 가 좋게 됨**여자가 남자를 적극 유혹하여 같이 도망갈 속마음 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 명분을 앞세워 먼저 나서서 속결하면 유리함 `,0,0,`정정당당하고 신속히 종결처리해야만 유리하고 좌고우면하거나 사안을 지체시키면 되려 불리해지는 人`,0,0,`신규사 있음**고집있음**1녀2남 삼각관계有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유화적 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 상황에 억울하더라도 순응 유화적 자세로 처신하며  후로 미뤄야만 반드시 결과가 유리해지는 人`,0,0,`좋고 나쁘든 사안이 계속 발현됨**종적없이 어디로 숨고싶은 심정**남자가 타인의 부인을 유혹하여 자기 부인처럼 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 후에 응대함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`타인의 호의를 쉽게 받아들이지 말고 전후좌우를 세심히 잘 살펴야 함**윗인(부모)이 아래(자녀)로부터 핍박을 받음**독신 고독 각방 주말붑 별리 사별 처지 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 격이므로 재난이 밖에서 오니 신중해야 함`,0,0,`반복무상배신사를 겪는 人`,0,0,`신규사 있음**고집있음**색정음사 有**결말이 없고 반복되기만 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 앞세워 속결하면 결과가 유리함`,0,0,`정정당당하고 신속히 종결처리하면 유리하고 좌고우면하거나 지체시키면 결과가 되려 불리해지는 人`,0,0,`종적없이 어디로 숨고싶은 심정**독신 고독 각방 주말부부 별리 사별 처지 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순한 처신으로 사안을 연기시켜야만 유리함`,0,0,`풍상고초 후 성공할 人`,0,0,`복잡한 일을 하나로 묶게되는 상황임**먼저 굽히고 나중에 일어서는 상 임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위적 공격**만약 타인과 다툰다면  피아간 전의가 별로 없고 손상도 없으며 있다해도 가벼움`,0,0,`상대를 공격해도 적중되지 않아 허사무용이 될 人`,0,0,`신규사가 있음**고집이 있음**여건이 부족함**1남2녀 삼각관계 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유화적 처신으로 사안을 연기시켜야만 결과가 유리함`,0,0,`역상적 상황에 억울하더라도 순응유화적자세로 사안을 뒤로 연기시켜야만 결과가 반드시 유리해지는 人`,0,0,`일이 띄엄띄엄 진행되어 물러나려 하나 되려 진행하게 됨**밝은 데로 나아감**독신 고독 각방 주말부부 별리 사별 처지`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 세워 속결처리해야만 유리함`,0,0,`정정당당하고 신속히 종결하면 유리하고 좌고우면하거나 지연시키면 되려 불리해지는 人`,0,0,`사안진행이유리함**기이하게 이루게 됨**관재조심`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 뜻은 움직이나 중지됨`,0,0,`지금 것을 지키며 기회를 기다리는 人 **높은 시험에 통과하는일이 많음`,0,0,`스스로 자부함**기이하게 성취됨**독신 고독 각방 주말부부별리 사별 처지`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`충성`,`악성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`충성`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손모인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-색정사有`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-애증`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-이사의사`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-부부이심동요분산`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-이전예정`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허 색정사有`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`  승`],
    [``,``,``],
    [` 화해`,` 화해`,` 화해`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,` 무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`  승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승  `,`  승`,`  승`],
    [``,``,``],
    [` 무력`,` 무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 화해`,` 화해`,` 화해`],
    [``,``,``],
    [`  승`,`  승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`  승`,`  승`],
    [``,``,``],
    [` 화해`,`화해`,` 화해`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [` 화해`,`화해`,` 화해`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [1,1,2,`음4월`,2,3,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월`,3,2,5,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음2월`,3,2,5,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음11월`,2,3,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음6월`,3,1,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음8월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5월`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음4월`,1,1,2,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음5월`,1,1,2,`음9월-연기 또는파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월`,2,1,4,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월`,2,3,5,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초중반을 허무하게 보내고 종반에 실적을 냄 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일 처리가 강하지 못해도 실용적 효과를 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 종반에 허사가 초래됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 원조를 받아 단합하며 세력도 적절성을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 결국 중반 종반에 허사를 남김`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갇고 상대와 같은 소리를 내며 동요 불안 분산을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속임이 있으나 업무는 무난하며 다만 아래로부터 핍박을 받게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯해도 상대를 잘 조정하는 힘을 가져 여러 사안을 단합하여 개혁 단행으로 시종 업적을 이룸  `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일 처리가 강하지 못해 권리를 양도하게 되어 종반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 초 중반을 기대할 수 없고 종반의 재정 기회도 결국 효과를 못냄 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 기대가 안되고 시종 전화위복적 실적에 의지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 불성실하고 스스로 자부 하나 의지할 데가 없으며 기존의 후원관계성마져 망가뜨리게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,``,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,`반대有3`,`이행`,`선난후난`,`退    背`,`  근`,`去`,`주인후원`,`정남`,``,`정동`],
    [`7.8월`,``,`발현무력`,``,`포기요망`,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`음9월집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,`반대有`,`이행`,`선재후난`,`進    向`,`  원`,`留`,`주인압박`,`남남서`,``,`동남`],
    [`7.8월`,``,`본인제압`,``,`초반압박`,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,`필요`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,`반대없음`,`이행`,`선재후난`,`進    向`,`원`,`去`,`주인세입`,`서남`,``,`남남동`],
    [`7.8월`,``,``,``,`후난애증`,``,``,`질병해소`,`애증화합`,``,``,``],
    [`10.11`,``,``,``,`초반압박`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,`필요`,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`빈대有`,`이행`,`선난후費`,`退    背`,`  원`,`去`,`주인후원`,`정서`,`정서`,``],
    [`7.8월`,`집集`,`대세제압`,``,`포기요망`,``,``,``,``,``,``,``],
    [`10.11`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`음5월집集`,`반대有`,`이행`,`선실후이`,`退    背`,`  원`,`留`,`주인이전`,`북북동`,`남남동`],
    [`7.8월`,``,`종반제압`,``,`천천히`,``,``,``,`이사속뜻`,`세밀심사`,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`이행`,`선費후이`,`進\n向`,`  원`,`去`,`주인피해`,`정동`,`정남`,``],
    [`7.8월`,``,``,``,`후이대립`,``,``,`세밀심사`,`주인세입`,`세밀심사`,``,``],
    [`10.11`,`집集`,``,``,``,``,``,`했음`,`동시동요`,`했음`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대有`,`이행`,`선난후파`,`進    向`,`  원`,`留`,`세입피로`,`정북`,`정서`,``],
    [`7.8월`,``,`중말제압`,``,`포기요망`,`지구전末`,``,``,`설기쇠약`,`세밀심사`,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,`했음`,``,``],
    [`3.6\n9.12`,`음3월집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`집集`,`반대有`,`이행`,`선同후이`,`退    背`,`  원`,`留`,`세입피로`,`북북동`,`북북동`,``],
    [`7.8월`,``,`대세제압`,``,``,``,``,`가나오나`,`설기쇠약`,`세밀심사`,``,``],
    [`10.11`,``,``,``,``,``,``,`동일쇠약`,``,`했음`,``,``],
    [`3.6\n9.12`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,`반대없음`,`이행`,`사안상대`,`進     向`,`  원`,`留`,`주인무력`,`정북`,`동북`,``],
    [`7.8월`,``,``,``,`무력`,``,``,`이전이사\n`,``,``,``,``],
    [`10.11`,``,``,``,`권리양도`,``,``,`되려불성`,``,``,``,``],
    [`3.6\n9.12`,``,``,``,`기미허사`,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대有`,`이행`,`선이후刑`,`退    背`,`  원`,`留`,`주인무력`,`정동`,``,`정동`],
    [`7.8월`,``,`측근제압`,``,`상대무력`,``,``,``,``,``,``,``],
    [`10.11`,`음11월集`,``,``,`포기요망`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`집集`,`반대有`,`이행`,`선이후이`,`進    向`,`  원`,`留`,`무난`,`동남`,``,`동남`],
    [`7.8월`,``,`중말제압`,``,`느긋이`,`요행적幸`,``,``,``,``,``,``],
    [`10.11`,``,`본인화합`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`이행`,`산이후이`,`退    背`,`원근왕래`,`去`,`무난`,`남남동`,`동북`],
    [`7.8월`,``,``,``,`느긋이`,``,``,``,``,``,``,``],
    [`10.11`,`음10월集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,`전흉사귀`,`전사유혈`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`상가부정`,`상가왕래`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`음사귀`,`산소탈`,0,0,`육친`,``,``],
    [`질병`,`도로귀`,`교통사고`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`여귀`,`부인`,0,0,`묘좌`,`동남`,`없음`],
    [``,``,``,0,0,`묘향`,`북서`,``],
    [`생업`,`제례귀`,`제례위범`,0,0,`전경`,`큰강도로`,``],
    [``,``,``,0,0,`묘속`,`水`,``],
    [`가정`,`여귀`,`부인`,0,0,`육친`,`모친`,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`여귀`,`부인`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`여귀`,`부인`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`변사귀`,`객사`,0,0,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,`북좌`,`없음`],
    [``,``,``,0,0,`묘향`,`남향`,``],
    [`생업`,``,``,0,0,`전경`,`강천`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,``,``,0,0,`육친`,`처첩`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`특별있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`여귀`,`부인`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`제례귀`,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`남귀`,`가장`,0,0,`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`상가부정`,`상가왕래`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`사통한자의 거짓모해사`,`빈궁**전도**활인진출하면자립함`,`비상지애사`,`음7,8,10,11월 부자**외 남자 색정사有`,`관직자 영전도약사-일반인 반흉`,`빈궁**관재**전화위복`,`환성경사`,`동요불안이심분산사**화합사**출행사`,`문서헌책답신지체사-구설사`,`빈궁**관재**이동사`,`재난전소사`,`빈궁**관재`,`외사 출행이동사-급속사`,`음1,2,4,5월 부자**추천발탁영전사`,``,`부인사**가나 있으나 좋음`,`觀書-문화풍아`,`빈궁**관재`,`내사 출행이동사-급속사`,`빈궁**병재**가정침체**`,`내 녀색정사`,`事後쟁탈사`,``,`음3,4,5,6,9,12월은 활인진출로 성공할 자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`선천 숙명적 독신고독자`,`외교막힘**매사불성`,``,`전화위복`,``,``,``,`재액해소`,``,`재액해소`,``,``,``,`음4,5월하재조심`,``,`음4,5월화재조심`,``,``,``,`애증손해가출실종우려`,``,``,`매사위역**경구사`,`매사불성세속**허사자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,``,`빈궁**병재**관재**종교진출하면자립함`,`사통한자의 거짓모해사`,`빈궁**활인진출하면자립함`,``,`음7,810,11월 부자**외 남자 색정사有`,`관직자 영전도약사-일반인 흉`,`빙궁**관재**재액해소**전화위복`,``,`동요불안이심분산사**화합사**출행사`,`문서헌책답신지체-구설사`,`빈궁**이동사`,`재난전소사`,`빈궁**관재`,`외사 출행이동사-급속사`,`음1,2,4,5월 부자**추천발탁영전사**기이관재`,``,`가나 있으나 좋음**관재주의`,`觀書-문화풍아`,`빈궁**관재`,`내사 출행이동사-급속사`,`빈궁**병재**가장침체`,`내 녀 색정사`,`事後쟁탈사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`매사불성`,`선천 숙명적 독신고독자`,`외교막힘**매사불성`,`재액해소`,`비상지애**전화위복`,``,``,``,`환성경사`,``,``,``,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,``,``,`애증손해가출실종우려`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내 녀 색정사`,`事後쟁탈사**전도`,``,`빈궁**병재**관재**종교진출하면자립함`,`사통한자의 거짓모해사`,`빈궁*관재**활인진출하면자립함`,`비상지애사`,`음7,8,10,11월 부자**외 남자 색정사有`,`관직자 영전도약사-일반인 반흉`,`빈궁**관재**전화위복`,``,`동요불안이심분산사**화합사**출행사`,`문사헌책답신지체-구설사`,`빈궁**이동사`,`재난전소사`,`빈궁**관재`,`출행이동사-급속사**관재`,`음12,4,5월 부자**추천발탁영전사`,``,`가나 있으나 좋음**관재주의`,`觀書-문화풍아`,`빈국**관재`,`내사 출행이동사-급속사`,`빈궁**병재**전도**가정침체`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`매사불성`,`선천 숙명적 독신고독자`,`외교막힘**매사불성`,`재액해소`,`비상지애**전화위복`,``,``,``,`환성경사`,``,``,``,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,``,``,`애증손해가출실종우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`빈궁**병재**가정침체`,`내사 출행이동사-급속사`,`빈궁**내 녀 색정사有`,`사통한자의 거짓모해사`,`관재**종교진출하면자립함`,`소인쟁재사`,`빈궁**활인진출하면자립함`,`어깨를 나란히하는 반복사`,`부부반목사**외 남자 색정사有`,``,`빈궁**관재**재액해소**전화위복`,`불리 고시-없는 구설 근심사`,`빈궁**동요불안이심분산사**출행사`,`시절을 놓쳤다면 퇴하는 것이 이로움`,`가택이사**질병사관재`,``,`비곡치욕**관재**재액해소`,``,`빈궁**외사 출행이동사-급속사**관재**전도`,`와병 불기사-구설놀램`,`빈궁**전도`,``,`관재**재액해소**부족`,`희경사-단, 부족`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`애증손해가출실종우려`,``,``,``,`매사불성`,``,`외교막힘**매사불성`,`선천 숙명적 독신고독자`,`전화위복`,``,``,``,``,``,``,``,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`관재`,`희경사`,`빈궁**병재**전도**가정침체`,`내사 출행이동사-급속사`,`빈궁**병재**전도`,`사통한자의 거짓모해사`,`종교진출하면자립함`,`소인쟁재사`,`관재**활인진출하면자립함`,`어깨를 나란히하는 반복사`,`부부반목사**외 남자 색정사有**관재`,``,`빈궁**관재**전화위복`,`불리 고시-없는 구설 근심사`,`빈궁**관재**동요불안이심분산사**출행사`,`시절을 놓쳤다면 퇴하는 것이 이로움`,`가택이사**질병사`,``,`비곡치욕**관재`,``,`빈궁**외사 출행이동사-급속사`,`와병 불기사-구설놀램`,`빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`애증손해가출실종우려`,``,``,`내 녀 색정사`,`매사불성**빈궁**병재**전도`,``,`외교막힘**매사불성`,`선천 숙명적 독신고독자`,`전화위복`,`재액해소`,``,``,``,``,``,``,` `,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`관재**충산동요이별`,``,`관재**충산동요이별**전도`,`희경사`,`빈궁*병재**관재**가정침체**재액해소`,`내사 출행이동사-급속사`,`빈궁**관재**재액해소`,`사통한자의 거짓모해사`,`종교진출하면자립함`,`소인쟁재사`,`빈궁**활인진출하면자립함-선천숙명적독신고독자`,`어개를 나란히하는 반복사`,`부부반목사**외 남자 색정사有`,``,`빈궁**관재**전도**전화위복`,`불리 고시-없는 구설근심사`,`빈궁**동요불안이심분산사**출행사`,`시절을 놓쳤다면 퇴하는 것이 이로움`,`가택이사**질병`,``,`비곡치욕**관재**전도`,``,`빈궁**외사 출행이동사-급속사**충산동요이별`,`와병 불기사-구설놀램`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재조심`,``,``,``,`충산동요이별`,`가정손해가출실종우려`,`충산동요이별`,`내 녀 색정사`,`매사불성**관재**전도`,`충산동요이별`,`외교막힘**매사불성`,`충산동요이별`,`충산동요이별`,`전화위복`,`충산동요이별`,``,`충산동요이별`,``,`충산동요이별`,``,`충산동요이별`,``,`음4,5월화재조심`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`빈궁**외사 출행이동사-급속사`,`와병 불기사-구설놀램`,`빈궁`,``,`관재`,`희경사`,`관재**병재**가정침체`,`내사 출행이동사-급속사`,`빈궁`,`사통한자의 거짓모해사`,`관재**종교진출하면자립함`,`소인쟁재사`,`빈궁**관재**활인진출하면자립함`,`어깨를 나란히하는 반복사`,`부부반목사**외 남자 색정사有**전도`,``,`빈궁**관재**전화위복`,`불리 고시-없는 구설 근심사`,`빈궁**동요불안이심분산사**출행사`,`시절을 놓쳤다면 퇴하는 것이 이로움`,`관재**이동사**재액해소**부족`,``,`비곡치욕**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재조심`,``,`음4,5월화재조심`,``,``,``,`애증손해가출실정우려`,``,``,`내 녀 색정사`,`매사불성`,``,`외교막힘**매사불성`,`선천 숙명적 독신고독자`,`전화위복`,``,``,``,`재액해소`,``,`가택이사**질병사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`빈궁**관재`,`재난전소사`,`빈궁**외사 출행이동사-급속사`,``,`화합사**사사완성`,``,`빈궁**관재`,`왕래사송계류사`,`음3,6,9,12,7,8월 부자**가정막힘`,`내사 출행이동사-급속사`,`빈궁`,`사통한자의 거짓모해사`,`빈궁**병재**관재**종교진출하면자립함`,`소인쟁재사`,`활인진출하면자립함`,`선천 숙명적 독신고독자`,`빈궁**외 남자 색정사有`,``,`빈궁**관재**전화위복`,`불리 고시-없는 구설 근심사`,`동요불안이심분산사**출행사`,`신분이 차이나는 음란-존비상몽사`,`가택이사**질병사**이동사**재액해소**부족`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,``,``,`문관출세인`,`애증ㄱ손해가출실종우려`,``,`내 녀 색정사`,`매사불성`,``,`매사불성**전도**외교막힘`,``,`전화위복`,``,``,``,`재액해소부족`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`가택이사**질병사`,``,`관재`,`재난전소사`,`빈궁**외사 출행이동사-급속사`,`구설송쟁사흉-문서소식사는 吉`,`화합사**사사완성`,``,`관재`,`왕래사송계류사`,`음3,6,9,12,7,8월 부자**가정막힘`,`내사 출행이동사-급속사`,`빈궁**전도`,`사통한자의 거짓모해사`,`빈궁**병재**관재**종교진출하면자립함`,`소인쟁재사`,`활인진출하면자립함`,`선천 숙명적 독신고독자`,`빈궁**전화위복`,`외 남자 색정사有`,`빈궁**관재**전화위복`,``,`동요불안이심분산사**출행사`,`신분이 차이나는 음란-존비상몽사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,``,``,`문관출세인**전도`,`애증손해가출실종우려`,``,`내 녀 색정사`,`매사불성`,``,`매사불성**전도**외교막힘`,``,`재액해소`,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,``,`빈궁**동요불안이심분산사**출행사`,`사행불량사`,`빈궁**이동사`,``,`관재**비곡치욕**재액해소`,`외사 출행이동사-급속사`,`음1,2,4,5월 부자**추천발탁영전사**전도`,`말 말고 근신함이 좋음`,`빈궁**전도`,`투서헌책불량함-구설사`,`빈궁**관재**재액해소`,`내사 출행이동사-급속사`,`화합사**혼인사**가정막힘`,`형책죄벌사`,`빈궁**관재`,``,`관재**종교진출하면 자립함**재액해소`,`사통한자의 거짓모해사`,`빈궁**활인진출하면 자립함`,`외 남자 색정사有`,`빈궁**병재**전화위복**부족`,``,`승진영전사**관재**전화위복**부족`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,``,``,`애증손해가출실종우려`,``,`내 녀 색정사`,``,`매사불성`,`선천 숙명적 독신고독자`,`외교막힘**매사불성`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`승진영전사**관재**전화위복`,``,`빈궁**관재**동요불안이심분산사`,`사행불량사`,`빈궁**이동사**재액해소`,``,`비곡치욕**관재`,`외사 출행이동사-금속사`,`음1,2,4,5월 부자**추천발탁영전사`,`말 말고 근신함이 좋음`,`빈궁`,`투서헌책불량함-구설사`,`관재`,`내사 출행이동사-급속사`,`화합사**혼인사**가정막힘`,`형책죄벌사`,`빈궁`,``,`관재**종교진출하면 자립함`,`빈궁**전도**외교막힘**선천숙명적독신고독자`,`음7,8,10,11월은 활인진출로 성공할 자`,`빈궁**병재**전도**전화위복`,`활인진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`재액해소`,``,``,``,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,``,``,`애증손해가출실종우려`,``,`내 녀 색정사`,``,`매사불성`,`사통한자의 거짓모해사`,`매사불성**세속허사자`,`외 남자 색정사有`,`매사불성`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외 남자 색정사有`,`음7,8,10,11월 부자**전화위복`,``,`빈궁**관재**전화위복`,``,`동요불안이심분산사**화합사**출행사`,``,`빈궁**이동사`,`재난전소사`,`관재`,`외사 출행이동사-급속사`,`음1,2,4,5월 부자**추천발탁영전사**전도`,``,`탄식신음**전도`,`문서헌책답신지체-구설사`,`관재**觀書-문화풍아`,`애증손해가출실종우려`,`빈궁**가정막힘`,`형책죄벌사`,`事後쟁탈사**내 녀 색정사`,`빈궁**병재**관재`,`음3,4,5,6,9,12월은 종교진출로 성공할 자`,`빈궁**외교막힘**사통한자의 거짓모해사`,`음7,8,10,11월은 활인진출로 성공함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`비상지애사`,``,``,``,`환성경사`,``,``,``,``,``,`음4,5월화재조심`,`음4,5월화재조심`,``,``,``,`내사 출행이동사-급속사`,`재액해소`,`내 녀 색정사`,`재액해소`,``,`매사불성`,`선천숙명적독신고독자`,`매사불성*세속허사자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 경거망동일세? 
막힌걸 알면서도 또 새 일을 꾸며? 
생활 토대가 비었는데 무슨 일이 되겠어?
`,`■ 표은남산이라! 
숨는것이 편하겠네? 
중상모략을 당했는데 갈수도 앉을수도 없게 생겼어!
⊕ 전사귀, 
⊖ 음귀발동 때문이야!
⊕⊖신변상가부정침범이
 들었어 !
`,`■ 옛날 일을 다시 일으킬 사람이 왔네?
⊕ 男 : 아랫사람과 정을 통하고 있네?
⊖ 女 : 남의 남자를 내 남자로 만들었어!
`,`■ 새일을 꾸미는건 좋으나 한 여자를 놓고 두남자가 다투는 일은 나중에 우환이 생길 일이 야! 
결국 배반되고 말겠어!

⊕신변상가부정침범이네?
`,`■ 힘빠지고 돈깨지는게 계속되고 있네?
男 : ⊕남의 여자를 내것처럼 쓰고 있네?
女 : 가정에 
⊕가택수망노부부정ㆍ자살귀, ⊖가택음사부정ㆍ도로귀가 발    동해서 그래!
`,`■ 물구덩이에 빠진데 다 
옛일, 옛 인연은 모두 끊어졌고 육친까지 등을 돌렸네? 
⊕ 변사귀, 
⊖ 전사귀를 해소하고 나서야      일이 되겠네!
`,`■ 색정사로 배반당했네?
의지할데가없겠어!새일도 바쁘기만 하지효과가 없고
남편 탓할수도 없겠네 !

女 : 남자가 첩을 보아 집을 안들어오네?
⊕ 가택에 음사부정 듬
`,`■ 발뻗고 편히 쉴데가 없겠어! 
안이고 밖이고 모두 적들이니 기도에 매달리는 수밖에! 
그래야 살길이 열려!

⊕⊖신변수망노부부정침범
 이 들었네 ?
`,`■男:돈이고 명예고 직위고       몽땅 다 부서지고 있네 !
女:부부가 이별하겠네 !
⊕ 전사귀, 
⊖ 변사귀 발동때문인데, 
⊖ 집안에 병자가 걱정이야!
`,`■ 한 남자에 두 여자라! 
그러니 사람도 집도 기울지! 
신규사가 있어도 총에 총알이 없는 격이야 !
`,`■ 어둠을 뚫고 아침을 맞았어! 

⊕가택에 전흉사귀ㆍ상가부정, ⊖ 가택귀ㆍ가택부정을 풀고나면 만사 길조야!
`,`■ 가만히 있으면 복이 될 것을 조급하게 굴다가는 관재에 걸려들겠어!
⊕ 음귀, 
⊖ 신불제사귀가 발동해서       경사를 막네?

⊖신변상가부정침범이 들     었어!`];
const YOGYEOL1 = [`■ 낮정단은 덕녹이 천공에 타고 역마에 탈기가 되어 목적에 다 다를수 없다. 인수 장생에 현무가 타서 지키나 움직이나 마찬가지이다.
`,`■ 우선은 고통이고 후에는 없게 되니 물러나 오히려 공이있게 된다. 모든 일을 힘써 하더라도 반드시 막히는 일은 있다.
`,`■ 가정은 기울고 사람도 기우나 말전에서 초전을 도와 밤정단은 부녀자 도망사가 일어나고 음七월점은 가정에 아이가 잉태된다.
`,`■ 앉아서 머리를 쓰는것이 유익하고 움직이고 조급하면 손해가 난다. 현무와 백호가 내게 임했으니 우환과 걱정이 생긴다.
`,`■ 꾀하는 일은 도리어 사기에 걸리고 여자나 금전은 아주 나쁘다. 밤귀인이 정오에 있고 낮귀인은 일몰에 있으니 이치에 어긋나 차질이 난다.
`,`■ 재성이 극을 받으니 밤정단은 처 재물이 손해난다. 어른의 재앙을 방비하라. 백호가 재앙을 부르리라.
`,`■ 서로의 교섭은 절을 만난다. 전후 모두 손해만 있다. 낮정단은 천후와 육합을 보고 두 귀인이 있어도 기쁘지 않다.
`,`■ 사람이고 집이고 묘가 되어 인택이 모두 답답하다. 움직이면 재앙이 되니 이는 삼전이 좋지 않기 때문이다.
`,`■ 간지가 무례한 형이 되고 계속 공망이 되고 있다. 밤정단은 천장이 삼전에 의지할 수 있으나 낮정단은 탈기와 손해뿐이다.
`,`■ 낮정단이나 밤정단이나 반드시 실물이 생긴다. 집안이 적막하니 굽히고 타인에게 의존하라. 두 귀인을 힘이 없어 믿을 수 없다.
`,`■ 귀인이 귀인에 부탁한다. 병은 위독해도 낫게된다. 흉을 흉으로 제어하고 등사로 백호를 물리친다.
`,`■ 지키면 잘되고 조급하게 움직이면 그물에 걸린다. 견우직녀가 합치는 격이니 기쁜 일과 섭섭함이 겹친다.`]
const SIBO1 = [
  `■ 덕ㆍ녹ㆍ고홍 공망자이다.
직․녹의 凶이 있게된다.
자부심이 강하여 지나친 행동으로 매사 허사가 된다. 
신규사에 집중해도 시의심이 발동하여 피차 갈등하고 일이 안된다. 
주의하고, 수양이 필요하다.
옛것을 지키고 때를 기다리라.
`,`■ 가장을 속인다. 가인이
원한을 품고 加害하려 
한다.  권리를 상실한다.
모망사 散하고 허비는 多하다. 
심신의 노고가 크다.
他人에게 복종하고 압박받는 운세이다. 일을 새롭게 하여    하거나 피신하는 것이 좋다. 女는 夫를 亡하게 하는 일을 하고, 男은 직위가 변경되거나 손상이 있다.
`,`■ 남녀 사통사가 있다.
옛것으로 회귀하려는 兆
酒ㆍ女가 吉하다.
사물이 모두 견련되고 지체되어 있다. 人宅이 쇠퇴하고 있다. ⊖女는 男을 적극 유혹하여 家  出할 심사이다. 
男은 여종업원과 연정이 있다. 財문제는 大吉하다.
七月에 임신사가 있다.
`,`■ 女ㆍ婦가 헌신한다.
他人이 스스로 와서
이롭게 한다.
모든 일에서 소비가 크고 손해가 난다. 身安心苦의 형편에 있다. 
一女二男의 삼각관계가 있다. 女子가 무조건 유익을 가져다 준다. 
그러나 그 財는 관재를 일으키는 원인이 되므로 유의하라.
직․녹의 凶이 기한다.
`,`■ 자충수적 우환이 있다.
가인이 원한을 품고 加害
하려 한다. 다인 다사건이다
관재가 있다.
폐지사를 부흥시키려한다. 부부가 음탕하다. 
⊕男은 他人의 부인을 유혹하여 거래하고 있다. 
일도 복작하고 파란이 있다. 부인이나 연인에게 화가 발생한다. 
용이 뱀으로 변한다.
⊕수망노부 ⊖음사부정이 듬
`,`■ 임신사가 있다.
육친간 반목한다.
옛일이 끊어진다.
간난험악의 운세이다. 신고 끝에 생기가 있게 된다. 他人관계는 좋으나 자기와 가정은 불화한다. 금전에 궁색하다. 
얻더라도 잃게 된다. 
모든 일이 허명ㆍ허사가 된다.
男 : 처가 임신했다.유산 주의.
`,`■ 사물이 갈림길에 있다.
상인의 능력이 상실되어
도움 받을 수가 없다.
上下간 타협하라. 
복이 된다. 財의 교섭또한 양호하다.
단, 금전이 災가 됨.


`,`■ 家人이 원한을 품고 加害
하려 한다. 가장을 속인다.
사람이나 가택이나 침체되어 있다. 인근에 적덕을 쌓으면 凶은 피면할 수 있다. 움직이면 불리하고 조용히 때를 기다리는 것이 유익하다. 폐지사를 부흥시키려 한다.
`,`■ 私的활동이 吉하다.
心動이 있다.
다인 다사건이다.
직․녹의 凶이 기한다.
모든 일이 순조롭지 못하다. 吉은 성취되지 못하고 凶도 소산된다. 
부인에 의해 내부에서 일이 기한다. 어떤 일도 처음은 어려우나 나중에 쉽게 풀린다.
현재 되어진 형편에 순응해야 한다.
`,`■ 男ㆍ夫가 헌신적이나 
무능력하다.
금전ㆍ여자는 災가 된다.
財利가 크다. 人ㆍ家의 허비가 크다. 가택권리가 이양된다. 화합하는 것 같으나 심중 불만이 이다. 후에 반드시 성공하게 된다.
금전사유를 명확히 하라.
직․녹의 凶이 기한다
`,`■ 자충수적 우환이 있다.
가장을 속인다. 가인이 
원한을 품고 加害하려 한다.
모망사가 흩어지고, 폐지사를 부흥시키려 한다. 
처음에는 凶하나 후에는 吉하게 된다. 
신중한 처신과 인의를 두텁게 하라. 일부 구설에 신경 쓸 것 없다.
좋다! 
권리 상실사가 기한다.
`,`■ 적극적으로 나서라.
순풍ㆍ순달한다.
名利가 오고, 만사 吉兆이다. 경사가 겹친다. 
어떤 교섭사도 이루어진다. 애써 계획을 짜지 말고 크게 가라. 
계획은 졸책이 된다.
현관문이 훼손된다.`
]
const SIBO2_1 = [`■ 직녹, 신변외사이다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
낭비많고 불명예사 있고 
형제에 재액이 발생한다
`,`■官･夫, 家內우환이 있다.
내방조짐 상의 : 
어떤 일이든 처음 불성한다.
권리 직위상실하고 남편은 망조한다.
`,`■금전여인가택내사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 금전ㆍ여, 가택내사이다.
내방조짐 상의 : 
조용히 있는게 이롭다.
여인구설 음란사가 있다.
`,`■ 손모, 가택내사이다.
내방조짐 상의 : 
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
재·녀 손실 예방요함
`,`■ 금전ㆍ여, 가택 이동의 兆
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 자손, 신변외사이다.
내방조짐 상의 : 
원행·이동사가 있다. 흉재소산ㆍ혼인성립ㆍ가택정단안녕ㆍ정사 利 암사 불리하다.
`,`■ 官･夫, 자충수적 우환
내방조짐 상의 : 
은둔ㆍ도망ㆍ원행에 이롭다.
만사위배, 위액이다.
`,`■ 官･夫, 부부 이별의 兆
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 男직위상실 女부부이별한다.
`,`■ 금전ㆍ여, 가택내사이다.
내방조짐 상의 : 
조용히 있는게 이롭다.
가택명의 양도된다.
`,`■ 손모, 가택내사이다.
내방조짐 상의 : 
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
만사가 피면에 의한다
`,`■ 官･夫, 천우신조의 兆
내방조짐 상의 : 
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [

  `/static/images/sagwa/009/009_001.png`,
  `/static/images/sagwa/009/009_002.png`,
  `/static/images/sagwa/009/009_003.png`,
  `/static/images/sagwa/009/009_004.png`,
  `/static/images/sagwa/009/009_005.png`,
  `/static/images/sagwa/009/009_006.png`,
  `/static/images/sagwa/009/009_007.png`,
  `/static/images/sagwa/009/009_008.png`,
  `/static/images/sagwa/009/009_009.png`,
  `/static/images/sagwa/009/009_010.png`,
  `/static/images/sagwa/009/009_011.png`,
  `/static/images/sagwa/009/009_012.png`,

];

const HEASYEOL = [
  // 1
  `  이 과는 월장이 시(時)에 가해져서 12신이 각각 본궁(本宮)에 있고 4과에 극이 없다. 양일(陽日)은 자기의 강(剛)에 맡기므로 자임격(自任格)이라고 부른다. 스스로 강해져서 앞장서서 행동한다. 즉 자부(自負)․자긍심(自矜心)을 일으키고 지나친 행동으로 화환(禍患)을 초래한다.
  일간의 해(亥)가 초전이 되어 덕록(德祿)으로 되지만 공망하고, 천공(天空)이 승하여 공상공(空上空)을 만나 허사(虛詐)가 되므로 실적(失跡)이 없다. 인(寅)은 일지(日支)의 역마에 해당하고, 친지반은 어느 것이나 육해(六害)로 되어 시의(猜疑)를 생한다. 과전(課傳)이 모두 사맹신(四孟神)으로 되면 어떤 일도 신의(新意)가 있다.
  백법에 말하기를 ｢공상공에 만나면 어떤 일도 추구하지 말아야 한다｣고 하였다. 비전에 말하기를 ｢대인(待人)은 즉시 돌아와도 원래는 내 집에서 나간 사람이며, 먼 곳의 사람이 아니다｣고 하였다. 일간일지(日干日支)의 상신과 지반의 동지(同志)가 어느 것이나 육해로 되면 피아(彼我) 모두 시의(猜疑)가 서로 따른다. 그러므로 함께 일을 계획하지 말아야 한다. 내몸은 상대방에 해(害)를 가하려고 하며 상대방은 내몸을 해치려는 의지가 있다. 그리하여 어떤 일도 주의와 수양을 요한다.
  일귀는 순행하여 삼전은 상(常)․청(靑)․후(后)이고, 야귀는 역행하여 삼전이 공(空)․현(玄)․합(合)이다. 일귀는 인(印)에 승하여 공명이나 구직은 조용히 때를 기다려야 길하다. 야귀는 사(巳)에 승하여 일간상신 해(亥)와 칠충(七冲)하므로 입시․공명은 이루기 어렵다.`
  ,
  // 2
  `  이 과는 제1과가 술해(戌亥)와 상에서 하를 극하여 원수과(元首課)로 된다. 삼전 술유신(戌酉申)은 퇴연여격(退連茹格)이며 반사(返射)라고 부른다. 일간상신에 술(戌)의 귀살(鬼殺)이 승하고 술(戌)이 초전으로 되면 참관격(斬關格)이라고 한다. 그러므로 일귀․야귀 모두 양호(兩虎)가 병하여도 공망하여 두려울 것이 없다.
  초전이 일간상신에서 시작되어 말전 신(申)은 일지에 돌아온다. 이와 같은 것은 조지격(朝支格)이라고 부르며 하(下)에 이롭고 상(上)에 불리하다. 참관(斬關)이 백호를 만나면 사(私)에 이하고 공(公)에 이롭지 않다. 임일(壬日) 천지반 중의 진(辰)이 사(巳)에 가해져 양사(兩蛇)를 승하면 양사협묘(兩蛇夾墓)라고 부르며, 병단은 뱃속에 적괴(積塊)가 있다. 모든 정단사는 힘써 연구하여 모사(謀事)를 이루며, 어떤 사건의 점관(占觀)도 흉은 중하고 길은 경하다.
  참관격이란 술(戌) 또는 진(辰)이 일간일지상신의 어느 것인가에 승하여 초전으로 되는 것을 말한다. 술(戌)은 천괴(天魁)라고 하고, 천관(天關)으로 되는 삼전중에 신유(申酉)를 보면 호음(虎陰)이 참관을 얻는다고 칭하며, 도망하는 자는 영원히 잡지 못한다. 만약 혈지(血支)․혈기(血忌)․양인(羊刃)․대살(大殺)이 함께 할 때는 반드시 사람을 상하게 하고 도주한다.
  이 과는 갱신(更新)하며 외출하는 것이 좋고 또는 도망하는 것이 좋다. 출행․외출 등은 길리(吉利)를 얻어도 집안에 있으면 괴란(怪亂)의 사건을 집안에서 초래한다. 참관격은 노고를 사양하지 말아야 한다. 백법에 말하기를 ｢일간상신 묘(墓)에 호(虎)가 승하면 병의 정단을 두려워한다｣고 하였다. 비전에 말하기를 ｢일간상신이 발용(發用)으로 되며 말전 일지에 돌아오는 것은 조지격(朝支格)이라고 하며 타인에게 복종하고 타인에게 압박되어 몸의 자유를 얻지 못한다. 또 술(戌)을 해(亥)에 가하여 초전을 이루는 것을 괴(魁)가 천문을 건넌다고 한다｣고 하였다. 모든 일은 간난험저(艱難險阻)를 겪은 후에 평탄(平坦)을 얻게 된다.
  일귀는 순행하여 삼전이 백(白)․공(空)․청(靑)이고, 야귀는 역행하여 삼전이 백(白)․상(常)․현(玄)이다. 초전․중전이 공망하고 말전만이 실(實)로 되어 신금(申金)은 일간을 생하는 것을 즐긴다.`
  ,
  // 3
  `  이 과는 제3과 일상에서 일하를 극하여 초전으로 되고 원수과로 된다. 그러므로 역간전(逆間傳)의 오진인(午辰寅)의 삼전은 고조격(顧祖格)이라고 부르며 희기화평(喜氣和平)하다. 오(午)의 화(火)는 인(寅)이 생하여 모(母)를 돌본다는 뜻으로서 편안한 상이다. 그러므로 화평(和平)이라고 부른다. 그러므로 옛것으로 돌아가는 징조가 있고 모든 모사는 어느 것이나 길조이다.
  간전격(間傳格)으로서 역석(逆汐)하고 사물이 유련(留連)과 저격(阻隔)이 있다. 초전의 오(午)는 처재(妻財)로서 말전의 인목(寅木)에 의해 생해지며 구재(求財)․식재(殖財)는 후한 것을 얻는다. 처재(妻財)에 천후(天后), 현무의 음장(陰將)이 승하는 것은 부인으로 한다. 임수(壬水)에서 오(午)는 태(胎)이고 신월(申月)은 생기(生氣)이기 때문에 회임(懷妊)의 징조가 있다. 일간상신 유(酉)는 목욕(沐浴)이 되고 일지 신금(申金)에서 지상신 오(午)를 보면 목욕이다. 따라서 인택(人宅)이 함께 패퇴(敗退)하고 신(身)을 점(占)하면 기혈(氣血)이 쇠약하다. 가택에 황폐의 징조가 있다. 간상(干上) 유(酉)는 여자의 사용인과 주색(酒色)의 일이 있다.
  백법에 말하기를 ｢간지상신이 패세(敗勢)를 만나면 인택(人宅)이 쇠퇴하고 초전은 일간에서 극되어 천장수신(天將水神)에 의해 오(午)가 극된다｣고 하였다. 이와 같은 것은 협극(夾剋)으로 되어 몸이 자유를 얻지 못하게 된다. 초전이 천후(天后)에 말전이 육합(六合)이면 일녀격(泆女格)으로 여자가 남자를 유혹한다. 비전에 말하기를 ｢천후상형(天后相刑)은 암매(暗昧)의 일이고 전극(戰剋)을 만나지 않으면 반드시 혼약은 성취된다｣고 하였다.
  이 과는 현직의 일을 정단하면 퇴직의 징조가 있다. 일간상신 유(酉)는 내몸을 생하여도 오화(午火)에서 극되어 생할 수 없다. 말전 인(寅)은 둔간하여 병(丙)의 편재(便財)로 되고 암재(暗財)라고 부르며 초전의 재(財)와 공통하여 대재(大財)를 얻는다. 일귀는 역행하여 삼전이 현(玄)․후(后)․사(蛇)이고, 야귀는 후(后)ㆍ사(蛇)ㆍ합(合)이며 일녀격(泆女格)으로 된다.`
  ,
  // 4
  `  이 과는 4과이지만 3과밖에 없는 불비격(不備格)이다. 그러므로 사신(巳申)은 상에서 하를 극하여 원수과이며, 삼전 사맹(四孟)은 원태격(元胎格)이다. 일지가 일간상신에 와서 간(干)을 생하는 것은 자재격(自在格)이라고 한다. 그러므로 임(壬)에서 신(申)은 장생(長生)에 해당되지만 삼전이 모두 병태(病胎)에 좌(坐)한다. 일간상신 장생은 좌(坐)하면서 이익을 얻는다. 중전은 탈기(脫氣)되고 말전은 공망하므로써 모든 일에서 소비와 손해가 있다.
  원수과로서 삼전이 병원태격(病元胎格)이면 몸은 편안하지만 마음에 걱정이 있다. 간지(干支) 삼전에 지형(支刑)이 있는 것은 불안의 상이지만 신변의 동작을 조심하면 흉해(凶害)가 없다. 사과의 불비(不備)로 2양1음(二陽一陰)이면 간사(奸私)로서 정의(精意)가 서로 배반된다.
  백법에 말하기를 ｢지형(支刑)이 상에 있으면 주객이 투합하지 못하고, 일지(日支)가 간상(干上)에 가해져서 일간을 생하면 구생격(俱生格)이라고 하여 만사가 유익하다｣고 하였다. 비전에 말하기를 ｢사(巳)가 신(申)에 가해지는 것은 병태(病胎)이며 일간에서 보아서 사화(巳火)는 재(財)가 된다. 그러므로 이 재(財)를 구할 때는 재(財)로 인하여 재해(災害)를 불러온다. 공명․구직을 정단하면 해(亥)의 녹신(祿神)이 공망하여 목적을 달성할 수 없다. 일귀는 역행하여 삼전이 음(陰)․사(蛇)․구(勾)이며 야귀는 삼전이 귀(貴)․합(合)․공(空)이다.`
  ,
  // 5
  `  이 과는 4과 중에 제1과, 제2과, 제4과에 상하의 극이 있다. 제4과의 하에서 상을 극하는 것을 초전으로 한 중심과(重審課)이다. 삼전 자신진(子申辰)이 삼합수국(三合水局)하여 윤하격(潤下格)이 된다. 초전은 순수(旬首)가 자(子)로 되면 육의(六儀)로 되고, 귀신(貴神)이 지반 묘(卯)에 임하여 여덕격(勵德格)이며, 초전에 육합, 말전에 천후가 승하여 교동격(狡童格)이 된다.
  이 격은 남자가 여자를 유혹할 염려가 있다. 중심과일지라도 사과에 상극이 많으므로 일은 다기(多岐) 혹은 주사파란(主事波瀾) 중 어느 쪽이다. 일간 임(壬)에서 보아 삼합수국은 비견으로 되고 제3과와 제4과는 또 같기 때문에 재(財)를 배반하거나 처재(妻財)의 흉을 방지해야 한다. 즉 일간과 동류가 삼전으로 되면 재(財)의 소모가 있거나 처첩(妻妾)의 신변에 흉을 초래한다. 동월(冬月)에 정단하면 삼전이 왕세(旺勢)이고 만사는 뜻대로 수행하여 얻게 된다.
  모두 삼합으로 되면 화미격(和美格)이라고 부르며 기쁜 일이 겹쳐서 발생한다. 귀신(貴神)이 유(酉)의 지(地)에 서면 여덕격이며, 천상(天上)의 일지상신에 임하여 삼전에 승하는 것은 참관격(斬關格)이다. 현임관리․회사중역은 기쁨이 있고 일반 서민은 멀리 도망간다. 교동격(狡童格)은 남녀의 색정 문제가 발생한다.
  일귀는 역행하여 삼전이 합(合)․백(白)․후(后)이며 교동격으로 된다. 야귀는 청(靑)․현(玄)․사(蛇)이고 용이 사(蛇)로 변하여 길이 흉으로 변하는 두려움이 있다. 백법에 말하기를 ｢일귀(日貴)는 야지(夜地)에, 야귀(夜貴)는 주지(晝地)에 있는 것은 귀신차질(貴神差跌)이라고 하여 착오를 초래한다｣고 하였다. 후(后)․합(合)이 승하여 점혼은 자유 결혼의 징조가 있다. 비전에 말하기를 ｢삼전이 삼합으로 되는 것은 유사(類事)에 취하면 구하여 얻을 수 있다｣고 하였다.`
  ,
  // 6
  `  이 과는 사과 중에 삼하에서 삼상을 극한다. 양일양비(陽日陽比)의 극을 취하여 오해(午亥)를 초전으로 하고 섭해도액격(涉害度厄格)으로 된다. 일간상신 오(午)의 재(財)는 초전으로 되고 천후(天后)․현무의 수장(水將)이 승하므로 동류(同類)에 의해 협극(夾剋)으로 된다.
  삼하(三下)가 상을 적(賊)하면 어른․존장에게 이롭지 않다. 섭해(涉害)는 모든 일에서 험저(險阻)․간난(艱難)을 만나 신고(辛苦)를 맛본 후에 임(壬)에서 말전이 장생으로 되어 생기(生機)가 싹트게 된다. 제1과와 제2과의 상신은 육해(六害)로 되고, 제3과와 제4과의 상신이 상합(相合)하여 타인은 화합하지만 자가(自家)는 평화롭지 못하다. 백법이 말하기를 ｢초전이 협극되면 재(財)의 건은 자유를 얻지 못한 다. 삼전은 체생(遞生)하여 타인의 천거를 받는다. 초전의 재(財)는 공망이 좌(坐)하여 재를 얻을 수 없으며 설사 얻더라도 실재(失財)되어 버린다｣고 하였다. 초전은 동기(動機)로 되고 발단문(發端門)이 공망하여 모든 동기는 허명(虛名)으로 되어 진실이 없다. 일귀(日貴)는 역행하여 삼전이 현(玄)․주(朱)․백(白)이고, 야귀는 삼전이 후(后)․구(勾)․현(玄)으로 모두 길장(吉將)으로 되지 않는다. 비전(秘傳)에 말하기를 ｢축(丑)은 복부(腹部)로 한다. 오(午)는 임(壬)에서 십이운(十二運)이 태(胎)이므로 복태격(腹胎格)이라고 하며, 래정(來情)은 임신의 건이다｣고 하였다.`
  ,
  // 7
  `  이 과는 천지 십이궁이 칠충(七冲)을 만나 양일(陽日)로 되면 양비(陽比)의 극이다. 제3과 인신(寅申)을 초전으로 하고 반음지일과(返吟知一課)로 된다. 삼전 맹신(孟神)은 원태격(元胎格)이다.
  제1과․제3과의 사신(巳申)이 지합(支合)하여 교차지합(交車支合)한다. 천지가 상충(相冲)을 반복하므로 모든 일이 불안하다. 그러므로 상은 하를 사랑하고 하는 상을 존경함으로써 싸움을 중지하고 원(怨)을 잊으면 재(災)가 제거되고 자연히 복을 불러온다. 간(干)과 지(支)의 양신(陽神)은 하에서 상을 적(賊)하고 두 음신(陰神)은 하를 극한다. 삼전의 역마가 절지(絶地)를 만나고, 귀신(貴神)이 사(巳)에 승하여 해(亥)의 극을 만나고 묘(卯)에 승하여 유(酉)의 극지(剋地)에 있으니 양귀(兩貴)는 극을 받는다.
  백법에 말하기를 ｢귀가 극을 받으면 귀(貴)를 잃는다｣고 하였다. 비전에 말하기를 ｢교차합재(交車合財)로 되면 재(財)의 교섭은 좋다. 인(寅)이 둔간(遁干)하여 양(兩)의 재(財)로 되어 초․말전이 동일하면 가장 좋다｣고 하였다. 일귀(日貴)는 역행하여 삼전이 사(蛇)․백(白)․사(蛇)이고, 야귀(夜貴)는 순행하여 삼전이 현(玄)․합(合)․현(玄)이며, 어느 것이나 흉장(凶將)이다.
  대인(待人)은 오더라도 또 다시 간다. 병점(病占)은 정신의 이상(異狀)을 초래하기 쉽다. 쟁송(爭訟)은 일지의 가택(家宅)에 사(蛇)와 현(玄)이 승하므로 현사(玄蛇)가 택에 임한다고 하여 대단히 흉하다. 분실물은 일지상신이 설기(泄氣)이고 현무가 승할 때는 가족이 훔친 것이다. 만약 월장(月將)이 인(寅)시일 때는 현무가 월장에 비추어 도적은 반드시 잡을 수 있다.`
  ,
  // 8
  `  이 과는 4과 중에 제1과만이 상에서 하를 극한다. 진해(辰亥)를 초전으로 하는 원수과(元首課)이다. 그러므로 일간상(日干上)에 진(辰)이 있어 초전을 형성하면 참관격(斬關格)이 된다.
  일간상신의 진(辰)은 임(壬)에서 보면 묘(墓)가 되며, 또 일지 금(金)에서 지상(支上)의 축(丑)은 금묘(金墓)이다. 간지가 모두 묘신(墓神)에 복(覆)하면 사람도 택도 혼미(昏迷)하여 통하기 어렵다. 초전은 묘신으로서 해(亥)의 공망의 지(地)에 있고 적덕(積德)을 이룰 때는 원수과이므로 흉을 피면한다.
  백법이 말하기를 ｢간묘(干墓)로 되어 관신(關神)을 병합하면 인택이 퇴축(退縮)한다. 화개일(華蓋日)에 복(覆)할 때는 사람이 혼미하다｣고 하였다. 일귀는 역행하여 삼전이 후(后)․공(空)․사(蛇)이고, 야귀는 삼전이 후(后)․구(勾)․현(玄)으로 흉장이다. 초전이 귀효(鬼爻)이고 둔간하여 또 귀(鬼)로 되므로 병을 정단하면 본래의 병은 치료되지 않고 또 다른 병이 병발한다. 어떤 일을 정단하여도 동(動)하면 불리하고 조용히 때를 기다리는 것이 유리하다.
`
  ,
  // 9
  `  이 과는 제2과와 제4과에 극이 있다. 상을 적하는 제2과 미묘(未卯)를 취하여 초전으로 하는 중심곡직격(重審曲直格)이다. 삼전이 곡직이나 되어도 순합(順合)이 아니므로 어떤 일도 순(順)으로 되지 않는다. 또는 부인으로 인하여 일이 발생한다. 처음은 굴(屈)하고 후에 신(伸)한다. 따라서 사물은 처음은 어려우나 후에는 쉽게 풀린다.
  간지 상신에 자묘(子卯)가 상형(相刑)하고 피아(彼我) 의지가 합치되지 않으며 중․말전이 공망이다. 이와 같은 것은 전(傳)에 행하지 않는다고 칭하며, 길흉은 초전의 일신(一神)에 있다고 한다. 군자(君子)는 기회를 보고 동하여 길사는 성취되기 어려워도 흉사는 소산된다.
  백법에 말하기를 ｢인택이 모두 사신(死神)으로 되면 인택이 쇠퇴한다. 합중살(合中殺)을 범하는 것은 꿀 속에 비소가 들어 있는 징조이다｣고 하였다. 비전에 말하기를 ｢일간 수토(水土)에서 자(子)를 보면 태(胎)이고 자(子)의 수(水)에서 보면 신(申)은 장생에 해당한다｣고 하였다. 이와 같은 것은 태(胎)가 장생에 앉는다고 하며, 임신을 정단하면 길하고 출산은 늦어진다. 임신일(壬申日) 말전의 묘(卯)는 순정(旬丁)이 승하면 자손효(子孫爻)로 되고, 자녀의 재(財)는 공망에 떨어져 재가 없는 것과 같다.
  일귀(日貴)는 순행하여 삼전은 구(勾)․상(常)․귀(貴)로서 어느 것이나 토(土)의 천장(天將)으로 일간(日干)을 극한다. 야귀(夜貴)는 삼전이 주(朱)․공(空)․음(陰)이고 중전이 공중공(空中空)으로 되어 어떤 일도 실의(實意)가 없다.`
  ,
  // 10
  `  이 과는 4과 중에 상하의 극이 없고 제2과 사화(巳火)를 일간에서 극하여 초전으로 한다. 요극과로서 일간에서 천신(天神)을 극할 때는 탄사격(彈射格)이 된다.
  초전은 귀신(貴神)의 재(財)로써 목생화(木生火)와 생지(生地)에 좌(坐)한다. 일간기궁(日干寄宮) 해(亥)는 일지상신에 승하여 신금(申金)에서 생(生)을 받고, 어떤 일이든지 연구하여 후에 반드시 성공하게 된다.
  일간에서 신(申)의 일지는 장생으로 되고 지상신(支上神)은 건록에 해당된다. 일간은 상신의 인(寅)에 탈기하고 일지 신금(申金)은 지상신 해(亥)에 설(泄)하기 때문에 인택이 함께 소모가 많다. 일지(日支)는 지마(支馬)로 되고 일간(日干)은 일지의 역마로 된다. 일간 음신은 재(財)로 되어 용신(用神)이 재성(財星)으로 되니 재리(財利)가 광대하다. 백법에 말하기를 ｢일지상신 건록은 권섭(權攝)이 바르지 않으며 간지상신에 녹마(祿馬)가 승하여 부귀쌍전(富貴雙全)하다. 일간 일지상신이 함께 탈기되는 것은 도난에 주의해야 한다. 공망에 천공(天空)이 승하는 것은 어떤 일도 추급(追及)하지 말아야 한다｣고 하였다. 비전에 말하기를 ｢간지상신이 지합하고 일지의 상하(上下)가 신해(申亥)의 육해(六害)로 되면 외부는 평화로우나 내부는 쟁해(爭害)를 잉태한다. 피아(彼我)의 교섭하는 일은 외관상 화협(和協)하는 것 같지만 심중에 불만을 품게 된다｣고 하였다.
  건록의 공망은 대인(待人)이 오지 않는다. 병증을 정단하면 흉하다. 일귀는 순행하여 삼전이 주(朱)․청(靑) ․상(常)이며, 야귀는 삼전이 귀(貴)․합(合)․공(空)이다.`
  ,
  // 11
  `  이 과는 4과 중 3과에 상하의 극이 있다. 양일로 되면 양비(陽比)이며 하에서 상을 극하는 자술(子戌)을 채용하여 초전으로 한다. 중심과(重審課)로서 간전격(間傳格)이다.
  삼전 자인진(子寅辰)은 향양(向陽)이라고 부른다. 자(子)는 북방 유암(幽暗)의 시각이며, 인(寅)과 진(辰)은 해가 뜨는 시각에 해당된다. 삼양(三陽)에 향하는 상(象)이 되어 암(暗)에서 명(明)으로 들어가고, 처음에는 흉이더라도 후에는 길조로 된다. 질병은 치유로 향하고 싸움은 화해되며 인정은 모두 화합으로 된다. 4과 상하(上下)에 극이 많으며 주사(主事)가 화협(和協)되지 않고 분쟁한다. 만사를 신중히 하고 인의(仁義)를 두텁게 하면 재난을 만나도 타개의 길을 얻게 된다.
  백법에 말하기를 ｢주객(主客)이 투합하지 못하는 것은 지형(支刑)이 상에 있다｣고 하였다. 비전에 말하기를 ｢자(子)가 사계(四季)에 임하고 백호가 승하면 소구고설(小口苦舌)이 빈발하여 재화(災禍)를 불러들인다. 현무를 삼전에서 보면 암암리에 재물의 손실이 있거나 도난에 주의해야 한다｣고 하였다. 순수(旬首)의 자(子)가 발용(發用)이 되면 의신(儀神)이라고 한다. 간상신(干上神)이 축(丑)과 지합(支合)하여 무슨 일이든지 흉이 변하여 길이 된다.
  일귀는 순행하여 삼전이 현(玄)․후(后)․사(蛇)이고, 야귀는 삼전이 백(白)․현(玄)․후(后)이며 천장(天將)이 길로 되지 않는다. 그러나 흉을 변화시켜 화(禍)를 형성하지 않고 명리가 모두 발복(發福)한다.`
  ,
  // 12
  `  이 과는 4과 중 제2과 축자(丑子)가 상에서 하를 극하여 초전으로 되는 원수과(元首課)이다. 삼전은 축인묘(丑寅卯)의 연여격(連茹格)이고 둔간하여 을병정(乙丙丁)의 삼기(三奇)이다. 지반의 전일지(前一支)로 천반을 이루는 것은 나망(羅網)으로 된다.
  원수과는 64과의 수(首)이다. 인묘(寅卯)와 높은데로 부터 하에 임하여 순풍(順風)의 상이다. 순둔간기(旬遁干奇)는 옥당(玉堂)이라고 하여 만사는 길조이다. 축인(丑寅)에서 묘(卯)에 도달하여 태양(太陽)에 바야흐로 지평선상에 양광을 나타내기 때문에 명리가 진달(進達)한다. 삼전이 삼기(三奇)를 띠면 군자(君子)와 상류 사회의 사람은 대단히 길하고, 상인(常人)도 재액(災厄)을 만나지 않고 재액을 해소하는 징조가 있다.
  백법에 말하기를 ｢모사에 의하여 졸책(拙策)으로 되는 것은 나망(羅網)을 만나기 때문이다. 호왕개왕(互旺皆旺)으로 되면 만사가 길조이니 공명을 이루는 나망(羅網)을 논하지 말아야 한다｣고 하였다. 수일(水日)에 정재(丁財)가 동하고 자(子)의 간상에서 순미(旬尾)의 유(酉)가 지상(支上)에 있어 일순주편격(一旬周偏格)으로 되어 시종 서로 길하다. 존숭(尊崇)하는 것은 삼전이 삼기(三奇)를 만난다.
  비전에 말하기를 ｢나망격은 산엽(山獵)에 유익하고, 또 나망(羅網)으로서 삼전 탈기 자손효를 이루면 해망(解網)이라 한다. 흉이 변하여 길이 된다｣고 하였다. 일귀는 삼전이 음(陰)․후(后)․귀(貴)이고 야귀는 삼전이 상(常)․현(玄)․음(陰)으로 되어 천장이 반길반흉이지만 전체적으로는 길로 정단한다. 연주격(連珠格)은 초전에서 중․말전과 일방에 서로 연결되어 길사를 정단하면 경사가 겹쳐서 발생한다. 임신은 계속 회임(懷妊)하고 어떤 일도 교섭하여 거행하는 징조가 있다. 청(晴)일 때는 청이 지속되고 우(雨)일 때는 우가 지속된다. 
  둔간삼기(遁干三奇)는 일월성(日月星)으로 되어 삼광(三光)이라고 부르며 만사는 화합하여 길조가 되므로 후회가 없다. 사과삼전이 자축(子丑)을 띠면 직녀(織女)․견우(牽牛)의 교회(交會)라고 하여 결혼은 반드시 성립되고 경사가 겹쳐서 발생한다. 그러나 자(子)와 유(酉)는 파신(破神)이며 해(亥)의 기궁(寄宮)과 일지(日支) 신(申)은 육해(六害)로 되기 때문에 미(美) 중에 다소 부족을 면하기는 어렵다.`
  ,
]


  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
