
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,5,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,8,4,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,9,3,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,10,2,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,11,1,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,12,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,1,11,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,2,10,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`위선`,3,9,`특별있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,4,8,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,5,7,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,6,6,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`4`,``,``],
    [`자녀`,`자녀 문제사`,`大`,`개시기`,`도적악의`,`문사유실`],
    [`재흉출길(在凶出吉)`,`천복이 도망한 격-. 在家는 나쁘고 출외해야 길한 상`,`大`,``,`공격불가`,``],
    [`사사성취(事事成就)`,`바라는 일이 순조롭게 이루어지는 상-남녀 合室 관계`,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,`기거적의`,``],
    [`문서`,`문서 관련사`,`大`,`5`,`허사허당`,``],
    [`송쟁`,`외사 급속송쟁-파재실종상해사 우려`,`小`,`배양기`,`급속송쟁`,`사사성취`],
    [`유시무종(有始無終)`,`어찌 귿이 모사를 꾸밀까보냐-시작은 있으나 끝이 없는 상`,`小`,``,`상해우려`,`타인시혜`],
    [`모사미수(謀事未遂)`,`일을 꾸며도 완성되지 못하는 상`,`大`,``,``,``],
    [`금전`,`내사 관련사`,`大`,``,``,``],
    [`상업`,`사업 관련사`,`小`,`6`,`송구경황`,`급속사有`],
    [`송쟁`,`처 관련 내사 반목송쟁사`,`小`,`존재기`,`사통사인`,`반목송쟁`],
    [`유의폐구(惟宜閉口)`,`아무리 생각해도 입을 닫아야만 할 상-말보다 文`,`大`,``,`나타남`,`부부불화`],
    [`투서헌책(宜上書獻策`,`상부에 소견을 올리거나 책략을 제시하면 좋은 상`,`小`,``,``,``],
    [`결속`,`결속하여 권리사를 크게 세우나 본인 병, 형재재해 발생함`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`7`,`최고인拜`,``],
    [`색정`,`외男 색정사 有`,`小`,`단절기`,`화합사有`,`화소복지`],
    [`결속승헌(結束乘軒)`,`고위관료에 임명되는 큰 경사의 상`,`大`,``,`색정음사`,``],
    [`북향개배(北向改拜)`,`절을 고쳐 해야 할 고위로부터 직위를 받는 상-입조 입각`,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`8`,``,``],
    [`부친`,`부친 관련사`,`小`,`침체기`,`동요분산`,`고시낙제`],
    [`손괴재물(損壞財物)`,`되돌아 올 수 없는 손실사 有`,`小`,``,`虛獲驚懼`,`사송무방`],
    [`등산재야(登山在野)`,`산과 들의 출입을 조심해야 할 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`9`,``,``],
    [`남편`,`남편 관련사`,`小`,`소외기`,`총애다툼`,`가정막힘`],
    [`고위무력(高位無力)`,`윗사람이 힘을 못써주는 상`,`小`,``,``,`필유성취`],
    [`허획경구(虛獲驚懼)`,`도적이 되돌아갔는데도 놀래서 붙잡으려 하는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`10`,`간음무도`,`색정사有`],
    [`색정`,`내女 색정사 有`,`小`,`침해기`,`단합결속`,`비용과출`],
    [`재실수옥(財失囚獄)`,`금전망실사로 죄수가 되는 상`,`小`,``,`외화내허`,`사송계류`],
    [`간음무도(姦淫無道)`,`색정사가 지나친 상`,`小`,``,``,`구속우려`],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`결속`,`개신하여 음8월 동지사를 완성하나 財,妻 이별사 발생함`,`大`,`11`,`급속송쟁`,``],
    [`송쟁`,`급속대립송쟁사-상해 우려`,`小`,`쇠퇴기`,`파재실종`,`후퇴유리`],
    [`처재유손(妻財有損)`,`처와 금전을 동시에 잃는 상`,`小`,``,`상해우려`,``],
    [`쇠즉의퇴(衰卽宜退)`,`왕세 進-衰 즉 물러나는 것이 좋고 전진하면 나쁜 상`,`大`,``,``,``],
    [`형제`,`형제친구동료관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`12`,``,``],
    [`매사 영속성없음`,`형제친구동료동지재손여인사선천적 기복재해를 겪음`,`大`,`과시기`,`송구경황`,`애증화합`],
    [`괴이천전(怪異遷轉)`,`일반적 상식이 아닌 상태로 옮겨다니는 상`,`小`,``,`사통인이`,`파재상해`],
    [`정위난측(情僞難測)`,`사통한 자의 마음을 가늠하기 어려운 상`,`大`,``,`나타남`,`우려`],
    [`직녹`,`직업직위승급권리 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`1`,`손해도망`,`莫入당도`],
    [`매사 영속성없음`,`직업자산권리남편남자사는 선천적 기복재해를 겪음`,`大`,`절정기`,`직녹자산`,`개신개변`],
    [`불감지자(不甘之者)`,`결코 달가운 사람이 아닌 상`,`小`,``,`百事俱憂`,`逢林有蛇`],
    [`참망이간(讒忙離間)`,`모함한 자의 거짓으로 사이가벌어진 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`2`,``,``],
    [`부친`,`부친 관련사`,`小`,`면모기`,`외교막힘`,`가정침체`],
    [`상인흠봉(常人欣逢)`,`일반인은 흔쾌한 일을 만나고 관직자는 직책불만이 있는 상`,`小`,``,`소식대기`,`추천발탁`],
    [`천발천약(薦拔遷躍)`,`추천발탁되어 영전하는 상`,`大`,``,`와도 나쁨`,``],
    [`박관`,`직업직위불안사`,`小`,``,``,``],
    [`岐路출행`,`남편 갈등사-동요불안분산사-원행이동 기로사 有`,`小`,`3`,`급속사有`,`동요분산`],
    [`자녀`,`자녀관련사`,`大`,`학습기`,`존비상몽`,`출행이동`],
    [`출석수두(出石獸頭0`,`가정에 돌로된 짐승머리로 인해 가장이 불년함`,`大`,``,`신분나이`,`불변근신`],
    [`불변근신(不辯謹愼)`,`장차를 위해 말을 닫고 근신해야 하는 象`,`小`,``,`차이불륜`,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`직업직위불안부부갈등자녀문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,`후원문서자매친구동료모친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,`금전사업처접조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`금전사업처접조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`후원문서형제친구동료부친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,``,`관사직업남편가정막힘으로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,``,`관사직업색정사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`후원문서자매친구동료모친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`후원문서부친조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,``,`직업직위불안부부갈등자녀문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 선수적 행동으로 서둘러 종결해야만 유리함`,0,0,`정당한 사안이므로 먼저 행동하여 급속히 종결해야만 유리하고 늦어지거나 미루면 되려 불리하게 될 人`,0,0,`일이 반복되기만 하고 결과가 없으**현재 사안에서 물러나야 할 입장임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 대결하지 말고 순응 유화적 처신으로 대하며 사안을 뒤로 미루면 결과가 유리해 짐`,0,0,`역상적 사안이므로 억울하더라도 절대 먼저 나서지 말고 유순히 나중으로 미루면 유익하게 결말되는 人`,0,0,`일이 역상적으로 띄엄띄엄 진행되나 계속 진행하게 됨**사물이 더디나 돌연적으로 일어남색정사의 근심이 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 피아간 전의가 별로 없고 있더라도 손상이 가벼움`,0,0,`호가호위를 꾸며 상대를 공격하나 빗나가게 되어 공격의 목적을 이루지 못함`,0,0,`고집이 있고 신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 선수적 행동으로 서둘러 종결해야만 유리함`,0,0,`정당한 사안이므로 먼저 행동하여 급속히 종결해야만 유리하고 늦어지거나 미루면 되려 불리하게 될 人`,0,0,`여러 일을 하나로 결속시키는 단합을 완성하나 외화내허하고 결국 피해를 당함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 방심하면 불리함`,0,0,`풍산고로 후 성공할 人`,0,0,`인정을 펴 인심을 얻어야 좋음**직위승급자산이 끓김**1남2녀 삼각관계 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상으로 재난이 밖에서 오게 되니 신중해야 함`,0,0,`반복 색정사로 의지거처가 불안한 人`,0,0,`색정사가 반복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 대결하지 말고 순응 유화적 처신으로 대하며 사안을 뒤로 미루면 결과가 유리해 짐`,0,0,`역상적 사안이므로 억울하더라도 절대 먼저 나서지 말고 유순히 나중으로 미루면 유익하게 결말되는 人`,0,0,`1녀2남 삼각관계有**일이 반복되고 종결이 안 됨**관직자는 좋음**윗사람(父)이 아래(子息)로부터 핍박받음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`여러 사안을 하나로 묶는 개신개혁사를 도모하나 완성하지 못함**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 대결하지 말고 순응 유화적 처신으로 대하며 사안을 뒤로 미루면 결과가 유리해 짐`,0,0,`역상적 사안이므로 억울하더라도 절대 먼저 나서지 말고 유순히 나중으로 미루면 유익하게 결말되는 人`,0,0,`신규사가 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 피아간 전의가 별로 없고 있더라도 손상이 가벼움`,0,0,`호가호위를 꾸며 상대를 공격하나 빗나가게 되어 공격의 목적을 이루지 못함`,0,0,`밝은 데로 나아가는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 피아간 전의가 별로 없고 있더라도 손상이 가벼움`,0,0,`조작한 일이 들통나 천둥에 놀라는 듯한 심중 두려움이 있으나 근신하면 자연 해소될 人`,0,0,`진행사를 퇴하려 하나 계속 진행하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지 않음`,0,0,`스스로 유약하다고 여기면 활동할 조건이 안 돼신음하는 人`,0,0,`신구사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`충성-무력`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면-무력`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아타동시발동사`,`기사`,`신구중간사`,`내외동시사`,`표면이면동시사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성-무력`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사-급속사 포함`,`기사`,`신사`,`내사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`공허무력`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`공허무력`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-고위`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`타세력피해`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥-고위`,`공허불육`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`공허불육`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥-고위`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [` 후승`,`후승`,` 후승`],
    [``,``,``],
    [` 초승`,`초승`,` 초승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해됨`,`화해됨`,`화해됨`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음3월-연기우려`,3,3,6,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음2월`,1,2,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월`,2,1,3,`음4월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,2,2,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음3월`,1,3,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,1,1,2,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,1,1,2,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음6월`,2,1,3,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음3월`,3,3,6,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음6월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받아 재정을 확충하고 시종 업적을 빛냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 초반에 업적을 남기지 않으면 중 종반에 빛을 잃음`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 상대를 잘 조정하고 다만 초반에 주력하지 못하면 중 종반에 빛을 잃음`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 일관적 단합을 완성하나 외화내허적이며 결국 피해를 끼치게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세고 초 중반에 실적을 내나 종반에 자산을 잃게 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 많아 시종일관 동요불안분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일처리가 강하지 못해 시종일관 허사공허무력함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 어무 전반이 허사 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행에 불성실한 면이 있고 초 중반이 허사이고 종반의 재정 실적 뿐임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 업무도 무난함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 업무는 일반 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 시종 반목대립하고 송사에 이르게 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`多集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인무력`,`退背`,`원`,`去`,`세입설기`,`정북`,``,`남남서`],
    [`10.11`,`集`,``,``,``,``,``,``,`피로쇠약`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인무력`,`退背`,`  원`,`  去`,`주인후원`,`북북동`,``,`북북동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선난후난`,`進向`,`  원`,`留`,`주인후언`,`남남서`,``,`동북`],
    [`10.11`,`  集`,``,``,``,``,``,``,`이심반목`,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`세력있음`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`세입설기`,`정남`,`정동`,``],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,`피로쇠약`,``,`세밀심사 `,``],
    [`3.6\n9.12`,``,`됨`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선난후난`,`退背`,`  원`,`  去`,`주인가해`,`동남`,`남남서`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선난후난`,`向進`,`  원`,`  留`,`주인가해`,`남남동`,`정남`,``],
    [`10.11`,``,`함`,``,``,``,``,``,``,``,`세밀심사 `,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선費후이`,`進向`,`  근`,`  留`,`세입설기`,`정남`,`북북동`,``],
    [`10.11`,`  集`,``,``,`천천이`,``,``,``,`피로쇠약`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`후반반대`,`이행`,`사안상대`,`退背`,`  근`,`   去`,`주인무력`,`서북`,`동북`,``],
    [`10.11`,``,`제압불가`,``,`무력`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,`연기要됨`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`사안상대`,`進向`,`  원`,`  留`,`주인무력`,`북북서`,`동남`,``],
    [`10.11`,``,``,``,`무력`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선이후난`,`退背`,`  원`,`   去`,`세입설기`,`정북`,``,`남남동`],
    [`10.11`,`  集`,`본인제압`,``,`빠르게`,``,``,``,`피로쇠약`,``,``,`세밀심사`],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선난후不`,`退背`,`  원`,`  去`,`무난`,`북북동`,``,`북북동`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부동조`,`이행`,`선不후이`,`進向`,`  근`,` 留`,`무난`,`동북`,``,`북북서`],
    [`10.11`,``,`본인제압`,``,`천천히`,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,``,`함`]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`저주부정`,`구설저주`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`제례귀`,`제례위범`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`제례귀`,`제례위범`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`수귀`,`익사`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`수귀`,`익사`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`물품부정`,`토기기물`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,`고모신사`,`성인위인`,`묘속`,0,``],
    [`가정`,``,``,``,`영정훼손`,`육친`,0,``],
    [`질병`,`수귀`,`익사`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`특별있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`성인귀`,`성소침해`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`성인귀`,`성소침해`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`도로귀`,`교통사`,``,``,`묘좌`,`남남동`,`없음`],
    [``,``,``,``,``,`묘향`,`북북서`,``],
    [`생업`,``,``,`제례부정`,`제례위범`,`전경`,`화원`,``],
    [``,``,``,``,``,`묘속`,`뱀굴`,``],
    [`가정`,`도로귀`,`교통사`,`음사부정`,`제사잘못`,`육친`, `모친`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`가정동요불안분산사`,`소식안옴-와도 불리함**외사 급속사`,`빈궁`,`백 가지가 근심인 격`,`전도`,`도모 미수사`,`곤궁`,`상서헌책사-좋음`,`관재**외男색정사`,`사사성취`,`전도**곤궁**외사동요불안분산사`,`매사위역사**문서유실사`,`관재**내부가정막힘`,`말 않고 근신이 좋음`,`내女색정사`,`발탁영전도약희사`,`외사급속송쟁실종상해사 우려`,`송구경황-두려운 사람 당도 관련`,`매사허성불성**세속허사 자**독신 또는 고독`,`음3,6,7,8,9,12월 방문자는 종교진출하면 `,`곤궁**매사영속성없음`,`종교진출하면 자립함`,`침체경구**외교막힘`,`전도**관재**상하 존비 불화사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 출행원행이동사`,`빈궁**병재`,``,`전화위복`,``,``,`내사 급속사`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`병재**외사 출행이동사`,`성공함`,``,``,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`관재**외교막힘`,`상하 존비 불화사`,`가정동요불안분산사`,`소식안옴-와도불리함**외사 급속사`,`전도**빈궁`,`백 가지가 근심인 격`,``,`도모 미수사`,`곤궁**관재`,`상서헌책사-좋음`,`외男색정사`,`사사성취`,`빈궁**외사동요불안분산사`,`매사위역사**문서유실사`,`관재**내부가정막힘`,`말 않고 근신이 좋음`,`관재**내女색정사`,`발탁영전도약희사-부족함`,`외사급속송쟁실종상해사우려`,`송구경황-두려운 사람 당도 관련`,`병재**매사영속성없음`,`종교진출하면 자립함`,`빈궁**매사영속성없음`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`외교막힘`,`내사 출행원행이동사`,`전도**병재**빈궁`,``,``,`전화위복`,``,`내사 급속사`,`재액해소`,``,`재액해소`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`외사 출행이동사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`빈궁**매사영속성없음`,`종교진출하면 자립함`,`전도**외교막힘`,`상하 존비 불화사`,`빈궁**병재**가정동요불안분산사`,`소식안옴-와도불리함`,`빈궁`,`백 가지가 근심인 격`,`전도`,`도모 미수사`,`상서헌책사-좋음`,`곤궁`,`외男색정사`,`사사성취`,`전도**빈궁**외사동요불안분산사`,`매사위역사**문서유실사`,`빈궁**관재**내부가정막힘`,`말 않고 근신이 좋음`,`관재**내女색정사`,`발탁영전도약희사-부족함`,`외사급속송쟁실종상해사 우려`,`전도**송구경황-두려운 사람 당도 관련`,`빈궁**병재**매사영속성없음`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,`외교막힘`,`내사 출행원행이동사`,`외사 급속사`,``,``,``,`전화위복`,`내사 급속사`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`외사 출행이동사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`전도**빈궁**병재**매사영속성없음`,`종교진출하면 자립함`,`전도**매사영속성없음`,`종교진출하면자립함`,`곤궁**소송계류사-퇴피가 좋음`,`매사위역사**외교막힘`,`가정동요불안분산사`,`필유성취**외사 급속사`,`고시낙제-송사 무방함`,`곤궁`,`재화 소제-복이 옴`,`곤궁`,`개인방문사적부탁면담이 좋믐`,``,`외男색정사`,`간음무도-무례`,`곤궁**총애다툼사`,`매사위역사**외사동요불안분산사`,`내부가정막힘`,`붙잡지는 못하고 노래기만 하는 상`,`냐女색정사`,`절을 두 번 해야하는 대경길사-입각하는 상`,`빈궁**관재**병재**손괴재물사`,`외사급속송쟁실종상해사 우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`마음 측정이 어려움`,`외사 출행이동사`,`불의 진취사`,`전화위복`,``,`전화위복`,`내사 출행원행이동사`,`재액해소`,``,`재액해소`,``,`전화위복`,`내사 급속사`,``,``,``,``,``,``,`음4,5월 화재주의`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`관재**병재**빈궁**손괴재물사`,`외사급속송쟁실종상해사 우려`,`곤궁**병재**매사영속성없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`빈궁**소송계류사-퇴피가 좋음`,`매사위역사**외교막힘`,`전도**가정동요불안분산사`,`필유성취`,`고시낙제-송사무방함`,`전도**관재**빈궁`,`재화소제-복이 옴`,`빈궁`,`개인방문사적부탁면담이좋음`,`관재`,`외男색정사`,`간음무도-무례`,`빈궁**총애다툼사`,`매사위역사**외사동요불안분산사`,`빈궁**관재**내부가정막힘`,`붙잡지는 못하고 놀래기만 하는 상`,`관재**내女색정사`,`절을 두 번 해야하는 대경길사-입각하는 상`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`마음 측정이 어려움`,`외사 출행이동사`,`불의 진취사`,``,``,`전화위복`,`내사 출행원행이동사`,`외사 급속사`,``,``,``,`재액해소**전화위복`,`내사 급속사`,`재액해소`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`절을 두 번 해야하는 대경길사-입각하는 상`,`내女색정사`,`곤궁**병재**손괴재물사`,`외사급속송쟁실종상해사 우려`,`전도**매사영속성없음**외사 출행이동사`,`종교진출하면자립함`,`관재**매사영속성없음`,`종교진출하면자립함`,`소송계류사-퇴피가 좋음`,`곤궁**매사위역사**외교막힘`,`관재**가정동요불안분산사`,`필요성취**외사 급속사`,`고시낙제-송사무방함`,`빈궁**관재`,`제화소제-복이 옴`,``,`개인방문사적부탁면담이 좋음`,`관재`,`외男색정사`,`간음무도-무례`,`곤궁**총애다툼사`,`매사위역사**외사동요불안분산사`,`빈궁`,`붙잡지는 못학고 놀래기만 하는 상`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재주의`,`동요불안분산사`,``,`동요불안분산사`,`상대 마음 측정이 어려움`,`동요불안분산사`,`불의 진취사`,`동요불안분산사**전화위복`,`전화위복`,`동요불안분산사`,`내사 출행원행이동사`,`동요불안분산사**재액해소`,``,`동요불안분산사**재액해소`,`전화위복`,`동요불안분산사`,`내사 급속사`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사**음4,5월 화재주의`,`동요불안분산사`,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`붙잡지는 목하고 놀래가만 하는 상`,`곤궁`,`절을  두 번 해야하는 대경길사-입각하는 상`,`내女색정사`,`전도**병재**빈궁**손괴재물사`,`외사급속송쟁실종상해사 우려`,`빈궁**병재**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면 자립함`,`빈궁**소송계류사-퇴피가 좋음`,`매사위역사**외교막힘`,`가정동요불안분사사`,`필요성취`,`고시낙제-송사무방함`,`곤궁`,`재화소재-복이 옴`,`전도**빈궁`,`개인방문사적부탁면담이 좋음`,``,`관재**외男색정사`,`간음무도-무례`,`전도**관재**빈궁**총애다툼사`,`매사위역사**외사동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내부가정막힘`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`상대 마음 측정이 어려움`,`외사 출행이동사`,`불의 진취사`,``,``,`전화위복`,`내사 출행원행이동사`,`외사 급속사`,``,``,``,`전화위복`,`내사 급속사`,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`곤궁**총애다툼사`,`매사위역사**외사동요불안분산사`,`허획경구(虛獲驚懼)사`,`빈궁**관재`,`절을 두 번 해야하는 대경길사-입갇하는 상`,`관재**내女색정사`,`곤궁**병재**손괴재물사`,`외사급속송쟁실종상해사 우려`,`빈궁**병재**매사영속성없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`빈궁**소송계류사-퇴피가 좋음`,`매사위역사**외교막힘`,`가정동요불안분산사`,`필유성취`,`고시낙제-송사무방함`,`빈궁`,`재화소제-복이 옴`,`빈궁`,`개인방문사적부탁면담이 좋음`,`전도`,`전도**외男색정사`,`간음무도-무례`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`내부가정막힘**재액해소`,`음4,5월 화재주의`,`재액해소`,`음4,5월 화재주의`,``,``,`상대 마음 측정이 어려움`,`외사 출행이동사`,`불의 진취사`,``,``,`전화위복`,`내사 출행원행이동사`,`외사 급속사`,``,``,``,`전화위복`,`내사 급속사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`전도**빈궁**재난불측사`,`외男색정사`,`빈궁**문서유실사`,`매사위역사**외사동요불안분산사`,`관재**모두 흉함`,``,`빈궁**관재**타인연루사`,`내女색정사`,`발탁승진도약길사`,`외사급속송쟁실종상해사 우려`,`곤궁**병재**매사영속성없음`,`종교진출하면 자립함`,`곤궁**병재**매사영속성없음`,`종교진출하면 자립함**전화위복`,`상하 존비 불화사`,`매사위역사**외교막힘`,`빈궁**가정동요불안분산사`,`필요성취`,`빈궁`,`처첩상회`,``,`송구경황-사통 상대가 공포인임을 알게됨`,`개인방문사적부탁면담이 좋음`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`내부가정막힘**재액해소`,`음4,5월 화재주의`,`재액해소`,`음4,5월 화재주의`,``,``,`상대 마음 측정이 어려움`,`외사 출행이동사`,`불의 진취사`,`가족절상사고우려`,``,`전화위복`,`내사 출행원행이동사`,`외사 급속사`,``,``,``,`전화위복`,`내사 급속사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내사 급속사`,`무고이간질사`,`모두 불리하고 되려 도난당함`,`곤궁**관재**외男색정사`,`전도**곤궁**총애다툼사`,`매사위역사**외사동요불안분산사`,`관재**내부가정막힘`,`화장이 지워질 정도의 비곡치욕`,`내女색정사`,`발탁영전도약희사`,`외사급속송쟁실종상해사 우려`,`전도**곤궁*숲에서 뱀을 만난 격`,`곤궁**병재**매사영속성없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면자립함`,`전도**곤궁**매사위역사**외교막힘`,`사송 계류사-퇴피가 좋음`,`가정동요불안분산사`,`有비상사`,`빈궁**관재**내부가정막힘`,`백 가지가 근심인 격`,`전도**관재**병재`,`손괴재물-이전이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외사 출행이동사`,``,``,`전화위복`,``,`내사 출행원행이동사`,`외사 급속사`,``,``,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`모사 미수사`,`곤궁**관재`,`상서헌책사-좋음`,`외男색정사`,`사사성취`,`빈궁**외사동요불안분산사`,`매사위역사**문서유실사`,`빈궁**관재**내부가정막힘`,`송구경황-두려운 사람 당도 관련`,`내女색정사`,`발탁영전도약희사`,`외사급속송쟁실종상해사 우려`,`말 않고 근신이 좋음`,`매사영속성없음`,`종교진출하면자립함`,`매사허성불성**세속허사 자**독신또는 고독`,`음3,6,7,8,9,12월 방문자는종교진출하면`,`외교막힘`,`상하 존비 불화`,`가정동요불안분산사**재액해소`,`소식안옴-와도불리함**곤궁**병재**외사 급속사`,`곤궁`,`백 가지가 근심인 격`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`전화위복`,`내사 급속사`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`전도**빈궁**병재`,`외사 출행이동사`,`전도**빈궁**전화위복`,`성공함`,``,``,`내사 출행원행이동사`,`폐한인**전화위복`,``,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`빈궁`,`백 가지가 근심인 격`,``,`모사 미수사`,`빈궁`,`상서헌책사-좋음`,`외男색정사`,`사사성취`,`빈궁**매사위역사**문서유실사`,`외사동요불안분산사`,`내부가정막힘`,`송구경황-두려운 사람 당도 관련`,`내女색정사`,`발탁영전도약희사`,`외사급속송쟁실종상해사 우려`,`말 않고 근신이 좋음`,`매사허성불성**세속허사 자**독신또는 고독`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`매사허성불성**세석허사 자**독신 또는 고독`,`음3,6,7,8,912월 방문자는 종교진출하면`,`외교막힘`,`상하 존비 불화`,`가정동요불안분산사**재액해소`,`곤궁**병재**소식안옴-와도불리함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`재액해소`,`전화위복`,``,`내사 급속사`,``,``,``,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`외사 출행이동사`,`성공함`,``,`성공함`,`전화위복`,``,`내사 출행원행이동사`,`외사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 집이고 일이고 ⊕자살귀, ⊖도로귀가 방해를 해서 관송사가 벌어지지! 정도로만 
처신을 해야 되겠어 !
아직 새 일을 꾸밀때도 아니고!

⊖가택음사부정이 들었다.

⊖신변신화선영부정이침    범 했네 ?
`,`■ 집안에 저주부정이 들어서 일마다 반복만 되고 퇴보
되기만 하겠어 !
이사를 해야만 어른의 재앙을 막게 생겼고 !

⊕⊖가택저주원심부정이 듬

⊕신변상가부정이 들었네?
`,`■돈복은 타고 났는데 색정사 근심은면하지를못하겠어! 우연히 집안 어른의 걱정을 만나게 생겼어!

⊕가택음사부정이 들었다.
`,`■ 금전사로 상대를 공격할 일이 있겠는데, 적중은 안되겠어! 내가 손해고 상대가 유리한 운세야 !
기도하면 만사 유익하게 될거야 !  그냥 돌아가세요!

⊕신변상가부정이들었네?
`,`■ 겉은 화려해 보여도 빛좋은 개살구 격이라!
결국 내몸을 치게 되니

⊕신불제사귀,가택음사부정이   들었다 
⊖신부정귀를해소시켜야 해!

⊕신변신화선영부정이     침범했네 ?
`,`■ 하늘에서 더 이상 복을 안주니 용퇴할 준비가 필요하겠어!
권리를 잃고 상하가 없겠어 !
一男二女 색정사도 그렇고! 
골육간 화목도 끝났겠어!
`,`■ 색정불화가 있네 ! 또 새 일을 하려는데 옛날일이 발목을 잡겠어! 
성패가 가늠이 안되는데 
고생밖에 더하겠어?
⊕수귀, 
⊖여귀 발동이나 잡고 봐야지!
⊕⊖신변여고음강부정이       들었네 ?
`,`■ 一女二男 색정사가 
  화근이네?골육상배, 신택불안, 어른흉동! 
가택에 고모신사 부정이 들어서 그래! 

⊕일이반복되기만하겠어!
`,`■금전 여자 일을 개혁해야겠는데 질투 의심으로 기로에 섰어! 
男:자칫 처첩을 상실하겠어 !
⊖男:타인의 부인을 유혹해      거래하네
  女:돈이 크게 빠지겠어 !
⊕수귀, 
⊖여귀발동이네?
`,`■ 신규사가 있겠는데, 기도없이 일이 되겠어?
그러니 마음도 뜨고 사람도 없어지고 집도 비게 되지! 기도하세요!

⊖신변상가부정이들었네?
`,`■ 활을 쏴도 맞지 않고 일도 뜨문뜨문하니 답답하겠는데, 평소 돈 쓰면서 인심을 얻어놓으면 큰일이 되겠어!
⊕전사귀, 
⊖가택음사부정이 들었다.
  신부정귀도 해소시키고!
`,`■ 마음이 크게 놀랬네?
호가호위일 뿐이니 잠자코 있으면 자연히 해소되는 일이야! 
그런데 집에 
⊕전사귀, ⊖신부정귀가 들어    부부불화가 있겠어!
⊖신변상가부정이 들었어 !`];
const YOGYEOL1 = [`■ 여행을 간다해도 중도에 돌아오게 된다. 모든 일도 중간에서 막히게 되리라.
`,`■ 사람발에 바퀴를 달았으니 어찌 가만히 있을까. 음2월 점은 관문을 돌파하니 만리밖으로 나가리라.
`,`■ 밤정단은 빈그릇에 다시 빈 것이 탔다. 
말은 많아도 쓸것이 없다. 집은 비록 부지이나 이사해야 하거나 나쁜 일이 생긴다.
`,`■ 피차 분수를 지켜야 하고 집에 귀인이 오거든 부탁하라. 낮정단은 여행이 안좋고, 밤정단은 억울한 누명을 쓰게 된다.
`,`■ 관재가 일어나도 일반인은 화를 면한다.
군자의점은 좋으나 가택을 점했을 때는 기운다고 본다.
`,`■ 위에서 모두 아래를 극하니 이것을 무록격이라 한다.
너와 내가 모두 어려우니 아랫사람의 행패를 조심하라.
`,`■ 나를 돕는다해도 말 뿐이다. 얻어보아야 별 것이 아니다. 열 개를 잃고 하나를 얻는다. 
귀인에게 부탁해도 허사다.
`,`■ 차 바퀴를 만들어 내지 못한다.
낮정단은 흉한 일이 생긴다. 내가 가서 사서 당하니 흉한 일 뿐 편하기 어렵다.
`,`■ 낮정단은 관살에 범이 탔으니 흉화가 반드시 있다. 녹이 비고 깨지니 집 지키기가 어렵겠다.
`,`■ 낮정단은 관살에 범이 타서 보이지 않는 재앙이 오리라.
아이나 불구자가 실종된다. 밤정단은 흉이 없다.
`,`■ 활을 쏘아 오히려 귀신을 도왔다. 그러나 구함을 믿을 수 있으니 방해꾼이 있긴하나 돈으로 막을 수 있다.
`,`■ 숙대로 만든 화살이나 맞은 화살에 마음을 다친다. 실재 놀라운 일은 없겠으니 잠자코 기다리면 풀릴 것이다.`]
const SIBO1 = [
  `■ 가내 우환이 있고 가장을
속인다. 가인이 원한을 
품고 加害
하려 한다.
凶을 凶으로 제어한다.
外 활동으로 財를 구한다. 
중간의 좌절을 견디라. 
정도로 나가면 된다. 
때를 기다렸다 행동하라. 
관송사가 있다.
관리는 탄핵ㆍ해임의 兆
형제의 재해가 생긴다.
`,`■ 관재가 발생한다.
폐지사를 부흥시키려 한다.
형제의 재해가 생긴다.
원행한다. 토지건에 의한 고민사가 있다.
寅ㆍ卯月 정단은 처ㆍ첩의 임신이 있다.
재산의 형성보다 복원의 이익이 좋다. 
후원사의 조건에 유의하라. 독이 있다.
四月에 임신사가 있다.
`,`■ 가내 財가 풍부하다.
음사사ㆍ주색사가 있다.
형제의 재해가 생긴다.
어떤 일이든 실익 없이 공론이다.
토지건으로 災가 근심된다. 內에서 갑자기 발생한다. 부인일이다.
사적으로 하라.
앞장서지 말아라.
四月에 임신사가 있다.
`,`■ 계획이 위험하다.
공격하지 마라.
我他 모두 이롭다.
人宅이 행복하다.
단 입조심을 해야 한다.
凶은 모두 제거된다.
특히 財利가 있게 된다.
春月 점은 부귀자이다.
형제의 재해가 생긴다.
`,`■ 자충수적 우환이 기한다.
폐지사ㆍ부흥심이 있다.
外는 活明하고 內는 어둡다. 허가 많고 실은 적다. 共同事가 있다.
一人이면 계속 발생하는 일이다.
`,`■ 자충수적 우환이 기한다.
婦女가 스스로와 피해를 끼친다.
가택을 손상한다.
여난이 있다.
一男二女가 삼각관계를 갖고 있다.
직위를 지킬 수 없게 된다. 下人을 압박하고 있어 충성받지 못한다.
양쪽으로 손해가 난다.
육친상배한다.
형제의 재해가 생긴다.
`,`■ 관송사가 발생한다.
중구경득 동요이다.
가택을 손상한다.
만사반복되어 갔다 다시 오고, 왔다 다시 가는 운세이다.
잃었다 얻고, 얻었다 잃는다. 일이 양단이다. 
이동개정사가 있고, 상인의 원조가 막혔다.
타인에 의해 고생한다.
`,`■ 고모신사 부정이 들었다.
男ㆍ夫가 헌신적이다.
四月에 임신사가 있다.
一女二男이 삼각관계에 있다. 일의 여건이 부족ㆍ불비하다.
파란 기복이 금전과 여자에 있다.
매사를 첫 번째에 결정하라. 재기회는 없다.
상대가 돕는다.
형제의 재해가 생긴다.
`,`■ 계획사가 위험하다.
타인에게 가택 권리가
넘어간다.
개혁하고자 한다.
그러나 財만 버린다.
희경사도 허사이다.
일도 한 가지가 아니고 위탁하려 하나 형제ㆍ친구가관련하여 파재 한다.
옛것을 지켜야 한다.
`,`■ 이사해야 한다.
형제의 재액이 생긴다.
문서사가 공망이다.
가택이 공망이다.
공론 공화사이다.
신규사나 개혁사가 있다.
이동 징조가 있어도 불가하다. 財는 후에 얻는다. 후원이 있더라도 후에 凶이 되므로 예방이 필요하다.
`,`■ 계획사가 위험하다.
일은 공적으로 해야 한다.
서로 의혹되어 불화하고 매사 실의가 없다.
上人의 의심을 사게 된다. 일이 측면에서 간헐적으로 발생한다.
광명정대한 처세가 필요하다.
`,`■ 가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
조용히 지키고 있어야 한다. 
부부반목하며 가정이 불화하고, 주객이 반복한다. 후퇴하라.
전진은 헛수고이다.
사물이 양갈래이다.
형제의 재해가 생긴다.`
]
const SIBO2_1 = [
  `■ 官･夫, 가택내사이다.
내방조짐 상의 :  
은혜를 원수로 갚는 송쟁사가 벌어졌다.
`,`■ 임신, 신변외사이다.
내방조짐 상의 :  
조용히 있는게 이롭다.
단, 모사․목적을 개변시켜라.
`,`■ 후원, 신변반목외사이다.
내방조짐 상의 :  
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 재ㆍ여, 가택대립사이다.
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.
신규사가 있다.
`,`■ 官･夫, 신변외사이다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다. 병재·관재 예방이 필요하다
`,`■ 후원, 신변대립사이다.
내방조짐 상의 :  
은근하고 伏하라.
권리를 상실한다.
`,`■ 官･夫, 가택내사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 임신, 신변외사이다.
내방조짐 상의 :  
조용히 있는게 이롭다.
신변․가족․골육이 반목한다.
`,`■ 직녹, 가택내사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
낭비많고 손재·녀 한다,재·녀 손실예방필요하다
`,`■ 형제․손모․가택내사
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
매사 혼미하다.
`,`■ 재ㆍ여, 가택송쟁사이다.
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.
만사 피면 여의롭다.
`,`■ 官･夫, 신변외사이다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
관사․관직사가길하다.`
]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/018/row-1-column-1.png`,
  `/static/images/sagwa/018/row-1-column-2.png`,
  `/static/images/sagwa/018/row-1-column-3.png`,
  `/static/images/sagwa/018/row-1-column-4.png`,
  `/static/images/sagwa/018/row-1-column-5.png`,
  `/static/images/sagwa/018/row-1-column-6.png`,
  `/static/images/sagwa/018/row-1-column-7.png`,
  `/static/images/sagwa/018/row-1-column-8.png`,
  `/static/images/sagwa/018/row-1-column-9.png`,
  `/static/images/sagwa/018/row-1-column-10.png`,
  `/static/images/sagwa/018/row-1-column-11.png`,
  `/static/images/sagwa/018/row-1-column-12.png`,
]

// 辛巳(신사)
const HEASYEOL = [
  // 1
  `  이 과는 천지신(天地神)이 본궁에 숨어서 동하지 않고 4과에 상하의 극이 없으며 음일(陰日)이므로 일지상신을 써서 초전으로 한다. 중․말전은 형충(刑冲)을 취하여 사맹신(四孟神)이며 원태격(元胎格)이 된다. 일간상신 술(戌)의 묘신(墓神)이 복(伏)하고 일지상신은 음양신(陰陽神)이 함께 관귀효(官鬼爻)로 되어 초전을 이룬다. 
  중전의 신(申)은 공망하며, 말전은 인(寅)의 재성(財星)으로서 밖에서 활동하여 재(財)를 구한다. 중전의 공망은 요절공망(腰折空亡)으로 되어, 사물은 중도에서 좌절되고, 여행 및 외출은 중도에서 멈춘다. 복음(伏吟)은 조용한 때를 기다리는 것이 좋다. 어떤 일도 정도(正道)로 전진하면 사람의 원조를 얻는다. 초전 관귀효에 덕록(德祿)이 승하고 말전에서도 생하므로 관공직․봉급 생활의 구직은 목적을 달성할 수 있다.
  백법에 말하기를 ｢말전은 초전을 돕는 격이다｣고 하였다. 비전에 말하기를 ｢신사일(申巳日)은 복음(伏吟)이 일지상신․일간을 극하여도 사(巳) 중에 병(丙)이 있어서 신(辛)과 간합(干合)하므로 화(火)의 극을 두려워하지 않고, 오히려 상회(相會)하는 것은 서로 돕는 뜻으로 길조로 변화한다. 
  일귀는 순행하여 삼전이 합(合)․공(空)․귀(貴)이고, 야귀는 역행하여 삼전이 사(蛇)․음(陰)․구(勾)이다.`
  ,
  // 2
  `  이 과는 제4과의 상에서 하를 극하여 이를 초전으로 하는 원수과(元首課)이다. 말전 축(丑)에 정신(丁神)이 승하여 동의(動意)를 생하므로 원행(遠行)의 요건(要件)을 일으킨다. 일간상신이 왕록(旺祿)으로서 길조이지만 공망하므로 길복을 지키기 어려우며, 오히려 초․중전의 재(財)에 붙는 것이 좋다.
  백법에 말하기를 ｢금일(金日)의 정신(丁神)은 흉화(凶禍)로 동하고 인묘월(寅卯月)은 태재생기(胎財生氣)로 되어 처첩은 회태(懷胎)한다｣고 하였다. 비전에 말하기를 ｢신일(辛日)에 유(酉)가 가해져 말전의 정축(丁丑)은 토지의 건으로 화(禍)를 초래한다｣고 하였다. 일귀는 순행하여 삼전이 사(蛇)․귀(貴)․후(后)이며 야귀는 역행하여 삼전이 합(合)․구(勾)․청(靑)이다.
  삼전에 귀신(貴神)․청룡이 승하면 기쁜 일이 있고, 구재(求財)에는 가장 길하다.


`
  ,
  // 3
  `  이 과는 제2과와 제4과에 극이 있고 하에서 상을 극하는 제4과를 취하여 축묘(丑卯)를 초전으로 하는 중심과(重審課)이다. 삼전이 역간전(逆間傳)이며 극음격(極陰格)이다. 축(丑)은 이양(二陽)으로 되어도 양이 미약하고, 음기가 찰 때는 해유(亥酉)는 음기가 점점 자라서 절양(絶陽)이 되므로 극음(極陰)이라고 부른다. 이는 음사(陰私)․주색(酒色)․사도(邪道)․부정(不正)의 상이다.
  초전에 정신(丁神)이 승하고 일간상신이 공망하면 천공(天空)이 승하는 것은 공중공허(空中空虛)로서 어떤 일도 공론공담(空論空談)으로 끝난다. 일지상신은 묘(卯)의 재신(財神)으로 되고 일지를 생하여 가정은 재(財)가 풍부하지만 정신(丁神)의 화환(禍患)이 초전을 이루기 때문에 만사는 예방을 먼저 해야 한다. 중․말전에 백호․현무가 승하므로 제4과를 초전으로 하고 정신(丁神)이 일간을 상하기 때문에 흉재는 홀연히 내부에서 발생하므로 주의하여 경계해야 한다. 축묘(丑卯)가 겹쳐서 신일(辛日)은 복태격(腹胎格)으로 되므로 집안에 임신부가 있다.
  백법에 말하기를 ｢금일정신(金日丁神)은 흉화를 동하고 교차지합(交車支合)하면 교섭사는 타협된다｣고 하였다. 재(財)의 건은 더욱 좋아져 공상(空上)에 천공을 만나면 사물을 추구하지 말아야 한다. 비전에 말하기를 ｢신일(辛日) 축(丑)의 초전이 정귀(丁鬼)로 되는 것은 묘(墓)의 일 또는 토지 사건이나 음식 및 부인의 일로 인해 재화(災和)를 초래하기 쉽다. 극음격(極陰格)은 사사(私事)를 도모하는 것은 길하며 공사(公事)는 불길하다. 다만 축(丑)은 순기(旬奇)이므로 조심하면 면할 수 있다.
  일귀는 순행하여 삼전이 후(后)․현(玄)․백(白)이며, 야귀는 역행하여 삼전이 청(靑)․백(白)․현(玄)이다. 정신(丁神)이 순기(旬奇)를 병(倂)하기 때문에 현임 관리는 부임이나 선임(選任)이 빠르다.`
  ,
  // 4
  `  이 과는 4과의에 상하여 극이 없고 제3과 상신과 일간이 요극(遙剋)하므로 인사(寅巳)를 초전으로 한다. 일간에서 극하는 것은 탄사격(彈射格)이라고 부른다.
  일강상신의 미(未)는 일간을 생하고 지상신의 인(寅)은 일간의 재(財)로 된다. 일간일지상신에서 생(生)을 받으므로 피아 모두에게 이롭고 또 인택이 함께 복을 향수한다. 신술(申戌)의 순(旬)은 술(戌)이 순수(旬首)이고 미(未)가 순미(旬尾)에 해당하며 일간기궁(日干寄宮)이 술(戌)의 상에 미(未)의 순미가 가해지면 폐구격(閉口格)으로 되므로 언사를 조심하여 구화(口禍)를 방지해야 한다. 요극과는 길흉이 경미하고 말전의 신금(申金)은 탄환(彈丸)이 있어도 공망하여 힘이 없으므로 오히려 흉을 형성하지 않는다.
  백법에 말하기를 ｢함께 생하고 서로 생하는 것은 만사에 유익하다｣고 하였다. 초․중전이 실(實)로 되고 말전이 공망하여 허(虛)로 되므로 처음이 있고 결과가 없다. 년명(年命)에 신(申)이 승할 때는 길조로 변화한다. 삼전이 체생(遞生)하므로 재리(財利)를 구하는 것은 길하다. 일귀는 역행하여 삼전이 귀(貴)․합(合)․공(空)이며, 춘월의 정단은 재왕(財旺)하여 부귀의 과로 된다. 야귀는 삼전이 구(勾)․백(白)․음(陰)이다.`
  ,
  // 5
  `  이 과는 4과 중에 제1과만 상에서 하를 극하므로 이를 초전으로 하는 원수과(元首課)이다. 삼전은 오인술(午寅戌)의 염상격(炎上格)이다.
  4과의 일간의 음양신은 삼합화국(三合火局)으로 되고 지(支)의 음양신은 사유축(巳酉丑)의 삼합금국(三合金局)이다. 일간상신이 관효(官爻)로 되어 삼전 인오술(寅午戌)이 삼합화국(三合火局)의 중적(衆敵)으로 되고 화귀(火鬼)는 일지상신의 인수(印綬)로 생화한다. 그러므로 모든 귀(鬼)는 두려워할 것이 못 된다.
  염상(炎上)의 화체(火體)는 외부는 밝고 내부는 어두우며, 밝은 것을 써서 이루어지기도 쉬운 만큼 멸(滅)하기도 쉽다. 화세(火勢)가 강성하여 위로 충(冲)하므로 허(虛)가 많고 실(實)이 적다. 성질이 조급하여 어떤 일도 빠르고, 문화(文化) 도야(陶冶)에는 가장 길하다. 문서(文書)․음신(音信)은 길하고, 질병은 음허(陰虛)로서 혈증(血症)이다. 소송사는 옥석(玉石)을 구분하기 어려우며 만사가 혹열(酷烈)을 만난다.
  삼합(三合)되는 것은 대중(大衆)을 의미하는 공동의 일로서 한 사람의 일이 아니다. 만약 개인의 일이면 계속적으로 발생하는 것으로 간점(看占)한다. 그 밖에 삼합격(三合格)은 모두 대동소이하다. 삼합화국의 관성(官星)은 공명․구직․현임 관리 등에게는 유익한 징조가 있으며, 상인(常人)도 흉을 해제(解除)한다. 백법에 말하기를 ｢중적(衆敵)이 있어도 존장의 인(印)으로 생화(生化)하므로 두려울 것이 없다｣고 하였다. 삼전 중에 삼기(三奇)ㆍ순기(旬奇)를 만나면 흉을 구(救)하는 것이므로 길로 전변한다. 일귀는 삼전이 상(常)이다. 천장은 어느 것이나 토신(土神)이고 화(火)를 설(泄)하여 천장은 교신(敎神)의 작용을 이룬다.`
  ,
  // 6
  `  이 과는 4상(四上)에서 4하를 극하는 섭해과(涉害課)이다. 사맹상신(四孟上神)의 자사(子巳)를 초전으로 하여 불비무록격(不備無祿格)을 구성한다. 일지의 사(巳)가 일간상에 와서 일간을 극하지만 자수(子水)가 이것을 제어하므로 구신(救神)이다. 무록(無祿)은 복수(福壽)를 지키지 못한다. 아랫사람, 또는 사용인을 상하기 때문에 충성할 수 없으며, 자연히 사용인이나 아랫 사람은 이산(離散)한다.
  백법에 말하기를 ｢간지(干支)는 어느 것이나 상극(傷戟)으로 되어 양손해(兩損害)는 면할 수 없다｣고 하였다. 생기태재(生氣胎財)는 처첩이 임신하고 만약 사기(死氣)가 승하면 태아를 손상한다. 일귀는 역행하여 삼전이 백(白)․귀(貴)․청(靑)이고, 야귀는 사(蛇)․상(常)․합(合)이다. 불비격은 정확한 것이 결여되어 어떤 일도 불비하고 결함이 있다. 이음일양(二陰一陽)으로 되면 2녀(二女)가 1남(一男)을 다투거나 삼각 관계의 징조가 있다. 4상은 하를 극하므로 무록격이며, 극이 많아서 전진할 길이 없고 재록(財祿)은 바랄 수 없기 때문에 무록이라고 한다. 굴(屈)하여 신(伸)하기 어렵고, 소송은 원고가 승리하며 만사는 삼전에서 구신(救神)을 의뢰할 뿐이다. 구신이 있으면 비로소 흉이 화하여 길조로 된다. 하급자에게 이롭지 않고, 사용인은 도망한다. 임신은 태아를 상하게 하며, 어떤 일도 남자에 의해 발생하는 극동(極動)에서 정(靜)으로 될 징조이다. 이와 같은 흉과(凶課)는 백법의 결절격(結節格)에도 해당한다. 정문(正文)에 말하기를 ｢정단 이전은 재화로 고로(苦勞)가 있어 흉격(凶格)을 보아도 이미 재액(災厄) 후가 되면 금후는 또 화해(禍害)가 있다고 정단하지 않고 오히려 우려에도 미치지 않는다｣고 하였다.`
  ,
  // 7
  `  이 과는 12신이 각각 충위(冲位)에 있고 제3과․제4과에 극이 있어 하에서 상을 극하는 제4과를 써서 초전으로 하고 삼전이 사해사(巳亥巳)로 된다. 이 과의 정단 사항은 만사가 반복하여 갔다가 다시 돌아온다. 즉 얻을 때는 다시 잃고, 잃을 때는 다시 얻는다. 이기려고 바라면 지게 된다. 요컨대 천장의 길흉에 의해 정해진다.
  초전과 말전은 일간을 극하여도 해수(亥水)는 이것을 구(救)하므로 재해를 보아도 당하지 않는다. 충신(冲神)만은 어떤 일도 양기(兩岐)를 걸쳐 이동․개정(改訂) 등의 일이 많다. 백법에 말하기를 ｢양귀(兩貴)가 극을 받으면 귀인의 원조는 얻기 어렵다｣고 하였다. 
  초전은 협극(夾剋)으로 되고 만사는 자기에 의한 것이 아니다. 비전에 말하기를 ｢천(天)은 서북에 기울어져 일월(日月)에 따르며, 지(地)는 동남에 함몰되어 강해(江海)는 사해(巳亥)에 돌아간다. 그러므로 어떤 일도 결함이 있고, 많은 것을 구하여도 얻은 것은 경하다｣고 하였다. 일귀(日貴)는 역행하여 삼전이 현(玄)․합(合)․현(玄)이고, 야귀는 순행하여 삼전이 후(后)․청(靑)․후(后)이며 길장이다.

`
  ,
  // 8
  `  이 과는 4과 중에 3과의 상하(上下)에 상극이 있고 제1과의 하에서 상을 극하는 묘술(卯戌)을 채용하여 초전으로 하는 중심과(重審課)이다. 묘술(卯戌)은 불비격으로 된다.
  음일(陰日)이면 음신(陰神)을 남기고 양신을 버려 2양1음으로 된다. 2녀가 1남을 다투게 되며, 남녀 관계는 삼각관계로 된다. 초전 묘(卯)는 일간의 재성(財星)으로 되고 현무가 승하여 재(財)의 실탈(失脫)을 초래한다. 일간․일지는 목생화(木生火)와 토생금(土生金)으로 되어 교차상생(交車相生)한다. 중․말전은 공망하고 말전 축(丑)은 정신(丁神)으로 되어 상생하여 극하므로 백호․사신(蛇神)의 흉장이 승한다.
  일간상신이 재동(財動)으로 되어 초전으로 되면 구재는 빨리 얻는다. 늦을 때는 중․말전이 공망하여 목적이 좌절된다. 일간기궁(日干寄宮)의 술(戌)은 일지상신으로 되고 하에서 생(生)을 받아 내몸은 상대방으로부터 이익을 받는다. 내전․외전의 대부분은 초전을 주로 한다. 이 과격과 같이 극이 많은 사건은 파란 기복이 있으며 또 하나의 사건이 아니라는 것을 알 수 있다.
  백법에 말하기를 ｢금일(金日) 정신(丁神)은 화(禍)에 의해 동(動)하며, 중․말전이 공망한 것은 삼전에 순환하지 않는다. 초전의 1신에 의존하여 길흉을 보아야 한다｣고 하였다. 비전에 말하기를 ｢묘(卯)에서 말전의 축(丑)을 보면 재(財)가 재(財)를 본다｣고 하였다. 그러나 신금(辛金)에 의해 축(丑)은 묘신(墓神)으로 되고 정신(丁神)이 승하여 일간을 극하므로 처와 사별(死別)하고 재혼한다. 교차상생하면 간합(干合)․육합(六合)이 있어 피아가 서로 화합하고 돕는 기쁨이 있다. 일귀는 역행하여 삼전이 후(后)․공(空)․사(蛇)이고, 야귀는 순행하여 삼전이 현(玄)․주(朱)․백(白)이다.`
  ,
  // 9
  `  이 과는 4과 중에 제1과․제3과에 극이 있고 금일이 음일이므로 음비(陰比)의 극(剋)인 제3과 유사(酉巳)를 취하여 초전으로 하고, 지일비용과(知一比用課)이다. 삼전 유축사(酉丑巳)가 삼합금국(三合金局)으로 되면 종혁격(從革格)이다.
  초․중전은 공망하고 말전만이 실(實)이며, 중전 축(丑)은 정신(丁神)으로 되고 백(白)․사(蛇)의 흉장이 승하여 모든 일에 흉화를 만난다. 일지상신의 택상(宅上)에는 청(靑)․합(合)이 녹상(祿上)에 승하여 가택(家宅)은 길조이나, 공망 파쇄(破碎)가 승하여 희경사는 허성(虛聲)뿐이며 가옥의 파손이 있다. 삼전이 종혁격(從革格)이면 사물을 개혁하는 일이다. 건록은 공망하여 복록은 허(虛)하다. 즉 처음에는 허하고 나중에는 실하다.
  백법에 말하기를 ｢건록이 지상(支上)에 임하면 권섭(權攝)이 바르지 않고 금일정신(金日丁神)은 동하여 재(災)를 초래한다｣고 하였다. 일간상신 인(寅)은 재성이지만 금국(金局)으로 되어 비견효(比肩爻)이고 타인(他人)이 와서 재(財)를 빼앗는 상이다. 일귀는 역행하여 삼전이 청(靑)․사(蛇)․현(玄)이고, 야귀는 순행하여 삼전이 합(合)․백(白)․후(后)로 된다. 교동격(狡童格)으로 되면 남녀 색정의 사건이 생긴다. 결혼은 자유연애의 혼약이다.`
  ,
  // 10
  `  이 과는 제3과만 하에서 상을 적하여 초전으로 되는 중심과이다. 삼전 신해인(申亥寅)은 원태격(元胎格)이다. 초․중전이 공망하고 말전의 재성(財星)만이 실(實)하다. 일간상신 정신(丁神)에 백호가 승하므로 암암리에 재액(災厄)을 예방해야 한다.
  일지상신의 택(宅)이 공망으로 되어 초전을 구성하는 것은 가택(家宅)이 공허하다. 상대방을 볼 때는 천공(天空)이 승하여 공상공(空上空)이 되므로 공론공화(空論空話))의 사건이라고 본다. 말전에 인(寅)의 재신(財神)에 천을귀신(天乙貴神)과 태상이 승한 것은 윗사람의 재(財), 또는 의식주의 재(財)를 얻는다. 중전에 역마가 승하여도 공망하므로 이동(移動)의 징조가 있어도 동하지 못하고, 후에 말전의 지반으로 되어 재(財)에 의해 동하여 재(財)를 얻는다.
  백법에 말하기를 ｢일간상신이 묘(墓)에 복(伏)하여 백호가 승하면 병의 정단은 두렵다｣고 하였다. 백호가 정신(丁神)에 승하여 일간을 극하는 것은 재화(災禍)가 얕지 않다. 비전에 말하기를 ｢월염(月壓)․대살(大殺)․천목(天目)․묘신(墓神)․정신(丁神)이 6소(六所)에 임하면 괴상한 사건을 만난다｣고 하였다. 일귀는 순행하여 삼전이 공(空)․현(玄)․귀(貴)이고, 야귀는 삼전이 주(朱)․청(靑)․상(常)이다. 백호가 정신(丁神) 축(丑)의 묘(墓)에 승하면 점병(占病)하면 생명상의 위험이 있고 소송사건은 극히 불리하다. 원태(元胎)가 모두 장생의 지(地)에 앉아도 초․중의 공망은 임신하지 못한다. 수태하여도 생육하기 어렵다.`
  ,
  // 11
  `  이 과는 4과 중 어디에도 상하에 극이 없다. 일간과 제2과 상신의 인(寅)을 요극(遙剋)하므로 요극탄사과(遙剋彈射課)이고, 삼전 인진오(寅辰午)는 1신을 사이 두고 중․말전을 구성한다. 이와 같은 것을 순간전격(順間傳格)이라고 부른다. 그 밖에 11국도 간전격으로 된다.
  인진오(寅辰午)는 출삼양(出三陽)이라고 한다. 인(寅)과 진(辰)은 양(陽)으로서 양(陽)이 극(極)하면 1음을 생하기 때문에 양을 나오게 하는 상으로 되면 출삼양이라고 부른다. 물(物)을 지나지 않고 점차 음에 들어가면 길하다. 지나칠 때는 다시 길로 된다. 병은 치료할 수 있고 소쟁(訴爭)은 화해하는 것이 좋다. 초전의 재(財)는 말전의 화귀(火鬼)를 돕는다. 그러나 일간상신은 귀살(鬼殺)을 제어하므로 화(禍)로 되지 않는다. 간전탄사격(間傳彈射格)은 사물의 옆에서 발생하고 간격(間隔)으로 발생한다.
  백법에 말하기를 ｢간(干)과 지상신은 자미(子未)의 육해로 되어 서로가 의혹을 일으켜서 불화(不和)를 암시한다｣고 하였다. 비전에 말하기를 ｢일간에서 상신을 생하여 탈기(脫氣)되고, 천공이 승하면 탈공격(脫空格)으로 되어 어떤 일도 중심이 없고 실의(實意)를 결한다｣고 하였다. 간상신은 자(子)이고 지상신은 미(未)로서 육해이므로 중간의 제2과에 태상(太常)을 밴다. 이와 같은 것은 윗사람 또는 존친으로부터 의심을 사게 된다. 과전은 광명정대하게 되면 공사(公事)는 길하고 음사(陰私)는 불리하게 된다.
  일귀는 순행하여 삼전이 귀(貴)․주(朱)․구(勾)이고, 야귀는 삼전이 상(常)․음(陰)․귀(貴)이다. 귀신(貴神)이 삼전에 들어가면 공명․구직 등은 윗사람의 원조를 얻는 일은 어느 것이나 길하다.`
  ,
  // 12
  `  이 과는 4과 중에 상하의 극이 없기 때문에 일지상신 오(午)가 일간을 극하여 요극과(遙剋課)로 된다. 일간에서 극하는 것은 탄사격(彈射格)이고, 이 과와 같이 천신(天神)에서 일간을 극하여 초전을 구성하는 것은 호시격(蒿矢格)이다. 그러나 12국의 대부분은 삼전이 진여(進茹)로 되고 호시(蒿矢)로 사람을 쏘아도 해를 이루지 않는다. 다만 요극과로서 삼전 중에 금(金)이 있으면 철시(鐵矢)로 되기 때문에 극을 받는 것이 중하다. 다행히 신금(申金)이 공망하므로 흉을 면할 수 있다.
  지반(地盤) 지지(地支)의 전(前)이 천반으로 되는 것은 나망(羅網)이라고 한다. 조용히 지키는 것이 좋고 함부로 모사(謀事)를 하면 목적을 달성하지 못한다. 요극과는 처음에는 뇌전(雷電)과 같이 격렬하여도 점차 소산하기 때문에 길흉화복은 어느 것이나 경미하다. 천장이 길흉과 상극에 의해 정단해야 한다. 또 4과 중에 칠충(七冲)․육해(六害)․지합(支合) 등이 교차(交叉)하고 가정 및 부부는 서로 반목(反目)하며, 상대방과의 담합(談合)은 주객(主客)이 서로 뜻을 다르게 한다.
  백법에 말하기를 ｢나망을 만나는 것은 모사(謀事)가 졸책으로 된다｣고 하였다. 비전에 말하기를 ｢일지 사(巳)의 역마는 간상에 승하기 때문에, 대인(待人)은 올 수 있다. 먼 곳의 사람이면 정해일(丁亥日)에 온다. 허실이 착종하므로 그 다음날에 온다｣고 하였다. 일귀는 순행하여 삼전이 구(勾)․청(靑)․공(空)이고, 야귀는 역행하여 삼전이 귀(貴)․후(后)․음(陰)이다. 이 과는 진여격(進茹格)이고 체생격(遞生格)으로 되어도 말전이 공망이므로 공곡전성(空谷傳聲)이라고 부르며, 사물은 후퇴(後退)하는 것이 좋다. 전진할 때는 결국 결과가 없게 된다.`
  ,
]


  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
