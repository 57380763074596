const columnA = [
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`01`,`좌`],
  [`23:32`,`04 `],
  [`01:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`02`,`좌`],
  [`01:32`,`09`],
  [`03:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`03`,`좌`],
  [`03:32`,`06`],
  [`05:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`04`,`좌`],
  [`05:32`,`11`],
  [`07:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`05`,`좌`],
  [`07:32`,`08`],
  [`09:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`06`,`좌`],
  [`09:32`,`01`],
  [`11:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`07`,`좌`],
  [`11:32`,`10`],
  [`13:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`08`,`좌`],
  [`13:32`,`03`],
  [`15:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`09`,`좌`],
  [`15:32`,`12`],
  [`17:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`10`,`좌`],
  [`17:32`,`05`],
  [`19:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`11`,`좌`],
  [`19:32`,`02`],
  [`21:31`,`내용 해당`],
  [``,``],
  [``,``],
  [`12`,`좌`],
  [`21:32`,`07`],
  [`23:31`,`내용 해당`],
  [``,``]
]
const columnD = [
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
  [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`고집`,7,10,`없음`,`01.21\n~
02.19`,`보통크기 \n고환큼

음모
적음 
분위기파`,`조용\n담담

성교후곧잠`],
  [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
  [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
  [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,8,9,`없음`,`02.20\n~
03.20`,`굵음\n적극적 매너

강력한 성교 함`,`문을 열면 적극적이 됨\n
감격을 숨기지않음`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`위선`,9,8,`없음`,`03.21\n~
04.20`,`단순 서툼\n시간 짧음

전,후 없이 본격적 임`,`주도적\n적극적

완벽
주의자 임`],
  [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
  [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
  [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
  [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,10,7,`없음`,`04.21\n~
05.20`,`때와 장소 안 가림\n
발정
타입
 곧 
시듬`,`정숙\n하고 
억제심 강함

분비물 많음`],
  [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
  [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
  [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,6,`없음`,`05.21\n~
06.20`,`기교파 반응 감상 타입\n
크고, 즐김`,`위쪽\n 절정감에 집요함

주도권을 빼앗기도`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`위선`,12,5,`없음`,`06.21\n~
07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
질이 큼

소극적 못견딤
절정 빠름`],
  [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
  [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
  [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
  [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,4,`없음`,`07.21\n~
08.20`,`정력파 女만족시키려 함\n
쉽게 지침`,`적극적 타입 수축 형\n
중년 쇠퇴 후 회복함`],
  [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
  [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
  [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,2,3,`없음`,`08.21\n~
09.20`,`헌신 리드 파 \n더러 포경

성지식 풍부함`,`부끄러움 많이 탐\n
성기는 명물임`],
  [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`위선`,3,2,`있음`,`09.21\n~
10.20`,`조루증 많음\n
음경은 작은 편`,`음모 많음\n
수축 형이고 능란함`],
  [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
  [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
  [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
  [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,4,1,`있음`,`10.21\n~
11.20`,`보통이상 크기\n
극적. 본능에 그침`,`은밀함\n절정을 끝없이 함

전신
경련. 수축 형 강력
 명물`],
  [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
  [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
  [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
  [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,12,`없음`,`11.21\n~
12.20`,`통크기. 강직도 있음\n
오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
  [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
  [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
  [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
  [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,11,`없음`,`12.21\n~
01.20`,`보통 크기. 강직도 있음\n
여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
  [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
  [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
  [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
  [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
]
const columnS = [
  [``,``,``,``,``,``],
  [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
  [``,``,``,``,``,``],
  [``,``,``,``,``,``],
  [``,``,``,``,``,``],
  [`박관`,`직업직위 불안사`,`小`,``,``,``],
  [`극부`,`내녀 색정사 有`,`小`,`4`,`손해도망`,`색정사有`],
  [`자녀`,`자녀 문재사`,`大`,`개시기`,`고위연회`,`손해가출`],
  [`불변근신(不辨謹愼)`,`장차를 대비하기 위하여 말을 삼가고 근신함이 좋은 상`,`大`,``,`초대열석`,`발탁영전`],
  [`문서필송(文書必送)`,`반드시 문서가 오가는 상`,`小`,``,`희사`,`희사`],
  [`후원`,`후원조력사-발탁승진희경사有`,`小`,``,`내외급속`,`내외송쟁`],
  [`문서`,`문서관련사`,`大`,`5`,`송쟁상해`,`동요불안`],
  [`모친`,`모친관련사`,`小`,`배양기`,`실종우려`,``],
  [`발탁영전(拔擢榮轉)`,`추천 발탁되어 영전하는 상`,`小`,``,`집정起居`,`不辨근신`],
  [`반목연루(反目連累)`,`타인에 의해 반목 대립사에 연루(連累)된 상`,`大`,``,`적의適宜`,``],
  [`금전`,`금전 관련사`,`大`,``,``,``],
  [`사업`,`사업 관련사`,`小`,`6`,`송구경황`,``],
  [`부인`,`처 관련 가정불화사`,`小`,`존재기`,`사통관계`,`가정침체`],
  [`소식대기(消息待期)`,`소식을 기다리는 상-와도 나쁨`,`大`,``,`畏人知`,`문서유실`],
  [`도모미수(圖謀未遂)`,`계획진행사가 완결되지 못하고 미수로 된 상`,`小`,``,``,``],
  [`결속`,`결속하여 음10월 大財를 획득하나 부모근심사 발생함`,`大`,``,``,``],
  [`사업`,`사업 관련사`,`小`,`7`,`改拜之封`,``],
  [`여인`,`내부가정女 간음무도사 有`,`小`,`단절기`,`군자大慶`,`복잡단합`],
  [`희중허사(喜中虛事)`,`기쁨이 허사가 되는 상`,`大`,``,`외사화합`,`간음무도`],
  [`간음무도(姦淫無道)`,`간음사가 무례하고 지난친 상`,`小`,``,``,``],
  [`후원`,`후원조력사`,`小`,``,``,``],
  [`문서`,`문서관련사`,`大`,`8`,``,``],
  [`부친`,`부친관련사`,`小`,`침체기`,`외사동요`,`고위사적`],
  [`불의진취(不宜進取)`,`앞으로 나아가서 취하게 되는 것은 안 좋운 상`,`小`,``,`도둑놀램`,`방문부탁`],
  [`제여불리(除餘不利0`,`다 빼고라도 불리함이 남는 상-특히 관직자는 두려운 상`,`大`,``,``,`이좋음`],
  [`관사`,`관사 사`,`大`,``,``,``],
  [`직업`,`직업직위사`,`大`,`9`,``,`출행이동`],
  [`남편`,`남편 관련 출행이동사`,`小`,`소외기`,`수혜사有`,`수혜사有`],
  [`노양고위(怒兩高位)`,`도와 줄 두 귀인 모두 화를 내는 상`,`小`,``,`총애다툼`,`禍消복지`],
  [`허획경구(虛獲驚懼)`,`도적은 못잡고 놀래기만 하는 상`,`小`,``,``,``],
  [`관사`,`관사 사`,`大`,``,``,``],
  [`직업`,`직업 지위사 `,`大`,`10`,``,``],
  [`남편`,`남편 관련 간음무도사`,`小`,`침해기`,`외사화합`,`상하화합`],
  [`관기상반(官忌常半)`,`주무사에 관직자는 피해야 하고 일반인은 반길한 상`,`小`,``,`간음무도`,`화소복지`],
  [`허획경구(虛獲驚懼)`,`도적은 못잡고 놀래기만 하는 상`,`小`,``,``,``],
  [`후원`,`후원조력사`,`小`,``,``,``],
  [`결속`,`결속하여 음10월 大財를 획득하나 부모근심사 발생함`,`大`,`11`,`반목송쟁`,`가정막힘`],
  [`송쟁`,`外반목대립급속송쟁사-파재실종상해(傷害) 우려`,`小`,`쇠퇴기`,`고위사적`,`음10월`],
  [`필유성취(必有成就)`,`外반목대립급속송쟁사-상신(傷身) 우려`,`小`,``,`방문청탁`,`대재득함`],
  [`양편상조(兩便相調)`,`주석(酒席) 양편조율의 상`,`大`,``,`좋음`,``],
  [`형제`,`형제관련사`,`小`,``,``,``],
  [`친구`,`친구관련사`,`小`,`12`,`출행이동`,``],
  [`동료`,`동료관련사`,`大`,`과시기`,`급속사有`,`시혜사有`],
  [`도난포획(盜難捕獲)`,`도적을 추격했으나 못잡은 상`,`小`,``,`송구경황`,`尊卑불화`],
  [`득룡대전(得龍大展)`,`뜻을 이루어 여러인에게 면모를 과시할 수 있게 된 상`,`大`,``,`연정사由`,``],
  [`직녹`,`직위승급사`,`小`,``,``,``],
  [`도망`,`외 손해도망사`,`小`,`1`,`참소이간`,`간음이익`],
  [`수난`,`재해감수사`,`大`,`절정기`,`손해도망`,`정대적은`],
  [`음신조격(音信阻隔)`,`소식을 기다리는 상-와도 나쁜 상`,`小`,``,`백사근심`,`되려재앙`],
  [`참망이간(讒忙離)`,`없는 사실을 꾸며 참소한 일로 고위인이 화를 내는 상`,`大`,``,``,``],
  [`후원`,`후원조력문서부친사`,`小`,``,``,``],
  [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`2`,``,`기거적의`],
  [`매사 영속성없음`,`후원문서부모사는 선천적 숙명재해를 겪음-독신고독`,`小`,`면모기`,`외교막힘`,`반목대립`],
  [`처유혈광(妻有血光)`,`처 관련 피를 보이고 사망하거나 病 중 임신을 한 상`,`小`,``,`소식대기`,`송사有`],
  [`집정적의(執政適宜)`,`부임한 곳의 업무 보는 장소와 침실이 만족한 상`,`大`,``,``,``],
  [`박관`,`직업직위불안남편갈등자녀문제사`,`小`,``,``,``],
  [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`3`,``,`송구경황`],
  [`매사 영속성없음`,`직업직위남편남자속음자녀사는 선천적 기복재해를 겪음`,`大`,`학습기`,`침체可畏`,`私情상대`],
  [`전도삼형(前途三刑)`,`앞으로 형사적 송사가 기다리는 상`,`大`,``,`존비불화`,`관련`],
  [`송구경황(悚懼驚惶)`,`사통한 자가 나타나서 두려워  하는 상`,`小`,``,``,``]
]
const columnZ = [
  [``,``,0,``,0],
  [`단계`,`단계`,0,`가능`,"불가"],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明시작`,``,0,``,"직업직위불안부부갈등색정자녀문제로불가"],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明종료`,``,0,`모친조력문서사로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明진행`,``,0,`금전사업처관련사로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗진행`,0,`금전사업여인색정관련사로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明하향`,``,0,`형제동료친구출비로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗절정`,0,``,`관사 직업 남편 사로 불가`],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗시작`,0,``,`관사 직업 남편 사로 불가`],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗종료`,0,`자매친구동료조력으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗진행`,0,`형제동료친구출행출비로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明진행`,``,0,`직위자산으로가능`,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,`暗진행`,0,``,`세속허사자로불가`],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [`明절정`,``,0,``,`세속허사자로불가`],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0],
  [``,``,0,``,0]
]
const columnAF =[
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [2,3,4,5,6,7,8,9,10,11,12,1],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [3,4,5,6,7,8,9,10,11,12,1,2],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [4,5,6,7,8,9,10,11,12,1,2,3],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [5,6,7,8,9,10,11,12,1,2,3,4],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [6,7,8,9,10,11,12,1,2,3,4,5],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [7,8,9,10,11,12,1,2,3,4,5,6],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [8,9,10,11,12,1,2,3,4,5,6,7],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [9,10,11,12,1,2,3,4,5,6,7,8],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [10,11,12,1,2,3,4,5,6,7,8,9],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [11,12,1,2,3,4,5,6,7,8,9,10],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [12,1,2,3,4,5,6,7,8,9,10,11],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [1,2,3,4,5,6,7,8,9,10,11,12],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0],
  [0,0,0,0,0,0,0,0,0,0,0,0]
]
const columnAS =[
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [2],
  [0],
  [0],
  [0],
  [0],
  [3],
  [0],
  [0],
  [0],
  [0],
  [4],
  [0],
  [0],
  [0],
  [0],
  [5],
  [0],
  [0],
  [0],
  [0],
  [6],
  [0],
  [0],
  [0],
  [0],
  [7],
  [0],
  [0],
  [0],
  [0],
  [8],
  [0],
  [0],
  [0],
  [0],
  [9],
  [0],
  [0],
  [0],
  [0],
  [10],
  [0],
  [0],
  [0],
  [0],
  [11],
  [0],
  [0],
  [0],
  [0],
  [12],
  [0],
  [0],
  [0],
  [0],
  [1]
]
const columnAU = [
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`호가호위로 놀램**만약 타인과 다툼이 있다면 피아간 전의가 별로 없고 손상도 없으며 있다고 하더라도 가벼움`,0,0,`상대의 공격으로 심중 두려움이 자연 해소될 人`,0,0,`현재의 사안에서 물러남이 좋음**호의적 금전을 받으면 재해를 겪음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`강건 정당**만약 타인과 다툰다면 먼저 행동하면 이기고 나중으로 미루면 패함`,0,0,`정정당당하게 급속 선수으로 종결함이 유리한 人`,0,0,`형통함**용이 그름을 얻은 격**사망사 또는 기괴사가 있음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`혐의 연루**만약 타인과 다툰다면 상대는 최초 계획을 변경하여 공격하므로 반드시 별도대책을 세워야 함`,0,0,`한 그믈에 걸린 물고기 격으로 견련 위배 연기 지체 변경사가 있는 人`,0,0,`1남2녀삼각관계有**여건불비부족함**독신 고독 각방 주말부부 별리 사별 처지임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`제일 친한 자나 제일 가까히 거주하는 자와 의논하면 중상모략을 해결할 수 있는 人`,0,0,`복잡한 사안을 하나로 묶음**여자가 남자를 유혹하여 도망할 속마음**재앙이 업드려 있음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`간난험악**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`타인의 호의를 쉽게 받아들이지 말아야 함**이전 일이 모두 단절됨**타인의 조력이 전혀 없음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`분산동요불안**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상으로 재난이 밖에서 오니 신중해야 함`,0,0,`동요불안이 반복적으로 발생하여 신음하는 人 `,0,0,`의지할 데가 없음**부부이심색정사**독신 고독 각방 주말부부 별리 사별 처지임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`간난험악**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`권리행사결여됨**일이 결말없이 반복됨**어른(부모)이 아래자녀)로부터 박해받음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주 소송의 조짐이 있는 人`,0,0,`복잡한 사안을 하나로 묶음**독신 고독 각방 주말부부 별리 사별 처지임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`혐의 연루**만약 타인과 다툰다면 상대는 최초 계획을 변경하여 공격하므로 반드시 별도대책을 세워야 함`,0,0,`한 그믈에 걸린 물고기 격으로 견련 위배 연기 지체 변경사가 있는 人`,0,0,`1녀2남삼각관계有**조건이 결여됨**독신 고독 각방 주말부부 별리 사별의 처지임`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`호가호위적 공격**만약 타인과 다툼이 있다면 피아간 전의가 별로 없고 손상도 없으며 있다고 하더라도 가벼움`,0,0,`상대를 공격해도 적중되지 않아 허사 무용이 될 人`,0,0,`태양이 떠오르는 격임**기쁨은 동남쪽에서 오고 고민은 북서에 있음`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`진퇴양난**만약 타인과 다툰다면 화해해야 좋고 계속 끌면 본색이 드러나게 됨`,0,0,`본인이 범인이거나 또는 범법자를 숨기고 있는 人`,0,0,`눈속임이 있음**굴을 못찾은 겨울뱀 처지임**그물에 걸림`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [`신음고로**만약 타인과 다툰다면 상대는 기회를 노리고 행동하지 않음`,0,0,`스스로 유약하게 여기고 움직이려 하지 않으며 또는 신체가 타의에 의해 자유롭지 못한 人`,0,0,`자신만 믿음**토지송쟁사有`,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0],
  [``,0,0,``,0,0,``,0,0]
]
const columnBE = [
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`피해인`,`타발동사`,`기사 `,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`피해인`,`아발동후회사`,`기사 `,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`이외사`,`미사`,`신사`,`돌발사`,`이면사`,`배신`,`보성`,`허위`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`타발동사`,`기사 `,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위빈빈`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`이외사`,`기사 `,`신사`,`돌발사`,`표리모호사`,`배신`,`보성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`손해인`,`타발동급속사`,`기사 `,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`무력인`,`이외사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`허위`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`무력인`,`이외사`,`미사`,`신사`,`돌발사`,`표면사`,`배신`,`보성`,`허위`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`아권리가져갈 인`,`아발동후회사`,`기사 `,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`손모인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [`이득인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`배신`,`보성`,`허위`],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``]
]
const columnBO = [
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`흥-이사함`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠`,`쇠`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-동요`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥-이전의사`,`쇠-가택고민`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-공허`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`흥`,`쇠-공허`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠`,`쇠`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠-공허`,`쇠`],
  [``,``],
  [``,``],
  [``,``],
  [``,``],
  [`쇠-공허`,`흥`],
  [``,``],
  [``,``],
  [``,``],
  [``,``]
]
const columnBS = [
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 비김`,`비김`,` 비김`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 무력`,`무력`,` 무력`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [` 화해`,`화해`,` 화해`],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 무력`,`무력`,` 무력`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [` 무력`,`무력`,` 무력`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 무력`,`무력`,` 무력`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [`  승`,`승`,`  승`],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [``,``,``],
  [` 비김`,`비김`,` 비김`],
  [``,``,``],
  [``,``,``]
]
const columnBV =[
  // [0,0,0,``,0,0,0,``],
  // [용모,가문,合6,``,용모,가문,合6,``],
  // [0,0,0,``,0,0,0,``],
  [2,3,5,`음5월`,3,2,5,`음11월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,3,3,`음4월`,2,3,5,`음12월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,6,`음3월-연기우려-음11월`,2,2,5,`음11월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,6,`음7월`,3,1,4,`음4월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [1,1,2,`음9월-연기 또는 파혼우려`,3,3,6,`음3월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,1,3,`음8월`,2,2,4,`음2월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,6,`음7월`,2,2,4,`음1월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,5,`음6월`,1,1,2,`음12월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [3,3,6,`음3월-연기우려`,1,1,2,`음8월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,3,5,`음6월`,3,1,4,`음12월-연기 또는 파혼우려`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [1,1,2,`음7월`,2,3,5,`음1월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [2,1,3,`음6월`,3,3,6,`음12월`],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``],
  [0,0,0,``,0,0,0,``]
]
const columnCE = [
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`성질이 폭력적이고 조금하며  편을 가름`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높고 무난함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`고집이 세고 초반부터 종반까지 무실적으로 일관함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`유순하고 준수 미모이나 일처리가 강하지 못하며 이전 이사를 진행하여 재정을 비게 함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`이격이 높고무난하나 재정이 침체함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인정미는 있으나 사심이 많아 초반과 종반이 허무함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인정미는 있어도 사심이 많아 권리를 결여시켜 행사하지 못함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`사교성이 좋아 동료의 협력을 받으나 초중반에 무력하고 종반에 재정을 침체시킴`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인정미는 인어도 사심이 많아 업무를 공허하게 함`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`인격이 높으나 권리를 양도하게 됨`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`사정을 갖고 상대와 같은 소리를 냄`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [`유순하고 준수 미모이나 일처리가 강하지 못해 송쟁사를 일으킴`,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0],
  [``,0,0,0,0,0]
]
const columnCL = [
  [``,``,``,``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``,``,``,``],
  [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
  [`1.2월`,`집集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
  [`4.5월`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
  [`7.8월`,``,`반대없음`,`이행`,`선난후난`,`退    背`,`  원`,`留`,`주인후원`,`남동`,`정남`],
  [`10.11`,``,``,``,`포기요망`,``,``,``,`주인세입`,`세밀심사`,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,`화합`,`함`,``,``],
  [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`다집多集`,`반대2`,`이행`,`선난후합`,`退    背`,`  원`,`去`,`주인후원`,`동남`,``,`동남`],
  [`7.8월`,``,`반대1불발`,``,`애증관계`,``,``,``,``,`세밀심사`,``,``],
  [`10.11`,``,`반대1`,``,`됨`,``,``,``,``,`함`,``,``],
  [`3.6\n9.12`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대없음`,`이행`,`선이후費`,`退    背`,`  근`,`去`,`무난`,`정북`,``,`남동`,],
  [`7.8월`,``,``,``,`빠르게`,``,``,`세밀심사`,``,``,``,``],
  [`10.11`,`음10월集`,``,``,``,``,``,`함`,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대`,`이행`,`선押후이`,`退    背`,`  근`,`去`,`주인이사`,`북북동`,`북북동`,`동남`],
  [`7.8월`,``,`발현안됨`,``,`천천히`,``,``,`관공직자`,`결정상태`,``,``,``],
  [`10.11`,`집集`,``,``,``,``,``,`留`,`참고`,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대`,`이행`,`선압후의`,`退    背`,`  원`,`去`,`주인압박`,`남남서`,`남남동`],
  [`7.8월`,`다집多集`,`발현안됨`,``,`반목대립`,``,``,``,``,``,``,``],
  [`10.11`,``,``,``,`함`,``,``,``,``,``,``,``],
  [`3.6\n9.12`,`집集`,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`음4월集`,`반대`,`이행`,`선이후파`,`進    向`,``,`留`,`무난`,`정동`,`정남`,``],
  [`7.8월`,``,`기력없음`,``,`빠르게`,``,`  원`,`이전이사`,`주인세입`,``,``,``],
  [`10.11`,``,``,``,``,``,``,`질병우환`,`동요`,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`다집多集`,`반대`,`이행`,`선損후이`,`退    背`,`  원`,`留`,`주인피해`,`동남`,`남남서`,``],
  [`7.8월`,``,`본인제압`,``,`천천히`,``,``,``,`세입가해`,``,``,``],
  [`10.11`,``,`함`,``,``,``,``,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대1`,`이행`,`상대사안`,`退    背`,`  근`,`留`,`주인무력`,`남남동`,`서남`,``],
  [`7.8월`,``,`발현안됨`,``,`공허무력`,``,``,`관공직자`,``,``,``,``],
  [`10.11`,`음10월集`,``,``,``,``,``,`절대`,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대없음`,`이행`,`상대사안`,`退    背`,`원근왕래`,`留`,`주인무력`,`정남`,`정남`,``],
  [`7.8월`,``,``,``,`공허무력`,``,``,``,``,``,``,``],
  [`10.11`,`음10월集`,``,``,``,``,``,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
  [`1.2월`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,``,`반대`,`이행`,`선厚후이`,`退    背`,`  근`,`留`,`세입피로`,`남남서`,``,`정북`],
  [`7.8월`,``,`본인제압`,``,`권리양도`,``,``,``,`설기쇠약`,``,``,``],
  [`10.11`,`집集`,``,``,`됨`,``,``,``,``,``,``,``],
  [`3.6\n9.12`,``,``,``,`포기요망`,``,``,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,`  근`,``,``,``,``,``],
  [`4.5월`,``,`반대없음`,`이행`,`본인무력`,`退    背`,`  근`,``,``,``,``,``],
  [`7.8월`,`다집多集`,``,``,`비용과출`,``,`음7,8월 遠`,`去`,`세입피로`,`서남`,``,`북북동`],
  [`10.11`,``,``,``,`포기요망`,``,`  근`,``,`설기쇠약`,``,``,``],
  [`3.6\n9.12`,`집集`,``,``,``,``,`  원`,``,``,``,``,``],
  [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
  [`4.5월`,`집集`,`반대없음`,`이행`,`선이후난`,`退    背`,`  근`,`去`,`무난`,`정서`,``,`동북`],
  [`7.8월`,``,``,``,`빠르게`,``,``,``,``,``,``,``],
  [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
  [`3.6\n9.12`,`다집多集`,``,``,``,``,``,``,``,``,``,``]
]



const columnCY = [
  [``,``,``,0,0,``,0,``],
  [``,``,``,0,0,``,0,``],
  [``,``,``,0,0,``,0,``],
  [`질병`,`도로귀`,`교통사고`,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,0,0,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,`신성귀`,`성소변경`,0,0,`육친`,0,``],
  [`질병`,`신성귀`,`성소변경`,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,0,0,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,`도로귀`,`교통사고`,0,0,`육친`,0,``],
  [`질병`,``,``,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,0,0,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,"저주원심","비방저주",`육친`,0,``],
  [`질병`,``,``,0,0,`묘좌`,"서남",`없음`],
  [``,``,``,0,0,`묘향`,"북서",``],
  [`생업`,`제례귀`,`제례위배`,0,0,`전경`,"큰강도로",``],
  [``,``,``,0,0,`묘속`,"水",``],
  [`가정`,``,``,0,0,`육친`,0,``],
  [`질병`,``,``,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,`수귀`,`익시사망`,0,0,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,0,0,`육친`,0,``],
  [`질병`,` 수귀`,`익사사망`,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,"近死侵靈","친척사망",`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
  [`질병`,` 수귀`,`익사사망`,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,0,0,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,0,0,`육친`,0,``],
  [`질병`,``,``,0,0,`묘좌`,0,` 특별있음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,0,0,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,0,0,`육친`,0,``],
  [`질병`,``,``,0,0,`묘좌`,0,`있음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,0,0,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,"제례부정","제례잘못",`육친`,0,``],
  [`질병`,`신성귀`,`성소변경`,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,0,0,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,0,0,`육친`,0,``],
  [`질병`,``,``,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,`상가부정`,`상가왕래`,`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,0,0,`육친`,0,``],
  [`질병`,``,``,0,0,`묘좌`,0,`없음`],
  [``,``,``,0,0,`묘향`,0,``],
  [`생업`,``,``,"제례부정","제례잘못",`전경`,0,``],
  [``,``,``,0,0,`묘속`,0,``],
  [`가정`,``,``,0,0,`육친`,0,``]
]
const columnDH = [
  [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
  [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
  [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
  [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
  [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
  [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
  [2,`소식기다림-와도 나쁨`,`병재**매사불성**활인진출하면 자립함`,``,`빈궁**가정 여자 색정사有**손해가출사`,``,`모사미수**외사반목대립쟁송**상신우려`,`투서고발상서헌책이 좋음`,`빈궁`,`사사성취`,`내 파재극처실종사우려**외 남자 색정사有**이동사`,``,`빈궁**외동요분산사**事事위역`,`말 말고 근신함이 좋음`,`빈궁**관재`,``,`추천발탁영전사`,``,`외사반목대립송쟁**상신우려**탄식신음`,`득지행권집정**외사 출행이동원행사`,`빈궁**병재**전도`,``,`녹위**자산**권리**부족**전도**외사손해**도망사우려`,`선천 숙명적 독신고독자`,`매사불성**활인진출해당자로내방 월이`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`외 급속사`,``,``,`전화위복**재액해소`,``,`가정동요분산**전화위복`,``,``,``,``,``,``,`내사 원행이동사`,`음4,5월화재조심`,``,`음4,5월화재조심`,``,`가정막힘`,`내 급속사`,``,``,``,``,`3,4,5,6,9,12월은 성공함`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [3,`선천숙명적 독신고독자`,`매사불성**종교진출하면 자립함`,`소식기다림-와도 나쁨`,`빈궁**매사불성**활인진출하면자립함`,``,`빈궁**내 가정 색정사有**손해가출사`,``,`모사미수**외사반목대립송쟁**상신우려`,`투서고발상서헌책이 좋음`,`빈궁`,`사사성취**이동사`,`내파재극처실종사우려**외 남자 색정사有`,``,`빈궁**외동요분산사**사사위역`,`말 말고 근신하이 좋음`,`빈궁**관재`,``,`추천발탁영전사`,``,`외사반목대립송쟁**상신우려**탄식신음`,`득지행권집정**외사 출행이동원행사`,`빈궁**병재**전도`,`전도`,`녹위**자산**권리**부족**유실사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,`외 급속사`,`빈궁**병재`,``,`전화위복**재액해소`,``,`가정동요분산**전화위복`,``,``,``,``,``,``,`내사 출행이동사`,`음4,5월화재조심`,``,`음4,5월화재조심`,``,`가정막힘`,`내 급속사`,``,``,`외사손해도망사우려`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [4,``,`녹위**자산**권리**유실사`,`선천 숙명적 독신고독자`,`매사불성**종교자립하면자립함`,`소식기다림-와도 나쁨`,`매사불성**활인진출하면자립함`,``,`빈궁**내 여자 색정사有**전화위복`,``,`모사미수**외사반목대립송쟁**상신우려`,`투사고발상서헌책이 좋음`,`빈궁**출행사`,`사사성취**이동사`,`내파재극처실종사우려**외 남자 색정사有`,``,`빈궁**외동요분산사**사사위역`,`말 말고 근신함이 좋음`,`빈궁**관재**전도`,``,`추천발탁영전사`,``,`외사반목대립송쟁**상신우려**탄식신음`,`득지행권집정**외사 출행이동원행사`,`빈궁**병재`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,`외사손해도망사우려`,``,``,`외 급속사`,`빈궁**병재`,``,`손해가출사`,``,`가정동요분산**전화위복`,``,``,``,``,``,``,`내사 출행이동사`,`음4,5월화재조심`,``,`음4,5월화재조심`,``,`가정막힘`,`내 급속사`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [5,`빈궁**병재**급속강제손실사`,`외사 출행이동원행사`,`불의진취**부자**외사손해도망사우려`,``,`빈궁**전도**매사불성**종교진출하면 자립함`,`투서고발상서헌책이 좋음`,`매사불성**활인진출하면 자립함`,``,`빈궁**내 여자 색정사有**전화위복`,``,`빈궁*전도**외사반목대립송쟁**상신우려`,`화소복지사`,`고위**부자**출행 영전사`,``,`내파재극처실종사우려**외 남자 색정사有`,`이동사`,`빈궁**외동요분산사**사사위역`,`총애다툼사`,`빈궁**관재`,`도둑은 못잡고 놀램`,`무관종사**부족**내외상하단합화합사`,`절을 고쳐해야하는 대경사-단 부족`,`빈궁**병재**전도**외사반목대립송쟁`,`재물손괴사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`재액해소`,`내 급속사`,`재액해소`,``,`선천 숙명적 독신고독자`,``,`외 급속사`,``,`손해가출사`,``,`가정동요분산`,``,`사적방문부탁이 좋음`,``,`간음무도사`,``,``,``,`음4,5월화재조심`,`내사 출행이동사`,`음4,5월화재조심`,``,`상신우려**가장막힘`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [6,`빈궁**병재**외사반목대립송쟁**상신우려`,`재물손괴사`,`빈궁**병재**강제급속손실사`,`외사 출행이동원행사`,`불의진취**부자**외사손해도망사우려`,``,`빈궁**매사불성**종교진출하면 자립함`,`투서고발상서헌책이 좋음`,`매사불성**활인진출하면 자립함`,``,`빈궁**내 여자 색정사有**전화위복`,``,`빈궁**외사반목대립송쟁**상신우려`,`화소복지사`,`고위**부자**출행영전사`,``,`내파재극처실종사우려**외 남자 색정사有`,`이동사`,`빈궁**외동요분산사**사사위역`,`총애다툼사`,`빈궁**관재**음4,5월화재조심`,`도둑은 못잡고 놀램`,`무관종사*부족**내외상하단합화합사`,`절을 고쳐해야 하는 대 경사-단, 부족`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`가정막힘`,``,``,`내 급속사`,``,``,`선천 숙명적 독신고독자`,``,`외 급속사`,``,`손해가출사`,``,`가정동요분산`,``,`사적방문부탁이 좋음`,``,`간음무도사`,``,``,``,`재액해소`,`내사 출행이동사`,`음4,5월화재조심**재액해소`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [7,`무관출세**내외상하단합화합사`,`절을 고쳐해야하는 대경사`,`빈궁**병재**외사반목대립송쟁**상신우려`,`가정막힘`,`빈궁**병재**전도**급속강제손실사`,`외사 출행이동원행사`,`불의진취**부자**외사손실도망사우려`,`전도`,`빈궁**매사불성**종교진출하면자립함`,`투서고발상서헌책이 좋음`,`매사불성**활인진출하면 자립함`,``,`빈궁**내 여자 색정사有**손해가출사**전화위복`,``,`빈궁**외사반목대립송쟁**상신우려`,`가정동요분산사**화소복지사`,`고위**부자**출행영전사`,`사작방문부탁이 좋음`,`이동사`,`내파재극처실종사우려**외 남자 색정사有`,`총애다툼사`,`빈궁**외사동요분산사**사사위역`,`도둑은 못잡고 놀램`,`빈궁**관재`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`동요불안분산사`,``,`동요불안분산사`,`재물손괴사`,`동요불안분산사`,`내 급속사`,`동요불안분산사`,``,`동요불안분산사`,`선천 숙명적 독신고독자`,`동요불안분산사`,`외 급속사`,`동요불안분산사`,`재액해소`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`간음무도사`,`동요불안분산사`,``,`동요불안분산사`,`내사 출행이동사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [8,`빈궁**관재`,`도둑은 못잡고 놀램`,`무관출세**내외상하단합화합사`,`절을 고쳐해야 하는 대경사`,`빈궁**병재**외사반목대립송쟁**상신우려`,`재물손괴사`,`빈궁**병재**전도**강제급속손실사`,`외사 출행이동원행사`,`불의진취**부자**외사손실도망사우려`,``,`빈궁**매사불성**종교진출하면 자립함`,`투서고발상서헌책이 좋음`,`매사불성**활인진출하면 자립함`,``,`빈궁**내 여자 색정사有**재액해소**전화위복`,``,`빈궁**외사반목대립송쟁**상신우려`,`화소복지사`,`고위**부자**출행영전사`,``,`내파재극처실종사우려**외 남자 색정사有`,`이동사`,`빈궁**외동요분산사**사사위역`,`총애다툼사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`음4,5월화재조심`,`내사 출행이동사`,``,``,`가정막힘`,``,``,`내 급속사`,`전도`,``,``,`선천 숙명적 독신고독자`,``,`외 급속사`,`손해가출사`,``,`가정동요분산`,``,`사적방문부탁이 좋음`,``,`간음무도사`,``,`음4,5월화재조심**재액해소`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [9,`빈궁*외동요분산사**사사위역`,`총애다툼사`,`빈궁**관재**전도`,`도둑은 못잡고 놀램`,`무관출세**내외상하단합화합**전도`,`절을 고쳐해야하는 대경사`,`빈궁**병재**외사반목대립송쟁**상신우려`,`재물손괴사`,`빈궁**병재**강제급속손실사`,`외사 출행이동원행사`,`불의진취**부자**외사손실도망사우려`,``,`매사불성**종교진출하면 자립함`,`투서고발상서헌책이 좋음`,`빈궁**매사불성**활인진출하면 자립함`,``,`빈궁**내 여자 색정사有**전화위복`,``,`빈궁**외사반목대립송쟁**상신우려`,`화소북지사`,`고위**부족**출행영전사`,``,`내파재극처실종사우려**외 남자 색정사有`,`이동사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,`음4,5월화재조심`,`내사 출행이동사`,`음4,5월화재조심`,``,`가정막힘`,``,``,`내 급속사`,`전도`,``,`선천 숙명적 독신고독자`,``,``,`외 급속사`,`손해가출사`,``,`가정동요분산`,``,`사적방문부탁이 좋음`,``,`간음무도사`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [10,`내파재극처실종사우려**외 남자 색정사有`,`빈궁`,`빈궁**외사동요분산**사사위역됨`,``,`화합사**관재**전도`,`내사 출행이동사`,`빈궁**전도**내외단합화합사`,``,`모망난수**음4,5,3,6,9,12월 부자`,`가정막힘`,`빈궁**전도**강제급속손실사`,`외사 출행이동원행사`,`빈궁**병재`,`가정불녕-가족절상사`,`매사불성**종교진출하면 자립함`,`투서고발상서헌책이 좋음`,`빈궁**매사불성**활인진출하면 자립함`,`도적 못잡음`,`빈궁**내 여자 색정사有**전화위복`,`부인-첩 서로 업신여김`,`빈궁**외사반목대립송쟁**상신사우려`,`송구경황사`,`고위**부족**출행영전사`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,`재난불측사`,`이동사`,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,`외사반목대립송쟁**상신우려`,` `,``,`내 급속사`,``,``,`선천숙명적 독신고독자`,``,``,`외 급속사`,`손해가출사`,``,`가정동요분산사`,``,`사적방문부탁이 좋음`,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [11,``,`무고이간사**음,10,11,1,2월 부자`,`외 남자 색정사有`,`빈궁**내파재극처실종사우려`,`총애다툼사`,`빈궁**전도**외동요분산사**사사위역`,`내사 출행이동사`,`비곡치욕**관재`,``,`추천발탁영전사`,`숲에서 뱀을 만난 격`,`빈궁**전도**외사반목대립송쟁**상신우려`,`외사 출행이동원행사`,`빈궁**병재**강제급속손실사`,`간음음사이익-정대적재앙`,`녹위**자산**권리**화합사`,`투서고발상서헌책이 좋음`,`매사불성**종교진출하면자립함`,``,`매사불성**활인진출하면 자립함`,`재물손괴`,`빈궁**내 여자 색정사有**전화위복`,``,`빈궁**병재**전도**외사반목대립송쟁`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,`출행영전사`,``,`이동사`,``,``,``,`음4,5월화재조심`,``,`음4,5월화재조심`,``,`가정막힘`,`내 급속사`,`재액해소`,`재액해소`,`외사손해도망사우려`,``,`선천 숙명적 독신고독자`,``,`위 급속사`,``,`손해가출사`,``,`가정동요분산**상신우려`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [12,``,`모사미수**외사반목대립송쟁**가정동요분산`,``,`빈궁**출행사`,`사사성취*외 남자 색정사有`,`전도**내파재극처실종사우려`,``,`빈궁**외동요분산사**사사위역`,`숲에서 뱀을 마난 격`,`빈궁**관재**재액해소`,``,`추천발탁영전사`,`송구경황`,`외사대립반목송쟁**상신우려**가정막힘`,`득지행권집정**출행이동원행사`,`빈궁**병재**강제급속손실사`,``,`녹위**자산**권리**유실사`,`투서고발상서헌책이 좋음`,`매사불성**종교진출하면 자립함`,`빈궁**병재**외 급속사`,`매사불성**활인진출해당자로 내방월이`,``,`빈궁**내 여자 색정사有**전화위복`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,`전화위복`,``,``,``,``,``,``,`내사 출행이동사`,`음4,5월화재조심`,``,`음4,5월화재조심**재액해소`,``,``,`외사 출행이동원행사`,`내 급속사`,``,`외사손해도망사우려`,``,`선천 숙명적 독신고독자`,`소식기다림-와도 나쁨`,`음3,7,8,10,12월은 성공함`,``,`손해가출사`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [1,``,`빈궁**전도**내 여자 색정사有**전화위복`,``,`모사미수**외사반목대립송쟁**가정동요분산`,``,`빈궁**출행사**재액해소`,`사사성취`,`내파재극처실종사우려**외 남자 색정사有`,``,`빈궁**외동요분산사**사사위역`,`숲에서 뱀을 만난 격`,`빈궁**관재`,``,`추천발탁영전사`,`송구경황사`,`외사반목대립송쟁**상신우려**가정막힘`,`득지행권집정**외사 출행이동원행사`,`빈궁**병재**강제급속손실사`,``,`녹위**자산**권리**유실사`,`투서고발상서헌책이 좋음`,`매사불성**종교진출해당자로 내방 월이`,`외 급속사`,`매사불성**활인진출해당자로내방 월이`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
  [0,``,`손해가출사`,``,`전화위복`,``,``,`이동사`,`재액해소`,``,``,`내사 출행이동사`,`음4,5월화재조심*`,``,`음4,5월화재조심`,``,``,`내 급속사`,``,``,`외사손해도망사우려`,`선천 숙면적 독신고독자`,`음3,4,5,6,9,12월은 성공함`,`소식기다림-와도 나쁨`,`음7,8,3,6,9,12월은 성공함`],
  [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
]

const CHOSA1 = [
  `■ 왜 자신감이 없을꼬? 
하극상 흉사가 땅으로 벌어졌네? 혼자 지킬 뿐 누구도 상대하지 말아야 해! 
그래야 빛을 보게 돼!

⊕⊖신변신화선영부정이         들었네 ?
`,`■ 마음이 천둥치겠어! 
 놀래겠어! 
입다물고 물러나서
⊕ 전사귀, 
⊖신부정귀를해소시키면      전화위복이 될거야!
⊕신변상가부정이들었어 !
`,`■ 돈ㆍ여자가 시비송사를 일으킬 조짐인데, 
이것은 다 
⊕전사귀ㆍ불에 타죽은 자살귀, ⊖신부정귀ㆍ도로귀침범 때문이야! 해소시키고 문서만 잘 관리하면 좋아져!
`,`■ 남자 하나를 놓고 두 여자가 다투는데, 여자들도 다른만자와 바람이 났네 !그러니 일도 불완전할수밖에 없지! 
집안에 
⊕ 가택여고음강부정, ⊖저주원심 부정이 들어서 그래!
⊕신변상가부정이 들었네 ? 
`,`■ 둘 중 하나를 놓고 갈등하고 있겠는데, 불만이 커! 

남:⊖타인의부인과색정이네! 
여:⊕남의 남자를 유혹했으니      따라 나서겠네? 
  색정이야!
`,`■ 물구덩이에 빠졌고, 
옛인연이 모두 거덜났고, 
上은 下를 업신여기고, 
기도하면서 기다리는 수밖에 없겠어!
자손사가 凶하겠어 !
`,`■ 上下불화 하네? 
옛일도 재발되네? 
움직일래도 장애가 많아!
무슨일이든 오래가지않고 !
⊕ 수귀, 
⊖ 여귀를 달래고 남을 앞세워 재기해야 일이 되겠어!
⊕⊖신변여고음강부정침범
 했어!
`,`■ 아랫 사람이 떠밀어 물구덩이에 빠진격일세? 
찍을 도장이 썩었으니 일이 반복되기만 하고 결과가 없지! 

⊕ 수귀, 
⊖ 여귀가 문제야! 
9월에나 완성되겠네 !
`,`■ 질투 의심으로 결정을 못하고 있겠어! 
돈․여자로 기로에 섰어! 일은 번잡하기만 하겠고! 이사를 잘하면 돈이 많이 보이기는 한데 자칫 관사구설이될수있겠고...
`,`■부부 똑같이 바람이 났네! 1녀 2남 삼각관계가있네 ? 일도 반복되기만 하고 결말이 없겠어 ! 
⊕가택신화선영, 
⊖가택고모신사 부정이 탔어! 이사해! 부모자손이 다 凶하겠어!
⊖신변 상가부정 침범이네!
`,`■ 총이 아무리 좋아도 총알이 없는데 어디에 쓰나? 지금 집이 넘어가게 생겼는데 

⊕ 전사귀, 
⊖ 신부정귀를 잡아야 되는게 있겠어!
`,`■ 앞 뒤가 캄캄하네! 도대체 진퇴기회가 없어! 선악이 분명하고! 하늘에서 투망을 던진 꼴이야! 
신불기도ㆍ근신이 필요해!
속고, 자손재해도 생기네 !
⊖신변 상가부정 침범이네!`
];
const YOGYEOL1 = [
  `■ 나를 찾아온 손님과 같이 할 수 없다. 사람이 와서 구하거든 조용히 지키고 응하지 말라. 병점은 가히 두렵다.
`,`■ 상대는 입을 다물고 말하기도 어렵다. 이를 버리고 간다해도 다시 변해 관살이 되니 나쁜 일로 바뀐다.
`,`■ 불이 많아 관살을 도우니 금전ㆍ여자는 근심이 된다. 분수를 넘는 여자나 재물을 취하면 그 재앙을 피할 수 없다.
`,`■ 상대가 와서 돕고자하면 받아들이되 맹동치 말라. 그래봤자 헛수고이니 지키고 있어야 즐거우리라.
`,`■ 丁火가 관귀를 도우니 먼저는 나빠도 나중은 좋다. 돈을 지니고 귀인에게 빌면 재앙은 면하게 된다.
`,`■ 비어있는 재산을 지키자니 그에 대해 할 말이 없다. 군자는 아주 나쁘고 피차 허물뿐이다.
`,`■ 상대가 의욕적으로 와서 구하나 어찌할 도리가 없다. 달콤한 말에 속으나 두귀인은 나서지 않고 성만 낸다.
`,`■ 위아래가 모두 형벌이니 낮정단은 여자 재물의 손실이다. 본래 좋은 것은 조금도 없어 바빠도 기대할 것이 없다.
`,`■ 낮정단은 재물손실이 있고 움직이면 재난이 많게 된다. 밤정단은 재물이 있는듯하나 겉으로만 그럴뿐 실속이 없다.
`,`■ 낮정단은 묘신에 백호가 타있고 피하다가 도리어 도적을 맞는다. 고통을 당하다가 아랫사람을 찾아야 이를 면한다.
`,`■ 밤정단은 내 재산이 범에게 물려갔으나 입다물고 불평하지 말라. 궁색해도 편하게 여기면 일이 풀려 재앙은 없다.
`,`■ 낮정단은 범이 다섯이니 재앙으로 놀람이 많다. 모두 미움으로 가득찼으나 밤정단은 흉이 차츰 사라진다.`
]
const SIBO1 = [
  `■ 토지ㆍ가옥관련사이다.
소송관재가 있다. 
부모에게 凶害가 기한다.
자손의 재해가 기한다.
心身의 자유가 없다.
안정ㆍ근신하며 자세를 굽혀야만 성과가 있다. 動해도 성과는 없다. 당면한 송쟁사는 좋게 해결된다.
寅ㆍ卯띠는 大財를 得  한다.
`,`■ 家內우환이 있다. 가족 中
가장을 속인다. 원한을
품고 加害하려 한다.
守舊하면 災가 해소됨.
관직자는 지위가 높고 재복이 크고, 일반인은 재해가 깊다. 처나 돈으로 송사가 발생한다. 직장인은 실직한다. 고민사로 원행한다.
반항을 받고 있다.
⊕일이 밝아진다
四月에 임신사가 있다.
`,`■ 家內우환 있고 가장을 속임.
가인이 원한을 품고 
加害 하려 한다.
과전에 태세지나 월장이 보이면 광명통달하고, 亥띠는 시험ㆍ선거가 적중한다. 女는 시가를 나와 재액을 만난다. 처나 財로 凶이 발생한다. 크기는 왕상으로 가늠하라.
四月에 임신사가 있다.
`,`■ 他人事를 묻는다.
⊕여고음강⊖저주원심 부정
女ㆍ婦가 헌신함. 
자손사에 속임을 당한다.
매사가 연속ㆍ연결되어 오히려 전진이 안된다. 
모든 일이 他人에게 의논되고 있다.  一男二女의 색정사를 벌이고 있다. 일도 여건이 불비되어 있다. 부정해소 치성하라.
자손사에 凶害가 있다.
`,`■ 자충수적 우환이 있다.
친구ㆍ자손ㆍ동업ㆍ장사임
자손사가 凶하다.
夫婦가 共히 음탕하다.
금전ㆍ여자로 인한 재난이 있다. 돈은 十月에 크게 얻는다. 女가 男을 유혹하여 음분한다. 어른의 일이 고심사가 된다. 결국 효과가 없다.
시험ㆍ선거사는 좋다.
男 : 처가 임신했다.
`,`■ 자충수적 우환이 있다.
옛일은 끊어지고, 직위도
해제된다.
고독하다. 원조가 없다.
일을 되돌릴수가 없다.
회한심을 갖고 있다. 단, 반성하며 자신을 낮추면 나중에 吉하게 된다.
골육간에 반목한다. 上人이 下人을 기만한다. 진행사는 손해일 뿐이다. 피아가 재앙이다.
`,`■ ⊕음사, ⊖상가부정이 듬
배반人은 세력이 없다.
上人의 지원을 믿지 말라. 옛일이 재발되었다.
적극적으로 전진해야 하고 직접나서지 말고 他人을 앞세워 측면으로 나가야 효과가 있다. 上人의 화냄이 있다. 일반인은 호조를 맡게 된다. 그러나 오래가지 못하므로 대책을 세우라.
`,`■ 夫婦가 반목한다.
下剋上을 받는다. 
四月에 임신사가 있다.
부부 사이가 이간된다. 
근신해야 한다. 매사 좋은듯 싶으나 완전하지 못하다. 
특히 남의 말을 좋게 해야 한다. 下剋上이 있다.
부모가 凶하다.
`,`■ 財ㆍ妻에 의해 소송사가
기한다. 財ㆍ여자의
취득을 세심히 살피라.
자손사로 속는다.
선택의 기로에 섰다.
근ㆍ친자로 결정하라.
금전문제는 성사된다.
완전하지 못하다.
女子끼리 다툰다. 일이 두 개이고 하나로 정리해야 吉하다. 분수를 지키면 凶은 경미하다.
四月에 임신사가 있다.
`,`■ ⊕신화선영 ⊖고모신사
자손사로 속는 일이 있다.
부모․자손이 凶하다.
男ㆍ夫는 헌신적이나 무력하다. 上人은 승진하고 下人은 직위해제가 된다.  一女二男 관계이다. 슬피 울 일이 발생한다.
상대는 財가 없다.
財를 구한다.
`,`■ 가택의 권리와 명의가
넘겨진다.
奸私事의 이익건이 있다.
당장은 효과가 없으나 후에 반드시 광명이 있게 된다. 財의 뿌리가 든든하다. 시ㆍ종이 분명하게 된다. 단, 서남쪽을 경계하라. 상대를 공격해도 성과가 없다.
여유심을 가지라.

男 : 처가 임신했다.
`,`■ 女는 기박한 팔자이다.
사건은 內에서 기한다.
진ㆍ퇴가 궁색하다.
가정에서 화한이 발생한다. 모망사는 졸책에 불과하다. 감추는 일이 있고 어둡다.
흉절시기를 보고 재기의 시기를 말하라.`
]
const SIBO2_1 = [
  `■ 토지송쟁, 가택내사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■官･夫, 가정손모가 있다.
내방조짐 상의 :  
원행·이동사가 있다.
조용히 있는게 이롭다.
가정우환이 있다.
`,`■官･夫, 신변ㆍ가택내외사
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■자손사속고,일이안된다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
속는일이 있고, 자손재액이
발생한다
`,`■ 금전ㆍ여, 신변외사이다.
내방조짐 상의 :  
이사·이전사가 있다.
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재 예방요함
`,`■ 직녹, 신변손해사이다.
내방조짐 상의 :  
은근하고 伏하라.
권리상실사가 있다.
`,`■官･夫,가정상하불화한다.
내방조짐 상의 :  
조용히 있는게 이롭다.
목적불성,반복왕래의 상이다.
`,`■財ㆍ妻로 인해 소송사 기함
내방조짐 상의 :  
원행·이동사가 있다.
조용히 있는게 이롭다.
처․재로 재난이 발생한다 
`,`■ 자손, 속고 신변이 막혔다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
속는 일이 있고 자손재액 발생한다.부모흉재예방요함
`,`■ 자손, 속고 부정이 들었다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
속는 일이 있고 자손재액 발생한다.
`,`■ 금전ㆍ여인사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 가정손모가 있다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
포악 불화의 상이다.`
]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [

`/static/images/sagwa/008/008_001.png`,
`/static/images/sagwa/008/008_002.png`,
`/static/images/sagwa/008/008_003.png`,
`/static/images/sagwa/008/008_004.png`,
`/static/images/sagwa/008/008_005.png`,
`/static/images/sagwa/008/008_006.png`,
`/static/images/sagwa/008/008_007.png`,
`/static/images/sagwa/008/008_008.png`,
`/static/images/sagwa/008/008_009.png`,
`/static/images/sagwa/008/008_010.png`,
`/static/images/sagwa/008/008_011.png`,
`/static/images/sagwa/008/008_012.png`,

];

const HEASYEOL = [
// 1
`  이 과는 십이신(十二神)이 본궁(本宮)에 복(覆)하여 복음과(伏吟課)이다. 사과에 극이 없을 때는 음일은 지상신(日支神)의 미(未)를 초전으로 하고 중․말전은 극전(剋戰) 대신에 형(刑)을 취하여 스스로 상극적(相剋賊)의 상(象)이다.
일지상신의 미(未)는 초전이 되고 말전의 술(戌)은 간상(干上)에 돌아온다. 이와 같은 것은 사람이 와서 나에게 일을 구(求)할 징조이다. 삼전의 사계신(四季神)은 가색격(稼穡格)이다. 천지 귀신(鬼神)이 동(動)하지 않고 극(剋)하지 않으며 스스로 그 유순함을 믿으므로 음일의 복음과(伏吟課)는 자신(自信)이라고 이름을 지었다.
삼전에 정마(丁馬)가 없을 때는 어떤 일도 자유를 얻지 못하여 가택은 불안하다. 근신하여 안전을 얻어야 한다. 삼형(三刑)이 완전하여도 기세가 강하고 신장(神將)이 길하면 모든 일은 성과가 있다. 복음(伏吟)은 천지일여(天地一如)하고 은복(隱伏)하여 아직 발현하지 않은 형상으로 안정하는 것이 좋다. 동할 때는 정체된다. 또 내몸은 굴(屈)하여 신(伸)하지 못하는 과격(課格)이다.
실물(失物)은 집안에서 찾아야 한다. 사람을 방문하여도 상대방은 나오지 않는다. 대인(待人)은 즉시 온다. 그러나 먼 곳의 사람은 오지 않는다. 삼전사과가 전부 토(土)이며 춘월(春月)․하월(夏月)은 약론(弱論)을 이루지 못하며, 인성(印星)이 태왕(太旺)하면 자손효(子孫爻)를 극한다. 이와 같은 이치에 따라 집안의 자녀(子女)에게는 이롭지 못하다. 어버이가 자식의 병을 정단할 때는 반드시 사(死)의 액(厄)이 있다.
일귀는 역행하고 삼전은 청(靑)․후(后)․상(常)이며, 야귀는 역행하여 삼전은 후(后)․청(靑)․상(常)으로 된다. 천장(天將)이 어느 것이나 길장으로 되어 년명상신의 재성(財星)이 승할 때는 계획하는 일이나 희망하는 일은 어느 것이나 발전의 기쁨이 있다. 가색(稼穡)의 류신은 농작(農作)․토공(土工)․건축 또는 토지․실내․가택 등이며 래정(來情)도 역시 이 류신에 따라 관점한다.
삼전이 전부 토신(土神)이면 유자격(遊子格)으로 병환은 치유되기 어렵고 질병은 끊임없이 발병한다. 3년 내에 파산(破産)을 만나고 관재(官災)는 그 해 안에 발생한다. 삼전 중에 기의(奇儀) 또는 길신(吉神)․길장(吉將)이 있으면 구신(救神)의 작용을 하므로 재액(災厄)을 해제할 수 있다.`
,
// 2
`  이 과는 상하의 극이 없고 제3과․제4과에 요극(遙剋)이 있다. 금일(今日)은 음일(陰日)로서 음비(陰比)의 극을 쓴다. 제4과를 써서 초전으로 하여 호시격(蒿矢格)이 되고 삼전 사진묘(巳辰卯)는 퇴여격(退茹格)으로 된다. 말전의 묘(卯)는 정신(丁神)이 승하고 제1과는 유술(酉戌)로서 육해(六害)이며, 제3과는 오미(午未)의 지합(支合)이다.
호시격으로서 퇴여격은 군자(君子)가 녹(祿)이 후하고 지위가 높으며 재부(財富)를 얻는다. 중전 일야(日夜)의 천장(天將)은 주작이 병임하여 학력(學力)이 깊고 재명(才名)이 특히 나타난다. 상인(常人)은 사오(巳午)의 관귀(官鬼)가 왕성하므로 병이나 재해가 발생하여 어떤 일도 길흉 화복을 측정하기 어렵다. 걱정은 동남에 있고 기쁨은 서북에 있다.
백법에 말하기를 ｢귀신이 오(午)의 관성(官星)에 승하는 것은 천을신기｣(天乙神祇)의 격이다. 제2과와 제3과의 두 곳에서 일간을 극하는 것은 귀(鬼)는 3, 4에 임한다고 하며 재해와 분쟁이 따라서 발생한다｣고 하였다. 비전(秘傳)에 말하기를 ｢신미일(辛未日)에 말전 묘(卯)를 보면 둔간(遁干)하여 정신(丁神)에 승하기 때문에 아내로 인하여 흉이 동한다. 혹은 재(財)로 인하여 화해(禍害)가 발생한다｣고 하였다.
백호가 묘유(卯酉)에 승하면 문(門)에 임한다고 칭하며, 출입(出入)에 관한 사건이 발생한다. 그러나 현무가 일간을 극하지 않으면 방해가 없다. 만약 일간이 동일한 오행(五行)일 때는 음사(陰私) 중에 횡재를 구할 수 있으며, 일간상신 유(酉)는 건록이 와서 내몸과 함께 한다고 한다. 그러나 일귀는 순행하여 삼전이 합(合)․주(朱)․사(蛇)이며 유(酉)에 백호가 승한다. 야귀는 역행하여 삼전이 사(蛇)․주(朱)․합(合)이며 유(酉)에 현무가 승한다. 녹(祿)은 현호(玄虎)에 빼앗겨서 현임관직 및 봉급생활자인 사람은 실직의 징조가 있다.
이 과의 초전의 사(巳)는 귀신(鬼神)에 있고 중전은 귀지(鬼地)에 있다. 또 말전은 정신(丁神)의 귀(鬼)를 형성한다. 전부 귀신(鬼神) 때문에 모든 일에서 반항을 받으니 재병(災病)을 예방해야 한다. 갑자(甲子)의 일순(一旬)은 유(酉)로써 순미(旬尾)가 된다. 일간상신에 순미가 승하며 현무가 동병(同倂)하는 것은 폐구격(閉口格)으로 기밀(機密)을 측정하기 어렵고 사적(事蹟)을 명확히 하기도 어렵다. 현무는 도적․가출․도주의 일이 있고, 도망자는 현무 쪽에 있다. 도적은 현무의 음신(陰神)의 방위에 있으며 잡을 수 있다.`
,
// 3
`  이 과는 4과 중에 제2과의 일상에서 일하를 극한다. 오신(午申)을 채용하여 초전으로 하며 삼전 오진인(午辰寅)은 역간(逆間)으로서 고조격(顧祖格)으로 된다.
초전의 오(午)와 일지상신의 사(巳)는 양화(兩火)로서 일간을 극하고 중전 진(辰)의 토(土)는 일간을 생한다. 또 말전 인(寅)은 일간의 재(財)로 되며 인(寅)을 둔간하여 병(丙)의 관귀효(官鬼爻)가 된다. 원수과(元首課)로 되어 군자(君子)는 그 덕(德)을 펴고 만사는 행통화조(行通和調)한다. 만약 오(午)가 태세(太歲)가 될 때는 용덕(龍德)의 격을 구성하여 우시운행(雨施雲行)하므로 功名을 달성한다.
고조격(顧祖格)은 모소(謀所)를 구하는 자에게 순조롭게 진척된다. 백법에 말하기를 ｢태재생기(胎財生氣)는 아내가 임신한다. 귀(鬼)가 천을에 승하면 천을신기격(天乙神祗格)으로 된다. 만약 태세 또는 월장 오(午)로서 귀신(貴神)이 승하는 경우에는 용덕격(龍德格)이 된다. 또 태세(太歲)에 귀신이 승하여 초전․중전의 어느 것인가에 월장의 신을 보면 이것은 역시 용덕으로 된다｣고 하였다. 군신제회(君臣除會)라고 칭하며 만사는 은혜를 받는 위(位)를 얻어 흔희광영(欣喜光榮)의 상(象)이다. 관공직에 있는 현임관은 더욱 길조를 이룬다. 그러나 용덕격으로 되지 않고 고조격이면 관공직의 현임관은 불길하며 파면이 아니면 퇴직한다. 관(官)이 많아서 관귀(官貴)가 없고 귀(貴)로 되지 않는다.
일귀는 순행하여 삼전이 구(勾)․주(朱)․귀(貴)이고, 야귀는 역행하여 삼전은 귀(貴)․주(朱)․구(勾)로 된다. 중전에 주작이 승하면 일간을 생하고 년명상신(年命上神)이 유(酉)로 되면 진(辰)의 주작과 지합(支合)하여 입시․시험․선거의 건은 반드시 적중한다.
두강(斗罡)이 일간․일지 상신에 가해져 초전으로 될 때는 사기격(死奇格)이라고 한다. 이 과정은 천반 진(辰)의 하(下)가 오(午)로 되고 오(午)는 일간의 음신으로서 초전으로 되는 것을 말한다. 천강(天罡)은 사기(死奇)이며 흉악의 신이다. 사수살(死囚殺)을 띠면 사망기괴(死亡奇怪)의 일이 있으므로 사기(死奇)라고 이름을 붙인 것이다. 이 격은 시가(媤家)를 나와서 화환(禍患)을 스스로 초래한다. 만약 왕상․덕합(德合)․길상(吉象)이 승하거나 상생(相生)할 때는 구신(救神)의 작용으로 되어 앞서 말한 흉해는 해제된다. 이것을 칭하여 사기회광(死奇回光)이라고 하며 화를 제거하고 복을 초래한다. 월장(月將)을 보는 것은 더욱 미격(美格)으로 변화한다.`
,
// 4
`  이 과는 4과의 상하에 상극이 없고, 또 요극(遙剋)도 없다. 이런 경우에는 다른 일신(一神)을 취하여 용신(用神)을 정하기 때문에 별책과(別責課)라고 부른다. 음일은 일지에 삼합(三合)하는 일신을 취한다.
예를 들면 금일은 미일(未日)이므로 미해묘(未亥卯)가 삼합한다. 미(未)의 앞인 해(亥)를 채용하여 초전을 정하고, 중․말전은 일간 상신의 미(未)를 써서 삼전을 구성한다. 지합(支合)․간합(干合)의 형충극(刑冲剋)은 어느 것이나 활동을 의미하므로 이와같이 다른 일신에 호소하여 삼전을 정한 것이다.
별책과는 4과가 완전하지 않고 3과밖에 없는 불비격(不備格)이다. 그러므로 매사가 불비 불완전하여 유연(留連)의 체(體)를 구성한다. 이 과(課)는 일명 무음(蕪淫)이라고도 부른다. 즉 이양일음(二陽一陰)은 이남일녀(二男一女)가 싸운다. 또 이음일양(二陰一陽)은 이녀일남(二女一男)가 싸운다. 즉 바른 것을 버리고 따로 합신(合神)을 구하여 음(淫)으로 달리기 때문에 이런 이름이 붙었다.
어떤 일이라도 연속해서 일이 많아 전진하려 하여도 전진할 수 없다. 임신은 늦어지고 모든 일을 타인에 의존하기 때문에 길흉은 사람과의 관계에서 발생한다. 서양자로 가려 하였으나 오히려 처를 취한다. 또한 시집을 가려고 하나 사위를 구한다. 가정을 정단할 때는 부처(夫妻)에게 음란의 징조가 있다.
일지의 미(未)가 일간상에 와서 자재격(自在格)이라고 한다. 지(支)가 와서 일간을 생하고 초전 해수(亥水)는 탈기(脫氣)라 하더라도 미토(未土)로 제(制)하여 다시 공망(空亡)으로 되므로 설손(泄損)이 없다. 또 중․말전은 내몸을 생하지만 술(戌)의 공망상에 있다. 그러나 일지에 전실(塡實)하여 공망이 해제되므로 생기(生氣)가 있다.
백법에 말하기를 ｢무음격은 부부간에 사정(私情)을 갖고 있어 비밀리에 쾌락을 이룬다｣고 하였다. 일귀는 역행하여 삼전은 합(合)․백(白)․백(白)이며, 야귀는 삼전이 백(白)․후(后)․후(后)로 된다. 이 과의 래정(來情)은 자손과 태산의 일이거나 색정 문제의 일이지 공명․구직 등의 일이 아니다.`
,
// 5
`  이 과는 제1과와 제3과에 상하의 극이 있다. 금일이 음일이면 음비(陰比)의 극을 채용하여 묘미(卯未)를 써서 초전으로 하는 지일과(知一課)이다. 삼전 묘해미(卯亥未)는 삼합목국(三合木局)으로 곡직격(曲直格)으로 된다. 묘(卯)에 정신(丁神)이 승하여 일간을 극하고, 오(午)는 일간을 극하더라도 제1과와 제3과는 오미(午未)가 지합하고 묘술(卯戌)이 지합하여 교차지합(交車支合)하므로 극해(剋害)도 결국에는 화합타협(和合妥協)하게 된다. 삼전(三傳) 목국(木局)은 먼저 곧다가 후에 구부러져 신금(辛金)을 만나 기재(機材)를 이룩한다. 그러나 말전이 공망하므로 부목(腐木)이 되어 기재로 되지 못한다. 다만 초전의 일신에 따를 때는 사과삼전이 삼재(三財)가 있어 구재(求財)는 많이 구하여 얻게 된다.
백법에 말하기를 ｢염막귀신(簾幕鬼神)은 시험․선거에 합격 당선되고, 교차상합(交車相合)은 교관(交關)의 이(利)가 있으며, 금일(金日) 정(丁)에 만나면 흉화(凶禍)에 동한다. 전재(傳財)가 귀(鬼)에 화하는 것은 재(財)를 구하여 재(災)를 일으키고 후(后)․합(合)의 점혼(占婚)은 자유 연애로서 중매가 없다. 피아전상(彼我全傷)은 서로 손해를 받는다｣고 하였다. 비전에서 말하기를 ｢이상(二上)에서 하(下)를 극할 때는 동류나 벗이 서로 구해(仇害)를 일으켜 화(禍)는 외부로부터 온다. 일귀가 일간상에 승하여 귀(鬼)가 있어도 귀숭(鬼崇)으로 보지 말아야 한다. 귀인에게 관계되는 용건이 있다고 할 수 있다｣고 하였다. 일간 일지에 양극(兩剋)이 있어도 교차상합하여 오히려 삼합회국(三合會局)으로 되면 처음에는 불화하지만 후에 화합한다.
일귀는 역행하여 삼전은 후(后)․합(合)․백(白)이고 일녀격(泆女格)이 된다. 야귀는 삼전이 합(合)․백(白)․후(后)로서 교동격(狡童格)으로 되며, 병단이나 소송은 흉하다. 초전이 묘(卯)나 또는 유(酉)이고 후(后)․합(合)이 승하는 경우에는 음일(淫泆)의 신(神)으로서 색정음분(色情淫奔)의 일이 있다. 교동격은 남자가 여자를 유혹하여 도망(逃亡)간다. 염치를 불구하고 호색(好色)의 남자가 되면 교동이라고 이름을 붙인다. 교녀격(狡女格)은 여자가 남자를 유혹하여 음사음욕(淫邪淫慾)을 금하는 일이 없다. 결혼․가취(嫁娶)가 불길하며 도망가는 것이 좋다. 기혼녀는 남편을 등지고 정부(情夫)를 따라 도주의 뜻이 있다.`
,
// 6
`  이 과는 4과가 모두 상에서 하를 극하여 섭해과(涉害課)로 된다. 음일음비(陰日陰比)의 극이고 사맹상신(四孟上神)의 유인(酉寅)을 초전으로 한다. 상에서 극하는 것은 상도액(上度厄)이라고 칭하며 또 무록(無祿)․사절격(四絶格)이다.
초전은 순미(旬尾)의 유(酉)가 초전으로 되어 폐구격(閉口格)으로서, 이것이 절지(絶地)에 임하는 것은 군자(君子)는 심히 이것을 증오한다. 일간은 사(巳)의 극을 만나고 제3과 인(寅)에 병(丙)이 둔간하여 관(官)으로 되며 일간에서 보아서 재동(財動)으로 되나 피아(彼我)가 함께 재앙을 받는다.
4상(四上)은 하를 극하여 무록격(無祿格)으로 되면 타개(打開)의 길이 없다. 사절(四絶)은 고독하고 원조가 없으며 굴(屈)하여 신(伸)하기 어렵다. 말전 해수(亥水)는 사(巳)의 귀살(鬼殺)을 제어하여 구신(救神)이 된다. 그러나 공망하여 역량이 약하고 회한(悔恨)이 있다. 다만 어떤 일도 선수를 칠 때는 승리한다.
삼전의 천장에 길신이 승하여 도움이 되기 때문에 몸을 삼가하여 반성하면 오히려 길조를 얻는다. 절소(絶所)에서 생(生)을 만나므로 처음에는 흉이더라도 후에는 길태(吉泰)를 얻는다. 만약 강한 것을 의뢰할 때는 궁경(窮境)에 도달한다. 백법에 말하기를 ｢간(干)과 지(支)가 함께 상(傷)하는 것은 피아(彼我)가 모두 양손(兩損)으로 된다. 또 피아간에 시기와 의심이 따른다. 태재사기(胎財死氣)는 태산(胎産)한다｣고 하였다. 비전에 말하기를 ｢무록격은 자신과 인연이 없고, 자식을 점하면 자연(子椽)이 없다. 도액격(度厄格)으로서 존장은 소인 또는 아랫사람을 기만한다｣고 하였다.
일귀는 역행하여 삼전은 청(靑)․음(陰)․합(合)이고, 야귀는 순행하여 삼전은 합(合)․음(陰)․청(靑)이다. 천장(天將)은 어느 것이나 길장(吉將)이 승하여 도움을 주므로 흉해는 경미하거나 해소된다. 질병을 정단하면 음식을 단절하여 사망한다. 4상(四上)이 하를 극하여 장도액격(長度厄格)으로 되고 병은 중태(重態)이다. 가정은 불길하여 골육(骨肉)이 서로 반목한다. 진행하는 사업은 손해뿐이고, 떠나는 자는 재해(災害)를 만난다. 왕상(旺相)하여 삼전에 길장(吉將)이 있으며 그리하여 화를 지나 복신(福神)이 와서 일지(日支)의 음신으로 초전을 구성하는 것은 유소년기(幼少年期)의 재(災)에 주의해야 한다.`
,
// 7
`  반음과(返吟課)는 천지 7충의 천지반을 이룬다. 이 과는 4과 상하의 극이 없으면 일지(日支)에서 사각(斜角)에 쏘는 것을 초전으로 한다. 이 과는 신미일(辛未日)의 6일은 어느 것이나 상하에 상극이 없다. 때문에 일지로부터 비스듬히 쏘는 것이 극(剋)을 채용하여 초전으로 한다.
이 과는 미일(未日)이고 삼합(三合)에서 비스듬히 해(亥)를 쏘는 해(亥)의 지상(地上)의 충신(冲神)을 채용하여 초전으로 한다. 만약 축일(丑日)이라면 비스듬히 쏜 삼합 사(巳)의 상충신(相冲神) 해(亥)를 채용한다. 이 과는 미일(未日)이 되면 법칙에 의해 사(巳)를 초전으로 한다. 중전은 일지상신의 축(丑)을 쓰고 말전은 일간상신(日干上神)을 쓰며, 비스듬히 충사(冲射)하여 충개발산(冲開發散)으로 초전을 구성하기 때문에 정란사격(井欄射格)이라고 이름을 붙였다. 일명 무친격(無親格)이라고도 한다.
정단사는 내외에 괴상한 사건이 있다. 길흉이 모두 장구(長久)하지 못하여 활동하는 것이 좋고 조용한 것은 착오를 낳는다. 일신(一身)을 양용(兩用)하므로 만사를 옆에서 구하여 성취하기 쉽고 직도(直道)에 활동하는 것은 용이하지 않다. 모든 일은 빨리 이루기 쉽고 또 파괴하기도 쉽다. 간지(干支)가 함께 묘신(墓神)에 복하여 피아(彼我) 전도(顚倒)의 상으로서 만사는 회한(悔恨)을 본다. 초전․중전이 모두 술해(戌亥)의 공망에 해당하므로 경영하는 사업이나 계획하는 일은 시종은 완전하지 못하다.
백법에 말하기를 ｢간지가 함께 묘신에 승하여 모두다 혼미(昏迷)하다. 일야양귀(一夜兩貴)가 승하여 천지가 함께 상극하므로 귀인(貴人)․존장의 노여움이 있다. 일지상신 묘신(墓神)에 백호가 승하는 것은 가택 안에 복시(伏屍)가 있다｣고 하였다. 비전에 말하기를 일귀(日鬼)가 초전으로 되고 중전․말전이 묘(墓)에서 만나면 상인(常人)은 기쁨을 만난다. 간지상신에 묘신이 가해지면 질병을 정단하면 치료할 수 없으며 재(災)는 해소하기 어렵고 사람과 약속한 일은 위약(違約)되며 대인(待人)은 빨리 돌아온다｣고 하였다.
일귀는 역행하여 삼전은 현(玄)․사(蛇)․음(陰)이며, 야귀(夜貴)는 순행하여 삼전은 후(后)․백(白)․음(陰)이다. 어느 것이나 천장(天將)은 흉장(凶將)이다.`
,
// 8
`  이 과는 4과가 전부 극하여 섭해과(涉害課)이다. 맹신상(孟神上)의 극을 취해야 하는 법칙인데, 맹(孟)이 없는 것은 충신(冲神)의 극을 취한다. 그러므로 하에서 상을 극하고 사자(巳子)의 제4과를 초전으로 한다.
모두 극이 있으면 도액격(度厄格)으로 되고 사술묘(巳戌卯)의 삼전은 주인격(鑄印格)이다. 4과에 극이 많은 것은 상하가 함께 화기(和氣)가 없다. 특히 묘(卯)에 정신(丁神)이 승하는데 묘(卯)는 신(辛)의 처재(妻財)에 해당한다. 그리하여 처 또는 재로 인하여 재(災)를 초래한다. 현무가 승하면 부부 사이가 이간되고, 삼전 사술묘(巳戌卯)는 주인격(鑄印格)으로 된다. 군자는 기회를 알고 인(仁)을 행하며 덕을 베푼다. 그리하여 명성이 따른다. 초전․말전에 천후(天后)․현무가 승하여 중․말전이 공망하고 주인파격(鑄印破格)하면 모든 일에 있어서 근신하는 것이 좋다.
백법에 말하기를 ｢피아(彼我)가 서로 투합(投合)하지 않으면 간지상신(日支上神)이 지형(支刑)으로 된다｣고 하였다. 비전에 말하기를 ｢술(戌)은 인수(印綬)이고 술(戌)의 장간(藏干)에 신금(辛金)이 있다. 사화(巳火)로써 극하고 이것으로 인모(印摸)를 구성하여 현임관직 또는 회사원은 중임(重任)되며 명리가 향상하지만 공망하여 분도(分度)를 감하게 한다.｣고 하였다.
4과가 모두 상극하면 육친장유(六親長幼)가 함께 불길하다. 섭해(涉害)가 깊은 것은 견기격(見機格)이라고 부르며 기회를 신속히 간파해야 한다. 시기를 잃으면 목적을 달성하기 어렵다. 일귀가 인(寅)에 승하고 유(酉)의 지반에 임하는 것은 여덕격(勵德格)이다. 군자에게 이롭고 소인에게는 불리하다. 선사(善事)를 이루면 복이 오고 부정(不正)을 행할 때는 화를 초래한다. 백법에 말하기를 ｢주객(主客)이 투합(投合)하지 않는 것은 형(刑)이 상에 있다. 중․말전이 공망하여 구관(求官)․구직(求職)의 뜻은 있어도 현실로 되는 일은 어렵다. 그러나 공망을 벗어나면 희망이 있다｣고 하였다. 년명상신 술(戌)의 공망전실(空亡塡實)은 공망론(空亡論)을 채용하지 않는다.
일귀는 역행하여 삼전은 현(玄)․구(勾)․후(后)이고, 야귀는 순행하여 삼전은 후(后)․구(勾)․현(玄)이다. 막귀(幕貴)가 극을 받으므로 입시․선거사는 어느 것이나 당선․합격이 불가능하다. `
,
// 9
`  이 과는 제1과․제3과의 하에서 상을 적(賊)하며, 금일이 음일이면 음일의 극을 취하여 초전으로 한다. 즉 음비를 써서 지일과 비용격(比用格)이 된다. 또 해묘미(亥卯未)가 삼합목국(三合木局)으로 되어 곡직격(曲直格)으로 된다. 정단사는 먼저 굴(屈)하고 후에는 신(伸)하며, 처음에는 난색(難色)을 보이지만 후에는 쉽다. 선악(善惡)이 혼합(混合)되어 있으므로 류신(類神)을 구하여 심사해야 한다. 수(髓) 4과의 천지반과 일간지(日干支) 상하(上下)가 상합(相合)․육합(六合)하여 화동(和同)한다. 그러나 초․말전 및 제3과․제4과가 공망하기 때문에 그 대부분은 목적을 달성하기 어렵다. 삼합이 삼전하여 전재(全財)로 되고 육합․청룡이 초전에 승하여 또 재동(財動)으로 되며 재왕(財旺)하게 된다. 분수를 넘으면 겉으로는 좋아도 내부는 공허의 상으로 된다. 귀신(貴神)이 술(戌)에 임하여 옥(獄)에 앉아도 일간상신에 승하면 오히려 길조로 된다.
백법에 말하기를 ｢삼합과 육합이 겹치면 만사에 경사가 있고, 중전에 정신(丁神)이 승하면 즉 금일정신(金日丁神)에 만나면 흉으로 동한다. 그러나 공망하면 흉은 극히 경미하다｣고 하였다. 비전에 말하기를 ｢묘(卯)가 해(亥)에 가해지고 일간의 태(胎)는 장생(長生)에 앉기 때문에 임신을 정단하면 길이다｣고 하였다. 출산을 정단할 때 삼육합(三六合)되면 출산의 정단은 불길하다. 합(合)으로 될 때는 모복(母腹)을 연연해 하여 떠나기 어렵기 때문에 출산은 지체된다. 재기(財氣)가 중첩되면 처첩(妻妾) 사이에 시비(是非)의 문제가 발생한다.
일귀는 역행하여 삼전은 합(合)․후(后)․백(白)이고 야귀는 순행하여 삼전은 청(靑)․현(玄)․사(蛇)로 된다. 청(靑)은 사(蛇)로 변하여 처음에는 길조라도 후에는 흉으로 되므로 만사를 신속히 처리하는 것이 좋다.
지일과(知一課)는 이하(二下)가 상을 극하므로 일은 양기(兩岐)로 분리되거나 혹은 두가지 사건이 있다. 그러나 하나를 선택하여 격을 이루어 하나로 돌아오는 것이 좋다. 모든 일은 가까운데 있으며, 하(下)가 상(上)을 극하여 질투의 사건이 발생한다. 혼인은 화합을 잃기 쉽고 사건은 동류(同類)에 의해 발생한다.`
,
// 10
`  이 과는 신미일(辛未日) 제4과와 같이 상하의 상극이 없다. 음일이면 일지(日支) 삼합(三合)의 전일신(前一神) 해(亥)를 채용하여 초전으로 하고, 중․말전은 일간상신 축(丑)으로 한다. 즉 다른 일신(一神)을 사용하는 과(課)이므로 별책과(別責課)라고 부른다. 4과가 불비로 되면 무음불비격(蕪陰不備格)이고 귀신(貴神)이 지반의 묘(卯)에 임하면 여덕격(勵德格)으로 된다.
이 과(課)는 놀랍고 괴상한 일이 있어도 실해(實害)가 없다. 4과가 불비로 되면 사물(事物)이 완전하지 않고 한편으로 치우치거나 혹은 결여된다. 희망하는 일은 달성하기 어렵다. 또 이양(二陽)이 일음(一陰)을 다투어 기강(紀綱)이 문란하고 길이 적고 흉이 많다. 여덕격은 상중계급에게는 승진의 기쁨이 있어도 하급관리나 봉급자는 휴직 또는 퇴직의 우려가 있다. 초전 및 제3과․제4과가 공망하여 길흉은 어느 것이나 이루기 어렵다.
백법에 말하기를 ｢간상(干上)의 묘(墓)에 호(虎)가 승하면 병단에 가장 흉하여 사망한다｣고 하였다.  비전에 말하기를 ｢불비난음(不備亂淫)은 반드시 음사(陰私)의 일이 있고 현무가 해(亥)에 승하여 초전을 구성하면 슬피우는 일이 발생한다.｣고 하였다.
별책과(別責課)는 어떤 일도 다른 곳에서 발단한다. 강을 건너려고 배를 찾아 구하는 것과 유사하다. 중․말전의 축(丑)은 둔간하여 을(乙)의 재(財)가 되지만 공망에  앉아서 재(財)를 얻으려고 하여도 상대방에 재(財)가 없기 때문에 희망이 없다. 가령 수재(收財)일지라도 오른쪽으로부터 들어와서 왼쪽으로 빠져나가는 것이니 내몸의 소득이 될 수 없다.
일귀는 순행(順行)하여 삼전은 현(玄)․후(后)․후(后)이고, 야귀는 청(靑)․백(白)․백(白)이다. 현무는 도적의 류신으로서 초전 해(亥)의 도적 류신이 승한다. 중․말전이 천후(天后)이면 도적은 여성이며, 공망하여 잡기 어렵다. 만약 공망하지 않을 때는 현무의 음신에 해당하는 천신(天神)이 도적을 잡는 곳에 해당된다. 재(財)의 분실도 역시 음신(陰神)의 위의 장소를 찾으면 반드시 발견된다.`
,
// 11
`  이 과는 사과 중에 상하의 극이 없고 일간에 의해 제2과 천신(天神)의 인(寅)을 요극하여 초전으로 한다. 일간에 의해 극하는 것은 탄사격(彈射格)이다. 일간의 음신(陰神)을 발용(發用)으로 하는 것은 일상(日上)의 양과(兩課)가 서로 스스로 싸우기 때문에 활동에 힘이 없다. 점사(占事)에 관계하지 말고 모든 일에 간섭하지 말아야 한다. 
요극은 토(土) 또는 금(金)을 탄환으로 하므로 진토(辰土)가 중전에 있어서 탄환으로 된다. 인진오(寅辰午)는 순간전(順間傳)으로서 출삼양격(出三陽格)이라고 한다. 향영흔흔(向榮欣欣)이라고 하여 눈앞의 성과는 없지만 후에 광명을 얻는다.
간상 자(子)와 일지 미(未)는 육해(六害)로 되고 기궁(寄宮) 술(戌)과 유(酉)도 육해로 되어 교차육해(交車六害)이다. 일간이 자(子)에 설기(泄氣)하더라도 초전 인(寅)의 재(財)를 생하여 재(財)의 근(根)이 되며 힘이 있다. 말전 오(午)의 화(火)는 일간을 극하여도 간상(干上)의 자(子)는 구신(救神)으로 되고 귀(鬼)를 제복(制伏)한다. 일간상신의 자(子)는 순수(旬首)이고 일지상신의 유(酉)가 순미(旬尾)로 되면 처음이 있고 결과가 있어서 주편격(周偏格)이라고 부른다.
백법에 말하기를 ｢일순주편격(一旬周偏格)은 시종 서로 좋다. 일지상신이 유(酉)의 건록(建祿)에 승하면 권섭(權攝)이 바르지 않다｣고 하였다. 비전에 말하기를 ｢일간상신 자(子)에 설탈(泄脫)하고 천공(天空)이 승하여 탈공격(脫空格)이라고 하여 전혀 실적(實績)이 없다. 탄사격은 집에 래객(來客)이 있어도 머무르지 않는다｣고 하였다.
고정사건은 서남으로부터 발생한다. 일귀는 순행하여 삼전이 귀(貴)․주(朱)․구(勾)이고, 야귀는 삼전이 상(常)․음(陰)․귀(貴)이다. 귀신(貴神)은 관성(官星)으로 되어 공명․구직의 목적을 달성한다. 출삼양격(出三陽格)은 병점 및 쟁송사의 정단은 어느 것이나 흉하고 그 밖의 일은 모든 일이 길조이다.`
,
// 12
`  이 과는 사과 중에 상하의 극이 없고 또 요극도 없는 것으로 유(酉)를 표준으로 하고, 음일(陰日)은 유(酉)의 지반지지(地盤地支)를 취하여 초전으로 한다. 서방(西方)의 백호가 금기(金氣) 형살로 되어 사생출입(死生出入)의 문(門)이 되므로 유(酉)에 따라 입전(立傳)한다. 유(酉) 중에 묘성(昴星)이 있기 때문에 이렇게 명명한 것이다.
중전은 간상신(干上神)을 사용하고 말전은 일지상신으로써 삼전을 결성한다. 음일(陰日)은 동사엄목격(冬蛇掩目格)이라고 한다. 음성(陰性)은 지(地)에 따르고 기(氣)는 아래에 숨어 암매불명(暗昧不明)하여 진퇴가 궁하게 된다. 화(禍)는 가정의 내부에서 발생한다.
이 과는 사과삼전 중에 해(亥)가 2개 있고 백호가 승하며, 신(申)은 3개가 있다. 백호의 본궁(本宮)은 신(申)이므로 과전에 5호(五虎)가 존재한다. 사호(蛇虎)가 삼전 중에 보이면 흉이 중하여 병단을 하면 사망하며 진퇴양난(進退兩難)이다. 이 과는 흉화가 중대할 뿐만 아니라 경천동지(驚天動地)의 상으로 대단히 신중해야 할 점과이다.
일귀는 순행하여 삼전이 공(空)․현(玄)․공(空)이고 도둑의 소행이며, 야귀는 역행하여 삼전이 음(陰)․백(白)․음(陰)이고 어느 것이나 불길의 천장(天將)으로 되어 화를 면하기 어렵다. 백법에 말하기를 ｢호(虎)를 보고 또 호를 만나면 힘을 펴기 어렵다. 모사가 졸책(拙策)으로 되는 것은 나망(羅網)을 만나기 때문이다. 도적은 천망(天網)에 걸린다｣고 하였다. 비전에 말하기를 ｢백호가 창광(猖狂)하면 가정에 불상사가 발생하며, 일간일지가 탈기(脫氣)하고 현무가 승하여 탈도격(脫盜格)으로 되어 도난을 만난다｣고 하였다.`
,
]


export default {
  columnA,
  columnD,
  columnS,
  columnZ,
  columnAF,
  columnAS,
  columnAU,
  columnBE,
  columnBO,
  columnBS,
  columnBV,
  columnCE,
  columnCL,
  columnCY,
  columnDH,
  // 
  CHOSA1,
  YOGYEOL1,
  SIBO1,
  SIBO2_1,
  SIBO2_2,
  SAGWA_IMAGE1,
  HEASYEOL,
}
