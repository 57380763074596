
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,5,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,8,4,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`신뢰`,9,3,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,2,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,1,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,12,`특별있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,1,11,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,2,10,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,3,9,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,4,8,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,5,7,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,6,`있음..`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`직녹`,`직업직위사`,`小`,``,``,``],
    [`권리`,`자산 직위 등 권리사`,`小`,`1`,`도적의혹`,`재난전소`],
    [`남편`,`남편관련사-외 부부남녀화합사`,`大`,`절정기`,`외화합사`,`압박수난`],
    [`좌모동지(座謀動止)`,`일을 앉아서 꾀하는 것이 좋고 움직이면 손실이 되는 상`,`大`,``,`남녀부부`,``],
    [`천이자상(遷移之象)`,`가택을 옮기거나 가족의 병을 근심하는 상`,`小`,``,``,``],
    [`관사`,`관사관련사`,`小`,``,``,`관계불통`],
    [`막힘`,`외사 외교막힘사`,`大`,`2`,`굴욕반복`,`가택이사`],
    [`남자`,`남자관련사-내부가정개신사 부족`,`小`,`면모기`,`외교막힘`,`간사은닉`],
    [`구재음동(求財陰動)`,`금전을 구하기 위해 주색음란사를 벌이는 상`,`小`,``,``,`改新의사`],
    [`손익필난(損翼必難)`,`스스로 물에 빠져 날개를 상한 격으로 일이 거꾸로인 상`,`大`,``,``,``],
    [`박관`,`직업직위불안사- 급속사`,`大`,``,``,``],
    [`극부`,`남편갈등사-내부가정 송쟁-강제급속손실사`,`小`,`3`,`소인쟁재`,``],
    [`자식`,`자식관련사-`,`小`,`학습기`,`급속불안`,`대립송쟁`],
    [`소인쟁재(小人爭財)`,`신분상 차이가 나는 자와 금전 다툼을 벌이는 상`,`大`,``,``,``],
    [`상호기만(相互欺瞞)`,`서로 속이는 상-토지가옥사 관련-1,2,10,11월은 묘지사`,`小`,``,``,``],
    [`결속`,`개혁하여 후원사를 크게 이루나 자식災害 발생함`,`大`,``,``,``],
    [`남자`,`남자관련사-내부가정개신사-이혼문제사 많음 `,`小`,`4`,`간인궤계`,`불리고시`],
    [`여식`,`자녀관련사`,`小`,`개시기`,`재해수난`,`구설실재`],
    [`영전도약(榮轉跳躍)`,`발탁되어 영전 도약하는 상`,`大`,``,``,``],
    [`개신개혁(改新改革)`,`여러 일을 하나로 일관시키는 개신개혁의 상-이혼사도 많음`,`小`,``,``,``],
    [`관사`,`관사관련사`,`小`,``,`문서상신`,``],
    [`직업`,`직업 관련사`,`大`,`5`,`답신지체`,``],
    [`극처`,`파재 극처사-실종사 우려`,`小`,`배양기`,`파재실종`,`비상지애`],
    [`명의양도(名義讓渡)`,`가옥의 명의를 타인에게 넘기는 상`,`小`,``,`우려`,`(각별애정)`],
    [`구설가식(口舌可息)`,`탐영(貪榮)적 문서상신의 회답 지체의 상-구설사 포함`,`大`,``,``,``],
    [`자금`,`자금 관련사`,`大`,``,``,``],
    [`사업`,`사업관련사-개신사 부족-음8월 가능함`,`大`,`6`,`불의투서`,`간인궤계`],
    [`처`,`내부가정 막힘사`,`小`,`존재기`,`獻策`,`가정막힘`],
    [`피차수상(彼此受傷)`,`서로 상(傷)함을 초래하는 상-배신적-이사 함`,`小`,``,`구설사`,``],
    [`천발천이(薦拔遷移)`,`추천 발탁되어 영전하는 상`,`小`,``,``,``],
    [`색정`,`외男 내女 동시 색정사有`,`大`,``,``,``],
    [`매사허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`7`,`불변근신`,`소인쟁재`],
    [`매사영속성없음`,`금전사업이익처여인사는 선천적 기복재해를 겪음`,`小`,`단절기`,`색정사有`,`색정사有`],
    [`문자암매(文字暗買)`,`문자(문장)를 몰래 사들이는 상`,`小`,``,``,``],
    [`불변근신(不辨謹愼)`,`장차를 위해 말을 삼가하고 근신해야 하는 상`,`小`,``,``,``],
    [`후원`,`개혁하여 후원사를 크게 이루나 자식災害가 발생함`,`小`,``,``,``],
    [`매사허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`8`,`발탁도약`,``],
    [`매사영속성없음`,`관사직업권리남편남자사는 선천적기복재해를 겪음`,`小`,`침체기`,`동요분산`,`관사형책`],
    [`주유형책(主有刑責)`,`주력사가 죄가 되어 처벌을 받게되는 상`,`小`,``,``,``],
    [`개신개혁(改新改革)`,`여러 일을 하나로 일관시키는 개신개혁의 상-이혼사도 많음`,`大`,``,``,``],
    [`후원`,`후원조력 관련사`,`小`,``,``,``],
    [`애증`,`내부 가정 애증 관련사`,`小`,`9`,`비곡차욕`,`愛憎화합`],
    [`부친`,`부친 소외 애증관련사`,`大`,`소외기`,`차별소외`,`파재극처`],
    [`혼인선통(婚姻先通)`,`혼인은 먼저 정을 통하고 후에 혼인하는 상`,`小`,``,`관련`,`가출우려`],
    [`혼인미만(婚姻美滿)`,`혼인사가 만족하여 예쁘게 단장하는데 여념이 없는 상`,`大`,``,``,``],
    [`후원`,`후원조력 관련사`,`小`,``,``,``],
    [`문서`,`내외 개신개혁 문서 관련사`,`小`,`10`,`관계불통`,`사송계류`],
    [`모친`,`모친 내부가정 관련사`,`大`,`침해기`,`개신개혁`,`개신개혁`],
    [`범모무성(凡謀無成)`,`모든 계획사에 결핍이 많아 성취하지 못하게 되는 상 `,`小`,``,`가택이사`,``],
    [`사송계류(詞訟稽留)`,`오고가는데 발생한 소송사가 끝나지 않고 진행중인 상`,`大`,``,`간사은닉`,``],
    [`관사`,`관사 관련사`,`小`,``,`우유한가`,``],
    [`직업`,`직업 관련사`,`大`,`11`,`실업처지`,``],
    [`송쟁`,`외 급속송쟁사-상해 실종 우려`,`小`,`쇠퇴기`,`급속송쟁`,`침체절감`],
    [`가택불녕(家宅不寧)`,`가택이 공허하여 편안하지 못한 상-독신고독쇠퇴 관련`,`小`,``,`상해우려`,``],
    [`왕래사송(往來詞訟)`,`왕래하며 일어난 벌칙이 이어지는 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`분산`,`내부 가정 동요 불안 분산사`,`小`,`12`,`존비상몽`,`구설사송`],
    [`출행`,`내외 출행이동원행사有`,`大`,`과시기`,`타인가해`,`동요분산`],
    [`병송수봉(病訟垂逢)`,`병과 관재를 만나게 되는 상`,`大`,``,``,`문서사吉`],
    [`존비상몽(尊卑相蒙)`,`윗인(男)과 아래인(女-종업원) 의 난음(亂陰) 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,`직위자산권리남편조력사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`관사직업남자사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위불안남편갈등자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안남편갈등자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`관사직업남편이사사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,`금전상업처조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`후원문서부친조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`후원문서모친조력내외개신개혁사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사직업남편가정불년으로가능`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,`형제자매친구동료조력출행사로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 강력하고 정당하게 먼저나서서 속결해야만 유리함`,0,0,`정당한 명분이 있으므로 선수를 쳐서 속결해야만 유리하게 되는 人`,0,0,`일이 엿순으로 진행함**약속은 해체되고 흉사는 풀림`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화유순한 처신을 하면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 대하아지 말고 순응하는 자세로 연기 또는 지체시키면 결과가 반드시 유리해지는 人`,0,0,`일이 띄엄띄엄 진행됨**모든 일을 사적 이면적으로 진행해야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 강력하고 정당하게 먼저나서서 속결해야만 유리함`,0,0,`정당한 명분이 있으므로 선수를 쳐서 속결해야만 유리하게 되는 人`,0,0,`토지가옥관련사**입을 다물어야 하는 입장이거나 말을 하지 않음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 강력하고 정당하게 먼저나서서 속결해야만 유리함`,0,0,`정당한 명분이 있으므로 선수를 쳐서 속결해야만 유리하게 되는 人`,0,0,`여러 사안을 하나로 묶는 개신개혁사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화유순한 처신을 하면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 대하아지 말고 순응하는 자세로 연기 또는 지체시키면 결과가 반드시 유리해지는 人`,0,0,`관직영전-녹위증가**임신출산질병은 나쁨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`반복不定하고 의지할 데가 없는 人`,0,0,`일이 결과없이 반복되고 비로소 순조로운 기회가 당도함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화유순한 처신을 하면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 대하아지 말고 순응하는 자세로 연기 또는 지체시키면 결과가 반드시 유리해지는 人`,0,0,`어떤 일도 추중반에는 불성함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`상대가 스스로 와서 금전사업여인사를 도움**개신개혁함-이혼사인 경우도 있음**여러 사람의 추천을 받음 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화유순한 처신을 하면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 대하아지 말고 순응하는 자세로 연기 또는 지체시키면 결과가 반드시 유리해지는 人`,0,0,`신규사가 있음**전화위복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**피아간 전의가 별로 없고 있다하더라도 가벼움`,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란듯한 마음이나 상대는 호가호위의 세력을 부리는 것이므로 조용히 있으면 놀래는 일이 자연히 사라지게 되는 人`,0,0,`일이 결과없이 반복됨**독신고독각방주말부부생리사별의 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**피아간 전의가 별로 없고 있다하더라도 가벼움`,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란듯한 마음이나 상대는 호가호위의 세력을 부리는 것이므로 조용히 있으면 놀래는 일이 자연히 사라지게 되는 人`,0,0,`어떤 일이든 초, 중반에는 불성함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리며 움직이지는 않음`,0,0,`자기를 스스로 유약하다고 여기고 적극적으로 나서지 않는 人-행동이 강제로 억제되는 경우도 있음`,0,0,`토지가옥사로 송쟁함**일이 결과없이 반복되나 계속 진행하면 밝아짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`충성`,`배신`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성-무력`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동아후회사겹침`,`미사`,`신구중간사`,`외내사겹침`,`아이면타표면사겹침`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사후회사겹침`,`기사`,`구사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-이사`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`양자무력`,`양자무력`,`양자무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,2,2,4,`음6월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음11월`,3,2,5,`음5월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음10월`,2,3,5,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월-연기 또는 파혼우려`,1,2,3,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월-연기 도는 파혼우려`,3,1,4,`음6월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음9월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음8월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,1,2,3,`음1월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음10월`,3,3,6,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월`,1,1,2,`음8월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음2월`,1,1,2,`음8월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음1월`,2,3,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 시종 순준 이하로 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 종반에 측근을 통해 내부 동요불안분산사를 일으키나 제압됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무전반이 수준이하 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 여러 일을 일관싴는 개신개혁사를 일으켜 성공함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이나 권한행사를 확립하는 권위를 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 의지 배경이 없어 외풍에 시달리고 이동사로 동요분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 급해 초 중반을 허무하게 보내고 종반에도 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 사람을 잘 조정하여 개신개혁을 단행하여 완성하고 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻어 초반에는 실적을 이루나 종반에는 내부와 충돌하여 동요분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔 한 듯하나 사람을 잘조정하지만 업무는 초 중반이 허무하고 종반에도 재정을 분산하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔 한듯하나 사람을 잘 조정하지만 초 중반을 허무하게 보내고 종반에서야 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정이 있어 상대와 같은 소리를 내고 업무도 시종 실패하며 시종 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`多集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선不후不`,`進向`,`근`,` 留`,`세입조력`,`북북동`,``,`정서`],
    [`10.11`,`集`,`본인화합`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,`제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근내부`,`이행`,`선損후損`,`向進`,`  원`,`  留`,`주인조력`,`동북`,``,`북서`],
    [`10.11`,``,`제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본안번대`,`이행`,`선損후不`,` 退背`,`  원`,`  去`,`주인조력`,`정동`,``,`북북서`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,`이후반목`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`이행`,`선난후이`,`進向`,`  근`,`  留`,`세입조력`,`동남`,`정북`,``],
    [`10.11`,``,`본인제압`,``,`천천히`,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`권리양도`,`進向`,`  근`,`  留`,`주인가해`,`동남`,`북북동`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`본인무력`,` 退背`,`  원`,`  去`,`주인가해`,`북북서`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`하게됨`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부분란`,`이행`,`본인무력`,`退背`,`원근왕래`,`  去`,`세입조력`,`정북`,`정서`,``],
    [`10.11`,``,`으로 유일`,``,``,``,``,``,`살기피로`,``,``,``],
    [`3.6\n9.12`,``,`제압됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인동조`,`이행`,`선이후난`,`進向`,`  원`,` 留`,`세입가해`,`서남`,`북북동`,``],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,`설기피로`,``,`세밀심사`,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,`이후결속`,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인반대`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`세입가해`,`서남`,`남남동`,``],
    [`10.11`,``,`끌면제압`,``,`빠르게`,``,``,``,`애증화합`,``,``,``],
    [`3.6\n9.12`,`  集`,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`단합제압`,`이행`,`사안무력`,`退背`,`원근왕래`,`  去`,`주인무력`,`정서`,``,`정북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`무력함`,`이행`,`사안무력`,`退背`,`  근`,` 去`,`주인무력`,`북북서`,``,`북북동`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`전체반대`,`이행`,`선난후이`,`退背`,`  근`,`  去`,`상호무난`,`북북서`,``,`서남`],
    [`10.11`,``,`본인동조`,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,`제압불가`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,` 음귀`,`묘지탈`,0,0,`묘좌`,`동남`,`있음`],
    [``,``,``,0,0,`묘향`,`서북`,``],
    [`생업`,``,``,0,0,`전경`,`늪지윤천`,``],
    [``,``,``,0,0,`묘속`,`목근`,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,`고조모`,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,``,`없음`],
    [``,`제례귀`,`제례위범`,0,0,`묘향`,``,``],
    [`생업`,``,``,"목기부정","목기반입",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`전사귀`,`전사`,0,0,`육친`,``,``],
    [`질병`,`전흉사`,`혈광사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`특별있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`여귀`,`호주부인`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`가장귀`,`호주사망`,"목기부정","목기반입",`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`여귀`,`호주부인`,"여고음강","사망시모",`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`전사귀`,`전사`,0,0,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,`남남동`,`있음`],
    [``,`전사귀`,`전사`,0,0,`묘향`,`북북서`,``],
    [`생업`,``,``,0,0,`전경`,`등라수`,``],
    [``,``,``,0,0,`묘속`,`화렴`,``],
    [`가정`,`음귀`,`묘지탈`,0,0,`육친`,`조부`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`상위기쁨-하위 소도자격-이동사`,`외내출행이동원행사`,`놀램의혹불녕-실재근심사`,`직녹자산권리사`,`외교막힘`,`존비상몽사`,`내외사- 급속사`,`머리단장하는 한가함`,``,`관계성불통-가택이사-가족질병근심사`,`매사위역사**외 파재극처-실종사우려`,`재난전소`,`내부가정막힘`,`송쟁사흉-문서소식사길`,`매사허성불성**세속허사자**외男내女동시색정사有`,`음1,2,4,5월 방문자는 종교진출하면성공`,`매사연속성없음**왕래사송계류사`,`종교진출하면자립함`,`재액해소`,`상린동요분산사`,``,`奸人궤계사`,`매사위역사**외사급속송쟁사-상해 우려`,`전화위복사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 동요불안분산사`,``,`빈궁`,`전화위복`,`빈궁*8관재`,``,``,``,`관재`,``,`빈궁**관재`,``,`음4월 화재주의`,`빈궁`,``,``,`외사 동요분산사`,`빈궁`,`내사 애증사有`,`전화위복`,`재액해소`,`빈궁`,`침체경구사`,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외사급속송쟁사-상해우려`,`소인쟁재사`,`허리굽혀 절하는 반복이동사`,`외내출행이동원행사`,`부부남녀반목사`,`직녹자산권리사`,`외교막힘`,`고시불리-없는구설근심사`,`내외사- 급속사`,`실기 즉 퇴장이 되려 쌓는격`,``,`관계성불통-가택이사가족질병근심사`,`외 파재극처사-실종사우려`,`비곡치욕`,`내부가정막힘`,`병 중태-구설놀램`,`매사영속성없음**외男내女동시색정사有`,`종교진출하면자립함**도적악의-공격불의`,`미초희경사**매사영속성없음`,`종교진출하면자립함`,`내사 애증사有`,`좋은소식사`,``,`奸人궤계사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁`,`내사 동요불안분산사`,``,`전화위복`,``,`빈궁**관재`,`재액해소`,`빈궁**관재`,`재액해소`,``,``,``,`음4월 화재주의`,`관재`,``,`빈궁`,``,`외사 동요분산사`,`빈궁**병재`,`전화위복`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`奸人궤계사`,`외사급속송쟁사-상해우려`,`소인쟁재사`,`허리굽혀절하는 반복이동사`,`외내출행이동원행사`,`부부남녀반목사`,`직녹자산권리사`,`외교막힘`,`고시불리-없는 구설근심사구설송쟁사흉-문서소식사길`,`내외사- 급속사`,`실기즉 퇴장이 되려 쌍는격`,``,`관계성불통-가택이사-가족질병사`,`외 파재극처사-실종사우려`,`비곡치욕`,`내부가정막힘`,`병 중태-구설놀램`,`매사영속성없음**외男내女동시색정사有`,`종교진출하면자립함**도적악의-공격불의`,`미초희경사**매사영속성없음`,`종교진출하면자립함`,`내사 애증사有`,`좋은소식사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`관재`,``,`빈궁`,`내사 동요불안분산사`,``,`전화위복`,`빈궁**관재`,``,`재액해소`,`빈궁`,`재액해소`,``,``,`관재`,`음4월 화재주의`,`관재`,``,`빈궁`,`외사 동요분산사`,``,`빈궁**병재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`도적실세-포획함`,`내사 애증사有`,`事후쟁탈사`,``,`전화위복사`,`매사위역사**외 급속송쟁사-상해우려`,`奸人궤계이동사**내사 동요불안분산사`,`외내출행이동원행사`,`비상지애사`,`직녹자산권리사`,`외교막힘`,`밤에 당하는 능욕박해사`,`환성경사`,`내외사-급속사`,``,`탐영적헌책답신지체-구설해소사`,`재난전소`,`매사위역사**외 파재극처사-실종사 우려`,`내부가정막힘`,`발탁영전도약사`,`매사영속성없음**외男내女동시색정사有`,`탄식신음**종교진출하면자립함`,`매사영속성없음**觀書우유한가`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`빈궁**병재`,``,``,`빈궁**병재**관재`,``,`재액해소`,`빈궁**관재`,`재액해소`,`전화위복`,`빈궁**관재`,``,``,``,`관재`,``,`빈궁`,``,`음4월화재주의`,`빈궁`,``,``,`외사 동요분산사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`매사영속성없음**觀書우유한가`,`종교진출하면자립함`,`도적실세-포획함`,`빈궁**병재**내사 애증사有`,`事후쟁탈사`,``,`전화위복사`,`매사위역사**외 급속송쟁사-상해우려`,`奸人궤계이동사**내사 동요불안분산사`,`외내출행이동원행사`,`비상지애사`,`직녹자산권리사`,`외교막힘`,`밤에 당하는 능욕박해사`,`환성경사`,`내외사-급속사`,``,`탐영적헌책답신지체-구설해소사`,`재난전소`,`매사위역사**외 파재극차사-실종사우려`,`내부가정막힘`,`발탁영전도약사`,`매사영속성없음**외男내女동시색정사有`,`탄식신음**종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,`외사 동요분산사`,`전화위복`,`재액해소`,`관재`,`재액해소`,`빈궁**병재`,``,`빈궁**관재`,``,`관재`,`전화위복`,`빈궁**관재`,``,`관재`,``,`빈궁`,``,``,``,`음4월화재주의`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`매사영속성없음**외男내女동시색정사有`,`종교진출하면자립함**말 말고 근심이 좋음`,`불의투서헌책**실재사**매사영속성없음`,`종교진출하면자립함`,`혼인단장사`,`관재**내사 애증사有`,``,`刑責사`,`외 급속송쟁사-상해우려`,`소인쟁재사`,`奸人궤계이동사**내사 동요불안분산사`,`외내출행이동원행사`,`소식기다림-와도 안좋음`,`직녹자산권리사`,`외교막힘`,`승진영전사`,`도적을 찾아가도 못잡음`,`내외사-급속사`,`사행불량`,``,`외 파재극처사-실종사우려`,`비곡치욕사`,`내부가정막힘**음4월 화재주의`,`발탁영전도약사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`빈궁`,`외사 동요불안분산사`,`빈궁`,`전화위복`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`관재`,`동요불안분산사`,`빈궁**관재***재액해소`,`동요불안분산사`,`빈궁**병재**동요불안분산사`,`재액해소**전화위복`,`관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`관재`,`동요불안분산사`,``,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`내부가정막힘**비곡치욕`,`발탁영전도약사`,`매사영속성없음**외男내女동시색정사有`,`종교진출하면자립함**말 말고 근신이 좋음`,`불의투서헌책**실재사**매사영속성없음`,`종교진출하면자립함`,`혼인단장사`,`내사 애증사有`,``,`刑責사`,`외 급속송쟁사-상해우려`,`소인쟁재사`,`奸人궤계이동사**내사 동요불안분산사`,`외내출행이동원행사`,`소식기다림-와도 안좋음`,`직녹자산권리사`,`외교막힘`,`승진영전사`,`도적을 찾아가도 못잡음`,`내외사-급속사`,`사행불량`,``,`외 파재극처사-실종사우려`,`비곡치욕사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4월 화재주의`,``,`빈궁`,``,`빈궁`,`외사 동요분산사`,`전화위복`,`전도**관재`,`전도**빈궁`,``,`관재`,``,`재액해소`,`빈궁**관재`,`빈궁**병재재액해소`,`전화위복`,`관재`,``,`빈궁`,``,`빈궁`,``,`관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`비곡치욕사`,`외 파재극처사-실종사우려`,`내부가정막힘`,`발탁영전도약사`,`매사영속성없음**외男내女동시색정사有`,`종교진출하면자립함**말 말고 근신이 좋음`,`불의투서헌책**실재사**매사영속성없음`,`종교진출하면자립함`,`혼인단장사`,`내사 애증사有`,``,`刑責사`,`외 급속송쟁사-상해우려`,`소인쟁재사`,`奸人궤계이동사**내사 동요불안분산사`,`외내출행이동원행사`,`소식기다림-와도안좋음`,`직녹자산권리사`,`외교막힘`,`승진영전사`,`도적을 찾아가도 못잡음`,`내외사-급속사`,`사행불량`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`음4월화재주의`,`재액해소`,`빈궁`,``,`빈궁`,`외사 동요분산사`,``,`전화위복`,``,`빈궁`,``,``,`빈궁`,``,`빈궁**병재`,`전화위복`,``,``,`빈궁**관재`,``,`빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`사행불량`,``,`외 파재극처사-실종사우려`,`비곡치욕사`,`내부가정막힘`,`발탁영전도약사`,`매사영속성없음**外남내女동시색정사有`,`종교진출하면자립함**말 말고 근신이 좋음`,`불의투서헌책**실재사**매사영속성없음`,`종교진출하면자립함`,`혼인단장사`,`내사 애증사有`,``,`刑責사`,`외급속송쟁사-상해우려`,`소인쟁재사`,`奸人궤계이동사**내사 동요불안분산사`,`외내출행이동원행사`,`소식기다림-와도 안좋음`,`직녹자산권리사`,`외교막힘`,`승진영전사`,`도적을 찾아가도 못잡음`,`내외사-급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`관재`,``,`음4월화재주의`,`재액해소`,`빈궁`,``,`빈궁`,`외사 동요분산사`,``,`전화위복`,`빈궁`,``,`관재`,``,`빈궁`,``,`빈궁**병재`,`전화위복`,`관재`,``,`빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내외사 급속사`,`머리단장하는 한가로움`,``,`관계성불통-가택이사-가족질병근심사`,`매사이역사**외 파재극처사-실종사우려`,`재난전소`,`내부가정막힘`,`구설송쟁사흉-문서소식사길`,`매사영속성없음**외男내女동시색정사有`,`종교진출하면자립함`,`왕래사송계류사**매사영속성없음`,`종교진출하면자립함`,`내사 애증사有`,`身物수손사`,``,`奸人궤계사`,`매사위역사**외 급속송쟁사-상해사우려`,`전화위복사`,`상위기쁨-하위수도자격-이동사`,`외내출행이동원행사**내사 동요불안분산사`,`놀램의록불년-실재근심사`,`직녹자산권리사`,`외교막힘`,`존비상몽사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`재액해소`,`관재`,`빈궁**관재`,``,`음4월화재주의`,`빈궁`,``,``,`빈궁`,`외사 동요분산사`,``,`전화위복`,`빈궁`,``,`관재**병재**빈궁`,``,`빈궁**병재`,`전도`,`전도**관재**빈궁`,`전화위복`,`빈궁**관재빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외교막힘`,`존비상몽사`,`내외사 급속사`,`머리단장하는 한가로움`,``,`관계성불통-가택이사-가족질병근심사`,`매사위역사**외 파재극처사-실종사우려`,`재난전소`,`내부가정막힘`,`구설송쟁사흉-문서소식사길`,`매사영속성없음**외男내女동시색정사有`,`종교진출하면자립함`,`왕래사송계류사**매사영속성없음`,`종교진출하면자립함`,`내사 애증사有`,`身物수손사`,``,`奸人궤계사`,`매사위역사**외 급속송쟁사-상해사 우려`,`전화위복사`,`상위기쁨-하위수도자격-이동사`,`외내출행이동원행사**내사 동요불안분산사`,`놀램의혹불녕-실재근심사`,`직녹자산권리사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`재액해소`,`관재`,`재액해소`,``,``,`빈궁**관재`,`음4월화재주의`,`빈궁`,``,``,`빈궁`,`외사 봉요분산사`,``,`전화위복`,`빈궁`,``,`빈궁**병재**관재`,``,`전도**관재**빈궁`,`폐한인`,`전도**빈궁`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`놀램의혹불년ㅇ-실재근심사`,`직녹자산권리사`,`외교막힘`,`존비상몽사`,`내외사-급속사`,`머리단장하는 한가로움`,``,`관계성불통-가택이사-가족질병근심사`,`매사위역사**외 파재극처사-실종사우려`,`재난전소`,`내부가정막힘`,`구설송쟁사흉-문서소식사길`,`매사허성불성**세속허사자**외男내女동시색정사有`,`음1,2,4,5월 방문자는 종교진출하면성공함`,`왕래사송계류사**매사허성불성**세속허사자`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,`내사 애증사有`,`身物소손사`,``,`奸人궤계사`,`매사위역사**외 급속송쟁사-상해 우려`,`전화위복사`,`상위기쁨-하위수도자격-이동사`,`외내출행이동원행사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,`전화위복`,`빈궁**관재`,``,`재액해소`,``,`재액해소`,``,`빈궁**관재`,``,`음4월화재주의`,`빈궁`,``,``,`빈궁**외사 동요분산사`,`성공함`,``,`전화위복`,`빈궁`,``,`빈궁**병재**관재`,``,`전도`,`내사 동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 땅 때문에 재판이 붙었겠어!    첫째는 입조심, 
  둘째는침범귀신(3면1국)           해소, 
  셋째는 분수를 알면 凶을           줄일 수 있겠어!
`,`■ 부부가 등을 돌렸네?
   각기 마음이 따로야!
⊕전사귀ㆍ음사부정, 
⊖음귀ㆍ가택음사부정 탓이네!
  해소시키고 나면 이별이든    합치든 마음문제야!
`,`■가정에 음란사가 넘치고 있어! ⊕전사귀, 
⊖변사귀 침범때문이네! 
  그러니 모든게 거꾸로 섰지!    먼데서형제나 동료한테서    송금이 오겠는데일이 자    꾸 엇나가네 !    
`,`■ ⊕병 걱정, 
  ⊖돈 걱정이 크겠어! 
    땅도 원수겠고! 
    귀신(삼전전귀)과 부정이       들어 서로 속이고 있어!       해소시켜야 흉이 복이       되겠어!

⊖신변 목기부정이 침범했네 ?
`,`■ 여러 일을 하나로 묶게      생겼네? 
   일은 잘 되겠는데 자식     걱정이 생기게 생겼어!
⊕전사귀, 
⊖변사귀를 잡아야만 자식보호    가 되겠네!
`,`■ 가택이 손상되네 ?. 
   가택권리가 넘어가게 생겼어!     病 걱정이 있게 될거고…
⊕전흉사귀, 
⊖가택귀를 잡아야 凶중 吉이    되겠어!
⊖신변 상가부정 침범했네?
`,`■색정불화가 있겠네 !
돈 걱정을 해도 내돈은 안되고다 없어질돈이겠는데, 
먼데서 오는 돈도 그렇겠어! 
집도 옮겨야 하겠고…
⊕변사귀, 
⊖신불제사귀가 붙어 일이 
  뒤죽박죽이야!
`,`■  임신했네? 
   이권을 상실하게 생겼어!男:처첩․돈 문제가 凶하네!

⊕⊖여귀ㆍ가택목기부정 
    때문에 가정 우환이 길어!

⊕신변 음사부정 침범했네?
`,`■ 일을 하나로 묶어 복은      되겠는데 자손 걱정이      생기겠어!
⊕신불제사귀, 
⊖음귀가 발동했어! 
해소시키면 돈이 저절로 
들어와!
`,`■신규사로 복을 얻게 생겼네?

⊕여귀, 
⊖도로귀만 해소하면 만사
  형통이겠어!

⊕신변에 수망노부부정이 
  침범했네 ?
`,`■ 권리를 잃게 생겼네?
   심중 놀라고 있겠고!

⊕변사귀, 
⊖전사귀만 해소하면 다 
  사라질 근심걱정이야!
`,`■ 직위도 변경되고 가정도     짝이 없고… 
   마음도 크게 놀라고 있고…
⊕가택음사부정, 변사귀, 
⊖전사귀를 해소하면 진퇴
  양난사가 해결되겠어!`];
  const YOGYEOL1 = [`■ 다섯 관살이 공격을 하나 입을 다물면 흉을 면한다. 낮밤 귀신이 범을 타고오니 병이나 소송을 겹쳐 만난다.
  `,`■ 서로 해치고 삼키려든다. 밤정단은 녹을 도둑 맞는다. 진퇴가 양난이니 과체이름을 핍박이라고 한다.
  `,`■ 초전은 관귀요, 말전은 망신이다. 중전에 역마만 홀로 있다. 천리 밖에 나가 무역하면 자본금의 열배는 얻게 된다.
  `,`■ 내 앞길에 흉한 일을 말로 다하기 어렵다.
  집안에 사람이 있어 원수를 막고 나를 살린다.
  `,`■ 사람은 돈 있는 곳으로 가고 모든 일이 오래 걸린다. 낮정단은 좋게 변하여 근심이 바뀌어 기쁨이 되리라.
  `,`■ 서로 사귀어 밝고 아름답다. 재물이나 운수는 신통치 않다.
  밤정단은 불행중 다행이 있고 다행중 불행도 있다.
  `,`■ 역마가 많으니 움직이기만 하고 쉬지 못한다. 귀인에게 청탁은 안되지만 재물만은 풍부하리라.
  `,`■ 밤정단은 도둑을 맞고 재산이 여러모로 흩어진다. 도둑을 많이 만났으니 강도로 돌변할까 걱정이다.
  `,`■ 돈ㆍ여자가 스스로 나에게 왔다. 돌고 돌아 문고리처럼 연결되었다. 하늘이 돌봐서 잘 풀릴 것이다. 여러 입이 나를 돕는다.
  `,`■ 그늘이 끼어 어두워도 의지할 곳이 있으니 걱정이 없다. 
  별로 반갑지 않은 것은 윗사람의 쓸데 없는 간섭이다.
  `,`■ 샘은 마르고 뿌리는 잘렸다. 모든 일에 손해나고 궁핍하다.
  계속 손해나고 마르니 점점 어두운 곳으로 들어간다.
  `,`■ 처음에는 맞지 않으나 계속 노력하면 좋은 일이 있다. 손해나게 하는 사람이 있으니 실물손해와 도적을 막으라.`]
  const SIBO1 = [`■ 신변에 저주를 받는 일이
  있다. ⊕능욕박해사가 있고
  ⊖존비상몽사가 있어
  신음고로한다. 질병과 소송사가 거듭 발생한다. 
  정체되는 일이 있고 힘든 형편이다.
  凶을 凶으로 제어한다.
  위임시킬 사람도 없다.
  男:권리상실사 기한다
  女:남편의 凶이 기한다.
  `,`■ 폐지사를 부흥시키려 한다.
  가정에 ⊕⊖음사부정이 들어
  소극적 방침을 벗어나면 해롭다.
  부부가 서로 반목하고 배반한다. 사음사가 있다.
  중요한 것은 옛것을 지키고 망동하지 말라는 것이다.
  `,`■ ⊕능욕박해사, ⊖구설비난사
  가 있다. 
  가족중에 이를 구해주게 된다.
  원방에서 형제로부터 송금이 있게 된다.
  일이 단번에 이루어지지 않는다. 대체로 吉보다 凶이 많은 운세이다.
  `,`■ ⊕반화위복, ⊖소인쟁재사
  가 있다.
  병과 송사의 불씨로 권리를
  상실하는 일이 있게 된다.
  ⊖가정에 음사부정을 해소시키면 가족 중에 재난을 구하게 된다. 서로 속이려 한다. 피차 손모가 있게 된다.
  男:권리상실사 기한다
  女:남편의 凶이 기한다.
  `,`■ 금전ㆍ사업의 여러 건을
  하나로 묶어 개혁ㆍ개신
  시킨다.
  타인에게 위탁하여 도모하면 吉하다. 타인의 추천ㆍ천거가 있게 된다.
  단, 가정에 우환이 있고 가장을 속이며 가인이 원한을 품고 가해하려 한다.
  부부가 음탕하다.
  `,`■ 이익사가 있고, 소득한다.
  서로 화합하는 중에 가택의
  권리가 넘어간다.
  이로 인해 가정이 불안하다. 그러나 귀살을 해소하면 비관중에 기쁨이 오고 길창사가 있게 된다.
  `,`■ 가택 개동의 조가 있다.
  움직임이 빠르다.
  반복무상하여 간 자는
  오고 얻는 것은 잃고 이기려 하면 진다.
  낮 정단은 吉하고 밤 정단은 凶한것이 대체이다.
  人宅 역시 불녕하고 목적사는 지체된다.
  `,`■ 가정에 ⊕⊖가택목기부정이
  들어 어둡고 소인쟁재사가
  있다.
  이권을 상실한다.
  임신사가 있고 중절한다.
  결국 여인의 비곡차욕사가 있게 된다.
  귀신과 부정을 다스려야만 한다.
  `,`■ 현재 여건부족 상태에서
  간난한 형편에 있다.
  ⊕형사책임건, ⊖간인궤계건이 있어 개신ㆍ개혁하려 한다. 다행히 스스로 와서 금전의 도움을 주는 이가 있어 목적이 달성된다.
  ⊕子丑辰午酉띠, ⊖亥寅辰未戌띠는 틀림 없다.
  `,`■ 자충수적 우환이 기한다.
  신규사로 승부를 걸어
  복이 된다.
  옛것은 과감히 버려야 좋다. 단, 가정에
  
  ⊕혼인, ⊖충산우려사가 있어 번민하게 된다.
  천우신조가 있다.
  `,`■ ⊕불의투서, ⊖사송계류사가
  있어 권리를 상실하는
  일이 있다.
  女는 별리ㆍ고독녀이고, 남편을 망신시키는 일을 한다. 
  심중 놀램이 크나 호가호위일뿐으로 근신하면 해가 없다.
  上人과 의논하라!
  `,`■ 가정에 이권을 상실하는 일,
  권리를 잃는 일이 있다.
  ⊕음사부정이 들었다.
  남녀불문하고 별리ㆍ고독하다. 
  심중 놀래고 있으나 자연해소 되는 일에 불과하다.
  모든 일은 목적 달성이 안되는 운세이므로 무리하면 사기 맞는다.
  七月 임신사가 있다.`]
  const SIBO2_1 = [`■官･夫, 신변외사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  토지관송사가 있다.
  부동 신음의 상이다.
  `,`■자손ㆍ구설ㆍ이사건이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官･夫,능욕박해외사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  가택 가정에 우환이 있다.동료 형제의 돈이 온다
  `,`■官･夫, 반목외사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■금전ㆍ여자, 가택내사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  자손 재액예방이 필요하다.
  `,`■자손ㆍ박관, 후회사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■금전ㆍ여자, 가택내사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  `,`■금전ㆍ여인ㆍ임신사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀에 재액이 있다.
  `,`■사업ㆍ후원, 내외사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다.
  자손 재해 예방이 필요하다.
  `,`■사업ㆍ후원, 가택내사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  화합후 교란 된다.
  `,`■官･夫,신변동요외사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■官･夫,신변동요외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/030/row-1-column-1.png`,
    `/static/images/sagwa/030/row-1-column-2.png`,
    `/static/images/sagwa/030/row-1-column-3.png`,
    `/static/images/sagwa/030/row-1-column-4.png`,
    `/static/images/sagwa/030/row-1-column-5.png`,
    `/static/images/sagwa/030/row-1-column-6.png`,
    `/static/images/sagwa/030/row-1-column-7.png`,
    `/static/images/sagwa/030/row-1-column-8.png`,
    `/static/images/sagwa/030/row-1-column-9.png`,
    `/static/images/sagwa/030/row-1-column-10.png`,
    `/static/images/sagwa/030/row-1-column-11.png`,
    `/static/images/sagwa/030/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 천지반이 본궁에 엎드려 움직이지 않으며 음일이니 자신격(自信格)이다. 자기 스스로 유약하다고 믿기 때문에 이런 이름이 있다.
    토(土)는 일간의 귀(鬼)로 되고 일간상신 및 3전의 중귀(衆鬼)는 내몸을 공격한다. 중전에 백호가 승하여 일간을 극하니 질병과 소송은 가장 흉한데 거듭 발생한다. 자신격(自信格)은 엎드려 움직이지 않는데, 진월(辰月)․술월(戌月)에는 천마가 앉아서 이동하는 일이 있다. 년명상신에 천마가 승하면 움직인다. 관의 류신이 왕성하여 임관이나 구직은 모두 신속하다. 보통사람에게는 불리하지만 말전이 공망하니 깊은 우려는 없다. 진술축미(辰戌丑未)는 사계(四季)의 신으로써 이들이 3전을 구성하면 가색(稼檣)이라 이름하여 모든 일에서 정체되는 간난의 형상이다.
    백법에 이르기를 ｢백호가 간상에 승하여 흉이 빠르고, 폐구격(閉口格)은 사물이 두 갈래에 걸친다｣라고 하였다. 비전에는 ｢진술축미(辰戌丑未)의 3전은 스스로 서로 충형되어 흉으로써 흉을 제어하니 적을 잡을 수 있다｣고 하였다. 3전이 관귀(官鬼)이면 형제가 재앙을 초래한다. 즉 관살(官殺)은 비견(比肩)을 극한다. 이 과에서는 말전이 공망하여 재화(災禍)는 경하다.
    일귀는 역행하여 3전이 구․백․음이고, 야귀는 순행하여 3전이 음․백․구이다. 이 과는 복익부동(伏匿不動)하는 형상이나 년명상신에 역마 또는 정신이 승할 때는 고요한 가운데 움직일 뜻이 생긴다. 이 과는 길조는 아니지만 조용히 만사에 분수를 알고 행하면 재해를 면할 수 있다.
    사계역전(四季逆傳)으로 되면 재물이 흩어지고 사물이 도(道)대로 되지 않는다. 역전(逆傳)이란 축술미진(丑戌未辰)을 말하는데, 축(丑)은 겨울, 술(戌)은 가을, 미(未)는 여름, 진(辰)은 봄에 해당하여 즉 네 계절의 순서를 이루지 않고 역전으로 된다. 남에게 위임한다 해도 사람을 얻지 못한다.`
    ,
    // 2
    `  이 과는 4과 중에서 제4과의 묘진(卯辰)이 1상에서 1하를 극하여 원수퇴여격(元首退茹格)으로 된다. 720과중 제2국은 퇴여격이다.
    일간일지가 교차상극하고 일간상신은 자(子)가 녹신에 해당된다. 초․중전은 설기로 되어 말전 및 일지상신의 관귀효(官鬼爻)를 제(制)하므로 구신(救神)으로 된다. 퇴여격은 전진해도 이롭지 못하고 소극적인 방침이 좋다. 녹왕(祿旺)이 신(身)에 임할 경우에는 옛 것을 지키면 길하다. 일간은 일지에서 극되고 일간상신이 일지를 극하는 것은 해리격(解離格)이라 하여 부부가 반목하고 서로 배반한다. 원수과는 1상이 1하를 극하는데 위는 하늘이고 아래는 땅으로, 하늘이 땅을 극함은 이치(理致)가 순(順)하므로 만사에 좋다.
    백법에 이르기를 ｢부부무음(夫婦蕪淫)은 서로 사정을 품고, 왕록이 신(身)에 임하면 망동하지 말지어다｣라고 하였다. 비전에는 ｢일지에 사신(蛇神)이 승하니 지반의 사(巳)는 사명(蛇名)이다｣고 하였다. 그리고 진(辰)은 묘신이니 양사협묘(兩蛇夾墓)라 하여 병을 정단하면 격병(隔病)에 걸려 구할 수가 없다. 년명 또는 행년에 진(辰)이 있으면 사기(死期)는 가장 빠르다.
    3전은 일간기궁 축(丑)과 일지상신 진(辰) 사이의 3전으로 된다. 즉 묘인축(卯寅丑)과 같은 것은 일진협정격(日辰夾定格)이라 하여 무슨 일이나 뜻을 세우면 계획한 선을 벗어나지 않는다. 녹왕(祿旺)이 신(身)에 임하면 앉아서 복을 받게 되므로 망동(亡動)할 필요가 없다. 일귀는 역행하여 3전이 주(朱)․합(合)․구(勾)이고, 야귀는 순행하여 3전이 귀(貴)․후(后)․음(陰)이다. 해(亥)의 위에 술(戌)이 가해져서 괴도(魁度)가 천문을 건너니 사물이 조색되어 심로하게 된다.`
    ,
    // 3
    `  이 과는 제4과의 축묘(丑卯)가 하에서 상을 극하여 중심과로 되며 역간전격(逆間傳格)이다. 축해유(丑亥酉)는 극음격(極陰格)이다. 초전의 축(丑)은 귀살(鬼殺)이지만 일지상신 묘목(卯木)이 이것을 제어하므로 가인(家人)은 흉을 방지한다.
    일간상신에 역마 정재(丁財)가 승하여 먼 곳의 재물을 소득하며, 또 활동하면 구재할 수 있다. 극음간전격(極陰間傳格)은 물러서도 장애가 있어서 편할 수가 없다. 또 축해유(丑亥酉)는 밤중이므로 광명을 보지 못하고, 음기가 차고 넘치니 주색․음란․간도(奸盜) 등 사도(邪道)를 주관하여 사물이 바르지 못하므로 흉화(凶禍)를 초래한다. 백법에는 ｢수일(水日) 정재(丁財)는 재의 일로 움직이며, 입추(立秋) 전일에 정단하여 축(丑) 위에 해(亥)를 보면 천화격(天禍格)으로 된다｣고 하였다.
    일귀는 역행하여 3전이 구(勾)․공(空)․상(常)이고, 야귀는 3전이 주(朱)․구(勾)․공(空)이다. 간전격(間傳格)은 한 자리를 사이에 두고 3전을 이루어 순과 역으로 각각 12격(格)이 있는데 모든 사물에 간격(間隔)이 있어서 곧바로 달성될 수 없다. 그리고 순간전(順間傳)은 무슨 일에서나 순으로 되고 역간(逆間)에서는 사물이 역조(逆調)로 된다. 사오미신(巳午未申)은 위에 있어서 하늘로 하고 해자축인(亥子丑寅)은 아래에 있어서 땅으로 한다. 사(巳)에서 술(戌)에 이르는 것은 음으로 하고 해(亥)에서 진(辰)에 이르는 것은 양으로 한다. 그러므로 해(亥)를 천문이라 하고 사(巳)를 지호(地戶)라 한다. 무릇 3전은 음에서 양으로 이르는 것이 길함이 많고, 양에서 음으로 이르는 것은 흉함이 많다. 양의 순간전(順間傳) 6격, 음의 순간전 6격이 합쳐서 12격이다. 또 양의 역간전(逆間傳) 6격과 음의 역간전 6격이 있어서 합치면 12격이다. 이상으로 간전격은 24격이 있다.`
    ,
    // 4
    `  이 과는 4과 중에서 제1과의 술계(戌癸)가 1상에서 1하를 극하여 원수가색격(元首稼檣格)으로 된다. 일간상신 술(戌)은 관귀효(官鬼爻)로 되고 백호가 승하여 초전으로 되니 흉조이다.
    3전이 중귀(衆鬼)로 되면 질병의 정단․소송 사건에서는 흉함이 많은데, 다행히도 일지상신의 인목(寅木)이 7살(殺)을 제어하여 흉을 해소한다. 일지상신은 가족의 흉을 구하고 일간일지는 서로 상탈(相脫)하여 피차간에 모손된다. 중․말전의 공망으로 흉의 힘이 공허하게 되어 해를 이루지 않는다.
    백법에는 ｢백호가 간상에 임하여 귀(鬼)로 되니 흉은 빨리 온다｣고 하였다. 중․말전의 공망은 전으로 가지 않으므로 초시(初時)를 생각해야 한다. 중귀가 완전할지라도 제신(制神)이 있으니 두려울 것은 없다. 비전에는 ｢백호에 사신사기(死神死氣)를 띨 때는 백화(魄化)라 부른다. 혹은 가문 날에 비가 오지 않아 싹이 말라 사망하는 일이 있다｣고 하였다. 간상신(干上神) 술(戌)이 초전으로 되는 것은 참관격(斬關格)이다. 교차탈(交車脫)하는 경우에는 피차간에 교섭사에서 서로 상대방을 속이려는 뜻이 있다. 일귀는 역행하여 3전이 백․음․사이고, 야귀는 3전이 청․상․후이다.
  `
    ,
    // 5
    `  이 과는 4과 중에서 제2과의 사유(巳酉)가 초전으로 되어 원수종혁격(元首從革格)으로 된다. 일간은 내몸이고 초전은 사(巳)의 재동(財動)으로 되며, 중전은 관성(官星), 말전은 인성(印星)이니 3전에 삼귀(三貴)가 완전하여 흉함이 없고 길하게 된다. 비록 흉함을 본다 해도 후에는 길하게 변화된다. 삼전지지(三傳地支)는 4과를 떠나지 않고 회환격(廻環格)으로 되며 사유축(巳酉丑)의 종혁격은 단련하여 변화되므로 옛 것을 고쳐 새로운 것에 붙어야 한다.
    백법에 이르기를 ｢3전의 체생(遞生)은 남의 천거가 있고, 3합 금(金)국은 복잡하여 한 사건이 아니라 두 곳의 사건으로 남에게 위탁하여 모사를 고쳐하면 반드시 성취된다｣고 하였다. 무릇 3합하는 것은 자기 한 사람의 일이 아니다. 길장을 만나면 공명하는 자가 있다. 해산하는 것은 이롭지 못하다. 일귀는 역행하여 3전이 귀․구․상이고, 야귀는 3전이 음․주․공이다. 
  `
    ,
    // 6
    `  이 과는 제2과․제3과․제4과에 상하의 극이 있고 하에서 상을 적(賊)하며, 제2과 묘신(卯申)을 사용하여 초전으로 한다. 묘(卯)가 신금(申金)에 앉아 극되어 초전으로 되는 것은 단륜격(斷輪格)이라 부른다.
    일간일지는 자축(子丑)․신사(申巳)와 교차지합(交車地合)하여 상하가 화합되니 자연히 화합하게 된다. 말전의 사(巳)는 일간의 재효(財爻)이지만 일지상신 자수(子水)에서 극되고 중전 술(戌)에 백호가 승하여 묘신(墓神)에 앉으므로 근심되는 사건이 있다. 청룡이 승하면 비관하는 가운데에 기쁨이 있다. 단륜격은 관(官)을 정단하면 대단히 유익하고, 일지상신 녹신에서 일지 사(巳)를 극하면 택내에 불안한 일이 있다.
    백법에 이르기를 ｢건록이 지상에 임하면 권섭(權攝)이 바르지 못하다｣고 하였다. 비전에는 ｢묘(卯)가 신(申)에 가해지고 귀신이 승하는 것은 내란의 징조이고, 자(子)가 사(巳)에 가해져서 극양으로 되고 무계(戊癸)가 간합(干合)하면 대길창(大吉昌)이다｣고 하였다. 그러나 질병의 점단에는 죽음의 액이 있다. 일귀는 역행하여 3전이 주․백․귀이고, 야귀는 3전이 귀․청․음이다. 용신(龍神)에 관성이 승하여 3전 중에 드는 것은 가장 길조이다.`
    ,
    // 7
    `  이 과는 12궁천지반이 동서남북으로 서로 대충(對冲)하므로 반음과(返吟課)이다. 하에서 극하는 사해(巳亥)를 초전으로 하고 사해사(巳亥巳)는 원태격(元胎格)이다.
    해(亥)는 일지의 역마정신(驛馬丁神)으로 되고 3전에 정마(丁馬)가 중첩되어 움직임이 신속하다. 사(巳)의 재성귀신(財星貴神)은 해(亥)의 수극(水剋)에 앉는다 할지라도 역마 지반이므로 재를 위하여 동분서주하면 크게 득재(得財)한다. 간지가 다 상극되는 것은 의지할 곳이 없는 격으로서 손상을 방지해야 한다. 귀신이 해(亥)에 임하면 귀가 천문에 오른다고 하여 활동하면 이롭고, 공사(公事) 또는 입시 등은 매우 길하다. 주작이 귀살에 승하면 소송 사건에서는 흉하고 불리하다. 반음과는 인정부정(人情不定)으로서 사물을 중하게 구하고 경하게 얻는 형상이다. 반음은 반복하여 왕래하므로 떠난 자는 돌아오고 얻은 것은 잃으며, 이기려고 하면 지게 된다.
    이 과에서는 3전에 길장이 앉으면 구신으로 되어 길로 변화한다. 12충(冲)이 극이 심하므로 두 길에서 서로 배반하여 목적은 지체되고 화합도 멀다. 또 점사(占事)는 성취되기 어렵고 망하기는 쉽다. 길신을 만나므로 반은 성취된다. 일간일지가 모두 위에서 극되니 인택이 모두 안녕하지 못하다.
    일귀는 순행하여 3전이 귀․공․귀이고, 야귀는 역행하여 3전이 음․구․음이다. 백법에는 ｢두 귀신이 극을 받으니 귀인을 신뢰하기 어려우며, 간지가 모두 상(傷)하는 것은 모두 모손을 방지해야 한다｣고 했다. 비전에 이르기를 ｢태상(太常)이 일간에 가해지고 묘월(卯月)에 정단하면 상복(喪服)의 사건이 있으며, 수일(水日) 반음과는 움직여서 자금을 획득한다｣라고 하였다.`
    ,
    // 8
    `  이 과는 4과 중 세 곳에 상극이 있으며 제1과 오계(午癸)의 극을 사용하여 초전으로 하는 중심과이다. 초전의 천반 공망은 과숙(寡宿)이라고 한다. 초전은 일간의 재로 되고 현무가 승하여 협극(夾剋)으로 되니 가끔 도난을 당한다. 
    중전은 정마에 해당되지만 공망에 승하여 멀리로 가려 해도 목적을 이루지 못한다. 일지상신에 관귀(官鬼)․청룡이 승하니 사관․구직에 길하다.
    백법에는 ｢수일(水日) 정재(丁財)를 만나 재의 움직임이 있다. 중전 천공이 공망에 승하면 공론이고 빈소리이니 추구해도 효과가 없다｣고 했다. 일귀는 순행하여 3전이 사(蛇)․공(空)․후(后)이고, 야귀는 역행하여 3전이 현(玄)․구(勾)․후(后)이다.
  
  
  
  `
    ,
    // 9
    `  이 과는 3하(三下)가 3상(三上)을 극하여 중심(重審)이나 지일(知一)을 모두 취할 수 없으므로 섭해과(涉害課)로 되며, 사맹(四孟)의 극인 유사(酉巳)를 사용하여 초전으로 하는 불비격(不備格)이다. 일지는 일간상으로 가서 췌서격(贅壻格)으로 된다.
    사(巳)는 일간의 재로 되고, 일지는 간상에 와서 재가 내몸에 붙는다. 또 과전이 모두 3합 금(金)국으로 되면 불비격이나 경하다. 3전이 체생하여 일간을 생하니 뭇사람들에게서 추천을 받는 기쁨이 있다. 종혁(從革)은 옛 것을 고쳐 새롭게 된다. 3전에 재관인(財官印)이 완전하므로 구직․사관에서는 모두 좋은 것을 얻는다. 3합하는 가운데 관귀효(官鬼爻)가 있으면 합 중에 살(殺)을 범한다고 하여 웃음 속에 칼을 품는다.
    백법에 이르기를 ｢합중살(合中殺)을 범함은 꿀 속에 비소를 감춘 것이고, 3전이 체생하여 사람의 추천을 받으며, 사(巳)는 순미(旬尾)로 되어 재신의 폐구격(閉口格)은 병의 점단에서 가장 흉하다｣고 하였다. 비전에는 ｢유축사(酉丑巳)는 헌인(獻刃)이라 이름하여 멀고 가까움을 막론하고 모두 그의 상해를 입는다｣고 했다. 3전(三傳)이 동과(四課)를 떠나지 않는 것은 회환격(廻環格)으로서 구태를 반복하는 일이 많다. 췌서격(贅壻格)은 천간을 남편으로 하고 지(支)를 아내로 한다. 지가 간상에 임하면 처가 남편을 따르는 것이며, 또 이와 반대로 일간기궁(日干寄宮)이 일지상신으로 되면 남편이 처에게 복종하는 것이다. 즉 서양자(壻養子)의 형상이다. 이상은 모두 자기를 버리고 사람을 따름으로써 이와 같이 이르며 남의 위세나 권력에 복종한다. 년명(年命)에 길장이 승할 때는 당권(當權)이라 불러 길격으로 된다. 일귀는 순행하여 3전이 구(勾)․상(常)․귀(貴)이고, 야귀는 3전이 공(空)․음(陰)․주(朱)이다.`
    ,
    // 10
    `  이 과는 제1과 제3과에 상하의 극이 있다. 하에서 상을 극하는 신사(申巳)를 사용하여 초전으로 하니 중심과(重審課)이다. 초전의 신(申)은 순수(旬首)이니 육의격(六儀格)이며, 신해인(申亥寅) 사맹신(四孟神)의 3전은 원태격(元胎格)을 구성한다.
    일간상신이 묘신(墓神)에서 극되지만 다행히 말전 인목(寅木)에서 제극(制剋)하므로 흉해는 구제된다. 원태격(元胎格)에서는 옛 것은 좋지 않고 새로운 것이 좋다.
    백법에는 ｢3전에 후(后)․합(合)이 승하니 점혼(占婚)하면 먼저 정을 통하고 후에 결혼한다｣고 했다. 비전에는 ｢결혼을 정단하면 반드시 결혼이 성립된다. 3전이 체생으로 되고 순의(旬儀)가 앉는 것은 공명이 달성되며, 수일(水日) 정재가 움직여서 재를 구할 수 있다｣고 하였다. 
    일귀는 순행하여 3전이 합(合)․공(空)․현(玄)이고, 야귀는 3전이 청(靑)․상(常)․후(后)이다. 청신(靑神)이 신(身)을 생하니 구직하고자 하면 좋은 직업을 얻는다. 육의격(六儀格)은 순주(旬主)가 초전으로 되어 흉을 해소하고 기쁨으로 화하며 모든 것의 구신(救神)으로 된다. 만일 지의순기(支儀旬奇), 간기(干奇)가 승한다면 더욱 길조(吉兆)이다.`
    ,
    // 11
    `  이 과는 상하의 극이 없고 제2과․제3과에서 요극이 있으며 천반에서 극하는 신이다. 제3과 미사(未巳)를 취하여 초전으로 하는 요극호시과(遙剋蒿矢課)로 된다. 4과3전이 전부 지반에 생탈(生脫)되는 것은 원소근단격(源消根斷格)이라 하여 무슨 일에서나 탈설소모(脫泄消耗)하고 모두 참된 뜻이 없다.
    미(未)에서 유(酉)로 저녁 시각으로 되고 말전은 해(亥)이니 밤중에 가깝다. 이와 같은 것은 입명격(入冥格)이라 하여 음기에 기울어 사물이 통하기 어렵다. 그러나 요극과는 길흉화복이 모두 경미하다. 초․중전이 공망하니 길흉이 모두 힘이 없으며, 질병을 정단할 때는 양생(養生)하지 않아서 생명이 위험하게 된다. 이 과는 승적(僧籍)에 있는 사람에게는 길하다.
    백법에 이르기를 ｢4과중에 일귀․야귀를 모두 띠고 있으므로 귀인의 원조 협력을 구하면 길하다｣고 하였다. 비전에는 ｢입명격(入冥格)은 심로가 많고 나날이 쇠퇴해가며, 진술(辰戌)의 위에 자(子)가 가해지고 백호가 승하면 그 해 중에 재화나 질병이 빈발한다｣고 했다. 오음(五陰)이 서로 이어지는 것은 만사가 혼미(昏迷)하다. 그러나 음모나 사계(邪計)에서는 이익이 있고, 광명정대한 일에는 불리하다. 일귀는 순행하여 3전이 주․구․공이고, 야귀는 3전이 구․공․상이다.`
    ,
    // 12
    `  이 과는 제3과․제4과에 비스듬히 극이 있는데 천장에서의 극이다. 미오(未午)를 사용하여 초전으로 하니 요극호시격(遙剋蒿矢格)이다. 3전 미신유(未申酉)는 진여격(進茹格)으로 공망하여 전진하지 않는다.
    그러나 초전의 귀살(貴殺)은 공망하여 흉을 보아도 흉을 만나지 않는다. 중․말전 신유(申酉)의 생기(生氣)는 길상(吉祥)을 이룬다. 백법에 이르기를 ｢나망격(羅網格)은 모소(謀所)에 졸책(拙策)이 많다｣라고 하였다. 일지상신이 처재(妻財)로 되고 공망하니 처첩(妻妾)을 얻지 못한다. 비전에는 ｢술(戌)이 유(酉)에 가해지니 가정을 정단하면 만년에 총첩(寵妾)에게 재산을 빼앗긴다｣고 하였다. 말전의 유(酉)는 첩으로 파쇄살(破碎殺)로 되기 때문이다.
    요극과의 초전을 정하는 원칙은 먼저 천신(天神)에서 일간을 극하는 것을 취하고, 천신에서 일간을 과(課)하지 않을 때는 일간에서 천신을 극하는 것을 초전으로 하여 탄사격(彈射格)으로 된다. 비스듬히 극하기 때문에 먼 곳의 사건으로 인해 공연히 놀라는 일이 있고, 사물이 성취되어도 허명허리(虛名虛利)가 많다. 3전이 공망하는 것은 실시(失矢)라 하여 일이 성사되지 않는다. 3전 중에 태음․현무․천공 중의 어느 것인가가 보이면 사기사건으로 화(禍)를 초래한다. 일귀는 순행하여 3전이 주(朱)․합(合)․구(勾)이고, 야귀는 3전이 구(勾)․청(靑)․공(空)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
