const VERSION101_1_1300 = [
    "/static/images/1300/1300__Part1.png",
"/static/images/1300/1300__Part2.png",
"/static/images/1300/1300__Part3.png",
"/static/images/1300/1300__Part4.png",
"/static/images/1300/1300__Part5.png",
"/static/images/1300/1300__Part6.png",
"/static/images/1300/1300__Part7.png",
"/static/images/1300/1300__Part8.png",
"/static/images/1300/1300__Part9.png",
"/static/images/1300/1300__Part10.png",
"/static/images/1300/1300__Part11.png",
"/static/images/1300/1300__Part12.png",
"/static/images/1300/1300__Part13.png",
"/static/images/1300/1300__Part14.png",
"/static/images/1300/1300__Part15.png",
"/static/images/1300/1300__Part16.png",
"/static/images/1300/1300__Part17.png",
"/static/images/1300/1300__Part18.png",
"/static/images/1300/1300__Part19.png",
"/static/images/1300/1300__Part20.png",
"/static/images/1300/1300__Part21.png",
"/static/images/1300/1300__Part22.png",
"/static/images/1300/1300__Part23.png",
"/static/images/1300/1300__Part24.png",
"/static/images/1300/1300__Part25.png",
"/static/images/1300/1300__Part26.png",
"/static/images/1300/1300__Part27.png",
"/static/images/1300/1300__Part28.png",
"/static/images/1300/1300__Part29.png",
"/static/images/1300/1300__Part30.png",
"/static/images/1300/1300__Part31.png",
"/static/images/1300/1300__Part32.png",
"/static/images/1300/1300__Part33.png",
"/static/images/1300/1300__Part34.png",
"/static/images/1300/1300__Part35.png",
"/static/images/1300/1300__Part36.png",
"/static/images/1300/1300__Part37.png",
"/static/images/1300/1300__Part38.png",
"/static/images/1300/1300__Part39.png",
"/static/images/1300/1300__Part40.png",
"/static/images/1300/1300__Part41.png",
"/static/images/1300/1300__Part42.png",
"/static/images/1300/1300__Part43.png",
"/static/images/1300/1300__Part44.png",
"/static/images/1300/1300__Part45.png",
"/static/images/1300/1300__Part46.png",
"/static/images/1300/1300__Part47.png",
"/static/images/1300/1300__Part48.png",
"/static/images/1300/1300__Part49.png",
"/static/images/1300/1300__Part50.png",
"/static/images/1300/1300__Part51.png",
"/static/images/1300/1300__Part52.png",
"/static/images/1300/1300__Part53.png",
"/static/images/1300/1300__Part54.png",
"/static/images/1300/1300__Part55.png",
"/static/images/1300/1300__Part56.png",
"/static/images/1300/1300__Part57.png",
"/static/images/1300/1300__Part58.png",
"/static/images/1300/1300__Part59.png",
"/static/images/1300/1300__Part60.png",]

const VERSION101_1_1400 = [
    "/static/images/1400/001_01__Part1.png",
"/static/images/1400/001_01__Part2.png",
"/static/images/1400/001_01__Part3.png",
"/static/images/1400/001_01__Part4.png",
"/static/images/1400/001_01__Part5.png",
"/static/images/1400/001_01__Part6.png",
"/static/images/1400/001_01__Part7.png",
"/static/images/1400/001_01__Part8.png",
"/static/images/1400/001_01__Part9.png",
"/static/images/1400/001_01__Part10.png",
"/static/images/1400/001_01__Part11.png",
"/static/images/1400/001_01__Part12.png",
"/static/images/1400/001_02__Part1.png",
"/static/images/1400/001_02__Part2.png",
"/static/images/1400/001_02__Part3.png",
"/static/images/1400/001_02__Part4.png",
"/static/images/1400/001_02__Part5.png",
"/static/images/1400/001_02__Part6.png",
"/static/images/1400/001_02__Part7.png",
"/static/images/1400/001_02__Part8.png",
"/static/images/1400/001_02__Part9.png",
"/static/images/1400/001_02__Part10.png",
"/static/images/1400/001_02__Part11.png",
"/static/images/1400/001_02__Part12.png",
"/static/images/1400/001_03__Part1.png",
"/static/images/1400/001_03__Part2.png",
"/static/images/1400/001_03__Part3.png",
"/static/images/1400/001_03__Part4.png",
"/static/images/1400/001_03__Part5.png",
"/static/images/1400/001_03__Part6.png",
"/static/images/1400/001_03__Part7.png",
"/static/images/1400/001_03__Part8.png",
"/static/images/1400/001_03__Part9.png",
"/static/images/1400/001_03__Part10.png",
"/static/images/1400/001_03__Part11.png",
"/static/images/1400/001_03__Part12.png",
"/static/images/1400/001_04__Part1.png",
"/static/images/1400/001_04__Part2.png",
"/static/images/1400/001_04__Part3.png",
"/static/images/1400/001_04__Part4.png",
"/static/images/1400/001_04__Part5.png",
"/static/images/1400/001_04__Part6.png",
"/static/images/1400/001_04__Part7.png",
"/static/images/1400/001_04__Part8.png",
"/static/images/1400/001_04__Part9.png",
"/static/images/1400/001_04__Part10.png",
"/static/images/1400/001_04__Part11.png",
"/static/images/1400/001_04__Part12.png",
"/static/images/1400/001_05__Part1.png",
"/static/images/1400/001_05__Part2.png",
"/static/images/1400/001_05__Part3.png",
"/static/images/1400/001_05__Part4.png",
"/static/images/1400/001_05__Part5.png",
"/static/images/1400/001_05__Part6.png",
"/static/images/1400/001_05__Part7.png",
"/static/images/1400/001_05__Part8.png",
"/static/images/1400/001_05__Part9.png",
"/static/images/1400/001_05__Part10.png",
"/static/images/1400/001_05__Part11.png",
"/static/images/1400/001_05__Part12.png",
"/static/images/1400/001_06__Part1.png",
"/static/images/1400/001_06__Part2.png",
"/static/images/1400/001_06__Part3.png",
"/static/images/1400/001_06__Part4.png",
"/static/images/1400/001_06__Part5.png",
"/static/images/1400/001_06__Part6.png",
"/static/images/1400/001_06__Part7.png",
"/static/images/1400/001_06__Part8.png",
"/static/images/1400/001_06__Part9.png",
"/static/images/1400/001_06__Part10.png",
"/static/images/1400/001_06__Part11.png",
"/static/images/1400/001_06__Part12.png",
"/static/images/1400/001_07__Part1.png",
"/static/images/1400/001_07__Part2.png",
"/static/images/1400/001_07__Part3.png",
"/static/images/1400/001_07__Part4.png",
"/static/images/1400/001_07__Part5.png",
"/static/images/1400/001_07__Part6.png",
"/static/images/1400/001_07__Part7.png",
"/static/images/1400/001_07__Part8.png",
"/static/images/1400/001_07__Part9.png",
"/static/images/1400/001_07__Part10.png",
"/static/images/1400/001_07__Part11.png",
"/static/images/1400/001_07__Part12.png",
"/static/images/1400/001_08__Part1.png",
"/static/images/1400/001_08__Part2.png",
"/static/images/1400/001_08__Part3.png",
"/static/images/1400/001_08__Part4.png",
"/static/images/1400/001_08__Part5.png",
"/static/images/1400/001_08__Part6.png",
"/static/images/1400/001_08__Part7.png",
"/static/images/1400/001_08__Part8.png",
"/static/images/1400/001_08__Part9.png",
"/static/images/1400/001_08__Part10.png",
"/static/images/1400/001_08__Part11.png",
"/static/images/1400/001_08__Part12.png",
"/static/images/1400/001_09__Part1.png",
"/static/images/1400/001_09__Part2.png",
"/static/images/1400/001_09__Part3.png",
"/static/images/1400/001_09__Part4.png",
"/static/images/1400/001_09__Part5.png",
"/static/images/1400/001_09__Part6.png",
"/static/images/1400/001_09__Part7.png",
"/static/images/1400/001_09__Part8.png",
"/static/images/1400/001_09__Part9.png",
"/static/images/1400/001_09__Part10.png",
"/static/images/1400/001_09__Part11.png",
"/static/images/1400/001_09__Part12.png",
"/static/images/1400/001_10__Part1.png",
"/static/images/1400/001_10__Part2.png",
"/static/images/1400/001_10__Part3.png",
"/static/images/1400/001_10__Part4.png",
"/static/images/1400/001_10__Part5.png",
"/static/images/1400/001_10__Part6.png",
"/static/images/1400/001_10__Part7.png",
"/static/images/1400/001_10__Part8.png",
"/static/images/1400/001_10__Part9.png",
"/static/images/1400/001_10__Part10.png",
"/static/images/1400/001_10__Part11.png",
"/static/images/1400/001_10__Part12.png",
"/static/images/1400/001_11__Part1.png",
"/static/images/1400/001_11__Part2.png",
"/static/images/1400/001_11__Part3.png",
"/static/images/1400/001_11__Part4.png",
"/static/images/1400/001_11__Part5.png",
"/static/images/1400/001_11__Part6.png",
"/static/images/1400/001_11__Part7.png",
"/static/images/1400/001_11__Part8.png",
"/static/images/1400/001_11__Part9.png",
"/static/images/1400/001_11__Part10.png",
"/static/images/1400/001_11__Part11.png",
"/static/images/1400/001_11__Part12.png",
"/static/images/1400/001_12__Part1.png",
"/static/images/1400/001_12__Part2.png",
"/static/images/1400/001_12__Part3.png",
"/static/images/1400/001_12__Part4.png",
"/static/images/1400/001_12__Part5.png",
"/static/images/1400/001_12__Part6.png",
"/static/images/1400/001_12__Part7.png",
"/static/images/1400/001_12__Part8.png",
"/static/images/1400/001_12__Part9.png",
"/static/images/1400/001_12__Part10.png",
"/static/images/1400/001_12__Part11.png",
"/static/images/1400/001_12__Part12.png",
"/static/images/1400/001_13__Part1.png",
"/static/images/1400/001_13__Part2.png",
"/static/images/1400/001_13__Part3.png",
"/static/images/1400/001_13__Part4.png",
"/static/images/1400/001_13__Part5.png",
"/static/images/1400/001_13__Part6.png",
"/static/images/1400/001_13__Part7.png",
"/static/images/1400/001_13__Part8.png",
"/static/images/1400/001_13__Part9.png",
"/static/images/1400/001_13__Part10.png",
"/static/images/1400/001_13__Part11.png",
"/static/images/1400/001_13__Part12.png",
"/static/images/1400/001_14__Part1.png",
"/static/images/1400/001_14__Part2.png",
"/static/images/1400/001_14__Part3.png",
"/static/images/1400/001_14__Part4.png",
"/static/images/1400/001_14__Part5.png",
"/static/images/1400/001_14__Part6.png",
"/static/images/1400/001_14__Part7.png",
"/static/images/1400/001_14__Part8.png",
"/static/images/1400/001_14__Part9.png",
"/static/images/1400/001_14__Part10.png",
"/static/images/1400/001_14__Part11.png",
"/static/images/1400/001_14__Part12.png",
"/static/images/1400/001_15__Part1.png",
"/static/images/1400/001_15__Part2.png",
"/static/images/1400/001_15__Part3.png",
"/static/images/1400/001_15__Part4.png",
"/static/images/1400/001_15__Part5.png",
"/static/images/1400/001_15__Part6.png",
"/static/images/1400/001_15__Part7.png",
"/static/images/1400/001_15__Part8.png",
"/static/images/1400/001_15__Part9.png",
"/static/images/1400/001_15__Part10.png",
"/static/images/1400/001_15__Part11.png",
"/static/images/1400/001_15__Part12.png",
"/static/images/1400/001_16__Part1.png",
"/static/images/1400/001_16__Part2.png",
"/static/images/1400/001_16__Part3.png",
"/static/images/1400/001_16__Part4.png",
"/static/images/1400/001_16__Part5.png",
"/static/images/1400/001_16__Part6.png",
"/static/images/1400/001_16__Part7.png",
"/static/images/1400/001_16__Part8.png",
"/static/images/1400/001_16__Part9.png",
"/static/images/1400/001_16__Part10.png",
"/static/images/1400/001_16__Part11.png",
"/static/images/1400/001_16__Part12.png",
"/static/images/1400/001_17__Part1.png",
"/static/images/1400/001_17__Part2.png",
"/static/images/1400/001_17__Part3.png",
"/static/images/1400/001_17__Part4.png",
"/static/images/1400/001_17__Part5.png",
"/static/images/1400/001_17__Part6.png",
"/static/images/1400/001_17__Part7.png",
"/static/images/1400/001_17__Part8.png",
"/static/images/1400/001_17__Part9.png",
"/static/images/1400/001_17__Part10.png",
"/static/images/1400/001_17__Part11.png",
"/static/images/1400/001_17__Part12.png",
"/static/images/1400/001_18__Part1.png",
"/static/images/1400/001_18__Part2.png",
"/static/images/1400/001_18__Part3.png",
"/static/images/1400/001_18__Part4.png",
"/static/images/1400/001_18__Part5.png",
"/static/images/1400/001_18__Part6.png",
"/static/images/1400/001_18__Part7.png",
"/static/images/1400/001_18__Part8.png",
"/static/images/1400/001_18__Part9.png",
"/static/images/1400/001_18__Part10.png",
"/static/images/1400/001_18__Part11.png",
"/static/images/1400/001_18__Part12.png",
"/static/images/1400/001_19__Part1.png",
"/static/images/1400/001_19__Part2.png",
"/static/images/1400/001_19__Part3.png",
"/static/images/1400/001_19__Part4.png",
"/static/images/1400/001_19__Part5.png",
"/static/images/1400/001_19__Part6.png",
"/static/images/1400/001_19__Part7.png",
"/static/images/1400/001_19__Part8.png",
"/static/images/1400/001_19__Part9.png",
"/static/images/1400/001_19__Part10.png",
"/static/images/1400/001_19__Part11.png",
"/static/images/1400/001_19__Part12.png",
"/static/images/1400/001_20__Part1.png",
"/static/images/1400/001_20__Part2.png",
"/static/images/1400/001_20__Part3.png",
"/static/images/1400/001_20__Part4.png",
"/static/images/1400/001_20__Part5.png",
"/static/images/1400/001_20__Part6.png",
"/static/images/1400/001_20__Part7.png",
"/static/images/1400/001_20__Part8.png",
"/static/images/1400/001_20__Part9.png",
"/static/images/1400/001_20__Part10.png",
"/static/images/1400/001_20__Part11.png",
"/static/images/1400/001_20__Part12.png",
"/static/images/1400/001_21__Part1.png",
"/static/images/1400/001_21__Part2.png",
"/static/images/1400/001_21__Part3.png",
"/static/images/1400/001_21__Part4.png",
"/static/images/1400/001_21__Part5.png",
"/static/images/1400/001_21__Part6.png",
"/static/images/1400/001_21__Part7.png",
"/static/images/1400/001_21__Part8.png",
"/static/images/1400/001_21__Part9.png",
"/static/images/1400/001_21__Part10.png",
"/static/images/1400/001_21__Part11.png",
"/static/images/1400/001_21__Part12.png",
"/static/images/1400/001_22__Part1.png",
"/static/images/1400/001_22__Part2.png",
"/static/images/1400/001_22__Part3.png",
"/static/images/1400/001_22__Part4.png",
"/static/images/1400/001_22__Part5.png",
"/static/images/1400/001_22__Part6.png",
"/static/images/1400/001_22__Part7.png",
"/static/images/1400/001_22__Part8.png",
"/static/images/1400/001_22__Part9.png",
"/static/images/1400/001_22__Part10.png",
"/static/images/1400/001_22__Part11.png",
"/static/images/1400/001_22__Part12.png",
"/static/images/1400/001_23__Part1.png",
"/static/images/1400/001_23__Part2.png",
"/static/images/1400/001_23__Part3.png",
"/static/images/1400/001_23__Part4.png",
"/static/images/1400/001_23__Part5.png",
"/static/images/1400/001_23__Part6.png",
"/static/images/1400/001_23__Part7.png",
"/static/images/1400/001_23__Part8.png",
"/static/images/1400/001_23__Part9.png",
"/static/images/1400/001_23__Part10.png",
"/static/images/1400/001_23__Part11.png",
"/static/images/1400/001_23__Part12.png",
"/static/images/1400/001_24__Part1.png",
"/static/images/1400/001_24__Part2.png",
"/static/images/1400/001_24__Part3.png",
"/static/images/1400/001_24__Part4.png",
"/static/images/1400/001_24__Part5.png",
"/static/images/1400/001_24__Part6.png",
"/static/images/1400/001_24__Part7.png",
"/static/images/1400/001_24__Part8.png",
"/static/images/1400/001_24__Part9.png",
"/static/images/1400/001_24__Part10.png",
"/static/images/1400/001_24__Part11.png",
"/static/images/1400/001_24__Part12.png",
"/static/images/1400/001_25__Part1.png",
"/static/images/1400/001_25__Part2.png",
"/static/images/1400/001_25__Part3.png",
"/static/images/1400/001_25__Part4.png",
"/static/images/1400/001_25__Part5.png",
"/static/images/1400/001_25__Part6.png",
"/static/images/1400/001_25__Part7.png",
"/static/images/1400/001_25__Part8.png",
"/static/images/1400/001_25__Part9.png",
"/static/images/1400/001_25__Part10.png",
"/static/images/1400/001_25__Part11.png",
"/static/images/1400/001_25__Part12.png",
"/static/images/1400/001_26__Part1.png",
"/static/images/1400/001_26__Part2.png",
"/static/images/1400/001_26__Part3.png",
"/static/images/1400/001_26__Part4.png",
"/static/images/1400/001_26__Part5.png",
"/static/images/1400/001_26__Part6.png",
"/static/images/1400/001_26__Part7.png",
"/static/images/1400/001_26__Part8.png",
"/static/images/1400/001_26__Part9.png",
"/static/images/1400/001_26__Part10.png",
"/static/images/1400/001_26__Part11.png",
"/static/images/1400/001_26__Part12.png",
"/static/images/1400/001_27__Part1.png",
"/static/images/1400/001_27__Part2.png",
"/static/images/1400/001_27__Part3.png",
"/static/images/1400/001_27__Part4.png",
"/static/images/1400/001_27__Part5.png",
"/static/images/1400/001_27__Part6.png",
"/static/images/1400/001_27__Part7.png",
"/static/images/1400/001_27__Part8.png",
"/static/images/1400/001_27__Part9.png",
"/static/images/1400/001_27__Part10.png",
"/static/images/1400/001_27__Part11.png",
"/static/images/1400/001_27__Part12.png",
"/static/images/1400/001_28__Part1.png",
"/static/images/1400/001_28__Part2.png",
"/static/images/1400/001_28__Part3.png",
"/static/images/1400/001_28__Part4.png",
"/static/images/1400/001_28__Part5.png",
"/static/images/1400/001_28__Part6.png",
"/static/images/1400/001_28__Part7.png",
"/static/images/1400/001_28__Part8.png",
"/static/images/1400/001_28__Part9.png",
"/static/images/1400/001_28__Part10.png",
"/static/images/1400/001_28__Part11.png",
"/static/images/1400/001_28__Part12.png",
"/static/images/1400/001_29__Part1.png",
"/static/images/1400/001_29__Part2.png",
"/static/images/1400/001_29__Part3.png",
"/static/images/1400/001_29__Part4.png",
"/static/images/1400/001_29__Part5.png",
"/static/images/1400/001_29__Part6.png",
"/static/images/1400/001_29__Part7.png",
"/static/images/1400/001_29__Part8.png",
"/static/images/1400/001_29__Part9.png",
"/static/images/1400/001_29__Part10.png",
"/static/images/1400/001_29__Part11.png",
"/static/images/1400/001_29__Part12.png",
"/static/images/1400/001_30__Part1.png",
"/static/images/1400/001_30__Part2.png",
"/static/images/1400/001_30__Part3.png",
"/static/images/1400/001_30__Part4.png",
"/static/images/1400/001_30__Part5.png",
"/static/images/1400/001_30__Part6.png",
"/static/images/1400/001_30__Part7.png",
"/static/images/1400/001_30__Part8.png",
"/static/images/1400/001_30__Part9.png",
"/static/images/1400/001_30__Part10.png",
"/static/images/1400/001_30__Part11.png",
"/static/images/1400/001_30__Part12.png",
"/static/images/1400/001_31__Part1.png",
"/static/images/1400/001_31__Part2.png",
"/static/images/1400/001_31__Part3.png",
"/static/images/1400/001_31__Part4.png",
"/static/images/1400/001_31__Part5.png",
"/static/images/1400/001_31__Part6.png",
"/static/images/1400/001_31__Part7.png",
"/static/images/1400/001_31__Part8.png",
"/static/images/1400/001_31__Part9.png",
"/static/images/1400/001_31__Part10.png",
"/static/images/1400/001_31__Part11.png",
"/static/images/1400/001_31__Part12.png",
"/static/images/1400/001_32__Part1.png",
"/static/images/1400/001_32__Part2.png",
"/static/images/1400/001_32__Part3.png",
"/static/images/1400/001_32__Part4.png",
"/static/images/1400/001_32__Part5.png",
"/static/images/1400/001_32__Part6.png",
"/static/images/1400/001_32__Part7.png",
"/static/images/1400/001_32__Part8.png",
"/static/images/1400/001_32__Part9.png",
"/static/images/1400/001_32__Part10.png",
"/static/images/1400/001_32__Part11.png",
"/static/images/1400/001_32__Part12.png",
"/static/images/1400/001_33__Part1.png",
"/static/images/1400/001_33__Part2.png",
"/static/images/1400/001_33__Part3.png",
"/static/images/1400/001_33__Part4.png",
"/static/images/1400/001_33__Part5.png",
"/static/images/1400/001_33__Part6.png",
"/static/images/1400/001_33__Part7.png",
"/static/images/1400/001_33__Part8.png",
"/static/images/1400/001_33__Part9.png",
"/static/images/1400/001_33__Part10.png",
"/static/images/1400/001_33__Part11.png",
"/static/images/1400/001_33__Part12.png",
"/static/images/1400/001_34__Part1.png",
"/static/images/1400/001_34__Part2.png",
"/static/images/1400/001_34__Part3.png",
"/static/images/1400/001_34__Part4.png",
"/static/images/1400/001_34__Part5.png",
"/static/images/1400/001_34__Part6.png",
"/static/images/1400/001_34__Part7.png",
"/static/images/1400/001_34__Part8.png",
"/static/images/1400/001_34__Part9.png",
"/static/images/1400/001_34__Part10.png",
"/static/images/1400/001_34__Part11.png",
"/static/images/1400/001_34__Part12.png",
"/static/images/1400/001_35__Part1.png",
"/static/images/1400/001_35__Part2.png",
"/static/images/1400/001_35__Part3.png",
"/static/images/1400/001_35__Part4.png",
"/static/images/1400/001_35__Part5.png",
"/static/images/1400/001_35__Part6.png",
"/static/images/1400/001_35__Part7.png",
"/static/images/1400/001_35__Part8.png",
"/static/images/1400/001_35__Part9.png",
"/static/images/1400/001_35__Part10.png",
"/static/images/1400/001_35__Part11.png",
"/static/images/1400/001_35__Part12.png",
"/static/images/1400/001_36__Part1.png",
"/static/images/1400/001_36__Part2.png",
"/static/images/1400/001_36__Part3.png",
"/static/images/1400/001_36__Part4.png",
"/static/images/1400/001_36__Part5.png",
"/static/images/1400/001_36__Part6.png",
"/static/images/1400/001_36__Part7.png",
"/static/images/1400/001_36__Part8.png",
"/static/images/1400/001_36__Part9.png",
"/static/images/1400/001_36__Part10.png",
"/static/images/1400/001_36__Part11.png",
"/static/images/1400/001_36__Part12.png",
"/static/images/1400/001_37__Part1.png",
"/static/images/1400/001_37__Part2.png",
"/static/images/1400/001_37__Part3.png",
"/static/images/1400/001_37__Part4.png",
"/static/images/1400/001_37__Part5.png",
"/static/images/1400/001_37__Part6.png",
"/static/images/1400/001_37__Part7.png",
"/static/images/1400/001_37__Part8.png",
"/static/images/1400/001_37__Part9.png",
"/static/images/1400/001_37__Part10.png",
"/static/images/1400/001_37__Part11.png",
"/static/images/1400/001_37__Part12.png",
"/static/images/1400/001_38__Part1.png",
"/static/images/1400/001_38__Part2.png",
"/static/images/1400/001_38__Part3.png",
"/static/images/1400/001_38__Part4.png",
"/static/images/1400/001_38__Part5.png",
"/static/images/1400/001_38__Part6.png",
"/static/images/1400/001_38__Part7.png",
"/static/images/1400/001_38__Part8.png",
"/static/images/1400/001_38__Part9.png",
"/static/images/1400/001_38__Part10.png",
"/static/images/1400/001_38__Part11.png",
"/static/images/1400/001_38__Part12.png",
"/static/images/1400/001_39__Part1.png",
"/static/images/1400/001_39__Part2.png",
"/static/images/1400/001_39__Part3.png",
"/static/images/1400/001_39__Part4.png",
"/static/images/1400/001_39__Part5.png",
"/static/images/1400/001_39__Part6.png",
"/static/images/1400/001_39__Part7.png",
"/static/images/1400/001_39__Part8.png",
"/static/images/1400/001_39__Part9.png",
"/static/images/1400/001_39__Part10.png",
"/static/images/1400/001_39__Part11.png",
"/static/images/1400/001_39__Part12.png",
"/static/images/1400/001_40__Part1.png",
"/static/images/1400/001_40__Part2.png",
"/static/images/1400/001_40__Part3.png",
"/static/images/1400/001_40__Part4.png",
"/static/images/1400/001_40__Part5.png",
"/static/images/1400/001_40__Part6.png",
"/static/images/1400/001_40__Part7.png",
"/static/images/1400/001_40__Part8.png",
"/static/images/1400/001_40__Part9.png",
"/static/images/1400/001_40__Part10.png",
"/static/images/1400/001_40__Part11.png",
"/static/images/1400/001_40__Part12.png",
"/static/images/1400/001_41__Part1.png",
"/static/images/1400/001_41__Part2.png",
"/static/images/1400/001_41__Part3.png",
"/static/images/1400/001_41__Part4.png",
"/static/images/1400/001_41__Part5.png",
"/static/images/1400/001_41__Part6.png",
"/static/images/1400/001_41__Part7.png",
"/static/images/1400/001_41__Part8.png",
"/static/images/1400/001_41__Part9.png",
"/static/images/1400/001_41__Part10.png",
"/static/images/1400/001_41__Part11.png",
"/static/images/1400/001_41__Part12.png",
"/static/images/1400/001_42__Part1.png",
"/static/images/1400/001_42__Part2.png",
"/static/images/1400/001_42__Part3.png",
"/static/images/1400/001_42__Part4.png",
"/static/images/1400/001_42__Part5.png",
"/static/images/1400/001_42__Part6.png",
"/static/images/1400/001_42__Part7.png",
"/static/images/1400/001_42__Part8.png",
"/static/images/1400/001_42__Part9.png",
"/static/images/1400/001_42__Part10.png",
"/static/images/1400/001_42__Part11.png",
"/static/images/1400/001_42__Part12.png",
"/static/images/1400/001_43__Part1.png",
"/static/images/1400/001_43__Part2.png",
"/static/images/1400/001_43__Part3.png",
"/static/images/1400/001_43__Part4.png",
"/static/images/1400/001_43__Part5.png",
"/static/images/1400/001_43__Part6.png",
"/static/images/1400/001_43__Part7.png",
"/static/images/1400/001_43__Part8.png",
"/static/images/1400/001_43__Part9.png",
"/static/images/1400/001_43__Part10.png",
"/static/images/1400/001_43__Part11.png",
"/static/images/1400/001_43__Part12.png",
"/static/images/1400/001_44__Part1.png",
"/static/images/1400/001_44__Part2.png",
"/static/images/1400/001_44__Part3.png",
"/static/images/1400/001_44__Part4.png",
"/static/images/1400/001_44__Part5.png",
"/static/images/1400/001_44__Part6.png",
"/static/images/1400/001_44__Part7.png",
"/static/images/1400/001_44__Part8.png",
"/static/images/1400/001_44__Part9.png",
"/static/images/1400/001_44__Part10.png",
"/static/images/1400/001_44__Part11.png",
"/static/images/1400/001_44__Part12.png",
"/static/images/1400/001_45__Part1.png",
"/static/images/1400/001_45__Part2.png",
"/static/images/1400/001_45__Part3.png",
"/static/images/1400/001_45__Part4.png",
"/static/images/1400/001_45__Part5.png",
"/static/images/1400/001_45__Part6.png",
"/static/images/1400/001_45__Part7.png",
"/static/images/1400/001_45__Part8.png",
"/static/images/1400/001_45__Part9.png",
"/static/images/1400/001_45__Part10.png",
"/static/images/1400/001_45__Part11.png",
"/static/images/1400/001_45__Part12.png",
"/static/images/1400/001_46__Part1.png",
"/static/images/1400/001_46__Part2.png",
"/static/images/1400/001_46__Part3.png",
"/static/images/1400/001_46__Part4.png",
"/static/images/1400/001_46__Part5.png",
"/static/images/1400/001_46__Part6.png",
"/static/images/1400/001_46__Part7.png",
"/static/images/1400/001_46__Part8.png",
"/static/images/1400/001_46__Part9.png",
"/static/images/1400/001_46__Part10.png",
"/static/images/1400/001_46__Part11.png",
"/static/images/1400/001_46__Part12.png",
"/static/images/1400/001_47__Part1.png",
"/static/images/1400/001_47__Part2.png",
"/static/images/1400/001_47__Part3.png",
"/static/images/1400/001_47__Part4.png",
"/static/images/1400/001_47__Part5.png",
"/static/images/1400/001_47__Part6.png",
"/static/images/1400/001_47__Part7.png",
"/static/images/1400/001_47__Part8.png",
"/static/images/1400/001_47__Part9.png",
"/static/images/1400/001_47__Part10.png",
"/static/images/1400/001_47__Part11.png",
"/static/images/1400/001_47__Part12.png",
"/static/images/1400/001_48__Part1.png",
"/static/images/1400/001_48__Part2.png",
"/static/images/1400/001_48__Part3.png",
"/static/images/1400/001_48__Part4.png",
"/static/images/1400/001_48__Part5.png",
"/static/images/1400/001_48__Part6.png",
"/static/images/1400/001_48__Part7.png",
"/static/images/1400/001_48__Part8.png",
"/static/images/1400/001_48__Part9.png",
"/static/images/1400/001_48__Part10.png",
"/static/images/1400/001_48__Part11.png",
"/static/images/1400/001_48__Part12.png",
"/static/images/1400/001_49__Part1.png",
"/static/images/1400/001_49__Part2.png",
"/static/images/1400/001_49__Part3.png",
"/static/images/1400/001_49__Part4.png",
"/static/images/1400/001_49__Part5.png",
"/static/images/1400/001_49__Part6.png",
"/static/images/1400/001_49__Part7.png",
"/static/images/1400/001_49__Part8.png",
"/static/images/1400/001_49__Part9.png",
"/static/images/1400/001_49__Part10.png",
"/static/images/1400/001_49__Part11.png",
"/static/images/1400/001_49__Part12.png",
"/static/images/1400/001_50__Part1.png",
"/static/images/1400/001_50__Part2.png",
"/static/images/1400/001_50__Part3.png",
"/static/images/1400/001_50__Part4.png",
"/static/images/1400/001_50__Part5.png",
"/static/images/1400/001_50__Part6.png",
"/static/images/1400/001_50__Part7.png",
"/static/images/1400/001_50__Part8.png",
"/static/images/1400/001_50__Part9.png",
"/static/images/1400/001_50__Part10.png",
"/static/images/1400/001_50__Part11.png",
"/static/images/1400/001_50__Part12.png",
"/static/images/1400/001_51__Part1.png",
"/static/images/1400/001_51__Part2.png",
"/static/images/1400/001_51__Part3.png",
"/static/images/1400/001_51__Part4.png",
"/static/images/1400/001_51__Part5.png",
"/static/images/1400/001_51__Part6.png",
"/static/images/1400/001_51__Part7.png",
"/static/images/1400/001_51__Part8.png",
"/static/images/1400/001_51__Part9.png",
"/static/images/1400/001_51__Part10.png",
"/static/images/1400/001_51__Part11.png",
"/static/images/1400/001_51__Part12.png",
"/static/images/1400/001_52__Part1.png",
"/static/images/1400/001_52__Part2.png",
"/static/images/1400/001_52__Part3.png",
"/static/images/1400/001_52__Part4.png",
"/static/images/1400/001_52__Part5.png",
"/static/images/1400/001_52__Part6.png",
"/static/images/1400/001_52__Part7.png",
"/static/images/1400/001_52__Part8.png",
"/static/images/1400/001_52__Part9.png",
"/static/images/1400/001_52__Part10.png",
"/static/images/1400/001_52__Part11.png",
"/static/images/1400/001_52__Part12.png",
"/static/images/1400/001_53__Part1.png",
"/static/images/1400/001_53__Part2.png",
"/static/images/1400/001_53__Part3.png",
"/static/images/1400/001_53__Part4.png",
"/static/images/1400/001_53__Part5.png",
"/static/images/1400/001_53__Part6.png",
"/static/images/1400/001_53__Part7.png",
"/static/images/1400/001_53__Part8.png",
"/static/images/1400/001_53__Part9.png",
"/static/images/1400/001_53__Part10.png",
"/static/images/1400/001_53__Part11.png",
"/static/images/1400/001_53__Part12.png",
"/static/images/1400/001_54__Part1.png",
"/static/images/1400/001_54__Part2.png",
"/static/images/1400/001_54__Part3.png",
"/static/images/1400/001_54__Part4.png",
"/static/images/1400/001_54__Part5.png",
"/static/images/1400/001_54__Part6.png",
"/static/images/1400/001_54__Part7.png",
"/static/images/1400/001_54__Part8.png",
"/static/images/1400/001_54__Part9.png",
"/static/images/1400/001_54__Part10.png",
"/static/images/1400/001_54__Part11.png",
"/static/images/1400/001_54__Part12.png",
"/static/images/1400/001_55__Part1.png",
"/static/images/1400/001_55__Part2.png",
"/static/images/1400/001_55__Part3.png",
"/static/images/1400/001_55__Part4.png",
"/static/images/1400/001_55__Part5.png",
"/static/images/1400/001_55__Part6.png",
"/static/images/1400/001_55__Part7.png",
"/static/images/1400/001_55__Part8.png",
"/static/images/1400/001_55__Part9.png",
"/static/images/1400/001_55__Part10.png",
"/static/images/1400/001_55__Part11.png",
"/static/images/1400/001_55__Part12.png",
"/static/images/1400/001_56__Part1.png",
"/static/images/1400/001_56__Part2.png",
"/static/images/1400/001_56__Part3.png",
"/static/images/1400/001_56__Part4.png",
"/static/images/1400/001_56__Part5.png",
"/static/images/1400/001_56__Part6.png",
"/static/images/1400/001_56__Part7.png",
"/static/images/1400/001_56__Part8.png",
"/static/images/1400/001_56__Part9.png",
"/static/images/1400/001_56__Part10.png",
"/static/images/1400/001_56__Part11.png",
"/static/images/1400/001_56__Part12.png",
"/static/images/1400/001_57__Part1.png",
"/static/images/1400/001_57__Part2.png",
"/static/images/1400/001_57__Part3.png",
"/static/images/1400/001_57__Part4.png",
"/static/images/1400/001_57__Part5.png",
"/static/images/1400/001_57__Part6.png",
"/static/images/1400/001_57__Part7.png",
"/static/images/1400/001_57__Part8.png",
"/static/images/1400/001_57__Part9.png",
"/static/images/1400/001_57__Part10.png",
"/static/images/1400/001_57__Part11.png",
"/static/images/1400/001_57__Part12.png",
"/static/images/1400/001_58__Part1.png",
"/static/images/1400/001_58__Part2.png",
"/static/images/1400/001_58__Part3.png",
"/static/images/1400/001_58__Part4.png",
"/static/images/1400/001_58__Part5.png",
"/static/images/1400/001_58__Part6.png",
"/static/images/1400/001_58__Part7.png",
"/static/images/1400/001_58__Part8.png",
"/static/images/1400/001_58__Part9.png",
"/static/images/1400/001_58__Part10.png",
"/static/images/1400/001_58__Part11.png",
"/static/images/1400/001_58__Part12.png",
"/static/images/1400/001_59__Part1.png",
"/static/images/1400/001_59__Part2.png",
"/static/images/1400/001_59__Part3.png",
"/static/images/1400/001_59__Part4.png",
"/static/images/1400/001_59__Part5.png",
"/static/images/1400/001_59__Part6.png",
"/static/images/1400/001_59__Part7.png",
"/static/images/1400/001_59__Part8.png",
"/static/images/1400/001_59__Part9.png",
"/static/images/1400/001_59__Part10.png",
"/static/images/1400/001_59__Part11.png",
"/static/images/1400/001_59__Part12.png",
"/static/images/1400/001_60__Part1.png",
"/static/images/1400/001_60__Part2.png",
"/static/images/1400/001_60__Part3.png",
"/static/images/1400/001_60__Part4.png",
"/static/images/1400/001_60__Part5.png",
"/static/images/1400/001_60__Part6.png",
"/static/images/1400/001_60__Part7.png",
"/static/images/1400/001_60__Part8.png",
"/static/images/1400/001_60__Part9.png",
"/static/images/1400/001_60__Part10.png",
"/static/images/1400/001_60__Part11.png",
"/static/images/1400/001_60__Part12.png",
]

const VERSION101_1_1500 = [
    "/static/images/1500/001.png",
    "/static/images/1500/002.png",
    "/static/images/1500/003.png",
    "/static/images/1500/004.png",
    "/static/images/1500/005.png",
    "/static/images/1500/006.png",
    "/static/images/1500/007.png",
    "/static/images/1500/008.png",
    "/static/images/1500/009.png",
    "/static/images/1500/010.png",
    "/static/images/1500/011.png",
    "/static/images/1500/012.png",
    "/static/images/1500/001.png",
    "/static/images/1500/002.png",
    "/static/images/1500/003.png",
    "/static/images/1500/004.png",
    "/static/images/1500/005.png",
    "/static/images/1500/006.png",
    "/static/images/1500/007.png",
    "/static/images/1500/008.png",
    "/static/images/1500/009.png",
    "/static/images/1500/010.png",
    "/static/images/1500/011.png",
    "/static/images/1500/012.png",
    "/static/images/1500/001.png",
    "/static/images/1500/002.png",
    "/static/images/1500/003.png",
    "/static/images/1500/004.png",
    "/static/images/1500/005.png",
    "/static/images/1500/006.png",
    "/static/images/1500/007.png",
    "/static/images/1500/008.png",
    "/static/images/1500/009.png",
    "/static/images/1500/010.png",
    "/static/images/1500/011.png",
    "/static/images/1500/012.png",
    "/static/images/1500/001.png",
    "/static/images/1500/002.png",
    "/static/images/1500/003.png",
    "/static/images/1500/004.png",
    "/static/images/1500/005.png",
    "/static/images/1500/006.png",
    "/static/images/1500/007.png",
    "/static/images/1500/008.png",
    "/static/images/1500/009.png",
    "/static/images/1500/010.png",
    "/static/images/1500/011.png",
    "/static/images/1500/012.png",
    "/static/images/1500/001.png",
    "/static/images/1500/002.png",
    "/static/images/1500/003.png",
    "/static/images/1500/004.png",
    "/static/images/1500/005.png",
    "/static/images/1500/006.png",
    "/static/images/1500/007.png",
    "/static/images/1500/008.png",
    "/static/images/1500/009.png",
    "/static/images/1500/010.png",
    "/static/images/1500/011.png",
    "/static/images/1500/012.png",

]

const VERSION101_2_1500 = [
    `(청룡)<br/>
子, 寅, 辰, 午, 申, 戌, 日<br/><br/>
 子  입해(入海 : 비상지애) 운이다.<br/>
 <br/>
     처첩의 임신사, 배, 자동차에 의한 금전 수익사, 투자의 기회를 맞아 기쁨이 있게 된다.<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 寅 宮 生我:타인의도움이있게된다. <br/>
內事 子 柱 支比:내부막힘이있게된다.<br/>
〃 寅 柱 生我:타인의도움이있게된다<br/>
〃 辰 柱 三合 : 유동불체한 내부 단합사가 있게된다.<br/>
〃 午 柱 支冲 : 음양대립.남녀상쟁.모사변천.동부서주.행동결합사가 있다<br/>
〃 申 柱 三合 : 유동불체의 단합사가 있게된다.<br/>
〃 戌 柱 我剋:상대를압박하는일이있게된다.<br/>
<br/>
丑  번니(嬏泥 : 모사미수) 운이다. <br/>
     가정 임신사, 목적사 고심, 계획사가 불완결하게 된다.<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
外事 寅 宮 我剋:상대를압박하는일이있게된다.<br/>
內事 子 柱 支合 : 순리징조, 규방부부융합의 화합사가 있게 된다.<br/>
〃 寅 柱 我剋:상대를압박하는일이있게된다.<br/>
〃 辰 柱 支破:이산,폐퇴사가있게된다.<br/>
〃 午 柱 支害 : 공송불리, 부부불화, 주사불명, 성취불성의 지장이 있다.<br/>
〃 申 柱 生我:신불귀인의도움을얻게된다.<br/>
〃 戌 柱 支刑 : 하극상배, 거지무원, 재부족사가 있게 된다.<br/>
<br/>
寅  승운(乘雲 : 이어경영)운이다. <br/>
     사업․경영․도모사를 모두 전진시켜 이익을 얻게 된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
<br/>
外事 內事 〃 〃 〃 〃 〃<br/>
寅 宮 日比:외교사가막히고위탁받은일이지체된다. 子 柱 生我:타인의도움을얻게된다.<br/>
寅 柱 支比:내부가막히고금전의손해가있게된다. 辰 柱 我剋:타인을압박하게된다.<br/>
午 柱 三合 : 문화 문명의 화합, 단합사가 있으나 외화내허이다. <br/>
申 柱 支冲 : 가정불안, 원수지사, 부부이심, 이별사가 일어난다. <br/>
戌 柱 我剋:타인을압박하는일이있게된다.<br/>
<br/>
 卯  구뇌(驅雷 : 이어경영) 운이다. <br/>
<br/>
     운영․활약사 모두 전진하여 길하게 된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
<br/>
外事 寅宮日劫:동료와관계되는손모사가있게된다.<br/>
內事 子 柱 支刑 : 존상불리, 화중저해, 상하불화, 무례지사의 손상이 있다<br/>
〃 寅柱支劫:형제가관련되어내부손모사가있게된다.<br/>
〃 辰 柱 支害 : 물사공허, 만사상쟁, 모사저해, 완결필패의 지장 쟁투사가 있다<br/>
〃 午柱支破:가정의이산,이동,파란사가있게된다.<br/>
〃 申柱我剋:타인을압박하는일이있게된다.<br/>
〃 戌柱支合:<br/>
<br/>
 辰  비천(飛天 : 중중희애) 운이다. <br/>
<br/>
     목전사의 진퇴, 금전사의 예측이 불리하게 된다.<br/>
     진퇴여부사가 일어난다. <br/>
<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
<br/>
〃 寅柱我剋:타인을압박하는일이있게된다.<br/>
〃 辰柱支比:내부에막히는일이있고금전손해가있게된다.<br/>
〃 午柱芽生:타인에게은혜를베푸는일이있게된다.<br/>
〃 申柱生我:타인으로부터도움을받는일이있게된다.<br/>
〃 戌 柱 支冲 : 희비불명, 하극상배, 부하도망등의 원수지사가 있다.<br/>
<br/>
 巳  암목(埯目 : 불측지우) 운이다. <br/>
<br/>
     운영사의 걱정이 따르고 이에 활동이 분주하게 되며 <br/>
     고위알현 등 에는 이롭게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
內事 子柱我剋:타인을압박을하는일이있다.<br/>
〃 寅 柱 支刑 : 도주관사, 거지곤란, 물사손해, 쟁투재앙의 손상이 있다.<br/>
〃 辰柱生我:타인으로부터도움을얻게된다.<br/>
〃 午柱支劫:형제와관련하여손모사가있다.<br/>
〃 申柱 支合 :화합,쟁송,파재사가있게된다.<br/>
刑破<br/>
〃 戌柱生我:타인의도움을얻게된다.<br/>
<br/>
<br/>
 午  소신(燒身 : 신물수손) 운이다. <br/>
<br/>
     신사숙녀의 문화사가 있게 되어 손재․관사가 발생하고        <br/>
     임신사로 동요하게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
外事 寅宮三合:문화광휘의단합사가있으나외화내허한다.<br/>
內事 子 柱 支冲 : 음양대립, 남녀상쟁, 모사변천, 동분서주, 행동결함 동요<br/>
원수사가 있다<br/>
〃 寅柱三合:문화광휘의단합사가있으나외화내허한다.<br/>
〃 辰柱生我:타인의도움을얻게된다.<br/>
〃 午柱支比:내부가막히고금전손해가있게된다.<br/>
〃 申柱 剋我 :타인으로부터압박을받게된다.<br/>
戌 柱 삼합 : 문화광휘의 단합사가 있으나 외화내허 한다.<br/>
<br/>
 未  재륙(在陸 : 모망난수) 운이다. <br/>
<br/>
     의․ 식․ 주의 일과 병자의 건을 만나고 안정해야 이롭게 <br/>
     된다.<br/>
        <br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
外事 寅宮我剋:타인을압박하는일이있게된다.<br/>
內事 子 柱 支害 : 시종무적, 관사구설, 경영조체, 암중재기, 지장쟁투가 있다<br/>
〃 寅柱我剋:타인을압박하는일이있게된다.<br/>
〃 辰柱支劫:형제.자매와관련하여손모사가있다. 〃 午柱支合:순리공동사가있게된다.<br/>
〃 申柱生我:타인의도움을얻게된다.<br/>
〃 戌柱刑破:형상사가일어나고여자가상한다.<br/>
<br/>
<br/>
 申  상린(傷鱗 : 충산우려) 운이다. <br/>
<br/>
     용의 비늘이 상하는 운으로 투송․감금․좌절사가 우려되고<br/>
     망동하면 흉해가 크게 된다.<br/>
外事 寅宮日冲:사악작숭,부부이심,이별사가있게된다. 內事 子柱三合:유동불체의단합사가있게된다.<br/>
〃 寅 柱 支冲 : 도로교통, 부부이심의 동요불안, 원수사가 있게 된다.<br/>
〃 辰柱我生:타인에게도움을주는일이있게된다.<br/>
〃 午柱我剋:타인을압박하는일이있게된다.<br/>
〃 申柱支比:내부가막히고금전손해가있게된다.<br/>
〃 戌柱我生:타인을돕는일이있게된다.<br/>
<br/>
<br/>
 酉  최각(摧角 : 불의진취) 운이다. <br/>
<br/>
     만사 소극적인 방침이라야 한다. 전진하면 흉해의 원인이 <br/>
     야기 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
外事 寅宮剋我:타인으로부터압박받는일이있게된다.<br/>
內事 子柱支破:女.兒의재해가있거나파재극처사가있게된다.<br/>
〃 寅柱剋我:타인으로부터압박받는일이있게된다.<br/>
〃 辰柱支合:순리징조공동사가있게된다.<br/>
〃 午柱我剋:타인을압박하는일이있게된다.<br/>
〃 申柱支劫:형제.자매가관련하여손모사가있게된다.<br/>
〃 戌 柱 支害 : 가정손상, 재병발기, 수하농락,등의 손해 실주사가 있다.<br/>
<br/>
 戌  등괴(登魁 : 소인쟁재) 운이다. <br/>
<br/>
     금전의 상쟁사가 일어나고 일은 힘들고 소득은 적으며 흉하다.<br/>
<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
外事 寅宮我剋:타인을압박을하게된다.<br/>
內事 子 柱 剋我 : 타인으로부터 압박을 받게 된다.<br/>
〃 寅柱我剋:타인을압박을하게된다.<br/>
〃 辰 柱 支冲 : 희비불명, 하극상배, 부하도망, 동요불안, 원수사가 있다.<br/>
〃 午 柱 三合 : 문화광휘의 단합사가 있으나 외화내허 이다.<br/>
〃 申 柱生我 : 타인으로부터 도움을 받게 된다.<br/>
〃 戌 柱 支比 : 형제자매가 관련되어 손모사가 있다.<br/>
<br/>
 亥  유강(游江 :비상지경) 운이다. <br/>
<br/>
     적극적으로 나서면 이익이 크고 결혼사가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별 그 사상을 보탠다.<br/>
外事 寅 宮合破 : 순리징조, 공동사가 있고 패후재성하게 된다. 內事 子 柱支劫 : 형제자매가 관련되어 손모사가 있다.<br/>
〃 寅 柱 合破 : 순리징조, 공동사가 있고 패후재성하게 된다.<br/>
〃 辰柱我剋:타인을압박하게된다.<br/>
〃 午 柱剋我 : 타인으로부터 압박을 받게 된다.<br/>
〃 申柱支害:선조후성,필시유종의손해실주사가있게된다.<br/>
〃 戌 柱我剋 : 타인으로부터 압박을 받게 된다.<br/>
<br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/>
`
,
`(육합)<br/>
<br/>
丑, 卯, 巳, 未, 酉, 亥, 日<br/>
<br/>
 子  반목(反目 : 부차반목) 운이다.<br/>
<br/>
     화초․과일 등에 관계되고 평화 중에 반목하여 예의를 잃게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 辰 宮三合 : 유동불체의 단합사가 있게 된다.<br/>
內事 丑 柱支合 : 손리징조, 공동사가 있게 되고 규방부부의 화합사가 있다<br/>
〃 卯 柱 支刑 : 존상불리, 상하불화, 무례지사의 대립사가 있게 된다.<br/><br/>
〃 巳柱剋我:상대로부터압박을받게된다.<br/>
〃 未 柱 支害 : 관사구설, 시종무적, 경영조체, 암중재기 지장, 쟁투 손해 실주사가 있게 된다.<br/>
〃 酉柱支破:女.兒의재해,또는파재극처사가있게된다.<br/>
〃 亥 柱支劫 : 형제자매와 관련하여 손모사가 있게 된다.<br/>
<br/>
<br/>
<br/>
丑  엄장(嚴莊 : 사장성취) 운이다.<br/>
<br/>
     의류 ․ 식료에 관계하고 구혼사가 있으며 와병에 이르게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 辰宮日破:폐퇴,파재,극처,실종사가있게된다. 內事 丑柱支比:내부가막히고금전의손해가있다.<br/>
〃 卯柱我剋:타인을압박하게된다.<br/>
〃 巳柱我生:타인을돕는일이생기게된다.<br/>
〃 未 柱 支冲 : 무망무성, 골육상배, 동요불안, 원수사가 발생하게 된다.<br/>
〃 酉柱三合:개혁적단합사가있게된다.별리사가기한다.<br/>
〃 亥柱剋我:타인으로부터압박받는일이있게된다.<br/>
<br/>
 寅  승헌(陞獻 : 환성지경) 운이다.<br/>
<br/>
     매사 통달하고 출타하여 길행을 맞으며 결혼, 중매사가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
〃 卯 柱支劫 : 형제자매로 인한 손모사가 있다.<br/>
〃 巳 柱 支刑 : 도주관사, 거지곤란, 물사손해, 쟁투재앙의 반목대립을<br/>
하게 된다<br/>
〃 未柱剋我:타인으로부터압박받는일이있게된다.<br/>
〃 酉柱我剋:타인을압박하게된다.<br/>
〃 亥 柱 合破 : 순리공동사가 있고 패후재성하게 된다.<br/>
- 285 -<br/>
<br/>
 卯  입실(入室 : 사사성취) 운이다.<br/>
<br/>
     목적사가 달성되고 길하며, 단 망동하면 구설을 맞는다, 혼인사가       있다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
內事 丑 柱 剋我 : 타인으로부터 압박을 받게 된다.<br/>
〃 卯 柱 支比 : 형제자매가 관련되어 손모사가 있다.<br/>
〃 巳 柱生我 : 타인으로부터 도움을 얻게 된다.<br/>
〃 未 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 酉 柱 支冲 : 문호개변, 분리실탈, 가정개동, 동요불안 원수사가 있다.<br/>
〃 亥 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
<br/>
辰  위례(違禮 : 창독득죄) 운이다.<br/>
<br/>
     모든 일이 위배되고 방해받으며 망동하면 죄를 범하게 된다.<br/>
     혼인사는 성립된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 辰宮日比:외교가막히고위탁받은일이안풀리게된다. 內事 丑 柱支破 : 폐퇴사, 파재극처사가 있게 된다.<br/>
〃 卯 柱 支害 : 물사공허, 만사상쟁, 모사저해, 손해 실주사가 있게 된다.<br/>
〃 巳柱我生:타인을돕는일이있게된다.<br/>
〃 未 柱支劫 : 형제자매가 관련하여 손모사가 있게 된다.<br/>
〃 酉柱支合:순리징조,공동사발현이있게된다.<br/>
〃 亥 柱剋我 : 타인으로부터 압박을 받게 된다. <br/>
<br/>
<br/>
 巳  불해(不諧 : 주사개흉) 운이다.<br/>
<br/>
     매사가 불리하고 불화되며 놀라는 일을 겪게 된다.<br/>
     기쁜 소식을 받게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 辰 宮 生我 : 타인으로부터 도움을 얻게 된다. <br/>
內事 丑 柱 生我 : 타인으로부터 도움을 얻게 된다.<br/>
〃 卯柱我生:타인을돕는일이있게된다.<br/>
〃 巳柱支比:내부가막히고금전손해가있게된다.<br/>
〃 未柱生我: 타인으로부터 도움을 얻게 된다.<br/>
〃 酉柱三合: 개혁적인 단합사가 있게 된다. 별리한다.<br/>
〃 亥柱支冲: 중구경득, 조상불경, 순거역래, 동요불안 원수지사가<br/>
있다<br/>
<br/>
午  승당(昇堂 :사개성수) 운이다.<br/>
<br/>
     혼인사에 의한 소송건이 생기고 도모사는 미완되며 가정에는 불평이       있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 辰 宮生我 : 타인으로부터 도움을 얻게 된다.<br/>
內事 丑 柱 支害 : 송사불리, 부부불화, 성취불성, 손해실주사가 있게된다.<br/>
〃 卯 柱 支破 : 파재극처, 가정파폐사가 있게 된다.<br/>
〃 巳 柱支劫 : 형제자매로 인한 손모사가 있다.<br/>
〃 未 柱 支合 : 순리징조, 공도사가 발현된다.<br/>
〃 酉 柱剋我 : 타인으로부터 압박을 받게 된다.<br/>
〃 亥柱我剋:타인을압박하게된다.<br/>
<br/>
未  납미(納未 : 사장성취) 운이다.<br/>
     의식이 족하게 되고 예절을 지키며 잔치할 일이 생긴다.<br/>
     길한 중에 다툼이 있게 된다.<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 辰宮日劫:동료에의해손모사가있게된다.<br/>
內事 丑 柱 支冲 : 모망무성, 골육상배, 동요불안, 원수 지사가 생긴다.<br/>
〃 卯 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱支比:형제자매에의해손모사가있게된다.<br/>
〃 酉柱生我:타인의도움을얻게된다.<br/>
〃 亥 柱剋我 : 타인으로부터 압박을 받게 된다.<br/>
<br/>
 <br/>
申  결발(結髮 : 혼인미만) 운이다.<br/>
<br/>
     군대에 관한 일이 있고 배, 또는 물에 관련되며 손재, 이병사가 <br/>
     일어난다. 혼사는 기쁘다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 辰宮我生:타인을돕게된다. 內事 丑柱我生:타인을돕게된다.<br/>
〃 卯 柱剋我 : 타인으로부터 압박을 받게 된다.<br/>
〃 巳柱 合 :순리공동사가반목되고파재극처하게된다<br/>
刑破<br/>
〃 未柱我生:타인을돕게된다.<br/>
〃 酉 柱支劫 : 형제자매가 관련하여 손모사가 있게 된다.<br/>
〃 亥 柱支害 : 모사불완, 제사불성, 손해 실주사가 있게 된다.<br/>
<br/>
<br/>
 酉  사서(私鼠 : 음사시리) 운이다.<br/>
<br/>
     가정이 동요하고 일도 부진하게 되며 음사불명건이 생긴다. <br/>
     남자가 음분 한다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 辰 宮日合 : 순리징조가 있고 공동사가 일어난다. 內事 丑 柱三合 : 개혁적 단합사가 생긴다. 별리한다.<br/>
〃 卯 柱 支冲 : 문호개변, 분리실탈, 가정개동의 동요불안 원수지사가 생긴다<br/>
〃 巳 柱 三合 : 개혁적 단합사가 생긴다. 별리한다.<br/>
〃 未柱我生:타인을돕는일이생긴다.<br/>
〃 酉柱支比:내부가막히고금전의손해가있다.<br/>
〃 亥柱生我:타인의도움을얻게된다.<br/>
<br/>
<br/>
 戌  망착(亡着 : 불검초리) 운이다.
<br/>
     타인에 의해 박해를 받게 되고 불안하며 색정사 혼인사로 송사하게       된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 辰 宮 日冲 : 희비불명, 하극상배, 부하도망, 등의 이별사가 있게 된다. 內事 丑 柱 支刑 : 하극상배, 거지무원, 재부족사, 반목대립, 쟁송사가<br/>
기 한다.<br/>
〃 卯柱支合:순리징조가있게되고공동사가발현된다.<br/>
〃 巳柱我生:타인에게도움을주게된다.<br/>
〃 未柱刑破:형상사기,파재실종사등이일어난다.<br/>
〃 酉 柱 支害 : 가정손상, 재병발기, 수하농락, 손해실주사사 있다.<br/>
〃 亥 柱剋我 : 타인으로부터 압박을 받게 된다.<br/>
<br/>
亥  특명(特命 : 필유성취) 운이다.<br/>
<br/>
     백사가 통달하고 흉이 제거되며 안태하게 된다. 혼인사도 좋다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 辰 宮 我剋 : 타인을 압박한다. 內事 丑 柱 我剋 : 타인을 압박한다.<br/>
〃 卯 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 巳 柱 支冲 : 중구경득, 조상불경, 순거역래, 동요불안 원수사가 있다.<br/>
〃 未 柱我剋 : 타인을 압박한다.<br/>
〃 酉柱我生:타인을돕게된다.<br/>
〃 亥柱支比:내부가막히고급전손해가있게된다.<br/>
<br/>

<br/><br/><br/><br/><br/><br/><br/><br/><br/>
`,
`(주작)<br/>
子, 寅, 辰, 午, 申, 戌, 日<br/>
<br/>
 子  손익(損翼 : 사공무방) 운이다.<br/>
 <br/>
     모든 재난은 해소되 관청의 기쁨을 얻게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳 宮 剋我 : 타인으로부터 압박을 받는 일이 생긴다. <br/>
內事 子柱支比:내부가막히고금전의손해가있게된다.<br/>
〃 寅柱生我:타인을돕는다.<br/>
〃 辰 柱 三合 : 유동불체의 단합사가 있다.<br/>
〃 午 柱 支冲 : 음양대립, 남녀상쟁, 모사변천, 동요불안 원수지사가 있다.<br/>
〃 申 柱 三合 : 유동불체의 단합사가 있다.<br/>
〃 戌 柱我剋 : 타인을 압박한다.<br/>
<br/>
丑  암목(埯目 : 무구설우) 운이다.<br/>
<br/>
     토지 가옥사가 일어나고 이때는 안정하여 옛것을 지켜야 좋다, <br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳 宮 我生 : 타인에게 도움을 준다.<br/>
內事 子 柱 支合 : 순리공동의 규방부부유합의 화합사가 있게 된다.<br/>
〃 寅柱我剋:타인을압박하게된다.<br/>
〃 辰 柱 支破 : 파폐의 파재극처사가 일어난다.<br/>
〃 午 柱 支害 : 송쟁불리, 부부불화 손해 실주사가 일어난다.<br/>
〃 申柱生我:타인의도움을받는다.<br/>
〃 戌 柱 支刑 : 하극상배, 거지무원, 재부족의 쟁송사가 있게 된다.<br/>
<br/>
<br/>
 寅  안소(安巢 : 구설가식) 운이다.<br/>
<br/>
     학문에 관련된 기쁨이 있게 되고 먼 곳에서 소식이 전해온다<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 巳 宮日刑 : 도주관사, 거지곤란, 물사손해 등 남자가 상한다. <br/>
內事 子柱我生:타인을돕게된다.<br/>
〃 〃 〃 〃 〃<br/>
卯<br/>
外事 內事 〃 〃 〃 〃 〃<br/>
寅柱支比:내부가막히고금전의손해가있다. 辰柱剋我:타인의압박을받는다.<br/>
午柱三合:문화광휘의일이있게되고외화내허한다.<br/>
申 柱 支冲 : 사악작숭, 부부이심, 도로지사, 동요불안, 원수지시가<br/>
생긴다 戌柱剋我:타인의압박을받는다.<br/>
<br/>
 卯  안소(安巢 : 구설가식) 운이다.<br/>
<br/>
     모든 일이 움직이지 않고 원신 문서사가 중절 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
巳 宮 生我 : 타인의 도움을 받는다.<br/>
子 柱 支刑 : 존상불리, 상하불화, 무례지자, 반목대립과 여자가 상한다. 寅 柱支劫 : 형제자매에 의한 손모사가 있다.<br/>
辰 柱 支害 : 물사공허, 만사상쟁, 모사저해의 손해 실주사가 생긴다. 午柱支破:파재극처사등가정이폐하는일이생긴다.<br/>
申 柱我剋 : 타인을 압박한다.<br/>
戌 柱 支合 : 순리공동사가 발현된다.<br/>
<br/>
 辰  투망(投網 : 재난전소) 운이다.<br/>
<br/>
     문서나 문학적인 일의 착오가 있게 되고 구설 및 불상사가 <br/>
     있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮我生:타인을돕게된다.<br/>
內事 子 柱 三合 : 유동불체의 단합사가 있게 된다.<br/>
〃 寅柱我剋:타인을압박하게된다.<br/>
〃 辰柱支比:내부가막히고금전의손모사가있다<br/>
〃 午柱我生:타인을돕는다.<br/>
〃 申柱生我:타인의도움을받는다.<br/>
〃 戌 柱 支冲 : 희비불명, 하극상배, 부하도망, 동요불안,원수지사가 생긴다<br/>
<br/>
<br/>
 巳  주상(晝翔 : 송흉음길) 운이다.<br/>
<br/>
     문서․문장․문학․문화사가 이롭게 되고 봄, 여름이 좋다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮日比:외교가막히고위탁받은일이풀리지않는다.<br/>
內事 子 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 寅 柱 支刑 : 도부관사, 거지곤란, 물사손해, 대립반목, 송사가 있고<br/>
여자가 상한다.<br/>
〃 辰柱生我:타인의도움을얻게된다.<br/>
〃 午 柱支劫 : 형제자매에 의해 손모사가 있다.<br/>
〃 申柱 合 :공동사발현,은혜배반,패후재성,실종사가있다.<br/>
刑破<br/>
〃 戌柱生我:타인의도움을얻게된다.<br/>
<br/>
<br/>
午  각부(脚符 : 경영분규) 운이다.<br/>
<br/>
     괴이한 구설의 대상이 되고 관사가 벌어지며 혼인사가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
內事 子 柱 支冲 : 음양대립, 남녀상쟁, 모사변천, 동요불안의 원수사가 생긴다.<br/>
〃 寅柱三合:문화광휘의단합사가있게되고외화내허한다. 〃 辰柱生我:타인의도움을얻게된다.<br/>
〃 午柱支比:내부가막히고금전소모의일이있게된다.<br/>
〃 申柱剋我:타인의압박을받는다.<br/>
〃 戌柱三合:문화광휘의단합사가있게되고외화내허한다. <br/>
<br/>
<br/>
未  임분(臨墳 : 불의투서) 운이다.<br/>
<br/>
     의․식이 양호하고 불사건이나 비읍사가 일어나며 酒食자리에서       싸우게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮我生:타인을돕게되는일이있다.<br/>
內事 子柱支害:관사구설,경영지체,시종무적,손해실주근심이있게된다.<br/>
〃 寅柱我剋:타인을압박하는일이있게된다.<br/>
〃 辰柱支劫:형제자매에의해금전소모가있게된다.<br/>
〃 午 柱 支合 : 순리징조 공동사가 발현된다.<br/>
〃 申柱生我:타인의도움을얻게된다.<br/>
〃 戌 柱 刑破 : 반목대립, 형상사가 일어나고 파재극처사가 생긴다.<br/>
<br/>
<br/>
 申  여각(勵角 : 경관섭송) 운이다.<br/>
<br/>
     군대 사무사가 일어나고 소식은 지체 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮 合 :순리공동사가있고반목대립,파재실종사로남자가상한다.<br/>
刑破<br/>
內事 子 柱 三合 : 유동불체의 단합사가 있게 된다.<br/>
〃 寅柱支冲 : 사악작숭, 부부이심, 도로지사, 동요불안, 원수지사가 생 긴다.<br/>
〃 辰柱我生:타인에게도움을주게된다.<br/>
〃 午柱我剋:타인을압박하게된다.<br/>
〃 申柱支比:내부사가막히고금전손모가있다.<br/>
〃 戌柱我生:타인에게도움을주는일이있다.<br/>
<br/>
<br/>
<br/>
 酉  야조(夜操 : 관재질병) 운이다.<br/>
<br/>
     관사구설이 발생하고, 또는 질병사가 있게 된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮 日 :개혁사로단합하고별리사가있게된다. 三合<br/>
內事 子柱支破:女.兒의재해가있게되고파재실종사가생긴다.<br/>
〃 寅柱剋我:타에의해압박을받는일이있다.<br/>
〃 辰柱支合:순리공동사가발현된다.<br/>
〃 午柱我剋:타인을압박하는일이있다.<br/>
〃 申柱支劫:형제자매로인해금전의손모가있게된다.<br/>
〃 戌 柱 支害 : 가정손상, 재병발기, 암중불비, 수하농락, 손해 실주사가<br/>
있게 된다.<br/>
<br/>
<br/>
<br/>
 戌  투망(投網 : 문서유망) 운이다.<br/>
<br/>
     일이 위배되고 분실사가 일어나며 착오가 있게 된다. 문장사는       좋게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
內事 子柱剋我:타인에게압박을받는일이있게된다.<br/>
〃 寅柱我剋:타인을압박하는일이있게된다.<br/>
〃 辰 柱 支冲 : 희비불명, 하극상배, 부하도망, 동요불안,원수지시가 있다.<br/>
〃 午柱三合:문화광휘,단합사가있게되고외화내허한다.<br/>
〃 申柱生我:타인의도움을받게된다.<br/>
〃 戌柱支比:내부가막히고금전의손모가있다.<br/>
<br/>
<br/>
亥   치사(癡事 : 헌책무용) 운이다.<br/>
<br/>
     건축사, 가축사, 야수에 관한 일이 있고 활동은 실재하여 <br/>
     안정이 좋다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳 宮 日冲 : 중구경득, 조상불경, 순거역래, 동요불안, 이별사가 있게 된다.<br/>
內事 子 柱 支劫 : 형제자매에 의한 손모사가 있다.<br/>
〃 寅柱合破:순리공동사가발현되고파후재성한다.<br/>
〃 辰柱我剋:타인을압박하게된다.<br/>
〃 午柱剋我:타인에게압박을받게된다.<br/>
〃 申 柱 支害 : 선저후성, 모사불완, 손해 실주사가 있다.<br/>
〃 戌柱我剋:타인을압박하게된다.<br/>
<br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/>
`,
`(등사)<br/>
丑, 卯, 巳, 未, 酉, 亥, 日<br/>
<br/>
 子  암목(埯目 : 불능상인) 운이다.<br/>
<br/>
     연약파쇄물․화형문장․물에 관련된 일이 있게되고 흉하지는 않으나       괴이몽은 있다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未 宮日害 : 관사구설, 경영지체의 근심이 있게 된다.<br/>
內事 丑 柱支合 : 순리공동사 발현과 규방부부융합의 화합사가 있게 된다.<br/>
〃 卯 柱 支刑 : 존상불리, 상하불화, 무례지자, 반목대립사가 기한다.<br/>
〃 巳柱剋我:타인의압박을받게된다.<br/>
〃 未 柱 支害 : 시종무적, 암중재기, 손해 실주사가 일어난다.<br/>
〃 酉柱支破:女.兒재해및파재극처사가일어난다.<br/>
〃 亥柱支劫:형제자매에의해손모사가있게된다. <br/>
<br/>
<br/>
<br/>
 丑  번구(嬏龜 : 화소복지) 운이다.<br/>
<br/>
     화(禍)와 복(福)이 자신의 행동에 달렸으므로 정(靜)하면 무방하나<br/>
     망동하면 원수사가 벌어진다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 未 宮 日冲 : 모망무성, 골육상배, 이별지사가 있게 된다. <br/>
內事 丑柱支比:내부가막히고금전의손모사가있게된다.<br/>
〃 卯柱我剋:타인을압박하게된다.<br/>
〃 巳柱我生:타인을돕는일이생긴다.<br/>
〃 未 柱 支冲 : 모망무성, 골육상배, 동요불안 원수지사가 있게 된다.<br/>
〃 酉柱三合:개혁적단합사가있게된다.별리한다.<br/>
〃 亥柱剋我:타인의압박을받게된다.<br/>
<br/>
 寅  생각(生角 : 왕진쇠퇴) 운이다.<br/>
<br/>
     화복양도에 처하고 뱀이 용으로 변하며 빈(貧)해도 일시 복이 있게        된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未 宮 剋我 : 타인에게 압박을 받는다. 內事 丑 柱 剋我 : 타인에게 압박을 받는다.<br/>
〃 卯柱支劫:형제자매에의한금전손모사가있다.<br/>
〃 巳 柱 支刑 : 도주관사, 거지곤란, 물사손해, 쟁투재앙의 소송사가 기한다.<br/>
〃 未 柱剋我 : 타인에게 압박을 받는다.<br/>
〃 酉柱我剋:타인을압박하게된다.<br/>
〃 亥 柱 合破 : 순리공동사가 일어나고 패후재성하게 된다.<br/>
<br/>
<br/>
<br/>
 卯  당문(堂門 : 재난불측) 운이다.<br/>
<br/>
     과(果)․식(食)․물의 기물에 관련되고 초화로 가정에 상처가 있으며 불화․재앙이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未 宮 三合 : 곡직번잡의 단합사가 있게 된다. 內事 丑柱剋我:타인의압박을받게된다.<br/>
〃 卯柱支比:내부가막히고금전의손모사가있다.<br/>
〃 巳柱生我:타인의도움을받게된다.<br/>
〃 未 柱 三合 : 곡직번잡의 단합사가 있다.<br/>
〃 酉 柱 支冲 : 문호개변 분리실탈, 가정개동, 동요불안,원수지사가 생긴다.<br/>
〃 亥 柱 三合 : 곡직번잡의 단합사가 있다.<br/>
<br/>
<br/>
 辰  상룡(象龍 : 재난전소) 운이다.<br/>
<br/>
     모든 일을 멀리하고 피하라. 임산부는 공사를 만나게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未 宮日劫 : 외부일로 손모사를 겪는다.<br/>
內事 丑 柱 支破 : 폐퇴적 파재 극처사가 일어난다.<br/>
〃 卯 柱 支害 : 물사공허 만사상쟁 모사저해 손해 실주사를 겪는다.<br/>
〃 巳柱我生:타인을돕는일이생긴다.<br/>
〃 未 柱 支劫 : 형제자매로 인한 손모사를 생긴다.<br/>
〃 酉柱支合:순리골동사가발현된다.<br/>
〃 亥柱剋我:타인의압박을받는다.<br/>
<br/>
<br/>
巳  승무(乘務 : 불변근신) 운이다.<br/>
<br/>
     모든 일이 전진하여 길하다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
內事 丑柱生我:타인의도움을얻게된다.<br/>
〃 卯柱我生:타인에게도움을준다.<br/>
〃 巳柱地比:내부가막히고금전의손모사가있게된다.<br/>
〃 未柱生我:타인의도움을얻게된다.<br/>
〃 酉柱三合:개혁적단합사가있게된다.<br/>
〃 亥 柱 支冲 : 중구경득 조상불경 순거역래 동요불안 원수지사가 생긴다<br/>
<br/>
<br/>
 午  비공(飛空 : 불변근신) 운이다.<br/>
<br/>
     부녀자건․이별사가 있고, 찾는 사람은 돌아오게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未 宮 日合 : 순리 공동사가 발현된다.<br/>
內事 丑柱支害:소송불리부부불화손해실주의근심이있게된다.<br/>
〃 卯柱支破:가정이폐하는파재극처사가있게된다.<br/>
〃 巳柱支劫:형제자매에의해손모사가있게된다.<br/>
〃 未 柱支合 : 순리 공동사가 발현된다.<br/>
〃 酉柱剋我:타인의압박을받게된다.<br/>
〃 亥柱我剋:타인을압박하게된다.<br/>
<br/>
<br/>
<br/>
<br/>
未  입림(入林 : 봉림불포) 운이다.<br/>
<br/>
     미곡․의류․주기류에 의한 구설․관사가 일어난다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未宮日比:외교가막히고위탁받은일도안풀린다.<br/>
內事 丑 柱 支冲 : 모망무성 골육상배 동요불안 원수지사가 생긴다.<br/>
〃 卯 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 巳柱我生:타인을돕는일이발생한다.<br/>
〃 未柱地比:내부가막히고금전의손모사가있게된다.<br/>
〃 酉柱生我:타인의도움을얻게된다.<br/>
〃 亥柱剋我:타인의압박을받게된다.<br/>
<br/>
<br/>
<br/>
 申  형검(衡劍 : 총시성재) 운이다.<br/>
<br/>
     도로 사인(死人)사, 군인사가 있게 되고 가정불화․상해건이 발생한다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未宮我生:타인을돕는일이생긴다. <br/>
內事 丑柱我生:타인을돕는일이생긴다.<br/>
〃 卯 柱剋我 : 타인으로부터 압박을 받게 된다.<br/>
〃 巳柱 合 :순리공동사반목대립송사파재극처사가생긴다.<br/>
刑破<br/>
〃 未柱我生:타인을돕는일이생긴다.<br/>
〃 酉柱支劫:형제자매에의한손모사가있게된다.<br/>
〃 亥 柱支害 : 선저후성 모사불안 제사불성 손해 실주의 근심이 생긴다.<br/>
<br/>
<br/>
 酉  노치(露齒 : 구설괴이) 운이다.<br/>
<br/>
     여아․유아 등의 재병사가 있게 되고 구설․괴이사가 발생한다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未宮我生:타인을돕게된다.<br/>
內事 丑 柱三合 : 개혁적 단합사가 생긴다. 별리한다.<br/>
〃 卯 柱 支冲 : 문호개변 분리실탈 가정개동 동요불안 원수지시가 있게 된다.<br/>
〃 巳 柱 三合 : 개혁적 단합사가 생긴다. 별리한다.<br/>
〃 未柱我生:타인을돕게된다.<br/>
〃 酉柱支比:내부가막히고금전손모사가있게된다.<br/>
〃 亥柱生我:타인의도움을얻게된다.<br/>
<br/>
<br/>
 戌  입총(入塚 : 재난전소) 운이다.<br/><br/>
<br/>
     어린 아이의 농락 건이 발생하고 모든 일의 근심은 해소 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未宮刑破:반목대립파재극처실종을겪게된다.<br/>
內事 丑 柱支刑 : 하극상배 거지무원 재부족의 손사를 겪는다.<br/>
〃 卯柱支合:순리공동사가발현된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱刑破:반목대립파재극처사를겪게된다.<br/>
〃 酉 柱 支害 : 가정손상 암중불비 수하농락 손해 실주사가 기한다.<br/>
〃 亥柱剋我:타인의압박을받는다.<br/>
<br/>
 亥  수수(隨水 : 봉흉화길) 운이다.<br/>
<br/>
     다기류의 건이나 부녀자 건이 생기고 여사용인 도망, 또는 <br/>
     실재사가 일어난다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未 宮 我剋 : 타인을 압박하게 된다. 內事 丑 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 卯 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 巳 柱 支冲 : 중구경득 조상불경 순거역래 동요불안 원수지시가 생긴다<br/>
〃 未柱我剋:타인을압박하게된다.<br/>
〃 酉柱我生:타인을돕게된다.<br/>
〃 亥柱支比:내부가막히고금전손모사가있게된다.<br/>
<br/>
`,
`(구진)<br/>
<br/>
寅, 辰, 午, 日<br/>

<br/>
 子  투기(投機 : 능욕박해) 운이다.<br/>
<br/>
     투자사로 손해가 있고 수치․불안․구설․소송사를 만난다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
內事 寅柱生我:타인의도움을받게된다.<br/>
〃 辰 柱三合 : 유동불체의 단합사가 있다.<br/>
〃 午 柱 支冲 : 음양대립 남녀상쟁 모사변천 동요불안 원수지사가 생긴다.<br/>
<br/>
<br/>
 丑  수월(受越 : 능욕박해) 운이다.<br/>
<br/>
     기구․자동차․병류․와성사가 있고 관리는 죄가 되며 능욕을       받게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮我生:타인을돕게된다. <br/>
內事 寅 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 辰柱支破:폐퇴의파재극처사가있다.<br/>
〃 午柱支害:소송불리부부불화주사불명손해실주사가있게된다.<br/>
<br/>
<br/>
 寅  조수(遭囚 : 상서헌책) 운이다.<br/>
    부녀자의 사상사가 있고 형극이 있으며 상소․진정서 제출이  좋다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳 宮日刑 : 도주관사 거지곤란 물사손해 반목대립이 있고<br/>
남자가 상한다.<br/>
內事 寅柱支比:내부가막히고금전손모사가있다<br/>
〃 辰 柱剋我:타인의압박을받는다.
〃 卯 外事 內事 〃 <br/>
〃 午 柱三合 : 문화광휘의 단합사가 있고 내허외화 한다.<br/>
<br/>
<br/>
 卯  임문(臨門 : 가실불화) 운이다.<br/>
<br/>
     필목 와기사가 있고 가정사가 불리하며 전거사가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
巳宮生我:타인의도움을얻게된다. 寅柱支劫:형제자매에의해손모사가있게된다.<br/>
辰 柱支害 : 물사공허 만사상쟁 모사저해 손해 실주사를 겪는다. <br/>
午 柱支破 : 가정폐파의 파재 극처사를 겪는다.<br/>
<br/>
辰  승당(昇堂 : 면굴난해) 운이다.<br/>
<br/>
    모든 일이 지연되고 반목되며 관송사가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮我生:타인을돕게된다. <br/>
內事 寅 柱 我剋 : 타인의 압박을 받는다.<br/>
〃 辰柱支比:내부가막히고금전손모사가있게된다. <br/>
〃 午柱我生:타인을돕게된다.<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
巳  봉인(捧印 : 관천민흉) 운이다.<br/>
<br/>
     사물이 개신되어 유리하게 된다, 승급사가 있다, 화로․홍색물       품에 관련된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
巳宮日比:외교가막히고위탁받은일도지체된다.<br/>
寅 柱支刑 : 도주관사 거지곤란 물사손해 송쟁사가 생기고 여자가<br/>
外事 內事 〃<br/>
午柱支劫:형제자매에의한금전의손모사가있게된다. <br/>
<br/>
<br/>
午  반목(反目 : 타인견루) 운이다.<br/>
<br/>
    사족물․주삭물․모피류에 관련되고 길흉이 타인에 의해 결정      되며 출생지이면 좋다.<br/>
상한다. 辰柱生我:타인의도움을얻게된다.<br/>
外事 巳宮日劫:외사에친구동료가관계되어금전손모가있게된다. 內事 寅 柱 三合 : 문화광휘 단합사가 있고 내허외화이다.<br/>
〃 〃<br/>
未<br/>
辰柱生我:타인의도움을얻게된다. 午柱支比:내부가막히고금전손모사가있게된다.<br/>
<br/>
<br/>
未  입역(入驛 : 모망난수) 운이다.<br/>
<br/>
    중혼 재혼의 부인 건이 있고 자식문제가 있으며 주석상의 쟁사가      있으나 길하다.<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다. <br/>
外事 巳宮我生:타인을돕게된다.<br/>
內事 寅 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 辰柱支劫:형제자매에의한금전손모사가있게된다. <br/>
〃 午柱支合:순리공동사가발현된다.<br/>
<br/>
 申  추호(推戶 : 구연반복) 운이다.<br/>
<br/>
     소․자동차․도망인 사망건, 주거변경사가 생긴다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/><br/>
<br/>
外事 巳 宮 合 刑破 : 순리 공동사 은혜배반 실종 파재극처가 생긴다. <br/>
內事 寅 柱 支冲 : 사악작숭 부부이심 동요불안 원수지사가 생긴다.<br/>
〃 辰柱我生:타인을돕게된다. <br/>
〃 午柱我剋:타인을압박하게된다.<br/>
<br/>
<br/>
 酉  피도(披刀 : 주유형책) 운이다.<br/>
<br/>
     금전상의 쟁투사가 있고 만사형책건이 일어나며 병을 앓는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
巳宮 日 :개혁적단합사가생긴다,별리한다. 三合<br/>
寅 柱 剋我 : 타인의 압박을 받는다.<br/>
辰柱支合 : 순리 공동사가 발현한다. 〃 午柱我剋:타인을압박하게된다.<br/>
<br/>
<br/>
 戌  하옥(下獄 : 송류피길) 운이다.<br/>
<br/>
     개(犬)건․골(骨)류․석기건과 관계되고 관송사가 일어난다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
巳宮我生:타인을돕는일이있게된다.<br/>
寅 柱 我剋 : 타인을 압박하게 된다.<br/>
辰 柱 支冲 : 희비불명 하극상배 부하도망 동요불안 원수지사가 생긴다. 午 柱三合 : 문화광휘의 단합사가 있고 외화내허 이다.<br/>
<br/>
<br/>
<br/>
 亥  장상(張裳 : ) 운이다.<br/>
<br/>
     석기․고기(古器)의 다툼에 관련되고 아손건이 일어나며 <br/>
     개혁사가 생긴다, 금전사는 갈하다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
巳宮 合 :순리공동사은혜배반실종파재극처가생긴다.<br/>
刑破<br/>
寅 柱 支冲 : 사악작숭 부부이심 동요불안 원수지사가 생긴다.<br/>
外事 巳 宮 日冲 : 중구경득 조상불경 순거역래 동요불안 이별지사가 생긴다 <br/>
內事 寅 柱合破 : 순리 공동사가 발현되고 패후재성사가 있다.<br/>
〃 辰柱我剋:타인을압박하게된다.<br/>
〃 午 柱剋我 : 타인에게 압박을 당한다.<br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/>
`,
`(천공)<br/>
<br/>
申, 戌, 子, 日<br/>
<br/>
<br/>
 子  익수(溺水 : 백사구우) 운이다.<br/>

     사인(死人)건을 만나고 부인이나 소인의 재액사를 겪게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
     外事 巳宮剋我:타인의압박을받게된다.<br/>
內事 申 柱 三合 : 유동불체의 단합사가 생긴다.<br/>
〃 戌柱我剋:타인을압박하게된다.<br/>
〃 子柱支比:내부사가막히고금전손모사가있게된다.<br/>
<br/>
 丑  대측(待側 : 사관천탁) 운이다.<br/>
<br/>
     차량전복사가 우려되고 윗사람의 언사에 속으며 가인이 원죄       하게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
<br/>
外事 巳 宮 我生 : 타인에게 도움을 준다.<br/>
內事 申 柱 生我 : 타인의 도움을 얻게 된다.<br/>
戌 柱 支刑 : 하극상배 거지무원 재부족의 소송사가 셍기고 여자가 상한다.<br/>
子 柱 支合 : 순리 공동사 규방부부융합사가 생긴다.<br/>
<br/>
<br/>
 寅  피제(被制 : 공사구설) 운이다.<br/>
<br/>
     저주사가 있고 무용건을 만나며 공사․사사에 구설시비가 <br/>
     있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
巳 宮日刑 : 도부관사 거지곤란 물사손해 쟁투재앙이 있고 남자가 상한다.<br/>
申 柱 支冲 : 사악작숭 부부이별 도로지사 동요불안 원수 맺게 된다. <br/>
〃 戌 柱 剋我 : 타인의 압박을 받는다.<br/>
申 柱 我剋 : 타인을 압박하게 된다.<br/>
<br/>
 卯  승매(乘梅 : 폭객기능) 운이다.<br/>
<br/>
     고위인․승도․ 예술가․열등공원등의 폭객으로 자상받거나        감언에 속는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
〃 戌柱剋我:타인의압박을받는다. <br/>
〃 子柱我生:타인을돕는다.<br/>
〃 戌柱支合:순리공동사가발현된다.<br/>
〃 子 柱 支刑 : 존상불리 상하불화 무례지자의 반목대립사가 생긴다.<br/>
<br/>
<br/>
<br/>
<br/>
 辰  건악(長事惡 : 폭객기능) 운이다.<br/>
<br/>
     암매 부실하고 매사 모욕을 받거나 하는데 다만 소사는 이룬다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事  巳 宮我生:타인을돕게된다.<br/>
內事 申 柱生我:타인의도움을받게된다.<br/>
〃 戌 柱支冲 : 희비불명 하극상배 부하도망 동료불안 원수지사가 있게<br/>
된다.<br/>
〃 子 柱 三合 : 유동불체의 단합사가 있다.<br/>
<br/>
 巳  수욕(受辱 : 모사즉길) 운이다.<br/>
<br/>
    허위 부실 건을 겪고 또는 신청서와 상신하는 일을 겪는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮日比:외교사가막히고위탁받은일이지체된다.<br/>
內事 申柱 合 :순리공동사반목대립패후재성사가있게된다. 刑破<br/>
〃 戌柱生我:타인의도움을얻게된다. <br/>
〃 子柱我剋:타인을압박하게된다.<br/>
<br/>
 <br/>
  午  익수(溺水 : 백사구우) 운이다.<br/>
<br/>
     문서 소송 시비사를 겪고 문장이나 문화사는 좋다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 巳宮日劫:동료친구에의해금전손모사가있게된다. 內事 申柱剋我:타인의압박을받게된다.<br/>
〃 戌柱三合:문화광휘단합사가있게되고외화내허이다.<br/>
〃 子 柱支冲 : 음양대립 남녀상쟁 모사변천 동분서주 동요불안 원수<br/>
맺는다.<br/>
<br/>
<br/>
 未  추진(趨進 : 사기득재) 운이다.<br/>
<br/>
     자식문제․병인건․고로건․음식문제가 발생하고 재물 주음사에       이익이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다. <br/>
巳宮我生:타인을돕게된다.<br/>
申 柱 生我 : 타인의 도움을 받는다.<br/>
戌 柱刑破 : 형상사 파재 극처사가 일어난다.<br/>
子 柱 支害 : 관제구설 경영지체 시종무적 손해 실주사가 기한다.<br/>
<br/>
 申  고설(鼓舌 : 정위난측) 운이다.<br/>
<br/>
     도적이나 금․철로 놀래고 선악․진위에 고심하므로 대개 <br/>
     흉조로 본다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다. <br/>
<br/>
巳宮 合 :순리공동사은혜배반파재극처실종사를겪는다. <br/>
刑破 申柱支比:내부가막히고금전손모사가생긴다. <br/>
〃 戌柱我生:타인을돕게된다.<br/>
外事 內事<br/>
〃 酉 外事 內事 <br/>
〃 子 柱三合 : 유동불체의 단합사가 있게 된다. <br/>
<br/>
<br/>
<br/>
 酉  교설(巧舌 : 간인궤계) 운이다.<br/>
<br/>
     음사 사건이 있고 믿는 일에 보답하는 건이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다. <br/>
巳宮 日 :개혁적단합사가있고별리한다. 三合<br/>
申柱支劫:형제자매에의한금전손모사가있게된다.<br/>
戌 柱支害 : 가정손상 배병발휘 수하농락 손해실주 근심이 생긴다. 〃 子柱支破:女.兒의재해가있고파재극처실종사를겪는다.<br/>
<br/>
<br/>
<br/>
<br/>
 戌  거가(居家 : 백사구허) 운이다.<br/>
<br/>
     이동사․불교건․식료사․사골류․소송사에 관련된 일을 겪는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다. <br/>
<br/>
外事 巳宮我生:타인을돕게된다.<br/>
內事 申柱生我:타인의도움을받게된다.<br/>
〃 戌柱支比:내부가막히고금전손모사가있게된다. <br/>
〃 子柱剋我:타인의압박을받는다.<br/>
<br/>
<br/>
 亥  좌사(座詞 : 간인궤계) 운이다.<br/>
<br/>
     복태를 손상하거나 분실사를 만나고 간사한 사람의 모사에 <br/>
     걸리게 되며 적은 이익이 있다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다. <br/>
外事 巳 宮 日冲 : 중구경득 조상불경 순거역래 동요불안 원수지사가 생긴다.<br/>
內事 申柱支害:모사불완제사불성손해실주의근심이있게된다. <br/>
〃 戌柱我剋:타인을압박하게된다.<br/>
〃 子柱支劫:형제자매에의한금전손모사가있게된다.<br/>
<br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/>
`,
`(귀인)
<br/>
酉, 亥, 丑, 日
<br/>


<br/>
<br/>
<br/>
 子  해분(解分 : 해거분우) 운이다.<br/>
<br/>
     화장품․얼음․여인 주식사 및 부인의 병 문제가 생기나 해소 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
未 宮日害 : 관사구설, 경영저해 손해실주의 근심이 있게 된다.<br/>
酉柱支破:女.兒의재해파재극처사가있게된다.<br/>
〃 亥柱支劫:형제자매에의한금전손모사가있게된다.<br/>
外事 內事<br/>
〃 丑<br/>
外事 內事<br/>
寅<br/>
外事 內事<br/>
<br/>
<br/>
 丑  승당(昇堂 : 귀인접인) 운이다.<br/>
<br/>
     금석․귀중물․보옥과 관계가 있고 관공서 신청서 논문고찰․귀인        면접의 기쁨이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
未 宮日冲 : 모망무성 골육상배 동요불안 원수지사가 있게 된다.<br/>
酉 柱 三合 : 개혁적 단합사가 있고 별리한다.
〃 亥柱剋我:타인의압박을받게된다.<br/>
〃 丑柱支比:내부가막히고금전의손모사가있게된다.<br/>
<br/>
<br/>
 寅  빙개(憑介 : 사문청알) 운이다.<br/>
<br/>
     공무건․서적․문장건에 관계되고 면접사의 기쁨이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
<br/>
未宮剋我:타인의압박을받게된다.<br/>
酉 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 亥柱合破:순리공동사의발현이있고패후재성한다. 〃 丑柱剋我:타인의압박을받게된다.<br/>
<br/>
<br/>
 卯  등거(登車 : 가택천이) 운이다.<br/>
<br/>
     관송사가 좋게 되고 퇴직에도 이로우며 불사․제사가 있고 <br/>
     이사문제가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
未 宮 三合 : 곡직번잡의 단합사가 있게 된다.<br/>
酉 柱 支冲 : 문호개변 분리실탈 가정개동 동요불안 원수지사가 생긴다. 亥 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 丑柱剋我:타인의압박을받게된다. <br/>
<br/>
 <br/>
辰  천우(天宇 : 상견흠리) 운이다.<br/>
<br/>
     금석․귀중물․보옥과 관계가 있고 관공서 신청서 논문고찰․귀인        면접의 기쁨이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未宮日劫:동료친구에의해금전손모사가있게된다. 內事 酉柱支合:순리공동사가발현된다.<br/>
〃 亥柱剋我:타인의압박사가있게된다.<br/>
〃 丑柱支破:퇴폐의파재극처사가있게된다.<br/>
<br/>
<br/>
<br/>
巳  수상(受賞 : 천발천탁) 운이다.<br/>
<br/>
     보물사가 귀중건에 관계되고 문서사로 상하 기쁨이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
     <br/>
      外事 未宮生我:타인의도움을얻게된다.<br/>
內事 酉柱三合:개혁적단합사가있게되고별리한다.<br/>
〃 亥 柱 支冲 : 중구경득 조상불경 순거역래 동요불안 원수지사가<br/>
생긴다.<br/>
〃 丑柱生我:타인의도움을얻는다.<br/>
<br/>
<br/>
 <br/>
<br/>
外事 酉柱生我:타인을도움을얻게된다. <br/>
內事 〃 亥柱剋我:타인의압박을받게된다.<br/>
<br/>
<br/>
 午  수상(受賞 : 천발천탁) 운이다.<br/>
<br/>
     보물․적신불 등의 물건사에 관련되고 모망사가 윗사람의 배려에        의해 추천․천전된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 未宮日合:순리징조가있게되고공동발현사가생긴다. <br/>
內事 酉柱剋我:타인의압박을받게된다.<br/>
〃 亥柱我剋:타인을압박하게된다.<br/>
丑 柱 支害 : 송사불리 부부불화 주사불명 손해실주 근심이 생긴다. <br/>
<br/>
<br/>
 未  열석(列席 : 귀인연회) 운이다.<br/>
<br/>
     혼인․주식사가 있고 안락의 혜택사가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
未 宮 日比 : 외교가 막히고 위탁받은 일이 지체된다.<br/>
內事 〃 未<br/>
內事 〃 申<br/>
丑 柱 支冲 : 모망무성 골육상배 동요불안 원수사가 생긴다. <br/>
<br/>
 申  이도(移途 : 도중청알) 운이다.<br/>
<br/>
     금전․보물․약물사가 있고 도로상의 구하는 일이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
未宮我生:타인을돕게된다.<br/>
酉 柱支劫 : 형제자매가 관련되어 손모사가 있게 된다.<br/>
〃 亥柱支害:선저후성모사불완손해실주의근심이생긴다.<br/>
 〃 丑柱我生:타인을돕게된다.<br/>
<br/>
 <br/>
<br/>
 酉  사실(私室 : 간사은닉) 운이다.<br/>
    가정불안의 징조가 일어난다.<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
未宮我生:타인을돕는일이있다<br/>
酉柱支比:내부가막히고금전의손모사가있게된다. 〃 亥柱生我:타인의도움을받게된다.<br/>
外事 內事<br/>
丑 柱三合 : 개혁적인 단합사가 있게 된다. 별리한다.<br/>
<br/>
 戌  입옥(入獄 : 상견흠리) 운이다.<br/>
<br/>
     금전․약물사로 가정 불안이 일어난다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
未 宮刑破 : 반목대립의 형상사가 생기고 파재 실종사를 겪는다.<br/>
酉 柱支害 : 가정손상 재병발기 수하농락 손해 실주사가 기한다. 〃 亥柱剋我:타인의압박을받게된다.<br/>
丑 柱 支刑 : 존상불리 상하불화 무례지자의 반목대립사가 생긴다. <br/>
<br/>
 <br/>
 亥  선봉(選縫 : 이어진취) 운이다.<br/>
<br/>
     진귀한 귀중품 건이거나 여성관계의 진취사가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
未 宮 我剋 : 타인을 압박하게 된다.<br/>
酉柱我生:타인을돕는일이있게된다.<br/>
〃 亥柱支比:내부가막히고금전손모사가생긴다. <br/>
〃 丑柱我剋:타인을압박하게된다.
<br/><br/><br/><br/><br/><br/><br/><br/><br/>
`,
`(귀인)<br/>
卯, 巳, 未, 日<br/>

<br/>

 子  조가(遭茄 : 주식수벌) 운이다.<br/>
<br/>
     유희․무용․음악 등의 일이 있고 술자리에 의한 상․벌이 있게 된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 未 宮 日害 : 관사구설 경영지체 손해실주 근심사가 생긴다. <br/>
內事 卯 柱 支刑 : 존상불리 상하불화 무례지자 반목대립이 생긴다.<br/>
〃 巳柱剋我:타인의압박을받게된다.<br/>
〃 未 柱支害 : 시기의심 시종무적 암중재기 손해실주 근심이 생긴다.<br/>
<br/>
<br/>
 丑  수작(受爵 : 진직천관) 운이다.<br/>
<br/>
     객인(客人)사가 있고 직위향상․천관의 기쁨이 있다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 未 宮日冲 : 모망무성 골육상배 동요불안 이별사가 있게 된다. 內事 卯 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未 柱支冲 : 모망무성 골육상배 동요불안 원수지사가 있게 된다.<br/>
<br/>
<br/>
<br/>
<br/>
 寅  측목(側目 : 참주이간) 운이다.<br/>
<br/>
     처의 신상에 상해가 있게 되고 타인의 중상을 받으며 신용이 저해된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
 未宮剋我:타인의압박을받게된다.<br/>
卯柱支劫:형제자매에의한금전손모사가있게된다.<br/>
巳 柱 支刑 : 도주관사 거지곤란 물사손해 쟁투재앙의 송사가 생긴다.<br/>
〃 未柱剋我:타인의압박을받게된다.<br/>
<br/>
 卯  유관(遺冠 : 재물유실) 운이다.<br/>
<br/>
     꽃․열매 또는 귀신사에 관계되고 손실․분실 건을 당하게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
未 宮 三合 : 곡직번잡의 단합사가 있게 된다.<br/>
卯柱支比:내부가막히고금전손모사가있게된다. <br/>
〃 巳柱生我:타인의도움을받게된다.<br/>
未 柱三合 : 곡직번잡의 단합사가 있게 된다. <br/>
<br/>
<br/>
<br/>
 辰  패인(佩印 : 이관불민) 운이다.<br/>
<br/>
     약물․식료의 일에 관계되고 구재․면접으로 명성과 직위가 향상 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
未宮日劫:동료친구에의해손모사가발생하게된다.<br/>
卯 柱 支害 : 물사공허 만사상쟁 모사저해 손해실주의 근심이 생긴다. 〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱支劫:형제자매에의해손모사가있게된다.<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
 巳  주인(鑄印 : 미초희경) 운이다.<br/>
<br/>
     사업․직업건이 있게 되고 명리가 향상되며 후에는 좋다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
未宮生我:타인의도움을받게된다.<br/>
卯柱我生:타인을돕게된다.<br/>
〃 巳柱支比:내부가막히고금전손모사가생긴다. <br/>
〃 未柱生我:타인의도움을받게된다.<br/>
<br/>
<br/>
<br/>
<br/>
 午  승헌(乘軒 : 신분대경) 운이다.<br/>
<br/>
     옷․머리에 관한 건이 있게되고 손모가 있으며 원조나 은혜를 받게       된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
未宮日合:순리공동사가발현된다.<br/>
卯 柱支破 : 가정파폐의 파재 극처사가 생긴다.<br/>
〃 巳柱支劫:형제자매에의한금전손모사가생긴다. <br/>
〃 未柱支合:순리공동사가발현된다.<br/>
<br/>
 未  봉상(捧觴 : 미초희경) 운이다.<br/>
<br/>
     자식과 모친의 관련 건이 발생하고 초대의 기쁨을 맞게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
未宮日比:외교가막히고위탁받은일도지체된다.<br/>
卯 柱 三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱支比:내부가막히고금전의손모사가생긴다.<br/>
<br/>
<br/>
 申  형림(衡林 : 진직천관) 운이다.<br/>
<br/>
     직업상의 변화사 있게되고 직위가 호전되며 주식사의 기쁨이 있게된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
未宮我生:타인을돕게된다.<br/>
卯 柱 剋我 : 타인의 압박을 받는다.<br/>
〃 巳柱 合 :순리공동사가발현되고반목대립하며파재극처한다.<br/>
刑破<br/>
〃 未柱我生:타인을돕게된다.<br/>
<br/>
<br/>
 酉  입권(立券 : 사후쟁탈) 운이다.<br/>
<br/>
     화장도구․얼굴․장식물․결혼 등의 일이 있고 증권사가 순조하나 <br/>
     후에 경쟁한다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 未宮刑破:형상사파재실종사가있고남자가상하게된다. <br/>
內事 卯 柱 支合 : 순리징조 공동사가 발현 된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
<br/>
戌  역명(逆命 : 존비불화) 운이다.<br/>
<br/>
     암장된 재화를 얻고 상하불화가 되며 쟁송의 징조를 이룬다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
〃 未柱刑破: 형상사파재실종사가있고남자가상하게된다.<br/>
內事 卯 柱 支合 : 순리징조 공동사가 발현 된다.<br/>
〃 巳 柱 我生 : 타인을 돕게 된다.<br/>
〃 未 柱 刑破 : 형상사 파재 실종사가 있고 남자가 상하게 된다.<br/>
<br/>
<br/>
 亥  미소(微召 : 상희하승) 운이다.<br/>
<br/>
     의․식(衣․食)사가 있거나 병을 겪거나 하며 위는 길하나 아래는 <br/>
     불길하다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
<br/>
外事 未 宮 我剋 : 타인을 압박하게 된다.<br/>
內事 卯 柱 三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 巳 柱 支冲 : 중구경득 조상불경 순거역래 동요불안 원수지사가 생긴다.<br/>
〃 未柱我剋:타인을압박하게된다.<br/>
<br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/>
`,
`(백호)<br/>
子, 寅, 辰, 午, 申, 戌, 日<br/>
<br/>
<br/>
<br/>
 子  익수(溺水 : 음신조격) 운이다.<br/>
<br/>
     익사사 발생을 조심해야하고 쥐의 해(害)를 받게되며 원방에서 덕인 오게 된다.<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
<br/>
申 宮 三合:유동불체의단합사가있게된다.<br/>
子 柱 支比:내부가막히고금전의손모가있다.<br/>
寅 柱 生我:타인의도움을얻게된다.<br/>
辰 柱 三合:유동불체의단합사가있게된다.<br/>
午 柱 支冲 : 음양부조 남녀상쟁 모사변천 동요불안 원수지사가<br/>
생긴다.<br/>
申 柱 三合:유동불체의단합사가있게된다.<br/>
戌 柱 我剋:타인을압박하게된다. <br/>
<br/>
<br/>
 丑  재야(在野 : 손괴재물) 운이다.<br/>
<br/>
     오곡사․동전․석암류의 일에 관계되고 전토․가축의 손실이       있게 된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
     <br/>
外事 申 宮 生我:타인의도움을얻게된다.<br/>
內事 子 柱 支合 : 순리징조 공동사 발현 규방부부융합의 화합사가 생긴다.<br/>
〃 寅 柱 我剋:타인을압박하게된다.<br/>
〃 辰 柱 支破:폐퇴의파재극처사가있게된다.<br/>
〃 午 柱 支害:송쟁불리부부불화손해실주의근심사가기한다.<br/>
〃 申 柱 生我:타인의도움을받는다.<br/>
〃 戌 柱 支刑: 하극상배 거지무원 재부족의 쟁송사가 있고 여자가<br/>
상한다.<br/>
<br/>
<br/>
 寅  등산(登山 : 관길민흉) 운이다.<br/>
<br/>
     피(血)를 보는 일이 있게 된다. 생살권을 갖게 된다. <br/>
     가옥에 손처가 생긴다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 申 宮 日冲:사악작숭부부이심도로지사동요불안 이별사가 기한다.<br/>
內事 子 柱 我生:타인을돕게된다.<br/>
〃 寅 柱 支比:내부가막히고t=금전의손모가있게된다.<br/>
〃 辰 柱 剋我:타인의압박을받게된다.<br/>
〃 午 柱 三合: 문화광휘의단합사가있게되고외화내허한다.<br/>
〃 申 柱 支冲 : 사악작숭 부부이심 도로지사 동요불안 원수지사가<br/>
기한다.<br/>
〃 戌 柱 剋我:타인의압박을받는다.<br/>
<br/>
<br/>
<br/>
卯  임문(臨門 : 절상인구) 운이다.<br/>
<br/>
     사망사․검난․도적을 겪으며 가인이 손상하게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 申 宮 我剋:타인을압박하게된다.<br/>
內事 子 柱 支刑 : 존상불리 상하불화 무례지자 반목대립이 생긴다.<br/>
〃 寅 柱 支劫 : 형제자매에 의해 손모사가 기한다.<br/>
〃 辰 柱 支害 : 물사공허 만사상쟁 모사저해 손해실주 근심이 기한다.<br/>
〃 午 柱 支破 : 가정파폐의 파재 극처사가 생긴다.<br/>
〃 申 柱 我剋:타인을압박하게된다.<br/>
〃 戌 柱 支合 : 순리징조 송동사가 발현된다.<br/>
<br/>
<br/>
 辰  지인(至人 : 관재형흉) 운이다.<br/>
<br/>
     사람이나 가축의 살상사가 있고 관재 등의 흉악사가 있으며 <br/>
     밤에 외출을 하면 상한다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
內事 子 柱 三合:유동불체의단합사가있게된다.<br/>
〃 寅 柱 我剋:타인을압박하게된다.<br/>
〃 辰 柱 支比:내부가막히고금전손모사가있게된다.<br/>
〃 午 柱 我生: 타인을돕게된다.<br/>
〃 申 柱 生我:타인의도움을받는다.<br/>
〃 戌 柱 支冲 : 희비불명 하극상배 부하도망 동요불안 원수지사가<br/>
발생한다.<br/>
<br/>
<br/>
 <br/>
巳  분신(焚身 : 양화소멸) 운이다.<br/>
<br/>
    병의 허위사가 있고 치료된다. 재화를 당하여 소재길상으로 <br/>
    가정에 즐거움이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
<br/>
外事 合 刑破 :순리공동사반목대립사파재실종사를겪는다. <br/>
我剋:타인을압박하게된다.<br/>
支刑 : 도주관사 거지곤란 물사손해 반목대립하고 여자가 상한다.<br/>
生我:타인의도움을얻게된다.<br/>
支劫 : 형제자매에 의한 손모사가 있게 된다.<br/>
合刑 : 순리 공동사 반목대립사 파재 실종사를 겪는다. <br/>
破 生我:타인의도움을얻게된다.<br/>
<br/>
<br/>
<br/>
 午  분신(焚身 : 앙화소멸) 운이다.<br/>
<br/>
     상복사․병인․반목․손해를 겪으며 흉하나 복이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 內事 〃 〃 〃 〃 〃<br/>
申 宮 剋我:타인의압박을받는다.<br/>
子 柱 支冲 : 희비불명 남녀상쟁 부하도망 동요불안 이별사가<br/>
발생한다.<br/>
寅 柱 三合 : 문화광휘의 단합사가 있다.<br/>
辰 柱 生我:타인의도움을얻는다.<br/>
午 柱 支比:내부가막히고금전의손모사가있게된다. 申 柱 剋我:타인의압박을받게된다.<br/>
戌 柱 三合 : 문화광휘의 단합사가 있다. 외화내허 이다.<br/>
<br/>
<br/>
 <br/>
 未  재야(在野 : 손괴재물) 운이다.<br/>
<br/>
     금전의 재해, 종기의 고통을 겪고 가축의 손실이 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
內事 子 柱 支害 :<br/>
〃 寅 柱 我剋:타인을압박하게된다.<br/>
〃 辰 柱 支劫:형제자매에의해금전손모사가있게된다.<br/>
〃 午 柱 支合 : 순리징조 공동사의 발현이 있게 된다.<br/>
〃 申 柱 生我:타인의도움을얻게된다.<br/>
〃 戌 柱 刑破:형상사와파재극처사가생긴다.<br/>
<br/>
<br/>
<br/>
 申  형첩(衡牒 : 주유가음) 운이다.<br/>
<br/>
     군사상의 건을 겪고 외출하여 상해를 당하게 된다, <br/>
     쟁송이 발생하나 후에 즐겁다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 申 宮 日比:외교가막히고위탁받은일이지체된다.<br/>
內事 子 柱 三合:유동불체의단합사가있게된다.<br/>
〃 寅 柱 支冲 : 사악작숭 부부이심 도로지사 동요불안 원수지사가<br/>
생긴다<br/>
〃 辰 柱 我生:타인을돕게된다.<br/>
〃 午 柱 我剋:타인을압박하는일이있게된다.<br/>
〃 申 柱 支比:내부가막히고금전의손모사가있게된다<br/>
〃 戌 柱 我生:타인을돕게된다.<br/>
<br/>
<br/>
酉  임문(臨門 : 절상인구) 운이다.<br/>
<br/>
    상복사를 겪고 외출하여 철(鐵), 석(石)의 재앙을 만나며 상쟁하여      사람을 상하게 한다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 申 宮 日劫:동료붕우에의한금전손모사가있게된다. 內事 子 柱 支破:女.兒의재해와파재극처사가생긴다.<br/>
〃 寅 柱 剋我:타인의압박을받는다.<br/>
〃 辰 柱 支合:순리징조공동사의발현이있게된다.<br/>
〃 午 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 申 柱 支劫:형제자매에의한손모사가있게된다.<br/>
〃 戌 柱 支害 : 가정손상 재병발기 수하농락 손해실주의 근심이<br/>
생긴다.<br/>
<br/>
<br/>
<br/>
 戌  낙정(落穽 : 반화위복) 운이다.<br/>
<br/>
     부모를 상하고 제사에 연루되며 타인으로부터 중상을 받는 등       소식은 늦다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 內事 〃 〃 〃 〃 〃<br/>
申 宮 生我:타인의도움을얻는다.<br/>
子 柱 剋我:타인의압박을받는다.<br/>
寅 柱 我剋:타인을압박하게된다.<br/>
辰 柱 支冲 : 희비불명 하극상배 부하도망 동요불안 원수지사가<br/>
생긴다.<br/>
午 柱 三合: 문화광휘의단합사가있게된다.외화내허이다.<br/>
申 柱 生我:타인의도움을얻게된다.<br/>
戌 柱 支比:내부가막히고금전의손모사가있게된다.<br/>
<br/>
<br/>
<br/>
<br/>
 亥  익수(溺水 : 음신조체) 운이다.<br/>
<br/>
     일에 지장이 생기고 달성이 안 되며 가정에 임신사가 있게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 申 宮 日害 : 선저후성 모사불완 제사불성의 손해실주 근심이<br/>
생긴다.<br/>
內事 子 柱 支劫:형제자매에의해손모사가있게된다.<br/>
〃 寅 柱 合破:순리공동사가있고패후재성하게된다.<br/>
〃 辰 柱 我剋:타인을압박하게된다.<br/>
〃 午 柱 剋我:타인의압박을받는다.<br/>
〃 申 柱 支害 : 선저후성 모사불완 제사불성의 손해실주 근심이<br/>
생긴다.<br/>
〃 戌 柱 我剋:타인을압박하게된다.<br/>
<br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/>
`,
`(태음)<br/>
丑, 卯, 巳, 未, 酉, 亥, 日<br/>
<br/>
<br/>
<br/>
 子  수겸(垂傔 : 첩부상회) 운이다.<br/>
<br/>
     문장건과 부녀자 도주건을 겪고 부인과의 질투, 모욕건이 있다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 戌 宮我剋 : 타인을 압박하게 된다.<br/>
內事 丑 柱 支合 : 순리징조와 공동사가 발현 된다.<br/>
〃 卯 柱 支刑 : 존상불리 상하불화 무례지자 반목대립하고 여자가 상한다.<br/>
〃 巳柱剋我:타인의압박을받는다.<br/>
〃 未柱支害:관사구설경영지체손해실주근심이기한다.<br/>
〃 酉柱支破:女.兒의재해파재극처사가기한다.<br/>
〃 亥柱支劫:형제자매에의한금전손모사가있게된다.<br/>
<br/>
 丑  입내(入內 : 존비상몽) 운이다.<br/>
<br/>
     소․동전 그릇류 건이 있게되고 상하가 모여 연구회․제사등을       지낸다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 戌 宮 日刑 : 하극상배 거지무원 재부족하고 남자가 상한다. 內事 丑 柱 支比 : 내부가 막히고 금전의 손모사가 생긴다.<br/>
〃 卯柱我剋:타인을압박하게된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未 柱支冲 : 모망무성 골육상배 동요불안 원수 지사가 기한다.<br/>
〃 酉柱三合:개혁적단합사가있게되고별리한다.<br/>
〃 亥柱剋我:타인의압박을받게된다.<br/>
<br/>
<br/>
 寅  선족(跣足 : 재물암동) 운이다.<br/>
<br/>
     벌재․보석․금은사에 좋고 개혁․영전․은사의 기쁨을 얻게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 戌宮剋我:타인의압박을받게된다. 內事 丑柱剋我:타인의압박을받게된다.<br/>
〃 卯柱支劫:형제자매에의해손모사가있게된다.<br/>
〃 巳 柱 支刑 : 도주관사 거지곤란 물사손해 반목대립하고 여자가 상한다.<br/>
〃 未柱剋我:타인의압박을받게된다.<br/>
〃 酉柱我剋:타인을압박하게된다.<br/>
〃 亥柱合破:순리공동사가있게되고패후재성한다.<br/>
<br/>
 卯  미행(微行 : 사행불량) 운이다.<br/>
<br/>
     사물이 암음(暗陰)하나 활동하게 되고 가정불화가 있게 된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 戌宮日合:순리징조가있고공동사가발현된다. 內事 丑柱剋我:타인의압박을받게된다.<br/>
〃 卯柱支比:내부가막히고금전의손모사가있게된다.<br/>
〃 巳柱生我:타인의도움을받게된다.<br/>
〃 未 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 酉 柱 支冲 : 문호개변 분리실탈 가정개동 동요불안 원수지사가<br/>
생긴다.<br/>
〃 亥 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
<br/>
<br/>
<br/>
 辰  조정(造庭 : 총애쟁탈) 운이다.<br/>
<br/>
     불화합의 쟁투사가 있게 되고 부인은 낙태로 몸이 상하며 <br/>
     재․문서가 움직이게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 戌 宮 日冲 : 희비불명 하극상배 부하도망 동요불안 이별지사가 있게 된다.<br/>
內事 丑 柱 支破 : 퇴폐의 파재 극처사가 기한다.<br/>
〃 卯 柱 支害 : 물사공허 만사상쟁 모사저해 손해실주 근심이 기한다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未 柱支劫 : 형제자매가 관련되어 손모사가 있게 된다.<br/>
〃 酉柱支合:순리공동사가발현된다.<br/>
〃 亥柱剋我:타인의압박을받게된다.<br/>
<br/>
<br/>
 巳  휴침(休枕 : 구설경변) 운이다.<br/>
<br/>
     부인의 구설이 있고 근심과 놀램, 도난사․허위사․음모사를 <br/>
     겪는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 戌宮生我:타인의도움을받게된다. 內事 丑柱生我:타인의도움을받게된다.<br/>
〃 卯柱我生:타인을돕게된다.<br/>
〃 巳柱支比:내부가막히고금전의손모사가있게된다.<br/>
〃 未柱生我:타인의도움을받게된다.<br/>
〃 酉柱三合:개혁적단합사가있고별리한다.<br/>
〃 亥 柱 支冲 : 중구경득 조상불경 순거역래 동요불안 원수지사가<br/>
생긴다.<br/>
<br/>
<br/>
<br/>
 午  탈건(脫巾 : 불의반공) 운이다.<br/>
<br/>
     금전과 문서사로 암약한다, 근심과 통한다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 戌 宮 三合 : 문화광휘의 단합사가 있게된다. 내허외화 이다. <br/>
內事 丑 柱 支害 : 송사불리 부부불화 손해실주의 근심사가 기한다.<br/>
〃 卯 柱 支破 : 가정폐파의 파재 극처사가 생긴다.<br/>
〃 巳柱支劫:형제자매로인해금전의손모사가있게된다.<br/>
〃 未柱支合:순리징조공동사발현이있게된다.<br/>
〃 酉柱剋我:타인의압박을받게된다.<br/>
〃 亥柱我剋:타인을압박하게된다.<br/>
<br/>
<br/>
 未  관서(觀書 : 문화풍아) 운이다.<br/>
<br/>
     문화․풍아․서적․결혼사를 겪는다, 냉정하여야 한다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 戌宮刑破:형상사가있게되고파재실종사를겪는다.<br/>
內事 丑 柱 支冲 : 모망무성 골육상배 동요불안 원수지사가 생긴다.<br/>
〃 卯 柱三合 : 곤직번잡의 단합사가 있게 된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱支比:내부가막히고금전의손모사가있게된다.<br/>
〃 酉柱生我:타인의도움을얻게된다.<br/>
〃 亥柱剋我:타인을압박하게된다.<br/>
<br/>
<br/>
<br/>
<br/>
 申  집정(執政 : 득지행권) 운이다.<br/>
<br/>
     군인사․사망건을 겪고 군자는 좋으나 하급인은 나쁘다고 본다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 戌宮我生:타인을돕게된다. 內事 丑柱我生:타인을돕게된다.<br/>
〃 卯柱剋我:타인의압박을받는다.<br/>
〃 巳柱 合 :순리공동사가있고반목송사하며파재극처하게된다.<br/>
刑破<br/>
〃 未柱我生:타인을돕게된다.<br/>
〃 酉柱支劫:형제자매로인한금전의손모사가있게된다.<br/>
〃 亥 柱 支害 : 선저후성 모사불완 손해실주의 근심사가 생긴다.<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
 酉  폐호(閉戶 : 가택안녕) 운이다.<br/>
<br/>
    거울․재화사를 겪고 도검․암매․사용인․첩 등의 일이 생기며       저서․서적건이 근심 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 戌 宮 日害 : 가정손상 암중불비 수하농락 손해실주의 근심사가<br/>
생긴다.<br/>
內事 丑柱三合:개혁적단합사가생기고별리한다.<br/>
〃 卯 柱 支冲 : 문호개변 분리실탈 가정개동 동요불안 원수지사가 생긴다.<br/>
〃 巳柱三合:개혁적단합사가생기고별리한다.<br/>
〃 未柱我生:타인을돕게된다.<br/>
〃 酉柱支比:내부가막히고금전의손모사가있게된다.<br/>
〃 亥柱生我:타인의도움을얻게된다.<br/>
<br/>
<br/>
 戌  피찰(被察 : 음인암손) 운이다.<br/>
<br/>
     군인․군속건이 있고 약물․공무사를 겪으며 부녀자 색정건이        생긴다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 戌宮日比:외교가막히고위탁받은일도지체된다.<br/>
內事 丑 柱 支刑 : 하극상배 거지무원 재부족의 형상사가 있게 되고<br/>
여자가 상한다.<br/>
〃 卯柱支合:순리징조공동사의발현이있게된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱刑破:형상사가있게되고파재극처사가생긴다.<br/>
〃 酉 柱 支害 : 가정손상 재병발기 손해실주의 근심사가 생긴다.<br/>
〃 亥柱剋我:타인의압박을받는다.<br/>
<br/>
<br/>
 亥  나형(裸形 : 도적우의) 운이다.<br/>
<br/>
     근심․구설․질병․도난사가 있고 반대의 조가 있으며 <br/>
     근심걱정이 생긴다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 戌 宮 我剋 : 타인을 압박하게 된다. 內事 丑 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 卯 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 巳 柱 支冲 : 중구경득 조상불경 순거역래 동요불안 원수지사가 생긴다.<br/>
〃 未柱我剋:타인을압박하게된다.<br/>
〃 酉柱我生:타인을돕게된다.<br/>
〃 亥柱支比:내부가막히고금전의손모사가생긴다.<br/>
<br/><br/><br/><br/><br/><br/><br/><br/>
`,
`(천후)<br/>
子, 寅, 辰, 午, 申, 戌, 日<br/>
<br/>
<br/>
<br/>
<br/>
 子  수윤(守閏 : 동지함의) 운이다.<br/>
<br/>
     윗사람의 결혼건, 도적건이 있고 주류사나 신앙건을 겪는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 內事 〃 〃<br/>
〃<br/>
〃 〃<br/>
亥 宮 日劫:동료친구에의해금전의손모사가있게된다. 子 柱 支比:내부가막히고금전의손모사가있게된다. 寅 柱 生我:타인의도움을얻게된다.<br/>
辰 柱 三合:유동불체의단합사가있게된다.<br/>
午 柱 支冲 : 음양대립 남녀상쟁 모사변천 동요불안 원수지사가 생긴다.<br/>
申 柱 三合:유동불체의단합사가있게된다. 戌 柱 我剋:타인을압박하게된다.<br/>
<br/>
<br/>
<br/>
 丑  수규(踰窺 : 송구경황) 운이다.<br/>
<br/>
     눈병․신도․결혼․전택 등의 일을 겪는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 亥 宮 剋我:타인의압박을받게된다.<br/>
內事 子 柱 支合:순리공동사와규방부부융합의화합사가있다.<br/>
〃 寅 柱 我剋:타인을압박하게된다.<br/>
〃 辰 柱 支破:폐퇴의파재극처사가있게된다.<br/>
〃 午 柱 支害 : 송사불리 부부불화 손해실주의 근심사가 생긴다.<br/>
〃 申 柱 生我:타인의도움을얻게된다.<br/>
〃 戌 柱 支刑 : 하극상배 거지무원 재부족의 형상사가 기하고<br/>
여자가 상한다.<br/>
<br/>
<br/>
<br/>
 寅  이발(理髮 : 우유한가) 운이다.<br/>
<br/>
     두발사․화장사․용자․용모의 건을 겪고 남녀교제, <br/>
     혼인문제가 생긴다.<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 內事 〃 〃 〃 〃 〃<br/>
亥 宮 合破 : 순리징조 공동사 발현이 있게되고 파재 실종사가 생긴다.<br/>
子 柱 我生:타인을돕게된다.<br/>
寅 柱 支比:내부가막히고금전의손모사가있게된다.<br/>
辰 柱 剋我:타인의압박을받게된다.<br/>
午 柱 三合 : 문화광휘의단합사가 있게 된다. 외화내허 이다.<br/>
申 柱 支冲 : 사악작숭 부부이심 도로지사 동요불안 원수지사가<br/>
생긴다.<br/>
戌 柱 剋我:타인의압박을받게된다.<br/>
<br/>
<br/>
<br/>
 卯  임문(臨門 : 간음무도) 운이다.<br/>
<br/>
     가정에 간음사가 벌어지고 무도하며 나쁜 기운이 뻗친다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
內事 子 柱 支刑:존상불리상하불화무례지가반목대립이있고 여자가 상한다.<br/>
〃 寅 柱 支劫:형제자매가관련하여금전의손모사가있게된다.<br/>
〃 辰 柱 支害 : 물사공허 만사상쟁 무사저해 손해실주의 근심이 있다.<br/>
〃 午 柱 支破 : 가정파폐의 파재 극처사가 생긴다.<br/>
〃 申 柱 我剋:타인을압박하게된다.<br/>
〃 戌 柱 支合:순리공동사가발현된다 - 336 -<br/>
<br/>
<br/>
<br/>
 辰  훼장(毁粧 : 비곡차욕) 운이다.<br/>
<br/>
     채소나 물에 빠지는 일로 차욕을 당하거나 가정에 악질의 <br/>
     근심이 기한다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
亥 宮 剋我:타인의압박을당한다.<br/>
子 柱 三合:유동불체의단합사가있게된다.<br/>
寅 柱 我剋:타인을압박하게된다.<br/>
辰 柱 支比:내부가막히고금전의손모사가있게된다.<br/>
午 柱 我生:타인을돕게된다.<br/>
申 柱 生我:타인의도움을받게된다.<br/>
戌 柱 支冲 : 희비불명 하극상배 부하도망 동요불안 원수지사가<br/>
外事 內事 〃 〃 〃 〃<br/>
<br/>
<br/>
<br/>
<br/>
 巳  나체(裸體 : 비곡차욕) 운이다.<br/>
<br/>
     조행상 불량하고 간음사가 있으며 수치스럽게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 亥 宮 內事 子 柱<br/>
〃 寅柱<br/>
〃 辰柱<br/>
〃 午柱<br/>
〃 申 柱<br/>
〃 戌柱<br/>
日冲 : 중구경득 조상불경 순거역래 동요불안 이별사가 있게 된다.<br/>
我剋:타인을압박하게된다.<br/>
支刑 : 도주관사 거지곤란 물사손해 반목대립하고 여자가 상한다.<br/>
生我:타인의도움을얻게된다.<br/>
支劫 : 형제자매에 의한 금전의 손모사가 있게 된다.<br/>
合 :순리공동사반목대립송사파재극처사가겹친다. <br/>
刑破 生我:타인의도움을얻게된다.<br/>
<br/>
<br/>
<br/>
<br/>
 午  복침(伏枕 : 탄식신음) 운이다.<br/>
<br/>
     부인사를 겪고 신음 탄식하며 임산부의 병이 생긴다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 內事 〃 〃 〃 〃 〃<br/>
亥 宮 我剋:타인을압박하게된다.<br/>
子 柱 支冲 : 음양대립 남녀상쟁 모사변천 동요불안 원수지사가<br/>
생긴다.<br/>
寅 柱 三合:문화광휘의단합사가있고외화내허이다.<br/>
辰 柱 生我:타인의도움을얻게된다.<br/>
午 柱 支比:내부가막히고금전의손모사가있게된다. 申 柱 剋我:타인의압박을받게된다.<br/>
戌 柱 三合:문화광휘의단합사가있고외화내허이다.<br/>
<br/>
<br/>
<br/>
 未  목욕(沐浴 : 송구경황) 운이다.<br/>
<br/>
     사물이 의혹되고 두려움을 겪으며 의복건으로 부인이 근심을       하게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 亥 宮 剋我:타인의압박을받게된다.<br/>
內事 子 柱 支害 : 관사구설 경영지체 손해실주의 근심이 생긴다.<br/>
〃 寅 柱 我剋:타인을압박하게된다.<br/>
〃 辰 柱 支劫:형제자매에의한금전의손모사가있게된다.<br/>
〃 午 柱 支合 : 순리징조 공동사의발현이 있게 된다.<br/>
〃 申 柱 生我:타인의도움을얻게된다.<br/>
〃 戌 柱 刑破:형상사가있게되고파재극처사가생긴다.<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
 申  수용(修容 : 우유한가) 운이다.<br/>
<br/>
     놀랄 일이 생기고 용자를 단정히 하면 해(害)는 없게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
<br/>
外事 內事 〃 〃 〃 〃 〃<br/>
亥 宮 日害 : 선저후성 모사불완 손해실주의 근심이 생긴다. 子 柱 三合:유동불체의단합사가있게된다.<br/>
寅 柱 支冲 : 사악작숭 부부이심 동료지사 동요불안 원수지사가 생긴다.<br/>
辰 柱 我生:타인을돕게된다.<br/>
午 柱 我剋:타인을압박하게된다.<br/>
申 柱 支比:내부가막히고금전의손모사가있게된다. <br/>
戌 柱 我生:타인을돕게된다.<br/>
<br/>
<br/>
<br/>
<br/>
 酉  기호(徛戶 : 간음무도) 운이다.<br/>
<br/>
     가정내에 색정․부정건이 생기고 자중하지 못하면 재화를 <br/>
     당하게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 亥 宮 生我:타인의도움을얻게된다.<br/>
內事 子 柱 支破:女.兒근심과파재극처사가있게된다.<br/>
〃 寅 柱 剋我:타인의압박을받게된다.<br/>
〃 辰 柱 支合:순리징조공동사가발현된다.<br/>
〃 午 柱 我剋:타인을압박하게된다.<br/>
〃 申 柱 支劫:형제자매에의한금전의손모사가있게된다.<br/>
〃 戌 柱 支害 : 가정손상 재병발기 수하농락 손해실주의 근심이 있다<br/>
<br/>
<br/>
戌  장위(褰車韋  : 탄식신음) 운이다.<br/>
<br/>
<br/>
     가정내 색정사를 겪고 실물․관사․의약건이 발생하게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 內事 〃<br/>
〃 〃<br/>
〃 〃<br/>
亥 宮 剋我:타인의압박을받게된다. 子 柱 剋我:타인의압박을받게된다.<br/>
寅 柱 我剋:타인을압박하게된다.<br/>
辰 柱 支冲 : 희비불명 하극상배 부하도망 동요불안 원수지사가<br/>
생긴다.<br/>
午 柱 三合:문화광휘의단합사가있고외화내허이다.<br/>
申 柱 生我:타인의도움을얻게된다.<br/>
戌 柱 支比:내부가막히고금전의손모사가있게된다.<br/>
<br/>
<br/>
<br/>
<br/>
 亥  치사(治事 : 동지함의) 운이다.<br/>
<br/>
     가정과 신변에 기쁨이 있고 매사 속히 진행한 후 조용히 하는       것이 좋다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 亥 宮 日比:외교가막히고위탁받은일도지체된다. <br/>
內事 子 柱 支劫:형제자매에의한금전의손모사가있게된다.<br/>
〃 寅 柱 合破:순리공동사가있게되고패후재성한다.<br/>
〃 辰 柱 我剋:타인의압박을받게된다.<br/>
〃 午 柱 剋我:타인을압박하게된다.<br/>
〃 申 柱 支害 : 선저후성 모사불완 손해실주의 근심사가 생긴다.<br/>
〃 戌 柱 我剋:타인을압박하게된다.<br/>
<br/>
<br/>
<br/>
`,
`(현무)<br/>
丑, 卯, 巳, 未, 酉, 亥, 日<br/>
<br/>
<br/>
<br/>
 子  산발(散髮 : 경의불녕) 운이다.<br/>
<br/>
     출입사가 많고 도난이 있으며 분실․의혹을 겪는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 丑 宮 日合 : 순리징조 공동사발현이 있고 규방부부융합의 화합사가 있게 된다.<br/>
內事 丑 柱 支合 : 순리징조 공동사발현이 있고 규방부부융합의 화합사가 있게 된다.<br/>
〃 卯 柱 支刑 : 존상불리 상하불화 무례지자 반목대립하고 여자가 상한다.<br/>
〃 巳柱剋我:타인으로부터압박을겪는다.<br/>
〃 未 柱支害 : 관사구설 경영지체 손해 실주의 근심사를 겪는다.<br/>
〃 酉柱支破:女.兒재해및파재극처사를겪는다.<br/>
〃 亥柱支劫:형제자매에의한금전손모사가있게된다.<br/>
<br/>
<br/>
 丑  승당(昇堂 : 사편재물) 운이다.
<br/>
     도난․분실․사기당하는 등의 주의를 요한다, 부실한 운이다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 丑宮日比:외교가막히고위탁받은일도지체된다. 內事 丑柱支比:내부기막히고금전의손모사가있게된다.<br/>
〃 卯柱我剋:타인을압박하게된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未 柱 支冲 : 모망무성 골육상배 동요불안 원수지사가 있게 된다.<br/>
〃 酉柱三合:개혁적단합사가있고별리한다.<br/>
〃 亥柱剋我:타인의압박을받게된다.<br/>
<br/>
<br/>
<br/>
 寅  입림(入林 : 포난심획) 운이다.<br/>
<br/>
     흑마(黑馬)․죽목․옷․문관사(文官事)를 겪는다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 丑 宮 剋我 : 타인의 압박을 받는다. 內事 丑 柱 剋我 : 타인의 압박을 받는다.<br/>
〃 卯柱支劫:형제자매에의한금전손모사가있게된다.<br/>
〃 巳 柱 支刑 : 도주관사 거지곤란 물사손해 반목쟁송하고 여자가 상한다.<br/>
〃 未柱剋我:타인의압박을받는다.<br/>
〃 酉柱我剋:타인을압박하게된다.<br/>
〃 亥柱合破:순리공동사가있고패후재성한다.<br/>
<br/>
<br/>
 卯  규호(竅戶 : 제사불리) 운이다.<br/>
<br/>
     눈병이 발생하고 도망인이 생기며 가정내 도둑이 있다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 丑宮剋我:타인의압박을받게된다. 內事 丑柱剋我:타인의압박을받게된다.<br/>
〃 卯柱支比:내부사가막히고금전의손모사가있게된다.<br/>
〃 巳柱生我:타인의도움을얻게된다.<br/>
〃 未 柱 三合 : 곡직번잡의단합사가 있게 된다.<br/>
〃 酉 柱 支冲 : 문호개변 분리실탈 가정개동 동요불안 원수지사가 생긴다<br/>
〃 亥 柱 三合 : 곡직번잡의단합사가 있게 된다.<br/>
<br/>

<br/>
 辰  실로(失路 : 도적소실) 운이다.<br/>
     가축류․도기류건을 겪으며 관사가 발생한다, 노비가 도주하게 된다. <br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 丑宮日破:폐퇴적파재실종사를겪게된다.<br/>
 內事 丑柱支破:파재극처사가있게된다.<br/>
〃 卯 柱 支害 : 물사공허 만사상쟁 모사저해 손해실주 근심이 생긴다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱支劫:형제자매에의한금전의손모사가있게된다.<br/>
〃 酉柱支合:순리징조가있고공동사가발현된다.<br/>
〃 亥柱剋我:타인의압박을받게된다. <br/>
<br/>
<br/>

<br/>
 巳  반고(反顧 : 허획경구) 운이다.<br/>
<br/>
     가정내 불안․불행사가 있게 되고 안녕치가 못하게 된다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 丑宮生我:타인의도움을받게된다. 內事 丑柱生我:타인의도움을받게된다.<br/>
〃 卯柱我生:타인을돕게된다.<br/>
〃 巳柱支比:내부가막히고금전의손모사가있게된다.<br/>
〃 未柱生我: 타인의도움을받게된다.<br/>
〃 酉柱三合:개혁적단합사가있게되고별리한다.<br/><br/>
〃 亥 柱 支冲 : 중구경득 조상불경 동요불안 원수지사가 생긴다.<br/>
<br/>
<br/>
 午  절로(截路 : 불의반공) 운이다.<br/>
<br/>
     쟁투사를 겪고 부모의 신변에 실패사가 있으며 도난․분실사가 있으나 피해는 적다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 丑 宮 日害 : 송사불리 부부불화 손해실주의 근심이 생긴다. 內事 丑 柱 支害 : 송사불리 부부불화 손해실주의 근심이 생긴다.<br/>
〃 卯 柱 支破 : 가정패파의 파재 극처사가 생긴다.<br/>
〃 巳柱支劫:형제자매에의해금전손모사가있게된다.<br/>
〃 未柱支合:순리징조공동사의발현이있게된다.<br/>
〃 酉 柱剋我 : 타인으로부터 압박을 받게 된다.<br/>
〃 亥柱我剋:타인을압박하게된다.<br/>

<br/>
<br/>
<br/>
 未  불성(不成 : 군자지경) 운이다.<br/>
<br/>
     신들린 사교건을 겪고  전직의 뜻도 생기며 만사 신중해야 한다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 丑 宮 日冲 : 모망무성 골육상배 동요불안 이별지사가 생긴다. <br/>
內事 丑 柱 支冲 : 모망무성 골육상배 동요불안 원수지사가 생긴다.<br/>
〃 卯 柱 三合 : 곡직번잡의 단합사가 생긴다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱支比:내부가막히고금전의손모사가있게된다.
〃 酉柱生我:타인의도움을받게된다.<br/>
〃 亥柱剋我:타인의압박을받게된다.<br/>
<br/>
<br/>
<br/>
 申  절족(折足 : 정가금획) 운이다.<br/>
<br/>
     도적건․군인 군속사․또는 사망사를 겪는다, 도적의 흉도 있게       된다,<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
<br/>
外事 丑宮我生:타인을돕게된다. <br/>
內事 丑柱我生:타인을돕게된다.<br/>
〃 卯柱剋我:타인의압박을받게된다.
〃 巳柱 合 :순리공동사반목송사파재극처사가생긴다.<br/>
刑破<br/>
〃 未柱我生:타인을돕게된다.<br/>
〃 酉柱支劫:형제자매에의한금전의손모사가있게된다.<br/>
〃 亥 柱 支害 : 선저후성 모사불완 손해실주 근심이 생긴다.<br/>
<br/>
<br/>
<br/>
 酉  발검(拔劍 : 불의반공) 운이다.<br/>
<br/>
     관재가 있고 옛날 감정에 의한 상해사가 발생하며 곤란하다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>

外事 丑宮三合:개혁적단합사가있게되고별리한다. <br/>
內事 丑柱三合:개혁적단합사가있게되고별리한다.<br/>
〃 卯柱我剋:타인을압박하게된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未 柱 支冲 : 모망무성 골육상배 동요블안 원수지사가 생긴다.<br/>
〃 酉柱支比:내부가막히고금전의손모사가있게된다.<br/>
〃 亥柱生我:타인의도움을받게된다.<br/>
<br/>
 戌  조수(遭囚 : 정가금획) 운이다.<br/>

     도난 분실사가 있고 도둑은 잡힌다.<br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
外事 丑 宮 日刑 : 하극상배 거지무원 재부족의 반목대립하고<br/>
남자가 상한다.<br/>
內事 丑 柱 支刑 : 하극상배 거지무원 재부족의 반목대립하고 여자가 상한다.<br/>
〃 卯柱支合:순리징조공동사가발현된다.<br/>
〃 巳柱我生:타인을돕게된다.<br/>
〃 未柱刑破:형상사가있고파재극처사가있게된다.<br/>
〃 酉 柱 支害 : 가정손상 재병발기 수하농락 손해실주 근심이 있다.<br/>
〃 亥柱剋我:타인의압박을받는다.<br/>
<br/><br/>

 亥  복장(伏藏 : 적난포획) 운이다.<br/>
<br/>
     대건축사에 관계되고 지하에 숨고 관을 손상하게 된다. <br/>
<br/>
     다음 기궁과 지주(支柱)별로 그 사상을 보탠다.<br/>
     <br/>
外事 丑 宮 我剋 : 타인을 압박하게 된다. 內事 丑 柱 我剋 : 타인을 압박하게 된다.<br/>
〃 卯 柱三合 : 곡직번잡의 단합사가 있게 된다.<br/>
〃 巳 柱 支冲 : 중구경득 조상불경 동요불안 원수지사가 생긴다.<br/>
〃 未柱我剋:타인을압박하게된다.<br/>
〃 酉柱我生:타인을돕게된다.<br/>
〃 亥柱支比:내부가막히고금전의손모사가있게된다.<br/>
<br/><br/><br/><br/><br/><br/><br/><br/>
`,
]

const VERSION20220207_1_1 = [
     `@@1@@甲子 @@2@@자 @@3@@자존@@4@@부모, 문서, 후원사 @@5@@陰10,11월 7,8월 本時판단 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라. 기이 하게 풀린다 외사에 타인으로부터 도움을 받고자 하는 일이 있고 내사 집안은 모두 막혀 있다`,
     `@@1@@甲子 @@2@@축 @@3@@지위@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 本時판단 사안 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 타인의 압박을 받는 일이 있고 내사 집안에 부부융합의 화합사가 있다`,
     `@@1@@甲子 @@2@@인 @@3@@이사, 이동@@4@@직녹, 명예사, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직,녹위자를 활용하되 서둘지 말라. 외사에 명예로운 일로 원행이동사가 있다.  외사에 외교가 막혀있고 타인에게 의뢰한 일도 지체되어 있으며 내사 집안에 타인을 돕고자하는 일이 있다. 원행이동사가 있다  `,
     `@@1@@甲子 @@2@@묘 @@3@@건강@@4@@형제, 동료, 붕우, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직,녹위자를 활용하되 서둘지 말라. 외사에 명예로운 일로 원행이동사가 있다.  외사에 명예로운 일로 원행이동사가 있다. 외사에 금전의 소모가 많고 내사 집안에 상하 무례 반목사가 있다`,
     `@@1@@甲子 @@2@@진 @@3@@신앙@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 계속 이어질 일의 단합사가 있다`,
     `@@1@@甲子 @@2@@사 @@3@@강제, 급속@@4@@ 자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다  외사에 전진후퇴가 난처한 반목대립관사가 있다 일이 급하고 남자가 상한다. 출행이 바뀌고 구설의혹 사가 있다. 사안이 급박하고 강제적 손실사가 우려 된다. 본 사안은 본래 정중에 있다 `,
     `@@1@@甲子 @@2@@오 @@3@@재해,@@4@@자손, 박관, 극부사  @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다  외사에 실속 없는 외화내허의 단합사가 있으나 내사 집안에 동요불안, 모사변천, 남녀상쟁, 분산사가 있다`,
     `@@1@@甲子 @@2@@미 @@3@@숙명,@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 타인을 압박하는 일이 있고 내사 집안에 관사구설과 암중 재해로 손해가 있으며 가출사가 있게 된다`,
     `@@1@@甲子 @@2@@신 @@3@@변동,@@4@@  직업, 관직, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 本時판단 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 부부이심으로 동요불안, 이별사가 있고 집안 에 멈추지 않고 계속될 단합사가 있다`,
     `@@1@@甲子 @@2@@유 @@3@@색정, 간음@@4@@관직, 애인, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다  색정사가 있는 자다. 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 여자 또는 자식의 재해가 있고 파재 극처사가 있다  `,
     `@@1@@甲子 @@2@@술 @@3@@공허@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 부인과 해로를 못하고 여자는 남자를 천하게 만든다. `,
     `@@1@@甲子 @@2@@해 @@3@@망신@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@원행, 이동사가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로의, 복, 수련, 종교에 귀의해야만 자립한다. 부모, 후원, 문서사 재해가 있다 `,
     `@@1@@乙丑 @@2@@자 @@3@@건강@@4@@부모, 후원, 문서사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라. 기이 하게 풀린다 외사에 타인을 압박하는 일이 있고 내사 집안에 부  부융합의 화합사가 있다`,
     `@@1@@乙丑 @@2@@축 @@3@@신앙,@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 파재 극처사가 잇고 내사 집안이 모두 막혀 있다`,
     `@@1@@乙丑 @@2@@인 @@3@@강제,@@4@@ 형제, 붕우, 근전손해, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 일이 된다. 여자를 활용하라. 외, 내, 집안 모두 타인으로부터 압박받는 일이 있다. 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@乙丑 @@2@@묘 @@3@@재해@@4@@직녹, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@직, 녹을 구한다. 직, 녹, 위자를 활용하되 서둘지 말라. 내외로 피해를 당하고 있다. 외사에 공허무력, 만사상쟁사로 손해근심이 있고 내사 집안에 타인으로부터 압박받는 일이 있다.`,
     `@@1@@乙丑 @@2@@진 @@3@@숙명@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 외교가 막혀있고 타인에게 의뢰한 일도 지체되고 있으며 내사 집안에 담,문 묘각의 퇴패로 파재 극처사가 있다. 본 사안은 본래 인의에 있다`,
     `@@1@@乙丑 @@2@@사 @@3@@변동@@4@@  자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다 외, 내, 집안 모두에 상황을 개신시키려는 뜻이 있고 타인으로부터 도움을 얻고자하는 일이 있다`,
     `@@1@@乙丑 @@2@@오 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다  외사에 타인으로부터 도움을 얻고자하는 일이 있고 내사 집안에 부부 불화, 관송사가 있다. 색정사가 있는 자다.`,
     `@@1@@乙丑 @@2@@미 @@3@@공허@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 금전소모가 많고 내사 집안에 모망사 불성, 골육상배의 동요 불안사가 있다`,
     `@@1@@乙丑 @@2@@신 @@3@@망신@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외, 내사 집안 모두 타인에게 은혜를 베푸는 일이 있다`,
     `@@1@@乙丑 @@2@@유 @@3@@자존@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다  외사에 친구 동료와 화합사가 있고 내사 집안에 상황을 개신시키는 단합사가 있다`,
     `@@1@@乙丑 @@2@@술 @@3@@지위@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 부인과 해로를 못하고 여자는 남자를 천하게 만든다. `,
     `@@1@@乙丑 @@2@@해 @@3@@이사, 이동@@4@@부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@원행, 이동사가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로의, 복, 수련, 종교에 귀의해야만 자립한다. 부모, 후원, 문서사 재해가 있다 `,
     `@@1@@丙寅 @@2@@자 @@3@@재해@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@丙寅 @@2@@축 @@3@@신앙@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다 외사에 개신시키는 일이 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@丙寅 @@2@@인 @@3@@변동@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라 외사에 전진, 후퇴가 곤란한 반목대립사가 있고 관송사가 있다. 내사 집안이 막혀 있다`,
     `@@1@@丙寅 @@2@@묘 @@3@@색정, 간음@@4@@문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 색정사가 있는 자다. 외사에 내밀한 후원을 얻고자 하고 내사 집안에 금전의 손모가 많다. 남자는 여자에 갈등하고 여자는 남자의 번민이 있다. 본 사안은 본래 권위에 있다  `,
     `@@1@@丙寅 @@2@@진 @@3@@공허@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 도움을 얻고자하는 일이 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@丙寅 @@2@@사 @@3@@망신@@4@@직녹, 명예사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라.  현재 외사에 외교가 막혀 있고 타인에 의뢰한 일이 지체되어 있고 내부 집안에 전진 후퇴가 곤란한 관송사로 반목 대립 한다`,
     `@@1@@丙寅 @@2@@오 @@3@@자존@@4@@형제, 붕우, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에는 타인을 돕는 일이 있고 내사 집안에서는 타인으로부터 압박받는 일이 있다`,
     `@@1@@丙寅 @@2@@미 @@3@@지위@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에는 타인을 돕는 일이 있고 내사 집안에서는 타인으로부터 압박받는 일이 있다`,
     `@@1@@丙寅 @@2@@신 @@3@@이사 이동@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 원행이동사가 있다. 외사에 은혜배반, 상하 불손의 반목 대립사가 있고 내사 집안에 부부이심으로 동요불안사가 있다`,
     `@@1@@丙寅 @@2@@유 @@3@@건강@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 상황을 개신시키는 단합사가 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@丙寅 @@2@@술 @@3@@신앙@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남녀 모두 자식의 재해가 있게 되며 남자는 불명예를 안고 여자는 잘 속는다.  `,
     `@@1@@丙寅 @@2@@해 @@3@@강제 급속@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 직업 갖기 어렵고 여자는 남편 재해를 당하며 일부종사가 어렵다. 상황이 급박하고, 강제적 손실사가 우려된다. `,
     `@@1@@丁卯 @@2@@자 @@3@@색정간음@@4@@  남편, 직업, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다 색정사가 있는 자다, 관사구설, 암중재해사가 있다`,
     `@@1@@丁卯 @@2@@축 @@3@@공허자손@@4@@  직업, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다 외사에 원하는 일이 안 되어 골육지간의 배반사로 원수가 되고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@丁卯 @@2@@인 @@3@@망신@@4@@  부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라 외사에 도움 받을 일이 있고 내사 집안에 금전손모가 많고 남자는 여자와 갈등하고 여자는 남자에 번민 한다. 본 사안은 본래 음욕에 있다.`,
     `@@1@@丁卯 @@2@@묘 @@3@@자존@@4@@ 부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 내사 내부 집안이 모두 막혀 있다`,
     `@@1@@丁卯 @@2@@진 @@3@@숙명@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 금전의 소모가 많고 내사 집안에 공허하고 무력하며 만사에 상쟁하는 손해로 가출사가 있다.`,
     `@@1@@丁卯 @@2@@사 @@3@@변동@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인으로부터 도움을 얻고자 하는 일이 있고 내사 집안에 타인을 돕는 일이 있다. 원행 이동사가 있다 `,
     `@@1@@丁卯 @@2@@오 @@3@@건강@@4@@직녹, 형제사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 일이 된다. 직, 녹위자를 활용하되 서둘지 말라 외사에 공공적 상하 화합사가 있고 내사 집안에 가정 파탄사가 일어날 우려가 있다`,
     `@@1@@丁卯 @@2@@미 @@3@@신앙,@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 외교가 막히고 타인에게 의뢰한 일이 지체되고 있으며 내부 가정에 번잡 굴신이 요구되는 단합사가 있다`,
     `@@1@@丁卯 @@2@@신 @@3@@강제, 급속@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 타인을 돕는 일이 있고 내사 집안에 상황이 급박하고, 강제적 손실사가 우려된다.`,
     `@@1@@丁卯 @@2@@유 @@3@@재해@@4@@ 금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 돕는 일이 있고 내사 집안에 남녀가 분리되고 문호가 개변되는 동요불안사가 있다.`,
     `@@1@@丁卯 @@2@@술 @@3@@공허@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남녀 모두 자식의 재해가 있게 되며 남자는 불명예를 안고 여자는 잘 속는다.  `,
     `@@1@@丁卯 @@2@@해 @@3@@변동@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 직업 갖기 어렵고 여자는 남편 재해를 당하며 일부종사가 어렵다. 상황이 급박하고, 강제적 손실사가 우려된다. `,
     `@@1@@戊辰 @@2@@자 @@3@@자존@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 된다. 기이하게 풀린다 외사에 타인의 압박을 받고 내사 집안에는 연속되어질 단합사가 있다`,
     `@@1@@戊辰 @@2@@축 @@3@@지위@@4@@형제, 붕우, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰고 여자를 활용하면 기이하게 풀린다 외사에 개신시키는 일이 있고 내부 집안에는 담, 문의 퇴폐사로 손해가 있고 가출사가 우려된다. 본 뜻은 무정에 있다.`,
     `@@1@@戊辰 @@2@@인 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 원행이동사가 있다. 외사에 전진 후퇴가 곤란한 도주 관사가 있고 내부 집안에는 타인으로부터 압박 받는 일이 있다`,
     `@@1@@戊辰 @@2@@묘 @@3@@건강@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에는 공허 무력한 사정이고 만사 상쟁으로 손해가 있고 가출사가 있게 된다`,
     `@@1@@戊辰 @@2@@진 @@3@@신앙@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인을 돕고자 하는 일이 있고 내부 집안은 막혀 있다`,
     `@@1@@戊辰 @@2@@사 @@3@@강제, 급속@@4@@직녹, 명예, 문서, 부모, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 녹위자를 활용하되 서둘지 말라 상황이 급박하고 강제적인 손실사가 우려된다 외사에 외교사 막히고 타인에게 의뢰한 일이 지체되고 있으며 내사 집안에 타인으로부터 도움을 얻고자 하는 일이 있다`,
     `@@1@@戊辰 @@2@@오 @@3@@재해@@4@@문서, 후원, 부모사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외, 내, 집안 모두 타인의 도움을 얻고자 하는 일이 있다`,
     `@@1@@戊辰 @@2@@미 @@3@@숙명@@4@@형제, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인을 돕는 일이 있고 내부 집안에는 금전 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@戊辰 @@2@@신 @@3@@변동@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 은혜배반 상하 불손한 반목 대립사 또는 형송사가 있고 내부 집안에 타인으로부터 도움을 얻고자 하는 일이 있다`,
     `@@1@@戊辰 @@2@@유 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 상황을 개신시키는 단합사가 있고 내부 가정에는 친구 동료의 화합사가 있다`,
     `@@1@@戊辰 @@2@@술 @@3@@공허@@4@@형제, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 형제자매의 재해가 있다 `,
     `@@1@@戊辰 @@2@@해 @@3@@망신@@4@@ 금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@원행이동사가 있다. 모든 일이 헛소리에 불과하고 불성하며  금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 처와 해로를 못하고 여자는 남자를 천하게 만든다 `,
     `@@1@@己巳 @@2@@자 @@3@@건강@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 된다. 기이하게 풀린다 외사에 타인을 압박하는 일이 있고 내사 집안에는타인으로부터 압박받는 일이 있다. 본 사안은 본래 정중에 있다`,
     `@@1@@己巳 @@2@@축 @@3@@신앙@@4@@형제, 붕우, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰고 여자를 활용하면 기이하게 풀린다 외사에 원하는 일이 안 되어 골육이 상배하고 내부 집안에는 타인으로부터 도움을 얻고자 하는 일이 있다`,
     `@@1@@己巳 @@2@@인 @@3@@강제, 급속@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 압박받는 일이 있고 내부 집안에는 상황이 급박하고 강제적인 손실사가 우려된다`,
     `@@1@@己巳 @@2@@묘 @@3@@재해@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 번잡한 단합사가 있고 내사 집안에는 타인을 돕고자 하는 일이 있다`,
     `@@1@@己巳 @@2@@진 @@3@@숙명@@4@@형제, 붕우, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외, 내, 집안에 타인으로부터 도움을 얻고자 하는 일이 있다`,
     `@@1@@己巳 @@2@@사 @@3@@변동@@4@@부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 녹위자를 활용하되 서둘지 말라 금전을 쓰면 일이 된다. 여인을 활용하라. 이사에 타인으로부터 도움을 얻고자 하는 일이 있고 내부 집안이 막혀 있다`,
     `@@1@@己巳 @@2@@오 @@3@@색정, 간음@@4@@직녹, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 색정사가 있는 자다. 외사에 공공적 상하 화합사가 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@己巳 @@2@@미 @@3@@공허@@4@@형제, 붕우, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인으로부터 도움을 얻고자 하고 내부 집안에 타인을 돕는 일이 있다`,
     `@@1@@己巳 @@2@@신 @@3@@망신@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 타인의 도움을 얻고자 하는 일이 있고 내부 집안에는 은혜배반 상하 불손한 반목 대립사, 또는 관송사가 있다`,
     `@@1@@己巳 @@2@@유 @@3@@자존@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인을 돕는 일이 있고 내부 집안에 상황을 개신시키는 단합사가 있다`,
     `@@1@@己巳 @@2@@술 @@3@@지위@@4@@형제, 붕우, 금전손해, 여인 갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 형제자매의 재해가 있다 `,
     `@@1@@己巳 @@2@@해 @@3@@이사, 이동@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@원행이동사가 있다. 모든 일이 헛소리에 불과하고 불성하며  금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 처와 해로를 못하고 여자는 남자를 천하게 만든다 `,
     `@@1@@庚午 @@2@@자 @@3@@재해@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 되나 단 기이하게 풀린다. 외사에 계속될 단합사가 있고 내사 집안에는 계획사가 변하고 남녀상쟁 동요불안사가 있다`,
     `@@1@@庚午 @@2@@축 @@3@@숙명@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 타인의 도움을 얻고자 하고 내사 집안에는 부부 불합, 관송 불리한 사정으로 손해가 있고 가출사가 우려 된다`,
     `@@1@@庚午 @@2@@인 @@3@@변동@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 동요 분산 이별사가 있고 내사 집안에는 외화 내허 한 단합사가 있다`,
     `@@1@@庚午 @@2@@묘 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 색정사가 있는 자다. 외사에 타인의 압박을 받고 내사 가정에는 가정파탄사가 있다`,
     `@@1@@庚午 @@2@@진 @@3@@공허@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 된다. 후원 문서 부친을 활용하라 외사에 타인의 도움을 얻고자 하고 내사 집안에는 타인을 돕는 일이 있다`,
     `@@1@@庚午 @@2@@사 @@3@@망신@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 은혜배반 상하 불손사가 있고 내사 집안에는 금전 손실이 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@庚午 @@2@@오 @@3@@자존@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다 외사에 타인의 압박을 받고 내사 집안은 막혀 있다`,
     `@@1@@庚午 @@2@@미 @@3@@지위@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 된다. 후원 문서 모친을 활용하라 외사에 타인의 도움을 받고자 하는 일이 있고 내사 집안에는 공공적인 상하 화합사가 있다`,
     `@@1@@庚午 @@2@@신 @@3@@이사, 이동@@4@@직녹, 명예사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 명예사로 원행 이동사가 있다`,
     `@@1@@庚午 @@2@@유 @@3@@건강@@4@@  형제, 붕우, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@금전를 쓰면 된다. 여인을 활용하라. 외사에 금전 소모가 많고 내사 집안에 타인을 압박하는 일이 있다. 본 사안은 본래 인의에 있다 `,
     `@@1@@庚午 @@2@@술 @@3@@신앙@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모 후원, 문서 재해가 있다. 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다.  신미일이면 혼인 불문 독거환경의 태생적 고독인이다 `,
     `@@1@@庚午 @@2@@해 @@3@@강제@@4@@급속, 자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@자손 재해가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 불명예를 안고 여자는 잘 속는다. 상황이 급박하고 강제적 손실사가 우려된다 `,
     `@@1@@辛未 @@2@@자 @@3@@색정, 간음@@4@@지손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 되나 단 기이하게 풀린다.  색정사가 있는 자다. 외사에 타인을 압박하는 일이 있으나 허사고 내사 집안에는 관사구설 암중재해사로 손해가 있고 가출사가 우려된다 `,
     `@@1@@辛未 @@2@@축 @@3@@공허@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 하극상배, 재물부족 반목대립 형송사로 원수가 되고 내사 집안에는 일이 뜻대로 안된 골육상배의 동요불안사가 있다`,
     `@@1@@辛未 @@2@@인 @@3@@망신@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 외화내허적 단합사가 있고 내사 집안에는 타인으로부터 압박받는 일이 있다 원행 이동사가 있다 `,
     `@@1@@辛未 @@2@@묘 @@3@@지존@@4@@  금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 골육형제 화합사가 있으나 결국 소용없고 내사 집안에는 번잡하고 굴신이 요구되는 단합사가 있다`,
     `@@1@@辛未 @@2@@진 @@3@@지위@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 된다. 후원 문서 부친을 활용하라 외사에 희비가 불명한 하극상배의 동요불안 이별사가 있고 내사 집안에는 재물손실이 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@辛未 @@2@@사 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 원행이동사가 있다 외, 내 집안 모두 타인으로부터 도움 받을 일이 있다`,
     `@@1@@辛未 @@2@@오 @@3@@건강@@4@@  직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다 외사에 실속없는 문화광휘적 단합사가 있고 내사 집안에는 공공적인 상하 화합사가 있다`,
     `@@1@@辛未 @@2@@미 @@3@@신앙@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 된다. 후원 문서 모친을 활용하라 외사에 타인의 도움을 받고자 하는 일이 있고 내사 집안은 막혀 있다`,
     `@@1@@辛未 @@2@@신 @@3@@강제@@4@@  급속, 형제, 붕우, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 금전을 쓰면 된다. 여인을 활용하라.  외사에 외교가 막혀있고 타인에게 의뢰한 일이 지체되고 있으며 내사 집안은 타인을 돕는 일이 있다. 상황이 급박하고 강제적 손실사가 우려된다. 본 사안은 본래 권위에 있다`,
     `@@1@@辛未 @@2@@유 @@3@@재해@@4@@  직녹, 형제사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라.  외사에 재해손상사, 수하 농락으로 손해 근심이 있고 내사 집안에는 타인을 돕는 일이 있다`,
     `@@1@@辛未 @@2@@술 @@3@@숙명@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 사안 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모 후원, 문서 재해가 있다. 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다.  신미일이면 혼인 불문 독거환경의 태생적 고독인이다 `,
     `@@1@@辛未 @@2@@해 @@3@@변동@@4@@  자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@자손 재해가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 불명예를 안고 여자는 잘 속는다. 상황이 급박하고 강제적 손실사가 우려된다 `,
     `@@1@@壬申 @@2@@자 @@3@@자존@@4@@형제, 붕우, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다 외사에 금전 손모가 많고 내사 집안은 멈추지 않고 계속될 단합사가 있다`,
     `@@1@@壬申 @@2@@축 @@3@@지위@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다 외사에 타인의 압박을 받고 내사 집안은 타인의 후원을 받고자 하는 일이 있다`,
     `@@1@@壬申 @@2@@인 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 골육존장의 화합사가 있으나 오래 못가며 내부 가정에 부부이심 동요불안 분산가가 있다. 원행 이동사가 있다`,
     `@@1@@壬申 @@2@@묘 @@3@@건강@@4@@ 자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모사와 손해가 나고 일이 지지부진하며 안 된다 외사에 번잡한 결속사가 있고 내사 집안에 타인으로 부터 압박받는 일이 있다`,
     `@@1@@壬申 @@2@@진 @@3@@신앙@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다 외사에 타인으로부터 압박받는 일이 있고 내부 집안에 타인의 도움을 받고자 하는 일이 있다`,
     `@@1@@壬申 @@2@@사 @@3@@강제, 급속@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 상황이 급박하고 강제적인 손실사가 우려된다. 외사에 크게 구하나 작은 것밖에 안되고 은혜배반 상하 불손사로 반목 대립하여 원수가 된다`,
     `@@1@@壬申 @@2@@오 @@3@@재해@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사는 타인을 압박하고 내사 집안은 타인의 압박을 받는 일이 있다`,
     `@@1@@壬申 @@2@@미 @@3@@숙명@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 압박받는 일이 있고 내부 가정은 타인의 도움을 받고자 한다. 본 사안은 본래 음욕에 있다`,
     `@@1@@壬申 @@2@@신 @@3@@변동@@4@@ 자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 모사가 안 되고 시작은 있으나 끝이 없는 형편으로 손해 근심이 있고 내사 집안은 막혀 있다`,
     `@@1@@壬申 @@2@@유 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 색정사가 있는 자다. 외사에 타인의 도움을 원하고 내사 집안은 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@壬申 @@2@@술 @@3@@공허@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@남자는 직업재해가 있고 여자는 남자의 재해가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 불명예를 안고 여자는 한 남자로 그치지 않는다.  `,
     `@@1@@壬申 @@2@@해 @@3@@망신@@4@@  직녹, 명예사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@형제자매의 재해가 있다. 모든 일이 헛소리에 불과하고 불성 하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 여자를 극하고 여자는 남자로 번민하며 천하게 만든다. 원행 이동사가 있다 임신일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다 `,
     `@@1@@癸酉 @@2@@자 @@3@@건강@@4@@ 직녹, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다 금전을 쓰면 된다. 여인을 활용하라. 기이하게 풀린다 외사에 남녀 규방부부화합사가 있고 내사 집안에는 여자나 아손의 재해로 손해가 있고 파재 극처사가 있다`,
     `@@1@@癸酉 @@2@@축 @@3@@신앙@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다 외사에 외교가 막히고 타인에게 의뢰한 일도 지체되고 있고 내사 집안에 상황을 개신시키는 단합사가 있다`,
     `@@1@@癸酉 @@2@@인 @@3@@강제@@4@@  급속, 자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 타인의 압박을 받고 내사 집안에 상황이 급박하고 강제적 손실사가 우려 된다`,
     `@@1@@癸酉 @@2@@묘 @@3@@재해@@4@@ 자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모사와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인의 압박을 받는 일이 있고 내사 집안에 분리되고 실탈 되는 남녀상쟁 동요불안사가 있다.`,
     `@@1@@癸酉 @@2@@진 @@3@@직업@@4@@ 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다 외사에 담, 문호의 퇴패사로 파재 극처사가 있고 내부 가정에는 친구 동료의 화합사가 있다`,
     `@@1@@癸酉 @@2@@사 @@3@@변동@@4@@ 금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 내외 가정에 상황을 개혁 개신시키는 일이 있다. 이 경우 부부이별사가 대부분이다`,
     `@@1@@癸酉 @@2@@오 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  색정사가 있는 자다. 외사에 부부 불합, 불리한 관송사로 손해가 있고 가출사가 일어난다. 본 사안은 본래 무정에 있다.`,
     `@@1@@癸酉 @@2@@미 @@3@@공허@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 모망사 불성으로 골육이 상쟁하여 이별사가 일어나고 내사 집안은 타인의 도움을 얻고자 하는 일이 있다`,
     `@@1@@癸酉 @@2@@신 @@3@@망신@@4@@ 부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사는 타인을 돕는 일이 있고 내사 집안은 금전 손모가 많으며 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@癸酉 @@2@@유 @@3@@재해@@4@@ 부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 개신시키는 결속사가 있고 내사 가정은 막혀 있다`,
     `@@1@@癸酉 @@2@@술 @@3@@지위@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@남자는 직업재해가 있고 여자는 남자의 재해가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 불명예를 안고 여자는 한 남자로 그치지 않는다.  `,
     `@@1@@癸酉 @@2@@해 @@3@@이사@@4@@ 이동, 형제, 붕우, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@형제자매의 재해가 있다. 모든 일이 헛소리에 불과하고 불성 하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 여자를 극하고 여자는 남자로 번민하며 천하게 만든다. 원행 이동사가 있다 임신일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다 `,
     `@@1@@甲戌 @@2@@자 @@3@@재해@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라  외사에 이익을 얻고 내사 가정에 타인을 압박하는 일이 있다`,
     `@@1@@甲戌 @@2@@축 @@3@@숙명@@4@@ 금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 반목 대립 형송사가 일어나고 금전사가 암울하다`,
     `@@1@@甲戌 @@2@@인 @@3@@변동@@4@@ 직녹, 명예사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 된다.  직,녹위자를 활용하되 서둘지 말라. 명예사가 있다`,
     `@@1@@甲戌 @@2@@묘 @@3@@색정, 간음@@4@@형제, 붕우, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라  색정사가 있는 자다. 외사에 형제골육의 화합사가 있다`,
     `@@1@@甲戌 @@2@@진 @@3@@공허@@4@@ 금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하고 내사 집안에 희비가 불명한 하극상배사가 있다`,
     `@@1@@甲戌 @@2@@사 @@3@@망신@@4@@  자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다 원행이동사가 있다. 본 사안은 본래 정중에 있다`,
     `@@1@@甲戌 @@2@@오 @@3@@자존@@4@@ 자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다  갑술일, 외, 내사에 문화광휘적 일이 있다. 다만 외화내허 하 다`,
     `@@1@@甲戌 @@2@@미 @@3@@지위@@4@@ 금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 형송사가 일어나고 재물이 암울하며 반목 대립사가 있다`,
     `@@1@@甲戌 @@2@@신 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@남자는 직업재해가 있고 여자는 남자의 재해가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된 다. 산기자로의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 불명예를 안고 여자는 한 남자로 그치지 않는다. 원행 이동사가 있고 상황이 급박하며 강제적인 손실사가 우려된다 `,
     `@@1@@甲戌 @@2@@유 @@3@@건강@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@남자는 직업재해가 있고 여자는 남자의 재해가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 불명예를 안고 여자는 한 남자로 그치지 않는다 `,
     `@@1@@甲戌 @@2@@술 @@3@@신앙@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 타인을 압박하고 내사 집안이 막혀있다`,
     `@@1@@甲戌 @@2@@해 @@3@@강제, 급속@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라. 외사에 골육존장의 화합사가 있으나 오래가지 않고 내사 집안에 타인을 압박하는 일이 있다 상황이 급박하고 강제적 손실사가 우려 된다 `,
     `@@1@@乙亥 @@2@@자 @@3@@색정, 간음@@4@@부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라  색정사가 있는 자다`,
     `@@1@@乙亥 @@2@@축 @@3@@공허@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 담문, 묘각이 퇴패한다. 내사 집안에 압박을 받는 일이 있다`,
     `@@1@@乙亥 @@2@@인 @@3@@망신@@4@@형제, 붕우, 동료, 금전손해, 여인 갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라.  외사에 압박을 받는 일이 있고 내사 집안은 골육존장의 화합사가 있으나 결국 소용이 없고 타인을 돕는 일이 있다`,
     `@@1@@乙亥 @@2@@묘 @@3@@자존@@4@@  직녹사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라  직,녹을 구한다. 동료, 형제 직, 녹,위 자를 활용하되 서둘지 말라. 내사 집안에 번잡한 결속사가 있다`,
     `@@1@@乙亥 @@2@@진 @@3@@지위@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 외교가 막히고 타인에게 의뢰한 일이 지체되고 있다. 내사에 타인의 압박을 받는다. 본 사안은 본래 인의에 있다`,
     `@@1@@乙亥 @@2@@사 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다 외사에 타인을 도움을 얻고자 하고 내사 집안에 크게 구하나 효과가 적으며 조상불경사로 동요 불안하다`,
     `@@1@@乙亥 @@2@@오 @@3@@건강@@4@@  자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다 외사에 타인으로부터 도움 받고자 하는 일이 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@乙亥 @@2@@미 @@3@@신앙@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 손모사가 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@乙亥 @@2@@신 @@3@@강제, 급속@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@남자는 직업재해가 있고 여자는 남자의 재해가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된 다. 산기자로의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 불명예를 안고 여자는 한 남자로 그치지 않는다. 원행 이동사가 있고 상황이 급박하며 강제적인 손실사가 우려된다 `,
     `@@1@@乙亥 @@2@@유 @@3@@재해@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@남자는 직업재해가 있고 여자는 남자의 재해가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 남자는 불명예를 안고 여자는 한 남자로 그치지 않는다 `,
     `@@1@@乙亥 @@2@@술 @@3@@숙명@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 희비불명 한 하극상배로 동요 불안 불안사가 있고 내사 집안에 타인으로부터 압박 받는 일이 있다`,
     `@@1@@乙亥 @@2@@해 @@3@@이사, 이동@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라.  외사에 타인을 압박하는 일이 있고 내사 집안은 막혀 있다`,
     `@@1@@丙子 @@2@@자 @@3@@자존@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 타인으로부터 압박을 받고 내사 집안은 막혔다`,
     `@@1@@丙子 @@2@@축 @@3@@지위@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단, 기이하게 풀린다. 외사에 개신사가 있고 내사 집안에 부부융합의 화합사가 있다`,
     `@@1@@丙子 @@2@@인 @@3@@이사, 이동@@4@@직녹, 부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라 원행이동사가 있다. 외사에 도주관사, 전진후퇴가 곤란한 일이 있고 내사 집안에 타인의 도움 받고자 하는 일이 있다`,
     `@@1@@丙子 @@2@@묘 @@3@@건강@@4@@ 직녹, 부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 타인의 도움을 받고자 하는 일이 있고 내사 집안에 상하 불화, 존상불리의 무례사가 있다. 본 사안은 본래 권위에 있다`,
     `@@1@@丙子 @@2@@진 @@3@@신앙@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인을 돕는 일이 있고 내사 집안에 계속 이어지는 결속사가 있다`,
     `@@1@@丙子 @@2@@사 @@3@@강제, 급속@@4@@직녹, 명예사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 외사에 외교가 막히고 타인에게 의뢰한 일이 지체되어 있고 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@丙子 @@2@@오 @@3@@재해,@@4@@형제, 붕우, 금전손해, 여인 갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 금전이 손실되고 내사 집안에 계획사가 변경 되고 남녀상쟁사가 있다`,
     `@@1@@丙子 @@2@@미 @@3@@숙명@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에는 타인을 돕는 일이 있고 내사 집안에는 관사 구설과 암중 재해로 손해가 있고 가출사가 있다.`,
     `@@1@@丙子 @@2@@신 @@3@@변동@@4@@ 금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@남자는 금전과 여자의 재해가 있고 여자는 금전재해와 남자를 천하게 한다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립 한다 `,
     `@@1@@丙子 @@2@@유 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@색정사가 있는 자다. 남자는 금전과 여자의 재해가 있고 여자는 금전재해와 남자를 천하게 한다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립 한다 `,
     `@@1@@丙子 @@2@@술 @@3@@공허@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다 외사에 침체한 타인을 돕는 일이 있고 내사 집안에  타인의 압박을 받는 일이 있다`,
     `@@1@@丙子 @@2@@해 @@3@@망신@@4@@  직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다  외사에 크게 구하나 작은 것밖에 안 되고 조상불경사로 이별사가 있으며 내사 집안에 금전 허실이 있고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@丁丑 @@2@@자 @@3@@건강@@4@@ 형제, 붕우, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 관사구설, 암중재해사로 손해 근심이 있고 내사 집안에는 부부융합의 화합사가 있다`,
     `@@1@@丁丑 @@2@@축 @@3@@신앙@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단, 기이하게 풀린다. 외사에 계획한 일이 불성하고 골육상배사로 동요불안 이별사가 있고 내사 집안은 막혔다`,
     `@@1@@丁丑 @@2@@인 @@3@@강제, 급속@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라 내, 외사 집안에 모두 타인의 압박을 받고 있다. 상황이 급박하고 강제적 손실사가 우려된다`,
     `@@1@@丁丑 @@2@@묘 @@3@@재해@@4@@ 직녹, 부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 번잡한 단합사가 있고 내사 집안에 타인의 압박을 받는 일이 있다. 본 사안은 본래 음욕에 있다.`,
     `@@1@@丁丑 @@2@@진 @@3@@숙명@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 금전 손모가 있고 내사 집안에 담문, 묘각의 퇴패사로 파재 극처사가 있다.`,
     `@@1@@丁丑 @@2@@사 @@3@@변동@@4@@ 형제, 붕우, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 금전을 쓰면 일이 된다. 여인을 활용하라. 외사에 타인의 도움 받을 일이 있고 내사 집안에도 타인의 도움 받을 일과 개신시키는 결속사가 있다`,
     `@@1@@丁丑 @@2@@오 @@3@@색정, 간음@@4@@직녹, 형제, 동료사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라  일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 색정사가 있는 자다. 외사에 공공적 상하 화합사가 있고 내사 집안에 부부불합 불리한 관송사로 손해가 있고 가출사가 있 게 된다  `,
     `@@1@@丁丑 @@2@@미 @@3@@공허@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 외교가 막히고 타인에게 의뢰한 일이 지체되고 내사 집안에는 모망사 불성하고 골육상배사로 동요 불안하다`,
     `@@1@@丁丑 @@2@@신 @@3@@망신@@4@@ 금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@남자는 금전과 여자의 재해가 있고 여자는 금전재해와 남자를 천하게 한다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립 한다 `,
     `@@1@@丁丑 @@2@@유 @@3@@자존@@4@@ 금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@색정사가 있는 자다. 남자는 금전과 여자의 재해가 있고 여자는 금전재해와 남자를 천하게 한다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립 한다 `,
     `@@1@@丁丑 @@2@@술 @@3@@지위@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다 외, 내사 모두 형송사, 사기, 하극상배, 재물부족, 재물지체사가 있고 서로 원수가 된다`,
     `@@1@@丁丑 @@2@@해 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다  외, 내사 모두 타인을 압박하는 일이 있고 원행이동사가 있다`,
     `@@1@@戊寅 @@2@@자 @@3@@재해@@4@@ 금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 된다  외사에 타인의 압박을 받고 내사 집안에 타인의 도움을 구하는 일이 있다. 본 사안은 본래 무정에 있다.`,
     `@@1@@戊寅 @@2@@축 @@3@@숙명@@4@@ 형제, 붕우, 금전손해, 여인 갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰고 여자를 활용하면 기이하게 풀린다 외사에 개신시키는 결속사가 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@戊寅 @@2@@인 @@3@@변동@@4@@  직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 도주관사, 진퇴곤란 갈등 반목사가 있고 원수가 되고 내사 집안은 막혔다`,
     `@@1@@戊寅 @@2@@묘 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 색정사가 있는 자다. 외사에 타인의 도움을 원하고  내사 집안에 금전손모가 많고 남자는 여자에 갈등하 고 여자는 남자에 번민 한다`,
     `@@1@@戊寅 @@2@@진 @@3@@공허@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인을 돕는 일이 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@戊寅 @@2@@사 @@3@@망신@@4@@  직녹, 명예, 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라  외사 외교가 막히고 타인에게 의뢰한 일이 지체 되고 있고 내사 집안에 도주관사, 진퇴곤란사가 있다`,
     `@@1@@戊寅 @@2@@오 @@3@@자존@@4@@ 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라 외사에 금전의 손모가 많고 내사 집안에 외화내허한 단합사가 있다`,
     `@@1@@戊寅 @@2@@미 @@3@@지위@@4@@  형제, 붕우, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인을 돕는 일이 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@戊寅 @@2@@신 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@강제적인 손실사가 있다. 부부이심 동요불안 분산사가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다 원행이동사가 있으나 허사다 `,
     `@@1@@戊寅 @@2@@유 @@3@@건강@@4@@  형제, 붕우, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@남녀 분리되고 문호를 바꾸며 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립 한다 `,
     `@@1@@戊寅 @@2@@술 @@3@@신앙@@4@@  형제, 붕우, 동료, 금전손해, 여인 갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라. 기이하게 풀린다 외사에 침체된 일을 돕는다. 내사 가정에 문화광휘 하나 외화내허적 일이 있다. 천우신조를 얻는다`,
     `@@1@@戊寅 @@2@@해 @@3@@강제@@4@@급속, 금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 크게 구하나 작게 얻고 조상불경사가 있고 내사 집안에 골육존상의 화합사가 있으나 길지 않다. 상황이 급박하고 강제적인 손실사가 우려된다. 천우신조를 얻는다`,
     `@@1@@己卯 @@2@@자 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 된다  색정사가 있는 자다. 외사에 관사구설 암중재해사가 있고 내사 집안에 무례한 상하불화 존상불리사가 있다. 본 사안은 본래 정중에 있다`,
     `@@1@@己卯 @@2@@축 @@3@@공허@@4@@  형제, 붕우, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰고 여자를 활용하면 기이하게 풀린다 외사에 계획사 안 되고 골육상배사가 있고 동요 불안, 이별사가 있으며 내사 집안에는 타인을 압박하는 일이 있다`,
     `@@1@@己卯 @@2@@인 @@3@@망신@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인의 압박을 받고 내사 집안에 금전 손모가 많으며 남자는 여자와 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@己卯 @@2@@묘 @@3@@자존@@4@@  직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 번잡한 결속사가 있고 내사 집안이 막혔다`,
     `@@1@@己卯 @@2@@진 @@3@@지위@@4@@  형제, 붕우, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 금전 손모가 많고 내사 집안에 공허무력하고 만사 상쟁하며 손해가 있고 가출사가 있게 된다`,
     `@@1@@己卯 @@2@@사 @@3@@이사, 이동@@4@@부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 금전을 쓰면 일이 된다. 여인을 활용하라. 원행이동사가 있다`,
     `@@1@@己卯 @@2@@오 @@3@@건강@@4@@  직녹, 형제, 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라 일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 외사에 공적 상하 결속사가 있고 내사 집안에 가정파탄사의 우려가 있다`,
     `@@1@@己卯 @@2@@미 @@3@@신앙@@4@@  형제, 붕우, 금전손해, 여인 갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 외교가 막히고 타인에 의뢰한 일이 지체되고 있고 내사 집안에 번잡한 결속사가 있다`,
     `@@1@@己卯 @@2@@신 @@3@@강제, 급속@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@강제적인 손실사가 있다. 부부이심 동요불안 분산사가 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다 원행이동사가 있으나 허사다  `,
     `@@1@@己卯 @@2@@유 @@3@@재해@@4@@  자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@남녀 분리되고 문호를 바꾸며 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립 한다 `,
     `@@1@@己卯 @@2@@술 @@3@@숙명@@4@@  형제, 붕우, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라. 기이하게 풀린다 외사에 반목대립 형송사가 있고 내사 집안에 형제골육의 화합사가 있다`,
     `@@1@@己卯 @@2@@해 @@3@@변동@@4@@  금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외, 내 집안 모두 번잡한 결속사가 있다`,
     `@@1@@庚辰 @@2@@자 @@3@@자존@@4@@ 자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 내, 외 집안 모두 계속 이어질 단합사가 있다`,
     `@@1@@庚辰 @@2@@축 @@3@@지위@@4@@ 부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 타인의 도움을 받고자 하고 내사에 파재 극처 묘각퇴폐사가 있다`,
     `@@1@@庚辰 @@2@@인 @@3@@이사, 이동@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 원행이동사가 있다. 외사에 부부이심 대립반목 동요불안 이별사가 있고 내사 가정에 타인의 압박을 받는 일이 있다`,
     `@@1@@庚辰 @@2@@묘 @@3@@건강@@4@@  금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 타인을 압박하는 일이 있고 내사 집안에 공허 무력 만사상쟁으로 손해가 있고 가출사가 있다`,
     `@@1@@庚辰 @@2@@진 @@3@@신앙@@4@@ 부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원 문서 부친을 활용하라 외사에 도움 받을 일이 있고 내사 집안이 막혔다`,
     `@@1@@庚辰 @@2@@사 @@3@@강제, 급속@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 강제적 손실사가 있다 외사에 은혜배반 상하 불손사가 있고 내사 집안에 타인의 도움 받는 일이 있다`,
     `@@1@@庚辰 @@2@@오 @@3@@재해@@4@@ 금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인의 압박을 받고 내사 집안에 타인의 도움 받을 일이 있다`,
     `@@1@@庚辰 @@2@@미 @@3@@숙명@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 타인의 도움을 받고자 하는 일이 있고 내사 집안에 금전 손모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@庚辰 @@2@@신 @@3@@변동@@4@@  직녹, 명예사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@외사가 막히고 타인에게 의뢰한 일이 지체되고 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 자손의 재해가 있다. 여자는 잘 속는다. 본 사안은 본래 권위에 있다. 천우신조를 얻는다 경진일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@庚辰 @@2@@유 @@3@@색정, 간음,@@4@@ 형제, 붕우, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@친구 동료의 화합사가 있으나 모든 일이 헛소리에 불과하고 불성하며 금전과 여자세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야 자립할 수 있는 산기자이다. 자손의 재해가 있고 여자는 잘 속는다. 본 사안은 본래 인의에 있다. 천우신조를 얻는다 신사일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@庚辰 @@2@@술 @@3@@공허@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 타인의 도움을 원하는 일이 있고 내사 집안에 희비불명, 하극상배의 동요 불안사가 있다`,
     `@@1@@庚辰 @@2@@해 @@3@@망신@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 손해 근심사가 있고 내사에 타인을 압박하는 일이 있다`,
     `@@1@@辛巳 @@2@@자 @@3@@건강@@4@@  자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사는 타인을 압박하고 내사 집안에 타인의 압박을 받는 일이 있다`,
     `@@1@@辛巳 @@2@@축 @@3@@신앙@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 하극상배, 재물부족 반목 대립사로 원수가 되고 내사 집안에 상황을 개신하는 결속사가 있다`,
     `@@1@@辛巳 @@2@@인 @@3@@강제, 급속@@4@@ 금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 상황이 급박하고 강제적 손실사가 우려된다. 내사 집안에 도주관사, 진퇴 곤란 반목 대립사가 있다`,
     `@@1@@辛巳 @@2@@묘 @@3@@재해,@@4@@  금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 형제골육의 화합사가 있고 내사 집안에 타인의 도움을 원하는 일이 있다`,
     `@@1@@辛巳 @@2@@진 @@3@@숙명@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원 문서 부친을 활용하라 외사에 희비불명, 하극상배사로 동요 불안 이별사가 있고 내부 집안에 타인을 돕는 일이 있다`,
     `@@1@@辛巳 @@2@@사 @@3@@변동@@4@@  직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인의 도움 받는 일이 있고 내사 집안이 막혔다`,
     `@@1@@辛巳 @@2@@오 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 색정사가 있는 자다. 외사에 타인의 압박을 받고 내사 집안에 금전 손모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@辛巳 @@2@@미 @@3@@공허@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 `,
     `@@1@@辛巳 @@2@@신 @@3@@망신@@4@@  형제, 붕우, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@외사가 막히고 타인에게 의뢰한 일이 지체되고 있다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 산기자로 의, 복, 수련, 종교에 귀의해야만 자립한다. 자손의 재해가 있다. 여자는 잘 속는다. 본 사안은 본래 권위에 있다. 천우신조를 얻는다 경진일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@辛巳 @@2@@유 @@3@@자존@@4@@  직녹, 형제, 동료사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@친구 동료의 화합사가 있으나 모든 일이 헛소리에 불과하고 불성하며 금전과 여자세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야 자립할 수 있는 산기자이다. 자손의 재해가 있고 여자는 잘 속는다. 본 사안은 본래 인의에 있다. 천우신조를 얻는다 신사일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@辛巳 @@2@@술 @@3@@지위@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사가 막히고 타인에게 부탁한 일이 지체되어 있고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@辛巳 @@2@@해 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 원행이동사가 있다. 외사에 타인을 압박하는 일이 있고 내사에 크게 구하나 적은 효과밖에 없고 조상을 거역하는 일이 있다`,
     `@@1@@壬午 @@2@@자 @@3@@재해@@4@@ 형제, 붕우, 금전손해, 여인 갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 된다. 여인을 활용하라.  외사에 금전의 소모가 많고 내사 집안에 남녀상쟁의 동요 불안사가 있다`,
     `@@1@@壬午 @@2@@축 @@3@@숙명@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다 외사에 타인으로부터 압박사가 있고 내사 집안에 부부불합, 관송사가 불리한 일로 손해가 있고 가출사가 있게 된다`,
     `@@1@@壬午 @@2@@인 @@3@@변동@@4@@  자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외, 내 집안 모두 골육화합, 문화광휘의 결속사가 있으나 내실이 없고 오래가지 못 한다`,
     `@@1@@壬午 @@2@@묘 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 색정사가 있는 자다. 외사에 번잡한 결속사가 있으나 오래가지 못 하고 내부 집안에 타인의 도움을 원하는 일이 있다`,
     `@@1@@壬午 @@2@@진 @@3@@공허@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 압박사가 있고 내사 집안에 타인에게 도움을 주는 일이 있다`,
     `@@1@@壬午 @@2@@사 @@3@@망신@@4@@  금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 크게 구하나 적게 이루고 동요 불안 이별사가 있고 내부 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@壬午 @@2@@오 @@3@@자존@@4@@ 금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 타인을 압박하는 일이 있고 내사 집안이 막혔다`,
     `@@1@@壬午 @@2@@미 @@3@@지위@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 압박을 받는 일이 있고 내사집안에 공공적 상하 화합사가 있다. 본 사안은 본래 음욕에 있다. 천우신조를 얻는다`,
     `@@1@@壬午 @@2@@신 @@3@@이사@@4@@  이동, 부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@부친의 재해가 있고, 후원, 문서, 학업이 부진하다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 원행이동사가 있다. 상황이 급박하고 강제적 손실사가 우려 된다 `,
     `@@1@@壬午 @@2@@유 @@3@@건강@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@모친의 재해가 있고, 후원, 문서, 학업이 부진하다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 `,
     `@@1@@壬午 @@2@@술 @@3@@신앙@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다, 기이하게 풀린다 외사에 타인으로부터 압박사가 있고 내부 집안에 문화광휘적 단합사가 있으나 외화내허한 일이다.`,
     `@@1@@壬午 @@2@@해 @@3@@강제@@4@@급속, 직녹, 명예, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라. 상황이 급박하고 강제적 손실사가 우려된다. 외사가 막히고 타인에게 의뢰한 일이 지체되고 있다. 내부 집안에 타인으로 부터 압박받는 일이 있다`,
     `@@1@@癸未 @@2@@자 @@3@@색정, 간음@@4@@직녹, 형제, 동료사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 된다. 여인을 활용하라. 일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라. 색정사가 있는 자다. 외사에 부부융합의 화합사가 있고 내부 가정에 관사구설 암중 재해사로 손해가 있고 가출사가 있게 된다 `,
     `@@1@@癸未 @@2@@축 @@3@@공허@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다 외, 내 집안 모두 계획사가 불성한 동요 불안 이별사가 있고 골육이 상배하는 일이 있다`,
     `@@1@@癸未 @@2@@인 @@3@@망신,@@4@@  자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외, 내 집안 모두 타인으로부터 압박을 받고 있다`,
     `@@1@@癸未 @@2@@묘 @@3@@자존,@@4@@ 자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인으로부터 압박사가 있고 내부 집안에 번잡한 결속사가 있다`,
     `@@1@@癸未 @@2@@진 @@3@@지위,@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 파제 극처사가 있고 묘각 퇴패사가 있으며 내사 집안에 금전이 소모되고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@癸未 @@2@@사 @@3@@이사, 이동,@@4@@  금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  원행이동사가 있다. 외사에 개신시키는 결속사가 있고 내사 집안에 타인의 도움을 구하는 일이 있다`,
     `@@1@@癸未 @@2@@오 @@3@@건강,@@4@@ 금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 부부 불합적 관송사 불리한 일이 있고 손해 가출사가 있으며 내사 집안에 공공적 상하화합사가 있다. 본 사안은 본래 무정에 있다. 천우신조를 얻는다`,
     `@@1@@癸未 @@2@@미 @@3@@신앙,@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 계획사가 불성하고 골육상배 이별사가 있으며 동요 불안하고 내사 집안이 막혔다`,
     `@@1@@癸未 @@2@@신 @@3@@강제, 급속,@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@부친의 재해가 있고, 후원, 문서, 학업이 부진하다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 원행이동사가 있다. 상황이 급박하고 강제적 손실사가 우려 된다 `,
     `@@1@@癸未 @@2@@유 @@3@@재해,@@4@@ 부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@모친의 재해가 있고, 후원, 문서, 학업이 부진하다. 모든 일이 헛소리에 불과하고 불성하며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 `,
     `@@1@@癸未 @@2@@술 @@3@@숙명,@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다, 기이하게 풀린다 외, 내 집안 모두 형송사가 일어나고 재물이 암체 되며 하극상배사, 재물부족사가 있고 서로 원수 맺는다`,
     `@@1@@癸未 @@2@@해 @@3@@변동,@@4@@ 형제, 붕우, 금전손해, 여인 갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라. 금전을 쓰면 된다. 여인을 활용하라 외, 내 집안 모두 타인을 압박하는 일이 있다`,
     `@@1@@甲申 @@2@@자 @@3@@자존,@@4@@  부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라. 기이하게 풀린다  외사에 타인의 도움을 얻고자 하는 일이 있고 내사 집안에 계속될 결속사가 있다.`,
     `@@1@@甲申 @@2@@축 @@3@@지위,@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 타인을 압박하는 일이 있고 내사 집안에 타인으로부터 도움을 얻고자 하는 일이 있다`,
     `@@1@@甲申 @@2@@인 @@3@@이사, 이동,@@4@@ 직녹, 명예, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 된다. 직, 녹위자를 활용하되 서둘지 말라. 원행이동사가 있다 외사 내사 집안 모두 동요불안, 부부이심, 분산사가 있다. 어떤 일도 오래가지 못한다. 직접 나서지 말고 타인을 앞세워야 한다`,
     `@@1@@甲申 @@2@@묘 @@3@@건강,@@4@@형제, 붕우, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 일이 된다. 여자를 활용하라. 외사에 금전의 손모가 많고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@甲申 @@2@@진 @@3@@신앙,@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 타인으로부터 도움 받을 일이 있다`,
     `@@1@@甲申 @@2@@사 @@3@@강제,@@4@@급속, 자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다. 강제적인 손실사가 있다 외사에 도주관사, 진퇴곤란의 반목대립, 형송사가 있고 내사 집안에 은혜배반 상하 불손사로 반목하는 일이 있다, 본 사안은 본래 정중에 있다. 천우신조 를 얻는다`,
     `@@1@@甲申 @@2@@오 @@3@@재해,@@4@@  자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자식의 재해가 있고 금전이 손모되며 세상일에 무능력자가 된다. 의, 복,  수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@甲申 @@2@@미 @@3@@숙명,@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가 있고 금전이 손모되며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@甲申 @@2@@신 @@3@@변동,@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다, 단 기이하게 풀린다 외사에 부부이심적 동요 불안 이별사가 있고 내사집안은 막혀있다`,
     `@@1@@甲申 @@2@@유 @@3@@색정,@@4@@간음, 직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  색정사가 있는 자다. 이사는 타인으로부터 압박을 받고 내사 집안은 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@甲申 @@2@@술 @@3@@공허,@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 문화광휘적 결속사가 있으나 속이 빈 것이고 내사 집안에 타인의 도움을 받고자 하는 일이 있다`,
     `@@1@@甲申 @@2@@해 @@3@@망신,@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라 외사에 골육존장의 화합사가 있으나 길지 않고 내사집안에 모사가 불성하고 유시무종한 일로 손해나고 가출사가 있게 된다`,
     `@@1@@乙酉 @@2@@자 @@3@@건강,@@4@@부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라. 기이하게 풀린다  외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 여자나 자손의 재해가 있게 된다`,
     `@@1@@乙酉 @@2@@축 @@3@@신앙,@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 담, 문 묘각 등 퇴폐로 손해가 있으며 파재, 극처사가 있고 내사 집안에 상황을 개신시키는 결속사가 있다`,
     `@@1@@乙酉 @@2@@인 @@3@@강제,@@4@@ 급속, 형제, 붕우, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라. 상황이 급박하고 강제적 손실사가 우려 된다`,
     `@@1@@乙酉 @@2@@묘 @@3@@재해,@@4@@직녹, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@직,녹을 구한다. 동료, 형제 직, 녹,위자를 활용하되 서둘지 말라.  외사에 공허무력, 만사상쟁사가 있고 내사 집안에 분리되고 가출하고 문호가 개변되는 일이 있다. 보통 부부 남녀 다툼과 이별이다`,
     `@@1@@乙酉 @@2@@진 @@3@@숙명,@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사가 막히고 타인에게 의뢰한 일이 지체되고 있으며 내사 집안에는 친구 동료 형제자매의 화합사가 있다. 본 사안은 본래 인의에 있다. 천우신조를 얻는다.  `,
     `@@1@@乙酉 @@2@@사 @@3@@변동@@4@@자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 지지부진하며 될 듯하나 안 된다. 강제적인 손실사가 있다 외사에 타인의 도움을 얻는 일이 있고 내사 집안에 상황을 개신시키는 결속사가 있다`,
     `@@1@@乙酉 @@2@@오 @@3@@색정, 간음@@4@@ 자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자식의 재해가 있고 금전이 손모되며 세상일에 무능력자가 된다. 의, 복,  수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@乙酉 @@2@@미 @@3@@공허@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가 있고 금전이 손모되며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@乙酉 @@2@@신 @@3@@망신@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다, 단 기이하게 풀린다 외사에 타인을 돕는 일이 있고 내사 집안에 금전 소 모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@乙酉 @@2@@유 @@3@@자존@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 외사에 형제자매 동료 친구의 화합사가 있고 내사집안은 막혀 있다`,
     `@@1@@乙酉 @@2@@술 @@3@@지위@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 희비가 불명한 하극상배로 동요 불안 이별사가 있고 내부 집안에 재해 질병손상 수하농락사로 손해가 있고 가출사가 있게 된다`,
     `@@1@@乙酉 @@2@@해 @@3@@이사, 이동@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라 원행이동사가 있다. 외사에 타인을 압박하는 일이 있고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@丙戌 @@2@@자 @@3@@재해@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다 외사에 타인으로부터 압박을 받는 일이 있고 내사집안에 타인을 압박하는 일이 있다`,
     `@@1@@丙戌 @@2@@축 @@3@@숙명@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 상황을 개신시키는 결속사가 있고 내사 집안에 하극 상배, 재물부족사로 반목대립 형송사로 손해가 있고 가출사도 있게 된다`,
     `@@1@@丙戌 @@2@@인 @@3@@변동@@4@@  직녹, 명예, 부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라 외사에 도주관사, 진퇴곤란의 반목대립사가 있고 내부 집안에 문화광휘사가 있으나 실속이 없다`,
     `@@1@@丙戌 @@2@@묘 @@3@@색정, 간음@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 색정사가 있는 자다. 외사에 타인의 도움을 얻는 일이 있고 내사 집안에 형제골육의 화합사가 있다. 본 사안은 본래 권위에 있다. 천우신조를 얻는다`,
     `@@1@@丙戌 @@2@@진 @@3@@공허@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인을 돕는 일이 있고 내사 집안에 희비가 불명한 하극상배사로 동요불안하다`,
     `@@1@@丙戌 @@2@@사 @@3@@망신@@4@@  직녹, 명예, 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 외사에 외교가 막히고 타인에게 의뢰한 일이 지체되고 내사 집안에 타인의 도움을 얻는 일이 있다`,
     `@@1@@丙戌 @@2@@오 @@3@@지존@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제자매의 재해가 있고 금전이 손모되며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 을유일은 색정사가 있는 자다  정해일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@丙戌 @@2@@미 @@3@@지위@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자식의 재해가 있고 금전이 손모되며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 을유일은 색정사가 있는 자다 정해일이면 혼인 불문 독거환경의 태생적 고독인이다  `,
     `@@1@@丙戌 @@2@@신 @@3@@이사, 이동@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다.  외사에 종교신앙적 화합사가 있으나 영속성이 없고 내사 집안에 타인을 돕고자 하는 일이 있다. 원행이동사가 있다`,
     `@@1@@丙戌 @@2@@유 @@3@@건강@@4@@  금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 개신시키는 일이 있고 내사 집안에 재해 병재나 손상사가 발생하며 수하 농락 건이 있어 가출사가 있게 된다`,
     `@@1@@丙戌 @@2@@술 @@3@@신앙@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 침체한 일을 돕는 일이 있고 내사 집안이 막혀 있다`,
     `@@1@@丙戌 @@2@@해 @@3@@강제, 급속@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  강제적인 손실사가 있다. 외사에 크게 구한 일이 적은 효과밖에 없고 조상에 위배되는 일이 있으며 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@丁亥 @@2@@자 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다 색정사가 있는 자다. 외사에 관사구설, 암중재해로  손해와 실주사가 있고 내사 집안에 금전손모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@丁亥 @@2@@축 @@3@@공허@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 계획사가 안 되고 골육이 상배되는 이별 동요 불안사가 있고 내사 집안에 타인으로부터 압박을 받는 일이 있다`,
     `@@1@@丁亥 @@2@@인 @@3@@망신@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 골육존장의 화합사가 있으나 오래가지 못한다. 본 사안은 본래 음욕에 있다. 천우신조를 얻는다`,
     `@@1@@丁亥 @@2@@묘 @@3@@자존@@4@@  부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외, 내 집안에 모두 번잡한 결속사가 있다.`,
     `@@1@@丁亥 @@2@@진 @@3@@지위@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 금전소모가 많고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@丁亥 @@2@@사 @@3@@이사, 이동@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라  원행이동사가 있다. 외사에 타인으로부터 도움 받는 일이 있고 내사 집안에 크게 구하나 작게 얻고 조상에 불경한 일로 동요 불안사가 있다`,
     `@@1@@丁亥 @@2@@오 @@3@@건강@@4@@  직녹, 형제사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제자매의 재해가 있고 금전이 손모되며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 을유일은 색정사가 있는 자다 정해일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@丁亥 @@2@@미 @@3@@신앙@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자식의 재해가 있고 금전이 손모되며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 을유일은 색정사가 있는 자다 정해일이면 혼인 불문 독거환경의 태생적 고독인이다  `,
     `@@1@@丁亥 @@2@@신 @@3@@강제, 급속@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다.  상황이 급박하고 강제적 손실사가 우려된다. 외사에 타인을 돕고자 하는 일이 있고 내사 집안에 모사가 불성하고 유시무종 한 일로 손해가 있고 가출사가 있게 된다`,
     `@@1@@丁亥 @@2@@유 @@3@@재해@@4@@  금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 돕는 일이 있고 내사 집안에 타인으로부터 도움을 얻고자 하는 일이 있다`,
     `@@1@@丁亥 @@2@@술 @@3@@숙명@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 형송사가 일어나고 재물이 암체 되며 서로 원수를 맺고 내사 집안에 타인으로부터 압박을 받는 일이 있다`,
     `@@1@@丁亥 @@2@@해 @@3@@변동@@4@@  직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 타인을 압박하는 일이 있고 내사 집안은 막혀 있다`,
     `@@1@@戊子 @@2@@자 @@3@@자존@@4@@ 금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 된다. 기이하게 풀린다 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안이 막혀있다`,
     `@@1@@戊子 @@2@@축 @@3@@지위@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰고 여자를 활용하면 기이하게 풀린다 외사에 개혁시키는 단합사가 있고 내사 집안에 부부 융합의 화합사가 있다. 본 사안은 본래 무정에 있다. 천우신조를 얻는다`,
     `@@1@@戊子 @@2@@인 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 원행이동사가 있다. 외사에 도주관사, 진퇴곤란의 대립 반목사가 있고 서로 원수 맺는다. 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@戊子 @@2@@묘 @@3@@건강@@4@@  직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 도움을 받을 일이 있고 내사 집안에 상하 불화하고 존상이 불리한 무례사가 있다`,
     `@@1@@戊子 @@2@@진 @@3@@신앙@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인을 돕는 일이 있고 내사 집안에 계속될 일의 단합사가 있다`,
     `@@1@@戊子 @@2@@사 @@3@@강제, 급속@@4@@직녹, 명예, 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 외교가 막히고 타인에게 의뢰한 일이 지체되고 있고 상황이 급박하고 강제적 손실사가 우려된다 `,
     `@@1@@戊子 @@2@@오 @@3@@재해@@4@@ 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모재해가 있고 후원이 없으며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 기축일이면 색정사가 있는 자이다 기축일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@戊子 @@2@@미 @@3@@숙명@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제재해가 있고 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 으, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 기축일이면 혼인 불문 독거환경의 태생적 고독인이다  `,
     `@@1@@戊子 @@2@@신 @@3@@변동@@4@@  자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다 외사에 은혜배반, 상하 불손의 반목 대립사가 있고 서로 원수 맺는다. 내사 집안에 길게 갈 결속사가 있다`,
     `@@1@@戊子 @@2@@유 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  색정사가 있는 자다. 외사에 개신시키는 결속사가 있고 내사 집안에 여자 자손의 재해, 파재, 극처사가 있다`,
     `@@1@@戊子 @@2@@술 @@3@@공허@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라. 외사에 침제된 자를 돕는 일이 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@戊子 @@2@@해 @@3@@망신@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 큰 것을 구하나 효과가 적고 조상불경사로 동요 불안, 이별사가 있고 내사 집안에 재물의 소모가 크며 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@己丑 @@2@@자 @@3@@건강@@4@@  금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 된다. 기이하게 풀린다 외사에 관사구설, 암중 재해로 손해 근심이 있고 내사 집안에 부부융합 화합사가 있다. 본 사안은 본래 정중에 있다. 천우신조를 얻는다`,
     `@@1@@己丑 @@2@@축 @@3@@신앙@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰고 여자를 활용하면 기이하게 풀린다 외사에 모망사 불성하고 골육상배사로 동요 불안하고 이별사가 있으며 내사 집안이 막혀 있다`,
     `@@1@@己丑 @@2@@인 @@3@@강제, 급속@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외, 내 집안에 타인으로부터 압박받는 일이 있다. 상황이 급박하고 강제적 손실사가 우려 된다`,
     `@@1@@己丑 @@2@@묘 @@3@@재해@@4@@  직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 번잡한 단합사가 있고 내사 집안에 타인으로 부터 압박받는 일이 있다`,
     `@@1@@己丑 @@2@@진 @@3@@숙명@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 금전소모가 많고 내사에 담, 문, 묘각 등 퇴폐사가 있으며 파재 극처 한다`,
     `@@1@@己丑 @@2@@사 @@3@@변동@@4@@  부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라. 외사에 타인으로부터 도움을 받을 일이 있고 내사 집안에 상황을 개신시키는 결속사가 있다 `,
     `@@1@@己丑 @@2@@오 @@3@@색정, 간음@@4@@직녹, 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모재해가 있고 후원이 없으며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 기축일이면 색정사가 있는 자이다 기축일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량  은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@己丑 @@2@@미 @@3@@공허@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제재해가 있고 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 으, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 기축일이면 혼인 불문 독거환경의 태생적 고독인이다  `,
     `@@1@@己丑 @@2@@신 @@3@@망신@@4@@  자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다 외, 내사 집안에 모두 타인을 돕는 일이 있다`,
     `@@1@@己丑 @@2@@유 @@3@@자존@@4@@  자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 타인을 돕는 일이 있고 내사 집안에 상황을 개신시키는 결속사가 있다`,
     `@@1@@己丑 @@2@@술 @@3@@지위@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라. 외, 내 집안 모두 형송사가 있고 재물이 암체되고 하극상배사, 재물부족의 반목 대립사로 원수 맺는다`,
     `@@1@@己丑 @@2@@해 @@3@@강제, 급속@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외, 내 집안 모두 모두 타인을 압박하는 일이 있다. 원행이동사가 있다`,
     `@@1@@庚寅 @@2@@자 @@3@@재해@@4@@ 자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단, 기이하게 풀린다. 외사에 멈추지 않을 단합사가 있고 내사 집안에 타인으로부터 도움 받을 일이 있다`,
     `@@1@@庚寅 @@2@@축 @@3@@숙명@@4@@ 부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라. 외사에 타인으로부터 도움 받을 일이 있고 외사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@庚寅 @@2@@인 @@3@@변동@@4@@  금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 부부이심 이별사가 있고 동요 불안하고 내사 집안이 막혀있다.`,
     `@@1@@庚寅 @@2@@묘 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  색정사가 있는 자다. 외사에 타인을 압박하는 일이 있고 내사 집안에 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@庚寅 @@2@@진 @@3@@공허@@4@@ 부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 된다. 후원 문서 부친을 활용하라 외사에 타인으로부터 도움 받을 일이 있고 내사 집 안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@庚寅 @@2@@사 @@3@@망신@@4@@  직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 은혜배반, 상하 불손의 대립 반목사로 원수 맺고 내사 집안에 도주관사 진퇴곤란 반목대립 형송사가 있다`,
     `@@1@@庚寅 @@2@@오 @@3@@자존@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직장재해가 있고 여자는 일부종사가 어렵다. 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 `,
     `@@1@@庚寅 @@2@@미 @@3@@지위@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모 문서재해가 있으며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의 해야만 자립할 수 있는 산기자이다 `,
     `@@1@@庚寅 @@2@@신 @@3@@이사, 이동@@4@@직녹, 명예, 형제사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다 외사에 외교가 막히고 타인에 의뢰한 일이 지체되고 있으며 내사 집안에 부부이심사로 동요 불안하다. 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@庚寅 @@2@@유 @@3@@건강@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 외사에 금전의 소모가 많고 내사 집안에 타인으로부터 압박받는 일이 있다. 본 사안은 본래 인의에 있다`,
     `@@1@@庚寅 @@2@@술 @@3@@신앙@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움 받는 일이 있고 내사 집안에 문화광휘적 외화내허한 단합사가 있다`,
     `@@1@@庚寅 @@2@@해 @@3@@강제, 급속@@4@@ 자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 모사불성, 유시무종사로 손해 근심이 있고 내사 집안에 골육존상의 화합사가 있으나 길지 않다. 상황이 급박하고 강제적 손실사가 우려 된다`,
     `@@1@@辛卯 @@2@@자 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단, 기이하게 풀린다. 색정사가 있는 자다. 외사에 타인을 압박하는 일이 있고 내사 집안에 무례한 일로 상하가 불화하고 존상이 불리한 반목 대립사가 있다`,
     `@@1@@辛卯 @@2@@축 @@3@@공허@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라. 외사에 하극 상배, 재물부족의 반목 대립사로 원수가 되고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@辛卯 @@2@@인 @@3@@망신@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 문화광휘적 외화내허 단합사가 있고 내사 집 안에 금전 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@辛卯 @@2@@묘 @@3@@자존@@4@@  금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 형제골육 화합사가 있고 내사 집안이 막혀 있다`,
     `@@1@@辛卯 @@2@@진 @@3@@지위@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 된다. 후원 문서 부친을 활용하라 외사에 희비가 불명하고 하극상배사로 이별사가 있고 내사 집안에 공허무력 만사상쟁사로 손해가 있고 가출사가 있게 된다`,
     `@@1@@辛卯 @@2@@사 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 원행이동사가 있다. 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@辛卯 @@2@@오 @@3@@건강@@4@@  직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직장재해가 있고 여자는 일부종사가 어렵다. 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 `,
     `@@1@@辛卯 @@2@@미 @@3@@신앙@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모 문서재해가 있으며 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의 해야만 자립할 수 있는 산기자이다 `,
     `@@1@@辛卯 @@2@@신 @@3@@강제, 급속@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다  금전을 쓰면 일이 된다. 여인을 활용하라. 기이하게 풀린다. 외사에 타인을 도우는 일이 있고 내사 집안에 타인으로부터 압박받는 일이 있다. 본 사안은 본래 권위에 있다 `,
     `@@1@@辛卯 @@2@@유 @@3@@재해@@4@@  직녹, 형제, 동료사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@금전를 쓰면 일이 된다. 여인을 활용하라 외사에 재병손상사가 있고 수하농락 건으로 손해가 있고 실주사가 기하고 내사 집안에 남녀가 분리 되고 문호가 개변된다. 보통 부부이별이다 `,
     `@@1@@辛卯 @@2@@술 @@3@@숙명@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 외교가 막히고 타인에 의뢰한 일이 지체되고 있고 내사 집안에 형제자매 골육 화합사가 있다`,
     `@@1@@辛卯 @@2@@해 @@3@@변동@@4@@  자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 타인을 압박하는 일이 있고 내사 집안에 번잡한 결속사가 있다`,
     `@@1@@壬辰 @@2@@자 @@3@@자존@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 된다. 여인을 활용하라. 기이하게 풀린다  외사에 금전의 소모가 많고 내사 집안에 멈추지 않을 결속사가 있다 `,
     `@@1@@壬辰 @@2@@축 @@3@@지위@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 담, 문 묘각의 퇴패로 파재 극처사가 있다`,
     `@@1@@壬辰 @@2@@인 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  원행이동사가 있다. 외사에 골육존장과 화합사가 있으나 오래가지 못 하고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@壬辰 @@2@@묘 @@3@@건강@@4@@  자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 번잡한 단합사가 있고 내사 집안에 공허무력, 만사상쟁으로 손해가 있고 가출사가 있게 된다`,
     `@@1@@壬辰 @@2@@진 @@3@@신앙@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 압박받는 일이 있고 내사 집안이 막혀있다`,
     `@@1@@壬辰 @@2@@사 @@3@@강제, 급속@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 강제적인 손실사가 있다 외사에 크게 구하나 작게 얻은 결과와 조상불경사로 동요불안 이별사가 있고 내사 집안에 타인으로부터 도움 받을 일이 있다`,
     `@@1@@壬辰 @@2@@오 @@3@@재해@@4@@ 금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 계사일이면 색정사가 있는 자다. 본 사안은 본래 무정에 있다.  `,
     `@@1@@壬辰 @@2@@미 @@3@@숙명@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직업, 여자는 남편의 재해가 있으며 일부종사가 어렵고 남녀 모두 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립 할 수 있는 산기자이다. 본 사안은 본래 음욕에 있다 `,
     `@@1@@壬辰 @@2@@신 @@3@@변동@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 모사가 불성하고 유시무종사로 손해 근심이 있고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@壬辰 @@2@@유 @@3@@색정, 간음@@4@@ 부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 형제자매 붕우 동료의 화합사가 있다`,
     `@@1@@壬辰 @@2@@술 @@3@@공허@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 희비가 불명한 하극상배로 동요 불안사가 있다`,
     `@@1@@壬辰 @@2@@해 @@3@@망신@@4@@ 직녹, 명예, 동료사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라 외사에 외교사 막히고 타인에게 의뢰한 일이 지체 되고 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@癸巳 @@2@@자 @@3@@건강@@4@@  직녹, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다  외사에 부부융합의 화합사가 있고 내사 집안에 타인으로부터 압박받는 일이 있다 `,
     `@@1@@癸巳 @@2@@축 @@3@@신앙@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다.  외사에 외교가 막혀 있고 타인에게 의뢰한 일이 지체되고 있으며 내사 집안에 상황을 개신시킬 단합사가 있다`,
     `@@1@@癸巳 @@2@@인 @@3@@강제, 급속@@4@@ 자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  상황이 급박하고 강제적 손실사가 우려 된다. 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 도주관사, 진퇴곤란의 반목대립사가 있다`,
     `@@1@@癸巳 @@2@@묘 @@3@@재해@@4@@  자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 타인으로부터 도움 받을 일이 있다`,
     `@@1@@癸巳 @@2@@진 @@3@@숙명@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 담, 문, 묘각 퇴폐사로 파재 극처사가 있고 내사 집안에 타인을 도울`,
     `@@1@@癸巳 @@2@@사 @@3@@변동@@4@@  금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 강제적인 손실사가 있다 외사에 상황을 개신시킬 단합사가 있고 내사 집안이 막혀있다`,
     `@@1@@癸巳 @@2@@오 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 계사일이면 색정사가 있는 자다. 본 사안은 본래 무정에 있다.  `,
     `@@1@@癸巳 @@2@@미 @@3@@공허@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직업, 여자는 남편의 재해가 있으며 일부종사가 어렵고 남녀 모두 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립 할 수 있는 산기자이다. 본 사안은 본래 음욕에 있다 `,
     `@@1@@癸巳 @@2@@신 @@3@@망신@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 타인을 돕는 일이 있고 내사 집안에 은혜배 반, 상하 불손사로 반목 대립 한다`,
     `@@1@@癸巳 @@2@@유 @@3@@자존@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 색정사가 있는 자다. 외, 내 집안 모두 상황을 개신 개혁하는 단합사가 있다. 대개 부부 이별 한다`,
     `@@1@@癸巳 @@2@@술 @@3@@지위@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외사에 하극상배, 재물부족의 반목 대립사가 있고 서로 원수가 되고 내사 집안에 타인을 돕는 일이  있다`,
     `@@1@@癸巳 @@2@@해 @@3@@이사, 이동@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 된다. 여인을 활용하라.  원행이동사가 있다. 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 크게 구하나 결과가 작고 조상불경사로 동요 불안사가 있다`,
     `@@1@@甲午 @@2@@자 @@3@@재해@@4@@  부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라. 기이하게 풀린다  외사에 타인으로부터 도움을 받을 일이 있고 내사 집안에 모사가 변경되고 남녀상쟁사로 동요 불안하다`,
     `@@1@@甲午 @@2@@축 @@3@@숙명@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 외사에 타인을 압박하는 일이 있고 내사 집안에 부부불합, 관송사가 불리한 손해와 가출사가 있게 된다`,
     `@@1@@甲午 @@2@@인 @@3@@변동@@4@@직녹, 명예, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 된다. 직,녹위자를 활용하되 서둘지 말라.  외교가 막히고 타인에게 의뢰한 일이 지체되고 내사 집안에 문화광휘적 단합사가 있으나 외화내허로 실속이 없다`,
     `@@1@@甲午 @@2@@묘 @@3@@색정, 간음@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라. 색정사가 있는 자다.  외사에 금전 소모가 많고 내사 집안에 가정파탄사의 근심이 있고 파재 극처사가 있게 된다`,
     `@@1@@甲午 @@2@@진 @@3@@공허@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 뜻은 인의에 있다. 을미일이면 혼인 불문하고 환경적 독거자이며 고독인이다 `,
     `@@1@@甲午 @@2@@사 @@3@@망신@@4@@자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자손의 재해가 있고 세상일에 무능력자가 된다. 의,복,수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 사안은 본래 정중에 있다 `,
     `@@1@@甲午 @@2@@오 @@3@@자존@@4@@  자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다, 단 기이하게 풀린다 외사에 문화광휘적 결속사가 있으나 외화내허로 실속이 없고 내사 집안이 막혀 있다`,
     `@@1@@甲午 @@2@@미 @@3@@지위@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 공공적 상하 화합사가 있다`,
     `@@1@@甲午 @@2@@신 @@3@@이사, 이동@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  원행이동사가 있다. 외사에 부부이심으로 동요 불안 이별사가 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@甲午 @@2@@유 @@3@@건강@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외사에 타인으로부터 압박받는 일이 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@甲午 @@2@@술 @@3@@신앙@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 희비불명, 하극상배사로 동요 불안 이별사가 있고 내사 집안에 실속 없는 문화광휘사가 있다`,
     `@@1@@甲午 @@2@@해 @@3@@강제, 급속@@4@@  부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라 강제적 손실사가 있다. 외사에 골육존장의 화합사가 있으나 오래 못 가고 내사 집안에 타인으로부터 압  박 받는 일이 있다.`,
     `@@1@@乙未 @@2@@자 @@3@@색정, 간음@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라. 기이하게 풀린다  색정사가 있는 자다. 외사에 오래갈 일의 단합사가 있고 내사 집안에 관사구설, 암중재해로 손해가 있고 가출사가 있게 된다`,
     `@@1@@乙未 @@2@@축 @@3@@공허@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@노력으로 일이 된다,  외사에 담, 문 모각의 퇴패사로 파재 극처사가 있고 내사 집안에 모망사 불성과 골육상배로 동요 불안사가 있다`,
     `@@1@@乙未 @@2@@인 @@3@@망신@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 쎠야 된다. 여자를 활용하라.  외, 내 집안에 모두 타인으로부터 압박을 받는 일이 있다`,
     `@@1@@乙未 @@2@@묘 @@3@@자존@@4@@직녹, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직, 녹,위자를 활용  하되 서둘지 말라. 외사에 공허무력하고 만사상쟁사로 손해근심이 있고 내사 집안에 번잡한 결속사가 있다`,
     `@@1@@乙未 @@2@@진 @@3@@지위@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 뜻은 인의에 있다. 을미일이면 혼인 불문하고 환경적 독거자이며 고독인이다 `,
     `@@1@@乙未 @@2@@사 @@3@@이사,@@4@@이동 자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자손의 재해가 있고 세상일에 무능력자가 된다. 의,복,수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 사안은 본래 정중에 있다 `,
     `@@1@@乙未 @@2@@오 @@3@@건겅@@4@@자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다, 단 기이하게 풀린다 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 공공적 상하 화합사가 있다`,
     `@@1@@乙未 @@2@@미 @@3@@신앙@@4@@금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 금전의 소모가 많고 내사 집안이 막혀 있다`,
     `@@1@@乙未 @@2@@신 @@3@@강제,@@4@@급속 직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외, 내 집안 모두 타인을 돕는 있다`,
     `@@1@@乙未 @@2@@유 @@3@@재해@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외사에 형제, 동료, 친구의 화합사가 있고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@乙未 @@2@@술 @@3@@숙명@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 희비가 불명한 일과 하극상배사로 동요 불안 이별사가 있고 내사 집안에 형송사가 일어나고 재물이 암체 되어 손해가 있고 반목 대립 한다`,
     `@@1@@乙未 @@2@@해 @@3@@변동@@4@@  부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라 외, 내, 집안에 모두 타인을 압박하는 일이 있다`,
     `@@1@@丙申 @@2@@자 @@3@@자존@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 단 기이하게 풀린다 외사에 타인으로부터 압박받는 일이 있고 내사 집안 에 오래갈 단합사가 있다`,
     `@@1@@丙申 @@2@@축 @@3@@지위@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 개신시키는 단합사가 있고 내사 집안에 타인 으로부터 도움을 박자하는 일이 있다`,
     `@@1@@丙申 @@2@@인 @@3@@이사, 이동@@4@@ 부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라  원행이동사가 있다. 외사에 도주관사 진퇴곤란의 반목 대립사로 서로 원수가 되고 있고 내사 집안에 부부이심의 동요 불안사가 있다`,
     `@@1@@丙申 @@2@@묘 @@3@@건강@@4@@  부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 타인을 압박하는 일이 있다. 본 사안은 본래 정중에 있다`,
     `@@1@@丙申 @@2@@진 @@3@@신앙@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자손의 재해가 있고  세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 남의 말에 잘 속는다 `,
     `@@1@@丙申 @@2@@사 @@3@@강제, 급속@@4@@직녹, 명예사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 강제적 손실사가 있다 병신일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@丙申 @@2@@오 @@3@@재해@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라. 기이하게 풀린다 외사에 형재 친구에 의해 금전소모가 있고 내사 집안에 탕ㅣㄴ으로부터 압박을 받는 일이 있다`,
     `@@1@@丙申 @@2@@미 @@3@@숙명@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 타인에게 도움을 주는 일이 있고 내사 집안에 타인으로부터 도움 받을 일이 있다`,
     `@@1@@丙申 @@2@@신 @@3@@변동@@4@@  금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 은혜배반, 상하 불손의 반목 대립사로 서로 원수가 되고 내사 집안이 막혔다`,
     `@@1@@丙申 @@2@@유 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 색정사가 있는 자다. 외사에 개신시키는 결속사가 있고 내사 집안에 금전의 손모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@丙申 @@2@@술 @@3@@공허@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인을 돕는 일이 있고 내사 집안에 도움을 받는 일이 있다`,
     `@@1@@丙申 @@2@@해 @@3@@망신@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 크게 구하나 적게 얻고 조상불경사로 동요 불안 이별사가 있고 내사 집안에 모사불성 유시무종한 일로 손해와 가출사가 있게 된다`,
     `@@1@@丁酉 @@2@@자 @@3@@건강@@4@@  직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 단 기이하게 풀린다 외사에 관사구설 암중재해로 손해가 있어 근심하고 내사 집안에 여자 아손의 재난이 있어 손해와 가출사가 있게 된다`,
     `@@1@@丁酉 @@2@@축 @@3@@신앙@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 타인을 돕는 일이 있고 내사에 상황을 개신 시키는 결속사가 있다`,
     `@@1@@丁酉 @@2@@인 @@3@@강제, 급속@@4@@ 부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라  강제적 손실사가 있다. 외사에 타인으로부터 압박 받는 일이 있고 내사 집안에 타인을 압박하는 일이 있다. 본 사안은 본래 음욕에 있다.`,
     `@@1@@丁酉 @@2@@묘 @@3@@재해@@4@@  부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 번잡한 결속사가 있고 내사 집안에 분리되고 떠나고 문호가 개변되는 남녀상쟁사로 동요 불안한 일이 있다`,
     `@@1@@丁酉 @@2@@진 @@3@@숙명@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자손의 재해가 있고  세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 남의 말에 잘 속는다 `,
     `@@1@@丁酉 @@2@@사 @@3@@변동@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 강제적 손실사가 있다 병신일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@丁酉 @@2@@오 @@3@@색정, 간음@@4@@직녹, 형제사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직, 녹, 위자를 활용하되 서둘지 말라. 기이하게 풀린다. 색정사가 있는 자다. 외사에 상하관계의 화합사가 있고 내사 집안에 타인으로부터 압박을 받는 일이 있다`,
     `@@1@@丁酉 @@2@@미 @@3@@공허@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사가 막히고 타인에게 의뢰한 일이 지체되고 내사 집안에 타인으로부터 도움 받을 일이 있다`,
     `@@1@@丁酉 @@2@@신 @@3@@망신@@4@@  금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 타인에게 도움 줄 일이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@丁酉 @@2@@유 @@3@@자존@@4@@  금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 돕는 일이 있고 내사 집안이 막혀 있다`,
     `@@1@@丁酉 @@2@@술 @@3@@지위@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 형송사가 일어나고 재물부족의 반목대립사로 원수가 되고 내사 집안에 재난과 병재손상, 수하농락사의 손해로 가출사가 있게 된다`,
     `@@1@@丁酉 @@2@@해 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  원행이동사가 있다 외, 내 집안 모두 타인에게 도움을 주는 일이 있다`,
     `@@1@@戊戌 @@2@@자 @@3@@재해@@4@@ 금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 된다, 기이하게 풀린다 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@戊戌 @@2@@축 @@3@@숙명@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라 외사에 개신시키는 결속사가 있고 내사 집안에 하극상배, 재물부족사로 형송사가 일어나고 반목 대립한다. 본 사안은 본래 무정에 있다`,
     `@@1@@戊戌 @@2@@인 @@3@@변동@@4@@  직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 도주관사, 진퇴곤란사로 반목 대립하고 내사 집안에 실속 없는 문화광휘사가 있다`,
     `@@1@@戊戌 @@2@@묘 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  색정사가 있는 자다. 외사에 타인으로부터 도움을 받는 일이 있고 내사 집안에 형제골육의 화합사가 있다`,
     `@@1@@戊戌 @@2@@진 @@3@@공허@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@戊戌 @@2@@사 @@3@@망신@@4@@  직녹, 명예, 부모, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  무술일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@戊戌 @@2@@오 @@3@@자존@@4@@ 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외교가 막히고 타인에게 의뢰한 일이 지체되고 내사 집안에 문화광휘적 단합사가 있으나 외화내허로 실속이 없다`,
     `@@1@@戊戌 @@2@@미 @@3@@지위@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외사에 타인을 돕는 일이 있고 내사 집안에 형송사가 일어나고 재물이 암손 되는 반목 대립사가 있다`,
     `@@1@@戊戌 @@2@@신 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 원행이동사가 있다. 외사에 은혜배반 상하 불손한 반목대립사로 원수가 되고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@戊戌 @@2@@유 @@3@@건강@@4@@  자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 개신시키는 결속사가 있고 내사 집안에 재병 손상, 수하농락사가 있고 손해가 나고 가출사가 있게 된다`,
     `@@1@@戊戌 @@2@@술 @@3@@신앙@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라 외사에 타인을 돕는 일이 있고, 내사 집안이 막혀 있다`,
     `@@1@@戊戌 @@2@@해 @@3@@강제, 급속@@4@@ 금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  강제적 손실사가 있다. 외사에 크게 구하나 적게 얻고 조상불경사로 동요 불안하고 이별사를 맞고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@己亥 @@2@@자 @@3@@색정, 간음,@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 된다, 기이하게 풀린다 색정사가 있는 자다. 외사에 관사구설, 암중재해사로 손해와 근심이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다. 본 사안은 본래 정중에 있다`,
     `@@1@@己亥 @@2@@축 @@3@@공허@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라 외사에 모망사 불성하고 골육상배의 동요 불안 이별사가 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@己亥 @@2@@인 @@3@@망신@@4@@  직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 골육존장의 화합사가 있으나 오래 못 간다`,
     `@@1@@己亥 @@2@@묘 @@3@@자존@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외, 내 집안에 모두 번잡한 결속사가 있다`,
     `@@1@@己亥 @@2@@진 @@3@@지위@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@己亥 @@2@@사 @@3@@이사 이동@@4@@부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 무술일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@己亥 @@2@@오 @@3@@건강@@4@@ 직녹, 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다  외사에 공공적 상하 하합사가 있고 내사 집안에 타인을 압박하는 일이 있다 `,
     `@@1@@己亥 @@2@@미 @@3@@신앙@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외사에 외교가 막히고 타인에게 의뢰한 일이 지체 되며 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@己亥 @@2@@신 @@3@@강제, 급속@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인을 돕는 일이 있고 내사에 모사가 불성하고 유시무종한 일로 손해가 있고 가출사가 일어난다. 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@己亥 @@2@@유 @@3@@재해@@4@@ 자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 타인을 돕는 일이 있고 내사 집안에 타인으로부터 도움을 받는 일이 있다`,
     `@@1@@己亥 @@2@@술 @@3@@숙명@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라 외사에 형송사가 일어나고 재물이 암체 손실되어 근심하고 원수 맺는다. 내사 집안에 타인으로부터 압박을 받는다`,
     `@@1@@己亥 @@2@@해 @@3@@변동@@4@@ 금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다  외사에 타인을 압박하는 일이 있고 내사 집안이 막혀 있다`,
     `@@1@@庚子 @@2@@자 @@3@@자존@@4@@ 자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다. 외사에 계속 진행될 단합사가 있고 내사 집안은 막혀 있다`,
     `@@1@@庚子 @@2@@축 @@3@@지위@@4@@ 부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 부부융합의 화합사가 있다`,
     `@@1@@庚子 @@2@@인 @@3@@이사, 이동@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  원행이동사가 있다. 외사에 부부이심으로 동요 불안 이별사가 있고 내사 집안에 타인으로부터 도움 받는 일이 있다`,
     `@@1@@庚子 @@2@@묘 @@3@@건강@@4@@  금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 상하 불화, 존상 불리한 무례사가 있다`,
     `@@1@@庚子 @@2@@진 @@3@@신앙@@4@@ 부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@상황이 급박하고 강제적 손실사가 우려 된다. 모든 일이 헛소리에 불과하고 불성하며 부모의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@庚子 @@2@@사 @@3@@강제, 급속@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직장의 재해가 있고 여자는 일부종사를 못하고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@庚子 @@2@@오 @@3@@재해@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직자는 좋다. 기이하게 풀린다 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 모사 변경, 남녀상쟁으로 동요불안하다`,
     `@@1@@庚子 @@2@@미 @@3@@숙명@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인으로부터 도움을 얻고자 하는 일이 있고 내사 집안에 관사구설 암중재해사가 있어 손해가 있고 가출사가 발생 한다`,
     `@@1@@庚子 @@2@@신 @@3@@변동@@4@@  직녹, 명예, 형제사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 외사에 외교가 막히고 타인에게 의뢰한 일이 지체 되고 내사 집안에 계속 이어질 일의 단합사가 있다`,
     `@@1@@庚子 @@2@@유 @@3@@색정, 간음@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@색정사가 있고 간음이다 금전를 쓰면 일이 된다. 여인을 활용하라. 색정사가 있는 자다. 외사에 금전소모가 많고 내사 집안에 여자나 아이의 재해가 있고 파재 극처사가 있다. 본 사안은 본래 인의에 있다`,
     `@@1@@庚子 @@2@@술 @@3@@공허@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@庚子 @@2@@해 @@3@@망신@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 모사가 불성하고 유시무종한 일로 손해가 있고 근심하며 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@辛丑 @@2@@자 @@3@@건강@@4@@  자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다. 외사에 타인을 압박하는 일이 있고 내사 집안에 부부부융합의 화합사가 있다`,
     `@@1@@辛丑 @@2@@축 @@3@@신앙@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 `,
     `@@1@@辛丑 @@2@@인 @@3@@강제, 급속@@4@@ 금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  강제적인 손실사가 있다. 외사에 문화광휘적 외화내허 한 결속사가 있고 내사 집안에 타인으로부터 압박 받는 일이 있다`,
     `@@1@@辛丑 @@2@@묘 @@3@@재해@@4@@  금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 형제골육의 화합사가 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@辛丑 @@2@@진 @@3@@숙명@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@상황이 급박하고 강제적 손실사가 우려 된다. 모든 일이 헛소리에 불과하고 불성하며 부모의 재해가 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@辛丑 @@2@@사 @@3@@변동@@4@@  직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직장의 재해가 있고 여자는 일부종사를 못하고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@辛丑 @@2@@오 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직자는 좋다. 기이하게 풀린다 색정사가 있는 자다. 외사에 실속 없는 문화광휘사가 있고 내사 집안에 부부불화, 관송사로 손해가 있으며 가출사가 있게 된다`,
     `@@1@@辛丑 @@2@@미 @@3@@공허@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 하극상배, 재물부족사로 반목 대립하고 서로 원수 맺으며 내사 집안에 모망사 불성과 골육상배의 동요 불안사가 있다`,
     `@@1@@辛丑 @@2@@신 @@3@@망신@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라. 본 사안은 본래 권위에 있다`,
     `@@1@@辛丑 @@2@@유 @@3@@자존@@4@@  직녹, 명예, 형제사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 외사에 재병손상 수하농락사로 손해 근심이 있고 내사 집안에 상황을 개신시킬 결속사가 있다`,
     `@@1@@辛丑 @@2@@술 @@3@@지위@@4@@  부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 외교가 막히고 타인에게 의뢰한 일이 지체 되고 있고 내사 집안에 하극상배, 재물부족사로 반목 대립하는 형송사가 있다`,
     `@@1@@辛丑 @@2@@해 @@3@@이사, 변동@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 원행이동사가 있다. 외, 내 집안 모두 타인을 압박하는 일이 있다`,
     `@@1@@壬寅 @@2@@자 @@3@@재해@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 된다. 여인을 활용하라. 기이하게 풀린다 외사에 금전의 소모가 많고 내사에 타인으로부터 도움을 얻고자 하는 일이 있다`,
     `@@1@@壬寅 @@2@@축 @@3@@숙명@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 타인으로부터 압박받는 일이 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@壬寅 @@2@@인 @@3@@변동@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 골육존장의 화합사가 있으나 길지 않고 내사 집안은 막혀 있다`,
     `@@1@@壬寅 @@2@@묘 @@3@@색정, 간음@@4@@  자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 색정사가 있는 자다. 외사에 번잡한 결속사가 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@壬寅 @@2@@진 @@3@@공허@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직장의 재해가 있고 여자는 일부종사를 못하고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@壬寅 @@2@@사 @@3@@망신@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 금전과 여자의 재해가 있고 여자는 남자를 천하게 만든다. 남녀 모두의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자 이다. 계묘일이면 원행이동사가 있다  `,
     `@@1@@壬寅 @@2@@오 @@3@@자존@@4@@  금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 타인을 압박하는 일이 있고 내사 집안에 실속없는 문화광휘사가 있다`,
     `@@1@@壬寅 @@2@@미 @@3@@지위@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 타인을 압박하는 일이 있다. 본 사안은 본래 음욕에 있다`,
     `@@1@@壬寅 @@2@@신 @@3@@이사, 이동@@4@@ 부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라.  원행이동사가 있다 외사에 모사가 불성하고 유시무종하여 손해가 있고 근심하고 내사 집안에는 부부 이심으로 동요불안사가 있다`,
     `@@1@@壬寅 @@2@@유 @@3@@건강@@4@@부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@壬寅 @@2@@술 @@3@@신앙@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외사에 타인으로부터 압박받는 일이 있고 내사 집안에 외화내허적 문화광휘사가 있다`,
     `@@1@@壬寅 @@2@@해 @@3@@강제, 급속@@4@@  직녹, 명예, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라. 상황이 급박하고 강제적인 손실사가 우려 된다 외사에 외교가 막히고 타인에게 의뢰한 일이 지체되어 있고 내사 집안에 골육존장의 화합사가 있으나 오래가지 않는다`,
     `@@1@@癸卯 @@2@@자 @@3@@색정, 간음@@4@@ 직녹, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라. 색정사가 있는 자다. 외사에 부부융합의 화합사가 있고 내사 집안에 상하 불화 존상이 불리한 무례사가 있다 `,
     `@@1@@癸卯 @@2@@축 @@3@@공허@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 외교가 막히고 타인에게 의뢰한 일이 지체 되고 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@癸卯 @@2@@인 @@3@@망신@@4@@ 자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@癸卯 @@2@@묘 @@3@@자존@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안이 막혀있다`,
     `@@1@@癸卯 @@2@@진 @@3@@지위@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직장의 재해가 있고 여자는 일부종사를 못하고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@癸卯 @@2@@사 @@3@@이사, 이동@@4@@ 금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 금전과 여자의 재해가 있고 여자는 남자를 천하게 만든다. 남녀 모두의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자 이다. 계묘일이면 원행이동사가 있다  `,
     `@@1@@癸卯 @@2@@오 @@3@@건강@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 부부불화, 관송사가 불리한 일로 손해와 근심이 있고 내사 집안에 가정파탄의 파재 극처사가 있다. 본 사안은 본래 무정에 있다.`,
     `@@1@@癸卯 @@2@@미 @@3@@신앙@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 모망사 불성하고 골육이 상배되는 동요 불안 이별사가 있고 내사 집안에는 번잡한 결속사가 있다`,
     `@@1@@癸卯 @@2@@신 @@3@@강제, 급속@@4@@ 부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라.  강제적 손실사가 있다. 외사에 타인을 돕는 일이 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@癸卯 @@2@@유 @@3@@재해@@4@@부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 상황을 개신시키는 단합사가 있고 내사 집안에 분리되고 가출하고 문호개변의 동요 불안사가 있다`,
     `@@1@@癸卯 @@2@@술 @@3@@숙명@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외사에 하극상배 재물부족의 반목 대립사가 있고 서로 원수 맺고 내사 집안에 형제골육의 화합사가 있다`,
     `@@1@@癸卯 @@2@@해 @@3@@변동@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 된다. 여인을 활용하라 외사에 타인을 압박하는 일이 있고 내사 집안에 번잡 굴신적 단합사가 있다`,
     `@@1@@甲辰 @@2@@자 @@3@@자존@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라.  외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 계속 이어질 단합사가 있다`,
     `@@1@@甲辰 @@2@@축 @@3@@지위@@4@@ 금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 담, 문, 묘각의 퇴패사로 파재 극처사가 있다`,
     `@@1@@甲辰 @@2@@인 @@3@@이사, 이동@@4@@직녹, 명예, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@직, 녹을 구하나 모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 원행이동사가 있다. 갑진일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다 을사일 이면, 상황이 급박하고 강제적 손실사가 우려 된다  `,
     `@@1@@甲辰 @@2@@묘 @@3@@건강@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@금전과 여자의 재해가 있고 여자는 남자를 추하게 한다. 모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 을묘일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@甲辰 @@2@@진 @@3@@신앙@@4@@ 금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력하면 일이 된다. 기이하게 풀린다.  외사에 타인을 압박하는 일이 있고 내사 집안은 막혀 있다. 본 사안은 본래 인의에 있다`,
     `@@1@@甲辰 @@2@@사 @@3@@강제, 급속@@4@@자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다,  외사에 도주관사 진퇴곤란사로 반목 대립이 있고 내사 집안에 타인으로부터 도움 받고자 하는 일이 있다. 본 사안은 본래 정중에 있다`,
     `@@1@@甲辰 @@2@@오 @@3@@재해@@4@@ 자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다,  외사에 실속 없는 문화광휘적 단합사가 있고 내사 집안에 타인으로부터 도움 받고자 하는 일이 있다`,
     `@@1@@甲辰 @@2@@미 @@3@@숙명@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@甲辰 @@2@@신 @@3@@변동@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 부부이심으로 동요 불안 이별사가 있고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@甲辰 @@2@@유 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 색정사가 있는 자다. 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 형제자매의 화합사가 있다`,
     `@@1@@甲辰 @@2@@술 @@3@@공허@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 희비가 불명하고 하극상배적 동요불안사가 있다`,
     `@@1@@甲辰 @@2@@해 @@3@@망신@@4@@부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라. 기이하게 풀린다.  외사에 육친존장의 화합사가 있으나 오래 못 가고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@乙巳 @@2@@자 @@3@@건강@@4@@  부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 순탄하게 된다. 후원, 문서, 모친을 활용하라.  외사에 계속 이어질 단합사가 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@乙巳 @@2@@축 @@3@@신앙@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 상황을 개혁 개신시키는 단합사가 있다.`,
     `@@1@@乙巳 @@2@@인 @@3@@강제, 급속@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@직, 녹을 구하나 모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 원행이동사가 있다. 갑진일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다 을사일 이면, 상황이 급박하고 강제적 손실사가 우려 된다  `,
     `@@1@@乙巳 @@2@@묘 @@3@@재해@@4@@  직녹, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@금전과 여자의 재해가 있고 여자는 남자를 추하게 한다. 모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.을묘일이면 혼인 불문 독거환경의 태생적 고독인이다. 도량은 크나 되는 일이 없고 다성 다패 한다  `,
     `@@1@@乙巳 @@2@@진 @@3@@숙명@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력하면 일이 된다. 기이하게 풀린다. 외사에 외교가 막히고 타인에게 의뢰한 일이 지체 되어 있고 내사 집안에 타인을 돕는 일이 있다 상황이 급박하고 강제적 손실사가 우려 된다 `,
     `@@1@@乙巳 @@2@@사 @@3@@변동@@4@@  자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다,  외사에 타인으로부터 도움을 얻고자 하는 일이 있고 내사 집안이 막혀 있다`,
     `@@1@@乙巳 @@2@@오 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다,  색정사가 있는 자다. 외사에 타인으로부터 도움 받고자 하는 일이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@乙巳 @@2@@미 @@3@@공허@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 금전의 소모가 많고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@乙巳 @@2@@신 @@3@@망신@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 타인을 돕는 일이 있고 내사에 은혜배반, 상하 불손의 반목 대립사가 있다`,
     `@@1@@乙巳 @@2@@유 @@3@@자존@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 형제자매 붕우 동료의 화합사가 있고 내사 집안에 개신시키는 결속사가 있다`,
     `@@1@@乙巳 @@2@@술 @@3@@지위@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 희비가 불명하고 하극상배적 동요 불안 이별사가 있다`,
     `@@1@@乙巳 @@2@@해 @@3@@이사, 이동@@4@@부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라. 기이하게 풀린다.  외사에 타인을 압박하는 일이 있고 내사 집안에 크게 원한 일이 적게 된 일과 조상불경사로 동요 불안하다. 원행이동사가 있다`,
     `@@1@@丙午 @@2@@자 @@3@@재해@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 계획사가 틀어져 남녀상쟁사로 동요 불안하다`,
     `@@1@@丙午 @@2@@축 @@3@@숙명@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 외사에 상황을 개신시키는 별리적 결속사가 있고 내사 집안에 부부 불합, 불리한 관송사로 반목하고 대립 한다`,
     `@@1@@丙午 @@2@@인 @@3@@변동@@4@@  부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모, 문서 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 사안은 본래 정중에 있다 `,
     `@@1@@丙午 @@2@@묘 @@3@@색정,@@4@@  간음 부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@색정사가 있는 자다. 부모, 문서재해가 있고 모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 사안은 본래 음욕에 있다. `,
     `@@1@@丙午 @@2@@진 @@3@@공허@@4@@ 자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다  외, 내 집안 모두 타인을 돕는 일이 있다`,
     `@@1@@丙午 @@2@@사 @@3@@망신@@4@@  직녹, 명예, 형제사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직, 녹,위자를 활용하되 서둘지 말라  외사에 외교가 막히고 타인에게 의뢰한 일이 지체 되어 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다 `,
     `@@1@@丙午 @@2@@오 @@3@@자존@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라 외사에 금전의 소모가 많고 내사 집안이 막혀 있다`,
     `@@1@@丙午 @@2@@미 @@3@@지위@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 타인을 돕는 일이 있고 내사 집안에 공공적 상하 화합사가 있다`,
     `@@1@@丙午 @@2@@신 @@3@@이사,@@4@@  이동 금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 외사에 은혜배반사와 상하불손 한 대립반목사가 있고 서로 원수가 된다. 내사 집안에 타인을 압박하는 일이 있다. 원행이동사가 있다`,
     `@@1@@丙午 @@2@@유 @@3@@건강@@4@@  금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 개혁 개신 이별적 결속사가 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@丙午 @@2@@술 @@3@@신앙@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인을 돕는 일이 있고 내사 집안에 외화 내허적인 실속 없는 문화광휘 한 결속사가 있다`,
     `@@1@@丙午 @@2@@해 @@3@@강제, 급속@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다  외사에 크게 구하는 일이 적게 되고 조상 불경적인 일로 동요 불안 이별사가 있고 상황이 급박하고 강제적 손실사가 우려 된다`,
     `@@1@@丁未 @@2@@자 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 색정사가 있는 자다. 외, 내 집안 모두 관사구설, 암중 재해로 손해가 있고 가출사가 일어난다`,
     `@@1@@丁未 @@2@@축 @@3@@공허@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. `,
     `@@1@@丁未 @@2@@인 @@3@@망신@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모, 문서 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 사안은 본래 정중에 있다 `,
     `@@1@@丁未 @@2@@묘 @@3@@자존@@4@@  부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@색정사가 있는 자다. 부모, 문서재해가 있고 모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 사안은 본래 음욕에 있다. `,
     `@@1@@丁未 @@2@@진 @@3@@지위@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다  외, 내 집안 모두 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@丁未 @@2@@사 @@3@@이사, 이동@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라 돈을 써야 된다. 여자를 활용하라. 외, 내 집안 모두 타인으로부터 도움을 얻고자 하는 일이 있다. 원행 이동사가 있다`,
     `@@1@@丁未 @@2@@오 @@3@@건강@@4@@  직녹, 형제사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직, 녹,위자를 활용 하되 서둘지 말라 내 집안 모두 공공적 상하 화합사가 있다`,
     `@@1@@丁未 @@2@@미 @@3@@신앙@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외, 내 집안이 외교, 의뢰, 금전 등이 모두 막혀 있다`,
     `@@1@@丁未 @@2@@신 @@3@@강제,@@4@@  급속 금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 외, 내 집안에 모두 타인을 돕는 일이 있다. 상황이 급박하고 강제적 손실사가 우려 된다.`,
     `@@1@@丁未 @@2@@유 @@3@@재해@@4@@  금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외, 내 집안 모두 타인을 돕는 일이 있다`,
     `@@1@@丁未 @@2@@술 @@3@@숙명@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사 내사 집안 모두 타인을 압박하는 일이 있다`,
     `@@1@@丁未 @@2@@해 @@3@@변동@@4@@  직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다  외, 내 집안 모두 타인을 압박하는 일이 있다`,
     `@@1@@戊申 @@2@@자 @@3@@자존@@4@@ 금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다, 기이하게 풀린다 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 계속되어질 단합사가 있다`,
     `@@1@@戊申 @@2@@축 @@3@@지위@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라 외사에 상황을 개신시키는 결속사가 있고 내사 집안에 타인으로부터 도움 받는 일이 있다. 본 사안은 본래 무정에 있다.`,
     `@@1@@戊申 @@2@@인 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 원행이동사가 있다. 남자는 직업의 재해가 있고 여자는 일부종사를 할 수 없다 기유일이면, 상황이 급박하고 강제적인 손실사가 우려 된다  `,
     `@@1@@戊申 @@2@@묘 @@3@@건강@@4@@  직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 남자는 직업의 재해가 있고 여자는 일부종사를 할수 없다 `,
     `@@1@@戊申 @@2@@진 @@3@@신앙@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라. 기이하게 풀린다 외사에 타인을 돕는 일이 있고 내사 집안에 타인으로부터 도움 받고자 하는 일이 있다`,
     `@@1@@戊申 @@2@@사 @@3@@강제, 급속@@4@@직녹, 명예, 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹, 위자를 활용하되 서둘지 말라 외사에 외교가 막히고 타인에게 의뢰한 일이 지체되어 있고 내사에 은혜배반, 상하 불손의 반목 대립사가 있다. 상황이 급박하고 강제적 손실사가 우려 된다 `,
     `@@1@@戊申 @@2@@오 @@3@@재해@@4@@ 부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라. 외사에 금전의 소모가 많고 내사 집안에 타인으로 부터 압박받는 일이 있다  `,
     `@@1@@戊申 @@2@@미 @@3@@숙명@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외사에 타인을 돕는 일이 있고 내사에 타인으로부터 도움을 받고자 하는 일이 있다`,
     `@@1@@戊申 @@2@@신 @@3@@변동@@4@@  자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 은혜배반, 상하 불손사로 반목 대립하고 서로 원수가 된다. 내사 집안이 막혀 있다`,
     `@@1@@戊申 @@2@@유 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 상황을 개신시키는 결속사가 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@戊申 @@2@@술 @@3@@공허@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라 외사에 타인을 돕는 일이 있고 내사 집안에 실속이 없는 문화광휘적 결속사가 있다`,
     `@@1@@戊申 @@2@@해 @@3@@망신@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 크게 구하나 적게 얻고 조상불경사로 동요불안 이별사가 있고 내사 집안에 타인으로부터 압박 받는 일이 있으며 상황이 급박하고 강제적 손실사가 우려 된다`,
     `@@1@@己酉 @@2@@자 @@3@@건강@@4@@  금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다, 기이하게 풀린다 외사에 관사구설 암중재해사로 손해근심이 있고 내사 집안에 여자, 아이 건으로 파재 극처사가 있다. 본 사안은 본래 정중에 있다`,
     `@@1@@己酉 @@2@@축 @@3@@신앙@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라 외사에 모망사가 불성하고 골육이 상배하는 일로 동요 불안 이별사가 있고 내사 집안에 상황을 개신시키는 결속사가 있다`,
     `@@1@@己酉 @@2@@인 @@3@@강제, 급속@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 원행이동사가 있다. 남자는 직업의 재해가 있고 여자는 일부종사를 할 수 없다 기유일이면, 상황이 급박하고 강제적인 손실사가 우려 된다  `,
     `@@1@@己酉 @@2@@묘 @@3@@재해@@4@@  직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 남자는 직업의 재해가 있고 여자는 일부종사를 할수 없다 `,
     `@@1@@己酉 @@2@@진 @@3@@숙명@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라. 기이하게 풀린다 외사에 금전의 소모가 많고 내사 집안에 형제 친구 동료의 화합사가 있다`,
     `@@1@@己酉 @@2@@사 @@3@@변동@@4@@  부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라 외사에 타인으로부터 도움 받는 일이 있고 내사 집안에 상황을 개신시키는 결속사가 있다 `,
     `@@1@@己酉 @@2@@오 @@3@@색정, 간음@@4@@직녹, 부모, 문서, 후원, 형제사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위 자를 활용하되 서둘지 말라. 색정사가 있는 자다 외사에 공공적 상하 화합사가 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@己酉 @@2@@미 @@3@@공허@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외사에 외교가 막히고 타인에게 의뢰한 일도 지체되고 내사 집안에 타인으로부터 도움 받고자 하는 일이 있다`,
     `@@1@@己酉 @@2@@신 @@3@@망신@@4@@  자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다 외사에 타인을 돕는 일이 있고 내사 집안에 금전 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@己酉 @@2@@유 @@3@@자존@@4@@  자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 타인을 돕는 일이 있고 내사 집안이 막혀 있다`,
     `@@1@@己酉 @@2@@술 @@3@@지위@@4@@  형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라 외사에 형송사가 일어나고 재물이 암체되어 대립 반목하고 서로 원수 맺는다. 내사 집안에 재병손상사와 수하농락 건이 있다`,
     `@@1@@己酉 @@2@@해 @@3@@이사, 이동@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다  외사에 타인을 압박하는 일이 있고 내사 집안에 타인으로부터 압박받는 일이 있다 원행이동사가 있다 `,
     `@@1@@庚戌 @@2@@자 @@3@@재해@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 멈추지 않고 계속될 결속사가 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@庚戌 @@2@@축 @@3@@숙명@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움을 받을 일이 있고 내사 집안에 하극상배사와 재물부족사로 반목 대립하는관송사가 있다`,
     `@@1@@庚戌 @@2@@인 @@3@@변동@@4@@ 금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@庚戌 @@2@@묘 @@3@@색정, 간음@@4@@  금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 경술일이면 색정사가 있는 자다  `,
     `@@1@@庚戌 @@2@@진 @@3@@공허@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 타인으로부터 도움을 받을 일이 있고 내사 집안에 희비불명 하극상배사로 동요불안사가 있다`,
     `@@1@@庚戌 @@2@@사 @@3@@망신@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 외사에 은혜배반 상하 불손으로 반목대립사가 있고 서로 원수가 된다. 내사 집안에 타인으로부터 도움받을 일이 있다`,
     `@@1@@庚戌 @@2@@오 @@3@@자존@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다.  외사에 타인으로부터 압박 받는 일이 있고 내사 집안에 실속 없는 문화광휘적 단합사가 있다`,
     `@@1@@庚戌 @@2@@미 @@3@@지위@@4@@ 부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 재물암체, 대립반목사로 형송사가 있다`,
     `@@1@@庚戌 @@2@@신 @@3@@이사, 이동@@4@@  직녹, 명예, 형제사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 외사에 외교가 막히고 타인에게 의뢰한 일이 지체 되어 있고 내사 집안에 타인을 돕고자 하는 일이 있다. 원행 이동사가 있다`,
     `@@1@@庚戌 @@2@@유 @@3@@건강@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@금전를 쓰면 일이 된다. 여인을 활용하라.  외사에 금전의 소모가 많고 내사 집안에 재병손상과 수하농락 건으로 손해가 있고 가출사가 일어난다. 본 사안은 본래 인의에 있다`,
     `@@1@@庚戌 @@2@@술 @@3@@이동@@4@@ 부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움 받을 일이 있고 내사 집안이 막혀있다`,
     `@@1@@庚戌 @@2@@해 @@3@@강제, 급속@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다  외사에 모사불성 유시무종사로 손해 근심이 있고 내사 집안에 타인을 압박하는 일이 있다 상황이 급박하고 강제적 손실사가 우려 된다 `,
     `@@1@@辛亥 @@2@@자 @@3@@색정, 간음@@4@@  자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  색정사가 있는 자다. 외사에 타인을 압박하는 일이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@辛亥 @@2@@축 @@3@@공허@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 하극상배사와 재물부족사로 반목 대립하는 하는 일로 원수가 된다`,
     `@@1@@辛亥 @@2@@인 @@3@@망신@@4@@ 금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@辛亥 @@2@@묘 @@3@@자존@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전과 여자의 손실이 있고 세상일에 무능력자가 된다. 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다 경술일이면 색정사가 있는 자다  `,
     `@@1@@辛亥 @@2@@진 @@3@@지위@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라. 기이하게 풀린다 외사에 희비불명 하극상배사로 동요불안, 이별사가 있고 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@辛亥 @@2@@사 @@3@@이사, 이동@@4@@  직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 크게 구하나 작게 얻은 일과 조상불경사로 동요불안사가 있다. 원행이동사가 있다`,
     `@@1@@辛亥 @@2@@오 @@3@@건강@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다.  외사에 실속 없는 문화광휘적 단합사가 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@辛亥 @@2@@미 @@3@@신앙@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 재물이 암체 되고 대립 반목하는 일이 있고 내사 집안에 타인으로부터 압박 받는 일이 있다`,
     `@@1@@辛亥 @@2@@신 @@3@@강제, 급속@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인을 돕는 일이 있고 네사 집안에 모사불성 유시무종 한 일로 손해가 있고 가출사가 일어난다. 상황이 급박하고 강제적인 손실사가 우려 된다. 본 사안은 본래 권위에 있다`,
     `@@1@@辛亥 @@2@@유 @@3@@재해@@4@@직녹, 형제사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라  외사에 재병손상 수하농락사로 손해 근심이 있고 내사 집안에 타인으로부터 도움 받을 일이 있다  `,
     `@@1@@辛亥 @@2@@술 @@3@@숙명@@4@@부모, 문서, 후원사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 외교사 막히고 타인에 의뢰한 일도 지체되고 있으며 내사 집안에 타인으로부터 압박 받는 일이 있다`,
     `@@1@@辛亥 @@2@@해 @@3@@변동@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 단 기이하게 풀린다 외사에 타인을 압박하는 일이 있고 내사 집안이 막혀 있다`,
     `@@1@@壬子 @@2@@자 @@3@@자존@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 된다. 여인을 활용하라. 외사에 금전의 소모가 많고 내사 집안이 막혀 있다`,
     `@@1@@壬子 @@2@@축 @@3@@지위@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 타인으로부터 압박 받는 일이 있고 내사 집안에 부부융합의 화합사가 있다`,
     `@@1@@壬子 @@2@@인 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 원행이동사가 있다 `,
     `@@1@@壬子 @@2@@묘 @@3@@건강@@4@@  자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가  있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@壬子 @@2@@진 @@3@@신앙@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사에는 좋다, 기이하게 풀린다 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 멈추지 않고 계속될 단합사가 있다`,
     `@@1@@壬子 @@2@@사 @@3@@강제, 급속@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 중구 경득, 조상불경사로 동요불안 이별사가 있고 내사 집안에 타인을 압박하는 일이 있다. 상황이 급하고 강제적 손실사가 우려 된다`,
     `@@1@@壬子 @@2@@오 @@3@@재해@@4@@ 금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 타인을 압박하는 일이 있고 내사 집안에 계획사 변경으로 남녀상쟁의 동요불안사가 있다`,
     `@@1@@壬子 @@2@@미 @@3@@숙명@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 압박 받는 일이 있고 내사 집안에 관사구설 암중재해로 가출사가 있다. 본 사안은 본래 음욕에 있다`,
     `@@1@@壬子 @@2@@신 @@3@@변동@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 모사불성 유시무종한 손해사로 근심하고 내사 집안에 멈추지 않고 계속될 단합사가 있다.`,
     `@@1@@壬子 @@2@@유 @@3@@색정, 간음@@4@@부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 색정사가 있는 자다. 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 여자 또는 자손의 재해로 파재 극처사가 있다`,
     `@@1@@壬子 @@2@@술 @@3@@공허@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외, 내, 집안 모두 타인으로부터 압박을 받는 일이 있다`,
     `@@1@@壬子 @@2@@해 @@3@@망신@@4@@직녹, 명예, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다  외사에 외교사 막히고 타인에게 의뢰한 일이 지체 되고 있으며 내사 집안에 금전의 소모가 많고 남자  는 여자에 갈등하고 여자는 남자에 번민 한다 `,
     `@@1@@癸丑 @@2@@자 @@3@@건강@@4@@  직녹, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라 외, 내 집안에 부부융합의 화합사가 있다`,
     `@@1@@癸丑 @@2@@축 @@3@@신앙@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외, 내 집안 모두 막혀 있다`,
     `@@1@@癸丑 @@2@@인 @@3@@강제, 급속@@4@@ 자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  상황이 급박하고 강제적 손실사가 우려 된다`,
     `@@1@@癸丑 @@2@@묘 @@3@@재해@@4@@  자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가  있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@癸丑 @@2@@진 @@3@@숙명@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사에는 좋다, 기이하게 풀린다 외사에 담, 문, 묘각 퇴폐사로 파재 극처사가 있고 내사 집안에 타인으로부터 압박 받는 일이 있다`,
     `@@1@@癸丑 @@2@@사 @@3@@변동@@4@@  금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외, 내 집안 모두 개신시킬 개혁 단합사가 있다`,
     `@@1@@癸丑 @@2@@오 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  색정사가 있는 자다. 외, 내 집안 모두 부부 불합, 관송사 불리한 손해 근심이 있고 가출사가 있다. 본 사안은 본래 무정에 있다.`,
     `@@1@@癸丑 @@2@@미 @@3@@공허@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외, 내 가정 모두모망사 불성과 골육상배의 동요불안 이별사가 있다`,
     `@@1@@癸丑 @@2@@신 @@3@@망신@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외, 내 집안 모두 타인을 돕는 일이 있다`,
     `@@1@@癸丑 @@2@@유 @@3@@자존@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외, 내 집안 모두 상황을 개신시킬 결속사가 있고 있다.`,
     `@@1@@癸丑 @@2@@술 @@3@@지위@@4@@  직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외사에 하극상배 재물부족의 반목대립사가 있고 원수가 되고 내사 집안에 관송사가 있다`,
     `@@1@@癸丑 @@2@@해 @@3@@이사, 이동@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@돈을 쓰면 일이 된다. 여자를 활용하라 외, 내, 집안 모두 타인을 압박하는 일이 있다. 원행이동사가 있다`,
     `@@1@@甲寅 @@2@@자 @@3@@재해@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모 문서의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 색정사가 있는 자다.  `,
     `@@1@@甲寅 @@2@@축 @@3@@숙명@@4@@ 금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@甲寅 @@2@@인 @@3@@변동@@4@@  직녹, 명예, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다. 외사에 외교가 막히고 타인에게 의뢰한 일도 지체 되어 있고 내사 집안도 침체되어 막혔다`,
     `@@1@@甲寅 @@2@@묘 @@3@@색정, 간음@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 일이 된다. 여자를 활용하라. 색정사가 있는 자다 외, 내, 집안에 금전의 소모가 많고 남자는 여자에  갈등하고 여자는 남자에 번민 한다`,
     `@@1@@甲寅 @@2@@진 @@3@@공허@@4@@ 금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다.  외, 내, 집안에 모두 타인을 압박하는 일이 있다`,
     `@@1@@甲寅 @@2@@사 @@3@@망신@@4@@  자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다, 외, 내, 집안에 모두 도주관사 진퇴곤란사로 반목 대립하고 서로 원수가 된다. 본 사안은 본래 정중에 있다 `,
     `@@1@@甲寅 @@2@@오 @@3@@자존@@4@@ 자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다, 외, 내, 집안에 모두 실속 없는 외화내허 한 문화광휘사가 있다`,
     `@@1@@甲寅 @@2@@미 @@3@@지위@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외, 내, 집안에 모두 타인을 압박하는 일이 있다`,
     `@@1@@甲寅 @@2@@신 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외, 내, 집안에 모두 부부이심으로 동요 불안 이별사가 있다. 원행이동사가 있다`,
     `@@1@@甲寅 @@2@@유 @@3@@건강@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다,  외, 내, 집안에 모두 타인으로부터 압박 받는 일이 있다`,
     `@@1@@甲寅 @@2@@술 @@3@@신앙@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외, 내, 집안에 모두 타인을 압박하는 일이 있다`,
     `@@1@@甲寅 @@2@@해 @@3@@강제, 급속@@4@@ 부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라. 기이하게 풀린다 외, 내, 집안에 모두 골육존장의 화합사가 있으나 오래 가지 않고 파재사를 겪는다상황이 급박하고 강제적인 손실사가 우려 된다 `,
     `@@1@@乙卯 @@2@@자 @@3@@색정, 간음@@4@@부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 부모 문서의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 색정사가 있는 자다.  `,
     `@@1@@乙卯 @@2@@축 @@3@@공허@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 금전 여자의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.  `,
     `@@1@@乙卯 @@2@@인 @@3@@망신@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 일이 된다. 여자를 활용하라 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@乙卯 @@2@@묘 @@3@@자존@@4@@  직녹, 형제사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직, 녹,위자를 활용하되 서둘지 말라 외사에 타인으로부터 압박을 받는 일이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@乙卯 @@2@@진 @@3@@지위@@4@@  자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다.  외사에 외교가 막히고 타인에게 의뢰한 일도 지체 되어 있으며 내사 집안에 공허무력, 만사상쟁으로 손해가 있고 가출사가 일어난다. 본 사안은 본래 인의에 있다`,
     `@@1@@乙卯 @@2@@사 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다,  외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 타인을 돕는 일이 있다. 원행이동사가 있다.`,
     `@@1@@乙卯 @@2@@오 @@3@@건강@@4@@  부모, 문서, 후원사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다, 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 가정파탄사가 있다`,
     `@@1@@乙卯 @@2@@미 @@3@@신앙@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 금전의 소모가 많고 내사에 번잡하고 굴신적 인 결속사가 있다`,
     `@@1@@乙卯 @@2@@신 @@3@@강제, 급속@@4@@직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다,  외사에 타인을 돕는 일이 있고 내사 집안에 타인으로부터 압박받는 일이 있다. 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@乙卯 @@2@@유 @@3@@재해@@4@@  직업, 남편, 관재, 병재사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 단 관직사는 좋다,  외사에 친구 동료 형제의 화합사가 있고 내사 집안에 남녀상쟁으로 분리되고 가출하며 문호가 개변되는 동요 불안사가 있다`,
     `@@1@@乙卯 @@2@@술 @@3@@숙명@@4@@  금전, 상업, 여인사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 희비불명 하극상배사로 동요 불안 이별사가 있고 서로 원수가 되고 내사 집안에 형제골육의 화합사가 있다`,
     `@@1@@乙卯 @@2@@해 @@3@@변동@@4@@  부모, 문서, 후원사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라. 기이하게 풀린다  외사에 타인을 압박하는 일이 있고 내사 집안에 번잡 굴신적 단합사가 있다`,
     `@@1@@丙辰 @@2@@자 @@3@@자존@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직업, 여자는 남편의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 여자는 일부종사를 할 수  없다 `,
     `@@1@@丙辰 @@2@@축 @@3@@지위@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 사안 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자손의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. `,
     `@@1@@丙辰 @@2@@인 @@3@@이사, 이동@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라, 기이하게 풀린다 외사에 도주관사 진퇴곤란사로 반목대립하고 서로 원수가 된다. 내사 집안에 타인으로부터 압박 받는 일이 있다. 원행이동사가 있다`,
     `@@1@@丙辰 @@2@@묘 @@3@@건강@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움받을 일이 있고 내사 집안에 공허무력 만사상쟁으로 손해사가 있고 가출사가 있게 된다. 본 사안은 본래 권위에 있다`,
     `@@1@@丙辰 @@2@@진 @@3@@신앙@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 타인으로부터 도움을 받는 일이 있고 내사 집안이 막혀 있다`,
     `@@1@@丙辰 @@2@@사 @@3@@강제, 급속@@4@@직녹, 명예, 형제사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직, 녹,위자를 활용하되 서둘지 말라 외사에 외교가 막히고 타인에게 의뢰한 일도 지체 되고 있으며 내사 집안에 타인으로부터 도움을 얻고자한다. 상황이 급박하고 강제적인 손실사가 우려된다.`,
     `@@1@@丙辰 @@2@@오 @@3@@재해@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 된다. 여자를 활용하라색정사가 있는 자다 외사에 금전의 소모가 많고 내사 집안에 타인으로 부터 도움 받고자하는 일이 있다`,
     `@@1@@丙辰 @@2@@미 @@3@@숙명@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 외사에 타인을 돕는 일이 있고 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@丙辰 @@2@@신 @@3@@변동@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 은혜배반, 상하불손사로 대립 반목하고 서로 원수가 되며 내사 집안에 타인으로부터 도움을 받을 일이 있다`,
     `@@1@@丙辰 @@2@@유 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 상황을 개신시키는 결속사가 있고 내사 집안에 형제 동료 친구의 화합사가 있다. 색정사가 있는 자다.`,
     `@@1@@丙辰 @@2@@술 @@3@@공허@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 타인을 돕는 일이 있고 내사 집안에 희비불명 하극상배사로 동요불안과 이별사가 있다`,
     `@@1@@丙辰 @@2@@해 @@3@@변동@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다  외사에 중구경득, 조상불경사로 동요불안하고 이별사가 있도 네사 집안에 타인을 압박하는 일이 있다 정사일, 외사에 타인으로부터 압박받는병진일,  일이 있고 내사 집안에 중구경득, 조상불경사로 동요 불안, 분산사가 있다. 원행이동사가 있다 `,
     `@@1@@丁巳 @@2@@자 @@3@@건강@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직업, 여자는 남편의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 여자는 일부종사를 할 수  없다 `,
     `@@1@@丁巳 @@2@@축 @@3@@신앙@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자손의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. `,
     `@@1@@丁巳 @@2@@인 @@3@@강제, 급속@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라, 기이하게 풀린다 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 도주관사 진퇴곤란으로 반목 대립한다. 상황이 급박하고 강제적인 손실사가 우려 된다. 본 사안은 본래 음욕에 있다`,
     `@@1@@丁巳 @@2@@묘 @@3@@재해@@4@@부모, 문서, 후원사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 번잡한 굴신적 단합사가 있고 내사 집안에 타인으로부터 도움을 받고자한다`,
     `@@1@@丁巳 @@2@@진 @@3@@숙명@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 금전의 소모가 많고 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@丁巳 @@2@@사 @@3@@변동@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@돈을 써야 일이 된다. 여자를 활용하라 외사에 타인으로부터 도움을 얻고자하고 내사 집안이 막혀 있다`,
     `@@1@@丁巳 @@2@@오 @@3@@색정, 간음@@4@@직녹, 형제사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@나를 지키고 있으면 일이 된다. 직, 녹,위자를 활용하되 서둘지 말라. 색정사가 있는 자다 외사에 상하관계의 화합사가 있고 내사 집안에 형제골육에 의한 금전손모가 있다`,
     `@@1@@丁巳 @@2@@미 @@3@@공허@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  외사에 외교가 막혀 있고 타인에게 의뢰한 일도 지 체 되고 있으며 내사 집안에 타인을 돕는 일이 있다`,
     `@@1@@丁巳 @@2@@신 @@3@@망신@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다.  외사에 타인을 돕는 일이 있고 내사 집안에 은혜배반, 상하 불손사로 반목 대립과 형송사가 있다`,
     `@@1@@丁巳 @@2@@유 @@3@@자존@@4@@금전, 상업, 여인사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 돕는 일이 있고 내사 집안에 상황을 개신시키는 결속사가 있다`,
     `@@1@@丁巳 @@2@@술 @@3@@지위@@4@@자손, 박관, 극부사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다.  `,
     `@@1@@丁巳 @@2@@해 @@3@@이사, 이동@@4@@직업, 남편, 관재, 병재사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 기이하게 풀린다  외사에 중구경득, 조상불경사로 동요불안하고 이별사가 있도 네사 집안에 타인을 압박하는 일이 있다 `,
     `@@1@@戊午 @@2@@자 @@3@@재해@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 금전과 여자의 재해가 있고 여자는 남자를 추하게 만든다. 남녀 모두의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. `,
     `@@1@@戊午 @@2@@축 @@3@@숙명@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 사안은 본래 무정에 있다 `,
     `@@1@@戊午 @@2@@인 @@3@@변동@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해가 있고 일이 안 된다. 단 기이하게 풀린다 외사에 도주관사, 진토곤란사로 반목대립하고 서로 원수가 되는 일이 있고 내사 집안에 실속 없는 외화 내허 한 문화광휘적 단합사가 있다`,
     `@@1@@戊午 @@2@@묘 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해가 있고 일이 안 된다 외사에 타인으로부터 도움 받을 일이 있고 내사 집안에 가정파탄의 근심사가 있다. 색정사가 있는 자다.`,
     `@@1@@戊午 @@2@@진 @@3@@공허@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외, 내, 집안 모두 타인을 돕고자하는 일이 있다`,
     `@@1@@戊午 @@2@@사 @@3@@망신@@4@@직녹, 명예, 부모, 후원, 문서사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 직, 녹위자를 활용하되 서둘지 말라  외사에 외교가 막히고 타인에게 의뢰한 일이 지체되고 있으며 내사 집안에 금전의 소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다 `,
     `@@1@@戊午 @@2@@오 @@3@@자존@@4@@직녹, 부모, 문서, 후원사  @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 부친을 활용하라 외사에 금전의 소모가 많고 내사 집안이 막혀 있다`,
     `@@1@@戊午 @@2@@미 @@3@@지위@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외사에 타인을 돕고자하는 일이 있고 내사 집안에 공공적 상하 화합사가 있다`,
     `@@1@@戊午 @@2@@신 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 외사에 은혜배반, 진퇴곤란사로 반목대립하고 서로 원수가 되는 일이 있고 내사 집안에 타인을 압박하는 일이 있다. 원행이동사가 있다`,
     `@@1@@戊午 @@2@@유 @@3@@건강@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외사에 상황을 개신시키는 결속사가 있고 내사 집안에 타인을 압박하는 일이 있다`,
     `@@1@@戊午 @@2@@술 @@3@@신앙@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외사에 타인을 돕고자 하는 일이 있고 내사 집안에 실속 없는 외화내허적 문화관휘사가 있다`,
     `@@1@@戊午 @@2@@해 @@3@@강제, 급속@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외사에 중구경득, 조상불경사로 동요불안하고 서로 원수 맺는 일이 있다. 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@己未 @@2@@자 @@3@@색정, 간음@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 금전과 여자의 재해가 있고 여자는 남자를 추하게 만든다. 남녀 모두의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. `,
     `@@1@@己未 @@2@@축 @@3@@공허@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제의 재해가 있고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 본 사안은 본래 무정에 있다 `,
     `@@1@@己未 @@2@@인 @@3@@망신@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해가 있고 일이 안 된다. 단 기이하게 풀린다 외, 내, 집안 모두 타인으로부터 압박을 받는 일이  있다`,
     `@@1@@己未 @@2@@묘 @@3@@자존@@4@@직업, 남편, 관재, 병재사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@피해가 있고 일이 안 된다 외, 내, 집안 모두 번잡하고 굴신적인 단합사가 있다`,
     `@@1@@己未 @@2@@진 @@3@@지위@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  오, 내, 집안 모두 금전소모가 많고 남자는 여자에 갈등하고 여자는 남자에 번민 한다`,
     `@@1@@己未 @@2@@사 @@3@@이사, 이동@@4@@부모, 문서, 후원사  @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 부친을 활용하라 일이 된다. 후원 문서 부친을 활용하라. 외, 내, 집안 모두 타인의 도움을 얻고자하는 일이 있다. 원행이동사가 있다`,
     `@@1@@己未 @@2@@오 @@3@@건강@@4@@직녹, 부모, 문서, 후원사  @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 외, 내, 집안 모두 공공적 상하 화합사가 있다`,
     `@@1@@己未 @@2@@미 @@3@@신앙@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외, 내, 집안 모두 막혀 있다`,
     `@@1@@己未 @@2@@신 @@3@@강제, 급속@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 외, 내, 집안 모두 타인을 돕고자하는 일이 있다. 상황이 급하고 강제적인 손실사가 우려 된다`,
     `@@1@@己未 @@2@@유 @@3@@재해@@4@@자손, 박관, 극부사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다  외, 내, 집안 모두 타인을 돕고자하는 일이 있다`,
     `@@1@@己未 @@2@@술 @@3@@숙명@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여자를 활용하라.  외, 내, 집안 모두 형송사가 일어나고 재물이 암체 되어 반목 대립하는 일이 있다`,
     `@@1@@己未 @@2@@해 @@3@@변동@@4@@금전, 상업, 여인사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다 외, 내, 집안 모두 타인을 압박하는 일이 있다.`,
     `@@1@@庚申 @@2@@자 @@3@@자존@@4@@ 자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자손의 재해가 있고 여자는 남자를 추하게 만든다. 남녀 모두의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. `,
     `@@1@@庚申 @@2@@축 @@3@@지위@@4@@부모, 문서, 후원사  @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 부모, 문서의 재해가 있고 여자는 남자를 추하게 만든다. 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. `,
     `@@1@@庚申 @@2@@인 @@3@@이사, 이동@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다  외사에 부부이심사로 동요불안하고 서로 원수 맺는 일이 있다. 원행이동사가 있다`,
     `@@1@@庚申 @@2@@묘 @@3@@건강@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 외, 내, 집안에 타인을 압박하는 일이 있다`,
     `@@1@@庚申 @@2@@진 @@3@@신앙@@4@@부모, 문서, 후원사  @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라.  외, 내, 집안에 타인으로부터 도움을 받는 일이 있다`,
     `@@1@@庚申 @@2@@사 @@3@@강제,@@4@@급속 직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  외사에 은혜배반, 상하불손의 반목대립사가 있고 서로 원수 맺는다. 상황이 급박하고 강제적인 손실사가  우려 된다`,
     `@@1@@庚申 @@2@@오 @@3@@재해@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외, 내, 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@庚申 @@2@@미 @@3@@숙명@@4@@ 부모, 문서, 후원사  @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외, 내, 집안에 타인으로부터 도움 받는 일이 있다`,
     `@@1@@庚申 @@2@@신 @@3@@변동@@4@@직녹, 명예, 형제사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 외사에 외교가 막혀 있고 타인에게 의뢰한 일도 지체되어 있고  내사 집안도 막혀 있다`,
     `@@1@@庚申 @@2@@유 @@3@@색정, 간음@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@금전를 쓰면 일이 된다. 여인을 활용하라. 색정사가 있는 자다 외사에 금선소모가 많고 내사 집안에도 금전소모가 많으며 남자는 여자에 갈등하고 여자는 남자에 번민 한다. 본 사안은 본래 인의에 있다`,
     `@@1@@庚申 @@2@@술 @@3@@공허@@4@@부모, 문서, 후원사  @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외, 내, 집안에 모두 타인으로부터 도움 받을 일이 있다`,
     `@@1@@庚申 @@2@@해 @@3@@망신@@4@@ 자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 기이하게 풀린다. 외, 내, 집안 모두 모사불성, 유시무종한 일로 손해 근심이 있고 가출사가 일어난다`,
     `@@1@@辛酉 @@2@@자 @@3@@건강@@4@@  자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 자손의 재해가 있고 여자는 남자를 추하게 만든다. 남녀 모두의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. `,
     `@@1@@辛酉 @@2@@축 @@3@@신앙@@4@@ 부모, 문서, 후원사  @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 부모, 문서의 재해가 있고 여자는 남자를 추하게 만든다. 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. `,
     `@@1@@辛酉 @@2@@인 @@3@@강제, 급속@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 기이하게 풀린다  외사에 타인으로부터 압박받는 일이 있고 내사 집안에 타인을 압박하는 일이 있다. 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@辛酉 @@2@@묘 @@3@@재해@@4@@금전, 상업, 여인사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다. 외사에 형제골육의 화합사가 있고 내사 집안에 분리되고 가출하고 문호가 개변되는 동요불안사가 있다. 대개 부부 남녀 이별이다`,
     `@@1@@辛酉 @@2@@진 @@3@@숙명@@4@@ 부모, 문서, 후원사  @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라.  외사에 희비불명하고 하극상배의 동요불안, 이별사가 있다`,
     `@@1@@辛酉 @@2@@사 @@3@@변동@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다  내사에 상황을 개신시키는 결속사가 있고 대개 별리사이다`,
     `@@1@@辛酉 @@2@@오 @@3@@색정, 간음@@4@@직업, 남편, 관재, 병재사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 실속 없는 문화광휘사가 있고 내사 집안에  타인으로부터 압박받는 일이 있다. 색정사가 있는 자다.`,
     `@@1@@辛酉 @@2@@미 @@3@@공허@@4@@부모, 문서, 후원사  @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 형송사가 일어나고 재물이 암체 된 일로 반목 대립하며 서로 원수가 된다`,
     `@@1@@辛酉 @@2@@신 @@3@@망신@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 일이 된다. 여인을 활용하라 외사에 타인을 돕는 일 이 있고 내사 집안에 형제 골육에 의해 금전손모가 있다`,
     `@@1@@辛酉 @@2@@유 @@3@@자존@@4@@  직녹, 형제사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 직, 녹위자를 활용하되 서둘지 말라 외사에 재병손상, 수하농락사로 손해가 있고 근심하는 일이 있고 내사 집안이 막혀 있다  `,
     `@@1@@辛酉 @@2@@술 @@3@@지위@@4@@부모, 문서, 후원사  @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@순탄하게 일이 된다. 후원 문서 모친을 활용하라 외사에 외교가 막혀 있고 타인에게 의뢰한 일도 지체되고 있으며 내사 집안에 재병손상 수하농락으로 손해가 있고 가출사도 있게 된다`,
     `@@1@@辛酉 @@2@@해 @@3@@이사, 이동@@4@@자손, 박관, 극부사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 기이하게 풀린다. 외사에 재병손상, 수하농락사로 손해가 있고 근심하며 내사 집안에 타인을 돕는 일이 있다. 원행이동사가 있다`,
     `@@1@@壬戌 @@2@@자 @@3@@재해@@4@@ 형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제의 재해가 있고 남녀 모두의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 계해일이면 색정사가 있는 자다 도량은 크나 되는 일이 없고 다성 다패 한다.  `,
     `@@1@@壬戌 @@2@@축 @@3@@숙명@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직업의 재해가 있고 여자는 남편의 재해가 있으며 일부종사가 안 되고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다. 계해일이면 혼인 불문 독거환경의 태생적 고독인이다  `,
     `@@1@@壬戌 @@2@@인 @@3@@변동@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 기이하게 풀린다. 외사에 골육존장의 화합사가 있고 내사 집안에 실속 없는 문화광휘적 단합사가 있다`,
     `@@1@@壬戌 @@2@@묘 @@3@@색정, 간음@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 大, 기타 월 小함 @@6@@손해가 나고 일이 지지부진하며 안 된다. 색정사가 있는 자다. 외사에 번잡하고 굴신적인 단합사가 있고 내사 집안에 형제골육의 화합사가 있다`,
     `@@1@@壬戌 @@2@@진 @@3@@공허@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 희비불명, 하극상배의 동요불안사가 있다`,
     `@@1@@壬戌 @@2@@사 @@3@@망신@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 중구경득, 조상불경사로 동요불안사가 있고 이별 한다`,
     `@@1@@壬戌 @@2@@오 @@3@@자존@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 타인을 압박하는 일이 있고 내사 집안에 실속없는 문화광휘적 단합사가 있다`,
     `@@1@@壬戌 @@2@@미 @@3@@지위@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 형송사가 있고 재물이 암체 되어 반목 대립한다. 본 사안은 본래 음욕에 있다`,
     `@@1@@壬戌 @@2@@신 @@3@@이사, 이동@@4@@부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라.  외사에 모사가 불성하고 유시무종한 일로 손해가 있어 근심하고 내사 집안에 타인을 돕는 일이 있다. 원행이동사가 있다`,
     `@@1@@壬戌 @@2@@유 @@3@@건강@@4@@ 부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 타인으로부터 도움받을 일이 있고 내사 집안에 재병손상, 수하농락사로 손해가 있고 근심하며 가출사가 일어난다`,
     `@@1@@壬戌 @@2@@술 @@3@@신앙@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다, 외사에 타인으로부터 압박받는 일이 있고 내사 집안이 막혀 있다`,
     `@@1@@壬戌 @@2@@해 @@3@@강제,@@4@@ 급속 직녹, 명예, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 동료, 형제 직, 녹위자를 활용하되 서둘지 말라. 기이하게 풀린다 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@癸亥 @@2@@자 @@3@@색정, 간음@@4@@직녹, 형제사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 형제의 재해가 있고 남녀 모두의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.계해일이면 색정사가 있는 자다 도량은 크나 되는 일이 없고 다성 다패 한다.  `,
     `@@1@@癸亥 @@2@@축 @@3@@공허@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@모든 일이 헛소리에 불과하고 불성하며 남자는 직업의 재해가 있고 여자는 남편의 재해가 있으며 일부종사가 안 되고 남녀 모두 의, 복, 수련, 종교에 귀의해야만 자립할 수 있는 산기자이다.계해일이면 혼인 불문 독거환경의 태생적 고독인이다  `,
     `@@1@@癸亥 @@2@@인 @@3@@망신@@4@@ 자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 物,心,事 大, 기타 월 小함 @@6@@소모와 손해가 나고 일이 지지부진하며 안 된다. 기이하게 풀린다. 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 골육존장의 화합사가 있다`,
     `@@1@@癸亥 @@2@@묘 @@3@@자존@@4@@자손, 박관, 극부사 @@5@@陰1,2월 10,11월 物,心,事 物,心,事 大, 기타 월 小함 @@6@@손해가 나고 일이 지지부진하며 안 된다. 외사에 타인으로부터 압박받는 일이 있고 내사 집안에 번잡하고 굴신적인 단합사가 있다`,
     `@@1@@癸亥 @@2@@진 @@3@@지위@@4@@ 직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다. 외사에 담, 문, 묘각의 퇴폐사로 파재 극처사가 있고 내사 집안에 타인으로부터 압박 받는 일이 있다`,
     `@@1@@癸亥 @@2@@사 @@3@@이사, 이동@@4@@금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 상황을 개신시키는 결속사가 있고 내사 집안에 중구경득, 조상불경사로 동요 불안한 일이 있다. 원행이동사가 있다`,
     `@@1@@癸亥 @@2@@오 @@3@@건강@@4@@ 금전, 상업, 여인사 @@5@@陰4,5월 1,2월 物,心,事 大, 기타 월 小함 @@6@@노력으로 일이 된다 외사에 부부 불합으로 관송사가 있고 선해가 있으며 가출사가 일어난다. 본 사안은 본래 무정에 있다`,
     `@@1@@癸亥 @@2@@미 @@3@@신앙@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다 외사에 모망사 불성하고 골육상배로 동요불안사가 있고 이별하며 내사 집안에 타인으로부터 압박받는 일이 있다`,
     `@@1@@癸亥 @@2@@신 @@3@@강제, 급속@@4@@부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라.  외사에 타인을 돕는 일이 있고 내사 집안에 모사 불성, 유시무종사로 손해가 있고 가출사가 일어난다. 상황이 급박하고 강제적인 손실사가 우려 된다`,
     `@@1@@癸亥 @@2@@유 @@3@@재해@@4@@  부모, 문서, 후원사 @@5@@陰7,8월 3,6,9,12월 物,心,事 大, 기타 월 小함 @@6@@일이 된다. 후원 문서 모친을 활용하라 외사에 상황을 개신시키는 결속사가 있고 내사 집안에 타인으로부터 도움 받을 일이 있다`,
     `@@1@@癸亥 @@2@@술 @@3@@숙명@@4@@직업, 남편, 관재, 병재사 @@5@@陰3,6,9,12월 4,5월 物,心,事 大, 기타 월 小함 @@6@@피해를 보고 일도 안 된다. 관직사는 좋다, 외사에 하극상배, 재물부족사로 반목대립하고 원수는 일이 있다`,
     `@@1@@癸亥 @@2@@해 @@3@@변동@@4@@형제, 붕우, 동료, 금전손해, 여인갈등사 @@5@@陰10,11월 7,8월 物,心,事 大, 기타 월 小함 @@6@@금전을 쓰면 된다. 여인을 활용하라. 기이하게 풀린다 외사에 타인을 압박하는 일이 있고 내사 집안이 막혀 있다`,
     ]


export default {
    
    VERSION101_1_1300 ,
    VERSION101_1_1400,
    VERSION101_1_1500,
    VERSION101_2_1500,
    VERSION20220207_1_1,
}
