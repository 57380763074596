
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,10,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,9,9,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,8,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`위선`,11,7,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,12,6,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,5,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`위선`,2,4,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,3,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`설득`,4,2,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,5,1,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,6,12,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,11,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`9`,``,``],
    [`자녀`,`자녀 문제사`,`大`,`소외기`,`외사단합`,`가정동요`],
    [`만당금옥(滿堂金玉)`,`가정에 보배가 가득 찬 상`,`大`,``,`名播四海`,`부부쟁투`],
    [`불변근신(不辨謹愼)`,`장차를 위해 말을 삼가하고 근신함이 좋은 상`,`小`,``,``,``],
    [`후원`,`윗인 손해사`,`小`,``,``,``],
    [`문서`,`전토(음3,6,9,12월)분묘관련사`,`大`,`8`,`불변근신`,`위례득죄`],
    [`모친`,`모친관련사`,`小`,`침체기`,`압박수난`,`손해가출`],
    [`불변근신(不辨謹愼)`,`장차를 위해 말을 삼가고 근신하는 것이 좋은 상`,`小`,``,``,``],
    [`골육소천(骨肉召天)`,`여름 가을에 골육의 상사가 있게될 상 `,`大`,``,``,``],
    [`금전`,`금전사`,`大`,``,``,``],
    [`사업`,`생업 동요불안분산사`,`小`,`7`,``,``],
    [`부인`,`처(妻)단합사-외화내허 격`,`小`,`단정기`,`반목대립`,`다사단합`],
    [`선파후합(先破後合)`,`먼저는 깨지고 나중에 합하는 상`,`大`,``,`동요불안`,`외화내허`],
    [`구설사송(口舌詞訟)`,`관재 소송 관련의 상`,`小`,``,`이심사`,``],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`결속`,`권리사를 음9월 완성함- 단외화내허적-형제災厄발생우려 `,`小`,`6`,``,``],
    [`색정`,`내녀 색정사 有`,`小`,`존재기`,`도적소실`,`파재극처`],
    [`이이도망(利而逃亡)`,`관재가 극성하여 도망치는 것이 유리한 상-음9월`,`大`,``,`약속누설`,`색정음사`],
    [`불검초죄(不檢招罪)`,`비밀을 약속하고도 누설하여 죄를 자초한 상`,`小`,``,``,`우유한가`],
    [`후원`,`후원사`,`小`,``,``,``],
    [`문서`,`문서사`,`大`,`5`,``,`투서헌책`],
    [`부친`,`부친관련사`,`小`,`배양기`,`사행불량`,`고위면접`],
    [`병송심외(病訟深畏)`,`병과 소송사가 깊어 두려운 상`,`小`,``,`약속누설`,``],
    [`불검초죄(不檢招罪)`,`비밀을 약속하고도 누설하여 죄를 자초한 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`급속`,`외사 급속사`,`大`,`4`,``,``],
    [`애증`,`외사 애증화합 반목송쟁 파재-실종 상해사 우려`,`小`,`개시기`,`愛憎화합`,`가정과출`],
    [`화촉지경(火燭之驚)`,`작은 불로 놀라게 되는 상`,`小`,``,`우유한가`,`엄목지상`],
    [`우유한가(優遊閑暇)`,`용모를 꾸밀 필요가 없는 한가한 상-실업자에 통함`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`3`,``,``],
    [`남편`,`남편 관련사`,`小`,`학습기`,`압박수난`,`가정막힘`],
    [`도적소실(盜賊消失)`,`도적이 길을 잃은 상`,`小`,``,`투서헌책`,`투서헌책`],
    [`모해곡읍(謀害哭泣)`,`도적 모해(謀害) 사상의 상-도적 관련`,`小`,``,`고윚면접`,`急文불용`],
    [`직위`,`직업직위 불안 관련 후원조력사`,`小`,``,``,``],
    [`문서`,`허위문서 관련사`,`大`,`2`,``,``],
    [`결속`,`단합하여 財기반을 크게 세우나 명예직위남편 훼절우려됨`,`小`,`면모기`,`수혜받음`,`상하화합`],
    [`기력선의(其力鮮矣)`,`힘을 쓸 수 없는 것을 선연히 알수 있는 상`,`小`,``,`엄목지상`,`도적허사`],
    [`도적소실(盜賊消失)`,`도적이 길을 잃는 격`,`大`,``,``,``],
    [`출행`,`내사 출행이동사`,`小`,``,``,``],
    [`침체`,`외교막힘사`,`小`,`1`,``,``],
    [`명예`,`수상(受賞)사`,`大`,`절정기`,`외교막힘`,`상대압박`],
    [`동최흠녕(動最欠寧)`,`적극적 활약은 제일 나빠 편안함에 흠이 되는 상`,`小`,``,`투서불용`,`형사처벌`],
    [`주유형책(主有刑責)`,`형사처벌을 받게 되는 상`,`大`,``,``,``],
    [`자매`,`자매손비사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`小`,`12`,``,``],
    [`색정`,`외남 색정사 有`,`大`,`과시기`,`과출비용`,`타인압박`],
    [`기가전진(豈可前進)`,`어째서 앞으로 나아가려고만 하는가-의 상`,`小`,``,`색정음사`,`가함`],
    [`도적실세(盜賊失勢)`,`가출도망사`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`11`,`음사閉戶`,`고위연회`],
    [`매사 영속성없음`,`후원조력문서부친사는 선천적 기복재해를 겪음`,`小`,`쇠퇴기`,`가택안녕`,`초대열석`],
    [`흉다길소(凶多吉小)`,`가정불화로 흉은 많고 길은 적은 상`,`小`,``,``,`희사有`],
    [`도적죄수(盜賊罪囚)`,`도적이 붙잡혀 죄수가 되는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`10`,``,``],
    [`매사 영속성없음`,`직업직위자녀 잘속는 일은 선천적 기복재해를 겪음`,`大`,`침해기`,`손해도망`,`불변근신`],
    [`충택불녕(冲宅不寧)`,`집안에 짐승 머리 산석이 있어 가택불안의 상`,`大`,``,`허사무우유한가`,`장차대비`],
    [`우유한가(優遊閑暇)`,`어얼굴을 다듬을 필요가 없는 한가로운 상-실업자에 동함`,`小`,``,`됨`,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,``,"직위불안부부갈등자녀문제로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`모친조력문서사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`금전부인사업사로 가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전상업여인사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`후원문서부친조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,"급속강제손실관사화재사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,"관사직업남편수난곡읍사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`모친조력문서사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`명예자산직녹사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`금전상업여인사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위로 놀램**만약 타인과 다툰다면 피아 간 전의가 별로 없고 손상도 없으며있다 해도가벼움`,0,0,`옳지못한 일이 밝혀져 상대의 호가호위적 추궁으로 심중 매우 놀라고 있으나 근신하면 자연 심중 두려움이 해소될 人 `,0,0,`현재 사안에서 물러나는 것이 이로움`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`동료와의 동업 또는 협동사로 의혹을 받고 불안하여 선택을 목하고 있으나 제일 친한 자 또는 제일 가까히 거주하는 자와 의논하여 결정하면 중상모략이 해소될 人`,0,0,`일이 띄엄띄엄 역조로 향함**옛 일로 돌아가고자 함**불비부족함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 명분을 앞세워 먼저 빨리 종결해야만 유리함`,0,0,`정정당당하게 급속 선수적 종결이 유리한 人`,0,0,`신규사가 있음**고집이 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`여인금전의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`저주 소송조짐이 있는 人`,0,0,`다사단합**외화내허적임**종적없이 어디로 숨어버리고 싶은 심정**독신 고독 독방 별리 주말부부 사별적 처지`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`여인금전의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`저주 소송의 조짐이 있는 人`,0,0,`관직자 기쁨 有**일반인은 희비교차함**독신 고독 각방 별리 주말부부 별리 사별 처지`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오니 신중해야 함`,0,0,`매사 불안반복무상하여 번민하는 人`,0,0,`신규사가 있고 고집이 있음**색정음사가 반복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`동료와의 동업 또는 협동사로 의혹을 받아 불안하여 선택기로에 서있으나 제일 친한 자 또는 제일 가까히 거주하는 자와 의논하여 결정하면 중상모략이 해소될 人`,0,0,`입을 닫음**독신 고독 각방 주말부부 별리 사별적 처지`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후에 행동함이 유리함`,0,0,`풍상 고초 후 성공할 人`,0,0,`나쁜 일이 연이어 일어남**타인의 호의를 쉽게 받아들이지 말아야 하고 전후좌우를 자세히 살펴야 함**일이 결말없이 반복됨**입을 닫음**종적없이 어디로 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,0,0,`역상적 사안에 억울하더라도 절대 반발하지 말고 순응유화적 자세로 이후로 연기해야만 반드시 결과가 유리하게 되는 人`,0,0,`풍운불측의 입장**재앙이 엎들여 있는 격**번민이 큼`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후에 행동함이 유리함`,0,0,`풍상 고초 후 성공할 人`,0,0,`서리 깔린 다리를 건너는 상**종적없이 어디로 숨고싶은 심정**타인의 호의를 쉽게 받아들이지 말아야 함**전후좌우를 세밀히 살펴야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`출행불귀(出行不歸)**만약 타인과 다툰다면 재앙을 당하게 되므로 처신을 절제하고 출입을 삼가해야 함`,0,0,`재앙방비가 필요하므로 오늘로부터 최소 6일 간 비행기 차 배로 원행 이동을 하지 말아야 할 人`,0,0,`촘촘한 그믈에 걸려드는 격**독신 고독 각방 주말부부 별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻은 동하나 중지가 됨`,0,0,`수구대신하며 기회를 기다리는 人`,0,0,`신규사 있음**고집이 있음**스스로 자부함**송쟁사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`익성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사 `,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손모인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈 인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동후회사`,`미사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`이발동사`,`미사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`피해`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해`,`화해`,`화해`],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [`애증화해`,`애증화해`,`애증화해`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [` 화해`,`화해`,` 화해`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,4,`음3월`,3,3,6,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월-연기우려`,1,1,2,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월-연기 또는 파혼우려`,3,1,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음8월`,3,3,6,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월`,1,2,3,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,1,1,2,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음5월-연기우려`,1,1,2,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음4월-연기우려`,1,2,3,`음10월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월`,3,1,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음8월`,3,3,6,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음7월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이며 조급하나 업무는 성공적임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하나 업무적 역량을 발휘함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 종반 허망함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`동료의 원조를 받으나 초반이 허망하고 외화내허적 업무를 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받으나 초반에 허사가 많고 움직임이 분주함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일처리가 강하지 못해 동요불안 분산사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 많아 내부를 공허하개 만듬`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많아 손해를 크게 끼침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것 같더라도 상대를 잘 조정하나 복잡한 여러 고민사를일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`용모는 준수 미모이나 일처리가 강하지 못해 중반 종반에 허사 공허사를 남김`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많아 비용을 허비함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`용모는 준수 미모이나 일처리가 강하지 못해 대립 반목송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月別`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,`반대극성`,`이행`,`선난후이`,`進 \n向`,`  근`,``,``,``,``,``],
    [`7.8월`,`다집多集`,`본인제압`,``,`천천히`,`역조감안`,``,`留`,`무난`,`서남`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,`반대극성`,`이행`,`선이후난`,`進 \n向`,`  원`,`去`,`세입피로`,`정서`,``,`남남서`],
    [`7.8월`,``,`비선측근`,``,`빠르게`,`초반극복`,``,``,`설기쇠약`,``,``,``],
    [`10.11`,``,`제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,`반대극성`,`이행`,`선財후이`,`進 \n向`,`  원`,`去`,`주인후원`,`남남서`,``,`서남`],
    [`7.8월`,``,`내부담합`,``,``,`초반극복`,``,``,``,``,``,``],
    [`10.11`,``,`먼곳비선`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대극성`,`이행`,`선財후난`,`退 \n背`,`원근왕래`,`留`,`주인후원`,`정북`,`정서`,``],
    [`7.8월`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`음9월집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대`,`이행`,`선이후財`,`進 \n向`,`원근왕래`,`去`,`세입피로`,`북북동`,`동북`,``],
    [`7.8월`,``,`본인제압`,``,``,`초반인내`,``,``,`설기쇠약`,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`음9월집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대조짐`,`이행`,`선費후이`,`退 \n背`,`  원`,`留`,`주인박해`,`동북`,`정동`,``],
    [`7.8월`,``,`발현안됨`,``,``,`동요불안`,``,``,`부부남녀`,``,``,``],
    [`10.11`,`집集`,``,``,``,``,``,``,`반목상쟁`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대기미`,`이행`,`사안무력`,`進 \n向`,`원근왕래`,`留`,`주인무력`,`서남`,`정북`,``],
    [`7.8월`,``,`발현안됨`,``,`회피포기`,`귀인조력`,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,`전부적`,``,``,``,``,``,``],
    [`3.6\n9.12`,`음3월집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`집集`,`반대없음`,`이행`,`사안무력`,`退 \n背`,`  원`,`留`,`주인무력`,`정서`,`북북동`,``],
    [`7.8월`,``,``,``,`회피포기`,`기진맥진`,``,``,`이전이사`,``,``,``],
    [`10.11`,``,``,``,``,`함`,``,``,`되려불성`,``,``,``],
    [`3.6\n9.12`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`이행`,`선損후이`,`退 \n背`,`  원`,`去`,`주인피해`,`남남동`,`동북`,``],
    [`7.8월`,`다집多集`,``,``,``,`본인무력`,``,``,`세입가해`,`세밀심사`,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`3.6\n9.12`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`이행`,`권리양도`,`退 \n背`,`  원`,`去`,`주인피해`,`남남동`,`정동`],
    [`7.8월`,`다집多集`,``,``,`됨`,`본인무력`,``,`골육지책`,`세입가해`,``,``,``],
    [`10.11`,``,``,``,``,``,``,`남남동쪽`,``,``,``,``],
    [`3.6\n9.12`,`집集`,``,``,``,``,``,`必去요망`,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`이행`,`선이후이`,`進 \n向`,`  근`,`去`,`주인세입`,`정남`,``,`동남`],
    [`7.8월`,``,``,``,`느긋이`,`귀인조력 `,``,``,`상하화합`,``,``,``],
    [`10.11`,``,``,``,``,`전부적`,``,``,`세입피로`,``,``,``],
    [`3.6\n9.12`,`음9월집集`,``,``,``,``,``,``,`설기쇠약`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대극성`,`이행`,`선난후난`,`進 \n向`,`  근`,`留`,`무난`,`서남`,``,`남남동`],
    [`7.8월`,`다집多集`,`송쟁비화`,``,``,`송쟁감안`,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`집集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,`도로귀`,`교통사고`,``,``,`묘좌`,0,`없음`],
    [``,`전사귀`,`전사`,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`전사귀`,`전사`,``,``,`육친`,0,``],
    [`질병`,`도로귀`,`교통사고`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`도로귀`,`교통사고`,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,`변사귀`,`객사`,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`여귀`,`사망부인`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`두성불안`,`기도위배`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,`신제사귀`,`제례위배`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`가당부정`,`성물훼손`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`두성부정`,`기도위배`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`매사영속성없음`,`상위기쁨-하위수도자 격`,`소식기다림-와도 나쁨`,`빈궁**병재**전화위복**재액해소`,`관직자-영전도약사`,`빈궁**전화위복`,`외사 출행이동사`,`고위안좌-이익경영사`,``,`관재**이동사**내 여자 색정사有`,``,`화합사**득죄주의`,`송쟁사흉-문서소식사-길`,`관재**파재실종애증사`,`말 말고 근신해야 함`,`관재**가정막힘`,``,`고위연회길사`,`내사 출행이동사`,`명예자산권리사**여유한가**전도`,``,`빈궁**전도**외 남자 색정사有`,``,`빈궁**관재**정체경공사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내 급속사`,`매사불성**활인진출하면자립`,``,`남녀부부상쟁분산`,``,``,``,``,``,`시기의심도주사`,``,``,`외 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외교막힘`,``,`종업원 모사`,``,`매사불성**종교진출하면자립`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사영속성없음`,`빈궁**관재`,`상위기븜-하위 소도자 격`,`내 급속사`,`소식기다림-와도 나쁨`,`빈궁**병재**전화위복**재액해소`,`관직자-영전도약사`,`빈궁**전화위복`,`외사 출행이동사`,`고위안좌-이익경영사**관재`,``,`빈궁**이동사**내 여자 색정사有`,``,`화합사**득죄주의`,`송쟁사흉-문서소식사- 길`,`빈궁**관재**파재실종애증사`,`말 말고 근신해야 함`,`관재**가정막힘`,``,`고위연회길사**부족`,`여유한가**내사 출행이동사`,`명예자산직녹권리사**외교막힘**전도`,``,`빈궁**외 남자 색정사有`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`매사불성*종교진출하면자립`,``,`매사불성*활인진출하면자립`,``,`남녀부부상쟁분산`,``,``,``,``,``,`시기의심도주사`,``,``,`외 금속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`출행사`,``,`종업원 모사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`빈궁**전도**외 남자 색정사有`,``,`빈궁`,`매사영속성없음`,`상위기쁨-하위수도자 격`,`소식기다림-와도 나븜`,`빈궁**병재**전화위복**재액해소`,`관직자-영전도약사`,`빈궁**전화위복`,`외사 출행이동사`,`고위안좌-이익경영사`,``,`빈궁**이동사**내 여자 색정사有`,``,`화합사**득죄주의`,`송쟁사흉-문서소식사-길`,`빈궁**관재**파재실종애증사`,`말 말고 근신해야 함`,`빈궁**관재**가정막힘`,``,`고위연회길사**부족`,`여유한가**내사 출행이동사`,`명예자산직녹권리사**외교막힘**전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`종업원 모사`,``,`매사불성*종교진출하면자립`,`내 급속사`,`매사불성*활인진출하면자립`,``,`남녀부부상쟁분산사`,``,``,``,``,``,`시기의심도주사`,``,``,`외 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`명예자산권리사`,`내사 출행이동사`,`빈궁**외 남자 색정사有`,``,`약속비밀누설불검약초죄사`,``,`빈궁**전도`,`급문사투서헌책불용-자출수됨-금전실재사`,`빈궁**전도**전화위복`,`눈이가려진격으로사람을 상해할수없음`,`고위연회길사**전화위복`,``,`음10,11,1,2월 부자**재액해소`,`한가로움**외사 출행이동사`,`이동사**내 여자 색정사有**재액해소`,``,`빈궁`,`도적실로-도적소실사`,`관재**파재실종애증사`,`희경사有`,`빈궁**관재**병재`,`재난소멸사`,`빈궁**관재**가정막힘`,`사기득재사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`충산사**외교막힘`,``,``,``,`매사불성*종교진출하면자립`,``,`매사불성*활인진출하면자립`,`내 급속사`,`남녀부부상쟁분산사`,``,``,``,`여유한가`,``,`시기의심도주사`,``,``,``,`음4,5월 화재주의`,`외 급속사`,`음4,5월 화재주의`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`빈궁**관재`,`사기득재사`,`명예자산권리사**외교막힘`,``,`빈궁**관재**외 남자 색정사有`,``,`약속비밀누설불검약초죄사`,``,`빈궁**전도`,`급문사투서헌책불용-자충수됨-금전실재사`,`남녀부부상쟁분산사**전화위복`,`눈이가려진격으로사람을상해할수없음`,`고위연회길사**전화위복`,``,`음1,2,10,11월 부자**관재**재액해소`,`한가로움**외사 출행이동사`,`이동사**내 여자 색정사有**재액해소`,``,`관재`,`도적실로-도적소실사`,`빈궁**관재**병재**파재실종애증사`,`희경사有**부족`,`빈궁**병재**가정막힘`,`재난소멸사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`충산사**내사 출행이동사`,``,``,``,`매사불성*종교진출하면자립`,``,`매사불성*활인진출하면자립`,`내 급속사`,``,``,``,``,`여유한가`,``,`시기의심도주사`,``,``,``,`음4,5월 화재주의`,`외 급속사`,`음4,5월 화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`빈궁**병재**가정막힘`,`재난소멸사`,`빈궁**전도`,`사기득재사`,`명예자산권리사**관재**재액해소`,``,`빈궁**관재**외 남자 색정사有**재액해소`,``,`전도**동요불안분산사`,`약속비밀누설불검초죄사`,`빈궁**전도**동요불안분산사`,`급문사투서헌책불용-자충수됨-금전실재사`,`빈궁**남녀부부상쟁분산사**관재**전화위복`,`눈이가려진격으로사람을상해할수없음`,`고위연회길사**전도**전화위복`,``,`음12,10,11월 부자**여유한가`,`한가로움**외사 출행이동사`,`이동사**내 여자 색정사有**동요불안분산사`,``,`빈궁**전도`,`도적실로-도작소실사`,`희경사**부족**파재실종애증사`,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재주의`,`동요불안분산사`,`동요불안분산사`,``,`외교막힘**동요불안충산사`,`내사 출행이동사`,`동요불안분산사`,``,`매사불성*활인진출하면자립`,``,`매사불성*활인진출하면자립`,`내 급속사`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`시기의심도주사`,``,`동요불안분산사`,``,`음4,5월 화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`희경사**파재실종애증사`,`외 급속사`,`빈곤**병재**가정막힘`,`재난소멸사`,`빈궁`,`사기득재사`,`명예자산권리사**관재`,`내사 출행이동사`,`관재**외 남자 색정사有`,``,`약속비밀누설불검약초죄사**전도`,``,`빈궁**관재**전도`,`급문사투서헌책불용-자충수됨-금전실재사`,`빈궁**남녀부부상쟁분산사**전도**전화위복`,`눈이갸려진격으로사람을상해할수없음`,`고위연회길사**전화위복`,``,`음1,2,10,11월 부자**재액해소`,`한가로움**외시 출행이동사`,`이동사**관재**내 여자 색정사有**재액해소`,``,`관재`,`도적실로-도적소실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외교막힘**충산사`,``,``,``,`매사불성*종교진출하면자립`,``,`매사불성*활인진출하면자립`,`내 급속사`,``,``,``,``,`여유한가`,``,`시기의심도주사`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`빈궁**관재`,`도적실로-도적소실사`,`희경사**파재실종애증사`,`외 급속사`,`병재**가정막힘`,`재난소멸사`,`빈궁`,`사기득재사`,`명예자산권리사**관재`,`내사출행이동사`,`빈궁**외 남자 색정사有`,``,`약속비밀누설불검약초죄사`,``,`빈궁**전도`,`급문사투서헌책불용-자충수됨-금전실재사`,`빈궁**남녀부부상쟁분산사**전도**전화위복`,`눈이가려진격으로사람을상해할수없음`,`고위연회길사**전화위복`,``,`음1,2,10,11월 부자**재액해소`,`한가로움**외사 출행이동사`,`이동사**내 여자 색정사有**재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외교막힘**충산사`,``,``,``,`매사불성*종교진출하면자립`,``,`매사불성*활인진출하면자립`,`내 급속사`,``,``,``,``,`여유한가`,``,`시기의심도주사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`빈궁**이동사**내 여자 색정사有`,``,`빈궁`,`도적실로-도적소실사`,`희경사**관재**파재실종애증사**재액해소`,`외 급속사`,`빈궁**관재**병재**가정막힘**재액해소`,`재난소멸사`,`빈궁`,`사기득재사`,`명예자산권리사`,`내사 출행이동사`,`빈궁**외 남자 색정사有`,``,`비밀약속누설불검약초죄사`,``,`빈궁`,`급문사투서헌책불용-자충수됨-금전실재사`,`빈궁**남녀부부상쟁분산사**전화위복`,`눈이가려진격으로사람을상해할수없음`,`고위연회길사**부족**전화위복`,``,`음1,2,10.11월 부자**부족`,`한가로움**외사 출행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`시기의심도주사`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외교막힘**충산사`,``,``,``,`매사불성*종교진출하면자립`,``,`매사불성*활인진출하면자립`,`내 급속사`,``,``,``,``,`여유한가`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`고위안좌-이익경영사`,`음1,2,10,11월 부자`,``,`이동사**내 여자 색정사有**관재**전도`,``,`화합사**득죄주의`,`외 급속사`,`빈궁**관재****파재실종애증사**재액해소`,`말 말고 근신해야 함`,`빈궁**관재**가정막힘**재액해소`,``,`고위연회길사`,`여유한가`,`명예자산직녹권리사*부족`,``,`빈궁**외 남자 색정사有`,`도적실세-포획-죄수됨`,`빈궁`,`매사영속성없음`,`상위기븜-하위 수도자 격`,`소식기다림-와도 나쁨`,`빈궁**병재**남녀부부상쟁분산사**전화위복`,`관직자-영전도약사`,`빈궁**관재**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사 출행이동사`,``,``,`시기의심도주사`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`외교막힘**내사 출행이동사`,``,`종업원 모사`,``,`매사불성*종교진출하면자립`,`내 급속사`,`매사불성*활인진출하면자립`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`관직자-영전도약사`,`빈궁**관재**남녀부부상쟁분산사**전화위복`,`외사 출행이동사`,`음1,2,10,11월 부자**재액해소`,``,`빈궁**이동사**내 여자 색정사有**재액해소`,``,`화합사**득죄주의`,`외 급속사`,`빈궁**관재**파재실종애증사**재액해소`,`말 말고 근신해야 함`,`빈궁**관재**가정막힘`,``,`고위연회길사`,`여유한가`,`명예자산직녹권리사**외교막힘`,``,`빈궁**외 남자 색정사有`,`도적실세-포획-죄수됨`,`빈궁**내 급속사`,`상위기븜-하위 수도자 격`,`매사불성*음7,810,11월 활인진출로성공할 자`,`소식기다림-와도 나쁨`,`빈궁**병재**전도**전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`고위안좌-이익경영사`,``,`시기의심도주사`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`내사 출행이동사`,``,`종업원 모사`,``,`매사불성*종교진출하면자립`,`폐한인**내 급속사`,`전도`,``,`남녀부부상쟁분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`소식기다림-와도 나쁨`,`빈궁**병재**전화위복`,`관직자-영전도약사`,`빈궁**전화위복`,`고위안좌-이익경영사`,`음1,2,10,11월 부자**전도`,``,`빈궁**전도**이동사**내 여자 색정사有`,``,`화합사**득죄주의`,`외 급속사`,`빈궁**관재****파재실종애증사**재액해소`,`말 말고 근신해야 함`,`빈궁**관재**가정막힘**재액해소`,``,`고위연회길사`,`여유한가`,`명예자산작녹권리사**외교막힘`,``,`빈궁**외 남자 색정사有`,`도적실세-포획-죄수됨`,`매사불성*음3,4,5,6,9,12월종교진출로성공할자`,`상위기븜-하위 수도자 격`,`매사불성*음7,8,10,11월 활인진출로성공할자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`남녀부부상쟁분산사`,``,``,`외사 출행이동사`,``,``,`시기의심도주사`,``,``,``,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,``,`내사 출행이동사`,``,`종업원 모사`,``,`빈궁`,`매사영속성없음`,`내 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 설 수도 없고 앉을수도 없고, 도망가고 싶겠어 ! 관재야! 돈이 들어와도 사업에 쓰면 안돼!  
⊕전사 가택귀
⊖가택음사부정을 물리치고    자만심을 버려야해!
⊕신변상가부정침범이 들었어!
`,`■ 일단 물러서야 하겠어! 지금 놀래는 것은 걱정할 것 없고! 

⊕ 가택귀, 
⊖ 도로귀 장난이야!
`,`■ 가정에 가당신사부정이 들었어! 선택에 기로에섰고 옛일로 돌아가고 싶은데 여건이 부족하겠어 !
⊕가택귀, ⊖ 도로귀가 몸을 치고 있고! 그러니 공을 세우고도 되돌아 가야하는 격이 되었지!
⊖신변 음사부정침범이 들었어!
`,`■집을 옮기려 하는데 그전에 

⊕ 전사귀, 
⊖ 변사귀 먼저 해소시켜야 신규사도 될것이고 재산 재해 속는일이 해소되지!
`,`■ 도둑놈 같은 형제ㆍ친구가 나를 구해줄 것이고, 여자는 결국 원수가 될것인데, 지금 마음에 의심질투가 있고 관재 고심으로 기로에 섰어!
⊕ 전흉사귀,   
⊖여귀를 잡아줘야 풀려!
⊕⊖신변두성불안부정이         침범했네 !
`,`■ 용이 승천하다 곤두박질한 격일세? 
모두 허성이야! 
금전 여자의 의심과 질투만 가득하고!

⊕신불제사귀, 
⊖음귀를 잡아야 재기가 되지!
`,`■ 색정 배반사네! 배반해놓고 뭘 바랄수 있겠어? 
새로운 일도 효과가 없겠어! 
여:서로 마음이 달라 남편이 집에 발붙이질 않네?
⊕가택 음사부정이 들었다. 
⊖신변상가부정이들었어 !
`,`■ 고진감래라 !
참고 견디고 둘중 가깝고 친한 사람으로 하나를 선택해야 나중에 되는게 있어 !
후퇴하면 되려 망해 ! 
`,`■ 쓰러지기 일보직전이네? 집에 가당신사 부정도 들었고! 사람의 지혜가 한계가 왔으면 신불에 빌어보는 수밖에 없을거야!
⊕⊖ 가택에 가당신사
     부정이 침입했다.
⊖신변음사부정이 들었어!
`,`■ 숨기고(죄인) 있는 일이 있네 !자칫 하늘의 투망에 걸려 들겠어! 앞길을 예측할 수 없게 되어 있어! 
돈 관계 여자문제가 관재로 번지겠어 !
속는일도 기하고 자손재해도 있겠어 !
`,`■ 집안이 머리도 없고 꼬리도 없네? 
거기에 부부의 부정까지 들어버렸고! 집이 넘어가겠는데 병자는 어떻게 하나?
⊕가택상가부정이 들었다.
⊕⊖신변두성불안부정이          침범했어 !
`,`■ 앞에는 호랑이, 뒤에는 늑대가 버티고 있는 격일세? 하늘에서 그물로 묶어 버리기로 작정한 셈이야 ! 
기도하는 수밖에 없거 ! 
그게 살길이야 !`];
const YOGYEOL1 = [`■ 낮정단에 백호가 덕녹에 탔으니 중전에 있는 재물을 포기하라. 이를 헌금ㆍ헌납하면 좋겠고 사업자금에 쓰면 나쁘다.
`,`■ 아무리 친하게 교섭해도 상대는 의심하고 공격한다. 등사ㆍ주작을 만나니 적들이 많게 된다.
`,`■ 어른은 고조격으로 좋다하나 아랫사람의 행패가 있다. 한을 품은자가 고자질하니 초전관살을 돕기 때문이다.
`,`■ 낮정단은 천장이 생하고 밤정단은 극한다. 소송사는 나쁘고 관직건은 좋다. 말전은 병주고 약주니 귀인을 믿지 말라.
`,`■ 상대가 스스로 와서 돕는다해도 너무 믿거나 의지하지 말라. 또 밤정단은 대체로 어둡고 실체가 없다.
`,`■ 누가 나를 돕는다해도 온전한 것이 못된다. 어두움이 나를 싸고도니 집까지 점점 기울어 간다.
`,`■ 재물이 절지에 있고 범이 7마리나 진치고 있어. 이 재물을 취하지 말라. 가을점은 집에 화재가 있다.
`,`■ 인수ㆍ양인ㆍ재성이 모두 삼전에 있다. 밤정단은 현무ㆍ주작ㆍ백호가 있어 말전의 재가 힘을 못쓴다.
`,`■ 사람이고 집이고 모두 손해난다. 이는 실물을 묻는게 틀림없다. 사람은 질병이 많고 집도 점점 기운다.
`,`■ 재물에 살이 숨어있다. 서로 예절없이 반목한다. 반드시 나쁜 일로 움직이니 귀인의 도움을 바랄 수 없다.
`,`■ 중전과 말전이 공망되고 스스로 자초한 재앙이다. 일은 얽혀 안풀리고 병자도 쉽게 낫지 못한다.
`,`■ 무엇을 잃어서 왔다. 괜한 놀램이고 걱정이다. 이해관계 없이 도와주는 이가 있으니 앞으로 편안할 것이다.`]
const SIBO1 = [`■ 家內우환이 있고, 가인이
가장을 속이며 원한을
품고 加害하려 한다.
먼저 선수를 쳐 大利를 얻는다. 특히 未띠는 좋다. 正道가 아니면 근신해야 한다. 유리는 하나 늦는게 흠이다. 옛것을 굳건히 지키라.

凶을 凶으로 제어한다.
관재송사가 있다.
`,`■ 家內우환이 있고, 가인 중
가장을 속이며 원한을
품고 加害하려 한다.
凶을 덕행으로 다스리면 실력이 웅대하게 된다. 대외관계는 모두 양호하게 된다. 일시적으로 역조현상이 있더라도 이겨나가라.
모든 凶이 사라진다.
`,`■ 자충수적 우환이 기한다.
상대가 스스로 와서
피해를 준다.
사물을 복구하려고 한다. 상대를 믿어서는 안된다. 加害心이 있다. 매사를 시초에 결정하고 승부를 내야 한다. 지체하면 곤란해진다. 방침을 바꾸라.

⊕⊖ 가당신사 부정이 듬.
관재가 있다.
`,`■ 자충수적 우환이 기한다.
⊖관리ㆍ정치인은 탄핵
의 조짐이 있다.
他人의 추천ㆍ천거를 믿지 말고 본인의 의지를 관철시켜야 한다. 그러면 성공된다. 단, 女ㆍ金錢이 災가 된다.
이를 방지하는데 주력해야 한다.
財ㆍ妻에 의해 소송이 기한다. 살피라.
四月에 임신사가 있다.
`,`■ 관재가 있다. ⊖상가부정
夫婦가 같이 음탕하다.
財ㆍ妻를 경계하라.
만사 순조롭다. 적극적으로 나아갈 필요가 있다. 신명이 돕는다. 
부하나 종업원이 도주한다. 과욕적인 財를 탐하면 송사가 벌어진다.
부모의 凶이 기한다.
`,`■ 財ㆍ女子에 의해 소송사
가 기한다. 세심한 주의
취득을 요한다. 
일을 느긋하게 추진하라. 
무슨 일이든 초에는 헛소리이다. 나중에 他人을 믿으면 吉하다.
妻ㆍ財에는 加害心이 있다. 자세히 살펴야 한다. 
四月에 임신사가 있다.
부모의 凶이 기한다.
`,`■ 財ㆍ妻에 의해 소송사가
기한다. 금전과 女의
취득을 신중히 하라.
골육불화, 부부이심사가 있다.
두 개의 일이 겹쳐 동요ㆍ불안사가 되었다.
서로 원수가 된다. 원행ㆍ별리사가 있다. 결국은 吉이 된다.

가정에서 가장을 깔본다.
`,`■ 人心을 잃고 있다.
우연한 기회를 놓치지
말라.
二事중 선택의 기로에 있다. 불안하고 암울한 형편이다. 
多人의 기만이 있고, 財가 東入西出한다. 그러나 전진하여 吉하게 된다. 후퇴하면 가운이 쇠한다.
가정에 속는 일이 잇고 자손재해가 기한다.
`,`■ 시간이 가야 小成한다.
부모의 재해가 기한다
男子는 직위해탈의 위험 상태이고, 女子는 夫와의 갈등이 심해 별리한다. 허사ㆍ손실이 크다. 
질병도 있다. 모든 일을 공적으로 하는 것이 좋고 사적은 凶하다. 고사를 지내는 것이 좋다.
`,`■ 男 : 刑訟事가 기한다.
女 : 사통간사가 있다.
外出ㆍ여행을 절대로
금하라. 災厄이 있다.
가정음사가 있으나 불명한 상태이다. 財ㆍ女로 인해 송사가 발생한다. 금전의 취득과 여자의 관계를 조심해야 한다. 일체 차질이 발생하고, 특히 下人女ㆍ사용인ㆍ妾이 凶하다.
四月에 임신사가 있다.
`,`■ 가택의 권리가 박탈된다.
가정에 병자가 있다.
부모의 재해가 기한다
상대에게 가서 능욕ㆍ박해를 당한다. 하극상의 부정행위가 있다. 처음에는 吉한듯해도 결국 凶이 된다. 만사 위역되고 凶해소가 여려우나 천우신조가 있다. 기도ㆍ치성하라.
`,`■ 酒席투쟁이 발생한다.
他人에게 속는 일을
조심할것.
허한 일에 놀라거나 분실사를 겪는다. 정숙하면 吉하나 분주하면 凶하다. 가능한한 나서지 말고 外事를 막으라.
특히 연회장소에서 凶이 발생하므로 주석을 조심하라.
奸私 주의할 것.`]
const SIBO2_1 = [`■ 직녹, 신변외사이다.
내방조짐 상의 :  
원행·이동사가 있다.
움직임이 근본이고 동기가 발동한다.
`,`■ 官･夫, 가택내사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 官･夫, 가택내사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■官･夫, 신변외사이다.
내방조짐 상의 :  
조용히 있는게 이롭다.
이전․이사를 한다.처․재로 인한 禍주의요
`,`■ 후원, 가택내사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
색정송쟁·병재·관재 예방이
필요하다.
`,`■ 후원, 가택내사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다. 가운이 쇠퇴의 상이다.
`,`■ 금전ㆍ여, 가택내사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 후원사, 이사 필요
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.
매사에 지장이 있다.
`,`■ 후박관ㆍ극부ㆍ자손사
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.
男직위상실 女부부이별한다.
`,`■ 금전ㆍ여, 가정 분쟁사
내방조짐 상의 :  
은근하고 伏하라.
부부별리 충돌한다.
`,`■ 직녹, 신변외사이다.
내방조짐 상의 :  
원행·이동사가 있다.
움직임이 근본이고 동기가 발동한다.
`,`■ 후원, 가택내사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.후원사 난망하고 부모 재액발생한다`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/007/007_001.png`,
  `/static/images/sagwa/007/007_002.png`,
  `/static/images/sagwa/007/007_003.png`,
  `/static/images/sagwa/007/007_004.png`,
  `/static/images/sagwa/007/007_005.png`,
  `/static/images/sagwa/007/007_006.png`,
  `/static/images/sagwa/007/007_007.png`,
  `/static/images/sagwa/007/007_008.png`,
  `/static/images/sagwa/007/007_009.png`,
  `/static/images/sagwa/007/007_010.png`,
  `/static/images/sagwa/007/007_011.png`,
  `/static/images/sagwa/007/007_012.png`,
];

const HEASYEOL = [
  // 1
  `  이 과의 십이신(十二神)은 각각 본궁에 숨어서 동하지 않고 양일(陽日)은 일간상신 신(申)을 초전으로 하여 자임격(自任格)이라고 부른다. 양(陽)은 강(剛)으로 자신의 강(剛)에 맡겨 사과 중에 극신(剋神)이 없으면 삼전은 형(刑)을 중․말전으로 한다. 따라서 신인사(申寅巳)는 원태격(元胎格)으로 된다.
  만사가 유순하면 길로 되어 일이 성취되지만 맹동(猛動)하여 강포(剛暴)하면 화를 초래한다. 모두 가까운 것을 의미한다. 있는 것은 옮기고 만나는 것은 헤어지며 정(靜) 중에 사(思)는 동(動)하게 된다. 실물(失物)․가출(家出)․도망 등은 가까운 곳에 있고, 대인(待人)은 즉시 온다.
  삼전이 역마를 만나므로 사(巳)를 얻어 중지하지 않고 동하며, 동하는 것은 성취된다. 경(庚)은 신(申)과 오(午)에 야귀신의 미(未)는 공정(拱定)한다. 만약 년명상신(年命上神)에 미(未)가 승하면 대단히 길하여 어떤 일도 성취된다. 원태격(元胎格)이 왕상하면 길조를 얻고 휴수(休囚)는 흉하다. 만사는 자기를 지키는 대기(待機)의 자세가 좋고 망동(妄動)을 싫어한다. 백법에 말하기를 ｢왕록(旺祿)이 신(身)에 임하는 것은 함부로 동하면 불길하다. 신임(信任) 중에 어느 것에든지 정마(丁馬)가 임할 때는 동(動)을 다스린다｣고 하였다. 비전에 말하기를 ｢년명상신에 묘(卯)가 있으면 정신(丁神)이 승한다. 즉 금일정신(金日丁神)은 관인(官人)을 정단하면 부임이 극히 빠르다｣고 하였다.
  일귀는 순행하고 삼전은 백(白)․사(蛇)․구(勾)이다. 초전 신(申)은 일마(日馬)로 되며 백호가 동병할 때는 흉을 동하는 것이 빠르다. 간지의 중간에 미(未)의 귀신이 끼일 때는 시험․선거는 반드시 적중한다. 야귀는 역행하고 삼전이 후(后)․청(靑)․주(朱)로 된다. 중전 청룡이 재동(財動)으로 되고 둔귀(遁鬼)를 혼합하므로 간지는 미(未)의 귀신을 공(拱)하여 대리(大利)를 얻는다. 재(財)로 관귀(官鬼)를 구(求)하고 삼전의 사맹신(四孟神)은 현태격(玄胎格)으로 된다. 역마․청룡․주작․귀신이 있으면 대단히 유리한 과이다. 사맹(四孟)은 오행의 초기이므로 대개는 일이 늦어진다. 건록은 함께 길격(吉格)이며 백호가 삼전에 들어가면 질병을 주관한다.`
  ,
  // 2
  `  이 과는 사과 중에 상하의 극이 없고 제2과 오(午)의 화(火)는 일간 경(庚)을 요극(遙剋)하므로 호시격(蒿矢格)이다. 일간상신의 미(未)와 일지가 지합하고 기궁(寄宮) 신(申)과 일지상신 사(巳)가 지합하여 교차상합(交車相合)으로 일간상신이 된다.
  사과․삼전과 사오(巳午)의 사화(四火)가 내몸인 경금(庚金)을 극하여도 이 화(火)는 진미(辰未)에 생화한다. 즉 화(火)의 살(殺)은 토(土)의 인수(印綬)에 변화하여 내몸을 생한다. 흉이 덕행(德行)에 의해 길로 변화하는 것이다. 구직, 구관(求官)이 가장 길하다. 요극과는 길흉이 모두 경한데 호시(蒿矢)는 힘이 가장 열(劣)하여 손상을 받지 않는다. 일귀․염막(簾膜) 귀신(貴神)이 간상에 임하므로 선거와 입시는 반드시 적중한다.
  이 과는 처음에는 기세가 용장하여도 오래가면 미약하게 되어 길흉이 모두 크지 않다. 화복은 모두 외부에서 발생하고 승패는 주(主)에 이익이 있다. 백법에 말하기를 ｢염막귀신(簾膜貴神)은 제1위에 입시(入試)가 적중하며 요극과의 호시격으로서 시(矢)가 되면 길흉이 두루 경미하게 된다. 다만 금(金) 또는 토(土)가 있으면 조금 강력하게 된다.｣ 초전 오(午)에 둔간하여 경금(庚金)이 있어 힘은 웅대하게 된다. 관성이 왕지(旺地)에 있으면 관공 현직에 있는 사람은 길조이고 상인(常人)은 불리하다. 일간일지 사신오미(巳申午未)가 교차지합(交車支合)으로 되어 교섭(交涉)․교무(交貿)는 모두 타협된다.
  일귀는 순행하고 삼전은 청(靑)․구(勾)․합(合)이다. 청신(靑神)․육합(六合)이 삼전 중에 있고 일간상신이 막귀(幕貴)에 승하여 시험과 선거는 반드시 적중한다. 야귀는 역행하고 삼전에 사(蛇)․주(朱)․합(合)이 승한다. 삼전 오사진(午巳辰)은 역연여(逆連茹)로 된다. 사과를 떠나서 삼전을 구성하면 회환격(廻環格)으로 된다. 초전이 간(干)의 제1과 또는 제2과 상신이 발용(發用)하는 것을 투출(透出)이라고 하며 또한 조일격(朝日格)이라고도 하는데, 별동(別動)하여 이익이 되지 않는다.
  연주퇴여(連珠退茹)는 어떤 일도 逆調의 일이 많다. 또 처음에는 길흉이 함께 세력이 강한 것 같아도 오래지 않아서 미약하게 되며, 길흉이 모두 크지 않다. 화는 외부로부터 발생한다.`
  ,
  // 3
  `  이 과는 사과중에 제1과와 제4과가 二上은 二下를 극하고 지반 申의 상신을 취하여 초전으로 하며 섭해과(涉害課)이다. 삼전 오진인(午辰寅)은 역간전(逆間傳)으로서 고조격(顧祖格)이며, 삼전은 사과 중을 떠나지 않고 회환격(廻環格)으로 된다. 일간상신 오(午)의 관귀효(官鬼爻)는 흉조로 되지만 토(土)의 인수(印綬)로 화하여 흉을 제거한다.
  고조격(顧祖格)은 길격(吉格)이며 인(寅)을 보아 장생으로 된다. 오(午)에 의해 인(寅)을 전하고 자(子)에 의해 돌아와서 모(母)를 돌보기 때문에 고조격이라고 부른다. 사물은 복구(復舊)의 상이 있고 구재와 모사는 어느 것이나 호조(好調)이다. 적(賊)은 가고 대인(待人)은 온다. 병의 정단은 흉하고 관사(官事)는 길하다.
  말전은 재(財)로 되고 병(丙)의 둔귀(遁鬼)에서 극을 당하지만 기궁(寄宮)의 신(申)은 지반 술(戌)의 상(上)에 있어 생(生)을 받으며, 귀(鬼)는 토(土)에 생화(生化)하여 피난도생격(避難逃生格)으로 된다. 백법에 말하기를 ｢일지는 간상에 와서 일간을 극하고 그는 나에게 와서 구해(仇害)하려 한다｣고 하였다. 비전에 말하기를 ｢말전의 인(寅)은 초전의 오(午)를 생하고 오(午)의 화(火)는 경금(庚金)을 극한다. 쟁송(爭訟)에 있어서는 타인의 사주(使嗾)에 의한 고조격(顧祖格)은 친근하게 맞고 낡은 정은 따뜻하게 하는 상이다. 따라서 계획하고 바라는 건은 처음에 시도하여 길과(吉果)를 얻게 된다｣고 하였다. 현임 관리는 불만이 많다.
  일귀는 순행하여 삼전은 청(靑)․합(合)․사(蛇)이며 청은 사(蛇)로 화하여 흉조로 변한다. 야귀는 삼전이 사(蛇)․합(合)․청(靑)으로 되며 사는 청으로 변화하여 농․공․상 어느 것이나 길과(吉果)이다.
  섭해견기격(涉害見機格)은 일을 구하면 후에 성공한다. 즉 처음은 곤란하고 후에 쉽다는 것을 알 수 있다. 또 견기(見機)는 기(機)를 보아 기회를 타는 것이 좋다. 처음은 곤란하더라도 결과는 성공에 도달한다. 불비격은 사물이 불비부족하고 거래나 기타 어느 것이든 정확한 곳에 결함이 있다.`
  ,
  // 4
  `  이 과는 4과 중에 제1과의 사신(巳申)과 일상이 일하를 극하여 원수과(元首課)이다. 초전의 사(巳)는 일간의 경금(庚金)을 극하여 관성(官星)이 된다. 그러나 말전 해(亥)는 설탈(泄脫)의 신이더라도 구신(救神)의 작용을 하여 귀해(鬼害)의 화(禍)를 해소한다.
  원수과는 상에서 하늘이 극하여 순리의 상이 되고, 동란이나 비정상적인 이유가 없으므로 만사는 길상을 이룬다. 일상은 하를 극하여 존장이 하를 제어한다. 사주추명(四柱推命)에 관한 정관(正官)과 같은 길조의 과로서 육임 64과의 수(首)이다.
  점단의 건은 만사가 순리롭고 주종(主從)이 화합하며 부자(父子)는 친근하고 자애롭다. 결혼은 해로(偕老)의 기쁨이 있고 임신은 남아를 잉태하며, 관직이나 봉급 생활자는 기쁨이 있고, 가정에는 경사가 많다. 길신이 왕상(旺相)할 때는 더욱 길을 중첩하고, 운룡풍호(雲龍風虎)가 서로 따르는 대인(大人)의 상이다.
  음력(陰曆) 월말의 정단은 천번(天煩)으로 되어 이 과를 기(忌)한다. 삼전의 사맹신(四孟神)은 원태격(元胎格)으로 된다. 원태는 임신의 과로 자녀의 건이 아니면 어떤 개혁되는 일로써 새 것에 따라 사물을 순리롭게 한다.
  백법에 말하기를 ｢제3과의 묘(卯)는 둔간하여 정신(丁神)을 승한다. 그리하여 금일(金日) 정신(丁神)은 묘(卯)의 재신(財神)으로 되기 때문에 처(妻) 또는 이성(異性)으로 인하여 재(災)가 발생한다｣고 하였다. 또 묘(卯)는 경(庚)에서 보아 십이운(十二運)이 태(胎)로 되며 술월(戌月)에 정단하면 묘(卯)는 사기(死氣)에 해당한다. 태방(胎方)과 사기가 동승하면 태아를 손상한다. 삼전은 하에서 순(順)을 체생(遞生)한다. 그러나 해(亥)는 공망하기 때문에 타인의 추천도 힘이 되기 어렵다.
  일귀는 순행하고 삼전은 구(勾)․사(蛇)․음(陰)이며, 야귀는 역행하여 삼전이 주(朱)․청(靑)․상(常)으로 된다. 비전에 말하기를 ｢사(巳)가 신(申)에 가해지고 묘(卯)가 오(午)에 가해지는 것은 백신(百神)은 택(宅)에 임한다고 하며 재화를 방지해야 한다. 즉 일간상신이 일간을 극하고 일지상신에 정(丁)이 승하여 일간을 극하기 때문이다.`
  ,
  // 5
  `  이 과는 제2과와 제4과에 이하(二下)와 이상(二上)을 적(賊)하지만 제4과 인(寅)의 지반의 극을 취하여 초전으로 하는 지일섭해과(知一涉害課)이다. 일간상신(日干上神)은 진(辰)의 토(土)에 의해 토생금으로 상생하지만 일야(日夜)의 귀신은 현무에서 생을 받아 길로 되어도 천장(天將)의 흉신으로서 신뢰하기 어렵다.
  삼전은 초․중전이 공망하고 삼합화국(三合火局)이 된다. 오히려 말전의 인(寅)의 재(財)는 일지상신에 돌아온다. 피차 모두 화국(火局)의 살(殺)이 되지 않으니 허성(虛聲)을 울릴 뿐 실해(實害)는 없다. 그러나 인(寅)의 재(財)가 오(午)의 귀지(鬼地)에 있기 때문에 길흉이 상반하여 즐거운 가운데 우려가 있다. 술(戌)에 육합이 승하여 인(寅)에 가해지면 사용인이 도주하거나 해고된다. 진술(辰戌)이 초전으로 되면 참관격(斬關格)이 되지만, 염상격(炎上格)은 참관을 통하여 구사(舊事)를 버리고 새로운 것을 취한다. 삼전이 전귀(全鬼)일지라도 공망하므로 두려울 것이 없다. 귀신이 있어도 깊게 숨고 살(殺)도 전변하여 길복을 이룬다.
  백법에 말하기를 ｢중귀(衆鬼)가 나타나더라도 전혀 두렵지 않다. 후(后)․합(合)을 점혼하여 중매인이 없이 결합한다｣고 하였다. 비전에 말하기를 ｢간상의 진토(辰土)는 경금(庚金)을 양육한다. 이와 같은 경우에는 삼전의 귀(鬼)가 생(生)으로 화하여 흉이 길로 변화한다｣고 하였다.
  일귀는 역행하고 삼전은 합(合)․백(白)․후(后)이며, 지반 해(亥)에 천공․귀신이 임하면 막귀(幕貴)가 천문(天門)에 오른다고 하여 시험과 선거는 합격된다. 야귀는 순행하고 삼전은 합(合)․후(后)․백(白)이며, 현임 관직의 사람에게 유리하다. 삼합하여 관귀효(官鬼爻)는 관직 공명의 류신이지만 이 건은 공망되어 목적을 달성하지 못한다. 만약 년명상신 술(戌)이 전실공망(塡實空亡)하면 목적을 달성한다.
  참관격은 진(辰) 또는 술(戌)이 초전으로 되는 것을 말한다. 진(辰)은 천강(天罡)이라고 하며, 술(戌)은 천괴(天魁)라고 부르며 이를 괴강(魁罡)이라고 부른다. 토(土)가 겹쳐서 폐색되어 천관(天關)을 건너기 어렵고 도로에 통행하는데는 관문(關門)을 참(斬)하여 열기 때문에 참관격이라고 부른다. 술(戌)은 천괴(天魁)로 하고 인(寅)을 가하여 천량(天梁)이라고 부른다. 그러므로 출행에 길하며 미(未)는 해(亥)에 임하여 천문에 오르고 진술축미(辰戌丑未) 사살(四殺)은 사우(四隅)에 매몰되므로써 사대길시(四大吉時)로 되어 만사가 순리롭다.`
  ,
  // 6
  `  이 과는 제1과․제2과에 하에서 상을 적(賊)하고 양일(陽日)로 되면 양비(陽比)의 과(課)를 취하여 술묘(戌卯)를 초전으로 하고 지일과 비용격(比用格)으로서 일간의 상신은 재성(財星)으로 된다. 간지는 묘(卯)와 오(午)가 상생하고 신(申)과 축(丑)이 상생하여 교차상생격(交車相生格)으로 된다. 초전 술(戌)의 공망이 중전 술(戌)의 공지(空地)에 있으므로 말전 자(子)의 일신(一神)을 취하여 길흉을 정단한다. 간상의 묘(卯)는 지신(支神)을 생하고 지상(支上)의 축(丑)은 일간을 생하여 구생격(俱生格)으로 되며 상호간에 생의(生意)가 있다. 경일(庚日) 묘(卯)는 신(申)에 가하여 금일(今日)의 태신(胎神)으로 되므로 출산을 정단하면 당일에 출생하는데 태신을 극절(剋絶)하기 때문이다. 비용과(比用課)는 역(逆)에 따르므로써 자세히 검사해야 한다. 또 용전(龍戰)으로 되면 덕(德)을 수(修)하여 길하고, 위덕(違德)은 흉을 형성하며 만사의 정단은 초, 중전이 공망하여 허성이 있기 때문에 경솔하게 믿는 것을 두려워한다. 백법에 말하기를 ｢묘(卯)는 정신(丁神)에 해당한다. 즉 금일(金日) 정신(丁神)을 만나는 것은 흉화(凶禍)로 인하여 동(動)하고 태재(胎財)의 생기(生氣)는 처가 임신한다｣고 하였다. 비전에 말하기를 ｢술(戌)은 묘(卯)에 가하여 지합하므로 타인을 믿어 내몸은 길행(吉幸)을 얻게 된다｣고 하였다. 또 술사(戌巳)는 모두 공망하고 말전의 자(子)는 탈기(脫氣)되어 모든 일이 허모(虛耗)가 된다. 묘술(卯戌)은 패묘(敗墓)로 되어 은(恩)을 베풀어서 구원(仇怨)을 푼다.
  일귀는 역행하고 삼전은 합(合)․상(常)․사(蛇)이며, 야귀는 순행하여 삼전은 합(合)․음(陰)․청(靑)으로 된다. 자(子)는 사(巳)에 가해져 병단(病斷)은 불리하다. 묘유일(卯酉日)에 점과(占課)하여 묘유(卯酉)의 상신을 초전으로 할 때는 용전격(龍戰格)으로 된다. 그러나 금일(今日)은 오일(午日)로서 초전은 묘(卯)의 상신을 초전으로 하기 때문에 진정한 용전(龍戰)이 아니다. 
  관작격(官爵格)은 세월년(歲月年)의 역마가 초전으로 되거나 천괴(天魁) 즉 술(戌)과 태상이 삼전 중에 있는 것을 취하며 이는 후자에 속한다. 술(戌)을 묘(卯)으로 하고 관직이 승진하며 태상(太常)도 같다. 그러므로 공망을 만나 길조는 변화하고 주사(主事)는 지체되어 관작실인(官爵失印)이라고 한다. 관직에 있는 사람은 불상사(不祥事)가 발생하고 기쁨은 변하여 흉으로 된다.`
  ,
  // 7
  `  이 과는 천지반이 각각 충위(冲位)에 있고 상극하여 반음과(返吟課)로 되고 음양이 왕래하고 적(賊)이 반복하므로 반음과라고 부른다. 오는 사람은 떠나려 하고 떠날 때에는 돌아올 것을 생각한다. 득실이 일정하지 않고 얻으면 먼저 잃고 패하면 반대로 성공한다.
  삼전의 인신인(寅申寅)은 일간(日干) 지(支)의 역마에 해당하여 원행(遠行)과 이동의 정단이다. 흉신(凶神)․흉장(凶將)을 만나면 손실을 초래하고, 동(動)하면 무익하며 고생이 겹쳐서 발생한다. 길신(吉神)․길장(吉將)이 승하면 길조가 있다. 이 과(課)의 대부분은 이동하여 모든 일이 양기(兩岐)에 걸친다. 상하친자(上下親子)가 불화하고 부처(夫妻)가 외심(外心)이 있으며, 병단은 양(兩)증으로 만사가 반복되어 결정하기 어렵다. 초전 인(寅)의 재(財)는 절지(絶地)에 임하고 또 둔간(遁干)하여 병(丙)의 둔귀(遁鬼)로 된다. 따라서 재(財)로써 재(災)를 초래하거나 식물(食物)로써 몸을 손상시킨다. 처(妻)로 인하여 소송을 만나므로 만사에 근신해야 한다.
  원태격은 인정에 어둡고 불순(不順)하여 매사에 반복하고 후에 성취된다. 대체로 간난(艱難)의 과격(課格)이다. 백법에 말하기를 ｢백호가 둔간(遁鬼)를 승하는 것은 재(災)가 얕지 않다. 주객(主客)의 정의(情意)가 투합(投合)하지 않는 것은 형(刑)이 상에 있기 때문이다｣고 하였다. 비전에 말하기를 ｢반음과를 얻은 정단은 사물이 반복되고 두 개의 사건 또는 사건이 겹쳐서 발생하는 등의 일로 심신(心身)이 동요된다｣고 하였다. 그리하여 피차 원심(怨心)을 품을 우려가 있다.
  인(寅)은 천리공조(千吏公曺)라고 부르며 관공직의 신(神)이다. 병(丙)의 둔귀가 있어 현임관리는 승진의 기쁨을 만난다. 신(申)은 일마(日馬)가 거래하여 빨리 된다는 암시이다. 일귀는 역행하고 삼전은 후(后)․청(靑)․후(后)이며 모두 길(吉)이다. 야귀는 순행하고 삼전은 백(白)․사(蛇)․백(白)이며 구직․봉급생활자는 길조가 있다. 보통의 정단(正斷)은 재(財)로 인하여 재(災)를 생하고 형(刑)을 범하면 형벌에 걸리며 병단은 사망한다.`
  ,
  // 8
  `  이 과는 제3과와 제4과의 이상(二上)에서 하를 극한다. 금일(今日)이 양일(陽日)이면 양의 비(比)를 쓰기 때문에 제4과 진해(辰亥)를 초전으로 하여 지일과 비용격(比用格)으로 한다. 제4과를 초전으로 하는 것은 맥월격(驀越格)으로 일이 홀연히 발생한다.
  초전 진(辰)의 토(土)는 일간을 생하여도 해(亥)의 공지(空地)에 앉으므로 생으로 보지 않는다. 중전 금(金)은 일간(日干)의 왕신(旺神)이며 말전 인(寅)은 일간의 재성(財星)이다. 신왕(身旺)하여 재(財)를 보아도 인(寅)의 둔간은 병(丙)은 귀살(鬼殺)로 되어 재(財)로 인해 재(災)를 초래하기 쉽다. 제3과․제4과를 용신(用神)으로 하는 것은 길흉 사건이 내부에서 발생한다. 그러므로 공망하는 것으로써 인정은 소원(疏遠)하게 된다.
  초전은 해(亥)의 공망에 앉고 중․말전은 차순(次旬)의 공망 신유(申酉)에 해당한다. 유(酉)가 중․말전에 있는 것은 각답(脚踏)이 공망으로 되기 때문에 사물은 전진하면 길하고 후퇴하면 불길하다.
  백법에 말하기를 ｢교차탈기(交車脫氣)로 되는 것은 인택(人宅)이 탈(脫)을 받는다고 하며 도난을 만날 우려가 있다. 삼전이 서로 극하는 것은 중인(衆人)에게서 기만당하고 묘신(墓神)이 일간에 복하여 사람은 혼미하다｣고 하였다. 비전에 말하기를 ｢경오일(庚午日) 일간상신 축(丑)은 일지 간(干)에서 탈설(脫泄)하고 지상(支上)이 해(亥)는 일간에서 탈설한다.｣ 이와 같은 것은 피아상탈(彼我相脫)이라고 하며 재(財)가 동쪽에서 들어와 서쪽으로 나간다. 이재를 구하여 얻어도 불리하다.
  또 공명을 정단하는 것은 바른 길이 아니면서 출신(出身)한다. 일귀는 역행하고 삼전은 현(玄)․구(勾)․후(后)이며, 야귀(夜貴)는 순행하고 삼전은 현(玄)․주(朱)․백(白)으로 된다. 현무(玄武)는 공지(空地)에 임하기 때문에 도적은 잡기 어렵다. 야귀는 막귀(幕貴)로 되어 응시(應試)는 반드시 중위(中位)에 적중한다. 일간상신이 묘(墓)에 복하여 초전은 진(辰)의 용묘(用墓)로 된다. 묘는 폐색(閉塞)의 상으로서 모사(謀事)에 이롭지 않으며, 가운(家運)을 정단하면 집안이 번영하지 못한다. 병은 흉하고 재(財)는 모산(耗散)한다. 일상(日上)에 일묘(日墓)가 복한다고 말하며 사람은 혼미하고 운명은 쇠퇴한다.`
  ,
  // 9
  `  이 과는 제2과․제4과의 이상(二上)에서 하를 극하고 맹지(孟地)의 극이 없다. 그러므로 중신(仲神)의 진자(辰子)의 극을 초전으로 하여 섭해과(涉害課)이다.
  삼전은 진신자(辰申子)의 삼합수국(三合水局)으로 되고 일간은 경금(庚金)에서 전탈(廛脫)이 된다. 또 일간에서 간상신(干上神)을 탈설(脫泄)하고 일지 오(午)에서 술(戌)에 화생토(火生土)로 탈설한다. 이와 같은 피점단(被占斷)의 건은 대개는 질병의 건이다. 또 가택을 정단하면 소모 손실이 많다. 이와같이 전부 탈설이 많으니 내정(來情)은 물심재(物心財)의 소모이거나 혹은 탈상탈(脫上脫)에서 허사(虛事) 손실의 건이다.
  이 과는 초전의 지반 자(子)가 순수(旬首)이며 현무를 승하므로 폐구격(閉口格)이다. 또한 수미(首尾)가 서로 가하여 환원(環圓)의 상이다. 끝이 없어 그 입을 볼 수 없으므로 폐구(閉口)라고 이름을 붙였다. 입을 봉하고 말하지 않으므로 어떤 일도 종적을 명확하게 하기 어렵다. 찾는 사람은 그림자를 감추고 실물(失物)은 잠장은닉(潛藏隱匿)의 징조가 있다. 임신은 벙어리이고 또 입 주위에 고장이 있으며, 정단사는 모두 후에 성취된다.
  폐구격은 병단은 흉하고 무록(無祿)을 만드는 것은 반드시 죽는다. 현무는 도적의 류신이다. 정양신(正陽神)에 승하면 양신(陽神)의 지반을 공격하고, 음신(陰神)에 승하면 그 음신의 지반을 공격한다. 예를 들면 초전 진(辰)에 현무가 승하므로 자(子)의 북방을 찾아야 한다. 백법에 말하기를 ｢탈상(脫上)에 탈(脫)이 있는 것은 허사(虛詐)를 방지해야 한다. 사과삼전(四課三傳) 육양격(六陽格)은 공용(公用)은 길하고 사정(私情)의 건은 흉하다｣고 하였다. 비전에 말하기를 ｢진신자(辰申子)의 삼전 중에 초전 진(辰)의 천강(天罡)이 있는 것은 두(斗)를 가리킨다고 하며 음양천상(陰陽天象)을 완미(玩味)하게 한다｣고 하였다.
  일귀는 역행하여 삼전이 현(玄)․청(靑)․사(蛇)이고 야귀는 순행하여 삼전은 현(玄)․사(蛇)․청(靑)으로 된다. 일간 경(庚)의 기궁(寄宮) 신(申)은 지반 진(辰)에서 토생금(土生金)으로 생을 받기 때문에 전탈(全脫)이더라도 흉의 작용은 경하다. 순수(旬首) 자(子)의 상에 현무가 승하는 것은 포도(捕盜)에 유리하여 토성(土性)인 사람이 반드시 잡을 수 있다.`
  ,
  // 10
  `  이 과는 제3과의 화극금(火剋金)으로 하에서 상을 극하는 유오(酉午)를 초전으로 하는 중심과(重審課)이다. 자오묘유(子午卯酉)의 사중일(四仲日)에 삼전의 사중신(四仲神)은 삼교격(三交格)으로 되고, 말전의 묘(卯)는 일간의 재(財)가 되는 정신(丁神)에 해당하고 금일(金日) 정신은 흉화(凶禍)로 동(動)한다.
  삼전이 충형파(冲刑破)로 되고 일귀가 술(戌)에 임하여 야귀는 진(辰)에 들어간다. 즉 귀신(貴神)이 진술(辰戌)에 임하면 입옥(入獄)이라고 한다. 또 일귀는 야지(夜地)에 임하고 야귀는 주지(晝地)에 있다. 이와 같은 것을 귀인착오(貴人蹉誤)라고 한다. 유(酉)는 부녀의 사용인에 해당된다. 양인(羊刃)과 주작․구진이 병승(倂乘)하는 것은 사용인의 일이며, 또는 재(財)에서 상해 사건이 발생하거나 처첩이 서로 싸우는 가정불화가 일어나기 쉽다. 이것은 삼전이 모두 충형파(冲刑破)로 되기 때문이다.
  삼교(三交)라는 것은 앞에서 서술한 바와 같이 사중일(四仲日)에 삼전이 사중(四仲)으로 되는 것을 말한다. 그러므로 사과삼전(四課三傳) 중에 중신(仲神)의 천장이 승하는 것을 말한다. 예를 들면 태음은 신유(辛酉)의 중신이 가해져 제1교(第一交)로 하고 일지상신에 유(酉)의 중신이 가하여 제2교로 되며 삼전의 중신으로 제3교가 된다. 상기한 것과 같이 삼자가 상교(相交)하는 것은 삼교격이다. 가정에 음사불명(陰私不明)의 일이 있고 구재는 이익이 없고 소송은 관재(官災)가 생기며 병전(兵戰)을 나타낸다. 남자는 형죄를 범하고 여자는 사통간사(私通姦邪)의 행위를 한다.
  백법에 말하기를 ｢금일정(金日丁)에 만나는 것은 동하여 흉화가 있고 귀신착오(貴神蹉誤)는 사물의 차질(蹉跌)을 초래한다. 주객(主客)이 투합(投合)하지 않는 것은 형(刑)은 상에 있고 삼전의 재성(財星)이 귀(鬼)에 화하기 때문이다. 그러므로 재를 구하는 것을 중지해야 한다. 비전에 말하기를 ｢경오일(庚午日) 간상에 해(亥)가 가해져 말전 묘(卯)를 보고 정신(丁神)이 승하면 처(妻)로 인하여 흉에 동하거나 재로 인하여 화가 발생한다.｣ 삼전이 체생(遞生)하여도 삼교격은 불길의 격이 되면 체생도 효과가 박하다. 그러므로 일귀는 역행하고 삼전은 구(勾)․사(蛇)․음(陰)이다. 야귀는 순행하여 삼전은 주(朱)․청(靑)․상(常)으로 된다. 야귀는 일귀에 승(勝)하므로 길조가 있다. 그러나 귀신(貴神)이 진술(辰戌)에 임하면 공명․구직은 어느 것이나 불량하다.`
  ,
  // 11
  `  이 과는 제2과․제3과의 하에서 상을 극하여 비용(比用)을 채용하지 못한다. 또 맹신(孟神)의 극이 없으므로 중신(仲神)을 취하여 용신(用神)으로 하는 섭해과(涉害課)이다. 초전 신(申)은 일록에 해당하고 중․말전은 공망한다. 귀묘(鬼墓) 및 탈기(脫氣)인 자(子)의 하에 술(戌)의 공망이 있기 때문에 일간은 흉해를 보아도 흉에서 면(免)한다. 일간기궁(日干寄宮)의 신(申)은 지상에 투(投)하고 오(午)의 화(火)에서 극을 받는다. 사과는 삼전을 떠나지 않고 회환격(廻環格)으로 되어 물(物)의 두서(頭緖)는 단단하다. 또 병자는 불치의 우려가 있다. 순수(旬首)의 자(子)가 말전으로 되는 것은 난수격(亂首格)으로, 만사는 위역(違逆)하고 우환은 해소(解消)가 불가능하다. 길사는 이루기 쉽고 구노(仇怒)는 해결되지 않으나 만약 길장․길신을 만나면 흉이 해소된다. 귀인이 해(亥)에 임하고 귀신은 천문에 오르며, 신장살몰(神藏殺沒)하여 재앙(災殃)이나 기타의 불상(不祥)은 없어진다.
  백법에 말하기를 ｢일지상신이 건록에 해당하면 권섭(權攝)이 바르지 않고 강색귀호(罡塞鬼戶)는 모사(謀事)를 맡지 못한다｣고 하였다. 비전에 말하기를 ｢경오일(庚午日) 일간상에 술(戌)이 가해져 초전․중전의 지반에 야귀를 공(拱)하기 때문에 관록승천(官祿昇遷)의 기쁨이 있다. 즉 전후인종(前後引從) 및 승천(昇遷)은 길조를 얻는 소이 이다. 그러나 공망하므로 길은 경하다. 또한 난수격(亂首格)은 하(下)는 상(上)의 존장을 범하여 부정의 행위가 있다.
  일귀는 순행하여 삼전은 백(白)․현(玄)․후(后)이고, 야귀는 역행하여 후(后)․현(玄)․백(白)으로 된다. 막귀(幕貴)가 공망하여 입시는 낙선의 고(苦)가 있으며, 사살(四殺)이 몰(沒)하므로 위험을 구하는 격이다.`
  ,
  // 12
  `  이 과는 사과 중에 상하의 극이 없고 또 요극(遙剋)도 없다. 그러므로 양일이면 지반 유(酉)의 상신(上神) 술(戌)을 취하여 초전으로 한다. 중전은 일지상신을 취하고 말전은 일간상신을 취하여 삼전을 구성하므로 묘성과(昴星課)이다.
  유(酉) 중에 묘숙(昴宿)이 있고 유(酉)는 서방 백호의 금기(金氣)로서 성(性)은 형살(刑殺)의 의(義)를 다스린다. 상극을 대체하여 형살의 신(神)인 양금(兩金)을 사용하고 유(酉)의 신명(神名)을 채용하므로 묘성과라고 부른다. 음일은 양일에 의해 유(酉)의 상하를 용신(用神)으로 하기 때문에 복앙격(伏仰格)이라고 이름을 붙였으며 이 격은 관량폐색(關梁閉塞)이라고 한다. 조용히 지키면 길하고, 망동(妄動)하면 화가 밖에서 발생한다. 그러므로 집안에 있으면 화(禍)를 면한다.
  초전은 공망하여 현무에 승하며 인택(人宅)의 손모(損耗)를 받아도 공망하여 경미하다. 래정(來情)은 분실의 건이거나 허(虛)에 놀란 사건이다. 호시(虎視)․전봉격(轉蓬格)은 털을 불어 흠집을 구하는 상(象)이다. 사과(四課)가 전부 지반의 전지(前支)로 되기 때문에 나망(羅網)으로 되어 흉화를 방지해야 한다. 마음이 미혹되면 의문이 생긴다. 오직 관록(官祿)의 건만은 길조이다. 즉 야귀는 천반의 녹신(祿神)에 공(拱)하기 때문이다.
  백법에 말하기를 ｢전후인종(前後引從)은 승천(昇遷)이 길하며 나망을 만나 모사는 졸책(拙策)을 초래한다｣고 했다. 비전에 말하기를 ｢경오일(庚午日)의 일간상신 유(酉)는 양인(羊刃)이 되고 태상(太常)은 연회주식(宴會酒食)을 주재한다｣고 하였다. 나망(羅網)․양인(羊刃)․구살(勾殺)의 3살이 상병(相倂)하므로 연석(筵席)에서 투쟁이 발생한다.
   일귀는 순행(順行)하여 삼전이 현(玄)․공(空)․상(常)이며, 야귀는 역행(逆行)하여 삼전이 현(玄)․귀(貴)․음(陰)으로 된다. 이때는 전체적으로 자기를 지키고 조용히 때를 기다리는 것이 상책이다. 초려조급(焦慮操急)이라고 부르며, 음일의 묘성과는 동사엄목격(冬蛇掩目格)으로 된다. 이 과는 양일일 때는 호시전봉격으로 된다. `
  ,
]

export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
