import React, { useState } from "react";
import firebase from "../firebase";

import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css';


const modules = {
    toolbar: [
      // [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      // ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'camera'],
      // [
      //   { list: 'ordered' },
      //   { list: 'bullet' },
      //   { indent: '-1' },
      //   { indent: '+1' },
      // ],
      // ['link', 'image', 'video'],
      // ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    'header',
    // 'font',
    // 'size',
    // 'bold',
    // 'italic',
    // 'underline',
    // 'strike',
    // 'blockquote',
    // 'list',
    // 'bullet',
    // 'indent',
    // 'link',
    // 'image',
    // 'camera',
    // 'video',
  ]
  
  // <div className={``}></div>
  // const pageCode1 =  'page000/1/find-id';
export default function Forms() {
  const [textInput1, setTextInput1] = useState("");
  const [textInput2, setTextInput2] = useState("");
  const [textInput3, setTextInput3] = useState("");
  const [textInput4, setTextInput4] = useState("V");
  const [textInput5, setTextInput5] = useState("");

  
  const [quillValue1, setQuillValue1] = useState();
  const [quillValue2, setQuillValue2] = useState();
  const [quillValue3, setQuillValue3] = useState();
  const [quillValue4, setQuillValue4] = useState();
  const createTodo = (event) => {
    event.preventDefault();
    const todoRef = firebase.database().ref("Todo");
    // const otherRef = firebase.database().ref("other");
    // const other = {
    //   title,
    // };
    const todo = {
      title : textInput1,
      order : textInput2,
      imageUrl : event.imageUrl ? event.imageUrl : "",
      videoUrl : textInput3,
      type : textInput4,
      description : quillValue1, 
      // title : textInput1,
      complete: false,
    };
    todoRef.push(todo);
    // otherRef.push(other);
    setTextInput1("");
    setTextInput2("");
    setTextInput3("");
    setTextInput4("");
    setTextInput5("");
  };

  return (
    <form onSubmit={createTodo}>
    <input
      type="text"
      placeholder="제목 입력 Enter Title..."
      className="task-input"
      value={textInput1}
      required
      onChange={e=> {
        setTextInput1(e.target.value)
      }}
    />
    <input
      type="number"
      placeholder="순서 번호 입력 Enter Order Number..."
      className="task-input"
      value={textInput2}
      required
      onChange={e=> {
        setTextInput2(e.target.value)
      }}
    />
    <textarea
      type="text"
      placeholder="비디오/비메오 영상 주소 입력 Enter Video(Vimeo) Url..."
      className="task-textarea "
      value={textInput3}
      required={false}
      onChange={e=> {
        setTextInput3(e.target.value)
      }}
    />

    <QuillWrapper
      style={{
        maxWidth : `calc(100vw - 400px)`,
        width : `calc(100vw - 400px)`,
        background : '#fff',
      }}
      theme={'snow'}
      id={'description'}
      placeholder={'설명을 입력해주세요'}
      value={quillValue1}
      modules={modules}
      formats={formats}
      onChange={(event) => setQuillValue1(event)}
  /> 
    <div className="flex-column ">
<select name="imageType" id="imageType" className="task-select" value={textInput4}
  onChange={e=> {
    setTextInput4(e.target.value)
  }}
>
      <option value="V">V (영상/비디오)</option>
      <option value="Y">Y (연혁)</option>
      <option value="Z">Z (조직도)</option>
      <option value="A">A (인사말씀)</option>
      <option value="B">B (저술목록)</option>
      <option value="C">C (강의안내)</option>
      <option value="D">D (앱/도서/프로그램 효과 활용 설명회)</option>
      <option value="E">E (컬럼 마당)</option>
      <option value="F">F (회원 동정)</option>
      <option value="G">G (공지)</option>
</select>
</div>
      <button className="button-add" type="submit">
        Add
      </button>
    </form>
  );
}
