
const VERSION_ILSANGSAEHWAL_GILHUENG_METHED = [
    [1,"子","子","갑자자(甲子子)『좋습니다! 다 잘 되겠습니다!』","▲ 이 괘(卦)를 얻었을 때에는 천시(天時), 지리(地利), 인화(人和)를 얻어 제사(諸事) 협력(協力) 화합(和合)하며, 기쁨을 얻게 됩니다.; 소망(所望)이나 계획(計劃)은 진행(進行)시켜서 좋을 것입니다.; 수하인(手下人)의 존경(尊敬)을 받고 재화(財貨)를 얻는 기쁨은 아래에서 위로 올라오는 상(常)입니다.; 그 위에 기쁨은 그침이 없게 됩니다.; ▲ 경영(經營)하는 일은 순조(順調)롭게 진행(進行)되고, 목적(目的)은 무난(無難)히 달성(達成)될 것입니다.; ▲ 재화 순리(財貨 順利)를 구(求)하는 일은 상당(相當)한 이(利)를 얻게 됩니다.; ▲ 영업(營業) 이익(利益) 대차(貸借), 또는 희망 계획(希望 計劃)하는 일은 거의 모든 일이 일거양득(一擧兩得)의 기쁨을 얻게 됩니다.; ▲ 구재지사(求財之事)는 동방(東方), 남방(南方)이 길방(吉方)이며, 크게 애쓰지 않아도 목적(目的)이 달성(達成)됩니다.; 갑오일(甲午日)에 기쁜 일이 있게 될 것입니다.; ▲ 자금조달(資金調達)은 가능(可能)하고 임금(賃金)은 회수(回收)될 것입니다.;"],
    [2,"子","丑","을축자(乙丑子)『좋은 소식이 곧 옵니다. 기대하십시오』","▲ 음신(音信)은 좀 늦어질 징조(徵兆)가 보입니다.; 그러나 일순 중 후반(一旬 中 後半)에는 원근(遠近)을 불문(不問)하고, 꼭 도착(到着)합니다.; 음신(音信)은 좋은 소식(消息)이고 정신(精神)이 명쾌(明快)하게 되겠습니다.; 또는 귀인(貴人)의 가음(佳音)에 접(接)하여 좋은 일을 보게 됩니다.; 다음 갑일(甲日)이 지난 후(後)에 래신(來信)이 있는 것이 정상(正常)입니다.; ▲ 어떤 일이든지 공허(空虛)한 것은 없고, 크게 기대(期待)할 만한 음신(音信)일 것입니다.;"],
    [3,"子","寅","병인자(丙寅子)『반드시 성립됩니다. 인연이 좋습니다』","▲ 주위(周圍)의 상부상조(相扶相助)를 얻어 반드시 혼인(婚姻)은 성립(成立)합니다.; 혼인 후(婚姻後)에는 부부화합(夫婦和合)하겠습니다.; ▲ 양가동심(兩家同心)이 되어 약혼(約婚)이 성립(成立)되면 천우(天佑)를 얻어 부부전도(夫婦前途)는 행복(幸福)을 얻겠습니다.; 부부화합(夫婦和合), 좋은 가정(家庭)이 이룩됩니다.; ▲ 경사(慶事)는 빨리 거행(擧行)하는 것이 길(吉)합니다.;"],
    [4,"子","卯","정묘자(丁卯子)『모든 것이 새롭고 이익이 되겠습니다』","▲ 동풍(東風)이 불어 새싹이 나오는 상(象)입니다.; 즉 송구영신(送舊迎新), 새로운 일이 시작(始作)될 징조(徵兆)입니다.; ▲ 여행(旅行)이나 외출(外出)은 다소(多少) 난점(難點)이 있을 것입니다.; 길흉상반(吉凶相半)이며, 목적(目的)은 반정도(半程度) 달성(達成)되겠습니다.; 외출(外出)이나 여행(旅行) 도중(途中)에 지체저해(遲滯阻害)가 생기기 쉬우므로 곧바로 가는 것이 상책입니다.; ▲ 대인(待人)이나 음신(音信)은 오겠으나 좀 늦어 지겠습니다.; 오는 것은 적으나 그것이 이익(利益)이 되겠습니다.; 육친(六親)이 온다면 함께 즐거워하는 경사(慶事)가 있겠습니다.;"],
    [5,"子","辰","무진자(戊辰子)『사리사욕을 품지말고 대의정도를 유지하면 길합니다』","▲ 이 괘(卦)를 얻으면 면담(面談)을 주선(周旋)하여줄 소개자(紹介者)를 만나게 되어 우적( 遇的)인 기쁨이 있겠습니다.; 그러나 이기적(利己的)인 생각과 자세(姿勢)로 임(臨)한다면 면담성과(面談成果)는 기대(期待)할 수 없습니다.; 대아(大我)를 위한 공익정신(公益精神)을 갖고 지성(至誠)으로 임(臨)한다면 방문성과(訪問成果)는 기쁨이 있겠습니다.; 그러나 급히 서두르지 마십시오.; 상대(相對)는 아직도 의혹(疑惑)을 품고 있습니다.; ▲ 방문(訪問)하면, 기다리는 일이 있으므로 면담(面談)할 수 있습니다.; 선사(善事)일 경우(境遇) 목적(目的)은 달성(達成)됩니다.; 단, 사리사욕(私利私慾)에 치우친 문제(問題)는 성취(成就)를 보기 어려울 것입니다.; ▲ 래객(來客)은 오고 있는 중(中)입니다.; 좀 늦어 지겠습니다.;"],
    [6,"子","巳","기사자(己巳子)『집에 왔던 여자의 소행입니다』","▲ 이 괘(卦)를 얻으면 자택(自宅)을 방문(訪問)하였던 여인(女人)이 절취(竊取)하여 땅에 묻어 감추었습니다.; 마음에 집히는 곳을 찾아보면 원위치(原位置)로 돌아 오게 될것입니다.; ▲ 계속(繼續)하여 유사(類似)한 피해(被害)가 예견(豫見)됩니다.; 가내 단속(家內 團束)을 잘해야 합니다.; 방심(放心)은 금물(禁物)입니다.;"],
    [7,"子","午","경오자(庚午子)『서쪽에 있으나 오지고 않고 찾지도 못합니다. 체념하십시오』","▲ 실종자(失踪者)는 서방(西方)에 있으나, 용이(容易)하게 발견(發見)되지 않으며, 또한 돌아올 의사(意思)도 없습니다.; 오래도록 행방(行方)이 아득할 것이고, 발견(發見)도 되지 않을 것입니다.; ▲ 시일(時日)이 경과(經過)하면 생사(生死)를 알 길이 없으므로, 그저 체념(諦念)하는 것이 좋으며, 다른 방법(方法)이 없습니다.;"],
    [8,"子","未","신미자(辛未子)『지금은 때가 아닙니다. 후일을 기약하십시오』","▲ 이 괘(卦)을 얻으면 계획(計劃)하는 일이 없더라도 불리(不利)하며 목적(目的)은 달성(達成)하기가 어렵습니다.; ▲ 굳게 본분(本分)을 지킨다면 해(害)는 없습니다.; 즉(卽) 파정풍랑식(波靜風浪息), 강호명월조(江湖明月照).; 이와같은 심경(心境)으로 때를 기다리는 것이 현명(賢明)합니다.; ▲ 풍파(風波)가 험악(險惡)합니다.; 강(江)을 건너는 일은 어렵습니다.; 목적(目的)은 성취(成就)하기가 어렵습니다.; 그러나 의지(意志)를 강(强)하게 갖고 끈기있게 노력(努力)한다면 후(後)에는 성취(成就)될 것입니다.;"],
    [9,"子","申","임신자(壬申子)『유리하고 장차의 바탕이 될것입니다. 그러나 인간적인 호소에는 응하십시오』","▲ 이 괘(卦)을 얻으면 소송(訴訟), 관사(官事), 모두 이유(理由)가 유리(有利)하므로 급히 서두르지 않아도 좋습니다.; 그리고 해결(解決)의 광명(光明)이 보입니다.; 하천(河川)에 맑은 물이 많이 흐르는 상(象)입니다.; 때를 얻었기 때문에 물은 흐리지 않습니다.; ▲ 모두가 이유(理由)가 유리(有利)하나 이외(理外)의 리(里)가 있는 법.; 그러므로 인정(人情)에 유념(留念)하는 것이 좋겠습니다.; 송쟁(訟爭)은 오히려 장래(將來) 유리(有利)한 동기(動機)를 잉태(孕胎)할 수도 있을 것입니다.;"],
    [10,"子","酉","계유자(癸酉子)『북쪽에 있는 신불에 기도하십시오. 임(壬)일 진(辰)일부터 좋아집니다』","▲ 이 괘(卦)을 얻으면 병세(病勢)는 아직 절정(絶頂)에 이르지 않았으며 점차(漸次)로 악화(惡化)될 조짐(兆朕)이므로 우수(憂愁)는 끝나지 않았습니다, 병증(病症)은 혈농증상(血濃症狀)으로서 두복경목에 종양(腫瘍)이 생기고 있으나, 임일(壬日)이나 진일(辰日)부터는 차차 병(病)이 퇴보(退步)하게 되겠습니다.; ▲ 양약(良藥)을 복용(服用)하여 병세(病勢)가 고비를 넘기면 점차 가벼워 지겠습니다.; ▲ 북방(北方), 또는 손방(巽方), 동남방(東南方)에 양의(良醫)가 있습니다.; 북(北)쪽 방향(方向)의 신불(神佛)에 기원(祈願)하십시오.;"],
    [11,"子","戌","갑술자(甲戌子)『아직 시기가 아닙니다, 기다리십시오』","▲ 매매(賣買) 어느쯕이건 비용(費用)이 들고 노력(努力)을 들여도 성립(成立)은 어렵습니다.; 최소(最小)의 목적(目的)은 어렵게나마 성립(成立)하나 큰 목적(目的)은 가망(可望)이 없다.; 신중(愼重)을 기(期)하지 않으면 손재(損財)만 하고 기회(機會)도 빨리 오지 않겠습니다.; ▲ 신규사업(新規事業) 착수(着手)를 위한 일은 기다리는 것이 현명(賢明)합니다.; ▲ 상담(商談)은 아직 시기(時期)가 빠르며, 기회(機會)를 보는 것이 중요(重要)합니다.; 행(幸)은 후(後)에 기대(期待)됩니다.;"],
    [12,"子","亥","을해자(乙亥子)『임계(壬癸)일에 출산하고 남아입니다. 가운이 융창할 것입니다』","▲ 임신(姙娠)하였다면 득남(得男)하겠습니다.; 난산(難産)의 위험(危險)은 없습니다.; 출산후(出産後) 가정(家庭)은 날로 융창(隆昌)할 길조(吉兆)입니다.; ▲ 선조(先祖)의 감응(感應)이 있습니다.; 혹 신앙(信仰)이 있다면 태만(怠慢)히 하지 마십시오.; 임계일(壬癸日)에 출산(出産)하게 됩니다.;"],
    [13,"丑","子","을자축(乙子丑)『일이 어렵습니다』","▲ 재물(財物)을 구(求)하여도 뜻대로 되지 않습니다.; 매사(每事) 의심(疑心)이 생겨 진퇴(進退)를 결정(決定)할 수 없습니다.; 재물(財物)을 구(求)하여 재물(財物)로 인해 차질(蹉跌)이 생기는 상(象)입니다.; ▲ 재물(財物)을 구(求)하여도 불가능(不可能)합니다.; 결국(結局) 본분(本分)을 지키고, 기다릴 수 밖에 없습니다.; 인일(寅日)이면 소재(小財)는 얻겠습니다.; 혹 진일(辰日)이라면 만사(萬事) 공허(空虛)로 끝나겠습니다.; 요(要)는 재운(財運)이 없습니다.; 누구도 원망(怨望)하지 말고, 후일(後日)을 기약(期約)하는 방침(方針)을 세우는 것이 현명하겠습니다.; ▲ 자금(資金)이나 차입금(借入金) 모두, 조달(調達)이 불가능(不可能)합니다.; ▲ 대금(貸金)의 회수(回收)도 어렵겠습니다.;"],
    [14,"丑","丑","갑축축(甲丑丑)『좀 늦어지나 반드시 옵니다』","▲ 통신(通信)이나 음신(音信)은 좀 늦어진다.; 그러나 전언(傳言)의 경우(境遇)는 있을 수 있습니다.; 좀 늦어 지더라도 꼭 음신(音信)은 있게 됩니다.; ▲ 문장(文章), 서류(書類), 문화적 용건(文化的 用件)은 좀 늦더라도 꼭 입수(入手)된다.; 점단일(占斷日)이 진술일(辰戌日)이면 많이 연체(延滯)되고 경일(庚日), 신일(申日)이면 음신(音信)이 빨리 올 것입니다.;"],
    [15,"丑","寅","을인축(乙寅丑)『성사되겠습니다. 좋은 배필입니다』","▲ 혼담(婚談)은, 기대(期待)하지 않았던 혼담(婚談)이 성립(成立)되어 결혼후(結婚後) 희경지사(喜慶之事)가 끊이지 않으며, 부부화합(夫婦和合)겠습니다.; ▲ 평지(平地)를 보행(步行)하는 것과 같이 안전(安全)합니다.; 평생쾌락(平生快樂)의 호운(好運)입니다.; ▲ 부부동심(夫婦同心) 부창부수(夫唱婦隨) 화목지상(和睦之象)입니다.; ▲ 망설이지 마십시오.; 약혼(約婚)만 성립(成立)되면 춘풍(春風)이 불어오는 상(象)입니다.;"],
    [16,"丑","卯","병묘축(丙卯丑)『북방은 해롭습니다. 다른 곳은 좋습니다』","▲ 외출(外出), 여행(旅行), 이사(移徒), 모두 동방(東方)은 길(吉).; 남방(南方)은 소성(小成), 서방(西方)은 흉(凶)합니다.; 북방(北方)은 노력(努力)해도 효과(效果)가 없겠습니다.; ▲ 명ㆍ리(名ㆍ利) 모두 양난지상(兩難之象)입니다.; 무슨 일이건 지체(遲滯)될 징조(徵兆)가 보입니다.; ▲ 대인(待人)은 오지 않습니다.; 원방인(遠方人)은 아직 올 예정(豫定)을 못하고 있습니다.; ▲ 대인(待人), 음신(音信)은 혹 동방(東方)이라면 올 수도 있습니다.; 외출(外出)도 또한 동방(東方)이라면 나가도 무방(無妨)하겠습니다.;"],
    [17,"丑","辰","정진축(丁辰丑)『만사 형통합니다』","▲ 귀인(貴人)을 방문(訪問)하면 광풍밀월(光風蜜月)의 정(情)을 갖고 맞을 것이며, 만사(萬事) 성공(成功)하겠습니다.; 후원(後援)을 구(求)하는 일, 타인(他人) 의뢰(依賴)의 건(件), 그밖에 어떠한 목적(目的)이라도 통달(通達)하겠습니다.; ▲ 상하(上下)를 막론(莫論)하고 다 좋으며, 면접(面接)ㆍ담화(談話)를 하는 가운데 좋은 기회(機會)를 얻게 되며, 영리(營利)의 기반(基盤)이 되겠습니다.; ▲ 방문(訪問)하면 면담(面談)이 가능(可能)하며, 방문(訪問) 목적(目的)은 유리(有利)하게 전개(展開)되겠습니다.; ▲ 래객(來客)을 기다리는 경우(境遇)는 빠른 시간(時間)안에 오게 됩니다.;"],
    [18,"丑","巳","무사축(戊巳丑)『공직자나 동네 불량배의 짓이고 동쪽 토(土), 나무 밑을 찾아 보십시오』","▲ 실물(失物)ㆍ도난(盜難) 어느 것이나 타인(他人)의 힘에 의하여 시간(時間)은 오래 걸리겠으나 발견(發見)되겠습니다.; ▲ 공직인(公職人) 또는 인근 도배(隣近 徒輩)의 악의(惡意)에 의해 물건(物件)을 없앨 가능성(可能性)이 있습니다.; 동방(東方)을 검색(檢索)하여 보는 것이 좋을 것입니다.; 타인(他人)의 내통(內通) 혹, 구전(口傳)으로 단서(端緖)를 얻게 될 것입니다.; ▲ 토(土), 목(木)의 아래를 찾아보면 대개(大槪)는 발견(發見)이 되겠습니다.;"],
    [19,"丑","午","기오축(己午丑)『찾지 마십시오. 스스로 오게 됩니다』","▲ 마음에 표리(表裏)가 있어 배은망덕(背恩忘德)하고 도주(逃走)한 것입니다.; 하늘은 돕지 않습니다.; 급히 찾아 보아도 헛 수고(手苦)입니다.; 시간(時間)이 경과(經過)하면 스스로 뉘우치고 돌아옵니다.; 기다리는 방법(方法) 밖에 없습니다.; ▲ 처음은 소식(消息)을 전(傳)하고, 뒤에 스스로 돌아올 것입니다.; 찾아 다니는 것은 노력(努力)만을 허비(虛憊)하게 되니 찾지 마십시오.;"],
    [20,"丑","未","경미축(庚未丑)『어렵다고 포기하지말고 인내하면 곧 궤도에 오르게 됩니다』","▲ 이 괘(卦)를 얻으면 성공(成功)을 추구(追求)해도 성취(成就)되기가 어렵습니다.; 그러나 성의(誠意)와 인내심(忍耐心)을 갖고 도모(圖謀)한다면 지연(遲延)되더라도 목적(目的)을 달성(達成)하게 됩니다.; ▲ 한번 순조(順調)롭게 일이 풀릴 때에는 기쁜 일은 거듭되며, 만사(萬事) 천우신조(天佑神助)를 얻어 귀인(貴人)의 인도(引導)로 원조(援助)를 얻을 길조(吉兆)입니다.; 후원자(後援者)를 얻어 반드시 힘이 되겠습니다.; ▲ 목적(目的)의 달성(達成)은 늦으나 한번 궤도(軌度)에 올라서기만 하면 모든 일이 순조(順調)롭게 되겠습니다.;"],
    [21,"丑","申","신신축(辛申丑)『선수를 치되 삼자의 조정에 응하십시오』","▲ 쟁송(爭訟)은 오래 걸리며 절외생지(絶外生枝)의 상(象)입니다.; 사건(事件)은 여러 갈래로 번져 확대(擴大)될 조짐(兆朕)이 있습니다.; 무슨 일이든 기선(機先)을 제압(制壓)하고 조리정연(條理整然)하게 진행(進行)한다면 이유(理由)는 서고 유리(有利)하게 될 것입니다.; ▲ 3자(三者)의 조정중재(調停仲裁)가 있을 때에는 이에 응(應)하여 해결(解決)하는 것이 가(可)합니다.; 물심양면(物心兩面)의 피해(被害)가 있었으나 금후(今後) 점점 확대(擴大)될 경향(傾向)이므로 화해(和解)하는 것이 결국(結局)은 이기는 것이 되겠습니다.;"],
    [22,"丑","酉","임유축(壬酉丑)『노인은 어렵고 소아는 일어납니다. 조상에 제사하십시오』","▲ 이 괘(卦)를 얻으면 병(病)은 소아(小兒), 또는 노인(老人)의 병(病)입니다.; 소아(小兒)는 쉽게 회복(回復)되겠으나 노인(老人)은 십중팔구(十中八九) 사별(死別)하게 될 우려(憂慮)가 많으므로 양의(良醫)를 찾아 양약(良藥)으로 치료(治療)에 전념(專念)하는 것이 긴요(緊要)합니다.; ▲ 우려(憂慮)의 흑운(黑雲)이 가시지 않았습니다.; 조상(祖上)에 제사(祭祀)하고 모든 일을 지성(至誠)으로 신덕(神德)에 기원(祈願)하며 적선(積善)을 행(行)하면 평안(平安)을 얻겠습니다.;"],
    [23,"丑","戌","계술축(癸戌丑)『계속 이익은 없게 되니 서둘러 마무리 하십시오』","▲ 이 괘(卦)를 얻으면 상매(商賣)ㆍ재운(財運) 모두가 좋습니다.; 경영(經營)이 순조(順調)로우며 모두 뜻한대로 되겠습니다.; 모든 일을 신속(迅速)하게 처리(處理)하는 것이 좋으며, 지체(遲滯)할 경우(境遇) 효과(效果)가 감소(減少)하게 됩니다.; ▲ 모든일은 중인(衆人)의 협조(協調)와 자신(自身)의 활발(活潑)한 노력(努力)으로 이익(利益)은 날로 증가(增加)하게 되겠습니다.; ▲ 가격(價格)이 상승일로 (上昇一路)일 때에는 매매원결(賣買遠決)로 전매이익(轉賣利益)을 보는 것이 좋으며, 상담(商談)은 성립(成立)되나 급(急)히 진행(進行)시키는 것이 상책(上策)이며, 좋은 가격(價格)은 계속(繼續)될 수 없겠습니다.;"],
    [24,"丑","亥","갑해축(甲亥丑)『수재이고 출세할 아이이나 맡겨 길러야 합니다』","▲ 임신중(姙娠中)이며, 남아(男兒)를 출산(出産)합니다.; 그러나 양육(養育)이 어렵지 않을까 두렵습니다.; ▲ 만일 편처(偏妻)라고 하면 아무일 없이 잘 자랍니다.; 불연(不然)이면 타인(他人)에게 의뢰(依賴)하여 양육(養育)하는 것이 좋겠습니다.; 잘 기를 수만 있다면 성장(成長)하여 수재(秀才)로 가문(家門)을 빛낼 것입니다.;"],
    [25,"寅","子","갑자인(甲子寅)『지체는 되겠으나 이익이 큽니다』","▲ 괘상(卦象)은 길(吉)하나, 다소(多少) 지체(遲滯)됩니다.; 그러나 성의(誠意)를 갖고 노력(努力)하면 증식(增植)이 성숙(成熟)하는 것처럼 결실(結實)을 얻게 되겠습니다.; 재리(財利)를 구(求)하면 임차(賃借) 어느쪽이든지 이익(利益)을 얻을 것입니다.; ▲ 다소(多少) 지장(支障)은 있으나 뜻대로 재리(財利)를 얻을 것입니다.; ▲ 자금조달(資金調達)은 처음에는 어려우나 순조(順調)롭게 얻어집니다.; ▲ 자금회수(資金回收)는 일부(一部)만 가능(可能)합니다.;"],
    [26,"寅","丑","을축인(乙丑寅)『지금 소식이 오고 있는 중입니다』","▲ 음신(音信)의 유무(有無)를 단정(斷定)하기가 어려운 상(象)입니다.; 혹 음신(音信)이 있다면 인일(寅日) 또는 묘일(卯日)에 도달(到達)하겠습니다.; ▲ 음신(音信)은 호사(好事)는 적고, 불만(不滿)스러운 음신(音信)이겠습니다.; ▲ 인일(寅日) 또는 묘일(卯日)에 점단(占斷)한다면, 음신(音信)은 근일 중(近日 中)에 도달(到達)합니다.; 즉, 음신(音信)은 오고 있는 중(中)입니다.;"],
    [27,"寅","寅","갑인인(甲寅寅)『순조롭고 결과가 좋습니다』","▲ 혼담(婚談)은 순조(順調)롭게 이루어질 것입니다.; 그러나 재물(財物)의 실비(失費)가 많을 징조(徵兆)가 보입니다.; 이 점(點) 주의(注意)를 요(要)합니다.; ▲ 타인(他人)에게 의뢰(依賴)하더라도 지체(遲滯)하지 말고 성혼(成婚)하는 것이 좋습니다.; ▲ 이 혼담(婚談)은, 성립(成立)된다면 부부화목(夫婦和睦), 부귀(富貴)를 얻게 될 것입니다.;"],
    [28,"寅","卯","을묘인(乙卯寅)『이다 뜻대로 되겠습니다. 기다리는 사람은 午未 일에 옵니다』","▲ 외출(外出), 여행(旅行) 모두 계획소망(計劃所望)이 거의 목적(目的)을 달성(達成)하게 되겠습니다.; 또한 면회(面會)를 구(求)하여도 뜻대로 될것이고, 외출(外出)에서 길보(吉報)를 얻게 됩니다.; 뜻한대로 행(行)하여서 이득(利得)이 있을 것이 틀림없습니다.; ▲ 볼 일이나 관광(觀光), 여행(旅行), 아무런 방해(妨害)없이 도원경(桃源境)을 보게 되겠습니다.; ▲ 대인(待人)은 원근불문(遠近不問)하고 올 것입니다.; 가까운 곳 사람은 오미시(午未時)에, 먼곳에 있는 사람은 오미일(午未日)에 스스로 올 것입니다.; 타인(他人)은 이익(利益)을, 육친(六親)은 희소식(喜消息)을 갖고 올 것입니다.;"],
    [29,"寅","辰","병진인(丙辰寅)『분명한 목적을 세워 방문하십시오. 성과가 큽니다』","▲ 목적(目的)을 세워 방문(訪問)하면 큰 효과(效果)를 거두어 성취(成就)합니다.; 거의 연체(延滯)되는 일 없이 순조(順調)롭게 진행(進行)될 것이나, 때로 하배(下輩)의 방해(妨害)를 받겠습니다.; ▲ 무슨 일이건 결과(結果)는 걱정할 것 없습니다.; 서로 친(親)하는 가운데 복(福)을 획득(獲得)하게 되겠습니다.; ▲ 상위자(上位者)나 존장(尊長)을 방문(訪問)하면 면담(面談)이 가능(可能)하며 유리(有利)하나, 재하인(在下人)의 경우(境遇)는 불리(不利)합니다.; ▲ 래방(來訪)을 요청(要請)하면 약속(約束)을 깨고 오지 않습니다.; 객(客)이 온다해도 이익(利益)은 기대할 수 없습니다.; 혹(或) 저녁 시간(時間) 후(後)라면 유리(有利)하게 전환(轉換)될 수도 있겠습니다.;"],
    [30,"寅","巳","정사인(丁巳寅)『도둑 맞은 것이고, 또 도둑 맞을 것입니다』","▲ 악인(惡人)에 의해 절취(竊取) 당(當)한 것입니다.; ▲ 운명적(運命的)인 손재수(損財數)입니다.; 타인(他人)의 발설(發說), 혹은 대화(對話)에서 단서(端緖)를 얻게될 가능성(可能性)이 있습니다.; ▲ 또한 파재(破財), 실물(失物), 도난(盜難)등을 겹쳐서 당(當)할 징조(徵兆)가 있으므로 신중(愼重)한 경계(警戒)를 요(要)합니다.;"],
    [31,"寅","午","무오인(戊午寅)『묶여 있는 상태입니다. 다른 방법이 없습니다』","▲ 실종(失踪), 도망자(逃亡者)는 숨어 있을 곳이 없어 동분서주(東奔西走), 심신(心身)이 극도(極度)로 유췌(惟悴)합니다.; 후회(後悔)하는 정(情)이 있어 돌아오고 싶으나, 사람에 의해 저지당(沮止當)하고 있으므로 자유행동(自由行動)이 어려운 것입니다.; ▲ 극도(極度)로 궁(窮)한 나머지 자멸행위(自滅行爲)를 할 징조(徵兆)가 있으나, 빨리 뉘우친다면 스스로 돌아올 수도 있겠습니다.; 기다리는 이외(以外)의 다른 방법(方法)은 없겠습니다.;"],
    [32,"寅","未","기미인(己未寅)『마음을 다시 가다듬으십시오. 좋게 변합니다』","▲ 계획(計劃)하는 일은 조석(朝夕)으로 정신(精神)만 허비(虛憊)하게 됩니다.; 낙화유수(落花流水)와 같이 전도(前途)는 근심스러운 상태(狀態)이나, 돌연(突然)히 변화(變化)하여 화길(化吉)하게 됩니다.; 즉, 비관(悲觀)이 변(變)하여 환희(歡喜)가 된다는 것입니다.; 그러므로 심기일전(心機一轉) 추진(推進)해 나가는 것이 좋겠습니다.; ▲ 끈기있게 의지(意志)를 굳게 갖고, 목적(目的) 달성(達成)을 위해 노력(努力)한다면 좀 늦는 한이 있더라도 꼭 성취(成就)를 보게 될것입니다.;"],
    [33,"寅","申","경신인(庚申寅)『걱정할 것 없습니다. 화해 분위기가 있으면 응하십시오』","▲ 관사(官事)나 쟁송(爭訟)은 중대(重大)한 것이 아니며, 작은 일이므로 정력(精力)을 소모(消耗)하는 다툼으로 까지는 이르지 않을 것입니다.; ▲ 경우(境遇)에 따라서는 조정화해(調停和解)의 의사(意思)가 있을 때에는 나가서 응(應)하는 것이 옳을 것입니다.; ▲ 파재(破財), 출비(出費), 능욕(凌辱)을 당(當)할 흉조(凶兆)가 있기는 하나, 구름은 걷히고 명월(明月)이 천하(天下)를 비추는 상(象)이므로 근심을 할 일은 없겠습니다.;"],
    [34,"寅","酉","신유인(辛酉寅)『동방 토(土)신에 기도하고 북두칠성에 기원하면 병은 낫습니다』","▲ 이 괘(卦)는 여성(女性)의 질환(疾患)입니다.; 동방(東方)의 동토(動土), 즉 토신(土神)을 범(犯)하여 타병(他病)을 병발(倂發)할까 두렵습니다.; 그러므로 병세(病勢)는 일시악화(一時惡化)되어 가인(家人)들을 놀라게 할 것입니다.; 그러나 크게 걱정할 필요(必要)는 없습니다.; 동방(東方)의 토신(土神)에게 제(祭)를 올리고 감사(感謝)하며, 그밖에 성진(星辰) 북두칠성(北斗七星)에게 기원(祈願)하면 병(病)은 평정(平靜)될 것이며 가정(家庭)은 예전처럼 명랑청신(明朗淸新)하게 될것입니다.; ▲ 대소(大小)를 불문(不問)하고 실비(失費)가 적지 않을 것입니다.;"],
    [35,"寅","戌","임술인(壬戌寅)『협력심만 가지면 모든 일이 형통합니다』","▲ 이 괘(卦)을 얻으면 상업(商業)으로 득재(得財)할 가장 좋은 운세(運勢)입니다.; 모든 일에는 순리(順利)가 있는 법(法), 동심협력(同心協力)을 하면 재(財)의 소망(所望)을 달성(達成)합니다.; ▲ 매매(賣買)는 모두 진행(進行)하여도 좋습니다.; 기쁜일이 거듭되겠습니다.; ▲ 상담(商談)할 때 마음 내키는 대로 매매(賣買)하여도 좋습니다.; 재고상품(在庫商品)은 원매인(願買人)이 있을 것이며, 물품매입(物品買入)을 하려고 하면 뜻대로 되겠습니다.;"],
    [36,"寅","亥","계해인(癸亥寅)『남아입니다. 큰 인물이 될 아이입니다』","▲ 이 괘상(卦象)은 남아(男兒)를 임신(姙娠)하게 됩니다.; 형산(荊山) 의 옥(玉)을 얻으며 명월(明月)이 중천(中天)에 빛나는 상(象)입니다.; 성육 후(成育 後) 수재(秀才)로 대기(大器)가 되겠습니다.; ▲ 출산(出産)의 기쁨은 신일(申日), 자일(子日), 진일(辰日)에 있을 것입니다.;"],
    [37,"卯","子","계자묘(癸子卯)『마음대로 진행해도 실패가 없는 좋은 운입니다』","▲ 이 괘(卦)을 얻으면 귀인(貴人)의 후원(後援), 또는 형제(兄弟), 붕우(朋友), 동지(同志)의 협력(協力)에 의하여 재리(財利)를 얻게 되겠습니다.; ▲ 업무상(業務上) 금전대차(金錢貸借) 출자(出資) 등은 거의 깊이 고려(考慮)하지 않고 진행(進行)하여도 길(吉)하게 됩니다.; ▲ 자금(資金)ㆍ금융(金融)은 중개자(仲介者)를 통하므로서 성립(成立)하며, 대금회수(貸金回收)도 사용인(使用人)에게 대행청구(代行請求)를 시키면 후에 회수(回收)되겠습니다.;"],
    [38,"卯","丑","갑축묘(甲丑卯)『곧 소식이 옵니다. 조금만 기다리십시오』","▲ 먼 곳에서 오는 음신(音信)은 좀 늦어지나 조만간(早晩間) 음신(音信)을 받게 됩니다.; 가까운 곳 음신(音信)은 좋은 소식(消息)을 얻고, 일순중(一旬中)의 십일 간(十日 間)에 올 것입니다.; 혹 인일(寅日), 진일(辰日)이면 좋은 소식(消息)이 집으로 오겠습니다.; ▲ 계획(計劃)하는 일, 희망(希望)하는 일의 소식(消息)은 일순 중(一旬 中)에 호음신(好音信)이 있을 것이니, 조용히 기대(期待)하고 있는 것이 좋습니다.;"],
    [39,"卯","寅","을인묘(乙寅卯)『진행하여 성과가 있겠습니다. 아주 좋습니다』","▲ 결혼(結婚)이나 약혼(約婚)은 진전(進展)시키는 것이 좋습니다.; 양가(兩家) 모두 의기상통(意氣相通), 화기애애(和氣靄靄)하며 서로 기뻐하는 상(象)입니다.; ▲ 홍도(紅桃) 백이(白李)와 같이 생기(生氣)가 넘치며, 주위(周圍)의 협조(協助)가 있으므로 성립(成立)은 의심(疑心)할바 있습니다.; ▲ 결혼후(結婚後) 백년해로(百年偕老)는 물론, 양가(兩家) 모두 대길(大吉)하며, 만사(萬事) 안심(安心)하고 진행(進行)시켜도 좋겠습니다.;"],
    [40,"卯","卯","갑묘묘(甲卯卯)『순풍에 돛단배 격입니다』","▲ 형제(兄弟) 붕우(朋友) 동업자(同業者), 동지(同志) 모두가 의지소통(意志疏通) 협력(協力)을 얻을 수 있는 희망(希望)이 있습니다.; ▲ 순풍행주(順風行舟)와 같이 외출(外出) 여행(旅行) 등에 거의 목적달성(目的達成), 만사이득(萬事利得)이 될 것입니다.; 출행(出行)에 있어 도중재난(途中災難)이나 꺼리끼는 일 없이 목적지(目的地)에 도착(到着), 환대(歡待)를 받게 될 것입니다.; ▲ 대인(待人)ㆍ음신(音信)은 마음속에 원(願)할 때에는 상응(相應)하여 오게 됩니다.; ▲ 방문(訪問)ㆍ회담(會談)ㆍ구재(求財)ㆍ상매(商賣) 등은 거의 길조(吉兆)라고 할 수 있습니다.;"],
    [41,"卯","辰","을진묘(乙辰卯)『원하는 것은 무엇이든지 잘 됩니다』","▲ 방문(訪問)하면 상화하목(上和下睦)의 기쁨을 얻겠습니다.; ▲ 무슨 일이건 호운(好運)이므로 추진(推進)하는 것이 좋습니다.; 다소(多少) 지연(遲延)될 수도 있으나, 반드시 이익(利益)이 있겠습니다.; 신일(申日), 유일(酉日), 혹 유시(酉時)에 방문(訪問)하면 뜻을 이루게 되겠습니다.; ▲ 방문(訪問)하면 면회(面會)가 가능(可能)합니다.; 대인(待人)은 오전중(午前中)은 좀 늦을 것입니다.;"],
    [42,"卯","巳","병사묘(丙巳卯)『보관 잘못으로 잃었습니다. 래방자의 소행이고 급히 서쪽을 탐색하십시오』","▲ 실물(失物)은 자신(自身)의 보관실책(保管失策)으로 잃은 것입니다.; 타인(他人)의 래방자(來訪者)에 의(依)한 소행(所行)임을 알아차리고 임방(壬方)북방(北方), 또는 서방(西方)을 찾아보면 발견(發見)할 수 있을 것입니다.; ▲ 혹(或) 도난(盜難)일 경우(境遇), 급(急)히 서방(西方)으로 탐색(探索)하고 빨리 손쓰면 찾을 수도 있습니다.; 그러나 늦을 경우(境遇)는 은닉(隱匿)되어 발견(發見)이 어렵게 됩니다.;"],
    [43,"卯","午","정오묘(丁午卯)『멀리 가려고 하나 가까운 곳에 있습니다』","▲ 가인(家人)의 실종(失踪)을 감정(鑑定)해보면 이는 정신착란(精神錯亂), 또는 오해(誤解) 때문에 타향(他鄕)으로 달아난 것입니다.; 마음은 원방(遠方)으로 갈 의지(意志)가 있으나 몸은 가까운 곳에 있습니다.; ▲ 사람이 와서 알려줄 것입니다.; 임일(壬日), 계일(癸日)에 가서야 발견(發見)할 수 있겠습니다.; ▲ 가출(家出)하여 시일(時日)에 오래 경과(經過)한 때에는 병환(病患)으로 고생(苦生)하고 있겠습니다.;"],
    [44,"卯","未","무미묘(戊未卯)『처음에는 나쁘나 뒤에는 좋게 되겠습니다』","▲ 계획(計劃)하는 일은 선흉 후길(先凶 後吉)의 상(象)입니다.; 만사(萬事) 처음에는 운세(運勢)가 트이지 않아 성과(成果)가 적으나 전도(前途)가 불안(不安)하더라도 심지(心志)를 굳게 하고 전진(前進)한다면 혹 좌절(挫折)되는 일이 있다 할지라도 마침내는 성취(成就)할 수 있게 되겠습니다.; 혹 진일(辰日)에 지정(指定)한 경우(境遇)는 중도(中途)에 근심할 일은 없으나 단, 목적(目的)은 늦게 성공(成功)하게 됩니다.; ▲ 처음은 좀 곤란(困難)에 봉착(逢着)하겠으나, 뒤에는 춘풍(春風)을 만난 것처럼 목적(目的)이 달성(達成)되겠습니다.;"],
    [45,"卯","申","기신묘(己申卯)『아랫 사람이 조종을 받아 일으킨 일이나 무시하지말고 인간적으로 해결 되겠습니다』","▲ 공소(公訴), 관사(官事), 쟁송(爭訟), 모두가 사용인(使用人) 또는 수하인(數下人), 혹 여인(女人)의 도발적(挑發的)인 사건(事件)으로서 다소(多少)의 파란(波瀾)과 기복(起伏)이 생기겠으나 결국(結局)은 확대(擴大)되지 않고, 종국(終局)을 보게 될 것입니다.; ▲ 상대(相對)를 경시(輕視)하지 말고, 인간적(人間的)으로 해결(解決)한다는 방침(方針)으로 나간다면 이유 여하(理由 如何)를 불문(不問)하고 반드시 타개(打開)되겠습니다.; ▲ 수하인(數下人), 사용인(使用人), 혹 여인(女人)에 의한 사건(事件)이 아니라도 이는 이들이 배후(背後)에서 조종(操縱)하는 즉, 강박적(强迫的)인 의사(意思)가 존재(存在)하고 있는 것입니다.;"],
    [46,"卯","酉","경유묘(庚酉卯)『가택신을 진압하는 것이 급선무이고 침으로 고쳐야 하겠습니다』","▲ 이 괘(卦)를 얻으면 병증(病症)은 동방(東方)의 사귀(邪鬼)를 침범(侵犯)한 까닭에 병(病)은 항진증상(亢進症狀)을 보이고 있으므로 즉시(卽時) 가내진호(家內鎭護)의 수법(修法)을 사용하십시오.; ▲ 인일(寅日) 또는 신일(申日)에 병(病)은 물러갈 것입니다.; 치료(治療)는 침구(鍼灸)로서 치료(治療)하는 것이 좋을 것입니다.;"],
    [47,"卯","戌","신술묘(辛戌卯)『기회를 잘 보아야 하고 대량적인 일은 불가합니다』","▲ 매매(賣買) 무역등(貿易等) 모두가 수변인(水邊人), 혹 심씨(心氏), 지씨등(池氏等)과 거래(去來)를 개시(開始)하게 됨으로서 이익(利益)을 얻게 됩니다.; 작은 일이라도 방심태만(放心怠慢)함이 없이 경영(經營)하는 것이 긴요(緊要)합니다.; ▲ 신규사업(新規事業)을 착수(着手)코져 할 때에는 천시지리(天時地利)를 성찰(省察)하고 계획(計劃)하여야 하며 경솔(輕率)하게 착수(着手)한다면 오랜 시간(時間) 비용손재(費用損財)를 가중(加重)하게 될것입니다.; ▲ 매매(賣買)ㆍ거래(去來) 모두 아직은 시기(時期)가 아닙니다.; 현재시세(現在市勢)는 머지않아 보합시세(保合時勢)가 될것입니다.; 상담(商談)은 성립(成立)되나, 대량거래(大量去來)는 불가능(不可能)합니다.; 매입(買入)하려고 할 때에는 가까운 시일(時日)에 기회(機會)를 얻게 될것입니다.;"],
    [48,"卯","亥","임해묘(壬亥卯)『큰 길상입니다. 참 좋겠습니다』","▲ 이 괘(卦)을 얻으면 하사(何事)를 불문(不問)하고 대 길조(大 吉兆)가 있을 상(象)입니다.; 임신(姙娠)하면 귀자(貴子)를 얻고, 모자(母子) 모두 안강(安康)하게 됩니다.; 비상(非常)한 기쁨이 있을 것이며, 빈부(貧富)를 막론(莫論)하고 전도개운(前途開運)하고 출세(出世)함은 물론(勿論) 가운 영창(家運 榮昌) 효심돈독(孝心敦篤)한 현자(賢者)를 얻겠습니다.; ▲ 늘 천우신조(天佑神助)를 얻고 있으므로 가족(家族) 모두가 신앙심(信仰心)이 돈독(敦篤)한 것이 소망(所望)스럽습니다.;"],
    [49,"辰","子","임자진(壬子辰)『친구에 의해 재복이 있게 생겼습니다. 친구를 잃지 마십시오』","▲ 타인(他人)의 도움을 받아 재(財)를 구(求)하는 것이 좋겠습니다.; 하월(夏月)에는 지우(知友) 지기(知己)에 의하여 재원(財源)이 발복(發福)하게 됩니다.; 지우(知友)의 도움없이는 구재취리(求財取利)는 어려우며 모두 손비(損費)만 되겠습니다.; ▲ 제사(諸事) 침체(沈滯)하며 아직은 시기(時期)가 아닙니다, 아무리 활약(活躍)하여도 달성(達成)이 어렵습니다.; 잘 고려(考慮)하여 퇴보(退步)의 방침(方枕)을 세우는 것이 현명(賢明)합니다.; ▲ 남방(南方)에서 희소식(喜消息), 또는 기우적(奇遇的)인 소식(消息)이 와서 재원(財源)이 조성(造成)될 수도 있겠습니다.; ▲ 임금(賃金)은 남방(南方) 또는 동방(東方)일 경우(境遇), 회수(回收)가 가능(可能)합니다.;"],
    [50,"辰","丑","계축진(癸丑辰)『포기 하십시오. 기력이 없습니다』","▲ 음신(音信)을 기다려도 상대방(相對方)이 연체(延滯)하고 있기 때문에 래신(來信)의 희망(希望)은 없습니다.; 기다려 봐도 큰 희망(希望)은 없겠습니다.; ▲ 태양(太陽)은 서산(西山)에 기울고 심사(心思)는 처량(凄凉)합니다.; 만사(萬事) 체념(諦念)하는 것이 중요(重要)합니다.;"],
    [51,"辰","寅","갑인진(甲寅辰)『다른 곳을 골라도 되겠고 성사되면 아주 잘 살겠습니다』","▲ 빨리 대화(對話)를 진행(進行)시키면 혼담(婚談)은 꼭 성립(成立)합니다.; 만일(萬一) 성립(成立)되지 않을 때에는 빨리 다른 곳에서 구(求)하는 것이 양책(良策)입니다.; 늦을 때에는 반복(反復)해도 되지 않습니다.; ▲ 결혼(結婚)이 성립(成立)되면 가문(家門)이 번영(繁榮)하고, 부부화합(夫婦和合)합니다.; ▲ 중매인(中媒人)이 선량(善良)하면 만사 신뢰(萬事 信賴)해도 좋으니, 의심(疑心)이 생기면 반복(反復)해도 성립(成立)은 어렵겠습니다.;"],
    [52,"辰","卯","을묘진(乙卯辰)『크게 생각지 마십시오』","▲ 여행(旅行)의 목적(目的)이 소사(小事)는 성취(成就)되나, 큰 소망(所望)이라면 성취(成就)할 가능성(可能性)이 희박(稀薄)합니다.; 그저 당시(當時)의 명운(命運)에 순응(順應)하지 않으면 않됩니다.; ▲ 무슨 일이나 망서림이 따르게 됩니다.; 양자택일(兩者擇一)의 분기점(分岐點)에 이르게 된다는 것입니다.; 또한 과거사(過去事) 혹은 재기재흥(再起再興)의 징조(徵兆)가 보입니다.; ▲ 대인(待人)은 오게 됩니다.; 이득(利得)을 갖고 옵니다.; 혹 물품(物品)의 증정(贈呈)을 만나는 기쁨이 있겠습니다.; ▲ 면회(面會)를 구(求)할 경우 목적(目的)은 소성(小成)합니다.; 방화일지(芳花一枝)를 얻는 상(象)입니다.;"],
    [53,"辰","辰","갑진진(甲辰辰)『곡절이 있겠으나 결국 뜻대로 되겠습니다』","▲ 방문(訪問)하여도 심적(心的)으로 피로(疲勞)하고 심신(心身)이 바쁩니다.; 그러므로 목적달성(目的達成)은 만사(萬事) 우여곡절(迂餘曲折)이 있으나 종당(終當)은 성사(成事)됩니다.; 피아(彼我) 모두 사애(思愛)의 정(情)을 잃치 않고 있기 때문입니다.; 혹 인일(寅日)이면 쉽게 면담(面談)할 수 있으며, 서로 만족(滿足)한 의사교환(意思交換)이 가능(可能)합니다.; 무슨 일이건 지성(至誠)을 갖고 추진(推進)한다면 늦더라도 성사(成事)되겠습니다.; 성급(性急)하게 서두르지 말라.; 동방(東方) 혹은 인일(寅日)에 만족(滿足)하게 목적(目的)이 달성(達成)됩니다.; ▲ 재가(在家)하여 면담(面談)하여도 목적(目的)은 달성(達成)됩니다.; ▲ 기다리는 사람은 올 것입니다.; 그러나 야간(夜間)이면 도중(途中)에서 돌아갑니다.;"],
    [54,"辰","巳","을사진(乙巳辰)『남쪽에 있으나 찾기가 어렵겠습니다』","▲ 실물(失物) 도난(盜難) 어느 것이나 찾는다는 것은 거의 어렵습니다.; 물을 엎은 것과 같아서 다시 돌아오기는 어렵습니다.; ▲ 도난(盜難), 분실(紛失) 어느 것이나 남방(南方)에서 찾을 희망(希望)이 있으나, 완전(完全)할 수 없다는 것이 유감(遺憾)입니다.;"],
    [55,"辰","午","병오진(丙午辰)『가정불화로 타인과 같이 나갔는데. 자(子), 또는 묘(卯)방에 숨었습니다』","▲ 도주자(逃走者)는 타인(他人)과 함께 동반도주(同伴逃走)한 것입니다.; 부평초(浮萍草)와 같이 떠돌아 다니고 있습니다.; 그러나 자방(子方) 또는 묘방(卯方)에 숨어 있습니다.; ▲ 혹(或), 진술방(辰戌方)을 돌아보면 소식(消息)을 알 수 있고, 불연(不然)이면 음신(音信)이 올것입니다.; 그리고 뒤에 돌아오게 됩니다.; ▲ 가정불화(家庭不和)가 원인(原因)입니다.; 도주(逃走)한 후(後), 몸을 의지(依持)할 곳이 없는 상(象)입니다.;"],
    [56,"辰","未","정미진(丁未辰)『엉뚱한 계획이나 나중에 큰 이익이 되겠습니다』","▲ 계획(計劃)이나 도모(圖謀)하는 일이 선곤후태(先困後泰)의 상(象)입니다.; 한사람의 힘으로는 성취(成就)하기가 어렵고, 공동협력자(共同協力者), 또는 후원자(後援者)를 얻으면 길(吉)하겠습니다.; ▲ 처음에는 망상(妄想)에 가까운 일이, 두뇌(頭腦)와 화학적(化學的) 연구(硏究)에 의해 뒤에는 명리(名利)를 달성(達成)하고 크게 이익(利益)을 얻겠습니다.; ▲ 처음에는 목적(目的)을 달성(達成)하기가 어려울 것 같으나, 뒤에는 형세(形勢)가 변화(變化)하기 때문에 끈기있게 노력(努力)하는 것이 중요(重要)합니다.;"],
    [57,"辰","申","무신진(戊申辰)『내가 불리하니 인내 하십시오. 상황이 유리해 집니다』","▲ 이 괘(卦)를 얻으면 상대(相對)에게는 유리(有利)하고, 나에게는 불리(不利)하게 되는 상(象)입니다.; 무슨 일이건 인내(忍耐)와 지구전(持久戰)으로 임(臨)한다면 유리(有利)한 방향(方向)으로 전환(轉換)되겠습니다.; ▲ 모든 일이 지체(遲滯)되는 것은 피(避)할 수 없습니다.; 힘써 화해(和解)하는 방향(方向)으로 방침(方針)을 세우는 것이 현명(賢明)합니다.;"],
    [58,"辰","酉","기유진(己酉辰)『반드시 쾌유되겠으니 간호를 잘 하십시오』","▲ 병상(病狀)은 가벼우나, 우희(憂喜)가 공존(共存)하는 상(象)입니다.; 오월(午月) 또는 오일(午日)에 이르러 점차(漸次) 치료(治療)되는 병세(病勢)로 호전(好轉), 촌퇴척진(寸退尺進)의 보조(步調)로 쾌유(快癒)되겠습니다.; ▲ 정신(精神) 차려 간병(看病)하고 정양(靜養)하는 것이 긴요(緊要)합니다.; 병상(病狀)은 길어 지겠으나, 꼭 치료(治療)됩니다.;"],
    [59,"辰","戌","경술진(庚戌辰)『서둘러야 합니다. 영속성이 없습니다』","▲ 매매(賣買) 교역사업(交易事業)은 거의 진행(進行)함으로서 만사(萬事) 성과(成果)를 올리게되겠습니다.; 그러나 늦어질 때는 이익(利益)은 없겠습니다.; 무슨 일이든 빠를수록 좋으며 태만(怠慢)함을 경계(警戒)해야 합니다.; 동방(東方)의 거래인(去來人)은 점점(漸漸) 양호(良好)하게 되겠습니다.; ▲ 수하인(手下人), 또는 사용인(使用人)의 실패(失敗)가 있을 것이나, 그것은 소사(小事)에 불과(不過)합니다.; 상거래(商去來)는 양호(良好)할 것이나 방심(放心)은 금물(禁物)이며 모든 일에 신중(愼重)을 기(期)하지 않으면 안됩니다.; ▲ 가격전망(價格展望)은 상승(上昇)할 것이 예상(豫想)되나 영속성(永續性)은 없습니다.; 기회(機會)를 기다리는 것이 좋으며 재고품(在庫品)은 원매인(願買人)이 있으니 속(速)히 결정(決定)하지 않으면 위약(違約)이 초래(招來)될 것입니다.;"],
    [60,"辰","亥","신해진(辛亥辰)『아직 익지 않았습니다. 신명께 기도 하십시오』","▲ 이 괘(卦)를 얻으면 무성(茂盛)하나 출수(出穗)가 되지 않고 과실(果實)이 무거워 가지(枝)를 상(傷)하는 형상(形象)입니다.; 우고(憂苦)는 아직 소산(消散)되지 않았으며 늘 근심걱정을 하게 됩니다.; ▲ 천지신명(天地神明)에게 평안(平安)을 기원(祈願)하삽시오.; 그리하면 비로소 길응(吉應)하여 현자(賢子)를 얻게 될것입니다.;"],
    [61,"巳","子","신자사(辛子巳)『뜻에 맞지 않습니다. 작은 것을 착실하게 가꾸십시오』","▲ 재리(財利)를 얻기 위해 고노간난(苦勞艱難)을 다해도 결과(結果)는 얻지 못할 것입니다.; 천리(天理)에 순응(順應)하면 소재(小財)는 수중(手中)에 들어옵니다.; 그러나 재(財)를 얻는 것으로 인(因)하여 구해(仇害)가 일어나기 쉬운 운세(運勢)입니다.; 혹(或)은 타인(他人)의 질시(疾視)를 받게 될것입니다.; ▲ 어느 일이든 재리(財利)를 추구(追求)하면 홀연(忽然)히 근심이 생이게 될 것이므로 이러한 때에는 조용히 있는 것이 좋습니다.; ▲ 자금(資金)과 차입금(借入金)은 소성(小成)은 가능(可能)하나 크게 성취(成就)는 되지 않습니다.; 임금회수(賃金回收) 역시(亦是) 뜻한대로 되지 않겠습니다.;"],
    [62,"巳","丑","임축사(壬丑巳)『기다리지 마십시오. 오지 않겠습니다』","▲ 이 괘(卦)를 얻으면 음신(音信)은 오지 않습니다.; 온다 하여도 늦기 때문에 무슨 일이든 의심(疑心)이 생깁니다.; 그러나 시기(時期)가 늦어지면 먼저 소식(消息)을 전(傳)하고 뒤에 래방(來訪), 또는 귀가(歸家)한 다는 음신(音信)이 있겠습니다.; ▲ 십중 팔구(十中 八九)는 음신(音信)이 올 시기(時期)가 아닙니다.; 기다리지 않음이 좋겠습니다.;"],
    [63,"巳","寅","계인사(癸寅巳)『인연이 아닌 바에야 미련둘 이유가 없고 해로가 안되는데 맺을 필요가 없습니다』","▲ 혼담(婚談)은 방해(妨害)가 있어 성립(成立)이 어려우나 혹, 귀인(貴人)이 중매(中媒)하는 경우는 성립(成立)되겠습니다.; 그러나 결혼 후(結婚 後) 서로 정(情)이 융화(融和)되지 않아 애정(愛情)을 상(傷)하게 될 징조(徵兆)가 있습니다.; ▲ 앙천탄식(仰天歎息), 부지통곡(俯地慟哭), 눈물을 흘리는 상(象)입니다.; 귀인(貴人)의 후원(後援)과 교훈(敎訓)을 얻게 되면 부부(夫婦)가 성심성의(誠心誠意)를 다할 때 비로서 춘풍(春風)의 화기(和氣)처럼 和合을 이루게 되겠습니다.;"],
    [64,"巳","卯","갑묘사(甲卯巳)『무엇이든 다 좋게 되겠습니다』","▲ 원근(遠近) 어느쪽이나 여행외출(旅行外出)이 양호(良好)하고, 여행중(旅行中) 유정(有情)한 사람을 만나 친(親)하게 됩니다.; ▲ 신사(新事), 구사(舊事) 모두 목적완미(目的完微)되며, 대인(待人) 원신(遠信)도, 언젠가는 도래(到來)합니다.; ▲ 여행(旅行), 출행(出行), 대인(待人), 음신(音信) 모두가 뜻한대로 됩니다.; 대인(待人)은 오게 될 것이고, 모든 일이 구사(舊事)는 신사(新事)로 바뀌게 됩니다.; ▲ 명암(明暗), 즉 선악(善惡)이 불원간(不遠間) 희사(喜事)로 화(化)합니다.; 구사재기(舊事再起) 재회(再會) 있어도 일로평안(一路平安)하겠습니다.;"],
    [65,"巳","辰","을진사(乙辰巳)『계획을 고치십시오. 되어지는 일이 없겠습니다』","▲ 방문(訪問)ㆍ배알(拜謁), 모두가 헛 수고(手苦)가 되겠습니다.; 더욱 후회(後悔)만 남게 되며, 무슨 일이건 용두사미(龍頭蛇尾), 시작(始作)은 있으나 결과(結果)가 없습니다.; ▲ 타인(他人)에게 구(求)하는 것 보다, 자신(自身)에게 구(求)하는 것이 현명(賢明)하겠습니다.; 결심(決心)과 계획(計劃)을 고치고 시기(時期)를 기다리는 것이 최선(最善)의 방책(方策)이 됩니다.; ▲ 방문(訪問)하여도 부재중(不在中)이므로 목적(目的)은 달성(達成)하기가 어렵겠습니다.; ▲ 래객(來客)은 있으나, 이득(利得)이 없다.; 도로한담(徒勞閑談)만 하게 됩니다.;"],
    [66,"巳","巳","갑사사(甲巳巳)『내부자 소행입니다. 찾게 되겠습니다』","▲ 실물(失物)이나 도난(盜難)은 거의 집안사람.; 종사원(從事員), 또는 내용(內容)을 잘 아는 자(者)의 소행(所行)입니다.; 빨리 손을 쓴다면 반정도(半程度)는 찾을 수 있게 됩니다.; 만약 지체(遲滯)된다면 하나도 찾을 가망(可望)이 없습니다.; ▲ 결국은 서북방(西北方) 술해방(戌亥方)에서 반분(半分)은 찾게 됩니다.; 혹 사취당(詐取當)하였을 때도 또한 같습니다.;"],
    [67,"巳","午","을오사(乙午巳)『찾기 어렵습니다. 사람의 꼬임에 빠졌습니다』","▲ 실종인(失踪人)은 중간인(中間人)이 농락 때문에 가출(家出)한 것입니다.; 즉 선동(煽動)에 편승(便乘)하여 남방(南方)으로 달아난 것입니다.; 물품(物品), 재물(財物)을 갖고 나갔을 경우(境遇)는 백사장(白沙場)에 묻어놓은 것처럼 찾기가 어렵습니다.; ▲ 오미일(午未日)에 찾아보는 것이 좋겠습니다.; 가출(家出)한 까닭은 가족(家族)과의 이견(異見) 또는 타인(他人)의 꼬임에 의한 것입니다.;"],
    [68,"巳","未","병미사(丙未巳)『아주 잘 되겠습니다. 순조롭습니다』","▲ 소망(所望)과 계획(計劃)하는 일은 크게 형통(亨通)합니다.; 아무런 장애(障碍)없이 순조(順調)롭게 진전(進展)되겠습니다.; 때로 음인(陰人)에 의(依)한 방해(妨害)가 있을 징후(徵候)가 있으나, 대단한 일은 아닙니다.; ▲ 바다에 낚시를 던져 진어(珍魚)를 낚아 올리듯 순리(順利)를 제회(際會)하게 되겠습니다.; 목적(目的)은 완수(完遂)되며, 순조(順調)롭게 이득(利得)을 얻겠습니다.;"],
    [69,"巳","申","정신사(丁申巳)『원한 때문에 일어난 일입니다. 무겁습니다』","▲ 관재(官災), 소송사건(訴訟事件)은 거의 원한(怨恨) 때문에 일어난 것입니다.; 더욱 이 사건(事件)은 타인(他人)의 고자질, 모략(謀略)에 의한 것이므로 점점 근심과 화환(禍患)은 무거워 지는 상(象)입니다.; 그러므로 손재(損財)와 정신적(精神的) 손상(損傷)이 많게 됩니다.; ▲ 내(內), 외(外) 주위(周圍) 사람들 과의 불화합(不和合) 때문에 번민(煩悶)하게 되겠습니다.; ▲ 무일(戊日)이나 유일(酉日)에 해결(解決)의 서광(曙光)이 보이니 이때를 놓치지 마십시오.;"],
    [70,"巳","酉","무유사(戊酉巳)가택신에 기도하고 북쪽에서 약을 쓰십시오』","▲ 이 괘(卦)를 얻으면 병증(病症)의 경과(經過)는 불안(不安)한 상태(狀態)를 가중(加重)하게 될 것입니다.; 여인(女人) 또는 사용인(使用人)의 신변(身邊)에 병환(病患)이 붙어있는 상(象)입니다.; ▲ 자일(子日), 축일(丑日)부터 병상(病狀)은 조금씩 차도(差度)가 보이겠습니다.; 가신(家神)에 기원(祈願)하고, 북방(北方)의 의약(醫藥)을 쓴다면 병세(病勢)는 점차 호전(好轉)되게 됩니다.;"],
    [71,"巳","戌","기술사(己戌巳)『이익이 있습니다. 잘해 보십시오』","▲ 교역(交易)ㆍ상품매매(商品賣買) 등 모두 상당(相當)한 이윤(利潤)을 얻을 수 있을 것입니다.; ▲ 경선순수(輕船順水)의 상(象)입니다.; 상기(商機)를 얻어 순조(順調)롭게 진행(進行)됩니다.; 예컨대, 비는 그치고 하늘은 쾌청(快晴)한 것처럼 가는 곳에 이익(利益)이 따릅니다.; 적은 방해(妨害)는 있으나 신경(神經)을 쓸 일이 아니며, 자일(子日), 축일(丑日)에는 점점 이익(利益)을 보게 될 것입니다.; ▲ 보합시세(保合時勢)이나, 전도(前途)는 상승(上昇)할 것이므로 매입(買入)하는 방침(方針)으로 진행(進行)하는 것이 좋습니다.; 상담(商談)은 순조(順調)롭게 될 것입니다.; ▲ 상품(商品)은 원매자(願買者)가 있으나, 급하게는 되지 않습니다.;"],
    [72,"巳","亥","경해사(庚亥巳)『남아를 낳겠고, 기도를 잘 하십시오. 움직이는 것은 해롭습니다』","▲ 이 괘(卦)를 얻으면 남아(男兒)를 임신(姙娠)하며 귀자(貴子)를 출산(出山)하게 됩니다.; 평생(平生) 건강(健康)에 방심(放心)한 까닭에 분만(分娩)에 어려움이 예상(豫想)됨으로 늘 태교(胎敎)를 중(重)하게 생각하고 순산(順産)을 기원(祈願)하는 것이 좋겠습니다.; ▲ 임신중(姙娠中), 이동(移動) 혹(或)은 경거망동(輕擧妄動)의 징조(徵兆)가 있으니 매사(每事) 신중(愼重)을 기(期)하지 않으면 안됩니다.;"],
    [73,"午","子","경자오(庚子午)『크게 계획하지 마십시오. 성의가 필요합니다』","▲ 재화(財貨)를 구(求)하는 일은 타인(他人) 또는 타동적(他動的)인 힘에 의존(依存)하는 것이 좋으며 계획(計劃)보다 적게 성취(成就)되더라도 감수(甘受)하는 것이 좋습니다.; ▲ 모든 일이 타인(他人)에 미혹(迷惑)되는 상(象)이므로, 이러한 때 에는 남가일몽(南柯一夢)이 되기 쉽습니다.; ▲ 무슨 일이고 선난(先難)이 있으므로 성의(誠意)를 갖고 임(臨)한다면 목적(目的)한 바는 뜻대로 되겠습니다.; ▲ 자금(資金), 금융(金融) 모두 순조(順調)롭지 못합니다.; 대금(貸金) 회수(回收)도 불가능(不可能)하게 됩니다.;"],
    [74,"午","丑","신축오(辛丑午)『단념하십시오. 기대가 안됩니다』","▲ 이 괘(卦)를 얻으면 음신(音信)은 오지 않습니다.; 장래(將來)에 올 희망(希望)도 거의 기대(期待)할 수 없습니다.; ▲ 상대방(相對方)에서 차질(蹉跌)이 생긴 것이므로, 번민(煩悶)하지 말고 만사(萬事 )인내(忍耐)로서 시기(時機)가 올 때를 기다리는 것이 좋을 것입니다.; ▲ 일락서산지상(日落西山之象)이므로 음신(音信)은 체념(諦念)하는 것이 좋습니다.;"],
    [75,"午","寅","임인오(壬寅午)『내 생각대로 결정해야 좋습니다. 방해를 극복하십시오』","▲ 이 괘(卦)를 얻으면 혼담(婚談)에는 좋을 것입니다.; 그러나 타인(他人)의 중상(中傷), 또는 방해(妨害)가 있기 쉬우며, 모처럼의 양연(良緣)을 놓칠 징조가 농후(濃厚)합니다.; 쌍방(雙方) 모두 심경(心境)을 평정(平靜)하게 하고 타인(他人), 또는 고용인(雇傭人)의 중상(中傷)에 마음쓰지 말고 결혼(結婚)한다면 백년해로(百年偕老)의 행복(幸福)을 얻겠습니다.; 요(要)는, 서로 굳은 결심(決心)만 할 수 있다면 혼인(婚姻)의 성립(成立)을 보게 되겠습니다.; ▲ 타인(他人)의 방해(妨害)가 예상(豫想)됨으로 잘 타개(打開)하지 않으면 안됩니다.;"],
    [76,"午","卯","계묘오(癸卯午)『온다해도 피곤할 뿐입니다. 간다해도 무익입니다』","▲ 이 괘(卦)를 얻으면 여행(旅行), 출입(出入)은 원근(遠近)을 불문(不問)하고 도로무익(徒勞無益)합니다.; 인생(人生)의 행로간난(行路艱難)을 생각하게 합니다.; 공비(空費)만 쓰게될 뿐 명칭무성(名稱無成)이 될것입니다.; ▲ 대인(待人)은 거의 오지 않을 것입니다.; 혹 온다고 하더라도 아무런 유익(有益)함이 없습니다.; 래객(來客)은 정신적(精神的) 소모(消耗)만 초래(招來)하겠습니다.;"],
    [77,"午","辰","갑진오(甲辰午)『동쪽 윗사람을 만나십시오』","▲ 방문(訪問), 남녀간(男女間), 어느쪽이건 상위자(上位者)를 만나면 이득(利得)이 있겠습니다.; ▲ 방향(方向)은 동방(東方)이 좋고, 주객(主客) 모두 협력(協力)과 이익(利益)을 얻을 수 있습니다.; 무슨 목적(目的)을 품고 있다면 꼭 후원(後援)을 얻을 수 있습니다.; ▲ 희망사항(希望事項)은 길(吉)하게 응(應)하고 있습니다.; 상위자(上位者)를 방문(訪問)하면 댁(宅)에서 만날 수 있고, 담화(談話)는 유리(有利)하게 진행(進行)될 것입니다.; ▲ 재하자(在下者)는 내방(來訪)이 있겠습니다.; 계획(計劃)하는 일은 부탁(付託)에 상응(相應)하는 길조(吉兆)가 보입니다.;"],
    [78,"午","巳","을사오(乙巳午)『집 식구의 짓입니다. 분실한 것일수도 있습니다』","▲ 가인(家人)으로 인해 분실(紛失), 도난(盜難)을 당(當)한 것입니다.; 이욕(利慾)을 탐(貪)하여 은의(恩義)를 배반(背反)한 소행(所行)입니다.; ▲ 실물(失物)은 놔 둔 장소(場所)를 잃어버렸기 때문입니다.; 언젠가는 찾게 됩니다.; 원래 위치(位置)를 찾아보는 것이 중요(重要)합니다.; ▲ 실물(失物)이나 도난(盜難)은 빨리 찾아보면 유일(酉日)에는 발견(發見)하게 되겠습니다.; 불연(不然)이면 단서(端緖)라도 얻게 되겠습니다.;"],
    [79,"午","午","갑오오(甲午午)『스스로 귀가하거나 연락이 오겠습니다』","▲ 이 괘(卦)를 얻으면 실종(失踪)을 점단(占斷)할 경우 남방(南方)에서 찾을 수 있습니다.; 또는 자기(自己) 스스로 나타날 조짐(兆朕)도 있으나, 방해(妨害)가 생기기 쉬운 상(象)입니다.; ▲ 점단일(占斷日)이 진술일(辰戌日)이면, 수년후(數年後)가 아니면 나타나지 않으며, 본인(本人)은 고통(苦痛)스런 생활(生活)을 하고 있을 것입니다.; 오미일(午未日)에 가출(家出)하였을 경우 자기(自己) 스스로 귀가(歸嫁)할 것입니다.; 혹 연락(連絡)을 받게 되겠습니다.;"],
    [80,"午","未","을미오(乙未午)『때를 기다려야 합니다』","▲ 계획지사(計劃之事)는 급(急)하게 성립(成立)되기는 어렵겠습니다.; 즉, 삼심양의(三心兩意)의 상(象)이므로 성공(成功)이 어렵지요.; 본분(本分)을 지키고, 시기(時期)가 올 때를 기다려야 합니다.; 아무리 심신(心身)이 바쁘게 노력(努力)하여도 효과(效果)는 얻기 어렵습니다.; ▲ 꽃은 봄이 되어야 피는 법(法), 시기(時期)를 기다려 계획(計劃)하면 성공(成功)하겠습니다.; ▲ 목적 달성(目的 達成)은 어려우며, 혹 이루어 지더라도 소성(小成)에 그치겠습니다.;"],
    [81,"午","申","병신오(丙申午)『돈 쓰는 수밖에 없겠습니다. 그러면 유리해 집니다』","▲ 도량(度量)이 협소(狹小)한 것이 발단(發端)이 되어 소송(訴訟)이나 관재(官災)가 일어난 것입니다.; 피차(彼此) 동일(同一)한 심정(心情)이므로 사건(事件)이 발생(發生)하게 되는 것이며, 모두 고통(苦痛)을 주는 화환(禍患)이 침범(侵犯)하고 있습니다.; ▲ 깊이 사려(思慮)하고 재력(財力)에 의뢰(依賴)한다면 만사(萬事) 유리(有利)하게 전환(轉換)되겠습니다.; 상대(相對)를 경시(輕視)하지 말고, 대응(對應)하는 것이 긴요(緊要)합니다.;"],
    [82,"午","酉","정유오(丁酉午)『위험합니다. 치료에 최선을 다해야 합니다』","▲ 노인(老人), 또는 소년(少年)의 병(病)입니다.; 병세(病勢)는 낙관(樂觀)을 불허(不許)하며, 충분(充分)한 의료(醫療)를 요(要)합니다.; 표면상(表面上)은 고통(苦痛)이 적은 것 같으나 병(病)은 악화(惡化)될 징조(徵兆)가 보이므로, 명의(名醫)와 양약(良藥)으로 치료(治療)하고 간병(看病)에 전념(專念)해야 합니다.; ▲ 유일(酉日), 술일(戌日)에는 소강(小康)을 얻을 것이나, 이 날 부터 병세(病勢)가 악화(惡化)될 경우(境遇)에는 구사일생(九死一生)의 가능성(可能性)밖에 없습니다.;"],
    [83,"午","戌","무술오(戊戌午)『여의치가 않습니다. 포기하고 기다리는 것이 상책입니다』","▲ 이 괘(卦)를 얻으면 매매(賣買), 무역(貿易), 경영(經營), 사업(事業) 등 거의가 마음이 산란(散亂)해 지고, 혹은 지장(支障)이 초래(招來)되어 순조(順調)롭게 되지 않습니다.; 매매(賣買)는 이익(利益)이 없고, 계약(契約)은 불이행(不履行)되는 일이 많을 것이므로 만사(萬事)를 보고 넘기는 방침(方針)이 현명(賢明)합니다.; ▲ 달이 지나면 소사(小事)는 성립(成立)하나, 대사(大事)는 성립(成立)되기가 어렵습니다.; 이익(利益)이 큰 쪽을 쫒다보면 오히려 손재(損財)만 초래(招來)하게 되겠습니다.; 본분(本分)을 지키고 대욕(大慾)을 버리는 것이 긴요(緊要)합니다.; ▲ 매매(賣買) 공(共)히 역상(逆商)을 하는 것이 유리(有利)합니다.; 전도(前途)는 불규칙(不規則)한 보합(保合)입니다.; 상담(商談)은 순조(順調)롭지 못할 것이며, 상품(商品)의 구득(購得)은 가능(可能)하지만 원매자(願買者)는 만나기가 어렵겠습니다.;"],
    [84,"午","亥","기해오(己亥午)『콩 심은데 콩나고 팥 심은데 팥나게 되지요』","▲ 이 괘(卦)를 얻으면 대체로 적덕지가(積德之家)에서는 현자(賢子)를 출산(出産)하며, 불선(不善)한 가계(家系)에서는 악명(惡名)을 남길 자식(子息)을 얻을 인연(因緣)입니다.; 요(要)는 신앙심(信仰心)이 돈독(敦篤)하며, 또는 음덕(陰德)을 쌓은 가정(家庭)에서는 옥동자(玉童子)를 출산(出産)하여 가운(家運)이 영창(榮昌)하게 됩니다.; 잘 양육(養育)하면 길조(吉兆)가 있을 것입니다.; ▲ 이 괘상(卦象)은 아직은 옥석(玉石)을 판단(判斷)할 수 없으므로, 그저 하늘의 뜻에 따르는 것이 현명(賢明)합니다.; 운명(運命)의 성쇠(盛衰)에 따라 분기(分岐)가 되겠지요.;"],
    [85,"未","子","기자미(己子未)『방해가 있겠으나 꾸준히 나가십시오. 아랫사람이 속을 썩이겠습니다』","▲ 재화(財貨)나 이익(利益)을 추구(追求)하여도 불안정(不安定)한 상(象)입니다.; 이는 타인(他人)이나, 혹은 부하(部下) 사람 때문에 방해(妨害)가 발생(發生)하는 것입니다.; 그러나 만사(萬事) 성의(誠意)를 갖고 인내(忍耐)로서 일에 임(臨)한다면 운명(運命)은 개척(開拓)되고, 목적(目的)은 성취(成就)될 것입니다.; ▲ 축일(丑日)은 모든 일에 길일(吉日)입니다.; 무슨 일이건 용기(勇氣)와 끈기를 갖고 목적(目的)을 향(向)해 추진(推進)한다면 대소(大小) 불문(不問)하고, 재리(財利)를 얻게 되겠습니다.; 상업상(商業上)의 이익(利益)은 역시 호운(好運)입니다.; ▲ 사용인(使用人), 또는 부하(部下)에게 고정(苦情)이 있습니다.; ▲ 자금(資金), 차입금(借入金), 모두 소액(少額)은 조달(調達)되나 큰 금액(金額)은 불가능(不可能)합니다.; 또한 대금(貸金)의 회수(回收)도 되지 않겠습니다.;"],
    [86,"未","丑","경축미(庚丑未)『생각과는 다릅니다. 신유(申酉)일이 아니거든 포기하십시오』","▲ 이 괘(卦)를 얻으면 음신(音信)은 없습니다.; 풍성학려의 상(象)이므로 생각은 멀리 달리고 있으나 간산만수(干山萬水)가 상격(相隔)되어 있습니다.; 혹(或) 신일(申日), 유일(酉日), 자일(子日) 진일(辰日)에는 좋은 음신(音信)이 있게 됩니다.; ▲ 점단 일(占斷 日)이 신일(申日) 유일(酉日)이 아니면 음신(音信)이 올 희망(希望)은 거의 없습니다.;"],
    [87,"未","寅","신인미(辛寅未)『인연이 아닌 듯 합니다. 굳이 서두를 필요는 없습니다』","▲ 타인(他人)으로 인(因)해 방해(妨害)가 생겨 혼인(婚姻)은 성립(成立)될 것 같지 않으나, 혹(或) 점단일(占斷日)이 신일(申日) 유일(酉日)이라면 성립(成立)을 보게 되겠습니다.; ▲ 결혼(結婚)을 한다해도 처음은 잘 화합(和合)이 되지 않습니다.; 중도(中途)에 가서야 차차 완화(緩和)되어 나중에는 서로 이해(理解), 화합(和合)하게 되겠습니다.; ▲ 타인(他人)으로 연유(緣由)하여 부부(夫婦)의 결합(結合)이 파괴(破壞)되는 우려(憂慮)가 생기겠습니다.; 그러므로 이 혼담(婚談)은 길흉(吉凶)이 상반(相半)하는 상(象)입니다.; 이 점(点) 주의(注意)를 요(要)합니다.;"],
    [88,"未","卯","임묘미(壬卯未)『이시기를 조절하십시오. 속도를 늦추십시오』","▲ 이 괘(卦)를 얻으면 여행이나 외출(外出)은 평안(平安)합니다.; ▲ 대인(待人)은 오나 좀 지연(遲延)되겠습니다.; ▲ 모든 일을 신속(迅速)만을 위주(爲主)로 하면 목적달성(目的達成)은 어렵고, 시기(時機)를 보고 행동(行動)한다면 성공(成功)합니다.; 대인(待人)도 오지 않을 것이며, 온다고 하더라도 늦게야 오게 됩니다.; 무엇이든 시기(時期)를 기대(期待)하는 것이 중요(重要)합니다.;"],
    [89,"未","辰","계진미(癸辰未)『그냥 혼자 지내십시오. 찾아봐야 손해만 납니다』","▲ 이 괘(卦)를 얻으면 누구를 방문(訪問)하던지 면담(面談)이 어렵습니다.; 면접(面接)이 되더라도 목적(目的)은 달성(達成)하기 어려울 것입니다.; 심로(心勞)와 손재(損財)만 초래(招來)될 것이므로, 다른 방책(方策)을 고려(考慮)하던가, 아니면 독서삼매(讀書三昧)를 즐기는 것이 유익(有益)할 것입니다.; ▲ 래객(來客)은 있으나 이익(利益)은 적을 것이며, 덕(德)으로서 맞이하면 소모(小耗)를 얻을 수 있을 것입니다.;"],
    [90,"未","巳","갑사미(甲巳未)『동남간을 알아 보십시오』","▲ 분실(紛失), 도난(盜難), 절취도망(竊取逃亡)한 것.; 모두가 오리무중(五里霧中)이고, 판단(判斷)이 어렵습니다.; 작은 단서(端緖)라도 발견(發見)하면 찾을 수 있습니다.; ▲ 동남(東南)의 방향(方向)으로 탐문(探問)하면 묘진일(卯辰日)에 가서 찾을 수 있을 것입니다.;"],
    [91,"未","午","을오미(乙午未)『서쪽으로 갔는데 찾기가 어렵습니다』","▲ 은애(恩愛)하는 정(情)이 날로 소원(疏遠)해 집니다.; 발견(發見)하거나 대면(對面)의 시기(時期)를 판단(判斷)하기 어렵습니다.; 더욱 음신(音信)도 시일(時日)이 경과(經過)함에 따라 멀어집니다.; 손이 닳도록 찾아도 만나기가 어렵겠습니다.; 방향(方向)은 서방(西方)으로 달아난 것이 틀림없습니다.; ▲ 인일(寅日), 신일(申日)에 점단(占斷)하였으면 속(速)히 발견(發見)됩니다.; 혹 인월(寅月)이나, 인일(寅日)에 돌아오겠으나 꼭 기대(期待)하기는 어렵습니다.;"],
    [92,"未","未","갑미미(甲未未)『방해로 지연되니 성심성의를 다해야 합니다』","▲ 계획(計劃)하는 일이 처음에는 방해(妨害)가 생겨 만사의혹(萬事疑惑), 결정(決定)이 어려울 것입니다.; 망동(妄動)하면 손해(損害)만 될 뿐 아무런 성과(成果)도 얻지 못합니다.; 견인불발(堅忍不拔)의 정신력(精神力)으로 서두르지 말고, 지성(至誠)으로 일을 추진(推進)해 나간다면 후광(後光)을 얻어 길응(吉應)됩니다.; ▲ 목적달성(目的達成)이 거의 어렵고, 혹 이루어 지더라도 소성(小成)에 불과(不過)하며, 그 위에 지연(遲延)될 가능성(可能性)이 많습니다.;"],
    [93,"未","申","을신미(乙申未)『서두르되 윗사람과 의논해야 합니다. 어차피 치러야 할 문제입니다』","▲ 소송(訴訟)이나 관사(官事)는 거의 손재(損財)가 많고, 일은 확대(擴大)될 것입니다.; 염려(念慮)는 크게 하나, 실효(實效)는 적겠습니다.; ▲ 상위자(上位者) 혹은 집안 어른의 후원(後援)과 지도(指導)를 받아 대처(對處)하는 것이 상책(上策)입니다.; 그렇게 하면 관송사(官訟事)는 다소(多少) 가볍게 되겠습니다.; ▲ 무슨 일이건 서둘러 방책(方策)을 강구(講究)하는 것이 필요(必要)합니다.;"],
    [94,"未","酉","병유미(丙酉未)『귀신침범입니다. 무연불(無緣佛)에 정성껏 기도하십시오』","▲ 병환(病患)과 원인(原因), 거의가 내외귀신(內外鬼神)의 발동(發動)에 의한 것입니다.; 한기(寒氣)와 열(熱)이 왕래(往來)하며, 또한 창기(瘡氣)까지 병발(倂發)하는 수도 있습니다.; 병세(病勢)는 우희양면(憂喜兩面)하고, 차츰 진퇴(進退)하게 되겠습니다.; ▲ 선조(先祖)를 지성(至誠)으로 치제(致祭)하고, 무연(無緣)의 불(佛)을 공양(供養)하면 순차(順次) 병귀(病鬼)는 퇴산(退散)됩니다.; 시각(時刻)은 유시(酉時)를 용(用)하십시오.; 그리하면 신일(申日), 유일(酉日)에 가서 차도(差度)가 있을 것입니다.;"],
    [95,"未","戌","정술미(丁戌未)『여자가 제공하는 정보에 전력하여 이익이 큽니다』","▲ 상업(商業)ㆍ매매(賣買) 어느것이나 순조(順調)롭게 성취(成就)되며, 많은 이득(利得)을 얻을 것입니다.; 남방(南方)의 거래(去來)는 일층(一層) 이익(利益)이 많겠습니다.; 또한 그 대부분(大部分)은 여인(女人)에 의하여 동기(動機)가 조성(造成)되겠습니다.; 단(但), 속(速)히 차선(車船)을 수습(收拾)하는 것이 좋습니다.; 그리고 맹진(猛進)은 삼가야 하며, 다소(多少) 가격경쟁(價格競爭), 혹(或) 가격(價格)의 이견(異見)이 예상(豫想)되나, 결국(結局)은 순조(順調)롭게 성립(成立)되며 이익(利益)을 얻게 되겠습니다.; ▲ 매매(賣買)는 천정가(天井價)에 가깝다.; 그러므로 파는 쪽이 유리(有利)합니다.; 재고품(在庫品)은 매출(賣出)하는 것이 좋으며, 원매자(願買者)도 있겠습니다.;"],
    [96,"未","亥","무해미(戊亥未)『어렵습니다. 마음을 비우고 관음기도를 하십시오. 기적이 기대됩니다』","▲ 이 괘(卦)를 얻으면 임신(姙娠)이 어렵습니다.; 혹, 임신(姙娠)이 된다해도 우희(憂喜)가 공존(共存)하는 상(象)입니다.; 산액(産厄)이 있어 모자(母子) 함께 한때 위험상태(危險狀態)에 처(處)하게 되겠습니다.; ▲ 태교(胎敎)와 양생(養生)의 뜻을 버리고, 일찍 관음(觀音)을 신앙(信仰)하여 무사(無事)를 기원(祈願)하면 기적(奇蹟)을 얻을 수가 있을 것입니다.;"],
    [97,"申","子","무자신(戊子申)『최선의 노력을 하면 여자의 도움으로 일이 되나 급하면 안됩니다』","▲ 이 卦를 얻으면 努力과 꾀하는 일이 지력(知力)을 다한 덕(德)으로 성사(成事)되겠습니다.; 급히 서두르면 실책(失策)하게 됩니다.; 예를 들면 험로(險路)를 달리는 차(車)와 같아서 천천히 가야 하는 것처럼, 노고(勞苦)를 아끼지 않고 노력(努力)한다면 후(後)에는 반드시 뜻대로 빨리 달릴 수 있게 되겠습니다.; ▲ 상하(上下), 내외(內外)가 모두 화합(和合)하고 노력(努力)하면 여인(女人) 또는 음(陰)의 후원자(後援者)를 얻게 됩니다.; 급하게 서두르지 말것이며, 목적(目的)을 달성(達成)하는데만 진력(盡力)하십시오.; ▲ 자금(資金), 금융(金融), 거의 소성(小成)은 가능(可能)하나, 대성(大成)은 어렵습니다.; 자금(資金)의 회수(回收)도 또한 같습니다.;"],
    [98,"申","丑","기축신(己丑申)『소식이 없다고 원망하지말고 있으면 큰 기쁨이 오게 됩니다』","▲ 높은 곳에서 밖을 내다보며 음신(音信)을 기다리는 상(象)입니다.; 그러나 음신(音信)은 오지 않습니다.; ▲ 음신(音信)이 오지않아 체념(諦念)하였을 때, 홀연(忽然)히 좋은 음신(音信)은 올 것입니다.; 내용은 복록(福祿) 희경지사(喜慶之事)입니다.; 희망(希望)과 소망(所望)이 성취(成就)되는 문서(文書)가 올 것이니, 가정(家庭)에 기쁨이 넘치게 됩니다.; ▲ 처음에는 멋진 산수화(山水畵)와 같은 아름다운 것이고, 뒤에는 홀연(忽然)히 문서(文書)가 도달(到達)하여 그림을 비추어 주는 기쁨이 있겠습니다.;"],
    [99,"申","寅","경인신(庚寅申)『혼인이 안될뿐더러 된다해도 해로가 안됩니다』","▲ 이 괘(卦)를 얻으면 혼인(婚姻)의 성립(成立)은 지난(至難)합니다.; 모두 허사(虛事), 허언(虛言)이 많고 진실(眞實)이 결(缺)하고 있습니다.; 또한 결혼(結婚)을 한다해도 시사반구(施思反仇)가 되어 화합(和合)이 안되며 서로 원수(怨讐) 대(對)하듯 하게 됩니다.; ▲ 부부(夫婦)가 반목(反目)하지 않을 경우(境遇)라도 생리사별(生離死別)의 우려(憂慮)가 있게 될것입니다.;"],
    [100,"申","卯","신묘신(辛卯申)『손해만 나겠습니다. 기대하지 마십시오』","▲ 이 괘(卦)를 얻으면 출행(出行), 여행(旅行), 대인(待人), 모두가 주저(躊躇)하며 진전(進展)이 없습니다.; 소망(所望)이나 계획(計劃)하는 일은 진행(進行)하려해도 지지부진(遲遲不進)하게 됩니다.; ▲ 대인(待人)은 동방(東方), 북방인(北方人)은 위약(違約)하고 오지 않을 것입니다.; ▲ 만사(萬事) 정신(精神)을 가다듬고 신중(愼重)을 기(期)하지 않는다면 재물손모(財物損耗)만 초래(招來)하게 됩니다.; 어느 일이든 반응(反應)은 늦어진다고 생각하십시오.;"],
    [101,"申","辰","임진신(壬辰申)『늦추는 것이 좋습니다. 서둘러도 되지 않습니다』","▲ 이 괘(卦)를 얻으면 방문(訪問)하여도 면담(面談)은 불가능(不可能)합니다.; 혹(或) 면담(面談)하게 되더라도 목적달성(目的達成)은 안되며 시간(時間)만 허비(虛費)하게 되겠습니다.; 그러므로 냉정(冷情)하게 사기(士氣)를 길러 시기(時機)를 기다려 방문(訪問)하는 것이 현명(賢明)하겠습니다.; ▲ 래객(來客)은 있으나 마음이 안정(安定)되지 않아 변경(變更)을 보게 될것입니다.;"],
    [102,"申","巳","계사신(癸巳申)『가까운 사람에게 당합니다. 그러나 선처하는 것이 좋습니다』","▲ 분실(紛失), 도난(盜難)은 근친(近親), 또는 친척(親戚), 붕우(朋友)의 고의(故意)에 의하여 당(當)하게 됩니다.; 혹은 가옥(家屋)이 방지(防止)할 수 있는 원인(原因)이 되고 있습니다.; 재물(財物)의 손모(損耗)는 거듭되기 쉬우며, 더욱 가장(家長)의 심사(心思)를 상(傷)하게 되겠습니다.; ▲ 좋게 온정(溫情)을 갖고 선처(善處)하는 외 다른 방법(方法)은 없습니다.;"],
    [103,"申","午","갑오신(甲午申)『무사하고 종교 방면의 친지에게 가 있습니다』","▲ 가인(家人)의 실종(失踪)이니, 신변(身邊)은 무사(無事)하며 원국(遠國)으로 간 것은 아니고, 친지(親知)에게 의뢰(依賴)하여 숨어 있습니다.; 사원(寺院)이나 종교인가(宗敎人家)를 찾아보는 것이 좋습이다.; ▲ 출분(出奔)한 후(後) 귀의(歸意)는 갖고 있으나, 귀가(歸嫁)하기가 어려울 것으로 보입니다.; ▲ 빨리 찾아보면 무난(無難)히 찾을 수 있습니다.; 오랜 친구(親舊), 은인(恩人)의 집 등에 있는 징조(徵兆)가 있습니다.;"],
    [104,"申","未","을미신(乙未申)『꼭 되겠습니다. 빨리는 안됩니다』","▲ 계획(計劃)하고 있는 일은 타인(他人)의 도움으로 달성(達成)됩니다.; 천랑기청(天朗氣淸), 산악(山岳)이 유연(悠然)한 상(象)입니다.; 만사(萬事) 꼭 성취(成就)되겠습니다.; ▲ 계획(計劃)은 늦더라도 꼭 만성(晩成)되게 됩니다.; 소망(所望)하는 일이나 계획(計劃)은 거의 목적(目的)하는 바를 뜻대로 이루게 됩니다.; ▲ 목적(目的)은 달성(達成)하게 될 것이나, 급(急)하게는 되지 않습니다.;"],
    [105,"申","申","갑신신(甲申申)『내 탓으로 생긴 사건이나 화해가 되겠습니다』","▲ 쟁송사건(爭訟事件)은 재물(財物) 혹은 타인(他人)을 놀라게 한 일이 원인(原因)이 되어 타인(他人)의 농락(弄絡)에 의해 발생(發生)한 것입니다.; 처음에는 다소 차질(蹉跌)이 생겨 불리(不利)하겠으나 점차 호전(好轉)되어 유리(有利)한 방향(方向)으로 전환(轉換)되어 결국(結局)은 화해(和解) 또는 불기소(不起訴)로 될 것입니다.; 진일(辰日), 자일(子日)에는 자연(自然)스럽게 화해(和解)되겠습니다.; ▲ 원래(元來) 어떤 사건(事件)이건 작일(昨日)은 유정(有情), 금일(今日)은 숙원(宿怨)으로 화(化)하여 타인(他人)의 중상모략(中傷謀略)으로 악화(惡化)되나, 결국(結局)은 호전(好轉)되고, 선흉후길지상(先凶後吉之象)입니다.;"],
    [106,"申","酉","을유신(乙酉申)『완쾌가 어렵습니다』","▲ 허리(腰)ㆍ위의 병(病)입니다.; 병(病)은 점점 악화(惡化)되는 상태(狀態)입니다.; 거의 완쾌(完快)를 기대(期待)하기 어렵습니다.; 십중 칠팔(十中 七八) 약간(若干)은 호전(好轉)되나, 전쾌(全快)는 어렵습니다.; ▲ 생년지지(生年地支)의 전오위지지(前五位地支) 방위(方位)에서 의사(醫師)를 구(求)하십시오.; 그리고 제7위 방위(第7位 方位)의 신불(神佛)에 기원(祈願)하면 호전(好轉)되겠습니다.; 이를테면 자년생인(子年生人)의 경우의 제5위 지지(第5位 地支)는 진방(辰方), 즉 동남방(東南方), 제7위 지지(第7位 地支)는 오방(午方)입니다.;"],
    [107,"申","戌","병술신(丙戌申)『이익이 크겠습니다만 소극적인 방침으로 하십시오』","▲ 상거래(商去來) 매매(賣買) 계약(契約) 등(等)은 거의 성립(成立)되며, 모두 상당(相當)한 이윤(利潤)을 올릴 수 있겠습니다.; 예(例)를 들면 동매 서매(東買 西賣)로 이익(利益)이 많고, 따라서 당시(當時) 판단(判斷)대로 매매(賣買)를 하면 유쾌(愉快)할 정도(程度)로 이득(利得)을 보겠습니다.; 그러나 너무 망설이거나 이익(利益)만을 노리고, 맹진(盲進)할 때에는 대선전복(大船顚覆)의 손재(損財)가 있을 것이므로, 기회(機會)를 보고 소극적(消極的) 방침(方針)으로 처리(處理)해 나가는 것이 현명(賢明)합니다.; ▲ 가격(價格) 전망(展望)은 상승(上昇)하겠습니다.; 그러므로 매입(買入)하는 쪽이 유리(有利)합니다.; ▲ 상담(商談)은 성립(成立)합니다.; 재고품(在庫品)은 원매자(願買者)가 있겠습니다.; ▲ 매매(賣買)가 진행(進行)되는 도중(途中)에 가격(價格)은 최고가(最高價)로 상승(上昇)하게 됩니다.;"],
    [108,"申","亥","정해신(丁亥申)『신불에 정성으로 기도해야 합니다. 아이를 낳게 되면 운세가 펴집니다』","▲ 이 괘(卦)를 얻으면 임신(姙娠)이 어려운 상(象)입니다.; 혹(或) 임신(姙娠)이 될 때에는 해일(亥日), 또는 신일(申日)에 크게 놀라는 일이 생겨 인일(寅日)에 난산(難産)하게 되겠습니다.; ▲ 정성(精誠)으로 신불(神佛)에 기원(祈願)하십시오.; 신앙(信仰)의 덕(德)으로 악기(惡氣)가 소산(消散)되면 차차 산난(産難)은 없어지겠습니다.;.; 자녀출산 후(子女出産 後)는 구름이 걷치고 명월(明月)을 우러러 보는 것처럼 가내화기(家內和氣)가 충만(充滿)되겠습니다.; 신앙(信仰)의 힘과 평생(平生)의 신조(信條)에 기대(期待)하십시오.;"],
    [109,"酉","子","정자유(丁子酉)『선수를 치고 나가야만 합니다. 늦으면 안됩니다』","▲ 재화(財貨)를 구(求)하려 한다면 신속(迅速)히 노력(努力)하고 활동(活動)한다면 목적(目的)은 달성(達成)될 것이나, 혹(或) 늦어 진다면 지체(遲滯)되는 상태(狀態)이며, 노력(努力)을 해도 효과(效果)는 얻을 수 없게 될것입니다.; ▲ 상대(相對)를 선제(先制)함으로써 성과(成果)를 얻는 상(象)이므로, 선수(先手)를 쓰는 것이 중요(重要)합니다.; ▲ 영업상(營業上)의 차입(借入), 금융관계(金融關係)는 모두 신속(迅速)히 활동(活動)하면 목적(目的)을 달성(達成)할 수 있으나, 이에 따른 시비(是非)나 구설(口舌)이 예상(豫想)되므로 모든 일이 불안(不安)한 상태(狀態)입니다.;"],
    [110,"酉","丑","무축유(戊丑酉)『좋은 소식이 오겠습니다. 조금만 기다리십시오』","▲ 신경(神經)을 쓰며 음신(音信)을 기대(期待)하는 상(象)입니다.; 마음속으로 소망(所望)하고 있는 것을 기대(期待)하고 있습니다.; 기다리는 음신(音信)은 지금 오고 있습니다.; 가까운 시간내(時間內)에 도착(到着)하게 될것입니다.; ▲ 음신(音信)이 오면 화향(花香)이 옷소매를 스치는 것처럼 행복(幸福)한 소식(消息)을 맞이하게 되어 기쁨이 겹치겠습니다.; 모두가 이윤(利潤)과 가화(佳話)의 좋은 음신(音信)이 오겠습니다.;"],
    [111,"酉","寅","기인유(己寅酉)『선입견을 버리면 좋은 부부가 되겠습니다』","▲ 약혼(約婚)은 급하게 성립(成立)될 길조(吉兆)입니다.; 그러나 늦어지면 성립(成立)되지 않습니다.; 늦을 때에는 타인(他人)에 의하여 방해(妨害)가 생기기 쉬운 운세(運勢)입니다.; ▲ 약혼(約婚)을 한다해도 도중(途中) 타인(他人)의 모략(謀略)에 의해 파경(破鏡)의 불행(不幸)이 우려(憂慮)됨으로 이 점(點)을 주의(注意)하는 것이 중요(重要)합니다.; ▲ 혼인 후(婚姻 後) 처음에는 서로 정의(情意)가 합치(合致)되지 않는 것처럼 보이나, 날이 경과(經過)함에 따라 부부(夫婦)는 잘 융합(融合)될 것입니다.; 요(要)는, 중도(中途)의 난관(難關)을 잘 극복(克服)해야만 백년해로(百年偕老) 춘일융화(春日融和)의 기쁨이 있게 됩니다.;"],
    [112,"酉","卯","경묘유(庚卯酉)『가만히 있는 것이 상책입니다. 도모하지 마십시오』","▲ 무슨 일이건 결과(結果)는 허망하게 됩니다.; 이러한 때 망동(妄動)한다면 결과적(結果的)으로 재해(災害)가 발생(發生)하여 심신(心身)이 괴로워 질것입니다.; ▲ 조용히 있다 하더라도 재환(災患)이 생기기 쉬운 운세(運勢)이므로 미동(微動)만 해도 실비(失費)를 하게 되고, 만사(萬事)가 뜻대로 되지 않을 것입니다.; 모두 본분(本分)을 지키는 것이 가장 좋습니다.; ▲ 출행(出行) 여행(旅行)의 생각은 있으나 동(動)할 수 없게 됩니다.; ▲ 대인(待人)은 출발(出發)도 하지 않은 상(象)입니다.; 오기가 어렵습니다.;"],
    [113,"酉","辰","신진유(辛辰酉)『모두 잘 되겠습니다. 전력을 다 하십시오』","▲ 이 괘(卦)를 얻었을 때에는 상하(上下) 모두 회견(會見)을 하여 순리(順利)를 얻겠습니다.; ▲ 멀리 헤어졌던 사람을 상봉(相逢)하는 즐거움과 같이, 재(財)를 구(求)하는 일, 계획(計劃)하고 있는 일에 대한 상담(相談)은 거의 순조(順調)롭게 마무리 되며, 서로 협조(協調)하게 됩니다.; 면담(面談)은 구정(舊情)을 따뜻하게 사며 춘화무락(春花舞蝶)과 같이 평화(平和)롭게 화기(和氣)넘치는 면담(面談)으로 시종(始終)하게 되겠습니다.; ▲ 방문(訪問)하여 즐거운 심정(心情)으로 면담(面談)하게 되고, 모든 목적(目的)은 달성(達成)될것입니다.; ▲ 래객(來客)은, 약속(約束)이 되어 있다면 늦더라고 오게 됩니다.;"],
    [114,"酉","巳","임사유(壬巳酉)『집안에 있습니다. 인(寅)일에 발견합니다』","▲ 실물(失物)은 집안에 있으며 밖으로 나간 것은 아닙니다.; 발견(發見)되지 않더라도 의기소침(意氣銷沈)할 것 까지는 없을 것입니다.; ▲ 인일(寅日)에는 발견(發見)될 것이나, 혹 정체(停滯)되면 쉽게 발견되지 않겠습니다.;"],
    [115,"酉","午","계오유(癸午酉)『배신자입니다. 찾을 길이 없습니다』","▲ 이 괘(卦)를 얻으면 의리(義理)를 망각(忘却)하고 타인(他人)의 꼬임을 받아 가출(家出)한 것입니다.; 마침내는 음신(音信)이 있겠으나, 이미 배은망덕(背恩忘德)한 소행(所行)을 저지른 자(者)이므로 자연(自然)에 일임(一任), 방기(放棄)하는 외 다른 도리(道理)는 없습니다.; ▲ 견마(犬馬)라도 사람에 대해 의리(義理)를 지키거늘, 그저 가정(家庭)이 암담(暗澹)함을 한탄(恨歎)하게 되겠습니다.; 가인(家人)의 실종(失踪)은 찾을 길이 없다고 보며, 생사(生死)의 정도(程度)도 판정(判定)하기가 어렵습니다.;"],
    [116,"酉","未","갑미유(甲未酉)『손해가 크고 자칫 헛수고일듯 싶습니다』","▲ 계획추진사항(計劃推進事項)은 방해(妨害)를 받아 인력(人力)과 실비(失費)의 손해(損害)가 있으나, 결과적(結果的)으로 목적(目的)은 달성(達成)하게 되겠습니다.; ▲ 모두가 연목구어격(緣木求魚格)으로 비관적(悲觀的)입니다.; 서둘러 보아도 졸열(拙劣)한 결과(結果)가 초래(招來)되고, 만사 공허(萬事 空虛)하게 되기 쉽습니다.;"],
    [117,"酉","申","을신유(乙申酉)『방책을 윗사람과 급히 세우면 다 잘 되겠습니다』","▲ 관사(官事)나 소송(訴訟) 모두가 소산(消散)하는 수(數)입니다.; 증거(證據)와 책임(責任)이 애매모호(曖昧模糊)하여 일이 마무리가 되지 않는 상(象)입니다.; 누구의 원조(援助)나 귀인(貴人)의 조력(助力)을 얻는다면 관재(官災)는 퇴산(退散)되고, 위험(危險)한 다리를 무사(無事)히 건너게 되겠습니다.; 그렇게 되면 걱정은 기쁨으로 화(化)하게 됩니다.; ▲ 그러므로 급(急)히 방책(方策)을 강구(講究)하는 것이 유리(有利)합니다.;"],
    [118,"酉","酉","갑유유(甲酉酉)『악운 때문이나 신불에 기원하면 낫겠습니다』","▲ 노인(老人), 혹 소아(小兒)의 병환(病患)은 쾌유(快癒)되기가 어렵습니다.; 그 밖의 병(病)은 한때 염려(念慮)스러운 상태(狀態)가 있을지라도 회복(回復)되겠습니다.; ▲ 원래 악운(惡運)으로 인하여 발병(發病)한 것이므로 손재(損財)가 많을 것입니다.; ▲ 병인(病因)은 동토(動土), 혹은 묘당(廟堂)을 촉범(觸犯)한 것이 원인(原因)이므로 가내(家內)를 청결(淸潔)히 하고 신불(神佛)에 기원(祈願)하면 병(病)은 쾌유(快癒)되고, 기쁨이 옵니다.; 임계일(壬癸日)에 안강(安康)을 얻겠습니다.;"],
    [119,"酉","戌","을술유(乙戌酉)『규모가 크고 유리합니다. 순조롭습니다』","▲ 매매교역(賣買交易)은 클수록 유리(有利)하게 회전(回轉)되며, 물품(物品)은 클수록 이익(利益)이 많습니다.; 대, 중, 소(大, 中, 小) 어느 것이나 일에 상응(相應)한 효과(效果)를 얻을 기회(機會)입니다.; 진전(進展)시켜서 좋을 것입니다.; ▲ 길상복록(吉祥福祿)의 상(象)입니다.; 길중(吉中)에 배신지사(背信之事)가 있을 수 있습니다.; 그러나 소재(小災)에 불과(不過)한 것이므로 개의(介意)할 것 까지는 없습니다.; ▲ 매물(買物)에 유리(有利)합니다.; 상담(商談)은 순조(順調)롭게 이루어지며, 시세(時勢)는 보합(保合)이나 전도(前途)는 등귀(騰貴)하겠습니다.;"],
    [120,"酉","亥","병해유(丙亥酉)『임계(壬癸)일에 처음 만난 사람의 남녀에 따라 아들 딸을 구별하십시오』","▲ 임신여부(姙娠與否)가 확실(確實)치 않습니다.; 임신중(姙娠中)이라 해도 남녀(男女)를 판단(判斷)하기가 어렵습니다.; 임신(姙娠)이라면 천우신조(天佑神助)를 얻어 안산(安産)하게 되겠습니다.; ▲ 남녀(男女)의 판별(判別)은 임일(壬日), 계일(癸日)에 처음으로 상봉(相逢)하게 되는 타인(他人)이 남자(男子)이면 아들, 여자(女子)이면 딸로 판단(判斷)하십시오.; ▲ 음덕(陰德)을 쌓은 가문(家門)이므로, 오래도록 가운융창(家運隆昌), 단락(團樂)한 생활(生活)을 얻게 되겠습니다.;"],
    [121,"戌","子","병자술(丙子戌)『어렵습니다. 아랫사람의 방해가 있겠습니다』","▲ 재(財)를 구(求)하여 목적(目的)을 달성(達成)하기가 어렵겠습니다.; 자금(資金)이 조달(調達)될 것으로 예상(豫想), 만반(萬般) 계획(計劃)을 세웠으나 뜻대로 된다는 것은 불가능(不可能)합니다.; 자금조달(資金調達)을 위해 동분서주(東奔西走)해도 얻기가 어렵습니다.; 설사 얻는 일이 있다고 하여도 결과(結果)는 구설(口舌)과 고통(苦痛)이 될 뿐입니다.; ▲ 사용인(使用人), 혹은 수하인(數下人)의 일로 인해 방해(妨害)가 되며, 타인(他人)의 방해로 구설(口舌)이 생깁니다.; 혹 피해(被害)가 우려(憂慮)되니 삼가고 조심(操心)하십시오.; ▲ 금융(金融), 자금조달(資金調達)은 거의 목적(目的)을 달성(達成)하기가 어렵습니다.; 대금(貸金) 또한 회수(回收)가 불가능(不可能)합니다.;"],
    [122,"戌","丑","정축술(丁丑戌)『기다리지 마십시오. 온다해도 시원치가 않습니다』","▲ 이 괘(卦)를 얻으면 음신(音信)은 오지 않습니다.; 모든 일이 착오(錯誤)가 생겨 늦게 도착(到着)하는 수도 있겠습니다.; ▲ 음신(音信)이 온다해도 명리(名利)에 관계(關係)되는 일은 천차만별(千差萬別) 어느 하나도 마음에 흡족(洽足)한 것은 없겠습니다.; ▲ 희소식(喜消息)을 기다린다는 것은 시기상조(時機尙早)임을 깨달아야 합니다.;"],
    [123,"戌","寅","무인술(戊寅戌)『정혼은 안되겠고 먼저 동지를 구하십시오. 그러면 도움이 될 것입니다』","▲ 마음 속으로는 정(定)해져 있으나, 성립(成立)은 되지 않습니다.; 재삼담합(再三談合)하게 될 징조(徵兆)입니다.; 고로 실비(實費)와 노력(努力)이 소모(消耗)됩니다.; ▲ 서로 요해(了解)를 얻게 됩니다.; 화지(花枝)에 동풍(東風)이 불어오는 상(象)입니다.; 요(要)는 약혼(約婚)이 좀 늦어질 조(兆)입니다.; 진행(進行)시켜 나가면 성취(成就)를 보게 되고, 남녀(男女) 모두 길응(吉應)하게 되겠습니다.;"],
    [124,"戌","卯","기묘술(己卯戌)『무슨 일이든 일단 진전시키십시오 다 잘될 것입니다』","▲ 이 괘(卦)를 얻으면 원방출입(遠方出入)ㆍ여행(旅行)에 아무런 방해(妨害)없이 목적지(目的地)에 도착(到着)하게 되며, 이익(利益)을 얻는 기쁨과, 또는 기쁨의 원천(源泉)이 조성(造成)될 것입니다.; 일진춘풍(一陣春風)이 나룻배를 건너게 도와 주는 것처럼 여정(旅程)이 쾌적(快適)하게 될 것입니다.; ▲ 명리(名利) 모두 성취(成就)할 수 있는 대길조(大吉兆)라고 점단(占斷)할 수 있겠습니다.; ▲ 대인(待人)은 원방(遠方)에서 와서 재복((財福)을 차습(蹉拾)하게 됩니다.;"],
    [125,"戌","辰","경진술(庚辰戌)『오해 안받는 것이 다행이니 제휴하되 소극적 자세를 보이십시요』","▲ 처음부터 자신(自身)을 의심(疑心), 마음의 불안정(不安定) 또는 여러 가지 망서림으로 방문(訪問)한다해도 이익(利益)이 없습니다.; 오이려 오해(誤解)만 받게될 조짐(兆朕)이 보입니다.; 흉(凶)한 상(象)입니다.; ▲ 진퇴(進退)를 결정(決定)하기가 어렵고 도리어 오해(誤解)만 사게 될 흉조(凶兆)이나, 다행(多幸)히 상대방(相對方)과 서로 제휴(提携)하게 된다면 의외(意外)의 복운(福運)을 잡게 되겠습니다.; 그런나 함부로 꾀한다면 졸책(拙策)이 되어 목적(目的)은 성취(成就)되기가 어렵게 됩니다.; ▲ 방문(訪問)해도 부재중(不在中)이리라, 목적(目的)을 달성(達成)하기는 어렵고, 다만 후일(後日)을 약속(約束)하고 돌아오게 될것입니다.; ▲ 래방객(來訪客)은 이른 시간(時間)이면 올 것이나, 늦어질 경우(境遇)는 도중(途中)에서 변경(變更)하여 래방(來訪)하지 않을 것입니다.;"],
    [126,"戌","巳","신사술(辛巳戌)『집 식구 짓입니다. 동북쪽 구석을 살피십시오』","▲ 이 괘(卦)를 얻으면 실물(失物)은 가내 인(家內 人)의 소행(所行)입니다.; 타인(他人)에게 의심(疑心)이 생겨 외부인(外部人) 심중(心中)으로 찾아 보아도 찾기는 어렵겠습니다.; ▲ 가내(家內) 동북우(東北隅:구석)를 탐색(探索)하면 찾을 수 있을 것입니다.; 그러나, 때에 따라서는 헛수고가 될 수도 있을 것입니다.;"],
    [127,"戌","午","임오술(壬午戌)『혼자 고민하다 나갔습니다. 피곤해 있습니다. 후에 옵니다』","▲ 심중(心中)에 고민(苦悶)이 있어 가출(家出)한 것입니다.; 원방(遠方)으로 달아난 것이 틀림없습니다.; 타인(他人)에 의해 감금(監禁)되던가, 아니면 유혹(誘惑)에 걸려 심신(心身)이 피로(疲勞)할 조짐(兆朕)이 있습니다.; ▲ 시일(時日)이 경과(經過)하면 평정(平靜)을 찾고 임일(壬日), 혹 유일(酉日)에 음신(音信)이 있던가 또는 귀가(歸家)하게 되겠습니다.;"],
    [128,"戌","未","계미술(癸未戌)『이시기를 더 기다리십시오. 서두르지 마십시오. 때는 반드시 옵니다』","▲ 소망(所望)이나 계획(計劃)하는 일은, 아직 시기(時期)가 오지 않아 십중팔구(十中八九)는 성립(成立)하지 않습니다.; 사람에게 의뢰(依賴)하면 오히려 손실(損失)과 공비(空費)만 쓰게 됩니다.; ▲ 시기(時機)가 올때까지 심로(心勞)하지 말고 급히 서두르지 마십시오.; 모름지기 호운(好運)이 올때까지 기다리는 것이 현명(賢明)합니다.; 앞으로 한번 행운(幸運)이 오게 되면 순풍(順風)에 돛을 달게 되겠습니다.; ▲ 모든 일은 거의 목적달성(目的達成)이 어려운 상(象)입니다.;"],
    [129,"戌","申","갑신술(甲申戌)『내 꾀로 결국 이기겠으나 화해가 되겠습니다』","▲ 소송사건(訴訟事件)은 처음에는 방해(妨害)가 있으나, 뒤에는 이기게 될 것입니다.; 결과적(結果的)으로 화해(和解)가 되겠습니다.; ▲ 원고(原告)로서 소송(訴訟)하면 상대(相對)가 참소(讒訴)로 맞설 징조(徵兆)가 있으며, 처음에는 형세불리(形勢不利)할 것이나 결국(結局)은 이기게 됩니다.; 중도(中途)에 화해협상(和解協商) 제의(提議)가 있을 때에는 수락(受諾)하는 태도(態度)로 임(臨)하는 것이 현명(賢明)합니다.; ▲ 승리(勝利)의 이유(理由)가 모두 아방(我方)의 책모(策謀)에 의한 것이므로, 이 점(點) 유의(留意)하지 않으면 안됩니다.;"],
    [130,"戌","酉","을유술(乙酉戌)『신불에 기도하는 수밖에 없습니다』","▲ 병환(病患)의 전도(前途)가 불안(不安)합니다.; 생사(生死)를 판단(判斷)하기가 어렵습니다.; 물에 동전(銅錢)을 띄우려고 애쓰는 것과 같은 상(象)입니다.; 어지간히 간병(看病)에 심혈(心血)을 다하는 정성(精誠)이 필요(必要)합니다.; ▲ 그저 조금 차도(差度)가 보이거든 신불(神佛)에 천우신조(天佑神助)를 기원(祈願)하라.; 그러면 혹 영험(靈驗)을 얻게 될 수도 있습니다.;"],
    [131,"戌","戌","갑술술(甲戌戌)『노인의 방해가 있긴하나 이익이 큽니다』","▲ 매매(賣買)는 순조(順調)롭게 성립(成立)되며, 상당(相當)한 이익(利益)을 얻겠습니다.; 중간(中間)에 노인(老人)이 한때 방해(妨害)하는 일이 있겠으나 결국(結局)은 뜻대로 되겠습니다.; ▲ 축인생인(丑寅生人) 또는 축인방(丑寅方)에서 더욱 재리(財利)가 많으리라.; 일반적(一般的)인 매매상담(賣買商談)은 성립(成立)되고, 수량(數量)은 대체로 많겠습니다.; ▲ 매매(賣買)에 따른 이해(利害)는 상담시(商談時)의 생각대로 하여서 길(吉)합니다.; ▲ 원매자(願買者)를 구(求)하면 쉽게 나타납니다.; 상담(商談)은 순조(順調)롭게 이루어 지겠습니다.; 매자(賣者)의 경우(境遇)도 또한 같습니다.;"],
    [132,"戌","亥","을해술(乙亥戌)『적선한 덕으로 남아를 얻습니다』","▲ 남아(男兒)를 얻겠습니다.; 모자(母子) 모두 건강(健康)하고, 양육중(養育中) 다소(多少) 어려움은 있을 것이나.; 원래 적선(積善)한 덕(德)이 있어 탈없이 명주(明珠)와 같은 남아(男兒)를 얻게 됩니다.; ▲ 기쁨은 인인(寅日)이 아니면 신일(申日)에 있겠습니다.;"],
    [133,"亥","子","을자해(乙子亥)『정신을 맑게 하면 다 잘 됩니다』","▲ 재리(財利)를 구(求)하여 반드시 목적(目的)을 달성(達成)합니다.; 어떤 일이건 돌연(突然)히 길조(吉兆)가 나타나 성취(成就)를 보게 됩니다.; 임계일(壬癸日), 자진일(子辰日)에는 목적(目的)을 달성(達成)하게 되며, 귀인(貴人)의 후원(後援)을 얻게 될 것입니다.; ▲ 때로는 재록(財綠)이 없는 것 같으나, 심기일전(心機一轉)하여 계획(計劃)을 세우면 가능(可能)하겠습니다.; ▲ 자금(資金), 차입금(借入金)은 뜻대로 조달(調達)됩니다.; 대금(貸金)은 회수(回收)가 가능(可能)합니다.;"],
    [134,"亥","丑","병축해(丙丑亥)『남쪽에서 기쁜소식이 있습니다』","▲ 남방(南方)에서 기쁜 음신(音信)이 오겠습니다.; 혹 기쁜 소식(消息)으로 온 가족(家族)이 흔쾌(欣快)한 기쁨을 갖게 되겠습니다.; ▲ 남방 이외(南方 以外)의 곳은 다소(多少) 늦어질 것입니다.; 역시 기쁜 소식(消息)일 것이나 경미(輕微)한 것입니다.; ▲ 요(要)는, 소식(消息)에 의(依)하여 가내(家內)에 희경사(喜慶事)가 생기겠습니다.;"],
    [135,"亥","寅","정인해(丁寅亥)『좋은 인연입니다. 축복받는 부부가 되겠습니다』","▲ 숙세(宿世)의 인연(因緣)으로 서로 도와 혼인(婚姻)이 성립(成立)되겠습니다.; 신일(申日), 또는 유일(酉日)에 결정(決定)됩니다.; 원래 이 혼약(婚約)은 천정배필(天定配匹)로서 인연(因緣)에 따라 맺어지는 배합(配合)이라고 보는 것이 좋겠습니다.; ▲ 결혼후(結婚後)는 백년화합(百年和合)을 얻게 되고, 금슬상화(琴瑟相和)하며, 기쁜 경사(慶事)가 있을 것입니다.; 가위(可謂) 가연(佳緣)이라고 할만 합니다.;"],
    [136,"亥","卯","무묘해(戊卯亥)『급히 가는 일에 장애가 있는 것은 당연하나 오히려 돈이 생기겠습니다』","▲ 이 괘(卦)를 얻으면 급하게 생각이 떠올라 외출(外出), 또는 여행(旅行)을 하게 됩니다.; 중도(中途) 다소(多少)의 난관(難關)에 봉착(逢着)하여 소소(小小)한 재해(災害)가 예상(豫想)되나 대소로운 것은 아닙니다.; 힘써 근신(謹愼)하고 노력(努力)한다면 목적(目的)을 달성(達成)하게 됩니다.; ▲ 중도(中途)에서 방해(妨害)가 생기면 득재(得財)하는 기쁨이 있겠습니다.; ▲ 대인(待人)은 중도(中途)에서 뜻을 변경(變更)할 징조(徵兆)가 보입니다.; 혹, 오게 되면 재화(財貨)의 기쁨이 생기겠습니다.;"],
    [137,"亥","辰","기진해(己辰亥)『먼저 약속부터 하십시오. 좋은 관계가 기대됩니다』","▲ 서로 면담(面談)이 있는 관계(關係)이므로 더욱 깊이 친(親)하게 될 기회(機會)입니다.; 서로 알게 된지는 얼마 안되나 금후(今後) 깊게 우의(友誼)를 갖게 되겠습니다.; ▲ 방문(訪問)의 목적(目的)이 양다리를 걸치는 것처럼 한다면 성립(成立)되지 않습니다.; ▲ 방문(訪問)을 하려하면 먼저 약속(約束)을 하고 가는 것이 좋으며, 그렇게 하지 않으면 재방(再訪)하는 번거로움이 있게 될 것입니다.; ▲ 갑자일(甲子日)이나 갑신일(甲申日)이라면 백사(百事) 모두 달성(達成)하게 되겠습니다.; ▲ 돌연(突然)한 방문(訪問)은, 부재중(不在中)이 아니면 있다 하더라도 면회(面會)하기가 어렵습니다.; ▲ 래방자(來訪者)는 없을 것이나, 원방(遠方)에서 약속(約束)이 되어 있는 사람은 오게 괼 것입니다.;"],
    [138,"亥","巳","경사해(庚巳亥)『물속을 찾으십시오. 여자가 발견하게 될것입니다』","▲ 이 괘(卦)를 얻으면 실물(失物)은 타인(他人)으로 인(因)해 절취당(竊取當)한 경우입니다.; 수변(水邊),하수구(下水溝), 교하(橋下), 또는 수중(水中)을 찾아보는 것이 양책(良策)입니다.; 또는 여성(女性)에 의(依)해 발견(發見)될 가능성(可能性)이 있습니다.; ▲ 절도(竊盜)는 『인(人)의 소행(所行)이 아니고 거의 사정(事情)을 잘 아는 자(者)의 소위(所爲)입니다.; ▲ 수중(水中)에 있을 경우(境遇) 깊은 곳에 있으므로 어려운 감(感)이 없지 않으나, 심혈(心血)을 기울여 탐색(探索)하면 반드시 원위치(原位置)로 돌아옵니다.;"],
    [139,"亥","午","신오해(辛午亥)『여자구설이 걱정됩니다. 집나간 사람은 서쪽에 있습니다』","▲ 이 괘(卦)를 얻으면 실종 인(失踪 人)은 가출(家出)하면서 물건(物件), 혹은 재물(財物)을 가져 서방(西方)으로 갔습니다.; 경신(庚辛)의 방향(方向), 서방(西方)을 탐색(探索)하면 빠를 때에는 잡을 수 있을 것이나, 늦을 때에는 타인(他人)의 손으로 넘어 가버렸습니다.; ▲ 혹 음인(陰人), 여인(女人)과의 다툼이나 구설(口舌)이 생기기 쉽겠습니다.;"],
    [140,"亥","未","임미해(壬未亥)『모든 일이 순조롭습니다. 최대한 노력하며 윗사람을 찾으십시오』","▲ 이 괘(卦)를 얻으면 계획(計劃)하는 일, 또는 구재(求財), 거의가 처음은 정체(停滯)될 상(象)이나 점차(漸次)로 성과(成果)가 있겠습니다.; 구재(求財), 소망(所望), 모두 시기(時機)에 따라 뜻대로 될 것입니다.; ▲ 귀인(貴人)의 보살핌이 있어 성취(成就)의 실마리가 잡힐 것이며, 점단 일(占斷 日)이 자일(子日), 축일(丑日)이면 노력(勞力)과 비용(費用)이 적게들면서 목적(目的)한 바가 진전(進展)을 보게 됩니다.; ▲ 목적(目的)은 달성(達成)되고, 모든일이 순조(順調)롭습니다.;"],
    [141,"亥","申","계신해(癸申亥)『비용만 나는 싸움입니다. 비용을 줄이고 스스로 자중하는 수밖에 없습니다』","▲ 관사(官事), 송사(訟事), 모두 모름지기 비용(費用)의 소모(消耗)가 많게 됩니다.; 더욱이 심로(心勞)와 고통(苦痛)이 중첩(重疊)되기 때문에 한(恨)을 품게 될것입니다.; 그저 우인(友人)의 원조(援助)를 기다려 선처(善處)하면 길(吉)하게 되겠습니다.; ▲ 스스로 수양(修養)하고 자중자애(自重自愛), 번뇌(煩惱)를 경(輕)하게 하고 실비(失費)를 줄이는 방책(方策)을 고려(考慮)하는 것이 현명(賢明)하겠습니다.;"],
    [142,"亥","酉","갑유해(甲酉亥)『띠 반대쪽 절에 가서 모래를 퍼다가 집안 밖에 뿌리십시오』","▲ 고용인(雇傭人), 혹은 자녀(子女)의 병환(病患)으로 자유(自由)롭지 못합니다.; 더욱이 가장(家長)도 병환(病患)의 염려(念慮)가 있습니다.; 사기악급(邪氣惡急)이 범(犯)하고 있기 때문입니다.; 의약치료(醫藥治療) 외(外)에 가내(家內)를 정결(淨潔)히 하고, 신불(神佛)에 분향기원(焚香祈願)하면 병귀(病鬼)는 점차 퇴산(退散)될 것입니다.; ▲ 병인(病人)의 생년지지(生年地支)의 반대방향(反對方向)에 있는 사원(寺院)에서 청정(淸淨)한 모래(砂)를 구(求)하여 집안 내외(內外)에 뿌리십시오.; 영험(靈驗)이 있을 것입니다.; (지지(地支)의 반대방향(反對方向)이란, 예(例)컨대 자년생(子年生)이면 오방(午方) 축년생(丑年生)이면 미방(未方)을 말합니다).;"],
    [143,"亥","戌","을술해(乙戌亥)『뜻대로 안되는 운세입니다』","▲ 매매(賣買)는 거의 실리(失利)가 적고, 오히려 손실(損失)을 당(當)하기 쉽겠습니다.; 타인(他人)으로 인해 망설이고, 혹 속임수에 빠질 징조(徵兆)가 보입니다.; 그러나 본업(本業)이 아닌 부업(副業)이라면 소리(小利)는 얻을 수 있겠습니다.; ▲ 풍랑(風浪)으로 바다가 거칩니다.; 항해(航海)가 어려운 상(象)입니다.; 허욕(虛慾)을 버리고 본분(本分)을 지켜 손실(損失)을 예방(豫防)하는 것이 상책(上策)입니다.; ▲ 모든일에 방해(妨害)가 생기기 쉽습니다.; 방침(方針)을 변경(變更)하고, 영리(營利)를 다시 한번 점검(點檢)해 보는 것이 좋겠습니다.; ▲ 매매(賣買)는 어느쪽이건 보고 넘기는 것이 유리(有利)합니다.; ▲ 시세(時勢)는 고저부정(高低不定)입니다.; 나의 매물(賣物)은 원매자(願買者)가 붙지 않습니다.; ▲ 상담(商談)은 순조(順調)롭다고 전망(展望)됩니다.; 망서림은 불리(不利)합니다.; 매매(賣買)도 불문(不問)입니다.;"],
    [144,"亥","亥","갑해해(甲亥亥)『귀자이며 순산입니다』","▲ 태점(胎占)의 경우에 점단(占斷)이 어려우며, 의뢰인(依賴人)이 처음 발설(發說)한 말이 남(男)이면 아들, 여(女)이면 딸로 판단(判斷)하십시요.; ▲ 난산(難産) 산액(産厄)은 없을 것입니다.; 자인묘일(子寅卯日)에 경사(慶事)가 있겠습니다.; ▲ 임신여부(姙娠與否)는 현귀(賢貴)한 자(子)를 회임(懷妊)합니다.; 임신중(姙娠中)에는 아무런 근심걱정이 없습니다.;"]
  ]

const VERSION001_JIDU = [
    [1,"子","子","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보 또는 未방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [2,"子","丑","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [3,"子","寅","午","▣ 午일, 또는 午방향으로 택하라","▣ 지관이라 하여 도로,주차,하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [4,"子","卯","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 소리(小利)하고, 여행, 매매. 승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 매매는 성립된다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가라, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [5,"子","辰","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 타인의 압박이 발생하니 중지가 요구된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 길이 없으면 申방으로 100보 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데, 단 2시간 동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [6,"子","巳","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 지격이라 하여 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가라. 길이 없으면 酉방으로 100보 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [7,"子","午","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경,하인(下人)으로 손상▣ 고심이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 戌방으로 100보 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [8,"子","未","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 병이 중하여 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 곧 남쪽에서 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [9,"子","申","子","▣ 子일, 또는 子방향으로 택하라","▣ 천관이라 하여, 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 길이 없으면 子방으로 100보 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [10,"子","酉","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 목전,근대사는 길하며,원대,원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 丑방으로 100보 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜다","▣ 배신한다"],
    [11,"子","戌","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사 길하고, 특히 승부사는 큰 이익이 있게 된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 寅방으로 100보 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [12,"子","亥","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 천격이라 하여, 위의 천재(天災)가 있게 된다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 길이 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [13,"丑","子","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 천격이라 하여, 위의 천재(天災)가 있게 된다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 길이 없으면 卯방으로 100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [14,"丑","丑","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 치료가 가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보 또는 未방으로 80보 가라","▣ 吉은 外 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [15,"丑","寅","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장으로 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [16,"丑","卯","午","▣ 午일, 또는 午방향으로 택하라","▣ 지관이라 하여 도로,주차,하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [17,"丑","辰","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 소리(小利)하고, 여행, 매매. 승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 매매는 성립된다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞아 못찾으며 잃은 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가면 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [18,"丑","巳","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 경쟁가능성이 있고, 타인의 압박이 발생하니 중지요망","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 길이 없으면 申방으로 100보 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 2시간 동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [19,"丑","午","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 지격이라 하여 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라. 길이 없으면 酉방으로 100보 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [20,"丑","未","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 변경되고,하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 戌방으로 100보 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [21,"丑","申","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 모든일이 경구 손모가 초래된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 병이 중하여 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [22,"丑","酉","子","▣ 子일, 또는 子방향으로 택하라","▣ 천관이라 하여, 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 길이 없으면 子방으로 100보 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [23,"丑","戌","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 추진,목전,근대사는 길하며, 원대, 원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 丑방으로 100보 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜이다","▣ 배신한다"],
    [24,"丑","亥","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사추진이 길하고, 특히 승부사는 큰 이익이 있다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 寅방으로 100보 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [25,"寅","子","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사 길하고, 특히 승부사는 큰 이익이 있게 된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 寅방으로 100보 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [26,"寅","丑","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 천격이라 하여, 위의 천재(天災)가 있게 된다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [27,"寅","寅","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 치료가 가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보 또는 未방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가라  잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [28,"寅","卯","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [29,"寅","辰","午","▣ 午일, 또는 午방향으로 택하라","▣ 지관이라 하여 도로, 주차, 하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [30,"寅","巳","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 진행은 소리(小利)하고, 여행,매매,승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 매매는 성립된다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가라, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [31,"寅","午","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 가능성이 있고,타인의 압박이 발생하니 중지가 요구된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 길이 없으면 申방으로 100보 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 단 2시간 동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [32,"寅","未","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 지격이라 하여 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가라. 길이 없으면 酉방으로 100보 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [33,"寅","申","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경되고,하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재. 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 戌방으로 100보 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [34,"寅","酉","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 병이 중하여 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內  凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [35,"寅","戌","子","▣ 子일, 또는 子방향으로 택하라","▣ 천관이라 하여, 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 길이 없으면 子방으로 100보 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [36,"寅","亥","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 목전, 근대사는 길하며, 원대, 원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 丑방으로 100보 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜이다","▣ 배신한다"],
    [37,"卯","子","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 목전, 근대사는 길하며, 원대, 원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 丑방으로 100보 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜이다","▣ 배신한다"],
    [38,"卯","丑","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사추진이 길하고, 특히 승부사는 큰 이익이 있다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 寅방으로 100보 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [39,"卯","寅","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 길이 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [40,"卯","卯","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 치료가 가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보 또는 未방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가라  잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [41,"卯","辰","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보 또는 申방으로 80보 가라","▣ 吉은 內,  凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [42,"卯","巳","午","▣ 午일, 또는 午방향으로 택하라","▣ 지관이라하여 도로,주차,하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [43,"卯","午","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 소리(小利)하고, 여행, 매매. 승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가라, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [44,"卯","未","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 경쟁가능성이 있고, 타인의 압박이 발생하니 중지","▣ 불가하다","▣ 불가하다","▣ 매매가 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 길이 없으면 申방으로 100보 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 단 2시간 동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [45,"卯","申","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 지격이라 하여 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가라. 없으면 酉방으로 100보 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [46,"卯","酉","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경, 하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 매매는 성립된다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 戌방으로 100보 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [47,"卯","戌","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 모든일이 손모가 초래된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [48,"卯","亥","子","▣ 子일, 또는 子방향으로 택하라","▣ 천관이라 하여 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 길이 없으면 子방으로 100보 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [49,"辰","子","子","▣ 子일, 또는 子방향으로 택하라","▣ 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 길이 없으면 子방으로 100보, 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [50,"辰","丑","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 추진, 목전, 근대사는 길하며, 원대, 원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 丑방으로 100보, 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜이다","▣ 배신한다"],
    [51,"辰","寅","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사 길하고, 특히 승부사는 큰 이익이 있게 된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 寅방으로 100보, 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [52,"辰","卯","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 길이 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [53,"辰","辰","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 치료가 가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보, 또는 未방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가라  잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [54,"辰","巳","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보, 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [55,"辰","午","午","▣ 午일, 또는 午방향으로 택하라","▣ 지관이라 하여, 도로, 주차, 하수등의 재해가 있게 된다","▣ 신불기원, 가무, 유흥등 으로 피난을 하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보, 또는 酉방으로 80보 가라","▣ 吉은가정 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [56,"辰","未","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 소리(小利)하고, 여행, 매매. 승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 매매는 성립된다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보, 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가라, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [57,"辰","申","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 타인으로 인하여 압박이 발생하니 중지가 요구된다","▣ 불가하다","▣ 불가하다","▣ 매매가  불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 길이 없으면 申방으로 100보, 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 2시간 동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [58,"辰","酉","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 지격이라 하여, 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가라. 없으면 酉방으로 100보, 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [59,"辰","戌","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경되고, 하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 매매는 성립된다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 戌방으로 100보, 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [60,"辰","亥","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [61,"巳","子","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [62,"巳","丑","子","▣ 子일, 또는 子방향으로 택하라","▣ 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 길이 없으면 子방으로 100보, 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [63,"巳","寅","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 추진, 목전, 근대사는 길하며, 원대, 원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 丑방으로 100보 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜이다","▣ 배신한다"],
    [64,"巳","卯","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 추진이 길하고, 특히 승부사는 큰 이익이 있게 된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 寅방으로 100보 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [65,"巳","辰","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 길이 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [66,"巳","巳","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 치료가 가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보 또는 未방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가라  잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [67,"巳","午","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [68,"巳","未","午","▣ 午일, 또는 午방향으로 택하라","▣ 지관이라 하여 도로, 주차, 하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [69,"巳","申","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 소리(小利)하고, 여행, 매매. 승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 매매는 성립된다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가라, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [70,"巳","酉","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 경쟁이 있고, 타인의 압박이 발생하니 중지가 요구된다","▣ 불가하다","▣ 불가하다","▣ 매매가  불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 길이 없으면 申방으로 100보 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 단 2시간 동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [71,"巳","戌","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가라. 없으면 酉방으로 100보 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [72,"巳","亥","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경되고, 하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 戌방으로 100보 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [73,"午","子","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경되고, 하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 매매는 성립된다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 戌방으로 100보 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [74,"午","丑","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 구득 불가하다","▣ 구득 불가하다","▣ 매매 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [75,"午","寅","子","▣ 子일, 또는 子방향으로 택하라","▣ 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 길이 없으면 子방으로 100보, 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [76,"午","卯","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 목전, 근대사는 길하며, 원대, 원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 丑방으로 100보 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜이다","▣ 배신한다"],
    [77,"午","辰","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사 길하고, 특히 승부사는 큰 이익이 있게 된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 寅방으로 100보, 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [78,"午","巳","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있다","▣ 내외 폐한 상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 길이 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [79,"午","午","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 치료가 가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면점을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보, 또는 未방으로 80 보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가라  잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [80,"午","未","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [81,"午","申","午","▣ 午일, 또는 午방향으로 택하라","▣ 도로, 주차, 하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보, 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [82,"午","酉","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 소리(小利)하고, 여행, 매매. 승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료 가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보, 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가라, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [83,"午","戌","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 타인의 압박이 발생하니 중지가 요구된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 길이 없으면 申방으로 100보 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 단 2시간 동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [84,"午","亥","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가라. 없으면 酉방으로 100보 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [85,"未","子","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가라. 없으면 酉방으로 100보 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [86,"未","丑","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경되고, 하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 매매는 성립된다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 없으면 戌방으로 100보 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [87,"未","寅","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 불가하다","▣ 불가하다","▣ 매매 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [88,"未","卯","子","▣ 子일, 또는 子방향으로 택하라","▣ 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 없으면 子방으로 100보, 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [89,"未","辰","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 목전, 근대사는 길하며, 원대, 원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 없으면 丑방으로 100보, 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜이다","▣ 배신한다"],
    [90,"未","巳","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사추진이 길하고, 특히 승부사는 큰 이익이 있게 된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 없으면 寅방으로 100보, 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [91,"未","午","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [92,"未","未","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 늦게 치료가 가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보 또는 未방으로 80보 가라","▣ 吉은 外, 흉은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가라  잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [93,"未","申","巳","▣ 巳일 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성하다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장으로 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [94,"未","酉","午","▣ 午일, 또는 午방향으로 택하라","▣ 도로, 주차, 하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보, 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [95,"未","戌","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 진행은 소리(小利)하고, 여행, 매매, 승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 매매는 성립된다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보, 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가라, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [96,"未","亥","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 경쟁가능, 타인의 압박이 발생하니 중지가 요구된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 없으면 申방으로 100보, 또는 亥방으로 80보가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 단 2시간 동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [97,"申","子","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 경쟁가능 타인의 압력이 발생되므로 중지가 요망된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 申방으로 100보, 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [98,"申","丑","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 충지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성림되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 지료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환이 발생되므로 중지가 요망된다","▣ 중심으로 가라 길이 없으면 酉방으로 100보 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실이 아니고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이기는데 2시간 동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면, 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [99,"申","寅","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경, 하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 매매는 성립된다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 없으면 戌방으로 100보, 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜다","▣ 배신한다"],
    [100,"申","卯","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 구득 불가하다","▣ 구득 불가하다","▣ 매매 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [101,"申","辰","子","▣ 子일, 또는 子방향으로 택하라","▣ 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 없으면 子방으로 100보, 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [102,"申","巳","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 추진, 목전, 근대사는 길하며, 원대, 원지사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 없으면 丑방으로 100보, 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜다","▣ 배신한다"],
    [103,"申","午","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사 길하고, 특히 승부사는 큰 이익이 있게 된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 없으면 寅방으로 100보, 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [104,"申","未","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [105,"申","申","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 치료가 가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보 또는 未방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가라  잡히지 않는다","▣ 개인다","▣ 진짜다","▣ 배신한다"],
    [106,"申","酉","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보, 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [107,"申","戌","午","▣ 午일, 또는 午방향으로 택하라","▣ 도로, 주차, 하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보, 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [108,"申","亥","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 소리(小利)하고, 여행, 매매 승부사는 좋다","▣ 원한만큼 획득한다","▣ 원하는것을  얻는다","▣ 목적달성한다","▣ 여아인데 산액이 있다","▣ 길운은 늦더라도 치료가능, 흉운은 치료가 어렵다","▣ 약속인은 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측행이면 찾을수 있다 길이없으면 未방으로 100보, 또는 戌방향으로 80보 가라","▣ 吉은 內에서 있게 되고, 凶은 外에서 올것이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 부하자나 아이건은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午 방으로 도망가면 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜다","▣ 배신한다"],
    [109,"酉","子","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 진행은 소리(小利)하고, 여행, 매매, 승부사는 좋다","▣ 원하는 만큼 획득한다","▣ 원하는 것을 얻는다","▣ 매매는 성립된다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 오는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라. 없으면 未방으로 100보, 또는 戌방으로 80보 가라","▣ 吉은 外, 凶은 內 에 있게된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가라, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜다","▣ 배신한다"],
    [110,"酉","丑","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 경쟁이 있고, 타인의 압박이 발생하니 중지가 요구된다","▣ 불가하다","▣ 불가하다","▣ 매매가  불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로가라, 길이 없으면 申방으로 100보, 또는 亥방으로 80보가라","▣ 吉은 內, 凶은 外가 된다","▣ 분실이 아니고 깊이놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 辰방을 등지면 이기는데 2시간 동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [111,"酉","寅","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가라. 없으면 酉방으로 100보, 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [112,"酉","卯","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 후일 변경되고, 하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 매매는 성립된다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 戌방으로 100보, 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 못찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 진짜다","▣ 배신한다"],
    [113,"酉","辰","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 구득 불가하다","▣ 구득 불가하다","▣ 매매 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장이 있어 못온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 未방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [114,"酉","巳","子","▣ 子일, 또는 子방향으로 택하라","▣ 한서 풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 절반은 얻는다","▣ 매매 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라 길이 없으면 子방으로 100보, 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [115,"酉","午","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 매사 길하고, 목전, 근대사는 길하며, 원대, 원지사는 부실","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 길운은 늦더라도 치료가 가능, 흉운은 치료가 어렵다","▣ 약속인은 오고 있는 중이며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 丑방으로 100보, 또는 辰방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이며 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가온다","▣ 진짜이다","▣ 배신한다"],
    [116,"酉","未","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사추진이 길하고, 특히 승부사는 큰 이익이 있게 된다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로 가라 길이 없으면 寅방으로 100보 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外 이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [117,"酉","申","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있다","▣ 내외 폐한상으로 중지가 요구된다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구 된다","▣ 중심으로 가라, 길이 없으면 卯방으로100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [118,"酉","酉","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 금액을 획득할수 있다","▣ 원하는 만큼 획득하게 된다","▣ 목적 달성된다","▣ 남아이나 산액이 있다","▣ 길운이면 늦더라도 치료가능, 흉운이면 치료가 어렵다","▣ 약속인은 지금 오고 있는 중이고 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라, 길이 없거든 辰방으로 100보, 또는 未방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것으로 못 찾는다. 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다, 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯방으로 도망가라  잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [119,"酉","戌","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있다","▣ 초대인은 지장이 있어 못 온다","▣ 길하다","▣ 좌측으로가라 길이 없거든 巳방향으로 100보, 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外에 있게 된다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [120,"酉","亥","午","▣ 午일, 또는 午방향으로 택하라","▣ 도로, 주차, 하수등의 재해가 있게 된다","▣ 신불기원 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반만큼 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금은 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 가라, 길이 없거든 午방으로 100보, 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [121,"戌","子","午","▣ 午일, 또는 午방향으로 택하라","▣ 도로, 주차, 하수등의 재해가 있게 된다","▣ 신불기원, 또는 가무, 유흥등으로 피난하는 것이 좋다","▣ 원하는 금액의 절반을 획득 할 수 있다","▣ 원하는 것의 반을 얻을 수 있다","▣ 매매 성립되나 조금 손해 보아야 한다","▣ 여아이다","▣ 경이 경해 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접하게 된다","▣ 오는 중이다","▣ 화환(禍患)이 발생하므로 중지가 요구된다","▣ 중심으로 곧장가면 찾을 수 있으나 午방으로 100보, 또는 酉방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기하게 된다","▣ 분실한 것 이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [122,"戌","丑","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 진행은 소리(小利)하고 여행, 매매, 승부사는 좋다","▣ 원한만큼 획득한다","▣ 원한만큼 얻는다","▣ 목적 달성한다","▣ 여아이나 산액이 있다","▣ 운이 좋으면 늦게라도 치료가능하나 흉운이면 치료가 어렵다","▣ 약속인은 오고 있는 중이다","▣ 부재, 또는 있어도 면접은 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측행이면 길을 찾을수 있는데 길이 없으면 未방으로 100보, 또는 戌방으로 80보이다","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞은 것이라 못 찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이기는데 2시간 동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午방으로 도망가면, 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [123,"戌","寅","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 타인의 압박이 발생되므로 중지 요구된다","▣ 불가하다","▣ 불가하다","▣ 불가하다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장으로 못 온다","▣ 길하다","▣ 좌측행이면 찾을수 있고 없으면 申방으로 100보, 또는 亥방으로 80보 가라","▣ 吉은 內에서 있게 되고, 凶은 外에서 올것이다","▣ 분실이 아니고 깊이 놓아두어 남쪽에서 곧 찾는다","▣ 어른일은 어렵다","▣ 辰방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未방으로 도망가면 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [124,"戌","卯","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 상(上)의 지재(地災)가 있게 된다","▣ 출행은 재화가 발생하고 중지가 요구 된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해 보아야 한다","▣ 여아이다","▣ 병이 경해 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리다가 면접하게 된다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요망된다","▣ 중심으로 곧장가면 찾을 수 있고 없으면 酉방으로 100보, 또는 子방향으로 80보 가가","▣ 吉은 가정, 凶은 신변에서 기한다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申방으로 도망가면 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [125,"戌","辰","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 계획은 휴일 변경되고, 하인(下人)으로부터 손상이 있다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 남아이니 산액이 있다","▣ 길운은 늦더라도 치료 가능하나 흉운은 치료가 어렵다","▣ 약속인은 지금 오고 있으며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측행이면 찾을수 있고 없으면 戌방으로 100보, 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞은 것이며 찾을수 없고 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉 방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [126,"戌","巳","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다.","▣ 불가함","▣ 불가함","▣ 매매 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장으로 못 온다","▣ 길하다","▣ 좌측행이면 찾을수 있고 없으면 亥방으로 100보, 또는 寅방으로 80보가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 깊이 높아두어 남쪽에서 곧 찾는다","▣ 어른 일은 어렵다","▣ 未방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌 방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [127,"戌","午","子","▣ 子일, 또는 子방향으로 택하라","▣ 한서풍설의 천재가 있게 된다","▣ 중도 좌절, 정신적 불안이 발생한다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 반을 얻는다","▣ 매매는 성립되나 조금 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리다가 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)서 발생하므로 중지가 요구된다","▣ 중심으로 가라 없으면 子방으로 100보, 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기하게 된다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [128,"戌","未","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 목전 근대사는 길하고, 원대ㆍ원자사는 부실하다","▣ 원하는 만큼 획득한다","▣ 원하는 만큼 얻는다","▣ 목적 달성한다","▣ 여아이며 산액이 있다","▣ 길운은 늦더라도 치료가능하나 흉운은 치료가 어렵다","▣ 약속인은 오고 있으며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 吉ㆍ凶간 불성한다","▣ 우측으로 가라. 없으면 丑방으로 100보, 또는 辰방으로 70보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞아 찾을수 없으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [129,"戌","申","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사추진길하고 특히 승부사는 큰 이익이 있다","▣ 구득 불가 하다","▣ 불가하다","▣ 매매 불성하다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장으로 못 온다","▣ 吉하다","▣ 좌측으로 가라 없으면 寅방으로 100보, 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두고 있어 남쪽에서 곧 찾늗다","▣ 어른의 일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [130,"戌","酉","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있게 된다","▣ 내외 폐한상으로 중지가 요구 된다","▣ 원하는 금액의 절반을 획득할수 있다","▣ 원하는 것의 절반을 얻는다","▣ 매매는 성립되나 조금 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료 가능하다","▣ 약속인은 방금 집에서 출발 했다","▣ 가서 조금 기다리면 면접한다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생하므로 중지가 요구된다","▣ 중심으로 가라 없으면 卯방으로 100 보 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기하게 된다","▣ 분실한 것이고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [131,"戌","戌","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한 불통의 상으로 수구가 요구된다","▣ 원하는 만큼 획득 할수 있다","▣ 원하는 만큼 얻는다","▣ 매매 성립은 된다","▣ 남아이나 산액이 있다","▣ 길운은 늦더라도 치료 가능, 흉운이면 치료가 어렵다","▣ 약속인은 오고 있으며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측으로 가라 길이 없으면 辰방으로 100보, 또는 未방으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 분실이 아니고 도적맞아 찾지 못하며 서북쪽이다","▣ 부하자나 아이 건은 어렵다","▣ 子방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯 방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [132,"戌","亥","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지개통의 상으로 하사도 대길하다","▣ 불가하다","▣ 불가하다","▣ 불성한다","▣ 남아이다","▣ 중병으로 치료가 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장이 있어 못온다","▣ 吉 하다","▣ 좌측으로가라, 길이 없으면 巳방으로 100보, 또는 申방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 깊이 놓아두고 있어 남쪽에서 곧 찾는다","▣ 어른의 일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [133,"亥","子","巳","▣ 巳일, 또는 巳방향으로 택하라","▣ 없음","▣ 천지 개통의 상으로 어떤 일도 대길하다","▣ 불가함","▣ 불가함","▣ 불성함","▣ 남아이다","▣ 중병으로 치료하기 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장으로 못 온다","▣ 吉 하다","▣ 좌측으로 가라, 길이 없거든 巳方으로 100보, 또는 申方으로 80보 가라","▣ 吉은 內, 凶은 外에서 올것이다","▣ 분실이 아니고 간직한 것으로 남쪽에서 곧 찾는다","▣ 어른일은 어렵다","▣ 丑방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 辰 방으로 도망가라, 잡히지 않는다","▣ 申시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [134,"亥","丑","午","▣ 午일, 또는 午방향으로 택하라","▣ 도로ㆍ주차ㆍ하수등의 재해가 있게 된다","▣ 신불기원, 또는 가무, 유흥등으로 피하는 것이 좋다","▣ 원하는 금액의 절반은 획들 할 수 있다.","▣ 절반을 얻을수 있다","▣ 성립은 되나 조금 손해 보아야 한다","▣ 여아이다","▣ 병이 가벼워 치료가 가능하다","▣ 약속인은 오고 있는 중이다","▣ 가서 조금 기다리면 면접하게 된다","▣ 초대인은 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요망된다","▣ 중심으로가면 길을 찾을수 있는데 길이 없으면 午 방으로 100보, 또는 酉방으로 80보이다","▣ 吉은 가정, 凶은 신변에서 기하게 된다","▣ 분실한 것이고 예상보다 늦게 찾으며 방위는 동쪽이다","▣ 동료사는 어렵다","▣ 寅방을 등지면 이기는데 단 2시간 동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 巳 방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [135,"亥","寅","未","▣ 未일, 또는 未방향으로 택하라","▣ 없음","▣ 매사 진행은 소리(小利)하고, 여행, 매매, 승부사는 좋다","▣ 원한만큼 획득한다","▣ 원하는것을 얻는다","▣ 목적달성한다","▣ 여아인데 산액이 있다","▣ 길운은 늦더라도 치료가능하나, 흉운은 치료가 어렵다","▣ 약속인은 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 초대인은 곧 도착한다","▣ 길흉간 불성한다","▣ 우측행이면 찾을수 있다 길이없으면 未방으로 100보 또는 戌방향으로 80보 가라","▣ 吉은 內에서 있게 되고, 凶은 外에서 올것이다","▣ 도적맞아 못찾는다 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 卯방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 午 방으로 도망가면 잡히지 않는다","▣ 戌시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [136,"亥","卯","申","▣ 申일, 또는 申방향으로 택하라","▣ 없음","▣ 타인의 압박이 발생되므로 중지가 요구된다","▣ 불가함","▣ 불가함","▣ 불성함","▣ 남아이다","▣ 중병으로 치료하기 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장이 있어 못 온다","▣ 吉 하다","▣ 좌측행이면 찾을수 있다. 길이 없으면 申방으로 100보, 또는 亥방으로 80보 가라","▣ 吉은 內, 凶은 外에 있다","▣ 분실이 아니고 잘 놓아 두어 남쪽에서 곧 찾을수 있다","▣ 어른일은 어렵다","▣ 辰방을 등지고 어기는데 단 2시간만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 未 방으로 도망가면, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [137,"亥","辰","酉","▣ 酉일, 또는 酉방향으로 택하라","▣ 상(上)의 지재(地災)가 있게 된다.","▣ 출행은 재화가 발생하고 중지가 요구된다","▣ 원하는 금액의 절반은 획득한다","▣ 구하는 것의 반쯤을 얻는다","▣ 성립은 되나 조금 손해 보아야 한다","▣ 여아이다","▣ 병이 경하니 치료가능하다","▣ 약속인은 방금 출발했다","▣ 가서 조금 기다리면 면접하게 된다","▣ 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가면 찾을수 있는데 길이 없으면 酉 방으로 100보, 또는 子방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기하게 된다","▣ 분실한 것이고 예상보다 늦게 찾으며 방위는 동쪽이다","▣ 동료사는 어렵다","▣ 巳방을 등지면 이기는데 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 申 방으로 도망가라, 잡히지 않는다","▣ 子시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [138,"亥","巳","戌","▣ 戌일, 또는 戌방향으로 택하라","▣ 없음","▣ 모든 계획은 후일 변경된다","▣ 원하는 금액을 얻는다","▣ 원하는 것을 얻는다","▣ 성립된다","▣ 남아이나 산액이 있다","▣ 길운은 늦더라도 치료가능하나 흉운은 치료가 어렵다","▣ 약속인은 지금 오고 있으며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 곧 도착한다","▣ 길흉간 불성한다","▣ 우측행이면 찾으나 없으면 戌방으로로 100보, 또는 丑방으로 80보 가라","▣ 吉은 外, 凶은 內에서 있게 된다","▣ 도적맞아 찾을수 없으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 午방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 酉 방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 진짜이다","▣ 배신한다"],
    [139,"亥","午","亥","▣ 亥일, 또는 亥방향으로 택하라","▣ 없음","▣ 좁은 문이고 불통하여 어려우며 손모가 초래된다","▣ 구재가 불가하다","▣ 불가함","▣ 불성함","▣ 남아이다","▣ 중병으로 치료하기 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대인은 지장이 있어 못 온다","▣ 吉 하다","▣ 좌측행이면찾을수 있는데, 길이 없거든 亥방으로 100보, 또는 寅방으로 80보 가라","▣ 吉은 內에서 있게 되고, 凶은 外에서 올것이다","▣ 분실이 아니고 간직한 것으로 남쪽에서 곧 찾는다","▣ 존장사는 어렵다","▣ 未방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 戌 방으로 도망가라, 잡히지 않는다","▣ 寅시부터 비가 온다","▣ 헛 소문이다","▣ 충성한다"],
    [140,"亥","未","子","▣ 子일, 또는 子방향으로 택하라","▣ 한서풍설의 천재가 있게 된다","▣ 중도좌절, 정신적 불안에 발생된다","▣ 원하는 금액의 절반을 획득한다","▣ 원하는 것의 반 정도 얻는다","▣ 매매 성립되나 조금 손해 보아야 한다","▣ 여아이다","▣ 병이 가벼워 치료가능하다","▣ 약속인은 집에서 방금 출발했다","▣ 가서 조금 기다리면 면접한다","▣ 오는 중이다","▣ 화환(禍患)이 발생되므로 중지가 요구된다","▣ 중심으로 곧장가면 찾을수 있는데 없으면 子방으로 100보, 또는 卯방으로 80보 가라","▣ 吉은 가정, 凶은 신변에서 기하게 된다","▣ 분실한 것으로 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 申방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 亥 방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [141,"亥","申","丑","▣ 丑일, 또는 丑방향으로 택하라","▣ 없음","▣ 목전, 근대사는 대길, 원대, 원지사는 부실하다","▣ 원한만큼 획득한다","▣ 원하는 것을 획득한다","▣ 목적달성한다","▣ 여아이나 산액이 있다","▣ 길운이면 늦더라도 치료가 되나 흉운은 치료가 어렵다","▣ 약속인은 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 곧 도착한다","▣ 吉ㆍ凶간 불성한다","▣ 우축행이면 찾을수 있고, 없으면 丑방으로 100보, 또는 辰으로 80보 가라","▣ 吉은 外, 凶은 內에서 있게 된다","▣ 도적맞아서 못 찾으며 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 酉방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 子방으로 도망가라, 잡히지 않는다","▣ 辰시부터 비가 온다","▣ 진짜이다","▣ 배신한다"],
    [142,"亥","酉","寅","▣ 寅일, 또는 寅방향으로 택하라","▣ 없음","▣ 매사 추진이 길하고, 특히 승부사는 큰 이익이 있다","▣ 불가함","▣ 불가함","▣ 불성함","▣ 남아이다","▣ 중병으로 치료하기 어렵다","▣ 약속인은 아직 집에 있다","▣ 상대는 집에 있어 만나게 된다","▣ 초대받은 이는 지장으로 못 온다","▣ 吉 하다","▣ 좌측으로 가라, 길이 없거든 寅방으로 100보, 또는 巳방으로 80보 가라","▣ 吉은 內, 凶은 外이다","▣ 분실이 아니고 간직한 것으로 남쪽에서 곧 찾는다","▣ 어른일은 어렵다","▣ 戌방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 악성인이다. (천반 子 下지지로 다시 보라)","▣ 丑 방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 헛 소문이다","▣ 충성한다"],
    [143,"亥","戌","卯","▣ 卯일, 또는 卯방향으로 택하라","▣ 위의 천재(天災)가 있게 된다","▣ 내외 폐한 상으로서 중지가 요구 된다.","▣ 원하는 금액의 절반은 획득한다","▣ 원하는 것의 반 정도를 얻는다","▣ 매매 성립은 되나 조금 손해 보아야 한다","▣ 여아이다","▣ 병이 경하여 치료가 가능하다","▣ 약속인은 집에서 방금 출발 했다","▣ 가서 조금 기다리면 면접하게 된다","▣ 오는 중이다","▣ 화환이 발생하므로 중지가 요망된다","▣ 중심으로 곧장가면 찾을수 있는데, 없으면 卯방으로 100보, 또는 午방으로 80보 가라","▣ 吉은 가정, 凶은 신변에 기한다","▣ 분실이 아니고 예상보다 늦게 동쪽에서 찾는다","▣ 동료사는 어렵다","▣ 亥방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 선인이다. (천반 子 下지지로 다시 보라)","▣ 寅 방으로 도망가라, 잡히지 않는다","▣ 午시부터 비가 온다","▣ 반은 맞고 반은 틀리다","▣ 회의적이다"],
    [144,"亥","亥","辰","▣ 辰일, 또는 辰방향으로 택하라","▣ 없음","▣ 폐한불통의 상으로 수구가 요구된다","▣ 원한만큼 획득한다","▣ 원한만큼 얻는다","▣ 목적 달성한다","▣ 남아이나 산액이 있다","▣ 길운은 늦더라도 치료가능하나 흉운은 치료가 어렵다","▣ 약속인은 지금 오고 있으며 곧 도착한다","▣ 부재, 또는 있어도 면접을 못한다","▣ 곧 도착한다","▣ 吉凶간 불성한다","▣ 우측행이면 길을 찾는데 없으면 辰방으로 100보, 또는 未으로 80보 가라","▣ 吉은 外, 凶은 內에 있게 된다","▣ 도적맞은 것이어서 찾을수 없고 방위는 서북쪽이다","▣ 부하자나 아이건은 어렵다","▣ 子방을 등지면 이긴다 단 2시간동안만 효력이 있다","▣ 보통인이다. (천반 子 下지지로 다시 보라)","▣ 卯 방으로 도망가라, 잡히지 않는다","▣ 개인다","▣ 진짜다","▣ 배신한다"]
  ]


  const VERSION001_CHILDBIRTH_BIRTHESDATA1 = [
    [1,"甲子","남의 호의를 감사할 줄 알고, 고지식한 면이 있어 주위나 식구들을 불편하게 하기도 한다. 일에 열중하고 결과를 중시하여 음악ㆍ체육ㆍ의사ㆍ과학자로 성공하는 사람이 많다.","배우자 복이 박해 행복하지 못한 경우가 많다. 평범하지 않고 전문직 종사자가 많다. 인수격은 예술에 소질이 많고, 재ㆍ관격은 성격이 단정하다. 식상ㆍ비겁격은 고집이 있다."],
    [2,"乙丑","신강자는 성질이 완벽을 좋아하고 적극적 이지만 남들과 불화가 많고 처와 자식에게도 다정하지 못하고 처의 활동을 싫어하고 거친성품이고, 신약자는 날카롭다.","자신은 깔끔하고 완벽한것 같지만 허점과 모순이 많고 부군과 자식에게도 자기 주장을 강요하여 가정이 불안하다."],
    [3,"丙寅","적극적이고 총명하지만 화를 잘 내고 식구를 원망하는 버릇이 있고 폭력적이며 너무 총명하여 학업을 중단하는 사례도 있다.","작은 일도 감사하는 총명함이 있으나 급한 성질 때문에 일을 망칠때도 있다. 신강자는 신경질이 많아 식구들을 불편케 해도 뒤 끝은 없다. 정관격은 온순하고, 신약은 의부증으로 가정이 불안하다."],
    [4,"丁卯","성격은 원만한데 세밀하지 못해 오해도 받지만 예지력이 있어 흉조를 미리 알때도 있고 가정 적이고 처세가 좋아 적이 없어 사람들의 환영을 받는다.","현모 양처로 효자를 두기도 하지만 실망하여 화가나면 극단적인 면도 있다. 신기가 발동하면 엉뚱한 행동을 한다. 신강자는 조용하나 극단적일 때도있다. 신약은 날카로우나 현모양처이다."],
    [5,"戊辰","폭넓은 천재성이 사회를 너무 쉽게 생각하여 학업을 게을리해 지장이 많고 신분에 맞지않는 사회사업도하며 고집이세 가정이 원많지 못한 사례도 많다.","활발한 성격이다. 기발한 수완으로 일을 벌려도 결실은 적다. 때로는 독신을 생각 할 때도 있다. 고집 때문에 부군과 자식이 외면하여 말년은 고독하고, 배신도 잘 당하는 경향이 있다."],
    [6,"己巳","미성(美聲)의 소유자로, 다정 원만하여 주위의 호감을 사고 약간 가벼우나 좋은 가정을 이룬다.","자상하고 인내 하지만 목청이 커 오해로 가정이 불화 할 때도 있다. 신강자는 활동성이 강해 피곤하게 살지만 말년은 행복하다. 신약은 현모양처로 가정적이다."],
    [7,"庚午","예의가 바르지만 처의 처신이 정숙하지 못하면 의처증이 발동하여 폭력적일 때도 있으나, 대개 모범 가장이 많다.","온유한 현모양처 상이지만 남성 공포증으로 소심한 자는 독신자도 있다. 그러나 결혼하면 현모양처로 행복한 결혼생활로 자녀를 효자로 키운다."],
    [8,"辛未","온후한 성격이지만 때로는 고집이 세 남을 힘들게 하고 자학적 일때도 있다. 그러나 의리있고 원만한 가정적인 사람이 많다.","현모양처의 상으로 매사 감사할 줄 아는 성품으로 남편과 자식복이 있어 행복을 누리는 자가 많다. 따라서 흉살이 없고, 대운에서 흉살을 만나지 않으면 좋다. 일시적인 고난은 있으나 말년은 행복하다."],
    [9,"壬申","적극 활발한 성격이나 때로는 옹고집이라 환영도 받고 대립도 하는 양면성이 있고 가정적인 자와 의처증인 자도 있다.","현모양처로 가정에 충실하고 효자를 두지만 신기가 있어 현몽이 맞을 때가 많고 그 신기가 발동해도 받아들이지 않으면 풍파가 많아 고통 당한다. 받아 들이면 풍파는 없다."],
    [10,"癸酉","계산적이고 냉혹한 면도 있으나 자기의 노력으로 일생을 평탄하게 산다.","신기가 있어 현몽을 받아 위기를 넘길때가 많고 신강자외에는 현모양처로 효자를 두고 남편의 지극한 사랑으로 행복을 누린다. 그러나 약간의 예외도 있다."],
    [11,"甲戌","성격이 온유한 듯하나 고집이 너무있어 남의 비난을 받을때가 많지만 사회활동은 활발하고 가정에서는 무뚝뚝 하고 멋이 없다.","외향적이고 자기중심이라 부군과 자식에게 외면 당하고 가정불화가 많고 남이 비난하면 참지 못 하고 분노한다. 신강자는 결혼하면 귀자도 있다. 신약자는 평범하다."],
    [12,"乙亥","방탕기가 있어 결혼이 늦고 가정파탄이 있다. 여자 관계를 조심하지 않으면 고난이 따른다.","마음씨 고운 요조숙녀지만 배우자 문재로 걱정이 많다. 자식을 지성으로 키워 효자를 둔다. 신강자는 재복 있는 자가 많고, 신약자는 노고가 많다."],
    [13,"丙子","세련되고 바람기가 있어 여인들의 호감을 사지만 여자에 대한 두려움 때문에 결혼 후에도 여자를 이해하지 못하고 폭력적이다.","요조숙녀이지만 남자 공포증이 있어 부부 성생활까지도 두려워 한다. 자식은 효자다. 신강자는 가정이 불안정해도 생활력은 강하다. 신약은 재복이 있어 안정적이다."],
    [14,"丁丑","냉정하고 논리적인것 같아도 강유를 겸비하여 불의를 싫어하고 활동적이다. 공직자는 청렴하고, 가정은 불길하다.","허욕은 많은데 노력에 비해 결과가 적고 감정보다는 논리를 앞세운다. 가정보다는 사회적이고 예ㆍ체능 종사자가 많고 자식운 보다는 배우자 운이 박하다."],
    [15,"戊寅","천재적이고 예의 바르다. 그러나 여자에 약한 자도 있고 웅지를 펴지못함을 한스러워 하지만 대운에서 호운을 만나면 대성 할수도 있다.","요조숙녀이나 남편을 두려워 하든가 무시하는 자도 있다. 그래서 부부 생활이 원만치 못하고 애로가 많다. 교육열이 높아 고관 효자를 두는 수도 있고, 무슨 일이나 열성적이다."],
    [16,"己卯","근본은 착한데 착하지 않은듯 하고, 알면서도 모르는 듯 기이한 성품이다 배우자를 두려워 하고 또는 폭력적인 성품을 지닌자도 있다.","쓸대 없는 비밀이 많고 배우자와 사소한 문제가 많고, 부부생활에 우여곡절이 많은 특징이 있다. 남녀모두 독신자가 많다."],
    [17,"庚辰","성질이 적극적이고 재능이 다양해도 기회가 없어 제데로 발휘 할수없고 건달끼가 있어 유치하나 가정에 충실하다.","활동은 많아도 소득은 별로없다. 자녀 교육은 열성이라 자식이 효성스워 행복한 운명이다. 식상격의 여인은 남편이 폭력적인 자도있다."],
    [18,"辛巳","온후한 성품인대 결혼운이 박해서 독신이 많고 인생 행로가 빗나가 노고가 많으며 소득이 적다.","온하하고 지성 스러우나 결혼운이 박해 독신이 많고 남자기피 증이있어 여인의 기뿜을 누리지 못하나 자손의 기뿜은 있다. 신강자는 박색이다."],
    [19,"壬午","보수와 진보의 양면성이 있고 활달한 것 같은대 마음속의 교만 때문에 남을 무시하는 경향이 있어 타인과 불화하고 여인에게 관대할줄 모른다.","아집이있고 공격적이며 남을 불편케 하고 남편과 자식도 무시하는 독선 때문에 부부불화 하고 말년에는 자식도 외면한다. 신앙은 없으나 가끔 사이비 종교 때문에 남의 질시를 받는다."],
    [20,"癸未","세련미 때문에 선망의 대상이고 신강자는 사회생활이 적극 대담하고 가정에는 소심하여 소인배가 많다.","여인은 장점이 많아 행복한 자가 많다."],
    [21,"甲申","온후하고 활달한 성격 때문에 금전 거래에 손해가 많고 여성들의 호감을 산다. 처에게 관심이 지나쳐 의처증 자도있다. 자녀 교육은 열성이라 효자를 두는 사람이 많고 말년은 무난하다.","현모 양처가 많고 남성 공포증이 있어 신강자는 남자 관계가 여의치 못한 자도있다. 신약은 행복한 운이고, 식상격은 폭력 남편도 있다."],
    [22,"乙酉","수려하고 세련된 용모 때문에 타인의 호감을 사지만 월급자가 많고 예술적인 기질이나 처복이 약해 고난을 격기도 하고, 혹자는 의처증에 폭력적 이기도 하다.","배우자 복이 없어 남편의 바람과 폭력으로 결혼생활이 순조롭지 못하고 남성 공포증 때문에 자포자기 타락하는 자도 있다. 평탄 생활자는 드물다. 자식은 잘 키워 말년은 행복이 있다."],
    [23,"丙戌","과격하고 승부욕이 강해 잘 따지고 번거롭게 해 주변에서 싫어한다. 그러나 처자에 대한 애정과 인정이 많아 안정된 가정을 이룬다. 약간 주책이다.","고집이 세어 남을 불편하게 하고 노력은 많은데 소득이 적고 뜻데로 되는일도 없다. 결혼운이 나빠 궁합을 잘 보고 결혼해야 한다. 심성은 착한데 불운한 자가 많다."],
    [24,"丁亥","고집 불통이라 되지않을 일도 억지로해 실패수가 많고 반성할줄 모르면서 스스로 불운하다 생각하고 남을 불편케 하고, 여성 들에게는 예의바른 호인으로 보여 호감을 산다.","고집이 세고 남 앞에 나서기 좋아하고 목소리는 대개 갈라진 소리이고 불운한 편이 많고 예ㆍ체능 방면에 성공한 자도 간혹 있다. 유치하고 여자답지 못해 불운자가 많고 단, 정관격은 복이있다."],
    [25,"戊子","천재적인 아이디어 맨으로 성공한 자도 많지만 다혈질에 남을 깔보는 기질 때문에 남들과 불화가 많고 처의 활동을 싫어하고 가정을 등한시 해 말년에 처자가 외면한다.","고집이 세고 대인 관계가 억망이고 남편과 자식에게도 억지를 부려 가정도 원만치 못하고 성겨이 성격이 편협해 신앙도 없지만 사이비 종교에 빠지면 말릴 수 없다."],
    [26,"己丑","처자에 무관심해 가정이 불화한 자가 많고 호인의 풍모라 사회 생활은 별 문제가 없다.","자기 중심이며 사소한 일로 화를 잘내고 목청이 높아 부부 불화하고 한번 오해하면 잘 풀지않고 신앙이 없고 자식에게도 억지 강요가 많아 노후에 자식이 싫어하고, 정관격은 무난하다."],
    [27,"庚寅","남성적이고 적극적이며 자존심도 강하다 여자에게는 보수적이라 활동적인 여성은 배우자로 기피한다. 사회적으로는 원만하여 출세자가 많고 청고한 기질을 지니고 있다.","깔끔한 성픔에 자기 약점은 감추고 자존심과 질투심이 매우강해 남편과 자식에게 자기 방식을 고집하여 가정이 불화하고 초혼때는 원만해도 중년 이후는 불만이 많고, 가정이 불안하다."],
    [28,"辛卯","냉정하고 자존심이 강하며 활동이 저극적인 사람으로 사회생활은 원만한데 처의 사회 활동을 싫어해 가정이 불화 할 수 있다. 조용한 여자가 적합하다.","성질은 활달한것 같으나 냉정하고 독점욕이 강해 매사를 자기 위주로 행사하여 남과 불화가 많고 직선적이며 남의 단점을 보면 참지 못해 가정 생할이 불안하다. 독신녀가 많다."],
    [29,"壬辰","신사같이 보이지만 과격하고 소심하며 상관없는 일에 참견하기 좋아하고 여성을 바로 보지 못하는 의심 때문에 의처증이 발동하면 폭력적일 때도 있다.","명분을 중시하는 거친 성격으로 여성답지 못해 남편의 사랑을 받지 못해도 개의치 않는다. 자식을 극성으로 길러 효자는 있으나, 너무 지나쳐 결혼생활을 실패히는 자도 많다."],
    [30,"癸巳","진보적이고 활달하지만 처ㆍ자식에게는 너무 보수적이라 존경받지 못한다. 특히 겨울생은 잔인성이 극렬하다.","자신이 지나치게 깔끔한 성질이라 남의 작은 실수도 용납하지 못하고 남편과 자식도 자기식대로 하지 않으면 분노하고 심할때는 졸도까지 한다."],
    [31,"甲午","활달하고 예의 바르지만 때로는 과격하고 잔인하다. 처자에 대한 사랑이 지나쳐 식구들을 불편하게 하기도 하는 주책이다.","남성같은 허스키한 목소리의 소유자가 많고 성품이 과격하여 화가나면 폭력적일 때도 있다. 배우자 운이 박하여 독신이 많고 가정이 불안하여 말년이 불행하다."],
    [32,"乙未","활달한것 같아도 냉정하고 과격하여 처의 사회 활동을 싫어하고 자기의 의사가 관철이 안될때는 폭력적이 되기도 한다. 화목한 가정 같아도 가족의 불만이 크다.","활달한 기질이라 가정보다는 사회활동에 더 적극적이고 고집이 있어 자기잘못을 인정하지 않는 기질 때문에 독신이 많고 부부 화합이 드물다."],
    [33,"丙申","활달하고 진취적이나 자존심이 강하여 자기 뜻데로 안되면 심하게 화를 내는 고집 통이다.","자존심이 강한 고집 통이다 자신의 실수는 인정하지 않고 오이려 화를 내고 목적을 위해서는 거짓말도 불사한다. 남편과 자식에게도 자기식으로 하기 때문에 말년이 불행하다.  정관격은 예외이다."],
    [34,"丁酉","자존심이 강하고 적극적이지만 처의 외부 활동을 싫어하고 종교관이 비뚤어져 신앙심이 없다.","적극적이고 외향적이라 사회활동에 신경쓰며 자식과 남편의 의견을 무시하고 자기 주장만 관철하는 고집 때문에 가정 불화가 많고, 독신자도 많다. 신약자는 안정적이다."],
    [35,"戊戌","적극적이고 기발한 아이디어가 많고 이론적인 성격때문에 발상이 자나쳐 실패수가 많고 바람기가 많아 여자 관계가 복잡해 실패를 가중한다.","성격이 이상하여 남편과 자식도 무시하는 기질이 있다. 가정이 불안하고 또한 신앙심이 없으나 간혹 사이비종교에 심취 되기도 한다. 매우 독선적이다."],
    [36,"己亥","적극적이고 진취적이므로 사회적으로는 성공할 수 있으나 가정에서는 지나치게 보수적이라 처의 사회 활동을 용납하지 않는 특징이 있고 가정에서는 매우 권위이적인 사람이 많다.","자존심이 강하고 갈끔한 성격이라 남의 실수를 용납하지 않는다. 목청이 크고 강하며 부부 불화가 많고 이혼하는 예도 많다. 특히 무능한 남편을 만난다. 말년에는 자식이 외면한다."],
    [37,"庚子","온유한 여성적 기질에 예의 바르나 약간 주책이고 여자에게 지나친 관심과 애정 때문에 자신이나 여자를 불편하게 하는 경우도 있다. 일생이 무난하다.","수다스럽고 자기 꾀에 잘넘어가 고생하는 예도 있다. 가정은 평온하고 효자가 있어 말년은 행복하다."],
    [38,"辛丑","냉정 하면서도 원만한 성품으로 처세가 유연하여 타인의 환영을 받으며 사회적으로 원만한데 냉정한 성품 때문에 가까운 사람들이 질시한다.","현모양처의 유연한 상이나 움직이기 싫어해 비만이 많고 채면을 중시한다. 결혼 생할은 평탄치 못하나 말년은 효자덕에 편안할 수 있고 현실에 순응하는 편이다."],
    [39,"壬寅","굴곡이 많다 심성이 착하여 가정적이지만 엉뚱한 일로 고난을 당하기도 하고 앞날을 예측 할 수 없는 사람이 많다. 일생이 순탄한 자도, 불행한 자도있다.","심성이 착하고 성실하지만 불운한 자가 많고 신앙심이 두터운 자도 있고 행복한 자도 불행한 자도 있어 양면성이 많다. 참으로 굴곡이 많다."],
    [40,"癸卯","온후 유덕한 성품으로 순박하고 진취적이다. 올바른 교육 관으로 자식에 대한 교육열이 대단하고 약간 주책이기는 하나 남을 배려 할 줄 아는 착한 심성이다.","착하고 상냥하며 계산적으로 가정을 잘 꾸리는 반면 아량이 좁다. 자녀를 잘 길러 말년이 행복하다. 신강자는 지나치게 계산적이고 잔인한 면도 있다. 신약자는 애교가 많고 평탄한 일생이다."],
    [41,"甲辰","예측이 안되는 안하무인격으로 남울 우습게 보는 막무가내 이지만 활동은 능동적 이라 사회 생활은 성공적인 편이나 결혼 생활은 불행하다.","아집이 강하여 타인의 의사를 무시해 남들과 불화할 때도 많고 하고자 하면 수단 방법을 가리지않고 거짓말도 서슴치 않는다. 성격 때문에 결혼과 자식운이 박하여 말년이 불운한 사람이 많다."],
    [42,"乙巳","활발하고 고집이 센 성격에 자존심이 강하면서도 남을 배려하는 착한 심성의 소유자이다. 매사 무난하고 가정적인 사람 이지만 때로는 외곬수 적인 고집 때문에 불행을 자초하는 수도 있다.","활동이 왕성한 남성적인 기질 때문에 배우자 운이 약하여 결혼운이 평탄치 못하다. 노력에 비해 소득이 적고 불운한자가 많다."],
    [43,"丙午","기질은 천재인데 아집 때문에 타인을 무시하는 경향이 있고 처자에게 매정하다.","식신 상관격은 남을 무시하는 버릇 때문에 평탄치 못하고, 신강자는 남성적인 기질로 사는 자가 많고, 정관격은 부귀 한 자도 있지만 고집이 외골수라 삐뜰어진 신앙인도 있다."],
    [44,"丁未","여성적인 성겨과 면모를 가진 사람이라 사회적으로 소극적이고 자녀 교육에는 관심이 많아 효자를 둔다.","자상하고 섬세한 현모 양처이다. 희생적인 열성으로 자녀를 효자로 키운다."],
    [45,"戊申","천재성이 지나쳐 예측 불허한 행동도 하며 남에게 배풀기를 좋아한다. 자녀 교육에는 사랑과 열성을 다하는 희생정신을 발휘한다.","누구를 한번 사랑하면 일편단심이다. 현모양처이고 활동적인 팔방 미인이다. 그러나 신기가 발동하면 큰 무당도 가능하고, 남편과 원만하기 어렵다."],
    [46,"己酉","유연하고 선한 심성으로 종교적인 기질이 있고 가정 생활이 원만하나 약간 주책이다.","아름다운 목청을 타고 났으며 온화한 마음씨를 가졌다. 청년기는 약간 불행해도 말년은 행복하다."],
    [47,"庚戌","활발하고 적극적이고 정치에 관심이 많다. 도박을 즐길때도 있지만, 매사 지성을 다한다.","온유하고 활동적이다. 결혼 후에도 사회 활동이 많아 불화한 자도 있다. 자식에게는 희생정신이 강하고 자상한 어머니가 된다."],
    [48,"辛亥","냉정하고 치밀한 성격으로 매사 계산적이며 사회생활은 소극적이다. 그러나 가정생활은 원만하게 꾸려나간다.","냉정하고 계산적으로 부부 불화가 심해 남편과 별거하는 사람도 많고, 결혼운이 박해 독신도 많다. 때로는 불륜에 빠지는 경우도 많다."],
    [49,"壬子","적극적이고 능동적이지만 고집이 세고 보수적이다. 여자의 사회 활동을 싫어하고 희생 정신이 없다.","자기 주장이 강하고 이기적이며 자기 모순은 인정치 않고 매사 논리적 이다. 남들과 불화가 많고 끈질긴 노력형이다."],
    [50,"癸丑","사교적으로 보이지만 사회생활에는 소극적인 면이 있고 가정에 관심이 지나쳐 오히려 의처증을 유발한다.","전형적인 어머니상으로 남편에게는 순종하고 자식에게는 자상한 어머니이나 결혼 생할은 불운하여 독신자도많고 신앙심은 깊고 인내는 강하지만 때로는 편협 할 때도 있다."],
    [51,"甲寅","고집불통의 외곬수이고 활동력이 왕성해 성공하는 사례도 많지만 그 고집 때문에 남들과 불화하고 반성할 줄 모르는 단점이 있는 것이 흠이 된다.","성격이 거칠고 이상하여 남들과 잘다투고 결혼에 실폐한 사람도 많고 처녀로 일생을 보내는 여인도 있고 한마디로 나쁜 것을 다갖춘 성격 파탄인 독신자가 많다."],
    [52,"乙卯","활달한 반면 고집과 자존심이 강하여 소신을 굽히지 않으며 조화를 중시하고 사회생활을 우선하고 가정생활은 둘째다.","온유하고 처세는 잘해도 자기 주장이 너무 강하여 남을 무시하는 수가있고 성실하고 끈기있게 목표를 달성한다. 그러나 편향된 사고로 인하여 자녀 교육이 빗나가 말년이 불행하다."],
    [53,"丙辰","천재적인 기질을 타고나 매사 적극적이고 활발하여 큰 성공자가 많다. 행동이 부산하고 거칠 때도 있고 주사를 부리고 폭력적일 때도 있다.","거칠고 강한 성품으로 남편을 내조하여 성공으로 이끌기도 하고 자기 주장을 관철하는 활동력이 강해 일생의 직업을 가지는 수도 있다. 기질이 너무 강해 독신자와 불행한 자도 많다."],
    [54,"丁巳","점잖하고 신중한 사람이다. 웅지를 품고 끈기있고 인내심도 강하지만 때로는 폭발하면 끝장을 본다.","조용하고 차분하게 보이지만 능동적이고 권위적인 성질을 가져 외부로 표현은 잘하지 않지만 자기 주장이 강해 남을 무시할 때도 있다."],
    [55,"戊午","능동적이고 적극성으로 큰 일을 잘해내는 천재성이 있어 주위 사람들의 환영도 받지만 적도 많아 불운을 초래 할 때도 있다. 사회 생활과 가정 생활도 잘해낸다. 좀 인색한게 흠이다.","원만하고 자상해 보이지만 성급한 면이 있고 예ㆍ체능 방면은 천재성이 있어 성공하는 자가 있고 대부분 무난한 성격으로 행복하게 산다."],
    [56,"己未","원만한 성격에 남성다운 사람이고 이상이 높고 처세가 좋아 남의 호감을 산다. 그러나 능력은 좀 부족하다. 남을 지휘할때는 신중해야 한다.","고집통에 자존심이 강하고 남의 말을 귀담아 듣지 않는다. 목소리가 커 남의 오해를 산다. 고집이 많아 불운한 사람도 많다."],
    [57,"庚申","고집이 있고 자존심이 강하다. 세밀하고 침착하여 큰일도 능히 해낼 수 있다. 독선적인 면도 있고 외골수다.","고집통에 자존심이 강해 부군을 무시하는 경향이 있어 가정불화가 많고 성질이 불같아 한번 고집을 부리면 설득이 안되고 신앙심도 없고 이기적이며 괴상한 성격자가 많다."],
    [58,"辛酉","외유내강의 성격 소유자이다 치밀히 계산하여 목표 달성을 위하여 밀고 나간다.","냉정하고 치밀한 성품으로 누가 불편하게 하면 과격해지기 때문에 결혼 생활이 불운한 자가 많다."],
    [59,"壬戌","사치를 좋아하나 성품이 순수하여 착한 사람이 많지만 여인에 대한 공포심이 있어 의처증세도 있으며 속으로만 앓고 내색하지 않는다 가정 생할이 불우한 사람이 많다.","계산적인 성격으로 자기위주의 자이다. 불리한 일은 의리나 약속이 필요없다. 그러나 배우자에게는 성실하다. 신앙심이 없고 자식복이 없어 말년이 불행한 경우가 많다."],
    [60,"癸亥","냉정하고 세밀한 성품이지만 적극적이고 능동적으로 활동한다. 그러나 가정에서는 자기 중심이기 때문에 가정이 불화하다. 자존심이 강해 독선적인 사람이 많다.","고집이 세고 계산적이며 매사를 자기 편리한 쪽으로 생각하고 불리하면 의리도 약속도 없다. 그러나 남편에게는 잘한다. 자식복이 없어 말년이 불운하다."]
  ]
  const VERSION001_CHILDBIRTH_BIRTHESDATA2 = [
    [1,`甲子`,1,`甲子`,`(집안에 손모건이 있고, \n타인에 의해 금전의 암손이 있으며 
  여인사에 관련되어 있다.)`,`● 년주나 월주에 庚이 없고 辛ㆍ丑ㆍ酉ㆍ午가 있으면 타향에 나가 독자적으로 富貴해진다.`,`● 년ㆍ월지에 나란히 寅이 있게되면 대ㆍ세운 申ㆍ酉운에 부자가 되었다가 쇠퇴하게 된다.`,`● 년ㆍ월지가 子ㆍ亥ㆍ未가 되면 대운 酉 운에 貴해진다.   이상ㆍ하 모두 (출산 O)`,`● 관살혼잡 없이 정관격이 되면 大貴하게 되고, 巳ㆍ午ㆍ未 월 생자는 평범하다.`,`● 丑월, 巳월 생은 흉사(凶死)하게 된다.   (출산 X)`,0,0,0],
    [2,`甲子`,2,`乙丑`,`(귀인사에 관계되고 上人과 금전사의 경위를 밝힌다.)`,`● 처는 현명하고 자식은 귀하게 된다.   (출산 O)`,`● 子월 생은 凶이 많게 된다.   (출산 X)`,`● 봄철 출생자는 一生 파재사가 있다.   (출산 X)`,`● 金神이 있으면 귀인이 못된다.`,`● 가을철 출생자는 최고 통수권자를 모시는 직책을 얻게된다.,그러나, 권력은 크지만 직위는 높지 않다. 특히 丑월 생 大吉 하다.   (출산 O)`,0,0],
    [3,`甲子`,3,`丙寅`,`(상 받는 일 등의 명예사가 있고 입신사 로 원행ㆍ이동한다.)`,`● 년주나 월주에 庚이 없고 辛만 있으면서 봄ㆍ여름 출생자는 관직이 최고에 이를것이다.`,`● 午월 생은 대운 東ㆍ北운에 잠간의 직위에 오른다.`,`● 申월 생은 中ㆍ上將의 지위를 얻는다.   이상ㆍ하 모두 (출산 O)`,`● 만약 지지에 戌 이 있거나 申子辰 水局 을 이루면 평범한 삶이다.`,`● 년지월지에 같이 子나 寅이 있으면 大貴자이고 단, 근무지가 많이 바뀌는 괴로움이 있다.`,0,0,0],
    [4,`甲子`,4,`丁卯`,`(上人ㆍ처첩이 불리하고, 가정에 저해가 있고, \n上ㆍ下간 무례하고 身이 막혔다.
  친구ㆍ형제가 관련되어 있다.)`,`● 하는 일마다 진퇴가 많고, 관재를 면하기 어렵다.`,`● 처와 이별하게 될것이고, 타향에서 사망하게 된다.`,`● 卯월 생은 불길하다.   이상 (출산 X)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 길하다.   (출산 O)`,0,0,0,0],
    [5,`甲子`,5,`戊辰`,`(병재ㆍ적귀의 침범이 있다.)`,`● 고향을 떠나 결실을 얻을 사람이다.`,`● 가문을 열고 문호를 빛나게 할 것이다.`,`● 처는 현명하고, 자식은 효자일것이다.`,`● 未월 생은 北方운에 귀하게 되고, 酉월 생은 北方운과 그 方位에서 大貴하게 된다.   이상 모두 (출산 O)`,0,0,0,0],
    [6,`甲子`,6,`己巳`,`(도난ㆍ파재ㆍ손모 상해 등의 강제건이 있고, 남자가 상한다.)`,`● 부자로 살다가 가난하게 될 것이다. 물려 받은 자산을 지킬수가 없다.`,`● 처는 현명하나 자식은 망측하다.   이상 (출산 X)`,`● 丑ㆍ寅ㆍ巳ㆍ未년월 생은 발달하고, 申ㆍ子ㆍ辰ㆍ戌년월 생은 노력하여 관직을 얻는다.   (출산 O)`,``,0,0,0,0],
    [7,`甲子`,7,`庚午`,`(병재ㆍ동요사가 있고 가정내에 男ㆍ女쟁투가 있다. \n동분서주하고 일이 변전되고, 행동결함이 있다.)`,`● 평범한 삶을 산다.`,`● 만약, 子ㆍ午년월, 또는 寅ㆍ戌월 생은 대운 西ㆍ北운에 발달한다.   (출산 O)`,``,``,0,0,0,0],
    [8,`甲子`,8,`辛未`,`(上人과의 금전관계가 있다. \n일이 지체되고 암매하며, 방해를 받는다.
  旺은 전토건이고,
  衰는 분묘건이다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未ㆍ巳ㆍ酉월 생은 대운 西ㆍ北운에 文官으로 이름을 드러내게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [9,`甲子`,9,`壬申`,`(천강사장 여부를 보라. \n초전이 점시 위 申을 도우면 모망사 불성하고 만물이 상한다. 
  초전 土, 金을 보라.)`,`● 申ㆍ子ㆍ辰ㆍ亥월 생은 貴하게 될것이다.   (출산 O)`,`단, 대운 水ㆍ土 운이 되면 凶하다.   水ㆍ土운 확인하여(출산 X)`,``,``,0,0,0,0],
    [10,`甲子`,10,`癸酉`,`(男ㆍ女불문하고 색정사가 있다. \n몸이 막히고, 자식 키우기 어렵다.
  초전이 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 장수한다.   (출산 O)`,`● 여름철 출생자는 일생 반복한다.   (출산 X)`,`● 가을철 출생자는 일정한 정처가 없고, 凶이 많다.   (출산 X)`,`● 겨울철 출생자는 평범하게 산다.`,`● 유일하게 丑ㆍ未월 생 만큼은 발달한다.   (출산 O)`,0,0,0],
    [11,`甲子`,11,`甲戌`,`(낭비가 심하고, 금전유통이 저해되고 관송사가 기한다.\n이권 상실사가 있다.)`,`● 봄철 출생자는 장수한다.`,`● 여름철 출생자는 성격이 조급하다.`,`● 가을철 출생자는 貴하게 된다.   이상 (출산 O)`,`● 겨울철 출생자는 일을 고쳐하다가 바람만 맞게 된다.`,`● 辰ㆍ戌ㆍ丑월 생은 不吉하다.   이상 (출산 X)`,0,0,0],
    [12,`甲子`,12,`乙亥`,`(셋집 살이자 이다.\n도량이 크나 허명이다. 
  의ㆍ복의 도에 의지해야만 자립한다.
  후원이 불성한다.)`,`● 申월생 洋刃이 있으면, 中將지위자이고, 九월생 正官이 있고, 土가 보이면 文官직 一, 二급자가 된다.`,`● 辰ㆍ巳ㆍ未ㆍ丑월 생은 다같이 吉하다.   이상 (출산 O)`,`● 만약 년월주에 같이 卯가 있게 되면 凶하다.`,`● 처로 인하여 득재하나 극처하며, 혹은 재복이 평범하면 질액을 앓게된다.   이상 (출산 X)`,0,0,0,0],
    [13,`乙丑`,1,`丙子`,`(上人관련 후원사, \n외교 원활 단합사, 
  가정부부 융합사, 
  송구경황, 
  上人 병재사가 있다.)`,`● 午가 없으면 현명하고 온후하며 덕이 높다.`,`● 申ㆍ酉월 생은 사법관이나 교도소장이 될것이다.`,`● 寅월, 子월 생도 吉 하다.   이상 (출산 O)`,`● 단, 丙寅ㆍ己未ㆍ甲戌ㆍ己丑월 생은 형옥(刑獄)을 당한다.   (출산 X)`,0,0,0,0],
    [14,`乙丑`,2,`丁丑`,`(가정파재ㆍ女人실주ㆍ內ㆍ外폐한지정ㆍ가문ㆍ가정이 기운다.)`,`● 가을철 출생자는 병권(兵權)을 쥐게 되나 신체 질병을 앓는다.`,`● 여름철 출생자는 吉 하다.`,`● 겨울철 출생자는 평상적인 삶을 산다.`,`● 봄철 출생자는 부자로서 장수한다.   이상 모두 (출산 O)`,0,0,0,0],
    [15,`乙丑`,3,`戊寅`,`(손재ㆍ병재가 있고 \n內ㆍ外 금전과 여인 문제가 급속하다. 
  강제성을 갖는다.)`,`● 子년 戌월 생은 富貴할 것이다.`,`● 辰ㆍ戌월 생은 대운 木ㆍ火운에 권력을 잡는데, 무관은 장교로 少將직 권한을 갖고,`,`문관은 국ㆍ처장의 직위를 갖는다.   이상 모두 (출산 O)`,``,0,0,0,0],
    [16,`乙丑`,4,`己卯`,`(外의 일이 무력하고, 상쟁이 있고, 우고사가 있다.)`,`● 봄철 출생자는 고독하게 된다.`,`● 여름철 출생자는 곤궁하게 된다.`,`● 가을철 출생자는 신체가 약하게 된다.   이상 (출산 X)`,`● 겨울철 출생자는 중년에 가서 大發 성공한다.   (출산 O)`,0,0,0,0],
    [17,`乙丑`,5,`庚辰`,`(外는 막히고 \n內는 가문이 쇠퇴하고 부인과 불화한다.)`,`● 어른과는 불화ㆍ불목하고 자식과는 친하게 된다.`,`● 申년 출생자로서 천간에 甲이나 戊가 있으면 貴하게 된다.   (출산 O)`,``,``,0,0,0,0],
    [18,`乙丑`,6,`辛巳`,`(男은 직위ㆍ명예손상사가 있고, \n女는 夫와 갈등이 있고, 
  타인의 도움이 필요하다.)`,`● 어려서는 곤란하고 커서는 편하게 될것이다.`,`● 寅ㆍ午ㆍ丙ㆍ丁년월 출생자는 문ㆍ무겸전 발달한다.   (출산 O)`,`● 巳ㆍ申ㆍ酉ㆍ丑월 출생자는 신체가 허약하게 된다.   (출산 X)`,``,0,0,0,0],
    [19,`乙丑`,7,`壬午`,`(박관ㆍ실직사가 있다.\n신규사를 도모한다.
  男ㆍ女 공히 색정사가 있다.)`,`● 봄철과 여름철 출생자는 부자로 살게 된다.`,`● 가을철과 겨울철 출생자는 官星이 보이고 인수성이 있게 되면 가히 貴하게 될것이다.   이상 모두 (출산 O)`,``,``,0,0,0,0],
    [20,`乙丑`,8,`癸未`,`(가정동요ㆍ불안ㆍ폐지사가 있고, \n골육간 상배하고 하극상 대립이 있다.)`,`● 고독하게 산다.   (출산 X)`,`● 만약 년주나 월주에 土가 보이면 길하다.   (출산 O)`,``,``,0,0,0,0],
    [21,`乙丑`,9,`甲申`,`(명예에 관련하여 上人으로부터 추천ㆍ발탁 받는 일이 있다.\n고생을 사서 하는 일인 경우에 속한다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 子ㆍ辰 년월 출생자는 대운 東ㆍ南 운에 귀하게 된다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑 년월 출생자는 貴한 중에 凶이 있게 될것이다.`,`● 卯ㆍ午ㆍ未월 출생자는 吉하다.   (출산 O)`,``,0,0,0,0],
    [22,`乙丑`,10,`乙酉`,`(外의 결속이 있고, \n內는 개혁적인 일이 있다.
  옛일, 옛 인연이 끝난다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 巳ㆍ酉ㆍ丑월생이  合金국을 이루면 대운 酉운에 西방위에서 大貴해진다.   (출산 O)`,`● 寅ㆍ午ㆍ酉월 생은 빈곤하다.   (출산 X)`,`● 丑ㆍ卯ㆍ未월 생은 길하다.   (출산 O)`,`● 子년, 子월 생은 대운 南方운에 무관 사령관 직에 오른다. 문관은 청ㆍ처장이다.   (출산 O)`,`● 寅월생이 천간에 火ㆍ金이 보이면 군수 지위를 갖는다.   (출산 O)`,`● 申월생이 천간에 水ㆍ木ㆍ金이 보이면 존대한 특명을 받아 임무를 수행한다.   (출산 O)`,0,0],
    [23,`乙丑`,11,`丙戌`,`(하극상 배반사가 있고  財 부족사정이 있다.\n매사 불성하고 산기인이다.
  이권 상실사가 있다.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 가을철 출생자는 고단하다.   (출산 X)`,`● 겨울철 출생자는 인수성이 보이고 상관성이 있으면 청장ㆍ처장의 지위에 오른다.   (출산 O)`,`● 戌월 생은 대운 木ㆍ火운이 오면 향리의 수장에 오른다.   (출산 O)`,0,0,0,0],
    [24,`乙丑`,12,`丁亥`,`(원행ㆍ이동ㆍ별리사가 있다. \n후원ㆍ사업이 고립 난망이고 산기인이다.)`,`● 卯ㆍ未ㆍ子ㆍ申월 생은 천간에 재성이 보이고 인수가 있으면 才德을 겸비하고 사법관이나,`,`국회의원을 하게된다.   (출산 O)`,`● 년주ㆍ월주 간지가 모두 金이 될 때는 凶하다.   (출산 X)`,``,0,0,0,0],
    [25,`丙寅`,1,`戊子`,`(他人의 도움이 필요하다. \n초전이 본 점시를 도우면 몸이 상한다.)`,`● 卯ㆍ丑월 생은 淸貴하게 산다.   (출산 O)`,`● 寅ㆍ戌월 생은 평범한 삶이다.`,`● 여름철 출생자는 金ㆍ水가 있게 되면 吉한 삶이다.   (출산 O)`,`● 子월생이 正官格이 되면 大貴 하게 된다.   (출산 O)`,`● 癸巳월 생은 刑을 받고, 癸丑월 생은 凶하고, 己酉월 생은 참패ㆍ단명한다.   (출산 X)`,0,0,0],
    [26,`丙寅`,2,`己丑`,`(자손사ㆍ박관사ㆍ夫와 갈등관계가 있다. \n상대를 공격하는 일에 관련된다.)`,`● 평범하게 산다.`,`● 乙酉월생이 正官格이 되고 庚과 합이 되면 大運 南方운에 貴해진다.   (출산 O)`,`● 己丑년월 출생자가 천간에 官이나 인수성이 뜨면 貴하다. 단, 질병을 갖는다.`,``,0,0,0,0],
    [27,`丙寅`,3,`庚寅`,`(內가 막히고,\n外는 대립관계이다.
  일이 급속하다.)`,`● 貴가 오래가지 못한다.   (출산 X)`,`● 申ㆍ酉년월 출생자는 하급인으로 산다.   (출산 X)`,`● 子ㆍ丑ㆍ寅ㆍ未월 생은 吉하다.   (출산 O)`,`● 년ㆍ월에 寅이 나라히 있어도 吉하다.   (출산 O)`,0,0,0,0],
    [28,`丙寅`,4,`辛卯`,`(內의 소모가 크다.\n男ㆍ女 共히 색정사가 있다.)`,`● 출생지를 떠나 자수성가 하게 된지만, 신체가 허약하다.`,`● 寅ㆍ卯ㆍ未ㆍ子월 생은 貴하게 된다.   (출산 O)`,`● 다른 月의 출생자는 평상적인 인생이다.`,``,0,0,0,0],
    [29,`丙寅`,5,`壬辰`,`(손사ㆍ박관사ㆍ夫와의 갈등이 있다. \n상대를 공격하는 심정에 있다.)`,`● 일간이 旺하고 편관도 힘이 있으면, 寅ㆍ卯ㆍ辰ㆍ丑ㆍ未 년생 貴하다. (一說 大凶이라고도 한다)`,``,``,``,0,0,0,0],
    [30,`丙寅`,6,`癸巳`,`(직위나 녹위를 구하는 일이다. \n外가 막혀 있다. 
  他人으로부터 위탁받은 일이 안되고 있다.)`,`● 봄철 출생자는 천간지지에 水가더  없으면 좋은 부인를 만난다.   확인 후 (출산 X)`,`● 卯ㆍ戊ㆍ申ㆍ酉년월 출생자는 少將직위, 또는 당선직 문관이 된다.   (출산 O)`,``,``,0,0,0,0],
    [31,`丙寅`,7,`甲午`,`(파재ㆍ극처사가 있다. \n內의 결속이 있으나 허사이다. 
  가정을 떠나는 일이 있다.)`,`● 辰ㆍ戊ㆍ丑ㆍ未월 출생자는 운에 개고(開庫)되어 화가 투출하면 貴하게 된다.   이상을 보아 (출산 O)`,`● 午월 생은 火가 너무 세어 凶하다.   (출산 X)`,``,``,0,0,0,0],
    [32,`丙寅`,8,`乙未`,`(박관ㆍ夫와의 갈등이 있다. \n상대를 공격하는 마음이 있다.)`,`● 寅ㆍ未월 생은 인수격이 成格되면 지혜롭고 풍후하다. 단, 처자식과의 인연이 박하다.`,``,``,``,0,0,0,0],
    [33,`丙寅`,9,`丙申`,`(별리ㆍ별거ㆍ이동ㆍ원행사가 있다.\n內에 부부이심으로 동요ㆍ불안ㆍ원수맺는 일이 있다.)`,`● 일지와 시지가 대충(對沖)되어 처를 잃고 자식걱정이 있게 된다.   (출산 X)`,`● 여름철 출생자는 대운 木ㆍ火운에 吉하게 된다.`,``,``,0,0,0,0],
    [34,`丙寅`,10,`丁酉`,`(外에 개혁을 원한다.\n他人의 압박사가 있다.)`,`● 寅ㆍ卯ㆍ未년월 출생자는 천간에 정관이나 편관이 있게 되면 貴하다.   (출산 O)`,`● 다른 월 출생자는 예술가로 평상적인 삶을 산다.`,``,``,0,0,0,0],
    [35,`丙寅`,11,`戊戌`,`(매사 불성한다.\n금전ㆍ여자문제도 있다.
  자손ㆍ명예도 손상된다. 
  山氣人이다.
  속는 일이 있다.)`,`● 여름철 출생자는 장수하고 부자로 산다.`,`● 寅월 생은 최고 吉하다.   (출산 O)`,`● 말솜씨가 있고 선하다.  이상 (출산 O)`,`● 봄철 출생자는 평범하다.`,`● 申이 있고 甲을 제거하면 大吉하다.   (출산 O)`,`● 가을철과 겨울철 출생자는 일반 하위층으로 산다.,● 巳ㆍ申ㆍ丑년월 생은 사법권자로서의 직위를 갖는다.   (출산 O)`,0],
    [36,`丙寅`,12,`己亥`,`(강제적인 동요ㆍ불안이 있다.\n매사불성한다.
  의ㆍ복의 도에 의지하여야 자립한다.
  山氣人이다.
  권리를 상실한다.)`,`● 여름철 출생자는 처는 현명하나 자식이 불효하다.`,`● 부귀 또한 멀다.   이상 (출산 O)`,``,``,0,0,0,0],
    [37,`丁卯`,1,`庚子`,`(男ㆍ女 共히 색정사가 있다.\n外 근심사 有. 가정 上ㆍ下 무례관사가 있고 여자에게 해롭다.
  대립ㆍ모순이 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 빈곤하다.   (출산 X)`,`● 午월생이나 寅ㆍ卯월 생은 길하다.   (출산 O)`,`● 가을철ㆍ겨울철 출생자는 평상적인 삶을 산다.`,`● 癸ㆍ巳월 생은 조상을 파명한다.   (출산 X)`,`● 己ㆍ未월 생은 凶하다.   (출산 X)`,0,0,0],
    [38,`丁卯`,2,`辛丑`,`(이별사가 있다. \n자손사이다. 
  박관ㆍ실직ㆍ손모사가 있다. 
  유명무실하고 內ㆍ外가 어둡다.)`,`● 辰ㆍ巳ㆍ未년월 생은 부자로 사는데, 처ㆍ자 인연이 늦다.   (출산 O)`,`● 寅ㆍ卯년월생이 대운에 戌운이 오면 大貴를 얻는다.   (출산 O)`,``,``,0,0,0,0],
    [39,`丁卯`,3,`壬寅`,`(외부의 일이 부진하여 가정의 비용출비가 많다.\n이로인해 망신사가 발생한다.)`,`● 寅ㆍ卯ㆍ辰년월 생은 吉하고, 대운 金ㆍ水운에 大貴해진다.   (출산 X)`,`● 亥ㆍ子월 생은 대운 金운에 貴하게 된다.   (출산 O)`,``,``,0,0,0,0],
    [40,`丁卯`,4,`癸卯`,`(외부로는 활발한듯 해도 가정ㆍ내부는 꽉 막혔다.\n금전과 여인사로 고심이 크다.)`,`● 寅ㆍ卯ㆍ辰월 생은 대운 水운에 貴하게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [41,`丁卯`,5,`甲辰`,`(박관ㆍ夫와의 갈등이 있다. \n손해가 있고, 가난이 기울고 상쟁되고 불완전하다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 고독하다.   (출산 X)`,`● 卯월 출생자는 대운 金ㆍ水운에 권력을 쥔다.   이상 확인 후 (출산 O)`,``,``,0,0,0,0],
    [42,`丁卯`,6,`乙巳`,`(파재ㆍ妻ㆍ女와 이별한다. \n원행ㆍ별리사가 있고, 타인을 돕는 일에 관련된다. 
  친구나 형제가 관련된다.)`,`● 모두 吉하다.`,`● 봄철 출생자는 학자.`,`● 여름철 출생자는 정치가.`,`● 가을철 출생자는 사업가.`,`● 겨울철 출생자는 관직자로 발달한다.   이상 모두 (출산 O)`,0,0,0],
    [43,`丁卯`,7,`丙午`,`(토대를 위해 직위나 재산을 증식하려 한다. \n가정내부에는 파재ㆍ도망사가 발생한다. 
  계속이 안되고 개신사가 있다.)`,`● 좋은 중에 재난이 있다.`,`● 卯가 刑ㆍ破를 보면 백정(白丁)이 된다.   (출산 X)`,`● 丁巳월 출생자는 대운 酉ㆍ北운에 吉하다.`,``,0,0,0,0],
    [44,`丁卯`,8,`丁未`,`(박관ㆍ실직사에 관련된다. \n가정은 결속되나 외교가 막혀 있다. 
  他人의 부탁건이 안 풀린다.)`,`● 사주에 刑을 이루면 발달한다.   (출산 O)`,`● 寅ㆍ卯월 생은 인수가 되어 부자이고 돈후하다.   (출산 O)`,`● 辰ㆍ戌ㆍ辛ㆍ午가 보이면 吉하다.   (출산 O)`,``,0,0,0,0],
    [45,`丁卯`,9,`戊申`,`(他人으로부터 압박받는 일이 있다. \n강제성을 갖는다. 
  財ㆍ女가 침범을 받는다.)`,`● 봄철 출생자는 상관격이 되고 인수가 보이면 吉하다.`,`● 여름철 출생자는 비견이 있으면 吉하다`,`● 가을철 출생자는 상관이 있고 재성이 보이면 吉하다.`,`● 겨울철 출생자는 상관이 있고 관성이 보이면 평범하다.`,`● 辰ㆍ戌ㆍ丑ㆍ未월 출생자는 吉하다.   이상 모두 (출산 O)`,0,0,0],
    [46,`丁卯`,10,`己酉`,`(가정이 동요ㆍ불안하고 분리개변사가 기한다. \n男ㆍ女이별ㆍ원수 맺는 일이 있다.)`,`● 일주와 시주가 충극되므로 처를 잃고 자식 걱정이 많게 된다.   (출산 X)`,`● 巳ㆍ午월 출생자는 吉하다.   (출산 O)`,`● 乙卯가 보이면 나쁘다.   (출산 X)`,`● 년월에 亥ㆍ未ㆍ巳 어느것이 있을때는 沖으로 보지 않는다.`,0,0,0,0],
    [47,`丁卯`,11,`庚戌`,`(대립ㆍ모순이 있고 외부출입이 속하다. \n내부화합도 실효가 없다. 
  他人에게 속는 일이 있다. 
  山氣人이다.)`,`● 亥ㆍ未년월 출생자 三合하여 貴하다.   (출산 O)`,`● 子월 생은 水生木이 되어 吉하다.   (출산 O)`,`● 년월에 卯가 보이면 스님이 된다. 貴하다.   (출산 O)`,`● 癸가 旺하면 눈에 질병을 앓는다.`,`● 酉월 생은 凶하다.   (출산 X)`,`● 년월에 酉ㆍ戌이 나란히 있고, 천간에 甲ㆍ巳가 보이면 貴중 凶이다.`,0,0],
    [48,`丁卯`,12,`辛亥`,`(매사 불성한다. 내외 결속하는 듯하나 결국 효과가 없다. \n女는 夫를 亡하게 하는 일을 하고 있고, 
  男은 직위가 손상된다. 
  山氣人이다.)`,`● 년월에 인수가 있고, 재성을 생하는 土가 보이면 貴하다.   (출산 O)`,`● 년월에 子가 나란히 있으면 대운 木ㆍ火운에 크게 발달한다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑ㆍ월 생은 酉方에서 富貴해진다.   (출산 O)`,``,0,0,0,0],
    [49,`戊辰`,1,`壬子`,`(내부 의사가 결집되는 비곡사ㆍ수치사가 있다.)`,`● 봄철 출생자, 여름철 출생자, 가을철 출생자, 겨울철 출생자 모두 대운 西ㆍ南운에 크게 발달한다.   (출산 O)`,`● 庚辰월 凶하다.`,`● 自形이 보이면 凶하다.`,`● 辛巳월 凶하다.`,`● 乙丑월 凶하다.   이상 모두 (출산 X)`,0,0,0],
    [50,`戊辰`,2,`癸丑`,`(계속이 안되고, 개신사가 있다. \n일체 불완전하다. 
  上人과 관계되어 금전소모가 있다.)`,`● 巳ㆍ午ㆍ未ㆍ戌월 출생자는 吉하다.`,`● 申ㆍ子ㆍ辰 재국(財局)이 되면 吉하다.`,`● 년월 간지가 모두 土가 되면 貴하다.`,`● 년월 지지가 모두 土(辰ㆍ戌ㆍ丑ㆍ未)가 되어도 貴하다.   이상 모두 (출산 O)`,0,0,0,0],
    [51,`戊辰`,3,`甲寅`,`(외부의 대립ㆍ반목 모순사로 출입이 급하다.\n신규사로 원행ㆍ이동이 있다. 
  거지가 곤란하다.
  천강사장을  보라.)`,`● 년월 庚이나 申이 보이면 貴하다.`,`● 辰ㆍ戌ㆍ丑ㆍ未월 출생자도 貴하다.   이상 모두 (출산 O)`,``,``,0,0,0,0],
    [52,`戊辰`,4,`乙卯`,`(손해ㆍ실주사가 있다. \n독직ㆍ득죄 관사가 기한다. 별리사가 있다. 
  만사공허 무력상쟁한다.
  초전이 본 점시를 도우면몸이 상한다.)`,`● 貴하나 고독하다.`,`● 己卯년이나 월에 출생하면 조상을 빛내고 자식은 효도하고 처는 현명하게 된다.   이상 모두 (출산 O)`,``,``,0,0,0,0],
    [53,`戊辰`,5,`丙辰`,`(내부 가정이 막혀 굴욕을 겪고 \n금전과 여인이 소모ㆍ갈등되어 근심하고 있다. 
  친구 형제가 관련되어 있다.
  천강사장을  보라.)`,`● 大癸한다.그러나 아버지를 일찍 잃는다.`,`● 火ㆍ土월 생은 대운 東ㆍ北운에 부자가 된다.   이상 보아 (출산 O)`,`● 水ㆍ木월 생은 대운 火ㆍ土운에 貴하게 된다.   이상 보아 (출산 O)`,``,0,0,0,0],
    [54,`戊辰`,6,`丁巳`,`(外交가 막혔다. \n타인의 부탁사가 안풀린다. 
  강제성이 있는 일을 겪는다.)`,`● 巳ㆍ酉ㆍ丑ㆍ월 생은 권리가 크나 풍유아가 된다.`,`● 년월간지에 水나 木이 없으면 청운의 꿈을 얻는다.   (출산 O)`,`● 丑월 출생자는 대운 南方운에 발달한다.   이상 보아 (출산 O)`,`● 申월 출생자는 대운 北方운에 발달한다.   이상 보아 (출산 O)`,0,0,0,0],
    [55,`戊辰`,7,`戊午`,`(他人으로부터 도움받는 일이다. \n문서사로 관사구설이 기한다. 
  자중해야 한다.)`,`● 공록격으로서 년주ㆍ월주에 酉ㆍ巳ㆍ甲ㆍ乙이 없으면 貴하다.`,`● 卯ㆍ甲월 생은 무관직을 갖는다.   이상 (출산 O)`,`● 丑ㆍ酉ㆍ亥월 생은 대운 南方운에 貴하게 된다. (一說 관재형옥의 凶이 있다고도 한다)`,``,0,0,0,0],
    [56,`戊辰`,8,`己未`,`(귀인사에 관련하여 금전소모사가 있고 \n여자와의 갈등도 있다. 
  관공직 진출은 좋으나 일반인은 불리하다.)`,`● 봄철과 여름철 출생자는 대운 北方운에 富貴하게 된다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [57,`戊辰`,9,`庚申`,`(박관사가 기한다. \n夫와 갈등이 있다.
  외부의 화합은 반목ㆍ불화가 된다. 
  관재 근심이 있다.)`,`● 년ㆍ월에 나란히 酉가 있으면 좋다.`,`● 년월에 申이 하나 있어도 貴하게 된다.   이상 (출산 O)`,``,``,0,0,0,0],
    [58,`戊辰`,10,`辛酉`,`(男ㆍ女 共히 색정사가 있다. \n구태를 개선하고 애정을 달리한다. 
  자손사로 내부의 의견을 단합한다.)`,`● 丑ㆍ未년월이 되고 천간에 水가 보이면 貴하다.`,`● 癸巳월 생은 문장을 잘하는 재능이 있게 된다.   이상 (출산 O)`,`● 庚ㆍ申ㆍ巳ㆍ亥년 출생자는 범법자가 되고 말년까지 결과가 없다.   (출산 X)`,``,0,0,0,0],
    [59,`戊辰`,11,`壬戌`,`(금전ㆍ여인이 파재 별리한다. \n內의 동요불안이 있다.
  매사 불성하고 山氣人이다.
  형제재액이 기한다.)`,`● 처를 잃고 자식 걱정이 많게 된다.   (출산 X)`,`● 사주에 土가 많고 成局 되면 大貴한다.   (출산 O)`,`● 卯월 생은 대운 火ㆍ金운에 中貴자가 된다.   이상 보아 (출산 O)`,`● 酉ㆍ申ㆍ戌ㆍ丑월 생은 대운 西ㆍ南 운에 貴하게 된다.   이상 보아 (출산 O)`,0,0,0,0],
    [60,`戊辰`,12,`癸亥`,`(헛된 사욕의 두려움이 있다. \n大를 구하나 小도 없다.
  山氣人이다.
  속는 일이 있다.)`,`● 財格이나 官格이 되면 貴가 아니라도 부자가 된다.`,`● 酉ㆍ卯년월 출생자는 貴하게 된다.`,`● 여름철 출생자는 가르치는 일을 한다.   이상 모두 (출산 O)`,``,0,0,0,0],
    [61,`己巳`,1,`甲子`,`(上人과 관련하여 外의 財를 따진다. \n관사구설이 있고 女와의 남모르는 害 있다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.`,`● 己ㆍ丑월 생은 문관으로 貴하고 만성한다.   이상 (출산 O)`,`● 년월에 寅ㆍ卯가 나란히 있게 되면 가난하고 천하다.   이하 (출산 X)`,`● 甲申월 생은 몸이 불완전하고 일찍 죽는다.`,`● 癸酉월 생은 요절한다.,● 丙子월 생은 고독하고 가난하다.`,`● 丁丑ㆍ壬寅ㆍ乙未월 생은 형액을 받는다.`,0],
    [62,`己巳`,2,`乙丑`,`(형제ㆍ동료가 관련된다. \n外에 이별사가 있다. 
  유명무실한 처지이다.)`,`● 편관이 없고 酉ㆍ戌이 보이면 文貴하게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [63,`己巳`,3,`丙寅`,`(도주관사가 있고 진퇴가 곤란하다. \n전진하면 실패한다. 
  수구하는 방침이 좋다.
  초전이 본 점시를 도우면 몸이 상한다)`,`● 寅월생이 정관격이 되면 대운 水ㆍ木운에 大貴하게 된다.`,`● 戌월 생은 무관직으로 少將에 이른다.   이상 (출산 O)`,`● 년월지에 申ㆍ亥가 보이면 大貴하나 惡死하게 된다.`,``,0,0,0,0],
    [64,`己巳`,4,`丁卯`,`(불가항력적이다. \n외부에서는 결속이 있으나 번잡하다. 
  도움을 원한다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 충ㆍ파가 없으면 富貴하게 된다.   (출산 O)`,`● 寅ㆍ午ㆍ辰년월 생은 형액을 받는다.   (출산 X)`,`● 亥월 생은 대운 木ㆍ火운에 貴해진다.   (출산 O)`,`● 봄철 출생자가 천간에 甲ㆍ乙이 있고 制가 되면 貴하나, 制합이 없게 되면 凶하고 요정한다.`,0,0,0,0],
    [65,`己巳`,5,`戊辰`,`(금전이 손모되었다.\n女ㆍ부인과도 갈등이 있다. 
  형제ㆍ동료가 관련된다. 
  他人을 돕는 일에 관련된다.)`,`● 고독하게 살고, 인생 후반에 발달한다.`,`● 봄철 출생자는 관직자로 貴하게 될것이다.   (출산 O)`,`● 여름철 출생자는 평온한 삶이다.   (출산 O)`,`● 가을철 출생자는 흉폭하다.   (출산 X)`,`● 겨울철 출생자는 財旺하여 길하다.   (출산 O)`,0,0,0],
    [66,`己巳`,6,`己巳`,`(內가 막혀있다. \n금전이 암손되고 여인관계가 고민이다. 
  변동사가 있다.)`,`● 午년월 출생자는 군세가 혁혁하고 명예를 드높이게 될것이다.   (출산 O)`,`● 봄철 출생자는 처가 현명하고 자식도 효도할 것이다.   (출산 O)`,`● 여름철,가을철 출생자는 귀한 삶이 못된다.   (출산 X)`,`● 申월 생은 상관격이 되고 재성이 보이면 吉하다.   (출산 O)`,`● 辰ㆍ戌월 생은 財ㆍ印ㆍ食이 구비되면 吉하다.   (출산 O)`,0,0,0],
    [67,`己巳`,7,`庚午`,`(사업ㆍ후원사, 직ㆍ녹을 늘리는 일이다. \n男ㆍ女 共히 색정사가 있다. 
  外交는 좋으나 가정은 허모가 많다.)`,`● 貴하다.   (출산 O)`,`● 寅ㆍ午ㆍ戌월 생은 金神이 없고 火局을 이루면 貴하다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.`,`● 哈ㆍ殺운에 凶死할 우려가 있다.`,0,0,0,0],
    [68,`己巳`,8,`辛未`,`(外의 일들이 막혀 금전 손모가 많다. \n형제동료가 관련된다.
  他人을 돕는 일이 근심되었다. 
  기능마비사가 있다.)`,`● 寅ㆍ午ㆍ戌월 생은 貴하다.   (출산 O)`,`● 亥ㆍ卯ㆍ辰월 생은 대운 金ㆍ火ㆍ水ㆍ木운에 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [69,`己巳`,9,`壬申`,`(夫와 갈등이 있다.\n內 화합하나 반목ㆍ대립이 있다. 
  上ㆍ下불순하고 은혜를 모르는 일에 관련된다.)`,`● 巳ㆍ酉ㆍ丑월 생은 상관격이 되면 대운 北合운에 吉하다.`,`● 寅ㆍ卯ㆍ戌월 생은 대운 西ㆍ南운에 吉하다.`,`● 申ㆍ子ㆍ辰월 생은 대운 木ㆍ火운에 吉하다.   이상 대운 보아 (출산 O)`,``,0,0,0,0],
    [70,`己巳`,10,`癸酉`,`(內가 결속하여 개신ㆍ개혁의 일을 시도한다. \n신규사이다. 
  마음이 단단하나 늦다.)`,`● 寅ㆍ卯ㆍ戌월 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [71,`己巳`,11,`甲戌`,`(금전ㆍ여자가 사라져 고립된다. \n外에 대립관계가 있고 매사 불성한다. 
  생산성의 일은 적성이 아니다.
  山氣人이다.
  형제 재액이 있다.)`,`● 寅ㆍ午ㆍ戌월 생은 貴하다.   (출산 O)`,`● 亥ㆍ戌년월 생은 관직자로 청백리의 명예가 있게 된다.   (출산 O)`,`● 子월 생은 대운 木ㆍ火운에 大貴하게 된다.   (출산 O)`,``,0,0,0,0],
    [72,`己巳`,12,`乙亥`,`(금전ㆍ상업ㆍ여인관계이다. \n內에 동요불안사가 있다. 
  크게 구하나 작은 것도 얻지 못한다. 
  이권 상실하고 매사 불성하고 山氣人이다.)`,`● 寅ㆍ午ㆍ戌월 생은 貴하다.`,`● 巳월 출생자는 대운 西ㆍ北운에 大貴하게 된다.`,`● 午ㆍ未년월 생은 권세가 있게 된다.`,`● 子ㆍ亥년월 생은 財星이 많고 편관을 도우면 從殺이 되어서 大병권을 잡게 된다.`,0,0,0,0],
    [73,`庚午`,1,`丙子`,`(外는 결속하나 內는 동요 불안하다.\n男ㆍ女 부부상쟁하고, 
  모사는 변경위역되고, 
  거주행동에 애로가 있다.)`,`● 日ㆍ時가 상충하여 처를 잃고 자식 걱정이 많게 된다.   (출산 X)`,`● 木ㆍ火월 출생자는 대운 西ㆍ南운에 권세를 쥔다.   (출산 O)`,`● 가을철 출생자는 자녀 갖기가 어렵다.   (출산 X)`,`● 己巳월 생은 가문을 파하고, 己亥월 생은 수탈당하고, 癸丑월 생은 고독하다.   이상 (출산 X)`,0,0,0,0],
    [74,`庚午`,2,`丁丑`,`(上人관계에서 손해가 있다.\n부부간 불화가 있고, 관사가 불리하다.
  유명무실한 사정이다.)`,`● 貴도 벌고 富도 벌다.   (출산 X)`,``,``,``,0,0,0,0],
    [75,`庚午`,3,`戊寅`,`(내부ㆍ가정은 밝으나 공허하고 \n外에 동요ㆍ불안ㆍ이별사가 있다.
  부부간 二心한다.)`,`● 가을철 출생자는 貴하다.   (출산 O)`,`● 寅ㆍ午ㆍ戌월생자는 질병을 갖게 되고, 사주에 모두 寅이 있으면 大貴하다.   (출산 O)`,``,``,0,0,0,0],
    [76,`庚午`,4,`己卯`,`(男ㆍ女 共히 색정사가 있고, \n가정에 파재ㆍ도망ㆍ가출의 심로가 있다.
  개신사가 있고 불완전하다.)`,`● 申ㆍ子ㆍ辰월 생자는 대운 東ㆍ南운에 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [77,`庚午`,5,`庚辰`,`(他人에게 도움을 주고자 하는 일에 관련된다.\n고립ㆍ고갈의 사정에 직면했다.)`,`● 申월 출생자 대운 木ㆍ火운에 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [78,`庚午`,6,`辛巳`,`(外에서 화합중 반목 대립문제가 있다.\n은혜를 배반하는 불순한 행위를 겪는다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 巳ㆍ午ㆍ丑년월 출생자는 사법계에 종사하게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [79,`庚午`,7,`壬午`,`(가정ㆍ내부가 막혔다.\n他人에 의해 금전ㆍ여인사가 기했다.
  上ㆍ下부동의 관사가 기한다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 寅ㆍ午ㆍ戌월 생은, 金이 약하고 火가 旺하면 잔질을 앓게 된다.`,`● 亥ㆍ子ㆍ午ㆍ丑월 생은 대운 木ㆍ火운에 貴하게 된다.   (출산 O)`,``,``,0,0,0,0],
    [80,`庚午`,8,`癸未`,`(공공사ㆍ직의 기쁜 소식이 있다.)`,`● 貴하다.   (출산 O)`,`● 申ㆍ酉ㆍ巳ㆍ亥년월 출생자는 무관으로 中將 지위를 갖는다.   (출산 O)`,``,``,0,0,0,0],
    [81,`庚午`,9,`甲申`,`(직ㆍ녹위가 증가하는 명예사가 있다.\n원행ㆍ이동사도 있다.
  단, 외교적 위탁사가 막혀 있다.)`,`● 월주에 丙ㆍ巳ㆍ寅ㆍ午ㆍ丁이 없으면 貴하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [82,`庚午`,10,`乙酉`,`(금전이 소모되고, 女人관계로 갈등이 있다.\n형제ㆍ동료가 관련된다.
  상대를 압박한다.)`,`● 寅ㆍ午ㆍ戌, 亥ㆍ卯ㆍ未 合局이 되면 귀하다.   (출산 O)`,``,``,``,0,0,0,0],
    [83,`庚午`,11,`丙戌`,`(外에 단결이 있으나 결국 공허한 일이다.\n매사불성하고 금전손해가 있다.
  山氣人이다.
  사업후원사의 고립이 있다.)`,`● 申ㆍ酉ㆍ戌ㆍ亥ㆍ丑월 생자는 무관 少將지위가 된다.   (출산 O)`,`● 巳ㆍ午ㆍ未ㆍ申월 생자는 천간에 壬을 보면 장관 이상의 직위에 오른다.   (출산 O)`,``,``,0,0,0,0],
    [84,`庚午`,12,`丁亥`,`(夫와의 갈등도 있다.\n직위가 위태하고 남에게 속는 일이 있다.
  매사불성하고 유시부종하며 山氣人이다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 출생자는 영화가 있다.   (출산 O)`,`● 申ㆍ亥ㆍ酉월 생은 대운 木ㆍ火운과 東ㆍ南방위에서 출세한다.   (출산 O)`,``,``,0,0,0,0],
    [85,`辛未`,1,`戊子`,`(男ㆍ女 共히 색정사가 있다.\n관사구설과 남모르는 고민으로 손해가 있다.)`,`● 寅ㆍ巳ㆍ午ㆍ酉년월 생자는 대운 火ㆍ木 운에 貴하게 된다.`,`● 亥ㆍ子년월 생자는 청귀하다.`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자도 吉다.   이상 모두 (출산 O)`,`● 庚申월과 壬子월 출생자는 고독하고 凶하다.   (출산 X)`,`● 癸丑월 출생자도 형액이 있고 凶하다.   (출산 X)`,0,0,0],
    [86,`辛未`,2,`己丑`,`(男ㆍ女 共히 색정사가 있다.\n관사구설과 남모르는 고민으로 손해가 있다.)`,`● 寅ㆍ卯ㆍ未월 생은 吉하다.   (출산 O)`,`● 巳ㆍ午월 생은 관직에 오른다.   (출산 O)`,``,``,0,0,0,0],
    [87,`辛未`,3,`庚寅`,`(上人과의 財문제를 다툰다.\n가정 내부가 어둡다.
  규모는 위 왕쇠로 구분하라.)`,`● 巳ㆍ未ㆍ丑월 출생자는 관직을 갖는다. 단, 말년에 풍질을 앓게 된다.`,`● 寅ㆍ卯ㆍ午ㆍ戌월 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [88,`辛未`,4,`辛卯`,`(內ㆍ外화합ㆍ결속사가 있다.\n옛 일이 끝나고 새 일 새 인연을 만든다.
  금전사가 좋다.)`,`● 년월에 寅ㆍ巳ㆍ亥가 나란히 있으면 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [89,`辛未`,5,`壬辰`,`(外에서 하극상배를 당하고 희비예측이 안되고 있다.\n침체해 있다.)`,`● 가을철 출생자는 貴하다.   (출산 O)`,`● 여름철 출생자는 노곤하다.   (출산 X)`,``,``,0,0,0,0],
    [90,`辛未`,6,`癸巳`,`(원행ㆍ이동ㆍ명예사가 있다.\n변동이 있고 他人의 도움을 원하고 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 寅ㆍ午ㆍ戌월 출생자는 吉하다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑월 출생자는 평범한 삶을 산다.`,`● 申ㆍ子ㆍ辰월 출생자는 백정(白丁)이 된다.   (출산 X)`,`● 丑ㆍ卯ㆍ未월 출생자는 淸秀하게 산다.`,0,0,0,0],
    [91,`辛未`,7,`甲午`,`(內ㆍ外가 밝고 결속화합이 있으나 불만이 있다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 申ㆍ子ㆍ辰월 출생자는 편관을 제하면 吉하고 대운 丑ㆍ戊운에 발달한다.   편관제복과 대운보아 (출산 O)`,`● 未월 생자는 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [92,`辛未`,8,`乙未`,`(外에 반목ㆍ대립이 있고 內는 막혀있다.\n금전손해가 있다.)`,`● 寅ㆍ卯ㆍ未년월 출생자 관직자가 좋다.   (출산 O)`,`● 년월에 寅午ㆍ午戌이 나란히 있으면 대운 酉方운에 吉하다.`,`● 년월에 巳酉ㆍ酉戌이 나란히 있으면 대운 南方운에 吉하다.   이상 대운 확인하여 (출산 O)`,``,0,0,0,0],
    [93,`辛未`,9,`丙申`,`(금전이 손모되고 女人과도 갈등이 있다.\n강제성을 띤 가정손해가 있고 급하다.)`,`● 봄철 출생자는 淸貴하다.`,`● 여름철 출생자는 평상적인 삶이다.`,`● 가을철 출생자는 부자로 산다.   이상ㆍ하 모두 (출산 O)`,`● 겨울철 출생자는 貴하고 장수한다.`,0,0,0,0],
    [94,`辛未`,10,`丁酉`,`(직위ㆍ녹위를 증가하는 일이다.\n입신을 위해 노력한다.
  수하자의 농락이 있고 손상이 있다.)`,`● 貴나 富를 바라지 말라.`,`● 申ㆍ酉월 출생자이고, 丙ㆍ丁이 없으면 대운 南方운에 貴하게 된다.   이상보아 (출산 O)`,``,``,0,0,0,0],
    [95,`辛未`,11,`戊戌`,`(어렵다.\n고독인이다.
  外交가 막히고 관재기미도 있다.
  매사불성하고 山氣人이다.
  사업후원사의 고립이 있다.)`,`● 卯ㆍ未ㆍ酉 출생자는 대게 凶으로 본다.`,`● 처를 잃고 자식 근심이 크다.   (출산 X)`,`● 寅ㆍ巳ㆍ午ㆍ戌ㆍ丑월 출생자는 貴하다.   이하 모두 (출산 O)`,`● 申ㆍ子ㆍ辰ㆍ亥월 출생자도 貴하다.`,0,0,0,0],
    [96,`辛未`,12,`己亥`,`(他人에게 속는 일이 있다.\n매사불성하고, 금전손해가 있고 山氣人이다.)`,`● 丑ㆍ寅ㆍ未ㆍ寅월 출생자는 명회가 크다.`,`● 巳ㆍ午년월 출생자는 부자로 산다.   이상 모두 (출산 O)`,`● 가을철 출생자도 丙이 보이면 吉하다.   사주에 丙 확인하여 (출산 O)`,``,0,0,0,0],
    [97,`壬申`,1,`庚子`,`(돈이 크게 손모되고 \n女ㆍ妻와의 갈등이 있다.
  형제ㆍ친구가 관련된다.
  내부적으로 다수의견이 모아진다.)`,`● 봄철과 겨울철 출생자는 평범하다.`,`● 여름철 출생자는 吉하다.   이하 모두 (출산 O)`,`● 乙巳월 생은 형액이 있게 된다.`,`● 丁酉월 생은 요절한다.   좌우 (출산 X)`,`● 乙亥월 생은 고독하다.   (출산 X)`,`● 대운 土ㆍ火운에 길하다.,● 丑ㆍ戌ㆍ未월 생은 吉하다.   (출산 O)`,0],
    [98,`壬申`,2,`辛丑`,`(他人의 도움이 요청되는 일이다.\n재액을 방지하는 자구책이다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 丑ㆍ卯ㆍ未ㆍ寅 년월 출생자 문관직에 오르나 기복이 심하다.`,`● 辰ㆍ戌ㆍ申ㆍ酉월 출생자는 부자로 산다.   (출산 O)`,``,``,0,0,0,0],
    [99,`壬申`,3,`壬寅`,`(불안ㆍ동요가 있다.\n부부이심 별리사가 있다.
  가정이 흔들린다.)`,`● 巳ㆍ酉ㆍ丑월 출생자는 少ㆍ中將 지위에 오른다.   (출산 O)`,`● 辛ㆍ卯ㆍ戌월 출생자는 貴하나, 짧다.`,``,``,0,0,0,0],
    [100,`壬申`,4,`癸卯`,`(외부의 결속 화합사가 있으나 번잡하고 늦다.\n허비가 따르는 귀인사도 있다.)`,`● 丑ㆍ卯ㆍ未월 생자는 처가 현명하고 자식은 효도할것이다.   (출산 O)`,`● 년월에 辰이 같이 있으면 醫ㆍ卜者 가 된다.`,`● 子월 생은 대운 水ㆍ未운에 貴하게 된다.   이상보아 (출산 O)`,``,0,0,0,0],
    [101,`壬申`,5,`甲辰`,`(타인의 도움이 필요하다.\n침체ㆍ부유심이 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 子월 출생자는 재난이 있게 된다.   (출산 X)`,`● 丑ㆍ寅년월 출생자는 문관으로 武權을 갖는다.   (출산 O)`,`● 년월에 나란히 子가 되면 대운 金운에 西方에서 발달한다.   이상보아 (출산 O)`,``,0,0,0,0],
    [102,`壬申`,6,`乙巳`,`(외부의 이별사가 있다.\n上人과 財 문제를 논의하고 大를 원하나 小가 된다.)`,`● 未월 생자는 대운 北方운에 貴하게 된다.   이상 확인 후 (출산 O)`,`● 辰ㆍ戌ㆍ丑 월 생도 貴하다. 단, 自刑이 요구 된다.`,``,``,0,0,0,0],
    [103,`壬申`,7,`丙午`,`(女人ㆍ妻가 임신했다.\n상대로부터 압박ㆍ침해사가 있다.)`,`● 金ㆍ水월 출생자는 貴하다.   (출산 O)`,`● 총재격이 되어 吉하다.   (출산 O)`,`● 위 두가지가 안되면 극빈하게 된다.   (출산 X)`,``,0,0,0,0],
    [104,`壬申`,8,`丁未`,`(他人의 도움을 얻고자 한다.\n고립적인 일이 있고 돌발적이다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 巳ㆍ午년월 출생자는 大富가 된다.   (출산 O)`,`또는 천간에 戊ㆍ己가 보이면 富貴 겸전이다.   (출산 O)`,``,``,0,0,0,0],
    [105,`壬申`,9,`戊申`,`(財불성ㆍ유시무종하고 모사도 불성한다.\n가정ㆍ내부가 막혔다.
  금전ㆍ여인과의 손해도 있다.)`,`● 寅ㆍ卯ㆍ未월 출생자는 편관을 제하면 吉하다.   편관제복 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [106,`壬申`,10,`己酉`,`(男ㆍ女共히 색정사가 있다.\n가정의 손모가 크다.
  색정사가 끝나거나 사이가 별리 된다.)`,`● 충과 파가 보이지 않으면 大吉하다.   충파 확인 후 (출산 O)`,`● 戌월 생자는 대운 東方운에 貴하게 된다.   이상 확인 후 (출산 O)`,``,``,0,0,0,0],
    [107,`壬申`,11,`庚戌`,`(女는 夫를 亡하게 하는 일을 하고 있고, \n男은 직위변경 손상이 있다.
  매사 불성하고 山氣人이다.
  권리를 상실한다.)`,`● 丑ㆍ戌년월 출생자 吉하다.   (출산 O)`,`● 木ㆍ火월 생자도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [108,`壬申`,12,`辛亥`,`(외교가 막혔다.\n도량은 크다. 허명이다.
  모망사는 불성한다.
  고독하다. 산기인이다.
  의복의 도에 의지해야 자립할 人이다.)`,`● 辰ㆍ戊ㆍ丑ㆍ未월 출생자 吉하다.   (출산 O)`,`● 년주ㆍ월주 천간에 己ㆍ土가 없으면 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [109,`癸酉`,1,`壬子`,`(직위ㆍ녹위를 증가하려 한다.\n外合이 있고 가정은 파재ㆍ女子가 손상한다.)`,`● 대체로 凶하다.`,`● 丙寅월, 乙巳월, 丁酉월 생자는 凶死한다.   (출산 X)`,``,``,0,0,0,0],
    [110,`癸酉`,2,`癸丑`,`(外가 막혔다.\n內는 단결 개신ㆍ별리사가 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 복덕수귀격으로 학문을 깊이하여 박식하나, 진퇴가 심하고, 지나치게 강하면 형옥을 당하게 될것이다.   (출산 X)`,``,``,``,0,0,0,0],
    [111,`癸酉`,3,`甲寅`,`(실직사에 관련된다.\n파재ㆍ손해사도 있다.
  일이 급하게 진행되고 강제성을 갖는다.)`,`● 金神격이다.`,`● 寅ㆍ午ㆍ戌월 생자는 직위가 1급에 이른다.   (출산 O)`,`● 丑년생은 不貴하다.   (출산 X)`,`● 巳ㆍ未ㆍ亥ㆍ子년월 출생자는 부자이고 장수한다.   (출산 O)`,`● 辰ㆍ丑 년월 생은 천간에 甲ㆍ丁이 보이면 凶하다.   (출산 O)`,0,0,0],
    [112,`癸酉`,4,`乙卯`,`(귀인사에 관련되고, \n男ㆍ女간 동요 불안한 가정이고 신분이 변경된다.
  분리사이다.)`,`● 申ㆍ子ㆍ辰월 생자는 貴하다.   (출산 O)`,`● 寅ㆍ午ㆍ戌월 생과 丑ㆍ卯ㆍ未월 생자는 평범하다.`,`● 巳ㆍ酉ㆍ丑월 생자는 부유하게 산다.   (출산 O)`,``,0,0,0,0],
    [113,`癸酉`,5,`丙辰`,`(外에서 토대가 흔들린다.\n內로는 형제ㆍ붕우간 화합이 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 貴는 있으나 고독하다.`,`● 巳ㆍ酉ㆍ丑월 생자는 貴하다.   (출산 O)`,`● 寅ㆍ卯월 생자는 不吉하다.   (출산 X)`,`● 子ㆍ午월 생자는 不吉하다.   (출산 O)`,`● 戌월 생자는 貴하다.   (출산 O)`,0,0,0],
    [114,`癸酉`,6,`丁巳`,`(內ㆍ外결속하여 개신하려 한다.\n귀인사가 있고 명예에 관련된다.)`,`● 처음은 곤중하나 후에 발달한다.`,`● 巳ㆍ酉ㆍ丑월 생자는 정관격이나 인수격이 되면 貴하다.`,`● 丑ㆍ卯ㆍ未월 생자는 식신ㆍ상관격이 되면 貴하다.   이상 격을 보아 (출산 O)`,`● 午ㆍ戌월 생은財旺하면 吉하다.   財旺 보아 (출산 O)`,0,0,0,0],
    [115,`癸酉`,7,`戊午`,`(부부불합하고, 색정사로 가택이 불안하다.\n관송사의 불씨가 있다.)`,`● 처를 잃고 돈도 잃게 된다. 시작은 있어도 끝이 없다.   (출산 X)`,`● 子년생은 자식이 貴하게 되고, 대운 南方운에 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [116,`癸酉`,8,`己未`,`(外에서 바라는 일이 안되고 골육간 동요이다.\n기능이 마비ㆍ고갈되었다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 寅ㆍ巳ㆍ申ㆍ酉ㆍ戌월년 출생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [117,`癸酉`,9,`庚申`,`(사업을 위한 가정의 財가 많이 손모 되었다.\n실수가 있다.)`,`● 卯ㆍ酉ㆍ戌ㆍ寅년월 출생자는 貴하다. 이상 金ㆍ火ㆍ未운에 대길하다.   (출산 O)`,``,``,``,0,0,0,0],
    [118,`癸酉`,10,`辛酉`,`(外는 단결ㆍ개신하나 \n內는 막혔다.
  심정은 웅대하나 실정은 허약하다.)`,`● 申ㆍ酉월출생자는 처가와 파란을 겪는다.`,`● 子ㆍ卯년월 생은 천간에 庚ㆍ辛이 있으면 大貴하게 된다.   (출산 O)`,`● 丑월 생은 단명 한다.   (출산 X)`,``,0,0,0,0],
    [119,`癸酉`,11,`壬戌`,`(外는 財부족ㆍ內는 수하 농락ㆍ재병손상이 있다.\n매사 불성하고 女는 夫를 亡하게 하고 男은 직위손상이 있다.
  山氣人이다.
  권리를 상실한다.)`,`● 丑ㆍ子월 생은 재능이 많게 된다.`,`● 봄철 출생자는 평범한 삶이다.`,`● 여름철 출생자는 吉하다.   (출산 O)`,`● 丑ㆍ辰ㆍ戊월생자는 富貴 겸전하게 된다.   (출산 O)`,0,0,0,0],
    [120,`癸酉`,12,`癸亥`,`(他人을 돕는 일에 財가 손모되고 별리ㆍ이가의 사정에 있다.\n매사 불성하고 산기인이다.
  형제 재액이 있다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자 부자로 산다.   (출산 O)`,`● 酉월출생자는 대운 東ㆍ北운에 읍ㆍ면장직을 갖는다.   (출산 O)`,`● 申월생자는 대운 東方운에 사법직에 오른다.  대운 확인 후 (출산 O)`,``,0,0,0,0],
    [121,`甲戌`,1,`甲子`,`(內ㆍ外 재액 징조가 있다.\n상대를 압박한다.)`,`● 乙卯월 출생자는 형액이 있다.   (출산 X)`,`● 乙亥월 출생자는 시비 大難을 겪는다.   (출산 X)`,`● 申ㆍ巳ㆍ酉ㆍ丑월 金이 나오면 大貴하다.   (출산 O)`,``,0,0,0,0],
    [122,`甲戌`,2,`乙丑`,`(上人에 관련하여 금전의 경위를 따지는 일이 있다.\n금전ㆍ여인ㆍ상업사이고 하극상ㆍ財부족이 있다.)`,`● 大富로 산다. 단, 초년 고생을 면치 못한다.   (출산 O)`,`● 寅년 출생자는 吉하다.   (출산 O)`,`● 子월 생은 西方운에 吉하다.   이상 확인 후 (출산 O)`,``,0,0,0,0],
    [123,`甲戌`,3,`丙寅`,`(외교가 막혔다.\n他人이 부탁한 일이 안 풀린다.)`,`● 년월이 土가 되면 부자로 산다.   (출산 O)`,`● 년월에 金이 되면 일생 반복한다.   (출산 X)`,`● 亥ㆍ子ㆍ卯월 출생자는 吉하다.   (출산 O)`,``,0,0,0,0],
    [124,`甲戌`,4,`丁卯`,`(男ㆍ女 共히 색정사가 있다.\n금전ㆍ女人의 손모 갈등이 있다.
  형제ㆍ친구가 관련된다.)`,`● 亥월 생은 재능이 있다.   (출산 O)`,`● 卯월 생은 충ㆍ합되는 대운에 不吉해진다.   (출산 X)`,`● 년월 천간이 庚이 되고 년월 지지가 申ㆍ酉가 나란히 있게 되면 요절한다.   (출산 X)`,``,0,0,0,0],
    [125,`甲戌`,5,`戊辰`,`(內의 동요 불안이 기한다.\n하극상이고 희비가 불명하다.)`,`● 大富가 된다.`,`● 寅ㆍ亥년월 출생자는 少將직위에 오른다. 단, 日時 충 되어 초년 고생하고 중년에 발복한다.   (출산 O)`,``,``,0,0,0,0],
    [126,`甲戌`,6,`己巳`,`(外에서 도주관사ㆍ거지곤란의 급속사가 있다.\n他人의 도움을 필요로 한다.)`,`● 財格이 되면 大貴이다.   (출산 O)`,`● 戌년월 출생자는 고위직에 오른다.   (출산 O)`,``,``,0,0,0,0],
    [127,`甲戌`,7,`庚午`,`(內ㆍ外 多人의 의사가 모아지고 문화광명하나 결실은 없다)`,`● 辰ㆍ戌월 출생자는 貴가 아니면 富를 쌓는다.   (출산 O)`,`● 丑월 생은 대운 南方운에 사법기관의 일원이 된다.   이상 보아 (출산 O)`,`● 寅월 생은 淸貴하게 산다.`,``,0,0,0,0],
    [128,`甲戌`,8,`辛未`,`(침체된 사정이고 귀인과 관련하여 財의 경위를 따진다.\n송사건이 되었다.)`,`● 초년 고생, 말년 貴하다.`,`● 寅ㆍ卯ㆍ酉ㆍ丑년월 출생자는 吉하다.   (출산 O)`,`● 申ㆍ子년월 생은 文貴하다.   (출산 O)`,``,0,0,0,0],
    [129,`甲戌`,9,`壬申`,`(침체된 사정이고 귀인과 관련하여 財의 경위를 따진다.\n송사건이 되었다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未ㆍ亥ㆍ寅년월 출생자는 吉하다.   (출산 O)`,`● 午ㆍ酉월 생은 장수하나 가난한 삶이 된다.   (출산 X)`,``,``,0,0,0,0],
    [130,`甲戌`,10,`癸酉`,`(男 직위손상, 女 남편을 망하게 한다.\n변심했다.
  손해실주사가 있다.
  山氣人이다.
  권리를 상실한다.)`,`● 子ㆍ戌년월 출생자는 文名이 있게 된다.   (출산 O)`,`● 午월 출생자는 貴는 적고 富를 이룬다.   (출산 O)`,``,``,0,0,0,0],
    [131,`甲戌`,11,`甲戌`,`(가정 내부가 꽉 막혔다.\n고립된 입장이고 큰 일에 부적하다.)`,`● 평범하게 살것이다.`,`● 卯월 생은 凶하다.   (출산 X)`,`● 천간에 庚ㆍ辛이 있고 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,``,0,0,0,0],
    [132,`甲戌`,12,`乙亥`,`(모두 화합중 상쟁이 있다.\n웃음 속에 칼이 있다.
  他人을 압박한다.
  급속사이다.)`,`● 가을철 출생자는 평범하게 산다.`,`● 여름철 출생자는 노력하나 돈을 못 모은다.   (출산 X)`,`● 봄ㆍ겨울 출생자는 대운 土운에 貴해진다.   이상 확인 후 (출산 O)`,``,0,0,0,0],
    [133,`乙亥`,1,`丙子`,`(外의 多人 의사가 취합된다.\n男ㆍ女 共히 색정사가 있다.
  上人의 발탁후원사에 관련된다.)`,`● 庚辰년월 출생자는 富貴하게 살것이다.   (출산 O)`,`● 년월지지가 나란히 戌이나 亥가 되면 기복이 심하다.   (출산 X)`,`● 丑월 생은 대운 南方운에 吉하게 되나 巳ㆍ午ㆍ酉운에는 극빈하게 된다.   (출산 X)`,`● 壬辰월 乙酉월 출생자는 貴한중에 凶死하게 된다.   (출산 X)`,0,0,0,0],
    [134,`乙亥`,2,`丁丑`,`(파재ㆍ실주사가 있다.\n內가 불안고정이 있다.
  가문이 기울고 가세가 고갈되는 형편이다.)`,`● 성급하므로 조심을 해야 한다.   (출산 X)`,`● 午월 출생자는 庚이나 壬이 천간에 있으면 貴하다.   확인 후 (출산 O)`,``,``,0,0,0,0],
    [135,`乙亥`,3,`戊寅`,`(패후재성한다.\n웃음 속에 칼이 숨었다.
  他人을 도우려다 생긴 일이다.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 노력하는 삶이다.   (출산 X)`,`● 가을철ㆍ겨울철 생은 吉하다.   (출산 O)`,``,0,0,0,0],
    [136,`乙亥`,4,`己卯`,`(형제ㆍ친구에 관련되고 \n外는 근심 
  內는 화합사가 있다.)`,`● 寅ㆍ巳월 출생자는 庚이 없으면 貴하다. 년월 천간 庚 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [137,`乙亥`,5,`庚辰`,`(외교가 막혔다.\n가정파재ㆍ실주사가 있다.
  남자가 상한다.
  반복대립한다.)`,`● 戊ㆍ癸년월 천간 출생자는 吉하다.   (출산 O)`,`● 申ㆍ卯월, 巳ㆍ酉ㆍ丑년월로 반합이 되면, 대운 木ㆍ土운에 大貴하게 된다.  대운 확인하여(출산 O)`,``,``,0,0,0,0],
    [138,`乙亥`,6,`辛巳`,`(직위변동ㆍ가정동요불안ㆍ부부갈등사가 있다.\n자식건도 있다.
  원행ㆍ이동사가 있다.)`,`● 巳ㆍ午월 출생자는 吉하다.   (출산 O)`,`● 가을철 출생자는 질병이 있게 된다.   (출산 X)`,`● 봄철 출생자는 천간에 金이 있으면 사법직을 갖는다.   (출산 O)`,``,0,0,0,0],
    [139,`乙亥`,7,`壬午`,`(박관ㆍ실직사에 관련된다.\n상대를 압박한다.)`,`● 봄ㆍ여름ㆍ겨울 출생자는 吉하다.   (출산 O)`,`● 가을철 출생자는 반복하는 삶을 산다.   (출산 X)`,``,``,0,0,0,0],
    [140,`乙亥`,8,`癸未`,`(재병이 있고, 실탈건 ㆍ적침사에 관련된다.)`,`● 봄철 출생자는 처자가 형액을 입는다.   (출산 X)`,`● 申월 생은 貴하다.   (출산 O)`,`● 酉월 생은 년월에 火가 보이면 길하다.   (출산 O)`,`● 午ㆍ未ㆍ戌년이나 월 출생자는 지위가 높게 된다.   (출산 O)`,0,0,0,0],
    [141,`乙亥`,9,`甲申`,`(男은 직위 손상하고 \n女는 夫를 망하게 한다.
  매사불성한다.)`,`● 가을철 출생자는 貴하다.`,`● 丑ㆍ卯ㆍ未ㆍ午월 출생자는 吉하다. 단, 자식 인연이 멀다.`,``,``,0,0,0,0],
    [142,`乙亥`,10,`乙酉`,`(男은 직위가 변경되고 女는 夫를 망하게 한다.\n外의 화합이 있고 매사불성한다.
  권리를 상실한다.)`,`● 봄철 출생자는 어질고 수명이 길다.   (출산 O)`,`● 대체로 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [143,`乙亥`,11,`丙戌`,`(外의 이별사가 있다.\n하극상을 당한다.
  유명무실하다.
  內는 불안ㆍ고해가 있다.
  권리를 상실한다.)`,`● 혈액질환이 있게 된다.`,`● 亥ㆍ卯ㆍ未월 출생자는 천간에 戊ㆍ己가 보이면 부자가 된다.   (출산 O)`,``,``,0,0,0,0],
    [144,`乙亥`,12,`丁亥`,`(모든 일이 막혔다.\n금전ㆍ손해ㆍ여인관계가 관련되어 있다.
  上人의 후원사가 반복되고 개변이 많다.)`,`● 寅ㆍ卯월 생 자가 천간에 戊ㆍ己가 보이면 부자이다.   (출산 O)`,`● 丑ㆍ辰월 생은 대운 金ㆍ火운에 貴하게 된다.`,`● 亥ㆍ子ㆍ申월 생은 천간에 戊ㆍ己가 보이면 길하다.   이상 확인 후 (출산 O)`,``,0,0,0,0],
    [145,`丙子`,1,`戊子`,`(내부사가 꽉 막혔다.\n금전손해ㆍ여인사가 있다.
  신규사가 있고 자고심이 강하다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 寅ㆍ巳ㆍ卯ㆍ未월 생은 貴하다.   (출산 O)`,`● 亥ㆍ子월 생은 가난하고 단명하다.   (출산 X)`,`● 戌월 생은 대운 火ㆍ土운에 小貴해진다.   (출산 O)`,`● 丁巳월 생은 요절한다.   (출산 X)`,`● 己酉월 생은 파란곡절이 심하게 된다.   (출산 X)`,0,0,0],
    [146,`丙子`,2,`己丑`,`(부부갈등이 있고 명예가 손상된다.\n男은 직위가 흔들린다.
  가정내 화합사가 있다.)`,`● 寅ㆍ亥ㆍ申ㆍ辰년월 출생자는 천간에 財ㆍ官ㆍ食이 보이면 貴하게 된다.   천간 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [147,`丙子`,3,`庚寅`,`(외부에서 대립ㆍ반목사가 있다.\n他人에게 도움주는 일에 관련 있다.
  이동사가 있다.)`,`● 子월 생은 貴에 가깝다.   이상 水ㆍ木운에 吉하다.   이상 확인 후 (출산 O)`,`● 未ㆍ申ㆍ午년월 생은 무관직에 오르나 수명이 짧다.   (출산 X)`,``,``,0,0,0,0],
    [148,`丙子`,4,`辛卯`,`(내부적으로 무례한 일을 겪는다.\n관근심이 있고 他人을 돕는일에 관련된다.)`,`● 亥ㆍ子월 생은 吉하다. 단, 戊ㆍ己가 보이지 않아야 한다.   戊ㆍ己 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [149,`丙子`,5,`壬辰`,`(부부갈등이 있고 내부에 多人의 의사가 수렴되나 일은 늦어진다.\n男은 직위손상이 있다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 편관 제복이 있으면 吉하다.   확인 후 (출산 X)`,`● 亥ㆍ卯년이나 월 출생자는 부귀하다.   (출산 O)`,``,``,0,0,0,0],
    [150,`丙子`,6,`癸巳`,`(외교가 막혔다.\n위탁사가 안풀린다.
  명예사가 있고 입신사이다.)`,`● 녹마호환격이라 한다.`,`● 壬ㆍ己ㆍ寅ㆍ亥가 보이지 않으면 大貴자이다.   확인 후 (출산 O)`,``,``,0,0,0,0],
    [151,`丙子`,7,`甲午`,`(금전이 소모되고 女子와의 갈등도 있다.\n형제ㆍ친구가 관련되었다.
  男ㆍ女 다툼으로 동요ㆍ불안하고 일이 두가지 이다.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 고독하다.   (출산 X)`,`● 가을철 출생자는 재복이 있다.   (출산 O)`,`● 겨울철 출생자는 관직자로 貴하다.   (출산 O)`,`단, 공히 자식이 貴하고 만성자이다. 대운 火ㆍ土운에 발달한다.`,0,0,0],
    [152,`丙子`,8,`乙未`,`(갈등관계로 부부간ㆍ직위가 손상된다.\n손해사ㆍ실주사가 있다.)`,`● 겨울 생은 貴하다.   (출산 O)`,`● 봄ㆍ여름 생은 吉하다.   (출산 O)`,`● 가을 생은 반복하며 산다.   (출산 X)`,``,0,0,0,0],
    [153,`丙子`,9,`丙申`,`(모망사가 불성한다.\n合중 반목이 있게 된다.
  女ㆍ妻에게 재난이 기한다.
  일이 늦다.
  이권을 상실한다.)`,`● 寅ㆍ卯월 출생자는 대운 木ㆍ火운에 발달한다.`,`● 년월에 金이 나란히 있어 종재격이 되면 吉하다.   이상 대운ㆍ격 확인 후 (출산 O)`,``,``,0,0,0,0],
    [154,`丙子`,10,`丁酉`,`(외부는 결속되나,\n내부적으로는 금전ㆍ여자가 소모된다.
  上人과 금전을 따진다.
  이권을 상실한다.)`,`● 봄철 생은 온순하며 산다.`,`● 여름철 생은 貴하다.   (출산 O)`,`● 가을철 생은 평범하게 산다.`,`● 겨울철 생은 吉하다.   (출산 O)`,0,0,0,0],
    [155,`丙子`,11,`戊戌`,`(부부갈등이 있다.\n직위가 손상된다.
  상대로부터 압박을 받는다.
  기능이 상실된다.)`,`● 복과 수명이 좋고 길고 명리가 크다.   (출산 O)`,`● 亥ㆍ卯ㆍ未ㆍ申ㆍ酉ㆍ戌ㆍ巳년월 생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [156,`丙子`,12,`己亥`,`(외부의 일이 단절되고 이별사가 있다.\n上人의 도움을 원하나 질책을 받는다.
  사서 고생하는 격이다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 가을철, 겨울철 출생자는 평범하게 산다.`,`● 未월 생은 대운 水ㆍ木운에 吉하고, 子월 생은 대운 東운에 吉하다.`,`● 申ㆍ亥ㆍ丑ㆍ戌년월 출생자는 吉하다.   (출산 O)`,``,0,0,0,0],
    [157,`丁丑`,1,`庚子`,`(관사구설ㆍ암중재해가 있다.\n內는 부부융합의 화합사가 있다.
  夫에 관련한 일이기도 하다.
  천강사장 여부?)`,`● 木ㆍ火월 출생자는 貴하고, 또는 대운 木ㆍ火운에 貴해진다.   (출산 O)`,`● 亥ㆍ子년월 출생자도 貴하다.`,`● 단, 먼저는 곤궁하고 후에 吉하다.   (출산 O)`,``,0,0,0,0],
    [158,`丁丑`,2,`辛丑`,`(內ㆍ外 동요 불안ㆍ이별사가 있고 막혔다.\n침체되고 직위손상ㆍ부부갈등ㆍ자손문제가 겹친다.)`,`● 申ㆍ酉월 생은 관직자가 된다.   (출산 O)`,`● 년월에 子나 寅이 나란히 있으면 무관 少將지위에 오른다.   (출산 O)`,`● 亥ㆍ子월 출생자는 신고한 삶을 산다.   (출산 X)`,`● 辰월 생은 위엄이 있는 貴가 있다.   (출산 O)`,0,0,0,0],
    [159,`丁丑`,3,`壬寅`,`(가정 내부가 압박된다.\n內ㆍ外 불안하다.
  강제적인 일을 겪는다.)`,`● 貴하다.   (출산 O)`,`● 寅ㆍ卯년월 출생자는 대운 金ㆍ水운에 貴하게 된다.`,`● 亥ㆍ子월 생은 대운 西方운에 貴하다.   이상 대운 보아 (출산 O)`,``,0,0,0,0],
    [160,`丁丑`,4,`癸卯`,`(外는 단결하나 內는 병채ㆍ실탈의 일이 있어 불안하다.\n정신ㆍ압박ㆍ피로가 있다.)`,`● 辰ㆍ戌ㆍ己ㆍ未월 생자는 사법직이나 형무를 담당한다.   (출산 O)`,``,``,``,0,0,0,0],
    [161,`丁丑`,5,`甲辰`,`(자손사ㆍ박관사ㆍ직위 손상사가 있다.\n가운이 쇠퇴하는 징조이다.
  예기치 못한 일이다.)`,`● 亥ㆍ子월 생은 富하다.   (출산 O)`,`● 申월 생은 대운 東方운에 貴해진다.   (출산 O)`,`● 午월 생은 대운 金ㆍ水운에 貴해진다.   (출산 O)`,`● 년월에 寅이 같이 있으면 사법기관의 長이 된다.   (출산 O)`,0,0,0,0],
    [162,`丁丑`,6,`乙巳`,`(타인에게 은혜를 베풀다 금전손모 변동이 생겼다.\n형제ㆍ동료가 관련 되었다.
  女ㆍ妻와 갈등이 있다.)`,`● 봄철 생은 부자로 산다.`,`● 가을철 생은 吉하다.`,`● 겨울철 생은 貴하다.   이상 (출산 O)`,`● 여름철 생은 고독하다.   (출산 X)`,0,0,0,0],
    [163,`丁丑`,7,`丙午`,`(外는 공직 상관의 합이 있으나\n內는 부부불합 관송불리사가 있다.
  男ㆍ女 색정사가 있다.)`,`● 寅ㆍ卯ㆍ戌ㆍ未 년월 생은 貴하다.`,`● 酉ㆍ丑월 생은 吉하다.   이상 (출산 O)`,`● 亥ㆍ子월 생자은 凶하다.   (출산 X)`,``,0,0,0,0],
    [164,`丁丑`,8,`丁未`,`(內ㆍ外 동요 불안하고 막혔다.\n자손ㆍ박관ㆍ불명예가 기한다.
  모망사 불성하고 골육상배 한다)`,`● 일시 충으로 말년이 不吉하다.   (출산 X)`,`● 만약 년월에 辰ㆍ戌이 되면 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [165,`丁丑`,9,`戊申`,`(매사 불성하고 금전ㆍ여인이 손상된다.\n타인을 돕는 일에 관련 되었다.
  山氣人이다.
  이권을 상실한다.)`,`● 未ㆍ申년월 생은 貴하다.   이상 東ㆍ南운에 大吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [166,`丁丑`,10,`己酉`,`(신규개시사가 있다.\n上人과 財의 경위를 밝힌다.
  매사 불성하고 산기인이다.
  이권을 상실한다.)`,`● 辰ㆍ巳ㆍ午ㆍ未ㆍ申ㆍ戌년월 출생자는 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [167,`丁丑`,11,`庚戌`,`(자손사ㆍ박관사ㆍ부부갈등이 있다.\n內ㆍ外 하극상의 관사가 있다.
  財 부족의 경우이다.)`,`● 처의 걱정이 많고 자식을 상하게 된다.   (출산 X)`,`● 寅ㆍ亥ㆍ申ㆍ酉년월 생은 少將지위에 오른다.   (출산 O)`,``,``,0,0,0,0],
    [168,`丁丑`,12,`辛亥`,`(귀인사에 관련된다.\n추천 발탁사로 스스로 사서 고생하는 격이다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 寅ㆍ卯년월 출생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [169,`戊寅`,1,`壬子`,`(처ㆍ첩 임신사도 있다.\n재난이 있다.
  他人의 도움을 얻는 일이다.)`,`● 丑ㆍ酉년월 생은 吉하다.   (출산 O)`,`● 己巳월, 己亥월 생자는 凶하고 형액을 당한다.   (출산 X)`,``,``,0,0,0,0],
    [170,`戊寅`,2,`癸丑`,`(上人과의 관계에서 금전손모가 있다.\n女子와 갈등이 있고 동료 형제가 관련된다.
  상대를 압박한다.)`,`● 여름철 출생자는 吉하다.   (출산 O)`,`● 가을, 겨울철 출생자는 평범하게 산다.`,``,``,0,0,0,0],
    [171,`戊寅`,3,`甲寅`,`(外로는 대립ㆍ반목이 있고 \n內는 막혔다.
  일이 급속히 전개되는데 금전ㆍ여자가 걸림돌이다.
  천강사장 여부?)`,`● 巳ㆍ午년월 출생자는 골육이 같이 못살게 된다.`,`● 午월 생과 子월 생은 대운 西ㆍ北운에 병권을 쥐고 이름을 날리게 된다.   (출산 O)`,``,``,0,0,0,0],
    [172,`戊寅`,4,`乙卯`,`(男ㆍ女 共히 색정사가 있다.\n內로 허비가 많다.
  일의 결과가 망신스럽게 된다.
  천강사장 여부?)`,`● 亥ㆍ子월 출생자은 처가 현명하고 자식은 효자이다.   (출산 O)`,`● 卯ㆍ辰ㆍ寅년월에 있으면 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [173,`戊寅`,5,`丙辰`,`(금전이 손모되고 女관계가 소원해 진다.\n기능이 정지되는 형세이다.
  상대를 압박한다.)`,`● 중년에 大貴하게 될것이다.   (출산 O)`,`● 子월 생은 대운 北方운에 貴하게 된다.   (출산 O)`,``,``,0,0,0,0],
    [174,`戊寅`,6,`丁巳`,`(직녹을 구하는 일이다.\n명예사가 있다.
  외교가 막혀 부탁받은 일이 안 풀린다.
  자칫 탄로 난다.)`,`● 처자를 잃을 걱정이 있게 된다.   (출산 X)`,`● 子ㆍ午월 생자는 대운 東ㆍ北운에 사법관이 된다.   이상보아 (출산 O)`,``,``,0,0,0,0],
    [175,`戊寅`,7,`戊午`,`(內는 결속되나 일이 지연되고 결국 흩어진다.\n상대를 돕는 일이 있다.)`,`● 戌월 생은 천간에 癸가 보이면 貴하게 된다.   (출산 O)`,`● 년월에 申ㆍ子 반합이 되면 역시 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [176,`戊寅`,8,`己未`,`(上人과의 관계에서 금전이 손모되었다.\n女子관계도 소원해 졌다.
  상대를 압박한다.)`,`● 처음은 공궁하고 나중에는 풀린다. 월간에 甲이 있고 지지에 午ㆍ未가 있게 되면 貴하다.   확인 하여 (출산 O)`,``,``,``,0,0,0,0],
    [177,`戊寅`,9,`庚申`,`(부처간에 반목이 있다.\n二心으로 가정이 동요 불안하다.
  他人에게 속는다.
  원행 별리사가 있다.)`,`● 凶하다.   (출산 X)`,`● 寅월 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [178,`戊寅`,10,`辛酉`,`(外에서 개신ㆍ개혁ㆍ단합하나 지루하다.\n가정손모가 있고 불성하고 속는일이 있다.)`,`● 월지가 辰ㆍ戌ㆍ丑ㆍ未가 되면 대운 西ㆍ北운에 부자 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [179,`戊寅`,11,`壬戌`,`(동료ㆍ형제가 관련되어 금전과 女子가 손모 되었다.\n일이 침체되고 상대를 압박한다.)`,`● 寅ㆍ午ㆍ戌ㆍ辰년월 출생자는 대운 金ㆍ水원에 大貴하게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [180,`戊寅`,12,`癸亥`,`(外 이별사 있고 결과가 기대에 못 미친다.\n옛 인연이 다했고 패후 다시 이루는 형세이다.)`,`● 午ㆍ未월 출생자는 이름이 높게 된다.   (출산 O)`,`● 酉ㆍ丑년월 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [181,`己卯`,1,`甲子`,`(무례한 봉변이 있고 관사구설 등 혼자만 아는 재해를 겪는다.\n上人과의 금전사가 있다.)`,`● 초년은 고생하나 후에 발달한다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 출생자는 貴하다.   (출산 O)`,`● 己巳월생자는 파란곡절이 있다.   이하 모두 (출산 X)`,`● 庚申월 생은 혈광재앙이 있다.`,`● 壬子월 생은 형액을 당한다.`,0,0,0],
    [182,`己卯`,2,`乙丑`,`(골육간 상배하고 外 이별사가 있다.\n금전ㆍ女子가 손모 되었다.
  상대를 압박한다.)`,`● 子ㆍ申월생자는 대운 金ㆍ土운에 貴하게 된다.   이상 보아 (출산 O)`,`● 巳ㆍ酉ㆍ丑ㆍ戌월 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [183,`己卯`,3,`丙寅`,`(內에서 금전의 고통이 있다.\n가정내 강제적인 손모ㆍ재병사가 있다.
  초전이 본점시를도우면 몸이 상한다.)`,`● 午ㆍ辰월년 출생자는 문장으로 출세한다(출산 O)`,`● 寅ㆍ午월 출생자 당선직을 갖는다.   (출산 O)`,``,``,0,0,0,0],
    [184,`己卯`,4,`丁卯`,`(外에서는 多人결속이 되나 內에서는 막혀있다.\n금전손해ㆍ여인과의 이별사가 있다.
  천강사장 여부?)`,`● 巳ㆍ酉ㆍ丑년월 출생자는 문관으로 출세한다.   (출산 O)`,`● 三合木局 이 되면 형액을 당한다.   (출산 X)`,``,``,0,0,0,0],
    [185,`己卯`,5,`戊辰`,`(만사상쟁하고 공허하여 무력하다.\n금전과 여자와의 갈등이 크다.
  상대를 압박한다.)`,`● 卯월 출생자는 사법에 종사한다.   (출산 O)`,``,``,``,0,0,0,0],
    [186,`己卯`,6,`己巳`,`(他人을 돕는 일에 관련된다.\n원행이동, 또는 별리사가 있다.)`,`● 大富가 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [187,`己卯`,7,`庚午`,`(직위ㆍ녹위를 증가 하는 일이다.\n外는 결속되나 
  內는 가정이 흔들린다.
  男ㆍ女 共히 색정사가 있다.)`,`● 년월에 甲ㆍ丙ㆍ寅ㆍ午가 보이면 大貴하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [188,`己卯`,8,`辛未`,`(外交가 막혀있다.\n금전과 여자 문제가 시끄럽다.
  형제나 친구가 관련 되었다.)`,`● 酉ㆍ戌ㆍ寅ㆍ卯ㆍ未년월 출생자는 대운 東ㆍ南운에 貴하게 된다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [189,`己卯`,9,`壬申`,`(上人과의 관계에서 속고 있다.\n매사불성하고 가정도 불안하다.
  자손사ㆍ박관사도 있다.)`,`● 辰월 출생자는 일찍 발달하게 될것이다.   (출산 O)`,`● 亥월 출생자는 대운 南方운에 좋아진다.   (출산 O)`,``,``,0,0,0,0],
    [190,`己卯`,10,`癸酉`,`(신분ㆍ가정ㆍ위치를 바꾸는 입장에 있다.\n속는 일이 있고 동요ㆍ불안하다.
  색정문제에 고심 있다.)`,`● 형액을 당한다.   (출산 X)`,`● 巳월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [191,`己卯`,11,`甲戌`,`(外는 형상급속사가 있고 \n內는 형제ㆍ동료의 단결이 있다.
  금전이 손모 되었다.)`,`● 丙ㆍ丁ㆍ午ㆍ丙년월 출생자는 金이 보이면 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [192,`己卯`,12,`乙亥`,`(外ㆍ內결속되나 번잡하다.\n먼저 숙이고 나중에 펴려는 심정이다.)`,`● 대운 木ㆍ金운에 少將 직위에 오른다.   (출산 O)`,``,``,``,0,0,0,0],
    [193,`庚辰`,1,`丙子`,`(박관ㆍ夫와의 갈등도 있다.\n內ㆍ外결속사가 있고 일이 연이어 발생한다.)`,`● 木ㆍ火월 출생자는 대운 西方운에 吉하다.   이상 보아 (출산 O)`,`● 乙丑월 생은 파란곡절을 겪는다.   (출산 X)`,`● 丁丑월 생은 형액을 당한다.   (출산 X)`,`● 己丑월생도 凶하다.   (출산 X)`,0,0,0,0],
    [194,`庚辰`,2,`丁丑`,`(上人의 후원을 받고자 한다.\n사업에 관련된다.
  가문이 기우는 형세에 있다.)`,`● 丑월 생은 부자이나 수명이 짧다.   (출산 O)`,`● 大貴恣을 측근에서 보필하게 된다.   (출산 O)`,``,``,0,0,0,0],
    [195,`庚辰`,3,`戊寅`,`(부부이심ㆍ이별사가 있다.\n作崇의 변고이다.)`,`● 봄철과 여름철 출생자는 천간에 丙ㆍ丁이 보이면 辰ㆍ丑ㆍ未대운이나 申ㆍ酉대운에 貴하게 된다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [196,`庚辰`,4,`己卯`,`(신규사가 있고 공허ㆍ무력하다.\n손해ㆍ상쟁한다.)`,`● 고독하다.   (출산 X)`,``,``,``,0,0,0,0],
    [197,`庚辰`,5,`庚辰`,`(內가 막혔다.\n금전손해가 있고 여자문제도 있다.
  자고심이 있다.)`,`● 戌 충운에 貴하다.   대운 보아 (출산 O)`,``,``,``,0,0,0,0],
    [198,`庚辰`,6,`辛巳`,`(外의 결속이 있으나 대립ㆍ반목이 된다.\n강제성이 있다.
  上ㆍ下 불순하다.
  초전이 본점시를 도우면 몸이 상한다.)`,`● 대운 西ㆍ南운에 吉하나 말년이 고독하다.`,``,``,``,0,0,0,0],
    [199,`庚辰`,7,`壬午`,`(他人에 의존한다.\n재난이 있다.
  별리한다.
  초전이 본점시를 도우면 몸이 상한다.)`,`● 절조가 있게 된다.`,`● 발복하면, 수명이 재촉된다.`,``,``,0,0,0,0],
    [200,`庚辰`,8,`癸未`,`(上人의 후원이 필요하다.\n내부에 손모사가 있다.
  고생을 사서 하는 격이다.)`,`● 년월주에 丁이 없으면 군수ㆍ면장이 된다.   丁 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [201,`庚辰`,9,`甲申`,`(재산과 명예를 증가하려 하나 뜬구름이다.\n외교가 막혔다.
  매사 불성하고 고독인이다.
  산기자이다.)`,`● 寅ㆍ午ㆍ戌ㆍ丙ㆍ丁ㆍ巳가 없으면 貴하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [202,`庚辰`,10,`乙酉`,`(男ㆍ女 共히 색정사가 있다.\n금전이 손모되고 친구ㆍ형제가 관련 되었다.
  매사 불성하고 산기자이다.)`,`● 貴ㆍ福과 직위가 일게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [203,`庚辰`,11,`丙戌`,`(희비의 판단이 불명하고 \n하극상의 배반사로 동요ㆍ불안한 입장에 있다.)`,`● 처의 근심이 있고 자식을 잃는 아품이 있게 된다.   (출산 X)`,``,``,``,0,0,0,0],
    [204,`庚辰`,12,`丁亥`,`(박관사ㆍ夫와의 갈등도 있다.\n유시부종하고 도모사도 불성한다.)`,`● 巳ㆍ午ㆍ酉월 생자 대운 北운에 吉하다.   이상보아 (출산 O)`,``,``,``,0,0,0,0],
    [205,`辛巳`,1,`戊子`,`(박관사 남편과 갈등이 있다.\n男은 명예 손상되고 직위근심이 있다.
  전환ㆍ시도 기이다.)`,`● 巳ㆍ午월 출생자가 정관격이 되면 貴하다.   정관격 확인 후 (출산 O)`,`● 亥ㆍ子월 생 상관격이 되면 처는 많고 자식은 적다.`,`● 卯ㆍ戌월 생은 중산층으로 산다.   (출산 O)`,`● 丙ㆍ癸월 생은 형액이 있다.   (출산 X)`,`● 乙巳월 생은 가난하다.   (출산 X)`,`● 丙寅월 생 단명하다.   (출산 X)`,0,0],
    [206,`辛巳`,2,`己丑`,`(財가 부족하고 하극상의 대립이 있고 외사가 급속하다.)`,`● 고독하다.   (출산 X)`,`● 申ㆍ酉월 생은 대운 화운에 처가 형액을 입는다.   (출산 X)`,`● 년월지에 나란히 丑이 있게 되면 貴하다.   (출산 O)`,``,0,0,0,0],
    [207,`辛巳`,3,`庚寅`,`(上人과 財 경위를 따지는 일이 있다.\n거지 곤란의 형편이고 도주 관사가 있다.
  강제성을 띤다.)`,`● 봄철 생은 貴하다.   (출산 O)`,`● 여름철 출생자는 녹을 받는다.`,`● 가을철 출생자는 건강하다.   이상 (출산 O)`,`● 겨울철 생은 폭력배가 된다.   (출산 X)`,0,0,0,0],
    [208,`辛巳`,4,`辛卯`,`(형제골육간 화합사가 있다.\n他人의 도움을 받고자 한다.)`,`● 봄철 생은 財가 좋다.   (출산 O)`,`● 여름철 생은 官이좋다.   (출산 O)`,`● 가을철 생은 건강하다.   (출산 O)`,`● 겨울철 생은 유약하다.   (출산 X)`,0,0,0,0],
    [209,`辛巳`,5,`壬辰`,`(하극상의 이별사가 있다.\n희비 불명한 일로 동요한다.)`,`● 봄철 생은 재복이 있다.   (출산 O)`,`● 여름철 생은 실패 반복된다.   (출산 X)`,`● 가을철 생은 吉하다.   (출산 O)`,`● 겨울철 생은 木ㆍ土가 보이면 妙하다.   (출산 O)`,0,0,0,0],
    [210,`辛巳`,6,`癸巳`,`(가정 內가 막혀있고, 금전ㆍ여자 문제가 있다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 子ㆍ午년생 貴하다.   (출산 O)`,`● 寅ㆍ午ㆍ戌월 생은 貴하다.   (출산 O)`,`● 申ㆍ子ㆍ辰월 생은 용맹하다.`,``,0,0,0,0],
    [211,`辛巳`,7,`甲午`,`(男ㆍ女共히 색정사가 있다.\n內에서 금전손모가 있다.
  外는 결속하나 피해되는 일이고 단합이 안된다.
  천강사장 여부?)`,`● 卯월 생은 무관직을 갖는다.   (출산 O)`,`● 寅ㆍ戌월 생은 가문을 먹칠한다.   (출산 X)`,`● 巳ㆍ午월 생은 위관장교에 머문다.`,``,0,0,0,0],
    [212,`辛巳`,8,`乙未`,`(外에서 형상사가 있고 급속하다.)`,`● 寅ㆍ卯ㆍ未년월 출생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [213,`辛巳`,9,`丙申`,`(財가 허모되고 어떤 일도 신실 하지 않다.\n형체손상이 있고 合하고 다툰다.
  山氣人이다.
  형제 재액이 있다.)`,`● 申ㆍ未년월 출생자는 무관직 中將 지위가 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [214,`辛巳`,10,`丁酉`,`(內 결손되나 일이 양단이므로 지체된다.\n수하의 농락이 있고 재병이 기한다.
  山氣人이다.
  형제 재액이 있다.)`,`● 亥월 생은 성급하여 반복한다.   (출산 X)`,`● 午월 생은 신체가 병을 앓는다.   (출산 X)`,`● 이외 출생자는 부귀하다.   (출산 O)`,``,0,0,0,0],
    [215,`辛巳`,11,`戊戌`,`(외교가 막혔다.\n위탁사가 안되고 있다.
  금전도 손해났다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월생자는 文貴하게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [216,`辛巳`,12,`己亥`,`(女는 남편과 갈등이 있다.\n男은 직위손상이 있다.
  大를 구하나 小일뿐이다.)`,`● 먼저는 실패하나 후에 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [217,`壬午`,1,`庚子`,`(男ㆍ女 부부가 상쟁하고 도모사가 변천된다.\n겸손이 필요하다.)`,`● 여자는 많고 남자는 적게 된다.   (출산 O)`,`● 未월 출생자는 대운 南方운에 발달한다.   이상 보아 (출산 O)`,`● 甲午월 생은 시비 관재가 많다.   (출산 X)`,`● 癸酉월 생은 크게 破 한다.   (출산 X)`,`● 辛ㆍ亥월 생은 악사(惡死)한다.   (출산 X)`,0,0,0],
    [218,`壬午`,2,`辛丑`,`(內에서 부부불합 하고 관송사가 불리하다.\n쇠퇴의 조이다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 貴하다.   (출산 O)`,`● 가을생도 吉하다.   (출산 O)`,`● 財ㆍ官ㆍ印격은 모두 吉하다.   (출산 O)`,``,0,0,0,0],
    [219,`壬午`,3,`壬寅`,`(골육간 갈등이 있다.\n內의 일도 단결하나 多事가 늦어진다.
  변동이 있다.)`,`● 어려서 질병을 많이 앓는다.   (출산 X)`,`● 寅년 출생자는 貴하다.   (출산 O)`,`● 戌월 출생자는 대운 東ㆍ南운에 貴하게 된다.   (출산 O)`,``,0,0,0,0],
    [220,`壬午`,4,`癸卯`,`(外는 단합되나 늦고 \n內는 가정이 산란하여 있다.
  上人과의 관계가 불리하다.
  男ㆍ女 共히 색정사가 있다.)`,`● 貴하다.   (출산 O)`,`● 巳ㆍ午년월 생은 무관, 또는 사법직을 갖는다.   (출산 O)`,``,``,0,0,0,0],
    [221,`壬午`,5,`甲辰`,`(他人에게 은혜를 베푸는 일에 관련됨.\n어둡다.
  색정사가 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 재난이 많다.   (출산 X)`,`● 寅ㆍ辰년월 출생자는 학식자이다.   (출산 O)`,``,``,0,0,0,0],
    [222,`壬午`,6,`乙巳`,`(外事에서 크게 구하나 작게 얻는다.\n단절사가 있다.)`,`● 戌ㆍ亥월 출생자는 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [223,`壬午`,7,`丙午`,`(內가 막혔고 금전이 손해났고 \n여인과의 관계도 소원 해졌다.)`,`● 겨울철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 돈이 많다.   (출산 O)`,`● 午월 출생자는 종재격으로 吉하다.   이상 (출산 O)`,``,0,0,0,0],
    [224,`壬午`,8,`丁未`,`(上人과의 관계가 합의 된다.\n고립되는 심정에 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 평범하게 산다.`,`● 여름철 생은 부자로 산다.   (출산 O)`,`● 가을철 출생자는 貴하다.   (출산 O)`,`● 겨울철 출생자는 吉하다.   (출산 O)`,0,0,0,0],
    [225,`壬午`,9,`戊申`,`(규사로 이동.\n원행사가 있으나 모든 모망사가 불성.
  유시무종이다.
  山氣人이다.
  일의 고립이 있다.)`,`● 亥ㆍ卯ㆍ未ㆍ寅월 출생자는 권세가 있다.   (출산 O)`,`● 子ㆍ丑월 출생자는 대운 木운에 淸貴하게 된다.`,`● 戌월 출생자는 대운 東ㆍ南운에 貴하다.   이상 대운 확인 후 (출산 O)`,``,0,0,0,0],
    [226,`壬午`,10,`己酉`,`(어떤 일도 후원이 안된다.\n상대를 압박한다.
  山氣人이다.
  일의 고립이 있다.)`,`● 먼저는 곤하고 후에 풀린다.`,`● 丑ㆍ寅월 출생자는 대운 金ㆍ水운에 무관직으로 貴하게 된다.   이상 확인 후 (출산 O)`,``,``,0,0,0,0],
    [227,`壬午`,11,`庚戌`,`(관사ㆍ夫ㆍ직위사이다.\n內에 결속이 있으나 일이 늦는다.
  內事이다.
  桃花氣가 있다.
  천강사장 여부?)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,`● 申월 생은 文才가 있게 된다.   (출산 O)`,`● 亥ㆍ子월 출생자는 천간에 壬이 보이면 凶하다.   확인 후 (출산 X)`,``,0,0,0,0],
    [228,`壬午`,12,`辛亥`,`(직위ㆍ녹위를 구하는 일이다.\n外交가 막혔다.
  부탁 받은 일이 안된다.
  강제성의 재해가 있다.)`,`● 丑월 생은 사법직을 갖는다.   (출산 O)`,`● 戌월 생은 대운 東ㆍ北운에 貴하게 된다.   이상 확인 후 (출산 O)`,`● 그 외 출생자는 고독하다.   (출산 X)`,``,0,0,0,0],
    [229,`癸未`,1,`壬子`,`(男ㆍ女 공히 색정사가 있다.\n外로는 금슬이 좋고 
  內는 관사구설의 재해를 키우고 있다.)`,`● 申ㆍ酉월 출생자는 부자이다.   (출산 O)`,`● 戌ㆍ亥월 출생자는 貴하다.   (출산 O)`,`● 寅ㆍ卯월 출생자는 고독하다.   (출산 X)`,`● 辰ㆍ巳월 출생자는 흉하다.   (출산 X)`,`● 乙ㆍ丑월 생은 凶하다.   (출산 X)`,`● 壬申월 생은 신체불구가 된다.   (출산 X),● 庚ㆍ辰월 생은 형액을 당한다.   (출산 X)`,0],
    [230,`癸未`,2,`癸丑`,`(外交가 막히고 골육불화하고 목적사는 안되고 있다.\n 內가 동요불안하다.
  초전이 본점시를도우면 몸이 상한다.)`,`● 子ㆍ丑ㆍ寅년월 생은 貴하고 부자가 된다.   (출산 O)`,`● 卯월 생은 대운 金ㆍ水원에 貴해진다.   (출산 O)`,`● 년월이 辰ㆍ戌이 완전하면 종업원생활 하다가 貴해진다.   (출산 O)`,`다만, 조실부모 한다.`,0,0,0,0],
    [231,`癸未`,3,`甲寅`,`(부부갈등도 있다.\n직장이 근심된다.
  명예손상이 있고 압박을 받을 일이 있다.)`,`● 영특하고, 중년에 貴해진다.   (출산 O)`,``,``,``,0,0,0,0],
    [232,`癸未`,4,`乙卯`,`(內로 단합이 되고 있으나 일이 늦다.\n한가지 일이 아니다.
  신규사에 속하고 의지가 있다.)`,`● 寅ㆍ卯ㆍ亥ㆍ未월 출생시는 吉하다.   (출산 O)`,`● 辰ㆍ戌ㆍ申ㆍ子월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [233,`癸未`,5,`丙辰`,`(外에서 매사가 기우는 형편이다.\n고립되는 형상에 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 寅ㆍ卯ㆍ未월 생은 평범하다.`,`● 辰ㆍ戌ㆍ丑월 생은 吉하다.   (출산 O)`,`● 巳월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [234,`癸未`,6,`丁巳`,`(上人과의 財문제로 경위를 밝힐 문제가 있다.\n도움을 얻고자 한다.)`,`● 寅ㆍ午ㆍ戌월 생은 吉하다.   (출산 O)`,`● 가을철 출생자는 녹을 먹는다.   (출산 O)`,``,``,0,0,0,0],
    [235,`癸未`,7,`戊午`,`(外로는 기운다.\n內는 공직상관의  화합이 있다.
  구연은 끝나고 변신한다.)`,`● 巳ㆍ午월 생은 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [236,`癸未`,8,`己未`,`(골육간 상배되고  목적사는 안된다.\n內도 막혀 어둡고 동요 불안하다.
  금전ㆍ여자의 문제도 있다.
  천강사장여부?)`,`● 봄철 생은 吉하다.   (출산 O)`,`● 여름철 생은 평범하다.`,`● 가을철, 겨울철 생은 건강하다.`,`● 申월 생은 대운 木운에 吉하다.   이상 확인 후 (출산 O)`,`● 巳ㆍ辰년 출생자는 小貴하다.   (출산 O)`,0,0,0],
    [237,`癸未`,9,`庚申`,`(매사 후원이 없다.\n부모의 신변에 손상이 온다.
  매사 불성한다.
  山氣人이다.)`,`● 사주에 甲寅ㆍ乙卯가 보이면 처는 현명하고 자식은 효도한다.   확인 후 (출산 O)`,`● 말년에 영화가 있다.   (출산 O)`,`● 甲ㆍ酉년월 출생자는 少將지위에 오른다.   (출산 O)`,``,0,0,0,0],
    [238,`癸未`,10,`辛酉`,`(外에서 다양한 결속이 이루어져도 결국 불성한다.\n후원도 없고 기대가 없다.
  고립된다.
  山氣人이다.)`,`● 봄철 출생자는 가난하다.   (출산 X)`,`● 여름철 출생자는 먼저 곤하다가 후에 좋아진다.`,`● 가을철 출생자는 吉하다.   (출산 O)`,`● 겨울철 출생자는 貴하다.   (출산 O)`,0,0,0,0],
    [239,`癸未`,11,`壬戌`,`(內에 관재 송사가 있다.\n토지ㆍ가옥의 문제이고 어둡다.
  돌발적으로 생겨 났다.
  천강사장 여부?)`,`● 巳월 출생자는 貴하게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [240,`癸未`,12,`癸亥`,`(자신 만만한 자세로 금전을 소비하고 여자문제도 간단하지 않다.\n부부 불화한다.)`,`● 戌ㆍ丑ㆍ未월 생자는 대운 東ㆍ北운에 貴해진다.   (출산 O)`,`● 申ㆍ酉월 생자는 대운 東方운에 貴하게 된다.   (출산 O)`,``,``,0,0,0,0],
    [241,`甲申`,1,`甲子`,`(가정이나 생업내부의 의견이 취합되어 여건을 형성하고 \n외부적인 도움을 필요로 한다.)`,`● 貴하다. 그러나 제사지낼 자식의 걱정이 있게 된다. 이상 東ㆍ南운에 문관으로 청한하게 지낸다.`,`● 亥ㆍ卯ㆍ未ㆍ辰ㆍ申ㆍ丑 월 생자 吉하다.   이상(출산 O)`,`● 丁巳월 생자는 凶하다.   이상(출산 X)`,``,0,0,0,0],
    [242,`甲申`,2,`乙丑`,`(되어진 일을 매듭짓고 새로운 각오를 세운다.\n기도자이다.)`,`● 질병을 앓게 되고 평범한 삶을 살게 될것이다.`,`● 子월 생은 대운 南方운에 吉하게 된다.   이상 보아 (출산 O)`,`● 가을철 출생자는 吉하다.   (출산 O)`,`● 겨울철 출생자는 貴하다.   (출산 O)`,0,0,0,0],
    [243,`甲申`,3,`丙寅`,`(부부간 이심으로 유리전폐사가 있다.\n상류인은 희소식을 기다리고,
  관리공직자는 영전이 있게 되고,
  하류인은 파녹ㆍ실업ㆍ실직 근심이 있다.)`,`● 처 근심이 많고 자식을 잃을 우려가 있다.   (출산 X)`,`● 巳ㆍ亥월 출생자는 貴하다.`,``,``,0,0,0,0],
    [244,`甲申`,4,`丁卯`,`(금전 손해와 종사업에 지장이 있다.\n친구나 형제가 관련되었다.
  여인사의 갈등도 있다.)`,`● 무관이나 사법직을 갖고 살게 될것이다.   (출산 O)`,`● 寅ㆍ午년월 생자 貴하다.   (출산 O)`,`● 卯월 생은 不吉하다.   (출산 X)`,``,0,0,0,0],
    [245,`甲申`,5,`戊辰`,`(금전ㆍ종사업의 운세가 기울었다.\n종교성에 관심을 갖고 있다.)`,`● 외로우나 고결하게 살아간다.`,`● 丑월 출생자는 부자로 산다.   (출산 O)`,`● 寅월 생자는 貴하다.   (출산 O)`,``,0,0,0,0],
    [246,`甲申`,6,`己巳`,`(파재ㆍ상해사를 주의해야 한다.\n관재사 발생의 원인을 갖고 있다.
  上ㆍ下불순하다.
  주색의 주의가 필요하다.)`,`● 총명하고 후덕하며 貴하나 몸을 고독할 것이다.`,``,``,``,0,0,0,0],
    [247,`甲申`,7,`庚午`,`(타인에게 속는 일이 있다.\n자녀의 손상이 있다.
  신체변동이 많고 자식에게 의지하기 어렵다고 본다.)`,`● 亥ㆍ卯년월 출생자는 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [248,`甲申`,8,`辛未`,`(낭비가 많고 금전유통에 지장이 있다.\n이권을 상실하고, 처를 잃거나 여인과 이별했다.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 신고가난이 삶이다.   (출산 X)`,`● 가을철 출생자는 이름을 드러낸다.   (출산 O)`,`● 겨울철 출생자는 새로운 일로 기반을 닦는다.   (출산 O)`,0,0,0,0],
    [249,`甲申`,9,`壬申`,`(초전이 본 점시를 도우면 모망사는 불성하고 몸을 상하게 된다.\n초전 申ㆍ酉ㆍ辰ㆍ戌ㆍ丑 확인하라.)`,`● 寅월 출생자는 무관직을 갖게 될것이다. 단, 日干이 신약하면 안된다.   日干 강약 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [250,`甲申`,10,`癸酉`,`(男ㆍ女공히색정사가있다.\n男：관재유발의 사건이 있다.
  女：남자와의 새로운 관계가 진행된다.)`,`● 평범한 삶을 살게 된다.`,`● 여름철 출생자는 장수한다.   이상 南方운에 부자가 된다.   이상 확인 후 (출산 O)`,`● 申ㆍ酉월 생자는 단명한다.   (출산 X)`,``,0,0,0,0],
    [251,`甲申`,11,`甲戌`,`(금전ㆍ여자ㆍ생업을 힘들여 왔으나 의지와 다르게 효과가 없다.)`,`● 官貴자이나, 단, 외롭고 그 직위가 오래가지 않을 것이다.`,``,``,``,0,0,0,0],
    [252,`甲申`,12,`乙亥`,`(신규사가 있다.\n잘 될듯하나 손해가 있어 고심한다.
  순리적이지 못하다.)`,`● 학문을 완성하고, 사법관이 될것이다.   (출산 O)`,`● 申ㆍ酉월 생자는 선고후길의 삶이다.   (출산 O)`,``,``,0,0,0,0],
    [253,`乙酉`,1,`丙子`,`(여자나 자손의 재해가 있다.\n수상존장으로 부터의 발탁을 기대한다.)`,`● 貴하다. 그러나 고독하다.`,`● 戊寅월 생은 大凶하다.   (출산 X)`,`● 丁巳월 생은 선조의 이름을 먹칠한다.   (출산 X)`,`● 己酉월 생은 금속도검에 의해 죽게 된다.   (출산 X)`,0,0,0,0],
    [254,`乙酉`,2,`丁丑`,`(파재, 또는 실주사가 있다.)`,`● 申ㆍ丑년월 생은 吉하다.   (출산 O)`,`● 寅ㆍ亥년월 생도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [255,`乙酉`,3,`戊寅`,`(도난ㆍ파재ㆍ상해ㆍ손모사가 있거나 모든 일이 급속하게 진행된다.\n처와의 갈등이 있고 공격한다.)`,`● 봄철 출생자는 富貴하게 살것이다.   (출산 O)`,`● 여름철 출생자는 평범하다.`,`● 가을철 출생자는 貴하고 장수한다.   (출산 O)`,`● 겨울철 출생자는 吉하다.   (출산 O)`,0,0,0,0],
    [256,`乙酉`,4,`己卯`,`(극처ㆍ극부ㆍ실직ㆍ실업의 근심이 있다.\n병재 또는 상쟁사가 있다.
  재앙 방비가 필요하다.)`,`● 말년에 고독하게 될것이다.`,``,``,``,0,0,0,0],
    [257,`乙酉`,5,`庚辰`,`(외부에서의 구재건이나 시행건이 있다.\n통신의 기쁨이 있다.)`,`● 亥ㆍ子년월 출생자는 天干에 戊ㆍ癸가 나타나면 貴하다.`,`● 년월 지지가 모두 金이 되면 福이 크다.   이상 확인 후 (출산 O)`,``,``,0,0,0,0],
    [258,`乙酉`,6,`辛巳`,`(출입사가 속하다.\n재병 및 실탈의 건이거나 적의 침범사가 있다.)`,`● 未월 출생자는 吉하다.   (출산 O)`,`● 酉월 출생자는 대운 南方운에 질병을 앓게 될것이다.   (출산 X)`,``,``,0,0,0,0],
    [259,`乙酉`,7,`壬午`,`(타인에게 속거나 자손의 재해가 있다.\n하는 일마다 안되고 흉명이다.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 평범하게 산다.`,`● 년월 간지가 乙酉가 모두 되면 貴하다.   년월 확인 후 (출산 O)`,``,0,0,0,0],
    [260,`乙酉`,8,`癸未`,`(하는 일마다 안되고 인격이 떨어지고 \n빈곤하며 의복이 도에 의지해야 자립한다.
  낭비가 심하고, 금전 유통이 저해된다.)`,`● 봄철 출생자는 건강하다.   (출산 O)`,`● 여름철 출생자는 몸이 허약하고 가난하다.   (출산 X)`,`● 가을철 출생자는 吉하다.   (출산 O)`,`● 겨울철 출생자는 평범하다.`,0,0,0,0],
    [261,`乙酉`,9,`甲申`,`(귀인사에 관여하거나 수상존장으로부터 발탁 받는 일이다.\n사서 고생하는 경우가 많다.
  의술ㆍ종교인인 경우가 많다.)`,`● 년월천간에 丁火가 보이면 吉하나, 없으면 기복이 심하게 될것이다.   丁火 확인 후 (출산 O)`,`● 亥ㆍ卯ㆍ未ㆍ酉년월 출생자는 무관직을 갖는다.   (출산 O)`,``,``,0,0,0,0],
    [262,`乙酉`,10,`乙酉`,`(초전이 본 점시를 도우면 모망사 불성하고 몸이 상한다.\n초전 辰ㆍ戌ㆍ丑ㆍ申ㆍ酉이면 불길하다.
  해당 국수의 초전을 확인하라.)`,`● 년월 간지가 火가 많으면 재난이 크다.   (출산 X)`,`● 대운 木ㆍ金운에는 貴가 있다.`,``,``,0,0,0,0],
    [263,`乙酉`,11,`丙戌`,`(손해나는 일이 있거나 가정 불측사이다.\n길흉이 느리고 유명무실하다.
  부부변심ㆍ가정손상 수하인의 농락을 받는다.)`,`● 봄철 출생자는 건강하다.   (출산 O)`,`● 여름철 출생자는 吉하다.   (출산 O)`,`● 가을철 출생자는 吉하다.   (출산 O)`,`● 겨울철 출생자는 평범하다.`,0,0,0,0],
    [264,`乙酉`,12,`丁亥`,`(기쁜 소식이 있거나 관리는 영전의 기쁨을 얻는다.)`,`● 가을철 출생자는 대운 水운에 吉하다.   이상 水운 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [265,`丙戌`,1,`戊子`,`(외사가 시도되는 시기이다.\n관사ㆍ권리ㆍ직업ㆍ남편의 일이 모두 절후시생 격이다.
  천강사장 여부?)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 건강하다.   (출산 O)`,`● 가을철 출생자는 재복이 있다.   (출산 O)`,`● 己亥월 출생자는 惡死하게 될것이다.   (출산 X)`,`● 癸酉월 출생자는 요절하게 된다.   (출산 X)`,0,0,0],
    [266,`丙戌`,2,`己丑`,`(관송 근심이 있다.\n일이 급하다.)`,`● 형무관련 고위직에 오른것이다.   (출산 O)`,`● 亥ㆍ卯월 출생자는 대운 火ㆍ金운에 大貴하게 된다.   이상 보아 (출산 O)`,`● 년월 지지에 辰ㆍ未 모두 있으면 大富가 된다.   (출산 O)`,``,0,0,0,0],
    [267,`丙戌`,3,`庚寅`,`(外의 근심이 있고, 남자가 상한다.\n재병ㆍ실탈건이 있고 적의 압박을 받는다.)`,`● 亥ㆍ子월 생자는 貴하다.   (출산 O)`,`● 申ㆍ酉년월 출생자는 대운 北方운에 吉하다.`,`● 寅ㆍ午ㆍ戌월 생은 대운 北方운에 吉하다.`,`단, 대운 死絶(酉亥)에 이르러 근신하라. 이상 대운 확인 후 (출산 O)`,0,0,0,0],
    [268,`丙戌`,4,`辛卯`,`(男ㆍ女 불문하고 색정사가 있다.\n외부에서 구재ㆍ여행건이 있다.)`,`● 처를 잃고 자식을 해치는 기운이 있다.   (출산 X)`,`● 亥子월생이 아닌자는 貴하다. (不 化氣格者)  (출산 O)`,`● 봄철 출생자는 총명하나 주색을 밝히게 될것이다.`,`● 겨울철 출생자는 대운 西方운에 富貴하게 된다.   이상 확인 후 (출산 O)`,0,0,0,0],
    [269,`丙戌`,5,`壬辰`,`(유리전폐사가 기한다.\n관직자는 영전 승진하고, 무관은 공을 세우고, 일반인은 출속인이 많다.)`,`● 凶하다.`,`● 卯ㆍ未년월 생은 관직자가 된다.`,`● 辰ㆍ戌ㆍ丑월 생은 평온하게 산다.`,`● 午ㆍ子ㆍ巳년월 생은 사법직을 갖는다.   이상 (출산 O)`,0,0,0,0],
    [270,`丙戌`,6,`癸巳`,`(직녹ㆍ명예사에 관련된다.\n입신사이다.)`,`● 卯ㆍ戌ㆍ丑ㆍ未월 출생자는 貴하나 오래가지 못한다.`,`● 亥ㆍ寅년월 출생자는 사법직을 갖는다.   (출산 O)`,``,``,0,0,0,0],
    [271,`丙戌`,7,`甲午`,`(어떤 일도 허사가 된다.\n금전허모ㆍ여자손실ㆍ형제손상이 있다.
  의복의 도에 의지해야 자립한다.)`,`● 봄철출 생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 고독하다.   (출산 X)`,`● 가을철 출생자는 자식걱정이 있다.   (출산 X)`,``,0,0,0,0],
    [272,`丙戌`,8,`乙未`,`(관송의 근심이 있다.\n타인에게 속는 일이 있고 자손을 손상한다.
  모든 도모사에 결과가 없다.
  불완전하다.
  산기인이다.)`,`● 申ㆍ子ㆍ辰년월 생은 귀인을 측근에서 모시게 된다.`,`● 寅ㆍ卯ㆍ巳년월 출생자는 大貴하게 된다.   (출산 O)`,``,``,0,0,0,0],
    [273,`丙戌`,9,`丙申`,`(출입사가 빠르다.\n上ㆍ下 내외 근심이 있고 남자가 상하게 된다.)`,`● 년월주에 壬ㆍ癸ㆍ亥ㆍ子ㆍ丑ㆍ酉가 없으면 貴하다.   이상 확인 후 (출산 O)`,`● 寅ㆍ午ㆍ巳가 보이면 고독하다.   (출산 X)`,``,``,0,0,0,0],
    [274,`丙戌`,10,`丁酉`,`(겨울은 염막귀인이 된다.\n干上ㆍ支上ㆍ年命上에 酉를 보면 시험ㆍ선거는 고위에 합격ㆍ당선된다.
  부부변심 요주의.)`,`● 寅ㆍ卯월 출생자는 대운 水운에 吉하다.`,`● 申ㆍ酉월 출생자는 대운 火운에 貴하다.   이상 대운 확인 후 (출산 O)`,``,``,0,0,0,0],
    [275,`丙戌`,11,`戊戌`,`(모든 일이 막히거나 타인에 의해 금전암손, \n또는 여인사가 있다.
  지체ㆍ암매하다.)`,`● 가난하다.   (출산 X)`,`● 寅ㆍ巳ㆍ午ㆍ戌ㆍ火ㆍ局자는 大吉하다.   년월지 확인 후 (출산 O)`,``,``,0,0,0,0],
    [276,`丙戌`,12,`己亥`,`(이별사가 있다.\n다난하다.
  의술이나 종교가인 경우가 많다.
  초전이 본 점시를 도우면 만사불성하고 몸이 상한다.)`,`● 寅ㆍ卯ㆍ巳ㆍ午월 생은 처를 잃고 자식은 적다.   (출산 X)`,`● 酉ㆍ亥ㆍ子ㆍ丑월 생은 평범하고, 대운 東方운에 吉하다.   이상 확인 후 (출산 O)`,``,``,0,0,0,0],
    [277,`丁亥`,1,`庚子`,`(男ㆍ女불문하고 색정사가 있다.\n병재ㆍ관재로 불길하고 서로 원수가 된다.
  초전이 본 점시를 도우면 모망사 불성하고 만물이 상한다.)`,`● 50세에 좋은 결실이 있게 될것이다.`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 吉하다.   (출산 O)`,`● 寅ㆍ午년월 출생자는 貴하다.   (출산 O)`,`● 庚ㆍ寅월 생자 악사(惡死)한다.   이하 (출산 X)`,`● 庚ㆍ申월 생자 내집 갖기가 어렵다.`,`● 辛酉월 생자 흉하다.`,0,0],
    [278,`丁亥`,2,`辛丑`,`(유명무실하고 길흉이 더디다.\n타인과 다툼이 있다.
  전토ㆍ분묘ㆍ불사에 관련되고 이별사가 있다.)`,`● 봄철, 가을철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 凶하다.   (출산 X)`,`● 겨울철 출생자는 吉하다.   (출산 O)`,`● 未ㆍ戌월 출생자는 大富가 된다.   (출산 O)`,0,0,0,0],
    [279,`丁亥`,3,`壬寅`,`(화류계 출입에 관계가 있다.\n예술계에 진출하면 발달한다.
  웃음 속에 칼을 조심해야 한다.
  패후에 재성한다.)`,`● 일귀격이다.`,`● 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [280,`丁亥`,4,`癸卯`,`(관송사를 조심해야 한다.\n타인에게 은혜를 베푸는 일에 관련된다.
  여러 사람과의 결속을 꾀한다.)`,`● 귀인을 모시는 측근자가 된다.   (출산 O)`,`● 寅ㆍ午월 출생자는 과ㆍ처장 지위에 오른다.   (출산 O)`,``,``,0,0,0,0],
    [281,`丁亥`,5,`甲辰`,`(신앙인이다.)`,`● 申ㆍ辰년월 생은 大貴하게 된다.   (출산 O)`,`● 午월 생은 혈액질병을 앓고 凶하다.   (출산 X)`,``,``,0,0,0,0],
    [282,`丁亥`,6,`乙巳`,`(출분하여 금전의 손해를 만난다.\n순리를 부득하고 유리ㆍ전폐사가 있다.)`,`● 처의 걱정이 있고, 자식을 잃게 될것이다.   (출산 X)`,`● 巳ㆍ酉ㆍ丑, 申ㆍ子ㆍ辰월 생자는 부자가 될것이다.   (출산 O)`,``,``,0,0,0,0],
    [283,`丁亥`,7,`丙午`,`(실재ㆍ실탈사가 있다.\n금전유통이 저해되고 있다.
  빈명으로 본다.
  직녹사에서 고립된다.)`,`● 평범하다.`,`● 대운 金ㆍ水운에 중ㆍ고급직위의 次位자가 된다.   (출산 O)`,`● 未ㆍ申ㆍ酉ㆍ丑년월 출생자는 먼저 곤궁하고 나중에 풀린다.`,``,0,0,0,0],
    [284,`丁亥`,8,`丁未`,`(기궁 공망자는 신앙인에 적합하다.\n자식에게 의지하기 어렵다.
  실재ㆍ실탈사가 있다.
  속는 일이 있다.)`,`● 卯월 생은 三合木局이 되어 장수한다.`,`● 여름철 출생자는 고독하고 가난하게 산다.   (출산 X)`,``,``,0,0,0,0],
    [285,`丁亥`,9,`戊申`,`(외출 중에 물(物)을 득한다.\n수구가 좋고 움직이면 실착을 초래한다.
  도난ㆍ상해ㆍ파재ㆍ손모사가 겹치고 급속사가 있다.)`,`● 처자식과의 인연이 좋지 않다.   (출산 X)`,`● 火ㆍ土가 년월에 많으면 눈병이 있게 된다.`,`● 子월 생은 吉하다.   (출산 O)`,``,0,0,0,0],
    [286,`丁亥`,10,`己酉`,`(관귀의 재물건이 있고, \n타인에게 부탁한 일로 복을 얻게 된다.)`,`● 곤란하다. 또는, 종업원을 처로 삼게 될 것이다.`,`● 년월주에 戊ㆍ己ㆍ丙ㆍ丁이 또 보이면 권세가의 측근자가 될것이다.`,``,``,0,0,0,0],
    [287,`丁亥`,11,`庚戌`,`(실주ㆍ도망사가 기한다.)`,`● 巳ㆍ酉ㆍ丑년월 출생자는 당선 직위를 얻는다.`,`● 寅ㆍ卯ㆍ亥월 생은 대운 火ㆍ金운에 사법부의 최고지위에 오르거나 처장에 이른다.   이상 확인 후 (출산 O)`,``,``,0,0,0,0],
    [288,`丁亥`,12,`辛亥`,`(타인으로 인하여 금전의 암손이 있고 \n여인과의 관계사가 있다.
  고의ㆍ진퇴 반복하고 개변이 많다.
  순리를 부득하고 여자가 상한다.)`,`● 巳ㆍ午월 생자는 貴하다.   (출산 O)`,`● 卯ㆍ未월 생자는 三合하여 吉하다.   (출산 O)`,`● 이 외 자는 自刑으로 처와 불화하고 모친과 살것이다.   (출산 X)`,``,0,0,0,0],
    [289,`戊子`,1,`壬子`,`(모든 일이 막히고 타인에 의해 금전의 암손이 있고 \n여인과 관계된 일이다.)`,`● 寅ㆍ卯월 출생자는 大富가 될것이다.   (출산 O)`,`● 酉ㆍ亥년월 생은 대운 西方운에 貴하게 된다.   이상 확인 후 (출산 O)`,`● 乙卯년생자는 형액이 있게 된다.   (출산 X)`,`● 癸巳월, 丙午월 생은 凶하다.   (출산 X)`,0,0,0,0],
    [290,`戊子`,2,`癸丑`,`(혼인 등 화합사가 있거나 외부의 구재ㆍ통신의 기쁨이 있다.\n시험ㆍ선거사는 고위 당선ㆍ합격한다.
  干上ㆍ支上ㆍ年命上에 丑 확인)`,`● 년월 천간에 丙ㆍ丁이 보이면 부자가 될것이다.   천간 丙丁 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [291,`戊子`,3,`甲寅`,`(초전이 본 점시를 도우면 모망사 불성하고 몸이 상한다.\n병재ㆍ관재가 우려된다.
  출입사가 속하다.
  천강사장 여부?)`,`● 먼저는 곤중하나 후에 부자가 될것이다.   (출산 O)`,`● 乙ㆍ丑년이나 월주자는 貴하다.   년월 확인 후 (출산 O)`,``,``,0,0,0,0],
    [292,`戊子`,4,`乙卯`,`(밝은데서 어두운 데로 들어가는 상이다.\n상하 불화가 있고 저해가 있고 자식 키우기 어렵다.
  천강사장 여부?)`,`● 처를 잃고 자식은 형액을 받게 된다.   (출산 X)`,`● 홀로 섰다 넘어졌다 한다.   (출산 X)`,``,``,0,0,0,0],
    [293,`戊子`,5,`丙辰`,`(형제ㆍ친구가 관련된 손모사가 있고, \n의견이 다수 취합되는 화합사가 있다.)`,`● 봄철 출생자는 貴에 가깝다.   (출산 O)`,`● 여름철 출생자는 신고한 삶이다.   (출산 X)`,`● 가을철 출생자는 권세가 높다.   (출산 O)`,`● 겨울철 출생자는 재복이 있다.   (출산 O)`,0,0,0,0],
    [294,`戊子`,6,`丁巳`,`(명예사가 있으나 외교가 막혀 위탁받은 일이 안되고 금전도 막혔다.)`,`● 子월 출생자는 부자이다.   (출산 O)`,`● 亥ㆍ丑년월 출생자는 貴하다.   (출산 O)`,`● 년월주에 土가 많으면 지방 관리자이다.`,``,0,0,0,0],
    [295,`戊子`,7,`戊午`,`(男ㆍ女간에 다툼이 있다.\n사업도 동요ㆍ불안하다.
  매사불성하고 의복의 도에 의지해야 자립할 人이다.
  후원사에 고립된다.)`,`● 인생 전반부는 형액을 받고 후반부에 발달한다.`,``,``,``,0,0,0,0],
    [296,`戊子`,8,`己未`,`(금전과 여자와의 복이 빈약하다.\n귀인사가 있으나 불성한다.
  매사불성하고 산기인이다.
  형제 재액이 있다.)`,`● 자식이 늦고 처의 걱정이 많다.   (출산 X)`,`● 初에는 곤하나 말년에 吉하다.`,``,``,0,0,0,0],
    [297,`戊子`,9,`庚申`,`(上ㆍ下ㆍ內ㆍ外 근심이 있다.\n男子가 상한다.)`,`● 子월 출생자는 大貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [298,`戊子`,10,`辛酉`,`(男ㆍ女 공히 색정사로 가정이 기운다.\n계속이 안되고 개신사가 일어나고 일체 불완전하다.)`,`● 巳ㆍ酉ㆍ丑월 생자가 상관격이 되고, 대운 水운이 오면 吉하게 된다.   이상 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [299,`戊子`,11,`壬戌`,`(병재, 또는 실주사가 있고, 압박받아 정신이 괴롭다.\n일도 침체된다.
  旺：전토사
  衰：분묘사이다.)`,`● 申ㆍ酉월 생자는 大富이다.   (출산 O)`,`● 辰ㆍ丑월 생도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [300,`戊子`,12,`癸亥`,`(외부에서 동요ㆍ불안한 일이 기한다.\n진퇴반복사ㆍ고의사ㆍ개변이 많다.)`,`● 년월주에 戊子가 없으면 貴하다.   (출산 O)`,`● 甲子가 년월에 있으면 大貴하다.   (출산 O)`,`● 巳ㆍ午ㆍ未월 생도 貴하다.   (출산 O)`,``,0,0,0,0],
    [301,`己丑`,1,`甲子`,`(합의한 후 암중저해가 있다.\n밖에서는 근심사, 안에서는 화합사가 있다.
  干上ㆍ支上ㆍ年命上이 되면 시험ㆍ선거 吉)`,`● 寅ㆍ午월 출생자는 요절하게 된다.   (출산 X)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 貴하다.   (출산 O)`,`● 甲寅월 생자는 惡死한다.   (출산 X)`,`● 丁未월 생자는 형액이 있다.   (출산 X)`,`● 丁丑월 생자는 조상을 破 한다.   (출산 X)`,0,0,0],
    [302,`己丑`,2,`乙丑`,`(길흉이 더디고 유명무실하며 타인에 의해 금전의 손해가 있다.\n여인사에 관련된다.)`,`● 무관직을 갖는다.`,`● 卯월 출생자는 대운 西ㆍ北운에 少將이 된다.   이상보아 (출산 O)`,``,``,0,0,0,0],
    [303,`己丑`,3,`丙寅`,`(가정이 순탄치 못하고 길흉이 상반하며 강제적인 손해수가 발생한다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 관직으로 나간다.   (출산 O)`,`● 가을철 출생자는 吉하다.   (출산 O)`,`● 겨울철 출생자는 평범하다.`,``,0,0,0,0],
    [304,`己丑`,4,`丁卯`,`(초전에서 본 시간을 도우면 매사불성하고 몸까지 상한다.\n초전 亥ㆍ子ㆍ寅ㆍ卯이면 위험하다.)`,`● 申ㆍ子ㆍ辰월 생자는 무관직을 갖는다.   (출산 O)`,`● 亥ㆍ卯ㆍ未월 생자는 富를 이룬다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑월 생자는 대운 東ㆍ北운이 吉하다.   이상 확인 후 (출산 O)`,``,0,0,0,0],
    [305,`己丑`,5,`戊辰`,`(매사 계속이 안되고 개변해야 할 일이 발생하며 일체 불완전한 일이 있다.)`,`● 몸이 고독하다.`,`● 辰월 출생하고 甲이 보이면 吉하다.`,`● 午월 생은 부자이다.   (출산 O)`,`● 酉월 생은 사법직이다.   (출산 O)`,`● 년월에 子ㆍ丑자는 대운 東ㆍ南운에 貴하다.   이상 보아 (출산 O)`,0,0,0],
    [306,`己丑`,6,`己巳`,`(타인으로부터 은혜를 받고져 하는 일에 관련된다.\n즉, 사업ㆍ후원사가 된다.)`,`● 辰월 출생자는 대운 西ㆍ北운에 吉하다.   이상 보아 (출산 O)`,`● 午ㆍ未월 생자는 貴하다.   (출산 O)`,`● 甲ㆍ子ㆍ戌ㆍ巳월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [307,`己丑`,7,`庚午`,`(男ㆍ女 불문하고 색정사가 있다.\n녹공망으로 의복의 도에 의지해야만 자립할 수 있다.)`,`● 寅월 출생자는 貴하다.   (출산 O)`,`● 여름철 출생자는 凶하다.   (출산 X)`,`● 가을철 출생자는 폭객이다.   (출산 X)`,`● 겨울철 출생자는 부자이나 자식이 아쉽다.`,0,0,0,0],
    [308,`己丑`,8,`辛未`,`(금전의 손해가 있다.\n형제ㆍ친구가 관련되어 있다.
  여자와도 갈등 관계에 있다.
  형제 재액이 있다.)`,`● 貴가 없으면 富가 있게 될것이다.   (출산 O)`,``,``,``,0,0,0,0],
    [309,`己丑`,9,`壬申`,`(타인에게 은혜를 베푸는 일에 관련된다.\n干上ㆍ支上ㆍ年命上에 申이 있으면 선거ㆍ시험은 고위에 당선, 합격한다.)`,`● 천간에 甲이 있으면 예의를 중시하고 강개심이 있다.`,`● 辰ㆍ戌월 출생자는 吉하다.`,``,``,0,0,0,0],
    [310,`己丑`,10,`癸酉`,`(타인에게 은혜를 베푸는 일에 관련된다.\n천장이 合ㆍ后이면 임신이고 靑이면 결혼한다.)`,`● 봄철 출생자는 펑범하다.`,`● 여름철 출생자는 은자이다.`,`● 가을철 출생자는 권세가 크다.   (출산 O)`,`● 겨울철 출생자는 부자이다.   (출산 O)`,0,0,0,0],
    [311,`己丑`,11,`甲戌`,`(內ㆍ外 반목ㆍ대립ㆍ형송사가 있다.\n손재ㆍ여자 갈등이 있다.
  형제ㆍ친구가 관련된다.)`,`● 아버지를 일찍 여윈다.`,`● 辰월 생은 복이 크다.   (출산 O)`,`● 巳ㆍ午ㆍ未ㆍ申월 생은 관직자이다.   (출산 O)`,`● 亥ㆍ戌ㆍ寅ㆍ卯월 생은 凶하다.   (출산 X)`,0,0,0,0],
    [312,`己丑`,12,`乙亥`,`(관직자는 영전의 기쁨이 있고, \n일반인은 여인과의 별리ㆍ원행ㆍ이동사가 있다.)`,`● 巳ㆍ酉ㆍ丑월 생은 관직자로 貴하다.   (출산 O)`,`● 午월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [313,`庚寅`,1,`丙子`,`(男：실직ㆍ직위불안ㆍ불명예가 있다.\n女：자손사익, 남편과의 갈등 관계에 있다.)`,`● 봄철 출생자는 재복이 있다.`,`● 년월에 子가 나란히 있으면 貴하다.   좌우(출산 O)`,`● 가을철 출생자는 대운 東ㆍ南운에 吉하다.   이상보아 (출산 O)`,`● 겨울생은 평범하다.`,`● 癸酉월 생은 凶하다.   (출산 X)`,`● 辛亥월 생은 몸을 크게 다친다.   (출산 O),● 甲子월 생은 요절한다.   (출산 X)`,0],
    [314,`庚寅`,2,`丁丑`,`(上人의 병재건인 경우도 많다.\n旺相：전토건
  休囚：분묘ㆍ불사건
  시험ㆍ선거사는 干上ㆍ支上ㆍ年命上 어느곳에 점시가 있으면 고위 당선ㆍ합격한다.)`,`● 寅ㆍ卯ㆍ午ㆍ未ㆍ亥월 출생자는 고결한 삶을 산다. 午ㆍ未운에 貴하다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [315,`庚寅`,3,`戊寅`,`(모든 일이 막히거나 타인에 의해 금전의 암손, 또는 여인사가 있다.\n外에서는 다툼, 
  內에는 막혀있다.)`,`● 巳ㆍ午ㆍ未월생이나 가을철 출생자는 貴하다.   (출산 O)`,`● 庚ㆍ子월 생은 자살한다.   (출산 X)`,`● 辛ㆍ酉가 년주나 월주에 있으면 요절한다.   (출산 X)`,``,0,0,0,0],
    [316,`庚寅`,4,`己卯`,`(男ㆍ女불문하고 색정사가 있다.\n가정에 금전이 궁핍하다.
  부부갈등이 크다.)`,`● 처의 근심이 있고 자식을 잃게 된다.   (출산 X)`,`● 子년월 출생자 貴하다.   (출산 O)`,`● 丑ㆍ未월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [317,`庚寅`,5,`庚辰`,`(사업ㆍ후원사ㆍ上人관계의 기능이 마비되었다.\n그 책임의 손재를 궁금해 한다.)`,`● 巳ㆍ午나 土가 년월지에 있게 되면 吉하다.   (출산 O)`,`● 庚ㆍ辰이 년주월주에 있으면 王侯가 아니면 凶하다.   년월주 확인(출산 O)`,``,``,0,0,0,0],
    [318,`庚寅`,6,`辛巳`,`(초전이 본 점시를 도우면 모든 일이 불성하고 몸을 상한다.\n어떤 일도 순리롭지 못하고 관사가 벌어져 있다.
  초전 寅卯巳 확인)`,`● 巳ㆍ酉ㆍ丑월 생자는 신강하면 吉하고, 신약하면 가난하다.`,``,``,``,0,0,0,0],
    [319,`庚寅`,7,`壬午`,`(의ㆍ복의 도에 의지해야만 자립이 가능한 사람이다.\n세속에서는 관재만 당한다.
  女는 남편을 망하게 하는 일을 벌인다.)`,`● 년주월주에 같이 申이 있으면 大貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [320,`庚寅`,8,`癸未`,`(上人의 후원이 안된다.\n병점은 위험하다.
  산기인이다.
  세속 일에는 늘 실패한다.
  의ㆍ복의 도에 의지하라.)`,`● 辰ㆍ戌ㆍ丑ㆍ未ㆍ巳ㆍ申ㆍ酉월 생은 신강하면 吉하다.   (출산 O)`,`● 寅ㆍ午ㆍ戌월 생자는 신약하면 단명한다.   (출산 X)`,``,``,0,0,0,0],
    [321,`庚寅`,9,`甲申`,`(외교가 막혔다.\n위탁받은 일도 안된다.
  명예사가 있으나 즐겁지 않다.
  부부간 원수로 변한다.
  별리사ㆍ원행ㆍ이동사가 있다.)`,`● 吉命이 될것이다.   (출산 O)`,``,``,``,0,0,0,0],
    [322,`庚寅`,10,`乙酉`,`(금전소모ㆍ처와의 갈등이 있고, 이로인한 압박사로 고로한다.)`,`● 寅ㆍ午ㆍ戌월 출생자는 吉하다.   (출산 O)`,`● 甲ㆍ子ㆍ辰월 출생자는 흉폭하다.   (출산 X)`,``,``,0,0,0,0],
    [323,`庚寅`,11,`丙戌`,`(기도ㆍ신앙인이다.\n관직자는 승진하고 무인은 전공이 있으며 일반인은 출속인이 많다.)`,`● 甲ㆍ子ㆍ辰월 생은 吉하다.   (출산 O)`,`● 년주나 월주에 午가 나란히 있게 되면 고독하다.   (출산 X)`,`● 년주나 월주에 寅이 나란히 있게 되면 貴하다.   (출산 O)`,``,0,0,0,0],
    [324,`庚寅`,12,`丁亥`,`(밖에서 근심사가 기한다.\n도난ㆍ상해ㆍ파재사가 있다.
  화류계 출입 관계 人이고 주색에 빠지나 예술계로 진출하면 발달한다.)`,`● 평범하게 살것이다.`,`● 초년에는 가난하고 중년에 펴진다.`,`● 未월 생은 부자로 산다.   (출산 O)`,``,0,0,0,0],
    [325,`辛卯`,1,`戊子`,`(관사 근심이 있거나 급속건이 있다.\n순리적이지 못하고 여자가 상한다.
  밝은데서 어두운데로 들어가는 형국이다.)`,`● 겨울철 충생자는 부자이다.`,`● 卯월 출생자는 吉하다.   좌우 (출산 O)`,`● 여름철 출생자는 청귀하다.   (출산 O)`,`● 丑월 생은 大吉하다.   (출산 O)`,`● 寅月출생자는 곤중하다.`,`● 辛卯월 생은 부모가 凶하게 된다. 이하 (출산 X)`   ,`● 辛巳월 생은 삶이다.,● 壬申월 생은 凶하다.   (출산 X)`],
    [326,`辛卯`,2,`己丑`,`(출입사가 빠르다.\n기능마비의 사정에 있다.)`,`● 寅ㆍ卯ㆍ亥ㆍ未월 생자가 財格 이면 대운 南方운에 吉하다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [327,`辛卯`,3,`庚寅`,`(본 점시가 干上ㆍ支上ㆍ年命上 어느 곳에 나타나면 선거ㆍ시험사는 고위 당선ㆍ합격한다.)`,`● 午ㆍ未ㆍ亥ㆍ子월 생은 吉하다.   (출산 O)`,`● 申ㆍ酉월 생은 父가 不吉하다.   (출산 X)`,``,``,0,0,0,0],
    [328,`辛卯`,4,`辛卯`,`(모든 일이 막히고, 타인에 의해 금전이 암손되고 여인사도 있다.)`,`● 寅ㆍ午ㆍ戌월 생자는 財富 똑같이 좋다.   (출산 O)`,``,``,``,0,0,0,0],
    [329,`辛卯`,5,`壬辰`,`(일이 지체되고 있다.\n外에서 타인과 다툼이 있다.
  흉이 빠르다.
  수구가 좋고 움직이면 실착을 초래한다.)`,`● 봄철 출생자는 처가 현명하고 자식도 효자이다.   (출산 O)`,`● 여름철 출생자는 부자이다.   (출산 O)`,`● 가을철 출생자는 吉하다.   (출산 O)`,`● 겨울철 출생자는 부자이다.   (출산 O)`,0,0,0,0],
    [330,`辛卯`,6,`癸巳`,`(기쁜 소식이 있고 관직자는 영전한다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철과 여름철 출생자는 신약하여 수명이 짧다.   (출산 X)`,`● 가을철 출생자는 신강하여 노력적 삶을 산다.`,``,``,0,0,0,0],
    [331,`辛卯`,7,`甲午`,`(남자는 직위가 변경되거나 해탈되고 여자는 남편을 망하게 하는 일을 벌인다.\n의복의 도에 의지해야 자립한다.)`,`● 卯월 생은 무관으로 少將 직위가 된다.   (출산 O)`,`● 巳ㆍ午월 생자는 무관 교위에 오른다.`,`● 寅ㆍ戌월 생은 破祖한다.   (출산 X)`,``,0,0,0,0],
    [332,`辛卯`,8,`乙未`,`(사업 후원이 불성하고 신체변동이 많다.\n의ㆍ복의 도에 의지해야 자립한다.
  출입사가 빠르고 上ㆍ下ㆍ內ㆍ外 근심이 있고 남자가 상한다.)`,`● 亥ㆍ卯ㆍ未월 생자 관직자로 진출하는 것이 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [333,`辛卯`,9,`丙申`,`(도난ㆍ파재ㆍ상해ㆍ손모사가 있고 모든 일이 급속하게 진행된다.\n강제성을 갖는다.)`,`● 寅ㆍ巳ㆍ午ㆍ未ㆍ戌ㆍ酉ㆍ亥월 생은 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [334,`辛卯`,10,`丁酉`,`(병ㆍ재해ㆍ동요ㆍ불안의 건이 있다.\n유리전폐한다.
  밖에서 근심이 들어왔다.
  골육참상ㆍ부부불화ㆍ가정개동의 근심사가 있다.)`,`● 申ㆍ酉월 생은, 다시 년ㆍ월 천간에 丙ㆍ丁이 보이지 않으면 貴하다.   丙丁확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [335,`辛卯`,11,`戊戌`,`(외교가 막혀 위탁받은 일이 안되고 금전의 유통이 안된다.)`,`● 亥ㆍ卯월 생은, 대운 木ㆍ火운에 貴하다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [336,`辛卯`,12,`己亥`,`(가지 많은 나무 바람 잘 날 없다는 형편이다.\n男은 직위불안, 女는 남편 갈등사도 따른다.)`,`● 寅ㆍ卯ㆍ亥ㆍ未월 생은 종신까지 경사롭다.   (출산 O)`,``,``,``,0,0,0,0],
    [337,`壬辰`,1,`庚子`,`(금전이 많이 소모되고 부인, 또는 여자와의 갈등이 있다.)`,`● 寅ㆍ午월 생자는 관직자 진출이 좋다.   (출산 O)`,`● 년월주에 辰이 있고, 천간에 庚ㆍ壬이 보이면 좋다.   (출산 O)`,`● 丙辰월 생은 집 갖기 어렵다.   (출산 X)`,`● 丁未월 생은 破祖 한다.   (출산 X)`,`● 辛丑월 생은 실패수 있고 형액이 있다.   (출산 X)`,0,0,0],
    [338,`壬辰`,2,`辛丑`,`(가정에 파재, 또는 도방사가 있다.\n계속이 안되고 개신사가 일어나고 모든 것이 불완전한 사정이다.
  천강사장 여부?)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,`● 봄철 출생자는 평범하다.`,`● 여름철 출생자는 재복이 있어 부자이다.   (출산 O)`,``,`● 가을철 출생자는 吉한 중에 凶이 있게 될것이다.`,`● 겨울철 출생자는 대운 南方운에 吉하다.`,0,0],
    [339,`壬辰`,3,`壬寅`,`(가정에 병ㆍ재액이 발생하고 괴이건이 있고 압박 받는 일이 있다.)`,`● 천간에 丙ㆍ丁ㆍ戌ㆍ己가 없고, 대운 비견운이 오면 大吉하다.   이상 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [340,`壬辰`,4,`癸卯`,`(가산이 탕진되고 일이 저해되고 완결이 안된다.\n수구가 좋다.
  시험ㆍ선거사는 干上ㆍ支上ㆍ年命上에 본 점시가 있으면 고위 당선ㆍ합격한다.)`,`● 土ㆍ金월 출생자는 대운 재성운에 吉하다.   이상 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [341,`壬辰`,5,`甲辰`,`(초전이 본 점시를 도우면 만사불성하고 몸을 상하게 된다.\n초전 巳ㆍ辰ㆍ戌ㆍ丑ㆍ未를 확인하라.
  유명무실ㆍ관재조심ㆍ도검상신을 주의할 것.)`,`● 子월 생은 大吉하다.   (출산 O)`,`● 寅월 생은 貴가 아니면 부자이다.   (출산 O)`,``,``,0,0,0,0],
    [342,`壬辰`,6,`乙巳`,`(타인과 다툼을 일으킨다.\n上人과 금전의 경위를 따질 일이 있다.)`,`● 봄철 출생자는 평범하다.`,`● 여름철 출생자는 부자이다.   (출산 O)`,`● 겨울철 출생자는 가난하다.   (출산 X)`,`● 가을철 출생자는 은실하다.`,0,0,0,0],
    [343,`壬辰`,7,`丙午`,`(이권을 상실한다.\n처첩의 손상사가 일어난다.
  낭비가 많고, 금전유통의 저해가 있다.
  의ㆍ복의 도에 의지해야 자립한다.
  산기인이다.)`,`● 寅ㆍ午ㆍ戌 출생자는 재복이 크다.   (출산 O)`,``,``,``,0,0,0,0],
    [344,`壬辰`,8,`丁未`,`(가정내 금전 고심이 많다.\n권리를 상실한다.)`,`● 辰월 생은 大吉하다.   (출산 O)`,`● 寅월 생은 처가 둘이다.`,`● 旺合水局이 되면 고독하며 가난하게 산다.   (출산 X)`,``,0,0,0,0],
    [345,`壬辰`,9,`戊申`,`(외부에서 사업상의 근심사가 일어난다.)`,`● 戌월 생은 일찍 발달한다.   (출산 O)`,`● 寅ㆍ丑ㆍ申ㆍ子ㆍ午년월 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [346,`壬辰`,10,`己酉`,`(男ㆍ女불문하고 색정사가 있다.)`,`● 大吉하다.`,`● 巳월 생은 대운 東ㆍ北운에 貴하다.   이상 확인 후 (출산 O)`,``,``,0,0,0,0],
    [347,`壬辰`,11,`庚戌`,`(가정에 간사사가 일어난다.\n下人의 도주사가 있다.
  초전이 본 점시를 도우면 만물이 상하고 모망사는 불성한다.)`,`● 여름철 출생자는 부자가 못된다.   (출산 X)`,`● 봄철 출생자는 평범하다.`,`● 겨울철 출생자는 고난하다.   (출산 X)`,`● 가을철 출생자는 조용히 산다.`,0,0,0,0],
    [348,`壬辰`,12,`辛亥`,`(외교가 막혀 위탁받은 일이 안되고 있다.\n上ㆍ下ㆍ內ㆍ外근심이 있다.
  예술, 또는 구류인이 많다.)`,`● 貴하기는 하나 凶死하게 된다.   (출산 X)`,``,``,``,0,0,0,0],
    [349,`癸巳`,1,`壬子`,`(입신사로서 직ㆍ녹을 증가시키는 일에 해당된다.)`,`● 봄철과 여름철 출생자는 부자이다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 貴가 아니면 부자로 산다.   (출산 O)`,`● 戊寅월과 戊申월 생자는 형액을 받는다.   (출산 X)`,`● 己酉월 생은 가난하다.   (출산 X)`,0,0,0,0],
    [350,`癸巳`,2,`癸丑`,`(초전이 본 점시를 도우면 모망사 불성하고 몸을 상한다.\n巳ㆍ辰ㆍ戌ㆍ丑ㆍ未 여부를 확인하라.)`,`● 복력이 있고 박학ㆍ달통한다.   (출산 O)`,`● 丑, 또는 巳가 년월에 같이 있으면 大貴하다.   (출산 O)`,``,``,0,0,0,0],
    [351,`癸巳`,3,`甲寅`,`(옛 것을 그대로 지키는 것이 좋고 움직이면 실착이 초래된다.)`,`● 평범하다.`,`● 戌ㆍ己ㆍ庚ㆍ申년에는 좋지 않다.`,``,``,0,0,0,0],
    [352,`癸巳`,4,`乙卯`,`(시험ㆍ선거사에 고위 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上 어느 곳에 卯를 확인하라.)`,`● 財ㆍ富이좋다.   (출산 O)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 재복이 있다.   (출산 O)`,`● 가을철 출생자는 학풍이 있다.   (출산 O)`,`● 겨울철 출생자는 평범하게 산다.`,0,0,0],
    [353,`癸巳`,5,`丙辰`,`(日破는 실종을 의미한다.\n파재ㆍ실주사건이 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 子월 생은 대운 南方운에 貴하게 된다.   이상 보아 (출산 O)`,`● 子ㆍ未년월 생은 부자이다.   (출산 O)`,``,``,0,0,0,0],
    [354,`癸巳`,6,`丁巳`,`(타인에 의뢰한 일로 복을 받는다.)`,`● 子월 생은 貴하다.   (출산 O)`,`● 巳ㆍ午월 생은 부자이다.   (출산 O)`,`● 년월에 丑이 나란히 있으면 貴하다.   (출산 O)`,``,0,0,0,0],
    [355,`癸巳`,7,`戊午`,`(男ㆍ女불문하고 색정사가 있다.\n낭비가 많고 금전유통에 저해가 있다.
  의ㆍ복의 도에 의지해야 자립할 人이다.
  이권을 상실한다.)`,`● 중년에 大富가 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [356,`癸巳`,8,`己未`,`(전토ㆍ분묘ㆍ불사에 관련된 경우가 많다.\n공명불현ㆍ유명무실ㆍ신체변동이 많고 기복이 심하다.
  의ㆍ복에 의지하라.)`,`● 년월에 戌土가 없고, 卯木이 있으면 貴하다.   戌土卯木 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [357,`癸巳`,9,`庚申`,`(계속이 불가하고 개신사가 기하고 일체불완전 하다.\n화류계 출입에 관계하고 주식의 재난이 있다.
  예술계에 진출하면 발달한다.)`,`● 未월 생은 학문자이다.   (출산 O)`,``,``,``,0,0,0,0],
    [358,`癸巳`,10,`辛酉`,`(上人의 병재사인 경우도 있다.)`,`● 고독하다.`,`● 寅ㆍ卯년이나 丑ㆍ未ㆍ辰ㆍ戌월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [359,`癸巳`,11,`壬戌`,`(출입사가 속하다.\n上ㆍ下ㆍ內ㆍ外 근심이 잇고 남자가 상하게 된다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 평범하다.`,`● 여름철ㆍ가을철ㆍ겨울철 출생자는 다같이 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [360,`癸巳`,12,`癸亥`,`(파재ㆍ동요ㆍ불안사가 있고 유리전폐사가 기한다.\n원행ㆍ이동ㆍ별리사가 있다.)`,`● 亥ㆍ子년월 출생자는 대운 南方운에 貴하다.   이상 보아 (출산 O)`,`● 巳ㆍ申년월 출생자는 吉하다.`,``,``,0,0,0,0],
    [361,`甲午`,1,`甲子`,`(동분서주하고 모든 일이 변전된다.\n유리ㆍ전폐사가 있다.)`,`● 처를 잃고 자식 걱정이 많게 될것이다.   (출산 X)`,`● 子ㆍ午년월 생은 吉하다.   (출산 O)`,`● 亥ㆍ卯ㆍ未ㆍ酉월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [362,`甲午`,2,`乙丑`,`(上人과의 금전사, 또는 여인사에 관련하여 그 경위를 따지는 일이 있다.\n골육 이별이 있다.)`,`● 甲일간이 신약하면 가난하고 단명한다.   (출산 X)`,`● 申ㆍ酉ㆍ亥월 생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [363,`甲午`,3,`丙寅`,`(외교가 막히고 타인에게 위탁받은 일이 정체되어 있다.)`,`● 巳ㆍ午월 생자는 단명하다.   (출산 X)`,`● 水ㆍ木월 생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [364,`甲午`,4,`丁卯`,`(인도에 어긋난 취처도발ㆍ색정사가 있다.\n주색이 심하고 일도 계속 안된다.
  일체 불완전하다.)`,`● 가을철 출생자는 요절한다.   (출산 X)`,`● 여름철 출생자는 富者이다.   (출산 O)`,``,``,0,0,0,0],
    [365,`甲午`,5,`戊辰`,`(낭비가 많고 금전 유통의 저해가 있다.\n이권이 상실된다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 성패가 반복하게 된다.   (출산 X)`,`● 일찍 인생의 쓴맛을 본다.   (출산 X)`,`● 酉월 생은 貴하다.   (출산 O)`,``,0,0,0,0],
    [366,`甲午`,6,`己巳`,`(타인에게 속는 일이 있고 언어가 바르지 않다.\n의ㆍ복의 도에 의지해야 자립한다.)`,`● 酉월 생은 대운 木ㆍ水운에 무관직 少將지위에 오른다.`,``,``,``,0,0,0,0],
    [367,`甲午`,7,`庚午`,`(순리적이지 못한 일에 봉착돼 있다.\n上ㆍ下부동하고 대탕소음하다.)`,`● 집안을 기울게 한 뒤 돈을 번다.`,`● 금전으로 인한 시비가 잦게 된다.`,`● 寅ㆍ午ㆍ戌년월 생은 貴하다.   (출산 O)`,``,0,0,0,0],
    [368,`甲午`,8,`辛未`,`(上人과의 財, 또는 여인의 문제를 따지는 일이 있다.)`,`● 봄ㆍ겨울 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 凶하다.   (출산 X)`,`● 가을철 출생자는 진취적이지 못하다.   (출산 X)`,``,0,0,0,0],
    [369,`甲午`,9,`壬申`,`(관재 불리하고 별리사가 있고 상매건 좌절의 근심도 있다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 申ㆍ子ㆍ辰월 생자는 타인의 사업을 계승하게 된다.`,`● 午월 생은 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [370,`甲午`,10,`癸酉`,`(관사로 타인을 압박 한다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 고독하다.`,`● 寅ㆍ午ㆍ戌월 출생자는 대운 東ㆍ北운에 吉하다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [371,`甲午`,11,`甲戌`,`(금전ㆍ여인사에 관련하여 내외적인 화합사가 있다.)`,`● 봄철 출생자는 貴人이다.   (출산 O)`,`● 여름철 생은 내재산을 지킬수 없다.   (출산 X)`,`● 가을철 생은 吉하다.   (출산 O)`,`● 년월에 나란히 寅이 있으면 貴하다.   확인 후 (출산 O)`,0,0,0,0],
    [372,`甲午`,12,`乙亥`,`(후원사에 강제성이 개입되어 상해ㆍ도난이 있거나 실종사가 야기된다.)`,`● 卯월 생은 양인이 되어 골육을 극하게 된다.   (출산 X)`,`● 寅월 생은 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [373,`乙未`,1,`丙子`,`(男ㆍ女불문 색정사가 있다.\n골육간의 불화도 있다.
  上人의 병문제인 경우가 많다.
  후원ㆍ발탁사에 관련된다.)`,`● 평범한 삻이다.`,`● 亥ㆍ子년월 생은 貴하다.   (출산 O)`,`● 己未월 생자는 형액이 있게 된다.   (출산 X)`,`● 丙申월 생은 凶하다.   (출산 X)`,`● 己丑월 생은 破祖 한다.   (출산 X)`,0,0,0],
    [374,`乙未`,2,`丁丑`,`(은혜는 원수로 변한다.\n형제ㆍ친구의 뜻은 모두 다르고 목적사는 불성한다.
  유리전폐사가 있다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 富貴하게 산다.   (출산 O)`,`● 가을철 출생자는 이름을 드러낸다.   (출산 O)`,`● 여름철 출생자는 곤고한 삻을 산다.   (출산 X)`,`● 겨울철 생은 평범하다.`,`● 申년월 생은 선출직을 갖게 된다.   (출산 O)`,0,0,0],
    [375,`乙未`,3,`戊寅`,`(금전ㆍ여인의 손실ㆍ손모가 있고 형제 친구가 관련된다.\n의술 또는 구류술업인이 많다.)`,`● 봄철 생은 장수한다.`,`● 가을철 출생자는 貴하다.   (출산 O)`,`● 여름철 출생자는 평범하다.`,`● 겨울철 출생자는 정신적 장애가 있게 된다.   (출산 X)`,0,0,0,0],
    [376,`乙未`,4,`己卯`,`(직녹을 물으나 外에 근심이 있다.\n침해ㆍ침범을 받아 심로한 입장이다.)`,`● 가을철 출생자는 년월 천간에 庚ㆍ辛이 보이지 않으면 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [377,`乙未`,5,`庚辰`,`(낭비가 많고 금전ㆍ여자의 유통에 저해가 많다.\n고독하다.
  실착이 많고 이권상실사가 있고 의복의 도에 의지해야 자립한다.)`,`● 亥ㆍ卯월 출생자는 吉하다.   (출산 O)`,`● 巳ㆍ申월 출생자는 관직자가 좋다.   (출산 O)`,`● 년월주가 丑과 戌이 있게 되면 大吉하다.   (출산 O)`,``,0,0,0,0],
    [378,`乙未`,6,`辛巳`,`(남에게 속임을 당하고 있다.\n산기인이며 자식에 의지하기 어렵다.
  의복의 도에 의지해야 자립한다.)`,`● 午월 생은 장성급 무관이 된다.   (출산 O)`,`● 亥ㆍ子년월 출생자는 의회 의장이 될것이다.   (출산 O)`,``,``,0,0,0,0],
    [379,`乙未`,7,`壬午`,`(亥ㆍ酉 월장기간은 임신이고, 丑 월장은 결혼사가 있다.)`,`● 亥ㆍ卯년월 출생자는 吉하다.   (출산 O)`,`● 申월생이 정관격이 되면 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [380,`乙未`,8,`癸未`,`(모든 일이 막히고 금전손해가 있고 여인관계도 있다.)`,`● 봄철 출생자는 형액이 있다.   (출산 X)`,`● 가을철 출생자는 공명을 이룬다.   (출산 O)`,`● 겨울철 출생자는 조용히 살게 된다.`,`● 여름철 출생자는 평범하다.`,0,0,0,0],
    [381,`乙未`,9,`甲申`,`(귀인사에 관여하거나 수상존장으로부터 발탁 받는 일에 관련된다.\n사서 고생하는 경우가 많다.
  천강사장 여부?)`,`● 未ㆍ酉ㆍ亥월 출생자는 총명하고 뛰어나다.   (출산 O)`,``,``,``,0,0,0,0],
    [382,`乙未`,10,`乙酉`,`(초전이 본 점시를 도우면 목적사 불성하고 몸이 상한다.\n초전의 戌ㆍ丑ㆍ未ㆍ申ㆍ酉를 확인하라.
  단, 관직자는 좋다.)`,`● 사주에 刑破가 없으면 貴하다.   (출산 O)`,`● 申월 생은 不貴하다.   (출산 X)`,``,``,0,0,0,0],
    [383,`乙未`,11,`丙戌`,`(길흉이 더디고 아래는 위를 속이고 사물의 결과가 없다.\n유명무실하다.)`,`● 午ㆍ未ㆍ戌 년월 생은 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [384,`乙未`,12,`丁亥`,`(출입ㆍ변동사가 있다.)`,`● 貴하다.   (출산 O)`,`● 子ㆍ亥년월 출생자는 大貴하다.   (출산 O)`,`● 酉월 생은 고독하다.   (출산 X)`,``,0,0,0,0],
    [385,`丙申`,1,`戊子`,`(관송사가 기한다.\n초전이 亥ㆍ子ㆍ申ㆍ酉가 되면 모망사불성하고 몸이 상한다.
  초전을 확인하라.)`,`● 巳ㆍ午년월 출생자는 대운 東ㆍ北운에 사법직에 오른다.   (출산 O)`,`● 癸巳월 생은 중년에 형액을 받는다.   (출산 X)`,`● 乙酉월 생은 파란만장하다.   (출산 X)`,``,0,0,0,0],
    [386,`丙申`,2,`己丑`,`(종교ㆍ신앙인이 많다.)`,`● 혈액 질환을 앓는다.   (출산 X)`,`● 문학 방면의 교사, 또는 교수가 된다.`,``,``,0,0,0,0],
    [387,`丙申`,3,`庚寅`,`(별리ㆍ이동사가 있고 남자가 상한다.)`,`● 亥ㆍ卯ㆍ未ㆍ申ㆍ子ㆍ辰년월 생은 吉하다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑년월 생은 財福이 있다.   (출산 O)`,`● 寅ㆍ午ㆍ戌년월 생은 평범하다.`,``,0,0,0,0],
    [388,`丙申`,4,`辛卯`,`(男：木 사업이 좋고\n女：교태ㆍ색정의 조심을 유의해야 한다.)`,`● 총명하나 주색을 좋아한다.`,`● 봄철 출생자는 吉하다.   이상 北운에 富貴가 쌍전하다.   (출산 O)`,``,``,0,0,0,0],
    [389,`丙申`,5,`壬辰`,`(자식에게 의지하기 어렵고 출속인이며 신앙ㆍ종교인이다.\n속는 일이 있다.)`,`● 봄철 출생자는 평범하다.`,`● 가을철 출생자는 부자이다.   (출산 O)`,`● 겨울철 생은 수명이 짧다.   (출산 X)`,`● 申ㆍ子ㆍ辰 水局을 이루고, 인수나 비견이 보이면 貴하다.   확인 하여 (출산 O)`,0,0,0,0],
    [390,`丙申`,6,`癸巳`,`(상을 받는 일이 있거나 명예사가 있으나 허사가 된다.\n上ㆍ下불순하고 선난후성한다.
  극처하고 노빈하다.)`,`● 貴나 富를 바라지 말라.   (출산 X)`,``,``,``,0,0,0,0],
    [391,`丙申`,7,`甲午`,`(재병실탈의 건이 있고 적 침범사가 있다.\n혈광재앙을 조심하라.)`,`● 년월에 火ㆍ土가 없으면 福이 박하다.`,``,``,``,0,0,0,0],
    [392,`丙申`,8,`乙未`,`(불명예사가 있고 남편과의 갈등이 있다.)`,`● 년월에 재성이 없으면 吉하다.   확인 하여 (출산 O)`,``,``,``,0,0,0,0],
    [393,`丙申`,9,`丙申`,`(타인으로 인하여 금전의 암손, 또는 여인과의 관계가 있다.\n남자가 상한다.)`,`● 이름은 높으나 호색 한다.`,`● 子ㆍ辰년월 생은 수명이 짧다.   (출산 X)`,`● 亥ㆍ卯ㆍ未월 생은 吉하다.`,``,0,0,0,0],
    [394,`丙申`,10,`丁酉`,`(男ㆍ女불문하고 색정사가 있다.)`,`● 亥ㆍ子년월 생은 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [395,`丙申`,11,`戊戌`,`(전토ㆍ분묘ㆍ불사에 관련되고 \n왕상은 전토사, 
  휴수사는 분묘사이다.
  일의 지체가 있다.)`,`● 亥월 출생자는 대운 東운에 貴하다.   이상 보아 (출산 O)`,`● 寅ㆍ午월 출생자는 吉하다.   (출산 O)`,`● 丑ㆍ辰월 출생자는 말년이 나쁘다.   (출산 X)`,``,0,0,0,0],
    [396,`丙申`,12,`己亥`,`(초전이 일간을 주하면 모망사불성하고 몸이 상한다.\n초전 亥ㆍ子ㆍ申ㆍ酉를 확인하라.
  이별사가 있다.)`,`● 봄철ㆍ여름철 출생자는 자력으로 성공한다. 처의 덕이 있게 될것이다.   (출산 O)`,`● 가을철, 겨울철 생은 평범하다.`,``,``,0,0,0,0],
    [397,`丁酉`,1,`庚子`,`(여자나 자손의 재해가 있다.\n계속이 안되고 개신사가 일어나고 일체가 불완전하다.
  초전이 일간을 극하면 목적사 불성하고 몸이 상한다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 출생자는 명철하고 큰 출세를 한다.   (출산 O)`,`● 巳년월 출생자도 貴하다.   (출산 O)`,`● 甲寅월생이 되면 파란이 많다.   (출산 X)`,`● 癸巳월 생은 凶하다.   (출산 X)`,`● 乙酉월 생도 형액이 있어 凶다.   (출산 X)`,0,0,0],
    [398,`丁酉`,2,`辛丑`,`(전토ㆍ분묘ㆍ불사에 관련된다.\n왕상은 전토ㆍ휴수는 묘지사이다.
  유명무실하고 불리하며 오랜 뒤에 무사하게 된다.)`,`● 酉ㆍ卯ㆍ巳ㆍ午월 생은 文名을 날린다.   (출산 O)`,`● 亥ㆍ子년월 출생자는 최고로 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [399,`丁酉`,3,`壬寅`,`(도난ㆍ상해ㆍ파재ㆍ손모사 등의 사건이 있고 \n일이 급속하게 전개 된다.)`,`● 亥ㆍ未ㆍ寅ㆍ卯ㆍ申ㆍ子년월 출생자는 文名으로 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [400,`丁酉`,4,`癸卯`,`(골육참상ㆍ가정개동의 조짐이 있다.\n유리ㆍ전폐사가 기한다.)`,`● 巳ㆍ戌년월 출생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [401,`丁酉`,5,`甲辰`,`(선길후흉하고 선난후성한다.\n손모사가 있다.
  의ㆍ복의 도에 의지해야 자립한다.
  속는 일이 있다.)`,`● 년월지에 戌이 없으면 貴하다.   戌 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [402,`丁酉`,6,`乙巳`,`(하고자 하는 일은 모두 불성한다.\n의ㆍ복의 도에 의지해야 자립한다.
  금전ㆍ여인의 손모가 있고 형제 재액이 있다.)`,`● 년월에 金ㆍ水가 보이지 않으면 형액이 있다.`,``,``,``,0,0,0,0],
    [403,`丁酉`,7,`丙午`,`(男ㆍ女불문하고 색정사가 있다.)`,`● 亥ㆍ卯ㆍ未년월 출생자는 吉하다.`,`● 巳ㆍ酉ㆍ丑년월 출생자는 평범하다.`,`● 단, 모두 자손이 불리하다.`,``,0,0,0,0],
    [404,`丁酉`,8,`丁未`,`(외교가 막히고 타인으로부터 위탁받은 일의 지체가 있다.)`,`● 日貴格이다.   (출산 O)`,`● 寅월 생은 不吉하다.   (출산 X)`,``,``,0,0,0,0],
    [405,`丁酉`,9,`戊申`,`(음실사가 기한다.\n스스로의 경계가 필요하다.)`,`● 寅ㆍ午ㆍ戌ㆍ丑ㆍ未월 생은 기이한 발복이 있게 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [406,`丁酉`,10,`己酉`,`(타인으로 인한 금전의 암손이 있고 \n여인과의 관계도 있다.
  타인에 의뢰한 일로 복을 얻는다.)`,`● 형액이 있고 고독하다.   (출산 X)`,`대운 木ㆍ火운에는 吉하다.`,``,``,0,0,0,0],
    [407,`丁酉`,11,`庚戌`,`(출입사가 급하고 상하내외의 근심이 있고 남자가 상한다.\n가정손해ㆍ부부변심ㆍ수하인의 농락건이 기한다.)`,`● 예술 분야에서 명성을 얻고 貴人에 가까운 신분이 된다.   (출산 O)`,`단, 육친인연이 박하다.`,``,``,0,0,0,0],
    [408,`丁酉`,12,`辛亥`,`(상받는 등의 명예사가 있다.\n부귀인의 명이고 관리는 승진한다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 子월 출생자는 대운 東方운에 사법직에 오른다.   이상 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [409,`戊戌`,1,`壬子`,`(임신사가 있다.)`,`● 寅ㆍ午ㆍ巳년월 생은 吉하다.   (출산 O)`,`● 戌ㆍ午월 생은 凶하다.   (출산 X)`,`● 申ㆍ卯월 생과 癸ㆍ丑월 생은 형액이 있다.   (출산 X)`,`● 寅ㆍ午월 생은 가난하다.   (출산 X)`,0,0,0,0],
    [410,`戊戌`,2,`癸丑`,`(시험ㆍ선거사는 고위 당선된다.\n단, 干上ㆍ支上ㆍ年命上ㆍ行年上 어느곳에 이 丑이 올라 있어야 한다.)`,`● 여름철 출생자는 대운 東方운에 貴하다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 권세가 있고 풍류심이 있다(출산 O)`,``,``,0,0,0,0],
    [411,`戊戌`,3,`甲寅`,`(재ㆍ병사가 있고 적침사로 불길하다.\n초전이 점시를 도우면 모망사 불성하고 몸이 상한다.
  초전 亥ㆍ子ㆍ寅ㆍ卯를 확인하라.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 대운 木ㆍ火운에 당선직이 된다.   (출산 O)`,`● 申ㆍ子ㆍ辰ㆍ酉년월 생은 貴하다.   (출산 O)`,`● 巳ㆍ午월 생은 부자이다. 상처한다.`,``,0,0,0,0],
    [412,`戊戌`,4,`乙卯`,`(男ㆍ女불문하고 색정사가 있다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 관직자로 진출한다.   (출산 O)`,`● 여름철 출생자는 학자로 진출한다.   (출산 O)`,`● 가을철 출생자는 평범하게 살것이다.`,`● 겨울철 출생자는 부자가 될것이다.   (출산 O)`,0,0,0,0],
    [413,`戊戌`,5,`丙辰`,`(희노애락이 불명하고 부하나 사용인이 도주하고 허는 실로 변한다.\n신앙ㆍ종교인이다.
  형제의 재액을 겪는다.)`,`● 여름철 출생자는 처자식을 잃게 된다.   (출산 X)`,``,``,``,0,0,0,0],
    [414,`戊戌`,6,`丁巳`,`(일생 파란이 많고 다성다패하고 \n만년 결과가 없고 노빈하고 극처한다.
  의ㆍ복의 도에 의지해야 자립한다.
  산기인이다.)`,`● 사주에 충파가 없으면 吉하다.   확인 후 (출산 O)`,`● 子월생이 사주 천간에 甲이 보이면 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [415,`戊戌`,7,`戊午`,`(혈광 재앙을 조심해야 한다.)`,`● 凶하다.   (출산 X)`,``,``,``,0,0,0,0],
    [416,`戊戌`,8,`己未`,`(시험ㆍ선거하는 고위당선이다.\n단, 干上ㆍ支上ㆍ年命上ㆍ行年上 어느 곳에 본 未가 보여야 한다.
  他는 결과가 없다고 본다.)`,`● 봄철 출생자는 관직으로 나가라.   (출산 O)`,`● 여름철 출생자는 안은한 생활을 한다.`,`● 가을철 출생자는 평범하게 산다.`,`● 겨울철 출생자는 고단한 삻이다.   (출산 X)`,0,0,0,0],
    [417,`戊戌`,9,`庚申`,`(출입사가 속하다.\n上ㆍ下ㆍ內ㆍ外 근심이 잇고 남자가 상하는 우려가 있다.)`,`● 말년에 병에 시달린다.   (출산 X)`,``,``,``,0,0,0,0],
    [418,`戊戌`,10,`辛酉`,`(수구가 좋고 동하면 실착을 초래한다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자 貴하다.   (출산 O)`,`단, 공을 이루고 물러나는 기간이 빠르게 된다.`,``,``,0,0,0,0],
    [419,`戊戌`,11,`壬戌`,`(길흉간에 더디다.\n타인에 의해 금전 손해가 있고 여인사도 있다.
  전토ㆍ분묘ㆍ불사에 관련된다.)`,`● 가을철 출생자는 富貴로 살게 된다.   (출산 O)`,`● 辰ㆍ丑월 생은 吉하다.   (출산 O)`,`● 여름철 출생자는 대운 西ㆍ北운에 吉하다.   이상 보아 (출산 O)`,``,0,0,0,0],
    [420,`戊戌`,12,`癸亥`,`(타인과 다툼이 있다.\n극처한다.)`,`● 寅ㆍ卯ㆍ巳ㆍ午ㆍ丑ㆍ戌ㆍ亥년월 생은 천간에 乙ㆍ庚ㆍ丁이 보이면 吉하고, 대운 火ㆍ土운에 발달한다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [421,`己亥`,1,`甲子`,`(男ㆍ女불문하고 색정사가 있다.\n시험ㆍ선거사는 고위 합격ㆍ당선된다.
  干上ㆍ支上ㆍ年命上ㆍ行年上에서 子를 살피라.)`,`● 봄철과 여름철 출생자는 吉하다.   (출산 O)`,`● 壬寅월 생자는 惡死한다.   (출산 X)`,`● 壬申월 생자는 고독하고 형액이 따른다.   (출산 X)`,`● 癸酉월 생자는 파란이 많다.   (출산 X)`,0,0,0,0],
    [422,`己亥`,2,`乙丑`,`(타인과 다툼이 있다.\n전토ㆍ분묘ㆍ불사에 관련된다.
  왕상 : 전토사
  휴수 : 묘지사)`,`● 卯월 출생자는 大貴한다.   (출산 O)`,`● 申월 출생자는 대운 水ㆍ土운에 발달한다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [423,`己亥`,3,`丙寅`,`(색정의 財가 있고 화류계 출입인이고 \n예술가가 되면 발달한다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철과 가을철 출생자는 평범하다.`,`● 겨울철 생은 재복이 큰 부자이다.   (출산 O)`,``,0,0,0,0],
    [424,`己亥`,4,`丁卯`,`(초전에서 본 점시를 도우면 모망사 불성하고 몸이 상한다.\n초전 子ㆍ亥ㆍ寅ㆍ卯 확인하라.)`,`● 가을철 출생자는 편관을 制하면 부귀하게 된다. 편관 제복 보아 (출산 O)`,`● 봄철 출생자는 요절한다.   (출산 X)`,`● 여름철 생은 吉하다.   (출산 O)`,`● 겨울철 출생자는 평범하다.`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,0,0,0],
    [425,`己亥`,5,`戊辰`,`(하고자 하는 일은 모두 불성하고 실재ㆍ실탈의 일이 있다.\n신앙ㆍ종교인이 많다.
  형제의 재액을 겪는다.)`,`● 戌월 출생자 당선직을 갖는다.   (출산 O)`,`● 寅ㆍ午ㆍ子ㆍ辰년월 출생자도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [426,`己亥`,6,`己巳`,`(의ㆍ복의 도에 의지해야 자립한다.\n사업후원사에 고립한다.)`,`● 육친 인연이 박하다.   (출산 X)`,``,``,``,0,0,0,0],
    [427,`己亥`,7,`庚午`,`(입신사가 있다.)`,`● 午ㆍ戌년월 출생자는 吉하다.   (출산 O)`,`● 卯월 생은 대운 西方운에 吉하다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [428,`己亥`,8,`辛未`,`(외교가 막히고 타인으로부터 위탁 받은 일도 안되고 금전도 막혔다.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 평범하다.`,`● 가을철 출생자는 가난하다.   (출산 X)`,`● 겨울철 생은 부자이다.   (출산 O)`,0,0,0,0],
    [429,`己亥`,9,`壬申`,`(시험ㆍ선거사는 고위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上에서 申을 확인하라.)`,`● 卯ㆍ未가 년월에 있어 三合이 되고 天干에 火가 보이면 大貴하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [430,`己亥`,10,`癸酉`,`(월장 巳는 결혼사가 있고 월장 丑은 임신사가 있다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 대운 木ㆍ火운에 貴하다.   이상 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [431,`己亥`,11,`甲戌`,`(출입사가 속하고 남자가 상한다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 대운 木운에 吉하다.`,`● 亥ㆍ子월 출생자는 대운 火운에 吉하다.   이상 대운 보아 (출산 O)`,``,``,0,0,0,0],
    [432,`己亥`,12,`乙亥`,`(모든 일이 막히거나 타인에 의해 금전이 손모되고 여인사가 있다.\n순리적이지 못하고 여자가 상한다.)`,`● 년월주 천간에 辛이 보이면 貴하다.   (출산 O)`,`● 戌월 출생자는 사법직에 오른다.   (출산 O)`,``,``,0,0,0,0],
    [433,`庚子`,1,`丙子`,`(모든 일이 막히거나 타인에 의한 금전손실이 있고 여자 문제가 있다.)`,`● 유명인이 되나, 처 자식이 형액을 입게 될것이다.`,``,``,``,0,0,0,0],
    [434,`庚子`,2,`丁丑`,`(전토ㆍ분묘사에 관련된다.\n시험ㆍ선거사는 고위에 당선ㆍ합격한다.
  干上ㆍ支上ㆍ年命上ㆍ行年上에서 丑을 확인하라.)`,`● 봄철과 여름철 출생자는 吉하다.   (출산 O)`,`● 가을철 출생자는 평범하게 산다.`,`● 겨울철 생은 고독하다.   (출산 X)`,``,0,0,0,0],
    [435,`庚子`,3,`戊寅`,`(외부에서 활동건에 의해 다툼이 일어난다.)`,`● 寅ㆍ午ㆍ戌년월 생은 공직자가 된다.   (출산 O)`,``,``,``,0,0,0,0],
    [436,`庚子`,4,`己卯`,`(가정에 평화 중 지장이 초래된다.\n明入暗한다.
  여자가 상한다.)`,`● 처의 걱정이 있고, 자식을 잃는다.   (출산 X)`,`● 子년월 생은 貴하다.   (출산 O)`,`● 丑ㆍ未년월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [437,`庚子`,5,`庚辰`,`(차가에 거주하고 손재ㆍ여인사가 있다.\n사업후원사에 고립한다.
  의ㆍ복의 도에 의지한다.)`,`● 丙ㆍ丁ㆍ巳ㆍ午ㆍ戌ㆍ寅을 보지 않으면 貴하다.   확인 후 (출산 O)`,`● 卯년 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [438,`庚子`,6,`辛巳`,`(교활하고 열등하며 만용자이다.\n언어가 바르지 않고 소모가 많고 가음을 좋아한다.
  권리를 상실한다.)`,`● 봄철 출생자는 부자이다.   (출산 O)`,`● 여름철 과 가을철 출생자는 吉하다.   (출산 O)`,`● 겨울철 생은 가난하다.   (출산 X)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 대운 西ㆍ南운에 吉하다.   이상 보아 (출산 O)`,0,0,0,0],
    [439,`庚子`,7,`壬午`,`(동분서주하고 제사변천된다.\n행동의 결함이 있고 男ㆍ女쟁투의 상이다.
  초전이 본점시를 도우면 몸이 상한다.)`,`● 처ㆍ자식을 잃을 걱정이 있다.`,``,``,``,0,0,0,0],
    [440,`庚子`,8,`癸未`,`(수구가 좋고 망동하면 실착을 빗는다.\n시험ㆍ선거사는 고위에 당선ㆍ합격한다.
  干上ㆍ支上ㆍ年命上ㆍ行年上에서 未를 보라.)`,`● 년월주에 丁이 없거나, 己土가 있으면 吉하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [441,`庚子`,9,`甲申`,`(외교가 막히고 부탁받은 일이 안되고 있다.)`,`● 년월에 寅이나 丙이 보이면 부자이다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [442,`庚子`,10,`乙酉`,`(男ㆍ女불문하고 색정사가 있다.\n개속이 불가하고 일체 불완전하다.)`,`● 부자이다.   (출산 O)`,`● 월지에 巳ㆍ午가 없으면 평범하다.`,`● 亥ㆍ卯가 년월에 있게 되면 형액이 있다.   (출산 X)`,``,0,0,0,0],
    [443,`庚子`,11,`丙戌`,`(군자는 진급하고 무인은 전공하고 일반인은 출속인이 많다.)`,`● 여름철 출생자는 몸이 쇠약한 凶이 있다.   (출산 X)`,`● 가을철 출생자는 貴하다.   (출산 O)`,`● 겨울철 출생자는 고단하고 가난하다.   (출산 X)`,``,0,0,0,0],
    [444,`庚子`,12,`丁亥`,`(외부의 근심이 있다.)`,`● 貴하다.   (출산 O)`,`● 卯월 생자는 대운 火운에 吉하다.   이상 보아 (출산 O)`,`● 申ㆍ子ㆍ辰월 생자는 관직에 나서지 말라.   (출산 X)`,``,0,0,0,0],
    [445,`辛丑`,1,`戊子`,`(결혼의 화합사가 있거나 \n외부의 구재건ㆍ여행건ㆍ통신의 즐거움이 있다.)`,`● 년월에 丑이 나란히 있으면 문관이다.   (출산 O)`,`● 년월에 辰이 나란히 있으면 대운 西方운에 사범직에 오른다.   이상 보아 (출산 O)`,`● 庚寅월 생은 凶하다.   (출산 X)`,`● 癸未월 생은 형액이 있다.   (출산 X)`,`● 癸丑월 생은 고독하다.   (출산 X)`,0,0,0],
    [446,`辛丑`,2,`己丑`,`(모든 일이 막히거나 타인에 의한 금전의 암손, \n또는 여인과 관계된 일이 있다.
  출입사가 속하다.)`,`● 고독하다.`,`● 申ㆍ酉월 생은 대운 火운에 처자식이 형액을 입게 된다.   (출산 X)`,`● 년월에 丑이 나란히 있으면 대운 西ㆍ南운에 貴하다.   이상 보아 (출산 O)`,``,0,0,0,0],
    [447,`辛丑`,3,`庚寅`,`(도난ㆍ파재ㆍ손모ㆍ상해사가 있거나 모든 일이 급히 진행 된다.)`,`● 봄철과 여름철 생은 吉하다.   (출산 O)`,`● 가을철 생은 명성을 얻게 된다.   (출산 O)`,`● 겨울철 생은 조용히 산다.`,``,0,0,0,0],
    [448,`辛丑`,4,`辛卯`,`(병재ㆍ실탈의 건이 있거나 적의 침범사가 있다.)`,`● 봄철과 여름철 출생자는 부자이다.   (출산 O)`,`● 가을철 출생자는 처자식이 형액을 입는다.   (출산 X)`,`● 겨울철 출생자는 가난 신고한 삶이다.   (출산 X)`,``,0,0,0,0],
    [449,`辛丑`,5,`壬辰`,`(전토ㆍ분묘ㆍ불사에 관련 된다.\n왕상 : 전토
  휴수 : 분묘건이다.
  신앙ㆍ종교인이 많다.
  사업후원사에 고립한다.)`,`● 봄철 생은 吉하다.   (출산 O)`,`● 여름철 생은 평범하게 산다. 가을철 출생자는 평범하게 산다.`,`● 가을철 생은 대운 東ㆍ南운에 吉하다.   이상 보아 (출산 O)`,`● 년월에 未ㆍ戌이 있게 되면 아주 貴하다.   확인 후 (출산 O)`,0,0,0,0],
    [450,`辛丑`,6,`癸巳`,`(손모가 많고 관송사가 기하고 의복의 도에 의지해야 자립한다.\n권리를 상실한다.)`,`● 부모와 凶하게 이별하게 된다.`,`● 스스로 복은 짓는다.`,`● 가을철과 겨울철 출생자는 凶하다.   이상 모두 (출산 X)`,``,0,0,0,0],
    [451,`辛丑`,7,`甲午`,`(男ㆍ女불문하고 색정사가 있다.\n인도에 어긋난 취처 도발이 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 未ㆍ申년월 생은 부자이나 요절하게 될것이다.   (출산 X)`,``,``,``,0,0,0,0],
    [452,`辛丑`,8,`乙未`,`(출입사가 속하다.\n上ㆍ下ㆍ內ㆍ外 근심이 있고 남자가 상한다.
  은혜가 변해 원수가 된다.)`,`● 亥ㆍ卯ㆍ未ㆍ寅월 출생자는 부자이다.   (출산 O)`,``,``,``,0,0,0,0],
    [453,`辛丑`,9,`丙申`,`(의술 또는 구류술 업자가 많다.)`,`● 소년시절에는 궁핍하다가 중년에야 吉하다.`,``,``,``,0,0,0,0],
    [454,`辛丑`,10,`丁酉`,`(外의 근심사가 있다.)`,`● 이름이 높다.   이상 木ㆍ水운에 貴하다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [455,`辛丑`,11,`戊戌`,`(官의 근심사가 있고 급속건이 있다.\n순리를 부득하고 여자가 상한다.)`,`● 년월주에 辰ㆍ未가 보이면 대운 南方운에 吉하다.   사주 辰ㆍ戌ㆍ丑ㆍ未와 대운 보아 (출산 O)`,``,``,``,0,0,0,0],
    [456,`辛丑`,12,`己亥`,`(별리사가 기한다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월이나 寅ㆍ卯월 생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [457,`壬寅`,1,`庚子`,`(근신이 좋고 혈광사를 주의하라.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,`● 申ㆍ酉월생이 천간에 인수가 보이면 貴하다.   확인 후 (출산 O)`,`● 乙巳월, 乙亥월 생은 형액이 있다.   (출산 X)`,`● 丁酉월 생은 가문을 먹칠한다.   (출산 X)`,0,0,0,0],
    [458,`壬寅`,2,`辛丑`,`(신앙ㆍ종교가가 많다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 丑월 인수격은 吉하다.     인수여부 보아 (출산 O)`,`● 辰ㆍ巳월 생자는 신고한 삶이다.   (출산 X)`,``,``,0,0,0,0],
    [459,`壬寅`,3,`壬寅`,`(모든 일이 막히거나 타인에 의해 금전의 암손이 있고 \n여인사가 있다.)`,`● 년월주에 土가 많으면 吉하다.   확인 후 (출산 O)`,`● 巳ㆍ亥년월 생은 吉하다.   (출산 O)`,`● 년월에 寅이 나란히 있으면 吉하다.   (출산 O)`,``,0,0,0,0],
    [460,`壬寅`,4,`癸卯`,`(男ㆍ女불문하고 색정사가 있다.)`,`● 子년월 생은 貴하다.   (출산 O)`,`● 巳월 생은 부자이다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,``,0,0,0,0],
    [461,`壬寅`,5,`甲辰`,`(초전이 본 점시를 도우면 목적사 불성하고 몸이 상하게 된다.\n초전 午ㆍ戌ㆍ丑ㆍ未를 확인하라.
  권리를 상실한다.)`,`● 巳월 생은 편관이 있고 충형이 없으면 吉하다.   확인 후 (출산 O)`,`● 午월 생은 관직으로 나가면 貴하다.   (출산 O)`,`● 辰ㆍ卯월 생은 대운 東方운에 吉하다.   이상 보아 (출산 O)`,``,0,0,0,0],
    [462,`壬寅`,6,`乙巳`,`(가장은 이권이나 금전ㆍ여인의 기복을 겪는다.\n부인을 손실하고 도주ㆍ관사가 일어난다.)`,`● 외로우나 고결하게 살게 된다.`,`● 佛門에 귀의 하면 富貴를 얻게 된다.`,``,``,0,0,0,0],
    [463,`壬寅`,7,`丙午`,`(외화내빈, 결속이 안된다.)`,`● 寅ㆍ午ㆍ戌월 출생자는 修身 독거자이다.`,``,``,``,0,0,0,0],
    [464,`壬寅`,8,`丁未`,`(병은 양의ㆍ양약을 구할 수 있다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 亥ㆍ卯ㆍ未월 생자는 大吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [465,`壬寅`,9,`戊申`,`(사기(邪氣) 발동이 있고 부부 이심사가 있다.\n유리전폐사, 또는 원수가 된다.)`,`● 고독하다.`,`● 寅ㆍ午ㆍ戌월 생은 吉하다.   (출산 O)`,`● 년월주에 辰이 나란히 있으면 吉하다.   (출산 O)`,``,0,0,0,0],
    [466,`壬寅`,10,`己酉`,`(정단사 거행은 불량하고 출행을 기(忌)한다.)`,`● 大 富貴자가 될것이다.   (출산 O)`,`● 亥ㆍ子월 출생자는 대운 官운에 관직에 오른다. 단, 풍류를 좋아한다.`,``,``,0,0,0,0],
    [467,`壬寅`,11,`庚戌`,`(병재ㆍ관재가 불길하고 \n처전이 본 점시를 도우면 모망사 불성하고 몸이 상한다.
  午ㆍ戌ㆍ丑ㆍ未를 확인하라.)`,`● 부인이 형액을 입고 자식이 부실하다.   (출산 X)`,`● 봄철 출생자는 평범하다.`,`● 여름철과 겨울철 생은 吉하다.   (출산 O)`,``,0,0,0,0],
    [468,`壬寅`,12,`辛亥`,`(주색의 재난이 있고 웃음 속에 칼이 있다.)`,`● 간난신고하는 끝에 발달하게 될것이다.`,`● 未월 생은 50세 이후에 貴하게 된다.`,`● 亥월 생은 대운 東ㆍ南운에 貴하다.   이상 보아 (출산 O)`,``,0,0,0,0],
    [469,`癸卯`,1,`壬子`,`(男ㆍ女불문하고 색정사가 있다.\n순리적이지 못하고 여자가 상한다.)`,`● 己가 없으면 권위있는 삶을 산다. 귀인에 가깝다. 단, 처를 일고 자식이 해롭다.`,``,``,``,0,0,0,0],
    [470,`癸卯`,2,`癸丑`,`(초전에서 본 점시를 도우면 모망사 불성하고 몸이 상한다.\n초전 午ㆍ丑ㆍ戌ㆍ未 여부를 확인하라.
  병재ㆍ관재건으로 불길하다.)`,`● 貴하나 고독하다.`,``,``,``,0,0,0,0],
    [471,`癸卯`,3,`甲寅`,`(가정 내부의 손모건이 크고 여인의 갈등사가 있다.)`,`● 亥ㆍ卯ㆍ未월 생은 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [472,`癸卯`,4,`乙卯`,`(시험ㆍ선거사는 고위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上에서 卯를 확인하라.)`,`● 초년에는 간난신고 하다.`,`● 寅ㆍ卯월 생은 기예부문에서 귀인에 가까운 인물이 될것이다.   (출산 O)`,`● 丙ㆍ子월 생은 凶하다.   (출산 X)`,``,0,0,0,0],
    [473,`癸卯`,5,`丙辰`,`(의복의 도에 의지해야만 자립한다.\n산기인중 의술 쪽이다.
  권리를 상실한다.)`,`● 고독하나 재복은 있게 된다.`,`● 亥ㆍ卯년월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [474,`癸卯`,6,`丁巳`,`(관귀의 財가 있고 타인에게 부탁한 일로 복을 얻는다.\n의ㆍ복의 도에 의지해야 자립한다.
  의술쪽이다.)`,`● 子월 생은 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [475,`癸卯`,7,`戊午`,`(가정이 파탄된다.\n어떤 일도 계속이 안되고 개신사가 일어나고 일체 불건완하다.)`,`● 申ㆍ子ㆍ辰ㆍ亥월 생은 吉하다.   (출산 O)`,`● 卯ㆍ戌월 생은 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [476,`癸卯`,8,`己未`,`(초전에서 본 점시를 도우면 모망사 불성하고 몸을 상한다.\n유명무실하고 불리하며 이별사가 있다.)`,`● 子ㆍ巳ㆍ未년월 생은 당선직위를 가질것이다.   (출산 O)`,``,``,``,0,0,0,0],
    [477,`癸卯`,9,`庚申`,`(도난ㆍ상해ㆍ파재ㆍ손모사가 있거나 모든 일이 급속하게 진행된다.)`,`● 卯월 생은 대운 西ㆍ北운에 貴하다.`,`● 申월 생은 대운 東ㆍ北운에 사법직에 오른다.   이상 대운 보아 (출산 O)`,`● 庚辰ㆍ庚戌년월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [478,`癸卯`,10,`辛酉`,`(가정에 동요ㆍ불안ㆍ유리전폐사가 있다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 貴하다.   (출산 O)`,`● 子ㆍ卯년월 생도 貴하다.   (출산 O)`,`● 巳ㆍ午년월 생은 貴하나 처자식 인연이 불리하다.`,``,0,0,0,0],
    [479,`癸卯`,11,`壬戌`,`(출입사가 속하고 上ㆍ下ㆍ內ㆍ外 근심이 있고 남자가 상한다.\n화합중에 다툼이 있다.
  천강사장 여부?)`,`● 寅ㆍ巳년월 생은 천간에 戊ㆍ丁이 보이면 吉하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [480,`癸卯`,12,`癸亥`,`(가출ㆍ방황ㆍ山門事가 있다.)`,`● 卯월 생은 대운 金ㆍ水운에 발달한다.   이상 보아 (출산 O)`,`● 辰ㆍ戌월 생은 고독하다.   (출산 X)`,``,``,0,0,0,0],
    [481,`甲辰`,1,`甲子`,`(길흉간에 거듭된다.)`,`● 년월에 水가 있게 되면 양자가 될 것이다.`,`● 酉월 생은 大吉하다.   (출산 O)`,`● 申월 생은 吉하다.   (출산 O)`,`● 子월 생은 대운 木ㆍ水운에 吉하다.   이상 보아 (출산 O)`,`● 乙卯월 생은 형액이 있다.   (출산 X)`,`● 癸亥월 생은 凶死한다.   (출산 X),● 癸巳월 생은 水火의 재액이 있다.   (출산 X)`,0],
    [482,`甲辰`,2,`乙丑`,`(관귀의 財가 있고 타인에게 의뢰한 일로 복을 얻는다.\n계속이 안되고 개신사가 기하고 불완전하다.)`,`● 부자로 산다.   (출산 O)`,``,``,``,0,0,0,0],
    [483,`甲辰`,3,`丙寅`,`(일생 파란이 많고 다집다산하고 만년 결과가 없고 노빈하고 내집 갖기 어렵다.\n도량은 크나 허명이다.
  의ㆍ복의 도에 의지 해야한다.)`,`● 일생 귀인의 범주에서 명리를 이룬다.   (출산 O)`,`● 卯ㆍ巳년월 출생자는 평범하다.`,``,``,0,0,0,0],
    [484,`甲辰`,4,`丁卯`,`(가산이 탕진되고 형상파재하고 만사상쟁하고 불완전하다.\n형제의 재액을 겪고 여인과 곡절이 많다.)`,`● 大富 이다.   (출산 O)`,`● 단, 봄철 출생자는 不吉하다.   (출산 X)`,`● 丑ㆍ酉월 출생자는 貴하나 고독하다.`,``,0,0,0,0],
    [485,`甲辰`,5,`戊辰`,`(모든 일이 막히고 타인에 의해 금전의 암손이 있고 여인사가 있다.)`,`● 재복이 있다.   (출산 O)`,`● 처가 둘이고 자식을 늦게 둔다.`,`● 년월에 辰이 나란히 있게 되면 大貴하다.   (출산 O)`,``,0,0,0,0],
    [486,`甲辰`,6,`己巳`,`(도난ㆍ파재ㆍ상해ㆍ손모사가 있거나 일이 급속하게 전개된다.)`,`● 신체가 수려하고 의젓하며 일생 평안하다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑년월 출생자는 대운 火ㆍ金운에 凶하다.   이상 보아 (출산 X)`,``,``,0,0,0,0],
    [487,`甲辰`,7,`庚午`,`(외화내빈의 특징이 있다.)`,`● 땅을 많이 지닐것이다.   (출산 O)`,`● 寅월 출생자는 대운 金운에 사법직에 오른다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [488,`甲辰`,8,`辛未`,`(전토ㆍ분묘ㆍ불사에 관련된다.\n왕상：전토사
  휴수：분묘사.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 田土를 많이 갖는다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑ㆍ子년월 생은 다 같이 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [489,`甲辰`,9,`壬申`,`(초전이 본 점시를 도우면 모망사는 불성하고 몸이 상한다.\n초전 辰ㆍ戌ㆍ丑ㆍ未ㆍ申ㆍ酉를 확인하라.)`,`● 寅ㆍ辰년월 출생자는 문장으로 貴人이 된다.   (출산 O)`,`● 천간에 丙이 보이면 더욱 좋다.   (출산 O)`,``,``,0,0,0,0],
    [490,`甲辰`,10,`癸酉`,`(男ㆍ女불문하고 색정사가 있다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 子ㆍ戌년월 출생자는 명리를 이룬다.   (출산 O)`,``,``,``,0,0,0,0],
    [491,`甲辰`,11,`甲戌`,`(희노애락이 불명하고 부하나 사용인의 도망사가 기한다.\n원수 맺는다.)`,`● 재복이 있으나 凶이 많게 될것이다.`,``,``,``,0,0,0,0],
    [492,`甲辰`,12,`乙亥`,`(실종, 또는 도망사가 기한다.)`,`● 酉월 생이 정관격이 되면 최고로 貴하게 된다.     정관격 확인 후 (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未 寅ㆍ亥년월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [493,`乙巳`,1,`丙子`,`(귀인사에 관여 하거나 수상ㆍ존장으로부터 박탈 받는 일이 있다.)`,`● 吉하다.   (출산 O)`,`● 단, 巳ㆍ午년월 출생자는 수명이 짧다. 아니면 고단한 삶을 살게 된다.   (출산 X)`,``,``,0,0,0,0],
    [494,`乙巳`,2,`丁丑`,`(실주ㆍ도망사가 있다.)`,`● 亥ㆍ卯ㆍ未ㆍ寅월 출생자는 金을 보아 吉하다.     천간 金여부 확인후 (출산 O)`,``,``,``,0,0,0,0],
    [495,`乙巳`,3,`戊寅`,`(교활하고 열등하며 만용을 부린다.\n극처하고 산기인이다.
  형제의 재액이 있다.)`,`● 평범하다.`,`● 申ㆍ庚월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [496,`乙巳`,4,`己卯`,`(소비를 감당 못하고 개변사가 많고 노빈하게 된다.\n걱정이 많고 산기인이다.
  도량은 크나 허명이다.)`,`● 봄철 출생자는 고독하고 가난하다.   (출산 X)`,`● 여름철 출생자는 평범하다.`,`● 가을철 출생자는 질액을 가진다.   (출산 X)`,`● 겨울철 출생자는 貴하다.   (출산 O)`,0,0,0,0],
    [497,`乙巳`,5,`庚辰`,`(외교가 막히고 타인으로부터 위임ㆍ부탁받은 일이 안되고 있다.)`,`● 일생 진퇴가 많다.   (출산 X)`,`● 결혼을 하지 않는 것이 좋다. 결혼하면 처자를 극한다.`,`● 독신은 大吉하다.`,``,0,0,0,0],
    [498,`乙巳`,6,`辛巳`,`(모든 일이 막히고 타인에 의해 금전의 암손이 있고 \n여인사도 있다.)`,`● 처자식을 극하여 불미하다.   (출산 X)`,`● 巳ㆍ酉ㆍ丑월 생은 질병을 갖게 된다.   (출산 X)`,``,``,0,0,0,0],
    [499,`乙巳`,7,`壬午`,`(男ㆍ女불문하고 색정사가 있다.)`,`● 吉하다.   (출산 O)`,`● 봄철과 여름철 출생자는 부자이다.   (출산 O)`,`● 가을철과 겨울철 생은 평범하다.`,``,0,0,0,0],
    [500,`乙巳`,8,`癸未`,`(신앙ㆍ종교가가 많다.\n기도생활을 한다.)`,`● 貴가 아니면 부자이다.   (출산 O)`,`단, 먼저는 곤란하고 후에 좋게 된다.`,`● 년월에 午가 나란히 있게 되면 中將 지위에 오른다.   확인 후 (출산 O)`,``,0,0,0,0],
    [501,`乙巳`,9,`甲申`,`(관사 근심이 있고 귀명으로 본다.\n초전이 본 점시를 도우면 모망사는 불성하고 몸이 상한다.)`,`● 봄철 출생자는 큰 지혜를 갖고 당선직에 오른다.   (출산 O)`,`● 여름철 생은 신심이 괴로운 삶이다.   (출산 X)`,`● 가을철과 겨울철 생은 질병을 갖는다.   (출산 X)`,`● 午년월 출생자는 대운 財운에 大吉하다.   이상 보아 (출산 O)`,0,0,0,0],
    [502,`乙巳`,10,`乙酉`,`(병재ㆍ관재가 불리하다.\n초전이 본 점시를 도우면 모망사 불성하고 몸이 상한다.
  초전 辰ㆍ戌ㆍ丑ㆍ未ㆍ申ㆍ酉를 확인하라.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 상관이 制 되면 吉하다.확인 후 (출산 O)`,`● 가을철 출생자는 신체가 허약하게 된다.   (출산 X)`,`● 겨울철 생은 福이 크나 신체가 약하다.`,0,0,0,0],
    [503,`乙巳`,11,`丙戌`,`(유명무실하고 인색한 사람이다.)`,`● 吉하다.   (출산 O)`,`● 戌ㆍ丑ㆍ未년월 출생자는 사범직에 오른다.   (출산 O)`,`● 亥월 생은 대운 東운에 중앙의회의 의장이 된다.   이상 보아 (출산 O)`,``,0,0,0,0],
    [504,`乙巳`,12,`丁亥`,`(전직ㆍ이동ㆍ승진사가 있다.)`,`● 吉하다.   (출산 O)`,`● 卯월 생은 대운 西ㆍ北운에 吉하다.   이상 보아 (출산 O)`,`● 월간에 壬ㆍ丁이 있고, 그 지지가 卯ㆍ酉ㆍ寅이 되면 貴하다.   월주 천간 확인 후 (출산 O)`,``,0,0,0,0],
    [505,`丙午`,1,`戊子`,`(男ㆍ女 쟁투의 상이다.\n동분서주ㆍ제사변천ㆍ행동결함이 있다.
  초전이 본 점시를 동면 몸이 상한다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 출생자는 大富이다.   (출산 O)`,`● 丁巳월 생은 惡死한다.   (출산 X)`,`● 丁亥월 생은 형액이 있다.   (출산 X)`,`● 辛丑월 생은 고독하다.   (출산 X)`,0,0,0,0],
    [506,`丙午`,2,`己丑`,`(관송사 불리ㆍ부부불화ㆍ골육이별ㆍ불성사가 있다.)`,`● 봄철 출생자는 대운 火ㆍ金운에 吉하다.   이상 보아 (출산 O)`,`● 여름철 출생자는 평범하다.`,`● 가을철 출생자는 부자이다.   (출산 O)`,`● 겨울철 출생자는 吉하다.   (출산 O)`,0,0,0,0],
    [507,`丙午`,3,`庚寅`,`(출입사가 속하고 남자가 상한다.\n사업후원사의 고립이 있다.)`,`● 년월주에 壬ㆍ癸ㆍ子ㆍ未가 없고 巳가 보이면 吉하다.   확인 후 (출산 O)`,`● 辰월 출생자는 전반 빈곤하고 후반 부자이다.`,``,``,0,0,0,0],
    [508,`丙午`,4,`辛卯`,`(男ㆍ女불문하고 색정사에 관련된다.\n흉명이고 성질이 포악하고 차가에 거주하고 수상의 조력이 난망하다.
  가정을 파한다.)`,`● 子월 생은 처자식과의 인연이 박하다.   (출산 X)`,`● 년월 중에 癸가 있으면 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [509,`丙午`,5,`壬辰`,`(군자는 관직이 상진하고 무인은 전공하고 일반인은 출속인이 많다.)`,`● 吉하다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,`● 만약 편관이 制되지 못하면 평범하다.`,``,0,0,0,0],
    [510,`丙午`,6,`癸巳`,`(의술ㆍ구류술업을 가진 사람인 경우가 많다.)`,`● 辰ㆍ丑월 생은 吉하다.   (출산 O)`,`● 癸巳월 생도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [511,`丙午`,7,`甲午`,`(上ㆍ下부동하고 가문상침하고 대탕소음하고 파재의 상이다.)`,`● 寅ㆍ午ㆍ戌월 출생자는 무관 中將 자위를 갖는다.   (출산 O)`,``,``,``,0,0,0,0],
    [512,`丙午`,8,`乙未`,`(결혼등의 화합사가 있거나 외부의 구재건, \n또는 통신의 즐거움이 있다.
  출비가 많다.)`,`● 부자이다.   (출산 O)`,`● 寅ㆍ午ㆍ戌월 출생자는 손안에 사법권을 쥔다.   (출산 O)`,``,``,0,0,0,0],
    [513,`丙午`,9,`丙申`,`(출입사가 속하고 上ㆍ下ㆍ內ㆍ外 근심이 있고 남자가 상한다.)`,`● 심장질환을 갖게 될것이다.   (출산 X)`,`● 火厄을 당한 후에 大發하게 된다.`,``,``,0,0,0,0],
    [514,`丙午`,10,`丁酉`,`(관귀의 財가 있고 타인에게 의뢰한 일로 복을 얻는다.)`,`● 巳ㆍ午ㆍ戌월 출생자는 스님의 命으로 大吉하다.   (출산 O)`,`● 酉월 생은 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [515,`丙午`,11,`戊戌`,`(전토ㆍ분묘ㆍ불사에 관련된다.\n왕상：전토 
  휴수：묘사이다.
  길흉이 더디다.)`,`● 명리가 쌍전한다. 좋다.   (출산 O)`,``,``,``,0,0,0,0],
    [516,`丙午`,12,`己亥`,`(다난하고 의술인ㆍ종교가가 많다.\n사서 고생하는 경우가 많다.
  초전이 본 점시를 동면 몸이 상한다.)`,`● 무관으로 진출하라. 吉하다.   (출산 O)`,`● 寅ㆍ巳ㆍ午월 생은 처를 두 번 맞고 자식이 늦다.   (출산 X)`,`● 가을철과 겨울철 생자는 명리가 진퇴한다.   (출산 X)`,``,0,0,0,0],
    [517,`丁未`,1,`庚子`,`(男ㆍ女불문하고 색정사가 있다.\n관사구설ㆍ골육불화ㆍ지체ㆍ암중재해가 있다.
  수구가 좋고 동하면 실학을 가져온다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 출생자는 吉하다.   (출산 O)`,`● 戊子년이나 월 출생자는 문장을 잘 하게 된다.   (출산 O)`,`● 戊申월 출생자는 요절한다.   (출산 X)`,`● 丙戌월 출생자는 형액이 있다.   (출산 X)`,`● 辛ㆍ丑월 생은 凶하다.   (출산 X)`,0,0,0],
    [518,`丁未`,2,`辛丑`,`(은혜가 변해 원수가 되는 일이 있다.\n유명무실하고 불리하며 모망사는 불성하다.
  붕우ㆍ형제의지가 모두 다르다.)`,`● 처를 잃고 자식 걱정이 많게 된다.   (출산 X)`,`● 酉월 생은 천간에 水가 보이면 吉하다.   水 확인 후 (출산 O)`,``,``,0,0,0,0],
    [519,`丁未`,3,`壬寅`,`(셋집에 살고 윗사람의 근력이 없고 신변기복이 많다.\n사업ㆍ후원사에 고립이 있다.)`,`● 惡死한다.   (출산 X)`,`● 가을철 출생자는 부자이다.   (출산 O)`,`● 겨울철 출생자는 貴하다.   (출산 O)`,`● 봄, 여름 출생자는 평범하다.`,0,0,0,0],
    [520,`丁未`,4,`癸卯`,`(모망사 불성하고 실재ㆍ실탈의 일이 있다.\n차가에 거주하고 신변기복이 많다.
  사업ㆍ후원사에 고립이 있다.)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름철 출생자는 평범하다.`,`● 가을철 출생자는 부자이다.   (출산 O)`,`● 겨울철 출생자는 가난하다.   (출산 X)`,0,0,0,0],
    [521,`丁未`,5,`甲辰`,`(전토ㆍ농경사에 관련되고 종교ㆍ신앙인이 많다.)`,`● 丑ㆍ亥ㆍ卯ㆍ未월 생은 吉하다.   (출산 O)`,`● 辰ㆍ戌월 생은 부자이다.   (출산 O)`,`● 酉ㆍ午월 생은 대운 金ㆍ水운에 吉하다.   이상 보아 (출산 O)`,``,0,0,0,0],
    [522,`丁未`,6,`乙巳`,`(형제ㆍ친구가 관련되어 있다.)`,`● 巳ㆍ亥년월 생은 당선직을 갖는다.   (출산 O)`,``,``,``,0,0,0,0],
    [523,`丁未`,7,`丙午`,`(화합사가 있고 외부의 구재건, 여행건 또는 통신의 즐거움이 있다.)`,`● 貴하다.   (출산 O)`,`● 甲申월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [524,`丁未`,8,`丁未`,`(모든 일이 막히거나 타인의 부탁건이 안되고 금전의 암손이 있고 \n여인사도 있다.)`,`● 부모를 일찍 이별하게 될것이다.   (출산 X)`,`● 山中人이 되면 吉하다.`,``,``,0,0,0,0],
    [525,`丁未`,9,`戊申`,`(도난ㆍ파재ㆍ상해ㆍ손모등 강제적인 일을 겪는다.)`,`● 巳ㆍ午ㆍ未ㆍ戌월 생은 吉하다.   (출산 O)`,`● 亥ㆍ卯가 년월에 있으면 三合되어 吉하다.`,`● 子ㆍ辰반합 되어도 吉하다.   이상 확인 후 (출산 O)`,``,0,0,0,0],
    [526,`丁未`,10,`己酉`,`(上人과 금전사의 경위를 따지는 일이 있다.\n타인에 의뢰한 일로 복을 받는다.)`,`● 만약 卯ㆍ乙ㆍ癸가 년월주에 보이면 不實하다.   확인 후 (출산 X)`,``,``,``,0,0,0,0],
    [527,`丁未`,11,`庚戌`,`(출입사가 속하고 上人 內ㆍ外 근심이 있고 男ㆍ女 모두 상한다.\n순리를 부득하고 사물에 결과가 없다.)`,`● 형액이 따른다.   (출산 X)`,``,``,``,0,0,0,0],
    [528,`丁未`,12,`辛亥`,`(수상존장으로 부터 발탁받는 일이 있고 \n사서 고생하는 경우가 많다.
  시험ㆍ선거사는 고위에 당선 합격한다.)`,`● 亥ㆍ卯ㆍ未ㆍ寅ㆍ辰ㆍ午년월 생은 吉하다.   (출산 O)`,`● 巳ㆍ丑년월 생은 사법직을 갖는다.   (출산 O)`,``,``,0,0,0,0],
    [529,`戊申`,1,`壬子`,`(吉ㆍ凶을 막론하고 계속 이어진다.)`,`● 먼저는 곤중하고 나중에는 발복한다.`,`● 子ㆍ酉년월 출생자는 吉하다.   (출산 O)`,`● 己巳월 출생자는 형액이 따른다.   (출산 X)`,`● 壬午월 출생자는 요절한다.   (출산 X)`,`● 癸亥월 출생자는 고독하고 빈한하다.   (출산 X)`,0,0,0],
    [530,`戊申`,2,`癸丑`,`(시험ㆍ선거사는 고위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上 어느 곳에서 丑을 확인하라.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 吉하나, 주색을 좋아한다.`,``,``,``,0,0,0,0],
    [531,`戊申`,3,`甲寅`,`(사귀가 발동하고 간사출분의 부부이심사가 있다.\n권리를 상실한다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 申ㆍ酉월 출생자는 대운 土운에 吉하다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [532,`戊申`,4,`乙卯`,`(소모성이 많고 관사가 일어난다.\n의ㆍ복의 도에 의지해야 자립한다.
  권리를 상실한다.)`,`● 중년에 破祖 하게 된다.`,`● 봄철 출생자는 고단하다.   (출산 X)`,`● 여름철 생은 貴하다.   (출산 O)`,`● 겨울철 출생자는 大吉하다.   (출산 O)`,`● 가을철 출생자는 고독하고 고단한 삶이다.   (출산 X)`,0,0,0],
    [533,`戊申`,5,`丙辰`,`(신앙ㆍ종교ㆍ기도자이다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 대운 土운에 발달한다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [534,`戊申`,6,`丁巳`,`(화합중 서로 다툼이 있다.\n선난후성하고 은혜를 원수로 갚는다.)`,`● 봄철과 여름철 출생자는 평범하다.`,`● 겨울철 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [535,`戊申`,7,`戊午`,`(혈광재앙을 방비하여야 한다.\n손모가 크다.)`,`● 년이나 월에 寅이 있으면 대운 南운에 사법권을 쥔다.   (출산 O)`,`● 년월에 午가 있으면 貴하다.   (출산 O)`,`● 년월에 戌이 있으면 貴하다.   (출산 O)`,`● 己가 년월 天干에 나란히 있으면 大吉하다.   (출산 O)`,0,0,0,0],
    [536,`戊申`,8,`己未`,`(시험ㆍ선거사는 고위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上 어느 곳에서 未를 확인하라.)`,`● 대운 財ㆍ官운에 발달한다.   이상 확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [537,`戊申`,9,`庚申`,`(모든 일이 막히거나 타인으로 인하여 금전이 암손되고 \n여인사가 있다.)`,`● 大吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [538,`戊申`,10,`辛酉`,`(男ㆍ女불문하고 색정사가 있다.)`,`● 戌ㆍ亥ㆍ子ㆍ丑년월 생자는 권리를 가진다.   (출산 O)`,``,``,``,0,0,0,0],
    [539,`戊申`,11,`壬戌`,`(모망사는 지체되고 암매하다.\n왕상：전토사
  휴수：분묘ㆍ신불사에 관련된다.)`,`● 봄철 출생자는 貴하다.   (출산 O)`,`● 여름철 생은 평범하다.`,`● 가을철 생은 부자이다.   (출산 O)`,`● 겨울철 출생자는 평범하다.`,0,0,0,0],
    [540,`戊申`,12,`癸亥`,`(외출중에 물건을 잃고 산악고처를 가지 말라.)`,`● 子ㆍ未년월 출생자는 초년에는 곤궁하고 뜨내기 생활을 하다가 처로 인해 致富하게 된다.`,``,``,``,0,0,0,0],
    [541,`己酉`,1,`甲子`,`(밖에서 손해ㆍ실주사의 근심이 있다.\n안에서 여자ㆍ아손의 재해, 또는 실주사가 있다.
  시험ㆍ선거사는 고위에 당선ㆍ합격한다.)`,`● 寅ㆍ午ㆍ申ㆍ未ㆍ丑 년월 생은 文貴하다.   (출산 O)`,`● 庚寅월 생은 파란곡절이 많다.   (출산 X)`,`● 己巳월 생은 凶하다.   (출산 X)`,`● 戊戌월 생은 외롭게 죽게 될것이다.   (출산 X)`,0,0,0,0],
    [542,`己酉`,2,`乙丑`,`(타인과 다툼이 있다.\n길흉이 더디고, 유명무실하고 불리하다.
  오랜후에 무사하다.)`,`● 丑ㆍ戌 월 생자는 貴하다.   (출산 O)`,`● 이 외 월 출생자는 진퇴 반복한다.   (출산 X)`,``,``,0,0,0,0],
    [543,`己酉`,3,`丙寅`,`(하고자 하는 일은 모두 불성하고 \n타인에 의해 금전의 암손이 있고 여자문제가 있다.
  의ㆍ복의 도에 의지해야 자립한다.
  직위가 변경된다.)`,`● 출생 월 천간에 木이나 火가 보이면 吉하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [544,`己酉`,4,`丁卯`,`(하고자 하는 일은 모두 불성하고 \n타인에 의해 금전의 암손이 있고 여자문제가 있다.
  의ㆍ복의 도에 의지해야 자립한다.
  직위가 변경된다.)`,`● 기복이 많다.   (출산 X)`,`● 未년 출생자는 년월 천간에 辛ㆍ癸가 보이면 대운 火ㆍ土운에 吉하다.   확인 후 (출산 O)`,``,``,0,0,0,0],
    [545,`己酉`,5,`戊辰`,`(결혼 등의 화합사가 있거나 \n외부의 구재건, 여행건, 통신의 즐거움이 있다.)`,`● 봄철 출생자는 관직으로 진출하라.   (출산 O)`,`● 여름철 출생자는 평범하다.`,`● 가을철 출생자는 성격이 폭력적이 된다.   (출산 X)`,`● 겨울철 출생자는 재복이 있다.   (출산 O)`,0,0,0,0],
    [546,`己酉`,6,`己巳`,`(多人事가 있다.)`,`● 여름철 출생자는 대운 火운에 貴하게 된다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [547,`己酉`,7,`庚午`,`(男ㆍ女 불문하고 색정사가 있다.)`,`● 吉하다.   (출산 O)`,`단, 풍류를 좋아한다.`,``,``,0,0,0,0],
    [548,`己酉`,8,`辛未`,`(외교가 막히고 타인의 부탁건이 안되고 있고 금전의 어려움도 있다.)`,`● 봄철과 여름철 출생자는 용모가 뛰어나다. 청고하게 살것이다.`,``,``,``,0,0,0,0],
    [549,`己酉`,9,`壬申`,`(시험ㆍ선거사는 고위 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上 중에서 申을 확인하라.)`,`● 巳ㆍ酉ㆍ丑월 생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [550,`己酉`,10,`癸酉`,`(모든 일이 막히거나 타인에 의해 금전의 암손이 있고 여자 문제도 있다.)`,`● 이름을 낸다.   (출산 O)`,`● 丑ㆍ未ㆍ子ㆍ午년월 출생자는 吉하다.   (출산 O)`,`● 酉월 생도 貴에 가깝다.   (출산 O)`,`● 己酉가 년월에 모두 있으면 낭패로 본다.   (출산 X)`,0,0,0,0],
    [551,`己酉`,11,`甲戌`,`(출입사가 속한다.\n가정손상ㆍ재병사ㆍ부부변심ㆍ수하인의 농락사 등이 있다.
  실종ㆍ실주사가 있다.)`,`● 辰ㆍ丑월 생은 재복이 있다.   (출산 O)`,`● 名利가 중간정도 이다.`,`● 乙酉년월 출생자는 성격이 우매하다.   (출산 X)`,``,0,0,0,0],
    [552,`己酉`,12,`乙亥`,`(內ㆍ外 모두 변동수가 있다.\n吉ㆍ凶간에 불성한다.)`,`● 봄ㆍ여름철 출생자는 貴하다.   (출산 O)`,`● 가을철 출생자는 청빈하다.`,`● 겨울철 생은 재복이 크다.   (출산 O)`,``,0,0,0,0],
    [553,`庚戌`,1,`丙子`,`(길흉간에 계속 연이어진다.)`,`● 봄철과 여름철 출생자는 대운 西ㆍ南운에 吉하다.`,`● 가을철 생은 대운 木ㆍ火운에 吉하다.   이상 대운 보아 (출산 O)`,`● 乙巳월 생은 凶하다.`,`● 乙亥월 생은 관재시비가 잦다.   (출산 X)`,`● 乙丑월 생은 실패가 잦다.   (출산 X),● 戊戌월 생은 惡死 한다.   (출산 X)`,0,0],
    [554,`庚戌`,2,`丁丑`,`(관사의 근심이 있고 유명무실ㆍ불리하다.\n시험ㆍ선거사는 고위에 당선ㆍ합격한다.
  干上ㆍ支上ㆍ年命上ㆍ行年上에 丑 여부를 확인하라.)`,`● 봄철 생은 사업하라.   (출산 O)`,`● 가을철 생은 평범하다.`,`● 겨울철 생은 40세 이후에 발복한다.`,``,0,0,0,0],
    [555,`庚戌`,3,`戊寅`,`(낭비가 많고 금전 유통의 저해가 많다.\n의ㆍ복의 도에 의지해야 자립한다.
  이권을 상실한다.)`,`● 吉하다.   (출산 O)`,`● 巳ㆍ卯월 생도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [556,`庚戌`,4,`己卯`,`(男ㆍ女불문하고 색정사가 발생한다.)`,`● 모친이 천하고 어려서 외롭다. 중년에 貴하다.`,`● 乙ㆍ酉ㆍ辰ㆍ丑월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [557,`庚戌`,5,`庚辰`,`(희노애락이 불명하고 아랫사람의 도주하는 일이 있다.)`,`● 대운 土운에 자립하고, 西ㆍ南운에 貴해진다.`,`● 寅ㆍ辰년월 생은 평범하다.`,``,``,0,0,0,0],
    [558,`庚戌`,6,`辛巳`,`(출입사가 급하고 남자가 상한다.\n실주ㆍ실종사가 발생할 조짐이 있다.)`,`● 년월에 寅ㆍ卯가 있게 되면 吉하다.`,`● 午ㆍ未년월 생은 대운 東ㆍ南운 문교직 기획자가 된다.   이상 확인 후 (출산 O)`,`● 申ㆍ寅월 생은 깨끗한 이름을 얻는다 대운 火운에는 나쁘다.`,``,0,0,0,0],
    [559,`庚戌`,7,`壬午`,`(원한사가 발생한다.)`,`● 卯월 생은 정재가 되어 吉하다.`,`● 未월 생은 貴하다.   (출산 O)`,`● 丑ㆍ辰년월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [560,`庚戌`,8,`癸未`,`(관사조짐이 발생하고 下가 上을 기만한다.\n다난하다.)`,`● 戌월 생은 대운 東方운이 辰월이나 卯년에 大貴하다.   이상 확인 후 (출산 O)`,`● 가을철 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [561,`庚戌`,9,`甲申`,`(기쁜 소식을 받고 관직자는 영전한다.)`,`● 巳ㆍ午ㆍ戌월 생은 처가 현명하고 자식은 효자이다.   (출산 O)`,``,``,``,0,0,0,0],
    [562,`庚戌`,10,`乙酉`,`(수구가 좋고 움직이면 실착을 초래한다.)`,`● 亥ㆍ卯ㆍ未월 생은 貴하다.   (출산 O)`,`● 酉월 생은 겸손하라! 형액이 따른다.   (출산 X)`,`● 寅ㆍ午ㆍ戌월 생은 관성이 들어 吉하다.   (출산 O)`,``,0,0,0,0],
    [563,`庚戌`,11,`丙戌`,`(타인에 의한 금전의 손해가 있고 여인사가 있다.\n군자는 관직이 상진하고 
  무인은 전공하고 일반인은 출속인이 많다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,`● 申ㆍ子ㆍ辰월 생은 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [564,`庚戌`,12,`丁亥`,`(外의 근심이 있다.\n도난ㆍ상해ㆍ파재 등이 강제적으로 일어난다.)`,`● 辰ㆍ巳ㆍ午월 생은 권리가 있게된다.   (출산 O)`,``,``,``,0,0,0,0],
    [565,`辛亥`,1,`戊子`,`(男ㆍ女불문하고 색정사가 있다.\n가정이 불화한다.)`,`● 貴가 아니면 부자가 될것이다.   (출산 O)`,`● 亥월 생은 년월천간에 丙이 보이지 않으면 大貴가 된다.   확인 후 (출산 O)`,`● 丙申월 생은 유랑한다.   (출산 X)`,`● 辛酉월, 丁酉월 생은 凶하다.   (출산 X)`,`● 丙寅월 생은 惡死 한다.   (출산 X)`,0,0,0],
    [566,`辛亥`,2,`己丑`,`(출입사가 빠르다.\n남자가 상한다.
  巳 월장은 임신,丑 월장은 결혼사가 있다.)`,`● 寅ㆍ巳ㆍ午월 생은 吉하다.   (출산 O)`,`● 卯ㆍ未월 생은 부자로 산다.   (출산 O)`,`● 申ㆍ子ㆍ辰년월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [567,`辛亥`,3,`庚寅`,`(先길後흉하고, \n초성후 패하고, 패후 재성하게 된다.
  의ㆍ복의 도에 의지해야 자립한다.
  이권을 상실한다.)`,`● 貴하나 신체가 약하다.`,``,``,``,0,0,0,0],
    [568,`辛亥`,4,`辛卯`,`(신체의 변동이 많고 낭비가 多하고, \n금전유통의 저해가 있다.
  의ㆍ복의 도에 의지해야 자립한다.
  이권을 상실한다.)`,`● 봄ㆍ여름철 출생자는 吉하다.   (출산 O)`,`● 가을ㆍ겨울철 출생자는 일생 고단하다.   (출산 X)`,`● 亥亥가 년월에 나란히 있으면 吉하다.   (출산 O)`,``,0,0,0,0],
    [569,`辛亥`,5,`壬辰`,`(유명무실하고 불리하다.\n전토ㆍ분묘ㆍ불사에 관련된다.
  왕상：전토사 
  휴수：분묘사이다.)`,`● 貴하다.   (출산 O)`,`● 봄철 출생자는 재복이 있다.   (출산 O)`,`● 여름철 출생자는 貴하다.   (출산 O)`,`● 가을철 출생자는 이름이 깨끗하다.`,`● 겨울철 출생자는 복이 있다.   (출산 O)`,0,0,0],
    [570,`辛亥`,6,`癸巳`,`(일반인은 순거역래하고, 관직자는 천관한다.)`,`● 처를 잃고 자식 걱정이 많다.   (출산 X)`,`● 봄철 출생자는 부자이다.   (출산 O)`,`● 여름철 출생자는 貴하다.   (출산 O)`,`● 가을철 출생자는 평범하게 산다.`,`● 겨울철 출생자는 성급하다.`,0,0,0],
    [571,`辛亥`,7,`甲午`,`(스스로 사서 고생하는 경우가 많다.\n초전이 본 점시를 도우면 모망사 불성하고 몸이 상한다.
  초전 土ㆍ金을 확인하라.)`,`● 亥ㆍ子년월 출생자는 문학으로 이름을 낸다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [572,`辛亥`,8,`乙未`,`(上ㆍ下ㆍ內ㆍ外 근심이 있고 남자가 상한다.\n실주ㆍ도망사가 발생한다.)`,`● 亥ㆍ卯ㆍ未월 생자는 吉하다.   (출산 O)`,`● 가을ㆍ겨울생은 고독하다.   (출산 X)`,``,``,0,0,0,0],
    [573,`辛亥`,9,`丙申`,`(좋은 중에 쇠하는 일로 변하고, \n산악고처의 재화가 있게 된다.)`,`● 申ㆍ子ㆍ辰월 생은 福이 후하다.   (출산 O)`,``,``,``,0,0,0,0],
    [574,`辛亥`,10,`丁酉`,`(外에서 근심이 발생한다.)`,`● 貴하다.   (출산 O)`,`● 丑ㆍ寅ㆍ卯ㆍ酉년월 출생자는 貴의 근처이다.   (출산 O)`,``,``,0,0,0,0],
    [575,`辛亥`,11,`戊戌`,`(외교가 막히고 타인의 부탁받은 일이 불성하고 \n금전의 유통이 지체된다.)`,`● 巳ㆍ亥년월 출생자는 凶하다.   (출산 X)`,`단, 기지와 모략이 뛰어나다.`,``,``,0,0,0,0],
    [576,`辛亥`,12,`己亥`,`(순리적이지 못하고 고의부결하고 진퇴반목ㆍ개변사가 많다.)`,`● 년월주에 모두 亥가 보이면 무관 中將 지위에 오른다.   (출산 O)`,``,``,``,0,0,0,0],
    [577,`壬子`,1,`庚子`,`(매사가 막히고 타인으로 인하여 금전의 손해, \n또는 여인사에 관련된다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 吉하다.   (출산 O)`,`● 午년월 출생자도 貴하다.   (출산 O)`,`● 년월에 모두 子가 보이면 凶하다.   (출산 X)`,`● 辛巳월 생도 凶하다.   (출산 X)`,`● 辛亥월, 甲子월 생은 형액이 따른다.   (출산 X)`,0,0,0],
    [578,`壬子`,2,`辛丑`,`(혼인등의 화합사,  외부의 구재건, 시행건, 통신의 즐거움이 있다.)`,`● 巳ㆍ酉ㆍ丑월 생은 부모의 온정이 자나치다.`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 용모가 풍후하고 출세한다.   (출산 O)`,``,``,0,0,0,0],
    [579,`壬子`,3,`壬寅`,`(원행ㆍ이동ㆍ변리사가 있고 타인에게 속는 일이 있다.\n의ㆍ복의 도에 의지하라.)`,`● 년월에 子가 나란히 있으면 大貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [580,`壬子`,4,`癸卯`,`(자손의 재액이 있고, 타인에게 속으며 \n의ㆍ복의  도에 의지해야 자립한다.)`,`● 처자식 근심이 많게 된다.   (출산 X)`,`단, 巳가 보이면 재복은 있다.`,``,``,0,0,0,0],
    [581,`壬子`,5,`甲辰`,`(매사 불성하고, 만물은 상한다.\n재병 및 실탈의 건, 적침범사이다.)`,`● 년월에 子가 같이 있으면 貴하다.   (출산 O)`,`● 巳ㆍ酉월 생자도 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [582,`壬子`,6,`乙巳`,`(구재, 상업, 여인사에 관련된다.\n신변동요와 이별사가 있다.)`,`● 부자이나, 처자식 인연은 아쉬움이 있다.`,``,``,``,0,0,0,0],
    [583,`壬子`,7,`丙午`,`(재ㆍ처사로 동요, 불안건이 있거나, \n적침범사로 유리전폐사가 있다.)`,`● 고독한 貴人이다.`,`● 년월에 子나 午가 보이면 貴이다.   (출산 O)`,``,``,0,0,0,0],
    [584,`壬子`,8,`丁未`,`(관사, 권리, 직업, 남편일에 관련하여 손해나는 일이 있거나, \n불측의 일이다.)`,`● 년월에 木ㆍ金이 있으면 부자이다.   (출산 O)`,`● 이 외 출생자는 평범하다.`,``,``,0,0,0,0],
    [585,`壬子`,9,`戊申`,`(사업 시작사에 관련된다.\n타인이 나에게 은혜를 베푸는 일이다.)`,`● 巳ㆍ午년월 출생자는 권리를 행사 한다.   (출산 O)`,``,``,``,0,0,0,0],
    [586,`壬子`,10,`己酉`,`(파재, 또는 실직사이다.\n男ㆍ女불문하고, 색정사에 관련된다.)`,`● 봄철 출생자는 평범하다.`,`● 여름철 생은 吉하다.   (출산 O)`,`● 가을철과 겨울철 출생자는 재복이 있다.   (출산 O)`,``,0,0,0,0],
    [587,`壬子`,11,`庚戌`,`(매사 불성하고, 만물은 상한다.\n재병 및, 적의 침범사로 원수 맺는다.)`,`● 巳ㆍ午월 생은 부자이다.   (출산 O)`,`● 酉월 생은 인생 破害가 있다.`,``,``,0,0,0,0],
    [588,`壬子`,12,`辛亥`,`(직,녹을 구하는 일이거나 입신사이다.\n명예사가 있다.)`,`● 亥ㆍ卯ㆍ未월 생자는 貴하다.`,`단, 부모가 不吉하다.`,``,``,0,0,0,0],
    [589,`癸丑`,1,`壬子`,`(혼인 등의 화합사가 있고 구재건ㆍ여행건ㆍ통신의 즐거움이 있다.)`,`● 午ㆍ寅, 乙丑ㆍ己丑년월 출생자는 文職에 선출된다.   (출산 O)`,`● 壬寅ㆍ乙未ㆍ庚戌월 생은 형액이 있다.   (출산 X)`,``,``,0,0,0,0],
    [590,`癸丑`,2,`癸丑`,`(內ㆍ外가 막히고 금전의 암손이 있고 여인사가 있다.\n외교ㆍ위탁사가 불성되고 있다.)`,`● 申ㆍ子ㆍ辰 三合 년월 출생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [591,`癸丑`,3,`甲寅`,`(타인에게 속는 일이 있다.\n교활하고 열등하며, 언어가 바르지 않고 가음을 좋아한다.
  자손의재액을 겪는다.)`,`● 년월에 다같이 水가 있으면 貴하다.   (출산 O)`,`● 辰월 생은 대운 東운이 오면 꺽인다.   이상 보아 (출산 X)`,``,``,0,0,0,0],
    [592,`癸丑`,4,`乙卯`,`(의ㆍ복의 도에 의지해야 자립한다.\n타인에게 속는 일이 있고 자손의 재액을 겪는다.)`,`● 丑ㆍ辰월 생자는 이름이 높다.   (출산 O)`,``,``,``,0,0,0,0],
    [593,`癸丑`,5,`丙辰`,`(계속이 불가하고 개신사가 기하고 일체 불완전하다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 일간이 旺하면 貴하다.   일간 힘 보아 (출산 O)`,``,``,``,0,0,0,0],
    [594,`癸丑`,6,`丁巳`,`(관공사의 財가 있고 타인에게 부탁한 일로 복을 얻는다.)`,`● 선빈 후부자 이고, 대운 火운에 발복할 것이다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [595,`癸丑`,7,`戊午`,`(男ㆍ女불문하고 색정사가 있다.\n內ㆍ外 근심사가 있다.
  수구가 좋고 움직이면 실착을 초래한다.)`,`● 재복이 크다, 대운 南方운에 貴하다. 단, 수명이 짧다.`,`● 대운 東운에 貴가 떨어지나 평은하게 산다.`,``,``,0,0,0,0],
    [596,`癸丑`,8,`己未`,`(유명무실하고 불리하다.\n형제ㆍ친구의 의견이 모두 다르다.
  원수 맺는 일이 있다.)`,`● 이름이 높다.`,`● 년월에 같이 丑이 있으면 가난하다.   (출산 X)`,`● 辰ㆍ戌ㆍ丑ㆍ未 土가 많으면 눈병이 있게 될다.   (출산 X)`,``,0,0,0,0],
    [597,`癸丑`,9,`庚申`,`(明暗(表裏) 모두 동일하다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 문장으로 이름을 날린다.   (출산 O)`,`● 子월 생도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [598,`癸丑`,10,`辛酉`,`(明暗(表裏) 모두 동일하다.)`,`● 가을철 생은 吉하다.관살운에 발복한다.   (출산 O)`,`● 겨울철 생은 복록이 크다.   (출산 O)`,``,``,0,0,0,0],
    [599,`癸丑`,11,`壬戌`,`(출입사가 속하고 上ㆍ下ㆍ內ㆍ外 근심이 있고 男ㆍ女모두 상한다.\n순리부득하고 가정이 암담하고 관재가 기한다.)`,`● 辰ㆍ申년월 출생자는 대운 南方운에 吉하다.   (출산 O)`,`● 亥월 생은 대운 土운에 吉하다.   이상 대운 보아 (출산 O)`,``,``,0,0,0,0],
    [600,`癸丑`,12,`癸亥`,`(부부갈등이 있다.)`,`● 巳ㆍ酉ㆍ丑년월 출생자는 복이 크다.   (출산 O)`,`● 午월 생은 평범하다.`,`● 酉월 생은 사법관이 된다. 단, 수명이 길지 않다.`,``,0,0,0,0],
    [601,`甲寅`,1,`甲子`,`(후원이 없고, 매사 불성하고 산기인이다.\n신변이 다변하다.
  의ㆍ복의 도에 의지해야 자립한다.
  사업후원에 고립한다.)`,`● 貴하다.`,`● 庚申ㆍ辛酉ㆍ丑未를 보지 않으면 大貴하다.   확인 후 (출산 O)`,`● 乙巳월 생은 형액이 있다.`,`● 丁丑월 생은 好中 凶이 있다.`,0,0,0,0],
    [602,`甲寅`,2,`乙丑`,`(낭비가 많고 금전의 유통 저해가 있다.\n의ㆍ복의 도에 의지해야 자립한다.
  이권을 상실한다.)`,`● 봄철 출생자는 궁핍하다.   (출산 X)`,`● 가을철 생은 권리가 있게 된다.   (출산 O)`,`● 겨울철 출생자는 부자이다.   (출산 O)`,`● 여름철 출생자는 大吉하다.   (출산 O)`,0,0,0,0],
    [603,`甲寅`,3,`丙寅`,`(외교가 막히고 타인의 청탁건이 지체되고 \n금전의 암손ㆍ여인 관계가 있다.)`,`● 년월주에 巳ㆍ酉ㆍ申을 보지않으면 大吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [604,`甲寅`,4,`丁卯`,`(男ㆍ女불문하고 색정사가 있다.)`,`● 년월주에 木ㆍ火가 있으면 貴하다.   (출산 O)`,`● 乙亥월 생은 문장으로 이름을 날린다.   (출산 O)`,``,``,0,0,0,0],
    [605,`甲寅`,5,`戊辰`,`(內ㆍ外 금전사로 다툼이 있다.)`,`● 년월에 辰이 함께 있으면 중등 부자이다.   (출산 O)`,`● 년월에 寅이 함께 있으면 장수한다.`,`● 년월에 亥ㆍ卯ㆍ未 반합이 되면 재복이 크다.   (출산 O)`,``,0,0,0,0],
    [606,`甲寅`,6,`己巳`,`(장은 가모를 손상하고 화환근심이 있고 \n수구가 좋고 동하면 실착이 초래된다.
  어떤일도 순조롭지 못하다.)`,`● 처자식 인연이 근심으로 남는다.   (출산 X)`,`● 戊子년월 생은 부친의 상속을 많이 받는다.   (출산 O)`,``,``,0,0,0,0],
    [607,`甲寅`,7,`庚午`,`(타인에게 은혜를 베푸는 일에 관련된다.)`,`● 봄철 출생자는 장수한다.`,`● 여름철 생은 부자이다.   (출산 O)`,`● 년월주에 申이 보이면 권리가 있다.   (출산 O)`,`● 酉월 생은 성정이 안정되지 못한다.   (출산 X)`,`● 겨울철 생은 처자식이 불리하다.   (출산 X)`,0,0,0],
    [608,`甲寅`,8,`辛未`,`(관귀의 재가 있고, \n타인에게 의뢰한 일로 복을 얻는다.
  전토ㆍ분묘ㆍ불사에 관련되고 
  왕상은 전토, 
  휴수는 분묘사이다.)`,`● 년월에 亥ㆍ申이 보이면 吉하다.   (출산 O)`,`● 년월에 丑이나 酉가 나란히 있으면 大吉하다.   (출산 O)`,``,``,0,0,0,0],
    [609,`甲寅`,9,`壬申`,`(상매건의 좌절ㆍ근심ㆍ걱정이 있다.\n간사출분의 변동이 많다.
  별리사가 있다.
  천강사장 유무 ?)`,`● 좋은 중에 잃는 것이 있게 될것이다.`,`● 辰ㆍ戌ㆍ丑ㆍ未년월 생은 大吉하다.   (출산 O)`,`● 寅ㆍ午ㆍ戌월 생은 편관이 制되면 吉하다.   확인 후 (출산 O)`,``,0,0,0,0],
    [610,`甲寅`,10,`癸酉`,`(男：관사, \n女：남편 일이다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 수명이 吉하다.`,`● 여름철 출생자는 성정이 부정하다.   (출산 X)`,`● 가을철 출생자는 凶이 많다.   (출산 X)`,`● 겨울철 출생자는 평범하다.`,0,0,0,0],
    [611,`甲寅`,11,`甲戌`,`(신앙ㆍ종교인ㆍ산기인이 많다.)`,`● 40세 후에는 쇠퇴한다.   (출산 X)`,``,``,``,0,0,0,0],
    [612,`甲寅`,12,`乙亥`,`(실종ㆍ실주ㆍ도망사가 있다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 부자이다.   (출산 O)`,`● 辛酉월 생은 貴하다.   (출산 O)`,`● 겨울철 생은 평범하다.`,``,0,0,0,0],
    [613,`乙卯`,1,`丙子`,`(男ㆍ女불문하고 색정사가 있다.\n上ㆍ下 불화한다.
  매사불성하고 의ㆍ복의 도에 의지해야 자립한다.)`,`● 高命 이다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未ㆍ亥년월 생은 凶중 吉이 있게 된다.`,`● 丁巳월 생은 破祖한다.   (출산 X)`,`● 戊申월 생은 신체가 완전치 못하다.   (출산 X)`,`● 乙酉월 생은 凶하다.   (출산 X)`,0,0,0],
    [614,`乙卯`,2,`丁丑`,`(실주ㆍ실종사가 있다.\n빈병이고 의ㆍ복의 도에 의지해야 자립한다.
  이권을 상실한다.)`,`● 亥월 생은 福이 많다.   (출산 O)`,`● 申ㆍ亥월 생은 무관이다.   (출산 O)`,``,``,0,0,0,0],
    [615,`乙卯`,3,`戊寅`,`(의술ㆍ구류술업을 한다.)`,`● 刑中에 발복이 있다.`,`● 가을철 생은 貴하다.   (출산 O)`,`● 酉년생, 또는 辰ㆍ戌ㆍ丑ㆍ未월 생은 부자이다.   (출산 O)`,`● 午월 생은 천간에 관성이 보이면 吉하다.   확인 후 (출산 O)`,0,0,0,0],
    [616,`乙卯`,4,`己卯`,`(外 근심이 있다.\n모든 일이 막히고 금전의 손해와 여인사가 있다.)`,`● 봄철 출생자는 淸高하나 고독하다.`,`● 여름철 출생자는 평범하다. 申이 없으면 질병이 있게 된다.   (출산 X)`,`● 겨울철 출생자는 온후하다.`,`● 丑ㆍ未년월 생은 현달한다.   (출산 O)`,0,0,0,0],
    [617,`乙卯`,5,`庚辰`,`(외교가 막히고 타인에게 부탁받은 일이 안되고 있다.\n가산탕진ㆍ형상파재ㆍ만사상쟁ㆍ불완결하다.)`,`● 官貴하다.   (출산 O)`,`● 火ㆍ土년월 생도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [618,`乙卯`,6,`辛巳`,`(출입사가 급하다.)`,`● 봄철 출생자는 富貴자이다.   (출산 O)`,`● 여름철 출생자는 평범하다.`,`● 가을철, 겨울철 출생자는 吉하다.   (출산 O)`,`단, 가을생은 겨울생 만큼은 못하다.`,0,0,0,0],
    [619,`乙卯`,7,`壬午`,`(계속이 불가하고 개변사가 있고 일체 불완전하다.)`,`● 高名 이다.   (출산 O)`,`● 辰ㆍ戌월 생은 평범하다.`,`● 酉월 출생자는 貴하다.   (출산 O)`,``,0,0,0,0],
    [620,`乙卯`,8,`癸未`,`(종교ㆍ신앙ㆍ기도인이 많다.)`,`● 午ㆍ未년월 출생자는 富貴자이다.   (출산 O)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 부모 반대를 무릅쓰고 처를 취한다.`,``,0,0,0,0],
    [621,`乙卯`,9,`甲申`,`(보통 사서 고생할 경우가 많다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 총명하고 재능이 있게 된다.   (출산 O)`,`● 여름철 생은 노력하나 얻는 것이 없다.   (출산 X)`,`● 가을철, 겨울철 생은 질병을 갖는다.   (출산 X)`,``,0,0,0,0],
    [622,`乙卯`,10,`乙酉`,`(골육참상ㆍ부부불화ㆍ가정개동의 사건이다.\n초전이 본 점시를 도우면 모망사 불성하고 몸을 상한다.
  초전 辰ㆍ戌ㆍ未ㆍ申ㆍ酉 여부를 확인하라.)`,`● 申ㆍ酉월 생은 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [623,`乙卯`,11,`丙戌`,`(전토ㆍ분묘ㆍ불사에 관련된다.\n왕상은 전토, 휴수는 분묘사로 본다.
  유명무실하고 선합후난의 조짐이 있다.)`,`● 寅ㆍ卯월 출생자는 대운 西方운에 貴하다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [624,`乙卯`,12,`丁亥`,`(上人의 병에는 양의를 구한다.)`,`● 巳ㆍ酉ㆍ丑월 생자는 편관, 申월 생은 정관으로 貴하다.   (출산 O)`,`● 亥월 생은 대운 東ㆍ南운에 사법직에 오른다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [625,`丙辰`,1,`戊子`,`(모망사 불성하고 실재ㆍ실탈사가 있다.\n의ㆍ복의 도에 의지해야 자립한다.
  권리를 상실한다.)`,`● 戌월 출생자는 貴吉하다.   (출산 O)`,`● 己巳월 생은 凶하다.   (출산 X)`,`● 己亥월 생은 형액이 있다.   (출산 X)`,`● 癸丑월 생은 破祖한다.   (출산 X)`,0,0,0,0],
    [626,`丙辰`,2,`己丑`,`(자식에 의지하기 어렵고, 의ㆍ복의 도에 의지해야 자립한다.\n속는 일이 많다.)`,`● 戌월이 아니면 不發한다.   (출산 X)`,``,``,``,0,0,0,0],
    [627,`丙辰`,3,`庚寅`,`(출입사가 속하고 괴이건이 있거나 가정에 재해가 일어난다.)`,`● 寅ㆍ午ㆍ戌ㆍ未년월 생은 처가 현명하고 자식은 효자이다. 富貴 쌍전한다.   (출산 O)`,``,``,``,0,0,0,0],
    [628,`丙辰`,4,`辛卯`,`(가산탕진ㆍ형상파재ㆍ만사상쟁ㆍ불완결의 일이 있고, \n수구가 좋고 움직이면 실착을 가져온다.)`,`● 寅ㆍ戌월 출생자는 高名 이다.   (출산 O)`,``,``,``,0,0,0,0],
    [629,`丙辰`,5,`壬辰`,`(모든 일이 막히고 금전의 암손이 있고 여인사도 있다.\n고의부결ㆍ진퇴반복ㆍ개변사가 많다.)`,`● 몸이 외롭고 관재가 있다.`,`● 금전은 있으나 凶死 한다.   (출산 X)`,`● 봄철 출생자는 대운 北운에 吉하다.`,`● 여름철 출생자는 대운 東方운에 吉하다.`,`● 가을철 출생자는 대운 南方운이 吉하다.   이상 대운보아 (출산 O)`,0,0,0],
    [630,`丙辰`,6,`癸巳`,`(외교가 막히고 타인으로부터 위탁 받은 일이 안되고 있다.)`,`● 辰ㆍ戌년월 출생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [631,`丙辰`,7,`甲午`,`(금전 손모와 여자와의 갈등이 있고 형제ㆍ친구가 관련되어있다.)`,`● 寅ㆍ卯월 출생자는 吉하다.`,`● 辛ㆍ酉월 출생자는 재복이 있다.`,`● 巳ㆍ午월 출생자는 평온하다.`,`● 亥ㆍ子월 출생자는 官貴자이다.   이상 (출산 O)`,0,0,0,0],
    [632,`丙辰`,8,`乙未`,`(종교ㆍ신앙ㆍ기도인이 많다.)`,`● 봄철 생은 吉하다.   (출산 O)`,`● 여름철 생은 평온하다.   (출산 O)`,`● 가을철 생은 고생한다.   (출산 X)`,`● 겨울철 생은 貴하다.   (출산 O)`,0,0,0,0],
    [633,`丙辰`,9,`丙申`,`(출입사가 속하고 上ㆍ下ㆍ內ㆍ外 근심이 있다.\n실종ㆍ도망사가 발생한다.)`,`● 寅월 생은 대운 東ㆍ南운에 貴하다.   이상 보아 (출산 O)`,`● 未ㆍ戌월 생은 신체가 약하고 단명하게 된다.   (출산 X)`,``,``,0,0,0,0],
    [634,`丙辰`,10,`丁酉`,`(男ㆍ女불문하고 색정사가 있다.\n귀인사에 관여 하고 관귀의 財가 있고 
  타인에 의뢰한 일로 복을 얻는다.)`,`● 寅ㆍ午ㆍ戌월 생은 년월주에 인수가 보이면 貴하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [635,`丙辰`,11,`戊戌`,`(유명무실하고 불리하며 오랜 후에 무사하다.\n노복 도주건이 있고 애락이 불명하다.)`,`● 午월 생은 貴하다.   (출산 O)`,`● 亥월 생은 고독하다.   (출산 X)`,``,``,0,0,0,0],
    [636,`丙辰`,12,`己亥`,`(의술ㆍ종교인이 많다.\n초전이 본 점시를 도우면 모망사 불성하고 몸이 상한다.
  초전 亥ㆍ申ㆍ酉 여부를 확인하라.)`,`● 寅월 생은 貴하다.   (출산 O)`,`● 辰월 생은 스님으로 이름을 얻게 된다.`,`● 戌월 생은 吉하다.   (출산 O)`,`● 巳월 생도 吉하다.   (출산 O)`,0,0,0,0],
    [637,`丁巳`,1,`庚子`,`(근심사가 많고 신체변동도 많으며 \n다집다산하고 변전사도 많다.
  外의 근심이 있다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 봄ㆍ여름철 생은 吉하다.   (출산 O)`,`● 가을ㆍ겨울철 생은 평범하다.`,`● 庚寅월 생은 형액이 있다.`,`● 庚申월 생은 大破가 있다.`,`● 辛酉월 생도 형액이 있다.   이상(출산 X)`,0,0,0],
    [638,`丁巳`,2,`辛丑`,`(유명무실하고 길흉이 더디다.\n타인에게 속는 일이 있다.
  남자가 상한다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 여자로 인해 貴해진다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑ㆍ申년월 출생자는 재복이 있다.   (출산 O)`,`● 卯월 생은 평범하다.`,``,0,0,0,0],
    [639,`丁巳`,3,`壬寅`,`(형상파재ㆍ저해ㆍ의혹사가 일어난다.\n가장은 택모를 잃고 움직이면 실착이 초래된다.)`,`● 시작은 있으나 끝이 없다.   (출산 X)`,`대운 金ㆍ水운에 西ㆍ北방위가 吉하다.`,``,``,0,0,0,0],
    [640,`丁巳`,4,`癸卯`,`(먼저 숙이고 나중에 펴는 상이다.)`,`● 丑월 생은 대운 北方운에 吉하다.`,`● 辛酉월 생은 년월 천간에 庚申이 보이면 吉하다.   확인 후 (출산 O)`,``,``,0,0,0,0],
    [641,`丁巳`,5,`甲辰`,`(선길후흉의 손모건이 있다.)`,`● 凶ㆍ刑ㆍ孤 하다.   (출산 X)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 여름ㆍ가을철 생은 평범하다.`,`● 겨울철 출생자는 한직에 머문다.`,0,0,0,0],
    [642,`丁巳`,6,`乙巳`,`(모든 일이 막히거나 타인에 의해 금전 손해가 있고 \n여인사가 있다.)`,`● 巳월 출생자가 水를 보지 않으면 吉하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [643,`丁巳`,7,`丙午`,`(男ㆍ女 불문하고 색정사가 있다.)`,`● 大吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [644,`丁巳`,8,`丁未`,`(외교가 막혔고 타인의 부탁 받은 일이 지체 되고 있다.)`,`● 大貴자이다.   (출산 O)`,`단, 午가 있으면 안된다.   (출산 X)`,``,``,0,0,0,0],
    [645,`丁巳`,9,`戊申`,`(계속이 불가하고 개신사가 일어나며 일체 불완전하다.\n화합중 서로 다툼이 있고 
  上ㆍ下불순하며 은혜를 원수로 갚는다.)`,`● 富貴를 바라지 말라.   (출산 X)`,`● 刑을 받지 않으면 처자 근심이 크다.   (출산 X)`,`● 가을철과 겨울철 출생자는 노고의 삶이다.   (출산 X)`,`● 여름철 출생자는 대운 西ㆍ北운에 吉이 있다.   이상 보아 (출산 O)`,0,0,0,0],
    [646,`丁巳`,10,`己酉`,`(上人과 財를 따지는 일이 있다.)`,`● 巳ㆍ酉ㆍ丑년월 출생자는 富貴자이다.   (출산 O)`,`● 亥ㆍ子년월 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [647,`丁巳`,11,`庚戌`,`(실주ㆍ실종사가 있다.)`,`● 辰ㆍ巳월 생은 대운 金ㆍ水운에 사법직에 오른다.   이상 보아 (출산 O)`,`● 未가 년월에 같이 있으면 평범하다.`,``,``,0,0,0,0],
    [648,`丁巳`,12,`辛亥`,`(관리는 천전의 기쁨이 있다.\n초전이 본 점자를 도우면 몸이 상한다.)`,`● 巳ㆍ午월 출생자는 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [649,`戊午`,1,`壬子`,`(동분서주ㆍ제사변천ㆍ행동결함이 있다.\n낭비가 많고 금전유통의 저해가 있다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 처를 근심하고 자식을 상한다.   (출산 X)`,`● 巳ㆍ午년월 출생자는 사법직을 갖는다.   (출산 O)`,`● 申월 생은 대운 木ㆍ火운에 貴하다.   이상 보아 (출산 O)`,`● 丙午월 생자는 凶하다.   (출산 X)`,`● 癸亥월 생자는 형액이 있다.   (출산 X)`,0,0,0],
    [650,`戊午`,2,`癸丑`,`(의ㆍ복의 도에 의지해야 자립한다.\n형제의 재액을 겪고 금전ㆍ여인과 인연이 기한다.)`,`● 寅ㆍ戌ㆍ未ㆍ午년월 출생자는 총명 발달한다.   (출산 O)`,``,``,``,0,0,0,0],
    [651,`戊午`,3,`甲寅`,`(관사ㆍ재해의 근심이 있고 출입사가 빠르다.\n초전이 본 점시를 도우면 모망사 불성하고 몸이 상한다.
  초전 亥ㆍ寅ㆍ卯를 확인하라.)`,`● 巳ㆍ酉ㆍ丑월 출생자는 문필로 세상을 놀라게 할것이다.   (출산 O)`,`● 寅ㆍ卯ㆍ辰ㆍ巳ㆍ戌ㆍ子년월 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [652,`戊午`,4,`乙卯`,`(男ㆍ女불문하고 색정사가 있다.\n계속이 불가하고 개신사가 일어나고 일체 불완전하다.
  천강사장 유무 ?)`,`● 봄철 출생자는 吉하다.   (출산 O)`,`● 午ㆍ未ㆍ申ㆍ亥년월 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [653,`戊午`,5,`丙辰`,`(신앙ㆍ종교ㆍ기도인이 많다.)`,`● 亥ㆍ卯년월 출생자는 대운 東ㆍ北운에 부자가 된다.`,`● 丑ㆍ未년월 출생자는 대운 西ㆍ南운에 吉하다.   이상 대운보아 (출산 O)`,``,``,0,0,0,0],
    [654,`戊午`,6,`丁巳`,`(외교가 막혔고 타인에게 부탁 받은 일이 지체 되고 있다.)`,`● 년월주에 甲ㆍ乙ㆍ寅ㆍ卯가 없으면 富貴자이다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [655,`戊午`,7,`戊午`,`(모든 일이 막히고 타인에 의해 금전의 손해가 있고 \n여인사가 있다.
  上ㆍ下부동하고 가문상침하고 대탕소음 한다.)`,`● 형액을 받은후 발달한다.`,`● 년월에 나란히 午가 있으면 貴하다.   확인 후 (출산 O)`,``,``,0,0,0,0],
    [656,`戊午`,8,`己未`,`(시험ㆍ선거사는 고위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上에서 未를 확인하라.)`,`● 기획력에 뛰어나다. 수명이 길지 않다.`,``,``,``,0,0,0,0],
    [657,`戊午`,9,`庚申`,`(上ㆍ下ㆍ內ㆍ外 근심이 있고 남자가 상한다.)`,`● 甲ㆍ寅ㆍ丙ㆍ卯를 보지 않으면 대운 西ㆍ北운에 大貴 한다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [658,`戊午`,10,`辛酉`,`(자손사, 극부사, 직위해탈사로 본다.)`,`● 봄ㆍ여름 출생자는 평범하다.`,`● 가을철 생은 吉하다.   (출산 O)`,`● 겨울철 생은 처자식과의 인연이 不吉하다.   (출산 X)`,``,0,0,0,0],
    [659,`戊午`,11,`壬戌`,`(전토ㆍ분묘ㆍ불사에 관련된다.\n왕상：전토사
  휴수：분묘사)`,`● 寅ㆍ午ㆍ戌 년월 생은 고독하고 초년이 나쁘다.`,``,``,``,0,0,0,0],
    [660,`戊午`,12,`癸亥`,`(외동을 주사하고 타인과 다툼이 있다.)`,`● 貴하다.`,`● 寅ㆍ巳년월 생도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [661,`己未`,1,`甲子`,`(男ㆍ女불문하고 색정사가 있다.\n의ㆍ복의 도에 의지해야 자립한다.
  이권을 상실한다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생은 吉하다.   (출산 O)`,`● 卯년월 출생자는 困한다.`,`● 寅ㆍ亥월 생은 문장으로 명성을 얻는다.   (출산 O)`,`● 甲申월 생은 몸이 불완전하다.`,`● 丙子월 생은 고독하다.,● 丁丑월 생은 형액이 있다.   이상(출산 X)`,0,0],
    [662,`己未`,2,`乙丑`,`(上ㆍ下ㆍ內ㆍ外 근심이 있고 男ㆍ女가 다같이 상한다.\n형제ㆍ붕우의 이심이 있고 유명무실하고 불리하다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 辰ㆍ戌이 년월에 각기 있게 되면 大貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [663,`己未`,3,`丙寅`,`(관사 명예사가 있다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자는 대운 東ㆍ北운에 吉하다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [664,`己未`,4,`丁卯`,`(초전이 본 점시를 도우면 모망사 불성하고 몸을 상한다.\n초전 亥ㆍ寅ㆍ卯 여부를 확인하라.)`,`● 富貴를 바라지 말라.   (출산 X)`,`● 卯ㆍ戌ㆍ亥ㆍ丑년월 출생자는 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [665,`己未`,5,`戊辰`,`(신앙ㆍ종교ㆍ기도인이 많다.)`,`● 丑월 생은 吉하다.   (출산 O)`,`● 寅ㆍ巳ㆍ亥ㆍ午ㆍ戌년월 출생자는 문필가로 이름이 있다.   (출산 O)`,``,``,0,0,0,0],
    [666,`己未`,6,`己巳`,`(기쁜 소식이 있고 천관ㆍ영전사가 있게 된다.)`,`● 년월지에 午가 보이지 않으면 부자이다.   (출산 O)`,`● 편관이 보이지 않아도 부자이다.   (출산 O)`,``,``,0,0,0,0],
    [667,`己未`,7,`庚午`,`(결혼 등 화합사가 있거나 외부의 구재ㆍ여행ㆍ통신의 즐거움을 갖는다.)`,`● 육친을 刑剋 한다. 말년에 이르러 발복한다.`,``,``,``,0,0,0,0],
    [668,`己未`,8,`辛未`,`(외교가 막히고 부탁받은 일이 안되고 있다.\n타인에 의한 금전 손해가 있고 여인사도 있다.)`,`● 寅ㆍ午ㆍ戌월 생은 貴하다.   (출산 O)`,`● 亥ㆍ卯ㆍ辰ㆍ午월 생은 대운 金ㆍ火ㆍ木운에 大吉하다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [669,`己未`,9,`壬申`,`(시험ㆍ선거사는 고위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上에서 申을 확인하라.)`,`● 巳월 생은 貴하다.   (출산 O)`,`● 亥ㆍ子ㆍ寅월 생은 대운 金ㆍ火운에 부자가 된다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [670,`己未`,10,`癸酉`,`(월장 酉는 임신, 월장 丑은 결혼사가 있다.)`,`● 戌ㆍ亥ㆍ丑ㆍ辰년월 출생자는 대운 西方운에 貴하다.   이상 보아 (출산 O)`,`● 子ㆍ巳년월 생도 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [671,`己未`,11,`甲戌`,`(아랫사람이 윗사람을 기만하고 사물은 막힌다.\n계속이 불가하고 개신사가 기하고 일체 불완전하다.
  男ㆍ女가 상한다.)`,`● 관재 구설이 많다.   (출산 X)`,`● 辛ㆍ卯년월 출생자는 형액이 있다.   (출산 X)`,`● 寅ㆍ午년월 출생자는 권리가 있다.   (출산 O)`,``,0,0,0,0],
    [672,`己未`,12,`乙亥`,`(본 점시에 점지된 천장으로 상의를 구별하라.)`,`● 사주에 충형이 없으면 문장으로 명성을 얻게 된다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [673,`庚申`,1,`丙子`,`(하고자 하는 일은 불성하거나 실재ㆍ실탈이 있다.\n자식에 의지하기 어렵고 신변기복이 많다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 申월 생은 대운 土운에 발전한다.`,`● 未년월 생은 대운 西方운에 부자가 된다.   이상 대운보아 (출산 O)`,`● 乙巳월 생은 형액이 있다.   (출산 X)`,`● 丁丑월 생은 好中 凶이 있다.   (출산 X)`,0,0,0,0],
    [674,`庚申`,2,`丁丑`,`(전토ㆍ분묘ㆍ불사에 관련된다.\n왕상：전토
  휴수：분묘사이다.
  의ㆍ복의 도에 의지해야 자립한다.
  고립무원한다.)`,`● 丑월 생은 대운 金ㆍ火운에 貴하다.   이상 보아 (출산 O)`,`● 巳ㆍ午ㆍ未ㆍ戌월 생은 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [675,`庚申`,3,`戊寅`,`(사귀발동ㆍ전직 이동사가 있다.)`,`● 처의 걱정이 많고 자식은 형액이 있다.   (출산 Z)`,`● 寅ㆍ卯ㆍ辰년월 생은 대운 火ㆍ金운에 吉하다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [676,`庚申`,4,`己卯`,`(손해가 있다.)`,`● 卯월 생은 부귀를 바라지 말라.   (출산 X)`,`● 丑ㆍ巳년월 생은 淸高하게 산다.`,``,``,0,0,0,0],
    [677,`庚申`,5,`庚辰`,`(종교ㆍ신앙ㆍ기도인이 많다.)`,`● 子ㆍ申년월 생이 丙ㆍ丁ㆍ巳ㆍ午가 없으면 大貴하다.   확인 후 (출산 O)`,``,``,``,0,0,0,0],
    [678,`庚申`,6,`辛巳`,`(웃음 속에 칼이 있고 꿀에 독이 들었다.\n실주ㆍ도망ㆍ실종건이 있다.
  초전이 본 점시를 도우면 몸이 상한다.)`,`● 형액이 있다.   (출산 X)`,`● 巳ㆍ酉ㆍ丑월 생은 특별한 발달을 하게 될것이다.   (출산 O)`,``,``,0,0,0,0],
    [679,`庚申`,7,`壬午`,`(초전이 본 점시를 도우면 모망사는 불성하고 몸을 다친다.\n초전 辰ㆍ戌ㆍ未ㆍ申ㆍ酉를 확인하라.)`,`● 吉하다.   (출산 O)`,`● 午ㆍ未년월 생은 貴하다.   (출산 O)`,`● 卯ㆍ辰ㆍ巳월 생도 吉하다.   (출산 O)`,``,0,0,0,0],
    [680,`庚申`,8,`癸未`,`(시험ㆍ선거사는 상위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上에서 未 여부를 확인하라.)`,`● 酉월 생은 장성급 무관이다.   (출산 O)`,``,``,``,0,0,0,0],
    [681,`庚申`,9,`甲申`,`(타인이 부탁한 일이 안되고 있다.)`,`● 吉하다.   (출산 O)`,`● 卯ㆍ午ㆍ未ㆍ戌ㆍ丙ㆍ丁이 없으면 大吉하다.   확인 후 (출산 O)`,``,``,0,0,0,0],
    [682,`庚申`,10,`乙酉`,`(男ㆍ女 불문하고 색정사가 있다.\n내외 손모가 많고 부부갈등이 있다.)`,`● 寅ㆍ午ㆍ戌년월 생은 大貴하다.   (출산 O)`,`● 巳ㆍ酉ㆍ丑년월 생은 中貴하다.   (출산 O)`,`● 申ㆍ子ㆍ辰년월 생은 부자이다.   (출산 O)`,`● 卯ㆍ未년월 생은 不具가 된다.`,0,0,0,0],
    [683,`庚申`,11,`丙戌`,`(관직이 상진하고 무인은 전공하고 상인은 출속인이 많다.\n신앙ㆍ종교ㆍ기도인이다.)`,`● 戌ㆍ亥년월 생은 대운 東ㆍ南운에 吉하다.   이상 보아 (출산 O)`,`● 申월 생자는 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [684,`庚申`,12,`丁亥`,`(內ㆍ外근심이 있다.\n선저후성ㆍ유시무종ㆍ산악고처의 재난이있게 된다.)`,`● 가을철 출생자는 대운 南方운에 貴하다.`,`● 北운에 평상적이다.   이상 보아 (출산 O)`,``,``,0,0,0,0],
    [685,`辛酉`,1,`戊子`,`(여자ㆍ아손의 재해가 있다.\n의ㆍ복의 도에 의지해야 자립한다.
  자손사로 속는 일이 있다.)`,`● 子ㆍ丑월생이 丙ㆍ丁을 보지 않으면 대운 火운에 처자식을 잃게 될것이다.   확인 후 (출산 X)`,`● 壬寅월 생은 凶하다.   (출산 X)`,`● 辛巳월 생은 크게 패한다.`,`● 庚戌월 생은 凶하다.   이상 (출산 X)`,0,0,0,0],
    [686,`辛酉`,2,`己丑`,`(출입사가 속한다.\n의ㆍ복의 도에 의지해야 자립한다.
  사업후원사에 고립무원된다.)`,`● 破祖한다.   (출산 X)`,`● 巳ㆍ酉ㆍ丑월 생은 금전복은 있으나 처자식이 아쉽다.`,``,``,0,0,0,0],
    [687,`辛酉`,3,`庚寅`,`(시험ㆍ선거사는 고위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上에서 寅을 확인하라.)`,`● 봄철 출생자는 돈을 모은다.`,`● 여름철 출생자는 관록을 얻는다.`,`● 가을철 출생자는 건강하다.`,`● 겨울철 출생자는 吉하다.   이상 (출산 O)`,0,0,0,0],
    [688,`辛酉`,4,`辛卯`,`(가정개변사가 있다.\n골육참상ㆍ부부불화가 있다.)`,`● 어려운 가정에서 외롭게 태어나 중년에 富貴한다.   (출산 O)`,``,``,``,0,0,0,0],
    [689,`辛酉`,5,`壬辰`,`(유명무실하고 불리하다.\n전토ㆍ분묘ㆍ불사에 관련된다.
  왕상：전토 
  휴수：분묘사)`,`● 봄철,여름철 생은 富貴하다.   (출산 O)`,`● 가을철 생은 吉하다.   (출산 O)`,`● 겨울철 출생자는 백정(白丁)이 된다.   (출산 X)`,``,0,0,0,0],
    [690,`辛酉`,6,`癸巳`,`(상 받는 일이 있거나 명예사가 있다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 貴하다.   (출산 O)`,`단, 주색을 좋아 한다.`,`● 寅ㆍ卯월 생은 재복이 크다.   (출산 O)`,``,0,0,0,0],
    [691,`辛酉`,7,`甲午`,`(男ㆍ女불문하고 색정사가 있다.\n초전이 본 점시를 도우면 모망사 불성하고 몸이 상한다.
  초전 寅卯巳午를 확인하라.)`,`● 봄철 출생자는 재복이 있다.   (출산 O)`,`● 여름철 출생자는 관록을 먹는다(출산 O)`,`● 가을철 생은 富를 이루나 오래가지 못한다.   (출산 X)`,`● 겨울철 생은 평범하다.`,0,0,0,0],
    [692,`辛酉`,8,`乙未`,`(上ㆍ下 내외 근심이 있고 남자가 상한다.\n실주ㆍ도망사가 있다.)`,`● 巳ㆍ午월 출생자는 대운 東운에 大吉하다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未년월 생도 吉하다.   (출산 O)`,`● 가을철 출생자는 평범하다.`,``,0,0,0,0],
    [693,`辛酉`,9,`丙申`,`(망신사를 예방하라.)`,`● 寅ㆍ巳ㆍ午ㆍ未ㆍ戌월 생은 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [694,`辛酉`,10,`丁酉`,`(모든 일이 막히거나 타인에 의한 금전손해ㆍ여인사가 있다.\n고의반복ㆍ진퇴개변이 많다.
  근심사가 있다.)`,`● 辛酉월 생자는 사법권과 형무권을 동시에 쥔다.   (출산 O)`,``,``,``,0,0,0,0],
    [695,`辛酉`,11,`戊戌`,`(가정손상ㆍ부부변심이 있고 외교가 막혔으며 부탁받은 일이 지체되고 있다.)`,`● 형액이 凶하다.   (출산 X)`,`● 巳ㆍ酉ㆍ辰ㆍ戌ㆍ丑ㆍ未년월 생은 재능이 있고 이름을 얻는다.   (출산 O)`,``,``,0,0,0,0],
    [696,`辛酉`,12,`己亥`,`(직위변동사가 있다.)`,`● 辰ㆍ戌ㆍ丑ㆍ未년월 생은 吉하다.   (출산 O)`,``,``,``,0,0,0,0],
    [697,`壬戌`,1,`庚子`,`(男ㆍ女 불문하고 색정사가 있다.\n의ㆍ복의 도에 의지해야 자립한다.
  형제의 재액을 겪는다.)`,`● 단명한다.   (출산 X)`,`● 丑월 생은 대운 金ㆍ火운에 吉하다.   이상 보아 (출산 O)`,`● 己酉월 생은 고독하다.   이하 (출산 X)`,`● 丁亥월 생은 형액이 있다.`,`● 辛丑월 생도 형액이 있다.`,0,0,0],
    [698,`壬戌`,2,`辛丑`,`(大는 小를 기만하고 가정은 암담하다.\n권리를 상실한다.
  관사근심이 있다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 辰월 생은 관직자로 吉하다.   (출산 O)`,`● 丑ㆍ戌월 생도 吉하다.   (출산 O)`,`● 년월주에 乙ㆍ癸ㆍ卯가 있으면 평범하다.`,``,0,0,0,0],
    [699,`壬戌`,3,`壬寅`,`(실주ㆍ도망사가 있다.)`,`● 이름을 크게 날리게 된다.   (출산 O)`,`단, 그 貴가 오래가지 않는다.`,``,``,0,0,0,0],
    [700,`壬戌`,4,`癸卯`,`(陽光이 늦는 冬에는 염막귀인으로도 본다.)`,`● 甲ㆍ癸ㆍ午ㆍ酉년월 생은 文貴하다.   (출산 O)`,`● 申월 생은 평범하다.`,``,``,0,0,0,0],
    [701,`壬戌`,5,`甲辰`,`(유명무실하고 불리하다.\n길흉이 빠르고 노복의 도주건이 있다.
  전토ㆍ분묘ㆍ불사에 관련된다.
  초전이 본 점시를 도우면 모망사 불성하고 몸을 상한다.)`,`● 寅ㆍ申ㆍ酉월 생은 대운 南方운에 귀인에 가깝다.   이상 보아 (출산 O)`,`● 木ㆍ水월 생자 역시 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [702,`壬戌`,6,`乙巳`,`(외동주사하고 타인과 다툼이 있다.\n다난하다.)`,`● 년월주에 寅이 있으면 貴하다.   (출산 O)`,`● 辰ㆍ戌ㆍ丑ㆍ未월 생자도 吉하다.   (출산 O)`,``,``,0,0,0,0],
    [703,`壬戌`,7,`丙午`,`(외화내빈의 실정이다.)`,`● 三合財局자는 大富이다.   (출산 O)`,`● 가을철 출생자는 貴하다.   (출산 O)`,``,``,0,0,0,0],
    [704,`壬戌`,8,`丁未`,`(관근심이 있고 아랫사람은 윗사람을 속이고 사물은 결과가 없다.\n계속이 불가하고 개신사가 일어나고 일체 불완전하다.)`,`● 중년에 상처한다.`,`● 亥ㆍ子ㆍ申ㆍ酉월 생은 부자이다.   (출산 O)`,`● 辰월 생은 大吉하다.   (출산 O)`,``,0,0,0,0],
    [705,`壬戌`,9,`戊申`,`(외부의 기쁜 소식이 있고 관직자는 영전한다.)`,`● 지체가 많은 인생이다.   (출산 X)`,`● 년월에 酉가 나란히 있으면 부자이다.   (출산 O)`,`● 戊ㆍ己ㆍ土가 많으면 눈병을 가진다.   확인 후 (출산 X)`,``,0,0,0,0],
    [706,`壬戌`,10,`己酉`,`(가정손상ㆍ부부변심ㆍ수하인의 농락이 있다.\n수구가 좋고 움직이면 실착하게 된다.)`,`● 亥ㆍ寅ㆍ子월 생은 대운 財ㆍ官운에 貴하다.`,`● 亥나 子가 년월에 나란히 있으면 文貴하다.   (출산 O)`,``,``,0,0,0,0],
    [707,`壬戌`,11,`庚戌`,`(모든 일이 막히고 타인에 의한 금전손해와 여인사가 있다.\n초전이 본 점시를 도우면 모망사 불성하고 몸을 상한다.)`,`● 처첩으로 인하여 재난이 있게 된다.   (출산 X)`,`● 일마다 반복이다.`,`● 년월에 戌이 같이 있으면 貴하다.   (출산 O)`,`● 寅ㆍ丑년월 생은 무관이다.`,0,0,0,0],
    [708,`壬戌`,12,`辛亥`,`(외교가 막히고 타인에게 부탁받은 일이 안되고 있다.)`,`● 소년에 뜻을 이룬다.   (출산 O)`,`● 亥ㆍ申ㆍ巳ㆍ戌년월 생은 각기 같은 방위에서 뜻을 펴게 된다.   (출산 O)`,``,``,0,0,0,0],
    [709,`癸亥`,1,`壬子`,`(男ㆍ女불문하고 색정사가 있다.\n일생 파란이 많고 다집다산하며 결과가 없다.
  의ㆍ복의 도에 의지해야 자립한다.)`,`● 貴하다.   (출산 O)`,`● 戊ㆍ寅월 생은 凶死한다.   이하 (출산 X)`,`● 戊ㆍ申월 생은 고독하다.`,`● 巳ㆍ酉월 생은 곤고하다.`,0,0,0,0],
    [710,`癸亥`,2,`癸丑`,`(외교가 막히고 타인의 부탁건이 지체되고 있다.\n소모건이 많고 관송사가 기한다.
  의ㆍ복의 도에 의지해야 자립한다.
  고독인 이다. 권리를 상실한다.)`,`● 子ㆍ巳ㆍ午ㆍ未가 없으면 大貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [711,`癸亥`,3,`甲寅`,`(화류계 출입 관계인이 많고 예술계로 진출하면 발달한다.\n패후재성하고 웃음 속에 칼이 있다.)`,`● 子ㆍ丑ㆍ未ㆍ申월 생은 貴하다.   (출산 O)`,``,``,``,0,0,0,0],
    [712,`癸亥`,4,`乙卯`,`(시험ㆍ선거사는 고위에 당선ㆍ합격한다.\n干上ㆍ支上ㆍ年命上ㆍ行年上에서 卯를 확인하라.)`,`● 辰ㆍ巳월 생은 사법권을 갖는다.   (출산 O)`,``,``,``,0,0,0,0],
    [713,`癸亥`,5,`丙辰`,`(처의 실주ㆍ도망ㆍ실종사를 방지해야 한다.)`,`● 甲木을 보지 않으면 대운 南方운에 吉하다.   이상 보아 (출산 O)`,``,``,``,0,0,0,0],
    [714,`癸亥`,6,`丁巳`,`(반복이 많고 순거역래하며 유리전폐사가 있다.)`,`● 형액이 있다.   (출산 X)`,`● 봄ㆍ여름 출생자는 吉하다.   (출산 O)`,`● 겨울철 생은 평범하다.`,`● 庚寅년월 생은 무관직을 갖는다.   (출산 O)`,0,0,0,0],
    [715,`癸亥`,7,`戊午`,`(수구가 좋고 움직이면 실착을 초래한다.)`,`● 未월 생은 대운 東方운에 大吉하다.   (출산 O)`,`● 금전은 있으나 시비가 많다.`,``,``,0,0,0,0],
    [716,`癸亥`,8,`己未`,`(외동을 주사하고 타인과 다툼이 있다.\n길흉이 빠르고 공명은 불현 한다.
  전토ㆍ분묘ㆍ불사에 관련이 있다.
  왕상：전토사 휴수：분묘사이다.)`,`● 亥년월 생은 貴하다.   (출산 O)`,`● 未월 생은 大吉하다.   (출산 O)`,``,``,0,0,0,0],
    [717,`癸亥`,9,`庚申`,`(수구가 좋고 움직이면 실착이 초래된다.)`,`● 고독하다.`,`● 未월 생은 공부욕심이 많다.`,`● 申월 생은 귀인에 가깝다.   (출산 O)`,``,0,0,0,0],
    [718,`癸亥`,10,`辛酉`,`(上人의 병재건인 경우도 된다.)`,`● 戌월 생은 대운 東方운에 貴하다.`,`● 子년월 생은 대운 財ㆍ官운에 大貴한다.   이상 대운 보아 (출산 O)`,``,``,0,0,0,0],
    [719,`癸亥`,11,`壬戌`,`(출입사가 속하고 상하내외 근심이 있고 남자가 상한다.\n초전이 본 점시를 도우면 몸이 상한다.)`,`● 봄철 출생자는 시비가 잦다.   (출산 X)`,`● 여름철 출생자는 평범하다.`,`● 가을철과 겨울철 출생자는 吉하다.   (출산 O)`,``,0,0,0,0],
    [720,`癸亥`,12,`癸亥`,`(모든 일이 막히거나 타인에 의해 금전 손해가 있고 여인사도 있다.)`,`● 총기가 있고 중년에 大富가 된다.   (출산 O)`,`● 겨울철 출생자는 戊ㆍ己를 보지 않으면 大貴하다.   확인 후 (출산 O)`,`만약 위 戊ㆍ己가 있게 되면 이름을 깨끗이 알리고 고고하게 산다.`,``,0,0,0,0]
  ]

//   VERSION_ILSANGSAEHWAL_GILHUENG_METHED,
//   VERSION001_JIDU,
//   VERSION001_CHILDBIRTH_BIRTHESDATA1,
//   VERSION001_CHILDBIRTH_BIRTHESDATA2,


export default {
      VERSION_ILSANGSAEHWAL_GILHUENG_METHED,
      VERSION001_JIDU,
      VERSION001_CHILDBIRTH_BIRTHESDATA1,
      VERSION001_CHILDBIRTH_BIRTHESDATA2,
}