const JEOLGI_LABEL0 = [
    '소한', // 0
    '입춘', // 1
    '경칩', // 2
    '청명', // 3
    '입하', // 4
    '망종', // 5
    '소서', // 6
    '입추', // 7 
    '백로', // 8
    '한로', // 9
    '입동', // 10
    '대설', // 11
    
    '대한', // 12
    '우수', // 13
    '춘분', // 14
    '곡우', // 15
    '소만', // 16
    '하지', // 17
    '대서', // 18
    '처서', // 19
    '추분', // 20
    '상강', // 21
    '소설', // 22
    '동지', // 23
]

// 대한 다음해의(12) - 우수 다음해의(13)

const JEOLGI1 = [
    
`우수 - 춘분`,
`춘분 - 곡우 `,
`곡우 - 소만 `,
`소만 - 하지 `,
`하지 - 대서 `,
`대서 - 처서 `,
`처서 - 추분 `,
`추분 - 상강 `,
`상강 - 소설 `,
`소설 - 동지 `,
`동지 - 대한 `,

]

const JEOLGI2 = [
    '소한(285)',
    '입춘(315)',
    '경칩(345)',
    '청명(15)',
    '입하(45)',
    '망종(75)',
    '소서(105)',
    '입추(135)',
    '백로(165)',
    '한로(195)',
    '입동(225)',
    '대설(255)',
    '대한(300)',
    '우수(330)',
    '춘분(0)',
    '곡우(30)',
    '소만(60)',
    '하지(90)',
    '대서(120)',
    '처서(150)',
    '추분(180)',
    '상강(210)',
    '소설(240)',
    '동지(270)',
]


const JOONGGI = [
    {
        year : 1929,
        joonggi : [
            '1929년 01월 06일 04:22:05',
            '1929년 02월 04일 16:08:46',
            '1929년 03월 06일 10:32:01',
            '1929년 04월 05일 15:51:17',
            '1929년 05월 06일 09:40:23',
            '1929년 06월 06일 14:10:52',
            '1929년 07월 08일 00:31:42',
            '1929년 08월 08일 10:08:45',
            '1929년 09월 08일 12:39:38',
            '1929년 10월 09일 03:47:05',
            '1929년 11월 08일 06:27:30',
            '1929년 12월 07일 22:56:28',
            '1929년 01월 20일 21:42:14',
            '1929년 02월 19일 12:06:52',
            '1929년 03월 21일 11:34:50',
            '1929년 04월 20일 23:10:19',
            '1929년 05월 21일 22:47:36',
            '1929년 06월 22일 07:00:36',
            '1929년 07월 23일 17:53:18',
            '1929년 08월 24일 00:41:16',
            '1929년 09월 23일 21:52:18',
            '1929년 10월 24일 06:41:26',
            '1929년 11월 23일 03:48:05',
            '1929년 12월 22일 16:52:44',
        ]
    },
    {
        year : 1930,
        joonggi : [
            '1930년 01월 06일 10:02:36',
            '1930년 02월 04일 21:51:11',
            '1930년 03월 06일 16:16:37',
            '1930년 04월 05일 21:37:26',
            '1930년 05월 06일 15:27:03',
            '1930년 06월 06일 19:58:06',
            '1930년 07월 08일 06:19:43',
            '1930년 08월 08일 15:57:01',
            '1930년 09월 08일 18:28:25',
            '1930년 10월 09일 09:37:30',
            '1930년 11월 08일 12:20:14',
            '1930년 12월 08일 04:50:40',
            '1930년 01월 21일 03:33:00',
            '1930년 02월 19일 17:59:48',
            '1930년 03월 21일 17:29:45',
            '1930년 04월 21일 05:05:48',
            '1930년 05월 22일 04:42:02',
            '1930년 06월 22일 12:52:50',
            '1930년 07월 23일 23:41:59',
            '1930년 08월 24일 06:26:21',
            '1930년 09월 24일 03:35:56',
            '1930년 10월 24일 12:25:59',
            '1930년 11월 23일 09:34:28',
            '1930년 12월 22일 22:39:33',
        ]
    },
    
    {
        year : 1931,
        joonggi : [
            '1931년 01월 06일 15:55:38',
            '1931년 02월 05일 03:40:40',
            '1931년 03월 06일 22:02:08',
            '1931년 04월 06일 03:20:27',
            '1931년 05월 06일 21:09:36',
            '1931년 06월 07일 01:41:48',
            '1931년 07월 08일 12:05:38',
            '1931년 08월 08일 21:44:57',
            '1931년 09월 09일 00:17:20',
            '1931년 10월 09일 15:26:55',
            '1931년 11월 08일 18:09:54',
            '1931년 12월 08일 10:40:18',
            '1931년 01월 21일 09:17:30',
            '1931년 02월 19일 23:40:17',
            '1931년 03월 21일 23:06:17',
            '1931년 04월 21일 10:39:47',
            '1931년 05월 22일 10:15:25',
            '1931년 06월 22일 18:28:04',
            '1931년 07월 24일 05:21:23',
            '1931년 08월 24일 12:10:16',
            '1931년 09월 24일 09:23:18',
            '1931년 10월 24일 18:15:29',
            '1931년 11월 23일 15:24:41',
            '1931년 12월 23일 04:29:34',
        ]
    },

    {
        year : 1932,
        joonggi : [
            '1932년 01월 06일 21:45:07',
            '1932년 02월 05일 09:29:23',
            '1932년 03월 06일 03:49:22',
            '1932년 04월 05일 09:06:22',
            '1932년 05월 06일 02:55:11',
            '1932년 06월 06일 07:27:47',
            '1932년 07월 07일 17:52:19',
            '1932년 08월 08일 03:31:51',
            '1932년 09월 08일 06:02:54',
            '1932년 10월 08일 21:09:40',
            '1932년 11월 07일 23:49:42',
            '1932년 12월 07일 16:18:24',
            '1932년 01월 21일 15:06:49',
            '1932년 02월 20일 05:28:23',
            '1932년 03월 21일 04:53:35',
            '1932년 04월 20일 16:28:03',
            '1932년 05월 21일 16:06:35',
            '1932년 06월 22일 00:22:37',
            '1932년 07월 23일 11:18:03',
            '1932년 08월 23일 18:06:14',
            '1932년 09월 23일 15:15:53',
            '1932년 10월 24일 00:03:53',
            '1932년 11월 22일 21:10:08',
            '1932년 12월 22일 10:14:15',

        ]
    },


    {
        year : 1933,
        joonggi : [
            '1933년 01월 06일 03:23:23',
            '1933년 02월 04일 15:09:19',
            '1933년 03월 06일 09:31:27',
            '1933년 04월 05일 14:50:32',
            '1933년 05월 06일 08:41:47',
            '1933년 06월 06일 13:17:22',
            '1933년 07월 07일 23:44:21',
            '1933년 08월 08일 09:25:34',
            '1933년 09월 08일 11:57:29',
            '1933년 10월 09일 03:03:56',
            '1933년 11월 08일 05:43:01',
            '1933년 12월 07일 22:11:08',
            '1933년 01월 20일 20:52:42',
            '1933년 02월 19일 11:16:18',
            '1933년 03월 21일 10:43:06',
            '1933년 04월 20일 22:18:16',
            '1933년 05월 21일 21:56:47',
            '1933년 06월 22일 06:11:49',
            '1933년 07월 23일 17:05:26',
            '1933년 08월 23일 23:52:22',
            '1933년 09월 23일 21:01:08',
            '1933년 10월 24일 05:48:05',
            '1933년 11월 23일 02:53:27',
            '1933년 12월 22일 15:57:30',
        ]
    },
    {
        year : 1934,
        joonggi :[
            '1934년 01월 06일 09:16:30',
'1934년 02월 04일 21:03:39',
'1934년 03월 06일 15:26:22',
'1934년 04월 05일 20:43:41',
'1934년 05월 06일 14:30:46',
'1934년 06월 06일 19:01:24',
'1934년 07월 08일 05:24:28',
'1934년 08월 08일 15:03:41',
'1934년 09월 08일 17:36:10',
'1934년 10월 09일 08:45:01',
'1934년 11월 08일 11:26:44',
'1934년 12월 08일 03:56:34',
'1934년 01월 21일 02:36:54',
'1934년 02월 19일 17:01:39',
'1934년 03월 21일 16:27:54',
'1934년 04월 21일 04:00:08',
'1934년 05월 22일 03:34:54',
'1934년 06월 22일 11:47:55',
'1934년 07월 23일 22:42:11',
'1934년 08월 24일 05:32:03',
'1934년 09월 24일 02:45:10',
'1934년 10월 24일 11:36:17',
'1934년 11월 23일 08:44:23',
'1934년 12월 22일 21:49:25',
        ]
    },
    {
        year : 1935,
        joonggi :[
            '1935년 01월 06일 15:02:22',
            '1935년 02월 05일 02:48:44',
            '1935년 03월 06일 21:10:13',
            '1935년 04월 06일 02:26:22',
            '1935년 05월 06일 20:12:04',
            '1935년 06월 07일 00:41:38',
            '1935년 07월 08일 11:05:35',
            '1935년 08월 08일 20:47:52',
            '1935년 09월 08일 23:24:07',
            '1935년 10월 09일 14:35:41',
            '1935년 11월 08일 17:17:33',
            '1935년 12월 08일 09:44:53',
            '1935년 01월 21일 08:28:20',
            '1935년 02월 19일 22:51:58',
            '1935년 03월 21일 22:17:45',
            '1935년 04월 21일 09:50:06',
            '1935년 05월 22일 09:24:50',
            '1935년 06월 22일 17:37:54',
            '1935년 07월 24일 04:32:57',
            '1935년 08월 24일 11:23:58',
            '1935년 09월 24일 08:38:09',
            '1935년 10월 24일 17:29:11',
            '1935년 11월 23일 14:35:25',
            '1935년 12월 23일 03:37:07',
        ]
    },
    {
        year : 1936,
        joonggi :[
            '1936년 01월 06일 20:46:40',
            '1936년 02월 05일 08:29:18',
            '1936년 03월 06일 02:49:09',
            '1936년 04월 05일 08:06:46',
            '1936년 05월 06일 01:56:31',
            '1936년 06월 06일 06:30:42',
            '1936년 07월 07일 16:58:20',
            '1936년 08월 08일 02:43:12',
            '1936년 09월 08일 05:20:36',
            '1936년 10월 08일 20:32:27',
            '1936년 11월 07일 23:14:41',
            '1936년 12월 07일 15:42:17',
            '1936년 01월 21일 14:12:16',
            '1936년 02월 20일 04:33:03',
            '1936년 03월 21일 03:57:50',
            '1936년 04월 20일 15:31:06',
            '1936년 05월 21일 15:07:26',
            '1936년 06월 21일 23:21:37',
            '1936년 07월 23일 10:17:53',
            '1936년 08월 23일 17:10:31',
            '1936년 09월 23일 14:25:58',
            '1936년 10월 23일 23:18:05',
            '1936년 11월 22일 20:25:03',
            '1936년 12월 22일 09:26:41',
        ]
    },
    {
        year : 1937,
        joonggi :[
            '1937년 01월 06일 02:43:47',
            '1937년 02월 04일 14:25:36',
            '1937년 03월 06일 08:44:27',
            '1937년 04월 05일 14:01:25',
            '1937년 05월 06일 07:50:38',
            '1937년 06월 06일 12:22:52',
            '1937년 07월 07일 22:46:00',
            '1937년 08월 08일 08:25:24',
            '1937년 09월 08일 10:59:26',
            '1937년 10월 09일 02:10:57',
            '1937년 11월 08일 04:55:17',
            '1937년 12월 07일 21:26:20',
            '1937년 01월 20일 20:01:00',
            '1937년 02월 19일 10:20:43',
            '1937년 03월 21일 09:45:04',
            '1937년 04월 20일 21:19:11',
            '1937년 05월 21일 20:57:10',
            '1937년 06월 22일 05:11:59',
            '1937년 07월 23일 16:06:56',
            '1937년 08월 23일 22:57:51',
            '1937년 09월 23일 20:12:55',
            '1937년 10월 24일 05:06:31',
            '1937년 11월 23일 02:16:30',
            '1937년 12월 22일 15:21:40',
        ]
    },
    {
        year : 1938,
        joonggi :[
            '1938년 01월 06일 08:31:11',
            '1938년 02월 04일 20:15:01',
            '1938년 03월 06일 14:33:48',
            '1938년 04월 05일 19:48:41',
            '1938년 05월 06일 13:35:12',
            '1938년 06월 06일 18:06:41',
            '1938년 07월 08일 04:31:24',
            '1938년 08월 08일 14:12:43',
            '1938년 09월 08일 16:48:08',
            '1938년 10월 09일 08:01:25',
            '1938년 11월 08일 10:48:21',
            '1938년 12월 08일 03:22:01',
            '1938년 01월 21일 01:58:45',
            '1938년 02월 19일 16:19:36',
            '1938년 03월 21일 15:43:04',
            '1938년 04월 21일 03:14:44',
            '1938년 05월 22일 02:50:11',
            '1938년 06월 22일 11:03:35',
            '1938년 07월 23일 21:57:06',
            '1938년 08월 24일 04:45:49',
            '1938년 09월 24일 01:59:30',
            '1938년 10월 24일 10:53:47',
            '1938년 11월 23일 08:06:06',
            '1938년 12월 22일 21:13:24',
        ]
    },
    {
        year : 1939,
        joonggi :[
            '1939년 01월 06일 14:27:53',
            '1939년 02월 05일 02:10:28',
            '1939년 03월 06일 20:26:12',
            '1939년 04월 06일 01:37:25',
            '1939년 05월 06일 19:21:05',
            '1939년 06월 06일 23:51:41',
            '1939년 07월 08일 10:18:23',
            '1939년 08월 08일 20:03:30',
            '1939년 09월 08일 22:42:04',
            '1939년 10월 09일 13:56:39',
            '1939년 11월 08일 16:43:34',
            '1939년 12월 08일 09:17:04',
            '1939년 01월 21일 07:50:42',
            '1939년 02월 19일 22:09:16',
            '1939년 03월 21일 21:28:27',
            '1939년 04월 21일 08:55:08',
            '1939년 05월 22일 08:26:41',
            '1939년 06월 22일 16:39:26',
            '1939년 07월 24일 03:36:38',
            '1939년 08월 24일 10:31:10',
            '1939년 09월 24일 07:49:26',
            '1939년 10월 24일 16:45:51',
            '1939년 11월 23일 13:58:31',
            '1939년 12월 23일 03:05:59',
        ]
    },
    {
        year : 1940,
        joonggi :[
            '1940년 01월 06일 20:23:43',
            '1940년 02월 05일 08:07:35',
            '1940년 03월 06일 02:24:00',
            '1940년 04월 05일 07:34:36',
            '1940년 05월 06일 01:16:19',
            '1940년 06월 06일 05:44:06',
            '1940년 07월 07일 16:08:04',
            '1940년 08월 08일 01:51:32',
            '1940년 09월 08일 04:29:16',
            '1940년 10월 08일 19:42:26',
            '1940년 11월 07일 22:26:49',
            '1940년 12월 07일 14:57:54',
            '1940년 01월 21일 13:44:06',
            '1940년 02월 20일 04:03:45',
            '1940년 03월 21일 03:23:44',
            '1940년 04월 20일 14:50:55',
            '1940년 05월 21일 14:23:03',
            '1940년 06월 21일 22:36:25',
            '1940년 07월 23일 09:34:06',
            '1940년 08월 23일 16:28:33',
            '1940년 09월 23일 13:45:34',
            '1940년 10월 23일 22:39:21',
            '1940년 11월 22일 19:48:59',
            '1940년 12월 22일 08:54:44',
        ]
    }
    ,


    {
        year : 1941,
        joonggi :[
            '1941년 01월 06일 02:03:57',
            '1941년 02월 04일 13:49:47',
            '1941년 03월 06일 08:10:07',
            '1941년 04월 05일 13:24:59',
            '1941년 05월 06일 07:09:54',
            '1941년 06월 06일 11:39:15',
            '1941년 07월 07일 22:03:07',
            '1941년 08월 08일 07:45:55',
            '1941년 09월 08일 10:23:52',
            '1941년 10월 09일 01:38:16',
            '1941년 11월 08일 04:24:06',
            '1941년 12월 07일 20:56:01',
            '1941년 01월 20일 19:33:39',
            '1941년 02월 19일 09:56:24',
            '1941년 03월 21일 09:20:23',
            '1941년 04월 20일 20:50:28',
            '1941년 05월 21일 20:22:51',
            '1941년 06월 22일 04:33:18',
            '1941년 07월 23일 15:26:10',
            '1941년 08월 23일 22:16:54',
            '1941년 09월 23일 19:32:45',
            '1941년 10월 24일 04:27:12',
            '1941년 11월 23일 01:37:50',
            '1941년 12월 22일 14:44:09',
        ]
    },
    {
        year : 1942,
        joonggi :[
            '1942년 01월 06일 08:02:20',
            '1942년 02월 04일 19:48:35',
            '1942년 03월 06일 14:09:22',
            '1942년 04월 05일 19:23:52',
            '1942년 05월 06일 13:06:53',
            '1942년 06월 06일 17:32:35',
            '1942년 07월 08일 03:51:49',
            '1942년 08월 08일 13:30:20',
            '1942년 09월 08일 16:06:10',
            '1942년 10월 09일 07:21:44',
            '1942년 11월 08일 10:11:10',
            '1942년 12월 08일 02:46:50',
            '1942년 01월 21일 01:23:30',
            '1942년 02월 19일 15:46:49',
            '1942년 03월 21일 15:10:38',
            '1942년 04월 21일 02:39:09',
            '1942년 05월 22일 02:08:41',
            '1942년 06월 22일 10:16:15',
            '1942년 07월 23일 21:07:26',
            '1942년 08월 24일 03:58:14',
            '1942년 09월 24일 01:16:27',
            '1942년 10월 24일 10:15:11',
            '1942년 11월 23일 07:30:24',
            '1942년 12월 22일 20:39:33',
        ]
    },

    {
        year : 1943,
        joonggi :[
            '1943년 01월 06일 13:54:52',
            '1943년 02월 05일 01:40:07',
            '1943년 03월 06일 19:58:34',
            '1943년 04월 06일 01:11:14',
            '1943년 05월 06일 18:53:24',
            '1943년 06월 06일 23:19:00',
            '1943년 07월 08일 09:38:52',
            '1943년 08월 08일 19:18:32',
            '1943년 09월 08일 21:55:11',
            '1943년 10월 09일 13:10:31',
            '1943년 11월 08일 15:58:47',
            '1943년 12월 08일 08:32:53',
            '1943년 01월 21일 07:18:53',
            '1943년 02월 19일 21:40:15',
            '1943년 03월 21일 21:02:36',
            '1943년 04월 21일 08:31:30',
            '1943년 05월 22일 08:02:51',
            '1943년 06월 22일 16:12:19',
            '1943년 07월 24일 03:04:32',
            '1943년 08월 24일 09:55:01',
            '1943년 09월 24일 07:11:43',
            '1943년 10월 24일 16:08:17',
            '1943년 11월 23일 13:21:29',
            '1943년 12월 23일 02:29:06',
        ]
    },

    {
        year : 1944,
        joonggi :[
            '1944년 01월 06일 19:39:17',
            '1944년 02월 05일 07:22:57',
            '1944년 03월 06일 01:40:28',
            '1944년 04월 05일 06:54:00',
            '1944년 05월 06일 00:39:45',
            '1944년 06월 06일 05:10:55',
            '1944년 07월 07일 15:36:04',
            '1944년 08월 08일 01:18:52',
            '1944년 09월 08일 03:55:33',
            '1944년 10월 08일 19:08:44',
            '1944년 11월 07일 21:54:41',
            '1944년 12월 07일 14:27:41',
            '1944년 01월 21일 13:07:07',
            '1944년 02월 20일 03:27:08',
            '1944년 03월 21일 02:48:35',
            '1944년 04월 20일 14:17:48',
            '1944년 05월 21일 13:50:49',
            '1944년 06월 21일 22:02:17',
            '1944년 07월 23일 08:55:52',
            '1944년 08월 23일 15:46:28',
            '1944년 09월 23일 13:01:37',
            '1944년 10월 23일 21:55:59',
            '1944년 11월 22일 19:07:34',
            '1944년 12월 22일 08:14:48',
        ]
    },
    {
        year : 1945,
        joonggi :[
            '1945년 01월 06일 01:34:28',
            '1945년 02월 04일 13:19:24',
            '1945년 03월 06일 07:38:02',
            '1945년 04월 05일 12:51:49',
            '1945년 05월 06일 06:36:38',
            '1945년 06월 06일 11:05:26',
            '1945년 07월 07일 21:26:49',
            '1945년 08월 08일 07:05:06',
            '1945년 09월 08일 09:38:10',
            '1945년 10월 09일 00:49:11',
            '1945년 11월 08일 03:34:13',
            '1945년 12월 07일 20:07:42',
            '1945년 01월 20일 18:53:37',
            '1945년 02월 19일 09:14:53',
            '1945년 03월 21일 08:37:12',
            '1945년 04월 20일 20:06:53',
            '1945년 05월 21일 19:40:14',
            '1945년 06월 22일 03:52:03',
            '1945년 07월 23일 14:45:25',
            '1945년 08월 23일 21:35:18',
            '1945년 09월 23일 18:49:46',
            '1945년 10월 24일 03:43:36',
            '1945년 11월 23일 00:55:12',
            '1945년 12월 22일 14:03:34',
        ]
    },
    {
        year : 1946,
        joonggi :[
            '1946년 01월 06일 07:16:21',
            '1946년 02월 04일 19:03:55',
            '1946년 03월 06일 13:24:40',
            '1946년 04월 05일 18:38:33',
            '1946년 05월 06일 12:21:29',
            '1946년 06월 06일 16:48:44',
            '1946년 07월 08일 03:10:50',
            '1946년 08월 08일 12:51:37',
            '1946년 09월 08일 15:27:27',
            '1946년 10월 09일 06:40:49',
            '1946년 11월 08일 09:27:11',
            '1946년 12월 08일 02:00:14',
            '1946년 01월 21일 00:44:37',
            '1946년 02월 19일 15:08:32',
            '1946년 03월 21일 14:32:40',
            '1946년 04월 21일 02:02:10',
            '1946년 05월 22일 01:33:55',
            '1946년 06월 22일 09:44:19',
            '1946년 07월 23일 20:37:02',
            '1946년 08월 24일 03:26:22',
            '1946년 09월 24일 00:40:37',
            '1946년 10월 24일 09:34:42',
            '1946년 11월 23일 06:46:19',
            '1946년 12월 22일 19:53:20',
        ]
    },
    {
        year : 1947,
        joonggi :[
            '1947년 01월 06일 13:06:22',
            '1947년 02월 05일 00:50:23',
            '1947년 03월 06일 19:07:58',
            '1947년 04월 06일 00:20:10',
            '1947년 05월 06일 18:02:59',
            '1947년 06월 06일 22:31:14',
            '1947년 07월 08일 08:55:51',
            '1947년 08월 08일 18:40:53',
            '1947년 09월 08일 21:21:07',
            '1947년 10월 09일 12:37:20',
            '1947년 11월 08일 15:24:25',
            '1947년 12월 08일 07:56:14',
            '1947년 01월 21일 06:31:32',
            '1947년 02월 19일 20:51:55',
            '1947년 03월 21일 20:12:40',
            '1947년 04월 21일 07:39:24',
            '1947년 05월 22일 07:09:02',
            '1947년 06월 22일 15:18:49',
            '1947년 07월 24일 02:14:11',
            '1947년 08월 24일 09:08:59',
            '1947년 09월 24일 06:28:37',
            '1947년 10월 24일 15:25:51',
            '1947년 11월 23일 12:37:39',
            '1947년 12월 23일 01:42:46',
        ]
    },
    {
        year : 1948,
        joonggi :[
            '1948년 01월 06일 19:00:16',
            '1948년 02월 05일 06:42:02',
            '1948년 03월 06일 00:57:55',
            '1948년 04월 05일 06:09:22',
            '1948년 05월 05일 23:52:15',
            '1948년 06월 06일 04:20:23',
            '1948년 07월 07일 14:43:32',
            '1948년 08월 08일 00:26:21',
            '1948년 09월 08일 03:05:01',
            '1948년 10월 08일 18:20:17',
            '1948년 11월 07일 21:06:34',
            '1948년 12월 07일 13:37:40',
            '1948년 01월 21일 12:18:25',
            '1948년 02월 20일 02:36:41',
            '1948년 03월 21일 01:56:46',
            '1948년 04월 20일 13:24:52',
            '1948년 05월 21일 12:57:38',
            '1948년 06월 21일 21:10:35',
            '1948년 07월 23일 08:07:32',
            '1948년 08월 23일 15:02:33',
            '1948년 09월 23일 12:21:41',
            '1948년 10월 23일 21:18:00',
            '1948년 11월 22일 18:28:50',
            '1948년 12월 22일 07:33:16',
        ]
    },
    {
        year : 1949,
        joonggi :[
            '1949년 01월 06일 00:41:11',
            '1949년 02월 04일 12:22:53',
            '1949년 03월 06일 06:39:19',
            '1949년 04월 05일 11:51:59',
            '1949년 05월 06일 05:36:37',
            '1949년 06월 06일 10:06:51',
            '1949년 07월 07일 20:31:37',
            '1949년 08월 08일 06:14:59',
            '1949년 09월 08일 08:54:12',
            '1949년 10월 09일 00:11:04',
            '1949년 11월 08일 02:59:48',
            '1949년 12월 07일 19:33:27',
            '1949년 01월 20일 18:08:35',
            '1949년 02월 19일 08:27:05',
            '1949년 03월 21일 07:48:02',
            '1949년 04월 20일 19:17:16',
            '1949년 05월 21일 18:50:40',
            '1949년 06월 22일 03:02:45',
            '1949년 07월 23일 13:56:42',
            '1949년 08월 23일 20:48:14',
            '1949년 09월 23일 18:05:50',
            '1949년 10월 24일 03:02:59',
            '1949년 11월 23일 00:16:04',
            '1949년 12월 22일 13:22:53',
        ]
    },
    {
        year : 1950,
        joonggi :[
            '1950년 01월 06일 06:38:45',
            '1950년 02월 04일 18:20:48',
            '1950년 03월 06일 12:35:28',
            '1950년 04월 05일 17:44:28',
            '1950년 05월 06일 11:24:42',
            '1950년 06월 06일 15:51:02',
            '1950년 07월 08일 02:13:19',
            '1950년 08월 08일 11:55:14',
            '1950년 09월 08일 14:33:42',
            '1950년 10월 09일 05:51:41',
            '1950년 11월 08일 08:43:45',
            '1950년 12월 08일 01:21:43',
            '1950년 01월 20일 23:59:37',
            '1950년 02월 19일 14:17:32',
            '1950년 03월 21일 13:35:09',
            '1950년 04월 21일 00:59:08',
            '1950년 05월 22일 00:27:10',
            '1950년 06월 22일 08:36:02',
            '1950년 07월 23일 19:29:56',
            '1950년 08월 24일 02:23:12',
            '1950년 09월 23일 23:43:34',
            '1950년 10월 24일 08:44:45',
            '1950년 11월 23일 06:02:31',
            '1950년 12월 22일 19:13:20',
        ]
    } ,


    {
        year : 1951,
        joonggi :[
            '1951년 01월 06일 12:30:25',
            '1951년 02월 05일 00:13:29',
            '1951년 03월 06일 18:26:43',
            '1951년 04월 05일 23:32:40',
            '1951년 05월 06일 17:09:17',
            '1951년 06월 06일 21:32:35',
            '1951년 07월 08일 07:53:53',
            '1951년 08월 08일 17:37:28',
            '1951년 09월 08일 20:18:13',
            '1951년 10월 09일 11:36:25',
            '1951년 11월 08일 14:26:38',
            '1951년 12월 08일 07:02:21',
            '1951년 01월 21일 05:52:04',
            '1951년 02월 19일 20:09:40',
            '1951년 03월 21일 19:25:43',
            '1951년 04월 21일 06:48:04',
            '1951년 05월 22일 06:15:23',
            '1951년 06월 22일 14:24:49',
            '1951년 07월 24일 01:20:39',
            '1951년 08월 24일 08:16:07',
            '1951년 09월 24일 05:36:51',
            '1951년 10월 24일 14:36:02',
            '1951년 11월 23일 11:51:05',
            '1951년 12월 23일 01:00:04',
        ]
    },
    {
        year : 1952,
        joonggi :[
            '1952년 01월 06일 18:09:46',
            '1952년 02월 05일 05:52:55',
            '1952년 03월 06일 00:07:19',
            '1952년 04월 05일 05:15:04',
            '1952년 05월 05일 22:54:03',
            '1952년 06월 06일 03:20:21',
            '1952년 07월 07일 13:44:41',
            '1952년 08월 07일 23:31:00',
            '1952년 09월 08일 02:13:42',
            '1952년 10월 08일 17:32:25',
            '1952년 11월 07일 20:21:36',
            '1952년 12월 07일 12:55:35',
            '1952년 01월 21일 11:38:25',
            '1952년 02월 20일 01:56:43',
            '1952년 03월 21일 01:13:45',
            '1952년 04월 20일 12:36:39',
            '1952년 05월 21일 12:03:52',
            '1952년 06월 21일 20:12:33',
            '1952년 07월 23일 07:07:28',
            '1952년 08월 23일 14:02:49',
            '1952년 09월 23일 11:23:40',
            '1952년 10월 23일 20:22:13',
            '1952년 11월 22일 17:35:39',
            '1952년 12월 22일 06:43:09',
        ]
    },

    {
        year : 1953,
        joonggi :[
            '1953년 01월 06일 00:02:04',
            '1953년 02월 04일 11:45:55',
            '1953년 03월 06일 06:02:28',
            '1953년 04월 05일 11:12:39',
            '1953년 05월 06일 04:52:21',
            '1953년 06월 06일 09:16:07',
            '1953년 07월 07일 19:34:57',
            '1953년 08월 08일 05:14:38',
            '1953년 09월 08일 07:52:45',
            '1953년 10월 08일 23:10:26',
            '1953년 11월 08일 02:00:59',
            '1953년 12월 07일 18:37:01',
            '1953년 01월 20일 17:21:20',
            '1953년 02월 19일 07:41:06',
            '1953년 03월 21일 07:00:30',
            '1953년 04월 20일 18:25:23',
            '1953년 05월 21일 17:52:49',
            '1953년 06월 22일 01:59:56',
            '1953년 07월 23일 12:52:10',
            '1953년 08월 23일 19:45:11',
            '1953년 09월 23일 17:05:53',
            '1953년 10월 24일 02:06:15',
            '1953년 11월 22일 23:22:04',
            '1953년 12월 22일 12:31:27',
        ]
    },

    {
        year : 1954,
        joonggi :[
            '1954년 01월 06일 05:45:19',
            '1954년 02월 04일 17:30:44',
            '1954년 03월 06일 11:48:34',
            '1954년 04월 05일 16:59:11',
            '1954년 05월 06일 10:38:10',
            '1954년 06월 06일 15:00:51',
            '1954년 07월 08일 01:19:13',
            '1954년 08월 08일 10:59:06',
            '1954년 09월 08일 13:37:53',
            '1954년 10월 09일 04:57:19',
            '1954년 11월 08일 07:50:35',
            '1954년 12월 08일 00:28:32',
            '1954년 01월 20일 23:11:03',
            '1954년 02월 19일 13:32:19',
            '1954년 03월 21일 12:53:25',
            '1954년 04월 21일 00:19:33',
            '1954년 05월 21일 23:47:24',
            '1954년 06월 22일 07:54:02',
            '1954년 07월 23일 18:44:56',
            '1954년 08월 24일 01:35:54',
            '1954년 09월 23일 22:55:16',
            '1954년 10월 24일 07:56:21',
            '1954년 11월 23일 05:14:12',
            '1954년 12월 22일 18:24:21',
        ]
    },
    {
        year : 1955,
        joonggi :[
            '1955년 01월 06일 11:35:54',
            '1955년 02월 04일 23:17:38',
            '1955년 03월 06일 17:30:59',
            '1955년 04월 05일 22:38:46',
            '1955년 05월 06일 16:18:00',
            '1955년 06월 06일 20:43:28',
            '1955년 07월 08일 07:05:54',
            '1955년 08월 08일 16:50:05',
            '1955년 09월 08일 19:31:48',
            '1955년 10월 09일 10:52:10',
            '1955년 11월 08일 13:45:12',
            '1955년 12월 08일 06:22:49',
            '1955년 01월 21일 05:01:52',
            '1955년 02월 19일 19:18:47',
            '1955년 03월 21일 18:35:06',
            '1955년 04월 21일 05:57:52',
            '1955년 05월 22일 05:24:22',
            '1955년 06월 22일 13:31:23',
            '1955년 07월 24일 00:24:33',
            '1955년 08월 24일 07:18:56',
            '1955년 09월 24일 04:40:53',
            '1955년 10월 24일 13:43:03',
            '1955년 11월 23일 11:00:53',
            '1955년 12월 23일 00:10:54',
        ]
    },
    {
        year : 1956,
        joonggi :[
            '1956년 01월 06일 17:30:19',
            '1956년 02월 05일 05:11:57',
            '1956년 03월 05일 23:24:29',
            '1956년 04월 05일 04:31:11',
            '1956년 05월 05일 22:10:00',
            '1956년 06월 06일 02:35:50',
            '1956년 07월 07일 12:58:03',
            '1956년 08월 07일 22:40:15',
            '1956년 09월 08일 01:18:58',
            '1956년 10월 08일 16:35:55',
            '1956년 11월 07일 19:25:56',
            '1956년 12월 07일 12:02:08',
            '1956년 01월 21일 10:48:19',
            '1956년 02월 20일 01:04:40',
            '1956년 03월 21일 00:20:18',
            '1956년 04월 20일 11:43:27',
            '1956년 05월 21일 11:12:35',
            '1956년 06월 21일 19:23:44',
            '1956년 07월 23일 06:19:55',
            '1956년 08월 23일 13:14:48',
            '1956년 09월 23일 10:35:03',
            '1956년 10월 23일 19:34:20',
            '1956년 11월 22일 16:49:52',
            '1956년 12월 22일 05:59:29',
        ]
    },
    {
        year : 1957,
        joonggi :[
            '1957년 01월 05일 23:10:27',
            '1957년 02월 04일 10:54:39',
            '1957년 03월 06일 05:10:08',
            '1957년 04월 05일 10:18:50',
            '1957년 05월 06일 03:58:24',
            '1957년 06월 06일 08:24:46',
            '1957년 07월 07일 18:48:12',
            '1957년 08월 08일 04:32:05',
            '1957년 09월 08일 07:12:13',
            '1957년 10월 08일 22:30:00',
            '1957년 11월 08일 01:20:04',
            '1957년 12월 07일 17:55:59',
            '1957년 01월 20일 16:38:37',
            '1957년 02월 19일 06:57:59',
            '1957년 03월 21일 06:16:29',
            '1957년 04월 20일 17:41:13',
            '1957년 05월 21일 17:10:25',
            '1957년 06월 22일 01:20:31',
            '1957년 07월 23일 12:14:51',
            '1957년 08월 23일 19:07:34',
            '1957년 09월 23일 16:26:04',
            '1957년 10월 24일 01:24:13',
            '1957년 11월 22일 22:39:03',
            '1957년 12월 22일 11:48:37',
        ]
    },
    {
        year : 1958,
        joonggi :[
            '1958년 01월 06일 05:04:21',
            '1958년 02월 04일 16:49:12',
            '1958년 03월 06일 11:04:53',
            '1958년 04월 05일 16:12:22',
            '1958년 05월 06일 09:49:12',
            '1958년 06월 06일 14:12:13',
            '1958년 07월 08일 00:33:26',
            '1958년 08월 08일 10:17:12',
            '1958년 09월 08일 12:58:51',
            '1958년 10월 09일 04:19:10',
            '1958년 11월 08일 07:11:56',
            '1958년 12월 07일 23:49:38',
            '1958년 01월 20일 22:28:29',
            '1958년 02월 19일 12:48:27',
            '1958년 03월 21일 12:05:48',
            '1958년 04월 20일 23:26:59',
            '1958년 05월 21일 22:51:02',
            '1958년 06월 22일 06:56:54',
            '1958년 07월 23일 17:50:28',
            '1958년 08월 24일 00:45:54',
            '1958년 09월 23일 22:08:50',
            '1958년 10월 24일 07:11:20',
            '1958년 11월 23일 04:29:08',
            '1958년 12월 22일 17:39:42',
        ]
    },
    {
        year : 1959,
        joonggi :[
            '1959년 01월 06일 10:58:20',
            '1959년 02월 04일 22:42:12',
            '1959년 03월 06일 16:56:38',
            '1959년 04월 05일 22:03:05',
            '1959년 05월 06일 15:38:45',
            '1959년 06월 06일 20:00:05',
            '1959년 07월 08일 06:19:53',
            '1959년 08월 08일 16:04:06',
            '1959년 09월 08일 18:47:55',
            '1959년 10월 09일 10:09:49',
            '1959년 11월 08일 13:02:06',
            '1959년 12월 08일 05:37:19',
            '1959년 01월 21일 04:18:51',
            '1959년 02월 19일 18:37:34',
            '1959년 03월 21일 17:54:31',
            '1959년 04월 21일 05:16:29',
            '1959년 05월 22일 04:42:06',
            '1959년 06월 22일 12:49:46',
            '1959년 07월 23일 23:45:26',
            '1959년 08월 24일 06:43:30',
            '1959년 09월 24일 04:08:26',
            '1959년 10월 24일 13:11:00',
            '1959년 11월 23일 10:26:56',
            '1959년 12월 22일 23:34:19',
        ]
    },
    {
        year : 1960,
        joonggi :[
            '1960년 01월 06일 16:42:28',
            '1960년 02월 05일 04:23:09',
            '1960년 03월 05일 22:36:07',
            '1960년 04월 05일 03:43:34',
            '1960년 05월 05일 21:22:35',
            '1960년 06월 06일 01:48:35',
            '1960년 07월 07일 12:12:40',
            '1960년 08월 07일 21:59:46',
            '1960년 09월 08일 00:45:23',
            '1960년 10월 08일 16:08:41',
            '1960년 11월 07일 19:02:03',
            '1960년 12월 07일 11:37:46',
            '1960년 01월 21일 10:10:01',
            '1960년 02월 20일 00:26:17',
            '1960년 03월 20일 23:42:39',
            '1960년 04월 20일 11:05:53',
            '1960년 05월 21일 10:33:31',
            '1960년 06월 21일 18:42:17',
            '1960년 07월 23일 05:37:26',
            '1960년 08월 23일 12:34:23',
            '1960년 09월 23일 09:58:51',
            '1960년 10월 23일 19:01:51',
            '1960년 11월 22일 16:18:23',
            '1960년 12월 22일 05:25:56',
        ]
    } ,
    

    {
        year : 1961,
        joonggi :[
            '1961년 01월 05일 22:42:37',
            '1961년 02월 04일 10:22:26',
            '1961년 03월 06일 04:34:39',
            '1961년 04월 05일 09:42:07',
            '1961년 05월 06일 03:21:17',
            '1961년 06월 06일 07:46:02',
            '1961년 07월 07일 18:06:37',
            '1961년 08월 08일 03:48:21',
            '1961년 09월 08일 06:29:13',
            '1961년 10월 08일 21:50:57',
            '1961년 11월 08일 00:46:12',
            '1961년 12월 07일 17:25:55',
            '1961년 01월 20일 16:01:07',
            '1961년 02월 19일 06:16:28',
            '1961년 03월 21일 05:32:06',
            '1961년 04월 20일 16:55:04',
            '1961년 05월 21일 16:22:15',
            '1961년 06월 22일 00:30:06',
            '1961년 07월 23일 11:23:36',
            '1961년 08월 23일 18:18:32',
            '1961년 09월 23일 15:42:26',
            '1961년 10월 24일 00:47:22',
            '1961년 11월 22일 22:07:41',
            '1961년 12월 22일 11:19:28',
        ]
    },
    {
        year : 1962,
        joonggi :[
            '1962년 01월 06일 04:34:57',
            '1962년 02월 04일 16:17:21',
            '1962년 03월 06일 10:29:31',
            '1962년 04월 05일 15:34:15',
            '1962년 05월 06일 09:09:29',
            '1962년 06월 06일 13:31:16',
            '1962년 07월 07일 23:51:06',
            '1962년 08월 08일 09:33:42',
            '1962년 09월 08일 12:15:22',
            '1962년 10월 09일 03:37:55',
            '1962년 11월 08일 06:34:55',
            '1962년 12월 07일 23:16:40',
            '1962년 01월 20일 21:57:54',
            '1962년 02월 19일 12:14:34',
            '1962년 03월 21일 11:29:32',
            '1962년 04월 20일 22:50:40',
            '1962년 05월 21일 22:16:30',
            '1962년 06월 22일 06:24:06',
            '1962년 07월 23일 17:17:57',
            '1962년 08월 24일 00:12:28',
            '1962년 09월 23일 21:35:12',
            '1962년 10월 24일 06:40:00',
            '1962년 11월 23일 04:01:51',
            '1962년 12월 22일 17:15:16',
        ]
    },

    {
        year : 1963,
        joonggi :[
            '1963년 01월 06일 10:26:27',
            '1963년 02월 04일 22:07:45',
            '1963년 03월 06일 16:17:10',
            '1963년 04월 05일 21:18:40',
            '1963년 05월 06일 14:51:58',
            '1963년 06월 06일 19:14:26',
            '1963년 07월 08일 05:37:36',
            '1963년 08월 08일 15:25:24',
            '1963년 09월 08일 18:11:51',
            '1963년 10월 09일 09:36:14',
            '1963년 11월 08일 12:32:19',
            '1963년 12월 08일 05:12:37',
            '1963년 01월 21일 03:53:52',
            '1963년 02월 19일 18:08:35',
            '1963년 03월 21일 17:19:40',
            '1963년 04월 21일 04:36:08',
            '1963년 05월 22일 03:58:08',
            '1963년 06월 22일 12:04:01',
            '1963년 07월 23일 22:59:12',
            '1963년 08월 24일 05:57:32',
            '1963년 09월 24일 03:23:29',
            '1963년 10월 24일 12:28:49',
            '1963년 11월 23일 09:49:21',
            '1963년 12월 22일 23:01:53',
        ]
    },

    {
        year : 1964,
        joonggi :[
            '1964년 01월 06일 16:22:20',
            '1964년 02월 05일 04:04:56',
            '1964년 03월 05일 22:16:01',
            '1964년 04월 05일 03:18:21',
            '1964년 05월 05일 20:51:02',
            '1964년 06월 06일 01:11:44',
            '1964년 07월 07일 11:32:09',
            '1964년 08월 07일 21:16:10',
            '1964년 09월 07일 23:59:28',
            '1964년 10월 08일 15:21:32',
            '1964년 11월 07일 18:15:08',
            '1964년 12월 07일 10:53:05',
            '1964년 01월 21일 09:41:03',
            '1964년 02월 19일 23:57:18',
            '1964년 03월 20일 23:09:51',
            '1964년 04월 20일 10:27:08',
            '1964년 05월 21일 09:49:46',
            '1964년 06월 21일 17:56:48',
            '1964년 07월 23일 04:52:43',
            '1964년 08월 23일 11:51:02',
            '1964년 09월 23일 09:16:39',
            '1964년 10월 23일 18:20:40',
            '1964년 11월 22일 15:38:54',
            '1964년 12월 22일 04:49:32',
        ]
    },
    {
        year : 1965,
        joonggi :[
            '1965년 01월 05일 22:01:58',
            '1965년 02월 04일 09:46:07',
            '1965년 03월 06일 04:00:38',
            '1965년 04월 05일 09:06:42',
            '1965년 05월 06일 02:41:32',
            '1965년 06월 06일 07:02:06',
            '1965년 07월 07일 17:21:22',
            '1965년 08월 08일 03:04:36',
            '1965년 09월 08일 05:47:51',
            '1965년 10월 08일 21:11:08',
            '1965년 11월 08일 00:06:33',
            '1965년 12월 07일 16:45:34',
            '1965년 01월 20일 15:28:52',
            '1965년 02월 19일 05:47:49',
            '1965년 03월 21일 05:04:45',
            '1965년 04월 20일 16:26:03',
            '1965년 05월 21일 15:50:13',
            '1965년 06월 21일 23:55:41',
            '1965년 07월 23일 10:48:11',
            '1965년 08월 23일 17:42:43',
            '1965년 09월 23일 15:06:00',
            '1965년 10월 24일 00:09:57',
            '1965년 11월 22일 21:29:10',
            '1965년 12월 22일 10:40:26',
        ]
    },
    {
        year : 1966,
        joonggi :[
            '1966년 01월 06일 03:54:22',
            '1966년 02월 04일 15:37:50',
            '1966년 03월 06일 09:51:22',
            '1966년 04월 05일 14:56:29',
            '1966년 05월 06일 08:30:27',
            '1966년 06월 06일 12:49:38',
            '1966년 07월 07일 23:07:00',
            '1966년 08월 08일 08:48:58',
            '1966년 09월 08일 11:32:04',
            '1966년 10월 09일 02:56:46',
            '1966년 11월 08일 05:55:17',
            '1966년 12월 07일 22:37:47',
            '1966년 01월 20일 21:19:40',
            '1966년 02월 19일 11:37:49',
            '1966년 03월 21일 10:52:54',
            '1966년 04월 20일 22:11:30',
            '1966년 05월 21일 21:32:02',
            '1966년 06월 22일 05:33:22',
            '1966년 07월 23일 16:23:12',
            '1966년 08월 23일 23:17:42',
            '1966년 09월 23일 20:43:08',
            '1966년 10월 24일 05:50:46',
            '1966년 11월 23일 03:14:06',
            '1966년 12월 22일 16:28:10',
        ]
    },
    {
        year : 1967,
        joonggi :[
            '1967년 01월 06일 09:48:20',
            '1967년 02월 04일 21:30:50',
            '1967년 03월 06일 15:41:54',
            '1967년 04월 05일 20:44:41',
            '1967년 05월 06일 14:17:25',
            '1967년 06월 06일 18:36:19',
            '1967년 07월 08일 04:53:20',
            '1967년 08월 08일 14:34:52',
            '1967년 09월 08일 17:17:43',
            '1967년 10월 09일 08:41:10',
            '1967년 11월 08일 11:37:23',
            '1967년 12월 08일 04:17:29',
            '1967년 01월 21일 03:07:31',
            '1967년 02월 19일 17:23:41',
            '1967년 03월 21일 16:36:48',
            '1967년 04월 21일 03:55:07',
            '1967년 05월 22일 03:17:52',
            '1967년 06월 22일 11:22:50',
            '1967년 07월 23일 22:15:51',
            '1967년 08월 24일 05:12:25',
            '1967년 09월 24일 02:38:02',
            '1967년 10월 24일 11:43:46',
            '1967년 11월 23일 09:04:28',
            '1967년 12월 22일 22:16:19',
        ]
    },
    {
        year : 1968,
        joonggi :[
            '1968년 01월 06일 15:26:11',
            '1968년 02월 05일 03:07:24',
            '1968년 03월 05일 21:17:46',
            '1968년 04월 05일 02:20:55',
            '1968년 05월 05일 19:55:48',
            '1968년 06월 06일 00:19:06',
            '1968년 07월 07일 10:41:38',
            '1968년 08월 07일 20:27:12',
            '1968년 09월 07일 23:11:25',
            '1968년 10월 08일 14:34:24',
            '1968년 11월 07일 17:29:18',
            '1968년 12월 07일 10:08:16',
            '1968년 01월 21일 08:54:06',
            '1968년 02월 19일 23:09:13',
            '1968년 03월 20일 22:22:00',
            '1968년 04월 20일 09:41:07',
            '1968년 05월 21일 09:05:51',
            '1968년 06월 21일 17:13:16',
            '1968년 07월 23일 04:07:24',
            '1968년 08월 23일 11:02:52',
            '1968년 09월 23일 08:26:11',
            '1968년 10월 23일 17:29:35',
            '1968년 11월 22일 14:48:32',
            '1968년 12월 22일 03:59:46',
        ]
    },
    {
        year : 1969,
        joonggi :[
            '1969년 01월 05일 21:16:49',
            '1969년 02월 04일 08:58:53',
            '1969년 03월 06일 03:10:34',
            '1969년 04월 05일 08:14:51',
            '1969년 05월 06일 01:49:47',
            '1969년 06월 06일 06:11:30',
            '1969년 07월 07일 16:31:32',
            '1969년 08월 08일 02:14:07',
            '1969년 09월 08일 04:55:25',
            '1969년 10월 08일 20:16:40',
            '1969년 11월 07일 23:11:21',
            '1969년 12월 07일 15:51:19',
            '1969년 01월 20일 14:38:11',
            '1969년 02월 19일 04:54:30',
            '1969년 03월 21일 04:08:06',
            '1969년 04월 20일 15:26:53',
            '1969년 05월 21일 14:49:44',
            '1969년 06월 21일 22:55:03',
            '1969년 07월 23일 09:48:10',
            '1969년 08월 23일 16:43:22',
            '1969년 09월 23일 14:06:54',
            '1969년 10월 23일 23:11:03',
            '1969년 11월 22일 20:31:05',
            '1969년 12월 22일 09:43:42',
        ]
    },
    {
        year : 1970,
        joonggi :[
            '1970년 01월 06일 03:01:42',
            '1970년 02월 04일 14:45:45',
            '1970년 03월 06일 08:58:29',
            '1970년 04월 05일 14:01:45',
            '1970년 05월 06일 07:33:48',
            '1970년 06월 06일 11:52:15',
            '1970년 07월 07일 22:10:33',
            '1970년 08월 08일 07:54:07',
            '1970년 09월 08일 10:37:55',
            '1970년 10월 09일 02:01:34',
            '1970년 11월 08일 04:57:44',
            '1970년 12월 07일 21:37:20',
            '1970년 01월 20일 20:23:49',
            '1970년 02월 19일 10:41:47',
            '1970년 03월 21일 09:56:19',
            '1970년 04월 20일 21:14:55',
            '1970년 05월 21일 20:37:19',
            '1970년 06월 22일 04:42:39',
            '1970년 07월 23일 15:36:53',
            '1970년 08월 23일 22:33:53',
            '1970년 09월 23일 19:58:58',
            '1970년 10월 24일 05:04:13',
            '1970년 11월 23일 02:24:33',
            '1970년 12월 22일 15:35:41',
        ]
    } ,

    {
        year : 1971,
        joonggi :[
            '1971년 01월 06일 08:45:05',
            '1971년 02월 04일 20:25:25',
            '1971년 03월 06일 14:34:44',
            '1971년 04월 05일 19:36:00',
            '1971년 05월 06일 13:08:08',
            '1971년 06월 06일 17:28:52',
            '1971년 07월 08일 03:51:09',
            '1971년 08월 08일 13:40:13',
            '1971년 09월 08일 16:30:12',
            '1971년 10월 09일 07:58:33',
            '1971년 11월 08일 10:56:37',
            '1971년 12월 08일 03:35:44',
            '1971년 01월 21일 02:12:40',
            '1971년 02월 19일 16:26:56',
            '1971년 03월 21일 15:38:08',
            '1971년 04월 21일 02:54:13',
            '1971년 05월 22일 02:15:00',
            '1971년 06월 22일 10:19:36',
            '1971년 07월 23일 21:14:45',
            '1971년 08월 24일 04:15:16',
            '1971년 09월 24일 01:44:54',
            '1971년 10월 24일 10:53:10',
            '1971년 11월 23일 08:13:59',
            '1971년 12월 22일 21:23:55',
        ]
    },
    {
        year : 1972,
        joonggi :[
            '1972년 01월 06일 14:41:51',
            '1972년 02월 05일 02:20:13',
            '1972년 03월 05일 20:28:05',
            '1972년 04월 05일 01:28:51',
            '1972년 05월 05일 19:01:11',
            '1972년 06월 05일 23:21:59',
            '1972년 07월 07일 09:42:55',
            '1972년 08월 07일 19:28:31',
            '1972년 09월 07일 22:15:07',
            '1972년 10월 08일 13:41:47',
            '1972년 11월 07일 16:39:25',
            '1972년 12월 07일 09:18:45',
            '1972년 01월 21일 07:59:00',
            '1972년 02월 19일 22:11:23',
            '1972년 03월 20일 21:21:25',
            '1972년 04월 20일 08:37:28',
            '1972년 05월 21일 07:59:29',
            '1972년 06월 21일 16:06:11',
            '1972년 07월 23일 03:02:32',
            '1972년 08월 23일 10:03:01',
            '1972년 09월 23일 07:32:45',
            '1972년 10월 23일 16:41:29',
            '1972년 11월 22일 14:02:43',
            '1972년 12월 22일 03:12:55',
        ]
    },

    {
        year : 1973,
        joonggi :[
            '1973년 01월 05일 20:25:19',
            '1973년 02월 04일 08:04:11',
            '1973년 03월 06일 02:12:35',
            '1973년 04월 05일 07:13:52',
            '1973년 05월 06일 00:46:23',
            '1973년 06월 06일 05:06:50',
            '1973년 07월 07일 15:27:22',
            '1973년 08월 08일 01:12:48',
            '1973년 09월 08일 03:59:24',
            '1973년 10월 08일 19:27:15',
            '1973년 11월 07일 22:27:39',
            '1973년 12월 07일 15:10:25',
            '1973년 01월 20일 13:48:12',
            '1973년 02월 19일 04:01:08',
            '1973년 03월 21일 03:12:26',
            '1973년 04월 20일 14:30:21',
            '1973년 05월 21일 13:53:50',
            '1973년 06월 21일 22:00:35',
            '1973년 07월 23일 08:55:33',
            '1973년 08월 23일 15:53:28',
            '1973년 09월 23일 13:21:07',
            '1973년 10월 23일 22:30:10',
            '1973년 11월 22일 19:54:02',
            '1973년 12월 22일 09:07:43',
        ]
    },

    {
        year : 1974,
        joonggi :[
            '1974년 01월 06일 02:19:56',
            '1974년 02월 04일 14:00:06',
            '1974년 03월 06일 08:07:06',
            '1974년 04월 05일 13:05:00',
            '1974년 05월 06일 06:33:52',
            '1974년 06월 06일 10:51:40',
            '1974년 07월 07일 21:11:06',
            '1974년 08월 08일 06:57:11',
            '1974년 09월 08일 09:45:04',
            '1974년 10월 09일 01:14:39',
            '1974년 11월 08일 04:17:58',
            '1974년 12월 07일 21:04:38',
            '1974년 01월 20일 19:45:40',
            '1974년 02월 19일 09:58:41',
            '1974년 03월 21일 09:06:37',
            '1974년 04월 20일 20:18:50',
            '1974년 05월 21일 19:36:03',
            '1974년 06월 22일 03:37:37',
            '1974년 07월 23일 14:30:12',
            '1974년 08월 23일 21:28:40',
            '1974년 09월 23일 18:58:25',
            '1974년 10월 24일 04:10:36',
            '1974년 11월 23일 01:38:27',
            '1974년 12월 22일 14:55:57',
        ]
    },
    {
        year : 1975,
        joonggi :[
            '1975년 01월 06일 08:17:30',
            '1975년 02월 04일 19:59:11',
            '1975년 03월 06일 14:05:47',
            '1975년 04월 05일 19:01:30',
            '1975년 05월 06일 12:27:11',
            '1975년 06월 06일 16:42:01',
            '1975년 07월 08일 02:59:24',
            '1975년 08월 08일 12:44:53',
            '1975년 09월 08일 15:33:18',
            '1975년 10월 09일 07:02:04',
            '1975년 11월 08일 10:02:37',
            '1975년 12월 08일 02:46:11',
            '1975년 01월 21일 01:36:15',
            '1975년 02월 19일 15:49:43',
            '1975년 03월 21일 14:56:40',
            '1975년 04월 21일 02:07:13',
            '1975년 05월 22일 01:23:40',
            '1975년 06월 22일 09:26:26',
            '1975년 07월 23일 20:21:45',
            '1975년 08월 24일 03:23:37',
            '1975년 09월 24일 00:55:11',
            '1975년 10월 24일 10:06:01',
            '1975년 11월 23일 07:30:43',
            '1975년 12월 22일 20:45:34',
        ]
    },
    {
        year : 1976,
        joonggi :[
            '1976년 01월 06일 13:57:22',
            '1976년 02월 05일 01:39:28',
            '1976년 03월 05일 19:48:07',
            '1976년 04월 05일 00:46:28',
            '1976년 05월 05일 18:14:26',
            '1976년 06월 05일 22:31:14',
            '1976년 07월 07일 08:50:51',
            '1976년 08월 07일 18:38:22',
            '1976년 09월 07일 21:28:12',
            '1976년 10월 08일 12:58:03',
            '1976년 11월 07일 15:58:35',
            '1976년 12월 07일 08:40:57',
            '1976년 01월 21일 07:25:06',
            '1976년 02월 19일 21:39:55',
            '1976년 03월 20일 20:49:35',
            '1976년 04월 20일 08:02:56',
            '1976년 05월 21일 07:21:06',
            '1976년 06월 21일 15:24:12',
            '1976년 07월 23일 02:18:26',
            '1976년 08월 23일 09:18:15',
            '1976년 09월 23일 06:48:11',
            '1976년 10월 23일 15:58:02',
            '1976년 11월 22일 13:21:30',
            '1976년 12월 22일 02:35:09',
        ]
    },
    {
        year : 1977,
        joonggi :[
            '1977년 01월 05일 19:51:03',
            '1977년 02월 04일 07:33:24',
            '1977년 03월 06일 01:44:08',
            '1977년 04월 05일 06:45:44',
            '1977년 05월 06일 00:16:00',
            '1977년 06월 06일 04:32:03',
            '1977년 07월 07일 14:47:53',
            '1977년 08월 08일 00:30:14',
            '1977년 09월 08일 03:15:42',
            '1977년 10월 08일 18:43:57',
            '1977년 11월 07일 21:45:50',
            '1977년 12월 07일 14:30:50',
            '1977년 01월 20일 13:14:26',
            '1977년 02월 19일 03:30:26',
            '1977년 03월 21일 02:42:16',
            '1977년 04월 20일 13:57:14',
            '1977년 05월 21일 13:14:21',
            '1977년 06월 21일 21:13:45',
            '1977년 07월 23일 08:03:38',
            '1977년 08월 23일 15:00:14',
            '1977년 09월 23일 12:29:15',
            '1977년 10월 23일 21:40:40',
            '1977년 11월 22일 19:07:01',
            '1977년 12월 22일 08:23:09',
        ]
    },
    {
        year : 1978,
        joonggi :[
            '1978년 01월 06일 01:43:13',
            '1978년 02월 04일 13:26:58',
            '1978년 03월 06일 07:38:12',
            '1978년 04월 05일 12:39:21',
            '1978년 05월 06일 06:08:33',
            '1978년 06월 06일 10:23:06',
            '1978년 07월 07일 20:36:57',
            '1978년 08월 08일 06:17:40',
            '1978년 09월 08일 09:02:24',
            '1978년 10월 09일 00:30:55',
            '1978년 11월 08일 03:34:03',
            '1978년 12월 07일 20:20:03',
            '1978년 01월 20일 19:03:59',
            '1978년 02월 19일 09:20:56',
            '1978년 03월 21일 08:33:33',
            '1978년 04월 20일 19:49:32',
            '1978년 05월 21일 19:08:26',
            '1978년 06월 22일 03:09:33',
            '1978년 07월 23일 14:00:15',
            '1978년 08월 23일 20:56:47',
            '1978년 09월 23일 18:25:24',
            '1978년 10월 24일 03:37:10',
            '1978년 11월 23일 01:04:37',
            '1978년 12월 22일 14:20:58',
        ]
    },
    {
        year : 1979,
        joonggi :[
            '1979년 01월 06일 07:31:33',
            '1979년 02월 04일 19:12:18',
            '1979년 03월 06일 13:19:37',
            '1979년 04월 05일 18:17:57',
            '1979년 05월 06일 11:47:11',
            '1979년 06월 06일 16:05:12',
            '1979년 07월 08일 02:24:37',
            '1979년 08월 08일 12:10:54',
            '1979년 09월 08일 14:59:46',
            '1979년 10월 09일 06:30:03',
            '1979년 11월 08일 09:32:47',
            '1979년 12월 08일 02:17:49',
            '1979년 01월 21일 00:59:55',
            '1979년 02월 19일 15:13:13',
            '1979년 03월 21일 14:21:56',
            '1979년 04월 21일 01:35:22',
            '1979년 05월 22일 00:53:51',
            '1979년 06월 22일 08:56:10',
            '1979년 07월 23일 19:48:32',
            '1979년 08월 24일 02:46:44',
            '1979년 09월 24일 00:16:24',
            '1979년 10월 24일 09:27:50',
            '1979년 11월 23일 06:54:06',
            '1979년 12월 22일 20:09:46',
        ]
    },
    {
        year : 1980,
        joonggi :[
            '1980년 01월 06일 13:28:53',
            '1980년 02월 05일 01:09:28',
            '1980년 03월 05일 19:16:30',
            '1980년 04월 05일 00:14:42',
            '1980년 05월 05일 17:44:29',
            '1980년 06월 05일 22:03:45',
            '1980년 07월 07일 08:23:57',
            '1980년 08월 07일 18:08:31',
            '1980년 09월 07일 20:53:28',
            '1980년 10월 08일 12:19:16',
            '1980년 11월 07일 15:18:15',
            '1980년 12월 07일 08:01:16',
            '1980년 01월 21일 06:48:37',
            '1980년 02월 19일 21:01:39',
            '1980년 03월 20일 20:09:41',
            '1980년 04월 20일 07:22:42',
            '1980년 05월 21일 06:42:03',
            '1980년 06월 21일 14:47:01',
            '1980년 07월 23일 01:41:59',
            '1980년 08월 23일 08:40:38',
            '1980년 09월 23일 06:08:41',
            '1980년 10월 23일 15:17:31',
            '1980년 11월 22일 12:41:24',
            '1980년 12월 22일 01:56:05',
        ]
    } ,

    {
        year : 1981,
        joonggi :[
            '1981년 01월 05일 19:12:39',
            '1981년 02월 04일 06:55:25',
            '1981년 03월 06일 01:05:08',
            '1981년 04월 05일 06:05:03',
            '1981년 05월 05일 23:34:48',
            '1981년 06월 06일 03:52:40',
            '1981년 07월 07일 14:11:53',
            '1981년 08월 07일 23:57:10',
            '1981년 09월 08일 02:43:14',
            '1981년 10월 08일 18:09:34',
            '1981년 11월 07일 21:08:31',
            '1981년 12월 07일 13:51:16',
            '1981년 01월 20일 12:35:59',
            '1981년 02월 19일 02:51:39',
            '1981년 03월 21일 02:02:51',
            '1981년 04월 20일 13:18:33',
            '1981년 05월 21일 12:39:26',
            '1981년 06월 21일 20:44:41',
            '1981년 07월 23일 07:39:43',
            '1981년 08월 23일 14:38:10',
            '1981년 09월 23일 12:05:13',
            '1981년 10월 23일 21:12:52',
            '1981년 11월 22일 18:35:59',
            '1981년 12월 22일 07:50:34',
        ]
    },
    {
        year : 1982,
        joonggi :[
            '1982년 01월 06일 01:02:35',
            '1982년 02월 04일 12:45:29',
            '1982년 03월 06일 06:54:36',
            '1982년 04월 05일 11:52:42',
            '1982년 05월 06일 05:20:01',
            '1982년 06월 06일 09:35:55',
            '1982년 07월 07일 19:54:35',
            '1982년 08월 08일 05:41:45',
            '1982년 09월 08일 08:31:43',
            '1982년 10월 09일 00:02:09',
            '1982년 11월 08일 03:04:07',
            '1982년 12월 07일 19:48:06',
            '1982년 01월 20일 18:30:54',
            '1982년 02월 19일 08:46:32',
            '1982년 03월 21일 07:55:51',
            '1982년 04월 20일 19:07:28',
            '1982년 05월 21일 18:22:55',
            '1982년 06월 22일 02:23:00',
            '1982년 07월 23일 13:15:24',
            '1982년 08월 23일 20:15:14',
            '1982년 09월 23일 17:46:12',
            '1982년 10월 24일 02:57:47',
            '1982년 11월 23일 00:23:19',
            '1982년 12월 22일 13:38:10',
        ]
    },

    {
        year : 1983,
        joonggi :[
            '1983년 01월 06일 06:58:42',
            '1983년 02월 04일 18:39:42',
            '1983년 03월 06일 12:47:13',
            '1983년 04월 05일 17:44:22',
            '1983년 05월 06일 11:10:51',
            '1983년 06월 06일 15:25:42',
            '1983년 07월 08일 01:43:14',
            '1983년 08월 08일 11:29:38',
            '1983년 09월 08일 14:20:03',
            '1983년 10월 09일 05:51:05',
            '1983년 11월 08일 08:52:13',
            '1983년 12월 08일 01:33:41',
            '1983년 01월 21일 00:16:56',
            '1983년 02월 19일 14:30:35',
            '1983년 03월 21일 13:38:45',
            '1983년 04월 21일 00:50:10',
            '1983년 05월 22일 00:06:26',
            '1983년 06월 22일 08:08:41',
            '1983년 07월 23일 19:04:07',
            '1983년 08월 24일 02:07:29',
            '1983년 09월 23일 23:41:37',
            '1983년 10월 24일 08:54:17',
            '1983년 11월 23일 06:18:21',
            '1983년 12월 22일 19:29:56',
        ]
    },

    {
        year : 1984,
        joonggi :[
            '1984년 01월 06일 12:40:52',
'1984년 02월 05일 00:18:45',
'1984년 03월 05일 18:24:40',
'1984년 04월 04일 23:22:20',
'1984년 05월 05일 16:50:58',
'1984년 06월 05일 21:08:37',
'1984년 07월 07일 07:29:07',
'1984년 08월 07일 17:17:53',
'1984년 09월 07일 20:09:49',
'1984년 10월 08일 11:42:34',
'1984년 11월 07일 14:45:32',
'1984년 12월 07일 07:28:04',
'1984년 01월 21일 06:05:02',
'1984년 02월 19일 20:16:13',
'1984년 03월 20일 19:24:20',
'1984년 04월 20일 06:38:06',
'1984년 05월 21일 05:57:36',
'1984년 06월 21일 14:02:14',
'1984년 07월 23일 00:58:13',
'1984년 08월 23일 08:00:12',
'1984년 09월 23일 05:32:54',
'1984년 10월 23일 14:45:39',
'1984년 11월 22일 12:10:39',
'1984년 12월 22일 01:22:50',

        ]
    },
    {
        year : 1985,
        joonggi :[
            '1985년 01월 05일 18:35:05',
            '1985년 02월 04일 06:11:49',
            '1985년 03월 06일 00:16:23',
            '1985년 04월 05일 05:13:35',
            '1985년 05월 05일 22:42:33',
            '1985년 06월 06일 02:59:57',
            '1985년 07월 07일 13:18:37',
            '1985년 08월 07일 23:04:17',
            '1985년 09월 08일 01:53:03',
            '1985년 10월 08일 17:24:35',
            '1985년 11월 07일 20:29:30',
            '1985년 12월 07일 13:16:21',
            '1985년 01월 20일 11:57:34',
            '1985년 02월 19일 02:07:24',
            '1985년 03월 21일 01:13:45',
            '1985년 04월 20일 12:25:48',
            '1985년 05월 21일 11:42:56',
            '1985년 06월 21일 19:44:09',
            '1985년 07월 23일 06:36:28',
            '1985년 08월 23일 13:35:42',
            '1985년 09월 23일 11:07:28',
            '1985년 10월 23일 20:21:53',
            '1985년 11월 22일 17:50:47',
            '1985년 12월 22일 07:07:41',
        ]
    },
    {
        year : 1986,
        joonggi :[
            '1986년 01월 06일 00:28:02',
            '1986년 02월 04일 12:07:42',
            '1986년 03월 06일 06:12:08',
            '1986년 04월 05일 11:06:07',
            '1986년 05월 06일 04:30:36',
            '1986년 06월 06일 08:44:24',
            '1986년 07월 07일 19:00:46',
            '1986년 08월 08일 04:45:37',
            '1986년 09월 08일 07:34:37',
            '1986년 10월 08일 23:06:45',
            '1986년 11월 08일 02:12:50',
            '1986년 12월 07일 19:00:57',
            '1986년 01월 20일 17:46:11',
            '1986년 02월 19일 07:57:31',
            '1986년 03월 21일 07:02:42',
            '1986년 04월 20일 18:12:08',
            '1986년 05월 21일 17:27:55',
            '1986년 06월 22일 01:29:58',
            '1986년 07월 23일 12:24:24',
            '1986년 08월 23일 19:25:48',
            '1986년 09월 23일 16:58:52',
            '1986년 10월 24일 02:14:12',
            '1986년 11월 22일 23:44:21',
            '1986년 12월 22일 13:02:08',
        ]
    },
    {
        year : 1987,
        joonggi :[
            '1987년 01월 06일 06:13:01',
            '1987년 02월 04일 17:51:40',
            '1987년 03월 06일 11:53:37',
            '1987년 04월 05일 16:44:08',
            '1987년 05월 06일 10:05:36',
            '1987년 06월 06일 14:18:59',
            '1987년 07월 08일 00:38:41',
            '1987년 08월 08일 10:29:15',
            '1987년 09월 08일 13:24:08',
            '1987년 10월 09일 04:59:41',
            '1987년 11월 08일 08:05:41',
            '1987년 12월 08일 00:52:14',
            '1987년 01월 20일 23:40:23',
            '1987년 02월 19일 13:49:57',
            '1987년 03월 21일 12:51:58',
            '1987년 04월 20일 23:57:33',
            '1987년 05월 21일 23:10:02',
            '1987년 06월 22일 07:10:46',
            '1987년 07월 23일 18:06:04',
            '1987년 08월 24일 01:09:51',
            '1987년 09월 23일 22:45:17',
            '1987년 10월 24일 08:00:52',
            '1987년 11월 23일 05:29:24',
            '1987년 12월 22일 18:45:54',
        ]
    },
    {
        year : 1988,
        joonggi :[
            '1988년 01월 06일 12:03:31',
            '1988년 02월 04일 23:42:50',
            '1988년 03월 05일 17:46:33',
            '1988년 04월 04일 22:39:04',
            '1988년 05월 05일 16:01:44',
            '1988년 06월 05일 20:14:53',
            '1988년 07월 07일 06:32:55',
            '1988년 08월 07일 16:20:16',
            '1988년 09월 07일 19:11:32',
            '1988년 10월 08일 10:44:30',
            '1988년 11월 07일 13:48:55',
            '1988년 12월 07일 06:34:29',
            '1988년 01월 21일 05:24:18',
            '1988년 02월 19일 19:35:08',
            '1988년 03월 20일 18:38:37',
            '1988년 04월 20일 05:44:47',
            '1988년 05월 21일 04:56:41',
            '1988년 06월 21일 12:56:32',
            '1988년 07월 22일 23:51:06',
            '1988년 08월 23일 06:54:03',
            '1988년 09월 23일 04:28:51',
            '1988년 10월 23일 13:44:06',
            '1988년 11월 22일 11:11:59',
            '1988년 12월 22일 00:27:53',
        ]
    },
    {
        year : 1989,
        joonggi :[
            '1989년 01월 05일 17:45:57',
            '1989년 02월 04일 05:27:11',
            '1989년 03월 05일 23:34:10',
            '1989년 04월 05일 04:29:56',
            '1989년 05월 05일 21:53:57',
            '1989년 06월 06일 02:05:15',
            '1989년 07월 07일 12:19:27',
            '1989년 08월 07일 22:03:54',
            '1989년 09월 08일 00:53:56',
            '1989년 10월 08일 16:27:21',
            '1989년 11월 07일 19:33:33',
            '1989년 12월 07일 12:20:59',
            '1989년 01월 20일 11:06:59',
            '1989년 02월 19일 01:20:31',
            '1989년 03월 21일 00:28:16',
            '1989년 04월 20일 11:38:56',
            '1989년 05월 21일 10:53:33',
            '1989년 06월 21일 18:53:02',
            '1989년 07월 23일 05:45:30',
            '1989년 08월 23일 12:46:13',
            '1989년 09월 23일 10:19:37',
            '1989년 10월 23일 19:35:09',
            '1989년 11월 22일 17:04:38',
            '1989년 12월 22일 06:22:02',
        ]
    },
    {
        year : 1990,
        joonggi :[
            '1990년 01월 05일 23:33:15',
            '1990년 02월 04일 11:14:01',
            '1990년 03월 06일 05:19:18',
            '1990년 04월 05일 10:12:56',
            '1990년 05월 06일 03:35:27',
            '1990년 06월 06일 07:46:19',
            '1990년 07월 07일 18:00:29',
            '1990년 08월 08일 03:45:32',
            '1990년 09월 08일 06:37:28',
            '1990년 10월 08일 22:13:48',
            '1990년 11월 08일 01:23:30',
            '1990년 12월 07일 18:14:11',
            '1990년 01월 20일 17:01:34',
            '1990년 02월 19일 07:14:02',
            '1990년 03월 21일 06:19:16',
            '1990년 04월 20일 17:26:33',
            '1990년 05월 21일 16:37:25',
            '1990년 06월 22일 00:32:48',
            '1990년 07월 23일 11:21:32',
            '1990년 08월 23일 18:20:50',
            '1990년 09월 23일 15:55:31',
            '1990년 10월 24일 01:13:58',
            '1990년 11월 22일 22:46:56',
            '1990년 12월 22일 12:07:00',
        ]
    } ,


    {
        year : 1991,
        joonggi :[
            '1991년 01월 06일 05:28:07',
            '1991년 02월 04일 17:08:25',
            '1991년 03월 06일 11:12:15',
            '1991년 04월 05일 16:04:42',
            '1991년 05월 06일 09:26:54',
            '1991년 06월 06일 13:38:19',
            '1991년 07월 07일 23:53:01',
            '1991년 08월 08일 09:37:16',
            '1991년 09월 08일 12:27:22',
            '1991년 10월 09일 04:01:08',
            '1991년 11월 08일 07:07:52',
            '1991년 12월 07일 23:56:02',
            '1991년 01월 20일 22:47:06',
            '1991년 02월 19일 12:58:20',
            '1991년 03월 21일 12:01:56',
            '1991년 04월 20일 23:08:23',
            '1991년 05월 21일 22:20:15',
            '1991년 06월 22일 06:18:47',
            '1991년 07월 23일 17:11:08',
            '1991년 08월 24일 00:12:50',
            '1991년 09월 23일 21:48:05',
            '1991년 10월 24일 07:05:11',
            '1991년 11월 23일 04:35:46',
            '1991년 12월 22일 17:53:39',
        ]
    },
    {
        year : 1992,
        joonggi :[
            '1992년 01월 06일 11:08:32',
            '1992년 02월 04일 22:48:17',
            '1992년 03월 05일 16:52:08',
            '1992년 04월 04일 21:45:09',
            '1992년 05월 05일 15:08:41',
            '1992년 06월 05일 19:22:21',
            '1992년 07월 07일 05:40:17',
            '1992년 08월 07일 15:27:24',
            '1992년 09월 07일 18:18:20',
            '1992년 10월 08일 09:51:29',
            '1992년 11월 07일 12:57:03',
            '1992년 12월 07일 05:44:13',
            '1992년 01월 21일 04:32:30',
            '1992년 02월 19일 18:43:30',
            '1992년 03월 20일 17:48:04',
            '1992년 04월 20일 04:56:54',
            '1992년 05월 21일 04:12:09',
            '1992년 06월 21일 12:14:10',
            '1992년 07월 22일 23:08:51',
            '1992년 08월 23일 06:10:08',
            '1992년 09월 23일 03:42:48',
            '1992년 10월 23일 12:57:09',
            '1992년 11월 22일 10:25:53',
            '1992년 12월 21일 23:43:15',
        ]
    },

    {
        year : 1993,
        joonggi :[
            '1993년 01월 05일 16:56:32',
            '1993년 02월 04일 04:37:10',
            '1993년 03월 05일 22:42:32',
            '1993년 04월 05일 03:37:11',
            '1993년 05월 05일 21:01:43',
            '1993년 06월 06일 01:15:14',
            '1993년 07월 07일 11:32:04',
            '1993년 08월 07일 21:17:59',
            '1993년 09월 08일 00:07:49',
            '1993년 10월 08일 15:40:03',
            '1993년 11월 07일 18:45:35',
            '1993년 12월 07일 11:33:50',
            '1993년 01월 20일 10:22:49',
            '1993년 02월 19일 00:35:10',
            '1993년 03월 20일 23:40:39',
            '1993년 04월 20일 10:49:01',
            '1993년 05월 21일 10:01:44',
            '1993년 06월 21일 17:59:45',
            '1993년 07월 23일 04:50:51',
            '1993년 08월 23일 11:50:18',
            '1993년 09월 23일 09:22:29',
            '1993년 10월 23일 18:37:09',
            '1993년 11월 22일 16:06:52',
            '1993년 12월 22일 05:25:50',
        ]
    },

    {
        year : 1994,
        joonggi :[
            '1994년 01월 05일 22:48:07',
            '1994년 02월 04일 10:30:56',
            '1994년 03월 06일 04:37:41',
            '1994년 04월 05일 09:31:48',
            '1994년 05월 06일 02:54:06',
            '1994년 06월 06일 07:04:53',
            '1994년 07월 07일 17:19:23',
            '1994년 08월 08일 03:04:22',
            '1994년 09월 08일 05:55:07',
            '1994년 10월 08일 21:29:05',
            '1994년 11월 08일 00:35:38',
            '1994년 12월 07일 17:22:55',
            '1994년 01월 20일 16:07:25',
            '1994년 02월 19일 06:21:39',
            '1994년 03월 21일 05:28:01',
            '1994년 04월 20일 16:36:01',
            '1994년 05월 21일 15:48:29',
            '1994년 06월 21일 23:47:33',
            '1994년 07월 23일 10:41:01',
            '1994년 08월 23일 17:43:46',
            '1994년 09월 23일 15:19:14',
            '1994년 10월 24일 00:36:02',
            '1994년 11월 22일 22:06:00',
            '1994년 12월 22일 11:22:44',
        ]
    },
    {
        year : 1995,
        joonggi :[
            '1995년 01월 06일 04:34:05',
            '1995년 02월 04일 16:12:51',
            '1995년 03월 06일 10:16:04',
            '1995년 04월 05일 15:08:07',
            '1995년 05월 06일 08:30:04',
            '1995년 06월 06일 12:42:29',
            '1995년 07월 07일 23:01:00',
            '1995년 08월 08일 08:51:45',
            '1995년 09월 08일 11:48:35',
            '1995년 10월 09일 03:27:13',
            '1995년 11월 08일 06:35:37',
            '1995년 12월 07일 23:22:17',
            '1995년 01월 20일 22:00:27',
            '1995년 02월 19일 12:10:43',
            '1995년 03월 21일 11:14:26',
            '1995년 04월 20일 22:21:29',
            '1995년 05월 21일 21:34:13',
            '1995년 06월 22일 05:34:23',
            '1995년 07월 23일 16:29:40',
            '1995년 08월 23일 23:34:50',
            '1995년 09월 23일 21:13:00',
            '1995년 10월 24일 06:31:31',
            '1995년 11월 23일 04:01:24',
            '1995년 12월 22일 17:16:49',
        ]
    },
    {
        year : 1996,
        joonggi :[
            '1996년 01월 06일 10:31:27',
            '1996년 02월 04일 22:07:52',
            '1996년 03월 05일 16:09:37',
            '1996년 04월 04일 21:02:00',
            '1996년 05월 05일 14:26:00',
            '1996년 06월 05일 18:40:47',
            '1996년 07월 07일 05:00:00',
            '1996년 08월 07일 14:48:48',
            '1996년 09월 07일 17:42:24',
            '1996년 10월 08일 09:18:42',
            '1996년 11월 07일 12:26:34',
            '1996년 12월 07일 05:14:01',
            '1996년 01월 21일 03:52:30',
            '1996년 02월 19일 18:00:43',
            '1996년 03월 20일 17:03:05',
            '1996년 04월 20일 04:09:53',
            '1996년 05월 21일 03:23:06',
            '1996년 06월 21일 11:23:44',
            '1996년 07월 22일 22:18:42',
            '1996년 08월 23일 05:22:49',
            '1996년 09월 23일 03:00:06',
            '1996년 10월 23일 12:18:42',
            '1996년 11월 22일 09:49:26',
            '1996년 12월 21일 23:05:54',
        ]
    },
    {
        year : 1997,
        joonggi :[
            '1997년 01월 05일 16:24:29',
            '1997년 02월 04일 04:01:58',
            '1997년 03월 05일 22:04:09',
            '1997년 04월 05일 02:56:17',
            '1997년 05월 05일 20:19:28',
            '1997년 06월 06일 00:32:33',
            '1997년 07월 07일 10:49:24',
            '1997년 08월 07일 20:36:19',
            '1997년 09월 07일 23:28:50',
            '1997년 10월 08일 15:05:11',
            '1997년 11월 07일 18:14:40',
            '1997년 12월 07일 11:04:54',
            '1997년 01월 20일 09:42:31',
            '1997년 02월 18일 23:51:29',
            '1997년 03월 20일 22:54:40',
            '1997년 04월 20일 10:02:49',
            '1997년 05월 21일 09:17:53',
            '1997년 06월 21일 17:19:57',
            '1997년 07월 23일 04:15:27',
            '1997년 08월 23일 11:19:12',
            '1997년 09월 23일 08:55:47',
            '1997년 10월 23일 18:14:46',
            '1997년 11월 22일 15:47:35',
            '1997년 12월 22일 05:07:03',
        ]
    },
    {
        year : 1998,
        joonggi :[
            '1998년 01월 05일 22:18:08',
            '1998년 02월 04일 09:56:52',
            '1998년 03월 06일 03:57:14',
            '1998년 04월 05일 08:44:57',
            '1998년 05월 06일 02:03:11',
            '1998년 06월 06일 06:13:23',
            '1998년 07월 07일 16:30:25',
            '1998년 08월 08일 02:19:50',
            '1998년 09월 08일 05:15:55',
            '1998년 10월 08일 20:55:45',
            '1998년 11월 08일 00:08:23',
            '1998년 12월 07일 17:01:36',
            '1998년 01월 20일 15:46:04',
            '1998년 02월 19일 05:54:53',
            '1998년 03월 21일 04:54:32',
            '1998년 04월 20일 15:56:44',
            '1998년 05월 21일 15:05:27',
            '1998년 06월 21일 23:02:35',
            '1998년 07월 23일 09:55:23',
            '1998년 08월 23일 16:58:57',
            '1998년 09월 23일 14:37:12',
            '1998년 10월 23일 23:58:36',
            '1998년 11월 22일 21:34:14',
            '1998년 12월 22일 10:56:28',
        ]
    },
    {
        year : 1999,
        joonggi :[
            '1999년 01월 06일 04:17:09',
            '1999년 02월 04일 15:57:03',
            '1999년 03월 06일 09:57:42',
            '1999년 04월 05일 14:44:37',
            '1999년 05월 06일 08:01:00',
            '1999년 06월 06일 12:09:07',
            '1999년 07월 07일 22:24:59',
            '1999년 08월 08일 08:14:07',
            '1999년 09월 08일 11:09:59',
            '1999년 10월 09일 02:48:22',
            '1999년 11월 08일 05:57:53',
            '1999년 12월 07일 22:47:28',
            '1999년 01월 20일 21:37:20',
            '1999년 02월 19일 11:46:50',
            '1999년 03월 21일 10:45:49',
            '1999년 04월 20일 21:45:59',
            '1999년 05월 21일 20:52:25',
            '1999년 06월 22일 04:49:07',
            '1999년 07월 23일 15:44:07',
            '1999년 08월 23일 22:51:04',
            '1999년 09월 23일 20:31:30',
            '1999년 10월 24일 05:52:13',
            '1999년 11월 23일 03:24:50',
            '1999년 12월 22일 16:43:48',
        ]
    },
    {
        year : 2000,
        joonggi :[
            '2000년 01월 06일 10:00:41',
            '2000년 02월 04일 21:40:23',
            '2000년 03월 05일 15:42:39',
            '2000년 04월 04일 20:31:58',
            '2000년 05월 05일 13:50:10',
            '2000년 06월 05일 17:58:34',
            '2000년 07월 07일 04:13:57',
            '2000년 08월 07일 14:03:00',
            '2000년 09월 07일 16:59:10',
            '2000년 10월 08일 08:38:12',
            '2000년 11월 07일 11:48:04',
            '2000년 12월 07일 04:37:02',
            '2000년 01월 21일 03:23:02',
            '2000년 02월 19일 17:33:18',
            '2000년 03월 20일 16:35:15',
            '2000년 04월 20일 03:39:31',
            '2000년 05월 21일 02:49:25',
            '2000년 06월 21일 10:47:43',
            '2000년 07월 22일 21:42:41',
            '2000년 08월 23일 04:48:31',
            '2000년 09월 23일 02:27:36',
            '2000년 10월 23일 11:47:29',
            '2000년 11월 22일 09:19:21',
            '2000년 12월 21일 22:37:26',
        ]
    } ,


    {
        year : 2001,
        joonggi :[
            '2001년 01월 05일 15:49:16',
            '2001년 02월 04일 03:28:50',
            '2001년 03월 05일 21:32:30',
            '2001년 04월 05일 02:24:23',
            '2001년 05월 05일 19:44:50',
            '2001년 06월 05일 23:53:36',
            '2001년 07월 07일 10:06:42',
            '2001년 08월 07일 19:52:21',
            '2001년 09월 07일 22:46:11',
            '2001년 10월 08일 14:25:02',
            '2001년 11월 07일 17:36:53',
            '2001년 12월 07일 10:28:53',
            '2001년 01월 20일 09:16:18',
            '2001년 02월 18일 23:27:16',
            '2001년 03월 20일 22:30:43',
            '2001년 04월 20일 09:35:53',
            '2001년 05월 21일 08:44:13',
            '2001년 06월 21일 16:37:44',
            '2001년 07월 23일 03:26:15',
            '2001년 08월 23일 10:27:09',
            '2001년 09월 23일 08:04:29',
            '2001년 10월 23일 17:25:36',
            '2001년 11월 22일 15:00:28',
            '2001년 12월 22일 04:21:30',
        ]
    },
    {
        year : 2002,
        joonggi :[
            '2002년 01월 05일 21:43:31',
            '2002년 02월 04일 09:24:06',
            '2002년 03월 06일 03:27:33',
            '2002년 04월 05일 08:18:17',
            '2002년 05월 06일 01:37:18',
            '2002년 06월 06일 05:44:47',
            '2002년 07월 07일 15:56:12',
            '2002년 08월 08일 01:39:20',
            '2002년 09월 08일 04:31:03',
            '2002년 10월 08일 20:09:19',
            '2002년 11월 07일 23:21:50',
            '2002년 12월 07일 16:14:14',
            '2002년 01월 20일 15:02:02',
            '2002년 02월 19일 05:13:20',
            '2002년 03월 21일 04:16:09',
            '2002년 04월 20일 15:20:29',
            '2002년 05월 21일 14:29:06',
            '2002년 06월 21일 22:24:25',
            '2002년 07월 23일 09:14:52',
            '2002년 08월 23일 16:16:59',
            '2002년 09월 23일 13:55:24',
            '2002년 10월 23일 23:17:50',
            '2002년 11월 22일 20:53:45',
            '2002년 12월 22일 10:14:23',
        ]
    },

    {
        year : 2003,
        joonggi :[
            '2003년 01월 06일 03:27:44',
            '2003년 02월 04일 15:05:21',
            '2003년 03월 06일 09:04:54',
            '2003년 04월 05일 13:52:30',
            '2003년 05월 06일 07:10:30',
            '2003년 06월 06일 11:19:44',
            '2003년 07월 07일 21:35:40',
            '2003년 08월 08일 07:24:19',
            '2003년 09월 08일 10:20:15',
            '2003년 10월 09일 02:00:33',
            '2003년 11월 08일 05:13:12',
            '2003년 12월 07일 22:05:10',
            '2003년 01월 20일 20:52:35',
            '2003년 02월 19일 11:00:13',
            '2003년 03월 21일 09:59:46',
            '2003년 04월 20일 21:02:47',
            '2003년 05월 21일 20:12:25',
            '2003년 06월 22일 04:10:28',
            '2003년 07월 23일 15:04:09',
            '2003년 08월 23일 22:08:11',
            '2003년 09월 23일 19:46:50',
            '2003년 10월 24일 05:08:27',
            '2003년 11월 23일 02:43:23',
            '2003년 12월 22일 16:03:50',
        ]
    },

    {
        year : 2004,
        joonggi :[
            '2004년 01월 06일 09:18:33',
            '2004년 02월 04일 20:56:13',
            '2004년 03월 05일 14:55:38',
            '2004년 04월 04일 19:43:19',
            '2004년 05월 05일 13:02:28',
            '2004년 06월 05일 17:13:46',
            '2004년 07월 07일 03:31:17',
            '2004년 08월 07일 13:19:37',
            '2004년 09월 07일 16:12:55',
            '2004년 10월 08일 07:49:18',
            '2004년 11월 07일 10:58:34',
            '2004년 12월 07일 03:48:58',
            '2004년 01월 21일 02:42:24',
            '2004년 02월 19일 16:50:01',
            '2004년 03월 20일 15:48:39',
            '2004년 04월 20일 02:50:26',
            '2004년 05월 21일 01:59:13',
            '2004년 06월 21일 09:56:53',
            '2004년 07월 22일 20:50:11',
            '2004년 08월 23일 03:53:16',
            '2004년 09월 23일 01:29:51',
            '2004년 10월 23일 10:48:50',
            '2004년 11월 22일 08:21:42',
            '2004년 12월 21일 21:41:37',
        ]
    },
    {
        year : 2005,
        joonggi :[
            '2005년 01월 05일 15:03:01',
            '2005년 02월 04일 02:43:04',
            '2005년 03월 05일 20:45:12',
            '2005년 04월 05일 01:34:18',
            '2005년 05월 05일 18:52:51',
            '2005년 06월 05일 23:01:54',
            '2005년 07월 07일 09:16:35',
            '2005년 08월 07일 19:03:23',
            '2005년 09월 07일 21:56:41',
            '2005년 10월 08일 13:33:19',
            '2005년 11월 07일 16:42:28',
            '2005년 12월 07일 09:32:43',
            '2005년 01월 20일 08:21:34',
            '2005년 02월 18일 22:31:56',
            '2005년 03월 20일 21:33:26',
            '2005년 04월 20일 08:37:15',
            '2005년 05월 21일 07:47:25',
            '2005년 06월 21일 15:46:08',
            '2005년 07월 23일 02:40:43',
            '2005년 08월 23일 09:45:28',
            '2005년 09월 23일 07:23:11',
            '2005년 10월 23일 16:42:21',
            '2005년 11월 22일 14:15:00',
            '2005년 12월 22일 03:34:58',
        ]
    },
    {
        year : 2006,
        joonggi :[
            '2006년 01월 05일 20:46:58',
            '2006년 02월 04일 08:27:17',
            '2006년 03월 06일 02:28:39',
            '2006년 04월 05일 07:15:29',
            '2006년 05월 06일 00:30:39',
            '2006년 06월 06일 04:36:59',
            '2006년 07월 07일 14:51:28',
            '2006년 08월 08일 00:40:48',
            '2006년 09월 08일 03:39:02',
            '2006년 10월 08일 19:21:22',
            '2006년 11월 07일 22:34:50',
            '2006년 12월 07일 15:26:48',
            '2006년 01월 20일 14:15:19',
            '2006년 02월 19일 04:25:35',
            '2006년 03월 21일 03:25:35',
            '2006년 04월 20일 14:26:04',
            '2006년 05월 21일 13:31:34',
            '2006년 06월 21일 21:25:52',
            '2006년 07월 23일 08:17:42',
            '2006년 08월 23일 15:22:36',
            '2006년 09월 23일 13:03:23',
            '2006년 10월 23일 22:26:28',
            '2006년 11월 22일 20:01:45',
            '2006년 12월 22일 09:22:06',
        ]
    },
    {
        year : 2007,
        joonggi :[
            '2007년 01월 06일 02:40:10',
            '2007년 02월 04일 14:18:13',
            '2007년 03월 06일 08:18:00',
            '2007년 04월 05일 13:04:39',
            '2007년 05월 06일 06:20:24',
            '2007년 06월 06일 10:27:05',
            '2007년 07월 07일 20:41:44',
            '2007년 08월 08일 06:31:15',
            '2007년 09월 08일 09:29:29',
            '2007년 10월 09일 01:11:30',
            '2007년 11월 08일 04:24:02',
            '2007년 12월 07일 21:14:06',
            '2007년 01월 20일 20:00:50',
            '2007년 02월 19일 10:08:57',
            '2007년 03월 21일 09:07:26',
            '2007년 04월 20일 20:07:04',
            '2007년 05월 21일 19:11:56',
            '2007년 06월 22일 03:06:26',
            '2007년 07월 23일 14:00:11',
            '2007년 08월 23일 21:07:59',
            '2007년 09월 23일 18:51:14',
            '2007년 10월 24일 04:15:24',
            '2007년 11월 23일 01:49:54',
            '2007년 12월 22일 15:07:49',
        ]
    },
    {
        year : 2008,
        joonggi :[
            '2008년 01월 06일 08:24:51',
            '2008년 02월 04일 20:00:26',
            '2008년 03월 05일 13:58:49',
            '2008년 04월 04일 18:45:52',
            '2008년 05월 05일 12:03:27',
            '2008년 06월 05일 16:11:44',
            '2008년 07월 07일 02:26:51',
            '2008년 08월 07일 12:16:12',
            '2008년 09월 07일 15:14:09',
            '2008년 10월 08일 06:56:38',
            '2008년 11월 07일 10:10:35',
            '2008년 12월 07일 03:02:19',
            '2008년 01월 21일 01:43:33',
            '2008년 02월 19일 15:49:34',
            '2008년 03월 20일 14:48:18',
            '2008년 04월 20일 01:51:08',
            '2008년 05월 21일 01:00:54',
            '2008년 06월 21일 08:59:23',
            '2008년 07월 22일 19:54:49',
            '2008년 08월 23일 03:02:15',
            '2008년 09월 23일 00:44:30',
            '2008년 10월 23일 10:08:39',
            '2008년 11월 22일 07:44:21',
            '2008년 12월 21일 21:03:47',
        ]
    },
    {
        year : 2009,
        joonggi :[
            '2009년 01월 05일 14:14:08',
            '2009년 02월 04일 01:49:49',
            '2009년 03월 05일 19:47:33',
            '2009년 04월 05일 00:33:47',
            '2009년 05월 05일 17:50:50',
            '2009년 06월 05일 21:59:05',
            '2009년 07월 07일 08:13:30',
            '2009년 08월 07일 18:01:09',
            '2009년 09월 07일 20:57:36',
            '2009년 10월 08일 12:40:04',
            '2009년 11월 07일 15:56:16',
            '2009년 12월 07일 08:52:15',
            '2009년 01월 20일 07:40:20',
            '2009년 02월 18일 21:46:07',
            '2009년 03월 20일 20:43:39',
            '2009년 04월 20일 07:44:25',
            '2009년 05월 21일 06:51:11',
            '2009년 06월 21일 14:45:32',
            '2009년 07월 23일 01:35:44',
            '2009년 08월 23일 08:38:35',
            '2009년 09월 23일 06:18:36',
            '2009년 10월 23일 15:43:29',
            '2009년 11월 22일 13:22:35',
            '2009년 12월 22일 02:46:48',
        ]
    },
    {
        year : 2010,
        joonggi :[
            '2010년 01월 05일 20:08:48',
            '2010년 02월 04일 07:47:52',
            '2010년 03월 06일 01:46:21',
            '2010년 04월 05일 06:30:29',
            '2010년 05월 05일 23:44:02',
            '2010년 06월 06일 03:49:24',
            '2010년 07월 07일 14:02:25',
            '2010년 08월 07일 23:49:10',
            '2010년 09월 08일 02:44:42',
            '2010년 10월 08일 18:26:30',
            '2010년 11월 07일 21:42:31',
            '2010년 12월 07일 14:38:24',
            '2010년 01월 20일 13:27:43',
            '2010년 02월 19일 03:35:38',
            '2010년 03월 21일 02:32:13',
            '2010년 04월 20일 13:29:49',
            '2010년 05월 21일 12:33:55',
            '2010년 06월 21일 20:28:25',
            '2010년 07월 23일 07:21:13',
            '2010년 08월 23일 14:26:58',
            '2010년 09월 23일 12:09:02',
            '2010년 10월 23일 21:35:04',
            '2010년 11월 22일 19:14:35',
            '2010년 12월 22일 08:38:28',
        ]
    } ,


    {
        year : 2011,
        joonggi :[
            '2011년 01월 06일 01:54:39',
            '2011년 02월 04일 13:32:58',
            '2011년 03월 06일 07:29:59',
            '2011년 04월 05일 12:12:00',
            '2011년 05월 06일 05:23:14',
            '2011년 06월 06일 09:27:22',
            '2011년 07월 07일 19:42:02',
            '2011년 08월 08일 05:33:27',
            '2011년 09월 08일 08:34:14',
            '2011년 10월 09일 00:19:06',
            '2011년 11월 08일 03:34:57',
            '2011년 12월 07일 20:29:01',
            '2011년 01월 20일 19:18:33',
            '2011년 02월 19일 09:25:20',
            '2011년 03월 21일 08:20:44',
            '2011년 04월 20일 19:17:26',
            '2011년 05월 21일 18:21:11',
            '2011년 06월 22일 02:16:31',
            '2011년 07월 23일 13:11:51',
            '2011년 08월 23일 20:20:40',
            '2011년 09월 23일 18:04:39',
            '2011년 10월 24일 03:30:20',
            '2011년 11월 23일 01:07:51',
            '2011년 12월 22일 14:30:04',
        ]
    },
    {
        year : 2012,
        joonggi :[
            '2012년 01월 06일 07:43:57',
            '2012년 02월 04일 19:22:25',
            '2012년 03월 05일 13:21:04',
            '2012년 04월 04일 18:05:37',
            '2012년 05월 05일 11:19:42',
            '2012년 06월 05일 15:25:55',
            '2012년 07월 07일 01:40:45',
            '2012년 08월 07일 11:30:35',
            '2012년 09월 07일 14:29:02',
            '2012년 10월 08일 06:11:44',
            '2012년 11월 07일 09:25:59',
            '2012년 12월 07일 02:18:58',
            '2012년 01월 21일 01:09:52',
            '2012년 02월 19일 15:17:38',
            '2012년 03월 20일 14:14:27',
            '2012년 04월 20일 01:12:06',
            '2012년 05월 21일 00:15:33',
            '2012년 06월 21일 08:08:50',
            '2012년 07월 22일 19:00:54',
            '2012년 08월 23일 02:06:51',
            '2012년 09월 22일 23:49:00',
            '2012년 10월 23일 09:13:35',
            '2012년 11월 22일 06:50:11',
            '2012년 12월 21일 20:11:38',

        ]
    },

    {
        year : 2013,
        joonggi :[
            '2013년 01월 05일 13:33:39',
            '2013년 02월 04일 01:13:26',
            '2013년 03월 05일 19:14:52',
            '2013년 04월 05일 00:02:28',
            '2013년 05월 05일 17:18:11',
            '2013년 06월 05일 21:23:21',
            '2013년 07월 07일 07:34:37',
            '2013년 08월 07일 17:20:22',
            '2013년 09월 07일 20:16:16',
            '2013년 10월 08일 11:58:30',
            '2013년 11월 07일 15:13:54',
            '2013년 12월 07일 08:08:34',
            '2013년 01월 20일 06:51:44',
            '2013년 02월 18일 21:01:37',
            '2013년 03월 20일 20:01:56',
            '2013년 04월 20일 07:03:18',
            '2013년 05월 21일 06:09:31',
            '2013년 06월 21일 14:03:58',
            '2013년 07월 23일 00:55:59',
            '2013년 08월 23일 08:01:43',
            '2013년 09월 23일 05:44:09',
            '2013년 10월 23일 15:09:50',
            '2013년 11월 22일 12:48:09',
            '2013년 12월 22일 02:11:01',
        ]
    },

    {
        year : 2014,
        joonggi :[
            '2014년 01월 05일 19:24:13',
            '2014년 02월 04일 07:03:18',
            '2014년 03월 06일 01:02:17',
            '2014년 04월 05일 05:46:41',
            '2014년 05월 05일 22:59:28',
            '2014년 06월 06일 03:03:04',
            '2014년 07월 07일 13:14:48',
            '2014년 08월 07일 23:02:30',
            '2014년 09월 08일 02:01:27',
            '2014년 10월 08일 17:47:31',
            '2014년 11월 07일 21:06:42',
            '2014년 12월 07일 14:04:08',
            '2014년 01월 20일 12:51:16',
            '2014년 02월 19일 02:59:31',
            '2014년 03월 21일 01:57:07',
            '2014년 04월 20일 12:55:34',
            '2014년 05월 21일 11:59:05',
            '2014년 06월 21일 19:51:15',
            '2014년 07월 23일 06:41:23',
            '2014년 08월 23일 13:46:00',
            '2014년 09월 23일 11:29:06',
            '2014년 10월 23일 20:57:05',
            '2014년 11월 22일 18:38:13',
            '2014년 12월 22일 08:03:03',
        ]
    },
    {
        year : 2015,
        joonggi :[
            '2015년 01월 06일 01:20:35',
            '2015년 02월 04일 12:58:29',
            '2015년 03월 06일 06:55:42',
            '2015년 04월 05일 11:39:09',
            '2015년 05월 06일 04:52:37',
            '2015년 06월 06일 08:58:12',
            '2015년 07월 07일 19:12:17',
            '2015년 08월 08일 05:01:25',
            '2015년 09월 08일 07:59:35',
            '2015년 10월 08일 23:42:51',
            '2015년 11월 08일 02:58:40',
            '2015년 12월 07일 19:53:23',
            '2015년 01월 20일 18:43:17',
            '2015년 02월 19일 08:49:50',
            '2015년 03월 21일 07:45:10',
            '2015년 04월 20일 18:41:53',
            '2015년 05월 21일 17:44:49',
            '2015년 06월 22일 01:37:57',
            '2015년 07월 23일 12:30:29',
            '2015년 08월 23일 19:37:18',
            '2015년 09월 23일 17:20:34',
            '2015년 10월 24일 02:46:46',
            '2015년 11월 23일 00:25:19',
            '2015년 12월 22일 13:47:58',

        ]
    },
    {
        year : 2016,
        joonggi :[
            '2016년 01월 06일 07:08:24',
            '2016년 02월 04일 18:46:03',
            '2016년 03월 05일 12:43:34',
            '2016년 04월 04일 17:27:33',
            '2016년 05월 05일 10:41:55',
            '2016년 06월 05일 14:48:32',
            '2016년 07월 07일 01:03:23',
            '2016년 08월 07일 10:53:03',
            '2016년 09월 07일 13:51:07',
            '2016년 10월 08일 05:33:24',
            '2016년 11월 07일 08:47:43',
            '2016년 12월 07일 01:41:09',
            '2016년 01월 21일 00:27:06',
            '2016년 02월 19일 14:33:44',
            '2016년 03월 20일 13:30:12',
            '2016년 04월 20일 00:29:27',
            '2016년 05월 20일 23:36:30',
            '2016년 06월 21일 07:34:12',
            '2016년 07월 22일 18:30:13',
            '2016년 08월 23일 01:38:29',
            '2016년 09월 22일 23:21:08',
            '2016년 10월 23일 08:45:34',
            '2016년 11월 22일 06:22:24',
            '2016년 12월 21일 19:44:12',
        ]
    },
    {
        year : 2017,
        joonggi :[
            '2017년 01월 05일 12:55:45',
            '2017년 02월 04일 00:34:04',
            '2017년 03월 05일 18:32:44',
            '2017년 04월 04일 23:17:20',
            '2017년 05월 05일 16:31:03',
            '2017년 06월 05일 20:36:37',
            '2017년 07월 07일 06:50:42',
            '2017년 08월 07일 16:40:00',
            '2017년 09월 07일 19:38:37',
            '2017년 10월 08일 11:22:09',
            '2017년 11월 07일 14:37:50',
            '2017년 12월 07일 07:32:40',
            '2017년 01월 20일 06:23:37',
            '2017년 02월 18일 20:31:20',
            '2017년 03월 20일 19:28:39',
            '2017년 04월 20일 06:27:02',
            '2017년 05월 21일 05:30:56',
            '2017년 06월 21일 13:24:10',
            '2017년 07월 23일 00:15:22',
            '2017년 08월 23일 07:20:13',
            '2017년 09월 23일 05:01:48',
            '2017년 10월 23일 14:26:42',
            '2017년 11월 22일 12:04:40',
            '2017년 12월 22일 01:27:58',
        ]
    },
    {
        year : 2018,
        joonggi :[
            '2018년 01월 05일 18:48:45',
            '2018년 02월 04일 06:28:30',
            '2018년 03월 06일 00:28:11',
            '2018년 04월 05일 05:12:48',
            '2018년 05월 05일 22:25:23',
            '2018년 06월 06일 02:29:09',
            '2018년 07월 07일 12:41:53',
            '2018년 08월 07일 22:30:41',
            '2018년 09월 08일 01:29:43',
            '2018년 10월 08일 17:14:44',
            '2018년 11월 07일 20:31:47',
            '2018년 12월 07일 13:25:56',
            '2018년 01월 20일 12:09:02',
            '2018년 02월 19일 02:18:01',
            '2018년 03월 21일 01:15:28',
            '2018년 04월 20일 12:12:34',
            '2018년 05월 21일 11:14:38',
            '2018년 06월 21일 19:07:18',
            '2018년 07월 23일 06:00:21',
            '2018년 08월 23일 13:08:34',
            '2018년 09월 23일 10:54:06',
            '2018년 10월 23일 20:22:24',
            '2018년 11월 22일 18:01:30',
            '2018년 12월 22일 07:22:45',
        ]
    },
    {
        year : 2019,
        joonggi :[
            '2019년 01월 06일 00:38:59',
            '2019년 02월 04일 12:14:20',
            '2019년 03월 06일 06:09:45',
            '2019년 04월 05일 10:51:27',
            '2019년 05월 06일 04:02:46',
            '2019년 06월 06일 08:06:25',
            '2019년 07월 07일 18:20:32',
            '2019년 08월 08일 04:13:04',
            '2019년 09월 08일 07:16:52',
            '2019년 10월 08일 23:05:38',
            '2019년 11월 08일 02:24:23',
            '2019년 12월 07일 19:18:29',
            '2019년 01월 20일 17:59:33',
            '2019년 02월 19일 08:03:57',
            '2019년 03월 21일 06:58:26',
            '2019년 04월 20일 17:55:17',
            '2019년 05월 21일 16:59:08',
            '2019년 06월 22일 00:54:15',
            '2019년 07월 23일 11:50:23',
            '2019년 08월 23일 19:02:01',
            '2019년 09월 23일 16:50:11',
            '2019년 10월 24일 02:19:45',
            '2019년 11월 22일 23:58:57',
            '2019년 12월 22일 13:19:27',
        ]
    },
    {
        year : 2020,
        joonggi :[
            '2020년 01월 06일 06:30:07',
            '2020년 02월 04일 18:03:20',
            '2020년 03월 05일 11:56:54',
            '2020년 04월 04일 16:38:11',
            '2020년 05월 05일 09:51:24',
            '2020년 06월 05일 13:58:26',
            '2020년 07월 07일 00:14:28',
            '2020년 08월 07일 10:06:12',
            '2020년 09월 07일 13:08:03',
            '2020년 10월 08일 04:55:16',
            '2020년 11월 07일 08:13:56',
            '2020년 12월 07일 01:09:31',
            '2020년 01월 20일 23:54:41',
            '2020년 02월 19일 13:57:01',
            '2020년 03월 20일 12:49:38',
            '2020년 04월 19일 23:45:30',
            '2020년 05월 20일 22:49:18',
            '2020년 06월 21일 06:43:41',
            '2020년 07월 22일 17:36:53',
            '2020년 08월 23일 00:44:56',
            '2020년 09월 22일 22:30:40',
            '2020년 10월 23일 07:59:33',
            '2020년 11월 22일 05:39:46',
            '2020년 12월 21일 19:02:21',
        ]
    } ,


    {
        year : 2021,
        joonggi :[
            '2021년 01월 05일 12:23:26',
            '2021년 02월 03일 23:58:48',
            '2021년 03월 05일 17:53:41',
            '2021년 04월 04일 22:35:07',
            '2021년 05월 05일 15:47:11',
            '2021년 06월 05일 19:52:07',
            '2021년 07월 07일 06:05:29',
            '2021년 08월 07일 15:53:59',
            '2021년 09월 07일 18:52:57',
            '2021년 10월 08일 10:39:03',
            '2021년 11월 07일 13:58:46',
            '2021년 12월 07일 06:57:04',
            '2021년 01월 20일 05:39:52',
            '2021년 02월 18일 19:43:59',
            '2021년 03월 20일 18:37:29',
            '2021년 04월 20일 05:33:25',
            '2021년 05월 21일 04:37:07',
            '2021년 06월 21일 12:32:10',
            '2021년 07월 22일 23:26:26',
            '2021년 08월 23일 06:34:59',
            '2021년 09월 23일 04:21:06',
            '2021년 10월 23일 13:51:10',
            '2021년 11월 22일 11:33:44',
            '2021년 12월 22일 00:59:19',
        ]
    },
    {
        year : 2022,
        joonggi :[
            '2022년 01월 05일 18:14:04',
            '2022년 02월 04일 05:50:48',
            '2022년 03월 05일 23:43:46',
            '2022년 04월 05일 04:20:15',
            '2022년 05월 05일 21:25:58',
            '2022년 06월 06일 01:25:49',
            '2022년 07월 07일 11:38:01',
            '2022년 08월 07일 21:29:10',
            '2022년 09월 08일 00:32:19',
            '2022년 10월 08일 16:22:28',
            '2022년 11월 07일 19:45:29',
            '2022년 12월 07일 12:46:16',
            '2022년 01월 20일 11:39:07',
            '2022년 02월 19일 01:43:02',
            '2022년 03월 21일 00:33:25',
            '2022년 04월 20일 11:24:17',
            '2022년 05월 21일 10:22:35',
            '2022년 06월 21일 18:13:51',
            '2022년 07월 23일 05:07:00',
            '2022년 08월 23일 12:16:11',
            '2022년 09월 23일 10:03:43',
            '2022년 10월 23일 19:35:42',
            '2022년 11월 22일 17:20:30',
            '2022년 12월 22일 06:48:14',
        ]
    },

    {
        year : 2023,
        joonggi :[
            '2023년 01월 06일 00:04:51',
            '2023년 02월 04일 11:42:33',
            '2023년 03월 06일 05:36:14',
            '2023년 04월 05일 10:13:04',
            '2023년 05월 06일 03:18:46',
            '2023년 06월 06일 07:18:22',
            '2023년 07월 07일 17:30:42',
            '2023년 08월 08일 03:22:53',
            '2023년 09월 08일 06:26:42',
            '2023년 10월 08일 22:15:35',
            '2023년 11월 08일 01:35:36',
            '2023년 12월 07일 18:32:57',
            '2023년 01월 20일 17:29:34',
            '2023년 02월 19일 07:34:19',
            '2023년 03월 21일 06:24:27',
            '2023년 04월 20일 17:13:38',
            '2023년 05월 21일 16:09:12',
            '2023년 06월 21일 23:57:50',
            '2023년 07월 23일 10:50:28',
            '2023년 08월 23일 18:01:19',
            '2023년 09월 23일 15:50:00',
            '2023년 10월 24일 01:20:53',
            '2023년 11월 22일 23:02:42',
            '2023년 12월 22일 12:27:22',
        ]
    },

    {
        year : 2024,
        joonggi :[
            '2024년 01월 06일 05:49:22',
            '2024년 02월 04일 17:27:07',
            '2024년 03월 05일 11:22:46',
            '2024년 04월 04일 16:02:17',
            '2024년 05월 05일 09:10:05',
            '2024년 06월 05일 13:09:53',
            '2024년 07월 06일 23:20:03',
            '2024년 08월 07일 09:09:16',
            '2024년 09월 07일 12:11:20',
            '2024년 10월 08일 03:59:57',
            '2024년 11월 07일 07:20:04',
            '2024년 12월 07일 00:17:02',
            '2024년 01월 20일 23:07:20',
            '2024년 02월 19일 13:13:10',
            '2024년 03월 20일 12:06:24',
            '2024년 04월 19일 22:59:45',
            '2024년 05월 20일 21:59:30',
            '2024년 06월 21일 05:50:59',
            '2024년 07월 22일 16:44:25',
            '2024년 08월 22일 23:55:02',
            '2024년 09월 22일 21:43:39',
            '2024년 10월 23일 07:14:44',
            '2024년 11월 22일 04:56:29',
            '2024년 12월 21일 18:20:34',
        ]
    },
    {
        year : 2025,
        joonggi :[
            '2025년 01월 05일 11:32:45',
            '2025년 02월 03일 23:10:27',
            '2025년 03월 05일 17:07:15',
            '2025년 04월 04일 21:48:33',
            '2025년 05월 05일 14:57:10',
            '2025년 06월 05일 18:56:30',
            '2025년 07월 07일 05:04:58',
            '2025년 08월 07일 14:51:33',
            '2025년 09월 07일 17:51:55',
            '2025년 10월 08일 09:41:11',
            '2025년 11월 07일 13:04:02',
            '2025년 12월 07일 06:04:34',
            '2025년 01월 20일 05:00:06',
            '2025년 02월 18일 19:06:33',
            '2025년 03월 20일 18:01:28',
            '2025년 04월 20일 04:56:00',
            '2025년 05월 21일 03:54:37',
            '2025년 06월 21일 11:42:15',
            '2025년 07월 22일 22:29:26',
            '2025년 08월 23일 05:33:50',
            '2025년 09월 23일 03:19:19',
            '2025년 10월 23일 12:50:54',
            '2025년 11월 22일 10:35:33',
            '2025년 12월 22일 00:03:04',
        ]
    },
    {
        year : 2026,
        joonggi :[
            '2026년 01월 05일 17:23:08',
            '2026년 02월 04일 05:02:06',
            '2026년 03월 05일 22:58:57',
            '2026년 04월 05일 03:39:58',
            '2026년 05월 05일 20:48:42',
            '2026년 06월 06일 00:48:20',
            '2026년 07월 07일 10:56:56',
            '2026년 08월 07일 20:42:43',
            '2026년 09월 07일 23:41:15',
            '2026년 10월 08일 15:29:16',
            '2026년 11월 07일 18:52:03',
            '2026년 12월 07일 11:52:30',
            '2026년 01월 20일 10:44:54',
            '2026년 02월 19일 00:51:54',
            '2026년 03월 20일 23:45:56',
            '2026년 04월 20일 10:39:05',
            '2026년 05월 21일 09:36:43',
            '2026년 06월 21일 17:24:29',
            '2026년 07월 23일 04:13:03',
            '2026년 08월 23일 11:18:47',
            '2026년 09월 23일 09:05:11',
            '2026년 10월 23일 18:37:54',
            '2026년 11월 22일 16:23:19',
            '2026년 12월 22일 05:50:12',
        ]
    },
    {
        year : 2027,
        joonggi :[
            '2027년 01월 05일 23:09:56',
            '2027년 02월 04일 10:46:16',
            '2027년 03월 06일 04:39:29',
            '2027년 04월 05일 09:17:27',
            '2027년 05월 06일 02:25:09',
            '2027년 06월 06일 06:25:46',
            '2027년 07월 07일 16:37:01',
            '2027년 08월 08일 02:26:44',
            '2027년 09월 08일 05:28:24',
            '2027년 10월 08일 21:17:02',
            '2027년 11월 08일 00:38:31',
            '2027년 12월 07일 17:37:38',
            '2027년 01월 20일 16:29:49',
            '2027년 02월 19일 06:33:26',
            '2027년 03월 21일 05:24:39',
            '2027년 04월 20일 16:17:35',
            '2027년 05월 21일 15:18:12',
            '2027년 06월 21일 23:10:48',
            '2027년 07월 23일 10:04:37',
            '2027년 08월 23일 17:14:16',
            '2027년 09월 23일 15:01:41',
            '2027년 10월 24일 00:32:50',
            '2027년 11월 22일 22:16:11',
            '2027년 12월 22일 11:42:07',
        ]
    },
    {
        year : 2028,
        joonggi :[
            '2028년 01월 06일 04:54:36',
            '2028년 02월 04일 16:31:11',
            '2028년 03월 05일 10:24:45',
            '2028년 04월 04일 15:03:02',
            '2028년 05월 05일 08:12:08',
            '2028년 06월 05일 12:15:57',
            '2028년 07월 06일 22:30:15',
            '2028년 08월 07일 08:21:07',
            '2028년 09월 07일 11:22:05',
            '2028년 10월 08일 03:08:27',
            '2028년 11월 07일 06:27:12',
            '2028년 12월 06일 23:24:37',
            '2028년 01월 20일 22:21:53',
            '2028년 02월 19일 12:25:58',
            '2028년 03월 20일 11:17:05',
            '2028년 04월 19일 22:09:26',
            '2028년 05월 20일 21:09:45',
            '2028년 06월 21일 05:01:57',
            '2028년 07월 22일 15:53:56',
            '2028년 08월 22일 23:00:52',
            '2028년 09월 22일 20:45:15',
            '2028년 10월 23일 06:13:19',
            '2028년 11월 22일 03:54:20',
            '2028년 12월 21일 17:19:37',
        ]
    },
    {
        year : 2029,
        joonggi :[
            '2029년 01월 05일 10:41:51',
            '2029년 02월 03일 22:20:42',
            '2029년 03월 05일 16:17:32',
            '2029년 04월 04일 20:58:18',
            '2029년 05월 05일 14:07:41',
            '2029년 06월 05일 18:09:54',
            '2029년 07월 07일 04:22:20',
            '2029년 08월 07일 14:11:41',
            '2029년 09월 07일 17:11:49',
            '2029년 10월 08일 08:58:03',
            '2029년 11월 07일 12:16:41',
            '2029년 12월 07일 05:13:44',
            '2029년 01월 20일 04:00:49',
            '2029년 02월 18일 18:07:52',
            '2029년 03월 20일 17:01:55',
            '2029년 04월 20일 03:55:38',
            '2029년 05월 21일 02:55:48',
            '2029년 06월 21일 10:48:14',
            '2029년 07월 22일 21:42:02',
            '2029년 08월 23일 04:51:33',
            '2029년 09월 23일 02:38:26',
            '2029년 10월 23일 12:08:04',
            '2029년 11월 22일 09:49:19',
            '2029년 12월 21일 23:14:02',
        ]
    },
    {
        year : 2030,
        joonggi :[
            '2030년 01월 05일 16:30:30',
            '2030년 02월 04일 04:08:24',
            '2030년 03월 05일 22:03:14',
            '2030년 04월 05일 02:40:56',
            '2030년 05월 05일 19:46:14',
            '2030년 06월 05일 23:44:26',
            '2030년 07월 07일 09:55:25',
            '2030년 08월 07일 19:47:15',
            '2030년 09월 07일 22:52:45',
            '2030년 10월 08일 14:45:12',
            '2030년 11월 07일 18:08:39',
            '2030년 12월 07일 11:07:33',
            '2030년 01월 20일 09:54:19',
            '2030년 02월 18일 23:59:52',
            '2030년 03월 20일 22:52:01',
            '2030년 04월 20일 09:43:31',
            '2030년 05월 21일 08:41:01',
            '2030년 06월 21일 16:31:14',
            '2030년 07월 23일 03:24:49',
            '2030년 08월 23일 10:36:19',
            '2030년 09월 23일 08:26:49',
            '2030년 10월 23일 18:00:29',
            '2030년 11월 22일 15:44:32',
            '2030년 12월 22일 05:09:33',
        ]
    } ,


    {
        year : 2031,
        joonggi :[
            '2031년 01월 05일 22:23:03',
            '2031년 02월 04일 09:58:13',
            '2031년 03월 06일 03:50:56',
            '2031년 04월 05일 08:28:17',
            '2031년 05월 06일 01:35:06',
            '2031년 06월 06일 05:35:36',
            '2031년 07월 07일 15:48:46',
            '2031년 08월 08일 01:42:51',
            '2031년 09월 08일 04:50:04',
            '2031년 10월 08일 20:42:52',
            '2031년 11월 08일 00:05:35',
            '2031년 12월 07일 17:02:49',
            '2031년 01월 20일 15:47:53',
            '2031년 02월 19일 05:50:50',
            '2031년 03월 21일 04:40:53',
            '2031년 04월 20일 15:31:09',
            '2031년 05월 21일 14:27:50',
            '2031년 06월 21일 22:17:03',
            '2031년 07월 23일 09:10:23',
            '2031년 08월 23일 16:23:14',
            '2031년 09월 23일 14:15:13',
            '2031년 10월 23일 23:49:22',
            '2031년 11월 22일 21:32:32',
            '2031년 12월 22일 10:55:28',
        ]
    },
    {
        year : 2032,
        joonggi :[
            '2032년 01월 06일 04:16:00',
            '2032년 02월 04일 15:48:51',
            '2032년 03월 05일 09:40:07',
            '2032년 04월 04일 14:17:30',
            '2032년 05월 05일 07:25:46',
            '2032년 06월 05일 11:27:54',
            '2032년 07월 06일 21:40:49',
            '2032년 08월 07일 07:32:37',
            '2032년 09월 07일 10:37:48',
            '2032년 10월 08일 02:30:18',
            '2032년 11월 07일 05:54:10',
            '2032년 12월 06일 22:53:14',
            '2032년 01월 20일 21:31:12',
            '2032년 02월 19일 11:32:07',
            '2032년 03월 20일 10:21:48',
            '2032년 04월 19일 21:14:03',
            '2032년 05월 20일 20:14:54',
            '2032년 06월 21일 04:08:40',
            '2032년 07월 22일 15:04:39',
            '2032년 08월 22일 22:18:14',
            '2032년 09월 22일 20:10:47',
            '2032년 10월 23일 05:46:08',
            '2032년 11월 22일 03:31:06',
            '2032년 12월 21일 16:55:51',
        ]
    },

    {
        year : 2033,
        joonggi :[
            '2033년 01월 05일 10:08:00',
            '2033년 02월 03일 21:41:29',
            '2033년 03월 05일 15:32:14',
            '2033년 04월 04일 20:08:01',
            '2033년 05월 05일 13:13:40',
            '2033년 06월 05일 17:13:20',
            '2033년 07월 07일 03:24:51',
            '2033년 08월 07일 13:15:38',
            '2033년 09월 07일 16:20:15',
            '2033년 10월 08일 08:13:50',
            '2033년 11월 07일 11:40:58',
            '2033년 12월 07일 04:44:49',
            '2033년 01월 20일 03:32:41',
            '2033년 02월 18일 17:33:42',
            '2033년 03월 20일 16:22:37',
            '2033년 04월 20일 03:13:01',
            '2033년 05월 21일 02:10:52',
            '2033년 06월 21일 10:01:02',
            '2033년 07월 22일 20:52:42',
            '2033년 08월 23일 04:01:44',
            '2033년 09월 23일 01:51:34',
            '2033년 10월 23일 11:27:30',
            '2033년 11월 22일 09:16:04',
            '2033년 12월 21일 22:45:54',
        ]
    },

    {
        year : 2034,
        joonggi :[
            '2034년 01월 05일 16:04:24',
            '2034년 02월 04일 03:41:03',
            '2034년 03월 05일 21:32:16',
            '2034년 04월 05일 02:06:07',
            '2034년 05월 05일 19:09:02',
            '2034년 06월 05일 23:06:33',
            '2034년 07월 07일 09:17:31',
            '2034년 08월 07일 19:08:59',
            '2034년 09월 07일 22:13:52',
            '2034년 10월 08일 14:07:00',
            '2034년 11월 07일 17:33:33',
            '2034년 12월 07일 10:36:42',
            '2034년 01월 20일 09:27:10',
            '2034년 02월 18일 23:30:05',
            '2034년 03월 20일 22:17:23',
            '2034년 04월 20일 09:03:37',
            '2034년 05월 21일 07:56:47',
            '2034년 06월 21일 15:44:05',
            '2034년 07월 23일 02:36:14',
            '2034년 08월 23일 09:47:39',
            '2034년 09월 23일 07:39:27',
            '2034년 10월 23일 17:16:20',
            '2034년 11월 22일 15:04:51',
            '2034년 12월 22일 04:33:53',
        ]
    },
    {
        year : 2035,
        joonggi :[
            '2035년 01월 05일 21:55:35',
            '2035년 02월 04일 09:31:26',
            '2035년 03월 06일 03:21:31',
            '2035년 04월 05일 07:53:44',
            '2035년 05월 06일 00:54:49',
            '2035년 06월 06일 04:50:42',
            '2035년 07월 07일 15:01:02',
            '2035년 08월 08일 00:54:13',
            '2035년 09월 08일 04:02:22',
            '2035년 10월 08일 19:57:33',
            '2035년 11월 07일 23:23:43',
            '2035년 12월 07일 16:25:23',
            '2035년 01월 20일 15:14:08',
            '2035년 02월 19일 05:16:02',
            '2035년 03월 21일 04:02:36',
            '2035년 04월 20일 14:48:52',
            '2035년 05월 21일 13:43:20',
            '2035년 06월 21일 21:33:00',
            '2035년 07월 23일 08:28:33',
            '2035년 08월 23일 15:44:02',
            '2035년 09월 23일 13:38:48',
            '2035년 10월 23일 23:16:02',
            '2035년 11월 22일 21:03:05',
            '2035년 12월 22일 10:30:45',
        ]
    },
    {
        year : 2036,
        joonggi :[
            '2036년 01월 06일 03:43:22',
            '2036년 02월 04일 15:19:47',
            '2036년 03월 05일 09:11:41',
            '2036년 04월 04일 13:46:08',
            '2036년 05월 05일 06:49:15',
            '2036년 06월 05일 10:46:52',
            '2036년 07월 06일 20:57:24',
            '2036년 08월 07일 06:48:47',
            '2036년 09월 07일 09:54:50',
            '2036년 10월 08일 01:48:49',
            '2036년 11월 07일 05:14:31',
            '2036년 12월 06일 22:15:53',
            '2036년 01월 20일 21:10:54',
            '2036년 02월 19일 11:14:09',
            '2036년 03월 20일 10:02:42',
            '2036년 04월 19일 20:50:21',
            '2036년 05월 20일 19:44:45',
            '2036년 06월 21일 03:32:05',
            '2036년 07월 22일 14:22:32',
            '2036년 08월 22일 21:32:16',
            '2036년 09월 22일 19:23:11',
            '2036년 10월 23일 04:58:42',
            '2036년 11월 22일 02:45:10',
            '2036년 12월 21일 16:12:44',

        ]
    },
    {
        year : 2037,
        joonggi :[
            '2037년 01월 05일 09:33:54',
            '2037년 02월 03일 21:11:28',
            '2037년 03월 05일 15:06:02',
            '2037년 04월 04일 19:43:53',
            '2037년 05월 05일 12:49:19',
            '2037년 06월 05일 16:46:42',
            '2037년 07월 07일 02:55:00',
            '2037년 08월 07일 12:42:54',
            '2037년 09월 07일 15:45:26',
            '2037년 10월 08일 07:37:41',
            '2037년 11월 07일 11:03:56',
            '2037년 12월 07일 04:07:09',
            '2037년 01월 20일 02:53:35',
            '2037년 02월 18일 16:58:46',
            '2037년 03월 20일 15:50:07',
            '2037년 04월 20일 02:40:10',
            '2037년 05월 21일 01:35:20',
            '2037년 06월 21일 09:22:18',
            '2037년 07월 22일 20:12:27',
            '2037년 08월 23일 03:21:52',
            '2037년 09월 23일 01:12:56',
            '2037년 10월 23일 10:49:44',
            '2037년 11월 22일 08:38:15',
            '2037년 12월 21일 22:07:36',
        ]
    },
    {
        year : 2038,
        joonggi :[
            '2038년 01월 05일 15:26:39',
            '2038년 02월 04일 03:03:39',
            '2038년 03월 05일 20:55:20',
            '2038년 04월 05일 01:29:19',
            '2038년 05월 05일 18:31:03',
            '2038년 06월 05일 22:25:29',
            '2038년 07월 07일 08:32:22',
            '2038년 08월 07일 18:21:09',
            '2038년 09월 07일 21:26:08',
            '2038년 10월 08일 13:21:26',
            '2038년 11월 07일 16:50:42',
            '2038년 12월 07일 09:56:14',
            '2038년 01월 20일 08:48:42',
            '2038년 02월 18일 22:51:58',
            '2038년 03월 20일 21:40:28',
            '2038년 04월 20일 08:28:23',
            '2038년 05월 21일 07:22:33',
            '2038년 06월 21일 15:09:14',
            '2038년 07월 23일 01:59:45',
            '2038년 08월 23일 09:10:00',
            '2038년 09월 23일 07:02:07',
            '2038년 10월 23일 16:40:32',
            '2038년 11월 22일 14:31:10',
            '2038년 12월 22일 04:02:10',
        ]
    },
    {
        year : 2039,
        joonggi :[
            '2039년 01월 05일 21:16:30',
            '2039년 02월 04일 08:52:45',
            '2039년 03월 06일 02:42:53',
            '2039년 04월 05일 07:15:38',
            '2039년 05월 06일 00:17:59',
            '2039년 06월 06일 04:15:19',
            '2039년 07월 07일 14:25:59',
            '2039년 08월 08일 00:17:56',
            '2039년 09월 08일 03:23:53',
            '2039년 10월 08일 19:17:08',
            '2039년 11월 07일 22:42:45',
            '2039년 12월 07일 15:44:56',
            '2039년 01월 20일 14:43:31',
            '2039년 02월 19일 04:45:35',
            '2039년 03월 21일 03:31:52',
            '2039년 04월 20일 14:17:37',
            '2039년 05월 21일 13:10:43',
            '2039년 06월 21일 20:57:15',
            '2039년 07월 23일 07:48:00',
            '2039년 08월 23일 14:58:30',
            '2039년 09월 23일 12:49:27',
            '2039년 10월 23일 22:24:54',
            '2039년 11월 22일 20:12:02',
            '2039년 12월 22일 09:40:26',
        ]
    },
    {
        year : 2040,
        joonggi :[
            '2040년 01월 06일 03:03:26',
            '2040년 02월 04일 14:39:43',
            '2040년 03월 05일 08:31:03',
            '2040년 04월 04일 13:05:21',
            '2040년 05월 05일 06:09:11',
            '2040년 06월 05일 10:07:52',
            '2040년 07월 06일 20:19:04',
            '2040년 08월 07일 06:09:53',
            '2040년 09월 07일 09:13:56',
            '2040년 10월 08일 01:05:21',
            '2040년 11월 07일 04:29:07',
            '2040년 12월 06일 21:29:53',
            '2040년 01월 20일 20:20:52',
            '2040년 02월 19일 10:23:39',
            '2040년 03월 20일 09:11:31',
            '2040년 04월 19일 19:59:23',
            '2040년 05월 20일 18:55:33',
            '2040년 06월 21일 02:46:13',
            '2040년 07월 22일 13:40:39',
            '2040년 08월 22일 20:53:09',
            '2040년 09월 22일 18:44:45',
            '2040년 10월 23일 04:19:34',
            '2040년 11월 22일 02:05:20',
            '2040년 12월 21일 15:32:40',
        ]
    } ,


    {
        year : 2041,
        joonggi :[
            '2041년 01월 05일 08:47:56',
            '2041년 02월 03일 20:24:58',
            '2041년 03월 05일 14:17:40',
            '2041년 04월 04일 18:52:25',
            '2041년 05월 05일 11:54:19',
            '2041년 06월 05일 15:49:35',
            '2041년 07월 07일 01:58:19',
            '2041년 08월 07일 11:48:31',
            '2041년 09월 07일 14:53:24',
            '2041년 10월 08일 06:46:47',
            '2041년 11월 07일 10:12:56',
            '2041년 12월 07일 03:15:37',
            '2041년 01월 20일 02:13:07',
            '2041년 02월 18일 16:17:06',
            '2041년 03월 20일 15:06:38',
            '2041년 04월 20일 01:54:45',
            '2041년 05월 21일 00:48:40',
            '2041년 06월 21일 08:35:41',
            '2041년 07월 22일 19:26:30',
            '2041년 08월 23일 02:36:05',
            '2041년 09월 23일 00:26:23',
            '2041년 10월 23일 10:01:47',
            '2041년 11월 22일 07:49:08',
            '2041년 12월 21일 21:18:10',
        ]
    },
    {
        year : 2042,
        joonggi :[
            '2042년 01월 05일 14:34:57',
            '2042년 02월 04일 02:12:40',
            '2042년 03월 05일 20:05:36',
            '2042년 04월 05일 00:40:27',
            '2042년 05월 05일 17:42:39',
            '2042년 06월 05일 21:38:02',
            '2042년 07월 07일 07:47:06',
            '2042년 08월 07일 17:38:37',
            '2042년 09월 07일 20:45:17',
            '2042년 10월 08일 12:40:23',
            '2042년 11월 07일 16:07:28',
            '2042년 12월 07일 09:09:03',
            '2042년 01월 20일 07:59:53',
            '2042년 02월 18일 22:04:18',
            '2042년 03월 20일 20:53:08',
            '2042년 04월 20일 07:39:35',
            '2042년 05월 21일 06:31:04',
            '2042년 06월 21일 14:15:39',
            '2042년 07월 23일 01:06:09',
            '2042년 08월 23일 08:17:57',
            '2042년 09월 23일 06:11:22',
            '2042년 10월 23일 15:49:23',
            '2042년 11월 22일 13:37:12',
            '2042년 12월 22일 03:03:53',
        ]
    },

    {
        year : 2043,
        joonggi :[
            '2043년 01월 05일 20:25:10',
            '2043년 02월 04일 07:58:35',
            '2043년 03월 06일 01:47:36',
            '2043년 04월 05일 06:20:05',
            '2043년 05월 05일 23:21:53',
            '2043년 06월 06일 03:17:58',
            '2043년 07월 07일 13:27:39',
            '2043년 08월 07일 23:20:35',
            '2043년 09월 08일 02:30:00',
            '2043년 10월 08일 18:27:32',
            '2043년 11월 07일 21:55:39',
            '2043년 12월 07일 14:57:12',
            '2043년 01월 20일 13:41:24',
            '2043년 02월 19일 03:41:29',
            '2043년 03월 21일 02:27:36',
            '2043년 04월 20일 13:14:15',
            '2043년 05월 21일 12:08:57',
            '2043년 06월 21일 19:58:11',
            '2043년 07월 23일 06:53:16',
            '2043년 08월 23일 14:09:36',
            '2043년 09월 23일 12:06:45',
            '2043년 10월 23일 21:46:37',
            '2043년 11월 22일 19:34:53',
            '2043년 12월 22일 09:01:03',
        ]
    },

    {
        year : 2044,
        joonggi :[
            '2044년 01월 06일 02:12:19',
            '2044년 02월 04일 13:44:06',
            '2044년 03월 05일 07:31:24',
            '2044년 04월 04일 12:02:53',
            '2044년 05월 05일 05:05:16',
            '2044년 06월 05일 09:03:49',
            '2044년 07월 06일 19:15:43',
            '2044년 08월 07일 05:08:25',
            '2044년 09월 07일 08:16:19',
            '2044년 10월 08일 00:13:06',
            '2044년 11월 07일 03:41:47',
            '2044년 12월 06일 20:45:00',
            '2044년 01월 20일 19:37:14',
            '2044년 02월 19일 09:35:37',
            '2044년 03월 20일 08:20:22',
            '2044년 04월 19일 19:06:32',
            '2044년 05월 20일 18:01:41',
            '2044년 06월 21일 01:50:57',
            '2044년 07월 22일 12:43:10',
            '2044년 08월 22일 19:54:29',
            '2044년 09월 22일 17:47:41',
            '2044년 10월 23일 03:26:07',
            '2044년 11월 22일 01:15:07',
            '2044년 12월 21일 14:43:25',
        ]
    },
    {
        year : 2045,
        joonggi :[
            '2045년 01월 05일 08:02:19',
            '2045년 02월 03일 19:36:05',
            '2045년 03월 05일 13:24:50',
            '2045년 04월 04일 17:57:05',
            '2045년 05월 05일 10:59:19',
            '2045년 06월 05일 14:56:49',
            '2045년 07월 07일 01:07:54',
            '2045년 08월 07일 10:59:29',
            '2045년 09월 07일 14:05:17',
            '2045년 10월 08일 06:00:28',
            '2045년 11월 07일 09:29:40',
            '2045년 12월 07일 02:35:23',
            '2045년 01월 20일 01:22:01',
            '2045년 02월 18일 15:22:10',
            '2045년 03월 20일 14:07:26',
            '2045년 04월 20일 00:52:40',
            '2045년 05월 20일 23:45:48',
            '2045년 06월 21일 07:33:43',
            '2045년 07월 22일 18:26:37',
            '2045년 08월 23일 01:38:58',
            '2045년 09월 22일 23:32:44',
            '2045년 10월 23일 09:12:20',
            '2045년 11월 22일 07:03:40',
            '2045년 12월 21일 20:34:56',
        ]
    },
    {
        year : 2046,
        joonggi :[
            '2046년 01월 05일 13:55:48',
            '2046년 02월 04일 01:30:53',
            '2046년 03월 05일 19:17:35',
            '2046년 04월 04일 23:44:45',
            '2046년 05월 05일 16:40:29',
            '2046년 06월 05일 20:32:03',
            '2046년 07월 07일 06:40:06',
            '2046년 08월 07일 16:33:08',
            '2046년 09월 07일 19:43:06',
            '2046년 10월 08일 11:42:13',
            '2046년 11월 07일 15:13:58',
            '2046년 12월 07일 08:21:06',
            '2046년 01월 20일 07:15:41',
            '2046년 02월 18일 21:15:26',
            '2046년 03월 20일 19:57:40',
            '2046년 04월 20일 06:38:43',
            '2046년 05월 21일 05:28:17',
            '2046년 06월 21일 13:14:28',
            '2046년 07월 23일 00:08:30',
            '2046년 08월 23일 07:24:20',
            '2046년 09월 23일 05:21:32',
            '2046년 10월 23일 15:03:22',
            '2046년 11월 22일 12:56:05',
            '2046년 12월 22일 02:28:18',
        ]
    },
    {
        year : 2047,
        joonggi :[
            '2047년 01월 05일 19:42:11',
            '2047년 02월 04일 07:17:50',
            '2047년 03월 06일 01:05:07',
            '2047년 04월 05일 05:32:30',
            '2047년 05월 05일 22:28:20',
            '2047년 06월 06일 02:20:40',
            '2047년 07월 07일 12:30:18',
            '2047년 08월 07일 22:25:42',
            '2047년 09월 08일 01:37:58',
            '2047년 10월 08일 17:37:30',
            '2047년 11월 07일 21:07:09',
            '2047년 12월 07일 14:10:51',
            '2047년 01월 20일 13:09:46',
            '2047년 02월 19일 03:10:11',
            '2047년 03월 21일 01:52:28',
            '2047년 04월 20일 12:32:20',
            '2047년 05월 21일 11:19:43',
            '2047년 06월 21일 19:03:18',
            '2047년 07월 23일 05:55:18',
            '2047년 08월 23일 13:10:41',
            '2047년 09월 23일 11:07:54',
            '2047년 10월 23일 20:48:22',
            '2047년 11월 22일 18:38:05',
            '2047년 12월 22일 08:07:03',
        ]
    },
    {
        year : 2048,
        joonggi :[
            '2048년 01월 06일 01:29:11',
            '2048년 02월 04일 13:04:25',
            '2048년 03월 05일 06:53:55',
            '2048년 04월 04일 11:25:04',
            '2048년 05월 05일 04:24:18',
            '2048년 06월 05일 08:18:07',
            '2048년 07월 06일 18:26:36',
            '2048년 08월 07일 04:18:40',
            '2048년 09월 07일 07:27:54',
            '2048년 10월 07일 23:26:32',
            '2048년 11월 07일 02:56:39',
            '2048년 12월 06일 20:00:38',
            '2048년 01월 20일 18:46:55',
            '2048년 02월 19일 08:48:18',
            '2048년 03월 20일 07:33:38',
            '2048년 04월 19일 18:17:15',
            '2048년 05월 20일 17:07:51',
            '2048년 06월 21일 00:53:44',
            '2048년 07월 22일 11:46:45',
            '2048년 08월 22일 19:02:19',
            '2048년 09월 22일 17:00:27',
            '2048년 10월 23일 02:42:32',
            '2048년 11월 22일 00:33:19',
            '2048년 12월 21일 14:02:05',
        ]
    },
    {
        year : 2049,
        joonggi :[
            '2049년 01월 05일 07:18:31',
            '2049년 02월 03일 18:53:11',
            '2049년 03월 05일 12:42:44',
            '2049년 04월 04일 17:14:11',
            '2049년 05월 05일 10:12:27',
            '2049년 06월 05일 14:03:33',
            '2049년 07월 07일 00:08:38',
            '2049년 08월 07일 09:57:44',
            '2049년 09월 07일 13:05:21',
            '2049년 10월 08일 05:04:50',
            '2049년 11월 07일 08:38:13',
            '2049년 12월 07일 01:46:28',
            '2049년 01월 20일 00:41:01',
            '2049년 02월 18일 14:42:05',
            '2049년 03월 20일 13:28:25',
            '2049년 04월 20일 00:13:16',
            '2049년 05월 20일 23:03:37',
            '2049년 06월 21일 06:47:08',
            '2049년 07월 22일 17:36:07',
            '2049년 08월 23일 00:47:09',
            '2049년 09월 22일 22:42:25',
            '2049년 10월 23일 08:25:01',
            '2049년 11월 22일 06:19:07',
            '2049년 12월 21일 19:52:00',
        ]
    },
    {
        year : 2050,
        joonggi :[
            '2050년 01월 05일 13:07:17',
            '2050년 02월 04일 00:43:11',
            '2050년 03월 05일 18:32:05',
            '2050년 04월 04일 23:02:37',
            '2050년 05월 05일 16:01:22',
            '2050년 06월 05일 19:54:15',
            '2050년 07월 07일 06:01:19',
            '2050년 08월 07일 15:51:55',
            '2050년 09월 07일 19:00:04',
            '2050년 10월 08일 10:59:34',
            '2050년 11월 07일 14:33:06',
            '2050년 12월 07일 07:41:13',
            '2050년 01월 20일 06:33:13',
            '2050년 02월 18일 20:34:30',
            '2050년 03월 20일 19:19:00',
            '2050년 04월 20일 06:01:36',
            '2050년 05월 21일 04:50:15',
            '2050년 06월 21일 12:32:27',
            '2050년 07월 22일 23:20:49',
            '2050년 08월 23일 06:32:06',
            '2050년 09월 23일 04:27:57',
            '2050년 10월 23일 14:11:16',
            '2050년 11월 22일 12:05:46',
            '2050년 12월 22일 01:38:08',
        ]
    } ,


    {
        year : 2051,
        joonggi :[
            '2051년 01월 05일 19:01:37',
            '2051년 02월 04일 06:35:32',
            '2051년 03월 06일 00:21:28',
            '2051년 04월 05일 04:49:06',
            '2051년 05월 05일 21:46:32',
            '2051년 06월 06일 01:40:08',
            '2051년 07월 07일 11:48:53',
            '2051년 08월 07일 21:41:16',
            '2051년 09월 08일 00:50:45',
            '2051년 10월 08일 16:49:54',
            '2051년 11월 07일 20:21:35',
            '2051년 12월 07일 13:28:05',
            '2051년 01월 20일 12:18:12',
            '2051년 02월 19일 02:16:52',
            '2051년 03월 21일 00:58:36',
            '2051년 04월 20일 11:40:04',
            '2051년 05월 21일 10:30:50',
            '2051년 06월 21일 18:18:04',
            '2051년 07월 23일 05:12:23',
            '2051년 08월 23일 12:28:34',
            '2051년 09월 23일 10:26:48',
            '2051년 10월 23일 20:09:32',
            '2051년 11월 22일 18:02:17',
            '2051년 12월 22일 07:33:34',
        ]
    },
    {
        year : 2052,
        joonggi :[
            '2052년 01월 06일 00:47:58',
            '2052년 02월 04일 12:22:23',
            '2052년 03월 05일 06:08:57',
            '2052년 04월 04일 10:36:44',
            '2052년 05월 05일 03:34:13',
            '2052년 06월 05일 07:28:54',
            '2052년 07월 06일 17:39:25',
            '2052년 08월 07일 03:32:40',
            '2052년 09월 07일 06:41:34',
            '2052년 10월 07일 22:39:15',
            '2052년 11월 07일 02:09:18',
            '2052년 12월 06일 19:14:56',
            '2052년 01월 20일 18:13:38',
            '2052년 02월 19일 08:13:03',
            '2052년 03월 20일 06:55:31',
            '2052년 04월 19일 17:37:22',
            '2052년 05월 20일 16:28:26',
            '2052년 06월 21일 00:15:39',
            '2052년 07월 22일 11:08:18',
            '2052년 08월 22일 18:20:55',
            '2052년 09월 22일 16:15:09',
            '2052년 10월 23일 01:54:38',
            '2052년 11월 21일 23:45:27',
            '2052년 12월 21일 13:16:39',
        ]
    },

    {
        year : 2053,
        joonggi :[
            '2053년 01월 05일 06:35:33',
            '2053년 02월 03일 18:12:29',
            '2053년 03월 05일 12:02:44',
            '2053년 04월 04일 16:33:55',
            '2053년 05월 05일 09:33:01',
            '2053년 06월 05일 13:27:04',
            '2053년 07월 06일 23:36:34',
            '2053년 08월 07일 09:29:26',
            '2053년 09월 07일 12:38:05',
            '2053년 10월 08일 04:35:32',
            '2053년 11월 07일 08:05:36',
            '2053년 12월 07일 01:11:19',
            '2053년 01월 19일 23:58:40',
            '2053년 02월 18일 14:01:20',
            '2053년 03월 20일 12:46:49',
            '2053년 04월 19일 23:29:42',
            '2053년 05월 20일 22:19:00',
            '2053년 06월 21일 06:03:35',
            '2053년 07월 22일 16:55:39',
            '2053년 08월 23일 00:09:41',
            '2053년 09월 22일 22:05:45',
            '2053년 10월 23일 07:46:41',
            '2053년 11월 22일 05:38:14',
            '2053년 12월 21일 19:09:23',
        ]
    },

    {
        year : 2054,
        joonggi :[
            '2054년 01월 05일 12:31:43',
            '2054년 02월 04일 00:07:22',
            '2054년 03월 05일 17:54:58',
            '2054년 04월 04일 22:22:30',
            '2054년 05월 05일 15:17:17',
            '2054년 06월 05일 19:06:55',
            '2054년 07월 07일 05:13:14',
            '2054년 08월 07일 15:06:25',
            '2054년 09월 07일 18:19:01',
            '2054년 10월 08일 10:21:40',
            '2054년 11월 07일 13:55:45',
            '2054년 12월 07일 07:02:53',
            '2054년 01월 20일 05:50:27',
            '2054년 02월 18일 19:51:01',
            '2054년 03월 20일 18:33:55',
            '2054년 04월 20일 05:14:32',
            '2054년 05월 21일 04:02:26',
            '2054년 06월 21일 11:46:38',
            '2054년 07월 22일 22:40:04',
            '2054년 08월 23일 05:57:54',
            '2054년 09월 23일 03:58:57',
            '2054년 10월 23일 13:44:18',
            '2054년 11월 22일 11:38:21',
            '2054년 12월 22일 01:09:26',
        ]
    },
    {
        year : 2055,
        joonggi :[
            '2055년 01월 05일 18:22:01',
            '2055년 02월 04일 05:55:15',
            '2055년 03월 05일 23:40:54',
            '2055년 04월 05일 04:07:42',
            '2055년 05월 05일 21:03:19',
            '2055년 06월 06일 00:55:20',
            '2055년 07월 07일 11:04:41',
            '2055년 08월 07일 21:00:30',
            '2055년 09월 08일 00:15:00',
            '2055년 10월 08일 16:18:30',
            '2055년 11월 07일 19:52:13',
            '2055년 12월 07일 12:57:57',
            '2055년 01월 20일 11:48:28',
            '2055년 02월 19일 01:46:46',
            '2055년 03월 21일 00:28:06',
            '2055년 04월 20일 11:08:00',
            '2055년 05월 21일 09:55:40',
            '2055년 06월 21일 17:39:21',
            '2055년 07월 23일 04:31:30',
            '2055년 08월 23일 11:48:02',
            '2055년 09월 23일 09:48:14',
            '2055년 10월 23일 19:32:47',
            '2055년 11월 22일 17:25:39',
            '2055년 12월 22일 06:55:06',
        ]
    },
    {
        year : 2056,
        joonggi :[
            '2056년 01월 06일 00:15:07',
            '2056년 02월 04일 11:46:36',
            '2056년 03월 05일 05:31:36',
            '2056년 04월 04일 09:59:27',
            '2056년 05월 05일 02:57:26',
            '2056년 06월 05일 06:51:47',
            '2056년 07월 06일 17:01:49',
            '2056년 08월 07일 02:55:32',
            '2056년 09월 07일 06:06:47',
            '2056년 10월 07일 22:08:34',
            '2056년 11월 07일 01:42:48',
            '2056년 12월 06일 18:50:23',
            '2056년 01월 20일 17:32:24',
            '2056년 02월 19일 07:29:25',
            '2056년 03월 20일 06:10:33',
            '2056년 04월 19일 16:51:35',
            '2056년 05월 20일 15:41:25',
            '2056년 06월 20일 23:27:42',
            '2056년 07월 22일 10:21:43',
            '2056년 08월 22일 17:38:32',
            '2056년 09월 22일 15:39:00',
            '2056년 10월 23일 01:24:50',
            '2056년 11월 21일 23:19:43',
            '2056년 12월 21일 12:51:07',
        ]
    },
    {
        year : 2057,
        joonggi :[
            '2057년 01월 05일 06:09:31',
            '2057년 02월 03일 17:41:59',
            '2057년 03월 05일 11:26:28',
            '2057년 04월 04일 15:52:05',
            '2057년 05월 05일 08:46:07',
            '2057년 06월 05일 12:35:46',
            '2057년 07월 06일 22:41:54',
            '2057년 08월 07일 08:33:23',
            '2057년 09월 07일 11:43:34',
            '2057년 10월 08일 03:45:39',
            '2057년 11월 07일 07:22:17',
            '2057년 12월 07일 00:34:08',
            '2057년 01월 19일 23:29:37',
            '2057년 02월 18일 13:26:52',
            '2057년 03월 20일 12:07:24',
            '2057년 04월 19일 22:46:59',
            '2057년 05월 20일 21:34:42',
            '2057년 06월 21일 05:18:34',
            '2057년 07월 22일 16:10:11',
            '2057년 08월 22일 23:24:30',
            '2057년 09월 22일 21:22:47',
            '2057년 10월 23일 07:08:30',
            '2057년 11월 22일 05:06:12',
            '2057년 12월 21일 18:42:22',
        ]
    },
    {
        year : 2058,
        joonggi :[
            '2058년 01월 05일 11:58:00',
            '2058년 02월 03일 23:33:58',
            '2058년 03월 05일 17:19:21',
            '2058년 04월 04일 21:43:27',
            '2058년 05월 05일 14:35:28',
            '2058년 06월 05일 18:24:13',
            '2058년 07월 07일 04:31:00',
            '2058년 08월 07일 14:24:45',
            '2058년 09월 07일 17:37:30',
            '2058년 10월 08일 09:40:42',
            '2058년 11월 07일 13:16:36',
            '2058년 12월 07일 06:26:35',
            '2058년 01월 20일 05:25:31',
            '2058년 02월 18일 19:25:08',
            '2058년 03월 20일 18:04:31',
            '2058년 04월 20일 04:40:22',
            '2058년 05월 21일 03:23:33',
            '2058년 06월 21일 11:03:34',
            '2058년 07월 22일 21:53:18',
            '2058년 08월 23일 05:08:15',
            '2058년 09월 23일 03:07:55',
            '2058년 10월 23일 12:53:55',
            '2058년 11월 22일 10:50:17',
            '2058년 12월 22일 00:24:30',
        ]
    },
    {
        year : 2059,
        joonggi :[
            '2059년 01월 05일 17:48:40',
            '2059년 02월 04일 05:23:27',
            '2059년 03월 05일 23:08:13',
            '2059년 04월 05일 03:31:56',
            '2059년 05월 05일 20:23:27',
            '2059년 06월 06일 00:11:46',
            '2059년 07월 07일 10:18:19',
            '2059년 08월 07일 20:12:14',
            '2059년 09월 07일 23:26:04',
            '2059년 10월 08일 15:30:07',
            '2059년 11월 07일 19:05:10',
            '2059년 12월 07일 12:13:06',
            '2059년 01월 20일 11:06:02',
            '2059년 02월 19일 01:04:43',
            '2059년 03월 20일 23:43:45',
            '2059년 04월 20일 10:19:45',
            '2059년 05월 21일 09:04:04',
            '2059년 06월 21일 16:46:47',
            '2059년 07월 23일 03:40:25',
            '2059년 08월 23일 10:59:41',
            '2059년 09월 23일 09:03:03',
            '2059년 10월 23일 18:50:17',
            '2059년 11월 22일 16:45:26',
            '2059년 12월 22일 06:17:29',
        ]
    },
    {
        year : 2060,
        joonggi :[
            '2060년 01월 05일 23:33:21',
            '2060년 02월 04일 11:07:40',
            '2060년 03월 05일 04:53:35',
            '2060년 04월 04일 09:19:12',
            '2060년 05월 05일 02:12:16',
            '2060년 06월 05일 06:01:03',
            '2060년 07월 06일 16:06:46',
            '2060년 08월 07일 01:58:39',
            '2060년 09월 07일 05:10:07',
            '2060년 10월 07일 21:13:01',
            '2060년 11월 07일 00:48:22',
            '2060년 12월 06일 17:57:04',
            '2060년 01월 20일 16:57:38',
            '2060년 02월 19일 06:56:45',
            '2060년 03월 20일 05:38:01',
            '2060년 04월 19일 16:16:51',
            '2060년 05월 20일 15:03:00',
            '2060년 06월 20일 22:45:09',
            '2060년 07월 22일 09:35:15',
            '2060년 08월 22일 16:49:03',
            '2060년 09월 22일 14:47:43',
            '2060년 10월 23일 00:32:56',
            '2060년 11월 21일 22:28:09',
            '2060년 12월 21일 12:00:58',
        ]
    } ,


    {
        year : 2061,
        joonggi :[
            '2061년 01월 05일 05:17:55',
            '2061년 02월 03일 16:53:13',
            '2061년 03월 05일 10:41:09',
            '2061년 04월 04일 15:09:53',
            '2061년 05월 05일 08:05:59',
            '2061년 06월 05일 11:56:08',
            '2061년 07월 06일 22:01:36',
            '2061년 08월 07일 07:52:21',
            '2061년 09월 07일 11:02:03',
            '2061년 10월 08일 03:03:38',
            '2061년 11월 07일 06:39:23',
            '2061년 12월 06일 23:49:54',
            '2061년 01월 19일 22:42:07',
            '2061년 02월 18일 12:42:41',
            '2061년 03월 20일 11:25:47',
            '2061년 04월 19일 22:06:02',
            '2061년 05월 20일 20:51:47',
            '2061년 06월 21일 04:31:45',
            '2061년 07월 22일 15:19:52',
            '2061년 08월 22일 22:32:39',
            '2061년 09월 22일 20:30:55',
            '2061년 10월 23일 06:16:45',
            '2061년 11월 22일 04:13:43',
            '2061년 12월 21일 17:48:23',
        ]
    },
    {
        year : 2062,
        joonggi :[
            '2062년 01월 05일 11:12:13',
            '2062년 02월 03일 22:46:32',
            '2062년 03월 05일 16:30:54',
            '2062년 04월 04일 20:54:56',
            '2062년 05월 05일 13:46:56',
            '2062년 06월 05일 17:34:18',
            '2062년 07월 07일 03:37:57',
            '2062년 08월 07일 13:28:27',
            '2062년 09월 07일 16:39:57',
            '2062년 10월 08일 08:44:05',
            '2062년 11월 07일 12:22:03',
            '2062년 12월 07일 05:34:01',
            '2062년 01월 20일 04:29:42',
            '2062년 02월 18일 18:27:48',
            '2062년 03월 20일 17:07:01',
            '2062년 04월 20일 03:44:09',
            '2062년 05월 21일 02:29:18',
            '2062년 06월 21일 10:10:55',
            '2062년 07월 22일 21:01:42',
            '2062년 08월 23일 04:17:50',
            '2062년 09월 23일 02:19:27',
            '2062년 10월 23일 12:07:54',
            '2062년 11월 22일 10:06:40',
            '2062년 12월 21일 23:42:13',
        ]
    },

    {
        year : 2063,
        joonggi :[
            '2063년 01월 05일 16:56:45',
            '2063년 02월 04일 04:30:41',
            '2063년 03월 05일 22:13:54',
            '2063년 04월 05일 02:36:26',
            '2063년 05월 05일 19:27:51',
            '2063년 06월 05일 23:17:06',
            '2063년 07월 07일 09:24:59',
            '2063년 08월 07일 19:19:38',
            '2063년 09월 07일 22:33:03',
            '2063년 10월 08일 14:36:25',
            '2063년 11월 07일 18:11:36',
            '2063년 12월 07일 11:20:18',
            '2063년 01월 20일 10:23:30',
            '2063년 02월 19일 00:20:50',
            '2063년 03월 20일 22:58:43',
            '2063년 04월 20일 09:34:31',
            '2063년 05월 21일 08:19:09',
            '2063년 06월 21일 16:01:28',
            '2063년 07월 23일 02:52:54',
            '2063년 08월 23일 10:08:21',
            '2063년 09월 23일 08:07:46',
            '2063년 10월 23일 17:52:51',
            '2063년 11월 22일 15:47:55',
            '2063년 12월 22일 05:20:40',
        ]
    },

    {
        year : 2064,
        joonggi :[
            '2064년 01월 05일 22:40:48',
            '2064년 02월 04일 10:14:20',
            '2064년 03월 05일 03:58:54',
            '2064년 04월 04일 08:23:52',
            '2064년 05월 05일 01:18:01',
            '2064년 06월 05일 05:09:39',
            '2064년 07월 06일 15:19:10',
            '2064년 08월 07일 01:13:53',
            '2064년 09월 07일 04:25:54',
            '2064년 10월 07일 20:27:30',
            '2064년 11월 07일 00:01:11',
            '2064년 12월 06일 17:08:50',
            '2064년 01월 20일 16:00:54',
            '2064년 02월 19일 05:58:52',
            '2064년 03월 20일 04:38:11',
            '2064년 04월 19일 15:15:24',
            '2064년 05월 20일 14:01:17',
            '2064년 06월 20일 21:45:14',
            '2064년 07월 22일 08:38:56',
            '2064년 08월 22일 15:56:13',
            '2064년 09월 22일 13:56:37',
            '2064년 10월 22일 23:41:49',
            '2064년 11월 21일 21:36:19',
            '2064년 12월 21일 11:08:20',
        ]
    },
    {
        year : 2065,
        joonggi :[
            '2065년 01월 05일 04:29:04',
            '2065년 02월 03일 16:03:12',
            '2065년 03월 05일 09:48:40',
            '2065년 04월 04일 14:13:27',
            '2065년 05월 05일 07:04:56',
            '2065년 06월 05일 10:51:44',
            '2065년 07월 06일 20:56:24',
            '2065년 08월 07일 06:48:50',
            '2065년 09월 07일 10:01:29',
            '2065년 10월 08일 02:05:28',
            '2065년 11월 07일 05:42:09',
            '2065년 12월 06일 22:52:26',
            '2065년 01월 19일 21:48:24',
            '2065년 02월 18일 11:47:09',
            '2065년 03월 20일 10:27:44',
            '2065년 04월 19일 21:05:25',
            '2065년 05월 20일 19:50:15',
            '2065년 06월 21일 03:32:03',
            '2065년 07월 22일 14:23:58',
            '2065년 08월 22일 21:40:54',
            '2065년 09월 22일 19:42:11',
            '2065년 10월 23일 05:29:15',
            '2065년 11월 22일 03:26:09',
            '2065년 12월 21일 17:00:21',
        ]
    },
    {
        year : 2066,
        joonggi :[
            '2066년 01월 05일 10:14:19',
            '2066년 02월 03일 21:48:53',
            '2066년 03월 05일 15:33:39',
            '2066년 04월 04일 19:57:17',
            '2066년 05월 05일 12:48:13',
            '2066년 06월 05일 16:35:26',
            '2066년 07월 07일 02:41:27',
            '2066년 08월 07일 12:36:30',
            '2066년 09월 07일 15:52:53',
            '2066년 10월 08일 08:00:25',
            '2066년 11월 07일 11:38:49',
            '2066년 12월 07일 04:48:03',
            '2066년 01월 20일 03:41:39',
            '2066년 02월 18일 17:40:16',
            '2066년 03월 20일 16:19:23',
            '2066년 04월 20일 02:54:50',
            '2066년 05월 21일 01:37:01',
            '2066년 06월 21일 09:15:59',
            '2066년 07월 22일 20:05:52',
            '2066년 08월 23일 03:23:04',
            '2066년 09월 23일 01:26:36',
            '2066년 10월 23일 11:15:58',
            '2066년 11월 22일 09:13:10',
            '2066년 12월 21일 22:45:07',
        ]
    },
    {
        year : 2067,
        joonggi :[
            '2067년 01월 05일 16:06:36',
            '2067년 02월 04일 03:36:52',
            '2067년 03월 05일 21:18:04',
            '2067년 04월 05일 01:40:10',
            '2067년 05월 05일 18:31:45',
            '2067년 06월 05일 22:20:54',
            '2067년 07월 07일 08:28:43',
            '2067년 08월 07일 18:24:39',
            '2067년 09월 07일 21:41:52',
            '2067년 10월 08일 13:50:30',
            '2067년 11월 07일 17:30:00',
            '2067년 12월 07일 10:40:11',
            '2067년 01월 20일 09:22:28',
            '2067년 02월 18일 23:16:54',
            '2067년 03월 20일 21:53:12',
            '2067년 04월 20일 08:28:11',
            '2067년 05월 21일 07:12:30',
            '2067년 06월 21일 14:55:35',
            '2067년 07월 23일 01:50:12',
            '2067년 08월 23일 09:11:49',
            '2067년 09월 23일 07:19:14',
            '2067년 10월 23일 17:11:21',
            '2067년 11월 22일 15:10:12',
            '2067년 12월 22일 04:42:42',
        ]
    },
    {
        year : 2068,
        joonggi :[
            '2068년 01월 05일 21:58:59',
            '2068년 02월 04일 09:28:35',
            '2068년 03월 05일 03:08:29',
            '2068년 04월 04일 07:29:17',
            '2068년 05월 05일 00:20:09',
            '2068년 06월 05일 04:09:08',
            '2068년 07월 06일 14:16:27',
            '2068년 08월 07일 00:10:43',
            '2068년 09월 07일 03:25:25',
            '2068년 10월 07일 19:32:39',
            '2068년 11월 06일 23:12:59',
            '2068년 12월 06일 16:25:45',
            '2068년 01월 20일 15:19:32',
            '2068년 02월 19일 05:12:57',
            '2068년 03월 20일 03:48:34',
            '2068년 04월 19일 14:23:57',
            '2068년 05월 20일 13:09:35',
            '2068년 06월 20일 20:53:22',
            '2068년 07월 22일 07:46:16',
            '2068년 08월 22일 15:03:30',
            '2068년 09월 22일 13:06:22',
            '2068년 10월 22일 22:56:33',
            '2068년 11월 21일 20:56:35',
            '2068년 12월 21일 10:32:16',
        ]
    },
    {
        year : 2069,
        joonggi :[
            '2069년 01월 05일 03:47:52',
            '2069년 02월 03일 15:20:23',
            '2069년 03월 05일 09:02:07',
            '2069년 04월 04일 13:23:33',
            '2069년 05월 05일 06:14:16',
            '2069년 06월 05일 10:02:54',
            '2069년 07월 06일 20:10:32',
            '2069년 08월 07일 06:05:35',
            '2069년 09월 07일 09:20:12',
            '2069년 10월 08일 01:26:35',
            '2069년 11월 07일 05:07:04',
            '2069년 12월 06일 22:21:56',
            '2069년 01월 19일 21:12:42',
            '2069년 02월 18일 11:08:35',
            '2069년 03월 20일 09:44:38',
            '2069년 04월 19일 20:18:19',
            '2069년 05월 20일 19:00:37',
            '2069년 06월 21일 02:40:53',
            '2069년 07월 22일 13:31:57',
            '2069년 08월 22일 20:48:52',
            '2069년 09월 22일 18:51:28',
            '2069년 10월 23일 04:41:46',
            '2069년 11월 22일 02:43:14',
            '2069년 12월 21일 16:21:40',
        ]
    },
    {
        year : 2070,
        joonggi :[
            '2070년 01월 05일 09:47:07',
            '2070년 02월 03일 21:21:23',
            '2070년 03월 05일 15:01:59',
            '2070년 04월 04일 19:19:22',
            '2070년 05월 05일 12:04:22',
            '2070년 06월 05일 15:47:36',
            '2070년 07월 07일 01:51:40',
            '2070년 08월 07일 11:46:12',
            '2070년 09월 07일 15:03:24',
            '2070년 10월 08일 07:12:57',
            '2070년 11월 07일 10:55:09',
            '2070년 12월 07일 04:10:21',
            '2070년 01월 20일 03:04:35',
            '2070년 02월 18일 17:00:48',
            '2070년 03월 20일 15:34:30',
            '2070년 04월 20일 02:04:06',
            '2070년 05월 21일 00:42:53',
            '2070년 06월 21일 08:22:12',
            '2070년 07월 22일 19:15:16',
            '2070년 08월 23일 02:36:44',
            '2070년 09월 23일 00:44:25',
            '2070년 10월 23일 10:37:58',
            '2070년 11월 22일 08:40:32',
            '2070년 12월 21일 22:18:56',
        ]
    } ,


    {
        year : 2071,
        joonggi :[
            '2071년 01월 05일 15:35:30',
            '2071년 02월 04일 03:10:25',
            '2071년 03월 05일 20:52:09',
            '2071년 04월 05일 01:10:11',
            '2071년 05월 05일 17:54:49',
            '2071년 06월 05일 21:37:33',
            '2071년 07월 07일 07:42:23',
            '2071년 08월 07일 17:38:44',
            '2071년 09월 07일 20:57:31',
            '2071년 10월 08일 13:07:33',
            '2071년 11월 07일 16:48:18',
            '2071년 12월 07일 10:00:23',
            '2071년 01월 20일 09:02:00',
            '2071년 02월 18일 22:59:15',
            '2071년 03월 20일 21:34:16',
            '2071년 04월 20일 08:04:28',
            '2071년 05월 21일 06:42:35',
            '2071년 06월 21일 14:20:26',
            '2071년 07월 23일 01:11:51',
            '2071년 08월 23일 08:31:29',
            '2071년 09월 23일 06:37:21',
            '2071년 10월 23일 16:28:47',
            '2071년 11월 22일 14:28:20',
            '2071년 12월 22일 04:03:30',
        ]
    },
    {
        year : 2072,
        joonggi :[
            '2072년 01월 05일 21:22:32',
            '2072년 02월 04일 08:56:35',
            '2072년 03월 05일 02:40:31',
            '2072년 04월 04일 07:03:17',
            '2072년 05월 04일 23:53:18',
            '2072년 06월 05일 03:39:32',
            '2072년 07월 06일 13:44:46',
            '2072년 08월 06일 23:38:56',
            '2072년 09월 07일 02:54:45',
            '2072년 10월 07일 19:02:54',
            '2072년 11월 06일 22:43:27',
            '2072년 12월 06일 15:56:05',
            '2072년 01월 20일 14:44:45',
            '2072년 02월 19일 04:42:42',
            '2072년 03월 20일 03:20:39',
            '2072년 04월 19일 13:54:36',
            '2072년 05월 20일 12:35:15',
            '2072년 06월 20일 20:13:24',
            '2072년 07월 22일 07:03:45',
            '2072년 08월 22일 14:22:06',
            '2072년 09월 22일 12:27:27',
            '2072년 10월 22일 22:19:28',
            '2072년 11월 21일 20:20:03',
            '2072년 12월 21일 09:55:38',
        ]
    },

    {
        year : 2073,
        joonggi :[
            '2073년 01월 05일 03:18:27',
            '2073년 02월 03일 14:52:21',
            '2073년 03월 05일 08:36:19',
            '2073년 04월 04일 12:58:52',
            '2073년 05월 05일 05:47:24',
            '2073년 06월 05일 09:30:15',
            '2073년 07월 06일 19:30:28',
            '2073년 08월 07일 05:19:50',
            '2073년 09월 07일 08:32:55',
            '2073년 10월 08일 00:40:54',
            '2073년 11월 07일 04:23:44',
            '2073년 12월 06일 21:40:02',
            '2073년 01월 19일 20:36:34',
            '2073년 02월 18일 10:34:21',
            '2073년 03월 20일 09:12:57',
            '2073년 04월 19일 19:47:49',
            '2073년 05월 20일 18:29:05',
            '2073년 06월 21일 02:06:37',
            '2073년 07월 22일 12:54:50',
            '2073년 08월 22일 20:10:51',
            '2073년 09월 22일 18:14:55',
            '2073년 10월 23일 04:07:40',
            '2073년 11월 22일 02:11:06',
            '2073년 12월 21일 15:50:20',
        ]
    },

    {
        year : 2074,
        joonggi :[
            '2074년 01월 05일 09:05:40',
            '2074년 02월 03일 20:40:53',
            '2074년 03월 05일 14:23:55',
            '2074년 04월 04일 18:44:45',
            '2074년 05월 05일 11:32:47',
            '2074년 06월 05일 15:17:18',
            '2074년 07월 07일 01:20:37',
            '2074년 08월 07일 11:12:52',
            '2074년 09월 07일 14:27:55',
            '2074년 10월 08일 06:36:53',
            '2074년 11월 07일 10:19:21',
            '2074년 12월 07일 03:34:03',
            '2074년 01월 20일 02:33:45',
            '2074년 02월 18일 16:31:39',
            '2074년 03월 20일 15:08:29',
            '2074년 04월 20일 01:41:20',
            '2074년 05월 21일 00:21:16',
            '2074년 06월 21일 07:58:05',
            '2074년 07월 22일 18:45:21',
            '2074년 08월 23일 02:00:12',
            '2074년 09월 23일 00:03:26',
            '2074년 10월 23일 09:55:27',
            '2074년 11월 22일 07:57:31',
            '2074년 12월 21일 21:34:53',
        ]
    },
    {
        year : 2075,
        joonggi :[
            '2075년 01월 05일 14:57:28',
            '2075년 02월 04일 02:30:13',
            '2075년 03월 05일 20:10:59',
            '2075년 04월 05일 00:30:38',
            '2075년 05월 05일 17:19:19',
            '2075년 06월 05일 21:06:19',
            '2075년 07월 07일 07:13:07',
            '2075년 08월 07일 17:08:00',
            '2075년 09월 07일 20:23:35',
            '2075년 10월 08일 12:31:06',
            '2075년 11월 07일 16:11:15',
            '2075년 12월 07일 09:24:07',
            '2075년 01월 20일 08:16:08',
            '2075년 02월 18일 22:11:38',
            '2075년 03월 20일 20:46:13',
            '2075년 04월 20일 07:18:02',
            '2075년 05월 21일 05:59:13',
            '2075년 06월 21일 13:40:04',
            '2075년 07월 23일 00:33:07',
            '2075년 08월 23일 07:52:52',
            '2075년 09월 23일 05:58:28',
            '2075년 10월 23일 15:50:16',
            '2075년 11월 22일 13:50:50',
            '2075년 12월 22일 03:26:44',
        ]
    },
    {
        year : 2076,
        joonggi :[
            '2076년 01월 05일 20:46:42',
            '2076년 02월 04일 08:19:32',
            '2076년 03월 05일 02:00:34',
            '2076년 04월 04일 06:20:00',
            '2076년 05월 04일 23:08:01',
            '2076년 06월 05일 02:54:12',
            '2076년 07월 06일 13:00:03',
            '2076년 08월 06일 22:54:13',
            '2076년 09월 07일 02:08:41',
            '2076년 10월 07일 18:14:34',
            '2076년 11월 06일 21:53:10',
            '2076년 12월 06일 15:05:10',
            '2076년 01월 20일 14:07:18',
            '2076년 02월 19일 04:03:03',
            '2076년 03월 20일 02:38:29',
            '2076년 04월 19일 13:11:35',
            '2076년 05월 20일 11:54:27',
            '2076년 06월 20일 19:36:26',
            '2076년 07월 22일 06:29:20',
            '2076년 08월 22일 13:47:21',
            '2076년 09월 22일 11:49:54',
            '2076년 10월 22일 21:38:40',
            '2076년 11월 21일 19:37:23',
            '2076년 12월 21일 09:13:03',
        ]
    },
    {
        year : 2077,
        joonggi :[
            '2077년 01월 05일 02:28:08',
            '2077년 02월 03일 14:02:45',
            '2077년 03월 05일 07:46:29',
            '2077년 04월 04일 12:08:23',
            '2077년 05월 05일 04:57:43',
            '2077년 06월 05일 08:44:13',
            '2077년 07월 06일 18:50:32',
            '2077년 08월 07일 04:46:10',
            '2077년 09월 07일 08:02:50',
            '2077년 10월 08일 00:10:26',
            '2077년 11월 07일 03:49:56',
            '2077년 12월 06일 21:02:08',
            '2077년 01월 19일 19:55:03',
            '2077년 02월 18일 09:53:14',
            '2077년 03월 20일 08:30:45',
            '2077년 04월 19일 19:04:05',
            '2077년 05월 20일 17:44:40',
            '2077년 06월 21일 01:23:08',
            '2077년 07월 22일 12:13:31',
            '2077년 08월 22일 19:31:30',
            '2077년 09월 22일 17:35:32',
            '2077년 10월 23일 03:25:42',
            '2077년 11월 22일 01:25:03',
            '2077년 12월 21일 15:00:31',
        ]
    },
    {
        year : 2078,
        joonggi :[
            '2078년 01월 05일 08:24:28',
            '2078년 02월 03일 19:57:02',
            '2078년 03월 05일 13:37:35',
            '2078년 04월 04일 17:55:47',
            '2078년 05월 05일 10:41:18',
            '2078년 06월 05일 14:24:26',
            '2078년 07월 07일 00:28:29',
            '2078년 08월 07일 10:23:51',
            '2078년 09월 07일 13:43:20',
            '2078년 10월 08일 05:55:42',
            '2078년 11월 07일 09:39:05',
            '2078년 12월 07일 02:52:28',
            '2078년 01월 20일 01:41:04',
            '2078년 02월 18일 15:36:26',
            '2078년 03월 20일 14:10:30',
            '2078년 04월 20일 00:40:34',
            '2078년 05월 20일 23:19:02',
            '2078년 06월 21일 06:57:38',
            '2078년 07월 22일 17:50:38',
            '2078년 08월 23일 01:13:35',
            '2078년 09월 22일 23:24:13',
            '2078년 10월 23일 09:20:12',
            '2078년 11월 22일 07:22:34',
            '2078년 12월 21일 20:57:40',
        ]
    },
    {
        year : 2079,
        joonggi :[
            '2079년 01월 05일 14:13:11',
            '2079년 02월 04일 01:42:53',
            '2079년 03월 05일 19:20:40',
            '2079년 04월 04일 23:37:05',
            '2079년 05월 05일 16:21:59',
            '2079년 06월 05일 20:05:37',
            '2079년 07월 07일 06:11:25',
            '2079년 08월 07일 16:09:03',
            '2079년 09월 07일 19:29:59',
            '2079년 10월 08일 11:43:08',
            '2079년 11월 07일 15:26:49',
            '2079년 12월 07일 08:39:45',
            '2079년 01월 20일 07:35:36',
            '2079년 02월 18일 21:28:04',
            '2079년 03월 20일 20:00:22',
            '2079년 04월 20일 06:30:16',
            '2079년 05월 21일 05:09:34',
            '2079년 06월 21일 12:49:01',
            '2079년 07월 22일 23:42:15',
            '2079년 08월 23일 07:04:04',
            '2079년 09월 23일 05:12:53',
            '2079년 10월 23일 15:07:41',
            '2079년 11월 22일 13:09:23',
            '2079년 12월 22일 02:43:44',
        ]
    },
    {
        year : 2080,
        joonggi :[
            '2080년 01월 05일 19:59:18',
            '2080년 02월 04일 07:27:40',
            '2080년 03월 05일 01:04:52',
            '2080년 04월 04일 05:22:25',
            '2080년 05월 04일 22:10:22',
            '2080년 06월 05일 01:57:30',
            '2080년 07월 06일 12:05:22',
            '2080년 08월 06일 22:02:51',
            '2080년 09월 07일 01:22:11',
            '2080년 10월 07일 17:34:05',
            '2080년 11월 06일 21:18:23',
            '2080년 12월 06일 14:33:36',
            '2080년 01월 20일 13:20:41',
            '2080년 02월 19일 03:12:07',
            '2080년 03월 20일 01:43:50',
            '2080년 04월 19일 12:14:03',
            '2080년 05월 20일 10:54:08',
            '2080년 06월 20일 18:33:49',
            '2080년 07월 22일 05:26:35',
            '2080년 08월 22일 12:47:42',
            '2080년 09월 22일 10:56:09',
            '2080년 10월 22일 20:51:45',
            '2080년 11월 21일 18:55:35',
            '2080년 12월 21일 08:32:33',
        ]
    } ,


    {
        year : 2081,
        joonggi :[
            '2081년 01월 05일 01:55:45',
            '2081년 02월 03일 13:25:39',
            '2081년 03월 05일 07:02:26',
            '2081년 04월 04일 11:16:57',
            '2081년 05월 05일 03:59:40',
            '2081년 06월 05일 07:40:54',
            '2081년 07월 06일 17:43:16',
            '2081년 08월 07일 03:36:47',
            '2081년 09월 07일 06:54:21',
            '2081년 10월 07일 23:06:17',
            '2081년 11월 07일 02:52:31',
            '2081년 12월 06일 20:11:33',
            '2081년 01월 19일 19:11:29',
            '2081년 02월 18일 09:03:30',
            '2081년 03월 20일 07:34:00',
            '2081년 04월 19일 18:01:16',
            '2081년 05월 20일 16:38:21',
            '2081년 06월 21일 00:16:08',
            '2081년 07월 22일 11:07:54',
            '2081년 08월 22일 18:28:49',
            '2081년 09월 22일 16:37:33',
            '2081년 10월 23일 02:34:17',
            '2081년 11월 22일 00:40:58',
            '2081년 12월 21일 14:22:16',
        ]
    },
    {
        year : 2082,
        joonggi :[
            '2082년 01월 05일 07:38:20',
            '2082년 02월 03일 19:11:57',
            '2082년 03월 05일 12:49:54',
            '2082년 04월 04일 17:02:53',
            '2082년 05월 05일 09:42:43',
            '2082년 06월 05일 13:21:55',
            '2082년 07월 06일 23:24:51',
            '2082년 08월 07일 09:21:06',
            '2082년 09월 07일 12:42:20',
            '2082년 10월 08일 04:57:16',
            '2082년 11월 07일 08:44:01',
            '2082년 12월 07일 02:01:20',
            '2082년 01월 20일 01:05:32',
            '2082년 02월 18일 15:00:06',
            '2082년 03월 20일 13:30:21',
            '2082년 04월 19일 23:55:07',
            '2082년 05월 20일 22:28:31',
            '2082년 06월 21일 06:03:02',
            '2082년 07월 22일 16:52:48',
            '2082년 08월 23일 00:13:13',
            '2082년 09월 22일 22:22:43',
            '2082년 10월 23일 08:19:50',
            '2082년 11월 22일 06:25:13',
            '2082년 12월 21일 20:04:27',
        ]
    },

    {
        year : 2083,
        joonggi :[
            '2083년 01월 05일 13:25:55',
            '2083년 02월 04일 00:58:07',
            '2083년 03월 05일 18:35:58',
            '2083년 04월 04일 22:50:02',
            '2083년 05월 05일 15:31:21',
            '2083년 06월 05일 19:11:44',
            '2083년 07월 07일 05:15:35',
            '2083년 08월 07일 15:12:36',
            '2083년 09월 07일 18:34:16',
            '2083년 10월 08일 10:49:15',
            '2083년 11월 07일 14:35:29',
            '2083년 12월 07일 07:51:36',
            '2083년 01월 20일 06:46:06',
            '2083년 02월 18일 20:39:59',
            '2083년 03월 20일 19:10:16',
            '2083년 04월 20일 05:35:13',
            '2083년 05월 21일 04:08:36',
            '2083년 06월 21일 11:43:39',
            '2083년 07월 22일 22:35:31',
            '2083년 08월 23일 05:59:01',
            '2083년 09월 23일 04:11:24',
            '2083년 10월 23일 14:10:07',
            '2083년 11월 22일 12:15:24',
            '2083년 12월 22일 01:53:09',
        ]
    },

    {
        year : 2084,
        joonggi :[
            '2084년 01월 05일 19:14:48',
            '2084년 02월 04일 06:46:24',
            '2084년 03월 05일 00:24:50',
            '2084년 04월 04일 04:40:14',
            '2084년 05월 04일 21:22:43',
            '2084년 06월 05일 01:02:32',
            '2084년 07월 06일 11:03:16',
            '2084년 08월 06일 20:56:08',
            '2084년 09월 07일 00:14:09',
            '2084년 10월 07일 16:27:02',
            '2084년 11월 06일 20:13:21',
            '2084년 12월 06일 13:30:59',
            '2084년 01월 20일 12:33:22',
            '2084년 02월 19일 02:27:11',
            '2084년 03월 20일 00:59:14',
            '2084년 04월 19일 11:27:26',
            '2084년 05월 20일 10:04:11',
            '2084년 06월 20일 17:40:22',
            '2084년 07월 22일 04:30:22',
            '2084년 08월 22일 11:50:13',
            '2084년 09월 22일 09:58:54',
            '2084년 10월 22일 19:55:44',
            '2084년 11월 21일 18:01:30',
            '2084년 12월 21일 07:41:05',
        ]
    },
    {
        year : 2085,
        joonggi :[
            '2085년 01월 05일 00:56:06',
            '2085년 02월 03일 12:29:39',
            '2085년 03월 05일 06:10:17',
            '2085년 04월 04일 10:28:05',
            '2085년 05월 05일 03:12:50',
            '2085년 06월 05일 06:54:24',
            '2085년 07월 06일 16:56:09',
            '2085년 08월 07일 02:49:15',
            '2085년 09월 07일 06:07:16',
            '2085년 10월 07일 22:20:15',
            '2085년 11월 07일 02:07:25',
            '2085년 12월 06일 19:26:53',
            '2085년 01월 19일 18:23:25',
            '2085년 02월 18일 08:19:21',
            '2085년 03월 20일 06:53:20',
            '2085년 04월 19일 17:22:36',
            '2085년 05월 20일 15:58:47',
            '2085년 06월 20일 23:32:39',
            '2085년 07월 22일 10:19:17',
            '2085년 08월 22일 17:36:14',
            '2085년 09월 22일 15:43:19',
            '2085년 10월 23일 01:39:59',
            '2085년 11월 21일 23:47:03',
            '2085년 12월 21일 13:28:34',
        ]
    },
    {
        year : 2086,
        joonggi :[
            '2086년 01월 05일 06:53:29',
            '2086년 02월 03일 18:26:14',
            '2086년 03월 05일 12:03:41',
            '2086년 04월 04일 16:17:26',
            '2086년 05월 05일 08:58:44',
            '2086년 06월 05일 12:38:29',
            '2086년 07월 06일 22:39:54',
            '2086년 08월 07일 08:33:17',
            '2086년 09월 07일 11:52:16',
            '2086년 10월 08일 04:06:55',
            '2086년 11월 07일 07:55:33',
            '2086년 12월 07일 01:15:44',
            '2086년 01월 20일 00:11:26',
            '2086년 02월 18일 14:05:10',
            '2086년 03월 20일 12:35:06',
            '2086년 04월 19일 23:00:21',
            '2086년 05월 20일 21:34:28',
            '2086년 06월 21일 05:09:26',
            '2086년 07월 22일 15:59:30',
            '2086년 08월 22일 23:20:43',
            '2086년 09월 22일 21:32:03',
            '2086년 10월 23일 07:31:59',
            '2086년 11월 22일 05:40:44',
            '2086년 12월 21일 19:22:31',
        ]
    },
    {
        year : 2087,
        joonggi :[
            '2087년 01월 05일 12:42:24',
            '2087년 02월 04일 00:15:01',
            '2087년 03월 05일 17:51:48',
            '2087년 04월 04일 22:04:20',
            '2087년 05월 05일 14:44:33',
            '2087년 06월 05일 18:24:21',
            '2087년 07월 07일 04:27:50',
            '2087년 08월 07일 14:24:10',
            '2087년 09월 07일 17:44:12',
            '2087년 10월 08일 09:57:22',
            '2087년 11월 07일 13:43:03',
            '2087년 12월 07일 07:00:12',
            '2087년 01월 20일 06:05:05',
            '2087년 02월 18일 19:58:29',
            '2087년 03월 20일 18:28:10',
            '2087년 04월 20일 04:53:38',
            '2087년 05월 21일 03:28:51',
            '2087년 06월 21일 11:05:50',
            '2087년 07월 22일 21:58:07',
            '2087년 08월 23일 05:19:32',
            '2087년 09월 23일 03:28:11',
            '2087년 10월 23일 13:24:06',
            '2087년 11월 22일 11:29:02',
            '2087년 12월 22일 01:08:23',
        ]
    },
    {
        year : 2088,
        joonggi :[
            '2088년 01월 05일 18:25:06',
            '2088년 02월 04일 05:57:58',
            '2088년 03월 04일 23:36:52',
            '2088년 04월 04일 03:52:38',
            '2088년 05월 04일 20:36:34',
            '2088년 06월 05일 00:19:52',
            '2088년 07월 06일 10:25:48',
            '2088년 08월 06일 20:23:27',
            '2088년 09월 06일 23:43:48',
            '2088년 10월 07일 15:56:11',
            '2088년 11월 06일 19:40:34',
            '2088년 12월 06일 12:56:35',
            '2088년 01월 20일 11:50:29',
            '2088년 02월 19일 01:45:12',
            '2088년 03월 20일 00:16:55',
            '2088년 04월 19일 10:44:05',
            '2088년 05월 20일 09:20:03',
            '2088년 06월 20일 16:56:36',
            '2088년 07월 22일 03:48:05',
            '2088년 08월 22일 11:09:24',
            '2088년 09월 22일 09:18:10',
            '2088년 10월 22일 19:13:37',
            '2088년 11월 21일 17:17:36',
            '2088년 12월 21일 06:56:06',
        ]
    },
    {
        year : 2089,
        joonggi :[
            '2089년 01월 05일 00:21:03',
            '2089년 02월 03일 11:54:28',
            '2089년 03월 05일 05:34:29',
            '2089년 04월 04일 09:50:15',
            '2089년 05월 05일 02:31:40',
            '2089년 06월 05일 06:10:22',
            '2089년 07월 06일 16:10:58',
            '2089년 08월 07일 02:04:31',
            '2089년 09월 07일 05:23:52',
            '2089년 10월 07일 21:37:50',
            '2089년 11월 07일 01:24:41',
            '2089년 12월 06일 18:42:50',
            '2089년 01월 19일 17:38:05',
            '2089년 02월 18일 07:33:42',
            '2089년 03월 20일 06:06:29',
            '2089년 04월 19일 16:33:24',
            '2089년 05월 20일 15:07:52',
            '2089년 06월 20일 22:42:53',
            '2089년 07월 22일 09:33:34',
            '2089년 08월 22일 16:55:39',
            '2089년 09월 22일 15:06:50',
            '2089년 10월 23일 01:05:15',
            '2089년 11월 21일 23:11:50',
            '2089년 12월 21일 12:51:58',
        ]
    },
    {
        year : 2090,
        joonggi :[
            '2090년 01월 05일 06:08:32',
            '2090년 02월 03일 17:42:07',
            '2090년 03월 05일 11:21:21',
            '2090년 04월 04일 15:36:03',
            '2090년 05월 05일 08:16:34',
            '2090년 06월 05일 11:54:49',
            '2090년 07월 06일 21:56:27',
            '2090년 08월 07일 07:52:39',
            '2090년 09월 07일 11:15:42',
            '2090년 10월 08일 03:33:37',
            '2090년 11월 07일 07:22:34',
            '2090년 12월 07일 00:39:42',
            '2090년 01월 19일 23:34:31',
            '2090년 02월 18일 13:29:46',
            '2090년 03월 20일 12:01:50',
            '2090년 04월 19일 22:28:15',
            '2090년 05월 20일 21:01:59',
            '2090년 06월 21일 04:35:50',
            '2090년 07월 22일 15:25:24',
            '2090년 08월 22일 22:47:06',
            '2090년 09월 22일 20:59:18',
            '2090년 10월 23일 06:59:16',
            '2090년 11월 22일 05:05:47',
            '2090년 12월 21일 18:43:33',
        ]
    } ,



    {
        year : 2091,
        joonggi :[
            '2091년 01월 05일 12:01:48',
            '2091년 02월 03일 23:30:40',
            '2091년 03월 05일 17:06:10',
            '2091년 04월 04일 21:20:00',
            '2091년 05월 05일 14:02:56',
            '2091년 06월 05일 17:45:21',
            '2091년 07월 07일 03:50:45',
            '2091년 08월 07일 13:49:21',
            '2091년 09월 07일 17:13:18',
            '2091년 10월 08일 09:31:18',
            '2091년 11월 07일 13:20:36',
            '2091년 12월 07일 06:38:24',
            '2091년 01월 20일 05:22:01',
            '2091년 02월 18일 19:12:52',
            '2091년 03월 20일 17:41:49',
            '2091년 04월 20일 04:07:30',
            '2091년 05월 21일 02:42:37',
            '2091년 06월 21일 10:18:54',
            '2091년 07월 22일 21:11:28',
            '2091년 08월 23일 04:36:13',
            '2091년 09월 23일 02:50:49',
            '2091년 10월 23일 12:52:28',
            '2091년 11월 22일 11:00:17',
            '2091년 12월 22일 00:38:32',
        ]
    },
    {
        year : 2092,
        joonggi :[
            '2092년 01월 05일 18:00:39',
            '2092년 02월 04일 05:28:41',
            '2092년 03월 04일 23:02:30',
            '2092년 04월 04일 03:14:27',
            '2092년 05월 04일 19:56:09',
            '2092년 06월 04일 23:37:37',
            '2092년 07월 06일 09:40:49',
            '2092년 08월 06일 19:35:53',
            '2092년 09월 06일 22:56:06',
            '2092년 10월 07일 15:11:33',
            '2092년 11월 06일 19:00:46',
            '2092년 12월 06일 12:20:58',
            '2092년 01월 20일 11:16:24',
            '2092년 02월 19일 01:05:52',
            '2092년 03월 19일 23:33:32',
            '2092년 04월 19일 09:59:16',
            '2092년 05월 20일 08:36:26',
            '2092년 06월 20일 16:14:57',
            '2092년 07월 22일 03:07:40',
            '2092년 08월 22일 10:30:15',
            '2092년 09월 22일 08:41:47',
            '2092년 10월 22일 18:41:43',
            '2092년 11월 21일 16:50:29',
            '2092년 12월 21일 06:31:54',
        ]
    },

    {
        year : 2093,
        joonggi :[
            '2093년 01월 04일 23:46:55',
            '2093년 02월 03일 11:18:28',
            '2093년 03월 05일 04:54:17',
            '2093년 04월 04일 09:06:08',
            '2093년 05월 05일 01:46:12',
            '2093년 06월 05일 05:26:29',
            '2093년 07월 06일 15:30:32',
            '2093년 08월 07일 01:27:37',
            '2093년 09월 07일 04:49:37',
            '2093년 10월 07일 21:05:55',
            '2093년 11월 07일 00:55:41',
            '2093년 12월 06일 18:17:12',
            '2093년 01월 19일 17:13:39',
            '2093년 02월 18일 07:06:02',
            '2093년 03월 20일 05:34:31',
            '2093년 04월 19일 15:58:28',
            '2093년 05월 20일 14:32:01',
            '2093년 06월 20일 22:07:03',
            '2093년 07월 22일 08:57:21',
            '2093년 08월 22일 16:18:24',
            '2093년 09월 22일 14:28:54',
            '2093년 10월 23일 00:28:16',
            '2093년 11월 21일 22:37:34',
            '2093년 12월 21일 12:20:45',
        ]
    },

    {
        year : 2094,
        joonggi :[
            '2094년 01월 05일 05:44:53',
            '2094년 02월 03일 17:16:57',
            '2094년 03월 05일 10:51:20',
            '2094년 04월 04일 14:59:55',
            '2094년 05월 05일 07:35:38',
            '2094년 06월 05일 11:11:52',
            '2094년 07월 06일 21:13:55',
            '2094년 08월 07일 07:11:33',
            '2094년 09월 07일 10:36:00',
            '2094년 10월 08일 02:55:14',
            '2094년 11월 07일 06:46:39',
            '2094년 12월 07일 00:08:02',
            '2094년 01월 19일 23:03:58',
            '2094년 02월 18일 12:55:55',
            '2094년 03월 20일 11:21:33',
            '2094년 04월 19일 21:40:51',
            '2094년 05월 20일 20:09:35',
            '2094년 06월 21일 03:42:18',
            '2094년 07월 22일 14:34:21',
            '2094년 08월 22일 22:00:15',
            '2094년 09월 22일 20:16:33',
            '2094년 10월 23일 06:20:11',
            '2094년 11월 22일 04:30:52',
            '2094년 12월 21일 18:13:20',
        ]
    },
    {
        year : 2095,
        joonggi :[
            '2095년 01월 05일 11:34:58',
            '2095년 02월 03일 23:07:00',
            '2095년 03월 05일 16:41:56',
            '2095년 04월 04일 20:50:49',
            '2095년 05월 05일 13:25:50',
            '2095년 06월 05일 17:00:20',
            '2095년 07월 07일 03:00:56',
            '2095년 08월 07일 12:58:36',
            '2095년 09월 07일 16:23:25',
            '2095년 10월 08일 08:42:26',
            '2095년 11월 07일 12:32:35',
            '2095년 12월 07일 05:51:33',
            '2095년 01월 20일 04:55:43',
            '2095년 02월 18일 18:48:00',
            '2095년 03월 20일 17:14:56',
            '2095년 04월 20일 03:35:55',
            '2095년 05월 21일 02:05:49',
            '2095년 06월 21일 09:38:51',
            '2095년 07월 22일 20:30:55',
            '2095년 08월 23일 03:56:19',
            '2095년 09월 23일 02:11:07',
            '2095년 10월 23일 12:12:44',
            '2095년 11월 22일 10:21:00',
            '2095년 12월 22일 00:00:50',
        ]
    },
    {
        year : 2096,
        joonggi :[
            '2096년 01월 05일 17:15:52',
            '2096년 02월 04일 04:46:48',
            '2096년 03월 04일 22:23:07',
            '2096년 04월 04일 02:35:40',
            '2096년 05월 04일 19:15:36',
            '2096년 06월 04일 22:54:21',
            '2096년 07월 06일 08:56:33',
            '2096년 08월 06일 18:53:21',
            '2096년 09월 06일 22:16:57',
            '2096년 10월 07일 14:35:19',
            '2096년 11월 06일 18:25:50',
            '2096년 12월 06일 11:45:48',
            '2096년 01월 20일 10:41:30',
            '2096년 02월 19일 00:33:57',
            '2096년 03월 19일 23:02:54',
            '2096년 04월 19일 09:26:39',
            '2096년 05월 20일 07:58:30',
            '2096년 06월 20일 15:30:47',
            '2096년 07월 22일 02:19:28',
            '2096년 08월 22일 09:41:33',
            '2096년 09월 22일 07:54:44',
            '2096년 10월 22일 17:56:17',
            '2096년 11월 21일 16:05:28',
            '2096년 12월 21일 05:46:13',
        ]
    },
    {
        year : 2097,
        joonggi :[
            '2097년 01월 04일 23:10:50',
            '2097년 02월 03일 10:41:56',
            '2097년 03월 05일 04:18:16',
            '2097년 04월 04일 08:30:11',
            '2097년 05월 05일 01:08:09',
            '2097년 06월 05일 04:43:41',
            '2097년 07월 06일 14:41:22',
            '2097년 08월 07일 00:32:53',
            '2097년 09월 07일 03:52:51',
            '2097년 10월 07일 20:10:54',
            '2097년 11월 07일 00:03:52',
            '2097년 12월 06일 17:27:46',
            '2097년 01월 19일 16:27:11',
            '2097년 02월 18일 06:19:36',
            '2097년 03월 20일 04:48:23',
            '2097년 04월 19일 15:11:17',
            '2097년 05월 20일 13:41:59',
            '2097년 06월 20일 21:13:22',
            '2097년 07월 22일 08:00:59',
            '2097년 08월 22일 15:22:07',
            '2097년 09월 22일 13:35:50',
            '2097년 10월 22일 23:39:47',
            '2097년 11월 21일 21:52:43',
            '2097년 12월 21일 11:37:25',
        ]
    },
    {
        year : 2098,
        joonggi :[
            '2098년 01월 05일 04:56:25',
            '2098년 02월 03일 16:28:58',
            '2098년 03월 05일 10:03:58',
            '2098년 04월 04일 14:13:14',
            '2098년 05월 05일 06:48:50',
            '2098년 06월 05일 10:23:21',
            '2098년 07월 06일 20:22:19',
            '2098년 08월 07일 06:16:30',
            '2098년 09월 07일 09:38:40',
            '2098년 10월 08일 01:57:55',
            '2098년 11월 07일 05:50:39',
            '2098년 12월 06일 23:12:54',
            '2098년 01월 19일 22:20:55',
            '2098년 02월 18일 12:13:22',
            '2098년 03월 20일 10:40:25',
            '2098년 04월 19일 21:01:44',
            '2098년 05월 20일 19:31:34',
            '2098년 06월 21일 03:02:58',
            '2098년 07월 22일 13:50:58',
            '2098년 08월 22일 21:11:39',
            '2098년 09월 22일 19:24:12',
            '2098년 10월 23일 05:26:54',
            '2098년 11월 22일 03:38:06',
            '2098년 12월 21일 17:20:49',
        ]
    },
    {
        year : 2099,
        joonggi :[
            '2099년 01월 05일 10:39:16',
            '2099년 02월 03일 22:09:29',
            '2099년 03월 05일 15:42:36',
            '2099년 04월 04일 19:51:24',
            '2099년 05월 05일 12:29:02',
            '2099년 06월 05일 16:07:44',
            '2099년 07월 07일 02:11:40',
            '2099년 08월 07일 12:10:11',
            '2099년 09월 07일 15:34:10',
            '2099년 10월 08일 07:52:12',
            '2099년 11월 07일 11:42:38',
            '2099년 12월 07일 05:03:16',
            '2099년 01월 20일 04:02:18',
            '2099년 02월 18일 17:52:38',
            '2099년 03월 20일 16:17:41',
            '2099년 04월 20일 02:37:56',
            '2099년 05월 21일 01:08:18',
            '2099년 06월 21일 08:41:37',
            '2099년 07월 22일 19:32:55',
            '2099년 08월 23일 02:57:04',
            '2099년 09월 23일 01:11:04',
            '2099년 10월 23일 11:12:53',
            '2099년 11월 22일 09:22:36',
            '2099년 12월 21일 23:04:20',
        ]
    },
    {
        year : 2100,
        joonggi :[
            '2100년 01월 05일 16:29:16',
            '2100년 02월 04일 04:00:16',
            '2100년 03월 05일 21:34:31',
            '2100년 04월 05일 01:43:47',
            '2100년 05월 05일 18:20:55',
            '2100년 06월 05일 21:58:07',
            '2100년 07월 07일 07:59:03',
            '2100년 08월 07일 17:54:05',
            '2100년 09월 07일 21:15:18',
            '2100년 10월 08일 13:31:12',
            '2100년 11월 07일 17:20:06',
            '2100년 12월 07일 10:40:08',
            '2100년 01월 20일 09:45:53',
            '2100년 02월 18일 23:37:16',
            '2100년 03월 20일 22:03:40',
            '2100년 04월 20일 08:25:12',
            '2100년 05월 21일 06:57:16',
            '2100년 06월 21일 14:32:11',
            '2100년 07월 23일 01:23:56',
            '2100년 08월 23일 08:47:38',
            '2100년 09월 23일 07:00:28',
            '2100년 10월 23일 17:00:42',
            '2100년 11월 22일 15:09:14',
            '2100년 12월 22일 04:50:53',
        ]
    } ,
    {
        year : 2101,
        joonggi : [
            '2101년 01월 05일 22:06:51',
            '2101년 02월 04일 09:39:54',
            '2101년 03월 06일 03:16:51',
            '2101년 04월 05일 07:28:02',
            '2101년 05월 06일 00:05:08',
            '2101년 06월 06일 03:41:25',
            '2101년 07월 07일 13:42:47',
            '2101년 08월 07일 23:39:46',
            '2101년 09월 08일 03:04:04',
            '2101년 10월 08일 19:23:12',
            '2101년 11월 07일 23:14:15',
            '2101년 12월 07일 16:34:53',
            '2101년 01월 20일 15:33:47',
            '2101년 02월 19일 05:27:38',
            '2101년 03월 21일 03:56:35',
            '2101년 04월 20일 14:19:01',
            '2101년 05월 21일 12:49:23',
            '2101년 06월 21일 20:21:29',
            '2101년 07월 23일 07:10:48',
            '2101년 08월 23일 14:33:17',
            '2101년 09월 23일 12:46:37',
            '2101년 10월 23일 22:48:06',
            '2101년 11월 22일 20:57:28',
            '2101년 12월 22일 10:38:59',
        ]
    }
]



export default {
    JEOLGI_LABEL0,
    JEOLGI1,
    JOONGGI
}

// ※ 표준시간적용 = TT - ΔT + 나라별표준시간대
// ※ 먼 과거/미래의 델타T(ΔT)는 오차가 있을 수 있음.
// ※ 2017년 이후의 델타T는 예측값임.
// ※ 1582년 10월 15일 이전 날짜는 율리우스력임.
// ※ 24절기 기간: BC13000 년 ~ 16999 년
// ※ 정기법: 15도 배수의 태양의 시황경: 0, 15, 30, ...