
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,5,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,8,4,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,9,3,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,10,2,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,1,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,12,12,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,1,11,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,2,10,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,3,9,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`신회`,4,8,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,5,7,`특별있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,6,6,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`금전`,`금전 여인 상업 관련사`,`小`,``,``,``],
    [`색정`,`외 남자 내 여자 동시 색정사 有`,`小`,`7`,`고시득길`,``],
    [`손실`,`외사 손해 도망사`,`大`,`단절기`,`색정사有`,`색정사有`],
    [`사관심외(仕官深畏)`,`관직자는 두려움이 깊고 일반인은 가벼운 상`,`大`,``,`미세송사`,`음인암손`],
    [`음인암손(陰人暗損)`,`숨겨진 정인으로 인해 남모르는 손해가 있는 상`,`小`,``,``,`(색정관련)`],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`8`,`주력사凶`,`姦淫무도`],
    [`동요`,`외사 동요분산사`,`小`,`침체기`,`침체공허`,`침체공허`],
    [`사불시외(似不是畏)`,`두려울 것 같으나 시늉에 불과한 것인 상`,`小`,``,``,``],
    [`이어경영(利於經營)`,`고위에 안좌하여 여러 인의 이익을 구하는 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,`고위行道`],
    [`직업`,`직업 직위 관련사`,`小`,`9`,`관吉평不`,`청원좋음`],
    [`남편`,`남편 관련사`,`小`,`소외기`,``,`면모훼손`],
    [`수가영창(受家榮昌)`,`가정가족을 지키면서 생각하는 바로 영창하게 되는 상`,`大`,``,``,`애증화합`],
    [`허획경구(虛獲驚懼)`,`도적을 잡지는 못하고 놀래고 두려워 하는 상`,`小`,``,``,``],
    [`결속`,`결속하여 권리사를 크게 이루나 病 형제災害가 우려됨`,`大`,``,``,``],
    [`직업`,`직업관련사`,`小`,`10`,`사사성취`,`손괴재물`],
    [`남자`,`남자 관련사`,`小`,`침해디`,``,`내외단합`],
    [`재필상재(財必生災)`,`돈독치 못한 연인의 돈을 받으면 반드시 화가 있을 상`,`大`,``,``,``],
    [`손괴재물(損壞財物)`,`재물을 손괴한 상-牛관련`,`小`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`11`,``,``],
    [`매사 영속성없음`,`형제친구동료남편남자사는 선천적 기복재해를 겪음`,`小`,`쇠퇴기`,`재물암동`,`情위난측`],
    [`부모묘지(父母墓地)`,`부모 묘지에 災禍가 있는 상`,`小`,``,``,`상대진실`],
    [`정위난측(情爲難測)`,`상대의 마음을 가늠하기 어려운 상`,`大`,``,``,`가늠안됨`],
    [`후원`,`후원 조력관련사`,`大`,``,`송구경황`,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`12`,`(간음상대`,`동요분산`],
    [`매사 영속성없음`,`후원문서부모사는 선천적 숙명재해를 겪음-독신고독`,`小`,`과시기`,`나타남)`,`출행이동`],
    [`불능안식(不能安息)`,`내부가정의 여러 대소사 근심으로 편안할 수 없는 상`,`小`,``,`출행이동`,`앙화소멸`],
    [`앙화소멸(殃禍消滅)`,`재난이 소멸되는 상`,`小`,``,``,``],
    [`화합`,`직위 녹위 관련 상하화합사`,`大`,``,``,``],
    [`자산`,`자산 관련사`,`大`,`1`,`근심해산`,`관吉평不`],
    [`권리`,`권리 관련사`,`小`,`절정기`,`상하화합`,`재화소멸`],
    [`가택천수(家宅遷修)`,`가택을 옮기거나 수리하면 길하게 되는 상`,`小`,``,``,``],
    [`앙화소멸(殃禍消滅)`,`재난이 소멸되는 상`,`小`,``,``,``],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`결속`,`결속하여 권리사를 크게 이루나 病 형제災害가 우려됨`,`大`,`2`,``,``],
    [`막힘`,`외사 외교 막힘`,`小`,`면모기`,`외교막힘`,`매사불리`],
    [`필무정옥(必無正屋)`,`반드시 소유된 집이 없는 상`,`小`,``,`봉흉화길`,`도난유실`],
    [`모망난수(謀望難遂)`,`계획사를 완수하지 못하는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`3`,`비밀누설`,`손해가출`],
    [`자식`,`자식관련사`,`大`,`학습기`,`직위불안`,`강제급속`],
    [`선난후경(先難後更)`,`먼저는 어려우나 나중에는 변해 활발해지는 상`,`小`,``,`강제급속`,`재물暗動`],
    [`재물암동(財物暗動)`,`재물을 남모르게 주고받고 하는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극남`,`남자 갈등사`,`小`,`4`,``,``],
    [`여식`,`여식 관련사`,`大`,`개시기`,`간음무도`,`손괴재물`],
    [`주색파재(酒色破財)`,`주색잡기로 첩의 집에 살며 파재하는 상`,`小`,``,``,``],
    [`손괴재물(損壞財物)`,`재물이 손실된 상- 牛관련`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,`고위行途`,``],
    [`친구`,`친구 관련사`,`大`,`5`,`청원좋음`,``],
    [`송쟁`,`외사 급속 송쟁사-상해 우려`,`小`,`배양기`,`외사송쟁`,`침체驚懼`],
    [`수즉위왕(守卽爲旺)`,`지금을 지키면 왕성해지고 적극활약은 형벌을 부르는 상`,`小`,``,``,`酒食受罰`],
    [`손괴재물(損壞財物)`,`재물이 손실된 상- 牛관련`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`상업`,`상업 관련사`,`小`,`6`,`봉림유蛇`,``],
    [`막힘`,`내부가정 막힘사`,`大`,`존재기`,`(숲에서뱀`,`가정막힘`],
    [`부득안녕(不得安寧)`,`재(財)가 없어서 되려 편안한 상-부도 도난 겁탈 관련`,`大`,``,`을만난격)`,`도적難捕`],
    [`적난포획(賊難捕獲)`,`도적을 못잡은 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,`금전사업연인사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,`재매친구동료조력비용지출로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사 직업 남편 사로 불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사직압남자문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`후원문서부모조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`자매친구동료비용지출사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`직업직위불안여식주색재물손괴사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`형제친구동료조력비용지출로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`금전사업연인사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저나서서 명분을 앞세워 정당하게 속결해야만 유리함 `,0,0,`사안이 정당하고 이치에 맞으므로 속결해야 하고 좌고우면하거나 지체 또는 연기시키면 부리해지는 人`,0,0,`일이 역순으로 진행됨**깊은 산속으로 숨고싶은 심정임**일이 결과없이 반복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 전의가 별로 없고 피해도 없으며 있다해도 가벼움`,0,0,`옳지않은 일이 들통나 천둥에 놀란 듯한 두려운 심정이나 언행을 삼가하고 조용히 있으면 자연히 사라지게 되는 人`,0,0,`일이 띄엄띄엄 진행되어 퇴하려 하나 되려 진행하게 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저나서서 명분을 앞세워 정당하게 속결해야만 유리함 `,0,0,`사안이 정당하고 이치에 맞으므로 속결해야 하고 좌고우면하거나 지체 또는 연기시키면 부리해지는 人`,0,0,`일이 결과없이 반복됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 일을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`복잡다단한 여러 일을 하나로 묶음-단, 되려 해로움**방침을 급히 바꿔야 함**여건이 결여되어 있음**꼬리가 머리가 되어 구별이 없음**어른(父)이 아래(子)를 핍박함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대립하지 말고 순은유화적 처신으로 사안을 지체시키거나 뒤로 밀면 결과가 유리하게 됨 `,0,0,`역상적 사안이므로 억울하더라도 절대 대항하지 말고 순응 유순한 자세로 일을 뒤로 미루면 반드시 결과가 유리하게 되는 人`,0,0,`정대한 모든 일이 단절됨**기이한 전화위복사가 있게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화가 밖에서 오니 신중해야 함`,0,0,`매사 동요불안분산되고 반복 상배되며 심신이 배회되고 있는 人`,0,0,`신규사가 있음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`권한권리영전사는 음4월이라야 가능함**일이 결과없이 반복됨**독신고독각방주말부부생리사별적 처지임 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 일을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`복잡다단한 여러 일을 하나로 묶음-단, 되려 해로움**여건이 갖춰지지 않음**타인이 스스로 와서 금전을 도움**타인에 의지함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 전의가 별로 없고 피해도 없으며 있다해도 가벼움`,0,0,`옳지않은 일이 들통나 천둥에 놀란 듯한 두려운 심정이나 언행을 삼가하고 조용히 있으면 자연히 사라지게 되는 人`,0,0,`신규사가 있음**일이 반복되기만 하고 결과가 없으며 점점 어두워 짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 일을 뒤로 미루는 것이 현명함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 띄엄띄엄 진행됨**출가하는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저나서서 명분을 앞세워 정당하게 속결해야만 유리함 `,0,0,`사안이 정당하고 이치에 맞으므로 속결해야 하고 좌고우면하거나 지체 또는 연기시키면 부리해지는 人`,0,0,`일이 순서적으로 진행됨**기이한 전화위복사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 공격할 뜻이 있어도 행동으로 나서지는 않음 `,0,0,`자기 스스로 유약하다고 여기고 적극적 활동을 포기하고 신음하는 人`,0,0,`사방이 막힌 격임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성-무력`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`좌고우면`,`선성-무력`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신-무력`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사-급속사겹침`,`미사`,`신사`,`내사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해함`,`화해함`,`화해함`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,1,3,`음7월`,2,2,4,`음1월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,2,2,4,`음12월-연기 또는파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음7월`,3,1,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음2월`,2,2,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음6월`,1,1,2,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음5월`,2,1,3,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월-파혼우려`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음11월`,2,1,3,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음10월`,3,2,5,`음4월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,3,3,6,`음3월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월`,2,3,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 타인과 같은 소리를 내며 업무도 수준이하로 유지됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 중반 외에 초반 종반에 수준이하의 실적이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많아 업무를 초중반에 실패하고 종반에 이르러 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 복잡한 여러 일을 일관시키는 결속단합을 꾀하여 완성하나 결국 큰 피해를 초래하게 됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하나 업무는 기이한 행운으로 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반을 공허 실패로 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 권리행사의 결여를 초래하고 내부를 공허하게 하며 오직 자기만 내세워 수준을 전락시킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하여 여러 복잡한 사안을 일관시키는 단합결속을 완성하나 결과적으로 패착이 됨-단, 관공서라면 대 실적이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 타인과 같은 소리를 내며 업무도 초반에는 피해를 끼치고 중 종반에는 공허로 진행되어 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반을 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반이 수준이하로 되고 피해를 끼침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미가 있고 사심도 있으나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선난후이`,`進向`,`원`,`留`,`주인가해`,`남남서`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`주인조력`,`서남`,``,`정서`],
    [`10.11`,``,``,``,``,``,``,``,`주인이사`,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,`의사 참고`,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`약속차질`,`본인무력`,` 退背`,`원근왕래`,`  去`,`주인조력`,`정서`,``,`북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인주축`,`불이행`,`주축이됨`,`退背`,`  원`,`  去`,`주인가해`,`북서`,`북북서`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`권리양도`,`退背`,`  원`,`  去`,`세입가해`,`북북서`,`동북`,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대없음`,`불이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`정북`,`정동`,``],
    [`7.8월`,``,``,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`불이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`북북서`,`북북동`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`불이행`,`선합후합`,` 進向`,`  원`,`  留`,`세입조력`,`동북`,`동북`,``],
    [`10.11`,``,`제압불가`,``,``,`관공사에`,``,``,`설기피로`,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,`한정됨`,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`선난후난`,`退背`,`  원`,`  去`,`세입조력`,`정동`,`북서`,``],
    [`10.11`,`  集`,`내부반대`,``,``,``,``,``,`애증관계`,``,`세밀심사`,``],
    [`3.6\n9.12`,``,`내부제압`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선이후이`,`退背`,`  원`,`  去`,`주인가해`,`정남`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선난후이`,` 退背`,`  원`,`  去`,`무난`,`남남서`,``,`정북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`선攻후攻`,` 進向`,`  근`,`  留`,` 무난`,`정남`,``,`정남`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`신화부정`,`사망친족`,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`원한귀`,`불혼사망`,``,``,`묘좌`,``,`특별있음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`자살귀`,`목맴`,``,``,`묘좌`,``,`있음`],
    [``,``,`강력침범`,``,``,`묘향`,``,``],
    [`생업`,`자살귀`,`목맴`,``,``,`전경`,``,``],
    [``,``,`강력침범`,``,``,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,`정남`,`없음`],
    [``,``,``,``,``,`묘향`,`정북`,``],
    [`생업`,` 음귀`,`묘지탈`,``,``,`전경`,`바다포구`,``],
    [``,``,``,``,``,`묘속`,`화렴`,``],
    [`가정`,``,``,``,``,`육친`,`조부`,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,` 수귀`,`익사`,``,``,`묘좌`,``,`없음간`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`제례부정`,`제례위범`,`육친`,``,``],
    [`질병`,` 수귀`,`익사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,` 수귀`,`익사`,``,``,`육친`,``,``],
    [`질병`,`음귀`,`묘지틸`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`저주부정`,`下人원한`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,`전흉사귀`,`혈광사`,``,``,`묘좌`,``,`없음`],
    [``,`원한귀`,`불혼사망`,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,`없음`,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,`북북서`,`없음`],
    [``,``,``,``,``,`묘향`,`남남동`,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,`바다포구`,``],
    [``,``,``,``,``,`묘속`,`  水`,``],
    [`가정`,``,``,``,``,`육친`,`자매 5조모`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사 막힘사**숨긴 정인으로 인해 손해가 있음`,`도적 못잡음`,`외남내녀 동시색정사`,`酒食受罰사`,`외사 동요불안분산사`,`손괴재물사`,`외사 애증파재사`,`공공사사 구설사`,``,`고위안좌-이익경영사`,`매사 허성불성-세속허사자**매사위역사`,`음3,4,5,6,9,12월방문자는종교진출하면성공`,`매사영속성없음**내외 출행이동사`,`종교진출하면자립함`,`직녹자산권리사`,`경범소송사-고시 길함`,`외사 외교막힘사`,`숲속에서 뱀을 만난 격`,`내외 급속사`,`길 가는 고위인에게 청원 -좋음`,``,`간음무도사-이동사`,`매사위역사**외사급속송쟁-상해우려`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`전화위복`,`전도`,`빈궁**병재`,``,`재액해소`,`빈궁**병재`,`재액해소`,`관재`,`면굴난해-감옥관련`,`빈궁`,`주력사 흉함`,``,`음5월 화재주의**전화위복`,`빈궁`,``,`빈궁`,``,``,``,``,`침체경구사**남녀종업원모사有`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사**외사급속송쟁사-상해우려`,``,`내사 막힘사**숨긴 정인으로 인해 손해가 있음`,`도적 못잡음`,`외남내녀 동시색정사`,`酒食受罰사`,`외사 동요불안분산사`,`손괴재물사`,`외사 애증파재사`,`공공사사 구설사`,``,`고위안좌-이익경영사`,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**내외 출행이동사`,`종교진출하면자립함`,`직녹자산권리사`,`경범소송사-고시는 길함`,`외사외교 막힘`,`숲속에서 뱀을 만난 격`,`내외급속사`,`길 가는 고위인에게 청원-좋음`,``,`간음무도사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**관재**빈궁`,``,`빈궁`,``,`전화위복`,``,`전도**병재**빈궁`,``,`빈궁**관재`,``,`관재`,``,`면굴난해-감옥관련`,`전도**빈궁`,`주력사 흉함`,``,`음5월 화재주의**전화위복`,`빈궁**관재`,``,`전도**빈궁`,`재액해소`,``,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`남 모르는 능욕박해사`,``,`매사위역사**매사급속송쟁사-상해우려`,``,`내사 막힘사`,`필시성취사`,`외남내녀 동시색정사`,``,`외사 동요불안분산사`,`모사미수사`,`외사 애증파재사`,`공공사사 구설사`,``,`가정경구불녕-가족 중 절상사 有`,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**내외 출행이동사`,`종교진출하면자립함`,`직녹자산권리사**문서가 남모르게 움직임  `,`경범소송사-고시는 길함`,`외사외교 막힘`,`송구경황-색정사 관련`,`내외 급속사`,`길 가는 고위인에게 청원-좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`빈궁`,``,`재액해소`,``,`재액해소**전화위복`,`빈궁`,``,``,`빈궁**관재`,``,`관재**병재`,``,`관직자 좋음-일반인 불리`,``,`도적은 못잡고 두려움`,`빈궁**관재`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,``,``,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,``,`내외 급속사`,`도적악의-공격말 것`,``,`숨겨진 정인으로 인해 손해가 발생함`,`매사위역사**외사급속송쟁사-상해우려`,`수신제가 되아 가나 있으나 좋음`,`내사 막힘사`,`일체근심해소사`,`외남내녀 동시색정사`,`외사 동요불안분산사`,`재난해소-복이 옴`,`문서투서헌책지체사-구설사`,`외사 애증파재사`,`사사성취사`,``,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사영속성없음**내외 출행이동사`,`종교진출하면자립함**상대 심중을 가늠하기 어려움`,`직녹자산권리사`,`경범소송사-고시는 길함**상대심중을 가늠키 어려움`,`외사외교막힘`,`손괴재물사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁`,``,`빈궁**병재`,``,`전도`,``,`전도`,`전화위복`,``,`빈궁`,`관재`,`재액해소`,`관재`,`재액해소`,`빈궁`,``,`관재`,``,`음5월 화재주의**전화위복`,`빈궁`,``,`관재**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`계획달성미수사`,`외사외교막힘`,``,`내외 급속사`,`간음사 이익-정대적은 되려 재앙됨**이동사`,``,`문서유실사`,`매사위역사**외사급속송쟁-상해우려`,`흉을 만나 복으로 변함`,`내사 막힘사`,`일체근심해소사`,`외남내녀 동시색정사`,`외사 동요불안분산사`,`송구경황-색정사 관련`,`남 모르게 재물이 움직임`,`외사 애증파재사`,`모두 불리-도난사`,``,`매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**내외 출행이동사`,`종교진출하면자립함**재앙소멸사`,`직녹자산권리사`,`상대 심중을 가늠키 어려움`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**병재**빈궁`,``,`전도**관재`,``,`빈궁`,``,`빈궁`,`재액해소`,`관재`,`재액해소**전화위복`,``,``,`빈궁**관재`,``,``,`빈궁**관재`,``,`관직자 좋음-일반인 불리`,``,`빈궁**병재`,`음5월 화재주의**전화위복`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`직녹자산권리사**빈궁`,`상대 심중을 가늠키 어려움`,`계획달성미수사`,`외사외교막힘`,``,`내외 급속사`,`간음사 이익-정대적은 되려 재앙됨**이동사`,``,`문서유실사`,`매사위역사**외사급속송쟁사-상해우려`,`흉을 만나 복으로 변함`,`내사막힘사`,`일체근심해소사`,`외남내녀 동시색정사`,`외사 동요불안분산사`,`송구경황-색정사 관련`,`남 모르게 재물이 움직임`,`외사 애증파재사`,``,`모두 불리-도난사`,`매사영속성없음**관직자 좋음-일반인 불리`,`종교진출하면자립함`,`매사영속성없음**내외출행이동사`,`종교진출하면자립함**재앙소멸사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음5월 화재주의`,`동요불안분산사`,``,`동요불안분산사`,`병재**빈궁**관재`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사**재액해소`,`관재**재액해소`,`동요불안분산사**전화위복`,``,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,``,`동요불안분산사`,`빈궁**병재`,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음**내외 출행이동사`,`종교진출하면자립함**재앙소멸사`,`직녹자산권리사`,`상대 심중을 가늠키 어려움`,`계획달성미수사`,`외사외교막힘`,``,`내외 급속사`,`간음사 이익-정대적은 되려 재앙됨**이동사`,``,`문서유실사`,`매사위역사**외사급속송쟁사-상해우려`,`흉을 만나 복으로 변함`,`내사 막힘사`,`일체근심해소사`,`외난내녀 동시색정사`,`외사 동요불안분산사`,`송구경황-색정사 관련`,`남 모르게 재물이 움직임`,`외사 애증파재사`,``,`모두 불리-도난사`,`매사영속성없음**관직자 좋음-일반인 불리`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,`음5월 화재주의**전화위복`,`빈궁`,``,``,`전도**병재**관재`,``,``,``,`빈궁`,``,`빈궁**관재`,`재액해소`,``,`재액해소**전화위복`,``,``,`빈궁**관재`,``,`빈궁**관재`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**관직자 좋음-일반인 불리`,`종교진출하면자립함`,`매사영속성없음**내외 출행이동사`,`종교진출하면자립함**재앙소멸사`,`상대 심중을 가늠키 어려움`,`직녹자산권리사`,`계획달성미수사`,`외사외교막힘`,`종종걸음의 반복사-門관련`,`내외 급속사`,``,`간음사이익-정대적은 되래 재앙임**이동사`,`문서유실사`,`매사위역사**외사급속송쟁사-상해우려`,`흉을 만나 복으로 변함`,`내사 막힘사`,`일체근심해소사`,`외남내녀 동시색정사`,`외사 동요불안분산사`,`송구경황-색정사 관련`,`남 모르게 재물이 움직임`,`외사 애증파재사`,`모두 불리-도난사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁**병재`,``,`빈궁**재액해소`,`음5월 화재주의**전화위복`,``,`재액해소`,``,`빈궁**병재`,``,``,`빈궁`,``,`전도**빈궁`,``,`전도`,`전화위복`,``,``,`빈궁**관재`,``,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`모두 불리-도난사`,``,`매사영속성없음**관직자 좋음-일반인 불리`,`종교진출하면자립함`,`매사영속성없음**내외 출행이동사`,`종교진출하면자립함**재앙소멸사`,`상대 심중을 가늠키 어려움`,`직녹자산권리사`,`계획달성미수사`,`외사외교막힘`,`내외 급속사`,`종종걸음의 반복사-門관련`,`간음사 이익-정대적은 되려 재앙임**이동사`,``,`문서유실사`,`매사위역사**외사급속송쟁사-상해우려`,`흉을 만나 복으로 변함`,`내사 막힘사`,`일체근심해소사`,`외남내녀 동시색정사`,`외사 동요불안분산사`,`송구경황-색정사 관련`,`남 모르게 재물이 움직임`,`외사 애증파재사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,``,`전도**빈궁**병재`,``,`음5월 화재주의**전화위복`,`전도**빈궁`,``,``,`빈궁**병재`,`재액해소`,``,`재액해소`,`빈궁`,``,`빈궁`,``,``,`전화위복`,``,``,`빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사 애증파재사`,`공공사사 구설사`,``,`고위안좌-이익경영사`,`매사영속성없음**매사위역사`,`종교진출하면자립함`,`매사 영속성없음**내외 출행이동사`,`종교진출하면자립함**주력사 흉함`,`경범소송사-고시는 기함`,`직녹자산권리사`,`숲속에서 뱀을 만난 격`,`외사외교막힘`,`내외 급속사`,`길 가는 고위인에게 청원-좋음`,``,`간음무도사-이동사`,`매사위역사**외사급속송쟁사-상해우려`,`숨겨진 정인으로 인해 손해가 발생함`,`내사 막힘사`,`도적 못잡음`,`외남내녀 동시색정사`,``,`외사 동요불안분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**관재`,`재액해소`,`관재`,``,`빈궁`,``,``,`음5월 화재주의**전화위복`,`빈궁`,``,`빈궁`,``,``,``,``,`빈궁`,``,`전도**빈궁`,``,`전화위복`,`전도`,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외사 동요불안분산사`,``,`외사 애증파재사`,`공공사사 구설사`,``,`고위안좌-이익경영사`,`매사 영속성없음**매사위역사`,`종교진출하면자립함`,`매사 허성불성-세속허사자**내외출행이동사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`경범소송사-고시는 길함`,`직녹자산권리사`,`숲속에서 뱀을 만난 격`,`외사외교막힘`,`내외 급속사`,`길 가는 고위인에게 청원-좋음`,``,`간음무도사-이동사`,`매사위역사**외사급속송쟁사-상해우려`,`숨겨진 정인으로 인해 손해가 발생함`,`내사 막힘사`,`도적 못잡음`,`외남내녀 동시색정사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,`재액해소`,`빈궁**관재`,`재액해소`,`관재`,``,`빈궁`,`주력사 흉함`,``,`음5월 화재주의**전화위복`,`빈궁`,``,`빈궁`,``,``,``,``,`빈궁`,``,`폐한인`,`전도**빈궁`,`전화위복`,`전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외남내녀 동시색정사`,``,`외사 동요불안분산사`,``,`외사 애증파재사`,`공공사사구설사`,``,`고위안좌-이익경영사`,`매사 허성불성-세속허사자**매사위역사`,`음3,4,5,6,9,12월 방문자는 종교진출하면 `,`매사 허성불성-세속허사자**내외출행이동사`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`경범소송사-고시는 길함`,`직녹자산권리사`,`숲속에서 뱀을 만난 격`,`외사외교막힘`,`내외 급속사`,`길 가는 고위인에게 청원-좋음`,``,`간음무도사-이동사`,`매사위역사**외사급속송쟁사-상해우려`,`숨겨진 정인으로 인해 손해가 발생함`,`내사 막힘사`,`도적 못잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`재액해소`,`빈궁**병재`,``,`빈궁**관재`,``,`관재`,`빈궁`,`성공함`,`주력사 흉함`,`전도`,`음5월 화재주의**전화위복`,`전도**빈궁`,`재액해소`,`빈궁`,``,``,``,``,`남녀종업원모사有**재액해소`,`빈궁`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 모든 일이 잠복해 있으니,      사람까지 납작 엎드려 있     어야 할 운세네!
  분수를 벗어나면 골육까지 등돌리게 생겼어!
  
  ⊕신변상가부정 침범
  ⊖신변음사부정이 침범했네?
  `,`■ 일은 막히고, 아랫사람은     공모해서 손해나게 할 것     이고… 여자 때문에 관재     기운도 보이고
  
  ⊕⊖가택에 여고음강 부정을      빨리 없애야되겠어!
  `,`■ 지금 놀래고 있는 일은      해소가 되겠는데,
  ⊕남의 여자를 유혹하여거래하네!  
  
  ⊕자살귀, 
  ⊖가택귀 훼방이 문제야! 
  
    그런데 속없는 자식 때문에    오히려 복이 될 일이 있겠어!
  `,`■ 처음부터 말뿐이니 사람     에게 기대할 것이 없겠어!
  ⊕변사귀, 
  ⊖원한귀 침범을 해소해야만    원하는 것을 얻겠어!
  
  ⊕신변상가부정 침범
  ⊖신변음사부정이 침범했네?
  `,`■ 여건도 안되고 골육은 등을     돌리고 윗사람은 아랫사람을     속이고 바람도 나고…
     게획과 방침을 변경하고
  ⊕자살귀, 
  ⊖전흉사귀 발동이야! 
    해소시키면 고진감래가 될거야!
  
  ⊖신변상가부정이 침범했어!
  `,`■ 내집 넘겨주고 남의 집살이     하게 생겼네? 
     옛일이나 지금일이나 모두     끝나고 새로 천거될 일이     생기겠어!
  ⊕음귀, 
  ⊖원한귀를 없애면 반드시     전화위복이 되겠어!
  `,`■ 배반당했네? 
     만사가 뒤틀렸겠어! 
     색정불화로 부부가         별리되는 운세야! 
  
     일도 두가지로 쪼개졌어!
  `,`■ 제가(濟家)가 안되었네? 
     여자 때문에 기로에 섰어!관직자:巳月에기쁨이오겟어!이게 다 
  ⊕수귀, 
  ⊖전흉사귀가침범해서그래!     막으면 큰 돈이 들어오게    생겼어!
  `,`■여러 일을 하나로 묶느라가지많은 나무 바람 잘 날이    없게 생겼네?
  ⊕수귀, 
  ⊖전흉사귀 때문이야! 해소    해야 여자가 돈 보따리를    들고 찾아오게 될거야!
  
  ⊕신변음사부정이 침범했어!
  `,`■ 마음에 천둥이 치겠네?
   부부가 각기 딴 정을 갖고     있으니 놀랠일만 벌어지고      일도 끝이 안나지!
  ⊕음귀, 
  ⊖원한귀 장난이야! 
  이를 막아야 소원이 이루어지지!
  ⊕신변저주원심부정 침범
  ⊖신변여고음강부정이 침범했네?
  `,`■ 마음이 떠 있네 !
     급히 지금의 계획과 방침을     확 바꿔야 일이 되겠어!
  그대로 있으면 일이 어려워!
  ⊕수귀와 
  ⊖가택귀도 해소시키고! 
    
  `,`■ 좋네, 좋아! 
     그런데 집에 귀신이 많아!
  
  ⊕변사귀ㆍ자살귀, 
  ⊖원한귀ㆍ가택귀를 잡아주면    만사형통이야!`];
  const YOGYEOL1 = [`■ 재물에 도적이 들었으니 도적을 여러번 맞게 생겼다. 말전에 白ㆍ蛇가 들어 남모르는 슬픔을 겪으리라.
  `,`■ 내 녹을 버리는 것을 좋아하지 말라. 다른 일도 막혀 안된다.
  가정에 부정이 들어 백가지가 막힌다.
  `,`■ 모두 여자만 가득하여 손해나고 막힌다. 
  손사자나 방랑자가 집에 있고 자손이나 종업원으로 속상한다.
  `,`■ 사람이 답답하겠으나 여러사람의 도움은 있다. 손해날 것 같으나 그렇지 않으니 집을 지키는 것이 마땅하다.
  `,`■ 나와 너 모두 극을 받으면서 손해를 피해 재물을 취하나 계속 돌고 돌아 내게 재앙이 오게 되고 만다.
  `,`■ 서로의 교섭은 화목하나 직녹문제가 걱정이고 자식이나 부모일도 좋게 보지 않는다.
  `,`■ 오나가나 모두 빈 것 뿐이다. 부모나 모시는 분의 일도 좋지 않다. 처첩과 주종이 싸우니 항상 화목하지 않다.
  `,`■ 집안이 그늘이 끼어 어둡다. 이웃 또한 나쁘나 귀인의 재물이 들어온다. 낮정단은 귀인이 나를 돕는다.
  `,`■ 밤정단은 재물이나 교색녀가 내게 오지만 너무 좋아하면 흉하다. 
  다만 일찍 버리는 것이 마땅하니 나중에 적과 내통하는 것을 면한다.
  `,`■ 활도 있고 화살도 있으나 활은 없어지고 화살도 떨어졌으니 하고자 하는 바를 이루지 못할 것이다.
  `,`■ 주색으로 손상되는 것이 내몸에 올랐다. 
  그 외 음침한 것이 가득하니 모든 정단이 아득하리라.
  `,`■ 사람과 집위에 모두 귀인이 올라 기특함이 있을 것이나 군자는 좋지만 일반인은 도리어 나쁘리라.`]
  const SIBO1 = [`■ 吉은 적고 凶은 많다.
  일은 사적으로 도모해야
  이롭다.
  사방이 폐색되어 통하지 못한다. 엎드려 신음할 뿐이다. 밤 정단은 도난사가 있거나 부도가 났다.
  2건의 財가 있으나 분수를 지켜야 한다.
  `,`■ 가정에 부정이 들어
  금전의 손모가 있고
  일이 막혀있다.
  후퇴하는 처신이 吉하다.
  대기해야 한다. 사용인 남자와 여자가 공모하여 주인을 해치거나 도망가게 된다. 단, 공명ㆍ취직ㆍ시험 등은 매우 吉하다.
  `,`■ 후원사는 빈말이고 
  부모의 재화가 기한다.
  과전이 음뿐으로 혼매한
  사정에 있다.
  즐거움이 없으며 연정사가 연이어 있다.
  일이 갈림길에 놓여 있고  
  ⊕남자가 타인의 부인을 유혹하여 거래하고 있다.
  귀신을 잡으면 두려움은 없게 된다.
  `,`■ 사업ㆍ후원 모두 말뿐이고
  진정이 없다.
  모든 목적사가 달성되지
  못하고 반가운 소식도 없다.
  타인의 추천이나 천거도 기대가 안된다. 오로지 귀신을 해소해야만 간난신고후 얻는 것이 있고 길행을 불러올 수 있다.
  부모의 재해가 발생한다.
  `,`■ 자충수적 우환이 있다.
  무슨일이나 지체된다.
  피차의 손상을 방지해야 한다.
  윗사람으로부터 압박과 기만을 당해 수비게 펴지지 않는다. 
  태아의 손상사가 있거나 임신에 대한 열망이 있다.
  女는 색정사가 있다.
  관공직사는 매우 좋다.
  다인 다사건이다.
  `,`■ 자충수적 우환이 있고
  직위나 재산이 위태롭다.
  가택의 권리가 넘어간다.
  교섭ㆍ타협사는 좋으나 귀신을 제어시키면 효과가 크다.
  `,`■ 저주받는 일이 있다.
  부모의 재앙이 있다.
  동요하여 매사가 공허하다.
  골육의 분리가 걱정된다.
  전진할 뜻이 있으나 여의치 않고 일이 두가지로 번졌다. 문서유실을 방지해야 한다.
  `,`■ 가정에 부정이 들었다.
  일의 계획이 비용과다로
  파산 지경이나 다시 열리게
  된다. 집수리ㆍ축조가 吉하다. 일이 두 갈래로 번져 결정을 못하고 고심한다. 하나로 하되 근친자로 하라.
  무엇이든 하나로 묶으면 큰 돈이 된다. 귀신과 부정을 해소하라.
  正月 임신사가 있다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  자기 집이 없는 형편에 있다. 먼저 굽히고 일을 공동으로 하면 이루어진다.
  구하는 일을 서둘지 말라. 
  늦게 이루어진다.
  다인 다사건이다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  부부사이에 서로 외정을 가져 반목ㆍ대립한다.
  심중 놀램과 걱정이 크나 귀신을 해소하면 자연 해소된다.
  ⊕일이 반복되고 결실이 없다
  `,`■ 아침을 향해 나아가나
  미명일 뿐이다.
  내부에서 찾는 귀인도
  그 힘을 돕지 못하게 된다.
  특히 아랫사람이나 이성으로 인해 실패의 근심이 야기된다. 그러나 길흉간 흉은 경미하고 관공직사는 吉하다.
  부모의 凶기하게 된다.
  `,`■ 귀인이 흉을 지키므로
  모든 일에서 전화위복을
  얻게 된다.
  그러나 서둘지 말고 시기를 조절하는 지혜를 가져야 한다. 관리는 승진ㆍ영전이 있다. 단, 형제의 손상이 걱정이다.
  正月 임신사가 있다.`]
  const SIBO2_1 = [`■가택금전내사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  부동신음의 상이다.
  매사 중도폐기 된다.
  `,`■손모신변,대립사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  人宅이 쇠퇴의 상이다
  `,`■官ㆍ夫,내외가정동요사다.
  내방조짐 상의 : 
  은근하고 伏하라.
  이전 이사를 한다.
  자손상해, 불구의 상이다.
  `,`■가택동요후원사이다.
  내방조짐 상의 :  
  원행·이동사가있다.어떤 일이든 처음 불성한다.손재·녀하고 형제 재액이 있다
  `,`■손모신변외사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다.
  병재·관재 예방이 필요하다.
  `,`■직ㆍ녹,후원신변외사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  가택권리가 양도된다.
  `,`■가택동요후원내사이다.
  내방조짐 상의 : 
  원행·이동사가있다.
  어떤 일이든 처음 불성한다.
  `,`■가택동요후원내사이다.
  내방조짐 상의 :  
  원행·이동사가있다.어떤 일이든 처음 불성한다.손재·녀하고 부모의 재액이 있다
  `,`■손모신변외사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다.
  병재·관재 예방이 필요하다.
  `,`■가정,官･夫,내사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  부부간사 악의상이다
  `,`■골육상배동요신변사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■골육상배동요신변사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단을은잡는다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/036/row-1-column-1.png`,
    `/static/images/sagwa/036/row-1-column-2.png`,
    `/static/images/sagwa/036/row-1-column-3.png`,
    `/static/images/sagwa/036/row-1-column-4.png`,
    `/static/images/sagwa/036/row-1-column-5.png`,
    `/static/images/sagwa/036/row-1-column-6.png`,
    `/static/images/sagwa/036/row-1-column-7.png`,
    `/static/images/sagwa/036/row-1-column-8.png`,
    `/static/images/sagwa/036/row-1-column-9.png`,
    `/static/images/sagwa/036/row-1-column-10.png`,
    `/static/images/sagwa/036/row-1-column-11.png`,
    `/static/images/sagwa/036/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `    이 과는 12신이 천지동궁(天地同宮)에 있다. 복음과로서 4과에 상하의 극이없고 음일은 일지상신을 초전으로 하며, 초전이 자형(自刑)이니 일간상신을 중전으로 하고 말전은 충의 축(丑)으로서 조식(造式)한다. 그래서 음일은 자신두전격(自信杜傳格)으로 된다.
    엎드려 움직이지 않으므로 靜이 극(極)하다. 움직이는 일간의 왕쇠와 천장의 길흉으로써 재복(災福)을 정단한다. 두전격은 폐색(閉塞)되어 통하지 않는다. 임신을 정단하면 농아(聾啞)일 두려움이 있다. 자신(自信)이란 음일이므로 스스로 유약하다고 믿는 것이다.
    초전의 길(吉)은 움직일 수 있으며, 흉하면 대기하는 것이 좋다. 일지음양신(日支陰陽神)이 초전으로 되니 일간의 재는 겹쳐 보게 된다. 현무가 승하면 가끔 도난을 만나고 어음거래는 부도가 나게 된다. 과전이 전부 음지이므로 공사(公事)에는 불리하고 음으로 도모하는 일은 유리하다. 두전격에서는 장애를 방비하고 새롭게 고쳐 하면 이루어진다. 재가 많고 사호(蛇虎)가 승하면 남에게서 겁탈을 당하므로 충분한 주의가 필요하다. 또 구재에서도 욕심내지 말고 분수를 지키는 것이 길하다.
    백법에 ｢둔귀(遁鬼)에 백호가 승하는 것은 재앙을 본다면 적지않다｣고 하였다. 비전에 이르기를 ｢복음과는 간지가 모두 그 자리에 있다. 따라서 일간일지의 두 과뿐이며 음양 두 과로서 불비의 형상이니 양일에는 간상을 사용하고 음을 버리며, 음일에는 지상(支上)을 사용하고 양을 버리는데, 천지가 모두 독립한다. 따라서 부족한 체(體)이다｣라고 하였다. 일귀는 순행하여 3전이 후(后)․백(白)․사(蛇)이고, 야귀는 역행하여 3전이 현(玄)․사(蛇)․백(白)이다. 밤에도 천장(天將)의 백(白)․사(蛇)가 앉을 때는 이 과뿐만 아니라 모든 정단에 흉한 것이 많으므로 삼가 경계해야 한다.`
    ,
    // 2
    `  이 과는 4과 중에서 제3과 만이 1상이 1하를 극하므로 술해(戌亥)를 사용하여 초전으로 하니 원수퇴여참관과(元首退茹斬關課)이다. 일간상신의 오(午)는 일록으로 되고 술(戌)의 천괴(天魁)가 해(亥)에 임하여 초전으로 되므로 괴도천문격(魁度天門格)이다. 백법의 51법에 해당하는데 이것을 인용하여 설명한다. ｢이 과는 술(戌)을 천괴(天魁)라 부르고 해(亥)는 천문(天門)이라 부른다. 무릇 술(戌)이 해(亥)에 가해져서 초전을 이룰 때는 이 격으로 된다. 모사 및 기타의 정단사에는 모두 조격(阻隔)을 생한다. 술(戌)이 해(亥)에 가해져서 초전으로 될 때는 천장에 의하여 길흉을 간점해야 한다. 3전이 술유신(戌酉申)이면 퇴여격이니 물러서서 지키는 것이 길하다. 일간상신에 주작이 승하니 공명․구관․입시 등은 반드시 목적을 달성한다｣고 하였다.
    백법에는 ｢녹왕(祿旺))이 신(身)에 임하는 것은 망동하지 말고 대기하면 녹을 받는다｣고 하였다. 괴도천문격은 조격되는 일이 있다. 
    비전에서는 ｢술(戌)과 유(酉)가 중첩되고 현무에 태음이 승하면 사용인 남녀가 공모하여 주인을 해치거나 남녀가 공모하고 도망한다｣고 하였다. 일귀는 순행하여 3전이 음(陰)․현(玄)․상(常)이고, 야귀는 역행하여 3전이 음(陰)․후(后)․귀(貴)이다.`
    ,
    // 3
    `    이 과는 4과에 모두 상하의 극이 없으며 제2과 상신(上神)에서 일간을 극하여 요극호시격(遙剋蒿矢格)으로 된다.
    중전 축(丑)의 탄환(彈丸)이 있으나 묘목(卯木)이 이것을 제어하므로 힘이 없으며, 4과3전이 전부 육음이니 약간 혼매(昏昧)한 징조가 있다. 초전의 묘목(卯木)은 일간을 극하므로 칠살(七殺)이지만 흉살(凶殺)이 공망되니 두려울 것이 없다. 초는 중전을, 중전은 말전을 체극(遞剋)하므로 화기가 없다. 말전 해수(亥水)는 재효이다. 
    백법에서는 ｢3전에 후․합이 있으니 점혼에서는 남녀 자유 결혼이며, 육음이 서로 연결되므로 모든 일에서 혼미한 형상으로 된다｣고 하였다. 
    비전에는 ｢일간상신에 역마가 승하고 지상신에 순정(旬丁)이 앉아 인택이 모두 움직인다｣라고 하였다. 수면도 안온하지 못하고, 또 사해(巳亥)는 호환(互換) 역마로 되어 갈림길이 있다.
    일귀는 순행하여 3전이 합(合)․사(蛇)․후(后)이고, 교동격(狡童格)으로 되며 남자가 여자를 유혹한다. 야귀는 역행하여 3전이 청(靑)․백(白)․현(玄)이다.`
    ,
    // 4
    `  이 과는 4과 중에 상하의 극이 없고 제4과 사(巳)에서 신(申)을 극하여 초전으로 되니 원수과(元首課)이며 현태체생격(玄胎遞生格)이다. 과전(課傳)에 공망이 많고 일간상신의 진(辰)은 묘신이지만 공망을 즐기며, 초․중전의 역마가 공망한다. 모든 동의(動意)나 음신(音信)에서 목적이 달성되지 못한다.
    백법에는 ｢화개(華蓋)가 일(日)을 덮으니 사람은 혼회(昏晦)한 징조가 있고, 3전이 체생하여 일을 생하므로 만사에서 길행을 얻는다｣고 하였다. 
    비전에는 ｢일간 또는 일지상신에 천강이 승하면 남과의 면회 약속 상담에서 위약하지 않고 실행된다｣고 하였다. 초전의 공망은 과숙격(寡宿格)으로 되고 체생하므로 공망은 남의 천거에 의한 것이지만 말뿐이고 진정(眞情)이 없다. 일간상신에 진(辰)의 천강이 승하면 간난신고한 뒤에 물건을 얻는다. 
    일귀는 순행하여 3전이 청․주․후이고, 야귀는 3전이 현․공․합이다.`
    ,
    // 5
    `  이 과는 4과 중 제3과에서 상이 하를 적하므로 지일ㆍ중심과(知一ㆍ重審課)를 취할 수 없다. 그러므로 극이 많은 것은 섭해과(涉害課)로 되어 지반4맹신(地盤四孟神)의 극이다. 제3과 미해(未亥)를 사용하여 초전으로 하니 곡직도액격(曲直度厄格)을 구성한다. 4과이지만 1과가 결여되니 불비격(不備格)으로 된다.
    과전이 전부 3합 목국(木局인데, 일간기궁은 일지상신에 임하여 묘(卯)를 버리고 일지 해(亥)의 재에 붙는다. 3전이 4과를 떠나지 않으니 회환격(廻環格)으로 간점 사건은 길흉이 모두 흩어지지 않고 무슨 일이나 지체된다. 또한 일간상신은 묘(卯)의 칠살에 해당되지만 살해를 피해 일지의 재(財)에 붙는다. 백법에 ｢일간일지가 모두 상신(上神)에서 극되는 것은 피차가 손상을 방비해야 한다｣고 하였다. 일야천장(日夜天將)은 백호가 승하니 재앙이 3합이 되면 부녀자가 사정(私情)을 품는다｣고 하였다.
    섭해과는 4과 중에 극적이 많은데. 그 극의 심천을 분명하게 하여 지반에 돌아 극이 많은 것을 사용하고, 극이 상등한 것에 의하여 맹신(孟神) 또는 중상신(仲上神)을 취하여 왔다. 그러나 맹상신의 극을 취하되 이것이 없을 때는 중상신을 채용하는 방법도 있다. 과전이 모두 같은 3합회국을 이루면 불비격이지만 반응이 극히 경미하므로 중요시할 것 없다. 3상(三上)에서 극하는 것은 유도액(幼度厄)이라 부르는데, 윗사람으로부터 압박을 받아 위축된 것을 펼 수가 없다. 섭해과는 사물이 지체되고, 병은 빨리 치료할 수 없으며, 하를 극하니 태아에 손상이 있거나 임신으로 인해 애를 쓴다. 모두가 처음은 곤란하고 뒤에는 길하며, 즉 풍우를 맞아 고진감래하는 형상이다.
   백호가 3전에 들어 관귀효(官鬼爻)는 최관부(催官符)라 부르는데 현임 관리라면 부임․천관이 속하다. 그러나 보통 사람은 화환(禍患)을 면치 못한다. 일귀는 순행하여 3전이 백(白)․합(合)․후(后)이고, 야귀는 3전이 후(后)․백(白)․합(合)이다. 후(后)․합(合)이 승하면 자유결혼격이다.`
    ,
    // 6
    `  이 과는 4과 중에서 세 곳에 상하의 극이 있으며 하에서 상을 적(賊)하는 오해(午亥)를 사용하여 초전으로 하니 중심과이다. 순수(旬首)의 오(午)가 초전으로 되면 육의격(六儀格)이다.
    일간일지는 교차지합(交車支合)하여 교섭․타협하는 일에서는 좋으나. 오화(午火)가 해(亥)에 임하므로 건록이 절지(絶地)에 앉는다. 육의는 모두가 흉을 구(救)하는 구신(救神)이 된다. 
    일록(日錄)이 지(支)의 절(絶)에 임하면 스스로 존대(尊大)할 수 없으며, 새롭게 계획하는 것을 즐기지 않고 구태는 끝맺는 것이 좋다. 
    백법에 일지상신에 일록이 승하는 것은 권섭부정격(權攝不正格)으로 된다고 하였다. 초․중․말이 순으로 생하는 것은 체생격으로 되어 사람의 천거를 받는다. 
    일귀는 역행하여 3전이 구(勾)․후(后)․귀(貴)이고, 야귀는 공(空)․청(靑)․음(陰)이다.`
    ,
    // 7
    `  이 과는 천지대충(天地對冲)에 있고 사해(巳亥)의 하에서 사를 극하는 것을 초전으로 하여 반음원태과(返吟元胎課)이다. 3전 사해사(巳亥巳)는 모두 공망으로 된다. 사(巳)는 일을 생하는 인수생기(印綬生氣)이지만 해(亥)의 제지(制地)에 있고 또 공망되므로 윗사람의 재앙이 있다. 중전은 처재이지만 충격 왕래가 일정하지 않을 뿐만 아니라 개공이므로 길흉이 모두 이루어지지 않는다. 모두가 동요하여 공허하게 끝날 뿐이다. 고진과숙(孤辰寡宿)은 골육의 분리를 방비해야 한다. 구류술업(九流術業) 종사자나 승도(僧道)에게는 흉을 이루지 않는다.
    백법에서는 ｢거래객(去來格)이 모두 공망하는 것은 동의가 있으나 움직일 수 없고, 공여격(空如格)은 모든 일을 휴식하고 추구하지 말라, 목적이 달성되지 않는다｣라고 하였다
    비전에서는 ｢역마의 공망은 전진하려 해도 전진할 수 없으며 갈림길에 있고, 인수가 역마로 되어 문서류는 중도에서 유실된다｣고 하였다.
    일귀는 역행하여 3전이 백(白)․사(蛇)․백(白)이고, 야귀는 순행하여 3전이 현(玄)․합(合)․현(玄)이다. 3전에 백호․등사가 승하여 공망하면 헛된 놀람을 만난다.`
    ,
    // 8
    `  이 과는 4과 중 세 곳에 상하의 극이 있고 음일이니 음비라 부르며, 사자(巳子)의 음의 극을 사용하여 초전으로 하니 지일비용과(知一比用課)로 된다. 3전이 동술묘(巳戌卯)인 것은 주인격(鑄印格)으로서 초․중전이 공망하여 손모(損模)라 부르며 파격(破格)으로 된다. 
    초․중전의 공망이므로 일의 계획이 비용의 과다지출로 파산된다. 일지상신 진(辰)은 중전 술(戌)과 충하여 개묘(開墓)로 되니 모든 정체가 열린다. 백법에 ｢전후인종격(前後引從格)은 승진의 기쁨이 있고 또는 가옥을 수리하여 길조를 얻는다｣고 하였다. 비전에는 ｢일간상신에 귀신이 승하여 재로 되고 일지상신이 참된 고(庫)로 되면 재가 택으로 돌아가므로 대리 대재를 얻는다｣고 하였다.
    귀신이 묘(卯)에 임하는 것은 여덕격이다. 지일과는 양일에는 양의 극을 사용하고 음일에는 음의 극을 사용하므로 지일비용이라고 한다. 이 과는 2하에서 2상을 적하므로 사물은 두 갈래에 걸치고, 일간과 비(比)를 사용하므로 오로지 하나로 돌아가니 다투는 일은 화해하는 것이 좋다. 무슨 일에서나 함께 모사하고 함께 이롭게 하는 방침이 좋다. 찾는 사람이나 잃어버린 물건은 가까이에서 찾으라. 장사․경영 모두에서 붕우와 지인과 타협하는 것이 길하니 둘을 버리고 한뜻으로 집중해야 한다. 소원한 것을 돌보지 말고 근친에게 붙으라.
    주인승헌(鑄印乘軒)은 현임․구관에 모두 길하나 사(巳)의 공망은 인모(印模)가 이루어지지 않는다고 하여 목적이 달성되지 않는다. 그러나 그 사람의 년명이 사(巳)의 공망에 승하면 전실(塡實) 공망는 공망을 풀어 주인(鑄印)이 이루어진다. 
    일귀는 역행하여 3전이 백․주․현이고, 야귀는 순행하여 3전이 현․주․백이다.`
    ,
    // 9
    `  이 과는 2하가 2상을 극하여 중심과(重審課)를 취할 수 없으므로 섭해과(涉害課)로 된다. 지반맹신의 극이 없기에 중신의 극인 미묘(未卯)를 사용하여 초전으로 하니 3합곡직격(三合曲直格)을 구성한다.
    일지가 일간상신에 와서 극을 받으므로 췌서격에 해당한다. 4과가 불비하면서 지(地)가 지상(支上)에 탈기로 되면 올바른 주택이 없이 남에게 의뢰하는 형상이다. 3전이 4과상신을 떠나지 않으므로 회환격(廻環格)으로 되어 길흉이 함께 이루어진다. 해산(解散)이나 걱정스러운 일은 이롭지 못하고, 3전이 3합목국(三合木局)하여 중귀(衆鬼)로 되니 모든 일에서 예방을 먼저해야 한다. 과전이 모두 3합하는 것은 화미격(和美格)으로 흉이 전변하니 단결하여 공동으로 하는 일은 모두가 이루어진다. 3합목국은 전부 목(木)이다. 역(易)에 이르기를 ｢제(帝)는 진(震)으로 나간다. 목(木)은 동방의 기재(器材)로 되고 기공(技工)으로 된다｣고 하였다. 시령(時令)은 봄의 양기가 왕성하여 진(震)으로 되고 움직여 반드시 화창하게 된다. 조용하면 번영하여 무성한 재를 구하는데 배가 된다.
    점사는 먼저 곡절이 있고 후에는 곧게 되며 병점 및 소송 사건은 오래 끈다. 물건을 급하게 구하여도 달성되지 않으니 대기만성의 기분이 좋다. 췌서격은 일지가 간상에 와서 극되니 처가 남편에게 순종한다. 또 몸으로써 발재에 힘쓰며 년명상신에 길장이 앉으면 길조를 얻는다. 3전이 3합관귀효(三合官鬼爻)이니 구관․구직․부임은 속하다.
    일귀는 역행하여 3전이 청(靑)․사(蛇)․현(玄)이며, 야귀는 순행하여 3전이 후(后)․합(合)․백(白)이다. 백법에 ｢후(后)․합(合)의 점혼은 자유 결혼의 징조｣라고 했다. 목(木)은 봄에 왕하고 여름에는 무성하므로 장래의 사업은 원대하고 유망하다. 미월(未月)은 중전에 천조(天詔)가 승하여 반드시 길보가 있다.`
    ,
    // 10
    `  이 과는 4과 중에 상하의 극이 없고, 제3과 인(印)에서 일간을 극하여 인해(寅亥)가 초전으로 되어 요극현태격(遙剋玄胎課)이다.
    천반에서 일간이 극되어 초전으로 되는 것은 호시(蒿矢)라 부르는데, 이 격은 금(金) 또는 토(土)가 있으면 화살(箭矢)로 된다. 신금(申金)이 있지만 공망하여 힘이 없으니 해를 이루지 않는다.
    초전 인(寅)은 관성으로서 수(水)의 생지(生地)에 앉으므로 관직이 영진(榮進)한다. 즉 재와 관이 쌍전하여 명리를 점(占)하면 큰 효과를 이룩한다. 중․말전은 공망하여 전으로 돌지 않으므로 초전의 일신(一神)으로 길흉을 결정한다.
    백법에 ｢일간일지의 상하 및 교차상극(交車相剋)은 부부 사이에 사정(私情)이 있고, 서로 투합하지 못하는 것은 위로 지형(支刑)한다｣고 했다. 
    비전에서는 ｢요극과이면서 일간이 극되나 말전의 신(申)은 이것을 제어하므로 귀(鬼)는 도리어 기쁨으로 변한다｣고 하였다. 일귀는 역행하여 3전이 음․백․구이고, 야귀는 3전이 공․합․귀이다.`
    ,
    // 11
    `  이 과는 제3과․제4과에서 2상이 2하를 극하여 원수(元首)나 중심(重審)을 모두 취할 수 없으므로 섭해과(涉害課)로 된다. 맹신지반(孟神地盤)의 축해(丑亥)를 사용하여 초전으로 한다. 3전 축묘사(丑卯巳)는 순간전격(順間傳格)이다. 일간상에 정신(丁神)이 앉고 축묘사(丑卯巳)는 어두운 곳에서 밝은 곳으로 나오므로 출호격(出戶格)으로 된다. 그러나 말전의 사(巳)가 공망되므로 출양(出陽)할 수 없다. 중전 묘(卯)는 귀살로 되나 유(酉)에서 제어하니 재해에 걸리지 않으며, 해가 있어도 극히 경미하다.
    백법에 ｢점혼(占婚)하여 후․합이 승하면 중매인이 없다. 두 귀신(貴神)이 극되어 귀인의 힘을 얻지 못한다｣고 하였다.
    비전에는 ｢일간이 유(酉)의 상신에 탈기로 되고 천후(天后)가 승하면 금(金)에서 또 탈기하여 탈상탈(脫上脫)을 만난다｣고 하였다. 아랫 사람의 이성(異性)으로 인하여 실패할 염려가 있다.
    일귀는 역행하여 3전이 후(后)․현(玄)․백(白)이고, 야귀는 3전이 백(白)․청(靑)․합(合)이다. 청룡관귀는 공명․임관․구직에서 모두 길하다.`
    ,
    // 12
    `  이 과는 4과 중 제4과에서 1상이 1하를 극한다. 축자(丑子)를 사용하여 초전으로 하니 원수진여과이다. 
    일간일지상신에 일야의 귀신이 앉으니 신(身)․택이 모두 귀기(貴氣)를 받거나 또는 3기(奇)를 만나면 더욱 길조가 있다. 관리라면 천관․진급하는 기쁨이 있다.
      3전이 축인묘(丑寅卯)로서 진여(進茹)로 되고 말전 묘(卯)는 태양이 해돋이할 때로서 광명을 얻는다. 중․말전의 인묘(寅卯)는 관귀이나 일간 음양신에서 제어하여 두려울 것 없다. 
      백법에는 ｢일간일지상신에 일야(日夜)의 두 귀신이 더해져서 존경받는 기쁨이 있다｣고 했다. 3전 중에 3기(三奇)를 만나면 흉을 변화하여 길조를 얻는다. 과전이 나망(羅網)을 만나는 것은 모사가 많아서 졸책을 이루니 군자는 때를 기다리는 것이 마땅하다. 
      비전에는 ｢간지의 전지(前支)가 간지의 상신으로 되면 천라지망(天羅地網)이라 하며, 이것을 만나면 활동해도 쾌활할 수 없다｣고 하였다. 그러나 점인의 년명상신에서 충파하거나 혹은 공망을 만나면 파라파망(破羅破網)으로 되어 피해가 없다.
      3전이 순(純) 관귀로 되면 형제의 신상에 재앙을 초래한다. 일귀는 순행하여 3전이 사(蛇)․주(朱)․합(合)이고, 야귀는 역행하여 3전이 백(白)․공(空)․청(靑)이다. 청룡이 3전에 들면 모든 일에서 길행을 얻는다. 귀신이 일간상에 임하는 것은 길조이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
