
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,7,4,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,8,3,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,9,2,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,1,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,12,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,12,11,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,1,10,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,2,9,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,3,8,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,4,7,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,6,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,5,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`상업`,`상업 관련사`,`小`,`7`,`위례득죄`,`관직이동`],
    [`색정`,`외男 색정사有`,`大`,`단절기`,`색정음사`,`부부화합`],
    [`차필임신(妻必孕胎)`,`처첩이 반드시 임신한 상`,`大`,``,`외사도망`,`처첩임신`],
    [`주식수벌(酒食受罰)`,`술자리에서 벌어진 사건으로 벌을 받는 상`,`小`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`8`,`가실불화`,`도적죄수`],
    [`막힘`,`내부 가정 막힘사`,`小`,`침체기`,`폐한지경`,`가정막힘`],
    [`폐구수생(閉口守生)`,`입을 닫고 지켜야만 하는 상-돈을 탐해 도장을 훼손 말것 `,`小`,``,``,``],
    [`적난포획(賊難捕獲)`,`도적을 잡지 못함`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`남편`,`남편 관련사`,`小`,`9`,``,``],
    [`급속`,`외사 급속사`,`小`,`소외기`,`급속사有`,`음사폐호`],
    [`복닉만장(伏匿萬狀)`,`많은 문서를 숨기고 있는 상`,`大`,``,`손실은닉`,`가택안녕`],
    [`능욕박해(凌辱迫害)`,`남 모르는 능욕을 당하고 박해를 겪는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`10`,`사사성취`,`도적악의`],
    [`결속`,`개혁으로 재원의 바탕을 크게 세우나 명예직위남편 훼절됨`,`小`,`침해기`,`개신개혁`,`공격말것`],
    [`서길관불의(庶吉官不)`,`일반인은 좋고 관직자는 좋지 않은 상`,`大`,``,``,`이혼우려`],
    [`불측지우(不測之憂)`,`가늠할 수 없는 근심의 상`,`小`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`11`,``,``],
    [`동료`,`동료 관련사`,`小`,`쇠퇴기`,`재물암동`,`파재극처`],
    [`피차암매(彼此暗昧)`,`서로 어둡고 가늠되지 않는 쇠퇴의 상`,`小`,``,``,`실종우려`],
    [`불리도난(不利盜難)`,`모두 불리하고 또는 도난을 당하는 상`,`大`,``,``,``],
    [`후원`,`후원 조력사`,`大`,``,``,``],
    [`문서`,`문사 관련사`,`大`,`12`,``,``],
    [`출행`,`외사 출행이동원행사`,`小`,`과시기`,`출행이동`,`모사미수`],
    [`수지무수(守之無遂)`,`지켜야 할 것을 지키지 못하여 내부 가정이 파괴되는 상`,`小`,``,`봉흉화길`,`허사됨`],
    [`봉흉화길(逢凶化吉)`,`흉을 만났으나 변하여 길이 되는 상`,`小`,``,``,``],
    [`색정`,`내女 색정사`,`大`,``,``,``],
    [`매사허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`1`,``,`가정女婦`],
    [`매사영속성없음`,`직업직위부모후원사는 선천적 숙명재해를 겪음`,`小`,`절정기`,`일체근심`,`색정사有`],
    [`합중범해(合中犯害)`,`처음에는 화합하나 결과적으로는 범죄로 되는 상`,`小`,`세속허사`,`해소`,`상대진심`],
    [`앙화소멸(殃化消滅)`,`재앙이 소멸되는 상`,`小`,`자로무망`,``,`가늠안됨`],
    [`개혁`,`개혁하여 재원의 바탕을 크게 이루나 명예직위남편 훼절됨`,`小`,``,``,``],
    [`매사허성불성`,`음3,,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`2`,``,``],
    [`매사영속성없음`,`형제자매친구동료사는 선천적 숙명재해를 겪음-독신고독`,`小`,`면모기`,`생업막힘`,`가정동요`],
    [`유의폐구(惟宜閉口)`,`입을 꾹 닫는 것이 유일한 해결방법이 되는 상`,`小`,``,`봉흉화길`,`불안분산`],
    [`간음시리(姦淫是利)`,`간음사가 이익이고 정대적이면 되려 재앙이 되는 상 `,`大`,``,``,`앙화해소`],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`4`,`문서유실`,`관직이동`],
    [`자식`,`자식 관련사`,`大`,`학습기`,`직위불안`,`평민불리`],
    [`출외직달(出外職達)`,`출행이동원행하여 직업이 구해지는 상`,`小`,``,`급속사有`,``],
    [`정위난측(情爲難測)`,`상대의 진심(情)을 가늠하기 어려운 상`,`大`,``,``,``],
    [`박관`,`직업직위불안사`,`小`,``,``,``],
    [`박남(剝男)`,`女는 남자 관련사`,`小`,`5`,`간음무도`,`展布경영`],
    [`수난`,`수난(受難)사`,`大`,`개시기`,`직위불안`,`개신개혁`],
    [`격중득통(隔中得通)`,`막혔던 중 통함을 얻음-단 도망함이 이로운 상`,`小`,``,``,``],
    [`전포경영(展布經營)`,`고위가 되어 多衆의 이익을 널리 알리는 상 `,`大`,``,``,``],
    [`형재`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`5`,``,``],
    [`쟁송`,`외내 반목대립 급속송쟁사-상해극처실종 우려`,`小`,`배양기`,`내외쟁송`,`반목쟁송`],
    [`기이동위(豈利動爲)`,`이익을 위해 動해야 하는 상-단 칼(刑)이 앞에 놓여있는 격`,`小`,``,`고위행길`,`공사구설`],
    [`공사구설(公私口舌)`,`공적이든 사적이든 모두 구설이 있게되는 상`,`大`,``,`청원좋음`,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`상업`,`상업 관련사`,`小`,`6`,`莫入堂到`,`손괴재물`],
    [`출행`,`내사 가정 출행이동원행사`,`大`,`존재기`,`逢林有蛇`,`출행이동`],
    [`정중생동(靜中生動)`,`조용히 있는 중에 움직이게 됨-단 두려움이 있게 될 상`,`大`,``,``,``],
    [`손괴재물(損壞財物)`,`복구될 수 없는 재물을 손괴함- 소(牛) 관련`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,`금전상업여인처임신사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,`형제친구동료조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사남편강제손실사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사직업남자문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`형제친구동료조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`후원문서출행사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`직업직위불안남편갈등자식문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`직업직위불안남자갈등여식문제로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`형제친구동료사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`금전상업여인출행사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대응하지 말고 순능유화적 처신으로 일을 지체시켜야 함`,0,0,`역상적 사안이므로 억울하더라도 절대 바날하지 말고 순응하여 사안을 뒤로 미루면 결과가 유리해지는 人`,0,0,`일이 수서있게 안 되고 역순으로 진행됨**결과없이 반복되기만 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대응하지 말고 순능유화적 처신으로 일을 지체시켜야 함`,0,0,`역상적 사안이므로 억울하더라도 절대 바날하지 말고 순응하여 사안을 뒤로 미루면 결과가 유리해지는 人`,0,0,`일이 수서있게 안 되고 역순으로 띄엄띄엄 진행되어 퇴하려 하나 계속하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)-은닉**만약 타인과 다툰다면 감추고 있는 일이 들통날 우려가 있으므로 그 자리를 피해야 함`,0,0,`기구한 삶을 살며 본인이 범인이거나 또는 범법자를 감추고 있는 人`,0,0,`남자는 관재우려가 있음**여자는 음식중독우려가 있음**의지거처가 없음**산속으로 깊이 숨소싶은 심정일 때가 다가오고 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기어려우므로 신중해야 하고 일을 뒤로 미루는 것이 현명하며 방심하면 불리해짐`,0,0,`풍상고초후 성공할 人`,0,0,`여러 사안을 하나로 묶는 개신개혁사가 있음**일이 갑자기 돌발함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 대응하지 말고 순능유화적 처신으로 일을 지체시켜야 함`,0,0,`역상적 사안이므로 억울하더라도 절대 바날하지 말고 순응하여 사안을 뒤로 미루면 결과가 유리해지는 人`,0,0,`해오던 옛일이 끓어짐**임신출산병질사는 매우 나쁨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있어 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`속성속패반복하고 어떤 일도 직접나서면 안 되며 타인을 앞세워 측면으로 구해야 할 人`,0,0,`상하 공손함이 없고 무친함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`권리권한행사를 하는 상-자격증서 관련)**일이 결과없이 반복되기만 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기어려우므로 신중해야 하고 일을 뒤로 미루는 것이 현명하며 방심하면 불리해짐`,0,0,`풍상고초후 성공할 人`,0,0,`여러 일을 하나로 묶는 개신개혁사가 있음**일이 갑자기 돌발함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)은닉**만약 타인과 다툰다면 남자는 자칫 송쟁이 되고 여자는 흉이 겹치게 되므로 피해야 함`,0,0,`신택이 불안하고 여하간 근신해야 할 人`,0,0,`남자는 형상사가 있음**여자는 독물의 해가 있음**반복무상한 처지임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**정당한 일이므로 먼저 나서서 굳세게 속결하면 유리함`,0,0,`일을 미루거나 좌고우면으로 지체하면 오히려 불리해지는 人`,0,0,`일이 띄엄띄엄 진행되어 퇴하려 하나 계속하게 됨**밝음이 가득차서 급진할 필요가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**정당한 일이므로 먼저 나서서 굳세게 속결하면 유리함`,0,0,`일을 미루거나 좌고우면으로 지체하면 오히려 불리해지는 人`,0,0,`일이 순서있게 진행됨**형벌사가 있게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지 않음`,0,0,`스스로 유약하다고 여기고 나아가지 않는 人-신체가 자유롭지 못한 경우가 많음`,0,0,`토자가옥관련사로 송쟁사가 일어남`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-침체인`,`이외사`,`미사`,`신구중간사`,`모호함`,`돌발사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`이외사`,`미사`,`신사`,`돌연사`,`모호사`,`배신`,`보성-무력`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`이외사`,`기사`,`모호사`,`돌발사`,`분간않됨`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사-급속사겹침`,`기사`,`구사`,`내사`,`표면이면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥-화합`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-이사함`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-반목대립`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥-이전의사`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해됨`,`화해됨`,`화해됨`],
    [` 승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,` 비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [1,1,2,`음7월`,3,3,6,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월-연기 또는 파혼우려`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음6월-연기 또는 파혼우려`,2,3,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음1월-연기 또는 파혼우려`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,3,`음2월`,2,1,3,`음2월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음3월`,3,1,4,`음9월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음10월`,3,2,5,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,2,2,4,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음8월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 어무도 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 업무가 초반 이후 저조하고 종반에는 실패함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 상대를 잘 조정하지만 업무는 초반 이후중반에 허무하고 종반도 막힘`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 결속단합하여 개신개혁을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많으나 업무의 권위를 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여 업무를 시종 동요불안분산사를 일으킴 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세나 업무의 권위를 높임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받고 여러 일은 일관시키는 개신개혁을 단행하여 성공함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 속이며업무도 수준 이하로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 종반이 공허함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 속이며 업무도 수준 이하로 마침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 시종 반목대립 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`반대없음`,`불이행`,`사안무력`,`退背`,`원`,`去`,`세입가해`,`정북`,``,`정서`],
    [`10.11`,`多集`,``,``,``,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`측근있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`발현안됨`,`불이행`,`선攻후不`,`進向`,`원근왕래`,`  留`,`세입가해`,`북북동`,``,`북서`],
    [`10.11`,` 多集`,`본인제압`,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`반목대립`,`向進`,`  근`,`  留`,`반목대립`,`동북`,``,`북북서`],
    [`10.11`,` 多集`,``,``,`포기요됨`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,`불이행`,`선損후不`,` 退背`,`  원`,`  去`,`세입조력`,`정동`,`정북`,``],
    [`7.8월`,``,`본인반대`,``,``,``,``,``,`동조피로`,``,``,``],
    [`10.11`,``,`측근조력`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`불이행`,`선損후不`,`退背`,`  원`,`  去`,`세입저력`,`동남`,`북북동`,``],
    [`10.11`,`  集`,`측근제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`남남동`,`동북`,``],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`불이행`,`사안무력`,`向進`,`  근`,`  留`,`주인무력`,`정북`,``,``],
    [`10.11`,``,`본안합세`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`오래끔`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`측근반대`,`불이행`,`선이후난`,`進向`,`  원`,`  留`,`주인조력`,`북북동`,`북서`,``],
    [`10.11`,``,`발현안됨`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,`다중제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선滯후通`,`退背`,`  근`,`  去`,`피차파재`,`정서`,`북북서`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근합세`,`불이행`,`선난후난`,`退背`,`  원`,`  去`,`주인가해`,`정서`,``,`정북`],
    [`10.11`,` 集`,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선난후난`,` 退背`,`  원`,`  去`,`주인가해`,`서북`,``,`북북동`],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`본인무력`,`退背`,`  근`,`  去`,`무난`,`북북서`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,``,``,0,0,`묘좌`,`정북`,`없음`],
    [``,``,``,0,0,`묘향`,`정남`,``],
    [`생업`,``,``,0,0,`전경`,`江川`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,``,``,0,0,`육친`,` 6조모여식`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`제례부정`,`제례위범`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`저주부정`,`원한`,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`자살귀`,`목맴`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`수귀`,`익사`,0,0,`묘좌`,`서남`,`없음`],
    [``,``,``,0,0,`묘향`,`동남`,``],
    [`생업`,`음귀`,`묘지탈`,0,0,`전경`,`큰도로`,``],
    [``,``,`본인형제`,0,0,`묘속`,`압석`,``],
    [`가정`,``,`관재수난`,0,0,`육친`,`부친`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`수귀`,`익사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`저주부정`,`원심`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`제례부정`,`제례위범`,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`가장귀`,`호주사망`,0,0,`육친`,``,``],
    [`질병`,`원귀`,`불혼사망`,0,0,`묘좌`,``,`없음`],
    [``,`가장귀`,`호주사망`,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`원한귀`,`불혼사망`,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내사출행이동원행사`,`도둑을 못잡음`,`외男색정사`,`酒食受罰사`,`외동요 내막힘`,`손괴재물사`,`내사 급속사`,`公私구설`,``,`널리알려 이익경영을 하는 지위 득함`,`매사위역사`,`허리굽히는 일을 면할 수 없음-감옥관련`,`외사 출행이동원행사`,`모두 흉함`,`매사허성불성**세속허사자**내女색정사有`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`매사영속성없음**가정막힘`,`종교진출하면자립함`,`외사 급속사`,`고위자도로상면시-청원함이좋음`,``,`간음무도`,`외내 급속송쟁사-상해 실종우려`,`은밀한관계인에게 손해 남**매사위역`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`전화위복`,``,`재액해소`,`빈궁**병재`,``,`빈궁**관재`,``,`관재`,`재액해소`,`빈궁`,`음4월 화재주의`,`전도`,``,`전도**빈궁`,`선천 숙명적 독신고독자`,`빈궁`,`전화위복`,``,``,``,`빈궁**관재`,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외내  급속송쟁-상해 실종사 우려`,`은밀한 관계인에게 손해 남**매사위역사`,`내사 출행이동원행사`,`도둑을 못잡음도둑`,`외男색정사`,`酒食受罰사`,`외동요내막힘`,`손괴재물사`,`내사 급속사`,`公私구설`,``,`널리알려 이익경영을하는 지위 득함`,`매사위역사`,`허리굽히는 일을 면할 수 없음-감옥관련`,`외사 출행이동원행사`,`모두 흉함`,`매사영속성없음**내女색정사有`,`종교진출하면자립함`,`매사영속성없음**가정막힘`,`종교진출하면자립함`,`외사 급속사`,`고위자 도로상면시-청원함이좋음`,``,`간음무도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`빈궁**관재`,``,`빈궁`,`전화위복`,``,`빈궁**병재`,``,`빈궁**관재`,``,`관재`,``,`빈궁`,``,`음4월 화재주의`,`전도`,``,`전도**빈궁`,`선천 숙명적 독신고독자`,`빈궁빈궁`,`전화위복`,`재액해소`,``,`재액해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`간음무도`,`매사위역사`,`외내 급속송쟁-상해 실종사 우려`,`내사 출행이동원행사`,`필유성취`,`외男색정사`,`밤에 능욕을 당하고 박해받음`,`외동요내막힘`,`모사미수사`,`내사 급속사`,`公私구설`,``,`가족상실-일가놀램불녕`,``,`도적소실사`,`외사 출행이동원행사`,`도둑을 못잡고 놀래기만 함`,`매사영속성없음*문서暗動**내女색정사有`,`종교진출하면자립함`,`매사영속성없음**가정막힘`,`종교진출하면자립함`,`침체경구사`,`고위자도로상면시-청원함이좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`재액해소`,`빈궁`,``,``,`전화위복`,`빈궁**병재`,`빈궁**관재`,`재액해소`,`관재`,``,`빈궁`,``,`전도`,`재액해소`,`음4월 화재주의`,`전도**빈궁`,`빈궁`,``,`선천 숙명적 독신고독자`,``,`외사 급속사`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`酒食受罰사`,`외사 급속사`,``,`도둑악의-공격안 좋음`,`매사위역사`,`외내 급속송쟁-상해 실종사 우려`,`수신제가로 가나있으나 좋음`,`내사 출행이동원행사`,`일체근신해소`,`외男색정사`,`화소복지사`,`외동요내막힘`,`보고문서답신지체-구설관련사`,`내사 급속사`,``,`사사성취`,`매사위역사`,`허리굽히는 일을 면할 수 없음-감옥관련`,`예측이 안 되는 근심사`,`외사 출행이동원행사`,`情 가늠이 안 됨**매사영속성없음**내女색정사有`,`종교진출하면자립함`,`매사영속성없음**손괴재물**가정막힘`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,`전화위복`,`전도**빈궁`,``,`빈궁`,``,``,`재액해소`,`전화위복`,`재액해소`,`빈궁`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`음4월 화재주의`,``,`빈궁`,``,`빈궁**병재`,`선천 숙명적 독신고독자`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`매사영속성없음*모사난수**가정막힘`,`종교진출하면자립함**선천 숙명적 독신고독자`,`외사 급속사**전화위복`,`일체근심해소`,``,`간음사이익-정대적이면 되려 재앙됨`,`외내 급속송쟁-상해 실종사 우려`,`종종걸음을 반복함-門관련**매사위역사`,`봉흉화길사`,`내사 출행이동원행사`,`일체근심해소`,`외男색정사`,`외동요내막힘**봉흉화길사`,`송구경황-색정상대가 두려운 자임을 알게 됨`,`보고서답신지체-구설관련사`,`내사 급속사`,``,`모든 일 안 좋음-또는 도난 당함`,`행득권리-관직 좋고 일반 불리함`,``,`앙화소멸사`,`외사 출행이동원행사`,`情 가늠이 안 됨**내女색정사有**매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도`,`외사 급속사`,`재액해소`,`전도**빈궁`,`재액해소`,`관재`,``,`전도**빈궁`,``,`빈궁`,`전화위복`,`관재`,`전도`,``,`빈궁**관재`,``,`빈궁**관재`,``,`전도`,``,`음4월 화재주의`,`빈궁**병재`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`情 가늠이 안 됨**내女색정사有**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음*모사난수**가정막힘`,`종교진출하면자립함`,`외사 급속사**전화위복`,`이체근심해소**외사 급속사`,``,`간음사 이익-정대적이면 되려 재앙됨`,`외내 급속송쟁-상해 실종사 우려`,`종종걸음을 바복함-門관련**매사위역사`,`봉흉화길사`,`내사 출행이동원행사`,`일체근심해소`,`외男색정사`,`**외동요내막힘**봉흉화길사`,`송구경황-색정상대가 두려운잘임을 알게 됨`,`보고서답신지체-구설관련사`,`내사 급속사`,``,`모든 일 안 좋음-또는 도난 당함`,``,`행득권리-관직 좋고 일반 불리함`,`전도**빈궁**병재**앙화소멸사`,`외사 출행이동원행사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`전도**빈궁`,`동요불안분산사`,`선천 숙명적 독신고독자`,`동요불안분산사`,`빈궁관재**병재`,`동요불안분산사`,`관재`,``,`동요불안분산사**재액해소`,`빈궁**관재`,`동요불안분산사`,`관재**병재**전화위복`,`동요불안분산사`,`동요불안분산사`,`재액해소`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사`,`재액해소`,`동요불안분산사`,`음4월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`앙화소멸사`,`외사 출행이동원행사`,`情 가늠이 안 됨**내女색정사有**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**모사난수**가정막힘`,`종교진출하면자립함`,`외사 급속사`,`일체근심해소`,``,`간음사 이익-정대족이면 되려 재앙됨`,`외내 급속송쟁-상해 실종사 우려`,`종종걸음을 반복함-門관련**매사위역사`,`봉흉화길사`,`내사 출행이동원행사`,`일체근심해소`,`외男색정사`,`외동요내막힘**봉흉화길사`,`송구경황-색정상대가 두려운자임을 알게 됨`,`보고서답신지체-구설관련사`,`내사 급속사`,``,`모든 일 안 좋음-또는 도난 당함`,``,`행득권리-관직 좋고 일반 불리함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4월 화재주의`,`병재`,``,`빈궁`,``,`선천 숙명적 독신고독자`,`전도**병재**관재`,`전화위복`,`전도`,``,``,`빈궁`,`재액해소`,`빈궁**관재`,`전화위복`,`재액해소`,``,``,`빈궁***관재`,``,`빈궁**관재`,``,`관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`행득권리-관직 좋고 일반 불리함`,``,`앙화소멸사`,`외사 출행이동원행사`,`情 가늠이 안 됨**내女색정사有**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**모사난수**가정막힘`,`종교진출하면자립함`,`외사 급속사`,`일체근심해소`,``,`간음사 이익-정대적이면 되려 재앙됨`,`외내 급속송쟁-상해 실종사 우려`,`종종걸음을 반복함-門관련**매사위역사`,`봉흉화길사`,`내사 출행이동원행사`,`일체근심해소`,`외男색정사`,`외동요내막힘**봉흉화길사`,`송구경황-색정상대가 두려운자임을 알게 됨`,`보고서답신지체-구설관련사`,`내사 급속사`,``,`모든 일 안 좋음-또는 도난 당함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`빈궁**병재**재액해소`,`음4월 화재주의`,`빈궁`,``,``,`선천 숙명적 독신고독자`,`빈궁**병재`,`전화위복`,``,``,``,`빈궁`,``,`빈궁`,`전화위복`,``,``,``,`전도**관재**빈궁`,``,`전도**관재**빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`모든 일 안 좋음-또는 도난 당함`,``,``,`행득권리-관직 좋고 일반 불리함`,`앙화소멸사`,`외사 출행이동원행사`,`情 가늠이 안 됨**내女색정사有**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**모사난수**가정막힘`,`종교진출하면자립함`,`외사 급속사`,`일체근심해소`,``,`간음사 이익-정대적이면 되려 재앙됨`,`외내 급속송쟁-상해 실종사 우려`,`종종걸음을 반복함-門관련**매사위역사`,`내사 출행이동원행사**봉흉화길사`,``,`일체근심해소`,`외男색정사`,`외동요내막힘**봉흉화길사`,`송구경황-색정상대가 두려운자임을 알게 됨`,`보고서답신지체-구설관련사`,`내사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,``,`빈궁**병재`,`음4월 화재주의`,`빈궁`,``,``,`선천 숙명적 독신고독자`,`전도**빈궁**병재`,`전화위복`,`전도`,``,``,`빈궁`,`재액해소`,`빈궁빈궁**`,`전화위복`,`재액해소`,``,``,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내사 급속사`,`公私구설`,``,`널리 알려 이익경영하게된 지위 득함`,`매사위역사`,`허리굽히는 일을 며할 수 없음-감옥관련`,`외사 출행이동원행사`,`모두 흉함`,`내女색정사有**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**가정막힘`,`종교진출하면자립함`,`외사 급속사`,`고위자도로상면시-청원함이 좋음`,``,`간음무도`,`외내 급속송쟁-상해 실종사 우려`,`은밀한 관계인에게 손해 남**매사위역사`,`내사 출행이동원행사`,`도둑을 못잡음`,`男색정사`,`酒食受罰사`,`외동요내막힘`,`손괴재물사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`관재`,``,`전도**빈궁`,``,``,`음4월 화재주의`,`빈궁`,``,`전도**빈궁`,`선천 숙명적 독신고독자`,`재액해소`,`전화위복`,`재액해소`,``,``,`전도**빈궁`,``,`빈궁`,`전화위복`,``,``,`전도**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외동요내막힘`,`손괴재물사`,`내사 급속사`,`公私구설`,``,`널리 알려 이익경영하게되는 지위 득함`,`매사위역사`,`허리굽히는 일을 면할 수 없음-감옥관련`,`외사 출행이동원행사`,`모두 흉함`,`내女색정사有**매사영속성없음`,`종교진출하면자립함`,`매사허성불성**세속허사자**가정막힘`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,`외사 급속사`,`고위자 도로에서 상면시-청원함이 좋음`,``,`간음무도`,`외내 급속송쟁-상해 실종사 우려`,`은밀한 관계인에게 손해 남**매사위역사`,`폐한인**내사 출행이동원행사`,`도둑을 못잡음`,`외男색정사`,`酒食受罰사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**병재`,``,`빈궁**관재`,``,`관재`,``,`전도**빈궁`,``,``,`음4월 화재주의`,`빈궁`,``,`전도**빈궁`,`성공함**선천숙명적독신고독자`,`전화위복`,``,`재액해소`,``,`전도**빈궁`,`재액해소`,`빈궁`,``,``,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`외男색정사`,`酒食受罰사`,`외동요내막힘`,`손괴재물사`,`내사 급속사`,`公私구설`,``,`널리 알려 이익경영하게된 지위 득함`,`매사위역사`,`허리굽히는 일을 면할 수 없음-감옥관련`,`외사출행이동원행사`,`모두 흉함`,`내女색정사有**매사허성불성**세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 성공 함`,`매사 허성불성**세속허사자**가정막힘`,`음3,4,5,6,9,12월 방문자는 종교진출하면`,``,`고위자 도로에서 상면시-청원함이 좋음`,``,`간음무도`,`외내 급속송쟁-상해 실종사 우려`,`은밀한 관계인에게 손해 남**매사위역사`,`내사 출행이동원행사`,`도득을 못잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위보`,`전도`,``,`빈궁**병재`,`재액해소`,`빈궁**관재`,`재액해소`,`관재`,`빈궁`,``,``,`음4월 화재주의`,`빈궁`,``,`빈궁`,`성공함((선천숙명적독신고독자`,`전화위복`,`외사 급속사`,``,``,``,`빈궁`,`전도**빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 첩첩산중에 홀로 갇힌 
  형국일세 ? 
토지사로 재판이 붙었어!
⊕가택음사부정, 
⊖가택상가부정이 들어 신음이    크겠어!

⊕신변 상가부정 침범함
⊖신변 음사부정 침범했네?
`,`■ 일이 매듭이 없겠네?
시작은 산뜻해도 나중에는 그림속의 떡이야! 
임신했겠는데,애낳고 집안이 기울겠어!
`,`■ 이사하네? 
돈도 뛰고 여자도 뛰는 형상이야!
집안 단속이 급선무인데, 
자칫 산으로 몸 숨길 일이 벌어지겠어!
`,`■몸이고 집이고 다 불안하네? 다른 지역으로 갈려고 하겠어 ! 근신이 필요한데,예절을 잃으면 흉하겠어 !  가택에
⊕여고음강, 
⊖저주원심 부정으로 피신할    일이 벌어졌겠어!

⊕신변고모신사부정 침범함
⊖신변신화선영부정 침범했어!
`,`■ 확 바꾸는 개혁을 하게      생겼는데, 
부부이별이 되고 말겠어!
⊕자살귀, 
⊖가택음사부정전흉사귀발동으로 들어오는 돈도 결국 다른 남자가 뺏어가겠어!

⊖신변 상가부정 침범했어!
`,`■관직자:소원을 이루겠네!
         그런데 귀신을 해소            시켜야겠어 !
일반인:⊕수귀ㆍ음귀, 
      ⊖전흉사귀ㆍ원한귀             장난이라 해소하면            소원이이뤄지네!  공무원이라면 모를까 인연이 다해 투망에 걸리겠어!
`,`■상하불화인데 돈과 여자가 마구 뛰어 들어오네? 
일이 오래가면 안돼! 다른 사람을 앞세워 당장 거머쥐지 않으면 금방 흩어져 버릴 것이니, 간격을 두지 말아야 하겠어!
`,`■ 숨어있던 후원이 이제야 눈앞에 오긴 왔는데 의심 질투는 왜 ?
⊕수귀, 
⊖전흉사귀가 방해하고 있네? 男：공직자는 영전하고
女：임신사가 있겠는데 낙태      를 생각하네!
`,`■여러개를 하나로 묶네!
옛것을버리고새것으로     바꾸겠어? 
그 일로 돈은 크게 움직이는데  부부 이별의 불행이 있겠어!
⊖가택상가부정이 들었다.

⊕신변음사부정 침범했네?
`,`■내 토대가 모두 무너졌으니 사방에 돈 쓴 일만 가득하겠어! 앞에는 호랑이, 뒤에는 늑대야!
⊕가택신화선영, 
⊖가택고모신사 부정 탓이야!

⊕신변 저주원심부정 침범함
⊖신변여고음강부정침범했네?
`,`■ 자, 선수치고 기선을 
  제압해 버려야 할 일인데

⊕수귀, 
⊖가택귀가 방해를 하니 때를    놓칠까 걱정이야! 
도움 받을 곳도 안 보이고…
`,`■ 식구 중에 가장을 속이는     일이 있겠어! 

  가해(加害)하려고 해 ! 
그래도
⊕ 변사귀ㆍ자살귀, 
⊖원한귀ㆍ가택귀만 잡으면    만사가 크게 좋겠어!`];
  const YOGYEOL1 = [`■ 상대는 내게 와서 청한다. 그러나 나는 상대와 투합하지 않는다. 
  가히 두려운 바는 낮밤 모두 흉장뿐이다.
  `,`■ 귀인이 내녹을 친다.
  멀리서 재물을 구한다.
  부모ㆍ문서사는 불리하다.
  혼인사만은 빨리 이루어진다.
  `,`■ 가택에 정마가 뛰니 이사수가 있다.
  머물 수 없는 변동수다.
  일을 당하면 입을 다물라.
  일도 사적으로 하라.
  `,`■ 괴강이 중첩되어 타향으로 옮겨간다.
  귀인의 재물이 오나 무례한 행동을 조심해야 한다.
  `,`■ 사람이고 집이고 모두 실패와 손해뿐이다. 
  그러나 손해가 차라리 나으리니 사고나 재앙을 면하기 때문이다.
  `,`■ 관귀살이 많아 재화가 다투어 침범한다.
  단, 밤정단은 귀인이 집에 들어 재앙을 물리치고 복을 내린다.
  `,`■ 가고오고 서로 만나네. 역마ㆍ정마가 함께 이르렀다. 움직이면 재물을 취하고 지키고 있으면 이익이 안된다.
  `,`■ 먼저는 흩어지고 후에 거머쥐니 동업이나 교섭사는 유익하다. 끝에서 처음을 도우니 독일수도 있고 꿀일 수도 있다.
  `,`■ 역마ㆍ정신이 재물을 실었다. 모두 나를 도우니 입을 열면 돈을 얻고 입을 다물면 재앙이 없을 것이다.
  `,`■ 비록 삼기가 나타났지만 낮정단은 도적이나 관재가 두렵다. 괴강을 만났으니 가만히 있지는 못하리라.
  `,`■ 무너진 다음 또 숨기고 감추니 가정에 우환이 생길 수밖에 없다. 내외에 우환이 생기니 귀인이 있어도 소용없는 일이다.
  `,`■ 모두 나를 해치나 밤정단은 길함이 있다. 
  신불의 은혜를 입어 공무원이나 일반인 모두 좋다.`]
  const SIBO1 = [`■田地ㆍ농경사로 송쟁이
  있고 財부족, 하극상배로 
  신음한다. 冬春은 가볍고
  秋夏는 무겁다. 방어능력을 상실했다. 그러나 냉정하고 근신하면 핍박을 벗어난다. 
  凶을 凶으로 제어하고 형제ㆍ친구를 단속해야 한다.
  형제의 재해가 발생한다.
  `,`■가정에 임신사가 있다.
  금전ㆍ혼인사 외에는
  좋은 것이 없다.
  특히 토대가 비어버려 허위나 문서사의 부실에 한탄하는 입장이다.
  가능한한 처음의 조건에 맞춰 결행하는 것이 좋다.
  직․녹의 손실이 기한다
  `,`■금전과 여인사가 암암리에
  발동하고 있다.
  막히더라도 극복하면서
  가능한 때를 기다리며 음적으로 진행해야만 吉하다.
  특히 ⊕여자, ⊖도난을 주의하고 숨은 남자를 잘 조정하면 이롭다.
  형제의 재해가 기한게된다.
  `,`■임신사가 있다.
  눈을 가리고 감추는 것이 있다.
  가정에 ⊕여고음강, 
  ⊖저주원심 부정이 들어 매우 고뇌한다. 관사가 벌어진다. 
  신택불안으로 심신이 고로한 입장에 있다. 
  8일, 8개월, 8년이 끝나는 시기이다.
  `,`■가장이 가정을 등한시 한다.
  개변ㆍ개신사가 있다.
  ⊖음사부정이 들었다.
  남자는 직위해탈ㆍ불명예사가 있고, 여자는 부부이별을 결단한다. 부부가 음탕하다. 
  이 일로 財가 발동하나
  
  ⊕자살귀, ⊖전흉사귀 장난으로 의지가 반대로 된다.
  `,`■자충수적 우환이 있다.
  가장이 가정을 겉돈다.
  ⊕음귀ㆍ수귀, ⊖원한귀ㆍ
  전흉사귀 침범 이유이다.
  관직자는 녹위가 증가하나 일반인은 종사 업무가 끝났다고 보고, 자칫 법망에 걸리게 된다.
  병 치료는 丑방ㆍ8수ㆍ단맛ㆍ황색에 치료의 비밀이 있다.
  `,`■신변에 저주사가 있다.
  금전과 여자가 크게 움직인다.
  별리ㆍ원행ㆍ이동사가 있다.
  타인에게 의탁하나 실현이 없다. 직접 구하는 것은 모두 실패이므로 타인을 앞세워 측면으로 도모해야 한다.
  재물과 여자 단속을 잘해야 한다.
  형제의 재해가 기한게 된다.
  `,`■돈 여자의 일에 의심과 
  질투가 있어 기로에 있다.
  근ㆍ친자로 결정해야 한다.
  관직자는 영전ㆍ승진하고 구직도 좋다. 일반인은 가택의 권리가 넘어간다. 신변에 임신사가 있다. 불육한다. 일에 매듭이 없으므로 때를 기다려야 한다.
  `,`■남자는 직위 해탈사가 있고
  여자는 부부이별한다.
  ⊕상가부정으로 가정에
  병자가 있다. 
  개신ㆍ개혁ㆍ별리사로 금전이 움직인다.
  여러사람의 동조를 받으나 도난ㆍ병, 또는 숨겨둔 남자로 인해 금전이 크게 손모된다.
  남자는 애인을 잃는다.
  `,`■가정에 ⊕신화선영
  ⊖고모신사 부정이 들어
  재산이 탕진 되었다.
  서산에 해지는 격이다.
  여자는 별리ㆍ무덕한 고독녀이고 남자는 호랑이 꼬리를 밟은 격이다.
  거짓된 일만 연속이므로 어떤 일도 추구하지 말고 근신해야 한다.
  직․녹의 손실이 있다.
  `,`■가내 우환이 있고, 가장을
  속이며, 가인이 원한을 품고
  가해하려 한다.
  문호개변하는 충돌이 있고 부부이별이 우려된다.
  이는 ⊕수귀, ⊖가택귀 발동 때문이다. 무슨일이든 신속히 결단해야만 이롭다.
  머뭇거릴수록 凶이 크다.
  `,`■가내 우환이 있고, 가장을
  속이며, 가인이 원한을 품고
  가해하려 한다.
  ⊕변사귀ㆍ자살귀, 
  ⊖원한귀ㆍ가택귀 침범 때문이다. 이를 해소시키면 만사가 이롭게 되고 그냥두면 관재를 몰고 온다.
  봄 정단은 더욱 그렇다.`]
  const SIBO2_1 = [`■가택손재재판사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  부동신음의 상이다.
  선합 후별한다.
  `,`■근친ㆍ금전ㆍ여인, 가택사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에이롭다.매사지장이 있다.
  부모재해․권리침해사가 기한다
  `,`■금전ㆍ여인, 가정내사이다.
  내방조짐 상의 : 
  원행·이동사가 있고 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■금전ㆍ임신, 가택사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  기만․모욕사가 있다.
  `,`■목전개변이별사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  男직위상실하고 
  女부부이별한다.
  `,`■관ㆍ살, 夫,신변외사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■원행ㆍ이동재물사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■후원이면사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  관직자는 영전한다.
  일반인은 의심질투한다
  `,`■자손ㆍ박관, 가택내사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다.
  男직위상실하고 
  女부부이별한다.
  `,`■별리, 신변외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  낭비가 많고 재·녀 상실하고 형제에 재액이 있다.
  `,`■官･夫, 신변외사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官･夫,가정내사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/026/row-1-column-1.png`,
    `/static/images/sagwa/026/row-1-column-2.png`,
    `/static/images/sagwa/026/row-1-column-3.png`,
    `/static/images/sagwa/026/row-1-column-4.png`,
    `/static/images/sagwa/026/row-1-column-5.png`,
    `/static/images/sagwa/026/row-1-column-6.png`,
    `/static/images/sagwa/026/row-1-column-7.png`,
    `/static/images/sagwa/026/row-1-column-8.png`,
    `/static/images/sagwa/026/row-1-column-9.png`,
    `/static/images/sagwa/026/row-1-column-10.png`,
    `/static/images/sagwa/026/row-1-column-11.png`,
    `/static/images/sagwa/026/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 12신이 본궁에 복(伏)하므로 복음과(伏吟課)로 된다. 음일이므로 일지상신을 초전으로 하고 중․말전은 지형(支刑)을 취하여 자신가색격(自信稼檣格)으로 된다.
    초전은 지상신, 말전은 간상으로 돌아온다. 즉 지에서 간으로 전하는 것은 사람이 와서 나에게 위탁하는 것이므로 길흉이 모두 성립된다. 3전4과가 모두 비견․겁재(劫財)의 동류이면 형제효가 움직여서 처를 극하고 파(破)한다. 일귀․야귀에 모두 발사(發蛇)․호(虎)가 승하고 중전에 태음이 승하여 재화(災禍)를 초래하며 괴괴복닉(怪怪伏溺)의 사건이 있다. 복음은 엎드린 채 아직 굽히지도 펴지도 못하는데, 조용하면 안온하고 움직이면 정체가 생긴다. 원래 자신격(自信格)은 음일로서 스스로 유(柔)하다고 자각하고 냉정(冷靜)히 하면 해가 없고, 만사에 때를 기다리는 것이 좋다. 토(土)가 중첩되면 침체가 심하고 모든 일에서 핍박하여 자기에게 따르지 않는다.
    백법에 이르기를 ｢그가 우리 쪽에 청을 드리는데 이것은 지에서 간으로 전하기 때문이다｣라고 하였다. 주객(主客)이 투합되지 않는 것은 지형(支刑)이 위에 있기 때문이다. 비전에 이르기를 ｢4과3전 중에 정마(丁馬)가 없어 년명상신 괴강(魁罡) 또는 해(亥)의 정신(丁神)이 승하면 움직임을 지배하고, 묘신이 초전에 있으면 모든 일이 혼회(昏晦)한 형상을 이룬다｣라도 하였다. 일귀는 순행하여 3전이 동․음․백이고 야귀는 역행하여 3전이 백․음․사이다. 3전 중에 사(蛇)․호(虎)가 승하면 흉재가 있다. 그리고 야귀가 초전 백호이고 말전이 동신(蛇神)인 경우에는 용두사미라 하여 처음에는 위험하지만 후에는 두려운 일이 없다.`
    ,
    // 2
    `  이과는 제3과만이 하에서 상을 적하므로 자축(子丑)은 초전으로 하여 중심퇴여격(重審退茹格)이 된다. 귀신이 초전에 승하여 일간의 재로 되고 일간상신의 녹(祿)을 충하여 녹을 잃는다. 중전의 해재(亥財)는 정신(丁神)과 역마가 되어 먼 곳의 재를 구하는 뜻이다. 일간상신의 오(午)가 공망하고 주작이 승하면 복록을 잃고, 문장에 관한 일에서 불리하다. 퇴여격은 대개가 모든 일에서 불순한데 정마가 타서 물러서는 가운데 전진하는 일이 있다.
    백법에 이르기를 ｢공망에 천공이 타면 공중공격(空中空格)이라 부르며 모든 일에서 허사부실(虛詐不實)하고, 일간일지는 교차육해(交車六害)로 된다｣라고 하였다. 백법 중 제21법 교차격에 이르기를 ｢교차육해는 처음에는 서로 사이가 좋고 친한 상태이나 후에 가서는 모두가 그림의 떡으로 되어 버리고, 어떤 일에서나 시작은 산뜻하나 마지막에는 아름다움을 볼 수 없다｣라고 하였다. 순기(旬奇)가 지(支)에 임하여 초전으로 되고 길조를 이루어도 역퇴중음(逆退重陰)의 지(地)이므로 길은 경미하다. 일귀는 순행하여 3전이 귀․후․음이고, 야귀는 3전이 상․현․음이다. 초전의 천지가 자축(子丑)에 가해지고 태상․귀신이 타면 우녀상회(牛女相會)하는 격으로 되어 점혼(占婚)이나 구재(求財)는 모두 성사된다.
  `
    ,
    // 3
    `  이 과는 제3과만이 하에서 상을 적하고 해축(亥丑)을 초전으로 하여 중심역간전과(重審逆間傳課)로 된다. 일지상신에 정신역마가 타서 초전으로 되어 주택 이동의 징조가 있다.
    4과3전에서 사묘해유미(巳卯亥酉未)의 5음(五陰)을 본다. 만일 년명상신 또는 행년이 축(丑)자이면 육음상계(六陰相繼)라고 하여 무슨 일에서나 陰氣이고 혼미하게 된다. 3전이 해유미(亥酉未)이면 시둔격(時遁格)이라 부른다. 즉 3전이 모두 음계(陰界)이고 말전 미(未) 안에 정화(丁火)가 있는데 정(丁)은 움직임을 주관한다. 기문둔갑(奇門遁甲)에는 정(丁)을 옥녀(玉女)라 부르며 움직여서 산림에 은둔하는 형상이므로 시둔격이라 부른다.
    중심과로서 역퇴간전격(逆退間傳格)은 일이 안에서 일어나고 부인에게 관계된다. 존장이 능력이 없고 불순하며 초전이 재동(財動)하고 천후․현무가 동승하기 때문에 부정(不正)한 형상이 있다. 일간상신은 생하는 인수(印綬)로 되어 백사가 이루어진다. 제2과 음신(陰神)에서 생조(生助)되어 더욱 양호하지만, 일지상신(日支上神)이 초전으로 되어 해(亥)에서 충극(冲剋)을 받으므로 성사(成事) 중에 동요가 있다.
    백법에서는 ｢청룡이 생기(生氣)에 타면 길하지만 다소 늦게 발복한다｣라고 하였다. 시둔격은 몸을 숨기고 때를 기다리는 것이 좋다. 군자(君子)는 길하고 서민은 흉격이다. 일귀는 순행하여 3전이 후(后)․현(玄)․백(白)이고, 야귀는 역행하여 3전이 현(玄)․후(后)․사(蛇)이다. 백호의 흉장이 말전에 승하면 병증의 정단에는 공망되므로 두려울 것이 없다.`
    ,
    // 4
    `  이 과는 4과 중에 상하의 극도 요극도 없기 때문에 묘성과이고, 음일에는 천지반 유(酉)의 하신(下神) 자(子)를 취하여 초전으로 한다.
    진술(辰戌)은 일간일지상신에 있고 3전 중에 들며 괴강(魁罡)은 변동의 신이다. 신택(身宅)이 불안하고 타향으로 멀리 움직일 징조가 있다. 자(子)는 일간의 재로 되고 귀신이 타서 윗사람 존장으로부터 재의 원조를 받지만 자묘(子卯)는 예절을 잃는 일이 없도록 주의하여야 한다. 음일 묘성과는 동사엄목(冬蛇掩目)이라고 한다. 음기는 지(地)를 따르기 때문에 무슨 일에서나 조용히 엎드려 있으면 길하고 망동하면 흉을 초래한다. 그러나 진술(辰戌)이 상충(相冲)하므로 동의를 생하지만 음일묘성과(陰日昴星課)는 숨어 있으므로 움직일 수가 없다.
    백법에는 ｢주객(主客)이 투합되지 못하는 것은 지형(支刑)에 있다
  고 하였다. 비전에는 ｢자(子)는 화개(華蓋)라고 부르며, 형상을 가리는 태음지호(太陰地戶)는 숨어 있고, 천을귀신은 능히 내몸을 비호(庇護)한다｣고 하였다. 중전의 공망은 단교(斷橋)로 되어 움직일 수가 없다. 일귀는 순행하여 3전이 귀․구․음이고 귀신이 묘(卯)에 임하면 여덕(勵德)이 된다. 야귀는 3전이 구․상․주이며 귀신이 해(亥)에 임하여 천문에 오른다. 일귀와 야귀는 귀신이 임하는 곳이 다르다.`
    ,
    // 5
    `  이 과는 제1과와 제4과에서 2상(二上)이 이하(二下)를 극하여 비용과(比用課)를 채용할 수 없기 때문에 섭해과(涉害課)로 된다. 지반에 맹신(孟神)의 극이 없고 중신(仲神)의 극이 있다. 사유(巳酉)를 사용하여 초전으로 하여 섭해종혁과(涉害從革課)로 된다. 제4과를 초전으로 하는 것은 맥월격(驀越格)이다. 일간상신 묘(卯)는 일귀(日鬼)로 되지만 제3과 또는 말전의 유(酉)에서 제극하므로 해를 이루지 않는다. 섭해종혁은 사물이 두 갈래에 걸쳐 있어 모든 일에서 고심한다. 망동하면 일간이 탈설하여 소모를 초래한다. 종혁(從革)은 낡은 것을 버리고 새로운 것을 따르는 것이 좋다.
    백법에서는 ｢일간의 귀(鬼)에 백호가 승하면 흉이 신속하게 오고, 귀(鬼)를 제(制)하여 인수(印綬)로 되는 것은 양의(良醫)를 얻는다｣라고 하였다. 즉 적은 반드시 도망쳐서 사방으로 흩어진다. 일귀(日貴)는 순행하여 3전이 청(靑)․사(蛇)․현(玄)이고, 야귀는 3전이 현(玄)․청(靑)․사(蛇)이다. 제1과․제2과의 일간음양은 목(木)국으로 되고 제3과․제4과의 일지음양은 금(金)국이다. 내몸을 극하는 것은 피차간의 의지가 생각과 반대된다. 일간일지의 지반이나 천반의 음양이 모두 7충한다. 3합은 화합하는 가운데 상하로 전투가 있다.
  `
    ,
    // 6
    `  이 과는 제1과․제2과․제4과의 상하에 극이 있고 하에서 상을 극하는 제4과의 묘신(卯申)을 사용하여 초전으로 하는 중심단륜과(重審斷輪課)이다.
    인(寅)은 일간상신으로 되고 묘(卯)는 초전으로 되어 두 목(木)이 와서 내몸을 극한다. 재화(災禍)인 듯하여도 신유(申酉)의 탈기가 있어 구신으로 되며, 또 귀신은 일지상신에 승하여 흉을 변화시키므로 길조로 된다. 초전의 칠살은 말전의 사(巳)로 생화(生化)하고 사화(巳火)는 일간을 돕는 인수(印綬)로 된다. 즉 귀(鬼)를 끌어서 생(生)으로 되므로 처음에는 흉하다 해도 후에는 성취된다. 길단륜(吉斷輪)이란 묘(卯)가 경신신유(庚辛申酉)에 가해져서 초전으로 되는 것을 말한다. 묘(卯)는 차륜이고 금(金)의 부금(斧金)으로, 묘목(卯木)은 금(金)에 단재(斷材)되어 기물을 이룬다. 옛 것을 버리고 새로운 것을 따르는 형상으로 주인격(鑄印格)과 비슷하며, 처음에 곤란하고 후에는 성취된다.
    중전 술(戌)은 인수(印綬)라 부른다. 말전에 백호가 타서 최관격(催官格)으로 되기 때문에 사관(仕官)․전임(轉任) 등에서 극히 길조이다. 백법에는 ｢제귀(制鬼)의 자리는 양의(良醫)를 얻는다｣라고 하였다. 일귀는 역행하여 3전이 현(玄)․주(朱)․백(白)이고, 야귀는 순행하여 3전이 백(白)․주(朱)․현(玄)이다.`
    ,
    // 7
    `  이 과는 12지(支)가 충궁(冲宮)에 있으나 상하의 상극은 없다. 신축일(辛丑日)․정축일(丁丑日)․기축일(己丑日)․신미일(辛未日)․정미일(丁未日)․기미일(己未日) 등 6일은 정란사무친격(井欄射無親格)이라 부른다. 즉 축일(丑日)은 역마 해(亥)를 초전으로 하며 중전은 지상신(支上神), 말전은 간상신(干上神)을 채용한다. 초전의 해(亥)는 정신 역마가 타서 재신으로 되지만 과전에 비견․겁재가 무리를 이루어 재를 빼앗기 때문에 움직여서 재를 얻어도 강탈을 당한다. 정란사(井欄射)는 피차가 모두 바른 길을 통하여 구하려 하나 용납되기 어렵고 도리어 암암리에 구(求)하면 이루어지기 쉽다. 일신양용(一身兩用)의 형상으로서 움직이면 좋고 조용하면 도리어 우려를 초래한다.
    백법에 이르기를 ｢중․말전의 공망은 전(傳)으로 돌지 않으며, 처음 생각에 따라서 혼인은 천후(天后)․육합(六合)이 승하여 중매인이 없는 혼약이다｣라고 하였다. 비전에 이르기를 정란격은 ｢정도(正道)로 움직이면 이루어지기 어렵고 파탄되기 쉽다｣라고 하였다. 길신을 만나도 마지막까지 완수되지 못한다. 일귀는 역행하여 3전이 사․청․후이고, 야귀는 순행하여 3전이 합․후․청이다.`
    ,
    // 8
    `  이 과는 4과중 제1과․제2과․제4과에 상하의 극이 있다. 아래에서 극하는 제2과 사자(巳子)를 초전으로 하고, 음일이므로 음비(陰比)를 사용하여 지일비용격(知一比用格)이 된다. 천지반은 일간일지가 상하에서 각각 충전(冲戰)하며 자(子)와 축(丑), 오(午)와 미(未)가 교차지합(交車支合)하여 생극․충해․지합 등의 혼탁된 상태를 이룬다. 지일비용과(知一比用課)는 사물이 양단에 걸쳐 있어 먼 데 것을 버리고 가까운 데 것을 취하며, 소원한 것을 버리고 친근한 것을 취한다. 신용하는 가운데 의혹이 생기고 은혜 속에 원한을 품는다.
    주인격(鑄印格)은 승헌(乘軒)이라 부르는데 사관(仕官)․구직에서는 대길하고 보통사람에게는 좋지 못하다. 역마․육합은 공망의 지에 앉아서 진체(眞體)가 아니다. 백법에서 이르기를 ｢일지상신 건록은 권섭부정(權攝不正)으로 되고 피차 의심을 품게 되면 육해가 서로 따른다｣라고 하였다. 비전에는 ｢귀신이 신(身)에 임하고 주작이 술(戌)에 승하여 귀신을 극하면 귀인은 기탄(忌憚)을 받고 웃사람 존장에 의존해도 유익한 일이 없다.｣ 주인격(鑄印格)은 관록이 연진하는 형상으로서 태음․천마가 보이지 않을 때는 참다운 격이 아니며 보통 사람에게는 재앙을 초래하고 매사가 늦게 성취된다｣고 하였다.
    일귀는 역행하여 3전이 백․주․현이고, 야귀는 순행하여 3전이 현․주․백이다. 귀신은 묘(卯)에 임하여 여덕격(勵德格)으로 되고 군자(君子)는 때를 기다려 움직이는 것이 좋다. 보통 사람은 흉으로 전변되기 쉽다. 말전의 묘(卯)의 귀살은 초전의 화(火)를 생하고 사화(巳火)는 일간을 생하므로 귀(鬼)가 화하여 흉을 이루지 않는다.`
    ,
    // 9
    `  이 과는 제1과․제4과의 두 과가 하에서 상을 적하여 비용(比用)을 취하지 않으므로 섭해과로 되며 지반은 맹신의 극(剋)이다. 유사(酉巳)를 사용하여 초전으로 하고 삼합금국(三合金局)의 종혁격이다.
    일간상신은 일지의 역마로 되고 초전의 유(酉)는 정신(丁神)으로 되어 재물이나 개혁하는 일로 인해 동의(動意)가 발생된다. 3전3합의 금(金)은 일간상신의 재를 생하여 나에게 유익되는 일이 많다. 섭해과는 어떤 일에서나 곤란한 일이 있어 모든 일에서 물심양면에 소모가 있다. 일지상신의 사(巳)는 순미(旬尾)의 지지로서 택상(宅上)에 임하는 것은 말을 삼가야 구화(口禍)가 없다. 무슨 일에서나 말을 조심하면 재리와 기타의 수확을 얻는다. 다만 백호, 현무의 흉장이 승하므로 외적에 대하여 경계할 필요가 있다.
    백법에 이르기를 ｢일간에서 보아 삼합금국(三合金局)은 탈기(脫氣)로 되나 일간상신이 재를 설하는 것과 같은 경우는 환혼채격(還魂債格)이라 하여 대여금(貸與金), 즉 채권의 재를 얻는다｣라고 하였다. 비전에서는 ｢일간에서 초전은 설기로 되므로 분실물은 류신인 부녀 또는 쇠붙이 이다｣라고 하였다. 공망되지 않고 유(酉)는 장생(長生)에 해당하기 때문에 분실한 것이 아니라 둔 곳을 잊은 것이다. 쇠붙이 그릇 안에 있다.
    말(末)은 중전을 생하고, 중전은 초전을 생하며, 초전은 일간상신의 재를 생하므로 남이 선전해 주는 덕택으로 수재(收財)는 물론 다른 일도 잘 이루어진다. 일귀는 역행하여 3전이 합(合)․후(后)․백(白)이고, 야귀는 순행하여 3전이 사(蛇)․청(靑)․현(玄)이다.`
    ,
    // 10
    `  이 과는 상하의 극 및 요극(遙剋)이 없어 묘성과(昴星課)로 된다. 음일이므로 천지반 중 천반 유(酉)의 하신(下神) 오묘(午卯)를 초전으로 하고, 중전은 간상신, 말전은 지상신으로 음양을 엇갈아 취하는 것이 원칙이다. 묘성과는 부앙격(俯仰格)이라고 한다. 유(酉)를 목표로 하는 것은 유(酉) 안에 묘성(昴星)이 있기 때문이다. 음일에는 유(酉)의 아래를 취하여 초전으로 하는데 이는 태양이 장차로 서산에 져서 고개를 수그린다는 뜻이다. 유(酉)는 살기(殺氣)를 생하는 신으로서 우위(憂危)․재해의 과(課)이다. 부격(俯格)은 걱정이 가까이에 있는데, 양일의 부격인 때는 걱정이 먼 것이다.
    일간일지상신에 진술(辰戌)이 있고 3전 중에 진술(辰戌)을 보면 이 신이 동신(動神)이지만 음일은 복닉(伏匿)하여 종적을 발견하기 어렵다. 오(午)가 묘(卯)에 가해지면 명당(明堂)이라 부르는데, 처음에는 번창하지만 공망되어 반감된다. 초전의 공망은 고진(孤辰)으로 되어 정단사에서는 거짓이 많고 실의(實意)가 없다. 백법에는 ｢공상이 천공을 만나면 빈말뿐이므로 아무 일도 추구하지 말라｣고 하였다. 일귀는 역행하여 3전이 공(空) ․주(朱)․상(常)이고, 야귀는 3전이 주(朱)․음(陰)․구(勾)이다. 비전에는 ｢묘성엄목(昴星掩目)은 눈을 가려 흉해가 숨어 있는 가운데 발생하며 놀라운 사건을 만난다｣고 하였다.`
    ,
    // 11
    `  이 과는 제3과에서만 상이 하를 극하여 원수과(元首課)로 된다. 초전은 택상의 관귀가 초전으로 되지만 일간상신이 이것을 제어하여 선(善)이 되지 못한다. 원수과는 위에서 부하를 제어하여 광명 정대하고, 만사에서 선수를 쳐 기선을 제압하므로 유리하다. 묘사미(卯巳未)는 음지(陰支)의 순간전영양격(順間傳迎陽格)으로서 묘(卯)는 해돋이이고 사미(巳未)에는 해가 둥근 모양이므로 이런 이름이 붙었다. 무슨 일이나 빨리 해버리는 것이 좋다. 때를 놓쳐 늦어지면 해지는 저녁을 맞게 되기 때문이다. 이러한 이치에 따라 일찍 신속하게 행동해야 한다.
    간전격(間傳格)은 일지(一支)를 사이두고 순역(順逆)의 3전을 이룬다. 순역은 어느 것이나 각각 12격이 있는데, 무슨 일에서나 곧게 전진하여 달성하기는 불가능하며 간격을 두면서 진행하는 격이다. 순(順)은 사물이 순조롭게 진행되고 역(逆)은 주사(主事)가 역조(逆潮)을 나타낸다. 그러나 일간(日干)에 대한 3전의 왕약(旺弱)에 따라 길흉의 반응이 있다. 왕상하고 길장이 타면 길을 이루고, 휴수(休囚)하고 흉장이 타면 흉조를 이룬다. 일간에서 상신(上神)을 생하여 탈기하고 일지상신과 7충 된다. 피차간에 서로 불화를 초래하고 또 4과의 음양과 음신의 상하가 각각 7충으로 되어 상하내외로 싸운다.
    백법에 이르기를 ｢귀신이 타는 상하가 상극하고 극지(剋地)에 임하기 때문에 귀인이 번민하여 윗사람의 원조는 바라기 어렵다｣고 하였다. 일귀는 역행하여 3전이 현․백․청이고, 야귀는 3전이 청․합․사 이다. 청은 화하여 사(蛇)로 된다.`
    ,
    // 12
    `  이 과는 4과 중에 제2과가 상신(上神)에서 하를 극하여 원수진여격(元首進茹格)으로 된다. 3전 인묘진(寅卯辰)은 정화격(正和格)이라 부르는데 모든 일이 발전하며 은광(恩光)을 입는다.
    3전이 목합(木合)하여 일간을 극하나 일간상신인 신(申)에서 제하므로 귀(鬼)라 해도 두려울 것이 없다. 그러나 합하여 관성(官星)은 자리(位)와 때를 얻으므로 현임 관리에게는 진급하는 기쁨이 있고 구관(求官)은 목적을 달성한다. 원수진여과(元首進茹課)는 만사가 전진하고 순조롭다. 무슨 일에서나 목적이 달성되지 않는 일이 없다. 길장이 타면 적절하다. 춘월에 정단하면 3전이 천시를 얻어 더욱 길하다.
    백법에서는 ｢4과 지신(地神)의 전지(前支)가 천신으로 되면 나망(羅網)이라고 부르는데, 모사는 졸책(拙策)이 된다｣라고 하였다. 제3과․제4과에서 일간이 귀(鬼)로 되면 귀가 삼(三), 사(四)에 있다. 소송이나 관사에서 재화가 있다. 귀(鬼)가 전창(全彰)하더라도 간상신에게 제어하므로 두려울 것이 없다. 일귀는 순행하여 3전이 주․합․구이고, 야귀는 역행하여 3전이 공․청․구이다. 야귀는 청룡이 전에 들어 입시․선거 등에 특히 당선된다.
  `
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
