const columnA = [
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`01`, `좌`],
  [`23:32`, `04 `],
  [`01:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`02`, `좌`],
  [`01:32`, `09`],
  [`03:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`03`, `좌`],
  [`03:32`, `06`],
  [`05:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`04`, `좌`],
  [`05:32`, `11`],
  [`07:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`05`, `좌`],
  [`07:32`, `08`],
  [`09:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`06`, `좌`],
  [`09:32`, `01`],
  [`11:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`07`, `좌`],
  [`11:32`, `10`],
  [`13:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`08`, `좌`],
  [`13:32`, `03`],
  [`15:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`09`, `좌`],
  [`15:32`, `12`],
  [`17:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`10`, `좌`],
  [`17:32`, `05`],
  [`19:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`11`, `좌`],
  [`19:32`, `02`],
  [`21:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`12`, `좌`],
  [`21:32`, `07`],
  [`23:31`, `내용 해당`],
  [``, ``],
];
const columnD = [
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [
    `1.2월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `궤후`,
    `천부적좌고우면신중성자`,
    `신뢰`,
    7,
    9,
    `없음`,
    `01.21\n~
  02.19`,
    `보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,
    `조용\n담담
  
  성교후곧잠`,
  ],
  [
    `4.5월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `단절자`,
    `단斷`,
    `고립적`,
    `1 4\n7 10`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 4\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `신뢰`,
    8,
    8,
    `절반`,
    `02.20\n~
  03.20`,
    `굵음\n적극적 매너
  
  강력한 성교 함`,
    `문을 열면 적극적이 됨\n
  감격을 숨기지않음`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `인자`,
    `천부적진취창의성자`,
    `설득`,
    9,
    7,
    `있음`,
    `03.21\n~
  04.20`,
    `단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,
    `주도적\n적극적
  
  완벽
  주의자 임`,
  ],
  [
    `4.5월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `인자`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 3\n7 10`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `인자`,
    `천부적좌고우면신중성자`,
    `위선`,
    10,
    6,
    `있음`,
    `04.21\n~
  05.20`,
    `때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,
    `정숙\n하고 
  억제심 강함
  
  분비물 많음`,
  ],
  [
    `4.5월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `인자`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 3\n7 10`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 14\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `위선`,
    11,
    5,
    `있음`,
    `05.21\n~
  06.20`,
    `기교파 반응 감상 타입\n
  크고, 즐김`,
    `위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴褪`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `예의`,
    `천부적진취창의성자`,
    `설득`,
    12,
    4,
    `없음`,
    `06.21\n~
  07.20`,
    `기교가 세밀함`,
    `분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`,
  ],
  [
    `4.5월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `예의`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `10후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `14\n710`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `예의`,
    `천부적좌고우면신중성자`,
    `고집`,
    1,
    3,
    `없음`,
    `07.21\n~
  08.20`,
    `정력파 女만족시키려 함\n
  쉽게 지침`,
    `적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`,
  ],
  [
    `4.5월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `예의`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `10후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `퇴세자`,
    `퇴褪`,
    `퇴휴적`,
    `14\n710`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 14\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `신뢰`,
    2,
    2,
    `없음`,
    `08.21\n~
  09.20`,
    `헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,
    `부끄러움 많이 탐\n
  성기는 명물임`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `포살`,
    `천부적진취창의성자`,
    `설득`,
    3,
    1,
    `없음`,
    `09.21\n~
  10.20`,
    `조루증 많음\n
  음경은 작은 편`,
    `음모 많음\n
  수축 형이고 능란함`,
  ],
  [
    `4.5월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `3 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `포살`,
    `천부적좌고우면신중성자`,
    `설득`,
    4,
    12,
    `없음`,
    `10.21\n~
  11.20`,
    `보통이상 크기\n
  극적. 본능에 그침`,
    `은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`,
  ],
  [
    `4.5월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `3 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `종교재난`,
    `1 2\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `신뢰`,
    5,
    11,
    `없음`,
    `11.21\n~
  12.20`,
    `통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,
    `적극적 \n상위 취함`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `종교발전`,
    `2 4\n5 7`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `종교탄핵`,
    `1 2\n4 5`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `종교한산`,
    `2 4\n5 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `종교대성`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `퇴세자`,
    `퇴退`,
    `활인탄핵`,
    `10후\n11후`,
    `궤후`,
    `천부적진취창의성자`,
    `설득`,
    6,
    10,
    `없음`,
    `12.21\n~
  01.20`,
    `보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,
    `적극적 \n상위 취함`,
  ],
  [
    `4.5월`,
    `소외자`,
    `소疏`,
    `활인한산`,
    `5 6\n7`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `진세자`,
    `진進`,
    `활인발전`,
    `2 후\n3 후`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `왕세자`,
    `성盛`,
    `활인대성`,
    `현재`,
    `지헤`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `단절자`,
    `단斷`,
    `활인재난`,
    `1 4\n7 10`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
];
const columnS = [
  [``, ``, ``, ``, ``, ``],
  [`관련`, `속사정`, ``, ``, `外業신변 男上`, `內事 가정 女下`],
  [``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``],
  [`관사`, `관사 관련사`, `小`, ``, ``, `관직자`],
  [`직업`, `진직영전사`, `小`, `7`, `목적달성`, `생살대권`],
  [`색정`, `내외 부부 동시색정사 有`, `大`, `단절기`, `색정사有`, `색정사有`],
  [
    `진직천관(進職遷官)`,
    `승진 영전사가 있게되는 상`,
    `大`,
    ``,
    `손해도망`,
    `무례반목`,
  ],
  [`역유재희(亦有財喜)`, `재희(財喜)사의 상`, `小`, ``, ``, `일반刑흉`],
  [`박관`, `직위불안사-急文辭 투서헌책불득용(不得用)`, `小`, ``, ``, ``],
  [`극부`, `남편갈등사`, `大`, `8`, `동요불안`, ``],
  [`여식`, `여식문제사`, `小`, `침체기`, `생업침체`, `능욕박해`],
  [
    `도적위란(盜賊謂亂)`,
    `도둑난(亂)-실재(失財)사의 상`,
    `小`,
    ``,
    `권리부족`,
    ``,
  ],
  [
    `암조능욕(暗遭凌辱)`,
    `남모르게 당하는 능욕박해사 가 있는 상`,
    `大`,
    ``,
    `희경사`,
    ``,
  ],
  [`후원`, `후원조력사`, `大`, ``, ``, ``],
  [`문서`, `문서관련사`, `小`, `9`, `관재형벌`, ``],
  [`부친`, `부친관련사`, `小`, `소외기`, `면모훼손`, `부부불화`],
  [
    `종심무환(從心無患)`,
    `되어진 일을 따르는 마음이면  화환이 없어질 상`,
    `大`,
    ``,
    ``,
    `면모훼손`,
  ],
  [`부처반목(夫妻反目)`, `內 부부무례반목의 상`, `小`, ``, ``, ``],
  [`후원`, `후원조력사`, `大`, ``, ``, ``],
  [`문서`, `문서사`, `小`, `10`, `이전이동`, ``],
  [
    `결속`,
    `음12월 결속완성해 후원사를 크게이루나 자식근심 발생함`,
    `小`,
    `침해기`,
    `다사단합`,
    `가정막힘`,
  ],
  [
    `혼길파실(婚吉破失)`,
    `혼인사는 좋으나 깨어져 상실되는 일이 있는 상`,
    `大`,
    ``,
    `가정불화`,
    `이익진취`,
  ],
  [
    `미초희경(微招喜慶)`,
    `고위로부터 술잔을 내려받고 기쁨을 애써 참는 상`,
    `小`,
    ``,
    `자녀걱정`,
    `음10월`,
  ],
  [`박관`, `직위불안사`, `小`, ``, ``, ``],
  [`극부`, `남편갈등사`, `大`, `11`, ``, ``],
  [`자식`, `자식 문제사`, `小`, `쇠퇴기`, `환성경사`, `탄식신음`],
  [
    `입충해석(入塚解釋)`,
    `묘(墓)에 들어가는 상- 각자 해석 필요`,
    `小`,
    ``,
    `직위불안`,
    `손해가출`,
  ],
  [
    `탄식신음(嘆息呻吟)`,
    `병 근심 또는 도모사가 완성되지 못해 탄식하는 상`,
    `大`,
    ``,
    ``,
    ``,
  ],
  [`자매`, `형제자매 관련사`, `大`, ``, ``, ``],
  [`친구`, `친구 관련사 `, `大`, `12`, `존비상몽`, ``],
  [`동료`, `동료 관련사`, `小`, `과시기`, `신분나이`, `관재질병`],
  [`도사과모(道士寡婦)`, `도사 과부 가택의 상`, `小`, ``, `차이음란`, ``],
  [`재물유실(財物遺失)`, `재물유실 손실의 상`, `小`, ``, `관계`, ``],
  [`직위`, `外 상하화합지위사`, `大`, ``, ``, ``],
  [`녹위`, `수입증가사`, `大`, `1`, ``, ``],
  [`자산`, `재산 관련사`, `小`, `절정기`, `상하화합`, `파재극처`],
  [
    `권리행사(權利行事)`,
    `권리행사 결여의 상-(음9월에 회복됨)`,
    `小`,
    ``,
    `動止咸宜`,
    `혼인단장`,
  ],
  [`복통수욕(腹痛受辱)`, `복통으로 망신을 당하는 상`, `小`, ``, ``, ``],
  [`박관(剝官)`, `직위 불안사`, `小`, ``, ``, ``],
  [`극부`, `남편 갈등사`, `大`, `2`, ``, ``],
  [
    `결속`,
    `음12월 결속완성, 후원사를 크게이루나 자식근심 발생함`,
    `大`,
    `면모기`,
    `외교막힘`,
    `복잡단합`,
  ],
  [
    `복통수욕(腹痛受辱)`,
    `복통으로 인한 수욕의 상`,
    `小`,
    ``,
    `이익진취`,
    `왕래詞訟`,
  ],
  [
    `사송계류(詞訟稽留)`,
    `외교가 막혀 송사가 끝나지 않고 이어지는 상`,
    `小`,
    ``,
    `음10월`,
    ``,
  ],
  [`금전`, `재물사`, `小`, ``, ``, ``],
  [`사업`, `사업 관련 급속사`, `小`, `3`, ``, ``],
  [`처`, `처 관련사`, `大`, `학습기`, `급속사有`, `급속사有`],
  [`공명미수(功名未遂)`, `공명사 미수의 상`, `小`, ``, `재난소실`, `身物손실`],
  [`관재질병(官災疾病)`, `관재 질병의 상`, `大`, ``, ``, ``],
  [`금전`, `금전지체부족사`, `小`, ``, ``, ``],
  [`상업`, `사업 관련사`, `小`, `4`, `酒食宴會`, `동요분산`],
  [`여인`, `연정사 `, `大`, `개시기`, `상대시혜`, `이혼불안`],
  [
    `사택불녕(私宅不寧)`,
    `본가 아닌 딴 살림처가 불안한 상`,
    `小`,
    ``,
    `간사은닉`,
    `배신門戶`,
  ],
  [`간사은닉(奸邪隱匿)`, `첩(연인)을 숨겨둔 상`, `大`, ``, ``, `관직영전`],
  [`박관`, `직업직위 불안사`, `小`, ``, ``, ``],
  [
    `매사 허성불성`,
    `음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,
    `大`,
    `5`,
    `반목대립`,
    `중중희애`,
  ],
  [
    `매사 영속성없음`,
    `직업직위남편남자자식 잘 속는 일은 선천적 기복재해를 겪음`,
    `小`,
    `배양기`,
    `급속송사`,
    `화합허당`,
  ],
  [
    `중중희애(重重喜愛)`,
    `정을 주는 정도가 크고 무거운 상`,
    `小`,
    ``,
    `상해우려`,
    ``,
  ],
  [
    `우유한가(優遊閑暇)`,
    `용모를 다듬을 필요가 없는 한가로운 상-실업자 격`,
    `大`,
    ``,
    ``,
    ``,
  ],
  [`관사`, `관사 관련사`, `小`, ``, ``, ``],
  [
    `매사 허성불성`,
    `음7,8,10,11월 방문자는 종교진출하면 성공함`,
    `小`,
    `6`,
    `不宜투서`,
    `폭객기능`,
  ],
  [
    `매사 영속성없음`,
    `관사관직직업권리남편남자사는 선천적 기복재해를 겪음`,
    `大`,
    `존재기`,
    `헌책구설`,
    `이사이동`,
  ],
  [
    `맹호출시(猛虎出市)`,
    `무서운 호랑이가 나타난 상-(재해 救免 됨)`,
    `大`,
    ``,
    ``,
    `함`,
  ],
  [
    `폭객기능(暴客欺凌)`,
    `폭력배의 속임수와 능욕을 당하는 상`,
    `小`,
    ``,
    ``,
    ``,
  ],
];
const columnZ = [
  [``, ``, 0, ``, ``],
  [`단계`, `단계`, 0, `가능`, `불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明시작`, ``, 0, ``, `색정사로 불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明종료`, ``, 0, ``, `직위부부갈등자녀사로 불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明진행`, ``, 0, `부친문서후원으로 가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, `모친문서후원으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明하향`, ``, 0, ``, `직위부부갈등자녀문제로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗절정`, 0, `자매조력으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗시작`, 0, `직위자산형재조력으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗종료`, 0, ``, `직위불안부부갈등자녀문제로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, `금전부인사업활용으로빠르게가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明진행`, ``, 0, `금전여인사업활용으로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, ``, `세속허사자로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明절정`, ``, 0, ``, `세속하사자로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
];
const columnAF = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
];
const columnAS = [
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [2],
  [0],
  [0],
  [0],
  [0],
  [3],
  [0],
  [0],
  [0],
  [0],
  [4],
  [0],
  [0],
  [0],
  [0],
  [5],
  [0],
  [0],
  [0],
  [0],
  [6],
  [0],
  [0],
  [0],
  [0],
  [7],
  [0],
  [0],
  [0],
  [0],
  [8],
  [0],
  [0],
  [0],
  [0],
  [9],
  [0],
  [0],
  [0],
  [0],
  [10],
  [0],
  [0],
  [0],
  [0],
  [11],
  [0],
  [0],
  [0],
  [0],
  [12],
  [0],
  [0],
  [0],
  [0],
  [1],
];
const columnAU = [
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유화적 처신으로 후로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 시시비비를 가리려 말고 순응 유화적 자세로 사안을 뒤로 연기시켜아만 결과가 반드시 유리해지는 人`,
    0,
    0,
    `물러날 상황이나 되려 잔진하게 됨**전화위복됨`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `간난(艱難)험악고초**만약 상대와 다툰다면  근심걱정을 풀기어려우니 신중해야만 하고 후에 응해야 현명하며 방심하면 불리함`,
    0,
    0,
    `풍상 고초 후에 성공할 人`,
    0,
    0,
    `타인의 호의를 쉽게 받아들이지 말고 전후좌우를 세심하게 관찰해야함**현재 어둡고 기력이 없으므로 매사를 이면적으로 처신해야 함 `,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `호가호위 세(狐假虎威 勢**만약 타인과 다툰다면 피아간에 전의가 별로 없고 손상도 없으며 있다 해도 가벼움`,
    0,
    0,
    `옳지못한 일이 밝혀져 심중 놀람이 크나 말과 처신만 절제하면 자연적으로 해소될 人`,
    0,
    0,
    `집에서 쉬는 것이 좋고 타지에 나가면 불리함**일이 모두 연루되어 있으나 멈추면 조용해짐** `,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `강건정당속결**만약 타인과 다툰다면  먼저 나서서 속결처리해야만 유리함`,
    0,
    0,
    `명분이 있으므로 정정당당하게 선수적 종결이 유리하고 좌고우면하여 지체시키면 되려 불리해지는 人`,
    0,
    0,
    `여러 일이 하나로 묶어질 듯하나 결국 단절되고 번잡함**독신 고독 별리 각방 주말부부격 `,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 먼저 나서지 말고 순응적 처신으로 사안을 후로 미루ㅓ야만 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 순응 유화적 자세로사안을 지체 연기시켜야만 결과가 반드시 유리해지는 人`,
    0,
    0,
    `사람이나 가정이 모두 불리함**관재 발생 우려됨**고독 독신 별리 각방 주말부부 격`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `동요분산불안배신**만약 상대와 다툰다면 간신이 있고 천지가 뒤집힌 격으로 재난이 밖에서 오니 신중해야 함 `,
    0,
    0,
    `일이 반복되어 불안하고 색정사로 진퇴양난격에 처한 人`,
    0,
    0,
    `숨기고 감추는 일이 있음**위지처가 없음**처지 번민함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 시시비비를 가리려 말고 순응유화적 처신으로 후로 미뤄야만 반드시 유리해지는 人`,
    0,
    0,
    `권리행사는 초기외 공허함**승진도 지체하면 허사됨**매사 음적 이면적으로 접근해야 함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 순응 유화 처신해야만 결과가 유리해지는 人`,
    0,
    0,
    `번잡한 여러 일을 하나로 결속시키려 하나 결국 동일권 시도가 불발함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 순응 유화적 처신으로 사안을 뒤로 미뤄야만 결과가 반드시 유리해지는 人`,
    0,
    0,
    `숨기고 감추는 일이 있음**위지처가 없음**관송사 번민함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 상대와 다툰다면 맞서지 말고 유순한 처신으로 사안을 뒤로 미뤄야만 결과가 유리함`,
    0,
    0,
    `역상적인 사안으로 억울하더라도 절대 순응 유화적 자세로사안을 지체 연기해야만 결과가 반드시 유리해지는 人`,
    0,
    0,
    `관공직 옮김**신택불안**매사반복**풍운불측 격`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `간난(艱難)험악고초**만약 상대와 다툰다면  근심걱정을 풀기어려우니 신중해야만 하고 후에 응해야 현명하며 방심하면 불리함`,
    0,
    0,
    `풍운고초 후 성공할 人`,
    0,
    0,
    `타인의 호의를 쉽게 받아들이지 말고 전후좌우를 세심하게 관찰해야함**현재 어둡고 기력이 없으므로 매사를 이면적으로 처신해야 함**앞으로 나아가야 함**풍운불측**종적없이 어디로 숨고싶은 심정임 `,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `부동신음(不動呻吟)**타인과 다툰다면 상대는 기회를 노리고 있으나 움직이지는 않음`,
    0,
    0,
    `움직이고 싶은 뜻고 달리 움직일 수 없는 처지의 人`,
    0,
    0,
    `스스로 유약하다고 자포자가 함**험난한 입장이고 숨기는 일이 있음**풍운불축의 격`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
];
const columnBE = [
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `타발동급속사`,
    `기사`,
    `구사`,
    `내사`,
    `표면이면동시사`,
    `충성`,
    `악성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동사`,
    `기사`,
    `구사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `피해인`,
    `타발동사`,
    `미사`,
    `신구중간사`,
    `내사`,
    `표면사`,
    `배신`,
    `보성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `타발동급속사`,
    `미사`,
    `구사`,
    `내사`,
    `이면사`,
    `충성`,
    `악성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `타발동사`,
    `미사`,
    `구사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `타발동사`,
    `기사`,
    `신구중간사`,
    `내사`,
    `이면사`,
    `배신 무역`,
    `보성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `아발동후회사`,
    `기사`,
    `신사`,
    `외사`,
    `이면사`,
    `충성무력`,
    `악성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동사`,
    `미사`,
    `구사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `아권리가져갈인`,
    `타발동급속사`,
    `미사`,
    `신구중간사`,
    `내사`,
    `이면사`,
    `배신`,
    `보성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `아발동사`,
    `미사`,
    `신구중간사`,
    `외사`,
    `표면사`,
    `충성`,
    `악성무력`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동사`,
    `기사`,
    `구사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성무력`,
    `허위사`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `타발동사`,
    `기사`,
    `신구중간사`,
    `내사`,
    `표면이면동시사`,
    `배신`,
    `보성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
];
const columnBO = [
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `흥`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠-가택고민`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `흥 `],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥-이전함`, `쇠-공허 이사필요`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠-공허 이사필요`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `가정동요부부쟁투`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-피해압박`, `가족이가택싫어함`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-공허`, `가정복잡단합`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `쇠 `],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `손해 가출가택고민`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `흥-이사함`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
];
const columnBS = [
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`승`, `승`, `승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, `승`, ``],
  [``, ``, ``],
  [``, `패`, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [` 무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [``, ``, ``],
  [` 무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [` 비김`, `비김`, ` 비김`],
  [``, ``, ``],
  [``, ``, ``],
];
const columnBV = [
  // [0,0,0,``,0,0,0,``],
  // [용모,가문,合6,``,용모,가문,合6,``],
  // [0,0,0,``,0,0,0,``],
  [3, 3, 6, `음4월`, 2, 2, 4, `음2월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 3, 6, `음5월`, 1, 1, 2, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음4월`, 2, 2, 4, `음8월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음5월-연기 또는 파혼우려`, 1, 1, 2, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 2, 5, `음8월`, 1, 1, 2, `음8월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음5월`, 1, 1, 2, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음4월`, 3, 2, 5, `음12월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음3월`, 1, 1, 3, `음11월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음2월`, 3, 2, 5, `음8월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 1, 4, `음7월`, 1, 1, 2, `음5월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 3, 5, `음6월`, 3, 2, 5, `음4월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [1, 1, 2, `음5월`, 2, 2, 4, `음3월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
];
const columnCE = [
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인격이 높으나 일을 진행하여 결과는 허무하되 외부의 상황에 따른 전화위복적 기우를 받아 공적을 냄`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `둔한 듯하나 공적으로는 별무효과로 드러나지만 개인적 조력에 의지함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `동료의 원조를 받고 공공의 피해를 내는 듯하나 결과를 성공적으로 마침`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인품이 높으나 중간의 일시적 실적으로는 초창기와 결과의 실적을 만회하지 못함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `유순하고 준수 미모이나일처리가 강하지 못해 결국 실적에는 실패함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`인품이 높으나 시종일관 명분과 편가르기 남녀다툼으로 마침`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `언어가 불성실하고 권리행사에 필요한 도장을 새길 칼을 구하지 못하는 격으로 진행함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`둔한 것 같고 역시 둔하게 마침`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`언어가 교묘하며 속이며 권리를 빼앗기는 결과를 초래함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`인격이 높으나 자기 이익만 취하고 공공적 일은 실패함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인격이 높고 공공사도 순서있게 진행하여 초창기의 부진을 극복하며 중간과 결과의 자산 실적을 크게 남김`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `언어가 불성실하나 중반의 침해를 빼고 초창기와 종반기의 실적이 큼`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
];
const columnCL = [
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, `낮`, `밤`],
  [
    `1.2월`,
    `음月적용`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n선택적`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
  ],
  [
    `4.5월`,
    `집集`,
    `반대`,
    `불이행`,
    `선이후이`,
    `進 \n向`,
    `  근`,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    ``,
    `내부제압`,
    ``,
    `느긋이`,
    ``,
    ``,
    `留`,
    `무난`,
    `남남서`,
    ``,
    `정동`,
  ],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `집集`,
    `반대`,
    `불이행`,
    `선실후실`,
    `逆進 逆向`,
    `  원`,
    `留`,
    `주인피해`,
    `정동`,
    ``,
    `동남`,
  ],
  [`7.8월`, ``, `발현안됨`, ``, `이면접근`, ``, ``, ``, `세입가해`, ``, ``, ``],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `선난후난`,
    `退 \n背`,
    `  원`,
    `留`,
    `주인세입`,
    `정동`,
    ``,
    `남남동`,
  ],
  [
    `7.8월`,
    `집集`,
    `내부발현`,
    ``,
    `포기요망`,
    `인내감수`,
    ``,
    ``,
    `상호무례`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, `다집多集`, ``, ``, ``, `종반자산`, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, `進向`, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `음10월`,
    `進 \n向`,
    `  근`,
    `留`,
    `주인무력`,
    `정북`,
    `정남`,
    ``,
  ],
  [
    `7.8월`,
    ``,
    `내부발현`,
    ``,
    `선난후의`,
    `인내持久`,
    ``,
    `이사이전`,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `집集`,
    `기세없음`,
    ``,
    `기다려야`,
    ``,
    ``,
    `하게됨`,
    ``,
    ``,
    ``,
    ``,
  ],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `상대무력`,
    `退 \n背`,
    `  근`,
    `留`,
    `주인무력`,
    `동북`,
    `남남서`,
    ``,
  ],
  [`7.8월`, ``, `이외타인`, ``, `포기요망`, ``, ``, ``, ``, `세밀심사`, ``, ``],
  [`10.11`, ``, `측근제압`, ``, ``, ``, ``, ``, ``, `함`, ``, ``],
  [`3.6\n9.12`, `음9월집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대없음`,
    `불이행`,
    `선이후손`,
    `退 \n背`,
    `원근왕래`,
    `留`,
    `주인압박`,
    `서남`,
    `정동`,
    ``,
  ],
  [
    `7.8월`,
    ``,
    ``,
    ``,
    `압박필요`,
    `동요분산`,
    ``,
    ``,
    `상호대립`,
    `세밀심사`,
    ``,
    ``,
  ],
  [`10.11`, `집集`, ``, ``, ``, `불안`, ``, ``, `동요불안`, `함`, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `다집多集`,
    `반대`,
    `불이행`,
    `선이후난`,
    `退 \n背`,
    `  원`,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    ``,
    `본인발현`,
    ``,
    `신속압박`,
    `권리행사`,
    ``,
    `去`,
    `주인압박`,
    `동북`,
    `정서`,
    ``,
  ],
  [
    `10.11`,
    ``,
    `결말제압`,
    ``,
    `필요`,
    `부실`,
    ``,
    ``,
    `상호미움 `,
    ``,
    ``,
    ``,
  ],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `集`,
    `반대`,
    `불이행`,
    `아 무력`,
    `退 \n背`,
    `  원`,
    ``,
    `세입압박`,
    `동북`,
    `남남동`,
  ],
  [
    `7.8월`,
    ``,
    `측근발현`,
    ``,
    `포기요망`,
    `단합저해`,
    `기력상실`,
    `去`,
    `세입가해`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, `기세없음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, `무시진행`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, `去`, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `아 무력`,
    `退 \n背`,
    `원`,
    `이사이전`,
    `세입피로`,
    `정동`,
    `정남`,
    ``,
  ],
  [
    `7.8월`,
    `다集`,
    `이외타인`,
    ``,
    `권리양도`,
    `권리양도`,
    ``,
    `권리양도`,
    `설기쇠약`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, `종반제압`, ``, `됨`, `됨`, ``, `좀더나음`, ``, ``, ``, ``],
  [`3.6\n9.12`, `집集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `선이후이`,
    `進 \n向`,
    `  원`,
    `去`,
    `세입피로`,
    `동남`,
    ``,
    `정북`,
  ],
  [
    `7.8월`,
    `다집多集`,
    `이면측근`,
    ``,
    `동지적`,
    `초반윗인`,
    ``,
    ``,
    `설기쇠약`,
    ``,
    ``,
    ``,
  ],
  [`10.11`, ``, `기세없음`, ``, `진행필요`, `조력재득`, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `집集`, `무시진행`, ``, ``, `중말허무`, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    `집集`,
    `반대없음`,
    `불이행`,
    `선손후이`,
    `進 \n向`,
    `  원`,
    `留`,
    `주인피해`,
    `남남동`,
    `북북동`,
  ],
  [`7.8월`, ``, ``, ``, `미뤄야`, ``, ``, ``, `세입가해`, ``, ``, ``],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `다집多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `4.5월`,
    ``,
    `반대`,
    `불이행`,
    `선이후난`,
    `進 \n向`,
    `원근왕래`,
    `去`,
    `무난`,
    `정남`,
    ``,
    `동복`,
  ],
  [`7.8월`, ``, `측근내부`, ``, `신속히`, ``, ``, ``, `주인세입`, ``, ``, ``],
  [`10.11`, `집集`, `완전제압`, ``, ``, ``, ``, ``, `동시이사`, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, `하게됨`, ``, ``, ``],
];
const columnCY = [
  [``, ``, ``, 0, 0, ``, 0, ``],
  [``, ``, ``, 0, 0, ``, 0, ``],
  [``, ``, ``, 0, 0, ``, 0, ``],
  [`질병`, `  水`, `익사`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, `상가부정`, `상가조문`, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `특별있음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, `자살귀`, ` 水투신`, 0, 0, `묘좌`, 0, `있음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, `상가부정`, `상가조문`, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `있음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, `자살귀`, ` 水투신`, 0, 0, `묘좌`, 0, `있음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, `도로귀`, `교통사고`, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ` 여귀`, ` 부인`, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, `신부정귀`, `신성침범`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, `신부정귀`, `신성침범`, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ` 여귀`, ` 부인`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, `음사부정`, `제사잘못`, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, `신부정귀`, `신성침범`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ``, ``, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, `음사부정`, `제사잘못`, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
  [`질병`, ` 수귀`, `水익사`, 0, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, 0, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, 0, 0, `전경`, 0, ``],
  [``, ``, ``, 0, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, 0, 0, `육친`, 0, ``],
];
const columnDH = [
  [
    `출생연도`,
    `24`,
    `96`,
    `25`,
    `97`,
    `26`,
    `98`,
    `27`,
    `99`,
    `28`,
    `00`,
    `29`,
    ``,
    `30`,
    `102`,
    `19`,
    `91`,
    `20`,
    `92`,
    `21`,
    `93`,
    `22`,
    `94`,
    `23`,
    `95`,
  ],
  [
    0,
    `36`,
    ``,
    `37`,
    ``,
    `38`,
    ``,
    `39`,
    ``,
    `40`,
    ``,
    `41`,
    ``,
    `42`,
    ``,
    `31`,
    ``,
    `32`,
    ``,
    `33`,
    ``,
    `34`,
    ``,
    `35`,
    ``,
  ],
  [
    0,
    `48`,
    ``,
    `49`,
    ``,
    `50`,
    ``,
    `51`,
    ``,
    `52`,
    ``,
    `53`,
    ``,
    `54`,
    ``,
    `43`,
    ``,
    `44`,
    ``,
    `45`,
    ``,
    `46`,
    ``,
    `47`,
    ``,
  ],
  [
    0,
    `60`,
    ``,
    `61`,
    ``,
    `62`,
    ``,
    `63`,
    ``,
    `64`,
    ``,
    `65`,
    `01`,
    `66`,
    `02`,
    `55`,
    `03`,
    `56`,
    `04`,
    `57`,
    `05`,
    `58`,
    ``,
    `59`,
    ``,
  ],
  [
    0,
    `72`,
    `08`,
    `73`,
    `09`,
    `74`,
    `10`,
    `75`,
    `11`,
    `76`,
    `12`,
    `77`,
    `13`,
    `78`,
    `14`,
    `67`,
    `15`,
    `68`,
    `16`,
    `69`,
    `17`,
    `70`,
    `06`,
    `71`,
    `07`,
  ],
  [
    0,
    `84`,
    `20`,
    `85`,
    `21`,
    `86`,
    `22`,
    `87`,
    `23`,
    `88`,
    `24`,
    `89`,
    `25`,
    `90`,
    `26`,
    `79`,
    `27`,
    `80`,
    `28`,
    `81`,
    `29`,
    `82`,
    `18`,
    `83`,
    `19`,
  ],
  [
    2,
    `내외 결속단합사`,
    `빈궁**관재**전도(顚倒)**도적의혹근심사`,
    `의혹불안사-실재사`,
    `빈궁**관재**전도**전화위복`,
    ``,
    `승진영전사**외사동요분산사**전화위복`,
    `빈궁**병재`,
    `관직자 대길-생살대권 쥠**재액해소`,
    `폭객기만능욕사-이전사`,
    `빈궁**관재`,
    ``,
    `음3,4,5,6,9,12월 부자**고위안좌-이익경영사`,
    `관직자-영전도약-일반인-반흉`,
    `빈궁**음4,5월 화재주의`,
    `사사성취사`,
    ``,
    `투서헌책불량함-구설-실재사`,
    `빈궁`,
    `모두 재난 뿐임`,
    `빈궁**병재**내외급속사`,
    `관계성불통-가택이사-간사은닉`,
    `가정동요분산개변사`,
    ``,
    `탄식신음**폐한(閉寒)인`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    `손해도망사우려`,
    `음4,5월 화재주의`,
    `내외사 출행이동원행사`,
    ``,
    `외사 상하화합사`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    3,
    `재난전소사`,
    `빈궁**관재`,
    `내외결속단합사`,
    `빈궁**관재**전도`,
    ``,
    `부부반목**전도**전화위복`,
    `남 모르게 당하는 능욕박해사`,
    `빈궁**외사동요분산사**전화위복`,
    ``,
    `음10,11,1,2월 부자**고위안좌-이익경영사`,
    `폭객기만능욕사-이전사`,
    `빈궁**형통길사발현**재액해소`,
    `관재형벌사-매우 나쁨`,
    `빈궁**병재**매사위역`,
    `내외사 출행이동원행사`,
    `희경사`,
    `도적악의-공격 말 것`,
    `빈궁**관재`,
    `觀書-문화풍아`,
    `빈궁`,
    `내외 급속사`,
    `급속사**머리단장적 한가`,
    `관계성불통-가택이사-간사은닉`,
    `가정도요분산개변사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `매사불성**종교귀의자립함`,
    `급문사투서헌책-불용됨`,
    `매사불성**종교진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    `출행사`,
    `재액해소`,
    ``,
    `이동사`,
    `손해도망사 우려`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    `가정동요분산개변사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    4,
    `관계성불통-가택이사-간사은닉`,
    ``,
    `재난전소사`,
    `빈궁`,
    `내외 결속단합사`,
    `빈궁**관재`,
    ``,
    `부부반목**전화위복`,
    `남 모르게 당하는 능욕박해사`,
    `빈궁**외사동요분산사**재액해소**전화위복`,
    ``,
    `음10,11,1,2월 부자**고위안좌-이익경영사`,
    `폭객기만능욕사-이전사`,
    `빈궁**형통길사발현`,
    `관재형벌사-매우 나쁨`,
    `빈궁**병재**매사위역**재액해소`,
    `내외사 원행이동출행사`,
    `희경사**전도`,
    `도적악의-공격 말 것`,
    `빈궁**관재**전도`,
    `觀書-문화풍아`,
    `빈궁`,
    `내외 급속사`,
    `급속사**머리단장적 한가`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `가정동요분산개변사`,
    ``,
    `매사불성**종교진출자립함`,
    `급문사투서헌책-불용됨`,
    `매사불성**종교진출자립함`,
    ``,
    `내외 남자여자 동사 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    `손해도망사 우려`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    5,
    `빈궁**병재**내외 급속사`,
    `도적의혹근심사-실재사`,
    `빈궁**가정동요분산개변사`,
    ``,
    `탄식신음사`,
    ``,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `빈궁**관재**전도**전화위복`,
    `눈이 가려져 상해를 할 수 없음`,
    `빈궁**외사동요분산사**전화위복`,
    ``,
    `재액해소`,
    `불리 고시-없는 구설근심사`,
    `반궁**재액해소`,
    `가정불화사-이동사`,
    `음3,4,5,6,9,12월 부자**중중희애사`,
    ``,
    `빈궁**내외사 출행이동원행사`,
    `복통-도모사 吉`,
    `병재**직녹길사발현**부족`,
    `재난소멸사`,
    `희경사-단, 부족`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    ``,
    `음4,5월 화재주의`,
    `손해도망사 우려`,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    6,
    `희경사**관재`,
    ``,
    `빈궁**병재**내외 급속사`,
    `도적실세-잡음`,
    `빈궁**관재**가정동요분산개변사`,
    ``,
    `관재**전도**탄식신음`,
    ``,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `빈궁**전화위복`,
    `눈이 가려져 상해를 할 수 없음`,
    `관재**외사동요분산사**전화위복`,
    ``,
    ``,
    `불리 고시-없는 구설근심사`,
    ``,
    `가정불화사-이동사`,
    `음3,4,5,6,9,12월 부자**중중희애사`,
    `손해도망사 우려`,
    `빈궁**내외사 출행이동원행사`,
    `복통-도모사 吉`,
    `빈궁**병재**직녹길사발현**부족`,
    `재난소멸사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `파재실종상해사 우려`,
    `종교진출하면자립함`,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    ``,
    `음3,4,5,6,9,12월 부자`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    7,
    ``,
    `身물손실사`,
    `빈궁**전도`,
    `왕래 사송계류사`,
    `관재**혼인사**내외 급속사`,
    ``,
    `관재**가정동요분산개변사**재액해소`,
    `관재-병질사`,
    `빈궁**전도**재액해소`,
    `파재실종상해사 우려`,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `관재**탄식신음**전화위복`,
    `내외 남자여자 동시 색정사`,
    `빈궁**외사동요분산사**전도**전화위복`,
    `신분 차이나는 음란-존비상몽사`,
    `빈궁**출행사`,
    `도적을 추격했으나 못잡음`,
    `재물분실사`,
    `이동사`,
    `병재**전도**매사위역**충산동요이별`,
    `관재형벌-매우 나쁨`,
    `빈궁-복통`,
    `내외사 원행이동출행사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    `재난소실사`,
    `충산동요이별`,
    `종교진출하면 자립함`,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별`,
    ``,
    `충산동요이별**이동사`,
    ``,
    `음4,5월 화재주의`,
    `손해도망사 우려`,
    `음4,5월 화재주의`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    8,
    `빈궁**내외사 출행이동원행사`,
    `복통-도모사 吉`,
    `身물손실사`,
    ``,
    `빈궁`,
    `왕래 사송계류사`,
    `관재**혼인사**내외 급속사**전도`,
    ``,
    `빈궁**전도**가정동요분산개변사`,
    `관재-병질사`,
    `빈궁**파재실종상해사 우려`,
    `재난소실사`,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `관재**재액해소**전화위복**가나 있으나 좋음`,
    ``,
    `빈궁**외사동요분산사**전화위복`,
    `신분 차이나는 음란-존비상몽사`,
    `빈궁**출행사`,
    `도적을 추격했으나 못잡음`,
    `재물분실사`,
    `이동사`,
    `관재**전도**매사위역`,
    `관재형벌사-매우 나쁨`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `손해도망사우려`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    9,
    `병재**매사위역`,
    `관재형벌사-매우 나쁨`,
    `빈궁**내외사 출행이동원행사`,
    `복통-도모사 吉`,
    `身물손실사`,
    ``,
    `빈궁`,
    `왕래 사송계류사`,
    `화합사**혼인사`,
    `내외 급속사`,
    `빈궁**가정동요분산개변사`,
    `관재-병질사`,
    `빈궁**파재실종상해사 우려`,
    `재난소실사`,
    `재난극복 이득진취사-전도`,
    `내외단합결속사`,
    `관재**전도**전화위복**가나 있으나 좋음`,
    ``,
    `빈궁**외사동요분산사**전화위복`,
    `신분 차이나는 음란-존비상몽사`,
    `관재**형통길사발현`,
    `도적을 추격했으나 못잡음`,
    `재물분실사`,
    `이동사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `손해도망사 우려`,
    ``,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    `급속사`,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    `이동사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    10,
    `재물분실사`,
    `이동사`,
    `병재**위역사`,
    `관재형벌사-매우 나쁨`,
    `빈궁**재액해소`,
    `복통-도모사吉`,
    `身물손실사**재액해소`,
    ``,
    `왕래 사송계류사`,
    `빈궁`,
    `화합사**혼인사`,
    `내외 급속사`,
    `빈궁**가정동요분산개변사`,
    `관재-병질사`,
    `빈궁**파재실종상해사 우려`,
    `재난극복 이득진취사-부족`,
    `내외단합결속사`,
    `위해제압-진취이익-용속성없음`,
    `관재**전화위복**가나 있으나 좋음`,
    ``,
    `빈궁**외사동요분산사**전화위복`,
    `신분 차이나는 음란-존비상몽사`,
    `빈궁`,
    `도적을 추격했으나 못잡음`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `이동사`,
    ``,
    `손해도망사 우려`,
    ``,
    `음4,5월 화재주의`,
    `내외사 출행이동원행사`,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    `급속사`,
    ``,
    ``,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    11,
    `관직자 대길-생살대권 쥠-일반인 형벌 대흉함`,
    `병재**전도`,
    `폭객기만능욕사-이전사`,
    `빈궁**관재**전도`,
    `중중희애사有`,
    `음3,4,5,6,9,12월 부자**재액해소`,
    `관직자-영전도약 일반인-반흉`,
    `빈궁**재액해소`,
    ``,
    `화합사**매사완성**직녹길사발현`,
    `급문사 투서헌책불량-구설-실재사`,
    `빈궁`,
    `모두 재난 뿐임`,
    `병재**내외 급속사`,
    `가정동요분산개변사`,
    `관계성불통-가택이사-간사은닉`,
    `병 또는 도모사 미수`,
    `파재실종상해사우려**탄식신음`,
    `도적의혹근심사`,
    `빈궁**내외단합결속사`,
    ``,
    `빈궁**외사동요분산사**전화위복`,
    ``,
    `승진영전사**전화위복**`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `출행사`,
    ``,
    `이동사`,
    `중중희애사有`,
    `손해도망우려`,
    `내외사 출행이동원행사`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    ``,
    `매사불성**활인진출자립함`,
    ``,
    `내외 남자여자 동시 색정사有`,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    12,
    ``,
    `승진영전사**외사동요분산사**전화위복`,
    `관재형벌사- 매우나쁨`,
    `빈궁**관재**병재**형통길사발현**재액해소`,
    `폭객기만능욕사-이전사`,
    `재액해소`,
    `중중희애사有`,
    `음3,4,5,6,9,12월 부자**재액해소`,
    `관직자-영전도약- 일반인-반흉`,
    `빈궁**재액해소`,
    ``,
    `화합사**매사완성**직녹길사발현`,
    `급문사 투서헌책불량-구설-실재사`,
    `빈궁`,
    `모두 재난 뿐임`,
    `빈궁**병재**내외 급속사`,
    `가정동요분산개변사`,
    `관계성불통-가택이사-간사은닉`,
    `탄식신음-병 또는 도모사 미수`,
    `빈궁**전도**파재실종상해사 우려`,
    `도적의혹근심-실재사`,
    `빈궁**전도**활인진출해당자-성공함`,
    ``,
    `빈궁**전도**전화위복`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    ``,
    ``,
    `손해도망사우려`,
    `내외사 출행이동원행사`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    `매사불성**종교진출자립함`,
    `내외단합결속사`,
    ``,
    ``,
    `내외 남자여자 동시 색정사 有`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    1,
    ``,
    `빈궁**관재**전화위복`,
    ``,
    `승진영전사**전도**외사동요분산사**전화위복`,
    `관직자 대길-생살대권 쥠-일반인 형벌 대흉함`,
    `빈궁**관재**형통길사발현`,
    `폭객기만능뇩사-이전사`,
    `빈궁**형통길사발현`,
    ``,
    `음3,4,5,6,9,12월 부자**중중희애사有**전도`,
    `관직자-영전도약 일반인-반흉`,
    `빈궁`,
    ``,
    `화합사**매사완성**직녹길사발현`,
    `급문사 투서헌책불량-구설-실재사`,
    `빈궁**전도`,
    `모두 재난 뿐임`,
    `병재**내외 급속사**재액해소`,
    `관계성불통-가택이사-간사은닉`,
    `가정동요분산개변사`,
    `탄식신음-병 또는 도모사 미수`,
    `매사불성**전도**종교진출해당자**성공함`,
    `도적의혹근심-실재사`,
    `빈궁**관재**매사불성**활인진출자립함`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    `내외 남자여자 동시 색정사 有`,
    ``,
    ``,
    ``,
    `출행사`,
    ``,
    ``,
    ``,
    `손해도망사우려`,
    `내외사 출행이동원행사`,
    `음4,5월 화재주의`,
    ``,
    `음4,5월 화재주의`,
    ``,
    ``,
    ``,
    ``,
    ``,
    `재액해소`,
    ``,
    `파재실종상해사 우려`,
    `내외단합결속사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
];

const CHOSA1 = [
  `■ 가정불화로 이사하네? 일도 안되고 계획은 위험하고!

  ⊕ 도로귀
  ⊖ 수귀 장난이야!
  `,
  `■ 하나를 욕심내다가 열을 잃게 생겼네? 아직 때가 안됐어!
  ⊕ 도로귀ㆍ신부정귀
  ⊖가택상가부정ㆍ수귀ㆍ음귀가 침범해서 가정에 병자가 생겼어!
  ⊕가택상가부정이 들었어!
  `,
  `■ 색정사로 돈께나 없어지네? 요행수를 바랄수 없어! 
  방비를 잘하는 수밖에! 
  가장이 집밖에서 겉돌고 있겠어!
  `,
  `■ 지금 관사로 크게 놀라고 있겠는데. 이게 다 
  ⊕ 가택음사부정ㆍ도로귀, ⊖ 물에 빠져죽은 자살귀 때문이야! 의심만 하지 말고 가세 기우는 이유를 알아야지!
  ⊕신변목기부정침범함
  ⊖신변상가부정침범이야!
  `,
  `■ 여러사람 관련사, 일도 여러개네?  
  오직 신불기도가 잘 되는길이네? 
  
  ⊕ 신부정귀, 
  ⊖ 변사귀부터 달래고 봐야겠어!
  `,
  `■ 확 바꾸든가 꽉 지키든가 해야 결판이 나지?
  
  ⊕ 도로귀ㆍ가택수망노부    부정이 듬
  ⊖ 자살귀ㆍ음사부정이 없어져야 차차 좋아져!
  `,
  `■ 계속 착오만 나고 효과는 없으니 부부가 똑같이 바람피고 있어서 그렇네? 조왕신에게 빌고 때를 기다리시오!
  `,
  `■ 뭐, 목적을 갖고 멀리 가봐도 결과가 없게 생겼어! 흉조야! 관직자는 9월에 소원성취가 있겠어 !
  
  ⊕ 수귀발동함
  ⊖ 자살귀 발동으로 경쟁만 하게 생겼어!
  `,
  `■ 여러일을 묶는 일이 있으나 10월이 되어야 성립되니 죽고 살고 신불에 기도하는 수밖에 없네? 그러고 나서 이사를 하면 풀릴거야!
  ⊕ 신부정귀,
  ⊖ 음귀발동을 막고!
  `,
  `■ 여러 사람이 입을 모아 돕고 있네?   좋네! 
  그냥 돌아가시오!
  단,
  ⊕ 여귀,
  ⊖ 수귀는 해소해야 해!
  ⊖신변수망노부부정 침범
  ⊕신변음사부정이 들었어!
  `,
  `■ 어째서 하는 일에 핵심이 없지? 
  윗사람도 믿을 수가 없고!
  
  ⊕ 신부정귀,
  ⊖ 음귀를 퇴치하는 수밖에 없군!
  ⊖신변음사부정이들었어!
  `,
  `■ 관재 붙었네? 내몸하나 편히 누울데가 없겠어! 주의 인정이 변했어 !
  진퇴양난이야! 이게 다
  
  ⊕가택수망노부,
  ⊖가택목기부정 때문이야!`,
];
const YOGYEOL1 = [
  `■ 정마가 셋이고 중전 子와 형이 되어 발동함이 작지 않다. 움직이면 관재ㆍ도적을 당한다.
`,
  `■ 분수밖의 욕심을 내지 말라. 남의 좁쌀 조금 탐내다가 내 반년분 식량이 날아간다. 낮정단은 직녹을 범에게 뺏긴다.
`,
  `■ 과전이 모두 음이니 일이 답답하다. 계속 손해만 나니 윗사람에게 조차 의지가 안된다.
`,
  `■ 내 토대를 범과 도적에게 뺏기니 일을 다시해도 고통뿐이다. 결국 중도에 할 말이 없게 되니 집안에 근심이 항상이다.
`,
  `■ 과전이 고리로 연결되어 일의 매듭이 없다. 타인의 도움을 바라지 말라. 낮정단은 이득이 있게 된다.
`,
  `■ 사람위에 생기가 있으니 지금 것을 지키면 좋다. 섣불리 움직이면 관재ㆍ도적을 만난다. 뱀띠의 병은 수명을 바라지 말라.
`,
  `■ 정마가 가득하니 발에 바퀴를 단 격이다. 여름 낮정단은 불이 두렵고 밤정단은 윗사람의 재물이 들어온다.
`,
  `■ 피아간에 모두 나쁘다. 중전에 공망과 묘가 있고 말전에 정마가 뛰어 모든 일이 종적이 없다.
`,
  `■ 낮정단은 천장이 탈기되나 삼전은 내몸을 돕는다. 길흉이 반반이니 사슬처럼 연결되고 귀인은 성낸다.
`,
  `■ 서로의 교섭이 좋고 도움이 계속되나 내속은 결정이 안된다. 권리 업무는 가히 클 것이다.
`,
  `■ 낮밤의 귀인이 있어도 막상 일이 벌어지면 핑계뿐이다. 내가 힘이 약하니 병점이면 걱정이다.
`,
  `■ 밤정단은 금전이 나가고 이는 친구나 형제 때문이다. 병자는 허리 통증일 것이요 낮정단은 윗사람을 믿지 말라.`,
];
const SIBO1 = [
  `■ 가택이동의 兆가 있다
女는 시집가기를 원한다.
게획은 위험하다. 심신의 자유가 없는 지경이다. 파재ㆍ별리건이 기한다. 모망사는 달성이 어렵다. 활동도 장구하지가 않다. 근신하면 우환은 해소된다. 凶을 凶으로 제어한다. 文書에 치중해야 한다.
正月에 임신사가 있다.
`,
  `■ ⊖ 상가부정듬
가정에 병자가 있다.
모산ㆍ허비가 많다. 吉凶이 내부나 女人으로 인하여 초래하게 된다. 모사는 어두워 옛것을 지키는 것이 좋다. 1을 탐해 10을 잃는 격이다. 酒食으로 凶을 구한다. 신불기도가 좋다. 
⊖ 행상으로 이익이 있다. 공명ㆍ문장사는 좋다.
正月에 임신사가 있다.
`,
  `■ 사물이 양갈래이다
간사사가 있다
색정사를 경계하라. 
內外가 침침하다. 右에서 얻고 左로 잃는다. 요행을 바라지 말고 자기 노력을 경주하라. 모해를 받아도 그 방비를 두려워한다. 
예방을 엄밀히 하라. 
흉해는 점점 해소된다.
`,
  `■ 관송사가 있다. 도망적의
가내우환발생함. 가장속임
가인이 원한품고 加害의 兆
⊕ 음사부정 듬 ⊖ 부녀자의 병. 가정내부사건이다. 의심을 갖고 內心 두려움이 있다. 서로 의심이 있다. 집안에서 근신하면 凶은 없고 吉로 변화한다. 관사가 기하나 해소된다. 人ㆍ宅이 함께 쇠하는 兆. 발설하면 무시당한다.
正月에 임신사가 있다.
`,
  `■ 가내우환발생함. 가장속임
女ㆍ婦는 헌신적이다.
상대가 스스로 와서 돕는다.
동일한 사건이 여러 갈래로 퍼졌다. 他人에게 유리한 국면이다. 신명ㆍ신불에 기도하면 백사가 통하게 된다. 특히 배ㆍ차ㆍ건축ㆍ수리 등에 이익이 있다. 반복부정 과정을 거친다. 夫婦모두 음탕하다. 
⊕행상으로 이익 있다.
`,
  `■ ⊕ 수망노부, ⊖음사부정듬
他人에게 속는 일이 있다.
만사만단을 이기고 현재 상태를 지켜야 吉하게 된다. 
서서히 발복하게 된다. 
만약 지키지 못하면 凶兆가 된다. 실의가 없어도 실망치 말라. 부정을 해소하면 상황이 반전된다.
正月에 임신사가 있다.
`,
  `■ 가택을 손상한다.
여름철 정단은 화재주의
를 주지시켜야 한다.
동요가 많고 쉬지 못한다. 
착오가 반복하여 효과가 없다. 성공한다 해도 패한다. 부부간에 서로 이심하여 사정을 갖고 있다. 조왕신에게  치성하고 물러나 쉬며 때를 기다리라.
⊕⊖ 계획사는 위험하다.
`,
  `■ 自充手적 우환 有
가택을 손상한다.
女의 병이 있다면 약이
없는 병이므로 수법불가임. 본인이나 가정이나 상대가 모두 凶兆이다. 시종이 완전하지 않고 경쟁한다. 현재의 형편에 순응하고 따라야 한다. 서두르게 되면 결국 패만 남는다.
원행ㆍ이동사가 있다.
正月에 임신사가 있다.
`,
  `■ 自充手적 우환有. 허모 多
신불기도가 좋다.
적덕을 쌓아야 한다. 吉凶이 상반하여 凶을 정단하면 계속 凶이 되고, 吉을 정단하면 계속 吉이 있게 된다. 만약 凶일때는 이사를 시키면 吉이 된다. 특히 신불에 기도하면 소원사가 이루어진다. 男ㆍ夫는 헌신적이다. 스스로 가서 능욕을 당한다.
`,
  `■ 他人의 속임을 당한다.
가택권리가 상실된다.
부자라면 財로 身亡한다. 
여러사람의 협력과 추천으로 內外융합하고 순조롭다. 자기 미혹으로 결정이 쉽지 않은 상황이다. 전진하여 대귀를 얻는다. 고난자도 귀하게 되나, 중용을 지키라. 순응하라. 부자는 망한다. 官으로 가정을 破하게 된다.
`,
  `■ 가택 이동의 兆가 있다.
금전ㆍ처로 인해 소송사 기함
公불리, 私 유리함.
하고있는 일에 핵심이서지 않는다. 반복부정하여 금전이 옹색하고 上人의 조력도 없다. 진퇴를 결정할 수 없다. 귀인이 多 한 때문이다. 심신이 피로하기만 하다. 秋ㆍ冬月을 대기하라.
`,
  `■ 관송사가 있다. 도망적의
⊕수망노부 ⊖목기부정듬
吉凶이 겹쳐 기한다.
금전ㆍ처로 인해 소송사 기함. 
상대의 신망을 얻기위해 노력한다. 능력이 부족하여 진퇴양난에 있다. 서둘지 말고 정확한 행동을 해야만 吉하게 된다. 형제 때문에 財가 소모된다. 吉정단은 吉, 凶 정단은 凶으로 본다.`,
];
const SIBO2_1 = [
  `■ 후원사, 이사가택내사이다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,
  `■ 자손신변동요외사이다.
내방조짐 상의 :  
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,
  `■ 자손신변동요외사이다.
내방조짐 상의 : 
은근하고 伏하라.
내심 동요하고 있다.
차질을 거친다.
`,
  `■官･夫,가정손해사이다.
내방조짐 상의 :  
개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
용두사미의 상이다.
`,
  `■ 자손신변외사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 자손재해 예방이 필요하다.
`,
  `■ 속은 일, 가택내사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다. 속는일이 있고 자손 재액이 발생한다.
`,
  `■목전사·후원가택내사이다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,
  `■ 손모사, 후회심이 있다.
내방조짐 상의 :  
원행·이동사가 있다.
조용히 있는게 이롭다.
산소에 우환이 있다
`,
  `■ 자손신변외사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 자손 재해 예방이 필요하다.
`,
  `■ 금전ㆍ여, 가택동요사이다.
내방조짐 상의 :  
은근하고 伏하라.
색정의 상이다.
`,
  `■ 금전ㆍ여, 가택동요사이다.
내방조짐 상의 : 
은근하고 伏하라.
구재․여인 신속하나
화근이 된다.
`,
  `■ 자손, 가정부정들었다.
내방조짐 상의 :  
은둔ㆍ도망ㆍ원행에 이롭다.
관재 근심이 있다.`,
];
const SIBO2_2 = [];

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/004/004_001.png`,
  `/static/images/sagwa/004/004_002.png`,
  `/static/images/sagwa/004/004_003.png`,
  `/static/images/sagwa/004/004_004.png`,
  `/static/images/sagwa/004/004_005.png`,
  `/static/images/sagwa/004/004_006.png`,
  `/static/images/sagwa/004/004_007.png`,
  `/static/images/sagwa/004/004_008.png`,
  `/static/images/sagwa/004/004_009.png`,
  `/static/images/sagwa/004/004_010.png`,
  `/static/images/sagwa/004/004_011.png`,
  `/static/images/sagwa/004/004_012.png`,
];

const HEASYEOL = [
  // 1
  `  이 과는 상하에 극적(剋賊)이 없다. 음일(陰日)이면 일지상신을 초전으로 하며, 중전은 묘(卯)를 형(刑)하는 자(子)를 쓰고, 말전은 자(子)를 충(冲)하는 오(午)를 쓴다. 음일(陰日)은 자신격(自信格)으로 된다. 유(柔)는 음(陰)으로 천지신(天地神)이 동하지 않고 극하지 않는다. 극은 동하여도 극이 없는 것은 동할 수 없다.
  만사는 복(伏)하여 몸(身)은 자유를 얻지 못한다. 그렇지만 이 과의 묘(卯)는 정신(丁神)을 둔간(遁干)한다. 그러므로 사과중 이묘(二卯)와 초전 묘(卯)는 결국 삼정(三丁)으로 겹치고 삼전이 형충(刑冲)하여 동하므로 인사(人事)에서 놀라움이 있다. 삼전중에 흉신(凶神)은 제복(制伏)의 지(地)에 있다. 그리하여 우환(憂患)을 견문(見聞)하여도 허성(虛聲)이며 실해(實害)를 받지 않는다. 복음(伏吟)이라 하더라도 이와 같이 정신(丁神)이 있으면 정(靜) 중에 동의(動意)가 발생하는 것이다.
  삼전이 자오묘유(子午卯酉)의 중신(仲神)으로 되어 점시(占時) 또는 일(日)이 자오묘유(子午卯酉)로 되면 삼교격(三交格)으로 된다. 어떤 일이라도 몸에 감겨서 진퇴불능(進退不能)으로 된다. 또 용전(龍戰)이라고도 부르며 신심(身心)에 의혹이 발생하여 발전이 적고 퇴보가 많다. 만사는 조용히 있는 것이 좋고, 망동(妄動)은 체(滯)가 되며, 모든 모사(謀事)는 목적을 달성하기 어렵다. 두 마리 토끼를 쫓다가 한 마리 토끼도 얻지 못하는 격으로 된다.
  봄(春)은 길하고 겨울(冬)은 평(平)이며 하추(夏秋)는 불길하다. 백법에 말하기를 ｢신임정마(信任丁馬)는 잠시동안에 동의(動意)가 생한다｣고 하였다. 비전에서 말하기를 ｢이 과(課)로서 악장(惡將)을 만날 때는 파재별리(破財別離)의 건이 있다｣고 하였다. 유일(柔日) 형충(刑冲)의 삼전은 가정 내에 놀라운 건이 발생하고 형파(刑破)중에 합(合)이 있는 것은 흉중(凶中)에 길사가 있다.
  일귀는 순행하고 삼전은 구(勾)․사(蛇)․백(白)이고 어느 것이나 흉장(凶將)이며, 야귀(夜貴)는 역행(逆行)하고 삼전은 공(空)․현(玄)․합(合)이며 말전의 합(合)만이 길장으로 된다. 낮귀의 귀인은 천지반 중의 해(亥)에 임하여 등삼천(登三天)으로 되고, 시험사(試驗事)는 적중한다.`,
  // 2
  `  이 과는 제4과 축인(丑寅)의 일하(一下)가 상을 극하여 중심과로 된다. 말전에서 해자축(亥子丑)은 퇴여격(退茹格)으로 된다. 무릇 모사(謀事)를 이루는데는 삽체(澁滯)된다. 그리하여 구태(舊態)를 지키는 것이 유익하다.
  하(下)가 상(上)을 극하므로 하는 상에 유순(柔順)하지 않으며, 어떤 일도 길흉(吉凶)은 내부에서 발생하거나 혹은 여인에 의해 발생한다. 소쟁(訴爭) 등은 후에 일으키는 자가 승리한다. 귀신의 순행(順行)은 길하고 역행(逆行)은 흉조(凶兆)로 된다. 초전이 말전을 극하면 길하고 말전이 초전을 극하면 흉으로 본다. 모두 말전이 길장(吉將)이거나 천월(天月)이 덕록(德祿)을 얻으면 흉이더라도 변화하여 길로 된다. 일간상신이 왕록(旺祿)하여도 백호가 승할 때는 녹(祿)을 지키지 못하며, 초전이 설기(泄氣)로 되어 소비가 많고, 정(丁)의 축(丑)은 일지의 묘신으로 되기 때문에 하나를 탐내어 열 개를 잃는 형상이다.
  말전에 관귀(官鬼)가 승하더라도 공망하고, 또 설기(泄氣)의 축(丑)은 구신(救神)으로 되어 흉을 제어하므로 재화(災禍)는 발생하지 않는다. 백법에 말하기를 ｢일간이 왕록(旺祿)에 승하는 것은 망동(妄動)을 삼가야 한다. 이귀신(二貴神)이 공망하는 것은 기쁜 일이 공허(空虛)로 된다｣고 하였다. 비전에서 말하기를 ｢퇴여삼기(退茹三奇)는 예를 들면 축자해(丑子亥)는 입묘격(入墓格)이라고 부르며 사물을 수장(收藏)하는 형상이다. 갑자(甲子)의 순(旬)은 축(丑)을 삼기옥당(三奇玉堂)으로 하고 축(丑)에 계명(鷄鳴)한다. 일정(日精)이 구비되기 때문에 모든 일은 흉을 만나도 길로 화한다｣고 하였다. 그리하여 형살(刑殺)을 기(忌)하는 일이 없고, 기우(奇遇)가 있어 승진(昇進)의 기쁨이 있다. 관성(官星)을 보고 공명(功名)․문장(文章) 등을 정단하면 더욱 유익하다.
  일귀는 순행하고 삼전은 주(朱)․사(蛇)․귀(貴)로 되며, 말전 해(亥)의 관성(官星)에 귀신(貴神)이 승하여 상기한 바와 같다. 야귀는 역행하고 삼전은 상(常)․현(玄)․음(陰)이며 귀인은 지(地)를 얻지 못하기 때문에 약간 손색이 있다.`,
  // 3
  `  이 과는 상하의 상극이 제3과와 제4과에 있다. 음일(陰日)에 동일한 음극(陰剋)일 때는 섭해과(涉害課)로 된다. 그리하여 지반 사맹(四孟)의 상(上)의 극을 취하며 사맹신(四孟神)이 없는 것은 사중신(四仲神)의 극을 채용하는 것을 원칙으로 하며 제3과의 축묘(丑卯)를 초전으로 한다. 그리하여 사과삼전(四課三傳)이 전부 음지(陰支)이므로 극음격(極陰格)으로 되어 혼미침윤(昏迷沈倫)의 상을 이룬다.
  일간상신 사(巳)의 화(火)는 일지에서 보아서 설기(泄氣)하므로 일간 정(丁)에서 제3과에 설기(泄氣)하여 초전으로 된다. 따라서 우(右)에서 얻어서 좌(左)에 잃는다. 섭해과는 일이 두 개에서 하나를 취하여 사용하는 것도 곤란하다. 쓴맛을 본 다음에 단맛을 알게 되는 것이다. 고생하지 않고 요행의 이득을 얻으려고 하는 것은 불리하고, 땀과 피의 노력에 의해 수확한다.
  삼전 축해유(丑亥酉)는 역간전(逆間傳)으로 되며, 섭해과(涉害課)로서 지반 맹신(孟神)의 극이 없고 중신(仲神)의 극을 초전으로 한다. 이것은 찰미격(察微格)으로 된다. 중신을 취하여 초전으로 하는 것은 해(害)가 점차 얕아지고 경미하기 때문이다. 그것을 관찰하기를 두려워하거나 계획적인 모해(謀害)의 뜻이 있으며 충분하게 경계를 엄하게 하면 해를 면할 수 있으니 예방을 엄하게 해야 한다. 또 이 과(課)는 소인(小人)이 모해의 뜻이 있으므로 예방하는 것이 좋다.
  백법에 말하기를 ｢일야양귀신(日夜兩貴神)의 공망은 기쁨을 들어도 허희(虛喜)뿐이다. 귀효(鬼爻)에 천을(天乙)이 승하는 것은 천을신기격(天乙神祇格)으로 된다. 일귀는 순행하고 삼전은 귀(貴)․음(陰)․상(常)이고 야귀는 삼전이 주(朱)․귀(貴)․음(陰)으로 된다. 격위(隔位)로 삼전을 구성하는 것은 순간전(順間傳)과 이 과와 같은 역간전(逆間傳)이 있다. 순간전은 일이 순(順)하고, 역간전을 구성할 때는 대개는 조해(阻害)가 있다고 한다｣`,
  // 4
  `  이 과는 사과의 상하에 극이 없고 제3과 자(子)의 상신(上神)에서 정일간(丁日干)을 사극(斜剋)하므로 이것을 칭하여 요극과(遙剋課)라고 부른다. 초전 자(子)의 귀효(鬼爻)로 사신(蛇神)이 승하여 중전 유(酉)의 재(財)는 폐구격(閉口格)으로 되며, 점시(占時)가 자오묘유(子午卯酉)로 되어 삼전 사중(四仲)이면 삼교격(三交格)으로 된다.
  말전의 오(午)는 건록으로 백호․현무의 흉상이 승한다. 이 과(課)를 얻어 정단하는 것은 3과를 용신(用神)으로 채용하는데 대개는 내부의 사건이다. 외부의 사건일 때는 업신여김을 받는다. 처음에는 흉세(凶勢)이지만 오래가면 흉은 자연히 호시격(蒿矢格)으로 화하여 해소된다. 초전의 자(子)는 순수(旬首)로 되면 순의(旬儀)로 되고, 양귀(兩貴)가 함께 순행하며 가령 악살(惡殺)이 있어도 해를 이루지 않고 점차 길경(吉慶)으로 화한다. 공사(公私)가 모두 화기(和氣)가 있고 형상(刑傷)은 해제된다. 초전의 귀살(鬼殺)은 일간상신의 진(辰)에서 이것을 제(制)하여 해(害)는 해산되고 오직 자묘(子卯)의 상형(相刑)으로 되며, 가정에 작은 불화(不和)가 있을 뿐이다. 혹은 정단사에 대하여 의심을 품는다.
  일귀는 순행하고 삼전은 사(蛇)․음(陰)․백(白)이며, 야귀는 삼전이 합(合)․귀(貴)․현(玄)으로 된다. 백법에 말하기를 ｢간상(干上)의 진(辰)은 일지 묘(卯)와 육해(六害)로 되며 일지상신(日支上神) 자(子)는 일간기궁(日干寄宮)의 미(未)와 육해로 된다. 즉 교차육해(交車六害)로 되어 피아(彼我)가 함께 상해(相害)되고 의심도 함께 따른다｣고 하였다. 비전에서 말하기를 ｢호시격(蒿矢格)은 제3과의 양신(陽神)을 초전으로 한다. 양양(兩陽)이 상극하여 스스로 싸우니 힘이 있는 것이 먼저 동하지 말아야 한다. 삼교덕기(三交德氣)는 내(內)에 있고 형기(刑氣)는 밖에 있기 때문에 집에 있는 것이 이(利)하고 원행은 불길하다｣고 하였다. 일을 거행함에 있어서는 희노(喜怒)가 함께 있다. 해(解)하는 것은 또 맺는다. 요극과(遙剋課)로 되어 제3과 또는 제4과를 초용(初用)으로 할 때는 흉세는 점차 약해진다.`,
  // 5
  `  이 과는 제4과 미해(未亥)의 상에서 하를 극하여 초전으로 되고 원수과(元首課)의 곡직격(曲直格)이다. 삼전 미묘해(未卯亥)는 사과상신(四課上神)이며, 이와 같은 것은 회환격(廻環格)으로 된다.
  사과는 불비격(不備格)으로 되어도 회환격은 주편(周偏)의 뜻이 있는 것을 불비격으로 하지 않는다. 사과삼전(四課三傳)이 동일한 삼합회국(三合會局)으로 되면 불비격으로 되어도 불비를 쓰지 않는데, 이것은 상기의 이치에 의한 것이다. 회환격은 일이 다단(多端)하여도 동일권내를 벗어나지 않는다. 삼합목국(三合木局)이 되어 일간을 생하는 부모효(父母爻)로 되어도 일간에서 천장(天將)이 오행을 탈(脫)할 때는 전미(全美)를 얻지 못하고 길 중에 실(失)이 있다고 본다. 묘(卯)는 일간을 생하여도 해(亥)의 공망의 지(地)에 있으며, 가택(家宅)에는 유익하지만 사람에게는 무익하다. 또 타인에게는 유익하나 내몸에는 불리하게 된다. 곡직격으로 묘(卯)는 미(未)에 가하여 처음은 직(直)으로 되고 후에 곡(曲)으로 된다.
  백법에 말하기를 ｢서로 생(生)하고 함께 생하는 것은 만사가 유익하다. 귀(鬼)에 천을(天乙)이 승하는 것은 천을신지격(天乙神祗格)으로 된다｣고 하였다. 비전에 말하기를 ｢삼합목국(三合木局)은 선차(船車)․목조건축․수조(修造)․신축(新築)․구재(求財) 등 백사(白事)를 모두 통달한다. 춘월(春月)의 정단은 특히 길하다｣고 하였다. 삼전이 순 부모효로 되어 일간을 생하는 것은 쉽게 구하지 못하지만 몸(身)은 스스로 안태(安泰)하게 된다. 일귀는 순행하고 삼전은 상(常)․구(勾)․귀(貴)이며, 야귀는 삼전이 음(陰)․공(空)․주(朱)이다. 년명상신(年命上神)이 해(亥)로 되면 관공직을 구하거나 국가 시험은 반드시 적중한다. 현임관은 영달하고 승진의 기쁨을 만난다.
  `,
  // 6
  `  이 과는 제2과가 금극목으로 상에서 하를 극하고 제3과는 하(下)의 묘(卯)에서 상의 술(戌)을 극하여 중심과로 된다. 일간 정(丁)의 상신 인(寅)은 화(火)의 장생이 된다. 스스로 능히 만사만단(萬事萬端)에 대해 지킬 때는 오랫동안 길조가 있다. 이와 반대로 어떤 일도 버리고 돌보지 않고 또 지키지 않으면 흉조로 변화한다.
  백법에 말하기를 ｢청룡은 생기(生氣)가 장생에 앉을 때는 길로 되어도 서서히 복이 발생한다｣고 하였다. 술(戌)과 사(巳)의 장간(藏干)은 병신(丙辛)이 있으므로 노치(爐治)로서 주인(鑄印)으로 되어도 삼전에 묘(卯)가 없으므로 규모를 손상한다. 그러므로 주인격으로 보지 않는다. 어떤 일이라도 개혁을 생각하면서 기존의 것을 지키는 것이 좋다. 만약 망동(妄動)할 때는 정신적 고민과 물질적 손실이 있으니 성공한다 해도 결국은 별 효과가 없다. 오히려 금(金)을 화(化)하여 철(鐵)로 만드는 격이다.
  초전․중전이 공망하므로 처음에는 공(空)을 가리키는 빈말과 같이 어떤 일도 실의(實意)가 없다. 후에는 말전의 일신(一神)에 의해 정단해야 한다. 백법에 말하기를 ｢제1과 육합(六合)의 일지상신에 천후(天后)가 승하고 후(后)․합(合)이 승하여 점혼(占婚)은 자유 연애결혼의 징조가 있다. 일간의 묘신(墓神)이 초전으로 되고 관신(關神)을 합병하면 인택(人宅)이 함께 쇠퇴한다｣고 하였다. 관신이란 춘월(春月)은 축(丑), 하월(夏月)은 진(辰), 추월(秋月)은 미(未), 동월(冬月)은 술(戌)로 되기 때문에 이 과(課)는 동월일 때는 간묘병관(干墓倂關)으로 된다. 內戰하여 謀事는 흉을 초래한다.
  일귀는 순행하고 삼전은 후(后)․공(空)․사(蛇)로 된다. 야귀는 삼전이 사(蛇)․상(常)․합(合)으로 된다.
  초전에 묘신(墓神)이 승하여 사수(死囚)로 되는 것은 천옥격(天獄格)이다. 묘(墓)는 일(日)의 고(庫)이고 사수(死囚)는 시령(時令)의 사기(死氣)이다. 술(戌)은 지망(地網)이 되어 사묘장송(死墓葬送)의 기(氣)로 된다. 나옥(羅獄)은 도망하기 어려우며 우환(憂患)의 격이다. 그러나 이 과는 일간상신의 인(寅)이 장생으로 되어 술(戌)의 묘신을 극함으로써 구신(救神)의 작용을 한다.`,
  // 7
  `  이 과는 천지가 상충(相冲)하여 반음과(返吟課)로 된다. 상하의 극이 있다. 그리하여 하에서 상을 극하는 묘유(卯酉)를 초전으로 한다. 묘(卯)는 전부 정신(丁神)이 둔간하여 동요가 격렬하고 또 반음은 쉬지 않고 움직인다.
  하월(夏月)은 일지상신이 주작을 승하면 정신(丁神)의 화기(火氣)가 모여 화난(火難)이 있다. 귀신(貴神)이 승하면 귀인과 윗사람의 재(財)를 얻는다. 간지는 교차상극(交車相剋)으로 되고 지상신(支上神) 유(酉)는 계의 둔귀(遁鬼)가 있다. 재(財)를 만드는 것은 폐구격(閉口格)이 된다.
  반음(返吟)이 의지하지 못하는 격은 반복하여 착오가 생기고 손익(損益)도 서로 반복되어 활동은 많고 효과가 적다. 또 구사(舊事)를 반복하거나 가옥 개수의 징조가 있다. 삼전의 사중(四仲)은 정단 점시(占時)가 사중으로 될 때는 삼교격(三交格)으로 되어 싸움을 의미한다. 월장이 자오묘유(子午卯酉)의 지반에 가해지고 축미(丑未)가 진술(辰戌)에 가해질 때는 천지이번(天地二煩)이 된다. 삼광(三光)이 명확하지 않고 덕기(德氣)는 내(內)에 있고 형기(刑氣)는 외(外)에 있으므로 천지이번이라고 한다. 피점자의 가정에 재화(災禍)가 있다.
  백법에 말하기를 ｢부부(夫婦) 무음격(蕪淫格)은 각각 사정(私情)이 있다｣고 하였다. 비전에 말하기를 ｢화귀(火鬼)와 사주(蛇朱)가 합병하여 택(宅)을 극하면 집안에 화재가 발생한다｣고 하였다. 화귀란 춘(春)은 오(午), 하(夏)는 유(酉), 추(秋)는 자(子), 동(冬)은 묘(卯)이다. 이 과는 하월일 때는 유(酉)가 화귀로 되고 주작이 승하여 묘(卯)의 택(宅)을 극하기 때문에 화액(火厄)이 있다. 조왕(阻王) 신에 기도하여 액을 제거해야 한다. 
  초․말 양전(兩佺)에 정신(丁神)이 승하므로 길흉이 즉시 나타나 행인(行人)은 갔다가 다시 돌아온다. 교역사(交易事)는 성공하고 다시 패한다. 모든 일에 양도양단(兩途兩端)이 있다. 일귀는 역행하고 삼전은 상(常)․주(朱)․상(常)이다. 야귀는 순행하고 삼전이 공(空)․귀(貴)․공(空)으로 된다. 이 과(課)를 얻을 때는 만사는 기다리는 것이 상책이다. 가정은 불안하고 망동(妄動)하면 재앙(災殃)이 있다.`,
  // 8
  `  이 과는 일상(一上)이 하(下)를 극하고, 이하(二下)가 이상(二上)을 극한다. 극이 많아도 음일(陰日)에 음비(陰比)로 되어 제2과 사자(巳子)의 극을 초전으로 하여 중심과로 된다.
  일간(日干)은 자(子)에 극되고, 일지는 신(申)에 극되어 피차 모두 흉으로 되어 가상(家相)은 인택(人宅)이 극을 받는다. 중전(中傳) 술(戌)은 묘신(墓神)으로 공망하고 중간의 토(土) 때문에 은폐되어 사물이 명확하지 않으며, 삼전의 묘신은 길조가 없다. 초전이 역마에, 말전이 정신(丁神)에 승하여 동(動)이 과하므로 안녕하지 못하다.
  주인격(鑄印格)으로 사(巳)는 노(爐)로 되고 술(戌)은 인(印)이며 묘(卯)는 인모(印模)라고 부른다. 그러나 술(戌)과 묘(卯)는 공망하여 인(印)을 파하고 모(模)를 손상하여 관리나 회사원은 영전하지 못한다. 자(子)가 미(未)에 가하여 육해(六害)로 되는 것은 시종이 완전하지 못하며, 신(申)은 묘(卯)에 임하여 경쟁하게 된다. 주인승헌격(鑄印乘軒格)은 원해(遠害)가 복으로 변화한다.
  백법에 말하기를 ｢간(干)과 지(支)가 모두 극되는 것은 양손(兩損)을 방재해야 한다｣고 하였다. 합(合)․후(后)가 함께 승하여 결혼은 중매인이 없이 맺어진다. 비전에 말하기를 ｢신(申)은 묘(卯)에 가해져서 육합(六合)이 승할 때는 미격(迷格)이라고 부르며 입관(入棺)이라고 말한다. 신(申)은 신(身)이고 묘(卯)는 관(棺)이며 병을 정단하는 데는 가장 흉으로 된다. 그러므로 류신(類神)에 따라 정단해야 한다｣고 하였다.
  이 과는 일간의 재(財)로 되기 때문에 처첩의 병을 정단하면 반드시 죽는다. 즉 처재(妻財)가 관통(棺桶)에 들어가기 때문이다. 일귀는 역행하고 삼전은 공(空)․사(蛇)․상(常)이다. 야귀는 순행하고 삼전이 상(常)․사(蛇)․공(空)으로 되며, 질병의 정단은 불길하게 된다.`,
  // 9
  `  이 과의 제1과는 상에서 하를 극하고 제3과와 제4과는 어느 것이나 하(下)에서 상을 극한다. 처음의 극을 써서 초전으로 하여 중심과로 된다. 삼전은 삼합목국(三合木局)으로 되어 곡직격(曲直格)으로 된다.
  목(木)은 일간을 생하여도 낮 천장은 모두 토신(土神)으로 되기 때문에 설기(泄氣)하고, 하나는 생하고 다른 하나는 탈설(脫泄)로 되어 길흉이 서로 상반된다. 과전(課傳)이 함께 해묘미(亥卯未)이고 삼전(三傳)은 사과상신(四課上神)을 떠나지 않는다. 이와 같은 것은 회환격(廻環格)으로, 흉을 정단하면 흉사(凶事)가 발생하고 길사를 정단하면 길조가 일어난다. 일명 반주(盤珠)라고 부른다.
  이 과는 극이 많아서 섭해중심(涉害重審)이라고 이름을 붙였으며 어떤 일도 삽체(澁滯)되어 이룩하기 어렵다. 중전 해(亥)는 공망하고 삼합목국(三合木局)은 동량(棟樑)의 재(財)이다. 그 중에 공망하는 것은 중량(重量)에 견디지 못하기 때문에 이 집을 버리고 다른 곳에서 구득하여 순리롭게 된다. 춘월(春月)의 밤(夜)에 정단하면 길조가 있고 일귀(日鬼)가 공망하여 흉도 두려울 것이 없다. 병을 점하면 증상을 알아도 요법에 손을 쓰기 어렵다.
  백법에 말하기를 ｢천지반(天地盤) 중에 귀신(貴神)이 승하는 곳은 하(下)에서 극을 받기 때문에 귀(貴)를 구하기 어렵다. 제귀(制鬼)의 위(位)는 양의(良醫)가 있고 귀(鬼)와 천을(天乙)이 동병하여 천을신지(天乙神祗)의 격으로 본다｣고 하였다. 비전에 말하기를 ｢해(亥)가 미(未)에 가해지는 것은 상에서 하를 전한다고 말한다. 해(亥)가 묘(卯)에 가하는 것은 먼저 곡(曲)하고 후에는 직(直)으로 되기 때문에 처음에는 곤란하지만 후에 쉽다｣고 하였다. 불비격(不備格)으로 되더라도 삼전사과가 전부 삼합목국(三合木局)하여 회환격(廻環格)으로 되는 것은 불비격으로 하지 않고, 일귀(日鬼)를 발용(發用)으로 하여 현임(現任)의 사람은 역마의 년월(年月)에 영전(榮轉)을 구하여 얻게 된다.
  일귀는 역행하고 삼전은 구(勾)․귀(貴)․상(常)이며, 야귀는 삼전이 주(朱)․음(陰)․공(空)으로 된다. 주작은 이(利)를 구하여 구설이 생기고, 해(亥)의 관귀(官鬼)가 공망하더라도 년명상신(年命上神)이 해(亥)의 전실(塡實)로 되면 시험이나 선거는 반드시 적중한다.`,
  // 10
  `  이 과는 제4과가 단독으로 하에서 상을 극하여 초전으로 되어 시입중심과(始入重審課)이다. 삼전이 사중(四仲)으로 정단시(正斷時)가 사중(四仲)이거나 월장이 사중이면 삼교격(三交格)을 쓴다. 4과의 간지(干支)는 오미묘술(午未卯戌)이다.
  교차지합(交車支合)하며 초전은 중전을 생하고 중전은 말전을 생하며 말전은 일간을 상생하여 이것은 체생격(遞生格)으로 된다. 교차지합(交車支合)은 서로 화합하여 사물과 타협한다. 가정은 서로 평화롭고 융합한다. 체생(遞生)은 타인의 천거를 만나는 상으로서 오직 일간상에 묘신이 복(伏)하고 자기가 혼미하여 결정하기 어렵다. 왕록(旺祿)이 지(支)에 임하면 권섭(權攝)을 선망한다. 삼전이 체생하면 사람은 복리를 얻고 만사개운(萬事開運)의 길을 얻는다. 일(日)에 묘신이 복(伏)하는 것을 애석하게 생각한다.
  삼교격(三交格)은 사물이 구련(勾連)되어 운행하기 어렵고 이번과(二煩課)는 물체에 접촉하여 형극(刑剋)을 생하므로 삼가는 것이 좋다. 중․말전이 순의(旬儀)․지의(支儀)에 승하여 점차 복을 형수해야 한다. 백법에 말하기를 ｢간지가 함께 지(支)에서 상(上)에 설기(泄氣)하기 때문에 인택(人宅)이 함께 누설(漏泄)하매 타인에 의해 도적맞거나 손모(損謀)를 면하기 어렵다｣고 하였다. 권섭부정(權攝不正)은 건록이 지상(支上)에 임하고 삼전이 체생(遞生)하여 사람의 천거를 받는다. 비전에서 말하기를 ｢오(午)가 유(酉)에 가해지면 사교(死交)라고 부르며 유(酉)가 오(午)에 가해지면 파교(破交)라고 부른다. 반음과(返吟課)의 삼교(三交)는 반목(反目)이라고 부르며 어느 것이나 성합(成合)․화합(和合)하는 능력이 없다｣고 하였다. 만약 태음․육합이 없을 때는 삼교가 교차되지 않는다고 한다. 또 년월일시(年月日時)가 모두 사중신(四仲神)일 때는 삼교(三交)가 풀리지 않는다고 말한다. 화(禍)가 더욱 심하고 만약 기왕(氣旺)하여 길장(吉將)이 승하면 관(官)을 점(占)하여 대귀(大貴)를 얻는다. 가을에 점하면 재왕(財旺)하고, 겨울에 점하면 관왕(官旺)한다. 지상(支上)의 오(午)는 택(宅)의 묘(卯)에서 설기(泄氣)된다. 만약 부귀한 사람이라면 재(財)로 인하여 신(身)을 망치고 관(官)에 의하여 가정을 파한다.
  일귀는 역행하여 삼전은 주(朱)․후(后)․상(常)으로 되며, 야귀는 삼전이 귀(貴)․현(玄)․공(空)으로 된다. 양귀(兩貴)가 간(干)을 공(珙)하여 귀(貴)를 고(告)하여 일이 이루어진다. 년명이 미(未)로 되면 시험은 적중할 희망이 있다.`,
  // 11
  `  이 과는 제1과가 3과의 하에서 상을 극하고 처음의 극을 써서 유미(酉未)를 초전으로 한다. 초전 유(酉)는 일간의 재(財)로 되어 주작을 승할 때는 협극(夾剋)으로 되고 재(財)는 자유를 얻지 못한다. 중전의 귀신(貴神)이 공망하거나 일간상신(日干上神)에 귀신이 승하여 귀신이 많은 것은 사물이 하나로 귀일(歸一)되기 어려우며, 화(火)에서 유(酉)를 보면 사(死)로 되어 본신의 힘은 약하고 말전은 초전이 재(財)를 도와 중전의 관귀(官鬼)를 제(制)한다. 중․말전은 공망하고 초전은 지파(支破)로 되어 심신(心身)이 함께 불리하다.
  이 과는 사과삼전(四課三傳)이 음지(陰支)뿐으로 육음격(六陰格)이다. 따라서 모두 미몽(迷蒙)의 상태로 되기 쉽다. 순미(旬尾)의 유(酉)가 초전으로 되어 폐구격(閉口格)으로 심정(心情)을 측정하기 어려우며, 어른이나 존장의 원조를 받지 못한다. 또 묘유일(卯酉日)에 정단하여 묘유(卯酉)가 초전으로 되고 년명(年命)에 묘유(卯酉)가 오르면 용전격(龍戰格)으로 되어 정단자는 반복부정(反復不定)하여 의혹을 일으킨다. 만나는 자는 이별하고, 집안에 있는 자는 밖으로 나가려 하여도 가지 못하며 외출하여 재(災)를 만난다. 구혼(求婚)은 하지 말아야 한다. 잉태(孕胎)는 쉽지 않다. 재물은 불안한 격으로 된다. 또 용전(龍戰)으로 되면 어떤 일도 진퇴(進退)에 대해 결행(決行)하지 못하고, 적덕(積德)하여 안전을 얻어도 함부로 구할 때는 후회를 불러오게 된다.
  백법에 말하기를 ｢사과삼전에 일귀․야귀가 함께 있는 것은 도리어 귀인에 의존하지 못하며, 귀(鬼)에 천을(天乙)이 승하는 것은 신기격(神祇格)으로 된다｣고 하였다. 육음격(六陰格)은 몽매(夢昧)하여 심연(深淵)을 건너가는 것과 같이 공사(公事)는 흉하고 사사(私事)는 유익하다. 병은 몸에 감겨 오래 지속된다.
  일귀는 역행하여 삼전은 주(朱)․귀(貴)․음(陰)이며, 야귀는 삼전이 귀(貴)․음(陰)․상(常)으로 된다. 과전에 귀신(貴神)이 많은 것은 오히려 귀(貴)로 되지 않는다.`,
  // 12
  `  이 과는 제1과와 제3과의 양과가 하에서 상을 적(賊)하여 섭해과(涉害課)로 된다. 지반의 사맹상신(四孟上神)의 극을 취하며, 사맹이 없는 것은 사중상신(四仲上神)인 제3과의 극을 채용하여 초전으로 하고 진묘(辰卯)를 발용(發用)으로 한다. 중상신(仲上神)을 쓰는 것은 극히 얕아 그 미(微)를 관찰하기 때문에 찰미격(察微格)이라고 부른다.
  이 격은 상대방의 불신불인(不信不仁)을 두려워하는 계획이나 모해(謀害)의 뜻이 있다. 따라서 경계하고 예방하면 화난을 방지할 수 있다. 진자(辰字)가 일간ㆍ일지상신에 가해지면 출산을 정단하면 난산으로 된다. 중․말전은 비견과 동류이며, 형제는 일간상신의 재(財)를 쟁탈하는 상이다. 진(辰)은 요(腰)에서 묘(卯)에 임하여 극을 당하고 백호의 병신(病神)이 승하면 근골요통(筋骨腰痛)의 병이 있다.
  진사오(辰巳午)는 진여격(進茹格)으로 되어도 참관격 때문에 전진(前進)에 저해가 있다. 전진하려고 하여도 전진할 능력이 없고 또 퇴(退)하지도 못하며, 순여격(順茹格)으로서 허공(虛空)이 없기 때문에 중상류인 사람은 복력(福力)이 겹친다. 간(干)과 지상신은 암의 일진(一辰)에 승하기 때문에 나망격(羅網格)으로 되고 자기를 지켜 이롭다. 백법에 말하기를 ｢모소졸책(謀所拙策)을 이루는 것은 나망격을 만나기 때문이다｣고 하였다.
  일귀는 역행하여 삼전은 백(白)․공(空)․청(靑)이고, 야귀는 삼전이 청(靑)․구(勾)․합(合)으로 된다. 모두 길장으로 되면 궤계음모(詭計陰謀)는 좋지 않지만 정확한 행동은 길조를 얻는다. 년명상신이 술(戌)일 때는 진(辰)은 충(冲)하여 어떤 일도 장해를 해제한다. 병점(病占)은 백호가 승하는 것을 두려워한다. 용신(用神)의 진(辰)에서 사오(巳午)와 상련(相連)하여 중․말전을 형성하는 것은 일명 연주(演奏)라고도 부른다. 이 과는 삼전이 맹중계(孟仲季)가 상련하는 것으로 관주(寬珠)라고도 한다. 정단사는 길흉이 함께 겹치고 길의 정단은 길이 겹친다. 흉의 정단은 흉사가 겹쳐서 발생한다. 그러나 진연주(進連珠)는 사물이 순조롭게 된다. 제12국은 진연여격(進連茹格)으로 된다.`,
];

export default {
  columnA,
  columnD,
  columnS,
  columnZ,
  columnAF,
  columnAS,
  columnAU,
  columnBE,
  columnBO,
  columnBS,
  columnBV,
  columnCE,
  columnCL,
  columnCY,
  columnDH,
  //
  CHOSA1,
  YOGYEOL1,
  SIBO1,
  SIBO2_1,
  SIBO2_2,
  SAGWA_IMAGE1,
  HEASYEOL,
};
