
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,7,12,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,8,11,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,9,10,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,9,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,11,8,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,12,7,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`신뢰`,1,6,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,2,5,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,3,4,`특별있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,4,3,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,5,2,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`설득`,6,1,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위불안권리상실남편갈등자식 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`4`,`직위불안`,`남편갈등`],
    [`매사 영속성없음`,`직업직위권리남편갈등자식사는 선천적 기복재해를 겪음`,`大`,`개시기`,`도적악의`,`파재극처`],
    [`욕경난경(欲更難更)`,`욕심을 변경해도 역시 다시 되돌아가기 어려운 상`,`大`,``,``,`진직영전`],
    [`삼각관계(三角關係)`,`1남2녀 삼각관계를 벌이고 있는 상`,`小`,``,``,``],
    [`후원`,`후원 문서 모친 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`5`,`급속송쟁`,`개신기로`],
    [`매사 영속성없음`,`후원 문서 모친사는 선천적 기복재해를 겪음`,`小`,`배양기`,`파재실종`,`송구경황`],
    [`관희상외(官喜常畏)`,`관직자는 기쁨이 있고 일반인은 두려움이 닥치는 상`,`小`,``,`執政적의`,`(私情人나`],
    [`발탁도약(拔擢跳躍)`,`발탁되어 도약하게 되는 상`,`大`,``,`(근무환경)`,`타남)`],
    [`금전`,`금전 처 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`6`,`단합결여`,`급속사有`],
    [`급속`,`내부 가정 급속사`,`小`,`존재기`,`허작부실`,`증오가출`],
    [`난경의생(難驚疑生)`,`놀랄 일에 겹쳐 또 의혹사가 발생하는 상`,`大`,``,``,`공중누각`],
    [`발탁도약(拔擢跳躍)`,`발탁되어 도약하게 되는 상`,`小`,``,``,``],
    [`결속`,`개혁으로 음12월 동지사를 크게 이루나 부부이별 발생함`,`大`,``,``,``],
    [`동요`,`내부 가정 동요불안 부부남녀문호(이별)개변사`,`小`,`7`,`외사화합`,`동요분산`],
    [`색정`,`외남 색정사`,`小`,`단절기`,`색정사有`,`부부개변`],
    [`비동구상(卑同俱傷)`,`종업원과 형제가 몸을 다치게 되는 조건이 갖춰진 상`,`大`,``,`선택기로`,`人宅재해`],
    [`허획경구(虛獲驚懼)`,`도적이 도망치다 되돌아봐 무서워 못잡고 놀라는 상`,`小`,``,``,``],
    [`후원`,`후원 문서 부친 관련사`,`小`,``,``,``],
    [`동요`,`외사 동요불안분산사`,`大`,`8`,`동요분산`,`침체공허`],
    [`부친`,`부친 관련사`,`小`,`침체기`,`허획驚懼`,`寵愛쟁탈`],
    [`관서개앙(官庶皆殃)`,`관직자든 일반인이든 차별없이 재앙을 당하게 되는 상`,`小`,``,`(도적못잡`,`(乘車관련)`],
    [`특명성취(特命成就)`,`의욕 대로 일을 성취해 상부로부터 특명을 받게 되는 상`,`大`,``,`고놀램)`,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`남편`,`남편 관련사`,`大`,`9`,`면모훼손`,`가정개신`],
    [`개신`,`내부가정 기로 개신사`,`小`,`소외기`,`침체절감`,`이별이후`],
    [`상인가외(常人可畏)`,`일반인은 가히 두려움을 만나게 되는 상`,`小`,``,`총애쟁탈`,`다시復合`],
    [`간음무도(姦淫無道)`,`간음사가 지나친 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`남자`,`남자 관련사`,`大`,`10`,`외화내허`,`색정사有`],
    [`색정`,`내녀 색정사`,`小`,`침해기`,`간음무도`,`私門청원`],
    [`재환난구(災患難求)`,`재환을 당해도 면할 방법이 어려운 상`,`小`,``,``,`(사적방문)`],
    [`손괴재물(損壞財物)`,`재물을 손괴한 상-양-염소관련`,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`결속`,`결속하여 권리사를 크게 이루나 病 형재災害 발생함`,`大`,`11`,`급속송쟁`,`침체공허`],
    [`송쟁`,`외사 급속송쟁 파재 실종사- 상해 우려`,`小`,`쇠퇴기`,`전화위복`,`吉중喪服`],
    [`관륭상상(官隆常祥)`,`관직자-직위가 흥륭해지고 일반인-상서로움을 맞게되는 상`,`小`,``,`근심해소`,``],
    [`사문청알(사문청알)`,`고위인 또는 상대를 가택으로 찾아가 청원함이 좋은 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`출행`,`외사 출행이동사`,`小`,`12`,`출행이동`,`비용과출`],
    [`과출`,`내부가정비용과출사`,`大`,`과시기`,`간사은닉`,`면모훼손`],
    [`동지공장(動止空張)`,`가다가 멈춤이 길게 비어있는 상-病은 사망함`,`小`,``,``,`가인병액`],
    [`재난불측(災難不測)`,`재난의 정도를 가늠하기 어려운 상`,`大`,``,``,``],
    [`직녹`,`직위녹위권리 관련사`,`小`,``,``,``],
    [`손해`,`손해 도망사`,`小`,`1`,`손해도망`,`가정막힘`],
    [`막힘`,`내부가정 막힘사`,`大`,`절정기`,`허탈손모`,`여인사통`],
    [`흉동난정(凶動難停)`,`날뛰는 흉이 멈출줄을 모르는 상-반드시 금전을 잃었음`,`小`,``,``,``],
    [`음적유조(陰的有助)`,`남모르게 도움을 받게되는 상`,`大`,``,``,``],
    [` 후원`,`후원조력사`,`小`,``,``,``],
    [`막힘`,`외사 외교막힘사`,`大`,`2`,`외교막힘`,`증오가출`],
    [`가출`,`내부가정 증오가출사`,`小`,`면모기`,`개변길행`,`삼각관계`],
    [`서면탈도(庶免脫盜)`,`도적이 날뛰어도 일반인은 도난 피해를 면하게 되는 상`,`小`,``,``,`(1녀2남)`],
    [`도적난포(盜賊難捕)`,`도적을 추적했으나 체포하지 못한 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`급속`,`외사 급속사`,`小`,`3`,`급속사有`,`출행이동`],
    [`출행`,`내부가정 출행이동사`,`大`,`학습기`,`尊卑불화`,`피차의혹`],
    [`생생무궁(生生無窮)`,`적극적으로 활동하여 오랫동안의 생업을 크게 이루는 상 `,`大`,``,`止靜필요`,`발설주의`],
    [`도적악의(盜賊惡意)`,`도적이 악의를 가졌으니 반항하거나 공격하지 말라-는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`금전사업처조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전사업여인색정조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`후원문사부친조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,"관사남편가정기로개신사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,"관사남자내녀색정사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`후원문사모친조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`형제친구동료출행사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`직녹권리손해가정막힘사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`후원문서부친조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,``,"직업직위불안급속출행사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`견련위배지체연기변경**만약 타인과 다툰다면 흑백이 구별되지 못하고 한 그물에 걸린 물고기 격이 되므로 그 자리를 피해야 유리함`,0,0,`어떤 약속도 지체위배연기변경되므로 별도 대책을 강구해둬야만 하는 人`,0,0,`1남2녀 삼각관계有**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결처리해야만 유리함`,0,0,`명분이 있는데도 좌고우면하거나 산안을 뒤로 미루면 결과가 되려 불리해지는 人`,0,0,`일이 역순으로 띄엄띄엄 진행됨**관직자는 승진영전함**지나치게 財女를 탐하면 재앙이 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결처리해야만 유리함`,0,0,`명분이 있는데도 좌고우면하거나 산안을 뒤로 미루면 결과가 되려 불리해지는 人`,0,0,`풍운불측의 상**간사은닉건이 있음**관직자는 영전함**일이 결과없이 반복되나 역시 어두워짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`동료와의 동업 또는 협력사로 중상모략을 받고 불안하여 선택의 기로에 서 있으나 제일 친하고 가까이 거주하는 자와 의논하면 풀리게 되는 人`,0,0,`여러 일을 하나로 일관시키려 함-단, 음12월에 완성- 결속사는 좋으나 금전과 처첩이 훼절됨**가택 이사함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 일을 지체 연기시켜야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 즉시 시시비비를 가리지 말고 유순한 언행으로 일을 이후로 연기시켜야만 반드시 결과가 유리해지는 人`,0,0,`정대한 일들이 단절됨**기이한 전화위복사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상으로 화가 밖에서 오니 신중해야 함`,0,0,`반드시 색정사로 인한 부부갈등으로 移散 이별사가 우려되는 人`,0,0,`가정불화가 심함**파란동요를 겪음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`집안 육친 윗인(父이 아래인(子)을 핍박함**일이 결과없이 반복되나 역사 어두워짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 일을 지체 연기시켜야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 즉시 시시비비를 가리지 말고 유순한 언행으로 일을 이후로 연기시켜야만 반드시 결과가 유리해지는 人`,0,0,`여러 사안을 일관시키려는 결속사 있음-외화내허적이고 이후 관공직사는 좋으나 일반사는 관재 또는 병을 유발함**전화위복사가 있음   `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威 勢)세**만약 타인과 다툰다면 본인이 호가호위의 세를 부려 상대를 공격해도 적중되지 않고 되려 불리하게 됨`,0,0,`본인의 공격이 효과가 없으므로 포기하는 것이 절대 유리한 人`,0,0,`풍운불측의 상**연루사가 있음**간사은닉건이 있음**관직자는 遷轉함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 먼저 나서서 속결처리해야만 유리함`,0,0,`명분이 있는데도 좌고우면하거나 산안을 뒤로 미루면 결과가 되려 불리해지는 人`,0,0,`타인의 음적 도움을 받음**허모사가 많음**냉정한 방침이 요됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 처신으로 일을 지체 연기시켜야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 즉시 시시비비를 가리지 말고 유순한 언행으로 일을 이후로 연기시켜야만 반드시 결과가 유리해지는 人`,0,0,`1녀2남 삼각관계 有**간사은닉건이 있음**일이 순조로운 듯하나 퇴함이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 엿보며 움직이지는 않음`,0,0,`스스로 유약심을 갖고 적극 활동을 주저하며 신음함-신체의 자유가 억압된 경우도 있음`,0,0,`토지분쟁사가 발생하게 됨**산으로 깊숙이 숨고싶은 심정임**가정불화의 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용나감`,`이외사`,`기사`,`구사`,`돌발사`,`모호사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회시`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발독급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동표면사타발동급속사겹침`,`미사`,`신사`,`아외사타내사급침`,`아표면타이면사겹침`,`좌고우면`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동사`,`미사`,`신구중간사`,`내사`,`타표면이면사겹침`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,3,3,6,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음4월`,3,3,6,`음10월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,3,2,5,`음9월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음10월`,2,1,3,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,3,2,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음3월-연기우려`,2,1,3,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,3,`음6월`,3,1,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월`,3,2,5,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음6월`,2,3,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 초반의 부진을 딛고 중 종반에 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하고 업무도 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 상하 위계를 세우나 종반 실적이 부진함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 여러 일을 일관시키는 결속사를 음12월에 완성하여 동지사는 좋게 되나 재정이 크게 소모되어 중 종반이 허무함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받으나 업무실적이 뚜렷하지 못하고 종반에 이르러 허무하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 온순하고 준수 미모이나 업무가 강하지 못해 전체가 동요분산배신사가 발생하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초반의 수준 이후중 종반이 허무함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 여러 일을 일관시키는 결속사를 이루어 관공직사에 큰 실적을 쌓게 되나 일반사는 관재를 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 초 중반에는 업무에 실패하고 종반에 이르러서야 수준을 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무의 초반 중반이 허무하고 종반에도 내부 동요사를 일으킴 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반에 걸쳐 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 권리를 양도하는 일을 벌이며 본인의 이익에 치중함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선費후費`,`進向`,`원`,`留`,`무난`,`정서`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`이행`,`선이후이`,`退背`,`  근`,`  去`,`주인조력`,`북서`,``,`남남서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선난후이`,`進向`,`  근`,`  留`,`주인가해`,`동북`,``,`서남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`이행`,`선난후費`,`退背`,`원근왕래`,`  去`,`주인가해`,`정남`,`정동`,``],
    [`10.11`,``,`변모제압`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`본인합세`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`주인조력`,`동남`,`북북서`,``],
    [`10.11`,` 多集`,`내부제압`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선攻후난`,`進向`,`  원`,`  留`,`세입가해`,`동북`,`동북`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세없음`,` 이행`,`선攻후費`,`向進`,`  근`,`  留`,`세입가해`,`정동`,`정남`,``],
    [`10.11`,``,`초반제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`이행`,`사안무력`,`進向`,`  근`,`  留`,`주인무력`,`동남`,`동남`,``],
    [`10.11`,`  集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`제압불가`,`이행`,`본인사안`,`退背`,`  근`,`   去`,`주인무력`,`북북서`,`동북`,``],
    [`10.11`,``,``,``,`모두무력`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`이행`,`본인무력`,`退背`,`원근왕래`,`  去`,`세입조력`,`정서`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선이후난`,`退背`,`원근왕래`,`  去`,`세입조력`,`남남서`,``,`동남`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대없음`,`이행`,`권리양도`,`進向`,`  원`,`  留`,`무난`,`서남`,``,`남남동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [``,``,``,``,``,``,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,`신부정귀`,`신不定`,``,``,`육친`,0,``],
    [`질병`,` 수귀`,`익사`,``,``,`묘좌`,`남남동`,`없음`],
    [``,``,``,``,``,`묘향`,`북북서`,``],
    [`생업`,``,``,``,``,`전경`,"제각",``],
    [``,``,``,``,``,`묘속`,  "뱀",``],
    [`가정`,` 수귀`,`익사`,``,``,`육친`,"증조모",``],
    [`질병`,`제례귀`,`제례위법`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,` 수귀`,`익사`,`음물부정`,`금속류入`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`음물부정`,`금속류入`,`육친`,0,``],
    [`질병`,`수귀`,`익사`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`제례귀`,`제례위법`,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,0,``],
    [`질병`,`전사귀`,`전사`,``,``,`묘좌`,0,`특별있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,`여귀`,`가장부인`,``,``,`묘좌`,0,`있음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,``,``,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,`제례부정`,`제례위범`,`육친`,0,``],
    [`질병`,``,``,``,``,`묘좌`,0,`없음`],
    [``,``,``,``,``,`묘향`,0,``],
    [`생업`,``,``,`제례부정`,`제례위법`,`전경`,0,``],
    [``,``,``,``,``,`묘속`,0,``],
    [`가정`,``,``,``,``,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외 급속사-내 기로출행원행사`,`소식불통사-와도 나쁨`,`매사 허성불성-세속허사자**외남 색정사`,`음7,8,10,11월 방문자는 종교진출하면성공함`,`매사 영속성없음**모사미수사`,`종교진출하면자립함`,`내 급속사`,`투서상신사 좋음`,`외사 화합-내사 동요불안이별사`,`입실성취사-남녀관계`,`매사 위역사**외사동요분산사**내사 붕우화합사`,`문서유실사`,``,`장차 위해 불변근신-이동사`,`내녀 색정사`,`발탁영전도약사`,`외사 급속송쟁파재실종사-상해우려`,`송구경황사-통정인이 나타나 두려움`,`외 출행이동사`,`득지행권사-執政기거좋음`,`직녹권리사**외사 손해도망사-가정막힘`,`도적악의-공격말 것`,`존비불화사`,`외사 외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁**병재`,`내사 파재극처사**모두 근심사 뿐`,`전도**빈궁`,`외사 송쟁파재실종상해사우려`,``,`재액해소**전화위복`,`빈궁`,`재액해소`,`관재`,``,`빈궁`,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`관재`,``,``,`빈궁**병재`,``,`빈궁`,``,`관재`,`침체驚懼사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외사 외교막힘`,`존비불화사`,`외 급속사-내 기로출행원행사`,`소식불통사-와도 나쁨`,`매사 영속성없음**외남 색정사**모두 근심사 뿐`,`종교진출하면자립함`,`매사 영속성없음**모사미수사`,`종교진출하면자립함`,`내 급속사`,`투서상신사 좋음`,`외사 화합-내사 동요불안이별사`,`입실성취사-남녀관계`,`매사 위역사**외사 동요분산사**내사 붕우화합사`,`문서유실사`,``,`장차 위해 불변근신-이동사`,`내녀 색정사`,`발탁영전도약사`,`외사 급속송쟁파재실종사-상해우려`,`송구경황사-통정인이 나타나 두려움`,`내 출행이동사`,`득지행권사-執政기거좋음`,`직녹권리사**외사 손해도망사-가정막힘`,`도적악의-공격말 것`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전화위복`,`빈궁**병재`,`내사파재극처사**재액해소`,`빈궁`,`외사송쟁파재실종상해사우려`,``,`존화위복`,`빈궁**관재`,``,``,`빈궁`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`관재`,``,``,`전도**빈궁**병재`,``,`전도**빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`직녹권리사**외사 손해도망사-가정막힘`,`도적악의-공격말 것`,`외사 외교막힘`,`존비불화사`,`외 급속사-내 기로출행이동사**내사파재극처사`,`소식불통사-와도 나쁨`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함`,`매사 영소성없음**모사미수사`,`종교진출하면자립함`,`내 급속사`,`투서상신사 좋음`,`외사 화합-내사 동요불안이별사`,`입실성취사-남녀관계`,`매사 위역사**외사 동요분산사**내사붕우화합사`,`문서유실사`,``,`장차 위해 불변근신-이동사`,`내녀 색정사`,`발탁영전도약사`,`외사 급속송쟁파재실종사-상해우려`,`송구경황사-통정인이 나타나 두려움`,`내 출행이동사`,`득지행권사-執政기거좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,``,``,`재액해소**전화위복`,`빈궁**병재`,`내사 파재극처사**모두 근심사 뿐`,`빈궁`,`외사송쟁파재실종상해사우려`,``,`전화위복`,`빈궁`,``,``,``,`빈궁`,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`관재`,``,``,``,`전도**빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`외 출행이동사`,`상대애정가늠안됨`,`직녹권리사**외사 손해도망사-가정막힘`,`불의진취사-退가 좋음`,`외사 외교막힘**송쟁사계류-퇴피가 좋음`,`매사 위역사`,`외 급속사-내 기로출행원행사`,`특명-필유성취사`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함`,`매사 영속성없음**화소복지사`,`종교진출하면자립함`,`내 급속사`,`자택방문사적청원이 좋음`,`외사 화합-내사 동요불안이별사`,`간음무도사`,`총애경쟁사`,`매사 위역사**외사동요분산사**내사붕우화합사`,``,`허획驚懼사-이동사`,`내녀 색정사`,`군자대경사-북면 개배지봉희사`,`외사 급속송쟁파재실종사-상해우려`,`손괴재물사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**병재`,``,`전도`,``,`빈궁`,``,`재액해소**전화위복`,``,`내사 파재극처사`,`빈궁`,`외사송쟁파재실종상해사우려`,`빈궁`,`전화위복`,``,`관재`,``,`빈궁`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`관재`,``,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`손괴재물사`,`외사 급속송쟁파재실종사-상해우려`,`외 출행이동사`,`상대애정가늠안됨`,`직녹권리사**외사 손해도망사-가정막힘`,`불의진취사-退가 좋음`,`외사 외교막힘**송쟁사계류-퇴피가 좋음`,`매사 위역사`,`외 급속사-내 출행원행사`,`특명-필유성취사`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함`,`매사 영속성없음**화소복지사`,`종교진출하면자립함`,`내 급속사`,`자택방문사적청원이 좋음`,`외사 화합-내사 도요불안이별사`,`간음무도사`,`총애경쟁사`,`매사 위역사**외사 동요분산사**내사붕우화합사`,``,`허획驚懼사-이동사`,`내녀 색정사`,`군자대경사-북면개배지봉희사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**관재**병재`,``,`빈궁**병재`,``,`관재`,`재액해소`,`빈궁`,`전화위복`,``,`내사 파재극처사`,`빈궁`,`외사송쟁파재실종상해사우려`,`빈궁`,`전화위복`,`관재`,``,`빈궁`,`재액해소`,`빈궁`,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`내녀 색정사`,`군자대경사-북면개배지봉희사`,`손괴재물사`,`외사 급속송쟁파재실종사-상해우려`,`외 출행이동사`,`상대애정가늠안됨`,`직녹권리사**외사 손해도망사-가정막힘`,`불의진취사-退가 좋음`,`외사 외교막힘**송쟁사계류-퇴피가 좋음`,`매사 위역사`,`외 급속사-내 기로출행원행사`,`특명-필유성취사`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함`,`매사 영속성없슴**외사송쟁파재실종상해사우려`,`종교진출하면자립함**화소복지사`,`내 급속사`,`자택방문사적청원이 좋음`,`외사 화합-내사 동요불안이별사`,`간음무도사`,`종애경쟁사`,`매시 위역사**외사 동요분산사**내사붕우화합사`,``,`허획驚懼사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요분산사`,`관재`,`동요분산사`,`전도**빈궁**병재`,`동요분산사**재액해소`,`빈궁**병재`,`동요분산사**재액해소`,`관재`,`동요분산사`,`전도**빈궁`,`동요분산사**전화위복`,`관재`,`내사파재극처사**동요분산사`,`빈궁`,`동요분산사`,`전도**빈궁`,`동요분산사**전화위복`,``,`동요분산사`,``,`동요분산사`,`전도**빈궁`,`음4,5월화재주의**동요분산사`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,``,`허획驚懼사-이동사`,`내녀 색정사`,`군자대경사-북면개배지봉희사`,`손괴재물사`,`외사 급속송쟁파재실종사-상해우려`,`외 출행이동사`,`상대애정가늠안됨`,`직녹권리사**외사 손해도망사-가정막힘`,`붕의진취사-退가 좋음`,`외사 외교막힘**송쟁사계류-퇴피가 좋음`,`매사 위역사`,`외 급속사-내 기로출행원행사`,`특명-필유성취사`,`매사 영소겅없음**외남 색정사`,`종교진출하면자립함`,`매사 영속성없음**화소복지사`,`종교진출하면자립함`,`내 급속사`,`자택방문사적청원이 좋음`,`외사 화합-내사 동요불안이별사`,`간음무도사`,`매사 위역사**외사 동요분산사**내사붕우화합사`,`총애경쟁사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`관재`,``,`빈궁**병재`,``,`빈궁**관재**병재`,``,``,`빈궁`,``,`전화위복`,`전도**관재`,`내사 파재극처사`,`전도**빈궁`,`외사송쟁파재실종상해사우려`,`빈궁`,`재액해소**전화위복`,``,`재액해소`,`관재`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,``,`매사 위역사**외사 동요분산사**내사붕우화합사`,``,`허획驚懼사-이동사`,`내녀 색정사`,`군자대경사-북면재배지봉희사`,`손괴재물사`,`외사 급속송쟁파재실종사-상해우려`,`외 출행이동사`,`상대애정가늠안됨`,`진녹권리사**외사 손해도망사-가정막힘`,`불의진취사-退가 좋음`,`외사 외교막힘**송쟁사계류-퇴피가 좋음`,`매사 위역사`,`외 급속사-내 기로출행원행사`,`특명-필유성취사`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함`,`매사 영속성없음**화소복지사`,`종교진출하면자립함`,`내 급속사`,`자택방문사적청원이 좋음`,`외사 화합-내사 동요불안이별사`,`간음무도사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`존도**빈궁**관재`,``,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`관재`,``,`전도**빈궁**병재`,`재액해소`,`빈궁**병재`,`재액해소`,``,`전도**빈궁`,``,`전화위복`,``,`내사 파재극처사`,`빈궁`,`외사송쟁파재실종상해사우려`,`전도**빈궁`,`전화위복`,`관재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재난불측사`,`외사 동요불안-내사 동요불안이별사`,`송쟁사 흉-문서사 길`,`매사 위역사**외사 동요분산사**내사붕우화합사`,``,`모든 일 흉함-이동사`,`내녀 색정사`,`타인견련반목-송쟁사 매우 흉함`,`모망난수사`,`외사 급속송쟁파재실종사-상해우려`,`외 출행이동사`,`상대애정가늠안됨`,`직녹권리사**외사 손해도망사-가정막힘`,`절상인구사-가족중 신체 상해 또는 사망 `,`외사 외교막힘`,`존비불화사`,`외 급속사-내 기로출행원행사`,`도적포획사`,`매사 영속성없음**외남 색정사**妾婦 상호멸시사`,`종교진출하면자립함`,`매사 영속성없음**송구경황사-통정인두려움`,`종교진출하면자립함`,`내 급속사`,`자택방문사적청원이 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`전도**빈궁`,``,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**관재`,``,`전도`,`재액해소`,`빈궁**병재`,`재액해소`,`빈궁**병재`,``,`전도`,`전화위복`,`빈궁`,`내사 파재극처사`,`빈궁`,`외사송쟁파재실종상해사우려`,``,`전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`내 급속사`,`참망이간질사`,`도난사`,`외사 화합-내사 동요불안이별사`,`매사 위역사**외사 동요분산사**내사붕우화합사`,`총애경쟁사-승차 관련`,``,`비곡수욕사-훼절`,`내녀 색정사`,`발탁영전도약사`,`숲에서 뱀을 보고 놀란 격`,`외사 급소공쟁파재실종사-상해우려`,`외 출행이동사`,`괴이사-경버범죄사-고시득길함`,`진녹권리사**외사 손해도망사-가정막힘`,`간음사 이익-정대적은 되려 재앙`,`매사 위역사**외사 외교막힘`,`구연반복사`,`외 급속사-내 기로출행이동사`,`비상지애사`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함`,`매사 영속성없음**손괴재물사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소**전화위복`,``,`재액해소`,`빈궁**관재`,`빈궁`,``,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`관재`,``,`빈궁`,``,`병재`,``,``,`빈궁`,``,`전화위복`,`전도`,`내사 파재극처사**모두 근심사 뿐`,`전도**빈궁`,`외사송쟁파재실종상해사우려`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자**모사미수사`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`내 급속사`,`투서상신사 좋음`,`임실성취사-남녀관계`,`외사 화합-내사 동요불안이별사`,`매사 위역사**외사 동요분산사**내사붕우화합사`,`문서유실사`,``,`장차 위해 불변근신-이동사`,`내녀 색정사`,`발탁영전도약사`,`송구경황사-통정인이 나타나 두려움`,`외사 급속송쟁파재실종사-상해우려`,`외 출행이동사`,`득지행권사-執政기거좋음`,`직녹권리사**외사 손해도망사-가정막힘`,`도적악의-공격말 것`,`외사 외교막힘`,`존비불화사`,`외 급속사-내 기로출행이동사`,`소식불통사-와도 나쁨`,`매사 영속성없음**외남 색정사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`외사송쟁파재실종상해사우려`,``,`전화위복`,`빈궁**관재`,``,``,`재액해소`,`빈궁`,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**빈궁**관재`,`재액해소`,``,`빈궁**병재`,``,`빈궁`,``,`재액해소`,``,`빈궁**병재`,`폐한인**전화위복`,`내사 파재극처사**모두 근심사 뿐`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자**외남 색정사`,`음7,8,10,11월방문자종교진출하면성공함`,`매사 허성불성-세속허사자**모사미수사`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`내 급속사`,`투서상신사 좋음`,`입실성취사-남녀관계`,`외사 화합-내사 동요불안이별사`,`매사 위역사**외사 동요분산사**내사붕우화합사`,`문서유실사`,``,`장차 위해 불변근신-이동사`,`내녀 색정사`,`발탁영전도약사`,`송구경황사-통정인이 나타나 두려움`,`외사 급속송쟁파재실종사-상해우려`,`외 출행이동사`,`득지행권사-執政기거좋음`,`직녹권리사**외사 손해도망사-가정막힘`,`도적악의-공격말 것`,`외사 외교막힘`,`존비불화사`,`외 급속사-내 기로출행원행사`,`소식불통사-와도 나쁨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 파재극처사**모두 근심사 뿐`,`빈궁`,`외사송쟁파재실종상해사우려`,``,`전화위복`,`전도**빈궁`,``,`전도`,``,`빈궁`,`음4,5월화재주의**재액해소`,`빈궁**관재`,`음4,5월화재주의**재액해소`,`관재`,``,``,`빈궁**병재`,``,`빈궁`,``,``,``,`빈궁**병재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 움직여봐야 되는 일은 없고     관재 걱정이 생기겠어!!
  ⊕가택에 상가부정이 들었으니    이것부터 해소해야 관사     망신을 면하겠어!
  ⊕⊖신변신화선영부정도침범      했는데 ?
  `,`■ 모든 일의 결과가 뜻과는    정반대가 되고 있네? 
     1男2女 삼각관계도 있고!
    종업원(여자) 고민사도 있네!
  
  
  ⊕신변상가부정이 침범했어 !
  `,`■ 옛 것으로 되돌아가는 상       인데,돈 갖다 바쳐야 되는      일이 있겠네? 
     그런데 분수를 넘으면 
     재앙이 되겠어!
  ⊕전사귀, 
  ⊖신부정귀를 해소해야 성공    하겠어!
  `,`■ 가정불화가 심하네?
    감추는 비밀사(죄인)도      있겠고!색정사도 있네 !
  ⊕신불제사귀, 
  ⊖신부정귀를 해소해야 망신    을 면하겠어!
  
  ⊕신변상가부정도 침범했네?
  `,`■ 한 뜻, 한 마음, 되는 건
     좋으나 돈이 크게 께지겠어 !  男:돈과 처첩의 손실이 있게     생겼어 !
  ⊕수귀, 
  ⊖도로귀ㆍ가택음사부정 
    발동해서 그래!
  `,`■ 일도 인연도 다 끝났네?     가정에 여고음강 부정이     침범했어!
  
  ⊕신불제사귀, 
  ⊖여귀도 발동하고 있고…     해소해야 일이 잘 풀려
    가겠어!
  `,`■색정불화네!배반당했겠어?
     가정이 풍파야 ! 
     기도ㆍ치성을 드려야 
     풍파가 가라앉겠어 ! 
     이익도 오고…
  
  ⊕⊖신변에 여고음강부정이 침       범했네 ?
  `,`■ 돈과 여자로 의심질투가 있네?     이럴까 저럴까 마음은 심란     한데 골육까지 하극상으로     등돌렸어!
  
  ⊕수귀, 
  ⊖여귀 해소가 급선무야!
  `,`■ 일은 잘 되어도 상복(喪服)     입을 일이 걱정이네 ? 또, 
  ⊕신불제사귀, 
  ⊖신부정귀도 해소해야 일이    열리겠어!
  `,`■ 비밀(죄인은닉)은 많고, 뜻     대로 되는 일은 없고, 
     가정은 불화고…     
     겉도 속도 모두 비었어!
  ⊖여인․부모․자손병재도있겠고!
  ⊕전사귀, 
  ⊖신부정귀 해소가 급해!
  ⊖신변상가부정도 침범했네?
  `,`■ 부모 자손 후원사가 걱정이네!     도움은 없고 속기만 하고… 
  女:⊕남자를 유혹해 색정사를         벌이고 있고!
  ⊕자살귀, 
  ⊖여귀를 해소시켜야 숨은     귀인을 만나 일이 풀리겠어!
  `,`■ 진퇴양난이네? 
     집안에 숨기는 것(죄인)이     있겠어!
     무섭고… 거기다가 
     가정까지 불화이고… 
     삼각관계도 있네 
     1女2男관계야!
  ⊕⊖가택신화선영부정 탓이네!
  ⊖신변상가부정도 침범했어 !`];
  const YOGYEOL1 = [`■ 왕녹이 집에 있으나 백호와 현무가 있어 좋지 않다. 전진하면 살길을 만나나 때 없이 싸우게 된다.
  `,`■ 왕한 녹이 있으나 믿기 어렵다. 현무 백호가 타서 자리를 바꾸겠다. 신경 쓸 일이 많이 생기는데 아래여자 종업원이다.
  `,`■ 분수 넘는 재물은 취하지 말라. 가졌다하면 재앙이 온다. 
  돈을 바치고 귀인에게 부탁하면 이런 일은 다 좋겠다.
  `,`■ 밤정단은 인수에 범이 있고 다시 관살을 둘이나 만나니 성공을 바라는 자는 조금의 도움이 없겠다.
  `,`■ 저나 나나 모두 다치고 동업이나 교섭사는 더욱 나쁘다. 
  정귀와 파쇄살이 겸하니 가택이사나 괴이사가 생긴다. 
  `,`■ 정마와 천장이 있으니 움직일 뜻이 보통이 아니다. 그러나 관귀와 패살 뿐이니 관직자나 일반인 모두 재앙이 두렵다.
  `,`■ 위아래가 육해이나 서로 다시 육합이 된다.
  집안에 간사한 일이 벌어지겠는데 현무와 융합이 있기 때문이다. 
  `,`■ 앞에 재물이 가득하나 취했다하면 화의 단서가 된다. 몸은 편안히 지내기 어렵고 여러사람의 공격이나 구설이 있겠다.
  `,`■ 삼전은 관살이고 천장은 인수여서 관직자는 크게 좋겠다. 일반인도 근심이 잘 풀려가겠고 귀인에게 뇌물주면 성공한다.
  `,`■ 사과와 초전이 모두 공망되어 허망하다.
  처나 재물 문제는 안좋고 합이 보여도 허망하다. 
  `,`■ 먼저는 죽었다가 나중에 살아난다. 말전 관살에 정신까지 겸했다. 
  낮정단은 실물이나 실패가 있겠고 흉동이 멈춰지지 않겠다.
  `,`■ 손해와 공망과 묘가 많아 길흉사가 모두 생기지 않겠다. 높은 자가 낮은 자에게 가서 좋은 녹을 만나게 되리라. `]
  const SIBO1 = [`■ 유약심으로 신음ㆍ고로
  한다.
  형사사의 불씨가 기다린다.
  피차 서로 의혹을 품고 있다. 
  조용히 구태를 지키고 망동하지 않아야 한다.
  ⊕가정에 병자가 있다.
  `,`■ 어떤 일도 불비ㆍ부족의
  형편에 있다.
   부모의 흉해가 있다.
  남자는 처를 버리고 여자 종업원과 색정사를 갖고 있다. 
  여자는 1남 2녀 삼각관계를 갖고 있다. 
  일은 생각과는 반대의 결과가 초래된다. 타인에게 의지하는 상이다.
  `,`■ 폐지사를 복구하려 한다.
  윗사람의 財를 얻게 된다.
  무슨 일이고간에 장애를 거친 후 성사된다.
  구재ㆍ모망사 모두 길조가 있다. 
  직장인은 일찍 퇴직하는 것이 좋다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  어떤 일도 공론ㆍ공화이고 허작ㆍ부실한 형편에 있다. 
  색정건이 있고 가정불화가 심하다. 
  四月 임신사가 있다.
  속는 일이 생기고 자손 흉해가 기한다.
  `,`■ 자충수적 우환이 있다.
  가장을 속이고 가인이
  원한을 품고 가해하려
  한다. 
  개신ㆍ개혁으로 부부이별사가 기한다.
  사업자는 부도나게 된다. 시작은 있고 끝이 없다. 
  丑月에 발현 한다.
  `,`■ 자충수적 우환이 있다.
  구사는 끝나고 신사는
  부흥한다.
  동요ㆍ변동이 있다.
  토지 다툼이나 번민사가 기한다. 그러나 큰 해는 없다.
  관공직자는 복이 된다.
  자손․부모의凶害가 발생한다.
  `,`■ 가정에 간사사가 있다.
  부부이별한다.
  후에 다시 복연된다.
  재혼은 大吉하다.
  가정불화가 있고 감추는 비밀이 있고 연정사가 연루되어 있다.
  관공직자는 승진한다.
  四月 임신사가 있다.
  `,`■ 가정 내부ㆍ여자로 인해
  기로에 섰다.
  의심ㆍ질투가 있다.
  타인의 멸시를 받는다.
  하나로 전일시켜 과감하면 금전의 복이 있게 된다. 먼 곳에서 금전의 기부ㆍ증정을 받는다.
  속는 일, 자손 재해가 기한다.
  四月 임신사가 있다.
  `,`■ 가정이 어둡다.
  ⊕음사부정 ⊖상가부정
  들고 후원도 없다.
  상복건이 겹쳐 일어날 조짐이 있다.
  가정에 병자가 있다.
  부정해소로 만사 길조로 변화한다. 
  부모 재해가 있다.
  `,`■ 가정 불화가 있다.
  감추는 비밀이 있다.
  연정사에 연루되고 
  女는 별리ㆍ고독인이다.
  財의 다툼이 있다. 
  길흉은 경미하고 상대를 공격해도 적중이 안된다. 
  가정에 病者가 있고 대개 여성이다.
  四月 임신사가 있다.
  `,`■ 후원이 없고 속고 있다.
  모두 허사허탈된다.
  냉정한 대처가 필요하다.
  女는 별리ㆍ고독인이다.
  ⊕女가 남의 남자를 적극 유혹하여 따르고, 가출하는 상이다.
  四月 임신사가 있다.
  `,`■ 가정에 부정이 들었다.
  감추는 비밀이 있고
  가정이 불화한다.
  대개 연정 색정사에 연루된다.
  1녀 2남의 삼각관계가 있다. 
  심신이 부유되어 안정하지 못한다. 
  부모 자손 凶害가 기한다.
  개변하면 吉하다.`]
  const SIBO2_1 = [`■ 직녹,가택내사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■ 후원ㆍ사업내외반목사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액 예방이 필요하다
  `,`■官･夫,신변외사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다.
  질병이 침범한다.
  `,`■官･夫,가정폐퇴사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.매사 반복부정하다
  `,`■官･夫,가택개신사이다.
  내방조짐 상의 : 
  도망은 멀리 갔고, 비밀사통하고 재·녀 상실 예방이 필요하다.
  `,`■ 자손ㆍ박관신변외사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  은근하고 伏하라.
  질병 관재를 주의하라
  `,`■ 財ㆍ女,가정동요사이다.
  내방조짐 상의 : 
  색정불화․이심원한의 상이다.상호저해․구해가 심하다
  `,`■ 사업ㆍ후원신변반목사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  모략․중상․비난․탄압의 상이다
  `,`■ 財ㆍ女,외사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  병재·관재 에방이 필요함
  `,`■財ㆍ女,내외동요사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■ 사업ㆍ후원남녀화합사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액 예방이 필요하다
  `,`■ 자손ㆍ박관외사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.
  은근하고 伏하라.
  허위 사가사를 주의하라`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/058/row-1-column-1.png`,
    `/static/images/sagwa/058/row-1-column-2.png`,
    `/static/images/sagwa/058/row-1-column-3.png`,
    `/static/images/sagwa/058/row-1-column-4.png`,
    `/static/images/sagwa/058/row-1-column-5.png`,
    `/static/images/sagwa/058/row-1-column-6.png`,
    `/static/images/sagwa/058/row-1-column-7.png`,
    `/static/images/sagwa/058/row-1-column-8.png`,
    `/static/images/sagwa/058/row-1-column-9.png`,
    `/static/images/sagwa/058/row-1-column-10.png`,
    `/static/images/sagwa/058/row-1-column-11.png`,
    `/static/images/sagwa/058/row-1-column-12.png`,
  ]
  const HEASYEOL = [
    // 1
    `  이 과는 천지신이 복(伏)하여 움직이지 않으니 복음자신과(伏吟自信課)이다. 이 과는 상하에 극이 없기 때문에 음일은 지상신(支上神)인 유(酉)를 초전으로 하는데, 만약 극이 있으면 극을 취하여 초전으로 한다. 극이 없으면 음일은 일지상신. 양일은 일간상신을 사용하여 초전으로 한다.
    천지반은 마치 은복(隱伏)한 듯 아직도 발(發)하지 않는 상이므로 조용한 것이 좋고, 음직일 때는 정체한다. 무슨 일이나 장닉(藏匿)하여 움직이지 않으니 조용한 가운데 노(勞)를 구하며 구부리고 펴지 않는 상태이다. 
    실물(失物)은 가까운 데 있으나 방문하여도 상대방은 나오지 않는다. 병은 신음을 할 뿐 말을 하지 못하며, 대인은 양일에는 즉시에 도착하지만 음일이면 미정으로 늦어진다.
    초전은 건록이 되고, 현무․백호의 흉장이 타서 술유(戌酉)는 교차육해로 되어 피차 서로 의혹을 품는다. 조용히 하고 망동하지 말아야 한다.`
    ,
    // 2
    `  이 과는 불비격으로 3과밖에 없으며, 4과 모두 상하에 극이 없으니 다른 일신을 취하여 초전으로 하기 때문에 별책과(別責課)라 한다. 음일은 정(靜)으로 스스로를 지키기 때문에 일지의 삼합을 이루기 전의 일지를 초전으로 한다. 중전․말전은 일간상신의 유(酉)를 취하게 된다.
    이 과는 3과로서 불비격이니 무슨 일이나 불비 결함이 있어 오래 끌고, 일을 도모해도 재와 물이 완전하지 못하다. 대안(對岸)을 건너려 하여도 배가 없어 배를 찾는 것과 같고, 이 동람에게 구혼한 후에 다른 사람에게 장가를 드는 것과 같다. 임신사는 지연된다. 일은 처음에는 손해를 보고 후에는 이익을 보는 상이며, 길흉은 천장에 따라서 정단한다.
    별책불비격은 2음1양이니 2녀가 1남과 싸운다. 2양1음은 2남1녀가 다투는데, 남자가 정처(正妻)를 버리고 다른 여자를 구하여 부부 생활을 하기 때문에 물사유연(物事留連)을 취급한다. 통상 앞으로 나아가려고 하나 전진할 수 없고 잉태는 지연된다. 무슨 일이나 남에게 의존하고 타인의 힘을 빌어서 행하며 길흉은 다른 사람과 많이 관계된다. 가정내는 색정음란(色情淫亂)의 징조가 있고 유술(酉戌)은 남녀사용인의 성(星)에 해당한다. 사과삼전에 세 곳에 겹치기 때문에 사용인에 관한 번민이 있고 일지는 간상에 복귀하며 무슨 일이나 구태를 중시하고 신규(新規)를 폐하며 완결을 희망하는 것이다. 일간일지에서 보아 초전 묘신은 내가 남에게 폐를 끼친다. 일귀는 역행하고 삼전은 후․백․백이고, 야귀는 순포하며 삼전은 청․현․현이다. 이 과는 삼전야간의 지에 해당하고 공명의 정단은 좋지 않다.`
    ,
    // 3
    `  이 과는 제2과의 오신(午申)에서 1상이 1하를 극하고 오신(午申)을 초전으로 하는 원수과이다. 초전은 화귀(火鬼)가 일간을 극하지만, 중전의 진(辰)에 생화되어 재난을 이루지 않는다. 말전은 인(寅)의 재이고, 일귀(日貴)는 구진(勾陳)의 싸움의 재이며, 야귀는 천을재(天乙財)로 되니 손위 존장의 재(財)를 얻는다.
    삼전은 역간전격(逆間傳格)으로 고조격(顧祖格)이라 부른다. 예를 들면 오(午)는 인(寅)에서 보아 자손이 되고, 인(寅)은 오화(午火)에서 보아 장생이 되며, 오(午)에서 인(寅)에 전하고 자(子)는 모(母)를 돌아본다. 복구(復舊)의 상이기 때문에 이런 이름으로 부른다. 
    구재․모사․망사는 모두 길조이고, 적(賊)은 떠나가고 대인은 온다. 점병(占病)은 흉이고 관사는 대길이다. 무슨 일이나 장애가 있은 후에 성사되고 도리가 통한다. 
    삼전 중에 오(午)와 인(寅)이 있어 술(戌)의 일신(一神)을 기다린다. 이와 같은 것을 허일대용(虛一待用)이라 하는데, 술(戌)의 연월일(年月日)에 효험이 있다.
    일귀는 역포하고 삼전은 구․주․귀이며 야귀는 순포하고 삼전은 귀․주․구이다. 귀신이 진(辰)에 엄하면 옥(獄)을 밟는다고 하여 일찍 퇴임 퇴직하는 것이 좋다.`
    ,
    // 4
    `  이 과는 제3과 오유(午酉)에서 1상이 1하를 극하여 원수과로 된다. 삼교(三交)․고개(高蓋)․여덕격(勵德格)이다. 초전 오(午)는 일간상신 미(未)와 지합하고, 제2과 진(辰)과 묘(卯)는 육해, 말전 자(子)는 제3과 오(午)와 칠충(七冲)하고 제4과와는 자묘(子卯)로 지형(支刑)이 된다. 합은 화합이고 해는 방해하며 충은 분산이고 형(刑)은 다툰다. 이리하여 4과는 체(體)로 되고, 초이며 삼전은 용으로 하여 길흉을 정한다.
    말전의 자(子)는 순(旬)의 공망이고 야귀는 천공이 승하여 공론으로 되니 공중누각․탈상탈이 되고 허작부실(虛作不實)․소모손실로 본다. 일간은 일지상신 및 초전에서 극을 받아 고심이 있지만 말전의 자수(子水)에서 제한다. 그러나 공망이 되니 힘이 적어 제력(制力)이 부족하다. 
    양에 합하고 음에는 해로 되니 화합하는 가운데 의견 충돌이 있고, 오(午)에 천을귀신이 타서 유(酉)에 임하면 부모와 존장에게 불안이 있으며, 오(午)의 상에 묘(卯)가 가담하여 육합이 승하면 색정의 건이 생긴다. 일귀는 역포하고 삼전은 상․후․주이며, 야귀는 삼전이 귀․합․공이다.`
    ,
    // 5
    `  이 과는 2상이 2하를 극하여 음일음비(陰日陰比)라 하며, 사유(巳酉)의 극을 취하여 초전으로 하는 지일과이다. 삼전 사축유(巳丑酉) 삼합금국은 종혁격(從革格)이다. 
    일간은 일간상신․지상신․초전으로부터 극을 받고 정신 사(巳)의 둔귀(遁鬼)까지 있어 인택은 모두 재해를 받는다. 
    중전 축(丑)의 묘신은 공망에 들어가니 재복을 정단하여도 순조롭지 않다. 밤에 정단하면 염막귀신(簾幕貴神)이 일간에 임하기 때문에 시험은 반드시 통과한다. 
    구직은 신속하게 취직할 수 있다. 겁재가 겹쳐 재를 빼앗으므로 사업의 조락실패(凋落失敗)는 면할 수 없다. 
    일귀는 역행하며 삼전은 현․사․청이고, 야귀는 삼전이 동․청․현이다. 중․말전이 공망하여 정단 사건은 시작은 있어도 끝이 없는 상이다.`
    ,
    // 6
    `  이 과는 상하의 극이 세 곳에 있고, 하에서 상을 극하는 해진(亥辰)을 초전으로 하는 중심과이다. 일간상신 사(巳)는 순정(旬丁)이 타고 초전의 해(亥)는 역마로 되며, 일지의 위에 진(辰)의 천강이 승하여 동요 변동을 암시한다. 중전의 관귀효는 자해(子亥)의 수(水)로 제(制)하여 흉을 보아도 실해(實害)는 해소된다.
    사절격(四絶格)에 역마가 초전이 되니 구사(舊事)는 끝이 나고 신사는 부흥한다. 말전에 오(午)의 칠살이 축(丑)의 묘신에 가담하여 토지의 다툼, 혹은 고정(苦情)의 건이 발생한다. 
    일간상신․정신․관귀는 일간을 극하여 흉화의 징조가 있으나. 사(巳)는 신일(辛日)의 덕신(德神)에 해당하고 흉을 잘 화하므로 길로 되어 관공직에 있는 사람은 도리어 복을 받는다.
    일귀는 역행하며 삼전은 합․상․사이고, 야귀는 순포하고 삼전은 청․귀․백이며, 말전은 사․호의 흉장이 공망하여 큰 해는 없다. 이 과는 사(巳)가 자(子)에 가담하여 사자(死字)로 되기 때문에 병단을 꺼린다.`
    ,
    // 7
    `  이 과는 천지상하가 충위(冲位)에 있고 반음과로 된다. 음일에는 일지상신을 사용해서 초전으로 하고 묘유묘(卯酉卯)는 용전격(龍戰格)이며, 초전 묘(卯)와 유(酉)의 상극은 단륜격(斷輪格)이다. 묘(卯)는 차륜(車輪)이고 신(辛)은 연장으로 목기(木氣)를 끊기 때문에 단륜(斷輪)이라 명명한다.
    관공직에 있는 사람은 승진이 있고, 낡은 것을 고치고 새것을 보는 격이다. 원래 목(木)은 기구 기물을 만드는 것이기에 금(金)의 단(斷)을 만나면 기재(器才)를 이룰 수 있다. 묘(卯)에는 을(乙)의 장간(藏干)이 있고 신유(申酉) 중에 경금(庚金)이 있어 간합하여 귀기(貴器)를 이룬다. 묘(卯)는 이 과가 성립하여도 늦어지니 후에 이루게 된다. 천장이 상․음․합을 얻으면 구재는 크게 획득한다. 
    목기가 휴수(休囚)하거나 백호가 승하면 관통(棺桶)이라고 본다. 공망이 되면 후목(朽木)은 조각(彫刻)하기 어렵다. 4과 상하는 진술묘유(辰戌卯酉)로 충이 되고 진유묘술(辰酉卯戌)은 교차지합한다. 또 진묘(辰卯)와 술유(戌酉)는 육해로 되어 파란 동요한다. 
    가정 내에 간사와 상해가 있고 해사(害事)는 해소된다. 부부는 서로 이별하고 후에 복연(復緣)이 된다. 이것은 처재가 충해지합하기 때문이다. 결혼은 불가능하지만 재혼은 성립된다. 이별 문제는 성립될 수 있고 후에 재혼이 성사된다. 교차지합하면 초혼은 좋지 않지만, 재혼은 대길(大吉)로 본다.
    일귀는 역행하고 삼전은 후․청․후이다. 야귀는 순포하고 삼전은 현․합․현이다. 사중(四仲)의 점시(占時)에 사중일(四仲日)이면 묘유묘(卯酉卯)는 삼교격(三交格)이 되고 다툼질이 있으며 형해가 있고 연루(連累)되는 일이 있다. 길장이 있으면 구신이 되어 흉은 완화된다.`
    ,
    // 8
    `  이 과는 4과의 상하에 극이 있고 하에서 상을 극하는 미인(未寅)을 초전으로 하는 지일과이다. 금일의 음양에 의하여 음비(陰比)의 극인 미인(未寅)을 초전으로 한다. 극이 많으니 물사는 여러 갈래에 미치지만 하나를 취하여 지일과로 한다. 정단하면 무슨 일이나 가까이에 있고 승패는 주모자가 이긴다. 재화는 밖을 경계해야 한다.
    4과 중 3하가 상을 극하여 존장은 재앙을 면할 수 없다. 삼전의 초전은 중전을 극하며, 중전은 말전을 극하고, 말전은 일간을 극한다. 이런 것을 체극(遞剋)이라 하여 재원(財源)이 끊이지 않고 차재(借財)하였으며 반제(返濟)하는 상이다. 체극이 되면 일반인이 얕본다.
   말전에 순정덕신(旬丁德神)이 있고 원방에서 재(財)의 기부(寄附)가 있거나 혹은 증정을 받는다.
   일귀는 역포하여 삼전은 백․주․현이고, 야귀는 순행하고 삼전은 사․공․후이다. 초전에 사(蛇)․호(虎)가 승하면 놀라운 사건이 발생한다.`
    ,
    // 9
    `  이 과는 제1과 인신(寅辛)에서 1하가 1상을 극하여 중심과로 되며, 삼전은 인오술(寅午戌) 염상격(炎上格)이다. 
    인(寅)은 봄, 오(午)는 여름, 술(戌)은 가을이어서 순합(順合)이라 한다. 이리하여 초․중․말전은 일간에 체생이 된다. 
    삼전은 재(財)․관(官)․인(印)의 삼귀(三貴)로 되고, 초전의 인(寅)은 순주(旬主)에 해당하여 육의(六儀)라고 한다. 또 삼전의 천장이 모두 토신으로 일간을 생하며, 사오(巳午)의 화귀(火鬼)는 토(土)의 인수(印綬)로 생화하여 흉이 없이 길로 화한다.
    이상과 같이 만사는 모두 길조의 과격이다. 다만 택상 축(丑)의 묘신에 백호가 승하면 묘문개격(墓門開格)이라 하여 상복의 건이 겹치니 길중흉(吉中凶)이다. 인수에 화하는 것은 천장의 길신에 의뢰하여 구신으로 되니 백재(百災)를 해소한다.
    일귀는 역포하며 삼전은 귀․상․구이고, 야귀는 순포하며 삼전은 상․귀․구로 된다. 육의가 승하면 흉은 변하여 길조로 된다.`
    ,
    // 10
    `  이 과는 4과 중 상하의 극이 없기 때문에 사(斜)의 극인 신묘(辛卯)의 극을 취하여 초전으로 하고 요극과(遙剋課)이다. 4과와 초전이 공망이 되니 표리는 모두 공허하고 힘이 없다. 
    묘(卯)의 처재(妻財)는 공지에 앉으니 신(辛)의 기궁(寄宮) 술(戌)과 합한다 하더라도 정(情)이 없다.    묘(卯)와 유(酉)가 충하여 재의 다툼이 있으나 탄사격(彈射格)은 길흉이 모두 경하다. 
    일간상신 축(丑)이 묘호(墓虎)가 되니 가인에게 병액(病厄)이 있는데, 대개는 여성의 병이다. 초전 공망은 고진이라 하여 결혼의 정단은 흉이라 본다. 
    일귀는 순행하고 삼전은 사․구․백이며, 야귀는 순포하고 삼전은 현․귀․합이다.`
    ,
    // 11
    `  이 과는 축해(丑亥)에서 상이 하를 극하여 원수과이지만 초․중전이 모두 공망이 되고 말전은 관귀라 하여도 4과에서 제복하여 해를 받지 않는다.
    일간일지는 하에서 상을 생하여 실탈이 된다. 사과삼전이 모두 공망이 많으며 허탈(虛脫)이 있고, 말전은 관귀가 되어 취직의 건은 후에 이루어진다. 이 과는 가능한 냉정한 방침이 좋다. 말전에서 초전을 생하므로 일간을 생하면 몰래 타인의 원조를 받는다.
     일귀는 순행하고 삼전은 후․사․합이며, 야귀는 삼전이 백․현․후이다. 재신이 공망되어 현무가 승하면 실탈을 면할 수 없다. 공관(公官)은 정신이 승하여 멀리 부임하고, 년명상의 충극은 좋지 않다.`
    ,
    // 12
    `  이 과는 4과 중 해술(亥戌)이 하에서 상을 극하고 중심과로 되며, 해자축(亥子丑)으로 삼전진여격(三傳進茹格)이다. 4과이지만 3과밖에 없기 때문에 불비격이다. 
    일간에서 해자해(亥子亥)는 설기로 되고 자축(子丑)이 공망하니 무슨 일이나 공탈이 되어 길흉이 모두 없다. 일간은 존장이고 지(支)는 부하이다.
    술(戌)의 기궁은 지상(支相)에 가담하여 토생금(土生金)으로 하를 생하니 상대방에게 유익하다. 삼전진여격이고 연주삼기(聯珠三奇)로 되니 흉을 구하여 길로 변화시킨다.
    일간상신 해(亥)는 피난도생(避難逃生)이라 칭하여 물(物)을 개변하면 크게 길로 된다.
    순미(旬尾)의 해(亥)가 초전이 되면 폐구격이다. 
    일귀는 순행하며 삼전은 현․음․후이고, 야귀는 역행하여 삼전은 백․공․청이다.`
    ,
  ]


  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
