
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,12,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,9,11,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,10,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,11,9,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,12,8,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,1,7,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`신뢰`,2,6,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,3,5,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`설득`,4,4,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,5,3,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,6,2,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,1,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`대립`,`금전사업이권처첩 관련사-가정 부부쟁투사 有`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`6`,`처재무산`,`부부대립`],
    [`매사 영속성없음`,`금전사업이권처첩 관련사는선천작 기복재해를 겪음`,`大`,`존재기`,`위례득죄`,`訟흉文길`],
    [`영번가악(榮繁可惡)`,`영화롭고 번창한데 측근에 연루되어 화환이 있게 되는 상`,`大`,``,``,``],
    [`가실불화(家室不和)`,`가정 부부불화의 상`,`小`,``,``,``],
    [`자매`,`형제친구동료금전손실여인손실 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`5`,`개신결여`,`손해가출`],
    [`매사 영속성없음`,`형제친구동료금전손실여인사는 선천적 기복재해를 겪음`,`小`,`배양기`,`부부불화`,`위례득죄`],
    [`귀위불해(鬼爲不害)`,`나쁜 것이 제압되어 해가 없게 되는 상`,`小`,``,``,``],
    [`영전도약(榮遷跳躍)`,`고위 측근으로 영전하여 도약하는 상-부족-음12월 可`,`大`,``,``,``],
    [`송쟁`,`외사 급속송쟁파재실종사-상해우려`,`大`,``,``,``],
    [`남편`,`남편 관련사`,`小`,`4`,`급속송쟁`,`가정단합`],
    [`급속`,`외사 급속 강제손실사 有`,`小`,`개시기`,`입조입각`,`외화내허`],
    [`숭리성수(崇俚成愁)`,`우러러 모시지만 이루어지는 것은 없고 수심뿐인 상`,`大`,``,``,`부부불화`],
    [`입조입각(入朝入閣)`,`고위에 입조 입각하여 대중을 위해 이익경영을 하게 된 상 `,`小`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`색정`,`내녀 색정사 有`,`小`,`3`,`타인受惠`,`색정사有`],
    [`결속`,`결속사로 후원사를 크게 이루나 자식災害 발생함`,`小`,`학습기`,`투서헌책`,`파재극처`],
    [`부모재구(父母在咎)`,`부모의 재앙이 걱정인 상`,`大`,``,`귀인접인`,`우유한가`],
    [`자불검약(自不檢約)`,`약속한 비밀을 누설하여 죄에 이르는 상`,`小`,``,`(음12월可0`,`(실업한가)`],
    [`형제`,`형제 관련사`,`小`,``,``,`타인施惠`],
    [`친구`,`친구 관련사`,`大`,`2`,`타인시혜`,`침체공허`],
    [`동료`,`동료 관련사`,`小`,`면모기`,`비용과출`,`투서헌책`],
    [`우불가동(尤不可動)`,`적극 움지이는 것은 매우 부당 하고 불가하다-는 상`,`小`,``,`엄목지상`,`고위접인`],
    [`엄목지상(掩目之象)`,`눈이 가려진 격의 상`,`大`,``,``,`(음12월可)`],
    [`명예`,`수상(受賞)사`,`大`,``,``,``],
    [`직녹`,`직녹관련사`,`大`,`1`,`외교막힘`,`비용과출`],
    [`막힘`,`외사 외교 막힘사 `,`小`,`절정기`,`불의투서`,`면모훼손`],
    [`방화화재(防火燭災)`,`화재를 방비하여야 하는 상-촛불 조심`,`小`,``,`급용문서`,`엄목지상`],
    [`앙화소멸(殃禍消滅)`,`두려운 재난이 모두 물러갔는가-의 상`,`小`,``,`모두 불용`,`허사불성`],
    [`후원`,` 후원조력 관련사`,`大`,``,``,``],
    [`색정`,`외남 색정사 有`,`大`,`12`,`색정사有`,`가정막힘`],
    [`막힘`,`내부 가정 막힘사`,`小`,`과시기`,`비용과출`,`투서헌책`],
    [`동모불가(動謀不可)`,`소극적 일보다 식비가 배로 드니 적극 나서지 말라-는 상`,`小`,``,`불검초죄`,`급용문서`],
    [`도적소실(盜賊消失)`,`도적 근심을 안 해도 될까-하는 상`,`小`,``,`(비밀누설)`,`모두 불용`],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`화합`,`내부가정 상하화합사`,`大`,`11`,`침체공허`,`상하화합`],
    [`결속`,`결`,`小`,`쇠퇴기`,`관재형책`,`문서망실`],
    [`관길상흉(官吉常凶)`,`관직자는 좋으나 일반인은 나쁜 상`,`小`,``,``,``],
    [`우유한가(優遊閑暇)`,`실업자 격으로 한가한 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`직위불안`,`출행이동`],
    [`출행`,`내부가정 출행이동사`,`大`,`침해기`,`면모훼손`,`관재형책`],
    [`후생점창(後生漸昌)`,`나중에 점점 번창하게 되는 상`,`小`,``,`충산이산`,``],
    [`관재형책(官災刑責)`,`관재 형책을 받게 되는 상`,`大`,``,``,``],
    [`박관`,` 관련사`,`小`,``,``,``],
    [`극남`,` 관련사`,`小`,`9`,`개신변모`,`재난손실`],
    [`개신`,`외사 개신사`,`大`,`침체기`,`고위연회`,`우유한가`],
    [`묘이불수(苗而不秀)`,`싹수를 보건대 주력사가 버젓하게 자랄 수 없는 상`,`小`,``,`초대열석`,`실업자격`],
    [`수용자태(修容姿態)`,`용모에 신경쓸 필요가 없는 상-실업자 격`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`침체`,`내외 침체 소외 관련사`,`大`,`8`,`침체소외`,`가정단합`],
    [`화합`,`내부 가정 단합사-외화내허적`,`小`,`소외기`,`불변근신`,`외화내허`],
    [`동모수신(動謀須愼)`,`일을 움직이건대 반드시 신중에 신중을 기해야만 하는 상`,`小`,``,`(일시휴직)`,`고위연회`],
    [`고위안좌(高位安坐)`,`고위에 입각하여 대중의 이익을 강구하는 상-음12월 가능`,`大`,``,``,`초대열석`],
    [`금전`,`금전사업이권 처 관련사`,`小`,``,``,``],
    [`출행`,`외사 출행원행이동 기로사-동요불안분산사`,`小`,`7`,`기로출행`,`급속사有`],
    [`급속`,` 내사 급속사`,`大`,`단절기`,`송흉문길`,`불변근신`],
    [`난생당외(難生當畏)`,`활발하기 어려운데 두려운 일만 당도하는 상`,`大`,``,``,`이 좋음`],
    [`송훙문길(訟凶文吉)`,`구설과 송쟁사는 흉하고 문서 소식사는 깋한 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,``],
    [`단계`,`단계`,0,`가능`,`불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明시작`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明종료`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`관사송쟁강제급속손실사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`관사내녀색정사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明하향`,``,0,`형제친수동료조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗절정`,0,`명예자산직녹권리사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗시작`,0,`후원문서색정조력으로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗종료`,0,`형제조력출비가정화합사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,``,`직업직위불안남편갈등사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明진행`,``,0,``,`직업직위불안남자갈등사로불가`],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,`暗진행`,0,`형제친구동료가정화합사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [`明절정`,``,0,`금전사업처첩조력사로가능`,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``],
    [``,``,0,``,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결해야만 결과가 유리함 `,0,0,`명분이 있고 정당한 사안이므로 서둘러 속결해야만 하고 좌고우면하거나 뒤로 밀면 되려 불리해지는 人`,0,0,`일이 거꾸로 진행되나 계속하게 됨**1남2녀 삼각관계 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 자세로 사안을 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 대항하지 말아야 하고 유순한 처신으로 결정을 보류해야만 결과가 반드시 유리해지는 人`,0,0,`일이 거꾸로 띄엄띄엄 진행되나 계속하게 됨**결과없이 반복되는 일이 역시 어두워짐**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결해야만 결과가 유리함 `,0,0,`명분이 있고 정당한 사안이므로 서둘러 속결해야만 하고 좌고우면하거나 뒤로 밀면 되려 불리해지는 人`,0,0,`신규사가 있음**기이한 전화위복사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 자세로 사안을 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 대항하지 말아야 하고 유순한 처신으로 결정을 보류해야만 결과가 반드시 유리해지는 人`,0,0,`결속단합하여 후원사를 크게 세움-단,외화내허적-또 일시적 우려-자식걱정이 발생함**남자가 타인의 부인을 유혹하여 자기 여자로 사용함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 자세로 사안을 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 대항하지 말아야 하고 유순한 처신으로 결정을 보류해야만 결과가 반드시 유리해지는 人`,0,0,`정대한 일들이 끓어짐**가정이 불화함**결속사는 나브고 해산사는 좋음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 화가 밖에서 오니 신중해야 함`,0,0,`동요불안 반복무상 연루건이 있는 人`,0,0,`풍운불측의 상**사간(私奸)사가 있고 은닉사가 있음**기로사로 원행이동사 有**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`동료와의 협력 또는 동업사로 의혹을 받아 선택의 기로에 서있고 가장 친하고 가장 가까히 거주하는 자와 의논하면 해결되는 人`,0,0,`육친 윗인이 육친 아래인을 핍박함**산속으로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결해야만 결과가 유리함 `,0,0,`명분이 있고 정당한 사안이므로 서둘러 속결해야만 하고 좌고우면하거나 뒤로 밀면 되려 불리해지는 人`,0,0,`결속단합하여 후원사를 크게 세움-단,외화내허적-또 일시적 우려-자식걱정이 발생함**여자가 남자를 적극 유혹하여 함께 도망할 각오까지 하고 있음**결과없는 반복사가 역시 어두워짐**기이한 전화위복사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 자세로 사안을 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 대항하지 말아야 하고 유순한 처신으로 결정을 보류해야만 결과가 반드시 유리해지는 人`,0,0,`풍운불측의 상**연루건-간사은닉사가 있음**관재발생 우려`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응유화적 자세로 사안을 지체시키거나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 대항하지 말아야 하고 유순한 처신으로 결정을 보류해야만 결과가 반드시 유리해지는 人`,0,0,`일이 순조로운 듯하나 퇴함이 좋음**서리내린 다리를 걷는 격`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`위배지체연기변경부부동시색정**만약 타인과 다툰다면 본인의사와 관계없이 위배변경되므로 별도 대책을 세워야 함`,0,0,`한 그물에 걸린 물고기 격으로 견련사가 있고 계획과 방침이 연기 변경되는 人`,0,0,`부부 똑같이 색정사가 있음**별도 대책을 강구해야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 공격할 뜻은 있으나 움직이지는 않음`,0,0,`스스로 자임하고 옛것을 지키며 새 기회를 기다리는 人`,0,0,`신규사가 있음**송쟁사가 있음**(고시준비자에게 많음)**이전이동사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-출비됨`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-침체인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`좌고우면-무력`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`기사`,`신사`,`돌발사`,`모호사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사후회사겹침`,`기사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월`,3,2,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,1,1,2,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음8월`,2,1,3,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음7월`,1,1,2,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음6월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월`,3,2,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음4월`,1,1,2,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,3,2,5,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음8월`,3,2,5,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음7월`,2,3,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯해도 조정능력이 있으나 업무는 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고업무도 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 업무도 신규사로 수준을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협력을 받고 결속단합하여 후원사를 크게 이룸-단 외화내허적이고 장구적이지는 못함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무 전반에 걸쳐 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무전반에 걸쳐 동요불안배신사를 일으키고 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 있어 초반 외 대부분의 실적이 저조함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 온순하고 준수 미모이며 업무가 강하지 못해도 결속단합을 이루어 후원사를 크게 세움-단 외화내허적-장구성이 없음`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 듯해도 조정능력이 있으나 업무는 수준이하 실패가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 온순화고 준수 미모이나 업무가 강하지 못해 실적이 수준에 못 미침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하고 초반의 부진을 극복하고 상하화합을 이루며 실적을 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`多集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`권리양도`,`退背`,`근`,`去`,`무난`,`정남`,``,`정남`],
    [`10.11`,` 集`,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선이후난`,`退背`,`  근`,`  去`,`세입조력`,`남남서`,``,`남남서`],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`선난후費`,`退背`,`  원`,`  去`,`주인조력`,`서남`,``,`동북`],
    [`10.11`,`  集`,`끌면제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`이행`,`분인무력`,`退背`,`  원`,`  去`,`주인조력`,`정서`,`남남서`,``],
    [`10.11`,``,`다중제압`,``,``,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`종반반대`,`이행`,`본인사안`,`退背`,`원근왕래`,`  去`,`주인무력`,`동남`,`남남서`,``],
    [`10.11`,`  集`,`제압불가`,``,`모두무력`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`사안무력`,`進向`,`원근왕래`,`  留`,`주인무력`,`북북서`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부동조`,`이행`,`선攻후攻`,`退背`,`  근`,`  去`,`주인가해`,`정동`,`정동`,``],
    [`10.11`,``,`중도제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,`끌면불가`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선費후이`,`退背`,`  원`,`  去`,`세입조력`,`남남서`,`동남`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`본인제압`,`이행`,`선損후費`,` 退背`,`  근`,`  去`,`세입가해`,`동북`,`동북`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대없음`,`이행`,`선損후損`,` 進向`,`  근`,`  留`,`세입가해`,`정남`,``,`정남`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선費후이`,`進向`,`  원`,`  留`,`세입조력`,`동남`,``,`남남서`],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,` 이행`,`선이후이`,`進向`,`  근`,`  留`,`무난`,`남남동`,``,`남남동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [`질병`,`전사귀`,`전사`,``,``,`묘좌`,``,`없음`],
    [``,`제례귀`,`제례위법`,``,``,`묘향`,``,``],
    [`생업`,``,``,`가당부정`,`성소훼손`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,`동남`,`없음`],
    [``,``,``,``,``,`묘향`,`북서`,``],
    [`생업`,`전사귀`,`전사`,``,``,`전경`,`花田`,``],
    [``,``,``,``,``,`묘속`,`장충`,``],
    [`가정`,``,``,`가당부정`,`성소훼손`,`육친`,`고조부`,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,`가장귀`,`호주사망`,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,`전사귀`,`전사`,``,``,`육친`,``,``],
    [`질병`,` 여귀`,`호주부인`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,`여귀`,`호주부인`,``,``,`육친`,``,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,`북북서`,`없음`],
    [``,``,``,``,``,`묘향`,`남남동`,``],
    [`생업`,``,``,`가당부정`,`성소훼손`,`전경`,`화전`,``],
    [``,``,``,``,``,`묘속`,`  水`,``],
    [`가정`,``,``,``,``,`육친`,` 조모`,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`가당부정`,`성소훼손`,`육친`,``,``],
    [`질병`,` 음귀`,`묘지탈`,``,``,`묘좌`,``,`없음`],
    [``,``,`기세없음`,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`가택귀`,`호주사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`가택귀`,`호주사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`내부가정급속사**외사동요기로출행원행이동사`,`상喜하僧사`,`매사 허성불성-세속허사자**소식대기-와도 나쁨`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음`,`종교진출하면자립함`,`외사급사-송쟁실종사 우려`,`입조입각-대중위한이익강구사`,`내녀 색정사**파재극처실종가출사 우려`,`부부불화사`,`위례득죄사`,``,`명예직녹자산권리사-외교막힘`,`문서소식 길사-송사 흉-이동사`,`외남 색정사-가정결속사-가정막힘`,`휴직-장차를 위한 불변근신사`,`내부가정상하화합사`,`고위연회초대열석사`,`내부가정출행이동사-외사애증화합-송쟁파재극처`,`실업자격-한가함`,`남녀직원공모사-은닉사有`,`음사폐호-가택안녕사`,`매사 위역사**가정단합사`,`도적실세-잡음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`내사 동요이별사`,`빈궁**병재`,`관직영전도약사-부족`,`전도**빈궁`,`전화위복`,`관재`,``,`빈궁**관재`,``,`전도`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,`전도`,`재액해소`,``,`재액해소`,`빈궁`,`침체驚懼사`,`전도**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사 위역사**가정단합사`,`도적실세-잡음`,`내부가정 급속사**외사동요기로출행원행이동사`,`상喜하僧사`,`매사 영속성없음**소식대기-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`외사급속사-송쟁실종사 우려`,`입조입각-대중위한이익강구사`,`내녀 색정사**파재극처실종가출사 우려`,`부부불화사`,`위례득죄사`,`내녀 색정사`,`명예직녹자산권리사-외교막힘`,`문서소식 길사-송사 흉-이동사`,`외남 색정사-가정결속사-가정막힘`,`휴직-장차를 위한 불변근신사`,`내부가정상화화합사`,`고위연회초대열석사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`실업저격-한가함`,``,`음사폐호-가택안녕사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`전화위복`,`전도`,`내사 동요이별사`,`전도**빈궁**병재`,`관직영전도약사-부족`,`빈궁`,`재액해소**전화위복`,`관재`,`재액해소`,`빈궁**관재`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁**관재`,``,``,``,``,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`음사폐호-가택안녕사`,`매사 위역사**도적실세-잡음`,`가정단합사`,`내부가정급속사**외사동요 기로출행원행이동사`,`상喜하僧사`,`매사 영속성없음**소식대기-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`외사급속사-송쟁실종사 우려`,`입조입각-대중위한이익강구사`,`내녀 색정사**파재극처실종가출사 우려`,`부부불화사`,`위례득죄사`,``,`명예직녹자산권리사-외교막힘`,`문서소식 길사-송사 흉-이동사`,`외남 색정사-가정결속사-가정막힘`,`휴직-장차를 위한 불변근신사`,`내부가정상하화합사`,`고위연회초대열석사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`실업저격-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁`,``,`빈궁`,`전화위복`,``,`내사 동요이별사`,`빈궁**병재`,`관직영전도약사-부족`,`빈궁`,`전화위복`,`관재`,``,`빈궁**관재`,``,``,`음4,5월화재주의`,`전도**빈궁**관재`,`음4,5월화재주의`,`전도**빈궁**관재`,``,``,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`충산상린사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`관재형책사`,``,`약속비밀누설자초죄사`,`가정단합사`,`내부가정급속사**외사동요기로출행이동원핸사`,`투서헌책-급문서 불용-금전실재사`,`매사 영속성없음**눈이 가려진 엄목 격`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`실업저격-한가함`,`외사급속사-송쟁실종사 우려`,`사행불량사`,`내녀 색정사**파재극처실종가출사 우려`,`도적소실사`,`매사 위역사**내녀 색정사`,`명예직녹자산권리사-외교막힘`,`권한수여-미초희경사-이동사`,`앙화소멸사`,`외남 색정사-가정결속사-가정막힘`,`내부가정상하화합사`,`사기득재사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁`,``,``,``,`전도**빈궁`,`전화위복`,`내사 동요이별사`,`전도**빈궁`,`투서헌책고위면접-부족`,``,`관재`,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,`빈궁`,``,`음4,5월화재주의`,`관재`,`음4,5월화재주의`,`빈궁**병재`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`내부가정상하화합사`,`사기득재사`,`충산상린사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`관재형책사`,``,`약속비밀누설자초죄사`,`가정단합사`,`투서헌책-급문서 불용-금전실재사`,`내부가정급속사**외사동요기로출행원행이동사`,`매사 영속성없음**눈이 가려진 엄목 격`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`실업자격-한가함`,`외사급속사-송쟁실종사 우려`,`사행불량사`,`내녀 색정사**파재극처실종가출사 우려`,`도적소실사`,`매사 위역사**내녀 색정사`,`명예직녹자산권리사-외교막힘`,`권한수여-미초희경사-이동사`,`앙화소멸사`,`외남 색정사-가정결속사-가정막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,`재액해소`,``,``,`빈궁**관재`,``,``,`재액해소`,`빈궁`,`전화위복`,`내사 동요이별사`,`빈궁`,`투서헌책고위면접-부족`,`재액해소`,`관재`,`전화위복`,``,`빈궁**관재`,`빈궁`,`재액해소`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`앙화소멸사`,`외남 색정사-가정결속사-가정막힘`,`내부가정상하화합사`,`사기득재사`,`충산상린사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`관재형책사`,``,`약속비밀누설-자초죄사`,`가정단합사`,`투서헌책-급문서 불용-금전실재사`,`내부가정급속사**외사동요기로출행이동원행사`,`매사 영속성없음**눈이 가려진 엄목 격`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`실업저격-한가함`,`외사급속사-송쟁실종사 우려`,`사행불량사`,`내녀 색정사**파재극처실종가출사 우려`,`도적소실사`,`매사 위역사**내녀 색정사`,`명예직녹자산권리사-외교막힘`,`권한수여-미초희경사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의**동요불안사`,`빈궁**병재`,``,`빈궁`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`전도**빈궁**관재`,`동요불안분산사`,``,`빈궁**관재`,`동요부난사**재액해소**전화위복`,`빈궁`,`내사 동요이별사`,`투서헌책고위면접-부족`,`동요불안분산사`,`관재`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`음4,5월화재주의**동요분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`명예직녹자산권리사-외교막힘`,`권한수여-미초희경사-이동사`,`앙화소멸사`,`외남 색정사-가정결속사-가정막힘`,`내부가정상하화합사`,`사기득재사`,`충산상린사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`관재형책사`,``,`약속비밀누설-자초죄사`,`가정단합사`,`투서헌책-급문서불용-금전실재사`,`내부가정급속사**외사동요출행이동원행사`,`매사 영속성없음**눈이 가려진 엄목 격`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`실업저격-한가함`,`외사급속사-송쟁실종사 우려`,`사행불량사`,`내녀 색정사**파재극처실종가출사 우려`,`매사 위역사`,`도적소실사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**병재`,``,`빈궁`,``,`관재`,``,`빈궁`,``,``,`전도**빈궁**관재`,`전화위복`,`내사 동요이별사`,`전도**빈궁`,`퉈헌책고위면접-부족`,``,`관재`,`재액해소**전화위복`,`빈궁**관재`,`재액해소`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`도적소실사`,`매사 위역사`,`명예직녹자산권리사-외교막힘`,`권한수여-미초희경사-이동사`,`앙화소멸사`,`외남 색정사-가정결속사-가정막힘`,`내부가정상하화합사`,`사기득재사`,`충산상린사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`관재형책사`,``,`약속비밀누설-자초죄사`,`가정단합사`,`투서헌책-급문서 불용-금전실재사`,`내부가정급속사**외사동요기로출행원행이동사`,`매사 영속성없음**눈이 가려진 엄목 격`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`실엊자격-한가함`,`외사급속사-송쟁실종사 우려`,`사행불량사`,`내녀 색정사**파재극처실종가출사 우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**관재`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**병재`,``,`전도**빈궁`,``,``,`빈궁`,``,``,`전도`,`빈궁`,`전화위복`,`빈궁`,`내사 동요이별사**재액해소`,`투서헌책고위면접-부족`,`전도`,`관재`,`전화위복`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`사행불량사`,`내녀 색정사**파재극처실종가출사우려`,`도적소실사`,`매사 위역사`,`권한수여-미초희경사-이동사`,`명예직녹자산권리사-외교막힘`,`앙화소멸사`,`외남 색정사-가정결속사-가정막힘`,`내부가정상하화합사`,`사기득재사`,`충산상린사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`관재형책사`,``,`가정단합사`,`약속비밀누설-자초죄사`,`투서헌책-급문서 불용-금전실재사`,`내부가정급속사**외사동요기로출행이동원행사`,`매사 영속성없음**눈이 가려진 엄목 격`,`종교진출하면자립함`,`매사 영속서없음`,`종교진출하면자립함`,`실업자격-한가함`,`외사급속사-송쟁실종사 우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁**관재`,``,`빈궁`,``,`음4,5월화재주의**재액해소`,``,`음4,5월화재주의**재액해소`,`빈궁**병재`,``,`빈궁`,``,``,`빈궁`,``,``,``,`빈궁`,`전화위복`,`빈궁`,`내사 동요이별사`,`투서헌책고위면접-부족`,``,`전도**관재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외사급속사-송쟁실종사 우려`,`입조입각-대중위한이익강구사-부족`,`내녀 색정사**파재극처실종가출사 우려`,`부부불화사`,``,``,`문서ㅗ식 길사-송사 흉-이동사`,`며예직녹자산권리사-외교막힘`,`외남 색정사-가정결속사`,`휴직-장차를 위한 불변근신사`,`내부가정상하화합사-가정막힘`,`고위연회초대열석사`,`실업저격-한가함`,`내부가정출행이동사-외사애증화합송쟁파재극처`,``,`음사폐호-가택안녕사`,`매사 위역사-가정단합사`,`도적실세-잡음`,`상喜하僧사`,`내부가정급속사**외사동요기로출행이동원행사`,`매사 영속성없음**소식대기-와도 나쁨`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**관재`,`전도**빈궁**관재`,``,``,``,`음4,5월화재주의**재액해소`,`빈궁`,`음4,5월화재주의**재액해소`,`빈궁`,``,``,``,``,`빈궁`,``,`빈궁`,``,`전화위복`,``,`빈궁**병재`,`내사 동요이별사`,`관직영전도약사-부족`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`매사 허성불성-세속허사자`,`음3,4,5,6,9,12월방문자종교진출하면성공함`,`입조입각-대중위한이익강구사-부족`,`외사급속사-송쟁실종사 우려`,`내녀 색정사**파재극처실종가출사 우려`,`부부불화사`,``,``,`문서소식길사-송사 흉-이동사`,`명예직녹자산권리사-외교막힘`,`외남 색정사-가정결속사`,`휴직-장차를 위한 불변근신사`,`내부가정상하화합사-가정막힘`,`고위연회초대열석사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`실업저격-한가함`,``,`음사폐호-가택안녕사`,`매사 위역사-가정단합사`,`도적실세-잡음`,`상喜하僧사`,`내부가정급속사**외사동요기로출행원행이동사`,`매사 영속성없음**소식대기-와도 나쁨`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관직영전도약사-부족`,`전도**빈궁`,`전화위복`,`관재`,``,`빈궁**관재`,``,`전도`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,`전도`,`재액해소`,``,`재액해소`,`빈궁`,``,`전도**빈궁`,`전화위복`,``,`내사 동요이별사`,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`매사 허성불성-세속허사자**소식대기-와도 나쁨`,`음7,8,10,11월방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`음3,4,6,9,12월방문자는종교진출하면 성공함`,`외사급속사-송쟁실종사 우려`,`입조입각-대중위한이익강구사`,`내녀 색정사**파재극처실종가출사 우려`,`부부불화사`,``,``,`문서소식길사-송사 흉-이동사`,`며예직녹자신권리사-외교막힘`,`외남 색정사-가정결속사`,`휴직-장차를 위한 불변근신사`,`내부가정상하화합사-가정막힘`,`고위연회초대열석사`,`내부가정출행이동사-외사애증화합송쟁파재극처`,`실업자격-한가함`,``,`음사폐호-가택안녕사`,`매사 위역사-가정단합사`,`도적실세-잡음`,`내부가정급속사**외사동요기로출행원행이동사`,`상喜하僧사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내사 동요이별사`,`빈궁**병재`,`관직영전도약사-부족`,`빈궁`,`전화위복`,`관재`,``,`빈궁*관재`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,``,`전도`,``,`전도**빈궁`,``,`빈궁`,`폐한인`,`재액해소**전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 내 재산이 날아갈 재판이니     불똥 붙은 셈인데 마음만     바쁘지 움직일 수가 없겠네?

  ⊕도로귀, 
  ⊖가택귀ㆍ가택음사부정을     잡으면 뒤집을 수 있겠어!
  `,`■ 변동수가 있네 ? 
     이사하겠어! 
     1男 2女 삼각관계도 있고!     빈말 때문에 물러설 수도     없겠네!
  ⊕도로귀ㆍ변사귀, 
  ⊖전사귀ㆍ가택귀 장난이야!
  ⊕⊖신변가당신사부정 침범이      들었어 !  
  `,`■ 돈이 줄줄 세고 있네?
     형제의 재해가걱정이고!
  ⊕변사귀ㆍ가당신사 부정 
  ⊖전사귀ㆍ가당신사 부정이    훼방하고 있는데다 
    산소(3면3국)를 돌보지 
    않아 흉상이 라서 그래!
  `,`■ 돈이 들어와도 재앙!
     승진되어도 재앙이겠네?
     은혜가 원수로 변하겠어! 
     돈이 문제야!
  ⊕도로귀ㆍ변사귀, 
  ⊖전사귀ㆍ가택귀 해소가 
    제일 급하네!
  
  ⊕신변음사부정도 침범했어! 
  `,`■ 하늘의 축복을 받는 사람이     남의 부인을 유혹했으니     색정사로 망신당하게 생겼어?
  男 :지금 타인의 부인을 내여자로      만들었는데
  ⊕여귀, 
  ⊖전흉사귀ㆍ가택상가부정 때문    이긴한데 자식한테 까지 禍가    오게 생겼어!
  `,`■ 이권을 상실하게 생겼네?     남녀추행으로 재앙을 당     하겠어!
     지금까지의 일과 인연이     끝나는 운세야!
     형제도 凶하고!
  ⊕여귀, 
  ⊖전흉사귀가 발동해서 그래!
  ⊕신변음사부정도 침범했네? 
  `,`■감추는 것(죄인)이 많겠네?
     색정사로 가정불화도 
     심하겠어! 
     불똥이 어디로 튕길지 
     모를 형편이야! 
     일도 그렇고! 
  ⊕가택음사부정 해소가 급하네!
    이권이 상실되겠어!
  `,`■ 도둑맞고 불안하고, 
     이럴까 저럴까하네?
     관재고심도있고,골육은 등     돌리고어른은반대하고… 
  ⊕여귀, 
  ⊖전흉사귀 해소가 급선무야!
  
  ⊕⊖신변가당신사부정도 침범      했어 !
  `,`■ 겉은 화려해서 빛나는데,   ⊕부부싸움이 심하겠네?
   ⊖병이 있겠네?
  女:⊕남의 남자를 적극적으로       유혹해 쓰네 ! 
  이 모두가 
  ⊕⊖가택가당신사 부정탓이야!
  `,`■ 감추고(죄인) 있는게 많이      있겠네?이권을상실하겠어!
     가정 불화가 심하겠어!
  男：재화형송사의 운세야 !
  女：음식부정이야!
  ⊕음귀, 
  ⊖신불제사귀 때문이야!
  ⊖신변음사부정도 침범했어 !
  `,`■시작은 있는데 끝이 없네?    일이 남의 손에 매여 살    얼음판이겠어! 
  이권이 상실되겠고!
  
  ⊕가택상가부정을 해소 해야    좋아지겠네!
  `,`■ 일은 생각과 거꾸로 가고     부부는 똑같이 
     바람이 났고,1녀2남 삼각     관계가 되었고 ! 
     모두 끝내야 길이 보여!
  ⊕도로귀, 
  ⊖가택귀를 없애고 나면 
    천우신조가 있겠어!
  
  ⊖신변음사부정도 침범했네?`];
  const YOGYEOL1 = [`■ 말전에서 초전을 도우나 이어서 해친다.
  정마와 역마가 있어도 말은 부처이나 마음은 뱀과 같다.
  `,`■ 나도 어둡고 집도 요란스럽게 발동한다. 
  귀살이 묘에 타 있으니 병자가 생기거나 재앙이 있겠다.
  `,`■ 사람은 다치고 집은 침침하고 앞길도 벼랑이다. 끝에도 실패이다.
  이익이 없으니 작은 것에 만족하라.
  `,`■ 위험한 지경이 되더라도 구원받아 무사하겠다. 도움을 받은 자가 오히려 해치니 재물 때문에 재앙이 생기리라.
  `,`■ 나를 돕는자가 많으나 밤정단은 두려운 일이 생긴다. 수양과 근신이 필요하다. 앞에서는 좋은 척 해도 뒤에서는 나쁘다.
  `,`■ 사과의 형체가 없으니 일의 자취를 밝히기 어렵겠다. 밤정단은 귀살이 범타고 있으나 그 힘이 약하여 놀랄 것 없다.
  `,`■ 오나가나 모두 비었다. 모든 일이 자취가 없겠다. 기쁜 일도 기쁘지 않겠고 나쁜 일도 나쁘지 않으리라.
  `,`■ 앞길이 어둡고 손해나고 귀신만 만나니 모두 두렵고 불미스럽겠다.
  집안에 들어앉아 있으면 먹고는 살지만 걱정이다.
  `,`■ 밤정단은 숨은 귀신이 범을 타고 있어 재앙이 두렵다. 자손이 있어도 물리칠 수 없으니 중간에서 방해받기 때문이다.
  `,`■ 귀인이 옥에 들어 덕보기 어렵다. 과정과 결과는 없고 오직 시작 뿐이니 돈 버리고 병들까 걱정이다.
  `,`■ 서로 왕래하여 정을 맺으니 좋은 일을 취할 수 있겠다. 오직 꺼리는 것은 돈 버는 일인데 싹은 트겠으나 자라지는 못한다.
  `,`■ 상대가 스스로 와서 도우니 내것을 지키는 것이 좋겠다. 욕심을 내서 계획하면 귀신과 칼든 도적을 만나겠다.`]
  const SIBO1 = [`■ 가정의 소비가 많다.
  어떤 일도 막혀 움직여
  주지 않는다. 그러나
  망동하면 재해를 초래한다. 근일 중 움직일 수 있는 단서를 잡게 된다.
  여러 사람이 연이어 침범하는 운세이다. 귀신을 해소해야 좋아진다.
  `,`■ 변동수가 있고 가택의
  권리를 이양한다.
  몸은 어둡고 가정은 동요
  한다. 고생되고 낭비가 많고 병ㆍ재가 걱정이다.
  사관ㆍ구직은 양호하고 내외교섭과 상의 하는 일은 성사된다.
  흉이 경하다.
  형제의 재해가 기한다.
  `,`■ 자충수적 우환이 있다.
  서로의 의지가 통하지 
  않고 재물의 소모가 있다.
  재해는 일어나지 않으나 일이 반복되기만 한다.
  女는 과부이거나 별리인이다. 만사가 실체가 없고 그림자 뿐이다.
  占時가 子午卯酉는 흉으로보고 다른 시간이면 피면으로 보라!
  `,`■ 자충수적 우환이 있다.
  가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  그러나 재해를 본다해도 해는 없게 된다.
  단 병 또는 소송사가 있게 될 조짐이 있다.
  윗사람의 천거와 원조를 받아 흉해를 구한다.
  正月 임신사가 있다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  ⊕귀인의 말에 속고
  ⊖허위사가 있다.
  내부에 손해사가 일어난다. 남자가 타부인을 유혹한다. 그러나 흉은 해소된다. 귀신을 해소시키면 천우신조를 얻게 된다. 관공직사는 좋다.
  `,`■ 이권을 상실한다.
  인연이 결절된다.
  악신의 빌미가 있어 내외
  쇠퇴의 운세이다.
  모두 형체는 없고 무력하여 그림자를 밟는 격이다. 
  女는 별리고독인이다.
  남녀 인연 끊는 일이다.
  해산시키는 일은 오히려 좋다. 正月 임신사 有
  `,`■ 가정에 이권상실사가 있다.
  왕래를 반복해도 실효가
  없다.
  가정부부간, 남녀간 불화한다.
  비밀사가 있고 감춘다.
  간통사로 본다.
  윗사람을 속이고 등진다.
  正月 임신사가 있다.
  `,`■ 가정이 쇠미하다.
  사람과 집을 버리고 멀리
  도피하고 싶은 심정이다.
  은거하며 기도하면 효과를 본다. 
  존장으로부터 압박과 기만, 빈대를 받는다. 
  골육상배한 상태이다.
  선택의 기로에 서서 불안한 입장이다.
  `,`■ ⊕여인의 구해사
  ⊖가당신사로 병 불안이 
  있다. 
  가정에서 부부불화가 심하다. 가정에 가당신사 부정이 들었다. 
  이를 해소시키면 일체의 흉이 제거된다.
  女는 타인의 남자를 유혹하여 따른다. 색정을 삼가치 않는다.
  `,`■ 가실 불화가 있다.
  비밀을 감추고, 악인을
  숨기고 있다.
  占時가 子午卯酉이면 男은 재화형전을 당하고 女는 심신이 부정하다고 본다. 남녀 모두 재앙을 당한다. 또는 여자의 중독사ㆍ재병사ㆍ산고 등으로 秋冬이면 죽는다고 본다. 正月 임신사가 있다.
  `,`■ ⊖가정에 상가신살 부정
  으로 병자가 있다.
  나무는 섰으나 열매가 없다.
  시작은 있으나 끝이 없다.
  악인에게 내 사정이 탐지되어 저주 받는다.
  모든 계획ㆍ모망사는 성사되지 못한다. 占時가 밤이면 더욱 흉하고 낮이면 작은 일에 희망이 있다.
  正月 임신사가 있다.
  `,`■ 모든 일이 생각과 반대가
  된다.
  부부가 똑같이 색정사를
  벌이고 있다. 일도 불비부족으로 중단해야 좋다.
  강을 건너야 하는데 뒤늦게 배를 구하는 격이다.
  신불기도가 흉을 구한다.
  뜻밖의 행운이 오고 원대한 뜻을 이루게 된다.
  단, 고정은 있다.`]
  const SIBO2_1 = [`■ 직ㆍ녹,신변외사다.
  내방조짐 상의 : 
 도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
 `,`■官･夫,내외파손사이다.
 내방조짐 상의 :  
 흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
 `,`■ 손모후회가정사이다.
 내방조짐 상의 : 
 어떤 일이든 처음 불성한다.
 손재·녀하고 형제 재액이 있다
 관리는 탄핵된다.
 `,`■官･夫,반목대립외사이다.
 내방조짐 상의 :  
 개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
 신규사가 있다.
 `,`■ 손모가택내사이다.
 내방조짐 상의 : 
 움직임이 근본이고 동기가 발동한다. 자손 재해 예방이 필요하다
 `,`■財ㆍ女,가택상쟁사이다.
 내방조짐 상의 :  
 어떤 일이든 처음 불성한다.
 이권상실하고 재·녀 재액이 있다.
 `,`■ 사업ㆍ후원가정동요사이다.
 내방조짐 상의 : 
 은둔ㆍ도망ㆍ원행에 이롭다.
 색정 재액의 상이다.
 `,`■손모·해산건·신변동요사이다.
 내방조짐 상의 :  
 흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
 `,`■官･夫,대립반목내외사다.
 내방조짐 상의 : 
 개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
 병재·관재 예방이 필요
 `,`■ 자손ㆍ박관개신사이다.
 내방조짐 상의 :  
 움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
 `,`■ 자손ㆍ박관신변외사이다.
 내방조짐 상의 : 
 원행·이동사가 있다.움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다. 
 `,`■官･夫,반목내외사이다.
 내방조짐 상의 :  
 개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
 진퇴양난사가 있다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/055/row-1-column-1.png`,
    `/static/images/sagwa/055/row-1-column-2.png`,
    `/static/images/sagwa/055/row-1-column-3.png`,
    `/static/images/sagwa/055/row-1-column-4.png`,
    `/static/images/sagwa/055/row-1-column-5.png`,
    `/static/images/sagwa/055/row-1-column-6.png`,
    `/static/images/sagwa/055/row-1-column-7.png`,
    `/static/images/sagwa/055/row-1-column-8.png`,
    `/static/images/sagwa/055/row-1-column-9.png`,
    `/static/images/sagwa/055/row-1-column-10.png`,
    `/static/images/sagwa/055/row-1-column-11.png`,
    `/static/images/sagwa/055/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `   이 과는 천지가 복(伏)하고 본궁 중에 있기 때문에 복음과(伏吟課)로 되며, 4과에 극이 없으니 일간상신을 초전으로 한다. 
    복음(伏吟)은 무슨 일이나 복(伏)하여 움직이지 않는다. 양일은 자임격(自任格)으로 망동(妄動)하면 재(災)를 생한다. 그러나 초전이 정신이고 중전이 역마이면 근일에 동조(動兆)가 있다. 술(戌)의 일간에서 사(巳)의 덕록이 신(身)에 임하면 길로 된다. 말전은 일간을 극하여 귀효이지만, 중전에서 제하므로 흉해를 구한다. 초전은 중전을 극하고 중전은 말전을 극하며 말전은 일간을 극한다. 결국 체극이 되어 침릉(侵凌)은 면하기 어렵다.
    일귀는 순포(順布)하고 삼전은 구․백․사이며, 야귀는 역행하고 삼전은 주․후․청이다.
    복음과는 무슨 일이나 부근에 있는데 예를 들면 분실물은 근처 혹은 집안에 있다고 본다.`
    ,
    // 2
    `  이 과는 4과 중 일간의 음신 제2과의 묘진(卯辰)이 1상에서 1하를 극하여 초전이 되고 원수과이다.
    일간상신에 묘가 복(覆)하여 내몸은 혼회(昏晦)가 있다. 일지상신에 정신이 있고 가정은 동요한다.
    초․중전 묘인(卯寅)은 관귀(官鬼)이고 둔간 갑을(甲乙)도 관살이 되어 명암(明暗)이 내몸을 극한다. 사관․구직은 양호하지만 기타의 일은 고생과 낭비가 많고 재병을 초래한다. 퇴여격으로 말전이 공망이 되니 물러설 때는 오히려 결함을 만난다.
    4과는 불비격으로 천강은 일간에 가담하며, 일의 건록은 지상(支相)에 가담한다.
    일귀는 순행하고 삼전은 주․사․귀이며, 야귀는 역행하고 삼전은 구․청․공이다. 말전이 공망하고 천공이 승하면 공론과 빈말로 끝이 난다. 교차상생하면 내외가 모두 교섭 및 상의하는 일은 성사된다. 흉과이지만 원수과이기 때문에 흉이 경하다.`
    ,
    // 3
    `   이 과는 4과 중 세 곳에 상하의 극이 있어 하에서 상을 손상하는 제2과 축묘(丑卯)를 사용하여 초전으로 하는 중심과이다.
    삼전에서 일신 건너기 때문에 역간전격(逆間傳格)이고, 일간상신 묘(卯)에서 극을 받지만 말전 유(酉)에서 제를 받으니 해가 없다. 초․중전은 공망하고 말전은 실(實)이다. 
    일간일지상신이 육해로서 주객(主客)의 의지가 통하기 어렵고 축해유(丑亥酉)는 극음(極陰)의 지(支)이기 때문에 재(災)는 일어나지 않으나 재물의 소모가 있다. 이 과는 길흉이 모두 암시되지 않는다.
    극음격․여덕격에 해당한다. 일귀는 순행하고 삼전은 귀․음․상이며, 야귀는 역행하고 삼전은 공․상․음이다. 초전이 공중공격이니 만사가 그림자만 있고 형체가 없다. 
    이 과는 점시(占時)가 자오묘유(子午卯酉)가 아니면 흉을 이루지 않는다.`
    ,
    // 4
    `  이 과의 제1과 인술(寅戌)에서 1상이 1하를 극하여 인사(寅巳)를 초전으로 하는 원수과이다. 초전의 인(寅)은 순수(旬首)에 해당하기 때문에 순의(旬儀)이고 삼전의 사맹신(四孟神)은 원태격이다. 인(寅)은 갑인(甲寅)이므로 명과 암으로 일간을 극한다. 귀효이지만 말전의 신(申)에서 제하므로 재(災)를 보아도 해가 없다.
    말전은 금(金)에서 수(水). 인목(寅木)과 체생격(遞生格)이 되어 구직의 건은 손윗 사람으로부터 천거와 원조를 받는다. 사과삼전 중 사귀(四鬼)가 일간을 상해하니 병소(病訴)가 생긴다.
    순의는 흉해를 구한다. 일귀는 순행하며 삼전은 사․음․백이고, 야귀는 역행하며 삼전은 청․상․후이다.
  
  
  `
    ,
    // 5
    `   이 과는 제4과 술인(戌寅)에서 1하가 상을 손상하여 중심과이다. 삼전 인오술(寅午戌)은 염상격(炎上格)으로 일간을 생한다. 천후에 육합이 타서 교동격이 되므로 남자가 여자를 유혹한다. 제4과를 초전으로 하면 맥월격(驀越格)으로 혹은 무슨 일이나 연체(延滯)하거나 또는 우연하게 발생한다.
    일간일지는 교차육해이다. 즉 합 중 해가 있으면 내부에 해사(害事)가 발생한다. 일간상신이 축(丑)의 공망에 해당하고 천공이 승하면 공론으로 된다. 귀신이 해(亥)에 임하면 귀신이 천문에 오른다. 
    사맹상신에 진술축미(辰戌丑未)가 승하면 천문에 올라가서 신장살몰(神藏殺沒)이 되고 흉을 제복생화(制伏生化)하며, 길신이 나타나서 흉은 모두 해소된다. 백호가 묘에 임하면 존친에게 재해가 있다.
    비전에 이르기를 술(戌)에 육합이 승하면 성이 다른 두 세 사람과 동거한다. 삼전이 체생(遞生)하고 삼합회국(三合會局)하여 일간을 생하며 만사는 순조롭게 진전된다. 
    말전 인(寅)에 백호가 승하면 최관사자(催官使者)라 칭하여 관공직(官公職)에 가장 양호하다. 일귀는 역행하고 삼전은 합․백․후이다.`
    ,
    // 6
    `   이 과는 상하의 극이 있고, 하에서 상을 극하는 자사(子巳)를 사용하여 초전으로 하는 중심과이다. 4과가 모두 자축(子丑)의 공망이 되고 초․중전도 공망하며, 말전과 같은 공망은 거의 형(形)이 없다. 형이 없으면 일을 명확하게 하기 어렵다.
    말전에 백호가 타서 일간이 극을 당하면 병점(病占)은 가장 흉하고, 이 과와 같은 무력(無力)한 과격은 구사(舊事)의 결속이나 해산에는 오히려 좋다.
    비전에 이르기를 ｢인(寅)에 백호가 타서 일간을 극하면 악신(惡神)의 빌미가 있다｣고 하였다.
    일귀는 역행하며 삼전은 사․공․후이고, 야귀는 순행하며 삼전은 청․귀․백이다. 일귀는 장생을 만나면 관공직은 길하나 여기에서는 묘(墓)에 앉아 길하지 못하다.
  
  
  `
    ,
    // 7
    `   이 과는 천지반 충위(冲位)에 있고 반음과로 되며, 어느 것이나 상하의 상극뿐이다. 양일은 하에서 상을 극하는 오자(午子)를 사용하여 초전으로 한다.
    삼전이 모두 자(子)의 공망을 만난다. 반복 왕래하면 모두 공(空)이 되고 종적(踪跡)이 없다. 길흉을 만나지만 모두 실현되지 않는다. 즉 그림자를 붙잡는 것과 같다. 반음은 반복 이동의 과이지만 공망이 있으니 움직일 수 없고, 일지상신에 사신이 올라타서 지지의 가택을 극하기 때문에 화난에 주의를 기울여야 한다.
    비전에 이르기를 ｢일간상신 해(亥)는 재효로 되지만 폐구(閉口)의 작용에 의한 재동(財動)으로 역시 병점은 불길하며 말을 하지 못한다｣고 하였다.
    3하가 3상을 극하영 장도액격(長度厄格)으로 되면 손윗 사람을 속이며 존장과 등지니 흉이다. 
    일귀는 역행하며 삼전은 백․사․백이고, 야귀는 순행하며 삼전은 후․청․후이다. 길장이 승하여도 유익하지 않은 과격이다.`
    ,
    // 8
    `   이 과는 3과에 모두 음양의 극(剋)이 있다. 오늘은 양일이므로 양의 극인 진해(辰亥)의 극을 사용하여초전으로 하며, 양일양비(陽日陽比)의 지일과(知一課)가 된다. 3상에서 하를 극하기 때문에 유도액격(幼度厄格)이라고 하고, 4과의 진(辰)자를 초전으로 하여 참관격이다. 일간상신의 술(戌)에서 충하면 참관(斬關)이 통하여 개고(開庫)로 된다.
    초전은 묘신, 중전은 설탈(泄脫)로 되고, 말전은 일간을 극하니 일귀로 된다. 그러나 유금(酉金)이 귀(鬼)를 제하니 흉을 보아도 흉에 걸리지 않는다. 제3과는 해(亥)의 재성이 택상에 임하고 유도액(幼度厄)은 부하의 것이니 유소(幼少)한 사람이 위로부터 압박을 받는다. 초전에 현무가 승하면 분실․도난사건이 있다. 진(辰)에 현무가 승하면 도둑이라면 전과(前科)가 있는 남자이다. 일간의 묘신이 초전으로 되고 사계관신(四季關神)이 초전으로 되는 것은 간묘병관(干墓倂關)이라 하여 인택(人宅)을 모두 버리게 된다. 그러나 일간의 음신․양신이 초전이 되면 사람이 쇠퇴하고, 만약 제3과․제4과의 음양이 초전이 되면 택(宅)이 쇠미(衰微)한다.
    비전에 이르기를 ｢현무가 진(辰)에 승하고 해(亥)에 가담하면 도망자는 반드시 돌아온다. 해(亥)는 현무의 본궁이므로 본집에 돌아오는 것은 당연한 이치이다.
    일귀는 역행하며 삼전은 현․구․후이고, 야귀는 순행하며 현․주․백이다, 백호․귀효는 가장 두렵지만 제지(制地)에 있어서 경하고, 현무는 진술(辰戌)의 충이 있으면 도망사건에서는 이익이 있다.`
    ,
    // 9
    `   이 과는 제4과의 인술(寅戌)이 1상에서 1하를 극하여 초전으로 되는 원수과이다. 초전 갑인(甲寅)은 육의(六儀)라 하여 일체의 흉을 구제한다. 
    초전의 인(寅)은 명암의 귀(鬼)로 되어 두려운 것이지만 유금(酉金)이 일산상신에서 제하여 귀해(鬼害)를 방지한다. 백호가 초전이면 흉이 신속하다. 
    삼전은 인오술(寅午戌)이 삼합화국해서 일간을 생한다. 삼전에 후․합이 승하면 여자는 색정으로 흐르고 삼가지 않는다. 삼전이 귀효에서 체생이 되면 살인이 생화하고 백사에서 흉을 풀어주니 길조로 본다.
    비전에 이르기를 ｢천후의 음신에 백호가 승하면 부부 싸움이 끊어지지 않는다｣고 하였다. 
    삼전삼합(三傳三合) 염상격(炎上格)으로 체생이 된다.
    일귀는 역행하며 삼전은 후․백․합이고, 야귀는 순행하며 백․후․합이다.`
    ,
    // 10
    `   이 과는 제3과에서 1하가 1상을 손상하여 중심과로 되고, 일지는 오(午)의 사중신(四仲神)이 삼교격(三交格)으로 된다. 중․말전은 순공(旬空)에 해당하고, 중전의 천반공망은 부천공망(浮天空亡)이라 한다. 천반공망은 이동(移動)하지만, 지반공망은 지저공망(地底空亡)이 되어 움직이지 않는다. 그러므로 길흉이 중하다. 초전의 유(酉)만이 실(實)이나 일간이 설기로 되어 삼전 중의 하나도 취할 수 없다. 삼교의 과는 신불의 일에 유리하다. 삼전은 체생이 되기 때문에 공망을 탈(脫)하여 야귀가 생의(生意)를 나타낸다. 청룡은 생기에 임하여 길이지만 지연된다. 중․말전이 공망을 이루면 초전의 일신(一神)을 길흉 사신의 유신으로 결정한다.
    비전에 이르기를 ｢술오(戌午)일 초전의 유(酉)가 오(午)에 가담하고 천지반의 진술(辰戌)이 축미(丑未)에 가담하면 천지이번과(天地二煩課)로 된다｣고 하였다. 또 구진(勾陳)이 승하면 결혼 문제가 있는데 오래 끌기만 하고 성립되지 않는다. 
    점시(占時)가 사중신일때는 진정한 삼교격이 되어 교섭사․간섭사는 오래 끌고 중지하려고 해도 불가능하다. 일귀는 역행하며 삼전은 구․사․음이고, 야귀는 순행하여 삼전은 주․청․상이다.
    이 과는 삼전이 재관에 해당하지만 공망이 되니 명리(名利)를 구할 희망이 없다. 이번격(二煩格)은 가정 내에 재화가 있다. 남명이 천번(天煩)을 만날 때는 재화형전(災禍刑戰)의 일이 있고, 여명은 중독(中毒)․재병(災病)․산난(産難)․신심부정(身心不正)․의 일이 있으니 남녀 모두에게 흉이다, 병환에 걸리면 춘하의 정단은 위험을 면하게 하지만, 추동(秋冬)에는 사액(死厄)이 있다. 여행과 모사는 흉을 초래한다. 진정한 이번격은 사중월(四仲月)은 혹은 사중일(四仲日)이고 진술(辰戌)의 지반에 축미(丑未)가 승하는 것을 말한다. 본 과는 오일(午日) 제10국으로 진술(辰戌)의 지(地)에 축미(丑未)가 승하기 때문에 이번격(二煩格)이 된다.`
    ,
    // 11
    `  이 과는 제3과의 오화(午火)에서 신(申)의 천반을 극하여 중심과로 된다. 일간의 미(未)와 일지의 오(午)가 지합하고, 일지상신의 신(申)과 기궁 사(巳)와 지합하여 교차지합(交車支合)이 된다. 초전의 신금(申金)은 일간의 술토(戌土)에서 보아 장생이다. 즉 수토(水土)장생의 법에 따른다. 초전에 백호가 승하면 지반 오화(午火)에 의하여 소신(燒身)되어 두려워할 것 없다. 말전 자(子)의 수재(水財)가 공망하여 묘(苗)는 있으나 열매가 없으며, 시작은 있으나 끝이 없다.
    유해(酉亥)가 2신을 건너 삼전을 이루면 순간전격(順間傳格)이다. 제11국은 지반 인(寅) 위에 진(辰)이 승하기 때문에 강색귀호(罡塞鬼戶)라고 하며, 과전 중에는 보이지 않지만 이 격은 악인(惡人)에게 엿보이고 저주 당하는 격이다. 신술자(申戌子)의 순간전격은 삼연(三淵)을 건너는 격이 되니 비가올 듯할 뿐 오지 않는다. 대인은 오지 않고 병환․송사(訟事)는 흉조로 되며, 목전에 조격(阻隔)이 생긴다. 구직은 성사되지 않고, 모사(謀事)․망사(望事)도 성공되지 않는다. 그러나 말전이 공망하여 위험은 좀 해소된다. 교차지합하니 교섭사가 성립된다.
    일귀는 순행하며 삼전은 백․현․후이고, 야귀는 역행하며 후․현․백이다. 비전에 이르기를 ｢오(午)에 백호가 임하고, 주작은 진(辰)에 가담하여. 주작은 구진에 투입한다고 하며, 소송사가 발생할 우려가 있다｣고 하였다. 
    백법의 번호는 미술일(未戌日), 무오일(戊午日) 제11국으로 삼전 신술자(申戌子)는 백법 제94에 해당한다. 섭삼연격(涉三淵格)이란 신(申)의 시각에는 광휘가 있지만, 술일(戌日)에 들어간 후부터 야반(夜半) 자(子)의 시각은 명에서 암으로 들어가니 단전격이 나아가는 데 방해가 있다. 그러므로 이렇게 명명하였다. 큰 것은 구하여도 성사되지 않고 소사는 공망을 나와서 획득할 수 있다.`
    ,
    // 12
    `   이 과는 4과 중 상하에 극이 없으므로 다른 일신을 지반 중에서 취하여 사용하기 때문에 별책과(別責課)라고 한다. 양일은 늘 움직이기 쉬운 위치에 있기 때문에 일간의 간합상신(干合上神)을 취용한다. 무(戊)는 계(癸)와 합하고 계(癸)는 기궁이 축(丑)이 되기 때문에 지반 축(丑) 위에 인(寅)을 사용하여 초전으로 한다. 중․말전은 양일에는 간상신 오(午)를 사용한다. 만약 음일이면 일지의 삼합의 전지(前支)를 사용하는 것이다.
    이 과는 3과 뿐이므로 불비격으로 되어 무슨 일이나 불비와 결함이 있고 일이 삽체(澁滯)된다. 재(財)를 구하여도 완전하지 못하다. 강을 건너려 해도 배가 없고, 구혼한 후에 다른 사람에게 장가드는 일이 있다. 일지가 간상에 가서 일간을 생하면 그가 와서 내쪽에 유익하다. 이리하여 제왕(帝旺)이 되고 뜻대로 된다. 그러나 일간상의 신록이 왕(旺)할 경우에는 망동하면 좋지 않다. 초전의 인(寅)은 귀살이지만 제4과에서 제하므로 흉을 보아도 후에 예방할 수 있다. 중․말전은 양인이기 때문에 피를 보는 일을 꺼린다. 4과가 사오(巳午)․오미(午未)․신미(申未)로 되면 나망격(羅網格)이라 하여 지키고 있는 것이 유리하다. 책동(策動)․망동은 좋지 않기 때문에 모사(耗事)는 졸책(拙策)이다.
    비전에 이르기를 ｢인목(寅木)은 귀살이 되고 춘월에 정단하면 귀(鬼)가 왕하기 때문에 재해가 한 사람뿐만 아니라 수사람에게 미치게 되니 신불(神佛)에게 기도하여야 한다｣고 하였다. 이 과는 갑인(甲寅)의 순중(旬中)에 있고 초전의 인(寅)은 순의(旬儀)로 된다. 공명을 정단하면 뜻밖의 기쁨이 있고, 일지의 오(午)가 일간상신으로 되어 일간을 생하여 미(未)와 지합하면 자재격(自在格)이라 명(名)하니 일에서 힘들이지 않고 획득한다.
    일귀는 순행하며 삼전은 사․청․청이고, 사(蛇)는 화하여 청으로 되며 원대한 뜻을 이룩한다. 야귀는 삼전이 역행하여 청․사․사이며 청은 변화하여 사(蛇)로 된다. 무슨 일이나 마지막에는 고정(苦情)이 있다. 즉 처음에는 길이나 후에는 흉이라 본다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
