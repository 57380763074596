import React, { useState, useEffect } from 'react';

import fireDb from "../firebase";
import Firebase from 'firebase';
import Tablet1 from "./Tablet1";

// https://www.geeksforgeeks.org/how-to-authenticate-phone-number-using-firebase-in-reactjs/
const Login = () => {
    // Inputs
    const [mynumber, setnumber] = useState("");
    const [replaceNumber, setReplaceNumber] = useState("");
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState('');

    const [tabletContentsUse, setTabletContentsUse] = useState(false);
  
    useEffect(()=> {

        // window.localStorage.clear();
        
        // 번호인식(휴대폰, 핸드폰)123123
        const yookimAi_Phone = window.localStorage.getItem("yookimAi_Phone");
        console.log("yookimAi_Phone", yookimAi_Phone);
        if (yookimAi_Phone && yookimAi_Phone != "")  {
            var phonePureValue = yookimAi_Phone.replaceAll("+").replaceAll("-");
            // setMessageFromAndroid("" + phonePureValue)
            setTabletContentsUse(true);
        } else {
            // window.location.href = "/tablet-otp"
        }
        
    },[])
    useEffect(()=> {
        
        if (mynumber.indexOf("010") == 0) {
            setReplaceNumber(mynumber.replace("010", "+8210"));
        }
    },[mynumber])

    // Sent OTP
    const signin = () => {
  
        if (mynumber === "" || mynumber.length < 10) return;

        if (mynumber.indexOf("010") != 0) {
            alert("010으로 시작하는 숫자만 입력해주세요.");
        }

  
        let verify = new Firebase.auth.RecaptchaVerifier('recaptcha-container');
        Firebase.auth().signInWithPhoneNumber(mynumber.replace("010", "+8210"), verify).then((result) => {
            setfinal(result);
            alert("코드를 보냈습니다.")
            setshow(true);
        })
        .catch((err) => {
            alert(err);
            window.location.reload()
        });
    }
  
    // Validate OTP
    const ValidateOtp = () => {
        if (otp === null || final === null)
            return;
        final.confirm(otp).then((result) => {
            // success
            window.localStorage.clear();
            // window.localStorage.setItem("yookimAi_Phone","821025363850" )
            window.localStorage.setItem("yookimAi_Phone",mynumber )
            setTimeout(()=> {
                window.location.href = "/tablet";

            }, 1500)
        }).catch((err) => {
            alert("Wrong code");
        })
    }
  
    if (tabletContentsUse == true) {
        return (<Tablet1/>)
    }


    return (
        <div style={{ "marginTop": "200px" }}>
            <center>
                <h3>육임 AI 전화번호 확인</h3>
                <div style={{ display: !show ? "block" : "none" }}>
                    <input  style={{
                        width: `calc(100% - 12px)`,
                        
                        height: `40px`,
                        display: 'flex',
                        alignItems : 'center',
                        justifyContent: 'center',
                        color : '#000',
                        border: `1px solid #000`,
                        marginTop: 'auto',
                        bottom: 4,
                        fontSize : 16, 
                        fontWeight : 700,
                        marginTop: 8,
                    }}
                    value={mynumber} 
                    onChange={(e) => { 
                       setnumber(e.target.value) 
                    }}
                        placeholder="전화번호 (010으로 시작하는 번호 )" />
                    <br /><br />
                    <div id="recaptcha-container"></div>


                    <div style={{
                        width: `calc(100% - 12px)`,
                        
                        height: `40px`,
                        background: `#8d8daa`,
                        display: 'flex',
                        alignItems : 'center',
                        justifyContent: 'center',
                        color : '#fff',
                        border: `1px solid #000`,
                        marginTop: 'auto',
                        bottom: 4,
                        fontSize : 16, 
                        fontWeight : 700,
                        marginTop: 8,
                    }}
                    onClick={signin}
                    >인증코드 보내기
                    </div>

                </div>
                <div style={{ display: show ? "block" : "none" }}>
                    <input type="text" style={{
                        width: `calc(100% - 12px)`,
                        
                        height: `40px`,
                        display: 'flex',
                        alignItems : 'center',
                        justifyContent: 'center',
                        color : '#000',
                        border: `1px solid #000`,
                        marginTop: 'auto',
                        bottom: 4,
                        fontSize : 16, 
                        fontWeight : 700,
                        marginTop: 8,
                    }}
                     placeholder={"문자로온 인증코드 입력"}
                        onChange={(e) => { setotp(e.target.value) }}></input>
                    <br /><br />

                    <div style={{
                        width: `calc(100% - 12px)`,
                        
                        height: `40px`,
                        background: `#8d8daa`,
                        display: 'flex',
                        alignItems : 'center',
                        justifyContent: 'center',
                        color : '#fff',
                        border: `1px solid #000`,
                        marginTop: 'auto',
                        bottom: 4,
                        fontSize : 16, 
                        fontWeight : 700,
                        marginTop: 8,
                    }}
                    onClick={ValidateOtp}
                    >
                        인증
                    </div>

                </div>
            </center>
        </div>
    );
}
  
export default Login;