
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,9,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,8,8,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,9,7,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`고집`,10,6,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,5,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,12,4,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`신뢰`,1,3,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,2,2,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`신뢰`,3,1,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`위선`,4,12,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,5,11,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,10,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`직위`,`현직 퇴임-응직 임관 됨`,`小`,``,`도적관련`,``],
    [`녹위`,`지금 그대로 좌수(座守)함이 좋음`,`小`,`1`,`夜起散髮`,`충산동요`],
    [`자산`,`자산 관련사-소사 이익 대사 무익`,`大`,`절정기`,`의혹불녕`,`파재가출`],
    [`부인송사(婦人訟事)`,`부인이 송사를 일으키는 상`,`大`,``,`외녀화합`,`송사제기`],
    [`사송계류(詞訟稽留)`,`재판이 대기 중인 상`,`小`,``,`명위반납`,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업사`,`大`,`2`,``,``],
    [`권리`,`권리사`,`小`,`면모기`,`외교막힘`,`개신개혁`],
    [`부인배배(婦人拜陪)`,`부인을 맞아들이는 상`,`小`,``,`구연반복`,`남녀맞절`],
    [`미초희경(微招喜慶)`,`고위로부터 술잔을 내려받고기쁨을 애써 참는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`3`,`급속사有`,`급속사有`],
    [`자식`,`자식관련사`,`小`,`학습기`,`소인쟁재`,`손해가출`],
    [`피흉기길(彼凶己吉)`,`상대는 흉하고 본인은 길한 상`,`大`,``,``,``],
    [`도적악의(盜賊惡意)`,`도적이 악의를 가졌으므로 되려 공격하지 말라-는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,``],
    [`극남`,`남자 갈등사`,`小`,`4`,``,`추천受賞`],
    [`결속`,`개신개혁하여 후원사를 크게 완성하나 자녀 근심사 발생`,`小`,`개시기`,`사후쟁탈`,`문호개변`],
    [`발탁도약(拔擢跳躍)`,`추천 발탁되어 활약하게 되는 상`,`大`,``,``,`불안분산`],
    [`면전복배(面前腹背)`,`면전 작호(作好) 배후 위해사`,`小`,``,``,`이혼우려`],
    [`관사`,`관사관련사-문서침체 관련`,`小`,``,``,``],
    [`직업`,`직업관련사`,`大`,`5`,``,`재난전소`],
    [`남편`,`남편관련사`,`小`,`배양기`,`파재실종`,`동기화합`],
    [`문서지체(文書遲滯)`,`문서(보고서)를 올렸으나 답이 없는 상`,`小`,``,``,``],
    [`공직방비(公職防備)`,`공직자는 흉 방비 필요-일반인 은 잠정적-서민 면함`,`大`,``,``,``],
    [`금전`,`자금관련사`,`大`,``,``,``],
    [`사업`,`사업관련사`,`大`,`6`,`도적실세`,`사행불량`],
    [`처첩`,`여인 관련사`,`小`,`존재기`,`개신개혁`,`개신개혁`],
    [`사행불량(私行不良)`,`여인의 정조가 문란한 상`,`小`,``,`불의투서`,`이혼우려`],
    [`다반실탈(多般失脫)`,`태아(胎兒)낙태 파재 분실 관련의 상`,`小`,``,``,``],
    [`금전`,`금전관련사-실재(失財)`,`大`,``,``,`색정사有`],
    [`사업`,`상업관련사-투서헌책 역효과`,`大`,`7`,`색정사有`,`도적관련`],
    [`색정`,`외남 내녀 동시 색정사 有`,`小`,`단절기`,`손해도망`,`포난심획`],
    [`족가해액(足可解厄)`,`액난의 해소가 만족한 상`,`小`,``,`불변근신`,`(못잡음)`],
    [`불의헌책(不宜獻策)`,`투서헌책이 좋지 못한 상`,`小`,``,``,``],
    [`관사`,`형책(刑責)관사 有`,`小`,``,``,``],
    [`직업`,`직업관련사`,`大`,`8`,`개신개혁`,`개신개혁`],
    [`결속`,`개신개혁하여 후원사를 크게 완성하나 자녀 근심사 발생`,`小`,`침체기`,`동요분산`,`수혜사有`],
    [`추천수상(推薦受賞)`,`개혁하여 후원조력이 크나 자녀 근심이 닥치는 상`,`小`,``,`추천도약`,`승진영전`],
    [`간인모해(奸人謀害)`,`간사한 자가 꾸민 거짓으로  해악을 당하는 상`,`大`,``,``,``],
    [`후원`,`후원 조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`9`,``,`소식대기`],
    [`부친`,`부친 관련사`,`大`,`소외기`,`시혜사有`,`와도나쁨`],
    [`비곡수욕(悲哭差辱)`,`면모가 훼손되어 슬피 우는 상-훼절`,`小`,``,`비곡차욕`,`비용과출`],
    [`녹역위이(祿亦危矣)`,`녹위(祿位)의 재액(災厄)이 닥치는 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서관련사`,`小`,`10`,`가택이사`,``],
    [`모친`,`모친관련사`,`大`,`침해기`,`개신개혁`,`가정막힘`],
    [`존비상몽(尊卑相蒙)`,`신분과 나이 차이가 큰 동침사가 있는 상`,`小`,``,`관계불통`,`가족질병`],
    [`길사목적(吉事目賊)`,`길사(吉事)를 마쳤으나 도적의 눈앞에 드러낸 꼴의 상 `,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`11`,`외사급속`,``],
    [`매사 영속성없음`,`명예관직직업권리남편남자사는 선천적 기복재해를 겪음`,`小`,`쇠퇴기`,`송쟁사有`,`손해가출`],
    [`전토허사(田土虛詐)`,`전토 가옥사의 사기 허사를 당하 상`,`小`,``,`파재실종`,`반화위복`],
    [`유미관의(有微官位)`,`관직 또는 권리사가 미미하고 부족한 상`,`大`,``,`상해우려`,`부족함`],
    [`형제`,`형제관련사-형제친구 관련사`,`小`,``,``,``],
    [`매사 허성불성`,`음7,8,10,11월 방문자는 종교진출하면 성공함`,`小`,`12`,``,``],
    [`매사 영속성없음`,`형제자매친구동지처첩불행금전손모사는 선천기복 재해 임`,`大`,`과시기`,`존비상몽`,`간인궤계`],
    [`미면조체(未免阻滯)`,`일의 방해나 지체를 면하기 어려운 상`,`大`,``,`사有`,`사有`],
    [`존비상몽(尊卑相蒙)`,`신분과 나이가 차이나는 동침하는 관계의 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,`직위자산으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`관사남자권리문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위부부갈등자식문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위남자갈등여식문제면전복배적행위로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`관시직업남편문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,`금전사업여인세력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,`금전사업여인세력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`관사직업남자모해사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`후원문서부친조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"후원문서모친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 놀람**만약 타인과 다툼이 있다면 피아간 전의가 별로 없으며 있다하더라도 가벼움 `,0,0,`옳지목한 일이 밝혀져 상대의 추궁으로 큰 두려움을 갖고 있으나 언행 자제로 자연 해소될 人`,0,0,`현재 사안에서 물러남이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 놀람**만약 타인과 다툼이 있다면 피아간 전의가 별로 없으며 있다하더라도 가벼움 `,0,0,`옳지 못한 일이 밝혀져 상대의 추궁으로 생긴 큰 두려움을 갖고 있으나 언행을 자제하면 자연 해소될 人`,0,0,`일이 거꾸로 띄엄띄엄 진행됨**돌아서서 밝은 곳으로 나아가게 됨**크게 구하더라도 작게 얻음**타인을 공격해도 적중하지 않음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후에 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`타인의 호의를 쉽게 받아들이지 말고 전후좌우를 세밀히 살펴야 함**풍운불측의 상임**타사에 연루되거나 은닉사로 관재가 우려됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 앞세워 속결해야만 유리함`,0,0,`정정당당하게 서둘러 속결하면 유리하고 좌면우면하거나 지체시키면 되려 불리해지는 人`,0,0,`여러 사안을 하나로 묶는 개신개혁을 단행하려 함**그러나 여건이 부족 불비한 실정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후에 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`현재의 계획과 방침을 급히 변경시켜야 함**모든 옛 일이 끓김`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상으로 재난이 밖에서 오니 신중해야 함`,0,0,`동요불안 이별의 반복사로 고통을 겪는  人`,0,0,`변동이 많아 의지처가 없는 형편임**풍운불측의 입장임**반복되기만 하던 일에비로소 순리와 결말의 시기가 닥침`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해할 뜻이 있음`,0,0,`저주 소송의 조짐이 있는 人`,0,0,`윗 인(父母)이 아랫 사람(子女)로부터 핍박을 받고 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우니 신중해야 하고 후에 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`여러 사안을 하나로 묶는 개신개혁을 단행하려 함**그러나 여건이 부족 불비한 실정임**현재의 계획과 방침을 급히 변경시켜야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 세워 속결해야만 유리함 `,0,0,`정정당당하게 급속 선수적 종결이 유리하고 좌고우면하거나 지체시키면 되려 불리해지는 人`,0,0,`토지가옥사(권리양도됨)**종적없이 어디로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 명분을 앞세워 속결해야만 유리함`,0,0,`정정당당하게 급속 선수적 종결이 유리하고 좌고우면하거나 지연시키면 결과가 되려 불리해지는 人`,0,0,`일이 순서적으로 띄엄띄엄 진행됨**집을 나서야 하는 사안이 있음**독신 고독 각방 주말부부 별리 사별의 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 먼저 나서지 말고 유화적 처신으로 사안을 연기시켜야만 유리함`,0,0,`역상적 사안으로 억울하더라도 절대 반발하거나 따지지 말고 유순유화적 언행과 처신으로 따르면서 일을 뒤로 미루어야 결과가 반드시 유리하게 될 人`,0,0,`앞으로 나아가야 할 일이나 물러남이 좋음**독신 고독 각방 주말부부 별리 사별의 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 뜻은 동해도 기회를 노리고 움직이지 않음`,0,0,`스스로 약하다고 여기고 자포자기하는 격**타의적으로 신체가 부자유한 경우도 있음 `,0,0,`자신만 믿음**토지가옥사 송쟁사 있음**몸이 부자유 함 `,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위절반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신`,`좌고우면`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`출성`,`악성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면`,`선성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사`,`기사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`허위`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-색정사有`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-가택고민`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-가택권리양도됨`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`이전의사有`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [1,1,2,`음12월`,3,3,6,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월`,3,2,5,`음9월-연기되거나파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,3,2,5,`음8월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월-연기 또는 파혼우려`,3,3,6,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음7월`,3,1,4,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음1월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,2,2,4,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음6월`,2,1,3,`음12월-연기또는파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음3월`,2,1,3,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음2월`,1,1,2,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음1월`,2,3,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집에 세서 초중반이 공허하고 종반에 겨우 표면적 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일 처리가 강하지 못한 듯해도 무난함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 초 중반에 재정을 확충하나 종반이 무실함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 원조를 받아 단합 결속하고 원만한 균형을 이룸 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있으나 사심이 많고 항상 금전을 우선함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하여  동요불안사로 재정파탄사를 야기함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 일 처리가 강하지 못하더라도 균형을 이룸 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯해도 상대를 잘 조정하는 능력이 있으나 중 종반이 공허 허사가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이는 경향이 있어 반목대립하고 내부를 무력시키며 종반에 허사무용 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 내부를 무용하게 하고 초반에도 실적이 없으며 실적이 없게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높아 균형을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`말에 불성실한 면이 많고 행위도 같으며 시종 동요배신 이심사를 조장하여 싪패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대협력`,`이행`,`선이후이`,`進    向`,`  근`,`留`,`무난`,`북북동`,``,`정남`],
    [`10.11`,``,`세력규합`,``,`느긋하게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`음9월集`,`필요함`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`집集`,`반대세력`,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,`극렬조력`,`이행`,`선난후난`,`退    背`,`  근`,`去`,`주인후원`,`동북`,``,`남남동`],
    [`7.8월`,``,`포기연기`,``,`포기요망`,``,``,``,``,``,``,``],
    [`10.11`,``,`음1,2,7,`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`8월再`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,``,`이행`,`선費후이`,`退     背`,`원근왕래`,`去`,`주인압박`,`정동`,``,`정남`],
    [`7.8월`,``,`반대有`,``,`본인무력`,``,``,``,``,``,``,``],
    [`10.11`,``,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,`이행`,`선費후난`,`進    向`,`  원`,`去留무관`,`주인압박`,`정서`,`남남서`,``],
    [`7.8월`,`집集`,`반대有`,``,`포기요망`,``,``,``,`결국세입`,``,``,``],
    [`10.11`,`다집多集`,`대세제압`,``,``,``,``,``,`동조결속`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`음5월集`,``,`이행`,`선난후이`,`進    向`,`  근`,`去`,`주인후원`,`남남동`,`서남`,``],
    [`7.8월`,``,`반대有`,``,`천천히`,`속행후퇴`,``,``,``,``,``,``],
    [`10.11`,``,`기세무력`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,`이행`,`선損후從`,`退    背`,`  원`,`留`,`주인이사`,`정남`,`정서`,``],
    [`7.8월`,``,`반대없음`,``,``,``,``,``,`결정참고`,``,``,``],
    [`10.11`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대有`,``,`선損후이`,`進     向`,`  근`,`去`,`주인피해`,`정북`,`동남`,``],
    [`7.8월`,``,`측근조력`,`이행`,``,``,``,``,`세입가해`,``,``,``],
    [`10.11`,``,`음1,2,7,`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`음3월集`,`8월再`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`집集`,``,`이행`,`선난후損`,`進     向    `,`  원`,`留`,`주인후원`,`북북동`,`북북동`,``],
    [`7.8월`,``,`반대有`,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,`대세제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`집集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`다집多集`,`반대전부`,`이행`,`선同후從`,`退    背`,`  원`,`留`,`세입피로`,`동북`,`동북`,``],
    [`7.8월`,``,`음1,2월 再`,``,`권리양도`,``,``,`관공직留`,`설기쇠약`,``,``,``],
    [`10.11`,``,``,``,`됨`,``,``,`일반인去`,`파재실종`,``,``,``],
    [`3.6\n9.12`,``,``,``,`포기요됨`,``,``,`권리양도`,`우려`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,`이행`,`상대사안 `,`進    向`,`  원`,`留`,`주인무력`,`정동`,``,`정동`],
    [`7.8월`,``,`반대有`,``,`무력허사`,`지구력要`,``,``,``,`세밀심사`,``,``],
    [`10.11`,`음11월集`,`기세무력`,``,``,``,``,``,``,`함`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`집集`,``,`불이행`,`상대사안 `,`退    背`,`원근왕래`,`留`,`주인무력`,`남남서`,``,`동남`],
    [`7.8월`,``,`반대有`,``,`무력허사`,``,``,`오나가나`,``,``,``,``],
    [`10.11`,``,`본인제압`,``,``,``,``,`마찬가지`,``,``,``,``],
    [`3.6\n9.12`,`다집多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,`반데전체`,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`안건주지`,`불이행`,`선이후난`,`전화위복`,`  근`,`去`,`무난`,`정북`,``,`남남동`],
    [`7.8월`,``,`협력역설`,``,`빠르게`,`기이요행`,``,``,``,``,``,``],
    [`10.11`,`음10월集`,`성의열변`,``,``,`유일기대`,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`제압됨`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,``,``,``,0,0],
    [``,``,``,0,``,``,``,0,0],
    [``,``,``,0,``,``,``,0,0],
    [`질병`,`전사귀`,` 전사`,0,``,`묘좌`,``,0,0],
    [``,``,``,0,``,`묘향`,``,0,0],
    [`생업`,``,``,0,``,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,``,``,0,``,`육친`,``,0,0],
    [`질병`,`제례귀`,`제례위범`,0,``,`묘좌`,``,0,0],
    [``,``,``,0,``,`묘향`,``,0,0],
    [`생업`,``,``,0,``,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,`제례귀`,`제례위범`,0,``,`육친`,``,0,0],
    [`질병`,``,``,0,``,`묘좌`,``,0,0],
    [``,``,``,0,``,`묘향`,``,0,0],
    [`생업`,`가장귀`,`호주사망`,"상가부정",`조문왕래`,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,``,``,0,``,`육친`,``,0,0],
    [`질병`,`전사귀`,`전사`,0,``,`묘좌`,`서남`,0,0],
    [``,``,``,0,``,`묘향`,`북서`,0,0],
    [`생업`,``,``,0,``,`전경`,`큰강도로`,0,0],
    [``,``,``,0,``,`묘속`,`水`,0,0],
    [`가정`,``,``,0,``,`육친`,``,0,0],
    [`질병`,`혈광귀`,`유혈사망`,0,``,`묘좌`,``,0,0],
    [``,``,``,0,``,`묘향`,``,0,0],
    [`생업`,``,``,0,``,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,``,``,"음사부정",`제사잘못`,`육친`,``,0,0],
    [`질병`,``,``,0,``,`묘좌`,``,0,0],
    [``,``,``,0,``,`묘향`,``,0,0],
    [`생업`,`변사귀`,`객사`,0,``,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,``,``,0,``,`육친`,``,0,0],
    [`질병`,`변사귀`,`객사`,0,``,`묘좌`,``,0,0],
    [``,``,``,0,``,`묘향`,``,0,0],
    [`생업`,``,``,"음사부정",`제사잘못`,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,``,``,0,``,`육친`,``,0,0],
    [`질병`,`제례귀`,`제례위범`,0,``,`묘좌`,``,0,0],
    [``,``,``,0,``,`묘향`,``,0,0],
    [`생업`,``,``,0,``,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,`제례귀`,`제례위범`,0,``,`육친`,``,0,0],
    [`질병`,`여귀`,`부인사망`,0,``,`묘좌`,``,0,0],
    [``,`변사귀`,`객사`,0,``,`묘향`,``,0,0],
    [`생업`,`여귀`,`부인사망`,0,``,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,``,``,"상가부정",`조문왕래`,`육친`,``,0,0],
    [`질병`,`음귀`,`기세없음`,0,``,`묘좌`,``,0,0],
    [``,``,``,0,``,`묘향`,``,0,0],
    [`생업`,``,``,0,``,`전경`,``,0,0],
    [``,``,``,0,``,`묘속`,``,0,0],
    [`가정`,``,``,0,``,`육친`,``,0,0],
    [`질병`,`음귀`,`산소탈`,0,``,`묘좌`,`북서`,0,0],
    [``,``,``,0,``,`묘향`,`동남`,0,0],
    [`생업`,``,``,0,``,`전경`,`香臺`,0,0],
    [``,``,``,0,``,`묘속`,`공혈`,0,0],
    [`가정`,``,``,0,``,`육친`,`자식`,0,0],
    [`질병`,`혈광귀`,`혈광사`,0,` 西`,`묘좌`,``,0,0],
    [``,`전사귀`,`전사`,0,` 불가`,`묘향`,``,0,0],
    [`생업`,``,``,0,` 바위`,`전경`,``,0,0],
    [``,``,``,0,`  石`,`묘속`,``,0,0],
    [`가정`,``,``,0,` 조모`,`육친`,``,0,0]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`상위기쁨-하위 수도자 격`,`전도**매사불성**활인진출하면자립함`,`도적관련 금전실재근심사`,`기이빈궁**전도**전화위복`,`신분이 차이나는 음란-존비상몽사`,`빈궁**관재**외교막힘**전화위복`,`느긋하고 한가함`,`외내 급속강제사**손해가출사우려**재액해소`,``,`가택이동사**질병사**동요분산**남녀부부상쟁`,`재난전소사`,`반궁**관재**위역`,`구설송사흉-문서소식 吉`,`빈궁`,``,`화합사**내외남녀동시 색정사有**사사완성`,`왕래사송계류사`,`관재**외사동요분산사`,``,`동요분산사`,`사통한자의 거짓모해사`,`빈궁**가정막힘**종업원 조력사`,`세속사는 침체경구(驚懼)사 연발됨`,`빈궁**병재**관재**매사불성**위역`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`외내사 출행이동원행사`,``,``,``,``,``,``,``,`재액해소`,``,`파재극처실종사우려`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``,``,``,`전화위복사`,`종교진출로성공함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`소인쟁재사`,`관재**매사불성**종교진출하면자립함`,`견련반복사`,`빈궁**전도**활인진출하면자립함`,``,`권리화합사**전도**부부반목**전화위복`,`불리 고시-없는 구설근심사`,`빈궁**관재**외교막힘**전화위복`,`시절을 놓쳤으면 퇴보가 유리함 `,`외내 강제급속손실사**손해가출사우려`,``,`가택이동사**질병사**동요분산**재액해소`,``,`비곡치욕사**관재`,`와병불기-구설사`,`빈궁`,`도적악의-공격하지 말아야 함`,`빈궁**관재**외내남녀동시색정사有`,``,`희경사`,`좋은 소식사`,`빈궁**병재`,`사통한자의 거짓모해사`,`빈궁**가정막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`외내사 출행이동원행사`,``,``,``,``,``,``,``,`남녀부부상쟁`,``,`파재극처실종사우려`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`사통한자의 거짓모해사`,`전도**가정막힘`,`소인쟁재사`,`관재**매사불성**종교진출하면자립함`,`견련반복사`,`매사불성**활인진출하면자립함`,``,`권리화합사**부부반목**재액해소**전화위복`,`불리 고시-없는 구설근심사`,`관재**외교막힘**전화위복`,`시저을 놓쳤으면 퇴보가 유리함`,`외내 강제급속손실사`,``,`가택이동사**질병사**동요분산`,``,`비곡치욕사**관재`,`와병불기-구설사`,`관재`,`도적악의-공격하지 말아야 함`,`관재**색외내남녀동시 색정사有`,``,`희경사`,`좋은소식사`,`병재**전도`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,`외내사 출행이동원행사`,``,``,``,``,``,`손해가출사우려`,``,`남녀부부상쟁`,``,`파재극처실종사우려`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`빈궁**병재**전도`,`외사동요분산사`,`事後쟁탈사**전도**가정막힘`,``,`빈궁**병재**관재**매사불성`,`전화위복사`,`빈궁**매사불성**활인진출하면자립함`,`사통한자의 거짓모해사`,`비상지애사**7,8,10,11월 부자`,``,`빈궁**관재**외교막힘**전화위복`,`밤에 당하는 는욕박해사`,`화합사**외내 급속사`,`환성경사`,`빈궁**관재**가정동요분산**이동사`,`문서헌책답신지체-구설사-이동사`,`빈궁**관재**위역`,`재화전소사`,`가택이동사**질병사**음12,45월 부자`,``,`외내남녀동시 색정사有`,`탄식신음-병 또는 事미수`,`빈궁**관재**외사동요분산사`,`觀書-문화풍아`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`도적실족-잡음`,``,``,`종교진출하면자립함`,``,``,`외내사 출행이동원행사`,`재액해소**전화위복`,``,``,``,`손해가출사우려`,``,`남녀부부상쟁`,``,`파재극처실종사우려`,``,``,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`빈궁**병재**전도**외사동요분산사`,`觀書-문화풍아`,`빈궁**병재**재액해소`,`외사동요분산사**도적실족-잡음`,`事後쟁탈사**빈궁**가정막힘**관재`,``,`병재**관재**전도**매사불성`,`전화위복사`,`병재**관재**전도**활인진출하면자립함`,`사통한자의 거짓모해사`,`비상지애사**음7,8,10,11월 부자**관재`,``,`빈궁**관재**전도**외교막힘**전화위복`,`밤에 당하는 능욕박해사`,`화합사**외내 급속사**관재`,`환성경사`,`빈궁**이동사**가정동요분산**남녀부부상쟁`,`문서헌책답신지체-구설사-이동사`,`빈궁**관재**전도**위역`,`재화전소사`,`가택이동사**질병사**침체`,``,`외내남녀동시 색정사有`,`탄식신음-병 또는 事미수`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`재액해소`,``,`종교진출하면자립함`,``,``,`외내사 출행이동원행사`,`전화위복`,``,``,``,`손해가출사우려`,``,``,``,`파재극처실종사우려`,``,``,`음4,5월화재주의`,``,`음4,5월화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`빈궁**외내남녀동시 색정사有`,`말 말고 근신해야 함`,`빈궁**병재**전도**외사동요분산사`,`투서헌책불량-구설사-금전실재사`,`화합사**관재**재액해소`,`혼인사`,`빈궁**관재**가정막힘**재액해소`,`형책처벌사`,`관재**전도**매사불성**동요불안분산사`,`소인쟁재사`,`빈궁**관재**매사불성**활인진출로자립함`,`사통한자의 거짓모해사`,`빈궁**병재**관재**전화위복`,`소식기다림-와도 나쁨`,`전도**외교막힘**전화위복`,`승진영전사`,`빈궁**급외내 금속사**손해가출사우려`,`도적을 쫒아갔으나 못잡은 격`,`빈궁****가정동요분산**이동사**남녀부부상쟁`,`사행불량`,`관재**전도`,`비곡치욕사`,`가택이동사**질병사`,`와병 불기사-구설놀램`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`음4,5월화재주의`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`종교진출하면자립함`,``,`동요불안분산사`,`외내사 출행이동원행사`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`가정동요**불안분산사`,`파재극처실종사우려`,`동요불안분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`가택이동사**질병사`,``,`빈궁**외내남녀동시 색정사有`,`말 말고 근심해야 함`,`빈궁**관재**외사동요분산사`,`투서헌책불량-구설사-금전실재사`,`화합사*혼인사`,``,`빈궁**가정막힘`,`형책처벌사`,`관재**매사불성**종교진출하면자립함`,`소인쟁재사`,`빈궁**관재**전도**매사불성`,`사통한자의 거짓모해사`,`빈궁**병재**전도**전화위복`,`소식기다림-와도 나쁨`,`외교막힘**전화위복`,`승진영전사`,`빈궁**급외내 급속사**재액해소`,`도적을 쫒아갔으나 못잡은 격`,`빈궁**관재**가정동요분산**이동사**재액해소`,`사행불량`,`비곡치욕사**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``,``,``,``,``,``,`활인진출하면자립함`,`외내사 출행이동원행사`,``,``,``,``,`손해가출사우려`,``,`남녀부부상쟁`,``,`파재극처실종사우려`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`관재`,`비곡치욕사`,`가택이동사**질병사**재액해소`,``,`빈궁**외내남녀동시 색정사有**재액해소`,`말 말고 근신해야 함`,`빈궁**관재**외사동요분산사`,`투서헌책불량-구설사-금전실재사`,`화합사**혼인사`,``,`빈궁**가정막힘`,`형책처벌사`,`관재**매사불성**종교진출하면자립함`,`소인쟁재사`,`빈궁**매사불성**활인진출하면자립함`,`사통한자의 거짓모해사`,`빈궁**병재**전화위복`,`소식기다림-와도 나쁨`,`승진영전사**외교막힘**전화위복`,``,`빈궁**관재**전도**외내 급속사`,`도적을 쫒아갔으나 못잡은 격`,`빈궁**전도**가정동요분산사**이동사`,`사행불량`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`파재극처실종사우려`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``,``,``,``,``,``,``,`외내사 출행이동원행사`,``,``,``,``,`손해가출사우려`,``,`남녀부부상쟁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`빈궁**이동사**가정동요분산사**남녀붑상쟁`,`사행불량`,`관재`,`비곡치욕사`,`가택이동사**질병사**음1,2,4,5월 부자`,``,`빈궁**외내남녀동시 색정사有`,`말 말고 근신해야 함`,`빈궁**관재**외사동요분산사`,`투서헌책불량-구설사-금전실재사`,`화합사**혼인사`,``,`빈궁**가정막힘`,`형책처벌사`,`관재**매사불성**종교진출하면자립함`,`소인쟁재사`,`빈궁**전도**매사불성**활인진출하면자립함`,`사통한자의 거짓모해사`,`빈궁**병재**전도**전화위복`,`소식기다림-와도 나쁨`,`승진영전사**외교막힘**전화위복`,``,`빈궁**강외내 급속사**재액해소`,`도적을 쫒아갔으나 못잡은 격`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`파재극처실종사우려`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``,``,``,``,``,``,``,`외내사 출행이동원행사`,``,``,``,``,`손해가출사우려`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`느긋하고 한가함`,`외내 급속사**재액해소`,``,`가택이동사**질병사**관재**가정동요분산`,`재화전소사`,`빈궁**관재**위역`,`구설송사 흉-문서소식 吉`,`빈궁`,``,`화합사**외내남녀동시 색정사有**사사완성`,`견련반복사`,`빈궁**관재**외사봉요분산사`,``,`동요분산사`,`사통한자의 거짓모해사`,`가정막힘**외사동요`,`전화위복사`,`빈궁**병재**전도**매사불성`,`상위기쁨-하위수도자 격`,`전도**활인진출하면자립함`,`도적관련 금전실재근심사`,`빈궁**전도**전화위복**부족`,`신분이 차이나는 음란-존비상몽사`,`빈궁**관재**외교막힘**전화위복**부족`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`손해가출사우려`,``,`남녀붑상쟁**재액해소`,``,`파재극처실종사우려`,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``,``,``,``,``,`종교진출하면자립함`,``,`외내사 출행이동원행사`,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`신분이 차이나는 음란-존비상몽사`,`빈궁**관재**재액해소**외교막힘**전화위복`,`느긋하고 한가함`,`외내급속사`,``,`가택이동사**질병사**동요분산사`,`재화전소사`,`빈궁**관재**재액해소**위역`,`구설송사 흉-문서소식 吉`,`빈궁**`,``,`화합사**외내남녀동시 색정사有**사사완성`,`견련반복사`,`빈궁**관재**외사동요분산사**재액해소`,``,`동요분산사`,`사통한자의 거짓모해사`,`가정막힘`,`전화위복사`,`빈궁**병재**관재**매사불성`,`전도**매사불성**폐한인**외내사 출행이동원행사`,`종교진출해당자**음3,6,9,12월 이날 이시간`,`도적관련급전실재근심사`,`빈궁**전도**활인진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`손해가출사우려`,``,`남녀부부상쟁`,``,`파재극처실종사우려`,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``,``,``,``,``,`종교진출하면자립함`,`상위기쁨-하위 수도자 격`,`방문자 종교진출로성공함`,``,`전화위복**부족`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`도적관련금전실재근심사`,`권리사**전화위복`,`신분이 차이나는 음란-존비상몽사`,`빈궁**관재**외교막힘**전화위복`,`느긋하고 한가함`,`외내 급속사**재액해소`,``,`가택이동사**질병사**분산동요**재액해소`,`재화전소사`,`빈궁**관재**위역`,`구설송사 흉-문서소식 吉`,`빈궁`,``,`화합사**외내남녀동시 색정사有**사사완성`,`견련반복사`,`빈궁**관재**외사동요분산사`,``,`동요분산사`,`사통한자의 거짓모해사`,`가정막힘`,`빈궁**병재**관재**매사불성`,`종교진출해당자**음3,6,9,12월 이날 이시간`,`전도**외내사 출행이동원행사`,`활인진출해당자**음7,8,10,11월 이날 이시간`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,`손해가출사우려`,``,`남녀부부상쟁`,``,`파재극처실종사우려`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,``,``,``,``,``,``,`전화위복사`,`방문자 종교진출로성공함`,`상위기쁨-하위 소도자 격`,`방문자 활인진출로성공함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■ 사방이 막혔으니 기다리는 수밖에 없겠어! 
땅이 온통 귀신이 되어송사가 붙었어! 
⊕전사귀ㆍ흉사귀ㆍ음귀, 
⊖음귀ㆍ혈광귀ㆍ전사귀가 모든 일을 가로막고 있어! 이것만 잠재우면 천우신조를 얻게 되겠어!
`,`■ 마음속에 천둥번개가 치고 있네? 
놀라지만 말고

⊕음귀, 
⊖전사귀만 잡으면 다 가라앉게 되어 있네!
`,`■ 호랑이 행세를 하는 
여우한테 겁먹고 있네?
입다물고 조용히 있는게 상책이야!
⊕음귀, 
⊖신불제사귀만 쫓아주면 흉은 없어져!
`,`■앞길이얽히고설켰네? 그런데다 간사사까지 끼어들고? 은닉(죄인)하는 일이 있겠어 !  기도밖에 없네!
男 : 관재가 보이네?
女 : 음식을 주의해야 해!
⊕신변상가부정침범 
⊖신변목기부정침범이 들었어!
`,`■ 일이 한두가지가 아니네?
확 바꾸면 큰 경사가 있겠어!
부족한 옛것을 과감히 버리고  새것으로 정비를 해야 해!
⊕ 전사귀, 
⊖ 변사귀 해소가 중요하고!
`,`■ 지금 물구덩이에 빠졌는데 계획이나 방침을 급히 변경시켜야만 되게 되어 있어! 
신입구출 방침이 좋고
⊕도로귀가택,음사부정 ⊖가택수망노부 여귀를 몰아내야 하고!
⊖신변상가부정침범이 들었어!
`,`■ 색정사로 배반당했네! 
그러나 남을 탓할 것도 없겠는데! 의지할데도 없고, 앞길은 분간도 안되고! 
집도 생업도 이전해야 하고!
자칫 호적 바꿀 일도 있겠어!
`,`■ 육친간에 등을 돌렸고 
   하극상 이별이 벌어지네 !
   의심질투도 가득하고 !
⊕변사귀, 
⊖신불제사귀해소가 필요하겠고, 그러면 전화위복이 되게 되어 있네?
⊕신변음사부정침범했네?
`,`■ 지금은 여건이 부족해도  새로 개혁시키면 여러 일들이 확 풀리겠네 ? 
그런데 ⊕ 신불제사귀, 
      ⊖ 음귀 장난 때문에 부부가 같이 바람이 났어 !
`,`■ 집 권리가 넘어가네?
땅이 내몸을 치는 원수가 되었어!
관재고심이 크겠네 !
⊕변사귀ㆍ여귀, 
⊖전사귀ㆍ도로귀가 독을 뿌리지만 오히려 치성으로 풀릴수가 있어!
⊕신변수망노부부정침범
⊖신변음사부정침범했네?
`,`■ 마음이 씁쓸하겠어!
   집이 비었어! 
내일은 내가 해결할 수밖에 없겠는데
⊕ 신불제사귀, ⊖ 음귀를 해소하고 기도하면 돈도 들어오겠어!
`,`■ 꽉 막혔네? 
   진퇴양난이야!

⊕ 가택목기부정, 
⊖가택상가부정이 들어서 그래! 
독수공방 신세라!`];
const YOGYEOL1 = [
  `■ 너와 내가 서로 단합되었으나 내쪽의 관살로 형상사가 있다. 낮과 밤의 병점은 처음은 위중해도 끝에는 가볍다.
`,`■ 현재의 직녹을 지켜라. 움직이면 상처를 입는다. 호시격이라고 속지말라. 낮정단은 화살에 단단한 촉이 있다.
`,`■ 간상이나 삼전에 쓸것이 없으니 총을 쏴봐야 맞지 않는다. 낮정단에 병이나 소송은 잘 풀리게 된다.
`,`■ 인택 모두 손상되니 사람은 허사이고 집은 파한다. 다행히 현무와 천후가 재물을 빼앗으니 상대는 나쁘고 나는 좋다.
`,`■ 낮정단은 천장이 삼전을 돕고 삼전은 또 나를 도와 좋다.
종혁격으로 변하니 여러사람의 도움을 얻는회환격이 다.
`,`■ 양 귀인이 집을 받드시 낮정단은 백호가 가택의 흉을 없앤다. 두 귀인을 자세히 보면 좋지만은 않아 밤정단은 편안하기 어렵다.
`,`■ 심택삼전 모두가 밤정단은 침범이 더욱 심하다. 보이는 것이 모두 뛰는 것들이니 집이 옮겨지거나 숨기는 일이 있게 된다.
`,`■ 금전ㆍ직위ㆍ명예ㆍ재산은 좋으나 모두 하극상을 받는다. 그러나 앞뒤에서 밀고 당기니 족히 액난은 피한다.
`,`■ 귀인과 재물이 심술을 부린다. 타인에게 의지하는 것도 안심이 안된다. 낮정단은 천장이 삼전을 생하고 삼전은 또 나를 도운다.
`,`■ 서로간에 합을 했으나 밤정단은 내 녹에 도적이 탔다. 지금이 작다고 욕심을 부리면 관재나 재앙이 오게 된다.
`,`■ 낮귀가 밤귀자리에 모여 관사나 셋방살이하기 쉽다. 서로 교섭하여 실망하고 헛것에 놀랄 것이다.
`,`■ 간지가 삼전과 연결되어 집 떠난 자는 곧 온다. 일은 가정을 떠나지 않고 도적은 근처에 숨었다.`
]
const SIBO1 = [`■ 자충수적 우환이 있다
독이 변해 약이 된다.
권리 상실사가 있게된다.
스스로 유약하여 재화도 가볍다. 중간에 좌절되나 凶을 凶으로 제어한다. 토지사로 관재가 발생하여 신음하나 근신하여 풀리게 된다.
상류인은 형통하다.
女子는 시집가기를 원한다.
`,`■ 外事 골육상배 동요兆
求官은 吉하다. 현직자는
오류로 편책을 당하게 된다.
매사 유리하고, 상대는 불리하다. 단, 현재․현실․구사를 지키라. 일이 역조가 되어 오히려 吉하게 된다. 폐지사를 부흥시키려 한다.
七月에 임신사가 있다.
`,`■ 家內 우환이 발생한다.
가정을 속이고, 가인이 원한
을 품고 加害 하려한다.
외사 골육 상배 동요의 兆
일이 多人의 취합하로 결과가 늦다. 불리하더라도 나중에 반드시 吉하게 되므로 조급하지 말라. 上人에게 의뢰하는 일이다. 가정에 사기(死氣)가 있다. 행상으로 이익이 있다.
⊖계모건 가정사가 있다.
`,`■ 자충수적 우환이 있다.
신음․탄식한다.
금전․여자가 문제이고
관재를 주의하라.
진퇴양난이다. 일이 안되고 있다. 덕행․적덕을 하라. 吉․凶의 갈림길이다. 특히 子․午․卯․酉띠는 불길하다.
은닉사가 있고 절도를 잃었다.
男 : 처가 임신했다.유산 주의.
`,`■ 女․妻가 헌신한다.
他人이 스스로 와서 돕게 된다.
구개신취 개선개혁사가 있다
개혁․변화를 꾀한다. 차근차근히 하라. 모든 일이 형통하게 된다. 
他人의 후원을 얻는다. 
추천사도 있게 된다. 
문장․증권등도 더욱 吉하다. 부부가 음탕하다. 
二事中 一得한다.
男 : 첩이 임신했다.유산 주의.
`,`■ 관재가 있다.
가내 우환이 발생한다.
凶을 凶으로 제어하여 人宅이 이익을 얻는다.
고로후에 얻게 된다. 
자손에 의해 大財가 들어온다. 계획ㆍ방침을 급히 변경해야만 한다. 가인이 원한을 품고 加害하려 한다.
`,`■ 자충수적 우환이 있다.
가택 이동ㆍ가출의 兆
⊖ 반복부정하다.
가정이 음사사로 동요ㆍ파란이 일었다. 후퇴하게 된다. 목적달성이 안된다. 上人의 조력도 없다. 친근자ㆍ기도ㆍ근신만이 凶을 해소시킨다. 때를 기다리라.
비난을 받고 있다.
`,`■ ⊖음사부정이 들었다.
외사 골육상배 동요이다.
음참사가 있다.
폐지사를 부흥시키려 한다. 
下剋上으로 上人이 불리하다. 육친이 빙탄관계에 있다. 
시비ㆍ구설에 시달린다. 시기ㆍ의심사가 기한다. 그러나 厄은 해소된다.
七月에 임신사가 있다.
`,`■ 가내 우환이 발생한다.
가인이 원한을 품고 加害
하려 한다.
두가지 일로 애증이 엇갈리고 있다. 일을 바꾸고 사람을 바꾸려 해도 따르지 않는다. 
夫婦가 共히 私情을 갖고 있다. 酒食으로 변이 된다.
男ㆍ夫가 헌신한다. 
다인다사, 구개신취 개혁 취합사이다.
`,`■ ⊕상가부정이 들었다.
관재가 있다.
권리상실사가 있게 된다.
자충수적 우환이 있다.
갱신하여 활동해야 한다. 
가택의 명의가 바뀐다. 
비로소 때가 왔다. 
凶을 凶으로 대응하면 凶이 소산한다. 
자구책이 제일이다. 
현재는 혼몽침체이다.
`,`■ 상류인은 기쁨이 있다.
형제의 凶이 기한다.
외교에 주력하라.
이외의 기쁨이 있게 된다. 단, 서둘지 말라. 
초에는 불성한다. 
자손에 의해 財가 들어온다. 그러나 자칫 이 財로 고생한다. 
주의할것.
신불기도가 효과 있다.
`,`■ ⊕목기부정 ⊖상가부정이
들었다.
모산하고 허비가 많다.
허실ㆍ허모ㆍ허독 財소모가 있다.
吉이 겹치나 이익이 박하다. 가정에서 일이 발단된다. 凶은 자연히 소산된다. 어떤 일을 애써 도모하지 말라. 
형제의 재액이 있게 된다.`]
const SIBO2_1 = [`■ 관송, 신변외사이다.
내방조짐 상의 : 
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 官･夫, 신변동요사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 官･夫, 신변동요사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 가택금전ㆍ여사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 금전ㆍ여, 가택내사이다.
내방조짐 상의 : 
조용히 있는게 이롭다.
자손 흉재 예방필요.
개신․개혁의 상이다.
`,`■ 자손, 가택동요사이다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 자손, 가택동요사이다.
내방조짐 상의 : 
도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
`,`■ 官･夫, 신변동요사이다.
내방조짐 상의 : 
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 사업, 가택내사이다.
내방조짐 상의 : 
은근하고 伏하라.
질병·관재 예방이 필요하다
`,`■ 官･夫, 가택내사이다.
내방조짐 상의 : 
은둔ㆍ도망ㆍ원행에 이롭다.
일의 모사 효과가 있다.
`,`■ 官･夫, 실종외사이다.
내방조짐 상의 : 
흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
`,`■ 손모ㆍ허실사, 吉兆이다.
내방조짐 상의 : 
원행·이동사가 있다.
어떤 일이든 처음 불성한다.
손재·녀하고 형제재액 발생`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [

  `/static/images/sagwa/010/010_001.png`,
  `/static/images/sagwa/010/010_002.png`,
  `/static/images/sagwa/010/010_003.png`,
  `/static/images/sagwa/010/010_004.png`,
  `/static/images/sagwa/010/010_005.png`,
  `/static/images/sagwa/010/010_006.png`,
  `/static/images/sagwa/010/010_007.png`,
  `/static/images/sagwa/010/010_008.png`,
  `/static/images/sagwa/010/010_009.png`,
  `/static/images/sagwa/010/010_010.png`,
  `/static/images/sagwa/010/010_011.png`,
  `/static/images/sagwa/010/010_012.png`,

]

const HEASYEOL = [
  // 1
  `  이 과는 12궁 본위에 있어 복음과(伏吟課)로 된다. 일간상신에서 극을 받아 축(丑)을 초전으로 하며 축술미(丑戌未)의 삼전 토(土)만은 가색격(稼穡格)으로 된다.
  계(癸)의 기궁(寄宮) 축(丑)에서 축(丑)을 보면 화개(華蓋)로 되어 초전이 된다. 중전의 술(戌)은 양호(兩虎)가 승하여 병단은 극히 흉하다. 초전 축(丑)은 말전에서 충(冲)하여 묘(墓)일지라도 개고(開庫)이다. 음일(陰日) 복음은 자신격(自信格)이다. 자신이라는 것은 스스로 유약하다고 자각하기 때문에 흉을 만나도 경미하다. 중전의 공망은 요절공망(腰折空亡)이라고 하여 어떤 일도 중도에서 좌절한다. 그러나 백호 관귀효(官鬼爻)가 공망하고, 더구나 토(土)의 중귀(衆鬼)가 일지상신 중금(重金)에 설(洩)하며, 그 노(怒)를 설(洩)하여 재(災)를 면한다. 순기(旬奇)의 발용은 상하가 상생하고 인택이 함께 서로 왕성하여 길조로 변화한다.
  백법에 말하기를 ｢삼전에 따라 택(宅)의 금(金)을 생하므로 가택이 넓어도 일간을 극하여 사람이 쇠퇴한다. 서로 생비(生比)하고 함께 상생하는 것은 어떤 일도 유익하다｣고 하였다. 비전에 말하기를 ｢삼전이 상형상충(相刑相冲)하면 흉으로써 흉을 제어하고 독이 변하여 약으로 되며, 적(賊)은 자수(自首)한다. 일귀(日鬼)가 용신(用神)으로 되면 관직(官職)은 광휘(光輝)가 있고, 복음과(伏吟課)로서 정마(丁馬)를 보지 않으면 정(靜)하여 부동(不動)의 상이다｣고 하였다.
  일귀(日鬼)는 역행하여 삼전이 구(勾)․백(白)․음(陰)이고, 야귀는 순행하여 삼전이 음(陰)․백(白)․구(勾)이며 어느 것이나 흉장(凶將)이다. 중전만이 홀로 공망되는 것은 단교(斷橋)라고 부르며 어떤 일도 진퇴(進退)가 있어 성공하기 어렵다. 대인(待人)은 중도에서 저애(阻碍)가 생겨 오지 않는다. 질병은 복(腹) 중의 허증(虛症)의 질환이다. 새로운 질병은 치료되어도 오랜 병은 사액(死厄)이 있다. 귀신(貴神)이 묘(卯) 또는 유(酉)에 승하면 여덕격(勵德格)이다. 야귀는 묘(卯)에 승하여 상인(常人)은 흉이 되지만 상류 계급의 사람은 형통(亨通)한다. 간지의 음신(陰神)에 만약 귀신의 앞에 섰다면 소인은 세(勢)를 의지하여 건장하게 되고, 양신(陽神)은 귀신(貴神)의 후에 섰다고 칭하며 군자는 겸양(謙讓)하다.`
  ,
  // 2
  `  이 과는 4과중에 상하의 극이 없고 제4과 미(未)에서 일간을 요극하는 호시과(蒿矢課)로서 퇴여격(退茹格)이다. 일간 계(癸)에서 자(子)는 건록이 된다. 일상의 건록은 이것을 지키는 것이 좋다. 함부로 동할 때는 초전 미(未)에서 극을 받는다. 중전 미(未)의 화기(火氣)는 초전의 귀(鬼)를 생하여 돕는다.
  요극과(遙剋課)로서 지(支)의 음신이 초전으로 되면 길흉이 모두 힘이 미약하다. 모든 일은 우리쪽에 이익이 있고 상대방에는 이롭지 않다. 작은 일은 유익하고 큰 일은 무익하며, 모든 일은 후에 동(動)하는 것이 유익하고 선발(先發)하는 것은 불리하게 끝난다. 재(災)는 내부에서 발생하고 주의하고 신중해야 한다. 일간 상신의 녹왕(祿旺)은 길과(吉課)일지라도 초전과 육해로 되며, 중전과 충(冲)하여 동요가 생기고 완전하지는 못하다. 관(官)을 정단할 때는 관록을 얻어 대단히 길하다. 현직의 사람은 오류를 초래하여 편책된다.
  백법에 말하기를 ｢왕록(旺祿)이 신(身)에 임하는 것은 현실을 지키는 것이 길하고 망동은 불길하다. 서로 왕성하여 모두 왕으로 되면 앉아서 계획하는 것이 좋다｣고 하였다. 비전에 말하기를 ｢태음이 승하여 일간을 극할 때는 간사(姦私)의 사건이 발생하고 결국에는 부인의 쟁송(爭訟)을 일으킨다. 일지의 상하에서 일간을 함께 생하면 여자는 시집가는 것을 바란다. 일간상의 건록은 관(官)을 극하기 때문에 현임 관리는 벌칙을 범하고 또는 퇴직의 우려가 있다｣고 하였다.
  일귀는 역행하여 삼전이 음(陰)․후(后)․귀(貴)로서 길장(吉將)이고, 야귀는 순행하여 삼전은 구(勾)․합(合)․주(朱)이다. 이 과는 만사가 구태(舊態)를 지키고 일상 충실하게 근무하며 함부로 망동하지 않는 것이 좋다. 진퇴연여(進退連茹)는 어느 것에서나 길흉이 겹쳐 발생한다. 퇴연여(退連茹)는 어떤 일도 역조(逆兆)로 되어 이익이 있으며 전진하는 것은 무익하다.`
  ,
  // 3
  `  이 과는 4과 중에 상하의 극적(剋賊)이 없고 제3과 상신과 제4과 상신에서 일간의 상극이 있다. 먼저 극한 제3과의 미(未)를 채용하여 요극과로 한다. 천신(天神)에서 일간을 극하는 것은 호시격(蒿矢格)이다.
  삼전이 미사묘(未巳卯)로서 퇴간전(退間傳)으로 되고 일지상신이 발용(發用)으로 되면 먼저 동(動)하지 말아야 한다. 때를 기다리는 것이 좋다. 먼저 소모되고 불리하더라도 후에는 반드시 보상(補償)을 얻게 된다. 말전 묘(卯)가 사(巳)에 가해져 천지(天地)에 양귀(兩貴)가 승하기 때문에 주사(主事)는 양귀와 관계되는 것이다.
  삼전 미사묘(未巳卯)는 회명(回明)이라고 부른다. 미(未)는 일음(一陰)이고 사묘(巳卯)는 이양(二陽)으로서 음에 의해 양에 이르고, 월광(月光)은 점차 원월(圓月)에 향하는 상으로 되어 명(命)으로 돌아온다. 어떤 일도 집단회결(集團會結)하므로 일을 급하게는 이루지 못하고 천천히 진행하는 것이 길히다. 오랜 비는 개인다. 길사는 점차 이루어지고 흉사는 점차 소산된다.
  백법에 말하기를 ｢일간상신 해(亥)의 공망에 천공(天空)이 승하여 공중공(空中空)｣을 만나면 어떤 일도 추구하지 말아야 한다. 수일(水日)에 정재(丁財)가 동(動)하는 격에 해당한다. 일야(一夜)의 귀신(貴神)이 동소(同所)에 가하여 주사(主事)는 양귀(兩貴)에 의뢰한다｣고 하였다. 비전에 말하기를 ｢사기(死氣)가 일귀(日貴)를 만들고 태상(太常)이 승하여 일지상신이 택(宅)에 들어가면 가정에 상사(喪事)가 있다. 미유(未酉)에 가하는 것은 계모(繼母)의 건이다｣고 하였다.
  미(未)에 태상이 승하는 것은 행상(行商)에서 이익을 얻는다. 일귀는 역행하여 삼전이 음(陰)․귀(貴)․주(朱)이며, 야귀는 삼전이 상(常)․음(陰)․귀(貴)에 해당하여 어느 것이나 천장(天將)은 길신이다. 태상이 천장(天將)의 길신이나 태상이 일귀(日鬼)를 구성하면 처의 병단은 반드시 사망한다.`
  ,
  // 4
  `  이 과는 이상에서 이하를 극하여 섭해과(涉害課)이다. 지반 맹신(孟神)의 극을 취하고 맹신이 없을 때는 중신(仲神)의 극을 취하기 때문에 제3과의 오유(午酉)를 초전으로 한다. 자오묘유일(子午卯酉日) 또는 점시(占時)로서 삼전 중신(仲神)은 삼교격(三交格)이라고 부른다.
  오(午)가 유(酉)에 가해져 사교(死交)로 되고, 어떤 일도 되지 않고 진퇴양난이다. 정단하는 사람은 반드시 덕행(德行)해야 한다. 길흉은 적덕(積德)의 여하에 따른다. 일간상신 술(戌)은 간(干)을 손상 한다. 즉, 사람이 손상한다. 일지상신의 오(午)는 일지 유(酉)를 극하여 가택을 손상시킨다. 그러나 술(戌)의 공망을 만나 흉사는 실현되지 않는다. 초전은 처재(妻財)이지만 천장 현무․천후 어느 것이나 수장(水將)에 의해 극을 당하여 협극(夾剋)으로 되고 내몸의 재(財)가 안되므로 자유를 얻지 못한다.
  백법에 말하기를 ｢간상 묘(墓)에 호(虎)가 승하면 병을 정단하지 말아야 한다. 일간일지가 함께 극을 받는 것은 양귀(兩鬼)를 방지해야 한다｣고 하였다. 비전에 말하기를 ｢백호․귀효(鬼爻)가 일간상에 있지만 공망하기 때문에 목전에 재(災)가 있어도 후에는 오히려 두려울 일이 없다. 관직에 있는 사람은 부임이 아주 빠르다.
  인월(寅月)․신월(申月)은 초전의 오(午)가 천마(天馬)에 해당된 묘(卯)는 천차(天車)라고 하며 고개승헌(高蓋乘軒)의 상으로 되어 구직(求職)․현직(現職)이 함께 승진하는 기쁨이 있으며, 명리(名利)․공명(功名)이 함께 달성된다. 일귀는 역행하여 삼전이 후(后)․주(朱)․청(靑)․길장(吉將)이다. 야귀는 삼전이 현(玄)․귀(貴)․합(合) 역시 길장이다.
  삼교격(三交格)이란 사중일(四仲日)에 지반 사중(四仲)에 중신(仲神)이 기하여 초전으로 되고 일교(一交)로 되며, 사중이 상극하여 초전으로 되고 이교(二交)로 된다. 삼전 중에 천후(天后)․태음(太陰)․육합(六合)이 승하는 것은 어느 것이나 중장(仲將)으로 되어 삼교(三交)라고 부른다. 은닉(隱匿)하여 명확하지 않고 절도(節度)를 잃어 저해가 발생하며, 어떤 일도 타인으로 인하여 파해(破害)를 만난다. 부모와 자식이 서로 방조하지 않고 사물의 진퇴(進退)도 역시 가능하지 못하게 된다. 대체로 화호(和好)의 상을 얻지 못하고 삼전이 형왕(刑旺)으로 되어 덕(德)은 쇠퇴한다. 과전년명(課傳年命)이 모두 사중(四仲)을 만나는 것은 모든 일이 불길하다. 정(靜)하는 것이 유익하고 망동하면 불리하다.`
  ,
  // 5
  `  이 과는 사유(巳酉)의 상극을 초전으로 하는 원수불비격(元首不備格)이며 사유축(巳酉丑)의 삼전은 종혁격(從革格)을 구성한다.
  사과삼전이 모두 삼합회국(三合會局)으로 되면 화미격(和美格)이라고 부르며, 상하내외(上下內外)에 경사가 있고 교역(交易)은 크게 통한다. 삼전이 체생(遞生)하여 사람의 추천을 받는 일덕(日德)이 재상(財上)에 승하여 타인의 원조와 후원을 받는다. 종혁격은 먼저 따르고 후에 혁신하여 조용히 때를 기다려 동하는 것이 좋다. 음(陰)이 극(極)하면 양(陽)이 일어나며, 중금(衆金)은 일간의 계수(癸水)를 생하여 백사길창(百事吉昌)의 과격(課格)이다.
  백법에 말하기를 ｢일지가 일간상신에 와서 일간을 생하므로 나는 그를 구(求)하고 그가 와서 생조(生助)한다. 삼전이 체생(遞生)하여 사람의 추천을 받게 된다｣고 하였다. 비전에 말하기를 ｢일귀(日貴)를 사용하면 귀신(貴神) 일덕(日德)이 초전으로 되고 사년(巳年) 또는 사(巳)의 월장(月將)의 경우는 용덕격(龍德格)으로 된다. 청(靑)이 행하여 자우(慈雨)가 있으니 만물에 미치고 또 군은(君恩)은 하(下)에 미칠 징조이다｣고 하였다. 일귀는 역행하여 삼전이 귀(貴)․구(勾)․상(常)이고, 천장(天將)이 어느 것이나 토신(土神)으로서 금국(金局)을 생하며, 문장(文章)․증권(證券)의 일은 더욱 묘하게 된다. 야귀는 삼전이 음(陰)․주(朱)․공(空)이다. 사과삼전이 진하여 동일한 회국(會局)은 어느 것이나 불비(不備)로 된다. 그러나 이와 같은 불비격은 극히 경미하다. 일은 2건을 정단하여 하나를 얻거나 길 중에 불길이 조금 있는 정도이다.`
  ,
  // 6
  `  이 과는 4과 중에 제2과와 제4과의 하(下)에서 상(上)을 극하여 섭해과(涉害課)로 된다. 맹상신(孟上神)을 초전으로 하는 것은 견기격(見機格)이다. 삼전 묘술사(卯戌巳)는 단륜격(斷輪格)이다.
  예를 들면 묘(卯)의 장간(藏干)이 을(乙)의 목(木)에 있고 신(申)의 장간이 경(庚)에 있어, 상하가 간합(干合)하여 기기(器機)를 구성한다. 묘(卯)와 사(巳)에 귀신(貴神)이 있고, 일지상신 진(辰)을 끼고 양귀(兩貴)가 공택(拱宅)으로 되며, 일지상신 진(辰)은 귀묘(鬼墓)로 되지만 중전 술(戌)에 의해 충개(冲開)하므로 흉으로 흉을 제어하여 자연히 흉은 흩어진다. 일간일지상신이 각각 생을 받아 인택(人宅)이 함께 이익(利益)을 받는다. 묘(卯)는 주차(舟車)로서 계일(癸日)에 주차를 운전하여 책임이 중대하며 처음은 곤란하다. 고생을 맛본 후에 뜻을 얻는다. 그러나 중전 술(戌)이 공망에 해당되기 때문에 꽃이 피어도 과실을 맺지 못하는 상이다.
  백법에 말하기를 ｢호생(互生)하면 만사가 유익하다. 천장(天將)의 내전(內戰)은 계획하여 위험하다｣고 하였다. 비전에 말하기를 ｢중․말전․재관(財官)이 함께 공망하고 귀신 공묘(供墓)는 공명․구직을 정단하면 시작은 있고 끝은 없다｣고 하였다. 초전 묘(卯)에 정신(丁神)이 승하여 즉 수일(水日) 정신이 재(財)로 동하며 자손(子孫) 하나의 재로 되면 자손에 의해 대재를 얻을 희망이 있다.
  단륜격(斷輪格)은 공명을 관점하여 길조가 있어도 중전․말전이 공망하면 관(官)을 얻을 시기에 이르지 못한다. 년명상신(年命上神)에 술(戌)이 승하면 공론(空論)이 해제된다. 일귀는 역행하여 삼전이 주(朱)․백(白)․귀(貴)이고 야귀는 삼전이 귀(貴)․청(靑)․음(陰)이다. 견기격(見機格)은 맹신상(孟神上)을 채용한다. 맹(孟)은 사계(四季)의 수(首)이고 일기(一期)의 기후는 이미 배태(胚胎)하기 때문에 일의 처음에 발생한다. 과복(過福)이 숨는 기회를 신중하게 보고 어떤 일도 급히 개변해야 한다. 낡은 것을 지킬 때는 삽체(澁滯)가 있어 일을 이루는데는 채산(採算)하여 이기고 무계획은 실패한다. 모두 처음은 곤란하여도 후에는 쉽다. 일지상신이 괴강(魁罡)을 승하기 때문에 관사(官事)가 발생한다.`
  ,
  // 7
  `  이 과는 십이궁이 각각 천지가 상충(相冲)하여 사과 중 삼전이 상하의 극이 있는 반음과(返吟課)이다. 섭해과(涉害課)로서 의지할 곳 없는 과격(課格)이다.
  묘는 정신(丁神)으로 되고 일지상신 및 초전․말전에 정신(丁神)이 승하여 수일(水日)과 정재(丁財)는 재(財)의 움직임이 있고 상당히 크다. 일지상신은 가정에 동요가 있고, 수생목(水生木)으로 자손효에 의해 재(財)가 동한다. 믿을 곳없는 격에 가하여 사중(四仲)이고 삼전은 삼교격(三交格)으로서 묘(卯)에 귀신(貴神)이 승하여 여덕격(勵德格)으로 된다. 왕래가 비상하며 동이 많으면 정(靜)한 것을 바라며, 혹은 오래 정(靜)하면 동하는 일을 생각한다. 보통 반복 왕래하며 화기(和氣)를 잃어 어떤 일도 목적을 달성하기 어렵다.
  년명이 묘유(卯酉)에 설 때는 용전(龍戰)의 상으로 하나를 생하면 하나는 극살(剋殺)하므로 진퇴가 모두 부정(不定)하다. 그러므로 동하는 일은 후회할 일이 생기며 조용하게 때를 기다리는 방침을 쓰면 재해(災害)나 비난받는 일이 발생하지 않는다.
  백법에 말하기를 ｢양귀신(兩貴神)이 극을 받는 것은 귀인․어른의 힘을 얻지 못한다. 귀(鬼)를 제어하는 위치에서 양의(良醫)를 구한다｣고 하였다. 비전에 말하기를 ｢일간상신이 귀(鬼)를 만나도 일간상신의 탈기(脫氣)는 구신(救神)으로 된다｣고 하였다. 즉 친한 사람의 도움을 받거나 가인(家人) 또는 집에 수호신(守護神)에 있어서 흉을 해제하게 된다.
  일귀는 순행하여 삼전이 음(陰)․구(勾)․음(陰)이고, 야귀는 역행하여 삼전이 귀(貴)․공(空)․귀(貴)로서 공명을 바랄 때는 활동하는 것이 좋다. 병의 정단은 가장 흉과이다. 정재(丁財)가 많아서 대처자(帶妻者)는 처를 극하며, 처가 없는 자는 구혼(求婚)하면 처를 취할 수 있다.`
  ,
  // 8
  `  이 과는 4과 중에 모두 상하의 극뿐이다. 원래 극이 많을 때는 먼저 하에 의해 상을 적(賊)하는 것을 채용하는 것이 삼전 연식(連式)의 원칙이다. 그러므로 음양의 극이 겹쳐 있을 때는 양일(陽日)은 양의 극을 취하고 음일은 음의 극을 사용한다. 이것을 양일양비(陽日陽比), 음일음비(陰日陰比)를 쓴다고 칭한다.
  이 과는 음양의 상하에 상극이 있고 음비를 쓰는 것을 입법(立法)으로 하는 지일과(知一課)이다. 초전의 미(未)는 일귀(日鬼)이다. 중전의 자(子)는 일록(日錄)으로 되고 사(巳)는 미(未)에 임하여 처재(妻財)로 되며, 일덕(日德)은 자(子)에 임한다. 삼전이 모두 하에서 상을 적(賊)하고, 계(癸)의 기궁(寄宮) 축(丑)은 말전 자(子)의 후에 있으며, 초전 인(寅)은 축(丑)의 앞에 있어서 축(丑)을 낀다. 이와 같은 것을 전인후종(前引後從)이라고 하는데 일간은 삼전의 중심에 위치하기 때문에 삼적(三賊)의 액(厄)을 해제한다.
  또 춘월(春月)에 정단하면 팔둔오복격(八遯五福格)이 된다. 팔둔격은 우위(憂危)를 즉석에서 만나고 오복(五福)은 복록(福祿)이 나란히 모인다. 팔(八)은 음수(陰數)이고 악신(惡神)․흉장(凶將)․일진(日辰)의 극적으로 형해(刑害)를 병하면 음참(陰慘)의 사건이 발생한다. 오복 즉 오(五)는 천지중용(天地中庸)의 수로서 양명(陽明)의 상으로 삼전이 생왕(生旺)하고 귀신(貴神)․일덕(日德)이 있어 흉격일지라도 구조(救助)를 형성한다. 
  4과 중에 삼하(三下)와 상을 적(賊)하는 것은 장도액(長度厄)이라고 하여 존장에게 불리하다. 초전은 중전을 극하고 중전은 말전을 극하여 체극(遞剋)으로 된다. 관리는 흉이 없어도 상인(常人)은 시비․구설을 면하지 못하거나 흉조가 있다.
  일귀는 순행하여 삼전이 주(朱)․백(白)․귀(貴)이고, 야귀는 역행하여 삼전이 상(常)․합(合)․음(陰)이다. 흉을 구성하지 않더라도 귀신(貴神)이 술진(戌辰)의 옥지(獄地)에 있으며 현무(玄武) 등사(螣蛇)의 흉신이 택상(宅上)에 있으니 실탈과 도적을 방지해야 한다. 백법에 말하기를 ｢전인후종(前引後從)은 승진의 기쁨이 있고, 피아가 시의(猜疑)하는 것은 육해(六害)가 있으며, 천장(天將)이 내전(內戰)하면 액난을 초래한다.｣`
  ,
  // 9
  `  이 과는 제1과․제2과에서 2하(二下)는 상을 적한다. 어느 것이나 음(陰)만의 극이 되면 비용중심(比用重審)은 쓰기 어려우므로 섭해과(涉害課)로 되고, 사맹(四孟) 사(巳)의 상신(上神) 유(酉)를 써서 초전으로 한다. 
  삼전은 사유축(巳酉丑)의 종혁격(從革格)으로 된다. 사과삼전이 모두 삼합금국(三合金局)이 되면 삼전은 사과상신만으로 구성하고 이것을 반주격(盤珠格)이라고 한다. 또 불비격(不備格)으로 되어도 이것은 경미하다. 섭(涉)이란 건넌다는 뜻이며, 해(害)라는 것은 극을 말한다. 극해(剋害)의 심천(深淺)을 지반(地盤)에 의해 많이 건넌 것을 초전으로 하는 것이 원칙이다. 삼전이 사과상신(四課上神)을 벗어나지 않은 것을 회환(廻環)이라고 부른다. 삼전 금국(金局)은 일간을 생하고 기쁨은 대중으로부터 유정(有情)을 받고 복을 맞아 우려는 스스로 소산된다. 삼합(三合)하는 것은 주사(主事)가 양단(兩端)을 다스린다.
  사과가 불비(不備)로 되어 일간일지가 교차상극(交車相剋)되며 사물은 바꿔서 이것에 따르지 않는다. 따라서 후에 개혁(改革)하며 길흉이 모두 일사(一事)가 가고 다시 돌아온다. 말전의 재성(財星)은 일간상신에 임하고 귀신(貴神)이 승하여 노력 활동하여 재(財)를 얻는다. 둔간하여 사(巳)의 귀(鬼)로 되기 때문에 심술(心術)을 따라서 일을 도모하는 것이 좋다. 삼전의 천장(天將)은 천신(天神)을 생비(生比)하여 흉이 있어도 길로 변화한다.
  백법에 말하기를 ｢부부는 음(淫)에 홀러서 각각 사정(私情)을 품고 삼전의 체생(遞生)은 타인의 추천을 얻는다. 전묘(傳墓)는 묘(墓)에 들어가 애증(愛憎)을 나눈다｣고 하였다. 비전에 말하기를 ｢일상신(日上神)이 재(財)로 되고 순둔(旬遁)하여 간귀(干鬼)에 해당되면 재(財)로 인해 화를 초래하거나 음식에 의해 몸을 손상할 우려가 있으며, 혹은 부인으로 인하여 분쟁이 발생한다｣고 하였다.`
  ,
  // 10
  `  이 과는 4과 중에 제1과 진계(辰癸)가 상에서 하를 극하여 원수가색격(元首稼穡格)으로 한다. 일간상신 진(辰)을 초전으로 하면 참관격(斬關格)이다. 일간상신이 묘신(墓神)에 복하여 사물은 혼몽침체(昏夢沈滯)가 있다. 진(辰)과 진(辰)은 자형(自刑)이고, 중전은 일지상신 자(子)와 육해로 되며 말전이 공망하고 진술(辰戌)이 충(冲)하여 몽매(蒙昧)에서 일극(一隙)의 광(光)을 본다.
  체(滯)는 일로(一路) 통하는 희망이 있고 정단사는 갱신외출(更新外出)의 방침을 취하면 길로 된다. 흉은 스스로 노력에 의하여 해제하려 하면 자연히 해산된다. 일간일지는 교차지합(交車支合)으로 되고 교섭사(交涉事)는 성립된다. 삼전 진(辰)은 봄의 말(末)이고 미(未)는 여름의 끝이며 술(戌)은 가을의 끝이다. 이를 일러 사계(四季)가 서로 전한다고 부르며 순(順)을 이룬다. 토(土)는 만물을 생하고 진(辰)이 축(丑)에 가해져 토(土)의 기(氣)를 열어 생생한 뜻이 처음으로 동하는 징조라고 간점(看占)한다. 초전 진(辰)은 일간의 귀묘(鬼墓)로 되고 둔간하여 무(戊)의 암귀(暗鬼)가 있다.
  백법에 말하기를 ｢일지상신(日支上神)이 건록(建祿)을 만나면 권섭부정(權攝不正)이며, 간묘(干墓)가 병관(倂關)하는 것은 인택이 함께 폐(廢)한다｣고 하였다. 비전에 말하기를 ｢묘신(墓神)이 일(日)에 복하고 사신(蛇神)이 승하여도 다행히 말전의 술(戌)은 백호가 승하여 진(辰)을 충(冲)한다. 이와 같은 것은 파묘충귀(破墓冲鬼)로 되고 흉으로써 흉을 소산(消散)한다｣고 하였다. 즉 독이 변하여 약효를 이루는 상이다.
  삼전이 순수한 관귀(官鬼)로 되면 관공직 또는 봉급 생활을 하는 자의 구직은 목적을 달성한다. 이것은 한가지 일이 아니고 겹쳐서 발생한다. 일귀는 순행하여 삼전이 후(后)․주(朱)․청(靑)의 길장이고 청룡이 공망한다. 야귀는 삼전이 사(蛇)․구(勾)․백(白)으로 천장은 어느 것이나 흉장이다. 병은 사병(死病)으로 되고 소송은 형벌(刑罰)을 당한다.`
  ,
  // 11
  `  이 과는 4과 중에 축해(丑亥)가 일상에서 일하를 극하고 축해(丑亥)가 초전으로 되어 원수과이다. 삼전 축묘사(丑卯巳)는 순간전격(順間傳格)이다. 축(丑)은 야반(夜半)이고 묘(卯)는 일출시(日出時)이며, 사(巳)는 태양이 점차 떠오른다. 삼전이 음으로부터 양으로 향해 진행한다. 또 묘(卯)는 가정이고 집을 나와서 활동에 들어가기 때문에 출호(出戶)라고 부른다. 사람을 방문하여도 집에 없으며 여행은 출발한다. 대인(待人)은 오고, 모든 일은 활약하여 유리하다. 상․중류 계급의 사람은 기쁨을 만난다.
  초전은 순기(旬奇)로서 의외의 경사를 만난다. 그러나 초전이 공망의 지(地)를 만나기 때문에 공허로 끝나는 경우가 있다. 조용히 시기를 기다리면 길사가 돌아온다. 일간일지는 어느 것이나 상신(上神)에 탈기(脫氣)한다. 천공(天空)․태음(太陰)이 승하는 것은 소모․출재(出財)가 있거나 타인에게 사취(詐取)당한다. 그러나 공망하여 피해는 경미하다. 또 초전에 의해 일간을 극하여도 귀살(鬼殺)이 공망의 지(地)에 있고 아울러 일간상신에서 제복(制伏)되어 힘이 없으니 해(害)를 당하지 않는다.
  중전 묘(卯)는 정재(丁財)이다. 백법에 말하기를 ｢수일(水日)이 정재(丁財)를 만나 재동(財動)이 있고 인택(人宅)이 함께 탈기로 되면 도난 실탈(失脫)이 있다｣고 하였다. 일귀는 순행하여 삼전이 상(常)․음(陰)․귀(貴)이며, 귀신(貴神)은 묘(卯)에 승하기 때문에 때를 기다리는 것이 좋다. 야귀는 삼전이 음(陰)․귀(貴)․주(朱)이고 천장은 어느 것이나 모두 길장(吉將)이다. 재(財)를 구하는 것은 나쁘다. 재(財)는 귀(鬼)로 화하기 때문에 재(財)를 얻어 고생한다.`
  ,
  // 12
  `  이 과는 4과 중 제4과의 해술(亥戌)의 상극을 초전으로 하고, 일하가 일상을 적(賊)하여 중심과(重審課)로 된다. 삼전의 해자축(亥子丑)과 진여격(進茹格)은 4과를 용신(用神)으로 하는 것은 맥월격(驀越格)으로 된다.
  순의(旬儀)가 중전에 들어가고 지의(支儀)는 4과 중에 있어 만사는 화동(和同)하고 길사가 겹친다. 간상이 탈기로 되고 지상은 술(戌)이 공망하여 이익이 박하다. 간상의 인(寅)은 말전 축(丑)의 앞에 해당하고 지상의 술(戌)의 앞이 초전의 해(亥)에 해당하기 때문에 모두 가까운데 속하여 어떤 일도 가정에서 발생한다. 일간상신에 현무가 승하고 일지 상신에 백호가 승하여 인묘(寅卯)의 목(木)으로 제어한다. 그러므로 재병(災病)의 해가 없다. 정단 사건의 대부분은 길조로서 흉도 자연히 해산된다.
  백법에 말하기를 ｢나망(羅網)은 모사(謀事)하면 졸책을 초래하고 공상공(空上空)에 만나는 것은 사물을 추구하지 말라｣고 하였다. 비전에 말하기를 ｢간상이 탈기로 되고 현무가 승하는 것은 탈도격(脫盜格)이라고 부르며 도난․사기․손모에 주의해야 한다. 과전 중에 삼기(三奇)․육의(六儀)를 만나면 구신(救神)의 작용을 하여 모든 흉재를 해제한다｣고 하였다.
  일귀는 순행하여 삼전이 공(空)․백(白)․상(常)이고, 야귀는 삼전이 상(常)․현(玄)․음(陰)으로 천장(天將)의 길흉이 상반(相半)한다. 태상(太常)이 축(丑)의 관귀(官鬼)와 합병하면 주식(酒食)으로 인하여 병에 걸린다.`
  ,
]



  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
