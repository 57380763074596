
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,3,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,8,2,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`신뢰`,9,1,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`신뢰`,10,12,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,11,11,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,12,10,`특별있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,1,9,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,2,8,`있음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`설득`,3,7,`있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`불성`,4,6,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,5,5,`있음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`신뢰`,6,4,`있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`직녹`,`직위녹위 관련사`,`小`,``,``,``],
    [`반목`,`가정반목대립사`,`小`,`1`,`외사화합`,`가정반목`],
    [`색정`,`내女 색정사`,`大`,`절정기`,`직녹권리`,`색정사有`],
    [`선난후이(先難後易)`,`먼저는 어렵지만 나중에는 순조로운 상`,`大`,``,`도둑실재`,`실업한가`],
    [`존비상몽(尊卑相蒙)`,`신분과 나이 차이가 많이 나는 남녀 상교의 상`,`小`,``,`우려`,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업직위 공허사`,`大`,`2`,`굴욕반복`,`불리고시`],
    [`남자`,`남자 관련사`,`小`,`면모기`,`외교막힘`,`구설`],
    [`산탈난재(散脫難災)`,`재난을 흩어지게 하기가 어려운 상`,`小`,``,``,``],
    [`목명지상(目暝之象)`,`눈이 가려진 격-불리시험-없는 구설을 근심하는 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`大`,``,``,`부처반목`],
    [`극부`,`남편 갈등사`,`小`,`3`,`소인쟁재`,`면모훼손`],
    [`결속`,`결속하여 財기반을 크게 세우나 직위권리남편이 훼절됨`,`小`,`학습기`,`외사급속`,`남편갈등`],
    [`면조귀고(免遭鬼苦)`,`나를 해치는 자로 인한 고민 고통를 면할 수 있게 된 상`,`大`,``,`강제손실`,`비용과출`],
    [`소인쟁재(小人爭財)`,`신분이 차이나는 자와 금전 다툼을 하는 상`,`小`,``,``,``],
    [`박관`,`직업직위불안사`,`大`,``,``,``],
    [`훼탈`,`결속하여 財기반을 크게 세우나 관직직업권리남편 훼절됨`,`小`,`4`,`사후쟁탈`,``],
    [`막힘`,`내사 가정 막힘사`,`小`,`개시기`,`직위불안`,`가정막힘`],
    [`상흔관기(常忻官忌)`,`일반인은 흔쾌하고 관직자는 나쁜 상`,`大`,``,`이전이동`,`간인궤계`],
    [`문화풍아(文化風雅)`,`관서(觀書)의 상-실업자 격`,`小`,``,``,``],
    [`관사`,`관사직업직위사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`5`,`파재극처`,`반화위복`],
    [`매사 영속성없음`,`관직직업권리남편남자사는 선천적 기복재해를 겪음`,`小`,`배양기`,`도적失勢`,`손해가출`],
    [`개불리야(皆不利也)`,`모든 일이 불리할 뿐인 상`,`小`,``,`실종우려`,`우려`],
    [`탐영지체(貪榮遲滯)`,`출세를 위해 문서를 올렸으나 답신이 없는 상-구설사`,`大`,``,``,``],
    [`금전`,`금전사업여인 관련사`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`6`,`금전사업`,`관재형책`],
    [`매사 영속성없음`,`금전사업이권처첩사는 선천적 기복재해를 겪음`,`小`,`존재기`,`불의투서`,`출행이동`],
    [`진퇴우박(進退遇迫)`,`왕래진퇴가 어리석어 박해를 받는 상 `,`小`,``,`진퇴버민`,`사행불량`],
    [`사행불량(私行不良)`,`남 모르게 문밖을 오가는 상-색정사 관련`,`小`,``,``,`이사예정`],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`결속`,`결속하여 財기반을 크게 세우나 직위권리남편이 훼절됨`,`大`,`7`,`색정사有`,`혼인단장`],
    [`색정`,`외男 색정사`,`小`,`단절기`,`손해도망`,`파재수난`],
    [`사다흠리(事多欠利)`,`일은 많으나 이익은 부족한 상`,`小`,``,`불변근신`,``],
    [`불변근신(不辨謹愼)`,`말을 삼가고 근신해야 하는 상`,`小`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`후원`,`음4월 결속하여 후원사를 크게 이루나 자녀근심 발생함`,`大`,`8`,`동요분산`,`불변근신`],
    [`극남`,`남자 관련사`,`小`,`침체기`,`발탁도약`,`결속단합`],
    [`택사흠형(宅舍欠亨)`,`가택사가 순조롭지 않은 상`,`小`,``,`침체개통`,``],
    [`주유형책(主有刑責)`,`사건 형책사가 있게 될 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 권리 관련사`,`小`,`9`,`비곡차욕`,`강제손실`],
    [`부친`,`부친 관련사`,`大`,`소외기`,`면모훼손`,`급속사有`],
    [`관자가용(官者可用)`,`관직사는 쓰임을 받게 되는 상`,`小`,``,``,`불변근신`],
    [`주유형책(主有刑責)`,`사건 형책사가 있게 될 상`,`大`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`小`,`10`,`간사은닉`,`동요분산`],
    [`모친`,`모친 관련사`,`大`,`침해기`,`가택이사`,`가정불화`],
    [`만사탈모(萬事脫耗)`,`모든 일에 수입은 없고 허비가 있게 되는 상`,`小`,``,`관계불통`,`門戶개변`],
    [`불의투서(不宜投書)`,`투서헌책이 불리한 상-구설  또는 금전실재사`,`大`,``,``,`(이혼)`],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`11`,`失業한가`,`재난전소`],
    [`송쟁`,`외사 급속송쟁사-파재실종상해사 우려`,`小`,`쇠퇴기`,`급속송쟁`,`화합사有`],
    [`상인안녕(常人安寧)`,`여러 일에서 일반인은 편안하게 됨`,`小`,``,``,``],
    [`비곡차욕(悲哭差辱)`,`화장을 훼손당한 격으로 분하고 원통하야 우는 상`,`大`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`小`,`12`,`妾婦相侮`,`가족질병`],
    [`출행`,`외사 출행 이동 원행사`,`大`,`과시기`,`(처첩간에`,`결속단합`],
    [`병송기야(病訟忌也)`,`관직자는 좋으나 병과 송사는 나쁜 상`,`大`,``,`서로무시)`,``],
    [`존비상몽(尊卑相蒙)`,`신분과 나이 차이가 많이 나는 남녀 상교의 상`,`小`,``,`출행이동`,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,"직위외사화합사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`관사직업남자외교막힘사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`직업직위불안남편갈등자식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안남자갈등여식사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,"금전사업여인사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`관사직업남자사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,"후원문서부친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,"후원문서모친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사직업송쟁사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,"형제친구동료조력출행사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서 대항하지 말고 순응유화적 처신으로 사안을 지체연기시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 시시비비를 가리려 말고 유순한 자세로 결정을 뒤로 미루면 결과가 반드시 유리해지는 人 `,0,0,`일이 거꾸로 진행됨**기이한 전화위복사가 있게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 시중해야 하고 후에 행동해야 하며 방심하면 불리함`,0,0,`풍상고초후 성공할 人`,0,0,`일이 거꾸로 띄엄띄엄 진행됨**색정사 우려가 있음**불화 가쇠의 조짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 정당한 사안이므로 당당히 나서 속결해야만 유리함`,0,0,`명분을 앞세워 주도적으로 속결해야 하고 좌고우면하거나 일을 늦추면 되려 불리해지는 人`,0,0,`토지가옥사 有**산속으로 깊이 숨고싶은 심정임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 시중해야 하고 후에 행동해야 하며 방심하면 불리함`,0,0,`풍상고초후 성공할 人`,0,0,`복잡다단한 여러 사안을 하나로 묶는 결속단합사 있음**도모사가 결과없이 반복되어 어두움`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`권리사가 있고 불완전함-음4월에 완성됨**관직자는 승진영전함**깊은 산속으로 숨고싶은 심정이 있게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함`,0,0,`반복 무상하고매사 동요불안 분산 배신사를 겪는 人`,0,0,`가택이사함**풍운불측의 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서 대항하지 말고 순응유화적 처신으로 사안을 지체연기시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 시시비비를 가리려 말고 유순한 자세로 결정을 뒤로 미루면 결과가 반드시 유리해지는 人 `,0,0,`기이한 전화위복사 있음-단 시작 초기단계**외男 색정사 有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 시중해야 하고 후에 행동해야 하며 방심하면 불리함`,0,0,`풍상고초후 성공할 人`,0,0,`여러 사안을 결속하려는 개신개혁사 有**자녀사 근심 有**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서 대항하지 말고 순응유화적 처신으로 사안을 지체연기시켜야만 결과가 유리함`,0,0,`풍상고초후 성공할 人`,0,0,`간사 은닉하고 연루사가 있음**`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위 세(狐假虎威勢)세**만약 타인과 다툰다면 상대는 호가호위의 세에 불과하므로 일시 경과하면 두려움이 자연히 사라짐`,0,0,`옳지 못한 사안을 진행하다가 들통이 난 격으로 천둥에 놀란듯한 두려움을 갖고 있으나 언행을 삼가하고 조용히 있으면 자연히 사라지는 人`,0,0,`일이 결과없이 반복됨**여건이 갖춰지지 않음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서 대항하지 말고 순응유화적 처신으로 사안을 지체연기시켜야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 시시비비를 가리려 말고 유순한 자세로 결정을 뒤로 미루면 결과가 반드시 유리해지는 人 `,0,0,`일이 순서적으로 진행되나 공허함-단 1929, 41,53,65,77,89,2001년 생과 42,54,66,78,90,2002년 생은 진행하여 크게 이로움**산속으로 숨고싶은 심정임**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 있으나 움직이지는 않음`,0,0,`스스로 유약하다고 여기고 적극적 활동을 하지 못하며 신음하는 人-강제적 신체제약을 받는자도 있음`,0,0,`토지가옥 관련 소송사가 있음**일이 결과없이 반복되나 밝아짐`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성-무력`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동사`,`미사`,`구사`,`외사`,`표면사`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성-무력`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`이발공사-후회사겹침`,`기사`,`구사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,3,2,5,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음9월`,2,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월`,3,3,6,`음6월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음1월`,2,2,4,`음7월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,2,1,3,`음6월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음1월`,0,0,0,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월-연기우려`,3,2,5,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음7월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음6월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음9월`,1,1,2,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음4월-연기 또는 파혼우려`,1,1,2,`음8월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음1월`,3,2,5,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이나 업무는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 수준이하 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 여러 사안을 일관시키는 결속을 이루나 결과적으로 큰 손실이 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 권리사를 음4월에 완성하여 겨우 면모를 세움`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무 내내 동요분산 배신사가 있게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하며 업무는 수준을 유지하다가 종반에 이르러 공허함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것 같으나 설득력을 가져 여러 사안을 일관시키는 개신개혁을 시도하여 음4월에 완성해 큰 성과를 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것 같으나 설득력을 가져 업무의 수준을 유지하나 은닉사가 있게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 듯해도 설득력이 있어 초반에는 공허하나 중 종반에는 수준을 유지함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무가 시종 공허함-단 좌 '적확성'에 적시된 해당 출생연도자는 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 상대와 같은 소리를 내며 업무를 시종 반목대립소송사로 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`集`,`반대있음`,`불이행`,`선損후損`,`進向`,`원`,`留`,`무난`,`북북동`,``,`정서`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근제압`,`불이행`,`선난후난`,`進向`,`  원`,` 留`,`세입가해`,`동북`,``,`서북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 集`,`반대다수`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인합세`,`불이행`,`권리양도`,`退背`,`  원`,`  去`,`주인조력`,`동북`,``,`북북서`],
    [`10.11`,``,`내부측근`,``,``,``,``,``,`이후무례`,``,``,``],
    [`3.6\n9.12`,`多集`,`제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선이후난`,`進向`,`  근`,`  留`,`주인조력`,`정동`,`정남`,``],
    [`10.11`,``,``,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선난후이`,`向進`,`  근`,`  留`,`세입가해`,`북북동`,`남남서`,``],
    [`10.11`,`  集`,``,``,`천천히`,``,``,``,``,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`했음`,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선이후난`,`進向`,`  원`,`  去`,`주인가해`,`북북서`,`북북동`,``],
    [`10.11`,`  集`,`발현안됨`,``,`빠르게`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세무력`,`불이행`,`선이후난`,`進向`,`원근왕래`,` 留`,`주인가해`,`정남`,`정동`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`제압불가`,`불이행`,`선난후난`,`退背`,`  원`,`  去`,`세입가해`,`서남`,`북서`,``],
    [`10.11`,``,`끌면제압`,``,`본인무력`,``,``,``,`이후결속`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 다집`,`본인반대`,`불이행`,`본인무력`,`退背`,`  원`,`  去`,`세입조력`,`정서`,`북북서`,``],
    [`10.11`,``,`기세무력`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세무력`,`불이행`,`사안무력`,`退背`,`  원`,`  去`,`주인무력`,`정서`,``,`정남`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세무력`,`불이행`,`사안무력`,`退背`,`  근`,`  去`,`주인무력`,`북북서`,``,`?`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대다수`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`선난후난`,`退背`,`  근`,`  去`,`무난`,`정북`,``,`서남`],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,`음귀`,`묘지탈`,0,0,`묘좌`,`동북`,`있음`],
    [``,``,`직녹자산`,0,0,`묘향`,`남서`,``],
    [`생업`,``,`훼손`,0,0,`전경`,`임야`,``],
    [``,``,``,0,0,`묘속`,`목근`,``],
    [`가정`,``,``,0,0,`육친`,`형제`,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`가장귀`,`제례귀`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`가장귀`,`호주사망`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`음귀`,`묘지탈`,0,0,`묘좌`,`북북서`,`없음`],
    [``,``,``,0,0,`묘향`,`남남동`,``],
    [`생업`,``,``,0,0,`전경`,`임야`,``],
    [``,``,``,0,0,`묘속`,`  수`,``],
    [`가정`,``,``,0,0,`육친`,` 모친`,``],
    [`질병`,`전흉사귀`,`혈광사망`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`특별있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`여귀`,`호주부인`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`제례귀`,`제례위범`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`여귀`,`호주부인`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`전사귀`,`전사`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`도로귀`,`교통사망`,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`음귀`,`전흉사귀`,0,0,`묘좌`,`정동`,`있음`],
    [``,`전사귀`,``,0,0,`묘향`,`정서`,``],
    [`생업`,`음귀`,``,0,0,`전경`,`초지`,``],
    [``,``,``,0,0,`묘속`,`목근`,``],
    [`가정`,``,``,0,0,`육친`,`조모`,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,``,`간사한 자가 꾸민 거짓사`,`내여자 색정사-내사반목대립무례사`,`비상지애사`,`외교막힘`,`남모르게 당하는 능욕박해사`,`외사 급속사`,`환성경사`,`내사 막힘`,`출세를 위한 투서헌책 답신지체-구설사`,`매사 허성불성-세속허사자**매사위역사**재난해소사`,`음3,4,5,6,9,12월 방문자는 종교진출하면 `,`매사영속성없음`,`종교진출하면자립함`,`외남자 색정사**외사 도망-내사 파재극처사`,`탄식신음-병 또는 모사 미수사`,`외사동요불안분산사`,`觀書-문화풍아`,`내사 급속사`,`도적실세-잡음`,``,`事후쟁탈사-이동사`,`매사위역사**외사급속송쟁파재사-실종상해 우려`,`모두 근심사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`전화위복`,`전도`,`빈궁**관재`,``,`재액해소`,``,`재액해소`,`빈궁**관재`,`빈궁`,`성공함**내사 손해가출사`,`추천발탁영전도약사`,``,`음5월 화재주의**전화위복`,``,`빈궁**관재`,``,`빈궁**병재`,``,``,``,`침체경구사`,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외사급속송쟁파재사-실종상해 우려`,``,``,`바지를 걷고 어깨를 맞춰야 하는 반보사`,`내여자 색정사-내사반목대립무례사`,`비상지애사**부부반목`,`외교막힘`,`불리 고시-없는 구설근심사`,`외사 급속사`,`왕進쇠退사`,`내사막힘`,`관계불통-가택이사-가족질병사`,`매사영속성없음**비곡치욕사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외남자 색정사**외사 도망-내사 파재극처사`,`도적악의-공격말 것`,`외사도요불안분산사`,`권리부족사`,`내사급속사`,`좋은 소식사`,``,`간사한자가 꾸민 거짓사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`관재`,``,`전도**빈궁`,``,`전화위복`,`전도`,``,`빈궁**관재`,`재액해소`,`빈궁**관재`,`재액해소`,``,`내사 손해가출사`,``,`병 중태-구설사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,`관재`,``,`빈궁**병재`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,``,`간사한 자가 꾸민 거짓사-이동사`,`외서급속송쟁파재사-실종상해 우려`,``,``,`비지를 걷고 어깨를 맞취야 하는 반복사`,`내여자색정사**내사반목대립무례사`,`비상지애사**부부반목`,`외교막힘`,`불리 고시-없는 구설근심사`,`외사 급속사`,`왕進쇠退사`,`내사막힘`,`관계불통-가택이사-가족질병사`,`매사영속성없음**추천발탁영전도약사**비곡치욕사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외남자 색정사**외사 도망-내사 파재극처사`,`도적악의-공격말 것`,`외사동요불안분산사`,`권리부족사`,`내사 급속사`,`좋은 소식사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`관재`,``,`전도**빈궁`,``,`전화위복`,`전도`,``,`빈궁**관재`,`재액해소`,`빈궁`,`재액해소`,``,`내사 손해가출사`,``,`병 중태-구설사`,`빈궁`,`음5월 화재주의**전화위복`,`빈궁**관재`,``,`관재`,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`도적실세-잡음`,`내사 급속사`,`事후쟁탈사`,``,`매사위역사**외사급속송쟁파재사-실종상해 우려`,`전화위복사`,`간사한자가 꾸민 거짓사`,``,`내여자색정사-내사반목대립무례사`,`비상지애사`,`외교막힘`,`남 모르게 당하는 능욕박해사`,`환성경사`,`외사 급속사`,``,`내사막힘`,`매사영속성없음**매사위역사**재난해소사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외남자 색정사**외사 도망-내사 파재극처사`,`탄식신음-병 또는 모사미수사`,`외사동요불안분산사`,`觀書-문화풍아`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,``,``,`빈궁**관재**병재`,``,`전도**빈궁`,``,`전화위복`,`전도`,``,`빈궁**관재`,`재액해소`,``,`재액해소`,`빈궁`,`내사 손해가출사`,`빈궁`,`추천발탁영전도약사`,``,`음5월 화재주의**전화위복`,``,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`觀書-문화풍아`,`외사동요불안분산사`,`도적실세-잡음`,`내사 급속사`,`事후쟁탈사`,``,`매사위역사**외사급속송쟁파재사-실종상해 우려`,`전화위복사`,`간사한 자가 꾸민 거짓사`,``,`내여자색정사**내사반목대립무례사`,`비상지애사`,`외교막힘`,`남 모르게 당하는 능욕박해사`,`환성경사`,`외사 급속사`,``,`내사막힘`,`매사영속성없음**매사위역사**재난해소사`,`종교진출하면자립함`,`매사영석성없음`,`종교진출하면자립함`,`외남자색정사**외사 도망-내사 파재극처사`,`탄식신음-병 또는 모사 미수사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도*빈궁**관재`,``,`재액해소`,`빈궁**병재`,`재액해소`,`관재`,``,`전도**병재`,``,`빈궁`,`전화위복`,`관재`,`전도**빈궁**관재`,``,`관재`,``,`빈궁`,``,`내사 손해가출사`,`전도**빈궁`,`추천발탁영전도약사`,``,`음5월 화재주의**전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`외남자 색정사**외사 도망-내사 파재극처사`,`말 말고 근신함이 좋음`,`투서헌책 안좋음`,`외사동요불안분산사`,`혼인단장사`,`내사 급속사`,`형사처벌사-이동사`,``,`외사급속송쟁파재사-실종상해 우려`,`신분이 차이나는 소인쟁재사`,`간사한자가 꾸민 거짓사`,``,`내여자 색정사**내사반목대립무례사`,`소식기다림-와도 나쁨`,`승진도약사`,`외교막힘`,`도적을 추격했으나 못잡음`,`외사 급속사`,`사행불량사`,`내사막힘`,`매사영속성없음**내사 손해가출사**비곡치욕사`,`종교진출하면자립함`,`매사영속성없음**추천발탁영전도약사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음5월화재주의**동요분산사`,`빈궁`,`동요불안분산사`,`전도**빈궁**관재`,`동요불안분산사**재액해소`,`전도**관재`,`동요불안분산사**재액해소`,`빈궁**관재`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사**전화위복`,`빈궁**병재`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`반궁`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도`,`동요불안분산사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`매사영속성없음**추천발탁영전도약사`,`종교진출하면자립함`,`외남자색정사**외사 도망-내사 파재극처사`,`말 말고 근신함이 좋음`,`투서헌책 안좋음`,`외사동요불안분산사`,`혼인단장사`,`내사 급속사`,`형사처벌사-이동사`,``,`신분이 차이나는 소인쟁재사`,`외사급속송쟁파재사-실종상해 우려`,`간사한자가 꾸민 거짓사`,``,`내여자 색정사**내사반목대립무례사`,`소식기다림-와도 나쁨`,`승진도약사`,`외교막힘`,`도적을 추격했으나 못잡음`,`외사 급속사`,`사행불량사`,`내사막힘`,`매사영속성없음**비곡치욕사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`비곡치욕사`,``,`음5월 화재주의**전화위복`,`빈궁`,``,`빈궁**관재`,``,`빈궁`,``,`전도**빈궁`,``,`관재`,`재액해소`,`빈궁**관재`,`재액해소**전화위복`,`빈궁**병재`,``,`관재`,``,`빈궁`,`빈궁**관재`,``,`내사 손해가출사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사영속성없음**비곡치욕사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외남자색정사**외사 도망-파재극처사`,`말 말고 근신함이 좋음`,`투서헌책 안좋음`,`외사동요불안분산사`,`혼인단장사`,`내사 급속사`,`형사처벌사-이동사`,``,`신분이 차이나는 소인쟁재사`,`외사급속송쟁파재사-실종상해 우려`,`간사한자가 꾸민 거짓사`,``,`내여자 색정사**내사반목대립무례사`,`소식기다림-와도 나쁨`,`승진도약사`,`외교막힘`,`도적을 추격했으나 못잡음`,`외사 급속사`,`사행불량사`,`내사막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`내사 손해가출사`,`추천발탁영전도약사`,``,`재액해소**전화위복`,`빈궁**음5월 화재주의`,``,`빈궁`,``,``,`빈궁`,``,`관재`,``,`빈궁`,`빈궁`,`전화위복`,`빈궁**병재`,``,`관재`,``,`전도**빈궁**관재`,``,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`사행불량사`,`내사막힘`,`매사영속성없음**비곡치욕사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외남자색정사**외사 도망-내사 파재극처사`,`말 말고 근신함이 좋음`,`투서헌책 안좋음`,`외사동요불안분산사`,`혼인단장사`,`내사 급속사`,`형사처벌사-이동사`,``,`신분이 차이나는 소인쟁재사`,`외사급속송쟁파재사-실종상해 우려`,`간사한자가 꾸민 거짓사`,``,`내여자 색정사**내사반목대립무례사`,`소식기다림-와도 나쁨`,`승진도약사`,`외교막힘`,`도적을 추격했으나 못 잡음`,`외사 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,``,`내사 손해가출사`,`추천발탁영전도약사`,``,`재액해소**전화위복`,`빈궁**음5월 화재주의`,``,`빈궁**관재`,``,``,`빈궁`,``,`관재`,``,`빈궁`,``,`전화위복`,`빈궁**병재`,``,`관재`,``,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`우유한가사-느긋한 실정`,`외사 급속사`,`내사막힘`,`관계불통-가택이사-가족질병사`,`매사영속성없음**매사위역사**재난해소사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외남자 색정사**외사 도망-내사 파재극처사`,`사사성취사`,`왕래사송계류사`,`외사동요불안분산사`,`내사 급속사`,`생선비늘 벗겨지듯하는 분산사`,``,`간사한자가 꾸민 거짓사-이동사`,`매사위역사**외사급속송쟁파재사-실종상해 우려`,`전화위복사`,``,`상위 기쁨-하위 수도자 격`,`내여자 색정사**내사반목대립무례사`,`침입의혹놀램-불녕`,`신분과 나이 차이가 많은 음란-존비상침사 `,`외교막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,``,`재액해소`,`관재`,`빈궁`,`내사 손해가출사`,`구설송쟁사`,`빈궁`,`음5월 화재주의**전화위복`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`전도`,``,`전화위복`,`전도**빈궁`,``,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외교막힘`,`신분과 나이차이가 많은 음란-존비상몽사`,`외사 급속사`,`우유한가사-느긋한 실정`,`내사막힘`,`관계불통-가택이사-가족질병사`,`매사영속성없음**매사위역사**재난해소사`,`종교진출하면 자립함`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`외남자 색정사**외사 도망-내사 파재극처사`,`사사성취사`,`외사동요불안분산사`,`왕래사송계류사`,`내사 급속사`,`생선비늘 벗겨지듯하는 분산사`,``,`간사한자가 꾸민 거짓사-이동사`,`매사위역사**외사급속송쟁파재사-실종상해 우려`,`전화위복사`,``,`상위 기쁨-하위 수도자격`,`내여자 색정사-내사반목대립무례사`,`침입놀램의혹-불녕`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`재액해소`,`관재`,`재액해소`,``,`빈궁`,`내사 손해가출사`,`구설송쟁사`,`빈궁`,`음5월 화재주의**전화위복`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재**병재`,``,`폐한인`,`전도`,`전화위복`,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`내여자 색정사-내사반목대립무례사`,`침입의혹놀램-불녕`,`신분과 나이차이가 많은 음란-존비상몽사`,`외교막힘`,`외사 급속사`,`우유한가사-느긋한 실정`,`매사막힘`,`관계불통-가택이사-가족질병사**재난해소사`,`매사 허성불성-세속허사자**매사위역사`,`음3,4,5,6,9,12월 방문자는 종교진출하면 `,`매사 허성불성-세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`외남자 색정사**외사 도망-내사 파재극처사`,`사사성취사`,`외사동요불안분산사`,`왕래사송계류사`,`내사 급속사`,`생선비늘 벗겨지듯하는 분산사`,``,`간사한자가 꾸민 거짓사-이동사`,`매사위역사**외사급속송쟁파재사-실종상해 우려`,`전화위복사`,``,`상위 기쁨-하위 수도자격`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`전도**빈궁`,``,`빈궁**관재`,`재액해소`,``,`재액해소`,``,`빈궁`,`성공함**내사 손해가출사`,`구설송쟁사`,`빈궁`,`음5월 화재주의**전화위복`,``,`빈궁**관재`,``,``,``,`빈궁`,``,`빈궁**관재*병재`,``,`전도`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 온갖 귀신이 다 붙었네?     땅문서에 소송이 붙었어 ! 

  귀신(3면)을 빨리 해소해야 탈출구가 생기겠어!
  ⊕⊖전사귀ㆍ흉사귀ㆍ음귀
     발동이야 !
  `,`■⊕능욕 당하고 
    ⊖속없는 말에 끌려 다녀도… 
  
  ⊕전사귀 
  ⊖음귀만 해소 시키면 지금은    어려워도 나중에는 좋아지    겠어!
  `,`■ 집안이 기울어 가네?
     내가 스스로 능욕박해를      당하고․구설이분분해서      흉이 심한 지경이야! 
  
  ⊕전사귀 
  ⊖변사귀 장난이 심해 !
  `,`■ 병이 걱정이겠어 ! 
     집안은 흥왕할 운세인데     귀신들이(3면) 득시글 
     거려서 땅문제로 소송이     불겠어!
  
  ⊕신변상가부정 침범
  ⊖신변목기부정이 침범했네 ?
  `,`■ 男 : 직위가 흔들리네? 
          마음이 떴어!
     女 : 이별수가 보이네?
   
  귀신부터 잡고 기도해야 
  일이 풀리겠어!
  `,`■ 일은 맘먹은데로 잘 되     겠는데 부부가 똑같이       바람이 났네? 
      의심질투  가 있네 !
  공직자:巳月에 소원성취 되겠어 !
  ⊕전흉사귀ㆍ가택상가부정
  ⊖가택목기부정ㆍ가택귀를     없애야 망신이 없겠어!
  `,`■ 색정불화가 심하네? 
     집을 옮기거나 떠나야 
     하겠어!
  ⊕변사귀 
  ⊖신불제사귀를 잡아야 그나마
    남한테 탄핵을 면하겠어 !
  ⊕남이 알지못할 음사사가 있어!
  `,`■ 운세가 좋게 가는데,
  ⊕처 
  ⊖부모의 병이 걱정이야 ! 
  
  ⊕여귀 
  ⊖여귀를 잡아야 凶이 없겠어!
  
  ⊕신변음사부정이 침범했네?
  `,`■ 여러일을 묶고바꾸는 것은     좋겠는데,이권상실과       처․첩,자식걱정이 더 크     겠어! 
     巳月에 희비가 교차 하겠어 !
  ⊕신불제사귀 
  ⊖음귀를 없애야 대가 안끊    기지!
  `,`■ 男 : 종업원을 처로 들어          앉히게 생겼네?
  은밀하게 감추는 일(죄인)이 있네!
     女 : 색정사가 있겠어!
  ⊕여귀ㆍ가택음사부정 
  ⊖도로 귀를 해소시켜야 일이     풀리겠어!
  ⊖신변 음사부정이 침범했네?
  `,`■지금 관재․一男二女
    쟁론사로 놀래고 있겠는데, 
     진퇴양난에 빠졌네 !
  ⊕변사귀 
  ⊖전사귀를 잡아야만 원조가    생기겠어!
  `,`■ 모두 등 돌렸는데 病과      관재가 고심되겠는데,
  ⊕여귀ㆍ가택수망노부부정, ⊖도로귀ㆍ기택음사부정 장난     이라 해소시키면 뚫리겠어!`];
  const YOGYEOL1 = [`■ 과전에 모두 관귀가 올랐다. 고통이 적지 않다. 밤정단은 입안에 귀인이 있어 모든 고통을 막아 주리라.
  `,`■ 밤정단은 내녹을 지키기가 어렵겠고 일반인은 낮밤 모두 재앙이 있음을 알아야 한다. 공직자나 종견 사원은 뜻대로 되겠다.
  `,`■ 낮천장은 모두 관귀이고 귀신의 장난으로 저주가 있다. 과전이 돌고 도니 재앙에서 벗어날 길이 안보인다.
  `,`■ 서로 뜻은 맞으나 침침한데서 벗어나기 어렵다. 분수를 알고 녹을 지키면 집안이 점점 흥황해 지리라.
  `,`■ 내가 스스로 움직여 가고보니 도적굴이다. 밤정단은 관재가 생겨 돈은 없어지나 나쁜 일은 풀리게 된다.
  `,`■ 서로 치고 받으니복을 받지 못한다. 밤정단은 범이 와서 놀래고 비고 손해나고 도적 맞겠다.
  `,`■ 밤정단은 귀인이 입을 다무니 가정은 안정이 없고 요동한다. 움직였다 하면 손해가 나고 지키기도 어려우리라.
  `,`■ 중간에 손해나고 결과도 나쁘다. 내 돈이 없어져도 잊어 버리라. 다행이 남의 도움이 있겠다. 밤정단은 불행중 다행이다.
  `,`■ 아래에서 위를 모두 극하니 제사가 끊어지고 집안은 망하리라. 낮정단은 하늘이 도와 재앙은 가고 복이 오리라.
  `,`■ 내녹과 귀인과 재물이 묘를 만나 어둡기만 하다. 지키고 있자니 답답하니 움직이면 형통하리라.
  `,`■ 간지와 삼전이 위에서 아래를 탈기 시킨다. 
  물이 마르고 백리도 잘렸다. 병이나 소송도 나쁘다. 
  `,`■ 앞길에 모두 빈 것뿐이니 모든 일에 종적이 없다. 헛되게 움직임을 면하지 못하고 병이나 소송을 같이 만나리라.`]
  const SIBO1 = [`■ 자충수적 우환이 있고
  凶을 凶으로 제어한다. 토지
  사에 소송이 붙었다.
  근본을 지키는 것이 좋고 활동은 이익이 안된다. 
  질병에는 동쪽․서쪽, 청색․신맛, 백색․매운맛 7․6수에 양의․양약이 있다.
  `,`■ 내사보다는 외사가 이롭다.
  凶이변해 吉이 된다.
  윗사람의 원조를 얻는다.
  함부로 나아가지 말고 자중하면 길하다. 별도 방책으로 뜻을 펴는 것이 좋다. 처음은 어렵고 나중에 좋다.
  `,`■ 능욕박해와 구설이 있다.
  공사는 불리하고 사사로 이를
  해소 시켜야 이롭다.
  망동하거나 邪로 하면 결국 凶이 된다. 내가 타인에 의존하나 결국 손해이다. 여자 사용인의 상사가 나서 주식으로 인한 사건이 난다.
  `,`■ 자충수적 우환이 있다.
  토지사가 어둡다. 가문이
  번창한다. 가택의 명의를
  이양한다. 凶을 凶으로 제어한다. 병재․송사가 흉하나 사람이 나서서 해소 시켜준다.
  권리상실사가 기한다.
  女:남편의 凶이 기한다.
  `,`■ 上人에 의해 이익이
  있게 된다.
  공사는 불리하고 사사에 이롭다. 
  부부가 다같이 음탕하여  꿀속에 비소를 감춘다.
  금전이 외부에서 들어오고 모든 일을 타인과 의논하여 집행하는 것이 이롭다.
  `,`■ 부부간 외정으로 금슬이
  나쁘고 피차 배반하고
  타협하지 않는다.
  개신ㆍ혁신을 꾀하나 여의치 않다. 
  모든 일은 신속한 결행이 요구된다. 늦으면 허사이다. 가정의 부정을 해소하는 것이 좋다.
  심중에 의심 질투가 있다.
  이권을 상실한다.
  `,`■ 자충수적 우환이 있다.
  가정파탄의 근심이 있다.
  서로 반목하고 의심하여 싸운다. 
  남으로부터 지탄을 받는다. 
  ⊕남이 알지 못하는 사업계획이 있다. 또는 음사가 있다.
  ⊖일이 반복될 뿐 결실이 없다
  `,`■ ⊕小兒의 근심이 있고
  ⊖도난사가 기한다.
  처가 병에 걸리는 근심이 있게 된다. 
  가정은 번영하게 된다. 임신사가 있다. 부모의 병은 사망으로 본다.
  금전ㆍ여자 문제로 의심이 따른다.
  七月 임신사가 있다.
  `,`■ 가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  옛것을 고쳐 새것으로 나아간다.
  별리사가 일어난다.
  자손걱정이 있다.
  ⊖여자 사용인이 도망가는 일이 벌어진다.
  다인 다사건이다.
  `,`■ 가정에 임신사가 있다.
  사용인 여자를 처로
  삼는 일이 있게 된다.
  부부는 사정을 품고 이간되고 반목된다.
  처첩간에 암투가 벌어진다. 원방에서 송금이 있고 일이 두갈래로 나뉘고 사물은 정체된다.
  권리를 상실한다.
  `,`■ 심중 놀램이 있다.
  사물이 미혹되어 사적
  으로 흐른다.
  여건도 부족하고 분쟁이 일어난다.
  역마공망으로 동기가 막힌다. 도중 먼곳에서 송금사가 있으나 ⊕여자, ⊖귀인과 다투게 된다.
  이권을 상실한다.
  금전 여인의 凶이 있다.
  `,`■ 내몸에 허모가 많고
  직위변경, 또는 손상이
  있다.
  도중에 옛것을 버리고 새 일에 나아간다.
  모든 일에 퇴보자세가 좋다. 질병과 관재의 기운이 기한다.
  七月 임신사가 있다.
  권리․이권․여인․금전의 凶있다.`]
  const SIBO2_1 = [`■官･夫,신변외사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官ㆍ夫,신변외사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官･夫,신변외사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■官ㆍ夫,반목대립외사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  가정 우환이 발생한다
  `,`■官･夫,신변동요사이다.
  내방조짐 상의 : 
  정사 利 암사 불리하다.
  男직위상실하고
  女부부이별한다. 
  `,`■자손ㆍ박관가택내사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  기쁨후 근심사가 있게 된다.
  `,`■사행불량가정파탄사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  이전․이사를 한다.
  색정불화의 상이다.
  `,`■財ㆍ女,외사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  求財․女가 속성한다.
  `,`■가정개변내외사이다.
  내방조짐 상의 : 
  병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다. 자손 재해예방이 필요하다.
  `,`■가정개변목전내사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■官･夫,신변동요사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官ㆍ夫,파재ㆍ실종사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/040/row-1-column-1.png`,
    `/static/images/sagwa/040/row-1-column-2.png`,
    `/static/images/sagwa/040/row-1-column-3.png`,
    `/static/images/sagwa/040/row-1-column-4.png`,
    `/static/images/sagwa/040/row-1-column-5.png`,
    `/static/images/sagwa/040/row-1-column-6.png`,
    `/static/images/sagwa/040/row-1-column-7.png`,
    `/static/images/sagwa/040/row-1-column-8.png`,
    `/static/images/sagwa/040/row-1-column-9.png`,
    `/static/images/sagwa/040/row-1-column-10.png`,
    `/static/images/sagwa/040/row-1-column-11.png`,
    `/static/images/sagwa/040/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 복음과(伏吟課)로서 상극이 있는 제1과 축(丑)을 초전으로 하며, 중․말전은 형(刑)을 사용하여 가색격(稼穡格)이 된다. 축술미(丑戌未)는 일간의 중귀(衆鬼)로 되나 묘목(卯木)이 이것을 제어하여 귀살도 두려울 것은 없다. 
    복음가색격은 전적으로 근본을 지키는 것이 좋고, 활동적인 사업은 이익이 되지 않는다. 중전에 백호가 관성(官星)에 승하니 사관(仕官)을 정단하면 가장 빠르며, 보통 사람은 병의 점단을 꺼린다.
    백법에 ｢귀(鬼)를 제어하는 것은 양의(良醫)가 있다｣고 하였다.
    비전에는 ｢3전이 스스로 형충(刑冲)하는 것은 도적을 잡을 수 있다. 즉 흉으로써 흉을 제어하는 것이다｣라고 하였다. 일지상신은 중귀(衆鬼)를 제어하므로 집안 사람은 화해(禍害)를 풀게 된다. 백호가 3전 중에 있고 토귀(土鬼)가 수(水)를 극하므로 신경(腎經) 질병을 앓으며 소송 사건은 법관의 노여움을 산다.
    일귀는 역행하여 3전은 구(勾)․백(白)․음(陰)이며, 야귀는 순행하여 3전이 음(陰)․백(白)․구(勾)이다. 천장이 전부 흉신이니 처음부터 백호를 두려워한다. 구진은 쟁투의 신이고 태음은 간사(奸邪)한 활동을 한다.`
    ,
    // 2
    `  이 과는 4과의 축인(丑寅)이 1하가 1상을 극하여 중심퇴여격(重審退茹格)이며, 3전이 3기(奇)를 띤다. 일간 계(癸)에서 자(子)는 녹신(祿神)이 되며, 청룡이 앉을 때는 길을 지키기 어렵다. 초전은 관효(官爻)로서 간록(干祿)과 제휴하므로 관공직․임관․구직 등에서 모두 길하다. 관청 소송․질병 점단은 비난이 있다.
    연주삼기(連珠三奇)는 꾀꼬리가 골짜기를 건너는 형상으로 흉이 화하여 길조로 된다. 말전의 해(亥)가 계(癸)의 간(干) 밖으로 나가는 것은 투간격(透間格)이라 하여 안의 일보다 밖의 일이 더 유리하며, 모든 일이 처음에는 어려우나 후에는 쉽다.
    백법에 ｢음양귀신이 천지반에서 공망되는 것을 즐거운 일이 허사가 되기 쉽다｣고 하였다. 3전이 3기(三奇)를 만나면 웃사람․존장의 원조를 얻는다. 3전은 축(丑)에서 밝은 쪽으로 향하고 자해(子亥)로 야음(夜陰)의 지(地)로 들어간다. 그러므로 만사에서 현상을 유지하는 것이 길하고, 전진할 때는 장애가 생긴다. 일간상신 건록은 때를 기다리는 것이 좋고, 혹은 별도로 방책을 강구하면 길하게 된다.
    일귀는 역행하여 3전은 구(勾)․청(靑)․공(空)이고, 야귀는 순행하여 3전이 음(陰)․현(玄)․상(常)이다.`
    ,
    // 3
    `  이 과는 제3과․제4과에 상하의 극이 있어서 같은 음의 극이니 섭해과(涉害課)이며, 지반에 4맹(四孟))이 없으므로 중신(仲神)의 극을 취하여 제3과를 초전으로 하니 섭해간전격(涉害干傳格)이다. 과전이 모두 음지(陰地)이니 극음격(極陰格)으로서, 공사(公事)에서는 흉하고 음사(陰私)의 일은 길하다. 3전이 역간전(逆間傳)이고 축해유(丑亥酉)는 극음격으로서, 음은 길고 양은 끊어진다. 그 형상은 사(邪)로서 바르지 못하면 흉을 초래한다. 안정을 즐기되 망동하면 장애가 생긴다.
    백법에 ｢음양귀신(陰陽貴神)은 모두 공망하여 경사는 공허로 된다｣고 하였다. 
    비전에는 ｢일간기궁(日干寄宮)이 일지상신에 임하여 극되니 그에게로 가서 실망하는 일이 일어난다｣고 하였다. 일간의 음신(陰神) 유해(酉亥)가 중첩되고 태상이 승하면 여자 사용인의 가택에 상사(喪事)가 나서 주식(酒食)으로 인한 사건이 일어난다. 
    말전의 유(酉)는 정신(丁神)의 재로 변해 친척․친우간에 재정동기(財政動機)가 있거나 먼 곳의 송금을 받는다.
    일귀는 역행하여 3전이 구(勾)․공(空)․상(常)이며, 야귀는 3전이 주(朱)․구(勾)․공(空)이다.`
    ,
    // 4
    `  이 과는 제1과의 술계(戌癸)가 1상에서 1하를 극하여 원수가색격(元首稼欌格)이며 일간상신의 술(戌)이 초전으로 되어 참관격(斬關格)이다. 일간일지는 술(戌)과 묘(卯), 자(子)와 축(丑)이 교차지합(交車支合)한다. 일지상신은 일간의 건록에 해당하고, 일간의 음양과 3전이 모두 칠살묘신(七殺墓神)으로 되며 초․말전이 충(冲)하여 개묘(開墓)한다.
    일지상신에 녹신 청룡이 승하고 자(子)의 녹신은 묘(卯)의 택신을 생하여 가문이 번창한다. 초전의 술(戌)은 최관(催官)으로 되어 임관․구직 등은 길하나 말전이 공망하므로 뒤가 공허하여 결과가 없다. 3전에 3형 충신(三刑冲神)이 있어서 내몸의 적이 되므로 흉도 약간 감소된다.
    백법에 ｢녹(祿)이 지상(支上)에 임하여 권섭(權攝)이 바르지 못하며 관전에 묘고(墓庫)가 앉아서 칠살이 되며, 간상에 백호가 앉으니 병은 가장 흉한 징조요, 백호가 일간을 극하나 말전의 사(蛇)가 백호를 제어하여 흉으로써 흉을 제어하니 우환은 반드시 사람에 의하여 반드시 해결될 수 있다｣고 하였다.
    일귀는 역행하여 3전은 백(白)․음(陰)․사(蛇)이며, 야귀는 3전이 청(靑)․상(常)․후(后)이다. 초전에 청룡․백호가 앉아 귀(鬼)와 묘(墓)가 동림(同臨)하면 병에 걸린다.`
    ,
    // 5
    `  이 과는 제2과․제4과에서 2상이 2하를 극하니 비용(比用)을 취할 수 없으므로 섭해과로 된다. 원칙에 의하여 4맹신(四孟上)의 극을 취하여 미해(未亥)를 초전으로 한다. 중․말전은 묘해(卯亥)로 되어 3합목국곡직격(三合木局曲直格)으로 된다.
    일간상신의 유(酉)는 수일정재(水日丁財)로 되어 재의 움직임은 외부에서 일어난다. 3전의 곡직은 일간에서 전탈(全脫)되나 일간상신의 인수(印綬)가 이것을 제어하므로써 일간을 생하니 윗사람․존장으로 인하여 이익이 생긴다. 과전이 모두 음지이니 공사에는 이롭지 못하고 사사에는 유익하다.
    백법에 ｢3합 중에 살(殺)을 보는 것은 꿀속에 비소를 감춘다｣고 하였다. 
    비전에는 ｢이 격은 일간의 탈기를 구하므로 탈모(脫毛) 걱정이 없다｣고 하였다.
    일귀는 역행하며 3전이 음(陰)․주(朱)․공(空)이고 야귀는 3전이 상(常)․귀(貴)․구(勾)이다. 야귀는 천장이 모두 토신(土神)이니 3합목국에서 내전(內戰)이 일어나므로 3합은 화합 중에 불화를 삼가야 한다. 3전이 곡직격이면 춘월에 왕성하니  모든 일을 타인과 상론하는 것이 좋고 또 사사(私事)는 신불(神佛)에 기도하여 성사된다. 초전의 미(未)에 태상이 승하면 모든 계획이 대길하다. 처음에는 의혹이 생기지만 후에는 해소된다.`
    ,
    // 6
    `  이 과는 제2과․제3과의 두 과에 극이 있고 음일에는 음비(陰比)의 극인 묘신(卯申)을 사용하여 초전으로 하니 지일참관단륜격(知一斬關斷輪格)이다. 일간일지가 교차상극하고, 말전은 일덕(日德)이지만 공망으로 되고 또 묘(墓)에 승하므로 복덕을 받지 못한다. 일간상신은 내몸을 생하는 인수로 되어 초전의 묘(卯)에 탈기되지만, 묘신은 단륜(斷輪)이라 하여 사물이 성립한다. 일간상 신(申)이 백호․현무가 앉아 길한 가운데 웃사람이 병에 걸리거나 도난당하고 분실하며, 교차상극하니 부부가 무음(蕪淫)으로 되어 금슬(琴瑟)이 화합하지 못하고 피차가 서로 배반하며 타협하지 않는다.
    백법에 부부 무음은 서로 사정(私情)을 품고, 단륜격은 공명을 정단하면 목적이 달성된다. 다만 말전이 공망이므로 신속하여야 한다｣고 하였다. 
    일귀는 역행하며 3전은 주(朱)․백(白)․귀(貴)이고, 야귀는 3전이 귀(貴)․청(靑)․음(陰)이다.
    주인격(鑄印格)이란 3전이 동술묘(巳戌卯)이며, 술(戌) 중에 신금(辛金)이 있고 사(巳)중에 병화(丙火)가 있어서 간합(干合)한다. 금(金)은 화(火)의 연야(煉冶)를 기다려 귀한 그릇을 이루는데, 연약(煉藥)은 성(成)의 형상이니 태산은 크게 길하다. 또한 질병은 여려움과 고통을 이겨내므로 정치가에게는 길하고 백성에게는 흉하다고 본다. 또 단륜격은 금극목(金克木)에 의하여 그릇을 이루니 혁신하는 형상이 있고, 묘목(卯木)은 휴수(休囚)․파(破)와 공망을 꺼려서 관통(棺桶)으로 화한다.`
    ,
    // 7
    `  이 과는 천지가 충위(冲位)에 있으므로 반음과이며, 사중일(四仲日)에 삼전사중(三傳四仲)은 용전격(龍戰格)이다. 순미(旬尾)의 묘(卯)가 초전으로 되면 폐구격(閉口格)이다. 정신(丁神)이 택 상에 임하여 집안에 동요․불안이 있다. 묘유일(卯酉日)에 묘유(卯酉)를 용신(用神)으로 하고 삼교(三交)에 또 묘유(卯酉)가 앉는 것은 용전격이라 부른다. 양(陽)은 천도(天道)이며 음은 지도(地道)이다. 양은 움직임을 지배하고 음은 안정을 주관하며, 양이 부를 때는 음이 화하여 분쟁이 일어나지 않는다. 묘유(卯酉)는 음양이 서로 교차되어 양이 음을 의심하여 싸우게 되므로 이와 같은 이름이 붙었다.
    이산(離散)․투쟁․불안녕․진퇴소장(進退消長)이 함께 있고 길흉이 서로 반복되어 천장의 길흉이 원칙에 따르는 것이다. 반음과(返吟課)는 만사가 반복하여 떠나는 자는 다시 오고, 이기려고 하면 진다. 정단사는 파탄되기 쉽다. 일간상신의 관귀(官鬼)가 신(身)에 임하지만 초전에서 제어하므로 귀는 두려울 것이 없다. 일지상신이 택신의 묘(卯)를 극하여 가택에 불안이 있고, 간상귀살(干上鬼殺)이 신(身)에 임하고 주작이 승하면 남에게 탄핵(彈劾)을 받을 징조이다. 
    백법에 ｢일야 두 귀신이 극을 받으면 귀인의 지원이 없고, 일간일지상신에서 극되면 서로가 모손을 방지해야 한다｣고 하였다. 비전에는 ｢처에 의하여 남편의 병세를 알려면 남편의 명상(命上)에 일귀(日鬼)․사기(死氣)․조객(弔客) 등이 승할 때는 병세가 위험하고 사망을 면하기 어렵다｣고 하였다. 
    일귀는 순행하여 3전은 음(陰)․구(勾)․음(陰)이고, 야귀는 역행하며 3전이 귀(貴)․공(空)․귀(貴)이다. 일지의 앞이 공망에 해당되면 핍박격(逼迫格)이라 하여 수세방침(守勢方針)이 좋다. 초전에 태음이 승하면 남이 알지 못하는 사업을 계획한다. 혹은 음사(陰私)의 일로서 의혹이 일어난다.`
    ,
    // 8
    `  이 과는 4과 중 3과에 상하의 극이 있으면 하에서 상을 적하는 제1과 오축(午丑)을 초전으로 하는 중심과이다. 일간상신 오(午)의 재효(財爻)를 초전으로 하며, 오(午)는 순수(旬首)이고 육의(六儀)에 해당된다. 
    일지상신이 인수장생(印綬長生)으로 되니 가택이 번영한다. 3전은 오해진(午亥辰)이 함께 자형(自刑)의 지지(地支)이지만 다행히 순의(旬儀)로 되어 흉을 구한다. 오(午)의 재신에 현무․등사가 승하므로 처가 병에 걸리지 않으면 도난을 당한다.
    백법에 ｢일지상신에 백호가 승하여 택을 극하는 것은 집에 복호(伏戶)가 있다｣고 하였다.
    비전에는 ｢일지상신의 신(申)은 인수(印綬)이고 묘(卯)는 관신(棺神)이다. 만일 부모에게 병이 있으면 가장 불길하다. 묘(卯)는 침관(寢棺)이고 인수는 입관되는 것이므로 사액(死厄)이 있다.
    일귀는 순행하여 3전이 사(蛇)․공(空)․후(后)이며, 야귀는 3전이 현(玄)․구(勾)․후(后)이다. 순수(旬首)가 초전을 이루면 육의과(六儀課)로 되어 길장․의신(醫神)․순기(旬奇)를 만나면 기쁨이 넘치며, 흉신일지라도 이것을 구(救)할 수 있다.`
    ,
    // 9
    `  이 과는 4과가 모두 하에서 상을 극하여 섭해과(涉害課)이다. 4맹상신(四孟上神)의 극인 유사(酉巳)를 사용하여 초전으로 하니 종혁격이다. 4하가 4상을 극하므로 절사격(絶嗣格)이며, 초전지반이 공망하므로 고진격(孤辰格)이다. 3전의 금국(金局)이 일간을 생하고 일지의 택을 극하면 사람은 풍성하다 하더라도 자택(自宅)이 없다. 3전이 3합금국(三合金局)이면 옛 것을 고쳐 새롭게 하는 형상이지만, 초․말전이 공망하니 시종이 일정하지 못하다.
    백법에 ｢수일정재(水日丁財)는 재의 움직임이 있으나 공망에 앉으니 힘이 떨어진다｣고 하였다. 3전은 체생(遞生)이지만 초․말전이 공망하니 남의 천거가 있어도 참된 뜻이 없다. 절사격(絶嗣格)은 하(下)가 상을 범하여 충실하지 못하다.
    일귀는 순행하며 3전은 구(勾)․상(常)․귀(貴)이고, 야귀는 3전이 귀(貴)․음(陰)․주(朱)이다.
    천공이 유(酉)에 승하여 사(巳) 위에 임하면 실재(失財) 또는 여자 사용인이 도주하는 일이 있다. 초전에 구진(勾陳)이 승하고 년명상(年命上)에 구진을 보면 백사가 정체하여 펴지기 어렵다. 그렇지 않으면 마음이 미혹되어 두 갈래에 걸치고 남에게 의존하는 일이 일어난다.`
    ,
    // 10
    `  이 과는 제1과와 제4과에 상하의 극이 있어서 하에서 상을 극하는 유오(酉午)를 사용하여 초전으로 하니 중심삼교과(重審三交課)이다.
    진(辰)은 일간의 묘신에 엎드리고 초전의 유(酉)는 일간의 정재(丁財)로 움직인다. 중전의 자(子)는 녹신으로 되어 일간의 왕세(旺勢)를 만난다. 3전 유자묘(酉子卯)는 3교격(三交格)으로서 초전에서 체생(遞生)으로 된다. 일간일지가 교차육해(交車六害)하고 일간 진(日干 辰), 일지 오(日支 午)에서 년명상신 진(辰) 또는 오(午)를 보면 해리격(解離格)이라 하여 남녀 및 부부가 이간 반목한다.
    백법에 ｢부부 무음(蕪淫)은 서로 사정을 품고 피차에 정의가 투합되지 않는데, 이는 형(刑)이 상(上)에 있으며, 수일정재(水日丁財)는 재의 움직임이 있다｣고 하였다. 
    비전에는 ｢제3과․제4과에 오유(午酉)가 있고 구진(勾陳)이 앉으면 집안에 산부가 있으며 혹은 여자 사용인을 본처로 한다｣고 하였다. 육합이 승할 때는 색정 사건이 있고, 점시가 사중신(四仲神)이면 참된 삼교격으로서 교섭사는 견련(牽漣)하여 오래 끌게 된다. 교차육해는 서로 암투한다.
    일귀는 순행하여 3전이 구(勾)․백(白)․음(陰)이고, 야귀는 3전이 공(空)․현(玄)․귀(貴)이다. 초전 유(酉)에 천공이 승하면 사용인이 달아날 형상이며 또는 실재 사건이 일어난다. 초전의 구진은 모든 일에서 두 갈래에 걸치거나 사물이 정체되어 통하기 어렵다.`
    ,
    // 11
    `  이 과는 상하에 극이 없고 요극(遙剋)은 모두 사미(巳未)에 있으나 천신에서 극하는 것을 취하는 것이 원칙이니 제4과의 미사(未巳)를 초전으로 하여 호시불비과(蒿矢不備課)로 된다. 3전은 하에서 상을 생하여 설생격(泄生格)이다. 불비격으로서 육음으로 되니 미혹되고, 공(公)에 이롭지 않고 사사(私事)에 이롭다. 제4과를 초전으로 하는 것은 맥월격(驀越格)이고 길흉은 우연히 이루어진다.
    백법에 ｢일귀․야귀가 다 가해져서 두 귀인의 원조를 얻는다｣고 하였다. 
    비전에는 ｢4과3전이 순음(純陰)이니 음이 극하여 양으로 전화되므로 임신에서 태아를 정단하면 남아이다｣라고 하였다.
    불비격은 달이 차지 않아서 해산한다. 4과3전이 모두 지신(地神)에서 천신(天神)을 생하는 것은 원소근단격(源消根斷格)이라 하며 만사에서 탈모(脫毛)를 면치 못한다.
    일귀는 순행하여 3전이 주(朱)․구(勾)․공(空)이고, 야귀는 구(勾)․공(空)․상(常)이다. 초전에 주작이 승하여 일간을 극하면 모든 일에서 분쟁이 일어난다. 망사(望事)․구재(求財)는 모두 이루어지지 않는다.`
    ,
    // 12
    `  이 과는 제3과의 진묘(辰卯)를 초전으로 하여 중심과이다. 3전이 진사오(辰巳午)이면 진여격(進茹格)이나 전부가 공망으로 되니 모든 일에서 소리만 있고 참된 것이 없다. 일지상신 진(辰)을 초전으로 하는 것은 참관격(斬關格)이다.
    백법에 ｢진여공망(進茹空亡)은 퇴보에 좋다. 모사하여도 졸책으로 되는 것은 과전(課傳)이 나망(羅網)이다｣고 하였다. 일귀는 순행하며 3전이 후(后)․귀(貴)․사(蛇)이고, 야귀는 3전이 사(蛇)․주(朱)․합(合)이다.
    무릇 점과는 첫째로 공망을 두려워하고, 둘째로 흉장(凶將)을 꺼린다. 공망이란 일순(一旬)의 마지막의 두 지(支)를 말한다. 간(干)은 녹(祿)이라 하는데, 지(支)가 있고 간(干)이 없는 녹은 공(空)이 아니라고 부른다. 천반의 공망은 유행(遊行)공망이라고 하여 충에 의하여 공망을 구할 수 있으나. 지반공망은 함락공망(陷落空亡)이라 불러 구재할 수가 없다. 공망에서 일간을 극할 때는 내몸에 소모가 많고, 일지가 극되면 가택 내에 암손(暗損)이 있다. 흉장은 공망을 즐겨 길로 변화되며, 길장․녹신이 공망될 때는 모사․망사가 이루어지지 않고, 역마의 공망은 옛 것을 버리고 새것을 쓴다. 
    천공은 12천장 중 물(物)이 없으며 우주의 신이니 12천장 중의 공망이다. 또한 길장이 제극공망(制剋空亡)되면 즐거운 일이 슬픔으로 바뀐다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
