import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./AddEdit.css";
import { toast } from "react-toastify";
import fireDb from "../firebase";
import Firebase from 'firebase';

const initialState = {
  name: "",
  email: "",
  contact: "",
  status: "",
  status2 : "",
  status3 : "",
  status4 : "",
  status5 : "",
  status6 : "",
};

const AddEdit = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  const { name, email, contact, address, status, status2, status3, status4,status5, status6,status7 , contact_ip } = state;
  const history = useHistory();
  const { id } = useParams();
  const [auth, setAuth] = useState(false)

  const [textEmail, setTextEmail] = useState("")
  const [textPassword, setTextPassword] = useState("")
  

  Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // var uid = user.uid;

      setAuth(true)
      // ...
    } else {
      // User is signed out
      setAuth(false);
      // ...
    }
  });

  useEffect(() => {
    Firebase.database().ref().child("contacts").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !contact ) {
      toast.error("Please provide value in each input field");
    } else {
      if (!id) {
        Firebase.database().ref().child("contacts").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Contact Added Successfully");
          }
        });
      } else {
        Firebase.database().ref().child(`contacts/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Contact Updated Successfully");
          }
        });
      }

      setTimeout(() => history.push("/admin"), 500);
    }
  };



  function login(e) {
    e.preventDefault();

    console.log("Firebase.auth().currentUser", )
    Firebase.auth().signInWithEmailAndPassword(textEmail, textPassword)
    .then(()=> {
      setAuth(true);
    })
    .catch((error) => {
      setAuth(null);
      alert("입력한 이메일/비밀번호 입력란의 로그인 정보가 다릅니다. FIREBASE 인증시스템에 의해 이전 로그인 정보가 있다면 로그인 시도합니다.")
    });


    Firebase.auth().onAuthStateChanged((user) => {
      if(user){
        setAuth(user)
      }else{
        setAuth(null);
      }
    });

}


  // if (auth == false ) {
  //     return(
  //         <div className="form_block">
  //             <div id="title">Login</div>
  //             <div className="body">
  //                 <form>
  //                     <input type="text" 
  //                     value={textEmail} 
  //                     onChange={e=> {setTextEmail(e.target.value)}} 
  //                     name="email" />
  //                     <input type="password" 
  //                     value={textPassword} 
  //                     onChange={e=> {setTextPassword(e.target.value)}} 
  //                     name="password" />
  //                     <input className="loginBtn" type="submit" onClick={login} value="Enter" />
  //                 </form>
  //             </div>

  //   <style>{`
  //   body {
  //     margin: 0;
  //     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //       "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //       sans-serif;
  //     -webkit-font-smoothing: antialiased;
  //     -moz-osx-font-smoothing: grayscale;
  //   }
    
  //   code {
  //     font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  //       monospace;
  //   }
  //   .form_block{
  //     width: 400px;
  //     display: table;
  //     margin-top: 10%;
  //     margin-left: auto;
  //     margin-right: auto;
  //     background-color: rgba(0,0,0,.1);
  //     padding: 5px;
  //     border-radius: 5px;
  //   }
  //   .form_block > #title{
  //     background: #3d8577;
  //     padding: 10px;
  //     color: #fff;
  //     font-weight: bold;
  //     font-size: 20px;
  //     text-align: center;
  //     border-top-right-radius: 5px;
  //     border-top-left-radius: 5px;
  //     text-shadow: -1px -1px 0 rgba(0,0,0,.2);
  //   }
  //   .form_block > .body{
  //     background: #FFFFFF;
  //     padding: 10px;
  //     display: table;
  //   }
    
  //   input[type='text'],input[type='password'] {
  //     height: 35px;
  //     width: 100%;
  //     margin-bottom: 10px;
  //     box-sizing: border-box;
  //     padding: 4px 5px;
  //     background: #fff;
  //     border: 1px solid #d0d0d0;
  //     -webkit-border-radius: 3px;
  //     -moz-border-radius: 3px;
  //     border-radius: 3px;
  //   }
  //   .loginBtn{
  //     height: 35px;
  //     box-sizing: border-box;
  //     padding: 4px 10px;
  //     background: #f5f4ea;
  //     border: 1px solid #d0d0d0;
  //     -webkit-border-radius: 3px;
  //     -moz-border-radius: 3px;
  //     border-radius: 3px;
  //     cursor: pointer;
  //   }
  //   .loginBtn:hover{
  //     background: #c3c3ba;
  //   }
  //   .registerBtn{
  //     float: right;
  //     background: #3d8577;
  //     color: #fff;
  //     height: 35px;
  //     box-sizing: border-box;
  //     padding: 4px 10px;
  //     border: 1px solid #d0d0d0;
  //     -webkit-border-radius: 3px;
  //     -moz-border-radius: 3px;
  //     border-radius: 3px;
  //     cursor: pointer;
  //     margin-top: -35px;
  //   }
  //   .registerBtn:hover{
  //     background: #2d6358;
  //   }
  //   .Error{
  //     padding: 10px;
  //     background-color: #feeaea;
  //     border: 1px solid #fadadb;
  //     margin-bottom: 10px;
  //     border-radius: 3px;
  //   }
  //   `}
  //     </style>
  //         </div>
  //         )
  // }

  return (
    <div style={{ marginTop: "100px" }}>
      <form
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit}
      >
        <label htmlFor="name">이름</label>
        <input
          type="text"
          id="name"
          name="name"
          placeHolder="Your Name..."
          value={name || ""}
          onChange={handleInputChange}
        />
        <label htmlFor="email">이메일</label>
        <input
          type="email"
          id="email"
          name="email"
          placeHolder="Your Email..."
          value={email || ""}
          onChange={handleInputChange}
        />
        <label htmlFor="contact">전화번호(010)</label>
        <input
          type="number"
          id="contact"
          name="contact"
          placeHolder="Your Contact No. ..."
          value={contact || ""}
          onChange={handleInputChange}
        />
        <label htmlFor="contact">주소</label>
        <input
          type="text"
          id="address"
          name="address"
          placeHolder="주소"
          value={address || ""}
          onChange={handleInputChange}
        />
        {/* <label htmlFor="name">Status</label>
        <input
          type="text"
          id="status"
          name="status"
          placeHolder="Your Status..."
          value={status || ""}
          onChange={handleInputChange}
        /> */}

      <label>활성화 여부1</label><br/>
      <select  name="status" 
        value={status || "NO"}
      onChange={e=> {
        console.log("e");
        console.log(e.target.value);
        handleInputChange(e, e);
      }}>
        <option value="NO">NO</option>
        <option value="YES">YES</option>
      </select>

      <label>활성화 여부2</label><br/>
      <select  name="status2" 
        value={status2 || "NO"}
      onChange={e=> {
        console.log("e");
        console.log(e.target.value);
        handleInputChange(e, e);
      }}>
        <option value="NO">NO</option>
        <option value="YES">YES</option>
      </select>

      <label>활성화 여부3</label><br/>
      <select  name="status3" 
        value={status3 || "NO"}
      onChange={e=> {
        console.log("e");
        console.log(e.target.value);
        handleInputChange(e, e);
      }}>
        <option value="NO">NO</option>
        <option value="YES">YES</option>
      </select>

      <label>활성화 여부4</label><br/>
      <select  name="status4" 
        value={status4 || "NO"}
      onChange={e=> {
        console.log("e");
        console.log(e.target.value);
        handleInputChange(e, e);
      }}>
        <option value="NO">NO</option>
        <option value="YES">YES</option>
      </select>
      <label>활성화 여부5</label><br/>
      <select  name="status5" 
        value={status5 || "NO"}
      onChange={e=> {
        console.log("e");
        console.log(e.target.value);
        handleInputChange(e, e);
      }}>
        <option value="NO">NO</option>
        <option value="YES">YES</option>
      </select>
      <label>활성화 여부6</label><br/>
      <select  name="status6" 
        value={status6 || "NO"}
      onChange={e=> {
        console.log("e");
        console.log(e.target.value);
        handleInputChange(e, e);
      }}>
        <option value="NO">NO</option>
        <option value="YES">YES</option>
      </select>

      <label>활성화 여부7(PC/노트북)</label><br/>
      <select  name="status7" 
        value={status7 || "NO"}
      onChange={e=> {
        console.log("e");
        console.log(e.target.value);
        handleInputChange(e, e);
      }}>
        <option value="NO">NO</option>
        <option value="YES">YES</option>
      </select>
      <label htmlFor="contact_ip">아이피</label>
        <input
          type="text"
          id="contact_ip"
          name="contact_ip"
          placeHolder="Your Ip..."
          value={contact_ip || ""}
          onChange={handleInputChange}
        />



        <input type="submit" value={id ? "Update" : "Save"} />
      </form>
    </div>
  );
};

export default AddEdit;
