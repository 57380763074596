
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,11,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`위선`,9,10,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기자 않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`위선`,10,9,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,11,8,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,7,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`위선`,1,6,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`위선`,2,5,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,4,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,3,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`위선`,5,2,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,6,1,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`위선`,7,12,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`9`,`내외단합`,``],
    [`자녀`,`자녀 문제사`,`大`,`소외기`,`고위연회`,`내외단합`],
    [`필방촉화(必防燭火)`,`반드시 불에 놀라게 되므로 방비를 잘 해야 할 상`,`大`,``,`열석길사`,`부부불화`],
    [`가실불화(家室不和)`,`부부불화사가 있는 상`,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,`고위안좌`],
    [`문서`,`문서 관련사-파재극처실종사 우려`,`大`,`8`,``,`衆人이익`],
    [`모친`,`모친 관련사-음1,2,,7,8,10,11월방문자는 묘지사임`,`小`,`침체기`,`타인수혜`,`강구展布`],
    [`존례미면(尊禮未免)`,`피차 예의를 차리지 못하여 불편한 상`,`小`,``,`불변근신`,`파재극처`],
    [`불변근신(宜不辨勤愼)`,`장차를 위해 말을 삼가하고 근신해야 할 싱`,`大`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`7`,`구설사공`,``],
    [`출행`,`外동요분산사-내외 원행이동사 가 있는 상`,`小`,`단절기`,`동요분산`,`관직영전`],
    [`고위임외(高位臨畏)`,`고위인이 가택을 찾아오는 두려운 일이 있게되는 상`,`大`,``,`출행이동`,`원행이동`],
    [`구설송사(口舌訟事)`,`구설송사 凶-문서 또는 소식은 吉`,`小`,``,``,``],
    [`금전`,`금전 관련사`,`大`,``,``,`눈이 가려`],
    [`사업`,`사업 관련사`,`小`,`6`,``,`진격`],
    [`훼절`,`여러 일을 결속 음7월 재정바탕을세우나 직위남편 훼절함 `,`小`,`존재기`,`압박가함`,`손해의혹`],
    [`엄목지상(掩目之象)`,`눈을 가리는 격이나 사람은 상하지 않는 象`,`大`,``,`도적소실`,`가출도망`],
    [`자원조력(自願助力)`,`상대가 스스로 와서 돕는 상`,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`5`,``,``],
    [`부친`,`부친 관련사`,`小`,`배양기`,`사행불량`,`가정막힘`],
    [`기이동야(豈利動也)`,`어찌 굳이 이익을 위해 적극 나서는가-의 상`,`小`,``,``,`불의투서`],
    [`앙화소멸(殃禍消滅)`,`무서운 재난이 없어지는 象`,`大`,``,``,``],
    [`관사`,`강제급속손실사`,`大`,``,``,``],
    [`송쟁`,`급속송쟁사-애증화합-상해우려`,`大`,`4`,`사통사有`,`사통사有`],
    [`송쟁`,`외사 급속송쟁사-내사 급속사`,`小`,`개시기`,`급속송쟁`,`금속사有`],
    [`필주사통(必主私通)`,`반드시 남녀 사통사가 있는 상`,`小`,``,`상해우려`,`약속비밀`],
    [`우유한가(優遊閑暇)`,`머리 다듬는 한가함-실업자 상`,`小`,``,``,`누설`],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`직업`,`직업 관련사`,`大`,`3`,`투서헌책`,``],
    [`남편`,`남편 관련사`,`小`,`학습기`,`고위면접`,`관재형책`],
    [`악처불효(惡妻不孝)`,`억지로 얻은 처는 부모에게 불효하게 됨`,`小`,``,``,``],
    [`우유한가(優遊閑暇)`,`느긋하게 즐기는 상-실업자 격`,`小`,``,``,``],
    [`후원`,`후원조력사`,`小`,``,``,``],
    [`문서`,`문서 관련사`,`大`,`2`,``,``],
    [`결속`,`여러 일을 결속 음7월 재정바탕을세우나 직위남편 훼절함 `,`小`,`면모기`,`눈이가려`,`충산분산`],
    [`길흉무실(吉凶無實)`,`좋고 나쁘건 간에 모두 실효가 앖는 상`,`小`,``,`진격`,``],
    [`도적소실(도적소실)`,`도적이 길을 잃은 격`,`大`,``,``,``],
    [`명예`,`수상(受賞)직녹자산권리사`,`小`,``,``,``],
    [`매사 허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`1`,``,``],
    [`매사 영속성없음`,`명예직녹권리자산사는 선천적 숙명재해를 겪음-독신고독`,`大`,`절정기`,`외교막힘`,`화합허사`],
    [`음인재득(陰人財得)`,`독신 고독한 처지임-비정상 정인의 도움을 받는 상`,`小`,`세속허사`,``,``],
    [`우유한가(優遊閑暇)`,`머리 다듬는 한가함-실업자 상`,`大`,`자로무망`,``,``],
    [`자매`,`형제자매친구동지재손여인사-외남 외녀 동시 색정사 有`,`小`,``,``,``],
    [`매사허성불성`,`음3,6,7,8,9,12월 방문자는 종교진출하면 성공함`,`小`,`12`,``,`색정음사`],
    [`매사 영속성없음`,`형제자매친구동지재손여인사는 선천적 기복재해를 겪음`,`大`,`과시기`,`색정음사`,`불변근신`],
    [`초수안일(稍遂安逸)`,`소극적 분수를 지켜 평안한 상`,`小`,``,`도적실세`,`말삼가고`],
    [`우유한가`,`머리를 다듬는 한가로움-실업자의 상`,`大`,``,``,`근신필요`],
    [`후원`,`후원조력사-공허`,`小`,``,``,``],
    [`문서`,`필수 문서사`,`大`,`11`,``,`가정불안`],
    [`부친`,`부친관련사`,`小`,`쇠퇴기`,`음사폐호`,`동요분산`],
    [`문서필수(文書必遂)`,`반드시 문서사가 있게 되는 상`,`小`,``,`가택안녕`,`문서유실`],
    [`불변근신(宜不辨勤愼)`,`말조심하고 근신해야 할 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`小`,`10`,``,``],
    [`자녀`,`자녀 문제사-임신이 안 되는 상`,`大`,`침해기`,`손해의혹`,`위례득죄`],
    [`자녀간난(子女艱難)`,`자녀의 간절함과 어려움이 있는 상`,`大`,``,`도망사有`,``],
    [`무례득죄(無禮得罪)`,`예의를 크게 벅어나 죄에 이르는 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`직업직위불안부부갈등자녀문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,"자매친구동료모친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`급속손실사직업남편관련사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`관사직업남자권리문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,"형제친구동료부친조력으로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,0,`관사급속송쟁손실동요불안분산사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,0,`관사직업남편관련사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,`후원자매친구동료조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,0,`부부색정사세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`후원문서부친조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`직업직위불안부부갈등자녀문제로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS = [
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속전속결로 종결해야만 유리하고 미적거리거나 늦으면 되려 불리해짐`,0,0,`당연한 일이므로 정정당당하게 좌고우면 말고 속결해야만 유리한 人`,0,0,`현재 일에서 물러나는 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`웃음 속에 칼이 들어있는 상으로 타인의 선심을 쉽게 받아들이면 후회함**일이 띄엄띄엄 진행되어 물러나려 하나 되려 진행하게 됨**옛일을 되돌아보는 상**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속전속결로 종결해야만 유리하고 미적거리거나 늦으면 되려 불리해짐`,0,0,`당연한 일이므로 정정당당하게 좌고우면 말고 속결해야만 유리한 人`,0,0,`신규사가 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 유순 유화적 언행과 처신으로 일을 뒤로 미뤄야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 나서지 말고 순응하며 후응해야만 결과가 좋은 人`,0,0,`여러 일을 하나로 묶지만 본인에게는 이로움이 없고 쇠해지는 사안임**어디 산속으로 깊이 숨어버리고 싶은 심정**여건 조건아 불비부족한 상태임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`제사지낼 혈육이 끓어지는 상**사물이 돌연히 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오니 신중해야 함`,0,0,`이심사로 반목대립하여 반복적 색정사가 있음`,0,0,`신규사가 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 유순 유화적 언행과 처신으로 일을 뒤로 미뤄야만 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 나서지 말고 순응하며 후응해야만 결과가 좋은 人`,0,0,`사물이 돌연하게 발생함**관공직은 영전함**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 행동하여 속전속결로 종결해야만 유리하고 미적거리거나 늦으면 되려 불리해짐`,0,0,`당연한 일이므로 정정당당하게 좌고우면 말고 속결해야만 유리한 人`,0,0,`여러 일을 하나로 묶지만 본인에게는 이로움이 없고 쇠해지는 사안임**일이 결과없이 반복됨**여건 조건아 불비부족한 상태임**입을 닫음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위위(狐假虎威)세**만약 타인과 다툰다면 호가호위적 위세로 상대를 공격해도 적중되지 않고 실효됨`,0,0,`꾸민 위세가 효과를 이루지 못함`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 띄엄띄엄 진행되어 결과가 없음**어디 깊은 산속으로 숨고싶은 심정**서리내린 다리를 걷는 격**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**천둥에 놀란 듯한 사안**만약 타인과 다툰다면 피아간 전의가 별로 없고 손상도 없으며 있다 해도 가벼움 `,0,0,`꾸민 일이 들통나 심중 천둥치듯 크게 놀래고 있으나 언행을 조심하고 조용히 있으면 자연 해소될 人`,0,0,`결과가 없을 일에서 물러나야만 이로움-단 가을 방문자이면 득성함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 동해도 움직이지는 않음`,0,0,`옛것을 지키며 새로 다가오는 기회를 기다리는 상**고등시험 준비생이 많음-실패함`,0,0,`고집이 있고 신규사가 있음**독신고독각방주말부부별리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성-무력`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이득인`,`아발동사`,`기사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`기사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면`,`선성-무력`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`이외사`,`기사`,`신사`,`돌연사`,`모호함`,`배신`,`보성-무력`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`이면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사-후회사 겹침`,`미사`,`신사`,`외사`,`표면이면사겹핌`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-가출사우려`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥-가출사우려`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-이동이사예정-실종사우려`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 후승`,`후승`,` 후승`],
    [``,``,``],
    [` 초승`,`초승`,` 초승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [` `,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 초승`,`초승`,` 초승`],
    [``,``,``],
    [` 후승`,`후승`,` 후승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``],
    [` 무력`,`무력`,`무력`],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음2월`,1,3,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음1월`,3,2,5,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음음12월`,2,2,4,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,3,1,4,`음5월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,2,2,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음5월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음2월`,1,1,2,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음5월`,2,1,3,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,2,2,4,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음5월`,2,1,3,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음4월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음3월`,3,3,6,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세나 업무는 기대이상의 빛을 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하나 초반의 무력을 딛고 중종반에 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 중반에 두각을 나탄게 되나 초 종반은 일반에서 떨어짐`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하며 업무 전반이 실패로 끝남`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세고 중반의 기회를 놓치면 실적이 실패함 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 업무는 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이며 중반에 전력하여 업적을 쌓게 되고 초중반에는 미달됨 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인저이는 있어도 사심이 많아 결속단합을 시도하나 불성하고 막대한 손실을 끼침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 업무처리가 강하지 못해 반목대립으로 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 업무처리가 강하지 못해 초중말 실적이 없게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조금하며 중반 실적 외에 초 종반에는 미달됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 초중말 내내 반목대립 소송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`多集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`후이`,`進向`,`원`,`留`,`주인가해`,`정동`,``,`정북`],
    [`10.11`,`  集`,``,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`무력`,`이행`,`선加후이`,`退背`,`  원`,`  去`,`주인가해`,`동남`,``,`북북동`],
    [`10.11`,``,``,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`무력`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`상호파재`,`동남`,``,`동북`],
    [`10.11`,``,``,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`결속단합`,`退背`,`  근`,`  去`,`세입손해`,`남남동`,`동북`,`정동`],
    [`10.11`,`多集`,``,``,`느긋이`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부제압`,`이행`,`선손후이`,`退背`,`  원`,`  去`,`세입가해`,`정남`,`남남동`,`동남`],
    [`10.11`,``,`함`,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`상호불안`,`남남서`,`북북동`,`정남`],
    [`10.11`,``,``,``,`빠르게`,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`상대무력`,`進向`,`   원`,`  留`,`주인무력`,`북북서`,`북북동`,``],
    [`10.11`,``,``,``,``,``,``,``,``,`세밀심사`,`세밀심사`,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`상대무력`,`退背`,`  근`,`  去`,`주인무력`,`서북`,`정남`,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`다集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대있음`,`이행`,`선이후난`,`退背`,`  원`,`  去`,`무난`,`북북서`,`동남`,``],
    [`10.11`,`  集`,`본인제압`,``,`빠르게`,``,``,``,``,``,`세밀심사`,``],
    [`3.6\n9.12`,``,`함`,``,``,``,``,``,``,``,`함`,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`내부반대`,`이행`,`선不후이`,`退背`,`  원`,`  去`,`주인후원`,`정북`,``,`남남동`],
    [`10.11`,``,`발현안됨`,``,`천천히`,``,``,``,``,``,``,`세밀정단`],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부동조`,`이행`,`선不후不`,`退背`,`  근`,`  去`,`주인후원`,`북북동`,``,`북서`],
    [`10.11`,``,`중반제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대있음`,`이행`,`선이후난`,`退背`,`  근`,`  去`,`무난`,`동북`,``,`북북서`],
    [`10.11`,``,`내부제압`,``,`빠르게`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`됨`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [`질병`,``,``,``,``,`묘좌`,`장동`,`없음`],
    [``,``,``,``,``,`묘향`,`정서`,``],
    [`생업`,``,``,``,``,`전경`,`습지`,``],
    [``,``,``,``,``,`묘속`,`목근`,``],
    [`가정`,``,``,``,``,`육친`,`자식.4대祖`,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`두성부정`,`기도위배`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`전흉사`,`혈광사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`가단부정`,`성소훼손`,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,`제례귀`,`제례위범`,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`두성부정`,`기도위배`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,`도로귀`,`교툥사`,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`도로귀`,`교통사망`,``,``,`묘좌`,`남남동`,`없음`],
    [``,``,``,``,``,`묘향`,`북북서`,``],
    [`생업`,``,``,``,``,`전경`,`습지`,``],
    [``,``,``,``,``,`묘속`,`뱀굴`,``],
    [`가정`,`변사귀`,`객사`,``,``,`육친`,`처,증조부`,``],
    [`질병`,`변사귀`,`객사`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`가당부정`,`성소훼손`,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,``,`상위 대喜- 하인 수도자 격 `,`내외단합결속사`,`소식 대기-와도 불리함`,``,`관직자 개통영전이동사- 일반인 실종사 우려`,`외내사 출행원행이동사`,`고위안좌-이득경영사**음1,2,10,11월 부자`,``,`부부남녀가정불화사`,``,`가정막힘**위례득죄사`,`외사애증사`,`구설쟁송은 흉-문서 소식은 길함`,``,`큰 뜻 말 참고 근신이 좋음`,``,`고위연회길사`,`매사허성불성**세속허사 자**독신 또는 고독`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`매사영속성없음`,`종교진출하면 자립함`,`매사위역`,`내부가정동요불안사-도적관련`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,`전화위복`,``,``,``,``,``,``,`내외 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`선천 숙명적독신고독자`,`성공함`,`남녀종업원모사有`,`부부남녀모두색정사有`,`전화위복`,`침체경구사有`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역`,`내부가정동요불안사-도적관련`,``,`상위 대喜-하위수도자 격`,`내외단합결속사`,`소식 대기-와도 불리함`,``,`관직자 개통영전이동사-일반인 실종사 우려`,`외내사 출행원행이동사`,`고위안좌-이득경영사**음1,2,10,11월 부자`,``,`부부남녀가정불화사`,``,`가정막힘**위례득죄사`,`외사애증사`,`구설쟁송은 흉-문서소식은 길함`,``,`큰 뜻 말 참고 근신이 좋음`,``,`고위연회길사`,`매사영속성없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`전화위복`,`재난해소`,``,``,`재난해소`,``,`전화위복`,``,``,``,``,``,``,`내외 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`선천 숙명적 독신고독자`,``,``,`부부남녀모두색정사有`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`매사영속성없음`,`종교진출하면 자립함`,`매사위역`,`내부가정동요불안사-도적관련`,``,`상인 대喜-하인수도자 격`,`내외단합결속사`,`소식 대기-와도 불리함`,``,`관직자개통영전이동사-일반인 실종사 우려`,`외내사 출행원행이동사`,`고위안좌-이득경영사**음1,2,10,11월 부자`,``,`부부남녀가정불화사`,``,`가정막힘**위례득죄사`,`외사애증사`,`구설쟁송은 흉-문서소식은 길함`,``,`큰 뜻 말 참고 근신이 좋음`,``,`고위연회길사`,`매사영속성없음`,`종교진출하면 자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`부부남녀모두색정사有`,``,`전화위복`,``,`재난해소`,``,`재난해소`,``,`전화위복`,``,``,``,``,``,``,`내외 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`선천 숙명적 독신고독자`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`매사영속성없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`불겸약 자초 罪사`,`내부가정동요불안사`,`투서헌책불용-불득용사-또는 금전실재사`,``,`눈이가려져 사람이 다치지 않는 상`,`내외단합결속사`,`고위연회길사`,`일반인 파재극처실종사 우려`,``,`느긋하게 쉼-직업없음**외내사 출행원행이동사`,``,`느긋히 쉼**출행원행이동사`,`가정막힘**도적소실사`,`매사위역`,`희경사-부족함`,`외사애증사`,`재앙소멸사`,``,`사시득재사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요분산사`,`선천 숙면적 독신고독자`,`부부남녀모두 색정사有`,``,`재난해소`,`전화위복`,``,``,``,``,`재난해소`,`전화위복`,``,``,``,``,``,`재난해소`,`내외 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,`재난해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`사기득재사`,``,`매사 영속성 없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`내부가정동요불안사`,`불검약 자초 罪사`,`투서헌책불용-불득용사 또는 금전실재사`,``,`눈이 가려져 사람이 다치지 않는 상`,`내외단합결속사`,`고위연회길사`,`일반인 파재극처실종사 우려`,``,`느긋하게 쉼-직업없음**외내사 출행원행이동사`,``,`느긋히 쉼**출행원행이동사`,`가정막힘**도적소실사`,`매사위역`,`희경사`,`외사애증사`,`재앙소멸사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`동요분산사`,`선천 숙명적 독신고독자`,`부부남녀모두색정사有`,``,``,`전화위복`,`재난해소`,``,`재난해소`,``,``,`전화위복`,``,``,``,``,``,``,`내외 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`재앙소멸사`,``,`사기득재사`,``,`매사 영속성 없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`내부가정동요불안사`,`불검약 자초 罪사`,`투서헌책불용-불득용사 또는 금전실재사`,``,`눈이 가려져 사람이 다치지 않는 상`,`내외단합결속사`,`고위연회길사`,`일반인 파재극처실종사 우려`,``,`느긋하게 쉼-직업없음**외내사 출행원행이동사`,``,`조행불량`,`가정막힘**도적소실사`,`매사위역`,`희경사`,`음4,5월 화재주의`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`음4,5월 화재주의`,`동요불안분산사`,``,`동요불안분산사`,`선천 숙명적 독신고독자`,`부부남녀모두색정사有`,`동요불안분산사`,`동요불안분산사`,`전화위복`,`동요불안분산사`,``,`동요불안분산사`,``,`전화위복`,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,``,`동요불안분산사`,`외사애증사`,`내외 급속사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`희경사`,`외사애증사`,`재앙소멸사`,``,`사기득재사`,``,`매사 영속성 없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`내부가정동요불안사`,`불검약 자초 罪사`,`투서헌책불용사 또는 금전실재사`,``,`눈이 가려져 사람이 다치지 않는 상`,`내외단합결속사`,`고위연회길사`,`일반인 파재극처실종사 우려`,``,`느긋하게 쉼-직없없음**외내사 출행이동원행사`,``,`총애다툼사`,`가정막힘**도적소실사`,`매사위역`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`내외 급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`동요분산사`,`선천 숙명적 독신고독자`,`부부남녀모두색정사有`,``,``,`전화위복`,``,``,``,``,`전화위복`,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`매사위역`,`가정막힘**도적소실사`,`외사애증사`,`희경사`,`재앙소멸사`,``,`사기득재사`,``,`매사 영속성 없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`내부가정동요불안사`,`불검약 자초 罪사`,`투서헌책불용-불득용 또는 금전실재사`,``,`눈이 가려져 사람이 다치지 않는 상`,`내외단합결속사`,`고위연회길사`,`일반인 파재극처실종사 우려`,``,`느긋하게 쉼-직업없음**외내사 출행원행이동사`,`총애다툼사`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`내외급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`동요분산사`,`선천 숙명적 독신고독자`,`부부남녀모두색정사有`,``,`전화위복`,``,``,``,``,``,``,`전화위복`,`재난해소`,``,`재난해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`총애다툼사`,``,`매사위역`,`가정막힘**도적소실사내부가정`,`외사애증사`,`희경사`,`재앙소멸사`,``,`사기득재사`,``,`매사영속성없음`,`종교진출하면 자립함`,`매사영속성없음`,`종교진출하면 자립함`,`내부가정동요불안사`,`불검약 자초 罪사`,`투서헌책불용-불득용 또는 금전실재사`,``,`눈이 가려져 사람이 다치지 않는 상`,`내외단합결속사`,`고위연회길사`,`일반인 파재극처실종사 우려`,``,`느긋하게 쉼-직업없음**외내사 출행원행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`내외급속사`,`음4,5월 화재주의`,``,`음4,5월 화재주의`,``,``,`동요분산사`,`선천 숙명적 독신고독자`,`부부남녀모두색정사有`,``,`전화위복`,``,``,``,``,``,`전화위복`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`외내사 원행이동사`,`고위안좌-이득경영사**음1,2,10,11월 부자`,``,`부부남녀가정불화사`,``,`가정막힘**위례득죄사`,`내외 급속사**외사애증사`,`구설쟁송은 흉, 문서소식은 좋음`,`큰 뜻 말 참고 근신이 좋음`,`구설쟁송은 흉-문서소식은 길함`,``,`고위연회길사`,`매사영속성없음`,`종교진출하면 자립함`,`매사영속성 없음`,`종교진출하면 자립함`,`매사위역`,`내부가정동요불안사-도적관련`,``,`상인 대喜-하인수도자 격`,`내외단합결속사`,`소식 대기-와도 불리함`,``,`관직자개통승진이동사-일반인 실종사 우려`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,`음4,5월 화재주의`,`재난해소`,`음4,5월 화재주의`,`재난해소`,``,``,``,`선천 숙명적 독신고독자`,`부부남녀모두색정사有`,``,`전화위복`,``,``,``,``,``,`전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`관직자개통영전이동사-일반인 실종사 우려`,`외내사 원행이동사`,`고위안좌-이득경영사**음1,2,10,11월 부자`,``,`부부남녀가정불화사`,``,`가정막힘**우례득죄사`,`음4,5월 화재주의`,`구설쟁송은 흉-문서소식은 좋음`,`큰 뜻 말 참고 근신이 좋음`,`구설쟁송은 흉-문서소식은 길함`,``,`고위연회길사`,`매사영속성없음`,`종교진출하면 자립함`,`매사허성불성**세속허사자`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`매사위역`,`내부가정동요불안사-도적관련`,``,`상인 대喜-하인수도자 격`,`내외단합결속사`,`소식 대기 와도 불리함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`전화위복`,``,``,``,``,``,``,`외사애증사`,`내외 급속사`,`음4,5월 화재주의`,``,``,``,`선천 숙명적 독신고독자`,`성공함`,`부부남녀모두색정사有`,`성공함`,`전화위복`,``,`재난해소`,`폐한인`,``,`재난해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`내외단합결속사`,`소식 대기-와도 불리함`,``,`관직자영전이동사-일반인 실종사 우려`,`외내사 원행이동사`,`고위안좌-이득경영사**음1,2,10,11월 부자`,``,`부부남녀가정불화사`,``,`가정막힘**위례득죄사`,`내외 급속사**외사애증사`,`구설쟁송은 흉-문서소식은 좋음`,`큰 뜻 말 참고 근신이 좋음`,`구설쟁송은 흉-문서소식은 길함`,``,`고위연회길사`,`매사허성불성*세속허사 자**독신 또는 고독`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`매사허성불성**세속허사 자**독신 또는 고독`,`음3,6,7,8,9,12월 방문자는 종교진출하면`,`매사위역`,`내부가정동요불안사-도적관련`,`상인 대喜-하인수도자 격`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`전화위복`,``,``,``,``,``,``,``,`음4,5월 화재주의`,`재난해소`,`음4,5월 화재주의`,`재난해소`,``,``,`선천 숙명적 독심고독자`,`성공함`,`부부남녀모두색정사有`,`성공함`,`전화위복`,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
const CHOSA1 = [`■재판이 벌어지겠네! 아직 때가 아닌데 새일로 나서고보니 비용감당이 안되는거고, 訟事로 가정도 불편하고! 
⊕전사귀, 
⊖변사귀가 골목을 지키고, 가택에는 가당신사 부정이 들고! 
아이들이 당하게 생겼어!

⊕신변상가부정이 들었어!
`,`■ 노력한 만큼의 댓가는 있겠지만 진퇴의 기미를 잘 보아야 하겠어! 
어른의 재해가 걱정이야 !
`,`■ 물구덩이에서 허덕이지 말고 급히 방침ㆍ계획을 확 바꿔! 옛일로 돌아가는 조짐이야 ! 지금대로는 다 틀리게 생겼어! 
⊕가택귀, 가택음사
부정이 들었다. 
⊖도로귀의 시기야!

⊖신변 음사부정이 들었어!
`,`■이사도 하고 새 일을 시작    하겠는데, 
뱀이 용 되는 상이지만 아직
때가 아니네 ! 
칠월(七月)이 와야 해 !
⊕전사귀, 
⊖변사귀가 발동해서 내 몸을 치니 본인이 관직자라면
좋으나 일반인은 관재야!
`,`■ 한두가지가 아닌 일로 비용께나 나가는데,

男:직위가 흔들리니 새 일을 
    찾아 봐야겠네?
女:부부가갈라서게되겠어!
⊕가택음사부정이 들었다.
⊕⊖신변두성불안부정이
    침범했네 ?
`,`■ 풍파는 심하고, 일은 갑자기 벌어지고, 제사 지내 줄 자식은 걱정이고!
급히 계획과 방침을 바꿔야 겠어! 
⊕전흉사귀, 
⊖여귀 발동으로 그렇군! 
내 돈이 오히려 병ㆍ송사를 부르는 격이야!
`,`■ 색정불화로 의견이 안맞는데 새로 하려는 일이 잘 되겠어? 어디 의지할데도 없겠어!

⊕⊖가택에 가당신사 부정이 들어 돈ㆍ여자 구설을 못 면하겠어!

⊖신변상가부정이 들었어!
`,`■ 집을 고치겠네 ! 이사를할 운인데 우연한 인연이 와서 돈도 나갈텐데, 酉년이나 八월에 좋은 일이 있을테니 아까워할 이유가 없겠어!
`,`■ 역량은 부족한데 비용만 계속나가게되어있겠어! 일도 반복되기만 하고!

男:자칫七月에 직위해제 
    되겠네?
女:자칫 七月에 부부이별 
    하겠네!
⊕신변음사부정이 들었어!
`,`■새 일을 시작하겠는데 도망가고싶은 관사만 벌어지겠어!

⊕신불제사귀, 
⊖음귀가 발동해서 그래!

상대를 아무리 쏴대도 적중이 안되겠어 !
`,`■직․녹이 훼손되게 생겼네?기도ㆍ치성외에 방법이 없겠네? 효과도 있겠고! 얇은 얼음을 밟고 강을 건너는 격이야 !
⊕가택귀, 
⊖도로귀가 물구덩으로 
  몰아가고 있어!
⊕⊖신변두성불안부정이       침범했네 ?
`,`■ 진퇴양난격일세? 
심중에 두려움이 가득인데,

⊕전사귀ㆍ가택귀, 
⊖변사귀ㆍ도로귀가집과 일에 붙어 나가지를 않고 있어! 
해소하고 기도하면 걱정은 해소 돼 !`];
const YOGYEOL1 = [`■ 빈 녹은 마땅히 버리라. 사업을 시작하면 돈이 생긴다. 낮정단은 관살이 장생이 되고 밤정단은 아니므로 진가를 가리라.
`,`■ 처음 사업을 시작하려면 봄과 여름을 피하고 가을에 하라. 또 자세히보니 낮정단은 좋으나 겨울 밤정단은 화재를 조심하라.
`,`■ 서로 불화한다. 누군가가 자꾸 교사한다. 높은이가 낮은 자에게 구하니 재앙이나 화입을까 주의하라.
`,`■ 밤정단은 장생이 관살이 되고 낮정단은 관살이 장생이 된다. 말전이 생극을 상반하니 패하기도 하고 이루기도 하리라. 
`,`■ 상대가 나에게 와서 구한다. 얼핏 아름답지 않는 것 같으나 모든 일이 잘 되어 일을 이루게 되리라.
`,`■ 아래에서 위를 범하니 제사가 끊어지게 된다. 중간에 숨은 관살이 있으니 움직이면 재앙을 당한다.
`,`■ 움직일 생각은 간절하나 보이는 것 모두 비고 끊어진 것이다. 밤정단은 다섯범을 만나니 놀래는 것을 어찌 말로 다하랴.
`,`■ 내가 돈을 먼저 써야 나중에 크게 얻는다.
앞에서 끌고 뒤에서 밀어서 낮정단은 닭해나 음 팔월이 좋다.
`,`■ 과전이 순환고리가 되어 손해만 난다. 신경쓰지 말고 타인에게 의지하라. 귀인의 도움을 받기 어렵기 때문이다.
`,`■ 밤정단은 귀인이 입을 다물고 돈 보따리를 싣고 도망간다. 다행히 장생을 만났으나 허무한 것이 따를 따름이다.
`,`■ 처음과 중간이 비었고 두 범이 근심이다. 돕기도 하고 괴롭히기도 하니 즐거움 끝에 근심이 생기리라.
`,`■ 사람과 집이 서로 합하고 귀인을 껴안고 있다. 쑥대로 만든 화살에 맞아도 촉이 없으니 두렵지 않다.`]
const SIBO1 = [`■관사가 있다.
⊕⊖가당신사 부정이 듬
凶을 凶으로 제어한다.
적덕하면 吉로 변해 개화한다. 신규사로 매진하고 개혁하라. 반드시 좋아진다. 他人을 접촉하지 말고 수양에 매진하면 된다.
女는 시집가기를 원한다.
자칫직위가 훼손되고 가을에직ㆍ녹이다시 성립하는 일이 벌어진다.
`,`■가택이 손상한다.
계획사는 위험하다.
⊖財事가 간난한다.
분수를 알고 활약하면 돈이 된다. 女子와 小人은 凶이 초래되고 中上流人은 吉하다. 
고심한후 편안함이 있게 된다. 후원받는 일에 凶이 있으니 경계ㆍ심사하라. 四月 임신사 有
上人의 말을 면밀히 분석하여 대비하라.
`,`■자충수적 우환이 있다.
가택이 손상한다.
몸이 傷하게 된다.
他人에게 의존한다.
계획과 방침을 급히 변경하라. 그대로 진행하면 불성한다. 他人으로부터 돈을 받을 때 주의하라. 
災가 되는 돈이다.
人宅이 불안한 사정이나 吉化된다.

`,`■자충수적 우환이 있다.
후원의 조건에 대해
숙고해야 한다. 害가 있다.
상류인ㆍ관직자는 좋고 일반인은 나쁘다.
신규사로 매진하고 개혁을 해야 한다. 재해는 없으나 吉한 일을 만나 비애를 겪게 된다.
가택 이동의 兆가 있다.
`,`■부부가 共히 음탕하다.
女ㆍ婦ㆍ상대는 헌신한다.
관재가 있다. 계획사는 위험하다. 

⊕그러나 구해주는 사람이 있게 된다. 아랫사람을 경계하고 친목하라. 길흉이 下者에서 발생한다. 목적사는 반드시 달성된다.
`,`■계획사는 위험하다.
⊕下가 上을 기만한다.
가족이 불화한다.
가택의 害가 있다.
가장에게 厄이 있다.
실업자 전락의 근심이 있다. 女ㆍ小人은 예의를 잃고 고독하다. 부녀자가 문제이다.
골육이 분리된다.
후원사의 조건을 경계해야 한다. 독이된다.
四月에 임신사가 있다.
`,`■이별사가 있다.
사물이 양갈래이다.
⊕⊖가당신사 부정이 듬
치성해야 한다.
動이 불가능한 형세이다. 吉凶 모두 공허하다.
스스로 자초한 셈이다. 
고독하고, 심신의 의지처가 없다. 기회가 오기를 기다리라.
가을이다.
금전에 후원이 따른다.
`,`■부부이심 이별사가 있다.
폐지사를 부흥시키려는 兆
후원사의 조건을 경계ㆍ
심사하라. 독이 있다.
금전ㆍ여자 문제가 현안사이다. 처음에는 열리지 않으나 후에 大財ㆍ현녀를 얻게 된다. 
가옥을 수조하게 된다.
吉하다.
秋月에 기회가 온다.
형제의 재해가 발생한다.
`,`■男ㆍ夫가 헌신하나 능력
이 없다.
손모사가 계속된다.
불명예ㆍ직위해탈이 있게 된다. 사물이 공허하여 실익이 없다. 가을ㆍ겨울이라야 호전된다.
그 안에는 허비하면서도 극해를 받게 된다.
⊕⊖계획사는 위험하다.
후원사에 비용이 든다.
`,`■부부이심 이별사가 있다.
돈ㆍ여자 소송사가 있다.
가을에 발현된다.
금전과 여자가 충산된다.
금전은 먼데서 빨리 서둘면 된다. 모든 일은 외부에서 발생한다. 
사기에 걸리게 된다.
모든 일은 초기, 첫 조건에 決 하라.
직위가 훼손된다.
`,`■가내 우환이 있고, 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
서둘 것이 없다. 
어차피 처음과 중간에는 일이 안된다. 
얇은 얼음판을 건너는 격이다.
秋月이 와야만 기회를 잡는다. 구사일생이다.
후원사에는 금전이 든다.
형제의 재액이 생긴다.
`,`■가내 우환이 있고 가장을
속인다. 가인이 원한을
품고 加害하려 한다.
일이 두가지이다.
후원사에는 손실이 따른다. 
심중 두려움이 있다. 
그러나 凶은 없게 된다. 
후퇴하라. 小事는 吉, 大事는 凶이 된다.
일의 성공을 위해 모사하지 말라. 성사가 된다.
형제의 재액이 생긴다.`]
const SIBO2_1 = [`■ 직녹, 신변송사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
낭비많고 불명예가 있고
손재·녀 한다.
`,`■ 임신, 가택상쟁사이다.
내방조짐 상의 :  
조용히 있는게 이롭다.
신택이 불안하다.
`,`■ 官･夫, 구사회귀사이다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
계획을 바꿔라.
`,`■ 官･夫, 신변외사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 자손, 신변ㆍ가택내외사이다. 
내방조짐 상의 :  
男직위상실하고
女부부이별한다.
`,`■官･夫, 자손걱정이 있다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
가정 우환이 있다.
`,`■ 재ㆍ여, 신변동요사이다.
내방조짐 상의 :  
움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
`,`■ 재ㆍ여, 신변동요사이다.
내방조짐 상의 :  
원행·이동사가 있다.
은둔ㆍ도망ㆍ원행에 이롭다.
`,`■ 후원, 가택내사이다.
내방조짐 상의 :  
男직위상실하고
女부부이별한다.
`,`■ 재ㆍ여, 신변동요사이다.
내방조짐 상의 :  
원행·이동사가 있다.
은둔ㆍ도망ㆍ원행에 이롭다.
`,`■ 직녹, 신변외사이다.
내방조짐 상의 :  
어떤 일이든 처음 불성한다.
낭비많고 불명예가 생긴다. 

`,`■ 官･夫,두가지 용무이다.
내방조짐 상의 :  
움직임이 근본이고 동기가 발동한다.
색정의 상이다.`]
const SIBO2_2 = []

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/017/row-1-column-1.png`,
  `/static/images/sagwa/017/row-1-column-2.png`,
  `/static/images/sagwa/017/row-1-column-3.png`,
  `/static/images/sagwa/017/row-1-column-4.png`,
  `/static/images/sagwa/017/row-1-column-5.png`,
  `/static/images/sagwa/017/row-1-column-6.png`,
  `/static/images/sagwa/017/row-1-column-7.png`,
  `/static/images/sagwa/017/row-1-column-8.png`,
  `/static/images/sagwa/017/row-1-column-9.png`,
  `/static/images/sagwa/017/row-1-column-10.png`,
  `/static/images/sagwa/017/row-1-column-11.png`,
  `/static/images/sagwa/017/row-1-column-12.png`,
]

const HEASYEOL = [
  // 1
  `  이 과는 십이신이 본궁에 있고 숨어서 동하지 않기 때문에 복음과(伏吟課)라고 부른다. 양일(陽日)은 일간상신을 쓰고 극적 대신 형(刑)을 취하여 중․말전을 정한다. 양일(陽日)은 자임격(自任格)이라고 하는데, 삼전의 사맹신(四孟神)만은 원태격(元胎格)으로 된다.
  인신사해(寅申巳亥)가 사맹신(四孟神)이고 오행(五行)이 생왕(生旺)의 위치로 처음 개화(開花)하는 상으로서 자실(子實)을 맺을 징조로 되면 회태(懷胎)를 물으면 임신한다. 명(名)을 구하면 일(日)에 융성한다. 재리(財利)를 정단하면 재원(財源)이 풍부하다. 삼전에 흉장이 승하면 길이 흉으로 변화하고, 초전의 류신에 의하여 만사는 단절된다.
  이 과는 초전이 공망하며 특히 야귀는 천후(天后)가 공망하므로 임신하지 못한다. 천후는 자(子)로서 12지(十二支)의 원(元)이며 자(子)에 통하므로 공망을 두려워하여 원태(元胎)를 양육하지 못한다고 한다. 즉 자녀(子女) 간난의 징조가 있다. 삼전의 맹신(孟神)은 상(上)에 이롭고 하(下)에 불리하다. 먼 곳에 이롭고, 가까운 곳에 이롭지 않다. 복익부동(伏匿不動)하면 길하고 동하여 접촉하면 책망이 있다. 피점자는 항상 덕(德)을 수(修)하여 적선(積善)으로써 행하면 길로 화한다.
  초전이 공망한 것은 고진격(孤辰格)이며, 어떤 일에도 공허하여 힘이 없고, 모든 일을 개혁하여 동하면 길하다. 백호가 신(申)의 금지(金地)에 승하고 구진이 사(巳)의 인(印)에 승하며 사(蛇)가 인(寅)에 승하면 시험․구관(求官)․선거 등 어느 것이나 당선 적중한다. 백법에 말하기를 ｢주객(主客)이 투합(投合)하지 않는 것은 형(刑)이 상에 있다｣고 하였다. 형이 상에 있다는 것은 삼전 또는 사과상신(四課上神)에 있는 것을 말한다. 야귀는 청룡이 인(寅)에 승하여 묘당(廟堂)에 들어간다. 사(巳)에 주작이 있고 년명(年命) 또는 행년(行年)에 생기(生氣)를 만나면 관리는 현달한다.`
  ,
  // 2
  `  이 과는 제3과에 1상이 1하를 극한다. 묘진(卯辰)을 써서 초전으로 하고 원수과(元首課)로 된다. 삼전은 인묘축(寅卯丑)으로 역연여격(逆連茹格)이며, 일간의 재신(財神)이 일지상(日支上)에 임하여 초․중전을 구성하기 때문에 재(財)가 지나치게 다난한 상이다. 분수를 알고 적당하게 구할 때는 가장 길하다. 중전이 역마로 되고 말전에 정신(丁神)이 승하여 활동, 노력함으로써 재(財)를 쟁취한다. 원수과는 상에서 하를 극하므로 순리(順理)의 과이다. 즉 천(天)의 상(象)으로 순정(順正)하여 문란하지 않고 모든 과(課)의 수(首)를 이루기 때문에 이 같은 이름이 붙었다.
  이 과는 720과 중에 115과에 있는 길과(吉課)이지만 여자․소인(小人)은 동난의 징조가 있으며, 흉을 초래하게 된다. 그러나 상․중류 계급에게는 길행(吉幸)이 있는 과이다. 간점법(看占法)은 초전․일진신장(日辰神將)․년명(年命) 중 발전의 길흉․왕쇠(旺衰)․생묘(生墓)․합충(合冲)․형극(刑剋)․상생을 중용하여 정단(正斷)해야 한다. 함부로 자신의 육감(六感)이나 기분으로 만언(漫言)을 농하는 것은 사람을 그르치는 것이므로 상기한 일정한 원칙에 따라 정단하면 적중하지 않는 일이 없다.
  백법에 말하기를 ｢금일(金日)이 정신(丁神)을 만나는 것으로 흉으로 동하기 쉽다｣고 하였다. 비전에 말하기를 ｢묘인축(寅卯丑)의 역연여격(逆連茹格)은 고심신로(苦心身勞)한 후에 편안을 얻게 된다｣고 하였다. 삼전이 퇴(退)하는 중에 진행되며, 평화스럽고 안정되므로 곤란이 길상(吉祥)으로 변화시킨다.`
  ,
  // 3
  `  이 과는 2상에서 2하를 극하여 동일한 양극(陽剋)으로 되면 비용격(比用格)을 채용하지 않으므로 섭해과(涉害課)로 된다. 제1과는 지반 신(申)의 사맹신(四孟神)으로 되면 오신(午申)을 채용하여 초전으로 한다. 삼전이 오진인(午辰寅)이며, 역간전격(逆間傳格)으로서 고조(顧祖)라고 부르며 오화(午火)에서 인(寅)을 보면 장생으로 된다. 자손이 선조를 돌보기 때문에 이 같은 이름을 갖게 되었다.
  오(午)는 양이 극(極)하여 1음(一陰)을 생하고, 중․말전이 양계(陽界)에 있으며 스스로 웅(雄)으로 되어도 겸손한 상이기 때문에 정단사는 길하다. 사과상신(四課上神)을 분리하지 않고 삼전을 구성한다. 이와 같은 것을 회환격(廻環格)이라고 부르며 길도 흉도 모두 완전하지 않다고 보아야 한다. 일간상신은 일간을 극하고 지상신(支上神)은 일지를 극하므로 피아(彼我)가 함께 제극(制剋)을 받는다. 육소(六所)에서 제신(制神)이 없으면 화미(和美)를 얻지 못한다. 일간에서 일지상신은 재성(財星)으로 되고 재(財)는 오(午)의 귀효(鬼爻)를 생하며 일간을 극하므로 이 재(財)를 취할 때는 재화(財禍)를 초래한다. 일간상신은 초전으로 되고 말전은 일지상신에 돌아와 나는 아랫 사람에 의존하여 이익을 얻는다. 맹신(孟神)의 지반을 취하여 초전으로 되는 것은 견기격(見機格)으로, 기회를 보고 빨리 활약해야 한다. 만약 신중함이 지나칠 때는 앉아서 기회를 잃게 된다.
  원래 섭해과는 일이 지체되므로 병은 빨리 치유하기 어렵다. 임신은 태(胎)를 상한다. 어떤 일도 처음에는 어렵고 후에 쉬우며, 처음은 흉하고 후에 길하므로 대소심천(大小深淺)에 준해야 한다. 현임 관직에 있는 사람은 기쁨이 있고 상인(常人)은 신택(身宅)이 함께 불안정하다. 백법에 말하기를 ｢간상(干上)․지상(支上)이 모두 상(傷)하면 피아가 서로 손실된다｣고 하였다. 삼전이 간(干)으로부터 지(支)에 돌아오는 것은 나는 사물을 타(他)로부터 구한다. 비전에 말하기를 ｢말전은 초전을 생하고 수의 귀살(鬼殺)을 도와도 공망의 지(地)에 있기 때문에 일간은 귀(鬼)를 만나도 두려울 것이 없다｣고 하였다. 일귀는 삼전이 청(靑)․합(合)․사(蛇)이고 용은 사(蛇)로 변화한다. 야귀는 역행하여 삼전이 사(蛇)․합(合)․청(靑)이고 사(蛇)는 용으로 변화하므로 만사는 후에 길로 되고 사물이 흥성한다.`
  ,
  // 4
  `  이 과는 제1과 사신(巳申)이 일상에서 일하를 극하여 원수과(元首課)로 된다. 삼전의 사맹(四孟)은 원태격(元胎格)이다. 초전은 경(庚)에 의해 사(巳)가 장생으로 된다. 중전에 역마를 보는 삼전은 해수(亥水)에 의해 중전의 목(木)을 생하고 목(木)은 초전의 화(火)를 생하여 체생(遞生)으로 된다. 사화(巳火)는 일간을 극하고 일간상신을 발용(發用)으로 하며 구진이 승하는 것은 봉인(封印)이라고 하는데, 관직에 있는 사람은 현달이 의심이 없고, 상인(常人)은 길하지 못하다. 그러나 귀(鬼)가 공망의 지(地)에 있기 때문에 일간을 상할 수 없다. 그 위에 말전의 수(水)는 화귀(火鬼)를 제어하므로 재해가 없다.
  백법에 말하기를 ｢삼전이 체생(遞生)하더라도 일간을 극하면 즐거움 후에 비애를 초래하는 상이다. 일귀는 순행하여 삼전이 구(勾)․사(蛇)․음(陰)이고, 어느 것이나 흉장이다. 야귀는 역행하여 삼전이 주(朱)․청(靑) ․상(常)이고 어느 것이나 길장(吉將)이다. 천후는 개이고 후에 비로 되는데, 태풍의 여하를 정단하는 데는 전적으로 백호를 본다. 기(氣)가 있는 신, 또는 기(氣)를 얻는 지(地)에 임할 때는 대풍이 있다. 백호가 임하는 지위(地位)에 의해 바람이 발생한다. 예를 들면 유(酉)의 지반이면 서풍이고, 해(亥)일 때는 서북풍 등과 같다. 백호가 공망하거나 제극하면 바람이 없거나 있어도 대풍이 없다. 천반 미(未)의 입지(立地)에 의해 바람이 발생한다. 미(未)는 풍신(風神)이며 주작이 사오(巳午)에 있으면 소(巢)에 있다고 말하며, 바람이 있다. 청룡․백호가 삼전으로 되어 일간일지에 가해지면 풍우(風雨)가 격렬하고, 백호가 동방의 지위(地位)에 임하면 바람을 다스린다. 미(未)의 풍신이 오면 바람은 점점 위력을 떨친다. 그러나 모두 공망이 되면 변화한다.`
  ,
  // 5
  `  이 과는 제2과 제3과에 하에서 상을 극하고 자진(子辰)을 초전으로 하여 중심과로 된다. 삼전 자신진(子申辰)은 윤하격(潤下格)이며 4과가 불비(不備)로 되어도 삼전사과가 모두 삼합수국(三合水局)으로 되기 때문에 불비격으로 되어도 불비를 취하지 않는다. 일지상의 자(子)는 초전으로 되고 말전의 진(辰)은 일간상에 돌아온다.
  그가 와서 나를 구하는 일이 있고 삼전이 사과상신(四課上神)으로 되는 것은 회환격(廻環格)으로 된다. 삼합수국(三合水局)하므로 유동(流動)이 멎지 않는다. 계획하는 일과 바라는 일은 반드시 성립되고 친척과 화합한다. 다만 일간에서 삼전이 자손효(子孫爻)로 되어 재(財)를 구하지 않아도 재(財)를 소득한다. 모두 삼합국(三合局)을 성립하면 길흉을 불문하고 계속하여 발생한다. 중․말전이 공망한 것은 좌절이 있다.
  백법에 말하기를 ｢삼전이 체생하면 타인의 추천을 얻는다｣고 하였다. 과전이 삼합․육합하면 기쁜 일이 있다. 귀신의 해(亥)에 임하여 사우(四隅)에 진술축미(辰戌丑未)가 승하면 귀신이 천문(天門)에 오르고 신장살몰(神藏殺沒)이라고 하여 길행(吉幸)을 초래한다. 흉도 자연히 해소한다. 삼합수국은 수(水)의 기세로 되는 것으로 길흉이 어느 것이나 하(下)에 있다. 일귀는 역행하여 삼전이 사(蛇)․청(靑)․현(玄)이고, 야귀는 순행하여 삼전이 청(靑)․사(蛇)․현(玄)이다.
  삼합수국하는 수(水)는 하(下)에 내려와 윤(潤)하므로 윤하(潤下)라고 한다. 생왕묘(生旺墓)의 삼신(三神) 즉 맹(孟)․중(仲)․계신(季神)이 서로 결합하여 수국(水局)하고 유동하여 멎지 않는다. 유(柔)는 능히 강(剛)을 이기고 수(水)는 내부를 밝게 하므로 외부는 어둡다. 상은 하에 취하여 길이 되고 하급 사람에게는 흉하다. 소송사 및 기타 어느 것이나 연락하고 병환도 역시 같다. 문서․통신은 주작의 류신을 보아도 발동의 징조가 없다.`
  ,
  // 6
  `  이 과는 4과가 모두 하에서 상을 극한다. 제1과와 제4과의 극지(剋地)는 사맹신(四孟神)으로 되어도 양일(陽日)로 되면 양비(陽比)의 제4과 오해(午亥)를 초전으로 하고 섭해과(涉害課)로 된다. 4하에 의해 4상을 극하고 절사격(絶嗣格)으로 된다.
  존장을 극하기 때문에 장도액(長度厄)이라고 한다. 배하의 사람은 존장을 기만하고 육친은 빙탄불상용(氷炭不相容)한다. 가택에는 이롭지 않으며 모든 일이 겹쳐서 발생한다. 초전에 흉장이 승하면 가장에게 재화가 있다. 4하(四下)에서 상을 극하여 절사(絶嗣)로 되면 여자와 소인은 예(禮)를 잃고 고독(孤獨)․실직(失職)의 우려가 있으며, 흉장이 승하여 골육이 이산하고 병환은 구조가 없다. 또 결혼해도 자식연이 없다. 존장의 병단은 반드시 죽는다. 길장이 승하면 흉중에 길을 숨긴다. 길장은 공망을 두려워하고 흉장은 공망을 즐긴다. 이 과 뿐만 아니라 모두 이 같은 이치(理)를 써야 한다. 하에서 상을 극하는 것은 녹(祿)을 빼앗는다고 하며 실업의 우려가 있고 재화(災禍)는 맥연(驀然)하게 발생한다. 먼저 정(精)하고 후에 동하여도 바로 지키는 것에 의해 길로 변화한다.
  백법에 말하기를 ｢금일(金日)이 정신(丁神)을 만나면 화(禍)에 의해 동(動)이 있다｣고 하였다. 태생기(胎生氣)는 처첩이 회태한다. 만약 사기(死氣)가 승할 때는 태아를 손상한다. 비전에 말하기를 ｢이 과는 천장(天將)의 흉은 골육 분리의 우려가 있으며 길장일 때는 재(財)를 소득하는 기쁨이 있다｣고 하였다. 삼전이 체생하여도 말전이 공망하면 생하여 무익하다. 일귀는 역행하여 삼전이 백(白)․귀(貴)․청(靑)이고, 야귀는 순행하여 삼전이 후(后)․공(空)사(蛇)의 흉장이다. 사건은 부녀자에 의해 일어난다. 말전은 건록이 공망하고 복덕기(福德氣)가 없으므로 복수(福壽)가 결여된다.`
  ,
  // 7
  `  이 과는 천지가 칠충(七冲)하고 제7국이 거의 반음과(返吟課)로서 믿을 곳 없는 격이다. 삼전의 사맹신(四孟神)은 원태격(元胎格)이다. 그러나 모두 공망하고 인(寅)은 절지(絶地)에 임하여 동하려고 하여도 동할 수 없다. 
  백호는 야귀에 있고 음양신에 인(寅)이 있으며 사과삼전이 오호(五虎)를 이룬다. 흉의 위해가 심하지만 다행히 모두 공망하여 공허뿐이므로 실해(實害)가 발생하지 않는다. 길흉이 모두 없고 바람을 잡으려는 것과 같다. 조용히 동기(動機)를 기다리는 것이 좋다.
  백법에 말하기를 ｢천후(天后)․육합(六合)이 승하면 중매인 없이 혼약하고, 양귀(兩貴)가 상극하여도 모두 공망하여 동의(動意)가 없다｣고 하였다. 비전에 말하기를 ｢초전의 공망은 고과(孤寡)라고 부르며 반세(半世)에 고독하고 적적하다｣고 하였다. `
  ,
  // 8
  `  이 과는 4과 중에 제4과의 인유(寅酉)의 극(剋)을 써서 초전으로 하며 중심과로서 제4과를 초전으로 하는 것은 맥월격(驀越格)으로 된다.
  초전의 인(寅)은 일간의 재성(財星)이지만 공망의 지(地)에 앉는다. 다만 초전은 중전을 극하고 중전은 말전을 극하여 체극(遞剋)하고, 구재는 재(財)가 후한 것을 얻는다. 일간상신에 축(丑)의 묘신(墓神)이 복(伏)하면 중전에 미(未)의 충신(冲神)이 있어 양묘(兩墓)가 모두 열린다.
  백법에 말하기를 ｢전후인종격(前後引從格)은 승진(昇進)이 있어 길행(吉幸)으로 된다｣고 하였다. 백법의 제1법의 해설을 재록(再綠)한다. ｢전후인종격에 2법이 있다. 제1은 일간인종격(日干引從格)이라고 부르며 초전이 간(干)의 앞에 있어 인(引)으로 되고 말전이 간의 뒤에 있어 종(從)으로 된다. 이 격을 만날 때는 관직위계(官職位階)가 승진한다. 제2는 초전이 지전(支前)에 있어 인(引)으로 되고 말전이 지(支)의 후에 있어 종(從)으로 되어 지지인종(地支引從格)이라고 한다. 이 격은 가택을 수조(修造)하여 이동한다｣고 하였다. 이상2법은 모두 길격(吉格)으로서 이 격은 제1법에 속한다. 경(庚)의 기궁 신(申) 앞의 유(酉)가 초전의 지반에 해당되고 신(申)의 뒤는 미(未)로서 이 미(未)는 말전으로 된다. 즉 일간인종격(日干引從格)이라고 한다.
  일귀(日貴)는 역행하여 삼전이 후(后)․공(空)․사(蛇)이고 천을귀신(天乙貴神)이 일간상에 승한다. 야귀(夜貴)는 순행하여 삼전이 백(白)․귀(貴)․청(靑)이다. 축(丑)은 개귀(蓋貴)로 되고 입시 및 선거사는 모두 당선된다.`
  ,
  // 9
  `  이 과는 4과 중의 제2과에서 토극수(土剋水)로 1상이 하를 극하여 초전으로 되는 원수과(元首課)로서 삼전 진신자(辰申子)의 삼합수국윤하격(三合水局潤下格)으로 된다. 삼전은 사과를 떨어지지 않고 순환하므로 궁(窮)하지 않으며, 회환격(廻環格)이라고 하여 사물이 연속되어 단절되지 않는다.
  일간에서 삼전은 설기(泄氣)가 지나치고 또 체생(遞生)하여도 모두 실익(實益)이 없다. 중전이 공망하고 동시에 탈기(脫氣)하는 것은 사물이 공허하게 되어 탈(脫)을 만나고, 따라서 삼합수국(三合水局)도 유통생화(流通生化)는 불가능하므로 길흉이 모두 실적(實蹟)이 없다. 가을 또는 겨울에는 수기(水氣)가 왕성하여 좋게 변화한다.
  백법에 말하기를 ｢피난도생(避難逃生)은 구태구상(舊態舊狀)을 버리고 양화(良化)한다. 따라서 생사(生死)는 말전의 자(子)는 경(庚)에 의해 십이운사(十二運死)로 되고 경(庚)의 기궁(寄宮)은 신(申)이며 7자(字), 자(子)는 9수(九數)이므로 칠구(七九)는 육십삼(六十三)이므로 63세에 사망한다.
  일귀는 역행하여 삼전이 현(玄)․청(靑)․사(蛇)이며, 야귀는 순행하여 삼전이 현(玄)․사(蛇)․청(靑)이다. 초전의 일야(日夜)가 함께 현무가 승하고 진(辰)의 월장(月將)의 외부는 도난․실탈이 있거나 가업이 쇠퇴한다. 중․말이 공망하여 전(傳)에 순행하지 않는 것은 오히려 흉을 보고 실해(實害)를 만나지 않는다.
`
  ,
  // 10
  `  이 과는 상하에 극이 없고 제2과 상신을 일간에서 극하여 요극탄사격(遙剋彈射格)으로 된다. 삼전 인사신(寅巳申)은 사맹(四孟)의 신이며 원태격(元胎格)이다. 일지상신이 순미(旬尾)의 미(未)로 되면 폐구격(閉口格)으로 귀신(貴神)이 승하여 발(發)하지 않는다.
  인(寅)은 일간의 재마(財馬)로 되어 구재(求財)의 일로서 동(動)한다. 초전의 역마는 먼 곳에서의 구재가 좋다. 또한 말전의 건록이 공망하므로 재(財)는 빨리 구하는 것이 길하다. 일상(日上) 또는 제2과를 초전으로 하면 모든 사물은 외부에서 발생한다. 초․중전이 실(實)로 되고 말전이 공망하면 처음은 있고 후에는 길흉이 모두 형성되지 않는다.
  백법에 말하기를 ｢귀신(貴神)이 진(辰)에 임하면 옥(獄)에 앉는다고 하며 존장은 번민의 상(象)이다｣고 하였다. 비전에 말하기를 ｢신(申)이 사(巳)에 가하면 생태우격(生態憂格)으로 만사는 고의(孤疑)의 건을 만난다. 요극격은 길흉이 함께 경미하다. 초전 인(寅)은 절(絶)로 되어도 인목(寅木)에서 해(亥)를 보면 장생으로 된다｣고 하였다. 이와 같은 것은 절(絶) 중에 생(生)을 만난다고 하며, 흉재(凶災)를 보아도 후에는 생기(生氣)를 만나므로 흉이 변화하여 길조로 된다.
  일귀는 역행하여 삼전이 후(后)․상(常)․청(靑)으로 되고, 야귀는 순행하여 삼전이 백(白)․음(陰)․사(蛇)이다. 야귀에서 일귀에 천장(天將) 길신(吉神)이 승한다. 요극과의 원칙은 먼저 천신에서 일간의 극을 취하여 호시격(蒿矢格)으로 한다. 만약 천신(天神)에서 극이 없을 때는 일간에 의해 천신을 극하는 것을 취하여 탄사격(彈射格)으로 한다.
  이 과는 삼전 중에 금(金) 또는 토(土)가 있는 것을 좋아하지 않는다. 금토는 토(土)이고 시옥(矢玉)이 되어 막연히 재액(災厄)을 생한다. 이 과는 삼전 중에 태음․현무․천공 중 어느 것이라도 보게 되면 사기(詐欺)에 걸린다. 요극(遙剋)이 멀수록 길흉이 모두 경미하고 먼 곳의 요건(要件)은 공허로 끝나게 되며 허명허리(虛名虛利)로 된다.`
  ,
  // 11
  `  이 과는 4과 중에 제2과․제4과가 하에서 상을 적하고 동일한 양극(陽剋)이므로 섭해과(涉害課)로 된다. 
  사맹(四孟)의 극이 없으므로 중신(仲神)의 극을 채용하여 제4과의 신오(申午)를 초전으로 한다. 일지상신의 오(午)는 일간의 귀(鬼)로 되어도 일간상신의 술(戌)에 의해 설기되므로 우흉(憂凶)은 길로 변화한다. 또는 살(殺)을 제어하여 흉해를 제거하고 초․중전이 공망에 해당하여 처음과 중도에는 노력해도 효과가 없다. 그러나 백호․현무의 양흉(兩凶)은 공망하여 실해(實害)도 없다. 삼전 신술자(申戌子)는 삼연(三淵)을 건너는 격이다. 즉 신술(申戌)은 음위(陰位)이며, 자(子)는 야반음(夜半陰)의 극(極)이 되므로 위험을 건너는 상이다. 고심(苦心) 곤란을 밟으므로 모든 일의 예방을 먼저 하지 못하면 노력은 많고 효과가 적다. 추월(秋月)의 정단은 자립(自立)의 상으로 길조이다. 귀신(貴神)이 해(亥)에 임하는 것은 귀신이 천문(天門)에 오른다. 사우(四隅)에 진술축미(辰戌丑未)의 4신이 승하면 신장살몰(神藏殺沒)로 되어 모든 재(災)를 피할 수 있다. 그러므로 어떤 일도 추구(追求)하면 좋다.
    백법에 말하기를 ｢백호가 둔귀(遁鬼)에 동승(同乘)하는 것은 재(災)를 보면 얕지 않다｣고 하였다. 일간상신․일지상신을 모두 생하는 것은 인택(人宅)이 함께 이익을 얻는다. 귀(貴)가 천문에 오르고 신장살몰(神藏殺沒)은 지반 해궁(亥宮)에 천을귀신(天乙貴神)이 임하는 것을 말하며, 절정무륜(絶頂無倫)의 격이라고 하여 만사는 대길(大吉)로 변화한다. 그러나 소송 및 질병의 정단은 구사일생(九死一生)의 정상(精狀)이다.
    일귀는 순행하여 삼전이 백(白)․현(玄)․후(后)이고, 야귀는 역행하여 삼전이 후(后)․현(玄)․백(白)으로서 모두 흉장이다.`
  ,
  // 12
  `  이 과는 4과 중에 상하의 극이 없고 제3과․제4과 천반(天盤)에서 일간을 극하고 금일(今日)은 양일(陽日)이면 양비(陽比)의 극이며, 제4과를 써서 초전으로 하는 요극호시과(遙剋蒿矢課)로 된다.
  요극이 2개소에 있으면 사물이 양건(兩件) 또는 양기(兩岐)에 걸치는 일이다. 일간상신․일지상신과 유진신사(酉辰申巳)가 지합(支合)하는 것은 교차상합(交車相合)이다. 일간상신 유(酉)와 일지상신의 사(巳)는 삼전 오미신(午未申)을 낀다. 그러므로 야귀는 내(內)에 있고 모든 일이 성립되기 쉽다. 요극호시(遙剋蒿矢)에 신금(申金)이 있으면 철(鐵)의 화살로서 두렵지만 다행이 공망하므로 단지 허경(虛驚)으로서 실해(實害)를 만나지 않는다. 삼전 오미신(午未申)은 순연여(順連茹)로 되는데, 신(申)의 공망이 있어 전진 중에 동요가 생기고 후회하는 일이 있다.
  묘유(卯酉)의 지(地)에 진술(辰戌)이 승하면 천망사장(天網四張)이라고 부르며 만물이 파상(破傷)의 상이다. 말전이 공망하여 처음에는 놀라고 두려운 일이 있어도 나중에는 무사하게 된다. 나에게 유익하고 상대방에게는 불리하며, 모든 일이 소사(小事)는 길이고 대사건(大事件)은 흉하다. 요극과는 모두 근심 걱정과 구설(口舌)이 서남(西南)에 있으므로 서북을 좋아한다. 천반지지(天盤地支)는 지반의 앞에 있는 것을 나망(羅網)이라고 한다.
  백법에 말하기를 ｢모사(謀事)를 이루어 졸책(拙策)으로 되는 것은 나망격(羅網格)이기 때문이다｣고 하였다. 호시격은 내몸을 극하여도 두려울 것이 없다. 그러나 내객(來客)이 있을 때는 오래 머무르면 불길하다. 비전에 말하기를 ｢연진여격(連進茹格)이 공망하면 공곡(空谷)에 소리를 전하는 것과 같다. 후퇴는 길하고 전진은 불길하다. 오미신(午未申)의 연여(連茹)는 여명(麗明)으로 권위를 발휘한다.`
  ,
]

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
