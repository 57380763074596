
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`신뢰`,7,6,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,8,5,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`설득`,9,4,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`불성`,10,3,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,11,2,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`설득`,12,1,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`설득`,1,12,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,2,11,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,3,10,`특별있음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`고집`,4,9,`있음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,5,8,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`설득`,6,7,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편(男) 갈등사`,`小`,`4`,``,``],
    [`색정`,`내女 섹정사有-임신이면 겨울을 조심할 것`,`大`,`개시기`,`도적악의`,`색정사有`],
    [`불리부모(不利父母)`,`부모에게 해로운 일이 있는 상`,`大`,``,`공격말것`,`굴욕반복`],
    [`유모미수(有謀未遂)`,`계획사가 완전하게 이루어지지 못한 상`,`小`,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`모친`,`모친 관련사`,`大`,`5`,``,``],
    [`쟁송`,`외사 급속송쟁사-상해우려`,`小`,`배양기`,`급송쟁사`,`공허침체`],
    [`흉동불녕(凶動不寧)`,`적극 활동하면 매우 나빠 편안함을 잃을 상`,`小`,``,`起居적의`,`백사근심`],
    [`소식조격(消息阻隔)`,`소식을 기다리나 와도 나쁜 소식이 될 상`,`大`,``,``,``],
    [`금전`,`자금 문제사`,`大`,``,``,``],
    [`상업`,`상업 관련사`,`小`,`6`,`송구경황`,``],
    [`처`,`처 관련 과출사`,`小`,`존재기`,`간음관계`,`비용과출`],
    [`손익자상(損翼自傷)`,`새가 스스로 강물에 달려들어 날개를 상하는 격의 상`,`大`,``,`인출현`,`백사근심`],
    [`백사구우(百事俱憂)`,`모든 일이 근심이 되게끔 되어 있는 상`,`小`,``,``,``],
    [`大財`,`결속하여 음6월 완성하고 대재 획득하나 부모災害발생함`,`大`,``,`고위직위`,``],
    [`상업`,`상업 관련사`,`小`,`7`,`수여받음`,`필유성취`],
    [`색정`,`외男 색정사有`,`小`,`단절기`,`허사우려`,``],
    [`잉시신기(孕是神祇)`,`임신을 위하여 신에게 빌어야 하는 상-작귀의 방해는 없음`,`大`,``,`색정사有`,``],
    [`손괴재물(損壞財物)`,`복구될 수 없는 재물(가축등 牛)을 손괴함`,`小`,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,`도적놓침`,``],
    [`동요`,`외사 동요불안분산사`,`大`,`8`,`허획경구`,`사송계류`],
    [`화합`,`내 화합사`,`小`,`침체기`,`동요분산`,`손해가출`],
    [`동의난조(動意難阻)`,`활동하려는 생각이 아직 서지 않는 상`,`小`,``,``,``],
    [`사송피길(詞訟避吉)`,`송쟁사가 있고 우선 피하는 것이 상책인 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`大`,``,``,``],
    [`급속`,`외사 출행이동원행사`,`大`,`9`,`총애쟁탈`,`불위진취`],
    [`출행`,`내 가정 강제급속피해사`,`小`,`소외기`,`출행이동`,`급속피해`],
    [`구재생기(求財生氣)`,`금전을 기이하게 얻게 되는 상`,`小`,``,``,``],
    [`간음무도(姦淫無度)`,`절제를 잃은 색정사를 벌이고 있는 상`,`小`,``,``,``],
    [`관사`,`관사관련사`,`大`,``,``,``],
    [`매사허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`10`,`간음무도`,`정위난측`],
    [`매사영속성없음`,`직업직위권리남편남자사는 선천적 기복재해를 겪음`,`小`,`침해기`,``,`(상대진심`],
    [`필무정옥(必無正屋)`,`반드시 자기 집이 없는 상`,`小`,``,``,`가늠안됨)`],
    [`간음무도(姦淫無度)`,`절제를 잃은 색정사를 벌이고 있는 상`,`小`,``,``,`재해손실`],
    [`결속`,`결속하여 음6월 완성하고 대재 획득하나 부모災害발생함`,`小`,``,``,``],
    [`매사허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출로 성공함`,`大`,`11`,`고위청원`,``],
    [`매사영속성없음`,`후원조력문서모친사는 선천적 기복재해를 겪음`,`小`,`쇠퇴기`,`사적방문`,`손괴재물`],
    [`취즉생화(取卽生禍)`,`이익을 요구해 취하면 화를 당하는 상`,`小`,``,`좋음`,``],
    [`손괴재물(損壞財物)`,`복구할 수 없는 재물(가축 등 牛)을 잃은 상`,`大`,``,``,``],
    [`직녹`,`명예수상직녹권리 관련사`,`小`,``,`송구경황`,``],
    [`출행`,`외사 출행이동원행사`,`小`,`12`,`사통관계`,`급속사有`],
    [`급속`,`내사 가정 급속사`,`大`,`과시기`,`인나타남`,`타인사건`],
    [`상대무력(相對無力)`,`누구든 조력을 얻을 수 없는 무력인 상`,`小`,``,`출행이동`,`연루관계`],
    [`절상인구(折傷人口)`,`가족 중 상하는 식구가 있는 상`,`大`,``,``,``],
    [`자매`,`자매 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`小`,`1`,`백사근심`,`비곡차욕`],
    [`색정`,`외남 색정사 有`,`大`,`절정기`,`색정사有`,`수치비관`],
    [`무중생유(無中生有)`,`포기할 수밖에 없는 상태에서  다시 힘을 얻는 상`,`小`,``,``,``],
    [`비곡수욕(悲哭差辱)`,`치욕스러움이 폭로되어 울부짖는 상-훼절`,`大`,``,``,``],
    [`후원`,`후원 관련사`,`小`,``,``,``],
    [`부친`,`부친 관련사`,`大`,`2`,`소식대기`,`불의투서`],
    [`내외`,`외사 침체 내부 가정 화합사有`,`小`,`면모기`,`와도나쁨`,`구설실재`],
    [`유환득년(有患得寧)`,`병질이 있되 낫게 되는 상`,`小`,``,`외교막힘`,`가정화합`],
    [`문서유망(文書遺亡)`,`문서를 잃은 상`,`大`,``,``,``],
    [`박관`,`직업직위불안사`,`小`,``,`존비상몽`,``],
    [`극부`,`남편갈등사`,`小`,`3`,`신분나이`,``],
    [`자녀`,`내부가정 복잡결속단합사`,`大`,`학습기`,`차이음란`,`복잡단합`],
    [`급속손실(急速損失)`,`외사 강제급속손실사有`,`大`,``,`관계有`,`사사성취`],
    [`사사성취(事事成就)`,`모든 일이 뜻대로 이루어지는 상-남녀 합실 관계`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,``,,"직위불안부부갈등색정사로불가",],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`후원문사모친조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`금전상업처조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`금전상업여인조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`후원문사부친조력거정화합사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,``,,"관사직업급속피해출행이동사로불가",],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`형제조력출행비용지출로로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,`직녹자산권리사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`후원문서부친조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,``,"직업직위부부갈등급속손실사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 대항하지 말고 순응유화유순한 처신으로 사안을 뒤로 미루면 결과가 유리해짐`,0,0,`역상적 사안으로 억울하더라도 절대 맞서 다투지 말고 순응하며 결론을 지체시키면 후에 반드시 유리하게 되는 人`,0,0,`일이 결과없이 반복되기만 함**범법하면  사죄가 되지않고 병에 걸리면 치유가 되지 않음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`일이 역순으로 띄엄띄엄 진행되어 퇴하려 하나 되려 진행하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`엄목(掩目:눈을 가림)-은닉**만약 타인과 다툰다면 감춰진 허물이 드러날 우려가 크고 진퇴양난에 빠지게 되므로 극구 피해야 함`,0,0,`본인이 범인이거나 또는 범법자를 숨기고 있어 인내로 버텨야 하는 人`,0,0,`가정불녕**반복부정함**관직자는 자리를  옮김**관재가 일어날 조짐이 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`중상모략적 사안이므로 가장 친하가나 제일 가까이 거주하는 자와 의논하여 방침을 결정하면 풀리게 되는 人`,0,0,`여러 일을 하나로 묶음-단, 음3,6,9,12월이라야 완성되고 큰 돈이 됨**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 대항하지 말고 순응유화유순한 처신으로 사안을 뒤로 미루면 결과가 유리해짐`,0,0,`역상적 사안으로 억울하더라도 절대 맞서 다투지 말고 순응하며 결론을 지체시키면 후에 반드시 유리하게 되는 人`,0,0,`깊은 산중으로 숨고싶은 심정임**가정불녕함**1녀2남 삼각관계有**여건조건이 충족되지 않은 상태임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 재난이 밖에서 오니 신중해야 함`,0,0,`반복적 배반사로 불안분산사를 겪는 人`,0,0,`관직자는 영전함**가정불녕함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 대항하지 말고 순응유화유순한 처신으로 사안을 뒤로 미루면 결과가 유리해짐`,0,0,`역상적 사안으로 억울하더라도 절대 맞서 다투지 말고 순응하며 결론을 지체시키면 후에 반드시 유리하게 되는 人`,0,0,`1남2녀 삼각관계 있음**일이 결과없이 반복됨**타세에 의존함-타인이 스스로 와서 금전을 도움**관직자는 영전함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려우므로 신중해야 하고 후에 행동함이 현명하며 방심하면 불리함`,0,0,`풍상고초 후 성공할 人`,0,0,`가정불녕**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 대항하지 말고 순응유화유순한 처신으로 사안을 뒤로 미루면 결과가 유리해짐`,0,0,`역상적 사안으로 억울하더라도 절대 맞서 다투지 말고 순응하며 결론을 지체시키면 후에 반드시 유리하게 되는 人`,0,0,`남자는 관재를 당함**여자는 모친을 害함**일이 결과없이 반복됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威) 세**만약 타인과 다툰다면 피아간에 전의가 별로 없고 있어도 가벼움`,0,0,`속 마음에 천둥에 놀란 듯 두려움을 갖고 있으나 언행을 조심하고 근신하면 두려움이 저절로 사라지게 되는 人`,0,0,`가정불녕**여자는 타인의 남자를 적극 유혹하여 함께 도망칠 마음을 갖고 있음**일이 띄엄띄엄 진행되어 퇴하려 하나 계속하게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 대항하지 말고 순응유화유순한 처신으로 사안을 뒤로 미루면 결과가 유리해짐`,0,0,`역상적 사안으로 억울하더라도 절대 맞서 다투지 말고 순응하며 결론을 지체시키면 후에 반드시 유리하게 되는 人`,0,0,`깊은 산중으로 숨고싶은 심정임**풍운불측의 상`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,0,0,`스스로 유약하다고 여기고 적극적이지 못해 신음하는 人-신체가 억압되어 있는 경우가 많음`,0,0,`만사가 연루됨**풍운불측의 상**금전거래는 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`좌고우면-무력`,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`배신-무력`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`타발동급속사`,`기사`,`신구중간사`,`내사`,`표면사`,`배신`,`보성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-비용지출`,`아발동사`,`기사`,`신구중간사`,`외사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`미사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`충성`,`악성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`구사`,`내사`,`표면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동사`,`기사`,`신구중간사`,`내사`,`표면이면 겹침`,`배신`,`보성`,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  흥`,`흥`,`  흥`],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`화해함`,`화해함`,`화해함`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [`화해됨`,`화해됨`,`화해됨`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [2,3,5,`음1월`,1,2,3,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음12월`,3,2,5,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음3월-연기 우려`,2,3,5,`음9월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음10월`,3,1,4,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,1,2,3,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음9월`,3,2,5,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음7월`,2,3,5,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음6월-연기 또는 파혼우려`,1,1,2,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,4,`음3월`,1,1,2,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음4월`,3,1,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음2월`,2,2,4,`음1월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음3월`,3,3,6,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 훌륭하나 업무는 수준에 못미침`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 중반의 실적 외 초반 종반은 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 속이며 업무 역시 수준에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 복잡한 일을 일관시키고 3,6,9,12월 중에 완성하여 재정을 크게 확보함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`ㄷ둔한 것같으나 상대를 잘 조정하지만 업무는 수준 이하 임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 업무가 강하지 못하여 본인의 역량 외적인 사유로 동요분산사를 겪으며업무에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이고 업무가 강하지 못하나 수준이상의 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초 중반을 결속 단합을 꾀하는 일로 허무하게 보내고 종반(음3,6,9,12월)에 이르러 실적을 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 초 중반이 저조하고 종반에 이르러서야 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 업무가 강하지 못하여 시종 수준 이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하여 속이고 초반 외 중 종반의 업무가 수준이하가 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이고 업무가 강하지 못하나 수준 이상의 실적을 이룸`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`月\n적용`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,`集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선攻후攻`,`進向`,`원`,`留`,`무난`,`서북`,``,`정북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`선이후이`,`進向`,`  원`,`  留`,`세입가해`,`북북서`,``,`북북서`],
    [`10.11`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`불이행`,`본인무력`,` 退背`,`  근`,`  去`,`주인조력`,`정북`,``,`동북`],
    [`10.11`,` 多集`,``,``,``,``,``,``,`상호무례`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`불이행`,`본인무력`,`退背`,`  근`,`  去`,`주인조력`,`북북동`,`정서`,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,`주인이사`,``,``,``],
    [`3.6\n9.12`,`  集`,``,``,``,``,``,``,`하게됨`,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본이반대`,`불이행`,`선이후난`,`退背`,`  원`,`  去`,`세입가해`,`동북`,`북서`,``],
    [`10.11`,``,`측근제압`,``,`빠르게`,``,``,``,`이후화합`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`권리양도`,`進向`,`  원`,`  留`,`세입가해`,`정동`,`서남`,``],
    [`10.11`,`  集`,``,``,`후費`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선比후不`,`進向`,`  원`,`  留`,`세입가해`,`동남`,`정북`,``],
    [`10.11`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`측근반대`,`불이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`동남`,`북서`,``],
    [`10.11`,``,`본인동조`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,`未發무력`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`내부반대`,`불이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`남남동`,`북북서`,``],
    [`10.11`,``,`未發무력`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`내부반대`,`불이행`,`선난후攻`,`退背`,`  근`,`  去`,`세입조력`,`정북`,``,`정북`],
    [`10.11`,``,`본인제압`,``,``,``,``,``,`설기피로`,`세밀심사`,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,`함`,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`불이행`,`선이후不`,`退背`,`원근왕래`,`  去`,`세입가해`,`남서`,``,`북북동`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인동조`,`불이행`,`선攻후合`,`進向`,`  근`,`  留`,`무난`,` 서남`,``,`북북서`],
    [`10.11`,`  集`,`무력불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`음5월가능`,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,0,0,0,0,``,0,``],
    [``,0,0,0,0,``,0,``],
    [``,0,0,0,0,``,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,`제례귀`,`제례위범`,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,`수귀`,`익사`,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,`고모신사`,`성물移脫`,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,`여고부정`,`고혼시모`,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,`상가부정`,`조문왕래`,`육친`,0,``],
    [`질병`,0,0,0,0,`묘좌`,0,`특별있음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,0,0,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,`전사귀`,`전사`,0,0,`육친`,0,``],
    [`질병`,`여귀`,`호주부인`,0,0,`묘좌`,`남남동`,`있음`],
    [``,0,0,0,0,`묘향`,`북북서`,``],
    [`생업`,0,0,0,0,`전경`,`등라윤천`,``],
    [``,0,0,0,0,`묘속`,`뱀굴`,``],
    [`가정`,0,0,0,0,`육친`,`5대조모`, `妹`,``],
    [`질병`,`도로귀`,`교통사망`,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,`상가부정`,`조문왕래`,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,`저주원심`,`원한저주`,`육친`,0,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,0,`없음`],
    [``,0,0,0,0,`묘향`,0,``],
    [`생업`,0,0,`제례부정`,`제례위범`,`전경`,0,``],
    [``,0,0,0,0,`묘속`,0,``],
    [`가정`,0,0,0,0,`육친`,0,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`소식안옴-와도 나쁨-이동사`,`외사 급속사`,`전도**빈궁`,`내 여자 색정사**일체근심사 뿐`,`외사급속송쟁-상해실종사우려`,`모사미수사`,`빈궁`,`상서헌책이좋음`,`내부가정막힘`,`사사성취**외男색정사`,`매사위역사**외시동요분산사`,`문서유실사`,`내 출행이동원행사`,`말 말고 근신함이 좋음`,`受賞사**매사허성불성**세속허사자`,`음1,2,4,5월 방문자는종교진출하면성공함`,`매사영속성없음**두려운 자 至-송구경황-나체 관련`,`종교진출하면자립함`,`외 급속사-출행이동원행사`,`있든 가든 좋음`,`도적악의-불의공격`,`직녹자산사`,`외교막힘`,`존비불화`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**병재**빈궁`,`전화위복`,``,``,``,``,`재난해소`,``,`재난해소`,`관재`,`빈궁`,``,`빈궁**관재`,`음4월 화재주의`,`빈궁`,``,``,``,`빈궁**병재`,`전화위복`,`빈궁`,``,`침체경구사`,`관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`외교막힘`,`존비불화`,`소식안옴-와도나쁨-이동사`,`빈궁**병재`,`일체근심사 뿐`,`내女색정사`,`외사급속송쟁-상해실종사우려`,`모사미수사`,`빈궁**관재`,`상서헌책이좋음`,`내부가정막힘`,`사사성취**외男색정사`,`매사위역사**외시동요분산사`,`문사유실사`,`내 출행이동원행사`,`말 말고 근신함이 좋음`,`受賞사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**두려운자 至-송구경황-나체 관련`,`종교진출하면자립함`,`외 급속사-출행이동원행사`,`있든 가든 좋음`,`도적악심-불의공격`,`직녹자산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재난해소`,`관재`,`외사 급속사`,``,`빈궁`,`전화위복`,`재난해소`,``,``,``,``,``,`재난해소`,`빈궁`,`전도**관재**빈궁`,`음4월 화재주의`,`전도`,``,``,``,`빈궁**병재`,`전화위복`,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`도적악심-불의공격`,`직녹자산사`,`외교막힘`,`존비불화`,`병재**빈궁`,`소식안옴-와도나쁨-이동사`,`일체근심사 뿐`,`내女색정사`,`외사급속송쟁-상해실종사우려`,`모사미수사`,``,`상서헌책이좋음`,`내부가정막힘`,`사사성취**외男색정사`,`매사위역사**외사동요분산사`,`문사유실사`,`내 출행이동원행사`,`말 말고 근신함이 좋음`,`受賞사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**두려운자至-송구경황-나체 관련`,`종교진출하면자립함`,`외 급속사-출행이동원행사`,`있든 가든 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`재난해소`,``,`외사 급속사`,``,`빈궁`,`전화위복`,`재난해소`,``,`빈궁`,``,``,``,`재난해소`,`빈궁`,`전도**빈궁**관재`,`음4월 화재주의`,`전도`,``,``,``,`빈궁**병재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`애정을 가늠할 수없는 事`,`외 급속사-출행이동원행사`,`직녹자산사`,`퇴-불의진취`,`매사위역사**외교막힘`,`면굴난해-감옥관리관련`,`외사 급속사`,`특명필유성취사**외男색정사`,`낙제고시**송쟁무방`,`내女색정사`,`화소복지사`,`외사급속송쟁-상해실종사우려`,`개인방문청탁이좋음`,``,`간음무도`,`내부가정막힘`,`총애다툼사`,`매사위역사**외 동요분산사`,`도적을 못잡고 놀래기만하는 상`,`내 출행이동원행사`,`군자대경사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**손괴재물`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`병재`,``,``,`빈궁`,``,`전도`,``,`전도**빈궁`,`전화위복`,``,``,`재난해소`,`관재`,`제난해소`,`빈궁`,``,`빈궁**관재`,``,`음4월 화재주의`,``,``,`빈궁**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`매사영속성없음**손괴재물`,`종교진출하면자립함`,`외 급속사-출행이동사`,`애정을 가늠할수없는事`,`진녹자산사`,`퇴-불의진취`,`매사위역사**외교막힘`,`면굴난해-감옥관리관련`,`외사 급속사`,`특명필유성취사**외男색정사`,`내女색정사`,`낙제고시-송쟁무방`,`화소복지사`,`외사급속송쟁-상해실종사우려`,`개인방문청탁이좋음`,``,`간음무도`,`내부가정막힘`,`총애다툼사`,`매사위역사**외 동요분산사`,`도적을 못잡고놀래기만하는 상`,`내 출행이동원행사`,`군자대경사**매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**병재`,``,`전화위복`,`빈궁**병재`,``,`관재`,``,`빈궁`,``,`전도`,`전화위복`,`전도**관재**빈궁`,``,`빈궁`,`관재`,`재난해소`,``,`재난해소`,``,`빈궁`,`음4월 화재주의`,`빈궁**관재`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`군자대경사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**손괴재물`,`종교진출하면자립함`,`동요불안분산사**외 급속사-출행이동원행사`,`애정을가늠할수없는事**외 출행이동원행사`,`직녹자산사`,`퇴-불의진취`,`매사위역사**외교막힘`,`면굴난해-감옥관리관련`,`외사 급속사`,`특명필유성취사**외男색정사`,`내女색정사`,`낙제고시-송쟁무방`,`화소복지사`,`외사급속송쟁-상해실종사우려`,`개인방문청탁이좋음`,``,`간음무도`,`내부가정막힘`,`총애다툼사`,`매사위역사**외 동요분산사`,``,`내 출행이동원행사**전도**빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,``,`동요불안분산사`,`전도**병재**빈궁`,`관재**전화위복`,`재난해소`,`빈궁**전도**동요불안분산사`,`재난해소`,`동요불안분산사`,`관재`,`동요불안분산사`,`빈궁**관재`,`관재**전화위복`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사`,``,`동요불안분산사`,`빈궁`,`동요불안분산사`,`음4월 화재주의`,`동요불안분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`도적을못잡고놀래기만하는상`,`내 출행이동원행사`,`군자대경사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**손괴재물`,`종교진출하면자립함`,`외 급속사-출행이동원행사`,`애정을가늠할수없는事`,`직녹자산사`,`퇴-불의진취`,`매사위역사**외교막힘`,`면굴난해-감옥관리관련`,`외사 급속사`,`특명필유성취사**외男색정사`,`내女색정사`,`낙제고시-송쟁무방`,`화소복지사`,`외사급속송쟁-상해실종사우려`,`개인방문청탁이좋음`,``,`간음무도`,`내부가정막힘`,`총애다툼사`,`매사위역사**외 동요분산사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4월 화재주의`,`빈궁**관재`,``,``,`전도**병재**빈궁`,``,`관재**병재**빈궁**전화위복`,`재난해소`,``,`재난해소`,`전도**빈궁`,``,`관재**전화위복`,``,`전화위복`,`빈궁`,`전도**빈궁`,``,`전도**빈궁`,``,`빈궁`,``,`전도**빈궁**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`총애다툼사`,`매사위역사**외 동요분산사`,`도적을못잡고놀래기만하는 상`,`내 출행이동원행사`,`군자대경사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**손괴재물`,`종교진출하면자립함`,`외 급속사-출행원행이동사`,`애정을가늠할수없는事`,`직녹자산사`,`퇴-불의진취`,`매사위역사**외교막힘`,`면굴난해-감옥관리관련`,`외사 급속사`,`특명필유성취사**외男색정사`,`내女색정사`,`낙제고시-송쟁무방`,`화소복지사`,`외사급속송쟁-상해실종사우려`,`개인방문청탁이좋음`,``,`간음무도`,`내부가정막힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`음4월 화재주의`,`빈궁**관재`,``,`관재`,``,`빈궁**병재`,`전화위복`,`빈궁**병재`,``,``,`빈궁`,``,`전도`,``,`전화위복`,`전도**빈궁`,``,`빈궁`,`재난해소`,`곤재`,`재난해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`재난불측사`,`내부가정막힘`,`문서유실사`,`매사위역사**외 동요분산사`,`내 출행이동원행사`,`주력사 흉함`,`군자대경사**매사영속성없음**타인송사연루사`,`종교진출하면자립함`,`모사미수**매사영속성없음`,`종교진출하면자립함`,`외 급속사-출행이동원행사`,`애정을가늠할수없는事`,`직녹자산사`,`퇴-불의진취**가족중절상자발생-일가경구불녕`,`존비불화`,`외교막힘`,`도적못잡음`,`특명필유성취사**외男색정사`,`내女색정사`,`부인과 첩이 서로 업신여기며 다투는 상`,`외 급속송쟁사-상해실종사우려`,`송구경황-색정사 후 두려운자임을 알게됨`,`개인방문청탁이좋음`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,`음4월 화재주의`,`재난해소`,``,``,``,``,`전화위복`,``,``,``,``,``,`외사 급속사`,``,`전화위복`,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,``,`무고이간질관련사`,`내부가정막힘`,`매사불리-또는 도난사`,`매사위역사**외 동요분산사`,`총애 다툼사`,`내 출행이동원행사`,`비곡차욕**폭로관련`,`受賞사**매사영속성없음`,`종교진출하면자립함`,`매사영속성없음**숲에서 뱀을 만난격`,`종교진출하면자립함`,`외 급속사-출행이동원행사`,`괴이송사-득길고시`,`직녹자산사`,`간음사이익-정대적이면 되려재앙됨`,`매사위역사**외교막힘`,`밤에당한능욕박해`,`외사 급속사`,`有非常사-길`,`내女색정사`,`일체근심사 뿐`,`외 급속송쟁사-상해실종사우려`,`손괴재물-牛관련`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,`빈궁`,``,`관재`,``,`음4월 화재주의`,``,`빈궁`,``,``,``,`전도**병재**빈궁`,`전화위복`,`전도`,``,`빈곤`,``,`재난해소`,``,`빈궁**전화위복`,`재난해소`,`빈궁**병재관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`외 급속송쟁사-상해실종사우려`,`모사미수사`,``,`상서헌책이좋음`,`내부가정막힘`,`사사성취**외男색정사`,`매사위역사**외 동요분산사`,`문서유실사`,`내 출행이동원행사`,`말 말고 근신함이 좋음`,`受賞사**매사영속성없음`,`종교진출하면자립함`,`매사허성불성**세속허사자**송구경황-두려운자 至`,`음3,4,5,6,9,12월방문자는종교진출하면성공`,`외 급속사-출행이동원행사`,`오든 가든 좋음`,`직녹자산사`,`도적악심-불의공격**매사위역사`,`외교막힘`,`존비불화`,`폐한인**외사 급속사`,`소식안옴-와도나쁨-이동사`,`일체근심사 뿐`,`내女색정사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`재난해소`,`빈궁**관재`,`재난해소`,``,`빈궁`,``,`음4월 화재주의`,`빈궁**관재`,``,``,``,``,`빈궁**병재`,`전화위복`,`빈궁`,``,``,``,`전도**병재**빈궁`,``,`전도**빈궁`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`일체근심사 뿐`,`내女색정사`,`외 급속송쟁사-상해실종사우려`,`모사미수사`,``,`상서헌책이좋음`,`내부가정막힘`,`사사성취**외男색정사`,`매사위역사**외 동요분산사`,`문서유실사`,`내 출행이동원행사**음4월 화재주의`,`말 말고 근신함이 좋음`,`受賞사**매사허성불성**세속허사자`,`음1,2,4,5월방문자는 종교진출하면성공함`,`매사허성불성**세속허사자**송구경황-두려운자 至`,`음3,4,5,6,9,12월 방문자는종교진출하면성공`,`외 급속사-출행이동원행사`,`오든 가든 좋음`,`직녹자산사`,`도적악심-불의공격**매사위역사`,`외교막힘`,`존비불화`,`외사 급속사`,`소식안옴-와도나쁨-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,`전화위복`,``,`전도`,``,`빈궁`,``,``,`전도**빈궁`,``,`빈궁**관재`,``,``,``,`전도`,``,`빈궁**병재**재난해소`,`전화위복`,`재난해소`,`빈궁`,``,`전도`,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 임신했겠네? 애를 낳으면     가정이 융성해지겠는데, 
  다만 
관재에 연루되는 은닉(죄인)사와
⊕전사귀, 
⊖신부정귀가 걱정이야! 
지금 신음고로 하는 일도 그 때문이야!

⊕⊖신변신화선영부정침범했네?
`,`■ ⊕집안 아이가 병이 있겠어! ⊖후원ㆍ금전사가 목적 달성이 안 되고 있겠고?
잠시 물러나 기도가 방법이네! 
반드시 효과가 크겠어!

⊕신변상가부정이 들었네 ?
`,`■ 후원이 막힐 때는 후퇴심으로 지키고 있는 게 상수야 ! 
단속을 잘하고 가다보면 
되는 일이 있을 거야 !

女:모친 살상을 주의요함.
`,`■ 흉조야 ! 
  눈을 가리는 음란사가 있게     생겼어!가만히 있어도
  우환이 생기는 운세야!
⊕문서구설, 
⊖사기도 당하고!

⊕신변 상가부정 침범했네?
`,`■ 집을 옮기네? 
  일도 한두가지가 아니겠고!     하나로 묶겠어!
女：남의 남자를 서방 삼고      있네?

⊖가택상가부정이 들었다

`,`■ 一女二男 삼각관계가 있네?     일도 조건이 부족하겠어!

⊕수귀ㆍ가택고모신사 부정, 
⊖여귀ㆍ가택고모신사 부정을     없애야 관재도 해소되고     집안이 평안해져!
`,`■ 임신했네? 
  색정사로 집안이 자칫 깨지     겠어! 배반사가 있는데      배반당할 일이 목전에 와     있어!

⊕⊖신변에 여고음강부정이      침범했네 ?
`,`■ 一男二女 삼각관계네 ?
  임신도 됐고! 
女:남편(남자)때문에 돈께나     부서지고 있겠어! 

男：두여자와 관계를 맺었네!
`,`■男:일도여러개사람도여럿이       관계되는일을하나로 묶       겠는데가지많은나무에     바람잘 날 없다는 격이야!
男：⊕남의 여자를 내 마누라        삼고 있네? 그래봤자 
     별리ㆍ무덕이야!
⊕가택상가부정, ⊖가택음사부정 때문이야.
`,`■ 내 녹은 비고 있는데 
  일은 자꾸 반복되기만 하고
⊕전사귀, 
⊖신부정귀가 들어서 그렇네?    자칫 관재까지 몰고 오겠어!
男:권리를 잃는다
女:남편을 잃는 격이네 !
 모친살상을 주의요함.
⊖신변 상가부정 침범했네?
`,`■ 가정이 편안해야 일이 될텐데 모두 빈소리 뿐인데다가 놀랠일만 생기네? 

⊕수귀, 
⊖여귀 장난이야!
女：⊖남의남자를유혹해        서방삼고 있네?
`,`■ 마음이 떴네? 
  갈수록 태산이겠어!
  관재고심도 있네 !
⊕자살귀ㆍ전사귀ㆍ저주원심부정, ⊖도로귀ㆍ신부정귀ㆍ가택원심      부정 탓이야! 자칫 도망갈     일도 생기겠어!

⊖신변 상가부정 침범했어 !`];
  const YOGYEOL1 = [`■ 서로 화목한 후 무례한 일로 속상한다. 
  저 사람이 이와같이 하니 나도 또한 이같이 한다.
  `,`■ 내 녹에 범과 도둑이 드니 항상 근심이다.
  녹을 버리고 사업을 시작하려는데 이것 또한 편안치 않다.
  `,`■ 자손이나 아랫사람에 의해 나쁜 일로 움직이게 된다. 낮과 밤의 천장이 도둑과 백호가 교대로 탔다.
  `,`■ 범이 노려보고 형벌을 재촉하니 해로운 일이 거듭된다. 매우 놀라고 매우 두려우니 손해는 실재이고 좋은 것은 비었다.
  `,`■ 재물이 관귀로 변했다. 발아래 바퀴가 달린 셈이다. 낮에는 귀인에게 부탁하려면 재물을 써야 성사가 된다.
  `,`■ 사람이 돈을 만났으니 급히 취해야 마땅하다. 문고리처럼 연결되었으니 움직이는 것을 막지 못하리라.
  `,`■ 먼저 미움이 있고 나중에 화합되리라. 두 귀인이 있다해도 기쁨이 없다. 귀인이 은혜를 베풀 수 없게 된다.
  `,`■ 과전이 순환되어 끝나지 않는다. 재물이 사람에게 왔으니 빨리 거두라. 만약 더디면 고통이 생기리라.
  `,`■ 밤정단은 귀인의 재물이 오고 여럿이 도와주겠으나 중간에 살이 숨어 이를 취한즉 재앙이 되겠다.
  `,`■ 낮정단은 범이 묘위에서 노리고 밤정단은 녹을 범이 물어간듯 싶어 놀랜다. 손해와 도적이 가득하니 낮정단은 귀인을 못 본다.
  `,`■ 쏘는 화살이 쑥대이고 보니 하는 바가 종적이 없게 된다. 샘은 마르고 나무는 백리가 잘렸다. 
  손해날 일만 가득하겠다.
  `,`■ 관귀가 내몸을 상하니 병은 반드시 두통이다. 
  서로 사모하고 협동은 좋고 낮정단은 귀인을 어찌 믿을 수 있겠는가.`]
  const SIBO1 = [`■ 가정에 임신사가 있다.
  이로인한 불화가 있다.
  대처능력을 상실하여 
  신음고로한다.
  갈수록 무례한 일이 벌어져 결국 직위손상이 있게 된다. 여자는 남자를 망하게 하는 일을 하게 된다. 여자가 문제이다.
  ⊕ 가택에 음사부정이 침입했다
  `,`■ 부녀자에 의해 불순한
  일이 벌어진다.
  ⊕아이의 병이 위험하다.
  人ㆍ宅은 복록이 크나
  
  ⊖은 복록을 빼앗기는 일이 있다. 폐지사를 부흥시킨다. 삼기(三奇)가 있어 재앙이 풀리고 무슨 일이나 이루어지는 것이 있다. 단, 가택문제가 분리되는 고민이 생긴다.
  `,`■ 목적사에 정체가 많고
  순조롭지 못하다. 
  문서사가 이롭다가 도중
  흉세로 변화한다. 병과 도난을 조심해야 한다.
  
  ⊕가정에 아이가 아플 징조가 있다. 복통이다. 일에 음기가 흘러 광명정대하게 처신하는 것이 좋다.
  `,`■ 가정에 도난사가 발생한다.
  내부자 소행이며
  ⊖은 사용인의 짓으로 본다.
  또, 놀랠 일이 발생한다.
  그러나 상하좌우를 주의하고 경계하면 우환은 해소된다. 안정하라.
  `,`■ 후원이나 직위의 안정이
  위태롭다. 부부가 똑같이
  음탕하다. 분수를 넘으면
  재앙을 맞는다. 도모사는 우환이 되고 허비만 많다.
  특히⊖女는 별리ㆍ고독녀이고, 타인 남자를 적극적으로 유혹하여 거래하고 있다. 四月 임신사 有
  
  ⊖상가부정이 들었고 가택을 옮기는 兆이다. 
  `,`■ 자충수적 우환이 있다.
  ⊕⊖가정에 고모신사부정이
  들어 관재를 일으킨다.
  또 一女二男 삼각관계도 있다.
  사건이 하나가 아니다.
  관재를 피해 피신하려는 의도가 있다. 타인과 화합하고 신뢰를 얻는 쪽으로 노력하는 것이 해결의 열쇠이다.
  `,`■ 가택이 손상된다.
  가택의 권리가 넘어간다.
  쟁투ㆍ장해가 있다.
  서로 시의하고 서로 화합하는 이중성을 갖는 사정이다. 二事이고 거듭 발생한 일이다. 
  임신사로 분리ㆍ개변사가 기한다.
  `,`■ 처첩의 임신이 있다.
  금전문제가 발생하였고
  가정 손모가 따른다.
  ⊕허위, ⊖문서에 의한다.
  一男二女 사건이다.
  여자는 남자를 잘 따르고 헌신한다. 
  폐지사를 다시 부흥시켜 좋게 된다.
  `,`■ 가정에 어른의 병자가 있다.
  상복수이다. 四月 임신 有
  女는 별리ㆍ고독녀이다.
  남의 남자를 적극적으로 유혹하여 거래하고 있다. 
  일도 여건부족이고 간난한 입장에 있다. 가정이 편안치 못하고 불화한다.
  男은, 자손사로 凶을 맞고 임신을 겪는데 애를 낳고 흉해진다. 
  `,`■ 가내 우환이 있고, 가장을
  속이며, 가인이 원한을 품고
  가해하려 한다.
  가정에 허비가 많다. 
  男은, 별리고독하다. 신변에 저주받는 일이 있다.
  임신사로 금전손모가 발생하게 된다.
  음사가 지나쳐 추하게 되는 것을 방지해야 한다.
  四月 임신사가 있다.
  女:구추앙화-공망午月․年상사 주의하라.
  `,`■ 가내 우환이 있고, 가장을
  속이며, 가인이 원한을 품고
  가해하려는 兆가 있다.
  男：어떤 일도 진행하지 않는 것이 좋다. 인택이 힘을 잃게 된다. 처가 음탕하다.
  女：⊖남의 남자를 적극적으로 유혹해 거래하고 있다. 가운이 쇠퇴하는 징조이다.
  `,`■ ⊕⊖가정에 저주원심 부정이
  들어 가정 동요ㆍ관재
  도피사가 발생한다.
  여자는 남편에게 凶이 동한다.
  무리한 계획이나 행동을 절제하고 지키고 있으면 시운이 열리기 된다.
  관직자는 午月에 기쁨이 온다.
  일반이은 권리를 잃는다.`]
  const SIBO2_1 = [`■가택내, 여ㆍ임신사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■목적미수반목외사이다.
  내방조짐 상의 :  
  조용히 있는게 이롭다.
  녹을 탈취당한다.
  도난 질병이 기함.
  `,`■목적성부반목외사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  자녀의 흉이 발생한다.
  `,`■자손ㆍ박관ㆍ문서허위사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다.
  부모․문서가 흉하다.
  `,`■병ㆍ의심가택내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액 예방이 필요하다
  `,`■근친신변외사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■여ㆍ임신내외사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■財ㆍ女가택내사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,`■근ㆍ친,가택내사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  후원사는 난망하고 부모재액 예방이 필요하다
  `,`■가택별리내사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官･夫, 심중두려움이있다.
  내방조짐 상의 : 
  원행·이동이 있다.
  은근하고 伏하라.
  손모가 과다하다.
  `,`■재난해소신변외사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  허위․사기 주의 요함`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/028/row-1-column-1.png`,
    `/static/images/sagwa/028/row-1-column-2.png`,
    `/static/images/sagwa/028/row-1-column-3.png`,
    `/static/images/sagwa/028/row-1-column-4.png`,
    `/static/images/sagwa/028/row-1-column-5.png`,
    `/static/images/sagwa/028/row-1-column-6.png`,
    `/static/images/sagwa/028/row-1-column-7.png`,
    `/static/images/sagwa/028/row-1-column-8.png`,
    `/static/images/sagwa/028/row-1-column-9.png`,
    `/static/images/sagwa/028/row-1-column-10.png`,
    `/static/images/sagwa/028/row-1-column-11.png`,
    `/static/images/sagwa/028/row-1-column-12.png`,
  ]
// 辛卯(신묘)
  const HEASYEOL = [
    // 1
    `  이 과는 천신이 엎드려 움직이지 않으므로 음일복음과(陰日伏吟課)로서 일지상신의 묘(卯)를 사용하여 초전으로 한다. 중․말전은 형과 충으로써 정하고 3전 묘자오(卯子午)는 용전격(龍戰格)이다. 형충(刑冲)을 취하는 것은 예컨대 천지2반이 엎드려 움직이지 않고, 움직이지 않는 것은 평화의 형상으로 너무 왕성하기에 형전(刑戰)을 사용하는 것이다.
    음일은 자신격(自信格)이라고 부른다. 음은 유순하여 스스로 약하다고 자각하기에 분수를 잘 지킨다. 이 과는 일간일지의 왕쇠를 주관한다. 왕할 때는 전진하고 쇠할 때는 기다려야 한다. 4과가 상하로 술(戌)과 묘(卯)가 지합하거나 교차지합하므로 두 사람이 동심․화합․협력하는 형상으로서 미격(美格)이다. 초전은 술(戌)과 지합하고 중․말전은 일지상신과 충형(冲刑)된다.
    백법에 이르기를 ｢상하 상합은 피차에 서로 심정이 일치하고, 태재생기(胎財生氣)는 처첩이 임신한다｣고 하였다. 태재사기(胎財死氣)는 태아를 손상한다. 생기는 진월(辰月), 사기는 술월(戌月)이다. 비전에 이르기를 ｢술(戌)의 월장에 술시(戌時)의 복음과를 얻으면 쌍태아이다｣고 하였다.
    일귀는 순행하여 3전이 사(蛇)․음(陰)․구(勾)이고, 야귀는 역행하여 3전이 합(合)․공(空)․귀(貴)이다. 일지가 사중일(四仲日)이고 3전은 사중, 천장(天將) 또는 중장(仲將)은 삼교격(三交格)으로 된다. 즉 3자가 교차하여 만나므로 이렇게 부른다. 사중신(四仲神)은 앞에 맹신(孟神)의 생기가 없고 뒤에 계신(季神)이 기탁하는 것이 없이 서로 연루되고 음에 패(敗)하므로 왕신이라면 불손(不遜)한 형상으로 된다. 그러나 일간상신에 태상이 승하여 흉한 가운데 길하다.`
    ,
    // 2
    `  이 과는 제4의 축인(丑寅)과 하에서 상을 극하여 중심역연여격(重審逆連茹格)으로 삼기(三奇)를 이루면 흉을 해소한다. 중심(重審)은 하에서 상을 범하므로 대개는 불순하며, 부녀자에 의하여 일어난다. 천후가 축(丑)의 초전에 승하면 아이에게 병환이 있다. 해(亥)에는 정신(丁神) 백호가 승하여 일간을 극하므로 병은 위험하다.
    백법에 이르기를 ｢일간일지상신이 각각 왕록(旺祿)에 임하면 망동을 꺼린다｣고 하였다. 백호가 둔귀(遁鬼)에 승하는 것은 병단(病斷)하면 중증이다. 비전에 이르기를 ｢일간상신이 왕록이라도 현무가 앉으면 현탈(玄奪)이라 하여 복록을 빼앗긴다｣고 하였다. 3전은 자축(子丑)이 서로 더하여 무슨 일에서나 반드시 이루어지며, 길장이 승하면 적절하다. 3기가 초전을 이루면 의혹을 풀고 기쁨을 맞는다.
    일귀는 순행하여 3전이 후(后)․음(陰)․현(玄)이고, 야귀는 역행하여 3전이 청(靑)․공(空)․백(白)이다. 청룡이 인수(印綬)로 되어 문서일에서는 모두 좋다. 3전이 명몽(冥蒙)한 형상이다. 예컨대 축(丑)은 인(寅)에 향하지 않고 자해(子亥)로 음위(陰位)로 물러나므로 사물이 음기로 되어 명몽(冥蒙)의 징조를 나타낸다.`
    ,
    // 3
    `  이 과는 4과 중에서 제3과까지 상하에 음양의 극이 있다. 음일음비(陰日陰比)이나 극하는 곳이 두 자리가 있고 맹신의 극은 없으니 중상(仲上)의 극이다. 축묘(丑卯)가 초전이 되며, 섭해역간전과(涉害逆間傳課)로 된다. 섭해과는 무슨 일에서나 순조롭지 못하고 또 역여전격이므로 사물에서 정체가 많다. 초전은 일간을 생하는데 인수․청룡이 승하며, 중전은 이역문(移易門)이라 하여 중도의 변화를 아는데 해(亥)에 정신(丁神)으로 된다. 금일(金日) 정신은 흉하게 움직일 뿐만 아니라 백호․현무의 흉장은 내몸을 극하니 흉이 신속하게 나타난다. 축(丑)은 묘신이지만 묘(卯)의 제지(制地)에 있어서 묘문개격(墓門開格)으로 되어 음택(陰宅)을 비는 데에 좋다. 음택이란 묘상(墓相)을 말한다. 또 여자가 복통에 걸리는데, 묘목(卯木)이 토(土)를 극하기 때문이다.
    백법에 이르기를 ｢금(金)일 정신에 극되는 것은 흉화를 만나 움직이고, 신묘일(辛卯日)로 일지상에 축(丑)이 더해져 초전을 이루는 것은 구추격(九醜格)이라 하여 모든 일에서 불리하다｣고 하였다
  따라서 모친 살상을 주의해야 한다(女임).  3전이 퇴여(退茹)하여 극음의 지(地)에 들면 사물에 음기(陰氣)가 흐른다. 일귀는 순행하여 3전이 현(玄)․백(白)․청(靑)이고, 야귀는 역행하여 3전이 백(白)․현(玄)․후(后)이다. 이 과는 흉이 있고 길조가 없는 과격(課格)이다.`
    ,
    // 4
    `  이 과는 상하의 극이 없고 요극도 없는 묘성과로서, 음일이니 천반 유(酉)의 하신 자(子)를 써서 초전으로 한다. 중전은 간상신인 미(未), 말전은 일지상신의 자(子)를 사용하여 묘성호시과(昴星虎視課)이다.
    일간상의 미(未)는 백호가 승하는데, 호시격으로 백호를 보면 흉조이다. 과전 중에 형해(刑害)가 중첩되어 만나니 반드시 놀라운 일을 만난다. 일간상신의 미(未)는 공망으로 되고 자(子)가 초․말전에 설기한다. 이 과는 움직이지 않는 가운데 놀라움과 우환이 발생되는데 다행히도 중전이 공망하고 지형(支刑)이 또 힘이 없으니 육해(六害) 또한 상해를 이루지 않는다. 주의하여 흉해를 경계하고 흉을 피하는 것이 좋다.
    백법에서는 ｢호시격이면서 백호를 만나면 베풀기 어렵다｣고 하였다. 피차간에 의심하는 것은 육해가 따르기 때문이다. 비전에서는 ｢택상자(宅上子)에 탈기하여 초전을 이루는 것은 도난․분실이 집안 사람의 행동이며, 천공이 승할 때는 사용인이 훔친 것이다. 말전의 자(子)는 일지상신에 돌아가므로 훔친 뒤에 집을 나가지 않았다｣고 하였다. 일귀는 역행하여 3전이 주․백․주이고, 야귀는 3전이 공․후․공이다. 무릇 묘성과는 부동 불변하는 음기의 과이므로 형옥(刑獄)․질병 등의 정단에는 가장 꺼리며, 가택을 정단할 때는 안정하면 불칙한 일이 없고 구태의연할 수 있다.`
    ,
    // 5
    `  이 과는 제1과와 제4과에서 2상(二上)이 2하(二下)를 극하고, 음일이니 음비(陰比)의 극이므로 미해(未亥)를 초전으로 하여 지일비용곡직격(知一比用曲直格)을 구성한다.
    3전 목(木)국은 일간의 재로 되고 재는 일간상신의 관귀효(官鬼爻)를 도와 재가 귀(鬼)로 화한다. 그런데 일지상신 및 말전의 해(亥)는 오(午)의 관귀를 제어하므로 귀를 두려워할 것은 없다. 흉이 변하여 길조로 된다. 말전의 해(亥)는 정신(丁神)이 승하고 음일 음비는 무슨 일이나 안에서 생긴다. 또 4과를 초전으로 하는 것은 맥월격(驀越格)으로서 길흉이 모두 급작스레 일어난다. 3전에 천후(天后)․육합(六合)이 승하면 음사(陰私) 암매한 일이 있다. 일간상신이 공망을 만나고 칠살이 공망하면 흉한 일이 해소되고 구제된다. 초전의 공망은 과숙(寡宿)으로 되어 결혼이나 화합은 흉하다.
    백법에서는 ｢귀신(貴神)이 술(戌)에 임하면 옥(獄)에 앉는다｣고 하였다. 귀인이나 존장의 원조를 얻기 어렵다. 일간상신에 귀신이 앉는 것은 천을신기(天乙神祗)라고 말한다. 일귀는 역행하여 3전이 백․후․합이고, 야귀는 3전이 후․합․백이다. 비전에 이르기를 ｢정신(丁神)이 관귀(官鬼)에 있으면 임관이나 부임은 극히 빠르고, 보통 사람이라면 가택에 흉화가 발생한다. 삼합곡직격(三合곡曲直格)이 공망하면 썩은 나무로서 기재를 이루지 못한다｣고 하여 목적을 달성하기 어렵다고 하였다.`
    ,
    // 6
    `  이 과는 4과 중의 제3과에 상하의 극이 있고 하에서 상을 극하므로 이것을 취하여 술묘(戌卯)를 초전으로 하니 중심참관불비격(重審斬關不備格)으로 된다. 3전 술사자(戌巳子)는 4과를 떠나지 않고 회환격(廻環格)으로 된다.
    중전에 역마가 관효(官爻)로 되면 동요가 있다. 4과 중에 극이 많으면 사건이 하나가 아니거나 파란이 많다. 불비격(不備格)은 음일이니 양을 버리고 1음2양으로 되어 두 남자가 한 여자를 다투는 형상이다. 따라서 색정 사건이면 2남1녀가 다루는 3각 관계다. 기타의 사건에서는 불비, 부족하거나 또는 바르지 못하다.
    백법에 이르기를 ｢귀신의 차질(蹉跌)은 무슨 일에서나 착오가 생긴다. 월장에 현무가 앉으면 태양이 무(武)를 비춘다고 하여 적(賊)을 잡을 수 있다｣고 하였다. 비전에는 ｢묘(卯)가 신(申)에 가해지거나 술묘(戌卯)에 가승(加乘)되면 단륜격(斷輪格)이라고 하여 병을 정단하면 수족의 행동이 부자유스럽거나 수족이 손상되는 일이 있으며, 술(戌)이 묘(卯)에 가해지면 지합(支合)으로 되고 둔간하여 병(丙)과 신(辛)은 간합한다. 즉 천지덕합(天地德合)으로 되어 타인과 화합하고 신뢰를 얻어 복상(福祥)을 초래한다｣라고 했다.`
    ,
    // 7
    `  이 과는 천지반이 충위(冲位)에 있으므로 반음과(返吟課)로서 하에서 상을 극한다. 제4과 묘유(卯酉)를 사용하여 초전으로 한다. 상하가 7충하는 가운데 교차육합(交車六合)으로 천지가 각각 지합한다. 즉 과전(課傳)이 충해합(冲害合)으로 교체되므로 쟁투․장해가 있는 가운데 화합이 있다. 일귀․야귀가 다 인신(寅申)․자오(子午)로 아래에서 극되므로 윗사람의 원조와 은택은 불가능하다. 초․말전이 재로 되고 일지상신 중전은 유(酉)의 형제효(兄弟爻)가 있어서 재물의 다툼을 초래한다.
    백법에 이르기를 ｢일지상신 건록은 권섭(權攝)이 부정(不正)한 일이 있고, 상하가 상합하면 피차 서로의 심정이 맞으며, 두 귀(貴)가 극을 받으니 귀(貴)를 얻기 어렵다｣고 하였다. 비전에 이르기를 ｢반음과로 정단하면 한열이 왕래하고 폐장이나 심장이 쇄약한 증상이다. 또 무슨 일에서나 두가지 사건이 일어난다｣고 했다. 일귀는 역행하여 3전이 후(后)․청(靑)․후(后)이고, 야귀는 순행하여 3전이 현(玄)․합(合)․현(玄)이다. 현무가 초․말전에서 보이니 도적․분실․부정(不正) 등이 모두 한번이 아니고 거듭하여 발생한다.
    묘(卯)가 신(申) 또는 유(酉)에 가해지는 것은 단륜격(斷輪格)으로 되며, 묘(卯)는 목기(木器)인데 금(金)의 극을 받아 기구(器具)를 이루므로 옛 것을 고쳐서 정신(鼎新)한 형상이다. 묘유일(卯酉日)로서 초전이 묘유(卯酉)이고 년명이 또한 묘유(卯酉)이니 참다운 용전격(龍戰格)으로 된다. 양은 천도(天道)이며 군자의 도(道)이다. 음은 지도(地道)로서 신(臣)의 도(道)이다. 양은 움직이고 음은 순(順)을 주관한다. 양이 부를 때는 음은 이것에 화하여 맞서지 않는다. 묘유(卯酉)는 음양이 서로 사귀어 고르게 된다. 양은 건(乾)으로서 청(靑)이라 부르며 음을 극하여 싸우므로 용전(龍戰)이라 이름한다.
    이 과(課)는 분리․쟁투․집산․불안․진퇴․길흉․안태(安泰) 등이 있다. 일간은 왕성하여 분에 넘치고, 일간이 쇠퇴되어 미급되는 것을 두려워하며, 흉 중에 길이 있고 길 중에 흉을 이룬다.`
    ,
    // 8
    `  이 과는 4과 중에서 제3과까지 상하의 극이 있으며 하에서 상을 극하는 제1과 묘술(卯戌)을 사용하여 초전으로 하는 중심과(重審課)로 된다. 3전 묘신축(卯申丑)은 4과를 떠나지 않으므로 회환격(廻環格)으로 된다. 일지(日支)의 묘(卯)가 일간상에 더해지면 췌서격(贅壻格)인데, 4과이지만 신묘(申卯)가 가지런히 있어서 1양2음의 불비격(不備格)이다.
    일간에서 상신의 묘(卯)가 재로 되고 초전으로 되는 것은 무슨 일이나 재 또는 처첩의 용건이다. 그러므로 초전의 재는 빠르게 소득하는 것이 좋다. 늦어지면 신(申)의 극을 받아 재는 상극된다. 불비격은 바른 일이 없으며 혹은 불비․결함이 있다. 남녀의 일은 2녀1남의 3각 관계이다. 일지가 일간상으로 가고 술(戌)과 지합하여 원만하지만 극되어 남편을 잘 따른다. 신(辛)일이 말전에 묘신을 보면 구륜재단격(舊輪再斷格)이라 하여 퇴관․실직 등을 재차 도모하여 부흥하는 등의 일이 있다.
    백법에 이르기를 ｢태재생기(胎財生氣)는 아내가 잉태하고 진월(辰月)은 생기(生氣)로 된다｣고 하였다. 비전에 이르기를 ｢태세(太歲)가 택에 들어 택을 극하면 반드시 사상사(死喪事)가 있다｣고 하였다. 이 과로서 만일 신년(申年)이라면 일지상신의 신(申)은 태세가 택에 든다고 하는데, 신금(申金)이 택신 묘목(卯木)을 극하는 것과 같은 것을 말한다.
    일귀는 역행하여 3전이 후(后)․공(空)․사(蛇)이고, 야귀는 순행하여 3전이 현(玄)․주(朱)․백(白)이다. 또한 불비격은 천후를 점하면 음기(陰氣)이고 해산을 점하면 여자로서, 음이 양을 능가하므로 양에는 결함이 있다.`
    ,
    // 9
    `  이 과는 4과 중 3개의 과에 상하의 극이 있고 하에서 상을 극하는 것이 두 과가 있어서 음일 음비를 취할 수 없다. 그러므로 섭해과(涉害課)로 된다. 지반 맹신(孟神)의 극이 없기 때문에 중신(仲神)의 극인 미묘(未卯)를 초전으로 한다. 3전은 해묘미(亥卯未)의 3합목국곡직격(三合木局曲直格)이다.
    초․중전이 공망이면 썩은 나무가 그릇을 이루지 못하는 격이다. 일간상신 및 말전의 묘(卯)는 재효(財爻)로 되어 대소를 막론하고 재의 요건이 있다. 견기격(見機格)은 기(機)를 보아 움직이는 것이 좋다. 막귀(幕貴)가 일간상에 임하므로 관공학교(官公學校) 및 입시의 건은 반드시 당선된다. 초전이 공망이면 모든 일에서 처음에는 허화무실(虛花無實)한 징조인데, 년명행년(年命行年)이 미(未)로 전실(塡實)하면 공망은 해소된다고 보아 정단한다. 태(胎)는 장생(長生)에 앉으니 임신은 길하고 출산은 흉하다.
    백법에 이르기를 ｢귀신이 진(辰) 또는 술(戌)에 임하는 것은 귀신(貴神)이 옥(獄)에 앉는다｣고 하여 웃사람이나 존장에게 번민하는 일이 있다. 비전에 이르기를｢월장(月將)이 일간상신에 가해지고 관귀의 성(星)이 년명(年命)에 가해지는 경우에는 입시․선거 등에서 반드시 당선된다｣고 하였다.
    만일 말전이 공망하는 경우에는 공망을 탈하여 입시에 합격한다. 4과 중에서 3하(三下)에서 3상(三上)을 극하는 것은 유도액격(幼度厄格)으로 되어 장유(長幼)이기 때문에 이익이 되지 않는다. 또 초전이 묘신이면 걱정스러운 일이 있다. 3전에 길장이 승할 때는 흉을 구(救)하여 길조로 된다.`
    ,
    // 10
    `  이 과는 제4과에서 유오(酉午)가 하에서 상을 적하므로 중심과(重審課)로 되며, 사중일(四仲日)에 중장(仲將)이 승하면 삼교격(三交格)이 된다. 귀신(貴神)이 묘(卯) 또는 유(酉)에 임하면 여덕격(勵德格)이다.
    야귀(日貴) 백호가 묘(墓)에 승하여 일간의 신(身)에 임하는 경우에는 병을 정단(正斷)하면 흉이다. 초전의 유(酉)는 일록(日祿)에 해당하고 오(午)가 극지에 더해져도 공망의 지에 있으니 헛된 것에 놀랄 뿐이다. 말전의 일간이 재로 되고 야귀는 현무가 앉아서 소모 또는 재물의 도난이나 분실 사건이 있다. 간과 지가 교차상극(交車相剋)하므로 교섭․무역․교환하는 일에는 이롭지 못하다. 오(午)는 공망하여 허위가 많고 참된 뜻이 적다.
    백법에 이르기를 ｢일간상에 백호․묘신이 승하면 병을 점하여 흉하다｣고 하였다. 오축(午丑)에 육해(六害)가 있으면 의심이 서로 따른다. 비전에 이르기를 ｢을무기신임(乙戊己辛壬)의 오간(五干)에 사중신(四仲神)이 가해지는 것은 구추살(九醜殺)이다｣라고 하였다. 사중신(四仲神)의 유(酉)가 초전으로 되고 일간 또는 일지상신에 축(丑)을 보면 구추격(九醜格)이 된다. 구추란 60간지 중에서 5일을 1후(候)로 하여 3후는 15일의 교(交)에 묘오유자(卯午酉子)의 四仲의 날을 가리킨다. 즉 장(壯)이 장(壯)을 만나 장(壯)이라는 물건이 될 때, 늙어서 장이 될 때는 길이 아니라 죽음에 이르므로 추(醜)라고 하는 것이다. 천간 5에 지지의 4가 합쳐져 9로 되므로 이렇게 명명하였다. 장(壯)에 지나쳐서 황음(荒淫)이 극(極)하고 탐취(耽醉)에 만족하여 뜻을 제멋대로 하는 형상이다.  따라서 午年月에 모친살상을 주의해야 한다(女임)
    이 격(格)은 일간 또는 일지상신에 축(丑)이 있는 것을 취한다. 축(丑)은 귀신의 자리이다. 추는 귀한 자리에 들지 않고 추에 싫증나지 않아 추가 왕성하여져서 추상(醜象)을 이룬다. 일귀는 순행하여 3전이 백․음․사이고, 야귀는 3전이 합․공․현으로 된다. 초전이 백호이고 말전이 동신(蛇神)이면 호두사미(虎頭蛇尾)라 하여 처음에는 흉험하다 해도 종국에는 무사하게 된다. 삼교격(三交格)은 피차의 교섭사가 연결되어 종결되지 않는다.`
    ,
    // 11
    `  이 과는 4과 중에 상하의 극이 없고 인(寅)과 사(巳)의 요극(遙剋)이 있으며, 음일이니 사(巳)의 극을 취하여 초전으로 하여 호시격(蒿矢格)이다.
    4과 모두 하에서 상으로 탈루(脫漏)되는 것을 원소근단(源消根斷)이라 하여 소모가 많다. 병을 정단하면 세력이 쇠약하고 무절제에 의한 병원(病源)으로 되며, 원기부족하여 결국에는 위독하게 될 염려가 있다. 요극호시격(遙剋蒿矢格)은 힘이 약하며 상해하기 어려우므로 길흉이 모두 경하다. 후(后)․합(合)이 있어서 일녀격(泆女格)으로 되면 사물이 애매하며, 내몸에 이롭고 상대방에게는 이롭지 못하다.
    3전이 동미유(巳未酉)이면 간전격(間傳格)으로서 변영(變盈)이라 부른다. 사물은 왕성하면 쇠퇴되고 차면 이지러지는 것은 자연의 이치이다. 사미(巳未)는 양이 왕성하고, 말전의 유(酉)는 양이 이미 끝나고 변하여 음상(陰象)을 이루기에 이런 이름을 갖게 되었다.
    백법에 이르기를 ｢탈상탈(脫上脫)이 공(空)에 앉는 것은 무슨 일이나 추구하지 말라｣고 하였다. 중․말전의 공망은 전(傳)에 가지 않으니 초전의 일신(一神)으로만 길흉을 정한다. 비전에 이르기를 ｢이 과를 얻어 가정(家庭)을 정단하면 사(巳)가 육양(六陽)의 극이고 천후가 승하니 처가 음탕하다. 일간상신이 탈기로 되어 천공(天空)을 보므로 하초의 병이다｣라고 하였다. 택상(宅上)에는 파쇄살(破碎殺)이 승하고 자(子)는 1양(一陽)의 시작이고 사(巳)는 육양(六陽)의 끝이다. 일간은 내몸에서 시작하여 가(家)에서 끝나며, 특히 유(酉)의 녹신이 공망하여 가운이 퇴폐하게 된다. 일귀는 순행하여 3전이 합․청․백이고, 야귀는 3전이 후․사․합이다. 체생(遞生)하나 공망하므로 참 뜻이 없고 빈 소리뿐이다.`
    ,
    // 12
    `  이 과는 4과 중 제3과에서 하가 상을 극하여 진묘(辰卯)를 초전으로 하는 중심과로 된다. 일지상신 진을 초전으로 하여 참관격으로 되며, 귀신이 육합에 승하면 참관이 통한다.
    일간상신 해(亥)는 정신(丁神)으로 되고 백호가 승하므로 병을 점단하면 두통을 앓는다. 일간일지가 교차상생으로 되어 무슨 일이나 유정(有情)한 형상이다. 말전의 귀신이 공망하여 안타깝다. 일간상신이 둔귀(遁鬼)로 되고 백호가 승하면 흉은 신속하게 나타난다. 말전이 초전을 생하고 함께 만난다 해도 편안할 수 없다.
    백법에 이르기를 ｢나망(羅網)을 만나면 모사(謀事)하여도 졸책을 초래한다｣고 하였다. 서로 생하고 함께 생하는 것은 만사에 유익하다. 금일(金日)이 정신을 만나면 흉화에 움직인다. 비전에 이르기를 ｢일간일지가 교차상생하고 초전이 일간을 생하며 말전은 초전을 생한다. 이와 같은 것은 형통격(亨通格)이라 하여 사람은 모든 일에서 순리롭고 시운이 열린다. 또 나망격은 자기를 지키기에 이로우며 무리하게 책모하는 것은 좋지 않다｣고 하였다. 현임 관리는 이동하면 고생을 초래한다.
    진사오(辰巳午)의 진여(進茹)는 승계격(昇階格)으로 되어 광명의 형상이니 공명․구직하는 사람에게는 기쁨이 있다. 말전의 공망은 오(午)의 월일(月日)이 해공하여 길을 초래한다. 일귀는 순행하여 3전이 주(朱)․합(合)․구(勾)이고, 야귀는 역행하여 3전이 주(朱)․사(蛇)․귀(貴)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
