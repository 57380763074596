
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`불성`,8,3,`없음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`불성`,9,2,`없음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`불성`,10,1,`없음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,11,12,`없음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,11,`없음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`불성`,1,10,`없음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`불성`,2,9,`없음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,3,8,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`불성`,4,7,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,5,6,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,6,5,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,4,`없음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`6`,``,`결속단합`],
    [`처첩`,`처첩 관련사`,`大`,`개시기`,`위례득죄`,`고위연회`],
    [`진득기녹(進得其祿)`,`일을 진행하여 식녹을 획득하게 되는 상`,`大`,``,``,`초대열석`],
    [`가실불화(家室不和)`,`부부가 불화하는 상`,`小`,``,``,``],
    [`자매`,`자매 관련사`,`小`,``,`외사개신`,``],
    [`친구`,`친구 관련사`,`大`,`5`,`음8월도래`,`불변근신`],
    [`동료`,`동료 관련사`,`小`,`배양기`,`가실불화`,``],
    [`필방화촉(必防火燭)`,`반드시 화촉(화재)을 예방하는 주의가 필요한 상`,`小`,``,``,``],
    [`관직영전(官職榮轉)`,`관직자는 영전하는 상`,`大`,``,``,``],
    [`관사`,`외사 급속송쟁-상해 우려`,`大`,``,``,`사송흉함`],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`4`,`급속송쟁`,`동요분산`],
    [`매사 영속성없음`,`관사직업권리남편사는 선천적 기복재해를 겪음`,`小`,`개시기`,`상신우려`,`출행이동`],
    [`고위무력(高位無力)`,`도움 받을 고위인이 무력하게 된 상`,`大`,``,`가실불화`,`불변근신`],
    [`고위안좌(高位安坐)`,`고위에 올라 대중의 이익을 강구하는 상-단, 부족함`,`小`,``,`고위안좌`,`문서길함`],
    [`결속`,`결속단합하여 大財를 획득하나 부모신변 재해근심 발생함`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`小`,`3`,`투서헌책`,`손해손실`],
    [`매사 영속성없음`,`관사직업권리남편사는 선천적 기복재해를 겪음`,`小`,`학습기`,`고위면접`,`도적소실`],
    [`다재공수(多財空手)`,`금전은 많고 여럿이나 막상 손에 쥐는 것이 없게 되는 상`,`大`,``,``,``],
    [`엄목지상(掩目之象)`,`눈이 가려진 격의 상`,`小`,``,``,``],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`2`,`엄목지상`,``],
    [`동료`,`동료 관련사`,`小`,`면모기`,`(눈이가려`,`사행불량`],
    [`가이고귀(可而告貴)`,`고위인에게 충분히 말 할 수 있게 되는 상`,`小`,``,`진격)`,``],
    [`엄목지상(掩目之象)`,`눈이 가려진 격의 상`,`大`,``,``,``],
    [`명예`,`수상(受賞) 관련사`,`大`,``,``,`애증화합`],
    [`직녹`,`직위녹위 관련사`,`大`,`1`,`외교막힘`,`송쟁파재`],
    [`자산`,`자산권리 관련사`,`小`,`절정기`,`투서불용`,`극처`],
    [`필유손식(必有損息)`,`반드시 가정 여식의 손실 또는 折傷사가 있게되는 상`,`小`,``,``,`급속사有`],
    [`우유한가(優遊閑暇)`,`유유자적 한가한 상-실업자 격)`,`小`,``,``,`실업자상`],
    [`후원`,`후원조력문서 관련사`,`大`,``,``,``],
    [`색정`,`외남 색정사 有`,`大`,`12`,`약속비밀`,`투서헌책`],
    [`부모`,`부모 관련사`,`小`,`과시기`,`누설득죄`,`고위면접`],
    [`호악개무(好惡皆無)`,`좋고 나쁜 일이고 간에 무효가 되는 상`,`小`,``,`색정사有`,``],
    [`사행불량(私行不良)`,`품행 행실이 불량한 상`,`小`,``,``,``],
    [`결속`,`결속단합하여 大財를 획득하나 부모신변 災害 발생함`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`11`,`외사침체`,`엄목지상`],
    [`동료`,`동료 관련사`,`小`,`쇠퇴기`,`관재형책`,`(눈이 가려`],
    [`종난피탈(終難避脫)`,`결국 나쁜 일을 탈피할 방법이 없는 상`,`小`,``,``,`진격)`],
    [`도적소실(盜賊消失)`,`도적이 실족하여 침범하지 못하게 된 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,`급속송쟁`,``],
    [`극부`,`남편 갈등사`,`小`,`10`,`파재실종`,`가정막힘`],
    [`자녀`,`자녀 관련사`,`大`,`침해기`,`애증화합`,`투서불용`],
    [`양귀무력(兩貴無力)`,`고위인이 무력하게 되어 도움을 받지 못하는 상`,`小`,``,`면모훼손`,``],
    [`우유한가(優遊閑暇)`,`여유자적한 상-실업자에 통함`,`大`,``,`충산분산`,``],
    [`박관`,`직업직위 불안사-개신사`,`小`,``,``,``],
    [`색정`,`내녀 색정사 有`,`小`,`9`,`외사개신`,`색정사有`],
    [`자녀`,`자녀 관련사`,`大`,`소외기`,`고위연회`,`비용과출`],
    [`소파인구(笑破人口)`,`가정에 웃음이 사라진 상`,`小`,``,`초대열석`,`도적실세`],
    [`음신조격(音信阻隔)`,`소식을 기다리나 오지 얺는 상-와도 나쁨`,`大`,``,``,`잡음`],
    [`형제`,`형제 관련사`,`小`,``,``,``],
    [`친구`,`친구 관련사`,`大`,`8`,`불변근신`,`침체공허`],
    [`동료`,`동료 관련사`,`小`,`침체기`,``,`음사폐호`],
    [`동지경공(動止警恐)`,`가만히 있으나 나아가나 놀랍고 두려운 일을 겪을 상`,`小`,``,``,`가택안녕`],
    [`도적실세(盜賊失勢)`,`도적이 실족하여 침범하지 못하게 된 상`,`大`,``,``,``],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`직업`,`직업 관련사`,`小`,`7`,`출행이동`,`면모훼손`],
    [`남편`,`남편 관련사`,`大`,`단절기`,`송흉문길`,`우유한가`],
    [`역명한화(亦名閑話)`,`명성이 때를 지나 잊혀지는 상`,`大`,``,``,`(실업저상)`],
    [`송흉문길(訟凶文吉)`,`형송사는 나쁘고 문서소식사는 길한 상`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,``,0],
    [`단계`,`단계`,0,`가능`,"불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明시작`,``,0,`금전사업처조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明종료`,``,0,`자매친구동료조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,`세속허사자로불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明하향`,``,0,`형재친구동료사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗절정`,0,`명예직위자산권리사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗시작`,0,`후원문서부모색정사조력으로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗종료`,0,`자매친구동료조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,``,"직업직위남편갈등자식사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明진행`,``,0,``,"직업직위남자여식색정사로불가"],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,`暗진행`,0,`형제친구동료조력사로가능`,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [`明절정`,``,0,``,`관사 직업 남편 사로 불가`],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0],
    [``,``,0,``,0]
  ]
const columnAF =[
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함`,0,0,`명분이 있는 사안이므로 정정당당하게 속결해야만 유리하고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,0,0,`일이 거꾸로 진행되어 퇴하는 것이 옳으나 1950,51,62,63,74,75,86,87,98,99년 출생자는 진행하여 좋음**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적으로 사안을 지체시키고나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 시시비비를 가리지 말고 유순한 처신으로 후결해야만 결과가 반드시 유리한 人`,0,0,`일이 결과없이 반복됨-점점 어두워지므로 퇴하는 것이 좋음**색정사 근심이 있는 상**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻이 있음`,0,0,`저주소송의 조짐이 있는 人`,0,0,`신규사가 있음**1남2녀 삼각관계 있음**여건이 부족불비함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적으로 사안을 지체시키고나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 시시비비를 가리지 말고 유순한 처신으로 후결해야만 결과가 반드시 유리한 人`,0,0,`결속단합하여 大財를 획득함**좋은 일이면 계속 여러 차례 좋고 나쁜 일이면 여러 차례 계속 나쁨**깊은 산속으로 숨고싶은 심정이 다가오므로 예방해야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`간난험악풍상고초**만약 타인과 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 사안을 이후로 미뤄야 함`,0,0,`풍상고초 후 성공할 人`,0,0,`윗인을 아래인이 핍박함**타인의 호의를 쉽게 받아들이지 말아야 함**전후좌우를 세밀히 살펴야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집한 상이므로 신중해야 함`,0,0,`모든 일이 뜻과 다른 결과를 초래하고 배신되며 이심을 갖게 되는 人`,0,0,`신구사가 있음**이동사나 속결사도 좌절됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함`,0,0,`명분이 있는 사안이므로 정정당당하게 속결해야만 유리하고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,0,0,`독신고독각방주말부부생리사별적 처지임**생업과 가정이 소외 침체함-생업은 음3월, 가정은 음6월에 풀리므로 최선을 대해야 하고 미리 대비 함이 좋음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게 속결하는 것이 유리함`,0,0,`명분이 있는 사안이므로 정정당당하게 속결해야만 유리하고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,0,0,`여러 일을 결속시켜 大財를 획득하고 좋은 일이면 계속 이어지고 나쁜 일이면 역시 계속됨**군자가 대를 기다리는 격이나 점점 어두워짐-단 전화위복 됨**가정가택사에 후회사가 있게 됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위 세(狐假虎威 勢)**만약 타인과 다툰다면 상대는 호가호위 세를 부리나 전의는 별로 없어 피해도 없으며 있다해도 가벼움 `,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란 듯 하는 심중이나 조용히 있으면 근심이 자연 사라지게 되는 人`,0,0,`신규사가 있음**1녀2남 삼각관계 있음**여건이 부족불비함**독신고독각방주말부부생리사별적 처지임`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유화유순후결**만약 타인과 다툰다면 맞서지 말고 순응유화적으로 사안을 지체시키고나 뒤로 밀어야만 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 시시비비를 가리지 말고 유순한 처신으로 후결해야만 결과가 반드시 유리한 人`,0,0,`일이 띄엄띄엄 진행되고 중간 결과가 허무함으로 초반에 승부를 내고 빠져야 함**일이 밝은 데로 나아가는 듯하나 불성함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`출행불귀재앙우려(出行不歸災殃憂慮)**만약 타인과 다툰다면 피해가 크게 되므로 근신해야 함`,0,0,`마치 등뒤에서 백호가 노려보는 격으로 멀리 가는 차,배,비행기를 이용하면 사고가 나서 돌아올 수 없는 재앙을 당할 수 있는 人-특히 음1,2월에 조심-출행 예정에서 최소 3일.`,0,0,`남녀 직원의 공모사가 있음-주의해야 함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인과 다툰다면 상대는 뜻이 있어도 공격하지는 않음`,0,0,`스스로 자임하고 옛것을 지키며 새 기회를 기다리는 人-고시 준비생에 많음`,0,0,`소송사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면-무력`,`선성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리가져갈인`,`아발동후회사타발동급속사겹침`,`기사`,`신사`,`아외사타내사겹침`,`아표면사타이면사겹침`,`배신-무력`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면`,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`타발동사`,`기사`,`신사`,`내사`,`표면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`기사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타바동급속사`,`기사`,`신사`,`내사`,`이면사`,`배신`,`보성`,`위선`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인-침체인`,`타발동급속사`,`미사`,`신구중간사`,`내사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`타발동급속사`,`미사`,`구사`,`내사`,`이면사`,`좌고우면`,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`표면사이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`무력`,`무력`,`무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 무력`,`무력`,` 무력`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV =[
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,1,4,`음6월`,3,3,6,`음12월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음5월`,2,3,5,`음11월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음4월`,2,1,3,`음12월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월-연기 또는 파혼우려`,2,3,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음8월-파혼우려`,2,1,3,`음2월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음3월`,2,1,3,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,1,4,`음6월-파혼우려`,3,3,6,`음12월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,3,4,`음12월-연기우려`,2,3,5,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음12월`,2,1,3,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음9월`,2,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음8월`,2,2,4,`음4월-파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음7월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 둔한 것 같으나 조정능력이 있어 불리한 조건을 극복시키게 됨-단 1950,51,62,63,74,75,86,87,98,99년 출생자는 업무에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언행이 불성실하고 업무도 결과없이 반복하며 결국 전체를 기울게 함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하나 업무에 실패함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하나 업무를 일관시키는 결속단합을 통해 재정을 크게 확충함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 폭력적이고 조급하여 업무를 손실나게 하고 상호 증오감을 일으킴 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 유순하고 용모가 준수 미모이나 업무진행이 약해 전체적으로 동요불안사가 있게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사정을 갖고 타인과 같은 소리를 내며 업무도 초 중반이 수준이하이고 종반에 이르러 서는 내부 침체를 초래함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미가 있고 사심이 있으나 업무를 일관시키는 결속을 통해 재원을 크게 확충하는 어적을 이룸 `,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성격이 유순하고 준수 미모이나 업무가 강하지 못해 소송사가 이어져도 재정을 확보함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`고집이 세서 업무 전반이 수준이하 또는 실패로 평가되게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인정미는 있어도 사심이 많고 초반 침체 중반 손실 종반 이익을 키우나 자칫 큰 사고를 당할 우려가 있음`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하고 속이며 초반의 실적 외 중 종반에 소송사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`다수반대`,`이행`,`선이후이`,`退背`,`근`,`去`,`주인조력`,`정남`,``,`정서`],
    [`10.11`,``,`기세없음`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`본인무력`,`退背`,`원근왕래`,`  去`,`주인가해`,`남남서`,``,`북서`],
    [`10.11`,``,`기세없음`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`  集`,`발현안됨`,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인반대`,`이행`,`본인무력`,`退背`,`원근왕래`,`  去`,`상호애증`,`서남`,``,`북북서`],
    [`10.11`,``,`기세없음`,``,``,``,``,``,``,``,``,`세밀심사`],
    [`3.6\n9.12`,``,`발현안됨`,``,``,``,``,``,``,``,``,`함`],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`반대없음`,`이행`,`선이후난`,`進向`,`  원`,`  留`,`주인조력`,`정서`,`동남`,``],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,`  集`,`음3,4월은`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`북서`,`북북동`,``],
    [`10.11`,` 多集`,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인조력`,`이행`,`사안무력`,`進向`,`  원`,`  留`,`주인무력`,`북북서`,`남남동`,``],
    [`10.11`,``,`제압불가`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세없음`,`이행`,`선난후난`,` 退背`,`  근`,`  去`,`주인조력`,`정북`,`동남`,``],
    [`10.11`,``,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선攻후난`,`退背`,`  원`,`  去`,`세입조력`,`북북동`,`정북`,``],
    [`10.11`,``,``,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`기세없음`,`이행`,`선攻후費`,`退背`,`원근왕래`,`  去`,`세입조력`,`서남`,`북북서`,``],
    [`10.11`,``,`본인제압`,``,``,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 集`,`기세없음`,`이행`,`선난후난`,`進向`,`  근`,`  留`,`세입조력`,`정서`,``,`서남`],
    [`10.11`,`多集`,`측근제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`이행`,`선난후난`,`進向`,`  근`,`  留`,`무난`,`동남`,``,`정북`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대있음`,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`본인제압`,`이행`,`선난후이`,`進向`,`  근`,`  留`,`무난`,`남남동`,``,`북북서`],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``],
    [`질병`,`전사귀`,`전사`,``,``,`묘좌`,``,`없음`],
    [``,`가장귀`,`호주`,``,``,`묘향`,``,``],
    [`생업`,``,``,`가당부정`,`성소훼손`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,`전사귀`,`전사`,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,`가장귀`,`호주사망`,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,``],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,`없음`,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`두성부정`,`기도위배`,`육친`,``,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,`정동`,`없음`],
    [``,``,``,``,``,`묘향`,`정서`,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,`큰도로`,``],
    [``,``,``,``,``,`묘속`,`화장필요`,``],
    [`가정`,`음귀`,`묘지탈`,``,``,`육친`,`모친`,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,`여귀`,`호주부인`,``,``,`육친`,``,``],
    [`질병`,` 음귀`,`묘지탈`,``,``,`묘좌`,`북북동`,`없음`],
    [``,``,``,``,``,`묘향`,`남남서`,``],
    [`생업`,``,``,``,``,`전경`,`큰도로`,``],
    [``,``,``,``,``,`묘속`,`토압`,``],
    [`가정`,``,``,``,``,`육친`,`조모`,``],
    [`질병`,``,``,``,``,`묘좌`,`정북`,`없음`],
    [``,``,``,``,``,`묘향`,`정남`,``],
    [`생업`,``,``,``,``,`전경`,`큰도로`,``],
    [``,``,``,``,``,`묘속`,`  水`,``],
    [`가정`,``,``,`음사부정`,`제사잘못`,`육친`,`조부`,``],
    [`질병`,`여귀`,`호주부인`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,`두성부정`,`기도위배`,`육친`,``,``],
    [`질병`,``,``,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``],
    [`질병`,`가장귀`,`호주사망`,``,``,`묘좌`,``,`없음`],
    [``,``,``,``,``,`묘향`,``,``],
    [`생업`,``,``,``,``,`전경`,``,``],
    [``,``,``,``,``,`묘속`,``,``],
    [`가정`,``,``,``,``,`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사 출행원행사`,`행사의관사`,``,`소식기다림-와도 나쁨`,``,`관직영전도약사`,`매사 허성불성-매사 영속성없음**외사급속출행사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 영속성없음`,`종교진출하면자립함`,``,`위례득죄사`,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**구설사송사 흉-문서소식사 길`,`외남 색정사`,`큰 뜻을 위해 불辯근신`,`고위연회초대열석희사-이동사`,``,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`실업자 상-한가함`,`내녀 색정사**남녀직원 공모사有`,`음사폐호-가택안녕`,`매사위역사`,`도적잡음-죄수됨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,``,`빈궁**병재`,``,`전도**빈궁`,``,`고위안좌-이익시행**전화위복`,``,`부부불화사`,`빈궁`,``,`전도`,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,`전도`,`재액해소`,``,`재액해소`,`빈궁`,`침체驚懼사`,`전도**관재**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`매사위역사`,`도적잡음-죄수 됨`,`외사 출행원행사`,`행사의관사`,``,`소식기다림-와도 나쁨`,``,`관직영전도약사`,`매사 영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`윌`,`위례득죄사`,`외사막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**구설사송사 흉-문서소식사 길`,`외남 색정사`,`큰 듯을 위해 불辯근신`,`고위연회초대출석희사-이동사`,``,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`실업자상-한가함`,`내녀 색정사`,`음사폐호-기택안녕`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`전화위복`,`전도`,``,`전도**빈궁**병재`,``,`빈궁`,`고위안좌-이익시행**전화위복`,``,`부부불화사`,`빈궁`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁**관재`,``,``,``,``,``,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`내녀 색정사`,`음사폐호-가택안녕`,`매사위역사`,`도적잡음-죄수 됨`,`외사 출행원행사`,`행사의관시`,``,`소식기다림-와도 나쁨`,``,`관직영전도약사`,`매사 영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`위례득죄사`,`외사막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**구설사송사 흉-문서소식사 길`,`큰 뜻을 위해 불辯근신`,`외남 색정사`,`고위연회초대열석희사-이동사`,``,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`실업저 상-한가함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁`,``,`전도**빈궁`,`전화위복`,``,``,`빈궁**병재`,``,`전도**빈궁`,`고위안좌-이익시행**전화위복`,``,`부부불화사`,`빈궁`,``,`전도`,`음4,5월화재주의`,`빈궁**관재`,`음4,5월화재주의`,`빈궁**관재`,``,`전도`,`재액해소`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`충산동요분산사`,`내사 가정 막힘**외사애증화합-급속송쟁파재-상해`,`내녀 색정사`,`관재형책사`,``,`약속비밀누설-자초 죄사`,`투서헌책 불용-금전실재사`,`외사 출행원행사`,`눈이 가려진 상`,``,`투서헌책-고위면접희사`,``,`매사 영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`도적소실사`,`매사위역사`,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**권리부여받는 경사`,`손괴재물사`,`외남 색정사`,``,`사기득재사-이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`빈궁`,``,`재액해소`,``,`빈궁`,`전화위복`,`빈궁`,``,`재액해소`,``,`전화위복`,``,`사행불량사`,`빈궁`,`재액해소`,`빈궁`,`음4,5월화재주의`,`전도**관재`,`음4,5월화재주의`,`전도**빈궁**병재`,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`사기득재사-이동사`,``,`충산동요분산사`,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`내녀 색정사`,`관재형책사`,``,`약속비밀누설-자초 죄사`,`투서헌책 불용-금전실재사`,`외사 출행원행사`,`눈이 가려진 상`,``,`투서헌책-고위면접희사`,``,`매사 영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`도적소실사`,`매사위역사`,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**권리부여받는 경사`,`손괴재물사`,`외남 색정사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**관재`,``,``,`빈궁**관재`,``,`재액해소`,``,``,`전화위복`,`빈궁`,``,`재액해소`,`빈궁**관재`,`전화위복`,``,`사행불량사`,`빈궁`,`재액해소`,`빈궁`,`음4,5월화재주의`,`전도`,`음4,5월화재주의`,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`외남 색정사**동요불안분산사`,`손괴재물사`,`사기득재사-이동사`,``,`충산동요분산사`,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`관재형책사`,`내녀 색정사`,``,`약속비밀누성-자초죄사`,`투서헌책 불용-금전실재사`,`외사 출행원행사`,`눈이 가려진 상`,``,`투서헌책-고위면접희사`,``,`매사 영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`도적소실사`,`매사위역사`,`외사막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**권리부여받는 경사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,`빈궁**병재`,`동요불안분산사`,`전도**빈궁`,`관재`,`동요불안분산사**재액해소`,`빈궁**관재`,`동요불안분산사**재액해소`,`전도`,`동요불안분산사`,`빈궁**관재`,`동요불안분산사**전화위복`,`빈궁**관재`,`동요불안분산사`,`전도`,`동요불안분산사`,`동요불안분산사**전화위복`,``,`사행불량사**동요불안분산사`,`빈궁`,`동요불안분산사`,`전도**빈궁`,`동요불안분산사**음4,5월화재주의`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`면예직녹자산사**권리부여받는 경사`,`손괴재물사`,`외남 새정사`,`사기득재사-이동사`,``,`충산동요분산사`,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`관재형책사`,`내녀 색정사`,``,`약속비밀누설-자초 죄사`,`투서헌책 불용-금전실재사`,`외사 출행원행사`,`눈이 가려진 상`,``,`투서헌책-고위면접희사`,``,`매사 영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,`도적소실사`,`매사위역사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**병재`,``,`전도**빈궁`,`관재`,`재액해소`,`빈궁`,`재액해소`,``,`전도`,`빈궁**관재`,`전화위복`,`빈궁`,``,`전도`,``,`전화위복`,``,`사행불량사`,`빈궁`,``,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`도적소실사`,`매사위역사`,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**권리부여받는 경사`,`손괴재물사`,`외남 색정사`,`사기득재사-이동사`,``,`충산동요분산사`,`내사 가정 막힘**외사 애증화합-급속송쟁차재-상해`,`관재형책사`,`내녀 색정사`,``,`약속비밀누설-자초 죄사`,`투서헌책 불용-금전실재사`,`외사 출행원행사`,`눙이 가려진 상`,``,`투서헌책-고위면접희사`,``,`매사 영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**병재`,``,`빈궁`,``,``,``,`전도**빈궁`,``,``,`전도**빈궁`,`전화위복`,``,`전도**빈궁`,``,``,`전화위복`,``,`사행불량사`,`빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`매사 영속성없음`,`종교진출하면자립함`,`도적소실사`,`매사위역사`,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**권리부여받는 경사`,`손괴재물사`,`외남 색정사`,`사기득재사-이동사`,``,`충산동요분산사`,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`관재형책사`,`내녀 색정사`,``,`약속비밀누설-자초 죄사`,`투서헌책 불용-금전실재사`,`외사 출행원행사`,`눈이 가려진 상`,``,`투서헌책-고위면접희사`,``,`매사 영속성없음**외시 급속출행이동사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`사행불량사`,`빈궁`,`전도**빈궁`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,`빈궁**병재`,``,`전도**빈궁`,``,`재액해소`,`빈궁`,`재액해소`,``,`전도`,`빈궁`,`전화위복`,`빈궁`,``,`전도`,``,`전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,`매사영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 영속성없음`,`종교진출하면자립함`,``,`위례득죄사`,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**구설사송사 흉-문서소식사 길`,`큰 뜻을 위해 불辯근신`,`외남 색정사`,``,`고위연회초대열석희사-이동사`,`실업자 상-한가함`,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`음사폐호-가택안녕`,`내녀 색정사`,`매사위역사`,`도적잡음-죄수 됨`,`외사 출행원행사`,`행사의관사`,``,`소식기다림-와도 나쁨`,``,`관직영전도약사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`고위안좌-이익시행**전화위복`,``,`부부불화사`,`빈궁`,`재액해소`,``,`음4,5월화재주의`,``,`음4,5월화재주의`,`전도**빈궁`,`재액해소`,``,``,``,`빈궁`,``,`재액해소`,`빈궁`,`전화위복`,``,`빈궁**병재`,``,`재액해소`,`빈궁**관재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,``,`관직영전도약사`,`매사 영속성없음**외사 급속출행이동사`,`종교진출하면자립함`,`매사 허성불성-세속허사자`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,``,`위례득죄사`,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**구설사송사 흉-문서소식사 길`,`큰 뜻을 위해 불辯근신`,`외남 색정사`,``,`고위연회초대열석-이동사`,`실업자상-한가함`,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`내녀 색정사`,`음사폐호-가택안녕`,`매사위역사`,`도적잡음-죄수 됨`,`외사 출행원행사`,`행사의관사`,``,`소식기다림-와도 나쁨`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,`고위안좌-이익시행**전화위복`,``,`부부불화사`,`빈궁`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,``,``,``,``,`빈궁`,``,`빈궁`,`전도`,`폐한인**전화위복`,``,`전도**빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,``,`소식기다림-와도 나쁨`,``,`관직영전도약사`,`매사 허성불성-세속허사자**외사 급속출행이동사`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,`매사 허성불성-세속허사자`,`음1,2,10,11월 방문자는 종교진출하면 성공함`,``,`위례득죄사`,`외사 막힘**내사 급속사**내사송쟁파재극처사`,`명예직녹자산사**구설사송사 흉-문서소식사 길`,`큰 뜻을 위해 불辯근신`,`외남 색정사`,``,`고위연회초대열석희사-이동사`,`내사 가정 막힘**외사 애증화합-급속송쟁파재-상해`,`실업저 상-한가함`,`내녀 색정사`,`음사폐호-가택안녕`,`매사위역사`,`도적잡음-죄수 됨`,`외사 출행원행사`,`행사의관시`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`재액해소`,`빈궁**병재`,``,`빈궁`,`고위안좌-이익시행**전화위복`,``,`부부불화사`,`빈궁`,``,``,`음4,5월화재주의`,`빈궁`,`음4,5월화재주의`,`빈궁`,``,``,`전도`,``,`전도**빈궁`,``,`빈궁`,``,`**재액해소전화위복`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  
  const CHOSA1 = [`■ 은혜가 원수로 변했네?     관송사가 벌어져 도망가     고싶은  운세야!
  여자 : 남편에게 재해가 있게          생겼네!
  관직자:자칫 권리를 상실하겠어!
  
  ⊕도로귀ㆍ가택상가부정 
  ⊖가택귀 때문이야!
  `,`■ 변동수가 있는데 진퇴
     양난 이겠네?
  
  ⊕도로귀 
  ⊖가택귀를 해소하고 밀어    부쳐 나가야 길이 열리겠어!
  
  ⊕⊖신변에 가당신사부정이      침범했네 ?
  `,`■ 색정사가 있겠는데… 
     부부가 모두 그래!
  
  ⊕변사귀 
  ⊖전사귀ㆍ가택음사부정을    해소 시키고 일을 암암리에    해나가면 되는게 있겠어!
  `,`■1男2女 색정사가 있네?
    마음에 돈․여자로 의심    질투가 가득하네!  
     권리도 상실되겠고 !
  女:남편의 재해가 걱정되네 !
  ⊕도로귀 
  ⊖가택귀 잡는게 급선무야!
  ⊕신변음사부정이 침범했네?
  `,`■ 가정에 든 
  ⊕⊖가택 두성․불안․부정만      해소 시키면 만사형통      하겠어! 
      
  그런데 슬피 울 일은있게
  생겼네?
  `,`■ 가정에 上下가 없네?
     육친빙탄으로 어른이      욕을 당하고 있겠어!
  이게 모두 
  ⊕여귀 
  ⊖전흉사귀 침범 때문에 
    그렇네!
  
  ⊕신변음사부정이 침범했네?
  `,`■ 색정사로 배신당하는 
     운세인데… 
     만사가 동요불안하고 
     일도 양 갈래야!
  남:권리를 잃네 !
  여:남자가 떠나게  생겼어!
     아니면 재해를 당하든가!
  ⊕여귀 
  ⊖전흉사귀ㆍ가택상가부정    때문이야 ! 
  `,`■남:권리를 잃겠네 !
    여:남편(남자)의 재해가        걱정이네 !    
   기도․치성드리면 좋겠어!
  ⊕음귀 
  ⊖신불제사귀가 문제야!
  
  ⊕⊖신변에 가당신사부정이      침범 했네 ?
  `,`■ 도난사가 있네! 그래도
     좋은 일도 많겠고 금전도     풍성하게 들어 올거고…     돌아가세요!
  
  ⊕단, 가택에 상가부정이 
    들었어!
  `,`■1女2男 삼각관계가 있네?    감추느라마음이꽤나놀래고    있겠는데!
  ⊕여귀 
  ⊖전흉사귀를 달래주면 
    화살을 피할 수 있겠어!
  
  ⊖신변음사부정이 침범했네?
  `,`■ 마음이 떴네?
  女:⊕남자를걷어부치고유혹      했네? 
  男:돈과 여자는 빨리 취득하지 않으면 실패 해!
  
  ⊕도로귀ㆍ상가부정, 
  ⊖가택귀ㆍ가택상가부정을    잡아야만 해!
  `,`■호랑이 꼬리를 밟고 있는    격이야!
    문 앞에는 범이 노리고     문 뒤에는 이리가 버틴     운세야! 
    어디 멀리 가지말고 가능한    내부에서 근신해야 돼!
  ⊖신변음사부정이 침범했네?`];
  const YOGYEOL1 = [`■ 계속 형벌하니 못되면 남의 탓이고 말전에서 초점을 도우니 잘되면 내공을 내세우는 자를 만난다.
  `,`■ 물러서면 낭떠러지 이니 뒤로 물러서지 말라. 나쁜 것은 즐겁게 버리고 앞으로 나아가면 성공이다.
  `,`■ 사람이나 집이나 모두 위에서 극한다. 일반인은 인택에 재앙이 생기나 공직자는 크게 얻는 것이 있으리라.
  `,`■ 고위자가 하위자에게 의탁하나 피차 나쁜 것을 감추지 못한다. 두 귀인이 성내고만 있으나 가히 나쁜 일 만은 벗어나리라.
  `,`■ 머리에 꼬리가 붙고 고리에 머리가 붙었다.
  서로 교섭하고 은밀하게 도모하면 에 득재 하리라.
  `,`■ 화합을 했으나 무례하게 행동하니 괴롭겠다,
  위태로운 일을 만나더라도 모두 두려울 것이 없다.
  `,`■ 범이 귀신을 몰고 오나 두려울 것은 없다. 그러나 욕심은 내지 말라. 만약 돈이나 여자를 탐하게 되면 관재나 재앙이 함께 오리라.
  `,`■ 시작도 흉하고 계속 빈 것만 연결 되었다. 이같이 논하건대 밤정단은 전혀 길흉이 없겠다.
  `,`■ 비록 깨지는 것을 만났어도 길에 돈만은 가득하다. 나와 네가 서로 손해가 있겠으니 각자 어리석기 때문이다.
  `,`■ 화살을 맞았으나 쑥대다 맞아도 다치지 않겠다. 나와 네가 서로 단합하나 귀인은 돕지 않으니 믿지 말라.
  `,`■ 범이 재물을 물고 도망갔으니 내손에는 안들어 온다. 쓸데 없는 신경만 쓰고 타인의 웃음까지 깨뜨리겠다.
  `,`■ 도적이 집에 붙으니 종업원이나 아랫사람이 도망한다. 그물을 친다 해도 두 손이 묶여 잡지 못한다.`]
  const SIBO1 = [`■ 정중동의의 조가 있고 
  자만심이 앞서 대립
  반목의 소송사가 있다.
  신규사가 있으나 완성되지 못한다. 일이 양 갈래로 벌어졌다. 마음도 二心이다. 교관․교섭사는 吉하고 고민중 길행이 있다. 망동치 말고 멈춰야 좋다.
  男:권리상실 함.
  女:남편 凶이 기함.
  `,`■ 가택이나 생업의 변동
  ․이동을 꾀하나 여의치
  않다. 계속 전진해야만
  吉하다. 양보와 후퇴는 불리하며 옛것을 지키는 것은 불리하다. 별리․고독하다. 관공직사는 불리하고 일반인은 좋다. 방위를 피하여 직녹을 증식하라.
  `,`■ 인택이 모두 불녕하고 
  파란기복이 있다. 음사․
  주색․혼미의 사건이 있다.
  별리․고독하다. 나 不正은 凶을 초래한다. 
  단, 공사는 나쁘고 사사롭게 음모하는 것은 이롭게 된다. 화재로 인한 재해․공포의 사건이 기한다.
  男:권리상실 함.
  女:남편 凶이 기함.
  `,`■ 가택의 권리가 넘어간다.
  자충수적 우환이 있으나
  경미하다. 남의 천거를 
  받아도 진실이 없다. 1남2녀의 삼각관계가 있고 남자가 떠나며 고독하다. 일도 조건․여건이 부족하다.
  `,`■ 저주사가 있다. 가정에
  부정이 들었다. 구재에 대
  득하고 시작과 끝이 확실
  하다. 모사 및 다른건도 성취된다. 
  단, 분수를 지켜야 한다. 임신사로 부인이 슬피 울 일이 발생한다. 또 어른에게 재난이 기한다.
  `,`■ 임신사가 있고 小兒의
  안전을 염려한다. 上下
  가 불화하여 빙탄의 조
  이다. 여러 사람으로부터 무시 당한다. 소송사로 죄를 받을 우려가 있으나 경미하다.
  男:권리상실 함.
  女:남편 凶이 기함.
  `,`■ 만사 동요․불안한 운세
  이다. 반복 무상한 형편
  이다. 구재사나 여성 문제
  는 좋다. 이동할 뜻이 있으나 움직일 수 없다. 부부 이심․별리가 있고 남자가 떠나고 고독인 이다.
  `,`■ 남자는 직위의 위태함이
  있고 여자는 남자가 떠나
  며 고독인 이다. 처음
  은 吉하고 나중은 불길하므로 무슨 일이나 속행해야 좋다. 돈 문제는 吉하다.
  `,`■ 가정에 임신사가 있다.
  모든 일에서 여러 사람의
  모사에 의해 활동하고
  타인에게 의탁하여 일을 성취한다. 인택에 소모사가 많다. 그러나 흉화위길하게 된다.
  단, 북방의 청년이 도적질하는 것을 예방하라.
  `,`■ 1녀2남의 삼각관계가 있
  고 이로 인해 마음이 불
  안하고 두렵다. 근신하고
  입조심하면 흉을 면한다.  타인이 스스로 와서 손해를 끼친다. 피차 의견이 맞지 않아 남자가 떠나고 별리․고독하다. 길흉화복이 경미하다.
  `,`■ 가정에 부정이 들어 財
  를 얻어도 반감 된다.
  심신이 불안하여 안정
  을 취할 수 없다. 관공직자는 좋으나 공망으로 경하고 凶이 바뀌어 길사를 맞는다.
  ⊕여자가 남자를 유혹하여 색정거래한다.
  正月 임신사가 있다.
  男:권리상실 함.
  女:남편 凶이 기함.
  `,`■ 매우 두려운 국수이다.
  생사 출입의 액난을 예방
  해야 한다. 외출이나 장
  거리를 가지 않고 집에서 근신하면 혈광사를 면하나 출행하면 죽거나 큰 재액을 당한다. 亥日까지 근신하라!`]
  const SIBO2_1 = [`■직･녹, 신변 외 소송사이다
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,`■官･夫, 변동사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  직위·권리상실하고 남편은 망조한다.
  `,`■ 형제손모,가정 별리사이다.
  내방조짐 상의 : 
  어떤 일이든 처음 불성한다.
  人宅쇠퇴의 상이다.
  `,`■官･夫, 가택 동요사이다.
  내방조짐 상의 :  
  원행·이동사가 있다.어떤 일이든 처음 불성한다.직위·권리상실하고 남편은 망조한다.
  `,`■재･여,가정내사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다. 부모 재액 예방이 필요하다
  `,`■재･여, 가정내사이다.
  내방조짐 상의 :  
  움직임이 근본이고 동기가 발동한다.
  육친 상하 불화한다.
  `,`■官･夫,별라시이다.
  내방조짐 상의 : 
  원행·이동사가 있다.어떤 일이든 처음 불성한다.직위·권리상실하고 남편은 망조한다.
  `,`■官･夫, 별라시이다.
  내방조짐 상의 :  
  원행·이동사가 있다.어떤 일이든 처음 불성한다.직위·권리상실하고 남편은 망조한다.
  `,`■손모가정·해산건·내사이다.
  내방조짐 상의 : 
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  부모재액 예방이 필요
  `,`■官･夫,가택 부부이심사다.
  내방조짐 상의 :  
  원행·이동사가 있다.어떤 일이든 처음 불성한다.직위·권리상실하고 남편은 망조한다.
  `,`■재･여, 가정내사이다.
  내방조짐 상의 : 
  움직임이 근본이고 동기가 발동한다.
  순조변화 한다
  `,`■夫･재,가정·해산·손해사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/045/row-1-column-1.png`,
    `/static/images/sagwa/045/row-1-column-2.png`,
    `/static/images/sagwa/045/row-1-column-3.png`,
    `/static/images/sagwa/045/row-1-column-4.png`,
    `/static/images/sagwa/045/row-1-column-5.png`,
    `/static/images/sagwa/045/row-1-column-6.png`,
    `/static/images/sagwa/045/row-1-column-7.png`,
    `/static/images/sagwa/045/row-1-column-8.png`,
    `/static/images/sagwa/045/row-1-column-9.png`,
    `/static/images/sagwa/045/row-1-column-10.png`,
    `/static/images/sagwa/045/row-1-column-11.png`,
    `/static/images/sagwa/045/row-1-column-12.png`,
  ]

  const HEASYEOL = [
    // 1
    `  이 과는 4과 중에 극이 없고 양일복음(陽日伏吟)이므로 일간상신을 사용하여 초전으로 하니 3전4맹(三傳四孟)은 원태격(元胎格)이다. 말전 인(寅)은 공망하여 원태(元胎)가 자라지 않는다. 초전은 덕록으로 되고 중전의 신(申)은 수토장생(水土長生)하며, 말전의 역마는 공망을 아까워 한다. 
    일간상신은 덕록에서 생을 받아 우흉(憂凶)이 없고 사람이 와서 원조를 받는다. 춘월에는 더욱 길하다. 일간일지는 교차지합으로 되어 화합한다.
    백법에 ｢교관(交關) 교섭에서 모두 길한 것은 교차지합되는 까닭이다｣라고 하였다. 지형(支刑)에 있어서 상하가 합하는 것은 두 마음이 있다고 본다. 3소(所) 백호는 흉조이지만 장생에 승할 때는 불행중에 길행이 있다. 양일복음(陽日伏吟)은 자임격(自任格)으로 되며, 말전이 공망하므로 움직임을 멈추고 부동의 형상으로 되니 도리어 안전하다.
    일귀는 순행하고 3전은 구(勾)․백(白)․사(蛇)이며, 야귀는 역행하여 3전이 주(朱)․후(后)․청(靑)이다.`
    ,
    // 2
    `  이 과는 4과 중 제2과의 묘진(卯辰)이 상에서 하를 극하여 원수퇴여격(元首退茹格)이다. 3전 모두 인묘(寅卯)의 공망이므로 개공격(皆空格)이다.
    차순(次旬)의 공망은 자축(子丑)공망으로 된다. 말전의 축(丑)은 답각공망(踏脚空亡)이라고 부른다. 일간일지 상신에 묘신(墓神)이 덮고 개공(皆空)은 길흉이 모두 이루어지지 않는다. 퇴여공망(退茹空亡)은 전진하면 길하다.
    답각공망은 백법의 제18법에 해당하는데 그 해설을 인용한다. ｢이 과는 3전이 퇴여격에 들며 3전이 완전히 공망을 만난다. 따라서 이런 이름이 붙었다. 후퇴하려고 하나 공망을 만나므로 전진하는 것이 좋고 물러서면 불길하다. 
    이 과는 인묘(寅卯)의 3전이 일간 무토(戊土)를 극(剋)하여 귀살로 되고, 귀살 공망은 재해를 이루지 않아 재해를 피할 수 있다. 옛것을 지키는 것은 좋지 않다. 관공 사건은 관귀가 공망하여 불길하지만 보통 사람은 적극적인 방침을 취해야 한다｣고 하였다. 심사격(尋死格) 및 답각진공격(踏脚進空格)은 같은 항목을 참조하기 바란다.
    일귀는 순행하며 3전은 주(朱)․사(蛇)․귀(貴)이며, 야귀는 역행하여 구(勾)․청(靑)․공(空)이다.
    비전에는 ｢일간상신에 묘신(墓神)이 덮이는 것은 3전 인묘축(寅卯丑) 이외의 방위에서 이록(移祿)을 구하는 것이 좋다｣고 하였다.`
    ,
    // 3
    `  이 과는 4과 중 3과에 상하의 극이 있다. 하에서 상을 적하는 축묘(丑卯)를 사용하여 초전으로 하고 중심과로 된다. 3전이 음의 경지니 극음이라 부른다. 
    일간일지상신에서 함께 극되어 인택이 모두 안녕하지 못하다. 상하의 극이 많으면 모두 파란기복이 있다. 극음격(極陰格)은 음사나 주색 혼미의 사건이 있다. 그 형상은 사(邪)하고 부정(不正)은 흉하다. 그러나 공사(公事)에서는 흉하고 사정(私情)은 수행할 수 있다. 즉 음모하는 일은 이루어진다. 
    일지의 택신이 신금(申金)에 오화(午火)가 승하고 만일 사신(蛇神)이 앉는다면 불의 재해 또는 공포의 사건이 일어난다.
    백법에 ｢간지가 함께 상극(傷剋)되니 피차간에 서로 손상이 있다｣고 하였다. 묘(卯)의 관귀가 공망하므로 재해 흉화는 면한다. 
    일귀는 순행하며 3전이 귀(貴)․음(陰)․상(傷)이고, 야귀는 역행하여 3전이 공(空)․상(傷)․음(陰)이다.`
    ,
    // 4
    `  이 과에서는 4과 중 제1과․제3과가 상에서 하를 극하며, 양일이니 양비의 극이다. 인사(寅巳)를 사용하여 초전으로 하고 3전이 4맹신(四孟神)인 것은 비용원태과(比用元胎課)로 된다. 4과이나 3과뿐이니 불비격(不備格)이다.
    일간기궁은 일지상신에 가고 일지 신(申)과 합하여 극(剋)으로 된다. 인(寅)은 과전공망으로 일간을 극한다. 공망되는 것은 흉을 보아도 흉의 힘이 없으니 해소된다. 3전체생은 남의 천거를 받으나 공망하므로 진실한 정이 아니다.
    백법에 ｢권섭부정(權攝不正)은 일간의 건록이 일지상에 임한다｣고 하였다. 내가 상대방에게 이익을 주는 형상이다. 일간일지상신에서 함께 극되는 것은 인택이 모두 손해를 본다.
    일귀는 순행하며 3전은 사(蛇)․음(陰)․백(白)이고, 야귀는 역행하여 3전이 청(靑)․상(常)․후(后)이다. 4과 중에 1과가 결여되어 불비격이니 불비․결함이 있어서 사물이 순통하지 못하다.
    불비격에 대한 해설은 과거에도 논하였으나 이제 다시 해설한다. 4과는 2양과 2음이 완전하여 4과를 형성한다. 4과 중에서 1과는 다른 과와 같아서 3과뿐으로, 음신(陰神)이 결여되면 음의 불비격, 양신(陽神)이 결여되면 양의 불비격이라 한다. 2양1음은 2남이 1녀를 다투는 형상이며, 2음1양은 2녀가 1남을 다투는 형상이다. 그러므로 색정․삼각 관계의 징조가 있다. 또 별책과(別責課)는 극적(剋賊)이 없는 불비과이다.`
    ,
    // 5
    `  이 과는 4과 중의 제4과에서 1하가 1상을 극하여 중심(重審)으로 된다. 3전이 자신진(子申辰)의 3합수국(三合水局)이므로 윤하격(潤下格)이다. 일간에서 3전은 3합재국(三合財局)이니 구재는 많이 얻는다. 
    일간일지상신에 순미(旬尾)와 순수(旬首)의 축(丑)과 진(辰)이 승하면 수미(首尾)가 서로 상합(相合)하여 모든 일의 시작과 끝이 확실하다. 모사 및 기타의 사건은 성취된다. 윤하격(潤下格)은 물이 흐르는 것과 같이 만사가 순조롭게 진행되며, 과전에 3합․지합이 함께 있으면 3ㆍ6상합(三六相合)으로 되어 기쁜 일이 거듭되고, 바라는 바는 달성되지 않는 것이 없다. 그러나 재개 태왕은 도리어 실재할 우려가 있다. 분수를 알고 구재해야 한다.
    백법에 ｢수미상견(首尾相見)하는 것은 시종(始終相宜)하고 일간일지상신에 묘(卯)신이 승하는 것은 조체를 예방해야 한다｣고 하였다.
    비전에는 ｢자(子)에 사신(蛇神)이 승하니 부인이 슬피우는 일이 일어난다｣고 하였다. 재기가 왕성하면 인수가 부모를 몰래 극하므로 사기(死氣)가 있고, 또 사신(死神)이 승할 때는 존친의 병을 정단하면 사망한다. 
    일귀는 순행하며 3전은 사(蛇)․청(靑)․현(玄)이고, 야귀는 순행하여 3전이 청(靑)․사(蛇)․현(玄)이다. 초전의 자가 일간상신 축(丑)과 지합하므로 만사가 이루어진다.`
    ,
    // 6
    `  이 과는 4과에 모두 상하의 극이 있다. 그러므로 섭해과로 되고 지반4맹(地盤四孟)의 하에서 상을 극한다. 제1과를 취하여 초전으로 하여 섭해도액과(涉害度厄課)이다. 자(子)와 미(未)는 육해(六害), 자(子)와 묘(卯)는 지형(支刑), 묘(卯)와 술(戌)은 지합(支合), 미(未)와 술(戌)은 지형으로 되어 만사가 복잡 다단하다. 
    도액격(度厄格)은 상하가 불화하여 쟁투한다. 초전과 말전에 천을귀신(초전 子巳, 말전 寅未)을 모시므로 귀인의 비호를 받아 재해를 해제한다. 말전의 관귀는 공망하고 제3과․제4과도 또한 공망한다.
    백법에 ｢지형이 있으면 주객이 투합되지 못하고 3전이 체극되면 남에게 무시당한다｣고 하였다. 술(戌)에서 자(子)는 12운태(運胎)이다. 인월(寅月)에 정단하면 처가 임신한다. 또 처의 년명상신에 태신이 임할 경우에도 적절하다. 역마와 백호가 동병(同倂)하고 귀살로 되면 소송사에서 죄과를 받는다. 다행히 공망하므로 형죄(刑罪)도 경하다.
    일귀는 역행하며 3전이 사(蛇)․공(空)․후(后)이고, 야귀는 순행하여 3전이 청(靑)․귀(貴)․백(白)이다. 이 과에서는 자(子)가 사(巳)에 가해져서 사(死)자로 된다. 그러므로 병단(病斷)은 흉하고 길조가 없다. 태상이 묘(卯)에 승하는 불행이 있지만 공망하니 흉은 경하다.`
    ,
    // 7
    `  이 과는 천지12신(天地十二神)이 대충 위에 있으므로 반음과(返吟課)로 된다. 상하가 모두 상극하며 양일이니 하에서 상을 적(賊)하는 인신(寅申)을 초전으로 한다. 3전 4맹신(四孟神)은 원태격이며, 과전이 모두 공망한다. 
    일간상신 해(亥)는 재동(財動)으로 되고 인(寅)과 지합하니 구재 및 여성 문제는 좋은 형세이다. 초전 백호는 관귀이나 공망으로 되어 흉을 이루지 않는다. 반음과는 반복되는 것이지만 전공(全空)이니 길도 흉도 이루지 않으며, 일간일지의 각 상신은 서로 지합한다.
    백법에 ｢상하 상합되는 것은 피차간에 서로의 마음이 같다. 거래가 공망하니 이동할 징조가 있으나 움직일 수 없다｣고 하였다.
    비전에는 ｢무일(戊日)의 반음과로서 진월은 생기에서 일간을 극하므로 다행 중에 불행이 있다. 사기(死氣)가 일간을 생하는 것은 불행 중에 다행이며, 처의 병을 정단하면 반드시 죽는다. 만일 월내(月內)의 사기(死氣)가 간지상신에 임하면 처의 죽음은 빠르다｣고 하였다. 초전이 공망되면 일이 성취되지 않으니 그 순(旬)을 지나서 작전 계획을 강구해야 한다. 
    일귀는 역행하여 3전이 후(后)․청(靑)․후(后)이고, 야귀는 순행하여 3전이 백(白)․사(死)․백(白)이다.`
    ,
    // 8
    `  이 과는 4과 중 제2과에서 1상이 1하를 극하니 원수과(元首課)로 된다. 일간일지상신이 술축(戌丑)의 묘신(墓神)에 앉는다. 초․중전은 묘(卯)의 공망으로 되고 말전이 홀로 실이다. 초전이 관귀이나 공망하여 흉을 이루지 않는다. 말전에 천공이 승하면 3전 개공과 동일한 작용을 한다. 
    원수과는 처음에는 길하고 후에 불길하므로 무슨 일이나 속성하면 길하다.
    백법에 ｢일간일지상신에 묘(墓)가 덮이면 인택이 모두 혼미하다｣고 하였다. 
    비전에는 ｢태음이 묘(卯)에 승하여 공망되는 것은 이름을 이룩할 수 없다｣고 하였다. 일지상신이 둔간하여 계(癸)의 암재(暗財)로 되므로 구재는 유망하다. 
    일귀는 순행하며 3전은 음(陰)․청(靑)․귀(貴)이고, 야귀는 순행하여 3전이 상(常)․사(蛇)․공(空)이다.`
    ,
    // 9
    `  이 과는 4과 중 제4과가 진자(辰子)로 1상이 1하를 극하여 초전으로 되며 원수윤하과(元首潤下課)이다. 초전에 순수(旬首)의 진(辰)이 있어서 육의격(六儀格)이며, 3전이 3삼합수국의 재효로 된다.
    윤하격은 모든 일에서 많은 사람들의 모사에 의하여 움직이고 혹은 남에게 기탁하여 사물을 성취한다. 3전이 체생(遞生)하고 육의발용(六儀發用)으로 되니 웃사람이나 타인의 원조를 받는다. 일간일지가 모두 천반에 설기(泄氣)로 되니 인택에 경비의 소모가 많다. 귀신이 묘유(卯酉)에 임하는 것은 여덕격(勵德格)이다. 
    육의(六儀)란 6양(六陽)의 지신(支神)이며, 순수(旬首)는 성진(星辰)의 장(長)이다. 예의를 존중하므로 육의라 부른다. 모든 일에 경사가 있으며 집안에 희기(喜氣)가 차는 격이다. 육의는 원조(援助)의 신으로서 병단하면 양의(良醫)를 얻고, 가정은 태평하다. 괴강(魁罡)을 용(用)으로 하는 것은 흉을 변하여 길하게 한다.
    3전은 3합수국으로 되고 간지 중에 자축(子丑), 사신(巳申)의 지합이 있다. 정단하는 일은 하등의 정체도 없으며 목적이 이룩되고 만사가 유구(悠久)한 형상이다. 그러나 종국에는 조용할 수가 없다.
    백법에 ｢간(干)의 묘신(墓神)이 합병되는 것은 인택에 회기(晦氣)를 초래한다｣고 하였다. 
    비전에는 ｢현무가 초전으로 되고 상기(相氣)가 승하여 말전의 자(子)는 택상으로 돌아간다｣고 하였다. 북방의 젊은이가 와서 도적질한다.
    일귀는 역행하여 3전은 현(玄)․청(靑)․사(蛇)이고, 야귀는 순행하여 3전이 현(玄)․사(蛇)․청(靑)이다. 여덕격은 군자가 때를 기다리는 격이다.`
    ,
    // 10
    `  이 과는 상하의 극이 없고 요극(遙剋)이 있다. 천신에서 극하는 인(寅)을 초전으로 하며 호시원태불비과(蒿矢元胎不備課)이다.
    초전 인목(寅木)은 공망하고, 호시(蒿矢)의 시(矢)는 썩은 나무로서 힘이 없으니 흉해를 이루지 못한다. 특히 일간상의 신금(申金)은 귀살을 제어한다. 요극과는 길흉이 모두 경하다.
    불비격에 육해충극(六害冲剋)은 불화․동요․의심 등이 있다. 일지가 일간상에 임하고 일간에서 탈기로 되면 타인이 와서 내게 탈설이 모손을 초래한다.
    백법에 ｢피차간에 의지가 투합되지 않는 것은 지형상(支刑上)에 있기 때문이다｣라고 하였다.
    일귀는 역행하며 3전은 후(后)․상(常)․청(靑)이고, 야귀는 순포하여 3전이 백(白)․음(陰)․사(蛇)이다. 그러나 호두사미(虎頭蛇尾)로서 결과가 좋지 않다. 다만 길흉화복이 모두 크지 않다.`
    ,
    // 11
    `  이 과는 4과 중 제4과에서 1하가 1상을 적(賊)하여 중심과가 된다. 일간에서 초전이 재효로 되고 지반이 묘(墓)에 앉는다. 즉 비견으로 형제․친족과 구재에서 경쟁하는 형상이므로 후에 재를 얻어도 재의 목적은 반감된다. 해(亥)에 천을 귀신이 임하면 귀신이 천문(天門)에 오르고, 지반의 4맹상신(四孟上神)에 진술축미(辰戌丑未)가 승하면 신장살몰(神將殺沒)로 되어 흉을 해제하고 길을 돕는다.
    중전은 최관사자(催官使者)로서 관공직에 있는 사람에게는 양호하지만, 공망되므로 공망을 벗어나면 길하다. 또한 년명(年命) 인(寅)의 전실공망(塡實空亡)도 관직에는 길하다.
     백법에 후(后)․합(合)이 있으면 결혼을 정단하면 자유 결혼이며, 남녀 부정의 징조이다. 초전이 파해(破害)되는 것은 심신이 돌아갈 곳을 얻지 못한다｣고 하였다. 
     비전에는 ｢초전 재효(財爻)는 중전 귀효(鬼爻)에 들지만 다행히 공망하므로 구재해도 재해가 없다｣고 하였다. 빠를 때는 재를 소득한다. 
     일귀는 순행하며 3전이 후(后)․사(蛇)․합(合)이고, 야귀는 역행하여 3전이 백(白)․청(靑)․합(合)이다. 3전 자(子)의 밤중과 인(寅)의 새벽에서 진(辰)이 말전이면 3양에 향하여 어둠에서 밝음을 나타낸다. 그러나 공망되므로 힘이 약하다.`
    ,
    // 12
    `  이 과는 4과 중에 상하의 극이나 요극이 없으므로 묘성과(昴星課)이며, 양일이니 유(酉)의 상신을 사용하여 초전으로 한다. 육임(六壬)의 과식은 극을 사용하는데, 극은 움직이는 것이며 길흉은 움직이는 것에 따라 계기를 이룬다. 그러므로 대개는 극적(剋賊)을 사용하지만 과 중에 극이 없는 것은 유(酉)를 목표로 하여 초전을 정한다. 유(酉) 중에는 묘숙(昴宿)이 있고, 유(酉)｣는 서방(西方) 백호 금위(金位)로서 형살(刑殺)을 지배한다. 즉 생사출입(生死出入)의 문이므로 유(酉)에 따라서 초전을 구성하기 때문에 이렇게 이름붙인 것이다.
    양일에는 지반 유(酉)의 상신을 채용하고, 음일이면 천반 유(酉)의 상신을 채용하며, 음일이면 천반 유(酉)의 하신을 초전으로 하므로 일명 앙복격(仰伏格)이라고 부른다. 
    중전은 일지상신, 말전은 일간상신으로써 이루니 전봉격(轉逢格)이라 부른다. 전봉이란 움직이는 형상이다. 이 과는 놀랍고 두려운 일이 있고, 만사가 폐색되고 삽체된다. 여행․외출을 금지하는데, 나가면 돌아올 수 없거나 화환(禍患)이 발생한다. 따라서 집에 안정하고 있으면 길하고 흉을 만나지 않는다.
    일간의 왕상을 즐기므로 왕할 때는 흉으로 논하지 말아야 한다. 술(戌)과 유(酉)는 사용(使用)의 류신(類神)으로서, 술유(戌酉)가 상하에 가림하고 술(戌)에 무(武)․현(玄)이 승하면 사용인이 부정을 저지르고 도주한다. 혹은 남녀 사용인이 공모하여 간사(奸邪)를 저지른다.
    백법에 ｢지반의 전지(前支)로서 4과를 이루는 것은 나망격(羅網格)인데 모사는 졸책(拙策)을 이룬다｣고 하였다.
    비전에는 ｢오(午)가 유(酉)를 보거나 유(酉)가 오(午)를 보면 사승살(四勝殺)로서 제각기 재주를 뽐낸다｣고 하였다. 사승살(四勝殺)은 백법 중에도 해설되어 있다. 교섭․타협사는 좋지 않다. 
    일귀는 순행하며 3전이 현(玄)․상(常)․청(靑)이고, 야귀는 역행하여 3전이 현(玄)․음(陰)․사(蛇)이다.`
    ,
  ]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
