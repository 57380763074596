
const columnA = [
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`01`,`좌`],
    [`23:32`,`04 `],
    [`01:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`02`,`좌`],
    [`01:32`,`09`],
    [`03:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`03`,`좌`],
    [`03:32`,`06`],
    [`05:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`04`,`좌`],
    [`05:32`,`11`],
    [`07:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`05`,`좌`],
    [`07:32`,`08`],
    [`09:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`06`,`좌`],
    [`09:32`,`01`],
    [`11:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`07`,`좌`],
    [`11:32`,`10`],
    [`13:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`08`,`좌`],
    [`13:32`,`03`],
    [`15:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`09`,`좌`],
    [`15:32`,`12`],
    [`17:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`10`,`좌`],
    [`17:32`,`05`],
    [`19:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`11`,`좌`],
    [`19:32`,`02`],
    [`21:31`,`내용 해당`],
    [``,``],
    [``,``],
    [`12`,`좌`],
    [`21:32`,`07`],
    [`23:31`,`내용 해당`],
    [``,``]
  ]
const columnD = [
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [``,``,``,``,``,``,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`궤후`,`천부적좌고우면신중성자`,`설득`,8,5,`있음`,`01.21\n~
  02.19`,`보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,`조용\n담담
  
  성교후곧잠`],
    [`4.5월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`발전적`,`3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`성세자`,`성盛`,`왕성적`,`현재`,`지혜`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`고립적`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 4\n7 10`,`아둔`,`천부적중정신앙심자`,`신뢰`,9,4,`있음`,`02.20\n~
  03.20`,`굵음\n적극적 매너
  
  강력한 성교 함`,`문을 열면 적극적이 됨\n
  감격을 숨기지않음`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적진취창의성자`,`고집`,10,3,`있음`,`03.21\n~
  04.20`,`단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,`주도적\n적극적
  
  완벽
  주의자 임`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`성세자`,`성盛`,`왕성적`,`현재`,`인자`,`천부적좌고우면신중성자`,`설득`,11,2,`있음`,`04.21\n~
  05.20`,`때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,`정숙\n하고 
  억제심 강함
  
  분비물 많음`],
    [`4.5월`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`집요`,``,``,0,0,``,``,``,``],
    [`7.8월`,`단절자`,`단斷`,`고립적`,`6 후`,`집요`,``,``,0,0,``,``,``,``],
    [`10.11`,`진세자`,`진進`,`발전적`,`3 후`,`인자`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`소외자`,`소疏`,`쇠락적 `,`1 3\n7 10`,`집요`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,12,1,`있음`,`05.21\n~
  06.20`,`기교파 반응 감상 타입\n
  크고, 즐김`,`위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴褪`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적진취창의성자`,`고집`,1,12,`있음`,`06.21\n~
  07.20`,`기교가 세밀함`,`분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴退`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`진세자`,`진進`,`발전적`,`3 후`,`예의`,`천부적좌고우면신중성자`,`고집`,2,11,`있음`,`07.21\n~
  08.20`,`정력파 女만족시키려 함\n
  쉽게 지침`,`적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`],
    [`4.5월`,`성세자`,`성盛`,`왕성적`,`현재`,`예의`,``,``,0,0,``,``,``,``],
    [`7.8월`,`소외자`,`소疏`,`쇠락적 `,`10후`,`산만`,``,``,0,0,``,``,``,``],
    [`10.11`,`단절자`,`단斷`,`고립적`,`6 후`,`산만`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`퇴세자`,`퇴褪`,`퇴휴적`,`14\n710`,`산만`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`고립적`,`2 14\n7 10`,`아둔`,`천부적중정신앙심자`,`설득`,3,10,`없음`,`08.21\n~
  09.20`,`헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,`부끄러움 많이 탐\n
  성기는 명물임`],
    [`4.5월`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`퇴휴적`,`2 5\n8 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`쇠락적 `,`1 5\n8 9`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`왕성적`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적진취창의성자`,`고집`,4,9,`없음`,`09.21\n~
  10.20`,`조루증 많음\n
  음경은 작은 편`,`음모 많음\n
  수축 형이고 능란함`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`소외자`,`소疏`,`쇠락적 `,`6 후`,`포살`,`천부적좌고우면신중성자`,`설득`,5,8,`없음`,`10.21\n~
  11.20`,`보통이상 크기\n
  극적. 본능에 그침`,`은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`],
    [`4.5월`,`단절자`,`단斷`,`고립적`,`3 후`,`포살`,``,``,0,0,``,``,``,``],
    [`7.8월`,`성세자`,`성盛`,`왕성적`,`현재`,`명철`,``,``,0,0,``,``,``,``],
    [`10.11`,`퇴세자`,`퇴退`,`퇴휴적`,`9 후`,`포살`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`진세자`,`진進`,`발전적`,`1 4\n7 10`,`명철`,``,``,0,0,``,``,``,``],
    [`1.2월`,`단절자`,`단斷`,`종교재난`,`1 2\n7 10`,`아둔`,`천부적중정신앙심자`,`고집`,6,7,`없음`,`11.21\n~
  12.20`,`통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,`적극적 \n상위 취함`],
    [`4.5월`,`진세자`,`진進`,`종교발전`,`2 4\n5 7`,`후덕`,``,``,0,0,``,``,``,``],
    [`7.8월`,`퇴세자`,`퇴退`,`종교탄핵`,`1 2\n4 5`,`아둔`,``,``,0,0,``,``,``,``],
    [`10.11`,`소외자`,`소疏`,`종교한산`,`2 4\n5 11`,`아둔`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`성세자`,`성盛`,`종교대성`,`현재`,`후덕`,``,``,0,0,``,``,``,``],
    [`1.2월`,`퇴세자`,`퇴退`,`활인탄핵`,`10후\n11후`,`궤후`,`천부적진취창의성자`,`고집`,7,6,`특별있음`,`12.21\n~
  01.20`,`보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,`적극적 \n상위 취함`],
    [`4.5월`,`소외자`,`소疏`,`활인한산`,`5 6\n7`,`궤후`,``,``,0,0,``,``,``,``],
    [`7.8월`,`진세자`,`진進`,`활인발전`,`2 후\n3 후`,`지혜`,``,``,0,0,``,``,``,``],
    [`10.11`,`왕세자`,`성盛`,`활인대성`,`현재`,`지헤`,``,``,0,0,``,``,``,``],
    [`3.6\n9.12`,`단절자`,`단斷`,`활인재난`,`1 4\n7 10`,`궤후`,``,``,0,0,``,``,``,``]
  ]
const columnS = [
    [``,``,``,``,``,``],
    [`관련`,`속사정`,``,``,`外業신변男上`,`內事가정女下`],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [``,``,``,``,``,``],
    [`관사`,`관사`,`小`,``,``,``],
    [`수난`,`수난(受難) 관련사`,`小`,`6`,``,``],
    [`남편`,`남편관련사`,`大`,`존재기`,`중중희애`,`음사폐호`],
    [`부부불화(夫婦不和)`,`부부불화사`,`大`,``,``,`가택안녕`],
    [`난중달성(難中達成)`,`어려움으로 마음을 앓으나 결국 달성하게 되는 상`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남자 갈등사`,`大`,`5`,``,``],
    [`송쟁`,`내부가정 반목대립으로 쟁송사가 발생한 상`,`小`,`배양기`,`개신예정`,`대립송쟁`],
    [`주력사폐(主力事廢)`,`결국 주력사가 폐하게 되는 상 `,`小`,``,`부부불화`,`도적失勢`],
    [`구설우심(口舌憂心)`,`考試는 불리하고 없는 구설을 걱정하는 상`,`大`,``,``,``],
    [`후원`,`후원 관련사`,`大`,``,``,``],
    [`문서`,`송쟁급속사`,`小`,`4`,`환성경사`,`미초희경`],
    [`부친`,`부친 관련사`,`小`,`개시기`,`급속송쟁`,`미완부족`],
    [`흉제진취(凶制進取)`,`여러 난관을 극복하고 이익을 취할 수 있게 되는 상`,`大`,``,`상해우려`,``],
    [`자상훼괴(自相毁壞)`,`자기 면모를 스스로 훼손하는 상`,`小`,``,``,``],
    [`후원`,`후원 관련사`,`大`,``,``,``],
    [`결속`,`결속 개혁으로 대재를 획득하게 되나 부모災害 발생함`,`小`,`3`,``,``],
    [`색정`,`내女 색정사 有-이혼 우려`,`小`,`학습기`,`능욕박해`,`색정사有`],
    [`잉태사안(孕胎事案)`,`잉태 희사의 상-종업원 병 또는 잉태사일 수 있음`,`大`,``,``,``],
    [`간사은닉(奸私隱匿)`,`관계성불통-가택이사-私室출입 간사은닉(숨긴 첩-정인)`,`小`,``,``,``],
    [`박관`,`직업직위 불안사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`2`,``,``],
    [`자식`,`자식 관련사`,`小`,`면모기`,`부부반목`,`미초희경`],
    [`장상가외(長上可畏)`,`집안 어른 수명(壽命)위기의 상 `,`小`,``,``,`(권리부족`],
    [`부부반목(夫婦反目)`,`부부 반목의 상`,`大`,``,``,`희사실소)`],
    [`명예`,`수상(受賞)사`,`大`,``,``,``],
    [`자산`,`직녹권리 관련사`,`大`,`1`,`명예권리`,``],
    [`막힘`,`외사 외교막힘사`,`小`,`절정기`,`외교막힘`,`침체망신`],
    [`인택상해(人宅傷害)`,`집안 사람이 피해를 당하고 가택도 상하는 상`,`小`,``,`투서헌책`,`관재형벌`],
    [`미초희경(微招喜慶)`,`권한을 득한 희경사이나 권리가 부족하여 실소하는 상`,`小`,``,`불용`,`흉함`],
    [`색정`,`외남 색정사 有`,`大`,``,``,``],
    [`매사 허성불성`,`음1,2,4,5월 방문자는 종교진출하면 성공함`,`大`,`12`,`深居簡出`,``],
    [`매사 영속성없음`,`직업지위남편남자사는 선천적 기복재해를 겪어야 함`,`小`,`과시기`,`색정사有`,`暴客欺凌`],
    [`금장지경(金章之慶)`,`큰 권리를 부여받아 귀한 도장을 쥐게 되는 상`,`小`,``,``,`(폭력배의`],
    [`우유한가(優遊閑暇)`,`용모를 상관하지 않아도 되는 한가로운 상-실업자 관련`,`小`,``,``,`거짓능멸)`],
    [`결속`,`개신개혁을 완성하여 대재를 획득하나 부모災害 발생함 `,`小`,``,``,`관직자`],
    [`매사 허성불성`,`음3,4,5,6,9,12월 방문자는 종교진출하면 성공함`,`大`,`11`,`관계불통`,`생살대권`],
    [`매사 영속성없음`,`남편남자자식잘속는일은 선천적 기복재해를 겪음`,`小`,`쇠퇴기`,`가택이사`,`쥠`],
    [`간사은닉(奸私隱匿)`,`관계성불통-가택이사-私室출입 간사은닉(숨겨둔 첩-정인)`,`小`,``,`간사은닉`,`일반관재`],
    [`처재반외(妻財反畏)`,`금전과 처 사가 잘되기를 원하나 되려 두려운 상 `,`大`,``,``,`형벌대흉`],
    [`금전`,`금전 관련사`,`小`,``,`애증화합`,``],
    [`사업`,`사업 관련사`,`小`,`10`,`급속송쟁`,``],
    [`출행`,`처 관련 출행 이동 원행사`,`大`,`침해기`,`파재실종`,`출행이동`],
    [`지체의혹(遲滯疑惑)`,`신규사가 지체되고 의혹이 붙은 상`,`小`,``,`상신우려`,`승진영전`],
    [`총시성재(總是成災)`,`모두 재난 뿐인 상`,`大`,``,`總是成災`,``],
    [`금전`,`금전 관련사`,`小`,``,``,``],
    [`사업`,`사업 관련사`,`小`,`9`,``,``],
    [`여인`,`여인 관련사`,`大`,`소외기`,`개신개혁`,`손해가출`],
    [`음사지재(陰私之災)`,`일이 분명하지 않고 私情으로 인하여 재화를 당하는 상`,`小`,``,``,``],
    [`동지함의(動止咸宜)`,`안방 본분을 지키고 있어 가나 있으나 좋은 상`,`大`,``,``,``],
    [`박관`,`직업직위 불안침체사`,`小`,``,``,``],
    [`극부`,`남편 갈등사`,`大`,`8`,``,``],
    [`자식`,`자식 관련사`,`小`,`침체기`,`침체불안`,`가정막힘`],
    [`공외병송(恐畏病訟)`,`병과 송쟁사가 두려운 상`,`小`,``,`신물수손`,`재난극복`],
    [`흉제진취(凶制進取)`,`여러 난관을 극복하고 이익을 취할 수 있게 됨`,`大`,``,``,`이익진취`],
    [`관사`,`관사 관련사`,`小`,``,``,``],
    [`급속 `,`내사 급속사`,`小`,`7`,`출행원행`,`급속사有`],
    [`출행`,`외사 출행 이동 원행사`,`大`,`단절기`,`동요분산`,`압박가함`],
    [`폐구안녕(閉口安寧)`,`입을 닫아야만 편안할 상`,`大`,``,``,``],
    [`모사즉길(謀事卽吉)`,`계확사는 길한 상- 복통(腹痛) 有`,`小`,``,``,``]
  ]
const columnZ = [
    [``,``,0,0,``],
    [`단계`,`단계`,0,"가능",`불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明시작`,``,0,0,`관사직업남편부불화사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明종료`,``,0,0,`직업직위불안가정송사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`후원문사사로가능함`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`후원문서색정조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明하향`,``,0,0,`직업직위불안가정송사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗절정`,0,"명예직녹권리사로가능",``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗시작`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗종료`,0,0,`세속허사자로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,`금전사업처조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明진행`,``,0,`금전사업여인조력으로가능`,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,`暗진행`,0,0,`직업직위불안가정막힘사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [`明절정`,``,0,0,`관사직업남편생업동요불안분산사로불가`],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``],
    [``,``,0,0,``]
  ]
const columnAF = [
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [2,3,4,5,6,7,8,9,10,11,12,1],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [3,4,5,6,7,8,9,10,11,12,1,2],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [4,5,6,7,8,9,10,11,12,1,2,3],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [5,6,7,8,9,10,11,12,1,2,3,4],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [6,7,8,9,10,11,12,1,2,3,4,5],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [7,8,9,10,11,12,1,2,3,4,5,6],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [8,9,10,11,12,1,2,3,4,5,6,7],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [9,10,11,12,1,2,3,4,5,6,7,8],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [10,11,12,1,2,3,4,5,6,7,8,9],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [11,12,1,2,3,4,5,6,7,8,9,10],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [12,1,2,3,4,5,6,7,8,9,10,11],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [1,2,3,4,5,6,7,8,9,10,11,12],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0]
  ]
const columnAS =[
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [0],
    [2],
    [0],
    [0],
    [0],
    [0],
    [3],
    [0],
    [0],
    [0],
    [0],
    [4],
    [0],
    [0],
    [0],
    [0],
    [5],
    [0],
    [0],
    [0],
    [0],
    [6],
    [0],
    [0],
    [0],
    [0],
    [7],
    [0],
    [0],
    [0],
    [0],
    [8],
    [0],
    [0],
    [0],
    [0],
    [9],
    [0],
    [0],
    [0],
    [0],
    [10],
    [0],
    [0],
    [0],
    [0],
    [11],
    [0],
    [0],
    [0],
    [0],
    [12],
    [0],
    [0],
    [0],
    [0],
    [1]
  ]
const columnAU = [
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`강건정당속결**만약 타인과 다툰다면 먼저 나서서 속전속결로 종결해야만 유리함`,0,0,`정당한 명분이 있으므로 속결처리해야만 하고 좌고우면하여 미뤄지면 되려 불리해지는 人`,0,0,`현재 사안이 거꾸로 진행됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 뒤로 물러나 순응윳화적 처신으로 사안을 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 먼저 나서지 말고 순응유화적 처신으로 사안을 뒤로 밀면 결국 유리하게 되는 人`,0,0,`일이 띄엄끠엄 진행됨`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 피아간 싸울 의사가 별로 없고 있다해도 가벼움`,0,0,`옳지 못한 일이 밝혀져 천둥에 놀란 듯 두려워 하는 심정이나 근신하면 자연히 사라질 人`,0,0,`신규사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`호가호위(狐假虎威)세**만약 타인과 다툰다면 피아간 싸울 의사가 별로 없고 있다해도 가벼움`,0,0,`호가호위 위세로 상대를 공격하나 적중하시키지지 못하는 상황에 있는 人`,0,0,`여러 사안을 하나로 묶는 개신개혁사有**가을철에 대재(大財)사 맞고 부모근심사 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`동료와의 관계 또는 동업사로 혐의를 받고 있으나 제일 친하거나 제일 가까이 거주하는 자와 의논하여 결정하면 현재의 불안이 해결될 人`,0,0,`모든 관계성이 끓김**1남2녀 삼각관계有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 상이므로 신중해야 함 `,0,0,`반복적 동요사를 겪으며 진퇴양난에 처한 人`,0,0,`신규사가 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`혐의불안선택기로**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,0,0,`동료와의 관계 또는 동업사로 혐의를 받고 있으나 제일 친하거나 제일 가까이 거주하는 자와 의논하여 결정하면 현재의 불안이 해결될 人`,0,0,`1녀2남 삼각관계有`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 뒤로 물러나 순응윳화적 처신으로 사안을 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 먼저 나서지 말고 순응유화적 처신으로 사안을 뒤로 밀면 결국 유리하게 되는 人`,0,0,`여러 사안을 하나로 묶는 개신개혁사有**가을철에 대재(大財)사 맞고 부모근심사 발생함`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 뒤로 물러나 순응윳화적 처신으로 사안을 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 먼저 나서지 말고 순응유화적 처신으로 사안을 뒤로 밀면 결국 유리하게 되는 人`,0,0,`신규사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 뒤로 물러나 순응윳화적 처신으로 사안을 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 먼저 나서지 말고 순응유화적 처신으로 사안을 뒤로 밀면 결국 유리하게 되는 人`,0,0,`일이 띄엄끠엄 진행됨**밝은 데로 나아 감`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`순응유순유화후결**만약 타인과 다툰다면 뒤로 물러나 순응윳화적 처신으로 사안을 연기시키면 결과가 유리함`,0,0,`역상적 사안이므로 억울하더라도 절대 먼저 나서지 말고 순응유화적 처신으로 사안을 뒤로 밀면 결국 유리하게 되는 人`,0,0,`일이 순서적으로 진행됨**전화위복`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [`수구대신(守舊待新)**만약 타인고 다툰다면 상대는 뜻은 움직이나 중지됨`,0,0,`자기 스스로를 과신하여 기회를 잃고 신음하며 다음을 기다리는 人**고등시험 응시자가 많음`,0,0,`신규사 있음`,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0],
    [``,0,0,``,0,0,``,0,0]
  ]
const columnBE = [
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동후회사`,`기사`,`구사`,`외사`,`이면사`,`좌고우면 `,`선성`,`거짓`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신사`,`외사`,`이면사`,`배신-무력`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`무력인`,`아발동후회사`,`미사`,`신구중간사`,`외사`,`이면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`아권리를가져갈인`,`아발동타발동급속사겹침`,`미사`,`신구중간사`,`외내사겹침`,`아표면타이면사겹침`,`좌고우면 `,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동후회사`,`기사`,`신사`,`외사`,`이면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`타발동급속사`,`미사`,`신사`,`내사`,`이면사`,`충성`,`악성-무력`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`이익인`,`아발동사`,`미사`,`신구중간사`,`외사`,`표면사`,`좌고우면 `,`선성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인`,`아발동사`,`미사`,`신사`,`외사`,`표면사`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`신구중간사`,`내사`,`표면사`,`충성`,`악성`,`진위반반`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`피해인`,`타발동사`,`미사`,`신사`,`내사`,`표면사`,`좌고우면 `,`선성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [`손해인-침체`,`아발동후회사겹침`,`기사`,`신사`,`외사`,`표면이면사겹침`,`배신`,`보성`,`진실`],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``]
  ]
const columnBO = [
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-공허`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`흥`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠-동요 후개통`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`쇠-공허`,`쇠-이사함`],
    [``,``],
    [``,``],
    [``,``],
    [``,``],
    [`흥`,`쇠`],
    [``,``],
    [``,``],
    [``,``],
    [``,``]
  ]
const columnBS = [
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`합의유리`,`합의유리`,`합의유리`],
    [`  승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`애중합의`,`애증합의`,`애증합의`],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`  승`,`승`,` 승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [` 승`,`승`,`  승`],
    [``,``,``],
    [``,``,``],
    [``,``,``],
    [`비김`,`비김`,`비김`],
    [``,``,``],
    [``,``,``]
  ]
const columnBV = [
    // [0,0,0,``,0,0,0,``],
    // [용모,가문,合6,``,용모,가문,合6,``],
    // [0,0,0,``,0,0,0,``],
    [3,2,5,`음8월`,2,2,4,`음2월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음7월`,2,1,3,`음11월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음10월`,1,1,2,`음10월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,2,3,`음3월`,1,1,2,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,1,3,`음2월`,3,2,5,`음7월-연기우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,3,6,`음1월`,2,2,4,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음12월`,3,2,5,`음-음11월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [3,2,5,`음1월-연기우려`,2,1,3,`음7월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,2,4,`음12월`,3,2,5,`음12월-연기 또는 파혼우려`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음11월`,3,3,6,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [1,1,2,`음11월`,3,2,5,`음4월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [2,3,5,`음9월`,3,2,5,`음3월`],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``],
    [0,0,0,``,0,0,0,``]
  ]
const columnCE = [
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`둔한 것같으나 업무는 수준에 맞게 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`언어가 교묘하며 속이나 업무는 평상적임`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 훌륭하고 업무도 순준 이상으로 진행함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 여러 일을 하나로 일관시키는 업적으로 재정을 확충함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`사교성이 좋아 동료의 협조를 얻으나 업무가 허무하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높고 외부 변수를 맞아 동요불안사를 겪게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 업무가 강하지 못해 초반에 실적을 내나 종반에 무력하게 됨`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인품이 높고 업무를 일관시키는 개혁을 이뤄 재정을 확충하는 실적을 올림`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`성질이 폭력적이고 조급하나 중반의 손해를 넘기고 종반에 실적을 냄`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`유순하고 준수 미모이나 업무가 강하지 못해 중반 이외초 종반에는 실적이 미달함`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`인격이 높으나 업무는 수준 이하로 마침-단 기우적 변수로 희망적 기틀을 다짐`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [`어어와 처신이 불성실하고 업무 전반에 걸쳐 반목대립하여 송쟁사를 일으킴`,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0],
    [``,0,0,0,0,0]
  ]
const columnCL = [
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,``,``],
    [``,``,``,``,``,``,``,``,``,``,`낮`,`밤`],
    [`1.2월`,`多集`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n선택적`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`,`月\n무시`],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,``,`반대없음`,`불이행`,`선압박`,`退背`,`원`,`去`,`세입조력`,`정서`,``,`정동`],
    [`10.11`,`集`,``,``,`후화합`,``,``,``,`설기피로`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`  集`,`반대있음`,`불이행`,`선압박`,`進向`,`  원`,`  留`,`세입조력`,`북서`,``,`북서`],
    [`7.8월`,``,`본인제압`,``,`후불가`,``,``,``,`설기피로`,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,`불이행`,`상대무력`,`進向`,`  원`,`  留`,`주인무력`,`북북서`,``,`북북서`],
    [`7.8월`,` 集`,`본인제압`,``,``,``,``,``,``,``,``,``],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`불이행`,`상대무력`,`進向`,`  근`,`  留`,`주인무력`,` 정북`,`정북`,``],
    [`7.8월`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`본인반대`,`불이행`,`권리양도`,`退背`,`  원`,` 去`,`주인조력`,`북북동`,`북북동`,``],
    [`7.8월`,` 集`,`제압없음`,``,`됨`,``,``,``,``,``,``,``],
    [`10.11`,`多集`,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,`多集`,`반대있음`,`불이행`,`선費후不`,`退背`,`  원`,`  去`,`상호동요`,`동북`,`동북`,``],
    [`7.8월`,``,`본인합세`,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,`내부제압`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`불이행`,`선이후합`,`退背`,`  원`,`  去`,`상호화합`,`동남`,`정서`,``],
    [`7.8월`,` 多集`,``,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대없음`,`불이행`,`선이후不`,`進向`,`  근`,`  留`,`주인가해`,`동남`,`북서`,``],
    [`7.8월`,`多集`,``,``,`빠르게`,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,``,``,``,``,``,``,``,``,``,``],
    [`7.8월`,` 多集`,`반대있음`,`불이행`,`선損후이`,`退背`,`  원`,`  留`,`상호반목`,`남남동`,`북북서`,``],
    [`10.11`,``,`끌면제압`,``,`천천히`,``,``,``,``,``,``,``],
    [`3.6\n9.12`,` 集`,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,`불이행`,`선난후난`,`退背`,`  원`,`  去`,`세입가해`,`정서`,``,`정북`],
    [`7.8월`,`  集`,`1회연기`,``,``,``,``,``,``,``,``,``],
    [`10.11`,`多集`,`제압됨`,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,``,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,``,`반대있음`,`불이행`,`본안뮤력`,`退背`,`원근왕래`,`  去`,`세입가해`,`동남`,``,`북북동`],
    [`7.8월`,`  集`,`끌면제압`,``,`불가함`,``,``,``,`주인이사`,``,``,``],
    [`10.11`,` 多集`,`본인무력`,``,``,``,``,``,`확정참고`,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``],
    [`1.2월`,`  集`,``,``,``,``,``,``,``,``,``,``],
    [`4.5월`,` 多集`,`반대없음`,`불이행`,`선손후손`,`進向`,`  근`,`  留`,`무난`,`서서남`,``,`동북`],
    [`7.8월`,``,``,``,``,``,``,``,``,``,``,``],
    [`10.11`,``,``,``,``,``,``,``,``,``,``,``],
    [`3.6\n9.12`,``,``,``,``,``,``,``,``,``,``,``]
  ]
const columnCY = [
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [``,``,``,0,0,``,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,"목기부정",`木절단入`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`자살귀`,`水투신`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`자상귀`,`水투신`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`변사귀`,`객사`,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,`변사귀`,`객사`,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,`상가부정`,`조문왕래`,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,``,``,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,`상가부정`,`조문왕래`,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,0,0,`육친`,``,``],
    [`질병`,`음귀`,`묘지탈`,0,0,`묘좌`,`북북동`,`없음`],
    [``,``,``,0,0,`묘향`,`남남서`,``],
    [`생업`,``,``,`음사부정`,`제사잘못`,`전경`,`대로보임`,``],
    [``,``,``,0,0,`묘속`,`봉분흉함`,``],
    [`가정`,``,``,0,0,`육친`,`고조모`,``],
    [`질병`,`여귀`,`가장부인`,0,0,`묘좌`,``,`없음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,`여귀`,`가장부인`,0,0,`육친`,``,``],
    [`질병`,`신부정귀`,`신不定`,0,0,`묘좌`,`북북서`,`없음`],
    [``,`여귀`,`가장부인`,0,0,`묘향`,`남남동`,``],
    [`생업`,``,``,0,0,`전경`,`항구포구`,``],
    [``,``,``,0,0,`묘속`,`  水`,``],
    [`가정`,`신부정귀`,`신不定`,0,0,`육친`,` 조모`,``],
    [`질병`,``,``,0,0,`묘좌`,``,`특별있음`],
    [``,``,``,0,0,`묘향`,``,``],
    [`생업`,``,``,0,0,`전경`,``,``],
    [``,``,``,0,0,`묘속`,``,``],
    [`가정`,``,``,"여고음강","시모혼령",`육친`,``,``]
  ]
const columnDH = [
    [`출생연도`,`24`,`96`,`25`,`97`,`26`,`98`,`27`,`99`,`28`,`00`,`29`,``,`30`,`102`,`19`,`91`,`20`,`92`,`21`,`93`,`22`,`94`,`23`,`95`],
    [0,`36`,``,`37`,``,`38`,``,`39`,``,`40`,``,`41`,``,`42`,``,`31`,``,`32`,``,`33`,``,`34`,``,`35`,``],
    [0,`48`,``,`49`,``,`50`,``,`51`,``,`52`,``,`53`,``,`54`,``,`43`,``,`44`,``,`45`,``,`46`,``,`47`,``],
    [0,`60`,``,`61`,``,`62`,``,`63`,``,`64`,``,`65`,`01`,`66`,`02`,`55`,`03`,`56`,`04`,`57`,`05`,`58`,``,`59`,``],
    [0,`72`,`08`,`73`,`09`,`74`,`10`,`75`,`11`,`76`,`12`,`77`,`13`,`78`,`14`,`67`,`15`,`68`,`16`,`69`,`17`,`70`,`06`,`71`,`07`],
    [0,`84`,`20`,`85`,`21`,`86`,`22`,`87`,`23`,`88`,`24`,`89`,`25`,`90`,`26`,`79`,`27`,`80`,`28`,`81`,`29`,`82`,`18`,`83`,`19`],
    [2,`외사출행이동사**동요분산사`,`모든재난극복-이익진취`,``,`눈이가려진 상`,``,`가정반목송쟁사`,`외사급속송쟁사-상해우려`,`환성경사`,``,`내 녀 색정사`,`가정침체개통사`,`중중희애사`,``,`명예자산권리사**외교막힘`,`매사허성불성**세속허사 자**희경사**男색정사`,`음1,2,4,5월 방문자는 종교진출하면 `,`매사영속성없음**희경사`,`종교진출하면자리함`,`던화위복`,`가정사출행이동사`,`음사폐문안녕`,`남녀종업원모의모사有`,`내부가정막힘**침체경구사`,`탄식신음-병 또는 事미수`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`내사 급속사`,`빈궁**관재`,`전화위복`,`전도**빈궁`,``,``,``,`빈궁**관재`,``,`전도`,``,`음4월 화재조심`,`빈궁`,``,`빈궁**병재`,``,`전도`,`빈궁*병재`,`재난해소`,`재난해소`,`빈궁`,`전도**관재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [3,`내부가정막힘`,`탄식신음-병 또는 事미수`,`외사출해이동사**동요분산사`,`모든재난극복-이익진취`,``,`눈이 가려진 상`,``,`가정대립송쟁사`,`외사급속송쟁사-상해우려`,`환성경사`,``,`내 녀 색정사`,`가정침체개통사`,`중중희애사`,``,`명예자산권리사**외교막힘`,`매사영속성없음**男색정사`,`종교진출하면 자립함`,`매사영속성없음**희경사`,`종교진출하면 자립함`,``,`가정사출행이동사`,``,`음사폐문안녕`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`내사 급속사`,``,`전화위복`,``,``,``,`재난해소`,``,`재난해소`,``,``,`음4월 화재조심`,``,``,``,``,``,``,`전화위복`,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [4,`관재`,`음사폐문안녕`,`내부가정막힘`,`탄식신음-事미수`,`외사출행이동사**동요분산사`,`모든재난극복-이익진취`,``,`눈이 가려진 상`,``,`가정대립송쟁사`,`외사급송쟁사-상해우려`,`환성경사`,``,`내 녀 색정사`,`가정침체개통사`,`중중희애사`,``,`명예자산권리사**외교막힘`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음**희경사`,`종교진출하면자립함`,``,`가정시출행이동사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,`재난해소`,``,`내사 급속사`,`빈궁**관재`,`전화위복`,`빈궁`,`재난해소`,``,``,`빈궁`,``,``,`재난해소`,`빈궁**관재**전도`,`음4월 화재조심`,``,``,``,``,`빈궁**병재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [5,`외모화장이 필요없는 여유한가`,`가정사출행이동사`,`관계성붙통-가택이전-간사은닉`,``,`내부가정막힘`,`매사위역사`,`내사 급속사`,`외사출행이동사**동요분산사`,`부부반목사`,`눈이 가려진 상`,``,`가정대립송쟁사`,`물품손실사`,`외사급속송쟁사-상해우려`,``,`내 녀 색정사`,`가정침체개통사`,`매사위역사`,`면예자산권리사**외교막힘`,`희경사`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,`전화위복`,``,``,``,`빈궁`,`빈궁**관재`,``,`관재`,`전화위복`,`빈궁`,``,`전도`,``,`전도**빈궁**관재`,``,`빈궁**병재`,``,`음4월 화재조심`,`병재**관재**재난해소`,``,``,``,`빈궁**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [6,`매사영속성없음`,`종교진출하면자립함`,`외모화장이 필요없는 여유한가`,`가정사출행이동사`,`관계성불통-가택이전-간사은닉`,``,`내부가정막힘`,`매사위역사`,`내사 급속사`,`외사출행이동사**동요분산사`,`부부반목사`,`눈이 가려진 상`,``,`가정대립송쟁사`,`물품손실사`,`외사급속송쟁사-상해우려`,``,`내 녀 색정사`,`가정침체개통사`,`매사위역사`,`명예자산권리사**외교막힘`,`희경사`,`매사영속성없음**男색정사`,`종교진출하면자립함`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,``,``,`전화위복`,`관재`,``,`                                                                  `,`빈궁**재난해소`,``,`빈궁**관재`,`관재`,`전화위복`,`빈궁`,`재난해소`,`관재`,``,`빈궁`,``,`병재`,`재난해소`,`음4월 화재조심`,`전도`,``,`전도**빈궁`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [7,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외모화장이 필요없는 여유한가`,`가정사출행이동사`,`관계성불통-가택이전간사은닉`,``,`내부가정막힘`,`매사위역사`,`내사 급속사`,`외사출행이동사**동요분산사`,`부부반목사`,`눈이 가려진 상`,``,`가정대립송쟁사`,`물품손실사`,`외사급속송쟁사-상해우려`,``,`내 녀 색정사`,`가정침체개통사`,`매사위역사`,`희경사`,`명예자산권리사**외교박힘`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`동요불안분산사`,`빈궁`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전도**관재**전화위복`,`동요불안분산사`,`전도**관재`,`동요불안분산사`,`빈궁`,`동요불안분산사`,`빈궁**관재**재난해소`,`동요불안분산사`,`관재**재난해소**전화위복`,`동요불안분산사`,`빈궁`,`동요불안분산사`,``,`동요불안분산사`,`빈궁`,`동요불안분산사`,`전빈궁**병재`,`동요불안분산사`,`음4월 화재조심`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [8,`희경사`,`명예자산권리사**외교막힘`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`외모화장이 필요없는 여유한가`,`가정사출행이동사`,`관계성불통-가택이전-간사은닉`,``,`내부가정막힘`,`매사위역사`,`내사 급속사`,`외사출행이동사**동요분산사`,`부부반목사`,`눈이 가려진 상`,`가정대립송쟁사`,``,`물품손실사`,`외사급속송쟁사-상해우려`,``,`내 녀 색정사`,`가정침체개통사`,`매사위역사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`음4월 화재조심`,`재난해소`,`빈궁`,``,`빈궁`,``,`관재`,`전화위복`,``,``,`빈궁`,`                                 `,`빈궁`,``,`빈궁**관재`,`전화위복`,``,``,``,`전도`,``,`전도**관재`,``,`빈궁**관재**병재`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [9,`가정침체개통사`,``,`명예자산권리사**외교막힘`,`빈궁`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,``,`가정사출행이동사`,`관계성불통-가택이전-간사은닉`,``,`내부가정막힘`,`탄식신음-병 또는 事미수`,`도적의혹이동사`,`외사출행이동사**동요분산사`,`부부반목사`,`눈이 가려진 상`,`가정대립송쟁사`,``,`물품손실사`,`외사급속송쟁사-상해우려`,``,`내 녀 색정사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,`음4월 화재조심`,`재난해소`,``,``,`빈궁`,``,`병재`,`전화위복`,``,``,``,`  `,`빈궁**관재`,`내사 급속사`,`빈궁**관재`,`전화위복`,``,``,`전도**병재**관재`,``,`전도***빈궁`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [10,`내 녀 색정사`,``,`가정침체개통사`,``,`명예자산권리사**외교막힘`,`빈궁`,`매사영속성없음**男색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`가정사출행이동사`,``,`관계성불통-가택이전-간사은닉`,``,`내부가정막힘`,`탄식신음-병 또는 事미수`,`도적의혹이동사`,`외사출행이동사**동요분산사`,`눈이 가려진 상`,`모든재난극복-이익진취`,`가정대립송쟁사`,``,`외사급속송쟁사-상해우려`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전도**빈궁`,``,``,``,`음4월 화재조심`,`재난해소`,`전도`,``,`빈궁`,``,`빈궁**병재`,`전화위복`,``,``,``,``,`빈궁**관재`,`내사 급속사`,`빈궁**관재`,`전화위복`,``,``,`전도**빈궁**병재`,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [11,``,`외사급속송쟁사-상해우려`,`내 녀 색정사`,`재물손실사`,`가정침체개통사`,`매사위역사`,``,`명예자산권리사**외교막힘`,`매사영속성없음**물품손실사**男색정사`,`종교진출하면자립함`,`매사영속성없음`,`종교진출하면자립함`,`가정사출행이동사`,`혼인사-부족`,``,`관재질병사`,`내부가정막힘`,`매사위역사`,`외사출행이동사**동요분산사`,`모든재난극복-이익진취`,`눈이 가려진 상`,`스스로지켜 가나 있으나 좋음`,`상하존비상몽`,`가정대립송쟁사`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁`,``,`관재`,``,`빈궁**병재`,`재난해소`,`음4월 화재조심`,`전도**빈궁`,`전도`,``,`빈궁`,``,``,`전화위복`,`빈궁**관재`,``,`빈궁`,`재난해소`,`관재`,`내사 급속사`,`관재`,`전화위복`,`빈궁**관재`,`재난해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [12,`상하존비상몽`,`가정대립송쟁사`,``,`외사급속송쟁사-상해우려`,`내 녀 색정사`,`재물손실사`,`가정침체개통사`,`매사위역사`,``,`명예자산권리사**외교막힘`,`매사 영속성 없음**물품손실사**男색정사`,`종교진출하면 자립함`,`매사 허성불성**세속허사자`,`음1,2,4,5월 방문자는 종교진출하면 자립함`,`가정사출행이동사`,`혼인사-부족`,``,`관재질병사`,`내부가정막힘`,`매사위역사`,`외사출행이동사**동요분산사`,`모든재난극복-이익진취`,`눈이 가려진 상`,`스스로지켜 가나있으나 좋음`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`빈궁**관재`,`재난해소`,`빈궁**관재`,``,``,``,`빈궁**병재`,`재난해소`,`음4월 화재조심`,`전도**빈궁`,``,`전도`,`빈궁`,``,``,`전화위복`,`빈궁`,``,`빈궁`,`재난해소`,`관재**내사 급속사`,`폐한인`,`관재`,`전화위복`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [1,`빈궁**관재`,`눈이 가려진 상`,``,`가정대립송쟁사`,`외사급속송쟁사-상해우려`,`환성경사`,`내 녀 색정사`,``,`가정침체개통사`,`중중희애사`,``,`명예자산권리사**외교막힘`,`매사 허성불성**세속허사자**男색정사`,`음1,2,4,5월 방문자는 종교진출하면 `,`매사허성불성**세속허사 자**희경사`,`음3,4,5,6,9,12월 방문자는 종교진출하면 `,`가정사출행이동사`,``,``,`음사폐문안녕`,`내부가정막힘`,`탄식신음-병 또는 事미수`,`외사출행이동사**동요분산사`,`모든재난극복-이익진취`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``],
    [0,`전화위복`,`재난해소`,`빈궁`,``,``,``,`빈궁`,``,``,``,`음4월 화재조심`,`빈궁`,`빈궁**병재`,`성공함`,``,`성공함`,`전도**빈궁**병재`,`전화위복`,``,`전도**빈궁`,``,``,`관재**내사 급속사`,`재난해소`],
    [0,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``]
  ]
  

  const CHOSA1 = [`■ 몸을 가만있어도 마음은     바쁘겠어! 

  가택에 들어온 
  ⊕⊖가택수망노부 부정만 해소시키면 가만히 있어도 복이 굴러 들어오겠네! 
  `,`■ 지금 시도하는 일에서 후퇴하는 방침으로 가야 오히려 이익이 오겠어! 
  아니면 기도ㆍ치성을 드리고 일을 확 저질러가도 좋겠고!
  
  ⊕⊖신변 목기부정 침범했네?
  `,`■ 집안에 든 음란부정사만 빼고는 바라는 일은 다 잘될 텐데
  
  ⊕변사귀, 
  ⊖신부정귀를 떼야 망신을 
    당하지 않게 생겼어!
  `,`■ 가만히 있어도 발복할 분이 오셨네? 
  그러나
  ⊕변사귀, 
  ⊖신부정귀를 해소시키면 
    더욱 좋겠어!
  `,`■ 금전이 여기저기 널려 있긴 한데 하나로 개혁시키려 하겠네!그러나 여름이라야 효과가 있겠어! 그리고 상대 내 화살에 안 맞겠어 ! 
  그대로 두면 자칫 부부이별까지 가겠어!
  
  ⊖가택상가부정이야!
  `,`■ 옛일, 옛 인연이 모두 끝났네? 一男二女 삼각관계도 그렇고! 선택의 기로에 서서 불안한 심정이야! ⊕자살귀, 
  ⊖도로귀를 잡아!
  
  ⊖신변음사부정이들었어!
  `,`■ 토대를 지키기가 진퇴양난이겠어! 
  기도하며 조금만 더 버티면 새 일에 힘이 붙을 텐데! 
  ⊕⊖가택상가부정이 듬.
  ⊕변사귀,⊖신부정귀 훼방이야!
  ⊖기도 치성이 효과가 있을 거야 !
  `,`■ 마음이 불안하겠고, 선택의 기로에 섰어! 근친자 하나로 결정 해야겠어 !
  一女二男 삼각관계도 있네? 기도ㆍ치성을 잘 드려야 부족한 것들이 채워지겠어!
  ⊕⊖신변 음사부정이 침범        했어 !
  `,`■ 일도 여러개고 돈도 여기저기 열렸는데 일을 확 바꿔하나로 묶어야 내것이 되게 생겼어! 
  그런데 부모가 걱정이네!
  
  ⊖가택상가부정이야!
  `,`■ 새 것을 따라 나서야만 하겠어!
  
  ⊕음귀, ⊖신부정귀만 잘 떼면 모든 일이 계획대로 다 되게 생겼네!
  
  ⊕신변음사부정 침범했어!
  `,`■ 집안에 같이 사는 사람이 원수로 변하게 생겼네?
  
  ⊕수귀, 
  ⊖여귀를 잡아야만 탈을 면    할거야!
  `,`■ 이사하겠네? 
  보배를 품에 안고 왜 골목을 헤매나?
  
  ⊕음귀ㆍ수귀, 
  ⊖여귀ㆍ신부정귀 때문이야! 이것을 떼면 형통하게 되겠어!`];
  const YOGYEOL1 = [`■ 돈과 토대와 건강이 모두 갖추어졌다. 집에 있으면 답답하고 입 다물고 있어도 편하지 않다.
  `,`■ 후퇴해도 좋고 전진해도 좋다. 그러나 지키고만 있으면 재앙을 면하기 어렵다.
  `,`■ 서로 교합되니 좋고 나는 풍부하나 상대는 궁핍하다. 귀인이 귀인을 만나니 공직자는 좋으나 일반인은 살길이 두렵다.
  `,`■ 낮정단은 귀인이 힘이 없다. 부탁해도 도움이 안된다. 사람은 기운을 받으나 가택은 좋지 않은 일이 생긴다.
  `,`■ 하늘이 사업을 도우나 잃는 것이 상반이다. 
  왼손으로 벌어서 오른손으로 버리니 끓는 물에 눈녹듯 하리라.
  `,`■ 여러 사람이 나를 해치나 다른데 정신팔려 모른다. 직녹은 이미 끊어졌으니 병들면 말을 못하고 먹는다.
  `,`■ 모두 입을 다물고 주야에 관귀가 범을 타고 있다. 진퇴가 곤란하니 사람은 다치고 집은 쇠락한다.
  `,`■ 굴 하나를 놓고 두 뱀이 싸운다. 돌을 짊어지고 강을 건넌다.
  칡을 캐려면 머리를 찾으라. 辛이 卯에 가했기 때문이다.
  `,`■ 밤정단은 하늘이 도와 재수가 있으나 손윗사람에게 재앙이 생긴다.
  부모로 인해 재물이 많이 손모되어 간다.
  `,`■ 처음에는 돈 때문에 일이 생기나 나중에는 여자에게 해를 입는다. 
  그러나 끝에는 덕을 보니 역마ㆍ정마가 교대로 있다.
  `,`■ 손해수가 몸에 탔으니 가인 중에 원수 될 사람이 있다. 또한 병자나 재앙이 오니 낮정단은 더욱 나쁘다.
  `,`■ 낮정단은 귀인에게 의논해야 일이 쉽다.
  왕성한 기운이라 해도 자세히 보면 무슨 이익이 있겠는가.`]
  const SIBO1 = [`■ 내게 좋은 것이 많아
  그대로 지켜야 한다.
  단, 가정에 ⊕⊖수망노부
  부정이 들어 가장이 집을 겉돈다. 
  재산에 흉계가 끼어들어 재판이 붙었다. 사람은 흥하나 집이 좁다. 
  신규사도 조용히 진행시키면 발전한다.
  `,`■ 사람이나 집이나 모두
  소모사가 많다.
  타인에게 의존하여 도움
  을 얻게 된다. 
  물러나는 자세를 견지하면 유리해진다.
  금전과 자식 문제로 凶이 있으나 재앙을 이루지는 않는다.
  `,`■ 가정에 금전ㆍ이동ㆍ원행사
  등 기쁨이 있고, 교섭사나
  교역 등은 이익이 되나
  음사ㆍ음란ㆍ주색간도 등 부정한 징조가 있다. 
  부인의 일이 논란이 된다.
  `,`■ 이익사가 있고 소득한다.
  집안일을 밖에서 말하면
  해롭게 된다.
  사람은 만사에 행운이 있으나 가택은 속는 일이 있고 훼손이 있게 된다.
  신규사로 심중 놀람이 있으나 조용하면 다 해소된다. 공격자가 오히려 도움이 된다. 서북쪽에 고사하면 좋다.
  `,`■ 부부음탕하다.
  사람은 이익이 있으나
  가정에는 재물의 손모가
  많다. 저주 받는 일이 있다.
  금전사가 여럿이고 개신ㆍ개혁하려 한다. 그것이 좋다. 부부이별을 생각한다.
  집안 어른에게 재해가 기한다. 시기를 잃게 되는 걱정이 있다.
  다인 다사건이다.
  `,`■ 자충수적 우환이 있다.
  가택의 권리가 넘어간다.
  一男二女의 색정사가 있다.
  동료간의 옛일이 끝나는 이유로 선택의 기로에서 불안하다.
  먼곳과 소원관계를 버리고 가깝고 친한자를 선택하면 결과가 좋다.
  正月 임신사가 있다.
  속는 일이 있다.
  `,`■ 자충수에 의한
  우환이 임했다.
  사람이 상하고 가택은 썩는 형국이다. 오고가는 왕래사의 기복이 심하고 신규사의 불안도 크다.
  
  ⊕문서, ⊖존장의 패해가 있고 고사를 지내야 좋다.
  `,`■ 外 신변에 폐지사를
  부흥시키려는 兆가 있다.
  금전ㆍ여자문제로 원행
  ㆍ이동사도 있다.
  심중은 불안하고 결정을 못해 기로에 있다. 
  친한 것으로 결정해야 좋다.
  무엇이든 첫 조건이나 제의를 결정해야 한다.
  형제의 재해가 야기된다.
  `,`■ ⊕上人금전, ⊖문서금전
  문제가 현안이 되었다.
  財가 여러군데서 움직
  인다. 개혁하는 것이 좋다.
  옛것을 버리고 새것으로 쓴다. 상대와는 서로 시의하고 반목적이다.
  上人의 후원을 바라지 말라.
  다인 다사건이다.
  `,`■ 신규사가 있다.
  금전ㆍ사업으로 원행
  ㆍ이동사가 있다.
  귀신 방해만 해소시키면 만사길조이다. 좋다. 단, 가장이 가정을 겉돈다.
  `,`■ 가내 우환이 발생하고
  가인이 가장을 속이며 원한
  을 품고 가해하려 한다.
  도중에 장애는 있으나 차근차근 전진하여 목적사가 달성된다.
  자식 문제로 속는 일이 발생한다. 모든 재화는 해소된다. 
  正月 임신사가 있다.
  `,`■ 가내 우환이 발생하고
  가인이 가장을 속이며 원한
  을 품고 가해하려 한다.
  가정ㆍ사업장 등 가택을 옮기려 한다.
  근신하면 천우신조를 받는다.
  正月 임신사가 있다.
  형제의 재해가 야기된다.`]
  const SIBO2_1 = [`■신변직녹외사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  은혜를 원수로 갚는 관송사가 있다.
  `,`■가택후원내사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단을 잡는다.
  `,`■가택자손반목사이다.
  내방조짐 상의 : 
  조용히 있는게 이롭다.
  관재 질병이 침입한다.
  `,`■신변이별외사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.
  `,`■신변금전외사이다.
  내방조짐 상의 : 
  정사 利 암사불리하다.
  부모 재해 예방이 필요하다.
  `,`■신변가정불안사이다.
  내방조짐 상의 :  
  은둔ㆍ도망ㆍ원행에 이롭다. 가택의 권리가 양도된다.
  `,`■신변직녹외사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  색정불화 이심 원한의 상이다.
  `,`■신변금전원이동행사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  부족 불비하다.
  `,`■신변금전외사이다.
  내방조짐 상의 : 
  정사 利 암사불리하다.
  부모 재해 예방이 필요하다.
  `,`■신변금전원행이동사이다.
  내방조짐 상의 :  
  개정ㆍ승신 하고 모든 일이 겹쳐 일어난다.
  후원 천거가 있다.
  `,`■가정침해사이다.
  내방조짐 상의 : 
  은둔ㆍ도망ㆍ원행에 이롭다.
  만사피면 여의하다.
  `,`■신변이별외사이다.
  내방조짐 상의 :  
  도망은 멀리 갔고, 비밀사통하고 병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.`]
  const SIBO2_2 = []

  const SAGWA_IMAGE1 = [
    `/static/images/sagwa/023/row-1-column-1.png`,
    `/static/images/sagwa/023/row-1-column-2.png`,
    `/static/images/sagwa/023/row-1-column-3.png`,
    `/static/images/sagwa/023/row-1-column-4.png`,
    `/static/images/sagwa/023/row-1-column-5.png`,
    `/static/images/sagwa/023/row-1-column-6.png`,
    `/static/images/sagwa/023/row-1-column-7.png`,
    `/static/images/sagwa/023/row-1-column-8.png`,
    `/static/images/sagwa/023/row-1-column-9.png`,
    `/static/images/sagwa/023/row-1-column-10.png`,
    `/static/images/sagwa/023/row-1-column-11.png`,
    `/static/images/sagwa/023/row-1-column-12.png`,
  ]
  
const HEASYEOL = [
  // 1
  `  이 과(課)는 천지동궁(天地同宮)이며, 복음과(伏吟課)로써 상하 요극(遙剋)이 모두 이루어지지 않으므로 복음과를 취하여 사용한다. 삼전 사신인(巳申寅)은 원태격(元胎格)이며, 간상 사(巳)는 순미(旬尾) 사(巳)가 가해져서 녹신(祿神)으로 되어 초전(初傳)을 이루었다.
  병의 정단은 좋지 않다. 초․중전이 왕하여 말전이 장생(長生)으로 되면 외부에서 찾지 않고서도 앉아서도 복리가 형통한다. 양일(陽日) 복음은 자임격(自任格)으로서 천지가 폐색되어 아직 펴지지 않은 형상이나, 원태(元胎)는 장래 생발할 징조를 보이므로 고요한 가운데 동의(動意)를 품고 대기하여 활동으로 옮겨야 한다.
  초전은 중전을 극하고, 중전은 재물이 또 재물을 본다고 하여 상당한 재물을 소득하게 된다. 말전은 일간을 생하고 일지를 극하므로 사람은 번영하고 집은 좁다. 그러나 일지상신이 묘(墓)에 복(伏)하면 가정은 혼미하나 극에 의하여 열리므로 후에는 광명에 도달된다. 백법(百法)에 이르기를 ｢삼전이 체극(遞剋)되면 타인의 침릉(侵凌)을 받는다｣고 하였다. 비전(比傳)에 이르기를 ｢복음은 즉시 움직여도 목적에 도달하기 어려우며 기다리는 사람은 속히 돌아온다｣고 하였다. 
  초전에 천공이 승하는 것은 타인에 의존할 수 없으며, 구진(勾陳)이 승하는 것은 무슨 일에서나 정의를 끊을 수 없다. 이 과(課)는 초전이 일덕(日德)․일록(日祿)이며 중전은 일간의 재(財)로 되고, 말전은 일간의 장생에 해당하니 만사가 길과이다. 왕록(旺祿)이 간상(干上)l에 있으면 몸(身)에 임한다고 말한다. 길하다 해도 급하게 움직이지 않는 것이 좋으니 앉아서 길보(吉報)가 있거나 좋은 생각이 나온다. 일귀(日貴)는 역행하여 삼전(三傳)은 구(勾)․사(蛇)․백(白)이며, 야귀는 순행하여 삼전이 공(空)․현(玄)․합(合)이다. 귀신(貴神)이 해(亥)에 임하여 귀(貴)는 천문으로 올라 흉재를 푼다.`
  ,
  // 2
  `  이 과(課)는 4과 중에서 제2과의 묘진(卯辰)이 상에서 하를 극하므로 원수과(元首課)이고 3전은 묘인축(卯寅丑)의 퇴여격(退茹格)이다. 퇴여는 물러나는 것이 좋다. 일간상신 임(壬)에 둔귀(遁鬼)가 있고 스스로 제지(制地)에 앉았으므로 재앙을 이루지 않는다. 그리고 청룡의 길장이 승하여 화를 이루지 않으므로 전진하는 것도 길하다.
  일간이 일지의 상신을 생하여 탈기(脫氣)로 되니 인택(人宅)에 모두 소모가 많고, 일간 일지상신이 지합하므로 타인에 의존하여 힘을 얻는다. 일귀(日貴)는 역행하고 삼전은 공(空)․백(白)․상(常)이며, 야귀는 순행하여 삼전은 구(勾)․합(合)․주(朱)이다. 


`
  ,
  // 3
  `  이 과에서는 제2과와 제4과에 상하의 극이 있다. 제2과는 하에서 상을 적(賊)하기 때문에 축묘(丑卯)를 초전으로 삼아서 중심역간전격(重審逆間傳格)으로 된다.
  일간일지 서로의 상하는 지합된다. 즉 교차합(交車合)으로 되어 담합(談合)․교섭․교역 모두에서 타협이 성립된다. 일간상신 묘(卯)는 일간을 생하여 만사 내몸에 유익하다. 삼전 축해유(丑亥酉)는 음의 역간전(逆間傳)으로서 달이 서산에 잠긴다고 하여 극음격(極陰格)이다. 초전은 이양(二陽)을 생하지만 양은 미약하고 음의 기(氣)가 넘치며 퇴전(退傳) 해유(亥酉)로 양은 소멸되고 음은 생장한다. 즉 양이 끊어지므로 극음격이라고 말한다. 음사(陰私)․음란(淫亂)․주색․간도(奸盜) 등 부정한 징조를 이룬다. 중심과(重審課)는 일이 안에서 일어나거나 부인으로 인해 의논이 생긴다. 역간전격(逆間傳格)은 조격(阻隔)을 생하기 쉽고 교차재동(交車財動)은 재물의 기쁨이 있다.
  백법에 말하기를 ｢일야천을귀신(日夜天乙貴神)이 삼전 중에 있으면 윗사람에게 의존하면 좋고, 육음격(六陰格)은 혼미한 일이 많다｣라고 했다. 비전(比傳)에 이르기를 ｢초전의 축(丑)에 주작이 승하면 타인의 천거가 있다｣고 하였다. 일귀(日貴)는 순행하여 삼전이 구(勾)․주(朱)․귀(貴)이고, 야귀(夜貴)는 삼전이 주(朱)․귀(貴)․음(陰)이다. 일지상신의 신(申)은 순수(旬首)로서 현무가 앉아 폐구(閉口)의 형상을 이루면 도난사건이 있다.`
  ,
  // 4
  `  이 과는 상하의 상극이 없고 제2과 상신이 일간을 극하여 초전이 되고 요극원태과(遙剋元胎格)이다.
  초전의 해(亥)에 정신(丁神) 역마가 앉아 일간상신이 장생(長生)으로 되고 길장이 승하여 음신의 해(亥)에서 생을 받고 또 지합한다. 내몸은 만사에 길행을 얻으나 일지는 음신․양신이 모두 공망되어 가택 중에 무엇인가 훼손되는 곳이 있다. 또한 천신에서 극되어 요극(遙剋)은 호시격(蒿矢格)인 화복이 모두 가볍다. 원태격(元胎格)으로 장래에 발생할 뜻이 있을지라도 눈앞에서는 현상을 유지하는 것이 상책이다.
  백법(百法)에 이르기를 ｢관귀(官鬼)가 천을에 승하면 천을신기격(天乙神祇格)이다｣고 하였다. 백법의 48법에 해당하는데 여기에 다시 기록한다. 이 격은 관귀(官鬼)가 천을귀신과 동승(同乘)하는 것을 말한다. 숭귀(崇鬼)로 간점(看占)하지 않으나 정단하면 신불에 의하여 해를 받고, 일지상신에 임하면 가정 내의 신불이 바르지 못할 경우에는 재병에 걸린다. 바르게 수리하고 택신(宅神)을 모셔 기도드리면 재앙이 없다. 또 적(敵) 때문에 도움을 받는 형상이 있다. 비전(比傳)에 이르기를 ｢사(巳)가 신(申)에 가해지는 녹신(祿神)은 폐구(閉口)로 되고 병을 정단하면 음성을 잃는 질환이며 또 음식을 먹지 못한다｣라고 하였다. 일귀(日貴)는 순행하여 삼전이 주․후․상이며, 야귀(夜貴)는 삼전이 귀․현․공이다. 일귀는 인(寅)에 청룡이 앉아서 생기(生氣)로 되며, 사물에 조급해하지 않고 진취할 뜻이 없어도 발복할 기회를 얻는다.`
  ,
  // 5
  `  이 과는 상하에 상극이 없고 일간에서 제2과 상신(上神) 유(酉)를 요극(遙剋)하여 초전이 되고 요극탄사종혁격(遙剋彈射從革格)이 된다.
  일간에서 삼합금국처재(三合金局妻財)로 되어 일간상신은 축(丑)에 탈기(脫氣)하고, 일지상신은 화생토(火生土)로 생을 받는다. 그러나 공망하여 생하면서도 생하지 않으므로 내몸은 재가 풍성하나 일지에서 설기하여 가정에는 재물의 소모가 많다. 요극과는 토금(土金)이 있으면 탄환이라 해도 사람을 쏠 수 없으며, 화와 복이 모두 빈약하다. 제1과․제2과는 내몸이다. 상대는 화국(火局)이니 금국은 극이 되어 상대가 내몸과 의지를 다르게 한다 해도 공망하니 두려울 것 없다. 종혁(從革)은 모든 일에서 새롭게 시작해도 무방하다.
  백법에 말하기를 ｢삼전에 재기가 너무 왕하면 오히려 재물이 결핍되고, 유(酉)가 둔간(遁干)하여 을(乙)의 인수로 되며, 재의 지에 있으면 윗사람이 화를 입거나 존친(尊親)의 병점(病占)을 두려워한다｣고 하였다.
  일귀는 순행하여 삼전은 귀․상․구이며, 야귀는 삼전이 음․공․주이다. 귀신이 묘(卯)에 임하면 여덕격(勵德格)으로서 군자는 때를 기다려 사물에 조급하지 않으면 재액을 면한다. 비전에 말하기를 ｢일간에서 삼전을 극하는 것은 구재의 목적이 달성된다｣라고 하였다. 일지에서 삼전을 생하여 재원의 소모가 있으므로 재를 소득하여도 낭비의 우려가 있다. 유사축(酉巳丑)은 조회(操會)라 부르며 이미 시기를 잃는 징조이다.`
  ,
  // 6
  `  이 과는 제1과․제3과․제4과에서 위로부터 아래를 극한다. 양일(陽日)은 양비(陽秘)의 자사(子巳)를 취하여 초전으로 하니 지일과(知一課)이다.
  일간상신은 수극(水剋)으로 되고 일지상신은 사술(巳戌)의 묘(墓)에 가(加)한다. 사(巳)는 순미(旬尾)의 지지가 일지상에 가해져서 폐구격(閉口格)이며, 태상(太常)이 승할 때는 음식물을 탐하여 병원(病源)을 이룬다. 초전의 자(子)는 귀살이지만 중전의 토(土)가 이것을 제어하므로 구신(救神)의 작용을 한다. 말전의 인(寅)은 일간의 장생에 해당하고 인수(印綬)는 살을 끌어서 덕(德)으로 화한다. 인(寅)의 장생이 묘(墓)에 들어가면 점병을 싫어한다. 지일과(知一課)는 사건이 동류(同類)에 의하여 일어나고 불비격도 역시 동류에 의하여 결함이 생긴다.
  백법(百法)에 말하기를 ｢일지건록(日支建祿)은 권섭부정(權攝不正)하는 일이 있고, 중전․말전의 공망은 전(傳)에 돌지 않아 초전의 일신으로 길흉을 정한다｣라고 하였다. 일귀(日貴)는 순행하고 삼전은 합(合)․음(陰)․청(靑)이며, 야귀(夜貴)는 삼전이 사(蛇)․상(常)․합(合)이다.
  이 과는 삼상(三上)에서 삼하(三下)를 극하여 유도액(幼度厄)으로 되기 때문에 부하나 아랫 사람의 정단은 이익으로 되지 않는다. 육친과 가택이 불화하며 병환 또는 소송 사건이 거듭 일어나며, 양신(陽神)이 초전으로 되고 사신(蛇神)의 흉장이 승하면 존장에게 재난이 생긴다.`
  ,
  // 7
  `  이 과는 12신이 대궁(對宮)에 있어서 반음과(返吟課)로 되며 극이 있으면 극을 사용하는 것이 원칙이다. 그러므로 제1과와 제2과에 극이 있어서 아래에서 위를 적(賊)하는 사해(巳亥)를 채용하여 초전(初傳)으로 하고, 삼전이 사해사(巳亥巳)인 반음원태과(返吟元胎課)로 된다.
  병(丙)에서 사(巳)는 건록이며, 순미(旬尾)의 사(巳)가 초전으로 되면 폐구녹격(閉口祿格)이다. 일간상신(日干上神) 해(亥)의 관귀에 귀신이 타서 천을신기(天乙神祗)로 되고 일지인 진(辰)은 백호가 승하여 둔귀로 된다. 진퇴양난하며, 사람은 상하고 가택은 썩는 형상을 이룬다. 반음과는 왕래기복(往來起伏)이 심하여 의존할 수 없다.
  백법에 말하기를 ｢귀살(鬼殺)이 천을(天乙)과 병합하여 천을신기로 되는데 택신(宅神)에 보답해야 한다｣라고 하였다. 백호와 둔귀(遁鬼)와 병합하면 재화가 있으며 해(亥)는 사(巳)에 가해져서 일간을 극하고, 병점은 한열이 왕래하며 혹은 폐계통의 질병에 걸린다. 비전(秘傳)에 이르기를 ｢하늘은 서북으로 기울어지고 일월은 땅을 따라 동남에 떨어져서 강과 바다로 돌아간다｣라고 하였다. 사해(巳亥)는 그 중간으로 되어서 크게 구하려 하지만 얻는 것이 적다. 정신(丁神)은 중전에 있고 교섭하는 사건은 왕래가 신속하다.
  일귀는 순행하고 삼전이 상(常)․주(朱)․상(常)으로서 막귀(幕貴)가 간(干)에 임하므로 입시․선거․귀인의 면접이나 공서(公署)에의 신청 등은 좋고, 현임 관리에게는 불리하다. 야귀(夜貴)는 역행하고 삼전은 공(空)․귀(貴)․공(空)이다.`
  ,
  // 8
  `  이 과는 4과 중에서 제2과가 위에서 아래를 극하고 있다. 양일이기 때문에 양비(陽比)의 극이다. 제4과 신묘(申卯)를 초전으로 하여 지일과(知一課)이다.
  지일(知一)은 극동(剋動)이 있기 때문에 일은 두 갈래로 나누어진다. 같은 극이면 오로지 하나로 돌리고, 쟁송사(爭訟事)는 화해하는 것이 좋고 공존공영을 같이 도모하는 것이 좋다. 찾는 사람이나 잃은 물건 등은 가까이에서 찾아야 한다. 장사에서는 동업자와의 거래이다. 아래를 극하기 때문에 불안한 일이 생긴다. 먼 데 것을 버리고 가까운 데 것을 취하며, 소원(疏遠)함을 버리고 친근함을 취하는 것이 좋다. 비(比)를 쓰는 일에서는 도액(度厄)․무록(無祿)․절사격(絶嗣格)에서 모두 음양에 따라 초전을 만든다.
  불비격(不備格)은 무음격(蕪淫格)이라고도 부른다. 4과는 2양2음이 4과를 구성한다. 제1과는 간의 양신, 제2과는 간의 음신으로 하고, 제3과는 지의 양신이고 제4과는 지의 음신으로 한다. 4과 중에서 1과만 이같은 과이므로 기실 3과에 지나지 않는데 이것을 불비격이라고 한다. 양일에는 간의 음양신을 그대로 쓰고 음일에는 지의 음양신을 그대로 쓴다. 음신이 결여되면 음의 불비로 하고 양이 결여되면 양의 불비라고 한다. 이 원칙에 따르면 이 과는 양일이기 때문에 양신은 그대로 쓰고 음신을 버린다. 그리하여 2양1음으로 되어서 두 남자가 한 여자를 다투는 형상으로 되기 때문에 무음(蕪陰)이라고 부른다.
  일귀는 순행하고 삼전은 후(后)․구(勾)․현(玄)이며, 야귀는 역행하여 삼전은 합(合)․음(陰)․청(靑)이다. 신(申)이 묘(卯)에 가해지고 야장(夜將)의 육합이 타는 것은 처(妻)를 병단하면 반드시 죽는다. 백법에 이르기를 ｢일간상신(日干上神)인 술(戌)에 사신(蛇神)이 승하면 협묘(夾墓)가 엎드리니 흉해를 면치 못한다｣라고 하였다.`
  ,
  // 9
  `  이 과는 4과 중에서 제1과․제3과에 상하의 극이 있다. 아래에서 위를 극하므로 유사(酉巳)를 사용하여 초전이 되고 중심종혁격(重審從革格)이다. 일간에서 보아 삼합재동(三合財動)으로 되어 재가 왕성하다.
  일지음양신(日支陰陽神)은 삼합화국(三合火局)으로 되고 비겁(比劫)은 재를 빼앗아 존친이나 윗사람의 재앙을 제복(制伏)한다. 제1과의 재는 단독적인 힘이 적고, 제2과의 토(土)에서 생하여 재는 힘을 얻는다. 따라서 자기가 요구하는 재는 두텁다. 종혁(從革)은 정신(鼎新)한 형상이기 때문에 낡은 것을 버리고 새로운 사업을 시도한다. 일간일지는 교차육해(交車六害)로 되고 토금(土金), 목화(木火)가 상생하여 육해(六害)로 되기 때문에 꿀 속에 비소의 독이 있는 것과 같다.
  백법(百法)에 이르기를 ｢교차에는 10법이 있다. 21법 원칙중 제4에 이르기를 교차육해격이란 일간상신과 일지와 일지상신과 일간의 기궁(寄宮)이 서로 육해로 되는 것을 말한다｣고 하였다. 이 격은 처음에는 서로 화목한 상태이나 뒤에 가서는 모든 일이 그림 속의 떡이 되고 만다. 이 격은 시작은 훌륭하나 종국은 아름답지 못하다. 두 귀신이 극을 받아 귀인의 원조와 후원을 얻기 어렵다. 백법 중의 49법에서 두 귀가 극을 받는 법칙을 이해하면 일귀와 야귀의 어느 귀신이나 다 극을 받는 곳에 서는 것을 말한다. 윗사람이나 귀인을 신뢰하지 말아야 한다. 두 귀가 스스로 극제를 받아서 노여워하기 때문에 윗사람에 의지해도 성취되지 못한다. 그러므로 삼전 중의 유무를 불문하고 사용해야 한다.
  일귀는 역행하여 삼전이 귀(貴)․상(常)․구(勾)이고 천장(天將)은 모두 토(土)신에 해당하며, 전재(全財)를 생하여 가장 묘하다. 야귀는 삼전이 주(朱)․음(陰)․공(空)이다. 주작(朱雀)이 초전 유(酉)의 재에 승하므로 재물로 인하여 문제가 생긴다.`
  ,
  // 10
  `  이 과는 4과 중 제1과의 신사(申巳)에 극(剋)이 있을 뿐이므로 신사(申巳)를 초전으로 하여 중심시입과(重審始入課)로 된다.
  신(申)은 일간의 처재효(妻財爻)이다. 초전의 금(金)은 중전의 해(亥)를 생하며 해(亥)는 인목(寅木)을 생하고 목(木)은 일간의 병화(丙火)를 생하여 체생격(遞生格)을 구성한다. 초전의 신(申)은 역마이고 중전의 해(亥)는 정신(丁神)으로 정마(丁馬)가 함께 달린다. 만사에서 타인에게 천거되어 모든 일을 이동하게 된다. 삼전의 원태(元胎)는 새것을 따라서 처음은 어렵지만 후에는 성취된다.
  백법의 삼전체생격(三傳遞生格)을 발췌하여 다음에 게재한다. 삼전 체생격에는 두 법이 있는데, 하나는 초전이 중전을 생하고, 중전이 말전을 생하며, 말전이 일간을 생하는 것으로 본 과와 같은 것이다. 둘째는 말전에서 중전, 중전에서 초전, 초전에서 일간을 생하는 것이다. 이 두 격은 모두 사람의 추천을 받고 뭇사람들에게 의뢰한다. 시종여일하게 어떤 일에서나 목적이 달성된다. 초전 또는 말전이 공망될 때는 타인이 추천할 뜻이 있어도 성취․실현되지 않고 한담뿐이며 진실된 말이 없다. 초전은 순수(旬首)의 신(申)이기 때문에 육의격(六儀格)으로 되어 희사․경사를 초래하고 흉신도 해를 끼치지 못하여 길조로 된다.
  일귀는 역행하여 삼전은 사(蛇)․음(陰)․백(白)이고, 야귀는 삼전이 합(合)․귀(貴)․현(玄)이다. 말전은 길장이 아니지만 대체로 양격(良格)이기 때문에 흉이 있다 해도 극히 경미하다.`
  ,
  // 11
  `  이 과는 제3과의 하에서 상을 극하여 중심과(重審課)이며, 자인진(子寅辰)은 양의 순간전격(順間傳格)이다. 초전의 자(子)는 음이 극도에 달하여 일양(一陽)을 생하고 진(辰)에 이른다. 순행하여 양으로 향하기 때문에 향양격(向陽格)이라 부르며, 사(邪)를 제거하고 바르게 광명을 맞아 모든 일에서 길조를 이룬다.
  일간상신은 탈기(脫氣)하여 순공(旬空)으로 되어 공탈격(空脫格)으로 된다. 분실과 소모를 방지해야 한다. 초전은 귀살(鬼殺)로 되고 자식은 집안에서 나를 원한으로 해치려는 뜻이 있다. 말전 임(壬)의 둔간(遁干)이 귀(鬼)로 되어 늘 재해가 발생한다. 초전의 자(子)와 일간상신의 미(未)는 육해이지만 미(未)는 공망되어 해를 보아도 해를 입지 않는다. 진간전격(進間傳格)은 전진하는 징조이나 도중에 장애가 생긴다. 야귀는 천후가 자(子)에 승하여 내몸을 극하기 때문에 부녀에게서 재해를 받기 쉽다.
  백법(百法)에 이르기를 ｢육해를 보면 피차에 의심하는 일이 생긴다｣라고 하였다. 백호가 둔귀(遁鬼)에 승하면 재화가 적지 않다. 비전에 말하기를 ｢말전에서 초전을 극하면 시작에는 재화가 있으나 자연히 소산된다｣라고 하였다. 갑신(甲申) 순중(旬中)은 초전의 자(子)가 순기(旬奇)로 되어 위험을 만나도 구해(救解)된다. 일귀는 역행하여 삼전이 현(玄)․백(白)․청(靑)이고, 야귀(夜貴)는 삼전이 후(后)․현(玄)․백(白)으로 천장은 흉신이 많으므로 만사에 화를 피하고 신중해야 한다. 요행심 또는 야심을 품지 말아야 하며 일상사를 조용히 처리해야 한다.`
  ,
  // 12
  `  이 과는 4과 중의 제3과 해술(亥戌)이 일하가 일상을 적(賊)하므로 해술(亥戌)을 초전으로 하여 중심과(重審課)로 된다. 해자축(亥子丑)은 음의 진여격(進茹格)이다. 
  해자축(亥子丑)은 밤중에 한점의 햇빛도 보지 못하기 때문에 용잠(龍潛)이라 이름하며 보배를 품에 안고 헤매는 형상이다. 일간상신과 오미(午未)는 왕세이나 공망하여 힘이 없고 유익하지못하다. 해자축(亥子丑)은 연주삼기(聯珠三奇)로 된다. 일지상신에 귀신(貴神)이 승하면 귀가 집에 든다고 하여 흉은 결절(結絶)된다.
  백법(百法)에 이르기를 ｢지지의 앞이 천반으로 되면 나망(羅網)이라고 한다｣고 하였다. 나망은 모사(謀事)가 졸책(拙策)으로 된다. 제3과와 제4과에서 모두 일간을 극하면 귀(鬼)가 제3과․제4과에 임하고 재해․관사가 일어난다. 해자축(亥子丑)은 장래에 인묘(寅卯)로 전진하면 양전(陽轉)되므로 만사 시기를 기다리는 것이 좋다. 일귀는 역행하여 삼전이 음(陰)․현(玄)․상(常)이고, 야귀는 삼전이 귀(貴)․후(后)․음(陰)이다. 흉장이 아니기 때문에 스스로 수양하면 기우(奇遇)가 있다. 삼기(三奇)는 흉을 구하므로 가볍다.
  일반적으로 천을귀신(天乙貴神)은 순행을 좋아하여 무슨 일에서나 순조롭게 되고 역행은 이와 반대이다. 순행에서는 흉장도 흉이 가볍고, 역행은 흉이 무겁고 길장이라 해도 복력이 가볍다. 보조로 귀신의 순역(順逆)을 참작하기 바란다.`
  ,
]

  // '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
  const PDF_RAEJUNG1 = [
  ]
  
  
  const PDF_DANJEONG1 = [
  ]
  

  
export default {
    columnA,
    columnD,
    columnS,
    columnZ,
    columnAF,
    columnAS,
    columnAU,
    columnBE,
    columnBO,
    columnBS,
    columnBV,
    columnCE,
    columnCL,
    columnCY,
    columnDH,
    // 
    CHOSA1,
    YOGYEOL1,
    SIBO1,
    SIBO2_1,
    SIBO2_2,
    SAGWA_IMAGE1,
    HEASYEOL,
}
